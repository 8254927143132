import { Remirror } from '@remirror/react';
import { LinkContainer } from '@/components/Remirror';
import { Manager, ConferenceDetailsEditorOnChange, State } from './interfaces';
import styles from './style/Details.Editor.css';

type Props = {
  autoFocus?: boolean;
  className?: string;
  editable?: boolean;
  onChange?: ConferenceDetailsEditorOnChange;
  manager: Manager;
  state: State;
} & ChildrenProps;

export const ConferenceDetailsEditorContainer = ({
  autoFocus,
  children,
  className,
  editable = true,
  manager,
  onChange,
  state,
}: Props) => {

  return (
    <Remirror
      classNames={[styles.editor, className]}
      autoFocus={autoFocus}
      editable={editable}
      manager={manager}
      onChange={onChange}
      initialContent={state}>
      <LinkContainer>
        {children}
      </LinkContainer>
    </Remirror>
  );
};