import { useCallback, useContext } from 'react';
import * as menu from '@consts/menu';
import { MenuItemName } from '@containers/AppNavigation/interfaces';
import { WhitelabelMenuDisplayContext } from '@/components/Whitelabel/Context';

export const useWhitelabelMenuValidator = () => {
  const ctx = useContext(WhitelabelMenuDisplayContext);

  const validator = (key: MenuItemName) => {
    return !ctx.whitelabel
      ? true
      : permitted.includes(key);
  };

  return useCallback(validator, [ctx.whitelabel]);
};

const permitted = [
  menu.Logout,
  menu.Profile,
  menu.Settings,
];