import { useCallback, useContext, useEffect } from 'react';
import { Redirect, Switch, useHistory } from 'react-router-dom';
import { path } from '@consts';
import { MedicalOnboardingBackgroundContainer } from '@containers/MedicalOnboarding/MedicalOnboarding.Background.Container';
import { MedicalOnboardingCompletionContainer } from '@containers/MedicalOnboarding/MedicalOnboarding.Completion.Container';
import { MedicalOnboardingExpertiseContainer } from '@containers/MedicalOnboarding/MedicalOnboarding.Expertise.Container';
import { MedicalOnboardingScreensContainer } from '@containers/MedicalOnboarding/MedicalOnboarding.Screens.Container';
import { MedicalOnboardingCompletionContext, MedicalOnboardingScreensContext } from '@containers/MedicalOnboarding/Context';
import { useSelectUser } from '@containers/Store';
import { LinearProgress } from '@/components/Progress/LinearProgress';
import { useStepperContext } from '@/components/Stepper/hooks/useStepperContext';
import * as StepperRoutes from './MedicalOnboarding.Stepper.Routes';
import styles from './style/Stepper.css';

type Props = unknown;

const MedicalOnboardingStepper = (props: Props) => {

  useRerouteOnNotFound();

  return (
    <>
      <ProgressBar />
      <StepperRoutes.Expertise />
      <StepperRoutes.Background />
    </>
  );
};

MedicalOnboardingStepper.displayName = 'MedicalOnboarding.Stepper';

type MedicalOnboardingStepperProps = {
  children: React.ReactNode;
} & IUserId;

export const MedicalOnboarding = (props: MedicalOnboardingStepperProps) => {
  const user = useSelectUser();

  return (
    <MedicalOnboardingCompletionContainer userId={user.id}>
      <MedicalOnboardingBackgroundContainer userId={user.id}>
        <MedicalOnboardingExpertiseContainer userId={user.id}>
          <MedicalOnboardingScreensContainer>
            <Switch>
              <Redirect
                exact
                from={path.Onboarding.Expertise.Root}
                to={path.Onboarding.Expertise.ProfessionalRole} />
              <Redirect
                exact
                from={path.Onboarding.Background.Root}
                to={path.Onboarding.Background.Identification} />
              <MedicalOnboardingStepper />
            </Switch>
          </MedicalOnboardingScreensContainer>
        </MedicalOnboardingExpertiseContainer>
      </MedicalOnboardingBackgroundContainer>
    </MedicalOnboardingCompletionContainer>
  );
};

function ProgressBar() {
  const step = useStepperContext();
  const progress = ((step.index + 1) / step.count) * 100;

  return (
    <LinearProgress
      className={styles.progress}
      value={progress} />
  );
}

const useRerouteOnNotFound = () => {
  const history = useHistory();

  const ctx = {
    completion: useContext(MedicalOnboardingCompletionContext),
  };

  const { stepper } = useContext(MedicalOnboardingScreensContext);

  const handleRedirect = useCallback(() => {
    if (stepper.remaining.length) {
      history.push(stepper.route.next);
    } else {
      ctx.completion.mutation.mutateAsync()
      .then(() => history.push(stepper.route.next));
    }
  }, [
    ctx.completion.mutation,
    history,
    stepper.remaining,
    stepper.route.next,
  ]);

  useEffect(() => {

    if (stepper.context.index === -1) {
      handleRedirect();
    }

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [stepper.context.index]);
};