import http, { transformers } from '@services/http';
import type { GetProjectAccessUsers, UpdateProjectAccessUsers } from './interfaces/projects.access';

export const getProjectAccessUsers = (data: GetProjectAccessUsers.Request) => {
  return http.get<GetProjectAccessUsers.Response>(`/admin/projects/${data.projectId}/access`);
};

export const updateProjectAccessUsers = (data: UpdateProjectAccessUsers.Request) => {
  const { projectId, ...rest } = data;

  return http.put<UpdateProjectAccessUsers.Response>(`/admin/projects/${projectId}/access`, rest, { transformResponse: transformers.transformDates });
};