import { useCallback, useMemo, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelectCall } from '@containers/Store';
import { CallType } from '@enums';
import { ConfirmationStepperContext } from '@containers/Calendar/Context';
import { eventsMapKeysToDates } from '@containers/Calendar/mapping';
import { useProposeTimesLocation } from '@containers/Scheduler/hooks';
import { CalendarPicker } from '@/components/CalendarPicker';
import styles from './style/SelectingDay.css';

const mapState = (state: Store.State) => ({
  selectProject: (projectId: number) => state.projects[projectId],
});

type Props = unknown;

const SelectingDay = (props: Props) => {
  const { selectProject } = useSelector(mapState);
  const ctx = useContext(ConfirmationStepperContext);
  const history = useHistory();

  const { callId, next, setDay } = ctx;

  const call = useSelectCall(callId);

  const handleChangeDay = useCallback((date: Date) => {
    setDay(date);
    next();
  }, [
    next,
    setDay,
  ]);

  const location = useProposeTimesLocation({
    callId: ctx.callId,
  });

  const message = useMemo(() => {
    if (call.typeId === CallType.Project) {
      const project = selectProject(call.projectId);

      return `Pick a date and time to confirm the ${project.name} consultation.`;
    } else if (call.typeId === CallType.AdHoc) {

      return `Pick a date and time to confirm the ${call.request.subject} consultation.`;
    }
  }, [
    call,
    selectProject,
  ]);

  const dates = eventsMapKeysToDates(ctx.events);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <button
            className={styles.back}
            onClick={() => history.goBack()}>
            <ArrowBackIcon className={styles.arrow} />
          </button>
          <div className={styles.title}>Select a Time</div>
        </div>

        <div className={styles.main}>
          <div className={styles.subheader}>
            <div className={styles.message}>{message}</div>
          </div>

          <div className={styles.section}>
            <div className={styles.h2}>Select a Day</div>

            <Link to={location}>
              <button className={styles.propose}>
                Propose Alternate Times
              </button>
            </Link>
          </div>

          <CalendarPicker
            dates={dates}
            onChange={handleChangeDay} />
        </div>

      </div>
    </div>
  );
};

export { SelectingDay };
export default SelectingDay;