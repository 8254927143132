import * as types from '@store/action-types';
import {
  AppDataFetchedAction,
  AppDataFetchedState,
  AppStateRehydratedAction,
  AppStateRehydratedState,
  BatchActionsAction,
  BatchActionsState,
} from '@store/interfaces';
import * as $session from '@services/auth/session';

export const appDataFetched = (data: AppDataFetchedState): AppDataFetchedAction => {
  return {
    type: types.APP_DATA_FETCHED,
    calls: data.calls,
    chat: data.chat,
    contacts: data.contacts,
    documents: data.documents,
    group: data.group,
    onboarding: data.onboarding,
    pipeline: data.pipeline,
    platform: data.platform,
    projects: data.projects,
    settings: data.settings,
    user: {
      ...data.user,
      state: { authenticated: $session.isAuthenticated() },
    },
    workspaces: data.workspaces,
  };
};

export const appStateRehydrated = (data: AppStateRehydratedState): AppStateRehydratedAction => {
  return {
    type: types.APP_STATE_REHYDRATED,
    calls: data.calls,
    chat: data.chat,
    contacts: data.contacts,
    documents: data.documents,
    group: data.group,
    pipeline: data.pipeline,
    platform: data.platform,
    projects: data.projects,
    settings: data.settings,
    user: data.user,
    workspaces: data.workspaces,
  };
};

export const batchActions = (actions: BatchActionsState): BatchActionsAction => {
  return { type: types.BATCH_ACTIONS, actions };
};