import Button from '@/components/Button';
import styles from './style.css';

type ButtonProps = {
  children: React.ReactNode;
};

export const Buttons = (props: ButtonProps) => (
  <div className={styles.buttons}>
    {props.children}
  </div>
);

export default Buttons;