import { useCallback } from 'react';
import { useDownloadConferenceTagClip } from '@utils/api';
import { DownloadTaggedMomentMediaClipContext } from './context';

type Props = {
  momentId: number;
  tag: { name: string };
} & ICallId & ChildrenProps;

export const DownloadConferenceTagClipContainer = (props: Props) => {

  const { download } = useDownloadConferenceTagClip({
    momentId: props.momentId,
    callId: props.callId,
    type: 'video',
  });

  const handleDownload = useCallback(() => {
    return download({
      extension: `mp4`,
      name: `Call (${props.tag.name})`,
    });
  }, [download, props.tag]);

  return (
    <DownloadTaggedMomentMediaClipContext.Provider value={handleDownload}>
      {props.children}
    </DownloadTaggedMomentMediaClipContext.Provider>
  );
};