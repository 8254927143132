import { ButtonActivityIndicator, ButtonOutlined } from '@presentation';
import { cx, useToggle } from '@utils';
import { Checkbox } from '@/components/Checkbox';
import { Header } from '@/components/Modal/Header';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import styles from './style/Publishing.css';

type Props = {
  className?: string;
  onCancel: () => void;
  onSubmit: () => void;
  submitting: boolean;
};

export const PublishingCompliance = ({ submitting = false, ...props }: Props) => {
  const [checked, toggle] = useToggle(false);

  return (
    <div className={cx(styles.compliance, props.className)}>
      <Header>{copy.title}</Header>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.message}>{copy.message}</div>
          <div className={styles.confirmation}>
            <div className={styles.confirm}>
              <Checkbox
                checked={checked}
                disabled={submitting}
                onChange={toggle} />
              <div className={styles.agree}>{copy.confirm}</div>
            </div>
          </div>
        </div>
        <ButtonSet className={styles.footer}>
          <ButtonOutlined
            className={styles.btn}
            disabled={submitting}
            onClick={props.onCancel}>
            Cancel
          </ButtonOutlined>
          <ButtonActivityIndicator
            className={styles.btn}
            disabled={!checked}
            color="affirmative"
            implicitDisable={false}
            loading={submitting}
            onClick={props.onSubmit}>
            Publish
          </ButtonActivityIndicator>
        </ButtonSet>
      </div>
    </div>
  );
};

PublishingCompliance.displayName = 'Publishing.Compliance';

const copy = {
  confirm: `I confirm.`,
  message: `Publishing transcripts and/or recordings will make them visible to any Trinity clients who have permission to view this project, including any unredacted Personally Identifiable Information (PII) contained within them.  Please only proceed with publishing if you or other team members have followed Trinity's policies around PII redaction with the associated documents.`,
  title: `Transcript Publishing Compliance Confirmation`,
};