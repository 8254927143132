import { useCallback } from 'react';
import { X } from 'react-feather';
import type { ImageMarkupQuestion, PipedSurveyValue } from '@/types';
import { useIsLanguageVersion, useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { DragInputComponent, ImageInput } from '@/components/ImageInput';
import Toast from '@/components/Toast';
import { Button } from '@/components/Button';
import { PipingAnchor, CustomPipingButton, PipingMenuContainer } from '@/components/Survey.RichText/Editor.Piping';
import { getResponsePipeNodeText } from '@/containers/SurveyBuilder/utils/rich-text.parse';
import { SurveyQuestionType } from '@/enums';
import { richTextHasImages } from '@/containers/Survey/utils';
import styles from './style/ImageMarkup.Question.css';

export type Props = {
  item: ImageMarkupQuestion.Question;
};

export const ImageMarkupQuestionBuilder = ({ item }: Props) => {
  const [state, dispatch] = useSurveyBuilderState();

  const updateImage = useCallback((imageUrl: string) => {
    dispatch({
      type: 'update-question-settings',
      settings: {
        ...item.settings,
        initialImage: imageUrl,
      },
      questionIdentifier: item.base.identifier,
    });
  }, [dispatch, item.base.identifier, item.settings]);

  const onFileChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];

    const imageUrl = URL.createObjectURL(file);

    updateImage(imageUrl);
  }, [updateImage]);

  const onPipedValueSelect = useCallback((value: PipedSurveyValue) => {
    if ('question' in value) {
      dispatch({
        type: 'update-question-settings',
        settings: {
          ...item.settings,
          initialImage: value,
        },
        questionIdentifier: item.base.identifier,
      });
    } else {
      Toast.error({
        title: 'Invalid piped condition chosen',
      });
    }
  }, [dispatch, item.base.identifier, item.settings]);

  const eligiblePipingQuestions = useEligiblePipingQuestions(item);

  const isLanguageEditing = useIsLanguageVersion();

  if (isLanguageEditing) return null;

  if (!item.settings.initialImage) {
    return (
      <>
        <div className={styles.imageInputContainer}>
          <ImageInput
            inputProps={{
              multiple: false,
              accept: 'image/*',
            }}
            InputComponent={ImageInputComponent}
            onChange={onFileChange} />
          {eligiblePipingQuestions.length &&
            <>
              <div>Or</div>
              <div>
                <PipingMenuContainer onPipingValueSelect={onPipedValueSelect}>
                  <PipingAnchor activeQuestionOrdinal={item.ordinal} eligible={{ questions: eligiblePipingQuestions, items: [] }}>
                    {(_, ref) => (
                      <CustomPipingButton
                        ref={ref as React.Ref<HTMLButtonElement>}
                        buttonProps={{ variant: 'brick' }}>
                        Pipe previous image
                      </CustomPipingButton>
                    )}
                  </PipingAnchor>
                </PipingMenuContainer>
              </div>
            </>}
        </div>
      </>
    );
  } else if (typeof item.settings.initialImage !== 'string') {
    return (
      <div className={styles.pipedPreview}>
        <div>Image Piped From: {getResponsePipeNodeText(item.settings.initialImage, state.survey)}</div>
        <Button
          variant='brick'
          onClick={() => updateImage('')}
          size='small'>Clear
        </Button>
      </div>
    );
  } else {
    return (
      <div className={styles.imageContainer}>
        <img src={item.settings.initialImage} alt='Initial Image' />
        <X
          size={24}
          className={styles.removeImage}
          onClick={() => updateImage('')} />
      </div>
    );
  }
};

const ImageInputComponent = () => {
  return <DragInputComponent text='Upload Image' />;
};

const useEligiblePipingQuestions = (item: ImageMarkupQuestion.Question) => {
  const [state] = useSurveyBuilderState();

  const currentItemOrdinal = state.survey.items.find(i => i.source.identifier === item.base.identifier)?.ordinal;

  if (!currentItemOrdinal) return [];

  const questions = state.survey.questions.filter(q => {
    return validPipingQuestionType.includes(q.typeId)
      && state.survey.items.find(i => i.source.identifier === q.base.identifier)?.ordinal < currentItemOrdinal
      && q.options.every(o => richTextHasImages(o.value));
  });

  return questions;
};

const validPipingQuestionType = [SurveyQuestionType.Ranking];

export default ImageMarkupQuestionBuilder;