import { Component } from 'react';
import * as enums from '@enums';
import Button from '@/components/Button';
import ButtonSet from '@/components/Modal/ButtonSet';
import Header from '@/components/Modal/Header';
import Modal from '@/components/Modal/Modal.Legacy';
import styles from './style/Base.css';

type Props = {
  onSubmit: (status: enums.ApprovalStatus.Rejected | enums.ApprovalStatus.Approved) => unknown;
  visible:  boolean;
}

class RecordingConsentModal extends Component<Props> {
  handleClick = recordingConsentStatusId => () => {
    this.props.onSubmit(recordingConsentStatusId);
  }

  render() {
    return (
      <Modal
        blocking={true}
        onClose={this.handleClick(enums.ApprovalStatus.Rejected)}
        visible={this.props.visible}>
        <div className={styles.confirmationRoot}>
          <Header text="Call Recording Requested" />

          <div className={styles.body}>
            <p className={styles.paragraph}>{p1}</p>
            <p className={styles.paragraph}>{p2}</p>
          </div>

          <div className={styles.footer}>
            <ButtonSet>
              <Button.Destructive
                className={styles.promptBtn}
                onClick={this.handleClick(enums.ApprovalStatus.Rejected)}
                title="Reject"
                variant="brick" />
              <Button.Primary
                className={styles.promptBtn}
                onClick={this.handleClick(enums.ApprovalStatus.Approved)}
                title="Accept"
                variant="brick" />
            </ButtonSet>
          </div>

        </div>
      </Modal>
    );
  }
}
const p1 = `The client has requested that the upcoming call be recorded for compliance and internal research purposes. The recording will not be shared with others and is confidential.`;
const p2 = `Please accept or reject the client's call recording request. If you reject this request, call scheduling will still proceed, but the call will not be recorded.`;

export { RecordingConsentModal as RecordingConsent };
export default RecordingConsentModal;