import { SurveyLogicBuilderConditionsContainer } from '@containers/SurveyBuilder.Logic/ConditionsContainer';
import { useSurveyLogicActionValidations, useSurveyLogicBuilderDisplaySettings } from '@containers/SurveyBuilder.Logic';
import { LogicBuilderAction } from './Builder.Action';
import { LogicFormButtons } from './Builder.FormButtons';
import { LogicBuilderConditionsMatch } from './Builder.Match';
import { LogicValidation } from './Builder.Validation';
import styles from './style/Builder.css';

export const SurveyLogicItemBuilder = () => {

  const actionValidations = useSurveyLogicActionValidations();
  const settings = useSurveyLogicBuilderDisplaySettings();

  return (
    <div>
      {!settings.hideActions &&
        <div className={styles.action}>
          <div className={styles.label}>Action</div>
          <LogicBuilderAction />
          <LogicValidation value={actionValidations} />
        </div>
      }

      <SurveyLogicBuilderConditionsContainer>
        <LogicBuilderConditionsMatch />
      </SurveyLogicBuilderConditionsContainer>

      <LogicFormButtons />
    </div>
  );
};