import { Fragment } from 'react';
import { TableCellItem } from './interfaces';
import styles from './style/Table.css';

type Props = TableCellItem;

export const Cell = (props: Props) => {

  return (
    <div
      className={styles.trunc}
      title={props.row.original.call.title}>
      {props.row.original.call.title}
    </div>
  );
};

Cell.displayName = 'Column.Title.Cell';