import { useCallback, useContext, useMemo } from 'react';
import { MedicalBackgroundContext, MedicalExpertiseContext, MedicalOnboardingCompletionContext } from '@containers/MedicalOnboarding/Context';
import { useResetMedicalOnboardingQueries, useOnSubmitAdditionalExpertise, useOnSubmitContact, useOnSubmitLeadership, useOnSubmitPractice } from '@containers/MedicalOnboarding/hooks';
import { ProjectOnboardingContext } from '@containers/ProjectOnboarding/Context';
import { useStepper } from '@utils';
import { CompletionMedicalStepperContext } from './Context';
import { Step, useMedicalStepperStateSelector } from './Steps.Medical';

type Props = unknown;

export const MedicalExperienceContinuationStepper = (props: Props) => {
  const ctx = {
    background: useContext(MedicalBackgroundContext),
    completion: useContext(MedicalOnboardingCompletionContext),
    expertise: useContext(MedicalExpertiseContext),
    project: useContext(ProjectOnboardingContext),
  };

  const state = useMedicalStepperStateSelector();

  const [Screen, actions, index] = useStepper(state.screens);

  const hasNextStep = useMemo(() => {
    return index !== state.screens.length - 1;
  }, [
    index,
    state.screens,
  ]);

  const current = useMemo(() => {
    return state.steps[index];
  }, [
    index,
    state.steps,
  ]);

  const resetMedicalQueries = useResetMedicalOnboardingQueries();

  const navigateNext = useCallback(() => {
    if (hasNextStep) {
      actions.next();
    } else {
      ctx.completion.mutation.mutateAsync()
      .then(() => {
        resetMedicalQueries();

        ctx.project.next();
      });
    }
  }, [
    actions,
    ctx.completion.mutation,
    ctx.project,
    hasNextStep,
    resetMedicalQueries,
  ]);

  const handleSubmitAdditionalExpertise = useOnSubmitAdditionalExpertise(data => {
    return ctx.background.mutation.mutateAsync({
      additionalExpertise: {
        boardEligibility: data.boardEligibility,
        description: data.description,
        therapeuticAreas: data.therapeuticAreas,
      },
    });
  }, [ctx.background.mutation]);

  const handleSubmitContact = useOnSubmitContact(data => {
    return ctx.background.mutation.mutateAsync({
      contact: {
        contactMethod: data.contactMethod,
        market: data.market,
        phone: data.phone,
        phoneAlternate: data.phoneAlternate,
        reminderMethod: data.reminderMethod,
        smsConsent: data.smsConsent,
        timezone: data.timezone || null,
        inPersonResearch: data.inPersonResearch,
        virtualResearch: data.virtualResearch,
        surveyResearch: data.surveyResearch,
        phoneResearch: data.phoneResearch,
      },
    });
  }, [ctx.background.mutation]);

  const handleSubmitLeadership = useOnSubmitLeadership(data => {
    return ctx.background.mutation.mutateAsync({
      leadership: {
        items: data.items,
      },
    });
  }, [ctx.background.mutation]);

  const handleSubmitPractice = useOnSubmitPractice(data => {
    return ctx.background.mutation.mutateAsync({
      practice: {
        employer: data.employer,
        medicalFacilityType: data.medicalFacilityType,
        practiceTime: data.practiceTime,
      },
    });
  }, [ctx.background.mutation]);

  const next = useCallback(() => {

    switch (current) {
      case Step.BackgroundAdditionalExpertise:
        handleSubmitAdditionalExpertise().then(navigateNext);
        break;
      case Step.BackgroundContact:
        handleSubmitContact().then(navigateNext);
        break;
      case Step.BackgroundLeadership:
        handleSubmitLeadership().then(navigateNext);
        break;
      case Step.BackgroundPractice:
        handleSubmitPractice().then(navigateNext);
        break;

      default:
        navigateNext();
    }
  }, [
    current,
    handleSubmitAdditionalExpertise,
    handleSubmitContact,
    handleSubmitLeadership,
    handleSubmitPractice,
    navigateNext,
  ]);

  const value = {
    back: actions.back,
    goTo: actions.goTo,
    index,
    hasNextStep,
    next,
    skip: ctx.project.next,
    steps: state.steps,
  };

  return (
    <CompletionMedicalStepperContext.Provider value={value}>
      <Screen />
    </CompletionMedicalStepperContext.Provider>
  );
};

MedicalExperienceContinuationStepper.displayName = 'ProjectOnboarding.MedicalExperience.Continuation.Stepper';
