import { Chip } from '@presentation/Chip';
import { VICS } from '@/types';
import styles from './style.css';

type Props = {
  items:   Item[];
  onClick: (item: Item) => unknown;
};

export const Selected = (props: Props) => {
  return (
    <>
      {props.items.map(x =>
        <Chip
          className={styles.tag}
          clickable
          color="selected"
          key={x.id}
          onClick={() => props.onClick(x)}>
          <Chip.Body.Action
            onClick={() => props.onClick(x)}
            variant="remove">
            {x.name}
          </Chip.Body.Action>
        </Chip>)}
    </>
  );
};

Selected.displayName = 'Filters.Selected';

export const Suggested = (props: Props) => {
  return (
    <>
      {props.items.map(x =>
        <Chip
          className={styles.suggested}
          clickable
          color="basic"
          key={x.id}
          onClick={() => props.onClick(x)}>
          <Chip.Body.Action
            onClick={() => props.onClick(x)}
            variant="add">
            {x.name}
          </Chip.Body.Action>
        </Chip>)}
    </>
  );
};

Suggested.displayName = 'Filters.Suggested';

type Item = Descriptor | VICS;