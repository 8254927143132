import { useEffect } from 'react';
import type { FileSocketEvent } from '@/enums';
import type { Files } from '../interfaces';
import { files as $files } from '../files';

export const useFilesSocketEvent = <T extends FileSocketEvent>(event: T, fn: Files.RegisterHandler<T>, enabled = true) => {

  useEffect(() => {
    if (enabled) {
      $files.on(event, fn);
      return () => {
        $files.off(event, fn);
      };
    }
  }, [enabled, event, fn]);
};