import { useContext, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as QK from '@consts/querykey.admin';
import * as api from '$admin/api';
import { CalendarsContext, FrameContext, ParamsContext, ParticipantsContext } from './Context';
import { CalendarsQueryParams, useSchedulerUserIdsSelector } from './hooks';
import { FrameKey } from './interfaces';

type Props = {
  children: React.ReactNode;
};

export const Calendars = (props: Props) => {
  const ctx = {
    frame: useContext(FrameContext),
    participants: useContext(ParticipantsContext),
    scheduling: useContext(ParamsContext),
  };

  const selectSchedulerUserIds = useSchedulerUserIdsSelector();

  const request = useMemo(() => {
    const required = !!ctx.scheduling.params?.projectId;

    const params = required && ctx.participants.query.isFetched
      ? selectSchedulerUserIds()
      : {} as CalendarsQueryParams;

    const reactToChanges = [
      FrameKey.Booking,
      FrameKey.ModeratorSchedulers,
      FrameKey.UnconfirmedCalls,
    ].includes(ctx.frame.view.frame);

    return {
      enabled: !!params.userIds?.length && reactToChanges,
      params,
    };
  }, [
    ctx.frame.view.frame,
    ctx.participants.query,
    ctx.scheduling.params?.projectId,
    selectSchedulerUserIds,
  ]);

  const query = useQuery(QK.Projects.Scheduling.Calendars.Get({
    moderatorIds: request.params.moderatorIds,
    projectId: ctx.scheduling.params.projectId,
    userIds: request.params.userIds,
  }), () => {
    return api.projects.scheduling.findSchedulerCalendars({
      projectId: ctx.scheduling.params.projectId,
      ...request.params,
    });
  }, {
    enabled: request.enabled,
    refetchOnWindowFocus: false,
  });

  const value = {
    query,
  };

  return (
    <CalendarsContext.Provider value={value}>
      {props.children}
    </CalendarsContext.Provider>
  );
};

Calendars.displayName = 'CallScheduling.Calendars';