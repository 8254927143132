import * as xform from '@transformers/helpers';
import { safeJsonDate } from '@utils';
import { SurveyResponse } from '@/types';

const normalizeSurveyResponse = <T extends SurveyResponse<string>>(response: T) => {
  if (!response) {
    return null;
  }

  const runTransforms = xform.compose<SurveyResponse, T>(
    normalizeSurveyResponseDates,
  );

  return runTransforms(response);
};

function normalizeSurveyResponseDates(response: SurveyResponse<string>) {
  return Object.assign(response, {
    completedOn: safeJsonDate(response.completedOn),
  });
}

export {
  normalizeSurveyResponse,
};
export default {
  normalizeSurveyResponse,
};