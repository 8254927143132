import { ClassificationDropdown as Dropdown } from '@presentation/SurveyBuilder';
import { useClassificationConditionContext } from '@/containers/SurveyBuilder.Logic';
import styles from './style/Builder.css';

export const ClassificationDropdown = () => {

  const { item, updateClassification } = useClassificationConditionContext();

  return (
    <div className={styles.classificationDropdown}>
      <Dropdown
        onSelect={updateClassification}
        value={item.data.identifier} />
    </div>
  );
};