import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@actions';
import * as enums from '@enums';

const selector = (state: Store.State) => state.pipeline;

export const useCompleteProject = (data: IProjectId) => {
  const dispatch = useDispatch();
  const pipeline = useSelector(selector);

  return useCallback(() => {
    const record = pipeline.me[data.projectId];

    dispatch(actions.projectPipelineItemChanged({
      record: { ...record, statusId: enums.UserProjectStatus.Active },
    }));
  }, [dispatch, data, pipeline]);
};