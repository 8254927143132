import type * as Remirror from './rich-text';

export enum SurveyLogicExpressionNode {
  OpeningParenthesis = 'opening-paren',
  ClosingParenthesis = 'closing-paren',
  LogicalOperator = 'logical-operator',
  Condition = 'condition',
}

export declare namespace SurveyLogicExpression {
  export type RootNode = {
    type: Remirror.NodeType.Doc;
    content: ParagraphNode[];
  };

  export type RichTextValue = RootNode;

  export type ParagraphNode = Remirror.ParagraphNode<ParagraphContent>;

  export type ParagraphContent =
    OpeningParenthesisNode |
    ClosingParenthesisNode |
    LogicalOperatorNode |
    ConditionNode
  ;

  export type LogicalOperator = 'AND' | 'OR';

  export type OpeningParenthesisNode = {
    type: SurveyLogicExpressionNode.OpeningParenthesis;
  };

  export type ClosingParenthesisNode = {
    type: SurveyLogicExpressionNode.ClosingParenthesis;
  };

  export type LogicalOperatorNode = {
    attrs: {
      operator: LogicalOperator;
    };
    type: SurveyLogicExpressionNode.LogicalOperator;
  };

  export type ConditionNode = {
    attrs: {
      identifier: string;
    };
    type: SurveyLogicExpressionNode.Condition;
  };
}