import { memo, useRef, CSSProperties } from 'react';
import { useSpring, animated, config as presets } from '@react-spring/web';
import { useMeasure } from '@utils';
import { Portal } from '@/components/Portal';
import { Point } from '@/types';
import { TooltipAnchor } from './interfaces';

type Props = {
  anchor: TooltipAnchor;
  position: Point;
} & ChildrenProps;

const Offset = 14;

const tooltipStyle: CSSProperties = {
  pointerEvents: 'none',
  position: 'absolute',
  zIndex: 15,
  top: 0,
  left: 0,
};

const translate = (p: Point) => `translate(${p.x}px, ${p.y}px)`;

export const TooltipWrapper = memo(({
  anchor = 'top',
  children,
  position,
}: Props) => {
  const previousPosition = useRef<Point>(null);
  const [measureRef, bounds] = useMeasure<HTMLDivElement>();

  let to: { transform: string; } = undefined;
  let immediate = false;
  const hasDimension = bounds.width > 0 && bounds.height > 0;

  let x = Math.round(position.x);
  let y = Math.round(position.y);

  if (hasDimension) {
    if (anchor === 'top') {
      x -= bounds.width / 2;
      y -= bounds.height + Offset;
    } else if (anchor === 'right') {
      x += Offset;
      y -= bounds.height / 2;
    } else if (anchor === 'bottom') {
      x -= bounds.width / 2;
      y += Offset;
    } else if (anchor === 'left') {
      x -= bounds.width + Offset;
      y -= bounds.height / 2;
    } else if (anchor === 'center') {
      x -= bounds.width / 2;
      y -= bounds.height / 2;
    }

    to = {
      transform: translate({ x, y }),
    };
    if (!previousPosition.current) {
      immediate = true;
    }

    previousPosition.current = { x, y };
  }

  const animatedProps = useSpring({
    to,
    config: presets['gentle'],
    immediate,
  });

  const style = {
    ...tooltipStyle,
    // @ts-ignore
    transform: animatedProps.transform ?? translate({ x, y }),
  };

  return (
    <Portal>
      <animated.div ref={measureRef} style={style}>
        {children}
      </animated.div>
    </Portal>
  );
});

export default TooltipWrapper;