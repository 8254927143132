import { usePersistentProjectInvite } from '@containers/ProjectLinkInvitation/hooks/usePersistentProjectInvite';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { useSignupSubmit } from '$website/containers/Auth/hooks/useSignupSubmit';
import { SignupError } from '$website/screens/Auth/SignupError';
import styles from '$website/components/AuthModal/style/AuthModal.css';
import { PrivacyPolicy } from './PrivacyPolicy';
import { Signup } from './Email';
import * as Layout from './Layout';

type Props = unknown;

export const Email = (props: Props) => {
  const [form, submit] = useSignupSubmit();
  const urlstate = usePersistentProjectInvite();
  const state = urlstate.get();

  function handleForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    submit({ token: state?.token });
  }

  return (
    <>
      <Layout.Header>
        <Layout.Row>
          <Layout.Title>Sign up with Sentiment</Layout.Title>
        </Layout.Row>
      </Layout.Header>

      <div className={styles.body}>
        <form onSubmit={handleForm}>
          <Signup />
          <Layout.Row>
            <div className={styles.error}>
              <SignupError />
            </div>
          </Layout.Row>
          <Layout.Row className={styles.padtop}>
            <ButtonActivityIndicator
              className={styles.submit}
              loading={form.loading}>
              Sign Up
            </ButtonActivityIndicator>
          </Layout.Row>
        </form>
      </div>

      <PrivacyPolicy />
    </>
  );
};

Email.displayName = 'Frame.Signup.Email';