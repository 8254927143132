import * as types from '@store/action-types';
import { Group } from '@store/interfaces';

export const brandingUpdated = (data: Group.BrandingUpdated.State): Group.BrandingUpdated.Action => {
  return {
    type: types.GROUP_BRANDING_UPDATED,
    ...data,
  };
};

export const updateGroupSettings = (data: Group.SettingsUpdated.State): Group.SettingsUpdated.Action => {
  return {
    type: types.UPDATE_GROUP_SETTINGS,
    ...data,
  };
};