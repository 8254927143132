import { forwardRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import type { ProjectType } from '@enums';
import { SurveyType } from '@enums';
import { SurveyTemplateSelection } from '@screens/ProjectCreation/ProjectSurvey.Template';
import { BackButton, Footer, NextButton } from './Footer';
import { ProjectSurveyStepperContext, useProjectCreationNavigation, useProjectFormContext } from './context';
import { ProjectSurvey } from './ProjectSurvey.Builder';
import { SurveyTable } from './ProjectSurvey.Table';
import { useProjectSurveyStepper } from './hooks/useProjectSurveyStepper';

type Props = {
  includeExternalSurvey?: boolean;
  onComplete?: (isExternal: boolean) => void;
  project: {
    id: number;
    projectType: ProjectType;
  };
  SurveyBuilderScreen: React.ForwardRefExoticComponent<React.RefAttributes<unknown>>;
  SurveyTemplateScreen: (props?: unknown) => JSX.Element;
};

export const ProjectSurveyBuilderStepper = forwardRef(({
  includeExternalSurvey = true,
  onComplete,
  project,
  SurveyBuilderScreen,
  SurveyTemplateScreen,
}: Props, ref) => {
  const history = useHistory();

  const { Screen, ...stepper } = useProjectSurveyStepper({
    SurveyBuilderScreen,
    SurveyTemplateScreen,
  });

  const back = useCallback(() => {
    stepper.step > 0
      ? stepper.actions.back()
      : history.goBack();
  }, [
    history,
    stepper.actions,
    stepper.step,
  ]);

  const actions = {
    ...stepper.actions,
    back,
  };

  const ctx = {
    ...actions,
    includeExternalSurvey,
    onComplete,
    project,
    step: stepper.step,
    totalSteps: stepper.total,
  };

  return (
    <ProjectSurveyStepperContext.Provider value={ctx}>
      <Screen />
    </ProjectSurveyStepperContext.Provider>
  );
});

export const ProjectCreationSurveyStepper = forwardRef((_, ref) => {
  const { next } = useProjectCreationNavigation();
  const [form] = useProjectFormContext();

  return (
    <ProjectSurveyBuilderStepper
      onComplete={next}
      project={{
        id: null,
        projectType: form.projectType,
      }}
      SurveyBuilderScreen={ProjectSurvey}
      SurveyTemplateScreen={SurveyTemplateScreen} />
  );
});

const SurveyTemplateScreen = () => {
  const [form] = useProjectFormContext();
  const { next } = useProjectCreationNavigation();

  const canNext = form.surveys.some(s => s.typeId === SurveyType.Project);
  return (
    <>
      <SurveyTemplateSelection />
      <SurveyTable />
      <Footer>
        <BackButton />
        <NextButton disabled={!canNext} onClick={next} />
      </Footer>
    </>
  );
};