import type { SurveyQuestionType } from '@enums';
import type { RankingQuestion } from '@/types';
import type { Validation } from '../interfaces';
import { assertValidOptions } from './validation.options';
import { assertHasValue, returnAssertFailure } from './validation.base';

export const DefaultOptions = 4;
export const MinOptions = 2;
export const MaxOptions = 100;

export const DefaultSettings: RankingQuestion.Settings = {
  dontKnow: {
    enabled: false,
    label: `I don't know`,
  },
  displayFormat: 'default',
  randomization: false,
  minRank: null,
  maxRank: null,
  orderOptionsBasedOnSource: false,
  rationale: {
    enabled: false,
    minimum: 75,
  },
};

export const DefaultOptionMetadata: RankingQuestion.OptionMetadata = {
  canDelete: true,
  canModifyValue: true,
  isAnchored: false,
  isOpenEnded: false,
  template: {},
};

export function validateQuestion(question: RankingQuestion.Question): Validation.ValidateQuestionResult<SurveyQuestionType.Ranking> {
  const hasValue = assertHasValue(question);
  if (hasValue.success === false) {
    return returnAssertFailure(hasValue, question);
  }

  const hasValidOptions = assertValidOptions(question.options, MinOptions, MaxOptions);
  if (hasValidOptions.success === false) {
    return returnAssertFailure(hasValidOptions, question);
  }

  const areSettingsValid = assertSettings(question);
  if (areSettingsValid.success === false) {
    return areSettingsValid;
  }

  return {
    success: true,
    result: {
      question,
    },
  };
}

function assertSettings(question: RankingQuestion.Question): Validation.ValidateQuestionResult<SurveyQuestionType.Ranking> {
  const settings = question.settings;
  if (!settings) {
    return returnAssertFailure({ message: 'No settings given.', success: false }, question);
  }

  if (settings.minRank && settings.maxRank && settings.minRank > settings.maxRank) {
    return returnAssertFailure({ message: 'Min rank cannot be greater than max rank.', success: false }, question);
  }

  return { success: true, result: { question } };
}