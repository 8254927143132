import type { SurveyBuilder, SurveyItemsBuilder } from '../interfaces';
import * as $actions from './state.items';

export function itemsReducer(state: SurveyBuilder.State, action: SurveyBuilder.Actions): SurveyItemsBuilder.State {
  const items = state.survey.items;

  switch (action.type) {

    case 'revert-uncommitted-changes':
      return state.committed.items;

    case 'remove-section':
      return $actions.sectionRemoved(state.survey, action);

    case 'question-added':
      return $actions.questionAdded(state.survey, action);

    case 'question-copied':
      return $actions.questionCopied(state.survey, action);

    case 'message-added':
      return $actions.messageAdded(state.survey, action);

    case 'aie-added':
      return $actions.aieAdded(state.survey, action);

    case 'question-removed':
    case 'message-removed':
    case 'aie-removed':
      return $actions.itemRemoved(state.survey, action.itemIdentifier);

    case 'update-question-identifier':
      return $actions.questionIdentifierUpdated(state.survey, action);

    case 'item-moved':
      return $actions.itemMoved(state.survey, action);

    case 'section-break-added':
      return $actions.sectionBreakAdded(state.survey, action);

    default:
      return items;
  }
}