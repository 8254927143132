import * as consts from '@consts';
import type * as Routing from '@routes/interfaces';
import { SnowflakeReportContainer, ProductResearchGuideContainer, SurveyBestPracticesContainer } from '$website/containers';
import { Pricing } from '$website/screens/Pricing';
import { About } from '$website/screens/About';
import { Login, Signup } from '$website/screens/Auth';
import { Platform as LoginPlatform } from '$website/screens/Auth/Login.Platform';
import { Platform as SignupPlatform } from '$website/screens/Auth/Signup.Platform';
import { Careers } from '$website/screens/Careers';
import { CollaborationTools } from '$website/screens/CollaborationTools';
import { Compliance } from '$website/screens/Compliance';
import Contact from '$website/screens/Contact';
import { ContactUs } from '$website/screens/ContactUs';
import { CustomerResearch } from '$website/screens/CustomerResearch';
import { Quant2Qual } from '$website/screens/Quant2Qual';
import { BrandInsightsTerms, SentimentTerms } from '$website/screens/Terms';
import { ResetPassword, ResetPasswordPlatform } from '$website/screens/ResetPassword';
import { ForgotPassword } from '$website/screens/ForgotPassword';
import { ForgotPasswordPlatform } from '$website/screens/ForgotPassword';
import { PrivacyPolicy } from '$website/screens/PrivacyPolicy';
import { FAQ } from '$website/screens/FAQ';
import * as Router from '$website/Router';

const auth = [
  consts.path.Website.EnterpriseLogin,
  consts.path.Website.Login,
  consts.path.Website.PlatformLogin,
  consts.path.Website.PlatformSignup,
  consts.path.Website.ReferralSignup,
  consts.path.Website.Signup.Root,
];

const header = [
  consts.path.Website.About,
  consts.path.Website.Careers,
  consts.path.Website.CollaborationTools,
  consts.path.Website.Compliance,
  consts.path.Website.Consulting,
  consts.path.Website.Contact,
  consts.path.Website.ContactUs,
  consts.path.Website.Corporate,
  consts.path.Website.CustomerResearch,
  consts.path.Website.Demo,
  consts.path.Website.Enterprise,
  consts.path.Website.FAQ,
  consts.path.Website.Individuals,
  consts.path.Website.Investment,
  consts.path.Website.Quant2Qual,
  consts.path.Website.Pricing,
  consts.path.Website.Root,
  consts.path.Website.Terms.Root,
  consts.path.Website.Terms.Root2,
  consts.path.Website.Terms.BrandInsights,
  consts.path.Website.PrivacyPolicy.Root,
  consts.path.Website.PrivacyPolicy.Root2,
];

const excludeFooter = [
  consts.path.Website.ProductResearch,
  consts.path.Website.SnowflakeReport,
  consts.path.Website.ForgotPassword,
  consts.path.Website.ReferralSignup,
  consts.path.Website.ResetPassword,
  consts.path.Website.SurveyBestPractices,
];

export const paths = {
  auth,
  excludeFooter,
  header,
};

export const routes: Routing.Route[] = [
  {
    component: Login.Enterprise,
    exact: true,
    path: `${consts.path.Website.EnterpriseLogin}/:identity?`,
  },
  {
    component: LoginPlatform,
    exact: true,
    path: consts.path.Website.PlatformLogin,
  },
  {
    component: SignupPlatform,
    exact: true,
    path: consts.path.Website.PlatformSignup,
  },
  {
    component: Login.Visitor,
    exact: true,
    path: consts.path.Website.Login,
  },
  {
    component: Router.Signup,
    exact: true,
    path: [
      consts.path.Website.ReferralSignup,
      `${consts.path.Website.ReferralSignup}/:referral?`,
    ],
  },
  {
    component: Router.Signup,
    path: consts.path.Website.Signup.Root,
  },
  {
    exact: true,
    component: About,
    path: consts.path.Website.About,
  },
  {
    exact: true,
    component: FAQ,
    path: consts.path.Website.FAQ,
  },
  {
    exact: true,
    component: Contact,
    path: consts.path.Website.Contact,
  },
  {
    exact: true,
    component: ContactUs,
    path: consts.path.Website.ContactUs,
  },
  {
    exact: true,
    component: Login.Visitor,
    path: consts.path.Website.Root,
  },
  {
    exact: true,
    component: SentimentTerms,
    path: [
      consts.path.Website.Terms.Root,
      consts.path.Website.Terms.Root2,
    ],
  },
  {
    exact: true,
    component: BrandInsightsTerms,
    path: [
      consts.path.Website.Terms.BrandInsights,
    ],
  },
  {
    exact: true,
    component: PrivacyPolicy,
    path: [
      consts.path.Website.PrivacyPolicy.Root,
      consts.path.Website.PrivacyPolicy.Root2,
    ],
  },
  {
    exact: true,
    component: SnowflakeReportContainer,
    path: consts.path.Website.SnowflakeReport,
  },
  {
    exact: true,
    component: ProductResearchGuideContainer,
    path: consts.path.Website.ProductResearch,
  },
  {
    exact: true,
    component: SurveyBestPracticesContainer,
    path: consts.path.Website.SurveyBestPractices,
  },
  {
    component: Compliance,
    exact: true,
    path: consts.path.Website.Compliance,
  },
  {
    component: Careers,
    exact: true,
    path: consts.path.Website.Careers,
  },
  {
    component: CollaborationTools,
    exact: true,
    path: consts.path.Website.CollaborationTools,
  },
  {
    component: CustomerResearch,
    exact: true,
    path: [
      consts.path.Website.CustomerResearch,
      consts.path.Website.Consulting,
      consts.path.Website.Corporate,
      consts.path.Website.Enterprise,
      consts.path.Website.Individuals,
      consts.path.Website.Investment,
    ],
  },
  {
    component: Pricing,
    exact: true,
    path: consts.path.Website.Pricing,
  },
  {
    component: Quant2Qual,
    exact: true,
    path: consts.path.Website.Quant2Qual,
  },
  {
    component: ForgotPassword,
    exact: true,
    path: consts.path.Website.ForgotPassword,
  },
  {
    component: ForgotPasswordPlatform,
    exact: true,
    path: consts.path.Website.PlatformForgotPassword,
  },
  {
    component: ResetPassword,
    exact: true,
    path: consts.path.Website.ResetPassword,
  },
  {
    component: ResetPasswordPlatform,
    exact: true,
    path: consts.path.Website.PlatformResetPassword,
  },
];