import { useMemo } from 'react';
import { Modal } from '../Modal';
import { getPermissionErrorType } from './utils';
import { PermissionError } from './interfaces';
import { PermissionDenied } from './PermissionDenied';
import { DeviceInUse } from './DeviceInUse';
import { SystemDenied } from './SystemDenied';
import { GenericError } from './GenericError';
import { ErrorDetails } from './ErrorDetails';
import styles from './styles/ErrorModal.css';

type ErrorProps = {
  error: Error;
  onClose: () => void;
  errorType?: PermissionError; //For when you want to override what type of screen to show the user
};

export const DeviceErrorModal = (props: ErrorProps) => {
  const HelpScreen = useMemo(() => {
    if (!props.error) return null;
    const errorType = props.errorType ?? getPermissionErrorType(props.error);
    switch (errorType) {
      case PermissionError.DeviceInUse:
        return DeviceInUse;
      case PermissionError.PermissionDenied:
        return PermissionDenied;
      case PermissionError.SystemDenied:
        return SystemDenied;

      default:
        return GenericError;
    }
  }, [props.error, props.errorType]);

  if (!props.error) return null;

  return (
    <Modal open={!!props.error} onClose={props.onClose}>
      <div className={styles.root}>
        <HelpScreen />
        <ErrorDetails error={props.error} />
      </div>
    </Modal>
  );
};