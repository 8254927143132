import { useRef, forwardRef, memo, useCallback } from 'react';
import { AudioTrack, LocalAudioTrack, RemoteAudioTrack } from 'twilio-video';
import MicOff from '@mui/icons-material/MicOff';
import { useIsTrackEnabled } from './hooks/useIsTrackEnabled';
import { useAudioLevel } from './hooks/useAudioLevel';
import styles from './style/AudioLevelIndicator.css';

let clipId = 0;
const getUniqueClipId = () => clipId++;

type Props = {
  background?: string;
  icon: 'phone' | 'web';
  audioTrack?: AudioTrack;
  size?: number;
};

export const AudioLevelIndicator = memo(({ background, icon, audioTrack, size = 24 }: Props) => {
  const SVGRectRef = useRef<SVGRectElement>(null);
  const isTrackEnabled = useIsTrackEnabled(audioTrack as LocalAudioTrack | RemoteAudioTrack);

  const onLevelChange = useCallback((volume: number) => {
    if (SVGRectRef.current) {
      SVGRectRef.current?.setAttribute('y', String(21 - volume));
    }
  }, []);

  useAudioLevel({ audioTrack, onLevelChange });

  const clipPathId = `al-clip-${getUniqueClipId()}`;

  return isTrackEnabled
    ? icon === 'web'
      ? <WebSvg
        ref={SVGRectRef}
        background={background}
        clipPathId={clipPathId}
        size={size} />
      : <PhoneSvg
        ref={SVGRectRef}
        background={background}
        clipPathId={clipPathId}
        size={size} />
    : <OffSvg
      icon={icon}
      size={size} />;
});

type SvgProps = {
  background?: string;
  clipPathId: string;
  size: number;
};

const WebSvg = memo(forwardRef<SVGRectElement, SvgProps>(({ background = 'rgba(255, 255, 255, 0.1)', clipPathId, size }, ref) => (
  <svg
    focusable="false"
    viewBox="0 0 24 24"
    aria-hidden="true"
    height={`${size}px`}
    width={`${size}px`}>
    <defs>
      <clipPath id={clipPathId}>
        <rect
          ref={ref}
          x="0"
          y="21"
          width="24"
          height="24" />
      </clipPath>
    </defs>
    <path
      fill={background}
      d="M12 14c1.66 0 2.99-1.34 2.99-3L15 5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm5.3-3c0 3-2.54 5.1-5.3 5.1S6.7 14 6.7 11H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7z" />
    <path
      fill="#0c0"
      clipPath={`url(#${clipPathId})`}
      d="M12 14c1.66 0 2.99-1.34 2.99-3L15 5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm5.3-3c0 3-2.54 5.1-5.3 5.1S6.7 14 6.7 11H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7z" />
  </svg>
)));

const PhoneSvg = memo(forwardRef<SVGRectElement, SvgProps>(({ background = 'rgba(255, 255, 255, 0.1)', clipPathId, size }, ref) => (
  <svg
    focusable="false"
    viewBox="0 0 24 24"
    aria-hidden="true"
    height={`${size}px`}
    width={`${size}px`}>
    <defs>
      <clipPath id={clipPathId}>
        <rect
          ref={ref}
          x="0"
          y="21"
          width="24"
          height="24" />
      </clipPath>
    </defs>
    <path
      fill={background}
      d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z" />
    <path
      fill="#0c0"
      clipPath={`url(#${clipPathId})`}
      d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z" />
  </svg>
)));

type OffSvgProps = {
  icon: Props['icon'];
  size: number;
};

const OffSvg = memo(({ icon, size }: OffSvgProps) => {
  if (icon !== 'web') return null;
  return (
    <div className={styles.off}>
      <MicOff
        height={`${size}px`}
        width={`${size}px`}
        style={{ width: 'initial', height: 'initial' }} />
    </div>
  );
});

export default AudioLevelIndicator;