import type { ChangeEvent } from 'react';
import { useContext, useMemo, useState, useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Check } from 'react-feather';
import * as api from '@api';
import { TranscriptIdContext, EditingToolPaneContext, TranscriptDocumentContext, RefetchTranscriptContext, TranscriptSpeakersContext } from '@containers/Transcript/context';
import { CallRole } from '@enums';
import { ButtonActivityIndicator, ButtonOutlined } from '@presentation';
import type { TranscribeRT } from '@/types';
import { Tooltip } from '@/presentation/Tooltip';
import type { SpeakerMap } from '@/types/transcribe.processed';
import type { SelectChangeEvent } from '$admin/components/Select';
import { Select } from '$admin/components/Select';
import styles from './style/EditSpeakers.css';

type Props = unknown;

export const EditSpeakers = (props: Props) => {

  const refetch = useContext(RefetchTranscriptContext);
  const ctx = useContext(TranscriptDocumentContext);
  const map = useContext(TranscriptSpeakersContext);
  const pane = useContext(EditingToolPaneContext);
  const transcriptId = useContext(TranscriptIdContext);

  const [speakers, setSpeakers] = useState(map);

  const valid = useMemo(() => {
    return isSpeakerMapValid(speakers);
  }, [speakers]);

  const keys = useMemo(() => {
    const spks = ctx.document.content
      .filter(x => x.type === 'md')
      .map((x: TranscribeRT.MonologueDetails.Node) => x.attrs?.spk);

    const unique = Array.from(new Set(spks));

    return [...unique].sort((a, b) => a - b);
  }, [ctx.document]);

  const mutation = useMutation({
    mutationKey: [
      `put:transcripts/speakers`,
      transcriptId,
    ],
    mutationFn: () => {
      return api.transcripts.updateTranscriptSpeakers({
        transcriptId,
        speakers,
      }).then(() => refetch());
    },
    onSuccess: () => {
      pane.reset();
    },
  });

  const handleSave = useCallback(() => {
    return mutation.mutateAsync();
  }, [mutation]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          {keys.map((i, ii) =>
            <div
              className={styles.row}
              key={`speaker-${+ii + 1}`}>
              <div className={styles.label}>{`Speaker ${+ii + 1}`} <SpeakerTooltip speaker={speakers[+i]} /></div>
              <div className={styles.field}>

                <div className={styles.outline}>
                  <input
                    className={styles.input}
                    onChange={e => setSpeakers(state => ({
                      ...state,
                      [i]: {
                        ...state[i],
                        name: e.target.value,
                      },
                    }))}
                    placeholder={`Speaker ${+ii + 1}`}
                    value={speakers[+i]?.name || ''} />
                </div>

                <Select
                  className={styles.select}
                  onChange={e => setSpeakers(state => ({
                    ...state,
                    [i]: {
                      ...state[i],
                      role: e.target.value,
                    },
                  }))}
                  options={options}
                  placeholder="Select role"
                  value={speakers[+i]?.role || ''} />
              </div>
            </div>)}
        </div>

        <div className={styles.actions}>
          <ButtonOutlined
            className={styles.btn}
            color="silver"
            disabled={mutation.isLoading}
            onClick={pane.speakers.toggle}
            size="small">
            Cancel
          </ButtonOutlined>

          <ButtonActivityIndicator
            className={styles.btn}
            disabled={!valid}
            loading={mutation.isLoading}
            implicitDisable={false}
            indicatorSize={10}
            onClick={handleSave}
            size="small"
            variant="brick">
            Save
          </ButtonActivityIndicator>
        </div>
      </div>
    </div>
  );
};

EditSpeakers.displayName = 'EditSpeakers';

const options = [{
  id: CallRole.Scheduler,
  name: `Moderator`,
}, {
  id: CallRole.Respondent,
  name: `Respondent`,
}];

type TooltipProps = {
  speaker: SpeakerMap[number];
};
const SpeakerTooltip = ({ speaker }: TooltipProps) => {
  const TooltipText = useCallback(() => {
    return (
      <div>
        <div>
          User Type: {speaker.user?.type}
        </div>
        <div>
          User ID: {speaker.user?.id}
        </div>
        <div>
          Confidence: {speaker.context.automatchConfidence}
        </div>
      </div>
    );
  }, [speaker]);

  if (!speaker?.context?.automatchConfidence) return null;

  return (
    <Tooltip title={<TooltipText />}>
      <Check className={styles.tooltipIcon} />
    </Tooltip>
  );
};

function isSpeakerMapValid(speakers: SpeakerMap) {
  return Object.values(speakers).every(s => !s.name || s.name?.trim()?.length > 0);
}