import { } from 'react';
import { utils as $enumUtils } from '@enums';
import { ProjecytSurveyTermination } from '@/types';
import { ConditionsShorthand, ConditionsPopper } from '@/screens/SurveyMonitoring/ConditionsPopover';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import { Header } from '@/components/Modal/Header';
import { useModal } from '@/components/Modal/hooks';
import styles from './styles/TerminationModal.css';

type Props = {
  termination: ProjecytSurveyTermination;
} & Pick<ModalProps, 'open' | 'onClose'>;

export const TerminationModal = ({ open, onClose, termination }: Props) => {

  return (
    <Modal
      open={open}
      onClose={onClose}>
      <Header text="Response Termination" />
      <div>
        <div className={styles.row}>
          <div className={styles.label}>Action Type:</div> {$enumUtils.SurveyActionType.getName(termination.actionType)}
        </div>
        <div className={styles.row}>
          <div className={styles.label}>Condition Match</div>
        </div>
        <ConditionsPopper
          items={termination.match.conditions}
          rule={termination.match.rule} />

      </div>
    </Modal>
  );
};

export const useTerminationModal = () => useModal(TerminationModal);