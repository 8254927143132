import { useMemo } from 'react';
import { usePersistentProjectInvite } from '@containers/ProjectLinkInvitation/hooks/usePersistentProjectInvite';

export const useProjectAudienceType = () => {
  const urlstate = usePersistentProjectInvite();

  return useMemo(() => {
    const state = urlstate.get();

    return state?.data?.project?.audienceType;
  }, [urlstate]);
};