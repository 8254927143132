import { useCallback, useEffect, useState } from 'react';
import { MembersSelectedContext } from '../Context';
import { Members } from '../interfaces';
import { useFilters } from './hooks/useFilters';

type Props = ChildrenProps;

export function StateContainerSelected({ children }: Props) {
  const [filters] = useFilters();

  const [selected, setSelected] = useState<Members.Selected.State>({
    ids: new Set(),
    items: [],
  });

  const clearSelected = useCallback(() => {
    if (selected.ids.size === 0) return;

    setSelected({
      ids: new Set(),
      items: [],
    });
  }, [selected]);

  useEffect(() => {
    clearSelected();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.active]);

  return (
    <MembersSelectedContext.Provider value={[selected, setSelected, clearSelected]}>
      {children}
    </MembersSelectedContext.Provider>
  );
}