import { useCallback, useEffect, useMemo, useState } from 'react';
import { maxLengths } from '@consts';
import { EmploymentForm, EmploymentRecord } from '@/types';
import { ProfileEntry } from '@enums';
import { ProfileEmploymentForm } from './Profile.Employment.Form';
import { Section } from './interfaces';
import { generateDuring } from './Profile.DateRange.Form';
import { validateEmployment } from './Profile.Employment.Form.validation';
import { ProfileEmploymentFormContext } from './Context';
import { useProfileFormValidation } from './hooks';

type Props =
  Section.MultiEntry.FormProps<ProfileEntry.Employment>;

const ProfileEmploymentFormContainer = ({ onSave, ...props }: Props) => {
  const [item, setItem] = useState<EmploymentForm>(transformItem(props.value) || createItem());
  const [validations, runValidations] = useProfileFormValidation<EmploymentForm>({
    validate: validateEmployment,
  });

  const save = useCallback(() => {
    const { startMonth, startYear, endMonth, endYear, ...rest } = item;

    const during = generateDuring(item);
    return onSave({
      duration: {
        startDate: during[0],
        endDate: during[1],
      },
      ...rest,
    });
  }, [onSave, item]);

  const handleSubmit = useCallback(() => {
    const passed = runValidations(item, true);

    if (passed) {
      return save();
    }
  }, [
    item,
    runValidations,
    save,
  ]);

  useEffect(() => {
    runValidations(item, false);
  }, [item, runValidations]);

  const canSave = useMemo(() => {
    return item.description.length <= maxLengths.EMPLOYMENT_DESCRIPTION &&
      item.company?.name &&
      item.title?.name &&
      !!item.startYear;
  }, [
    item,
  ]);

  const value = {
    canSave,
    item,
    onCancel: props.onCancel,
    onDelete: props.onDelete,
    onSubmit: handleSubmit,
    setItem,
    validations,
  };

  return (
    <ProfileEmploymentFormContext.Provider value={value}>
      <ProfileEmploymentForm />
    </ProfileEmploymentFormContext.Provider>
  );
};

function transformItem(item: EmploymentRecord): EmploymentForm {
  if (!item) return null;

  return {
    id: item.id,
    company: {
      id: item.companyId,
      name: item.company,
    },
    functions: item.functions,
    location: {
      id: item.locationId,
      name: item.location,
    },
    title: {
      id: item.titleId,
      name: item.title,
    },
    description: item.description || '',
    startMonth: item.startMonth,
    startYear: item.startYear,
    endMonth: item.endMonth,
    endYear: item.endYear,
  };
}

function createItem(): EmploymentForm {
  return {
    id: null,
    company: null,
    functions: [],
    location: null,
    title: null,
    description: '',
    startMonth: new Date().getMonth() + 1,
    startYear: new Date().getFullYear() - 1,
    endMonth: new Date().getMonth() + 1,
    endYear: new Date().getFullYear(),
  };
}

export { ProfileEmploymentFormContainer };
export default ProfileEmploymentFormContainer;