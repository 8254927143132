import { useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { ChatContextType } from '@/brand-insights/enums';
import { ActiveChatSessionContext, ChatSessionFiltersContext } from '@/brand-insights/components/Chat/context';
import { ChatStateDispatchContext } from '@/brand-insights/components/Chat.State';
import { generateEmptyChatSessionFiltersState, getChatSourceFilterOptions } from '@/brand-insights/components/Chat.State/state.initial-state';
import { Project } from './Project';
import { Source } from './Source';
import { PublishedDate } from './PublishedDate';
import { Tag } from './Tag';

type Props = {
  active: boolean;
};

export function FilterPane(props: Props) {
  return (
    <Root>
      <Header>
        <HeaderLabel>Source Filters</HeaderLabel>
        <ResetController {...props} />
      </Header>
      <Filters>
        <PublishedDateController />
        <ProjectController />
        <TagController />
        <SourceController />
      </Filters>
    </Root>
  );
}

function SourceController() {
  const filters = useContext(ChatSessionFiltersContext);
  const dispatch = useContext(ChatStateDispatchContext);
  const { context } = useContext(ActiveChatSessionContext);

  const options = useMemo(() => getChatSourceFilterOptions({ context }), [context]);

  if (!options.length) return null;

  return (
    <Source
      options={options}
      value={filters.sources}
      onChange={sources => {
        dispatch({
          type: 'filters/updated',
          payload: {
            filters: {
              sources: sources.length ? sources : options,
            },
          },
        });
      }} />
  );
}

function PublishedDateController() {
  const filters = useContext(ChatSessionFiltersContext);
  const dispatch = useContext(ChatStateDispatchContext);
  const { context } = useContext(ActiveChatSessionContext);

  const defaultValue = useMemo(() => generateEmptyChatSessionFiltersState({ context }).minDocumentDate, [context]);

  return (
    <PublishedDate
      defaultValue={defaultValue}
      value={filters.minDocumentDate}
      onChange={minDocumentDate => {
        dispatch({
          type: 'filters/updated',
          payload: {
            filters: {
              minDocumentDate,
            },
          },
        });
      }} />
  );
}

function ProjectController() {
  const filters = useContext(ChatSessionFiltersContext);
  const dispatch = useContext(ChatStateDispatchContext);
  const { context } = useContext(ActiveChatSessionContext);

  const enabled = useMemo(() => [ChatContextType.Brand, ChatContextType.Folder, ChatContextType.Global].includes(context.type), [context]);

  const value = useMemo(() => {
    if (context.type === ChatContextType.Project) {
      return [{ id: context.data.projectId, name: context.metadata.name }];
    }

    return filters.projects;
  }, [context, filters.projects]);

  return (
    <Project
      disabled={!enabled}
      context={context}
      value={value}
      onChange={projects => {
        dispatch({
          type: 'filters/updated',
          payload: {
            filters: {
              projects,
            },
          },
        });
      }} />
  );
}

function TagController() {
  const filters = useContext(ChatSessionFiltersContext);
  const dispatch = useContext(ChatStateDispatchContext);

  return (
    <Tag
      value={filters.tags}
      onChange={tags => {
        dispatch({
          type: 'filters/updated',
          payload: {
            filters: {
              tags,
            },
          },
        });
      }} />
  );
}

function ResetController({ active }: Props) {
  const dispatch = useContext(ChatStateDispatchContext);

  return (
    <HeaderReset
      disabled={!active}
      onClick={() => dispatch({ type: 'filters/reset' })}>
      Reset
    </HeaderReset>
  );
}

const Root = styled.div(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  borderRadius: '5px',
  border: `1px solid ${theme.palette.gray.light1}`,
  boxShadow: '0 2px 6px 2px rgba(60, 64, 67, .15)',
  boxSizing: 'border-box',
  padding: '12px',
  minWidth: '200px',
  maxWidth: '600px',
}));

const Header = styled.div({
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '10px',
});

const HeaderLabel = styled.h1(({ theme }) => ({
  fontSize: '18px',
  fontFamily: theme.fonts.semiBold,
}));

const HeaderReset = styled.button(({ disabled, theme }) => ({
  color: !disabled ? theme.palette.text.primary : theme.palette.gray.light1,
  fontSize: '14px',
  transition: 'text-decoration 150ms, color 150ms',

  ':hover': {
    textDecoration: disabled ? 'underline' : 'none',
  },
}));

const Filters = styled.div({
  boxSizing: 'border-box',
  padding: '0 2px',
});