import {
  command,
  extension,
  getTextSelection,
  ApplySchemaAttributes,
  CommandFunction,
  ExtensionTag,
  NodeExtension,
  NodeExtensionSpec,
  NodeSpecOverride,
  PrimitiveSelection,
} from '@remirror/core';
import { SurveyLogicExpression, SurveyLogicExpressionNode } from '@/types/survey.logic.expression';
import styles from './style/Expression.Extension.css';

@extension({
  defaultOptions: {},
})
export class LogicalOperatorExtension extends NodeExtension {

  get name() {
    return SurveyLogicExpressionNode.LogicalOperator;
  }

  createTags() {
    return [ExtensionTag.InlineNode];
  }

  @command()
  insertLogicalOperator(operator: SurveyLogicExpression.LogicalOperator): CommandFunction {
    return ({ tr, dispatch }) => {
      const node = this.type.create({ operator });
      const { from, to } = getTextSelection(tr.selection, tr.doc);

      dispatch?.(tr.replaceRangeWith(from, to, node));

      return true;
    };
  }

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    return {
      ...override,
      atom: true,
      attrs: {
        operator: {},
        ...extra.defaults(),
      },
      inline: true,
      draggable: false,
      toDOM: node => ['span', {
        ...extra.dom(node),
        class: styles.operator,
        contentEditable: 'false',
        // selectable: 'false',
      }, node.attrs.operator],
    };
  }
}
