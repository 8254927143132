import { useCallback, useMemo } from 'react';
import cuid from 'cuid';
import { SurveyLogic } from '@/types/survey';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { SurveyActionType, SurveyConditionType, SurveyLogicRule, SurveyQuestionConditionType, SurveyQuestionType } from '@/enums';
import { ContextMenuItemProps } from '@/components/SurveyBuilder/ContextMenu.Item';

type Props = {
  option: {
    base: {
      identifier: string;
    };
  };
  question: {
    base: {
      identifier: string;
    };
  };
};

export const useDisqualifyOptionItem = (props: Props) => {
  const [state, dispatch] = useSurveyBuilderState();

  const findLogicItems = useCallback((conditionType: SurveyQuestionConditionType.OptionNotSelected | SurveyQuestionConditionType.OptionSelected) => {
    return state.survey.logic.filter(f => {
      if (f.conditions.length > 1) return false;
      const condition = f.conditions[0];
      return condition.conditionType === SurveyConditionType.Question &&
        condition.data.type === conditionType &&
        condition.data.question.identifier === props.question.base.identifier &&
        condition.data.value.option.identifier === props.option.base.identifier;
    });
  }, [
    state.survey.logic,
    props.question.base.identifier,
    props.option.base.identifier,
  ]);

  const notSelectedItems = findLogicItems(SurveyQuestionConditionType.OptionNotSelected);
  const selectedItems = findLogicItems(SurveyQuestionConditionType.OptionSelected);

  const notSelectedDisqualifyingLogic = useMemo(() => {
    return notSelectedItems.find(s => s.action.type === SurveyActionType.Disqualification);
  }, [notSelectedItems]);

  const selectedDisqualifyingLogic = useMemo(() => {
    return selectedItems.find(s => s.action.type === SurveyActionType.Disqualification);
  }, [selectedItems]);

  const handleRemoveLogicItem = useCallback((logicItemIdentifier: string) => () => {
    dispatch({
      type: 'logic-item-removed',
      payload: {
        identifier: logicItemIdentifier,
      },
    });
  }, [dispatch]);

  const handleAddDisqualifying = useCallback((conditionType: SurveyQuestionConditionType.OptionNotSelected | SurveyQuestionConditionType.OptionSelected) => () => {
    const condition: SurveyLogic.QuestionCondition = {
      conditionType: SurveyConditionType.Question,
      data: {
        question: {
          identifier: props.question.base.identifier,
        },
        type: conditionType,
        value: {
          option: {
            identifier: props.option.base.identifier,
          },
        },
      },
      identifier: cuid(),
    };

    dispatch({
      type: 'logic-item-added',
      payload: {
        item: {
          action: {
            type: SurveyActionType.Disqualification,
          },
          conditions: [condition],
          metadata: {
            canDelete: true,
            canEdit: true,
          },
          id: null,
          identifier: cuid(),
          rule: {
            expression: null,
            type: SurveyLogicRule.AllOfTheAbove,
          },
        },
      },
    });
  }, [
    dispatch,
    props.question.base.identifier,
    props.option.base.identifier,
  ]);

  const selectedDQItem: ContextMenuItemProps = useMemo(() => {
    const isDisqualifying = !!selectedDisqualifyingLogic;
    return {
      checked: isDisqualifying,
      children: 'Disqualify if selected',
      onClick: isDisqualifying ? handleRemoveLogicItem(selectedDisqualifyingLogic.identifier) : handleAddDisqualifying(SurveyQuestionConditionType.OptionSelected),
    };
  }, [selectedDisqualifyingLogic, handleAddDisqualifying, handleRemoveLogicItem]);

  const notSelectedDQItem: ContextMenuItemProps = useMemo(() => {
    const isDisqualifying = !!notSelectedDisqualifyingLogic;
    return {
      checked: isDisqualifying,
      children: 'Disqualify if not selected',
      onClick: isDisqualifying ? handleRemoveLogicItem(notSelectedDisqualifyingLogic.identifier) : handleAddDisqualifying(SurveyQuestionConditionType.OptionNotSelected),
    };
  }, [notSelectedDisqualifyingLogic, handleAddDisqualifying, handleRemoveLogicItem]);

  return [
    selectedDQItem,
    notSelectedDQItem,
  ];
};

