import { useCallback, useState } from 'react';
import { cx } from '@utils';
import { useInterval } from '@utils';
import surveys1 from 'static/images/website/customer-research/screencap-survey-mobile.svg?url';
import surveys2 from 'static/images/website/customer-research/screencap-survey-tablet.svg?url';
import surveys3 from 'static/images/website/customer-research/screencap-survey-desktop.svg?url';
import conferencing1 from 'static/images/website/customer-research/screencap-conferencing-mobile.svg?url';
import conferencing2 from 'static/images/website/customer-research/screencap-conferencing-tablet.svg?url';
import conferencing3 from 'static/images/website/customer-research/screencap-conferencing-desktop.svg?url';
import messaging1 from 'static/images/website/customer-research/screencap-messaging-mobile.svg?url';
import messaging2 from 'static/images/website/customer-research/screencap-messaging-tablet.svg?url';
import messaging3 from 'static/images/website/customer-research/screencap-messaging-desktop.svg?url';
import styles from './style/Feedback.css';

type Props = unknown;

export const Feedback = (props: Props) => {
  const [index, setIndex] = useState(0);

  const cycle = useCallback(() => {
    const nextIndex = index === items.length - 1
      ? 0
      : index + 1;

    setIndex(nextIndex);
  }, [
    index,
    setIndex,
  ]);

  useInterval(cycle, 12000);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <h2 className={styles.title}>
            Build better products
          </h2>
          <div className={styles.subheader}>
            Our quantitative and qualitative tools interact seamlessly with one another and can be accessed by your entire team
          </div>
        </div>
        <div className={styles.main}>
          <div className={styles.tabs}>
            {items.map((x, i) =>
              <div
                className={cx(styles.tab, { [styles.active]: i === index })}
                key={x.title}>
                <div
                  className={cx(styles.name)}
                  onClick={() => setIndex(i)}>
                  {x.title}
                </div>
                <div className={styles.underline} />
                <div className={styles.description}>{x.text}</div>
              </div>)}
          </div>
          {items.map((x, i) =>
            <div
              className={cx(styles.panel, { [styles.active]: i === index })}
              key={x.title}>
              <div className={styles.media}>
                <picture>
                  <source
                    media="(min-width: 1000px)"
                    srcSet={x.srcset.l} />
                  <source
                    media="(min-width: 800px)"
                    srcSet={x.srcset.m} />
                  <img
                    alt={x.title.toLowerCase()}
                    className={cx(styles.img, styles.l)}
                    src={x.srcset.s} />
                </picture>
              </div>
              <div className={styles.subtitle}>{x.text}</div>
            </div>)}
        </div>
      </div>
    </div>
  );
};

Feedback.displayName = 'CustomerResearch.Feedback';

const items = [{
  title: `Surveys`,
  text: `Collect pools of survey data and click through your survey to engage with respondents 1x1.`,
  srcset: {
    s: surveys1,
    m: surveys2,
    l: surveys3,
  },
}, {
  title: `Interviews`,
  text: `Interview potential buyers and users over the phone or a screen share and add them to your projects for later.`,
  srcset: {
    s: conferencing1,
    m: conferencing2,
    l: conferencing3,
  },
}, {
  title: `Messaging`,
  text: `Message anyone over our system for quick feedback.`,
  srcset: {
    s: messaging1,
    m: messaging2,
    l: messaging3,
  },
}];