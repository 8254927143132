import qs from 'query-string';
import http from '@services/http';
import * as xform from '@transformers';
import { Projects } from './interfaces';

export const findOrCreateProjectLink = (data: Projects.Links.FindOrCreateProjectLink.Request) => {
  return http.post<Projects.Links.FindOrCreateProjectLink.Response>(`/admin/projects/${data.projectId}/links/users/${data.userId}`, data)
  .then((res: Omit<Projects.Links.FindOrCreateProjectLink.Response, 'url'>) => ({
    link: xform.assignProjectLinkUrl(res.link),
  }));
};

export const fetchCreatorProjectLinks = (data: Projects.Links.FetchCreatorProjectLinks.Request) => {
  return http.get<Projects.Links.FetchCreatorProjectLinks.Response>(`/admin/projects/${data.projectId}/links/users/${data.userId}`)
  .then((res: { items: Omit<Projects.Links.FetchCreatorProjectLinks.Response['items'][number], 'url'>[] }) => ({
    items: res.items.map(xform.assignProjectLinkUrl),
  }));
};

export const getCustomSearchLegacy = ({ projectId, userId, ...rest }: Projects.Links.GetCustomSearchLegacy.Request) => {
  return http.get<Projects.Links.GetCustomSearchLegacy.Response>(`/admin/projects/${projectId}/link/${userId}?${qs.stringify(rest)}`)
  .then(xform.assignProjectLinkUrl);
};