export enum RouteKey {
  Details = 'details',
  Files   = 'files',
}

export type SceneMap = {
  [key in RouteKey]: TabScene;
};

export type RouteParams = {
  fileId: string;
  tab?: RouteKey;
};

export type TabScene = {
  component: React.ComponentType;
  path:      string;
  title:     string | React.ReactNode;
};