import { useEffect, useCallback, useRef, createElement, useState } from 'react';
import { Key } from 'ts-key-enum';

type Props = {
  excludeElement: HTMLElement;
  blockedClassName?: string;
};

type ExcludeElement = HTMLElement | {
  element: HTMLElement;
  onBlockedClass: string;
};

type NewProps = {
  excludeElements: ExcludeElement[];
  onUnBlock: () => void;
};

const BLOCKING_Z_INDEX = 999;

export const useBlockPage = ({ excludeElements, onUnBlock }: NewProps) => {
  const [overlayElement, setOverlayElement] = useState<HTMLDivElement>();

  const block = useCallback(() => {
    if (overlayElement) return;

    const blockingElement = document.createElement('div');
    setElementStyles(blockingElement, {
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: BLOCKING_Z_INDEX.toString(),
    });
    setOverlayElement(document.body.appendChild(blockingElement));

    if (excludeElements) {
      for (const e of excludeElements) {
        if (e instanceof HTMLElement) {
          e.style.zIndex = (BLOCKING_Z_INDEX + 1).toString();
        } else {
          e.element.style.zIndex = (BLOCKING_Z_INDEX + 1).toString();
          e.element.classList.add(e.onBlockedClass);
        }
      }
    }

  }, [excludeElements, overlayElement, setOverlayElement]);

  const unBlock = useCallback(() => {
    if (overlayElement) {
      overlayElement.remove();
      setOverlayElement(null);

      if (excludeElements) {
        for (const e of excludeElements) {
          if (e instanceof HTMLElement) {
            e.style.zIndex = null;
          } else {
            e.element.style.zIndex = null;
            e.element.classList.remove(e.onBlockedClass);
          }
        }
      }

      onUnBlock();
    }
  }, [excludeElements, overlayElement, setOverlayElement, onUnBlock]);

  //make sure to clean up the block if the hook becomes unmounted
  useEffect(() => {
    return unBlock;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (overlayElement) {
      const onKeyPress = (e: KeyboardEvent) => {
        //TODO: might need to check that we aren't in an input element for future cases
        if (e.key === Key.Escape) {
          unBlock();
        }
      };
      overlayElement.addEventListener('click', unBlock);
      window.document.addEventListener('keyup', onKeyPress);

      return () => {
        overlayElement.removeEventListener('click', unBlock);
        window.document.removeEventListener('keyup', onKeyPress);
      };
    }
  }, [overlayElement, unBlock]);

  return { block, unBlock };
};

function setElementStyles(el: HTMLElement, styles: Partial<CSSStyleDeclaration>) {
  Object.entries(styles).forEach(([k, v]) => {
    el.style[k] = v;
  });
}