import { useMemo } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import { pipingItemTypeMap } from '@/containers/SurveyBuilder/utils';
import { useQuestionOrdinalCutoff } from './useQuestionOrdinalCutoff';

type Options = {
  activeQuestionOrdinal?: number;
};

export const useEligiblePipingItems = (opts: Options = {}) => {
  const [state] = useSurveyBuilderState();

  const questionOrdinalCutoff = useQuestionOrdinalCutoff(opts);

  return useMemo(() => {
    return state.survey.items
      .filter(f => f.ordinal <= questionOrdinalCutoff && !!pipingItemTypeMap[f.type].length);
  }, [
    questionOrdinalCutoff,
    state.survey.items,
  ]);
};