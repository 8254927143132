import { Fragment, useCallback, useMemo } from 'react';
import { useRepeatQuestionResponsesData, useRepeatSurveyResponsesState } from '@containers/RepeatSurveyResponses/hooks';
import * as chart from '@containers/SurveyResponses/utils';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import { RepeatSurvey } from '@containers/RepeatSurveyResponses/interfaces';
import { SurveyQuestionType } from '@enums';
import { ProjectVersionHeaderRow, ResponseOption } from '@presentation/ProjectSurveyResponses';
import { SurveyAggregate, SurveyQuestionOption } from '@/types';
import { ResponseOptionUsers } from '@/components/SurveyResponses/ResponseOptionUsers';
import { SimpleAccordion } from '@/components/Accordion';
import { useFilteredOptions, useProjectVersion, useQueryAccordionMap } from './hooks';
import styles from './style/Data.css';

export const OptionsVersionData = () => {
  const { query, resetQueryState } = useRepeatSurveyResponsesState<RepeatSurvey.QueryState.Options>();
  const { historical, question } = useRepeatQuestionResponsesData<SurveyQuestionType.MultipleChoice | SurveyQuestionType.Multiselect>();
  const options = useFilteredOptions();
  const [isAccordionOpen, toggleAccordion] = useQueryAccordionMap('optionIds');

  const version = useProjectVersion(query.projectId);

  const projectData = useMemo(() => historical[query.projectId], [historical, query.projectId]);

  const renderOption = useCallback((option: SurveyQuestionOption) => {
    const data = projectData.data[option.base.id];

    return (
      <SimpleAccordion
        open={isAccordionOpen(option.base.id)}
        toggleOpen={toggleAccordion(option.base.id)}
        grows={false}
        className={styles.row}
        key={option.ordinal}
        height={50}
        label={renderAccordionLabel(data, option)}>
        <ResponseOptionUsers
          userIds={data.userIds}
          text={data.text}
          option={option}
          rationaleMap={projectData.rationale}
          question={question} />
      </SimpleAccordion>
    );
  }, [
    projectData,
    isAccordionOpen,
    question,
    toggleAccordion,
  ]);

  return (
    <>
      <ProjectVersionHeaderRow
        className={styles.row}
        onClick={resetQueryState}
        version={version} />
      {options.map(option => (
        <Fragment key={option.base.id}>
          {renderOption(option)}
        </Fragment>
      ))}
    </>
  );
};

function renderAccordionLabel(data: SurveyAggregate.Options.Data, option: SurveyQuestionOption) {
  const color = chart.options.OptionsColors[option.ordinal - 1];
  const subtitle = `(${Math.round(data.pct)}%)`;
  const label = parseSurveyRichText(option.value);

  return (
    <ResponseOption
      color={color}
      label={label}
      subtitle={subtitle} />
  );
}

export default OptionsVersionData;
