import { path } from '@consts';
import { LinkLabeledIcon } from '$website/components';
import styles from './style/BannerPricing.css';

type Props = unknown;

export const BannerPricing = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.title}>
            Access over 60 thousand potential customers
          </div>
          <LinkLabeledIcon to={path.Website.Pricing}>
            View Pricing
          </LinkLabeledIcon>
        </div>
      </div>
    </div>
  );
};

BannerPricing.displayName = 'CustomerResearch.BannerPricing';