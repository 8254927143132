import { createContext } from 'react';
import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import type { ImportSingleExternalProjectLink } from '@api/interfaces/projects.external-survey';
import type { ExternalSurveyConfiguration } from '@/types';

export type DownloadExternalSurveyLinks = (data: IProjectId) => Promise<{
  blob: Blob;
  filename: string;
}>;
export type ExternalSurveyConfigMutations = {
  deleteProjectConfigMutation: UseMutationResult<unknown, unknown, void, unknown>;
  deleteUnassignedLinksMutation: UseMutationResult<unknown, unknown, void, unknown>;
  importLinksMutation: UseMutationResult<{ numInserted: number }, unknown, { file: File; fixed?: boolean }, unknown>;
  importSingleLinkMutation: UseMutationResult<ImportSingleExternalProjectLink.Response, unknown, string, unknown>;
  updateProjectConfigMutation: UseMutationResult<unknown, unknown, { fixedLinks: boolean }, unknown>;
};

export const DownloadExternalSurveyLinksContext = createContext<DownloadExternalSurveyLinks>(null);
export const ExternalSurveyConfigurationRefetchContext = createContext<() => Promise<unknown>>(null);
export const ExternalSurveyConfigurationContext = createContext<ExternalSurveyConfiguration>(null);
export const ExternalSurveyConfigurationMutationsContext = createContext<ExternalSurveyConfigMutations>(null);
export const ExternalSurveyConfigurationBootstrapContext = createContext<UseQueryResult<ExternalSurveyConfiguration, unknown>>(null);