import { memo } from 'react';
import { utils } from '@/enums';
import { cx } from '@/utils';
import type { Transcribe } from '@/types';
import { roleIsModeratorLike } from '@/components/Conference.Participants/utils';
import styles from './style/Monologue.css';

type SpeakerProps = {
  className?: string;
  displayName: string;
  speaker: Transcribe.Speaker;
};

export const Speaker = memo((props: SpeakerProps) => {
  const hasModeratorRole = roleIsModeratorLike(props.speaker?.role);
  const isModerator = hasModeratorRole && props.speaker?.name != 'Moderator';

  return (
    <div className={cx(styles.speaker, props.className)}>
      {props.displayName}
      {isModerator && (
        <span className={styles.speakerRole}>
          ({utils.CallRole.getName(props.speaker.role)})
        </span>
      )}
    </div>
  );
});