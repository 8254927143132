import { useContext, useMemo } from 'react';
import { DispatchTooltipContext } from '@/components/Tooltip/Context';

export const useTooltip = () => {
  const context = useContext(DispatchTooltipContext);
  const memoizedContext = useMemo(() => {
    return {
      showTooltipAt: context.showTooltipAt,
      showTooltipFromEvent: context.showTooltipFromEvent,
      hideTooltip: context.hideTooltip,
    };
  }, [context]);

  return memoizedContext;
};

export default useTooltip;