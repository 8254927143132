import { useState } from 'react';
import { TranscriptLockContext } from './Context';

type Props = {
  children: React.ReactNode;
};

export const TranscriptRedactionLockContainer = (props: Props) => {
  const [isLocked, setTranscriptLock] = useState(false);

  return (
    <TranscriptLockContext.Provider value={[isLocked, setTranscriptLock]}>
      {props.children}
    </TranscriptLockContext.Provider>
  );
};

TranscriptRedactionLockContainer.displayName = 'Transcript.Redaction.Lock.Container';