import { useContext } from 'react';
import { getTranscriptTaggedMomentDetails } from '@api/transcripts';
import { useQueryWrapper } from '@/utils';
import {
  TaggedMomentFormDispatchContext,
  TaggedMomentFormStateContext,
  TranscriptDocumentContext,
  TranscriptHighlightsContext,
  TranscriptSpeakersContext,
} from './context';
import type { TaggedMomentFormParams } from './interfaces.tagged-moment';
import { TranscriptTimestampPositionsContainer } from './Timestamps';
import { TaggedMomentRichTextContainer } from './TaggedMoment.Editor';

type Props = ChildrenProps & {
  params: TaggedMomentFormParams;
};

export const TaggedMomentFormContainer = (props: Props) => {

  const state = useContext(TaggedMomentFormStateContext);
  const dispatch = useContext(TaggedMomentFormDispatchContext);

  const query = useQueryWrapper({
    queryKey: ['transcript:tagged-moment-details', props.params],
    queryFn: ({ queryKey }) => {
      const params = queryKey[1] as TaggedMomentFormParams;
      return getTranscriptTaggedMomentDetails({
        isGoodQuote: params.isGoodQuote,
        fromTs: params.range?.start,
        toTs: params.range?.end,
        momentId: params.momentId,
        tagId: params.tagId,
        transcriptId: params.transcriptId,
      });
    },
    onSuccess: data => {
      dispatch({
        type: 'state-reset',
        payload: {
          value: {
            keyword: props.params.keyword,
            momentId: props.params.momentId,
            tag: data.tag,
            tags: data.tags,
            fromTs: props.params.momentId ? data.range.start : null,
            toTs: props.params.momentId ? data.range.end : null,
            transcript: data.transcript,
          },
        },
      });
    },
  });

  const initialHighlights = query.data?.highlights || [];

  if (query.isLoading || !query.data || !state.transcript) {
    return null;
  }

  return (
    <TranscriptSpeakersContext.Provider value={query.data?.speakers}>
      <TranscriptDocumentContext.Provider value={query.data?.content}>
        <TranscriptTimestampPositionsContainer>
          <TranscriptHighlightsContext.Provider value={initialHighlights}>
            <TaggedMomentRichTextContainer>
              {props.children}
            </TaggedMomentRichTextContainer>
          </TranscriptHighlightsContext.Provider>
        </TranscriptTimestampPositionsContainer>
      </TranscriptDocumentContext.Provider>
    </TranscriptSpeakersContext.Provider>
  );
};