import { useEffect, useRef, useContext } from 'react';
import { Track, IVideoTrack } from 'twilio-video';
import { cx } from '@utils';
import { ConferenceLiveRoomScreenContext } from '@containers/Conference/Context';
import { useMediaStreamTrack } from './hooks/useMediaStreamTrack';
import { useVideoTrackDimensions } from './hooks/useVideoTrackDimensions';
import styles from './style/VideoTrack.css';

type VideoTrackProps = {
  track: IVideoTrack;
  isLocal?: boolean;
  priority?: Track.Priority;
  isMain?: boolean;
};

export function VideoTrack({ track, isLocal, priority, isMain }: VideoTrackProps) {
  const liveRoomCtx = useContext(ConferenceLiveRoomScreenContext); //don't use the hook that checks the container, this might be called outside conference
  const ref = useRef<HTMLVideoElement>(null);
  const mediaStreamTrack = useMediaStreamTrack(track);
  const dimensions = useVideoTrackDimensions(track);
  const isPortrait = (dimensions?.height ?? 0) > (dimensions?.width ?? 0);

  useEffect(() => {
    const el = ref.current;
    el.muted = true;

    if (track.setPriority && priority) {
      track.setPriority(priority);
    }

    track.attach(el);

    return () => {
      track.detach(el);

      // https://github.com/twilio/twilio-video.js/issues/1528
      el.srcObject = null;

      if (track.setPriority && priority) {
        track.setPriority(null);
      }
    };
  }, [track, priority]);

  const isFrontFacing = track.mediaStreamTrack.getSettings().facingMode !== 'environment';
  const classname = cx(styles.video, { [styles.local]: isLocal && isFrontFacing });

  return (
    <div className={styles.root} ref={isMain && liveRoomCtx ? liveRoomCtx.setPreferredVideoRef : null}>
      <video
        ref={ref}
        className={classname} />
    </div>
  );

  // const style = useMemo(() => ({
  //   transform: isLocal && isFrontFacing ? 'rotateY(180deg)' : '',
  //   objectFit: 'contain' as const,
  // }), [isLocal, isFrontFacing]);

  // return (
  //   <video
  //     ref={ref}
  //     className={styles.root}
  //     style={style} />
  // );
}

export default VideoTrack;