import { SystemId } from '@consts/users';
import { cx, getLocationFor } from '@utils';
import { FeedItemComment } from '@/types/comments';
import { MemberBaseballCard } from '@/components/BaseballCard';
import { Button } from '@/components/Button';
import UserAvatar from '@/components/UserAvatar/UserAvatar';
import styles from './style/Comment.css';

export const Career = (props: Pick<FeedItemComment, 'author'>) => {
  const value =
    props.author.career.current?.display ||
    props.author.career.former?.display;

  if (!value) return null;

  return (
    <div className={styles.career}>{value}</div>
  );
};

Career.displayName = 'Comment.Author.Career';

type AuthorProps = {
  onClick: () => unknown;
} & Pick<FeedItemComment, 'author'>;

export const Name = (props: AuthorProps) => {
  if (props.author.id === SystemId) {
    return (
      <div className={styles.name}>
        {props.author.profile.fullname}
      </div>
    );
  }

  const url = getLocationFor.user.profile(props.author.profile);

  return (
    <MemberBaseballCard.Member contact={props.author}>
      <Button
        className={cx(styles.name, styles.link)}
        onClick={props.onClick}
        to={url}
        variant="link">
        {props.author.profile.fullname}
      </Button>
    </MemberBaseballCard.Member>
  );
};

Name.displayName = 'Comment.Author.Name';

export const Picture = (props: AuthorProps) => {
  const className = cx({
    [styles.admin]: props.author.id === SystemId,
  });

  return (
    <MemberBaseballCard.Member contact={props.author}>
      <UserAvatar
        className={className}
        onClick={props.onClick}
        pictureUrl={props.author.profile.pictureUrl}
        size={48}
        resize={100}
        to={getLocationFor.user.profile(props.author.profile)} />
    </MemberBaseballCard.Member>
  );
};

Picture.displayName = 'Comment.Author.Picture';