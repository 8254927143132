import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { routes } from '@routes/routes';
import { RouteAuth } from '@enums';

const selectAuthenticated = (state: Store.State) => state.user.state.authenticated;

export const useAppRoutes = () => {
  const authenticated = useSelector(selectAuthenticated);

  return useMemo(() => {
    if (authenticated) {
      return routes.filter(f => f.auth !== RouteAuth.Unauthenticated);
    } else {
      return routes.filter(f => f.auth !== RouteAuth.Authenticated);
    }
  }, [
    authenticated,
  ]);
};