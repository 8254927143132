import { Check } from 'react-feather';
import styles from './styles.css';

type Props = {
  checked: boolean;
} & ChildrenProps;

const CHECK_SIZE = 16;

export const CheckedLabel = (props: Props) => {
  return (
    <div className={styles.checkLabel}>
      {props.checked ? <Check className={styles.check} size={CHECK_SIZE} /> : <div className={styles.checkSpacer} style={{ width: CHECK_SIZE }} />}
      <div>
        {props.children}
      </div>
    </div>
  );
};