import { addDays } from 'date-fns';

export const appPrompt = 'vancery_app_prompt';
export const consent = 'vancerycookieconsent';
export const loggedInAppPrompt = 'vancery_logged_in_prompt';
export const referral = 'referral';
export const conferenceDialOutPrompt = 'conference_dial_out_prompt';
export const conferenceAudioCuePreferences = 'conference_audio_cue_prefs';
export const conferenceCameraPreferences = 'conference_camera_prefs';

//https://developer.chrome.com/blog/cookie-max-age-expires/#:~:text=this%20limit%20added%3F-,As%20of%20Chrome%20release%20M104%20(August%202022)%20cookies%20can%20no,when%20the%20browsing%20session%20ends.
export const MAX_COOKIE_EXPIRATION = addDays(new Date(), 400);