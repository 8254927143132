import { memo } from 'react';
import { Tooltip } from '@presentation/Tooltip';

type Props = {
  label: string;
  text: string;
};

export const ConditionTooltip = memo(({ label, text }: Props) => (
  <Tooltip title={label}>
    <span style={{ fontFamily: 'var(--font-semibold)' }}>{text}</span>
  </Tooltip>
));