import { AlertTriangle } from 'react-feather';
import * as Toast from './Toast.Base';
import type { ToastComponentProps } from './interfaces';
import styles from './style.css';

type Props = ToastComponentProps;

export const Warn = (props: Props) => {
  return (
    <Toast.Base
      Icon={AlertTriangle}
      iconClassName={styles.warn}
      iconSize={18}
      body={props.body}
      title={props.title} />
  );
};

Warn.displayName = 'Toast.Warn';