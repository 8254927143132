import { getUserAgent, checkChrome, checkFirefox, checkSafari } from '@utils/browser';
import { PermissionError } from './interfaces';

export function getPermissionErrorType(error: Error) {
  const ua = getUserAgent();

  const errName = error.name;
  const errMessage = error.message;

  if (checkFirefox(ua)) {
    // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#exceptions
    if (errName === 'NotFoundError') {
      return PermissionError.SystemDenied;
    }
    else if (errName === 'NotReadableError') {
      return PermissionError.SystemDenied;
    }
    else if (errName === 'NotAllowedError') {
      return PermissionError.PermissionDenied;
    }
    else if (errName === 'AbortError') {
      return PermissionError.DeviceInUse;
    }
  }

  if (checkSafari(ua)) {
    if (errName === 'NotAllowedError') {
      return PermissionError.PermissionDenied;
    }
  }

  if (checkChrome(ua)) {
    if (errName === 'NotAllowedError') {
      if (errMessage === 'Permission denied by system') {
        return PermissionError.SystemDenied;
      }
      else if (errMessage === 'Permission denied' || errMessage === 'Permission dismissed') {
        return PermissionError.PermissionDenied;
      }
    }
    else if (errName === 'NotReadableError') {
      return PermissionError.DeviceInUse;
    }
  }

  //Technically these are Microsoft Edge cases but lets use them as a fallback
  if (errName === 'NotAllowedError') {
    return PermissionError.PermissionDenied;
  }
  else if (errName === 'NotReadableError') {
    return PermissionError.DeviceInUse;
  }

  return PermissionError.Unknown;
}