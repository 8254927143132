import { useMemo } from 'react';
import * as surveyBuilder from '@containers/SurveyBuilder/utils';
import { useMaxDiffQuestionBuilderContext } from '@containers/SurveyBuilder.Question/Context';
import { QuestionSettingTitle } from '@presentation/SurveyBuilder';
import Select from './MaxDiff.Settings.Select';
import styles from './style/MaxDiff.Question.css';

export default function MaxDiffSettings() {
  const { item, updateOptionsPerSet, updateSets } = useMaxDiffQuestionBuilderContext();

  const optSetLimits = surveyBuilder.maxDiff.calcOptionsPerSetLimits(item);
  const setsLimits = surveyBuilder.maxDiff.calcSetLimits(item);

  const recommendedSets = useMemo(() => {
    const suggested = surveyBuilder.maxDiff.suggestSets(item);
    return suggested !== item.settings.sets ? ` (Recommended: ${suggested})` : '';
  }, [item]);

  return (
    <div className={styles.settings}>
      <div className={styles.row}>
        <QuestionSettingTitle
          title={`Attributes per set`} />
        <div className={styles.attributes}>
          <Select
            min={optSetLimits.min}
            max={optSetLimits.max}
            onChange={updateOptionsPerSet}
            value={item.settings.optionsPerSet} />
        </div>
      </div>
      <div className={styles.row}>
        <QuestionSettingTitle
          title={`Number of sets${recommendedSets}`} />
        <div className={styles.sets}>
          <Select
            min={setsLimits.min}
            max={setsLimits.max}
            onChange={updateSets}
            value={item.settings.sets} />
        </div>
      </div>
    </div>
  );
}

export { MaxDiffSettings };