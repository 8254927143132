import { cloneElement, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as api from '@api';
import * as actions from '@actions';
import { ApprovalNotesModal } from '@/components/Modal/Approval';
import { SurveyApprovalActionProps, SurveyApprovalProps, SurveyApprovalResponse } from './interfaces';

const ApprovalAction = (props: SurveyApprovalActionProps) => {
  return cloneElement(
    props.component,
    { onClick: props.onClick },
  );
};

type ModalOptions = {
  approve: boolean;
};

const mapState = (state: Store.State) => ({
  group: state.group,
});

export const SurveyApprovalActions = (props: SurveyApprovalProps) => {
  const { group } = useSelector(mapState);
  const [modalOptions, setModalOptions] = useState<ModalOptions>(null);
  const dispatch = useDispatch();

  const callback = (res: SurveyApprovalResponse, approved: boolean) => {
    dispatch(actions.projectPipelineUpdated({
      pipeline: res.pipeline,
      projectId: props.item.project.id,
    }));

    if (props.onChange) {
      props.onChange(approved);
    }
  };

  const approve = (notes: string) => {
    api.projects.surveys.approval({
      approved: true,
      approvalNotes: notes,
      projectSurveyResponseId: props.item.projectSurveyResponseId,
    })
    .then(res => callback(res, true));
  };

  const reject = (notes: string) => {
    api.projects.surveys.approval({
      approved: false,
      approvalNotes: notes,
      projectSurveyResponseId: props.item.projectSurveyResponseId,
    })
    .then(res => callback(res, false));
  };

  const handleApproveClicked = () => {
    if (group.features.approvalNotes) {
      setModalOptions({ approve: true });
    } else {
      approve(null);
    }
  };

  const handleRejectClicked = () => {
    if (group.features.approvalNotes) {
      setModalOptions({ approve: false });
    } else {
      reject(null);
    }
  };

  return (
    <>
      <ApprovalAction
        component={props.approveComponent}
        onClick={handleApproveClicked} />
      <ApprovalAction
        component={props.rejectComponent}
        onClick={handleRejectClicked} />
      {modalOptions &&
        <ApprovalNotesModal
          approve={modalOptions.approve}
          maxLength={500}
          onClose={() => setModalOptions(null)}
          onSubmit={modalOptions.approve ? approve : reject}
          project={props.item.project.name}
          user={props.item.user.name} />
      }
    </>
  );
};

export default SurveyApprovalActions;