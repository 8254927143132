import { useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutatePastUniversalSearch } from '@utils/api';
import { IUniversalSearch } from '@/types';
import { QueryContext, KeyboardContext, ResetContext } from './Context';
import { KeyboardActiveHandler } from './interfaces';

type Props = {
  children: React.ReactNode;
  item: IUniversalSearch.SearchBarResultItem;
  index: number;
  handler?: () => unknown;
}

export default function ResultsClickContainer({ index, item, children, handler }: Props) {
  const history = useHistory();
  const pastSearches = useMutatePastUniversalSearch();
  const { query } = useContext(QueryContext);
  const { index: activeIndex, setActiveHandler } = useContext(KeyboardContext);
  const { clickedResultReset } = useContext(ResetContext);

  const handleClick: KeyboardActiveHandler = useCallback(e => {
    e?.preventDefault();
    handler?.();
    pastSearches.mutate({
      query,
      entityId: item.id,
      item,
    });
    clickedResultReset();
    history.push(`/${item.path}`);
  }, [clickedResultReset, handler, pastSearches, query, item, history]);

  useEffect(() => {
    if (activeIndex !== -1 && index === activeIndex) {
      setActiveHandler(handleClick);
    }
  }, [index, activeIndex, setActiveHandler, handleClick]);

  return (
    <a href={`/${item.path}`} onClick={handleClick}>
      {children}
    </a>
  );
}

export { ResultsClickContainer };