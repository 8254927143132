import { Fragment, useContext, useMemo } from 'react';
import { ProjectStateContext, ProjectStateLoadingContext } from './Context';
import { ProjectActionsState } from './ProjectActionsContainer';
import { ManageAccessContainer } from './Project.ManageAccess';

type Props = {
  children: React.ReactNode;
};

export const ProjectToolsContainer = (props: Props) => {
  const state = useContext(ProjectStateContext);
  const isLoading = useContext(ProjectStateLoadingContext);
  const actionOnParent = useMemo(() => !state.project, [state.project]);

  if (isLoading) {
    return (
      <Fragment>
        {props.children}
      </Fragment>
    );
  }

  return (
    <ManageAccessContainer projectId={state.project?.id}>
      <ProjectActionsState
        actionOnParent={actionOnParent}
        capabilities={state.capabilities}
        object={state.object}
        parent={state.projectParent}
        project={state.project}>
        {props.children}
      </ProjectActionsState>
    </ManageAccessContainer>
  );
};

ProjectToolsContainer.displayName = 'Project.Tools.Container';