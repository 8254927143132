import { BrandingContainer } from '@containers/Branding';
import { Controls } from './Settings.Branding.Controls';
import { Logo } from './Settings.Branding.Logo';
import { Survey } from './Settings.Branding.Survey';
import { Theming } from './Settings.Branding.Theming';
import styles from './style/Settings.Branding.Theming.css';

type Props = unknown;

export const Branding = (props: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.wrap}>

        <div className={styles.header}>
          <h1 className={styles.title}>{`Themes & Branding`}</h1>
          <Controls />
        </div>

        <div className={styles.main}>
          <BrandingContainer>
            <Row>
              <Logo />
            </Row>
            <h2 className={styles.h2}>Chart Themes</h2>
            <Row>
              <Theming />
            </Row>
            <h2 className={styles.h2}>Survey Themes</h2>
            <Row>
              <Survey />
            </Row>
          </BrandingContainer>
        </div>
      </div>
    </div>
  );
};

Branding.displayName = 'Settings.Branding';

type RowProps = {
  children?: React.ReactNode;
};

const Row = (props: RowProps) => {
  return (
    <div className={styles.row}>
      {props.children}
    </div>
  );
};