import { forwardRef, useCallback, Ref } from 'react';
import Popper from '@mui/material/Popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import { UserObjectAccessContainer, useObjectAccessState, useUserObjectAccess } from '@containers/ObjectAccess';
import { GrayOutlineButton } from '@presentation/Buttons';
import * as enums from '@enums';
import { cx } from '@utils';
import { ObjectAccessUser } from '@/types';
import { PopperMenu, PopperMenuItem } from '@/components/Popper';
import styles from './style/UserAccessContextMenu.css';

type MenuProps = {
  onClose: () => void;
};

export const UserActionsContextMenu = forwardRef(({ onClose }: MenuProps, ref: Ref<HTMLDivElement>) => {
  const [_, dispatch] = useObjectAccessState();
  const { selectedRole, roles, userId } = useUserObjectAccess();

  const removeUser = useCallback(() => {
    dispatch({
      type: 'remove',
      userId,
    });
  }, [
    dispatch,
    userId,
  ]);

  const updateUserRole = useCallback((roleId: enums.WorkspaceObjectRole) => () => {
    dispatch({
      type: 'update-role',
      roleId,
      userId,
    });
    onClose();
  }, [
    onClose,
    dispatch,
    userId,
  ]);

  return (
    <PopperMenu className={styles.menu} ref={ref}>
      {roles.map(role => (
        <PopperMenuItem key={role} onClick={updateUserRole(role)}>
          {enums.utils.WorkspaceObjectRole.getName(role)}
        </PopperMenuItem>
      ))}
      <div className={styles.divider} />
      <PopperMenuItem
        onClick={removeUser}>
        Remove
      </PopperMenuItem>
    </PopperMenu>
  );
});

type Props = {
  item: ObjectAccessUser;
};

export const UserObjectAccessPopper = (props: Props) => {

  return (
    <UserObjectAccessContainer
      selectedRole={props.item.roleId}
      userId={props.item.user.id}>
      <PopupState variant="popper" popupId="user-object-access-popper">
        {popupState => (
          <div>
            <div {...bindToggle(popupState)}>
              <GrayOutlineButton
                onClick={popupState.open}
                className={cx(styles.roleBtn, { [styles.open]: popupState.isOpen})}>
                <div>{enums.utils.WorkspaceObjectRole.getName(props.item.roleId)}</div>
                <div className={styles.chevron} />
              </GrayOutlineButton>
            </div>
            <Popper
              {...bindPopper(popupState)}
              placement="bottom-end"
              className={styles.popper}>
              <ClickAwayListener
                onClickAway={popupState.close}>
                <UserActionsContextMenu
                  onClose={popupState.close} />
              </ClickAwayListener>
            </Popper>
          </div>
        )}
      </PopupState>
    </UserObjectAccessContainer>
  );
};

export default UserObjectAccessPopper;