import { useMemo, useState, useEffect, useCallback } from 'react';

type Props = {
  initialPageSize: number;
  totalCount: number;
};

export const usePagination = (props: Props): PaginationResult => {
  const [currPage, setCurrPage] = useState(1);
  const [pageSize, setPageSize] = useState(props.initialPageSize);

  const goBack = useCallback(() => {
    setCurrPage(old => old - 1);
  }, []);

  const goNext = useCallback(() => {
    setCurrPage(old => old + 1);
  }, []);

  useEffect(() => {
    if (currPage !== 1 && currPage * pageSize > props.totalCount) {
      setCurrPage(Math.ceil(props.totalCount / pageSize));
    }
  }, [props.totalCount, currPage, pageSize]);

  return {
    pageNumber: currPage,
    maxPages: Math.ceil(props.totalCount / pageSize),
    canGoBack: currPage > 1,
    goBack,
    canGoNext: currPage * pageSize < props.totalCount,
    goNext,
    pageSize,
    setPageSize,
    totalCount: props.totalCount,
    slice: {
      from: (currPage - 1) * pageSize,
      to: currPage * pageSize,
    },
  };
};

export type PaginationResult = {
  pageNumber: number;
  maxPages: number;
  canGoBack: boolean;
  goBack: () => void;
  canGoNext: boolean;
  goNext: () => void;
  pageSize: number;
  setPageSize: (val: number) => void;
  totalCount: number;
  slice: {
    from: number;
    to: number;
  };
};