import { useContext, useMemo } from 'react';
import { SurveyThemingContext } from '@containers/Branding/Context';

export const useSurveyThemingPalette = () => {
  const ctx = useContext(SurveyThemingContext);

  return useMemo(() => {
    if (!ctx) {
      return {
        palette: null,
        theme: false,
      };
    }

    return {
      palette: ctx.theming.data?.palette,
      theme: !!ctx.theming.data?.palette,
    };
  }, [ctx]);
};