import { SharedBreadcrumb } from '@/components/Workspace/WorkspaceBreadcrumb';
import SharedTabViewContainer from './SharedTabViewContainer';
import WorkspaceHeader from './Header';

export const SharedWorkspaceMain = () => {

  return (
    <>
      <WorkspaceHeader>
        <SharedBreadcrumb />
      </WorkspaceHeader>
      <SharedTabViewContainer />
    </>
  );
};

export default SharedWorkspaceMain;