import { useState } from 'react';
import Select from 'react-select/creatable';
import { useQuery } from '@tanstack/react-query';
import type { CSSObjectWithLabel } from 'react-select';
import { useDebounceValue } from '@utils';
import * as api from '@services/api';
import type { GroupTagUpdateItem } from '@/types';
import { SearchType } from '@enums';

type Props = {
  onChange: (items: GroupTagUpdateItem[]) => void;
  defaultValue?: GroupTagUpdateItem[];
};

type Option = GroupTagUpdateItem;

export const GroupTagSelect = (props: Props) => {
  const [input, setInput] = useState('');

  const debouncedValue = useDebounceValue(input, 200);

  const searchQuery = useQuery({
    queryKey: ['get-group-tags', debouncedValue],
    queryFn: () => api.search.getAutocompleteResults<SearchType.GroupTag>({
      type: SearchType.GroupTag,
      text: debouncedValue,
    }),
    placeholderData: [],
  });

  return (
    <Select<Option, true>
      isMulti
      options={searchQuery.data}
      styles={{
        menuPortal: base => ({ ...base, zIndex: 10 }) as CSSObjectWithLabel,
        menu: base => ({ ...base, zIndex: 10 }) as CSSObjectWithLabel,
      }}
      getOptionLabel={x => x.name}
      getOptionValue={x => x.id?.toString()}
      menuPortalTarget={document.body}
      formatCreateLabel={input => `Create "${input}"`} //This doesn't seem to actually work
      isValidNewOption={input => input.length > 2}
      inputValue={input}
      getNewOptionData={input => ({ id: input, name: input })}
      isLoading={searchQuery.isLoading}
      onChange={newVal => props.onChange([...newVal])}
      defaultValue={props.defaultValue}
      onInputChange={setInput} />
  );
};