import { useCallback, useContext, useState } from 'react';
import { MedicalBackgroundContext, MedicalBackgroundIdentificationContext } from './Context';
import type { FormState, QueryData, SetValueParams } from './interfaces.identification';

type Props = {
  children: React.ReactNode;
};

export const StateContainer = (props: Props) => {
  const ctx = useContext(MedicalBackgroundContext);

  const [identification, setIdentification] = useState<FormState>(getStateFromQueryData(ctx.query.data.identification));

  const rehydrate = useCallback(() => {
    return ctx.query.refetch()
    .then(res => setIdentification(getStateFromQueryData(res.data.identification)));
  }, [
    ctx.query,
    setIdentification,
  ]);

  const handleChange = useCallback((data: SetValueParams) => {
    setIdentification({
      ...identification,
      ...data,
    });
  }, [identification]);

  const value = {
    form: identification,
    setValue: handleChange,
    rehydrate,
  };

  return (
    <MedicalBackgroundIdentificationContext.Provider value={value}>
      {props.children}
    </MedicalBackgroundIdentificationContext.Provider>
  );
};

StateContainer.displayName = 'MedicalBackground.Identification.StateContainer';

function getStateFromQueryData(data: QueryData): FormState {
  return {
    meNumber: data.meNumber ?? '',
    country: data.country ?? '',
    deaNumber: data.deaNumber ?? '',
    npiNumber: data.npiNumber ?? '',
    clinicalPracticeStartedOn: data.clinicalPracticeStartedOn,
    payerAdminType: data.payerAdminType,
    stateLicenseNumber: data.stateLicenseNumber ?? '',
    stateOfPractice: data.stateOfPractice ?? '',
    hcpType: data.hcpType,
    isKol: data.isKol,
    isPayer: data.isPayer,
  };
}