import type { ChangeEvent } from 'react';
import { useCallback, useState, memo } from 'react';
import { useSelector } from 'react-redux';
import { HardDrive } from 'react-feather';
import MuiMenu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import { ConnectedAccountType, ConnectedAccountProvider } from '@/enums';
import { GoogleDrive, Zoom } from '@/components/icons';
import { cx } from '@/utils';
import styles from './style/File.Upload.Menu.css';
import stylesF from './style/File.Upload.css';

type Props = {
  onMyComputerSelect: (e: ChangeEvent<HTMLInputElement>) => void;
  onDriveSelect: () => void;
  onZoomSelect: () => void;
};

const selector = (state: Store.State) => state.user.connected.filter(c => c.typeId == ConnectedAccountType.Files);

export const Menu = memo(({ onMyComputerSelect, onDriveSelect, onZoomSelect }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = !!anchorEl;

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const connectedFileAccounts = useSelector(selector);
  const hasDrive = !!connectedFileAccounts.find(a => a.providerId == ConnectedAccountProvider.Google);
  const hasZoom = !!connectedFileAccounts.find(a => a.providerId == ConnectedAccountProvider.Zoom);

  return (
    <>
      <div
        className={cx(styles.btn, { [styles.open]: open })}
        onClick={handleClick}>
        <div className={styles.btnLabel}>
          Upload
        </div>
        <Divider
          sx={{
            marginLeft: '4px',
            marginRight: '4px',
          }}
          variant="middle"
          orientation="vertical" />
        <KeyboardArrowDownIcon
          sx={{
            height: '20px',
            width: '20px',
          }} />
      </div>
      <MuiMenu
        disableScrollLock={true}
        disableAutoFocusItem
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transitionDuration={{
          appear: 0,
          enter: 0,
          exit: 0,
        }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: '4px',
              transition: 'duration 0 !important',
              fontFamily: 'var(--font-reg)',
            },
          },
        }}
        MenuListProps={{
          sx: {
            padding: '0',
          },
        }}>
        <MenuItem
          className={styles.menuItem}>
          <label className={styles.flexContainer}>
            <input
              className={stylesF.fileInput}
              multiple={true}
              onChange={onMyComputerSelect}
              type="file" />
            <HardDrive
              className={styles.menuIcon}
              size={22} /> My Computer
          </label>
        </MenuItem>
        {hasDrive &&
          <MenuItem
            className={cx(styles.menuItem, styles.flexContainer)}
            onClick={() => { onDriveSelect(); handleClose(); }}>
            <GoogleDrive
              className={styles.menuIcon}
              size={22} /> Google Drive
          </MenuItem>
        }
        {hasZoom &&
          <MenuItem
            className={cx(styles.menuItem, styles.flexContainer)}
            onClick={() => { onZoomSelect(); handleClose(); }}>
            <Zoom
              className={styles.menuIcon}
              size={22} /> Zoom
          </MenuItem>
        }
      </MuiMenu>
    </>
  );
});