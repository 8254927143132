import { Helmet } from 'react-helmet';
import { path } from '@consts';
import { Terms } from '$website/components';
import styles from './style.css';

const H = () => {
  const description = `Sentiment's Terms and Policies.`;

  return (
    <Helmet title="Terms of Use">
      <link
        rel="canonical"
        href={`${process.env.FRONT_BASE_URL}${path.Website.Terms.Root}`} />
      <meta
        name='description'
        content={description} />
    </Helmet>
  );
};

export default function SentimentTerms() {
  return (
    <>
      <H />
      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.header}>
            <h1 className={styles.title}>Terms of Use</h1>
          </div>
          <div className={styles.body}>
            <Terms.Sentiment />
          </div>
        </div>
      </div>
    </>
  );
}

export { SentimentTerms };