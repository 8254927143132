import type { Dispatch } from 'react';
import { createContext } from 'react';
import type { ChatSkin } from '@/brand-insights/enums';
import type { BrandInsightsCapabilities, Modal } from '@/brand-insights/types';
import type { ILink } from './interfaces';

export const BrandInsightsModalContext = createContext<Modal.ToggleContext>([false, () => { }]);
export const LinkContext = createContext<ILink.LinkContextValue>(undefined);
export const LinkDispatchContext = createContext<Dispatch<ILink.Action>>(undefined);
export const ChatSkinContext = createContext<ChatSkin>(undefined);
export const CapabilitiesContext = createContext<BrandInsightsCapabilities>({
  canViewTraces: false,
  canViewExternalLinks: false,
});