import { AxiosResponse } from 'axios';
import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as api from '@api';
import * as actions from '@actions';
import { path } from '@consts';
import { useSelectUser } from '@containers/Store';
import {
  AccountProfileFieldsStateContext,
  AccountProfileRefetchContext,
  AccountProfileLoadingContext,
} from './Context';
import AccountProfileEditStateContainer from './AccountProfileEditStateContainer';
import AccountProfileSaveContainer from './AccountProfileSaveContainer';
import { useProfileFields } from './hooks';

type Props =
  IUserId &
  ChildrenProps;

const AccountProfileContainer = (props: Props) => {
  const [state, dispatchState] = useProfileFields({ id: props.userId });

  const dispatch = useDispatch();
  const me = useSelectUser();
  const history = useHistory();

  const query = useQuery(['fetch-user-profile', props.userId], (ctx: QueryFunctionContext) => {

    const userId = ctx.queryKey[1] as number;
    if (userId !== state.id) {
      dispatchState({
        type: 'reset',
      });
    }

    return api.profiles.getUserProfile({ userId })
      .then(res => ({
        ...res,
        id: userId,
      }));
  }, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    onSuccess: data => {
      if (data.id === me.id) {
        dispatch(actions.userInfoChange({
          profile: { status: data.profile.status },
        }));
      }

      dispatchState({
        data: {
          bio: data.bio,
          rateCurrency: data.rateCurrency,
          education: data.education,
          employment: data.employment,
          id: data.id,
          industry: data.industry,
          profile: data.profile,
          product: data.product,
        },
        type: 'init',
      });
    },
    onError: (e: AxiosResponse) => {
      if (e.status === 404) {
        history.push(path.Profile.NotFound);
      }
    },
  });

  const loading = !state.initialized;

  return (
    <AccountProfileFieldsStateContext.Provider value={[state, dispatchState]}>
      <AccountProfileRefetchContext.Provider value={query.refetch}>
        <AccountProfileLoadingContext.Provider value={loading}>
          <AccountProfileEditStateContainer>
            <AccountProfileSaveContainer>
              {props.children}
            </AccountProfileSaveContainer>
          </AccountProfileEditStateContainer>
        </AccountProfileLoadingContext.Provider>
      </AccountProfileRefetchContext.Provider>
    </AccountProfileFieldsStateContext.Provider>
  );
};

export { AccountProfileContainer };
export default AccountProfileContainer;
