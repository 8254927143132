import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CalendarBase from './CalendarBase';
import { useInjectedStyles } from './hooks/useInjectedStyles';
import type { SchedulingCalendarProps, SchedulingChildProps } from './interfaces';

const mapState = (state: Store.State) => ({
  contacts: state.contacts,
  user: state.user,
});

type ChildComponentProps = {
  className?: string;
} & Partial<SchedulingChildProps>;

type Props = {
  calendar?: Partial<SchedulingCalendarProps>;
  children: React.ReactElement<ChildComponentProps>;
};

export const Calendar = ({
  calendar = {},
  children,
  ...props
}: Props) => {
  const state = useSelector(mapState);
  const location = useLocation();

  useInjectedStyles();

  return (
    <CalendarBase
      calendar={calendar}
      contacts={state.contacts}
      location={location}
      user={state.user}>
      {children}
    </CalendarBase>
  );
};

export default Calendar;