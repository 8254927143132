import { useCallback, useContext, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ChatHintType } from '@/brand-insights/enums';
import type { Chat } from '@/brand-insights/types';
import * as $api from '@/brand-insights/api';
import {
  ActiveChatSessionContext,
  AuthorContext,
  ChatHintsContext,
  ChatHintsLoadingContext,
} from './context';
import { useGetPendingSegmentation } from './hooks';

type Props = ChildrenProps;

export const ChatHintsContainer = (props: Props) => {
  const chat = useContext(ActiveChatSessionContext);
  const author = useContext(AuthorContext);

  const query = useQuery(['chat-hints', chat.context, author.id], () => {
    return $api.getChatHints({
      context: chat.context,
      userId: author.id,
    });
  });

  const getHintDisabled = useHintDisabledProvider();

  const items: Chat.QueryHint.Item[] = useMemo(() => {
    const hints: Chat.QueryHint.Item[] = (query.data?.items || []).map(hint => ({ ...hint, disabled: getHintDisabled(hint) }));
    return hints;
  }, [
    getHintDisabled,
    query.data?.items,
  ]);

  return (
    <ChatHintsContext.Provider value={items}>
      <ChatHintsLoadingContext.Provider value={query.isLoading}>
        {props.children}
      </ChatHintsLoadingContext.Provider>
    </ChatHintsContext.Provider>
  );
};

const useHintDisabledProvider = () => {
  const hasPendingSegmentation = useGetPendingSegmentation();
  return useCallback((hint: Chat.QueryHint.Item) => {
    switch (hint.type) {
      case ChatHintType.AnalysisTags: return !!hasPendingSegmentation;
      default: return false;
    }
  }, [hasPendingSegmentation]);
};