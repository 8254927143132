import { memo } from 'react';
import { cx } from '@utils';
import styles from './style/Profile.Form.Field.css';

type Props = {
  className?: string;
  required?: boolean;
  title: string;
} & ChildrenProps;

export const ProfileFormField = memo((props: Props) => {

  const titleClassName = cx(styles.title, {
    [styles.required]: props.required,
  });

  const className = cx(styles.root, props.className);

  return (
    <div className={className}>
      <div className={titleClassName}>{props.title}</div>
      {props.children}
    </div>
  );
});

export default ProfileFormField;