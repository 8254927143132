import http from '@services/http';
import { qs } from '@utils';
import * as xform from './transformers';
import { Projects } from './interfaces';

export const createCharge = (data: Projects.CreateCharge.Request) => {
  const { projectId, ...rest } = data;

  return http.post<Projects.CreateCharge.Response>(`/admin/projects/${data.projectId}/billing/charges`, rest);
};

export const createHonoraria = (data: Projects.CreateHonoraria.Request) => {
  const { projectId, ...rest } = data;

  return http.post<Projects.CreateHonoraria.Response>(`/admin/projects/${data.projectId}/billing/honoraria`, rest);
};

export const fetchCharges = (data: Projects.FetchCharges.Request) => {
  const query = qs.stringify({
    description: data.description,
    index: data.index,
    size: data.size,
  });

  const q = query.length ? `?${query}` : ``;

  return http.get<Projects.FetchCharges.Response<string>>(`/admin/projects/${data.projectId}/billing/charges${q}`)
  .then(res => xform.projects.transformProjectCharges(res));
};

export const fetchHonoraria = (data: Projects.FetchHonoraria.Request) => {
  return http.get<Projects.FetchHonoraria.Response>(`/admin/projects/${data.projectId}/billing/honoraria`);
};

export const searchChargePresets = (data: Projects.SearchChargePresets.Request) => {
  const { projectId, ...rest } = data;

  const q = qs.stringify(rest);

  return http.get<Projects.SearchChargePresets.Response>(`/admin/projects/${projectId}/billing/charges/presets?${q}`);
};

export const updateHonoraria = (data: Projects.UpdateHonoraria.Request) => {
  const { projectId, ...rest } = data;

  return http.put<Projects.UpdateHonoraria.Response>(`/admin/projects/${projectId}/billing/honoraria`, rest);
};