import {
  Helper,
  helper,
} from '@remirror/core';
import { TextColorExtension as RMTextColorExtension } from 'remirror/extensions';

export class TextColorExtension extends RMTextColorExtension {
  /**
   * Get the current color of the selected text.
   */
  @helper()
  getActiveColor(): Helper<string> {
    const marks = [
      this.store.getState().storedMarks?.find(mark => mark.type === this.type),
      this.store.getState().selection.$from.marks().find(mark => mark.type === this.type),
      //Don't use the $to selection, if there's a selection and the user types, it'll use the color that $from using anyway
      //this.store.getState().selection.$to.marks().find(mark => mark.type === this.type),
    ];

    const activeMark = marks.find(mark => !!mark);

    return activeMark?.attrs?.color;
  }
}