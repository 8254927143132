import cuid from 'cuid';
import {
  ExclusiveOptionsQuestion, SurveyQuestion,
} from '@/types/survey';
import { indexBy } from '@utils/array';
import { SurveyQuestionType } from '@/enums';
import { ExclusiveOptions, SurveyBuilder } from '../interfaces';
import { generateNewOption, getDefaultExclusiveOptionsSectionSettings } from './defaults';

type State = SurveyBuilder.State['survey']['questions'];

type UpdateQuestion = {
  identifier: string;
  state: State;
  mutate: (item: ExclusiveOptionsQuestion.Question) => ExclusiveOptionsQuestion.Question;
};

function updateQuestion({ identifier, mutate, state }: UpdateQuestion) {
  return state.reduce<SurveyQuestion[]>((acc, q) => {
    const question = q.base.identifier !== identifier
      ? q
      : mutate(q as ExclusiveOptionsQuestion.Question);

    return [...acc, question];
  }, []);

}

const addOption = (data: ExclusiveOptions.AddOption.State) => (item: ExclusiveOptionsQuestion.Question): ExclusiveOptionsQuestion.Question => {
  const ordinal = item.options.length + 1;

  const question = {
    ...item,
    options: [
      ...item.options,
      generateNewOption({
        metadata: {
          canDelete: true,
          canModifyValue: true,
          isOpenEnded: false,
          isExclusive: false,
          template: {},
          sectionId: data.sectionId,
        },
        ordinal,
      }, SurveyQuestionType.ExclusiveOptions),
    ],
  };

  return rebalanceOptionOrdinals(question);
};

const deleteSection = (data: ExclusiveOptions.DeleteSection.State) => (item: ExclusiveOptionsQuestion.Question): ExclusiveOptionsQuestion.Question => {

  const question = {
    ...item,
    options: item.options.filter(o => o.metadata.sectionId != data.sectionId),
    settings: {
      ...item.settings,
      optionSections: rebalanceSectionOrdinals(item.settings.optionSections.filter(s => s.identifier != data.sectionId)),
    },
  };

  return rebalanceOptionOrdinals(question);
};

const addSection = (data: ExclusiveOptions.AddSection.Action) => (item: ExclusiveOptionsQuestion.Question): ExclusiveOptionsQuestion.Question => {
  const ordinal = item.settings.optionSections.length + 1;

  const newSection: ExclusiveOptionsQuestion.OptionSection = { identifier: cuid(), value: '', type: 'single-select', ordinal, settings: getDefaultExclusiveOptionsSectionSettings() };
  const newOptions = Array.from({ length: 3 }).map((_, i) => {
    return generateNewOption({
      metadata: {
        canDelete: true,
        canModifyValue: true,
        isOpenEnded: false,
        isExclusive: false,
        template: {},
        sectionId: newSection.identifier,
      },
      ordinal: i + 1,
    }, SurveyQuestionType.ExclusiveOptions);
  });

  const question = {
    ...item,
    settings: {
      ...item.settings,
      optionSections: [...item.settings.optionSections, newSection],
    },
    options: [...item.options, ...newOptions],
  };

  return rebalanceOptionOrdinals(question);
};

function rebalanceOptionOrdinals(item: ExclusiveOptionsQuestion.Question): ExclusiveOptionsQuestion.Question {
  const sectionOrdinalMap = indexBy(item.settings.optionSections, k => k.identifier, v => v.ordinal);
  const options = item.options.sort((a, b) => sectionOrdinalMap[a.metadata.sectionId] - sectionOrdinalMap[b.metadata.sectionId] || a.ordinal - b.ordinal)
    .map((o, i) => ({
      ...o,
      ordinal: i + 1,
    }));

  return {
    ...item,
    options,
  };
}

function rebalanceSectionOrdinals(items: ExclusiveOptionsQuestion.OptionSection[]): ExclusiveOptionsQuestion.OptionSection[] {
  return items.sort((a, b) => a.ordinal - b.ordinal).map((s, i) => ({
    ...s,
    ordinal: i + 1,
  }));
}

export const exclusiveOptionsReducer = (state: SurveyBuilder.State, action: ExclusiveOptions.Action): State => {
  const questions = state.survey.questions;
  const identifier = action.questionIdentifier;

  switch (action.type) {
    case 'exclusive-options-add-option': {
      return updateQuestion({
        mutate: addOption(action),
        identifier,
        state: questions,
      });
    }
    case 'exclusive-options-delete-section': {
      return updateQuestion({
        mutate: deleteSection(action),
        identifier,
        state: questions,
      });
    }
    case 'exclusive-options-add-section': {
      return updateQuestion({
        mutate: addSection(action),
        identifier,
        state: questions,
      });
    }
  }
  return state.survey.questions;
};