import { xhr } from '@services/http/xhr';

export const logout = async (): Promise<LogoutUrlResponse> => {
  const res = await xhr.post(`/auth/logout`, {});
  return res.data;
};

export const logoutUrl = async (): Promise<LogoutUrlResponse> => {
  const res = await xhr.get(`/auth/logout-url`);
  return res.data;
};

type LogoutUrlResponse = {
  redirectUrl: string;
};