import { strategy } from '$website/containers/MarketSegmentation/segmentation';
import { Strategy } from '$website/containers/MarketSegmentation/interfaces';
import { useSiteStrategyState } from './useSiteStrategyState';

const useSegmentedContent = () => {
  const state = useSiteStrategyState();
  switch (state) {
    case Strategy.Consulting:
      return strategy.consulting.content;

    case Strategy.Corporate:
      return strategy.corporate.content;

    case Strategy.Enterprise:
      return strategy.enterprise.content;

    case Strategy.Investment:
      return strategy.investment.content;

    case Strategy.Individuals:
    default:
      return strategy.individuals.content;
  }
};

export { useSegmentedContent };
export default useSegmentedContent;