import { useCallback } from 'react';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import * as api from '$admin/api';

type Props =
  IProjectId & ISurveyId;

type Data = api.Projects.FetchSurvey.Response;
type QK = readonly [string, number, number];

type Options = UseQueryOptions<Data, AxiosResponse, Data, QK>;

export function useFetchProjectSurvey({ projectId, surveyId }: Props, options?: Options) {
  const fetch = useCallback(() => {
    return api.projects.surveys.fetchSurvey({
      projectId,
      surveyId,
    });
  }, [
    projectId,
    surveyId,
  ]);

  return useQuery(['admin-project-survey', projectId, surveyId], fetch, {
    ...options,
    placeholderData: {
      survey: null,
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
}