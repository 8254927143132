import extend from '@enums/extend';

export enum DocumentSigning {
  Required         = 1,
  NotRequired      = 2,
  ManuallyUploaded = 3,
}

const map = new Map([
  [DocumentSigning.Required,         { name: 'E-Signature Required' }],
  [DocumentSigning.NotRequired,      { name: 'E-Signature Not Required' }],
  [DocumentSigning.ManuallyUploaded, { name: 'Document Manually Uploaded' }],
]);

export enum HelloSignSignatureStatus {
  AwaitingSignature = 'awaiting_signature',
  Rejected          = 'rejected',
  Signed            = 'signed',
}

export default {
  DocumentSigning: extend(map),
};