import Cancel from '@mui/icons-material/Cancel';
import InsertPhoto from '@mui/icons-material/InsertPhoto';
import { useRemoveLogoMutation, useUpdateLogoMutation } from '@containers/Branding/hooks';
import { MediaOrientation } from '@enums';
import { ImageCropper } from '@/components/ImageCrop';
import styles from './style/Settings.Branding.Logo.css';

type Props = {
  imageSrc?: string;
};

export const Image = (props: Props) => {
  const mutation = useRemoveLogoMutation();

  if (!props.imageSrc) {
    return (
      <div className={styles.display}>
        <InsertPhoto className={styles.noimage} />
      </div>
    );
  }

  return (
    <div className={styles.display}>
      <img
        className={styles.image}
        src={props.imageSrc} />
      <div
        className={styles.remove}
        onClick={() => mutation.mutate({
          orientation: MediaOrientation.Square,
        })}>
        <Cancel />
      </div>
    </div>
  );
};

Image.displayName = 'Settings.Branding.Logo.Square.Image';

export const Cropper = (props: Props) => {
  const mutation = useUpdateLogoMutation();

  return (
    <ImageCropper
      aspectRatio={1}
      strictAspectRatio
      src={props.imageSrc}
      isSaving={mutation.isLoading}
      onSave={data => mutation.mutate({
        blob: data.blob,
        orientation: MediaOrientation.Square,
      })} />
  );
};

Cropper.displayName = 'Settings.Branding.Logo.Square.Cropper';