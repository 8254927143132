import http from '@services/http';
import * as xform from '@transformers';
import { Payout } from '@services/api/interfaces';

export const onboardingPayeeUrl = ({ userId, ...data }: Payout.OnboardingPayeeUrl.Request): Promise<Payout.OnboardingPayeeUrl.Response> => {
  return http.post(`/payout/${userId}/get-onboarding-payee-url`, data);
};

export const completeOnboardingPayee = ({ userId }: Payout.CompleteOnboardingPayee.Request): Promise<Payout.CompleteOnboardingPayee.Response> => {
  return http.post(`/payout/${userId}/complete-onboarding-payee`, {});
};

export const historyPayeeUrl = ({ userId }: Payout.HistoryPayeeUrl.Request): Promise<Payout.HistoryPayeeUrl.Response> => {
  return http.post(`/payout/${userId}/get-history-payee-url`, {});
};

export const getPayoutHistory = (
  data: Payout.GetPayoutHistory.Request,
): Promise<Payout.GetPayoutHistory.Response> => {
  return http.get(`/payout/${data.userId}/history${data.cursor ? `?c=${data.cursor}` : ''}`)
             .then(xform.normalizePayoutHistory);
};