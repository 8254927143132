import type { CSSObjectWithLabel, StylesConfig, Theme } from 'react-select';

type Props = {
  readonly?: boolean;
  isInvalid?: boolean;
};

export function createSelectStyles<T>(props: Props = {
  readonly: false,
  isInvalid: false,
}): StylesConfig<T, false> {
  return {
    menu: ex => ({
      ...ex,
      width: 'max-content',
      minWidth: '100%',
      zIndex: 2,
    } as CSSObjectWithLabel),
    control: ex => ({
      ...ex,
      border: props.readonly
        ? '2px solid transparent'
        : props.isInvalid
          ? '2px solid var(--pri-04)'
          : '2px solid var(--gray-l)',
      borderRadius: '5px',
      boxShadow: 'none',
      padding: props.readonly ? '2px 4px 2px 0' : '2px 4px',
      backgroundColor: props.readonly ? 'var(--pri-01)' : ex.backgroundColor,
      transition: props.readonly ? 'all 400ms cubic-bezier(0, 0, 0.2, 1)' : 'all 100ms',
      '&:hover, &:focus': {
        // border: `2px solid ${props.readonly ? 'transparent' : 'var(--pri-02)'}`,
      },
    } as CSSObjectWithLabel),
    container: ex => ({
      ...ex,
      maxWidth: '100%',
    } as CSSObjectWithLabel),
    indicatorsContainer: ex => ({
      ...ex,
      visibility: props.readonly ? 'hidden' : 'visible',
    } as CSSObjectWithLabel),
    input: ex => ({
      ...ex,
      color: 'var(--black)',
    } as CSSObjectWithLabel),
    placeholder: ex => ({
      ...ex,
      color: 'var(--gray)',
    } as CSSObjectWithLabel),
    singleValue: ex => ({
      ...ex,
      marginLeft: props.readonly ? '0' : '2px',
      color: 'var(--black)',
    } as CSSObjectWithLabel),
    valueContainer: ex => ({
      ...ex,
      padding: props.readonly ? '2px 4px 2px 0' : '2px 4px',
      overflow: 'hidden',
    } as CSSObjectWithLabel),
  };
}

export const createSelectTheme = () => (theme: Theme) => {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary: 'var(--pri-02)',
      primary25: 'var(--blue-l)',
      primary50: 'var(--blue-l)',
      primary75: 'var(--blue-l)',
    },
  };
};