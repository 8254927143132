import { memo } from 'react';
import styled from '@emotion/styled';
import type { Chat } from '@/brand-insights/types';
import { Tooltip } from '@/brand-insights/components/presentation/Tooltip';
import { StarToggle } from '@/brand-insights/components/presentation/StarToggle';
import { CitationNumber } from '@/brand-insights/components/presentation/CitationNumber';
import { CopyToClipboard } from './Message.CopyToClipboard';

type Props = {
  className?: string;
  citationValue: string;
  quote: Chat.Quote;
  onToggleStar: () => void;
  onCopy: () => void;
  onViewCitation: () => void;
  starred: boolean;
};

export const Quote = memo(({ className, citationValue, onCopy, onToggleStar, onViewCitation, quote, starred }: Props) => {
  return (
    <Root>
      <Tooltip title={starred ? 'Unfavorite Quote' : 'Favorite Quote'} placement="top">
        <div>
          <StyledStarToogle
            onClick={onToggleStar}
            value={starred} />
        </div>
      </Tooltip>
      <QuoteRoot className={className}>
        <div>
          <StyledQ>
            {quote.value}
          </StyledQ>
          <StyledCitation onClick={onViewCitation}>{citationValue}</StyledCitation>
        </div>
        <Footer>
          <Actions>
            <CopyToClipboard
              color={'unset'}
              onClick={onCopy} />
          </Actions>
        </Footer>
      </QuoteRoot>
    </Root>
  );
});

const Root = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
});

const StyledCitation = styled(CitationNumber)({
  cursor: 'pointer',
  marginLeft: 5,
});

const StyledStarToogle = styled(StarToggle)({
  display: 'flex',
  flexShrink: 0,
});

const Footer = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginTop: 10,
}));

const Actions = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
}));

const QuoteRoot = styled.div(({ theme }) => ({
  padding: 15,
  boxSizing: 'border-box',
  width: '100%',
  maxWidth: 575,
  lineHeight: '20px',

  whiteSpace: 'normal',

  backgroundColor: '#f9f9f9',

  borderRadius: 2,
  borderLeftStyle: 'solid',
  borderLeftWidth: 5,
  borderLeftColor: theme.palette.sentiment.primary.main,
}));

const StyledQ = styled.q(({ theme }) => ({

  fontFamily: theme.fonts.regular,

  '::before': {
    content: '"“"',
    color: theme.palette.gray.dark1,
    fontFamily: theme.fonts.semiBold,
    fontSize: 20,
    paddingRight: 3,
    lineHeight: '20px',
    verticalAlign: 'top',
  },

  '::after': {
    content: '"”"',
    color: theme.palette.gray.dark1,
    fontFamily: theme.fonts.semiBold,
    fontSize: 20,
    paddingLeft: 3,
    lineHeight: '20px',
    verticalAlign: 'top',
  },
}));
