import { useCallback } from 'react';
import { useActive, useChainedCommands, useCommands } from '@remirror/react';

export const useBoldCommand = () => {
  const active = useActive();
  const { toggleBold } = useCommands();
  const chain = useChainedCommands();

  const toggle = useCallback(() => {
    chain
      .toggleBold()
      .focus()
      .run();
  }, [chain]);

  return {
    active: active.bold(),
    toggle,
    enabled: toggleBold.enabled(),
  };
};