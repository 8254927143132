import { useContext } from 'react';
import SelectCreatable from 'react-select/creatable';
import type { CSSObjectWithLabel } from 'react-select';
import { TaggingContext } from './Context';
import type { Tagging } from './interfaces';
import styles from './style/Tagging.Tag.css';

type Props =
  Omit<Tagging.Tag,
    | 'end'
    | 'identifier'
    | 'start'
    | 'typeId'>;

export const Input = (props: Props) => {
  const { queries, popup, setValue } = useContext(TaggingContext);

  return (
    <SelectCreatable
      className={styles.select}
      classNames={{
        menuList: () => styles.menu,
        menu: () => styles.menu,
      }}
      formatCreateLabel={value => `Create tag "${value}"`}
      getOptionLabel={option => option['label'] ?? option?.name}
      getOptionValue={option => option?.id?.toString()}
      isLoading={queries.available.isLoading}
      isDisabled={queries.available.isLoading || queries.available.isError}
      isValidNewOption={value => value?.trim?.()?.length > 0}
      maxMenuHeight={550}
      menuPlacement={popup ? 'bottom' : 'top'}
      onChange={option => setValue('id', option.id)}
      onCreateOption={value => setValue('name', value)}
      options={queries.available?.data}
      value={props.name ? props : null}
      styles={{
        option: (base, props) => ({
          ...base,
          alignItems: 'center',
          display: 'flex',
          ':before': {
            backgroundColor: props.data.color,
            borderRadius: 12,
            content: '" "',
            display: 'block',
            marginRight: 8,
            height: 12,
            width: 12,
          },
        } as CSSObjectWithLabel),
      }} />
  );
};

Input.displayName = 'Tagging.Tag.Input';