import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { SurveyQuestionNumberSettings, SurveyQuestionNumberType } from '@/types';
import { CURRENCY_FORMATTERS_LEGACY, getCurrencySymbolLegacy } from '@utils/format';
import { NumberInput } from '@/components/Input/NumberInput';

type PassableNumberFormatProps = 'value' | 'onValueChange' | 'onBlur' | 'className' | 'isAllowed' | 'onFocus' | 'style';

type Props = {
  settings: SurveyQuestionNumberSettings;
  valueIsNumericString?: boolean;
} & Pick<NumericFormatProps, PassableNumberFormatProps>;

export const SurveyNumberInput = ({ settings, ...props }: Props) => {
  const additionalSettings = getCustomSettings(settings);
  return (
    <NumberInput {...props} {...additionalSettings} />
  );
};

export const SurveyNumberFormat = ({ settings, ...props }: Props) => {
  const additionalSettings = getCustomSettings(settings);
  return (
    <NumericFormat {...props} {...additionalSettings} />
  );
};

const defaultSettings = {
  thousandSeparator: true,
  autoComplete: 'off',
  placeholder: '-',
  allowLeadingZeros: false,
};
function getCustomSettings(settings: SurveyQuestionNumberSettings): Omit<NumericFormatProps, PassableNumberFormatProps> {
  const type = settings?.type ?? SurveyQuestionNumberType.Number;
  switch (type) {
    case SurveyQuestionNumberType.Number: {
      return defaultSettings;
    }
    case SurveyQuestionNumberType.Unit: {
      return {
        ...defaultSettings,
        suffix: settings.unitType,
      };
    }
    case SurveyQuestionNumberType.Currency: {
      const currencyType = settings?.currency ?? 'USD';
      const formatterOptions = CURRENCY_FORMATTERS_LEGACY[currencyType].resolvedOptions();

      const currencySign = getCurrencySymbolLegacy(currencyType);
      const decimalScale = formatterOptions.minimumFractionDigits;
      return {
        ...defaultSettings,
        fixedDecimalScale: true,
        prefix: currencySign,
        decimalScale,
      };
    }
    default:
      throw new UnreachableCaseError(type);
  }
}