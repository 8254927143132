import http, { transformers } from '@services/http';
import type { Conference } from './interfaces';

export const getConferenceDetails = async (data: Conference.FetchConferenceDetails.Request) => {
  return http.get<Conference.FetchConferenceDetails.Response>(`/admin/conference/${data.conferenceId}`, { transformResponse: transformers.transformDates });
};

export const getCloudwatchLogs = async (data: Conference.FetchCloudwatchLogs.Request) => {
  return http.get<Conference.FetchCloudwatchLogs.Response>(`/admin/conference/${data.conferenceId}/cloudwatch-logs`);
};

export const getDbLogs = async (data: Conference.FetchDBLogs.Request) => {
  return http.get<Conference.FetchDBLogs.Response>(`/admin/conference/${data.conferenceId}/db-logs`, { transformResponse: transformers.transformDates });
};

export const getRoomInstanceParticipants = async (data: Conference.GetRoomInstanceParticipants.Request) => {
  return http.get<Conference.GetRoomInstanceParticipants.Response>(`/admin/conference/room-instance/${data.roomInstanceId}/participants`, { transformResponse: transformers.transformDates });
};

export const searchConferences = async (data: Conference.SearchConferences.Request) => {
  return http.get<Conference.SearchConferences.Response>(`/admin/conference/search?query=${data.query}`, { transformResponse: transformers.transformDates });
};