import styles from './style/Label.Base.css';

export enum TemplateLabelColor {
  One,
  Two,
  Three,
  Four,
  Five,
  Six,
  Seven,
}

const TemplateLabelColors = {
  [TemplateLabelColor.One]: styles.colorOne,
  [TemplateLabelColor.Two]: styles.colorTwo,
  [TemplateLabelColor.Three]: styles.colorThree,
  [TemplateLabelColor.Four]: styles.colorFour,
  [TemplateLabelColor.Five]: styles.colorFive,
  [TemplateLabelColor.Six]: styles.colorSix,
  [TemplateLabelColor.Seven]: styles.colorSeven,
};

export function getTemplateLabelColorClass(color: TemplateLabelColor) {
  return TemplateLabelColors[color];
}