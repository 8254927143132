import type { Chat } from '@/brand-insights/types';
import { safeJsonDate } from './date';

export function transformChatSegmentation(data: Chat.Segmentation.Segmentation<string>): Chat.Segmentation.Segmentation {
  return {
    ...data,
    screens: data.screens.map(transformChatSegmentationScreen),
    createdOn: safeJsonDate(data.createdOn),
  };
}

function transformChatSegmentationScreen(data: Chat.Segmentation.InstanceScreenItem<string>): Chat.Segmentation.InstanceScreenItem {
  switch (data.type) {
    case 'tag-selection': {
      return {
        ...data,
        item: {
          ...data.item,
          createdOn: safeJsonDate(data.item.createdOn),
        },
      };
    }
    case 'dimension-selection': {
      return {
        ...data,
        item: {
          ...data.item,
          createdOn: safeJsonDate(data.item.createdOn),
        },
      };
    }
    case 'confirmation': {
      return {
        ...data,
        item: {
          ...data.item,
          createdOn: safeJsonDate(data.item.createdOn),
        },
      };
    }
    case 'result': {
      return {
        ...data,
        item: {
          ...data.item,
          createdOn: safeJsonDate(data.item.createdOn),
        },
      };
    }
    case 'cancelled': {
      return {
        ...data,
        item: {
          ...data.item,
          createdOn: safeJsonDate(data.item.createdOn),
        },
      };
    }
    default: throw new UnreachableCaseError(data);
  }
}