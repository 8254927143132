import { Footer } from '@/components/Branding';

type Props = {
  children: React.ReactNode;
  decorate: boolean;
};

export const ScreenDecorator = (props: Props) => {

  if (!props.decorate) return props.children as JSX.Element;

  return (
    <Footer.PoweredBy>
      {props.children}
    </Footer.PoweredBy>
  );
};

ScreenDecorator.displayName = 'Onboarding.Project.Footer.PoweredBy.ScreenDecorator';