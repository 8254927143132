export const phoneNumber = '9177686139';
export const phoneNumberDisplay = '917.768.6139';
export const addressLine1 = '230 Third Avenue (5th Floor)';
export const addressLine2 = 'Waltham, MA 02451-7528';
export const addressLine3 = 'United States';
export const email = 'support@sentimentglobal.com';
export const googleMapsLink = 'https://goo.gl/maps/koDtDDsCQBweX5sM8';
export const internalEmailDomains = ['@trinitypartners.com', '@trinitylifesciences.com'];

export const EUAddress = ['Widenmayerstraße 50', '80538 Munich, Germany'];
export const IndiaAddress = ['Global Business Park,', 'Tower – D, 8th Floor,', 'Sikanderpur, Mehrauli Gurgaon Road,', 'Gurgaon – 122002', 'Haryana, India'];
export const LondonAddress = ['40 Basinghall Street', 'Twelfth Floor', 'London EC2V 5DE, UK'];