import { useMemo } from 'react';
import { SurveyQuestionType } from '@enums';
import { useSurveyFilterDataContext } from '@containers/GroupProject/Context';
import { validUserFilterMethods } from '@containers/GroupProject/utils/survey-filtering';

const VALID_QUESTION_TYPES =
  Object.keys(validUserFilterMethods)
    .map(q => +q as SurveyQuestionType)
    .filter(k => validUserFilterMethods[k]);

export const useValidFilterSurveys = () => {
  const filterContext = useSurveyFilterDataContext();

  return useMemo(() => {
    if (!filterContext?.surveyData) {
      return [];
    }

    return filterContext.surveyData.map(s => ({
      ...s,
      surveyId: s.surveyId,
      name: s.surveyDetails.name,
      questions: s.questions.filter(q => VALID_QUESTION_TYPES.includes(q.typeId)),
    })).filter(s => s.questions.length > 0);

  }, [
    filterContext.surveyData,
  ]);
};

export const useValidFilterSurveyQuestions = (surveyId: number) => {
  const validSurveys = useValidFilterSurveys();

  return validSurveys.find(s => s.surveyId === surveyId)?.questions ?? [];
};