import type { IMainSearchFilters } from './interfaces';

export function reducer(state: IMainSearchFilters.State, action: IMainSearchFilters.Action): IMainSearchFilters.State {
  switch (action.type) {
    case 'query-update': {
      return {
        ...state,
        ...action.value,
      };
    }
    default: return state;
  }
}

export function getInitialState(state: Partial<IMainSearchFilters.State> = {}): IMainSearchFilters.State {
  return {
    tab: state?.tab || 'all',
    keywords: state?.keywords || [],
    filetypes: state?.filetypes || [],
    tags: state?.tags || [],
  };
}