import { useContext, useEffect, useMemo } from 'react';
import { usePastUniversalSearch } from '@utils/api';
import { KeyboardContext, ResetContext } from './Context';
import ResultsItems from './Results.Items';
import styles from './style/Past.css';

export default function PastSearches() {
  const query = usePastUniversalSearch();
  const { index } = useContext(KeyboardContext);
  const { updatedResultsReset } = useContext(ResetContext);

  useEffect(() => {
    updatedResultsReset(query.data?.length);
  }, [updatedResultsReset, query.data?.length]);

  const resultItems = useMemo(() => query.data?.map(i => i.item) ?? [], [query.data]);

  if (!resultItems.length) {
    return (
      <div className={styles.root}>
        <div className={styles.title}>Past Searches</div>
        <div className={styles.nothing}>No past searches.</div>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.title}>Past Searches</div>
      <ResultsItems
        activeIndex={index}
        items={resultItems} />
    </div>
  );
}

export { PastSearches };