import { useCallback, useContext } from 'react';
import { CSVFieldMappingContext, CSVQueriesContext } from '@containers/Group.Contacts.Import/Context';
import { BackButton, Footer, NextButton } from '@screens/ProjectCreation/Footer';
import styles from './style/CSVFieldMapping.css';

type Props = unknown;

export const CSVFieldMappingNavigation = (props: Props) => {
  const { valid } = useContext(CSVFieldMappingContext);
  const { fields, summary } = useContext(CSVQueriesContext);

  const handleClick = useCallback(() => {
    summary.refetch();
  }, [summary]);

  if (fields.isLoading) return null;

  return (
    <Footer className={styles.footer}>
      <BackButton />
      <NextButton
        disabled={!valid}
        to="/contacts/import/tags"
        onClick={handleClick}>
        Next
      </NextButton>
    </Footer>
  );
};

CSVFieldMappingNavigation.displayName = 'CSVFieldMappingNavigation';