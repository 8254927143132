import { useCallback } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks/useSurveyBuilderContext';
import { useRichTextEditorRegister } from '@/containers/SurveyBuilder/hooks/useRichTextEditorRegister';
import { SurveyRichText } from '@/types/survey.rich-text';
import { useSurveyBuilderItemEditing } from '@containers/SurveyBuilder/Context';
import {
  SurveyRichTextEditorContainer, SurveyRichTextEditorOnChange,
  useSurveyRichTextEditor,
} from '@/components/Survey.RichText';
import { LinkContainer } from '@/components/Remirror';
import { useSurveyAlternateImagesBuilderItemContext } from './context';

type Props = ChildrenProps;

export const AEIBuilderEditorContainer = (props: Props) => {
  /*
  const [_, dispatch] = useSurveyBuilderState();
  const item = useSurveyAlternateImagesBuilderItemContext();

  const { editing } = useSurveyBuilderItemEditing();

  const handleChange: SurveyRichTextEditorOnChange = useCallback(params => {
    if (!params.tr?.docChanged) return;

    setState(params.state);

    const value = params.state.doc.toJSON() as SurveyRichText.RichTextValue;

    dispatch({
      type: 'message-value-updated',
      messageIdentifier: item.identifier,
      value,
    });
  }, [
    item.identifier,
    dispatch,
    setState,
  ]);

  const editorRef = useRichTextEditorRegister({
    identifier: item.identifier,
  });
  */

  return (
    <>
      {props.children}
    </>
  );
};