import { cx } from '@utils';
import styles from './style.css';

type Props = {
  className?: string;
  children: React.ReactNode;
  labelClassName?: string;
  title: string;
};

export const Label = (props: Props) => {
  const root = cx(styles.root, props.className);
  const label = cx(styles.label, props.labelClassName);

  return (
    <div className={root}>
      <div className={label}>{props.title}</div>
      {props.children}
    </div>
  );
};

type TextLabelProps = Omit<Props, 'children'> & {
  fallback?: React.ReactNode;
  value: string;
  valueStyle?: string;
};

export const TextLabel = (props: TextLabelProps) => {
  const { fallback, value, valueStyle, ...rest } = props;
  const label = cx(styles.field, valueStyle);

  return (
    <Label {...rest}>
      <div className={label}>{value || fallback}</div>
    </Label>
  );
};