import { useCallback, useContext } from 'react';
import { ChatStateDispatchContext } from '@/brand-insights/components/Chat.State';
import { useScrollToBottomOfMessages } from '@/brand-insights/components/Chat';
import { useFetchChatInstanceMutation } from '@/brand-insights/api/hooks';
import Toast from '@/components/Toast';

type Props = {
  onError?: () => void;
  onSuccess?: () => void;
};

export const useLoadChatInstance = ({ onError, onSuccess }: Props = {}) => {

  const dispatch = useContext(ChatStateDispatchContext);
  const scrollToBottom = useScrollToBottomOfMessages();

  const mutation = useFetchChatInstanceMutation();

  return useCallback((chatInstanceIdentifier: string) => {
    dispatch({
      type: 'session-loading',
      payload: { chatInstanceIdentifier },
    });
    mutation.mutateAsync({ chatInstanceIdentifier }, {
      onSuccess: res => {
        dispatch({
          type: 'batch-actions',
          payload: {
            actions: [
              { type: 'chat-loaded', payload: res },
              { type: 'session-loaded', payload: { chatInstanceIdentifier } },
            ],
          },
        });
        onSuccess?.();
        scrollToBottom();
      },
      onError: () => {
        onError?.();
        // Toast.error({
        //   title: `Insights Chat`,
        //   body: `Could not find this chat thread!`,
        // });
      },
    });
  }, [dispatch, scrollToBottom, mutation, onError, onSuccess]);
};