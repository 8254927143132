import { useCallback, useState } from 'react';
import loadImage, { LoadImageOptions, LoadImageResult } from 'blueimp-load-image';

type Params = {
  maxHeight?: number;
  maxWidth?:  number;
};

export const useLoadImage = ({ maxHeight = 230, maxWidth = 230 }: Params = Defaults) => {
  const [value, setValue] = useState<string>(null);
  const [error, setError] = useState<boolean>(false);
  const [isPictureLoading, setPictureLoading] = useState<boolean>(false);

  const loadFromCanvasElement = useCallback((el: HTMLCanvasElement): ImageData => {
    setValue(el.toDataURL());

    return {
      height: el.height,
      width: el.width,
    };
  }, []);

  const loadFromImageElement = useCallback((el: HTMLImageElement): ImageData => {
    setValue(el.src);

    return {
      height: el.height,
      width: el.width,
    };
  }, []);

  const loadSource = useCallback((file: File | Blob | string): Promise<ImageData> => {
    setPictureLoading(true);
    setError(false);

    const options: LoadImageOptions = {
      canvas: true,
      // maxHeight,
      maxWidth,
      contain: true,
      orientation: true,
      crossOrigin: 'Anonymous',
    };

    return loadImage(file, options)
      .then(({ image }: LoadImageResult) => {
        setPictureLoading(false);
        if ((image as HTMLCanvasElement).toDataURL) {
          return loadFromCanvasElement(image as HTMLCanvasElement);
        } else if (((image as HTMLImageElement).src))  {
          return loadFromImageElement(image as HTMLImageElement);
        }
      })
      .catch(err => {
        setPictureLoading(false);
        setError(true);

        throw err;
      });

  }, [
    loadFromCanvasElement,
    loadFromImageElement,
    // maxHeight,
    maxWidth,
  ]);

  return {
    loading: isPictureLoading,
    error,
    load: loadSource,
    value,
  } as const;
};

export default useLoadImage;

type ImageData = {
  height: number;
  width: number;
};

const Defaults = {
  maxHeight: 230,
  maxWidth:  230,
};