import { useCallback, useState } from 'react';
import { Remirror } from '@remirror/react';
import type { SurveyRichText } from '@/types';
import { useRichTextEditorRegister } from '@/containers/SurveyBuilder';
import { SurveyRichTextEditor } from '@/components/Survey.RichText/Editor';
import {
  BoldButton,
  ImageButton,
  ItalicButton,
  LinkButton,
  ListBulletedButton,
  ListOrderedButton,
  UnderlineButton,
  Toolbar as TB,
  ColorButton,
  LinkContainer,
} from '@/components/Remirror';
import { useBasicSurveyRichTextEditor } from './hooks/useBasicSurveyRichTextEditor';
import { RichTextToggleButton } from './RichTextToggleButton';
import { EditorImperativeHandle } from './Editor.ImperativeHandle';
import { PipingAnchor } from './Editor.Piping.Anchor';
import { PipingMenuContainer } from './Editor.Piping.Container';
import { PipingButton } from './Editor.Piping.Button';
import editorStyles from './style/Editor.css';
import styles from './style/Node.FindAndReplace.Editor.css';
import type { SurveyRichTextEditorOnChange, FARInsertItem } from './interfaces';
import { getFindAndReplaceRichTextIdentifier } from './utils';

type Props = {
  item: FARInsertItem;
  onChange: (value: SurveyRichText.RichTextValue) => void;
  initialState: SurveyRichText.RichTextValue;
};

export const Editor = ({ initialState, item, onChange }: Props) => {
  const { manager, state, onChange: rmOnChange } = useBasicSurveyRichTextEditor({
    initialState,
    placeholder: 'Enter Label',
  });

  const handleChange: SurveyRichTextEditorOnChange = useCallback(params => {
    rmOnChange(params);

    if (!params.tr?.docChanged) return;

    const value = params.state.doc.toJSON() as SurveyRichText.RichTextValue;

    onChange(value);
  }, [
    rmOnChange,
    onChange,
  ]);

  const editorRef = useRichTextEditorRegister({ identifier: getFindAndReplaceRichTextIdentifier(item.label) });

  const [toolbarOpen, setToolbarOpen] = useState(false);

  return (
    <Remirror
      classNames={[editorStyles.editor]}
      editable={true}
      manager={manager}
      state={state}
      placeholder={item.label}
      onChange={handleChange}>
      <LinkContainer>
        {toolbarOpen && <ToolBar item={item} />}
        <div className={styles.editorRow}>
          <SurveyRichTextEditor className={editorStyles.editor} />
          <RichTextToggleButton enabled={toolbarOpen} onChange={setToolbarOpen} />
        </div>
        <EditorImperativeHandle ref={editorRef} />
      </LinkContainer>
    </Remirror>
  );
};

type ToolbarProps = {
  item: FARInsertItem;
};

const ToolBar = (props: ToolbarProps) => {
  return (
    <TB className={styles.toolbar}>
      <PipingMenuContainer>
        <PipingAnchor activeQuestionOrdinal={props.item.minOrdinal}>
          {(_, ref) => (
            <PipingButton ref={ref as React.Ref<HTMLButtonElement>} />
          )}
        </PipingAnchor>
      </PipingMenuContainer>
      <BoldButton />
      <ItalicButton />
      <UnderlineButton />
      <LinkButton />
      <ImageButton />
      <ListBulletedButton />
      <ListOrderedButton />
      <ColorButton />
    </TB>
  );
};