import { useCallback } from 'react';
import { usePermissionValidator, useRoleValidator } from '@utils/store';

type Params = {
  permissions?: Store.User.Permissions;
  roles?:       Store.User.Roles;
};

const useSessionValidator = () => {
  const validatePermission = usePermissionValidator();
  const validateRole = useRoleValidator();

  const validator = useCallback((params: Params) => {
    return validatePermission(params.permissions)
        && validateRole(params.roles);
  }, [
    validatePermission,
    validateRole,
  ]);

  return validator;
};

export { useSessionValidator };
export default useSessionValidator;