import { Line, Point } from '@/types';

export function distanceBetweenPoints(p1: Point, p2: Point) {
  const dx = p2.x - p1.x;
  const dy = p2.y - p1.y;

  return Math.sqrt(dx * dx + dy * dy);
}

export function closestPointOnLine(point: Point, line: Line): Point {
  const dx = line.e.x - line.s.x;
  const dy = line.e.y - line.s.y;
  const l2 = dx * dx + dy * dy;

  // if (l2 === 0) {
  //   return distanceBetweenPoints(point, line.s);
  // }

  let t = ((point.x - line.s.x) * dx + (point.y - line.s.y) * dy) / l2;
  t = Math.max(0, Math.min(1, t));

  return {
    x: line.s.x + t * dx,
    y: line.s.y + t * dy,
  };
}

export function distanceToLine(point: Point, line: Line) {
  const closestPoint = closestPointOnLine(point, line);

  return distanceBetweenPoints(point, closestPoint);
}

export function containsPoint(rect: DOMRect, point: Point) {
  return rect.x <= point.x &&
    point.x <= rect.x + rect.width &&
    rect.y <= point.y &&
    point.y <= rect.y + rect.height;
}