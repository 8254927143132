import { Chip } from '@presentation/Chip';
import SearchAutocomplete from '@/components/SearchAutocomplete';
import styles from './style.css';

const SearchAutocompleteWithTags = ({
  // Autocomplete
  onSelection,
  searchType,
  filterType = searchType,
  selectedItems,

  // Tags
  removeItem,

  placeholder = '',
  autocompleteClass = '',
  displayCustomItem = true,
  displaySearchItems = true,
  minCharactersForFetch = 1,
}) => {
  const renderTags = () => {
    return (
      <div className={styles.row}>
        <div className={styles.tags}>
          {selectedItems.map(x =>
            <Chip key={x.id} className={styles.tag} color='selected'>
              <Chip.Body.Action onClick={removeItem(x)} variant='remove'>
                {x.name}
              </Chip.Body.Action>
            </Chip>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <SearchAutocomplete
        autocompleteClass={autocompleteClass}
        placeholder={placeholder}
        selectedItems={selectedItems}
        onSelection={onSelection}
        searchType={searchType}
        filterType={filterType}
        displayCustomItem={displayCustomItem}
        displaySearchItems={displaySearchItems}
        minCharactersForFetch={minCharactersForFetch} />
      {renderTags()}
    </>
  );
};

export default SearchAutocompleteWithTags;
