import type { CalendarState, Call, Contact } from '@/types';
import { Calendar } from '@/components/Calendar/Calendar';
import { Providing } from './Providing';

type Props = {
  call:       Pick<Call, 'id'>;
  className?: string;
  onSubmit:   (data: CalendarState) => Promise<unknown>;
  with:       Pick<Contact, 'id'>;
};

const Proposals = ({
  className = '',
  ...props
}: Props) => {

  const calendar = {
    call: props.call,
    with: props.with,
  };

  return (
    <Calendar calendar={calendar}>
      <Providing
        className={className}
        onSubmit={props.onSubmit} />
    </Calendar>
  );
};

export { Proposals };
export default Proposals;