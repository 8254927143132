import { useCallback, useMemo } from 'react';
import { cx } from '@utils';
import type SurveyTab from './SurveyTab';
import styles from './style/Tabs.css';

type Props = {
  activeTab: SurveyTab;
  onClick: (tab: SurveyTab) => void;
  tabs: SurveyTab[];
};

const SurveyBuilderTabs = ({ activeTab, tabs, onClick }: Props) => {
  const renderTab = useCallback((tab: SurveyTab) => {
    const className = cx(styles.tab, {
      [styles.active]: tab === activeTab,
      [styles.inactive]: tab !== activeTab,
    });

    return (
      <div
        key={tab}
        className={className}
        onClick={() => onClick(tab)}>
        {tab}
      </div>
    );
  }, [activeTab, onClick]);

  const Tabs = useMemo(() => tabs.map(renderTab), [tabs, renderTab]);

  return (
    <div className={styles.tabs}>
      {Tabs}
    </div>
  );
};

export default SurveyBuilderTabs;