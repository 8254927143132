export function getUserAgent() {
  if (!navigator) return null;
  return navigator.userAgent;
}

export function checkChrome(ua: string) {
  return /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9.]+)(:?\s|$)/.test(ua);
}

export function isChrome() {
  return checkChrome(getUserAgent());
}

export function checkFirefox(ua: string) {
  return /Firefox\/([0-9.]+)(?:\s|$)/.test(ua);
}

export function isFirefox() {
  return checkFirefox(getUserAgent());
}

export function checkSafari(ua: string) {
  return /Version\/([0-9._]+).*Safari/.test(ua);
}

export function isSafari() {
  return checkSafari(getUserAgent());
}