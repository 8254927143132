import { useContext } from 'react';
import { ProjectStateContext } from '@containers/GroupProject/Context';
import { ProjectTagsQueryContainer } from '@containers/Group.Project.Tags/Project.Tags.Query.Container';
import { ButtonActivityIndicator } from '@presentation';
import { ProjectTags } from '@screens/Project.Tags/Project.Tags';
import * as Layout from '@/components/MedicalProfile/Layout';
import { OnboardingStepperContext } from './Context';
import styles from './style/Onboarding.Categories.css';

type Props = unknown;

export const ReviewSetup = (props: Props) => {
  const state = useContext(ProjectStateContext);

  return (
    <ProjectTagsQueryContainer
      enabled={!!state?.project?.id}
      projectId={state?.project?.id}>
      <TagsTable />
    </ProjectTagsQueryContainer>
  );
};

const TagsTable = (props: Props) => {
  const stepper = useContext(OnboardingStepperContext);

  return (
    <Layout.Screen classes={{
      root: styles.review,
      wrap: styles.gutter,
    }}>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <Layout.Header
            title={copy.title} />
          <ProjectTags loadingStateRowCount={10} />
          <Layout.Footer className={styles.complete}>
            <ButtonActivityIndicator
              className={styles.btn}
              color="affirmative"
              onClick={stepper.next}>
              Complete
            </ButtonActivityIndicator>
          </Layout.Footer>
        </div>
      </div>
    </Layout.Screen>
  );
};

ReviewSetup.displayName = 'Onboarding.ReviewSetup';

const copy = {
  title: `Almost done! Reorder tags or change colors before completing the setup process.`,
};