import { useCallback, useState } from 'react';
import * as CallNotify from '$admin/components/Call.Notify';
import { Modal } from './EditSettings.Modal';
import { CallEditSettingsContext } from './Context';
import { OnSuccess, SetContext } from './interfaces';

type Props = {
  children:   React.ReactNode;
  onSuccess?: OnSuccess;
};

export const Container = (props: Props) => {
  const [state, dispatch] = useState<SetContext.Params>(null);

  const resetContext = useCallback(() => {
    dispatch(null);
  }, []);

  const value = {
    params: state,
    resetContext,
    setContext: dispatch,
  };

  return (
    <CallEditSettingsContext.Provider value={value}>
      {props.children}
      {state &&
        <CallNotify.Container
          callId={state.callId}
          projectId={state.projectId}>
          <Modal
            callId={state.callId}
            projectId={state.projectId}
            onSuccess={props.onSuccess} />
        </CallNotify.Container>}
    </CallEditSettingsContext.Provider>
  );
};

Container.displayName = 'Call.EditSettings.Container';