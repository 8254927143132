import { PaidMessagingContainer } from '@containers/PlatformMessage';
import { RequestCallContainer } from '@containers/RequestCall';
import { cx } from '@utils';
/* import { HeaderActions } from './HeaderActions'; */
import { HeaderImage } from './HeaderImage';
import { HeaderInfo } from './HeaderInfo';
import styles from './style/Profile.Header.css';

const ProfileHeader = () => {

  return (
    <PaidMessagingContainer>
      <RequestCallContainer>
        <div className={cx(styles.root, styles.sm)}>
          <div className={styles.header}>
            <HeaderImage />
            {/* <HeaderActions /> */}
          </div>

          <HeaderInfo />
        </div>

        <div className={cx(styles.root, styles.lg)}>
          <div className={styles.header}>
            <HeaderImage />

            <div className={styles.columns}>
              <HeaderInfo />
              {/* <HeaderActions /> */}
            </div>
          </div>
        </div>
      </RequestCallContainer>
    </PaidMessagingContainer>
  );
};

export { ProfileHeader };
export default ProfileHeader;