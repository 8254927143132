import { useCallback, useContext } from 'react';
import { type LeadStatus, utils } from '@enums';
import { QueryParamsContext } from '$admin/Search/Leads/containers';
import { type OnSelectChange, Select } from '$admin/components';
import styles from './style/Column.Status.css';

const OptionsMap = {
  0: 'All',
  ...utils.LeadStatus.values().reduce((acc, x) => ({ ...acc, ...enumToProp(x) }), {}),
};

function enumToProp(val: LeadStatus) {
  return {
    [val]: utils.LeadStatus.getName(val),
  };
}

export const Filter = () => {
  const [qp, setQuery] = useContext(QueryParamsContext);

  const updateStatus: OnSelectChange<0 | LeadStatus> = useCallback(e => {
    const value = +e.target.value;

    setQuery({
      statusId: value === 0 ? undefined : value,
    }, 'pushIn');
  }, [setQuery]);

  return (
    <Select
      className={styles.field}
      defaultValue={0}
      id="status"
      name="status"
      onChange={updateStatus}
      options={OptionsMap}
      value={qp.statusId} />
  );
};

Filter.displayName = 'Column.Status.Filter';