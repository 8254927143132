import { useMemo } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks/useSurveyBuilderContext';
import { pipingTypeMap } from '@/containers/SurveyBuilder/utils';
import { useQuestionOrdinalCutoff } from './useQuestionOrdinalCutoff';

type Options = {
  activeQuestionOrdinal?: number;
};

export const useEligiblePipingQuestions = (opts: Options = {}) => {
  const [state] = useSurveyBuilderState();

  const questionOrdinalCutoff = useQuestionOrdinalCutoff(opts);

  return useMemo(() => {
    return state.survey.questions
      .filter(f => f.ordinal <= questionOrdinalCutoff && !!pipingTypeMap[f.typeId].length);
  }, [
    questionOrdinalCutoff,
    state.survey.questions,
  ]);
};