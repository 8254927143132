import { useState, useMemo, useCallback } from 'react';
import { Link2 } from 'react-feather';
import { useMutation } from '@tanstack/react-query';
import { getLocationFor } from '@utils';
import { promptEntityOnboarding } from '@api/projects.entities';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import boostGraphic from 'static/images/website/project-entities/boosting-graphic-2.svg?url';
import Toast from '@/components/Toast';
import { Button } from '@/components/Button';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal, Header } from '@/components/Modal';
import Input from '@/components/Input';
import { useModal } from '../hooks/useModal';
import styles from './styles.css';

type Props = IProjectId & Pick<ModalProps, 'open' | 'onClose'>;

type SubProps = IProjectId & {
  setScreenType: (screen: ScreenType) => void;
};

type ScreenType = 'landing' | 'prompt' | 'explanation';

export const EntityBoostPrompt = ({ projectId, ...modalProps }: Props) => {
  const [screenType, setScreenType] = useState<ScreenType>('landing');
  const Component = useMemo(() => ScreenMap[screenType], [screenType]);

  return (
    <Modal {...modalProps}>
      <Component projectId={projectId} setScreenType={setScreenType} />
    </Modal>
  );
};

const LandingScreen = ({ setScreenType }: SubProps) => {
  return (
    <div>
      <Header>
        It looks like this project is missing boosted medical terms for the transcription tool.
      </Header>
      <div className={styles.subTitle}>
        Boosting is an important feature to support transcript quality and must be entered before scheduling. Take a moment to ensure at least 10 boosted terms are set up before proceeding to scheduling.
      </div>
      <div className={styles.buttons}>
        <Button.Destructive variant='brick' onClick={() => setScreenType('prompt')}>A team member can do this</Button.Destructive>
        <Button variant='brick' onClick={() => setScreenType('explanation')}>I can do this</Button>
      </div>
    </div>
  );
};

const PromptTeamMember = (props: SubProps) => {
  const [email, setEmail] = useState<string>('');
  const isEmailValid = useMemo(() => {
    return !!email;
  }, [email]);
  const copyText = useCallback(() => {
    const url = `${process.env.FRONT_BASE_URL}${getLocationFor.project.entitiesOnboarding({ slug: props.projectId.toString() }).pathname}`;

    navigator.clipboard.writeText(url).then(() => {
      Toast.success({
        title: 'Copied!',
      });
    });
  }, [props.projectId]);

  const promptMutation = useMutation(() => {
    return promptEntityOnboarding({
      projectId: props.projectId,
      toUserEmail: email,
    });
  }, {
    onSuccess: () => {
      Toast.success({
        title: `We've emailed ${email} asking them to complete entity boosting`,
      });
    }, onError: () => {
      Toast.error({
        title: `There was an error contacting ${email}`,
      });
    },
  });
  return (
    <div>
      <Header>
        Ok, great! Let your team member know by email or send a link to the boosting page.
      </Header>
      <div className={styles.emailRow}>
        <Input
          type='email'
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder='Input Email' />
        <ButtonActivityIndicator
          disabled={!isEmailValid}
          implicitDisable={false}
          loading={promptMutation.isLoading}
          onClick={() => promptMutation.mutate()}
          variant='brick'>
          Send Email
        </ButtonActivityIndicator>
      </div>
      <div>
        <Button.Destructive
          variant='brick'
          className={styles.copyBtn}
          onClick={copyText}>
          <Link2 />
          Copy Link to Share
        </Button.Destructive>
      </div>
    </div>
  );
};

const Explanation = (props: SubProps) => {
  return (
    <div>
      <Header>
        Improve transcripts with medical term boosting
      </Header>
      <div className={styles.subTitle}>
        Improve the accuracy of the automated transcripts on this project by providing the medical terms or drug names you expect to come up in interviews
      </div>
      <div className={styles.graphicContainer}>
        <img className={styles.boostGraphic} src={boostGraphic} />
      </div>
      <div>
        <Button
          className={styles.continueBtn}
          variant='brick'
          target={window.location.pathname.includes('/admin') ? '_blank' : null}
          to={getLocationFor.project.entitiesOnboarding({ slug: props.projectId.toString() })}>
          Continue
        </Button>
      </div>
    </div>
  );
};

const ScreenMap: Record<ScreenType, React.FC<SubProps>> = {
  'landing': LandingScreen,
  'prompt': PromptTeamMember,
  'explanation': Explanation,
};

export const useEntityBoostModal = () => useModal(EntityBoostPrompt);