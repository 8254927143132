import type { MultiTextboxAnswerProps } from './interfaces';
import { Rationale } from './Rationale';
import styles from './style/MultiTextbox.css';

export const MultiTextbox = ({ answer, question, rationale }: MultiTextboxAnswerProps) => {

  if (answer.dontKnow?.selected) {
    return (
      <>{`"${answer.dontKnow.label}" selected.`}</>
    );
  }

  return (
    <div>
      {answer.values.map((v, i) => {
        const label = question.settings.entryLabels[i] || `${i + 1}`;

        return (
          <div key={i}>
            <span className={styles.label}>{label})</span> {v}
          </div>
        );
      })}
      <Rationale value={rationale} />
    </div>
  );
};

export default MultiTextbox;