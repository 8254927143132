import { useSelector, shallowEqual } from 'react-redux';

const selectUser = (state: Store.State) => state.user;

const useSelectUser = () => {
  const user = useSelector<Store.State, Store.User>(selectUser, shallowEqual);

  return user;
};

export { useSelectUser };
export default useSelectUser;