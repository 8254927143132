import { createElement, useCallback, useState } from 'react';
import { ButtonActivityIndicator } from '@presentation';
import { useAsyncStateLazy } from '@utils';
import * as api from '$admin/api';
import {  Project } from '$admin/interfaces';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { Header } from '@/components/Modal/Header';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import styles from './style/Project.RecommendMember.css';

type Props = {
  projectId:        number;
  recommendation: Pick<Project.Members.Recommendation, 'id' | 'message'>;
  user: Pick<Project.Members.Item['user'], 'fullname'>;
} & Pick<ModalProps, 'onClose' | 'open'>;

const EditMemberRecommendationModal = (props: Props) => {
  const [message, setMessage] = useState(props.recommendation.message || '');

  const handleMessageInput = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => setMessage(e.target.value), []);

  const handleSubmit = useCallback(() => {
    return api.projects.members.updateMemberRecommendation({
      message: message?.trim?.(),
      projectId: props.projectId,
      recommendationId: props.recommendation.id,
    })
    .then(props.onClose);
  }, [
    message,
    props.onClose,
    props.projectId,
    props.recommendation.id,
  ]);

  const [response, submit] = useAsyncStateLazy(handleSubmit, [handleSubmit]);

  return (
    <Modal
      onClose={props.onClose}
      open={props.open}>
      <div className={styles.root}>
        <Header text="Edit Message" />

        <div className={styles.body}>
          <div className={styles.row}>
            <div className={styles.label}>Name</div>
            <div>{props.user.fullname}</div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>Project Bio</div>
            <div className={styles.field}>
              <textarea
                autoFocus
                className={styles.textarea}
                onChange={handleMessageInput}
                rows={3}
                value={message} />
            </div>
          </div>
        </div>

        <div className={styles.footer}>
          <ButtonSet>
            <ButtonActivityIndicator
              className={styles.btn}
              loading={response.loading}
              onClick={submit}
              variant="brick">
              Save
            </ButtonActivityIndicator>
          </ButtonSet>
        </div>
      </div>
    </Modal>
  );
};

export const EditMemberRecommendation = (props: Props) => {
  if (!props.open) return null;

  return createElement(EditMemberRecommendationModal, props);
};

const defaultProps = {
  recommendation: {
    message: '',
  },
  user: {
    fullname: '',
  },
};

EditMemberRecommendation.defaultProps = defaultProps;
EditMemberRecommendation.displayName = 'Modal.Project.EditMemberRecommendation';