import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import * as QK from '@consts/querykey';
import { CSVQueriesContext } from '@containers/Group.Contacts.Import/Context';
import { useSelectGroup } from '@containers/Store';
import { AnimatedLoader } from '@/components/ActivityIndicator/AnimatedLoader';
import * as Layout from './Layout';
import styles from './style/CommitSummary.css';

type Props = unknown;

export const CommitSummary = (props: Props) => {
  const location = useLocation();
  const searchparams = new URLSearchParams(location.search);
  const app = searchparams.get('app');

  if (app === 'hubspot') {
    return (
      <HubSpotImportSummary />
    );
  } else if (app === 'salesforce') {
    return (
      <SalesforceImportSummary />
    );
  }

  return (
    <CSVImportSummary />
  );
};

const HubSpotImportSummary = (props: Props) => {
  const group = useSelectGroup();

  const query = useQuery([QK.Groups.Contacts.Import.HubSpot.Finalize.Get, group?.id], () => {
    return api.groups.contacts.finalizeHubSpot({
      groupId: group.id,
    });
  }, {
    enabled: !!group?.id,
  });

  if (query.isLoading) {
    return (
      <AnimatedLoader />
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Layout.Header>{copy.header.title}</Layout.Header>
        <Layout.Subheader>{copy.header.description}</Layout.Subheader>
        <div className={styles.main}>
          <InsertContacts value={query.data.insertCount} />
          <UpdateContacts value={query.data.updateCount} />
        </div>
      </div>
    </div>
  );
};

const SalesforceImportSummary = (props: Props) => {
  const group = useSelectGroup();

  const query = useQuery([QK.Groups.Contacts.Import.Salesforce.Finalize.Get, group?.id], () => {
    return api.groups.contacts.finalizeSalesforce({
      groupId: group.id,
    });
  }, {
    enabled: !!group?.id,
  });

  if (query.isInitialLoading) {
    return (
      <AnimatedLoader />
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Layout.Header>{copy.header.title}</Layout.Header>
        <Layout.Subheader>{copy.header.description}</Layout.Subheader>
        <div className={styles.main}>
          <InsertContacts value={query.data.insertCount} />
          <UpdateContacts value={query.data.updateCount} />
        </div>
      </div>
    </div>
  );
};

const CSVImportSummary = (props: Props) => {
  const { summary } = useContext(CSVQueriesContext);

  if (summary.isInitialLoading) {
    return (
      <AnimatedLoader />
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Layout.Header>{copy.header.title}</Layout.Header>
        <Layout.Subheader>{copy.header.description}</Layout.Subheader>
        <div className={styles.main}>
          <InsertContacts value={summary.data.insertCount} />
          <UpdateContacts value={summary.data.updateCount} />
        </div>
      </div>
    </div>
  );
};

CommitSummary.displayName = 'CommitSummary';

type SummaryItemProps = {
  value: number;
};

const InsertContacts = (props: SummaryItemProps) => {
  return (
    <div className={styles.item}>
      <div className={styles.value}>{props.value}</div>
      <div className={styles.title}>{copy.insert.title}</div>
      <div className={styles.desc}>{copy.insert.description}</div>
    </div>
  );
};

const UpdateContacts = (props: SummaryItemProps) => {
  return (
    <div className={styles.item}>
      <div className={styles.value}>{props.value}</div>
      <div className={styles.title}>{copy.update.title}</div>
      <div className={styles.desc}>{copy.update.description}</div>
    </div>
  );
};

const copy = {
  header: {
    description: `We’re ready to import your contacts! Please review the information below and click Start Import if everything looks alright.`,
    title: `Start Import`,
  },
  insert: {
    description: `New contact records will be created.`,
    title: `New Contacts`,
  },
  update: {
    description: `Existing contacts will be updated via a data merge based on email addresses.`,
    title: `Existing Contacts`,
  },
};