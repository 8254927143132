import { forwardRef, Ref, useCallback } from 'react';
import { Link } from 'react-router-dom';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import Popper from '@mui/material/Popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { ChevronRight } from 'react-feather';
import { getLocationFor, hasInternalAdminRole } from '@utils';
import { useSelectUser } from '@containers/Store';
import { useMatchProjectIdFromUrl, useProjectActions, useProjectActionsValidity } from '@containers/GroupProject/hooks';
import { MoreHorizontalAnchor } from '@presentation/Anchor';
import { BaseProjectSurvey, ProjectBase } from '@/types';
import { PopperMenu, PopperMenuItem, usePopperState } from '@/components/Popper';
import { useDownloadRecruitmentGrid } from '@/components/GroupProject/hooks';
import { useExportCallModal, useExportSurveyModal } from '@/components/GroupProject/ExportProjectDataModal';
import styles from './styles/SurveyActions.css';

type Props = {
  survey: BaseProjectSurvey;
  project: Pick<ProjectBase, 'id' | 'name' | 'parentId'>;
};

export const SurveyActions = (props: Props) => {
  const [toggleSurveyExport, SurveyExportModal] = useExportSurveyModal();
  return (
    <>
      <PopupState variant="popper" popupId="survey-actions-popper">
        {popupState => (
          <div>
            <div {...bindToggle(popupState)}>
              <MoreHorizontalAnchor open={popupState.isOpen} />
            </div>
            <Popper
              placement='bottom-end'
              {...bindPopper(popupState)}>
              <ClickAwayListener
                onClickAway={popupState.close}>
                <ActionsMenu
                  project={props.project}
                  survey={props.survey}
                  onSurveyReport={toggleSurveyExport}
                  onClose={popupState.close} />
              </ClickAwayListener>
            </Popper>
          </div>
        )}
      </PopupState>
      <SurveyExportModal surveyId={props.survey.id} project={props.project} />
    </>
  );
};

type MenuProps = {
  onClose: () => void;
  onSurveyReport: () => void;
} & Pick<Props, 'project' | 'survey'>;

const ActionsMenu = forwardRef(({ onClose, ...props }: MenuProps, ref: Ref<HTMLDivElement>) => {
  const { slug } = useMatchProjectIdFromUrl();

  const { open, setOpen, setPopperElement, setReferenceElement, styles: popperStyles, attributes } = usePopperState({
    placement: 'right-start',
  });

  const { download, isFetching } = useDownloadRecruitmentGrid({
    filename: `${props.project.name} Recruitment Grid.xlsx`,
    projectId: props.project.id,
    surveyId: props.survey.id,
  });

  const handleExportRecruitmentGrid = useCallback(() => {
    download({
      name: `Project Recruitment Grid`,
      title: `Generating Report`,
    });
    onClose();
  }, [
    download,
    onClose,
  ]);

  return (
    <>
      <PopperMenu ref={ref}>
        <PopperMenuItem
          onClick={() => {
            onClose();
          }}>
          <Link
            target="_blank"
            to={getLocationFor.project.surveyPreview({ surveyVersionId: props.survey.latestVersion })}>Preview Survey
          </Link>
        </PopperMenuItem>
        <PopperMenuItem>
          <Link to={getLocationFor.project.surveyMonitoring({ slug, surveyId: props.survey.id })}>
            Survey Monitoring
          </Link>
        </PopperMenuItem>
        <PopperMenuItem
          className={styles.exportItem}
          onClick={() => setOpen(!open)}
          ref={setReferenceElement}>
          Export
          <ChevronRight className={styles.icon} />
        </PopperMenuItem>
      </PopperMenu>
      {open &&
        <div
          ref={setPopperElement}
          style={popperStyles.popper}
          {...attributes.popper}>
          <ExportMenu
            onRecruitmentGrid={handleExportRecruitmentGrid}
            onClose={onClose}
            {...props} />
        </div>}
    </>
  );
});

type ExportMenuProps = {
  onClose: () => void;
  onSurveyReport: () => void;
  onRecruitmentGrid: () => void;
} & Pick<Props, 'project' | 'survey'>;

const ExportMenu = forwardRef((props: ExportMenuProps, ref: Ref<HTMLDivElement>) => {
  const user = useSelectUser();

  const isAdmin = hasInternalAdminRole(user);

  return (
    <PopperMenu ref={ref}>
      <PopperMenuItem onClick={props.onSurveyReport}>
        Survey Data
      </PopperMenuItem>
      {isAdmin &&
        <PopperMenuItem onClick={props.onRecruitmentGrid}>
          Recruitment Grid
        </PopperMenuItem>}
    </PopperMenu>
  );
});