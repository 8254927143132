import { useCallback, useMemo } from 'react';
import { WaveSurferParams } from 'wavesurfer.js/types/params';
import { PluginDefinition } from 'wavesurfer.js/types/plugin';
import WaveSurferCursorPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.cursor';
import { useAudioPlayerRef, useAudioPlayerState, useBootstrapAudioPlayer, useToggleAudioPlaying } from '@containers/AudioPlayer';
import { cx } from '@utils';
import { WaveformData } from '@/types';
import { useWaveSurferContext, WaveSurferContainer } from '@/components/WaveSurfer';
import Button from './Button';
import Time from './Progress.Time';
import { formatTimestamp } from './utils';
import styles from './style/WavePlayer.css';

type Props = {
  className?: string;
  data?: WaveformData;
  audio: React.DetailedHTMLProps<React.AudioHTMLAttributes<HTMLAudioElement>, HTMLAudioElement>;
};

const AudioWavePlayer = ({
  audio,
  data,
  className,
}: Props) => {

  const waveform = useWaveSurferContext();

  const [state] = useAudioPlayerState();
  const [_, setPlayerRef] = useAudioPlayerRef();
  const togglePlaying = useToggleAudioPlaying()

  const bootstrapAudioPlayer = useBootstrapAudioPlayer();

  const setRef = useCallback((el: HTMLAudioElement) => {

    if (waveform?.load && el) {
      if (data) {
        waveform.load(el, data);
      } else {
        waveform.load(el);
      }
    }

    setPlayerRef(el);
  }, [
    data,
    waveform?.load,
  ]);

  return (
    <div className={cx(styles.root, className)}>
      <Button
        playing={state.playing}
        togglePlaying={togglePlaying} />
      <div
        id={containerId}
        className={styles.wave} />
      <Time
        className={styles.duration}
        currentTime={state.currentTime}
        duration={state.duration} />
      <audio
        ref={setRef}
        {...bootstrapAudioPlayer()}
        {...audio} />
    </div>
  );
};

const containerId = 'wf-container';

const AudioWavePlayerWithState = (props: Props) => {

  const params = useMemo(() => {
    return {
      cursorColor: '#28AAE1', // pri-02
      progressColor: '#2880E0', // sec-05
      waveColor: '#f2f2f2', // gray-xl

      barRadius: 6,
      barWidth: 5,
      height: 80,

      backend: 'MediaElement',
      container: `#${containerId}`,
      responsive: true,

      plugins: [
        // @ts-ignore
        WaveSurferCursorPlugin.create({
          color: 'var(--black-l)',
          showTime: true,
          opacity: .8,
          customShowTimeStyle: {
            'background-color': 'var(--black-l)',
            color: 'var(--pri-01)',
            padding: '4px',
            'font-size': '12px',
            'font-family': 'var(--font-reg)',
          },
          formatTimeCallback: formatTimestamp,
        }) as PluginDefinition,
      ],
    } as WaveSurferParams;
  }, []);

  return (
    <WaveSurferContainer params={params}>
      <AudioWavePlayer {...props} />
    </WaveSurferContainer>
  );
};

export { AudioWavePlayerWithState as AudioWavePlayer };
export default AudioWavePlayerWithState;