import {
  SurveyLogicItemValidationsContext,
  RunSurveyLogicValidationsContext,
  SurveyConditionsMatchValidationsContext,
} from './context';
import { useRunSurveyLogicValidations } from './hooks';

type Props = ChildrenProps;

export const SurveyLogicItemValidationContainer = (props: Props) => {

  const {
    validations,
    matchValidation,
    runValidations,
  } = useRunSurveyLogicValidations();

  return (
    <SurveyLogicItemValidationsContext.Provider value={validations}>
      <SurveyConditionsMatchValidationsContext.Provider value={matchValidation}>
        <RunSurveyLogicValidationsContext.Provider value={runValidations}>
          {props.children}
        </RunSurveyLogicValidationsContext.Provider>
      </SurveyConditionsMatchValidationsContext.Provider>
    </SurveyLogicItemValidationsContext.Provider>
  );
};
