import type { ChangeEvent } from 'react';
import { useCallback, useContext, useMemo, useState } from 'react';
import { FileUploadReviewStateContext, FileUploadReviewDispatchContext } from '@containers/WorkspaceFileUpload/Context';
import { Checkbox } from '@/components/Checkbox';
import { Header } from '@/components/Modal/Header';
import { Button } from '@/components/Button';
import { trunc } from '@utils';
import { FileUploadReviewStepperActionsContext } from './context';
import styles from './style/Review.FilesReview.css';

export const FileUploadReview = () => {

  const stepperActions = useContext(FileUploadReviewStepperActionsContext);
  const reviewState = useContext(FileUploadReviewStateContext);
  const dispatch = useContext(FileUploadReviewDispatchContext);

  const [transcribeable] = useState(reviewState.transcribeable);

  const handleToggle = useCallback((identifier: string) => () => {
    dispatch({
      type: 'file-transcribe-toggled',
      payload: { identifier },
    });
  }, [dispatch]);

  const toggleAll = useCallback((event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    dispatch({
      type: 'transcribeable-updated',
      payload: {
        value: checked ? transcribeable : [],
      },
    });
  }, [dispatch, transcribeable]);

  const allRowsChecked = useMemo(() => {
    return transcribeable.every(k => reviewState.transcribeable.includes(k));
  }, [reviewState.transcribeable, transcribeable]);

  return (
    <div className={styles.root}>
      <Header text="Transcribe Files" />

      <div className={styles.body}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.transcribe}>
                <Checkbox
                  checked={allRowsChecked}
                  onChange={toggleAll} />
              </th>
              <th className={styles.name}>File</th>
            </tr>
          </thead>

          <tbody>
            {Object.keys(reviewState.files).map(identifier => {
              const file = reviewState.files[identifier];
              const checked = reviewState.transcribeable.includes(identifier);
              const canCheck = transcribeable.includes(identifier);

              return (
                <tr key={identifier}>
                  <td className={styles.transcribe}>
                    <Checkbox
                      disabled={!canCheck}
                      checked={checked}
                      onChange={handleToggle(identifier)} />
                  </td>

                  <td className={styles.name}>{trunc(file.name, 45)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className={styles.footer}>
        <Button.Primary
          variant="brick"
          onClick={stepperActions.next}>
          Confirm
        </Button.Primary>
      </div>
    </div>
  );
};