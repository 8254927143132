import { Remirror } from '@remirror/react';
import { useTaggedMomentBuilderRichTextEditor } from '@/components/Transcript/hooks';
import styles from '@/components/Transcript/style/RichText.css';
import { useTranscriptDocumentContext } from './hooks';

type Props = ChildrenProps;

export const TaggedMomentRichTextContainer = (props: Props) => {

  const transcript = useTranscriptDocumentContext();

  const { manager, onChange, state } = useTaggedMomentBuilderRichTextEditor({
    transcript: transcript.document,
  });

  return (
    <Remirror
      classNames={[styles.root, styles.noselect]}
      editable={false}
      manager={manager}
      onChange={onChange}
      initialContent={state}>
      {props.children}
    </Remirror>
  );
};