import { useCallback } from 'react';
import { useTargetProductProfileData } from '@containers/SurveyBuilder/hooks';
import { SurveyTemplate } from '@/types/survey.templates';
import { useCanSaveList, useListElements } from './list-elements';

const minimum = 1;
const maximum = 10;

export const useCanSaveOtherAttributes = (items: SurveyTemplate.LinkedEntity[]) => {

  return useCanSaveList({
    items,
    minItems: minimum,
    maxItems: maximum,
  });
};

export const useTemplateOtherAttributes = () => {
  const [templateData, dispatch] = useTargetProductProfileData();

  const {
    canAdd: canAddOtherAttribute,
    canRemove: canRemoveOtherAttribute,
    items: otherAttributes,
    onAddItem: onAddOtherAttribute,
    onRemoveItem: onRemoveOtherAttribute,
    onUpdateItem: onUpdateOtherAttribute,
  } = useListElements({
    min: minimum,
    max: maximum,
    initial: templateData.otherAttributes,
  });

  const onSubmit = useCallback(() => {
    dispatch({
      type: 'template-other-attributes-updated',
      value: otherAttributes,
    });
  }, [dispatch, otherAttributes]);

  return {
    canAddOtherAttribute,
    canRemoveOtherAttribute,
    onAddOtherAttribute,
    onRemoveOtherAttribute,
    onUpdateOtherAttribute,
    onSubmit,
    otherAttributes,
  };
};