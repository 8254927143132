import Axios, { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as api from '@api';
import { RecentProfile } from '@/types';
import { useIsInternalUser } from '@/containers/Store';

export default function useRecentProfiles<TData = RecentProfile[]>(
  options?: UseQueryOptions<RecentProfile[], AxiosError, TData>
) {
  const isInternalUser = useIsInternalUser();

  return useQuery(['recently-viewed-profiles'], async () => {
    const source = Axios.CancelToken.source();

    const p = api.users.getRecentProfiles({
      cancelToken: source.token,
    });

    p.cancel = () => {
      source.cancel();
    };

    return p.then(result => result.items);
  }, {
    staleTime: 60 * 1000,
    enabled: isInternalUser,
    ...options,
  });
}

export { useRecentProfiles };
