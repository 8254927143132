import { useContext } from 'react';
import { createNamedContext } from '@utils';
import type { Filters, ProjectCreationContextValue, Setup, SurveyModule, ProjectSurveyMutations } from './interfaces';

export const ProjectCreationContext = createNamedContext<ProjectCreationContextValue>(undefined, 'Project.Creation.Context');
export const ProjectCreationFiltersContext = createNamedContext<Filters.ContextValue>(undefined, 'Project.Creation.Filters.Context');
export const ProjectCreationSetupContext = createNamedContext<Setup.ContextValue>(undefined, 'Project.Creation.Setup.Context');

export const ProjectCreationSurveyModuleContext = createNamedContext<SurveyModule.ContextValue>(undefined, 'Project.Creation.SurveyModule.Context');
export const ProjectCreationSurveyMutationContext = createNamedContext<ProjectSurveyMutations.ContextValue>(undefined, 'Project.Creation.Surveys.Mutations.Context');

export const useProjectSurveyMutationContext = () => useContext(ProjectCreationSurveyMutationContext);