import { useCallback } from 'react';
import { useSurveyFormAnswerRationale } from '@/containers/SurveyForm';
import type { SurveySettings } from '@/types';
import { Textarea } from '@/components/Textarea';
import { useThemedTextArea } from './ThemedTextarea';
import styles from './style/Rationale.css';

type Props = {
  settings: SurveySettings.Rationale;
};

export const Rationale = ({
  settings = {
    enabled: false,
    minimum: 75,
  },
}: Props) => {

  const [rationale, setRationale] = useSurveyFormAnswerRationale();
  const themeProps = useThemedTextArea();

  const handleChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRationale(e.target.value);
  }, [setRationale]);

  if (!settings.enabled) return null;

  return (
    <>
      <div className={styles.label}>Please explain your rationale:</div>
      <Textarea
        className={styles.input}
        style={themeProps.style}
        rows={2}
        onChange={handleChange}
        onBlur={themeProps.onBlur}
        onFocus={themeProps.onFocus}
        value={rationale || ''} />
    </>
  );
};