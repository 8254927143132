import type { ActionMeta, MultiValue } from 'react-select';
import { useCallback, useContext, useState } from 'react';
import { ProjectClipsFiltersContext, SetProjectClipsFiltersContext } from '@containers/Project.Clips/context';
import { Button } from '@/components/Button';
import type { Moment } from '@/containers/Project.Clips/interfaces';
import { TagsMultiSelect } from '@/components/Transcript/Tag.MultiSelect';
import type { TagsMultiselectItem } from '@/components/Transcript/Tag.MultiSelect';
import { useProjectClipsTags } from './hooks/useProjectClipsTags';
import styles from './style/Filter.css';

type Props = {
  onSubmit: () => void;
};

export const TagsFilterMenu = ({ onSubmit }: Props) => {

  const filters = useContext(ProjectClipsFiltersContext);
  const setFilters = useContext(SetProjectClipsFiltersContext);

  const initialTags = filters.tagIds.map(m => ({
    tagId: m,
    tagStart: null,
    tagEnd: null,
  }));

  const [selectedTags, setSelectedTags] = useState<Moment[]>(initialTags);

  const {
    noOptionsMessage,
    options,
    selected,
  } = useProjectClipsTags(selectedTags);

  const handleChange = useCallback((value: MultiValue<TagsMultiselectItem>, meta: ActionMeta<TagsMultiselectItem>) => {
    setSelectedTags(value.map(v => ({
      tagId: v.id,
      tagStart: v.range?.start,
      tagEnd: v.range?.end,
    })));
  }, []);
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = useCallback((newValue: string) => setInputValue(newValue), []);

  const handleSubmit = useCallback(() => {
    setFilters({
      tagIds: selected.map(tag => tag.id),
    });
    onSubmit();
  }, [onSubmit, selected, setFilters]);

  return (
    <div className={styles.menu}>
      <TagsMultiSelect
        selected={selected}
        inputValue={inputValue}
        options={options}
        noOptionsMessage={noOptionsMessage}
        onChange={handleChange}
        onInputChange={handleInputChange} />
      <div className={styles.footer}>
        <Button
          className={styles.submit}
          onClick={handleSubmit}
          variant='brick'
          color='primary'>
          Apply
        </Button>
      </div>
    </div>
  );
};
