import { useCallback, useContext } from 'react';
import { ConferenceTagSummaryItem, ConferenceTagSummaryRating } from '@/types/conferences.tags';
import { createNamedContext } from '@/utils';

export type SetSummaryRatingContextValue = (item: ConferenceTagSummaryItem) => (rating: number) => unknown;

export type RefreshSummariesContextValue = {
  refresh: (tagId?: number) => unknown;
  isRefreshing: boolean;
  tagRefreshing: number;
};

export type SummaryFeedbackModalToggleContextValue = (item: ConferenceTagSummaryItem, rating?: ConferenceTagSummaryRating) => unknown;
export type SummaryFeedbackClickedContextValue = (item: ConferenceTagSummaryItem) => unknown;
export type SummaryCitationsClickedContextValue = (item: ConferenceTagSummaryItem) => unknown;
export type ToggleTagSumaryVersionsContextValue = (item: ConferenceTagSummaryItem) => unknown;

export const SetSummaryRatingContext = createNamedContext<SetSummaryRatingContextValue>(null, 'SetSummaryRatingContext');
export const RefreshSummariesContext = createNamedContext<RefreshSummariesContextValue>(null, 'RefreshSummariesContextValue');
export const TagSummaryItemContext = createNamedContext<ConferenceTagSummaryItem>(null, 'TagSummaryItemContext');
export const SummaryFeedbackModalToggleContext = createNamedContext<SummaryFeedbackModalToggleContextValue>(null, 'SummaryFeedbackModalToggleContextValue');
export const SummaryFeedbackClickedContext = createNamedContext<SummaryFeedbackClickedContextValue>(null, 'SummaryFeedbackClickedContext');
export const SummaryCitationsClickedContext = createNamedContext<SummaryCitationsClickedContextValue>(null, 'SummaryCitationsClickedContext');
export const ToggleTagSumaryVersionsContext = createNamedContext<ToggleTagSumaryVersionsContextValue>(null, 'ToggleTagSumaryVersionsContext');

export const useSetSummaryRatingContext = () => useContext(SetSummaryRatingContext);
export const useRefreshSummariesContext = () => useContext(RefreshSummariesContext);
export const useTagSummaryItemContext = () => useContext(TagSummaryItemContext);
export const useSummaryFeedbackModalToggleContext = (item: ConferenceTagSummaryItem) => {
  const ctx = useContext(SummaryFeedbackModalToggleContext);
  return useCallback(() => ctx(item), [ctx, item]);
};
export const useSummaryFeedbackClickedContext = (item: ConferenceTagSummaryItem) => {
  const ctx = useContext(SummaryFeedbackClickedContext);
  return useCallback(() => ctx(item), [ctx, item]);
};
export const useSummaryCitationsClickedContext  = (item: ConferenceTagSummaryItem) => {
  const ctx = useContext(SummaryCitationsClickedContext);
  return useCallback(() => ctx(item), [ctx, item]);
};
export const useToggleTagSumaryVersionsContext = (item: ConferenceTagSummaryItem) => {
  const ctx = useContext(ToggleTagSumaryVersionsContext);
  return useCallback(() => ctx(item), [ctx, item]);
};