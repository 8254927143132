import { cx } from '@utils';
import styles from './style/Table.css';

type BoxProps = {
  children:   React.ReactNode;
  className?: string;
};

export const Box = (props: BoxProps) => {
  return (
    <div className={cx(styles.root, props.className)}>
      {props.children}
    </div>
  );
};

Box.displayName = 'Table.Layout.Box';

type HeaderProps = {
  children?: React.ReactNode;
  classes?: {
    root?:  string;
    title?: string;
  };
  title: React.ReactNode;
};

export const Header = ({ classes = {}, ...props }: HeaderProps) => {
  return (
    <div className={cx(styles.header, classes.root)}>
      <h1 className={cx(styles.title, classes.title)}>{props.title}</h1>
      {props.children}
    </div>
  );
};

Header.displayName = 'Table.Layout.Header';

export const Footer = (props: Partial<BoxProps>) => {
  return (
    <div className={cx(styles.footer, props.className)}>
      {props.children}
    </div>
  );
};

Footer.displayName = 'Table.Layout.Footer';