export declare namespace Site {

  export type CoreUI = keyof Content;

  export type Screen = Exclude<CoreUI, 'nav'>;

  export type PageContent = Omit<Content, 'nav'>;

  export type Content = {
    main: {
      landing: {
        button: {
          cta:          ButtonAttributes;
          login:        ButtonAttributes;
          enterprise?:  ButtonAttributes;
          individuals?: ButtonAttributes;
        };
        text: {
          copy:      string;
          title:     string;
          segments?: string;
        };
      };
      banner: {
        button: {
          cta: ButtonAttributes;
        };
      };
      benefits: {
        collection: {
          benefits: {
            image: {
              icon: string;
            };
            text: {
              copy?:  string;
              title?: string;
            };
          }[];
        };
        text?: {
          title: string;
        };
      };
      funnel: {
        button: {
          cta: ButtonAttributes;
        };
        text: {
          cta: string;
        };
      };
      operations?: {
        collection: {
          planning: {
            button: { link: ButtonAttributes; };
          }[];
          product: {
            button: { link: ButtonAttributes; };
          }[];
        };
        text: {
          header:    string;
          planning:  string;
          product:   string;
          subheader: string;
        };
      };
      press: {
        text: { title: string; };
        collection: {
          logos: {
            image: {
              alt: string;
              src: string;
            };
          }[];
        };
      };
    };
    nav: {
      button: {
        cta:    ButtonAttributes;
        login:  ButtonAttributes;
        signup: ButtonAttributes;
      };
    };
    signup: {
      feature: {
        collection: {
          consultants: {
            collection: { tags: string[]; };
            image: { src: string; };
            text: {
              title: string;
              copy:  string;
            };
          }[];
          logos: {
            image: { src: string; };
          }[];
        };
        text: {
          title: string;
          proof: string;
        };
      };
    } | null;
  };

  export type Main =
    Pick<Content, 'main'>;

  export type Signup =
    Pick<Content, 'signup'>;

  export type UI = Partial<{
    collection: { [key: string]: Partial<UIProps>[]; };
  } & Pick<UIProps, 'button'>
    & Pick<UIProps, 'image'>
    & Pick<UIProps, 'text'>>;

  export type UIProps = {
    button: ButtonProps;
    image:  ImageProps;
    text:   TextProps;
  };

  export type ButtonProps = {
    [key: string]: ButtonAttributes;
  };

  export type ImageProps = {
    [key: string]: string;
  };

  export type TextProps = {
    [key: string]: string;
  };

  export type ButtonAttributes = {
    href?: string;
    text:  string;
    to:    string;
  };
}

export enum SegmentQueryValue {
  Corporate = '3',
}

export enum Strategy {
  Consulting  = 'consulting',
  Corporate   = 'corporate',
  Enterprise  = 'enterprise',
  Individuals = 'individuals',
  Investment  = 'investment',
}