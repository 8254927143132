import { useCallback, useEffect, useMemo, useState } from 'react';
import * as api from '@services/api';
import * as enums from '@enums';
import DropDown from '@/components/DropDown';
import { BaseVICS } from '@/types';
import styles from './style/SectorDropDown.css';

type Props = {
  onSelection: (item: BaseVICS) => void;
  selectedItems: BaseVICS[];
};

const SectorDropDown = ({ onSelection, selectedItems }: Props) => {
  const [items, setItems] = useState<BaseVICS[]>([]);

  const fetchSectors = useCallback(() => {
    api.search.fetchSectorFields()
      .then(data => {
        const mapped = data.items.map(m => ({
          id: m.id,
          name: m.name,
          typeId: enums.VICSType.Sector,
        }));

        setItems(mapped);
      });
  }, [
    setItems,
  ]);

  useEffect(() => {
    fetchSectors();
  }, [
    fetchSectors,
  ]);

  const filteredItems = useMemo(() => {
    return items.filter(f => !selectedItems.some(s => s.id === f.id));
  }, [
    items,
    selectedItems,
  ]);

  return (
    <DropDown
      items={filteredItems}
      getItemValue={v => v.name}
      onSelect={onSelection}
      text={'Enter Sectors'}
      classes={{ text: styles.inputText }} />
  );
};

export { SectorDropDown };
export default SectorDropDown;