import { ExternalCardContent } from './ExternalCard.Content';
import { CardTooltip } from './HtmlTooltip';

type Props = {
  classes?: {
    container?: string;
    tooltip?: string;
  };
  children: React.ReactNode;
  user: {
    name: string;
  };
  subtext?: string;
};

export const ExternalBasecallCard = (props: Props) => (
  <CardTooltip
    classes={props.classes}
    title={(<ExternalCardContent {...props} />)}>
    {props.children}
  </CardTooltip>
);