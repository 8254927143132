export enum SurveyActionType {
  None = 0,
  Disqualification = 1,
  Flag = 2,
  SkipToEnd = 3,
  SkipToQuestion = 4,
  SkipToSection = 5,
  IncludeSection = 6,
  QuotaOverLimit = 7,
  ExcludeQuestionChoice = 8,
  DisplayQuestionChoice = 9,
}

export const surveyActionTypeMap = new Map([
  [SurveyActionType.None, { name: 'None' }],
  [SurveyActionType.Disqualification, { name: 'Disqualify' }],
  [SurveyActionType.Flag, { name: 'Flag' }],
  [SurveyActionType.SkipToEnd, { name: 'Skip to end' }],
  [SurveyActionType.SkipToQuestion, { name: 'Skip to question' }],
  [SurveyActionType.SkipToSection, { name: 'Skip to section' }],
  [SurveyActionType.IncludeSection, { name: 'Include section' }],
  [SurveyActionType.QuotaOverLimit, { name: 'Quota over limit' }],
  [SurveyActionType.ExcludeQuestionChoice, { name: 'Hide choice' }],
  [SurveyActionType.DisplayQuestionChoice, { name: 'Display choice' }],
]);

export enum SurveyConditionType {
  Question = 1,
  AggregatedSum = 2,
  Classification = 3,
}

export enum SurveyQuestionConditionType {
  OptionRankedTopX = 'option-ranked-top-x',
  OptionSelected = 'option-selected',
  OptionNotSelected = 'option-not-selected',
  NumberOfOptionsSelected = 'number-options-selected',
  OptionRankedFirst = 'option-ranked-first',
  OptionRankedLast = 'option-ranked-last',
  MatrixValueChosen = 'matrix-value-chosen',
  MatrixValueNotChosen = 'matrix-value-not-chosen',
  NumberCellValue = 'number-cell-value',
  SliderValue = 'slider-value',
  SliderValuesTally = 'slider-values-tally',
  Straightline = 'straightline',
}

const surveyConditionBaseMap: Record<SurveyQuestionConditionType, string> = {
  [SurveyQuestionConditionType.OptionRankedTopX]: 'Top X ranked option',
  [SurveyQuestionConditionType.OptionSelected]: 'Option selected',
  [SurveyQuestionConditionType.OptionNotSelected]: 'Option not selected',
  [SurveyQuestionConditionType.NumberOfOptionsSelected]: 'Number of options selected',
  [SurveyQuestionConditionType.OptionRankedFirst]: 'First ranked option',
  [SurveyQuestionConditionType.OptionRankedLast]: 'Last ranked option',
  [SurveyQuestionConditionType.MatrixValueChosen]: 'Selected',
  [SurveyQuestionConditionType.MatrixValueNotChosen]: 'Not selected',
  [SurveyQuestionConditionType.NumberCellValue]: 'Cell value',
  [SurveyQuestionConditionType.SliderValue]: 'Row value',
  [SurveyQuestionConditionType.SliderValuesTally]: 'Row Values Tally',
  [SurveyQuestionConditionType.Straightline]: 'Straightline',
};

export const surveyConditionTypeMap = Object.keys(surveyConditionBaseMap).reduce((acc, key) => {
  const enumKey = key as SurveyQuestionConditionType;
  acc.set(enumKey, { name: surveyConditionBaseMap[enumKey] });
  return acc;
}, new Map<SurveyQuestionConditionType, { name: string }>());

export enum SurveySumConditionItemType {
  SliderRowValue = 'slider-row-value',
  NumberTableCellValue = 'number-table-cell-value',
}

export enum SurveyPipedValueType {
  OptionsSelected = 'options-selected',
  OptionsNotSelected = 'options-not-selected',

  MatrixRowsSelected = 'matrix-values-selected',
  MatrixRowsNotSelected = 'matrix-values-not-selected',
  MatrixOptionsSelected = 'matrix-options-selected',
  MatrixOptionsNotSelected = 'matrix-options-not-selected',

  FirstRankedOption = 'first-ranked-option',
  SecondRankedOption = 'second-ranked-option',
  SecondLastRankedOption = 'second-last-ranked-option',
  LastRankedOption = 'last-ranked-option',
  AllRankedOptions = 'all-ranked-options',
  RankedXOption = 'ranked-x-option',

  SliderRowValue = 'slider-row-value',
  SliderHighestValue = 'slider-highest-value',
  SliderLowestValue = 'slider-lowest-value',

  NumberTableCellValue = 'number-cell-value',

  TextEntryValues = 'text-entry-values',

  AlternateImageShown = 'alternate-image-shown',
}

export type SurveyPipedItemValueType = SurveyPipedValueType.AlternateImageShown;

export type SurveyPipedQuestionValueType = Exclude<SurveyPipedValueType, SurveyPipedItemValueType>;

export enum SurveyTaggingResultType {
  Classification = 1,
}

export const surveyTaggingResultTypeMap = new Map([
  [SurveyTaggingResultType.Classification, { name: 'Classify as' }],
]);

export enum SurveyLogicRule {
  AllOfTheAbove = 1,
  AnyOfTheAbove = 2,
  CustomExpression = 3,
}

export const surveyLogicRuleMap = new Map([
  [SurveyLogicRule.AllOfTheAbove, { name: 'All of the above' }],
  [SurveyLogicRule.AnyOfTheAbove, { name: 'Any of the above' }],
  [SurveyLogicRule.CustomExpression, { name: 'Custom expression' }],
]);