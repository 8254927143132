import { memo, useCallback, useRef } from 'react';
import { LocationDescriptor } from 'history';
import { Link } from 'react-router-dom';
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';
import { cx } from '@utils';
import styles from './style/VideoPreviewOverlay.css';

type Props = {
  className?: string;
  previewUrl: string;
  to: LocationDescriptor;
};

export const VideoPreviewOverlay = memo(({ className, previewUrl, to }: Props) => {
  const ref = useRef<HTMLImageElement>();

  const handleError = useCallback(() => {
    ref.current.style.visibility = 'hidden';
  }, []);

  return (
    <div className={cx(styles.preview, className)}>
      <Link
        className={styles.anchor}
        to={to}>
        <img
          ref={ref}
          className={styles.thumb}
          src={previewUrl}
          onError={handleError} />
        <div className={styles.overlay} />
        <PlayArrowRounded
          className={styles.play} />
      </Link>
    </div>
  );
});

export default VideoPreviewOverlay;