import { cx } from '@utils';
import styles from './style.css';

type Props = {
  className?: string;
  count:      number;
};

const QueryStats = (props: Props) => {
  const text = props.count === 1
             ? `${props.count} Result`
             : `${Math.min(50, props.count).toLocaleString()}${props.count > 50 ? '+' : ''} Results`;
  return (
    <div className={cx(styles.count, props.className)}>
      {text}
    </div>
  );
};

export default QueryStats;