import { useCallback, useMemo } from 'react';
import { SurveyQuestionOption, SurveyQuestionOptionMetadata } from '@/types/survey';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { ContextMenuItemProps } from '@/components/SurveyBuilder/ContextMenu.Item';

type Props = {
  questionIdentifier: string;
  option: SurveyQuestionOption<SurveyQuestionOptionMetadata<{ isOpenEnded: boolean }>>;
};

export const useOpenEndedOptionMenuItem = (props: Props) => {

  const [_, dispatch] = useSurveyBuilderState();

  const isOpenEnded = !!props.option.metadata.isOpenEnded;

  const toggleOpenEnded = useCallback(() => {
    dispatch({
      type: 'option-openended-updated',
      payload: {
        option: { identifier: props.option.base.identifier },
        questionIdentifier: props.questionIdentifier,
        value: !isOpenEnded,
      },
    });
  }, [
    dispatch,
    props.questionIdentifier,
    props.option.base.identifier,
    isOpenEnded,
  ]);

  const openEndedMenuItem: ContextMenuItemProps = useMemo(() => {

    return {
      checked: isOpenEnded,
      children: 'Open ended',
      onClick: toggleOpenEnded,
    };
  }, [isOpenEnded, toggleOpenEnded]);

  return openEndedMenuItem;
};