import { PlusCircle as PlusCircleIcon } from 'react-feather';
import { cx } from '@utils';
import styles from './style/FormButtons.css';

type Props = {
  children?:  React.ReactNode;
  className?: string;
  onClick:    () => unknown;
  size?:      number;
};

const PlusCircle = (props: Props) => {
  return (
    <button
      className={cx(styles.plus, props.className)}
      onClick={props.onClick}>
      <PlusCircleIcon size={props.size} />
      {props.children}
    </button>
  );
};

export { PlusCircle };
export default PlusCircle;