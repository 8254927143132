import { useCallback, useMemo } from 'react';
import { NumberParam, useQueryParams } from 'use-query-params';
import { useMutation, useQuery } from '@tanstack/react-query';
import LaunchIcon from '@mui/icons-material/Launch';
import * as api from '@api';
import * as API from '@api/interfaces';
import { MaybeLink } from '@/components/MaybeLink';
import { UserAvatar } from '@/components/UserAvatar';
import { AccessRequestContext } from './Context';
import * as Modal from './Modal';
import styles from './style/Access.Manage.css';

type Props = unknown;

export const AccessManage = (props: Props) => {
  const [qp, setQuery] = useQueryParams({
    a: NumberParam,
    r: NumberParam,
  });

  const query = useQuery([
    `get:access/requests/manage`,
  ], () => {
    return api.access.fetchPendingRequests();
  });

  const active = useMemo(() => {
    return typeof qp.r === 'number'
      ? query.data?.items?.find?.(x => x.request.id === qp.r)
      : null;
  }, [
    qp,
    query.data?.items,
  ]);

  const mutation = useMutation([
    `patch:access/requests/approval`,
    qp.a,
    qp.r,
  ], (params: API.Access.UpdateRequestApproval.Request) => {
    return api.access.updateRequestApproval({
      objectId: params.objectId,
      requestId: params.requestId,
      roleId: params.roleId,
    });
  }, {
    onSuccess: () => {
      query.refetch();
    },
  });

  const setQueryParams = useCallback(({ a, r }: { a: number; r: number }) => {
    const action = [1, 2].includes(a) ? a : 1;

    setQuery({
      a: action,
      r,
    }, 'replace');
  }, [
    setQuery,
  ]);

  const clearQueryParams = useCallback(() => {
    setQuery({
      a: undefined,
      r: undefined,
    }, 'replace');
  }, [
    setQuery,
  ]);

  const focus = useMemo(() => {
    return {
      approve: !!active && qp.a === 1,
      decline: !!active && qp.a === 2,
    };
  }, [
    active,
    qp.a,
  ]);

  if (query.isLoading) return null;

  if (!query.data?.items?.length) {
    return (
      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.header}>
            <div className={styles.title}>Access Requests</div>
          </div>
          <div className={styles.main}>
            No pending requests.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <div className={styles.title}>Access Requests</div>
        </div>

        <AccessRequestContext.Provider value={active}>
          <div className={styles.main}>
            {query.data.items.map(x =>
              <div
                className={styles.item}
                key={x.request.id}>
                <div className={styles.details}>
                  <div className={styles.pic}>
                    <UserAvatar
                      pictureUrl={x.user.pictureUrl}
                      size={30} />
                  </div>
                  <div className={styles.info}>
                    <div className={styles.user}>
                      <div className={styles.name}>{x.user.name}</div>
                      <div className={styles.email}>{x.user.email}</div>
                    </div>
                    <div className={styles.object}>
                      <div className={styles.label}>Request for</div>
                      <MaybeLink
                        target="_blank"
                        to={x.pathname}
                        rel="noopener noreferrer"
                        href={x.pathname}>
                        <div className={styles.link}>
                          <div className={styles.name}>{x.object.name}</div>
                          {x.pathname && <LaunchIcon />}
                        </div>
                      </MaybeLink>
                    </div>
                  </div>
                </div>

                <div className={styles.actions}>
                  <div
                    className={styles.action}
                    onClick={() => setQueryParams({ a: 1, r: x.request.id })}>
                    Approve
                  </div>
                  <div
                    className={styles.action}
                    onClick={() => setQueryParams({ a: 2, r: x.request.id })}>
                    Decline
                  </div>
                </div>
              </div>)}
          </div>

          {focus.approve &&
            <Modal.ApproveAccess
              loading={mutation.isLoading}
              onClose={clearQueryParams}
              onSubmit={mutation.mutateAsync}
              open />}
          {focus.decline &&
            <Modal.DeclineAccess
              loading={mutation.isLoading}
              onClose={clearQueryParams}
              onSubmit={mutation.mutateAsync}
              open />}

        </AccessRequestContext.Provider>
      </div>
    </div>
  );
};

AccessManage.displayName = 'Access.Manage';