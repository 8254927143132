import type { CreateExtensionPlugin } from '@remirror/core';
import {
  command,
  extension,
  helper,
  CommandFunction,
  ExtensionPriority,
  Helper,
  PlainExtension,
} from '@remirror/core';
import type { EditorState } from '@remirror/pm/state';
import { MarkType } from '@/types/transcribe.rich-text';
import { KaraokeState } from './Extension.Karaoke.State';
import * as KE from './interfaces.extension.karaoke';

@extension({
  defaultOptions: {},
  defaultPriority: ExtensionPriority.Low,
})
export class KaraokeExtension extends PlainExtension {

  offset: number;
  constructor(offset?: number) {
    super();
    this.offset = offset ?? 0;
  }

  get name() {
    return 'karaoke' as const;
  }

  getKaraokeState(): KaraokeState {
    return this.getPluginState();
  }

  createPlugin(): CreateExtensionPlugin {

    const pluginState = new KaraokeState();

    return {
      state: {
        init() {
          return pluginState;
        },
        apply(tr) {
          const action = tr.getMeta(KaraokeExtension.name) as KE.Action;
          return pluginState.apply({ tr, action });
        },
      },
      props: {
        decorations(state: EditorState) {
          return (this.getState(state) as KaraokeState).decorationSet;
        },
      },
    };
  }

  @command()
  setKaraokePosition(position: KE.Position): CommandFunction {
    return ({ tr, dispatch }) => {
      const offset = this.offset;
      const transformedPosition = transformPosition(position);
      dispatch?.(tr.setMeta(KaraokeExtension.name, {
        position: transformedPosition,
        type: 'update-karaoke-position',
      } as KE.UpdateKaraokePosition.Action));

      return true;

      function transformPosition(position: KE.Position) {
        if (position == null) {
          return null;
        }

        const transformed = {
          from: position.from + offset,
          to: position.to + offset,
        };

        if (transformed.from >= tr.doc.nodeSize || transformed.to >= tr.doc.nodeSize || transformed.from < 0 || transformed.to < 0) {
          return null;
        }

        return transformed;
      }
    };
  }

  @helper()
  getKaraokePosition(): Helper<KE.Position> {
    return this.getKaraokeState().position;
  }

  @helper()
  getKaraokeText(): Helper<string> {
    const state = this.getKaraokeState();

    const calculateTextIgnoringRedactions = () => {
      let text = '';
      this.store.view.state.doc.nodesBetween(state.position.from, state.position.to, (node, pos) => {
        if (node.marks != null && node.marks.some(mark => mark.type.name === MarkType.Redaction)) {
          //Do nothing, its redacted
        } else if (node.text) {
          text += node.text;
        }
      });

      return text;
    };

    return state.position
      ? calculateTextIgnoringRedactions()
      : null;
  }

}