import { memo } from 'react';

type Props = {
  className?: string;
  size: number;
};

export const Share = memo(({ className, size }: Props) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      viewBox="2 0 24 24">
      <circle
        cx="18"
        cy="5"
        r="3" />
      <circle
        cx="6"
        cy="12"
        r="3" />
      <circle
        cx="18"
        cy="19"
        r="3" />
      <line
        x1="8.59"
        y1="13.51"
        x2="15.42"
        y2="17.49" />
      <line
        x1="15.41"
        y1="6.51"
        x2="8.59"
        y2="10.49" />
    </svg>
  );
});

