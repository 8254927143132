import MailOutline from '@mui/icons-material/MailOutline';
import { ButtonOutlinedLabeledIcon } from '@presentation/ButtonOutlined.LabeledIcon';
import styles from './style/AuthButton.css';

type Props = {
  children?: React.ReactNode;
  onClick?:  () => unknown;
};

export const Email = (props: Props) => {
  return (
    <ButtonOutlinedLabeledIcon
      className={styles.btn}
      divider
      icon={<MailOutline className={styles.email} />}
      onClick={props.onClick}>
      {props.children}
    </ButtonOutlinedLabeledIcon>
  );
};

Email.displayName = 'AuthButton.Email';