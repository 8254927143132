import { useMemo } from 'react';
import { body } from '@containers/PostCreation/utils';
import * as PostCreation from '@containers/PostCreation/interfaces';
import { useTextPostStateContext, useTopicTagsContext, useVideoPostStateContext } from './usePostCreationContext';

export const useTextPostHasChanged = () => {
  const [state] = useTextPostStateContext();
  const [topic] = useTopicTagsContext();

  const hasChanged = useMemo(() => {
    return hasChanges({
      body: state.form.body,
      topics: topic.items,
    });
  }, [
    state.form.body,
    topic.items,
  ]);

  return hasChanged;
};

export const useVideoPostHasChanged = () => {
  const [state] = useVideoPostStateContext();
  const [topics] = useTopicTagsContext();

  const hasChanged = useMemo(() => {
    return hasChanges({
      body: state.body,
      description: state.description,
      thumbnail: state.thumbnail,
      title: state.title,
      topics: topics.items,
      video: state.video,
    });
  }, [
    state.body,
    state.description,
    state.thumbnail,
    state.title,
    state.video,
    topics.items,
  ]);

  return hasChanged;
};

const validation = {
  body: (value: Params['body']) => !body.isEmpty(value),
  description: (value: Params['description']) => !body.isEmpty(value),
  thumbnail: (value: Params['thumbnail']) => !!value,
  title: (value: Params['title']) => !!value?.trim?.()?.length,
  topics: (value: Params['topics']) => !!value.length,
  video: (value: Params['video']) => !!value,
};

function hasChanges(params: Params) {
  const keys = Object.keys(params) as (keyof Params)[];

  return keys.reduce((acc, x) => {
    const value = params[x];
    const validate = validation[x] as Validator;

    return acc.concat(validate(value));

  }, [] as boolean[])
  .includes(true);
}

type Validator<T extends Params = Params, K extends keyof T = keyof T> = (value: T[K]) => boolean;

type Params = {
  topics: PostCreation.TopicTags.State['items'];
} & Partial<PostCreation.Video.State>;