export enum SocketNamespace {
  Chat = 'chat',
  Queries = 'chat/queries'
}

export enum ChatSocketEvent {
  QueryTakingTooLong = 'query/taking-too-long',
  QueryAvailable = 'query/available',
  QueryErrored = 'query/errored',

  SegmentationResult = 'segmentation/result',
  SegmentationCompleted = 'segmentation/completed',
}

export enum LifeCycleSocketEvent {
  Connect = 'connect',
  ConnectError = 'connect_error',
  Disconnect = 'disconnect',
}

export type SocketEvent =
  | LifeCycleSocketEvent
  | ChatSocketEvent;