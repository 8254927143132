import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import * as QK from '@consts/querykey.admin';
import { CallRole } from '@enums';
import * as api from '$admin/api';
import { NotifyContext } from './Context';
import { Mutation, SetContext } from './interfaces';

type Props = {
  children: React.ReactNode;
} & ICallId
  & IProjectId;

export const Container = (props: Props) => {
  const [state, dispatch] = useState<SetContext.Params>(getInitialState(props));

  const query = useQuery(QK.Projects.Scheduling.Participants.Get({
    callId: state?.callId,
    projectId: state?.projectId,
  }), () => {
    return api.projects.scheduling.getCallParticipants({
      callId: state.callId,
      projectId: state.projectId,
    });
  }, {
    enabled: !!state,
  });

  const mutation = useMutation(QK.Projects.Scheduling.Notifications.Post({
    callId: state?.callId,
    projectId: state?.projectId,
  }), (params: Mutation.Variables) => {
    return api.projects.scheduling.notifyCallParticipants({
      callId: state.callId,
      projectId: state.projectId,
      notify: {
        participants: params.participants,
        respondent: params.respondent,
      },
    });
  });

  const resetContext = useCallback(() => {
    dispatch(null);
  }, []);

  const participants = useMemo(() => {
    const items = query.data?.items || [];

    const respondent = items.find(x => x.roleId === CallRole.PrimaryRespondent);

    const attendees = respondent?.offPlatform
        ? items.filter(x => x.roleId !== CallRole.PrimaryRespondent)
        : items;

    return {
      attendees,
      respondent,
    };
  }, [query.data?.items]);

  const value = {
    mutation,
    params: state,
    participants,
    query,
    resetContext,
    setContext: dispatch,
  };

  return (
    <NotifyContext.Provider value={value}>
      {props.children}
    </NotifyContext.Provider>
  );
};

Container.displayName = 'Call.Notify.Container';

function getInitialState(data: ICallId & IProjectId) {
  return data?.callId && data?.projectId
    ? data
    : null;
}