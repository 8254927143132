import { useRef } from 'react';

export function usePreviousDistinct<T>(value: T): T | undefined {
  const prevRef = useRef<T>();
  const curRef = useRef<T>(value);

  if (!Object.is(curRef.current, value)) {
    prevRef.current = curRef.current;
    curRef.current = value;
  }

  return prevRef.current;
}

export default usePreviousDistinct;