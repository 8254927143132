import { forwardRef, useCallback, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { useCommands } from '@remirror/react';
import { Portal } from '@/components/Portal';
import { useZIndexModifier, PopperMenu, PopperMenuItem } from '@/components/Popper';
import { ToolbarButton } from './Expression.Toolbar.Button';
import { ExpressionEditor } from './interfaces';
import { useSurveyConditionsBuilder } from '@/containers/SurveyBuilder.Logic';

export const OpeningParenthesisButton = forwardRef<HTMLButtonElement>((props, ref) => {

  const { insertOpeningParenthesis } = useCommands<ExpressionEditor.Extension>();

  const handleClick = useCallback(() => {
    insertOpeningParenthesis();
  }, [insertOpeningParenthesis]);

  return (
    <ToolbarButton
      ref={ref}
      onClick={handleClick}>
      (
    </ToolbarButton>
  );
});

export const ClosingParenthesisButton = forwardRef<HTMLButtonElement>((props, ref) => {

  const { insertClosingParenthesis } = useCommands<ExpressionEditor.Extension>();

  const handleClick = useCallback(() => {
    insertClosingParenthesis();
  }, [insertClosingParenthesis]);

  return (
    <ToolbarButton
      ref={ref}
      onClick={handleClick}>
      )
    </ToolbarButton>
  );
});

export const AndOperatorButton = forwardRef<HTMLButtonElement>((props, ref) => {

  const { insertLogicalOperator } = useCommands<ExpressionEditor.Extension>();

  const handleClick = useCallback(() => {
    insertLogicalOperator('AND');
  }, [insertLogicalOperator]);

  return (
    <ToolbarButton
      ref={ref}
      onClick={handleClick}>
      AND
    </ToolbarButton>
  );
});

export const OrOperatorButton = forwardRef<HTMLButtonElement>((props, ref) => {

  const { insertLogicalOperator } = useCommands<ExpressionEditor.Extension>();

  const handleClick = useCallback(() => {
    insertLogicalOperator('OR');
  }, [insertLogicalOperator]);

  return (
    <ToolbarButton
      ref={ref}
      onClick={handleClick}>
      OR
    </ToolbarButton>
  );
});

export const ConditionButton = forwardRef<HTMLButtonElement>((props, ref) => {

  const { items } = useSurveyConditionsBuilder();

  const [open, setOpen] = useState(false);

  const [referenceElement, setReferenceElement] = useState<HTMLElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);

  const zIndexModifier = useZIndexModifier({ zIndex: 8 });

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [zIndexModifier],
    placement: 'bottom-start',
  });

  const { insertCondition } = useCommands<ExpressionEditor.Extension>();

  const handleClick = useCallback((identifier: string) => () => {
    insertCondition(identifier);
    setOpen(false);
  }, [insertCondition]);

  return (
    <>
      <ToolbarButton
        ref={setReferenceElement}
        onClick={() => setOpen(true)}>
        + Condition
      </ToolbarButton>
      {open &&
        <Portal>
          <ClickAwayListener
            mouseEvent='onMouseDown'
            onClickAway={() => setOpen(false)}>
            <div
              ref={setPopperElement}
              style={popperStyles.popper}
              {...attributes.popper}>
              <PopperMenu>
                {items.map((m, i) => (
                  <PopperMenuItem
                    key={m.identifier}
                    onClick={handleClick(m.identifier)}>
                    C{i + 1}
                  </PopperMenuItem>
                ))}
              </PopperMenu>
            </div>
          </ClickAwayListener>
        </Portal>
      }
    </>
  );
});