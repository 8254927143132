import { useMemo } from 'react';
import { useParams } from 'react-router';
import { createEditor } from 'slate';
import { withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import ActivityIndicator from '@/components/ActivityIndicator';
import { Editor } from './Pane.Notes';
import { useConferenceNotes } from './hooks';
import styles from './style/Popup.Notes.css';

type Props = unknown;

export const Notes = (props: Props) => {
  const editor = useMemo(() => {
    return withHistory(withReact(createEditor()));
  }, []);
  const params = useParams<Stringify<ICallId>>();

  const { notes, update, query } = useConferenceNotes(+params.callId);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <div className={styles.win}>
            <div className={styles.title}>Notes</div>
          </div>
        </div>
        <div className={styles.main}>
          {query.isFetching ?
            <ActivityIndicator /> :
            <Editor
              editor={editor}
              value={notes}
              onChange={update} />
          }
        </div>
      </div>
    </div>
  );
};

Notes.displayName = 'Conference.Panel.Popup.Notes';