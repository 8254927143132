import { useContext, useEffect } from 'react';
import { TranscriptHighlightsContext } from '@/containers/Transcript/context.highlights';
import { useChainedCommands } from './useCommands';
import { useHelpers } from './useHelpers';

export function useInitializeHighlights() {

  const commands = useChainedCommands();

  const highlights = useContext(TranscriptHighlightsContext);
  const { getHighlightsInitialized } = useHelpers();
  const initialized = getHighlightsInitialized();

  useEffect(() => {
    if (!initialized && highlights.length) {
      commands.setHighlights(highlights).run();
    }
  }, [
    commands,
    initialized,
    highlights,
  ]);
}