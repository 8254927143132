import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import type { LocationDescriptor } from 'history';
import queryString from 'query-string';
import { path } from '@consts';
import { getLastLoginStrategy } from '@services/auth/session';
import { isPlatformUser } from '@utils';

const selectWasActionLogout = (state: Store.State) => state.user.state.wasActionLogout;

export function useBuildReturnRoute(): LocationDescriptor {
  const location = useLocation();
  const wasActionLogout = useSelector(selectWasActionLogout);
  const platform = useSelector((state: Store.State) => isPlatformUser(state.user));

  return useMemo(() => {
    const qs = queryString.stringify({
      returnUrl: `${location.pathname}${location.search}${location.hash}`,
    });

    const strategy = getLastLoginStrategy();

    return {
      pathname: strategy?.strategy === 'enterprise' ? path.Website.EnterpriseLogin : platform ? path.Website.PlatformLogin : path.Website.Login,
      search: wasActionLogout ? undefined : `?${qs}`,
    };
  }, [wasActionLogout, location.pathname, location.search, location.hash, platform]);
}