import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import { useSelectProject } from '@containers/Store';
import * as enums from '@enums';

export const useCompleteSurveyProjectOnboarding = (projectId: number) => {
  const dispatch = useDispatch();
  const project = useSelectProject(projectId);

  const completeOnboarding = () => {
    if (project.projectType !== enums.ProjectType.Survey) {
      return;
    }

    return api.projects.pipeline.onboarding.complete({
      projectId: project.id,
    })
    .then(res => {
      dispatch(actions.projectPipelineUpdated({
        projectId: project.id,
        pipeline: res.pipeline,
      }));
    });
  };

  return completeOnboarding;
};

export default useCompleteSurveyProjectOnboarding;