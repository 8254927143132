import { useCallback, useMemo } from 'react';
import { useSurveyBuilderState, useHasUncommittedMessageChanges, useSubmitSurveyDraft } from '@containers/SurveyBuilder/hooks';
import { useGetText } from '@/components/Remirror/hooks';
import { SurveyMessageBuilderSaveContext } from './context';
import { useUploadMessageImages } from './useUploadMessageImages';

type Props =
  ChildrenProps;

export const MessageBuilderSaveContainer = (props: Props) => {

  const [_, dispatch] = useSurveyBuilderState();
  const uploadImages = useUploadMessageImages();
  const getText = useGetText();

  const submitDraft = useSubmitSurveyDraft();

  const hasUncommittedChanges = useHasUncommittedMessageChanges();

  const canSave = useMemo(() => {
    const hasChanges = hasUncommittedChanges();
    if (!hasChanges) return false;

    const text = getText();

    return !!text.length;
  }, [getText, hasUncommittedChanges]);

  const handleSave = useCallback(async () => {

    await uploadImages();

    dispatch({
      type: 'toggle-editing',
      itemIdentifier: null,
    });

    submitDraft();
  }, [
    dispatch,
    submitDraft,
    uploadImages,
  ]);

  const value = {
    canSave,
    onSave: handleSave,
  };

  return (
    <>
      <SurveyMessageBuilderSaveContext.Provider value={value}>
        {props.children}
      </SurveyMessageBuilderSaveContext.Provider>
    </>
  );
};