import { useCallback, useState, useMemo } from 'react';
import Select from 'react-select';
import type { ActionMeta, MultiValue } from 'react-select';
import { useQuery } from '@tanstack/react-query';
import { css } from '@emotion/css';
import { useTheme } from '@/brand-insights/components/Theme';
import type { BrandInsightsInstanceContext } from '@/brand-insights/types';
import * as $api from '@/services/api/brand-insights';
import * as Presentation from './Presentation';
import type { ProjectFilter } from './interfaces';

export type Props = {
  context: BrandInsightsInstanceContext.Item;
  value: ProjectFilter.Item[];
  onChange: (value: ProjectFilter.Item[], meta: ActionMeta<ProjectFilter.Item>) => unknown;
  disabled?: boolean;
};

export function Project({ disabled, context, value, onChange }: Props) {
  const theme = useTheme();

  const selectStyles = useMemo(() => Presentation.buildSelectStyles(theme), [theme]);
  const selectTheme = useMemo(() => Presentation.buildSelectTheme(theme), [theme]);

  const selected = useMemo(() => value, [value]);

  const handleChange = useCallback((value: MultiValue<ProjectFilter.Item>, meta: ActionMeta<ProjectFilter.Item>) => {
    onChange(Array.from(value), meta);
  }, [onChange]);

  const [inputValue, setInputValue] = useState('');
  const [emptyDisabled, setEmptyDisabled] = useState(false);
  const optionsQuery = useQuery({
    queryKey: [`get:brand-insights/chat/filters/projects`, context, inputValue],
    queryFn: async () => {
      const result = await $api.filtersProjectSearch({
        context,
        query: inputValue,
      });

      setEmptyDisabled(result.empty);

      return result.results;
    },
    placeholderData: [],
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });

  const handleInputChange = useCallback((newValue: string) => setInputValue(newValue), []);

  const handleNoOptionsMessage = useCallback((obj: { inputValue: string }) => {
    if (optionsQuery.isFetching) return `Loading...`;
    return obj.inputValue?.length ? `No projects found` : null;
  }, [optionsQuery.isFetching]);

  return (
    <Presentation.Row>
      <Presentation.Label>Project</Presentation.Label>
      <Presentation.Value>
        <Select<ProjectFilter.Item, true>
          styles={selectStyles}
          components={{
            DropdownIndicator: null,
          }}
          theme={selectTheme}
          value={selected}
          isMulti
          options={optionsQuery.data}
          placeholder={`Search projects...`}
          noOptionsMessage={handleNoOptionsMessage}
          getOptionLabel={o => o.name}
          getOptionValue={o => `${o.id}`}
          onChange={handleChange}
          onInputChange={handleInputChange}
          isDisabled={disabled || emptyDisabled}
          menuPlacement="auto" />
      </Presentation.Value>
    </Presentation.Row>
  );
}