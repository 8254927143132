import { useMemo } from 'react';
import type { CellContext } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import type { ProjectClips } from '@/types/project.clips';
import { Tooltip } from '@/presentation/Tooltip';
import { TranscriptType } from '@/enums';
import { getLocationFor, trunc } from '@/utils';
import styles from './style/Project.Clips.css';

type Props = CellContext<ProjectClips.Item, unknown>;

export const SourceCell = ({ row }: Props) => {

  const location = useMemo(() => {
    const transcriptId = row.original.transcript.id;
    const context = row.original.transcript.context;

    const contentStart = row.original.content[0]?.range.start;

    const position = contentStart || row.original.range.start;

    if (context.type === TranscriptType.VideoCall) {
      return getLocationFor.call.transcript({
        callId: context.callId,
        position,
        slug: context.projectId.toString(),
      });
    } else if (context.type === TranscriptType.WorkspaceFile) {
      return getLocationFor.workspaces.transcript({
        position,
        transcriptId,
      });
    }
    return null;
  }, [row.original.content, row.original.range, row.original.transcript]);

  return (
    <Tooltip title={row.original.transcript.name}>
      <Link
        className={styles.link}
        to={location}>
        {trunc(row.original.transcript.name, 35)}
      </Link>
    </Tooltip>
  );
};