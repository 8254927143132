import styled from '@emotion/styled';

type Props = {
  className?: string;
} & ChildrenProps;

export function Pane({ className, children }: Props) {
  return (
    <Root className={className}>
      <Wrap>
        {children}
      </Wrap>
    </Root>
  );
}

const Root = styled.div`
  box-sizing: border-box;
  border: 1px solid var(--sec-01);
  border-radius: 4px;
`;

const Wrap = styled.div`
  box-sizing: border-box;
  padding: 18px;
`;