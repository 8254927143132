export const Analytics = {
  Impressions: {
    Post: `post:posts/analytics/impressions`,
  },
  Views: {
    Post: `post:posts/analytics/views`,
  },
};

export const Articles = {
  $: {
    Publish: `$publish:posts/articles`,
    Update: `$update:posts/articles`,
  },
  Delete: `delete:posts/articles`,
  Get: `get:posts/articles`,
  Post: `post:posts/articles`,
  Put: `put:posts/articles`,
  Attachments: {
    CoverImage: {
      Delete: `delete:posts/articles/attachments/cover-image`,
      Post: `post:posts/articles/attachments/cover-image`,
    },
    Post: `post:posts/articles/attachments`,
  },
  Edit: {
    Get: `get:posts/articles/edit`,
  },
  Comments: {
    Get: `get:posts/articles/comments`,
  },
};

export const External = {
  Get: `get:posts/external`,
};

export const Post = `post:posts`;