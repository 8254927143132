import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { Tooltip } from '@presentation/Tooltip';
import styles from './style/Badge.css';

type Props = unknown;

export const BadgeVerified = (props: Props) => {

  return (
    <Tooltip title="Verified">
      <div className={styles.verified}>
        <CheckOutlinedIcon fontSize="inherit" />
      </div>
    </Tooltip>
  );
};

BadgeVerified.displayName = 'Badge.Verified';

export const BadgeUnverified = (props: Props) => {

  return (
    <Tooltip title="Unverified">
      <div className={styles.unverified}>
        <CheckOutlinedIcon fontSize="inherit" />
      </div>
    </Tooltip>
  );
};

BadgeUnverified.displayName = 'Badge.Unverified';

export const BadgeOffPlatform = (props: Props) => {

  return (
    <Tooltip title="No Account">
      <div className={styles.offplatform}>
        <RemoveOutlinedIcon fontSize="inherit" />
      </div>
    </Tooltip>
  );
};

BadgeOffPlatform.displayName = 'Badge.OffPlatform';