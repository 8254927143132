import { useRichTextEditorRegister } from '@/containers/SurveyBuilder/hooks/useRichTextEditorRegister';
import type { SurveyRichText } from '@/types';
import { SurveyRichTextEditor } from '@/components/Survey.RichText/Editor';
import { SurveyRichTextEditorContainer } from '@/components/Survey.RichText/Editor.Container';
// eslint-disable-next-line import/no-cycle
import { useSurveyRichTextEditor } from '@/components/Survey.RichText/hooks/useSurveyRichTextEditor';
import { LinkContainer } from '@/components/Remirror';
import { Toolbar } from '@/components/SurveyBuilder.Message/Toolbar';
import styles from './style/Input.Stimulus.css';

type Props = {
  editable?: boolean;
  initialValue?: SurveyRichText.RootNode;
} & ChildrenProps;

export const TemplateTPPRichTextInput = () => {

  return (
    <div className={styles.root}>
      <Toolbar className={styles.toolbar} />
      <SurveyRichTextEditor />
    </div>
  );
};

export const TemplateTPPRichTextContainer = ({
  editable = true,
  ...props
}: Props) => {
  const { manager, state, onChange } = useSurveyRichTextEditor({
    initialState: props.initialValue,
    placeholder: 'Provide the TPP',
  });

  const editorRef = useRichTextEditorRegister({
    identifier: `tpp-text-input`,
  });

  return (
    <SurveyRichTextEditorContainer
      autoFocus={true}
      editable={editable}
      manager={manager}
      className={styles.editor}
      state={state}
      onChange={onChange}
      editorRef={editorRef}>
      <LinkContainer>
        {props.children}
      </LinkContainer>
    </SurveyRichTextEditorContainer>
  );
};