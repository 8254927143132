import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { NumberParam, useQueryParam } from 'use-query-params';
import {
  SurveyFormMetadataContext,
  SurveyFormVersionIdContext,
  SurveyFormStepperState,
  SurveyResponseIdentifierContext,
  SaveCompletedSurveyResponseContext,
  SaveDisqualifiedSurveyResponseContext,
  SurveyFormTemplateContext,
} from '@containers/SurveyForm';
import { ProjectSurveyQuestion } from '@screens/Survey';
import { SurveyVersionPreviewComplete } from '@screens/Survey.Preview';
import { useFetchSurveyVersionForm } from '@utils/api';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import { SurveyProjectContext } from './Context';

type Params = {
  surveyVersionId: string;
};

type CompleteState = {
  isComplete: boolean;
  isDisqualified?: boolean;
};

const errorText = 'You don\'t have access to this project.';
export const SurveyVersionPreviewContainer = (props: unknown) => {
  const { surveyVersionId: idParam } = useParams<Params>();
  const surveyVersionId = +idParam;
  const [ordinal] = useQueryParam('ordinal', NumberParam);
  const {
    data: res,
    isLoading,
    isError,
    refetch,
  } = useFetchSurveyVersionForm({
    preview: true,
    surveyVersionId,
    ordinal,
  }, {
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  const [completeState, setCompleteState] = useState<CompleteState>({ isComplete: false });

  const handleDisqualification = useCallback(() => {
    setCompleteState({ isComplete: true, isDisqualified: true });
  }, [setCompleteState]);

  const handleCompletion = useCallback(() => {
    setCompleteState({ isComplete: true, isDisqualified: false });
  }, [setCompleteState]);

  const retrySurvey = useCallback(() => {
    //Need to get a new response identifier
    refetch().then(() => {
      //Don't remount the survey data until we have the new response info
      setCompleteState({ isComplete: false });
    });
  }, [setCompleteState, refetch]);

  const ProjectQuestion = useMemo<React.ComponentType>(() => {
    return () => (
      <ProjectSurveyQuestion hideNavigation={!!ordinal} />
    );
  }, [
    ordinal,
  ]);

  if (isError) {
    return <div>{errorText}</div>;
  }

  if (isLoading || !res) {
    return <ActivityIndicator show />;
  }

  if (completeState?.isComplete) {
    return (
      <SurveyVersionPreviewComplete
        isDisqualified={completeState.isDisqualified}
        onRetry={retrySurvey} />
    );
  }

  return (
    <SurveyProjectContext.Provider value={res.project}>
      <SurveyFormVersionIdContext.Provider value={res.survey.id}>
        <SurveyFormMetadataContext.Provider value={res.metadata}>
          <SaveCompletedSurveyResponseContext.Provider value={handleCompletion}>
            <SaveDisqualifiedSurveyResponseContext.Provider value={handleDisqualification}>
              <SurveyResponseIdentifierContext.Provider value={res.response.identifier}>
                <SurveyFormTemplateContext.Provider value={res.survey.template}>
                  <SurveyFormStepperState
                    state={res.response.state}
                    QuestionScreenComponent={ProjectQuestion} />
                </SurveyFormTemplateContext.Provider>
              </SurveyResponseIdentifierContext.Provider>
            </SaveDisqualifiedSurveyResponseContext.Provider>
          </SaveCompletedSurveyResponseContext.Provider>
        </SurveyFormMetadataContext.Provider>
      </SurveyFormVersionIdContext.Provider>
    </SurveyProjectContext.Provider>
  );
};

SurveyVersionPreviewComplete.displayName = 'Survey.Version.Preview.Container';