import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import { RateLimitingContext } from './Context';

type Props = {
  children: React.ReactNode;
};

export const RateLimitingContainer = (props: Props) => {
  const query = useQuery({
    queryKey: [
      'get:users/rate-limiting',
    ],
    queryFn: api.users.getRateLimitingStats,
  });

  return (
    <RateLimitingContext.Provider value={{ query }}>
      {props.children}
    </RateLimitingContext.Provider>
  );
};

RateLimitingContainer.displayName = 'RateLimiting.Container';