import React, { useCallback, useMemo } from 'react';
import { PlusCircle, MinusCircle } from 'react-feather';
import type { MultiTextboxQuestion } from '@/types';
import { useSurveyFormQuestionAnswer } from '@/containers/SurveyForm';
import { parseSurveyRichText } from '@/containers/Survey/utils/rich-text';
import type { SurveyQuestionType } from '@/enums';
import { ThemedInput } from './ThemedInput';
import { DontKnow } from './DontKnow';
import styles from './style/MultiTextbox.css';
import { Rationale } from './Rationale';

type Props = {
  question: MultiTextboxQuestion.FormQuestion | MultiTextboxQuestion.Question;
};

export const MultiTextbox = ({ question }: Props) => {

  const [answer, setAnswer] = useSurveyFormQuestionAnswer<SurveyQuestionType.MultiTextbox>();

  const values = useMemo(() => answer.values, [answer.values]);

  const changeAnswer = useCallback((index: number) => {
    return (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const copiedAnswers = [...values];
      copiedAnswers[index] = e.target.value;
      setAnswer({
        dontKnow: answer.dontKnow,
        values: copiedAnswers,
      });
    };
  }, [setAnswer, values, answer.dontKnow]);

  const addRow = useCallback(() => {
    setAnswer({
      dontKnow: answer.dontKnow,
      values: [...values, ''],
    });
  }, [setAnswer, values, answer.dontKnow]);

  const removeRow = useCallback((index: number) => {
    return () => {
      setAnswer({
        dontKnow: answer.dontKnow,
        values: values.filter((_, i) => i !== index),
      });
    };
  }, [values, setAnswer, answer.dontKnow]);

  const handleToggle = useCallback(() => {

    const dontKnow = !answer.dontKnow;

    setAnswer({
      dontKnow,
      values: answer.values.map(m => ''),
    });
  }, [answer, setAnswer]);

  const canRemoveEntries = useMemo(() => {
    return !answer.dontKnow && values.length > question.settings.minimumEntries;
  }, [answer.dontKnow, question.settings.minimumEntries, values.length]);

  const canAddEntries = useMemo(() => {
    return !answer.dontKnow && values.length < question.settings.maximumEntries;
  }, [answer.dontKnow, question.settings.maximumEntries, values.length]);

  const hasLabels = useMemo(() => {
    return question.settings.entryLabels.some(s => !!s);
  }, [question.settings.entryLabels]);

  const renderRow = useCallback((v: string, i: number) => {

    const label = question.settings.entryLabels[i];

    return (
      <div className={styles.inputRow} key={i}>
        {hasLabels && <div className={styles.entryLabel}>{label}</div>}
        <div className={styles.subRow}>
          <div className={styles.input}>
            <ThemedInput
              className={styles.ta}
              disabled={answer.dontKnow}
              onChange={changeAnswer(i)}
              value={v}
              placeholder="Input text" />
          </div>
          {canRemoveEntries &&
            <MinusCircle className={styles.removeIcon} onClick={removeRow(i)} />
          }
        </div>
      </div>
    );
  }, [
    answer.dontKnow,
    hasLabels,
    canRemoveEntries,
    changeAnswer,
    question.settings.entryLabels,
    removeRow,
  ]);

  return (
    <div>
      <div className={styles.label}>
        {question.settings.label && parseSurveyRichText(question.settings.label)}
      </div>
      <div className={styles.inputRows}>
        {!!values.length && values.map(renderRow)}
      </div>
      {canAddEntries &&
        <div className={styles.addRow} onClick={addRow}>
          <PlusCircle className={styles.addIcon} /> Add
        </div>
      }
      {question.settings.dontKnow.enabled &&
        <DontKnow
          onChange={handleToggle}
          value={answer.dontKnow}
          label={question.settings.dontKnow.label} />
      }
      {!answer.dontKnow && <Rationale settings={question.settings.rationale} />}
    </div>
  );
};