import { createContext } from 'react';
import { RepeatSurvey, SurveyQuestion } from '@/types';

type ColorsMap = {
  [id: number]: string;
};

export type RepeatQuestionResponsesContextValue = {
  aggregate: RepeatSurvey.AggregateData;
  historical: RepeatSurvey.HistoricalQuestionData;
  question: SurveyQuestion;
  projectIds: number[];
};

export const RepeatQuestionResponsesContext = createContext<RepeatQuestionResponsesContextValue>(undefined);
export const RepeatSurveyResponseDataContext = createContext<RepeatSurvey.Data>(undefined);
export const RepeatChartRefContext = createContext<React.RefObject<HTMLDivElement>>(undefined);

export const ConjointAnalysisAttributeColorsContext = createContext<ColorsMap>(undefined);