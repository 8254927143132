import { memo } from 'react';
import styled from '@emotion/styled';
import CachedIcon from '@mui/icons-material/Cached';

type Props = {
  className?: string;
  onClick: () => unknown;
};

export const MessageRegenerateResponseFooter = memo(({ className, onClick }: Props) => {
  return (
    <Root className={className}>
      <Wrap>
        <Action onClick={onClick}>
          <StyledIcon />
          <Text>Regenerate Response</Text>
        </Action>
      </Wrap>
    </Root>
  );
});

const Root = styled.div`
  box-sizing: border-box;
`;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Action = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: 0.15s;

  color: ${({ theme }) => theme.palette.primary.light6};
  &:hover {
    opacity: 1;
  }
`;

const Text = styled.div`
  font-size: 12px;
  font-family: ${({ theme }) => theme.fonts.semiBold};
`;

const StyledIcon = styled(CachedIcon)`
  margin: 0 4px 0 0;
  font-size: 18px;
`;