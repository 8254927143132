import { useCallback } from 'react';
import cuid from 'cuid';
import { useSurveyBuilderState } from './useSurveyBuilderContext';

export const useAddSurveyQuestion = (sectionIdentifier: string) => {
  const [_, dispatch] = useSurveyBuilderState();

  return useCallback((ordinal: number) => () => {

    dispatch({
      type: 'question-added',
      questionIdentifier: cuid(),
      sectionIdentifier,
      item: {
        identifier: cuid(),
        ordinal,
      },
    });

  }, [dispatch, sectionIdentifier]);
};

export const useAddSurveyMessage = (sectionIdentifier: string) => {
  const [_, dispatch] = useSurveyBuilderState();

  return useCallback((ordinal: number) => () => {

    dispatch({
      type: 'message-added',
      messageIdentifier: cuid(),
      sectionIdentifier,
      item: {
        identifier: cuid(),
        ordinal,
      },
    });

  }, [dispatch, sectionIdentifier]);
};

export const useAddSurveyAIE = (sectionIdentifier: string) => {
  const [_, dispatch] = useSurveyBuilderState();

  return useCallback((ordinal: number) => () => {

    dispatch({
      type: 'aie-added',
      exerciseIdentifier: cuid(),
      sectionIdentifier,
      item: {
        identifier: cuid(),
        ordinal,
      },
    });

  }, [dispatch, sectionIdentifier]);
};