import { useCallback, useMemo } from 'react';
import type { MatrixSliderQuestion } from '@/types';
import { SurveyBuilderQuestionSettings } from '@/components/Survey.RichText/ReadOnly';
import { useGetDisplayTotal } from '../SurveyBuilder.Question/hooks';
import { Rows } from './Rows';
import {
  useGetRowDisplayLogic,
  useGetSlidersRowLogic,
  useGetAdditionalSlidersLogic,
  useQuestionLogic,
  useQuestionClassifications,
} from './hooks';
import { SurveyMetadata } from './Metadata';
import { SurveyQuestionLogic } from './Logic';
import { SurveyQuestionClassifications } from './Tagging';
import { SurveySettings } from './Settings';
import styles from './style/Sliders.css';

type Props = {
  item: MatrixSliderQuestion.Question;
};

export const SlidersPreview = ({ item }: Props) => {

  const questionLogic = useQuestionLogic(item.base.identifier);
  const questionClassifications = useQuestionClassifications(item.base.identifier);
  const getRowLogic = useGetSlidersRowLogic(questionLogic);
  const additionalLogic = useGetAdditionalSlidersLogic(questionLogic);
  const getRowDisplayLogic = useGetRowDisplayLogic(item.base.identifier);

  const renderExtras = useCallback((row: MatrixSliderQuestion.Row) => {
    const logicItems = getRowLogic(row);
    const dispalyLogicItems = getRowDisplayLogic(row);

    const { isAnchored, isOpenEnded } = row.metadata;
    const metadataItems = [
      isAnchored && 'Anchored',
      isOpenEnded && 'Open ended',
    ].filter(Boolean);

    return (
      <>
        <SurveyMetadata items={metadataItems} />
        {dispalyLogicItems}
        {logicItems}
      </>
    );
  }, [getRowLogic, getRowDisplayLogic]);

  return (
    <>
      <div className={styles.header}>Rows</div>
      <Rows
        item={item}
        renderRowExtras={renderExtras} />
      <Settings
        settings={item.settings}
        identifier={item.base.identifier} />
      <SurveyQuestionLogic items={additionalLogic} />
      <SurveyQuestionClassifications items={questionClassifications} />
    </>
  );
};

type SettingsProps = {
  settings: MatrixSliderQuestion.Settings;
  identifier: string;
};

const Settings = ({ settings, identifier }: SettingsProps) => {

  const getDisplayTotal = useGetDisplayTotal(settings.slider.total);

  const displayTotal = useMemo(() => getDisplayTotal(), [getDisplayTotal]);

  return (
    <SurveySettings>
      <div className={styles.row}>
        <div className={styles.label}>Label:</div>
        <SurveyBuilderQuestionSettings
            value={settings.slider.label}
            identifier={`${identifier}:settings:slider:label`} />
      </div>
      {settings.orderRowsBasedOnSource && <>Order rows based on source question</>}
      {settings.randomization && <>Randomize rows</>}
      {settings.slider.displayPctOfTotal && <>Display pct. of total</>}
      {settings.slider.hideSlider && <>Hide slider</>}
      {settings.slider.total.comparate && <>Total: {displayTotal}</>}
      {settings.slider.increment && <>Increment by {settings.slider.increment}</>}
      {(settings.slider.minValue !== null || settings.slider.maxValue !== null) &&
        <>
          {[
            settings.slider.minValue !== null && `Min. value: ${settings.slider.minValue}`,
            settings.slider.maxValue !== null && `Max. value: ${settings.slider.maxValue}`,
          ].filter(Boolean).join(', ')}
        </>
      }
    </SurveySettings>
  );
};