import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { CallStatus, CallType } from '@enums';

const selectExistingCall = (state: Store.State) => (userId: number) => {
  return state.calls.ids
    .map(callId => state.calls[callId])
    .filter(f =>
      f.typeId === CallType.AdHoc &&
      f.statusId === CallStatus.Pending
    ).filter(f =>
      (f.userId === userId && f.creatorUserId === state.user.id) ||
      (f.userId === state.user.id && f.creatorUserId === userId)
    );
};

export const useExistingAdHocCallCheck = () => {
  const getExistingCall = useSelector(selectExistingCall);

  return useCallback((userId: number) => {
    const existing = getExistingCall(userId);

    return existing[0];
  }, [getExistingCall]);

};