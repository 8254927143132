import { AudienceType } from '@enums';
import styles from './style/Landing.css';

type Props = {
  audienceType: AudienceType;
};

export const Graphic = (props: Props) => {
  const classname = props.audienceType === AudienceType.Payer
      ? styles.ghab
      : styles.sentiment;

  return (
    <div className={styles.graphic}>
      <div className={styles.frame}>
        <div className={classname} />
      </div>
    </div>
  );
};

Graphic.displayName = 'ProjectInvite.Landing.Graphic';