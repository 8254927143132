import { useMemo } from 'react';
import { CallRole } from '@enums';
import { Coordinator } from '@containers/Conference/interfaces';
import { useConferenceInstance } from '@containers/Conference/hooks/useConferenceInstance';

export const useCanEnd = () => {
  const instance = useConferenceInstance<Coordinator.Conference.MeetingRoom>();

  return useMemo(() => {
    return instance.participant.role === CallRole.Scheduler || (
      instance.status === 'meeting-room' && !instance.participants.some(p => p.id !== instance.pid && p.role === CallRole.Scheduler)
    );
  }, [instance.status, instance?.participants, instance?.participant, instance?.pid]);
};