import { Header } from '../Header';
import { useDeviceName } from './hooks';

export const DeviceInUse = () => {
  const device = useDeviceName();
  return (
    <>
      <Header>{`Can't start your ${device}`}</Header>
      <div>{`Another application (Zoom, Webex) or browser tab (Google Meet, Messenger Video) might already be using your ${device}. Please close other conferencing applications before proceeding.`}</div>
    </>
  );
};