import { useCallback, useMemo } from 'react';
import type { SurveyAIEGroup } from '@/types';
import DropDown from '@/components/DropDown';

type DropdownItem = {
  id: number;
  identifier: string;
  ordinal: number;
  name: string;
};

type Props = {
  disabled?: boolean;
  items: SurveyAIEGroup[];
  onSelect: (identifier: string) => void;
  value: SurveyAIEGroup;
};

export const AIEGroupDropdown = ({
  items,
  onSelect,
  value,
}: Props) => {
  const getValueFromName = useCallback((name: string) => {
    if (!name) return 'Select Concept';
    return `C${name}`;
  }, []);

  const getItemValue = useCallback((item: DropdownItem) => {
    return getValueFromName(item.name);
  }, [getValueFromName]);

  const handleSelect = useCallback((item: DropdownItem) => {
    onSelect(item.identifier);
  }, [onSelect]);

  const mappedItems: DropdownItem[] = useMemo(() => items.map(m => ({
    id: m.ordinal,
    identifier: m.identifier,
    ordinal: m.ordinal,
    name: m.name,
  })), [items]);

  return (
    <DropDown
      items={mappedItems}
      getItemValue={getItemValue}
      onSelect={handleSelect}
      text={getValueFromName(value?.name)} />
  );
};

export default AIEGroupDropdown;