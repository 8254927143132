import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

type Params = {
  slug?: string;
} & Partial<Stringify<IUserId>>;

const useMatchUserIdFromUrl = () => {
  const params = useParams<Params>();

  return useMemo(() => {
    const param = params.userId || params.slug;
    const [userId] = param.split('-').reverse();

    return +userId;
  }, [
    params.slug,
    params.userId,
  ]);
};

export { useMatchUserIdFromUrl };
export default useMatchUserIdFromUrl;