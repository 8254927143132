import { useEffect } from 'react';
import { useFetchProjectGoal } from '@utils/api';
import { ProjectGoalProgressInfo } from './interfaces';
import { useProjectUsers } from './useProjectUsers';

const useCalcProjectGoal = (projectId: number): ProjectGoalProgressInfo => {
  const [response, fetchProjectGoal] = useFetchProjectGoal();
  const records = useProjectUsers(projectId);

  useEffect(() => {

    if (projectId) fetchProjectGoal(projectId);

  }, [
    fetchProjectGoal,
    projectId,
  ]);

  if (!response?.value?.goal?.value) return null;

  function calcProgress(val: number) {
    return (records.length / val) * 100;
  }

  function calcRemaining(val: number) {
    return records.length >= val ? 0 : val - records.length;
  }

  const adjustedValue = Math.round(response.value.goal.value * 2.5);

  return {
    current: records.length,
    progress: calcProgress(adjustedValue),
    remaining: calcRemaining(adjustedValue),
    target: adjustedValue,
    value: response.value.goal.value,
  };
};

export { useCalcProjectGoal };
export default useCalcProjectGoal;