import { useCallback, useMemo, useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as $api from '@api';
import { WorkspaceObjectType } from '@enums';
import { useInvalidateFilesQueries } from '@utils/api';
import type { WorkspaceObjectTreeItem } from '@/types/workspace';
import { useMoveObject } from '@containers/WorkspaceObject/hooks';
import { MoveObjectContext, MoveObjectContextValue } from '@containers/WorkspaceObject/Context';

type Props =
  {
    enabled?: boolean;
  } &
  IWorkspaceObjectId &
  ChildrenProps;

export const MoveCallTranscriptContainer = ({
  enabled = true,
  ...props
}: Props) => {

  const [parentObjectId, setParentObjectId] = useState<number>(null);

  const query = useQuery(['get/object:move-details', props.objectId], () => {
    return $api.workspaces.object.moveObjectDetails({
      objectId: props.objectId,
    });
  }, {
    enabled,
  });

  const invalidateQueries = useInvalidateFilesQueries();

  const moveObject = useMoveObject(WorkspaceObjectType.CallTranscript);

  useEffect(() => {
    setParentObjectId(null);
  }, []);

  const onSave = useCallback(() => {
    return moveObject({
      objectId: props.objectId,
      parentObjectId,
      workspaceId: query.data.object.workspaceId,
    }).then(() => {
      invalidateQueries();
    });
  }, [
    invalidateQueries,
    moveObject,
    props.objectId,
    query.data?.object?.workspaceId,
    parentObjectId,
  ]);

  const currentPath = useMemo(() => {
    if (!query.data?.breadcrumbs) return [];
    return query.data.breadcrumbs.map(m => m.name);
  }, [query.data?.breadcrumbs]);

  const buildTreeItem = useCallback((objectId: number): WorkspaceObjectTreeItem => {
    const objects = query.data?.items ?? [];
    const object = objects.find(f => f.id === objectId);

    const children = Object.values(objects)
      .filter(f => f.parentObjectId === objectId)
      .map(m => buildTreeItem(m.id));

    const sortedChildren = [...children].sort((a, b) => a.object.name.localeCompare(b.object.name));

    return {
      children: sortedChildren,
      object,
    };
  }, [query.data?.items]);

  const projectParentObject = useMemo(() => {
    const objects = query.data?.breadcrumbs ?? [];
    const object = objects.find(f => f.typeId === WorkspaceObjectType.ProjectParent);
    return object;
  }, [query.data?.breadcrumbs]);

  const tree = useMemo(() => {
    return buildTreeItem(projectParentObject?.id);
  }, [
    buildTreeItem,
    projectParentObject,
  ]);

  const loading = query.isInitialLoading || !tree;

  const value: MoveObjectContextValue = {
    currentPath,
    data: tree,
    object: query.data?.object,
    loadingTree: loading,
    onSave,
    onSelectParentObject: setParentObjectId,
    parentObjectId,
  };

  return (
    <MoveObjectContext.Provider value={value}>
      {props.children}
    </MoveObjectContext.Provider>
  );
};

export default MoveCallTranscriptContainer;