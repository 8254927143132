import {
  BrandInsightsChatScreen,
  BrandInsightsStandaloneContainer,
} from '@/brand-insights';
import { useBrandInsightsAuthor, useBrandInsightsCapabilities } from '@containers/BrandInsights/hooks';

export const BrandInsightsChat = () => {

  const author = useBrandInsightsAuthor();
  const capabilities = useBrandInsightsCapabilities();

  return (
    <BrandInsightsStandaloneContainer author={author} capabilities={capabilities}>
      <BrandInsightsChatScreen />
    </BrandInsightsStandaloneContainer>
  );
};