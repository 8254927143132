import { memo } from 'react';
import { cx } from '@utils';
import { UserAvatar } from '@/components/UserAvatar';
import styles from './BaseballCard.css';

type Props = {
  className?: string;
  user: {
    name: string;
  };
  subtext?: string;
};

export const ExternalCardContent = memo(({ user, subtext }: Props) => {
  return (
    <div className={styles.contentContainer}>
      <div className={styles.textContainer}>
        <div>
          <UserAvatar
            className={cx(styles.avatar, styles.anonAvatar)}
            pictureUrl={undefined}
            size={50} />
        </div>
        <div>
          <div>
            <span className={styles.expertName}>{user.name}</span>
          </div>
          {subtext &&
            <div className={styles.subtext}>{subtext}</div>
          }
        </div>
      </div>
    </div>
  );
});