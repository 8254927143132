import CameraOnIcon from '@mui/icons-material/Videocam';
import styles from './style.css';

type Props = {
  resolution: number;
  visible: boolean;
};

export function CameraQuality(props: Props) {
  if (!props.visible) return null;

  const resolution = props.resolution
    ? `${props.resolution}p`
    : 'N/A';

  return (
    <div className={styles.root}>
      <div className={styles.title}>Camera Quality</div>
      <div className={styles.content}>
        <div className={styles.icon}><CameraOnIcon /></div>
        <div className={styles.quality}>{resolution}</div>
      </div>
    </div>
  );
}

export default CameraQuality;