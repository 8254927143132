import { useQuery } from '@tanstack/react-query';
import * as api from '@api';

type Props = {
  enabled?:  boolean;
} & IProjectId;

export const useProjectTagsQuery = (props: Props) => {

  const query = useQuery({
    queryKey: [
      `get:projects/conferences/tags`,
      props.projectId,
    ],
    queryFn: () => {
      return api.projects.conference.getTags({
        projectId: props.projectId,
      });
    },
    enabled: props.enabled,
    keepPreviousData: true,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  return query;
};