import { createContext } from 'react';
import type {
  ProjectSurveySettingsContextValue,
  SurveySettingsFormValues,
  SurveySettingsFormEditing,
  UpdateSurveySettingsFormMutation,
} from './interfaces';

export const ProjectSurveySettingsContext = createContext<ProjectSurveySettingsContextValue>(null);

export const SurveySettingsFormRefetchContext = createContext<() => void>(() => {});
export const SurveySettingsFormContext = createContext<SurveySettingsFormValues>(null);
export const SurveySettingsFormEditingContext = createContext<SurveySettingsFormEditing>(null);
export const UpdateSurveySettingsFormMutationContext = createContext<UpdateSurveySettingsFormMutation>(null);