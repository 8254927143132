import { useCallback } from 'react';
import type { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { querykey } from '@/consts';
import * as $api from '@/services/api';
import type { WorkspaceObjects as WS } from '@/services/api/interfaces';

type Props = WS.GetObjectChildren.Request;
type Data = WS.GetObjectChildren.Response;
type QueryKey = readonly [string, Props];
type Options = UseQueryOptions<Data, AxiosError, Data, QueryKey>;

export const useFetchObjectChildren = (props: Props, options?: Options) => {
  const qk = querykey.Workspaces.Children.Get(props);

  return useQuery(qk, ({ queryKey }) => {
    const [, params] = queryKey;
    return $api.workspaces.object.getObjectChildren(params);
  }, options);
};

export const useInvalidateObjectChildrenQuery = () => {
  const queryClient = useQueryClient();

  return useCallback(() => {
    const baseQueryKey = querykey.Workspaces.Children.Base;
    return queryClient.invalidateQueries([baseQueryKey]);
  }, [queryClient]);
};