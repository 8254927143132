import { useState, useCallback } from 'react';

type AccordionMap = {
  [key: number]: boolean;
};

function generateInitialMap(values: number[]) {
  if (!values) {
    return {};
  }
  return values.reduce((acc, x) => {
    return {
      ...acc,
      [x]: true,
    };
  }, {} as AccordionMap);
}

export const useAccordionMap = (initialOpen?: number[]) => {
  const [map, setMap] = useState<AccordionMap>(generateInitialMap(initialOpen));

  const toggleOpen = useCallback((v: number) => () => {
    setMap(m => ({
      ...m,
      [v]: !m[v],
    }));
  }, [
    setMap,
  ]);

  const isOpen = useCallback((v: number) => {
    return !!map[v];
  }, [
    map,
  ]);

  const reset = useCallback((values: number[]) => {
    setMap(generateInitialMap(values));
  }, [
    setMap,
  ]);

  return [isOpen, toggleOpen, reset] as const;
};

export default useAccordionMap;