import { useMemo } from 'react';
import { NumberInputTableQuestion } from '@/types/survey';
import { OptionsMap, fromArray } from '$admin/components/Select';

type Props = {
  settings: NumberInputTableQuestion.Settings;
};

export const useNumberInputTableDropdownOptions = ({ settings }: Props) => {

  // TODO: Do number settings need to be taken in to account?
  const options: OptionsMap = useMemo(() => {
    if (!settings.dropdowns.enabled) return {};

    const values: number[] = [];
    let counter = settings.validations.minValue;
    while (counter <= settings.validations.maxValue) {
      values.push(counter);
      counter += settings.dropdowns.increment;
    }

    return {
      '': '-',
      ...fromArray(values),
    };
  }, [
    settings.dropdowns,
    settings.validations,
  ]);

  return options;

};