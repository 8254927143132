import { memo } from 'react';
import { cx } from '@utils';
import { Icon } from './Card.CI';
import styles from './style/Card.css';

type Props = {
  onClick?: () => void;
};

export const MarketingCampaignEffectivenessCard = memo(({ onClick }: Props) => {

  const title = `Marketing Campaign Effectiveness`;

  return (
    <div
      className={styles.root}
      onClick={onClick}>
      <div className={cx(styles.poster, styles.mce)}>
        <Icon color="#5b5da8" />
      </div>
      <div className={styles.footer}>
        <div className={styles.title}>{title}</div>
      </div>
    </div>
  );
});