import { useMemo, useCallback } from 'react';
import DropDown from '@/components/DropDown';

export const OperatorDropdown = ({
  items = ['=', '>', '>=', '<', '<='],
  onSelect,
  value,
}: Props) => {

  const handleSelect = useCallback((item: OperatorDropdownItem) => {
    onSelect(item.id);
  }, [onSelect]);

  const mappedItems = useMemo(() => {
    return items.map(m => ({ id: m }));
  }, [items]);

  return (
    <DropDown
      items={mappedItems}
      getItemValue={v => v.id}
      onSelect={handleSelect}
      text={value} />
  );
};

type OperatorDropdownItem = {
  id: Operator;
};

type Props = {
  items?: Operator[];
  onSelect: (item: Operator) => void;
  value: Operator;
};

export default OperatorDropdown;