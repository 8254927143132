import { useCallback } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks/useSurveyBuilderContext';
import { useRichTextEditorRegister } from '@/containers/SurveyBuilder/hooks/useRichTextEditorRegister';
import type { SurveyRichText } from '@/types/survey.rich-text';
import { useSurveyBuilderItemEditing } from '@containers/SurveyBuilder/Context';
import type { SurveyRichTextEditorOnChange } from '@/components/Survey.RichText';
import {
  SurveyRichTextEditorContainer,
  useSurveyRichTextEditor,
} from '@/components/Survey.RichText';
import { LinkContainer } from '@/components/Remirror';
import { useSurveyMessageBuilderItemContext } from './context';

type Props = ChildrenProps;

export const MessageBuilderEditorContainer = (props: Props) => {
  const [_, dispatch] = useSurveyBuilderState();
  const item = useSurveyMessageBuilderItemContext();

  const { editing } = useSurveyBuilderItemEditing();

  const { manager, state, setState } = useSurveyRichTextEditor({
    initialState: item.value,
    placeholder: 'Enter your message',
  });

  const handleChange: SurveyRichTextEditorOnChange = useCallback(params => {
    if (!params.tr?.docChanged) return;

    setState(params.state);

    const value = params.state.doc.toJSON() as SurveyRichText.RichTextValue;

    dispatch({
      type: 'message-value-updated',
      messageIdentifier: item.identifier,
      value,
    });
  }, [
    item.identifier,
    dispatch,
    setState,
  ]);

  const editorRef = useRichTextEditorRegister({
    identifier: item.identifier,
  });

  return (
    <SurveyRichTextEditorContainer
      editable={editing}
      manager={manager}
      onChange={handleChange}
      state={state}
      editorRef={editorRef}>
      <LinkContainer>
        {props.children}
      </LinkContainer>
    </SurveyRichTextEditorContainer>
  );
};