import { useMemo } from 'react';
import * as utils from '@utils';
import { useUserRoles } from './useUserRoles';

export const useHasChatOnlyRole = () => {
  const roles = useUserRoles();

  const hasInsightsChatOnlyRole = useMemo(() => {
    return utils.hasInsightsChatOnlyRole({ roles });
  }, [roles]);

  return hasInsightsChatOnlyRole;
};

export default useHasChatOnlyRole;