import { useState } from 'react';
import { TranscriptFocusedCommentContext } from './Context';

type Props = {
  children: React.ReactNode;
};

export const TranscriptCommentingFocusContainer = (props: Props) => {
  const [focusedComment, setFocusedComment] = useState<string>(null);

  return (
    <TranscriptFocusedCommentContext.Provider value={[focusedComment, setFocusedComment]}>
      {props.children}
    </TranscriptFocusedCommentContext.Provider>
  );
};

TranscriptCommentingFocusContainer.displayName = 'Transcript.Commenting.Focus.Container';