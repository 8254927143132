import { useQuery } from '@tanstack/react-query';
import * as api from '$admin/api';

type Props = {
  projectId: number;
};

export function useFetchProjectDetails({ projectId }: Props) {
  return useQuery(['admin-project-details', projectId], () => {
    return api.projects.details.fetchProjectDetails({
      projectId,
    });
  }, {
    enabled: !!projectId,
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
}