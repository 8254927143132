import { useCallback, useContext, useMemo, useState } from 'react';
import { Edit2 } from 'react-feather';
import { TranscriptFocusedTagContext } from '@containers/Transcript.Tagging/Context';
import { useTranscriptTagsContext } from '@containers/Transcript.Tagging/hooks';
import { useTranscriptToolsPermission } from '@containers/Transcript/hooks';
import { cx } from '@utils';
import type { TaggedMoment } from '@/types/transcribe.rich-text';
import { ClickAwayListener } from '@/components/ClickawayListener';
import { Portal } from '@/components/Portal';
import { PopperMenu } from '@/components/Popper';
import { TagTimePickingContext } from './context';
import { EditPopper } from './Tag.EditPopper';
import { TagBadge } from './TagBadge';
import { useBlurHighlight, useFocusTag, useUnfocusTag, useShowTagHighlight, useHideTagHighlight } from './hooks';
import styles from './style/Tags.Highlight.css';

type Props = {
  tag: TaggedMoment;
};

export const SidebarTag = ({ tag }: Props) => {
  const [focusedTag] = useContext(TranscriptFocusedTagContext);
  const [_, dispatch] = useTranscriptTagsContext();
  const isFocused = useMemo(() => focusedTag === tag.identifier, [focusedTag, tag]);
  const [isEditing, setIsEditing] = useState<boolean>(!tag.id); //Set editing to true if the tag is new
  const { picking: isClickPicking, setPicking: setIsClickPicking } = useContext(TagTimePickingContext);

  const blurHighlight = useBlurHighlight();
  const focusTag = useFocusTag();
  const unfocusTag = useUnfocusTag();

  const unFocus = useCallback(() => {
    unfocusTag();
    setIsEditing(false);
    blurHighlight();
    setIsClickPicking(false);
    if (!tag.id) {
      dispatch({
        type: 'tag-removed',
        highlightIdentifier: tag.identifier,
      });
    }
  }, [unfocusTag, blurHighlight, dispatch, tag.id, tag.identifier, setIsClickPicking]);

  const onClickAway = useCallback(() => {
    if (!isClickPicking) {
      unFocus();
    }
  }, [unFocus, isClickPicking]);

  const showHighlight = useShowTagHighlight();
  const hideHightlight = useHideTagHighlight();

  const [showEdit, setShowEdit] = useState(false);
  const handleMouseEnter = useCallback(() => {
    if (!isFocused) {
      showHighlight(tag.identifier);
    }

    setShowEdit(true);
  }, [isFocused, showHighlight, tag.identifier]);

  const handleMouseLeave = useCallback(() => {
    if (!isFocused) {
      hideHightlight(tag.identifier);
    }
    setShowEdit(false);
  }, [isFocused, hideHightlight, tag.identifier]);

  const permission = useTranscriptToolsPermission();

  const handleTagClick = useCallback(() => {
    if (!permission.tagging) return;

    focusTag(tag.identifier);
  }, [permission.tagging, focusTag, tag.identifier]);

  const handleEditClick = useCallback(() => {
    setIsEditing(true);
    focusTag(tag.identifier);
  }, [focusTag, tag.identifier]);

  const clickAwayDisabled = !(isEditing || isFocused) || isClickPicking;

  const canEdit = (isFocused || showEdit) && !isEditing && permission.tagging;

  return (
    <ClickAwayListener
      mouseEvent='onMouseDown'
      touchEvent='onTouchStart'
      disabled={clickAwayDisabled}
      onClickAway={onClickAway}>
      <div>
        <div
          className={cx(styles.tagRoot)}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
          <TagBadge
            tag={tag}
            className={cx(styles.tagBadge, isFocused ? styles.tagFocused : null, !tag.id ? styles.placeholderTag : null)}
            onClick={handleTagClick} />
          {canEdit &&
            <Edit2
              className={styles.editIcon}
              size={16}
              onClick={handleEditClick} />}
        </div>
        {isEditing &&
          <Portal>
            <div className={styles.popperContainer}>
              <PopperMenu>
                <EditPopper initialState={tag} closePopper={unFocus} />
              </PopperMenu>
            </div>
          </Portal>}
      </div>
    </ClickAwayListener>
  );
};