import { useCallback, useState } from 'react';
import * as api from '@api';
import type { WorkspacePastGlobalSearch } from '@/types';
import { PastSearchesContext } from './Context';
import type { AddPastSearch } from './interfaces';

export default function PastSearchesContainer({ children }: ChildrenProps) {
  const [items, dispatch] = useState<WorkspacePastGlobalSearch[]>(mergeInitial());

  const fetchPast = useCallback(async () => {
    const results = await api.workspaces.globalSearch.getPast();
    dispatch(results.items);
  }, []);

  const addPast = useCallback((data: AddPastSearch) => {
    api.workspaces.globalSearch.addPast({
      query: data.query,
      entityId: data.entityId,
      item: data.item,
    }).then(() => fetchPast());
  }, [fetchPast]);

  const value = {
    items,
    fetchPast,
    addPast,
  };

  return (
    <PastSearchesContext.Provider value={value}>
      {children}
    </PastSearchesContext.Provider>
  );
}

function mergeInitial(): WorkspacePastGlobalSearch[] {
  return [];
}

export { PastSearchesContainer };