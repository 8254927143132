import { useMemo } from 'react';
import { useFetchSuggestedTopics } from '@utils/api';
import { SuggestedTopicsContext } from './Context';

type Props = {
  children: React.ReactNode;
  size?:    number;
};

export const SuggestedTopicsContainer = (props: Props) => {
  const query = useFetchSuggestedTopics({ size: props.size });

  const topics = useMemo(() => {
    return query.data?.pages?.length
      ? query.data.pages.flatMap(x => x.items)
      : [];
  }, [query.data?.pages]);

  const context = {
    query,
    topics,
  };

  return (
    <SuggestedTopicsContext.Provider value={context}>
      {props.children}
    </SuggestedTopicsContext.Provider>
  );
};

SuggestedTopicsContainer.displayName = 'SuggestedTopics.Container';