import { AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as api from '@api';
import { Users } from '@api/interfaces';

type Data = Users.GetSessions.Response;

export function useFetchSessions<TData = Data>(
  options?: UseQueryOptions<Data, AxiosResponse, TData>
) {

  return useQuery(['auth-sessions'], () => {
    return api.users.getSessions();
  }, options);
}