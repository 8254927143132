import { hasOptions, hasRows } from '@/containers/Survey/utils/questions';
import { SurveyQuestion } from '@/types/survey';

export function canReuseOptions(question: SurveyQuestion) {
  return !question.metadata.linked?.options &&
    hasOptions(question);
}

export function canReuseRows(question: SurveyQuestion) {
  return !question.metadata.linked?.rows &&
    hasRows(question);
}