import { useCallback, useMemo } from 'react';
import { useProvidedDevices } from '@containers/DeviceProvider';
import { useConferenceInstance } from '@containers/Conference';
import type { Coordinator } from '@containers/Conference/interfaces';
import { BrowserDeviceHelp } from '@presentation/BrowserDeviceHelp';
import { useAlert } from '@/components/Modal/Alert';
import styles from './styles.css';

type CheckDeviceProps = {
  onConfirm: () => void;
};

export const useCheckDevice = (props: CheckDeviceProps) => {
  const devices = useProvidedDevices();
  const instance = useConferenceInstance<Coordinator.Conference.PreRoom>();

  const [toggleAlert, BaseAlert] = useAlert();

  const audioIssue = useMemo(() => devices.audioErrorState || devices.audioLoading, [devices]);
  const videoIssue = useMemo(() => devices.videoErrorState || devices.videoLoading, [devices]);

  const shouldShowAlert = useMemo(() => (audioIssue || videoIssue) && (instance.settings.camera || instance.settings.microphone), [instance.settings, audioIssue, videoIssue]);

  const badDeviceText = useMemo(() => [instance.features.microphoneToggle && audioIssue ? 'microphone' : null, instance.features.cameraToggle && videoIssue ? 'camera' : null].filter(Boolean).join(' and/or '), [instance.features, audioIssue, videoIssue]);

  const visibilityText = useMemo(() => [instance.settings.camera && videoIssue ? 'see' : null, instance.settings.microphone && audioIssue ? 'hear' : null].filter(Boolean).join(' or '), [instance.settings, videoIssue, audioIssue]);

  const AlertContent = useCallback(() => {
    let message = `It looks like your browser is not giving ${badDeviceText} permissions. If you join the conference other participants won't be able to ${visibilityText} you.`;
    if (devices.audioErrorState || devices.videoErrorState || devices.audioLoading || devices.videoLoading) {
      message = `It looks like your browser is not giving ${badDeviceText} permissions. If you join the conference other participants won't be able to ${visibilityText} you.`;
    }

    return (
      <div>
        <div className={styles.title}>{message}</div>
        {devices.audioErrorState?.errMsg && <div className={styles.errMsg}>Error: {devices.audioErrorState.errMsg}</div>}
        {devices.videoErrorState?.errMsg && <div className={styles.errMsg}>Error: {devices.videoErrorState.errMsg}</div>}
        <BrowserDeviceHelp />
      </div>
    );
  }, [devices, badDeviceText, visibilityText]);

  const Alert = useCallback(() => {
    return (
      <BaseAlert
        className={styles.alertModal}
        confirmText='Join anyway'
        cancelText='Go back'
        onConfirm={props.onConfirm}
        message={<AlertContent />} />
    );

  }, [AlertContent, props.onConfirm, BaseAlert]);

  return [shouldShowAlert ? toggleAlert : props.onConfirm, Alert] as const;
};