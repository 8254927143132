import { useMemo } from 'react';
import { Sidebar } from '@presentation/Sidebar';
import { useProjectCreationNavigation } from './context';
import { useProjectCreationSteps } from './hooks';
import { ProjectCreationStepTitles } from './steps';
import styles from './style/style.old.css';

export const ProjectCreationSidebar = () => {

  const steps = useProjectCreationSteps();
  const { index } = useProjectCreationNavigation();

  const sidebarTitles = useMemo(() => {
    return steps.map(m => ({
      key: m.key,
      title: ProjectCreationStepTitles[m.key],
    }));
  }, [steps]);

  return (
    <Sidebar
      className={styles.sidebarWrap}
      index={index}
      steps={sidebarTitles} />
  );
};

export default ProjectCreationSidebar;