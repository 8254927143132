import { memo } from 'react';
import styles from './style/FAQ.css';

export const FAQ = memo(() => {
  const items = [{
    href: `https://www.sentimentglobal.com/faqs`,
    title: `What is Sentiment?`,
  }, {
    href: `https://www.sentimentglobal.com/faqs`,
    title: `How does compensation work?`,
  }, {
    href: `https://www.sentimentglobal.com/faqs`,
    title: `Who are our clients?`,
  }, {
    href: `https://www.sentimentglobal.com/faqs`,
    title: `View All >`,
  }];

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>Frequently Asked Questions</h2>
        <div className={styles.main}>
          {items.map(x =>
            <div
              className={styles.item}
              key={`${x.href}${x.title}`}>
              <a
                className={styles.link}
                href={x.href}
                rel="noreferrer"
                target="_blank">
                <div className={styles.text}>{x.title}</div>
              </a>
            </div>)}
        </div>
      </div>
    </div>
  );
});

export default FAQ;