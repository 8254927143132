import type {
  ApplySchemaAttributes,
  NodeExtensionSpec,
  NodeSpecOverride,
} from '@remirror/core';
import {
  command,
  extension,
  getTextSelection,
  CommandFunction,
  ExtensionTag,
  NodeExtension,
  PrimitiveSelection,
} from '@remirror/core';
import type { SurveyRichText } from '@/types/survey.rich-text';
import * as Remirror from '@/types/rich-text';
import { PipedResponse } from './Node.PipedResponse';

@extension({
  defaultOptions: {},
})
export class PipedResponseExtension extends NodeExtension {

  get name() {
    return Remirror.NodeType.PipedResponse;
  }

  createTags() {
    return [ExtensionTag.InlineNode];
  }

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    return {
      ...override,
      atom: true,
      attrs: {
        question: {
          default: null,
        },
        item: {
          default: null,
        },
        value: {},
        text: {
          default: '',
        },
        ...extra.defaults(),
      },
      inline: true,
      toDOM: node => ['span', {
        ...extra.dom(node),
        contentEditable: 'false',
        selectable: 'false',
      }],
    };
  }

  @command()
  addPipedResponseNode(attributes: SurveyRichText.PipedResponseNode['attrs'], selection?: PrimitiveSelection): CommandFunction {
    return ({ tr, dispatch }) => {

      const { from, to } = getTextSelection(selection ?? tr.selection, tr.doc);
      const node = this.type.create(attributes);

      dispatch?.(tr.replaceRangeWith(from, to, node));

      return true;
    };
  }

  ReactComponent = PipedResponse;
}