import { Card as BaseCard } from '@presentation/Main';
import { cx } from '@utils';
import styles from './style/Layout.css';

type Props = {
  className?: string;
  desktop?: boolean;
} & ChildrenProps;

export const Card = (props: Props) => {
  const className = cx(styles.card, props.className, {
    [styles.desktop]: props.desktop,
  });

  return (
    <BaseCard
      className={className}>
      {props.children}
    </BaseCard>
  );
};

export const Column = (props: Props) => {
  const className = cx(styles.col, props.className, {
    [styles.desktop]: props.desktop,
  });

  return (
    <div className={className}>
      {props.children}
    </div>
  );
};

export const ScreenLayout = (props: ChildrenProps) => {

  return (
    <div className={styles.root}>
      <div className={styles.main}>
        {props.children}
      </div>
    </div>
  );
};