import { Onboarding } from '@consts/path';
import { ContactMethod, ProfessionalRole } from '@enums';
import { useMedicalOnboardingQueriesState } from './hooks/useMedicalOnboardingQueriesState';
import { StepCheck } from './interfaces';
import { Optional, Required, validators } from './utils';

export enum Step {
  BackgroundAdditionalExpertise = 'Background.AdditionalExpertise',
  BackgroundContact = 'Background.Contact',
  BackgroundIdentification = 'Background.Identification',
  BackgroundLeadership = 'Background.Leadership',
  BackgroundPractice = 'Background.Practice',
  ExpertiseRole = 'Expertise.Role',
  ExpertiseSpecialty = 'Expertise.Specialty',
  /*
  ExpertiseSpecialties = 'Expertise.Specialties',
  ExpertiseSubspecialties = 'Expertise.Subspecialties',
  */
}

const StepChecks: StepCheck<typeof Step> = {
  [Step.BackgroundAdditionalExpertise]: state => {
    if (validators.isNonMedical(state.expertise?.professionalRole)) return false;

    return Object.values({
      boardEligibility: !state.background?.additionalExpertise?.boardEligibility?.answer,
      therapeuticAreas: !state.background?.additionalExpertise?.therapeuticAreas?.length,
    }).some(x => x === true);
  },
  [Step.BackgroundContact]: state => {
    if (validators.isNonMedical(state.expertise?.professionalRole)) return false;

    return Object.values({
      contactMethod: !state.background?.contact?.contactMethod,
      phone: !state.background?.contact?.phone,
      phoneAlternate: !state.background?.contact?.phoneAlternate,
      reminderMethod: !state.background?.contact?.reminderMethod,
      timezone: !state.background?.contact?.timezone,
      smsConsent: !state.background?.contact?.smsConsent && (state.background?.contact?.contactMethod === ContactMethod.Text || state.background?.contact?.reminderMethod === ContactMethod.Text),
      inPersonResearch: !state.background?.contact?.inPersonResearch,
      virtualResearch: !state.background?.contact?.virtualResearch,
      surveyResearch: !state.background?.contact?.surveyResearch,
      phoneResearch: !state.background?.contact?.phoneResearch,
    }).some(x => x === true);
  },
  [Step.BackgroundIdentification]: state => {
    const isCountryUSA = validators.isCountryUSA(state.background?.identification?.country);
    const isNonMedical = validators.isNonMedical(state.expertise?.professionalRole);

    return isNonMedical
    ? [
      state.background?.identification?.country,
      ...(state.background?.identification?.isPayer ? [state.background?.identification?.payerAdminType] : []),
    ].some(x => !x)
    : [
      state.background?.identification?.clinicalPracticeStartedOn,
      state.background?.identification?.country,
      ...(isCountryUSA && !Optional.npiNumber.includes(state.expertise?.professionalRole) ? [state.background?.identification?.npiNumber] : []),
      ...(isCountryUSA && Required.stateLicenseNumber.includes(state.expertise?.professionalRole) ? [state.background?.identification?.stateLicenseNumber] : []),
    ].some(x => !x);
  },
  [Step.BackgroundLeadership]: state => {
    if (state.expertise?.professionalRole !== ProfessionalRole.Physician) return false;

    return state.background?.leadership?.some?.(x => !x.answer);
  },
  [Step.BackgroundPractice]: state => {
    if (validators.isNonMedical(state.expertise?.professionalRole)) return false;

    return Object.values({
      employer: !state.background?.practice?.employer?.id,
      medicalFacilityType: !state.background?.practice?.medicalFacilityType?.answer,
      practiceTime: !state.background?.practice?.practiceTime?.answer?.items?.length,
    }).some(x => x === true);
  },
  [Step.ExpertiseRole]: state => !state.expertise?.professionalRole,
  [Step.ExpertiseSpecialty]: state => {
    const legacy = {
      specialties: state.expertise?.expertise?.industries?.length,
      subspecialties: state.expertise?.expertise?.subindustries?.length,
    };

    if (legacy.specialties && legacy.subspecialties) return false;

    return !state.expertise?.specialty?.primary?.group?.id
        || !state.expertise?.specialty?.primary?.subspecialty?.id;
  },
};

export const Steps = {
  all: [
    Step.ExpertiseRole,
    Step.ExpertiseSpecialty,
    Step.BackgroundIdentification,
    Step.BackgroundAdditionalExpertise,
    Step.BackgroundPractice,
    Step.BackgroundLeadership,
    Step.BackgroundContact,
  ],
  background: [
    Step.BackgroundIdentification,
    Step.BackgroundAdditionalExpertise,
    Step.BackgroundPractice,
    Step.BackgroundLeadership,
    Step.BackgroundContact,
  ],
  expertise: [
    Step.ExpertiseRole,
    Step.ExpertiseSpecialty,
  ],
};

const useSelectMedicalOnboardingSteps = (userId: number) => {
  const queries = useMedicalOnboardingQueriesState(userId);

  const state = {
    background: queries.background.data,
    expertise: queries.expertise.data,
  };

  return Steps.all.filter(step => {
    const validator = StepChecks[step];

    return validator
      ? validator(state)
      : true;
  });
};

export const useMedicalOnboardingStepsSelector = (userId: number, filter?: StepsFilter) => {
  const steps = useSelectMedicalOnboardingSteps(userId);

  return typeof filter === 'function'
      ? filter(steps)
      : steps;
};

type StepsFilter = (steps: Step[]) => Step[];

export const getPathFromStep = (step: Step) => {
  switch (step) {

    case Step.BackgroundAdditionalExpertise:
      return Onboarding.Background.AdditionalExpertise;

    case Step.BackgroundContact:
      return Onboarding.Background.Contact;

    case Step.BackgroundIdentification:
      return Onboarding.Background.Identification;

    case Step.BackgroundLeadership:
      return Onboarding.Background.Leadership;

    case Step.BackgroundPractice:
      return Onboarding.Background.Practice;

    case Step.ExpertiseRole:
      return Onboarding.Expertise.ProfessionalRole;

    case Step.ExpertiseSpecialty:
      return Onboarding.Expertise.Specialties;
    /*
    case Step.ExpertiseSpecialties:
      return Onboarding.Expertise.Specialties;

    case Step.ExpertiseSubspecialties:
      return Onboarding.Expertise.Subspecialties;
    */

  }
};

export const getStepFromPath = (path: string) => {
  switch (path) {

    case Onboarding.Background.AdditionalExpertise:
      return Step.BackgroundAdditionalExpertise;

    case Onboarding.Background.Contact:
      return Step.BackgroundContact;

    case Onboarding.Background.Identification:
      return Step.BackgroundIdentification;

    case Onboarding.Background.Leadership:
      return Step.BackgroundLeadership;

    case Onboarding.Background.Practice:
      return Step.BackgroundPractice;

    case Onboarding.Expertise.ProfessionalRole:
      return Step.ExpertiseRole;

    case Onboarding.Expertise.Specialties:
      return Step.ExpertiseSpecialty;
    /*
    case Onboarding.Expertise.Specialties:
      return Step.ExpertiseSpecialties;

    case Onboarding.Expertise.Subspecialties:
      return Step.ExpertiseSubspecialties;
    */
  }
};