import { cx } from '@utils';
import styles from './style/Table.css';

type Props = {
  children?:  React.ReactNode;
  className?: string;
};

export const Cell = (props: Props) => {
  return (
    <div className={cx(styles.cell, props.className)}>
      {props.children}
    </div>
  );
};