import { useContext } from 'react';
import { Switch } from '@/components/Switch';
import { FormDerivedEditStateContext, FormStateContext } from './Context';

type Props = {
  className?:     string;
  name:           string;
  value:          boolean;
  displayRenderer?: (value: boolean) => JSX.Element;
};

export const BooleanField = ({ className, name, value, displayRenderer }: Props) => {
  const editing = useContext(FormDerivedEditStateContext);

  return editing
    ? <BooleanInputField
        className={className}
        name={name} />
    : <BooleanDisplayField
        className={className}
        value={value}
        displayRenderer={displayRenderer} />;
};

export const BooleanDisplayField = (props: Omit<Props, 'name'>) => {
  if (props.displayRenderer)
    return props.displayRenderer(props.value);

  const value = props.value
      ? 'Enabled'
      : 'Disabled';

  return (
    <div className={props.className}>{value}</div>
  );
};

export const BooleanInputField = (props: Omit<Props, 'value'>) => {
  const [state, dispatch] = useContext(FormStateContext);

  const handleInputEvent = () => {
    dispatch({
      key: props.name as keyof typeof state.values,
      value: !state.values[props.name],
      type: 'value',
    });
  };

  return (
    <Switch
      active={state.values[props.name]}
      onClick={handleInputEvent} />
  );
};

export default BooleanField;