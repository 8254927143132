import { SurveyActionType, SurveyItemType, SurveyQuestionType } from '@enums/Survey';
import { SurveyBuilderVersion } from '@/types';
import {
  SurveyBuilderState,
} from './SurveyBuilderState';
import { SurveyBuilderOptionsContext } from './Context';
import { SubmitSurveyDraftContainer } from './SubmitSurveyDraftContainer';
import { RichTextEditorRegistryContainer } from './RichTextEditorRegistryContainer';

type Props = {
  initialValue?: SurveyBuilderVersion;
} & ChildrenProps;

const actionTypes = [
  SurveyActionType.None,
  SurveyActionType.Flag,
  SurveyActionType.Disqualification,
  SurveyActionType.SkipToEnd,
  SurveyActionType.SkipToQuestion,
];

const questionTypes = [
  SurveyQuestionType.ShortTextResponse,
  SurveyQuestionType.LongTextResponse,
  SurveyQuestionType.MultipleChoice,
  SurveyQuestionType.Multiselect,
  SurveyQuestionType.Ranking,
  SurveyQuestionType.MatrixGrid,
  SurveyQuestionType.Sliders,
];

const itemTypes = [SurveyItemType.Question];

export const ComplianceSurveyBuilderState = (props: Props) => {

  const details = {
    actionTypes,
    itemTypes,
    maxQuestions: 25,
    questionTypes,
    defaultSurveyDetails: { },
  };

  return (
    <SurveyBuilderOptionsContext.Provider value={details}>
      <RichTextEditorRegistryContainer>
        <SurveyBuilderState initialValue={props.initialValue}>
          <SubmitSurveyDraftContainer>
            {props.children}
          </SubmitSurveyDraftContainer>
        </SurveyBuilderState>
      </RichTextEditorRegistryContainer>
    </SurveyBuilderOptionsContext.Provider>
  );
};

export default ComplianceSurveyBuilderState;