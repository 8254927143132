export const Apps = {
  Connected: {
    Get: `get:groups/apps/connected`,
  },
  HubSpot: {
    Install: {
      Get: `get:groups/apps/hubspot/install`,
    },
  },
  Salesforce: {
    Install: {
      Get: `get:groups/apps/salesforce/install`,
    },
  },
};

export const Branding = {
  Get: `get:groups/branding`,
  Logo: {
    Delete: `delete:groups/branding/logo`,
    Post: `post:groups/branding/logo`,
  },
  Palettes: {
    Delete: `delete:groups/branding/palettes`,
    Post: `post:groups/branding/palettes`,
    Put: `put:groups/branding/palettes`,
  },
  Theming: {
    Put: `put:groups/branding/theming`,
  },
  Survey: {
    Pages: {
      Completion: {
        Image: {
          Delete: `delete:groups/branding/surveys/pages/completion/image`,
          Post: `post:groups/branding/surveys/pages/completion/image`,
        },
        Text: {
          Put: `put:groups/branding/surveys/pages/completion/text`,
        },
      },
    },
    Palettes: {
      Put: `put:groups/branding/surveys/palettes`,
    },
    Theming: {
      Get: `get:groups/surveys/theming`,
    },
  },
};

export const Contacts = {
  Delete: `delete:groups/contacts`,
  Get: `get:groups/contacts`,
  Import: {
    Post: `post:groups/contacts/import`,
    Analyze: {
      Post: `post:groups/contacts/import/analyze`,
    },
    Finalize: {
      Post: `post:groups/contacts/import/finalize`,
    },
    HubSpot: {
      Finalize: {
        Get: `get:contacts/import/hubspot/finalize`,
      },
      Run: {
        Post: `post:groups/contacts/import/hubspot/run`,
      },
    },
    Run: {
      Post: `post:groups/contacts/import/hubspot/run`,
    },
    Salesforce: {
      Finalize: {
        Get: `get:contacts/import/salesforce/finalize`,
      },
      Run: {
        Post: `post:groups/contacts/import/salesforce/run`,
      },
    },
  },
  Tags: {
    Get: `get:groups/contacts/tags`,
  },
};

export const Settings = {
  Patch: `patch:groups/settings`,
};