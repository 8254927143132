import { useMutation } from '@tanstack/react-query';
import * as api from '@api';
import * as QK from '@consts/querykey';
import { UGCImpressionType, UGCViewType } from '@enums';

export const useCreateImpressionMutation = (typeId: UGCImpressionType) => {
  const mutation = useMutation([QK.Posts.Analytics.Impressions.Post, typeId], (itemId: number) => {
    return api.posts.analytics.createImpressionEvent({
      itemId,
      typeId,
    });
  });

  return mutation;
};

export const useCreateViewEventMutation = (typeId: UGCViewType) => {
  const mutation = useMutation([QK.Posts.Analytics.Views.Post, typeId], (itemId: number) => {
    return api.posts.analytics.createViewEvent({
      itemId,
      typeId,
    });
  });

  return mutation;
};

export const useCreateViewArticlePostMutation = () => {
  return useCreateViewEventMutation(UGCViewType.ArticlePost);
};

export const useCreateViewFeedPostMutation = () => {
  return useCreateViewEventMutation(UGCViewType.FeedPost);
};

export const useCreateViewVideoMediaMutation = () => {
  return useCreateViewEventMutation(UGCViewType.DiscoveryVideo);
};