import { getClient } from './client';
import { rethrow } from './error';
import type { Email } from './interfaces';
import * as $metadata from './metadata';
import * as $session from './session';
import * as $sessionHttp from './session-http';

export const changePassword = ({ email }: Email.ChangePassword.Params) => {
  return new Promise((resolve, reject) => {
    $metadata.get<'change-password'>({
      action: 'change-password',
      email,
    })
    .then(metadata => {
      getClient().changePassword({
        connection: metadata.connection,
        email: metadata.username,
      }, (e, result) => {
        if (e) {
          console.error(e);
          return reject(e);
        }
        return resolve(result);
      });
    })
    .catch(e => {
      console.error(e);
      return reject(e);
    });
  });
};

export const login = (data: Email.Login.Params) => {
  return new Promise((resolve, reject) => {
    $metadata.get<'login'>({
      action: 'login',
      email: data.username,
    })
    .then(metadata => {
      getClient().client.login({
        password: data.password,
        realm: metadata.connection,
        scope: metadata.scope,
        username: metadata.username,
      }, (e, result) => {
        if (e) {
          console.error(e);
          return reject(e);
        }

        $sessionHttp.jwt({
          token: result.accessToken,
        })
        .then(session => {
          $session.set({
            authenticated: session.authenticated,
            csrf: session.csrf,
            expiry: session.expiry,
            userId: session.userId,
          });
          $session.setLastLoginStrategy({ strategy: 'standard' });
          return resolve(result);
        });
      });
    })
    .catch(e => {
      console.error(e);
      return reject(e);
    });
  })
  .catch(rethrow);
};

export const signup = (params: Email.Signup.Params) => {
  return new Promise((resolve, reject) => {
    $metadata.get<'signup'>({
      action: 'signup',
      email: params.email,
    })
    .then(metadata => {
      getClient().signupAndAuthorize({
        connection: metadata.connection,
        email: metadata.username,
        password: params.password,
        scope: metadata.scope,
        userMetadata: {
          ...params.metadata,
          origin: process.env.FRONT_BASE_URL,
          pathname: window.location.pathname,
          server: process.env.AUTH0_SERVER,
        },
      }, (e, result) => {
        if (e) {
          return reject(e);
        }

        $sessionHttp.jwt({
          token: result.accessToken,
        })
        .then(session => {
          $session.set({
            authenticated: session.authenticated,
            csrf: session.csrf,
            expiry: session.expiry,
            userId: session.userId,
          });
          $session.setLastLoginStrategy({ strategy: 'standard' });
          return resolve(result);
        });
      });
    })
    .catch(e => {
      return reject(e);
    });
  })
  .catch(rethrow);
};