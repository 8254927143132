import { useContext } from 'react';
import * as api from '@api';
import type * as API from '@api/interfaces';
import * as Discovery from '@/components/Discovery';
import { TopicsNavigationContext } from '@/containers/Discovery/Context';
import { cx, useAsyncState } from '@/utils';
import * as Category from './Category';
import * as Creator from './Creator';
import styles from './style/Discovery.css';

type Props = unknown;

export const DiscoveryMain = (props: Props) => {
  const topic = useContext(TopicsNavigationContext);

  const [state, refetch] = useAsyncState(() => {
    return Promise.all([
      api.discovery.fetchPopularVideoTopics(),
      api.discovery.fetchFeaturedCreators(),
    ])
    .then(([video, creators]) => ({
      video,
      creators,
    }));
  }, []);

  if (state.value?.video && topic.value) {
    const rows1 = state.value.video.items.slice(0, 2);
    const rows2 = state.value.video.items.slice(2, 4);
    const rows3 = state.value.video.items.slice(4);

    const featured1 = state.value.creators.items.slice(0, 4);
    const featured2 = state.value.creators.items.slice(4);

    const classes = {
      items: styles.row,
    };

    return (
      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.main}>
            <Discovery.TopicNavigation items={topic.value.items} />

            {rows1.map(x =>
              <Category.Row
                classes={classes}
                id={x.id}
                key={x.id}
                name={x.name}
                slug={x.slug}
                items={x.items} />)}

            <Creators items={featured1} />

            {rows2.map(x =>
              <Category.Row
                classes={classes}
                id={x.id}
                key={x.id}
                name={x.name}
                slug={x.slug}
                items={x.items} />)}

            <Creators items={featured2} />

            {rows3.map(x =>
              <Category.Row
                classes={classes}
                id={x.id}
                key={x.id}
                name={x.name}
                slug={x.slug}
                items={x.items} />)}
          </div>
        </div>
        <Discovery.Footer />
      </div>
    );
  }

  const classes = {
    item: styles.item,
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          {topic.value &&
            <Discovery.TopicNavigation items={topic.value.items} />}
          <div className={styles.skeleton}>
            <Discovery.Skeleton.Row classes={classes} />
            <Discovery.Skeleton.Row classes={classes} />
            <Discovery.Skeleton.Row classes={classes} />
          </div>
        </div>
      </div>
    </div>
  );
};

function Creators(props: API.Discovery.FetchFeaturedCreators.Response) {
  return (
    <Creator.Row
      classes={{
        root: cx(styles.tablet, styles.row),
        items: cx(styles.creators),
      }}
      items={props.items}
      name="Featured Members" />
  );
}

DiscoveryMain.displayName = 'Discovery.Main';