import { useCallback, useMemo } from 'react';
import { NumberInputTableQuestion } from '@/types/survey';
import { RowContextMenu } from './Row.ContextMenu';
import { useAnchoredRowMenuItem, useOpenEndedRowMenuItem } from './hooks/row-menu';

type Props = {
  row: NumberInputTableQuestion.Row;
  question: NumberInputTableQuestion.Question;
};

export const NumberInputTableRowContextMenu = (props: Props) => {

  const openEndedMenuItem = useOpenEndedRowMenuItem({
    row: props.row,
    questionIdentifier: props.question.base.identifier,
  });

  const anchoredMenuItem = useAnchoredRowMenuItem({
    row: props.row,
    question: props.question,
  });

  const items = useMemo(() => {

    return [
      openEndedMenuItem,
      props.question.settings.randomizeRows && anchoredMenuItem,
    ].filter(Boolean);
  }, [
    anchoredMenuItem,
    openEndedMenuItem,
    props.question.settings.randomizeRows,
  ]);

  return (
    <RowContextMenu
      row={props.row}
      items={items}
      excludeFindAndReplace={false} />
  );
};