import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import * as Twilio from '@twilio/conversations';
import { captureException } from '@sentry/react';
import type { ParticipantWeb } from '@/types/conferences.live';
import { ConferenceChatType, utils as enumUtils } from '@enums';
import { useCoordinator, useConferenceInstance } from '@containers/Conference/hooks';
import type * as IConference from '@containers/Conference/interfaces';
import * as utils from '@utils';
import { useConferenceAudioCuePreferences } from '@/components/Conference/hooks';
import type { MultiChat } from './interfaces';
import { ConferenceMultiChatContext } from './Context';
import * as chatUtils from './utils';

export function MultiChatContainer({ children }: ChildrenProps) {
  const { requestChatToken, initializeChatChannel } = useCoordinator();
  const [channels, setChannelsState] = useState<MultiChat.Channel[]>([]);
  const channelsRef = useRef<MultiChat.Channel[]>([]);
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom>();
  const [client, setClient] = useState<Twilio.Client>();
  const [unread, setUnread] = useState<number>(0);
  const [participants, setParticipants] = useState<Twilio.Participant[]>([]);
  const [messages, setMessages] = useState<Twilio.Message[]>([]);
  const [ready, setReady] = useState(false);
  const [chatSettings, setChatSettings] = useState<MultiChat.ChatAvailabilitySettings>();
  const notifyNewMessage = useNotifyNewMessage();
  const [activeChannel, setActiveChannel] = useState<MultiChat.Channel['identifier']>();

  const setChannels = useCallback((state: React.SetStateAction<MultiChat.Channel[]>) => {
    if (typeof state === 'function') {
      setChannelsState(channels => {
        const newChannels = state(channels);

        channelsRef.current = newChannels;

        return newChannels;
      });
    } else {
      setChannelsState(state);
      channelsRef.current = state;
    }
  }, []);

  useEffect(() => {
    if (chatSettings) {
      const settings = chatSettings;
      const you = instance.participants.find(p => p.id === instance.pid);

      //If we don't have "you" participant don't do anything yet.
      if (!you) return;
      //make sure chat is only available toward web participants
      const validDirect = instance.participants
        .filter(p => p.type === 'web'
          && p.id !== you?.id
          && settings[ConferenceChatType.Direct].includes(p.role)) as ParticipantWeb[];

      const directChannels = validDirect.map<MultiChat.Channel>(d => {
        return {
          identifier: chatUtils.buildDirectConversationIdentifier({
            conferenceIdentifier: instance.conferenceIdentifier,
            participants: [d, you],
          }),
          name: d.name,
          initialized: false, //assume nothing is initialized
          type: ConferenceChatType.Direct,
          them: {
            pid: d.id,
            chatIdentity: d.chatIdentity,
          },
          conversation: null,
          visible: d.status === 'meeting-room',
        };
      });

      const groupChannels = settings[ConferenceChatType.Group].map<MultiChat.Channel>(role => ({
        identifier: chatUtils.buildGroupConversationIdentifier({ conferenceIdentifier: instance.conferenceIdentifier, role }),
        name: enumUtils.CallRole.getName(role) + 's',
        initialized: false,
        type: ConferenceChatType.Group,
        role,
        conversation: null,
        visible: true,
      }));

      setChannels(channels => {
        const builtChannels = [...directChannels, ...groupChannels].map(c => {
          const existingChannel = channels.find(c2 => c2.identifier === c.identifier);
          return {
            ...c,
            initialized: existingChannel ? existingChannel.initialized : c.initialized,
            conversation: existingChannel ? existingChannel.conversation : c.conversation,
          };
        });

        return [...builtChannels, ...channels.filter(c => !builtChannels.some(c2 => c2.identifier === c.identifier))];
      });
    }
  }, [instance.participants, instance.participant.role, instance.pid, instance.conferenceIdentifier, chatSettings, setChannels]);

  const processPage = useCallback((items: Twilio.Message[], paginator: Twilio.Paginator<Twilio.Message>): Promise<Twilio.Message[]> => {
    if (paginator.hasPrevPage) {
      return paginator.prevPage()
        .then(p => processPage(items.concat(paginator.items), p));
    } else {
      return Promise.resolve(items.concat(paginator.items));
    }
  }, []);

  const processUnreadConversationCount = useCallback((conversation: Twilio.Conversation) => {
    // note: arbitrary delay to make sure we get correct/latest value from server
    setTimeout(() => {
      //If a conversation returns null that means that we haven't set how many messages we've read yet.
      //If there are any messages assume that we havent read them yet
      conversation.getUnreadMessagesCount().then(count => setUnread(oldCount => Math.min(99, Math.max(count ?? (conversation.lastMessage ? 1 : 0), oldCount))));
    }, 1000);
  }, []);

  const setInitializedChannel = useCallback(async (...args: Twilio.Conversation[]) => {
    const conversations = args
      .filter(c => (c.attributes as MultiChat.ConversationAttributes).conferenceIdentifier === instance.conferenceIdentifier)
      .filter(c => !channelsRef.current.find(c2 => c2.identifier === c.uniqueName)?.initialized);

    if (!conversations.length) return;

    function buildMissingThem(participantIdentities: [string, string]) {
      const theirIdentity = participantIdentities.find(i => i != instance.participant.chatIdentity);
      const participant = instance.participants.find(p => p.type === 'web' && p.chatIdentity === theirIdentity);
      return {
        pid: participant?.id,
        chatIdentity: participant?.type === 'web' ? participant.chatIdentity : null,
      };
    }

    const missingChannels: MultiChat.Channel[] = conversations
      .filter(c => !channelsRef.current.find(c2 => c2.identifier === c.uniqueName)).map(c => {
        const attributes = (c.attributes as MultiChat.ConversationAttributes);
        return {
          identifier: c.uniqueName,
          initialized: true,
          conversation: c,
          name: c.friendlyName, //TODO: fill this with the "TO" name if its direct
          visible: false,
          ...(attributes.type === ConferenceChatType.Group ? {
            type: ConferenceChatType.Group,
            role: attributes.role,
          } : {
            type: ConferenceChatType.Direct,
            them: buildMissingThem(attributes.participantIdentities),
          }),
        };
      });

    setChannels(channels => {
      return channels.reduce((acc, v) => {
        const matchingConversation = conversations.find(c => c.uniqueName === v.identifier);
        if (matchingConversation) {
          acc.push({
            ...v,
            initialized: true,
            conversation: matchingConversation,
          });
        } else {
          acc.push(v);
        }
        return acc;
      }, missingChannels);
    });

    await Promise.all(conversations.map(c => c.getParticipants().then(participants => {
      setParticipants(prev => [...prev.filter(p => !participants.some(p2 => p.sid === p2.sid)), ...participants]);
    })));

    await Promise.all(conversations.map(processUnreadConversationCount));

    return Promise.all(conversations.map(conversation => conversation.getMessages(100)
      .then(p => processPage([], p))
      .then(messages => {
        setMessages(old => [...old, ...messages]);
      })));
  }, [processPage, instance.conferenceIdentifier, setChannels, processUnreadConversationCount, instance.participant?.chatIdentity, instance.participants]);

  useEffect(() => {
    const conversations = dedupeConversations(channels.map(c => c.conversation).filter(Boolean));

    const participantJoined = (participant: Twilio.Participant) => {
      setParticipants(prev => [...prev.filter(p => p.sid != participant.sid), participant]);
    };

    const participantUpdated = ({ participant, updateReasons }: { participant: Twilio.Participant; updateReasons: string[] }) => {
      if (updateReasons.some(r => ['attributes'].includes(r))) {
        setParticipants(prev => [...prev.filter(p => p.sid === participant.sid), participant]);
      }
    };

    const messageAdded = (message: Twilio.Message) => {
      setMessages(old => [...old.filter(m => m.sid != message.sid), message]);
      notifyNewMessage();

      processUnreadConversationCount(message.conversation);
    };

    const messageUpdated = ({ message, updateReasons }: { message: Twilio.Message; updateReasons: string[] }) => {
      console.log(`Updating message ${message.sid} with reason(s): ${updateReasons.join(', ')}`);
      setMessages(old => [...old.filter(m => m.sid === message.sid), message]);
    };

    const messageRemoved = (message: Twilio.Message) => {
      setMessages(old => old.filter(m => m.sid != message.sid));
    };

    conversations.forEach(conversation => {
      conversation.on('participantJoined', participantJoined);
      conversation.on('participantUpdated', participantUpdated);

      conversation.on('messageAdded', messageAdded);
      conversation.on('messageUpdated', messageUpdated);
      conversation.on('messageRemoved', messageRemoved);
    });

    return () => {
      conversations.forEach(conversation => {
        conversation.off('participantJoined', participantJoined);
        conversation.off('participantUpdated', participantUpdated);

        conversation.off('messageAdded', messageAdded);
        conversation.off('messageUpdated', messageUpdated);
        conversation.off('messageRemoved', messageRemoved);
      });
    };

  }, [channels, processUnreadConversationCount, notifyNewMessage]);

  useEffect(() => {
    if (client) {
      client.on('conversationAdded', setInitializedChannel);

      return () => {
        client.off('conversationAdded', setInitializedChannel);
      };
    }
  }, [client, setInitializedChannel]);

  const processConversationPage = useCallback(async (page: Twilio.Paginator<Twilio.Conversation>): Promise<void> => {
    await setInitializedChannel(...page.items);
    if (page.hasNextPage) {
      return page.nextPage().then(processConversationPage);
    } else {
      return Promise.resolve();
    }
  }, [setInitializedChannel]);

  const initialize = useCallback(async (conferenceIdentifier: number) => {
    const response = await requestChatToken({ conferenceIdentifier });
    const newClient = new Twilio.Client(response.token);

    newClient.on('initialized', () => {
      setClient(newClient);
      setChatSettings(response.chatSettings);
      //setReady(true);
      //Shouldn't be necessary since the conversationAdded event gets fired for all conversations
      newClient.getSubscribedConversations().then(processConversationPage).then(() => {
        setActiveChannel(channelsRef.current.sort((a, b) => {
          return a.type - b.type;
        }).find(c => c.visible)?.identifier);
        setReady(true);
      });
    });

    newClient.on('initFailed', err => {
      captureException('Chat client initialization failure', {
        extra: err,
      });
    });
  }, [requestChatToken, processConversationPage]);

  const initializeChannel = useCallback((channelIdentifier: string) => {
    const channel = channels.find(c => c.identifier === channelIdentifier);
    initializeChatChannel({
      conferenceIdentifier: instance.conferenceIdentifier,
      conversationIdentifier: channel.identifier,
      ...(channel.type === ConferenceChatType.Direct ? {
        type: ConferenceChatType.Direct,
        toPid: channel.them.pid,
      } : {
        role: channel.role,
        type: ConferenceChatType.Group,
      }
      ),
    });
  }, [initializeChatChannel, instance.conferenceIdentifier, channels]);

  const sendMessage = useCallback((text: string, channelIdentifier: string) => {
    return channels.find(c => c.identifier === channelIdentifier)?.conversation?.sendMessage(text);
  }, [channels]);

  const consumeAll = useCallback(() => {
    setUnread(0);
    Promise.all(channels.map(c => c.conversation).filter(Boolean).map(c => c.setAllMessagesRead())).then(
      unreads => {
        setUnread(utils.sum(unreads));
      });
  }, [channels, setUnread]);

  const mappedChannels = useMemo(() => channels.map(({ conversation, ...rest }) => rest), [channels]);

  const ctxValue: MultiChat.Context = {
    initialize,
    initializeChannel,
    participants,
    unread,
    send: sendMessage,
    messages,
    consumeAll,
    channels: mappedChannels,
    ready,
    activeChannel,
    setActiveChannel,
  };

  return (
    <ConferenceMultiChatContext.Provider value={ctxValue}>
      {children}
    </ConferenceMultiChatContext.Provider>
  );
}

const newChatMessageSoundUrl = utils.cdn.buildAudioCueUrl('new-chat-message.mp3');

const useNotifyNewMessage = () => {
  const [preferences] = useConferenceAudioCuePreferences();

  return useCallback(() => {
    if (preferences.playNewMessageSound) {
      const chime = new Audio(newChatMessageSoundUrl);
      chime.volume = .5;

      chime.play();
    }
  }, [preferences]);
};

function dedupeConversations(conversations: Twilio.Conversation[]) {
  const dedupedSids = utils.arr.distinct(conversations.map(c => c.sid));

  return dedupedSids.map(s => conversations.find(c => c.sid === s));
}