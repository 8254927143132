import * as Yup from 'yup';
import type { ConferenceFeatureShareType } from '@enums';
import { ConferenceMediaType, ConferencePlatform, ConferenceType, utils as enumUtils } from '@enums';
import type { ConferenceSettings } from '@/types';
import { Remirror } from '@/types';
import { defaultConferenceDetailsContent } from '@/components/Conference';
import type { TelInputState } from '@/components/TelInput';

export function isOffPlatform(settings: Pick<ConferenceSettings, 'conferencePlatform'>) {
  return settings.conferencePlatform !== ConferencePlatform.Sentiment;
}

export function showConferenceMediaType(settings: Pick<ConferenceSettings, 'conferencePlatform' | 'conferenceType'>) {
  return false;
  return !isOffPlatform(settings) &&
    [ConferenceType.Web, ConferenceType.WebAndPhone].includes(settings.conferenceType);
}

export function showConferenceLink(settings: ConferenceSettings) {
  return isOffPlatform(settings) &&
    [ConferenceType.Web, ConferenceType.WebAndPhone].includes(settings.conferenceType);
}

export function showConferenceDialIn(settings: ConferenceSettings) {
  return isOffPlatform(settings) &&
    [ConferenceType.Telephone, ConferenceType.WebAndPhone].includes(settings.conferenceType);
}

export function showConferenceDetails(settings: Pick<ConferenceSettings, 'conferencePlatform'>) {
  return isOffPlatform(settings);
}

export function getConferenceTypes(settings: Pick<ConferenceSettings, 'conferencePlatform'>) {
  return isOffPlatform(settings)
    ? [ConferenceType.Web, ConferenceType.InPerson, ConferenceType.Telephone]
    : [ConferenceType.Telephone, ConferenceType.Web, ConferenceType.WebAndPhone];
}

export const resolve = {
  clientNotes: (value: Remirror.RootNode) => {
    const empty = isConferenceDetailsEmpty(value);

    return !empty ? value : null;
  },
  conferenceDetails: (value: Remirror.RootNode) => {
    const empty = isConferenceDetailsEmpty(value);

    return !empty ? value : null;
  },
  conferenceDialIn: (value: string) => value === '' ? null : value,
  conferenceLink: (value: string) => value === '' ? null : value,
};

export function resolveConferenceSettings(settings: ConferenceSettings): ConferenceSettings {
  return {
    ...settings,
    clientNotes: resolve.clientNotes(settings.clientNotes),
    conferenceDetails: resolve.conferenceDetails(settings.conferenceDetails),
    conferenceDialIn: resolve.conferenceDialIn(settings.conferenceDialIn),
    conferenceLink: resolve.conferenceLink(settings.conferenceLink),
    transcriptionQa: !!settings.transcriptionQa,
  };
}

export function isConferenceDetailsEmpty(value: Remirror.RootNode) {

  if (!value) return true;

  function isParagraphNodeEmpty(node: Remirror.ParagraphNode) {
    const content = (node.content || []);
    if (!content.length) return true;

    return content.every(isParagraphContentEmpty);
  }

  return (value.content || []).reduce((acc, node) => {
    if (!acc) return acc;

    const isEmpty = isParagraphNodeEmpty(node);
    return isEmpty;
  }, true);
}

function isParagraphContentEmpty(node: Remirror.TextNode) {

  if (node.type === Remirror.NodeType.Text) {
    return !node.text.trim().length;
  }

  return false;
}

type Form = {
  dialInValid: boolean;
  title: string;
} & ConferenceSettings;

export function resolveDefaultSettings(initialValue: ConferenceSettings, projectName: string): Form {
  return {
    ...initialValue,
    clientNotes: initialValue.clientNotes || defaultConferenceDetailsContent,
    conferenceLink: initialValue.conferenceLink || '',
    conferenceDialIn: initialValue.conferenceDialIn || '',
    conferenceDetails: initialValue.conferenceDetails || defaultConferenceDetailsContent,
    dialInValid: true,
    title: projectName,
    transcriptionQa: !!initialValue.transcriptionQa,
  };
}

export function platformChanged(value: ConferencePlatform, state: Form) {
  const offPlatform = isOffPlatform({ conferencePlatform: value });

  return {
    ...state,
    conferenceDetails: defaultConferenceDetailsContent,
    conferenceDialIn: '',
    conferenceLink: '',
    conferencePlatform: value,
    conferenceType: offPlatform ? ConferenceType.Web : ConferenceType.WebAndPhone,
    dialInValid: true,
  };
}

export function phoneChanged(value: TelInputState, state: Form) {
  return {
    ...state,
    conferenceDialIn: value.value,
    dialInValid: value.valid,
  };
}

export function conferenceTitleChanged(value: string, state: Form) {
  return {
    ...state,
    title: value,
  };
}

export function conferenceVideoShareChanged(value: ConferenceFeatureShareType, state: Form): Form {
  return {
    ...state,
    videoShareTypeId: value,
  };
}

export function conferenceScreenShareChanged(value: ConferenceFeatureShareType, state: Form): Form {
  return {
    ...state,
    screenShareTypeId: value,
  };
}

export function conferenceLanguagesChanged(value: Descriptor[], state: Form): Form {
  return {
    ...state,
    languages: value,
  };
}

export function conferenceTypeChange(value: ConferenceType, state: Form) {
  const offPlatform = isOffPlatform({ conferencePlatform: state.conferencePlatform });

  const settings = {
    ...state,
    conferenceType: value,
  };

  if (offPlatform) {
    switch (value) {
      case ConferenceType.InPerson:
        return {
          ...settings,
          conferenceDialIn: '',
          conferenceLink: '',
          dialInValid: true,
        };

      case ConferenceType.Telephone:
        return {
          ...settings,
          conferenceLink: '',
        };

      case ConferenceType.Web: {
        return {
          ...settings,
          conferenceDialIn: '',
          dialInValid: true,
        };
      }
      case ConferenceType.WebAndPhone:
      default:
        return settings;

    }
  } else {
    return {
      ...settings,
      conferenceDetails: defaultConferenceDetailsContent,
      conferenceDialIn: '',
      conferenceLink: '',
      dialInValid: true,
    };
  }
}

export const ConferenceSettingsSchema = Yup.object().shape({
  clientNotes: Yup.object().nullable(),
  conferenceLink: Yup.string()
    // .url('Must be a valid url')
    .when(['conferenceType', 'conferencePlatform'], {
      is: (conferenceType: ConferenceType, conferencePlatform: ConferencePlatform) => {
        return isOffPlatform({ conferencePlatform }) &&
          [ConferenceType.Web, ConferenceType.WebAndPhone].includes(conferenceType);
      },
      then: schema => schema.required('Required'),
    })
    .when(['conferenceType', 'conferencePlatform'], {
      is: (conferenceType: ConferenceType, conferencePlatform: ConferencePlatform) => {
        return !isOffPlatform({ conferencePlatform }) ||
          ![ConferenceType.Web, ConferenceType.WebAndPhone].includes(conferenceType);
      },
      then: schema => schema.oneOf([null, '']),
    }),
  conferenceDetails: Yup.object()
    .when(['conferencePlatform'], {
      is: (conferencePlatform: ConferencePlatform) => {
        return !isOffPlatform({ conferencePlatform });
      },
      then: schema => schema.test({
        exclusive: true,
        name: 'conference-details-not-empty',
        test: (value, ctx) => {
          // @ts-ignore
          if (!isConferenceDetailsEmpty(value as Remirror.RootNode)) {
            return ctx.createError({ message: 'Conference Details not empty' });
          }

          return true;
        },
      }),
    }),
  conferenceDialIn: Yup.string()
    .when(['conferenceType', 'conferencePlatform'], {
      is: (conferenceType: ConferenceType, conferencePlatform: ConferencePlatform) => {
        return isOffPlatform({ conferencePlatform }) &&
          [ConferenceType.Telephone, ConferenceType.WebAndPhone].includes(conferenceType);
      },
      then: schema => schema.required('Required'),
    })
    .when(['conferenceType', 'conferencePlatform'], {
      is: (conferenceType: ConferenceType, conferencePlatform: ConferencePlatform) => {
        return !isOffPlatform({ conferencePlatform }) ||
          ![ConferenceType.Telephone, ConferenceType.WebAndPhone].includes(conferenceType);
      },
      then: schema => schema.nullable().notRequired().oneOf([null, '']),
    })
    .when(['dialInValid'], {
      is: false,
      then: schema => schema.oneOf(['~']),
    }),
  conferenceMediaType: Yup.number()
    .required()
    .oneOf([ConferenceMediaType.Video, ConferenceMediaType.Voice], 'Invalid'),
  conferenceType: Yup.number()
    .required('Required')
    .oneOf(enumUtils.ConferenceType.values())
    .when(['conferencePlatform'], {
      is: (conferencePlatform: ConferencePlatform) => isOffPlatform({ conferencePlatform }),
      then: schema => schema.notOneOf([ConferenceType.WebAndPhone], 'Invalid'),
    })
    .when(['conferencePlatform'], {
      is: (conferencePlatform: ConferencePlatform) => !isOffPlatform({ conferencePlatform }),
      then: schema => schema.notOneOf([ConferenceType.InPerson], 'Invalid'),
    }),
  conferencePlatform: Yup.number()
    .required('Required')
    .oneOf(enumUtils.ConferencePlatform.values()),
  dialInValid: Yup.boolean().required().oneOf([true]),
});