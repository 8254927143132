import { Fragment, forwardRef, Ref, useCallback, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import { ProjectLink } from '@/types';
import { ProjectType } from '@/enums';
import { useDebounceCallback } from '@utils';
import { Share } from '@/components/icons';
import { MoneyInput } from '@/components/Input';
import styles from './style.css';

type Token = {
  token: {
    value: string;
  };
  url: string;
};

type FetchParams = Partial<Pick<ProjectLink, 'hourlyRate' | 'surveyCharge'>>;

type Props = {
  cacheKey: Array<string | number>;
  fetchToken: (params: FetchParams) => Promise<Token>;
  resetToken?: (value: string) => Promise<Token>;
  placement?: PopperPlacementType;
  size?: number;
  projectType?: ProjectType;
  defaultRate?: FetchParams;
};

export function ShareLinkPopper(props: Props) {
  return (
    <PopupState
      variant="popper"
      popupId="share-link-popper">
      {popupState => (
        <div>
          <div
            className={styles.trigger}
            {...bindToggle(popupState)}>
            <Share size={props.size ?? 24} />
          </div>
          <Popper
            {...bindPopper(popupState)}
            placement={props.placement ?? 'bottom-end'}
            className={styles.popper}>
            <ClickAwayListener
              onClickAway={popupState.close}>
              <div>
                <ShareLinkBody
                  defaultRate={props.defaultRate}
                  cacheKey={props.cacheKey}
                  onClose={popupState.close}
                  fetchToken={props.fetchToken}
                  projectType={props.projectType}
                  resetToken={props.resetToken} />
              </div>
            </ClickAwayListener>
          </Popper>
        </div>
      )}
    </PopupState>
  );
}

type BodyProps = {
  cacheKey: Props['cacheKey'];
  onClose: () => void;
  fetchToken: Props['fetchToken'];
  resetToken?: Props['resetToken'];
  projectType?: ProjectType;
  defaultRate?: FetchParams;
};

export const ShareLinkBody = forwardRef(({ cacheKey, onClose, fetchToken, resetToken, projectType, defaultRate = {} }: BodyProps, ref: Ref<HTMLDivElement>) => {
  const input = useRef<HTMLInputElement>();
  const [linkRate, setLinkRate] = useState<FetchParams>(defaultRate);

  const query = useQuery(['admin-share-link', ...cacheKey, linkRate], () => fetchToken(linkRate), {
    placeholderData: {
      token: {
        value: null,
      },
      url: '',
    },
    keepPreviousData: false,
    staleTime: 5000,
  });

  const handleCopyLink = useCallback(async () => {
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(input.current.value);
      } catch (e) {
        input.current.select();
        document.execCommand('copy');
      }
      input.current.blur();
    }
    else if (document.execCommand) {
      input.current.select();
      document.execCommand('copy');
      input.current.blur();
    }
    onClose();
  }, [onClose]);

  const handleReset = useCallback(() => {
    resetToken(query.data.token.value)
      .then(() => query.refetch());
  }, [query, resetToken]);

  const changeRate = useCallback((rate: number) => {
    setLinkRate(v => ({
      ...v,
      [projectType === ProjectType.Survey ? 'surveyCharge' : 'hourlyRate']: rate,
    }));
  }, [setLinkRate, projectType]);

  const debouncedChangeRate = useDebounceCallback(changeRate, 250);

  return (
    <Fragment>
      {projectType &&
        <div className={styles.main}>
          <div className={styles.label}>
            Payment
          </div>
          <div>
            <MoneyInput
              defaultValue={linkRate?.hourlyRate ?? linkRate?.surveyCharge}
              formatterProps={{
                placeholder: 'Amount',
              }}
              onValueChange={debouncedChangeRate} />
          </div>
        </div>
      }
      <div className={styles.main}>
        <div className={styles.label}>
          Share
        </div>
        <div className={styles.url}>
          <input
            className={styles.textbox}
            readOnly
            ref={input}
            type="text"
            value={query.data.url ?? '-'} />
        </div>
      </div>
      <div className={styles.btns}>
        <button />
        <button
          disabled={!query.data.token || !resetToken}
          className={styles.reset}
          onClick={handleReset}>
          Reset
        </button>
        <button
          disabled={query.isFetching || query.isLoading || !query.data?.url}
          className={styles.copy}
          onClick={handleCopyLink}>
          Copy Link
        </button>
      </div>
    </Fragment>
  );
});