import { useContext } from 'react';
import { ArticleFormContext } from '@containers/Post.Article.Form/Context';
import styles from './style/Form.Title.css';

type Props = unknown;

export const Title = (props: Props) => {
  const { setTitle, title } = useContext(ArticleFormContext);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <input
            className={styles.input}
            onChange={e => setTitle(e.target.value)}
            placeholder="Title"
            type="text"
            value={title} />
        </div>
      </div>
    </div>
  );
};

Title.displayName = 'Post.Article.New.Form.Title';