import { ButtonOutlinedLabeledIcon } from '@presentation/ButtonOutlined.LabeledIcon';
import { cx } from '@utils';
import { Facebook as FacebookIcon } from '@/components/icons';
import styles from './style/AuthButton.css';

type Props = {
  children?:  React.ReactNode;
  className?: string;
  onClick?:   () => unknown;
};

export const Facebook = (props: Props) => {
  return (
    <ButtonOutlinedLabeledIcon
      className={cx(styles.btn, props.className)}
      divider
      icon={<FacebookIcon size={26} />}
      onClick={props.onClick}>
      {props.children}
    </ButtonOutlinedLabeledIcon>
  );
};

Facebook.displayName = 'AuthButton.Facebook';