import { useCallback, useMemo } from 'react';
import { TabViewContext } from './Context';
import TabBar from './TabBar';
import type { TabViewProps } from './interfaces';

type Props<T = unknown> =
  TabViewProps<T>;

// eslint-disable-next-line comma-spacing
export const TabView = <T,>({ classes = {}, index, onIndexChange, routes, ...props }: Props<T>) => {
  const jumpTo = useCallback((key: Props<T>['routes'][number]['key']) => {

    const next = routes.reduce((acc, x, i) => x.key === key ? i : acc, 0);

    onIndexChange(next);

  }, [onIndexChange, routes]);

  const route = useMemo(() => routes[index], [routes, index]);

  const ctx = useMemo(() => ({
    index,
    jumpTo,
    route,
    routes,
  }), [
    index,
    jumpTo,
    route,
    routes,
  ]);

  return (
    <TabViewContext.Provider value={ctx}>
      <TabBar
        classes={{
          root: classes.bar,
          tab: classes.tab,
          tabs: classes.tabs,
        }}
        index={index}
        onClick={onIndexChange}
        routes={routes} />
      {props.renderTabView({ route, jumpTo })}
    </TabViewContext.Provider>
  );
};