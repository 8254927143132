import { useCallback } from 'react';
import { GrayOutlineButton } from '@presentation/Buttons';
import { useSubmitSurveyDraft } from '@/containers/SurveyBuilder';
import { Button } from '@/components/Button';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import ModalHeader from '@/components/Modal/Header';
import { useModal } from '@/components/Modal/hooks';
import { useCanSaveOtherAttributes, useTemplateOtherAttributes } from './hooks/other-attributes';
import { TemplateOtherAttributesInput } from './Input.OtherAttributes';
import styles from './style/Modal.css';

type Props =
  Pick<ModalProps,
    'open' |
    'onClose'>;

export const TemplateOtherAttributesModal = ({
  onClose,
  open,
}: Props) => {

  const {
    onSubmit,
    ...inputProps
  } = useTemplateOtherAttributes();

  const submitDraft = useSubmitSurveyDraft();

  const handleSubmit = useCallback(() => {

    onSubmit();
    submitDraft();
    onClose();
  }, [
    onSubmit,
    onClose,
    submitDraft,
  ]);

  const canSubmit = useCanSaveOtherAttributes(inputProps.otherAttributes);

  return (
    <Modal
      open={open}
      onClose={onClose}>

      <ModalHeader text="Update Other Attributes" />

      <div className={styles.body}>
        <TemplateOtherAttributesInput
          {...inputProps} />
      </div>

      <div className={styles.footer}>
        <GrayOutlineButton
          onClick={onClose}
          title="Cancel" />
        <Button.Primary
          className={styles.submit}
          disabled={!canSubmit}
          onClick={handleSubmit}
          title="Submit"
          variant="brick" />
      </div>
    </Modal>
  );
};

export const useTemplateOtherAttributesModal = () => useModal(TemplateOtherAttributesModal);

export default TemplateOtherAttributesModal;