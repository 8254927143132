import { useCallback, useMemo } from 'react';
import type { NumberFormatValues } from 'react-number-format';
import type { FormState } from '@containers/MedicalOnboarding/interfaces.practice';
import * as surveyForm from '@containers/SurveyForm/utils';
import { cx, useDebounceCallback } from '@utils';
import type { MatrixSliderQuestion } from '@/types';
import { SurveyQuestionNumberType } from '@/types';
import { NumberInput } from '@/components/Input';
import { MatrixSlider as SurveyMatrixSlider } from '@/components/SurveyMatrixSliders/MatrixSlider';
import { SurveyMatrixSliderHeader } from '@/components/SurveyMatrixSliders/Header';
import useSliderTextAnswers from '@/components/SurveyMatrixSliders/hooks/useSliderTextAnswers';
import useSliderValidation from '@/components/SurveyMatrixSliders/hooks/useSliderValidation';
import styles from './style/MatrixSlider.css';

type Props = {
  onChange: (value: OnChangeValue) => unknown;
} & Pick<FormState['practiceTime'],
  | 'answer'
  | 'question'>;

export const MatrixSlider = ({ answer, onChange, question, ...props }: Props) => {

  const [textAnswers, setTextAnswers] = useSliderTextAnswers(answer);
  /* @ts-ignore */
  const validationMessage = useSliderValidation(answer, { ...question, settings: Settings });

  const setAnswer = useCallback((value: OnChangeValue) => {
    onChange(value);
  }, [onChange]);

  const updateRowAnswer = useCallback((rowId: number) => (value: number) => {
    const existingItems = answer?.items || [];
    if (!existingItems.some(s => s.matrixRowId === rowId)) {
      const items = [...existingItems, { matrixRowId: rowId, value }];

      setAnswer({ items });
    } else {
      const items = existingItems.reduce((acc, x) => {
        return [
          ...acc,
          {
            matrixRowId: x.matrixRowId,
            value: x.matrixRowId === rowId ? value : x.value,
          },
        ];
      }, [] as MatrixSliderQuestion.RespondentAnswer.Item[]);

      setAnswer({ items });
    }
  }, [setAnswer, answer?.items]);

  const runningTotal = useMemo(() => {
    return surveyForm.matrixSlider.getRunningTotal(answer);
  }, [answer]);

  const renderHeader = useCallback(() => {
    if (Settings.slider.hideSlider) return null;

    return (
      <div className={styles.header}>
        <div className={styles.label} />
        <div className={styles.value} />
        <SurveyMatrixSliderHeader
          className={styles.headerContent}
          settings={Settings} />
      </div>
    );
  }, []);

  const updateSliderFromInput = useDebounceCallback(useCallback((rowId: number, value: number) => {
    updateRowAnswer(rowId)(value);
  }, [updateRowAnswer]), 100);

  const handleInputChange = useCallback((rowId: number) => (value: NumberFormatValues) => {
    setTextAnswers(prev => ({ ...prev, [rowId]: value.value }));

    const casted = parseFloat(value.value.length ? value.value : `${getMidValue(Settings.slider)}`);
    if (!isNaN(casted)) {
      const validated = validateSliderValue(casted, Settings.slider);
      updateSliderFromInput(rowId, validated);
    }
  }, [
    setTextAnswers,
    updateSliderFromInput,
  ]);

  const handleBlur = useCallback((rowId: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (!value.length && rowId in textAnswers) {
      const mid = getMidValue(Settings.slider);
      updateRowAnswer(rowId)(mid);
      setTextAnswers(prev => ({ ...prev, [rowId]: `${mid}` }));
    } else if (value.length) {
      const rowAnswer = answer.items.find(a => a.matrixRowId === rowId);

      setTextAnswers(prev => ({ ...prev, [rowId]: `${rowAnswer?.value ?? ''}` }));
    }
  }, [
    answer.items,
    setTextAnswers,
    textAnswers,
    updateRowAnswer,
  ]);

  const handlePositionChange = useCallback((rowId: number) => (position: number) => {
    setTextAnswers(prev => ({ ...prev, [rowId]: `${position ?? ''}` }));
    updateRowAnswer(rowId)(position);
  }, [
    setTextAnswers,
    updateRowAnswer,
  ]);

  const renderRow = useCallback((row: { id: number; value: number; ordinal: number }) => {
    const item = answer.items.find(f => f.matrixRowId === row.id);

    return (
      <div
        key={row.ordinal}
        className={cx(styles.row, {
          [styles.noSlider]: Settings.slider.hideSlider,
        })}>
        <div className={styles.label}>
          {`${row.value}`}
        </div>
        <div className={styles.value}>
          <NumberInput
            autoComplete="off"
            placeholder="-"
            value={textAnswers[row.id] ?? ''}
            thousandSeparator={true}
            onValueChange={handleInputChange(row.id)}
            onBlur={handleBlur(row.id)} />
        </div>
        {!Settings.slider.hideSlider &&
          <SurveyMatrixSlider
            className={styles.content}
            incrementBy={Settings.slider.increment}
            maxValue={Settings.slider.maxValue}
            minValue={Settings.slider.minValue}
            onPositionChange={handlePositionChange(row.id)}
            value={item?.value} />}
      </div>
    );
  }, [
    answer.items,
    handleBlur,
    handleInputChange,
    handlePositionChange,
    textAnswers,
  ]);

  return (
    <>
      {renderHeader()}
      {question.matrixRows.map(renderRow)}
      {Settings.slider.ensureAnswerTotalEqualsMax &&
        <div className={styles.totalRow}>
          <div className={styles.label}>Total</div>
          <div className={styles.total}>{runningTotal}%</div>
        </div>}
      {validationMessage &&
        <div className={styles.validation}>{validationMessage}</div>}
    </>
  );
};

MatrixSlider.displayName = 'MedicalProfile.MatrixSlider';

const Settings: MatrixSliderQuestion.Settings<number> = {
  slider: {
    displayPctOfTotal: false,
    label: null,
    maxValue: 100,
    minValue: 0,
    increment: 10,
    hideSlider: false,
    ensureAnswerTotalEqualsMax: false,
    total: {
      comparate: 100,
      operator: '=',
    },
  },
  orderRowsBasedOnSource: false,
  randomization: false,
  rationale: {
    enabled: false,
    minimum: 75,
  },
  numberSettings: {
    type: SurveyQuestionNumberType.Number,
  },
};

function getMidValue(settings: MatrixSliderQuestion.SliderSettings<number>) {
  const increments = Math.abs((settings.maxValue - settings.minValue) / settings.increment);
  return settings.minValue + (settings.increment * Math.ceil(increments / 2));
}

function validateSliderValue(value: number, settings: MatrixSliderQuestion.SliderSettings<number>) {
  return settings.minValue > settings.maxValue
    ? +Math.max(settings.maxValue, Math.min(settings.minValue, value)).toFixed(2)
    : +Math.min(settings.maxValue, Math.max(settings.minValue, value)).toFixed(2);
}

export type OnChangeValue = {
  items: {
    matrixRowId: number;
    value: number;
  }[];
};