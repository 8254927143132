import * as consts from '@consts';
import { LinkLabeledIcon } from '$website/components';
import styles from './style/Opportunities.css';

type Props = unknown;

export const Opportunities = (props: Props) => {

  return (
    <div
      className={styles.root}
      id="opportunities">
      <div className={styles.wrap}>
        <h2 className={styles.header}>Opportunities</h2>
        <div className={styles.subtitle}>{`We're looking for talented people to join our team across a number of roles.`}</div>
        <div className={styles.main}>
          <div className={styles.items}>
            {items.map((x, i) =>
              <div
                className={styles.item}
                key={`${x.text}-${i}`}>
                <div className={styles.info}>
                  <div className={styles.title}>{x.text}</div>
                  <LinkLabeledIcon
                    classes={{ root: styles.text }}
                    href={`mailto:${consts.vancery.email}?subject=Job Applicant For ${x.text}`}>
                    Apply Now
                  </LinkLabeledIcon>
                </div>
              </div>)}
          </div>
          <div className={styles.con} />
        </div>
      </div>
    </div>
  );
};

Opportunities.displayName = 'Career.Opportunities';

const items = [{
  text: `Product Manager`,
}, {
  text: `Sales Executive`,
}, {
  text: `Content Writer`,
}, {
  text: `UI/UX Designer`,
}, {
  text: `Visual Designer`,
}, {
  text: `Growth Marketing`,
}, {
  text: `Project Manager`,
}, {
  text: `Software Engineer`,
}, {
  text: `Product Marketing`,
}, {
  text: `Product Data Scientist`,
}, {
  text: `Product Data Scientist`,
}, {
  text: `Community Marketing`,
}, {
  text: `Sales Executive`,
}, {
  text: `Name Your Role`,
}];