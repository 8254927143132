import styles from '@screens/Workspace.File/style/Tab.css';
import { FilePreview } from '@/components/Workspace.File.Preview';

export const ProjectFileTabPreview = () => {
  return (
    <div className={styles.content}>
      <div className={styles.col}>
        <FilePreview />
      </div>
    </div>
  );
};