import { useCallback, useMemo } from 'react';
import { toTitleCase } from '@utils';
import { DropDown } from '@/components/DropDown';

type DropdownItem = {
  id: Value;
};

type Value = 'options' | 'rows';

type Props = {
  disabled?: boolean;
  items: Value[];
  onSelect: (v: Value) => void;
  value: Value;
};

function formatDisplay(value: Value) {
  return value
    ? toTitleCase(value)
    : '';
}

export const QuestionValuesSourceDropdown = ({
  disabled,
  items,
  onSelect,
  value,
}: Props) => {

  const getItemValue = useCallback((item: DropdownItem) => {
    return formatDisplay(item.id);
  }, []);

  const handleSelect = useCallback((item: DropdownItem) => {
    onSelect(item.id);
  }, [onSelect]);

  const mappedItems = useMemo(() => {
    return items
      .map(m => ({
        id: m,
      }));
  }, [items]);

  const text = useMemo(() => {
    return formatDisplay(value);
  }, [value]);

  return (
    <DropDown<DropdownItem>
      disabled={disabled}
      items={mappedItems}
      getItemValue={getItemValue}
      onSelect={handleSelect}
      text={text} />
  );
};

export default QuestionValuesSourceDropdown;