import http from '@services/http';
import { qs } from '@utils';
import type * as Projects from './interfaces/projects.external-survey';

export const getExternalLink = (data: Projects.GetExternalLink.Request): Promise<Projects.GetExternalLink.Response> => {
  return http.patch(`/projects/${data.projectId}/get-external-link`, {});
};

export const completeProject = (data: Projects.CompleteExternalProject.Request): Promise<Projects.CompleteExternalProject.Response> => {
  return http.post(`/projects/${data.projectId}/external-complete`, data);
};

export const importExternalProjectLinks = async (projectId: number, linksFile: File, fixed?: boolean): Promise<{ numInserted: number }> => {
  const formData = new FormData();
  formData.append('linksFile', linksFile, linksFile.name);

  const q = fixed ? `?${qs.stringify({ fixed })}` : '';

  return http.patch(`/projects/${projectId}/import-external-links${q}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const importSingleExternalProjectLink = async ({ projectId, ...data }: Projects.ImportSingleExternalProjectLink.Request) => {
  return http.put<Projects.ImportSingleExternalProjectLink.Response>(`/projects/${projectId}/import-single-external-link`, data);
};

export const deleteUnassignedExternalProjectLinks = async ({ projectId }: Projects.DeleteUnassignedExternalProjectLinks.Request) => {
  return http.delete(`/projects/${projectId}/unassigned-external-links`);
};

export const bootstrapExternalProject = async ({ projectId }: Projects.BootstrapExternalProject.Request) => {
  return http.post<Projects.BootstrapExternalProject.Response>(`/projects/${projectId}/bootstrap-external-project`, {});
};

export const deleteExternalProjectConfiguration = async ({ projectId }: Projects.DeleteExternalSurveyConfiguration.Request) => {
  return http.delete(`/projects/${projectId}/external-configuration`);
};

export const downloadExternalProjectLinks = (data: IProjectId) => {
  return http.download(`/projects/${data.projectId}/download-external-links`);
};

export const updateExternalProjectConfiguration = async (data: Projects.UpdateExternalSurveyConfiguration.Request) => {
  return http.patch<Projects.UpdateExternalSurveyConfiguration.Response>(`/projects/${data.projectId}/external-configuration`, data);
};