import extend from '@enums/extend';

export enum CallStatus {
  Scheduled      = 1,
  Completed      = 2,
  Rejected       = 3,
  Pending        = 4,
  Rescheduling   = 5,
  Cancelled      = 6,
  MissedByClient = 7,
}

const map = new Map([
  [CallStatus.Scheduled,      { name: 'Scheduled'    }],
  [CallStatus.Completed,      { name: 'Completed'    }],
  [CallStatus.Rejected,       { name: 'Rejected'     }],
  [CallStatus.Pending,        { name: 'Pending'      }],
  [CallStatus.Rescheduling,   { name: 'Rescheduling' }],
  [CallStatus.Cancelled,      { name: 'Cancelled'    }],
  [CallStatus.MissedByClient, { name: 'Missed'       }],
]);

export default {
  CallStatus: extend(map),
};