import { useMemo } from 'react';
import { body } from '@containers/PostCreation/utils';
import { useTextPostStateContext, useVideoPostStateContext } from './usePostCreationContext';

export const useTextPostCanPublish = () => {
  const [state] = useTextPostStateContext();

  const canPublish = useMemo(() => {
    return body.isValid(state.form.body);
  }, [state.form.body]);

  return canPublish;
};

export const useVideoPostCanPublish = () => {
  const [state] = useVideoPostStateContext();

  const canPublish = useMemo(() => {
    return !body.isEmpty(state.description)
        && !!state.video
        && !!state.title?.trim?.()?.length;
  }, [
    state.description,
    state.title,
    state.video,
  ]);

  return canPublish;
};