import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { addMinutes, format } from 'date-fns';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AvailableTimeslot } from '@containers/Calendar/interfaces';
import { ConfirmationStepperContext } from '@containers/Calendar/Context';
import { dateToEventsMapKey } from '@containers/Calendar/mapping';
import { useProposeTimesLocation } from '@containers/Scheduler/hooks';
import { Button } from '@/components/Button';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import { Timeslot } from './Timeslot';
import styles from './style/SelectingTime.css';

type Props = unknown;

const SelectingTime = (props: Props) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [timeslot, setTimeslot] = useState<AvailableTimeslot>(null);
  const ctx = useContext(ConfirmationStepperContext);
  const title = format(ctx.day, 'EEEE');
  const fulldate = format(ctx.day, 'MMMM d, y');
  const key = dateToEventsMapKey(ctx.day);

  function goBack() {
    ctx.back();
    ctx.setDay(null);
  }

  function handleSubmit() {
    setSubmitting(true);

    ctx.onSubmit({
      start: timeslot.start,
      end: addMinutes(timeslot.start, 60),
    })
    .then(() => ctx.next());
  }

  function isSelected(item: AvailableTimeslot) {
    if (timeslot?.start) {
      return new Date(timeslot.start).toISOString() === new Date(item.start).toISOString();
    }

    return false;
  }

  function ConfirmButton() {
    return timeslot && (
      <div className={styles.confirm}>
        <Button
          className={styles.btn}
          disabled={isSubmitting}
          color="affirmative"
          variant="brick"
          onClick={handleSubmit}>
          {isSubmitting
            ? <ActivityIndicator
                show
                options={{ color: `var(--pri-01)` }} />
            : 'Confirm'}
        </Button>
      </div>
    );
  }

  const location = useProposeTimesLocation({
    callId: ctx.callId,
  });

  const items = ctx.events[key];

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.header}>
            <button
              className={styles.back}
              onClick={goBack}>
              <ArrowBackIcon className={styles.arrow} />
            </button>
          </div>

          <div className={styles.info}>
            <div className={styles.subheader}>
              <div className={styles.h2}>Select a Time</div>

              <Link to={location}>
                <button className={styles.propose}>
                  Propose Alternate Times
                </button>
              </Link>
            </div>

            <div className={styles.section}>
              <div className={styles.h2}>{title}</div>
              <div className={styles.day}>{fulldate}</div>
            </div>
          </div>

          <div className={styles.content}>

            <div className={styles.scroll}>
              {items.map(x =>
                <Timeslot
                  item={x}
                  key={String(x.start)}
                  onSelect={setTimeslot}
                  selected={isSelected(x)} />)}
            </div>

            <ConfirmButton />

          </div>
        </div>
      </div>
    </div>
  );
};

export { SelectingTime };
export default SelectingTime;