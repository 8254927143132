import { useCallback, useMemo } from 'react';
import * as surveyBuilder from '@containers/SurveyBuilder/utils';
import { useCanCreateFindAndReplace } from '@/containers/SurveyBuilder/hooks/useCanCreateFindAndReplace';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks/useSurveyBuilderContext';
import { QuestionOptionsBuilderContainer } from '@containers/SurveyBuilder.Question/OptionsBuilderContainer';
import { QuestionRowsBuilderContainer } from '@containers/SurveyBuilder.Question/RowsBuilderContainer';
import { assertValidSettings } from '@containers/SurveyBuilder/utils/question.number-input-table';
import { QuestionSettingTitle, SettingsDivider } from '@presentation/SurveyBuilder';
import type { NumberInputTableQuestion, SurveySettings } from '@/types';
import { CheckboxLabel } from '@/components/Checkbox';
import { NumberInput } from '@/components/Input';
import { QuestionColumnsBuilder } from './OptionsBuilder';
import { ResponsePiping } from './ResponsePiping';
import { Warning } from './Warning';
import { QuestionRowsBuilder } from './RowsBuilder';
import NumberSettingsBuilder from './NumberSettingsBuilder';
import styles from './style/NumberInputTable.Question.css';
import { TotalsPopper } from './Settings.Total.Popper';
import { RandomizeOptionsCheckbox, RandomizeRowsCheckbox } from './Settings.Randomize';
import { NumberInputTableRowContextMenu } from './NumberInputTable.Row.ContextMenu';
import { NumberInputTableOptionContextMenu } from './NumberInputTable.Option.ContextMenu';
import { ReuseQuestionRows } from './ReuseQuestionValues';
import { useHasLinkedRows } from './hooks';
import { OrderRowsBasedOnSource } from './Settings.ReuseRowsOrder';
import { FindAndReplaceOptions, FindAndReplaceRows } from './FindAndReplace';
import { RationaleCheckbox } from './Settings.Rationale';
import { LanguageEditingBarrier } from './LanguageEditingBarrier';

type Props = {
  item: NumberInputTableQuestion.Question;
};

export const NumberInputTableBuilder = ({ item }: Props) => {
  const [_, dispatch] = useSurveyBuilderState();

  const toggleEmptyValues = useCallback((_, checked: boolean) => {
    dispatch({
      type: 'toggle-allow-empty-values',
      questionIdentifier: item.base.identifier,
      value: checked,
    });
  }, [
    dispatch,
    item.base.identifier,
  ]);

  const updateSettings = useCallback((settings: Partial<NumberInputTableQuestion.Settings>) => {
    dispatch({
      questionIdentifier: item.base.identifier,
      settings: {
        ...item.settings,
        ...settings,
      },
      type: 'update-question-settings',
    });
  }, [
    dispatch,
    item.base.identifier,
    item.settings,
  ]);

  const toggleDisplayTotals = useCallback((_, checked: boolean) => {
    updateSettings({
      displayTotals: checked,
    });
  }, [updateSettings]);

  const handleSettingsChange = useCallback((field: 'minValue' | 'maxValue') => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    updateSettings({
      validations: {
        ...item.settings.validations,
        [field]: value === '' ? null : +value,
      },
    });

  }, [
    item.settings.validations,
    updateSettings,
  ]);

  const handleTotalChange = useCallback((value: SurveySettings.TotalSetting) => {
    updateSettings({
      total: value,
    });
  }, [updateSettings]);

  const handleToggleDisplayPct = useCallback((_, checked: boolean) => {
    updateSettings({
      displayCellPct: checked,
    });
  }, [updateSettings]);

  const handleToggleDropdowns = useCallback((_, checked: boolean) => {
    updateSettings({
      dropdowns: {
        enabled: checked,
        increment: checked ? 1 : null,
      },
    });
  }, [updateSettings]);

  const handleDropdownIncrementChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    updateSettings({
      dropdowns: {
        enabled: true,
        increment: +e.target.value,
      },
    });
  }, [updateSettings]);

  const settingsValidation = useMemo(() => {
    return assertValidSettings(item);
  }, [item]);

  const hasLinkedRows = useHasLinkedRows(item);
  const canFar = useCanCreateFindAndReplace();

  return (
    <>
      <QuestionSettingTitle title="Rows">
        <ResponsePiping type="rows" />
        <ReuseQuestionRows />
        {canFar ? <FindAndReplaceRows question={item} /> : null}
      </QuestionSettingTitle>

      <QuestionRowsBuilderContainer
        maxRows={surveyBuilder.numberInputTable.MaxRows}
        minRows={surveyBuilder.numberInputTable.MinRows}>
        <QuestionRowsBuilder
          className={styles.rows}
          renderAction={row => (
            <NumberInputTableRowContextMenu
              question={item}
              row={row as NumberInputTableQuestion.Row} />
          )} />
      </QuestionRowsBuilderContainer>

      <RandomizeRowsCheckbox
        disabled={item.settings.orderRowsBasedOnSource}
        className={styles.checkbox} />

      {hasLinkedRows &&
        <OrderRowsBasedOnSource
          disabled={item.settings.randomizeRows}
          className={styles.checkbox} />
      }

      <QuestionSettingTitle title="Columns">
        <ResponsePiping type="options" />
        {canFar ? <FindAndReplaceOptions question={item} /> : null}
      </QuestionSettingTitle>
      <QuestionOptionsBuilderContainer
        maxOptions={surveyBuilder.numberInputTable.MaxColumns}
        minOptions={surveyBuilder.numberInputTable.MinColumns}>
        <QuestionColumnsBuilder
          className={styles.options}
          renderAction={option => (
            <NumberInputTableOptionContextMenu
              question={item}
              option={option as NumberInputTableQuestion.Option} />
          )} />
      </QuestionOptionsBuilderContainer>

      <LanguageEditingBarrier>
        <RandomizeOptionsCheckbox
          label="Randomize Columns" />

        <SettingsDivider />

        <div className={styles.checkbox}>
          <CheckboxLabel
            checked={item.settings.allowEmptyValues}
            label="Allow empty values"
            onChange={toggleEmptyValues} />
        </div>
        <CheckboxLabel
          className={styles.checkbox}
          checked={item.settings.displayTotals}
          label="Display column totals"
          onChange={toggleDisplayTotals} />

        <div className={styles.checkbox}>
          <CheckboxLabel
            checked={item.settings.displayCellPct}
            label="Display cell percentages"
            onChange={handleToggleDisplayPct} />
        </div>

        <div className={styles.checkbox}>
          <CheckboxLabel
            checked={item.settings.dropdowns.enabled}
            label="Dropdown inputs"
            onChange={handleToggleDropdowns} />
        </div>

        {item.settings.dropdowns.enabled &&
          <div className={styles.item}>
            <div className={styles.title}>Increment</div>
            <div className={styles.input}>
              <NumberInput
                allowLeadingZeros={false}
                allowNegative={false}
                onChange={handleDropdownIncrementChange}
                value={item.settings.dropdowns.increment} />
            </div>
          </div>
        }

        <div className={styles.item}>
          <div className={styles.title}>Minimum Value</div>
          <div className={styles.input}>
            <NumberInput
              allowLeadingZeros={false}
              onChange={handleSettingsChange('minValue')}
              value={item.settings.validations.minValue} />
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.title}>Maximum Value</div>
          <div className={styles.input}>
            <NumberInput
              allowLeadingZeros={false}
              onChange={handleSettingsChange('maxValue')}
              value={item.settings.validations.maxValue} />
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.title}>Required Sum</div>
          <TotalsPopper
            onChange={handleTotalChange}
            value={item.settings.total} />
        </div>

        <NumberSettingsBuilder item={item} />

        <RationaleCheckbox className={styles.checkbox} />

      </LanguageEditingBarrier>

      <Warning
        className={styles.warning}
        result={settingsValidation} />
    </>
  );
};

export default NumberInputTableBuilder;