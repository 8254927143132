import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import * as $api from '@api/workspaces.file';
import Toast from '@/components/Toast';
import { useInvalidateFilePreviewQueries, useInvalidateFilesQueries } from '@utils/api';
import { getLocationFor } from '@utils';
import { TranscribeFileContext, ImportTranscriptContext, ViewFileTranscriptContext } from './Context';

type Props =
  IWorkspaceId &
  IWorkspaceFileId &
  ITranscriptId &
  ChildrenProps;

export const TranscribeFileContainer = (props: Props) => {

  const history = useHistory();
  const invalidateFilePreviewQueries = useInvalidateFilePreviewQueries();
  const invalidateFilesQueries = useInvalidateFilesQueries();

  const transcribeMutation = useMutation({
    mutationKey: ['file.transcribe'],
    mutationFn: () => {
      return $api.transcribeFile({
        fileId: props.fileId,
        workspaceId: props.workspaceId,
      });
    },
    onSuccess: () => {
      Toast.alert({
        title: 'File transcription started',
      });
      invalidateFilePreviewQueries(props);
      invalidateFilesQueries();
    },
    onError: () => {
      Toast.error({
        title: 'Failed to start file transcription',
      });
    },
  });

  const importMutation = useMutation({
    mutationFn: () => {
      return $api.parseIntoTranscript({
        fileId: props.fileId,
        workspaceId: props.workspaceId,
      });
    },
    onSuccess: () => {
      Toast.alert({
        title: 'Transcript import started',
      });
      invalidateFilePreviewQueries(props);
      invalidateFilesQueries();
    },
    onError: () => {
      Toast.error({
        title: 'Failed to start transcript import',
      });
    },
  });

  const viewTranscript = useCallback(() => {
    const location = getLocationFor.workspaces.transcript({
      transcriptId: props.transcriptId,
    });
    history.push(location);
  }, [history, props.transcriptId]);

  return (
    <TranscribeFileContext.Provider value={transcribeMutation.mutateAsync}>
      <ImportTranscriptContext.Provider value={importMutation.mutateAsync}>
        <ViewFileTranscriptContext.Provider value={viewTranscript}>
          {props.children}
        </ViewFileTranscriptContext.Provider>
      </ImportTranscriptContext.Provider>
    </TranscribeFileContext.Provider>
  );
};