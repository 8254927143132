import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { pathname } from '@consts';
import { getLocationFor } from '@utils';
import * as $api from '@api/users';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import { Button } from '@/components/Button';
import styles from './styles.css';

export const ScreenerOnboarding = () => {
  const history = useHistory();
  const { isLoading, data } = useQuery(['get-invited-screeners'], () => {
    return $api.onboarding.getInvitedScreeners();
  });

  const goToProject = useCallback(() => {
    history.push(getLocationFor.onboarding.project(data[0]));
  }, [history, data]);

  if (isLoading) {
    return <ActivityIndicator />;
  }

  if (!data.length) {
    history.push(pathname.Home);
    return <ActivityIndicator />;
  }

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        Want to improve your chances of being selected for research projects that are interesting and relevant to you?
      </div>
      <div className={styles.details}>
        <p>
          Complete a couple of questions around your professional area of
          expertise to boost your odds of being selected for future projects.
        </p>
        <p>
          Click below to get started!
        </p>
      </div>
      <Button
        variant='brick'
        color='primary'
        onClick={goToProject}>
        Get Started
      </Button>
    </div>
  );
};