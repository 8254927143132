import type { NumericFormatProps, OnValueChange } from 'react-number-format';
import { NumericFormat } from 'react-number-format';
import { cx } from '@utils';
import { Input } from './Input';
import type { InputProps } from './interfaces';
import styles from './styles/style.css';

type Props =
  Pick<InputProps,
    'focusableClasses' |
    'classes' |
    'onBlur' |
    'onFocus' |
    'readOnly'>
  & Omit<NumericFormatProps, 'className'> & { rootClassName?: string };

export const NumberInput = ({
  classes = {},
  rootClassName,
  ...props
}: Props) => {
  return (
    <div className={cx(styles.root, rootClassName, classes.root)}>
      <NumericFormat
        {...props}
        customInput={Input}
        className={cx(styles.numberInput, classes.input)} />
    </div>
  );
};

export type OnNumberInputValueChange = OnValueChange;

export default NumberInput;