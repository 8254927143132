import { useCallback, useContext, useState } from 'react';
import { MonologueDetailsEditSpeakerContext, TranscriptIdContext } from '@containers/Transcript/context';
import { useEditSpeakerSelectionMutation } from '@containers/Transcript/hooks';
import { ButtonOutlined } from '@presentation/ButtonOutlined';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { cx } from '@utils';
import { Select } from '$admin/components/Select';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import { Header } from '@/components/Modal/Header';
import { ReassignSpeakerSelectionContext } from './context';
import styles from './style/EditSpeakers.css';

type Props = {
  children: React.ReactNode;
};

export const ReassignSpeakerSelectionContainer = (props: Props) => {
  const [state, dispatch] = useState<Value>(null);
  const transcriptId = useContext(TranscriptIdContext);

  const mutation = useEditSpeakerSelectionMutation({
    onSuccess: () => dispatch(null),
    transcriptId,
  });

  const value = {
    setValue: dispatch,
    mutation,
    value: state,
  };

  return (
    <ReassignSpeakerSelectionContext.Provider value={value}>
      {props.children}
      {!!state &&
        <ReassignSpeakerSelectionModal
          open={!!state}
          onClose={() => dispatch(null)} />}
    </ReassignSpeakerSelectionContext.Provider>
  );
};

ReassignSpeakerSelectionContainer.displayName = 'ReassignSpeakerSelection.Container';

type ReassignSpeakerSelectionModalProps = Pick<ModalProps, 'onClose' | 'open'>;

const ReassignSpeakerSelectionModal = (props: ReassignSpeakerSelectionModalProps) => {
  const md = useContext(MonologueDetailsEditSpeakerContext);
  const { mutation, value } = useContext(ReassignSpeakerSelectionContext);
  const [speakerIndex, setSpeakerIndex] = useState(value?.speakerIndex);

  const handleSubmit = useCallback(() => {
    mutation.mutate({
      speakerIndex,
      start: value?.start,
      end: value?.end,
    });
  }, [
    mutation,
    value?.end,
    value?.start,
    speakerIndex,
  ]);

  return (
    <Modal
      disableEscapeClose={mutation.isLoading}
      disableOverlayClick={mutation.isLoading}
      hideCloseIcon
      open={props.open}
      onClose={props.onClose}>
      <div className={cx(styles.root, styles.snippet)}>
        <Header>Edit Speaker</Header>
        <div className={styles.wrap}>
          <div className={styles.main}>
            <Select
              className={styles.input}
              onChange={e => setSpeakerIndex(e.target.value)}
              options={md?.options || []}
              placeholder="Select speaker"
              value={String(speakerIndex)} />
          </div>
          <div className={styles.actions}>
            <ButtonOutlined
              className={styles.btn}
              color="silver"
              disabled={mutation.isLoading}
              onClick={props.onClose}
              size="small">
              Cancel
            </ButtonOutlined>

            <ButtonActivityIndicator
              className={styles.btn}
              disabled={speakerIndex === value.speakerIndex}
              loading={mutation.isLoading}
              implicitDisable={false}
              indicatorSize={10}
              onClick={handleSubmit}
              size="small"
              variant="brick">
              Save
            </ButtonActivityIndicator>
          </div>
        </div>
      </div>
    </Modal>
  );
};

type Value = {
  end:          number;
  speakerIndex: number;
  start:        number;
};