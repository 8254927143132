import extend from '@enums/extend';

export enum Permission {
  InternalAdminDashboard          = 3,
  DeveloperBuildInfo              = 4,
  InternalResearchDashboard       = 5,
  InternalAdminBillingDashboard   = 6,
  GroupBillingAdmin               = 7,
  InternalAdminBillingTeamLead    = 8,
  InternalResearchScheduling      = 9,
  InternalAdminProjectsExperts    = 10,
  InternalAdminProjectsLeads      = 11,
  InternalAdminAccountsDashboard  = 12,
  InternalAdminDirectoryDashboard = 13,
  InternalAdminExpertsDashboard   = 14,
  InternalAdminLeadsDashboard     = 15,
  InternalAdminRecruiterDashboard = 16,
  InternalAdminSearchDashboard    = 17,
  BetaMain                        = 18,
  CallTranscriptWrite             = 19,
  EvapAnalystUserProfile          = 20,
  BrandInsightsFullpage           = 21,
  BrandInsightsViewTraces         = 22,
  CallRequireBoosting             = 23,
}

const map = new Map([
  [Permission.InternalAdminDashboard,          { name: 'internal-admin:dashboard' }],
  [Permission.DeveloperBuildInfo,              { name: 'developer:build-info' }],
  [Permission.InternalResearchDashboard,       { name: 'internal-research:dashboard' }],
  [Permission.InternalAdminBillingDashboard,   { name: 'internal-admin:billing:dashboard' }],
  [Permission.GroupBillingAdmin,               { name: 'group:billing-admin' }],
  [Permission.InternalAdminBillingTeamLead,    { name: 'internal-admin:billing:team-lead' }],
  [Permission.InternalResearchScheduling,      { name: 'internal-research:scheduling' }],
  [Permission.InternalAdminProjectsExperts,    { name: 'internal-admin:projects:experts' }],
  [Permission.InternalAdminProjectsLeads,      { name: 'internal-admin:projects:leads ' }],
  [Permission.InternalAdminAccountsDashboard,  { name: 'internal-admin:accounts:dashboard' }],
  [Permission.InternalAdminDirectoryDashboard, { name: 'internal-admin:directory:dashboard' }],
  [Permission.InternalAdminExpertsDashboard,   { name: 'internal-admin:experts:dashboard' }],
  [Permission.InternalAdminLeadsDashboard,     { name: 'internal-admin:leads:dashboard' }],
  [Permission.InternalAdminRecruiterDashboard, { name: 'internal-admin:recruiter:dashboard' }],
  [Permission.InternalAdminSearchDashboard,    { name: 'internal-admin:search:dashboard' }],
  [Permission.BetaMain,                        { name: 'beta:main' }],
  [Permission.CallTranscriptWrite,             { name: 'call:transcript:write' }],
  [Permission.EvapAnalystUserProfile,          { name: 'evap-analyst:user-profile' }],
  [Permission.BrandInsightsFullpage,           { name: 'brand-insights:full-page' }],
  [Permission.BrandInsightsViewTraces,         { name: 'brand-insights:view-traces' }],
  [Permission.CallRequireBoosting,                 { name: 'call:require-boosting' }],
]);

export default {
  Permission: extend(map),
};