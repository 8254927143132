import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { addYears } from 'date-fns';
import * as consts from '@consts';
import { useSelectUser } from '@containers/Store';
import { cx } from '@utils';
import styles from './style.css';

const IgnorePaths = [
  consts.pathname.EMBEDDED,
  consts.pathname.Inbound,
];

export function CookieConsent() {
  const [dismissed, setDismissed] = useState<boolean>(true);
  const [visible, setVisible] = useState<boolean>(false);
  const location = useLocation();
  const user = useSelectUser();

  const getCookie = useCallback((name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length == 2) return parts.pop().split(';').shift();
  }, []);

  const handleDismiss = useCallback(() => {
    const expiry = addYears(new Date(), 1);

    document.cookie = `${consts.cookies.consent}=dismiss; expires=${expiry.toUTCString()}; path=/`;

    setVisible(false);

    setTimeout(() => {
      setDismissed(true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (IgnorePaths.some(l => location.pathname.startsWith(l))) return;

    if (!getCookie(consts.cookies.consent)) {
      setDismissed(false);
      setTimeout(() => {
        setVisible(true);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user.state.authenticated) {
      if (!getCookie(consts.cookies.consent)) {
        handleDismiss();
      }
    }
  }, [user.state.authenticated, getCookie, handleDismiss]);

  if (dismissed) return null;

  return (
    <div className={cx(styles.root, { [styles.show]: visible })}>
      <div className={styles.message}>
        This website uses cookies to enhance your site experience.
        <a
          aria-label="read our terms of use"
          className={styles.link}
          href="/terms"
          target="_blank"
          tabIndex={0}>Read our Terms of Use
        </a>
      </div>
      <div className={styles.compliance}>
        <button
          aria-label="dismiss cookie message"
          tabIndex={0}
          className={styles.dismiss}
          onClick={handleDismiss}
          type="button">Accept
        </button>
      </div>
    </div>
  );
}