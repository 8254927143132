import { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import * as API from '@api/interfaces';
import * as enums from '@enums';
import { RecordingConsent as RecordingConsentModal } from '@/components/Modal/RecordingConsent';
import { RecordingConsentProps } from './interfaces';
import * as utils from './utils';

const mapState = (state: Store.State) => ({
  isCallCreator: utils.isCallCreator(state),
  isRecordingConsentResolved: utils.isRecordingConsentResolved(state),
});

const mapDispatch = {
  updateCall: actions.callUpdated,
};

type Props =
    RecordingConsentProps
  & ReturnType<typeof mapState>
  & typeof mapDispatch;

type State = {
  prompt: boolean;
};

class RecordingConsent extends Component<Props, State> {
  state: State = {
    prompt: false,
  }

  componentDidMount() {
    if (this.shouldCheckConsentStatus()) {
      this.getRecordingStatus();
    }
  }

  getRecordingStatus = () => {
    return api.scheduling.getRecordingConsentState({
      projectId: this.props.projectId,
    })
    .then(this.handleInitialValue);
  }

  handleConsentChange = (recordingConsentStatusId: enums.ApprovalStatus) => {
    if (this.props.callId) this.updateExistingCall(recordingConsentStatusId);

    this.props.onChange(recordingConsentStatusId);

    this.setState({ prompt: false });
  }

  handleInitialValue = ({ recordingConsentStatusId }: API.Scheduling.Project.GetRecordingConsentState.Response) => {
    this.props.onChange(recordingConsentStatusId);

    const shouldPrompt = typeof this.props.onInitialValue === 'function'
      ? this.props.onInitialValue(recordingConsentStatusId)
      : utils.isRecordingConsentRequired(recordingConsentStatusId);

    this.setState({ prompt: shouldPrompt });
  }

  shouldCheckConsentStatus = () => {
    if (this.props.disabled) {
      return false;
    } else if (this.props.disabled === false) {
      return true;
    } else {
      return !this.props.callId
          || !this.props.isCallCreator(this.props.callId)
          && !this.props.isRecordingConsentResolved(this.props.callId);
    }
  }

  updateExistingCall = (recordingConsentStatusId: enums.ApprovalStatus) => {
    api.scheduling.updateRecordingStatus({
      callId: this.props.callId,
      recordingConsentStatusId,
    })
    .then(data => this.props.updateCall({ call: data.call }));
  }

  render() {
    return (
      <>
        {this.props.children}
        <RecordingConsentModal
          onSubmit={this.handleConsentChange}
          visible={this.state.prompt} />
      </>
    );
  }
}
const R = connect(mapState, mapDispatch)(RecordingConsent);
export default R;