import { memo } from 'react';

type Props = {
  className?: string;
  size?: number;
};

export const Lightbulb = memo(({ className, size = 24 }: Props) => {
  return (
    <svg
      className={className}
      width={size}
      height={size * 1.453125}
      viewBox="0 0 22 31"
      version="1.1">
      <g
        id="Launchbase3"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g
          id="5"
          transform="translate(-783.000000, -656.000000)"
          fill="#FFFFFF">
          <g id="Group-3" transform="translate(783.000000, 656.000000)">
            <g id="Group-2">
              <path d="M10.7593366,0 C16.781775,0 21.6639259,4.86044177 21.6639259,10.8561005 C21.6639259,14.5664411 18.7314905,18.1470275 17.9400104,19.1175602 C16.4875064,20.8986571 15.8911377,23.25 15.8911377,23.25 L15.8911377,23.25 L5.7681761,23.25 C5.7681761,23.25 4.99035694,20.9782535 4.29704766,19.9692649 C2.70149825,17.6472259 1.21379283e-12,14.8122382 1.21379283e-12,10.8561005 C1.21379283e-12,4.86044177 4.73689821,0 10.7593366,0 Z M9.36447552,3.55110293 C7.02817383,3.71552367 3.38461538,6.14984434 3.38461538,10.4056051 C3.38461538,10.7784792 3.53221615,11.3268673 4.39699312,11.3268673 C5.02799121,11.3268673 5.41781009,10.799707 5.40753274,10.1724064 C5.39516033,9.41722891 5.56255901,8.1622272 6.84251238,6.84774066 C8.0060316,5.65282967 9.2485559,5.60247091 10.0091916,5.534437 C10.7584308,5.46742244 11.1653021,5.35284074 11.1653021,4.49355411 C11.1653021,3.55110293 10.5243938,3.46947195 9.36447552,3.55110293 Z" id="Combined-Shape" />
              <path d="M11,30.9552201 C13.7104436,30.9552201 15.9076923,28.3302461 15.9076923,25.0921766 C15.9076923,25.0921766 6.09230769,25.0921766 6.09230769,25.0921766 C6.09230769,28.3302461 8.28955638,30.9552201 11,30.9552201 Z" id="Oval" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
});