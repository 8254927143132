import { useContext, useEffect } from 'react';
import { createNamedContext } from '@utils';

type ZendeskScriptContextValue = {
  shouldLoad: boolean;
  loadAsync: () => void;
  isLoaded: boolean;
  setLoaded: () => void;
};

export const ZendeskScriptContext = createNamedContext<ZendeskScriptContextValue>(null, 'ZendeskScriptContext');

export const useZendeskScript = () => {
  return useContext(ZendeskScriptContext);
};

export const useLoadZendeskScript = () => {
  const { loadAsync } = useContext(ZendeskScriptContext);

  useEffect(() => {
    if (process.env.ZENDESK_SCRIPT != 'true') return;
    loadAsync();
  }, [loadAsync]);
};