import { SidebarHeader } from '@presentation/Main';
import Add from './Header.Add';
import Editing from './Header.Editing';
import { useGroupTags } from './hooks';

export default function Header() {
  return (
    <SidebarHeader title="Tags">
      <Actions />
    </SidebarHeader>
  );
}

function Actions() {
  const ctx = useGroupTags();

  if (!ctx.editable) return null;

  return ctx.editing
    ? <Editing />
    : <Add />;
}

export { Header };