import { useCallback } from 'react';
import { RouteComponentProps, useHistory, useLocation, useParams } from 'react-router-dom';
import * as consts from '@consts';
import { Invitation, InvitationResponsive } from '@screens/Scheduling';
import { device, qs } from '@utils';
import { useProjectCallInvite } from '@utils/api';
import { CalendarState } from '@/types';
import { useNotifier } from './Notifier';

type RouteParams = {
  projectId: string;
};

type RouteState = {
  creatorId:   number;
  schedulerId: number;
  scheduleeId: number;
  userId:      number;
};

type Props =
  RouteComponentProps<
    RouteParams,
    ObjectLike,
    RouteState>;

const InvitationCalendar = (props: Props) => {
  const notifier = useNotifier();
  const params = useParams<RouteParams>();
  const { state } = useLocation<RouteState>();
  const history = useHistory();

  const { mutateAsync: createInvite } = useProjectCallInvite({
    creatorId: state.creatorId,
    projectId: +params.projectId,
    scheduleeId: state.scheduleeId,
    schedulerId: state.schedulerId,
    userId: state.userId,
  }, {
    onSuccess: res => {
      const from = qs.getParam('from');

      notifier.callRequestSent({
        projectId: +params.projectId,
        userId: state.userId,
      });

      history.replace(from || consts.pathname.HOME);
    },
    onError: notifier.error,
  });

  const handleSubmit = useCallback((data: CalendarState) => {
    return createInvite({
      new: data.new,
      removed: data.removed,
    });
  }, [
    createInvite,
  ]);

  const Screen = device.phone
      ? InvitationResponsive
      : Invitation;

  return (
    <Screen
      creatorId={state.creatorId}
      scheduleeId={state.scheduleeId}
      schedulerId={state.schedulerId}
      onSubmit={handleSubmit}
      userId={state.userId} />
  );
};

export { InvitationCalendar };
export default InvitationCalendar;