import { InfoTooltip } from '@presentation/InfoTooltip';
import { cx } from '@utils';
import styles from './style/Input.css';

type Props = {
  children?:  React.ReactNode;
  className?: string;
  classes?: {
    root?: string;
    text?: string;
  };
  required?:  boolean;
  tooltip?:   string;
};

export const Label = ({ classes = {}, ...props }: Props) => {
  return (
    <div className={cx(styles.row, props.className, classes.root)}>
      {props.required
        ? <div className={cx(styles.label, classes.text)}>{props.children} <span className={styles.required}>*</span></div>
        : <div className={cx(styles.label, classes.text)}>{props.children}</div>}
      {props.tooltip &&
        <InfoTooltip
          className={styles.tooltip}
          text={props.tooltip} />}
    </div>
  );
};

Label.displayName = 'MedicalProfile.Label';