import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useAnchoredMenuRef = <T extends HTMLElement>() => {
  const location = useLocation();
  const ref = useRef<T>(null);
  const [open, setOpen] = useState(false);

  const handleClick = useCallback((e: MouseEvent) => {
    const anchorClick = ref?.current && ref.current.contains(e.target as T);

    if (!anchorClick) setOpen(false);
  }, [
    ref,
    setOpen,
  ]);

  useEffect(() => {

    if (open) {
      document.addEventListener('click', handleClick);
    }

    return () => {
      document.removeEventListener('click', handleClick);
    };

  }, [
    open,
    handleClick,
  ]);

  useEffect(() => {

    setOpen(false);

  }, [location.pathname]);

  const actions = {
    setOpen,
    toggle: useCallback(() => setOpen(!open), [open]),
  };

  return [ref, open, actions] as const;
};