import { useMemo } from 'react';
import { utils as enumUtils } from '@enums';
import { FieldSelect } from './Field.Select';
import styles from './style.css';

type Props = {
  classes?: {
    label?: string;
  };
  isEditing?: boolean;
  label: string;
  name: string;
  displayValue: string;
};

export function FieldConferenceFeatureShareType({
  classes = {},
  isEditing = false,
  label,
  name,
  displayValue,
}: Props) {
  const options = useMemo(() => {
    const items = enumUtils.ConferenceFeatureShareType.values();

    return items.reduce<{ [k: string]: string }>((acc, curr) => {
      acc[curr] = enumUtils.ConferenceFeatureShareType.getName(curr);
      return acc;
    }, {});
  }, []);

  return (
    <div className={styles.section}>
      <FieldSelect
        classes={classes}
        editing={isEditing}
        label={label}
        name={name}
        displayValue={displayValue}
        options={options} />
    </div>
  );
}