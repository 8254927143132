import { useCallback, useState } from 'react';
import { ConferencePlatform, ConferenceType, ProjectFrequency, ProjectSubtype, ProjectType, ConferenceFeatureShareType } from '@enums';
import { DefaultLanguage } from '@consts/conference';
import {
  SurveyQuotasGoalContext,
} from '@containers/SurveyBuilder';
import type { ProjectCreationForm } from './interfaces';
import { ProjectFormContext } from './context';

type Props =
  ChildrenProps;

const defaultValue: ProjectCreationForm = {
  accountId: null,
  clientName: '',
  currentCompanies: [],
  description: '',
  formerCompanies: [],
  frequency: ProjectFrequency.None,
  goal: 1,
  conferenceSettings: {
    clientNotes: null,
    conferenceDetails: null,
    conferenceDialIn: null,
    conferenceLink: null,
    conferenceMediaType: null,
    conferencePlatform: ConferencePlatform.Sentiment,
    conferenceType: ConferenceType.WebAndPhone,
    defaultDuration: 60,
    languages: [DefaultLanguage],
    videoShareTypeId: ConferenceFeatureShareType.DisabledForAll,
    screenShareTypeId: ConferenceFeatureShareType.EnabledForModerator,
    transcriptionQa: false,
  },
  name: '',
  netsuite: null,
  // projectCode: '',
  projectSubtype: null,
  projectType: ProjectType.Call,
  purchaseOrder: null,
  // startDate: null,
  roles: [],
  suggested: [],
  surveys: [],
  targetCompany: {},
  targetCompleteDate: null,
  titles: [],
  vics: [],
};

export const ProjectCreationFormContainer = ({ children }: Props) => {

  const [form, setForm] = useState(defaultValue);

  const setFormState = useCallback((data: Partial<ProjectCreationForm>) => {
    setForm(s => ({ ...s, ...data }));
  }, []);

  return (
    <SurveyQuotasGoalContext.Provider value={form.goal}>
      <ProjectFormContext.Provider value={[form, setFormState]}>
        {children}
      </ProjectFormContext.Provider>
    </SurveyQuotasGoalContext.Provider>
  );
};

export default ProjectCreationFormContainer;