import { useMemo } from 'react';
import { interpolateString } from 'd3-interpolate';
import { useSpring, to, config as presets } from '@react-spring/web';
import { usePrevious } from './usePrevious';

export const useAnimatedPath = (path: string) => {

  const previousPath = usePrevious(path);
  const interpolator = useMemo(() => interpolateString(previousPath, path), [previousPath, path]);

  const { value } = useSpring({
    from: { value: 0 },
    to: { value: 1 },
    reset: true,
    config: presets['gentle'],
    immediate: true,
  });

  return to(value, interpolator);
};