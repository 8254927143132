import { useCallback, useState } from 'react';
import Select from 'react-select';
import { useQuery } from '@tanstack/react-query';
import * as enums from '@enums';
import { Header, Subtitle, Title } from '@screens/ProjectCreation/Header';
import type { SurveyBuilder } from '@containers/SurveyBuilder/interfaces';
import { useSurveyBuilderState, useUpdateTemplate } from '@containers/SurveyBuilder/hooks';
import * as $api from '@services/api';
import type { SurveyBuilderVersion } from '@/types/survey';
import { SurveyTemplates, useSurveyTemplates } from '@/components/Survey.Templates';
import * as $admin from '$admin/api';
import { useSurveySourceModule } from '$admin/Project.Creation/hooks/useSurveySourceModule';
import { useProjectSurveyStepperContext } from './context';
import { BackButton, Footer } from './Footer';
import styles from './style/ProjectSurvey.Template.css';

type Props = {
  className?: string;
  canLoadDrafts?: boolean;
};

export const SurveyTemplateSelection = (props: Props) => {
  const { includeExternalSurvey, next, project } = useProjectSurveyStepperContext();

  const templates = useSurveyTemplates({
    includeExternalSurvey,
    projectType: project.projectType,
  });

  const [updateTemplate, Alert] = useUpdateTemplate({
    onUpdate: next,
  });

  return (
    <>
      <div className={styles.section}>
        <div className={styles.wrap}>
          <Header className={styles.row}>
            <Title className={styles.row}>Survey Builder</Title>
            <Subtitle className={styles.row}>
              Create a custom survey or choose from one of our templates to kick-start the drafting process.
            </Subtitle>
            {props.canLoadDrafts
              ? (
                <Subtitle>
                  <div style={{ maxWidth: '800px' }}>
                    <ProjectSurveySearch />
                  </div>
                </Subtitle>
              )
              : null}
          </Header>

          <SurveyTemplates
            className={props.className}
            templates={templates}
            onClick={updateTemplate} />

        </div>
      </div>
      <Alert />
    </>
  );
};

type ProjectSearchItem = {
  id: number;
  name: string;
  surveyId: number;
  projectCode: string;
};

const ProjectSurveySearch = () => {
  const [selected, setSelected] = useState<ProjectSearchItem>();
  const [keyword, setKeyword] = useState('');
  const query = useQuery({
    queryKey: [`get:admin/search/projects`, keyword], queryFn: ({ queryKey }) => {
      const name = queryKey[1];
      if (!name.length) return [];

      return $admin.search.projects.fetch({
        isExternal: false,
        name,
        typeIds: [enums.ProjectType.Survey, enums.ProjectType.SurveyCall],
        take: 25,
        searchProductCode: true,
      })
        .then(res => res.items.filter(i => i.survey?.id).map(item => ({
          id: item.id,
          name: `${item.name} (${item.id})`,
          surveyId: item.survey.id,
          projectCode: item.netsuite ? `${item.netsuite.name} (${item.netsuite.identifier})` : null,
        })));
    },
    placeholderData: [],
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const { next } = useProjectSurveyStepperContext();
  const [state, dispatch] = useSurveyBuilderState();
  const sourceModule = useSurveySourceModule();

  const handleChange = useCallback((value: ProjectSearchItem) => {
    setKeyword('');
    setSelected(value);

    $api.surveys.module.fetchSurveyModule({
      isModule: false,
      skipSanitization: false,
      surveyId: value.surveyId,
    })
      .then(result => {
        const actions = computeModuleActions({
          saved: state.survey,
          value: result.survey,
          sectionPosition: 1,
        });

        dispatch({
          type: 'batch-actions',
          actions,
        });

        sourceModule.setSourceSurveyId(value.surveyId);

        next();
      });
  }, [
    sourceModule,
    dispatch,
    next,
    state.survey,
  ]);

  return (
    <Select<ProjectSearchItem>
      options={query.data}
      filterOption={null}
      onInputChange={value => setKeyword(value)}
      onChange={handleChange}
      getOptionLabel={item => `${item.name}`}
      getOptionValue={item => `${item.id}`}
      placeholder={`Search past surveys`}
      noOptionsMessage={() => 'No results'}
      value={selected}
      components={{
        DropdownIndicator: null,
        Option: ({ data, innerRef, ...props }) => (
          <div
            className={styles.moduleOption}
            ref={innerRef}
            {...props.innerProps}>
            <div className={styles.moduleOptionNameContainer}>
              <div className={styles.moduleOptionName}>{data.name}</div>
              <div className={styles.moduleOptionSubtitle}>{data.projectCode}</div>
            </div>
            <div className={styles.moduleLoadDraft}>{`Load Draft >`}</div>
          </div>
        ),
      }} />
  );
};

function computeModuleActions({ saved, value, sectionPosition }: ComputeActions): SurveyBuilder.NonBatchActions[] {
  const actions: SurveyBuilder.NonBatchActions[] = [];

  const getItem = (identifier: string) => saved.items.find(x => x.source.identifier === identifier);
  const getItemOrdinal = (identifier: string) => getItem(identifier)?.ordinal || 0;

  // todo: probably need to merge duplicates and fix identifiers?
  value.classifications.forEach(classification => {
    actions.push({
      type: 'classification-added',
      payload: {
        value: classification,
      },
    });
  });

  value.logic.forEach(logic => {
    actions.push({
      type: 'logic-item-added',
      payload: {
        item: logic,
      },
    });
  });

  const existingMaxOrdinal = Math.max(0, ...saved.items.map(i => i.ordinal));

  value.messages.forEach((message, idx) => {
    const item = value.items.find(x => x.source.identifier === message.identifier);
    actions.push({
      type: 'message-added',
      item: {
        identifier: item.identifier,
        ordinal: existingMaxOrdinal + item.ordinal,
      },
      messageIdentifier: message.identifier,
      sectionIdentifier: item.section.identifier,
    });
    actions.push({
      type: 'message-value-updated',
      messageIdentifier: message.identifier,
      value: message.value,
    });
  });

  value.questions.forEach((question, idx) => {
    const item = value.items.find(x => x.source.identifier === question.base.identifier);
    actions.push({
      type: 'question-added',
      questionIdentifier: question.base.identifier,
      sectionIdentifier: question.section.identifier,
      item: {
        identifier: item.identifier,
        ordinal: existingMaxOrdinal + item.ordinal,
      },
    });
    actions.push({
      type: 'update-question',
      item: question,
    });
  });

  value.alternateImageExercises.forEach((aie, idx) => {
    const item = value.items.find(x => x.source.identifier === aie.identifier);
    actions.push({
      type: 'aie-added',
      exerciseIdentifier: aie.identifier,
      sectionIdentifier: item.section.identifier,
      item: {
        identifier: item.identifier,
        ordinal: existingMaxOrdinal + item.ordinal,
      },
    });
    actions.push({
      type: 'aie-updated',
      item: aie,
    });
  });

  value.quotas.forEach(quota => {
    actions.push({
      type: 'add-quota-item',
      identifier: quota.identifier,
      quotaType: quota.type,
    });
    actions.push({
      type: 'save-quota-item',
      value: quota,
    });
  });

  const startSectionOrdinal = sectionPosition || Math.max(0, ...saved.sections.map(s => getItemOrdinal(s.identifier))) + 1;
  value.sections.forEach((section, idx) => {
    actions.push({
      type: 'section-added',
      value: {
        ...section,
        ordinal: startSectionOrdinal + idx,
      },
    });
  });

  value.tagging.forEach(tagging => {
    actions.push({
      type: 'tagging-item-added',
      payload: {
        identifier: tagging.identifier,
      },
    });
    actions.push({
      type: 'tagging-item-saved',
      payload: {
        value: tagging,
      },
    });
  });

  return actions;
}

export const SurveyTemplateSelectionScreen = (props: Props) => {

  return (
    <>
      <SurveyTemplateSelection {...props} />
      <Footer>
        <BackButton />
      </Footer>
    </>
  );
};

export default SurveyTemplateSelection;

type ComputeActions = {
  saved: SurveyBuilderVersion;
  value: SurveyBuilderVersion;
  sectionPosition: number;
};