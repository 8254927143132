import { TargetProductProfileMessage } from '@/enums';
import { SurveyBuilder, SurveyTemplateBuilder } from '../interfaces';

export function stimulusUpdated(state: State, action: SurveyTemplateBuilder.StimulusUpdated.Action): State {

  return state.reduce((acc, x) => {
    if (x.metadata.template.key === TargetProductProfileMessage.Stimulus) {
      return acc.concat({
        ...x,
        value: action.value,
      });
    }

    return acc.concat(x);
  }, []);

}

type State = SurveyBuilder.State['survey']['messages'];