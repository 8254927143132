import http from '@services/http';
import type { Text } from './interfaces/transcripts';

export const updateText = ({ transcriptId, ...data }: Text.UpdateText.Request) => {
  return http.put<Text.UpdateText.Response>(`/transcripts/${transcriptId}/text`, data);
};

export const saveTextReplacements = ({ transcriptId, ...data }: Text.SaveTextReplacements.Request) => {
  return http.put<Text.SaveTextReplacements.Response>(`/transcripts/${transcriptId}/text/global`, data);
};

export const storeTextRedactions = ({ transcriptId, ...data }: Text.StoreTextRedactions.Request) => {
  return http.put<Text.StoreTextRedactions.Response>(`/transcripts/${transcriptId}/text/redactions`, data);
};

export const saveTranscript = ({ transcriptId, ...data }: Text.SaveTranscript.Request) => {
  return http.post<Text.SaveTranscript.Response>(`/transcripts/${transcriptId}/text/all`, data);
};