import { useSelector } from 'react-redux';
import { hasInternalAdminRole, hasInternalResearchAccess } from '@utils';
import { useIsOwnProfile } from './useIsOwnProfile';

const mapState = (state: Store.State) => state.user;

const useProfileEditableState = () => {
  const me = useSelector(mapState);
  const isOwnProfile = useIsOwnProfile();

  return isOwnProfile
      || hasInternalAdminRole(me)
      || hasInternalResearchAccess(me);
};

export { useProfileEditableState };
export default useProfileEditableState;