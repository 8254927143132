import { useCallback, memo } from 'react';
import ScreenShare from '@mui/icons-material/ScreenShareOutlined';
import StopScreenShare from '@mui/icons-material/StopScreenShareOutlined';
import { Tooltip } from '@presentation/Tooltip';
import { cx } from '@utils';
import styles from './style/Screen.css';

type Props = {
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  value: boolean;
  visible?: boolean;
  supported?: boolean;
  onClick?: (value: boolean) => void;
};

export const Screen = memo(({ className, children, disabled, value, visible, supported, onClick }: Props) => {
  const handleClick = useCallback(() => {
    onClick?.(!value);
  }, [value, onClick]);

  if (!visible) return null;

  return (
    <div
      className={cx(styles.screen, className)}
      onClick={!disabled ? handleClick : undefined}>
      {children ?? (
        <ScreenIcon
          value={value}
          disabled={disabled}
          supported={supported} />
      )}
    </div>
  );
});

const ScreenIcon = memo(({ value, disabled, supported }: Pick<Props, 'value' | 'disabled' | 'supported'>) => {
  const Icon = value
    ? (
      <div className={styles.iconScreen}>
        <ScreenShare />
      </div>
    )
    : (
      <div className={styles.iconScreenOff}>
        <StopScreenShare />
      </div>
    );

  let tooltip: string = null;
  if (disabled) {
    tooltip = 'Cannot share screen when another user is sharing';
  }
  if (!supported) {
    tooltip = 'Screen sharing is not supported with this browser';
  }

  if (tooltip) {
    return (
      <Tooltip
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
        title={tooltip}
        placement="top">
        {Icon}
      </Tooltip>
    );
  }

  return Icon;
});