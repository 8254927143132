import {
  SurveyQuestionMatrixRow,
} from '@/types';
import { Validation } from '../interfaces';

function assertValidNumberOfRows(rows: SurveyQuestionMatrixRow[], min: number, max: number): Validation.AssertionResult {
  const rowsLength = (rows ?? []).length;

  const isInvalid = rowsLength < min || rowsLength > max;

  if (isInvalid) {
    return {
      success: false,
      message: 'Invalid number of rows.',
    };
  }

  return { success: true };
}

function assertNoDuplicateRows(rows: SurveyQuestionMatrixRow[]): Validation.AssertionResult {
  const hasDuplicates
    = rows
    .some((s, i) =>
      rows.some((ss, ii) => ii !== i && s.value === ss.value)
    );

  if (hasDuplicates) {
    return {
      success: false,
      message: 'Duplicate rows present.',
    };
  }

  return { success: true };
}

function assertRowValues(rows: SurveyQuestionMatrixRow[]): Validation.AssertionResult {
  const hasEmptyValues = rows.some(s => !s.value);

  if (hasEmptyValues) {
    return {
      success: false,
      message: 'Row value is empty.',
    };
  }

  return { success: true };
}

export function assertValidRows(rows: SurveyQuestionMatrixRow[], min: number, max: number): Validation.AssertionResult {
  const hasNumberOfRows
    = assertValidNumberOfRows(rows, min, max);

  if (hasNumberOfRows.success === false) {
    return {
      success: false,
      message: hasNumberOfRows.message,
    };
  }

  const hasUniqueRows
    = assertNoDuplicateRows(rows);

  if (hasUniqueRows.success === false) {
    return {
      success: false,
      message: hasUniqueRows.message,
    };
  }

  const hasRowValues
    = assertRowValues(rows);

  if (hasRowValues.success === false) {
    return {
      success: false,
      message: hasRowValues.message,
    };
  }

  return { success: true };
}