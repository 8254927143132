import { useStarredResponseAnswer } from '@containers/SurveyResponse';
import { StarToggle } from '@presentation/StarToggle';
import { useLock } from '@utils';
import { StarAnswerToggleProps } from './interfaces';

export const StarAnswerToggle = ({ questionId }: StarAnswerToggleProps) => {
  const [_, lock] = useLock();
  const {
    onToggle,
    value,
  } = useStarredResponseAnswer(questionId);

  return (
    <StarToggle
      onClick={lock(onToggle)}
      value={value} />
  );
};

export default StarAnswerToggle;