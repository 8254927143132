import { useContext } from 'react';
import { IConference, useConferenceInstance } from '@containers/Conference';
import { ConferenceStatus } from '@/types/conferences';
import { PanelToolsContext } from '@/components/Conference.Panel/Context';
import * as Pane from './Pane';
import styles from './style/Panel.Tools.css';

type Props = {
  status: ConferenceStatus;
} & ICallId;

export const Sidebar = (props: Props) => {
  const ctx = useContext(PanelToolsContext);
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom>();

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          {ctx.notes.visible &&
            <Pane.Notes
              callId={props.callId}
              className={styles.notes}
              popOutNotes={ctx.notes.popout}
              toggleNotes={ctx.notes.toggle} />}
          {ctx.tagging.visible &&
            <Pane.Tagging
              callId={props.callId}
              started={instance.conference.started} />}
        </div>
      </div>
    </div>
  );
};

Sidebar.displayName = 'Panel.Sidebar';