import { NumericFormatProps } from 'react-number-format';
import { NumberInput } from './NumberInput';
import { InputProps } from './interfaces';

type MoneyProps = {
  onValueChange?: (val: number) => void;
  formatterProps?: Omit<NumericFormatProps, 'className' | 'value'>;
  rootClassName?: string;
} & Pick<InputProps,
  'focusableClasses' |
  'invalidClassName' |
  'invalid' |
  'classes' |
  'onBlur' |
  'onFocus' |
  'onChange' |
  'readOnly' |
  'autoFocus' |
  'step'>
  & Pick<NumericFormatProps, 'prefix' | 'value' | 'defaultValue' | 'min' | 'max'>;

export const MoneyInput = ({
  onValueChange,
  formatterProps,
  prefix = '$',
  ...props }: MoneyProps) => {

  return (
    <NumberInput
      onValueChange={onValueChange ? v => onValueChange(v.floatValue) : null}
      {...props}
      prefix={prefix}
      thousandSeparator={true}
      {...formatterProps} />
  );
};