export const ReviewPayouts = {
  Adjust: {
    Post: `post:admin/billing/review-payouts/adjust`,
  },
  Reject: {
    Post: `post:admin/billing/review-payouts/reject`,
  },
  Items: {
    Get: `get:admin/billing/review-payouts`,
  },
  ShowPopup: {
    Get: `get:admin/billing/review-payouts/show-popup`,
  },
};