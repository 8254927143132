import {
  EditorComponent,
} from '@remirror/react';
import { cx } from '@utils';
import styles from './style/Details.Editor.css';

type Props = {
  className?: string;
};

export const ConferenceDetailsEditor = ({
  className,
}: Props) => {

  return (
    <div className={cx(styles.root, className)}>
      <EditorComponent />
    </div>
  );
};