import { useEffect } from 'react';
import { useTranscriptFocusedComment } from '@containers/Transcript.Commenting/hooks/useTranscriptFocusedComment';
import { usePreviousDistinct } from '@utils';
import { useCommands } from './useCommands';

export function useBlurCommentHighlight() {
  const focusedComment = useTranscriptFocusedComment();
  const previouslyFocusedCommentHighlight = usePreviousDistinct(focusedComment?.highlight?.identifier);

  const { focusHighlight, blurHighlight } = useCommands();

  useEffect(() => {

    if (focusedComment?.highlight?.identifier) {
      focusHighlight(focusedComment.highlight.identifier, false);
    } else if (previouslyFocusedCommentHighlight) {
      blurHighlight();
    }
  }, [
    focusHighlight,
    blurHighlight,
    focusedComment?.highlight?.identifier,
    previouslyFocusedCommentHighlight,
  ]);
}