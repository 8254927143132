import {
  StringParam, QueryParamConfig, encodeString,
  decodeString, useQueryParams, withDefault,
} from 'use-query-params';
import { IUniversalSearch } from '@/types';

type Tab = IUniversalSearch.SearchPageQueryTab;

const TabParam: QueryParamConfig<Tab, Tab> = {
  encode: (tab: Tab) => encodeString(tab),
  decode: (tab: string) => decodeString(tab) as Tab,
};

export default function useFiltersState() {
  const [query, setQuery] = useQueryParams({
    q: StringParam,
    tab: withDefault(TabParam, 'all'),
  });

  return [query, setQuery] as const;
}

export type FiltersQuery = ReturnType<typeof useFiltersState>[0];

export { useFiltersState };