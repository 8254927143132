import { useCallback, useContext, useMemo } from 'react';
import { TranscriptFocusedCommentContext } from '@containers/Transcript.Commenting/Context';

export const useTranscriptCommentFocus = (identifier: string) => {
  const [focusedIdentifier, setFocusedIdentifier] = useContext(TranscriptFocusedCommentContext);

  const isFocused = useMemo(() => {
    return focusedIdentifier === identifier;
  }, [focusedIdentifier, identifier]);

  const setFocused = useCallback((focused: boolean) => {
    if (focused) {
      setFocusedIdentifier(identifier);
    } else {
      setFocusedIdentifier(s => {
        return s === identifier ? null : s;
      });
    }
  }, [identifier, setFocusedIdentifier]);

  return [isFocused, setFocused] as const;
};