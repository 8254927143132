import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { GettingStarted } from '@screens/PayoutSetup/Stepper.GettingStarted';
import { selectHasPayablePayoutAccount } from '@store/selectors';
import styles from './style/Onboarding.css';

export default function PayoutOnboarding() {
  const hasPayoutAccount = useSelector(selectHasPayablePayoutAccount);

  return (
    <div className={styles.wrap}>
      <Helmet title="Payout Setup" />
      <GettingStarted hasPayoutAccount={hasPayoutAccount} />
    </div>
  );
}

export { PayoutOnboarding };