import { useEffect, useState } from 'react';
import type { RouteProps } from 'react-router-dom';
import { path } from '@/consts';
import { TabView } from '@/components/TabView';
import { useTabViewQueryState } from '@/components/TabView/hooks/useTabViewQueryState';
import { qs } from '@/utils';
import type { SearchSceneMap, SearchRouteKey } from './interfaces';

type StateProps = {
  keys: SearchRouteKey[];
  routes: Array<{
    component: React.ComponentType;
    title: string | React.ReactNode;
    key: SearchRouteKey;
  }>;
};

export default function SearchTabView({ scenes }: SearchTabViewProps) {
  const [routes, setRoutes] = useState<StateProps>(getState(scenes));
  const [{ index }, updateLocation] = useTabViewQueryState<SearchRouteKey>({
    formatPath,
    routes: routes.keys,
  });

  useEffect(() => {
    setRoutes(getState(scenes));
  }, [scenes]);

  return (
    <TabView
      index={index}
      onIndexChange={updateLocation}
      routes={routes.routes}
      renderTabView={({ route }) => route ? <route.component /> : undefined} />
  );
}

function getState(scenes: SearchSceneMap) {
  const keys = Object.keys(scenes).filter(k => !!scenes[k]) as SearchRouteKey[];
  return {
    keys,
    routes: keys.map(k => ({ key: k, ...scenes[k] })),
  };
}

function formatPath(tab: SearchRouteKey, location: RouteProps['location']) {
  const search = qs.append(location.search, { tab });
  return `${path.Workspaces.Search}${search}`;
}

export { SearchTabView };

type SearchTabViewProps = {
  scenes: SearchSceneMap;
};