import { path } from '@/consts';
import { RouteAuth } from '@/enums';
import { StateRouter } from '@/routes/Routers';
import type { Routing } from '@/containers/Routing';
import { Workspaces } from '@/screens/Workspaces';
import { BrandNotFound, WorkspaceBrand } from '@/screens/Workspace.Brand';
import { Search } from '@/screens/WorkspaceGlobalSearch';
import { WorkspaceTranscript } from '@/screens/Workspace.Transcript';
import { WorkspaceFile, FileNotFound } from '@screens/Workspace.File/';
import { FolderNotFound, WorkspaceFolder } from '@/screens/Workspace.Folder';
import * as utils from '@/utils';

export const routes: Routing.Route[] = [
  {
    component: Search,
    path: path.Workspaces.Search,
  },
  {
    component: WorkspaceTranscript,
    path: path.Workspaces.Transcript,
    exact: true,
  },
  {
    component: FileNotFound,
    exact: true,
    path: path.Workspaces.FileNotFound,
  },
  {
    component: WorkspaceFile,
    path: [
      path.Workspaces.FileTabs,
      path.Workspaces.File,
    ],
  },
  {
    component: FolderNotFound,
    exact: true,
    path: path.Workspaces.FolderNotFound,
  },
  {
    component: WorkspaceFolder,
    path: [
      path.Workspaces.FolderTabs,
      path.Workspaces.Folder,
    ],
  },
  {
    component: BrandNotFound,
    exact: true,
    path: path.Workspaces.BrandNotFound,
  },
  {
    component: WorkspaceBrand,
    path: [
      path.Workspaces.BrandTabs,
      path.Workspaces.Brand,
    ],
  },
  {
    component: Workspaces,
    path: path.Workspaces.Root,
  },
];

const validator = (state: Store.State) => {
  return utils.hasClientRole(state.user) && state.group.features.workspaces
    ? routes
    : [];
};

export const router = StateRouter(validator, {
  auth: RouteAuth.Authenticated,
  path: path.Workspaces.Root,
});