import { useCallback, useMemo } from 'react';
import { useCanCreateFindAndReplace } from '@/containers/SurveyBuilder/hooks/useCanCreateFindAndReplace';
import { useRichTextEditorRegistryEditor } from '@/containers/SurveyBuilder/hooks/useRichTextEditorRegistryEditor';
import type { MatrixRangeQuestion } from '@/types/survey';
import { RowContextMenu } from './Row.ContextMenu';
import { useFindAndReplaceRowMenuItem } from './hooks/row-menu';

type Props = {
  row: MatrixRangeQuestion.Row;
};

export const MatrixRangeRowContextMenu = (props: Props) => {
  const canFar = useCanCreateFindAndReplace();
  const { getEditorByIdentifier } = useRichTextEditorRegistryEditor();

  const getEditor = useCallback(() => getEditorByIdentifier(props.row.base.identifier), [getEditorByIdentifier, props.row.base.identifier]);

  const findAndReplaceMenuItem = useFindAndReplaceRowMenuItem({
    getContext: getEditor()?.getContext,
  });

  const items = useMemo(() => {
    return [
      canFar && findAndReplaceMenuItem ? { children: findAndReplaceMenuItem.children, onClick: findAndReplaceMenuItem.onClick } : null,
    ].filter(Boolean);
  }, [
    canFar,
    findAndReplaceMenuItem,
  ]);

  return (
    <>
      <RowContextMenu
        row={props.row}
        items={items} />
      {canFar && findAndReplaceMenuItem.Modal ? <findAndReplaceMenuItem.Modal /> : null}
    </>
  );
};