import { forwardRef, memo } from 'react';
import { PlusCircle } from 'react-feather';
import { cx } from '@utils';
import styles from './style/AddItemButton.css';

export type AddItemButtonProps = {
  label: string | number;
  className?: string;
  onClick?: (e?: React.MouseEvent<HTMLDivElement>) => unknown;
  iconClassName?: string;
  textClassName?: string;
};

export const AddItemButton = memo(forwardRef<HTMLDivElement, AddItemButtonProps>((props, ref) => {
  return (
    <div
      ref={ref}
      className={cx(styles.root, props.className)}
      onClick={props.onClick}>
      <PlusCircle className={cx(styles.plus, props.iconClassName)} />
      <span className={cx(styles.label, props.textClassName)}>{props.label}</span>
    </div>
  );
}));

export default AddItemButton;