import { ProjectStartInput } from '@/components/GroupProject';
import { Field } from './Overview.Field';
import styles from './style/Overview.css';

type Props = {
  label:    string;
  onChange: (value: Date) => unknown;
  value:    Date;
};

export const StartDate = (props: Props) => {

  return (
    <Field label={props.label}>
      <div className={styles.picker}>
        <ProjectStartInput
          onChange={props.onChange}
          value={props.value} />
      </div>
    </Field>
  );
};

StartDate.displayName = 'Project.Details.Field.StartDate';