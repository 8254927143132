import { useMemo } from 'react';
import { SurveyConditionType, SurveyQuestionConditionType } from '@/enums';
import { SurveyLogic, SurveyTagging } from '@/types/survey';
import { isQuestionInAction } from '@/containers/SurveyBuilder.Logic/utils.lookup';

export const useGetAdditionalOptionsLogic = (questionLogic: SurveyLogic.Item[], questionIdentifier: string) => {

  return useMemo(() => {
    return questionLogic.filter(f => filterByConditions(f.conditions) || isQuestionInAction({
      action: f.action,
      question: { identifier: questionIdentifier },
    }));
  }, [questionLogic, questionIdentifier]);

};

export const useGetAdditionalOptionsTagging = (questionTagging: SurveyTagging.Item[]) => {
  return useMemo(() => {
    return questionTagging.filter(f => filterByConditions(f.conditions));
  }, [questionTagging]);
};

function filterByConditions(conditions: SurveyLogic.ItemCondition[]) {
  if (conditions.length !== 1) return true;

  const condition = conditions[0];
  if (condition.conditionType !== SurveyConditionType.Question) return true;

  return ![
    SurveyQuestionConditionType.OptionNotSelected,
    SurveyQuestionConditionType.OptionSelected,
  ].includes(condition.data.type);
}