import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Workspace } from '@/types';
import { useSelectWorkspaces } from '@containers/Store';

type Params = {
  workspace: string;
};

export const useMatchWorkspaceStateFromUrl = () => {
  const params = useParams<Params>();
  const workspaces = useSelectWorkspaces();

  const workspace = useMemo(() =>
    workspaces[params.workspace] as Workspace,
    [workspaces, params.workspace],
  );

  return workspace;
};

export default useMatchWorkspaceStateFromUrl;