export const Onboarding = {
  Background: {
    Get: (data: IUserId) => [`get:users/onboarding/background`, data.userId],
    Put: `put:users/onboarding/background`,
  },
  Statuses: {
    Put: `put:users/onboarding/statuses`,
  },
  Expertise: {
    Get: (data: IUserId) => [`get:users/onboarding/expertise`, data.userId],
    Role: {
      Put: `put:users/onboarding/expertise/role`,
    },
  },
};

export const Topics = {
  Get: `get:users/topics`,
};

export const UpcomingCalls = `get:users/upcoming-calls`;

export const RecentFiles = ['get:users/recent-files'];