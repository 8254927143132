import qs from 'query-string';
import http from '@services/http';
import type { Entities } from './interfaces/projects';

export const fetchItems = (data: Entities.FetchItems.Request) => {
  return http.get<Entities.FetchItems.Response>(`/projects/${data.projectId}/entities`, {
    params: {
      index: data.index,
      size: data.size,
      sortBy: data.sortBy,
      sortDir: data.sortDir,
      nameFilter: data.nameFilter,
    },
  });
};

export const addItems = ({ projectId, ...data }: Entities.AddItems.Request) => {
  return http.post<Entities.AddItems.Response>(`/projects/${projectId}/entities`, data);
};

export const search = ({ projectId, ...data }: Entities.Search.Request) => {
  return http.get<Entities.Search.Response>(`/projects/${projectId}/entities/search`, {
    params: {
      exact: data.exact,
      name: data.name,
      type: data.type,
    },
  });
};

export const modifyItem = ({ projectId, ...data }: Entities.ModifyItem.Request) => {
  return http.put<Entities.ModifyItem.Response>(`/projects/${projectId}/entities`, data);
};

export const updateEntityBoost = (data: Entities.UpdateEntityBoost.Request) => {
  const { name, projectId, type, ...rest } = data;

  return http.patch<Entities.UpdateEntityBoost.Response>(`/projects/${projectId}/entities/${type}/${name}`, rest);
};

export const toggleProjectEntityBoostLock = ({ projectId, ...data }: Entities.ToggleProjectEntityBoostLock.Request) => {
  return http.post(`/projects/${projectId}/entities/boost-lock`, data);
};

export const isProjectEntityBoostLocked = ({ projectId }: Entities.IsProjectEntityBoostLocked.Request) => {
  return http.get<Entities.IsProjectEntityBoostLocked.Response>(`/projects/${projectId}/entities/boost-lock`);
};

export const getEntityLists = ({ projectId }: Entities.GetEntityLists.Request) => {
  return http.get<Entities.GetEntityLists.Response>(`/projects/${projectId}/entities/lists`);
};

export const getEntityListRecords = ({ projectId, ...data }: Entities.GetListEntities.Request) => {
  return http.get<Entities.GetListEntities.Response>(`/projects/${projectId}/entities/lists/entries?${qs.stringify(data, { arrayFormat: 'bracket' })}`);
};

export const finishEntityOnboarding = ({ projectId, ...data }: Entities.FinishOnboarding.Request) => {
  return http.post(`/projects/${projectId}/entities/finish-onboarding`, data);
};

export const promptEntityOnboarding = ({ projectId, ...data }: Entities.PromptOnboarding.Request) => {
  return http.post(`/projects/${projectId}/entities/prompt-onboarding`, data);
};