import { OptionsColors } from './options';

export const NotApplicableColor = 'var(--gray-l)';

type Item = {
  id: number;
  isNotApplicable: boolean;
};

export function getSegmentColor({ colors = MatrixColors, ...data }: {
  colors?: MatrixColors;
  option: Item;
  options: Item[];
}) {
  if (data.option.isNotApplicable) {
    return NotApplicableColor;
  }

  const filtered = data.options
    .filter(f => !f.isNotApplicable);

  const keyIndex = filtered
    .findIndex(f => f.id === data.option.id);

  return colors[filtered.length % Object.keys(colors).length][keyIndex];
}

type MatrixColors = {
  [ordinal: number]: string[];
};

export const MatrixColors: MatrixColors = {
  1: OptionsColors,
  2: OptionsColors,
  3: OptionsColors,
  4: OptionsColors,
  5: OptionsColors,
  6: OptionsColors,
  7: OptionsColors,
  8: OptionsColors,
  9: OptionsColors,
  10: OptionsColors,
  11: OptionsColors,
  12: OptionsColors,
  13: OptionsColors,
};