import { memo, useCallback } from 'react';
import type { NumberInputTableQuestion } from '@/types/survey';
import { useGetDisplayTotal } from '../SurveyBuilder.Question/hooks';
import { Options } from './Options';
import { Rows } from './Rows';
import { OptionMetadata } from './Metadata';
import { SurveyQuestionLogic } from './Logic';
import { SurveyQuestionClassifications } from './Tagging';
import styles from './style/NumberInputTable.css';
import {
  useGetRowDisplayLogic,
  useGetOptionDisplayLogic,
  useQuestionClassifications,
  useQuestionLogic,
} from './hooks';
import { SurveySettings } from './Settings';

type Props = {
  item: NumberInputTableQuestion.Question;
};

export const NumberInputTableQuestionPreview = ({ item }: Props) => {

  const questionLogic = useQuestionLogic(item.base.identifier);
  const questionClassifications = useQuestionClassifications(item.base.identifier);
  const getRowDisplayLogic = useGetRowDisplayLogic(item.base.identifier);
  const getOptionDisplayLogic = useGetOptionDisplayLogic(item.base.identifier);

  const renderRowExtras = useCallback((row: NumberInputTableQuestion.Row) => {

    const displayLogicItems = getRowDisplayLogic(row);

    return (
      <>
        <OptionMetadata
          isAnchored={row.metadata.isAnchored}
          isExclusive={false}
          isOpenEnded={row.metadata.isOpenEnded} />
        {displayLogicItems}
      </>
    );
  }, [getRowDisplayLogic]);

  const renderOptionExtras = useCallback((option: NumberInputTableQuestion.Option) => {

    const displayLogicItems = getOptionDisplayLogic(option);

    return (
      <>
        <OptionMetadata
          isReadOnly={option.metadata.isReadOnly} />
        {displayLogicItems}
      </>
    );
  }, [getOptionDisplayLogic]);

  return (
    <>
      <div className={styles.header}>Rows</div>
      <Rows
        item={item}
        renderRowExtras={renderRowExtras} />
      <div className={styles.header}>Columns</div>
      <Options
        item={item}
        renderOptionExtras={renderOptionExtras} />
      <Settings settings={item.settings} />
      <SurveyQuestionLogic items={questionLogic} />
      <SurveyQuestionClassifications items={questionClassifications} />
    </>
  );
};

type SettingsProps = {
  settings: NumberInputTableQuestion.Settings;
};

const Settings = memo(({ settings }: SettingsProps) => {

  const getDisplayTotal = useGetDisplayTotal(settings.total);

  return (
    <SurveySettings>
      {settings.orderRowsBasedOnSource && <>Order rows based on source question</>}
      {settings.randomizeRows && <>Randomize rows</>}
      {settings.randomizeOptions && <>Randomize columns</>}
      {settings.allowEmptyValues && <>Allow empty values</>}
      {settings.displayCellPct && <>Display cell percentages</>}
      {settings.displayTotals && <>Display column totals</>}
      {settings.total.comparate && <>Required Sum: {getDisplayTotal()}</>}
      {!settings.dropdowns.enabled && (settings.validations.minValue !== null || settings.validations.maxValue !== null) &&
        <>
          {[
            settings.validations.minValue !== null && `Min. value: ${settings.validations.minValue}`,
            settings.validations.maxValue !== null && `Max. value: ${settings.validations.maxValue}`,
          ].filter(Boolean).join(', ')}
        </>
      }
      {settings.dropdowns.enabled &&
        <>Dropdown inputs: {settings.validations.minValue} - {settings.validations.maxValue}, increments of {settings.dropdowns.increment}</>
      }
    </SurveySettings>
  );
});