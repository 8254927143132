import { useState, useContext, useMemo, useCallback } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import type { ColumnDef } from '@tanstack/react-table';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';
import { transcripts } from '@api/projects';
import { SortableTable } from '@presentation/Tables';
import ActivityIndicator from '@/components/ActivityIndicator';
import { Checkbox } from '@/components/Checkbox';
import { Header } from '@/components/Modal';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { ButtonActivityIndicator, ButtonOutlined } from '@presentation';
import { Navigation } from './PublishModal.Navigation';
import type { PublishScreenProps } from './interfaces';
import { FilesTableContext, PublishModalContext } from './context';
import styles from './style/PublishModal.css';

export const Notify = (props: PublishScreenProps) => {
  const { projectId } = useContext(FilesTableContext);

  const usersQuery = useQuery({
    queryKey: ['get:project-transcripts:users-to-notify', projectId],
    queryFn: () => {
      return transcripts.getUsersToNotify({ projectId });
    },
  });

  if (usersQuery.isInitialLoading) return <ActivityIndicator />;

  //If no users exist, show a screen explaining why
  if (!usersQuery.data.users.length) return <NoUsers {...props} />;

  return <UsersContent {...props} users={usersQuery.data.users} />;
};

const NoUsers = (props: PublishScreenProps) => {
  return (
    <>
      <div className={styles.subheader}>
        There are no client users that can be notified.
      </div>
      <Navigation
        onCancel={props.onClose}
        cancelText='Close'
        nextText={null}
        onNext={null} />
    </>
  );
};

type UserEntry = Awaited<ReturnType<typeof transcripts.getUsersToNotify>>['users'][number];

type ContentProps = PublishScreenProps & {
  users: UserEntry[];
};

const UsersContent = (props: ContentProps) => {
  const { projectId } = useContext(FilesTableContext);
  const { numTranscriptsPublished } = useContext(PublishModalContext);
  const [selectedUsers, setSelectedUsers] = useState<number[]>(props.users.map(u => u.id));
  const notifyMutation = useMutation({
    mutationKey: [
      `post:projects/transcripts/notify-users`,
      projectId,
      selectedUsers,
      numTranscriptsPublished,
    ],
    mutationFn: () => {
      return transcripts.notifyUsers({ projectId, userIds: selectedUsers, numTranscriptsPublished });
    },
  });

  const toggleUserCheckbox = useCallback((userId: number) => {
    setSelectedUsers(old => {
      if (old.includes(userId)) {
        return old.filter(x => x !== userId);
      } else {
        return [...old, userId];
      }
    });
  }, []);

  const toggleMasterCheckbox = useCallback((checked: boolean) => {
    if (checked) {
      setSelectedUsers(props.users.map(u => u.id));
    } else {
      setSelectedUsers([]);
    }
  }, [props.users]);

  const columns = useMemo<ColumnDef<UserEntry>[]>(() => {
    return [{
      id: 'selected',
      header: 'Selected',
      cell: ({ row }) => (
        <Checkbox checked={selectedUsers.includes(row.original.id)} onChange={() => toggleUserCheckbox(row.original.id)} />
      ),
      meta: {
        className: styles.checkCell,
      },
    }, {
      id: 'name',
      header: 'Name',
      cell: ({ row }) => row.original.name,
    }];
  }, [selectedUsers, toggleUserCheckbox]);

  const table = useReactTable({
    data: props.users,
    getCoreRowModel: getCoreRowModel(),
    columns,
  });

  return (
    <>
      <Header>Notify Clients?</Header>
      <div className={styles.subheader}>
        Send an email to notify the client users of the new transcripts that have been published for this project.
      </div>
      <div className={styles.checkboxRow}>
        <Checkbox
          checked={selectedUsers.length === props.users.length}
          indeterminate={selectedUsers.length > 0 && selectedUsers.length !== props.users.length}
          onChange={(_, checked) => toggleMasterCheckbox(checked)} />
        Send Notification Email
      </div>
      <SortableTable table={table} />
      <ButtonSet className={styles.footer}>
        <ButtonOutlined
          className={styles.btn}
          color="black"
          disabled={notifyMutation.isLoading}
          fontWeight="bold"
          onClick={props.onClose}>
          {`Don't Notify`}
        </ButtonOutlined>
        <ButtonActivityIndicator
          color="affirmative"
          className={styles.btn}
          disabled={selectedUsers.length < 1}
          implicitDisable={false}
          loading={notifyMutation.isLoading}
          onClick={() => notifyMutation.mutateAsync().then(props.onClose)}>
          {`Notify Clients`}
        </ButtonActivityIndicator>
      </ButtonSet>
    </>
  );
};