import http from '@services/http';
import type { Members } from './interfaces';

export const fetchExpertise = (data: Members.FetchMedicalExpertise.Request) => {
  return http.get<Members.FetchMedicalExpertise.Response>(`/admin/members/${data.userId}/medical/expertise`);
};

export const fetchPayerFields = (data: Members.FetchMedicalPayerFields.Request) => {
  return http.get<Members.FetchMedicalPayerFields.Response>(`/admin/members/${data.userId}/medical/payer`);
};

export const updateExpertise = ({ userId, ...data }: Members.UpdateMedicalExpertise.Request) => {
  return http.patch<Members.UpdateMedicalExpertise.Response>(`/admin/members/${userId}/medical/expertise`, data);
};

export const updatePayerFields = (data: Members.UpdateMedicalPayerFields.Request) => {
  return http.patch<Members.UpdateMedicalPayerFields.Response>(`/admin/members/${data.userId}/medical/payer`, data);
};