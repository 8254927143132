import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHasConsultantRole } from '@containers/User';
import { useDisconnectUserAccount } from '@utils/api';
import * as selectors from '@store/selectors';
import {
  useOutlookCalendarSync,
} from '@/components/ConnectAccount/hooks';
import { useAlert } from '@/components/Modal/Alert';
import {
  OutlookCalendar,
} from '@/components/icons';
import { Integration } from '@/components/Settings';
import styles from './style/Settings.Integrations.css';

export const OutlookCalendarIntegration = () => {

  const [toggleAlert, Alert] = useAlert();

  const googleCalendar = useSelector(selectors.selectGoogleCalendar);
  const connected = useSelector(selectors.selectMicrosoftCalendar);

  const hasConsultantRole = useHasConsultantRole();
  const { mutateAsync: disconnect } = useDisconnectUserAccount({
    onSuccess: _ => {
      toggleAlert();
    },
  });

  const outlookCalendar = useOutlookCalendarSync();

  const handleDisconnect = useCallback(() => {
    if (!connected) return;
    return disconnect({
      id: connected.id,
    });
  }, [connected, disconnect]);

  const description = hasConsultantRole
    ? `Connect your Outlook calendar`
    : `Streamline the scheduling experience by allowing interviewees to see available time slots.`;

  return (
    <>
      <Integration
        className={styles.item}
        icon={OutlookCalendar}
        title={title}
        description={description}
        connected={!!connected}
        disabled={!!googleCalendar}
        loading={outlookCalendar.isLoading}
        onConnect={outlookCalendar.connect}
        onDisconnect={toggleAlert} />
      <Alert
        message="Are you sure you want to remove your connected Outlook calendar?"
        onConfirm={handleDisconnect} />
    </>
  );
};

const title = `Outlook Calendar Sync`;

export default OutlookCalendarIntegration;
