import { memo } from 'react';
import { FileIconProps } from './interfaces';

export const PresentationIcon = memo(({
  className,
  size = 24,
}: FileIconProps) => {

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 25">
      <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group" transform="translate(4.000000, 2.000000)" stroke="#EF8348">
          <g id="Group-4" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <g id="Group-8">
              <g id="Group-6">
                <path d="M10,0 L2,0 C0.8954305,0 0,0.8954305 0,2 L0,18 C0,19.1045695 0.8954305,20 2,20 L14,20 C15.1045695,20 16,19.1045695 16,18 L16,6 L10,0 Z" id="Path" />
              </g>
            </g>
          </g>
          <circle id="Oval" strokeWidth="2" cx="8" cy="12" r="4" />
          <line x1="8" y1="12" x2="11" y2="14" id="Path-4" strokeWidth="1.5" strokeLinecap="round" />
          <line x1="8" y1="12" x2="8" y2="8.87177195" id="Path-4" strokeWidth="1.5" strokeLinecap="round" />
        </g>
      </g>
    </svg>
  );
});

export default PresentationIcon;