import { useCallback, useContext, useMemo, useState } from 'react';
import { MinusCircle } from 'react-feather';
import cuid from 'cuid';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ButtonActivityIndicator, ButtonOutlined } from '@presentation';
import { TranscriptEntity } from '@/types/transcript.entities';
import { Tooltip } from '@/presentation/Tooltip';
import { Select } from '$admin/components/Select';
import { EntityTypesContext } from './Context';
import { EntityName } from './EntityName';
import styles from './style/AddEntity.Form.css';

type Props = {
  initialEntries?: number;
  loading: boolean;
  onCancel: () => void;
  cancelText?: string;
  onSubmit: (items: { name: string; type: string }[]) => void;
  minEntries: number;
};

export const AddEntityForm = ({ onSubmit, initialEntries = 1, cancelText = 'Cancel', minEntries = 10, ...props }: Props) => {
  const [entities, setEntities] = useState(createInitialState(initialEntries));

  const handleChange = useCallback((item: FormItemBaseProps) => {
    setEntities(state => state.map(x => x.id === item.id ? item : x));
  }, []);

  const remove = useCallback((id: string) => {
    setEntities(state => state.filter(x => x.id !== id));
  }, []);

  const push = useCallback(() => {
    setEntities(state => [
      ...state,
      createEmptyRow(),
    ]);
  }, []);

  const handleSubmit = useCallback(() => {
    onSubmit(entities
      .filter(x => x.name.trim().length > 0 && x.type)
      .map(x => ({
        name: x.name.trim(),
        type: x.type,
      })));
  }, [
    entities,
    onSubmit,
  ]);

  const valid = useMemo(() => {
    return entities.filter(x => {
      return x.name.trim().length > 0 && x.type;
    }).length >= minEntries;
  }, [entities, minEntries]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.row}>
            <div className={styles.nameLabel}>Entity Name</div>
            <div className={styles.label}>Entity Type</div>
          </div>
          {entities.map(x =>
            <FormItem
              id={x.id}
              key={x.id}
              name={x.name}
              onChange={handleChange}
              onRemove={remove}
              removable={entities.length > 1}
              type={x.type} />)}
          <button
            className={styles.add}
            onClick={push}
            type="button">
            <AddCircleOutlineIcon className={styles.icon} />
            <span>Add Entity</span>
          </button>
        </div>
        <div className={styles.footer}>
          <ButtonOutlined
            className={styles.btn}
            color="silver"
            disabled={props.loading}
            onClick={props.onCancel}>
            {cancelText}
          </ButtonOutlined>
          <Tooltip title={!valid ? `You must provide at least ${minEntries} entries.` : null} placement='top'>
            <div>
              <ButtonActivityIndicator
                className={styles.btn}
                disabled={!valid}
                indicatorSize={12}
                implicitDisable={false}
                loading={props.loading}
                onClick={handleSubmit}>
                Save
              </ButtonActivityIndicator>
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

AddEntityForm.displayName = 'AddEntity.Form';

type FormItemProps = {
  onChange: (item: FormItemBaseProps) => void;
  onRemove: (id: string) => void;
  removable: boolean;
} & FormItemBaseProps;

const FormItem = (props: FormItemProps) => {
  const ctx = useContext(EntityTypesContext);

  const handleChangeName = useCallback((value: { name: string; entityTypeKey: string }) => {
    props.onChange({
      id: props.id,
      name: value.name,
      type: value.entityTypeKey ?? props.type,
    });
  }, [props]);

  const handleChangeType = useCallback((value: string) => {
    props.onChange({
      id: props.id,
      name: props.name,
      type: value,
    });
  }, [props]);

  return (
    <div className={styles.row}>
      <div className={styles.nameField}>
        <EntityName
          onChange={handleChangeName}
          value={props.name} />
      </div>
      <div className={styles.field}>
        <Select
          className={styles.select}
          name={`type-${props.id}`}
          onChange={e => handleChangeType(String(e.target.value))}
          options={ctx.items}
          placeholder="Select Type"
          value={props.type} />
      </div>
      {props.removable &&
        <button
          className={styles.delete}
          onClick={() => props.onRemove(props.id)}>
          <MinusCircle />
        </button>}
    </div>
  );
};

type FormItemBaseProps = {
  id: string;
  name: string;
  type: string;
};

function createInitialState(count: number) {
  return Array.from(new Array(count)).map(createEmptyRow);
}

function createEmptyRow() {
  return {
    id: cuid(),
    name: '',
    type: '',
  };
}