import { SurveyTemplateType } from '@enums/survey.template';
import type { SurveyBuilderVersion } from '@/types/survey';
import { generateNewSection, generateDefaultLanguage } from '../state/defaults';

export const defaultTemplateData = {};

export const initialSurveyData: SurveyBuilderVersion = {
  alternateImageExercises: [],
  classifications: [],
  createdOn: null,
  id: null,
  items: [],
  language: {
    languages: [generateDefaultLanguage()],
    languageKeyMap: {},
  },
  logic: [],
  messages: [],
  questions: [],
  quotas: [],
  sections: [generateNewSection({ ordinal: 1 })],
  surveyId: null,
  surveyDetails: {},
  tagging: [],
  template: {
    data: null,
    type: SurveyTemplateType.None,
  },
};