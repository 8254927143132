import { useContext } from 'react';
import { AutofillClientAccountContext } from '@containers/Project.Creation/Context';
import * as SetupFields from '$admin/Project.Creation/Field.Setup';
import { Field } from './Overview.Field';

type Props = {
  label:        string;
  options:      Descriptor[];
  onChange:     (val: number) => unknown;
  tooltip?:     string;
  value:        number;
};

export const ClientAccount = (props: Props) => {
  const { autofilled } = useContext(AutofillClientAccountContext);

  return (
    <Field label={props.label}>
      <SetupFields.SelectSearch
        autofilled={autofilled}
        clearable
        name="accountId"
        onChange={x => props.onChange(x?.id)}
        options={props.options}
        placeholder="Client Account"
        value={(props.value ?? undefined)} />
    </Field>
  );
};

ClientAccount.displayName = 'Project.Creation.Details.Field.ClientAccount';