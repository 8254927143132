import http from '@services/http';
import * as API from './interfaces';

export const fetchRequest = (data: API.Access.FetchRequest.Request) => {
  return http.get<API.Access.FetchRequest.Response>(`/access/objects/${data.objectId}`);
};

export const createRequest = (data: API.Access.CreateRequest.Request) => {
  return http.post<API.Access.CreateRequest.Response>(`/access/requests`, data);
};

export const resendRequest = (data: API.Access.ResendRequest.Request) => {
  return http.post<API.Access.ResendRequest.Response>(`/access/requests/${data.requestId}/resend`, data);
};

export const fetchPendingRequests = () => {
  return http.get<API.Access.FindPendingRequests.Response>(`/access/requests/manage`);
};

export const updateRequestApproval = (data: API.Access.UpdateRequestApproval.Request) => {
  return http.patch<API.Access.UpdateRequestApproval.Response>(`/access/requests/${data.requestId}/approval`, data);
};