import { useMemo } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks/useSurveyBuilderContext';
import { SurveyItemType } from '@/enums';

type Options = {
  activeQuestionOrdinal?: number;
};

export const useQuestionOrdinalCutoff = (opts: Options = {}) => {
  const [state] = useSurveyBuilderState();

  return useMemo(() => {

    const item = state.survey.items.find(f => f.identifier === state.editing.itemIdentifier);

    const ordinal = opts.activeQuestionOrdinal ?? item.ordinal;

    const lastQuestionItem = [...state.survey.items].reverse().find(f => f.ordinal < ordinal && f.type === SurveyItemType.Question);

    if (!lastQuestionItem) return 0;

    const lastQuestion = state.survey.questions.find(f => f.base.identifier === lastQuestionItem.source.identifier);
    return lastQuestion.ordinal;
  }, [
    state.editing.itemIdentifier,
    state.survey.items,
    state.survey.questions,
    opts.activeQuestionOrdinal,
  ]);
};