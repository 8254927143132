import { useCallback, useMemo } from 'react';
import * as $api from '@api/workspaces.file-upload';
import { useInvalidateFilesQueries } from '@utils/api';
import type { WorkspaceFileUpload } from '@/types';
import { useCompleteFileUpload } from './hooks';
import { FileUploadEffectsContext } from './Context';
import type { Effects } from './interfaces';

type Props = {
  onAbort?: (data: WorkspaceFileUpload) => void;
  onComplete?: (data: WorkspaceFileUpload) => void;
  onFailure?: (data: WorkspaceFileUpload) => void;
  onStart?: (data: WorkspaceFileUpload) => void;
  onQueue?: (data: WorkspaceFileUpload) => void;
} & ChildrenProps;

export const BaseFileUploadEffectsContainer = ({
  onAbort,
  onComplete: onCompleteProp,
  onFailure,
  onStart,
  onQueue,
  ...props
}: Props) => {

  const onComplete = useCompleteFileUpload();
  const invalidateQueries = useInvalidateFilesQueries();

  const complete: Effects.OnCompleteUpload.Fn = useCallback(data => {
    return onComplete(data)
      .then(({ fileUpload }) => {
        onCompleteProp?.(fileUpload);
        invalidateQueries();
      });
  }, [
    invalidateQueries,
    onComplete,
    onCompleteProp,
  ]);

  const abort: Effects.OnAbortUpload.Fn = useCallback(data => {
    return $api.abortFileUpload(data)
      .then(({ fileUpload }) => {
        onAbort?.(fileUpload);
      });
  }, [
    onAbort,
  ]);

  const start: Effects.OnStartUpload.Fn = useCallback(data => {
    return $api.startFileUpload(data)
      .then(res => {
        onStart?.(res.fileUpload);
        return res;
      });
  }, [
    onStart,
  ]);

  const failure: Effects.OnFailUpload.Fn = useCallback(data => {
    return $api.failFileUpload(data)
      .then(({ fileUpload }) => {
        onFailure?.(fileUpload);
      });
  }, [
    onFailure,
  ]);

  const queue: Effects.OnQueueUpload.Fn = useCallback(data => {
    return $api.queueFileUpload({
      file: data.file,
      options: data.options,
      parentObjectId: data.parentObjectId,
      uploadIdentifier: data.uploadIdentifier,
      workspaceId: data.workspaceId,
    }).then(res => {
      onQueue?.(res.fileUpload);
      return res;
    });
  }, [
    onQueue,
  ]);

  const value: Effects.FileUploadEffects = useMemo(() => ({
    abort,
    complete,
    failure,
    queue,
    start,
  }), [
    abort,
    complete,
    failure,
    queue,
    start,
  ]);

  return (
    <FileUploadEffectsContext.Provider value={value}>
      {props.children}
    </FileUploadEffectsContext.Provider>
  );
};

export default BaseFileUploadEffectsContainer;