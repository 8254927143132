import { useEffect, useMemo, useState } from 'react';
import * as api from '@api';
import { useSelectUser } from '@containers/Store';
import { useAsyncStateLazy } from '@utils';
import { GDPRPrivacyNotice } from '@/components/Modal/GDPRPrivacyNotice';
import type { PrivacyConsentState } from './Context';
import { PrivacyConsentContext } from './Context';

type Props = {
  children: React.ReactElement;
};

const ConsentManagementContainer = (props: Props) => {
  const user = useSelectUser();
  const [consentId, setConsentId] = useState<number>(null);

  const [_, fetchPrivacyConsentRequest] = useAsyncStateLazy(() => {
    return api.users.consent.findPrivacyConsentRequest()
      .then(data => {

        if (data?.notice?.id) setConsentId(data.notice.id);

        return data;

      });
  }, [setConsentId]);

  useEffect(() => {

    if (user.meta.isEmailVerified && user.meta.isNew) {
      fetchPrivacyConsentRequest();
    }

  }, [
    fetchPrivacyConsentRequest,
    user.meta.isEmailVerified,
    user.meta.isNew,
  ]);

  const ctx: PrivacyConsentState = useMemo(() => {
    return [consentId, () => setConsentId(null)];
  }, [consentId]);

  return (
    <PrivacyConsentContext.Provider value={ctx}>
      {props.children}
      <GDPRPrivacyNotice />
    </PrivacyConsentContext.Provider>
  );
};

export { ConsentManagementContainer };
export default ConsentManagementContainer;