import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { Redirect } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getConferenceDetails } from '@api/conferences';
import { useAppReadyState } from '@containers/AppReadyState';
import { CallRating } from '@/components/Modal/CallFeedback/CallRating';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import styles from './styles.css';

type Props = {
  conferenceId: number;
  redirectLocation: string;
};

export const ConferenceRating = ({ conferenceId, redirectLocation }: Props) => {
  const history = useHistory();
  const { initialized } = useAppReadyState();
  const { data, isLoading, isError } = useQuery(['get-conference-details', conferenceId], () => {
    return getConferenceDetails({ conferenceId });
  }, {
    refetchOnWindowFocus: false,
  });

  const onSubmit = useCallback(() => {
    history.replace(redirectLocation);
  }, [history, redirectLocation]);

  if (isLoading || !initialized) {
    return <ActivityIndicator />;
  }

  if (isError || !data.call) {
    return <Redirect to={redirectLocation} />;
  }

  return (
    <div className={styles.conferenceRoot}>
      <CallRating
        item={data}
        onSubmit={onSubmit} />
    </div>
  );
};