import { useContext, useEffect } from 'react';
import * as Conference from '$admin/components/Conference.Settings';
import { defaultConferenceDetailsContent, useConferenceDetailsEditor } from '@/components/Conference';
import { CallDetailsContext } from './Context';
import * as Layout from './Layout';
import styles from './style/Field.css';

type Props = unknown;

export const ClientNotes = (props: Props) => {
  const ctx = useContext(CallDetailsContext);

  const notes = useConferenceDetailsEditor({
    initialState: ctx.query.data.settings.clientNotes,
    placeholder: `Enter client invitation details`,
  });

  useEffect(() => {

    if (notes.manager.view) {
      notes.manager.view.updateState(notes.manager.createState({
        content: ctx.query.data.settings.clientNotes || defaultConferenceDetailsContent,
      }));
    }

  }, [
    notes.manager,
    ctx.query.data.settings.clientNotes,
  ]);

  return (
    <div className={styles.row}>
      <Layout.Label>
        Client Invitation Details
      </Layout.Label>
      <Conference.Field.ClientNotes
        editing={false}
        manager={notes.manager}
        state={notes.state}
        values={{ clientNotes: ctx.query.data.settings.clientNotes }} />
    </div>
  );
};

ClientNotes.displayName = 'Call.Main.Field.ClientNotes';