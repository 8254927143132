import { isToday, isYesterday, isAfter, addDays, addMonths, isBefore } from 'date-fns';
import type { Chat } from '@/brand-insights/types';

export enum HistoryBucket {
  Shared = 'Shared With You',
  Today = 'Today',
  Yesterday = 'Yesterday',
  Previous7Days = 'Previous 7 Days',
  Previous30Days = 'Previous 30 Days',
  Previous90Days = 'Previous 90 Days',
  OlderThan90Days = 'Older Than 90 Days',

}

export const historyBuckets: HistoryBucket[] = [
  HistoryBucket.Shared,
  HistoryBucket.Today,
  HistoryBucket.Yesterday,
  HistoryBucket.Previous7Days,
  HistoryBucket.Previous30Days,
  HistoryBucket.Previous90Days,
  HistoryBucket.OlderThan90Days,
];

type HistoryFunctionMap = {
  [key in HistoryBucket]: (date: Date) => boolean;
};

const historyFunctionMap: HistoryFunctionMap = {
  [HistoryBucket.Shared]: () => false,
  [HistoryBucket.Today]: isToday,
  [HistoryBucket.Yesterday]: isYesterday,
  [HistoryBucket.Previous7Days]: date => isAfter(date, addDays(new Date(), -7)),
  [HistoryBucket.Previous30Days]: date => isAfter(date, addMonths(new Date(), -1)),
  [HistoryBucket.Previous90Days]: date => isAfter(date, addMonths(new Date(), -3)),
  [HistoryBucket.OlderThan90Days]: date => isBefore(date, addMonths(new Date(), -3)),
};

function isDateInBucket(date: Date, bucket: HistoryBucket) {
  return historyFunctionMap[bucket](date);
}

export function generateHistoryBuckets(items: Chat.Instance[], shared: Chat.Instance[]) {
  const bucketMap = historyBuckets.reduce((map, bucket) => {
    map[bucket] = [];
    return map;
  }, {} as Record<HistoryBucket, Chat.Instance[]>);

  for (const item of items) {
    for (const bucket of historyBuckets) {
      if (isDateInBucket(item.createdOn, bucket)) {
        bucketMap[bucket].push(item);
        break;
      }
    }
  }

  bucketMap[HistoryBucket.Shared] = shared;

  return historyBuckets
    .map(bucket => ({
      bucket,
      items: bucketMap[bucket],
    }))
    .filter(f => f.items.length);
}