import { useMemo } from 'react';
import { AddItemButton } from '@presentation/AddItemButton';
import { SurveyTemplate } from '@/types/survey.templates';
import { TextEntryList } from '@/components/TextEntryList';
import styles from './style/Presets.css';

type Props = {
  canAddCriteria: boolean;
  canRemoveCriteria: boolean;
  criteria: SurveyTemplate.LinkedEntity[];
  onAddCriteria: () => void;
  onRemoveCriteria: (id: string) => () => void;
  onUpdateCriteria: (id: string) => (v: string) => void;
};

export const TemplateCriteriaInput = ({
  canAddCriteria,
  canRemoveCriteria,
  onAddCriteria,
  onRemoveCriteria,
  onUpdateCriteria,
  criteria,
}: Props) => {

  const items = useMemo(() => {
    return criteria.map(m => ({
      key: m.id,
      value: m.value,
    }));
  }, [criteria]);

  return (
    <>
      <TextEntryList
        items={items}
        placeholder="Enter a criteria"
        removeItem={onRemoveCriteria}
        removeItemEnabled={canRemoveCriteria}
        updateItemValue={onUpdateCriteria} />
      {canAddCriteria &&
        <AddItemButton
          className={styles.add}
          label="Add"
          onClick={onAddCriteria} />}
    </>
  );
};

export type { Props as TargetCriteriaInputProps };