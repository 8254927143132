import { Chip } from '@presentation/Chip';
import { cx } from '@utils';
import styles from './style/Profile.Tags.css';

type Item = {
  id: number | string;
  name: string;
};

type Props = {
  className?: string;
  items: Item[];
  onClick: (value: Item) => () => unknown;
};

export const ProfileTagsForm = ({ className, items, onClick }: Props) => {

  return (
    <div className={cx(styles.tags, className)}>
      {items.map(item => (
        <Chip
          key={item.id  || item.name}
          className={styles.tag}
          color='selected'>
          <Chip.Body.Action variant='remove' onClick={onClick(item)}>
            {item.name}
          </Chip.Body.Action>
        </Chip>
      ))}
    </div>
  );
};

export default ProfileTagsForm;