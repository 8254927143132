import { useMemo } from 'react';
import { CURRENCY_FORMATTER_NAMES_LEGACY, CURRENCIES_LEGACY } from '@utils/format';
import { CurrencyLegacy } from '@/types/survey.currency';
import { DropDown } from '@/components/DropDown';

type Props = {
  items?: CurrencyLegacy[];
  onChange: (value: CurrencyLegacy) => void;
  value: CurrencyLegacy;
};

export const CurrenciesDropdown = ({ items = CURRENCIES_LEGACY, onChange, value }: Props) => {

  const dropdownItems = useMemo(() => {
    return items.map(m => ({
      id: m,
      value: CURRENCY_FORMATTER_NAMES_LEGACY[m],
    }));
  }, [items]);

  return (
    <DropDown
      items={dropdownItems}
      getItemValue={i => i.value}
      onSelect={val => onChange(val.id)}
      text={value ? CURRENCY_FORMATTER_NAMES_LEGACY[value] : ''} />
  );
};