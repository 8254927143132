import { WaveSurferParams } from 'wavesurfer.js/types/params';
import { WaveSurferContext } from './context';
import { useWaveSurfer } from './useWaveSurfer';

type Props ={
  params: WaveSurferParams;
} & ChildrenProps;

export const WaveSurferContainer = ({ children, params }: Props) => {

  const ws = useWaveSurfer(params);

  return (
    <WaveSurferContext.Provider value={ws}>
      {children}
    </WaveSurferContext.Provider>
  );
};