import { useReducer, Reducer } from 'react';
import { SurveyAdvancedQuotaStateContext } from './Context';
import { SurveyAdvancedQuotaBuilder } from './interfaces';
import { surveyAdvancedQuotaReducer } from './reducer.advanced';

type Props = {
  initial: SurveyAdvancedQuotaBuilder.State;
} & ChildrenProps;

export const SurveyAdvancedQuotaBuilderState = ({
  children,
  initial,
}: Props) => {

  const reducer = useReducer<Reducer<SurveyAdvancedQuotaBuilder.State, SurveyAdvancedQuotaBuilder.Action>>(surveyAdvancedQuotaReducer, initial);

  return (
    <SurveyAdvancedQuotaStateContext.Provider value={reducer}>
      {children}
    </SurveyAdvancedQuotaStateContext.Provider>
  );
};
