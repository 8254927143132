import { useContext, useMemo } from 'react';
import { ChatSessionSegmentationsContext } from '@/brand-insights/components/Chat/context';

export const useGetPendingSegmentation = () => {
  const segmentations = useContext(ChatSessionSegmentationsContext);

  return useMemo(() => {
    for (let i = segmentations.length - 1; i >= 0; i--) {
      if (segmentations[i].status === 'pending') return segmentations[i];
    }
  }, [segmentations]);
};