import { extend } from './extend';

export enum CallRole {
  Scheduler = 1,
  Respondent = 2,
  Attendee = 3,
  PrimaryRespondent = 4,
  ModeratorAttendee = 5,
}

const map = new Map([
  [CallRole.Scheduler,  { name: `Moderator` }],
  [CallRole.Respondent, { name: `Respondent` }],
  [CallRole.Attendee,   { name: `Attendee` }],
  [CallRole.PrimaryRespondent, { name: `Respondent` }],
  [CallRole.ModeratorAttendee, { name: `Moderator` }],
]);

export default {
  CallRole: extend(map),
};