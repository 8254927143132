import { GrayOutlineButton } from '@presentation/Buttons';
import Modal from '@/components/Modal/Modal.Legacy';
import Button from '@/components/Button';
import styles from './style.css';

type Props = {
  onClose: () => void;
  onSubmit: () => void;
};

const title = `We recommend providing a bit more detail before submitting.`;
const copy = `Clients are more likely to schedule follow-up calls with individuals
  that provide in depth responses to open ended survey questions, but you
  can still submit your responses as is.`;

export const ResponseLengthModal = (props: Props) => (
  <Modal
    blocking={true}
    onClose={props.onClose}
    visible={true}>
    <div className={styles.title}>{title}</div>
    <div className={styles.body}>{copy}</div>
    <div className={styles.footer}>
      <GrayOutlineButton
        className={styles.submit}
        onClick={props.onSubmit}
        title='Submit Anyway' />
      <Button.Primary
        className={styles.review}
        onClick={props.onClose}
        title='Review Answer'
        variant='brick' />
    </div>
  </Modal>
);

export default ResponseLengthModal;