import * as types from '@store/action-types';
import { Calls } from '@store/interfaces';

export const adHocCallCreated = (data: Calls.AdHocCallCreated.State): Calls.AdHocCallCreated.Action => {
  return {
    type: types.ADHOC_CALL_CREATED,
    call: data.call,
  };
};

export const adHocCallUpdated = (data: Calls.AdHocCallUpdated.State): Calls.AdHocCallUpdated.Action => {
  return {
    type: types.ADHOC_CALL_UPDATED,
    call: data.call,
  };
};

export const projectCallCreated = (data: Calls.ProjectCallCreated.State): Calls.ProjectCallCreated.Action => {
  return {
    type: types.PROJECT_CALL_CREATED,
    call: data.call,
  };
};

export const callUpdated = (data: Calls.CallUpdated.State): Calls.CallUpdated.Action => {
  return {
    type: types.CALL_UPDATED,
    ...data,
  };
};