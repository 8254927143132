import * as types from '@store/action-types';
import { routing as initialState } from '@store/initial-state';

const updateGA = function() {
  const gtag = window.gtag;
  if (gtag) {
    gtag('config', process.env.GA_TRACKING_ID, {
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }
};

function routingReducer(state = initialState, event) {
  switch (event.type) {

    case types.UPDATE_LOCATION: {
      const { redirect, referrer, type, ...rest } = event;

      return {
        ...state,
        redirect,
        referrer,
        ...rest,
      };
    }

    case types.LOCATION_CHANGE: {
      updateGA();
      const { action, location } = event;

      if (action === 'PUSH' || action === 'INIT') {
        return {
          routes: state.routes.concat(location),
        };
      } else if (action === 'POP') {
        return {
          routes: state.routes.slice(0, state.routes.length - 1),
        };
      } else if (action === 'REPLACE') {
        return {
          routes: [...state.routes.slice(0, state.routes.length - 1), location],
        };
      }

      return state;
    }

    case types.POP_FROM_HISTORY: {
      updateGA();
      return {
        routes: state.routes.slice(0, state.routes.length - 1),
      };
    }

    default:
      return state;
  }
}

export default routingReducer;