import { useCallback, useContext } from 'react';
import * as api from '@api';
import { SearchLoadingDispatchContext, SearchResultsDispatchContext } from '@/containers/WorkspaceGlobalSearch/Context';
import type { WorkspaceSearchFull } from '@/types';

export default function useSearchFetchMembers() {
  const setResults = useContext(SearchResultsDispatchContext);
  const setLoading = useContext(SearchLoadingDispatchContext);

  const members = useCallback(async ({ filters, size }: FetchData) => {
    const result = await api.workspaces.globalSearch.members({
      filters,
      size,
    });

    if (result) {
      setResults(prev => ({
        ...prev,
        members: result,
      }));
    }
  }, [setResults]);

  const fetch = useCallback(({ filters, size }: FetchData) => {
    setLoading(prev => ({ ...prev, members: { ...prev.members, loading: true } }));
    Promise.all([
      new Promise<void>(resolve => setTimeout(() => resolve(), 250)),
      members({ filters, size }),
    ])
    .then(() => {
      setLoading(prev => ({ ...prev, members: { loading: false } }));
    });
  }, [members, setLoading]);

  return fetch;
}

export { useSearchFetchMembers };

type FetchData = {
  filters: WorkspaceSearchFull.Member.Filters;
  size: number;
};