import { useCallback, useContext } from 'react';
import { TranscriptCommentsStateContext } from '@containers/Transcript.Commenting/Context';

export const useGetCommentsForHighlight = () => {

  const [state] = useContext(TranscriptCommentsStateContext);

  return useCallback((highlightIdentifier: string) => {
    return state.items
      .filter(f => f.highlight.identifier === highlightIdentifier);

  }, [state.items]);

};