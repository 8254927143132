import { useEffect } from 'react';
import { useAsyncStateLazy } from '@utils';
import * as api from '$admin/api';

export default function useFetchGroupUsers(groupId: number) {
  const [response, fetch] = useAsyncStateLazy(() => {
    return api.groups.getGroupUsers({ groupId })
      .then(data => data.items);
  }, [groupId]);

  useEffect(() => {
    if (groupId) {
      fetch();
    }
  }, [
    fetch,
    groupId,
  ]);

  return [response, fetch] as const;
}

export { useFetchGroupUsers };