import { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useDimensions } from '@utils';

type Options = {
  containerWidth:      number;
  computeTotalVisible: (width: number) => number;
};

const useCarouselNavigation = (count = 0, options: Options) => {
  const [itemSize, setItemSize] = useState(options.computeTotalVisible(options.containerWidth));

  const maxPosition = useMemo(() => {
    const max = count - itemSize;
    return max > -1 ? max : 0;
  }, [
    count,
    itemSize,
  ]);
  const [position, setPosition] = useState(0);

  const backEnabled = useMemo(() => {
    return position > 0;
  }, [position]);

  const nextEnabled = useMemo(() => {

    return position < count - itemSize && position > -1;

  }, [
    count,
    position,
    itemSize,
  ]);

  useEffect(() => {
    const t = options.computeTotalVisible(options.containerWidth);

    setItemSize(t);

  }, [options]);

  useEffect(() => {

    if (position > maxPosition) {
      setPosition(maxPosition);
    }

  }, [
    options.containerWidth,
    position,
    maxPosition,
  ]);

  const back = useCallback(() => {
    if (backEnabled) {
      setPosition(position - 1);
    }
  }, [
    position,
    backEnabled,
  ]);

  const next = useCallback(() => {
    if (nextEnabled) {
      setPosition(position + 1);
    }
  }, [
    nextEnabled,
    position,
  ]);

  return {
    back,
    canGoBack: backEnabled,
    canGoNext: nextEnabled,
    itemSize,
    next,
    position,
  };

};

const useCarouselItemWidth = <T extends HTMLDivElement>() => {
  const ref = createRef<T>();
  const [width, setWidth] = useState(0);

  const calc = useCallback((params: Params) => {

    if (ref.current) {
      const calculated = ref.current.offsetWidth / params.size;

      setWidth(calculated);
    }

  }, [ref]);

  return [ref, width, calc] as const;

};

export const useCarousel = (count = 0, options: Omit<Options, 'containerWidth'>) => {
  const dimensions = useDimensions();
  const [containerWidth, setContainerWidth] = useState(0);
  const [ref, width, calc] = useCarouselItemWidth();

  useEffect(() => {
    if (ref.current) {
      setContainerWidth(ref.current.offsetWidth);
      calc({ size: options.computeTotalVisible(ref.current.offsetWidth) });
    }
  }, [
    calc,
    dimensions,
    options,
    ref,
  ]);

  const navigation = useCarouselNavigation(count, {
    containerWidth,
    computeTotalVisible: options.computeTotalVisible,
  });

  return [ref, width, navigation] as const;
};

type Params = {
  size: number;
};