import { useProfileFeaturedPosts } from '@utils/api';
import { AccountProfileFeaturePostsContext, AccountProfileFeaturePostsLoadingContext } from './Context';

type Props =
  IUserId &
  ChildrenProps;

export const ProfileFeaturedPostsContainer = (props: Props) => {
  const { refetch, ...data } = useProfileFeaturedPosts(props.userId, {
    refetchOnWindowFocus: false,
  });

  return (
    <AccountProfileFeaturePostsContext.Provider value={data.data}>
      <AccountProfileFeaturePostsLoadingContext.Provider value={data.isLoading}>
        {props.children}
      </AccountProfileFeaturePostsLoadingContext.Provider>
    </AccountProfileFeaturePostsContext.Provider>
  );
};

export default ProfileFeaturedPostsContainer;