import FormControlLabel from '@mui/material/FormControlLabel';
import { forwardRef, useMemo } from 'react';
import { cx } from '@utils';
import { Input } from '@/components/Input';
import RadioButton from './RadioButton';
import { RadioGroupOptionProps } from './interfaces';
import styles from './style.css';

export default function RadioGroupOption(props: RadioGroupOptionProps) {

  const showInput = useMemo(() => props.allowText && props.isSelected, [props.allowText, props.isSelected]);

  return (
    <>
      <FormControlLabel
        className={cx(styles.option, props.className)}
        sx={{
          marginLeft: 0,
          alignItems: 'flex-start',
          [`& .MuiFormControlLabel-label`]: {
            fontSize: '16px',
            fontFamily: 'var(--font-reg)',
            width: '100%',
          },
        }}
        disabled={props.disabled}
        control={<RadioButton sx={{ marginRight: '10px' }} />}
        label={showInput ? (
          <InputLabel
            label={props.label}
            onInputChange={props.onInputChange}
            text={props.text} />
        ): props.label}
        key={props.value}
        value={props.value} />
    </>
  );
}

const InputLabel = forwardRef<HTMLDivElement, LabelProps>(({ label, onInputChange, text }, ref) => {

  return (
    <div className={styles.openEnded}>
      <div className={styles.label}>{label}</div>
      <Input
        name={`${label}-text`}
        onChange={onInputChange}
        placeholder="Enter additional text"
        value={text || ''} />
    </div>
  );
});

type LabelProps = {
  label: string;
  text: string;
} & Pick<RadioGroupOptionProps, 'onInputChange'>;

export { RadioGroupOption };