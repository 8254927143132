import { useState, useCallback, useEffect, useContext } from 'react';
import { ProjectSocketEvent } from '@/enums';
import { useProjectsSocketEvent, projects as $projectsWs } from '@/services/websocket';
import * as consts from '@consts';
import type { Projects } from '@services/websocket/interfaces';
import type { SkippedReplacements } from '@/types/transcript.cleanup';
import { Modal, Header } from '@/components/Modal';
import { Button } from '@/components/Button';
import Toast from '@/components/Toast';
import { WebSocketContext, RouteGuardContext } from './Context';

type Props = IProjectId & ChildrenProps;

export const SocketContainer = (props: Props) => {
  const [eventKey, setEventKey] = useState<string>();
  const [skippedReplacements, setSkippedReplacements] = useState<SkippedReplacements>();
  const { setSkipGuard } = useContext(RouteGuardContext);

  const handler: Projects.Handler<Projects.TranscriptCleanupEvent.Payload> = useCallback(event => {
    if (event.eventKey === eventKey) {
      if (event.success) {
        if (Object.values(event.result.skippedReplacements).flat().length > 0) {
          setSkippedReplacements(event.result.skippedReplacements);
        } else {
          setSkipGuard();
          setTimeout(() => window.location.reload(), 100);
        }
      } else {
        Toast.error({
          title: 'There was an error saving your transcript edits',
        });
      }

      setEventKey(null);
    } else {
      //Maybe tell the user someone else has saved and their edits might not be valid
    }
  }, [eventKey, setSkipGuard]);

  useProjectsSocketEvent(ProjectSocketEvent.TranscriptCleanupEvent, handler);

  useEffect(() => {
    $projectsWs.open();
    $projectsWs.subscribe(props.projectId);

    return () => {
      $projectsWs.unsubscribe(props.projectId);
      $projectsWs.close();
    };
  }, [props.projectId]);

  return (
    <WebSocketContext.Provider value={{ eventKey, setEventKey }}>
      {props.children}
      {skippedReplacements && <SkippedReplacementsModal skippedReplacements={skippedReplacements} />}
      {eventKey && <SavingModal />}
    </WebSocketContext.Provider>
  );
};

type SkippedReplacementsModalProps = {
  skippedReplacements: SkippedReplacements;
};
const SkippedReplacementsModal = (props: SkippedReplacementsModalProps) => {
  return (
    <Modal
      open={!!props.skippedReplacements}
      blocking={true}>
      <Header>There were some replacements we were unable to apply:</Header>
      <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
        {Object.entries(props.skippedReplacements ?? {}).map(([k, replacements]) => (
          <div key={k}>
            <div>
              TranscriptId: {k}
            </div>
            <div>
              {replacements.map(r => (
                <div key={k + '-' + r.docPos.from.toString()}>
                  {`${r.expectedText} -> ${r.replacementText} (${r.docPos.from}, ${r.docPos.to})`}
                </div>
              ))}
            </div>
          </div>
        ))
        }
      </div>
      <div>{`Please refresh the page and try again. If the problem persists please contact ${consts.emails.EngineeringSupportEmail}.`}</div>
      <div>
        <Button variant='brick' onClick={() => window.location.reload()}>
          Refresh
        </Button>
      </div>
    </Modal>
  );
};

const SavingModal = () => {
  return (
    <Modal
      open={true}
      blocking={true}
      disableOverlayClick={true}>
      <Header>{`We're saving your transcript edits...`}</Header>
    </Modal>
  );
};