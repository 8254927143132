/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import styled from '@emotion/styled';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

type SelectionTagProps = {
  id: number;
  label: string;
  checked: boolean;
  occurrences: number;
  onChange: (id: number, checked: boolean) => unknown;
  readonly: boolean;
};

export const SegmentationTagSelectionTag = (props: SelectionTagProps) => {
  const { onChange, id } = props;

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(id, event.target.checked);
  }, [id, onChange]);

  return (
    <Root>
      <StyledFormControlLabel
          control={(
            <StyledCheckbox
              name={`tag-${props.id}`}
              checked={props.checked}
              onChange={handleChange}
              size="small"
              disabled={props.readonly}
              disableRipple />
          )}
          label={(
            <CheckboxLabel {...props} />
          )} />
    </Root>
  );
};

const CheckboxLabel = ({ label, occurrences }: SelectionTagProps) => {
  return (
    <Label>
      <LabelText>{label}</LabelText>
      <LabelOccurrences>{`(${occurrences} occurrences)`}</LabelOccurrences>
    </Label>
  );
};

const Root = styled.div`
  box-sizing: border-box;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 0;
`;

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: '5px',
  '&.Mui-checked': {
    color: theme.palette.blue.main,
  },
}));

const Label = styled.div`
  font-size: 15px;
  font-family: ${props => props.theme.fonts.regular};
`;

const LabelText = styled.span`
`;

const LabelOccurrences = styled.span`
  color: ${props => props.theme.palette.gray.main};
  margin-left: 4px;
`;