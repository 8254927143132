import { useEffect, useState } from 'react';
import type { Socket } from 'socket.io-client';
import { SocketNamespace } from '@/brand-insights/enums';
import { getSocket } from '@/brand-insights/websocket/socket';
import { useSetupDefaultSocketListeners } from './useSetupDefaultListeners';

export const useChatSocket = () => {
  const [socket, setSocket] = useState<Socket>();

  useEffect(() => {
    const newSocket = getSocket(SocketNamespace.Chat);

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  useSetupDefaultSocketListeners(socket, SocketNamespace.Chat);

  return socket;
};