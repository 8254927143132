import { useMemo } from 'react';
import { ProjectType, utils } from '@enums';

export const useMethodologyOptions = (surveyProjects = true) => {
  const options = useMemo(() => {

    const all = [
      { id: ProjectType.SurveyCall, name: utils.ProjectType.getName(ProjectType.SurveyCall) },
      { id: ProjectType.Call, name: utils.ProjectType.getName(ProjectType.Call) },
      { id: ProjectType.Survey, name: utils.ProjectType.getName(ProjectType.Survey) },
    ];

    if (!surveyProjects) {
      return [all.find(x => x.id === ProjectType.Call)];
    }

    return all;

  }, [surveyProjects]);

  return options;
};