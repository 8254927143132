import { useMemo } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CellPost } from '../Cells';

export default function usePostsTableColumns() {
  return useMemo(() => [
    {
      accessor: 'name',
      id: 'name',
      Header: 'Name',
      Cell: CellPost,

    },
  ].filter(Boolean), []);
}

export { usePostsTableColumns };