import { useCallback } from 'react';
import { cx } from '@utils';
import { SurveyMetrics } from '@/types';
import { ConditionsPopper } from './ConditionsPopover';
import styles from './style/Table.css';

type Props = {
  items: SurveyMetrics.BasicQuotaProgress[];
};

export const BasicQuotasProgressTable = ({ items }: Props) => {

  const renderRow = useCallback((item: SurveyMetrics.BasicQuotaProgress) => {
    const limitHit = item.total >= item.quota.limit;
    return (
      <tr key={item.quota.id}>
        <td>{item.quota.name}</td>
        <td>
          <ConditionsPopper
            items={item.match.conditions}
            rule={item.match.rule} />
        </td>
        <td className={cx({
          [styles.limitHit]: limitHit,
        })}>{item.total}</td>
        <td>{item.quota.limit}</td>
      </tr>
    );
  }, []);

  return (
    <table className={cx(styles.table, styles.basic)}>
      <thead>
        <tr>
          <th>Name</th>
          <th>Condition</th>
          <th>Current</th>
          <th>Max</th>
        </tr>
      </thead>

      <tbody>
        {items.map(renderRow)}
      </tbody>
    </table>
  );
};

export default BasicQuotasProgressTable;