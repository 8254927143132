import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import * as consts from '@consts';
import { useSurveyThemingPalette } from '@containers/Branding/hooks/useSurveyThemingPalette';
import { useIsProjectTypeSurveyLike } from '@containers/Store';
import { getLocationFor } from '@utils';

export const useAbortOnboardingRedirect = (projectId: number) => {
  const history = useHistory();
  const { theme } = useSurveyThemingPalette();
  const isSurveyLike = useIsProjectTypeSurveyLike(projectId);

  const handleSurveyArchived = useCallback(() => {
    const loc = getLocationFor.onboarding.project({ projectId });

    theme
      ? history.replace(`${loc.pathname}/closed`)
      : history.replace(consts.path.Onboarding.Projects.Archived);

  }, [
    history,
    projectId,
    theme,
  ]);

  const handleSurveyDisqualification = useCallback(() => {
    const loc = getLocationFor.onboarding.project({ projectId });

    theme
      ? history.replace(`${loc.pathname}/end`)
      : history.replace(consts.path.Onboarding.Projects.Disqualified);
  }, [
    history,
    projectId,
    theme,
  ]);

  const redirect = useCallback((disqualified: boolean) => {
    isSurveyLike
      ? disqualified
        ? handleSurveyDisqualification()
        : handleSurveyArchived()
      : history.replace(consts.pathname.Home);
  }, [
    handleSurveyArchived,
    handleSurveyDisqualification,
    history,
    isSurveyLike,
  ]);

  return redirect;
};