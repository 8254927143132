import { useCallback } from 'react';
import { SurveyTaggingResultType, utils as enumUtils } from '@enums';
import { SurveyTagging } from '@/types';
import { generateDefaultTaggingAction, useSurveyTaggingItemState } from '@/containers/SurveyBuilder.Tagging';
import { TaggingActionTypeDropdown } from '@presentation/SurveyBuilder';
import styles from './style/Builder.Action.css';

export const TaggingBuilderActionDropdown = () => {

  const [item, dispatch] = useSurveyTaggingItemState();

  const updateAction = useCallback((value: SurveyTagging.Action) => {
    dispatch({
      type: 'action-updated',
      payload: value,
    });
  }, [dispatch]);

  const handleUpdate = useCallback((value: SurveyTaggingResultType) => {
    const action = generateDefaultTaggingAction(value);
    updateAction(action);
  }, [updateAction]);

  return (
    <div className={styles.actionDropdown}>
      <TaggingActionTypeDropdown
        items={enumUtils.SurveyTaggingResultType.values()}
        onSelect={handleUpdate}
        value={item.action.type} />
    </div>
  );
};