import { useCallback, useContext, useMemo } from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import type { ComponentType } from 'react';
import type { GroupProjectRouteParams, TabRouteItem } from '@containers/GroupProject/interfaces';
import { RouteKey } from '@containers/GroupProject/interfaces';
import { useGroupProjectTabViewState } from '@containers/GroupProject/hooks/useGroupProjectTabViewState';
import { ProjectCalls } from '@screens/Project.Calls';
import { ProjectDisplayrDashboard } from '@screens/Project.DisplayrDashboard';
import { ProjectFiles } from '@screens/Project.Files';
import { ProjectClips } from '@screens/Project.Clips';
import { ProjectPipeline } from '@screens/Project.Pipeline';
import { ProjectSummary } from '@screens/ProjectSummary';
import { ProjectTags } from '@screens/Project.Tags/Project.Tags';
import { ProjectTerms } from '@screens/Project.Terms';
import { SurveyResponsesContainer } from '@containers/GroupProject/SurveyResponsesContainer';
import { GroupProjectDetails } from '@screens/ProjectDetails';
import { ProjectQuantAnalysis } from '@screens/Project.QuantAnalysis';
import { ProjectTabsContext } from '@/containers/GroupProject/Context';
import { TabView } from '@/components/TabView';
import styles from './style/ProjectHeader.css';

const ProjectTabView = () => {

  const tabRoutes = useContext(ProjectTabsContext);
  const match = useRouteMatch<GroupProjectRouteParams>();
  const keys = useMemo(() => tabRoutes.map(m => m.key), [tabRoutes]);
  const [{ index }, updateLocation] = useGroupProjectTabViewState();
  const location = useLocation();

  const defaultRoute = useMemo(() => {
    const key = keys[0];
    return {
      pathname: `/projects/${match.params.slug}/${key}`,
      state: location.state,
    };
  }, [match.params.slug, keys, location.state]);

  const getRouteComponent = useCallback((tab: TabRouteItem) => {
    if (tab.key.startsWith('quant-analysis')) {
      return ProjectQuantAnalysis;
    }

    return routeComponentsMap[tab.key];
  }, []);

  const renderTabView = useCallback(() => {
    return (
      <Switch>
        {tabRoutes.map(tab =>
          <Route
            key={tab.key}
            path={tab.path}
            component={getRouteComponent(tab)} />)}
        <Redirect to={defaultRoute} />
      </Switch>
    );
  }, [
    defaultRoute,
    getRouteComponent,
    tabRoutes,
  ]);

  const routes = useMemo(() => {
    return tabRoutes.map(tab => ({
      key: tab.key,
      title: tab.title,
    }));
  }, [tabRoutes]);

  return (
    <TabView
      classes={{
        tab: styles.tab,
      }}
      index={index}
      onIndexChange={updateLocation}
      routes={routes}
      renderTabView={renderTabView} />
  );
};

const routeComponentsMap: Record<string, ComponentType> = {
  [RouteKey.Calls]: ProjectCalls,
  [RouteKey.Dashboard]: ProjectDisplayrDashboard,
  [RouteKey.Details]: GroupProjectDetails,
  [RouteKey.Entities]: ProjectTerms,
  [RouteKey.Highlights]: ProjectClips,
  [RouteKey.Pipeline]: ProjectPipeline,
  [RouteKey.Summary]: ProjectSummary,
  [RouteKey.SurveyResponses]: SurveyResponsesContainer,
  [RouteKey.Tags]: ProjectTags,
  [RouteKey.Files]: ProjectFiles,
};

export { ProjectTabView };
export default ProjectTabView;