import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useRemirrorContext } from '@remirror/react';
import * as api from '@api';
import type * as API from '@api/interfaces';
import { RefetchTranscriptContext } from '@containers/Transcript/context';

type UseEditSpeakerMutationProps = {
  onSuccess?: () => void;
} & ITranscriptId;

export const useEditSpeakerSelectionMutation = ({ onSuccess, transcriptId }: UseEditSpeakerMutationProps) => {
  const refetch = useContext(RefetchTranscriptContext);
  const { manager } = useRemirrorContext();

  const mutation = useMutation<M.Res, M.E, M.Req>({
    mutationKey: [
      `put:transcripts/speakers/selection`,
      transcriptId,
    ],
    mutationFn: params => {
      return api.transcripts.updateTranscriptSpeakerSelection({
        speakerIndex: params.speakerIndex,
        transcriptId,
        ts: {
          end: params.end,
          start: params.start,
        },
      })
      .then(async data => {
        const res = await refetch();

        manager.view.updateState(manager.createState({
          content: res.data.transcript.document,
        }));

        onSuccess?.();

        return data;
      });
    },
  });

  return mutation;
};

namespace M {
  export type Res = API.Transcripts.UpdateTranscriptSpeakerSelection.Response;
  export type E = unknown;
  export type Req = {
    end:          number;
    speakerIndex: number;
    start:        number;
  };
}