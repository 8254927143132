import { SurveyLogic } from '@/types/survey';
import { useSurveyLogicBuilderDisplaySettings } from '@/containers/SurveyBuilder.Logic';
import { LogicItemContextMenu } from './Preview.ContextMenu';
import { SurveyAction } from './Preview.Action';
import styles from './style/Preview.css';
import { LogicBuilderNamePreview } from './Preview.Name';
import { SurveyLogicMatch } from './Preview.Match';

type Props = {
  hideContextMenu: boolean;
  item: SurveyLogic.Item;
  ordinal: number;
};

export const SurveyLogicItemPreview = ({ hideContextMenu, item, ordinal }: Props) => {

  const settings = useSurveyLogicBuilderDisplaySettings();

  return (
    <div className={styles.root}>
      {!hideContextMenu &&
        <LogicItemContextMenu
          className={styles.menu}
          item={item} />}

      <LogicBuilderNamePreview
        ordinal={ordinal}
        item={item} />

      {!settings.hideActions &&
        <div className={styles.row}>
          <div className={styles.label}>Action:</div>
          <SurveyAction action={item.action} />
        </div>
      }

      <SurveyLogicMatch
        match={{
          conditions: item.conditions,
          rule: item.rule,
        }} />
    </div>
  );
};