import { useContext } from 'react';
import { SearchQueryContext } from '../Context';

type Params = unknown;

const useSearchQueryState = () => {
  return useContext(SearchQueryContext);
};

export { useSearchQueryState };
export default useSearchQueryState;