import { SurveyLogic } from '@/types/survey';
import { SurveyConditionType, SurveySumConditionItemType } from '@/enums';
import { ConditionsBuilder } from './interfaces';

type State = ConditionsBuilder.State;

function updateSumCondition(state: State, identifier: string, updater: SumConditionUpdater): State {
  return state.reduce<State>((acc, x) => {

    return x.identifier === identifier
      ? acc.concat(updater(x as SurveyLogic.SumCondition))
      : acc.concat(x);

  }, []);
}

type SumConditionUpdater = (value: SurveyLogic.SumCondition) => SurveyLogic.SumCondition;

export function sumOperatorUpdated(state: State, { payload }: ConditionsBuilder.SumConditionOperatorUpdated.Action): State {

  const updater = (value: SurveyLogic.SumCondition): SurveyLogic.SumCondition => {
    return {
      ...value,
      conditionType: SurveyConditionType.AggregatedSum,
      data: {
        ...value.data,
        operator: payload.value,
      },
    };
  };

  return updateSumCondition(state, payload.identifier, updater);
}

export function sumComparateUpdated(state: State, { payload }: ConditionsBuilder.SumConditionComparateUpdated.Action): State {

  const updater = (value: SurveyLogic.SumCondition): SurveyLogic.SumCondition => {
    return {
      ...value,
      conditionType: SurveyConditionType.AggregatedSum,
      data: {
        ...value.data,
        comparate: payload.value,
      },
    };
  };

  return updateSumCondition(state, payload.identifier, updater);
}

export function sumItemsUpdated(state: State, { payload }: ConditionsBuilder.SumConditionItemsUpdated.Action): State {

  const updater = (value: SurveyLogic.SumCondition): SurveyLogic.SumCondition => {
    return {
      ...value,
      conditionType: SurveyConditionType.AggregatedSum,
      data: {
        ...value.data,
        items: payload.value,
      },
    };
  };

  return updateSumCondition(state, payload.identifier, updater);
}