import { memo } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { cx } from '@utils';
import { PopperMenuItem } from '@/components/Popper';
import styles from './style/ContextMenu.css';

export type ContextMenuItemProps = {
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  children: string | JSX.Element;
  onClick: () => void;
  Modal?: () => JSX.Element;
};

export const ContextMenuItem = memo(({ checked, children, className: classNameProp, disabled, onClick }: ContextMenuItemProps) => {
  const className = cx({
    [styles.leftPad]: !checked,
    [styles.checked]: checked,
  }, classNameProp);

  return (
    <PopperMenuItem
      disabled={disabled}
      className={className}
      onClick={onClick}>
      <span className={styles.check}>
        {checked && <CheckIcon
          className={styles.checkmark}
          fontSize="small" />} {children}
      </span>
    </PopperMenuItem>
  );
});