import { ClickAwayListener } from '@mui/material';
import type { CellContext } from '@tanstack/react-table';
import { useCallback, useRef, useState } from 'react';
import { Check } from 'react-feather';
import { usePopper } from 'react-popper';
import { PopperMenu, PopperMenuItem, useZIndexModifier } from '@/components/Popper';
import { Portal } from '@/components/Portal';
import { MoreHorizontalAnchorSmall } from '@/presentation/Anchor';
import { useDownloadTranscriptClip } from '@/containers/Transcript/hooks';
import type { ProjectClips } from '@/types/project.clips';
import { useCopyToClipboard } from '@utils';
import styles from './style/Cell.Actions.css';

type Props = CellContext<ProjectClips.Item, unknown>;

export const ActionsCell = ({ row }: Props) => {

  return (
    <ActionsContextMenu
      monologues={row.original.content}
      range={{
        start: row.original.range.start,
        end: row.original.range.end,
      }}
      transcriptId={row.original.transcript.id} />
  );
};

type MenuProps = {
  range: {
    start: number;
    end: number;
  };
  transcriptId: number;
  monologues: ProjectClips.Monologue[];
};

const ActionsContextMenu = (props: MenuProps) => {

  const [open, setOpen] = useState(false);

  const { download: downloadClip } = useDownloadTranscriptClip({
    transcriptId: props.transcriptId,
    fromTs: props.range.start,
    toTs: props.range.end,
  });

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);

  const zIndexModifier = useZIndexModifier({ zIndex: 11 });
  const { styles: popperStyles } = usePopper(referenceElement, popperElement, {
    modifiers: [zIndexModifier],
    placement: 'bottom-start',
  });

  const handleBlur = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleDownloadClip = useCallback(() => {
    downloadClip({
      extension: `mp4`,
      name: `Transcript Clip`,
    });
    setOpen(false);
  }, [downloadClip]);

  const {
    showCopied,
    copy,
  } = useCopy();
  const handleCopy = useCallback(async () => {
    const content = props.monologues
      .map(({ speaker, text }) => `${speaker.name || `Speaker ${speaker.index}`}:\n${text}`)
      .join('\n\n');
    await copy(content);
  }, [copy, props.monologues]);

  return (
    <>
      <div
        ref={setReferenceElement}
        onClick={handleClick}>
        <MoreHorizontalAnchorSmall
          open={open} />
      </div>
      {open &&
        <Portal>
          <ClickAwayListener
            mouseEvent='onMouseDown'
            onClickAway={handleBlur}
            touchEvent='onTouchStart'>
            <div
              ref={setPopperElement}
              style={popperStyles.popper}>
              <PopperMenu>
                <PopperMenuItem onClick={handleDownloadClip}>
                  Download Clip
                </PopperMenuItem>
                <PopperMenuItem className={styles.item} onClick={handleCopy}>
                  {showCopied
                    ? <Check className={styles.check} />
                    : null}
                  {showCopied ? 'Copied!' : 'Copy Text'}
                </PopperMenuItem>
              </PopperMenu>
            </div>
          </ClickAwayListener>
        </Portal>
      }
    </>
  );
};

const useCopy = () => {
  const [showCopied, setShowCopied] = useState<boolean>(false);
  const timer = useRef<NodeJS.Timeout>();
  const copyToClipboard = useCopyToClipboard();

  const handleCopy = useCallback(async (value: string) => {
    setShowCopied(true);
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => setShowCopied(false), 2000);
    await copyToClipboard(value);
  }, [copyToClipboard]);

  return { showCopied, copy: handleCopy };
};