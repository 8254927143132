import type { Theme } from '@nivo/core';

export const theme: Theme = {
  axis: {
    legend: {
      text: {
        fill: 'var(--gray-d)',
        fontFamily: 'var(--font-semibold)',
      },
    },
    ticks: {
      line: {
        display: 'none',
      },
      text: {
        fontSize: 10,
        fontFamily: 'var(--font-reg)',
        fill: 'var(--gray-d)',
        padding: 5,
      },
    },
  },
  labels: {
    text: {
      fontFamily: 'var(--font-semibold)',
    },
  },
  tooltip: {
    container: {
      padding: null,
    },
  },
};

export const defs = [{
  id: 'NA',
  type: 'patternLines',
  background: 'var(--pri-01)',
  color: 'var(--gray-d)',
  spacing: 5,
  rotation: 135,
  lineWidth: 1,
}];