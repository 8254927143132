import { useCallback, useState } from 'react';
import { SelectInput } from './SelectInput';
import SelectList from './SelectList';
import type { SelectBaseProps } from './interfaces';

type Props<T> = {
  className?: string;
  inputValueClassName?: string;
  optionClassName?: string;
} & SelectBaseProps<T>;

const SelectBase = <T, >({ onSelect, onChange, getItemValue, ...props }: Props<T>) => {
  const [open, setVisibility] = useState(false);
  const [selected, setSelected] = useState<React.ReactNode>('');

  const toggleList = useCallback(() => {
    setVisibility(!open);
  }, [open, setVisibility]);

  const handleBlur = useCallback(() => {
    if (open) toggleList();
  }, [open, toggleList]);

  const handleSelect = useCallback((item: T) => {

    onSelect(item);

    setSelected(getItemValue(item));

  }, [setSelected, onSelect, getItemValue]);

  const items = onChange
    ? onChange(props.options)
    : props.options;

  const value = props.value || props.value === 0
    ? props.value
    : selected;

  return (
    <>
      <SelectInput
        autoFocus={props.autoFocus}
        className={props.className}
        inputValueClassName={props.inputValueClassName}
        onBlur={handleBlur}
        onClick={toggleList}
        defaultValue={props.defaultValue}
        placeholder={props.placeholder}
        value={value} />
      <SelectList
        className={props.optionClassName}
        open={open}
        getOptionItem={getItemValue}
        items={items}
        onMouseDown={handleSelect} />
    </>
  );
};

export { SelectBase };
export default SelectBase;