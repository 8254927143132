import { useContext } from 'react';
import { CallDetailsContext } from './Context';
import * as Layout from './Layout';
import styles from './style/Field.css';

type Props = unknown;

export const Project = (props: Props) => {
  const ctx = useContext(CallDetailsContext);

  return (
    <div className={styles.row}>
      <Layout.Label>Project</Layout.Label>
      {ctx.query.data.project.name}
    </div>
  );
};

Project.displayName = 'Call.Main.Field.Project';