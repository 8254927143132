import { useCallback, useContext, useMemo, useState, useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { useEditorView } from '@remirror/react';
import { TranscriptCommentsRefPointContext, TranscriptCommentsStateContext } from '@containers/Transcript.Commenting/Context';
import { TranscriptSidebarPositionsContext } from '@/containers/Transcript/context.sidebar';
import { useTranscriptTagsContext } from '@containers/Transcript.Tagging/hooks';
import type { TranscriptComment, TaggedMoment } from '@/types/transcribe.rich-text';
import { arr } from '@utils';
import { Portal } from '@/components/Portal';
import { TextReplacePopperContext } from './context';
import { useHelpers, useMapTimePosToDocPos } from './hooks';
import { SidebarComments } from './Comments.HighlightContainer';
import { SidebarTag } from './Sidebar.Tag';
import { SidebarItemContainer } from './Sidebar.ItemContainer';
import { TextReplacePopperContainer } from './TextReplace.Popper.Container';
import { getRemirrorCoords } from './utils';

export const TranscriptSidebar = () => {

  const { bounds } = useContext(TranscriptCommentsRefPointContext);
  const [sidebarPositions] = useContext(TranscriptSidebarPositionsContext);

  const observer = useSidebarObserver();

  const sortedItems = useSortedSidebarItems();
  const { getHighlightPosition } = useHelpers();
  const view = useEditorView();
  const mapTimePosToDocPos = useMapTimePosToDocPos();

  const highlightPositions = useMemo(() => {
    return sortedItems.reduce((acc, h) => {
      let position: number;
      if (h.type === 'tag') {
        const docPos = mapTimePosToDocPos(h.tag);
        if (docPos) {
          position = getRemirrorCoords(view, docPos.from);
        }
      } else {
        position = getHighlightPosition(h.highlight.identifier);
      }
      acc[h.highlight.identifier] = position;
      return acc;
    }, {} as {
      [highlightId: string]: number;
    });
  }, [
    getHighlightPosition,
    sortedItems,
    mapTimePosToDocPos,
    view]);

  const renderItem = useCallback((item: SidebarItem) => {
    if (item.type === 'comment') {
      return (
        <SidebarComments
          highlight={item.highlight}
          comments={item.comments} />
      );
    } else if (item.type === 'tag') {
      return (
        <SidebarTag
          tag={item.tag} />
      );
    } else if (item.type === 'replace') {
      return (
        <TextReplacePopperContainer />
      );
    }
  }, []);

  const renderItems = useCallback(() => {

    let y = bounds.top;

    const bottomPadding = 20;

    return sortedItems.map(m => {
      const hp = highlightPositions[m.highlight.identifier];

      if (!hp) return null;

      y = Math.max(y, hp);

      const position = {
        width: bounds.width,
        x: bounds.left,
        y,
      };

      const containerHeight = sidebarPositions[m.highlight.identifier]?.height || 0;

      y = y + containerHeight + bottomPadding;

      return (
        <SidebarItemContainer
          key={m.highlight.identifier}
          observer={observer}
          position={position}
          treatAsTool={m.type === 'replace'}
          itemIdentifier={m.highlight.identifier}>
          {renderItem(m)}
        </SidebarItemContainer>
      );
    }).filter(Boolean);

  }, [
    bounds,
    sidebarPositions,
    highlightPositions,
    observer,
    sortedItems,
    renderItem,
  ]);

  return (
    <Portal>
      <div>
        {bounds.width &&
          renderItems()}
      </div>
    </Portal>
  );
};

const useSidebarObserver = () => {
  const [, setSidebarPosition] = useContext(TranscriptSidebarPositionsContext);

  const [observer] = useState(() => new ResizeObserver((entries: ResizeObserverEntry[]) => {
    for (const entry of entries) {
      const identifier = entry.target.getAttribute('data-sidebar-item-identifier');
      setSidebarPosition(identifier)(entry.contentRect);
    }
  }));

  useEffect(() => {
    observer.observe(document.body);

    return () => observer.disconnect();
  }, [observer]);

  return observer;
};

const useSortedSidebarItems = () => {
  const [comments] = useContext(TranscriptCommentsStateContext);
  const [tags] = useTranscriptTagsContext();
  const textreplace = useContext(TextReplacePopperContext);
  const { getBasicHighlight } = useHelpers();
  const mapTimePosToDocPos = useMapTimePosToDocPos();

  const commentItems = useMemo(() => {
    return Object.entries(arr.groupBy(comments.items, i => i.highlight.identifier))
      .map<CommentSidebarItem>(([identifier, items]) => ({
        type: 'comment',
        highlight: {
          identifier,
          id: items[0].highlight.id,
        },
        comments: items,
      }));
  }, [comments.items]);

  const tagItems: SidebarItem[] = useMemo(() => {
    return tags.items.map<TagSidebarItem>(t => ({
      type: 'tag',
      highlight: {
        identifier: t.identifier,
      },
      tag: t,
    }));
  }, [tags.items]);

  const textreplaceItems = useMemo(() => {
    const textreplaceItem: TextReplaceSidebarItem = {
      highlight: {
        identifier: textreplace?.selection?.id,
        id: textreplace?.selection?.id,
      },
      type: 'replace',
    };

    return textreplace.selection
      ? [textreplaceItem]
      : [];
  }, [textreplace]);

  const sidebarItems = useMemo(() => {
    return [...commentItems, ...tagItems, ...textreplaceItems];
  }, [commentItems, tagItems, textreplaceItems]);

  const itemHighlightPositions = useMemo(() => {
    return sidebarItems.reduce((acc, x) => {
      let range: { from: number; to: number } = null;
      if (x.type === 'tag') {
        range = mapTimePosToDocPos(x.tag);
      } else {
        const extensionHighlight = getBasicHighlight(x.highlight.identifier);

        if (extensionHighlight) {
          range = { from: extensionHighlight.from, to: extensionHighlight.to };
        }
      }

      acc[x.highlight.identifier] = range;

      return acc;
    }, {} as {
      [identifier: string]: {
        from: number;
        to: number;
      };
    });
  }, [sidebarItems, mapTimePosToDocPos, getBasicHighlight]);

  const sortedItems = useMemo(() => {
    return sidebarItems
      .filter(i => itemHighlightPositions[i.highlight.identifier])
      .sort((a, b) => {
        const hA = itemHighlightPositions[a.highlight.identifier];
        const hB = itemHighlightPositions[b.highlight.identifier];

        if (hA.from === hB.from) return hA.to - hB.to;

        return hA.from - hB.from;
      });
  }, [sidebarItems, itemHighlightPositions]);

  return sortedItems;
};

interface BaseSidebarItem {
  highlight: {
    identifier: string;
  };
}

interface TextReplaceSidebarItem extends BaseSidebarItem {
  type: 'replace';
  highlight: {
    id: string;
    identifier: string;
  };
}

interface CommentSidebarItem extends BaseSidebarItem {
  type: 'comment';
  comments: TranscriptComment[];
  highlight: {
    identifier: string;
    id: number;
  };
}

interface TagSidebarItem extends BaseSidebarItem {
  type: 'tag';
  tag: TaggedMoment;
}

type SidebarItem = CommentSidebarItem | TagSidebarItem | TextReplaceSidebarItem;

export default TranscriptSidebar;