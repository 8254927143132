import { AxiosResponse } from 'axios';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import * as api from '@api';
import { TranscriptComment } from '@/types/transcribe.rich-text';

type Props = {
  transcriptId: number;
};

export const useTranscriptionComments = (props: Props, options: Options = {}) => {

  const query = useQuery(['transcript-comments', props], ({ queryKey }) => {
    const params = queryKey[1];
    return api.transcripts.getTranscriptComments(params)
      .then(res => res.comments);
  }, options);

  return query;
};

type Data = TranscriptComment[];
type QK = readonly [string, Props];

type Options = UseQueryOptions<Data, AxiosResponse, Data, QK>;