import { createNamedContext } from '@utils';
import { ProjectTagsActions, TagStateValue } from './interfaces';

type ContextValue = {
  projectId: number;
  state: TagStateValue;
  dispatch: React.Dispatch<ProjectTagsActions>;
};

export type TagFormData = {
  title: string;
  parentId: number;
  includeSummary: boolean;
  color: string;
};

type TagFormContextValue = {
  data: TagFormData;
  dispatch: React.Dispatch<Partial<TagFormData>>;
};

export const TagStateContext = createNamedContext<ContextValue>(undefined, 'Tag.State.Context');
export const TagFormContext = createNamedContext<TagFormContextValue>(undefined, 'Tag.Form.Context');