import http from '@services/http';
import * as xform from '$admin/api/transformers/search';
import { Search } from './interfaces';

export const fetchItems = (data: Search.FetchMembers.Request): Promise<Search.FetchMembers.Response> => {
  return http.post(`/search/members`, {
    active: data.active,
    listId: data.listId,
    pageIndex: data.pageIndex,
    pageSize: data.pageSize,
    payer: data.payer,
  })
  .then((result: Search.FetchMembers.Response<string>) => ({
    ...result,
    items: xform.transformMembers(result.items),
  }));
};

export const searchMembersExport = (data: Search.SearchMembersExport.Request) => {
  return http.downloadPost(`/search/members`, {
    active: data.active,
    export: data.export,
  });
};