import { Members } from '@api/interfaces';
import { getLocationFor, slugify } from '@utils';

export const transformProfileProps = (data: Members.Search.ResponseUnparsed): Members.Search.Response => {
  return data.items.map(item => ({
    ...item,
    profile: {
      ...item.profile,
      slug: getLocationFor.user.profile({
        slug: slugify({
          id: item.id,
          name: item.profile.fullname,
        }),
      }).pathname,
    },
  }));
};