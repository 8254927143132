import { memo } from 'react';
import { GraduationCap } from '@/components/icons';
import styles from './style/Icon.css';

export const EducationIcon = memo(() => (
  <div className={styles.root}>
    <div className={styles.icon}>
      <GraduationCap
        className={styles.defaultIcon} />
    </div>
  </div>
));

export default EducationIcon;