import { cx } from '@utils';
import styles from './style.css';

type PageTitleProps = {
  className?: string;
  title: string;
};

const PageTitle = (props: PageTitleProps) => (
  <div className={cx(styles.pageTitle, props.className)}>
    {props.title}
  </div>
);

export { PageTitle };
export default PageTitle;