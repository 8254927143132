import { useCallback, useEffect, useMemo, useState, useRef, Fragment } from 'react';
import { addDays } from 'date-fns';
import { useFullscreenToggle, useCookie } from '@utils';
import { cookies } from '@consts';
import { useSelectUser } from '@containers/Store';
import { CallDisclaimerModal, useDialoutModal, CallDialOutModal } from '@/components/Conference/Modal';
import { useConferenceInstance } from './hooks';
import { ConferenceLiveRoomBarContext, ConferenceLiveRoomScreenContext, ConferenceLiveRoomSelfDialOutContext } from './Context';
import type { Coordinator, LiveRoom } from './interfaces';

export function LiveRoomContainer({ children }: ChildrenProps) {
  return (
    <LiveRoomBarContainer>
      <LiveRoomScreenContainer>
        <LiveRoomDisclaimerContainer>
          <LiveRoomSelfDialOutContainer>
            {children}
          </LiveRoomSelfDialOutContainer>
        </LiveRoomDisclaimerContainer>
      </LiveRoomScreenContainer>
    </LiveRoomBarContainer>
  );
}

function LiveRoomBarContainer({ children }: ChildrenProps) {
  const [state, setState] = useState<LiveRoom.Bar.State>({
    active: null,
  });

  const value: LiveRoom.Bar.Context = useMemo(() => [
    state,
    setState,
  ], [state, setState]);

  return (
    <ConferenceLiveRoomBarContext.Provider value={value}>
      {children}
    </ConferenceLiveRoomBarContext.Provider>
  );
}

type DisclaimerProps = {
  children: React.ReactNode;
};

function LiveRoomDisclaimerContainer({ children }: DisclaimerProps) {
  const instance = useConferenceInstance<Coordinator.Conference.MeetingRoom>();

  const [seen, setSeen] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);

  const handleCloseModal = useCallback(() => setModal(false), []);

  useEffect(() => {
    if (!seen) {
      setSeen(true);
      if (instance?.conference?.disclaimer?.length) {
        setModal(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance?.conference?.disclaimer]);

  return (
    <Fragment>
      {children}
      {modal && (
        <CallDisclaimerModal
          text={instance?.conference?.disclaimer}
          onClose={handleCloseModal}
          open={modal} />
      )}
    </Fragment>
  );
}

export function LiveRoomScreenContainer({ children }: ChildrenProps) {
  const ref = useRef<HTMLDivElement>();
  const [preferredVideoRef, setPreferredVideoRef] = useState<HTMLVideoElement | HTMLDivElement>(null);
  const [isFullscreen, toggleFullscreen] = useFullscreenToggle();

  const handleToggle = useCallback(() => {
    toggleFullscreen({
      element: preferredVideoRef ?? ref.current,
    });
  }, [toggleFullscreen, preferredVideoRef]);

  const value: LiveRoom.Screen.Context = useMemo(() => ({
    isFullscreen,
    containerRef: ref,
    toggle: handleToggle,
    preferredVideoRef,
    setPreferredVideoRef,
  }), [isFullscreen, ref, handleToggle, preferredVideoRef, setPreferredVideoRef]);

  return (
    <ConferenceLiveRoomScreenContext.Provider value={value}>
      {children}
    </ConferenceLiveRoomScreenContext.Provider>
  );
}

const defaultSelfDialoutText = `It look's like we're having trouble getting audio permissions from your browser. Would you like to connect via your phone?`;

function LiveRoomSelfDialOutContainer({ children }: ChildrenProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const [openViaCookie, setOpenViaCookie] = useState(false);
  const [displayText, setDisplayText] = useState(defaultSelfDialoutText);
  const [cookie, setCookie, clearCookie] = useCookie(cookies.conferenceDialOutPrompt);

  const instance = useConferenceInstance<Coordinator.Conference.MeetingRoom>();
  const me = useSelectUser();

  const defaultPhone = useMemo(() => me?.contact.phone ?? instance.participant?.contact?.phone, [me, instance.participant]);

  const showModal = useCallback((reason?: string) => {
    setModalOpen(true);
    setDisplayText(old => reason ?? old);
  }, []);

  const onDialOut = useCallback(() => {
    setCookie({ value: (true).toString(), expires: addDays(new Date(), 31) });
    setModalOpen(false);
  }, [setCookie]);

  const value: LiveRoom.SelfDialOut.Context = useMemo(() => ({
    showModal,
  }), [showModal]);

  const onClose = useCallback(() => {
    setModalOpen(false);
    setOpenViaCookie(false);
    if (openViaCookie) {
      clearCookie();
    }
  }, [openViaCookie, clearCookie]);

  useEffect(() => {
    if (cookie) {
      setDisplayText(`It look's like you've had trouble connecting to to audio from your computer before. Would you like to connect audio via your phone?`);
      setModalOpen(true);
      setOpenViaCookie(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ConferenceLiveRoomSelfDialOutContext.Provider value={value}>
        {children}
      </ConferenceLiveRoomSelfDialOutContext.Provider>
      <CallDialOutModal
        open={modalOpen}
        text={displayText}
        defaultPhone={defaultPhone}
        onDialOut={onDialOut}
        onClose={onClose} />
    </>
  );
}