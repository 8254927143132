import { useCallback } from 'react';
import { Strategy } from '$website/containers/MarketSegmentation/interfaces';
import { useSiteStrategyState } from './useSiteStrategyState';

const useAssertStrategy = () => {
  const state = useSiteStrategyState();

  const assert = useCallback((strategy: Strategy | Strategy[]) => {

    return Array.isArray(strategy)
      ? strategy.includes(state)
      : state === strategy;

  }, [state]);

  return assert;
};

export { useAssertStrategy };
export default useAssertStrategy;