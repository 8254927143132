import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

type RouteParams = {
  callId: string;
};

export const useMatchCallIdFromUrl = () => {
  const params = useParams<RouteParams>();

  return useMemo(() => {
    return +params.callId;
  }, [params]);
};