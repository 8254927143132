import { useQuery } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import * as $api from '@api';

type Props = ICallId & ISurveyVersionId;

export const useSurveyPreviewQuery = ({ callId, surveyVersionId }: Props) => {
  return useQuery(['capture-sheet-get-project-survey-form-preview', callId, surveyVersionId], () => {
    return $api.surveys.captureSheet.getSurveyFormPreview({
      callId,
      surveyVersionId,
    });
  }, {
    enabled: !!surveyVersionId,
    retry: 1,
    staleTime: Infinity,
    onError: err => Sentry.captureException(err),
  });
};