import { useCallback, useMemo, useState } from 'react';
import type { MultiValue, CSSObjectWithLabel } from 'react-select';
import Select from 'react-select';
import { indexBy } from '@utils/array';
import type { UseModalProps } from '@/components/Modal';
import { Modal, Header, useModal } from '@/components/Modal';
import { Button } from '@/components/Button';
import type { SurveyBuilder } from '@/containers/SurveyBuilder';
import { useSubmitSurveyDraft, useSurveyBuilderState } from '@/containers/SurveyBuilder';
import type { SurveyQuestionOption, SurveySection } from '@/types';
import { parseSurveyRichText } from '@/containers/SurveyBuilder/utils/rich-text.parse';
import { InfoTooltip } from '@presentation/InfoTooltip';
import styles from './style/ConditionalOrderingModal.css';

type Props = {
  questionIdentifier: string;
} & UseModalProps;

type SectionOption = {
  section: SurveySection;
  disabled: boolean;
};

export const ConditionalOrderingModal = ({ questionIdentifier, ...props }: Props) => {
  const [state, dispatch] = useSurveyBuilderState();
  const [optionsState, setOptionsState] = useState(buildInitialOptionsState(questionIdentifier, state));

  const submitSurveyDraft = useSubmitSurveyDraft();

  const linkedSectionIdentifiers = useMemo(() => Object.values(optionsState).flatMap(s => s), [optionsState]);

  const currSection = useMemo(() => {
    const question = state.survey.questions.find(q => q.base.identifier === questionIdentifier);

    return state.survey.sections.find(s => s.identifier === question.section.identifier);
  }, [questionIdentifier, state.survey.questions, state.survey.sections]);

  const shownSections = useMemo(() => {
    return state.survey.sections.filter(s => s.ordinal > currSection.ordinal).sort((a, b) => a.ordinal - b.ordinal);
  }, [currSection.ordinal, state.survey.sections]);

  const onQuestionOptionsChange = useCallback((optionIdentifier: string, options: MultiValue<SectionOption>) => {
    setOptionsState(old => {
      return {
        ...old,
        [optionIdentifier]: options.map(s => s.section.identifier),
      };
    });
  }, []);

  const onSave = useCallback(() => {
    const inverted = indexBy(Object.entries(optionsState).flatMap(([k, v]) => v.map(s => ({ k: s, v: k }))), k => k.k, v => v.v);
    const actions: SurveyBuilder.NonBatchActions[] = [];

    for (const entry of shownSections) {
      actions.push({
        type: 'section-metadata-updated',
        payload: {
          metadata: {
            linkedOrder: inverted[entry.identifier] ? {
              questionIdentifier,
              optionIdentifier: inverted[entry.identifier],
            } : null,
          },
          identifier: entry.identifier,
        },
      });
    }
    dispatch({
      type: 'batch-actions',
      actions,
    });

    submitSurveyDraft();
    props?.onClose();
  }, [dispatch, optionsState, props, questionIdentifier, shownSections, submitSurveyDraft]);

  const canSave = useMemo(() => {
    return JSON.stringify(optionsState) !== JSON.stringify(buildInitialOptionsState(questionIdentifier, state));
  }, [optionsState, questionIdentifier, state]);

  const sectionOptions = shownSections.map<SectionOption>(s => ({
    section: s,
    disabled:
      (s.metadata.linkedOrder?.questionIdentifier && s.metadata.linkedOrder.questionIdentifier !== questionIdentifier)
      || linkedSectionIdentifiers.includes(s.identifier),
  }));

  const questionOptions = state.survey.questions.find(q => q.base.identifier === questionIdentifier).options as SurveyQuestionOption[];

  //Show all options for the current section
  //Give a way for the user to associate sections with each option

  return (
    <Modal {...props}>
      <Header>Conditionally Order Sections?</Header>
      <div className={styles.table}>
        <div className={styles.header}>
          <div className={styles.optionCol}>Option</div>
          <div>
            <div className={styles.flexWrapper}>Sections <InfoTooltip text={`The order of the selected sections does not matter. If multiple sections are assigned to one option they will be presented in the default order.`} /></div>
          </div>
        </div>
        <div className={styles.tbody}>
          {questionOptions.map(o => (
            <div key={o.base.identifier} className={styles.trow}>
              <div className={styles.optionCol}>
                {parseSurveyRichText(o.value, state.survey)}
              </div>
              <div>
                <Select<SectionOption, true>
                  options={sectionOptions}
                  isOptionDisabled={o => o.disabled}
                  value={sectionOptions.filter(s => optionsState[o.base.identifier]?.includes(s.section.identifier))}
                  getOptionLabel={o => o.section.name}
                  getOptionValue={o => o.section.identifier}
                  onChange={vals => onQuestionOptionsChange(o.base.identifier, vals)}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: base => ({ ...base, zIndex: 8 } as CSSObjectWithLabel),
                  }}
                  isMulti={true} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.buttons}>
        <Button
          variant='brick'
          color='destructive'
          onClick={props.onClose}>
          Cancel
        </Button>
        <Button
          variant='brick'
          color='affirmative'
          disabled={!canSave}
          onClick={onSave}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export const useConditionalOrderingModal = () => useModal(ConditionalOrderingModal);

/**
 * Key: Option Identifier; Value: Section Identifiers
 */
type OptionsState = Record<string, string[]>;

function buildInitialOptionsState(questionIdentifier: string, state: SurveyBuilder.State): OptionsState {
  const options = state.survey.questions.find(q => q.base.identifier === questionIdentifier).options;

  const optionsState: OptionsState = {};

  for (const o of options) {
    optionsState[o.base.identifier] = state.survey.sections.filter(s => s.metadata.linkedOrder?.questionIdentifier === questionIdentifier && s.metadata.linkedOrder?.optionIdentifier === o.base.identifier).map(s => s.identifier);
  }

  return optionsState;
}