import { memo } from 'react';
import ComputerIcon from '@mui/icons-material/Computer';
import type { SelectOptionProps } from '@/components/Select/interfaces';
import { Camera } from './Camera';
import { CameraPreview } from './Camera.Preview';
import { Microphone } from './Microphone';
import { Nametag } from './Nametag';
import { Visibility } from './Visibility';
import styles from './style/Tab.Desktop.css';

type Props = {
  cameraVisible?: boolean;
  cameraValue?: boolean;
  cameraSource?: string;
  disabledCamera?: boolean;
  microphoneValue?: boolean;
  microphoneSource?: string;
  disabledMicrophone?: boolean;
  disabledVisibility?: boolean;
  visibilityValue?: number;
  visibilityVisible?: boolean;
  showCameraPreview?: boolean;
  disabledNametag: boolean;
  onToggleCamera: (enabled: boolean) => void;
  onChangeCameraSource: (device: MediaDeviceInfo) => void;
  toggleMicrophone: (muted: boolean) => void;
  onChangeMicrophoneSource: (device: MediaDeviceInfo) => void;
  onSelectVisibility: (value: SelectOptionProps) => void;
};

export const Desktop = memo((props: Props) => {
  return (
    <div className={styles.root}>
      <Nametag
        className={styles.row}
        disabled={props.disabledNametag} />
      <Microphone
        className={styles.row}
        value={props.microphoneValue}
        source={props.microphoneSource}
        disabled={props.disabledMicrophone}
        toggleMicrophone={props.toggleMicrophone}
        onChangeMicrophoneSource={props.onChangeMicrophoneSource} />
      <Camera
        className={styles.row}
        value={props.cameraValue}
        source={props.cameraSource}
        disabled={props.disabledCamera}
        visible={props.cameraVisible}
        onToggleCamera={props.onToggleCamera}
        onChangeCameraSource={props.onChangeCameraSource} />
      {props.showCameraPreview && <CameraPreview className={styles.row} />}
      <Visibility
        className={styles.row}
        value={props.visibilityValue}
        disabled={props.disabledVisibility}
        visible={props.visibilityVisible}
        onSelect={props.onSelectVisibility} />
    </div>
  );
});

export const DesktopTitle = memo(() => (
  <div className={styles.desktopTitle}>
    <ComputerIcon />
    Join By Desktop
  </div>
));

