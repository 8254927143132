import { useSelector } from 'react-redux';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import * as selectors from '@store/selectors';
import CalendarBase from '@/components/Calendar/CalendarBase.js';
import { Checkbox } from '@/components/Checkbox';
import { SchedulingCalendarProps } from './interfaces';
import styles from './style/ExternalCalendarSelector.css';

const mapState = (state: Store.State) => ({
  connectedCalendar: selectors.connectedCalendar(state),
  contacts: state.contacts,
});

type Props = {
  calendar: SchedulingCalendarProps;
  onChange: (data: { id: number; selected: boolean; }) => void;
  user:     Pick<Store.User, 'id'>;
};

const ExternalCalendarSelector = (props: Props) => {
  const state = useSelector(mapState);

  const handleToggle = (id: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange({
        id,
        selected: e.target.checked,
      });
    }
  };

  const showShouldOtherViewCalendar = (user?: { id: number; }) => {
    return user && user.id;
  };

  const params = props.calendar;

  if (!state.connectedCalendar && !showShouldOtherViewCalendar(params.with)) {
    return null;
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.title}>View Calendar</div>
      {state.connectedCalendar &&
        <label className={styles.item}>
          <div className={styles.checkbox}>
            <Checkbox
              style={{ color: 'var(--gray-l)' }}
              icon={<CheckBoxOutlineBlankIcon style={{ height: 20, width: 20 }} />}
              checkedIcon={<CheckBoxIcon style={{ height: 20, width: 20 }} />}
              onChange={handleToggle(props.user.id)}
              defaultChecked={true} />
          </div>
          Personal <img className={styles.img} src={CalendarBase.getProviderImage(state.connectedCalendar.providerId)} />
        </label>}
      {showShouldOtherViewCalendar(params.with) &&
        <label className={styles.item}>
          <div className={styles.checkbox}>
            <Checkbox
              style={{ color: '#F8A8A8' }}
              icon={<CheckBoxOutlineBlankIcon style={{ height: 20, width: 20 }} />}
              checkedIcon={<CheckBoxIcon style={{ height: 20, width: 20 }} />}
              onChange={handleToggle(params.with.id)}
              defaultChecked={true} />
          </div>
          {state.contacts[params.with.id].profile.fullname}
        </label>}
    </div>
  );
};

export { ExternalCalendarSelector };
export default ExternalCalendarSelector;