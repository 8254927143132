import { useCallback } from 'react';
import { usePersonaResearchData } from '@containers/SurveyBuilder/hooks';
import { Header, Subtitle, Title } from '@screens/ProjectCreation/Header';
import {
  useCanSaveEvaluationCriteria,
  useCanSavePartnershipCriteria,
  useCanSaveTargetRoles,
  useTemplateEvaluationCriteria,
  useTemplatePartnershipCriteria,
  useTemplateRoles,
  TemplateCriteriaInput,
  TemplateRolesInput,
} from '@/components/Survey.Templates';
import {
  CategoryPresetScreen,
  CompetitorsPresetScreen,
  ThanksPresetScreen,
  TargetPresetScreen,
} from './ProjectSurvey.Presets.Shared';
import { BackButton, Footer, NextButton } from './Footer';
import { useProjectSurveyStepperContext } from './context';
import commonStyles from './style.css';
import styles from './style/ProjectSurvey.Presets.css';

const LetsGetStarted = () => {

  const title = `Great, let's get started building your survey!`;
  const subtitleOne = `To set up your Persona Research Survey template, we'll ask a couple of questions about the industry, companies, and products you're looking to research in order to pre-populate questions.`;
  const subtitleTwo = `You'll then be able to customize the survey draft with specific questions through our survey builder. Let's jump in!`;

  const actions = useProjectSurveyStepperContext();

  return (
    <>
      <div className={styles.section}>
        <div className={commonStyles.wrap}>
          <Header className={commonStyles.row}>
            <Title className={commonStyles.row}>{title}</Title>
            <Subtitle className={commonStyles.row}>{subtitleOne}</Subtitle>
            <Subtitle className={commonStyles.row}>{subtitleTwo}</Subtitle>
          </Header>
        </div>
      </div>
      <Footer>
        <BackButton onClick={actions.back} />
        <NextButton
          title="Get Started"
          onClick={actions.next} />
      </Footer>
    </>
  );
};

const totalSteps = 6;

const Category = () => {

  const title = `Step 1 of ${totalSteps}: The industry category`;
  const subtitle = `What is the product or industry category you would like to collect persona research on?`;

  return (
    <CategoryPresetScreen
      subtitle={subtitle}
      title={title} />
  );
};

const Target = () => {

  const title = `Step 2 of ${totalSteps}: Your research target`;
  const subtitle = `What is the name of the primary company or product you would like to collect persona research on?`;

  return (
    <TargetPresetScreen
      subtitle={subtitle}
      title={title} />
  );
};

const Competitors = () => {

  const title = `Step 3 of ${totalSteps}: The competitive landscape`;
  const subtitle = `What are the names of the competing companies or products you would like to collect persona research on?`;

  return (
    <CompetitorsPresetScreen
      subtitle={subtitle}
      title={title} />
  );
};

const EvaluationCriteria = () => {

  const [templateData] = usePersonaResearchData();

  const title = `Step 4 of ${totalSteps}: Evaluation Criteria`;
  const subtitle = `What are the most important criteria for buyers of ${templateData.category.value} when making a purchasing decision? Provide a set of variables that influence the buying decision (i.e. customer support quality, price, etc.).`;

  const { back, next } = useProjectSurveyStepperContext();

  const {
    onSubmit,
    ...criteriaProps
  } = useTemplateEvaluationCriteria();
  const canSave = useCanSaveEvaluationCriteria(criteriaProps.criteria);

  const handleNext = useCallback(() => {
    onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  return (
    <>
      <div className={styles.section}>
        <div className={commonStyles.wrap}>
          <Header className={commonStyles.row}>
            <Title className={commonStyles.row}>{title}</Title>
            <Subtitle className={commonStyles.row}>{subtitle}</Subtitle>
          </Header>
          <TemplateCriteriaInput {...criteriaProps} />
        </div>
      </div>
      <Footer>
        <BackButton onClick={handleBack} />
        <NextButton
          disabled={!canSave}
          onClick={handleNext} />
      </Footer>
    </>
  );
};

const PartnershipCritera = () => {

  const [templateData] = usePersonaResearchData();

  const title = `Step 5 of ${totalSteps}: Success Criteria`;
  const subtitle = `Whate are the most important criteria to indicate that a ${templateData.category.value} purchase drove success at the buyer's organization? Provide a set of outcomes the buyers look to see after adopting ${templateData.category.value} (i.e. boost in revenue, cost reduction, better collaboration, etc.)`;

  const { back, next } = useProjectSurveyStepperContext();

  const {
    onSubmit,
    ...criteriaProps
  } = useTemplatePartnershipCriteria();
  const canSave = useCanSavePartnershipCriteria(criteriaProps.criteria);

  const handleNext = useCallback(() => {
    onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  return (
    <>
      <div className={styles.section}>
        <div className={commonStyles.wrap}>
          <Header className={commonStyles.row}>
            <Title className={commonStyles.row}>{title}</Title>
            <Subtitle className={commonStyles.row}>{subtitle}</Subtitle>
          </Header>
          <TemplateCriteriaInput {...criteriaProps} />
        </div>
      </div>
      <Footer>
        <BackButton onClick={handleBack} />
        <NextButton
          disabled={!canSave}
          onClick={handleNext} />
      </Footer>
    </>
  );
};

const TargetRoles = () => {
  const title = `Step 6 of ${totalSteps}: Target Roles`;
  const subtitle = `What are the different roles in the decison-making proccess that you would like to collect insight on?`;

  const { back, next } = useProjectSurveyStepperContext();

  const {
    onSubmit,
    ...roleProps
  } = useTemplateRoles();
  const canSave = useCanSaveTargetRoles(roleProps.roles);

  const handleNext = useCallback(() => {
    onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  return (
    <>
      <div className={styles.section}>
        <div className={commonStyles.wrap}>
          <Header className={commonStyles.row}>
            <Title className={commonStyles.row}>{title}</Title>
            <Subtitle className={commonStyles.row}>{subtitle}</Subtitle>
          </Header>
          <TemplateRolesInput {...roleProps} />
        </div>
      </div>
      <Footer>
        <BackButton onClick={handleBack} />
        <NextButton
          disabled={!canSave}
          onClick={handleNext} />
      </Footer>
    </>
  );
};

export const PersonaResearchScreens = [
  LetsGetStarted,
  Category,
  Target,
  Competitors,
  EvaluationCriteria,
  PartnershipCritera,
  TargetRoles,
  ThanksPresetScreen,
];