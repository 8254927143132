import { CallRole, utils } from '@enums';
import { CopyLinkToClipboard } from './CopyLinkToClipboard';
import styles from './style/Conference.Participants.Table.css';

type Props = {
  identifier: number;
  pins:       Partial<Record<CallRole, number>>;
};

export const GuestPinsTable = ({ pins = {}, ...props }: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.th}>
        <div className={styles.name}>Role</div>
        <div className={styles.pin}>PIN</div>
        <div className={styles.visibility} />
        <div className={styles.role} />
      </div>
      {Object.keys(pins).map(x => {
        return (
          <div
            className={styles.tr}
            key={x}>
            <div className={styles.name}>{utils.CallRole.getName(+x)}</div>
            <div className={styles.pin}>
              <div className={styles.digits}>
                {pins[+x]}
              </div>
              <CopyLinkToClipboard
                identifier={props.identifier}
                pin={pins[+x] as string} />
            </div>
            <div className={styles.visibility} />
            <div className={styles.role} />
          </div>
        );
      })}
    </div>
  );
};

GuestPinsTable.displayName = 'Conference.Participants.GuestPins.Table';