import { Link } from 'react-router-dom';
import { cx, getLocationFor } from '@utils';
import { Contact } from '@/types';
import styles from './style.css';

type Props =  {
  className?: string;
  item:       Pick<Contact, 'profile'>;
  onClick?:   (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

const ItemUserProfileLink = (props: Props) => {
  const url = getLocationFor.user.profile({ slug: props.item.profile.slug });

  return (
    <div className={cx(styles.name, props.className)}>
      <Link
        onClick={props.onClick ? props.onClick : () => {}}
        className={styles.link}
        target="_blank"
        to={url}>
        {props.item.profile.fullname}
      </Link>
    </div>
  );
};

export default ItemUserProfileLink;