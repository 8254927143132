import { Fragment } from 'react';
import { formatCurrency } from '@utils/format';
import { TableCellItem } from './interfaces';
import styles from './style/Search.Members.css';

type Props = TableCellItem;

export const Cell = (props: Props) => {
  const currency = props.row.original?.user?.rateCurrency || 'USD';

  return (
    <Fragment>
      {props.row.original.user.hourlyRate != null ? formatCurrency(props.row.original.user.hourlyRate, currency, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '-'}
    </Fragment>
  );
};

Cell.displayName = 'Column.Rate.Cell';