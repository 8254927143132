import type { SurveyQuestionType } from '@enums';
import type { ShortTextResponseQuestion, TextResponseQuestion } from '@/types';
import type { Validation } from '../interfaces';
import { assertHasValue, returnAssertFailure } from './validation.base';

export const DefaultSettings: ShortTextResponseQuestion.Settings = {
  rationale: {
    enabled: false,
    minimum: 75,
  },
};

export function validateQuestion(question: TextResponseQuestion.Question): Validation.ValidateQuestionResult<SurveyQuestionType.ShortTextResponse> {
  const hasValue = assertHasValue(question);

  if (hasValue.success === false) {
    return returnAssertFailure(hasValue, question);
  }

  return {
    success: true,
    result: {
      question,
    },
  };
}