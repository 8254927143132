import { useCallback, useState, useMemo } from 'react';
import { useScript } from '@utils';
import { ZendeskScriptContext, useZendeskScript } from './Context';

export const ZendeskScriptContainer = (props: ChildrenProps) => {
  const [shouldLoad, setShouldLoad] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const loadAsync = useCallback(() => {
    setShouldLoad(true);
  }, []);

  const setLoaded = useCallback(() => {
    setIsLoaded(true);
  }, []);

  const value = useMemo(() => ({
    shouldLoad,
    loadAsync,
    isLoaded,
    setLoaded,
  }), [shouldLoad, loadAsync, isLoaded, setLoaded]);

  return (
    <ZendeskScriptContext.Provider value={value}>
      {props.children}
    </ZendeskScriptContext.Provider>
  );
};

export const ZendeskScript = () => {
  const { shouldLoad, setLoaded } = useZendeskScript();

  useScript({
    id: 'ze-snippet',
    src: shouldLoad ? 'https://static.zdassets.com/ekr/snippet.js?key=5790b51e-4de5-42c8-83f3-1c840d03946c' : null,
    onLoad: () => {
      window.zE('webWidget', 'hide');
      setLoaded();
    },
  });

  return null;
};
