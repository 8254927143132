import type { SurveyQuestionType } from '@enums';
import type { MultiTextboxQuestion } from '@/types';
import type { Validation } from '../interfaces';
import { assertHasValue, returnAssertFailure } from './validation.base';

const DefaultMaxEntries = 3;

export const DefaultSettings: MultiTextboxQuestion.Settings = {
  dontKnow: {
    enabled: false,
    label: `I don't know`,
  },
  entryLabels: Array.from({ length: DefaultMaxEntries }, (_, i) => ``),
  label: null,
  maximumEntries: DefaultMaxEntries,
  minimumEntries: 3,
  rationale: {
    enabled: false,
    minimum: 75,
  },
};

export function validateQuestion(question: MultiTextboxQuestion.Question): Validation.ValidateQuestionResult<SurveyQuestionType.MultiTextbox> {
  const hasValidSettings = assertHasValidSettings(question.settings);

  if (hasValidSettings.success === false) {
    return returnAssertFailure(hasValidSettings, question);
  }

  const hasValue = assertHasValue(question);

  if (hasValue.success === false) {
    return returnAssertFailure(hasValue, question);
  }

  return {
    success: true,
    result: {
      question,
    },
  };
}

function assertHasValidSettings(settings: MultiTextboxQuestion.Settings): Validation.AssertionResult {
  if (!settings.minimumEntries) {
    return {
      success: false,
      message: 'Minimum Textboxes is empty.',
    };
  } else if (!settings.maximumEntries) {
    return {
      success: false,
      message: 'Maximum Textboxes is empty.',
    };
  }

  return { success: true };
}