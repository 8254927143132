import { useMemo } from 'react';
import { useSurveyBuilderState } from './useSurveyBuilderContext';

export const useSectionQuestions = (identifier: string) => {
  const [state] = useSurveyBuilderState();

  return useMemo(() => {
    return state.survey.questions
      .filter(q => q.section.identifier === identifier);
  }, [identifier, state.survey.questions]);

};

export const useSectionItems = (identifier: string) => {
  const [state] = useSurveyBuilderState();

  const items = useMemo(() => {
    return state.survey.items
      .filter(q => q.section.identifier === identifier);
  }, [identifier, state.survey.items]);

  return items;
};