import type { AxiosError } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import type { Projects } from '@api/interfaces';

type Data = Projects.GetProjectSurveyForm.Response;
type QueryKey = [string, number];

type Props = {
  preview: boolean;
} & IProjectId;

const useFetchProjectSurveyForm = (
  {
    preview,
    projectId,
  }: Props,
  options?: UseQueryOptions<Data, AxiosError, Data, QueryKey>
) => {

  return useQuery({
    queryKey: ['fetch-project-survey-form', projectId, preview], queryFn: ({ queryKey }) => {
      const projectId = queryKey[1] as number;
      const preview = queryKey[2] as boolean;

      return api.projects.surveys.getForm({
        preview,
        projectId,
      });
    }, ...options,
  });
};

export { useFetchProjectSurveyForm };
export default useFetchProjectSurveyForm;