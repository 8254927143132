import { useCallback } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder';
import { useQuestionBuilderItem } from '@containers/SurveyBuilder.Question';
import { SurveySettings } from '@/types/survey';
import { CheckboxLabel } from '@/components/Checkbox';

type Props = {
  className?: string;
  disabled?: boolean;
};

export const OrderRowsBasedOnSource = ({
  className,
  disabled,
}: Props) => {

  const item = useQuestionBuilderItem();

  const [_, dispatch] = useSurveyBuilderState();

  const value = (item.settings as SurveySettings.OrderRowsBasedOnSource).orderRowsBasedOnSource;

  const handleToggle = useCallback((e: React.ChangeEvent) => {
    dispatch({
      type: 'reuse-rows-order-updated',
      payload: {
        questionIdentifier: item.base.identifier,
        value: !value,
      },
    });
  }, [
    dispatch,
    item.base.identifier,
    value,
  ]);

  return (
    <div className={className}>
      <CheckboxLabel
        disabled={disabled}
        checked={value}
        label="Order rows based on source"
        onChange={handleToggle} />
    </div>
  );
};