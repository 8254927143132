import Video from 'twilio-video';
import styles from './style/UnsupportedBrowserWarning.Video.css';

type Props = {
  children: React.ReactElement;
};

export function UnsupportedBrowserWarningVideo({ children }: Props) {
  if (!Video.isSupported) {
    return (
      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.title}>Browser is not supported</div>
          <div className={styles.text}>Please open this page in one of the supported browsers</div>
          <ul>
            <li>Chrome</li>
            <li>Firefox</li>
            <li>Safari</li>
            <li>Edge</li>
          </ul>
        </div>
      </div>
    );
  }

  return children;
}