import qnq1 from 'static/images/website/q2q/qnq-chart.png';
import qnq2 from 'static/images/website/q2q/qnq-line.png';
import qnq3 from 'static/images/website/q2q/qnq-video-call.png';
import styles from './style/Tools.css';

type Props = unknown;

export const Tools = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          {items.map(x =>
            <div
              className={styles.row}
              key={x.title}>
              <div className={styles.item}>
                <div className={styles.box}>
                  <img
                    alt={x.alt}
                    className={styles.img}
                    src={x.src} />
                </div>
                <div className={styles.box}>
                  <div className={styles.title}>{x.title}</div>
                  <div className={styles.text}>{x.text}</div>
                </div>
              </div>
            </div>)}
        </div>
      </div>
    </div>
  );
};

Tools.displayName = 'Quant2Qual.Tools';

const items = [{
  alt: 'Gather data',
  src: qnq1,
  text: `Build surveys with our robust programming tool and collect structured and unstructured data from your target respondents.`,
  title: `Gather quantitative and qualitative data through surveys`,
}, {
  alt: 'View respondent profiles',
  src: qnq2,
  text: `Sentiment experts are equipped with tools to adjust their visibility on the Sentiment platform to ensure that our research tools meet both the highest compliance standards and our customer needs.`,
  title: `Click into datapoints to see full respondent profiles`,
}, {
  alt: 'follup interview',
  src: qnq3,
  text: `Gather qualitative feedback from survey respondents in the form of on-demand interviews and video calls.`,
  title: `Understand outliers in your data through followup interviews`,
}];