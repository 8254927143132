import { useState, useLayoutEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export const useElementBoundingRect = <T extends HTMLElement>(el: T) => {
  const [state, setState] = useState(el?.getBoundingClientRect());

  const [observer] = useState(() => new ResizeObserver((entries: ResizeObserverEntry[]) => {
    for (const entry of entries) {
      setState(entry.target.getBoundingClientRect());
    }
  }));

  useLayoutEffect(() => {
    if (el) {
      recalculateRect();
      observer.observe(el);

      window.addEventListener('resize', recalculateRect);

      return () => {
        observer.unobserve(el);
        window.removeEventListener('resize', recalculateRect);
      };
    } else {
      setState(null);
    }

    function recalculateRect() {
      setState(el.getBoundingClientRect());
    }
  }, [el, observer]);

  return state;
};