import { createContext } from 'react';
import type { Dispatch } from 'react';
import type { SaveTaggedMoment } from '@api/interfaces/transcripts';
import type { TaggedMomentModalParams, TaggedMomentState } from './interfaces';

type SetTaggedMomentEditParams = (params: TaggedMomentModalParams) => void;
type SaveTaggedMoment = (params: SaveTaggedMoment.Request) => void;

export const SetTaggedMomentEditParamsContext = createContext<SetTaggedMomentEditParams>(null);

export const TaggedMomentFormStateContext = createContext<TaggedMomentState.Value>(null);
export const TaggedMomentFormDispatchContext = createContext<Dispatch<TaggedMomentState.Action>>(null);
export const SaveTaggedMomentContext = createContext<SaveTaggedMoment>(null);
export const IsSavingTaggedMomentContext = createContext<boolean>(false);