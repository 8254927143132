import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useStepper } from '@utils';
import { useInvalidateFilesQueries } from '@/utils/api';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import type { ModalProps } from '@/components/Modal/Modal';
import { useModal } from '@/components/Modal/hooks';
import { Modal } from '@/components/Modal';
import { ProjectAccessQueryContext } from '@/components/Project.Access/Context';
import { PublishModalContext } from './context';
import { Transcripts } from './PublishModal.Transcripts';
import { CreateClientUsers } from './PublishModal.CreateClientUsers';
import { Access } from './PublishModal.Access';
import { Notify } from './PublishModal.Notify';
import type { PublishScreenProps } from './interfaces';
import styles from './style/PublishModal.css';

type Props = Pick<ModalProps, 'open' | 'onClose'>;

export const PublishModal = ({ ...modalProps }: Props) => {
  const invalidateFilesQueries = useInvalidateFilesQueries();
  const access = useContext(ProjectAccessQueryContext);
  const [hasUnregisteredClientUsers, setHasUnregisteredClientUsers] = useState<boolean>();

  useEffect(() => {
    if (access.isFetchedAfterMount && hasUnregisteredClientUsers === undefined) {
      const state = !!access.data?.unregisteredClientUsers?.length;

      setHasUnregisteredClientUsers(state);
    }
  }, [
    access,
    hasUnregisteredClientUsers,
  ]);

  const Screens = useMemo(() => {
    return [
      Transcripts,
      hasUnregisteredClientUsers && CreateClientUsers,
      Access,
      Notify,
    ].filter(Boolean);
  }, [hasUnregisteredClientUsers]);

  const onClose = useCallback(() => {
    invalidateFilesQueries();
    modalProps.onClose();
  }, [invalidateFilesQueries, modalProps]);

  return (
    <Modal
      classes={{ root: styles.modal }}
      {...modalProps}>
      {access.isInitialLoading || hasUnregisteredClientUsers === undefined
        ? (
          <div className={styles.loader}>
            <ActivityIndicator show />
          </div>
        )
        : <PublishModalStepper
          onClose={onClose}
          screens={Screens} />}
    </Modal>
  );
};

export const usePublishModal = () => useModal(PublishModal);

type PublishModalStepperProps = {
  onClose: () => unknown;
  screens: ((props: PublishScreenProps) => JSX.Element)[];
};

const PublishModalStepper = (props: PublishModalStepperProps) => {
  const [Screen, actions, index] = useStepper(props.screens);
  const [numTranscriptsPublished, setNumTranscriptsPublished] = useState<number>();

  return (
    <PublishModalContext.Provider value={{
      actions,
      index,
      numScreens: props.screens.length,
      numTranscriptsPublished,
      setNumTranscriptsPublished,
    }}>
      <Screen onClose={props.onClose} />
    </PublishModalContext.Provider>
  );
};