import { useContext } from 'react';
import { FolderLoadingContext } from '@/containers/Workspace.Folder/Context';
import { useIsInternalUser } from '@/containers/Store/hooks';
import { DiscoverInsights as Btn } from '@/components/GroupProject/DiscoverInsights';
import styles from '@screens/Workspace.File/style/Header.Actions.css';
import { SkeletonAccess } from '@/components/ObjectAccess/Button';

export const DiscoverInsights = () => {
  const isLoading = useContext(FolderLoadingContext);
  const isInternalUser = useIsInternalUser();

  if (isLoading) {
    return <SkeletonAccess />;
  }

  if (isInternalUser) {
    return null;
  }

  return (
    <Btn className={styles.discover} />
  );

};
