import { StarToggle } from '@presentation/StarToggle';
import { cx } from '@utils';
import { RespondentProps } from '@/types';
import { QuotedText } from '@presentation/QuotedText';
import { MaybeLink } from '@/components/MaybeLink';
import { RespondentBaseballCard } from '@/components/BaseballCard';
import ViewAll from './ViewAll';
import styles from './style/ResponseTextItem.css';

export type ResponseItemProps = {
  className?: string;
  rationale?: string;
  respondent: RespondentProps;
  value: React.ReactNode;
  wrapClassName?: string;
} & StarProps;

type StarProps = {
  disabledStarring?: boolean;
  onToggleStar: () => void;
  starred: boolean;
};

export const ResponseItem = ({
  disabledStarring = false,
  className,
  rationale,
  respondent,
  onToggleStar,
  starred,
  value,
  wrapClassName,
}: ResponseItemProps) => {

  const hasValue = !!value;
  const hasRationale = !!rationale;

  return (
    <div className={cx(styles.root, className)}>
      <div className={cx(styles.wrap, wrapClassName)}>
        {!disabledStarring &&
          <StarToggle
            className={styles.star}
            onClick={onToggleStar}
            value={starred} />}

        <div className={styles.textblock}>
          <div className={styles.comment}>
            {hasValue && <QuotedText>{value}</QuotedText>}
            {hasRationale &&
              <div className={cx({ [styles.rationale]: hasValue })}>
                Rationale: <QuotedText>{rationale}</QuotedText>
              </div>
            }
            <div className={styles.author}>
              <RespondentBaseballCard
                classes={{
                  container: styles.cardContainer,
                }}
                {...respondent}>
                <MaybeLink to={respondent.paths.profile}>
                  {respondent.user.name}
                </MaybeLink>
              </RespondentBaseballCard>
            </div>
          </div>

          <ViewAll
            onClick={respondent.onViewResponse}
            to={respondent.paths.response} />
        </div>
      </div>
    </div>
  );
};

// type CardProps = {
//   children: React.ReactNode;
//   className?: string;
// } & RespondentProps;

// function BaseballCard(props: CardProps) {
//   switch (props.type)
//   if (props.type === 'internal') {
//     if (props.anonymity) {
//       return (
//         <AnonymousBaseballCard
//           className={props.className}
//           user={props.user}
//           subtext={props.user.nam == props.respondentName ? null : props.user.fullname}>
//           {props.children}
//         </AnonymousBaseballCard>
//       );
//     } else {
//       return (
//         <MemberBaseballCard.Client
//           className={props.className}
//           userId={props.user.id}
//           projectId={props.projectId}>
//           {props.children}
//         </MemberBaseballCard.Client>
//       );
//     }
//   } else {
//     return null;
//   }
// }

export default ResponseItem;