import { useCallback, useMemo } from 'react';
import { EyeOff } from 'react-feather';
import { ProfileEntry, SearchType } from '@enums';
import { SeeMore } from '@presentation/SeeMore';
import { cx, getLocationFor } from '@utils';
import { MaybeLink } from '@/components/MaybeLink';
import DateRange from './Profile.DateRange';
import Icon from './Profile.Employment.Icon';
import { Section } from './interfaces';
import Tags from './Profile.Tags';
import styles from './style/Profile.Section.Multiple.css';

type Props =
  Section.MultiEntry.ReadOnlyProps<ProfileEntry.Employment>;

export const ProfileEmployment = ({ value: item }: Props) => {

  const isEmpty = useMemo(() => {
    return !item.description?.length &&
      !item.functions.length &&
      !item.location;
  }, [
    item.description,
    item.functions.length,
    item.location,
  ]);

  const renderCompany = useCallback(() => {
    if (item.hasGroupParent) return null;

    const name = item.company ?? 'Current Company Private';

    return (
      <div className={styles.header}>
        <MaybeLink
          classes={{
            link: styles.link,
          }}
          to={getLocationFor.company.root(item.companySlug)}>
          <div className={styles.readOnlyCompany}>{name}</div>
        </MaybeLink>
        {!item.company && <EyeOff className={styles.eye} />}
      </div>
    );
  }, [
    item.company,
    item.companySlug,
    item.hasGroupParent,
  ]);

  return (
    <div className={cx(styles.root, styles.readOnly)}>
      <div className={styles.iconColumn}>
        <Icon
          companySlug={item.companySlug}
          logoFileKey={item.companyLogoFileKey}
          hasGroupParent={item.hasGroupParent}
          hasGroupChild={item.hasGroupChild} />
      </div>
      <div className={styles.main}>
        <div className={styles.topItem}>
          <div className={styles.half}>
            {renderCompany()}
            <div className={cx(styles.title, styles.desktop)}>{item.title}</div>
          </div>
          <DateRange
            startMonth={item.startMonth}
            startYear={item.startYear}
            endMonth={item.endMonth}
            endYear={item.endYear} />
        </div>
        <div className={cx(styles.half, {
          [styles.minHeight]: isEmpty,
        })}>
          <div className={cx(styles.title, styles.mobile)}>{item.title}</div>
          <div className={styles.subtitle}>{item.location}</div>
        </div>

        <SeeMore className={styles.description} lineHeight={20} maxLines={5}>
          {item.description}
        </SeeMore>

        {!!item.functions.length &&
          <div className={styles.tags}>
            <Tags
              items={item.functions}
              type={SearchType.JobFunction} />
          </div>
        }
      </div>
    </div>
  );
};

export default ProfileEmployment;