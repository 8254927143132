export function hashQueryKey(queryKey: readonly unknown[]): string {
  return JSON.stringify(queryKey, (_, val) =>
    isPlainObject(val)
      ? Object.keys(val)
        .sort()
        .reduce((result, key) => {
          result[key] = val[key];
          return result;
        }, {} as Record<string, unknown>)
      : val,
  );
}

function isPlainObject(o: unknown): o is object {
  if (!hasObjectPrototype(o)) return false;

  const ctor = o.constructor;

  if (typeof ctor === 'undefined') return true;

  const prot = ctor.prototype as typeof Object.prototype;

  if (!hasObjectPrototype(prot)) return false;

  /* eslint-disable-next-line no-prototype-builtins */
  if (!prot.hasOwnProperty('isPrototypeOf')) return false;

  return true;
}

function hasObjectPrototype(o: unknown): boolean {
  return Object.prototype.toString.call(o) === '[object Object]';
}