import { MinusCircle } from 'react-feather';
import { cx } from '@utils';
import { SurveyRichTextEditor } from '@/components/Survey.RichText/Editor';
import type { RichTextEntryListItemProps, RenderRichTextEntryListEditorProps, RichTextEntryItem } from './interfaces';
import styles from './style.css';

export const RichTextEntryListEditor = <T extends RichTextEntryItem = RichTextEntryItem>(props: RenderRichTextEntryListEditorProps<T>) => {
  return (
    <div className={styles.input}>
      <SurveyRichTextEditor className={cx(styles.editor, props.className)} />
      {props.children &&
        <div className={styles.overlay}>
          {props.children}
        </div>}
    </div>
  );
};

export const RichTextEntryListItem = <T extends RichTextEntryItem = RichTextEntryItem>({ children, ...props }: RichTextEntryListItemProps<T>) => {
  const editorProps: RenderRichTextEntryListEditorProps<T> = {
    className: cx(styles.textarea, props.editorClassName),
    children,
    disabled: props.disabled,
    placeholder: props.placeholder,
    item: props.item,
  };

  return (
    <div className={styles.root}>
      {props.renderLabel && props.renderLabel(props.item)}
      {props.renderEditor
        ? props.renderEditor(editorProps)
        : <RichTextEntryListEditor {...editorProps} />
      }
      <div className={styles.action}>
        {props.removeEnabled &&
          <MinusCircle
            color="var(--pri-04)"
            className={styles.remove}
            onClick={props.onRemove} />
        }
      </div>
    </div>
  );
};

export default RichTextEntryListItem;