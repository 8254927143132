import { useCallback, useEffect, useContext, memo } from 'react';
import { useMutation } from '@tanstack/react-query';
import type { Cell } from 'react-table';
import { format } from 'date-fns';
import { Bookmark } from 'react-feather';
import { useState } from 'react';
import { WorkspaceObjectType } from '@/enums';
import * as $api from '@api/workspaces';
import { Checkbox } from '@/components/Checkbox';
import { SelectedObjectsContext } from './context';
import type { IFilesTable } from './interfaces';
import { NameCell } from './Files.Table.Cells.Name';
import { ActionsCell } from './Files.Table.Cells.Actions';
import { TagsCell } from './Files.Table.Cells.Tags';
import styles from './style/Files.Table.Cells.css';

const OwnerCell = memo(({ row: { original: data } }: Cell<IFilesTable.Item>) => {
  return (
    <>{data.object.typeId === WorkspaceObjectType.Brand ? '-' : data.meta.owner.displayName}</>
  );
});

const CreatedOnCell = memo(({ row: { original: data } }: Cell<IFilesTable.Item>) => {
  return (
    <>{data.object.typeId === WorkspaceObjectType.Brand ? '-' : format(data.object.createdOn, 'MM/dd/yyyy')}</>
  );
});

function BookmarkCell({ row: { original: data } }: Cell<IFilesTable.Item>) {

  const [isBookmarked, setIsBookmarked] = useState(data.bookmarked);

  useEffect(() => {
    setIsBookmarked(data.bookmarked);
  }, [data.bookmarked]);

  const { mutateAsync, isLoading } = useMutation({
    mutationKey: ['file.bookmarkObject'],
    mutationFn: async () => {
      return $api.file.bookmarkObject({
        objectId: data.object.id,
        bookmark: !isBookmarked,
        workspaceId: data.object.workspaceId,
      });
    }, onSuccess: res => {
      data.bookmarked = res.bookmarked;
    },
  });

  const toggleBookmark = useCallback(() => {
    mutateAsync();
  }, [mutateAsync]);

  return (
    <button disabled={isLoading} onClick={toggleBookmark}>
      <Bookmark
        fill={data.bookmarked ? '#2596be' : 'white'}
        color={data.bookmarked ? '#2596be' : '#737373'}
        size={24} />
    </button>
  );

}

function CheckboxCell({ row: { original: data } }: Cell<IFilesTable.Item>) {
  const { selectedObjectIds, toggleSelectedObject } = useContext(SelectedObjectsContext);

  return (
    <Checkbox
      className={styles.checkbox}
      checked={selectedObjectIds.includes(data.object.id)}
      onChange={() => toggleSelectedObject(data.object.id)} />
  );
}

export default {
  Actions: ActionsCell,
  Checkbox: CheckboxCell,
  CreatedOn: CreatedOnCell,
  Name: NameCell,
  Owner: OwnerCell,
  Bookmark: BookmarkCell,
  Tags: TagsCell,
};