import { useSelector } from 'react-redux';

const selectScreeningQuestions = (projectId: number) => (state: Store.State) => state.projects[projectId]?.screeningQuestions;

const useSelectProjectScreeningQuestions = (projectId: number) => {

  return useSelector(selectScreeningQuestions(projectId));
};

export { useSelectProjectScreeningQuestions };
export default useSelectProjectScreeningQuestions;