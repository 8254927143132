import { Fragment, useContext } from 'react';
import { cx, formatCurrency } from '@utils';
import { ProjectHonorariaContext } from './Context';
import styles from './style/Project.Honoraria.css';

type Props = {
  children?: React.ReactNode;
};

export const ProjectHonorariaList = (props: Props) => {
  const ctx = useContext(ProjectHonorariaContext);

  return (
    <Fragment>
      {props.children}
      <div className={styles.header}>
        <div className={styles.title}>Title</div>
        <div className={styles.currency}>Currency</div>
        <div className={styles.amount}>Amount</div>
      </div>
      <div className={styles.table}>
        {ctx.items.map(x =>
          <div
            className={styles.wrap}
            key={x.id}>
            <div className={styles.main}>
              <div className={cx(styles.title, styles.readonly)}>{x.title}</div>
              <div className={cx(styles.currency, styles.readonly)}>{x.currency.code}</div>
              <div className={cx(styles.amount, styles.readonly)}>{formatCurrency(x.value, x.currency.code)}</div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

ProjectHonorariaList.displayName = 'Project.Honoraria.List';