import { forwardRef, memo } from 'react';
import { Loader } from 'react-feather';
import { cx } from '@utils';
import styles from './style.css';

type Props = {
  className?: string;
  color?:     string;
  size?:      number;
};

const Spinner = memo(forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <div className={cx(styles.spinner, props.className)} ref={ref}>
      <div className={styles.wrap}>
        <Loader color={props.color} size={props.size} />
      </div>
    </div>
  );
}));

export { Spinner };
export default Spinner;