import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { useAppReadyState } from '@containers/AppReadyState/hooks';
import * as selectors from '@store/selectors';
import { GetUpcomingCalls } from '@api/interfaces/users';
import { useUpcomingCalls } from '@/utils/api';
import { CallsAgenda, ConnectCalendar } from '@/components/CallsAgenda';
import { BlankCalendar } from '@/components/images';
import { ProjectCallAgendaItem } from './AgendaItem.Project';
import styles from './style/Agenda.css';

type Props = unknown;

type UpcomingCallsItem = GetUpcomingCalls.Item;

const Agenda = (props: Props) => {
  const connectedCalendar = useSelector(selectors.connectedCalendar);

  const state = useAppReadyState();

  const query = useUpcomingCalls({
    placeholderData: [],
    refetchOnWindowFocus: false,
    enabled: state.hydrated,
  });

  const renderItem = useCallback(({ item }: { item: UpcomingCallsItem }) => {
    return <ProjectCallAgendaItem item={item} />;
  }, []);

  const keyExtractor = useCallback((item: UpcomingCallsItem) => format(item.call.timeStart, 'MMM d'), []);

  const displayConnectCalendar = !query.isInitialLoading && !query.data.length && !connectedCalendar;

  return (
    <>
      <CallsAgenda
        ListEmptyComponent={ListEmpty}
        items={query.data}
        sectionKeyExtractor={keyExtractor}
        renderItem={renderItem} />
      {displayConnectCalendar &&
        <ConnectCalendar
          className={styles.connect} />
      }
    </>
  );
};

const ListEmpty = memo(() => {
  return (
    <div className={styles.empty}>
      <BlankCalendar
        className={styles.calendar} />
      <div className={styles.text}>No upcoming calls</div>
    </div>
  );
});

export { Agenda };
export default Agenda;