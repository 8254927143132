import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { getLocationFor } from '@utils';

export const useNavigateToCallDetails = (callId: number) => {
  const history = useHistory();

  return useCallback(() => {
    const location = getLocationFor.call.root({ callId });
    history.push(location);
  }, [callId, history]);
};