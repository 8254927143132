import { useCallback, useEffect, useRef } from 'react';
import IntlTelInput from 'intl-tel-input';
import { cx } from '@utils';
import { Input } from '@/components/Input';
import type { TelInputProps } from './interfaces';

type Props = TelInputProps;

const TelInput = ({ classes = {}, id = 'phone', ...props }: Props) => {
  const ref = useRef<HTMLInputElement>();
  const iti = useRef<IntlTelInput.Plugin>();

  const initialize = useCallback((r: HTMLInputElement) => {
    ref.current = r;
    iti.current = IntlTelInput(document.getElementById(id), {
      dropdownContainer: document.body,
      formatOnDisplay: false,
      separateDialCode: true,
      utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.3/build/js/utils.js',
    });
  }, [id]);

  function getTelState() {
    const num = iti.current.getNumber();

    return {
      empty: num.length === 0,
      valid: iti.current.isValidNumber(),
      value: num,
    };
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    props.onChange(e, getTelState());
  }

  const { onChangeCountryCode, value, ...rest } = props;

  useEffect(() => {

    const effectRef = ref.current;

    iti.current.setNumber((value ?? '') as string);

    const setCountryCode = e => {
      const state = getTelState();
      onChangeCountryCode(state);
      iti.current.setNumber(state.value);
    };

    effectRef.addEventListener('countrychange', setCountryCode);

    return () => {
      effectRef.removeEventListener('countrychange', setCountryCode);
    };

  }, [
    onChangeCountryCode,
    value,
  ]);

  const classnames = {
    focusable: classes.focusable || {},
    input: classes.input,
    invalid: cx(props.invalidClassName, classes.invalid),
    root: cx(props.className, classes.root),
  };

  return (
    <Input
      {...rest}
      className={classes.root}
      classes={{
        input: classnames.input,
        invalid: classnames.invalid,
      }}
      id={id}
      onChange={handleChange}
      ref={initialize}
      focusableClasses={classnames.focusable}
      invalid={props.invalid}
      type="tel" />
  );
};

export { TelInput };
export default TelInput;