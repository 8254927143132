import { Droppable } from 'react-beautiful-dnd';
import { cx } from '@/utils';
import { buildGroupPlaceholderIdentifier } from './utils';
import style from './style/GroupPlaceholder.css';

type Props = {
  prevGroupIdentifier: string;
};

export const GroupPlaceholder = (props: Props) => {
  return (
    <Droppable droppableId={buildGroupPlaceholderIdentifier(props.prevGroupIdentifier)}>
      {(provided, snapshot) => (
        <>
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={cx(style.root, snapshot.isDraggingOver ? style.over : null)}>
            <div className={style.bar} />
            <div style={{ display: 'none' }}>{provided.placeholder}</div>
          </div>
        </>
      )}
    </Droppable>
  );
};