import { Fragment, useCallback, useMemo } from 'react';
import { SurveyOptionType } from '@enums';
import { GridColumnIndicator } from '@presentation/GridColumnIndicator';
import { cx } from '@utils';
import type { SurveyQuestionFormOption, SurveyRichText } from '@/types/survey';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import { SurveyRichTextEditor, SurveyRichTextEditorContainer, useSurveyRichTextEditor } from '@/components/Survey.RichText';
import { useMatrixFormContext } from '@/components/SurveyForm/context';
import type { MatrixForm } from '@/components/SurveyForm/interfaces';
import SurveyMatrixGridRowItem from './GridRowItem';
import styles from './style/Grid.css';

const NAWidth = 8;
const NameWidth = 20;
const MaxCellWidth = 180;

type Props = {
  className?: string;
};

export const SurveyMatrixGrid = (props: Props) => {

  const {
    onSelect,
    onUpdateRowText,
    ...ctx
  } = useMatrixFormContext();

  const nonNAOptions = useMemo(() => {
    return ctx.options.filter(f => f.type !== SurveyOptionType.NotApplicable);
  }, [ctx.options]);
  const naOption = useMemo(() => ctx.options.find(f => f.type === SurveyOptionType.NotApplicable), [ctx.options]);

  const maxNameWidth = useMemo(() => {
    const maxRowLength = Math.max(...ctx.rows.map(m => parseSurveyRichText(m.value).length));
    const value = Math.max(Math.min(maxRowLength, 50), 20);
    return `${value}%`;
  }, [ctx.rows]);

  const gridWidth = useMemo(() => {
    return !naOption
      ? 100 - NameWidth
      : 100 - NameWidth - NAWidth;
  }, [naOption]);

  const cellWidth = useMemo(() => {
    const width = gridWidth / nonNAOptions.length;
    return `${width}%`;
  }, [gridWidth, nonNAOptions.length]);

  const renderHeaderNACell = useCallback((option: SurveyQuestionFormOption) => {
    return (
      <div className={styles.headerNa}>
        <div className={styles.headerCellWrap}>
          <div className={styles.headerTitle}>{parseSurveyRichText(option.value)}</div>
        </div>
      </div>
    );
  }, []);

  const renderHeader = useCallback(() => {
    return (
      <div className={styles.header}>
        <div
          className={styles.rowName}
          style={{ maxWidth: maxNameWidth }} />
        <div style={{
          width: `${gridWidth}%`,
          maxWidth: `${MaxCellWidth * nonNAOptions.length}px`,
        }}>
          <GridColumnIndicator
            values={nonNAOptions.map(o => {
              return <RichText key={o.id} value={o.value} />;
            })} />
        </div>
        {naOption && renderHeaderNACell(naOption)}
      </div>
    );
  }, [
    gridWidth,
    maxNameWidth,
    naOption,
    nonNAOptions,
    renderHeaderNACell,
  ]);

  const renderItem = useCallback((row: MatrixForm.Row, index: number) => {
    const rowText = ctx.rowTextMap[row.id];

    const handleSelect = (optionId: number) => onSelect({
      optionId,
      rowId: row.id,
    });

    if (ctx.variant === 'single') {
      return (
        <SurveyMatrixGridRowItem
          key={row.id}
          cellClassName={cx({
            [styles.cellLast]: index === ctx.rows.length - 1,
          })}
          cellWidth={cellWidth}
          maxNameWidth={maxNameWidth}
          options={ctx.options}
          openEndedValue={rowText}
          row={row}
          updateRowAnswer={handleSelect}
          updateRowTextValue={onUpdateRowText(row.id)}
          variant="single" />
      );
    } else {
      return (
        <SurveyMatrixGridRowItem
          key={row.id}
          cellClassName={cx({
            [styles.cellLast]: index === ctx.rows.length - 1,
          })}
          cellWidth={cellWidth}
          maxNameWidth={maxNameWidth}
          options={ctx.options}
          openEndedValue={rowText}
          row={row}
          updateRowTextValue={onUpdateRowText(row.id)}
          updateRowAnswer={handleSelect}
          variant="multiselect" />
      );
    }

  }, [
    cellWidth,
    maxNameWidth,
    ctx.options,
    ctx.rows.length,
    ctx.rowTextMap,
    ctx.variant,
    onSelect,
    onUpdateRowText,
  ]);

  const renderItems = useCallback(() => {
    return (
      <>
        {ctx.rows.map((row, i) => {
          const lastRow = i === 0 ? null : ctx.rows[i - 1];
          const showHeader = lastRow ? lastRow.metadata.isLastRowInCurrentGrid : true;
          //  || (
          //   ctx.scaleDisplayFrequency &&
          //     i % ctx.scaleDisplayFrequency === 0
          // );
          return (
            <Fragment key={row.id}>
              {showHeader && renderHeader()}
              {renderItem(row, i)}
            </Fragment>
          );
        })}
      </>
    );
  }, [
    renderHeader,
    renderItem,
    ctx.rows,
  ]);

  return (
    <div className={cx(styles.root, props.className)}>
      {renderItems()}
    </div>
  );
};

const RichText = (props: { value: SurveyRichText.RichTextValue }) => {
  const { manager, state, onChange } = useSurveyRichTextEditor({
    initialState: props.value,
  });

  return (
    <SurveyRichTextEditorContainer
      editable={false}
      manager={manager}
      state={state}
      onChange={onChange}>
      <SurveyRichTextEditor />
    </SurveyRichTextEditorContainer>
  );
};

export default SurveyMatrixGrid;