import { MessageCircle } from 'react-feather';
import { cx } from '@utils';
import { Button } from '@/components/Button';
import styles from './style/Comment.Button.css';

type Props = {
  className?: string;
  onClick: () => void;
};

const CommentButton = (props: Props) => {
  return (
    <Button
      className={cx(styles.btn, props.className)}
      color="transparent"
      onClick={props.onClick}
      variant="text">
      <MessageCircle height={18} /> Comment
    </Button>
  );
};

export { CommentButton as Button };