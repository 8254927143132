import { useCallback, useMemo } from 'react';
import type { EnumKey } from '@enums/interfaces';
import { cx } from '@utils';
import { DropDown } from './DropDown';
import type { EnumDropDownItem, EnumDropDownProps } from './interfaces';
import styles from './style.css';

export function EnumDropDown<T extends EnumKey = EnumKey>({ onSelect, placeholder, value, autoFocus, disabled, ...props }: EnumDropDownProps<T>) {
  const items = useMemo(() => {
    const ret = (props.items || props.enum.values()).map(k => ({
      id: k,
      name: props.enum.getName(k),
    }));

    if (props.sort) {
      ret.sort((a, b) => a.name.localeCompare(b.name));
    }

    return ret;
  }, [props.enum, props.items, props.sort]);

  const handleSelect = useCallback((item: EnumDropDownItem<T>) => {
    onSelect(item.id);
  }, [onSelect]);

  const text = useMemo(() => props.enum.getName(value), [props.enum, value]);

  const textClass = cx({
    [styles.placeholder]: !text,
  });

  return (
    <DropDown<EnumDropDownItem<T>>
      autoFocus={autoFocus}
      items={items}
      getItemValue={i => i.name}
      onSelect={handleSelect}
      placeholder={placeholder}
      text={text}
      disabled={disabled}
      classes={{ text: textClass }} />
  );
}

export default EnumDropDown;