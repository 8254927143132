import { useCallback } from 'react';
import { InfoOutlined as InfoOutlinedIcon, BugReport } from '@mui/icons-material';
import { cx } from '@utils';
import { useLiveRoomSelfDialOut, useLogDebugData } from '@containers/Conference';
import { Button } from '@/components/Button';
import { Tooltip } from '@presentation/Tooltip';
import { ConferenceLink } from '@/components/Conference.Entry/ConferenceLink';
import { PhoneInformation } from '@/components/Conference.Entry/PhoneInformation';
import { GuestLinks } from '@/components/Conference.Entry/GuestLinks';
import Toast from '@/components/Toast';
import CameraQuality from '../CameraQuality';
import NetworkQuality from '../NetworkQuality';
import SecureConnection from '../SecureConnection';
import { Settings } from './Settings';
import { InformationProps, InformationIconProps } from './interfaces';
import styles from './styles/Information.css';

export function Information(props: InformationProps) {

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <PhoneInformation
          className={styles.row}
          {...props.dial} />
        {props.dial.guestPins &&
          <GuestLinks conferenceIdentifier={props.conferenceIdentifier} pins={props.dial.guestPins} />
        }
        {!props.dial.guestPins &&
          <ConferenceLink
            className={styles.row}
            conferenceIdentifier={props.conferenceIdentifier}
            pin={props.dial?.pin ?? props.dial?.guestPin} />
        }
        <Settings />
      </div>
      <div className={styles.bottom}>
        <ConnectViaPhone />
        <NetworkQuality {...props.network} />
        <CameraQuality {...props.camera} />
        <SecureConnection />
        <DebugSection />
      </div>
    </div>
  );
}

const NetworkTest = () => {
  return (
    <a
      className={styles.networkTest}
      href="/conference/network-test"
      target="_blank"
      rel="noreferrer">
      Network Test
    </a>
  );
};

const ConnectViaPhone = () => {
  const { showModal } = useLiveRoomSelfDialOut();

  return (
    <Button
      className={styles.dialOutBtn}
      size='small'
      variant='brick'
      onClick={() => showModal('')}>
      Connect via Phone
    </Button>
  );
};

export const InformationIcon = (props: InformationIconProps) => (
  <div
    className={cx(styles.icon, { [styles.active]: props.active }, props.className)}
    onClick={props.onClick}>
    <InfoOutlinedIcon />
  </div>
);

const DebugSection = () => {
  const logDebug = useLogDebugData();
  const onLogClick = useCallback(() => {
    logDebug();
    Toast.success({
      title: `We've sent your information to our developers!`,
    });
  }, [logDebug]);
  return (
    <div className={styles.debugSection}>
      <NetworkTest />
      <div>
        <Tooltip title='Send local data to our developers!'>
          <BugReport className={styles.bugReport} onClick={onLogClick} />
        </Tooltip>
      </div>
    </div>
  );
};

Information.defaultProps = {
  active: false,
};

export default Information;