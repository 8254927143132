import { useMemo } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useHasGroupFeature } from '@/store/hooks';
import { useIsInternalUser } from '@/containers/Store/hooks';
import { CellCreatedOn, CellFileName, CellFileTags, CellOwner } from '../Cells';

export default function useFilesTableColumns() {
  const isDesktop = useMediaQuery('(min-width:900px)');
  const hasTagging = useHasGroupFeature('userTagging');
  const isInternalUser = useIsInternalUser();

  return useMemo(() => [
    {
      accessor: 'name',
      id: 'name',
      Header: 'Name',
      Cell: CellFileName,
      minWidth: 305,
    },
    isDesktop && hasTagging ? {
      accessor: 'tags',
      id: 'tags',
      Header: 'Tags',
      Cell: CellFileTags,
      minWidth: 175,
      maxWidth: 0,
    } : null,
    isInternalUser && isDesktop ? {
      accessor: 'owner',
      id: 'owner',
      Header: 'Owner',
      Cell: CellOwner,
      width: 80,
    } : undefined,
    isDesktop ? {
      accessor: 'createdOn',
      id: 'createdOn',
      Header: 'Created On',
      Cell: CellCreatedOn,
      minWidth: 80,
      width: 80,
    } : undefined,
  ].filter(Boolean), [isInternalUser, isDesktop, hasTagging]);
}

export { useFilesTableColumns };