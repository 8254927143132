import { useContext, useCallback, useState, useMemo } from 'react';
import { SelectedTermsContext, TranscriptReplacementsContext } from '@containers/Group.Project.Terms';
import { RadioButton } from '@/components/Radio';
import type { GlobalReplacement } from '@/containers/Group.Project.Terms/interfaces';
import type { UseModalProps } from '@/components/Modal';
import { Modal, Header, useModal } from '@/components/Modal';
import { Button } from '@/components/Button';
import { TransformedProjectTermsContext } from './Context';
import styles from './styles/MergeTermsModal.css';

export const MergeTermsModal = (props: UseModalProps) => {
  const { selectedTermValues, clearTermValues } = useContext(SelectedTermsContext);
  const { addGlobalReplacements } = useContext(TranscriptReplacementsContext);
  const { terms } = useContext(TransformedProjectTermsContext);
  const [selectedTerm, setSelectedTerm] = useState('');

  const mergeableTerms = useMemo(() => {
    return terms.filter(t => selectedTermValues.includes(t.termValue));
  }, [selectedTermValues, terms]);

  const onMerge = useCallback(() => {
    const replacements = mergeableTerms.filter(v => v.termValue !== selectedTerm).map<GlobalReplacement>(t => ({
      replacementText: selectedTerm,
      originalText: t.termValue,
      transcriptIds: t.transcriptIds,
      sourceEntities: t.entities,
    }));

    addGlobalReplacements(replacements);
    clearTermValues();
    props.onClose();
  }, [mergeableTerms, addGlobalReplacements, clearTermValues, props, selectedTerm]);
  return (
    <Modal {...props}>
      <Header>Choose Term to Merge Into</Header>
      <div>
        {mergeableTerms.map(t => (
          <div key={t.termValue} className={styles.termRow}>
            <RadioButton checked={t.termValue === selectedTerm} onClick={() => setSelectedTerm(t.termValue)} />
            {t.termValue}
          </div>
        ))}
      </div>
      <div className={styles.buttons}>
        <Button
          variant='brick'
          color='destructive'
          onClick={props.onClose}>Cancel
        </Button>
        <Button
          variant='brick'
          onClick={onMerge}
          disabled={!selectedTerm}>Merge
        </Button>
      </div>
    </Modal>
  );
};

export const useMergeTermsModal = () => useModal(MergeTermsModal);