export type ResponseItem = {
  type: 'bullets';
  value: string[];
} | {
  type: 'text';
  value: string;
} | {
  type: 'numbered';
  value: string[];
};

const bulletCharacters = ['*', '-', '•'];

function parseNumberedInput(input: string) {
  const regex = /^\d+\.\s*(.*)/;
  const match = input.match(regex);

  if (match) {
    return match;
  }

  return null;
}

export function parseResponse(value: string) {
  const lines = value.split('\n');

  const lineItems: ResponseItem[] = [];

  let bulletBuffer: string[] = [];
  let numberBuffer: string[] = [];
  for (const line of lines) {
    const isBulletedItem = line.length && bulletCharacters.includes(line[0]);

    if (isBulletedItem) {
      bulletBuffer.push(line.length > 1 && line[1] === ' ' ? line.slice(2) : line.slice(1));
      continue;
    }

    const parsed = parseNumberedInput(line);
    const isNumberedItem = !!parsed;

    if (isNumberedItem) {
      numberBuffer.push(parsed[1]);
      continue;
    }

    if (bulletBuffer.length) {
      lineItems.push({
        type: 'bullets',
        value: bulletBuffer,
      });

      bulletBuffer = [];
    } else if (numberBuffer.length && !!line.length) {
      lineItems.push({
        type: 'numbered',
        value: numberBuffer,
      });

      numberBuffer = [];
    } else if (!numberBuffer.length) {
      lineItems.push({
        type: 'text',
        value: line,
      });
    }
  }

  if (bulletBuffer.length) {
    lineItems.push({
      type: 'bullets',
      value: bulletBuffer,
    });

    bulletBuffer = [];
  }

  if (numberBuffer.length) {
    lineItems.push({
      type: 'numbered',
      value: numberBuffer,
    });

    numberBuffer = [];
  }

  return lineItems;
}