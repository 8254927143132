import {
  SurveyItem,
} from '@/types/survey';
import { OrdinalMap } from '../interfaces';

export const syncItemOrdinals = (ordinals: OrdinalMap) => (item: SurveyItem): SurveyItem => {
  return {
    ...item,
    ordinal: ordinals[item.identifier],
  };
};
