import { AudioPlayerContainer } from '@containers/AudioPlayer';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { useLock } from '@utils';
import { AudioPlayer } from '@/components/AudioPlayer';
import { Button } from '@/components/Button';
import styles from './style/Audio.css';

type Props = {
  canSave: boolean;
  onSave: () => Promise<unknown>;
  onViewText: () => unknown;
  url: string;
};

const PreCallRecordingAudio = ({ canSave, onSave, onViewText, url }: Props) => {

  const [busy, lock] = useLock();

  return (
    <div>
      <div className={styles.player}>
        <AudioPlayer
          audio={{
            preload:'metadata',
            src: url,
          }} />
      </div>
      <div className={styles.footer}>
        <Button.Secondary
          title="Update Text"
          variant="brick"
          onClick={onViewText} />
        <ButtonActivityIndicator
          variant="brick"
          disabled={!canSave}
          loading={busy}
          onClick={lock(onSave)}>
          Save
        </ButtonActivityIndicator>
      </div>
    </div>
  );
};

const PreCallRecordingAudioConnected = (props: Props) => {
  return (
    <AudioPlayerContainer>
      <PreCallRecordingAudio {...props} />
    </AudioPlayerContainer>
  );
};

export default PreCallRecordingAudioConnected;