import { SurveyItemType } from '@/enums';
import type { SurveyBuilderVersion, SurveyQuestion, SurveyItem, SurveyRichText, SurveyTextKeyMap, SurveyMessage, SurveyQuestionMatrixRow } from '@/types';
import { Remirror } from '@/types';

export const getTranslatedSurveyBuilderVersion = (languageCode: string, survey: SurveyBuilderVersion): SurveyBuilderVersion => {
  const textKeys = survey.language.languageKeyMap[languageCode];

  if (!textKeys || !Object.keys(textKeys).length) return survey;

  return {
    ...survey,
    questions: importQuestionTextKeys(structuredClone(survey.questions), textKeys),
    messages: importMessageTextKeys(structuredClone(survey.messages), textKeys),
  };
};

export const getSurveyTextKeys = (survey: SurveyBuilderVersion) => {
  const textEntries = extractQuestionTextKeys(survey.questions, survey.items);

  const messageEntries = extractMessageTextKeys(survey.messages, survey.items);

  return [...textEntries, ...messageEntries];
};

export const extractQuestionTextKeys = (questions: SurveyQuestion[], items: SurveyItem[]) => {
  const textEntries: SurveyTextEntry[] = [];
  for (const q of questions) {
    if (q.metadata.derivedType) continue;

    const questionPrefix = buildQuestionPrefix(q);
    const item = items.find(i => i.source.identifier === q.base.identifier);
    textEntries.push({ key: combineKeys(questionPrefix, 'value'), value: serializeRichText(q.value), title: `Q${item.ordinal} Text` });

    if (q.options) {
      for (const o of q.options) {
        const optionPrefix = `option:${o.base.identifier}`;
        textEntries.push({
          key: combineKeys(questionPrefix, optionPrefix, 'value'),
          value: serializeRichText(o.value),
          title: `Q${item.ordinal}:Option${o.ordinal} Text`,
        });

        for (const field of extractGenericRichTextFields(o.metadata)) {
          textEntries.push({
            key: combineKeys(questionPrefix, optionPrefix, 'metadata', field.key),
            value: serializeRichText(field.value),
            title: `Q${item.ordinal}:Option${o.ordinal} Metadata ${field.key}`,
          });
        }
      }
    }

    if (q.matrixRows) {
      for (const r of q.matrixRows) {
        const rowPrefix = `row:${r.base.identifier}`;
        textEntries.push({
          key: combineKeys(questionPrefix, rowPrefix, 'value'),
          value: serializeRichText(r.value),
          title: `Q${item.ordinal}:Row${r.ordinal} Text`,
        });

        for (const field of extractGenericRichTextFields(r.metadata)) {
          textEntries.push({
            key: combineKeys(questionPrefix, rowPrefix, 'metadata', field.key),
            value: serializeRichText(field.value),
            title: `Q${item.ordinal}:Row${r.ordinal} Metadata ${field.key}`,
          });
        }
      }
    }

    for (const field of extractGenericRichTextFields(q.settings)) {
      textEntries.push({
        key: combineKeys(questionPrefix, 'settings', field.key),
        value: serializeRichText(field.value),
        title: `Q${item.ordinal} Settings ${field.key}`,
      });
    }
  }

  return textEntries;
};

export const extractMessageTextKeys = (messages: SurveyMessage[], items: SurveyItem[]) => {
  const textEntries: SurveyTextEntry[] = [];

  for (const message of messages) {
    const messagePrefix = buildMessagePrefix(message);
    const item = items.find(i => i.source.identifier === message.identifier);

    textEntries.push({
      key: combineKeys(messagePrefix, 'value'),
      value: serializeRichText(message.value),
      title: `Message ${item.ordinal} Value`,
    });
  }

  return textEntries;
};

export const extractItemTextKeys = (survey: SurveyBuilderVersion, itemIdentifier: string) => {
  const item = survey.items.find(i => i.identifier === itemIdentifier);

  switch (item.type) {
    case SurveyItemType.Question: {
      const question = survey.questions.find(q => q.base.identifier === item.source.identifier);
      return extractQuestionTextKeys([question], [item]);
    }
    case SurveyItemType.Message: {
      const message = survey.messages.find(m => m.identifier === item.source.identifier);

      return extractMessageTextKeys([message], [item]);
    }
    case SurveyItemType.AlternateImageExercise: {
      return [];
    }
    default: {
      throw new UnreachableCaseError(item.type);
    }
  }
};

function extractGenericRichTextFields(obj: object) {
  if (!obj) return [];

  const results: { key: string; value: SurveyRichText.RootNode }[] = [];

  for (const [k, v] of Object.entries(obj)) {
    if (typeof v === 'object' && !!v) {
      if (objectIsRichText(v)) {
        results.push({ key: k, value: v });
      } else {
        for (const r of extractGenericRichTextFields(v as object)) {
          results.push({ key: combineKeys(k, r.key), value: r.value });
        }
      }
    }
  }

  return results;
}

function objectIsRichText(obj: unknown): obj is SurveyRichText.RootNode {
  return typeof obj === 'object' && obj['type'] === Remirror.NodeType.Doc;
}

function serializeRichText(value: SurveyRichText.RootNode) {
  return value;
}

export const importQuestionTextKeys = (questions: SurveyQuestion[], textKeys: SurveyTextKeyMap) => {
  const splitKeys = parseImportKeys(textKeys);

  for (const question of questions) {
    const questionPrefix = buildQuestionPrefix(question);

    importGenericRichTextFields(question, questionPrefix, splitKeys, parseAccessor);
  }

  return questions;

  function parseAccessor(question: SurveyQuestion, key: string) {
    if (key.includes(':')) {
      const [type, identifier] = key.split(':');

      switch (type) {
        case 'question': {
          const index = questions.findIndex(q => q.base.identifier === identifier);

          return ['questions', index];
        }
        case 'option': {
          const index = question.options?.findIndex(o => o.base.identifier === identifier);

          return ['options', index];
        }
        case 'row': {
          const index = question.matrixRows.findIndex((r: SurveyQuestionMatrixRow) => r.base.identifier === identifier);

          return ['matrixRows', index];
        }
      }
    } else {
      return [key];
    }
  }
};

export const importMessageTextKeys = (messages: SurveyMessage[], textKeys: SurveyTextKeyMap) => {
  const parsedKeys = parseImportKeys(textKeys);
  for (const message of messages) {
    const messagePrefix = buildMessagePrefix(message);

    importGenericRichTextFields(message, messagePrefix, parsedKeys, (obj, accessor) => [accessor]);
  }

  return messages;
};

function importGenericRichTextFields<T extends object>(obj: T, basePrefix: string, parsedKeys: ParsedImportKeys, accessorParser: AccessorParser<T>): T {

  for (const entry of parsedKeys.filter(x => x.keys[0] === basePrefix)) {
    const accessors = entry.keys.slice(1).flatMap(k => accessorParser(obj, k));

    //console.log(accessors);

    let currObj: object = obj;
    for (let i = 0; i < accessors.length - 1; i++) {
      const currAccessor = accessors[i];
      if (currObj[currAccessor]) {
        currObj = currObj[currAccessor] as object;
      } else {
        //console.log(`Couldn't find accessor ${currAccessor} of ${combineKeys(...entry.keys)}`);
        currObj = null;
        break;
      }
    }

    const finalAccessor = accessors[accessors.length - 1];
    if (currObj && currObj[finalAccessor]) {
      if (typeof currObj[finalAccessor] === typeof entry.value) {
        currObj[finalAccessor] = entry.value;
      } else {
        //console.log(`Trouble finding the accessor: ${combineKeys(...entry.keys)}`)
        //TODO: might need to log an error here. we can probably automatically convert a string to RT but assigning RT to a string could lead to problems if the type doesnt support RT
      }
    }
  }

  return obj;
}

export const getMissingLanguageKeys = (survey: SurveyBuilderVersion) => {
  const missingKeys: Record<string, string[]> = {};
  const additionalLanguages = survey.language.languages.filter(l => !l.isDefault).map(l => l.code);
  if (!additionalLanguages.length) return missingKeys;

  for (const l of additionalLanguages) {
    missingKeys[l] = [];
  }

  for (const keyEntry of getSurveyTextKeys(survey)) {
    for (const l of additionalLanguages) {
      if (!survey.language.languageKeyMap?.[l]?.[keyEntry.key]) {
        missingKeys[l].push(keyEntry.title);
      }
    }
  }

  return missingKeys;
};

function buildQuestionPrefix(question: SurveyQuestion) {
  return `${ItemTypeKey[SurveyItemType.Question]}:${question.base.identifier}`;
}

function buildMessagePrefix(message: SurveyMessage) {
  return `${ItemTypeKey[SurveyItemType.Message]}:${message.identifier}`;
}

function parseImportKeys(textKeys: SurveyTextKeyMap): ParsedImportKeys {
  return Object.entries(textKeys).map(([k, v]) => ({ keys: splitKey(k), value: v }));
}

function combineKeys(...keys: string[]) {
  return keys.join('.');
}

function splitKey(key: string) {
  return key.split('.');
}

type ParsedImportKeys = {
  keys: string[];
  value: string | SurveyRichText.RootNode;
}[];

type AccessorParser<T extends object> = (obj: T, accessor: string) => (string | number)[];

const ItemTypeKey: Record<SurveyItemType, string> = {
  [SurveyItemType.Question]: 'q',
  [SurveyItemType.Message]: 'm',
  [SurveyItemType.AlternateImageExercise]: 'aie',
};

type SurveyTextEntry = {
  key: string;
  value: SurveyRichText.RootNode;
  title: string;
};