import { useMemo, useCallback } from 'react';
import type { InvalidContentHandler } from '@remirror/core';
import type { EditorState } from '@remirror/pm';
import {
  Remirror,
  useRemirror,
  EditorComponent,
} from '@remirror/react';
import type { DocumentNode } from '@/types/transcribe.rich-text';
import { MonologueDetailsExtension, MonologueTextExtension, KaraokeExtension, TimestampExtension } from './Extension';
import { KaraokeMarker } from './KaraokeMarker';
import { SnippetMonologueDetails } from './Snippet.MonologueDetails';
import { useMediaTimeChangeEvent, useSeekEvent } from './hooks';
import { buildDocumentSnippet } from './utils';

type Props = {
  from: number;
  to: number;
  state: EditorState;
};

export const TranscriptSnippet = ({ from, to, state: parentState }: Props) => {
  const document = useMemo<DocumentNode>(() => {
    return buildDocumentSnippet(parentState.doc, from, to);
  }, [from, to, parentState]);

  const onError: InvalidContentHandler = useCallback(({ json, invalidContent, transformers }) => {
    console.error('Invalid Content', invalidContent);
    return transformers.remove(json, invalidContent);
  }, []);

  const offset = 0 - from + 2; //Add 1 for the beginning of the doc and 1 for the monologue details node that we artificially add

  const { manager, state } = useRemirror({
    extensions: () => [
      //TODO: add a way of custom styling the monologue details node
      new MonologueDetailsExtension(SnippetMonologueDetails),
      new MonologueTextExtension(),
      new TimestampExtension(),
      new KaraokeExtension(offset),
    ],
    content: document,
    onError,
  });

  return (
    <Remirror
      manager={manager}
      editable={false}
      initialContent={state}>
      <Snippet />
    </Remirror>
  );
};

const Snippet = () => {
  useMediaTimeChangeEvent();
  useSeekEvent();
  return (
    <>
      <EditorComponent />
      <KaraokeMarker />
    </>
  );
};