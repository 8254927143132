import type { ReactNode } from 'react';
import { useRef, useEffect, useCallback, useState, useMemo } from 'react';
import { Pause, Play } from 'react-feather';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Error } from '@mui/icons-material';
import styles from './styles/AudioPreview.css';

type Props = {
  audioUrl: string;
  from: number;
  to: number;
};

type AudioState = 'loading' | 'playing' | 'paused' | 'error';

export const AudioPreview = (props: Props) => {
  const [audioEl, setAudioEl] = useState<HTMLAudioElement>();
  const [audioState, setAudioState] = useState<AudioState>('paused');
  const [progressPct, setProgressPct] = useState<number>(0);

  const onClick = useCallback(() => {
    if (audioEl.paused) {
      if (audioEl.currentTime > props.to) {
        audioEl.currentTime = props.from;
      }
      audioEl.play();
    } else {
      audioEl.pause();
    }
  }, [audioEl, props.to, props.from]);

  const calculateProgress = useCallback(() => {
    const val = (audioEl.currentTime - props.from) / (props.to - props.from) * 100;
    setProgressPct(Math.max(0, Math.min(100, val)));
  }, [audioEl, props.from, props.to]);

  useEffect(() => {
    if (audioEl) {
      audioEl.onpause = () => setAudioState('paused');
      audioEl.onplay = () => setAudioState('playing');
      audioEl.onerror = () => setAudioState('error');

      audioEl.ontimeupdate = () => {
        if (audioEl.currentTime > props.to) {
          audioEl.pause();
        }
        calculateProgress();
      };
    }
  }, [audioEl, props.to, calculateProgress]);

  const playerButtons = useMemo<Record<AudioState, ReactNode>>(() => {
    return {
      'paused': <Play className={styles.playIcon} />,
      'playing': <Pause className={styles.pauseIcon} />,
      'loading': null,
      'error': <Error className={styles.icon} />,
    };
  }, []);

  if (props.from == null || props.to == null || !props.audioUrl) return null;

  return (
    <>
      <audio
        ref={setAudioEl}
        className={styles.audio}
        src={`${props.audioUrl}#t=${props.from},${props.to}`}
        autoPlay={false}
        preload='none' />
      <div className={styles.player} onClick={onClick}>
        <CircularProgressbar
          value={progressPct}
          maxValue={100}
          styles={buildStyles({
            // Rotation of path and trail, in number of turns (0-1)
            rotation: 0,

            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: 'butt',

            // Text size
            textSize: '16px',

            // How long animation takes to go from one percentage to another, in seconds
            pathTransitionDuration: 0.5,

            // Can specify path transition in more detail, or remove it entirely
            // pathTransition: 'none',

            // Colors
            pathColor: `var(--pri-02)`,
            textColor: '#f88',
            trailColor: '#d6d6d6',
            backgroundColor: '#3e98c7',
          })} />
        {playerButtons[audioState]}
      </div>
    </>
  );
};