import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';

export const useSubmitSignature = () => {
  const dispatch = useDispatch();

  const submitSignature = () => {
    return api.documents.fetchOwnDocuments()
      .then(res => dispatch(actions.documentsFetched(res)))
      .catch(e => console.log(e));
  };

  return submitSignature;
};

export default useSubmitSignature;