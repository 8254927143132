import { useTemplateDoseAdministrationModal } from '@/components/Survey.Templates/Modal.DoseAdministration';
import { TemplateItemLabel } from './Label.Base';
import { TemplateLabelColor } from './Label.colors';

type Props = {
  className?: string;
  disabled: boolean;
  value: string;
};

export const TemplateDoseAdministration = ({ className, disabled, value }: Props) => {

  const [toggleModal, Modal] = useTemplateDoseAdministrationModal();

  return (
    <>
      <TemplateItemLabel
        className={className}
        color={TemplateLabelColor.Seven}
        disabled={disabled}
        label={value}
        tooltip="Dosing & Administration"
        onClick={toggleModal} />
      {!disabled && <Modal />}
    </>
  );
};