import { cx } from '@utils';
import Button from '@/components/Button';
import { useConnectCalendarModal } from '@/components/Modal/ConnectCalendar';
import styles from './style/ConnectCalendar.css';

type Props = {
  className?: string;
};

const ConnectCalendar = (props: Props) => {
  const [toggleConnectModal, ConnectCalendarModal] = useConnectCalendarModal();

  return (
    <>
      <div className={cx(styles.root, props.className)}>
        <div className={styles.wrap}>
          <div className={styles.desc}>
            <div>Connect your calendar for a </div>
            <div>streamlined scheduling experience</div>
          </div>
          <Button.Secondary
            className={styles.connect}
            onClick={toggleConnectModal}
            variant="brick">
            Connect Calendar
          </Button.Secondary>
        </div>
      </div>
      <ConnectCalendarModal />
    </>
  );
};

export { ConnectCalendar };
export default ConnectCalendar;