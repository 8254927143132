import { useState } from 'react';
import { MessageSquare } from 'react-feather';
import { cx } from '@utils';
import { IChat } from '@/types';
/* import { RequestProjectCallModal } from '@/components/RequestCall'; */
import Channel from './Channel';
import styles from './style/Messenger.css';

type Props = {
  conversation:    IChat.DirectoryItemConversation;
  participant:     IChat.DirectoryItemParticipant;
  messengerActive: boolean;
  onClose:         () => unknown;
  onDelete:        (channelSid: string) => unknown;
  onScheduleCall:  () => unknown;
  sendMessage:     (data: { body: string; paid: boolean }) => unknown;
};

const Messenger = (props: Props) => {
  const [modal, setModal] = useState<IChat.DirectoryItemParticipant>(null);

  const handleScheduleCall = () => {
    setModal(props.participant);
  };

  const closeModal = () => {
    setModal(null);
  };

  const Placeholder = () => {
    return (
      <div className={styles.placeholder}>
        <MessageSquare className={styles.icon} />
      </div>
    );
  };

  const view = props.conversation
      ? <Channel
          conversation={props.conversation}
          participant={props.participant}
          onClose={props.onClose}
          onDelete={props.onDelete}
          onScheduleCall={handleScheduleCall}
          sendMessage={props.sendMessage} />
      : <Placeholder />;

  const classnames = cx({
    [styles.visible]: props.messengerActive,
    [styles.hidden]: !props.messengerActive,
  });

  return (
    <>
      <div className={classnames}>
        {view}
      </div>
      {/* modal?.contact?.id &&
        <RequestProjectCallModal
          onClose={closeModal}
          user={modal?.contact}
  open={!!modal?.contact?.id} /> */}
    </>
  );
};

export { Messenger };
export default Messenger;