import { useContext, useMemo } from 'react';
import { ProjectDetailsContext, ProjectStateContext } from '../Context';

export const useProjectState = () => {
  const project = useContext(ProjectStateContext);
  return project;
};

export const useProjectOrLatestChild = () => {
  const state = useProjectState();

  return useMemo(() => {
    const project = state?.project || state?.latestProject;
    if (!project) return null;
    return project;
  }, [state?.project, state?.latestProject]);
};

export default useProjectState;

export const useProjectQueryContext = () => {
  const { query } = useContext(ProjectDetailsContext);

  return query;
};