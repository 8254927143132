import { useState } from 'react';
import { CaptureSheetQuestionScreen } from '@screens/Survey';
import { SurveyFormScreen } from '@/containers/SurveyForm/Screen';
import { SurveyFormRootElementContext } from '@/containers/SurveyForm';
import { useSurveyFormScreenContext } from '@containers/SurveyForm/Context';
import { LeftBarMainActionContainer } from './LeftBarActionsContainer';
import { LeftBar } from './LeftBar';
import { FormControls } from './FormControls';
import { useLeftBarContext } from './context';
import styles from './style/Form.css';

export const SurveyForm = () => {
  const [state] = useSurveyFormScreenContext();

  return (
    <SurveyFormScreen
      key={state.item.id}
      WrapperComponent={WrapperComponent}
      QuestionScreenComponent={CaptureSheetQuestionScreen as React.ComponentType<{ hideNavigation?: boolean }>} />
  );
};

type WrapperProps = {
  Component: React.ComponentType;
};

const WrapperComponent = ({ Component: ItemComponent }: WrapperProps) => {
  const { zoomValue } = useLeftBarContext();
  const [widthDiv, setWidthDiv] = useState<HTMLDivElement>(null);

  //Don't render the inner content until we have the widthDiv, it's used as some hook deps and we dont want multiple renders
  return (
    <LeftBarMainActionContainer>
      <div className={styles.root}>
        <LeftBar />
        <div
          ref={setWidthDiv}
          className={styles.formRoot}
          style={{ transform: `scale(${zoomValue / 100}`, transformOrigin: `50% 0%` }}>
          <SurveyFormRootElementContext.Provider value={widthDiv}>
            {widthDiv && <ItemComponent />}
          </SurveyFormRootElementContext.Provider>
          <FormControls />
        </div>
      </div>
    </LeftBarMainActionContainer>
  );
};