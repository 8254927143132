import { useContext, useMemo } from 'react';
import { compareAsc, differenceInMinutes, isSameMinute } from 'date-fns';
import { format, lightFormat, setSeconds } from 'date-fns/fp';
import { CalendarRange } from '@/types';
import { Accordion } from '@/components/Accordion';
import { Segment } from './AvailabilitySchedule.Segment';
import { SettingsContext } from './Context';
import styles from './style/AvailabilitySchedule.css';

type Props<T> = {
  data?:        T;
  items:        CalendarRange<Date>[];
  suggested?:   CalendarRange<Date>[];
  renderEmpty:  (data: T) => React.ReactNode;
  renderHeader: (data: T) => React.ReactNode;
};

export const Section = <T,>(props: Props<T>) => {
  const ctx = {
    settings: useContext(SettingsContext),
  };

  const items = useMemo(() => {
    const eventsmap = makeEventsMap(props.items);

    return makeSections(eventsmap, ctx.settings.conference.defaultDuration);
  }, [
    ctx.settings.conference.defaultDuration,
    props.items,
  ]);

  const suggested = useMemo(() => {
    const eventsmap = makeEventsMap(props.suggested || []);

    return makeSections(eventsmap, ctx.settings.conference.defaultDuration);
  }, [
    ctx.settings.conference.defaultDuration,
    props.suggested,
  ]);

  const empty = useMemo(() => {
    return items.length < 1
        && suggested.length < 1;
  }, [
    items.length,
    suggested.length,
  ]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        {props.renderHeader(props.data)}
        <div className={styles.main}>
          {empty && props.renderEmpty(props.data)}
          {items.map(x =>
            <Accordion
              classes={{
                label: styles.label,
                panel: styles.accordion,
              }}
              collapsed
              grows={false}
              height={50}
              key={x.title}
              label={x.title}
              showCount={false}>
              {x.items.map(range =>
                <div
                  className={styles.item}
                  key={range.start.toString()}>
                  <Segment
                    end={range.end}
                    start={range.start} />
                </div>)}
            </Accordion>)}
          {suggested.map(x =>
            <Accordion
              classes={{
                label: styles.label,
                panel: styles.accordion,
              }}
              collapsed
              grows={false}
              height={50}
              key={x.title}
              label={`${x.title} (Suggested)`}
              showCount={false}>
              {x.items.map(range =>
                <div
                  className={styles.item}
                  key={range.start.toString()}>
                  <Segment
                    end={range.end}
                    start={range.start} />
                </div>)}
            </Accordion>)}
        </div>
      </div>
    </div>
  );
};

Section.displayName = 'AvailabilitySchedule.Section';

type EventsMap = Record<string, CalendarRange<Date>[]>;

const dateToEventsMapKey = lightFormat('yyyy-MM-dd');

const keyToReadable = (key: string) => {
  const [ year, month, day ] = key.split('-');
  const date = new Date(+year, +month - 1, +day);
  const f = format('MM/dd/yyyy');

  return f(date);
};

const makeEventsMap = (items: CalendarRange<Date>[]) => {
  return items.reduce<EventsMap>((acc, event) => {
    const key = dateToEventsMapKey(event.start);

    const trimmed = trimNonZeroSeconds(event);

    return {
      ...acc,
      [key]: acc[key] ? acc[key].concat(trimmed) : [trimmed],
    };
  }, {});
};

const setSecondsToZero = setSeconds(0);
const trimNonZeroSeconds = (range: CalendarRange<Date>) => ({
  end: setSecondsToZero(range.end),
  start: setSecondsToZero(range.start),
});

const makeSections = (events: EventsMap, duration: number) => {
  return Object.keys(events).reduce((acc, x) => {
    const segments = combineAdjacentSegments(events[x]);
    const items = segments.filter(range => differenceInMinutes(range.end, range.start) >= duration);

    return acc.concat({
      items,
      title: keyToReadable(x),
    });
  }, [] as Section[])
  .filter(x => x.items.length >= 1);
};

type Section = {
  items: CalendarRange<Date>[];
  title: string;
};

export * as Action from './AvailabilitySchedule.Action';

const sortAsc = (segments: CalendarRange<Date>[]) => {
  return [...segments].sort((a, b) => compareAsc(a.start, b.start));
};

export const combineAdjacentSegments = (segments: CalendarRange<Date>[]) => {
  const [head, ...tail] = sortAsc(segments);

  return tail.reduce((acc, item) => {
    const index = acc.length - 1;
    const prev = acc[index];

    if (isSameMinute(prev.end, item.start)) {
      acc[index] = { start: prev.start, end: item.end };

      return acc;
    }

    return acc.concat(item);
  }, [head]);
};