import { Users } from 'react-feather';
import { TableEmpty } from '@presentation';

type Props = unknown;

export const RespondentsTableEmpty = (props: Props) => {
  return (
    <TableEmpty
      Icon={() => <Users size={120} />}
      message={copy.message}
      note={copy.note} />
  );
};

RespondentsTableEmpty.displayName = 'Project.Respondents.Table.Empty';

const copy = {
  message: `No respondents available.`,
  note: `Onboard respondents to the project to view them here.`,
};