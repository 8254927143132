import { path } from '@consts';
import { RouteAuth } from '@enums';
import { StateRouter } from '@routes/Routers';
import * as Routing from '@routes/interfaces';
import { Permission, Role } from '@enums';
import { IndividualSearch } from '@screens/Search';
import { Search } from '@screens/Search.Main';
import * as utils from '@utils';
import SearchLegacy from '@/scenes/search';

const rootConfig = {
  exact: true,
  path: path.Search.Root,
};

const consultant: Routing.Route[] =  [
  { ...rootConfig,
    component: IndividualSearch,
    roles: [Role.Expert],
  },
];

const hasEvapPermission = utils.access.has.permission.some(Permission.EvapAnalystUserProfile);

const validator = (state: Store.State) => {
  if (utils.hasConsultantRole(state.user)) {
    return consultant;
  } else if (utils.hasClientRole(state.user)) {
    return [{
      path: path.Search.Root,
      component: hasEvapPermission(state.user.permissions) ? Search : SearchLegacy,
      roles: [Role.InternalAdmin, Role.FirmCompliance, Role.FirmAnalyst],
    }];
  } else {
    return [];
  }
};

export const router = StateRouter(validator, {
  auth: RouteAuth.Authenticated,
  path: path.Search.Root,
});