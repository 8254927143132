import { useMemo } from 'react';
import { useSurvey } from '@containers/Survey/hooks/useSurvey';
import { useProjectSurveyResponsesState } from './useProjectSurveyResponsesState';

const useActiveQuestion = () => {
  const survey = useSurvey();
  const { query } = useProjectSurveyResponsesState();

  const question = useMemo(() => {
    return survey.questions.find(f => f.base.id === query.questionId);
  }, [
    query.questionId,
    survey.questions,
  ]);

  return question;
};

export { useActiveQuestion };
export default useActiveQuestion;