import { useCallback, memo } from 'react';
import CallEndIcon from '@mui/icons-material/CallEnd';
import { cx } from '@utils';
import styles from './style/End.css';

type Props = {
  className?: string;
  children?: React.ReactNode;
  visible?: boolean;
  onClick?: () => void;
};

export const End = memo(({ className, children, visible, onClick }: Props) => {
  const handleClick = useCallback(() => {
    onClick?.();
  }, [onClick]);

  if (!visible) return null;

  return (
    <div
      className={cx(styles.end, className)}
      onClick={handleClick}>
      {children ?? (
        <div className={styles.iconEnd}>
          <CallEndIcon />
        </div>
      )}
    </div>
  );
});