import { SurveyBuilder, SurveyLogicBuilder } from '../interfaces';
import * as $actions from './state.logic';

export function logicReducer(state: SurveyBuilder.State, action: SurveyBuilder.Actions): SurveyLogicBuilder.State {

  const existingLogic = state.survey.logic;

  switch (action.type) {

    case 'logic-unsaved-item-added':
      return $actions.logicUnsavedItemAdded(existingLogic, action);

    case 'logic-item-added':
      return $actions.logicItemAdded(existingLogic, action);

    case 'logic-items-added':
      return $actions.logicItemsAdded(existingLogic, action);

    case 'logic-item-removed':
      return $actions.logicItemRemoved(existingLogic, action);

    case 'logic-item-saved':
      return $actions.logicItemSaved(existingLogic, action);

    case 'logic-editing-toggled-off':
      return $actions.logicEditingToggledOff(state, action);

    case 'logic-editing-toggled-on':
      return $actions.logicEditingToggledOn(state, action);

    case 'logic-items-updated':
      return action.payload.items;

    case 'revert-uncommitted-changes':
      return state.committed.logic;

    case 'remove-question-row':
      return $actions.rowRemoved(existingLogic, action);

    case 'remove-question-option':
      return $actions.optionRemoved(existingLogic, action);

    case 'question-removed':
      return $actions.questionRemoved(existingLogic, action);

    case 'remove-section':
      return $actions.sectionRemoved(state, action);

    case 'update-question-identifier':
      return $actions.questionIdentifierUpdated(existingLogic, action);

    case 'update-question-type':
      return $actions.questionTypeUpdated(existingLogic, action);

    default:
      return existingLogic;
  }

}