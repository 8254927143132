import { useCallback, useState } from 'react';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { usePopper } from 'react-popper';
import { ButtonOutlined } from '@presentation/ButtonOutlined';
import { useProjectResponseGridModal } from '@/components/Project.ConferenceTags.Overview';
import { useReportFormatSelectionModal } from '@/components/Reports';
import { ReportFormat } from '@/enums';
import { useZIndexModifier, PopperMenu, PopperMenuItem } from '@/components/Popper';
import { Portal } from '@/components/Portal';
import { useDownloadGoodQuotes } from './hooks/useDownloadGoodQuotes';

type Props = {
  className?: string;
} & IProjectId;

export const Actions = ({ className, projectId }: Props) => {

  const [open, setOpen] = useState(false);

  const zIndexModifier = useZIndexModifier({ zIndex: 8 });

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);
  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      zIndexModifier,
    ],
    placement: 'bottom-start',
  });

  const handleBlur = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const [toggleResponseGridModal, ResponseGridModal] = useProjectResponseGridModal();
  const [toggleGoodQuotesModal, GoodQuotesModal] = useReportFormatSelectionModal();
  const { mutateAsync: downloadGoodQuotes } = useDownloadGoodQuotes({ projectId });

  const onResponseGridClick = useCallback(() => {
    toggleResponseGridModal();
    setOpen(false);
  }, [toggleResponseGridModal]);

  const onDownloadQuotesClick = useCallback(() => {
    toggleGoodQuotesModal();
    setOpen(false);
  }, [toggleGoodQuotesModal]);

  const handleDownloadQuotes = useCallback((format: ReportFormat) => {
    downloadGoodQuotes({
      meta: {
        format,
        name: `Good Quotes`,
        title: `Generating Report`,
      },
      format,
    });
    setOpen(false);
  }, [downloadGoodQuotes]);

  return (
    <>
      <div
        className={className}
        ref={setReferenceElement}
        onClick={handleClick}>
        <ButtonOutlined>Actions</ButtonOutlined>
      </div>
      {open &&
        <Portal>
          <ClickAwayListener
            mouseEvent='onMouseDown'
            onClickAway={handleBlur}
            touchEvent='onTouchStart'>
            <div
              ref={setPopperElement}
              style={popperStyles.popper}
              {...attributes.popper}>
              <PopperMenu>
                <PopperMenuItem onClick={onResponseGridClick}>
                  Download Response Grid
                </PopperMenuItem>
                <PopperMenuItem onClick={onDownloadQuotesClick}>
                  Download Good Quotes
                </PopperMenuItem>
              </PopperMenu>
            </div>
          </ClickAwayListener>
        </Portal>
      }
      <GoodQuotesModal
        title="Export Good Quotes"
        formats={[ReportFormat.Excel, ReportFormat.PowerPoint]}
        onDownload={handleDownloadQuotes} />
      <ResponseGridModal projectId={projectId} />
    </>
  );
};