
type Props = {
  children:   string | React.ReactNode;
  className?: string;
  index:      number;
  onClick?:   () => unknown;
};

const TabBarTitle = (props: Props) => {
  return (
    <div
      className={props.className}
      onClick={props.onClick}>
      {props.children}
    </div>
  );
};

export { TabBarTitle };
export default TabBarTitle;