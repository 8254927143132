import type { Dispatch } from 'react';
import { createContext, useContext } from 'react';
import type { EditorImperativeRef } from '@/components/Survey.RichText/interfaces';
import type { SurveyActionType, SurveyItemType, SurveyQuestionType, SurveyType } from '@enums/Survey';
import type { SurveyItem } from '@/types/survey';
import { createNamedContext } from '@utils';
import type { SurveyBuilder, SurveyBuilderCapabilities } from './interfaces';
import { getDefaultCapabilities } from './state/defaults';

export type SurveyBuilderOptionsContextValue = {
  actionTypes?: SurveyActionType[];
  itemTypes: SurveyItemType[];
  maxQuestions: number;
  questionTypes?: SurveyQuestionType[];
  isModule?: boolean;
  showDetails?: boolean;
  surveyTypes?: SurveyType[];
  defaultSurveyDetails: SurveyBuilder.State['survey']['surveyDetails'];
};

export type RichTextEditorRegistryContextValue = {
  register: (identifier: string, editor: EditorImperativeRef) => void;
  unregister: (identifier: string) => void;
  getEditor: (identifier: string) => EditorImperativeRef;
};

export type SurveyBuilderStateContextValue =
  [SurveyBuilder.State, Dispatch<SurveyBuilder.Actions>, () => void];

export type ScreenerBuilderContextValue = {
  addQuestion: () => void;
  canAddQuestion: () => boolean;
  questions: string[];
  removeQuestion: (index: number) => () => void;
  setQuestions: (items: string[]) => void;
  updateQuestion: (index: number) => (value: string) => void;
};

export type EditableSurveyStateContextValue = {
  fetchSurvey: () => Promise<void>;
  deleteSurvey: () => Promise<void>;
  saveSurvey: () => Promise<void>;
} & ISurveyId;

export type SaveSurveyContextValue = () => Promise<void>;
export type SaveSurveyDraftContextValue = () => void;

export type SurveyBuilderItemEditingContextValue = {
  canEdit: boolean;
  editing: boolean;
};

export type SurveyLanguageEditorContextValue = {
  saveLanguageChanges: () => void;
};

export type QuestionOrdinalsMap = {
  [questionIdentifier: string]: number;
};

export type SurveyBuilderCapabilitiesContextValue = SurveyBuilderCapabilities;

type SurveyBuilderScreenState = {
  additionalLanguageCodes: string[];
  toggleAdditionalLanguageCode: (code: string) => void;
};

export const SurveyBuilderIncludeTaggingContext = createNamedContext<boolean>(false, 'SurveyBuilderIncludeTaggingContext');

export const SurveyBuilderStateContext = createNamedContext<SurveyBuilderStateContextValue>(undefined, 'SurveyBuilderContext');
export const SurveyBuilderOptionsContext = createNamedContext<SurveyBuilderOptionsContextValue>(undefined, 'SurveyBuilderOptionsContext');
export const SurveyBuilderCapabilitiesContext = createNamedContext<SurveyBuilderCapabilitiesContextValue>(getDefaultCapabilities(), 'SurveyBuilderCapabilitiesContext');

export const RichTextEditorRegistryContext = createNamedContext<RichTextEditorRegistryContextValue>(undefined, 'RichTextEditorRegistryContext');

export const SaveSurveyContext = createContext<SaveSurveyContextValue | undefined>(undefined);
export const SaveSurveyDraftContext = createNamedContext<SaveSurveyDraftContextValue>(undefined, 'SaveSurveyDraftContext');
/* export const ScreenerBuilderContext = createContext<ScreenerBuilderContextValue>(null!); */
export const EditableSurveyContext = createContext<EditableSurveyStateContextValue>(null!);

export const SurveyQuotasGoalContext = createNamedContext<number>(null, 'SurveyQuotasGoalContext');
export const SurveyBuilderItemEditingContext = createNamedContext<SurveyBuilderItemEditingContextValue>(null, 'SurveyBuilderItemEditingContext');
export const SurveyBuilderItemContext = createNamedContext<SurveyItem>(null, 'SurveyBuilderItemContext');

export const SurveyLanguageEditorContext = createNamedContext<SurveyLanguageEditorContextValue>(undefined, 'SurveyLanguageEditorContext');

export const SurveyBuilderScreenStateContext = createNamedContext<SurveyBuilderScreenState>(undefined, 'SurveyBuilderScreenStateContext');

export const useSurveyBuilderScreenState = () => useContext(SurveyBuilderScreenStateContext);

export const useSurveyQuotasState = () => {
  const [state, dispatch] = useContext(SurveyBuilderStateContext);

  return [{
    editing: state.editing.quota,
    items: state.survey.quotas,
  }, dispatch] as const;
};

export const useSurveyLogicState = () => {
  const [state, dispatch] = useContext(SurveyBuilderStateContext);

  return [{
    editing: state.editing.logic,
    items: state.survey.logic,
  }, dispatch] as const;
};

export const useSurveyTaggingState = () => {
  const [state, dispatch] = useContext(SurveyBuilderStateContext);

  return [{
    editing: state.editing.tagging,
    items: state.survey.tagging,
  }, dispatch] as const;
};

export const useSurveyQuotasGoal = () => useContext(SurveyQuotasGoalContext);
/* export const useScreenerBuilderContext = () => useContext(ScreenerBuilderContext); */
export const useSurveyBuilderItemEditing = () => useContext(SurveyBuilderItemEditingContext);
export const useSurveyBuilderItem = () => useContext(SurveyBuilderItemContext);
export const useSurveyBuilderIncludeTagging = () => useContext(SurveyBuilderIncludeTaggingContext);
export const useSurveyBuilderCapabilities = () => useContext(SurveyBuilderCapabilitiesContext);