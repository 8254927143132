import { useContext } from 'react';
import type { BrandInsightsCapabilities, Chat } from '@/brand-insights/types';
import { ChatModal } from '@/brand-insights/components/Chat.Modal';
import { ChatSettingsContainer } from '@/brand-insights/components/Chat';
import { ChatStateContainer } from '@/brand-insights/components/Chat.State';
import { ChatWidget as Widget } from '@/brand-insights/components/Chat.Widget';
import { ChatContainer } from './Chat.Container';
import { LinkHashContainer, LinkStateContainer, useBrandInsightsHash } from './Link.Container';
import { SentimentThemeContainer } from './Theme.Container';
import { BrandInsightsModalContext } from './context';

type Props = {
  author: Chat.Creator;
  capabilities: BrandInsightsCapabilities;
};

const BrandInsightsContainer = (props: Props) => {
  const hashValue = useBrandInsightsHash();
  const [open, handleToggle] = useContext(BrandInsightsModalContext);

  return (
    <SentimentThemeContainer>
      <BrandInsightsModalContext.Provider value={[open, handleToggle]}>
        {!open && <Widget onClick={handleToggle} />}
        <ChatSettingsContainer>
          <ChatStateContainer>
            <LinkStateContainer initialChatIdentifier={hashValue}>
              <LinkHashContainer>
                {open && (
                  <ChatContainer author={props.author} capabilities={props.capabilities}>
                    <ChatModal
                      onClose={handleToggle}
                      open={open} />
                  </ChatContainer>
                )}
              </LinkHashContainer>
            </LinkStateContainer>
          </ChatStateContainer>
        </ChatSettingsContainer>
      </BrandInsightsModalContext.Provider>
    </SentimentThemeContainer>
  );
};

export const BrandInsights = BrandInsightsContainer;