import type { SurveyAIEEntry, SurveyAIEGroup } from '@/types';

export type IdentifierType = 'group' | 'entry' | 'group-placeholder';

export function buildGroupIdentifier(group: SurveyAIEGroup) {
  return `group:${group.identifier}`;
}

export function buildEntryIdentifier(entry: SurveyAIEEntry) {
  return `entry:${entry.identifier}`;
}

export function buildGroupPlaceholderIdentifier(prevGroupIdentifier: string) {
  return `group-placeholder:${prevGroupIdentifier}`;
}

export function parseDndIdentifier(identifier: string) {
  const [type, parsedIdentifier] = identifier.split(':');

  return {
    type: type as IdentifierType,
    identifier: parsedIdentifier,
  };
}