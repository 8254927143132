import { useHasConsultantRole } from '@containers/User';
import {
  useFetchIntegrations,
} from '@utils/api';
import { S3 } from '@/components/icons';
import { Integration } from '@/components/Settings';
import { useS3Modal } from './S3IntegrationModal';
import styles from './style/Settings.Integrations.css';

export const S3Integration = () => {
  const {
    data,
    isLoading,
    refetch,
  } = useFetchIntegrations({
    refetchOnMount: false,
  });

  const [toggleModal, S3Modal] = useS3Modal();

  const hasConsultantRole = useHasConsultantRole();

  if (hasConsultantRole) return null;

  function onConnect() {
    refetch();
    toggleModal();
  }

  return (
    <>
      <Integration
        className={styles.item}
        icon={S3}
        title={title}
        description={description}
        connected={data?.aws?.connected}
        disabled={false}
        loading={isLoading}
        onConnect={toggleModal}
        onDisconnect={toggleModal}
        disconnectText={'Modify'} />
      <S3Modal
        originalState={data?.aws?.connection}
        onConnect={onConnect} />
    </>
  );
};

const title = `AWS S3 Bucket`;
const description = `Connect to an AWS S3 bucket to have daily project extracts sent to that bucket.`;

export default S3Integration;