import { AxiosResponse } from 'axios';
import { useQueryClient, useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as api from '@api';
import { Users } from '@api/interfaces';
import Toast from '@/components/Toast';

type Data = Users.LogoutSession.Response;
type Vars = Users.LogoutSession.Request;

export function useSessionLogout(
  options?: UseMutationOptions<Data, AxiosResponse, Vars>
) {
  const queryClient = useQueryClient();

  return useMutation(data => {
    return api.users.logoutSession(data);
  }, {
    onSuccess: () => {
      Toast.success({
        title: 'Session',
        body: 'Session has been logged out!',
      });
      queryClient.refetchQueries(['auth-sessions']);
    },
    ...options,
  });
}