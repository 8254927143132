import { memo } from 'react';
import { SurveyLogic } from '@/types';
import { SurveyLogicItem } from './Logic.Item';
import styles from './style/Logic.css';

type Props = {
  items: SurveyLogic.Item[];
};

export const SurveyQuestionLogic = memo(({ items }: Props) => {

  if (!items.length) return null;

  return (
    <>
      <div className={styles.label}>Logic</div>
      {items.map(item => (
        <div key={item.identifier} className={styles.item}>
          <span className={styles.bullet}>•</span>
          <SurveyLogicItem
            item={item} />
        </div>
      ))}
    </>
  );
});