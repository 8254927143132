import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { Role } from '@/enums';
import { updateUserRoles } from '$admin/api/users';
import { UserPermissions } from '$admin/interfaces';

export const useUpdateUserRoles = (props: Props, options?: Options) => {

  return useMutation(['admin.update-user-roles'], ({ roles }) => {
    return updateUserRoles({
      roles,
      userId: props.userId,
    });
  }, options);
};

type Props = IUserId;
type Data = {
  permissions: UserPermissions;
  roles: Role[];
};
type Vars = {
  roles: Role[];
};
type Options = UseMutationOptions<Data, AxiosResponse, Vars>;