import { useCallback, useMemo, useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as $api from '@api';
import { WorkspaceObjectType } from '@enums';
import { useInvalidateFilesQueries } from '@utils/api';
import { useSelectPersonalWorkspace, useSelectGroupWorkspace } from '@containers/Store';
import { Workspace, WorkspaceObjectTreeItem } from '@/types/workspace';
import { useMoveObject } from './hooks';
import {
  MoveObjectContext,
  MoveObjectContextValue,
  MoveObjectWorkspaceContext,
  MoveObjectWorkspaceContextValue,
} from './Context';

type Props =
  {
    enabled?: boolean;
    objectType: WorkspaceObjectType;
  } &
  IWorkspaceObjectId &
  ChildrenProps;

export const MoveObjectContainer = ({
  enabled = true,
  ...props
}: Props) => {

  const personalWorkspace = useSelectPersonalWorkspace();
  const groupWorkspace = useSelectGroupWorkspace();
  const [workspace, setWorkspace] = useState<Workspace>();
  const [parentObjectId, setParentObjectId] = useState<number>(null);

  const query = useQuery(['get/object:move-details', props.objectId], () => {
    return $api.workspaces.object.moveObjectDetails({
      objectId: props.objectId,
    });
  }, {
    enabled,
    onSuccess: res => {
      const workspaceId = res.object.workspaceId;
      setWorkspace(workspaceId === personalWorkspace.id ? personalWorkspace : groupWorkspace);
    },
  });

  const invalidateQueries = useInvalidateFilesQueries();

  const moveObject = useMoveObject(props.objectType);

  useEffect(() => {
    setParentObjectId(null);
  }, [workspace?.id]);

  const onSave = useCallback(() => {
    return moveObject({
      objectId: props.objectId,
      parentObjectId,
      workspaceId: query.data.object.workspaceId,
    }).then(() => {
      invalidateQueries();
    });
  }, [
    invalidateQueries,
    moveObject,
    props.objectId,
    query.data?.object?.workspaceId,
    parentObjectId,
  ]);

  const currentPath = useMemo(() => {
    if (!query.data?.breadcrumbs) return [];
    return query.data.breadcrumbs.map(m => m.name);
  }, [query.data?.breadcrumbs]);

  const buildTreeItem = useCallback((objectId: number): WorkspaceObjectTreeItem => {
    const objects = query.data?.items ?? [];
    const object = objects.find(f => f.id === objectId);

    const children = Object.values(objects)
      .filter(f => f.parentObjectId === objectId)
      .map(m => buildTreeItem(m.id));

    const sortedChildren = [...children].sort((a, b) => a.object.name.localeCompare(b.object.name));

    return {
      children: sortedChildren,
      object,
    };
  }, [query.data?.items]);

  const tree = useMemo(() => {
    if (!workspace?.objectId) return null;
    return buildTreeItem(workspace?.objectId);
  }, [
    buildTreeItem,
    workspace?.objectId,
  ]);

  const loading = query.isInitialLoading || !tree;

  const value: MoveObjectContextValue = {
    currentPath,
    data: tree,
    object: query.data?.object,
    loadingTree: loading,
    onSave,
    onSelectParentObject: setParentObjectId,
    parentObjectId,
  };

  const workspaceValue: MoveObjectWorkspaceContextValue = {
    loadingWorkspaces: loading,
    onSelectWorkspace: setWorkspace,
    selectedWorkspace: workspace,
    workspaces: [personalWorkspace, groupWorkspace],
  };

  return (
    <MoveObjectWorkspaceContext.Provider value={workspaceValue}>
      <MoveObjectContext.Provider value={value}>
        {props.children}
      </MoveObjectContext.Provider>
    </MoveObjectWorkspaceContext.Provider>
  );
};

export default MoveObjectContainer;