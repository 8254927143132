import { encode, stringify, from, getParam } from '@utils/querystring';

export function currentLocation() {
  return `${window.location.pathname}${window.location.search}`;
}

export function fromQuery() {
  const param = getParam('from');

  return param
    ? from(param)
    : from(window.location.pathname, window.location.search);
}