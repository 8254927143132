import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as api from '@api';
import { useSelectUser } from '@containers/Store';
import { ButtonActivityIndicator, ButtonOutlined } from '@presentation';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { Header } from '@/components/Modal/Header';
import { useModal } from '@/components/Modal/hooks';
import Toast from '@/components/Toast';
import styles from './style/DownloadAllModal.css';

type Props =
  & IWorkspaceObjectId
  & IWorkspaceId
  & Pick<ModalProps, 'onClose' | 'open'>;

export const DownloadAllModal = (props: Props) => {
  const user = useSelectUser();

  const mutation = useMutation({
    mutationKey: [
      `post:workspaces/objects`, {
        email: user.contact.email,
        objectId: props.objectId,
        workspaceId: props.workspaceId,
      },
    ], mutationFn: () => {
      return api.workspaces.object.downloadObjectRecursiveWithNotification({
        email: user.contact.email,
        objectId: props.objectId,
        workspaceId: props.workspaceId,
      });
    },
  });

  const handleSubmit = useCallback(() => {
    mutation.mutateAsync()
      .then(() => {
        Toast.info({
          title: `Download Initiated`,
          body: `You will receive an email when it's ready.`,
        });
        props.onClose();
      });
  }, [mutation, props]);

  return (
    <Modal
      onClose={props.onClose}
      open={props.open}>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <Header>{copy.title}</Header>
          <div className={styles.main}>
            <div className={styles.msg}>{copy.msg1}</div>
            <div className={styles.msg}>{copy.msg2.replace(`_`, user.contact.email)}</div>
          </div>
          <ButtonSet>
            <ButtonOutlined
              className={styles.btn}
              color="silver"
              disabled={mutation.isLoading}
              onClick={props.onClose}>
              Cancel
            </ButtonOutlined>
            <ButtonActivityIndicator
              className={styles.btn}
              loading={mutation.isLoading}
              onClick={handleSubmit}>
              Initiate Download
            </ButtonActivityIndicator>
          </ButtonSet>
        </div>
      </div>
    </Modal>
  );
};

DownloadAllModal.displayName = 'DownloadAll.Modal';

const copy = {
  title: `Download All Files`,
  msg1: `Would you like to download all files in the folder?`,
  msg2: `This process will take a few minutes. You'll receive an email at _ when the download is ready.`,
};

export const useDownloadAllModal = () => useModal(DownloadAllModal);