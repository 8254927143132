import { useCallback } from 'react';
import { ActionMeta, OnChangeValue  } from 'react-select';
import { SelectSearchUnderlined } from '$admin/components/Select';
import { RespondentItem } from './interfaces';

type Props = {
  className?:       string;
  inputValue:       string;
  isValidNewOption: (val: string) => boolean;
  onCreateOption:   (val: string) => unknown;
  onChange:         (val: string) => unknown;
  onSelect:         (newValue: OnChangeValue<RespondentItem, false>, actionMeta: ActionMeta<RespondentItem>) => unknown;
  options:          RespondentItem[];
  placeholder?:     string;
  value:            RespondentItem;
};

export const Autosuggest = (props: Props) => {

  const getOptionLabel = useCallback((item: RespondentItem & CreateOption) => {
    return item.value
      ? `Add ${item.value}`
      : item.offPlatform
        ? item.email
        : `${item.name} (${item.email})`;
  }, []);

  return (
    <SelectSearchUnderlined
      className={props.className}
      creatable
      createOptionPosition="first"
      formatCreateLabel={value => value}
      getOptionLabel={getOptionLabel}
      getOptionValue={x => x.email}
      inputValue={props.inputValue}
      isClearable={true}
      isValidNewOption={props.isValidNewOption}
      maxMenuHeight={200}
      noOptionsMessage={x => x.inputValue?.length ? `No matches found.` : null}
      onChange={props.onSelect}
      onCreateOption={props.onCreateOption}
      onInputChange={props.onChange}
      options={props.options || []}
      placeholder={props.placeholder}
      styles={{
        control: {
          height: 36,
        },
      }}
      value={props.value} />
  );
};

Autosuggest.displayName = 'PickRespondent.Autosuggest';

type CreateOption = {
  label: string;
  value: string;
};