import { useCallback } from 'react';
import { useToggle } from '@utils';
import ObjectOwnershipModal from '../ObjectOwnershipModal';

export const useObjectOwnershipModal = () => {
  const [open, toggleVisibility] = useToggle();

  const Modal = useCallback(() => {
    if (!open) return null;

    return (
      <ObjectOwnershipModal
        onClose={toggleVisibility}
        open={open} />
    );
  }, [
    open,
    toggleVisibility,
  ]);

  return [toggleVisibility, Modal] as const;
};

export default useObjectOwnershipModal;