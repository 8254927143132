import { useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';

type Props = {
  redirectIfInvalid?: boolean;
};

type Params = {
  conferenceIdentifier: string;
};

export function useConferenceParam(data: Props = { redirectIfInvalid: true }) {
  const history = useHistory();
  const params = useParams<Params>();

  const identifier = useMemo(() => {
    return !isNaN(+params?.conferenceIdentifier)
      ? +params.conferenceIdentifier
      : null;
  }, [params?.conferenceIdentifier]);

  if (data.redirectIfInvalid && !identifier) {
    // todo: use not found
    history.replace(`/home`);
  }

  return identifier;
}