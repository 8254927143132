import extend from '@enums/extend';

export enum PayoutableType {
  ProjectCall = 1,
  Survey = 2,
  Message = 3,
  AdHocCall = 4,
  Referral = 5,
  Miscellaneous = 6,
}

const map = new Map([
  [PayoutableType.ProjectCall,   { name: 'Project Call' }],
  [PayoutableType.Survey,        { name: 'Survey' }],
  [PayoutableType.Message,       { name: 'Message' }],
  [PayoutableType.AdHocCall,     { name: 'Ad Hoc Call' }],
  [PayoutableType.Referral,      { name: 'Referral' }],
  [PayoutableType.Miscellaneous, { name: 'Miscellaneous' }],
]);

export default {
  PayoutableType: extend(map),
};