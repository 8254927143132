import { memo } from 'react';
import { Check } from 'react-feather';
import { PopperMenu, PopperMenuItem } from '@/components/Popper';
import styles from './style/DeviceMenuList.css';

type Props = {
  devices: MediaDeviceInfo[];
  selectedDeviceId: MediaDeviceInfo['deviceId'];
  onSelectDevice: (device: MediaDeviceInfo) => void;
};

export const DeviceMenuList = ({ devices, selectedDeviceId, onSelectDevice }: Props) => {
  return (
    <>
      {devices.map(d => (
        <PopperMenuItem key={d.deviceId} onClick={() => onSelectDevice(d)}>
          <div className={styles.row}>
            <MenuItemSelectIcon selected={d.deviceId == selectedDeviceId} />
            {d.label}
          </div>
        </PopperMenuItem>
      ))}
    </>
  );
};

const MenuItemSelectIcon = memo(({ selected }: { selected: boolean }) => {
  if (selected) {
    return <Check className={styles.icon} size={18} />;
  } else {
    return <div className={styles.emptyIcon} />;
  }
});