import { useCallback } from 'react';
import { SurveyTemplate } from '@/types/survey';
import { RadioGroup, RadioGroupOnInputChange, RadioGroupOptionItem } from '@/components/Radio';

type Props = {
  onChange: (value: SurveyTemplate.MessageTesting.Qualification) => void;
  value: SurveyTemplate.MessageTesting.Qualification;
};

export const TemplateQualificationInput = ({ onChange, value }: Props) => {

  const handleChange: RadioGroupOnInputChange = useCallback(item => {
    onChange(item.optionId as SurveyTemplate.MessageTesting.Qualification);
  }, [onChange]);

  return (
    <>
      <RadioGroup
        selectedOption={value}
        onOptionChange={handleChange}
        options={radioOptions} />
    </>
  );
};

const radioOptions: RadioGroupOptionItem[] = [
  {
    label: 'Surgical Procedures',
    value: 'surgical-procedures',
  },
  {
    label: 'Patients Treated',
    value: 'patients-treated',
  },
  {
    label: 'Interventions',
    value: 'interventions',
  },
];
