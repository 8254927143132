import { useCallback, useContext, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Upload, UserX, Download } from 'react-feather';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu, { menuClasses } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { cx } from '@utils';
import { useDownloadLeadsSearchData } from '$admin/api/hooks';
import { QueryParamsContext, SelectedContext } from './containers/Context';
import * as Action from './Action';
import styles from './styles/Toolbar.Actions.css';

const StyledMenu = styled(Menu)(({ theme }) => ({
  [`& .${menuClasses.paper}`]: {
    borderRadius: '4px',
    transition: 'duration 0 !important',
  },
  [`& .${menuClasses.list}`]: {
    padding: '0',
  },
}));

type Props = {
  source?: string;
};

export const Actions = (props: Props) => {
  const [selected] = useContext(SelectedContext);
  const [queryParams] = useContext(QueryParamsContext);
  const [anchor, setAnchor] = useState<HTMLElement>(null);

  const handleClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setAnchor(e.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchor(null);
  }, []);

  const disabled = useMemo(() => {
    return {
      import: selected.ids.size > 0,
      remove: selected.ids.size < 1,
      download: false,
    };
  }, [selected.ids.size]);

  const { download } = useDownloadLeadsSearchData({
    name: queryParams.name,
    title: queryParams.title,
    source: props.source,
  });

  const handleDownloadLeadsCSV = useCallback(() => {
    download({
      extension: `csv`,
      name: `Leads Search Results`,
      title: `Generating Report`,
    });
    handleClose();
  }, [
    download,
    handleClose,
  ]);

  return (
    <>
      <div
        className={cx(styles.btn)}
        onClick={handleClick}>
        <div className={styles.label}>
          Actions
        </div>
        <Divider
          sx={{
            marginLeft: '4px',
            marginRight: '4px',
            height: '28px',
          }}
          variant="middle"
          orientation="vertical" />
        <KeyboardArrowDownIcon sx={{
          height: '25px',
          width: '25px',
          color: 'var(--trolley)',
        }} />
      </div>
      <StyledMenu
        disableAutoFocusItem
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchor}
        open={!!anchor}
        onClose={handleClose}
        transitionDuration={{
          appear: 0,
          enter: 0,
          exit: 0,
        }}>
        <MenuItem
          classes={{
            root: styles.input,
            gutters: styles.gutters,
          }}
          disabled={disabled.import}
          disableRipple>
          <Action.Import
            onClose={handleClose}>
            <div className={styles.item}>
              <Upload size={18} />
              <span>Import contacts</span>
            </div>
          </Action.Import>
        </MenuItem>

        <MenuItem
          classes={{
            root: styles.input,
            gutters: styles.gutters,
          }}
          disabled={disabled.remove}
          disableRipple>
          <Action.Remove
            disabled={disabled.remove}
            onClose={handleClose}>
            <div className={styles.item}>
              <UserX size={18} />
              <span>Remove contacts</span>
            </div>
          </Action.Remove>
        </MenuItem>
        <MenuItem
          classes={{
            root: styles.input,
            gutters: styles.gutters,
          }}
          disabled={disabled.download}
          disableRipple>
          <Action.Export
            disabled={disabled.download}
            onClick={handleDownloadLeadsCSV}>
            <div className={styles.item}>
              <Download size={18} />
              <span>Download CSV</span>
            </div>
          </Action.Export>
        </MenuItem>

      </StyledMenu>
    </>
  );
};

Actions.displayName = 'Group.Contacts.Panel.Toolbar.Actions';