export function buildBrandInsightsChatLink({ chatIdentifier, isStandaloneChat }: BuildBrandInsightsChatLink) {
  const url = new URL(window.location.href);

  if (!isStandaloneChat) {
    return `${url.origin}${url.pathname}${url.search}#bi:ocid:${chatIdentifier}`;
  }

  return `${url.origin}/brand-insights/chat/${chatIdentifier}`;
}

type BuildBrandInsightsChatLink = {
  chatIdentifier: string;
  isStandaloneChat: boolean;
};