import { SurveyActionType } from '@/enums';
import { SurveyLogic, SurveyBuilderVersion } from '@/types/survey';
import { Validation } from './interfaces';

export function validateLogicAction({ action, conditions }: SurveyLogic.Item, survey: SurveyBuilderVersion): Validation {
  if (!action.type) {
    return {
      message: 'Action type must be set',
      success: false,
    };
  }

  switch (action.type) {
    case SurveyActionType.IncludeSection: {
      const section = survey.sections.find(f => f.identifier === action.section.identifier);
      if (!section) {
        return {
          message: 'Invalid section',
          success: false,
        };
      }

      if (!section.hidden) {
        return {
          message: 'Section is not hidden by default',
          success: false,
        };
      }

      // const lastConditionSection = conditions
      //   .map(m => survey.questions.find(f => f.base.identifier === m.data.question.identifier))
      //   .map(m => survey.sections.find(f => f.identifier === m.section.identifier))
      //   .sort((a, b) => a.ordinal - b.ordinal)[0];

      // if (lastConditionSection.ordinal <= section.ordinal) {
      //   return {
      //     message: 'Section is not valid based on conditions',
      //     success: false,
      //   };
      // }

      return {
        success: true,
      };
    }

    case SurveyActionType.SkipToSection: {
      const section = survey.sections.find(f => f.identifier === action.section.identifier);
      if (!section) {
        return {
          message: 'Invalid section',
          success: false,
        };
      }

      return {
        success: true,
      };
    }

    case SurveyActionType.SkipToQuestion: {
      const question = survey.questions.find(f => f.base.identifier === action.question.identifier);
      if (!question) {
        return {
          message: 'Invalid question',
          success: false,
        };
      }

      // const lastConditionQuestion = conditions
      //   .map(m => survey.questions.find(f => f.base.identifier === m.data.question.identifier))
      //   .sort((a, b) => a.ordinal - b.ordinal)[0];

      // if (lastConditionQuestion.ordinal <= question.ordinal) {
      //   return {
      //     message: 'Section is not valid based on conditions',
      //     success: false,
      //   };
      // }

      return {
        success: true,
      };
    }

    case SurveyActionType.DisplayQuestionChoice:
    case SurveyActionType.ExcludeQuestionChoice: {
      // TODO: implement
      return {
        success: true,
      };
    }

    case SurveyActionType.Disqualification:
    case SurveyActionType.Flag:
    case SurveyActionType.QuotaOverLimit:
    case SurveyActionType.SkipToEnd: {
      return {
        success: true,
      };
    }

    default: {
      throw new UnreachableCaseError(action);
    }
  }
}