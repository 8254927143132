import axios, { AxiosHeaders, type AxiosInstance, type AxiosRequestConfig, type AxiosResponse } from 'axios';
import { bindActionCreators } from '@reduxjs/toolkit';
import * as actions from '@actions';
import store from '@store';
import * as $session from '@services/auth/session';
import { logConnectionRefused, logError, logRequest } from './console';
import type { XHR } from './interfaces';

const BACKEND_BASE_URL = new URL(process.env.BACKEND_BASE_URL);

const boundActions = bindActionCreators({
  logout: actions.logout,
}, store.dispatch);

export class Http {
  constructor() {
    this.baseURL = process.env.BACKEND_BASE_URL;
    this.instance = axios.create({
      baseURL: this.baseURL,
    });

    this.useInterceptors(this.instance);
  }

  private baseURL: string;
  private instance: AxiosInstance;

  private static requiresCSRFToken = new Set(['patch', 'post', 'put', 'delete']);

  private useInterceptors = (instance: AxiosInstance) => {
    instance.interceptors.request.use(
      config => {
        logRequest(config);

        const url = new URL(config.baseURL);
        if (url.hostname === BACKEND_BASE_URL.hostname) {
          config.withCredentials = true;

          if (process.env.__DEV__) {
            const xsid = $session.getXSID();
            if (xsid) {
              config.headers.set('X-SID', xsid);
            }
          }
        } else {
          console.warn(`Hostname mismatch, will not attach cookies to request.`);
        }

        if (!$session.isAuthenticated()) {
          logUnauthenticated();
          boundActions.logout();
          return {
            ...config,
            cancelToken: new axios.CancelToken(c => c('Aborting request: Session is expired or absent.')),
          };
        }

        if (Http.requiresCSRFToken.has(config.method)) {
          config.headers.set('X-CSRF-Token', $session.getCSRF());
        }

        return config;
      },
    );

    instance.interceptors.response.use(
      res => {
        if (process.env.__DEV__) {
          const url = new URL(res.config.baseURL);
          if (url.hostname === BACKEND_BASE_URL.hostname) {
            if (res.headers instanceof AxiosHeaders) {
              const xsid = res.headers.get('X-SID') as string;
              $session.setXSID(xsid);
            }
          }
        }
        return res;
      },
      async (e: XHR.Error) => {
        if (!e.response) {
          // if (!axios.isCancel(e)) {
          logConnectionRefused();
          e.offline = true;
          // }
          return Promise.reject(e);
        }

        if (e.response) {
          logError(e);

          if (e.response.status === 401) {
            logUnauthenticated();
            boundActions.logout();
          }

          return Promise.reject(e.response);
        }

        return Promise.reject(e);
      }
    );
  };

  public delete: Request = async (url, config) => {
    return this.instance.delete(url, config)
      .then(resp => resp.data);
  };

  public get: Request = (url, config) => {
    return this.instance.get(url, config)
      .then(resp => resp.data);
  };

  public head: Request = (url, config) => {
    return this.instance.head(url, config)
      .then(resp => resp.data);
  };

  public patch: FormDataRequest = (url, data, config) => {
    return this.instance.patch(url, data, config)
      .then(resp => resp.data);
  };

  public post: FormDataRequest = (url, data, config) => {
    return this.instance.post(url, data, config)
      .then(resp => resp.data);
  };

  public put: FormDataRequest = (url, data, config) => {
    return this.instance.put(url, data, config)
      .then(resp => resp.data);
  };

  public request = (config?: AxiosRequestConfig) => {
    return this.instance.request(config)
      .then(resp => resp.data);
  };

  public download = (url: string, config?: AxiosRequestConfig) => {
    type Response<T = Blob> = {
      headers: Record<string, string>;
    } & Omit<AxiosResponse<T>, 'headers'>;

    return this.instance.get<Blob>(url, { responseType: 'blob', ...config })
    .then((response: Response<Blob>) => {
      const filename = new RegExp(/filename="(.+)"/).exec(response.headers['content-disposition']);
      return {
        blob: response.data,
        filename: filename.length ? filename[1] : null,
      };
    });
  };

  public downloadPost = (url: string, data: unknown, config?: AxiosRequestConfig) => {
    type Response<T = Blob> = {
      headers: Record<string, string>;
    } & Omit<AxiosResponse<T>, 'headers'>;

    return this.instance.post<Blob>(url, data, { responseType: 'blob', ...config })
    .then((response: Response<Blob>) => {
      const filename = new RegExp(/filename="(.+)"/).exec(response.headers['content-disposition']);
      return {
        blob: response.data,
        filename: filename.length ? filename[1] : null,
      };
    });
  };
}

type Request = <R = unknown>(url: string, config?: AxiosRequestConfig) => Promise<R>;
type FormDataRequest = <R = unknown, T = unknown>(url: string, data: T, config?: AxiosRequestConfig) => Promise<R>;

function logUnauthenticated() {
  console.error(
    `%c${' '.repeat(6)}UNAUTHENTICATED %c⇣`,
    'color: #c10d34; font-weight: bold;',
    'color: #ffffff;'
  );
}