import type { SurveyBuilder } from '@/containers/SurveyBuilder/interfaces/interfaces.state';
import { SurveyQuestionType } from '@/enums';
import type { SurveyRichText } from '@/types/survey.rich-text';
import type { MatrixRangeQuestion, MatrixSliderQuestion, MultiTextboxQuestion } from '@/types';
import { Remirror } from '@/types';

export function getFindAndReplaceGroupingId(groupingId: string, label: string) {
  return (groupingId || label || '').trim();
}

export function getFindAndReplaceLabel(value: string, count: number, idx: number) {
  const sanitized = (value || '').replace(/(^\[|\]$)/g, '').trim();

  if (count === 1) {
    return `[${sanitized}]`;
  } else {
    const isPlural = sanitized.endsWith('s');
    return `[${isPlural && sanitized.length > 1 ? sanitized.slice(0, sanitized.length - 1) : sanitized} ${idx}]`;
  }
}

export function getFindAndReplaceLabelToEdit(value: string) {
  return (value || '').replace(/(^\[|\]$)/g, '').trim();
}

export function searchFindAndReplaceNodes(state: SurveyBuilder.State, groupingId: string): FindAndReplaceNodeMatches {
  const getMatchingInParagraph = (p: SurveyRichText.ParagraphNode, matchIndex: number): FindAndReplaceNodeMatch[] => {
    const matches: FindAndReplaceNodeMatch[] = [];
    for (let i = 0; i < p.content.length; i++) {
      const node = p.content[i];
      if (node.type === Remirror.NodeType.FindAndReplace && node.attrs.groupingId === groupingId) {
        matches.push({ node, matchIndex });
      }
    }
    return matches;
  };

  const getMatching = (root: SurveyRichText.RootNode, matchIndex: number) => {
    return root.content.flatMap(node => {
      if (node.type === Remirror.NodeType.Paragraph) {
        return getMatchingInParagraph(node, matchIndex);
      } else {
        return node.content.flatMap(n => n.content.flatMap(n => getMatchingInParagraph(n, matchIndex)));
      }
    });
  };

  const messageNodeMatches: FindAndReplaceMessageNodeMatches[] = [];
  for (const message of state.survey.messages) {
    const matchIndex = messageNodeMatches.length;
    const matchedNodeIdentifiers: Set<string> = new Set<string>();
    const messageMatches = getMatching(message.value, matchIndex);
    messageMatches.forEach(m => matchedNodeIdentifiers.add(m.node.attrs.identifier));

    if (messageMatches.length) {
      messageNodeMatches.push({
        messageIdentifier: message.identifier,
        matches: messageMatches,
        matchedNodeIdentifiers,
      });
    }
  }

  const questionNodeMatches: FindAndReplaceQuestionNodeMatches[] = [];
  for (const question of state.survey.questions) {
    const matchIndex = questionNodeMatches.length;
    const matchedNodeIdentifiers: Set<string> = new Set<string>();
    const questionMatches = getMatching(question.value, matchIndex);
    questionMatches.forEach(m => matchedNodeIdentifiers.add(m.node.attrs.identifier));

    const settingsMatches: FindAndReplaceQuestionSettings[] = [];
    if (question.typeId === SurveyQuestionType.MultiTextbox) {
      const settings = question.settings;
      const labelMatching = getMatching(settings.label, matchIndex);
      if (labelMatching.length) {
        settingsMatches.push({
          type: SurveyQuestionType.MultiTextbox,
          key: `label`,
          identifier: `${question.base.identifier}:settings:label`,
          matches: labelMatching,
        });
      }
    } else if (question.typeId === SurveyQuestionType.Sliders) {
      const settings = question.settings;
      const labelMatching = getMatching(settings.slider.label, matchIndex);
      if (labelMatching.length) {
        settingsMatches.push({
          type: SurveyQuestionType.Sliders,
          key: `label`,
          identifier: `${question.base.identifier}:settings:slider:label`,
          matches: labelMatching,
        });
      }
    } else if (question.typeId === SurveyQuestionType.MatrixRange) {
      const settings = question.settings;
      const leftColumnLabelMatching = getMatching(settings.leftColumnLabel, matchIndex);
      if (leftColumnLabelMatching.length) {
        settingsMatches.push({
          type: SurveyQuestionType.MatrixRange,
          key: `leftColumnLabel`,
          identifier: `${question.base.identifier}:settings:leftColumnLabel`,
          matches: leftColumnLabelMatching,
        });
      }
      const rightColumnLabelMatching = getMatching(settings.rightColumnLabel, matchIndex);
      if (rightColumnLabelMatching.length) {
        settingsMatches.push({
          type: SurveyQuestionType.MatrixRange,
          key: `rightColumnLabel`,
          identifier: `${question.base.identifier}:settings:rightColumnLabel`,
          matches: rightColumnLabelMatching,
        });
      }
    }

    const optionsMatches: FindAndReplaceQuestionNodeMatches['options'] = [];
    for (const option of question.options || []) {
      const m = getMatching(option.value, matchIndex);
      if (m.length) {
        optionsMatches.push({
          optionIdentifier: option.base.identifier,
          matches: m,
        });
        m.forEach(m => matchedNodeIdentifiers.add(m.node.attrs.identifier));
      }
    }

    const rowsMatches: FindAndReplaceQuestionNodeMatches['rows'] = [];
    for (const row of question.matrixRows || []) {
      const rowValueMatches = getMatching(row.value, matchIndex);
      const rowMetadataMatches: FindAndReplaceRowMetadata[] = [];

      if (question.typeId === SurveyQuestionType.MatrixRange) {
        const matrixRangeRow = row as MatrixRangeQuestion.Row;

        const leftStatementMatching = getMatching(matrixRangeRow.metadata.leftStatement, matchIndex);
        if (leftStatementMatching.length) {
          rowMetadataMatches.push({
            type: SurveyQuestionType.MatrixRange,
            key: `leftStatement`,
            identifier: `${row.base.identifier}:metadata:leftStatement`,
            matches: leftStatementMatching,
          });
        }
        const rightStatementMatching = getMatching(matrixRangeRow.metadata.rightStatement, matchIndex);
        if (rightStatementMatching.length) {
          rowMetadataMatches.push({
            type: SurveyQuestionType.MatrixRange,
            key: `rightStatement`,
            identifier: `${row.base.identifier}:metadata:rightStatement`,
            matches: rightStatementMatching,
          });
        }
      }

      if (rowValueMatches.length || rowMetadataMatches.length) {
        rowsMatches.push({
          rowIdentifier: row.base.identifier,
          matches: rowValueMatches,
          metadata: rowMetadataMatches,
        });

        rowValueMatches.forEach(m => matchedNodeIdentifiers.add(m.node.attrs.identifier));
        rowMetadataMatches.forEach(m => m.matches.forEach(m => matchedNodeIdentifiers.add(m.node.attrs.identifier)));
      }
    }

    if (questionMatches.length || optionsMatches.length || rowsMatches.length) {
      questionNodeMatches.push({
        questionOrdinal: question.ordinal,
        questionIdentifier: question.base.identifier,
        settings: settingsMatches,
        matches: questionMatches,
        options: optionsMatches,
        rows: rowsMatches,
        matchedNodeIdentifiers,
      });
    }
  }

  return {
    messages: messageNodeMatches,
    questions: questionNodeMatches,
  };
}

export function groupLabelsFromMatches(matches: FindAndReplaceNodeMatches): FindAndReplaceGroupedLabelMatches {
  const labels = new Set<string>();

  function matchProcessor(questionOrdinal: number) {
    return (match: FindAndReplaceNodeMatch) => {
      const label = match.node.attrs.label;
      labels.add(label);
      questionLabels[label] = questionLabels[label] || [];
      questionLabels[label].push({ ...match, questionOrdinal });
    };
  }

  const questionLabels: Record<string, GroupedNodeMatch[]> = {};
  for (const question of matches.questions) {
    const processMatch = matchProcessor(question.questionOrdinal);
    question.matches.forEach(processMatch);

    question.settings.forEach(s => s.matches.forEach(processMatch));

    question.options.forEach(o => o.matches.forEach(processMatch));

    question.rows.forEach(o => o.matches.forEach(processMatch));

    question.rows.forEach(r => r.matches.forEach(processMatch));
  }

  const messageLabels: Record<string, FindAndReplaceNodeMatch[]> = {};
  for (const message of matches.messages) {
    message.matches.forEach(m => {
      const label = m.node.attrs.label;
      labels.add(label);
      messageLabels[label] = messageLabels[label] || [];
      messageLabels[label].push(m);
    });
  }

  return {
    labels: Array.from(labels),
    questions: questionLabels,
    messages: messageLabels,
  };
}

export function updateRichTextValueNodes(root: SurveyRichText.RichTextValue, match: FindAndReplaceNodeMatch, value: string | SurveyRichText.RichTextValue): SurveyRichText.RichTextValue {
  const processParagraph = (p: SurveyRichText.ParagraphNode) => {
    const index = p.content.findIndex(n => n.type === Remirror.NodeType.FindAndReplace && n.attrs.identifier === match.node.attrs.identifier);
    if (index === -1) return p;

    const node = p.content[index] as SurveyRichText.FindAndReplaceNode;
    node.attrs.value = value;
    node.attrs.touched = true;
  };

  const working = Object.assign({}, root);

  working.content.forEach(node => {
    if (node.type === Remirror.NodeType.Paragraph) {
      processParagraph(node);
    } else {
      node.content.forEach(n => n.content.forEach(n => processParagraph(n)));
    }
  });

  return working;
}

export function getFirstNodeFromLabel(labels: FindAndReplaceGroupedLabelMatches, label: string) {
  return labels.questions[label]?.[0]?.node || labels.messages[label]?.[0]?.node;
}

export type FindAndReplaceNodeMatch = {
  node: SurveyRichText.FindAndReplaceNode;
  matchIndex: number;
};

export type FindAndReplaceQuestionSettings =
  | FindAndReplaceMatrixRangeQuestionSettings
  | FindAndReplaceMultiTextboxQuestionSettings
  | FindAndReplaceMatrixSliderQuestionSettings;

export type FindAndReplaceMultiTextboxQuestionSettings = {
  type: SurveyQuestionType.MultiTextbox;
  key: keyof Pick<MultiTextboxQuestion.Settings, 'label'>;
  identifier: string;
  matches: FindAndReplaceNodeMatch[];
};

export type FindAndReplaceMatrixSliderQuestionSettings = {
  type: SurveyQuestionType.Sliders;
  key: keyof Pick<MatrixSliderQuestion.Settings['slider'], 'label'>;
  identifier: string;
  matches: FindAndReplaceNodeMatch[];
};

export type FindAndReplaceMatrixRangeQuestionSettings = {
  type: SurveyQuestionType.MatrixRange;
  key: keyof Pick<MatrixRangeQuestion.Settings, 'leftColumnLabel' | 'rightColumnLabel'>;
  identifier: string;
  matches: FindAndReplaceNodeMatch[];
};

export type FindAndReplaceRowMetadata =
  | FindAndReplaceMatrixRangeRowMetadata;

export type FindAndReplaceMatrixRangeRowMetadata = {
  type: SurveyQuestionType.MatrixRange;
  key: keyof Pick<MatrixRangeQuestion.RowMetadata, 'leftStatement' | 'rightStatement'>;
  identifier: string;
  matches: FindAndReplaceNodeMatch[];
};

export type FindAndReplaceQuestionNodeMatches = {
  questionIdentifier: string;
  questionOrdinal: number;
  settings: FindAndReplaceQuestionSettings[];
  matches: FindAndReplaceNodeMatch[];
  options: Array<{
    optionIdentifier: string;
    matches: FindAndReplaceNodeMatch[];
  }>;
  rows: Array<{
    rowIdentifier: string;
    matches: FindAndReplaceNodeMatch[];
    metadata: FindAndReplaceRowMetadata[];
  }>;
  matchedNodeIdentifiers: Set<string>;
};

export type FindAndReplaceMessageNodeMatches = {
  messageIdentifier: string;
  matches: FindAndReplaceNodeMatch[];
  matchedNodeIdentifiers: Set<string>;
};

export type FindAndReplaceNodeMatches = {
  questions: FindAndReplaceQuestionNodeMatches[];
  messages: FindAndReplaceMessageNodeMatches[];
};

type GroupedNodeMatch = FindAndReplaceNodeMatch & { questionOrdinal: number };

export type FindAndReplaceGroupedLabelMatches = {
  labels: string[];
  questions: Record<string, GroupedNodeMatch[]>;
  messages: Record<string, FindAndReplaceNodeMatch[]>;
};