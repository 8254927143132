import { useContext } from 'react';
import { StarredResponseAnswersContext } from '../Context';

export function  useStarredResponseAnswer(questionId: number) {
  const context = useContext(StarredResponseAnswersContext);
  if (!context) {
    throw new Error('useStarredResponseAnswer must be used within a StarredResponseAnswersContext');
  }

  return {
    onToggle: context.onToggle(questionId),
    value: context.starred.includes(questionId),
  };
}

export default useStarredResponseAnswer;