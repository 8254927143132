import { AudienceType } from '@enums';
import { cx } from '@utils';
import styles from './style/Landing.css';

type Props = {
  audienceType: AudienceType;
  className?:   string;
  payment:      boolean;
};

export const Interview = (props: Props) => {
  const title = [
    !props.payment ? copy.interview.free : copy.interview.paid,
    `Sentiment`,
    props.audienceType === AudienceType.Payer && `Global Advisors`,
  ]
  .filter(Boolean)
  .join(' ');

  return (
    <h1 className={cx(styles.title, props.className)}>{title}</h1>
  );
};

Interview.displayName = 'ProjectInvite.Landing.Title.Interview';

export const Survey = (props: Props) => {
  const title = [
    !props.payment ? copy.survey.free : copy.survey.paid,
    `Sentiment`,
    props.audienceType === AudienceType.Payer && `Global Advisors`,
  ]
  .filter(Boolean)
  .join(' ');

  return (
    <h1 className={cx(styles.title, props.className)}>{title}</h1>
  );
};

Survey.displayName = 'ProjectInvite.Landing.Title.Survey';

const copy = {
  interview: {
    free: `You've been invited to \r\nparticipate in an interview with`,
    paid: `You've been invited to \r\nparticipate in a paid interview with`,
  },
  survey: {
    free: `You've been invited to \r\nparticipate in a survey with`,
    paid: `You've been invited to \r\nparticipate in a paid survey with`,
  },
};