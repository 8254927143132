import { SurveyLogic } from '@/types';
import { SurveyConditions } from './Preview.Conditions';
import { SurveyConditionsRule } from './Preview.Rule';
import styles from './style/Preview.css';

type Props = {
  match: SurveyLogic.SurveyConditionsMatch;
};

export const SurveyLogicMatch = ({ match }: Props) => {

  return (
    <div>
      <SurveyConditionsRule
        rule={match.rule}
        className={styles.rule} />
      <SurveyConditions conditions={match.conditions} />
    </div>
  );
};