import { useCallback } from 'react';
import type { AxiosResponse } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import type { GetCallTranscript } from '@api/interfaces/calls';
import * as api from '@api/calls';

type Data = GetCallTranscript.Response;
type QueryKey = readonly [string, ICallId];

const buildQueryKey = (data: ICallId): QueryKey => {
  return ['call-transcript', data];
};

export function useCallTranscript(
  data: ICallId,
  options?: UseQueryOptions<Data, AxiosResponse, Data, QueryKey>
) {

  return useQuery(buildQueryKey(data), ({ queryKey }) => {
    const callId = queryKey[1].callId;

    return api.getCallTranscript({
      callId,
    });
  }, options);
}

export function useInvalidateCallTranscriptQuery() {
  const queryClient = useQueryClient();

  return useCallback((data: ICallId) => {
    const queryKey = buildQueryKey(data);

    return queryClient.invalidateQueries(queryKey);
  }, [queryClient]);
}