import { useEffect, useState } from 'react';

type Props = {
  src: string;
  onLoad?: (e: Event) => void;
  onError?: (e: Event) => void;
} & Partial<Pick<HTMLScriptElement, 'id'>>;

type Status = 'idle' | 'loading' | 'ready' | 'error';

export const useScript = ({ id, src, onError, onLoad }: Props) => {
  const [status, setStatus] = useState<Status>('idle');

  useEffect(() => {
    if (!src) {
      setStatus('idle');
      return;
    }

    let script: HTMLScriptElement = document.querySelector(`script[src="${src}"]`);

    if (!script) {
      script = document.createElement('script');
      script.id = id;
      script.src = src;
      script.async = true;
      script.setAttribute('data-status', 'loading');
      document.body.appendChild(script);

      const setAttributeFromEvent = (event: Event) => {
        script.setAttribute(
          'data-status',
          event.type === 'load' ? 'ready' : 'error'
        );
        if (event.type === 'error') {
          onError?.(event);
        }
        else if (event.type === 'load') {
          onLoad?.(event);
        }
      };

      script.addEventListener('load', setAttributeFromEvent);
      script.addEventListener('error', setAttributeFromEvent);
    } else {
      setStatus(script.getAttribute('data-status') as Status);
    }

    const setStateFromEvent = (event: Event) => {
      setStatus(event.type === 'load' ? 'ready' : 'error');
      if (event.type === 'error') {
        onError?.(event);
      }
      else if (event.type === 'load') {
        onLoad?.(event);
      }
    };

    script.addEventListener('load', setStateFromEvent);
    script.addEventListener('error', setStateFromEvent);

    return () => {
      if (script) {
        script.removeEventListener('load', setStateFromEvent);
        script.removeEventListener('error', setStateFromEvent);
      }
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  return status;
};