import { memo } from 'react';
import CheckboxMUI from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import { useSurveyThemingPalette } from '@containers/Branding/hooks/useSurveyThemingPalette';
import type { CheckboxProps } from './interfaces';
import styles from './style/CheckboxGroup.css';

type StyledCheckboxProps = {
  checkedColor: string;
};

const StyledCheckbox = styled(CheckboxMUI, {
  shouldForwardProp: prop => prop !== 'checkedColor',
})<StyledCheckboxProps>(({ theme, disabled, checkedColor }) => ({
  color: disabled ? 'var(--gray-l)' : 'var(--black-l)',
  zIndex: 1,
  padding: 0,
  [`&:hover`]: {
    backgroundColor: 'inherit',
  },
  [`&.Mui-checked`]: {
    color: checkedColor,
  },
}));

export const Checkbox = memo(({ classes = {} as CheckboxProps['classes'], disabledColor, ...props }: CheckboxProps) => {
  const { palette, theme } = useSurveyThemingPalette();

  const checkedColor = props.disabled && disabledColor
      ? disabledColor
      : theme
        ? palette.primary.main
        : `var(--pri-02)`;

  const displayUnderlay = theme && props.checked;

  return (
    <div className={styles.checkbox}>
      <StyledCheckbox
        disableRipple={true}
        color="default"
        checkedColor={checkedColor}
        {...props} />
      {displayUnderlay &&
        <div
          className={styles.theme}
          style={{ backgroundColor: palette.primary.text }} />}
    </div>
  );
});

export default Checkbox;