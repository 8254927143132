import { useCallback, useContext, useMemo, useState } from 'react';
import { Upload, UserX } from 'react-feather';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { path } from '@consts';
import { SelectedContext } from '@containers/Group.Contacts/Context';
import { cx } from '@utils';
import { Button } from '@/components/Button';
import * as Action from './Action';
import styles from './style/Toolbar.Actions.css';

type Props = unknown;

export const Actions = (props: Props) => {
  const [selected] = useContext(SelectedContext);
  const [anchor, setAnchor] = useState<HTMLElement>(null);

  const handleClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setAnchor(e.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchor(null);
  }, []);

  const disabled = useMemo(() => {
    return {
      import: selected.ids.size > 0,
      remove: selected.ids.size < 1,
    };
  }, [selected.ids.size]);

  return (
    <>
      <div
        className={cx(styles.btn)}
        onClick={handleClick}>
        <div className={styles.label}>
          Actions
        </div>
        <Divider
          sx={{
            marginLeft: '4px',
            marginRight: '4px',
            height: '28px',
          }}
          variant="middle"
          orientation="vertical" />
        <KeyboardArrowDownIcon
          sx={{
            height: '25px',
            width: '25px',
            color: 'var(--trolley)',
          }} />
      </div>
      <Menu
        slotProps={{
          paper: {
            sx: {
              borderRadius: '4px',
              transition: 'duration 0 !important',
            },
          },
        }}
        MenuListProps={{
          sx: {
            padding: '0',
          },
        }}
        disableAutoFocusItem
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchor}
        open={!!anchor}
        onClose={handleClose}
        transitionDuration={{
          appear: 0,
          enter: 0,
          exit: 0,
        }}>
        <MenuItem
          classes={{
            root: styles.input,
            gutters: styles.gutters,
          }}
          disabled={disabled.import}
          disableRipple>
          <Button
            className={styles.action}
            color="transparent"
            disabled={disabled.import}
            to={path.Contacts.Import}
            onClick={handleClose}
            variant="link">
            <div className={styles.item}>
              <Upload size={18} />
              <span>Import contacts</span>
            </div>
          </Button>
        </MenuItem>

        <MenuItem
          classes={{
            root: styles.input,
            gutters: styles.gutters,
          }}
          disabled={disabled.remove}
          disableRipple>
          <Action.Remove
            disabled={disabled.remove}
            onClose={handleClose}>
            <div className={styles.item}>
              <UserX size={18} />
              <span>Remove contacts</span>
            </div>
          </Action.Remove>
        </MenuItem>

      </Menu>
    </>
  );
};

Actions.displayName = 'Group.Contacts.Panel.Toolbar.Actions';