import type { SurveyBuilder, SurveySectionsBuilder } from '../interfaces';
import * as $actions from './state.sections';

export function sectionsReducer(state: SurveyBuilder.State, action: SurveyBuilder.Actions): SurveySectionsBuilder.State {

  const sections = state.survey.sections;

  switch (action.type) {
    case 'new-section-added':
      return $actions.newSectionAdded(sections, action);

    case 'section-added':
      return $actions.sectionAdded(sections, action);

    case 'remove-section':
      return $actions.removeSection(sections, action);

    case 'toggle-section-hidden':
      return $actions.toggleSectionHidden(sections, action);

    case 'section-name-updated':
      return $actions.sectionNameUpdated(sections, action);

    case 'section-screener-toggled':
      return $actions.sectionScreenerToggled(sections, action);

    case 'section-break-added':
      return $actions.sectionBreakAdded(sections, action);

    case 'section-metadata-updated':
      return $actions.sectionMetadataUpdated(sections, action);

    default:
      return sections;
  }
}