import { useCallback, useEffect } from 'react';

type Props = {
  block: boolean;
  message?: string;
};

export const useBeforeUnload = ({ block, message }: Props) => {
  const handleBeforeUnload = useCallback((event: BeforeUnloadEvent) => {

    event.preventDefault();

    event.returnValue = message || '';

    return message;
  }, [message]);

  useEffect(() => {
    if (block) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [
    block,
    handleBeforeUnload,
  ]);
};

export default useBeforeUnload;