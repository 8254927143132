import image from 'static/images/website/research-community/banner.png';
import * as Layout from './Layout';
import styles from './style/Landing.css';

type Props = unknown;

export const Landing = (props: Props) => {

  return (
    <Layout.Screen className={styles.root}>
      <div className={styles.header}>
        <Layout.Title>
          {`Join a Best-in-Class `}<span className={styles.underline}>Research Community</span>
        </Layout.Title>
        {copy.subtitles.map((sub, i) =>
          <Layout.Subtitle key={`s-${i}`}>
            {sub.map(item =>
              <span
                className={item.className}
                key={item.text}>
                {item.text}
              </span>)}
          </Layout.Subtitle>)}
      </div>
      <div className={styles.graphic}>
        <img
          className={styles.img}
          src={image} />
      </div>
    </Layout.Screen>
  );
};

Landing.displayName = 'ResearchCommunity.Landing';

const copy = {
  header: `Join a Best-in-Class Research Community`,
  subtitles: [
    [
      {
        text: `Sentiment is an elite healthcare market research panel led by Trinity Life Sciences.`,
      },
    ], [
      {
        text: `We partner with physicians on highly engaging, `,
      },
      {
        className: styles.em,
        text: `paid research studies, `,
      },
    ], [
      {
        text: `and offer an `,
      },
      {
        className: styles.em,
        text: `exceptional white-glove experience.`,
      },
    ],
  ],
};