export function generateCameraTrackName() {
  return [
    'camera',
    Date.now(),
  ].join('-');
}

export function isCameraTrack(trackName: string) {
  return trackName?.startsWith('camera');
}

export function generateMicrophoneTrackName() {
  return [
    'microphone',
    Date.now(),
  ].join('-');
}

export function isMicrophoneTrack(trackName: string) {
  return trackName?.startsWith('microphone');
}

export function generateScreenTrackName() {
  return [
    'screen',
    Date.now(),
  ].join('-');
}

export function isScreenTrack(trackName: string) {
  return trackName?.startsWith('screen');
}

export const TWILIO_TRACK_ATTRIBUTE = 'data-cy-audio-track-name';