import { FollowedTopicsContainer } from '@containers/Topic';
import { InviteContacts } from '@screens/Main.Member/InviteContacts';
import { Column, FixedColumn, Scaffold } from '@screens/Main.Member/Layout';
import { CardContent, CardHeader, Divider } from '@presentation/Main';
import { AccountProfile } from '@screens/Main.Member/AccountProfile';
import { Agenda } from '@screens/Main.Member/Agenda';
import Card from '@screens/Main.Member/Card';
import { OnboardingChecklist } from '@/components/OnboardingChecklist';
import { Onboarding } from './Onboarding';
import AcceptedProjects from './AcceptedProjects';
import Invitations from './Invitations';

type Props = unknown;

export const Projects = (props: Props) => {

  return (
    <FollowedTopicsContainer>
      <Scaffold>

        <FixedColumn>
          <Card desktop>
            <CardContent>
              <AccountProfile />
            </CardContent>
          </Card>

          <Onboarding>
            <Card>
              <CardContent fluid>
                <OnboardingChecklist />
              </CardContent>
            </Card>
          </Onboarding>

          <Card desktop>
            <CardContent>
              <InviteContacts />
            </CardContent>
          </Card>
        </FixedColumn>

        <Column>
          <Card>
            <CardHeader>Invitations</CardHeader>
            <Divider />
            <CardContent fluid>
              <Invitations />
            </CardContent>
          </Card>

          <AcceptedProjects />
        </Column>

        <FixedColumn>
          <Card desktop>
            <CardHeader>Upcoming Calls</CardHeader>
            <CardContent fluid>
              <Agenda />
            </CardContent>
          </Card>

        </FixedColumn>

      </Scaffold>
    </FollowedTopicsContainer>
  );
};

Projects.displayName = 'Projects.Member';