import { useCallback, useContext, useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { useEditorState, useCommands as useRMCommands } from '@remirror/react';
import { Fragment } from 'prosemirror-model';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { TranscriptIdContext, TranscriptUpdatesContext } from '@/containers/Transcript/context';
import { buildNormalizedTextTimestampNodes, getTimeRangeForPosRange } from '@containers/Transcript/utils.selection';
import { useZIndexModifier } from '@/components/Popper';
import type { AutoCorrect } from '@/types/transcribe.rich-text';
import { MarkType } from '@/types/transcribe.rich-text';
import { TextReplaceExtensionPopper } from './TextReplace.Popper';
import { TextReplacePopperContext, TextReplacePopperPositionerContext } from './context';
import type { TextReplaceSelection } from './interfaces';
import { useTextReplacePositioner } from './hooks/useTextReplaceHighlight';
import { useChainedCommands, useHighlightWordSnappingSelection, useSelectionWordSnapping } from './hooks';
import styles from './style/TextReplace.Popper.css';

type Props = {
  children: React.ReactNode;
};

export const TextReplaceContainer = (props: Props) => {
  const [selection, setSelection] = useState<TextReplaceSelection>(null);

  const close = useCallback(() => setSelection(null), []);

  const value = {
    close,
    selection,
    setSelection,
  };

  return (
    <TextReplacePopperContext.Provider value={value}>
      <TextReplacePopperPositionerContainer>
        {props.children}
      </TextReplacePopperPositionerContainer>
    </TextReplacePopperContext.Provider>
  );
};

TextReplaceContainer.displayName = 'TextReplace.Container';

const TextReplacePopperPositionerContainer = (props: Props) => {
  const position = useTextReplacePositioner();

  return (
    <TextReplacePopperPositionerContext.Provider value={position}>
      {props.children}
    </TextReplacePopperPositionerContext.Provider>
  );
};

TextReplacePopperPositionerContainer.displayName = 'TextReplace.Popper.Positioner.Container';

export const TextReplacePopperContainer = (props: Partial<Props>) => {
  const { close, selection } = useContext(TextReplacePopperContext);
  const position = useContext(TextReplacePopperPositionerContext);
  const transcriptId = useContext(TranscriptIdContext);
  const { addCorrections, addOverwrittenAutoCorrections } = useContext(TranscriptUpdatesContext);

  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);

  const zIndexModifier = useZIndexModifier({ zIndex: 10 });

  const { attributes } = usePopper(
    position.element,
    popperElement,
    {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 5],
          },
        },
        zIndexModifier,
      ],
      placement: 'bottom-start',
    }
  );

  const commands = useChainedCommands();

  const handleCancel = useCallback(() => {
    close();
    if (selection.id) {
      commands.removeHighlights([selection.id]).run();
    }
  }, [close, commands, selection?.id]);

  const state = useEditorState();

  const wordsnap = useSelectionWordSnapping(selection, state);
  const [canUpdateHighlightSelection, setHighlightToWordSnappingSelection] = useHighlightWordSnappingSelection(wordsnap.selection);

  useEffect(() => {

    if (canUpdateHighlightSelection) {
      setHighlightToWordSnappingSelection();
    }

  }, [
    canUpdateHighlightSelection,
    setHighlightToWordSnappingSelection,
  ]);

  const rmCommands = useRMCommands();
  const handleSubmit = useCallback((value: string, addToDictionary: boolean) => {
    const nodes = buildNormalizedTextTimestampNodes(value, state.schema, wordsnap.ts.start, wordsnap.ts.end);

    const autocorrectIdentifiers: string[] = [];
    state.doc.nodesBetween(wordsnap.selection.from, wordsnap.selection.to, n => {
      if (n.isText) {
        for (const mark of n.marks.filter(m => m.type.name === MarkType.AutoCorrect)) {
          autocorrectIdentifiers.push((mark.attrs as AutoCorrect.Attributes).id);
        }
      }
    });

    rmCommands.removeMark({
      type: MarkType.AutoCorrect,
      selection: {
        from: wordsnap.selection.from,
        to: wordsnap.selection.to,
      },
    });

    rmCommands.insertNode(Fragment.from(nodes), {
      selection: wordsnap.selection,
    });

    commands.removeHighlights([selection.id]).run();

    addCorrections([{
      addToDictionary,
      text: value,
      ts: wordsnap.ts,
      originalText: wordsnap.text,
    }]);

    addOverwrittenAutoCorrections(autocorrectIdentifiers);
  }, [state.schema, state.doc, wordsnap.ts, wordsnap.selection, wordsnap.text, rmCommands, commands, selection.id, addCorrections, addOverwrittenAutoCorrections]);

  return (
    <>
      <div
        ref={setPopperElement}
        className={styles.container}
        {...attributes.popper}>
        {position.active &&
          <ClickAwayListener
            mouseEvent="onMouseDown"
            onClickAway={e => handleCancel()}
            touchEvent="onTouchStart">
            <TextReplaceExtensionPopper
              key={wordsnap?.text}
              loading={false}
              onCancel={handleCancel}
              onSubmit={handleSubmit}
              text={wordsnap.text} />
          </ClickAwayListener>}
      </div>
      {props.children}
    </>
  );
};

TextReplacePopperContainer.displayName = 'TextReplace.Popper.Container';