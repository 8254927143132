export const IndustryOptions = [
  'Manufacturing',
  'Retail',
  'Healthcare',
  'Government',
  'Agriculture/Mining',
  'Hospitality',
  'Financial Services',
  'Consulting/IT Services',
  'Transportation',
  'Energy',
  'Education',
  'Telecommunications',
  'Business Services (Law, Accounting, etc)',
  'Advertising/Media',
];

export const RoleOptions = [
  'Student, retired, or not currently employed ',
  'Contractor or consultant',
  'Individual Contributor',
  'Manager / Supervisor',
  'Director / Senior Director',
  'Vice President',
  'Executive / President',
  'Owner / C-Level',
];

export const DepartmentOptions = [
  'Customer service, client service, or call center',
  'Finance/Accounting/ procurement/ tax and revenue',
  'IT/technology',
  'Executive management, board, or executive office',
  'Legal/compliance/privacy',
  'Manufacturing, production, or operations',
  'Marketing/Direct Marketing/Digital',
  'Product Management/Development/Engineering',
  'Public relations/corporate communications',
  'Sales',
  'Strategy/corporate development',
];

export const RevenueOptions = [
  '$0 - $1M',
  '$1.0 - $10.0M',
  '$10.0 - $50.0M',
  '$50.0 - $100.0M',
  '$100.0 - $250.0M',
  '$250.0 - $500.0M',
  '$500.0 - $1.0B',
  '$1.0B - $5.0B',
  '$5.0B - $10.0B',
  '$10B+',
];

export const AgreeDisagreeOptions = [
  'Strongly disagree',
  'Disagree',
  'Neither agree nor disagree',
  'Agree',
  'Strongly Agree',
];