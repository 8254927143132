import { useCallback } from 'react';
import { useRemirrorContext } from '@remirror/react';
import * as Remirror from '@/types/rich-text';

export const useResetCommentEditor = () => {
  const { manager } = useRemirrorContext();

  const resetState = useCallback(() => {
    const emptyState = manager.createState({
      content: {
        type: Remirror.NodeType.Doc,
        content: [{
          type: Remirror.NodeType.Paragraph,
          content: [],
        }],
      },
    });
    manager.view.updateState(emptyState);
  }, [manager]);

  return resetState;
};