import { CardContent, Divider } from '@presentation/Main';
import { WorkspaceExpertFilesItem } from '@/types';
import Call from './Files.Call';
import Survey from './Files.Survey';
import styles from './style.css';

type Props = {
  items: WorkspaceExpertFilesItem[];
}

export default function ExpertFiles({ items }: Props) {
  return (
    <>
      <Divider />
      <CardContent className={styles.content}>
        <Files items={items} />
      </CardContent>
    </>
  );
}

function Files({ items }: Props) {
  if (!items.length) {
    return (
      <div className={styles.files}>
        <div className={styles.empty}>No files.</div>
      </div>
    );
  }

  return (
    <div className={styles.files}>
      {items.map((item, i) => {
        switch (item.type) {
          case 'call': {
            return <Call key={i} item={item} />;
          }
          case 'survey': {
            return <Survey key={i} item={item} />;
          }
        }
      })}
    </div>
  );
}

export { ExpertFiles };