import { useCallback } from 'react';
import { useChainedCommands } from '@remirror/react';
import type { FindAndReplaceExtension } from '../Extension.FindAndReplace';

export function useRemoveFindAndReplaceNode() {
  const chain = useChainedCommands<FindAndReplaceExtension>();

  const remove = useCallback(() => {
    chain
      .removeFindAndReplaceNode()
      .focus()
      .run();
  }, [chain]);

  return remove;
}