import type { ReactFrameworkOutput } from '@remirror/react';
import { createNamedContext } from '@utils';
import type { FindReplaceContextValue, ReassignSpeakerSelectionContextValue, TextReplacePopperContextValue, TextReplacePopperPositionerContextValue, TranscriptHelpers } from './interfaces';
import type { TranscriptExtensions } from './interfaces.extension.remirror';

type TranscriptCommands =
  ReactFrameworkOutput<TranscriptExtensions>['chain'];

// These are mainly for using inside of comments, which get wraped in another remirror context
export const TranscriptChainedCommandsContext = createNamedContext<TranscriptCommands>(undefined, 'TranscriptCommandsContext');
export const TranscriptHelpersContext = createNamedContext<TranscriptHelpers.Helpers>(undefined, 'TranscriptHelpersContext');
export const TranscriptEditorRefContext = createNamedContext<HTMLDivElement>(undefined, 'TranscriptEditorRefContext');

export const TextReplacePopperContext = createNamedContext<TextReplacePopperContextValue>(undefined, 'TextReplace.Popper.Context');
export const TextReplacePopperPositionerContext = createNamedContext<TextReplacePopperPositionerContextValue>(undefined, 'TextReplace.Popper.Positioner.Context');

export const FindReplaceContext = createNamedContext<FindReplaceContextValue>(undefined, 'FindReplace.Context');
export const ReassignSpeakerSelectionContext = createNamedContext<ReassignSpeakerSelectionContextValue>(undefined, 'ReassignSpeakerSelection.Context');

export const MenuColorDisplayContext = createNamedContext<[boolean, (val: boolean) => void]>(undefined, 'MenuColorDisplayContext');

export const TranscriptTimestampVisibilityContext = createNamedContext<boolean>(true, 'TranscriptTimestampVisibilityContext');

type TagTimePickingContextValue = {
  picking: boolean;
  setPicking: (val: boolean) => void;
};
export const TagTimePickingContext = createNamedContext<TagTimePickingContextValue>(undefined, 'TagTimePickingContext');