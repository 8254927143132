import { useCallback, useState } from 'react';
import { ShareProjectWithExpert as ShareProjectWithExpertModal } from '@/components/Modal/ShareProjectWithExpert';
import type { ModalProps } from './interfaces';

type Props = Pick<ModalProps, 'project'>;

export const useShareWithExpertModal = ({ project }: Props) => {
  const [visible, setVisibility] = useState(false);
  const toggleVisibility = useCallback(() => setVisibility(!visible), [visible]);

  const Modal = useCallback(() => {
    if (!visible) {
      return null;
    }

    return (
      <ShareProjectWithExpertModal
        onClose={toggleVisibility}
        project={project} />
    );
  }, [
    project,
    toggleVisibility,
    visible,
  ]);

  return [toggleVisibility, Modal] as const;
};

export default useShareWithExpertModal;