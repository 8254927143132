import { useDevices } from '@utils';
import type { ContextValue } from './Context';
import { DeviceProviderContext } from './Context';

type Props = {
  useAudio: boolean;
  useVidio: boolean;
  enabled?: boolean;
} & ChildrenProps;

export const DeviceProviderContainer = ({ useAudio, useVidio, enabled = true, children }: Props) => {
  const audioDevices = useDevices({ video: false, audio: enabled ? useAudio : false });
  const videoDevices = useDevices({ video: enabled ? useVidio : false, audio: false });

  const value: ContextValue = {
    videoInputDevices: videoDevices.videoInputDevices,
    hasVideoInputDevices: videoDevices.hasVideoInputDevices,
    videoErrorState: videoDevices.errorState,
    videoLoading: enabled && useVidio && videoDevices.loading,
    audioInputDevices: audioDevices.audioInputDevices,
    audioOutputDevices: audioDevices.audioOutputDevices,
    audioErrorState: audioDevices.errorState,
    hasAudioInputDevices: audioDevices.hasAudioInputDevices,
    audioLoading: enabled && useAudio && audioDevices.loading,
  };

  return (
    <DeviceProviderContext.Provider value={value}>
      {children}
    </DeviceProviderContext.Provider>
  );
};