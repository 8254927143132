export enum ChatChannelStatus {
  Joined = 'joined',
  Invited = 'invited',
  NotParticipating = 'not_participating',
}

export enum ChatMemberStatus {
  NotJoined = 1,
  Joined = 2,
}

export enum ChatUpdateReason {
  Attributes = 'attributes',
  CreatedBy = 'createdBy',
  DateCreated = 'dateCreated',
  DateUpdated = 'dateUpdated',
  FriendlyName = 'friendlyName',
  LastConsumedMessageIndex = 'lastConsumedMessageIndex',
  LastMessage = 'lastMessage',
  NotificationLevel = 'notificationLevel',
  Status = 'status',
  UniqueName = 'uniqueName',
}

export enum ChatSidebar {
  Channels = 1,
  Directory = 2,
}