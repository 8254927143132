import { useContext, useMemo } from 'react';
import { CallStatus } from '@enums';
import { Modal } from '@/components/Modal/Modal';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import { Header } from '@/components/Modal/Header';
import { FrameKey } from './interfaces';
import { FrameContext, ParamsContext, ReadyStateContext, UnconfirmedCallsContext } from './Context';
import * as Frame from './Frame';
import { useExitCallScheduling, useSchedulerCalendarSelector } from './hooks';
import styles from './style/Modal.CallScheduling.css';

type Props = unknown;

export const CallScheduling = (props: Props) => {
  const ctx = {
    frame: useContext(FrameContext),
    ready: useContext(ReadyStateContext),
    scheduling: useContext(ParamsContext),
    unconfirmed: useContext(UnconfirmedCallsContext),
  };

  const selectScheduler = useSchedulerCalendarSelector();

  const { view } = ctx.frame;

  const title = useMemo(() => {
    switch (view.frame) {
      case FrameKey.RequestAvailability:
        return `Request Availability`;

      case FrameKey.AddAvailability: {
        const scheduler = selectScheduler(view.data);
        return `Add Availability for ${scheduler.name}`;
      }

      case FrameKey.AttendeeList:
        return `Conference Attendees`;

      case FrameKey.ConferenceSettings:
        return `Conference Settings`;

      case FrameKey.Confirmation:
        return `Confirm Details & Send Invitations`;

      case FrameKey.ManageAttendees:
        return `Add Attendees`;

      case FrameKey.ModeratorSchedulers:
        return `Pick Moderator`;

      case FrameKey.UnconfirmedCalls: {
        return ctx.unconfirmed.data.items.filter(x => x.statusId === CallStatus.Rescheduling).length > 1
          ? `Pending calls with this user already exist for this project`
          : `A pending call with this user already exists for this project`;
      }

      case FrameKey.Booking:
      default:
        return `Schedule Call`;
    }
  }, [
    ctx.unconfirmed.data,
    selectScheduler,
    view,
  ]);

  const FrameComponent = useMemo(() => {
    switch (view.frame) {
      case FrameKey.RequestAvailability:
        return Frame.RequestAvailability;

      case FrameKey.AddAvailability:
        return Frame.AddAvailability;

      case FrameKey.AttendeeList:
        return Frame.AttendeeList;

      case FrameKey.ConferenceSettings:
        return Frame.ConferenceSettings;

      case FrameKey.Confirmation:
        return Frame.Confirmation;

      case FrameKey.ManageAttendees:
        return Frame.ManageAttendees;

      case FrameKey.ModeratorSchedulers:
        return Frame.ModeratorSchedulers;

      case FrameKey.UnconfirmedCalls:
        return Frame.UnconfirmedCalls;

      case FrameKey.Booking:
      default:
        return Frame.Booking;
    }
  }, [view]);

  const LoadingIndicator = useMemo(() => {
    return () => {
      return (
        <div className={styles.loader}>
          <ActivityIndicator show />
        </div>
      );
    };
  }, []);

  const close = useExitCallScheduling();

  return (
    <Modal
      classes={{
        root: styles.wrap,
        close: styles.close,
      }}
      onClose={close}
      disableOverlayClick={true}
      open>
      <Header className={styles.header}>{title}</Header>
      {!ctx.ready
        ? <LoadingIndicator />
        : <FrameComponent />}
    </Modal>
  );
};

CallScheduling.displayName = 'Modal.CallScheduling';