import { createRef, useEffect, useRef } from 'react';
import LinkedinIcon from '@mui/icons-material/LinkedIn';
import { cx } from '@utils';
import { Logo } from '@/components/Branding';
import { User } from '@/components/icons';
import styles from './style/PopulateProfile.css';

const R = 52;

type Props = unknown;

const PrepopulatingProfile = (props: Props) => {
  const ref = createRef<SVGCircleElement>();
  const circumference = useRef<number>();

  useEffect(() => {
    const svg = ref.current;

    if (!svg) return;

    if (circumference.current) return;

    const radius = svg.r.baseVal.value;
    const circ = radius * 2 * Math.PI;

    circumference.current = circ;
    svg.style.strokeDashoffset = `${R}`;
    svg.style.strokeDasharray = `${circumference.current} ${circumference.current}`;

  }, [ref]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={cx(styles.main, styles.syncing)}>
          <div className={styles.box}>
            <LinkedinIcon
              className={styles.linkedin}
              style={{ fontSize: 43 }} />
          </div>
          <div className={styles.dots} />
          <Logo.Square size={40} />
          <div className={styles.dots}>
            <div className={styles.chevron} />
          </div>
          <User
            color="var(--gray)"
            size="40"
            strokeWidth={0.5} />
        </div>
        <div className={styles.title}>{loading}</div>
        <div className={styles.indicator}>
          <svg
            className={styles.svg}
            width="120"
            height="120">
            <circle
              ref={ref}
              className={styles.progress}
              stroke="var(--pri-02)"
              strokeWidth="12"
              strokeLinecap="round"
              strokeDashoffset={R * 2 * Math.PI}
              fill="transparent"
              r={R}
              cx="60"
              cy="60" />
          </svg>
          <div className={styles.ring} />
        </div>
      </div>
    </div>
  );
};

const loading = `Just a moment, we are uploading your profile to Sentiment...`;

export { PrepopulatingProfile };
export default PrepopulatingProfile;