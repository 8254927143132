import { useCallback, useMemo } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import { useQuestionBuilderItemContext } from '@containers/SurveyBuilder.Question/Context';
import * as enums from '@enums';
import DropDown from '@/components/DropDown';

type Props = {
  items: enums.SurveyQuestionType[];
};

type QuestionTypeDropdownItem = {
  id: enums.SurveyQuestionType;
  name: string;
};

export const QuestionTypeDropdown = ({ items  }: Props) => {

  const [_, dispatch] = useSurveyBuilderState();
  const item = useQuestionBuilderItemContext();

  const dropdownItems = useMemo((): QuestionTypeDropdownItem[] => {
    return items.map(k => ({
      id: Number(k),
      name: enums.utils.SurveyQuestionType.getName(k),
    }));
  }, [items]);

  const text = useMemo(() => {
    return item.typeId
      ? enums.utils.SurveyQuestionType.getName(item.typeId)
      : 'Question Type';
  }, [item.typeId]);

  const handleSelect = useCallback((selected: QuestionTypeDropdownItem) => {
    dispatch({
      type: 'update-question-type',
      questionIdentifier: item.base.identifier,
      typeId: selected.id,
    });
  }, [
    dispatch,
    item.base.identifier,
  ]);

  return (
    <DropDown
      items={dropdownItems}
      getItemValue={v => v.name}
      onSelect={handleSelect}
      text={text} />
  );
};

export default QuestionTypeDropdown;