import { Fragment, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { InvitationType } from '@enums';
import { selectConsultantInvitations } from '@store/selectors';
import Placeholder from './Invitations.Placeholder';
import { AdHocCallTimeProposal } from './Invitations.AdHocCallTimeProposal';
import AdHocCallInvitation from './Invitations.AdHocCall';
import ProjectCallInvitation from './Invitations.ProjectCall';
import ProjectInvitation from './Invitations.Project';
import { Invitations as I } from '@/types';

export const Invitations = () => {
  const invitations = useSelector(selectConsultantInvitations);

  const renderInvitation = useCallback((item: I.Item) => {
    switch (item.type) {
      case InvitationType.AdHocCallRequest:
        return (
          <AdHocCallInvitation
            item={item.item} />
        );

      case InvitationType.ProjectCall:
        return (
          <ProjectCallInvitation
            item={item.item} />
        );
      
      case InvitationType.ProjectRequest:
        return (
          <ProjectInvitation
            item={item.item} />
        );

      case InvitationType.AdHocCallTimeProposal:
        return (
          <AdHocCallTimeProposal
            item={item.item} />
        );

      default: return null;
    }

  }, []);

  if (!invitations.length) {
    return <Placeholder />;
  }

  return <>
    {
      invitations.map(invite => (
        <Fragment key={`${invite.type}-${invite.item.id}`}>
          {renderInvitation(invite)}
        </Fragment>
      ))
    }
  </>;
};

export default Invitations;