import extend from '@enums/extend';

export enum PaymentMethod {
  Card = 1,
  BankAccount = 2,
}

const map = new Map([
  [PaymentMethod.Card,    { name: 'Bank Card' }],
  [PaymentMethod.BankAccount, { name: 'ACH Bank Account' }],
]);

export default {
  PaymentMethod: extend(map),
};