export function getFileExtension(name: string) {
  const split = name.split('.');

  return split.length > 1
    ? split.pop()
    : null;
}

export function getFileNameParts(name: string) {
  const split = name.split('.');
  const extension = split.length > 1
    ? split.pop()
    : null;

  return [
    split.join('.'),
    extension,
  ];
}

export function getFileNameVersion(name: string, version: number) {
  const parts = getFileNameParts(name);

  const newBase = `${parts[0]} (${version})`;

  return [
    newBase,
    parts[1],
  ].filter(Boolean).join('.');

}