import { cx } from '@utils';
import styles from './style/TableBody.css';

type TableBodyProps = {
  children: React.ReactNode;
  className?: string;
};

export const TableBody = (props: TableBodyProps) => (
  <div className={cx(styles.root, props.className)}>
    {props.children}
  </div>
);
