import { useCallback, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as api from '$admin/api';
import Toast from '@/components/Toast';
import { CallCancellationContext } from './Context';
import { Modal } from './Cancellation.Modal';
import { MutationParams, OnSuccess, SetContext } from './interfaces';

type Props = {
  children:   React.ReactNode;
  onSuccess?: OnSuccess;
};

export const Container = (props: Props) => {
  const [state, dispatch] = useState<SetContext.Params>(null);

  const resetContext = useCallback(() => {
    dispatch(null);
  }, []);

  const mutation = useMutation([
    `put:admin/calls/bookings/cancellations`,
    state?.callId,
  ], (data: MutationParams) => {
    return api.calls.bookings.cancel({
      callId: state.callId,
      notify: data.notify,
    });
  }, {
    onSuccess: res => {
      props.onSuccess?.(res);

      Toast.success({
        title: `Success`,
      });
    },
  });

  const value = {
    mutation,
    params: state,
    resetContext,
    setContext: dispatch,
  };

  return (
    <CallCancellationContext.Provider value={value}>
      {props.children}
      {state &&
        <Modal />}
    </CallCancellationContext.Provider>
  );
};

Container.displayName = 'Call.Cancellation.Container';