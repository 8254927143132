import type {
  ApplySchemaAttributes,
  NodeExtensionSpec,
  NodeSpecOverride,
} from '@remirror/core';
import {
  extension,
  ExtensionTag,
  NodeExtension,
} from '@remirror/core';
import { NodeType } from '@/types/transcribe.rich-text';
import styles from './style/Monologue.css';

@extension({
  defaultOptions: {},
})
export class MonologueTextExtension extends NodeExtension {
  get name() {
    return NodeType.MonologueText as const;
  }

  createTags() {
    return [
      ExtensionTag.LastNodeCompatible,
      ExtensionTag.TextBlock,
      ExtensionTag.Block,
      ExtensionTag.FormattingNode,
    ];
  }

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    return {
      content: 'inline*',
      draggable: false,
      ...override,
      attrs: {
        ...extra.defaults(),
      },
      parseDOM: [
        {
          tag: 'p',
          getAttrs: node => ({
            ...extra.parse(node),
          }),
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM: node => {
        return ['p', {
          ...extra.dom(node),
          class: styles.text,
          // contentEditable: 'false',
          spellCheck: 'false',
        }, 0];
      },
    };
  }
}