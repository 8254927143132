import { useMemo } from 'react';
import { useTwilioVoice, useConferenceInstance, IConference } from '@containers/Conference';

export function useYourParticipant() {
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom>();
  const twilio = useTwilioVoice();

  return useMemo(() => {
    return instance.participants.find(p => p.twilioIdentity === twilio.device.identity);
  }, [twilio.device.identity, instance.participants]);
}