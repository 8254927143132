import { useCallback, useContext, useMemo, useState } from 'react';
import { format, startOfDay } from 'date-fns/fp';
import { addMinutes, endOfDay, isToday, formatDuration, set, subMinutes } from 'date-fns';
import { ButtonOutlined } from '@presentation';
import { getCurrentTimezone } from '@/utils';
import { CalendarRange } from '@/types';
import { SelectUnderlined, OnSelectChange } from '$admin/components/Select';
import { Button } from '@/components/Button';
import { Focusable } from '@/components/Focusable';
import { DatePicker, TimePicker } from '@/components/DatePicker';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { useTabViewContext } from '@/components/TabView/hooks';
import { ConflictWarning } from './Booking.ConflictWarning';
import { CalendarsContext, FrameContext, ParamsContext, SettingsContext } from './Context';
import { FrameKey, TabKey } from './interfaces';
import styles from './style/Booking.Schedule.css';

type Props = unknown;

const minuteIntervals = 5;

export const Custom = (props: Props) => {
  const ctx = {
    calendars: useContext(CalendarsContext),
    frame: useContext(FrameContext),
    scheduling: useContext(ParamsContext),
    settings: useContext(SettingsContext),
  };

  const duration = ctx.settings.conference.defaultDuration;
  const tabview = useTabViewContext();
  const [day, setDay] = useState<Date>(new Date());
  const [range, setRange] = useState(getRange(day, duration));

  const [conflict, setConflict] = useState(false);

  const setTime = useCallback((date: Date) => {
    setRange({
      end: addMinutes(date, duration),
      start: date,
    });
  }, [
    duration,
  ]);

  const setDate = useCallback((date: Date) => {
    const time = getRange(date, duration, range);

    setDay(date);
    setRange(time);
  }, [
    duration,
    range,
  ]);

  const start = useMemo(() => {
    return isToday(day)
      ? getDayStartTime(day)
      : startOfDay(day);
  }, [day]);

  const handleSubmit = useCallback(() => {
    ctx.settings.setValue(x => ({ ...x, time: range }));
    ctx.frame.goToFrame({ frame: FrameKey.AttendeeList });
  }, [
    ctx.frame,
    ctx.settings,
    range,
  ]);

  const handleChangeDuration: OnSelectChange<number> = useCallback(e => {
    ctx.settings.setValue(state => ({
      ...state,
      conference: {
        ...state.conference,
        defaultDuration: e.target.value,
      },
    }));

    setRange({
      end: addMinutes(range.start, e.target.value),
      start: range.start,
    });
  }, [
    ctx.settings,
    range,
    setRange,
  ]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>

          <div className={styles.row}>
            <div className={styles.fields}>
              <div className={styles.field}>
                <div className={styles.label}>Date</div>
                <Focusable>
                  <div className={styles.datepicker}>
                    <DatePicker
                      minDate={new Date()}
                      selected={day}
                      onChange={setDate} />
                  </div>
                </Focusable>
              </div>
              <div className={styles.field}>
                <div className={styles.label}>Start Time</div>
                <Focusable>
                  <div className={styles.datepicker}>
                    <TimePicker
                      dateFormat="hh:mm aa"
                      minDate={start}
                      minTime={start}
                      maxTime={endOfDay(day)}
                      onChange={setTime}
                      renderCustomHeader={null}
                      selected={range.start}
                      showPopperArrow={false}
                      showTimeSelect
                      showTimeSelectOnly
                      timeFormat="hh:mm aa"
                      timeIntervals={minuteIntervals} />
                  </div>
                </Focusable>
              </div>
              <div className={styles.field}>
                <div className={styles.label}>End Time</div>
                <div>{formatTime(range.end)}</div>
              </div>
              <div className={styles.field}>
                <div className={styles.label}>Duration</div>
                <div className={styles.duration}>
                  <SelectUnderlined
                    classes={{
                      root: styles.select,
                      select: styles.input,
                    }}
                    options={Durations}
                    onChange={handleChangeDuration}
                    value={duration} />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.timezone}>
            {`All times displayed in ${getCurrentTimezone()}.`}
          </div>

          <ConflictWarning
            end={range.end}
            onChange={setConflict}
            start={range.start} />

        </div>

        <ButtonSet className={styles.footer}>
          <ButtonOutlined
            className={styles.btn}
            color="silver"
            onClick={() => tabview.jumpTo(TabKey.ViewAvailability)}>
            Cancel
          </ButtonOutlined>
          <Button.Primary
            className={styles.btn}
            onClick={handleSubmit}
            variant="brick">
            Confirm
          </Button.Primary>
        </ButtonSet>
      </div>
    </div>
  );
};

Custom.displayName = 'Schedule.Custom';

function formatSlotDuration(minutes: number) {
  return formatDuration({ minutes }, { format: ['minutes'] });
}

const formatTime = format('h:mm a');

type Options = {
  interval:   number;
  maxEndTime: Date;
};

function createRange(start: Date, options: Options) {
  const incremented = addMinutes(start, options.interval);

  if (incremented <= options.maxEndTime) {
    return {
      start,
      end: incremented,
    };
  }

  return getLastRange(options.maxEndTime, options.interval);
}

function getLastRange(end: Date, interval: number) {
  return {
    start: subMinutes(end, interval),
    end,
  };
}

function getDayStartTime(date: Date, interval = minuteIntervals) {
  const resolvedDate = isToday(date)
              ? new Date()
              : startOfDay(date);

  const coeff = 1000 * 60 * interval;
  return new Date(Math.ceil(resolvedDate.getTime() / coeff) * coeff);
}

function getRange(date: Date, interval: number, range?: CalendarRange<Date>) {
  if (range) {

    const earliest = getDayStartTime(date);

    const preserved = set(range.start, {
      month: date.getMonth(),
      date: date.getDate(),
      year: date.getFullYear(),
    });

    const start = preserved < earliest
      ? earliest
      : preserved;

    return {
      end: addMinutes(start, interval),
      start,
    };
  }

  const start = getDayStartTime(date);

  const segment = createRange(start, {
    interval,
    maxEndTime: endOfDay(date),
  });

  return {
    end: segment.end,
    start: segment.start,
  };
}

const Durations = [15, 30, 45, 60, 75, 90, 105, 120, 150, 180, 210, 240].map(value => ({
  name: formatSlotDuration(value),
  id: value,
}));