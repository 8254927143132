import { useCallback, useEffect, useState } from 'react';
import * as api from '@api';
import { useAsyncStateLazy } from '@utils';
import {
  GroupEntityTagsContext,
  GroupEntityTagsDispatchContext,
  GroupEntityTagsFetchContext,
  GroupEntityTagsLoadingContext,
  GroupEntityTagsUpdateContext,
} from './Context';
import { GroupTagsContextValue, GroupTagUpdateItem, GroupFileTagsProps } from './interfaces';

export default function GroupFileTagsContainer(props: GroupFileTagsProps) {
  const [state, dispatch] = useState<GroupTagsContextValue>(GroupFileTagsContainer.initialState);

  const [response, fetch] = useAsyncStateLazy(async () => {
    const result = await api.tags.group.getFileTags({
      fileId: props.fileId,
    });

    dispatch(result.tags);

    return result.tags;
  }, [props.fileId]);

  const update = useCallback(async (tags: GroupTagUpdateItem[]) => {
    const result = await api.tags.group.updateFileTags({
      tags,
      fileId: props.fileId,
    });
    dispatch(result.tags);
  }, [props.fileId]);

  useEffect(() => {
    if (props.fileId) {
      fetch();
    }
  }, [fetch, props.fileId]);

  return (
    <GroupEntityTagsContext.Provider value={state}>
      <GroupEntityTagsLoadingContext.Provider value={response.loading}>
        <GroupEntityTagsFetchContext.Provider value={fetch}>
          <GroupEntityTagsUpdateContext.Provider value={update}>
            <GroupEntityTagsDispatchContext.Provider value={dispatch}>
              {props.children}
            </GroupEntityTagsDispatchContext.Provider>
          </GroupEntityTagsUpdateContext.Provider>
        </GroupEntityTagsFetchContext.Provider>
      </GroupEntityTagsLoadingContext.Provider>
    </GroupEntityTagsContext.Provider>
  );
}

const initial: GroupTagsContextValue = [];

GroupFileTagsContainer.initialState = initial;

export { GroupFileTagsContainer };