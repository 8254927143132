import { useContext } from 'react';
import { FollowedTopicsContext, SuggestedTopicsContext } from '@containers/Topic/Context';
import { useCreateTopicsState } from './useCreateTopicsState';

export const useSuggestedTopicsState = () => {
  const followed = useContext(FollowedTopicsContext);
  const suggested = useContext(SuggestedTopicsContext);

  const items = useCreateTopicsState({
    data: suggested.topics,
    followed: followed.topics,
  });

  return items;
};