import type { SurveyQuestionType } from '@enums';
import type { MultiselectQuestion } from '@/types';
import type { Validation } from '../interfaces';
import { assertValidOptions } from './validation.options';
import { assertHasValue, returnAssertFailure } from './validation.base';
import { assertValidSelectionSettings as assertSelectionSettings, getSelectionSettingsMinMax } from './validation.settings.selections';

export const DefaultOptions = 4;
export const MinOptions = 2;
export const MaxOptions = 55;

export const DefaultSettings: MultiselectQuestion.Settings = {
  orderOptionsBasedOnSource: false,
  includeNoneOfTheAbove: false,
  gridFormat: false,
  maximumSelections: null,
  minimumSelections: null,
  randomization: false,
  rationale: {
    enabled: false,
    minimum: 75,
  },
};

export const DefaultOptionMetadata: MultiselectQuestion.OptionMetadata = {
  canDelete: true,
  canModifyValue: true,
  isAnchored: false,
  isExclusive: false,
  isOpenEnded: false,
  template: {},
};

export function getSelectionSettingOptions(item: MultiselectQuestion.Question) {
  return getSelectionSettingsMinMax({
    hasNoneOfTheAbove: item.settings.includeNoneOfTheAbove,
    options: item.options.length,
  });
}

export function assertValidSelectionSettings(question: MultiselectQuestion.Question) {
  const [min, max] = getSelectionSettingOptions(question);

  return assertSelectionSettings({
    min,
    max,
    settings: question.settings,
  });
}

export function validateQuestion(question: MultiselectQuestion.Question): Validation.ValidateQuestionResult<SurveyQuestionType.Multiselect> {
  const hasValue = assertHasValue(question);
  if (hasValue.success === false) {
    return returnAssertFailure(hasValue, question);
  }

  const hasValidOptions = assertValidOptions(question.options, MinOptions, MaxOptions);
  if (hasValidOptions.success === false) {
    return returnAssertFailure(hasValidOptions, question);
  }

  const hasValidSettings = assertValidSelectionSettings(question);
  if (hasValidSettings.success === false) {
    return returnAssertFailure(hasValidSettings, question);
  }

  return {
    success: true,
    result: {
      question,
    },
  };
}