import { Buffer } from 'buffer/';
import { useEffect, useReducer } from 'react';
import { useQueryParams, type QueryParamConfig } from 'use-query-params';
import { useDebounceCallback } from '@utils';
import type { Members as IMembers } from '$admin/interfaces/search';
import type { Members } from '../interfaces';
import { MembersFilterContext } from '../Context';

type Props = ChildrenProps;

export function StateContainerFilters({ children }: Props) {
  const [queryParams, setQueryParams] = useQueryParams({
    s: SearchParam,
  });

  const [state, dispatch] = useReducer(reducer, createInitialState(queryParams?.s));

  const setQueryParamsDebounced = useDebounceCallback(setQueryParams, 300);

  useEffect(() => {
    if (!state.active.length) {
      setQueryParams({ s: null });
    } else {
      const value: Members.Filters.StateQueryParams = {
        a: state.active.map(a => ({
          o: a.op,
          f: a.field,
          fq: a.fieldQual,
          fo: a.fieldOp,
          v: a.value,
        })),
      };

      if (JSON.stringify(value) !== JSON.stringify(queryParams)) {
        setQueryParamsDebounced({ s: value });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setQueryParamsDebounced, state.active]);

  useEffect(() => {
    dispatch({
      type: 'query-params-update',
      active: queryParams?.s?.a?.map(f => ({
        op: f.o,
        field: f.f,
        fieldQual: f.fq,
        fieldOp: f.fo,
        value: f.v,
      })) ?? [],
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams?.s?.a]);

  return (
    <MembersFilterContext.Provider value={[state, dispatch]}>
      {children}
    </MembersFilterContext.Provider>
  );
}

function reducer(state: Members.Filters.State, action: Members.Filters.Action): Members.Filters.State {
  switch (action.type) {
    case 'init': return createInitialState();
    case 'update-page': {
      return {
        ...state,
        pageIndex: action.index != undefined ? action.index : state.pageIndex,
        pageSize: action.size != undefined ? action.size : state.pageSize,
      };
    }
    case 'query-params-update': {
      return {
        ...state,
        active: action.active,
      };
    }
    case 'update-pending-filter-field': {
      return {
        ...state,
        pending: {
          ...state.pending,
          field: action.field,
          fieldQual: action.fieldQual,
          fieldOp: action.operator,
          value: [],
        },
      };
    }
    case 'update-pending-filter-field-qualifier': {
      return {
        ...state,
        pending: {
          ...state.pending,
          fieldQual: action.qualifier,
          fieldOp: action.operator,
        },
      };
    }
    case 'update-pending-filter-field-operator': {
      return {
        ...state,
        pending: {
          ...state.pending,
          fieldOp: action.operator,
        },
      };
    }
    case 'update-pending-filter-field-value': {
      return {
        ...state,
        pending: {
          ...state.pending,
          value: action.value,
        },
      };
    }
    case 'add-pending-filter': {
      const op = state.active?.length ? state.active[0].op : state.pending.op;
      return {
        ...state,
        pageIndex: 0,
        active: [
          ...state.active,
          {
            ...state.pending,
            op,
          },
        ],
        pending: createDefaultPending(),
      };
    }
    case 'update-active-filter-operator': {
      return {
        ...state,
        pageIndex: 0,
        active: state.active.map(a => ({
          ...a,
          op: action.operator,
        })),
      };
    }
    case 'update-active-filter-field': {
      return {
        ...state,
        pageIndex: 0,
        active: state.active.map((a, i) => {
          if (i !== action.index) return a;
          return {
            op: a.op,
            field: action.field,
            fieldQual: action.fieldQual,
            fieldOp: action.operator,
            value: [],
          };
        }),
      };
    }
    case 'update-active-filter-field-qualifier': {
      return {
        ...state,
        pageIndex: 0,
        active: state.active.map((a, i) => {
          if (i !== action.index) return a;
          return {
            ...a,
            fieldQual: action.qualifier,
            fieldOp: action.operator,
          };
        }),
      };
    }
    case 'update-active-filter-field-operator': {
      return {
        ...state,
        pageIndex: 0,
        active: state.active.map((a, i) => {
          if (i !== action.index) return a;
          return {
            ...a,
            fieldOp: action.operator,
          };
        }),
      };
    }
    case 'update-active-filter-field-value': {
      return {
        ...state,
        pageIndex: 0,
        active: state.active.map((a, i) => {
          if (i !== action.index) return a;
          return {
            ...a,
            value: action.value,
          };
        }),
      };
    }
    case 'remove-active-filter': {
      return {
        ...state,
        pageIndex: 0,
        active: state.active.filter((_, i) => i !== action.index),
      };
    }
    default: throw new Error(`Unhandled action in reducer!`);
  }
}

function createDefaultPending(): IMembers.Filters.Item {
  const pending: IMembers.Filters.Item<'company'> = {
    op: 'a',
    field: 'company',
    fieldQual: 'any',
    fieldOp: 'ioo',
    value: [],
  };
  return pending;
}

function createInitialState(q?: Members.Filters.StateQueryParams): Members.Filters.State {
  return {
    pending: createDefaultPending(),
    active: q?.a.map(f => ({
      op: f.o,
      field: f.f,
      fieldQual: f.fq,
      fieldOp: f.fo,
      value: f.v,
    })) ?? [],
    pageSize: 50,
    pageIndex: 0,
  };
}

const SearchParam: QueryParamConfig<Members.Filters.StateQueryParams, Members.Filters.StateQueryParams> = {
  encode: (state: Members.Filters.StateQueryParams) => state ? Buffer.from(JSON.stringify(state)).toString('base64') : undefined,
  decode: (q: string) => q ? JSON.parse(Buffer.from(q, 'base64').toString()) as Members.Filters.StateQueryParams : undefined,
};