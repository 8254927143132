import { Ranking } from '@enums';
import { Select } from '$admin/components/Select';

type Props = {
  className?: string;
  onChange:   Parameters<typeof Select>[0]['onChange'];
};

export const MemberRankingInput = (props: Props) => {
  const options = {
    [Ranking.Low]: 'Low',
    [Ranking.Normal]: 'Normal',
    [Ranking.High]: 'High',
  };

  return (
    <Select
      className={props.className}
      defaultValue={Ranking.Normal}
      id="ranking"
      name="ranking"
      onChange={props.onChange}
      options={options} />
  );
};