import { useState, useCallback } from 'react';
import * as api from '@api';
import { StarRating, StarRatingPreview } from '@/components/StarRating';
import type { ProjectSurveyResponseRating } from '@/types/project-surveys';

type Props = {
  rating: ProjectSurveyResponseRating;
} & IProjectSurveyResponseId & IProjectId;

export const SurveyResponseRating = ({ projectId, projectSurveyResponseId, ...props }: Props) => {
  const [rating, setRating] = useState(props.rating);

  const saveRating = useCallback((val: number) => {
    return api.projects.surveys.saveProjectSurveyResponseRating({
      value: val,
      projectId,
      projectSurveyResponseId,
      skipped: false,
    }).then(() => setRating({ ...rating, value: val }));
  }, [
    projectId,
    projectSurveyResponseId,
    rating,
  ]);

  if (!rating || rating.skipped) {
    return (
      <StarRating rating={rating?.value} onClick={saveRating} />
    );
  } else {
    return (
      <StarRatingPreview rating={rating.value} />
    );
  }
};