import { useCallback, useMemo } from 'react';
import type { SurveyQuestionType } from '@enums';
import type { MatrixRangeQuestion } from '@/types';
import { useSurveyFormQuestionContext, useValidateRationale } from './hooks';
import { SurveyFormBackContainer, SurveyFormSubmitContainer } from './BaseNavigation';
import type { ToggleMatrixValue } from './Context';
import { SurveyFormCanSubmitContext, ToggleMatrixValueContext, useSurveyFormQuestionAnswer } from './Context';

type Props =
  ChildrenProps;

export const MatrixRangeFormContainer = (props: Props) => {
  const [answer, setAnswer] = useSurveyFormQuestionAnswer<SurveyQuestionType.MatrixRange>();
  const question = useSurveyFormQuestionContext<SurveyQuestionType.MatrixRange>();

  const validateRationale = useValidateRationale();

  const canSubmit = useMemo(() => {

    const isRationaleValid = validateRationale();

    if (!isRationaleValid) return false;
    const answeredRows = answer.items.filter(f => !!f.optionId);
    return answeredRows.length === question.matrixRows.length;
  }, [answer, question, validateRationale]);

  const toggleRowOption: ToggleMatrixValue = useCallback((rowId, optionId) => {
    const isAlreadySelected = answer.items.some(s => s.matrixRowId === rowId);

    if (isAlreadySelected) {
      const items = answer.items.reduce<MatrixRangeQuestion.RespondentAnswer.Item[]>((acc, x) => {
        if (x.matrixRowId === rowId) {
          return acc.concat({
            matrixRowId: x.matrixRowId,
            optionId,
          });
        }
        return acc.concat(x);
      }, []);
      setAnswer({ items });
    } else {
      const items = answer.items.concat({ matrixRowId: rowId, optionId });
      setAnswer({ items });
    }

  }, [setAnswer, answer?.items]);

  return (
    <SurveyFormCanSubmitContext.Provider value={canSubmit}>
      <SurveyFormBackContainer>
        <SurveyFormSubmitContainer>
          <ToggleMatrixValueContext.Provider value={toggleRowOption}>
            {props.children}
          </ToggleMatrixValueContext.Provider>
        </SurveyFormSubmitContainer>
      </SurveyFormBackContainer>
    </SurveyFormCanSubmitContext.Provider>
  );
};

export default MatrixRangeFormContainer;