import { useMemo } from 'react';
import { ProjectSubtype, utils as enumUtils } from '@enums';
import { DescriptorLike } from '$admin/components/Select';
import { FieldSelectNumber } from './Field.Select';
import styles from './style.css';

type Props = {
  classes?: {
    label?: string;
    value?: string;
  };
  editing: boolean;
  displayValue: string;
  onChange?: (value: ProjectSubtype) => void;
};

export function FieldProjectSubtype({ classes = {}, editing, displayValue, onChange }: Props) {
  const options = useMemo(() => {

    return enumUtils.ProjectSubtype.values().map<DescriptorLike>(value => ({
      id: value,
      name: enumUtils.ProjectSubtype.getName(value),
    })).sort((a, b) => a.name.localeCompare(b.name));
  }, []);

  return (
    <div className={styles.section}>
      <FieldSelectNumber
        classes={classes}
        editing={editing}
        label="Project Type"
        name="projectSubtype"
        displayValue={displayValue}
        onChange={onChange}
        options={options} />
    </div>
  );
}