export enum ChatActionType {
  FindQuotes = 'find-quotes',
  QueryFiles = 'query-files',
  QuerySurveyQuestion = 'query-survey-question',
  SummarizeFolder = 'summarize-folder',
}

export enum ChatAttachmentType {
  SuggestedActions = 'suggested-actions',
}

export enum ChatHintType {
  AnalysisTags = 'analysis-tags',
  FindQuotes = 'find-quotes',
  Text = 'text',
}