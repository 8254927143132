import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useToggle } from '@/utils';
import * as $api from '@api/projects';
import {
  ProjectSurveySettingsContext,
  SurveySettingsFormContext,
  SurveySettingsFormEditingContext,
  UpdateSurveySettingsFormMutationContext,
} from './context';
import type { SurveySettingsFormValues } from './interfaces';
import { useCanEditSurveySettings } from './hooks';

type Props = ChildrenProps;

export const SurveySettingsFormContainer = (props: Props) => {

  const [isEditing, toggleEditing] = useToggle(false);
  const canEdit = useCanEditSurveySettings();
  const ctx = useContext(ProjectSurveySettingsContext);

  const updateMutation = useMutation(['update-survey-settings'], (values: Omit<SurveySettingsFormValues, 'repeatSurveys'>) => {
    return $api.updateProjectSurveySettings({
      projectId: ctx.project.id,
      surveySettings: {
        anonymity: values.anonymity,
        externalSourcing: values.externalSourcing,
        fixedLinks: values.fixedLinks,
        offPlatform: values.offPlatform,
        frequency: values.frequency,
        charge: values.charge,
        payout: values.payout,
        length: values.length,
        // memberReferrals: values.memberReferrals,
      },
    });
  });

  const form = {
    anonymity: ctx.meta.anonymity,
    length: ctx.attributes.surveyLength,
    externalSourcing: ctx.attributes.externalSourcing,
    fixedLinks: ctx.attributes.externalSurveyFixedLinks,
    offPlatform: Boolean(ctx.attributes.externalSurveyConfiguration),
    frequency: ctx.meta.frequency,
    memberReferrals: ctx.attributes.memberReferrals,
    charge: ctx.meta.charge,
    payout: ctx.meta.payout,
    repeatSurveys: ctx.meta.repeatSurveys,
  };

  const editingCtx = {
    canEdit,
    editing: isEditing,
    toggleEditing,
  };

  return (
    <UpdateSurveySettingsFormMutationContext.Provider value={updateMutation}>
      <SurveySettingsFormEditingContext.Provider value={editingCtx}>
        <SurveySettingsFormContext.Provider value={form}>
          {props.children}
        </SurveySettingsFormContext.Provider>
      </SurveySettingsFormEditingContext.Provider>
    </UpdateSurveySettingsFormMutationContext.Provider>
  );
};