import { useCallback, useMemo } from 'react';
import { ButtonActivityIndicator, ActivityBlocker } from '@presentation';
import { useSaveCompletedSurveyResponse, useCanSubmitSurveyForm, useSurveyFormScreenContext } from '@containers/SurveyForm/Context';
import { useAlert } from '@/components/Modal/Alert';
import { useLeftBarContext, useFormPreviewContext } from './context';
import { useSubmitItem } from './hooks';
import styles from './style/Form.css';

export const FormControls = () => {
  const { callId } = useLeftBarContext();
  const saveCompletedResponse = useSaveCompletedSurveyResponse();
  const canSubmit = useCanSubmitSurveyForm();
  const { mutateAsync, isLoading } = useSubmitItem({
    callId,
    onSuccess: () => {
      saveCompletedResponse();
    },
  });

  const [state] = useSurveyFormScreenContext();
  const { preview } = useFormPreviewContext();

  const shouldWarnOnSubmit = useMemo(() => {
    return preview.items.some(i => !state.metadata.completedItemIds.includes(i.id) && state.item.id !== i.id);
  }, [preview.items, state.item.id, state.metadata.completedItemIds]);
  const [toggleAlert, Alert] = useAlert();

  const submitSurvey = useCallback(() => {
    mutateAsync({ itemId: null });
  }, [mutateAsync]);

  const onCompleteClick = useCallback(() => {
    if (shouldWarnOnSubmit) {
      toggleAlert();
    } else {
      submitSurvey();
    }
  }, [shouldWarnOnSubmit, toggleAlert, submitSurvey]);

  return (
    <>
      <div className={styles.controls}>
        <ButtonActivityIndicator
          implicitDisable={false}
          disabled={!canSubmit}
          onClick={onCompleteClick}
          loading={isLoading}
          variant='brick'
          color='secondary'>
          Complete Survey
        </ButtonActivityIndicator>
        <Alert
          message={SUBMIT_CONFIRM_MESSAGE}
          onConfirm={submitSurvey} />
      </div>
      {isLoading && <ActivityBlocker />}
    </>
  );
};

const SUBMIT_CONFIRM_MESSAGE = `You haven't completed all the items yet. Are you sure you want to submit the survey?`;