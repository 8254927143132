import { ProjectSummaryCitationsContainer } from './Project.Citations';
import { ProjectSummaryFeedbackFormContainer } from './Project.Feedback.Form';
import { ProjectSummaryFeedbackSummaryContainer } from './Project.Feedback.Summary';
import { ProjectSummaryRatingsContainer } from './Project.Ratings';
import { ProjectSummaryRefreshContainer } from './Project.Refresh';
import { ProjectSummaryVersionsContainer } from './Project.Versions';

type Props = ChildrenProps & IProjectId;

export const ProjectSummaryContainer = ({ children, projectId }: Props) => {

  return (
    <ProjectSummaryRefreshContainer projectId={projectId}>
      <ProjectSummaryFeedbackSummaryContainer projectId={projectId}>
        <ProjectSummaryCitationsContainer projectId={projectId}>
          <ProjectSummaryFeedbackFormContainer projectId={projectId}>
            <ProjectSummaryRatingsContainer projectId={projectId}>
              <ProjectSummaryVersionsContainer projectId={projectId}>
                {children}
              </ProjectSummaryVersionsContainer>
            </ProjectSummaryRatingsContainer>
          </ProjectSummaryFeedbackFormContainer>
        </ProjectSummaryCitationsContainer>
      </ProjectSummaryFeedbackSummaryContainer>
    </ProjectSummaryRefreshContainer>
  );
};