import { useMemo } from 'react';
import type { MutateOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { response as $response } from '@api/surveys';
import type { Surveys } from '@api/interfaces/surveys';
import { useSurveyResponseIdentifier, useSurveyFormVersionId, useSurveyFormScreenContext, useSurveyFormQuestionAnswer, useSurveyFormAnswerRationale, useSurveyFormApi } from '../Context';

type Variables = { itemId: number };
type Data = Surveys.Response.GoToItem.Response;
type Options = MutateOptions<Data, AxiosError, Variables>;

export const useGoToItem = (options?: Options) => {
  const surveyVersionId = useSurveyFormVersionId();
  const responseIdentifier = useSurveyResponseIdentifier();
  const [state, setState] = useSurveyFormScreenContext();
  const answerCtx = useSurveyFormQuestionAnswer();
  const rationaleCtx = useSurveyFormAnswerRationale();

  const goToItem = useGoBackApi();

  return useMutation(['survey-response-go-to-item', responseIdentifier], ({ itemId }: Variables) => {
    return goToItem({
      rationale: rationaleCtx ? rationaleCtx[0] : null,
      responseIdentifier,
      surveyVersionId,
      surveyVersionItemId: state.item.id,
      value: answerCtx ? answerCtx[0] : null,
      itemId,
    });
  }, {
    ...options,
    onSuccess: res => {
      setState(res.state);
    },
  });
};

function useGoBackApi() {
  //const handlers = useSurveyFormApi();

  return useMemo(() => $response.goToItem, []);
}