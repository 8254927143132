import { useCallback, useMemo, useReducer } from 'react';
import { Portal } from '@/components/Portal';
import { QueryDownloaderContext } from './Context';
import { QueryDownloaderItem } from './QueryDownloader.Item';
import type { ContextValue, InitOptions } from './interfaces';
import { hashQueryKey } from './utils';
import { asyncItemsReducer, initialDownloadsState } from './state.downloads';

type Props = {
  children: React.ReactNode;
};

export const QueryDownloaderContainer = (props: Props) => {
  const [state, dispatch] = useReducer(asyncItemsReducer, initialDownloadsState);

  const init = useCallback((params: InitOptions) => {
    dispatch({
      extension: params.extension,
      id: params.id,
      queryKey: params.queryKey,
      name: params.name,
      title: params.title,
      type: `download/init`,
    });
  }, []);

  const remove = useCallback((id: string) => {
    dispatch({
      id,
      type: `download/remove`,
    });
  }, []);

  const set: ContextValue['set'] = useCallback((id, params) => {
    dispatch({
      id,
      ...params,
      type: `download/set`,
    });
  }, []);

  const value = useMemo(() => ({
    hash: hashQueryKey,
    init,
    items: state.items,
    remove,
    set,
  }), [
    init,
    state.items,
    remove,
    set,
  ]);

  return (
    <QueryDownloaderContext.Provider value={value}>
      {props.children}
      {state.items && (
        <Portal>
          {state.items.map((x, i) =>
            <QueryDownloaderItem
              index={i}
              item={x}
              key={x.id} />)}
        </Portal>
      )}
    </QueryDownloaderContext.Provider>
  );
};

QueryDownloaderContainer.displayName = 'QueryDownloader.Container';
