import { useContext, useReducer } from 'react';
import { CompositeFormStateContext } from './Context';

type Props<T extends {}> = {
  children:     React.ReactNode;
  initialState: { [K in keyof T]: T[K]; };
};

const CompositeFormStateContainer = <T extends {}>(props: Props<T>) => {
  const [state, dispatch] = useReducer((acc: T, x: { [K in keyof T]: T[K]; }) => {
    return { ...acc, ...x };
  }, props.initialState);

  return (
    <CompositeFormStateContext.Provider value={[state, dispatch]}>
      {props.children}
    </CompositeFormStateContext.Provider>
  );
};

export const useCompositeFormState = <T extends {}>() => {
  const [state, dispatch] = useContext(CompositeFormStateContext);

  return [state as T, dispatch] as const;
};

export { CompositeFormStateContainer };
export default CompositeFormStateContainer;