import { Button } from '@/components/Button';
import img from 'static/icons/broken-link.svg?url';
import styles from './style/ExternalSurvey.css';

type Props = unknown;

export const ExternalSurveyError = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <img
            className={styles.img}
            src={img} />
          <div className={styles.header}>{copy.title}</div>
          <div className={styles.message}>{copy.message}</div>
          <div className={styles.footer}>
            <Button
              className={styles.btn}
              color="primary"
              onClick={() => window.location.reload()}
              variant="brick">
              Refresh Page
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

ExternalSurveyError.displayName = 'ExternalSurvey.Error';

const copy = {
  title: `Whoops! It looks like your survey link is not valid.`,
  message: `Please reach out to the project manager for this study to receive a new survey link or refresh the page when a new link has been assigned.`,
};