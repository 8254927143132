import { useMemo } from 'react';
import { ComputedSerie } from '@nivo/line';

const lineWidth = 3;
const activeLineWidth = 4;
const inactiveLineWidth = 3;

const opacity = 1;
const activeOpacity = 1;
const inactiveOpacity = 0.2;

type Options = {
  activeSeries: number[];
};

export const useSerieStyle = ({
  activeSeries,
}: Options) => {

  const normalStyle = useMemo(
    () => ({
      lineWidth,
      opacity,
    }),
    []
  );

  const activeStyle = useMemo(
    () => ({
      lineWidth: activeLineWidth,
      opacity: activeOpacity,
    }),
    []
  );

  const inactiveStyle = useMemo(
    () => ({
      lineWidth: inactiveLineWidth,
      opacity: inactiveOpacity,
    }),
    []
  );

  return useMemo(() => {

    return (serie: ComputedSerie) => {
      if (activeSeries.length === 0) return normalStyle;
      if (activeSeries.includes(+serie.id)) return activeStyle;
      return inactiveStyle;
    };
  }, [normalStyle, activeStyle, inactiveStyle, activeSeries]);
};