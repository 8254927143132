import cuid from 'cuid';
import {
  SurveyActionType,
  SurveyQuestionConditionType,
  SurveyOptionType,
  SurveyPipedValueType,
  SurveyConditionType,
} from '@enums/Survey';
import {
  MarketingCampaignEffectivenessQuestion,
  MarketingCampaignEffectivenessSection,
  SurveyTemplateType,
} from '@enums/survey.template';
import type { SurveyTemplate } from '@/types/survey.templates';
import type {
  MultipleChoiceQuestion,
  SurveyBuilderVersion,
  SurveyQuestionOption,
  SurveySection,
} from '@/types/survey';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import * as $question from '../utils/question';
import * as $shared from './template.shared-questions';
import * as $template from './utils';

export const defaultTemplateData: SurveyTemplate.MarketingCampaignEffectiveness = {
  category: {
    id: cuid(),
    value: '',
  },
  competitors: $template.generateInitialTextItems(10),
  target: {
    id: cuid(),
    value: '',
  },
};

// export const defaultTemplateData: SurveyTemplate.MarketingCampaignEffectiveness = {
//   category: {
//     id: cuid(),
//     value: 'Category',
//   },
//   competitors: $template.generateInitialTextItems(5, 'Competitor'),
//   target: {
//     id: cuid(),
//     value: 'Target',
//   },
// };

export function generateSurveyData(data: SurveyTemplate.MarketingCampaignEffectiveness): SurveyBuilderVersion {

  const targetingSection = $template.generateSection({
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: MarketingCampaignEffectivenessSection.Targeting },
    },
    hidden: false,
    name: 'Targeting/Demographics',
    ordinal: 1,
  });

  const solutionsSection = $template.generateSection({
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: MarketingCampaignEffectivenessSection.Solutions },
    },
    hidden: false,
    name: 'Solutions',
    ordinal: 2,
  });

  const inHouseSection = $template.generateSection({
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: MarketingCampaignEffectivenessSection.InHouseSolution },
    },
    hidden: true,
    name: 'In-House Solution',
    ordinal: 3,
  });

  const thirdPartySection = $template.generateSection({
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: MarketingCampaignEffectivenessSection.ThirdPartySolution },
    },
    hidden: true,
    name: 'Third Party Solution',
    ordinal: 4,
  });

  const noSolutionSection = $template.generateSection({
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: MarketingCampaignEffectivenessSection.NoSolution },
    },
    hidden: true,
    name: 'No Solution',
    ordinal: 5,
  });

  const anySolutionSection = $template.generateSection({
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: MarketingCampaignEffectivenessSection.AnySolution },
    },
    hidden: true,
    name: 'Any Solution',
    ordinal: 6,
  });

  const distributionSection = $template.generateSection({
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: MarketingCampaignEffectivenessSection.Distribution },
    },
    hidden: true,
    name: 'Distribution',
    ordinal: 7,
  });

  function categoryNode() {
    return $template.generateCategoryNode(data.category);
  }

  function OrgSizeQuestion(): MultipleChoiceQuestion.Question {
    return $shared.OrgSizeQuestion({ section: targetingSection });
  }

  function OrgIndustryQuestion(): MultipleChoiceQuestion.Question {
    return $shared.OrgIndustryQuestion({ section: targetingSection });
  }

  function OrgRoleQuestion() {
    return $shared.OrgRoleDQQuestion({ section: targetingSection });
  }

  function OrgDepartmentQuestion() {
    return $shared.OrgDepartmentQuestion({ section: targetingSection });
  }

  const {
    logic: solutionsLogic,
    question: solutionsQuestion,
  } = (function SolutionsQuestion() {
    const questionIdentifier = cuid();

    const inHouseOption = $template.generateMultipleChoiceOption({
      metadata: {
        canModifyValue: true,
        canDelete: true,
        isAnchored: false,
        isOpenEnded: false,
        template: {},
      },
      ordinal: 1,
      value: 'Yes, an in-house solution',
    });

    const thirdPartyOption = $template.generateMultipleChoiceOption({
      metadata: {
        canModifyValue: true,
        canDelete: true,
        isAnchored: false,
        isOpenEnded: false,
        template: {},
      },
      ordinal: 2,
      value: 'Yes, a third party solution',
    });

    const noSolutionOption = $template.generateMultipleChoiceOption({
      metadata: {
        canModifyValue: true,
        canDelete: true,
        isAnchored: false,
        isOpenEnded: false,
        template: {},
      },
      ordinal: 3,
      value: 'No',
    });

    function generateIncludeSectionLogic(section: SurveySection, option: SurveyQuestionOption) {
      return $template.generateLogicItem({
        action: {
          type: SurveyActionType.IncludeSection,
          section: {
            identifier: section.identifier,
          },
        },
        conditions: [$template.generateLogicItemQuestionCondition({
          data: {
            question: {
              identifier: questionIdentifier,
            },
            type: SurveyQuestionConditionType.OptionSelected,
            value: {
              option: {
                identifier: option.base.identifier,
              },
            },
          },
        })],
      });
    }

    const logic = [
      generateIncludeSectionLogic(inHouseSection, inHouseOption),
      generateIncludeSectionLogic(anySolutionSection, inHouseOption),
      generateIncludeSectionLogic(thirdPartySection, thirdPartyOption),
      generateIncludeSectionLogic(anySolutionSection, thirdPartyOption),
      generateIncludeSectionLogic(noSolutionSection, noSolutionOption),
    ];

    const question = $template.generateMultipleChoiceQuestion({
      identifier: questionIdentifier,
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: MarketingCampaignEffectivenessQuestion.Solutions,
        },
      },
      options: [
        inHouseOption,
        thirdPartyOption,
        noSolutionOption,
      ],
      section: {
        identifier: solutionsSection.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode('Is your organization currently using a '),
        categoryNode(),
        $template.generateTextNode(' solution?'),
      ]),
    });

    return { logic, question };
  })();

  function InHouseSolutionQuestion() {
    return $template.generateShortTextQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: MarketingCampaignEffectivenessQuestion.InHouseSolution,
        },
      },
      section: {
        identifier: inHouseSection.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`Please briefly describe the capabilities of your in-house `),
        categoryNode(),
        $template.generateTextNode(` solution.`),
      ]),
    });
  }

  function ThirdPartySolutionQuestion() {
    return $template.generateMultiselectQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: MarketingCampaignEffectivenessQuestion.ThirdPartySolution,
        },
      },
      options: [
        $template.generateTargetMultiselectOption({
          ordinal: 1,
          target: data.target.value,
        }),
        ...data.competitors.map((item, i) => $template.generateCompetitorMultiselectOption({
          item,
          ordinal: i + 2,
        })),
      ],
      section: {
        identifier: thirdPartySection.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`Which `),
        categoryNode(),
        $template.generateTextNode(` provider(s) is your organization currently using?`),
      ]),
    });
  }

  function NoSolutionQuestion() {
    return $template.generateShortTextQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: MarketingCampaignEffectivenessQuestion.NoSolution,
        },
      },
      section: {
        identifier: noSolutionSection.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`Are you looking to adopt a Are you looking to adopt a `),
        categoryNode(),
        $template.generateTextNode(` solution in the future? Why or why not?`),
      ]),
    });
  }

  function SolutionRatingQuestion() {
    return $template.generateSlidersQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: MarketingCampaignEffectivenessQuestion.SolutionRating,
        },
      },
      matrixRows: [$template.generateSlidersRow({
        metadata: {
          canDelete: false,
          canModifyValue: false,
        },
        ordinal: 1,
        value: 'Value',
      })],
      section: {
        identifier: anySolutionSection.identifier,
      },
      settings: {
        ...$question.matrixSlider.DefaultSettings,
        slider: {
          displayPctOfTotal: false,
          hideSlider: false,
          increment: 1,
          label: null,
          minValue: 0,
          maxValue: 10,
          ensureAnswerTotalEqualsMax: false,
          total: $question.matrixSlider.DefaultSettings.slider.total,
        },
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`On a scale of 1-10, how is your current `),
        categoryNode(),
        $template.generateTextNode(` solution(s) meeting your expectations? (1 = not remotely meeting expectations, 10 = meeting and exceeding expectations)`),
      ]),
    });
  }

  const solutionRatingQuestion = SolutionRatingQuestion();

  function SolutionRatingExplanationQuestion() {
    return $template.generateShortTextQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: MarketingCampaignEffectivenessQuestion.SolutionRatingExplanation,
        },
      },
      section: {
        identifier: anySolutionSection.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`You rated your current `),
        categoryNode(),
        $template.generateTextNode(` solution(s) as a `),
        $template.generatePipedQuestionResponseNode({
          pipedValue: {
            type: SurveyPipedValueType.SliderRowValue,
            row: {
              identifier: solutionRatingQuestion.matrixRows[0].base.identifier,
            },
          },
          questionIdentifier: solutionRatingQuestion.base.identifier,
        }),
        $template.generateTextNode(` out of 10. Please elaborate on your rating.`),
      ]),
    });
  }

  function ImprovementAreasQuestion() {
    return $template.generateLongTextQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: MarketingCampaignEffectivenessQuestion.ImprovementAreas,
        },
      },
      section: {
        identifier: anySolutionSection.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`What are some key areas in which your current `),
        categoryNode(),
        $template.generateTextNode(` solution(s) could improve?`),
      ]),
    });
  }

  function MainReasonQuestion() {
    return $template.generateLongTextQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: MarketingCampaignEffectivenessQuestion.MainReason,
        },
      },
      section: {
        identifier: anySolutionSection.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`For any vendor that were part of the selection process for, what was the reason you ultimately ended up choosing that vendor as your solution (your silver bullet reason), and what are reasons you ultimately passed on potential vendor candidates?`),
      ]),
    });
  }

  function ChannelsQuestion() {

    const options = [
      'Email',
      'Print publication',
      'TV',
      'Radio',
      'Social media',
      'Industry events / groups',
      'Internet groups (Facebook, LinkedIn, Reddit, etc.)',
      'Web advertisements',
      'User reviews (Google review, Facebook review, etc.)',
      'Marketing content / reports (Whitepapers, Ebooks, Case Studies, etc.)',
      'Mobile apps',
      'Brand influencers',
    ];

    return $template.generateMultiselectQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: MarketingCampaignEffectivenessQuestion.Channels,
        },
      },
      options: [
        ...options.map((value, i) => $template.generateMultiselectOption({
          ordinal: i + 1,
          value,
        })),
        $template.generateMultiselectOption({
          metadata: {
            isAnchored: true,
            isOpenEnded: true,
          },
          ordinal: options.length + 1,
          type: SurveyOptionType.Default,
          value: 'Other',
        }),
      ],
      section: {
        identifier: distributionSection.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`Which channels do you leverage to stay informed on trends and new products relating to `),
        categoryNode(),
        $template.generateTextNode(` ? (Select all that apply)`),
      ]),
    });
  }

  const channelsQuestion = ChannelsQuestion();

  function ChannelsUsageQuestion() {

    const options = [
      'Less usage',
      'Same level of usage',
      'Increased usage',
    ];

    return $template.generateMatrixGridQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: MarketingCampaignEffectivenessQuestion.Channels,
        },
      },
      matrixRows: channelsQuestion.options
        .filter(f => f.type === SurveyOptionType.Default)
        .map((option, i) => $template.generateMatrixGridRow({
          conditions: [{
            identifier: cuid(),
            conditionType: SurveyConditionType.Question,
            data: {
              question: { identifier: channelsQuestion.base.identifier },
              type: SurveyQuestionConditionType.OptionSelected,
              value: {
                option: { identifier: option.base.identifier },
              },
            },
          }],
          metadata: {
            canModifyValue: true,
            canDelete: true,
            template: {},
          },
          ordinal: i + 1,
          value: parseSurveyRichText(option.value),
        })),
      options: options.map((value, i) => $template.generateOption({
        metadata: {
          canModifyValue: true,
          canDelete: true,
          template: {},
        },
        ordinal: i + 1,
        value,
      })),
      section: {
        identifier: distributionSection.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`Over the past year, how has your usage of the following channels changed?`),
      ]),
    });
  }

  function ChannelsRankedQuestion() {

    return $template.generateRankingQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: MarketingCampaignEffectivenessQuestion.ChannelsRanked,
        },
      },
      options: channelsQuestion.options
        .filter(f => f.type === SurveyOptionType.Default)
        .map((option, i) => $template.generateRankingOption({
          metadata: {
            canModifyValue: true,
            canDelete: true,
            template: {},
          },
          ordinal: i + 1,
          value: parseSurveyRichText(option.value),
        })),
      section: {
        identifier: distributionSection.identifier,
      },
      settings: {
        ...$question.ranking.DefaultSettings,
        minRank: 3,
        maxRank: 3,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`Please rank the top 3 channels you would use to inform a purchasing decision for a new `),
        categoryNode(),
        $template.generateTextNode(` solution.`),
      ]),
    });
  }

  const questions = [
    OrgSizeQuestion(),
    OrgIndustryQuestion(),
    OrgRoleQuestion(),
    OrgDepartmentQuestion(),
    solutionsQuestion,
    InHouseSolutionQuestion(),
    ThirdPartySolutionQuestion(),
    NoSolutionQuestion(),
    solutionRatingQuestion,
    SolutionRatingExplanationQuestion(),
    ImprovementAreasQuestion(),
    MainReasonQuestion(),
    channelsQuestion,
    ChannelsUsageQuestion(),
    ChannelsRankedQuestion(),
  ];

  return {
    alternateImageExercises: [],
    classifications: [],
    createdOn: null,
    id: null,
    items: $template.generateItemsForQuestions(questions),
    language: $template.generateLanguage(),
    logic: [
      ...solutionsLogic,
    ],
    messages: [],
    questions,
    quotas: [],
    sections: [
      targetingSection,
      solutionsSection,
      inHouseSection,
      thirdPartySection,
      noSolutionSection,
      anySolutionSection,
      distributionSection,
    ],
    surveyId: null,
    surveyDetails: null,
    tagging: [],
    template: {
      data,
      type: SurveyTemplateType.MarketingCampaignEffectiveness,
    },
  };
}