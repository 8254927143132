import extend from '@enums/extend';

export enum ProjectGoalType {
  Call   = 1,
  Survey = 2,
}

const goal = new Map([
  [ProjectGoalType.Call,   { name: 'Call' }],
  [ProjectGoalType.Survey, { name: 'Survey' }],
]);

export enum ProjectStatus {
  Inactive = 1,
  Active   = 2,
  Archived = 3,
  Draft    = 4,
}

const status = new Map([
  [ProjectStatus.Inactive, { name: 'Inactive' }],
  [ProjectStatus.Active,   { name: 'Active' }],
  [ProjectStatus.Archived, { name: 'Archived' }],
  [ProjectStatus.Draft,    { name: 'Draft' }],
]);

export enum ProjectType {
  Survey      = 1,
  Call        = 2,
  SurveyCall  = 3,
}

const type = new Map([
  [ProjectType.Call, { name: 'Interviews' }],
  [ProjectType.Survey, { name: 'Online Survey' }],
  [ProjectType.SurveyCall, { name: 'Screener + Interviews' }],
]);

export enum ProjectFrequency {
  None = 1,
  Weekly = 2,
  Monthly = 3,
  Quarterly = 4,
  AdHoc = 5,
}

const frequency = new Map([
  [ProjectFrequency.None, { name: 'None' }],
  [ProjectFrequency.Weekly, { name: 'Weekly' }],
  [ProjectFrequency.Monthly, { name: 'Monthly' }],
  [ProjectFrequency.Quarterly, { name: 'Quarterly' }],
  [ProjectFrequency.AdHoc, { name: 'Ad Hoc' }],
]);

export enum InternalGroup {
  PMR = 1,
  EVAP = 2,
  SA = 3,
  TGAS = 4,
  Other = 5,
}

const internalGroup = new Map([
  [InternalGroup.PMR, { name: 'PMR' }],
  [InternalGroup.EVAP, { name: 'EVAP' }],
  [InternalGroup.SA, { name: 'SA' }],
  [InternalGroup.TGAS, { name: 'TGAS' }],
  [InternalGroup.Other, { name: 'Other' }],
]);

export enum ProjectSubtype {
  OpportunityAssessment = 1,
  ConceptTesting = 2,
  MessageTesting = 3,
  PositioningTesting = 4,
  VisualAidTesting = 5,
  PatientJourney = 6,
  /* JourneyTesting = 7, */
  Other = 8,
  Segmentation = 9,
  DriversAndBarriers = 10,
  DemandAssessment = 11,
  CurrentLandscape = 12,
  /* AdverseEventsManagement = 13, */
}

const projectSubtype = new Map([
  [ProjectSubtype.OpportunityAssessment, { name: 'Opportunity Assessment' }],
  [ProjectSubtype.ConceptTesting, { name: 'Concept Testing' }],
  [ProjectSubtype.MessageTesting, { name: 'Message Testing' }],
  [ProjectSubtype.PositioningTesting, { name: 'Positioning Testing' }],
  [ProjectSubtype.VisualAidTesting, { name: 'Visual Aid Testing' }],
  [ProjectSubtype.PatientJourney, { name: 'Patient Journey' }],
  /* [ProjectSubtype.JourneyTesting, { name: 'Journey Testing' }], */
  [ProjectSubtype.Other, { name: 'Other' }],
  [ProjectSubtype.Segmentation, { name: 'Segmentation' }],
  [ProjectSubtype.DriversAndBarriers, { name: 'Drivers and Barriers' }],
  [ProjectSubtype.DemandAssessment, { name: 'Demand Assessment' }],
  [ProjectSubtype.CurrentLandscape, { name: 'Current Landscape' }],
  /* [ProjectSubtype.AdverseEventsManagement, { name: 'Adverse Events Management' }], */
]);

export * from './project.action';

export default {
  InternalGroup: extend(internalGroup),
  ProjectFrequency: extend(frequency),
  ProjectGoalType: extend(goal),
  ProjectStatus: extend(status),
  ProjectSubtype: extend(projectSubtype),
  ProjectType: extend(type),
};