import { useCallback, useMemo } from 'react';
import { BarDatum, BarMouseEventHandler, BarTooltipDatum, ComputedDatum, ResponsiveBar } from '@nivo/bar';
import { OrdinalColorScaleConfigCustomFunction } from '@nivo/colors';
import * as chart from '@containers/SurveyResponses/utils';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import { MaxDiffChartTooltip } from '@presentation/ProjectSurveyResponses';
import { MaxDifferenceQuestion } from '@/types';
import { trunc } from '@utils';
import { theme } from './Chart.Common';
import ChartWrapper from './ChartWrapper';
import { useChartAnimation } from './hooks';

export default function MaxDiffChart({ options, responses, onClick }: Props) {
  const items = useMemo(() =>
    options.map<MaxDiffBarDatum>(option => {
      const data = responses.options[option.base.id];
      return {
        most: data.right,
        least: data.left,
        nc: data.appeared - data.left - data.right,
        optionId: option.base.id,
        name: parseSurveyRichText(option.value),
      };
    }).reverse(), [options, responses.options]);

  const padding = useMemo(() => chart.calculateChartPadding(items.length), [items.length]);

  const handleMouseEnter: BarMouseEventHandler<SVGRectElement> = useCallback((_, e) => {
    e.currentTarget.style.cursor = 'pointer';
  }, []);

  const handleClick: BarMouseEventHandler<SVGRectElement> = useCallback(item => {
    const data = item.data as MaxDiffBarDatum;
    const choice = item.id as MaxDifferenceQuestion.Choices;
    onClick(data.optionId, choice);
  }, [onClick]);

  const formatLeftAxisLabel = useCallback((optionId: string) => {
    const option = options.find(o => o.base.id === +optionId);
    return trunc(parseSurveyRichText(option.value), 10);
  }, [options]);

  const formatBottomAxisLabel = useCallback((value: string) => value, []);

  const renderTooltip = useCallback((item: BarTooltipDatum) => {
    const choice = item.id as MaxDifferenceQuestion.Choices;
    const data = item.data as MaxDiffBarDatum;
    const appeared = data.least + data.most + data.nc;
    return (
      <MaxDiffChartTooltip
        appeared={appeared || 0}
        choice={choice}
        name={data.name}
        value={item.value} />
    );
  }, []);

  const colors: OrdinalColorScaleConfigCustomFunction<ComputedDatum<BarDatum>> = useCallback((item: ComputedDatum<BarDatum>) => {
    const choice = item.id as MaxDifferenceQuestion.Choices;
    return chart.maxdiff.getChoiceColor(choice);
  }, []);

  const maxValue =
    useMemo(() => Math.max(...options.map(o => responses.options[o.base.id].appeared)), [
      options, responses.options,
    ]);

  const bottomTickValues = useMemo(() => [0, maxValue], [maxValue]);
  const animate = useChartAnimation();

  return (
    <ChartWrapper style={{ height: chart.calculateChartHeight(items.length) }}>
      <ResponsiveBar
        data={items}
        keys={keys}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          format: formatBottomAxisLabel,
          tickValues: bottomTickValues,
        }}
        axisLeft={{
          format: formatLeftAxisLabel,
        }}
        animate={animate}
        colors={colors}
        enableLabel={false}
        enableGridX={true}
        enableGridY={false}
        indexBy="optionId"
        layout="horizontal"
        margin={{ top: 0, right: 25, bottom: 30, left: 75 }}
        minValue={0}
        maxValue={maxValue}
        onClick={handleClick}
        padding={padding}
        theme={theme}
        onMouseEnter={handleMouseEnter}
        tooltip={renderTooltip} />
    </ChartWrapper>
  );
}

const keys: MaxDifferenceQuestion.Choices[] = ['most', 'least', 'nc'];

type Props = {
  options: MaxDifferenceQuestion.Question['options'];
  responses: MaxDifferenceQuestion.Aggregate.Data;
  onClick: (optionId: number, choice: MaxDifferenceQuestion.Choices) => unknown;
};

type MaxDiffBarDatum = {
  most: number;
  least: number;
  nc: number;
  optionId: number;
  name: string;
};