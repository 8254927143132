import { useCallback, useMemo } from 'react';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { useTranscriptTagsOverview } from '@utils/api';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import { useModal } from '@/components/Modal/hooks';
import { TranscriptTagsSelection } from './TagSelection';
import { useTagsToSummarize } from './context';
import styles from './style/ConferenceTagsOverview.css';

type Props =
  {
    onDownload?: (tagIds: number[]) => unknown;
  } & BaseProps;

type BaseProps =
  & ITranscriptId
  & Pick<ModalProps,
    'open' |
    'onClose'>;

export const TranscriptTagsOverviewModal = ({ onClose, onDownload, open, transcriptId }: Props) => {

  const [tagsToSummarize, setTagsToSummarize] = useTagsToSummarize();

  const canDownload = useMemo(() => {
    return tagsToSummarize.length > 0;
  }, [tagsToSummarize.length]);

  const { data, isLoading } = useTranscriptTagsOverview({ transcriptId }, {
    onSuccess: res => {
      setTagsToSummarize(res.tags.map(tag => tag.base.id));
    },
    refetchOnWindowFocus: false,
  });

  const items = useMemo(() => {
    if (!data) return [];

    return data.tags.map(tag => {

      return {
        tag,
        instances: data.taggedMomentsMap[tag.base.id],
      };
    });
  }, [data]);

  const downloadSummary = useCallback(() => {
    onDownload?.(tagsToSummarize);
    onClose();
  }, [onClose, onDownload, tagsToSummarize]);

  return (
    <Modal
      classes={{
        root: styles.modal,
      }}
      hideCloseIcon
      open={open}
      onClose={onClose}>

      {!isLoading && (
        <div>
          <TranscriptTagsSelection
            items={items} />
          <div className={styles.footer}>
            <ButtonActivityIndicator
              className={styles.button}
              disabled={!canDownload}
              loading={false}
              implicitDisable={false}
              onClick={downloadSummary}>
              Download
            </ButtonActivityIndicator>
          </div>
        </div>
      )}
    </Modal>
  );
};

export const useTranscriptTagsOverviewModal = () => useModal(TranscriptTagsOverviewModal);