import { useCallback, useMemo, useState } from 'react';
import { utils as $enumUtils } from '@enums';
import { GrayOutlineButton } from '@presentation/Buttons';
import { useSubmitSurveyDraft, useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { SurveyItem } from '@/types';
import { toTitleCase } from '@utils';
import { SurveyItemsDropdown } from '@presentation/SurveyBuilder';
import { Button } from '@/components/Button';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import ModalHeader from '@/components/Modal/Header';
import { useModal } from '@/components/Modal/hooks';
import { DropDown } from '@/components/DropDown';
import styles from './style/Modal.MoveItem.css';

type Props ={
  item: SurveyItem;
} &
  Pick<ModalProps,
    'open' |
    'onClose'>;

export const MoveSurveyItemModal = ({
  item,
  onClose,
  open,
}: Props) => {

  const [position, setPosition] = useState<Position>('before');
  const [selectedItemIdentifier, setItemIdentifier] = useState<string>(null);

  const submitDraft = useSubmitSurveyDraft();
  const [state, dispatch] = useSurveyBuilderState();

  const selectedItem = useMemo(() => {
    return state.survey.items.find(f => f.identifier === selectedItemIdentifier);
  }, [state.survey.items, selectedItemIdentifier]);

  const handleSubmit = useCallback(() => {
    dispatch({
      type: 'item-moved',
      payload: {
        identifier: item.identifier,
        to: {
          position,
          ref: selectedItemIdentifier,
        },
      },
    });
    submitDraft();
    onClose();
  }, [
    dispatch,
    item.identifier,
    onClose,
    position,
    selectedItemIdentifier,
    submitDraft,
  ]);

  const canSubmit = useMemo(() => {
    return !!position &&
      !!selectedItemIdentifier;
  }, [
    position,
    selectedItemIdentifier,
  ]);

  const handlePlacementSelect = useCallback(({ id }: DropdownItem) => {
    setPosition(id);
  }, []);

  const itemType = $enumUtils.SurveyItemtype.getName(item.type);

  const title = `Move ${itemType}`;

  return (
    <Modal
      open={open}
      onClose={onClose}>

      <ModalHeader text={title} />

      <div className={styles.body}>
        <div className={styles.dropdowns}>

          <div className={styles.positionDropdown}>
            <DropDown<DropdownItem>
              items={[{ id: 'before', value: 'Before' }, { id: 'after', value: 'After' }]}
              getItemValue={item => item.value}
              onSelect={handlePlacementSelect}
              text={toTitleCase(position)} />
          </div>

          <div className={styles.itemDropdown}>
            <SurveyItemsDropdown
              items={state.survey.items}
              onSelect={setItemIdentifier}
              value={selectedItem} />
          </div>
        </div>
        <div className={styles.note}>Note: {itemType} will be placed in the same section as selected item.</div>
      </div>

      <div className={styles.footer}>
        <GrayOutlineButton
          onClick={onClose}
          title="Cancel" />
        <Button.Primary
          className={styles.submit}
          disabled={!canSubmit}
          onClick={handleSubmit}
          title="Submit"
          variant="brick" />
      </div>
    </Modal>
  );
};

type Position = 'before' | 'after';

type DropdownItem = {
  id: Position;
  value: string;
};

export const useMoveSurveyItemModal = () => useModal(MoveSurveyItemModal);

export default MoveSurveyItemModal;