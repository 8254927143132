import { useMemo } from 'react';
import Select, { ActionMeta, OnChangeValue } from 'react-select';
import * as enums from '@/enums';
import { createSelectStyles, createSelectTheme } from './select-styles';

type Props = {
  autoFocus?: boolean;
  className?: string;
  isInvalid?: boolean;
  onChange:  (newValue: OnChangeValue<Item, false>, actionMeta: ActionMeta<Item>) => unknown;
  value:     enums.PayerAdminType;
};

export const USAPayerAdmin = (props: Props) => {
  const roles = useMemo(() => {
    return [
      enums.PayerAdminType.ClinicalPathwayRepresentative,
      enums.PayerAdminType.EmployeeBenefitConsultant,
      enums.PayerAdminType.GPORepresentative,
      enums.PayerAdminType.HealthPlanClinicalPharmacist,
      enums.PayerAdminType.HealthPlanFinance,
      enums.PayerAdminType.HealthPlanMedicalDirector,
      enums.PayerAdminType.HealthPlanPharmacyDirector,
      enums.PayerAdminType.HospitalClinicalPharmacist,
      enums.PayerAdminType.HospitalFinance,
      enums.PayerAdminType.HospitalMedicalDirector,
      enums.PayerAdminType.HospitalPharmacyDirector,
      enums.PayerAdminType.IDNPayerMedicalDirector,
      enums.PayerAdminType.IDNPayerPharmacyDirector,
      enums.PayerAdminType.IDNProviderMedicalDirector,
      enums.PayerAdminType.MedicaidState,
      enums.PayerAdminType.MedicareMACCAC,
      enums.PayerAdminType.PBMRepresentative,
      enums.PayerAdminType.PatientAdvocacy,
      enums.PayerAdminType.PracticeManager,
      enums.PayerAdminType.SPPRepresentative,
      enums.PayerAdminType.Other,
    ].map(i => ({
      value: i,
      label: enums.utils.PayerAdminType.getName(i),
    }));
  }, []);

  const selectStyles = useMemo(() => createSelectStyles<Item>({ isInvalid: props.isInvalid }), [props.isInvalid]);
  const selectTheme = useMemo(() => createSelectTheme(), []);

  return (
    <Select
      autoFocus={props.autoFocus}
      className={props.className}
      defaultValue={roles.find(i => i.value === props.value)}
      value={roles.find(i => i.value === props.value)}
      options={roles}
      getOptionLabel={o => o.label}
      getOptionValue={o => `${+o.value}`}
      noOptionsMessage={() => `Payer role not found`}
      placeholder="Payer Role"
      onChange={props.onChange}
      styles={selectStyles}
      theme={selectTheme} />
  );
};

USAPayerAdmin.displayName = 'MedicalProfile.Field.USAPayerAdmin';

type Item = {
  label: string;
  value: enums.PayerAdminType;
};