/** @jsxImportSource @emotion/react */
import { useCallback, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import FormGroup from '@mui/material/FormGroup';
import { ChatStateDispatchContext } from '@/brand-insights/components/Chat.State';
import { CancelAction, MessageQueryHint } from '@/brand-insights/components/presentation';
import { ChatHintType } from '@/brand-insights/enums';
import type { Chat } from '@/brand-insights/types';
import { ActiveChatSessionContext } from './context';
import { SegmentationTagSelectionTag } from './Item.Segmentation.Tag';
import { UserMessage } from './Message.User';
import { BaseSystemUserMessage } from './Message.System';
import { useSegmentationTagSelectionMutation, useSessionSegmentation, useCancelSegmentationMutation, useScrollToBottomOfMessages } from './hooks';

type Props = {
  identifier: Chat.Segmentation.Segmentation['identifier'];
  data: Chat.Segmentation.TagSelection.ScreenData;
  readonly: boolean;
};

export const SegmentationTagSelection = (props: Props) => {
  return (
    <>
      <MessageContainer>
        <UserMessage value={copy.user} />
      </MessageContainer>
      <MessageContainer>
        <BaseSystemUserMessage extendedWidth>
          <SegmentationTagSelectionSystemMessage {...props} />
        </BaseSystemUserMessage>
      </MessageContainer>
    </>
  );
};

export const SegmentationTagSelectionSystemMessage = (props: Props) => {
  const chat = useContext(ActiveChatSessionContext);
  const segmentation = useSessionSegmentation({ segmentationIdentifier: props.identifier });
  const tagSelection = useSegmentationTagSelectionMutation();
  const cancel = useCancelSegmentationMutation();
  const dispatch = useContext(ChatStateDispatchContext);
  const scrollToBottom = useScrollToBottomOfMessages();

  const handleOnChange = useCallback((id: number, checked: boolean) => {
    const tags = props.data.tags.map(tag => {
      if (tag.tag.id !== id) return tag;
      return {
        ...tag,
        selected: checked,
      };
    });

    dispatch({
      type: 'segmentation/screen/updated',
      payload: {
        identifier: props.identifier,
        screen: {
          type: 'tag-selection',
          item: {
            ...props.data,
            tags,
          },
        },
      },
    });
  }, [dispatch, props.identifier, props.data]);

  const handleContinue = useCallback(() => {
    tagSelection.mutateAsync({
      chatInstanceIdentifier: chat.identifier,
      context: chat.context,
      segmentation,
    }).then(() => scrollToBottom());
  }, [
    tagSelection,
    chat.identifier,
    chat.context,
    segmentation,
    scrollToBottom,
  ]);

  const handleCancel = useCallback(() => {
    cancel.mutateAsync({
      chatInstanceIdentifier: chat.identifier,
      context: chat.context,
      segmentation,
    })
    .then(() => scrollToBottom());
  }, [
    cancel,
    chat.identifier,
    chat.context,
    segmentation,
    scrollToBottom,
  ]);

  const canContinue = useMemo(() => !cancel.isLoading && !tagSelection.isLoading && props.data.tags.filter(t => t.selected).length > 1, [
    cancel.isLoading,
    tagSelection.isLoading,
    props.data.tags,
  ]);
  const sortedTags = useMemo(() => props.data.tags.sort((a, b) => b.occurrences - a.occurrences), [props.data.tags]);

  const view = {
    actions: !props.readonly,
    empty: !sortedTags.length,
  };

  if (view.empty) {
    return (
      <Root>
        <HeaderCopyEmpty>
          {copy.system.headerEmpty}
        </HeaderCopyEmpty>
        {view.actions && (
          <Actions>
            <CancelAction
              displayValue={copy.cancelEmpty}
              onClick={handleCancel} />
          </Actions>
        )}
      </Root>
    );
  }

  return (
    <Root>
      <HeaderCopy>
        {copy.system.header}
      </HeaderCopy>
      <PickCopy>
        {copy.system.pick}
      </PickCopy>
      <Tags>
        <FormGroup>
          {sortedTags.map(({ selected, tag, occurrences }) => (
            <SegmentationTagSelectionTag
              key={tag.id}
              id={tag.id}
              label={tag.name}
              checked={selected}
              occurrences={occurrences}
              onChange={handleOnChange}
              readonly={props.readonly} />
          ))}
        </FormGroup>
      </Tags>
      {view.actions && (
        <Actions>
          <MessageQueryHint
            disabled={!canContinue}
            hint={{ type: ChatHintType.AnalysisTags, displayValue: copy.confirm }}
            onClick={handleContinue} />
          <CancelAction
            displayValue={copy.cancel}
            onClick={handleCancel} />
        </Actions>
      )}
    </Root>
  );
};

export const copy = {
  user: `I'd like to conduct an analysis.`,
  system: {
    header: `Ok, great! Let's get started. What tags would you like to analyze?`,
    headerEmpty: `I couldn't find enough tags for this record, you must tag records before you can run an analysis.`,
    pick: `Pick up to three tags.`,
  },
  confirm: `Let's analyze these tags.`,
  cancel: `Actually, I'd like to do something else.`,
  cancelEmpty: `I'll go tag some records.`,
};

const MessageContainer = styled.div`
  padding: 15px 20px;
`;

const Root = styled.div`
  box-sizing: border-box;
`;

const HeaderCopy = styled.div`
  box-sizing: border-box;
  border-bottom: 1px solid ${props => props.theme.palette.gray.light1};
  padding-bottom: 10px;
  margin-bottom: 10px;
`;

const HeaderCopyEmpty = styled(HeaderCopy)`
  box-sizing: border-box;
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
`;

const PickCopy = styled.div`
  font-size: 15px;
  font-family: ${props => props.theme.fonts.semiBold};
  margin-bottom: 10px;
`;

const Tags = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const Actions = styled.div`
  margin-top: 10px;

  > :not(:last-child) {
    margin-bottom: 10px;
  }
`;