import { useCallback, useContext, useEffect, useState } from 'react';
import { useUpdateReason } from '@remirror/react';
import { ArticleFormContext } from '@containers/Post.Article.Form/Context';
import { useGetText } from './useGetText';

export const useCanPublishArticle = () => {
  const updatereason = useUpdateReason();
  const { title } = useContext(ArticleFormContext);
  const getText = useGetText();
  const [enabled, setEnabled] = useState(false);

  const validate = useCallback(() => {
    const hasContent = getText().trim().length;
    const hasTitle = title.trim().length;

    const enabled = !!hasContent && !!hasTitle;

    setEnabled(enabled);
  }, [
    getText,
    title,
  ]);

  useEffect(() => {

    return validate();

  }, [
    updatereason,
    validate,
  ]);

  return enabled;
};