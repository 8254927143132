import { useCallback, useContext, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu, { menuClasses } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { CSVFieldMappingContext } from '@containers/Group.Contacts.Import/Context';
import { CSV } from '@containers/Group.Contacts.Import/interfaces';
import { cx } from '@utils';
import styles from './style/CSVFieldMapping.Select.css';

type Props = {
  column: string;
};

export const CSVFieldMappingSelect = (props: Props) => {
  const ctx = useContext(CSVFieldMappingContext);
  const [anchor, setAnchor] = useState<HTMLElement>(null);

  const handleClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setAnchor(e.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchor(null);
  }, []);

  const value = useMemo(() => {
    return ctx.getMappedValue(props.column);
  }, [
    ctx,
    props.column,
  ]);

  const handleSelect = useCallback((id: CSV.MappedColumnKey) => {
    ctx.setValue(id, props.column);
    handleClose();
  }, [
    ctx,
    props.column,
    handleClose,
  ]);

  return (
    <>
      <div
        className={cx(styles.btn)}
        onClick={handleClick}>
        <div className={styles.label}>
          {OptionsMap[value]}
        </div>
        <KeyboardArrowDownIcon sx={{
          height: '25px',
          width: '25px',
          color: 'var(--trolley)',
        }} />
      </div>
      <StyledMenu
        disableAutoFocusItem
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchor}
        open={!!anchor}
        onClose={handleClose}
        transitionDuration={{
          appear: 0,
          enter: 0,
          exit: 0,
        }}>
        {options.map(id =>
          <Option
            id={id}
            key={id}
            onClick={() => handleSelect(id)}>
            {OptionsMap[id]}
          </Option>)}
      </StyledMenu>
    </>
  );
};

const StyledMenu = styled(Menu)({
  [`& .${menuClasses.paper}`]: {
    borderRadius: '4px',
    transition: 'duration 0 !important',
    fontFamily: 'var(--font-reg)',
  },
  [`& .${menuClasses.list}`]: {
    padding: '0',
    fontFamily: 'var(--font-reg)',
  },
});

const OptionsMap = {
  email: 'Email',
  firstName: 'First Name',
  lastName: 'Last Name',
  title: 'Title',
  company: 'Company',
  phone: 'Phone',
  none: 'None',
};

const options = Object.keys(OptionsMap) as CSV.MappedColumnKey[];

type OptionProps = {
  id:       CSV.MappedColumnKey;
  children: React.ReactNode;
  onClick: () => unknown;
};

const Option = (props: OptionProps) => {
  return (
    <MenuItem
      classes={{
        root: styles.input,
        gutters: styles.gutters,
      }}
      disabled={false}
      disableRipple
      onClick={props.onClick}>
      {props.children}
    </MenuItem>
  );
};

CSVFieldMappingSelect.displayName = 'CSVFieldMappingSelect';