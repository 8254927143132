import type { ChangeEvent } from 'react';
import { useCallback, useMemo, useState } from 'react';
import cuid from 'cuid';
import { ClassificationDropdown } from '@presentation/SurveyBuilder';
import { useSurveyBuilderState, useSurveyClassifications } from '@/containers/SurveyBuilder';
import { GrayOutlineButton } from '@/presentation';
import { useSurveyTaggingItemState } from '@/containers/SurveyBuilder.Tagging';
import { SurveyTaggingResultType } from '@/enums';
import { Button } from '@/components/Button';
import { Input } from '@/components/Input';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import ModalHeader from '@/components/Modal/Header';
import { useModal } from '@/components/Modal/hooks';
import styles from './style/ClassificationModal.css';

type Props =
  Pick<ModalProps,
    'open' |
    'onClose'>;

export const TaggingClassificationModal = ({ open, onClose }: Props) => {

  const [item, dispatch] = useSurveyTaggingItemState();

  const [isNewClassification, setIsNew] = useState<boolean>(false);
  const [selectedClassification, setSelectedClassification] = useState<string>(item.action.value.identifier);

  const classifications = useSurveyClassifications();

  const canSubmit = useMemo(() => {
    return !!selectedClassification;
  }, [selectedClassification]);

  const handleSubmit = useCallback(() => {
    dispatch({
      type: 'action-updated',
      payload: {
        type: SurveyTaggingResultType.Classification,
        value: {
          identifier: selectedClassification,
        },
      },
    });
    onClose();
  }, [dispatch, onClose, selectedClassification]);

  const renderBody = useCallback(() => {

    if (!classifications.length || isNewClassification) {
      return <NewClassificationInput onClose={onClose} />;
    }

    return (
      <>
        <div className={styles.existing}>
          <div className={styles.label}>Choose existing classification:</div>
          <div className={styles.dropdown}>
            <ClassificationDropdown
              onSelect={setSelectedClassification}
              value={selectedClassification} />
          </div>
        </div>
        <div className={styles.row}>
          or&nbsp;<div className={styles.link} onClick={() => setIsNew(true)}>Enter new classification</div>
        </div>
        <Footer
          canSubmit={canSubmit}
          onSubmit={handleSubmit}
          onCancel={onClose} />
      </>
    );
  }, [
    canSubmit,
    classifications.length,
    handleSubmit,
    isNewClassification,
    onClose,
    selectedClassification,
  ]);

  return (
    <Modal
      open={open}
      onClose={onClose}>

      <ModalHeader text="Classification Selection" />

      <div className={styles.body}>
        {renderBody()}
      </div>

    </Modal>
  );
};

type NewProps = {
  onClose: () => void;
};

const NewClassificationInput = ({ onClose }: NewProps) => {

  const [value, setValue] = useState('');

  const [_, taggingDispatch] = useSurveyTaggingItemState();
  const [__, dispatch] = useSurveyBuilderState();
  const classifications = useSurveyClassifications();

  const canSubmit = useMemo(() => {
    return !!value.length;
  }, [value]);

  const handleSubmit = useCallback(() => {
    const existingClassification = classifications.find(f => f.name.toLocaleLowerCase() === value.toLocaleLowerCase());

    const classification = existingClassification || {
      id: null,
      identifier: cuid(),
      name: value,
      meta: {},
    };

    if (!existingClassification) {
      dispatch({
        type: 'classification-added',
        payload: {
          value: classification,
        },
      });
    }

    taggingDispatch({
      type: 'action-updated',
      payload: {
        type: SurveyTaggingResultType.Classification,
        value: {
          identifier: classification.identifier,
        },
      },
    });

    onClose();
  }, [
    classifications,
    dispatch,
    value,
    taggingDispatch,
    onClose,
  ]);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);

  return (
    <>
      <div className={styles.input}>
        <Input
          placeholder="Enter classification"
          onChange={handleChange}
          value={value} />
      </div>
      <Footer
        canSubmit={canSubmit}
        onSubmit={handleSubmit}
        onCancel={onClose} />
    </>
  );
};

type FooterProps = {
  canSubmit: boolean;
  onCancel: () => void;
  onSubmit: () => void;
};

const Footer = ({ canSubmit, onCancel, onSubmit }: FooterProps) => {

  return (
    <div className={styles.footer}>
      <GrayOutlineButton
        onClick={onCancel}>
        Cancel
      </GrayOutlineButton>
      <Button.Primary
        variant="brick"
        className={styles.submit}
        disabled={!canSubmit}
        onClick={onSubmit}>
        Submit
      </Button.Primary>
    </div>
  );
};

export const useClassificationModal = () => useModal(TaggingClassificationModal);