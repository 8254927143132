const ONE_HUNDRED = 100;

export function calculateNextStep(data: {
  dragHandlePosition: number;
  incrementByPct: number;
  leftBound: number;
  width: number;
}) {
  //Don't exceed right or left bound
  const adjustedPosition = Math.max(
    Math.min(
      data.dragHandlePosition,
      data.leftBound + data.width,
    ),
    data.leftBound,
  );

  const pctPosition = ((adjustedPosition - data.leftBound) / data.width) * ONE_HUNDRED;

  return Math.round(pctPosition / data.incrementByPct);
}

export function convertToPct(data: {
  maxValue: number;
  minValue: number;
  value: number;
}) {
  return ((data.value - data.minValue) / (data.maxValue - data.minValue)) * 100;
}

export function convertFromPct(data: {
  maxValue: number;
  minValue: number;
  pct: number;
}) {
  return data.minValue + ((data.pct / 100) * (data.maxValue - data.minValue));
}