import { memo } from 'react';
import { generatePath } from 'react-router-dom';
import LinkIcon from '@mui/icons-material/Link';
import PinIcon from '@mui/icons-material/FiberPin';
import * as consts from '@consts';
import { cx, pin } from '@utils';
import styles from './style/ConferenceLink.css';

type Props = {
  conferenceIdentifier: number;
  pin?: string;
  className?: string;
};

export const ConferenceLink = memo((props: Props) => {
  const url = `${process.env.FRONT_BASE_URL}${generatePath(consts.path.Conference.Entry, { conferenceIdentifier: props.conferenceIdentifier })}${props.pin ? `?pin=${props.pin}` : ''}`;

  const displayPin = pin.format(props.pin);

  return (
    <div className={cx(props.className)}>
      <div className={styles.header}>
        Conference Link
      </div>
      <div className={styles.url}>
        <LinkIcon />
        <div className={styles.details}>
          {url}
        </div>
      </div>
      {props.pin && (
        <div className={styles.pin}>
          <PinIcon />
          <div className={styles.details}>
            {displayPin}
          </div>
        </div>
      )}
    </div>
  );
});