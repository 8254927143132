import { Header } from '../Header';
import { useDeviceName } from './hooks';

export const SystemDenied = () => {
  const device = useDeviceName();
  return (
    <>
      <Header>{`Can't use your ${device}`}</Header>
      <div>Your browser might not have access to your {device}.</div>
      <div>To fix this problem make sure your device is enabled in System Preferences/Settings</div>
    </>
  );
};