import { useCallback, useEffect, useMemo, useState } from 'react';
import { Call, Device, TwilioError } from '@twilio/voice-sdk';
import * as Sentry from '@sentry/react';
import Toast from '@/components/Toast';
import { ConferenceTwilioVoiceContext } from './Context';
import { TwilioVoice } from './interfaces';

export function TwilioVoiceContainer({ children }: ChildrenProps) {
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [state, setState] = useState<Call.State>(Call.State.Closed);
  const [microphoneDevice, changeMicrophoneDevice] = useState<MediaDeviceInfo>();

  const onError = useCallback((error: TwilioError.TwilioError | Error) => {
    Sentry.captureException(error);
    console.error(`VOICE ERROR: ${error.message}`, error);
    Toast.error({
      title: 'Voice Error',
      body: `${error.message}`,
    });
  }, []);

  const { call, device, connect } = useDevice(onError);

  useEffect(() => {
    const handleAccept = (_: Call) => setState(Call.State.Open);
    const handleMute = (isMuted: boolean, _: Call) => setIsMuted(isMuted);
    const handleDisconnect = (_: Call) => setState(Call.State.Closed);
    const handleCancel = (_: Call) => setState(Call.State.Closed);

    if (call) {
      call.on('accept', handleAccept);
      call.on('mute', handleMute);
      call.on('disconnect', handleDisconnect);
      call.on('cancel', handleCancel);
    }
    return () => {
      if (call) {
        call.off('accept', handleAccept);
        call.off('mute', handleMute);
        call.off('disconnect', handleDisconnect);
        call.off('cancel', handleCancel);
      }
    };
  }, [call]);

  const getAudioPermission = useCallback(() => {
    if (!navigator.mediaDevices?.getUserMedia) return Promise.resolve(false);
    return navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        stream.getTracks().forEach(track => track.stop());
        return true;
      })
      .catch(() => false);
  }, []);

  const disconnect = useCallback(() => {
    call?.disconnect();
    if (device?.audio) {
      device.audio.unsetInputDevice();
    }
  }, [call, device]);

  const toggleMicrophone = useToggleMicrophone(call, isMuted);

  const changeMicrophoneSource = useCallback((newDevice: MediaDeviceInfo) => {
    changeMicrophoneDevice(newDevice);
    if (device) {
      device.audio.setInputDevice(newDevice?.deviceId).catch(err => {
        Toast.error({
          title: 'Could not change audio source',
        });
      });
    }
  }, [device, changeMicrophoneDevice]);

  const muteSelf = useCallback(() => {
    call?.mute(true);
  }, [call]);

  const value: TwilioVoice.Context = useMemo(() => ({
    call,
    device,
    connect,
    disconnect,
    getAudioPermission,
    state,
    isMuted,
    microphoneSource: microphoneDevice,
    toggleMicrophone,
    changeMicrophoneSource,
    muteSelf,
  }), [
    call,
    device,
    connect,
    disconnect,
    getAudioPermission,
    state,
    isMuted,
    microphoneDevice,
    toggleMicrophone,
    changeMicrophoneSource,
    muteSelf,
  ]);

  return (
    <ConferenceTwilioVoiceContext.Provider value={value}>
      {children}
    </ConferenceTwilioVoiceContext.Provider>
  );
}

function useDevice(onError: (error: TwilioError.TwilioError | Error) => void) {
  const [device, setDevice] = useState<Device>(null);
  const [call, setCall] = useState<Call>(null);

  const connect = useCallback((token: string, microphone: boolean, microphoneDeviceId: string) => {
    const newDevice = new Device(token, {
      logLevel: 'warn',
    });
    setDevice(newDevice);

    return newDevice
      .connect()
      .then(newCall => {
        setCall(newCall);
        newCall.on('accept', (call: Call) => {
          call.mute(!microphone);
          if (microphoneDeviceId) {
            newDevice.audio.setInputDevice(microphoneDeviceId).catch(err => console.log(err));
          }
        });
      })
      .catch(onError);
  }, [onError]);

  return {
    call,
    device,
    connect,
  };
}

function useToggleMicrophone(call: Call, isMuted: boolean) {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  return useCallback(() => {
    if (!isUpdating) {
      setIsUpdating(true);
      call?.mute(!isMuted);
      setIsUpdating(false);
    }
  }, [isUpdating, call, isMuted]);
}