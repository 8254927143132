import { useCallback, useMemo, useState, ChangeEvent } from 'react';
import cuid from 'cuid';
import { usePopper } from 'react-popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { ZIndex } from '@consts';
import { Chip } from '@presentation/Chip';
import { SurveyTemplate } from '@/types/survey';
import { cx, toTitleCase } from '@utils';
import { useDebounceValue } from '@utils/hooks';
import { useFetchSurveyTemplateRefData } from '@utils/api';
import { Input } from '@/components/Input';
import { useSameWidthModifier, useZIndexModifier } from '@/components/Popper';
import { Portal } from '@/components/Portal';
import styles from './style/Autocomplete.css';

type Props = {
  allowCustomSelection?: boolean;
  className?: string;
  onSelect: (value: SurveyTemplate.LinkedEntity) => void;
  onRemove: (id: string) => void;
  placeholder?: string;
  source: Source;
  values: SurveyTemplate.LinkedEntity[];
};

type Source = {
  companies?: boolean;
  industries?: boolean;
  medicalConditions?: boolean;
  products?: boolean;
};

export const SurveyTemplateAutocompleteMulti = ({
  allowCustomSelection,
  className,
  onRemove,
  onSelect,
  placeholder,
  source,
  values,
}: Props) => {

  const [inputValue, setInputValue] = useState('');

  const debouncedValue = useDebounceValue(inputValue, 250);

  const [focused, setFocused] = useState(false);

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);

  const { isFetching, data: items } = useFetchSurveyTemplateRefData({
    ...{
      companies: false,
      industries: false,
      medicalConditions: false,
      products: false,
      ...source,
    },
    value: debouncedValue,
  }, {
    keepPreviousData: true,
    placeholderData: [],
    refetchOnWindowFocus: false,
  });

  const filteredItems = useMemo(() => {
    return items.filter(f => !values.some(s => s.id === f.id.toString()));
  }, [items, values]);

  const sameWidthModifier = useSameWidthModifier();
  const zIndexModifier = useZIndexModifier({ zIndex: ZIndex.Modal + 1 });

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [sameWidthModifier, zIndexModifier],
    placement: 'bottom-start',
  });

  const includeCustomItem = useMemo(() => {
    if (!allowCustomSelection) return false;

    const hasInput = (inputValue?.length || 0) > 2;
    const missingFromList = !items.map(m => m.name.toLowerCase()).includes(inputValue.toLowerCase());
    const selectedAlready = values.map(m => m.value.toLowerCase()).includes(inputValue.toLowerCase());

    return hasInput && missingFromList && !selectedAlready;
  }, [allowCustomSelection, inputValue, items, values]);

  const popperVisible = useMemo(() => {
    if (!focused) return false;

    if (includeCustomItem) return true;

    return !!items.length;
  }, [
    focused,
    items,
    includeCustomItem,
  ]);

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleClickAway = useCallback((e: MouseEvent) => {
    if (!referenceElement.contains(e.target as Node)) {
      setFocused(false);
    }
  }, [referenceElement]);

  const handleSelect = useCallback((item: Item) => () => {
    onSelect({
      id: item.id.toString(),
      value: item.name,
      entity: {
        id: item.id,
        type: item.type,
      },
    });
    setFocused(false);
    setInputValue('');
  }, [
    onSelect,
  ]);

  const handleSelectCustom = useCallback(() => {
    onSelect({
      id: cuid(),
      value: inputValue,
    });
    setFocused(false);
    setInputValue('');
  }, [inputValue, onSelect]);

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  }, []);

  const customItem = useMemo(() => {
    if (!includeCustomItem) return null;

    return (
      <div
        className={styles.menuItem}
        onClick={handleSelectCustom}>
        {inputValue}
      </div>
    );
  }, [includeCustomItem, handleSelectCustom, inputValue]);

  return (
    <>
      <div
        ref={setReferenceElement}
        className={cx(className)}>
        <Input
          className={styles.input}
          placeholder={placeholder}
          onChange={handleInputChange}
          onFocus={handleFocus}
          value={inputValue} />
      </div>
      <div className={styles.tags}>
        {values.map(m => (
          <Chip
            key={m.id}
            className={styles.tag}
            color="selected">
            <Chip.Body.Action variant='remove' onClick={() => onRemove(m.id)}>
              {m.value}
            </Chip.Body.Action>
          </Chip>
        ))}
      </div>
      {popperVisible &&
        <Portal>
          <div
            ref={setPopperElement}
            style={popperStyles.popper}
            {...attributes.popper}>
            <ClickAwayListener onClickAway={handleClickAway}>
              <div className={styles.menu}>
                {customItem}
                {filteredItems.map((item, i) => (
                  <div
                    key={i}
                    className={styles.menuItem}
                    onClick={handleSelect(item)}>
                    {item.name}
                  </div>
                ))}
              </div>
            </ClickAwayListener>
          </div>
        </Portal>
      }
    </>
  );
};

type Item = {
  id: number;
  name: string;
  type: SurveyTemplate.LinkedEntity['entity']['type'];
};