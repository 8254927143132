import { AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getTranscriptEntities } from '@api/transcripts';

type Props = ITranscriptId;

export const useTranscriptEntities = (props: Props, options: Options = {}) => {
  return useQuery(['transcript-entities', props], ({ queryKey }) => {
    return getTranscriptEntities(queryKey[1]);
  }, options);
};

type Data = Awaited<ReturnType<typeof getTranscriptEntities>>;
type QK = readonly [string, Props];

type Options = UseQueryOptions<Data, AxiosResponse, Data, QK>;