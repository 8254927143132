import { createNamedContext } from '@/utils';
import type {
  PastSearchesContextValue, SearchFiltersQueryContextValue, SearchLoadingContextState,
  SearchLoadingDispatch, SearchLoadingReset, SearchResultsContextState, SearchResultsDispatch,
  SearchResultsReset,
} from './interfaces';

export const SearchFiltersContext = createNamedContext<SearchFiltersQueryContextValue>(undefined, 'WorkspaceGlobalSearch.SearchFilters.Context');

export const PastSearchesContext = createNamedContext<PastSearchesContextValue>(undefined, 'WorkspaceGlobalSearch.PastSearches.Context');

export const SearchResultsDispatchContext = createNamedContext<SearchResultsDispatch>(undefined, 'WorkspaceGlobalSearch.Results.Dispatch');
export const SearchResultsResetContext = createNamedContext<SearchResultsReset>(undefined, 'WorkspaceGlobalSearch.Results.Reset');

export const SearchResultsAllContext = createNamedContext<SearchResultsContextState['all']>(undefined, 'WorkspaceGlobalSearch.Results.All');
export const SearchResultsProjectContext = createNamedContext<SearchResultsContextState['projects']>(undefined, 'WorkspaceGlobalSearch.Results.Project');
export const SearchResultsMemberContext = createNamedContext<SearchResultsContextState['members']>(undefined, 'WorkspaceGlobalSearch.Results.Member');
export const SearchResultsFileContext = createNamedContext<SearchResultsContextState['files']>(undefined, 'WorkspaceGlobalSearch.Results.File');
export const SearchResultsFolderContext = createNamedContext<SearchResultsContextState['folders']>(undefined, 'WorkspaceGlobalSearch.Results.Folder');
export const SearchResultsTranscriptContext = createNamedContext<SearchResultsContextState['transcripts']>(undefined, 'WorkspaceGlobalSearch.Results.Transcript');
export const SearchResultsBrandContext = createNamedContext<SearchResultsContextState['brands']>(undefined, 'WorkspaceGlobalSearch.Results.Brand');

export const SearchLoadingDispatchContext = createNamedContext<SearchLoadingDispatch>(undefined, 'WorkspaceGlobalSearch.Loading.Dispatch');
export const SearchLoadingResetContext = createNamedContext<SearchLoadingReset>(undefined, 'WorkspaceGlobalSearch.Loading.Reset');

export const SearchLoadingAllContext = createNamedContext<SearchLoadingContextState['all']>(undefined, 'WorkspaceGlobalSearch.Loading.All');
export const SearchLoadingProjectContext = createNamedContext<SearchLoadingContextState['projects']>(undefined, 'WorkspaceGlobalSearch.Loading.Project');
export const SearchLoadingMemberContext = createNamedContext<SearchLoadingContextState['members']>(undefined, 'WorkspaceGlobalSearch.Loading.Member');
export const SearchLoadingFileContext = createNamedContext<SearchLoadingContextState['files']>(undefined, 'WorkspaceGlobalSearch.Loading.File');
export const SearchLoadingFolderContext = createNamedContext<SearchLoadingContextState['folders']>(undefined, 'WorkspaceGlobalSearch.Loading.Folder');
export const SearchLoadingTranscriptContext = createNamedContext<SearchLoadingContextState['transcripts']>(undefined, 'WorkspaceGlobalSearch.Loading.Transcript');
export const SearchLoadingBrandContext = createNamedContext<SearchLoadingContextState['brands']>(undefined, 'WorkspaceGlobalSearch.Loading.Brand');