import { cx } from '@utils';
import { RightProps } from './interfaces';
import styles from './style.css';

type Props =
    RightProps;

const Right = (props: Props) => {
  return (
    <div className={cx(styles.right, props.className)} style={props.style}>
      {props.children}
    </div>
  );
};

export { Right };
export default Right;