import type { SurveyQuestionType } from '@enums';
import type { MatrixGridQuestion } from '@/types';
import type { Validation } from '../interfaces';
import { assertValidOptions } from './validation.options';
import { assertValidRows } from './validation.rows';
import { assertHasValue, returnAssertFailure } from './validation.base';

export const DefaultColumns = 5;
export const MaxColumns = 13;
export const MinColumns = 2;

export const DefaultRows = 5;
export const MaxRows = 40;
export const MinRows = 1;

export const DefaultSettings: MatrixGridQuestion.Settings = {
  display: null,
  groupBy: 'row',
  includeNotApplicable: false,
  orderRowsBasedOnSource: false,
  randomizeRows: false,
  randomizeOptions: false,
  rationale: {
    enabled: false,
    minimum: 75,
  },
  scaleDisplayFrequency: null,
};

export const DefaultRowMetadata: MatrixGridQuestion.RowMetadata = {
  canDelete: true,
  canModifyValue: true,
  isAnchored: false,
  isLastRowInCurrentGrid: false,
  isOpenEnded: false,
  template: {},
};

export function validateQuestion(question: MatrixGridQuestion.Question): Validation.ValidateQuestionResult<SurveyQuestionType.MatrixGrid> {
  const hasValue = assertHasValue(question);
  if (hasValue.success === false) {
    return returnAssertFailure(hasValue, question);
  }

  const hasValidOptions = assertValidOptions(question.options, MinColumns, MaxColumns);
  if (hasValidOptions.success === false) {
    return returnAssertFailure(hasValidOptions, question);
  }

  const hasValidRows = assertValidRows(question.matrixRows, MinRows, MaxRows);
  if (hasValidRows.success === false) {
    return returnAssertFailure(hasValidRows, question);
  }

  return {
    success: true,
    result: {
      question,
    },
  };
}
