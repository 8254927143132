import { useState, useEffect } from 'react';

// This returns the type of the value that is returned by a promise resolution
type ThenArg<T> = T extends PromiseLike<infer U> ? U : never;

type Props = {
  video: boolean;
  audio: boolean;
};

type ErrorReason = 'permission-denied' | 'device-in-use' | 'no-devices-found' | 'unknown';

type ErrorState = {
  reason: ErrorReason;
  errMsg: string;
  prettyMsg: string;
};

export function useDevices({ video, audio }: Props) {
  const [deviceInfo, setDeviceInfo] = useState<ThenArg<ReturnType<typeof getDeviceInfo>>>({
    audioInputDevices: [],
    videoInputDevices: [],
    audioOutputDevices: [],
    hasAudioInputDevices: false,
    hasVideoInputDevices: false,
    loading: true,
  });

  const [errorState, setErrorState] = useState<ErrorState>();

  useEffect(() => {
    if (video || audio) {
      const getDevices = () => {
        if (!navigator.mediaDevices?.getUserMedia) {
          setErrorState({
            reason: 'unknown',
            errMsg: 'Your browser does not support WebRTC',
            prettyMsg: 'Your browser does not support WebRTC',
          });
          return;
        }

        navigator.mediaDevices.getUserMedia({ video, audio }).then(async stream => {
          await getDeviceInfo().then(devices => {
            setDeviceInfo(devices);
            setErrorState(null);
          });
          stream.getTracks().forEach(track => track.stop());
        }).catch((err: Error) => {
          let reason: ErrorReason;
          if (['NotAllowedError', 'PermissionDeniedError'].includes(err.name)) {
            reason = 'permission-denied';
          } else if (['NotReadableError', 'TrackStartError'].includes(err.name)) {
            reason = 'device-in-use';
          } else if (['NotFoundError'].includes(err.name) || ['Requested device not found'].includes(err?.message)) {
            reason = 'no-devices-found';
          } else {
            reason = 'unknown';
          }

          setErrorState({
            reason,
            errMsg: err?.message,
            prettyMsg: getDeviceErrorMessage(reason),
          });
        });
      };
      if (navigator.mediaDevices?.addEventListener) {
        navigator.mediaDevices.addEventListener('devicechange', getDevices);
      }

      const trackedPermissions: PermissionStatus[] = [];

      if (video) {
        navigator.permissions.query({
          name: 'camera' as PermissionName,
        }).then(q => {
          q.onchange = () => {
            getDevices();
          };
          trackedPermissions.push(q);
        }).catch(() => console.error(`Camera permissions API not supported`));
      }

      if (audio) {
        navigator.permissions.query({
          name: 'microphone' as PermissionName,
        }).then(q => {
          q.onchange = () => {
            getDevices();
          };
          trackedPermissions.push(q);
        }).catch(() => console.error(`Microphone permissions API not supported`));
      }

      getDevices();

      return () => {
        if (navigator.mediaDevices?.addEventListener) {
          navigator.mediaDevices.removeEventListener('devicechange', getDevices);
        }

        for (const p of trackedPermissions) {
          p.onchange = null;
        }
      };
    }
  }, [video, audio]);

  return {
    ...deviceInfo,
    errorState,
  };
}

export async function getDeviceInfo() {
  const devices = await navigator.mediaDevices.enumerateDevices();

  let videoInputDevices = devices.filter(device => device.kind === 'videoinput');

  //Put a default video device in there if we are given a list of devices but dont know which is the default
  //With audio we're told which is the default but with video we'll need to have an ambiguous placeholder
  if (videoInputDevices.length && !videoInputDevices.find(d => d.deviceId === 'default')) {
    videoInputDevices = [{ label: 'Default', deviceId: null, groupId: null, kind: 'videoinput', toJSON: () => { } }, ...videoInputDevices];
  }

  return {
    audioInputDevices: devices.filter(device => device.kind === 'audioinput'),
    videoInputDevices,
    audioOutputDevices: devices.filter(device => device.kind === 'audiooutput'),
    hasAudioInputDevices: devices.some(device => device.kind === 'audioinput'),
    hasVideoInputDevices: devices.some(device => device.kind === 'videoinput'),
    loading: false,
  };
}

export function getDeviceErrorMessage(reason: ErrorReason) {
  switch (reason) {
    case 'device-in-use':
      return 'The device requested is in use';
    case 'permission-denied':
      return 'This website has not given device permissions';
    case 'no-devices-found':
      return 'Your browser could not find your camera/mic. Please make sure it is plugged in and enabled.';
    default:
      return 'The was an error acquiring device permissions';
  }
}