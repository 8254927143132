import type { AxiosResponse } from 'axios';
import qs from 'query-string';
import http, { xhr } from '@services/http';
import type * as Projects from '@services/api/interfaces/projects.reports';

export const exportProjectData = (data: Projects.ExportProjectData.Request): Promise<Projects.ExportProjectData.Response> => {
  return xhr.get<Blob>(`/projects/${data.projectId}/export?${qs.stringify({ userIds: data.userIds, format: data.format, surveyId: data.surveyId })}`, { responseType: 'blob' })
    .then((res: Response) => {
      const filename = new RegExp(/filename="(.+)"/).exec(res.headers['content-disposition']);
      return {
        blob: res.data,
        filename: filename.length ? filename[1] : null,
      };
    });
  type Response = {
    headers: Record<string, string>;
  } & Omit<AxiosResponse<Blob>, 'headers'>;
};

export const exportRecruitmentGrid = async (data: Projects.ExportRecruitmentGrid.Request) => {
  return http.get<Blob>(`/projects/${data.projectId}/reports/recruitment-grids?${qs.stringify({ surveyId: data.surveyId })}`, { responseType: 'blob' }).then<Projects.ExportRecruitmentGrid.Response>(res => (
    {
      file: res,
      name: data.filename,
    }
  ));
};

export const exportProjectDataAsync = (data: Projects.ExportProjectDataAsync.Request): Promise<Projects.ExportProjectDataAsync.Response> => {
  return http.get(`/projects/${data.projectId}/reports/async?${qs.stringify({ userIds: data.userIds, format: data.format, surveyId: data.surveyId }, { arrayFormat: 'bracket', skipNull: true })}`);
};

export const exportProjectDataType = (data: Projects.ExportProjectDataType.Request): Promise<Projects.ExportProjectDataType.Response> => {
  return http.get(`/projects/${data.projectId}/reports/type?${qs.stringify({ userIds: data.userIds, format: data.format, dataType: data.dataType, surveyId: data.surveyId }, { arrayFormat: 'bracket', skipNull: true })}`);
};

export const exportProjectParentData = (data: Projects.ExportProjectParentData.Request): Promise<Projects.ExportProjectData.Response> => {
  return http.download(`/projects/parents/${data.projectParentId}/reports?${qs.stringify({ userIds: data.userIds, format: data.format, surveyId: data.surveyId }, { arrayFormat: 'bracket', skipNull: true })}`);
};

export const exportProjectParentDataAsync = (data: Projects.ExportProjectParentDataAsync.Request): Promise<Projects.ExportProjectDataAsync.Response> => {
  return http.get(`/projects/parents/${data.projectParentId}/reports/async?${qs.stringify({ userIds: data.userIds, format: data.format, surveyId: data.surveyId }, { arrayFormat: 'bracket', skipNull: true })}`);
};

export const exportProjectParentDataType = (data: Projects.ExportProjectParentDataType.Request): Promise<Projects.ExportProjectParentDataType.Response> => {
  return http.get(`/projects/parents/${data.projectParentId}/reports/type?${qs.stringify({ userIds: data.userIds, format: data.format, dataType: data.dataType, surveyId: data.surveyId }, { arrayFormat: 'bracket', skipNull: true })}`);
};

export const downloadProjectQuotesReport = ({ format, projectId }: Projects.GetProjectQuotesReport.Request) => {
  return http.get<Projects.GetProjectQuotesReport.Response>(`/projects/${projectId}/reports/good-quotes?format=${format}`);
};