import { useContext } from 'react';
import { cx } from '@utils';
import { ProjectInviteReferralEmailContext } from './Context';
import styles from './style/Email.Input.css';

type Props = {
  className?: string;
};

export const MessageTextArea = (props: Props) => {
  const ctx = useContext(ProjectInviteReferralEmailContext);

  return (
    <textarea
      className={cx(styles.textarea, props.className)}
      onChange={e => ctx.setMessage(e.target.value)}
      placeholder="Customize the invitation message..."
      value={ctx.data.message} />
  );
};

MessageTextArea.displayName = 'ProjectInviteReferral.Email.MessageTextArea';