import { useCallback, useMemo } from 'react';
import type { SurveyQuestionOption } from '@/types';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import DropDown from '@/components/DropDown';

type Props = {
  disabled?: boolean;
  items: SurveyQuestionOption[];
  onSelect: (identifier: string) => void;
  placeholder?: string;
  value: SurveyQuestionOption;
};

type DropdownItem = {
  id: string;
  value: string;
};

export const OptionsDropdown = ({
  disabled,
  items,
  onSelect,
  placeholder,
  value,
}: Props) => {

  const options = useMemo(() => {
    return items.map(m => ({
      id: m.base.identifier,
      value: parseSurveyRichText(m.value),
    }));
  }, [items]);

  const getItemValue = useCallback((item: DropdownItem) => {
    return item?.value || '';
  }, []);

  const handleSelect = useCallback((item: DropdownItem) => {
    onSelect(item.id);
  }, [onSelect]);

  const text = useMemo(() => {
    return value?.value ? parseSurveyRichText(value.value) : null;
  }, [value]);

  return (
    <DropDown<DropdownItem>
      placeholder={placeholder || 'Select Option'}
      disabled={disabled}
      items={options}
      getItemValue={getItemValue}
      onSelect={handleSelect}
      text={text} />
  );
};

export default OptionsDropdown;