import { useMemo, useState, useEffect } from 'react';
import { useDocChanged, useEditorState, useRemirrorContext } from '@remirror/react-core';
import { useTranscriptRedactingContext } from '@containers/Transcript.Redaction/hooks';
import { useTranscriptDocumentContext } from '@containers/Transcript/hooks';

export const useHasUnsavedChanges = () => {
  const redactingContext = useTranscriptRedactingContext();
  const { helpers } = useRemirrorContext();
  const editorState = useEditorState();
  const originalTranscript = useTranscriptDocumentContext();

  const [docChanged, setDocChanged] = useState(helpers.undoDepth() > 0);

  useDocChanged(handler => {
    setDocChanged(handler.transactions.length > 0);
  });

  //Make sure we recheck the changed state if we reload the transcript
  useEffect(() => {
    //Put this in a timeout so that it fires after we force update the transcript state
    setTimeout(() => {
      setDocChanged(helpers.undoDepth() > 0);
    });
  }, [originalTranscript, helpers]);

  const hasRedactionChanges = useMemo(() => {
    return redactingContext?.redactedRanges?.length > 0;
  }, [redactingContext?.redactedRanges]);

  return docChanged || hasRedactionChanges;
};