import type { Socket } from 'socket.io-client';
import { useEffect, useState } from 'react';
import type { SocketNamespace } from '@/enums';
import { getSocket } from '../socket';
import { useSetupDefaultSocketListeners } from './useSetupDefaultListeners';

export const useSocket = (namespace: SocketNamespace) => {
  const [socket, setSocket] = useState<Socket>();

  useEffect(() => {
    const newSocket = getSocket(namespace);
    setSocket(newSocket);

    return () => {
      newSocket.close();
    };
  }, [namespace]);

  useSetupDefaultSocketListeners(socket, namespace);

  return socket;
};