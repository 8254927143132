import { useCallback } from 'react';
import { useFilters } from './useFilters';

export function usePagination() {
  const [state, dispatch] = useFilters();

  const goToPage = useCallback((pageIndex: number) => {
    dispatch({
      type: 'update-page',
      index: pageIndex,
    });
  }, [dispatch]);

  return {
    goToPage,
    pageIndex: state.pageIndex,
    pageSize: state.pageSize,
  };
}