import { useCallback, useContext } from 'react';
import { MessagesListRefContext } from '../context';

type Vars = ScrollIntoViewOptions & {
  nthFromBottom?: number;
};

export function useScrollToBottomOfMessages() {
  const [messagesRef] = useContext(MessagesListRefContext);

  return useCallback(({
    nthFromBottom,
    ...vars
  }: Vars = {
    behavior: 'auto',
  }) => {
    setTimeout(() => {
      if (!messagesRef?.childElementCount) return;

      if (nthFromBottom) {
        const scrollIndex = messagesRef.children.length - nthFromBottom;
        messagesRef.children[scrollIndex].scrollIntoView(vars);
        return;
      }

      const childScrollIndex = messagesRef.childElementCount >= 3
        ? messagesRef.children.length - 2
        : messagesRef.children.length - 1;

      const lastChild = messagesRef.children[childScrollIndex];

      lastChild.scrollIntoView(vars);
    }, 0);
  }, [messagesRef]);
}