import { memo, useMemo } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import type { TableInstance } from 'react-table';
import type { Table } from '@tanstack/react-table';
import { cx } from '@utils';
import styles from './style/TablePagination.css';

type Props = {
  className?: string;
  canNextPage: boolean;
  canPreviousPage: boolean;
  nextPage: () => unknown;
  pageIndex: number;
  pageSize: number;
  previousPage: () => unknown;
  totalCount: number;
};

type RTProps = {
  className?: string;
  tableInstance: TableInstance;
  totalCount?: number;
};

type V8Props = {
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tableInstance: Table<any>;
  totalCount?: number;
};

type ControlledProps = {
  className?: string;
  goToPage: (pageIndex: number) => unknown;
  pageCount: number;
  pageIndex: number;
  pageSize: number;
  totalCount: number;
};

export const ControlledTablePagination = memo(({
  className,
  goToPage,
  pageCount,
  pageIndex,
  pageSize,
  totalCount,
}: ControlledProps) => {

  const canPreviousPage = pageIndex > 0;
  const canNextPage = pageIndex + 1 < pageCount;
  const previousPage = () => goToPage(pageIndex - 1);
  const nextPage = () => goToPage(pageIndex + 1);

  return (
    <TablePagination
      className={className}
      canPreviousPage={canPreviousPage}
      canNextPage={canNextPage}
      pageIndex={pageIndex}
      nextPage={nextPage}
      pageSize={pageSize}
      previousPage={previousPage}
      totalCount={totalCount} />
  );
});

export const ReactTablePagination = ({ className, tableInstance, totalCount }: RTProps) => {
  return (
    <TablePagination
      className={className}
      canPreviousPage={tableInstance.canPreviousPage}
      canNextPage={tableInstance.canNextPage}
      pageIndex={tableInstance.state.pageIndex}
      nextPage={tableInstance.nextPage}
      pageSize={tableInstance.state.pageSize}
      previousPage={tableInstance.previousPage}
      totalCount={totalCount || tableInstance.rows.length} />
  );
};

export const ReactTablev8Pagination = ({ className, tableInstance, totalCount }: V8Props) => {
  return (
    <TablePagination
      className={className}
      canPreviousPage={tableInstance.getCanPreviousPage()}
      canNextPage={tableInstance.getCanNextPage()}
      pageIndex={tableInstance.getState().pagination.pageIndex}
      nextPage={tableInstance.nextPage}
      pageSize={tableInstance.getState().pagination.pageSize}
      previousPage={tableInstance.previousPage}
      totalCount={totalCount ?? tableInstance.getCoreRowModel().rows.length} />
  );
};

export const TablePagination = memo(({
  canNextPage,
  canPreviousPage,
  className,
  nextPage,
  pageIndex,
  pageSize,
  previousPage,
  totalCount,
}: Props) => {

  const display = useMemo(() => {

    const start = pageIndex * pageSize;
    const end = Math.min(start + pageSize, totalCount);

    const startDisplay = Math.min(start + 1, totalCount);

    return `${startDisplay} - ${end} of ${totalCount}`;
  }, [
    pageIndex,
    pageSize,
    totalCount,
  ]);

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.count}>
        {display}
      </div>
      <div className={styles.navigation}>
        <ChevronLeft
          className={cx(styles.chevron, canPreviousPage ? '' : styles.disabled)}
          onClick={canPreviousPage ? previousPage : undefined} />
        <ChevronRight
          className={cx(styles.chevron, canNextPage ? '' : styles.disabled)}
          onClick={canNextPage ? nextPage : undefined} />
      </div>
    </div>
  );
});

export default TablePagination;