import { memo, useCallback, useMemo, useState, useRef } from 'react';
import * as enums from '@/enums';
import { Button } from '@/components/Button';
import { useStarRating } from '@/components/StarRating';
import { Textarea } from '@/components/Textarea';
import Toast from '@/components/Toast';
import UserAvatar from '@/components/UserAvatar/UserAvatar';
import { getOtherAdHocCallParticipant } from '@/containers/Scheduler/utils.calls';
import { useSelectContact, useSelectProject, useSelectCall, useSelectUser } from '@/containers/Store';
import { useHasClientRole } from '@/containers/User';
import { useConditionalRender } from '@/utils';
import { useSubmitCallRating } from '@/utils/api';
import type { AdHocCall, GroupTag } from '@/types';
import { Tags } from './Tags';
import type { CallRatingItem, CallRatingProps } from './interfaces';
import styles from './style.css';

type Props =
  CallRatingProps;

const CreatorHeader = memo(({
  pictureUrl,
  title,
}: {
  pictureUrl: string;
  title: string;
}) => {
  return (
    <>
      {pictureUrl &&
        <div className={styles.avatar}>
          <UserAvatar
            size={75}
            pictureUrl={pictureUrl} />
        </div>
      }
      <div className={styles.ratingTitle}>{title}</div>
    </>
  );
});

const ParticipantHeader = memo(({
  subject,
}: { subject: string }) => {
  const title = `How was your ${subject} call?`;

  return (
    <div className={styles.ratingTitle}>{title}</div>
  );
});

const ProjectCallClientHeader = ({ call }: CallRatingItem) => {
  const title = `How was your ${call.project.name} call with ${call.user.firstName}?`;

  return (
    <CreatorHeader
      pictureUrl={call.user.pictureUrl}
      title={title} />
  );
};

const ProjectCallExpertHeader = ({ call }: CallRatingItem) => {
  return (
    <ParticipantHeader subject={call.project.name} />
  );
};

const AdHocCallCreatorHeader = ({ call }: { call: AdHocCall }) => {
  const me = useSelectUser();
  const otherParticipantid = getOtherAdHocCallParticipant(call, me.id);
  const contact = useSelectContact(otherParticipantid);

  const title = `How was your ${call.request.subject} call with ${contact.profile.firstName}?`;

  return (
    <CreatorHeader
      pictureUrl={contact.profile.pictureUrl}
      title={title} />
  );
};

const AdHocCallRecipientHeader = ({ call }: { call: AdHocCall }) => {

  return (
    <ParticipantHeader subject={call.request.subject} />
  );
};

const CallRating = ({ item, onSubmit }: Props) => {
  const me = useSelectUser();
  const call = item.call;
  const hasClientRole = useHasClientRole();
  const notesRef = useRef<HTMLTextAreaElement>();

  const [rating, StarRating] = useStarRating({ size: 50, rootClassName: styles.rating });
  const [tags, setTags] = useState<GroupTag[]>([]);

  const { mutateAsync, ...submit } = useSubmitCallRating({ callId: call.id }, {
    onSuccess: _ => {
      onSubmit();
    },
    onError: e => {
      Toast.error({
        title: 'Error',
        body: `We're sorry, there was an issue with your request`,
      });
    },
  });

  const handleSubmit = useCallback(() => {
    return mutateAsync({
      isSkipped: false,
      rating,
      notes: notesRef.current.value,
      tags,
    });
  }, [
    tags,
    mutateAsync,
    rating,
  ]);

  const handleSkip = useCallback(() => {
    return mutateAsync({
      isSkipped: true,
      rating: null,
      notes: null,
      tags: null,
    });
  }, [
    mutateAsync,
  ]);

  const renderHeader = useCallback(() => {
    if (call.typeId === enums.CallType.AdHoc) {

      if (me.id === call.creatorUserId) {
        //return <AdHocCallCreatorHeader call={call} />;
      } else {
        //return <AdHocCallRecipientHeader call={call} />;
      }
    } else if (call.typeId === enums.CallType.Project) {
      if (hasClientRole) {
        return <ProjectCallClientHeader {...item} />;
      } else {
        return <ProjectCallExpertHeader {...item} />;
      }
    }
  }, [
    item,
    call,
    me.id,
    hasClientRole,
  ]);

  const renderButtons = useCallback(() => {
    const disabled = rating === null || submit.isLoading;
    const skip = submit.isLoading ? undefined : handleSkip;

    return (
      <div className={styles.btns}>
        <Button.Primary
          className={styles.submit}
          disabled={disabled}
          onClick={handleSubmit}
          variant='brick'>
          Submit
        </Button.Primary>
        <div
          className={styles.skip}
          onClick={skip}>
          {`Skip>`}
        </div>
      </div>
    );
  }, [
    rating,
    handleSkip,
    handleSubmit,
    submit.isLoading,
  ]);

  const isModerator = useMemo(() => {
    const participant = item.participants?.find(p => p.id === me.id);
    if (!participant) return false;
    return [enums.CallRole.Scheduler, enums.CallRole.ModeratorAttendee].includes(participant.callRoleId);
  }, [
    me?.id,
    item?.participants,
  ]);

  const $Tags = useConditionalRender(isModerator);

  return (
    <>
      {renderHeader()}
      <div className={styles.footnote}>{!hasClientRole ? 'Please rate the quality of your discussion with the interviewer.' : 'Help screen candidates for future projects by rating your call.'}</div>
      <StarRating />
      <Textarea
        ref={notesRef}
        className={styles.notes}
        placeholder={'Any additional feedback?'} />
      <$Tags>
        <Tags
          callId={call.id}
          onChange={tags => setTags(tags)} />
      </$Tags>
      {renderButtons()}
    </>
  );
};

export { CallRating };
export default CallRating;