import type {
  ApplySchemaAttributes,
  NodeExtensionSpec,
  NodeSpecOverride,
} from '@remirror/core';
import {
  extension,
  ExtensionTag,
  NodeExtension,
} from '@remirror/core';
import { NodeType } from '@/types/transcribe.rich-text';
import { MonologueDetails } from './MonologueDetails';

type ComponentParam = NodeExtension['ReactComponent'];
@extension({
  defaultOptions: {},
})
export class MonologueDetailsExtension extends NodeExtension {

  constructor(component?: ComponentParam) {
    super();
    this.ReactComponent = component ?? MonologueDetails;
  }

  get name() {
    return NodeType.MonologueDetails as const;
  }

  createTags() {
    return [ExtensionTag.Block];
  }

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    return {
      attrs: {
        s: {},
        e: {},
        spk: {},
        ...extra.defaults(),
      },
      toDOM: node => ['div', {
        ...extra.dom(node),
        contentEditable: 'false',
        selectable: 'false',
      }],
    };
  }
}