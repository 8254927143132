import { useEffect } from 'react';
import { useProvidedDevices } from '@containers/DeviceProvider';
import { ConferenceSocketEvent } from '@/enums';
import { useConferenceInstance, useSocket } from './hooks';

type DeviceLoggerProps = {
  enabled: boolean;
} & ChildrenProps;

export const DeviceLogger = (props: DeviceLoggerProps) => {
  const { audioErrorState, audioInputDevices, audioLoading, videoErrorState, videoInputDevices, videoLoading } = useProvidedDevices();
  const socket = useSocket();

  useEffect(() => {
    if (props.enabled) {
      socket.raw.emit(ConferenceSocketEvent.ParticipantDevicesUpdated, {
        deviceInfo: {
          lastEventTs: new Date(),
          audio: {
            isPrompting: audioLoading,
            error: audioErrorState?.prettyMsg,
            deviceList: audioInputDevices.map(d => ({ id: d.deviceId, name: d.label })),
          },
          video: {
            isPrompting: videoLoading,
            error: videoErrorState?.prettyMsg,
            deviceList: videoInputDevices.map(d => ({ id: d.deviceId, name: d.label })),
          },
        },
      });
    }
    //console.log({ audioErrorState, audioInputDevices, audioLoading });
  }, [props.enabled, audioErrorState, audioInputDevices, audioLoading, socket.raw, videoErrorState, videoInputDevices, videoLoading]);

  return props.children;
};