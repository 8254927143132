import { format } from 'date-fns';
import { UAParser } from 'ua-parser-js';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import PhonelinkOffIcon from '@mui/icons-material/PhonelinkOff';
import type { Participant as LiveParticipant, Participant, ParticipantWeb } from '@/types/conferences.live';
import { Tooltip } from '@presentation/Tooltip';
import styles from './style/Bar.Right.Participants.css';

type ParticipantDeviceInfoProps = {
  participant: LiveParticipant;
};

export function ParticipantDeviceInfo({ participant }: ParticipantDeviceInfoProps) {
  if (participant.type !== 'web' || !participant.deviceInfo) return null;

  const hasError = participant.deviceInfo.audio.error || participant.deviceInfo.video.error;

  const Icon = hasError ? PhonelinkOffIcon : PhonelinkIcon;

  const ToolTipContent = (
    <div>
      <div>Audio</div>
      <DeviceInfo info={participant.deviceInfo.audio} />
      <div>Video</div>
      <DeviceInfo info={participant.deviceInfo.video} />
      <UserAgentInfo userAgent={participant.userAgent} />
      <div>Last updated on {format(new Date(participant.deviceInfo.lastEventTs), 'p')}</div>
    </div>
  );

  return (
    <Tooltip
      title={ToolTipContent}
      placement='top'>
      <Icon className={styles.deviceIcon} />
    </Tooltip>
  );
}

type DeviceInfo = ParticipantWeb['deviceInfo']['audio'];

function DeviceInfo({ info }: { info: DeviceInfo }) {
  if (info.error) {
    return `Error: ${info.error}`;
  } else if (info.isPrompting) {
    return `Waiting on user permission prompt`;
  } else {
    return `Ready: ${info.deviceList.length} devices available`;
  }
}

type UserAgentProps = {
  userAgent: string;
};

const UserAgentInfo = (props: UserAgentProps) => {

  if (!props.userAgent) return null;

  let userAgent: UAParser.IResult = null;
  try {
    userAgent = new UAParser(props.userAgent).getResult();
  } catch (e) {
    return <div>Invalid User Agent</div>;
  }

  return (
    <div>
      <div>Browser: {userAgent.browser.name} ({userAgent.browser.version})</div>
      <div>Device: {userAgent.device.type ?? 'desktop'}</div>
    </div>
  );
};
