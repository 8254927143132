import { useContext, useEffect, useRef } from 'react';
import { EditorComponent } from '@remirror/react';
import { TaggedMomentFormStateContext } from '@/containers/Transcript/context.tagged-moment';
import { useChainedCommands, useHelpers, useConfigureTaggedMomentHighlighting, useInitializeHighlights, useInitializeTimestampPositions } from './hooks';
import { TranscriptChainedCommandsContext, TranscriptEditorRefContext, TranscriptHelpersContext } from './context';

export const TaggedMomentEditor = () => {

  const commands = useChainedCommands();
  const helpers = useHelpers();
  const { findRanges } = helpers;
  const editorRef = useRef<HTMLDivElement>(null);
  const state = useContext(TaggedMomentFormStateContext);

  useInitializeHighlights();
  useInitializeTimestampPositions();

  useConfigureTaggedMomentHighlighting({
    container: editorRef.current,
    enabled: true,
  });

  useEffect(() => {
    if (state.keyword) {
      findRanges({ query: state.keyword });
    }
  }, [findRanges, state.keyword]);

  return (
    <TranscriptEditorRefContext.Provider value={editorRef.current}>
      <TranscriptChainedCommandsContext.Provider value={commands}>
        <TranscriptHelpersContext.Provider value={helpers}>
          <div ref={editorRef}>
            <EditorComponent />
          </div>
        </TranscriptHelpersContext.Provider>
      </TranscriptChainedCommandsContext.Provider>
    </TranscriptEditorRefContext.Provider>
  );
};