import { useCallback, useState } from 'react';
import { Input } from '@/components/Input';
import { ProjectInviteReferralEmailContextValue } from './interfaces';
import styles from './style/Email.Input.css';

type Props = {
  onAdd: ProjectInviteReferralEmailContextValue['addAddress'];
};

export const TextField = ({ onAdd }: Props) => {
  const [value, setValue] = useState('');

  const handleKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onAdd(value, addresses => {
        if (addresses.includes(value)) {
          setValue('');
        }
      });
    }
  }, [
    onAdd,
    setValue,
    value,
  ]);

  return (
    <Input
      classes={{
        input: styles.input,
      }}
      focusableClasses={{
        root: styles.focusable,
      }}
      onChange={e => setValue(e.target.value)}
      onKeyDown={handleKeyDown}
      placeholder="Add Email Addresses"
      value={value} />
  );
};

TextField.displayName = 'ProjectInviteReferral.Email.Input.TextField';