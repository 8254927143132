import { FeedItemType } from '@enums';
import { FeedItem } from '@/types';

export const totals = (items: FeedItem[]) => {
  return items.reduce((acc, x) => ({
    all: acc.all + 1,
    content: acc.content + ContentValue(x),
    platform: acc.platform + PlatformValue(x),
  }), {
    all: 0,
    content: 0,
    platform: 0,
  });
};

function ContentValue(item: FeedItem) {
  const types = [
    FeedItemType.NewsArticle,
    FeedItemType.Post,
  ];

  return types.includes(item.contentType) ? 1 : 0;
}

function PlatformValue(item: FeedItem) {
  const types = [
    FeedItemType.SuggestedTopics,
  ];

  return types.includes(item.contentType) ? 1 : 0;
}