import { useCallback, useMemo } from 'react';
import { useSurveyBuilderState, useIsEditingSurvey } from '@containers/SurveyBuilder';
import { useRichTextListBuilderContext } from '@containers/SurveyBuilder.Question/Context';
import { ConditionalResponseIcon } from '@presentation/SurveyBuilder';
import { AddItemButton } from '@/presentation/AddItemButton';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import { Tooltip } from '@presentation/Tooltip';
import type { SurveyQuestionOption, SurveyQuestionOptionMetadata } from '@/types/survey';
import { SurveyConditionType } from '@/enums';
import { getTemplateEntityLabelComponent } from '@/components/Survey.Templates/Label';
import type { RenderRichTextEntryListEditorProps, RichTextEntryItem } from '@/components/TextEntryList/interfaces';
import { RichTextEntryListEditor } from '@/components/TextEntryList/RichTextEntryListItem';
import { RichListBuilderItem } from './RichListBuilder';
import { OptionContextMenu } from './Option.ContextMenu';
import { LanguageEditingBarrier } from './LanguageEditingBarrier';

type Props = {
  className?: string;
  renderAction?: (option: SurveyQuestionOption) => React.ReactNode;
  subject?: string;
};

type RichListItem = {
  metadata: SurveyQuestionOptionMetadata;
} & RichTextEntryItem;

export const QuestionOptionsBuilder = ({
  renderAction,
  subject = 'Option',
  ...props
}: Props) => {

  const context = useRichTextListBuilderContext<SurveyQuestionOption>();

  const [state] = useSurveyBuilderState();
  const editing = useIsEditingSurvey();

  const renderConditionalIcon = useCallback((option: SurveyQuestionOption) => {
    if (!option.conditions.length) return null;
    const condition = option.conditions[0];
    if (condition.conditionType !== SurveyConditionType.Question) return null;

    const dependentOrdinal = state.survey.questions
      .find(f => f.base.identifier === condition.data.question.identifier)?.ordinal;

    if (!dependentOrdinal) return null;

    const tooltipText = `Conditional upon response to Q${dependentOrdinal}`;

    return (
      <Tooltip title={tooltipText}>
        <div>
          <ConditionalResponseIcon />
        </div>
      </Tooltip>
    );
  }, [state.survey.questions]);

  const renderItem = useCallback((p: RenderRichTextEntryListEditorProps<RichListItem>) => {
    const entityType = p.item.metadata.template.linkedEntity?.type;

    if (!entityType) return <RichTextEntryListEditor<RichListItem> {...p} />;

    const TemplateComponent = getTemplateEntityLabelComponent(entityType);

    return (
      <TemplateComponent
        disabled={editing}
        value={parseSurveyRichText(p.item.value, false)} />
    );
  }, [editing]);

  const renderOptionMenu = useCallback((option: SurveyQuestionOption) => {
    if (renderAction) return renderAction(option);

    return (
      <OptionContextMenu option={option} />
    );
  }, [renderAction]);

  const listItemPlaceholder = useMemo(() => {
    const vowels = 'aeiou';
    const startsWithVowel = vowels.indexOf(subject.toLowerCase()[0]) !== -1;

    const grammarArticle = startsWithVowel
      ? 'an'
      : 'a';

    return `Enter ${grammarArticle} ${subject.toLowerCase()}`;
  }, [subject]);

  return (
    <div className={props.className}>
      {context.items.map(option => (
        <RichListBuilderItem<RichListItem>
          key={option.base.identifier}
          item={{
            key: option.base.identifier,
            value: option.value,
            metadata: option.metadata,
          }}
          renderAction={() => renderOptionMenu(option)}
          renderEditor={renderItem}
          placeholder={listItemPlaceholder}
          onUpdate={value => context.updateItemValue(option.base.identifier, value)}
          disabled={!option.metadata.canModifyValue}>
          {renderConditionalIcon(option)}
        </RichListBuilderItem>
      ))}
      {context.permissions.canAddItem &&
        <LanguageEditingBarrier>
          <AddItemButton
            label={`Add ${subject}`}
            onClick={context.addItem} />
        </LanguageEditingBarrier>}
    </div>
  );
};

export const QuestionColumnsBuilder = (props: Omit<Props, 'subject'>) => {
  return (
    <QuestionOptionsBuilder
      subject="Column"
      {...props} />
  );
};

export const QuestionAttributesBuilder = (props: Omit<Props, 'subject'>) => {
  return (
    <QuestionOptionsBuilder
      subject="Attribute"
      {...props} />
  );
};

export default QuestionOptionsBuilder;