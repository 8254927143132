import { memo } from 'react';
import { styled } from '@mui/material/styles';
import MuiTooltip, { type TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

export const Tooltip = memo(
  styled(({ className, ...props }: TooltipProps) => (
    <MuiTooltip
      {...props}
      arrow={true}
      classes={{ popper: className }} />
  ))`
  & .${tooltipClasses.tooltip} {
    background-color: ${({ theme }) => theme.palette.black.light1};
    color: ${({ theme }) => theme.palette.secondary.main};
    font-size: 13;
    font-family: ${({ theme }) => theme.fonts.semiBold};
  }

  & .${tooltipClasses.arrow} {
    color: ${({ theme }) => theme.palette.black.light1};
  }
  `
);