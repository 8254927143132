import { useContext } from 'react';
import { AccountProfileBioContext } from '@containers/AccountProfile/Context';

type Params = unknown;

const useAccountProfileBioContext = () => {
  return useContext(AccountProfileBioContext);
};

export { useAccountProfileBioContext };
export default useAccountProfileBioContext;