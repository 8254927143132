import { useCallback } from 'react';
import { Download } from 'react-feather';
import { CompletedCall } from '@/types';
import { useCallVideoUrl } from '@utils/api';
import { DownloadCallMediaContainer } from '@/containers/Transcript/Call.Media';
import { TranscriptDownloadMenu } from '@/screens/Transcript/DownloadMenu';
import { ConferenceMediaType } from '@/enums';
import { Tooltip } from '@/presentation/Tooltip';
import { TranscriptDownloader } from './TranscriptDownloader';
import styles from './style/CallItem.css';

type Props = {
  item: CompletedCall;
  hasNotes?: boolean;
};

export const Downloader = ({ hasNotes, item }: Props) => {
  const DownloadIcon = useCallback(() => {
    if (item.conference.mediaTypeId === ConferenceMediaType.Video) {
      return (
        <VideoDownloader
          hasNotes={hasNotes}
          item={item} />
      );
    } else if (item.conference.mediaTypeId === ConferenceMediaType.Voice) {
      return <VoiceDownloader item={item} />;
    }
  }, [
    hasNotes,
    item,
  ]);

  return (
    <DownloadIcon />
  );
};

const VideoDownloader = ({ hasNotes, item }: Props) => {
  const {
    data,
    refetch,
  } = useCallVideoUrl({
    callId: item.id,
    includeCaptions: false,
    roomInstanceId: item.conference.recordingInstanceId,
  }, {
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const url = useCallback(() => {
    return refetch().then(d => d.data.url);
  }, [refetch]);

  return (
    <DownloadCallMediaContainer callId={item.id}>
      <TranscriptDownloadMenu
        hasNotes={hasNotes}
        iconClassName={styles.downloader}
        callId={item.id}
        projectId={item.projectId}
        publishType={item.transcript?.settings?.publishType}
        source={{ url, type: 'video' }}
        transcriptId={item.transcript.id} />
    </DownloadCallMediaContainer>
  );
};

const VoiceDownloader = ({ item }: Props) => {
  return (
    <TranscriptDownloader transcriptId={item.transcript.id}>
      <Tooltip title="Download Files">
        <Download className={styles.icon} />
      </Tooltip>
    </TranscriptDownloader>
  );
};
