import { Downloader } from '@containers/Downloader';
import { useDocumentDownload } from '@containers/Document';
import * as enums from '@enums';
import { DocumentItem } from '@/types';
import { DocumentDownloadProps } from './interfaces';

export const DocumentDownload = (props: DocumentDownloadProps) => {
  const {
    downloadGroupUserDocument,
    downloadProjectUserDocument,
  } = useDocumentDownload();

  const assertDocumentSigning = (type: enums.DocumentSigning): boolean => {
    return props.item.project.documentSigning === type;
  };

  const handleDownload = (item: DocumentItem) => {
    if (assertDocumentSigning(enums.DocumentSigning.Required)) {
      return downloadGroupUserDocument({
        groupId: item.group.id,
        userId: item.user.id,
      });
    }

    if (assertDocumentSigning(enums.DocumentSigning.ManuallyUploaded)) {
      return downloadProjectUserDocument({
        projectId: item.project.id,
        userId: item.user.id,
      });
    }
  };

  const shouldHideDownload = () => {
    return assertDocumentSigning(enums.DocumentSigning.NotRequired)
        || (assertDocumentSigning(enums.DocumentSigning.Required) && !props.item.documents.group)
        || (assertDocumentSigning(enums.DocumentSigning.ManuallyUploaded) && !props.item.documents.project);
  };

  if (shouldHideDownload()) return null;

  return (
    <Downloader
      filename={props.filename}
      item={props.item}
      onClick={handleDownload}>
      {props.children}
    </Downloader>
  );
}

export default DocumentDownload;