import { useCallback, useContext, useMemo, forwardRef } from 'react';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import { ImportListMatch } from '$admin/Search/Members/Action.ImportListMatch';
import { ProjectActionsMenuContext } from './Context';
import { AddPerson, BulkAdd, DownloadMembersCSV, Followup, Reject, RemovePerson, Reset, Invite, SendToClient, MarkActive } from './Item';
import { Item } from './interfaces';

type Props = {
  onAddMember?: Item.OnClick;
  showAddMember?: boolean;
  onDownloadMembersCSV?: Item.OnClick;
  showDownloadMembersCSV?: boolean;
  onInviteMembers?: Item.OnClick;
  showInviteMembers?: boolean;
  onBulkAddToAProject?: Item.OnClick;
  showBulkAddToAProject?: boolean;
  onFollowup?: Item.OnClick;
  showFollowup?: boolean;
  onRejectMembers?: Item.OnClick;
  showRejectMembers?: boolean;
  onRemoveMembers?: Item.OnClick;
  showRemoveMembers?: boolean;
  onResetMembers?: Item.OnClick;
  showResetMembers?: boolean;
  onSendMembersToClient?: Item.OnClick;
  showSendMembersToClient?: boolean;
  onActivateMembers?: Item.OnClick;
  showActivateMembers?: boolean;
  onImportListMatch?: (e: React.ChangeEvent<HTMLInputElement>) => unknown;
  showImportListMatch?: boolean;
};

export const Members = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { close } = useContext(ProjectActionsMenuContext);

  const closer = useCallback((handler: Item.OnClick) => () => {
    close();
    handler();
  }, [close]);

  const showDivider = useMemo(() =>
    props.showInviteMembers ||
    props.showBulkAddToAProject ||
    props.showFollowup ||
    props.showImportListMatch ||
    props.showRejectMembers ||
    props.showRemoveMembers ||
    props.showResetMembers ||
    props.showActivateMembers ||
    props.showSendMembersToClient,
  [
    props.showActivateMembers,
    props.showInviteMembers,
    props.showBulkAddToAProject,
    props.showFollowup,
    props.showImportListMatch,
    props.showRejectMembers,
    props.showRemoveMembers,
    props.showResetMembers,
    props.showSendMembersToClient,
  ]);

  return (
    <div ref={ref}>
      {props.showImportListMatch &&
        <MenuItem disableRipple>
          <ImportListMatch onChange={props.onImportListMatch} />
        </MenuItem>}
      {props.showAddMember && (
        <MenuItem
          disableRipple
          disabled={!props.onAddMember}
          onClick={closer(props.onAddMember)}>
          <AddPerson title="Add Expert" />
        </MenuItem>
      )}
      {props.showDownloadMembersCSV && (
        <MenuItem
          disableRipple
          disabled={!props.onDownloadMembersCSV}
          onClick={closer(props.onDownloadMembersCSV)}>
          <DownloadMembersCSV />
        </MenuItem>
      )}
      {showDivider && <Divider sx={{ margin: '8px 10px' }} />}
      {props.showBulkAddToAProject && (
        <MenuItem
          disableRipple
          disabled={!props.onBulkAddToAProject}
          onClick={closer(props.onBulkAddToAProject)}>
          <BulkAdd title="Mass Add to a Project" />
        </MenuItem>
      )}
      {props.showFollowup && (
        <MenuItem
          disableRipple
          disabled={!props.onFollowup}
          onClick={closer(props.onFollowup)}>
          <Followup title="Follow up" />
        </MenuItem>
      )}
      {props.showInviteMembers && (
        <MenuItem
          disableRipple
          disabled={!props.onInviteMembers}
          onClick={closer(props.onInviteMembers)}>
          <Invite />
        </MenuItem>
      )}
      {props.showSendMembersToClient && (
        <MenuItem
          disableRipple
          disabled={!props.onSendMembersToClient}
          onClick={closer(props.onSendMembersToClient)}>
          <SendToClient />
        </MenuItem>
      )}
      {props.showRejectMembers && (
        <MenuItem
          disableRipple
          disabled={!props.onRejectMembers}
          onClick={closer(props.onRejectMembers)}>
          <Reject />
        </MenuItem>
      )}
      {props.showRemoveMembers && (
        <MenuItem
          disableRipple
          disabled={!props.onRemoveMembers}
          onClick={closer(props.onRemoveMembers)}>
          <RemovePerson title="Remove" />
        </MenuItem>
      )}
      {props.showResetMembers && (
        <MenuItem
          disableRipple
          disabled={!props.onResetMembers}
          onClick={closer(props.onResetMembers)}>
          <Reset />
        </MenuItem>
      )}
      {props.showActivateMembers && (
        <MenuItem
          disableRipple
          disabled={!props.onActivateMembers}
          onClick={closer(props.onActivateMembers)}>
          <MarkActive />
        </MenuItem>
      )}
    </div>
  );
});