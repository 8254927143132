import type {
  CSSObjectWithLabel,
  StylesConfig as SelectStylesConfig,
  Theme as SelectTheme,
  ThemeConfig as SelectThemeConfig,
} from 'react-select';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function buildSelectStyles(): SelectStylesConfig<any> {
  return {
    control: ex => ({
      ...ex,
      borderColor: 'var(--gray-l)',
      borderWidth: '2px',
    } as CSSObjectWithLabel),
    menu: ex => ({
      ...ex,
      fontSize: '14px',
      fontFamily: 'var(--font-reg)',
      width: 'max-content',
      minWidth: '100%',
      zIndex: 2,
    } as CSSObjectWithLabel),
    container: ex => ({
      ...ex,
      maxWidth: '100%',
    } as CSSObjectWithLabel),
    valueContainer: ex => ({
      ...ex,
      overflow: 'hidden',
    } as CSSObjectWithLabel),
    singleValue: ex => ({
      ...ex,
      fontSize: '14px',
      fontFamily: 'var(--font-reg)',
    } as CSSObjectWithLabel),
    multiValue: ex => ({
      ...ex,
      fontFamily: 'var(--font-reg)',
    } as CSSObjectWithLabel),
    multiValueLabel: ex => ({
      ...ex,
      fontSize: '14px',
    } as CSSObjectWithLabel),
    multiValueRemove: ex => ({
      ...ex,
      fontSize: '14px',
    } as CSSObjectWithLabel),
    placeholder: ex => ({
      ...ex,
      fontSize: '14px',
      fontFamily: 'var(--font-reg)',
    } as CSSObjectWithLabel),
  };
}

export function buildSelectTheme(): SelectThemeConfig {
  return (selectTheme: SelectTheme) => {
    return {
      ...selectTheme,
      colors: {
        ...selectTheme.colors,
        // primary: theme.palette.sentiment.primary.main,
        // primary25: theme.palette.primary.light5,
        // primary50: theme.palette.primary.light4,
        // primary75: theme.palette.primary.light3,
        // danger: theme.palette.red.main,
      },
    };
  };
}