import { forwardRef, useCallback, useContext, useMemo } from 'react';
import { useToggle } from '@utils';
import { ImportOptionsCRM } from './ImportOptions.CRM';
import { ImportOptionsToggleViewContext } from './ImportOptions.Context';
import { ImportOptionsStartMenu } from './ImportOptions.StartMenu';
import * as Layout from './Layout';
import styles from './style/ImportOptions.css';

type Props = unknown;

export const ImportOptions = (props: Props) => {
  const [showCRM, toggle] = useToggle();

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Layout.Header>Import Contacts</Layout.Header>
        <Layout.Subheader>Easily upload or import contacts into Sentiment.</Layout.Subheader>
        <div className={styles.main}>
          <ImportOptionsToggleViewContext.Provider value={toggle}>
            {!showCRM
              ? <ImportOptionsStartMenu />
              : <ImportOptionsCRM />}
          </ImportOptionsToggleViewContext.Provider>
        </div>
      </div>
    </div>
  );
};

ImportOptions.displayName = 'ImportOptions';