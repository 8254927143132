import { useCallback, useState } from 'react';
import type { StarQuoteOptions } from '@utils/api';
import { useDeleteTranscriptMomentMutation, useStarTranscriptQuoteMutation } from '@utils/api';
import {
  SetQuoteMomentIdContext,
  TranscriptQuoteMomentIdContext,
  TranscriptToggleStarQuoteContext,
} from './context';

type Props = {
  initialQuoteMomentId?: number;
  range: {
    start: number;
    end: number;
  };
  transcriptId: number;
} & ChildrenProps;

export const TranscriptGoodQuoteContainer = ({
  initialQuoteMomentId,
  range,
  transcriptId,
  ...props
}: Props) => {

  const [quoteMomentId, setQuoteMomentId] = useState<number>(initialQuoteMomentId);

  const { mutateAsync: starQuote } = useStarTranscriptQuoteMutation({
    onSuccess: res => {
      setQuoteMomentId(res.tag.id);
    },
  });
  const { mutateAsync: deleteMoment } = useDeleteTranscriptMomentMutation({ transcriptId }, {
    onSuccess: () => {
      setQuoteMomentId(null);
    },
  });

  const handleStarQuote = useCallback((options?: StarQuoteOptions) => {
    return starQuote({
      fromTs: range.start,
      toTs: range.end,
      transcriptId,
    }, options);
  }, [starQuote, range, transcriptId]);

  const handleToggleStarQuote = useCallback(() => {
    if (quoteMomentId) {
      return deleteMoment({ momentId: quoteMomentId });
    } else {
      return handleStarQuote();
    }
  }, [quoteMomentId, deleteMoment, handleStarQuote]);

  return (
    <SetQuoteMomentIdContext.Provider value={setQuoteMomentId}>
      <TranscriptQuoteMomentIdContext.Provider value={quoteMomentId}>
        <TranscriptToggleStarQuoteContext.Provider value={handleToggleStarQuote}>
          {props.children}
        </TranscriptToggleStarQuoteContext.Provider>
      </TranscriptQuoteMomentIdContext.Provider>
    </SetQuoteMomentIdContext.Provider>
  );
};