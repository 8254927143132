import { useMemo } from 'react';
import { useSurveyBuilderItem, useSurveyBuilderState } from '@containers/SurveyBuilder';
import {
  SurveyAlternateImagesBuilderItemContext,
} from './context';
import { AlternateImagesBuilderDeleteContainer } from './DeleteContainer';

type Props =
  ChildrenProps;

export const SurveyBuilderAlternateImagesContainer = (props: Props) => {

  const [state] = useSurveyBuilderState();
  const item = useSurveyBuilderItem();

  const alternateImages = useMemo(() => {
    return state.survey.alternateImageExercises.find(f => f.identifier === item.source.identifier);
  }, [
    item.source.identifier,
    state.survey.alternateImageExercises,
  ]);

  return (
    <SurveyAlternateImagesBuilderItemContext.Provider value={alternateImages}>
      <AlternateImagesBuilderDeleteContainer>
        {props.children}
      </AlternateImagesBuilderDeleteContainer>
    </SurveyAlternateImagesBuilderItemContext.Provider>
  );
};