import { memo } from 'react';
import { Flag } from 'react-feather';
import type { MultipleChoiceAnswerValueProps } from './interfaces';
import { Rationale } from './Rationale';
import styles from './style/MultipleChoice.css';

type Props =
  MultipleChoiceAnswerValueProps;

export const MultipleChoiceAnswerValue = memo((props: Props) => {
  const answer = props.answer.items[0];

  const value = answer.option.text
    ? `${answer.option.value} - ${answer.option.text}`
    : answer.option.value;

  return (
    <>
      <div className={styles.root}>
        {value}
        {answer.option.flagged && <Flag className={styles.flag} />}
      </div>
      <Rationale value={props.rationale} />
    </>
  );
});

export default MultipleChoiceAnswerValue;
