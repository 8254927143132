import { useCallback, useEffect, useState, useMemo } from 'react';
import Header from './Header';
import Tags from './Tags';
import { GroupTagsContext, GroupTagsDispatchContext, GroupTagsUpdateContext, GroupTagsResetContext } from './Context';
import type { GroupSidebarTagsProps, GroupTagsContextValue } from './interfaces';

type Props
  = GroupSidebarTagsProps;

export default function GroupSidebarTags({ tags, onSave }: Props) {
  const [state, dispatch] = useState<GroupTagsContextValue>(mergeInitial({ editable: true, tags: tags ?? [] }));

  const handleReset = useCallback(() => {
    dispatch(state => ({ ...state, tags }));
  }, [tags]);

  useEffect(() => {
    dispatch(state => ({ ...state, tags }));
  }, [tags]);

  const canSave = useMemo(() => {
    return JSON.stringify(state.tags) !== JSON.stringify(tags);
  }, [state.tags, tags]);

  return (
    <GroupTagsContext.Provider value={state}>
      <GroupTagsResetContext.Provider value={handleReset}>
        <GroupTagsDispatchContext.Provider value={dispatch}>
          <GroupTagsUpdateContext.Provider value={[onSave, canSave]}>
            <Header />
            <Tags />
          </GroupTagsUpdateContext.Provider>
        </GroupTagsDispatchContext.Provider>
      </GroupTagsResetContext.Provider>
    </GroupTagsContext.Provider>
  );
}

function mergeInitial(initial: Partial<GroupTagsContextValue>) {
  return {
    ...GroupSidebarTags.initialState,
    ...initial,
  };
}

const initial: GroupTagsContextValue = {
  editable: false,
  editing: false,
  tags: [],
};

GroupSidebarTags.initialState = initial;

export { GroupSidebarTags };