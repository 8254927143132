import { ProjectCallsQueryContainer } from './Project.Calls.Query.Container';
import { ProjectCallsSchedulingContainer } from './Project.Calls.Scheduling.Container';

type Props = {
  children: React.ReactNode;
  enabled:  boolean;
} & IProjectId;

export const ProjectCallsContainer = (props: Props) => {
  return (
    <ProjectCallsQueryContainer
      enabled={!!props.projectId && props.enabled}
      projectId={props.projectId}>
      <ProjectCallsSchedulingContainer>
        {props.children}
      </ProjectCallsSchedulingContainer>
    </ProjectCallsQueryContainer>
  );
};

ProjectCallsContainer.displayName = 'Project.Calls.Container';