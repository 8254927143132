import { useMemo } from 'react';
import type { Column } from 'react-table';
import type { Worksheet  } from '@/types';
import { DataCell } from '@/components/Workbook/DataCell';
import type { RowData } from '@/components/Workbook/interfaces';

export const useWorksheetColumns = ({ columns }: Worksheet): Column<RowData>[] => {
  return useMemo(() => {

    return [
      {
        id: 'header',
        width: 45,
        disableResizing: true,
      },
      ...columns.map(column => ({
        id: column,
        Cell: DataCell,
        Header: column,
        minWidth: 50,
        width: 150,
      })),
    ];
  }, [columns]);

};