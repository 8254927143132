import { useCallback, useMemo } from 'react';
import { useAvailableConditionQuestions, useQuestionConditionContext } from '@containers/SurveyBuilder.Logic';
import { QuestionDropdown } from '@presentation/SurveyBuilder';
import styles from './style/Builder.Condition.Question.css';

export function ConditionQuestionDropdown() {

  const { item: condition, updateQuestion } = useQuestionConditionContext();
  const items = useAvailableConditionQuestions();

  const selectedItem = useMemo(() => {
    return items.find(f => f.base.identifier === condition.data.question.identifier);
  }, [condition.data.question.identifier, items]);

  if (!items.length) return <div className={styles.none}>No available questions.</div>;

  return (
    <div className={styles.questionDropdown}>
      <QuestionDropdown
        items={items}
        onSelect={updateQuestion}
        value={selectedItem} />
    </div>
  );
}