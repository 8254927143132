import { cx } from '@utils';
import styles from './style.css';

type Props = {
  className?: string;
  color?:     React.CSSProperties['color'];
  value:      number;
};

const LinearProgress = (props: Props) => {
  const width = `${props.value}%`;

  const stylecolor = props.color
      ? { backgroundColor: props.color }
      : {};

  return (
    <div
      className={cx(styles.linear, props.className)}
      style={{ ...stylecolor, width }} />
  );
};

export { LinearProgress };
export default LinearProgress;