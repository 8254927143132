import { useSelector } from 'react-redux';
import { Permission, Role } from '@/enums';
import type { BrandInsightsCapabilities } from '@/brand-insights/types';

const selectBrandInsightsCapabilities = (state: Store.State): BrandInsightsCapabilities => {
  return {
    canViewTraces: state.user.permissions.includes(Permission.BrandInsightsViewTraces),
    canViewExternalLinks: !state.user.roles.includes(Role.InsightsChatOnly),
  };
};

export const useBrandInsightsCapabilities = () => {
  const capabilities = useSelector(selectBrandInsightsCapabilities);

  return capabilities;
};