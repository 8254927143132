import { useCallback, useContext } from 'react';
import { Edit2 } from 'react-feather';
import { CallRole } from '@enums';
import { SchedulerCalendar } from '$admin/interfaces/project.scheduling';
import * as AvailabilitySchedule from './AvailabilitySchedule';
import { CalendarsContext, FrameContext, ParticipantsContext } from './Context';
import { useSchedulerCallRoleSelector } from './hooks';
import { FrameKey } from './interfaces';
import styles from './style/Booking.ViewAvailability.css';

type Props = unknown;

export const Schedulers = (props: Props) => {
  const ctx = {
    calendars: useContext(CalendarsContext),
  };

  const selectCallRole = useSchedulerCallRoleSelector();

  const renderHeader = useCallback((item: SchedulerCalendar) => {
    const role = selectCallRole(item.email);

    return (
      <Header
        item={item}
        role={role} />
    );
  }, [selectCallRole]);

  const findSuggestedTimes = useCallback((user: Pick<SchedulerCalendar, 'email' | 'id'>) => {
    const suggested = ctx.calendars.query.data?.suggested || [];
    const role = selectCallRole(user.email);

    if (role.id !== CallRole.Scheduler) return [];

    return suggested.find(x => x.userId === user.id)?.items || [];

  }, [
    ctx.calendars.query.data?.suggested,
    selectCallRole,
  ]);

  return (
    <>
      {ctx.calendars.query.data.items.map(item =>
        <AvailabilitySchedule.Section
          data={item}
          items={item.events.filter(x => !x.isBusy)}
          key={item.email}
          renderEmpty={() => <div className={styles.empty}>None Provided</div>}
          renderHeader={renderHeader}
          suggested={findSuggestedTimes(item)} />)}
    </>
  );
};

Schedulers.displayName = 'Booking.ViewAvailability.Schedulers';

type HeaderProps = {
  item: SchedulerCalendar;
  role: Descriptor;
};

const Header = (props: HeaderProps) => {
  const ctx = {
    frame: useContext(FrameContext),
  };

  return (
    <div className={styles.header}>
      <div className={styles.content}>
        <div className={styles.label}>
          <div className={styles.title}>{`${props.item.name} (${props.role.name})`}</div>
          {props.role.id === CallRole.Scheduler &&
            <button
              className={styles.edit}
              onClick={() => ctx.frame.goToFrame({ frame: FrameKey.ModeratorSchedulers })}>
              <Edit2
                color={`var(--hyperlink)`}
                size={18} />
            </button>}
        </div>
        <AvailabilitySchedule.Action.AddTimes onClick={() => ctx.frame.goToFrame({
          data: props.item.email,
          frame: FrameKey.AddAvailability,
        })} />
      </div>
    </div>
  );
};

Header.displayName = 'Booking.ViewAvailability.Schedulers.Header';