import { useCallback } from 'react';
import { useSurveyQuestionConditionValue, useConditionQuestion } from '@containers/SurveyBuilder.Logic';
import { OptionsDropdown as Dropdown } from '@presentation/SurveyBuilder';
import { SurveyLogic } from '@/types';
import styles from './style/Builder.Condition.Question.css';

export const OptionsDropdown = () => {

  const [condition, updateConditionValue] = useSurveyQuestionConditionValue<SurveyLogic.ConditionWithOption>();

  const question = useConditionQuestion();
  const selectedOption = question.options.find(f => f.base.identifier === condition.value?.option?.identifier);

  const handleUpdate = useCallback((optionIdentifier: string) => {
    updateConditionValue({
      ...condition.value,
      option: { identifier: optionIdentifier },
    });
  }, [updateConditionValue, condition]);

  return (
    <div className={styles.optionsDropdown}>
      <Dropdown
        items={question.options}
        onSelect={handleUpdate}
        value={selectedOption} />
    </div>
  );
};