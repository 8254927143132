import { useCallback, ChangeEvent } from 'react';
import { SurveyTemplate } from '@/types/survey';
import { Input } from '@/components/Input';
import styles from './style/Input.PriceLinks.css';

type Props = {
  onChange: (key: keyof SurveyTemplate.AdditionalTPPPriceLinks) => (value: string) => void;
  value: SurveyTemplate.AdditionalTPPPriceLinks;
};

export const AdditionPriceInputs = ({ onChange, value }: Props) => {

  const handleChange = useCallback((key: keyof SurveyTemplate.AdditionalTPPPriceLinks) => (e: ChangeEvent<HTMLInputElement>) => {
    onChange(key)(e.target.value);
  }, [onChange]);

  return (
    <>
      <div className={styles.title}>Canada</div>
      <Input
        classes={{
          root: styles.input,
        }}
        onChange={handleChange('canada')}
        value={value.canada} />
      <div className={styles.title}>France</div>
      <Input
        classes={{
          root: styles.input,
        }}
        onChange={handleChange('france')}
        value={value.france} />
      <div className={styles.title}>Germany</div>
      <Input
        classes={{
          root: styles.input,
        }}
        onChange={handleChange('germany')}
        value={value.germany} />
      <div className={styles.title}>Italy</div>
      <Input
        classes={{
          root: styles.input,
        }}
        onChange={handleChange('italy')}
        value={value.italy} />
      <div className={styles.title}>United Kingdom</div>
      <Input
        classes={{
          root: styles.input,
        }}
        onChange={handleChange('uk')}
        value={value.uk} />
      <div className={styles.title}>Spain</div>
      <Input
        classes={{
          root: styles.input,
        }}
        onChange={handleChange('spain')}
        value={value.spain} />
    </>
  );
};