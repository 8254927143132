import extend from '@enums/extend';

export enum InvoiceStatus {
  Draft = 1,
  Open = 2,
  Pending = 3,
  Paid = 4,
  Refunded = 5,
  Failed = 6,
  Void = 7,
}

const map = new Map([
  [InvoiceStatus.Draft,    { name: 'Draft' }],
  [InvoiceStatus.Open,     { name: 'Open' }],
  [InvoiceStatus.Pending,  { name: 'Pending' }],
  [InvoiceStatus.Paid,     { name: 'Paid' }],
  [InvoiceStatus.Refunded, { name: 'Refunded' }],
  [InvoiceStatus.Failed,   { name: 'Failed' }],
  [InvoiceStatus.Void,     { name: 'Void' }],
]);

export default {
  InvoiceStatus: extend(map),
};