import { useCallback } from 'react';
import type { DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { Droppable } from 'react-beautiful-dnd';
import { cx } from '@utils';
import { useAltRankingFormContext } from './context';
import type { AltRankingForm } from './interfaces';
import { DraggableDropAreaItem } from './Ranking.Alt.DropArea.Item';
import { DestinationDroppableId } from './utils.ranking.alt';
import styles from './style/Ranking.Alt.DropArea.css';

type Props = {
  className?: string;
  items: AltRankingForm.DraggableItem[];
};

export const DestinationDropArea = ({
  className,
  items,
}: Props) => {

  const { onRemoveFromList } = useAltRankingFormContext();

  const renderItems = useCallback(() => {
    return items.map(item => (
      <DraggableDropAreaItem
        key={item.id}
        onRemove={onRemoveFromList(item.id)}
        className={styles.item}
        item={item} />
    ));
  }, [items, onRemoveFromList]);

  const computeClassName = useCallback((snapshot: DroppableStateSnapshot) => {
    return cx(styles.root, className, {
      [styles.draggingOver]: snapshot.isDraggingOver || !!snapshot.draggingFromThisWith,
    });
  }, [
    className,
  ]);

  return (
    <Droppable
      droppableId={DestinationDroppableId}>
      {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
        <div
          className={computeClassName(snapshot)}>
          <div className={styles.wrap}>
            <div className={styles.title}>
              Drag your choices here to rank them ({items.length} ranked)
            </div>
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={styles.droparea}>
              {renderItems()}
              {provided.placeholder}
            </div>
          </div>
        </div>
      )}
    </Droppable>
  );
};