import { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { UseQueryOptions, useQuery, useQueryClient } from '@tanstack/react-query';
import * as api from '@api';
import { TranscribeRT } from '@/types';
import { TaggedMoment } from '@/types/transcribe.rich-text';

type KeyProps = {
  includeContent: boolean;
  tagIds?: number[];
} & ITranscriptId;

type Props = KeyProps;

function buildKey(data: KeyProps): QK {
  return ['transcript-tags', data] as const;
}

export const useTranscriptionTaggedMoments = (props: Props, options: Options = {}) => {

  const query = useQuery(buildKey(props), ({ queryKey }) => {
    const data = queryKey[1];

    return api.transcripts.getTranscriptTaggedMoments(data)
      .then(res => res.items);
  }, options);

  return query;
};

type Data = TranscribeRT.TaggedMoment[];
type QK = readonly [string, KeyProps];

type Options = UseQueryOptions<Data, AxiosResponse, Data, QK>;

type UpdateFn = (old: TaggedMoment[]) => TaggedMoment[];

export const useUpdateTranscriptionTags = (props: KeyProps) => {
  const queryClient = useQueryClient();

  return useCallback((updateFn: UpdateFn) => {
    queryClient.setQueryData(buildKey(props), updateFn);
  }, [queryClient, props]);
};