import { useCallback } from 'react';
import { useGroupTagsDispatch } from './hooks';
import styles from './style.css';

export default function Add() {
  const dispatch = useGroupTagsDispatch();

  const handleClick = useCallback(() => {
    dispatch(state => ({ ...state, editing: true }));
  }, [dispatch]);

  return (
    <button className={styles.add} onClick={handleClick}>
      + Add
    </button>
  );
}

export { Add };