import { memo } from 'react';

type Props = {
  className?: string;
  size?: number;
};

export const InsightsIcon = memo(({ className, size = 46 }: Props) => {

  return (
    <svg
      className={className}
      width={size}
      height={size}
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 32 32"
      enableBackground="new 0 0 32 32"
      xmlSpace="preserve">
      <path
id="Vision_2_"
d="M4,7.36C2.147,7.36,0.64,5.853,0.64,4S2.147,0.64,4,0.64S7.36,2.147,7.36,4S5.853,7.36,4,7.36z M4,1.36
	C2.544,1.36,1.36,2.544,1.36,4S2.544,6.64,4,6.64S6.64,5.456,6.64,4S5.456,1.36,4,1.36z M28,31.36c-1.853,0-3.36-1.508-3.36-3.36
	s1.508-3.36,3.36-3.36s3.36,1.508,3.36,3.36S29.853,31.36,28,31.36z M28,25.36c-1.456,0-2.64,1.184-2.64,2.64s1.184,2.64,2.64,2.64
	s2.64-1.184,2.64-2.64S29.456,25.36,28,25.36z M4,29.36c-0.75,0-1.36-0.61-1.36-1.36S3.25,26.64,4,26.64S5.36,27.25,5.36,28
	S4.75,29.36,4,29.36z M4,27.36c-0.353,0-0.64,0.287-0.64,0.64S3.647,28.64,4,28.64S4.64,28.353,4.64,28S4.353,27.36,4,27.36z
	 M28,5.36c-0.75,0-1.36-0.61-1.36-1.36S27.25,2.64,28,2.64S29.36,3.25,29.36,4S28.75,5.36,28,5.36z M28,3.36
	c-0.353,0-0.64,0.287-0.64,0.64S27.647,4.64,28,4.64S28.64,4.353,28.64,4S28.353,3.36,28,3.36z M16,21.36
	c-2.956,0-5.36-2.405-5.36-5.36c0-2.956,2.404-5.36,5.36-5.36c2.955,0,5.36,2.404,5.36,5.36C21.36,18.955,18.955,21.36,16,21.36z
	 M16,11.36c-2.559,0-4.64,2.082-4.64,4.64s2.082,4.64,4.64,4.64s4.64-2.081,4.64-4.64S18.559,11.36,16,11.36z M16,19.36
	c-1.853,0-3.36-1.508-3.36-3.36s1.507-3.36,3.36-3.36s3.36,1.507,3.36,3.36S17.853,19.36,16,19.36z M16,13.36
	c-1.456,0-2.64,1.185-2.64,2.64c0,1.456,1.185,2.64,2.64,2.64c1.456,0,2.64-1.184,2.64-2.64S17.456,13.36,16,13.36z M28,0.64
	c-1.853,0-3.36,1.507-3.36,3.36c0,0.797,0.29,1.519,0.755,2.096L21.55,9.941C20.038,9.174,18.208,8.64,16,8.64
	c-8.143,0-11.3,7.146-11.331,7.218c-0.039,0.09-0.039,0.193,0,0.283c0.021,0.05,1.524,3.451,5.114,5.566l-3.688,3.687
	C5.519,24.93,4.796,24.64,4,24.64c-1.853,0-3.36,1.508-3.36,3.36S2.147,31.36,4,31.36S7.36,29.853,7.36,28
	c0-0.796-0.29-1.519-0.755-2.096l3.845-3.845c1.512,0.767,3.342,1.301,5.55,1.301c8.144,0,11.3-7.146,11.331-7.219
	c0.039-0.09,0.039-0.193,0-0.283c-0.021-0.05-1.524-3.45-5.114-5.566l3.687-3.688C26.481,7.07,27.204,7.36,28,7.36
	c1.853,0,3.36-1.507,3.36-3.36S29.853,0.64,28,0.64z M6.64,28c0,1.456-1.185,2.64-2.64,2.64S1.36,29.456,1.36,28
	S2.544,25.36,4,25.36S6.64,26.544,6.64,28z M26.603,16c-0.48,0.977-3.568,6.64-10.603,6.64S5.877,16.977,5.398,16
	C5.877,15.023,8.966,9.36,16,9.36S26.122,15.023,26.603,16z M28,6.64c-0.645,0-1.229-0.242-1.688-0.628l-0.32-0.32
	C25.603,5.233,25.36,4.647,25.36,4c0-1.456,1.184-2.64,2.64-2.64S30.64,2.544,30.64,4S29.456,6.64,28,6.64z" />
      <rect
id="_Transparent_Rectangle"
fill="none"
width="32"
height="32" />
    </svg>
  );
});