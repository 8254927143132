import { useCallback, useContext } from 'react';
import { useEditorState } from '@remirror/react';
import { TranscriptIdContext } from '@/containers/Transcript/context';
import { useTranscriptTagsContext } from '@/containers/Transcript.Tagging/hooks';
import { useStarTranscriptQuoteMutation } from '@utils/api';
import type { HighlightWithText } from '../interfaces.extension.highlight';
import { TranscriptChainedCommandsContext } from '../context';
import { useMapDocPosToTimePos, useMapTimePosToDocPos } from './useMapPositions';
import { useBuildTagContent } from './useBuildTagContent';

export const useMarkGoodQuote = (highlight: HighlightWithText) => {

  const commands = useContext(TranscriptChainedCommandsContext);
  const buildTagContent = useBuildTagContent();
  const mapDocPosToTimePos = useMapDocPosToTimePos();
  const mapTimePosToDocPos = useMapTimePosToDocPos();
  const transcriptId = useContext(TranscriptIdContext);
  const state = useEditorState();
  const [_, dispatch] = useTranscriptTagsContext();

  const { mutateAsync } = useStarTranscriptQuoteMutation({
    onSuccess: res => {
      const { from, to } = mapTimePosToDocPos(res.tag);
      commands.addHighlight({
        id: res.tag.identifier,
        color: res.tag.color,
        from,
        to,
        dbId: null,
        derived: true,
      }).removeHighlights([highlight.id]).run();
      dispatch({
        type: 'tag-added',
        tag: res.tag,
      });
    },
  });

  return useCallback(() => {
    const { start: fromTs, end: toTs } = mapDocPosToTimePos({
      from: highlight.from,
      to: highlight.to,
      state,
    });
    const content = buildTagContent(fromTs, toTs);
    return mutateAsync({
      content,
      fromTs,
      toTs,
      transcriptId,
    });
  }, [
    buildTagContent,
    highlight?.from,
    highlight?.to,
    mapDocPosToTimePos,
    mutateAsync,
    state,
    transcriptId,
  ]);
};