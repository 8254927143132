import { isOptionInCondition, isQuestionInCondition, isRowInCondition } from '@/containers/SurveyBuilder.Logic/utils.lookup';
import { SurveyLogicRule } from '@/enums';
import type {
  SurveyBuilder,
  SurveyTaggingBuilder,
  SurveyQuestionsBuilder,
  SurveySectionsBuilder,
  SurveyOptionsBuilder,
  SurveyRowsBuilder,
} from '../interfaces';
import { updateQuestionInCondition } from './state.conditions';

type State = SurveyTaggingBuilder.State;

export function taggingItemAdded(state: State, { payload }: SurveyTaggingBuilder.ItemAdded.Action): State {
  return state.concat({
    id: null,
    conditions: [],
    action: {
      type: null,
      value: null,
    },
    rule: {
      expression: null,
      type: SurveyLogicRule.AllOfTheAbove,
    },
    ...payload,
    identifier: payload.identifier,
  });
}

export function taggingItemRemoved(state: State, { payload }: SurveyTaggingBuilder.ItemRemoved.Action): State {
  return state.filter(f => f.identifier !== payload.identifier);
}

export function taggingItemSaved(state: State, { payload }: SurveyTaggingBuilder.ItemSaved.Action): State {
  return state.reduce((acc, x) => {
    if (x.identifier === payload.value.identifier) {
      acc.push(payload.value);
    } else {
      acc.push(x);
    }

    return acc;
  }, []);
}

export function taggingEditingToggledOff(state: SurveyBuilder.State, { payload }: SurveyTaggingBuilder.EditingToggledOff.Action): State {
  return state.editing.tagging.isNew
    ? state.survey.tagging.filter(f => f.identifier !== payload.identifier)
    : state.survey.tagging;
}

export function taggingEditingToggledOn(state: SurveyBuilder.State, { payload }: SurveyTaggingBuilder.EditingToggledOn.Action): State {
  return state.survey.tagging;
}

export function sectionRemoved(state: SurveyBuilder.State, action: SurveySectionsBuilder.RemoveSection.Action): State {
  const removedQuestionIdentifiers = state.survey.questions
    .filter(f => f.section.identifier === action.identifier)
    .map(m => m.base.identifier);

  return state.survey.tagging.filter(item => {

    const questionInConditions = item.conditions.some(condition => {
      return removedQuestionIdentifiers.some(questionIdentifier => isQuestionInCondition({
        condition,
        question: { identifier: questionIdentifier },
      }));
    });

    if (questionInConditions) return false;

    return true;
  });
}

export function questionRemoved(state: State, action: Omit<SurveyQuestionsBuilder.QuestionRemoved.State, 'itemIdentifier'>): State {
  return state.filter(item => {

    const questionInConditions = item.conditions.some(condition => isQuestionInCondition({
      condition,
      question: { identifier: action.questionIdentifier },
    }));

    if (questionInConditions) return false;

    return true;
  });
}

export function questionTypeUpdated(state: State, action: SurveyQuestionsBuilder.UpdateQuestionType.Action): State {
  return questionRemoved(state, { questionIdentifier: action.questionIdentifier });
}

export function questionIdentifierUpdated(state: State, action: SurveyQuestionsBuilder.UpdateQuestionIdentifier.Action): State {

  return state.reduce((acc, x) => {
    const conditions = x.conditions.map(m => updateQuestionInCondition(m, action.oldIdentifier, action.newIdentifier));

    return acc.concat({
      ...x,
      conditions,
    });
  }, []);
}

export function rowRemoved(state: State, action: SurveyRowsBuilder.RemoveRow.Action): State {
  return state.filter(item => {
    return !item.conditions.some(condition => isRowInCondition({
      condition,
      row: action.row,
    }));
  });
}

export function optionRemoved(state: State, action: SurveyOptionsBuilder.RemoveOption.Action): State {
  return state.filter(item => {
    return !item.conditions.some(condition => isOptionInCondition({
      condition,
      option: action.option,
    }));
  });
}