import { useMemo } from 'react';
import { path } from '@consts';
import type { SceneMap } from '@screens/Workspace.File/interfaces';
import { RouteKey } from '@screens/Workspace.File/interfaces';
import { FileTabSummary } from '@screens/Workspace.File/File.Tab.Summary';
import { FileTabDetails } from '@screens/Workspace.File/File.Tab.Details';
import { FileTabReferences } from '@screens/Workspace.File/File.Tab.References';
import { ProjectFileTabPreview } from './Tab.Preview';

export const useProjectFileTabMap = () => {

  const map: SceneMap = useMemo(() => ({
    [RouteKey.Preview]: {
      component: ProjectFileTabPreview,
      path: path.ProjectFileTabs.Preview,
      title: 'Preview',
    },
    [RouteKey.Summary]: {
      component: FileTabSummary,
      path: path.ProjectFileTabs.Summary,
      title: 'Summary',
    },
    [RouteKey.Details]: {
      component: FileTabDetails,
      path: path.ProjectFileTabs.Details,
      title: 'Details',
    },
    [RouteKey.References]: {
      component: FileTabReferences,
      path: path.ProjectFileTabs.References,
      title: 'References',
    },
  }), []);

  return map;
};