import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useSelectUser } from '@containers/Store';
import { hasClientRole, hasComplianceRole } from '@utils';
import { Checkbox } from '@/components/Checkbox';
import styles from '@/components/Modal/style/SendMessage.css';

type Props = {
  paid: boolean;
  onChange: () => unknown;
  onSetupPayment: () => unknown;
};

export function AsPaidMessage({ paid, onChange, onSetupPayment }: Props) {
  const hasPaymentMethod = false;

  if (!hasPaymentMethod) {
    return <RequiresPaymentMethod onSetupPayment={onSetupPayment} />;
  }

  return (
    <div className={styles.checkbox} onClick={onChange}>
      <Checkbox
        style={{ color: 'var(--pri-02)' }}
        icon={<CheckBoxOutlineBlankIcon style={{ height: 22, width: 22 }} />}
        checkedIcon={<CheckBoxIcon style={{ height: 22, width: 22 }} />}
        checked={paid} />
      <span className={styles.paid}>Send as Paid Message</span>
    </div>
  );
}

type RequiresPaymentMethodProps =
    Pick<Props, 'onSetupPayment'>;

function RequiresPaymentMethod({ onSetupPayment }: RequiresPaymentMethodProps) {
  const user = useSelectUser();
  const isClient = hasClientRole(user);
  const hasBillingPermission = hasComplianceRole(user);

  if (isClient && !hasBillingPermission) {
    return null;
    // return (
    //   <div className={styles.contactAdmin}>
    //     Contact your account admin to setup Paid Messages
    //   </div>
    // );
  }

  return (
    <div className={styles.setup} onClick={onSetupPayment}>
      Set up a billing account to send Paid Messages
    </div>
  );
}

export default AsPaidMessage;