import { useContext, useMemo } from 'react';
import { useSelectUser } from '@containers/Store';
import { ProjectAccessQueryContext } from '@/components/Project.Access/Context';

export const useOwnProjectAccessRole = () => {
  const query = useContext(ProjectAccessQueryContext);
  const user = useSelectUser();

  const access = useMemo(() => {
    return query.isFetched && query.data?.items
      ? query.data.items.find(x => x.id === user.id)?.roleId
      : null;
  }, [
    query,
    user,
  ]);

  return access;
};