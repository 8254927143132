import { useMemo } from 'react';
import { useTranscriptEnhancementState } from './useTranscriptEnhancementState';

export const useTranscriptToolsPermission = (): TranscriptToolPermission => {
  const state = useTranscriptEnhancementState();

  const permission = useMemo(() => {
    const has = {
      role: state.user.isAssignee && state.status.isInProgress,
      permission: state.user.hasTranscriptWritePermission && !state.status.isInProgress,
    };

    return {
      commenting: has.permission,
      entity: has.role || has.permission,
      highlight: has.permission,
      redaction: has.role || has.permission,
      replace: has.role || has.permission,
      speaker: has.role || has.permission,
      tagging: has.permission,
    };
  }, [
    state,
  ]);

  return permission;
};

type TranscriptToolPermission = {
  commenting: boolean;
  entity:     boolean;
  highlight:  boolean;
  redaction:  boolean;
  replace:    boolean;
  speaker:    boolean;
  tagging:    boolean;
};

export const useTranscriptToolsEnabled = () => {
  const permission = useTranscriptToolsPermission();

  return useMemo(() => {
    return Object.values(permission).some(Boolean);
  }, [permission]);
};