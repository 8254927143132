import { useCallback, useContext } from 'react';
import { addDays } from 'date-fns';
import * as utils from '@utils';
import * as api from '$website/api';

type Params = unknown;

const useSignupPing = () => {

  return useCallback(() => {
    const pinged = utils.getCookie('signup-ping');

    if (!pinged) {
      utils.setCookie({
        key: 'signup-ping',
        value: `${Date.now()}`,
        expires: addDays(new Date(), 1),
      });

      api.visitors.ping();
    }

  }, []);

};

export { useSignupPing };
export default useSignupPing;