import { useContext, useMemo } from 'react';
import { ProjectResponseFilterContext, SurveyResponsesFilterDataContext } from '@containers/GroupProject/Context';
import { filterRepeatResponses } from '@containers/GroupProject/utils/survey-filtering';
import { useSurvey } from '@containers/Survey/hooks/useSurvey';
import { RepeatSurveyResponseDataContext } from '../Context';

const useRepeatSurveyData = () => {
  const survey = useSurvey();
  const context = useContext(RepeatSurveyResponseDataContext);
  const filterContext = useContext(ProjectResponseFilterContext);
  const filterDataContext = useContext(SurveyResponsesFilterDataContext);
  const filteredData = useMemo(() => {
    if (filterContext?.filter?.filters?.length) {
      return filterRepeatResponses({
        filter: filterContext.filter, questions: survey.questions, repeatData: context, allData: { responses: filterDataContext.allResponses, questions: filterDataContext.allQuestions },
      });
    } else {
      return context;
    }
  }, [survey.questions, filterContext.filter, context, filterDataContext.allResponses, filterDataContext.allQuestions]);

  return filteredData;
};

export { useRepeatSurveyData };
export default useRepeatSurveyData;