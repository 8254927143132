import { useCallback, useMemo, useState } from 'react';
import { GrayOutlineButton } from '@presentation/Buttons';
import { SurveySettings } from '@/types';
import { OperatorDropdown } from '@presentation/SurveyBuilder';
import { Button } from '@/components/Button';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import ModalHeader from '@/components/Modal/Header';
import { useModal } from '@/components/Modal/hooks';
import { TotalsNumberInput } from './Settings.Total.NumberInput';
import styles from './style/Settings.Total.Modal.css';

type Props = {
  initialValue: SurveySettings.TotalSetting<number>;
  onSubmit: (value: SurveySettings.TotalSetting<number>) => void;
} & Pick<ModalProps,
    'open' |
    'onClose'>;

export const TotalNumberValueModal = ({ initialValue, onClose, onSubmit, open }: Props) => {

  const [value, setValue] = useState<number>(initialValue.comparate);
  const [operator, setOperator] = useState<Operator>(initialValue.operator);

  const canSubmit = useMemo(() => {
    return !!value && !!operator;
  }, [operator, value]);

  const handleSubmit = useCallback(() => {
    onSubmit({
      comparate: value,
      operator,
    });
    onClose();
  }, [operator, onClose, onSubmit, value]);

  return (
    <Modal
      hideCloseIcon={true}
      className={styles.numberModal}
      open={open}
      onClose={onClose}>

      <ModalHeader text="Required Sum" />

      <div className={styles.body}>
        <div className={styles.row}>
          <div className={styles.label}>Operator:</div>
          <div className={styles.operatorDropdown}>
            <OperatorDropdown
              value={operator}
              onSelect={setOperator} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>Value:</div>
          <TotalsNumberInput
            onChange={setValue}
            value={value} />
        </div>
      </div>

      <div className={styles.footer}>
        <GrayOutlineButton
          onClick={onClose}
          title="Cancel" />
        <Button.Primary
          className={styles.submit}
          disabled={!canSubmit}
          onClick={handleSubmit}
          title="Submit"
          variant="brick" />
      </div>

    </Modal>
  );
};

export const useTotalNumberValueModal = () => useModal(TotalNumberValueModal);