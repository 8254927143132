import { useCallback } from 'react';
import { useSurveyBuilderState } from './useSurveyBuilderContext';

export const useHasUncommittedQuestionChanges = () => {
  const [state] = useSurveyBuilderState();

  const hasUncommittedQuestionChanges = useCallback(() => {
    return JSON.stringify(state.survey.questions) !== JSON.stringify(state.committed.questions);
  }, [
    state.committed.questions,
    state.survey.questions,
  ]);

  return hasUncommittedQuestionChanges;
};

export const useHasUncommittedMessageChanges = () => {
  const [state] = useSurveyBuilderState();

  const hasUncommittedMessageChanges = useCallback(() => {
    return JSON.stringify(state.survey.messages) !== JSON.stringify(state.committed.messages);
  }, [
    state.committed.messages,
    state.survey.messages,
  ]);

  return hasUncommittedMessageChanges;
};

export const useHasUncommittedAIEChanges = () => {
  const [state] = useSurveyBuilderState();

  const hasUncommittedAIEChanges = useCallback(() => {
    return JSON.stringify(state.survey.alternateImageExercises) !== JSON.stringify(state.committed.alternateImageExercises);
  }, [
    state.committed.alternateImageExercises,
    state.survey.alternateImageExercises,
  ]);

  return hasUncommittedAIEChanges;

};

export const useHasUncommittedQuotaChanges = () => {
  const [state] = useSurveyBuilderState();

  const hasUncommittedQuotaChanges = useCallback(() => {
    return JSON.stringify(state.survey.quotas) !== JSON.stringify(state.committed.quotas);
  }, [
    state.committed.quotas,
    state.survey.quotas,
  ]);

  return hasUncommittedQuotaChanges;
};

export const useHasUncommittedChanges = () => {
  const [state] = useSurveyBuilderState();

  const hasUncommittedChanges = useCallback(() => {
    return JSON.stringify(state.survey.quotas) !== JSON.stringify(state.committed.quotas) ||
      JSON.stringify(state.survey.logic) !== JSON.stringify(state.committed.logic) ||
      JSON.stringify(state.survey.questions) !== JSON.stringify(state.committed.questions) ||
      JSON.stringify(state.survey.tagging) !== JSON.stringify(state.committed.tagging)
    ;
  }, [
    state.committed.logic,
    state.committed.questions,
    state.committed.quotas,
    state.committed.tagging,
    state.survey.logic,
    state.survey.questions,
    state.survey.quotas,
    state.survey.tagging,
  ]);

  return hasUncommittedChanges;
};