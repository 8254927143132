import type { CellProps, HeaderProps } from 'react-table';
import { Check } from 'react-feather';
import * as Pricing from '$website/containers/Pricing/interfaces';
import { PlanButton } from './Plan.Button';
import styles from './style/Column.Plan.css';

export const Cell = (props: CellProps<Pricing.Row>) => {
  const cell = props.row.original[props.column.id] as Pricing.PlanCell;

  if (cell.type === 'spacer') return '';

  if (cell.type !== 'feature') return cell.value.toString();

  if (cell.included) {
    return (
      <Check className={styles.check} />
    );
  } else {
    return '';
  }
};

Cell.displayName = 'Pricing.Column.Plan.Cell';

export const Footer = (props: CellProps<Pricing.PlanCell>) => {
  const id = props.column.id as Exclude<Pricing.ColumnIdentifer, Pricing.ColumnIdentifer.Service>;

  return (
    <div className={styles.launch}>
      <PlanButton id={id} />
    </div>
  );
};

Footer.displayName = 'Pricing.Column.Plan.Footer';

export const Header = (props: HeaderProps<Pricing.Row>) => {
  const plan = props.plans[props.column.id] as Pricing.Plan;
  const bg = {
    [Pricing.ColumnIdentifer.Service]: 'var(--pri-01)',
    [Pricing.ColumnIdentifer.Customer]: '#f9fbff',
    [Pricing.ColumnIdentifer.Market]: '#f3f8ff',
    [Pricing.ColumnIdentifer.Enterprise]: '#f9fbff',
  };

  const payment = props.monthly
    ? plan.payment.monthly
    : plan.payment.yearly;

  return (
    <>
      <div className={styles.name}>
        {plan.name}
      </div>
      <div className={styles.description}>
        {plan.description}
      </div>
      <div
        className={styles.payment}
        style={{ backgroundColor: bg[plan.id] }}>
        ${payment}
      </div>
      <div
        className={styles.rate}
        style={{ backgroundColor: bg[plan.id] }}>
        {`per user / monthly`}
      </div>
    </>
  );
};

Header.displayName = 'Pricing.Column.Plan.Header';