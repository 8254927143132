import { useCallback } from 'react';
import { format } from 'date-fns';
import { ConferenceTagSummaryItem, ConferenceTagSummary } from '@/types/conferences.tags';
import { useToggle } from '@/utils';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import { Header } from '@/components/Modal/Header';
import { SummaryFeedback, ViewCitations, ViewFeedback } from './Feedback';
import { SummaryContent } from './TagSummary';
import styles from './style/Modal.Versions.css';

type Props = {
  summaryItem: ConferenceTagSummaryItem;
  versions: ConferenceTagSummary[];
} & Pick<ModalProps, 'open' | 'onClose'>;

type VersionProps = {
  item: ConferenceTagSummaryItem;
  ordinal: number;
};

const TagSummaryVersion = ({ item, ordinal }: VersionProps) => {

  const [open, toggleOpen] = useToggle(false);

  const generatedOn = format(item.summary.generatedOn, 'MM/dd/yyyy hh:mm a');

  return (
    <div key={item.summary.id} className={styles.version}>
      <div className={styles.header}>
        <div className={styles.title}>
          <div className={styles.ordinal} onClick={toggleOpen}>Version #{item.summary.ordinal}</div>
          <div className={styles.generatedOn}>{generatedOn}</div>
        </div>
        <div className={styles.actions}>
          <ViewFeedback item={item} />
        </div>
      </div>

      {open && (
        <>
          <SummaryContent item={item.summary} />

          <div className={styles.footer}>
            <ViewCitations item={item} />
            <SummaryFeedback item={item} />
          </div>
        </>
      )}
    </div>
  );
};

export const TagSummaryVersionsModal = ({
  summaryItem,
  open,
  onClose,
  versions,
}: Props) => {

  const renderVersion = useCallback((item: ConferenceTagSummary, index: number) => {

    const mergedItem = {
      ...summaryItem,
      summary: item,
    };

    return (
      <TagSummaryVersion
        key={item.id}
        ordinal={index + 1}
        item={mergedItem} />
    );
  }, [summaryItem]);

  const title = `${summaryItem.tag.name}`;

  return (
    <Modal
      open={open}
      onClose={onClose}>

      <Header text={title} />

      <div>
        {versions.map(renderVersion)}
      </div>

    </Modal>
  );
};