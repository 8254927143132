import { useCallback, useContext } from 'react';
import type { HeaderContext } from '@tanstack/react-table';
import type { MouseEventHandler } from 'react';
import { ProjectClipsSortContext } from '@/containers/Project.Clips';
import type { ProjectClips } from '@/types';
import { SortableHeader } from './Header.Sortable';
import { useIsColumnSorted } from './hooks/useIsColumnSorted';

type Props = HeaderContext<ProjectClips.Item, unknown>;

export const GoodQuoteHeader = ({ column }: Props) => {

  const { onSortClick } = useContext(ProjectClipsSortContext);
  const hasSortApplied = useIsColumnSorted(column.id);

  const handleClick: MouseEventHandler = useCallback(e => {
    onSortClick(column.id);
  }, [column.id, onSortClick]);

  return (
    <SortableHeader
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      className={column.columnDef.meta['className']}
      onClick={handleClick}
      sortable={true}
      sorted={hasSortApplied}>
      Good Quote
    </SortableHeader>
  );
};