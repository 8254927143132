import { memo } from 'react';
import src from 'static/icons/microsoft-calendar.png';

type Props = {
  className?: string;
  onClick?: () => void;
};

const OutlookCalendar = memo(({
  className,
  onClick,
}: Props) => {
  return (
    <img
      className={className}
      onClick={onClick}
      src={src} />
  );
});

export { OutlookCalendar };
export default OutlookCalendar;