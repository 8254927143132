import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { WorkspaceGlobalSearchBarResultFirmMember } from '@/types';
import { cx } from '@utils';
import { UserAvatar } from '@/components/UserAvatar/UserAvatar';
import ClickContainer from './Results.ClickContainer';
import styles from './style/Results.Member.css';

export default function ResultsMember({ className, index, item, highlighted }: ResultsExpertProps) {
  return (
    <ClickContainer index={index} item={item}>
      <div className={cx(styles.root, { [styles.highlighted]: highlighted }, className)}>
        <UserAvatar
          className={styles.icon}
          pictureUrl={item.metadata.pictureUrl}
          size={28} />
        <div className={styles.details}>
          {/* <div className={styles.name}>{item.name} <span className={styles.title}>{item.metadata.title}</span></div> */}
          <div className={styles.name}>{item.name}</div>
          <div className={styles.type}>Member</div>
        </div>
        {highlighted && (
          <div className={styles.return}>
            <KeyboardReturnIcon />
          </div>
        )}
      </div>
    </ClickContainer>
  );
}

export { ResultsMember };

type ResultsExpertProps = {
  className?: string;
  index: number;
  item: WorkspaceGlobalSearchBarResultFirmMember;
  highlighted?: boolean;
}