import * as api from '@services/api';
import { ShareLinkPopper } from './ShareLink';
import { ProjectLinkProps } from './interfaces';

export const SelfSourceProjectLink = ({
  placement,
  projectId,
}: ProjectLinkProps) => {
  const fetchToken = () => {
    return api.projects.links.get({
      projectId,
    });
  };

  return (
    <ShareLinkPopper
      cacheKey={['self-source-project', projectId]}
      fetchToken={fetchToken}
      placement={placement} />
  );
};

export default SelfSourceProjectLink;