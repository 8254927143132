import * as enums from '@enums';
import { useConnectAccountContactSync } from '@/components/ConnectAccount/hooks/useConnectAccountContactSync';
import { GmailLabel, OutlookLabel } from './ProviderLabels';
import styles from './style/ImportContacts.css';

type Props = unknown;

export const ImportContacts = (props: Props) => {
  const sync = useConnectAccountContactSync();

  if (sync.connection) {
    const source = sync.connection.providerId === enums.ConnectedAccountProvider.Google
        ? 'Gmail'
        : 'Outlook';

    return (
      <div className={styles.imported}>
        {`Contacts synced from ${source}`}
      </div>
    );
  }

  if (sync.provider.outlook.loading || sync.provider.google.loading) {
    return (
      <div className={styles.imported}>Importing...</div>
    );
  }

  return (
    <div className={styles.root}>
      <GmailLabel
        className={styles.gmail}
        onClick={sync.provider.google.connect} /> |
      <OutlookLabel
        className={styles.outlook}
        onClick={sync.provider.outlook.connect} />
    </div>
  );
};

ImportContacts.displayName = 'ConnectAccount.ImportContacts';