import { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import * as api from '@api/scheduling.project';
import * as Scheduling from '@api/interfaces/scheduling.project';
import * as actions from '@store/actions';
import { ApprovalStatus } from '@enums';

type Data = Scheduling.ConfirmCall.Response;
type Variables = Pick<Scheduling.ConfirmCall.Request,
  'recordingConsentStatusId' |
  'slot'>;
type Key = ['confirm-project-call-request', number];

export function useConfirmProjectCall(
  data: ICallId,
  options?: UseMutationOptions<Data, AxiosResponse, Variables, Key>
) {
  const dispatch = useDispatch();

  const onSuccess = useCallback((res: Data, v: Variables, ctx) => {
    const getActionsForCall = (): [ReturnType<typeof actions.callUpdated>] => {
      return [ actions.callUpdated({ call: res.call }) ];
    };

    const getActionsForPipeline = () => {
      return [
        actions.projectPipelineUpdated({
          pipeline: res.pipeline,
          projectId: res.call.projectId,
        }),
      ];
    };

    dispatch(actions.batchActions([
      ...(res.call ? getActionsForCall() : []),
      ...(res.pipeline ? getActionsForPipeline() : []),
    ]));

    options?.onSuccess(res, v, ctx);
  }, [dispatch, options?.onSuccess]);

  return useMutation(keyProjectCallConfirmation(data.callId), params => {

    return api.projectCall.confirm({
      callId: data.callId,
      recordingConsentStatusId: params.recordingConsentStatusId,
      slot: params.slot,
    });

  }, {
    ...options,
    onSuccess,
  });
}

export function keyProjectCallConfirmation(callId: number) {
  return ['confirm-project-call-request', callId];
}