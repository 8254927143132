import { ExclusiveOptionsQuestion } from '@/types/survey.question.exclusive-options';
import { distinct } from '@utils/array';

export const buildExclusivityMap = (question: ExclusiveOptionsQuestion.FormQuestion) => {
  const map = question.settings.optionSections.flatMap(s => s.settings.exclusiveGroups).reduce<Record<string, string[]>>((acc, v) => {
    acc[v.primaryOption] = (acc[v.primaryOption] ?? []).concat(v.exclusiveOptions);
    for (const o of v.exclusiveOptions) {
      acc[o] = (acc[o] ?? []).concat([v.primaryOption]);
    }
    return acc;
  }, {});

  return Object.entries(map).reduce<Record<string, number[]>>((acc, [k, v]) => {
    acc[k] = distinct(v).map(x => question.options.find(o => o.base.identifier === x)?.id);
    return acc;
  }, {});
};