import { useCallback } from 'react';
import { LogicRuleDropdown } from '@/presentation/SurveyBuilder';
import { useConditionsRuleValidations, useSurveyRuleState, useSurveyConditionsBuilder } from '@/containers/SurveyBuilder.Logic';
import { SurveyLogicRule } from '@/enums';
import { cx } from '@utils';
import { LogicValidation } from './Builder.Validation';
import styles from './style/Builder.Rule.css';
import { useLogicExpressionModal } from './Expression.Modal';
import { ExpressionEditor } from './Expression.Editor';

type Props = {
  className?: string;
};

export const LogicBuilderConditionsRule = ({ className }: Props) => {

  const [state, dispatch] = useSurveyRuleState();
  const { items } = useSurveyConditionsBuilder();

  const [toggleExpressionModal, ExpressionModal] = useLogicExpressionModal();
  const validation = useConditionsRuleValidations();

  const handleSelect = useCallback((value: SurveyLogicRule) => {
    dispatch({
      type: 'rule-type-updated',
      payload: {
        value,
      },
    });
  }, [dispatch]);

  const renderExpression = useCallback(() => {
    if (state.type !== SurveyLogicRule.CustomExpression) return null;

    return (
      <div className={styles.expression}>
        {state.expression &&
          <>
            <ExpressionEditor />
            <div className={cx(styles.edit, styles.marginLeft)} onClick={toggleExpressionModal}>
              Edit
            </div>
          </>
        }
        {!state.expression &&
          <div className={styles.edit} onClick={toggleExpressionModal}>
            Setup expression
          </div>
        }
      </div>
    );
  }, [state, toggleExpressionModal]);

  const disabled = items.length < 2;

  return (
    <>
      <div className={className}>
        <div>
          <div className={styles.label}>Rule</div>
          <div className={styles.row}>
            <div className={styles.ruleDropdown}>
              <LogicRuleDropdown
                disabled={disabled}
                onSelect={handleSelect}
                value={state.type} />
            </div>
            {renderExpression()}
          </div>
        </div>
        <LogicValidation value={validation} />
      </div>
      <ExpressionModal />
    </>
  );
};
