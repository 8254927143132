import type { AxiosError } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import * as api from '@api';
import type { Projects } from '@api/interfaces';
import type { ProjectClips } from '@/types';
import { ConferenceTagType } from '@/enums';

type Props = Projects.GetProjectClips.Request;

type Data = {
  items: ProjectClips.Item[];
  total: number;
};
type QueryKey = readonly [string, Props];

type Options = UseQueryOptions<Data, AxiosError, Data, QueryKey>;

export const useProjectClipsQuery = (props: Props, options?: Options) => {

  return useQuery({
    queryKey: buildQueryKey(props),
    queryFn: ({ queryKey }) => {
      const [, params] = queryKey as QueryKey;
      return api.projects.getProjectClips(params);
    },
    ...options,
  });
};

export const useInvalidateProjectClipsQuery = () => {
  const queryClient = useQueryClient();

  return useCallback(() => {
    const queryKey = 'get-project-clips';

    return queryClient.invalidateQueries({
      queryKey: [queryKey],
    });
  }, [queryClient]);
};

export const useAddGoodQuoteInQueryData = (props: Props) => {
  const queryClient = useQueryClient();

  return useCallback((params: AddGoodQuoteParams) => {
    const queryKey = buildQueryKey(props);

    queryClient.setQueryData<Data>(queryKey, old => {

      const items = old.items.reduce((acc, x) => {
        if (x.transcript.id === params.transcriptId &&
          x.range.start === params.range.start &&
          x.range.end === params.range.end) {
          return acc.concat({
            ...x,
            isGoodQuote: true,
            tags: x.tags.concat({
              color: 'gray',
              id: 0,
              name: 'Good Quote',
              typeId: ConferenceTagType.Global,
              moment: {
                start: x.range.start,
                end: x.range.end,
              },
            }),
          });
        }

        return acc.concat(x);
      }, []);

      return {
        total: old.total,
        items,
      };
    });
  }, [props, queryClient]);
};

export const useSyncProjectClipsRangeQueryData = (props: Props) => {
  const queryClient = useQueryClient();

  return useCallback((params: SyncRangeTagsParams) => {
    const queryKey = buildQueryKey(props);

    queryClient.setQueryData<Data>(queryKey, old => {
      const items = old.items.reduce((acc, x) => {
        if (x.transcript.id === params.transcriptId &&
          x.range.start === params.range.start &&
          x.range.end === params.range.end) {
          const newTags = x.tags
            .filter(t => !params.deletes.includes(t.id))
            .concat(params.inserts);
          return acc.concat({
            ...x,
            isGoodQuote: newTags.some(t => t.name === 'Good Quote'),
            tags: newTags,
          });
        }

        return acc.concat(x);
      }, []);

      return {
        total: old.total,
        items,
      };
    });
  }, [props, queryClient]);
};

type SyncRangeTagsParams = {
  transcriptId: number;
  range: {
    start: number;
    end: number;
  };
  inserts: ProjectClips.Tag[];
  deletes: number[];
};

type AddGoodQuoteParams = {
  transcriptId: number;
  range: {
    start: number;
    end: number;
  };
};

function buildQueryKey(props: Props): QueryKey {
  return ['get-project-clips', props];
}