import * as pathname from '@consts/pathname';
import { extend, join } from '@utils/path';

const access = join(pathname.Access);
const account = join(pathname.Account);
const brands = join(pathname.Brands);
const brandInsights = join(pathname.BrandInsights);
const calls = join(pathname.Calls);
const companies = join(pathname.Companies);
const compliance = join(pathname.Compliance);
const conference = join(pathname.CONFERENCE);
const contacts = join(pathname.Contacts);
const discovery = join(pathname.Discovery);
const external = join(pathname.EXTERNALPROJECT);
const externalSourcing = join(pathname.ExternalSourcing);
const inbound = join(pathname.Inbound);
const invite = join(pathname.Invite);
const onboarding = join(pathname.Onboarding);
const messages = join(pathname.MESSAGES);
const posts = join(pathname.POSTS);
const profile = join(pathname.USER_PROFILE);
const projects = join(pathname.PROJECTS);
const search = join(pathname.SEARCH);
const settings = join(pathname.Settings);
const scheduling = join(pathname.SCHEDULING);
const topics = join(pathname.Topics);
const workspaces = join(pathname.WORKSPACES);

const accountprofile = join(account(`/profile`));

export const Access = {
  Manage: access(`/manage`),
  Request: access(`/requests/:objectId`),
  Root: access(),
};

export const Account = {
  Profile: {
    Root: accountprofile(),
    Checkup: accountprofile(`/checkup`),
    Setup: accountprofile(`/setup`),
  },
  Root: account(),
};

export const Brands = {
  Root: brands(),
};

export const BrandInsights = {
  Root: brandInsights(),
  Chat: brandInsights(`/chat`),
  ChatInstance: brandInsights(`/chat/:chatIdentifier`),
};

export const Calendar = {
  Root: pathname.CALENDAR,
};

export const Calls = {
  Root: calls(),
  Call: calls('/:callId'),
  NotFound: calls('/not-found'),
};

export const Companies = {
  Root: companies(),
  Company: companies(`/:slug`),
  NotFound: companies('/not-found'),
  CurrentEmployees: {
    Root: companies(`/:slug/current-employees`),
  },
  FormerEmployees: {
    Root: companies(`/:slug/former-employees`),
  },
};

const review = join(compliance('/:groupId/review'));

export const Compliance = {
  Review: {
    Call: review('/calls/:callId'),
    Message: review('/messages/:threadId'),
    Root: review(),
    Survey: review('/surveys/:responseId'),
  },
  Root: compliance(),
};

export const Conference = {
  Root: conference(),
  NetworkTest: conference('/network-test'),
  Entry: conference('/:conferenceIdentifier'),
  Join: conference('/:conferenceIdentifier/join'),
  Room: conference('/:conferenceIdentifier/room'),
  CaptureSheet: conference('/:callId/capture-sheet/:surveyId'),
  CaptureSheetPopout: conference('/:callId/capture-sheet/:surveyId/popout'),
  Completed: conference('/:conferenceIdentifier/completed'),
  NotFound: conference('/:conferenceIdentifier/not-found'),
  MiniNotes: conference('/:callId/mini/notes'),
  MiniTagging: conference('/:callId/mini/tagging'),
};

export const Contacts = {
  Import: contacts(`/import`),
  Root: contacts(),
  Record: contacts(`/:slug`),
};

export const Discovery = {
  Root: discovery(),
  Post: discovery('/:postId'),
  Topics: {
    Root: discovery(`/topics`),
    Topic: discovery(`/topics/:slug`),
  },
};

export const Inbound = {
  Actionable: {
    Root: inbound(`/actionable`),
    Token: inbound(`/actionable/:token`),
  },
  Confirmation: inbound(`/confirmation`),
  Connect: inbound(`/connect`),
  Downloads: inbound(`/downloads`),
  Scheduling: {
    AdHoc: {
      Confirmation: inbound(`/scheduling/calls/:callId`),
      Proposal: inbound(`/scheduling/calls/:callId/proposals`),
    },
    Root: inbound(`/scheduling`),
    Confirmation: inbound(`/scheduling/:projectId/calls/:callId`),
    Invitation: inbound(`/scheduling/:projectId`),
    Proposal: inbound(`/scheduling/:projectId/calls/:callId/proposals`),
  },
  Apple: inbound('/auth/apple'),
  Auth: inbound('/auth'),
  AuthError: inbound(`/auth/error`),
  Facebook: inbound(`/auth/facebook/`),
  Google: inbound(`/auth/google/`),
  Linkedin: inbound(`/auth/linkedin`),
  OAuth2Logout: inbound(`/auth/oauth2/logout`),
  OAuth2: inbound(`/auth/oauth2/:identifier`),
  AuthPW: inbound(`/auth/pw`),
  ProjectReport: inbound(`/project-report/:projectId`),
  Root: inbound(),
  HubSpot: inbound(`/hubspot`),
  Salesforce: inbound(`/salesforce`),
  Slack: inbound(`/slack`),
  SurveyCompletion: inbound(`/survey-completion`),
  Tipalti: inbound(`/tipalti`),
};

export const Invite = {
  Root: invite(),
  Token: invite(`/:token`),
};

export const Messages = extend({
  Root: messages(),
});

export const Notifications = {
  Root: pathname.NOTIFICATIONS,
};

const projectonboarding = join(onboarding('/projects'));

const medicalbackground = join(onboarding(`/background`));
const medicalexpertise = join(onboarding(`/expertise`));

const medical = {
  Background: {
    Root: medicalbackground(),
    AdditionalExpertise: medicalbackground(`/additional-expertise`),
    Contact: medicalbackground(`/contact`),
    Identification: medicalbackground(`/identification`),
    Leadership: medicalbackground(`/leadership`),
    Practice: medicalbackground(`/practice`),
  },
  Expertise: {
    Root: medicalexpertise(),
    ProfessionalRole: medicalexpertise(`/role`),
    Specialties: medicalexpertise(`/specialties`),
    Subspecialties: medicalexpertise(`/subspecialties`),
  },
  ContactPreferences: onboarding(`/contact-preferences`),
};

export const Onboarding = {
  Calls: onboarding('/calls'),
  Compliance: onboarding('/compliance'),
  ComplianceTerms: onboarding('/compliance/terms'),
  ComplianceTest: onboarding('/compliance/test'),
  ComplianceQuestions: onboarding('/compliance/questions'),
  /*
  Profile:                  onboarding('/profile'),
  ProfileEducation:         onboarding('/profile/education'),
  ProfileEmployment:        onboarding('/profile/employment'),
  ProfileEnrichment:        onboarding(`/profile/enrichment`),
  ProfileSummary:           onboarding('/profile/summary'),
  */
  Projects: {
    Root: projectonboarding(),
    Archived: projectonboarding('/archived'),
    Closed: projectonboarding('/:projectId/closed'),
    Completion: projectonboarding('/:projectId/completion'),
    Disqualified: projectonboarding('/disqualified'),
    End: projectonboarding('/:projectId/end'),
    Exit: projectonboarding('/:projectId/exit'),
    Project: projectonboarding('/:projectId'),
  },
  PayoutSetup: onboarding('/payout-setup'),
  Root: onboarding(),
  SecondaryScreener: onboarding('/screeners'),
  Topics: onboarding('/topics'),
  ...medical,
};

const callOnboarding = join(Onboarding.Calls);
export const CallOnboarding = {
  Call: callOnboarding('/:callId'),
  Details: callOnboarding('/:callId/details'),
  Availability: callOnboarding('/:callId/availability'),
  PayoutSetup: callOnboarding('/:callId/payout-setup'),
  SchedulingTutorial: callOnboarding('/:callId/scheduling-tutorial'),
};

const articleposts = join(posts(`/articles`));

export const Posts = {
  Articles: {
    Edit: articleposts(`/:postId/edit`),
    New: articleposts(`/new`),
    Post: articleposts(`/:postId`),
    Root: articleposts(),
  },
  NotFound: posts(`/not-found`),
  Post: posts(`/:postId`),
  Root: posts(),
};

export const Profile = {
  NotFound: profile('/not-found'),
  Root: pathname.USER_PROFILE,
  Tabs: {
    Root: profile(`/:userId`),
    Param: profile(`/:userId/:tab?`),
    Medical: profile(`/:userId/medical`),
    Payer: profile(`/:userId/payer`),
    Professional: profile(`/:userId/professional`),
    Projects: profile(`/:userId/projects`),
  },
  User: profile('/:slug?'),
  UserPosts: profile('/:slug/posts'),
};

export const Projects = {
  Cleanup: projects(`/:projectId/calls/:callId/cleanup`),
  Clone: projects(`/:slug/clone`),
  EntitiesOnboarding: projects(`/:slug/entities/onboarding`),
  File: projects(`/:projectId/files/:fileId`),
  FileNotFound: projects(`/:projectId/files/not-found`),
  FileTabs: projects(`/:projectId/files/:fileId/:tab`),
  New: projects(`/new/:step`),
  Preview: projects(`/:projectId/preview`),
  PreviewComplete: projects(`/:projectId/preview/complete`),
  Project: projects(`/:slug`),
  ProjectTabs: projects(`/:slug/:tab*`),
  Root: pathname.PROJECTS,
  Search: projects(`/:slug/search`),
  Surveys: projects(`/:slug/surveys`),
  SurveyMonitoring: projects(`/:slug/survey-monitoring/:surveyId?`),
  SurveyEditor: projects(`/:slug/survey/:surveyId`),
  SurveyNew: projects(`/:slug/survey/new`),
  SurveyPreview: projects(`/:surveyVersionId/survey-preview`),
  SurveyResponse: projects(`/:slug/survey-responses/:projectSurveyResponseId`),
  SurveySettings: projects(`/:projectId/survey-settings`),
  TagsOnboarding: projects(`/:projectId/tags/onboarding`),
  TranscriptLegacy: projects(`/:slug/summary/:callId/:type/transcript`),
  Transcript: projects(`/:slug/calls/:callId/transcript`),
};

export const ExternalProject = {
  Disqual: external('/:projectId/:secret/disqual'),
  Success: external('/:projectId/:secret/success'),
  QuotaLimit: external('/:projectId/:secret/quota'),
  Root: pathname.EXTERNALPROJECT,
};

export const ExternalSourcingProject = {
  Project: externalSourcing(`/:projectId/:configIdentifier/:userId?`),
  Root: pathname.ExternalSourcing,
};

const projectTabs = join(Projects.Project);

export const ProjectTabs = {
  Calls: projectTabs('/calls'),
  Dashboard: projectTabs('/dashboard'),
  Details: projectTabs('/details'),
  Entities: projectTabs('/entities'),
  Files: projectTabs('/files'),
  Highlights: projectTabs('/highlights'),
  QuantAnalysis: projectTabs('/quant-analysis/:quantAnalysisId'),
  Pipeline: projectTabs('/pipeline'),
  Summary: projectTabs('/summary'),
  SurveyResponses: projectTabs('/survey-responses'),
  Tags: projectTabs('/tags'),
};

export const AdHocCallScheduling = {
  Confirmations: scheduling(`/calls/:callId`),
  Proposals: scheduling(`/calls/:callId/proposals`),
};

export const Scheduling = {
  Project: scheduling(`/:projectId`),
  Confirmations: scheduling(`/:projectId/calls/:callId`),
  Root: pathname.SCHEDULING,
  Proposals: scheduling(`/:projectId/calls/:callId/proposals`),
};

export const Search = {
  Root: search(),
  Experts: search(`/experts`),
  Leads: search(`/leads`),
};

export const Settings = {
  Root: pathname.Settings,
  Account: settings(`/account`),
  Branding: settings(`/branding`),
  Calendar: settings('/calendar'),
  Compliance: settings(`/compliance`),
  ComplianceSurvey: settings(`/compliance-survey`),
  Integrations: settings(`/integrations`),
  Notifications: settings(`/notifications`),
  PostCallQuestions: settings(`/post-call-questions`),
  Privacy: settings(`/privacy`),
  Security: settings(`/security`),
  UserBilling: settings(`/billing`),
};

const selfservesurvey = join(pathname.SELFSERVESURVEY);

export const SelfServeSurvey = {
  Root: selfservesurvey(),
  NotAFit: selfservesurvey(`/naf`),
  Inactive: selfservesurvey(`/inact`),
};

export const Topics = {
  Root: topics(),
  Topic: topics(`/:slug`),
  NotFound: topics('/not-found'),
  Experts: {
    Root: topics(`/:slug/experts`),
  },
  News: {
    Root: topics(`/:slug/news`),
  },
  Posts: {
    Root: topics(`/:slug/posts`),
  },
};

const universalsearch = join(pathname.UniversalSearch);

export const UniversalSearch = {
  Root: pathname.UniversalSearch,
};

const unsubscribe = join(pathname.Unsubscribe);
export const Unsubscribe = {
  Root: unsubscribe(),
  Token: unsubscribe(`/:token`),
};

export const Workspaces = {
  Brand: workspaces('/brands/:brandId'),
  BrandNotFound: workspaces('/brands/not-found'),
  BrandTabs: workspaces('/brands/:brandId/:tab'),
  FileNotFound: workspaces('/files/not-found'),
  File: workspaces('/files/:fileId'),
  FileTabs: workspaces('/files/:fileId/:tab'),
  Folder: workspaces('/folders/:folderId'),
  FolderNotFound: workspaces('/folders/not-found'),
  FolderTabs: workspaces('/folders/:folderId/:tab'),
  Root: pathname.WORKSPACES,
  Search: workspaces('/search'),
  Shared: workspaces('/shared'),
  SharedTabs: workspaces(`/shared/:tab`),
  Transcript: workspaces('/transcripts/:transcriptId'),
  Workspace: workspaces(`/:workspace(group|personal)`),
  WorkspaceTabs: workspaces(`/:workspace/:tab`),
};

const workspaceTabs = join(Workspaces.Workspace);

export const WorkspaceTabs = {
  Files: workspaceTabs('/files'),
};

const brandTabs = join(Workspaces.Brand);
export const WorkspaceBrandTabs = {
  ProjectTracker: brandTabs('/tracker'),
  Projects: brandTabs('/projects'),
  Files: brandTabs('/files'),
};

const fileTabs = join(Workspaces.File);
export const WorkspaceFileTabs = {
  Preview: fileTabs('/preview'),
  Summary: fileTabs('/summary'),
  Details: fileTabs('/details'),
  References: fileTabs('/references'),
};

const projectFileTabs = join(Projects.File);
export const ProjectFileTabs = {
  Preview: projectFileTabs('/preview'),
  Summary: projectFileTabs('/summary'),
  Details: projectFileTabs('/details'),
  References: projectFileTabs('/references'),
};

const folderTabs = join(Workspaces.Folder);
export const WorkspaceFolderTabs = {
  Files: folderTabs('/files'),
  Details: folderTabs('/details'),
};

const sharedWorkspaceTabs = join(Workspaces.Shared);

export const SharedWorkspaceTabs = {
  Files: sharedWorkspaceTabs('/files'),
};

//These are compared using exact === false so keep that in mind when adding here
export const RoutesToIgnoreAnonymousActions = [Conference.Root];

//Routes where we should avoid prompting the user or interrupting their flow.
export const UninterruptibleRoutes = [Conference.Root, Onboarding.Root];