import { useCallback, useMemo } from 'react';
import { SurveyQuestionConditionType, utils as enumUtils } from '@enums';
import DropDown from '@/components/DropDown';

type ConditionTypeDropdownItem = {
  id: SurveyQuestionConditionType;
};

type Props = {
  disabled?: boolean;
  getTextValue?: (v: SurveyQuestionConditionType) => string;
  items?: SurveyQuestionConditionType[];
  onSelect: (value: SurveyQuestionConditionType) => void;
  value: SurveyQuestionConditionType;
};

function getDisplayText(v: SurveyQuestionConditionType) {
  return enumUtils.SurveyQuestionConditionType.getName(v);
}

export const ConditionTypeDropdown = ({
  disabled = false,
  getTextValue = getDisplayText,
  items = enumUtils.SurveyQuestionConditionType.values(),
  onSelect,
  value,
}: Props) => {
  const getItemValue = useCallback((item: ConditionTypeDropdownItem) => {
    return getTextValue(item.id);
  }, [getTextValue]);

  const handleSelect = useCallback((item: ConditionTypeDropdownItem) => {
    onSelect(item.id);
  }, [onSelect]);

  const mappedItems = useMemo(() => {
    return items.map(m => ({ id: m }));
  }, [items]);

  return (
    <DropDown
      disabled={disabled}
      placeholder="Select a type"
      items={mappedItems}
      getItemValue={getItemValue}
      onSelect={handleSelect}
      text={getTextValue(value)} />
  );
};

export default ConditionTypeDropdown;