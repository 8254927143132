import { useCallback, useState, useMemo, useRef } from 'react';
import type { TwilioError, LocalTrack, LocalAudioTrack, LocalVideoTrack, Room } from 'twilio-video';
import { Logger } from 'twilio-video';
import * as Sentry from '@sentry/react';
import { ConferenceLogType } from '@/types';
import { isScreenTrack } from '@/components/Conference.Video';
import {
  useRoom,
  useScreenShareToggle,
  useLocalTracks,
  useHandleRoomDisconnection,
  useRestartAudioTrackOnDeviceChange,
  useHandleTrackPublicationFailed,
  useIsTrackEnabled,
} from '@/components/Conference.Video/hooks';
import Toast from '@/components/Toast';
import { DeviceErrorModal, PermissionError, getPermissionErrorType } from '@/components/Modal/DevicePermissions';
import { ConferenceTwilioVideoContext } from './Context';
import { useLogConferenceEvent, useOnError } from './hooks';

export function TwilioVideoContainer({ children }: ChildrenProps) {
  const microphoneDeviceRef = useRef<MediaDeviceInfo>();//Manage this state in two places right now
  const [microphoneDevice, changeMicrophoneDevice] = useState<MediaDeviceInfo>();
  const cameraDeviceRef = useRef<MediaDeviceInfo>();
  const [cameraDevice, changeCameraDevice] = useState<MediaDeviceInfo>();

  const {
    localTracks,
    getLocalAudioTrack,
    removeLocalAudioTrack,
    getLocalVideoTrack,
    removeLocalVideoTrack,
  } = useLocalTracks(cameraDeviceRef);

  const onError = useOnError();

  const [audioError, setAudioError] = useState<Error>();
  const onAudioError = useCallback((error: Error | TwilioError) => {
    onError(error);

    const permissionsErrorType = getPermissionErrorType(error);
    if (permissionsErrorType !== PermissionError.Unknown) {
      setAudioError(error);
    }
  }, [onError]);

  const clearAudioError = useCallback(() => {
    setAudioError(null);
  }, []);

  const [videoError, setVideoError] = useState<Error>();
  const onVideoError = useCallback((error: Error | TwilioError) => {
    onError(error);

    const permissionsErrorType = getPermissionErrorType(error);
    if (permissionsErrorType !== PermissionError.Unknown) {
      setVideoError(error);
    }
  }, [onError]);

  const clearVideoError = useCallback(() => {
    setVideoError(null);
  }, []);

  const { room, connect, isConnecting } = useRoom(localTracks, onError, {
    bandwidthProfile: {
      video: {
        trackSwitchOffMode: 'disabled',
        dominantSpeakerPriority: 'standard',
        mode: 'collaboration',
        contentPreferencesMode: 'auto',
        clientTrackSwitchOffControl: 'manual',
      },
    },
    preferredVideoCodecs: 'auto',
    dominantSpeaker: true,
    networkQuality: {
      local: 3,
      remote: 3,
    },
  });

  const [isSharingScreen, toggleScreenShare] = useScreenShareToggle(room, onError);

  useHandleRoomDisconnection(
    room,
    onError,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    isSharingScreen,
    toggleScreenShare,
  );
  useHandleTrackPublicationFailed(room, onError);
  useRestartAudioTrackOnDeviceChange(localTracks);

  const [isSharingAudio, toggleLocalAudio, refreshLocalAudio] = useLocalAudioToggle(
    room,
    microphoneDeviceRef,
    localTracks,
    getLocalAudioTrack,
    removeLocalAudioTrack,
    onAudioError,
    clearAudioError,
  );

  const [isSharingVideo, toggleLocalVideo, refreshLocalVideo] = useLocalVideoToggle(
    room,
    cameraDeviceRef,
    localTracks,
    getLocalVideoTrack,
    removeLocalVideoTrack,
    onVideoError,
  );

  const [previewTrack, enablePreview, disablePreview, refreshPreview] = useCameraPreview(cameraDeviceRef);

  const setupLocalTracks = useCallback((microphone: boolean, camera: boolean, room: Room) => {
    if (microphone && !isSharingAudio) {
      toggleLocalAudio(null, room);
    }
    if (camera && !isSharingVideo) {
      toggleLocalVideo(room);
    }
  }, [toggleLocalAudio, isSharingAudio, toggleLocalVideo, isSharingVideo]);

  const muteSelf = useCallback(() => {
    if (isSharingAudio) {
      toggleLocalAudio();
    }
  }, [isSharingAudio, toggleLocalAudio]);

  const disconnect = useCallback(() => {
    room?.disconnect();
  }, [room]);

  const getAVPermission = useCallback((audio: boolean, video: boolean) => {
    if (!navigator.mediaDevices?.getUserMedia) return Promise.resolve(false);
    return navigator.mediaDevices.getUserMedia({ audio: audio ? { deviceId: microphoneDeviceRef.current?.deviceId } : false, video })
      .then(stream => {
        stream.getTracks().forEach(track => track.stop());
        return true;
      })
      .catch(() => false);
  }, [microphoneDeviceRef]);

  const changeAudioSource = useCallback((device: MediaDeviceInfo) => {
    microphoneDeviceRef.current = device;
    changeMicrophoneDevice(device);
    refreshLocalAudio();
  }, [microphoneDeviceRef, changeMicrophoneDevice, refreshLocalAudio]);

  const changeVideoSource = useCallback((device: MediaDeviceInfo) => {
    cameraDeviceRef.current = device;
    changeCameraDevice(device);
    refreshPreview();
    refreshLocalVideo();
  }, [cameraDeviceRef, refreshPreview, refreshLocalVideo, changeCameraDevice]);

  const value = useMemo(() => ({
    onError,
    room,
    connect,
    isConnecting,
    localTracks,
    setupLocalTracks,
    isSharingScreen,
    toggleScreenShare,
    isSharingAudio,
    toggleLocalAudio,
    audioSource: microphoneDevice,
    changeAudioSource,
    videoSource: cameraDevice,
    isSharingVideo,
    toggleLocalVideo,
    changeVideoSource,
    muteSelf,
    disconnect,
    getAVPermission,
    previewTrack,
    enablePreview,
    disablePreview,
  }), [
    onError,
    room,
    connect,
    isConnecting,
    localTracks,
    setupLocalTracks,
    isSharingScreen,
    toggleScreenShare,
    microphoneDevice,
    isSharingAudio,
    toggleLocalAudio,
    changeAudioSource,
    cameraDevice,
    isSharingVideo,
    toggleLocalVideo,
    changeVideoSource,
    muteSelf,
    disconnect,
    getAVPermission,
    previewTrack,
    enablePreview,
    disablePreview,
  ]);

  return (
    <ConferenceTwilioVideoContext.Provider value={value}>
      <DeviceErrorModal error={audioError} onClose={clearAudioError} />
      <DeviceErrorModal error={videoError} onClose={clearVideoError} />
      {children}
    </ConferenceTwilioVideoContext.Provider>
  );
}

function useLocalAudioToggle(
  room: Room,
  audioDevice: React.MutableRefObject<MediaDeviceInfo>,
  localTracks: LocalTrack[],
  getLocalAudioTrack: (microphoneDevice: MediaDeviceInfo) => Promise<LocalAudioTrack>,
  removeLocalAudioTrack: () => void,
  onError: (error: TwilioError | Error) => void,
  onSuccess?: () => void,
) {
  const audioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;
  const [isPublishing, setIsPublishing] = useState(false);
  const isEnabled = useIsTrackEnabled(audioTrack);
  const logConferenceEvent = useLogConferenceEvent();

  const toggleLocalAudio = useCallback((errHandler?: ErrorHandler, roomParam: Room = room) => {
    if (!isPublishing) {
      if (audioTrack) {
        const localTrackPublication = roomParam?.localParticipant?.unpublishTrack(audioTrack);
        // TODO: remove when SDK implements this event.
        roomParam?.localParticipant?.emit('trackUnpublished', localTrackPublication);
        removeLocalAudioTrack();
      } else {
        setIsPublishing(true);
        getLocalAudioTrack(audioDevice.current)
          .then(async (track: LocalAudioTrack) => {
            if (!roomParam?.localParticipant) {
              Sentry.captureException(new Error(`Cannot publish audio without an available room`));
            } else {
              await roomParam?.localParticipant?.publishTrack(track, { priority: 'low' });
            }

            onSuccess?.();
          })
          .catch((e: Error) => {
            onError(e);
            errHandler?.(e);
            logConferenceEvent(ConferenceLogType.Error, {
              message: `Error unmuting. ${e.message}`,
            });
          })
          .finally(() => {
            setIsPublishing(false);
          });
      }
    }
  }, [room, isPublishing, audioTrack, removeLocalAudioTrack, getLocalAudioTrack, audioDevice, onError, logConferenceEvent, onSuccess]);

  const refreshLocalAudio = useCallback(() => {
    if (audioTrack) {
      const localTrackPublication = room?.localParticipant?.unpublishTrack(audioTrack);
      // TODO: remove when SDK implements this event.
      room?.localParticipant?.emit('trackUnpublished', localTrackPublication);
      removeLocalAudioTrack();
      getLocalAudioTrack(audioDevice.current)
        .then((track: LocalAudioTrack) => room?.localParticipant?.publishTrack(track, { priority: 'low' }))
        .catch(onError)
        .finally(() => {
          setIsPublishing(false);
        });
    }
  }, [audioDevice, audioTrack, getLocalAudioTrack, onError, removeLocalAudioTrack, room?.localParticipant]);

  return [isEnabled, toggleLocalAudio, refreshLocalAudio] as const;
}

function useLocalVideoToggle(
  room: Room,
  cameraDevice: React.MutableRefObject<MediaDeviceInfo>,
  localTracks: LocalTrack[],
  getLocalVideoTrack: (cameraDevice: MediaDeviceInfo) => Promise<LocalVideoTrack>,
  removeLocalVideoTrack: () => void,
  onError: (error: TwilioError | Error) => void,
  onSuccess?: () => void,
) {
  const videoTrack = localTracks.find(track => !isScreenTrack(track.name) && track.kind === 'video') as LocalVideoTrack;
  const [isPublishing, setIsPublishing] = useState(false);

  const toggleLocalVideo = useCallback((roomParam: Room = room) => {
    if (!isPublishing) {
      if (videoTrack) {
        const localTrackPublication = roomParam?.localParticipant?.unpublishTrack(videoTrack);
        // TODO: remove when SDK implements this event.
        roomParam?.localParticipant?.emit('trackUnpublished', localTrackPublication);
        removeLocalVideoTrack();
      } else {
        setIsPublishing(true);
        getLocalVideoTrack(cameraDevice.current)
          .then((track: LocalVideoTrack) => {
            roomParam?.localParticipant?.publishTrack(track, { priority: 'low' });

            onSuccess?.();
          })
          .catch(onError)
          .finally(() => setIsPublishing(false));
      }
    }
  }, [room, isPublishing, videoTrack, removeLocalVideoTrack, getLocalVideoTrack, cameraDevice, onError, onSuccess]);

  const refreshLocalVideo = useCallback(() => {
    if (videoTrack) {
      const localTrackPublication = room?.localParticipant?.unpublishTrack(videoTrack);
      // TODO: remove when SDK implements this event.
      room?.localParticipant?.emit('trackUnpublished', localTrackPublication);
      removeLocalVideoTrack();
      getLocalVideoTrack(cameraDevice.current)
        .then((track: LocalVideoTrack) => room?.localParticipant?.publishTrack(track, { priority: 'low' }))
        .catch(onError)
        .finally(() => setIsPublishing(false));
    }
  }, [cameraDevice, getLocalVideoTrack, onError, removeLocalVideoTrack, room?.localParticipant, videoTrack]);

  return [!!videoTrack, toggleLocalVideo, refreshLocalVideo] as const;
}

function useCameraPreview(cameraDevice: React.MutableRefObject<MediaDeviceInfo>) {
  const { localTracks, getLocalVideoTrack, removeLocalVideoTrack, refreshLocalVideoTrack } = useLocalTracks(cameraDevice);
  const [isAcquiring, setIsAcquiring] = useState(false);

  const track = localTracks.find(track => !isScreenTrack(track.name) && track.kind === 'video') as LocalVideoTrack;

  const enable = useCallback(async () => {
    if (!isAcquiring) {
      setIsAcquiring(true);
      return getLocalVideoTrack().finally(() => setIsAcquiring(false));
    } else {
      return Promise.resolve<LocalVideoTrack>(null);
    }
  }, [isAcquiring, getLocalVideoTrack]);

  const disable = useCallback(() => removeLocalVideoTrack(), [removeLocalVideoTrack]);

  const refresh = useCallback(() => {
    if (track) {
      disable();
      setIsAcquiring(true);
      enable().finally(() => setIsAcquiring(false));
    }
  }, [disable, enable, track]);

  return [track, enable, disable, refresh] as const;
}

type ErrorHandler = (error: Error) => void;