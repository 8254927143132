import { memo } from 'react';
import styled from '@emotion/styled';

export const PrimaryButton = memo(
  styled.button`
    box-sizing: border-box;
    min-width: 100px;
    padding: 10px;
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;

    font-family: ${({ theme }) => theme.fonts.bold};
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: background-color 180ms ease-in-out, border 120ms ease-in, border-color 120ms ease-in, color 120ms ease-in;

    background-color: ${({ theme }) => theme.palette.btnPrimary.bg};
    border-color: ${({ theme }) => theme.palette.btnPrimary.border};
    color: ${({ theme }) => theme.palette.btnPrimary.color};

    &:hover {
      color: ${({ theme }) => theme.palette.btnPrimary.active.color};
      background-color: ${({ theme }) => theme.palette.btnPrimary.active.bg};
      border-color: ${({ theme }) => theme.palette.btnPrimary.active.border};
    }

    &:disabled {
      cursor: initial;
      color: ${({ theme }) => theme.palette.btnPrimary.disabled.color};
      background-color: ${({ theme }) => theme.palette.btnPrimary.disabled.bg};
      border-color: ${({ theme }) => theme.palette.btnPrimary.disabled.border};
    }
  `
);