import { memo } from 'react';
import Select, { type ActionMeta } from 'react-select';
import { utils } from '@enums';
import type { Members } from '$admin/interfaces/search';
import selectStyles from './SelectStyles';
import styles from './style/Filters.css';

type PositionProps = {
  value: Members.Filters.FieldQualifierPosition;
  onChange: (value: FieldQualifierPositionOption, meta: ActionMeta<FieldQualifierPositionOption>) => unknown;
};

export const FieldQualifierPosition = memo(({ value, onChange }: PositionProps) => {
  return (
    <div className={styles.fieldQualifier}>
      <Select<FieldQualifierPositionOption>
        className={styles.select}
        options={positionOptions}
        value={positionOptions.find(o => o.id === value)}
        getOptionLabel={o => o.name}
        getOptionValue={o => o.id}
        onChange={onChange}
        isSearchable={false}
        styles={selectStyles} />
    </div>
  );
});

type NameProps = {
  value: Members.Filters.FieldQualifierName;
  onChange: (value: FieldQualifierNameOption, meta: ActionMeta<FieldQualifierNameOption>) => unknown;
};

export const FieldQualifierName = memo(({ value, onChange }: NameProps) => {
  return (
    <div className={styles.fieldQualifier}>
      <Select<FieldQualifierNameOption>
        className={styles.select}
        options={nameOptions}
        value={nameOptions.find(o => o.id === value)}
        getOptionLabel={o => o.name}
        getOptionValue={o => o.id}
        onChange={onChange}
        isSearchable={false}
        styles={selectStyles} />
    </div>
  );
});

type MarketResearchProps = {
  value: Members.Filters.FieldQualifierMarketResearch;
  onChange: (value: FieldQualifierMarketResearchOption, meta: ActionMeta<FieldQualifierMarketResearchOption>) => unknown;
};

export const FieldQualifierMarketResearch = memo(({ value, onChange }: MarketResearchProps) => {
  return (
    <div className={styles.fieldQualifier}>
      <Select<FieldQualifierMarketResearchOption>
        className={styles.select}
        options={marketResearchOptions}
        value={marketResearchOptions.find(o => o.id === value)}
        getOptionLabel={o => o.name}
        getOptionValue={o => o.id}
        onChange={onChange}
        isSearchable={false}
        styles={selectStyles} />
    </div>
  );
});

type CoveredLivesProps = {
  value: Members.Filters.FieldQualifierCoveredLives;
  onChange: (value: FieldQualifierCoveredLivesOption, meta: ActionMeta<FieldQualifierCoveredLivesOption>) => unknown;
};

export const FieldQualifierCoveredLivesColumn = memo(({ value, onChange }: CoveredLivesProps) => {
  return (
    <div className={styles.fieldQualifier}>
      <Select<FieldQualifierCoveredLivesOption>
        className={styles.select}
        options={coveredLivesColumnOptions}
        value={coveredLivesColumnOptions.find(o => o.id === value)}
        getOptionLabel={o => o.name}
        getOptionValue={o => o.id}
        onChange={(val, meta) => onChange({ id: `col_${val.id}`, name: val.name }, meta)}
        isSearchable={false}
        styles={selectStyles} />
    </div>
  );
});

export const FieldQualifierCoveredLivesRow = memo(({ value, onChange }: CoveredLivesProps) => {
  return (
    <div className={styles.fieldQualifier}>
      <Select<FieldQualifierCoveredLivesOption>
        className={styles.select}
        options={coveredLivesRowOptions}
        value={coveredLivesRowOptions.find(o => o.id === value)}
        getOptionLabel={o => o.name}
        getOptionValue={o => o.id}
        onChange={(val, meta) => onChange({ id: `row_${val.id}`, name: val.name }, meta)}
        isSearchable={false}
        styles={selectStyles} />
    </div>
  );
});

export type FieldQualifierPositionOption = { id: Members.Filters.FieldQualifierPosition; name: string };

const positionOptions: FieldQualifierPositionOption[] = [
  { id: 'any', name: 'Any' },
  { id: 'current', name: 'Current' },
  { id: 'former', name: 'Former' },
];

export type FieldQualifierNameOption = { id: Members.Filters.FieldQualifierName; name: string };

const nameOptions: FieldQualifierNameOption[] = [
  { id: 'full', name: 'Full' },
  { id: 'first', name: 'First' },
  { id: 'last', name: 'Last' },
];

export type FieldQualifierMarketResearchOption = { id: Members.Filters.FieldQualifierMarketResearch; name: string };

const marketResearchOptions: FieldQualifierMarketResearchOption[] = [
  { id: 'in-person', name: 'In Person' },
  { id: 'virtual', name: 'Virtual' },
  { id: 'survey', name: 'Survey' },
  { id: 'phone', name: 'Phone' },
];

export type FieldQualifierCoveredLivesOption = { id: Members.Filters.FieldQualifierCoveredLives; name: string };

const coveredLivesColumnOptions: FieldQualifierCoveredLivesOption[] = utils.CoveredLivesTypes.values().map(x => ({
  id: `${x}`,
  name: utils.CoveredLivesTypes.getName(x),
}));

const coveredLivesRowOptions: FieldQualifierCoveredLivesOption[] = utils.CoveredLivesPlans.values().map(x => ({
  id: `${x}`,
  name: utils.CoveredLivesPlans.getName(x),
}));