import Color from 'color';
import type { EditorView } from 'remirror';
import type { Node } from '@remirror/pm/model';
import type { DocumentNode, MonologueDetails, MonologueNode, TextNode, Timestamp } from '@/types/transcribe.rich-text';
import { MarkType, NodeType } from '@/types/transcribe.rich-text';
import type { ConferenceTag } from '@/types';
import { ConferenceTagType } from '@/enums';

export const UnsavedHighlightColor = '#B2F2FB';

export const highlightColors = [
  {
    highlight: '#CCECF9',
    menu: '#B4DFF1',
    hover: '#9CD3EA',
  },
  {
    highlight: '#DCDCF8',
    menu: '#C8C9F4',
    hover: '#ABACEB',
  },
  {
    highlight: '#F7C0CD',
    menu: '#EEAABA',
    hover: '#EF819C',
  },
  {
    highlight: '#E4F1CF',
    menu: '#D6E8B8',
    hover: '#C1DD93',
  },
  {
    highlight: '#C6F5F5',
    menu: '#AEF3F3',
    hover: '#8DECEC',
  },
  {
    highlight: '#F7DAD4',
    menu: '#EFB5AA',
    hover: '#E7917F',
  },
];

type ComputeSegmentStyle = {
  color: string;
  isFocused: boolean;
};

export function computeSegmentStyle({ color, isFocused }: ComputeSegmentStyle) {

  const isTextSelection = color === UnsavedHighlightColor;

  const darkenBy = isTextSelection ? .05 : .3;
  const opacity = isFocused
    ? 10
    : isTextSelection ? 20 : 5;

  const rgbString = `${computeTranscriptColor({ color, opacity, darkenBy })} 0px 0px 0px 50px inset`;

  return `box-shadow: ${rgbString}, ${rgbString}, ${rgbString}; padding: 5px 0;`;
}

type ComputeTranscriptColor = {
  color: string;
  opacity: number;
  darkenBy?: number;
};

export function computeTranscriptColor({ color, opacity, darkenBy = .3 }: ComputeTranscriptColor) {
  const modifiedColor = Color(color)
    .darken(darkenBy)
    .saturate(.2)
    .rgb().array();

  return `rgb(${modifiedColor[0]}, ${modifiedColor[1]}, ${modifiedColor[2]}, ${opacity}%)`;
}

export function getRemirrorCoords(view: EditorView, pos: number) {
  const hTop = view.coordsAtPos(pos);
  const padding = 5;

  return window.scrollY + hTop.top - padding;
}

export function buildDocumentSnippet(docNode: Node, fromParam: number, toParam: number): DocumentNode {
  const allNodes = new Set<Node>();

  const { from, to } = resolveNodeSentenceBoundaries(docNode, fromParam, toParam);

  //Build a set of nodes that we know occur in the range, the parent node will still have children outside of the range so we need to filter
  docNode.nodesBetween(from, to, (node, pos, parent) => {
    allNodes.add(node);
  });

  let speakerNode: Node;
  docNode.descendants((node, pos) => {
    if (pos < from && node.type.name === NodeType.MonologueDetails) {
      speakerNode = node;
    }

    //Never descend, we only look at top level nodes
    return false;
  });

  const content = new Array<MonologueNode>();
  if (speakerNode) {
    content.push({
      type: NodeType.MonologueDetails,
      attrs: speakerNode.attrs as MonologueDetails.Attributes,
    });
  }
  docNode.nodesBetween(from, to, node => {
    content.push(pmNodeToRTNode(node, allNodes));
    return false;
  });

  return {
    type: NodeType.Document,
    content,
  };
}

function pmNodeToRTNode(node: Node, filterNodes: Set<Node>): MonologueNode {
  switch (node.type.name) {
    case NodeType.MonologueDetails:
      return {
        type: node.type.name,
        attrs: node.attrs as MonologueDetails.Attributes,
      };
    case NodeType.MonologueText: {
      const content = new Array<TextNode>();

      node.forEach(n => {
        if (filterNodes.has(n)) {
          content.push({
            type: NodeType.Text,
            text: n.text,
            marks: n.marks.filter(m => m.type.name === MarkType.Timestamp).map(m => ({
              type: MarkType.Timestamp,
              attrs: m.attrs as Timestamp.Attributes,
            })),
          });
        }
      });

      return {
        type: node.type.name,
        content,
      };
    }
  }
}

const sentenceBoundaryRegex = new RegExp(/[(.|?|!|\n|\r)]/, 'gi');
function resolveNodeSentenceBoundaries(docNode: Node, from: number, to: number) {
  const startSentenceBoundaries = getNodeSentenceBoundaries(docNode, from);
  const initialStartBoundaryIndex = startSentenceBoundaries.indexOf(from);
  const startPos = initialStartBoundaryIndex === 0 || initialStartBoundaryIndex === -1 ? from : startSentenceBoundaries[initialStartBoundaryIndex - 1] + 1;

  const endSentenceBoundaries = getNodeSentenceBoundaries(docNode, to);
  const initialEndBoundaryIndex = endSentenceBoundaries.indexOf(to);
  const endPos = initialEndBoundaryIndex === endSentenceBoundaries.length - 1 || initialEndBoundaryIndex === -1 ? to : endSentenceBoundaries[initialEndBoundaryIndex + 1] + 1;

  return {
    from: startPos,
    to: endPos,
  };

  function getNodeSentenceBoundaries(docNode: Node, position: number) {
    const resolvedPos = docNode.resolve(position);

    const startNodePos = resolvedPos.start();
    const txt = resolvedPos.parent.textContent;

    //switch variable for the matches below
    let match: RegExpExecArray = null;
    let stcpositions = new Array<number>();

    //loop over matches and add the indices to stcpositions
    while ((match = sentenceBoundaryRegex.exec(txt))) {
      stcpositions.push(match.index);
    }

    stcpositions = [startNodePos, ...stcpositions.map(idx => startNodePos + idx), position].sort((a, b) => {
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    });

    return stcpositions;
  }
}

export function assertIsGoodQuoteTag(tag: Pick<ConferenceTag, 'name' | 'typeId'>) {
  return tag.typeId === ConferenceTagType.Global && tag.name === 'Good Quote';
}

/**
 * Returns a number between 0 and length-1 (inclusive)
 *
 * @internal
 */
export function rotateIndex(index: number, length: number): number {
  if (length <= 0) {
    return 0;
  }

  return ((index % length) + length) % length;
}