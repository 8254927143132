import Section from './Section';
import { Bold } from './Span';
import styles from './style/Terms.css';

export default function BrandInsights() {
  return (
    <div className={styles.wrap}>
      <div className={styles.terms}>
        <Section>
          <p>{`This Brand Insights AI Software Terms of Use, (this `}{`"`}<Bold>{`Agreement`}</Bold>{`"), is a binding contract between Trinity Partners, LLC (`}{`"`}<Bold>{`Trinity`}</Bold>{`") and the individual intending to access or otherwise use the Software, hereinafter referred to as (`}{`"`}<Bold>{`Licensee`}</Bold>{`").`}</p>
        </Section>
        <Section>
          <p>{`TRINITY PROVIDES THE SOFTWARE SOLELY ON THE TERMS AND CONDITIONS SET FORTH IN THIS AGREEMENT AND ON THE CONDITION THAT LICENSEE ACCEPTS AND COMPLIES WITH THEM. BY CLICKING THE "ACCEPT" BUTTON BELOW   OR OTHERWISE USING THE SOFTWARE, YOU AGREE, AS THE LICENSEE, TO BE BOUND BY THIS AGREEMENT. YOU HEREBY (A) ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS; AND (B) REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, POWER AND AUTHORITY TO ENTER INTO THIS AGREEMENT. IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, TRINITY WILL NOT AND DOES NOT LICENSE THE SOFTWARE TO YOU AND YOU MUST NOT ACCESS OR USE THE SOFTWARE OR DOCUMENTATION.`}</p>
        </Section>
        <Section>
          <p>{`1.	`}<u>{`Definitions`}</u>{`. For purposes of this Agreement, the following terms have the following meanings:`}</p>
          <p className={styles.ind}>{`"`}<Bold>{`Authorized User`}</Bold>{`" means the individual who (i) has agreed to be bound by the terms of this Agreement; and (ii) is authorized to use the Software and Documentation and has been supplied a user identification and password by Trinity;`}</p>
          <p className={styles.ind}>{`"`}<Bold>{`Content`}</Bold>{`" shall have the meaning set forth in Section 7.`}</p>
          <p className={styles.ind}>{`"`}<Bold>{`Documentation`}</Bold>{`" means all documentation and other materials related to the Software and provided by Trinity, either in hardcopy or electronic form, including user manuals, help files and any other instructions, specifications, documents, and materials that describe the functionality, installation, testing, operation, use, maintenance, support, technical features, or requirements of the Software.`}</p>
          <p className={styles.ind}>{`"`}<Bold>{`Effective Date`}</Bold>{`" means the date of Licensee’s acceptance of this Agreement, or as otherwise communicated by Trinity to Licensee. `}</p>
          <p className={styles.ind}>{`"`}<Bold>{`Feedback`}</Bold>{`" shall have the meaning set forth in Section 9.`}</p>
          <p className={styles.ind}>{`"`}<Bold>{`Fee`}</Bold>{`" or "`}<Bold>{`Fees`}</Bold>{`" hall mean the fees payable pursuant to Section 3 hereof;`}</p>
          <p className={styles.ind}>{`"`}<Bold>{`Input`}</Bold>{`" shall have the meaning set forth in Section 7.`}</p>
          <p className={styles.ind}>{`"`}<Bold>{`Intellectual Property Rights`}</Bold>{`" means any and all intellectual property or proprietary rights throughout the world, including all: (i) patent rights (including patent applications and disclosures); (ii) registered and unregistered copyrights (including rights in software, including in source code and object code); (iii) registered and unregistered trademark rights; and (iv) trade secret rights.`}</p>
          <p className={styles.ind}>{`"`}<Bold>{`License`}</Bold>{`" has the meaning set forth in Section 2.`}</p>
          <p className={styles.ind}>{`"`}<Bold>{`Licensee`}</Bold>{`" has the meaning set forth in the preamble.`}</p>
          <p className={styles.ind}>{`"`}<Bold>{`Trinity`}</Bold>{`" has the meaning set forth in the preamble.`}</p>
          <p className={styles.ind}>{`"`}<Bold>{`Order Form`}</Bold>{`" shall mean a written document, including but not limited to a statement of work, executed by Trinity and Licensee or Licensee’s organization that describes the Software, term, fees, the number of Authorized Users, and any other applicable terms and conditions applicable to Licensee’s access and use of the Software. Each executed Order Form is hereby incorporated into this Agreement in its entirety by this reference. An Order Form will take precedence over any other provision of this Agreement, provided that any conflict or inconsistency in an Order Form with any other provision of this Agreement will only apply to that specific Order Form.`}</p>
          <p className={styles.ind}>{`"`}<Bold>{`Output`}</Bold>{`" has the meaning set forth in Section 7.`}</p>
          <p className={styles.ind}>{`"`}<Bold>{`Person`}</Bold>{`" means an individual, corporation, partnership, joint venture, limited liability company, governmental authority, unincorporated organization, trust, association or other entity.`}</p>
          <p className={styles.ind}>{`"`}<Bold>{`Software`}</Bold>{`" means Trinity’s InsightEDGE application, inclusive of Trinity’s Brand Insights AI product, and any updates, upgrades, enhancements, releases, improvements, and any other adaptations or modifications made to such Brand Insights AI product (which may contain, among other things, error corrections or other changes to the functionality, compatibility, capabilities, performance, efficiency, or quality of such Brand Insights AI product), whether or not owned by Trinity.`}</p>
          <p className={styles.ind}>{`"`}<Bold>{`Term`}</Bold>{`" has the meaning set forth in Section 10.`}</p>
          <p className={styles.ind}>{`"`}<Bold>{`Third Party`}</Bold>{`" means any Person other than Licensee or Trinity.`}</p>
          <p className={styles.ind}>{`"`}<Bold>{`Third Party Licenses`}</Bold>{`" has the meaning set forth in Section 4.`}</p>
        </Section>
        <Section>
          <p>{`2.	License Grant and Scope. Subject to and conditioned upon Licensee's strict compliance with all of the terms and conditions set forth in this Agreement, Trinity hereby grants to Licensee a non-exclusive, non-transferable, non-sublicensable limited license (hereinafter, the "License") during the Term to use the Software and Documentation, solely as set forth in this Section 2 and subject to all of the conditions and limitations set forth in Section 5 or elsewhere in this Agreement. This License grants Licensee the right to:`}</p>
          <p className={styles.ind}>{`a.	use the Software only for Licensee’s internal business purposes in connection with access and synthesis of unstructured and structured data or documents, including summarization of current and past market research      (the "Permitted Use"). The reproduction, copying, or redistribution of content for commercial purposes is prohibited without the express written permission of Trinity; and`}</p>
        </Section>
        <Section>
          <p>{`3.	Fees and Payment. Except in the case of n0-charge evaluation of the Software, in exchange for the license granted above, commencing on the applicable date set forth in the Order Form, Licensee shall pay Trinity the fees set forth in the Order Form (hereafter "Fee" or "Fees").`}</p>
        </Section>
        <Section>
          <p>{`4.	Third-Party Software. The Software may include software, content, data or other materials, including related documentation, that are owned by Persons other than Trinity and that are provided to Licensee on terms that are in addition to and/or different from those contained in this Agreement ("Third-Party Licenses"). This License and Licensee’s use of the Software is subject to such Third Party Licenses. Licensee is bound by and shall comply with all Third-Party Licenses. Any breach by Licensee of any Third-Party License is also a breach of this Agreement.`}</p>
        </Section>
        <Section>
          <p>{`5.	Use Restrictions. Licensee shall not, directly or indirectly:`}</p>
          <p className={styles.ind}>{`(a)	use (including make any copies of) the Software or Documentation beyond the scope of this License;`}</p>
          <p className={styles.ind}>{`(b)	permit any other Person to use the Software or Documentation;`}</p>
          <p className={styles.ind}>{`(c)	modify, translate, adapt or otherwise create derivative works or improvements, whether or not patentable, of the Software or Documentation or any part thereof;`}</p>
          <p className={styles.ind}>{`(d)	reverse engineer, disassemble, decompile, decode or otherwise attempt to derive or gain access to the source code of the Software or any part thereof;`}</p>
          <p className={styles.ind}>{`(e)	remove, delete, alter or obscure any trademarks or any copyright, trademark, patent or other Intellectual Property Rights notices from the Software or Documentation, including any copy thereof;`}</p>
          <p className={styles.ind}>{`(f)	use the Software or Documentation in a way that infringes, misappropriates or violates any third party rights;`}</p>
          <p className={styles.ind}>{`(g)	except as contemplated by this License, copy the Software or Documentation, in whole or in part;`}</p>
          <p className={styles.ind}>{`(h)	use any method to extract data from the Software or Documentation, including web scraping, web harvesting, or web data extraction methods, other than as permitted through the Documentation;`}</p>
          <p className={styles.ind}>{`(i)	use the Software or Documentation in the operation of a service bureau;`}</p>
          <p className={styles.ind}>{`(j)	use the Software or Documentation for purposes of the development of a competing software product or service or any other purpose that is to the Trinity's commercial disadvantage;`}</p>
          <p className={styles.ind}>{`(k)	mislead anyone by representing or labeling the content generated by the Software as human-generated content;`}</p>
          <p className={styles.ind}>{`(l)	use the Software to generate content that expresses harmful, false, deceiving or misleading information, misuses personal data, contains malware, unsolicited bulk content, ransomware, viruses, or other software; or`}</p>
          <p className={styles.ind}>{`(m)	use the Software or Documentation in violation of any foreign, federal, state or local law, regulation or rule; `}</p>
        </Section>
        <Section>
          <p>{`6.	Responsibility for Use of Software. Licensee is responsible and liable for all uses of the Software through access thereto provided by Licensee, directly or indirectly. Specifically, and without limiting the generality of the foregoing, Licensee is responsible and liable for all actions and failures to take required actions with respect to the Software or by any other Person to whom Licensee may provide access to or use of the Software, whether such access or use is permitted by or in violation of this Agreement.`}</p>
        </Section>
        <Section>
          <p>{`7.	Content. The output insights generated and returned by the Software ("Output") are based on the data processed on and through the interface ("Input"), the Input and Output shall collectively be referred herein as "Content." The Licensee is solely responsible for ensuring that Licensee’s use of the Content complies with applicable laws, rules, regulations.  Licensee is the owner of both the Input as well as any Output generated through Licensee’s use of the Software. The Content may be used for any legal and lawful purpose, including commercial purposes and publication, at Licensee’s own risk. Prior to publication, it is recommended to add a disclosure that the Output was generated by AI tools. Due to the nature of machine learning, Output may not be unique or accurate and the Software may generate the same or similar output for other customers.`}</p>
        </Section>
        <Section>
          <p>{`8.	Maintenance and Support. This License does not entitle Licensee to any maintenance or support services with respect to the Software. Trinity is not obligated to update, upgrade, enhance or improve the Software.`}</p>
        </Section>
        <Section>
          <p>{`9.	Feedback. Licensee may provide its feedback regarding the Software via the Software user interface, or as otherwise gathered or ascertained by Trinity via Trinity’s review of system logs, surveys, questionnaires or activity and usage data ("Feedback"). The Feedback may include, but is not limited to, Feedback on the performance, features that may be missing, suggestions on improvement, user experience, ratings or annotations of Output given certain Input, any error logs or records of difficulties or bugs encountered during the use of the Software or any other system data or meta data required to contextualize the Software use and user experience. Any Feedback provided, grants Trinity a perpetual, irrevocable, non-exclusive, worldwide, fully-paid, sub-licensable, assignable license to incorporate into the Software or otherwise use any Feedback received from Licensee to improve the Software and/or to develop new products and services. All Feeback will be utilized exclusively on a de-identified basis, and consistent with the confidentiality obligations set forth herein, as well as all applicable privacy laws, rules, and regulations. Licensee irrevocably waives any moral rights in such Feedback pursuant to applicable copyright law. Trinity acknowledges that any Feedback is provided on an "as-is" basis with no warranties of any kind.`}</p>
        </Section>
        <Section>
          <p>{`10.	Intellectual Property Rights. Licensee acknowledges and agrees that the Software and Documentation are provided under license, and not sold, to Licensee. Licensee does not acquire any ownership interest in the Software or Documentation under this Agreement, or any other rights thereto other than to use the same in accordance with the License, and subject to all of the terms, conditions and restrictions under this Agreement. Trinity (and its licensors and service providers) reserve and shall retain their entire right, title and interest in and to the Software and Documentation and all Intellectual Property Rights arising out of or relating to the Software and Documentation, except as expressly granted to the Licensee in this Agreement. Licensee shall safeguard all Software and Documentation (including all copies thereof) from infringement, misappropriation, theft, misuse or unauthorized access. Licensee shall promptly notify Trinity if Licensee becomes aware of any infringement of Trinity’s Intellectual Property Rights in the Software or Documentation and fully cooperate with Trinity in any legal action taken by Trinity to enforce its Intellectual Property Rights.`}</p>
        </Section>
        <Section>
          <p>{`11.	Term and Termination.`}</p>
          <p className={styles.ind}>{`(a)	This Agreement and the License set forth herein shall commence on the Effective Date and shall remain in effect for the period specified on the Order Form or as otherwise communicated by Trinity to Licensee (the "Term").`}</p>
          <p className={styles.ind}>{`(b)	Either party may, upon written notice to the other party, terminate this Agreement for material breach, provided that such material breach is not cured within thirty (30) days following receipt of such notice.`}</p>
          <p className={styles.ind}>{`(c)	Upon expiration or earlier termination of this Agreement, the License shall also terminate, and Licensee shall cease using and (if applicable) destroy all copies of the Software and Documentation.`}</p>
          <p className={styles.ind}>{`(d)	Notwithstanding any expiration or termination of this Agreement, any provisions of this Agreement which by their terms are intended to survive expiration or termination of this Agreement shall so survive and continue in full force and effect.`}</p>
        </Section>
        <Section>
          <p>{`12.	Warranty Disclaimer. THE SOFTWARE AND DOCUMENTATION ARE PROVIDED TO LICENSEE "AS IS" AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, TRINITY, ON ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, WITH RESPECT TO THE SOFTWARE AND DOCUMENTATION, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OR TRADE PRACTICE. TRINITY PROVIDES NO WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND THAT THE SOFTWARE WILL MEET THE LICENSEE'S REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE OR WORK WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS OR BE ERROR FREE OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, TRINITY DOES NOT GUARANTY ACCURACY OF ANY OUTPUT GENERATED BY THE SOFTWARE. GIVEN THE PROBABILISTIC NATURE OF MACHINE LEARNING, AND AI, USE OF THE SOFTWARE MAY IN SOME SITUATIONS RESULT IN INCORRECT OUTPUT THAT DOES NOT ACCURATELY REFLECT THE DESIRED OUTCOME. THE LICENSEE SHALL EVALUATE THE ACCURACY OF ANY OUTPUT AND SHALL NOT RELY ON TRINITY TO DO SO.`}</p>
        </Section>
        <Section>
          <p>{`13.	Limitation of Liability. TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW:`}</p>
          <p className={styles.ind}>{`(a)	IN NO EVENT WILL TRINITY OR ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS OR SERVICE PROVIDERS, BE LIABLE TO THE LICENSEE OR ANY THIRD PARTY FOR ANY USE, INTERRUPTION, DELAY OR INABILITY TO USE THE SOFTWARE, LOST REVENUES OR PROFITS, DELAYS, INTERRUPTION OR LOSS OF SERVICES, BUSINESS OR GOODWILL, LOSS OR CORRUPTION OF DATA, LOSS RESULTING FROM SYSTEM OR SYSTEM SERVICE FAILURE, MALFUNCTION OR SHUTDOWN, FAILURE TO ACCURATELY TRANSFER, READ OR TRANSMIT INFORMATION, FAILURE TO UPDATE OR PROVIDE CORRECT INFORMATION, SYSTEM INCOMPATIBILITY OR PROVISION OF INCORRECT COMPATIBILITY INFORMATION OR BREACHES IN SYSTEM SECURITY, OR FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL OR PUNITIVE DAMAGES, WHETHER ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE AND WHETHER OR NOT TRINITY WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.`}</p>
          <p className={styles.ind}>{`(b)	IN NO EVENT WILL TRINITY'S AND ITS AFFILIATES', INCLUDING ANY OF ITS OR THEIR RESPECTIVE LICENSORS' AND SERVICE PROVIDERS', COLLECTIVE AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT, WHETHER ARISING OUT OF OR RELATED TO BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, EXCEED: (A) FOR ANY CLAIMS ARISING WITHIN 12 MONTHS OF THE EFFECTIVE DATE, THE TOTAL AMOUNT PAID AND PAYABLE TO TRINITY FOR THE PRODUCT AND/OR SERVICES IN CONNECTION WITH WHICH THE LIABILITY AROSE DURING SUCH PERIOD; AND (B) THEREAFTER, THE TOTAL AMOUNT PAID BY LICENSEE TO TRINITY FOR THE PRODUCT AND/OR SERVICES IN CONNECTION WITH WHICH THE LIABILITY AROSE DURING THE 12 MONTHS IMMEDIATELY PRECEDING THE CLAIM (DETERMINED AS OF THE DATE OF ANY FINAL JUDGMENT IN AN ACTION).`}</p>
          <p className={styles.ind}>{`(c)	THE LIMITATIONS SET FORTH IN THIS SECTION 13 SHALL APPLY EVEN IF THE LICENSEE'S REMEDIES UNDER THIS AGREEMENT FAIL THEIR ESSENTIAL PURPOSE.`}</p>
        </Section>
        <Section>
          <p>{`14.	Confidentiality. Each party shall maintain the confidentiality of any confidential information of the other party that it obtains pursuant to this Agreement consistent with the efforts such party uses to protect its own confidential and trade secret information of like importance, and shall take all reasonable steps to ensure that such confidential information is not disclosed or distributed by its employees or agents to third parties not subject in writing to an agreement to protect such confidential information. Each party agrees that the Software and Documentation shall be deemed confidential information of Trinity.`}</p>
        </Section>
        <Section>
          <p>{`15.	Miscellaneous.`}</p>
          <p className={styles.ind}>{`(a)	This Agreement shall be governed by and construed in accordance with the internal laws of the Commonwealth of Massachusetts without giving effect to any choice or conflict of law provision or rule (whether of the Commonwealth of Massachusetts or any other jurisdiction) that would cause the application of laws of any jurisdiction other than those of the Commonwealth of Massachusetts. Any legal suit, action or proceeding arising out of or related to this Agreement or the matters contemplated hereunder shall be instituted exclusively in the courts within the Commonwealth of Massachusetts, and each party irrevocably submits to the exclusive jurisdiction of such courts in any such suit, action or proceeding and waives any objection based on improper venue or forum non conveniens.`}</p>
          <p className={styles.ind}>{`(b)	The relationship between Licensee and Trinity established by this Agreement is that of independent contractors. No joint venture or partnership is established by this Agreement. Neither party is the agent, broker, partner, employee, or legal representative of the other for any purpose.`}</p>
          <p className={styles.ind}>{`(c)	This Agreement constitutes the sole and entire agreement between Licensee and Trinity with respect to the subject matter contained herein, and supersedes all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to such subject matter.`}</p>
          <p className={styles.ind}>{`(d)	Licensee shall not assign or otherwise transfer any of its rights, or delegate or otherwise transfer any of its obligations or performance, under this Agreement, in each case whether voluntarily, involuntarily, by operation of law or otherwise, without Trinity's prior written consent, which consent Trinity may give or withhold in its sole discretion. For purposes of the preceding sentence, and without limiting its generality, any merger, consolidation or reorganization involving Licensee (regardless of whether Licensee is a surviving or disappearing entity) will be deemed to be a transfer of rights, obligations or performance under this Agreement for which Trinity’s prior written consent is required. No delegation or other transfer will relieve Licensee of any of its obligations or performance under this Agreement. Any purported assignment, delegation or transfer in violation of this Section is void. Trinity may freely assign or otherwise transfer all or any of its rights, or delegate or otherwise transfer all or any of its obligations or performance, under this Agreement without Licensee's consent. This Agreement is binding upon and inures to the benefit of the parties hereto and their respective permitted successors and assigns.`}</p>
          <p className={styles.ind}>{`(e)	This Agreement is for the sole benefit of the parties hereto and their respective successors and permitted assigns and nothing herein, express or implied, is intended to or shall confer on any other Person any legal or equitable right, benefit or remedy of any nature whatsoever under or by reason of this Agreement.`}</p>
          <p className={styles.ind}>{`(f)	If any term or provision of this Agreement is invalid, illegal or unenforceable in any jurisdiction, such invalidity, illegality or unenforceability shall not affect any other term or provision of this Agreement or invalidate or render unenforceable such term or provision in any other jurisdiction.`}</p>
        </Section>
      </div>
    </div>
  );
}

BrandInsights.displayName = 'Legal.Terms.BrandInsights';

export { BrandInsights };