import { useContext, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { WorkspaceTranscriptContainer } from '@containers/Transcript';
import { useHasDataStewardRole, useIsInternalUser } from '@containers/Store/hooks';
import { TranscriptHasMediaContext, TranscriptMediaContext, WorkspaceTranscriptDetailsContext } from '@containers/Transcript/context';
import { TranscriptLockContext } from '@containers/Transcript.Redaction/Context';
import { TranscriptPublishType } from '@enums';
import { cx } from '@utils';
import { TranscriptLocked } from '@screens/Transcript/TranscriptLocked';
import { TranscriptMedia as Media } from '@screens/Transcript/Transcript.Media';
import { TranscriptTabs } from '@screens/Transcript/TranscriptTabs';
import { TranscriptToolbar as Toolbar } from '@screens/Transcript/Transcript.Toolbar';
import { TranscriptScreenContainer } from '@screens/Transcript/Container';
import styles from '@screens/Transcript/style/Transcript.css';
import { WorkspaceTranscriptBreadcrumbs as Breadcrumbs } from './Breadcrumbs';
import { useWorkspaceTranscriptParams } from './hooks/useWorkspaceTranscriptParams';
import { useWorkspaceTranscriptTabs } from './hooks';
import { TranscriptHeader as Header } from './Header';

const WorkspaceTranscript = () => {

  const hasDataStewardRole = useHasDataStewardRole();
  const hasMedia = useContext(TranscriptHasMediaContext);
  const detailsCtx = useContext(WorkspaceTranscriptDetailsContext);
  const [isLocked] = useContext(TranscriptLockContext);
  const media = useContext(TranscriptMediaContext);
  const routes = useWorkspaceTranscriptTabs();

  const isInternalUser = useIsInternalUser();

  const access = useMemo(() => {
    return {
      recording: isInternalUser || canAccessRecording(detailsCtx?.settings?.publishType),
    };
  }, [
    detailsCtx?.settings?.publishType,
    isInternalUser,
  ]);

  const mediaClassName = cx({
    [styles.video]: media.isVideo === true,
    [styles.audio]: media.isVideo === false,
  });

  return (
    <>
      <Helmet title="Workspace Transcript" />

      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.main}>

            <div className={styles.toolbar}>
              <Breadcrumbs />

              <Toolbar />
            </div>

            <div className={mediaClassName}>
              <div className={styles.transcript}>
                <div className={styles.header}>
                  {!hasDataStewardRole && <Header />}

                  {access.recording && hasMedia && <Media />}

                  <div className={styles.tabview}>
                    {isLocked && <TranscriptLocked />}
                    {!isLocked && <TranscriptTabs routes={routes} />}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

const WithContainer = () => {
  const { transcriptId } = useWorkspaceTranscriptParams();

  return (
    <WorkspaceTranscriptContainer transcriptId={transcriptId}>
      <TranscriptScreenContainer>
        <WorkspaceTranscript />
      </TranscriptScreenContainer>
    </WorkspaceTranscriptContainer>
  );
};

WorkspaceTranscript.displayName = 'Workspace.Transcript';

export { WithContainer as WorkspaceTranscript };

function canAccessRecording(typeId: TranscriptPublishType) {
  return typeId === TranscriptPublishType.RecordingAndTranscript
    || typeId === TranscriptPublishType.RecordingOnly;
}