import { memo, useCallback, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { MessageQueryHint } from '@/brand-insights/components/presentation';
import { ChatActionType, ChatHintType } from '@/brand-insights/enums';
import type { Chat } from '@/brand-insights/types';
import { SubmitActionContext } from './context';

type Props = {
  className?: string;
  item: Chat.SuggestedActions.QueryFiles;
  query: string;
};

export const QueryFilesSuggestedAction = memo((props: Props) => {

  const ctx = useContext(SubmitActionContext);

  const submitAction = useCallback(() => {
    const fullQuery = [
      `Please answer the following question:`,
      ``,
      `"${props.query}"`,
      ``,
      `Using only available transcripts and documents.`,
    ].join('\n');

    return ctx({
      query: props.query,
      type: ChatActionType.QueryFiles,
    }, fullQuery);
  }, [ctx, props.query]);

  const hint: Chat.QueryHint.Item = useMemo(() => ({
    displayValue: `I have a question about documents and transcripts only`,
    type: ChatHintType.Text,
  }), []);

  return (
    <div className={props.className} onClick={submitAction}>
      <OrText>{`Or, if your question isn't related to surveys, choose the following:`}</OrText>
      <StyledHint
        hint={hint}
        onClick={submitAction} />
    </div>
  );
});

const StyledHint = styled(MessageQueryHint)({
  marginTop: 8,
});

const OrText = styled.div({
  marginBottom: 10,
});