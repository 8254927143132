import {
  useSubmitAnswer,
  useSurveyQuestionGoBack,
  useSurveyFormIsFirstItem,
} from './hooks';
import { SurveyFormItemBackContext, SurveyFormItemSubmitContext } from './Context';

type Props =
  ChildrenProps;

export const SurveyFormBackContainer = (props: Props) => {

  const goBack = useSurveyQuestionGoBack();
  const isFirstQuestion = useSurveyFormIsFirstItem();

  const ctx = {
    disabled: isFirstQuestion,
    isError: goBack.isError,
    isLoading: goBack.isLoading,
    handler: goBack.mutateAsync,
  };

  return (
    <SurveyFormItemBackContext.Provider value={ctx}>
      {props.children}
    </SurveyFormItemBackContext.Provider>
  );
};

export const SurveyFormSubmitContainer = (props: Props) => {

  const submitAnswer = useSubmitAnswer();

  const ctx = {
    isError: submitAnswer.isError,
    isLoading: submitAnswer.isLoading,
    handler: submitAnswer.mutateAsync,
  };

  return (
    <SurveyFormItemSubmitContext.Provider value={ctx}>
      {props.children}
    </SurveyFormItemSubmitContext.Provider>
  );
};