import { SurveyTaggingResultType } from '@/enums';
import { SurveyTagging, SurveyBuilderVersion } from '@/types/survey';
import { Validation } from './interfaces';

export function validateTaggingAction({ action }: SurveyTagging.Item, survey: SurveyBuilderVersion): Validation {

  if (!action.type) {
    return {
      message: 'Action type must be set',
      success: false,
    };
  }

  switch (action.type) {
    case SurveyTaggingResultType.Classification: {
      if (!action.value.identifier) {
        return {
          message: 'Classification must be selected',
          success: false,
        };
      }

      return {
        success: true,
      };
    }

  }

  return {
    success: true,
  };
}