import { useMemo } from 'react';
import { Info } from 'react-feather';
import { useSegmentQuestions } from '@containers/Survey';
import { Tooltip } from '@presentation/Tooltip';

type Props = {
  questionIdentifier: string;
};

export const SegmentedDataTooltip = ({ questionIdentifier }: Props) => {

  const segmentQuestions = useSegmentQuestions(questionIdentifier);

  const tooltip = useMemo(() => {
    if (!segmentQuestions.length) return null;

    return `Filter on ${segmentQuestions.map(m => `Q${m.ordinal}`).join(', ')} to see segmented data`;

  }, [segmentQuestions]);

  if (!tooltip) return null;

  return (
    <Tooltip
      tooltipstyles={{
        maxWidth: 190,
      }}
      title={tooltip}>
      <div>
        <Info color="var(--black-l)" />
      </div>
    </Tooltip>
  );
};