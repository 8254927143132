import { useContext, useMemo } from 'react';
import { ProjectTagsQueryContext } from '@containers/Group.Project.Tags/Context';
import { ConferenceTagType } from '@enums';
import { useProjectTagsWithChildren } from '@/containers/Group.Project.Tags/Project.Tags.Query.Container';

export const useTagsOnboardingEnabled = () => {
  const query = useContext(ProjectTagsQueryContext);
  const tags = useProjectTagsWithChildren();

  const enabled = useMemo(() => {
    if (query.isInitialLoading || !query.isFetchedAfterMount) return false;

    const hasCreatedProjectTags = tags.some(x => {
      return x.base.typeId === ConferenceTagType.Project
          || x.children.some(child => child.base.typeId === ConferenceTagType.Project);
    });

    return !hasCreatedProjectTags;
  }, [
    query.isFetchedAfterMount,
    query.isInitialLoading,
    tags,
  ]);

  return {
    enabled,
    loading: query.isInitialLoading,
  };
};