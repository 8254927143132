import { useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import Select, { createFilter, ActionMeta, OnChangeValue } from 'react-select';
import * as api from '@api';
import * as QK from '@consts/querykey';
import { createSelectStyles, createSelectTheme } from './select-styles';

type Props = {
  className?: string;
  country:    string;
  onChange:   (newValue: OnChangeValue<Item, false>, actionMeta: ActionMeta<Item>) => unknown;
  value:      string;
};

export const Timezone = (props: Props) => {
  const query = useQuery([QK.Search.Timezones.Get], () => {
    return api.search.fetchTimezones()
                     .then(res => res.items.map(x => ({
                       country: x.country,
                       label: x.timezone.replace(/_/g, ' '),
                       value: x.timezone,
                     })));
  }, {
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000,
  });

  const filterOption = useCallback((option: FilterOptionOption<Item>, rawInput: string) => {
    const defaultFilter = createFilter<Item>({
      ignoreCase: true,
      ignoreAccents: true,
    });

    const sectionFilter = (option: FilterOptionOption<Item>, input: string) => {
      if (input) {
        return option.data.value?.toLowerCase().split('/').some(s => s.startsWith(input.toLowerCase()));
      }
      return true;
    };

    const countryFilter = (option: FilterOptionOption<Item>) => {
      if (props.country) {
        return option.data.country === props.country || option.data.value === props.value;
      }
      return true;
    };

    if (option.data.label.match(new RegExp(rawInput, 'i'))) {
      return true;
    }

    return countryFilter(option) || (defaultFilter(option, rawInput) || sectionFilter(option, rawInput));
  }, [props.country, props.value]);

  const selectStyles = useMemo(() => createSelectStyles<Item>(), []);
  const selectTheme = useMemo(() => createSelectTheme(), []);

  return (
    <Select
      className={props.className}
      defaultValue={query.data?.find(i => i.value === props.value)}
      value={query.data?.find(i => i.value === props.value)}
      options={query.data || []}
      getOptionLabel={o => o.label}
      getOptionValue={o => o.value}
      noOptionsMessage={v => `Timezone not found`}
      placeholder="Select timezone"
      onChange={props.onChange}
      filterOption={filterOption}
      styles={selectStyles}
      theme={selectTheme} />
  );
};

Timezone.displayName = 'MedicalProfile.Field.Timezone';

type Item = {
  country: string;
  label: string;
  value: string;
};

type FilterOptionOption<D> = {
  label: string;
  value: string;
  data: D;
};