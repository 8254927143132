import { useMemo } from 'react';
import type { SurveyQuestionType } from '@/enums';
import {
  useSurveyFormQuestionContext,
  useValidateRationale,
} from './hooks';
import { SurveyFormCanSubmitContext, useSurveyFormQuestionAnswer } from './Context';
import { SurveyFormBackContainer, SurveyFormSubmitContainer } from './BaseNavigation';

type Props =
  ChildrenProps;

export const MultipleChoiceFormContainer = (props: Props) => {

  const [answer] = useSurveyFormQuestionAnswer<SurveyQuestionType.MultipleChoice>();
  const question = useSurveyFormQuestionContext<SurveyQuestionType.MultipleChoice>();
  const validateRationale = useValidateRationale();

  const canSubmit = useMemo(() => {

    const isRationaleValid = validateRationale();

    if (!isRationaleValid) return false;

    return hasSelection() &&
      isOtherValid();

    function hasSelection() {
      return !!answer?.optionId;
    }

    function isOtherValid() {
      const option = question.options.find(o => o.id === answer?.optionId);
      return option.metadata.isOpenEnded
        ? !!answer?.text?.length
        : true;
    }

  }, [answer, question, validateRationale]);

  return (
    <SurveyFormCanSubmitContext.Provider value={canSubmit}>
      <SurveyFormBackContainer>
        <SurveyFormSubmitContainer>
          {props.children}
        </SurveyFormSubmitContainer>
      </SurveyFormBackContainer>
    </SurveyFormCanSubmitContext.Provider>
  );
};

export default MultipleChoiceFormContainer;