import { useMemo } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CellTopic } from '../Cells';

export default function useTopicsTableColumns() {
  return useMemo(() => [
    {
      accessor: 'name',
      id: 'name',
      Header: 'Name',
      Cell: CellTopic,
      minWidth: 75,
    },
  ], []);
}

export { useTopicsTableColumns };