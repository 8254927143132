import { useContext } from 'react';
import { FileUploadReviewContainer } from '@/containers/WorkspaceFileUpload/Upload.Review';
import { FilesTable, FilesTableLoadingContext } from '@/components/Workspace.FilesTable';
import { FolderFilesContainer } from '@/containers/Workspace.Folder/Folder.Files.Container';
import { TabViewTableSkeleton } from '@/components/TabView';
import styles from './style/Workspace.Folder.css';

const FolderTabFiles = () => {

  const loading = useContext(FilesTableLoadingContext);

  return (
    <div className={styles.tableWrap}>
      {loading
        ? <TabViewTableSkeleton />
        : <FilesTable />
      }
    </div>
  );
};

const WithState = () => (
  <FileUploadReviewContainer>
    <FolderFilesContainer>
      <FolderTabFiles />
    </FolderFilesContainer>
  </FileUploadReviewContainer>
);

export { WithState as FolderTabFiles };
export default WithState;
