import { memo } from 'react';

type Props = {
  className?: string;
  size: number;
};

export const MailBulk = memo(({ className, size }: Props) => {
  return (
    <svg
      className={className}
      height={size}
      width={size}
      viewBox="0 0 23 20"
      xmlns="http://www.w3.org/2000/svg">
      <g
        transform="translate(1, 0)"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
        fill="none">
        <g
          transform="translate(6, 6)"
          strokeLinejoin="round">
          <path d="M1.5,1.13686838e-13 L13.5,1.13686838e-13 C14.325,1.13686838e-13 15,0.675 15,1.5 L15,10.5 C15,11.325 14.325,12 13.5,12 L1.5,12 C0.675,12 0,11.325 0,10.5 L0,1.5 C0,0.675 0.675,1.13686838e-13 1.5,1.13686838e-13 Z" />
          <polyline points="15 2 7.5 7 0 2" />
        </g>
        <path d="M2.9,14 L2.9,7 C2.9,4.790861 4.690861,3 6.9,3 L15.9,3 L15.9,3" />
        <path d="M0,11 L0,6 C-4.05812251e-16,2.6862915 2.6862915,6.08718376e-16 6,0 L13,0 L13,0" />
      </g>
    </svg>
  );
});