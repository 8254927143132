import { useState, useCallback } from 'react';
import { differenceInMinutes, differenceInSeconds } from 'date-fns';
import { useInterval } from '@utils';
import { OngoingDurationContext } from './Context';

type Props = {
  children: React.ReactNode;
  started: Date;
};

export const OngoingDurationContainer = ({ started, ...props }: Props) => {

  const [sec, setSec] = useState(generateSeconds(new Date(), started));
  const [min, setMin] = useState(generateMinutes(new Date(), started));

  const updateDuration = useCallback((date: Date = new Date()) => {
    const minutes = generateMinutes(date, started);
    const seconds = generateSeconds(date, started);

    setMin(minutes);
    setSec(seconds);
  }, [
    started,
    setMin,
    setSec,
  ]);

  useInterval(updateDuration, 1000, true);

  const value = {
    start: started,
    minutes: min,
    seconds: sec,
  };

  return (
    <OngoingDurationContext.Provider value={value}>
      {props.children}
    </OngoingDurationContext.Provider>
  );
};

OngoingDurationContainer.displayName = 'Tagging.OngoingDuration.Container';

function generateMinutes(start: Date, end: Date) {
  const total = differenceInMinutes(start, end);

  return total.toFixed().padStart(2, '0');
}

function generateSeconds(start: Date, end: Date) {
  const total = differenceInSeconds(start, end);

  return (total % 60).toFixed().padStart(2, '0');
}