import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ProjectStatus } from '@enums';
import { CardContent, CardHeader, Divider } from '@presentation/Main';
import Card from '@screens/Main.Member/Card';
import { ProjectBase } from '@/types';
import { ControlledTablePagination } from '@presentation/TablePagination';
import { ProjectStatusFilter } from '@/components/GroupProject';
import { Share } from '@/components/ShareLink';
import { ShareProjectFromExpert as ShareProjectFromExpertModal } from '@/components/Modal/ShareProjectWithExpert/FromExpert';
import Item from './AcceptedProjects.Item';
import Placeholder from './AcceptedProjects.Placeholder';
import styles from './style/AcceptedProjects.css';

const ItemsPerPage = 5;

const selectProjects = (status: ProjectStatus | 0) => (state: Store.State) => {
  if (!status) {
    return state.projects.ids.map(id => state.projects[id]);
  }

  return state.projects.ids.reduce<ProjectBase[]>((acc, projectId) => {
    const project = state.projects[projectId];

    return project.projectStatusId === status
      ? [...acc, project]
      : acc;
  }, []);
};

export const AcceptedProjects = () => {

  const [statusFilter, setStatus] = useState<0 | ProjectStatus>(ProjectStatus.Active);
  const [shareModal, setShareModal] = useState<number>(null);
  const [pageIndex, setPageIndex] = useState(0);

  const items = useSelector(selectProjects(statusFilter));

  const pageItems = useMemo(() => {
    const begin = pageIndex * ItemsPerPage;
    const end = begin + ItemsPerPage;

    return items.slice(begin, end);
  }, [items, pageIndex]);

  const pageCount = useMemo(() => Math.ceil(items.length / ItemsPerPage), [items.length]);

  return (
    <>
      <Card>
        <CardHeader className={styles.header}>
          <div>Projects</div>
          <ProjectStatusFilter
            className={styles.filter}
            onChange={setStatus}
            value={statusFilter} />
        </CardHeader>
        <Divider />
        <CardContent fluid>
          <div className={styles.body}>
            {!items.length && <Placeholder status={statusFilter} />}
            {pageItems.map(m => (
              <Item
                key={m.id}
                className={styles.item}
                item={m}>
                {m.projectStatusId === ProjectStatus.Active &&
                  <Share
                    className={styles.share}
                    onClick={() => setShareModal(m.id)} />}
              </Item>
            ))}
          </div>
          {items.length > ItemsPerPage &&
            <div className={styles.footer}>
              <ControlledTablePagination
                goToPage={setPageIndex}
                pageIndex={pageIndex}
                pageCount={pageCount}
                pageSize={ItemsPerPage}
                totalCount={items.length} />
            </div>
          }
        </CardContent>
      </Card>
      {shareModal &&
        <ShareProjectFromExpertModal
          projectId={shareModal}
          onClose={() => setShareModal(null)} />}
    </>
  );
};

export default AcceptedProjects;