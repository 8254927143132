import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://974e745bea7849ac965e8693a3213ebc@s.sentimentglobal.com/2',
  enabled: process.env.ERROR_TRACKING as unknown as boolean,
  environment: process.env.VANCERY_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        new URL(process.env.BACKEND_BASE_URL).hostname,
        'localhost',
        /^\//,
      ],
    }),
    new Sentry.Integrations.Dedupe(),
  ],
  sampleRate: 1,
  tracesSampleRate: 1,
  // tracesSampleRate: process.env.VANCERY_ENV === 'production' ? 0.2 : 0.5,
  release: process.env.SENTRY_RELEASE,
});