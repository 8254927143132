import { useState, useMemo, useEffect, useCallback } from 'react';
import type {
  DragStartEvent,
  UniqueIdentifier,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { X, Plus } from 'react-feather';
import type { RankingQuestion } from '@/types/survey';
import { useSurveyFormQuestionAnswer } from '@/containers/SurveyForm';
import type { SurveyQuestionType } from '@/enums';
import { indexBy } from '@/utils/array';
import { SurveyQuestionOption } from '../Survey.RichText';
import styles from './style/Ranking.Story.css';

export type Props = {
  items: RankingQuestion.FormOption[];
};

export const RankingStory = (props: Props) => {
  const [answer, setAnswer] = useSurveyFormQuestionAnswer<SurveyQuestionType.Ranking>();
  const [items, setItems] = useState(resolveAnswerItems(props.items, answer));
  const [activeId, setActiveId] = useState<UniqueIdentifier>(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const removedOptions = useMemo(() => props.items.filter(i => !answer.options.some(o => o.id === i.id)), [props.items, answer.options]);
  const activeOption = useMemo(() => props.items.find(i => i.id === activeId), [props.items, activeId]);

  const setAnswerOptions = useCallback((options: RankingQuestion.RespondentAnswer.Value['options']) => {
    setAnswer(({
      dontKnow: answer.dontKnow,
      openEndedValues: answer.openEndedValues,
      options,
    }));
  }, [answer, setAnswer]);

  const removeItem = useCallback((id: number) => {
    const orderedItems = items.filter(i => i.id !== id);
    setAnswerOptions(orderedItems.map((option, i) => ({ id: option.id, ordinal: i + 1 })));
    setItems(orderedItems);
  }, [setAnswerOptions, items]);

  const addBackItem = useCallback((id: number) => {
    const item = props.items.find(i => i.id === id);
    const orderedItems = [...items, item];
    setAnswerOptions(orderedItems.map((option, i) => ({ id: option.id, ordinal: i + 1 })));
    setItems(orderedItems);
  }, [setAnswerOptions, items, props.items]);

  //Bootstrap the answer with the default ordering if there isn't an answer
  useEffect(() => {
    if (!answer.options?.length) {
      setAnswer({
        ...answer,
        options: items.map((option, i) => ({ id: option.id, ordinal: i + 1 })),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}>
        <SortableContext items={items} strategy={rectSortingStrategy}>
          <Grid columns={4}>
            {items.map((option, index) => (
              <SortableOption
                key={option.id}
                option={option}
                onRemove={removeItem}
                index={index} />
            ))}
          </Grid>
        </SortableContext>

        <DragOverlay adjustScale={true}>
          {activeId ? (
            <div className={styles.draggingOption}>
              <Option option={activeOption} />
            </div>
          ) : null}
        </DragOverlay>
      </DndContext>
      {!!removedOptions.length &&
        <div className={styles.removedOptions}>
          {removedOptions.map((o => (
            <AddableOption
              key={o.id}
              option={o}
              onAdd={id => addBackItem(id)} />
          )))}
        </div>
      }
    </>
  );

  function handleDragStart(event: DragStartEvent) {
    setActiveId(event.active.id);
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems(items => {
        const overItem = items.find(i => i.id === over.id);
        const oldIndex = items.indexOf(activeOption);
        const newIndex = items.indexOf(overItem);

        const newOrder = arrayMove(items, oldIndex, newIndex);

        setAnswer({
          openEndedValues: answer.openEndedValues,
          dontKnow: answer.dontKnow,
          options: newOrder.map((option, i) => ({ id: option.id, ordinal: i + 1 })),
        });

        return newOrder;
      });
    }

    setActiveId(null);
  }

  function handleDragCancel() {
    setActiveId(null);
  }
};

type SortableOptionProps = {
  option: RankingQuestion.FormOption;
  index: number;
  onRemove: (id: number) => void;
};
const SortableOption = ({ option, onRemove, ...props }: SortableOptionProps) => {
  const sortable = useSortable({ id: option.id });

  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transform,
    transition,
  } = sortable;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? '100' : 'auto',
    opacity: isDragging ? 0.3 : 1,
  };

  return (
    <div
      ref={setNodeRef}
      className={styles.draggableOption}
      style={style}
      {...props}
      {...attributes}>
      <div
        {...listeners}>
        <Option option={option} />
      </div>

      {onRemove &&
        <div className={styles.optionAction}>
          <X className={styles.removeAction} onClick={() => onRemove(option.id)} />
        </div>
      }
    </div>
  );
};

type OptionProps = {
  className?: string;
  option: RankingQuestion.FormOption;
};
const Option = ({ option, className }: OptionProps) => {
  return (
    <div className={className}><SurveyQuestionOption value={option.value} imageDisplayOptions={{ sizeOverrides: { height: '40vh', width: null } }} /></div>
  );
};

type AddableOptionProps = {
  option: RankingQuestion.FormOption;
  onAdd: (id: number) => void;
};
const AddableOption = ({ option, onAdd }: AddableOptionProps) => {
  return (
    <div className={styles.draggingOption}>
      <Option option={option} />
      <div className={styles.optionAction}>
        <Plus className={styles.addAction} onClick={() => onAdd(option.id)} />
      </div>
    </div>
  );
};

type GridProps = {
  columns: number;
} & ChildrenProps;
export function Grid({ children, columns }: GridProps) {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        gridGap: 10,
        padding: 10,
      }}>
      {children}
    </div>
  );
}

function resolveAnswerItems(options: RankingQuestion.FormOption[], answer: RankingQuestion.RespondentAnswer.Value) {
  if (answer.options.length) {
    const index = indexBy(answer.options, k => k.id, v => v.ordinal);
    return options.filter(o => index[o.id]).sort((a, b) => index[a.id] - index[b.id]);
  } else {
    return options;
  }
}