import { useSelector } from 'react-redux';

const selectBrandInsightsAuthor = (state: Store.State) => {
  return {
    id: state.user.id,
    profile: {
      firstName: state.user.profile.firstName,
      lastName: state.user.profile.lastName,
    },
  };
};

export const useBrandInsightsAuthor = () => {
  const author = useSelector(selectBrandInsightsAuthor);

  return author;
};