import axios, { type AxiosResponse } from 'axios';
import snake from 'snakecase-keys';
import { getXSID } from '@/services/auth/session';
import { logResponseErrorInterceptor, logRequestInterceptor } from './interceptors';

const BRAND_INSIGHTS_BASE_URL = new URL(process.env.BRAND_INSIGHTS_API_URL);
const baseURL = `${process.env.BRAND_INSIGHTS_API_URL}`;

export const client = axios.create({
  baseURL,
});

client.interceptors.request.use(
  config => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    config.data = config.data ? snake(config.data) : config.data;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    config.params = config.params ? snake(config.params) : config.params;

    const url = new URL(config.baseURL);
    if (url.hostname === BRAND_INSIGHTS_BASE_URL.hostname) {
      config.withCredentials = true;
      if (process.env.__DEV__) {
        const xsid = getXSID();
        if (xsid) {
          config.headers.set('X-SID', xsid);
        }
      }
    }

    logRequestInterceptor(config);

    return config;
  },
);

client.interceptors.response.use(
  (res: AxiosResponse) => res,
  (res: AxiosResponse) => {
    logResponseErrorInterceptor(res);
    return Promise.reject(res);
  },
);