import { useCallback, useMemo } from 'react';
import cuid from 'cuid';
import { getFindAndReplaceGroupingId, getFindAndReplaceLabel } from '@/containers/Survey/utils/rich-text.find-and-replace';
import { ContextMenuItemProps } from '@/components/SurveyBuilder/ContextMenu.Item';
import { useAddFindAndReplaceNode } from '@/components/Survey.RichText/hooks/useAddFindAndReplaceNode';
import { useFindAndReplaceEditModal } from '@/components/Survey.RichText/Node.FindAndReplace.Modal.Edit';

export const useFindAndReplaceOptionMenuItem = (): ContextMenuItemProps => {
  const [toggleModal, Modal] = useFindAndReplaceEditModal();
  const addNode = useAddFindAndReplaceNode();

  const handleClick = useCallback(() => {
    toggleModal();
  }, [toggleModal]);

  const handleSubmit = useCallback((label: string) => {
    const identifier = cuid();

    addNode({
      identifier,
      groupingId: getFindAndReplaceGroupingId(label, label),
      label: getFindAndReplaceLabel(label, 1, 1),
      value: getFindAndReplaceLabel(label, 1, 1),
    });

    toggleModal();
  }, [addNode, toggleModal]);

  return useMemo(() => ({
    children: 'Insert structured entry',
    onClick: handleClick,
    Modal: () => <Modal onSubmit={handleSubmit} />,
  }), [
    Modal,
    handleClick,
    handleSubmit,
  ]);
};