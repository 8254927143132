import { useCallback, useMemo } from 'react';
import { useParams, useLocation, Redirect, Route, Switch, generatePath } from 'react-router-dom';
import * as consts from '@/consts';
import { TabView } from '@/components/TabView';
import { useTabViewState } from '@/components/TabView/hooks/useTabViewState';
import { type RouteParams, RouteKey, type SceneMap } from './interfaces';

type Props = {
  map: SceneMap;
  path?: string;
};

export const FileTabView = ({
  map,
  path = consts.path.Workspaces.FileTabs,
}: Props) => {
  const params = useParams<RouteParams>();
  const location = useLocation();

  const keys = useMemo(() => Object.keys(map) as RouteKey[], [map]);
  const routes = useMemo(() => keys.map(k => ({ key: k, ...map[k] })), [keys, map]);

  const formatPath = useCallback((tab: RouteKey) => generatePath(path, { ...params, tab }), [path, params]);

  const [{ index }, updateLocation] = useTabViewState<RouteKey>({
    formatPath,
    routes: keys,
  });

  const renderTabView = useCallback(() => {
    const defaultRoute = formatPath(RouteKey.Preview);

    return (
      <Switch>
        {keys.map((key: RouteKey) =>
          <Route
            key={key}
            path={map[key].path}
            component={map[key].component} />)}
        <Redirect to={`${defaultRoute}${location.search}${location.hash}`} />
      </Switch>
    );
  }, [
    location.search,
    location.hash,
    formatPath,
    keys,
    map,
  ]);

  return (
    <TabView
      index={index}
      onIndexChange={updateLocation}
      routes={routes}
      renderTabView={renderTabView} />
  );
};