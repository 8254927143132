import { memo } from 'react';
import { getLocationFor } from '@utils';
import { CompanyLogo } from '@/components/CompanyLogo';
import { MaybeLink } from '@/components/MaybeLink';
import { Building } from '@/components/icons';
import styles from './style/Icon.css';

type Props = {
  hasGroupChild: boolean;
  hasGroupParent: boolean;
  logoFileKey: string | null;
  companySlug: string;
};

export const EmploymentIcon = memo(({
  hasGroupChild,
  hasGroupParent,
  logoFileKey,
  companySlug,
}: Props) => {
  const renderIcon = () => {
    if (hasGroupParent) {
      return (
        <div className={styles.circle} />
      );
    } else if (logoFileKey) {
      const url = `${process.env.CDN_URL}/${logoFileKey}`;
      return (
        <MaybeLink to={getLocationFor.company.root(companySlug)}>
          <CompanyLogo
            className={styles.logo}
            pictureUrl={url} />
        </MaybeLink>
      );
    } else {
      return (
        <MaybeLink to={getLocationFor.company.root(companySlug)}>
          <Building
            className={styles.defaultIcon} />
        </MaybeLink>
      );
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.icon}>
        {renderIcon()}
      </div>
      {hasGroupChild && <div className={styles.verticalLine} />}
    </div>
  );
});

export default EmploymentIcon;