import { ProjectType } from '@enums';
import { ProjectDescription } from './Description';
import { Filters } from './Frame.Filters';
/* import { ProjectScreeningQuestions } from './ScreeningQuestions'; */
import { ProjectOverview } from './Overview';
import { ProjectCreationSurveyStepper } from './ProjectSurvey.Stepper';
import type { AutoFocusRefObject, ProjectCreationScreen } from './interfaces';
import { ProjectCreationSteps } from './interfaces';

export const ProjectCreationStepTitles = {
  [ProjectCreationSteps.ProjectOverview]: 'Overview',
  [ProjectCreationSteps.Description]: 'Description',
  /* [ProjectCreationSteps.ScreeningQuestions]: 'Screening Questions', */
  [ProjectCreationSteps.Survey]: 'Survey',
  [ProjectCreationSteps.AdditionalFilters]: 'Search Filters',
};

export const ProjectTypeSteps = {
  [ProjectType.Survey]: [
    ProjectCreationSteps.ProjectOverview,
    ProjectCreationSteps.Description,
    ProjectCreationSteps.Survey,
    ProjectCreationSteps.AdditionalFilters,
  ],
  [ProjectType.Call]: [
    ProjectCreationSteps.ProjectOverview,
    ProjectCreationSteps.Description,
    /* ProjectCreationSteps.ScreeningQuestions, */
    ProjectCreationSteps.AdditionalFilters,
  ],
  [ProjectType.SurveyCall]: [
    ProjectCreationSteps.ProjectOverview,
    ProjectCreationSteps.Description,
    ProjectCreationSteps.Survey,
    ProjectCreationSteps.AdditionalFilters,
  ],
};

export const ProjectCreationStepsMap = {
  [ProjectCreationSteps.ProjectOverview]: {
    key: ProjectCreationSteps.ProjectOverview,
    component: ProjectOverview,
  },
  [ProjectCreationSteps.Description]: {
    key: ProjectCreationSteps.Description,
    component: ProjectDescription,
  },
  /*
  [ProjectCreationSteps.ScreeningQuestions]: {
    key: ProjectCreationSteps.ScreeningQuestions,
    component: ProjectScreeningQuestions,
  },
  */
  [ProjectCreationSteps.Survey]: {
    key: ProjectCreationSteps.Survey,
    component: ProjectCreationSurveyStepper,
    ignoreRef: true,
  },
  [ProjectCreationSteps.AdditionalFilters]: {
    key: ProjectCreationSteps.AdditionalFilters,
    component: Filters,
  },
} as {
  [index: number]: ProjectCreationScreen<AutoFocusRefObject>;
};