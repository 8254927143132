import { memo } from 'react';
import { Hash } from 'react-feather';
import { cx } from '@utils';
import styles from './style/Company.Logo.css';

type ClassNameProps = {
  className?: string;
  logoClassName?: string;
};

type Props = {
  classes?: {
    root?: string;
    img?: string;
  };
  url: string;
} & ClassNameProps;

export const CompanyLogo = memo(({ classes = {}, ...props }: Props) => {
  const root = cx(props.className, classes.root);
  const img = cx(props.logoClassName, classes.img);

  return props.url
    ? <CompanyLogoImage
        classes={{
          img,
          root,
        }}
        url={props.url} />
    : <CompanyLogoPlaceholder
        className={root}
        logoClassName={img} />;
});

export const CompanyLogoImage = memo(({ classes = {}, ...props }: Props) => {
  const root = cx(styles.root, props.className, classes.root);
  const img = cx(styles.image, props.logoClassName, classes.img);

  return (
    <div className={root}>
      <ImageOverlay className={img}>
        <img
          className={img}
          src={props.url} />
      </ImageOverlay>
    </div>
  );
});

export const CompanyLogoPlaceholder = memo((props: ClassNameProps) => {
  return (
    <div className={cx(styles.root, props.className)}>
      <div className={cx(styles.placeholder, props.logoClassName)}>
        <Hash />
      </div>
    </div>
  );
});

type ImageOverlayProps = {
  className: string;
  children:  React.ReactNode;
};

const ImageOverlay = (props: ImageOverlayProps) => {
  return (
    <div className={cx(styles.wrap, props.className)}>
      {props.children}
      <div className={styles.overlay} />
    </div>
  );
};