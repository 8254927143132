import { useContext } from 'react';
import styled from '@emotion/styled';
import Dialog from '@mui/material/Dialog';
import isMobile from 'ismobilejs';
import { ChatInstance, ChatSettings, ChatSettingsToggleContext } from '@/brand-insights/components/Chat';
import { GetStartedContext, ChatGetStarted } from '@/brand-insights/components/Chat.GetStarted';
import { ChatHeader } from '@/brand-insights/components/Chat.Header';
import { Constants, useTheme } from '@/brand-insights/components/Theme';
import { ChatSidebar, EmptySidebar } from './Sidebar';
import { Close } from './Close';
import { useMonitorChatIdentifier } from './hooks';

export type ChatModalProps = {
  onClose: () => void;
  open: boolean;
};

export const ChatModal = ({ open, onClose }: ChatModalProps) => {
  const device = isMobile();
  const theme = useTheme();

  const [showGetStarted, dismissGetStarted] = useContext(GetStartedContext);
  const showSideBar = !device.phone;
  const showHeader = !device.phone;

  useMonitorChatIdentifier();

  const [settings] = useContext(ChatSettingsToggleContext);

  return (
    <Dialog
      fullScreen={device.phone ? true : false}
      fullWidth={device.phone ? false : true}
      maxWidth={device.phone ? false : 'md'}
      onClose={onClose}
      sx={{
        zIndex: theme.zIndex.baseModal,
      }}
      PaperProps={{
        sx: {
          overflow: 'inherit !important',
        },
      }}
      open={open}>

      <Root>
        {showSideBar && <Sidebar />}
        <Wrap>
          {!settings && showHeader && <ChatHeader />}
          {settings
            ? <ChatSettings />
            : showGetStarted
              ? <StyledGetStarted onClick={dismissGetStarted} />
              : <ChatInstance />}
        </Wrap>
        {showSideBar && <StyledClose onClick={onClose} />}
      </Root>
    </Dialog>
  );
};

const Sidebar = () => {
  const [showGetStarted] = useContext(GetStartedContext);

  if (showGetStarted) {
    return <EmptySidebar />;
  } else {
    return <ChatSidebar />;
  }
};

const Root = styled.div(({ theme }) => ({
  position: 'relative',
  display: 'flex',

  height: '95vh',
  backgroundColor: theme.palette.secondary.main,
  borderRadius: `${Constants.Modal.BorderRadius}px`,
}));

const Wrap = styled.div(({ theme }) => ({
  boxSizing: 'border-box',
  width: '100%',
}));

const closeOffset = -20;

const StyledClose = styled(Close)(({
  position: 'absolute',
  right: `${closeOffset}px`,
  top: `${closeOffset}px`,
}));

const StyledGetStarted = styled(ChatGetStarted)(({
  marginTop: 30,
}));