import { forwardRef } from 'react';
import { cx } from '@utils';
import styles from './style/Toolbar.css';

type Props = {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  enabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => unknown;
};

export const ToolbarButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const classes = cx(styles.btn, props.className, {
    [styles.enabled]: props.enabled,
  });

  return (
    <button
      ref={ref}
      className={classes}
      disabled={props.disabled}
      onMouseDown={e => e.preventDefault()}
      onClick={props.onClick}>
      {props.children}
    </button>
  );
});