import { useCallback, useContext, useState } from 'react';
import Popover from '@mui/material/Popover';
import { usePopupState } from 'material-ui-popup-state/hooks';
import CloseIcon from '@mui/icons-material/Close';
import { MonologueDetailsEditSpeakerContext, TranscriptIdContext } from '@containers/Transcript/context';
import { useEditSpeakerSelectionMutation, useGetSpeakerLabel, useTranscriptSpeaker } from '@containers/Transcript/hooks';
import { ButtonActivityIndicator, ButtonOutlined } from '@presentation';
import { Select } from '$admin/components/Select';
import { Speaker } from './MonologueDetails.Speaker';
import styles from './style/Monologue.css';

type Props = {
  end: number;
  speakerIndex: number;
  start: number;
};

export const MonologueDetailsEditSpeaker = (props: Props) => {
  const popup = usePopupState({
    popupId: `md-edit-speaker`,
    variant: `popover`,
  });

  const speaker = useTranscriptSpeaker(props.speakerIndex);

  return (
    <div className={styles.name}>
      <button
        onClick={popup.open}>
        <Speaker {...speaker} />
      </button>
      <Popover
        sx={{
          marginTop: '10px',
        }}
        slotProps={{
          paper: {
            sx: {
              backgroundColor: 'var(--pri-01)',
              border: 'none',
              borderRadius: '8px',
              padding: '15px',
              boxShadow: '0 2px 6px 2px rgba(60,64,67,.15)',
            },
          },
        }}
        open={popup.isOpen}
        anchorEl={popup.anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus>
        <PopperContent
          end={props.end}
          start={props.start}
          onClose={popup.close}
          speakerIndex={props.speakerIndex} />
      </Popover>
    </div>
  );
};

MonologueDetailsEditSpeaker.displayName = 'MonologueDetails.EditSpeaker';

type PopperContentProps = {
  onClose: () => void;
} & Props;

const PopperContent = (props: PopperContentProps) => {
  const [speakerIndex, setSpeakerIndex] = useState(props.speakerIndex);
  const md = useContext(MonologueDetailsEditSpeakerContext);
  const transcriptId = useContext(TranscriptIdContext);

  const mutation = useEditSpeakerSelectionMutation({
    onSuccess: props.onClose,
    transcriptId,
  });

  const handleSubmit = useCallback(() => {
    mutation.mutate({
      speakerIndex,
      start: props.start,
      end: props.end,
    });
  }, [
    mutation,
    props.end,
    props.start,
    speakerIndex,
  ]);

  const getSpeakerLabel = useGetSpeakerLabel();

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <div className={styles.title}>Edit Speaker</div>
          <button
            disabled={mutation.isLoading}
            onClick={props.onClose}>
            <CloseIcon />
          </button>
        </div>
        <div className={styles.main}>
          <div className={styles.field}>
            <div className={styles.label}>{getSpeakerLabel(props.speakerIndex)}</div>
            <Select
              className={styles.select}
              onChange={e => setSpeakerIndex(e.target.value)}
              options={md?.options || []}
              placeholder="Select speaker"
              value={String(speakerIndex)} />
          </div>
        </div>
        <div className={styles.actions}>
          <ButtonOutlined
            className={styles.btn}
            color="silver"
            disabled={mutation.isLoading}
            onClick={props.onClose}
            size="small">
            Cancel
          </ButtonOutlined>

          <ButtonActivityIndicator
            className={styles.btn}
            disabled={speakerIndex === props.speakerIndex}
            implicitDisable={false}
            indicatorSize={10}
            loading={mutation.isLoading}
            onClick={handleSubmit}
            size="small"
            variant="brick">
            Save
          </ButtonActivityIndicator>
        </div>
      </div>
    </div>
  );
};