import type { Reducer } from 'react';
import { useReducer } from 'react';
import type { LeftBarState, LeftBarActions } from '../interfaces';

export const InitialLeftBarState: LeftBarState = {
  loading: false,
  popoutStatus: 'closed',
  barStatus: 'shown',
  zoomValue: 100,
  surveyItems: [],
  surveySections: [],
  activeItemId: null,
  completedItemIds: [],
};

export const useLeftBarState = (initialState = InitialLeftBarState) => {
  return useReducer<Reducer<LeftBarState, LeftBarActions.Actions>>(reducer, initialState);
};

const reducer: Reducer<LeftBarState, LeftBarActions.Actions> = (prev, action) => {
  switch (action.type) {
    case 'state-replaced':
      return action.state;
    case 'active-item-updated':
      return {
        ...prev,
        activeItemId: action.activeItemId,
      };
    case 'bar-status-updated':
      return {
        ...prev,
        barStatus: action.status,
      };
    case 'completed-items-updated':
      return {
        ...prev,
        completedItemIds: action.completedItemIds,
      };
    case 'loading-status-updated':
      return {
        ...prev,
        loading: action.loading,
      };
    case 'popout-status-updated':
      return {
        ...prev,
        popoutStatus: action.status,
      };
    case 'survey-items-updated':
      return {
        ...prev,
        surveyItems: action.items,
        surveySections: action.sections,
      };
    case 'zoom-value-updated':
      return {
        ...prev,
        zoomValue: action.value,
      };
    default:
      return prev;
  }
};