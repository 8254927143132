import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import * as actions from '@actions';
import * as consts from '@consts';
import * as api from '@api';
import { Tokens } from '@api/interfaces';
import * as xform from '@api/transformers';
import { useAppReadyState } from '@containers/AppReadyState';
import { ActionableLinkType } from '@enums';
import { getLocationFor, qs } from '@utils';
import { BookingConfirmed, LinkExpired, TimeNoLongerAvailable } from '@screens/ActionableCallTimeConfirmation';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import * as ActionableLink from './interfaces';

type Props = {
  params: ActionableLink.ActionableParams.AdHocCallTimeConfirmation;
  token:  ActionableLink.URLParams['token'];
};

type Response = Tokens.HandleActionableLink.AdHocCallTimeConfirmation.Res<Date>;
type TError = AxiosResponse<Response>;

export const AdHocCallTimeConfirmation = (props: Props) => {
  const dispatch = useDispatch();

  const app = useAppReadyState();

  const { mutateAsync, ...request } = useMutation<Response, TError>(['actionable-confirm-call', props.token], () => {
    return api.tokens.handleActionableLink<ActionableLinkType.AdHocCallTimeConfirmation>({
      end: props.params.end,
      start: props.params.start,
      token: props.token,
    }).then(res => ({
      ...res,
      call: xform.normalizeCall(res.call),
      conference: xform.normalizeConference(res.conference),
    }));
  }, {
    onSuccess: res => {
      const ready = app.authenticated && app.hydrated;

      if (ready) {
        dispatch(actions.batchActions([
          actions.adHocCallUpdated({
            call: res.call,
          }),
        ]));
      }
    },
  });

  useEffect(() => {

    if (!request.isIdle) return;

    if (app.authenticated && app.hydrated) {
      mutateAsync();
    }

    if (!app.authenticated) {
      mutateAsync();
    }
  }, [
    app.authenticated,
    app.hydrated,
    request.isIdle,
    mutateAsync,
  ]);

  if (request.isError) {
    if (request.error.status === 403) {
      const location = {
        ...getLocationFor.scheduling.adHoc.selecting({
          callId: request.error.data.call.id,
          scheduleeId: request.error.data.observerUserId,
          schedulerId: request.error.data.user.id,
        }),
        search: qs.from(consts.pathname.Home),
      };

      return (
        <TimeNoLongerAvailable
          location={location}
          user={request.error.data.user} />
      );

    } else {
      return (
        <LinkExpired />
      );
    }
  }

  if (request.isSuccess) {
    return (
      <BookingConfirmed
        user={request.data.user}
        call={request.data.call} />
    );
  }

  return (
    <ActivityIndicator show />
  );
};

export default AdHocCallTimeConfirmation;