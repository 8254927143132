import { useCallback, useContext } from 'react';
import * as api from '@api';
import { SearchLoadingDispatchContext, SearchResultsDispatchContext } from '@/containers/WorkspaceGlobalSearch/Context';
import type { WorkspaceSearchFull } from '@/types';

export default function useSearchFetchFolders() {
  const setResults = useContext(SearchResultsDispatchContext);
  const setLoading = useContext(SearchLoadingDispatchContext);

  const folders = useCallback(async ({ filters, size }: FetchData) => {
    const result = await api.workspaces.globalSearch.folders({
      filters,
      size,
    });

    if (result) {
      setResults(prev => ({
        ...prev,
        folders: result,
      }));
    }
  }, [setResults]);

  const fetch = useCallback(({ filters, size }: FetchData) => {
    setLoading(prev => ({ ...prev, folders: { ...prev.folders, loading: true } }));
    Promise.all([
      new Promise<void>(resolve => setTimeout(() => resolve(), 250)),
      folders({ filters, size }),
    ])
    .then(() => {
      setLoading(prev => ({ ...prev, folders: { loading: false } }));
    });
  }, [folders, setLoading]);

  return fetch;
}

export { useSearchFetchFolders };

type FetchData = {
  filters: WorkspaceSearchFull.Folder.Filters;
  size: number;
};