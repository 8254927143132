import { useHasConsultantRole } from '@containers/User';
import {
  useDisconnectSlack,
  useFetchIntegrations,
} from '@utils/api';
import { Slack } from '@/components/icons';
import { useAlert } from '@/components/Modal/Alert';
import { Integration } from '@/components/Settings';
import { useConnectSlack } from '@/components/Slack';
import styles from './style/Settings.Integrations.css';

export const SlackIntegration = () => {

  const connect = useConnectSlack();
  const [toggleAlert, Alert] = useAlert();

  const {
    data,
    isLoading,
    refetch,
  } = useFetchIntegrations({
    refetchOnMount: false,
  });

  const {
    mutateAsync: disconnect,
  } = useDisconnectSlack({
    onSuccess: res => {
      refetch().then(_ => {
        toggleAlert();
      });
    },
  });

  const hasConsultantRole = useHasConsultantRole();

  if (hasConsultantRole) return null;

  const disabled = data?.slack?.connected && !data?.slack?.canConnect;

  return (
    <>
      <Integration
        className={styles.item}
        icon={Slack}
        title={title}
        description={description}
        connected={data?.slack?.connected}
        disabled={disabled}
        loading={isLoading}
        onConnect={connect}
        onDisconnect={toggleAlert} />
      <Alert
        onConfirm={disconnect as () => unknown}
        message="Are you sure you want to disconnect your slack account?" />
    </>
  );
};

const title = `Slack Notifications`;
const description = `Connect to Slack to receive timely updates from Sentiment on scheduling, surveys and calls.`;

export default SlackIntegration;