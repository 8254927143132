import { useMenuContext } from '@containers/AppNavigation/Context';
import * as Menu from './Menu';
import styles from './style/Nav.css';

type Props = {
  className?: string;
};

export const LinkIcons = (props: Props) => {
  const { nav } = useMenuContext();

  return (
    <div className={styles.root}>
      {nav.map(x =>
        <Menu.ActionableIcon
          Icon={x.icon}
          count={x.count}
          key={x.key}
          name={x.name}
          onClick={x.onClick}
          to={x.to} />)}
    </div>
  );
};

LinkIcons.displayName = 'Nav.LinkIcons';