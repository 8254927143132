import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { searchTranscriptTags } from '@api/transcripts';
import type { ConferenceTag } from '@/types';

type Params = ITranscriptId;

export const useSearchCallTags = (data: ITranscriptId) => {
  const query = useQuery({
    queryKey: buildQueryKey(data),
    queryFn: () => {
      return searchTranscriptTags(data).then(t => t.tags);
    },
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 15,
  });

  return query;
};

function buildQueryKey({ transcriptId }: Params) {
  return ['transcript', transcriptId, 'available-tags'];
}

export const useUpdateTagsQueryData = (data: ITranscriptId) => {
  const queryClient = useQueryClient();
  return useCallback((tag: ConferenceTag) => {
    queryClient.setQueryData(buildQueryKey(data), (prev: ConferenceTag[]) => {
      return (prev ?? []).filter(t => t.id !== tag.id).concat(tag);
    });
  }, [queryClient, data]);
};