import type { ReactNode } from 'react';
import styled from '@emotion/styled';
import { ChevronUp, ChevronDown } from 'react-feather';

type Props = {
  className?: string;
  children: ReactNode;
  title: string;
  open: boolean;
  toggleOpen: () => void;
};

export const ChatResponsePane = (props: Props) => {
  return (
    <PaneRoot className={props.className}>
      <PaneHeader onClick={props.toggleOpen}>
        <PaneHeaderTitle>{props.title}</PaneHeaderTitle>
        {props.open
          ? <StyledChevronUp />
          : <StyledChevronDown />}
      </PaneHeader>
      {props.open && props.children}
    </PaneRoot>
  );
};

const PaneRoot = styled.div(({ theme }) => ({
  cursor: 'pointer',
  borderTop: `2px solid ${theme.palette.gray.light2}`,
  paddingTop: 10,
}));

const PaneHeader = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const PaneHeaderTitle = styled.div(({ theme }) => ({
  fontFamily: theme.fonts.semiBold,
  fontSize: 16,
}));

const StyledChevronUp = styled(ChevronUp)(({ theme }) => ({
  color: theme.palette.gray.dark1,
  cursor: 'pointer',
  transition: `transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
}));

const StyledChevronDown = styled(ChevronDown)(({ theme }) => ({
  color: theme.palette.gray.dark1,
  cursor: 'pointer',
  transition: `transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
}));