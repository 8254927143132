import { Edit2 } from 'react-feather';
import { FormButtons } from '@presentation';
import { cx } from '@utils';
import styles from './style/Layout.css';

type LabelProps = {
  disabled?: boolean;
  editable?: boolean;
  editing?:  boolean;
  onCancel?: () => unknown;
  onEdit?:   () => unknown;
  onSubmit?: () => unknown;
} & Props;

export const Label = (props: LabelProps) => {
  const classname = cx(styles.label, {
    [styles.editing]: props.editing,
  }, props.className);

  return (
    <div className={classname}>
      <div className={styles.text}>{props.children}</div>
      {props.editable &&
        <div className={styles.actions}>
          {!props.editing && (
            <div className={styles.edit}>
              <Edit2
                className={styles.btn}
                onClick={props.onEdit}
                size={18} />
            </div>)}
          {props.editing && (
            <FormButtons
              disabled={props.disabled}
              onCancel={props.onCancel}
              onSubmit={props.onSubmit}
              size={12} />)}
        </div>}
    </div>
  );
};

Label.displayName = 'Call.Main.Label';

type Props = {
  className?: string;
  children:   React.ReactNode;
};

export const Row = (props: Props) => {
  return (
    <div className={cx(styles.row, props.className)}>
      {props.children}
    </div>
  );
};

Row.displayName = 'Call.Main.Row';

export const Screen = (props: Props) => {
  return (
    <div className={styles.root}>
      <div className={cx(styles.wrap, props.className)}>
        {props.children}
      </div>
    </div>
  );
};

Screen.displayName = 'Call.Main.Screen';

export const Title = (props: Props) => {
  return (
    <div className={cx(styles.title, props.className)}>
      {props.children}
    </div>
  );
};

Title.displayName = 'Call.Main.Title';