import { path } from '@consts';
import { ButtonOutlined } from '@presentation';
import pipeline from 'static/images/website/compliance/compliance-pipeline.svg?url';
import styles from './style/Landing.css';

type Props = unknown;

export const Landing = (props: Props) => {

  return (
    <>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.main}>
            <div className={styles.content}>
              <h1 className={styles.title}>
                Manage your compliance and legal processes with ease
              </h1>
              <div className={styles.subtitle}>
                Sentiment provides a suite of compliance tools to mitigate compliance risks and streamline the compliance audit process for your firm.
              </div>
              <ButtonOutlined
                className={styles.btn}
                color="blue"
                to={path.Website.Demo}
                variant="rounded">
                Request a Demo
              </ButtonOutlined>
            </div>
            <div className={styles.graphic}>
              <img
                alt="compliance workflow"
                className={styles.img}
                src={pipeline} />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <h2 className={styles.h2}>
            Sentiment has a robust and flexible compliance framework to protect your firm
          </h2>
          <div className={styles.subtitle}>
            A robust compliance infrastructure is critical to protecting your business and capturing the key records required for regulatory audits. Sentiment supports a number of functions that power our compliance:
          </div>
        </div>
      </div>
    </>
  );
};

Landing.displayName = 'Compliance.Landing';