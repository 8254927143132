import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { SearchResultItem } from '@api/interfaces/projects.access';
import { type CallRole, utils } from '@enums';
import * as QK from '@consts/querykey';
import { ConferenceParticipants as CallConferenceParticipants, options as OptionsLookup } from '@/components/Conference.Participants';
import type { ConferenceParticipantsProps } from '@/components/Conference.Participants/interfaces';
import type { XPlatResultItem } from '$admin/api/interfaces/search.users';
import * as api from '$admin/api';

type Props =
  Omit<ConferenceParticipantsProps, 'isRoleValid' | 'onChange' | 'search' | 'selected'>;

export const ConferenceParticipants = (props: Props) => {
  const [keyword, setKeyword] = useState('');
  const [selected, setSelected] = useState<XPlatResultItem[]>([]);

  const exclude = useMemo(() => {
    return props.items.map(x => x.email);
  }, [props.items]);

  const query = useQuery([
    QK.Admin.Search.Users.XPlat.Get,
    exclude,
    keyword,
  ], () => {
    return api.search.users.xplat({
      exclude,
      keyword,
    });
  }, {
    enabled: !!keyword.trim(),
    refetchOnWindowFocus: false,
  });

  const pastedEmailsQuery = useMutation([
    QK.Admin.Search.Users.XPlat.Get,
    exclude,
  ], (emails: string[]) => {
    return api.search.users.xplat({
      exclude,
      emails,
    });
  });

  const isRoleValid = useCallback((item: SearchResultItem, role: CallRole) => {
    if (!props.offPlatform && item.offPlatform) return OptionsLookup.restricted.some(r => r.id === role);

    return true;
  }, [props.offPlatform]);

  const handlePaste = useCallback((emails: string[], role: CallRole) => {
    pastedEmailsQuery.mutateAsync(emails)
    .then(res => {
      const all = new Set([
        ...res.items.map(x => x.email),
        ...selected.map(x => x.email),
        ...exclude,
      ]);

      const additions = emails.reduce((acc, email) => {
        return all.has(email)
          ? acc
          : [...acc, toUnsavedOffPlat(email)];
      }, res.items);

      const invalidAdditions = additions.filter(item => !isRoleValid(item, role));

      if (invalidAdditions.length) {
        alert(`The following emails cannot be added with the ${utils.CallRole.getName(role)} role: \n\n${invalidAdditions.map(x => x.email).join('\n')}`);
        return;
      }

      setSelected([...selected, ...additions]);
    });
  }, [
    exclude,
    isRoleValid,
    pastedEmailsQuery,
    selected,
  ]);

  const search = {
    keyword,
    loading: pastedEmailsQuery.isLoading,
    setKeyword,
    results: query.data?.items || [],
  };

  return (
    <CallConferenceParticipants
      getItemDisabled={props.getItemDisabled}
      isRoleValid={isRoleValid}
      items={props.items}
      offPlatform={props.offPlatform}
      onChange={setSelected}
      onPaste={handlePaste}
      search={search}
      selected={selected}
      updater={props.updater} />
  );
};

ConferenceParticipants.displayName = 'Admin.Conference.Participants';

const toUnsavedOffPlat = (email: string) => ({
  email,
  id: null,
  name: email,
  offPlatform: true,
});