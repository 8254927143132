import { useContext, useState, useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Button } from '@/components/Button';
import type { UseModalProps } from '@/components/Modal';
import { Modal, Header, useModal } from '@/components/Modal';
import { GroupTagSelect } from '@/components/AutoComplete/GroupTagSelect';
import type { GroupTagUpdateItem } from '@/types';
import { ButtonActivityIndicator } from '@/presentation';
import { massAddObjectTags } from '@api/tags-group';
import Toast from '@/components/Toast';
import { useInvalidateFilesQueries } from '@/utils/api';
import { useSelectedObjects } from './hooks';
import { SelectedObjectsContext } from './context';
import styles from './style/ApplyTags.Modal.css';

export const ApplyTagsModal = (props: UseModalProps) => {
  const selectedObjects = useSelectedObjects();
  const [tags, setTags] = useState<GroupTagUpdateItem[]>([]);

  const invalidateFilesQueryies = useInvalidateFilesQueries();
  const { clearSelectedObjects } = useContext(SelectedObjectsContext);

  const addTagsMutation = useMutation({
    mutationFn: () => massAddObjectTags({ objectIds: selectedObjects.map(o => o.object.id), tags: tags }),
    onError: () => Toast.error({ title: 'Error adding tags' }),
    onSuccess: () => {
      invalidateFilesQueryies();
      clearSelectedObjects();
      props.onClose();
    },
  });

  return (
    <Modal disableOverlayClick={true} {...props}>
      <Header>{`Apply Tags to ${selectedObjects.length} selected objects`}</Header>
      <GroupTagSelect onChange={setTags} />
      <div className={styles.buttons}>
        <Button
          variant='brick'
          color='destructive'
          onClick={props.onClose}>
          Cancel
        </Button>
        <ButtonActivityIndicator
          disabled={addTagsMutation.isLoading || !tags.length}
          implicitDisable={false}
          loading={addTagsMutation.isLoading}
          onClick={() => addTagsMutation.mutateAsync()}>
          Add Tags
        </ButtonActivityIndicator>
      </div>
    </Modal>
  );
};

export const useApplyTagsModal = () => useModal(ApplyTagsModal);