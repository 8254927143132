export enum StructuredFeedback {
  TooBroad = 1,
  TooSpecific = 2,
  TooWordy = 3,
  IrrelevantTopic = 4,
  FalseStatements = 5,
  OverGeneralization = 6,
  ToneIssues = 7,
  FormattingIssues = 8,
}

export const StructuredFeedbackNameMap: Record<StructuredFeedback, string> = {
  [StructuredFeedback.TooBroad]: 'Too Broad',
  [StructuredFeedback.TooSpecific]: 'Too Specific',
  [StructuredFeedback.TooWordy]: 'Too Wordy',
  [StructuredFeedback.IrrelevantTopic]: 'Irrelevant Topic',
  [StructuredFeedback.FalseStatements]: 'False Statements',
  [StructuredFeedback.OverGeneralization]: 'Over Generalization',
  [StructuredFeedback.ToneIssues]: 'Tone Issues',
  [StructuredFeedback.FormattingIssues]: 'Formatting Issues',
};