import { ReactDatePickerProps } from 'react-datepicker';
import { XCircle } from 'react-feather';
import { DatePicker } from './DatePicker';
import styles from './style.css';

type Props = ReactDatePickerProps;

export const DatePickerInput = ({
  isClearable,
  ...props
}: Props) => {

  return (
    <div className={styles.input}>
      <DatePicker
        isClearable={false}
        {...props} />
      {isClearable && props.selected &&
        <XCircle
          className={styles.close}
          onClick={() => props.onChange(null, null)} />
      }
    </div>
  );
};

export default DatePickerInput;