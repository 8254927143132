import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as api from '@api';
import { Projects } from '@api/interfaces';

type Data = Awaited<ReturnType<typeof api.surveys.fetchSurvey>>;
type QueryKey = [string, number];

type Props = ISurveyId;

const useFetchProjectSurvey = (
  { surveyId }: Props,
  options?: UseQueryOptions<Data, AxiosError, Data, QueryKey>
) => {

  return useQuery(['fetch-project-survey', surveyId], ({ queryKey }) => {
    const surveyId = queryKey[1] as number;

    return api.surveys.fetchSurvey({
      surveyId,
    });
  }, options);
};

export { useFetchProjectSurvey };
export default useFetchProjectSurvey;