import { cx } from '@utils';
import styles from './style/ContactRecord.css';

type Props = {
  children?:  React.ReactNode;
  className?: string;
};

export const Header = (props: Props) => {
  return (
    <div className={cx(styles.header, props.className)}>
      {props.children}
    </div>
  );
};

Header.displayName = 'Group.Contacts.Record.Layout.Header';

export const Title = (props: Props) => {

  return (
    <div className={cx(styles.title, props.className)}>
      {props.children}
    </div>
  );
};

Title.displayName = 'Group.Contacts.Record.Layout.Title';

export const Section = (props: Props) => {
  return (
    <div className={cx(styles.section, props.className)}>
      {props.children}
    </div>
  );
};

Section.displayName = 'Group.Contacts.Record.Layout.Section';