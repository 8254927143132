import { useCallback } from 'react';
import { FormButtons } from '@presentation/FormButtons';
import { SidebarHeader } from '@presentation/Main';
import { useTaggedProjects, useTaggedProjectsDispatch, useTaggedProjectsUpdate, useTaggedProjectsReset } from './hooks';
import styles from './style.css';

export default function Header() {
  return (
    <SidebarHeader title="Projects">
      <Actions />
    </SidebarHeader>
  );
}

function Actions() {
  const ctx = useTaggedProjects();

  if (!ctx.editable) return null;

  return ctx.editing
    ? <Editing />
    : <Add />;
}

function Add() {
  const dispatch = useTaggedProjectsDispatch();

  const handleClick = useCallback(() => {
    dispatch(state => ({ ...state, editing: true }));
  }, [dispatch]);

  return (
    <button className={styles.add} onClick={handleClick}>
      + Add
    </button>
  );
}

function Editing() {
  const { items } = useTaggedProjects();
  const dispatch = useTaggedProjectsDispatch();
  const [update, canUpdate] = useTaggedProjectsUpdate();
  const reset = useTaggedProjectsReset();

  const handleSave = useCallback(() => {
    update(items);
    dispatch(state => ({ ...state, editing: false }));
  }, [dispatch, update, items]);

  const handleCancel = useCallback(() => {
    reset();
    dispatch(state => ({ ...state, editing: false }));
  }, [dispatch, reset]);

  return (
    <div className={styles.buttons}>
      <FormButtons
        deletable={false}
        disabled={!canUpdate}
        onCancel={handleCancel}
        onSubmit={handleSave} />
    </div>
  );
}

export { Header };