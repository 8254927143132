import { useCallback, useContext, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import * as QK from '@consts/querykey';
import { MedicalBackgroundAdditionalExpertiseContext } from '@containers/MedicalOnboarding/Context';
import { AdditionalExpertise } from '@containers/MedicalOnboarding/interfaces';
import { Chip } from '@presentation/Chip';
import { useDebounce } from '@utils/hooks';
import AutoComplete from '@/components/AutoComplete';
import styles from './style/Autosuggest.css';

type Props = {
  className?:   string;
  max?:         number;
  placeholder?: string;
};

export const Conditions = ({ max = 3, ...props }: Props) => {
  const ctx = useContext(MedicalBackgroundAdditionalExpertiseContext);
  const [value, setValue] = useDebounce('', 150);

  const selected = ctx.form.therapeuticAreas;

  const setSelected = useCallback((items: AdditionalExpertise.MedicalConditionFormItem[]) => {
    ctx.setValue({
      therapeuticAreas: items,
    });
  }, [ctx]);

  const params = useMemo(() => {
    return {
      exclude: selected
        .filter(f => typeof f.id === 'number')
        .map(x => x.id),
      keyword: value.trim(),
    };
  }, [
    selected,
    value,
  ]);

  const query = useQuery([
    QK.Search.Medical.Conditions.Get,
    params.exclude,
    params.keyword,
  ] as const, ctx => {
    const exclude = ctx.queryKey[1];
    const keyword = ctx.queryKey[2];
    return api.search.medical.fetchConditions({
      exclude,
      keyword,
    }).then(res => res.items.filter(x => !exclude.includes(x.id)));
  }, {
    enabled: !!params.keyword,
    initialData: [] as Descriptor[],
  });

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setValue(value);
  }, [setValue]);

  const handleSubmit = useCallback((item: AdditionalExpertise.MedicalConditionFormItem) => {
    setValue('');
    setSelected([...new Set([...selected, item])]);
  }, [
    selected,
    setSelected,
    setValue,
  ]);

  const handleSelect = useCallback(({ userCreated, ...item }: AdditionalExpertise.MedicalConditionFormItem & { userCreated?: boolean }) => {
    if (!item.id) {
      return handleSubmit({
        custom: true,
        /* @ts-ignore */
        id: item.name.trim(),
        name: item.name,
      });
    }

    handleSubmit({
      custom: userCreated,
      ...item,
    });
  }, [handleSubmit]);

  const handleDelete = useCallback((item: AdditionalExpertise.MedicalConditionFormItem) => {
    setSelected(selected.filter(x => x.id !== item.id));
  }, [
    selected,
    setSelected,
  ]);

  return (
    <>
      {selected.length < max &&
        <AutoComplete.Tags
          allowCustom
          className={props.className}
          focusableClasses={{ root: styles.focusable }}
          getItemValue={(item: Descriptor) => item.name}
          items={query.data}
          name="conditions"
          onChange={handleChange}
          onSelect={handleSelect}
          placeholder={props.placeholder} />}
      <div className={styles.selections}>
        <ConditionsTags
          items={selected}
          onDelete={handleDelete} />
      </div>
    </>
  );
};

Conditions.displayName = 'MedicalProfile.Field.Conditions';

type ConditionsTagsProps = {
  items:    Descriptor[];
  onDelete: (item: Descriptor) => void;
};

const ConditionsTags = (props: ConditionsTagsProps) => {
  return (
    <div className={styles.tags}>
      {props.items.map(x =>
        <Chip
          color="selected"
          className={styles.override}
          key={x.name}>
          <Chip.Body.Action
            className={styles.icon}
            variant="remove"
            onClick={() => props.onDelete(x)}>
            {x.name}
          </Chip.Body.Action>
        </Chip>)}
    </div>
  );
};