import { useCallback } from 'react';
import { ConferenceSettings, Remirror } from '@/types';
import * as $conference from '$admin/containers/Call/utils';
import { ConferenceDetailsEditor, ConferenceDetailsEditorContainer, ConferenceDetailsEditorOnChange, Toolbar, Manager, State } from '@/components/Conference';
import styles from './style/Details.css';

type Props = {
  editing:        boolean;
  manager:        Manager;
  setFieldValue?: (name: string, value: Remirror.RootNode<Remirror.RootNodeContent>) => unknown;
  state:          State;
  values:         Pick<ConferenceSettings, 'clientNotes'>;
};

export const ClientNotes = ({ setFieldValue, values, ...props }: Props) => {

  const handleChange: ConferenceDetailsEditorOnChange = useCallback(params => {

    if (!params.tr?.docChanged) return;

    const value = params.state.doc.toJSON() as Remirror.RootNode;

    setFieldValue?.(name, value);
  }, [setFieldValue]);

  if (!props.editing
    && (!values.clientNotes || $conference.isConferenceDetailsEmpty(values.clientNotes))
  ) {
    return (
      <span className={styles.empty}>Not Set</span>
    );
  }

  return (
    <ConferenceDetailsEditorContainer
      onChange={handleChange}
      editable={props.editing}
      manager={props.manager}
      state={props.state}>
      {props.editing && <Toolbar className={styles.toolbar} />}
      <ConferenceDetailsEditor />
    </ConferenceDetailsEditorContainer>
  );
};

ClientNotes.displayName = 'Conference.Settings.Field.ClientNotes';

const name = `clientNotes`;