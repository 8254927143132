import type { SurveyBuilder } from '../interfaces';
import { aieReducer } from './state.aie.reducer';
import { detailsReducer } from './state.details.reducer';
import { itemsReducer } from './state.items.reducer';
import { languageReducer } from './state.language.reducer';
import { logicReducer } from './state.logic.reducer';
import { messagesReducer } from './state.messages.reducer';
import { questionsReducer } from './state.questions.reducer';
import { quotasReducer } from './state.quotas.reducer';
import { sectionsReducer } from './state.sections.reducer';
import { taggingReducer } from './state.tagging.reducer';
import { templateReducer } from './state.template.reducer';
import { classificationsReducer } from './state.classifications.reducer';

function batchActions(state: SurveyBuilder.State, { actions }: SurveyBuilder.BatchActions.State): SurveyBuilder.State['survey'] {

  let survey = state.survey;
  for (const action of actions) {
    survey = surveyReducer({
      ...state,
      survey,
    }, action);
  }

  return survey;
}

export function surveyReducer(state: SurveyBuilder.State, action: SurveyBuilder.Actions): SurveyBuilder.State['survey'] {

  switch (action.type) {

    case 'reset-survey':
      return action.value;

    case 'batch-actions':
      return batchActions(state, action);

    case 'draft-loaded':
      return action.draft;

    case 'draft-discarded':
      return state.savedSurvey;

    case 'draft-survey-data-updated':
      return action.value;

    default:
      return {
        ...state.survey,
        alternateImageExercises: aieReducer(state, action),
        classifications: classificationsReducer(state, action),
        items: itemsReducer(state, action),
        language: languageReducer(state, action),
        logic: logicReducer(state, action),
        messages: messagesReducer(state, action),
        questions: questionsReducer(state, action),
        quotas: quotasReducer(state, action),
        sections: sectionsReducer(state, action),
        surveyDetails: detailsReducer(state, action),
        tagging: taggingReducer(state, action),
        template: templateReducer(state.survey.template, action),
      };
  }
}