import { useCallback } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks/useSurveyBuilderContext';
import { MaxDifferenceQuestion, SurveyRichText } from '@/types/survey';
import { MaxDiffQuestionBuilderContext } from './Context';

type Props = {
  item: MaxDifferenceQuestion.Question;
} & ChildrenProps;

export default function MaxDiffQuestionBuilderContainer({ children, item }: Props) {
  const [, dispatch] = useSurveyBuilderState();

  const questionIdentifier = item.base.identifier;

  const updateOptionsPerSet = useCallback((value: number) => {
    dispatch({
      questionIdentifier,
      type: 'maxdiff-update-options-per-set',
      value,
    });
  }, [dispatch, questionIdentifier]);

  const updateSets = useCallback((value: number) => {
    dispatch({
      questionIdentifier,
      type: 'maxdiff-update-sets',
      value,
    });
  }, [dispatch, questionIdentifier]);

  const updateLeastLabel = useCallback((value: SurveyRichText.RichTextValue) => {
    dispatch({
      questionIdentifier,
      type: 'maxdiff-update-least-label',
      value,
    });
  }, [dispatch, questionIdentifier]);

  const updateMostLabel = useCallback((value: SurveyRichText.RichTextValue) => {
    dispatch({
      questionIdentifier,
      type: 'maxdiff-update-most-label',
      value,
    });
  }, [dispatch, questionIdentifier]);

  const value = {
    item,
    updateLeastLabel,
    updateMostLabel,
    updateOptionsPerSet,
    updateSets,
  };

  return (
    <MaxDiffQuestionBuilderContext.Provider value={value}>
      {children}
    </MaxDiffQuestionBuilderContext.Provider>
  );
}

export { MaxDiffQuestionBuilderContainer };