import { ProjectType } from '@enums';
import { useSelectProject } from '@containers/Store/hooks/useSelectProject';
import { useProjectSurveyPayment } from './useProjectSurveyPayment';
import { useProjectRequestCallRate } from './useProjectRequestCallRate';

export const useIsFreeProject = (projectId: number) => {
  const project = useSelectProject(projectId);
  const surveyPayment = useProjectSurveyPayment({
    projectId,
    projectType: project.projectType,
  });
  const callPayment = useProjectRequestCallRate({ projectId });

  if (!project) return false;

  if (project.projectType == ProjectType.Survey) {
    return surveyPayment?.data === 0;
  } else {
    return callPayment.data === 0;
  }
};