import { useCallback } from 'react';
import { addWeeks } from 'date-fns';
import { useCookie } from '@utils/hooks';

const COOKIE_KEY = 'entity-boost-dismissed';
export const useBoostDismiss = () => {
  const [value, setCookie] = useCookie(COOKIE_KEY, null, {
    watchForChanges: true,
  });

  const dismiss = useCallback(() => {
    setCookie({
      value: 'dismissed',
      expires: addWeeks(new Date(), 1),
    });
  }, [setCookie]);

  return {
    isDismissed: !!value,
    dismiss,
  };
};