import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import * as $api from '@api/transcripts';

type Vars = {
  highlightIdentifier?: string;
  momentId: number;
};
type Options = UseMutationOptions<unknown, AxiosResponse, Vars>;

export const useDeleteTranscriptMomentMutation = (props: ITranscriptId, options: Options) => {

  return useMutation({
    mutationFn: (data: Vars) => {
      return $api.deleteTaggedMoment({
        momentId: data.momentId,
        transcriptId: props.transcriptId,
      });
    },
    ...options,
  });
};