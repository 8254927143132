import { useCallback, useEffect, useRef, useState } from 'react';
import { AlertTriangle, Check } from 'react-feather';
import { useSurveyThemingPalette } from '@containers/Branding/hooks/useSurveyThemingPalette';
import { cx } from '@utils';
import { Textarea } from '@/components/Textarea';
import { LongTextResponseInputProps } from './interfaces';
import styles from './style/LongTextResponseInput.css';
import { useThemedTextArea } from './ThemedTextarea';

const LongTextResponseInput = ({ updateAnswer, ...props }: LongTextResponseInputProps) => {
  const [showWarning, setShowWarning] = useState(false);
  const [showThanks, setShowThanks] = useState(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const themeProps = useThemedTextArea();

  useEffect(() => {
    if (showThanks) {
      timeoutRef.current = setTimeout(() => {
        setShowThanks(false);
      }, 5000);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [showThanks]);

  const onBlur = useCallback((e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (showWarning) {
      if (props.isLongEnough) {
        setShowThanks(true);
        setShowWarning(false);
      }
    } else {
      if (!props.isLongEnough) {
        setShowThanks(false);
        setShowWarning(true);
      }
    }

    themeProps.onBlur(e);
  }, [
    themeProps,
    props.isLongEnough,
    showWarning,
  ]);

  const renderWarning = useCallback(() => {
    return (
      <div className={styles.warning}>
        <AlertTriangle size={35} />
        <div className={styles.warningText}>
          We recommend providing a bit more detail in your response.
        </div>
      </div>
    );
  }, []);

  const renderThanks = useCallback(() => {
    return (
      <div className={styles.thanks}>
        <Check size={35} />
        <div className={styles.thanksText}>Thank you for the additional detail!</div>
      </div>
    );
  }, []);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    updateAnswer(e.target.value);
  }, [updateAnswer]);

  const inputprops = {
    className: cx(styles.ta, { [styles.taWarning]: showWarning }),
    onBlur,
    onChange: handleChange,
    onFocus: themeProps.onFocus,
    placeholder: 'Provide a few sentences in your response.',
    style: themeProps.style,
    value: props.value,
  };

  return (
    <div className={props.className}>
      <Textarea {...inputprops} />
      <div className={styles.block}>
        {showWarning && renderWarning()}
        {showThanks && renderThanks()}
      </div>
    </div>
  );
};

export default LongTextResponseInput;