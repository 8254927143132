import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import * as api from '@services/api';
import { Analytics } from '@/types';
import { AnalyticsEvent } from 'enums';

type Params<T extends AnalyticsEvent> =
  Analytics.EventData<T>;

export const useTrackEvent = <T extends AnalyticsEvent>(event: T) => {
  const location = useLocation();

  return useCallback((data: Params<T>) => {

    return api.analytics.trackEvent<T>({
      createdOn: new Date(),
      data: {
        ...data,
        page: location.pathname,
      },
      event,
    });
  }, [
    event,
    location.pathname,
  ]);
};