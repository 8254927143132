import type { Table } from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';
import { ChevronDown, ChevronUp } from 'react-feather';
import { cx } from '@utils';
import styles from './style.css';

type Classes = {
  root: string;
  header: string;
  row: string;
  body: string;
  cell: string;
};

type Props<TableItem> = {
  table: Table<TableItem>;
  classes?: Partial<Classes>;
};

export const SortableTable = <TableItem, >({ table, classes = {} }: Props<TableItem>) => {
  return (
    <div className={cx(styles.tableRoot, classes.root)}>
      <div className={cx(styles.tableHeader, classes.header)}>
        {table.getHeaderGroups().map(headerGroup => (
          <div key={headerGroup.id} className={cx(styles.tableRow, classes.row)}>
            {headerGroup.headers.map(header => {
              return (
                <div key={header.id} className={cx(styles.tableCell, classes.cell, header.column.columnDef.meta?.['className'] as string)}>
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        className: header.column.getCanSort()
                          ? styles.sortable
                          : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      <div className={styles.iconContainer}>
                        {{
                          asc: <ChevronUp className={styles.sortIcon} />,
                          desc: <ChevronDown className={styles.sortIcon} />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ))}
      </div>
      <div className={cx(styles.tableBody, classes.body)}>
        {table
          .getRowModel()
          .rows
          .map(row => {
            return (
              <div key={row.id} className={cx(styles.tableRow, classes.row)}>
                {row.getVisibleCells().map(cell => {
                  return (
                    <div key={cell.id} className={cx(styles.tableCell, classes.cell, cell.column.columnDef.meta?.['className'] as string)}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}

      </div>
    </div>
  );
};