import { Redirect, Route, Switch } from 'react-router-dom';
import * as consts from '@consts';
import { AuthModalContainer, Footer, Navbar } from '$website/components';
import { MarketStrategyContainer } from '$website/containers';
import { paths, routes } from './routes';
import styles from './style.css';

const Website = () => {
  return (
    <MarketStrategyContainer>
      <AuthModalContainer>
        <div className={styles.root}>
          <Route
            component={Navbar}
            exact
            path={paths.header} />
          <div className={styles.main}>
            <Switch>
              {routes.map(x =>
                <Route
                  key={String(x.path)}
                  component={x.component}
                  exact={x.exact}
                  path={x.path} />)}
              <Redirect to={consts.path.Website.Root} />
            </Switch>
          </div>
          <Footer exclude={paths.excludeFooter} />
        </div>
      </AuthModalContainer>
    </MarketStrategyContainer>
  );
};

export { Website };
export default Website;
