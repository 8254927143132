import * as api from '@api';
import { useAsyncStateLazy } from '@utils';
import * as API from '@api/interfaces';

const useFetchComplianceAggregateReview = () => {

  return useAsyncStateLazy((data: API.Groups.Compliance.FetchAggregateReview.Request) => {

    return api.groups.compliance.fetchAggregateReview({
      approvalStatusIds: data.approvalStatusIds,
      groupId: data.groupId,
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      statusIds: data.statusIds,
    });

  });
};

export { useFetchComplianceAggregateReview };
export default useFetchComplianceAggregateReview;