/* eslint-disable react/jsx-max-props-per-line */
import { useMemo, memo } from 'react';

type Props = {
  className?: string;
  size?: number;
  fillPercentage?: number;
  fillColor?: string;
};

let uniqueStarId = 0;
const getUniqueStarId = () => uniqueStarId++;

export const Star = memo(({ size = 24, fillColor = 'blue', fillPercentage = 0, ...props }: Props) => {
  const fillId = useMemo(() => `star-id-${getUniqueStarId()}`, []);
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 24 24' fill={fillPercentage ? `url(#${fillId})` : 'none'} stroke='currentColor' strokeWidth='1' strokeLinecap='round' strokeLinejoin='round' className={props.className}>
      <defs>
        <linearGradient id={fillId} x1='0' y1='0' x2='1' y2='0'>
          <stop id='stop1' offset={fillPercentage} stopColor={fillColor} />
          <stop id='stop2' offset='0' stopColor='white' />
        </linearGradient>
      </defs>
      <polygon points='12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2' />
    </svg>
  );

});