import { useContext } from 'react';
import { ProfileEntry } from '@enums';
import { AccountProfileEditStateContext } from '@containers/AccountProfile/Context';

const useAccountProfileEditState = () => {

  const [entryId, onEditStateChange] = useContext(AccountProfileEditStateContext);

  return [entryId, onEditStateChange] as const;

};

export { useAccountProfileEditState };
export default useAccountProfileEditState;