import { useMemo } from 'react';
import { useObjectOwnershipContext } from './useObjectOwnershipContext';

export const useCanSaveOwnership = () => {
  const { selectedUser } = useObjectOwnershipContext();

  const canSave = useMemo(() => !!selectedUser, [selectedUser]);

  return canSave;
};

export default useCanSaveOwnership;