import { useState } from 'react';
import { SideBarExpandedContext } from './context';

type Props = {
  children: React.ReactNode;
};

export const SidebarContainer = (props: Props) => {

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <SideBarExpandedContext.Provider value={[isExpanded, setIsExpanded]}>
      {props.children}
    </SideBarExpandedContext.Provider>
  );
};