import { useCallback } from 'react';
import type { Icon } from 'react-feather';
import styles from './style/ImportOptions.StartMenu.css';

type Props = {
  items: ImportToolOption[];
};

export const ImportOptionsToolSelect = (props: Props) => {

  return (
    <>
      {props.items.map(x =>
        <ImportOption
          Icon={x.Icon}
          description={x.description}
          key={x.title}
          onClick={x.onClick}
          title={x.title}>
          {x.children}
        </ImportOption>)}
    </>
  );
};

ImportOptionsToolSelect.displayName = 'Group.Contacts.ImportStepper.ImportOptions.ToolSelect';

type ImportToolOption = {
  Icon:        Icon;
  children?:   React.ReactNode;
  description: string;
  title:       string;
  onClick?:    (e?: React.MouseEvent) => unknown;
};

const ImportOption = ({ Icon = null, ...props }: ImportToolOption) => {
  const handleClick = useCallback((e: React.MouseEvent) => {
    props.onClick?.(e);
  }, [props]);

  return (
    <div
      className={styles.item}
      onClick={handleClick}>
      <div className={styles.icon}>
        <Icon
          className={styles.svg}
          size={40} />
      </div>
      <div className={styles.title}>{props.title}</div>
      <div className={styles.desc}>{props.description}</div>
      {props.children}
    </div>
  );
};

ImportOption.displayName = 'Group.Contacts.ImportStepper.ImportOption';