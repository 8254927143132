import { useContext, useMemo } from 'react';
import { CallRole } from '@enums';
import { useIsInternalUser } from '@/containers/Store';
import { GuestPinsTable } from '@/components/Conference.Participants';
import { CallDetailsContext } from './Context';
import * as Layout from './Layout';
import styles from './style/Conference.Participants.css';

type Props = unknown;

export const ConferenceGuestPins = (props: Props) => {
  const ctx = useContext(CallDetailsContext);
  const isInternalUser = useIsInternalUser();

  const filteredPins = useMemo(() => {
    const roles = !isInternalUser
      ? [CallRole.Attendee]
      : Object.keys(ctx.query.data.conference.guestPins).map(m => +m as CallRole);

    return roles.reduce<Partial<Record<CallRole, number>>>((acc, role) => {
      acc[role] = ctx.query.data.conference.guestPins[role];
      return acc;
    }, {});
  }, [ctx.query.data.conference.guestPins, isInternalUser]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <Layout.Title>Guest Pins</Layout.Title>
        </div>
        <GuestPinsTable
          identifier={ctx.query.data.conference.identifier}
          pins={filteredPins} />
      </div>
    </div>
  );
};

ConferenceGuestPins.displayName = 'Conference.GuestPins';