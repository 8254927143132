import { Fragment, useCallback } from 'react';
import { SurveySectionBuilderContainer } from '@containers/SurveyBuilder.Section/SectionBuilderContainer';
import { useSurveyBuilderState, useCanAddSection, useCanAddSurveyModule } from '@containers/SurveyBuilder';
import { AddSectionButtonBordered, AddButtonDivider } from '@presentation';
import { SurveySectionBuilder } from '../SurveyBuilder.Section';
import { AddSectionAnchor } from './SectionsBuilder.AddSection';
import styles from './style/SurveySectionsBuilder.css';

export const SurveySectionsBuilder = () => {
  const [state, dispatch] = useSurveyBuilderState();
  const canAddSection = useCanAddSection();
  const canAddModule = useCanAddSurveyModule();

  const addSection = useCallback((ordinal: number) => () => {
    dispatch({
      type: 'new-section-added',
      ordinal,
    });
  }, [dispatch]);

  const renderSections = useCallback(() => {
    return state.survey.sections.map(m => (
      <Fragment key={m.ordinal}>
        <SurveySectionBuilderContainer
          identifier={m.identifier}>
          <SurveySectionBuilder />
        </SurveySectionBuilderContainer>
        {m.ordinal !== state.survey.sections.length &&
          <AdvancedAddSectionDivider
            canAddSection={canAddSection}
            canAddModule={canAddModule}
            sectionPosition={m.ordinal + 1}
            addSection={addSection} />
        }
      </Fragment>
    ));
  }, [
    addSection,
    canAddSection,
    canAddModule,
    state.survey.sections,
  ]);

  const nextSectionOrdinal = state.survey.sections.length + 1;

  return (
    <div className={styles.root}>
      {!!state.survey.sections.length &&
        <AdvancedAddSectionDivider
          canAddSection={canAddSection}
          canAddModule={canAddModule}
          sectionPosition={1}
          addSection={addSection} />
      }
      {renderSections()}
      <div className={styles.addSection}>
        {canAddSection && !canAddModule && (
          <AddSectionButtonBordered onClick={addSection(nextSectionOrdinal)} />
        )}
        {canAddSection && canAddModule && (
          <AddSectionAnchor>
            {(props, ref) => (
              <AddSectionButtonBordered
                ref={ref as React.Ref<HTMLDivElement>}
                onClick={() => {
                  props.setSectionPosition(nextSectionOrdinal);
                  props.setOpen(true);
                }} />
            )}
          </AddSectionAnchor>
        )}
      </div>
    </div>
  );
};

type AdvancedAddSectionDividerProps = {
  addSection: (ordinal: number) => () => unknown;
  sectionPosition: number;
  canAddSection: boolean;
  canAddModule: boolean;
};

const AdvancedAddSectionDivider = ({ addSection, sectionPosition, canAddSection, canAddModule }: AdvancedAddSectionDividerProps) => {
  return (
    <>
      {!canAddModule && (
        <AddButtonDivider
          className={styles.divider}
          onClick={addSection(sectionPosition)}
          disabled={!canAddSection} />
      )}
      {canAddModule && (
        <AddSectionAnchor>
          {(props, ref) => (
            <AddButtonDivider
              className={styles.divider}
              ref={ref as React.Ref<HTMLDivElement>}
              disabled={!canAddSection}
              onClick={() => {
                props.setSectionPosition(sectionPosition);
                props.setOpen(true);
              }} />
          )}
        </AddSectionAnchor>
      )}
    </>
  );
};

export default SurveySectionsBuilder;