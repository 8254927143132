import { cx } from '@utils';
import { ProjectHonorariaContainer } from './Project.Honoraria.Container';
import { ProjectHonorariaForm } from './Project.Honoraria.Form';
import { ProjectHonorariaList } from './Project.Honoraria.List';
import * as PH from './interfaces';
import styles from './style/Project.Honoraria.css';

type Props = {
  children?:       React.ReactNode;
  className?:      string;
  items?:          PH.Form.Item[];
  onChangeValues?: PH.Form.OnChangeValues;
  onSubmit?:       PH.Form.OnSubmit;
  readonly?:       boolean;
};

export const ProjectHonoraria = ({ items = [], ...props }: Props) => {
  return (
    <ProjectHonorariaContainer items={items}>
      <div className={cx(styles.root, props.className)}>
        {!props.readonly &&
          <ProjectHonorariaForm
            onChangeValues={props.onChangeValues}
            onSubmit={props.onSubmit}>
            {props.children}
          </ProjectHonorariaForm>}

        {props.readonly &&
          <ProjectHonorariaList>
            {props.children}
          </ProjectHonorariaList>}
      </div>
    </ProjectHonorariaContainer>
  );
};

ProjectHonoraria.displayName = 'Project.Honoraria';