import { useCallback, useContext } from 'react';
import styled from '@emotion/styled';
import { ChatContextType } from '@/brand-insights/enums';
import { ChatStateDispatchContext } from '@/brand-insights/components/Chat.State';
import { DropdownMenuItem } from '@/brand-insights/components/presentation';
import { HeaderContextContext } from './context';

type RendererProps = {
  onClick?: () => unknown;
};

type ContextRendererResult = {
  label: () => React.ReactNode;
  detail: () => React.ReactNode;
  menuItems: () => React.ReactNode;
};

export const useMenuRender = (props: RendererProps): ContextRendererResult => {
  const dispatch = useContext(ChatStateDispatchContext);
  const {
    displayContextType,
    setPendingContextType,
  } = useContext(HeaderContextContext);

  const handleEverythingSelected = useCallback(() => {
    dispatch({
      type: 'instance-context-changed',
      payload: {
        context: {
          type: ChatContextType.Global,
        },
      },
    });
    setPendingContextType(null);
    props.onClick?.();
  }, [dispatch, props, setPendingContextType]);

  const updatePendingContextType = useCallback((type: ChatContextType) => () => {
    setPendingContextType(type);
    props.onClick?.();
  }, [props, setPendingContextType]);

  const EverythingItem = (
    <StyledDropdownMenuItem onClick={handleEverythingSelected}>
      Everything
    </StyledDropdownMenuItem>
  );

  const BrandItem = (
    <StyledDropdownMenuItem onClick={updatePendingContextType(ChatContextType.Brand)}>
      Brand
    </StyledDropdownMenuItem>
  );

  const DocumentItem = (
    <StyledDropdownMenuItem onClick={updatePendingContextType(ChatContextType.Document)}>
      Document
    </StyledDropdownMenuItem>
  );

  const FolderItem = (
    <StyledDropdownMenuItem onClick={updatePendingContextType(ChatContextType.Folder)}>
      Folder
    </StyledDropdownMenuItem>
  );

  const ProjectItem = (
    <StyledDropdownMenuItem onClick={updatePendingContextType(ChatContextType.Project)}>
      Project
    </StyledDropdownMenuItem>
  );

  const TranscriptItem = (
    <StyledDropdownMenuItem onClick={updatePendingContextType(ChatContextType.Transcript)}>
      Transcript
    </StyledDropdownMenuItem>
  );

  const label = getContextDisplayText(displayContextType);

  return {
    label: () => <>{label}</>,
    detail: null,
    menuItems: () => (
      <>
        {EverythingItem}
        {BrandItem}
        {ProjectItem}
        {FolderItem}
        {DocumentItem}
        {TranscriptItem}
      </>
    ),
  };
};

function getContextDisplayText(contextType: ChatContextType): string {
  switch (contextType) {
    case ChatContextType.Brand:
      return 'Brand';
    case ChatContextType.Document:
      return 'Document';
    case ChatContextType.Folder:
      return 'Folder';
    case ChatContextType.Project:
      return 'Project';
    case ChatContextType.Transcript:
      return 'Transcript';
    case ChatContextType.Global:
      return 'Everything';
    default:
      throw new UnreachableCaseError(contextType);
  }
}

const StyledDropdownMenuItem = styled(DropdownMenuItem)`
  text-align: left;
  word-wrap: break-word;
  word-break: break-all;
  width: 100%;
`;