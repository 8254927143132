import { Fragment, useCallback, useMemo } from 'react';
import { useRepeatQuestionResponsesData, useRepeatSurveyData, useRepeatSurveyResponsesState } from '@containers/RepeatSurveyResponses/hooks';
import { RepeatSurvey as RS } from '@containers/RepeatSurveyResponses/interfaces';
import * as survey from '@containers/Survey/utils';
import * as chart from '@containers/SurveyResponses/utils';
import { SurveyQuestionType } from '@enums';
import { AggregateDataHeaderRow, ProjectVersionDataRow, ResponseOption } from '@presentation/ProjectSurveyResponses';
import { formatNumber } from '@utils';
import { SurveyQuestionMatrixRow, MatrixSliderQuestion } from '@/types';
import { SimpleAccordion } from '@/components/Accordion';
import { useFilteredRows, useQueryAccordionMap } from './hooks';
import styles from './style/Data.css';

export const MatrixSliderAggregateData = () => {
  const { query, resetQueryState, setQueryState } = useRepeatSurveyResponsesState<RS.QueryState.MatrixSlider>();
  const { projectIds } = useRepeatSurveyData();
  const { aggregate, historical, question } = useRepeatQuestionResponsesData<SurveyQuestionType.Sliders>();
  const rows = useFilteredRows();
  const [isAccordionOpen, toggleAccordion] = useQueryAccordionMap('rowIds');

  const onVersionClick = useCallback((rowId: number, projectId: number) => () => {
    setQueryState({
      rowIds: [rowId],
      projectId,
    });
  }, [setQueryState]);

  const renderVersions = useCallback((row: SurveyQuestionMatrixRow) => {
    return [...projectIds]
      .sort((a, b) => b - a)
      .reduce((acc, projectId, i) => {
        const avg = historical[projectId].data.rows[row.base.id]?.avg;
        if (avg !== undefined) {
          acc.push(
            <ProjectVersionDataRow
              key={projectId}
              label={`${formatNumber(avg, 1)} Avg.`}
              onClick={onVersionClick(row.base.id, projectId)}
              version={projectIds.length - i} />
          );
        }

        return acc;
      }, []);
  }, [
    historical,
    onVersionClick,
    projectIds,
  ]);

  const getRowData = useCallback((row: SurveyQuestionMatrixRow) => {
    return aggregate[row.base.id];
  }, [aggregate]);

  const renderRow = useCallback((row: SurveyQuestionMatrixRow) => {
    const data = getRowData(row);
    return (
      <SimpleAccordion
        key={row.base.id}
        open={isAccordionOpen(row.base.id)}
        toggleOpen={toggleAccordion(row.base.id)}
        grows={false}
        className={styles.row}
        height={50}
        label={renderAccordionLabel(data, row)}>
        {renderVersions(row)}
      </SimpleAccordion>
    );
  }, [
    getRowData,
    isAccordionOpen,
    renderVersions,
    toggleAccordion,
  ]);

  const sortedRows = useMemo(() => {
    const reversed = survey.questions.isSliderReversed(question.settings);
    return [...rows]
      .sort((a, b) => {
        return reversed
          ? getRowData(a).avg - getRowData(b).avg
          : getRowData(b).avg - getRowData(a).avg;
      });
  }, [
    getRowData,
    question,
    rows,
  ]);

  return (
    <>
      <AggregateDataHeaderRow
        className={styles.row}
        label="Key"
        onClose={query.rowIds && resetQueryState} />
      {sortedRows
        .map(row => (
          <Fragment key={row.base.id}>
            {renderRow(row)}
          </Fragment>
        ))
      }
    </>
  );
};

function renderAccordionLabel(data: MatrixSliderQuestion.RepeatData.Data, row: SurveyQuestionMatrixRow) {
  const color = chart.options.OptionsColors[row.ordinal - 1];
  const subtitle = `(${formatNumber(data.avg, 1)} Avg.)`;

  return (
    <ResponseOption
      color={color}
      label={survey.parseSurveyRichText(row.value)}
      subtitle={subtitle} />
  );
}

export default MatrixSliderAggregateData;