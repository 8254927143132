import { useCallback, useContext } from 'react';
import { ParamsContext, RespondentSetupContext } from '$admin/components/Scheduling/Context';

export const useExitCallScheduling = () => {
  const ctx = {
    respondent: useContext(RespondentSetupContext),
    scheduling: useContext(ParamsContext),
  };

  const resetContexts = () => {
    ctx.scheduling.resetContext();
    ctx.respondent.resetContext();
  };

  return useCallback(resetContexts, [
    ctx.respondent,
    ctx.scheduling,
  ]);
};