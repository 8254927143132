import {
  useProfileState, useGroupEntityTagsLoading, useGroupEntityTags,
  useGroupEntityTagsUpdate,
} from '@containers';
import { useMemberFiles } from '@utils/api';
import { GroupSidebarTags } from '@/components/GroupSidebarTags';
import { WorkspaceSidebarExpertFiles } from '@/components/WorkspaceSidebarExpertFiles';
import { useHasGroupFeature } from '@store/hooks';
import { Card, Column, ScreenLayout } from './Layout';
import Profile from './Profile';
import Helmet from './Helmet';

function UserProfileEnterprise(props: unknown) {
  const canViewTags = useHasGroupFeature('userTagging');

  return (
    <>
      <Helmet />
      <ScreenLayout>

        <Column>
          <Profile />
        </Column>

        {canViewTags &&
          <Column desktop>
            <Sidebar />
          </Column>
        }

      </ScreenLayout>
    </>
  );
}

function Sidebar() {
  const [user] = useProfileState();
  const tagsLoading = useGroupEntityTagsLoading();

  const userId = user?.id;

  const {
    data: files,
    isInitialLoading: filesLoading,
  } = useMemberFiles(userId, {
    enabled: !!userId,
    refetchOnWindowFocus: false,
  });

  if (!userId || tagsLoading || filesLoading) return null;

  return (
    <>
      <Card desktop>
        <Tags />
        <WorkspaceSidebarExpertFiles
          items={files} />
      </Card>
    </>
  );
}

function Tags() {
  const tags = useGroupEntityTags();
  const update = useGroupEntityTagsUpdate();

  return (
    <GroupSidebarTags
      tags={tags}
      onSave={update} />
  );
}

export { UserProfileEnterprise };
export default UserProfileEnterprise;