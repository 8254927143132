import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { AdHocCall } from '@/types';
import Toast from '@/components/Toast';
import { getOtherAdHocCallParticipant } from './utils.calls';

const selector = (state: Store.State) => {

  return {
    cancellation: {
      error: () => {
        return Toast.error({
          title: 'Error',
          body: `We're sorry. There was an issue attempting to cancel this call. Please try again.`,
        });
      },
      success: (callId: number) => {
        const call = state.calls[callId];
        const participantId = getOtherAdHocCallParticipant(call, state.user.id);
        const participant = state.contacts[participantId];

        return Toast.success({
          title: 'Call Cancelled',
          body: `${participant.profile.firstName} has been sent a notification that the scheduled call has been cancelled.`,
        });
      },
    },
    confirmation: {
      error: () => {
        return Toast.error({
          title: 'Error',
          body: `We're sorry. There was an issue with your request. Please try again.`,
        });
      },
      success: (data: Pick<AdHocCall, 'request' | 'timeStart'>) => {
        const date = format(data.timeStart, 'M/d/yyyy');
        const time = format(data.timeStart, 'h:mm a');

        Toast.alert({
          title: 'Call Scheduled',
          body: `You have successfully scheduled the ${data.request.subject} call for ${date} at ${time}. A calendar invitation has been sent to you as a reminder.`,
        });
      },
    },
    proposal: {
      error: () => {
        return Toast.error({
          title: 'Error',
          body: `We're sorry. There was an issue with your request. Please try again.`,
        });
      },
      success: (callId: number) => {
        const call = state.calls[callId];
        const participantId = getOtherAdHocCallParticipant(call, state.user.id);
        const participant = state.contacts[participantId];

        return Toast.success({
          title: 'New Times Sent',
          body: `${participant.profile.firstName} has been notified of your proposed new times.`,
        });
      },
    },
    rescheduling: {
      success: (callId: number) => {
        const call = state.calls[callId];
        const participantId = getOtherAdHocCallParticipant(call, state.user.id);
        const participant = state.contacts[participantId];

        return Toast.success({
          title: 'Call Reschedule Request Sent',
          body: `${participant.profile.firstName} has been sent your rescheduling request.`,
        });
      },
    },
  };
};

export const useAdHocCallSchedulingNotifier = () => useSelector(selector);