import { NodeViewComponentProps } from '@remirror/react';
import { ComponentType, useMemo } from 'react';
import { useSurveyConditionsBuilder, useSurveyMatchContext } from '@/containers/SurveyBuilder.Logic/context';
import styles from './style/Expression.Extension.css';

export const ConditionNode: ComponentType<NodeViewComponentProps> = props => {

  const ctx = useSurveyConditionsBuilder();

  return ctx
    ? <BuilderNode {...props} />
    : <Node {...props} />;
};

function BuilderNode({ node }: NodeViewComponentProps) {
  const { items } = useSurveyConditionsBuilder();

  const conditionIndex = useMemo(() => {
    const identifier = node.attrs.identifier as string;

    return items.findIndex(f => f.identifier === identifier);
  }, [items, node.attrs]);

  return (
    <span className={styles.condition}>
      C{conditionIndex + 1}
    </span>
  );
}

function Node({ node }: NodeViewComponentProps) {

  const match = useSurveyMatchContext();

  const conditionIndex = useMemo(() => {
    const identifier = node.attrs.identifier as string;

    return match.conditions.findIndex(f => f.identifier === identifier);
  }, [match.conditions, node.attrs]);

  return (
    <span className={styles.condition}>
      C{conditionIndex + 1}
    </span>
  );
}