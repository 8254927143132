import { memo } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { getLocationFor } from '@utils';
import { Chip } from '@/presentation/Chip';

type Props = {
  className?: string;
};

export const WorkspacesNavMenu = memo(({ className }: Props) => {

  return (
    <Root className={className}>
      <Label>Workspaces:</Label>
      <Link to={getLocationFor.workspaces.root({ workspace: 'personal' })}>
        <StyledChip clickable>
          Personal
        </StyledChip>
      </Link>
      <Link to={getLocationFor.workspaces.root({ workspace: 'group' })}>
        <StyledChip clickable>
          Group
        </StyledChip>
      </Link>
      <Link to={getLocationFor.workspaces.root({ workspace: 'shared' })}>
        <StyledChip clickable>
          Shared
        </StyledChip>
      </Link>
    </Root>
  );
});

const Label = styled.div({
  fontFamily: 'var(--font-semibold)',
  fontSize: 18,
  marginRight: 10,
});

const Root = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const StyledChip = styled(Chip)({
  marginRight: 10,
  padding: '4px 6px',
});
