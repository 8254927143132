import { memo } from 'react';
import { FileIconProps } from './interfaces';

export const VideoIcon = memo(({
  className,
  size = 24,
}: FileIconProps) => {

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 25 25">
      <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="play-circle-(2)" transform="translate(11.000000, 9.000000)" stroke="#E92161" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
          <polygon id="Path" points="-1.81188398e-13 2.35367281e-14 5.4 3.6 -1.81188398e-13 7.2" />
        </g>
        <rect id="Rectangle" stroke="#E92161" strokeWidth="2" x="2" y="5" width="21" height="15" rx="1" />
        <line x1="5" y1="5" x2="5" y2="20" id="Path-6" stroke="#E92161" strokeWidth="2" />
        <line x1="20" y1="5" x2="20" y2="20" id="Path-6" stroke="#E92161" strokeWidth="2" />
      </g>
    </svg>
  );
});

export default VideoIcon;