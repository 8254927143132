import { path } from '@consts';
import { getClient } from './client';
import type { Social } from './interfaces';
import * as $session from './session';
import * as $sessionHttp from './session-http';

export const authorize = (data: Social.Authorize.Params) => {
  return new Promise<Social.Authorize.Return>((resolve, reject) => {
    getClient().popup.authorize({
      connection: 'google-oauth2',
      domain: process.env.AUTH0_DOMAIN,
      redirectUri: `${process.env.FRONT_BASE_URL}${path.Inbound.Google}`,
      responseType: 'token id_token',
      scope: 'openid email profile',
      server: process.env.AUTH0_SERVER,
      token: data.token,
    }, (e, res) => {
      if (e) {
        console.log('error', e);
        return reject(e);
      }

      $sessionHttp.jwt({
        token: res.accessToken,
      })
      .then(session => {
        $session.set({
          authenticated: session.authenticated,
          csrf: session.csrf,
          expiry: session.expiry,
          userId: session.userId,
        });
        $session.setLastLoginStrategy({ strategy: 'standard' });
        return resolve(res);
      });
    });
  });
};

export const callback = (params: Social.AuthorizeCallback.Params) => {
  getClient().parseHash({ hash: params.hash }, (e, res) => {
    getClient().popup.callback({
      hash: params.hash,
      nonce: res?.idTokenPayload?.nonce,
      state: res?.state,
    });
  });
};