import { useCallback, useMemo } from 'react';
import {
  TemplateMedicalConditionsInput,
  TemplateMedicalSpecialtyInput,
  useCanSaveMedicalSpecialties,
  useTemplateMedicalSpecialties,
  useCanSaveQualificationVariable,
  useTemplateQualificationVariable,
  TemplateQualificationInput,
  useCanSaveMedicalConditionsMT,
  useTemplateMedicalMedicalConditionsMT,
  useTemplateMessageCategories,
  useCanSaveMessageCategories,
  MessageCategoriesInput,
  useCanSaveRichTextTpp,
  useSubmitRichTextTPP,
  useTemplateAttitudinals,
  useCanSaveAttitudinals,
  TemplateAttitudinalsInput,
  useCanSaveMTMaxDiff,
  useMTTemplateMaxDiff,
  useTemplateMTMessageAnalysis,
  useCanSaveMTMessageAnalysis,
  TemplateMessageAnalysisInput,
  MaxDiffInputs,
} from '@/components/Survey.Templates';
import { TemplateTPPRichTextContainer, TemplateTPPRichTextInput } from '@/components/Survey.Templates/Input.TPP.RichText';
import { useMessageTestingData } from '@/containers/SurveyBuilder';
import { Input } from '@/components/Input';
import {
  HelpText,
  ThanksPresetScreen,
} from './ProjectSurvey.Presets.Shared';
import { BackButton, Footer, NextButton, SkipStep } from './Footer';
import { useProjectSurveyStepperContext } from './context';
import { BasePresetScreen } from './ProjectSurvey.Presets.Shared';
import styles from './style/ProjectSurvey.Presets.css';

const LetsGetStarted = () => {

  const title = `Great, let's get started building your survey!`;
  const subtitleOne = `To set up your Message Testing Survey template, we'll ask a couple of questions which will be used to pre-populate the survey draft.`;
  const subtitleTwo = `You'll then be able to customize the survey draft with specific questions through our survey builder. Let's jump in!`;

  const actions = useProjectSurveyStepperContext();

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitleOne, subtitleTwo]} />
      <Footer>
        <BackButton onClick={actions.back} />
        <NextButton
          title="Get Started"
          onClick={actions.next} />
      </Footer>
    </>
  );
};

const MedicalSpecialtyScreen = () => {
  const { back, next, step } = useProjectSurveyStepperContext();

  const title = stepText(step - 1, `Medical Specialties`);
  const subtitle1 = `Which of the following primary medical specialties are you looking to collect responses from? Which should be disqualifying?`;

  const {
    onSubmit,
    ...inputProps
  } = useTemplateMedicalSpecialties();

  const canSubmit = useCanSaveMedicalSpecialties(inputProps.value);

  const handleNext = useCallback(() => {
    onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitle1]}>
        <TemplateMedicalSpecialtyInput {...inputProps} />
      </BasePresetScreen>
      <Footer>
        <BackButton onClick={handleBack} />
        <NextButton
          disabled={!canSubmit}
          onClick={handleNext} />
      </Footer>
    </>
  );
};

const QualificationScreen = () => {
  const { back, next, step } = useProjectSurveyStepperContext();

  const title = stepText(step - 1, `Qualification Variable`);
  const subtitle1 = `Which of the following variables will be used to qualify a respondent to participate in the study? (Select one)`;

  const {
    onSubmit,
    ...inputProps
  } = useTemplateQualificationVariable();

  const canSubmit = useCanSaveQualificationVariable(inputProps.value);

  const handleNext = useCallback(() => {
    onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitle1]}>
        <TemplateQualificationInput {...inputProps} />
      </BasePresetScreen>
      <Footer>
        <BackButton onClick={handleBack} />
        <NextButton
          disabled={!canSubmit}
          onClick={handleNext} />
      </Footer>
    </>
  );
};

export const MedicalConditionsScreen = () => {
  const { back, next, step } = useProjectSurveyStepperContext();

  const title = stepText(step - 1, `Medical Conditions`);
  const subtitle1 = `Which of the following medical conditions are you looking to gather data around from respondents?`;

  const {
    onSubmit,
    ...inputProps
  } = useTemplateMedicalMedicalConditionsMT();

  const canSubmit = useCanSaveMedicalConditionsMT(inputProps.value);

  const handleNext = useCallback(() => {
    onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitle1]}>
        <TemplateMedicalConditionsInput {...inputProps} />
      </BasePresetScreen>
      <Footer>
        <BackButton onClick={handleBack} />
        <NextButton
          disabled={!canSubmit}
          onClick={handleNext} />
      </Footer>
    </>
  );
};

const MessagesScreen = () => {
  const { back, next, step } = useProjectSurveyStepperContext();

  const title = stepText(step - 1, `Message List`);
  const subtitle1 = `Please provide the messages you are looking to evaluate in this study along with the categories they belong to.`;

  const {
    onSubmit,
    handlers,
    value,
  } = useTemplateMessageCategories();

  const canSubmit = useCanSaveMessageCategories(value);

  const handleNext = useCallback(() => {
    onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitle1]}>
        <MessageCategoriesInput
          value={value}
          {...handlers} />
      </BasePresetScreen>
      <Footer>
        <BackButton onClick={handleBack} />
        <NextButton
          disabled={!canSubmit}
          onClick={handleNext} />
      </Footer>
    </>
  );
};

const TPPScreenWithState = () => {

  const [templateData] = useMessageTestingData();

  return (
    <TemplateTPPRichTextContainer
      initialValue={templateData.tpp}>
      <TPPScreen />
    </TemplateTPPRichTextContainer>
  );
};

const TPPScreen = () => {
  const { back, next, step } = useProjectSurveyStepperContext();

  const title = stepText(step - 1, `TPP`);
  const subtitle1 = `Please set up the TPP data for this survey.`;

  const canSubmit = useCanSaveRichTextTpp();

  const onSubmit = useSubmitRichTextTPP();

  const handleNext = useCallback(() => {
    onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitle1]}>
        <TemplateTPPRichTextInput />
      </BasePresetScreen>
      <Footer>
        <BackButton onClick={handleBack} />
        <NextButton
          disabled={!canSubmit}
          onClick={handleNext} />
      </Footer>
    </>
  );
};

const AttitudinalsScreen = () => {
  const { back, next, step } = useProjectSurveyStepperContext();

  const title = stepText(step - 1, `Attitudinals`);
  const subtitle1 = `Please provide a series of attitudinals that a respondent can evaluate in the conext of this study.`;

  const {
    onSubmit,
    ...inputProps
  } = useTemplateAttitudinals();

  const canSubmit = useCanSaveAttitudinals(inputProps.values);

  const handleNext = useCallback(() => {
    onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitle1]}>
        <TemplateAttitudinalsInput {...inputProps} />
      </BasePresetScreen>
      <Footer>
        <BackButton onClick={handleBack} />
        <NextButton
          disabled={!canSubmit}
          onClick={handleNext} />
      </Footer>
    </>
  );
};

const MaxDiffScreen = () => {
  const { back, next, step } = useProjectSurveyStepperContext();

  const title = stepText(step - 1, `Max-Diff Exercise`);
  const subtitle1 = `Please provide some details for the max-diff exercise a respondent will complete.`;

  const {
    onChange,
    onSubmit,
    value,
  } = useMTTemplateMaxDiff();

  const canSubmit = useCanSaveMTMaxDiff(value);

  const handleNext = useCallback(() => {
    onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitle1]}>
        <MaxDiffInputs
          onChange={onChange}
          value={value} />
      </BasePresetScreen>
      <Footer>
        <BackButton onClick={handleBack} />
        <NextButton
          disabled={!canSubmit}
          onClick={handleNext} />
      </Footer>
    </>
  );
};

const MessageAnalysisScreen = () => {
  const { back, next, step } = useProjectSurveyStepperContext();

  const title = stepText(step - 1, `Message Analysis Exercise`);
  const subtitle1 = `Please provide the axis headers for each message analysis exercise a respondent will complete.`;

  const {
    onChange,
    onSubmit,
    items,
  } = useTemplateMTMessageAnalysis();

  const canSubmit = useCanSaveMTMessageAnalysis(items);

  const handleNext = useCallback(() => {
    onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitle1]}>
        <TemplateMessageAnalysisInput
          onChange={onChange}
          value={items} />
      </BasePresetScreen>
      <Footer>
        <BackButton onClick={handleBack} />
        <NextButton
          disabled={!canSubmit}
          onClick={handleNext} />
      </Footer>
    </>
  );
};

export const MessageTestingScreens = [
  LetsGetStarted,
  MedicalSpecialtyScreen,
  QualificationScreen,
  MedicalConditionsScreen,
  MessagesScreen,
  TPPScreenWithState,
  MessageAnalysisScreen,
  MaxDiffScreen,
  AttitudinalsScreen,
  ThanksPresetScreen,
];

function stepText(step: number, value: string) {
  return `Step ${step} of ${totalSteps}: ${value}`;
}

const totalSteps = MessageTestingScreens.length - 2;