import { useCallback } from 'react';
import { GrayOutlineButton } from '@presentation/Buttons';
import { useSubmitSurveyDraft } from '@/containers/SurveyBuilder';
import { Button } from '@/components/Button';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import ModalHeader from '@/components/Modal/Header';
import { useModal } from '@/components/Modal/hooks';
import { useCanSaveEfficacyEndpoints, useTemplateEfficacyEndpoints } from './hooks/efficacy-endpoints';
import { TemplateEfficacyEndpointsInput } from './Input.EfficacyEndpoints';
import styles from './style/Modal.css';

type Props =
  Pick<ModalProps,
    'open' |
    'onClose'>;

export const TemplateEfficacyEndpointsModal = ({
  onClose,
  open,
}: Props) => {

  const {
    onSubmit,
    ...inputProps
  } = useTemplateEfficacyEndpoints();

  const submitDraft = useSubmitSurveyDraft();

  const handleSubmit = useCallback(() => {

    onSubmit();
    submitDraft();
    onClose();
  }, [
    onSubmit,
    onClose,
    submitDraft,
  ]);

  const canSubmit = useCanSaveEfficacyEndpoints({
    primary: inputProps.primary.items,
    secondary: inputProps.secondary.items,
  });

  return (
    <Modal
      open={open}
      onClose={onClose}>

      <ModalHeader text="Update Efficacy Endpoints" />

      <div className={styles.body}>
        <TemplateEfficacyEndpointsInput
          {...inputProps} />
      </div>

      <div className={styles.footer}>
        <GrayOutlineButton
          onClick={onClose}
          title="Cancel" />
        <Button.Primary
          className={styles.submit}
          disabled={!canSubmit}
          onClick={handleSubmit}
          title="Submit"
          variant="brick" />
      </div>
    </Modal>
  );
};

export const useTemplateEfficacyEndpointsModal = () => useModal(TemplateEfficacyEndpointsModal);

export default TemplateEfficacyEndpointsModal;