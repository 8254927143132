import { plans } from '$website/containers/Pricing/content';
import * as Pricing from './interfaces';

export function buildFeatureGroup(items: Pricing.ServiceDescriptor[]) {
  return items.map(x => {
    return {
      service: x,
      customer: createFeatureCell(plans.customer, x),
      market: createFeatureCell(plans.market, x),
      enterprise: createFeatureCell(plans.enterprise, x),
    };
  });
}

export function buildTieredGroup(items: Pricing.ServiceDescriptor[]) {
  return items.map(x => {
    return {
      service: x,
      customer: createTieredCell(plans.customer, x),
      market: createTieredCell(plans.market, x),
      enterprise: createTieredCell(plans.enterprise, x),
    };
  });
}

export function buildUsageGroup(items: Pricing.ServiceDescriptor[]) {
  return items.map(x => {
    return {
      service: x,
      customer: createUsageCell(plans.customer, x),
      market: createUsageCell(plans.market, x),
      enterprise: createUsageCell(plans.enterprise, x),
    };
  });
}

export function createHeaderRow(cell: Pricing.ServiceDescriptor) {
  const item = {
    id: cell.id,
    type: 'header',
    value: '',
  } as Pricing.HeaderCell;

  return {
    service: {
      id: item.id,
      name: cell.name,
      type: item.type,
    },
    customer: item,
    market: item,
    enterprise: item,
  };
}

export function createSpacerRow() {
  const item = {
    id: 'spacer',
    type: 'spacer',
    value: '',
  } as Pricing.SpacerCell;

  return {
    service: {
      id: item.id,
      name: '',
      type: item.type,
    },
    customer: item,
    market: item,
    enterprise: item,
  };
}

function createFeatureCell(plan: Pricing.Plan, feature: Pricing.ServiceDescriptor): Pricing.FeatureCell {
  if (plan.features.includes(feature.id)) {
    return {
      id: feature.id,
      included: true,
      type: 'feature',
      value: feature.name,
    };
  }

  return {
    id: feature.id,
    included: false,
    type: 'feature',
    value: '',
  };
}

function createTieredCell(plan: Pricing.Plan, feature: Pricing.ServiceDescriptor): Pricing.TieredCell {
  const item = plan.tiered.find(x => x.id === feature.id);
  if (item) {
    return {
      id: item.id,
      type: 'tiered',
      value: item.value,
    };
  }

  return {
    id: feature.id,
    type: 'tiered',
    value: '',
  };
}

function createUsageCell(plan: Pricing.Plan, feature: Pricing.ServiceDescriptor): Pricing.UsageCell {
  const item = plan.usage.find(x => x.id === feature.id);

  if (item) {
    return {
      id: item.id,
      type: 'usage',
      value: item.value,
    };
  }

  return {
    id: feature.id,
    type: 'usage',
    value: '',
  };
}