import { useCallback } from 'react';
import { useAudioPlayerRef, useAudioPlayerState } from '../Context';

export const useToggleAudioPlaying = () => {

  const [ref] = useAudioPlayerRef();
  const [{ playing }] = useAudioPlayerState();

  return useCallback(() => {
    if (playing) {
      ref.pause();
    } else {
      ref.play();
    }
  }, [
    playing,
    ref,
  ]);

};