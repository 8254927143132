import { ComponentType, useCallback } from 'react';
import { Folder, Briefcase } from 'react-feather';
import { Brand } from '@/components/icons';
import { TreeView, TreeItem, TreeViewProps, TreeItemContextValue } from '@/components/TreeView';
import { WorkspaceObjectTreeItem, WorkspaceObject } from '@/types';
import { cx, trunc } from '@/utils';
import { WorkspaceObjectType } from '@/enums';
import styles from './style/WorkspaceFolderTree.css';

type Props = {
  data: WorkspaceObjectTreeItem;
} & Omit<TreeViewProps, 'children'>;

export const WorkspaceFolderTree = ({ data, ...treeProps }: Props) => {

  const renderNode = useCallback((object: WorkspaceObject) => (ctx: TreeItemContextValue) => {

    const rootClass = cx(styles.node, {
      [styles.expandable]: ctx.expandable,
      [styles.selected]: ctx.selected,
    });

    const expandClass = cx({
      [styles.chevronUp]: ctx.expandable && !ctx.expanded,
      [styles.chevronDown]: ctx.expandable && ctx.expanded,
      [styles.empty]: !ctx.expandable,
    });

    const iconMap: Partial<Record<WorkspaceObjectType, ComponentType<{ className: string }>>> = {
      [WorkspaceObjectType.ProjectParent]: Briefcase,
      [WorkspaceObjectType.Folder]: Folder,
      [WorkspaceObjectType.Workspace]: Folder,
      [WorkspaceObjectType.Brand]: Brand,
    };

    const Icon = iconMap[object.typeId];

    return (
      <div className={rootClass}>
        <div className={expandClass} />
        <div className={styles.label}>
          <Icon className={styles.icon} />
          <div className={styles.labelText}>
            {trunc(object.name, 50)}
          </div>
        </div>
      </div>
    );
  }, []);

  const renderTree = useCallback((item: WorkspaceObjectTreeItem) => (
    <TreeItem
      key={item.object.id}
      classes={{ children: styles.children }}
      nodeId={item.object.id}
      renderNode={renderNode(item.object)}>
      {item.children.map(node => renderTree(node))}
    </TreeItem>
  ), [renderNode]);

  return (
    <TreeView
      defaultExpanded={[data.object.id]}
      {...treeProps}>
      {renderTree(data)}
    </TreeView>
  );
};

export default WorkspaceFolderTree;