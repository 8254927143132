import { TranscriptSummaryCitationsContainer } from './Transcript.Citations';
import { TranscriptSummaryFeedbackFormContainer } from './Transcript.Feedback.Form';
import { TranscriptSummaryFeedbackSummarysContainer } from './Transcript.Feedback.Summary';
import { TranscriptSummaryRatingsContainer } from './Transcript.Ratings';
import { TranscriptSummaryRefreshContainer } from './Transcript.Refresh';
import { TranscriptSummaryVersionsContainer } from './Transcript.Versions';

type Props = ChildrenProps & ITranscriptId;

export const TranscriptSummaryContainer = ({ children, transcriptId }: Props) => {

  return (
    <TranscriptSummaryFeedbackFormContainer transcriptId={transcriptId}>
      <TranscriptSummaryRefreshContainer transcriptId={transcriptId}>
        <TranscriptSummaryCitationsContainer transcriptId={transcriptId}>
          <TranscriptSummaryFeedbackSummarysContainer transcriptId={transcriptId}>
            <TranscriptSummaryRatingsContainer transcriptId={transcriptId}>
              <TranscriptSummaryVersionsContainer transcriptId={transcriptId}>
                {children}
              </TranscriptSummaryVersionsContainer>
            </TranscriptSummaryRatingsContainer>
          </TranscriptSummaryFeedbackSummarysContainer>
        </TranscriptSummaryCitationsContainer>
      </TranscriptSummaryRefreshContainer>
    </TranscriptSummaryFeedbackFormContainer>
  );
};