import { useContext, useMemo } from 'react';
import { SearchLoadingAllContext, SearchResultsAllContext } from '@/containers/WorkspaceGlobalSearch/Context';
import { useSearchFiltersQuery } from '@/containers/WorkspaceGlobalSearch/hooks';
import SearchTabAll from './Search.Tab.All';
import SearchTabBrands from './Search.Tab.Brands';
import SearchTabProjects from './Search.Tab.Projects';
import SearchTabFiles from './Search.Tab.Files';
import SearchTabFolders from './Search.Tab.Folders';
import SearchTableTranscripts from './Search.Tab.Transcripts';
// import SearchTabMembers from './Search.Tab.Members';
import SearchTabView from './Search.Tab.View';
import type { SearchSceneMap } from './interfaces';
import { SearchRouteKey } from './interfaces';

export default function SearchTabViewContainer() {
  const [query] = useSearchFiltersQuery();
  const result = useContext(SearchResultsAllContext);
  const loading = useContext(SearchLoadingAllContext);

  // const showBrands = useMemo(() => {
  //   if (['brands'].includes(query.tab)) return true;
  //   return !loading.loading && !!result.brands?.total;
  // }, [
  //   query.tab,
  //   loading.loading,
  //   result.brands?.total,
  // ]);

  // const showProjects = useMemo(() => {
  //   if (['projects'].includes(query.tab)) return true;
  //   return !loading.loading && !!result.projects?.total;
  // }, [
  //   query.tab,
  //   loading.loading,
  //   result.projects?.total,
  // ]);

  // const showTranscripts = useMemo(() => {
  //   if (['transcripts'].includes(query.tab)) return true;
  //   return !loading.loading && !!result.transcripts?.total;
  // }, [
  //   query.tab,
  //   loading.loading,
  //   result.transcripts?.total,
  // ]);

  // const showFiles = useMemo(() => {
  //   if (['files'].includes(query.tab)) return true;
  //   return !loading.loading && !!result.files?.total;
  // }, [
  //   query.tab,
  //   loading.loading,
  //   result.files?.total,
  // ]);

  // const showFolders = useMemo(() => {
  //   if (['folders'].includes(query.tab)) return true;
  //   return !loading.loading && !!result.folders?.total;
  // }, [
  //   query.tab,
  //   loading.loading,
  //   result.folders?.total,
  // ]);
  const showBrands = true;
  const showProjects = true;
  const showTranscripts = true;
  const showFiles = true;
  const showFolders = true;

  const scenes: SearchSceneMap = useMemo(() => ({
    [SearchRouteKey.All]: {
      component: SearchTabAll,
      title: 'All',
    },
    [SearchRouteKey.Brands]: showBrands
      ? {
        component: SearchTabBrands,
        title: 'Brands',
      } : undefined,
    [SearchRouteKey.Projects]: showProjects
      ? {
        component: SearchTabProjects,
        title: 'Projects',
      } : undefined,
    // [SearchRouteKey.Members]: {
    //   component: SearchTabMembers,
    //   title: 'Experts',
    // },
    [SearchRouteKey.Transcripts]: showTranscripts
      ? {
        component: SearchTableTranscripts,
        title: 'Transcripts',
      } : undefined,
    [SearchRouteKey.Files]: showFiles
      ? {
        component: SearchTabFiles,
        title: 'Files',
      } : undefined,
    [SearchRouteKey.Folders]: showFolders
      ? {
        component: SearchTabFolders,
        title: 'Folders',
      } : undefined,
  }), [
    showBrands,
    showProjects,
    showTranscripts,
    showFiles,
    showFolders,
  ]);

  return <SearchTabView scenes={scenes} />;
}

export { SearchTabViewContainer };