import { Meter } from '@/components/Progress/Meter';
import styles from './style.css';

type Props = {
  value: number;
};

const SearchQualityMeter = (props: Props) => {
  return (
    <div className={styles.feedback}>
      <div className={styles.meter}>
        <div className={styles.sm}>Search Quality</div>
        <Meter
          max={95}
          min={15}
          orientation="vertical"
          value={props.value} />
      </div>
    </div>
  );
};

export { SearchQualityMeter };
export default SearchQualityMeter;