import type {
  ApplySchemaAttributes,
  NodeExtensionSpec,
  NodeSpecOverride } from '@remirror/core';
import {
  command,
  extension,
  CommandFunction,
  ExtensionTag,
  NodeExtension,
} from '@remirror/core';
import { insertPoint } from '@remirror/pm/transform';
import type { SurveyRichText } from '@/types/survey.rich-text';
import * as Remirror from '@/types/rich-text';
import { FindAndReplace } from './Node.FindAndReplace';

@extension({
  defaultOptions: {},
})
export class FindAndReplaceExtension extends NodeExtension {
  get name() {
    return Remirror.NodeType.FindAndReplace;
  }

  createTags() {
    return [ExtensionTag.InlineNode];
  }

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    return {
      ...override,
      atom: true,
      attrs: {
        identifier: {},
        groupingId: {
          default: null,
        },
        label: {},
        value: {
          default: '',
        },
        touched: {
          default: false,
        },
        ...extra.defaults(),
      },
      inline: true,
      toDOM: node => ['span', {
        ...extra.dom(node),
        contentEditable: 'false',
        selectable: 'false',
      }],
    };
  }

  @command()
  addFindAndReplaceNode(attributes: SurveyRichText.FindAndReplaceNode['attrs']): CommandFunction {
    return ({ tr, dispatch }) => {
      const pos = tr.selection.from;
      const node = this.type.create(attributes);

      const insertPos = insertPoint(tr.doc, pos, this.type);

      dispatch?.(tr.insert(insertPos, node));

      return true;
    };
  }

  @command()
  updateFindAndReplaceNode(attributes: SurveyRichText.FindAndReplaceNode['attrs']): CommandFunction {
    return ({ tr, dispatch }) => {
      dispatch?.(tr.replaceSelectionWith(this.type.create(attributes)));
      return true;
    };
  }

  @command()
  removeFindAndReplaceNode(): CommandFunction {
    return ({ tr, dispatch }) => {
      dispatch?.(tr.deleteSelection());
      return true;
    };
  }

  ReactComponent = FindAndReplace;
}