import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { path } from '@consts';
import { getLocationFor } from '@utils';
import styles from './style.css';

const mapState = (state: Store.State) => ({
  me: state.user,
});

type Props = undefined;

const ProjectSurveyArchived = (props: Props) => {
  const state = useSelector(mapState);

  const location = getLocationFor.me.profile({
    slug: state.me.profile.slug,
    userId: state.me.id,
  });

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h1 className={styles.header}>{header}</h1>
        <div className={styles.body}>{msg1}</div>
        <div className={styles.body}>{msg2}</div>
        <div className={styles.footer}>
          <div className={styles.link}>
            <Link
              replace
              to={location}>
              {`Update Profile >`}
            </Link>
          </div>
          <div className={styles.nodecoration}>
            <Link
              replace
              to={path.Projects.Root}>
              {`Go to Dashboard >`}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const header = `Hmm, looks like this survey is no longer active, but we will keep you posted on future opportunities.`;
const msg1 = `Want to improve your chances of getting selected for future surveys?`;
const msg2 = `Update your profile so that clients will know what kinds of projects you'd like to participate in.`;

export { ProjectSurveyArchived };
export default ProjectSurveyArchived;