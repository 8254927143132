import { useSurveyThemingPalette } from '@containers/Branding/hooks/useSurveyThemingPalette';
import { LinearProgress } from '@/components/Progress';

type Props = {
  value: number;
};

export const FormProgressBar = (props: Props) => {
  const { palette, theme } = useSurveyThemingPalette();

  return (
    <LinearProgress
      color={palette?.primary?.main}
      value={props.value} />
  );
};

FormProgressBar.displayName = 'FormProgressBar';