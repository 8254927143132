import { useMemo } from 'react';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { isQuestionInAction } from '@/containers/SurveyBuilder.Logic/utils.lookup';

export const useActionsLogic = (questionIdentifier: string) => {
  const [state] = useSurveyBuilderState();

  const logic = useMemo(() => {
    return state.survey.logic
      .filter(item => {
        return isQuestionInAction({
          action: item.action,
          question: { identifier: questionIdentifier },
        });
      });
  }, [questionIdentifier, state.survey.logic]);

  return logic;
};