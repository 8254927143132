import { RespondentProps } from '@/types';
import styles from './style/ResponseTextItem.css';
import { ResponseItem } from './ResponseItem';

export type ResponseTextItemProps = {
  className?: string;
  rationale?: string;
  respondent: RespondentProps;
  onToggleStar: () => void;
  starred: boolean;
  value: string;
  wrapClassName?: string;
};

export const ResponseTextItem = ResponseItem;

export const ResponseOptionFreeTextItem = (props: ResponseTextItemProps) => {
  return (
    <ResponseTextItem
      wrapClassName={styles.optionWrap}
      disabledStarring={!props.rationale}
      {...props} />
  );
};

export default ResponseTextItem;