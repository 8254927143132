import { useContext, useMemo } from 'react';
import { QuantAnalysisItemsContext, SelectedQuantAnalysisItemContext } from '../context';

export const useSelectedQuantAnalysisItem = () => {
  const selectedId = useContext(SelectedQuantAnalysisItemContext);
  const items = useContext(QuantAnalysisItemsContext);

  return useMemo(() => {
    return items.find(item => item.id === selectedId);
  }, [items, selectedId]);
};