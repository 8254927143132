import { setStyle } from '@remirror/core';
import type { EditorView, NodeView, ProsemirrorNode } from '@remirror/pm';
import type { ImageAttributes } from './interfaces.image';

export type DisplayOptions = {
  sizeOverrides?: {
    width: string;
    height: string;
  };
};
export class DisplayView implements NodeView {
  dom: HTMLElement;
  private image: HTMLImageElement;
  private dialogImage: HTMLImageElement;

  constructor(node: ProsemirrorNode, view: EditorView, getPos: () => number, options: DisplayOptions = {}) {
    const attrs = node.attrs as ImageAttributes;
    this.dom = document.createElement('div');

    this.image = createImage(options.sizeOverrides || {
      width: !isNaN(+attrs.width) ? `${attrs.width}px` : attrs.width.toString(),
      height: !isNaN(+attrs.height) ? `${attrs.height}px` : attrs.height.toString(),
    });
    this.dom.appendChild(this.image);

    const dialog = document.createElement('dialog');
    this.dialogImage = createImage({
      maxHeight: 'calc(100vh - 100px)',
      maxWidth: 'calc(100vw - 100px)',
    });
    dialog.appendChild(this.dialogImage);
    dialog.onclick = e => {
      if (e.target === dialog) {
        dialog.close();
      }

      e.stopPropagation();
      return false;
    };

    this.dom.appendChild(dialog);

    this.image.onclick = e => {
      dialog.showModal();
      e.stopPropagation();

      return false;
    };

    function createImage(style: Partial<CSSStyleDeclaration>) {
      const img = document.createElement('img');
      img.setAttribute('src', attrs.src);
      img.setAttribute('data-cid', attrs.cid as string);
      if (style) {
        setStyle(img, style);
      }
      return img;
    }
  }

  update(node: ProsemirrorNode): boolean {
    console.log('dom updated');
    if (node.type !== node.type.schema.nodes.image) {
      return false;
    }

    updateImage(this.image);
    updateImage(this.dialogImage);

    return true;

    function updateImage(img: HTMLImageElement) {
      img.setAttribute('src', node.attrs.src as string);
      img.setAttribute('data-cid', node.attrs.cid as string);
    }
  }

  destroy(): void {
    this.dom.remove();
  }

  stopEvent(): boolean {
    return true;
  }
}