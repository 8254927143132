import { memo } from 'react';
import { cx } from '@utils';
import { Star } from '@/components/icons';
import { MaxStars } from './interfaces';
import styles from './style.css';

type Props = {
  className?: string;
  rating: number;
};

export const StarRatingPreview = memo(({ className, rating }: Props) => {

  return (
    <div className={cx(styles.previewRoot, className)}>
      {Array.from(new Array(MaxStars)).map((_, i) => (
        <Star
          key={i}
          className={cx(styles.star, styles.preview)}
          fillColor={'var(--pri-02)'}
          fillPercentage={Math.max(Math.min(rating - i, 1), 0)} />
      ))}
    </div>
  );
});