import { FilePreview } from '@/components/Workspace.File.Preview';
import { useHasGroupFeature } from '@/store/hooks';
import Sidebar from './Sidebar';
import styles from './style/Tab.css';

const StateDependent = () => {
  const canViewTags = useHasGroupFeature('userTagging');

  return (
    <div className={styles.content}>
      <div className={styles.col}>
        <FilePreview />
      </div>
      {canViewTags &&
        <div className={styles.col}>
          <Sidebar forehead={false} />
        </div>
      }
    </div>
  );
};

export const FileTabPreview = () => {
  return (
    <StateDependent />
  );
};