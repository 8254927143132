import { useCallback, useContext } from 'react';
import { SearchParamsContext } from '../Context';
import { Action, Compat, initial  } from './useSearchParamsState';

const useCompatSearchParams = () => {
  const [params, actions] = useContext(SearchParamsContext);

  const compatDispatch = (action: Action) => {
    const map: Compat.DispatchMap = {
      ADD_TO_ADVANCED_FILTER_LIST: data => actions.push(data.list, data.item),
      REMOVE_FROM_ADVANCED_FILTER_LIST: data => actions.pop(data.list, data.id),
      SET_SEARCH_FILTERS: params => actions.init(params),
      BATCH_ACTIONS: data => actions.batch(data.actions),
      RESET_SEARCH_FILTERS: () => actions.clear(),
      SET_PRICE_RANGE: data => actions.between('price', [data.low, data.high]),
      UPDATE_SEARCH_SORT: data => data.sort === params.sort ? actions.orderBy(data.sortDir) : actions.sortBy(data.sort),
      KEYWORD_SEARCH_UPDATE: ({ keyword }) => actions.init({ ...initial, keyword: [{ id: keyword }] }),
    };

    map[action.type]?.(action);
  };

  const dispatch = useCallback(compatDispatch, [
    actions,
    params.sort,
  ]);

  return [params, dispatch] as const;
};

export { useCompatSearchParams };
export default useCompatSearchParams;