/* eslint-disable @typescript-eslint/indent */
import { parseSurveyRichText } from '@/containers/Survey/utils';
import type {
  RepeatSurvey,
  MatrixGridQuestion,
  MatrixMultiselectQuestion,
  MatrixSliderQuestion,
  NumberInputTableQuestion,
  SurveyAggregate,
  SurveyFiltering,
  SurveyQuestion,
  MatrixRangeQuestion,
  HybridSurveyResponseUserId,
} from '@/types';
import { SurveyQuestionType } from '@enums';
import { intersect, indexBy, distinct } from '@utils/array';
import { sum, avg } from '@utils/math';

const userFilterMethods: Record<SurveyQuestionType, SurveyFiltering.UserFilterFunction> = {
  [SurveyQuestionType.LongTextResponse]: applyTextResponseFilter,
  [SurveyQuestionType.MatrixGrid]: applyMatrixGridFilter,
  [SurveyQuestionType.MultipleChoice]: applyMultiselectFilter,
  [SurveyQuestionType.Multiselect]: applyMultiselectFilter,
  [SurveyQuestionType.Ranking]: applyRankingFilter,
  [SurveyQuestionType.ShortTextResponse]: applyTextResponseFilter,
  [SurveyQuestionType.MaxDifference]: applyMaxDiffFilter,
  [SurveyQuestionType.ConjointAnalysis]: applyConjointFilter,
  [SurveyQuestionType.Sliders]: applySlidersFilter,
  [SurveyQuestionType.MatrixMultiselect]: applyMatrixMultiselectFilter,
  [SurveyQuestionType.NumberInputTable]: applyNumberInputTableFilter,
  [SurveyQuestionType.MultiTextbox]: applyMultiTextboxFilter,
  [SurveyQuestionType.ExclusiveOptions]: applyExclusiveOptionsFilter,
  [SurveyQuestionType.Dropdown]: applyMultiselectFilter,
  [SurveyQuestionType.MatrixRange]: applyMatrixRangeFilter,
  [SurveyQuestionType.ImageMarkup]: applyImageMarkupFilter,
};

export const validUserFilterMethods: Record<SurveyQuestionType, SurveyFiltering.FilterFunction> = {
  [SurveyQuestionType.LongTextResponse]: getValidTextUsers,
  [SurveyQuestionType.Sliders]: getValidSliderUsers,
  [SurveyQuestionType.MultipleChoice]: getValidMultiselectUsers,
  [SurveyQuestionType.Multiselect]: getValidMultiselectUsers,
  [SurveyQuestionType.Ranking]: null,
  [SurveyQuestionType.ShortTextResponse]: getValidTextUsers,
  [SurveyQuestionType.MaxDifference]: null,
  [SurveyQuestionType.ConjointAnalysis]: null,
  [SurveyQuestionType.MatrixGrid]: null,
  [SurveyQuestionType.MatrixRange]: null,
  [SurveyQuestionType.MatrixMultiselect]: null,
  [SurveyQuestionType.NumberInputTable]: null,
  [SurveyQuestionType.MultiTextbox]: null,
  [SurveyQuestionType.ExclusiveOptions]: null,
  [SurveyQuestionType.Dropdown]: getValidMultiselectUsers,
  [SurveyQuestionType.ImageMarkup]: null,
};

function hasValidFilters(filter: SurveyFiltering.FilterEntries) {
  return getNumberOfActiveFilters(filter) > 0;
}

function isFilterValid(filter: SurveyFiltering.QuestionFilter) {
  if (!filter) return false;

  if (filter.type == 'slider-filter') {
    return !isNaN(filter.value) && !isNaN(filter.optionId);
  }

  if (filter.type == 'option-filter') {
    return !!filter.optionIds && filter.optionIds.length;
  }

  if (filter.type == 'text-filter') {
    return !!filter.text;
  }

  return false;
}

export function getNumberOfActiveFilters(filter: SurveyFiltering.FilterEntries) {
  if (!filter || !filter.filters) return 0;

  return filter.filters.filter(f => isFilterValid(f.filter)).length;
}

type SurveysQuestionMap = Record<number, SurveyQuestion[]>;

type ResponsesMap = Record<number, SurveyAggregate.QuestionMap>;

export function getFilteredUserIds(filter: SurveyFiltering.FilterEntries, surveyQuestions: SurveysQuestionMap, responsesMap: ResponsesMap) {
  if (!hasValidFilters(filter)) {
    return null;
  }

  const filters = filter.filters;

  const questionMap = indexQuestionMap(surveyQuestions);

  const userArrays = filters.filter(f => isFilterValid(f.filter)).map(f => {
    const questionResponses = responsesMap[f.surveyId][f.questionId];

    const question = questionMap[f.surveyId][f.questionId];

    if (!question) return [];

    return validUserFilterMethods[question.typeId](f.filter, questionResponses.data);
  });

  //TODO: support AND/OR logic here
  return distinct(userArrays.reduce(intersect));
}

function indexQuestionMap(surveyQuestions: SurveysQuestionMap) {
  const questionMap: Record<number, Record<number, SurveyQuestion>> = {};

  for (const [surveyId, questions] of Object.entries(surveyQuestions)) {
    questionMap[+surveyId] = indexBy(questions, q => q.base.id);
  }

  return questionMap;
}

type FilterResponses = {
  filter: SurveyFiltering.FilterEntries;
  allData: {
    questions: SurveysQuestionMap;
    responses: ResponsesMap;
  };
  questions: SurveyQuestion[];
  responses: SurveyAggregate.QuestionMap;
};

export function filterResponses({ filter, questions, allData, responses }: FilterResponses) {
  if (!hasValidFilters(filter)) {
    return responses;
  }

  const userIds = getFilteredUserIds(filter, allData.questions, allData.responses);

  const questionMap = indexBy(questions, q => q.base.id);

  const filteredResponses = Object.keys(responses).reduce<SurveyAggregate.QuestionMap>((acc, questionIdString) => {
    const questionId = +questionIdString;
    const question = questionMap[questionId];

    const questionResponses = responses[questionId];
    const rationaleMap = userIds.reduce<SurveyAggregate.RationaleMap>((acc2, userId) => {
      acc2[userId] = questionResponses.rationale[userId];
      return acc2;
    }, {});

    const filterMethod = userFilterMethods[question.typeId];
    const filteredData = filterMethod({ userIds }, questionResponses.data, question);

    acc[questionId] = {
      data: filteredData,
      rationale: rationaleMap,
      total: userIds.length,
    };

    return acc;
  }, {});

  return filteredResponses;
}

type FilterRepeatResponsesParams = {
  filter: SurveyFiltering.FilterEntries;
  questions: SurveyQuestion[];
  repeatData: RepeatSurvey.Data;
  allData: {
    questions: SurveysQuestionMap;
    responses: ResponsesMap;
  };
};

export function filterRepeatResponses({ filter, repeatData, questions, allData }: FilterRepeatResponsesParams) {
  if (!hasValidFilters(filter)) {
    return repeatData;
  }
  else {
    //TODO: rebuild aggregate data
    return {
      ...repeatData,
      historical: repeatData.projectIds.reduce<RepeatSurvey.HistoricalData>((acc, i) => {
        acc[i] = filterResponses({
          filter,
          questions,
          responses: repeatData.historical[i],
          allData,
        });

        return acc;
      }, {}),
    };
  }
}

export function applyMultiselectFilter(filter: SurveyFiltering.UserFilter, data: SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.Multiselect]) {
  const filtered = Object.keys(data).reduce<SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.Multiselect]>((acc, k) => {
    const option = data[+k];

    acc[+k] = {
      ...option,
      userIds: option.userIds.filter(u => filter.userIds.includes(u)),
    };

    return acc;
  }, {});

  const total = sum(Object.keys(data).map(k => filtered[+k].userIds.length));

  Object.values(filtered).forEach(v => {
    v.pct = (v.userIds.length / total) * 100;
  });

  return filtered;
}

export function applyTextResponseFilter(filter: SurveyFiltering.UserFilter, data: SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.LongTextResponse]): SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.LongTextResponse] {
  return Object.keys(data)
    .map<HybridSurveyResponseUserId>(k => isNaN(+k) ? k : +k)
    .filter(k => filter.userIds.includes(k))
    .reduce<SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.LongTextResponse]>((acc, userId) => {
    acc[userId] = data[userId];
    return acc;
  }, {});
}

export function applySlidersFilter(filter: SurveyFiltering.UserFilter, data: SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.Sliders]): SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.Sliders] {
  const rows = Object.keys(data.rows).reduce((acc, x) => {
    const k = +x;

    const entry: MatrixSliderQuestion.Aggregate.RowResult = {
      ...data.rows[k],
      userIds: data.rows[k].userIds.filter(u => filter.userIds.includes(u)),
      responses: Object.entries(data.rows[k].responses).reduce((acc, [k, v]) => {
        const userId: HybridSurveyResponseUserId = isNaN(+k) ? k : +k;
        if (filter.userIds.includes(userId)) {
          acc[k] = v;
        }
        return acc;
      }, {}),
    };

    entry.avg = entry.userIds.length
      ? avg(Object.values(entry.responses).map(m => m.value).filter(Boolean))
      : 0;

    acc[k] = entry;

    return acc;
  }, {});

  return {
    rows,
  };
}

export function applyNumberInputTableFilter(filter: SurveyFiltering.UserFilter, data: SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.NumberInputTable]): SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.NumberInputTable] {
  return Object.keys(data).reduce((acc, rowId) => {
    const rowData = data[+rowId];

    const options = Object.keys(rowData.options).reduce<OptionMap>((acc2, optionId) => {
      const optionData = rowData.options[+optionId];

      type UserMap = Record<HybridSurveyResponseUserId, number>;

      const users =
        Object.keys(optionData.users)
          .map<HybridSurveyResponseUserId>(k => isNaN(+k) ? k : +k)
          .filter(userId => filter.userIds.includes(userId))
          .reduce<UserMap>((acc, userId) => {
            acc[userId] = optionData.users[userId];
            return acc;
          }, {});

      const newOptionData: NumberInputTableQuestion.Aggregate.RowOptionResult = {
        avg: Object.keys(users).length
          ? avg(Object.values(users).filter(Boolean))
          : 0,
        users,
      };

      return {
        ...acc2,
        [+optionId]: newOptionData,
      };
    }, {});

    type OptionMap = {
      [optionId: number]: {
        avg: number;
        users: {
          [userId: HybridSurveyResponseUserId]: number;
        };
      };
    };

    const rowAvg = avg(Object.values(options).map(m => m.avg));

    return {
      ...acc,
      [+rowId]: {
        avg: rowAvg,
        options,
      },
    };
  }, {});
}

export function applyMatrixGridFilter(filter: SurveyFiltering.UserFilter, data: SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.MatrixGrid]): SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.MatrixGrid] {
  return Object.keys(data).reduce<MatrixGridQuestion.Aggregate.Data>((acc, groupIdString) => {
    const groupId = +groupIdString;
    const groupData = data[groupId];

    const items = Object.keys(groupData.items).reduce<MatrixGridQuestion.Aggregate.GroupItems>((acc2, itemIdString) => {
      const itemId = +itemIdString;
      const itemData = groupData.items[itemId];
      const userIds = itemData.userIds.filter(u => filter.userIds.includes(u));
      const newItemData: MatrixGridQuestion.Aggregate.GroupItemResult = {
        ...itemData,
        pct: (userIds.length / filter.userIds.length) * 100,
        userIds,
      };

      acc2[itemId] = newItemData;

      return acc2;
    }, {});

    const missingUserIds = groupData.missing.userIds.filter(u => filter.userIds.includes(u));

    acc[groupId] = {
      ...groupData,
      missing: {
        pct: !groupData.missing.userIds.length
          ? 0
          : groupData.missing.pct * (missingUserIds.length / groupData.missing.userIds.length),
        userIds: missingUserIds,
      },
      items,
    };

    return acc;
  }, {});
}

export function applyMatrixRangeFilter(filter: SurveyFiltering.UserFilter, data: SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.MatrixRange], question: MatrixRangeQuestion.Question): SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.MatrixRange] {
  return Object.keys(data).reduce((acc, rowIdStr) => {
    const rowId = +rowIdStr;
    const rowData = data[rowId];

    const options = question.options.reduce<MatrixRangeQuestion.Aggregate.RowOptions>((acc2, option) => {
      const optionData = rowData.options[option.base.id];
      const userIds = optionData.userIds.filter(u => filter.userIds.includes(u));
      const newOptionData: MatrixRangeQuestion.Aggregate.RowOptionResult = {
        ...optionData,
        userIds,
      };
      acc2[option.base.id] = newOptionData;
      return acc2;
    }, {});

    const { userIds, sum } = question.options.reduce<UserSums>((acc2, option) => {
      const optionData = rowData.options[option.base.id];
      const userIds = optionData.userIds.filter(u => filter.userIds.includes(u));
      const optionValue = +parseSurveyRichText(option.value);

      return {
        sum: acc2.sum + (userIds.length * optionValue),
        userIds: acc2.userIds.concat(userIds),
      };
    }, {
      sum: 0,
      userIds: [],
    });

    const avg = userIds.length ? sum / userIds.length : 0;

    acc[rowId] = {
      avg,
      options,
    };
    return acc;
  }, {});

  type UserSums = {
    userIds: HybridSurveyResponseUserId[];
    sum: number;
  };
}

export function applyMatrixMultiselectFilter(filter: SurveyFiltering.UserFilter, data: SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.MatrixMultiselect]): SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.MatrixMultiselect] {
  return Object.keys(data).reduce<MatrixMultiselectQuestion.Aggregate.Data>((acc, groupIdString) => {
    const groupId = +groupIdString;
    const groupData = data[groupId];

    const items = Object.keys(groupData.items).reduce<{ [optionId: number]: { userIds: HybridSurveyResponseUserId[] } }>((acc2, itemIdString) => {
      const itemId = +itemIdString;
      const itemData = groupData.items[itemId];
      const userIds = itemData.userIds.filter(u => filter.userIds.includes(u));
      const newItemData: MatrixMultiselectQuestion.Aggregate.GroupItemResult = {
        userIds,
      };
      acc2[itemId] = newItemData;
      return acc2;
    }, {});

    acc[groupId] = {
      ...groupData,
      items,
    };

    return acc;
  }, {});
}

export function applyMultiTextboxFilter(filter: SurveyFiltering.UserFilter, data: SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.MultiTextbox]): SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.MultiTextbox] {
  return Object.keys(data).reduce<SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.MultiTextbox]>((acc, k) => {
    const userId: HybridSurveyResponseUserId = isNaN(+k) ? k : +k;
    if (filter.userIds.includes(userId)) {
      acc[userId] = data[userId];
    }
    return acc;
  }, {});
}

export function applyExclusiveOptionsFilter(filter: SurveyFiltering.UserFilter, data: SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.ExclusiveOptions]): SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.ExclusiveOptions] {
  return applyMultiselectFilter(filter, data);
}

function filterStackedBarChart(filter: SurveyFiltering.UserFilter, data: SurveyAggregate.StackedBarChart.Data): SurveyAggregate.StackedBarChart.Data {
  const indexValues = data.indexes.ids.reduce((acc, i) => {
    const val = data.indexes.values[i];

    const filteredKeys = Object.keys(val.keys).reduce<SurveyAggregate.StackedBarChart.IndexKeys>((acc, x) => {
      const k = x;

      const userIds = val.keys[k].userIds.filter(u => filter.userIds.includes(u));
      acc[k] = {
        ...val.keys[k],
        pct: (userIds.length / filter.userIds.length) * 100,
        userIds,
      };

      return acc;
    }, {});

    acc[i] = {
      ...val,
      keys: filteredKeys,
    };

    return acc;
  }, {});

  return {
    ...data,
    indexes: {
      ...data.indexes,
      values: indexValues,
    },
  };
}

export function applyRankingFilter(filter: SurveyFiltering.UserFilter, data: SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.Ranking]): SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.Ranking] {
  return filterStackedBarChart(filter, data);
}

export function applyMaxDiffFilter(filter: SurveyFiltering.UserFilter, data: SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.MaxDifference]): SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.MaxDifference] {
  const options = Object.keys(data.options).reduce((acc, x) => {
    const k = +x;
    const responses = data.options[k].responses;

    const filteredResponses = {
      ...responses,
      leftUserIds: responses.leftUserIds.filter(u => filter.userIds.includes(u)),
      rightUserIds: responses.rightUserIds.filter(u => filter.userIds.includes(u)),
      ncUserIds: responses.ncUserIds.filter(u => filter.userIds.includes(u)),
    };

    acc[k] = { ...data.options[k], responses: filteredResponses };

    return acc;
  }, {});

  return { options };
}

export function applyConjointFilter(filter: SurveyFiltering.UserFilter, data: SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.ConjointAnalysis]): SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.ConjointAnalysis] {
  const attributes = Object.keys(data.attributes).reduce((acc, x) => {
    const k = +x;

    const filteredResponses = { ...data.attributes[k].responses, userIds: data.attributes[k].responses.userIds.filter(u => filter.userIds.includes(u)) };

    acc[k] = { ...data.attributes[k], responses: filteredResponses };

    return acc;
  }, {});

  const levels = Object.keys(data.levels).reduce((acc, x) => {
    const k = +x;

    const filteredResponses = { ...data.levels[k].responses, userIds: data.levels[k].responses.userIds.filter(u => filter.userIds.includes(u)) };

    acc[k] = { ...data.levels[k], responses: filteredResponses };

    return acc;
  }, {});
  return { ...data, attributes, levels };
}

export function applyImageMarkupFilter(filter: SurveyFiltering.UserFilter, data: SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.ImageMarkup]): SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.ImageMarkup] {
  const userData = Object.keys(data.userData).reduce((acc, k) => {
    if (filter.userIds.includes(k)) {
      acc[k] = data.userData[k];
    }

    return acc;
  }, {} as SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.ImageMarkup]['userData']);

  return {
    ...data,
    userData,
  };
}

function getValidMultiselectUsers(filter: SurveyFiltering.QuestionFilter<SurveyQuestionType.Multiselect>, data: SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.Multiselect]): HybridSurveyResponseUserId[] {
  const selectedUsers = filter.optionIds.flatMap(o => data[o].userIds);
  if (filter.isSelected) {
    return selectedUsers;
  } else {
    return Object.values(data).flatMap(o => o.userIds).filter(u => !selectedUsers.includes(u));
  }
}

function getValidTextUsers(filter: SurveyFiltering.QuestionFilter<SurveyQuestionType.LongTextResponse>, data: SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.LongTextResponse]): HybridSurveyResponseUserId[] {
  const textFilters = filter.text.split(',').map(t => t.trim());
  const regex = new RegExp(textFilters.join('|'), 'i');
  return Object.entries(data).filter(([k, { value }]) => {
    return regex.test(value) == filter.shouldContain;
  })
    .map<HybridSurveyResponseUserId>(([k, v]) => isNaN(+k) ? k : +k);
}

function getValidSliderUsers(filter: SurveyFiltering.QuestionFilter<SurveyQuestionType.Sliders>, data: SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.Sliders]): HybridSurveyResponseUserId[] {
  const userIds = Object.entries(data.rows[filter.optionId].responses)
    .filter(([k, v]) => filter.greaterThan ? v.value > filter.value : v.value < filter.value)
    .map<HybridSurveyResponseUserId>(([k, v]) => isNaN(+k) ? k : +k);
  return userIds;
}