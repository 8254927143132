import { cx } from '@utils';
import { Check } from '@/components/icons';
import styles from './style/OnboardingChecklist.css';

type Props = {
  complete: boolean;
  onClick:  () => unknown;
  title:    string;
};

export const Item = (props: Props) => {
  const Component = props.complete
      ? Complete
      : Incomplete;

  return (
    <Component
      onClick={props.onClick}
      title={props.title} />
  );
};

Item.displayName = 'OnboardingChecklist.Item';

const Complete = (item: Omit<Props, 'complete'>) => (
  <div className={cx(styles.item, styles.complete)}>
    <Check
      className={styles.checked}
      checkProps={{
        className: styles.check,
      }} />
    <div className={styles.text}>{item.title}</div>
  </div>
);

Complete.displayName = 'OnboardingChecklist.Item.Complete';

const Incomplete = (item: Omit<Props, 'complete'>) => (
  <div className={cx(styles.item, styles.incomplete)}>
    <div className={styles.icon} />
    <div
      className={styles.text}
      onClick={item.onClick}>
      {item.title}
    </div>
  </div>
);

Incomplete.displayName = 'OnboardingChecklist.Item.Incomplete';