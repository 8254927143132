import { useCallback, ChangeEvent } from 'react';
import { Textarea } from '@/components/Textarea';

type Props = {
  onChange: (value: string) => void;
  value: string;
};

export const TemplateDrugIndicationInput = ({ onChange, value }: Props) => {

  const placeholder = `Please summarize the indication for the target drug.`;

  const handleChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
  }, [onChange]);

  return (
    <Textarea
      // className={styles.input}
      placeholder={placeholder}
      onChange={handleChange}
      value={value} />
  );
};