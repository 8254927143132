import { safeJsonDate } from '@utils';
import type { WorkspaceSearchFull } from '@/types';

export function normalizeSearchFullBrandResults(data: WorkspaceSearchFull.Brand.Results<string>): WorkspaceSearchFull.Brand.Results {
  if (!data) return null;

  return {
    ...data,
    items: data.items.map(normalizeSearchFullBrandResult),
  };
}

function normalizeSearchFullBrandResult(data: WorkspaceSearchFull.Brand.Result<string>): WorkspaceSearchFull.Brand.Result {
  return {
    ...data,
    createdOn: safeJsonDate(data.createdOn),
  };
}

export function normalizeSearchFullProjectResults(data: WorkspaceSearchFull.Project.Results<string>): WorkspaceSearchFull.Project.Results {
  if (!data) return null;

  return {
    ...data,
    items: data.items.map(normalizeSearchFullProjectResult),
  };
}

function normalizeSearchFullProjectResult(data: WorkspaceSearchFull.Project.Result<string>): WorkspaceSearchFull.Project.Result {
  return {
    ...data,
    createdOn: safeJsonDate(data.createdOn),
  };
}

export function normalizeSearchFullMemberResults(data: WorkspaceSearchFull.Member.Results): WorkspaceSearchFull.Member.Results {
  if (!data) return null;

  return {
    ...data,
    items: data.items.map(normalizeSearchFullMemberResult),
  };
}

function normalizeSearchFullMemberResult(data: WorkspaceSearchFull.Member.Result): WorkspaceSearchFull.Member.Result {
  return data;
}

export function normalizeSearchFullFileResults(data: WorkspaceSearchFull.File.Results<string>): WorkspaceSearchFull.File.Results {
  if (!data) return null;

  return {
    ...data,
    items: data.items.map(normalizeSearchFullFileResult),
  };
}

function normalizeSearchFullFileResult(data: WorkspaceSearchFull.File.Result<string>): WorkspaceSearchFull.File.Result {
  return {
    ...data,
    createdOn: safeJsonDate(data.createdOn),
  };
}

export function normalizeSearchFullFolderResults(data: WorkspaceSearchFull.Folder.Results<string>): WorkspaceSearchFull.Folder.Results {
  if (!data) return null;

  return {
    ...data,
    items: data.items.map(normalizeSearchFullFolderResult),
  };
}

function normalizeSearchFullFolderResult(data: WorkspaceSearchFull.Folder.Result<string>): WorkspaceSearchFull.Folder.Result {
  return {
    ...data,
    createdOn: safeJsonDate(data.createdOn),
  };
}

export function normalizeSearchFullTranscriptResults(data: WorkspaceSearchFull.Transcript.Results<string>): WorkspaceSearchFull.Transcript.Results {
  if (!data) return null;

  return {
    ...data,
    items: data.items.map(normalizeSearchFullTranscriptResult),
  };
}

function normalizeSearchFullTranscriptResult(data: WorkspaceSearchFull.Transcript.Result<string>): WorkspaceSearchFull.Transcript.Result {
  return {
    ...data,
    createdOn: safeJsonDate(data.createdOn),
  };
}