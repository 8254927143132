import { AxiosResponse } from 'axios';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import * as api from '@api';
import * as Transcripts from '@api/interfaces/transcripts';

type Props = {
  transcriptId: number;
};

export const useTranscriptionHighlights = (props: Props, options: Options = {}) => {

  const query = useQuery(['transcript-highlights', props], ({ queryKey }) => {
    const params = queryKey[1];
    return api.transcripts.getTranscriptHighlights(params)
      .then(res => res.highlights);
  }, options);

  return query;
};

type Data = Transcripts.GetTranscriptHighlights.Response['highlights'];
type QK = readonly [string, Props];

type Options = UseQueryOptions<Data, AxiosResponse, Data, QK>;