import { useState, useMemo, useContext, useCallback, useEffect } from 'react';
import { PlusCircle, MinusCircle } from 'react-feather';
import type { SortingState, ColumnDef, CellContext, HeaderContext } from '@tanstack/react-table';
import { useReactTable, getCoreRowModel, getSortedRowModel, getPaginationRowModel, getFilteredRowModel } from '@tanstack/react-table';
import { SortableTable } from '@/presentation/Tables';
import { ReactTablev8Pagination } from '@/presentation/TablePagination';
import { capitalize } from '@/utils';
import Input from '@/components/Input';
import type { EntityListItem } from './interfaces';
import { EntityListOnboardingContext } from './Context';
import styles from './style/Onboarding.Lists.css';

type Props = {
  items: EntityListItem[];
};

export const EntitiesTable = ({ items }: Props) => {
  const columns = useMemo<ColumnDef<EntityListItem>[]>(() => {
    return [
      {
        id: 'name',
        header: NameHeader,
        accessorFn: x => x.name,
        cell: x => x.row.original.name,
        filterFn: 'includesString',
      }, {
        id: 'type',
        accessorFn: x => x.type,
        header: 'Type',
        cell: x => capitalize(x.row.original.type),
      }, {
        id: 'added-via',
        header: 'List',
        cell: x => x.row.original.lists.map(l => l.name).join(', '),
      }, {
        id: 'actions',
        cell: Actions,
      },
    ];
  }, []);

  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    data: items,
    state: {
      sorting,
    },
    initialState: {
      pagination: {
        pageSize: 50,
      },
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    columns,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <>
      <SortableTable table={table} classes={{ cell: styles.cell }} />
      <ReactTablev8Pagination tableInstance={table} totalCount={table.getFilteredRowModel().flatRows.length} />
    </>
  );
};

const NameHeader = (props: HeaderContext<EntityListItem, unknown>) => {
  return (
    <div className={styles.header}>
      <div>Name</div>
      <Input
        placeholder='Filter'
        value={props.column.getFilterValue() as string}
        onChange={e => props.column.setFilterValue(e.target.value)}
        onClick={e => e.stopPropagation()} />
    </div>
  );
};

const Actions = (props: CellContext<EntityListItem, unknown>) => {
  const { ignoredEntities, setIgnoredEntities } = useContext(EntityListOnboardingContext);

  const setIgnored = useCallback((ignored: boolean) => {
    if (ignored) {
      setIgnoredEntities(old => [...old, props.row.original.id]);
    } else {
      setIgnoredEntities(old => old.filter(x => x !== props.row.original.id));
    }
  }, [setIgnoredEntities, props.row.original.id]);

  if (ignoredEntities.includes(props.row.original.id)) {
    return (
      <PlusCircle className={styles.addRow} onClick={() => setIgnored(false)} />
    );
  } else {
    return (
      <MinusCircle className={styles.removeRow} onClick={() => setIgnored(true)} />
    );
  }
};