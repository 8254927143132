import { TableHeaderProps } from 'react-table';
import { cx } from '@utils';
import styles from './style/Table.css';

type Props = {
  children?:  React.ReactNode;
  className?: string;
} & TableHeaderProps;

export const Header = ({ className, children, ...props }: Props) => {
  return (
    <div
      {...props}
      className={cx(styles.header, className)}>
      {children}
    </div>
  );
};