import { memo } from 'react';
import { MinusCircle as MinusCircleIcon } from 'react-feather';

type Props = {
  className?: string;
  size?: number;
  onClick?: () => void;
};

export const MinusCircle = memo(({ className, size = 24, ...props }: Props) => {
  return (
    <MinusCircleIcon
      className={className}
      size={size}
      {...props} />
  );
});