import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import { updateTag } from '@api/projects.conference';
import { ConferenceTags } from '@api/interfaces/projects';
import { indexBy } from '@utils/array';
import { TagStateContext } from '@/components/Project.Tags/Context';

type HookParams = Pick<ConferenceTags.UpdateTag.Request, 'projectId' | 'projectTagId'>;

type MutationParams = Omit<ConferenceTags.UpdateTag.Request, 'projectId' | 'projectTagId'>;
export const useUpdateTagMutation = ({ projectId, projectTagId }: HookParams) => {
  const { dispatch, state } = useContext(TagStateContext);
  return useMutation(['update-project-tag', projectTagId], (data: MutationParams) => {
    return updateTag({
      projectId,
      projectTagId,
      ...data,
    });
  }, {
    onSuccess: (result, params) => {
      //if parent changed, remove and readd
      //else just do an inplace update
      if (result.rebalancedOrdinals) {
        const existingTag = state.tags.flatMap(t => t.children).concat(state.tags).find(t => t.id === projectTagId);
        dispatch({
          type: 'remove-tag',
          projectTagId,
        });
        dispatch({
          type: 'add-tag',
          tag: {
            ...existingTag,
            parent: {
              id: params.parentId,
            },
          },
        });
        dispatch({
          type: 'update-ordinals',
          ordinals: indexBy(result.rebalancedOrdinals, k => k.id, v => v.ordinal),
        });
      } else {

        dispatch({
          type: 'update-tag',
          projectTagId,
          partialTag: { includeSummary: params.includeSummary },
          baseTag: { name: params.title, color: params.color },
        });
      }
    },
  });
};