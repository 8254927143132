import { ShortTextResponseQuestion } from '@/types/survey';
import { SurveySettings } from './Settings';

type Props = {
  item: ShortTextResponseQuestion.Question;
};

export const ShortTextResponsePreview = ({ item }: Props) => {
  return (
    <SurveySettings>
      {item.settings.rationale.enabled && <>Ask for rationale</>}
    </SurveySettings>
  );
};