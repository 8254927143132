import { useCallback } from 'react';
import { MinusCircle } from 'react-feather';
import { cx } from '@utils';
import { TextareaAutosize } from '@/components/Textarea';
import type { TextEntryListItemProps, RenderTextEntryListInputProps, TextEntryItem } from './interfaces';
import styles from './style.css';

export const TextEntryListInput = <T extends TextEntryItem = TextEntryItem>(inputProps: RenderTextEntryListInputProps<T>) => {
  return (
    <div className={styles.input}>
      <TextareaAutosize
        disabled={inputProps.disabled}
        className={inputProps.className}
        onChange={inputProps.onChange}
        value={inputProps.item.value}
        placeholder={inputProps.placeholder} />
      {inputProps.children &&
        <div className={styles.overlay}>
          {inputProps.children}
        </div>
      }
    </div>
  );
};

export const TextEntryListItem = <T extends TextEntryItem = TextEntryItem>({ children, onUpdate, ...props }: TextEntryListItemProps<T>) => {
  const handleUpdate = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onUpdate(e.target.value);
  }, [onUpdate]);

  const inputProps: RenderTextEntryListInputProps<T> = {
    className: cx(styles.textarea, props.inputClassName),
    children,
    disabled: props.disabled,
    placeholder: props.placeholder,
    item: props.item,
    onChange: handleUpdate,
  };

  return (
    <div className={styles.root}>
      {props.renderLabel && props.renderLabel(props.item)}
      {props.renderInput
        ? props.renderInput(inputProps)
        : <TextEntryListInput {...inputProps} />
      }
      <div className={styles.action}>
        {props.removeEnabled &&
          <MinusCircle
            color="var(--pri-04)"
            className={styles.remove}
            onClick={props.onRemove} />
        }
      </div>
    </div>
  );
};

export default TextEntryListItem;