import { memo } from 'react';
import { Link } from 'react-router-dom';
import { Brand as BrandIcon } from '@/components/icons';
import { cx, getLocationFor } from '@/utils';
import type { IWorkspaceBrandItem } from '@/types/workspace.table';
import styles from './style/Files.Table.Grid.css';

type Props = {
  item: IWorkspaceBrandItem;
};

export const Brand = ({ item }: Props) => {
  const to = getLocationFor.workspaces.brand({
    brandId: item.brand.id,
  });

  return (
    <Link
      className={cx(styles.link, styles.file)}
      to={to}
      draggable={false}>
      <div className={styles.thumb}>
        <GenericBrandThumb />
      </div>
      <div className={styles.info}>
        <div className={styles.icon}>
          <BrandIcon
            className={styles.icon} />
        </div>
        <div className={styles.details}>
          <div className={styles.name}>{item.object.name}</div>
        </div>
      </div>
    </Link>
  );
};

const GenericBrandThumb = memo(() => {
  return (
    <div className={styles.genericThumb}>
      <BrandIcon className={styles.icon} />
    </div>
  );
});