import http from '@services/http';
import { transformConferenceTagSummaryItem, transformConferenceTagSummaryFeedback, transformTagSummary } from '@transformers';
import { qs } from '@utils';
import { Transcripts } from './interfaces';

export const downloadSummary = (data: Transcripts.DownloadSummary.Request) => {
  const params = {
    format: data.format || `pptx`,
    tagIds: data.tagIds,
  };

  return http.get<Blob>(`/transcripts/${data.transcriptId}/summary/export?${qs.stringify(params, { arrayFormat: 'bracket' })}`, { responseType: 'blob' })
    .then<Transcripts.DownloadSummary.Response>(res =>
    ({
      file: res,
      name: `Call Transcript Summary.${params.format}`,
    }));
};

export const fetchSummary = (data: Transcripts.FetchSummary.Request): Promise<Transcripts.FetchSummary.Response> => {
  return http.get<Transcripts.FetchSummary.Response<string>>(`/transcripts/${data.transcriptId}/summary`)
    .then(res => {
      return {
        items: res.items.map(transformConferenceTagSummaryItem),
      };
    });
};

export const getTagSummaryFeedbackSummary = ({ transcriptId, tagSummaryId }: Transcripts.GetTagSummaryFeedbackSummary.Request): Promise<Transcripts.GetTagSummaryFeedbackSummary.Response> => {
  return http.get<Transcripts.GetTagSummaryFeedbackSummary.Response<string>>(`/transcripts/${transcriptId}/summary/${tagSummaryId}/feedback-summary`)
    .then(res => {
      return {
        ...res,
        items: res.items.map(transformConferenceTagSummaryFeedback),
      };
    });
};

export const getTagSummaryFeedback = ({ transcriptId, tagSummaryId }: Transcripts.GetTagSummaryFeedbackSummary.Request) => {
  return http.get<Transcripts.GetTagSummaryFeedback.Response>(`/transcripts/${transcriptId}/summary/${tagSummaryId}/feedback`);
};

export const updateTagSummaryRating = ({ transcriptId, tagSummaryId, ...data }: Transcripts.UpdateTagSummaryRating.Request): Promise<Transcripts.UpdateTagSummaryRating.Response> => {
  return http.patch(`/transcripts/${transcriptId}/summary/${tagSummaryId}/rating`, data);
};

export const updateTagSummaryNotes = ({ transcriptId, tagSummaryId, ...data }: Transcripts.UpdateTagSummaryNotes.Request): Promise<Transcripts.UpdateTagSummaryNotes.Response> => {
  return http.patch(`/transcripts/${transcriptId}/summary/${tagSummaryId}/notes`, data);
};

export const updateTagSummaryFeedback = ({ transcriptId, tagSummaryId, ...data }: Transcripts.UpdateTagSummaryFeedback.Request): Promise<Transcripts.UpdateTagSummaryFeedback.Response> => {
  return http.patch(`/transcripts/${transcriptId}/summary/${tagSummaryId}/feedback`, data);
};

export const getTagSummaryNotes = ({ transcriptId, tagSummaryId }: Transcripts.GetTagSummaryNotes.Request): Promise<Transcripts.GetTagSummaryNotes.Response> => {
  return http.get(`/transcripts/${transcriptId}/summary/${tagSummaryId}/notes`);
};

export const generateTagSummaries = ({ transcriptId, tagIds }: Transcripts.GenerateTagSummaries.Request): Promise<Transcripts.GenerateTagSummaries.Response> => {
  return http.post(`/transcripts/${transcriptId}/summary/generate`, {
    tagIds,
  });
};

export const getTagSummaryCitations = ({ transcriptId, tagSummaryId }: Transcripts.GetTagSummaryCitations.Request): Promise<Transcripts.GetTagSummaryCitations.Response> => {
  return http.get(`/transcripts/${transcriptId}/summary/${tagSummaryId}/citations`);
};

export const getTagSummaryVersions = ({ transcriptId, tagSummaryId }: Transcripts.GetTagSummaryVersions.Request): Promise<Transcripts.GetTagSummaryVersions.Response> => {
  return http.get<Transcripts.GetTagSummaryVersions.Response<string>>(`/transcripts/${transcriptId}/summary/${tagSummaryId}/versions`)
    .then(res => {
      return {
        ...res,
        items: res.items.map(transformTagSummary),
      };
    });
};