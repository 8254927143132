import qs from 'query-string';
import * as enums from '@enums';
import http from '@services/http';
import * as xform from '@transformers';
import { safeJsonDate, slugify } from '@utils';
import type { Members, SaveAttributionInfo, Users } from './interfaces';

export const fetchAcquisitionData = () => {
  return http.get<Members.FetchAcquisitionData.Response>(`/admin/members/acquisition`);
};

export const fetchAggregate = (data: Members.FetchAggregate.Request) => {
  return http.get(`/admin/members/dashboard?${qs.stringify(data, { arrayFormat: 'bracket' })}`)
  .then((c: Members.FetchAggregate.Reponse) => ({
    items: c.items.map(xform.setUserSlugGeneric),
  }));
};

export const fetchBilling = (data: Members.FetchBilling.Request) => {
  const { userId, ...params } = data;
  const q = qs.stringify(params);

  return http.get<Members.FetchBilling.Response<string>>(`/admin/members/${userId}/billing?${q}`)
  .then(res => ({
    items: res.items.map(x => ({
      ...x,
      created: {
        on: new Date(x.created.on),
      },
    })),
    pagination: res.pagination,
    aggregations: res.aggregations,
  }));
};

export const fetchMember = async (data: Members.FetchMember.Request) => {
  return http.get(`/admin/members/${data.userId}`)
  .then((data: Members.FetchMember.Response) => {
    const { referral, ...rest } = data.item;
    return {
      ...rest,
      createdOn: safeJsonDate(data.item.createdOn),
      identities: data.item.identities.map(m => ({
        ...m,
        createdOn: safeJsonDate(m.createdOn),
        lastLogin: safeJsonDate(m.lastLogin),
      })),
      link: xform.assignLinkUrl({ token: { value: referral, typeId: enums.TokenType.Referral } }),
      profile: {
        ...data.item.profile,
        slug: slugify({
          id: data.item.id,
          name: data.item.profile.fullname,
        }),
      },
      settings: data.item.settings,
      vics: data.item.vics,
    };
  });
};

export const fetchMembers = (data: Members.FetchMembers.Request): Promise<Members.FetchMembers.Response> => {
  const q = qs.stringify(data);

  return http.get<Members.FetchMembers.Response<string>>(`/admin/members?${q}`)
  .then(result => ({
    ...result,
    items: result.items.map(item => ({
      ...item,
      user: {
        ...item.user,
        createdOn: safeJsonDate(item.user.createdOn),
      },
    })),
  }));
};

export const fetchProjects = (data: Members.FetchMemberProjects.Request) => {
  const { userId, ...params } = data;
  const q = qs.stringify(params);

  return http.get<Members.FetchMemberProjects.Response>(`/admin/members/${userId}/projects?${q}`);
};

export const getProfile = (data: Users.GetUserProfile.Request): Promise<Users.GetUserProfile.Response> => {
  return http.get<Users.GetUserProfile.Response>(`admin/users/${data.userId}/profile`)
  .then(xform.setUserSlugGeneric);
};

export const saveAttributionInfo: SaveAttributionInfo = data => {
  return http.post(`/admin/users/attribution`, data);
};

export const claimUserAttribution = (data: Members.ClaimUserAttribution.Request) => {
  return http.post<Members.ClaimUserAttribution.Response>(`/admin/users/${data.userId}/claim`, { });
};

export const setVerificationStatus = (data: Members.SetVerificationStatus.Request): Promise<Members.SetVerificationStatus.Response> => {
  const { userId, ...rest } = data;
  return http.patch(`/admin/members/${userId}/verification`, rest);
};

export const updateLinkedinInfo = (data: Members.UpdateLinkedinInfo.Request): Promise<Members.UpdateLinkedinInfo.Response> => {
  const { userId, ...rest } = data;

  return http.post(`/admin/members/${userId}/linkedin`, rest);
};

export const fetchEmails = (data: Members.FetchEmails.Request) => {
  const { userId, ...params } = data;
  const q = qs.stringify(params);

  return http.get<Members.FetchEmails.Response<string>>(`/admin/members/${userId}/emails?${q}`)
  .then(res => ({
    items: res.items.map(x => ({
      ...x,
      sentOn: safeJsonDate(x.sentOn),
    })),
    pagination: res.pagination,
    aggregations: res.aggregations,
  }));
};

export * as import from './members.import';
export * as medical from './members.medical';
export * as search from './members.search';