import type { ChangeEvent } from 'react';
import { useCallback, useContext, useMemo, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { ConnectedAccountType } from '@/enums';
import { RowsPerPage } from '@/components/TablePagination';
import { useCanUpload, useGoogleDriveUpload, useZoomRecordingUpload } from '@/containers/WorkspaceFileUpload/hooks';
import { CanUploadFilesContext, UploadFilesContext } from '@/containers/WorkspaceFileUpload/Context';
import { Card, CardContent, Divider, GroupCardHeader } from '@/presentation/Main';
import { FileLayoutSelector } from '@/presentation/FileLayoutSelector';
import { TablePagination } from '@/presentation/TablePagination';
import { cx, useDevice } from '@/utils';
import type { IFilesTable } from './interfaces';
import { FilesTableEmptyState, FilesTableEmptyStateUpload } from './Files.Table.EmptyState';
import { FilesTableGrid } from './Files.Table.Grid';
import { FilesTableList } from './Files.Table.List';
import { Menu } from './Files.Upload.Menu';
import { FilesActionsButton } from './Files.Table.Actions';
import { useFileLayoutSelector } from './hooks/useFileLayoutSelector';
import * as TableState from './Files.Table.state';
import { FilesTableDataContext, FilesTableLoadingContext } from './context';
import styles from './style/Files.Table.css';

type Props = {
  classes?: {
    root?: string;
  };
  renderHeaderTitle?: () => JSX.Element;
};

export const FilesTable = ({ classes = {}, renderHeaderTitle }: Props) => {
  const canUpload = useCanUpload();
  const onUpload = useContext(UploadFilesContext);
  const isLoading = useContext(FilesTableLoadingContext);
  const data = useContext(FilesTableDataContext);

  const handleUploadClick = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    return onUpload(Array.from(e.target.files));
  }, [onUpload]);

  const device = useDevice();

  const { openPicker, loading } = useGoogleDriveUpload({
    onSelect: onUpload,
  });

  const [openZoomPicker, ZoomPickerModal] = useZoomRecordingUpload();

  const hasConnectedFileAccounts = useSelector((state: Store.State) => !!state.user.connected.filter(a => a.typeId == ConnectedAccountType.Files).length);

  const [layout, setLayout] = useFileLayoutSelector();

  const [tableState, dispatchTable] = useReducer(TableState.reducer<IFilesTable.Item>, TableState.getInitialState<IFilesTable.Item>());

  useEffect(() => {
    dispatchTable({ type: 'load', items: data });
  }, [data]);

  const updatePageSize = useCallback((pageSize: number) => dispatchTable({ type: 'update-page-size', pageSize }), []);
  const nextPage = useCallback(() => dispatchTable({ type: 'next-page' }), []);
  const previousPage = useCallback(() => dispatchTable({ type: 'previous-page' }), []);
  const pageItems = useMemo(() => TableState.getPagedItems(tableState.items, tableState.pageIndex, tableState.pageSize), [tableState.items, tableState.pageIndex, tableState.pageSize]);

  const renderUpload = useCallback(() => {

    if (hasConnectedFileAccounts) {
      return (
        <div className={styles.buttons}>
          <FileLayoutSelector
            classname={styles.selector}
            selected={layout}
            onSelect={setLayout} />
          <Menu
            onDriveSelect={openPicker}
            onZoomSelect={openZoomPicker}
            onMyComputerSelect={handleUploadClick} />
          <ZoomPickerModal onSelect={onUpload} />
        </div>
      );
    } else {
      return (
        <div className={styles.buttons}>
          <FileLayoutSelector
            classname={styles.selector}
            selected={layout}
            onSelect={setLayout} />
          <FilesActionsButton />
        </div>
      );
    }

  }, [
    handleUploadClick,
    openPicker,
    hasConnectedFileAccounts,
    ZoomPickerModal,
    openZoomPicker,
    onUpload,
    layout,
    setLayout,
  ]);

  const Header = useCallback(() => {
    return (
      <GroupCardHeader className={styles.header}>
        <div className={styles.left}>
          {renderHeaderTitle
            ? renderHeaderTitle()
            : <div>Files</div>
          }
        </div>
        {renderUpload()}
      </GroupCardHeader>
    );
  }, [
    renderHeaderTitle,
    renderUpload,
  ]);

  const updateSortBy = useCallback((sortBy: IFilesTable.SortingRule) => {
    dispatchTable({ type: 'update-sort-by', sortBy: [sortBy] });
  }, []);

  const footerClasses = useMemo(() => {
    return {
      root: styles.footer,
      pageSize: styles.rows,
      pagination: styles.pagination,
    };
  }, []);

  const renderTableBody = useCallback(() => {
    if (layout === 'list') {
      return (
        <FilesTableList
          classes={classes}
          items={pageItems}
          canUpload={canUpload}
          onUpload={onUpload}
          pagination={{
            pageIndex: tableState.pageIndex,
            pageSize: tableState.pageSize,
            sortBy: tableState.sortBy,
            updateSortBy,
          }} />
      );
    } else if (layout === 'grid') {
      return (
        <FilesTableGrid
          classes={classes}
          items={pageItems}
          canUpload={canUpload}
          onUpload={onUpload} />
      );
    }

    return null;
  }, [
    classes,
    pageItems,
    canUpload,
    onUpload,
    layout,
    tableState,
    updateSortBy,
  ]);

  const renderContent = useCallback(() => {
    if (isLoading) return null;

    if (!data.length) {
      if (canUpload) {
        return <FilesTableEmptyStateUpload />;
      } else {
        return <FilesTableEmptyState />;
      }
    }

    return (
      <>
        {renderTableBody()}
        {!!data.length && (
          <FilesTableFooter
            classes={footerClasses}
            pageSize={tableState.pageSize}
            pageIndex={tableState.pageIndex}
            totalCount={tableState.totalCount}
            canNextPage={tableState.canNextPage}
            canPreviousPage={tableState.canPreviousPage}
            updatePageSize={updatePageSize}
            nextPage={nextPage}
            previousPage={previousPage} />
        )}
      </>
    );
  }, [
    canUpload,
    footerClasses,
    isLoading,
    data.length,
    renderTableBody,
    tableState,
    nextPage,
    previousPage,
    updatePageSize,
  ]);

  return (
    <Card className={styles.card}>
      <Header />
      {!data.length && <Divider />}
      <CardContent
        className={styles.content}
        fluid>
        {renderContent()}
      </CardContent>
    </Card>
  );
};

type FilesTableFooterProps = {
  classes?: {
    root?: string;
    pageSize?: string;
    pagination?: string;
  };
  pageSize: number;
  pageIndex: number;
  totalCount: number;
  canPreviousPage: boolean;
  canNextPage: boolean;
  updatePageSize: (size: number) => unknown;
  nextPage: () => unknown;
  previousPage: () => unknown;
};

const FilesTableFooter = ({
  pageSize, pageIndex, totalCount, updatePageSize,
  nextPage, previousPage, canNextPage, canPreviousPage,
  classes = {},
}: FilesTableFooterProps) => {
  return (
    <div className={cx(styles.paginationRoot, classes.root)}>
      <RowsPerPage
        className={cx(styles.pageSize, classes.pageSize)}
        value={pageSize}
        onSelect={updatePageSize}
        options={[25, 50, 100]} />
      <TablePagination
        className={cx(styles.pagination, classes.pagination)}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageIndex={pageIndex}
        nextPage={nextPage}
        pageSize={pageSize}
        previousPage={previousPage}
        totalCount={totalCount} />
    </div>
  );
};

export default FilesTable;