import FormatSizeIcon from '@mui/icons-material/FormatSize';
import { cx } from '@utils';
import styles from './style/RichTextToggleButton.css';

type RichTextToggleButtonProps = {
  enabled: boolean;
  onChange: (enabled: boolean) => void;
};

export const RichTextToggleButton = ({ enabled, onChange }: RichTextToggleButtonProps) => {
  return (
    <div
      className={cx(styles.rtToggle, enabled ? styles.rtEnabled : null)}
      onClick={() => onChange((!enabled))}>
      <FormatSizeIcon fontSize='small' />
    </div>
  );
};