import { useCallback } from 'react';
import { TableInstance } from 'react-table';
import { cx } from '@utils';
import { ReactTablePagination } from '@presentation/TablePagination';
import { RowsPerPage } from '@/components/TablePagination';
import styles from './style/Footer.css';

type Props = {
  classes?: FooterClasses;
  instance: TableInstance;
  pageSizes?: number[];
};

type FooterClasses = {
  root?: string;
  pagination?: string;
  pageSize?: string;
};

export const GroupTableFooter = ({ classes, instance, pageSizes }: Props) => {

  const updatePageSize = useCallback((pageSize: number) => {
    instance.gotoPage(0);
    instance.setPageSize(pageSize);
  }, [
    instance.gotoPage,
    instance.setPageSize,
  ]);

  return (
    <div className={cx(styles.root, classes?.root)}>
      <RowsPerPage
        className={cx(styles.pageSize, classes?.pageSize)}
        value={instance.state.pageSize}
        onSelect={updatePageSize}
        options={pageSizes || [25, 50, 100]} />
      <ReactTablePagination
        className={cx(styles.pagination, classes?.pagination)}
        tableInstance={instance} />
    </div>
  );
};

export default GroupTableFooter;