import { ChevronDown, ChevronUp } from 'react-feather';
import * as Table from '@/components/Table';
import styles from './style/Draggable.css';

type Props = {
  hidden?:  boolean;
  open?:    boolean;
  onClick?: () => void;
};

export const Cell = (props: Props) => {
  if (props.hidden) {
    return (
      <Table.Body.TD className={styles.accordion} />
    );
  }

  if (props.open) {
    return (
      <Table.Body.TD className={styles.accordion}>
        <div className={styles.cell}>
          <ChevronUp
            className={styles.chevron}
            onClick={props.onClick} />
        </div>
      </Table.Body.TD>
    );
  }

  return (
    <Table.Body.TD className={styles.accordion}>
      <div className={styles.cell}>
        <ChevronDown
          className={styles.chevron}
          onClick={props.onClick} />
      </div>
    </Table.Body.TD>
  );
};

Cell.displayName = 'Column.AccordionToggle.Cell';

export const Header = (props: unknown) => {
  return (
    <Table.Header.TH className={styles.accordion} />
  );
};

Header.displayName = 'Column.AccordionToggle.Header';