import { useContext } from 'react';
import { Calendars } from './CallScheduling.Calendars';
import { Confirm } from './CallScheduling.Confirm';
import { Frame } from './CallScheduling.Frame';
import { Params } from './CallScheduling.Params';
import { Participants } from './CallScheduling.Participants';
import { RespondentSetup } from './CallScheduling.RespondentSetup';
import { Settings } from './CallScheduling.Settings';
import { StateResolver } from './CallScheduling.StateResolver';
import { ParamsContext } from './Context';
import * as Modal from './Modal.CallScheduling';
import type { OnSuccess } from './interfaces';

type Props = {
  children:   React.ReactNode;
  onSuccess?: OnSuccess;
};

const Container = (props: Props) => {
  const ctx = {
    scheduling: useContext(ParamsContext),
  };

  return (
    <>
      {props.children}
      {ctx.scheduling.params &&
        <Frame>
          <StateResolver>
            <Participants>
              <Calendars>
                <Settings>
                  <Confirm onSuccess={props.onSuccess}>
                    <Modal.CallScheduling />
                  </Confirm>
                </Settings>
              </Calendars>
            </Participants>
          </StateResolver>
        </Frame>}
    </>
  );
};

const WithState = (props: Props) => {

  return (
    <Params>
      <RespondentSetup>
        <Container {...props} />
      </RespondentSetup>
    </Params>
  );
};

WithState.displayName = 'Call.Scheduling.Container';
export { WithState as Container };