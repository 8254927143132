import { useCallback, useContext } from 'react';
import { PostDeleteContext } from '@containers/Post/Context';
import { useLock, useMounted } from '@utils';
import { Alert } from '@/components/Modal/Alert';
import { ModalProps } from '@/components/Modal/Modal';

type Props =
  Pick<ModalProps, 'open' | 'onClose'>;

export const DeletePostPrompt = ({ onClose, open }: Props) => {
  const isMounted = useMounted();
  const onConfirm = useContext(PostDeleteContext);
  const [busy, lock] = useLock();

  const handleSave = useCallback(() => {
    return onConfirm()
      .then(_ => {
        if (isMounted()) {
          onClose();
        }
      });
  }, [
    isMounted,
    onClose,
    onConfirm,
  ]);

  return (
    <Alert
      confirmText="Remove"
      message="Are you sure you want to delete this post?"
      onClose={onClose}
      onConfirm={lock(handleSave)}
      open={open} />
  );
};