import { LogicBuilderConditions } from './Builder.Conditions';
import { LogicBuilderConditionsRule } from './Builder.Rule';
import styles from './style/Builder.css';

type Props = {
  className?: string;
};

export const LogicBuilderConditionsMatch = ({ className }: Props) => {

  return (
    <div className={className}>
      <LogicBuilderConditionsRule className={styles.rule} />
      <LogicBuilderConditions />
    </div>
  );
};