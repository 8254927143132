import type { ChangeEvent } from 'react';
import { useState, useRef, useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Upload } from 'react-feather';
import type { UseModalProps } from '@/components/Modal';
import { useModal, Modal, Header } from '@/components/Modal';
import { useSubmitSurveyDraft, useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { Select } from '@/components/Select';
import { parseSurveyTranslationFile } from '@api/surveys';
import { Button, Buttons } from '@/components/Button';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import Toast from '@/components/Toast';
import { Accordion } from '@/components/Accordion';
import styles from './style/ImportLanguageModal.css';

export const ImportLanguageModal = (props: UseModalProps) => {
  const [state, dispatch] = useSurveyBuilderState();
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedFile, setFile] = useState<File>(null);
  const [selectedLanguage, setLanguage] = useState(state.survey.language.languages.find(l => !l.isDefault).code);
  const submitSurveyDraft = useSubmitSurveyDraft();

  const parseFileMutation = useMutation({
    mutationFn: (file: Blob) => parseSurveyTranslationFile(file),
    onMutate: () => {
      setErrorMessage('');
    },
    onSuccess: resp => {

      if (resp.success == true) {
        dispatch({
          type: 'language-keys-imported',
          payload: {
            code: selectedLanguage,
            values: resp.texts,
          },
        });
        submitSurveyDraft();
        props.onClose();
      } else {
        setErrorMessage(resp.message);
      }
    },
    onError: err => {
      Toast.error({
        title: err['data'] as string ?? 'There was an error uploading your file.',
      });
    },
  });

  const onFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files.item(0));
  }, []);

  return (
    <Modal {...props}>
      <Header>Import Multilingual Survey Version</Header>
      <div className={styles.content}>
        <div>
          <div className={styles.header}>Language Variable</div>
          <Select
            onSelect={l => setLanguage(l.code)}
            getItemValue={l => l.code}
            options={state.survey.language.languages.filter(l => !l.isDefault)}
            value={selectedLanguage} />
        </div>
        <div>
          <label className={styles.inputLabel}>
            <Upload size={18} strokeWidth={3} /> Select Import File
            <input
              onChange={onFileChange}
              className={styles.fileInput}
              accept='.xlsx, .xls'
              type='file' />
          </label>
          {selectedFile && <div>{selectedFile.name}</div>}
        </div>
        <div className={styles.hint}>Import supports XLSX file format with mapped IDs from survey</div>
        {errorMessage &&
          <>
            <Accordion label={() => <div className={styles.errorLabel}>Error Parsing File</div>} grows={false}>
              <div className={styles.errorContent}>{errorMessage}</div>
            </Accordion>
          </>
        }
      </div>
      <Buttons>
        <Button
          variant='brick'
          color='destructive'
          onClick={props.onClose}>Cancel
        </Button>
        <ButtonActivityIndicator
          implicitDisable={false}
          loading={parseFileMutation.isLoading}
          disabled={!selectedFile}
          onClick={() => parseFileMutation.mutate(selectedFile)}
          variant='brick'
          color='affirmative'>
          Import
        </ButtonActivityIndicator>
      </Buttons>
    </Modal>
  );
};

export const useImportLanguageModal = () => useModal(ImportLanguageModal);