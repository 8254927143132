import { useContext } from 'react';
import type { AxiosResponse } from 'axios';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import type { Transcripts } from '@api/interfaces';
import { TranscriptHighlightActionsContext } from '@containers/Transcript/context.highlights';

type Data = Transcripts.SaveTranscriptHighlight.Response['highlight'];
type Variables<T extends Record<string, unknown>> = {
  highlight: Transcripts.SaveTranscriptHighlight.Request['highlight'];
} & T;
type Options<T extends Record<string, unknown>> = UseMutationOptions<Data, AxiosResponse, Variables<T>>;

export function useSaveHighlight<T extends Record<string, unknown> = Record<string, unknown>>(options?: Options<T>) {

  const { saveHighlight } = useContext(TranscriptHighlightActionsContext);

  return useMutation(['save-transcript-highlight'], ({ highlight }) => {

    return saveHighlight({ highlight });

  }, options);
}

export type { Options as SaveHighlightOptions };