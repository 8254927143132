import { Input } from '@/components/Input';
import styles from '$admin/shared/style/InlineTextField.css';
import { Label, TextLabel } from './Label';

type Props = {
  defaultValue?: string;
  editing: boolean;
  fallback?: React.ReactNode;
  label: string;
  name: HTMLInputElement['name'];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type: HTMLInputElement['type'];
  value: string;
};

const InlineTextField = ({ fallback = null, ...props }: Props) => {

  function DisplayField({ label, value, ...rest }: Pick<Props, 'fallback' | 'label' | 'value'>) {
    return (
      <TextLabel
        className={styles.section}
        fallback={rest.fallback}
        title={label}
        value={value} />
    );
  }

  return (
    <>
      {!props.editing &&
        <DisplayField
          fallback={fallback}
          label={props.label}
          value={props.value} />}

      {props.editing &&
        <Label
          className={styles.section}
          title={props.label}>
          <div className={styles.input}>
            <Input
              autoComplete="off"
              defaultValue={props.defaultValue}
              name={props.name}
              onChange={props.onChange}
              placeholder={props.placeholder}
              value={props.value ?? ''}
              type={props.type} />
          </div>
        </Label>}
    </>
  );
};

type InlineTextFieldLabelProps = {
  children: React.ReactElement;
} & Pick<Props, 'label'>;

function InlineTextFieldLabel(props: InlineTextFieldLabelProps) {
  return (
    <Label
      className={styles.section}
      title={props.label}>
      {props.children}
    </Label>
  );
}

type InlineTexFieldValueProps = {
  children: React.ReactNode;
  fallback?: React.ReactNode;
};

function InlineTextFieldValue({ fallback = null, ...props }: InlineTexFieldValueProps) {
  return (
    <div className={styles.field}>
      {props.children || fallback}
    </div>
  );
}

const defaultProps = {
  editing: false,
  value: '',
  type: 'text',
};

InlineTextField.defaultProps = defaultProps;
InlineTextField.Label = InlineTextFieldLabel;
InlineTextField.Value = InlineTextFieldValue;

export { InlineTextField };
export default InlineTextField;