import { path, pathname } from '@consts';
import { RouteAuth } from '@enums';
import { StateRouter } from '@routes/Routers';
import type { Routing } from '@containers/Routing';
import { Search } from '@screens/UniversalSearch';
import * as utils from '@utils';

const routes: Routing.Route[] = [
  {
    component: Search,
    path: path.UniversalSearch.Root,
  },
];

const validator = (state: Store.State) => {
  return utils.hasConsultantRole(state.user)
    ? routes
    : [];
};

export const router = StateRouter(validator, {
  auth: RouteAuth.Authenticated,
  path: pathname.UniversalSearch,
});