import { useCallback, useState } from 'react';
import { ChevronDown } from 'react-feather';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { cx } from '@utils';
import { SelectInputValue, SelectInputIcon } from './SelectInput';
import SelectList from './SelectList';
import type { SelectBaseProps } from './interfaces';
import styles from './badge.css';
import SelectBase from './SelectBase';

type Props<T> = {
  className?: string;
  inputValueClassName?: string;
  optionsClassName?: string;
} & SelectBaseProps<T>;

const SelectBadge = <T, >({ onSelect, onChange, getItemValue, ...props }: Props<T>) => {
  const [open, setVisibility] = useState(false);
  const [selected, setSelected] = useState<React.ReactNode>('');

  const toggleList = useCallback(() => {
    setVisibility(!open);
  }, [open, setVisibility]);

  const handleClickAway = useCallback(() => {
    if (open) setVisibility(false);
  }, [open]);

  const handleSelect = useCallback((item: T) => {

    onSelect(item);

    setSelected(getItemValue(item));

  }, [setSelected, onSelect, getItemValue]);

  const items = onChange
    ? onChange(props.options)
    : props.options;
  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <div
            className={styles.badgeBase}
            onClick={toggleList}
            onBlur={handleClickAway}>
            <SelectInputValue
              className={styles.inputValue}
              defaultValue={props.defaultValue}
              placeholder={props.placeholder}
              value={props.value ?? selected} />
            <ChevronDown size={12} className={styles.arrow} />
          </div>
          <SelectList
            rootClassName={cx(props.optionsClassName)}
            className={cx(styles.option)}
            open={open}
            getOptionItem={getItemValue}
            items={items}
            onMouseDown={handleSelect} />
        </div>
      </ClickAwayListener>
    </>
  );
};

export { SelectBadge };
export default SelectBadge;