import { memo } from 'react';
import { useSelectUser } from '@containers/Store';
import { cdn, cx, getLocationFor } from '@utils';
import * as admin from '@utils/permissions.admin';
import { UserAvatar } from '@/components/UserAvatar';
import { MaybeLink } from '@/components/MaybeLink';
import styles from './style/Name.Small.css';

export type NameSmallProps = {
  classes?: {
    root?:   string;
    avatar?: string;
    name?:   string;
  };
  id:          number;
  name:        string;
  pictureUrl?: string;
  link?:       boolean;
  linkTarget?: React.HTMLAttributeAnchorTarget;
};

export const NameSmall = memo(({ classes = {}, link = true, ...props }: NameSmallProps) => {
  const me = useSelectUser();
  const permitted = admin.hasAccessFor.experts.dashboard(me);

  const location = permitted && link
    ? getLocationFor.admin.member.details({
      userId: props.id,
    })
    : null;

  const pictureUrl = cdn.buildAvatarUrl(props.pictureUrl, 100);

  return (
    <div
      className={cx(styles.root, classes.root)}
      title={props.name}>
      <MaybeLink
        target={props.linkTarget}
        to={location}>
        <div
          className={styles.wrap}>
          <div className={cx(styles.avatar, classes.avatar)}>
            <UserAvatar
              pictureUrl={pictureUrl}
              size={30} />
          </div>
          <div className={cx(location ? styles.link : null, styles.trunc, classes.name)}>{props.name}</div>
        </div>
      </MaybeLink>
    </div>
  );
});

export default NameSmall;