import { memo } from 'react';
import { cx } from '@utils';
import styles from './style.css';

type Props = {
  className?: string;
  color?: string;
  onClick?: () => void;
  size?: number;
};

const Pencil = memo(({
  color = 'currentColor',
  size,
  ...props
}: Props) => {

  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      width={size || 24}
      height={size || 24}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}>
      <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
    </svg>
  );
});

export const PencilButton = memo(({ className, onClick, ...props }: Props) => {

  return (
    <div
      className={cx(styles.circle, className)}
      onClick={onClick}>
      <Pencil {...props} />
    </div>
  );
});

export { Pencil };
export default Pencil;