import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import type { QueryParamConfig } from 'use-query-params';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import { useSuspendInitialLoading } from '@containers/GroupProject/hooks';
import { useIsInternalUser } from '@/containers/Store';
import { WorkspacesNavMenu } from '@/components/Workspace/WorkspaceNav';
import AgendaCard from '@screens/GroupMain/AgendaCard';
import { useRegisterGlobalChatContext } from '@/screens/GroupMain/hooks';
import { BrandsTable } from '@/screens/GroupMain/BrandsTable';
import * as Table from '@/components/Table';
import type { GetBrands } from '@/services/api/interfaces/workspace.brand';
import * as CallScheduling from '$admin/components/Scheduling';
import type { PaginationProps } from '$admin/Project.Aggregate/Pagination';
import { Pagination } from '$admin/Project.Aggregate/Pagination';
import styles from './styles.css';

type Props = unknown;

export const GroupBrands = (props: Props) => {

  const isInternalUser = useIsInternalUser();

  useRegisterGlobalChatContext();

  return (
    <CallScheduling.Container>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.main}>
            <Helmet title="Brands" />

            <div className={styles.table}>
              {isInternalUser && <WorkspacesNavMenu className={styles.workspaces} />}

              <BrandsTableWidget />
            </div>

            <div className={styles.sidebar}>
              <AgendaCard />
            </div>

          </div>
        </div>
      </div>
    </CallScheduling.Container>
  );
};

const BrandsTableWidget = () => {
  const [qp, setQuery] = useQueryParams({
    index: withDefault(NumberParam, 0),
    size: withDefault(NumberParam, 25),
    sortBy: withDefault(StringParam, 'createdOn') as QueryParamConfig<'createdOn' | 'name' | 'owner'>,
    sortDir: withDefault(StringParam, 'desc') as QueryParamConfig<'asc' | 'desc'>,
  });

  const query = useQuery({
    queryKey: [
      `get:brands`, {
        index: qp.index,
        size: qp.size,
        sortBy: qp.sortBy,
        sortDir: qp.sortDir,
      },
    ],
    queryFn: () => {
      return api.workspaces.brand.getBrands({
        skip: qp.index * qp.size,
        take: qp.size,
        sortBy: qp.sortBy,
        sortDir: qp.sortDir,
      });
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const pageCount = useMemo(() => Math.ceil((query.data?.totalCount ?? 0) / qp.size), [qp.size, query.data?.totalCount]);

  const pagination = useMemo(() => ({
    pageCount,
    totalCount: query.data?.totalCount ?? 0,
  }), [pageCount, query.data?.totalCount]);

  const loading = useMemo(() => query.isInitialLoading && !query.data, [query.data, query.isInitialLoading]);

  const data = useMemo(() => {
    return loading
      ? getLazyTableData(25)
      : query.data?.brands ?? [];
  }, [loading, query.data?.brands]);

  const empty = useMemo(() => {
    return query.isFetchedAfterMount && !loading && !data.length;
  }, [data.length, loading, query.isFetchedAfterMount]);

  const suspended = useSuspendInitialLoading({
    data,
    empty,
    isInitialLoading: query.isInitialLoading,
    loading,
    pagination,
  }, 1000);

  const paginationProps: PaginationProps = {
    canNextPage: qp.index < pageCount - 1,
    canPreviousPage: qp.index > 0,
    nextPage: () => setQuery(old => ({ index: old.index + 1 })),
    pageCount,
    pageIndex: qp.index,
    pageSize: qp.size,
    pageSizeOptions: [25, 50, 100],
    previousPage: () => setQuery(old => ({ index: old.index - 1 })),
    pageOptions: Array.from({ length: pageCount }).map((_, i) => i + 1),
    setPageSize: size => setQuery({ index: 0, size }, 'pushIn'),
  };

  return (
    <div className={styles.table}>
      <Table.Layout.Box className={styles.box}>
        <Table.Layout.Header
          classes={{ title: styles.title }}
          title="Brands">
        </Table.Layout.Header>
        <BrandsTable
          items={suspended.data}
          isEmpty={suspended.empty}
          isLoading={suspended.loading}
          sort={{
            by: qp.sortBy,
            dir: qp.sortDir,
            onSort: (by, dir) => setQuery({ sortBy: by, sortDir: dir }),
          }} />
        <Table.Layout.Footer>
          <Pagination {...paginationProps} />
        </Table.Layout.Footer>
      </Table.Layout.Box>
    </div>
  );
};

GroupBrands.displayName = 'Group.Brands';

const getLazyTableData = <T extends GetBrands.BrandItem>(pageSize = 25) => {
  return Array.from({ length: pageSize }, _ => ({} as T));
};