import type { Chat } from '@/brand-insights/types';
import { generateEmptyChatSessionFiltersState } from './state.initial-state';
import type { ChatSessionState, ChatState } from './interfaces';

export function filtersReducer(state: ChatSessionState.State, action: ChatState.Action): Chat.Filters {
  switch (action.type) {
    case 'filters/reset': {
      const context = state.chatInstance.context;
      return generateEmptyChatSessionFiltersState({ context });
    }
    case 'filters/updated': {
      return Object.fromEntries(Object.entries({ ...state.filters, ...action.payload.filters }).filter(([_, v]) => v != null));
    }

    default:
      return state.filters;
  }
}