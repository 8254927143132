import {
  SurveyBuilderVersion,
} from '@/types/survey';
import {
  SurveyOptionsBuilder,
  SurveyRowsBuilder,
  SurveySectionsBuilder,
} from '../interfaces';
import * as $question from './state.questions';
import {
  addOptions,
  removeOption,
  updateOptionValue,
} from './state.options';
import {
  addRows,
  removeRow,
  updateRowValue,
} from './state.rows';

type ApplyChangesToSurvey = {
  addedOptions?: SurveyOptionsBuilder.AddOptions.State[];
  addedRows?: SurveyRowsBuilder.AddRows.State[];
  survey: SurveyBuilderVersion;
  removedOptions?: SurveyOptionsBuilder.RemoveOption.State[];
  removedRows?: SurveyRowsBuilder.RemoveRow.State[];
  removedSections?: SurveySectionsBuilder.RemoveSection.State[];
  updatedOptions?: SurveyOptionsBuilder.UpdateOptionValue.State[];
  updatedRows?: SurveyRowsBuilder.UpdateRowValue.State[];
};

export function applyChangesToSurvey({
  addedOptions = [],
  addedRows = [],
  removedOptions = [],
  removedRows = [],
  removedSections = [],
  updatedOptions = [],
  updatedRows = [],
  survey,
}: ApplyChangesToSurvey) {

  let returnValue = survey.questions;

  removedSections.forEach(f => {
    returnValue = $question.removeSection(survey, f);
  });
  removedOptions.forEach(f => {
    returnValue = removeOption(returnValue, f);
  });
  removedRows.forEach(f => {
    returnValue = removeRow(returnValue, f);
  });
  addedOptions.forEach(f => {
    returnValue = addOptions(returnValue, f);
  });
  addedRows.forEach(f => {
    returnValue = addRows(returnValue, f);
  });
  updatedOptions.forEach(f => {
    returnValue = updateOptionValue(returnValue, f);
  });
  updatedRows.forEach(f => {
    returnValue = updateRowValue(returnValue, f);
  });

  return returnValue;
}