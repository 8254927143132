import type { InternalAxiosRequestConfig } from 'axios';
import { qs } from '@utils';
import type { XHR } from './interfaces';

export function logConnectionRefused() {
  if (!process.env.__DEV__) return;

  console.error(
    `%c${' '.repeat(6)}SERVER OFFLINE %c⇣`,
    'color: #c10d34; font-weight: bold;',
    'color: #ffffff;'
  );
}

export function logError(e: XHR.Error) {
  if (!process.env.__DEV__) return;

  const location = `${e.config.url}\n\n${e.response.data}\n`;
  const statusText = `${e.response.statusText}`;
  console.error(
    `%cerror %c${e.response.status} %c${statusText}\n\n${location}`,
    'color: #646464',
    'color: #5fafff',
    'color: #ffffff',
  );
}

export function logRequest(config: InternalAxiosRequestConfig) {
  if (!process.env.__DEV__) return config;

  const pic = { patch: '◆', post: '◆', put: '◆', get: '■︎', delete: '✕' }[config.method];
  const method = config.method.toUpperCase().concat(' '.repeat(7 - config.method.length));
  const q = config.params ? `?${qs.stringify(config.params)}` : '';
  console.info(
    `%c${' '.repeat(8)}${method} %c${pic} ${config.url}${q}`,
    'color: #646464; font-weight: bold;',
    'color: #646464;'
  );

  return config;
}