import { useContext } from 'react';
import styles from '@screens/Workspace.File/style/Workspace.File.css';
import { WorkspaceFolderContainer } from '@containers/Workspace.Folder/Folder.Container';
import { FolderLoadingContext } from '@/containers/Workspace.Folder/Context';
import { TabViewSkeleton } from '@/components/TabView';
import { FolderTabView } from './Folder.TabView';
import { Header } from './Header';
import { useFolderTabsMap } from './useFolderTabsMap';

const WorkspaceFolder = () => {

  const isLoading = useContext(FolderLoadingContext);
  const tabsMap = useFolderTabsMap();

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Header />
        {isLoading
            ? <TabViewSkeleton />
            : <FolderTabView map={tabsMap} />}
      </div>
    </div>
  );
};

const WithState = () => (
  <WorkspaceFolderContainer>
    <WorkspaceFolder />
  </WorkspaceFolderContainer>
);

export { WithState as WorkspaceFolder };