import { memo } from 'react';
import { Helmet } from 'react-helmet';
import { CompanyLogo } from '@presentation/Company.Logo';
import styles from './style/Company.css';

type Props = {
  item: {
    name: string;
    logo: string;
  };
};

export const Header = memo(({ item }: Props) => {
  return (
    <div className={styles.root}>
      <Helmet title="Company" />
      <div className={styles.left}>
        <CompanyLogo
          className={styles.logo}
          url={item.logo} />
        <div>
          <div className={styles.name}>
            {item.name}
          </div>
        </div>
      </div>
    </div>
  );
});