import { useCallback } from 'react';
import { NumberParam, useQueryParam } from 'use-query-params';
import { ProjectSurveyBuilderState, SurveyQuotasGoalContext } from '@containers/SurveyBuilder';
import { useSurveyBuilderState, useCanSaveSurvey } from '@containers/SurveyBuilder/hooks';
import type { ProjectType } from '@/enums';
import { SurveyType } from '@/enums';
import * as api from '$admin/api';
import { EditContext, QueryContext, useSurveyStepperContext } from './Context';
import { useProjectSurveysContext } from './ProjectSurveysContainer';

type Props = {
  quota: {
    goal: number;
  };
  project: {
    id: number;
    projectType: ProjectType;
  };
} & ChildrenProps;

export function NewSurveyContainers({ project, quota, children }: Props) {

  return (
    <QueryContainer>
      <SurveyBuilderContainer project={project}>
        <SurveyQuotasGoalContext.Provider value={quota.goal}>
          <EditContainer projectId={project.id}>
            {children}
          </EditContainer>
        </SurveyQuotasGoalContext.Provider>
      </SurveyBuilderContainer>
    </QueryContainer>
  );
}

function QueryContainer({ children }: ChildrenProps) {
  return (
    <QueryContext.Provider value={[true as boolean, null]}>
      {children}
    </QueryContext.Provider>
  );
}

function SurveyBuilderContainer({ project, children }: Pick<Props, 'children' | 'project'>) {
  const { query: surveysQuery } = useProjectSurveysContext();
  return (
    <ProjectSurveyBuilderState projectType={project.projectType} excludeSurveyTypes={surveysQuery.data.surveys.filter(t => t.typeId === SurveyType.Project).map(s => s.typeId)}>
      {children}
    </ProjectSurveyBuilderState>
  );
}

function EditContainer({ children, projectId }: Pick<Props, 'children'> & IProjectId) {
  const canSaveSurvey = useCanSaveSurvey();
  const [state, dispatch] = useSurveyBuilderState();
  const { query: surveysQuery } = useProjectSurveysContext();
  const { actions } = useSurveyStepperContext();
  const [, setSurveyId] = useQueryParam('surveyId', NumberParam);

  const save = useCallback(() => {
    return api.projects.surveys.updateSurvey({
      projectId,
      survey: {
        alternateImageExercises: state.survey.alternateImageExercises,
        classifications: state.survey.classifications,
        items: state.survey.items,
        logic: state.survey.logic,
        messages: state.survey.messages,
        sections: state.survey.sections,
        surveyId: state.survey.surveyId,
        surveyDetails: state.survey.surveyDetails,
        questions: state.survey.questions,
        quotas: state.survey.quotas,
        tagging: state.survey.tagging,
        template: state.survey.template,
      },
      draftVersionId: state.draft.surveyVersionId,
    })
      .then(res => {
        surveysQuery.refetch();
        setSurveyId(res.survey.surveyId);

        actions.goTo(0);
        return res;
      });
  }, [
    actions,
    surveysQuery,
    projectId,
    state.draft.surveyVersionId,
    state.survey,
    setSurveyId,
  ]);

  const cancel = useCallback(() => {
    return new Promise<void>(resolve => {
      dispatch({
        type: 'reset-survey',
        value: state.savedSurvey,
      });
      resolve();
    });
  }, [state.savedSurvey, dispatch]);

  return (
    <EditContext.Provider value={[canSaveSurvey, save, cancel, null]}>
      {children}
    </EditContext.Provider>
  );
}