import { NumericFormat } from 'react-number-format';
import { Input } from '@/components/Input';

type Props = {
  prefix?:  string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => unknown;
  value:    string;
};

export const Amount = (props: Props) => {

  return (
    <NumericFormat
      customInput={Input}
      decimalScale={2}
      name="amount"
      onChange={props.onChange}
      placeholder={`${props.prefix ?? '$'}250.00`}
      prefix={props.prefix ?? ''}
      value={props.value} />
  );
};

Amount.displayName = 'Project.Honoraria.Field.Amount';
