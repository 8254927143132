import { useCallback } from 'react';
import type { InvalidContentHandler } from '@remirror/core';
import { useRemirror, ReactComponentExtension } from '@remirror/react';
import type {
  LinkOptions, PlaceholderOptions,
} from 'remirror/extensions';
import {
  DocExtension, TextExtension,
  ParagraphExtension, PlaceholderExtension,
  BoldExtension, ItalicExtension,
  LinkExtension, UnderlineExtension,
  BulletListExtension, OrderedListExtension,
} from 'remirror/extensions';
import type { SurveyRichText } from '@/types/survey.rich-text';
import type { DisplayOptions } from '@/components/Remirror/Extension.Image.DisplayView';
import type { ImageOptions } from '@/components/Remirror';
import { ImageExtension, TextColorExtension } from '@/components/Remirror';
// eslint-disable-next-line import/no-cycle
import {
  PipedResponseExtension,
  TemplatePriceLinkExtension,
  TemplateCategoryExtension,
  TemplateCompetitorExtension,
  TemplateTargetExtension,
  TemplateNodeExtension,
  TPPNodeExtension,
  ViewStimulusExtension,
  ViewTPPExtension,
  ViewTPP_RTExtension,
  FindAndReplaceExtension,
} from '../Extension';
import { useRemirrorStyles } from './useRemirrorStyles';

type Props = {
  initialState?: SurveyRichText.RootNode;
  placeholder?: string;
  imageDisplayOptions?: DisplayOptions;
};

export const useSurveyRichTextEditor = ({ initialState, placeholder, ...props }: Props) => {

  const onError: InvalidContentHandler = useCallback(({ json, invalidContent, transformers }) => {
    console.error('Invalid Content', invalidContent);
    return transformers.remove(json, invalidContent);
  }, []);

  // useRemirrorStyles();

  const imageExtensionParams: ImageOptions = {
    enableResizing: true,
    displayOptions: props.imageDisplayOptions/* ?? {
      sizeOverrides: {
        width: '200px',
        height: '200px',
      }
    }*/,
  };

  const linkExtensionParams: LinkOptions = {
    autoLink: true,
    defaultTarget: '_blank',
    // defaultProtocol: 'https:',
  };

  const placeholderExtensionParams: PlaceholderOptions = {
    placeholder,
  };

  return useRemirror({
    content: initialState,
    extensions: () => [
      new DocExtension({}),
      new PlaceholderExtension(placeholderExtensionParams),
      new ParagraphExtension({}),
      new TextExtension(),
      new ReactComponentExtension({}),
      new TemplatePriceLinkExtension({}),
      new TemplateCategoryExtension({}),
      new TemplateCompetitorExtension({}),
      new TemplateTargetExtension({}),
      new TemplateNodeExtension({}),
      new PipedResponseExtension({}),
      new BoldExtension({}),
      new ItalicExtension(),
      new UnderlineExtension(),
      new TextColorExtension({}),
      new LinkExtension(linkExtensionParams),
      new ImageExtension(imageExtensionParams),
      new BulletListExtension({}),
      new OrderedListExtension({}),
      new ViewStimulusExtension({}),
      new TPPNodeExtension({}),
      new ViewTPPExtension({}),
      new ViewTPP_RTExtension({}),
      new FindAndReplaceExtension({}),
    ],
    onError,
  });
};