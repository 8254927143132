import { Fragment } from 'react';
import { useSurveyThemingPalette } from '@containers/Branding/hooks/useSurveyThemingPalette';
import { Copy } from '@screens/ProjectDetails/Copy';
import { ShareProject } from '@screens/ProjectDetails/ShareProject';
import { Title } from '@screens/ProjectDetails/Title';
import { cx } from '@utils';
import { useProjectInviteContext } from '@/containers/ProjectOnboarding/Context';
import { ProjectType } from '@/enums';
import { BackButton } from '@/components/BackButton';
import { ButtonSet } from './RequestReview.ButtonSet';
import type { ProjectRequestReviewProps } from './interfaces';
import styles from './style.css';

type Props =
  ProjectRequestReviewProps;

export const ProjectRequestReview = (props: Props) => {

  const ctx = useProjectInviteContext();

  const { accountManager, call, project, survey } = ctx.data;

  const hasCallLikeType = [
    ProjectType.Call,
    ProjectType.SurveyCall,
  ].includes(project.projectType);

  const { theme } = useSurveyThemingPalette();

  const showCallRate = hasCallLikeType && !!call.rate;
  const showDuration = !hasCallLikeType;
  const showSurveyRate = !hasCallLikeType && survey.payment !== 0;

  return (
    <div className={cx(styles.root, { [styles.theme]: theme })}>
      <div className={styles.wrap}>
        {!theme &&
          <div className={styles.link}>
            <BackButton />
          </div>}

        <div className={styles.main}>
          <div className={styles.left}>
            <div className={styles.row}>
              <Title text="Title" />
              <Copy text={project.name} />
            </div>

            {showCallRate &&
              <div className={styles.row}>
                <Title text="Hourly Rate" />
                <Copy text={`$${call.rate}`} />
              </div>}

            {showDuration &&
              <div className={styles.row}>
                <Title text="Estimated Length" />
                <Copy text={`${survey.length || 50} minutes`} />
              </div>}

            {showSurveyRate &&
              <div className={styles.row}>
                <Title text="Payment" />
                <Copy text={`$${survey.payment}`} />
              </div>}

            <div className={styles.row}>
              <Title text="Description" />
              <Copy text={project.description} />
            </div>

            {accountManager &&
              <div className={cx(styles.row, styles.accountManager)}>
                If you need help or have any questions, please contact the project manager {accountManager.firstName} at <a className={styles.email} href={`mailto:${accountManager.email}`}>{accountManager.email}</a>
              </div>
            }

            {survey.isExternal &&
              <div className={cx(styles.row, styles.externalWarning)}>
                {copy.external}
              </div>
            }

            <div className={styles.consent}>
              {!hasCallLikeType || (hasCallLikeType && !call.recordingConsent.required)
                ? <ButtonSet
                    onAccept={props.onRequestAccepted}
                    onReject={props.onRequestDeclined} />
                : (
                  <div className={styles.recording}>
                    <Copy text={copy.consent} />
                    <ButtonSet
                      onAccept={props.onRequestAccepted}
                      onReject={props.onRequestDeclined} />
                  </div>
                )
              }
            </div>
          </div>

          {(!project.memberReferrals
            ? null
            :  !theme) &&
              <Fragment>
                <div className={styles.divider} />
                <div className={styles.right}>
                  <ShareProject project={project} />
                </div>
              </Fragment>}
        </div>
      </div>
    </div>
  );
};

const copy = {
  consent: `By accepting this project, I consent to be recorded and transcribed`,
  external: `This survey will direct you to a third party website. If you qualify, upon completion you will be redirected to Sentiment to receive compensation.`,
};