import { useCallback } from 'react';
import { Buffer } from 'buffer/';
import { S3Client } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';
import * as api from '@api';

type Params = {
  format:     string;
  height:     number;
  identifier: string;
  src:        string;
  type:       string;
  width:      number;
};

export const useUploadArticlePreviewImage = () => {
  const uploadImage = async (params: Params) => {
    const response = await api.posts.uploads.queue({
      format: params.format,
      postIdentifier: params.identifier,
      type: params.type,
    });

    const { credentials } = await api.posts.uploads.start({
      fileUploadId: params.identifier,
      s3Key: response.data.s3Key,
    });

    const client = new S3Client({
      credentials,
      region: 'us-east-1',
      useAccelerateEndpoint: true,
    });

    const body = Buffer.from(params.src.replace(/^data:image\/\w+;base64,/, ''), 'base64');

    const ul = new Upload({
      client,
      params: {
        Body: body,
        Bucket: response.data.bucket,
        ContentType: params.type,
        Key: response.data.s3Key,
        StorageClass: 'STANDARD_IA',
      },
    });

    await ul.done();

    return {
      format: params.format,
      height: params.height,
      identifier: params.identifier,
      s3Key: response.data.s3Key,
      type: params.type,
      width: params.width,
    };

  };

  return useCallback(uploadImage, []);
};