import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as api from '@api';
import type * as API from '@api/interfaces';
import Toast from '@/components/Toast';

type Params = {
  id?: number;
  offPlatform?: boolean;
  verified?: boolean;
};

const isOffPlatform = (params: Pick<Params, 'offPlatform'>) => {
  return !!params.offPlatform;
};

const isUnverified = (params: Params) => {
  if (params.offPlatform || !params?.id) return false;

  return !params.verified;
};

const isVerified = (params: Params) => {
  return !params.offPlatform && !isUnverified(params);
};

const useSendUserInviteEmailMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: IUserId) => {
      return api.groups.sendGroupUserInviteEmail({
        userId: params.userId,
      })
      .then(() => {
        Toast.success({
          title: `Email sent.`,
        });
      });
    },
    mutationKey: [
      `post:groups/emails/invite`,
    ],
  });

  return mutation;
};

const useRegisterClientUsersMutation = ({ projectId }: IProjectId) => {
  const mutation = useMutation({
    mutationFn: (data: Omit<API.Users.RegisterClientUsers.Request, 'projectId'>) => {
      return api.users.registerClientUsers({
        items: data.items,
        notify: data.notify,
        projectId,
      });
    },
    mutationKey: [
      `post:users/register-client-users`,
      projectId,
    ],
  });

  return mutation;
};

const useGetSendInviteEmailHandler = () => {
  const mutation = useSendUserInviteEmailMutation();

  const getSendEmailInviteHandler = (data: Params) => {
    if (!isUnverified(data)) return;

    return () => {
      mutation.mutateAsync({
        userId: data.id,
      });
    };
  };

  return useCallback(getSendEmailInviteHandler, [
    mutation,
  ]);
};

type UseGetCreateClientUserHandlerParams = {
  onBeforeCreateClientUser?: (params: { email: string }) => void;
  onSuccessCreateClientUser?: (res: API.Users.RegisterClientUsers.Response) => void;
} & IProjectId;

type GetCreateAccountHandlerParams = {
  email: string;
  registerable?: boolean;
};

const useGetCreateClientUserHandler = ({ onBeforeCreateClientUser, onSuccessCreateClientUser, projectId }: UseGetCreateClientUserHandlerParams) => {
  const mutation = useRegisterClientUsersMutation({ projectId });

  const getCreateAccountHandler = (data: GetCreateAccountHandlerParams) => {
    if (!data.registerable) return;

    return (params?: { firstName?: string; lastName?: string; notify: boolean }) => {
      onBeforeCreateClientUser?.({ email: data.email });

      mutation.mutateAsync({
        items: [{
          firstName: params.firstName,
          lastName: params.lastName,
          email: data.email,
        }],
        notify: params.notify,
      })
      .then(res => onSuccessCreateClientUser?.(res));
    };
  };

  return useCallback(getCreateAccountHandler, [
    mutation,
    onBeforeCreateClientUser,
    onSuccessCreateClientUser,
  ]);
};

type UseUserVerificationContextMenuParams = {
  onBeforeCreateClientUser?: (params: { email: string }) => void;
  onSuccessCreateClientUser?: (res: API.Users.RegisterClientUsers.Response) => void;
} & IProjectId;

type UserVerificationContextMenuParams = {
  email: string;
  registerable?: boolean;
} & Params;

export const useUserVerificationContextMenu = (data: UseUserVerificationContextMenuParams) => {
  const getCreateAccountHandler = useGetCreateClientUserHandler(data);
  const getSendEmailInviteHandler = useGetSendInviteEmailHandler();

  const shouldDisplayContextMenu = (params: UserVerificationContextMenuParams) => {
    return {
      onClickCreateClientUser: getCreateAccountHandler(params),
      onClickSendInviteEmail: getSendEmailInviteHandler(params),
      visible: isUnverified(params) || params.registerable,
    };
  };

  return useCallback(shouldDisplayContextMenu, [
    getCreateAccountHandler,
    getSendEmailInviteHandler,
  ]);
};

export const useEvalUserVerification = () => {
  const evalUserVerification = (params: Params) => {
    return {
      isOffPlatform: isOffPlatform(params),
      isUnverified: isUnverified(params),
      isVerified: isVerified(params),
    };
  };

  return useCallback(evalUserVerification, []);
};