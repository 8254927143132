import type { Room, TwilioError } from 'twilio-video';
import { useCallback, useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Callback = (...args: any[]) => void;

export function useHandleRoomDisconnection(
  room: Room,
  onError: Callback,
  removeLocalAudioTrack: () => void,
  removeLocalVideoTrack: () => void,
  isSharingScreen: boolean,
  toggleScreenShare: () => void
) {
  const handleDisconnected = useCallback((_: Room, error: TwilioError) => {
    if (error) {
      onError(error);
    }

    removeLocalAudioTrack();
    removeLocalVideoTrack();

    if (isSharingScreen) {
      toggleScreenShare();
    }
  }, [isSharingScreen, onError, removeLocalAudioTrack, removeLocalVideoTrack, toggleScreenShare]);

  useEffect(() => {
    if (room) {
      room.on('disconnected', handleDisconnected);
      return () => {
        room.off('disconnected', handleDisconnected);
      };
    }
  }, [room, handleDisconnected]);
}
