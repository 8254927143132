import { useCallback, useState } from 'react';
import type { WorkspaceSearchFull } from '@/types';
import {
  SearchLoadingAllContext, SearchLoadingBrandContext, SearchLoadingDispatchContext, SearchLoadingFileContext,
  SearchLoadingFolderContext, SearchLoadingMemberContext, SearchLoadingProjectContext,
  SearchLoadingResetContext,
  SearchLoadingTranscriptContext,
} from './Context';
import type { SearchLoadingContextState } from './interfaces';

export default function Loading({ children }: ChildrenProps) {
  const [state, dispatch] = useState<SearchLoadingContextState>(getInitialState());

  const reset = useCallback((tab: WorkspaceSearchFull.SearchTab) => {
    // dispatch(prev => ({
    //   ...prev,
    //   [tab]: { untouched: true, loading: true },
    // }));
    dispatch(getInitialState());
  }, []);

  return (
    <SearchLoadingDispatchContext.Provider value={dispatch}>
      <SearchLoadingResetContext.Provider value={reset}>
        <SearchLoadingAllContext.Provider value={state.all}>
          <SearchLoadingProjectContext.Provider value={state.projects}>
            <SearchLoadingMemberContext.Provider value={state.members}>
              <SearchLoadingFileContext.Provider value={state.files}>
                <SearchLoadingFolderContext.Provider value={state.folders}>
                  <SearchLoadingTranscriptContext.Provider value={state.transcripts}>
                    <SearchLoadingBrandContext.Provider value={state.brands}>
                      {children}
                    </SearchLoadingBrandContext.Provider>
                  </SearchLoadingTranscriptContext.Provider>
                </SearchLoadingFolderContext.Provider>
              </SearchLoadingFileContext.Provider>
            </SearchLoadingMemberContext.Provider>
          </SearchLoadingProjectContext.Provider>
        </SearchLoadingAllContext.Provider>
      </SearchLoadingResetContext.Provider>
    </SearchLoadingDispatchContext.Provider>
  );
}

function getInitialState(): SearchLoadingContextState {
  return {
    all: { untouched: true, loading: true },
    brands: { untouched: true, loading: true },
    projects: { untouched: true, loading: true },
    members: { untouched: true, loading: true },
    files: { untouched: true, loading: true },
    folders: { untouched: true, loading: true },
    transcripts: { untouched: true, loading: true },
  };
}

export { Loading };
