import type { MatrixRangeQuestion, SurveyRichText } from '@/types';
// import { device } from '@utils';
import { useToggleMatrixValue, useSurveyFormQuestionAnswer } from '@/containers/SurveyForm';
import type { SurveyQuestionType } from '@/enums';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import { RadioButton } from '@/components/Radio';
import {
  SurveyRichTextEditorContainer,
  useSurveyRichTextEditor, SurveyRichTextEditor,
} from '@/components/Survey.RichText';
import styles from './style/MatrixRange.css';
import { Rationale } from './Rationale';

type Props = {
  item: MatrixRangeQuestion.FormQuestion;
};

export const MatrixRangeForm = ({ item }: Props) => {

  // if (device.phone) {
  //   return <Mobile item={item} />;
  // }

  return (
    <>
      <Desktop item={item} />
      <Rationale settings={item.settings.rationale} />
    </>
  );
};

// const Mobile = ({ item }: Props) => {

//   return null;
// };

const Desktop = ({ item }: Props) => {

  const toggleOptionValue = useToggleMatrixValue();
  const [answer] = useSurveyFormQuestionAnswer<SurveyQuestionType.MatrixRange>();

  return (
    <table className={styles.grid}>
      <thead>
        <tr>
          <th className={styles.leftLabelHeader}>{parseSurveyRichText(item.settings.leftColumnLabel)}</th>
          {item.options.map(option => (
            <th key={option.id} className={styles.optionHeader}>
              {parseSurveyRichText(option.value)}
              {!!option.metadata.helpText &&
                <>
                  <br />
                  {option.metadata.helpText}
                </>
              }
            </th>
          ))}
          <th className={styles.rightLabelHeader}>{parseSurveyRichText(item.settings.rightColumnLabel)}</th>
        </tr>
      </thead>

      <tbody>
        {item.matrixRows.map(row => {
          const selectedOptionId = answer.items.find(f => f.matrixRowId === row.id)?.optionId;
          return (
            <tr key={row.id}>
              <td className={styles.labelCell}>
                <RichTextEditor initialState={row.metadata.leftStatement} />
              </td>
              {item.options.map(option => (
                <td className={styles.radioCell} key={option.id}>
                  <RadioButton
                    onChange={() => toggleOptionValue(row.id, option.id)}
                    checked={selectedOptionId === option.id} />
                </td>
              ))}
              <td className={styles.labelCell}>
                <RichTextEditor initialState={row.metadata.rightStatement} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

type RichTextProps = {
  initialState: SurveyRichText.RichTextValue;
};

const RichTextEditor = ({ initialState }: RichTextProps) => {

  const { manager, state, onChange } = useSurveyRichTextEditor({
    initialState,
  });

  return (
    <SurveyRichTextEditorContainer
      editable={false}
      manager={manager}
      state={state}
      onChange={onChange}>
      <SurveyRichTextEditor />
    </SurveyRichTextEditorContainer>
  );
};