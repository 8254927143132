import { useCallback } from 'react';
import { useAccountProfileNewEntry, useProfileEditableState, useAccountProfileEditState, useProfileState } from '@containers/AccountProfile';
import Entries from './Profile.Section.MultiEntry.Entries';
import { Header } from './Profile.Section.Header';
import Placeholder from './Profile.Placeholder';
import { Section } from './interfaces';
import { AddEntryButton } from './Profile.Form.AddEntryButton';
import { getMultiEntryTypeValues } from './Profile.Section.utils';
import styles from './style/Profile.Section.Multiple.css';

export const ProfileSectionMultiEntry = <T extends Section.MultiEntry.ProfileMultiEntry>(props: Section.MultiEntry.Props<T>) => {

  const [user] = useProfileState();
  const editable = useProfileEditableState();
  const [entryId] = useAccountProfileEditState();
  const [editingNewEntry, toggleNewEntry] = useAccountProfileNewEntry(props.type);

  const items = user[props.type];

  const canEdit = editable && !entryId;
  const isBeingEdited = entryId.startsWith(props.type);

  const {
    placeholder,
    title,
  } = getMultiEntryTypeValues(props.type);

  const renderHeader = useCallback(() => (
    <Header
      title={title}>
      {canEdit &&
        <AddEntryButton onClick={toggleNewEntry} />
      }
    </Header>
  ), [
    canEdit,
    title,
    toggleNewEntry,
  ]);

  if (!items.length && editable && !isBeingEdited) {
    return (
      <>
        {renderHeader()}
        <Placeholder
          disabled={!canEdit}
          onClick={toggleNewEntry}
          text={placeholder} />
      </>
    );
  }

  if (!items.length && !editable) return null;

  return (
    <>
      {renderHeader()}
      <Entries
        entryClassName={styles.entry}
        type={props.type} />
    </>
  );
};

export default ProfileSectionMultiEntry;