import { memo } from 'react';
import type { Chat } from '@/brand-insights/types';
import { PaginatedQuotes } from './Message.Response.Quotes.Paginated';

type Props = {
  data: Chat.QuotesData;
};

export const FindQuotesResponse = memo(({ data }: Props) => {

  if (!data.quotes.length) {
    return (
      <div>
        {`I'm sorry, I couldn't find any quotes about "${data.topic}".`}
      </div>
    );
  }

  return (
    <div>
      {`Here are some quotes I found about "${data.topic}":`}
      <PaginatedQuotes
        pageSize={5}
        quotes={data.quotes} />
    </div>
  );
});