import { Fragment, useMemo } from 'react';

type Props = {
  children:   React.ReactNode;
  className?: string;
};

export const useFooterScreenContainer = (Footer: React.ComponentType) => {

  return useMemo(() => {

    const ScreenContainer = (props: Props) => {

      return (
        <Fragment>
          <div className={props.className}>
            {props.children}
          </div>
          <Footer />
        </Fragment>
      );
    };

    ScreenContainer.displayName = `Branding.Footer.ScreenContainer(${Footer.displayName})`;

    return ScreenContainer;

  }, [Footer]);

};