import { memo } from 'react';
import conversation from 'static/images/invite/benefits-conversation-skyblue.svg?url';
import consult from 'static/images/invite/benefits-consult-purple.svg?url';
import mouse from 'static/images/invite/benefits-mouse-blue.svg?url';
import { Benefits } from './Benefits';
import styles from './style/Benefits.css';

export const CallBenefits = memo(() => {
  const collection = {
    benefits: [{
      image: {
        className: styles.interview,
        icon: conversation,
      },
      text: {
        copy: `Sign up, verify your account, and complete interviews on Sentiment.`,
        title: `Participate in research interviews`,
      },
    }, {
      image: {
        icon: mouse,
      },
      text: {
        copy: `Sync a payout account to get paid for surveys you complete.`,
        title: `Get Paid`,
      },
    }, {
      image: {
        icon: consult,
      },
      text: {
        copy: `Use your profile to collaborate on Sentiment for future projects! Either get paid to help others or reach out to other experts for help on your own goals!`,
        title: `You're all set to get paid and to collaborate with others on Sentiment!`,
      },
    }],
  };

  return (
    <Benefits items={collection.benefits} />
  );
});

export default CallBenefits;