import { useCallback, useContext } from 'react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { cx } from '@utils';
import { ProjectActionsMenuContext } from '$admin/components/ProjectActionsMenu/Context';
import styles from './style/Action.css';

type Props = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => unknown;
};

export const ImportListMatch = ({ onChange, ...props }: Props) => {
  const { close } = useContext(ProjectActionsMenuContext);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
    close();
  }, [
    close,
    onChange,
  ]);

  return (
    <div className={cx(styles.root)}>
      <UploadFileIcon className={styles.icon} />
      <span>Import List Match</span>
      <input
        autoComplete="off"
        accept=".xls,.xlsx"
        className={styles.input}
        title=""
        type="file"
        onChange={handleChange} />
    </div>
  );
};

ImportListMatch.displayName = 'Action.ImportListMatch';