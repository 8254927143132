import { useCallback, useContext } from 'react';
import { Search, X } from 'react-feather';
import styled from '@emotion/styled';
import { FilterButton } from '@/brand-insights/components/Chat.Filters';
import { Constants } from '@/brand-insights/components/Theme';
import { ActiveChatSessionContext } from '@/brand-insights/components/Chat/context';
import { ChatContextType } from '@/brand-insights/enums';
import { Tooltip } from '@/brand-insights/components/presentation';
import { trunc } from '@/brand-insights/utils';
import { CapabilitiesContext, LinkContext } from '@/brand-insights/components/BrandInsights/context';
import { HeaderContextContext } from './context';
import { SimpleContextSelector } from './SimpleContextSelector';
import { JumpToPage } from './JumpToPage';
import { ChatContextSearchContainer } from './ContextSearch.Container';
import { ContextSelectorContainer } from './ContextSelector.Container';
import { ContextSearch } from './ContextSearch';

type Props = {
  className?: string;
};

function ChatHeader({ className }: Props) {
  const link = useContext(LinkContext);
  const capabilities = useContext(CapabilitiesContext);

  return (
    <Root className={className}>
      <Wrap>
        {link.initialized && (
          <>
            <Left>
              <StyledFilterButton />
              <SimpleContextSelector />
              <ContextDisplay />
            </Left>

            <Right>
              {capabilities.canViewExternalLinks && <JumpToPage />}
            </Right>
          </>
        )}
      </Wrap>
    </Root>
  );
}

function ContextDisplay() {
  const {
    pendingContextType,
    setPendingContextType,
    pendingEverythingSearch,
    setPendingEverythingSearch,
  } = useContext(HeaderContextContext);
  const session = useContext(ActiveChatSessionContext);

  const handleReset = useCallback(() => {
    setPendingContextType(session.context.type);
  }, [setPendingContextType, session.context.type]);

  const handleEverythingSearch = useCallback(() => {
    setPendingEverythingSearch(true);
  }, [setPendingEverythingSearch]);

  const renderSubject = useCallback(() => {
    if (session.context.type === ChatContextType.Global) {
      return null;
    }

    const maxNameLength = 50;

    if (session.context.metadata.name.length > maxNameLength) {
      return (
        <Tooltip title={session.context.metadata.name}>
          <Subject onClick={handleReset}>
            {trunc(session.context.metadata.name, maxNameLength)}
          </Subject>
        </Tooltip>
      );
    }

    return (
      <Subject onClick={handleReset}>
        {session.context.metadata.name}
      </Subject>
    );
  }, [handleReset, session.context.metadata, session.context.type]);

  if (pendingContextType || pendingEverythingSearch) {
    return (
      <ChatContextSearchContainer>
        <StyledContextSearch />
      </ChatContextSearchContainer>
    );
  }

  if (session.context.type === ChatContextType.Global) {
    return <StyledSearchIcon size={19} onClick={handleEverythingSearch} />;
  }

  return (
    <ContextSubjectWrapper>
      {renderSubject()}
      <CloseIconWrapper onClick={handleReset}>
        <CloseIcon size={18} />
      </CloseIconWrapper>
    </ContextSubjectWrapper>
  );
}

function ChatHeaderWrapper() {
  return (
    <ContextSelectorContainer>
      <ChatHeader />
    </ContextSelectorContainer>
  );
}

export { ChatHeaderWrapper as ChatHeader };

const Root = styled.div(({ theme }) => ({
  position: 'sticky',
  top: '0',
  height: `${Constants.Modal.HeaderHeight}px`,
  boxSizing: 'border-box',
  border: `1px solid ${theme.palette.gray.light2}`,
  padding: '10px',
  background: theme.palette.white.main,
}));

const Wrap = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const Left = styled.div({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
});

const Right = styled.div({
  cursor: 'pointer',
  marginRight: '8px',
});

const StyledFilterButton = styled(FilterButton)({});

const StyledContextSearch = styled(ContextSearch)({});

const ContextSubjectWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

const CloseIcon = styled(X)(({ theme }) => ({
  color: theme.palette.gray.main,
}));

const CloseIconWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  boxSizing: 'border-box',
  width: 24,
  height: 24,

  cursor: 'pointer',

  marginLeft: 10,

  borderRadius: '50%',

  '&:hover': {
    backgroundColor: theme.palette.gray.light2,

    '& svg': {
      color: theme.palette.black.main,
    },
  },
}));

const StyledSearchIcon = styled(Search)(({ theme }) => ({
  marginLeft: 8,
  cursor: 'pointer',
  color: theme.palette.black.main,
}));

const Subject = styled.div(({ theme }) => ({
  cursor: 'pointer',
  padding: 5,
  boxSizing: 'border-box',
  borderRadius: 4,

  borderStyle: 'solid',
  borderWidth: 2,
  borderColor: 'transparent',

  color: theme.palette.black.main,

  '&:hover': {
    backgroundColor: theme.palette.gray.light2,
  },
}));