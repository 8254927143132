import { useCallback, useEffect, useState, useRef, memo } from 'react';
import { useEventListener } from '@utils/hooks';
import { useCompleteOnboardingPayee } from './hooks/useCompleteOnboardingPayee';
import { useTipaltiOnboardingPopup } from './hooks/useTipaltiOnboardingPopup';
import styles from './style/OnboardingPayee.css';

type Props = {
  userId: number;
  onComplete: () => void;
};

export const OnboardingPayee = ({ userId, onComplete }: Props) => {
  const onCompleted = useRef<boolean>(false);
  const [blocked, setBlocked] = useState<boolean>(false);
  const [closed, setClosed] = useState<boolean>(false);

  const complete = usePopupSentimentCallback();
  const completeOnboardingPayee = useCompleteOnboardingPayee();

  const handleBlocked = useCallback(() => setBlocked(true), []);
  const handleClosed = useCallback(() => setClosed(true), []);

  const [openPopup, closePopup] = useTipaltiOnboardingPopup({
    userId,
    onBlocked: handleBlocked,
    onClosed: handleClosed,
  });

  const handleReopen = useCallback(() => {
    setClosed(false);
    setBlocked(false);
    openPopup();
  }, [openPopup]);

  useEffect(() => {
    if (!onCompleted.current && complete) {
      onCompleted.current = true;
      completeOnboardingPayee.mutateAsync({ userId })
        .then(() => {
          onComplete();
          closePopup();
        });
    }
  }, [userId, complete, completeOnboardingPayee, closePopup, onComplete]);

  return (
    <IFrameStatus
      isBlocked={blocked}
      isClosed={closed}
      isCompleted={onCompleted.current}
      reopen={handleReopen} />
  );
};

type IFrameStatusProps = {
  isBlocked: boolean;
  isClosed: boolean;
  isCompleted: boolean;
  reopen: () => void;
};

const IFrameStatus = memo(({ isBlocked, isClosed, isCompleted, reopen }: IFrameStatusProps) => {
  if (isBlocked) {
    return (
      <div className={styles.status}>
        <div className={styles.blocked}>{`Payment setup pop-up was blocked. Please allow Sentiment to open pop-ups.`}</div>
        <div className={styles.link} onClick={reopen}>Click to open</div>
      </div>
    );
  }

  if (isClosed && !isCompleted) {
    return (
      <div className={styles.status}>
        <div>{`Payment setup pop-up was closed before being completed.`}</div>
        <div className={styles.link} onClick={reopen}>Click to re-open</div>
      </div>
    );
  }

  return (
    <div className={styles.status}>
      Payment setup pop-up is opening...
    </div>
  );
});

function usePopupSentimentCallback() {
  const [complete, setComplete] = useState<boolean>(false);

  const handleWindowEvent = useCallback((event: ITipaltiCallback) => {
    if (event.data?.SentimentInfo?.success) {
      setComplete(true);
    }
  }, []);

  useEventListener('message', handleWindowEvent);

  return complete;
}

interface ITipaltiCallback extends Event {
  data?: {
    SentimentInfo?: {
      success: boolean;
    };
    caller: 'TipaltiInbound';
  };
}