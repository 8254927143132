import { createRef, useCallback, useState, useRef, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { usePopper } from 'react-popper';
import { ChevronRight } from 'react-feather';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import type { SurveyBuilder } from '@containers/SurveyBuilder';
import { useSurveyBuilderState, useSubmitSurveyDraft } from '@containers/SurveyBuilder';
import * as $api from '@services/api';
import { cx } from '@utils';
import type { SurveyBuilderVersion } from '@/types/survey';
import { SurveyItemType } from '@/enums';
import { Portal } from '@/components/Portal';
import { useZIndexModifier } from '@/components/Popper';
import styles from './style/SurveySectionsBuilder.css';

export const AddSectionAnchor = (props: AddSectionAnchorProps) => {
  const [open, setOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);
  const [sectionPosition, setSectionPosition] = useState<number>(null);

  const zIndexModifier = useZIndexModifier({ zIndex: 8 });

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      zIndexModifier,
      {
        name: 'offset',
        options: {
          offset: [0, 5],
        },
      },
    ],
    placement: 'bottom-start',
  });

  const handleBlur = useCallback((e: MouseEvent) => {
    if (!referenceElement.contains(e.target as Node)) {
      setOpen(false);
    }
  }, [referenceElement, setOpen]);

  return (
    <>
      {props.children({
        setOpen,
        setSectionPosition,
        popperOpen: open,
        sectionPosition,
      }, setReferenceElement)}
      {open &&
        <Portal>
          <ClickAwayListener
            mouseEvent='onMouseDown'
            onClickAway={handleBlur}
            touchEvent='onTouchStart'>
            <div
              ref={setPopperElement}
              style={popperStyles.popper}
              {...attributes.popper}>
              <SectionMenu
                setOpen={setOpen}
                sectionPosition={sectionPosition} />
            </div>
          </ClickAwayListener>
        </Portal>
      }
    </>
  );
};

const NewSectionMenuItem = ({ setOpen, sectionPosition }: Pick<RenderAnchorProps, 'setOpen' | 'sectionPosition'>) => {
  const [state, dispatch] = useSurveyBuilderState();

  const addSection = useCallback((ordinal: number) => () => {
    dispatch({
      type: 'new-section-added',
      ordinal,
    });
    setOpen(false);
  }, [dispatch, setOpen]);

  return (
    <div
      className={styles.item}
      onClick={addSection(sectionPosition || state.survey.sections.length + 1)}>
      New Section
    </div>
  );
};

const SectionMenu = ({ setOpen, sectionPosition }: Pick<RenderAnchorProps, 'setOpen' | 'sectionPosition'>) => {
  const menuRef = useRef<HTMLDivElement>();
  const [openCategory, setOpenCategory] = useState<number>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);

  const catQuery = useListModuleCategories();
  const categories = useMemo(() => catQuery.data.categories, [catQuery.data.categories]);

  const catModulesQuery = useListModulesByCategory(openCategory);
  const categoryModules = useMemo(() => catModulesQuery.data.modules, [catModulesQuery.data.modules]);

  const [state, dispatch] = useSurveyBuilderState();
  const submitDraft = useSubmitSurveyDraft();

  const refMap = useMemo(() => {
    return categories.reduce<Map>((acc, x) => {
      acc[x.id] = createRef<HTMLDivElement>();
      return acc;
    }, {});

    type Map = {
      [identifier: string]: React.RefObject<HTMLDivElement>;
    };
  }, [categories]);

  const referenceElement = useMemo(() => {
    return refMap[openCategory];
  }, [refMap, openCategory]);

  const zIndexModifier = useZIndexModifier({ zIndex: 8 });

  const { styles: popperStyles, attributes } = usePopper(referenceElement?.current, popperElement, {
    modifiers: [
      zIndexModifier,
    ],
    placement: 'right-start',
  });

  const handleBlur = useCallback((e: MouseEvent) => {
    if (!menuRef.current.contains(e.target as Node)) {
      setOpenCategory(null);
    }
  }, []);

  const handleItemClick = useCallback((id: number) => () => {
    setOpenCategory(openCategory === id ? null : id);
  }, [openCategory]);

  const handleAddModule = useCallback((surveyId: number) => () => {
    setOpen(false);
    $api.surveys.module.fetchSurveyModule({
      isModule: true,
      skipSanitization: false,
      surveyId,
    })
      .then(result => {
        const actions = computeModuleActions({
          saved: state.survey,
          value: result.survey,
          sectionPosition,
        });

        dispatch({
          type: 'batch-actions',
          actions,
        });

        submitDraft();
      });
  }, [sectionPosition, submitDraft, state.survey, dispatch, setOpen]);

  return (
    <>
      <div
        className={cx(styles.menu, { [styles.overflow]: !openCategory })}
        ref={menuRef}>
        <NewSectionMenuItem
          setOpen={setOpen}
          sectionPosition={sectionPosition} />
        {categories.map(category => (
          <div
            key={category.id}
            ref={refMap[category.id]}
            onClick={handleItemClick(category.id)}
            className={cx(styles.item, { [styles.active]: openCategory === category.id })}>
            {category.name} {`Modules`} <ChevronRight className={styles.icon} />
          </div>
        ))}
      </div>
      {!!openCategory &&
        <Portal>
          <ClickAwayListener
            mouseEvent='onMouseDown'
            onClickAway={handleBlur}
            touchEvent='onTouchStart'>
            <div
              ref={setPopperElement}
              style={popperStyles.popper}
              {...attributes.popper}>
              <div className={cx(styles.menu, styles.overflow)}>
                {categoryModules.map(module => (
                  <div
                    className={styles.item}
                    key={module.surveyId}
                    onClick={handleAddModule(module.surveyId)}>
                    {module.name}
                  </div>
                ))}
              </div>
            </div>
          </ClickAwayListener>
        </Portal>}
    </>
  );
};

function computeModuleActions({ saved, value, sectionPosition }: ComputeActions): SurveyBuilder.NonBatchActions[] {
  const actions: SurveyBuilder.NonBatchActions[] = [];

  const getItemOrdinal = (identifier: string) => saved.items.find(x => x.source.identifier === identifier)?.ordinal || 0;

  // todo: probably need to merge duplicates and fix identifiers?
  value.classifications.forEach(classification => {
    actions.push({
      type: 'classification-added',
      payload: {
        value: classification,
      },
    });
  });

  value.logic.forEach(logic => {
    actions.push({
      type: 'logic-item-added',
      payload: {
        item: logic,
      },
    });
  });

  const precedingItems = saved.items.filter(f => {
    const section = saved.sections.find(s => s.identifier === f.section.identifier);
    return section.ordinal < sectionPosition;
  });

  const startingItemOrdinal = precedingItems.length > 0
    ? Math.max(...precedingItems.map(m => m.ordinal))
    : 1;

  value.items.forEach(item => {
    const newItemOrdinal = item.ordinal + startingItemOrdinal;

    switch (item.type) {
      case SurveyItemType.Question: {
        const question = value.questions.find(x => x.base.identifier === item.source.identifier);
        actions.push({
          type: 'question-added',
          questionIdentifier: question.base.identifier,
          sectionIdentifier: question.section.identifier,
          item: {
            identifier: item.identifier,
            ordinal: newItemOrdinal,
          },
        });
        actions.push({
          type: 'update-question',
          item: question,
        });
        break;
      }

      case SurveyItemType.Message: {
        const message = value.messages.find(x => x.identifier === item.source.identifier);
        actions.push({
          type: 'message-added',
          item: {
            identifier: item.identifier,
            ordinal: newItemOrdinal,
          },
          messageIdentifier: message.identifier,
          sectionIdentifier: item.section.identifier,
        });
        actions.push({
          type: 'message-value-updated',
          messageIdentifier: message.identifier,
          value: message.value,
        });
        break;
      }

      case SurveyItemType.AlternateImageExercise: {
        const exercise = value.alternateImageExercises.find(x => x.identifier === item.source.identifier);
        actions.push({
          type: 'aie-added',
          item: {
            identifier: item.identifier,
            ordinal: newItemOrdinal,
          },
          exerciseIdentifier: exercise.identifier,
          sectionIdentifier: item.section.identifier,
        });

        actions.push({
          type: 'aie-updated',
          item: exercise,
        });
        break;
      }

      default:
        throw new UnreachableCaseError(item.type);
    }
  });

  value.quotas.forEach(quota => {
    actions.push({
      type: 'add-quota-item',
      identifier: quota.identifier,
      quotaType: quota.type,
    });
    actions.push({
      type: 'save-quota-item',
      value: quota,
    });
  });

  value.sections.forEach((section, idx) => {
    actions.push({
      type: 'section-added',
      value: {
        ...section,
        ordinal: sectionPosition + idx,
      },
    });
  });

  value.tagging.forEach(tagging => {
    actions.push({
      type: 'tagging-item-added',
      payload: {
        identifier: tagging.identifier,
      },
    });
    actions.push({
      type: 'tagging-item-saved',
      payload: {
        value: tagging,
      },
    });
  });

  return actions;
}

const useListModuleCategories = () => {
  return useQuery({
    queryKey: [`survey-modules-list-categories`],
    queryFn: () => $api.surveys.module.searchCategories({ limit: 100 }),
    placeholderData: {
      categories: [],
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 60000,
  });
};

const useListModulesByCategory = (categoryId: number) => {
  return useQuery({
    queryKey: [`survey-modules-list-by-category`, categoryId],
    queryFn: () => $api.surveys.module.searchModulesByCategories({ categoryId, limit: 100 }),
    enabled: !!categoryId,
    placeholderData: {
      modules: [],
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 60000,
  });
};

type ComputeActions = {
  saved: SurveyBuilderVersion;
  value: SurveyBuilderVersion;
  sectionPosition: number;
};

type RenderAnchorProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSectionPosition: React.Dispatch<React.SetStateAction<number>>;
  popperOpen: boolean;
  sectionPosition: number;
};

type RenderAnchor = (props: RenderAnchorProps, ref: React.Ref<HTMLElement>) => React.ReactNode;

type AddSectionAnchorProps = {
  children: RenderAnchor;
};