import { useCallback, useMemo } from 'react';
import { RefreshCw } from 'react-feather';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { useRefreshSummariesContext } from '@containers/ConferenceTag.Summary';

type Props = {
  className?: string;
};

export const RefreshSummaries = ({ className }: Props) => {

  const { refresh, ...refreshCtx } = useRefreshSummariesContext();

  const isRefreshing = useMemo(() => {
    return refreshCtx.isRefreshing && !refreshCtx.tagRefreshing;
  }, [
    refreshCtx.isRefreshing,
    refreshCtx.tagRefreshing,
  ]);

  const handleRefresh = useCallback(() => {
    if (refreshCtx.isRefreshing) return;
    return refresh();
  }, [refresh, refreshCtx.isRefreshing]);

  return (
    <Root
      className={className}
      refreshing={isRefreshing}
      disabled={refreshCtx.isRefreshing}
      onClick={handleRefresh}>
      <span>Refresh</span>
      <StyledIcon
        refreshing={isRefreshing}
        strokeWidth={3} />
    </Root>
  );
};

type RootProps = {
  disabled?: boolean;
  refreshing: boolean;
};

const Root = styled('button', {
  shouldForwardProp: prop => !['disabled', 'refreshing'].includes(prop),
})<RootProps>(({ disabled, refreshing }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
  color: disabled && !refreshing ? 'var(--gray-l)' : '#5C73AD',
  cursor: disabled ? 'not-allowed' : 'pointer',
}));

const spin = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
});

type IconProps = {
  refreshing: boolean;
};

const StyledIcon = styled(RefreshCw, {
  shouldForwardProp: prop => prop !== 'refreshing',
})<IconProps>(({ refreshing }) => ({
  animationName: refreshing ? spin : undefined,
  animationDuration: refreshing ? '800ms' : undefined,
  animationIterationCount: refreshing ? 'infinite' : undefined,
  animationTimingFunction: refreshing ? 'linear' : undefined,
}));