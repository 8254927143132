import { ProfileEntry } from '@enums';
import { cx } from '@utils';
import { AddProfileEntryButton, ProfileSectionEntries } from '@/components/UserProfile';
import styles from './style/AccountProfileSetup.css';

type Props = {
  className?:   string;
  onAddForm: () => unknown;
};

const EmploymentSetupForm = (props: Props) => {
  return (
    <div className={cx(styles.main, props.className)}>
      <ProfileSectionEntries
        entryClassName={styles.sectionEntry}
        type={ProfileEntry.Employment} />
      <AddProfileEntryButton
        className={styles.create}
        onClick={props.onAddForm}>
        Add Position
      </AddProfileEntryButton>
    </div>
  );
};

const defaultProps = {
  items: [],
};

EmploymentSetupForm.defaultProps = defaultProps;

export { EmploymentSetupForm };
export default EmploymentSetupForm;