import { useCallback, useContext } from 'react';
import {
  KeyboardContext, PopupContext, QueryContext, ResetContext,
} from './Context';

export function Reset({ children }: ChildrenProps) {
  const popper = useContext(PopupContext);
  const { clearQuery } = useContext(QueryContext);
  const { resetKeyboard } = useContext(KeyboardContext);

  const clickedAwayReset = useCallback(() => {
    if (popper.isOpen) {
      popper.setOpen(false);
    }
  }, [popper]);

  const clickedResultReset = useCallback(() => {
    clearQuery();
    resetKeyboard();
  }, [clearQuery, resetKeyboard]);

  const updatedResultsReset = useCallback((items?: number) => {
    resetKeyboard(items);
  }, [resetKeyboard]);

  const value = {
    clickedAwayReset,
    clickedResultReset,
    updatedResultsReset,
  };

  return (
    <ResetContext.Provider value={value}>
      {children}
    </ResetContext.Provider>
  );
}