import { Fragment } from 'react';
import { isAfter } from 'date-fns';
import { TranscriptComment } from '@/types/transcribe.rich-text';
import { NewComment } from './Comment.New';
import { ParentComment } from './Comment.Parent';
import styles from './style/Comments.Highlights.css';

type Props = {
  highlight: TranscriptComment['highlight'];
  comments: TranscriptComment[];
};

export const SidebarComments = ({ comments, highlight }: Props) => {

  const sorted = comments.sort((a, b) => {
    return isAfter(a.parentComment.createdOn, b.parentComment.createdOn) ? 1 : -1;
  });

  return (
    <>{sorted.map(m => {
      const isNew = !m.parentComment.id;

      return (
        <Fragment key={m.parentComment.identifier}>
          {isNew &&
            <NewComment
              className={styles.item}
              identifier={m.parentComment.identifier}
              highlight={highlight} />}
          {!isNew &&
            <ParentComment
              className={styles.item}
              item={m} />}
        </Fragment>
      );
    })}
    </>
  );
};