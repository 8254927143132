import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSelectUser, useSelectPayoutSettings } from '@containers/Store/hooks';
import * as enums from '@/enums';
import { selectHasTipaltiPayoutAccount } from '@store/selectors';
import Button from '@/components/Button';
import { Check } from '@/components/icons';
import { PayeeDashboard, OnboardingPayee } from '@/components/Tipalti';
import styles from './style/Settings.UserBilling.ReceivePayouts.css';

export default function ReceivePayouts() {
  const hasPayoutAccount = useSelector(selectHasTipaltiPayoutAccount);

  return (
    <div>
      {hasPayoutAccount
        ? <Existing />
        : <Setup />}
    </div>
  );
}

const Setup = () => {
  const [show, setShow] = useState<boolean>(false);
  const user = useSelectUser();

  const handleComplete = useCallback(() => setShow(false), []);

  return (
    <div className={styles.content}>
      <div className={styles.text}>Set up a payout account to receive</div>
      <div className={styles.text}>compensation for projects you engage in.</div>
      {show
        ? (
          <div className={styles.text} style={{ marginTop: '20px' }}>
            <OnboardingPayee
              userId={user.id}
              onComplete={handleComplete} />
          </div>
        )
        : null}
      <Button
        className={styles.add}
        variant="brick"
        title="Add Account"
        onClick={() => setShow(true)} />
    </div>
  );
};

const Existing = () => {
  const user = useSelectUser();
  const settings = useSelectPayoutSettings();

  const [editing, setEditing] = useState<boolean>(false);

  return settings.payeeStatus === enums.TipaltiPayeeStatus.Pending
    ? (
      <div className={styles.content}>
        <div className={styles.text} style={{ marginBottom: '10px' }}><Check /></div>
        <div className={styles.text} style={{ marginBottom: '10px' }}>Account setup complete!</div>
        <div className={styles.text} style={{ marginBottom: '10px' }}>Awaiting confirmation from payment provider...</div>
      </div>
    )
    : editing
      ? (
        <div className={styles.content}>
          <PayeeDashboard userId={user.id} />
        </div>
      )
      : (
        <div className={styles.content}>
          <div className={styles.text} style={{ marginBottom: '10px' }}>You have a payment account set up!</div>
          <div className={styles.text}>
            <span>Method:</span> <span style={{ fontFamily: 'var(--font-semibold)' }}>{enums.utils.TipaltiPayeePaymentMethod.getName(settings.paymentMethod)}</span>
          </div>
          <Button
            className={styles.add}
            variant="brick"
            title="Update Details"
            onClick={() => setEditing(true)} />
          <div style={{ marginTop: '20px', fontSize: '12px', color: 'var(--gray-d)', fontStyle: 'italic' }}>Payment changes may take a few minutes to process after updating.</div>
        </div>
      );
};

export { ReceivePayouts };