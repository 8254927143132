import * as Table from '@/components/Table';
import styles from './style/Draggable.css';

type Props = {
  value: number;
};

export const Cell = (props: Props) => {
  return (
    <Table.Body.TD className={styles.ordinal}>
      {props.value}
    </Table.Body.TD>
  );
};

Cell.displayName = 'Column.Ordinal.Cell';

export const Header = (props: unknown) => {
  return (
    <Table.Header.TH className={styles.ordinal}>
      Order
    </Table.Header.TH>
  );
};

Header.displayName = 'Column.Ordinal.Header';