import { useState } from 'react';
import { TranscriptFocusedTagContext } from './Context';

type Props = {
  children: React.ReactNode;
};

export const TranscriptTaggingFocusContainer = (props: Props) => {
  const [focusedTag, setFocusedTag] = useState<string>(null);

  return (
    <TranscriptFocusedTagContext.Provider value={[focusedTag, setFocusedTag]}>
      {props.children}
    </TranscriptFocusedTagContext.Provider>
  );
};

TranscriptTaggingFocusContainer.displayName = 'Transcript.Tagging.Focus.Container';