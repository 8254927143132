import { Website } from '@consts/path';
import { cx } from '@utils';
import { Subtitle } from './Layout';
import styles from './style/Layout.css';

type Props = {
  className?: string;
};

export const PrivacyPolicy = (props: Props) => {
  return (
    <div className={cx(styles.footer, props.className)}>
      <Subtitle>By signing up, you agree to our</Subtitle>
      <Subtitle>
        <a
          href={Website.Terms.Root}
          rel="noreferrer"
          target="_blank">
          Terms and Privacy Policy
        </a>
      </Subtitle>
    </div>
  );
};