import { useMemo } from 'react';
import {
  useAccountProfileNewEntry,
  useAccountProfileEditState,
  useProfileState,
} from '@containers/AccountProfile';
import { useSelectUser } from '@containers/Store/hooks';
import { ProfileEntry } from '@enums';
import { getLocationFor, slugify } from '@utils';
import { AddProfileEntryButton, ProfileSectionEntries } from '@/components/UserProfile';
import { Button } from '@/components/Button';
import { AnimatedLoader } from '@/components/ActivityIndicator';
import styles from './style/EmploymentCheckup.css';

type Props = unknown;

const EmploymentCheckup = (props: Props) => {
  const [state] = useProfileState();
  const [entryId] = useAccountProfileEditState();
  const [_, addNewEntry] = useAccountProfileNewEntry(ProfileEntry.Employment);
  const me = useSelectUser();

  const location = useMemo(() => {
    const slug = slugify({
      id: me.id,
      name: me.profile.fullname,
    });

    return getLocationFor.me.profile({
      slug,
      userId: me.id,
    });
  }, [me.id, me.profile.fullname]);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>
      </div>

      {!state?.employment &&
        <AnimatedLoader
          className={styles.loader}
          color="var(--sec-01)" />}

      {state?.employment &&
        <div className={styles.main}>
          <ProfileSectionEntries
            entryClassName={styles.sectionEntry}
            type={ProfileEntry.Employment} />
          <AddProfileEntryButton onClick={addNewEntry}>
            Add Position
          </AddProfileEntryButton>

          <div className={styles.footer}>
            <Button
              className={styles.btn}
              color="primary"
              disabled={!!entryId}
              to={location}
              variant="brick">
              Complete
            </Button>
          </div>
        </div>}
    </div>
  );
};

const title = `Work Experience`;
const subtitle = `Please provide your work experience to fill out your profile.`;

export { EmploymentCheckup };
export default EmploymentCheckup;