import { Fragment, useCallback, useMemo } from 'react';
import { SurveyQuestionType } from '@enums';
import { parseSurveyRichText } from '@/containers/Survey/utils/rich-text';
import { useProjectSurveyResponsesState } from '@containers/SurveyResponses/hooks/useProjectSurveyResponsesState';
import { SurveyResponsesHeader } from '@presentation/ProjectSurveyResponses';
import { formatSurveyNumber } from '@utils/survey';
import { MatrixSliderQuestion } from '@/types';
import * as survey from '@containers/Survey/utils';
import ResponseMatrixSliderChart from './MatrixSlider.Chart';
import AggregateData from './MatrixSlider.Data.Aggregate';
import RowData from './MatrixSlider.Data.Row';
import { SegmentedDataTooltip } from './SegmentedDataTooltip';
import { useOptionsColors } from './hooks/usePaletteColors';
import { MatrixSliderItem } from './interfaces';

type Props = {
  question: MatrixSliderQuestion.Question;
  responses: MatrixSliderQuestion.Aggregate.Data;
};

export const ResponseMatrixSlider = (props: Props) => {
  const {
    query,
    resetQueryState,
    setQueryState,
  } = useProjectSurveyResponsesState<SurveyQuestionType.Sliders>();

  const handleRowClick = useCallback((rowId: number) => {
    setQueryState({
      rowId,
    });
  }, [setQueryState]);

  const handleBucketClick = useCallback((rowId: number) => (key: string) => {
    setQueryState({
      rowId,
      key,
    });
  }, [setQueryState]);

  const reversed = useMemo(() => survey.questions.isSliderReversed(props.question.settings), [props.question.settings]);

  const colors = useOptionsColors();

  const items: MatrixSliderItem[] = useMemo(() => {

    return [...props.question.matrixRows]
      .map((r, i) => {
        const data = props.responses.rows[r.base.id];
        const displayAvg = formatSurveyNumber(data.avg, props.question.settings.numberSettings);
        const name = parseSurveyRichText(r.value);

        return {
          avg: data.avg,
          displayAvg,
          color: colors[i],
          id: r.base.id,
          name,
          responses: data.responses,
          userIds: data.userIds,
          [r.base.id]: data.avg,
        };
      })
      .sort((a, b) => {
        return reversed
          ? a.avg - b.avg
          : b.avg - a.avg;
      });
  }, [
    colors,
    props.question.matrixRows,
    props.responses,
    props.question.settings.numberSettings,
    reversed,
  ]);

  const renderData = useCallback(() => {
    if (query.rowId) {
      return (
        <RowData
          onClose={resetQueryState}
          onSelect={handleBucketClick(query.rowId)}
          question={props.question}
          item={items.find(f => f.id === query.rowId)}
          selectedKey={query.key} />
      );
    } else {
      return (
        <AggregateData
          items={items}
          onClick={handleRowClick} />
      );
    }
  }, [
    query,
    resetQueryState,
    handleRowClick,
    handleBucketClick,
    items,
    props.question,
  ]);

  return (
    <Fragment>
      <SurveyResponsesHeader
        question={props.question}>
        <SegmentedDataTooltip questionIdentifier={props.question.base.identifier} />
      </SurveyResponsesHeader>
      <ResponseMatrixSliderChart
        question={props.question}
        items={items}
        reversed={reversed}
        onClick={handleRowClick} />
      {renderData()}
    </Fragment>
  );
};

export default ResponseMatrixSlider;