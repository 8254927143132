import { useCallback, useContext, useMemo, useState } from 'react';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import Popper from '@mui/material/Popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import * as API from '@api/interfaces';
import { WorkspaceObjectRole, WorkspaceObjectType, utils } from '@enums';
import { ButtonActivityIndicator, ButtonOutlined } from '@presentation';
import { cx } from '@utils';
import { PopperMenu, PopperMenuItem } from '@/components/Popper';
import { Modal, type ModalProps } from '@/components/Modal/Modal';
import { AccessRequestContext } from './Context';
import styles from './style/Modal.ApproveAccess.css';

type Props = {
  loading: boolean;
  onSubmit: (params: API.Access.UpdateRequestApproval.Request) => Promise<void>;
} & Pick<ModalProps, 'onClose' | 'open'>;

export const ApproveAccess = (props: Props) => {
  const ctx = useContext(AccessRequestContext);
  const [roleId, setRoleId] = useState<RoleType>(WorkspaceObjectRole.Viewer);

  const handleSubmit = useCallback(() => {
    props.onSubmit({
      objectId: ctx.object.id,
      requestId: ctx.request.id,
      roleId,
    })
    .then(() => props.onClose());
  }, [
    ctx.object.id,
    ctx.request.id,
    props,
    roleId,
  ]);

  return (
    <Modal
      disableOverlayClick={props.loading}
      onClose={props.onClose}
      open={props.open}>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.header}>Approve request?</div>
          <div className={styles.main}>
            <div className={styles.subtitle}>{`${ctx.user.name} will receive access to ${ctx.object.name}.`}</div>
            <div className={styles.permission}>
              <div className={styles.label}>Role:</div>
              <ObjectRolePopper
                onChange={setRoleId}
                value={roleId} />
            </div>
          </div>
          <div className={styles.footer}>
            <ButtonActivityIndicator
              className={styles.btn}
              loading={props.loading}
              onClick={handleSubmit}>
              Approve
            </ButtonActivityIndicator>
            <ButtonOutlined
              className={styles.btn}
              disabled={props.loading}
              color="silver"
              onClick={props.onClose}>
              Cancel
            </ButtonOutlined>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ApproveAccess.displayName = 'Modal.ApproveAccess';

type RoleType = API.Access.UpdateRequestApproval.Request['roleId'];

type ObjectRolePopperProps = {
  onChange: (value: RoleType) => void;
  value: RoleType;
};

const ObjectRolePopper = (props: ObjectRolePopperProps) => {
  const ctx = useContext(AccessRequestContext);

  const roles = useMemo<RoleType[]>(() => {
    if (ctx.object.typeId === WorkspaceObjectType.Folder) {
      return [WorkspaceObjectRole.Viewer];
    } else {
      return [
        WorkspaceObjectRole.Collaborator,
        WorkspaceObjectRole.Viewer,
      ];
    }
  }, [
    ctx.object.typeId,
  ]);

  return (
    <PopupState
      variant="popper"
      popupId="user-object-access-popper">
      {popupState => (
        <div>
          <div {...bindToggle(popupState)}>
            <ButtonOutlined
              className={cx(styles.select, { [styles.open]: popupState.isOpen })}
              color="silver"
              borderWidth="thin"
              onClick={popupState.open}>
              <div>{utils.WorkspaceObjectRole.getName(props.value)}</div>
              <div className={styles.chevron} />
            </ButtonOutlined>
          </div>
          <Popper
            {...bindPopper(popupState)}
            placement="bottom-end"
            className={styles.popper}>
            <ClickAwayListener
              onClickAway={popupState.close}>
              <PopperMenu className={styles.menu}>
                {roles.map(role => (
                  <PopperMenuItem
                    key={role}
                    onClick={() => {
                      props.onChange(role);
                      popupState.close();
                    }}>
                    {utils.WorkspaceObjectRole.getName(role)}
                  </PopperMenuItem>
                ))}
              </PopperMenu>
            </ClickAwayListener>
          </Popper>
        </div>
      )}
    </PopupState>
  );
};

ObjectRolePopper.displayName = 'Modal.ApproveAccess.ObjectRolePopper';