import { shallowEqual, useSelector } from 'react-redux';

const selectGroup = (state: Store.State) => state.group;

const useSelectGroup = () => {
  const group = useSelector<Store.State, Store.Group>(selectGroup, shallowEqual);

  return group;
};

export { useSelectGroup };
export default useSelectGroup;