import { createNamedContext } from '@utils';
import * as C from './interfaces';

export const TranscriptCommentsStateContext = createNamedContext<C.CommentsStateContextValue>(undefined, 'TranscriptCommentsStateContext');
export const TranscriptCommentsRefPointContext = createNamedContext<C.TranscriptCommentsRefPoint>(undefined, 'TranscriptCommentsRefPointContext');
export const TranscriptCommentActionsContext = createNamedContext<C.CommentActions>(undefined, 'TranscriptCommentActionsContext');
export const TranscriptCommentEditingContext = createNamedContext<C.CommentEditing>(undefined, 'TranscriptCommentEditingContxt');
export const TranscriptFocusedCommentContext = createNamedContext<C.FocusedCommentContextValue>(undefined, 'TranscriptFocusedCommentContext');

export const TranscriptChildCommentContext = createNamedContext<C.CommentContextValue>(undefined, 'TranscriptChildCommentContext');
export const TranscriptCommentReplyVisibilityContext = createNamedContext<C.CommentReplyVisibilityContextValue>(undefined, 'TranscriptCommentReplyVisibilityContext');
export const TranscriptCommentReplyActionsVisibilityContext = createNamedContext<C.CommentReplyActionsVisibilityContextValue>(undefined, 'TranscriptCommentReplyActionsVisibilityContext');
export const TranscriptParentCommentIdentifiersContext = createNamedContext<C.ParentCommentIdentifiers>(undefined, 'TranscriptParentCommentIdentifiersContext');