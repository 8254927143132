import { useCallback, useRef, useState } from 'react';
import cuid from 'cuid';
import { SurveyLogicRule } from '@enums';
import type { SurveyLogic } from '@/types';
import { useSubmitSurveyDraft, useSurveyBuilderState } from '@/containers/SurveyBuilder/hooks';

export function generateDefaultItem(): SurveyLogic.Item {
  const identifier = cuid();
  return {
    action: {
      type: null,
    },
    conditions: [],
    id: null,
    identifier,
    metadata: {
      canDelete: true,
      canEdit: true,
    },
    rule: {
      expression: null,
      type: SurveyLogicRule.AllOfTheAbove,
    },
  };
}

type Props = {
  generateNewItem?: () => SurveyLogic.Item;
  testItemRelevancy: (item: SurveyLogic.Item) => boolean;
};

export const useLogicBuilderProps = ({
  generateNewItem = generateDefaultItem,
  testItemRelevancy,
}: Props) => {
  const [state, dispatch] = useSurveyBuilderState();
  const [editing, setEditing] = useState<{
    identifier: string;
    isNew: boolean;
  }>({ identifier: null, isNew: false });
  const submitDraft = useSubmitSurveyDraft();

  const initialItems = useRef<SurveyLogic.Item[]>(state.survey.logic.filter(testItemRelevancy));
  const [items, setItems] = useState<SurveyLogic.Item[]>(initialItems.current);

  const handleRemoveItem = useCallback((identifier: string) => {
    setItems(items => items.filter(f => f.identifier !== identifier));
  }, []);

  const handleToggleEditingOn = useCallback((identifier: string) => {
    setEditing({ identifier, isNew: false });
  }, []);

  const handleToggleEditingOff = useCallback((identifier: string) => {
    if (editing.isNew) {
      setItems(items => items.filter(f => f.identifier !== identifier));
    }

    setEditing({ identifier: null, isNew: false });
  }, [editing.isNew]);

  const handleAddItem = useCallback(() => {
    const newItem = generateNewItem();
    setItems(items => items.concat(newItem));

    setEditing({
      identifier: newItem.identifier,
      isNew: true,
    });
  }, [generateNewItem]);

  const handleSave = useCallback((item: SurveyLogic.Item) => {
    setItems(items => {
      return items.map(x => {
        return x.identifier === item.identifier
          ? item
          : x;
      });
    });
    setEditing({ identifier: null, isNew: false });
  }, []);

  const handleSubmit = useCallback(() => {
    const added = items.filter(s => !initialItems.current.some(ss => ss.identifier === s.identifier));

    const merged = (state.survey.logic.reduce<SurveyLogic.Item[]>((acc, x) => {

      const updated = items.find(f => f.identifier === x.identifier);

      if (updated) {
        return acc.concat(updated);
      }

      const removed = initialItems.current.some(s => s.identifier === x.identifier);

      if (removed) {
        return acc;
      }

      return acc.concat(x);

    }, [])).concat(added);

    dispatch({
      type: 'logic-items-updated',
      payload: {
        items: merged,
      },
    });

    submitDraft();
  }, [
    items,
    dispatch,
    state.survey.logic,
    submitDraft,
  ]);

  const handleCloneItem = useCallback((identifier: string) => {
    const newItem = generateNewItem();

    setItems(items => {
      const item = items.find(x => x.identifier === identifier);

      const cloned = {
        ...newItem,
        action: item.action,
        conditions: item.conditions.map(x => {
          return {
            ...x,
            identifier: cuid(),
          };
        }),
        metadata: item.metadata,
        rule: item.rule,
      };

      return items.concat(cloned);
    });

    setEditing({
      identifier: newItem.identifier,
      isNew: true,
    });
  }, [generateNewItem]);

  return {
    addItem: handleAddItem,
    cloneItem: handleCloneItem,
    removeItem: handleRemoveItem,
    saveItem: handleSave,
    toggleEditingOn: handleToggleEditingOn,
    toggleEditingOff: handleToggleEditingOff,
    handleSubmit,
    items,
    editing,
  };
};