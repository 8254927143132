import { useCallback, useMemo } from 'react';
import { canJoinConference } from '@containers/Scheduler/utils.calls';
import * as Users from '@api/interfaces/users';
import { getLocationFor } from '@utils';

type Params = {
  call: Pick<Users.GetUpcomingCalls.Item['call'], 'id' | 'statusId' | 'timeStart' | 'timeEnd'>;
  conference: Pick<Users.GetUpcomingCalls.Item['conference'], 'identifier'>;
};

export const useJoinConference = (item: Params) => {
  const canJoin = useMemo(() => canJoinConference(item.call), [item]);

  const handleClick = useCallback(() => { }, []);

  const location = getLocationFor.call.conference(item?.conference?.identifier);

  return [canJoin, {
    onClick: handleClick,
    to: location,
  }] as const;
};

export default useJoinConference;