import extend from '@enums/extend';

export enum ReviewOption {
  Disabled          = 1,
  EnabledForAll     = 2,
  EnabledForFlagged = 3,
}

const map = new Map([
  [ReviewOption.Disabled,    { name: 'Disabled' }],
  [ReviewOption.EnabledForAll, { name: 'Enabled for all' }],
  [ReviewOption.EnabledForFlagged, { name: 'Enabled for flagged' }],
]);

export default {
  ReviewOption: extend(map),
};