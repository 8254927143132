import { useSurveyThemingPalette } from '@containers/Branding/hooks/useSurveyThemingPalette';
import { cx } from '@utils';
import type { SurveyRichText } from '@/types/survey.rich-text';
import { SurveyQuestionText, BuilderSurveyQuestionText } from '@/components/Survey.RichText';
import { SurveyQuestionNumber, SurveySectionNumber } from './QuestionNumber';
import type {
  BuilderQuestionTextProps,
  QuestionTextProps,
  SurveySectionTextProps,
  QuestionTextBaseProps,
  CaptureSheetLabelProps,
} from './interfaces';
import styles from './style/QuestionText.css';

export const QuestionRawText = (props: QuestionTextProps<string>) => {

  return (
    <QuestionTextBase
      ordinal={props.item.ordinal}
      className={props.className}
      textClassName={props.textClassName}>
      {props.item.value}
      {props.children}
    </QuestionTextBase>
  );
};

export const QuestionText = (props: QuestionTextProps<SurveyRichText.RichTextValue>) => {
  return (
    <QuestionTextBase
      ordinal={props.item.ordinal}
      className={props.className}>
      <SurveyQuestionText
        editorClassName={cx(styles.questionTextEditor, props.textClassName)}
        value={props.item.value} />
      {props.children}
    </QuestionTextBase>
  );
};

export const BuilderQuestionText = (props: BuilderQuestionTextProps<SurveyRichText.RichTextValue>) => {
  return (
    <QuestionTextBase
      ordinal={props.item.ordinal}
      className={props.className}>
      {props.item.captureSheetLabel &&
        <CaptureSheetLabel label={props.item.captureSheetLabel} color={props.item.captureSheetColor} />
      }
      <BuilderSurveyQuestionText
        editorClassName={props.textClassName}
        value={props.item.value}
        identifier={props.item.identifier} />
      {props.children}
    </QuestionTextBase>
  );
};

const CaptureSheetLabel = (props: CaptureSheetLabelProps) => {
  return <div className={styles.captureSheetLabel} style={props.color ? { backgroundColor: props.color } : null}>{props.label}</div>;
};

export const QuestionTextBase = (props: QuestionTextBaseProps) => {

  const { palette, theme } = useSurveyThemingPalette();
  const style = theme ? { color: palette.primary.main } : {};

  return (
    <div className={cx(styles.root, props.className)}>
      <SurveyQuestionNumber
        ordinal={props.ordinal}
        style={style} />
      <div className={cx(styles.value, props.textClassName)}>
        {props.children}
      </div>
    </div>
  );
};

export const SurveySectionText = (props: SurveySectionTextProps) => {
  return (
    <div className={cx(styles.root, props.className)}>
      <SurveySectionNumber
        ordinal={props.item.ordinal} />
      <div className={styles.value}>
        {props.item.name}
      </div>
    </div>
  );
};