import queryString from 'query-string';
import http from '@services/http';

export const getSignature = data => {
  return http.get(`/users/signature?${queryString.stringify(data)}`);
};

export const sendReferral = data => {
  return http.post(`/users/send-referral`, data);
};

export const syncContacts = data => {
  return http.post(`/users/sync/contacts`, data);
};

export const syncCalendar = data => {
  return http.post(`/users/sync/calendar`, data);
};