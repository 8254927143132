import Slider from './Slider';
import { RateSliderProps } from './interfaces';
import styles from './style/HourlyRate.css';

export const HourlyRateSlider = ({
  minimumRate,
  maximumRate,
  ...sliderProps
}: RateSliderProps) => {
  return (
    <div className={styles.root}>
      <Slider
        maxValue={maximumRate}
        minValue={minimumRate}
        {...sliderProps} />
      <div>
        <div className={styles.min}>{`$${minimumRate}`}</div>
        <div className={styles.max}>{`$${maximumRate}`}</div>
      </div>
    </div>
  );
};

export default HourlyRateSlider;