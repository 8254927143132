import { useContext, useEffect } from 'react';
import Popper from '@mui/material/Popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { bindPopper, PopupState } from 'material-ui-popup-state/hooks';
import { WorkspaceGlobalSearchBarResetContext, WorkspaceGlobalSearchBarQueryContext } from './Context';
import Filters from './Filters';
import Form from './Form';
import Results from './Results';
import styles from './style.css';

export default function PopperContent({ popupState }: PopperContentProps) {
  const { clickedAwayReset } = useContext(WorkspaceGlobalSearchBarResetContext);
  const { barRefs } = useContext(WorkspaceGlobalSearchBarQueryContext);

  useEffect(() => {
    if (barRefs.current.form && popupState.anchorEl !== barRefs.current.form) {
      popupState.setAnchorEl(barRefs.current.form);
    }
  }, [popupState, barRefs]);

  return (
    <ClickAwayListener onClickAway={clickedAwayReset}>
      <div>
        <Form />
        <Popper
          {...bindPopper(popupState)}
          placement="bottom-start"
          modifiers={[{
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          }]}>
          <div className={styles.container}>
            <div className={styles.content}>
              <div className={styles.results}>
                <Results />
              </div>
              <Filters />
            </div>
          </div>
        </Popper>
      </div>
    </ClickAwayListener>
  );
}

export { PopperContent as Popper };

type PopperContentProps = {
  popupState: PopupState;
};