import { useContext } from 'react';
import { useHasInternalAdminRole } from '@containers/User/hooks';
import { MedicalBackgroundContactContext } from '@containers/MedicalOnboarding/Context';
import { ContactMethod } from '@enums';

type Params = {
  phone: {
    empty: boolean;
    valid: boolean;
  };
  phoneAlternate: {
    empty: boolean;
    valid: boolean;
  };
};

export const useBackgroundContactValidation = ({ phone, phoneAlternate }: Params) => {
  const contact = useContext(MedicalBackgroundContactContext);

  const admin = useHasInternalAdminRole();

  return {
    meta: {
      smsConsent: {
        visible: (contact.form.contactMethod === ContactMethod.Text || contact.form.reminderMethod === ContactMethod.Text),
      },
    },
    errors: {
      contactMethod: !admin && !contact.form.contactMethod,
      phone: !admin && !(phone.valid && !phone.empty),
      phoneAlternate: !admin && !phoneAlternate.valid && !phoneAlternate.empty,
      reminderMethod: !admin && !contact.form.reminderMethod,
      timezone: !admin && !contact.form.timezone,
      smsConsent: !admin && !contact.form.smsConsent && (contact.form.contactMethod === ContactMethod.Text || contact.form.reminderMethod === ContactMethod.Text),
      inPersonResearch: !admin && !contact.form.inPersonResearch,
      virtualResearch: !admin && !contact.form.virtualResearch,
      surveyResearch: !admin && !contact.form.surveyResearch,
      phoneResearch: !admin && !contact.form.phoneResearch,
    },
  };
};