import styles from './style/Badge.css';

type Props = unknown;

export const BadgeExternal = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          External
        </div>
      </div>
    </div>
  );
};

BadgeExternal.displayName = 'Badge.External';