import { Info } from 'react-feather';
import { cx } from '@utils';
import { Tooltip } from '@presentation/Tooltip';
import { InfoTooltipProps } from './interfaces';
import styles from './style/InfoTooltip.css';

type Props =
  InfoTooltipProps;

export const InfoTooltip = (props: Props) => {
  const root = cx(styles.root, props.className);
  const icon = cx(styles.icon, props.iconStyle);

  const placement = props.placement
                    ? props.placement
                    : `right`;

  const tooltip = {
    padding: 15,
    ...props.tooltipStyle,
  };

  return (
    <div className={root}>
      <Tooltip
        title={props.text}
        placement={placement}
        tooltipstyles={tooltip}>
        <div className={styles.wrapper}>
          <Info className={icon} />
        </div>
      </Tooltip>
    </div>
  );
};