import { memo } from 'react';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoneIcon from '@mui/icons-material/Done';
import HourglassTopOutlinedIcon from '@mui/icons-material/HourglassTopOutlined';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import { TranscriptionRevisionStatus, utils } from '@enums';
import { cx } from '@utils';
import { Tooltip } from '@/presentation/Tooltip';
import styles from './style/CallItem.EnhancementStatus.css';

type Props = {
  className?: string;
  statusId: TranscriptionRevisionStatus;
};

export const EnhancementStatus = memo((props: Props) => {
  const Icon = iconmap[props.statusId];

  if (!Icon) return null;

  const classnames = cx(styles.main, {
    [styles.completed]: props.statusId === TranscriptionRevisionStatus.Completed,
  });

  const tooltipValue = utils.TranscriptionRevisionStatus.getName(props.statusId);

  return (
    <div className={cx(styles.root, props.className)}>
      <div className={styles.wrap}>
        <div className={classnames}>
          <Tooltip title={tooltipValue}>
            <Icon classes={{ root: styles.icon }} />
          </Tooltip>
        </div>
      </div>
    </div>
  );
});

EnhancementStatus.displayName = 'CallItem.Enhancement.Status';

const iconmap = {
  [TranscriptionRevisionStatus.Completed]: CheckCircleIcon,
  [TranscriptionRevisionStatus.InProgress]: ModelTrainingIcon,
  [TranscriptionRevisionStatus.InQueue]: HourglassTopOutlinedIcon,
};

export const VisibilityStatus = (props: Props) => {
  const visible = props.statusId === TranscriptionRevisionStatus.Completed || !props.statusId;

  const Icon = visible
    ? DoneIcon
    : AutoModeIcon;

  const tooltipValue = visible
    ? `Available`
    : `Processing`;

  return (
    <div className={cx(styles.root, props.className)}>
      <div className={styles.wrap}>
        <div className={cx(styles.main, styles.visibility)}>
          <Tooltip title={tooltipValue}>
            <Icon classes={{ root: styles.icon }} />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

VisibilityStatus.displayName = 'CallItem.Visibility.Status';