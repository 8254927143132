import { useMemo } from 'react';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import { MenuProps } from '@mui/material/Menu';
import { styled } from '@mui/material/styles';
import MUISelect, { SelectProps } from '@mui/material/Select';
import { cx } from '@utils';
import { DescriptorLike } from '$admin/components/Select';
import styles from '$admin/components/Select/style/Select.css';

type Props = {
  className?: string;
  defaultValue?: string;
  id?: string;
  isInvalid?: boolean;
  name?: string;
  onChange: SelectProps['onChange'];
  options: DescriptorLike[];
  value: string;
  placeholder?: string;
};

export const Input = (props: Props) => {
  const CustomMenuProps: Partial<MenuProps> = useMemo(() => ({
    classes: {
      paper: cx(styles.root, styles.paper),
      list: cx(styles.root, styles.options),
    },
    elevation: 0,
    MenuListProps: {
      classes: {
        root: cx(styles.root, styles.menu),
      },
    },
    PopoverClasses: {
      root: cx(styles.root, styles.options),
    },
    transitionDuration: 80,
    disableScrollLock: true,
  }), []);

  const className = cx(styles.select, styles.input, props.className);

  return (
    <MUISelect
      className={className}
      classes={{
        select: cx(styles.select, {
          [styles.placeholder]: !props.value,
          [styles.invalid]: props.isInvalid,
        }, styles.root),
      }}
      displayEmpty
      MenuProps={CustomMenuProps}
      defaultValue={props.defaultValue}
      id={props.id}
      input={<SelectInput />}
      name={props.name}
      onChange={props.onChange}
      renderValue={value => {
        return !value ? (props.placeholder || 'None') : props.options.find(x => {
          return typeof x.id === 'number'
            ? x.id === +value
            : x.id === value;
        })?.name;
      }}
      value={props.options.length ? props.value : ''}>
      {props.options.map(x =>
        <MenuItem
          style={{ minHeight: 36 }}
          key={x.id}
          value={String(x.id)}>
          {x.name}
        </MenuItem>)}
    </MUISelect>
  );
};

Input.displayName = 'Select.Input';

export const SelectInput = styled(InputBase)(({ theme }) => ({
  [`& .MuiSelect-root`]: {
    backgroundColor: 'var(--pri-01)',
    border: '2px solid var(--gray-l)',
    borderRadius: 4,
    color: 'var(--black-l)',
    fontFamily: 'var(--font-reg)',
    fontSize: 16,
    padding: '8px 26px 8px 12px',
    position: 'relative',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      backgroundColor: 'var(--pri-01)',
      borderColor: 'var(--gray-l)',
      borderRadius: 4,
      boxShadow: 'none',
    },
  },
}));