import { ReactNode } from 'react';
import { ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material/styles';
import { ChatSkin } from '@/brand-insights/enums';
import { ChatSkinContext } from '@/brand-insights/components/BrandInsights/context';
import { standaloneTheme } from './theme';

type Props = {
  children: ReactNode;
};

export const StandaloneThemeContainer = ({ children }: Props) => {

  // TODO: Create separate theme
  const muiTheme = createTheme(standaloneTheme);

  return (
    <ChatSkinContext.Provider value={ChatSkin.Standalone}>
      <MUIThemeProvider theme={muiTheme}>
        {children}
      </MUIThemeProvider>
    </ChatSkinContext.Provider>
  );
};