import { useState } from 'react';
import { usePopper } from 'react-popper';

type Options = Parameters<typeof usePopper>[2];

export const usePopperState = (options: Options) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, options);

  return {
    styles, attributes, referenceElement, setReferenceElement, popperElement, setPopperElement, open, setOpen,
  };
};