import { useTemplateClientModal } from '@/components/Survey.Templates/Modal.Client';
import { TemplateItemLabel } from './Label.Base';

type Props = {
  className?: string;
  disabled: boolean;
  value: string;
};

export const TemplateClient = ({ className, disabled, value }: Props) => {

  const [toggleModal, Modal] = useTemplateClientModal();

  return (
    <>
      <TemplateItemLabel
        className={className}
        disabled={disabled}
        label={value}
        tooltip="Data Controller"
        onClick={toggleModal} />
      {!disabled && <Modal />}
    </>
  );
};