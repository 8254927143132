import { useCallback } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder';
import { useQuestionBuilderItem } from '@containers/SurveyBuilder.Question';
import type { SurveySettings } from '@/types/survey';
import { CheckboxLabel } from '@/components/Checkbox';

type Props = {
  className?: string;
  disabled?: boolean;
};

export const OrderOptionsBasedOnSource = ({
  className,
  disabled,
}: Props) => {

  const item = useQuestionBuilderItem();

  const [_, dispatch] = useSurveyBuilderState();

  const orderOptionsBasedOnSource = (item.settings as SurveySettings.OrderOptionsBasedOnSource).orderOptionsBasedOnSource;

  const handleToggle = useCallback((e: React.ChangeEvent) => {
    dispatch({
      type: 'reuse-options-order-updated',
      payload: {
        questionIdentifier: item.base.identifier,
        value: !orderOptionsBasedOnSource,
      },
    });
  }, [
    dispatch,
    item.base.identifier,
    orderOptionsBasedOnSource,
  ]);

  return (
    <div className={className}>
      <CheckboxLabel
        disabled={disabled}
        checked={orderOptionsBasedOnSource}
        label="Order options based on source"
        onChange={handleToggle} />
    </div>
  );
};