import { useCallback, useContext } from 'react';
import { ProjectHonorariaContainer, ProjectHonorariaForm } from '$admin/components/Project.Honoraria';
import { ProjectCreationSetupContext } from './Context';
import { Setup as S } from './interfaces';

type Props = unknown;

export const Honoraria = (props: Props) => {
  const { form, setValue } = useContext(ProjectCreationSetupContext);

  const handleChange = useCallback((values: { honoraria: S.FormState['honoraria']['items'] }) => {
    setValue({
      honoraria: {
        items: values.honoraria,
      },
    });
  }, [setValue]);

  return (
    <ProjectHonorariaContainer items={form.honoraria.items}>
      <ProjectHonorariaForm onChangeValues={handleChange} />
    </ProjectHonorariaContainer>
  );
};

Honoraria.displayName = 'Field.Setup.Honoraria';