import { useCallback } from 'react';
import { useMessageTestingData, useSubmitSurveyDraft } from '@containers/SurveyBuilder/hooks';
import { GrayOutlineButton } from '@presentation/Buttons';
import { SurveyRichText } from '@/types';
import { useToggle } from '@/utils';
import { Button } from '@/components/Button';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import ModalHeader from '@/components/Modal/Header';
import { Toolbar } from '@/components/SurveyBuilder.Message/Toolbar';
import { useModal } from '@/components/Modal/hooks';
import { SurveyRichTextEditor } from '@/components/Survey.RichText/Editor';
// eslint-disable-next-line import/no-cycle
import { TemplateTPPRichTextContainer } from './Input.TPP.RichText';
import styles from './style/Modal.Stimulus.css';
import { useCanSaveRichTextTpp, useSubmitRichTextTPP } from './hooks/tpp-rt';

type Props = {
  tpp: SurveyRichText.RootNode;
} & Pick<ModalProps,
    'open' |
    'onClose'>;

const TemplateTPPRichTextModal = ({
  onClose,
  open,
  tpp,
}: Props) => {

  return (
    <Modal
      classes={{
        root: styles.modalWrap,
      }}
      open={open}
      onClose={onClose}>

      {/* <ModalHeader text="TPPRichText" /> */}

      <div className={styles.body}>
        <TemplateTPPRichTextContainer
          initialValue={tpp}
          editable={false}>
          <SurveyRichTextEditor />
        </TemplateTPPRichTextContainer>
      </div>

    </Modal>
  );
};

type EditableProps = {
  canEdit: boolean;
  editing: boolean;
  toggleEditing: () => void;
} & Pick<ModalProps,
    'open' |
    'onClose'>;

const EditableTemplateTPPRichTextModal = ({
  canEdit,
  editing,
  onClose,
  open,
  toggleEditing,
}: EditableProps) => {

  const onSubmit = useSubmitRichTextTPP();
  const submitDraft = useSubmitSurveyDraft();
  const canSubmit = useCanSaveRichTextTpp();

  const handleSubmit = useCallback(async () => {
    await onSubmit();
    submitDraft();
    toggleEditing();
  }, [toggleEditing, onSubmit, submitDraft]);

  return (
    <Modal
      classes={{
        root: styles.modalWrap,
      }}
      open={open}
      onClose={onClose}>

      {/* <ModalHeader text="TPPRichText" /> */}

      <div className={styles.body}>
        {canEdit && !editing &&
          <div className={styles.link} onClick={toggleEditing}>
            Edit TPP
          </div>
        }

        {editing &&
          <Toolbar
            disablePiping={true}
            className={styles.toolbar} />}
        <SurveyRichTextEditor />
      </div>

      {editing &&
        <div className={styles.footer}>
          <GrayOutlineButton
            onClick={onClose}
            title="Cancel" />
          <Button.Primary
            className={styles.submit}
            disabled={!canSubmit}
            onClick={handleSubmit}
            title="Submit"
            variant="brick" />
        </div>
      }

    </Modal>
  );
};

type ContainerProps = {
  canEdit: boolean;
} & Pick<ModalProps,
    'open' |
    'onClose'>;

const ModalContainer = (props: ContainerProps) => {

  const [editing, toggleEditing] = useToggle(false);
  const [templateData] = useMessageTestingData();

  return (
    <TemplateTPPRichTextContainer
      initialValue={templateData.tpp}
      editable={editing}>
      <EditableTemplateTPPRichTextModal
        canEdit={props.canEdit}
        editing={editing}
        toggleEditing={props.canEdit ? toggleEditing : null}
        onClose={props.onClose}
        open={props.open} />
    </TemplateTPPRichTextContainer>
  );
};

export const useTemplateTPPModal = () => useModal(TemplateTPPRichTextModal);
export const useEditableTemplateTPPModal = () => useModal(ModalContainer);
