import { useContext } from 'react';
import { ContentFeedContext } from '@containers/ContentFeed/Context';

type Params = unknown;

const useContentFeedState = () => {
  const context = useContext(ContentFeedContext);

  return context;
};

export { useContentFeedState };
export default useContentFeedState;