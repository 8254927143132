import { Fragment, useCallback, useState, useContext } from 'react';
import type { FindExtension } from '@remirror/extension-find';
import { useCommands, useHelpers, useRemirrorContext } from '@remirror/react';
import type { FromToProps } from 'remirror';
import { Fragment as PMFrag } from 'prosemirror-model';
import type { ProsemirrorNode } from '@remirror/pm';
import { buildNormalizedTextTimestampNodes, getTimeRangeForPosRange } from '@containers/Transcript/utils.selection';
import { TranscriptUpdatesContext } from '@containers/Transcript/context';
import type { Timestamp } from '@/types/transcribe.rich-text';
import { MarkType } from '@/types/transcribe.rich-text';
import { FindReplaceContext } from './context';
import type { ReplacementMade } from './interfaces';

type Props = {
  children: React.ReactNode;
};

export const FindReplaceContainer = (props: Props) => {
  const { findRanges } = useHelpers<FindExtension>();
  const commands = useCommands<FindExtension>();
  const remirrorCtx = useRemirrorContext();
  const [state, setState] = useState<FindReplaceState>(initialState);
  const { addCorrections } = useContext(TranscriptUpdatesContext);

  const find = useCallback((indexDiff?: number) => {
    setState(current => {
      const { query, caseSensitive, activeIndex } = current;
      const result = findRanges({
        query,
        caseSensitive,
        activeIndex: activeIndex == null ? 0 : activeIndex + (indexDiff ?? 0),
      });

      return {
        ...current,
        total: result.ranges.length,
        activeIndex: result.activeIndex ?? 0,
        ranges: result.ranges,
      };
    });
  }, [findRanges]);

  const findNext = useCallback(() => find(+1), [find]);
  const findPrev = useCallback(() => find(-1), [find]);
  const findIndex = useCallback((index: number) => find(index - state.activeIndex), [state.activeIndex, find]);

  const stopFind = useCallback(() => {
    setState(initialState());
    commands.stopFind();
  }, [commands]);

  const { commands: rmCommands, chain: rmChain, helpers: rmHelpers, getState } = useRemirrorContext();

  //const [replacements, setReplacements] = useState<ReplacementMade[]>([]);
  const storeReplacement = useCallback((pos: FromToProps) => {
    const originalText = rmHelpers.getTextBetween(pos.from, pos.to);
    const pmState = getState();
    const fromTs = getTimeAttr(pmState.doc.nodeAt(pos.from), 's');
    const toTs = getTimeAttr(pmState.doc.nodeAt(pos.to - 1), 'e');

    if (fromTs && toTs) {
      addCorrections([{
        originalText,
        text: state.replacement,
        ts: {
          start: fromTs,
          end: toTs,
        },
        addToDictionary: false,
      }]);
    }

    function getTimeAttr(node: ProsemirrorNode, type: keyof Pick<Timestamp.Attributes, 'e' | 's'>) {
      const tsMark = node?.marks?.find(m => m.type.name === MarkType.Timestamp);

      if (!tsMark) return null;

      return tsMark.attrs[type] as number;
    }
  }, [state.replacement, getState, rmHelpers, addCorrections]);

  const buildReplacementContent = useCallback((replacement: string, selection: FromToProps) => {
    const state = getState();

    const tsRange = getTimeRangeForPosRange({ ...selection, doc: state.doc });

    const nodes = buildNormalizedTextTimestampNodes(replacement, state.schema, tsRange.start, tsRange.end);

    return nodes;
  }, [getState]);

  const replace = useCallback((): void => {
    const { query, replacement, caseSensitive, activeIndex, ranges } = state;

    storeReplacement(ranges[activeIndex]);

    const frag = PMFrag.from(buildReplacementContent(replacement, ranges[activeIndex]));
    rmCommands.insertNode(frag, {
      selection: {
        from: ranges[activeIndex].from,
        to: ranges[activeIndex].to,
      },
    });

    const isQuerySubsetOfReplacement = caseSensitive
      ? replacement.includes(query)
      : replacement.toLowerCase().includes(query.toLowerCase());

    if (isQuerySubsetOfReplacement) {
      findNext();
    } else {
      find();
    }
  }, [state, rmCommands, buildReplacementContent, storeReplacement, findNext, find]);

  const replaceAll = useCallback((): void => {
    const { replacement, ranges } = state;

    for (const r of [...ranges].reverse()) {
      storeReplacement(r);
      const frag = PMFrag.from(buildReplacementContent(replacement, r));
      rmChain.insertNode(frag, {
        selection: {
          from: r.from,
          to: r.to,
        },
      });
    }

    rmChain.run();

    find();
  }, [state, rmChain, find, buildReplacementContent, storeReplacement]);

  const toggleCaseSensitive = useCallback(() => {
    const { activeIndex, caseSensitive, query } = state;

    const result = findRanges({
      activeIndex: activeIndex == null ? 0 : activeIndex,
      caseSensitive: !caseSensitive,
      query,
    });

    setState(current => {
      return {
        ...current,
        activeIndex: result.activeIndex ?? 0,
        caseSensitive: !current.caseSensitive,
        total: result.ranges.length,
        ranges: result.ranges,
      };
    });
  }, [
    findRanges,
    state,
  ]);

  const setQuery = useCallback((query: string) => {
    const { activeIndex, caseSensitive } = state;

    const result = findRanges({
      activeIndex: activeIndex == null ? 0 : activeIndex,
      caseSensitive,
      query,
    });

    setState(current => {
      return {
        ...current,
        activeIndex: result.activeIndex ?? 0,
        query,
        total: result.ranges.length,
        ranges: result.ranges,
      };
    });
  }, [
    findRanges,
    state,
  ]);

  const setReplacement = useCallback((replacement: string) => {
    setState(state => ({ ...state, replacement }));
  }, []);

  const value = {
    activeIndex: state.activeIndex,
    caseSensitive: state.caseSensitive,
    findNext,
    findPrev,
    findIndex,
    query: state.query,
    ranges: state.ranges,
    replace,
    replaceAll,
    replacement: state.replacement,
    setQuery,
    setReplacement,
    stopFind,
    toggleCaseSensitive,
    total: state.total,
  };

  return (
    <FindReplaceContext.Provider value={value}>
      {props.children}
    </FindReplaceContext.Provider>
  );
};

FindReplaceContainer.displayName = 'FindReplace.Container';

function initialState(): FindReplaceState {
  return {
    query: '',
    replacement: '',
    activeIndex: null,
    total: 0,
    caseSensitive: false,
    ranges: [],
  };
}

type FindReplaceState = {
  activeIndex: number | null;
  caseSensitive: boolean;
  query: string;
  replacement: string;
  total: number;
  ranges: FromToProps[];
};