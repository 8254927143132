import { useFiltersState } from '@containers/UniversalSearch/hooks';
import { useFetchUniversalSearchPagePosts } from '@utils/api';
import { formatPlurality } from '@utils';
import Table from './Search.Table.Posts';
import styles from './style/Tab.css';

const max = 50;

export default function SearchTabPost() {
  const [query] = useFiltersState();
  const posts = useFetchUniversalSearchPagePosts(query.q, max, {
    enabled: !!query.q?.length,
    refetchOnWindowFocus: false,
  });

  const isLoading = posts.isLoading;
  const data = posts.data?.items ?? [];
  const countText = !isLoading
    ? `(${getCountLabel(posts.data?.total ?? 0, 'Post')})`
    : null;
  const pagination = data.length > 25;

  return (
    <div className={styles.root}>
      <Table
        data={data}
        countText={countText}
        pagination={pagination}
        pageSize={25}
        isLoading={isLoading} />
    </div>
  );
}

function getCountLabel(total: number, label: string) {
  return total > max
    ? `+${max} ${formatPlurality(label, max)}`
    : `${total} ${formatPlurality(label, total)}`;
}