import type { ComponentType } from 'react';
import { useMemo } from 'react';
import type { NodeViewComponentProps } from '@remirror/react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks/useSurveyBuilderContext';
import { getResponsePipeNodeText } from '@containers/SurveyBuilder/utils/rich-text';
import type { SurveyRichText } from '@/types/survey.rich-text';
import { BasicRichText } from '@/components/Survey.RichText/ReadOnly.Basic';
import styles from './style/Node.PipedResponse.css';

export const PipedResponse: ComponentType<NodeViewComponentProps> = props => {

  const ctx = useSurveyBuilderState();

  return ctx
    ? <BuilderNode {...props} />
    : <Node {...props} />;
};

function BuilderNode({ node }: NodeViewComponentProps) {

  const [state] = useSurveyBuilderState();

  const text = useMemo(() => {
    return getResponsePipeNodeText((node.attrs as SurveyRichText.PipedResponseNode['attrs']), state.survey);
  }, [node, state]);

  return (
    <span className={styles.root}>{text}</span>
  );
}

function Node({ node }: NodeViewComponentProps) {
  const val = (node.attrs as SurveyRichText.PipedResponseNode['attrs']).text;

  if (typeof val === 'string') {
    return <span className={styles.root}>{val}</span>;
  } else {
    return <BasicRichText value={val} />;
  }
}

export default PipedResponse;
