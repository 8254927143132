import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as QK from '@consts/querykey';
import * as api from '$admin/api';
import { SelectSearchUnderlined } from '$admin/components/Select';
import { XPlatResultItem as Item } from '$admin/api/interfaces/search.users';
import { ModeratorOption } from './interfaces';

type Props = {
  keyword:  string;
  onChange: (val: string) => unknown;
  onSelect: (item: ModeratorOption) => unknown;
};

export const Autosuggest = ({ onChange, onSelect, ...props }: Props) => {
  const query = useQuery([
    QK.Admin.Search.Users.XPlat.Get,
    props.keyword,
  ], () => {
    return api.search.users.xplat({
      keyword: props.keyword,
    });
  }, {
    enabled: !!props.keyword.trim(),
    refetchOnWindowFocus: false,
  });

  const getOptionLabel = useCallback((item: Item) => {
    return `${item.name} (${item.email})`;
  }, []);

  const handleSelect = useCallback((item: ModeratorOption) => {
    onSelect(item);
    onChange('');
  }, [
    onChange,
    onSelect,
  ]);

  return (
    <SelectSearchUnderlined
      getOptionLabel={getOptionLabel}
      getOptionValue={x => x.email}
      inputValue={props.keyword}
      noOptionsMessage={x => x.inputValue?.length ? `No matches found.` : null}
      onChange={handleSelect}
      onInputChange={onChange}
      options={query.data?.items || []}
      placeholder="Name or Email"
      value={null} />
  );
};

Autosuggest.displayName = 'ModeratorSchedulers.Autosuggest';