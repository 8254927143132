import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import * as $api from '@api/projects';
import type { Projects } from '@api/interfaces';

type Props = IProjectId;
type Data = Projects.GetProjectTranscripts.Response['transcripts'];
type QueryKey = readonly [string, Props];

type Options = UseQueryOptions<Data, AxiosError, Data, QueryKey>;

export const useProjectTranscriptsQuery = (props: Props, options?: Options) => {

  const query = useQuery({
    queryKey: [
      `get:projects/transcripts`,
      props.projectId,
    ],
    queryFn: () => {
      return $api.getProjectTranscripts({
        projectId: props.projectId,
      }).then(res => res.transcripts);
    },
    ...options,
  });

  return query;
};
