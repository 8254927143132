import { useContext, useMemo } from 'react';
import { MedicalBackgroundAdditionalExpertiseContext, MedicalBackgroundContext } from '@containers/MedicalOnboarding/Context';
import { useBackgroundAdditionalExpertiseValidation, useOnSubmitAdditionalExpertise } from '@containers/MedicalOnboarding/hooks';
import * as Field from '@/components/MedicalProfile/Field';
import * as Layout from '@/components/MedicalProfile/Layout';
import { Label } from '@/components/MedicalProfile/Label';
import { RadioGroup } from '@/components/MedicalProfile/RadioGroup';
import { BackNavigationProps, NextNavigationProps, OnSuccessHandler } from './interfaces';
import styles from './style/AdditionalExpertise.css';

type Props = {
  back?:      BackNavigationProps;
  next?:      NextNavigationProps;
  onSuccess?: OnSuccessHandler<'additionalExpertise'>;
};

export const AdditionalExpertise = ({ back = {}, next = {}, onSuccess, ...props }: Props) => {
  const ctx = useContext(MedicalBackgroundContext);
  const additionalexpertise = useContext(MedicalBackgroundAdditionalExpertiseContext);

  const handleSubmit = useOnSubmitAdditionalExpertise(data => {
    ctx.mutation.mutate({
      additionalExpertise: {
        boardEligibility: data.boardEligibility,
        description: data.description,
        therapeuticAreas: data.therapeuticAreas,
      },
    }, {
      onSuccess: (data, variables) => {
        onSuccess?.(variables);
      },
    });
  }, [
    ctx.mutation,
    onSuccess,
  ]);

  const { errors } = useBackgroundAdditionalExpertiseValidation();

  const disabled = useMemo(() => {

    return Object.values(errors).some(x => x === true);

  }, [errors]);

  return (
    <Layout.Screen>
      <Layout.Header title={copy.title} />
      <div className={styles.item}>
        <Label required>
          {copy.label.therapeuticAreas}
        </Label>
        <div className={styles.field}>
          <Field.Conditions
            className={styles.input}
            placeholder={copy.placeholder.therapeuticAreas} />
        </div>

      </div>
      <div className={styles.item}>
        <div className={styles.row}>
          <div className={styles.label}>{copy.label.description}</div>
        </div>
        <div className={styles.field}>
          <textarea
            className={styles.input}
            onChange={e => additionalexpertise.setValue({ description: e.target.value })}
            placeholder={copy.placeholder.description}
            value={additionalexpertise.form.description}
            rows={3} />
        </div>

      </div>
      <div className={styles.item}>
        <Label required>
          {additionalexpertise.form.boardEligibility.question.value}
        </Label>
        <div className={styles.field}>
          <RadioGroup
            onChange={optionId => additionalexpertise.setValue({ boardEligibility: optionId })}
            options={additionalexpertise.form.boardEligibility.question.options}
            selected={additionalexpertise.form.boardEligibility.answer} />
        </div>
      </div>
      <Layout.Footer>
        {back && <Layout.BackButton />}
        <Layout.NextButton
          disabled={disabled}
          implicitDisable={false}
          loading={ctx.mutation.isLoading}
          onClick={handleSubmit}>
          {next.text}
        </Layout.NextButton>
      </Layout.Footer>
    </Layout.Screen>
  );
};

AdditionalExpertise.displayName = 'MedicalOnboarding.AdditionalExpertise';

const copy = {
  title: `Provide a bit of information about your areas of expertise to talior our project recommendations.`,
  label: {
    therapeuticAreas: `What are the top 3 therapeutic areas you treat?`,
    description: `Is there any additional information about your practice, area of expertise, or patient focus that you would like to share?`,
  },
  placeholder: {
    therapeuticAreas: `Enter the top 3 therapeutic areas you treat`,
    description: `Enter any other additional information about your practice, area of experise, or patient focus that you would like to share?`,
  },
};