import { memo, useCallback, useContext } from 'react';
import type { CellProps } from 'react-table';
import { ChevronDown, ChevronUp } from 'react-feather';
import { cx } from '@utils';
import { RowExpandedContext } from './Context';
import type { TranscriptsTableItem } from './interfaces';
import styles from './style/Cell.Occurrences.css';

const OccurrencesLimit = 20;

export const Occurrences = memo(({ row: { original: data } }: CellProps<TranscriptsTableItem>) => {

  const [open, setRowOpen] = useContext(RowExpandedContext);

  const toggleRowOpen = useCallback(() => {
    setRowOpen(s => !s);
  }, [setRowOpen]);

  const btnClassName = cx(styles.btn, {
    [styles.open]: open,
  });

  return (
    <div className={styles.root}>
      {data.occurrences > 0 && (
        <button className={btnClassName} onClick={toggleRowOpen}>
          {data.occurrences >= OccurrencesLimit ? `${OccurrencesLimit}+` : data.occurrences}
          {open && <ChevronUp className={styles.chevron} size={15} />}
          {!open && <ChevronDown className={styles.chevron} size={15} />}
        </button>
      )}
      {!data.occurrences && <>None</>}
    </div>
  );
});

export default Occurrences;