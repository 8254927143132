/** @jsxImportSource @emotion/react */
import { memo, useCallback, useState } from 'react';
import { css } from '@emotion/react';
import { Star as StarIcon } from 'react-feather';
import type { Chat } from '@/brand-insights/types';
import { useThemedStyles } from '../Theme';

type Props = {
  className?: string;
  onChange: (value: Chat.Rating) => unknown;
  size?: number;
  value: Chat.Rating;
};

const MaxStars = 5;

export const StarRating = memo(({ className, onChange, size = 20, value }: Props) => {

  const [hoveredRating, setHoveredRating] = useState<Chat.Rating>();

  const handleMouseEnter = useCallback((value: Chat.Rating) => () => {
    setHoveredRating(value);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHoveredRating(undefined);
  }, []);

  const handleClick = useCallback((value: Chat.Rating) => () => {
    onChange(value);
  }, [onChange]);

  return (
    <div className={className}>
      {Array.from(new Array(MaxStars)).map((_, i) => {
        const rating = i + 1 as Chat.Rating;
        const filled = rating <= value;
        const hovering = rating <= hoveredRating;

        return (
          <Star
            key={i}
            filled={filled}
            size={size}
            hovering={hovering}
            onMouseEnter={handleMouseEnter(rating)}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick(rating)} />
        );
      })}
    </div>
  );
});

type StarProps = {
  filled: boolean;
  hovering: boolean;
  size: number;
} & React.DetailedHTMLProps<React.HTMLAttributes<SVGElement>, SVGElement>;

const Star = memo((props: StarProps) => {

  const styles = useStarStyles(props);

  const { filled, hovering, ...iconProps } = props;

  return (
    <StarIcon
      css={styles.root}
      strokeWidth={1.5}
      {...iconProps} />
  );
});

const useStarStyles = (props: StarProps) => {
  return useThemedStyles({
    root: theme => css({
      cursor: 'pointer',
      transition: 'color 0.25s, fill 0.25s',
      transitionTimingFunction: 'cubic-bezier(.25, 1, .5, 1)',
      ...computeStarStyles(props, theme),
    }),
  });
};

function computeStarStyles(props: StarProps, theme: Theme) {
  if (props.filled) {
    return {
      color: theme.palette.primary.light6,
      fill: theme.palette.primary.light6,
    };
  } else if (props.hovering) {
    return {
      color: theme.palette.primary.light6,
      fill: theme.palette.primary.light6,
    };
  } else {
    return {
      color: theme.palette.primary.light6,
      fill: theme.palette.secondary.main,
    };
  }
}