export enum AdvancedSearchQuery {
  Competitor = 'competitor',
  CurrentCompany = 'current_company',
  FormerCompany = 'former_company',
  Industry = 'industry',
  JobFunction = 'jobfunction',
  Keyword = 'keyword',
  Location = 'location',
  Name = 'name',
  Product = 'product',
  Sector = 'sector',
  Seniority = 'seniority',
  SubIndustry = 'subindustry',
  Title = 'title',
}
