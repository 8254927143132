import { useCallback, useMemo } from 'react';
import type {
  DropResult,
  ResponderProvided } from 'react-beautiful-dnd';
import {
  DragDropContext,
} from 'react-beautiful-dnd';
import { useSurveyFormQuestionAnswer } from '@/containers/SurveyForm';
import type { SurveyQuestionType } from '@/enums';
import type { RankingQuestion } from '@/types/survey';
import { AltRankingFormContext } from './context';
import type { AltRankingForm } from './interfaces';
import { RankingAltForm } from './Ranking.Alt';
import { reorderItems, parseIdFromDraggableId } from './utils.ranking.alt';

type Props = {
  question: RankingQuestion.FormQuestion;
};

export const RankingAltFormContainer = ({ question }: Props) => {

  const [answer, setAnswer] = useSurveyFormQuestionAnswer<SurveyQuestionType.Ranking>();

  const sortedItems = useMemo(() => {
    return answer.options.sort((a, b) => a.ordinal > b.ordinal ? 1 : -1);
  }, [answer.options]);

  const leftItems = useMemo(() => {
    let i = 0;
    return (question.options).reduce<AltRankingForm.DraggableItem[]>((acc, option) => {
      if (answer.options.some(s => s.id === option.id)) return acc;
      i++;
      return acc.concat({
        id: option.id,
        index: i,
        isOpenEnded: option.metadata.isOpenEnded,
        value: option.value,
      });
    }, []);
  }, [
    answer.options,
    question.options,
  ]);

  const rightItems: AltRankingForm.DraggableItem[] = useMemo(() => {
    return sortedItems.map(o => {
      const option = question.options.find(f => f.id === o.id);
      return {
        id: o.id,
        index: o.ordinal,
        isOpenEnded: option.metadata.isOpenEnded,
        value: option.value,
      };
    });
  }, [
    sortedItems,
    question.options,
  ]);

  const minRankings = useMemo(() => {
    return question.settings.minRank || question.options.length;
  }, [
    question.options.length,
    question.settings.minRank,
  ]);

  const maxRankings = useMemo(() => {
    return question.settings.maxRank || question.options.length;
  }, [
    question.options.length,
    question.settings.maxRank,
  ]);

  const handleDropIntoList = useCallback((result: DropResult) => {
    const id = parseIdFromDraggableId(result.draggableId);
    const targetOrdinal = result.destination.index;

    const options = reorderItems({
      items: answer.options,
      target: {
        id,
        ordinal: targetOrdinal,
      },
      settings: {
        minRank: minRankings,
        maxRank: maxRankings,
      },
    });

    setAnswer({
      dontKnow: answer.dontKnow,
      options,
      openEndedValues: answer.openEndedValues,
    });
  }, [
    answer,
    maxRankings,
    minRankings,
    setAnswer,
  ]);

  const handleDragEnd = useCallback((result: DropResult, provided: ResponderProvided) => {

    if (result.combine) {
      // handleCombine(result);
      return;
    }

    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index &&
      result.destination.droppableId === result.source.droppableId) {
      return;
    }

    handleDropIntoList(result);

  }, [
    handleDropIntoList,
  ]);

  const handleRemoveFromList = useCallback((id: number) => () => {
    const options = answer.options
      .filter(f => f.id !== id)
      .map((m, i) => ({
        id: m.id,
        ordinal: i + 1,
      }));

    setAnswer({
      dontKnow: answer.dontKnow,
      options,
      openEndedValues: answer.openEndedValues,
    });
  }, [answer, setAnswer]);

  const ctx = {
    disabled: answer.dontKnow,
    leftItems,
    rightItems,
    onRemoveFromList: handleRemoveFromList,
  };

  return (
    <AltRankingFormContext.Provider value={ctx}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <RankingAltForm />
      </DragDropContext>
    </AltRankingFormContext.Provider>
  );
};