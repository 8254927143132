import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import * as api from '@api';
import type { Projects } from '@api/interfaces/projects';
import { ProjectStatus } from '@enums';
import ActiveProjectsPanel from '@screens/GroupMain/ActiveProjectsPanel';
import { useRecentProfiles } from '@utils/api';
import { useIsInternalUser } from '@/containers/Store/hooks';
import { WorkspacesNavMenu } from '@/components/Workspace/WorkspaceNav';
import * as CallScheduling from '$admin/components/Scheduling';
import { ScreenLayout } from './Layout';
import AgendaCard from './AgendaCard';
import styles from './style/Main.css';
import RecentProfiles from './RecentProfiles';
import { RecentFiles } from './RecentFiles';
import { ActiveBrandsPanel } from './ActiveBrandsPanel';
import { useBrandsQuery, useRegisterGlobalChatContext } from './hooks';

const selectWorkspacesEnabled = (state: Store.State) => state.group.features.workspaces;

const AnalystMain = () => {
  const workspacesEnabled = useSelector(selectWorkspacesEnabled);
  const isInternalUser = useIsInternalUser();

  useRegisterGlobalChatContext();

  const projectStatuses = useMemo(() => {
    const statuses = [ProjectStatus.Active];

    if (!isInternalUser) statuses.push(ProjectStatus.Archived);

    return statuses;
  }, [isInternalUser]);

  const query = useQuery({
    queryKey: [
      `get:projects`, {
        index: 0,
        size: 6,
        status: projectStatuses,
      },
    ],
    queryFn: () => {
      return api.projects.fetchProjects({
        index: 0,
        size: 6,
        status: projectStatuses,
      });
    },
    refetchOnWindowFocus: false,
  });

  const profiles = useRecentProfiles({
    notifyOnChangeProps: ['isLoading'],
  });

  const brandsQuery = useBrandsQuery();

  const loading = query.isInitialLoading && !query.data;

  const data = loading
    ? getLazyTableData(4)
    : query.data?.items ?? [];

  const empty = query.isFetchedAfterMount && !loading && !data.length;

  const showRecentProfiles = isInternalUser && !profiles.isInitialLoading;

  return (
    <ScreenLayout>
      <Helmet title="Home" />

      <div className={styles.col}>
        {workspacesEnabled && <WorkspacesNavMenu className={styles.workspaces} />}

        <ActiveBrandsPanel
          query={brandsQuery} />

        <CallScheduling.Container>
          <ActiveProjectsPanel
            data={data}
            loading={loading}
            empty={empty} />
        </CallScheduling.Container>

        {workspacesEnabled && <RecentFiles />}
      </div>

      <div className={styles.col}>
        <div className={styles.sidebar}>
          <AgendaCard />
          {!showRecentProfiles && <RecentProfiles />}
        </div>
      </div>

    </ScreenLayout>
  );
};

export { AnalystMain };
export default AnalystMain;

const getLazyTableData = <T extends Projects.FetchProjects.ProjectItem>(pageSize = 25) => {
  return Array.from({ length: pageSize }, _ => ({} as T));
};