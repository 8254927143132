export const faqs = [{
  label: `What payment methods does Sentiment accept?`,
  value: `We accept all major credit cards! For Enterprise orders meeting a minimum we also accept bank transfers.`,
}, {
  label: `Can I increase the number of seats in the middle of a billing period on Sentiment?`,
  value: `Yes, you can change your team size at any point during your subscription. You’ll be charged a prorated fee for adding new users and a refund on your next payment for removing users.`,
}, {
  label: `How does pricing work on Sentiment?`,
  value: `Our pricing is based on the plan you’d like to use, and the number of users on the platform. You can pay on a month-to-month or annual basis in one upfront installment (so if you purchase a plan for one year, you will pay for one year upfront).`,
}, {
  label: `Can you change or cancel your plan on Sentiment at any time?`,
  value: `You can cancel your subscription whenever you like, however we do not offer refunds for a billing period you’ve already paid for.`,
}, {
  label: `Can you negotiate terms on Sentiment?`,
  value: `All customers must agree to our standard terms of service and privacy policy.`,
}, {
  label: `Is Sentiment GDPR compliant?`,
  value: `Yes. We are GDPR-ready with a privacy-by-design architecture, clear privacy policies and a DPA available.`,
}, {
  label: `Is your data safe on Sentiment?`,
  value: `We care deeply about data privacy and security and we use industry-standard cloud infrastructure along with extra safeguards to ensure your data remains secure, contact us directly with any questions.`,
}];