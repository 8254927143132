import { useCallback } from 'react';
import { Button } from '@/components/Button';
import { useTabViewContext } from '@/components/TabView/hooks';
import { TabKey } from './interfaces';
import styles from './style/AvailabilitySchedule.css';

type Props = {
  onClick: () => unknown;
};

export const AddTimes = (props: Props) => {

  return (
    <Button
      className={styles.add}
      onClick={props.onClick}
      variant="brick">
      Add Times
    </Button>
  );
};

AddTimes.displayName = 'AvailabilitySchedule.Action.AddTimes';

export const Schedule = ({ onClick, ...props }: Props) => {
  const tabview = useTabViewContext();

  const handleClick = useCallback(() => {

    onClick();

    tabview.jumpTo(TabKey.Schedule);

  }, [
    onClick,
    tabview,
  ]);

  return (
    <Button
      className={styles.schedule}
      onClick={handleClick}
      variant="brick">
      Schedule
    </Button>
  );
};

Schedule.displayName = 'AvailabilitySchedule.Action.Schedule';