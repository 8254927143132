import { useReducer } from 'react';
import type { WorkspaceFileUpload } from '@/types';
import { FileUploadsStateContext, FileUploadsDispatchContext } from './Context';
import type { FileUploadState } from './interfaces';

type Props =
  ChildrenProps;

const initialState: FileUploadState.State = {
  invalid: [],
  progress: {},
  uploads: {},
  valid: [],
};

export const FileUploadsState = (props: Props) => {
  const [state, dispatch] = useReducer(fileUploadsReducer, initialState);

  return (
    <FileUploadsStateContext.Provider value={state}>
      <FileUploadsDispatchContext.Provider value={dispatch}>
        {props.children}
      </FileUploadsDispatchContext.Provider>
    </FileUploadsStateContext.Provider>
  );
};

function fileUploadsReducer(state: FileUploadState.State, action: FileUploadState.Actions) {

  switch (action.type) {
    case 'add-valid-upload':
      return {
        ...state,
        valid: [
          ...state.valid,
          action.fileUpload,
        ],
        progress: {
          ...state.progress,
          [action.fileUpload.identifier]: 0,
        },
      };

    case 'update-valid-upload':
      return {
        ...state,
        valid: state.valid.reduce<WorkspaceFileUpload[]>((acc, x) => {
          return x.id === action.fileUpload.id
            ? acc.concat(action.fileUpload)
            : acc.concat(x);
        }, []),
      };

    case 'add-invalid-file':
      return {
        ...state,
        invalid: [
          ...state.invalid,
          {
            file: action.file,
            reason: action.reason,
          },
        ],
      };

    case 'update-upload-progress':
      return {
        ...state,
        progress: {
          ...state.progress,
          [action.fileIdentifier]: action.value,
        },
      };

    case 'register-s3-upload':
      return {
        ...state,
        uploads: {
          ...state.uploads,
          [action.fileIdentifier]: action.upload,
        },
      };

    case 'remove-completed-uploads': {
      const completed = Object.keys(state.uploads)
        .filter(x => state.progress[x] === 100);

      const uploads = Object.keys(state.uploads).reduce<FileUploadState.State['uploads']>((acc, identifier) => {
        return completed.includes(identifier)
          ? acc
          : {
            ...acc,
            [identifier]: state.uploads[identifier],
          };
      }, {});

      const progress = Object.keys(state.progress).reduce<FileUploadState.State['progress']>((acc, identifier) => {
        return completed.includes(identifier)
          ? acc
          : {
            ...acc,
            [identifier]: state.progress[identifier],
          };
      }, {});

      return {
        ...state,
        progress,
        uploads,
        valid: state.valid.filter(x => !completed.includes(x.identifier)),
      };
    }
  }

}

export default FileUploadsState;