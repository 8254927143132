import { TranscriptTaggingFocusContainer } from './Tagging.Focus.Container';
import { TranscriptTaggingStateContainer } from './Tagging.State.Container';

type Props = {
  children: React.ReactNode;
};

export const TranscriptTaggingContainer = (props: Props) => {
  return (
    <TranscriptTaggingStateContainer>
      <TranscriptTaggingFocusContainer>
        {props.children}
      </TranscriptTaggingFocusContainer>
    </TranscriptTaggingStateContainer>
  );
};

TranscriptTaggingContainer.displayName = 'Transcript.Tagging.Container';