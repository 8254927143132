import { useMemo } from 'react';
import { Folder } from 'react-feather';
import { type Cell } from 'react-table';
import { Link } from 'react-router-dom';
import { useIsInternalUser } from '@containers/Store';
import { TranscriptionRevisionStatus, WorkspaceObjectType } from '@/enums';
import { Brand as BrandIcon, FileIcon, ProjectIcon, TranscriptFileIcon } from '@/components/icons';
import { TranscriptStatus } from '@/components/Workspace.File';
import { useProjectState } from '@/containers/GroupProject/hooks';
import { VisibilityStatus } from '@screens/ProjectSummary/CallItem.EnhancementStatus';
import type {
  IWorkspaceBrandItem,
  IWorkspaceFileItem,
  IWorkspaceFolderItem,
  IWorkspaceProjectItem,
  IWorkspaceTranscriptItem,
  WorkspaceObjectItem,
} from '@/types/workspace.table';
import { cx, getLocationFor } from '@/utils';
import { MaybeLink } from '@/components/MaybeLink';
import { useTranscriptLink } from './hooks/useTranscriptLink';
import styles from './style/Files.Table.Cells.css';

export function NameCell(props: Cell<WorkspaceObjectItem>) {
  switch (props.row.original.object.typeId) {
    case WorkspaceObjectType.Brand:
      return <BrandNameCell {...(props as Cell<IWorkspaceBrandItem>)} />;

    case WorkspaceObjectType.File:
      return <FileNameCell {...(props as Cell<IWorkspaceFileItem>)} />;

    case WorkspaceObjectType.Folder:
      return <FolderNameCell {...(props as Cell<IWorkspaceFolderItem>)} />;

    case WorkspaceObjectType.ProjectParent:
      return <ProjectNameCell {...(props as Cell<IWorkspaceProjectItem>)} />;

    case WorkspaceObjectType.CallTranscript:
      return <TranscriptNameCell {...(props as Cell<IWorkspaceTranscriptItem>)} />;

    case WorkspaceObjectType.Workspace:
      return null;

    default:
      throw new UnreachableCaseError(props.row.original.object.typeId);
  }
}

function BrandNameCell({ row: { original: data } }: Cell<IWorkspaceBrandItem>) {
  const to = getLocationFor.workspaces.brand({
    brandId: data.brand.id,
  });

  return (
    <div className={styles.name}>
      <BrandIcon
        className={styles.icon} />
      <Link className={styles.link} to={to}>
        <div className={styles.trunc}>{data.object.name}</div>
      </Link>
    </div>
  );
}

function TranscriptNameCell({ row: { original: data } }: Cell<IWorkspaceTranscriptItem>) {
  const to = useTranscriptLink(data);
  const isInternalUser = useIsInternalUser();

  const loc = isInternalUser || data.transcript.enhancement?.statusId === TranscriptionRevisionStatus.Completed
    ? to
    : null;

  return (
    <div className={styles.transcript}>
      <div className={styles.name}>
        <TranscriptFileIcon
          className={styles.icon} />
        <MaybeLink className={cx({ [styles.link]: !!loc })} to={loc}>
          <div className={styles.trunc}>{data.object.name}</div>
        </MaybeLink>
      </div>
      <VisibilityStatus statusId={data.transcript.enhancement?.statusId} />
    </div>
  );
}

function ProjectNameCell({ row: { original: data } }: Cell<IWorkspaceProjectItem>) {
  const to = getLocationFor.project.rootWithId({ id: data.project.id });
  return (
    <div className={styles.name}>
      <ProjectIcon
        className={styles.icon}
        projectType={data.project.projectType} />
      <Link className={styles.link} to={to}>
        <div className={styles.trunc}>{data.object.name}</div>
      </Link>
    </div>
  );
}

function FolderNameCell({ row: { original: data } }: Cell<IWorkspaceFolderItem>) {

  const to = getLocationFor.workspaces.folder({
    folderId: data.folder.id,
  });

  return (
    <div className={styles.name}>
      <Folder
        className={styles.icon}
        color="#737373"
        size={26} />
      <Link className={styles.link} to={to}>
        <div className={styles.trunc}>{data.object.name}</div>
      </Link>
    </div>
  );
}

function FileTranscriptCell({ row: { original: data } }: Cell<IWorkspaceFileItem>) {

  const isInternalUser = useIsInternalUser();

  const transcriptLocation = useMemo(() => {
    const loc = getLocationFor.workspaces.transcript({
      transcriptId: data.transcript.id,
    });

    if (isInternalUser) {
      return loc;
    }

    const enhancementStatusId = data.transcript.enhancement?.statusId;

    if (!enhancementStatusId || enhancementStatusId === TranscriptionRevisionStatus.Completed) {
      return loc;
    }

    return null;
  }, [data.transcript.enhancement?.statusId, data.transcript.id, isInternalUser]);

  return (
    <div className={styles.transcript}>
      <div className={styles.name}>
        <TranscriptFileIcon
          className={styles.icon} />
        <div className={styles.fileName}>
          <MaybeLink className={cx({ [styles.link]: !!transcriptLocation })} to={transcriptLocation}>
            <div className={styles.trunc}>{data.object.name}</div>
          </MaybeLink>
          <TranscriptStatus
            className={styles.transcriptStatus}
            transcript={data.transcript} />
        </div>
      </div>
      <VisibilityStatus statusId={data.transcript.enhancement?.statusId} />
    </div>
  );
}

function FileNameCell(props: Cell<IWorkspaceFileItem>) {
  const data = props.row.original;
  const state = useProjectState();

  const to = useMemo(() => {
    return state?.project?.id
      ? getLocationFor.project.file({ fileId: data.file.id, projectId: state.project.id })
      : getLocationFor.workspaces.file({ fileId: data.file.id });
  }, [data.file.id, state?.project?.id]);

  const hasTranscript = useMemo(() => {
    return data.transcript.status === 'complete';
  }, [data.transcript.status]);

  if (hasTranscript) {
    return <FileTranscriptCell {...props} />;
  }

  return (
    <div className={styles.name}>
      <FileIcon
        className={styles.icon}
        extension={data.file.extension} />
      <div className={styles.fileName}>
        <Link className={styles.link} to={to}>
          <div className={styles.trunc}>{data.object.name}</div>
        </Link>
        <TranscriptStatus
          className={styles.transcriptStatus}
          transcript={data.transcript} />
      </div>
    </div>
  );
}