import type { CellProps } from 'react-table';
import styles from './style/Cell.Owner.css';

type GenericOwnerItem = {
  owner: string;
};

export default function Owner({ row: { original: data } }: CellProps<GenericOwnerItem>) {
  return (
    <div className={styles.root}>
      {data.owner || ''}
    </div>
  );
}

export { Owner };