import { useCallback, useState } from 'react';
import { NumberFormatValues, NumericFormatProps } from 'react-number-format';
import { useSurveyThemingPalette } from '@containers/Branding/hooks/useSurveyThemingPalette';
import { cx } from '@utils';
import { NumberInputTable } from './interfaces';
import { SurveyNumberFormat } from './SurveyNumberInput';
import styles from './style/NumberInput.css';

type Props = NumberInputTable.InputProps;

export const NumberTableNumberInput = ({ className, invalid, onChange, value, settings }: Props) => {
  const { theme } = useSurveyThemingPalette();

  const { numberSettings, validations } = settings;

  const isInputAllowed = useCallback(({ floatValue, value }: NumberFormatValues) => {
    if (value.length > 1 && value.startsWith('0')) return false;
    if (value === '') return true;

    const valid = (!validations?.minValue !== null || floatValue >= validations?.minValue) &&
      (!validations?.maxValue !== null || floatValue <= validations?.maxValue);

    return valid;
  }, [validations]);

  const onValueChange = useCallback((vals: NumberFormatValues) => {
    onChange(vals.floatValue);
  }, [onChange]);

  const props: NumericFormatProps = {
    className: cx({
      [styles.input]: !theme,
      [styles.theme]: theme,
    }, className, {
      [styles.invalid]: invalid,
    }),
    decimalScale: 2,
    isAllowed: isInputAllowed,
    onValueChange,
    value,
    placeholder: '-',
    thousandSeparator: true,
    valueIsNumericString: true,
  };

  return theme
    ? <ThemedNumberTableInput {...props} settings={settings} />
    : <SurveyNumberFormat {...props} settings={numberSettings} />;
};

export default NumberTableNumberInput;

type ThemedNumberTableInputProps = Pick<Props,
  | 'className' | 'settings'>
  & NumericFormatProps;

const ThemedNumberTableInput = (props: ThemedNumberTableInputProps) => {
  const [focused, setFocused] = useState(false);
  const { palette } = useSurveyThemingPalette();

  const onBlur = () => setFocused(false);
  const onFocus = () => setFocused(true);

  const handleBlur = useCallback(onBlur, []);
  const handleFocus = useCallback(onFocus, []);

  const style = focused ? {
    border: `2px solid ${palette.primary.main}`,
  } : {};

  return (
    <SurveyNumberFormat
      onValueChange={props.onValueChange}
      className={props.className}
      settings={props.settings.numberSettings}
      //decimalScale={2}
      isAllowed={props.isAllowed}
      onBlur={handleBlur}
      onFocus={handleFocus}
      style={style}
      value={props.value} />
  );
};