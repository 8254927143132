import { useMemo } from 'react';
import { useFolderMenuItems } from './useFolderMenuItems';

export const useShowFolderMenu = () => {
  const items = useFolderMenuItems();

  const showMenu = useMemo(() => {
    return !!Object.values(items).filter(Boolean).length;
  }, [items]);

  return showMenu;
};

export default useShowFolderMenu;