import { ConjointAnalysisQuestion } from '@/types';

const ConjointAnalysisColors = [
  'rgba(90, 133, 227, OPACITY)',
  'rgba(49, 191, 144, OPACITY)',
  'rgba(242, 87, 83, OPACITY)',
  'rgba(243, 131, 58, OPACITY)',
  'rgba(242, 195, 55, OPACITY)',
];

export function getLevelColor(attributeIndex: number, levelIndex: number, total: number) {
  const incrementPct = levelIndex / total;

  const opacity = .2 + ((1 - incrementPct) * .8);

  return getColor(attributeIndex, opacity);
}

export function getAttributeColor(attributeIndex: number) {
  return getColor(attributeIndex, 1);
}

function getColor(attributeIndex: number, opacity: number) {
  return ConjointAnalysisColors[attributeIndex].replace('OPACITY', opacity.toString());
}