import { cx } from '@utils';
import { useEnterpriseSubmit } from '$website/containers/Auth/hooks/useEnterpriseSubmit';
import styles from './style/SignUpForm.css';

type Props = {
  className?: string;
};

export function EnterpriseError(props: Props) {
  const [state] = useEnterpriseSubmit();

  const content = state.error
      ? <div className={styles.error}>{state.error.message}</div>
      : null;

  return (
    <div className={cx(styles.notify, props.className)}>{content}</div>
  );
}

export default EnterpriseError;