import type { History } from 'history';
import type * as enums from '@enums';
import type { Call } from './calls';
import type * as IChat from './chat';
import type { ConnectedAccount } from './connected-accounts';
import type { Contact } from './contacts';
import type { GroupSignatureRequest } from './documents';
import type {
  Branding as GroupBranding,
  Group as GroupRecord,
} from './group';
import type { Notification } from './notifications';
import type { Completion as OnboardingCompletion } from './onboarding.background';
import type {
  UnparsedPipelineUsersMap,
  ProjectPipelineLinkUsesMap,
  PipelineProjectAttributes,
  PipelineRecord,
  PipelineRespondentsMap,
  PipelineUsersMap,
} from './pipeline';
import type { PlatformAlerts } from './platform-alerts';
import type { Project } from './projects';
import type { UserPayoutSettings } from './payout';
import type {
  Settings as PreferencesSettings,
} from './settings';
import type {
  UserContactProps,
  UserMetaProps,
  UserProfileObject,
  UserReferrerProps,
  UserSettingsProps,
  UserStateProps,
} from './user';
import type {
  Workspace,
} from './workspace';
import type { ProjectParent } from './projects';

export * as UI from './ui';

declare global {
  namespace Store {

    namespace Chat {
      export type Conversations = {
        [sid: string]: IChat.Conversation;
      } & { ids: string[] };

      export type Connections = {
        [id: number]: IChat.Connection;
      } & { ids: number[] };

      export type ConnectionState = IChat.ConnectionState;

      export type Messages = {
        [conversationSid: string]: IChat.Message[];
      };

      export type Participants = {
        [userId: number]: IChat.ParticipantState;
      };

      export type PlatformMessages = {
        [conversationSid: string]: IChat.PlatformMessage[];
      };

      export type Initialized = boolean;
    }

    namespace Documents {
      export type Group = {
        [groupId: number]: GroupSignatureRequest;
        ids: Array<GroupSignatureRequest['groupId']>;
      };
    }

    namespace Group {
      export type Billing = GroupRecord.Billing;
      export type BlacklistedCompanies = Descriptor[];
      export type Branding = GroupBranding;
      export type CreatedOn = Date;
      export type Document = GroupRecord.Document;
      export type Features = GroupRecord.Features;
      export type Id = number;
      export type Name = string;
      export type Settings = GroupRecord.Settings;
      export type TypeId = enums.GroupType;
    }

    namespace Notifications {
      export type Items = Notification[];
      export type Initialized = boolean;
    }

    namespace Pipeline {
      namespace Project {
        export type Anonymity = boolean | null;
        export type LinkUses = UnparsedPipelineUsersMap;
        export type Respondents = PipelineRespondentsMap;
        export type Users = PipelineUsersMap;
      }

      namespace Me {
        export type LinkUses = ProjectPipelineLinkUsesMap;
      }

      export type Me = {
        [projectId: number]: PipelineRecord;
        linkUses?: ProjectPipelineLinkUsesMap;
      };

      export type Project = {
        [projectId: number]: PipelineProjectAttributes;
      };
    }

    namespace Platform {
      export type Alerts = PlatformAlerts;
    }

    namespace Projects {
      export type Parents = {
        [id: number]: ProjectParent;
        ids: Array<ProjectParent['id']>;
      };
    }

    namespace Settings {
      export type Email = PreferencesSettings.Email;

      export type SMS = PreferencesSettings.SMS;
    }

    namespace User {
      export type Connected = ConnectedAccount[];
      export type Contact = UserContactProps;
      export type Id = number;
      export type Meta = UserMetaProps;
      export type Payout = UserPayoutSettings;
      export type Permissions = enums.Permission[];
      export type Profile = UserProfileObject;
      export type Referral = string;
      export type Referrer = UserReferrerProps;
      export type Roles = enums.Role[];
      export type Settings = UserSettingsProps;
      export type State = UserStateProps;
    }

    export type Calls = {
      [id: number]: Call;
      ids: number[];
    };

    export type Chat = {
      conversations: Chat.Conversations;
      connections: Chat.Connections;
      connectionState: Chat.ConnectionState;
      initialized: Chat.Initialized;
      participants: Chat.Participants;
      messages: Chat.Messages;
      platformMessages: Chat.PlatformMessages;
    };

    export type Contacts = {
      [id: number]: Contact;
      ids: Array<Contact['id']>;
    };

    export type Documents = {
      group: Store.Documents.Group;
    };

    export type Group = {
      billing: Store.Group.Billing;
      blacklistedCompanies: Store.Group.BlacklistedCompanies;
      branding: Store.Group.Branding;
      createdOn: Store.Group.CreatedOn;
      document: Store.Group.Document;
      features: Store.Group.Features;
      id: Store.Group.Id;
      name: Store.Group.Name;
      settings: Store.Group.Settings;
      typeId: Store.Group.TypeId;
    };

    export type Network = {
      isConnected: boolean;
    };

    export type Notifications = {
      initialized: Notifications.Initialized;
      items: Notifications.Items;
    };

    export type Onboarding = OnboardingCompletion;

    export type Pipeline = {
      me: Store.Pipeline.Me;
      project: Store.Pipeline.Project;
    };

    export type Platform = {
      alerts: Store.Platform.Alerts;
    };

    export type Projects<T = unknown> = {
      [id: number]: Project<T>;
      ids: Array<Project['id']>;
      parents: Store.Projects.Parents;
    };

    export type Routing = {
      routes: History.LocationState[];
    };

    export type Settings = {
      email: Settings.Email;
      sms: Settings.SMS;
    };

    export type User<T = Date> = {
      connected: Store.User.Connected;
      contact: Store.User.Contact;
      createdOn: T;
      id: Store.User.Id;
      meta: Store.User.Meta;
      payout: Store.User.Payout;
      permissions: Store.User.Permissions;
      profile: Store.User.Profile;
      referral: Store.User.Referral;
      referrer: Store.User.Referrer;
      roles: Store.User.Roles;
      settings: Store.User.Settings;
      state: Store.User.State;
    };

    export type Workspaces<T = Date> = {
      group: number;
      personal: number;
      values: {
        [id: number]: Workspace<T>;
      };
    };

    export type State = {
      calls: Store.Calls;
      chat: Store.Chat;
      contacts: Store.Contacts;
      documents: Store.Documents;
      group: Store.Group;
      network: Store.Network;
      notifications: Store.Notifications;
      onboarding: Store.Onboarding;
      pipeline: Store.Pipeline;
      platform: Store.Platform;
      projects: Store.Projects;
      routing: Store.Routing;
      settings: Store.Settings;
      user: Store.User;
      workspaces: Store.Workspaces;
    };
  }

  interface MediaDevices {
    getDisplayMedia(constraints: MediaStreamConstraints): Promise<MediaStream>;
  }

  type Descriptor = {
    id: number;
    name: string;
  };

  type PersonDescriptor = {
    id: number;
    first: string;
    last: string;
    name: string;
  };

  type UserCreatedDescriptor = {
    id: number;
    name: string;
    isUserCreated?: boolean;
  };

  type ChildrenProps = {
    children: React.ReactNode;
  };

  type ObjectLike<K extends string = string, V = unknown> = Record<K, V>;

  interface ExtendedPromise<T> extends Promise<T> {
    cancel?: () => void;
  }

  type Stringify<T extends Record<string, unknown>, K extends keyof T = keyof T> = {
    [P in K]: string;
  };

  type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

  type PartialExcept<T, K extends keyof T> = Pick<T, K> & Partial<Omit<T, K>>;

  class UnreachableCaseError {
    constructor(val: never);
  }

  // TODO: Remove when react types are updated to v18
  namespace React {
    interface DOMAttributes<T> {
      nonce?: string | undefined;
      onResize?: ReactEventHandler<T> | undefined;
      onResizeCapture?: ReactEventHandler<T> | undefined;
    }
  }
}

export class UnreachableCaseError extends Error {
  constructor(val: never) {
    super(`Unreachable case: ${JSON.stringify(val)}`);
  }
}

//@ts-ignore
globalThis.UnreachableCaseError = UnreachableCaseError;

export * from './global.interfaces';