import { useCallback } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder';
import { useSurveyAlternateImagesBuilderItemContext } from '@containers/SurveyBuilder.AIE';
import { QuestionSettingTitle } from '@/presentation/SurveyBuilder';
import { DropDown } from '@/components/DropDown';
import { Checkbox } from '@/components/Checkbox';
import styles from './style/Settings.css';

export const Settings = () => {
  const [, dispatch] = useSurveyBuilderState();

  const item = useSurveyAlternateImagesBuilderItemContext();

  const updateSettings = useCallback((settings: Partial<typeof item.settings>) => {
    dispatch({
      type: 'aie-settings-updated',
      exerciseIdentifier: item.identifier,
      settings,
    });
  }, [dispatch, item]);

  return (
    <div className={styles.root}>
      <div>
        <QuestionSettingTitle title={'Image Display Length'} />
        <DropDown
          classes={{ root: styles.dropDown }}
          items={IMAGE_DISPLAY_ITEMS}
          getItemValue={i => i.id}
          text={item.settings?.delaySeconds ? `${item.settings.delaySeconds} seconds` : 'Manual'}
          onSelect={e => dispatch({ type: 'aie-settings-updated', exerciseIdentifier: item.identifier, settings: { delaySeconds: e.val } })} />
      </div>
      <div className={styles.inlineRow}>
        <Checkbox checked={item.settings.randomize} onChange={e => updateSettings({ randomize: e.target.checked })} />
        <QuestionSettingTitle className={styles.settingsTitle} title={'Randomize Concepts'} />
      </div>
    </div>
  );
};

type DropDownItem = {
  id: string;
  val: number;
};

const IMAGE_DISPLAY_ITEMS: DropDownItem[] = [
  { id: 'Manual', val: null },
  { id: '1 second', val: 1 },
  { id: '3 seconds', val: 3 },
  { id: '5 seconds', val: 5 },
  { id: '10 seconds', val: 10 },
  { id: '15 seconds', val: 15 },
  { id: '20 seconds', val: 20 },
];