import { memo } from 'react';
import { Star } from 'react-feather';
import { cx } from '@utils';
import styles from './style/StarToggle.css';

type StarToggleProps = {
  className?: string;
  onClick: () => void;
  value: boolean;
};

export const StarToggle = memo(({ className, onClick, value }: StarToggleProps) => {
  const computedClass = cx(className, styles.root, {
    [styles.starred]: value,
    [styles.unstarred]: !value,
  });

  return (
    <Star
      className={computedClass}
      onClick={onClick} />
  );
});

export default StarToggle;
