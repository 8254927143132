import { AsyncFn, AsyncState, createNamedContext } from '@utils';
import { Email, Enterprise, Social } from './interfaces';

export type LoginFormContextValue = [Email.Login.Form, Email.Login.Dispatch];
export type LoginSubmitContextValue = [AsyncState<void>, AsyncFn];

export type SignupFormContextValue = [Email.Signup.Form, Email.Signup.Dispatch];
export type SignupSubmitContextValue = [AsyncState<void>, AsyncFn];

export type EnterpriseSubmitContextValue = [AsyncState<void>, (values: Enterprise.Login.State) => Promise<unknown>];

export const LoginFormContext = createNamedContext<LoginFormContextValue | undefined>(undefined, 'LoginFormContext');
export const LoginSubmitContext = createNamedContext<LoginSubmitContextValue | undefined>(undefined, 'LoginSubmitContext');

export const SignupFormContext = createNamedContext<SignupFormContextValue | undefined>(undefined, 'SignupFormContext');
export const SignupSubmitContext = createNamedContext<SignupSubmitContextValue | undefined>(undefined, 'SignupSubmitContext');

export const SocialAuthContext = createNamedContext<Social.ContextValue | undefined>(undefined, 'SocialAuth.Context');

export const EnterpriseSubmitContext = createNamedContext<EnterpriseSubmitContextValue | undefined>(undefined, 'EnterpriseSubmitContext');