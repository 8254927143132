import { addMinutes } from 'date-fns/fp';
import { CalendarDateSegment } from '@/types';

export const segmentRange = (dateRange: CalendarDateSegment, minutes = 30) => {
  const increment = addMinutes(minutes);

  function createSegments(range: CalendarDateSegment, acc: CalendarDateSegment[] = []): CalendarDateSegment[] {
    const date = increment(range.start);

    if (date < range.end) {
      const next = { start: date, end: range.end };
      const segment = { start: range.start, end: date };

      return createSegments(next, acc.concat(segment));
    }

    return acc.concat(range);
  }

  return createSegments(dateRange);
};