import { useCallback, useMemo } from 'react';
import { AudienceType } from '@enums';
import { useSelectProject } from '@containers/Store';

export const useAssertProjectAudienceType = <T extends AudienceType[]>(...types: T) => {
  const assertProjectAudienceType = (type: AudienceType) => {
    return types.includes(type);
  };

  return useCallback(assertProjectAudienceType, [types]);
};

export const useAssertProjectAudienceTypeLookup = (projectId: number) => {
  const project = useSelectProject(projectId);

  const assertPayerAudience = useAssertProjectAudienceType(AudienceType.Payer);
  const assertPractitionerAudience = useAssertProjectAudienceType(AudienceType.HealthcarePractitioner);
  const assertProfessionalAudience = useAssertProjectAudienceType(AudienceType.HealthcareProfessional);
  const assertPatientAudience = useAssertProjectAudienceType(AudienceType.Patient);
  const assertOtherAudience = useAssertProjectAudienceType(AudienceType.Other);

  const lookup = useMemo(() => {
    return {
      other: assertOtherAudience(project?.audienceType),
      patient: assertPatientAudience(project?.audienceType),
      payer: assertPayerAudience(project?.audienceType),
      practitioner: assertPractitionerAudience(project?.audienceType),
      professional: assertProfessionalAudience(project?.audienceType),
    };
  }, [
    assertOtherAudience,
    assertPatientAudience,
    assertPayerAudience,
    assertPractitionerAudience,
    assertProfessionalAudience,
    project?.audienceType,
  ]);

  return lookup;
};