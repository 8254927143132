import { useContext } from 'react';
import { useQueryParams } from 'use-query-params';
import { FolderStateContext } from '@/containers/Workspace.Folder/Context';
import { useInitiateFilesReview } from '@/containers/WorkspaceFileUpload/hooks/useInitiateFilesReview';
import { CanUploadFilesContext, UploadFilesContext } from '@/containers/WorkspaceFileUpload/Context';
import {
  FilesTableContext,
  FilesTableDataContext,
  SetFilesTableFiltersContext,
  FilesTableFiltersContext,
  FilesTableLoadingContext,
  FilesTableMetaContext,
  SelectedObjectsContainer,
} from '@/components/Workspace.FilesTable';
import { useFetchObjectChildren } from '@/utils/api/workspace.objects';

const useParams = () => {
  return useQueryParams({

  });
};

type Props = ChildrenProps;

export const FolderFilesContainer = (props: Props) => {
  const [params, setQueryParams] = useParams();

  const state = useContext(FolderStateContext);

  const uploadFiles = useInitiateFilesReview({
    parentObjectId: state.object.id,
    workspaceId: state.object.workspaceId,
  });

  const query = useFetchObjectChildren({
    parentObjectId: state.object.id,
    workspaceId: state.object.workspaceId,
  }, {
    refetchOnWindowFocus: false,
    placeholderData: {
      items: [],
      meta: {
        count: 0,
        size: 0,
      },
    },
  });

  return (
    <SelectedObjectsContainer>
      <FilesTableDataContext.Provider value={query.data?.items || []}>
        <FilesTableLoadingContext.Provider value={query.isLoading}>
          <FilesTableFiltersContext.Provider value={params}>
            <SetFilesTableFiltersContext.Provider value={setQueryParams}>
              <FilesTableContext.Provider value={{ parentObject: state.object }}>
                <UploadFilesContext.Provider value={uploadFiles}>
                  <CanUploadFilesContext.Provider value={state.capabilities.canAddChildren}>
                    <FilesTableMetaContext.Provider value={query.data?.meta}>
                      {props.children}
                    </FilesTableMetaContext.Provider>
                  </CanUploadFilesContext.Provider>
                </UploadFilesContext.Provider>
              </FilesTableContext.Provider>
            </SetFilesTableFiltersContext.Provider>
          </FilesTableFiltersContext.Provider>
        </FilesTableLoadingContext.Provider>
      </FilesTableDataContext.Provider>
    </SelectedObjectsContainer>
  );
};