import { useIsLanguageVersion } from '@/containers/SurveyBuilder';

export const LanguageEditingBarrier = (props: ChildrenProps) => {
  const isLanguageEditing = useIsLanguageVersion();

  if (!isLanguageEditing) {
    return props.children;
  } else {
    return null;
  }
};