import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import type { Column, CellProps } from 'react-table';
import { useTable, useFlexLayout, useSortBy, usePagination } from 'react-table';
import { format } from 'date-fns';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { getLocationFor } from '@utils';
import type { GetBrands } from '@/services/api/interfaces/workspace.brand';
import { Brand } from '@/components/icons';
import { useIsInternalUser } from '@/containers/Store';
import * as Table from '@/components/Table';
import styles from './style/BrandsTable.css';

type SortBy = 'name' | 'createdOn' | 'owner';
type SortDir = 'asc' | 'desc';

type Props = {
  items: GetBrands.BrandItem[];
  isLoading: boolean;
  isEmpty: boolean;
  sort?: {
    by: SortBy;
    dir: SortDir;
    onSort: (by: 'name' | 'createdOn' | 'owner', dir: 'asc' | 'desc') => void;
  };
};

type SortableHeaderProps = {
  name: SortBy;
} & ChildrenProps;

export const BrandsTable = (props: Props) => {
  const isInternalUser = useIsInternalUser();

  const SortableHeader = useCallback(({ name, children }: SortableHeaderProps) => {
    if (!props.sort) {
      return children;
    }
    else {
      const sorted = props.sort.by === name;
      return (
        <div className={styles.sortableHeader} onClick={() => props.sort.onSort(name, props.sort.by === name ? reverseDir(props.sort.dir) : 'asc')}>
          {children}
          {sorted ? (props.sort.dir === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />) : null}
        </div>
      );
    }
  }, [props.sort]);

  const columns = useMemo<Column<GetBrands.BrandItem>[]>(() => [
    {
      id: 'displayName',
      Cell: (x: CellProps<GetBrands.BrandItem>) => (
        <div className={styles.nameCell}>
          <Brand className={styles.brandIcon} />
          <Link to={getLocationFor.brand.root({ brandId: x.cell.row.original.id })}>
            {x.cell.row.original.displayName}
          </Link>
        </div>
      ),
      Header: () => (
        <SortableHeader name='name'>
          Name
        </SortableHeader>
      ),
      minWidth: 200,
    }, {
      id: 'progress',
      Cell: () => <></>,
      width: 105,
    }, isInternalUser ? {
      id: 'owner',
      Cell: (x: CellProps<GetBrands.BrandItem>) => x.cell.row.original.createdBy?.fullName,
      Header: () => (
        <SortableHeader name='owner'>
          Owner
        </SortableHeader>
      ),
      width: 80,
    } : undefined, {
      id: 'createdOn',
      Cell: (x: CellProps<GetBrands.BrandItem>) => format(x.cell.row.original.createdOn, 'MM/dd/yyyy'),
      Header: () => (
        <SortableHeader name='createdOn'>
          Created On
        </SortableHeader>
      ),
      minWidth: 80,
      width: 80,
    },
    {
      id: 'actions',
      Cell: () => <></>,
      minWidth: 50,
      width: 50,
      disableSortBy: true,
    },
  ].filter(Boolean), [SortableHeader, isInternalUser]);

  const params = {
    columns,
    data: props.items,
    disableSortBy: true,
    initialState: {
      pageIndex: 0,
      pageSize: 5,
    },
  };

  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable<GetBrands.BrandItem>(
    params,
    useFlexLayout,
    usePagination,
  );

  return (
    <Table.Root
      EmptyComponent={EmptyState}
      empty={props.isEmpty}
      loading={props.isLoading}>
      <Table.Header headerGroups={headerGroups} />
      <Table.Body
        {...getTableBodyProps()}
        classes={{
          tbody: styles.body,
          td: styles.td,
          tr: styles.tr,
        }}
        prepareRow={prepareRow}
        rows={rows} />
    </Table.Root>
  );
};

function EmptyState() {
  return (
    <div className={styles.empty}>
      <div className={styles.title}>No active brands</div>
    </div>
  );
}

function reverseDir(dir: SortDir) {
  return dir === 'asc' ? 'desc' : 'asc';
}