import { useLockScreen } from '@utils';
import { useConferenceInstance, IConference } from '@containers/Conference';
import { CallRole } from '@/enums';
import * as utils from './utils';
import { ColorRing } from './ColorRing';
import styles from './style/WaitingRoom.css';

export function WaitingRoom() {
  const instance = useConferenceInstance<IConference.Coordinator.Conference.WaitingRoom>();
  useLockScreen();

  const text = instance.waitingType === 'for-start'
    ? 'Waiting for host to start the conference.'
    : [`Waiting for host to let you in!`, instance.participant.role === CallRole.Attendee ? ATTENDEE_ADMIT_WAITING_TEXT : null].filter(Boolean).join(' ');

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.title}>{`${instance.conference.title} Call`}</div>
        <div className={styles.date}>
          {utils.formatCallTime(instance.call.start, instance.call.end)}
        </div>
        <div className={styles.text}>{text}</div>
        <div className={styles.text}>{`Please leave the browser open so you can be notified when you are admitted.`}</div>
        <ColorRing size={35} />
      </div>
    </div>
  );
}

const ATTENDEE_ADMIT_WAITING_TEXT = 'We will open the main conference room to observers as soon as the respondent has joined and the interview is ready to commence.';