import { forwardRef, useCallback, useContext, useMemo } from 'react';
import { Edit2, Check } from 'react-feather';
import { useCommands as useCommandsRM, useChainedCommands as useChainedRmCommands, useRemirrorContext } from '@remirror/react';
import cuid from 'cuid';
import { MenuItem } from '@mui/material';
import type { Mark } from '@remirror/pm/model';
import { TranscriptAutoCorrectContext, TranscriptUpdatesContext } from '@containers/Transcript/context';
import { MarkType, type AutoCorrect } from '@/types/transcribe.rich-text';
import { PopperMenu as Menu } from '@/components/Popper';
import { NodeType } from '@/types/rich-text';
import {
  useTextReplaceHighlight,
  useChainedCommands,
} from './hooks';
import { UnsavedHighlightColor } from './utils';
import styles from './style/Tooltip.Confidence.css';

type Props = {
  arrow?: {
    className: string;
    ref: React.Ref<HTMLDivElement>;
  };
};

export const AutoCorrectTooltip = forwardRef<HTMLDivElement, Props>(({ arrow, ...props }, ref) => {
  const { selectedMark, setMark } = useContext(TranscriptAutoCorrectContext);
  const { addConfirmedAutoCorrections } = useContext(TranscriptUpdatesContext);
  const rmContext = useRemirrorContext();
  const commands = useChainedCommands();
  const rmCommands = useCommandsRM();
  const textReplaceHighlight = useTextReplaceHighlight();

  const matchingMarks = useMemo(() => {
    if (selectedMark) {
      const marksWithRange: { mark: Mark; from: number; to: number }[] = [];
      const doc = rmContext.getState().doc;

      doc.descendants((n, pos, index) => {
        if (n.type.name === NodeType.Text) {
          for (const mark of n.marks.filter(m => m.type.name === MarkType.AutoCorrect)) {
            if ((mark.attrs as AutoCorrect.Attributes).id === (selectedMark.mark.attrs as AutoCorrect.Attributes).id) {
              marksWithRange.push({
                mark,
                from: pos,
                to: pos + n.nodeSize,
              });
            }
          }
        }
      });

      return marksWithRange;
    }
  }, [rmContext, selectedMark]);

  const effectiveRange = useMemo(() => {
    if (selectedMark) {
      return {
        from: Math.min(...matchingMarks.map(m => m.from)),
        to: Math.max(...matchingMarks.map(m => m.to)),
      };
    }
  }, [matchingMarks, selectedMark]);

  const onTextReplace = useCallback(() => {
    const highlight = {
      id: cuid(),
      color: UnsavedHighlightColor,
      from: effectiveRange.from,
      to: effectiveRange.to,
      dbId: null,
    };
    commands
      .blurHighlight()
      .addHighlight(highlight).run();

    textReplaceHighlight({ ...highlight, text: selectedMark.text });
    setMark(null);
    //Blur any existing higlight
    //Set a highlight covering the mark, trigger text replace highlight
  }, [effectiveRange, commands, textReplaceHighlight, selectedMark, setMark]);

  const onConfirm = useCallback(() => {
    //Remove the marks from the document and add the IDs to a container element so that backend knows to mark the autocorrection as confirmed
    rmCommands.removeMark({
      type: MarkType.AutoCorrect,
      selection: effectiveRange,
    });

    addConfirmedAutoCorrections([(selectedMark.mark.attrs as AutoCorrect.Attributes).id]);

    setMark(null);
  }, [addConfirmedAutoCorrections, effectiveRange, rmCommands, selectedMark, setMark]);

  if (!selectedMark) return null;

  return (
    <div
      ref={ref}>
      <Menu>
        <MenuItem className={styles.menuItem} onClick={onConfirm}>
          <Check size={14} /> Confirm
        </MenuItem>
        <MenuItem className={styles.menuItem} onClick={onTextReplace}>
          <Edit2 size={14} /> Edit Text
        </MenuItem>
      </Menu>
    </div>
  );
});

export default AutoCorrect;