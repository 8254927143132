import { Reducer, useReducer } from 'react';
import { TranscriptCommentsStateContext } from './Context';
import { Actions, State } from './interfaces';

type Props = {
  children: React.ReactNode;
};

export const TranscriptCommentingStateContainer = (props: Props) => {

  const [state, dispatch] = useReducer<Reducer<State, Actions>>(commentsReducer, initialState);

  return (
    <TranscriptCommentsStateContext.Provider value={[state, dispatch]}>
      {props.children}
    </TranscriptCommentsStateContext.Provider>
  );
};

TranscriptCommentingStateContainer.displayName = 'Transcript.Commenting.State.Container';

const initialState: State = {
  items: [],
};

function commentsReducer(state: State, action: Actions): State {

  switch (action.type) {

    case 'comments-fetched':
      return {
        items: action.items,
      };

    case 'comment-added': {
      return {
        items: state.items.concat({
          children: [{
            createdOn: new Date(),
            creator: action.creator,
            identifier: action.identifier,
            id: null,
            value: null,
          }],
          highlight: action.highlight,
          parentComment: {
            createdOn: new Date(),
            id: null,
            identifier: action.identifier,
          },
        }),
      };
    }

    case 'comment-removed': {
      return {
        items: action.commentId
          ? state.items.filter(f => f.parentComment.id !== action.commentId)
          : state.items.filter(f => f.parentComment.identifier !== action.identifier),
      };
    }

    case 'comment-saved': {
      const items = state.items.reduce((acc, x) => {
        if (x.parentComment.identifier === action.comment.identifier) {
          return acc.concat({
            children: [action.comment],
            highlight: action.comment.highlight,
            parentComment: {
              id: action.comment.id,
              identifier: action.comment.identifier,
            },
          });
        } else {
          return acc.concat(x);
        }
      }, []);

      return { items };
    }

    case 'comment-updated': {
      const items = state.items.reduce((acc, x) => {
        if (x.parentComment.id === action.parentCommentId) {

          const children = x.children.reduce((acc2, ch) => {
            const child = ch.id === action.comment.id
              ? action.comment
              : ch;
            return acc2.concat(child);
          }, []);

          return acc.concat({
            ...x,
            children,
          });
        } else {
          return acc.concat(x);
        }
      }, []);

      return { items };
    }

    case 'reply-saved': {
      const items = state.items.reduce((acc, x) => {
        if (x.parentComment.id === action.parentCommentId) {
          return acc.concat({
            ...x,
            children: [
              ...x.children,
              action.comment,
            ],
          });
        } else {
          return acc.concat(x);
        }
      }, []);

      return { items };
    }

    case 'reply-removed': {
      const items = state.items.reduce((acc, x) => {
        if (x.parentComment.id === action.parentCommentId) {
          return acc.concat({
            ...x,
            children: x.children.filter(f => f.id !== action.commentId),
          });
        } else {
          return acc.concat(x);
        }
      }, []);

      return { items };
    }

    case 'highlight-removed': {
      return {
        items: state.items.filter(f => f.highlight.identifier !== action.highlightIdentifier),
      };
    }

    default: return state;
  }
}