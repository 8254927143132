import { Calendar } from 'react-feather';
import { TableEmpty } from '@presentation';

type Props = unknown;

export const CallsTableEmpty = (props: Props) => {
  return (
    <TableEmpty
      Icon={() => <Calendar size={120} />}
      message={copy.message}
      note={copy.note} />
  );
};

CallsTableEmpty.displayName = 'Project.Calls.Table.Empty';

const copy = {
  message: `No interviews available.`,
  note: `Schedule interviews with respondents to see your upcoming interview schedule.`,
};