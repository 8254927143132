import { useContext } from 'react';
import { Link } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import { GroupContactRecordContext } from './Context';
import styles from './style/ContactRecord.css';

type Props = unknown;

export const Breadcrumbs = (props: Props) => {
  const ctx = useContext(GroupContactRecordContext);

  if (!ctx.query.data) {
    return (
      <div className={styles.breadcrumbs}>
        <Skeleton
          height={30}
          width={320}
          variant="text" />
      </div>
    );
  }

  return (
    <div className={styles.breadcrumbs}>
      <div className={styles.paths}>
        <Link to="/">Home</Link>
        <span>{`>`}</span>
        <Link to="/contacts">Contacts</Link>
        <span>{`>`}</span>
        {`${ctx.query.data.contact.firstName} ${ctx.query.data.contact.lastName}`}
      </div>
    </div>
  );
};

Breadcrumbs.displayName = 'Contacts.Record.Breadcrumbs';