import { createElement } from 'react';
import * as utils from './utils';

type Props = {
  children: React.ReactNode;
};

type Condition<T = boolean> = T | (() => boolean);

type FallbackType = React.ComponentType | React.ReactNode;

const createConditional = (arg: Condition, fallback: FallbackType = null) => {
  const condition = typeof arg === 'function' ? arg() : !!arg;

  const Conditional = (props: Props) => {
    const children = !condition
      ? renderFallback(fallback)
      : props.children;

    return (
      <>
        {children}
      </>
    );
  };

  return Conditional;
};

function isComponent(c: FallbackType): c is React.ComponentType {
  return typeof c === 'function';
}

function renderFallback(element: FallbackType) {
  return isComponent(element)
    ? createElement(element)
    : element;
}

export const conditional = utils;

export { createConditional };