import http from '@services/http';
import type { Tags } from './interfaces';

export const getPersonTags = ({ userId }: Tags.GetGroupPersonTags.Request) => {
  return http.get(`/tags/group/person/${userId}`)
    .then((data: Tags.GetGroupPersonTags.Response) => ({
      tags: data.tags,
    }));
};

export const updatePersonTags = ({ userId, ...payload }: Tags.UpdateGroupPersonTags.Request) => {
  return http.put(`/tags/group/person/${userId}`, payload)
    .then((data: Tags.UpdateGroupPersonTags.Response) => ({
      tags: data.tags,
    }));
};

export const getFileTags = ({ fileId }: Tags.GetGroupFileTags.Request) => {
  return http.get(`/tags/group/file/${fileId}`)
    .then((data: Tags.GetGroupFileTags.Response) => ({
      tags: data.tags,
    }));
};

export const updateFileTags = ({ fileId, ...payload }: Tags.UpdateGroupFileTags.Request) => {
  return http.put(`/tags/group/file/${fileId}`, payload)
    .then((data: Tags.UpdateGroupFileTags.Response) => ({
      tags: data.tags,
    }));
};

export const massAddObjectTags = ({ objectIds, tags }: Tags.MassAddFileTags.Request) => {
  return http.put(`/tags/group/objects`, { objectIds, tags });
};

export const getCallTags = ({ callId }: Tags.GetGroupCallTags.Request) => {
  return http.get(`/tags/group/call/${callId}`)
    .then((data: Tags.GetGroupCallTags.Response) => ({
      tags: data.tags,
    }));
};

export const updateCallTags = ({ callId, ...payload }: Tags.UpdateGroupCallTags.Request) => {
  return http.put(`/tags/group/call/${callId}`, payload)
    .then((data: Tags.UpdateGroupCallTags.Response) => ({
      tags: data.tags,
    }));
};

export const getSurveyResponseTags = ({ surveyResponseId }: Tags.GetGroupSurveyResponseTags.Request) => {
  return http.get(`/tags/group/survey-response/${surveyResponseId}`)
    .then((data: Tags.GetGroupSurveyResponseTags.Response) => ({
      tags: data.tags,
    }));
};

export const updateSurveyResponseTags = ({ surveyResponseId, ...payload }: Tags.UpdateGroupSurveyResponseTags.Request) => {
  return http.put(`/tags/group/survey-response/${surveyResponseId}`, payload)
    .then((data: Tags.UpdateGroupSurveyResponseTags.Response) => ({
      tags: data.tags,
    }));
};

export const getTranscriptTags = ({ transcriptId }: Tags.GetGroupTranscriptTags.Request) => {
  return http.get(`/tags/group/transcript/${transcriptId}`)
    .then((data: Tags.GetGroupTranscriptTags.Response) => ({
      tags: data.tags,
    }));
};

export const updateTranscriptTags = ({ transcriptId, ...payload }: Tags.UpdateGroupTranscriptTags.Request) => {
  return http.put(`/tags/group/transcript/${transcriptId}`, payload)
    .then((data: Tags.UpdateGroupTranscriptTags.Response) => ({
      tags: data.tags,
    }));
};

export const getFolderTags = ({ folderId }: Tags.GetGroupFolderTags.Request) => {
  return http.get(`/tags/group/folder/${folderId}`)
    .then((data: Tags.GetGroupFolderTags.Response) => ({
      tags: data.tags,
    }));
};

export const updateFolderTags = ({ folderId, ...payload }: Tags.UpdateGroupFolderTags.Request) => {
  return http.put(`/tags/group/folder/${folderId}`, payload)
    .then((data: Tags.UpdateGroupFolderTags.Response) => ({
      tags: data.tags,
    }));
};

export const getProjectParentTags = ({ projectParentId }: Tags.GetGroupProjectParentTags.Request) => {
  return http.get(`/tags/group/project-parent/${projectParentId}`)
    .then((data: Tags.GetGroupProjectParentTags.Response) => ({
      tags: data.tags,
    }));
};

export const updateProjectParentTags = ({ projectParentId, ...payload }: Tags.UpdateGroupProjectParentTags.Request) => {
  return http.put(`/tags/group/project-parent/${projectParentId}`, payload)
    .then((data: Tags.UpdateGroupProjectParentTags.Response) => ({
      tags: data.tags,
    }));
};