import { memo } from 'react';
import { Helmet } from 'react-helmet';
import { path } from '@consts';
import { BannerFooter } from './BannerFooter';
import { BannerPricing } from './BannerPricing';
import { CollaborationTools } from './CollaborationTools';
import { Features } from './Features';
import { Feedback } from './Feedback';
import { Landing } from './Landing';
import { Services } from './Services';
import { Testimonials } from './Testimonials';
import styles from './style/CustomerResearch.css';

type Props = unknown;

const H = memo(() => {
  const description = 'Sentiment is used by many of the most sophisticated product and marketing teams to align their products with their market.';

  return (
    <Helmet
      title="Customer Research">
      <link
        rel="canonical"
        href={`${process.env.FRONT_BASE_URL}${path.Website.CustomerResearch}`} />
      <meta
        name='description'
        content={description} />
      <meta
        name='og:description'
        content={description} />
    </Helmet>
  );
});

export const CustomerResearch = (props: Props) => {

  return (
    <div className={styles.root}>
      <H />
      <div className={styles.wrap}>
        <Landing />
        <Feedback />
        <Services />
        <BannerPricing />
        <Features />
        <CollaborationTools />
        <Testimonials />
        <BannerFooter />
      </div>
    </div>
  );
};

CustomerResearch.displayName = 'CustomerResearch';