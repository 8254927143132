import { useContext } from 'react';
import { PaidMessagingContext } from '@containers/PlatformMessage/Context';

export const usePaidMessagingModalState = () => {
  const context = useContext(PaidMessagingContext);

  if (!context && process.env.__DEV__) {
    throw new Error('`usePaidMessagingModalState` must be wrapped inside an `PaidMessagingContainer`');
  }

  return context;
};