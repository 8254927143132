import { useReducer, useRef } from 'react';
import cuid from 'cuid';
import { VideoPostIdentifierContext, VideoStateContext } from './Context';
import * as PostCreation from './interfaces';

type Props = {
  children: React.ReactNode;
};

export const StateContainer = (props: Props) => {
  const identifier = useRef(cuid()).current;
  const state = useReducer(reducer, initialState);

  return (
    <VideoPostIdentifierContext.Provider value={identifier}>
      <VideoStateContext.Provider value={state}>
        {props.children}
      </VideoStateContext.Provider>
    </VideoPostIdentifierContext.Provider>
  );
};

StateContainer.displayName = 'PostCreation.Post.Video.StateContainer';

function reducer(state: PostCreation.Video.State, action: PostCreation.Video.Action) {
  switch (action.type) {

    case 'set-thumbnail-file':
      return {
        ...state,
        thumbnail: action.file,
      };

    case 'set-video-file':
      return {
        ...state,
        snapshot: !action.file ? null : state.snapshot,
        video: action.file,
      };

    case 'set-video-snapshot':
      return {
        ...state,
        snapshot: action.src,
      };

    case 'update-post-body':
      return {
        ...state,
        body: action.value,
      };

    case 'update-video-description':
      return {
        ...state,
        description: action.value,
      };

    case 'update-video-title':
      return {
        ...state,
        title: action.value,
      };
  }
}

const initialBody = [
  {
    type: 'paragraph',
    children: [
      {
        text: '',
      },
    ],
  },
];

const initialState: PostCreation.Video.State = {
  body: { value: initialBody },
  description: { value: initialBody },
  snapshot: null,
  thumbnail: null,
  title: '',
  video: null,
};