import { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as consts from '@consts';
import { useAppReadyState } from '@containers/AppReadyState';
import { getLocationFor } from '@utils';

type Params = {
  callId: string;
};

export const Confirmation = () => {
  const params = useParams<Params>();
  const history = useHistory();
  const app = useAppReadyState();

  const handleRedirect = useCallback(() => {
    const queryParams = new URLSearchParams(location.search);

    const url = getLocationFor.scheduling.adHoc.selecting({
      callId: +params.callId,
      scheduleeId: +queryParams.get('scheduleeId'),
      schedulerId: +queryParams.get('schedulerId'),
    });

    history.replace({
      ...url,
      search: `?from=${consts.pathname.Home}`,
    });
  }, [
    history,
    params,
  ]);

  useEffect(() => {

    if (app.initialized) {
      handleRedirect();
    }

  }, [
    app.initialized,
    handleRedirect,
  ]);

  return null;
};

export default Confirmation;