import { useCallback, ChangeEvent } from 'react';
import { ButtonActivityIndicator } from '@presentation';
import { useLock } from '@utils';
import { Textarea } from '@/components/Textarea';
import styles from './style/Text.css';

type Props = {
  canPreview: boolean;
  onPreview: () => Promise<unknown>;
  setText: (value: string) => unknown;
  text: string;
};

export const PreCallRecordingText = ({ canPreview, onPreview, setText, text }: Props) => {

  const [busy, lock] = useLock();

  const handleChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  }, [setText]);

  return (
    <div>
      <Textarea
        className={styles.textarea}
        onChange={handleChange}
        value={text} />
      <div className={styles.footer}>
        <ButtonActivityIndicator
          variant="brick"
          disabled={!canPreview}
          loading={busy}
          onClick={lock(onPreview)}>
          Preview
        </ButtonActivityIndicator>
      </div>
    </div>
  );
};

export default PreCallRecordingText;
