import { RequestCallContainer } from '@containers/RequestCall/RequestCallContainer';
import { SearchContainer } from '@containers/WorkspaceGlobalSearch/SearchContainer';
import Main from './Search.Main';

export default function Search() {
  return (
    <SearchContainer>
      <RequestCallContainer>
        <Main />
      </RequestCallContainer>
    </SearchContainer>
  );
}

export { Search };