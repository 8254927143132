import { useCallback } from 'react';
import type { SurveyResponseItem } from '@/types';
import { SurveyQuestionType } from '@/enums';
import { cx } from '@utils';
import ConjointAnalysisAnswer from './ConjointAnalysis';
import TextAnswerValue from './TextResponse';
import MatrixGridAnswerValue from './MatrixGrid';
import MatrixSliderAnswerValue from './MatrixSlider';
import MaxDiff from './MaxDiff';
import MultipleChoiceAnswerValue from './MultipleChoice';
import MultiselectAnswerValue from './Multiselect';
import RankingAnswerValue from './Ranking';
import MatrixMultiselectAnswerValue from './MatrixMultiselect';
import MultiTextbox from './MultiTextbox';
import NumberInputTableAnswerValue from './NumberInputTable';
import OptionGroupsAnswer from './OptionGroups';
import styles from './style/Answer.css';
import { MatrixRangeAnswerValue } from './MatrixRange';
import { ImageMarkupAnswerValue } from './ImageMarkup';

type Props = {
  className?: string;
  item: SurveyResponseItem;
  showHidden?: boolean;
};

export const SurveyResponseAnswer = ({ className, item, showHidden }: Props) => {
  const renderContent = useCallback(() => {

    switch (item.question.typeId) {
      case SurveyQuestionType.MultipleChoice:
        assertData<SurveyQuestionType.MultipleChoice>(item);
        return (
          <MultipleChoiceAnswerValue
            answer={item.answer}
            rationale={item.rationale} />
        );

      case SurveyQuestionType.Dropdown:
        assertData<SurveyQuestionType.Dropdown>(item);
        return (
          <MultipleChoiceAnswerValue
            answer={item.answer}
            rationale={item.rationale} />
        );

      case SurveyQuestionType.LongTextResponse:
      case SurveyQuestionType.ShortTextResponse: {
        assertData<SurveyQuestionType.ShortTextResponse>(item);
        if (item.hidden && !showHidden) {
          return 'Answer is hidden for compliance reasons';
        } else {
          return (
            <TextAnswerValue
              answer={item.answer}
              rationale={item.rationale} />
          );
        }
      }

      case SurveyQuestionType.Multiselect:
        assertData<SurveyQuestionType.Multiselect>(item);
        return (
          <MultiselectAnswerValue
            answer={item.answer}
            rationale={item.rationale} />
        );

      case SurveyQuestionType.Ranking:
        assertData<SurveyQuestionType.Ranking>(item);
        return (
          <RankingAnswerValue
            settings={item.question.settings}
            answer={item.answer}
            rationale={item.rationale} />
        );

      case SurveyQuestionType.MatrixGrid:
        assertData<SurveyQuestionType.MatrixGrid>(item);
        return (
          <MatrixGridAnswerValue
            answer={item.answer}
            rationale={item.rationale} />
        );

      case SurveyQuestionType.Sliders:
        assertData<SurveyQuestionType.Sliders>(item);
        return (
          <MatrixSliderAnswerValue
            answer={item.answer}
            question={item.question}
            rationale={item.rationale} />
        );

      case SurveyQuestionType.MatrixMultiselect:
        assertData<SurveyQuestionType.MatrixMultiselect>(item);
        return (
          <MatrixMultiselectAnswerValue
            answer={item.answer}
            rationale={item.rationale} />
        );

      case SurveyQuestionType.ConjointAnalysis:
        assertData<SurveyQuestionType.ConjointAnalysis>(item);
        return (
          <ConjointAnalysisAnswer item={item} />
        );

      case SurveyQuestionType.MaxDifference:
        assertData<SurveyQuestionType.MaxDifference>(item);
        return <MaxDiff item={item} />;

      case SurveyQuestionType.NumberInputTable:
        assertData<SurveyQuestionType.NumberInputTable>(item);
        return (
          <NumberInputTableAnswerValue
            question={item.question}
            answer={item.answer}
            rationale={item.rationale} />
        );

      case SurveyQuestionType.MultiTextbox:
        assertData<SurveyQuestionType.MultiTextbox>(item);
        return (
          <MultiTextbox
            question={item.question}
            answer={item.answer}
            rationale={item.rationale} />
        );

      case SurveyQuestionType.ExclusiveOptions:
        assertData<SurveyQuestionType.ExclusiveOptions>(item);
        return (
          <OptionGroupsAnswer
            question={item.question}
            answer={item.answer}
            rationale={item.rationale} />
        );

      case SurveyQuestionType.MatrixRange:
        assertData<SurveyQuestionType.MatrixRange>(item);
        return (
          <MatrixRangeAnswerValue
            question={item.question}
            answer={item.answer}
            rationale={item.rationale} />
        );

      case SurveyQuestionType.ImageMarkup:
        assertData<SurveyQuestionType.ImageMarkup>(item);
        return (
          <ImageMarkupAnswerValue
            question={item.question}
            answer={item.answer} />
        );

      default:
        throw new UnreachableCaseError(item.question);
    }

  }, [item, showHidden]);

  return (
    <div className={cx(styles.root, className)}>
      {renderContent()}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
function assertData<T extends SurveyQuestionType>(d: SurveyResponseItem): asserts d is SurveyResponseItem<T> { }

export default SurveyResponseAnswer;