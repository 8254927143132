import { PipelineSocketContainer } from './PipelineContainer';
import { UserSocketContainer } from './UserContainer';

type Props =
  ChildrenProps;

export const WebSocketContainer = (props: Props) => {

  return (
    <UserSocketContainer>
      <PipelineSocketContainer>
        {props.children}
      </PipelineSocketContainer>
    </UserSocketContainer>
  );
};

export default WebSocketContainer;