import { useCallback, useContext } from 'react';
import { InitiateFilesReviewContext } from '../Context';

type Props = {
  parentObjectId: number;
  workspaceId: number;
};

export const useInitiateFilesReview = (props: Props) => {
  const initiateReview = useContext(InitiateFilesReviewContext);

  return useCallback((files: File[], isTranscriptImport?: boolean) => {
    return initiateReview({
      isTranscriptImport,
      files,
      parentObjectId: props.parentObjectId,
      workspaceId: props.workspaceId,
    });
  }, [
    props,
    initiateReview,
  ]);

};

export default useInitiateFilesReview;