import { useMemo } from 'react';
import * as utils from '@utils';
import { useUserRoles } from './useUserRoles';

export const useHasConsultantRole = () => {
  const roles = useUserRoles();

  const hasConsultantRole = useMemo(() => {
    return utils.hasConsultantRole({ roles });
  }, [roles]);

  return hasConsultantRole;
};

export default useHasConsultantRole;