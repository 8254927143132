import { useCallback } from 'react';
import { Accordion } from '@/components/Accordion';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder';
import styles from './style/LanguageValidationContent.css';

type Props = {
  validationErrors: Record<string, string[]>;
};

export const LanguageValidationContent = (props: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.title}>There are some items missing translated versions.</div>
      <div className={styles.subtitle}>Do you want to save?</div>
      <div>
        {Object.entries(props.validationErrors).filter(([k, v]) => v.length).map(([k, v]) => (
          <ValidationAccordion
            key={k}
            languageCode={k}
            missingKeys={v} />
        ))}
      </div>
    </div>
  );
};

type AccordionProps = {
  languageCode: string;
  missingKeys: string[];
};

const ValidationAccordion = (props: AccordionProps) => {
  const [state] = useSurveyBuilderState();

  const getKeyDisplayValue = useCallback((key: string) => {
    return key;
  }, []);

  return (
    <Accordion
      grows={false}
      showCount={false}
      label={props.languageCode}>
      <div className={styles.accordionRoot}>
        {props.missingKeys.map(k => (
          <div key={k}>
            {getKeyDisplayValue(k)}
          </div>
        ))}
      </div>
    </Accordion>
  );
};