/** @jsxImportSource @emotion/react */
import { useCallback, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { ChatHintType } from '@/brand-insights/enums';
import { CancelAction, MessageQueryHint } from '@/brand-insights/components/presentation';
import type { Chat } from '@/brand-insights/types';
import { ActiveChatSessionContext } from './context';
import { UserMessage } from './Message.User';
import { BaseSystemUserMessage } from './Message.System';
import { useSegmentationConfirmationMutation } from './hooks/useSegmentationConfirmation';
import { useSessionSegmentation } from './hooks/useSessionSegmentation';
import { useCancelSegmentationMutation, useScrollToBottomOfMessages } from './hooks';

type Props = {
  identifier: Chat.Segmentation.Segmentation['identifier'];
  tagSelection: Chat.Segmentation.TagSelection.ScreenData;
  dimensionSelection: Chat.Segmentation.DimensionSelection.ScreenData;
  data: Chat.Segmentation.Confirmation.ScreenData;
  readonly: boolean;
};

export const SegmentationConfirmation = (props: Props) => {
  return (
    <>
      <MessageContainer>
        <UserMessage value={<SegmentationConfirmationUserMessage {...props} />} />
      </MessageContainer>
      <MessageContainer>
        <BaseSystemUserMessage extendedWidth>
          <SegmentationConfirmationSystemMessage {...props} />
        </BaseSystemUserMessage>
      </MessageContainer>
    </>
  );
};

export const SegmentationConfirmationUserMessage = (props: Props) => {
  return (
    <div>
      <UserHeaderCopy>
        {copy.user.header}
      </UserHeaderCopy>
      <Dimensions css={{ marginBottom: 0 }}>
        {props.dimensionSelection.dimensions.map((dimension, idx) => (
          <DimensionItem key={`${idx}`}>
            {dimension}
          </DimensionItem>
        ))}
      </Dimensions>
    </div>
  );
};

export const SegmentationConfirmationSystemMessage = (props: Props) => {
  const chat = useContext(ActiveChatSessionContext);
  const segmentation = useSessionSegmentation({ segmentationIdentifier: props.identifier });
  const segmentationConfirmation = useSegmentationConfirmationMutation();
  const cancel = useCancelSegmentationMutation();
  const scrollToBottom = useScrollToBottomOfMessages();

  const handleContinue = useCallback(() => {
    segmentationConfirmation.mutateAsync({
      chatInstanceIdentifier: chat.identifier,
      context: chat.context,
      segmentation,
    }).then(() => scrollToBottom());
  }, [
    segmentationConfirmation,
    chat.identifier,
    chat.context,
    segmentation,
    scrollToBottom,
  ]);

  const handleCancel = useCallback(() => {
    cancel.mutateAsync({
      chatInstanceIdentifier: chat.identifier,
      context: chat.context,
      segmentation,
    })
    .then(() => scrollToBottom());
  }, [
    cancel,
    chat.identifier,
    chat.context,
    segmentation,
    scrollToBottom,
  ]);

  const selectedTags = useMemo(() => props.tagSelection.tags.filter(t => t.selected), [props.tagSelection.tags]);

  const canContinue = useMemo(() => !segmentationConfirmation.isLoading, [segmentationConfirmation]);

  const view = {
    actions: !props.readonly,
  };

  return (
    <Root>
      <SystemHeaderCopy>
        {copy.system.header}
      </SystemHeaderCopy>
      <SummaryCopy>
        {`You would like to analyze`} {selectedTags.map((t, i) => (
          <span key={t.tag.id}>
            <b>{t.tag.name}</b>{i !== selectedTags.length - 1 ? `, ` : null}
          </span>
        ))} {`tagged data on the following dimensions:`}
      </SummaryCopy>
      <Dimensions>
        {props.dimensionSelection.dimensions.map((dimension, idx) => (
          <DimensionItem key={`${idx}`}>
            {dimension}
          </DimensionItem>
        ))}
      </Dimensions>
      {view.actions && (
        <Actions>
          <MessageQueryHint
            disabled={!canContinue}
            hint={{ type: ChatHintType.AnalysisTags, displayValue: copy.confirm }}
            onClick={handleContinue} />
          <CancelAction
            displayValue={copy.cancel}
            onClick={handleCancel} />
        </Actions>
      )}
    </Root>
  );
};

export const copy = {
  user: {
    header: `I'd like to analyze the following topics:`,
  },
  system: {
    header: `Ok, great! Let's confirm the analysis inputs before we run it!`,
  },
  cancel: `Actually, I'd like to do something else.`,
  confirm: `Yes, let's run the analysis!`,
};

const MessageContainer = styled.div`
  padding: 15px 20px;
`;

const Root = styled.div`
  box-sizing: border-box;
`;

const UserHeaderCopy = styled.div`
  box-sizing: border-box;
  margin-bottom: 10px;
`;

const SystemHeaderCopy = styled.div`
  box-sizing: border-box;
  border-bottom: 1px solid ${props => props.theme.palette.gray.light1};
  padding-bottom: 10px;
  margin-bottom: 10px;
`;

const SummaryCopy = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
`;

const Dimensions = styled.ul`
  padding-inline-start: 1em;
  margin: 0 0 20px 0;

  li + li {
    margin-top: 10px;
  }
`;

const DimensionItem = styled.li`
`;

const Actions = styled.div`
  margin-top: 15px;

  > :not(:last-child) {
    margin-bottom: 10px;
  }
`;