import { useCallback } from 'react';
import { ButtonActivityIndicator } from '@presentation';
import { useAsyncStateLazy } from '@utils';
import * as api from '$admin/api';
import Button from '@/components/Button';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { Header } from '@/components/Modal/Header';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import { Project } from '$admin/interfaces';
import styles from './style/Project.RejectMember.css';

type RemoveItem = {
  id: Project.Lead.Item['id'];
};

type Props = {
  projectId: number;
  items: RemoveItem[];
  onConfirm?: () => unknown;
} & Pick<ModalProps, 'open' | 'onClose'>;

export const RemoveLeads = ({ projectId, items, open, onClose, onConfirm }: Props) => {
  const handleConfirm = useCallback(() => {
    return api.projects.leads.removeLeads({
      projectId: projectId,
      ids: items.map(i => i.id),
    })
    .finally(onConfirm);
  }, [projectId, items, onConfirm]);

  const [response, confirm] = useAsyncStateLazy(handleConfirm, [handleConfirm]);

  return (
    <Modal
      onClose={onClose}
      open={open}>
      <Header>{`Remove ${items.length} lead${items.length > 1 ? 's' : ''}?`}</Header>
      <ButtonSet className={styles.buttonset}>
        <Button
          className={styles.cancel}
          color="destructive"
          onClick={onClose}
          variant="brick">
          Cancel
        </Button>
        <ButtonActivityIndicator
          className={styles.btn}
          color="primary"
          loading={response.loading}
          onClick={confirm}
          variant="brick">
          Remove
        </ButtonActivityIndicator>
      </ButtonSet>
    </Modal>
  );
};

RemoveLeads.displayName = 'Modal.Project.RemoveLeads';