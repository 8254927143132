import { Component } from 'react';
import { SettingsTitle } from '@/components/Settings';
import * as enums from '@enums';
import Fields from '../Fields';
import styles from './style.css';

class ReviewControls extends Component {

  renderCallReview = () => {
    const dropdownOptions = [
      { id: enums.ReviewOption.Disabled, text: 'No Calls Require Review' },
      { id: enums.ReviewOption.EnabledForAll, text: 'All Calls Require Review' },
      { id: enums.ReviewOption.EnabledForFlagged, text: 'Calls With Flagged Experts Require Review' },
    ];

    const selected = dropdownOptions.find(i => i.id === this.props.features.callReviewStatus);

    return (
      <Fields.ReadOnly
        value={selected.text}
        label="Call Review" />
    );
  }

  renderSurveyReview = () => {
    const dropdownOptions = [
      { id: enums.ReviewOption.Disabled, text: 'No Surveys Require Review' },
      { id: enums.ReviewOption.EnabledForAll, text: 'All Surveys Require Review' },
      { id: enums.ReviewOption.EnabledForFlagged, text: 'Flagged Surveys Require Review' },
    ];

    const selected = dropdownOptions.find(i => i.id === this.props.features.surveyReviewStatus);

    return (
      <Fields.ReadOnly
        value={selected.text}
        label="Survey Review" />
    );
  }

  renderMessagesReview = () => {
    if (this.props.features.disableMessaging) {
      return null;
    }

    const dropdownOptions = [
      { id: enums.ReviewOption.Disabled, text: 'No Messages Require Review' },
      { id: enums.ReviewOption.EnabledForAll, text: 'All Messages Require Review' },
      { id: enums.ReviewOption.EnabledForFlagged, text: 'Flagged Messages Require Review' },
    ];

    const selected = dropdownOptions.find(i => i.id === this.props.features.messagesReviewStatus);

    return (
      <Fields.ReadOnly
        value={selected.text}
        label="Messages Review" />
    );
  }

  renderPublicCompanies = () => {
    const months = this.props.features.publicCompanyExclusionMonths;
    const value = months == 0 || months > 0
      ? `In the past ${months} Months`
      : 'No Review';

    return (
      <Fields.ReadOnly
        value={value}
        label="Experts with employment at a public company" />
    );
  }

  renderHeader = () => {
    const tooltipText = [
      `Expert profiles with the following criteria`,
      `will be flagged for review and excluded from`,
      `all searches for any users associated with this account`,
    ].join('\n');

    return (
      <SettingsTitle
        title="Review Settings"
        helpText={tooltipText} />
    );
  }

  render() {
    return(
      <>
        {this.renderHeader()}
        <div className={styles.info}>
          {this.renderCallReview()}
          {this.renderSurveyReview()}
          {this.renderMessagesReview()}
          {this.renderPublicCompanies()}
          <Fields.ReadOnly
            value={this.props.features.excludeGovOfficials ? 'Require Review' : 'No Review'}
            label="Government officials" />
          <Fields.ReadOnly
            value={this.props.features.excludeClinicalTrials ? 'Require Review' : 'No Review'}
            label="Experts involved in clinical trials" />
        </div>
      </>
    );
  }
}

export default ReviewControls;