import { useFlexLayout, useTable } from 'react-table';
import Skeleton from '@mui/material/Skeleton';
import * as Table from '@/components/Table';
import styles from './style/Skeleton.css';

export const TabViewTableSkeleton = () => {
  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable<ObjectLike>({
    columns: Columns,
    data: Data,
  }, useFlexLayout);

  return (
    <Table.Layout.Box>
      <Table.Layout.Header
        title={
          <Skeleton
            animation="wave"
            height={30}
            width={200}
            variant="rounded" />
        } />
      <Table.Root loading>
        <Table.Header headerGroups={headerGroups} />
        <Table.Body
          {...getTableBodyProps()}
          prepareRow={prepareRow}
          rows={rows} />
        <Table.Layout.Footer />
      </Table.Root>
    </Table.Layout.Box>
  );
};

export const TabViewSkeleton = () => {

  return (
    <div className={styles.skel}>
      <TabViewTabBarSkeleton />
      <div className={styles.view}>
        <TabViewTableSkeleton />
      </div>
    </div>
  );
};

TabViewSkeleton.displayName = 'Tabs.Skeleton';

export const TabViewTabBarSkeleton = () => {
  return (
    <div className={styles.bar}>
      <Skeleton
        animation="wave"
        className={styles.tab}
        height={45}
        width={150}
        variant="rectangular" />
      <Skeleton
        animation="wave"
        className={styles.tab}
        height={45}
        width={150}
        variant="rectangular" />
      <Skeleton
        animation="wave"
        className={styles.tab}
        height={45}
        width={150}
        variant="rectangular" />
    </div>
  );
};

TabViewTabBarSkeleton.displayName = 'Tabs.TabBar.Skeleton';

const Data = Array.from({ length: 25 }, _ => ({} as ObjectLike));

const Columns = [
  {
    Cell: () => null,
    width: 200,
    id: '1',
  },
  {
    Cell: () => null,
    width: 200,
    id: '2',
  },
  {
    Cell: () => null,
    width: 200,
    id: '3',
  },
];