import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { cx } from '@utils';
import styles from './style/Carousel.css';

type Props = {
  className?: string;
  back:       () => void;
  canGoBack:  boolean;
  canGoNext:  boolean;
  next:       () => void;
};

export const ButtonSet = (props: Props) => {

  return (
    <div className={cx(styles.buttons, props.className)}>
      <div className={styles.nav}>
        <button
          className={styles.btn}
          disabled={!props.canGoBack}
          onClick={props.back}>
          <ChevronLeft />
        </button>
      </div>
      <div className={styles.nav}>
        <button
          className={styles.btn}
          disabled={!props.canGoNext}
          onClick={props.next}>
          <ChevronRight />
        </button>
      </div>
    </div>
  );
};

ButtonSet.displayName = 'Carousel.ButtonSet';