import { AlertCircle } from 'react-feather';
import { Tooltip } from '@presentation/Tooltip';
import { Spinner } from '@/components/ActivityIndicator';
import { cx } from '@/utils';
import { FileTranscriptStatus, type WorkspaceFileTranscript } from '@/types/workspace';
import styles from './style/TranscriptStatus.css';

type Props = {
  className?: string;
  transcript: WorkspaceFileTranscript;
};

export const TranscriptStatus = ({ className, transcript }: Props) => {

  if (!transcript) return null;

  if (transcript.status === FileTranscriptStatus.InProgress) {
    return (
      <Tooltip title="Transcript is being processed">
        <div className={className}>
          <Spinner />
        </div>
      </Tooltip>
    );
  }

  if (transcript.status === FileTranscriptStatus.Errored) {
    return (
      <Tooltip title="Error during transcription">
        <AlertCircle className={cx(styles.alert, className)} />
      </Tooltip>
    );
  }

  return (
    <>
    </>
  );
};