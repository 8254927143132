import { memo } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { cx } from '@utils';
import { CreatedByWhenLabelProps } from './interfaces';
import styles from './style/CreatedByWhenLabel.css';

type Props =
  CreatedByWhenLabelProps;

const By = memo((props: Props) => {
  return <>Created by {props.by}</>;
});

const When = memo((props: Props) => {
  return <>{` ${props.when}`}</>;
});

export const CreatedByWhenLabel = memo((props: Props) => {
  const root = cx(styles.root, props.className);

  return (
    <div className={root}>
      <By {...props} />
      <When {...props} />
    </div>
  );
});

export const CreatedByWhenSkeleton = memo(() => {

  return (
    <Skeleton
      style={{ marginRight: 10 }}
      height={16}
      width="250px"
      variant="rectangular" />
  );
});