import { useFiltersState } from '@containers/UniversalSearch/hooks';
import {
  useFetchUniversalSearchPageExperts, useFetchUniversalSearchPagePosts,
  useFetchUniversalSearchPageTopics,
} from '@utils/api';
import { formatPlurality } from '@utils';
import { IUniversalSearch } from '@/types';
import ExpertsTable from './Search.Table.Experts';
import PostsTable from './Search.Table.Posts';
import TopicsTable from './Search.Table.Topics';
import styles from './style/Tab.css';

const pageSize = 5;

export default function SearchTabAll() {
  const [query] = useFiltersState();
  const experts = useFetchUniversalSearchPageExperts(query.q, 50, {
    enabled: !!query.q?.length,
    refetchOnWindowFocus: false,
  });
  const topics = useFetchUniversalSearchPageTopics(query.q, 50, {
    enabled: !!query.q?.length,
    refetchOnWindowFocus: false,
  });
  const posts = useFetchUniversalSearchPagePosts(query.q, 50, {
    enabled: !!query.q?.length,
    refetchOnWindowFocus: false,
  });

  return (
    <div className={styles.root}>
      <Experts
        result={experts.data}
        isLoading={experts.isInitialLoading} />
      <Topics
        result={topics.data}
        isLoading={topics.isInitialLoading} />
      <Posts
        result={posts.data}
        isLoading={topics.isInitialLoading} />
    </div>
  );
}

function Experts({ result, isLoading }: ExpertsProps) {
  const data = result?.items ?? [];
  const seeAll = result?.total > pageSize;
  const countText = !isLoading
    ? `(${getCountLabel(Math.min(pageSize, data.length), result?.total, 'Expert')})`
    : null;

  return (
    <ExpertsTable
      data={data}
      countText={countText}
      pageSize={pageSize}
      isLoading={isLoading}
      seeAll={seeAll} />
  );
}

function Posts({ result, isLoading }: PostsProps) {
  const data = result?.items ?? [];
  const seeAll = result?.total > pageSize;
  const countText = !isLoading
    ? `(${getCountLabel(Math.min(pageSize, data.length), result?.total, 'Post')})`
    : null;

  return (
    <PostsTable
      data={data}
      countText={countText}
      pageSize={pageSize}
      isLoading={isLoading}
      seeAll={seeAll} />
  );
}

function Topics({ result, isLoading }: TopicsProps) {
  const data = result?.items ?? [];
  const seeAll = result?.total > pageSize;
  const countText = !isLoading
    ? `(${getCountLabel(Math.min(pageSize, data.length), result?.total, 'Topic')})`
    : null;

  return (
    <TopicsTable
      data={data}
      countText={countText}
      pageSize={pageSize}
      isLoading={isLoading}
      seeAll={seeAll} />
  );
}

function getCountLabel(pageItems: number, total: number, label: string) {
  return total > pageItems
    ? `+${pageSize} ${formatPlurality(label, pageSize)}`
    : `${pageItems} ${formatPlurality(label, pageItems)}`;
}

type ExpertsProps = {
  result: IUniversalSearch.SearchPageResultExpertItems;
  isLoading: boolean;
};

type PostsProps = {
  result: IUniversalSearch.SearchPageResultPostItems;
  isLoading: boolean;
};

type TopicsProps = {
  result: IUniversalSearch.SearchPageResultTopicItems;
  isLoading: boolean;
};