import { useCallback, useMemo, useState } from 'react';
import { useToggle } from '@utils';
import { usePreCallRecordingPreview, usePreCallRecordingSave, usePreCallRecordingUrl, usePreCallRecordingValue } from '$admin/containers/PreCallRecording';
import { Header } from '@/components/Modal/Header';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import { useModal } from '@/components/Modal/hooks/useModal';
import Audio from './Audio';
import Text from './Text';
import styles from './style/Modal.css';

type Props =
  Pick<ModalProps, 'open' | 'onClose'>;

export const PreCallRecordingModal = ({ onClose, open }: Props) => {

  const [showPreview, togglePreview] = useToggle(false);
  const preview = usePreCallRecordingPreview();
  const save = usePreCallRecordingSave();
  const url = usePreCallRecordingUrl();
  const initialValue = usePreCallRecordingValue();
  const [text, setText] = useState<string>(initialValue?.text || '');
  const canPreview = useMemo(() => !!text, [text]);
  const canSave = useMemo(() => text !== initialValue?.text, [initialValue, text]);

  const handlePreview = useCallback(() => {
    return preview(text)
      .then(_ => {
        togglePreview();
      });
  }, [
    preview,
    text,
    togglePreview,
  ]);

  const handleSave = useCallback(() => {
    return save()
      .then(() => {
        onClose();
      });
  }, [
    onClose,
    save,
  ]);

  return (
    <Modal open={open} onClose={onClose}>
      <div className={styles.root}>
        <Header text="Pre Call Recording" />
        {showPreview
          ? (
            <Audio
              canSave={canSave}
              onSave={handleSave}
              onViewText={togglePreview}
              url={url} />
          ) : (
            <Text
              canPreview={canPreview}
              onPreview={handlePreview}
              setText={setText}
              text={text} />
          )
        }
      </div>
    </Modal>
  );
};

export const usePreCallRecordingModal = () => useModal(PreCallRecordingModal);

export default PreCallRecordingModal;