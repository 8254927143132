import { useCallback, useMemo } from 'react';
import { useProjectState } from '@containers/GroupProject/hooks/useProjectState';
import { getLocationFor } from '@utils';
import { ProjectSurveyResponsesStateContext } from './Context';
import { useMatchResponsesStateFromUrl } from './hooks';
import type { SurveyResponses } from './interfaces';

export const SurveyResponsesStateContainer = (props: ChildrenProps) => {

  const [query, setQuery, resetQueryState] = useMatchResponsesStateFromUrl();

  const state = useProjectState();

  const basePath = useMemo(() => {

    return getLocationFor.project.surveyResponses({ slug: state.project.slug }).pathname;
  }, [state.project.slug]);

  const setQueryState = useCallback((data: Partial<Omit<SurveyResponses.QueryState.Value, 'questionId'>>) => {
    setQuery({
      ...data,
      questionId: query.questionId,
      surveyId: query.surveyId,
    }, 'push');
  }, [
    query.questionId,
    query.surveyId,
    setQuery,
  ]);

  const value = {
    basePath,
    query,
    resetQueryState,
    setQueryState,
  };

  return (
    <ProjectSurveyResponsesStateContext.Provider value={value}>
      {props.children}
    </ProjectSurveyResponsesStateContext.Provider>
  );
};

export default SurveyResponsesStateContainer;