import { Calendar } from 'react-feather';
import { Link } from 'react-router-dom';
import { useSelectProject, useSelectUser } from '@containers/Store/hooks';
import { cx, getLocationFor } from '@utils';
import { ProjectCall } from '@/types';
import { Button } from '@/components/Button';
import styles from './style/Invitations.Item.css';

type Props = {
  className?: string;
  item: ProjectCall;
};

export const ProjectCallInvitation = ({ className, item }: Props) => {

  const me = useSelectUser();
  const project = useSelectProject(item.projectId);

  const location = getLocationFor.scheduling.selecting({
    callId: item.id,
    projectId: item.projectId,
    scheduleeId: me.id,
    schedulerId: item.creatorUserId,
  });

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.wrap}>
        <div className={styles.content}>
          <Calendar className={styles.icon} />
          <div className={styles.details}>
            <Link to={location} className={styles.title}>
              {project.name}
            </Link>
            <div className={styles.description}>
              Review availability and book a time to speak.
            </div>
          </div>
        </div>
        <Button.Secondary
          className={styles.btn}
          variant="brick"
          to={location}>
          Confirm
        </Button.Secondary>
      </div>
    </div>
  );
};

export default ProjectCallInvitation;