export const Admin         = 'Admin';
export const Calendar      = 'Calendar';
export const Home          = 'Home';
export const InsightsChat  = 'Insights Chat';
export const Logout        = 'Log Out';
export const Messages      = 'Messages';
export const Notifications = 'Notifications';
export const Profile       = 'Profile';
export const Projects      = 'Projects';
export const Research      = 'Research';
export const Search        = 'Search';
export const Settings      = 'Settings';
export const Share         = 'Share';