import { useCallback, useMemo } from 'react';
import { useSurveyTaggingItemState, useRunTaggingValidationsCtx } from '@containers/SurveyBuilder.Tagging';
import { useSubmitSurveyDraft, useSurveyTaggingState } from '@containers/SurveyBuilder';
import { FormButtons } from '@presentation/FormButtons';
import styles from './style/Builder.css';

export const TaggingFormButtons = () => {

  const [_, dispatch] = useSurveyTaggingState();
  const [item] = useSurveyTaggingItemState();
  const submitDraft = useSubmitSurveyDraft();
  const runValidations = useRunTaggingValidationsCtx();

  const canSave = useMemo(() => {
    return !!item.conditions.length;
  }, [item.conditions.length]);

  const handleSave = useCallback(() => {
    dispatch({
      type: 'tagging-item-saved',
      payload: { value: item },
    });
    submitDraft();
  }, [dispatch, item, submitDraft]);

  const handleSubmit = useCallback(() => {
    const valid = runValidations();

    if (valid) {
      return handleSave();
    }
  }, [handleSave, runValidations]);

  const handleCancel = useCallback(() => {
    dispatch({
      type: 'tagging-editing-toggled-off',
      payload: {
        identifier: item.identifier,
      },
    });
  }, [dispatch, item.identifier]);

  return (
    <FormButtons
      className={styles.btns}
      disabled={!canSave}
      onSubmit={handleSubmit}
      onCancel={handleCancel} />
  );
};