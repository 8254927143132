import { useEffect, useRef, useState, useCallback } from 'react';
import * as consts from '@consts';
import { SearchParamsContainer } from '@containers/Search';
import styles from './style.css';
import {
  CreditRangeSliderProps
} from './interfaces';

type HandleTouchEvent = (event: TouchEvent | React.TouchEvent) => void;
type HandleMouseEvent = (event: MouseEvent | React.MouseEvent) => void;

const Circle = (props: {
  sliderRef: React.MutableRefObject<any>,
  handleMouseDown: HandleMouseEvent,
  handleMouseUp: HandleMouseEvent,
  handleTouchStart: HandleTouchEvent,
  handleTouchEnd: HandleTouchEvent,
  style: any,
}) => {
  return (
    <div
      draggable={false}
      ref={props.sliderRef}
      onMouseDown={props.handleMouseDown}
      onMouseUp={props.handleMouseUp}
      onTouchStart={props.handleTouchStart}
      onTouchEnd={props.handleTouchEnd}
      className={styles.circle}
      style={props.style} />
  )
}

const Middle = (props: { style: any }) => {
  return <div className={styles.middle} style={props.style} />;
}

const PriceLabel = (props: { minPrice: number, maxPrice: number }) => {
  const plus = props.maxPrice === consts.rate.MAX_CREDIT ? '+' : '';
  return (
    <div className={styles.priceLabel}>
      <div className={styles.label}>Price</div>
      <div className={styles.price}>
        {`${props.minPrice} - ${props.maxPrice}${plus} credits`}
      </div>
    </div>
  );
}

const initialState = SearchParamsContainer.initialState;

const useStateFromProp = <T extends unknown>(initialValue: T) => {
  const [value, setValue] = useState(initialValue);
  useEffect(() => setValue(initialValue), [initialValue]);
  return [value, setValue] as [T, React.Dispatch<React.SetStateAction<T>>];
}

const CreditRangeSlider = (props: CreditRangeSliderProps) => {
  const [maxPrice, setMaxPrice] = useStateFromProp(props.maxPrice || initialState.priceHigh);
  const [minPrice, setMinPrice] = useStateFromProp(props.minPrice || initialState.priceLow);
  const [maxValue, setMaxValue] = useStateFromProp(calculatePercentage(props.maxValue) || 100);
  const [minValue, setMinValue] = useStateFromProp(calculatePercentage(props.minValue) || 0);
  const [dragging, setDragging] = useState('');

  const containerRef = useRef(null);
  const lowSliderRef = useRef(null);
  const highSliderRef = useRef(null);

  const circleDiameter = 10;
  const lowSlider = 'low';
  const highSlider = 'high';

  const calculateNextPosition = (low: boolean, x: number) => {
    const mouseX = x - circleDiameter; //Subtract to center

    //Don't exceed right bound
    let rawPosition = Math.min(mouseX, containerRef.current.offsetLeft + containerRef.current.clientWidth);

    //Don't exceed left bound
    rawPosition = Math.max(rawPosition, containerRef.current.offsetLeft);

    //Don't all to go past other slider
    if (low) {
      rawPosition = Math.min(rawPosition, calculateSliderCenterX(!low));
    } else {
      rawPosition = Math.max(rawPosition, calculateSliderCenterX(!low));
    }

    //Convert to Percentage
    const pctPosition = ((rawPosition - containerRef.current.offsetLeft) / containerRef.current.clientWidth) * 100;

    return low ? Math.min(pctPosition, 100) : Math.max(pctPosition, 0);
  }

  function calculatePrice (position: number) {
    const formatted = (position / 100) * initialState.priceHigh;

    return Math.round(formatted * 4) / 4;
  }

  function calculatePercentage (price: number) {
    return (price / initialState.priceHigh) * 100;
  }

  const calculateSliderCenterX = (low: boolean) => {
    const adjust = low ? circleDiameter : -circleDiameter;
    const rf = low ? lowSliderRef.current : highSliderRef.current;
    return rf.offsetLeft + containerRef.current.offsetLeft + adjust;
  }

  const processChange = (low: boolean, position: number) => {
    const price = calculatePrice(position);
    const percentage = calculatePercentage(price);

    setMaxValue(low ? maxValue : percentage);
    setMinValue(low ? percentage : minValue);
    setMaxPrice(low ? maxPrice : price);
    setMinPrice(low ? price : minPrice);
  }

  const startedDragging = (dragging: string) => {
    setDragging(dragging);
  }

  const stoppedDragging = () => {
    if (props.onChange) {
      props.onChange({
        high: maxPrice,
        low: minPrice,
      });
    }
    setDragging('');
  }

  const handleMouseDown = (low: boolean) => useCallback((e: MouseEvent) => {
    startedDragging(low ? lowSlider : highSlider);

    e.stopPropagation();
    e.preventDefault();
  }, [startedDragging]);

  const handleMouseUp = useCallback(e => {
    stoppedDragging();
  }, [stoppedDragging]);

  const handleMouseMove = useCallback((e: MouseEvent) => {
    if (dragging === lowSlider) {
      const nextPos = calculateNextPosition(true, e.clientX);
      processChange(true, nextPos);
    }

    if (dragging === highSlider) {
      const nextPos = calculateNextPosition(false, e.clientX);
      processChange(false, nextPos);
    }

    e.stopPropagation();
    e.preventDefault();
  }, [dragging]);

  const handleTouchMove = useCallback((e: TouchEvent) => {
    if (e.changedTouches.length > 1) {
      return;
    }

    const touch = e.changedTouches[0];
    const low = dragging === lowSlider;
    const nextPos = calculateNextPosition(low, touch.clientX);
    processChange(low, nextPos);
  }, [dragging]);

  const handleTouchStart = (low: boolean) => useCallback((e: TouchEvent) => {
    startedDragging(low ? lowSlider : highSlider);
  }, [startedDragging]);

  const handleTouchEnd = useCallback(e => {
    stoppedDragging();
  }, [stoppedDragging]);

  const handleReset = useCallback(e => {
    setMinPrice(initialState.priceLow);
    setMaxPrice(initialState.priceHigh);
    setMinValue(0);
    setMaxValue(100);
  }, [setMinPrice, setMaxPrice, setMinValue, setMaxValue]);

  const getCircleStyle = (low: boolean) => {
    return {
      left: `${low ? minValue : maxValue}%`,
    };
  }

  const getMiddleStyle = () => {
    return {
      width: `${maxValue - minValue}%`,
      left: `${minValue}%`,
    };
  }

  useEffect(() => {
    if (props.resetButton && props.resetButton.current) {
      props.resetButton.current.addEventListener('click', handleReset);
      return () => {
        if (props.resetButton && props.resetButton.current) {
          props.resetButton.current.removeEventListener('click', handleReset);
        }
      }
    }
  }, [props.resetButton, handleReset]);

  useEffect(() => {
    if (dragging) {
      window.document.body.addEventListener('mousemove', handleMouseMove, false);
      window.document.body.addEventListener('touchmove', handleTouchMove, false);
      window.document.body.addEventListener('mouseup', handleMouseUp, false);
      window.document.body.addEventListener('mouseleave', handleMouseUp, false);
      window.document.body.addEventListener('touchend', handleTouchEnd, false);
    }
    return () => {
      window.document.body.removeEventListener('mousemove', handleMouseMove, false);
      window.document.body.removeEventListener('touchmove', handleTouchMove, false);
      window.document.body.removeEventListener('mouseup', handleMouseUp, false);
      window.document.body.removeEventListener('mouseleave', handleMouseUp, false);
      window.document.body.removeEventListener('touchend', handleTouchEnd, false);
    };
  },
  [
    dragging,
    handleMouseMove,
    handleTouchMove,
    handleMouseUp,
    handleTouchEnd
  ]);

  return (
    <>
      <PriceLabel
        minPrice={minPrice}
        maxPrice={maxPrice}
      />
      <div className={styles.root}>
        <div
          className={styles.slider}
          ref={containerRef}>
          <div className={styles.back} />
          <Circle
            sliderRef={lowSliderRef}
            handleMouseDown={handleMouseDown(true)}
            handleMouseUp={handleMouseUp}
            handleTouchStart={handleTouchStart(true)}
            handleTouchEnd={handleTouchEnd}
            style={getCircleStyle(true)}
          />
          <Middle
            style={getMiddleStyle()}
          />
          <Circle
            sliderRef={highSliderRef}
            handleMouseDown={handleMouseDown(false)}
            handleMouseUp={handleMouseUp}
            handleTouchStart={handleTouchStart(false)}
            handleTouchEnd={handleTouchEnd}
            style={getCircleStyle(false)}
          />
        </div>
      </div>
    </>
  );
}

export default CreditRangeSlider;