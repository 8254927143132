import { useState } from 'react';
import * as api from '@api';
import { TargetCompany } from '@/types';
import AutoComplete from '@/components/AutoComplete';

type Props = {
  className?: string;
  defaultItem: TargetCompany;
  onChange:    (item: TargetCompany) => unknown;
} & Pick<React.InputHTMLAttributes<HTMLInputElement>, 'autoFocus'>;

const TargetCompanyInput = (props: Props) => {
  const [value, setValue] = useState<string>(props.defaultItem?.name ?? '');
  const [items, setItems] = useState<TargetCompany[]>([]);

  function handleChange({ target }) {
    setValue(target.value);

    api.autocomplete.company({
      ids: [],
      value: target.value,
    })
    .then(res => setItems(res));
  }

  function handleSelect(item: TargetCompany) {

    if (!item?.name) {
      props.onChange({});
    } else if (!item.id) {
      props.onChange({
        id: `targetCompany~${value}`,
        isUserCreated: true,
        name: item.name,
      });
    } else {
      props.onChange(item);
    }

    setValue(item?.name ?? '');
  }

  return (
    <AutoComplete
      autoFocus={props.autoFocus}
      className={props.className}
      getItemValue={item => item.name}
      name="targetCompany"
      placeholder="Enter the target company of your research (optional)"
      item={props.defaultItem}
      items={items}
      onBlur={(_, item) => handleSelect(item)}
      onChange={handleChange}
      onSelect={handleSelect}
      value={value} />
  );
};

const defaultProps = {
  autoFocus: false,
  defaultItem: {},
};

TargetCompanyInput.defaultProps = defaultProps;

export { TargetCompanyInput };
export default TargetCompanyInput;