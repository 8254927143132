import { useCallback, useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as api from '@api';
import { ProjectStateContext } from '@containers/GroupProject/Context';
import { ConferenceTagType, ProjectSubtype, utils } from '@enums';
import { ButtonActivityIndicator } from '@presentation';
import { ProjectConferenceTagWithMetadata } from '@/types';
import { Checkbox } from '@/components/Checkbox';
import * as Layout from '@/components/MedicalProfile/Layout';
import { OnboardingFormContext, OnboardingStepperContext } from './Context';
import styles from './style/Onboarding.SubtypeSelection.css';

type Props = unknown;

export const SubtypeSelection = (props: Props) => {
  const stepper = useContext(OnboardingStepperContext);
  const form = useContext(OnboardingFormContext);
  const state = useContext(ProjectStateContext);

  const mutation = useMutation([
    `post:projects/conferences/tags/modules`,
    state?.project?.id,
  ], () => {
    return api.projects.conference.createModuleTags({
      projectId: state.project.id,
      subtypes: form.subtypes,
    });
  }, {
    onSuccess: res => {
      const tags = res.tags.filter(x => x.base.typeId !== ConferenceTagType.Global);

      const categories = sortTags(tags.filter(t => !t.parent?.id).map(t => ({
        ...t,
        children: sortTags(tags.filter(c => c.parent?.id === t.id)),
      })));

      form.replaceCategoriesState(categories);
    },
  });

  const handleNavigateNext = useCallback(() => {
    if (form.subtypes.includes(ProjectSubtype.Other)) {
      stepper.next();
    } else {
      mutation.mutateAsync().then(stepper.next);
    }
  }, [
    form.subtypes,
    mutation,
    stepper,
  ]);

  return (
    <Layout.Screen>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <Layout.Header
            subtitle={copy.subtitle}
            title={copy.title} />
          <div className={styles.main}>
            <div className={styles.options}>
              {types.map(x =>
                <SubtypeOption
                  checked={form.subtypes.some(val => val === x.id)}
                  id={x.id}
                  key={x.id}
                  name={x.name}
                  onChange={(e, checked) => form.onChangeSubtype(x.id, checked)} />)}
            </div>

            <div className={styles.divider} />

            <SubtypeOption
              {...makeOption(ProjectSubtype.Other)}
              checked={form.subtypes.some(val => val === ProjectSubtype.Other)}
              onChange={(e, checked) => form.onChangeSubtype(ProjectSubtype.Other, checked)} />
          </div>
          <Layout.Footer>
            <div
              className={styles.cancel}
              onClick={stepper.redirect}>
              {`< Cancel`}
            </div>
            <ButtonActivityIndicator
              className={styles.btn}
              color="primary"
              disabled={!form.subtypes.length}
              implicitDisable={false}
              loading={mutation.isLoading}
              onClick={handleNavigateNext}>
              Next
            </ButtonActivityIndicator>
          </Layout.Footer>
        </div>
      </div>
    </Layout.Screen>
  );
};

SubtypeSelection.displayName = 'Onboarding.SubtypeSelection';

const copy = {
  title: `Select your project type(s) to pre-load a series of category tags for your interview`,
  subtitle: `If your project type doesn’t align to one of the below options, select other and you can customize the tags on the next screen.`,
};

type SubtypeOptionProps = {
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
} & Descriptor;

const SubtypeOption = (props: SubtypeOptionProps) => {
  return (
    <div className={styles.option}>
      <Checkbox
        checked={props.checked}
        onChange={props.onChange} />
      <div className={styles.label}>{props.name}</div>
    </div>
  );
};

SubtypeOption.displayName = 'Onboarding.SubtypeSelection.SubtypeOption';

const makeOption = (type: ProjectSubtype) => ({
  id: type,
  name: utils.ProjectSubtype.getName(type),
});

const types = [
  ...utils.ProjectSubtype.values()
    .filter(val => val !== ProjectSubtype.Other)
    .map(makeOption),
].sort((a, b) => a.name.localeCompare(b.name));

function sortTags<T extends ProjectConferenceTagWithMetadata>(tags: T[]) {
  return tags.sort((a, b) => (a.ordinal ?? Number.MAX_VALUE) - (b.ordinal ?? Number.MAX_VALUE));
}