import { useQueryParams, NumberParam } from 'use-query-params';
import { TranscriptCommentingContainer } from '@containers/Transcript.Commenting';
import { TranscriptRedactionContainer, TranscriptRedactionLockContainer } from '@containers/Transcript.Redaction';
import { TranscriptTaggingContainer } from '@containers/Transcript.Tagging';
import { AudioPlayerContainer } from '@containers/AudioPlayer';
import { InitialTranscriptPositionContext } from './context';
import { TranscriptConfidenceContainer } from './Confidence';
import { TranscriptAutoCorrectContainer } from './AutoCorrect';
import { TranscriptTimestampPositionsContainer } from './Timestamps';
import { UpdatesContainer } from './UpdatesContainer';

/* Note: These are containers that only contain state and don't rely on any other state. */

type Props = ChildrenProps;

export const TranscriptStateContainer = ({ children }: Props) => {

  const [params] = useQueryParams({
    s: NumberParam,
  });

  return (
    <InitialTranscriptPositionContext.Provider value={params.s}>
      <TranscriptCommentingContainer>
        <TranscriptTaggingContainer>
          <TranscriptRedactionLockContainer>
            <TranscriptRedactionContainer>
              <TranscriptConfidenceContainer>
                <TranscriptAutoCorrectContainer>
                  <TranscriptTimestampPositionsContainer>
                    <AudioPlayerContainer>
                      <UpdatesContainer>
                        {children}
                      </UpdatesContainer>
                    </AudioPlayerContainer>
                  </TranscriptTimestampPositionsContainer>
                </TranscriptAutoCorrectContainer>
              </TranscriptConfidenceContainer>
            </TranscriptRedactionContainer>
          </TranscriptRedactionLockContainer>
        </TranscriptTaggingContainer>
      </TranscriptCommentingContainer>
    </InitialTranscriptPositionContext.Provider>
  );
};