import { formatCurrency } from '@utils/format';
import styles from './style.css';
import type { DollarRateProps } from './interfaces';

const getClassNames = (props: DollarRateProps) => {
  return `${styles.root} ` +
         `${props.hideDollar ? '' : styles.dollar} ` +
         `${props.hidePerHour ? '' : styles.perHour} `;
};

const DollarRate = (props: DollarRateProps) => {
  if (!props.rate) return null;

  const rate = formatCurrency(props.rate, props.currency, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return (
    <div className={getClassNames(props)}>
      {rate}
    </div>
  );
}

export default DollarRate;