import { memo } from 'react';
import { Star } from 'react-feather';
import { cx } from '@utils';
import styles from './style.css';

type Props = {
  rating: number;
  size?: number;
  className?: string;
};

export const MiniStarRating = memo(({ rating, size, className }: Props) => {
  return (
    <div className={cx(styles.miniRoot, className)}>
      <Star
        className={styles.miniStar}
        size={size}
        strokeWidth={2} />
      <div className={styles.miniText} style={{ fontSize: size / 2 }}>{rating?.toFixed(1)}</div>
    </div>
  );
});