import type { Dispatch } from 'react';
import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import type { CallPayoutOverrideType, CallRole } from '@enums';
import type { TagOption } from '@/components/Group.Tags/interfaces';
import type { CalendarRange, ConferenceSettings, Currencies } from '@/types';
import type * as API from '$admin/api/interfaces';
import type { TimeslotItem } from '$admin/Scheduling/interfaces';

export type OnSuccess = (data: API.Projects.Scheduling.ScheduleCall.Response) => unknown;

export namespace SetContext {
  export type Params =
    & Partial<ICallId>
    & IProjectId
    & Partial<IUserId>;
}

export type CalendarsContextValue = {
  query: UseQueryResult<API.Projects.Scheduling.FindSchedulerCalendars.Response>;
};

export type ParamsContextValue = {
  params: SetContext.Params;
  resetContext: () => void;
  setContext: (params: SetContext.Params) => void;
};

export enum TabKey {
  Schedule = 'Schedule',
  ViewAvailability = 'view-availability',
}

export type BookingSegmentState = CalendarRange<Date>;

export type BookingSegmentContextValue = [
  BookingSegmentState,
  Dispatch<BookingSegmentState>,
];

export enum FrameKey {
  AddAvailability = 'AddAvailability',
  AttendeeList = 'AttendeeList',
  Booking = 'Booking',
  ConferenceSettings = 'ConferenceSettings',
  Confirmation = 'Confirmation',
  ManageAttendees = 'ManageAttendees',
  ModeratorSchedulers = 'ModeratorSchedulers',
  RequestAvailability = 'RequestAvailability',
  UnconfirmedCalls = 'UnconfirmedCalls',
}

export type Action =
  | { frame: FrameKey.AddAvailability; data: string }
  | { frame: FrameKey.AttendeeList }
  | { frame: FrameKey.Booking }
  | { frame: FrameKey.ConferenceSettings }
  | { frame: FrameKey.Confirmation }
  | { frame: FrameKey.ManageAttendees }
  | { frame: FrameKey.ModeratorSchedulers }
  | { frame: FrameKey.RequestAvailability }
  | { frame: FrameKey.UnconfirmedCalls };

export type AddAvailabilityFrameAction =
  Extract<Action, { frame: FrameKey.AddAvailability }>;

export type FrameContextValue = {
  back: () => void;
  goToFrame: (action: Action) => void;
  stack: Action[];
  view: Action;
};

export type EventsLookup = Record<string, TimeslotItem[]>;

export type PersonalCalendarContextValue = {
  form: {
    discardValue: () => void;
    editing: boolean;
    range: CalendarRange<Date>;
    setValue: Dispatch<CalendarRange<Date>>;
    toggleEditing: () => void;
  };
  lookup: EventsLookup;
  mutation: UseMutationResult<void, unknown, void, unknown>;
};

export type ConfirmContextValue = {
  confirmation: API.Projects.Scheduling.ScheduleCall.Response;
  mutation: UseMutationResult<API.Projects.Scheduling.ScheduleCall.Response, unknown, void>;
};

export type ModeratorOption = Omit<ParticipantItem, 'roleId' | 'scheduler'>;

export type ParticipantItem = {
  email: string;
  id?: number;
  internal?: boolean;
  name: string;
  offPlatform: boolean;
  roleId: CallRole;
  scheduler?: boolean;
  verified?: boolean;
};

export type ParticipantsContextValue = {
  query: UseQueryResult<API.Projects.Scheduling.GetCallParticipants.Response>;
  items: ParticipantItem[];
  setValue: React.Dispatch<React.SetStateAction<ParticipantItem[]>>;
};

export type ReadyStateContextValue = boolean;

export declare namespace RespondentSetup {
  export type Form = {
    email?: string;
    external: boolean;
    honorarium: {
      currency: Currencies.Item['code'];
      type: CallPayoutOverrideType;
      value: string;
    };
    payout: boolean;
  } & IProjectId
    & Partial<IUserId>;

  export type ResetContext = () => void;

  export type SetContext = (params: IProjectId) => void;

  export type SetValueParams = Partial<Omit<React.SetStateAction<Form>, 'projectId'>>;

  export type SetValue = React.Dispatch<SetValueParams>;
}

export type RespondentSetupContextValue = {
  form: RespondentSetup.Form;
  resetContext: RespondentSetup.ResetContext;
  setContext: RespondentSetup.SetContext;
  setValue: RespondentSetup.SetValue;
};

export type RespondentItem =
  & Omit<API.Search.Users.XPlatResultItem, 'id'>
  & Partial<Pick<API.Search.Users.XPlatResultItem, 'id'>>;

export declare namespace SetConferenceValue {
  export type Params = Partial<Settings.Conference>;
}

export declare namespace Settings {
  export type Call = API.Projects.Scheduling.GetCallSettings.CallEventDetails;

  export type Conference = {
    dialInValid: boolean;
    title: string;
  } & ConferenceSettings;

  export type Form = {
    call?: Settings.Call;
    conference: Settings.Conference;
    time: Settings.Time;
    tags: Settings.Tags;
  };

  export type Query = UseQueryResult<API.Projects.Scheduling.GetCallSettings.Response>;

  export type Time = CalendarRange<Date>;

  export type Tags = TagOption[];

  export type SetValue = React.Dispatch<React.SetStateAction<Form>>;
}

export type SettingsContextValue = Settings.Form & {
  isLoading: boolean;
  setValue: Settings.SetValue;
};

export type UnconfirmedCallsContextValue = UseQueryResult<API.Projects.Scheduling.FindRespondentUnconfirmedCalls.Response>;