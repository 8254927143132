import { useRouteMatch } from 'react-router-dom';
import ContactInfo from './ContactInfo';
import FollowUs from './FollowUs';
import { Sentiment } from './Sentiment';
import { Menus } from './Menus';
import styles from './style/Footer.css';

type Props = {
  exclude?: Parameters<typeof useRouteMatch>[number];
};

const Footer = ({
  exclude = {
    path: null,
  },
}: Props) => {
  const excluded = useRouteMatch(exclude);

  if (excluded) return null;

  return (
    <footer className={styles.root}>
      <div className={styles.wrap}>
        <Sentiment />
      </div>
    </footer>
  );
};

export { Footer };
export default Footer;