import { SurveyLogicBuilderConditionsContainer } from '@containers/SurveyBuilder.Logic/ConditionsContainer';
import { useSurveyTaggingActionValidations } from '@/containers/SurveyBuilder.Tagging/hooks';
import { LogicValidation, LogicBuilderConditionsMatch } from '@/components/SurveyBuilder.Logic';
import { TaggingBuilderAction } from './Builder.Action';
import { TaggingFormButtons } from './Builder.FormButtons';
import styles from './style/Builder.css';

export const SurveyTaggingItemBuilder = () => {

  const actionValidations = useSurveyTaggingActionValidations();

  return (
    <div>
      <div className={styles.action}>
        <div className={styles.label}>Action</div>
        <TaggingBuilderAction />
        <LogicValidation value={actionValidations} />
      </div>

      <SurveyLogicBuilderConditionsContainer>
        <LogicBuilderConditionsMatch />
      </SurveyLogicBuilderConditionsContainer>

      <TaggingFormButtons />
    </div>
  );
};