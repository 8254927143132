import { useContext } from 'react';
import {
  CloneGroupProjectContext,
  GroupProjectCloningContext,
  GroupProjectCloningFormContext,
} from '../Context';

export const useProjectCloning = () => {
  const context = useContext(GroupProjectCloningContext);

  return context;
};

export const useProjectCloningForm = () => {
  const context = useContext(GroupProjectCloningFormContext);

  return [context.form, context.setFormState] as const;
};

export const useCloneProject = () => {
  const cloneProject = useContext(CloneGroupProjectContext);

  return cloneProject;
};

export default useProjectCloning;