import { ProjectTagsQueryContainer } from './Project.Tags.Query.Container';

type Props = {
  children: React.ReactNode;
  enabled:  boolean;
} & IProjectId;

export const ProjectTagsContainer = (props: Props) => {
  return (
    <ProjectTagsQueryContainer
      enabled={!!props.projectId && props.enabled}
      projectId={props.projectId}>
      {props.children}
    </ProjectTagsQueryContainer>
  );
};

ProjectTagsContainer.displayName = 'Project.Tags.Container';