import * as consts from '@consts';
import { Button } from '@/components/Button';
import Body from '@/components/Modal/Body';
import ButtonSet from '@/components/Modal/ButtonSet';
import Header from '@/components/Modal/Header';
import Modal from '@/components/Modal/Modal.Legacy';
import styles from './style/UserProfileCheckup.css';

type Props = {
  onClose: () => unknown;
};

const UserProfileCheckup = (props: Props) => {
  return (
    <Modal
      visible
      onClose={props.onClose}>
      <div className={styles.wrap}>

        <Header>{title}</Header>

        <Body className={styles.body}>
          <div>{msg1}</div>
          <div>{msg2}</div>
        </Body>

        <ButtonSet>
          <Button
            className={styles.btn}
            color="primary"
            to={consts.path.Account.Profile.Checkup}
            variant="brick">
            Update Profile
          </Button>
        </ButtonSet>

      </div>
    </Modal>
  );
};

const title = `It looks like it's been a while since your profile was updated.`;
const msg1 = `Please take a moment to update your profile with any recent employment positions.`;
const msg2 = `This will give you better chances of being selected for consulting opportunities on the platform.`;

export { UserProfileCheckup };
export default UserProfileCheckup;