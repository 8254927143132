import { forwardRef, memo } from 'react';
import styles from './style/Expression.Toolbar.css';

type Props = {
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => unknown;
};

export const ToolbarButton = memo(forwardRef<HTMLButtonElement, Props>((props, ref) => {

  return (
    <button
      ref={ref}
      className={styles.btn}
      onMouseDown={e => e.preventDefault()}
      onClick={props.onClick}>
      {props.children}
    </button>
  );
}));