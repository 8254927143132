import { useEffect, useState } from 'react';
import { RemoteParticipant, Room } from 'twilio-video';
import { useDominantSpeaker } from './useDominantSpeaker';

export function useParticipants(room: Room) {
  const dominantSpeaker = useDominantSpeaker(room);
  const [participants, setParticipants] = useState(Array.from(room?.participants.values() ?? []));

  useEffect(() => {
    if (dominantSpeaker) {
      setParticipants(prevParticipants => [
        dominantSpeaker,
        ...prevParticipants.filter(participant => participant !== dominantSpeaker),
      ]);
    }
  }, [dominantSpeaker]);

  useEffect(() => {
    const participantConnected = (participant: RemoteParticipant) =>
      setParticipants(prev => [...prev, participant]);
    const participantDisconnected = (participant: RemoteParticipant) =>
      setParticipants(prev => prev.filter(p => p !== participant));
    room.on('participantConnected', participantConnected);
    room.on('participantDisconnected', participantDisconnected);
    return () => {
      room.off('participantConnected', participantConnected);
      room.off('participantDisconnected', participantDisconnected);
    };
  }, [room]);

  return participants;
}

export default useParticipants;