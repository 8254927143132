import { forwardRef, useCallback, useState } from 'react';
import { usePopper } from 'react-popper';
import { ChevronDown, Edit2 } from 'react-feather';
import { ClickAwayListener } from '@mui/material';
import { cx } from '@utils';
import { Button } from '@/components/Button';
import { PopperMenu, useZIndexModifier } from '@/components/Popper';
import { Portal } from '@/components/Portal';
import { Tags } from './interfaces';
import styles from './style/Tagging.ColorSelect.css';

type Props = {
  color?: string;
  id?: number;
  onChange: (color: string) => void;
  popperAnchor?: HTMLDivElement;
  BtnComponent?: React.ForwardRefExoticComponent<ColorSelectBtnProps & React.RefAttributes<unknown>>;
};

export const ColorSelect = ({ onChange, BtnComponent = ColorSelectButton, ...props }: Props) => {
  const [popperState, setPopperState] = useState(false);
  const [toggleEl, setToggleEl] = useState<HTMLElement>();
  const [popperEl, setPopperEl] = useState<HTMLDivElement>();
  const zIndexModifier = useZIndexModifier({ zIndex: Tags.PopperZIndex });
  const { styles: popperStyles, attributes } = usePopper(toggleEl, popperEl, {
    modifiers: [zIndexModifier],
  });

  const handleChange = useCallback((color: string) => {
    onChange(color);
    setPopperState(false);
  }, [
    onChange,
    setPopperState,
  ]);

  if (props.id) {
    return (
      <DisabledSelector color={props.color} />
    );
  }

  return (
    <div>
      <BtnComponent
        ref={r => setToggleEl(r as HTMLElement)}
        onClick={() => setPopperState(!popperState)}
        disabled={!!props.id}
        color={props.color} />
      {popperState && (
        <Portal anchorEl={props.popperAnchor}>
          <ClickAwayListener onClickAway={() => setPopperState(false)}>
            <div
              ref={setPopperEl}
              style={popperStyles.popper}
              {...attributes}>
              <PopperMenu>
                <ColorPopper onChange={handleChange} />
              </PopperMenu>
            </div>
          </ClickAwayListener>
        </Portal>
      )
      }
    </div>
  );
};

type ColorSelectBtnProps = {
  onClick: () => void;
  color: string;
  disabled: boolean;
};

const ColorSelectButton = forwardRef((props: ColorSelectBtnProps, ref) => {
  return (
    <Button
      ref={ref}
      className={styles.btn}
      size="small"
      disabled={props.disabled}
      variant="brick"
      color="destructive"
      onClick={props.onClick}>
      <ColorCircle color={props.color} />
      <ChevronDown size={20} />
    </Button>
  );
});

export const InlineColorSelectBtn = forwardRef((props: ColorSelectBtnProps, ref) => {
  return (
    <div
      className={cx(styles.inlineSelectContainer, props.disabled ? null : styles.clickable)}
      onClick={props.disabled ? null : props.onClick}>
      <ColorCircle
        ref={ref}
        className={props.disabled ? styles.disabled : null}
        color={props.color} />
      <Edit2 className={styles.inlineEditIcon} size={14} />
    </div>
  );
});

type ColorPopperProps = {
  onChange: (color: string) => void;
};

const ColorPopper = (props: ColorPopperProps) => {
  return (
    <div className={styles.popper}>
      {Tags.tagColors.map((color, i) => (
        <ColorCircle
          color={color.highlight}
          key={color.highlight}
          onClick={() => props.onChange(color.highlight)} />))}
    </div>
  );
};

type ColorCircleProps = {
  color: string;
  className?: string;
} & Pick<React.HTMLAttributes<HTMLDivElement>, 'onClick'>;

export const ColorCircle = forwardRef(({ color, onClick, className }: ColorCircleProps, ref: React.Ref<unknown>) => {
  return (
    <div
      ref={ref as React.Ref<HTMLDivElement>}
      className={cx(styles.circle, className)}
      style={{ backgroundColor: color ?? Tags.defaultColor }}
      onClick={onClick} />
  );
});

type DisabledSelectorProps = {
  color?: string;
};

const DisabledSelector = (props: DisabledSelectorProps) => {
  return (
    <div className={styles.disabled}>
      <ColorCircle color={props.color} />
    </div>
  );
};

const defaultColor = Tags.defaultColor;
export { defaultColor };