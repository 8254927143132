import { useCallback, useState } from 'react';
import { useSurveyBuilderState, useIsEditingSurvey, useHasUncommittedChanges, useSurveyBuilderOptions } from '@containers/SurveyBuilder/hooks';
import { UnsavedChangesAlert } from '@/components/Modal/UnsavedChangesAlert';
import { SurveyQuestionsBuilder } from './QuestionsBuilder';
import { Details } from './Details';
import Tabs from './Tabs';
import SurveyTab from './SurveyTab';
import SurveyLogicBuilder from './LogicBuilder';
import { SurveyPreview } from './SurveyPreview';
import styles from './style/SurveyBuilder.css';

type Props = {
  renderTab: (t: SurveyTab) => React.ReactNode;
  tabs: SurveyTab[];
};

export const SurveyBuilder = ({
  renderTab,
  tabs,
}: Props) => {
  const [state, dispatch] = useSurveyBuilderState();
  const isEditing = useIsEditingSurvey();
  const hasUncommittedChanges = useHasUncommittedChanges();

  const [tab, setTab] = useState<SurveyTab>(SurveyTab.Questions);
  const [pendingNextTab, setPendingNextChange] = useState<SurveyTab | null>(null);

  const handleTabClick = useCallback((t: SurveyTab) => {
    if (t === tab) return;

    if (isEditing) {
      if (hasUncommittedChanges()) {
        setPendingNextChange(t);
      } else {
        dispatch({
          type: 'revert-uncommitted-changes',
        });
        setTab(t);
      }
    } else {
      setTab(t);
    }
  }, [
    dispatch,
    hasUncommittedChanges,
    isEditing,
    setTab,
    setPendingNextChange,
    tab,
  ]);

  const discardChanges = useCallback(() => {
    dispatch({
      type: 'revert-uncommitted-changes',
    });
    setTab(pendingNextTab);
    setPendingNextChange(null);
  }, [
    dispatch,
    pendingNextTab,
    setPendingNextChange,
    setTab,
  ]);

  return (
    <>
      <div>
        <Details />
        <div className={styles.tabRow}>
          <Tabs
            activeTab={tab}
            tabs={tabs}
            onClick={handleTabClick} />
          <SurveyPreview />
        </div>
        {renderTab(tab)}
      </div>
      {pendingNextTab &&
        <UnsavedChangesAlert
          onClose={() => setPendingNextChange(null)}
          onConfirm={discardChanges}
          open={true} />
      }
    </>
  );
};

export default SurveyBuilder;