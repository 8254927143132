import queryString from 'query-string';
import http from '@services/http';
import { KPI } from '$admin/api/interfaces';

export const downloadAccountReport = (data: KPI.DownloadAccountReport.Request): Promise<KPI.DownloadAccountReport.Response> => {
  const qs = {
    period: data.period,
    start: data.start ? data.start.getTime() : undefined,
    end: data.end ? data.end.getTime() : undefined,
  };

  return http.download(`/admin/kpi/account-download?${queryString.stringify(qs)}`);
};

export const downloadContentLikeReport = (): Promise<KPI.DownloadContentLikeReport.Response> => {
  return http.download(`/admin/kpi/content-like-download`);
};

export const downloadCommentsReport = (): Promise<KPI.DownloadCommentsReport.Response> => {
  return http.download(`/admin/kpi/comments-download`);
};

export const downloadPostsReport = (): Promise<KPI.DownloadPostsReport.Response> => {
  return http.download(`/admin/kpi/posts-download`);
};

export const downloadTopicFollowsReport = (): Promise<KPI.DownloadTopicFollowsReport.Response> => {
  return http.download(`/admin/kpi/topic-follows-download`);
};