import { useContext } from 'react';
import * as CallCancellation from '$admin/components/Call.Cancellation';
import * as CallMarkComplete from '$admin/components/Call.MarkComplete';
import * as CallRescheduling from '$admin/components/Call.Rescheduling';
import * as CallScheduling from '$admin/components/Scheduling';
import { ProjectCallsQueryContext } from './Context';

type Props = {
  children: React.ReactNode;
};

export const ProjectCallsSchedulingContainer = (props: Props) => {
  const query = useContext(ProjectCallsQueryContext);

  return (
    <CallScheduling.Container onSuccess={() => query.refetch()}>
      <CallRescheduling.Container onSuccess={() => query.refetch()}>
        <CallCancellation.Container onSuccess={() => query.refetch()}>
          <CallMarkComplete.Container onSuccess={() => query.refetch()}>
            {props.children}
          </CallMarkComplete.Container>
        </CallCancellation.Container>
      </CallRescheduling.Container>
    </CallScheduling.Container>
  );
};

ProjectCallsSchedulingContainer.displayName = 'Project.Calls.Scheduling.Container';