import { memo } from 'react';
import Select, { type ActionMeta } from 'react-select';
import type { Members } from '$admin/interfaces/search';
import selectStyles from './SelectStyles';
import styles from './style/Filters.css';

type SingleCardMultiProps = {
  value: Members.Filters.FieldOperatorsSingleCardMulti;
  onChange: (value: FieldOperatorOptionSingleCardMulti, meta: ActionMeta<FieldOperatorOptionSingleCardMulti>) => unknown;
};

export const FieldOperatorSingleCardMulti = memo(({ value, onChange }: SingleCardMultiProps) => {
  return (
    <div className={styles.fieldOp}>
      <Select<FieldOperatorOptionSingleCardMulti>
        className={styles.select}
        options={singleCardOptions}
        value={singleCardOptions.find(o => o.id === value)}
        getOptionLabel={o => o.name}
        getOptionValue={o => o.id}
        isSearchable={false}
        styles={selectStyles}
        onChange={onChange} />
    </div>
  );
});

type MultiProps = {
  value: Members.Filters.FieldOperatorsMulti;
  onChange: (value: FieldOperatorOptionMulti, meta: ActionMeta<FieldOperatorOptionMulti>) => unknown;
};

export const FieldOperatorMulti = memo(({ value, onChange }: MultiProps) => {
  return (
    <div className={styles.fieldOp}>
      <Select<FieldOperatorOptionMulti>
        className={styles.select}
        options={multiOptions}
        value={multiOptions.find(o => o.id === value)}
        getOptionLabel={o => o.name}
        getOptionValue={o => o.id}
        isSearchable={false}
        styles={selectStyles}
        onChange={onChange} />
    </div>
  );
});

type MultiHybridProps = {
  value: Members.Filters.FieldOperatorsMultiHybrid;
  onChange: (value: FieldOperatorOptionMultiHybrid, meta: ActionMeta<FieldOperatorOptionMultiHybrid>) => unknown;
};

export const FieldOperatorMultiHybrid = memo(({ value, onChange }: MultiHybridProps) => {
  return (
    <div className={styles.fieldOp}>
      <Select<FieldOperatorOptionMultiHybrid>
        className={styles.select}
        options={multiHybridOptions}
        value={multiHybridOptions.find(o => o.id === value)}
        getOptionLabel={o => o.name}
        getOptionValue={o => o.id}
        isSearchable={false}
        styles={selectStyles}
        onChange={onChange} />
    </div>
  );
});

type KeywordProps = {
  value: Members.Filters.FieldOperatorsKeyword;
  onChange: (value: FieldOperatorOptionKeyword, meta: ActionMeta<FieldOperatorOptionKeyword>) => unknown;
};

export const FieldOperatorKeyword = memo(({ value, onChange }: KeywordProps) => {
  return (
    <div className={styles.fieldOp}>
      <Select<FieldOperatorOptionKeyword>
        className={styles.select}
        options={keywordOptions}
        value={keywordOptions.find(o => o.id === value)}
        getOptionLabel={o => o.name}
        getOptionValue={o => o.id}
        isSearchable={false}
        styles={selectStyles}
        onChange={onChange} />
    </div>
  );
});

type TextProps = {
  value: Members.Filters.FieldOperatorsText;
  onChange: (value: FieldOperatorOptionText, meta: ActionMeta<FieldOperatorOptionText>) => unknown;
};

export const FieldOperatorText = memo(({ value, onChange }: TextProps) => {
  return (
    <div className={styles.fieldOp}>
      <Select<FieldOperatorOptionText>
        className={styles.select}
        options={textOptions}
        value={textOptions.find(o => o.id === value)}
        getOptionLabel={o => o.name}
        getOptionValue={o => o.id}
        isSearchable={false}
        styles={selectStyles}
        onChange={onChange} />
    </div>
  );
});

type ComparisonProps = {
  value: Members.Filters.FieldOperatorsComparison;
  onChange: (value: FieldOperatorOptionComparison, meta: ActionMeta<FieldOperatorOptionComparison>) => unknown;
};

export const FieldOperatorComparison = memo(({ value, onChange }: ComparisonProps) => {
  return (
    <div className={styles.fieldOp}>
      <Select<FieldOperatorOptionComparison>
        className={styles.select}
        options={comparisonOptions}
        value={comparisonOptions.find(o => o.id === value)}
        getOptionLabel={o => o.name}
        getOptionValue={o => o.id}
        isSearchable={false}
        styles={selectStyles}
        onChange={onChange} />
    </div>
  );
});

type BooleanProps = {
  value: Members.Filters.FieldOperatorsBoolean;
  onChange: (value: FieldOperatorOptionBoolean, meta: ActionMeta<FieldOperatorOptionBoolean>) => unknown;
};

export const FieldOperatorBoolean = memo(({ value, onChange }: BooleanProps) => {
  return (
    <div className={styles.fieldOp}>
      <Select<FieldOperatorOptionText>
        className={styles.select}
        options={booleanOptions}
        value={booleanOptions.find(o => o.id === value)}
        getOptionLabel={o => o.name}
        getOptionValue={o => o.id}
        isSearchable={false}
        styles={selectStyles}
        onChange={onChange} />
    </div>
  );
});

export type FieldOperatorOption<T extends Members.Filters.FieldOperators> = { id: T; name: string };

export type FieldOperatorOptionSingleCardMulti = FieldOperatorOption<Members.Filters.FieldOperatorsSingleCardMulti>;

const singleCardOptions: FieldOperatorOptionSingleCardMulti[] = [
  { id: 'ioo', name: 'Is one of' },
  { id: 'inoo', name: 'Is not one of' },
];

export type FieldOperatorOptionMulti = FieldOperatorOption<Members.Filters.FieldOperatorsMulti>;

const multiOptions: FieldOperatorOptionMulti[] = [
  { id: 'ioo', name: 'Is one of' },
  { id: 'inoo', name: 'Is not one of' },
  { id: 'iao', name: 'Is all of' },
  { id: 'inao', name: 'Is not all of' },
];

export type FieldOperatorOptionMultiHybrid = FieldOperatorOption<Members.Filters.FieldOperatorsMultiHybrid>;

const multiHybridOptions: FieldOperatorOptionMultiHybrid[] = [
  { id: 'c', name: 'Contains' },
  ...multiOptions,
];

export type FieldOperatorOptionKeyword = FieldOperatorOption<Members.Filters.FieldOperatorsKeyword>;

const keywordOptions: FieldOperatorOptionKeyword[] = [
  { id: 'c', name: 'Contains' },
  { id: 'dnc', name: 'Does not contain' },
];

export type FieldOperatorOptionText = FieldOperatorOption<Members.Filters.FieldOperatorsText>;

const textOptions: FieldOperatorOptionText[] = [
  { id: 'c', name: 'Contains' },
  { id: 'dnc', name: 'Does not contain' },
  { id: 'i', name: 'Is' },
  { id: 'in', name: 'Is not' },
];

export type FieldOperatorOptionComparison = FieldOperatorOption<Members.Filters.FieldOperatorsComparison>;

const comparisonOptions: FieldOperatorOptionComparison[] = [
  { id: 'lt', name: 'Less than' },
  { id: 'lte', name: 'Less than or equal' },
  { id: 'gt', name: 'Greater than' },
  { id: 'gte', name: 'Greater than or equal' },
];

export type FieldOperatorOptionBoolean = FieldOperatorOption<Members.Filters.FieldOperatorsBoolean>;

const booleanOptions: FieldOperatorOptionText[] = [
  { id: 'i', name: 'Is' },
  { id: 'in', name: 'Is not' },
];