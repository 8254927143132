import { useContext } from 'react';
import { SiteStrategyDispatchContext } from '../Context';

type Params = unknown;

const useSiteStrategyDispatch = () => {
  const dispatch = useContext(SiteStrategyDispatchContext);

  return dispatch;
};

export { useSiteStrategyDispatch };
export default useSiteStrategyDispatch;