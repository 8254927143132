import { useContext } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { getCurrentTimezone } from '@utils';
import { PersonalCalendarContext } from './Context';
import { Fields } from './AddAvailability.Fields';
import styles from './style/AddAvailability.css';

type Props = {
  interval: number;
};

export const Editor = (props: Props) => {
  const ctx = useContext(PersonalCalendarContext);

  if (!ctx.form.editing) {
    return (
      <div className={styles.row}>
        <button
          className={styles.add}
          onClick={ctx.form.toggleEditing}>
          <AddCircleIcon
            className={styles.icon}
            color="inherit"
            fontSize="inherit" />
          Add Time
        </button>
      </div>
    );
  }

  return (
    <div className={styles.row}>
      <Fields
        interval={props.interval}
        lookup={ctx.lookup} />

      <div className={styles.timezone}>
        {`All times displayed in ${getCurrentTimezone()}.`}
      </div>

    </div>
  );
};

Editor.displayName = 'AddAvailability.Editor';