import { memo } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { cx } from '@utils';
import styles from './style/Skeleton.css';

type Props = {
  className?: string;
  rows: number;
};

export const Highlights = memo((props: Props) => {
  return (
    <div className={cx(styles.highlights, props.className)}>
      {Array.from({ length: props.rows }).map((_, i) =>
        <Row key={i} />)}
    </div>
  );
});

Highlights.displayName = 'Transcript.Skeleton.Highlights';

const Row = memo((props: unknown) => {
  return (
    <div className={styles.row}>
      <div className={styles.timestamp}>
        <Skeleton
          sx={{ fontSize: '16px', width: '100px' }}
          variant="text" />
      </div>
      <div className={styles.tag}>
        <Skeleton
          height={30}
          variant="rectangular"
          width={120} />
      </div>
      <div className={styles.text}>
        <Skeleton variant="text" sx={{ fontSize: '16px', width: '100px', marginBottom: '10px' }} />
        <Skeleton variant="text" sx={{ fontSize: '16px', lineHeight: '28px' }} />
        <Skeleton variant="text" sx={{ fontSize: '16px', lineHeight: '28px' }} />
        <Skeleton variant="text" sx={{ fontSize: '16px', lineHeight: '28px', width: '80%' }} />
      </div>
    </div>
  );
});

Row.displayName = 'Transcript.Skeleton.Highlights.Row';