import { useCallback } from 'react';
import { useActive, useChainedCommands, useCommands } from '@remirror/react';

export const useBulletListCommand = () => {
  const active = useActive();
  const { toggleBulletList } = useCommands();
  const chain = useChainedCommands();

  const toggle = useCallback(() => {
    chain
      .toggleBulletList()
      .focus()
      .run();
  }, [chain]);

  return {
    active: active.bulletList(),
    toggle,
    enabled: toggleBulletList.enabled(),
  };
};