import { createNamedContext } from '@utils';
import { Workspace, WorkspaceFolder, WorkspaceObject } from '@/types';

type WorkspaceStateContextValue = {
  workspace: Workspace;
};

type FolderStateContextValue = {
  folder: WorkspaceFolder;
  object: WorkspaceObject;
};

export const WorkspaceStateContext = createNamedContext<WorkspaceStateContextValue>(undefined, 'workspace-state');
export const WorkspaceFolderStateContext = createNamedContext<FolderStateContextValue>(undefined, 'folder-state');