import { createNamedContext } from '@utils';
import type { MultiChat, Coordinator, LiveRoom, Socket, TwilioVoice, TwilioVideo, TwilioLogs } from './interfaces';

export const ConferenceMultiChatContext = createNamedContext<MultiChat.Context>(undefined, 'Conference.MultiChat.Context');

export const ConferenceCoordinatorContext = createNamedContext<Coordinator.Context>(undefined, 'Conference.Coordinator.Context');

export const ConferenceCoordinatorEventContext = createNamedContext<Coordinator.Event.Context>(undefined, 'Conference.Coordinator.Event.Context');

export const ConferenceLiveRoomBarContext = createNamedContext<LiveRoom.Bar.Context>(undefined, 'Conference.LiveRoom.Bar.Context');

export const ConferenceLiveRoomScreenContext = createNamedContext<LiveRoom.Screen.Context>(undefined, 'Conference.LiveRoom.Screen.Context');

export const ConferenceLiveRoomSelfDialOutContext = createNamedContext<LiveRoom.SelfDialOut.Context>(undefined, 'Conference.LiveRoom.SelfDialOut.Context');

export const ConferenceSocketContext = createNamedContext<Socket.Context>(undefined, 'Conference.Socket.Context');

export const ConferenceTwilioVoiceContext = createNamedContext<TwilioVoice.Context>(undefined, 'Conference.TwilioVoice.Context');

export const ConferenceTwilioVideoContext = createNamedContext<TwilioVideo.Context>(undefined, 'Conference.TwilioVideo.Context');

export const ConferenceTwilioLogContext = createNamedContext<TwilioLogs.Context>(undefined, 'Conference.Twilio.Logs.Context');