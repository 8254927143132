import { memo } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import pickerCSS from 'react-datepicker/dist/react-datepicker.css';
import { cx, useUseableCSS } from '@utils';
import styles from './style.css';

type Props = ReactDatePickerProps;

export const DatePicker = memo(({
  className,
  dateFormat = 'M/d/yyyy',
  selected = new Date(),
  ...rest
}: Props) => {

  useUseableCSS(pickerCSS);

  return (
    <ReactDatePicker
      className={cx(styles.date, className)}
      calendarClassName={styles.customPicker}
      dateFormat={dateFormat}
      selected={selected}
      autoComplete="off"
      disabledKeyboardNavigation
      {...rest} />
  );
});

export default DatePicker;