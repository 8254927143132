import { useEnumerateTopicPosts } from '@utils/api';
import { TopicPostsContext } from './Context';

type Props = {
  enabled: boolean;
  slug: string;
} & ChildrenProps;

export function TopicPostsContainer({ enabled, children, slug }: Props) {
  const query = useEnumerateTopicPosts(slug, {
    getNextPageParam: lastPage => lastPage.next,
    refetchOnWindowFocus: false,
    enabled,
  });

  const value = {
    query,
  };

  return (
    <TopicPostsContext.Provider value={value}>
      {children}
    </TopicPostsContext.Provider>
  );
}