import { ContentFeed } from '@/types';
import { Feed } from '@/components/ContentFeed';
import { Card } from './Layout';
import styles from './style/Related.css';

type Props = {
  items: ContentFeed.NewsArticle[];
};

export const NewsFeed = ({ items }: Props) => {
  return (
    <>
      {items.map(post => (
        <Card
          className={styles.article}
          key={post.id}>
          <Feed.NewsArticle item={post} />
        </Card>
      ))}
    </>
  );
};