import { useCallback, useState } from 'react';
import { Button } from '@/components/Button';
import { Input } from '@/components/Input';
import { Modal } from '@/components/Modal/Modal';
import { LinkSelection } from './Context';
import styles from './style/Link.Modal.css';

type Props = {
  canEditText: boolean;
  onCancel: () => void;
  onSubmit: (value: SubmitParams) => void;
  selection: LinkSelection;
};

type SubmitParams = {
  href: string;
  text: string;
};

export const LinkExtensionModal = ({
  canEditText,
  onCancel,
  onSubmit,
  selection,
}: Props) => {

  const [text, setText] = useState(selection.text || '');
  const [href, setHref] = useState(selection.href || '');

  const canSave = href && text;

  const handleSubmit = useCallback(() => {
    onSubmit({ href, text });
  }, [
    href,
    onSubmit,
    text,
  ]);

  const handleHrefKeyPress = useCallback(({ key }: React.KeyboardEvent<HTMLInputElement>) => {
    if (key === 'Enter' && canSave) {
      handleSubmit();
    }

    if (key === 'Escape') {
      onCancel();
    }
  }, [
    canSave,
    handleSubmit,
    onCancel,
  ]);

  return (
    <Modal
      open={true}
      className={styles.modalWrap}
      hideCloseIcon={true}>
      <div className={styles.main}>
        <div className={styles.form}>

          <div className={styles.row}>
            <div className={styles.label}>Text</div>
            {!canEditText
              ? <div className={styles.input}>{text}</div>
              : <Input
                  defaultValue={text}
                  className={styles.input}
                  onChange={e => setText(e.target.value)} />}
          </div>

          <div className={styles.row}>
            <div className={styles.label}>Link</div>

            <Input
              className={styles.input}
              onChange={e => setHref(e.target.value)}
              value={href}
              onKeyPress={handleHrefKeyPress} />
          </div>

          <div className={styles.row}>
            <div className={styles.btns}>
              <Button
                className={styles.btn}
                color="destructive"
                onClick={onCancel}
                variant="brick">
                Cancel
              </Button>
              <Button
                className={styles.btn}
                disabled={!canSave}
                onClick={handleSubmit}
                variant="brick">
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};