import { Helmet } from 'react-helmet';
import { RequestCallContainer } from '@containers/RequestCall/RequestCallContainer';
import { SearchContainer } from '@containers/UniversalSearch/SearchContainer';
import Main from './Search.Main';

export default function Search() {
  return (
    <SearchContainer>
      <RequestCallContainer>
        <Helmet title="Search" />
        <Main />
      </RequestCallContainer>
    </SearchContainer>
  );
}

export { Search };