import { Room } from 'twilio-video';
import { useDominantSpeaker, useParticipants, useScreenShareParticipant } from '@/components/Conference.Video/hooks';
import { useSelectedParticipant } from './useSelectedParticipant';

export function useMainSpeaker(room: Room) {
  const screenShareParticipant = useScreenShareParticipant(room);
  const dominantSpeaker = useDominantSpeaker(room);
  const participants = useParticipants(room);
  const [selectedParticipant] = useSelectedParticipant();
  const localParticipant = room?.localParticipant;
  const remoteScreenShareParticipant = screenShareParticipant !== localParticipant ? screenShareParticipant : null;

  return selectedParticipant || remoteScreenShareParticipant || dominantSpeaker || participants[0] || localParticipant;
}