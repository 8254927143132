import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { Details } from './Frame.Details';

const selectGroupFeatures = (state: Store.State) => state.group.features;

export const ProjectOverview = forwardRef<HTMLInputElement>((_, ref) => {
  const groupFeatures = useSelector(selectGroupFeatures);

  return (
    <Details
      features={groupFeatures} />
  );
});

ProjectOverview.displayName = 'Project.Creation.Overview';