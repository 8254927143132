import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import { useDebounceValue } from '@utils';
import * as Column from './Column';
import { QueueTable } from './Queue.Table';
import styles from './style/Call.Enhancement.Queue.css';

type Props = unknown;

export const CallEnhancementQueue = (props: Props) => {
  const [qp, setQuery] = useQueryParams({
    code: withDefault(StringParam, ''),
    index: withDefault(NumberParam, 0),
    project: withDefault(StringParam, ''),
    size: withDefault(NumberParam, 50),
  });

  const debounced = useDebounceValue({
    code: qp.code,
    project: qp.project,
  });

  const query = useQuery({
    queryKey: [
      'get:cleanup',
      debounced,
      qp.index,
      qp.size,
    ],
    queryFn: () => {
      return api.transcripts.cleanup.fetchItems({
        code: debounced.code,
        index: qp.index,
        project: debounced.project,
        size: qp.size,
      });
    },
    keepPreviousData: true,
  });

  const pagination = {
    pageCount: query.data?.pagination?.pageCount ?? 0,
    totalCount: query.data?.pagination?.totalCount ?? 0,
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>

        <div className={styles.main}>
          <div className={styles.header}>
            <h1 className={styles.title}>Transcript Cleanup</h1>
          </div>

          <div className={styles.filters}>
            <Column.Project.Filter
              setValue={value => setQuery({ project: value })}
              value={qp.project} />

            <Column.Code.Filter
              setValue={value => setQuery({ code: value })}
              value={qp.code} />
          </div>

          <div className={styles.table}>
            <QueueTable
              data={query.data?.items ?? []}
              pagination={pagination}
              query={qp}
              setQuery={setQuery} />
          </div>
        </div>
      </div>
    </div>
  );
};

CallEnhancementQueue.displayName = 'Call.Enhancement.Queue';