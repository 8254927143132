import { NumberInput } from '@/components/Input';
import { Field } from './Overview.Field';
import * as Tooltip from './Tooltip';
import styles from './style/Overview.css';

type Props = {
  label:     string;
  onChange:  (value: string) => unknown;
  required?: boolean;
  value:     string;
};

export const PurchaseOrder = (props: Props) => {

  return (
    <Field
      alert={props.required ? '*' : null}
      label={props.label}
      tooltip={<Tooltip.PurchaseOrder />}
      tooltipStyle={{
        maxWidth: `unset`,
        backgroundColor: `var(--pri-01)`,
        boxShadow: `var(--box-shadow)`,
        padding: 0,
      }}>
      <div className={styles.type}>
        <NumberInput
          allowLeadingZeros
          classes={{ input: styles.numeric }}
          decimalScale={0}
          maxLength={5}
          minLength={5}
          onValueChange={item => props.onChange(item.value)}
          value={props.value} />
      </div>
    </Field>
  );
};

PurchaseOrder.displayName = 'Project.Details.Field.PurchaseOrder';