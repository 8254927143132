import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectStateLoadingContext, ProjectStateContext } from '@containers/GroupProject/Context';
import { ProjectStateContainer } from '@containers/GroupProject/Project.State.Container';
import { ProjectSubtype } from '@enums';
import { ProjectAccessQueryContainer } from '@/components/Project.Access/Project.Access.Query.Container';
import { TagItem } from '@/components/Project.Tags/interfaces';
import { OnboardingFormContext } from './Context';
import { ProjectTagsOnboardingStepper } from './Project.Tags.Onboarding.Stepper';

type Props = {
  children: React.ReactNode;
};

const ProjectTagsOnboardingFormContainer = (props: Props) => {
  const state = useContext(ProjectStateContext);
  const loading = useContext(ProjectStateLoadingContext);
  const [subtypes, setSubtypes] = useState<ProjectSubtype[]>([]);
  const [initialized, setInitialized] = useState(false);
  const [categories, setCategories] = useState<TagItem[]>([]);

  const setInitialState = useCallback(() => {
    setSubtypes([state.project.projectSubtype]);
    setInitialized(true);
  }, [
    setInitialized,
    setSubtypes,
    state.project?.projectSubtype,
  ]);

  useEffect(() => {

    if (!initialized && !loading) {
      setInitialState();
    }

  }, [
    initialized,
    setInitialState,
    loading,
  ]);

  const handleChangeSubtype = useCallback((subtype: ProjectSubtype, checked: boolean) => {
    setSubtypes(values => {
      if (!checked) {
        return values.filter(val => val !== subtype);
      } else if (hasRequiredExclusivity(subtype)) {
        return [subtype];
      } else {
        return [...new Set([...values.filter(val => !hasRequiredExclusivity(val)), subtype])];
      }
    });
  }, [setSubtypes]);

  const handleRemovePresetCategory = useCallback((id: number) => {
    setCategories(items => {
      return items.filter(x => x.id !== id);
    });
  }, [setCategories]);

  const value = {
    categories,
    onChangeSubtype: handleChangeSubtype,
    onRemovePresetCategory: handleRemovePresetCategory,
    replaceCategoriesState: setCategories,
    subtypes,
  };

  return (
    <OnboardingFormContext.Provider value={value}>
      {props.children}
    </OnboardingFormContext.Provider>
  );
};

const ProjectTagsStateContainer = (props: Props) => {
  const state = useContext(ProjectStateContext);

  const projectId = state.project?.id;

  return (
    <ProjectTagsOnboardingFormContainer>
      <ProjectTagsOnboardingStepper projectId={projectId} />
    </ProjectTagsOnboardingFormContainer>
  );
};

ProjectTagsStateContainer.displayName = 'Project.Tags.State.Container';

export const ProjectTagsOnboardingContainer = (props: Props) => {
  const { projectId } = useParams<Stringify<IProjectId>>();

  return (
    <ProjectAccessQueryContainer projectId={+projectId}>
      <ProjectStateContainer>
        <ProjectTagsStateContainer>
          {props.children}
        </ProjectTagsStateContainer>
      </ProjectStateContainer>
    </ProjectAccessQueryContainer>
  );
};

ProjectTagsOnboardingContainer.displayName = 'Project.Tags.Onboarding.Container';

function hasRequiredExclusivity(subtype: ProjectSubtype) {
  return subtype === ProjectSubtype.Other;
}