import { useCallback } from 'react';
import * as consts from '@consts';
import { useSelectUser } from '@containers/Store';
import { getLocationFor } from '@utils';

const useResolveRouteForNewUser = () => {
  const user = useSelectUser();

  const resolveRoute = useCallback(() => {
    return user.referrer?.projectId
      ? getLocationFor.onboarding.project({ projectId: user.referrer.projectId })
      : consts.pathname.Welcome;
  }, [user.referrer?.projectId]);

  return resolveRoute;
};

export { useResolveRouteForNewUser };
export default useResolveRouteForNewUser;