import { useContext } from 'react';
import { useInitiateFilesReview } from '@/containers/WorkspaceFileUpload/hooks/useInitiateFilesReview';
import { CanUploadFilesContext, UploadFilesContext } from '@/containers/WorkspaceFileUpload/Context';
import { useWorkspaceState } from '@/containers/Workspace/hooks/useWorkspaceState';
import { FileUploadReviewContainer } from '@/containers/WorkspaceFileUpload/Upload.Review';
import { FilesTable } from '@/components/Workspace.FilesTable';
import {
  FilesTableContext,
  FilesTableDataContext,
  SetFilesTableFiltersContext,
  FilesTableFiltersContext,
  FilesTableLoadingContext,
  SelectedObjectsContainer,
} from '@/components/Workspace.FilesTable';
import { useFetchObjectChildren } from '@/utils/api/workspace.objects';
import { TabViewTableSkeleton } from '@/components/TabView';
import { useWorkspaceParams } from './hooks/useWorkspaceParams';
import styles from './style/Workspaces.css';

const WorkspaceTabFiles = () => {

  const loading = useContext(FilesTableLoadingContext);

  return (
    <div className={styles.tableWrap}>
      {loading
        ? <TabViewTableSkeleton />
        : <FilesTable />
      }
    </div>
  );
};

const WorkspaceFilesContainer = (props: ChildrenProps) => {

  const { workspace } = useWorkspaceState();

  const [params, setQueryParams] = useWorkspaceParams();

  const uploadFiles = useInitiateFilesReview({
    parentObjectId: workspace.objectId,
    workspaceId: workspace.id,
  });

  const query = useFetchObjectChildren({
    workspaceId: workspace.id,
    parentObjectId: workspace.objectId,
  }, {
    refetchOnWindowFocus: false,
  });

  return (
    <SelectedObjectsContainer>
      <FilesTableDataContext.Provider value={query.data?.items || []}>
        <FilesTableLoadingContext.Provider value={query.isLoading}>
          <FilesTableFiltersContext.Provider value={params}>
            <SetFilesTableFiltersContext.Provider value={setQueryParams}>
              <FilesTableContext.Provider value={{ parentObject: { id: workspace.objectId, workspaceId: workspace.id } }}>
                <UploadFilesContext.Provider value={uploadFiles}>
                  <CanUploadFilesContext.Provider value={true}>
                    {props.children}
                  </CanUploadFilesContext.Provider>
                </UploadFilesContext.Provider>
              </FilesTableContext.Provider>
            </SetFilesTableFiltersContext.Provider>
          </FilesTableFiltersContext.Provider>
        </FilesTableLoadingContext.Provider>
      </FilesTableDataContext.Provider>
    </SelectedObjectsContainer>
  );
};

const WithState = () => {

  return (
    <FileUploadReviewContainer>
      <WorkspaceFilesContainer>
        <WorkspaceTabFiles />
      </WorkspaceFilesContainer>
    </FileUploadReviewContainer>
  );
};

export { WithState as WorkspaceTabFiles };
export default WithState;