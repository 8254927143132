import { useCallback, useEffect, useMemo, useState } from 'react';
import { Participant } from 'twilio-video';
import { useTwilioVideo } from '@containers/Conference';
import { SelectedParticipantContext } from './Context';
import { SelectedParticipant } from './interfaces';

export function SelectedParticipantContainer({ children }: ChildrenProps) {
  const [selected, setSelected] = useState<Participant>(null);
  const twilio = useTwilioVideo();

  const handleUpdate = useCallback((participant: Participant) => {
    setSelected(prev => (prev === participant ? null : participant));
  }, []);

  const handleDisconnect = useCallback(() => setSelected(null), []);

  useEffect(() => {
    twilio.room.on('disconnected', handleDisconnect);
    return () => {
      twilio.room.off('disconnected', handleDisconnect);
    };
  }, [twilio.room, handleDisconnect]);

  const value: SelectedParticipant.Context = useMemo(() => [
    selected,
    handleUpdate,
  ], [selected, handleUpdate]);

  return (
    <SelectedParticipantContext.Provider value={value}>
      {children}
    </SelectedParticipantContext.Provider>
  );
}