import { useContext } from 'react';
import { SurveyQuestionType } from '@enums';
import { RepeatSurvey, SurveyQuestion } from '@/types';
import { RepeatQuestionResponsesContext } from '../Context';

const useRepeatQuestionResponsesData = <T extends SurveyQuestionType = SurveyQuestionType>() => {
  const context = useContext(RepeatQuestionResponsesContext);

  return {
    aggregate: context.aggregate as RepeatSurvey.AggregateData<T>,
    historical: context.historical as RepeatSurvey.HistoricalQuestionData<T>,
    projectIds: context.projectIds,
    question: context.question as SurveyQuestion<T>,
  };
};

export { useRepeatQuestionResponsesData };
export default useRepeatQuestionResponsesData;