import { useCallback, useRef, useState } from 'react';
import Axios, { CancelTokenSource } from 'axios';
import * as api from '@api';
import { useDebounceCallback } from '@utils';
import Autocomplete from '@/components/AutoComplete';
import { ProfileAutocomplete as PA } from './interfaces';

export const ProfileFormAutocompleteMultiple = ({
  includeCustomItem,
  onSelect,
  ...props
}: PA.MultiProps) => {
  const [results, setResults] = useState<PA.AutocompleteMultiItem[]>([]);
  const [input, setInput] = useState('');
  const cancelSourceRef = useRef<CancelTokenSource>();

  const fetchResults = useCallback((t: string) => {
    cancelSourceRef.current = Axios.CancelToken.source();

    return api.search.getAutocompleteResults({
      ignoreList: props.ignoreList,
      text: t,
      type: props.type,
    }).then(data => {
      const items = data.map(m => ({
        id: m.id,
        name: m.name,
        isCustom: false,
      }));

      setResults([
        includeCustomItem(t) && {
          id: t,
          name: t,
          isCustom: true,
        },
        ...items,
      ].filter(Boolean));
    });
  }, [
    includeCustomItem,
    props.ignoreList,
    props.type,
  ]);

  const fetchDebounced = useDebounceCallback(fetchResults);

  const handleSelect = useCallback((item: PA.AutocompleteMultiItem) => {
    if (item.isCustom && !includeCustomItem(item.name)) {
      return;
    }

    setResults([]);
    setInput('');
    onSelect(item);
  }, [
    includeCustomItem,
    onSelect,
  ]);

  const handleInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value.trim();

    setInput(keyword);

    if (!keyword) return setResults([]);

    return fetchDebounced(keyword);

  }, [
    fetchDebounced,
  ]);

  return (
    <Autocomplete.Tags
      autoFocus={props.autoFocus}
      getItemValue={(v: PA.AutocompleteMultiItem) => v.name}
      items={results}
      name={props.name}
      onChange={handleInput}
      onSelect={handleSelect}
      placeholder={props.placeholder}
      value={input} />
  );
};

export default ProfileFormAutocompleteMultiple;