import { SurveyConditionType, SurveyQuestionType, SurveyQuestionConditionType } from '@enums/Survey';
import { SurveyLogic } from '@/types/survey';

export function generateConditionString(item: SurveyLogic.SurveyEnrichedCondition) {

  if (item.conditionType === SurveyConditionType.Question) {
    return generateQuestionConditionString(item);
  } else if (item.conditionType === SurveyConditionType.AggregatedSum) {
    return generateSumConditionString(item);
  } else if (item.conditionType === SurveyConditionType.Classification) {
    return `Classified as ${item.data.name}`;
  } else {
    throw new UnreachableCaseError(item);
  }
}

export function generateSumConditionString(item: SurveyLogic.EnrichedSumCondition) {

  const comparatorText = `${item.data.operator} ${item.data.comparate}`;

  const sumText = item.data.items.map(f => {
    const questionText = `Q${f.question.ordinal}`;

    if (f.question.typeId === SurveyQuestionType.Sliders) {
      const valuesText = (f as SurveyLogic.EnrichedNumberCellValue).values.map(m => {
        return `R${m.row.ordinal}`;
      }).join('/');

      return `${questionText} ${valuesText}`;
    } else if (f.question.typeId === SurveyQuestionType.NumberInputTable) {
      const valuesText = (f as SurveyLogic.EnrichedNumberCellValue).values.map(m => {
        return `R${m.row.ordinal}A${m.option.ordinal}`;
      }).join('/');
      return `${questionText} ${valuesText}`;
    }

    return ``;
  }).join(', ');

  return `Sum of ${sumText} ${comparatorText}`;
}

export function generateQuestionConditionString(item: SurveyLogic.EnrichedQuestionCondition) {
  if (!item) return null;

  const questionText = `Q${item.data.question.ordinal}`;

  switch (item.data.question.typeId) {

    case SurveyQuestionType.Dropdown:
    case SurveyQuestionType.ExclusiveOptions:
    case SurveyQuestionType.MultipleChoice: {
      const condition = item as SurveyLogic.EnrichedQuestionCondition<SurveyQuestionType.MultipleChoice>;
      const type = condition.data.type === SurveyQuestionConditionType.OptionSelected
        ? 'Selected'
        : 'Not selected';

      const optionText = `A${condition.data.value.option.ordinal}`;

      return `${questionText} ${optionText} ${type}`;
    }

    case SurveyQuestionType.Ranking: {
      const condition = item as SurveyLogic.EnrichedQuestionCondition<SurveyQuestionType.Ranking>;

      const conditionText = condition.data.type === SurveyQuestionConditionType.OptionRankedFirst
        ? 'Ranked first'
        : 'Ranked last';

      const optionText = `A${condition.data.value.option.ordinal}`;

      return `${questionText} ${optionText} ${conditionText}`;
    }

    case SurveyQuestionType.NumberInputTable: {
      const condition = item as SurveyLogic.EnrichedQuestionCondition<SurveyQuestionType.NumberInputTable>;

      const selectionText = `R${condition.data.value.row.ordinal}-A${condition.data.value.option.ordinal}`;

      const comparatorText = `${condition.data.value.operator} ${condition.data.value.comparate}`;

      return `${questionText} ${selectionText} ${comparatorText}`;
    }

    case SurveyQuestionType.Sliders: {
      const condition = item as SurveyLogic.EnrichedQuestionCondition<SurveyQuestionType.Sliders>;

      if (condition.data.type === SurveyQuestionConditionType.SliderValuesTally) {
        const value = condition.data.value;
        const rowsText = `${value.rows.operator} ${value.rows.comparate}`;
        const answerText = `${value.answer.operator}${value.answer.comparate}`;

        return `${questionText}: ${rowsText} row(s) where answer ${answerText}`;
      } else if (condition.data.type === SurveyQuestionConditionType.SliderValue) {

        const rowText = `R${condition.data.value.row.ordinal}`;

        const comparatorText = `${condition.data.value.operator}${condition.data.value.comparate}`;

        return `${questionText} ${rowText} ${comparatorText}`;
      }
    }

    case SurveyQuestionType.Multiselect: {
      const condition = item as SurveyLogic.EnrichedQuestionCondition<SurveyQuestionType.Multiselect>;

      if (condition.data.type === SurveyQuestionConditionType.NumberOfOptionsSelected) {
        const comparatorText = `${condition.data.value.operator} ${condition.data.value.comparate}`;

        return `${questionText} ${comparatorText}`;
      }

      const type = condition.data.type === SurveyQuestionConditionType.OptionSelected
        ? 'Selected'
        : 'Not selected';

      const optionText = `A${condition.data.value.option.ordinal}`;

      return `${questionText} ${optionText} ${type}`;
    }

    case SurveyQuestionType.MatrixRange:
    case SurveyQuestionType.MatrixMultiselect:
    case SurveyQuestionType.MatrixGrid: {
      const condition = item as SurveyLogic.EnrichedQuestionCondition<SurveyQuestionType.MatrixGrid>;

      if (condition.data.type === SurveyQuestionConditionType.Straightline) {
        return [
          'Straightline',
          condition.data.value.comparate && `(${condition.data.value.comparate})`,
        ].filter(Boolean).join(' ');
      }

      const type = condition.data.type === SurveyQuestionConditionType.MatrixValueChosen
        ? 'Selected'
        : 'Not selected';

      const selectionText = `R${condition.data.value.row.ordinal}-A${condition.data.value.option.ordinal}`;

      return `${questionText} ${selectionText} ${type}`;
    }

    case SurveyQuestionType.LongTextResponse:
    case SurveyQuestionType.ShortTextResponse:
    case SurveyQuestionType.MaxDifference:
    case SurveyQuestionType.ConjointAnalysis:
    case SurveyQuestionType.MultiTextbox:
    case SurveyQuestionType.ImageMarkup:
      return '[Invalid]';

    default:
      throw new UnreachableCaseError(item.data.question.typeId);
  }

}

export function wrapInParentheses(v: string) {
  return `(${v})`;
}

export function formatAvgDuration(d: number) {
  const minutes = Math.floor(d / 60000);
  const seconds = Math.floor((d % 60000) / 1000);
  return `${minutes}m ${seconds}s`;
}