import { useCallback, useMemo } from 'react';
import { ComputedDatum, BarTooltipProps } from '@nivo/bar';
import { MatrixSliderChartTooltip } from '@presentation/ProjectSurveyResponses';
import { MatrixSliderQuestion } from '@/types';
import { formatSurveyNumber } from '@utils/survey';
import BarChart from './BarChart';
import { MatrixSliderItem } from './interfaces';

type Props = {
  question: MatrixSliderQuestion.Question;
  items: MatrixSliderItem[];
  onClick: (rowId: number) => void;
  reversed: boolean;
};

export const ResponseMatrixSliderChart = (props: Props) => {

  const renderTooltip = useCallback((item: BarTooltipProps<MatrixSliderItem>) => {
    const data = item.data;

    return (
      <MatrixSliderChartTooltip
        avg={data.displayAvg}
        row={data.name} />
    );
  }, []);

  const formatBottomAxisLabel = useCallback((value: string) => {
    return Number.isInteger(+value)
      ? formatSurveyNumber(+value, props.question.settings?.numberSettings)
      : '';
  }, [props.question.settings?.numberSettings]);

  const formatLabel = useCallback((item: ComputedDatum<MatrixSliderItem>) => {
    const data = item.data;
    return data.displayAvg;
  }, []);

  const keys = useMemo(() => props.items.map(x => x.id.toString()), [props.items]);

  const maxValue = useMemo(() => {
    const settingsMax = props.question.settings?.slider?.maxValue;
    const rawMax = Math.max(...props.items.map(x => x.avg));

    if (settingsMax) {
      if (settingsMax < rawMax * 10) return settingsMax;
    }

    return Math.round(rawMax + (rawMax * 0.1 < 1 ? 1 : rawMax * 0.1));
  }, [props.items, props.question.settings]);

  return (
    <BarChart<MatrixSliderItem>
      data={props.items}
      keys={keys}
      reverse={props.reversed}
      axisBottom={{
        format: formatBottomAxisLabel,
        legend: 'Average',
        legendPosition: 'middle',
        legendOffset: 40,
      }}
      maxValue={maxValue}
      //minValue={props.question.settings?.slider?.minValue} //Min values higher than 0 don't work so well, the bar still starts from 0 no matter what
      label={formatLabel}
      margin={{ top: 0, right: 25, bottom: 50, left: 25 }}
      onClick={props.onClick}
      tooltip={renderTooltip} />
  );
};

export default ResponseMatrixSliderChart;