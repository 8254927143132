import { useState } from 'react';
import { cx } from '@utils';
import { ChartRefContext } from './context';
import styles from './style/ChartWrapper.css';

type Props = {
  className?: string;
  style?: React.CSSProperties;
} & ChildrenProps;

export const ChartWrapper = ({ children, className, style }: Props) => {
  const [ref, setRef] = useState<HTMLDivElement>(null);

  return (
    <div
      className={cx(styles.root, className)}
      ref={setRef}
      style={style}>
      <ChartRefContext.Provider value={ref}>
        {children}
      </ChartRefContext.Provider>
    </div>
  );
};

export default ChartWrapper;