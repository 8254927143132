import { useTemplateDrugModal } from '@/components/Survey.Templates/Modal.Drug';
import { TemplateItemLabel } from './Label.Base';

type Props = {
  className?: string;
  disabled: boolean;
  value: string;
};

export const TemplateDrug = ({ className, disabled, value }: Props) => {

  const [toggleModal, Modal] = useTemplateDrugModal();

  return (
    <>
      <TemplateItemLabel
        className={className}
        disabled={disabled}
        label={value}
        tooltip="Drug"
        onClick={toggleModal} />
      {!disabled && <Modal />}
    </>
  );
};