import type { Participant } from 'twilio-video';
import type { IConference } from '@containers/Conference';
import { useTwilioVideo, useConferenceInstance } from '@containers/Conference';
import { useParticipants } from '@/components/Conference.Video/hooks';

export const useGalleryParticipants = () => {
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom>();
  const twilio = useTwilioVideo();
  const participants = useParticipants(twilio.room);

  return [twilio.room.localParticipant, ...participants].filter(p => {
    return [...p.tracks.values()].some(t => t.isEnabled) ||
      instance.participants.find(p2 => p2.twilioIdentity === p.identity)?.isVisible;
  });
};

//Don't do this quite yet, we'll want to keep the dominant speaker ahead of everyone else still
function sortParticipants(a: Participant, b: Participant) {
  return a.videoTracks.size - b.videoTracks.size;
}