import { useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import * as api from '@api';
import type { GetCallDetails } from '@api/interfaces/calls';
import { path } from '@consts';
import { useSelectUser, useIsInternalUser } from '@containers/Store';
import { CallStatus, utils } from '@enums';
import { hasSchedulingPermission, hasInternalAdminRole } from '@utils';
import { useCallDetailsQuery } from '@utils/api';
import * as CallCancellation from '$admin/components/Call.Cancellation';
import * as CallEditSettings from '$admin/components/Call.EditSettings';
import * as CallRescheduling from '$admin/components/Call.Rescheduling';
import * as CallScheduling from '$admin/components/Scheduling';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import { ConferenceGuestPins } from './Conference.GuestPins';
import { ConferenceParticipants } from './Conference.Participants';
import { CallDetailsContext } from './Context';
import * as Field from './Field';
import { Header } from './Header';
import * as Layout from './Layout';
import type { Mutation, RestrictedField } from './interfaces';

type Props = unknown;

export const CallMain = (props: Props) => {
  const history = useHistory();
  const params = useParams<Stringify<ICallId>>();

  const callId = params.callId
      ? +params.callId
      : null;

  const mutation = useMutation<Mutation.Response, Mutation.Error, Mutation.Variables>([
    `patch:calls`,
    callId,
  ], data => {
    return api.calls.updateCallDetails({
      callId,
      ...data,
    });
  });

  const query = useCallDetailsQuery({ callId }, {
    onError: (error: AxiosResponse) => {
      if (error.status === 404) {
        history.replace(path.Calls.NotFound);
      }
    },
    enabled: !!callId,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const editable = useEditable({
    ownerId: query.data?.call?.owner?.id,
    statusId: query.data?.call?.statusId,
  });

  const pagetitle = useMemo(() => {
    const title = query.data?.call?.title || query.data?.project?.name;

    return `${title} - Call Details`;
  }, [query.data]);

  const isInternalUser = useIsInternalUser();

  const canChangeOwner = useCanChangeOwner({
    statusId: query.data?.call?.statusId,
  });

  const canDisplay = useCallback((value: RestrictedField) => {
    return value !== undefined
      && isInternalUser;
  }, [isInternalUser]);

  if (!callId) {
    return (
      <Redirect to={path.Calls.NotFound} />
    );
  }

  if (query.isLoading) {
    return (
      <ActivityIndicator show />
    );
  }

  const value = {
    editable,
    mutation,
    query,
  };

  return (
    <CallDetailsContext.Provider value={value}>
      <CallScheduling.Container onSuccess={() => query.refetch()}>
        <CallRescheduling.Container onSuccess={() => query.refetch()}>
          <CallCancellation.Container onSuccess={() => query.refetch()}>
            <CallEditSettings.Container onSuccess={() => query.refetch()}>
              <Helmet title={pagetitle} />
              <Layout.Screen>
                <Header />
                <Field.Title />
                <Field.Time />
                <Field.Project />
                <Field.Link />
                <Field.Details />
                {query.data.settings.clientNotes !== undefined &&
                  <Field.ClientNotes />}
                {query.data.conference.guestPins !== undefined &&
                  <ConferenceGuestPins />}
                {canDisplay(query.data.conference.participants) &&
                  <ConferenceParticipants />}
                {canDisplay(query.data.call.owner) &&
                  <Layout.Row>
                    <Field.Owner
                      editable={canChangeOwner}
                      label="Call Owner" />
                  </Layout.Row>}
                {canDisplay(query.data.settings.conferenceType) &&
                  <Layout.Row>
                    <Layout.Label>Conference Type</Layout.Label>
                    {utils.ConferenceType.getName(query.data.settings.conferenceType)}
                  </Layout.Row>}
                {canDisplay(query.data.settings.languages) &&
                  <Layout.Row>
                    <Layout.Label>Transcript Languages</Layout.Label>
                    {query.data.settings.languages?.map(x => x.name).join(' / ') || 'Not Set'}
                  </Layout.Row>}
                {canDisplay(query.data.settings.screenShareTypeId) &&
                  <Layout.Row>
                    <Layout.Label>Screen Sharing</Layout.Label>
                    {utils.ConferenceFeatureShareType.getName(query.data.settings.screenShareTypeId)}
                  </Layout.Row>}
                {canDisplay(query.data.settings.videoShareTypeId) &&
                  <Layout.Row>
                    <Layout.Label>Video Share</Layout.Label>
                    {utils.ConferenceFeatureShareType.getName(query.data.settings.videoShareTypeId)}
                  </Layout.Row>}
                {canDisplay(query.data.tags) &&
                  <Layout.Row>
                    <Layout.Label>Conference Tags</Layout.Label>
                    {query.data.tags?.map(x => x.name).join(', ') || 'Not Set'}
                  </Layout.Row>}
              </Layout.Screen>
            </CallEditSettings.Container>
          </CallCancellation.Container>
        </CallRescheduling.Container>
      </CallScheduling.Container>
    </CallDetailsContext.Provider>
  );
};

CallMain.displayName = 'Call.Main';

const useCanChangeOwner = (data: UseCanChangeOwnerParams) => {
  const user = useSelectUser();

  const validCallStatus = [
    CallStatus.Pending,
    CallStatus.Rescheduling,
    CallStatus.Scheduled,
  ].includes(data?.statusId);

  if (!validCallStatus) return false;

  return hasInternalAdminRole(user)
      || hasSchedulingPermission(user);
};

const useEditable = (data: UseEditableParams) => {
  const user = useSelectUser();

  const validCallStatus = ![
    CallStatus.Completed,
    CallStatus.MissedByClient,
    CallStatus.Rejected,
    CallStatus.Cancelled,
  ].includes(data?.statusId);

  if (!validCallStatus) return false;

  const isAdmin = hasInternalAdminRole(user);

  if (isAdmin) return true;

  return hasSchedulingPermission(user)
      && user.id === data?.ownerId;
};

type UseCanChangeOwnerParams = Pick<GetCallDetails.Response['call'], 'statusId'>;
type UseEditableParams = {
  ownerId: number;
} & Pick<GetCallDetails.Response['call'], 'statusId'>;