import { useRef, useCallback } from 'react';
import { CallRole } from '@enums';
import { Checkbox } from '@/components/Checkbox';
import { roleIsModeratorLike, roleIsRespondentLike } from '@/components/Conference.Participants/utils';
import { useRoleFilterContext } from './context';
import styles from './style/RoleSelection.css';

export const RoleSelection = () => {
  const [roleFilters, setRoleFilters] = useRoleFilterContext();
  const moderatorBoxRef = useRef<HTMLInputElement>();
  const respondentBoxRef = useRef<HTMLInputElement>();

  const recalculateFilter = useCallback(() => {
    if (moderatorBoxRef.current.checked && respondentBoxRef.current.checked) {
      setRoleFilters([]);
    } else {
      const roles = [CallRole.Attendee];

      if (moderatorBoxRef.current.checked) {
        roles.push(CallRole.Scheduler);
        roles.push(CallRole.ModeratorAttendee);
      }

      if (respondentBoxRef.current.checked) {
        roles.push(CallRole.PrimaryRespondent);
        roles.push(CallRole.Respondent);
      }

      setRoleFilters(roles);
    }
  }, [setRoleFilters]);

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        Include Speakers
      </div>
      <div className={styles.group}>
        <div className={styles.label}>
          <label htmlFor='moderaterRoleCheckbox'>
            Moderator:
          </label>
        </div>
        <Checkbox
          id='moderaterRoleCheckbox'
          inputRef={moderatorBoxRef}
          onChange={recalculateFilter}
          checked={!roleFilters.length || roleFilters.some(roleIsModeratorLike)} />
      </div>
      <div className={styles.group}>
        <div className={styles.label}>
          <label htmlFor='respondentRoleCheckbox'>
            Respondent:
          </label>
        </div>
        <Checkbox
          id='respondentRoleCheckbox'
          inputRef={respondentBoxRef}
          onChange={recalculateFilter}
          checked={!roleFilters.length || roleFilters.some(roleIsRespondentLike)} />
      </div>
    </div>
  );
};