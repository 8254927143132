import { memo } from 'react';
import { cx } from '@utils';
import styles from './style/Card.css';

type Props = {
  onClick?: () => void;
};

export const ExternalSurveyCard = memo(({ onClick }: Props) => {

  const title = `Use External Survey Platform`;

  return (
    <div
      className={styles.root}
      onClick={onClick}>
      <div className={cx(styles.poster, styles.external)}>
        <Icon />
      </div>
      <div className={styles.footer}>
        <div className={styles.title}>{title}</div>
      </div>
    </div>
  );
});

type IconProps = {
  color?: string;
};

const Icon = memo(({
  color = '#626262',
}: IconProps) => (
  <svg
    width="40px"
    height="34px"
    viewBox="0 0 32 28"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g
      id="Survey-Templates---External"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round">
      <g
        id="1"
        transform="translate(-514.000000, -217.000000)"
        stroke={color}
        strokeWidth="2">
        <g
          id="Group-3"
          transform="translate(515.000000, 218.000000)">
          <path
            d="M3,13.7274801 L3,13.7274801 L3,3.42857143 C3,1.53502371 4.55978216,0 6.48387097,0 L26.516129,0 C28.4402178,0 30,1.53502371 30,3.42857143 L30,20.5714286 C30,22.4649763 28.4402178,24 26.516129,24 L12.0464305,24 L12.0464305,24"
            id="Path" />
          <g
            id="corner-up-right"
            transform="translate(0.000000, 15.000000)"
            strokeLinejoin="round">
            <polyline
              id="Path"
              points="7.61538462 6.76923077 11 3.38461538 7.61538462 0" />
            <path
              d="M0,11 C-5.15069395e-16,6.79413921 3.40952383,3.38461538 7.61538462,3.38461538 L11,3.38461538 L11,3.38461538"
              id="Path" />
          </g>
          <line
            x1="3"
            y1="5.5"
            x2="30"
            y2="5.5"
            id="Path-6" />
        </g>
      </g>
    </g>
  </svg>
));