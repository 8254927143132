import { useRepeatSurveyData, useRepeatQuestionResponsesData } from '@containers/RepeatSurveyResponses/hooks';
import { ConjointAnalysisRepeatSurveyContainer } from '@containers/RepeatSurveyResponses/ConjointAnalysisContainer';
import { SurveyQuestionResponsesContext } from '@containers/SurveyResponses/Context';
import { SurveyQuestionType } from '@enums';
import { RepeatOptions } from '@/components/SurveyResponses.Repeat/Options';
import { RepeatMatrixSlider } from '@/components/SurveyResponses.Repeat/MatrixSlider';
import { RepeatRanking } from '@/components/SurveyResponses.Repeat/Ranking';
import { QuestionResponses } from '@/components/SurveyResponses';
import { Spinner } from '@/components/ActivityIndicator';
import styles from './style/QuestionResponses.css';
import ConjointAnalysis from './ConjointAnalysis';
import MaxDiff from './MaxDiff';

function Latest() {
  const { projectIds } = useRepeatSurveyData();
  const { question, historical } = useRepeatQuestionResponsesData();

  const latestProject = projectIds[projectIds.length - 1];
  const {
    data: responses,
    total,
  } = historical[latestProject];

  return (
    <SurveyQuestionResponsesContext.Provider value={{
      question,
      rationale: {},
      responses,
      total,
    }}>
      <QuestionResponses />
    </SurveyQuestionResponsesContext.Provider>
  );
}

const RepeatQuestionTypes = [
  SurveyQuestionType.MultipleChoice,
  SurveyQuestionType.Multiselect,
  SurveyQuestionType.Ranking,
  SurveyQuestionType.Sliders,
  SurveyQuestionType.ConjointAnalysis,
  SurveyQuestionType.MaxDifference,
];

export const RepeatSurveyQuestionResponses = () => {
  const { question, aggregate } = useRepeatQuestionResponsesData();

  if (!RepeatQuestionTypes.includes(question.typeId)) {
    return <Latest />;
  }

  if (!aggregate) {
    return (
      <div className={styles.loading}>
        <Spinner />
      </div>
    );
  }

  switch (question.typeId) {
    case SurveyQuestionType.Multiselect:
    case SurveyQuestionType.MultipleChoice: {
      return (
        <RepeatOptions />
      );
    }

    case SurveyQuestionType.Ranking: {
      return (
        <RepeatRanking />
      );
    }

    case SurveyQuestionType.Sliders: {
      return <RepeatMatrixSlider />;
    }

    case SurveyQuestionType.ConjointAnalysis: {
      return (
        <ConjointAnalysisRepeatSurveyContainer>
          <ConjointAnalysis />
        </ConjointAnalysisRepeatSurveyContainer>
      );
    }

    case SurveyQuestionType.MaxDifference: return <MaxDiff />;
  }
};

export default RepeatSurveyQuestionResponses;