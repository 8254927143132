import { memo } from 'react';
import { cx } from '@utils';
import { ConferenceSessionTitleProps } from './interfaces';
import styles from './style/Title.css';

type Props =
  ConferenceSessionTitleProps;

const Title = memo(({
  className,
  title,
  visible = true
}: Props) => {

  if (!visible) return null;

  return (
    <div className={cx(styles.root, className)}>
      {title} Call
    </div>
  );
});

export default Title;