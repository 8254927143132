import { useCallback } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { NumberInputTableQuestion } from '@/types';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import { formatSurveyNumber } from '@utils/survey';
import Grid from './NumberInputTable.Grid';
import List from './NumberInputTable.List';
import { Rationale } from './Rationale';

type Props = {
  answer: NumberInputTableQuestion.Answer.Value;
  question: NumberInputTableQuestion.Question;
  rationale: string;
};

export const NumberInputTableAnswerValue = ({ answer, question, rationale }: Props) => {

  const getCellAnswer = useCallback((rowId: number, optionId: number) => {
    const item = answer.items.find(f => f.option.id === optionId && f.row.id === rowId);
    if (!item?.value) return '-';
    return formatSurveyNumber(item.value, question.settings.numberSettings);
  }, [answer.items, question.settings.numberSettings]);

  const getDisplayRowText = useCallback((row: NumberInputTableQuestion.Row) => {
    const item = answer.items.find(f => f.row.id === row.id);
    const parsedRowValue = parseSurveyRichText(row.value);
    if (!item?.row) return parsedRowValue;
    const openEndedRowValue = item.row.openEndedValue
      ? `[${item.row.openEndedValue}]`
      : '';
    return [parsedRowValue, openEndedRowValue].join(' ');
  }, [answer.items]);

  const getColumnTotal = useCallback((optionId: number) => {

    const total = answer.items.reduce((sum, item) => {
      if (item.option.id === optionId) {
        return sum + item.value;
      }
      return sum;
    }, 0);

    return formatSurveyNumber(total, question.settings.numberSettings);
  }, [answer.items, question.settings.numberSettings]);

  const showGrid = useMediaQuery('(min-width:800px)');

  const params = {
    answer,
    getCellAnswer,
    getColumnTotal,
    getDisplayRowText,
    question,
  };

  return (
    <>
      {showGrid && <Grid {...params} />}
      {!showGrid && <List {...params} />}
      <Rationale value={rationale} />
    </>
  );
};

export default NumberInputTableAnswerValue;