import { useCallback, useMemo, useReducer } from 'react';
import useSearchFiltersQuery from '@/containers/WorkspaceGlobalSearch/hooks/useSearchFilterQuery';
import { Input } from '@/components/Input';
import { Chip } from '@/presentation/Chip';
import { useReducedFilterState } from './hooks/userReducedFilterState';
import styles from './style/Main.Filters.Keyword.css';

export const SearchMainFiltersKeyword = () => {
  const [, setQuery] = useSearchFiltersQuery();
  const filters = useReducedFilterState();

  const [state, dispatch] = useReducer(reducer, { value: '' });

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {

      setQuery({
        keywords: mergeKeywords(filters.keywords, state.value),
      });

      dispatch({ type: 'reset' });
    }
  }, [filters.keywords, state.value, setQuery]);

  const handleTagClick = useCallback((keyword: string) => () => {
    setQuery({
      keywords: filters.keywords.filter(k => k !== keyword),
    });
  }, [filters.keywords, setQuery]);

  const handleClear = useCallback(() => {
    setQuery({ keywords: [] }, 'replaceIn');
  }, [setQuery]);

  const showClear = useMemo(() => filters.keywords.length > 1, [filters.keywords]);

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.title}>Keyword</div>
        {showClear && <div className={styles.clear} onClick={handleClear}>Clear</div>}
      </div>
      <div className={styles.keyword}>
        <Input
          autoComplete="off"
          name="keyword"
          placeholder="Enter a keyword"
          type="text"
          onKeyDown={handleKeyDown}
          onChange={e => dispatch({ type: 'on-change', value: e.target.value })}
          value={state.value} />
      </div>
      <div className={styles.keywords}>
        {filters.keywords.map((keyword, i) => (
          <Chip
            className={styles.selected}
            color="selected"
            key={`keyword_${i}`}>
            <Chip.Body.Action
              className={styles.action}
              onClick={handleTagClick(keyword)}
              variant="remove">
              <div className={styles.label}>{keyword}</div>
            </Chip.Body.Action>
          </Chip>
        ))}
      </div>
    </div>
  );
};

function mergeKeywords(existing: string[], add: string): string[] {
  const compare = (add || '').trim().toLowerCase();
  if (existing.some(k => k.trim().toLowerCase() === compare)) return existing;
  return Array.from([...existing, add]);
}

function reducer(state: InputState, action: Action): InputState {
  switch (action.type) {
    case 'reset': {
      return {
        value: '',
      };
    }
    case 'on-change': {
      return {
        ...state,
        value: action.value,
      };
    }
    default: return state;
  }
}

type InputState = {
  value: string;
};

type Action =
  | { type: 'reset' }
  | { type: 'on-change'; value: string };