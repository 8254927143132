import { useCallback, useContext } from 'react';
import { CalendarsContext } from '$admin/components/Scheduling/Context';

export const useSchedulerCalendarSelector = () => {
  const ctx = {
    calendars: useContext(CalendarsContext),
  };

  const selectSchedulerCalendar = (email: string) => {
    return ctx.calendars.query.data?.items?.find?.(x => x.email === email);
  };

  return useCallback(selectSchedulerCalendar, [
    ctx.calendars.query,
  ]);
};

export const useSelectSchedulerCalendar = (email: string) => {
  const selector = useSchedulerCalendarSelector();

  return selector(email);
};