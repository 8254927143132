import { cx } from '@utils';
import calendly from 'static/images/website/collaboration-tools/icon-app-integration-calendly.svg?url';
import googlecalendar from 'static/images/website/collaboration-tools/icon-app-integration-google-calendar.svg?url';
import mscalendar from 'static/images/website/collaboration-tools/icon-app-integration-ms-calendar.svg?url';
import msexcel from 'static/images/website/collaboration-tools/icon-app-integration-ms-excel.svg?url';
import msoutlook from 'static/images/website/collaboration-tools/icon-app-integration-ms-outlook.svg?url';
import mspowerpoint from 'static/images/website/collaboration-tools/icon-app-integration-ms-powerpoint.svg?url';
import slack from 'static/images/website/collaboration-tools/icon-app-integration-slack.svg?url';
import tableau from 'static/images/website/collaboration-tools/icon-app-integration-tableau.svg?url';
import zoom from 'static/images/website/collaboration-tools/icon-app-integration-zoom.svg?url';
import styles from './style/AppIntegration.css';

type Props = unknown;

export const AppIntegration = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.content}>
          <div className={styles.header}>{copy.title}</div>
          <div className={styles.main}>
            {items.map(x =>
              <div
                className={cx(styles.item, x.className)}
                key={x.src}>
                <img
                  className={styles.icon}
                  src={x.src} />
              </div>)}
          </div>
        </div>
      </div>
    </div>
  );
};

AppIntegration.displayName = 'CollaborationTools.AppIntegration';

const copy = {
  title: `Sentiment integrates with the tools researchers use`,
};

const items = [{
  src: slack,
}, {
  src: googlecalendar,
}, {
  className: styles.m,
  src: mscalendar,
}, {
  src: calendly,
}, {
  className: styles.l,
  src: zoom,
}, {
  className: styles.l,
  src: tableau,
}, {
  src: msoutlook,
}, {
  src: mspowerpoint,
}, {
  src: msexcel,
}];