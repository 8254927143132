import { useCallback, memo, useMemo, useEffect } from 'react';
import MicOnIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import { cx } from '@utils';
import { useProvidedDevices } from '@containers/DeviceProvider';
import { Select, SelectOptionProps } from '@/components/Select';
import styles from './style/Microphone.css';

type Props = {
  className?: string;
  value?: boolean;
  source?: string;
  disabled?: boolean;
  toggleMicrophone: (muted: boolean) => void;
  onChangeMicrophoneSource: (device: MediaDeviceInfo) => void;
};

export const Microphone = memo(({ className, value: unmuted, source, toggleMicrophone, onChangeMicrophoneSource, disabled = false }: Props) => {
  const devices = useProvidedDevices();

  useEffect(() => {
    if (devices.audioErrorState?.errMsg && unmuted) {
      toggleMicrophone(false);
    }
  }, [devices.audioErrorState, unmuted, toggleMicrophone]);

  const options = useMemo(() => {
    return devices.audioInputDevices.map(d => ({
      id: d.deviceId,
      name: d.label,
    }));
  }, [devices.audioInputDevices]);

  const selectedOption = useMemo(() => options.find(o => o.id === (source ?? 'default')), [options, source]);

  const DeviceSelector = useCallback(() => {
    if (disabled) {
      return (
        <div className={styles.optionDisabled}>
          {selectedOption?.name}
        </div>
      );
    } else if (devices.audioErrorState) {
      return (
        <div className={styles.errorMessage}>
          {devices.audioErrorState.prettyMsg}
        </div>
      );
    } else if (devices.audioLoading) {
      return (
        <div className={styles.optionDisabled}>
          Waiting for microphone permissions from browser...
        </div>
      );
    } else if (devices.audioInputDevices.length) {
      return (
        <Select
          className={styles.select}
          value={selectedOption?.name}
          getItemValue={x => x.name}
          onSelect={v => { onChangeMicrophoneSource(devices.audioInputDevices.find(d => d.deviceId === v.id)); }}
          options={options} />
      );
    } else {
      return (
        <div>No devices available. Check brower permissions.</div>
      );
    }
  }, [disabled, devices.audioInputDevices, devices.audioErrorState, onChangeMicrophoneSource, devices.audioLoading, options, selectedOption]);

  return (
    <div className={cx(className)}>
      <div className={styles.header}>
        <div>
          <span className={styles.optionLabel}>Microphone</span><span className={styles.microphoneLabel}>{unmuted ? 'Unmuted' : 'Muted'} on Join</span>
        </div>
        <div className={styles.microphoneToggle} onClick={!disabled ? () => toggleMicrophone(!unmuted) : () => { }}>
          <div className={cx(styles.microphone, { [styles.off]: !unmuted })}>
            {unmuted ? <MicOnIcon /> : <MicOffIcon />}
          </div>
        </div>
      </div>
      <div>
        <DeviceSelector />
      </div>
    </div>
  );
});