import { useMemo } from 'react';
import { useSelectGroup } from '@containers/Store/hooks/useSelectGroup';
import { useSelectUser } from '@containers/Store/hooks/useSelectUser';
import { GroupType } from '@/enums';
import { hasInternalAdminRole } from '@utils/roles';

export const useCanAddSurveyModule = () => {
  const user = useSelectUser();
  const group = useSelectGroup();

  return useMemo(() => {
    return group.typeId === GroupType.Internal || (user && hasInternalAdminRole(user));
  }, [
    group?.typeId,
    user,
  ]);
};

export default useCanAddSurveyModule;