import type { ChangeEvent, KeyboardEventHandler } from 'react';
import { useCallback, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { ChatStateDispatchContext } from '@/brand-insights/components/Chat.State';
import { shouldForwardProp } from '@/brand-insights/utils/emotion';
import { ChatSkin } from '@/brand-insights/enums';
import { useSurveyThemingPalette } from '@containers/Branding/hooks/useSurveyThemingPalette';
import { RateLimitingContext } from '@containers/RateLimiting/Context';
import { SendIcon } from './SendIcon';
import { authorWrapWidth, authorSpacing } from './Message.styles';
import {
  SubmitMessageContext,
  ChatSessionInputContext,
  UploadFilesContext,
  IsPreparingUploadContext,
} from './context';
import { UploadFilesButton, uploadButtonHeight } from './Input.Upload';

type Props = {
  className?: string;
};

export const UnsubmittedMessage = ({ className }: Props) => {
  const input = useContext(ChatSessionInputContext);
  const dispatch = useContext(ChatStateDispatchContext);

  const { canSubmit, onSubmit: handleSubmit } = useContext(SubmitMessageContext);
  const uploadFiles = useContext(UploadFilesContext);
  const isPreparingUpload = useContext(IsPreparingUploadContext);
  const { query } = useContext(RateLimitingContext);

  const disabled = useMemo(() => {
    return query.data?.chats?.exceeded || !canSubmit;
  }, [
    canSubmit,
    query,
  ]);

  const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = useCallback(e => {

    if (e.key === 'Enter') {
      e.preventDefault();
      if (e.shiftKey) {
        dispatch({
          type: 'input-changed',
          payload: {
            value: `${input}\n`,
          },
        });
      } else if (!disabled) {
        handleSubmit();
      }
    }
  }, [disabled, dispatch, handleSubmit, input]);

  const handleChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    dispatch({
      type: 'input-changed',
      payload: { value: e.target.value },
    });
  }, [dispatch]);

  const palette = useSurveyThemingPalette();

  return (
    <Root className={className}>
      <Wrap>
        <TextArea
          placeholder="Enter a message or upload a file"
          minRows={3}
          maxRows={15}
          value={input}
          onKeyDown={handleKeyDown}
          onChange={handleChange} />

        <Button
          disabled={disabled}
          onClick={handleSubmit}>
          <StyledSendIcon
            disabled={disabled}
            size={40} />
        </Button>

        <StyledUploadButton
          isLoading={isPreparingUpload}
          onSelect={uploadFiles} />
      </Wrap>
    </Root>
  );
};

const baseTextareaPadding = 10;

const submitBtnSize = 38;

const Root = styled.div(({ theme }) => ({
  width: `calc(100% - ${authorWrapWidth + authorSpacing * 2}px)`,
  margin: '0 auto',
  marginBottom: theme.skin === ChatSkin.Standalone ? undefined : 'auto',
}));

const Wrap = styled.div({
  position: 'relative',
  marginBottom: 10,
});

const TextArea = styled(TextareaAutosize)(({ theme }) => ({
  boxSizing: 'border-box',
  backgroundColor: theme.skin === ChatSkin.Standalone ? theme.palette.white.main : theme.palette.gray.light2,
  borderRadius: 5,
  border: theme.skin === ChatSkin.Standalone ? `2px solid ${theme.palette.gray.light2}` : 'none',
  outline: 'none',
  resize: 'none',
  padding: baseTextareaPadding,
  paddingRight: baseTextareaPadding + submitBtnSize,
  paddingBottom: baseTextareaPadding + uploadButtonHeight,
  width: '100%',

  fontFamily: theme.fonts.regular,
  fontSize: 16,
  color: theme.palette.black.light1,

  '&:focus-visible': {
    outline: 'none',
  },
}));

const Button = styled.button`
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};

  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: ${baseTextareaPadding}px;

  width: ${submitBtnSize}px;
  height: ${submitBtnSize}px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: background-color 0.3s;

  background-color: ${props => props.disabled ? props.theme.palette.gray.light1 : props.theme.palette.sentiment.primary.main};

  &:hover:not([disabled]) {
    background-color: ${({ theme }) => theme.palette.sentiment.primary.dark};
  }
`;

type SendProps = {
  disabled: boolean;
};

const StyledSendIcon = styled(SendIcon, { shouldForwardProp: shouldForwardProp('disabled') }) <SendProps>`
  padding-left: 4px;
  fill: ${props => props.disabled ? props.theme.palette.gray.dark1 : props.theme.palette.white.main};

  transition: fill 0.3s;
`;

const StyledUploadButton = styled(UploadFilesButton)({
  position: 'absolute',
  bottom: baseTextareaPadding,
  left: baseTextareaPadding,
});
