import { memo } from 'react';
import { cx } from '@utils';
import styles from './style/Title.css';

type Props = {
  className?: string;
  label: string;
};

export const QuotaBuilderTitle = memo(({ className, label }: Props) => {

  return (
    <div className={cx(styles.title, className)}>{label}</div>
  );
});

export default QuotaBuilderTitle;