import { cx } from '@utils';
import styles from './style/TableRow.css';

type TableRowProps = {
  children: React.ReactNode;
  className?: string;
};

export const TableRow = (props: TableRowProps) => (
  <div className={cx(styles.root, props.className)}>
    {props.children}
  </div>
);
