import { useCallback, useContext } from 'react';
import { ProjectSurveyStarredAnswersStateContext } from '@containers/SurveyResponses/Context';

const useSelectStarredProjectSurveyAnswer = (questionId: number) => {
  const context = useContext(ProjectSurveyStarredAnswersStateContext);

  const selectStarredProjectSurveyAnswer = useCallback((projectSurveyResponseId: number) => {
    return {
      onToggle: context.onToggle({
        questionId,
        projectSurveyResponseId,
      }),
      value: context.starred[questionId]?.includes(projectSurveyResponseId),
    };
  }, [
    context,
    questionId,
  ]);

  return selectStarredProjectSurveyAnswer;
};

export { useSelectStarredProjectSurveyAnswer };
export default useSelectStarredProjectSurveyAnswer;