import { useCallback, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ConferenceTagSummaryItem } from '@/types/conferences.tags';
import * as $api from '@api';
import { TagSummaryVersionsModal } from '@/components/ConferenceTag.Summary/Modal.Versions';
import { ToggleTagSumaryVersionsContext } from './context';

type Props =
  IProjectId &
  ChildrenProps;

export const ProjectSummaryVersionsContainer = ({ projectId, children }: Props) => {

  const [openItem, setOpenItem] = useState<ConferenceTagSummaryItem>();

  const tagSummaryId = openItem?.summary?.id;

  const {
    data,
    isInitialLoading: isLoading,
    isFetching,
  } = useQuery(['get:project:tag-summaries:versions', {
    projectId,
    tagSummaryId,
  }], ({ queryKey }) => {
    return $api.projects.conference.getTagSummaryVersions({
      projectId,
      tagSummaryId,
    });
  }, {
    enabled: !!tagSummaryId,
    refetchOnWindowFocus: false,
    placeholderData: {
      items: [],
    },
  });

  const handleOpen = useCallback((item: ConferenceTagSummaryItem) => {
    setOpenItem(item);
  }, []);

  const handleClose = useCallback(() => {
    setOpenItem(null);
  }, []);

  const open = !!tagSummaryId;

  return (
    <ToggleTagSumaryVersionsContext.Provider value={handleOpen}>
      {children}
      {open && (
        <TagSummaryVersionsModal
          open={open}
          onClose={handleClose}
          versions={data.items}
          summaryItem={openItem} />
      )}
    </ToggleTagSumaryVersionsContext.Provider>
  );
};