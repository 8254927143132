import { X } from 'react-feather';
import { forwardRef } from 'react';
import { ButtonOutlined } from '@presentation/ButtonOutlined';
import styles from './style/Link.Popper.css';

type Props = {
  href: string;
  text: string;
  onCancel: () => void;
  onClickEdit: () => void;
  onRemove: () => void;
};

export const LinkExtensionPopper = forwardRef<HTMLDivElement, Props>(({
  href,
  text,
  onCancel,
  onClickEdit,
  onRemove,
}, ref) => {

  return (
    <div ref={ref} className={styles.root}>
      <div className={styles.box}>
        <div className={styles.row}>
          <div className={styles.display}>{text}</div>
          <div className={styles.link}>
            <a
              href={href}
              rel="noreferrer"
              target="_blank">
              {href}
            </a>
          </div>
        </div>
        {/* <div className={styles.row}>
          <div
            className={styles.close}
            onClick={onCancel}>
            <X />
          </div>
        </div> */}
      </div>
      <div className={styles.btns}>
        <ButtonOutlined
          borderWidth="thin"
          className={styles.btn}
          color="silver"
          onClick={onClickEdit}>
          Edit
        </ButtonOutlined>
        <ButtonOutlined
          borderWidth="thin"
          className={styles.btn}
          color="red"
          onClick={onRemove}>
          Remove
        </ButtonOutlined>
      </div>
    </div>
  );
});