export function setSession(conferenceIdentifier: number, sessionId: string) {
  try {
    sessionStorage.setItem(getKey(conferenceIdentifier), sessionId);
  } catch (err) {
    console.error(err);
  }
}

export function getSession(conferenceIdentifier: number) {
  return sessionStorage.getItem(getKey(conferenceIdentifier));
}

export function clearSession(conferenceIdentifier: number) {
  sessionStorage.removeItem(getKey(conferenceIdentifier));
}

const getKey = (conferenceIdentifier: number) => `vncy:conference:${conferenceIdentifier}:sid`;