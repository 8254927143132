import { useCallback, useEffect, useState } from 'react';
import type { Chat } from '@/brand-insights/types';
import {
  AssignCitationRefContext,
  OpenSnippetContext,
  ClickCitationContext,
  MessageCitationsToggleContext,
  CitationsContext,
} from './context';

type Props = {
  className?: string;
  citations: Chat.Citation[];
} & ChildrenProps;

type RefMap = {
  [citationKey: string]: HTMLDivElement;
};

export const SystemMessageContainer = (props: Props) => {
  const [selectedSnippet, setSelectedSnippet] = useState<[string, string]>();
  const [citationsOpen, setCitationsOpen] = useState<boolean>(false);
  const [citationRefMap, setCitationRefMap] = useState<RefMap>({});

  const [pendingCitationToScrollTo, setPendingCitationToScrollTo] = useState<string>();

  const assignCitationRef = useCallback((citationKey: string, el: HTMLDivElement) => {
    setCitationRefMap(c => ({
      ...c,
      [citationKey]: el,
    }));
  }, []);

  const scrollToCitation = useCallback((citationKey: string) => {
    const ref = citationRefMap[citationKey];
    if (!ref) return false;
    ref.scrollIntoView({ behavior: 'smooth' });
    return true;
  }, [citationRefMap]);

  // When tab isn't open and citation is clicked, we need to wait for the ref to be available
  useEffect(() => {
    if (pendingCitationToScrollTo) {
      const scrolled = scrollToCitation(pendingCitationToScrollTo);
      if (scrolled) {
        setPendingCitationToScrollTo(undefined);
      }
    }
  }, [pendingCitationToScrollTo, scrollToCitation]);

  const toggleCitationsOpen = useCallback(() => {
    setCitationsOpen(o => !o);
    setCitationRefMap({});
  }, []);

  const handleSnippetSelection = useCallback((selected: [string, string]) => {
    setSelectedSnippet(s => {
      if (!s) return selected;
      if (s[0] === selected[0] && s[1] === selected[1]) return null;
      return selected;
    });
  }, []);

  const handleCitationClick = useCallback((value: string) => {
    const snippetCharacter = value.slice(-1);
    const citationStr = value.slice(0, -1);

    const citationOrdinal = parseInt(citationStr);
    const snippetOrdinal = snippetCharacter.charCodeAt(0) - 96;

    const citation = props.citations.find(f => f.ordinal === citationOrdinal);

    if (!citation) return console.error('Citation not found', citationOrdinal, snippetOrdinal);

    const snippet = citation.snippets?.find(f => f.ordinal === snippetOrdinal);

    if (!snippet) return console.error('Snippet not found', citationOrdinal, snippetOrdinal);

    const citationKey = buildCitationKey(citation);

    setCitationsOpen(true);
    setSelectedSnippet([citationKey, snippet?.id]);
    setPendingCitationToScrollTo(citationKey);
  }, [props.citations]);

  return (
    <AssignCitationRefContext.Provider value={assignCitationRef}>
      <MessageCitationsToggleContext.Provider value={[citationsOpen, toggleCitationsOpen]}>
        <OpenSnippetContext.Provider value={[selectedSnippet, handleSnippetSelection]}>
          <ClickCitationContext.Provider value={handleCitationClick}>
            <CitationsContext.Provider value={props.citations}>
              {props.children}
            </CitationsContext.Provider>
          </ClickCitationContext.Provider>
        </OpenSnippetContext.Provider>
      </MessageCitationsToggleContext.Provider>
    </AssignCitationRefContext.Provider>
  );
};

function buildCitationKey(item: Chat.Citation) {
  return `${item.type}-${item.id}`;
}