import type {
  ApplySchemaAttributes,
  EditorView,
  NodeExtensionSpec,
  NodeSpecOverride,
  NodeViewMethod,
  ProsemirrorNode,
  RemirrorJSON } from '@remirror/core';
import {
  command,
  helper,
  Helper,
  CommandFunction,
  DelayedPromiseCreator,
  ErrorConstant,
  extension,
  ExtensionTag,
  getTextSelection,
  invariant,
  isElementDomNode,
  NodeExtension,
  omitExtraAttributes,
  PrimitiveSelection,
} from '@remirror/core';
import { insertPoint } from '@remirror/pm/transform';
import { ExtensionImageTheme } from '@remirror/theme';
import type { ImageExtensionAttributes } from '@/types/rich-text';
import { ResizableImageView } from './Extension.Image.ResizableImageView';
import type { Base64String, URLString, ImageObject } from './Extension.Image.ImageState';
import { ImageState } from './Extension.Image.ImageState';
import type { DisplayOptions } from './Extension.Image.DisplayView';
import { DisplayView } from './Extension.Image.DisplayView';
import * as Img from './interfaces.image';

const defaultOptions: ImageOptions = {
  createPlaceholder,
  updatePlaceholder: () => { },
  destroyPlaceholder: () => { },
  uploadHandler,
  enableResizing: false,
  onInsert: () => { },
  displayOptions: {},
};

@extension<ImageOptions>({
  defaultOptions,
})
export class ImageExtension extends NodeExtension<ImageOptions> {
  get name() {
    return 'image' as const;
  }

  createTags() {
    return [ExtensionTag.InlineNode, ExtensionTag.Media];
  }

  instance = new ImageState();

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    return {
      inline: true,
      draggable: true,
      selectable: false,
      ...override,
      attrs: {
        ...extra.defaults(),
        alt: { default: '' },
        cid: { default: null },
        crop: { default: null },
        height: { default: null },
        width: { default: null },
        rotate: { default: null },
        src: { default: null },
        title: { default: '' },
        fileName: { default: null },

        resizable: { default: false },
      },
      parseDOM: [
        {
          tag: 'img[src]',
          getAttrs: element => {
            return isElementDomNode(element)
              ? getImageAttributes({ element, parse: extra.parse })
              : {};
          },
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM: node => {
        const attrs = omitExtraAttributes(node.attrs, extra);
        return ['img', {
          ...extra.dom(node),
          ...attrs,
          'data-cid': node.attrs.cid as string,
        }];
      },
    };
  }

  @helper()
  createImageURLLookup(targets: ImageObject<Base64String>[], sources: ImageObject<URLString>[]): Helper<Record<string, URLString>> {
    const lookup = targets.reduce((acc, target) => {
      const source = sources.find(x => x.cid === target.cid);

      return {
        ...acc,
        [target.cid]: source.src,
      };
    }, {} as Record<string, URLString>);

    return lookup;
  }

  @helper()
  findImageObjects(): Helper<ImageObject[]> {
    const storestate = this.store.getState();
    const state = this.instance;

    return state.findRenderedImages(storestate);
  }

  @helper()
  replaceImageSrc(lookup: Record<string, URLString>): Helper<RemirrorJSON> {
    const storestate = this.store.getState();
    const state = this.instance;

    return state.replaceImageSrc(storestate, lookup);
  }

  @command()
  insertImage(attributes: Img.ImageAttributes, selection?: PrimitiveSelection): CommandFunction {
    return ({ tr, dispatch }) => {
      const { from, to } = getTextSelection(selection ?? tr.selection, tr.doc);
      const node = this.type.create(attributes);

      dispatch?.(tr.replaceRangeWith(from, to, node));

      return true;
    };
  }

  /**
   * Insert an image once the provide promise resolves.
   */
  @command()
  uploadImage(
    value: DelayedPromiseCreator<Img.ImageAttributes>,
    onElement?: (element: HTMLElement) => void,
  ): CommandFunction {
    const { updatePlaceholder, destroyPlaceholder, createPlaceholder } = this.options;
    return props => {
      const { tr } = props;

      let pos = tr.selection.from;

      return this.store
        .createPlaceholderCommand({
          promise: value,
          placeholder: {
            type: 'widget',
            get pos() {
              return pos;
            },
            createElement: (view, pos) => {
              const element = createPlaceholder(view, pos);
              onElement?.(element);
              return element;
            },
            onUpdate: (view, pos, element, data: number) => {
              updatePlaceholder(view, pos, element, data);
            },
            onDestroy: (view, element) => {
              destroyPlaceholder(view, element);
            },
          },
          onSuccess: (value, range, commandProps) => {
            const state = this.instance;
            const overrides = state.cacheImage(value);
            const attributes = overrides || value;
            return this.insertImage(attributes, range)(commandProps);
          },
        })
        .validate(({ tr, dispatch }) => {
          const insertPos = insertPoint(tr.doc, pos, this.type);

          if (insertPos == null) {
            return false;
          }

          pos = insertPos;

          if (!tr.selection.empty) {
            dispatch?.(tr.deleteSelection());
          }

          return true;
        }, 'unshift')

        .generateCommand()(props);
    };
  }

  private fileUploadFileHandler(files: File[]) {
    const { commands, chain } = this.store;
    const filesWithProgress: FileWithProgress[] = files.map((file, index) => ({
      file,
      progress: progress => {
        commands.updatePlaceholder(uploads[index], progress);
      },
    }));

    const uploads = this.options.uploadHandler(filesWithProgress);

    for (const upload of uploads) {
      chain.uploadImage(upload);
    }

    chain.run();

    return true;
  }

  // createPasteRules(): PasteRule[] {
  //   return [
  //     {
  //       type: 'file',
  //       regexp: /image/i,
  //       fileHandler: ({ files }) => this.fileUploadFileHandler(files),
  //     },
  //   ];
  // }

  createNodeViews(): NodeViewMethod | Record<string, NodeViewMethod> {
    return (node: ProsemirrorNode, view: EditorView, getPos: boolean | (() => number)) => {

      if (!view.editable || !this.options.enableResizing) return new DisplayView(node, view, getPos as () => number, this.options.displayOptions);

      const { cid } = this.instance.cacheImage(node.attrs as ImageExtensionAttributes);

      Object.assign(node.attrs, { cid });

      return new ResizableImageView(node, view, getPos as () => number);
    };
  }
}

/**
 * The set of valid image files.
 */
const IMAGE_FILE_TYPES = new Set([
  'image/jpeg',
  'image/gif',
  'image/png',
  'image/jpg',
  'image/svg',
  'image/webp',
]);

/**
 * True when the provided file is an image file.
 */
function isImageFileType(file: File): boolean {
  return IMAGE_FILE_TYPES.has(file.type);
}

/**
 * Get the width and the height of the image.
 */
function getDimensions(element: HTMLElement) {
  let { width, height } = element.style;
  width = width || element.getAttribute('width') || '';
  height = height || element.getAttribute('height') || '';

  return { width, height };
}

/**
 * Retrieve attributes from the dom for the image extension.
 */
function getImageAttributes({
  element,
  parse,
}: {
  element: HTMLElement;
  parse: ApplySchemaAttributes['parse'];
}) {
  const { width, height } = getDimensions(element);

  return {
    ...parse(element),
    alt: element.getAttribute('alt') ?? '',
    height: Number.parseInt(height || '0', 10) || null,
    src: element.getAttribute('src') ?? null,
    title: element.getAttribute('title') ?? '',
    width: Number.parseInt(width || '0', 10) || null,
    fileName: element.getAttribute('data-file-name') ?? null,
  };
}

function createPlaceholder(_: EditorView, __: number): HTMLElement {
  const element = document.createElement('div');
  element.classList.add(ExtensionImageTheme.IMAGE_LOADER);

  return element;
}

/**
 * The default handler converts the files into their `base64` representations
 * and adds the attributes before inserting them into the editor.
 */
function uploadHandler(files: FileWithProgress[]): DelayedImage[] {
  invariant(files.length > 0, {
    code: ErrorConstant.EXTENSION,
    message: 'The upload handler was applied for the image extension without any valid files',
  });

  let completed = 0;
  const promises: Array<DelayedPromiseCreator<Img.ImageAttributes>> = [];

  for (const { file, progress } of files) {
    promises.push(
      () =>
        new Promise<Img.ImageAttributes>(resolve => {
          const reader = new FileReader();

          reader.addEventListener(
            'load',
            readerEvent => {
              completed += 1;
              progress(completed / files.length);
              resolve({ src: readerEvent.target?.result as string, fileName: file.name });
            },
            { once: true },
          );

          reader.readAsDataURL(file);
        }),
    );
  }

  return promises;
}

type DelayedImage = DelayedPromiseCreator<Img.ImageAttributes>;

export type ImageOptions = {
  createPlaceholder?: (view: EditorView, pos: number) => HTMLElement;
  updatePlaceholder?: (
    view: EditorView,
    pos: number,
    element: HTMLElement,
    progress: number,
  ) => void;
  destroyPlaceholder?: (view: EditorView, element: HTMLElement) => void;

  /**
   * The upload handler for the image extension.
   *
   * It receives a list of dropped or pasted files and returns a promise for the
   * attributes which should be used to insert the image into the editor.
   *
   * @param files - a list of files to upload.
   * @param setProgress - the progress handler.
   */
  uploadHandler?: (files: FileWithProgress[]) => DelayedImage[];

  /**
   * Enable resizing.
   *
   * If true, the image node will be rendered by `nodeView` instead of `toDOM`.
   *
   * @default false
   */
  enableResizing: boolean;
  onInsert?: <T extends Img.ImageAttributes>(data: T) => T;
  displayOptions?: DisplayOptions;
};

type FileWithProgress = {
  file: File;
  progress: SetProgress;
};

/**
 * Set the progress.
 *
 * @param progress - a value between `0` and `1`.
 */
type SetProgress = (progress: number) => void;