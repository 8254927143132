import { useCallback } from 'react';
import { useRepeatSurveyResponsesState } from '@containers/RepeatSurveyResponses/hooks/useRepeatSurveyResponsesState';
import OptionsAggregateData from './OptionsAggregateData';
import OptionsVersionData from './OptionsVersionData';
import styles from './style/Data.css';

export const OptionsData = () => {
  const state = useRepeatSurveyResponsesState();

  const renderContent = useCallback(() => {
    if (state.query.projectId) {
      return (
        <OptionsVersionData />
      );
    } else {
      return (
        <OptionsAggregateData />
      );
    }
  }, [
    state.query.projectId,
  ]);

  return (
    <div className={styles.root}>
      {renderContent()}
    </div>
  );
};

export default OptionsData;