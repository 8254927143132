import { useContext } from 'react';
import { TranscriptType } from '@/enums';
import { DownloadConferenceTagClipContainer } from './Call.DownloadClip';
import { DownloadTranscriptTaggedMomentContainer } from './Transcript.DownloadTaggedMoment';
import { CallTranscriptDetailsContext, TranscriptIdContext, TranscriptTypeContext } from './context';

type Props = {
  momentId: number;
  tag: { name: string };
} & ChildrenProps;

export const TaggedMomentDownloadContainer = (props: Props) => {
  const transcriptType = useContext(TranscriptTypeContext);
  const transcriptId = useContext(TranscriptIdContext);
  const callDetails = useContext(CallTranscriptDetailsContext);

  if (transcriptType === TranscriptType.VideoCall) {
    return (
      <DownloadConferenceTagClipContainer
        tag={props.tag}
        callId={callDetails.call.id}
        momentId={props.momentId}>
        {props.children}
      </DownloadConferenceTagClipContainer>
    );
  } else if (transcriptType === TranscriptType.WorkspaceFile) {
    return (
      <DownloadTranscriptTaggedMomentContainer
        tag={props.tag}
        momentId={props.momentId}
        transcriptId={transcriptId}>
        {props.children}
      </DownloadTranscriptTaggedMomentContainer>
    );
  }

  return (
    <>
      {props.children}
    </>
  );

};