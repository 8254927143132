import { useContext } from 'react';
import { SignupSubmitContext } from '../Context';

type Params = unknown;

const useSignupSubmit = () => {
  const [response, submit] = useContext(SignupSubmitContext);

  return [response, submit] as const;
};

export { useSignupSubmit };
export default useSignupSubmit;