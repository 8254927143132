import { useCallback, useContext } from 'react';
import { VideoFileContext } from './Video.Context';
import styles from './style/Video.UploadVideo.VideoFileInput.css';

type Props = unknown;

export const VideoFileInput = (props: Props) => {
  const [_, setFile] = useContext(VideoFileContext);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const [data] = Array.from(e.target.files);

    setFile(data);
  }, [setFile]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.label}>
            Upload video file
          </div>
          <input
            className={styles.input}
            onChange={handleChange}
            accept=".mov,.mp4,.m4v"
            type="file" />
        </div>
      </div>
    </div>
  );
};

VideoFileInput.displayName = 'PostCreation.Video.UploadVideo.VideoFileInput';