import { useMemo } from 'react';
import { ProjectSurveyBuilderState } from '@containers/SurveyBuilder';
import { SurveyType } from '@/enums';
import { LinearProgress } from '@/components/Progress/LinearProgress';
import { ProjectCreationFormContainer } from './FormContainer';
import { FormError } from './FormErrorContainer';
import { ProjectCreationNavigationContainer } from './NavigationContainer';
import { ProjectCreationSidebar } from './Sidebar';
import { useProjectCreationNavigation, useProjectFormContext } from './context';
import { ScreenRefInjector } from './ScreenRefInjector';
import { useProjectCreationSteps } from './hooks';
import styles from './style/style.old.css';

const ProjectCreation = () => {

  const { index } = useProjectCreationNavigation();
  const steps = useProjectCreationSteps();

  const progress = useMemo(() => {
    return ((index + 1) / (steps.length + 1)) * 100;
  }, [index, steps.length]);

  return (
    <div className={styles.root}>
      <div className={styles.bar}>
        <LinearProgress value={progress} />
      </div>
      <div className={styles.sidebar}>
        <ProjectCreationSidebar />
      </div>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.content}>
            <ScreenRefInjector screens={steps} />
          </div>
        </div>
      </div>
    </div>
  );
};

const ProjectCreationWrapper = () => {
  return (
    <ProjectCreationFormContainer>
      <SurveyState>
        <ProjectCreationNavigationContainer>
          <FormError>
            <ProjectCreation />
          </FormError>
        </ProjectCreationNavigationContainer>
      </SurveyState>
    </ProjectCreationFormContainer>
  );
};

const SurveyState = (props: ChildrenProps) => {
  const [form] = useProjectFormContext();

  return (
    <ProjectSurveyBuilderState projectType={form.projectType} excludeSurveyTypes={form.surveys.filter(s => s.typeId === SurveyType.Project).map(s => s.typeId)}>
      {props.children}
    </ProjectSurveyBuilderState>
  );
};

export { ProjectCreationWrapper as ProjectCreation };
export default ProjectCreationWrapper;