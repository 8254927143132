import type { ThemeOptions } from '@mui/material/styles';
import type { Theme as LocalTheme } from '@/brand-insights/types/theme';
import { ChatSkin } from '@/brand-insights/enums';

const colors: LocalTheme.PaletteColors = {
  black: {
    light3: '#989898',
    light2: '#7a7a7a',
    light1: '#515151',
    main: '#323232',
    dark1: '#2a2a2a',
    dark2: '#191919',
    dark3: '#000000',
  },

  blue: {
    light3: '#f8fafe',
    light2: '#E5F5FC',
    light1: '#76d1ff',
    main: '#28aae1',
    dark1: '#1D94C7',
    dark2: '#6288aa',
  },
  gray: {
    light6: '#cfd5e1',
    light5: '#E5E9F2',
    light4: '#e3e3e3',
    light3: '#fafafa',
    light2: '#f2f2f2',
    light1: '#d3d3d3',
    main: '#adadad',
    dark1: '#626262',
  },
  green: {
    light3: '#d5f2f1',
    light2: '#bcd9d8',
    light1: '#37c630',
    main: '#2da127',
    dark1: '#268921',
    dark2: '#2f8b89',
  },
  purple: {
    light8: '#5C73AD',
    light7: '#f4f3ff',
    light6: '#e1e0f2',
    light5: '#d3d4f0',
    light4: '#c5c6f0',
    light3: '#b3b5e8',
    light2: '#7b7bc7',
    light1: '#575685',
    main: '#565add',
    dark1: '#3035d5',
    dark2: '#2125a6',
    dark3: '#1a1d80',
  },
  red: {
    main: '#E12E40',
    secondary: '#FFD6D7',
  },
  white: {
    main: '#ffffff',
  },
  hyperlink: '#1271c4',
};

export const palette: Theme['palette'] = {
  ...colors,

  sentiment: {
    primary: {
      light: colors.blue.light2,
      lighter: colors.blue.light3,
      main: colors.blue.main,
      dark: colors.blue.dark1,
    },
  },

  primary: {
    light6: colors.purple.light8,
    light5: colors.purple.light7,
    light4: colors.purple.light6,
    light3: colors.purple.light5,
    light2: colors.purple.light4,
    light1: colors.purple.light3,
    main: colors.purple.main,
    dark1: colors.purple.dark1,
  },

  secondary: {
    main: colors.white.main,
  },

  text: {
    primary: colors.black.light1,
  },

  btnPrimary: {
    color: colors.purple.main,
    bg: colors.purple.light4,
    bg1: colors.gray.light4,
    border: colors.purple.light4,
    active: {
      color: colors.purple.main,
      bg: colors.purple.light3,
      border: colors.purple.light3,
    },
    disabled: {
      color: colors.gray.light2,
      bg: colors.gray.light1,
      border: colors.gray.light1,
    },
  },

  btnSecondary: {
    color: colors.purple.main,
    bg: colors.white.main,
    border: colors.purple.light4,
    active: {
      color: colors.purple.main,
      bg: colors.white.main,
      border: colors.purple.main,
    },
    disabled: {
      color: colors.gray.light2,
      bg: colors.gray.light1,
      border: colors.gray.light1,
    },
  },
};

export const fonts: Theme['fonts'] = {
  regular: 'var(--font-reg)',
  semiBold: 'var(--font-semibold)',
  bold: 'var(--font-bold)',
};

export const zIndex: Theme['zIndex'] = {
  baseModal: 1300,
  modal: 1302,
  popper: 1301,
  widget: 10,
};

export const breakpoints: Theme['breakpoints'] = {
  values: {
    confirmationDialog: 550,
    navbarWidth: 1280,
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
};

const components = {
  MuiButton: {
    styleOverrides: {
      text: {
        fontFamily: 'var(--font-semibold)',
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        '--TextField-insightsBorderColor': palette.black.light2,
        '--TextField-insightsBorderHoverColor': palette.primary.light1,
        '--TextField-insightsBorderFocusedColor': palette.primary.main,
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      root: {
        '&:before': {
          borderBottom: '2px solid var(--TextField-insightsBorderColor)',
        },
        '&:hover:not(.Mui-disabled, .Mui-error):before': {
          borderBottom: `2px solid var(--TextField-insightsBorderHoverColor)`,
        },
        '&.Mui-focused:after': {
          borderBottom: '2px solid var(--TextField-insightsBorderFocusedColor)',
        },
      },
    },
  },
};

export const theme: ThemeOptions = {
  breakpoints,
  components,
  fonts,
  palette,
  skin: ChatSkin.Sentiment,
  zIndex,
};