import { useCallback, useEffect, useState } from 'react';
import { usePrevious } from '@utils';
import type { Group } from '@/types';
import { AutofillClientAccountContext } from './Context';

type AccountItem =
  & Descriptor
  & Pick<Group['attributes'], 'code'>;

type ClientAccountAutofillContainerProps = {
  children: React.ReactNode;
  code: string;
  accountId: number;
  accounts: AccountItem[];
  onAutofill: (account: AccountItem) => unknown;
};

export const AutofillClientAccountContainer = ({ accountId, accounts, code, onAutofill, ...props }: ClientAccountAutofillContainerProps) => {
  const [account, setAccount] = useState<AccountItem>(null);
  const previous = usePrevious(code);

  const autofill = useCallback(() => {
    const match = accounts.find(x => {
      if (typeof x.code !== 'string') return false;

      const [prefix] = code.split('-');

      return prefix?.toLowerCase?.() === x.code?.toLowerCase?.();
    });

    if (match) {
      onAutofill(match);
      setAccount(match);
    }
  }, [
    accounts,
    code,
    onAutofill,
  ]);

  useEffect(() => {
    if (!accountId && code && code !== previous) {
      autofill();
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    code,
    accountId,
    previous,
  ]);

  useEffect(() => {
    if (account && account?.id !== accountId) {
      setAccount(null);
    }
  }, [
    accountId,
    account,
  ]);

  return (
    <AutofillClientAccountContext.Provider value={{ autofilled: !!account }}>
      {props.children}
    </AutofillClientAccountContext.Provider>
  );
};

AutofillClientAccountContainer.displayName = 'Project.Creation.Autofill.ClientAccount.Container';