import { ObjectAccessUser } from '@/types';
import { WorkspaceObjectRole } from '@enums';


export function sortObjectAccess(a: ObjectAccessUser, b: ObjectAccessUser) {
  const isOwnerA = a.roleId === WorkspaceObjectRole.Owner;
  const isOwnerB = b.roleId === WorkspaceObjectRole.Owner;
  return (isOwnerA === isOwnerB)
    ? 0
    : isOwnerA
      ? -1
      : 1
  ;
}