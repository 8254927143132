import { useContext } from 'react';
import { ProjectClipsDataContext, ProjectClipsContainer } from '@/containers/Project.Clips';
import { useProjectOrLatestChild } from '@/containers/GroupProject/hooks';
import * as Table from '@/components/Table';
import { ClipsTable } from './Clips.Table';
import { Actions } from './Actions';
import { KeywordSearch } from './KeywordSearch';
import { AdditionalFilters } from './AdditionalFilters';
import styles from './style/Project.Clips.css';

const ProjectClips = () => {
  const project = useProjectOrLatestChild();
  const suspended = useContext(ProjectClipsDataContext);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Table.Layout.Box>
          <Table.Layout.Header title="Highlights">
            <div className={styles.search}>
              <KeywordSearch />
              <AdditionalFilters />
            </div>
            <Actions projectId={project.id} />
          </Table.Layout.Header>

          <ClipsTable
            data={suspended.data}
            empty={suspended.empty}
            loading={suspended.loading} />
        </Table.Layout.Box>
      </div>
    </div>
  );
};

const WithState = () => {
  return (
    <ProjectClipsContainer>
      <ProjectClips />
    </ProjectClipsContainer>
  );
};

export { WithState as ProjectClips };

ProjectClips.displayName = 'Project.Clips';
