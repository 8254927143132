import styled from '@emotion/styled';
import { shouldForwardProp } from '@/utils/emotion';

type Props = {
  className?: string;
  gridTemplateColumns?: string;
} & ChildrenProps;

export function Grid({ className, children, gridTemplateColumns = 'repeat(2, 0.5fr)' }: Props) {
  return (
    <Root className={className} gridTemplateColumns={gridTemplateColumns}>
      {children}
    </Root>
  );
}

type RootProps = {
  gridTemplateColumns: string;
};

const Root = styled('div', { shouldForwardProp: shouldForwardProp('gridTemplateColumns') })<RootProps>`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: ${props => props.gridTemplateColumns};
  grid-gap: 20px;
`;