import { useMemo } from 'react';
import styled from '@emotion/styled';
import type { Chat } from '@/brand-insights/types';
import { ChatHintType } from '@/brand-insights/enums';
import { useIsFullPageChat } from '@/brand-insights/components/Chat/hooks';
import * as Presentation from './presentation';

type Props = {
  className?: string;
  hint: Chat.QueryHint.Item;
  onClick: () => unknown;
  disabled?: boolean;
};

export const MessageQueryHint = (props: Props) => {
  switch (props.hint.type) {
    case ChatHintType.FindQuotes: return <AnalysisQueryHint {...props} />;
    case ChatHintType.AnalysisTags: return <AnalysisQueryHint {...props} />;
    case ChatHintType.Text: return <TextQueryHint {...props} />;
    default: throw new UnreachableCaseError(props.hint.type);
  }
};

export const TextQueryHint = ({ className, hint, disabled, onClick }: Props) => {
  const isFullPageChat = useIsFullPageChat();
  return (
    <Presentation.Root className={className} onClick={disabled ? undefined : onClick}>
      <TextWrap isDisabled={disabled} isFullPageChat={isFullPageChat}>
        <TextIcon isDisabled={disabled} /> {hint.displayValue}
      </TextWrap>
    </Presentation.Root>
  );
};

export const AnalysisQueryHint = ({ className, hint, disabled, onClick }: Props) => {
  const isFullPageChat = useIsFullPageChat();
  return (
    <Presentation.Root className={className} onClick={disabled ? undefined : onClick}>
      <AnalysisWrap isDisabled={disabled} isFullPageChat={isFullPageChat}>
        <AnalysisIcon isDisabled={disabled} /> {hint.displayValue}
      </AnalysisWrap>
    </Presentation.Root>
  );
};

const TextWrap = styled(Presentation.Wrap)(({ isDisabled, theme }) => {
  const { Text } = useMemo(() => Presentation.Colors(theme), [theme]);

  const enabled = Text.enabled;
  const disabled = Text.disabled;

  return {
    backgroundColor: `${isDisabled ? disabled.backgroundColor : enabled.backgroundColor}`,
    '&:hover': {
      backgroundColor: `${isDisabled ? disabled.hover.backgroundColor : enabled.hover.backgroundColor}`,
    },
  };
});

const TextIcon = styled(Presentation.TextStyledIcon)(({ isDisabled, theme }) => {
  const { TextIcon } = useMemo(() => Presentation.Colors(theme), [theme]);

  const enabled = TextIcon.enabled;
  const disabled = TextIcon.disabled;

  return {
    color: `${isDisabled ? disabled.color : enabled.color}`,
  };
});

const AnalysisWrap = styled(Presentation.Wrap)(({ isDisabled, theme }) => {
  const { Analysis } = useMemo(() => Presentation.Colors(theme), [theme]);

  const enabled = Analysis.enabled;
  const disabled = Analysis.disabled;

  return {
    backgroundColor: `${isDisabled ? disabled.backgroundColor : enabled.backgroundColor}`,
    '&:hover': {
      backgroundColor: `${isDisabled ? disabled.hover.backgroundColor : enabled.hover.backgroundColor}`,
    },
  };
});

const AnalysisIcon = styled(Presentation.AnalysisStyledIcon)(({ isDisabled, theme }) => {
  const { AnalysisIcon } = useMemo(() => Presentation.Colors(theme), [theme]);

  const enabled = AnalysisIcon.enabled;
  const disabled = AnalysisIcon.disabled;

  return {
    color: `${isDisabled ? disabled.color : enabled.color}`,
  };
});