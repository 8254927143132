import { SurveyConditionsMatchValidationsContext } from '@containers/SurveyBuilder.Logic';
import {
  SurveyTaggingItemValidationsContext,
  RunSurveyTaggingValidationsContext,
} from './context';
import { useRunSurveyTaggingValidations } from './hooks';

type Props = ChildrenProps;

export const SurveyTaggingItemValidationContainer = (props: Props) => {

  const {
    validations,
    matchValidation,
    runValidations,
  } = useRunSurveyTaggingValidations();

  return (
    <SurveyTaggingItemValidationsContext.Provider value={validations}>
      <SurveyConditionsMatchValidationsContext.Provider value={matchValidation}>
        <RunSurveyTaggingValidationsContext.Provider value={runValidations}>
          {props.children}
        </RunSurveyTaggingValidationsContext.Provider>
      </SurveyConditionsMatchValidationsContext.Provider>
    </SurveyTaggingItemValidationsContext.Provider>
  );
};
