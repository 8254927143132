import { memo } from 'react';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { cx } from '@utils';
import { Item } from './interfaces';
import styles from './style/Item.css';

export const MarkActive = memo(({ title = 'Mark Active', onClick }: Item.Props) => {
  return (
    <div
      className={cx(styles.root, styles.disabled)}
      onClick={onClick}>
      <CheckCircle
        className={styles.icon} />
      {title}
    </div>
  );
});