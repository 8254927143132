import { ConditionsBuilder, SurveyMatchBuilder } from './interfaces';
import { getDefaultCondition } from './utils';
import * as $classificationCondition from './reducer.condition.classification';
import * as $questionCondition from './reducer.condition.question';
import * as $sumCondition from './reducer.condition.sum';

type State = ConditionsBuilder.State;

export function surveyConditionsReducer(state: State, action: SurveyMatchBuilder.Action): State {
  switch (action.type) {

    case 'condition-added':
      return conditionAdded(state, action);

    case 'condition-removed':
      return conditionRemoved(state, action);

    // Question Condition Type
    case 'condition-question-updated':
      return $questionCondition.questionUpdated(state, action);

    case 'question-condition-value-updated':
      return $questionCondition.valueUpdated(state, action);

    case 'question-condition-type-updated':
      return $questionCondition.typeUpdated(state, action);

    // Sum Condition Type
    case 'sum-condition-comparate-updated':
      return $sumCondition.sumComparateUpdated(state, action);

    case 'sum-condition-operator-updated':
      return $sumCondition.sumOperatorUpdated(state, action);

    case 'sum-condition-items-updated':
      return $sumCondition.sumItemsUpdated(state, action);

    // Classification Condition
    case 'classification-updated':
      return $classificationCondition.classificationUpdated(state, action);

    default:
      return state;
  }
}

export function conditionAdded(state: State, action: ConditionsBuilder.ConditionAdded.Action): State {
  return state.concat(getDefaultCondition({
    conditionType: action.payload.conditionType,
  }));
}

export function conditionRemoved(state: State, { payload }: ConditionsBuilder.ConditionRemoved.Action): State {
  return state.filter(f => f.identifier !== payload.identifier);
}