import { useCallback, useMemo } from 'react';
import { SurveyQuestionType } from '@enums';
import { RespondentAnswer, SurveyFormQuestion } from '@/types';
import { ConjointAnalysisFormContainer } from './ConjointAnalysis';
import { ExclusiveOptionsFormContainer } from './ExclusiveOptions';
import { ImageMarkupFormContainer } from './ImageMarkup';
import { LongTextResponseFormContainer } from './LongTextResponse';
import { MatrixSliderFormContainer } from './MatrixSlider';
import { MaxDiffFormContainer } from './MaxDiff';
import { MultiselectFormContainer } from './Multiselect';
import { MatrixMultiselectFormContainer } from './MatrixMultiselect';
import { NumberInputTableFormContainer } from './NumberInputTable';
import { MultiTextboxFormContainer } from './MultiTextboxes';
import { useSurveyFormQuestionContext } from './hooks';
import { ShortTextResponseFormContainer } from './ShortTextResponse';
import { MultipleChoiceFormContainer } from './MultipleChoice';
import { MatrixGridFormContainer } from './MatrixGrid';
import { RankingFormContainer } from './Ranking';
import { DropdownFormContainer } from './Dropdown';
import { SurveyFormQuestionAnswerContext, SurveyFormAnswerRationaleContext, useSurveyFormScreenContext } from './Context';
import { MatrixRangeFormContainer } from './MatrixRange';

type Props =
  ChildrenProps;

function getQuestionContainer(question: Pick<SurveyFormQuestion, 'typeId'>) {
  switch (question.typeId) {
    case SurveyQuestionType.ConjointAnalysis:
      return ConjointAnalysisFormContainer;

    case SurveyQuestionType.LongTextResponse:
      return LongTextResponseFormContainer;

    case SurveyQuestionType.MaxDifference:
      return MaxDiffFormContainer;

    case SurveyQuestionType.Sliders:
      return MatrixSliderFormContainer;

    case SurveyQuestionType.Multiselect:
      return MultiselectFormContainer;

    case SurveyQuestionType.MatrixMultiselect:
      return MatrixMultiselectFormContainer;

    case SurveyQuestionType.NumberInputTable:
      return NumberInputTableFormContainer;

    case SurveyQuestionType.MultiTextbox:
      return MultiTextboxFormContainer;

    case SurveyQuestionType.ShortTextResponse:
      return ShortTextResponseFormContainer;

    case SurveyQuestionType.MultipleChoice:
      return MultipleChoiceFormContainer;

    case SurveyQuestionType.MatrixGrid:
      return MatrixGridFormContainer;

    case SurveyQuestionType.Ranking:
      return RankingFormContainer;

    case SurveyQuestionType.ExclusiveOptions:
      return ExclusiveOptionsFormContainer;

    case SurveyQuestionType.Dropdown:
      return DropdownFormContainer;

    case SurveyQuestionType.MatrixRange:
      return MatrixRangeFormContainer;

    case SurveyQuestionType.ImageMarkup:
      return ImageMarkupFormContainer;

    default:
      throw new UnreachableCaseError(question.typeId);
  }
}

export const SurveyFormQuestionContainer = (props: Props) => {

  const question = useSurveyFormQuestionContext();
  const [{ answer, rationale }, setState] = useSurveyFormScreenContext();

  const handleRationaleChange = useCallback((value: string) => {
    setState(s => ({
      ...s,
      rationale: value,
    }));
  }, [setState]);

  const handleAnswerChange = useCallback((value: RespondentAnswer) => {
    setState(s => ({
      ...s,
      answer: value,
    }));
  }, [setState]);

  const QuestionTypeContainer = useMemo(() => {
    return getQuestionContainer({ typeId: question.typeId });
  }, [question.typeId]);

  return (
    <SurveyFormAnswerRationaleContext.Provider value={[rationale, handleRationaleChange]}>
      <SurveyFormQuestionAnswerContext.Provider value={[answer, handleAnswerChange]}>
        <QuestionTypeContainer>
          {props.children}
        </QuestionTypeContainer>
      </SurveyFormQuestionAnswerContext.Provider>
    </SurveyFormAnswerRationaleContext.Provider>
  );
};

export default SurveyFormQuestionContainer;