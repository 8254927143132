import { useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import * as consts from '@consts';
import { useUpdateUserProfileReattestation } from '@utils/api';
import { UserProfileCheckup } from '@/components/Modal/UserProfileCheckup';

type Props = {
  children: React.ReactElement;
};

const mapState = (state: Store.State) => state.platform.alerts;

const UserProfileReattestation = (props: Props) => {
  const alerts = useSelector(mapState);
  const match = useRouteMatch(consts.path.Account.Profile.Checkup);
  const logProfileUpdate = useUpdateUserProfileReattestation();

  const shouldUpdate = alerts.displayProfileReattestation && !!match;
  const launchAlert = alerts.displayProfileReattestation && !match;

  useEffect(() => {

    if (shouldUpdate) {
      logProfileUpdate();
    }

  }, [
    logProfileUpdate,
    shouldUpdate,
  ]);

  return (
    <Fragment>
      {props.children}
      {launchAlert &&
        <UserProfileCheckup onClose={logProfileUpdate} />}
    </Fragment>
  );
};

export { UserProfileReattestation };
export default UserProfileReattestation;