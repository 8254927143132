import { useCallback, useEffect, useRef, useState } from 'react';
import type { ConnectOptions, LocalTrack, Room, TwilioError } from 'twilio-video';
import Video from 'twilio-video';
import { captureException } from '@sentry/react';
import { useReconnectHandler, useSocket } from '@containers/Conference/hooks';
import { ConferenceSocketEvent } from '@/enums';
import { isMobile } from '../utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Callback = (...args: any[]) => void;

export function useRoom(localTracks: LocalTrack[], onError: Callback, options?: ConnectOptions) {
  const [room, setRoom] = useState<Room>(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const optionsRef = useRef(options);

  const [onReconnecting, onReconnected] = useReconnectHandler(({
    onReconnectTimeout: () => {
      captureException('Browser timed out trying to reconnect to twilio room');
    },
  }));

  const socket = useSocket();

  useEffect(() => {
    // This allows the connect function to always access the most recent version of the options object. This allows us to
    // reliably use the connect function at any time.
    optionsRef.current = options;
  }, [options]);

  const connect = useCallback(
    (token: string, name: string) => {
      setIsConnecting(true);
      return Video.connect(token, { ...optionsRef.current, name, tracks: localTracks }).then(
        newRoom => {
          setRoom(newRoom);
          const disconnect = () => {

            const conferenceIdentifier = +newRoom.name.split('_')[1];
            socket.raw.emit(ConferenceSocketEvent.Leave, {
              conferenceIdentifier,
            });
            newRoom.disconnect();
          };

          // This app can add up to 13 'participantDisconnected' listeners to the room object, which can trigger
          // a warning from the EventEmitter object. Here we increase the max listeners to suppress the warning.
          newRoom.setMaxListeners(15);

          newRoom.once('disconnected', (room, error: TwilioError) => {
            if (error) {
              if (error.code !== 53118) {
                captureException('Twilio Video Room Disconnected', {
                  extra: {
                    error,
                  },
                });
                console.log(`Disconnected from room with error: ${JSON.stringify(error)}`);
              }
            }
            // Reset the room only after all other `disconnected` listeners have been called.
            setTimeout(() => setRoom(null));
            window.removeEventListener('beforeunload', disconnect);

            if (isMobile) {
              window.removeEventListener('pagehide', disconnect);
            }
          });

          newRoom.on('reconnecting', err => {
            console.log(`Twilio Room reconnecting (${err?.code}): ${err?.message}`);
            onReconnecting();
          });

          newRoom.on('reconnected', () => {
            onReconnected();
          });

          newRoom.localParticipant.videoTracks.forEach(publication =>
            // All video tracks are published with 'low' priority because the video track
            // that is displayed in the 'MainParticipant' component will have it's priority
            // set to 'high' via track.setPriority()
            publication.setPriority('low')
          );

          setIsConnecting(false);

          // Add a listener to disconnect from the room when a user closes their browser
          //The unload event claims to be unreliable but using onbeforeunload disconnects the user even if they decide to stay
          window.addEventListener('unload', disconnect);

          // Add a listener to disconnect from the room when a mobile user closes their browser
          window.addEventListener('pagehide', disconnect);

          return newRoom;
        },
        error => {
          onError(error);
          setIsConnecting(false);
        }
      );
    },
    [localTracks, onError, onReconnected, onReconnecting, socket.raw]
  );

  return { room, isConnecting, connect };
}

export default useRoom;