import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import { updateTag } from '@api/projects.conference';
import { ConferenceTags } from '@api/interfaces/projects';
import { ProjectConferenceTagWithMetadata } from '@/types';
import { TagStateContext } from '@/components/Project.Tags/Context';
import { TagActions } from '@/components/Project.Tags/interfaces';
import Toast from '@/components/Toast';

type HookParams = IProjectId & {
  tag: ProjectConferenceTagWithMetadata;
};

type MutationParams = Partial<Omit<ConferenceTags.UpdateTag.Request, 'projectId' | 'id'>>;
export const useInlineUpdateTagMutation = ({ projectId, tag }: HookParams) => {
  const { dispatch } = useContext(TagStateContext);
  return useMutation(['update-inline-project-tag', tag.id], (data: MutationParams) => {
    return updateTag({
      projectId,
      projectTagId: tag.id,
      color: tag.base.color,
      title: tag.base.name,
      includeSummary: tag.includeSummary,
      parentId: tag.parent?.id,
      ...data,
    });
  }, {
    onMutate: params => {
      const originalTag: Pick<TagActions.UpdateTag, 'partialTag' | 'baseTag'> = {
        partialTag: { includeSummary: tag.includeSummary },
        baseTag: { name: tag.base.name, color: tag.base.color },
      };
      dispatch({
        type: 'update-tag',
        projectTagId: tag.id,
        partialTag: { includeSummary: params.includeSummary ?? tag.includeSummary },
        baseTag: { name: params.title ?? tag.base.name, color: params.color ?? tag.base.color },
      });

      return { originalTag };
    },
    onError: (err, variable, ctx) => {
      Toast.error({ title: 'There was an error updating the tag' });

      dispatch({
        type: 'update-tag',
        projectTagId: tag.id,
        ...ctx.originalTag,
      });
    },
  });
};