import { useQueryClient } from '@tanstack/react-query';
import * as API from '@api/interfaces';
import * as QK from '@consts/querykey';
import { MedicalExpertiseData } from '@containers/MedicalOnboarding/interfaces';

type Params = number;

export const useMedicalOnboardingQueriesState = (userId: Params) => {
  const qc = useQueryClient();

  return {
    background: qc.getQueryState<API.Users.Onboarding.FetchBackground.Response>(QK.Users.Onboarding.Background.Get({
      userId,
    })),
    expertise: qc.getQueryState<MedicalExpertiseData>(QK.Users.Onboarding.Expertise.Get({
      userId,
    })),
  };
};