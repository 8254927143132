import { useCallback, useMemo } from 'react';
import { CallRole, utils } from '@enums';
import { BadgeExternal, BadgeOffPlatform, BadgeUnverified, BadgeVerified } from '@presentation';
import { useShouldDisplayExternalBadge } from '@/components/ObjectAccess/hooks';
import type { ConferenceParticipant } from '@/types';
import { CopyLinkToClipboard } from './CopyLinkToClipboard';
import styles from './style/Conference.Participants.Table.css';
import { formatPIN, sortCallAttendees } from './utils';

type Props = {
  identifier: number;
  items:      ConferenceParticipant[];
};

export const ConferenceParticipantsTable = (props: Props) => {
  const participants = useMemo(() => {
    return [...props.items].sort(sortCallAttendees);
  }, [props.items]);

  const formatDisplayName = useCallback((x: ConferenceParticipant) => {
    return x.offPlatform
      ? x.roleId === CallRole.PrimaryRespondent
        ? formatOffPlatformPrimaryRespondent(x)
        : x.email
      : `${x.name} (${x.email})`;
  }, []);

  const shouldDisplayExternalBadge = useShouldDisplayExternalBadge();

  return (
    <div className={styles.root}>
      <div className={styles.th}>
        <div className={styles.name}>Name</div>
        <div className={styles.pin}>PIN</div>
        <div className={styles.visibility} />
        <div className={styles.role}>Role</div>
      </div>
      {participants.map(x =>
        <div
          className={styles.tr}
          key={x.name}>
          <div className={styles.name}>
            <div className={styles.text}>{formatDisplayName(x)}</div>
            {x.verified && <BadgeVerified />}
            {!x.offPlatform && !x.verified && <BadgeUnverified />}
            {x.offPlatform && <BadgeOffPlatform />}
            {shouldDisplayExternalBadge(x.internal) && <BadgeExternal />}
          </div>
          <div className={styles.pin}>
            <div className={styles.digits}>
              {formatPIN(x.pin)}
            </div>
            <CopyLinkToClipboard
              identifier={props.identifier}
              pin={x.pin} />
          </div>
          <div className={styles.visibility}>
            {x.visibility
              ? <div className={styles.visible}>Visible</div>
              : <div className={styles.hidden}>Hidden</div>}
          </div>
          <div className={styles.role}>{`${utils.CallRole.getName(x.roleId)}${x.organizer ? ' (Organizer)' : ''}`}</div>
        </div>)}
    </div>
  );
};

ConferenceParticipantsTable.displayName = 'Conference.Participants.Table';

function formatOffPlatformPrimaryRespondent(item: ConferenceParticipant) {
  return item.userId === 0
    ? `${item.name} (Anonymous)`
    : `${item.name} (${item.email})`;
}