import { useCallback } from 'react';
import { Download } from 'react-feather';
import { useTranscriptDownloadModal } from './TranscriptDownloadModal';
import styles from './style/TranscriptDownloader.css';

type Props = {
  transcriptId: number;
} & Partial<ChildrenProps>;

export const TranscriptDownloader = ({ children, transcriptId }: Props) => {
  const [toggleModal, DownloadModal] = useTranscriptDownloadModal();
  const Content = useCallback(() => {
    if (children) {
      return <>{children}</>;
    } else {
      return <Download />;
    }
  }, [children]);

  return (
    <>
      <div
        className={styles.download}
        onClick={toggleModal}>
        <Content />
      </div>
      <DownloadModal
        transcriptId={transcriptId} />
    </>
  );
};