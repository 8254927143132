import { GetUpcomingCalls } from '@api/interfaces/users';
import { useIsInternalUser } from '@/containers/Store/hooks';
import { useNavigateToCallDetails } from '@/components/CallsAgenda';
import { BaseCallAgendaItem } from './AgendaItem.Base';

type UpcomingCallsItem = GetUpcomingCalls.Item;

type Props = {
  item: UpcomingCallsItem;
};

const ProjectCallAgendaItem = ({ item }: Props) => {
  const isInternalUser = useIsInternalUser();

  const description = isInternalUser
    ? item.primaryRespondent.name
    : '';

  const navigateToCallDetails = useNavigateToCallDetails(item.call.id);

  const title = item.call.title ?? item.project.name;

  return (
    <BaseCallAgendaItem
      item={item}
      onClick={navigateToCallDetails}
      title={title}
      description={description} />
  );
};

export { ProjectCallAgendaItem };
export default ProjectCallAgendaItem;