import { useCallback } from 'react';
import type { SurveyPipedValueType } from '@enums/Survey';
import type { SurveyQuestion, SurveyQuestionOption } from '@/types/survey';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import { trunc } from '@utils';
import { usePipingMenuState } from './context';
import { Menu, MenuItem } from './Editor.Piping.Menu';

type Props = {
  type: SurveyPipedValueType;
  item: SurveyQuestion;
  onClick: (row: SurveyQuestionOption) => void;
};

export const PipingOptionsMenu = ({ item, onClick, type }: Props) => {

  const { setOpen: setMenuOpen } = usePipingMenuState();

  const handleItemClick = useCallback((option: SurveyQuestionOption) => () => {
    onClick(option);
    setMenuOpen(false);
  }, [
    onClick,
    setMenuOpen,
  ]);

  const renderItem = useCallback((option: SurveyQuestionOption) => {

    const text = `A${option.ordinal}: ${trunc(parseSurveyRichText(option.value), 50)}`;

    const onClick = handleItemClick(option);

    return (
      <MenuItem
        key={option.ordinal}
        onClick={onClick}>
        {text}
      </MenuItem>
    );
  }, [handleItemClick]);

  return (
    <Menu>
      {item.options.map(renderItem)}
    </Menu>
  );
};