import { useCallback, useContext } from 'react';
import { NumberInput } from '@/components/Input';
import { Switch } from '@/components/Switch';
import { ProjectCreationSetupContext } from './Context';
import { Setup as S } from './interfaces';
import styles from './style/Frame.Setup.css';

type Props = unknown;

export const Survey = (props: Props) => {
  const { form, setValue } = useContext(ProjectCreationSetupContext);

  const setSurvey = useCallback((params: Partial<S.FormState['survey']>) => {
    setValue({
      ...form,
      survey: {
        ...form.survey,
        ...params,
      },
    });
  }, [
    form,
    setValue,
  ]);

  return (
    <>
      <div className={styles.row}>
        <div className={styles.field}>
          <div className={styles.label}>Anonymize Survey Participants</div>
          <Switch
            active={form.survey.anonymity}
            onClick={value => setSurvey({ anonymity: value })} />
        </div>

        <div className={styles.field}>
          <div className={styles.label}>Estimated Length (Minutes)</div>
          <NumberInput
            decimalScale={0}
            allowLeadingZeros={false}
            rootClassName={styles.input}
            onValueChange={v => setSurvey({ length: v.floatValue })}
            value={form.survey.length || ''} />
        </div>
      </div>
    </>
  );
};

Survey.displayName = 'Field.Setup.Survey';