import { useCallback } from 'react';
import { SurveyTemplateType } from '@enums';
import { useSurveyBuilderState } from './useSurveyBuilderContext';

export const useSurveyTemplate = () => {

  const [state, dispatch] = useSurveyBuilderState();

  const setTemplate = useCallback((value: SurveyTemplateType) => {
    dispatch({
      value,
      type: 'template-updated',
    });
  }, [
    dispatch,
  ]);

  return [state.survey.template.type, setTemplate] as const;
};