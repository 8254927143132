import type { ComponentType } from 'react';
import { cx } from '@utils';
import Pencil from '@/components/icons/Pencil';
import styles from './style.css';

type Props = {
  canEdit?: boolean;
  children: React.ReactNode;
  className?: string;
  onClick: () => void;
};

export const Editable = ({
  canEdit = true,
  children,
  className,
  onClick,
}: Props) => {
  if (!canEdit) {
    return <>{children}</>;
  }

  return (
    <div className={cx(styles.root, className)}>
      {children}
      <div className={styles.edit}>
        <Pencil
          className={styles.pencil}
          onClick={onClick} />
      </div>
    </div>
  );
};

type OverlayProps = {
  enabled?: boolean;
  children: React.ReactNode;
  className?: string;
  onClick: () => void;
  Component: ComponentType<{
    className?: string;
    onClick: () => void;
  }>;
};

export const VisibleOnHoverOverlay = ({
  Component,
  enabled = true,
  children,
  className,
  onClick,
}: OverlayProps) => {
  if (!enabled) {
    return <>{children}</>;
  }

  return (
    <div className={cx(styles.root, className)}>
      {children}
      <Component
        className={styles.overlayIcon}
        onClick={onClick} />
    </div>
  );
};

export default Editable;