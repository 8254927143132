import { Link } from 'react-router-dom';
import { cx } from '@utils';
import styles from './style/CountBadge.css';

type Props = {
  className?: string;
  count: number;
  icon: React.ReactNode;
  to: string;
};

const CountBadge = (props: Props) => (
  <Link className={cx(styles.root, props.className)} to={props.to}>
    <div className={styles.icon}>{props.icon}</div>
    <div className={styles.text}>{props.count}</div>
  </Link>
);

export { CountBadge };
export default CountBadge;