import { RefObject, useState, useMemo, useEffect } from 'react';

export function useOnScreen(ref: HTMLElement) {

  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(() => new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting)
  ), []);

  useEffect(() => {
    if (ref) {
      observer.observe(ref);
      return () => observer.disconnect();
    }
  }, [observer, ref]);

  return isIntersecting;
}