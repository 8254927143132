import { Link } from 'react-router-dom';
import { cx, getLocationFor } from '@utils';
import { TableCellItem } from './interfaces';
import styles from './style/Table.css';

type Props = TableCellItem;

export const Cell = (props: Props) => {
  const loc = getLocationFor.call.root({
    callId: props.row.original.call.id,
  });

  return (
    <Link
      className={cx(styles.id, styles.link)}
      to={loc}>
      {props.row.original.call.id}
    </Link>
  );
};

Cell.displayName = 'Column.Id.Cell';