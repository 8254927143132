import { useContext, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as $api from '@api';
import { BrandProjectTrackerContext, BrandStateContext } from '@/containers/Workspace.Brand/Context';
import type { BrandProjectTracker } from './interfaces';

type Props = ChildrenProps;

export const BrandProjectTrackerContainer = (props: Props) => {
  const state = useContext(BrandStateContext);

  const queryKey = useMemo(() => {
    return [
      `get:brand:project-tracker`, {
        brandId: state.brand.id,
      },
    ];
  }, [state.brand.id]);

  const query = useQuery(queryKey, () => {
    return $api.workspaces.brand.getProjectTracker({
      brandId: state.brand.id,
    });
  }, {
    placeholderData: {
      projects: [],
      tracker: [],
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const value: BrandProjectTracker.Context = useMemo(() => ({
    projects: query.data.projects.map(p => ({
      id: p.id,
      name: p.name,
    })),
    tracker: query.data.projects.reduce((acc, p) => {
      const projectTracker = query.data.tracker.find(t => t.projectId === p.id);
      acc[p.id] = {
        segments: [{
          name: p.name,
          start: projectTracker?.start,
          end: projectTracker?.end,
        }],
      };
      return acc;
    }, {} as Record<number, BrandProjectTracker.TrackerItem>),
  }), [
    query.data.projects,
    query.data.tracker,
  ]);

  return (
    <BrandProjectTrackerContext.Provider value={value}>
      {props.children}
    </BrandProjectTrackerContext.Provider>
  );
};

BrandProjectTrackerContainer.Name = 'Workspace.Brand.ProjectTracker.Container';