import extend from '@enums/extend';

export enum PayoutMethod {
  NotSet = 0,
  Tipalti = 4,
}

const payoutMethod = new Map([
  [PayoutMethod.NotSet, { name: 'Not Set' }],
  [PayoutMethod.Tipalti, { name: 'Tipalti' }],
]);

export enum TipaltiPayeePaymentMethod {
  NoPM = 0,
  PayPal = 1,
  WireTranser = 2,
  Payoneer = 3,
  ACH = 4,
  Check = 5,
  eCheck = 6,
  HoldMyPayments = 7,
  Custom = 8,
  Intercash = 9,
}

const payeePaymentMethod = new Map([
  [TipaltiPayeePaymentMethod.NoPM, { name: 'Not Set' }],
  [TipaltiPayeePaymentMethod.PayPal, { name: 'PayPal' }],
  [TipaltiPayeePaymentMethod.WireTranser, { name: 'Wire Transfer' }],
  [TipaltiPayeePaymentMethod.Payoneer, { name: 'Payoneer' }],
  [TipaltiPayeePaymentMethod.ACH, { name: 'ACH' }],
  [TipaltiPayeePaymentMethod.Check, { name: 'Check' }],
  [TipaltiPayeePaymentMethod.eCheck, { name: 'eCheck' }],
  [TipaltiPayeePaymentMethod.HoldMyPayments, { name: 'Hold My Payments' }],
  [TipaltiPayeePaymentMethod.Custom, { name: 'Custom' }],
  [TipaltiPayeePaymentMethod.Intercash, { name: 'Intercash' }],
]);

export enum TipaltiPayeeStatus {
  Pending = 0,
  Active = 1,
  Suspended = 2,
  Blocked = 3,
}

const payeeStatus = new Map([
  [TipaltiPayeeStatus.Pending, { name: 'Pending' }],
  [TipaltiPayeeStatus.Active, { name: 'Active' }],
  [TipaltiPayeeStatus.Suspended,  { name: 'Suspended' }],
  [TipaltiPayeeStatus.Blocked,  { name: 'Blocked' }],
]);

export enum TipaltiTaxFormStatus {
  NotSubmitted = 0,
  Submitted = 1,
  SubmittedPendingDocuments = 2,
  SubmittedPendingDocumentsApproval = 3,
  SubmittedFailedTINValidation = 4,
  SubmittedPendingTINValidation = 5,
  SubmittedTINValidated = 6,
  PendingReview = 7,
  PendingReviewPendingDocuments = 8,
  PendingReviewPendingDocumentsApproval = 9,
}

const taxFormStatus = new Map([
  [TipaltiTaxFormStatus.NotSubmitted, { name: 'Not Submitted' }],
  [TipaltiTaxFormStatus.Submitted, { name: 'Submitted' }],
  [TipaltiTaxFormStatus.SubmittedPendingDocuments, { name: 'Submitted - Pending Documents' }],
  [TipaltiTaxFormStatus.SubmittedPendingDocumentsApproval, { name: 'Submitted - Pending Documents Approval' }],
  [TipaltiTaxFormStatus.SubmittedFailedTINValidation, { name: 'Submitted - Failed TIN Validation' }],
  [TipaltiTaxFormStatus.SubmittedPendingTINValidation, { name: 'Submitted - Pending TIN Validation' }],
  [TipaltiTaxFormStatus.SubmittedTINValidated, { name: 'Submitted - TIN Validated' }],
  [TipaltiTaxFormStatus.PendingReview, { name: 'Pending Review' }],
  [TipaltiTaxFormStatus.PendingReviewPendingDocuments, { name: 'Pending Review - Pending Documents' }],
  [TipaltiTaxFormStatus.PendingReviewPendingDocumentsApproval, { name: 'Pending Review - Pending Documents Approval' }],
]);

export default {
  PayoutMethod: extend(payoutMethod),
  TipaltiPayeePaymentMethod: extend(payeePaymentMethod),
  TipaltiPayeeStatus: extend(payeeStatus),
  TipaltiTaxFormStatus: extend(taxFormStatus),
};