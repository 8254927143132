import { Alert } from '@/components/Modal/Alert';
import { ModalProps } from '@/components/Modal/Modal';

type Props = {
  onConfirm: () => unknown;
} & Pick<ModalProps,
    'onClose' |
    'open'>;

export const DeleteProfileEntryAlert = ({
  onConfirm,
  onClose,
  open,
}: Props) => {

  return (
    <Alert
      confirmText="Delete"
      message="Are you sure you want to delete this entry?"
      onClose={onClose}
      onConfirm={onConfirm}
      open={open} />
  );
};