import { useMemo, useRef } from 'react';
import { createEditor, Editor } from 'slate';
import { withReact, ReactEditor } from 'slate-react';
import { withHistory, HistoryEditor } from 'slate-history';

export function useSlateEditor() {
  const ref = useRef<Editor & ReactEditor & HistoryEditor>();

  if (!ref.current) ref.current = withHistory(withReact(createEditor()));

  return ref.current;
}