import type { ProjectSurveyDetailsInternalUser, ProjectSurveyDetailsExternalUser } from '@/types';
import { CompletedSurvey } from '@/scenes/surveys/CompletedSurvey/CompletedSurvey';
import { SurveyDetailsExternalUser } from './SurveyDetails.ExternalUser';
import { SurveyDetailsInternalUser } from './SurveyDetails.InternalUser';
import { ExternalUserDetails, InternalUserDetails } from './UserDetails';
import type { SurveyResponseProps } from './interfaces';
import styles from './style.css';

export const CompletedProjectSurveyReviewInternalUser = ({ data, ...props }: SurveyResponseProps<ProjectSurveyDetailsInternalUser>) => {
  return (
    <div className={styles.root}>
      <CompletedSurvey
        buttons={props.renderButtons()}
        results={props.renderBody()}
        surveyDetails={<SurveyDetailsInternalUser {...data} />}
        userDetails={<InternalUserDetails user={data.user} />} />
    </div>
  );
};

export const CompletedProjectSurveyReviewExternalUser = ({ data, ...props }: SurveyResponseProps<ProjectSurveyDetailsExternalUser>) => {
  return (
    <div className={styles.root}>
      <CompletedSurvey
        buttons={props.renderButtons()}
        results={props.renderBody()}
        surveyDetails={<SurveyDetailsExternalUser {...data} />}
        userDetails={<ExternalUserDetails user={data.user} />} />
    </div>
  );
};