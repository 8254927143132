import { nullableDate } from '@utils';
import { Groups } from '$admin/api/interfaces';

export const transformAccountDetails = (data: Groups.FetchAccount.Response<string>) => {
  return {
    attributes: data.attributes,
    billing: {
      ...data.billing,
      contractExpiresOn: nullableDate(data.billing.contractExpiresOn),
    },
    blacklistedCompanies: data.blacklistedCompanies,
    createdOn: new Date(data.createdOn),
    features: data.features,
    id: data.id,
    name: data.name,
    settings: data.settings,
  };
};