import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useProjectState } from '@containers/GroupProject/hooks';
import { ProjectTagsQueryContext } from '@containers/Group.Project.Tags/Context';
import { useOwnProjectAccessRole } from '@containers/GroupProject/hooks';
import { WorkspaceObjectRole } from '@enums';
import { ConditionalTooltip } from '@presentation/Tooltip';
import { getLocationFor, useDebounceValue } from '@utils';
import { useProjectTagsWithChildren } from '@/containers/Group.Project.Tags/Project.Tags.Query.Container';
import { Button } from '@/components/Button';
import * as Table from '@/components/Table';
import { useModal } from '@/components/Modal/hooks';
import { useTagStateReducer, useTagsOnboardingDismiss, useTagsOnboardingEnabled } from '@/components/Project.Tags/hooks';
import { TagStateContext } from '@/components/Project.Tags/Context';
import * as Modal from './Modal';
import { TagsTable } from './Tags.Table';
import styles from './style/ProjectTagsTab.css';

type Props = {
  loadingStateRowCount?: number;
};

export const ProjectTags = ({ loadingStateRowCount = 25 }: Props) => {
  const { project } = useProjectState();
  const [state, dispatch] = useTagStateReducer();
  const [openCreateTagModal, CreateTagModal] = useCreateTagModal();
  const query = useContext(ProjectTagsQueryContext);
  const tags = useProjectTagsWithChildren();

  const { dismiss, isDismissed } = useTagsOnboardingDismiss();
  const [showSetupTagsModal, setShowSetupTagsModal] = useState(false);

  const onboarding = useTagsOnboardingEnabled();

  const initialize = useCallback(() => {
    if (!onboarding.enabled && !isDismissed) {
      setShowSetupTagsModal(true);
    }

    dispatch({
      type: 'initialize',
      state: { tags },
    });
  }, [
    dispatch,
    isDismissed,
    onboarding,
    tags,
  ]);

  const closeSetupTagsModal = useCallback(() => {
    setShowSetupTagsModal(false);
    dismiss();
  }, [
    dismiss,
    setShowSetupTagsModal,
  ]);

  useEffect(() => {

    if (query.isFetchedAfterMount) {
      initialize();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [query.isFetchedAfterMount]);

  const loading = useDebounceValue(query.isInitialLoading && !query.data || !query.isFetchedAfterMount && !!query.data, 600);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <TagStateContext.Provider value={{ dispatch, projectId: project.id, state }}>
          <Table.Layout.Box>
            <Table.Layout.Header title="Tags">
              <div className={styles.actions}>
                <TagCreationButton
                  onClickCreateTag={() => openCreateTagModal(null)}
                  onboardingEnabled={onboarding.enabled} />
              </div>
            </Table.Layout.Header>

            <TagsTable
              loading={loading}
              loadingStateRowCount={loadingStateRowCount} />
          </Table.Layout.Box>
          <CreateTagModal />
          {showSetupTagsModal &&
            <Modal.SetupTags
              onClose={closeSetupTagsModal}
              open />}
        </TagStateContext.Provider>
      </div>
    </div>
  );
};

ProjectTags.displayName = 'Project.Tags';

type TagCreationButtonProps = {
  onClickCreateTag: () => void;
  onboardingEnabled?: boolean;
};

const TooltipTitle = `Please update your project access to collaborator`;

const TagCreationButton = (props: TagCreationButtonProps) => {
  const ctx = useProjectState();
  const roleId = useOwnProjectAccessRole();

  const disabled = useMemo(() => {
    return ![
      WorkspaceObjectRole.Owner,
      WorkspaceObjectRole.Collaborator,
    ].includes(roleId);
  }, [roleId]);

  if (props.onboardingEnabled) {
    return (
      <ConditionalTooltip
        enabled={disabled}
        title={TooltipTitle}>
        <span>
          <Button
            className={styles.btn}
            color="secondary"
            disabled={disabled}
            to={getLocationFor.project.tagsOnboarding({ projectId: ctx?.project?.id })}
            variant="brick">
            Get Started
          </Button>
        </span>
      </ConditionalTooltip>
    );
  }

  return (
    <ConditionalTooltip
      enabled={disabled}
      title={TooltipTitle}>
      <span>
        <Button
          className={styles.btn}
          color="secondary"
          disabled={disabled}
          onClick={props.onClickCreateTag}
          variant="brick">
          New
        </Button>
      </span>
    </ConditionalTooltip>
  );
};

TagCreationButton.displayName = 'Project.Tags.TagCreationButton';

const useCreateTagModal = () => {
  const [createParentId, setCreateParentId] = useState<number>(null);
  const [toggleCreateModal, CreateModal] = useModal(Modal.CreateTag, {
    keepMounted: false,
  });

  const openModal = useCallback((parentId: number) => {
    setCreateParentId(parentId);
    toggleCreateModal();
  }, [setCreateParentId, toggleCreateModal]);

  const CreateTagModal = useCallback(() => {
    return <CreateModal parentTagId={createParentId} />;
  }, [createParentId, CreateModal]);

  return [openModal, CreateTagModal] as const;
};