import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { useFindReplaceAllMutation, useTranscriptToolsPermission } from '@containers/Transcript/hooks';
import { ButtonActivityIndicator } from '@presentation';
import { cx, useDebounceValue } from '@utils';
import { TranscriptIdContext } from '@/containers/Transcript/context';
import Button from '@/components/Button';
import { FindReplaceContext } from './context';
import { Preview } from './FindReplace.Preview';
import styles from './style/FindReplace.css';

type Props = unknown;

export const FindReplace = (props: Props) => {
  const { caseSensitive, findNext, findPrev, query, replacement, setQuery, setReplacement, toggleCaseSensitive, activeIndex, total, replace, replaceAll } = useContext(FindReplaceContext);
  const permission = useTranscriptToolsPermission();
  const transcriptId = useContext(TranscriptIdContext);

  const mutation = useFindReplaceAllMutation({ transcriptId });

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>

          <div className={styles.label}>Search</div>
          <div className={styles.row}>
            <div className={styles.field}>
              <FindInput
                findNext={findNext}
                findPrev={findPrev}
                query={query}
                setQuery={setQuery} />
              <div className={styles.findMod}>
                <button className={styles.findBtn} onClick={findPrev}>
                  <ChevronLeft size={14} />
                </button>
              </div>
              <div className={styles.findMod}>
                <button className={styles.findBtn} onClick={findNext}>
                  <ChevronRight size={14} />
                </button>
              </div>
              <div
                className={styles.mod}
                title="Match Case">
                <button
                  className={cx(styles.btn, { [styles.enabled]: caseSensitive })}
                  tabIndex={-1}
                  onClick={toggleCaseSensitive}>
                  <TextFormatIcon />
                </button>
              </div>
            </div>
          </div>

          {permission.replace &&
            <>
              <div className={styles.label}>Replace</div>
              <div className={styles.row}>
                <div className={styles.field}>
                  <ReplaceInput
                    replacement={replacement}
                    setReplacement={setReplacement} />
                </div>
              </div>
            </>}

          <div className={styles.footer}>
            {total > 0
              ? <div className={styles.stats}>Result {activeIndex + 1} of {total}</div>
              : <div className={styles.stats}>No Results</div>}
            {permission.replace &&
              <div className={styles.btns}>
                <Button
                  size="small"
                  variant="brick"
                  disabled={!replacement && total <= 0}
                  onClick={replace}>
                  Replace
                </Button>
                <Button
                  size="small"
                  variant="brick"
                  disabled={!replacement && total <= 0}
                  onClick={replaceAll}>
                  Replace All
                </Button>
              </div>}
          </div>
          <Preview />
        </div>
      </div>
    </div>
  );
};

FindReplace.displayName = 'FindReplace';

type FindInputProps = {
  findNext: () => void;
  findPrev: () => void;
  query: string;
  setQuery: (query: string) => void;
};

const FindInput = ({ findNext, findPrev, query, setQuery }: FindInputProps) => {
  const mounted = useRef(false);
  const [value, setValue] = useState(query);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);

  const debounced = useDebounceValue(value, 300);

  useEffect(() => {

    if (!mounted.current) {
      mounted.current = true;
    } else {
      setQuery(debounced);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [debounced]);

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!query.length) return;

    if (e.key === 'Enter' && e.shiftKey) {
      findPrev();
    } else if (e.key === 'Enter') {
      findNext();
    }
  }, [
    findNext,
    findPrev,
    query,
  ]);

  return (
    <input
      autoFocus
      className={styles.input}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
      tabIndex={0}
      type="text"
      value={value} />
  );
};

type ReplaceInputProps = {
  replacement: string;
  setReplacement: (replacement: string) => void;
};

const ReplaceInput = ({ replacement, setReplacement }: ReplaceInputProps) => {
  return (
    <input
      className={styles.input}
      onChange={e => setReplacement(e.target.value)}
      tabIndex={0}
      type="text"
      value={replacement} />
  );
};

type ReplaceAllButtonProps = {
  disabled: boolean;
  loading: boolean;
  replaceAll: () => void;
};

const ReplaceAllButton = (props: ReplaceAllButtonProps) => {

  return (
    <ButtonActivityIndicator
      disabled={props.disabled}
      loading={props.loading}
      implicitDisable={false}
      indicatorSize={10}
      onClick={props.replaceAll}
      size="small"
      variant="brick">
      Replace All
    </ButtonActivityIndicator>
  );
};