import * as types from '@store/action-types';
import initialState from '@store/initial-state';

function network(state = initialState.network, action) {
  switch (action.type) {
    case types.CONNECTION_CHANGE:
      return {
        isConnected: action.isConnected,
      };

    default:
      return state;
  }
}

export default network;