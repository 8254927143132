import styled from '@emotion/styled';

type Props = ChildrenProps;

export function Title({ children }: Props) {
  return (
    <Root>
      <Text>{children}</Text>
    </Root>
  );
}

const Root = styled.div`
  box-sizing: border-box;
  margin: 0 0 14px 0;
`;

const Text = styled.h3`
  box-sizing: border-box;
  border-bottom: 1px solid var(--gray-l);
  padding: 0 0 10px 0;
  margin: 0;
`;