import { useMemo } from 'react';
import type { SurveyQuestionType } from '@enums';
import {
  useSurveyFormQuestionContext,
  useValidateRationale,
} from './hooks';
import { matrixSlider } from './utils';
import { SurveyFormCanSubmitContext, useSurveyFormQuestionAnswer } from './Context';
import { SurveyFormBackContainer, SurveyFormSubmitContainer } from './BaseNavigation';

type Props =
  ChildrenProps;

export const MatrixSliderFormContainer = (props: Props) => {
  const [answer] = useSurveyFormQuestionAnswer<SurveyQuestionType.Sliders>();
  const question = useSurveyFormQuestionContext<SurveyQuestionType.Sliders>();

  const validateRationale = useValidateRationale();

  const canSubmit = useMemo(() => {

    const isRationaleValid = validateRationale();

    if (!isRationaleValid) return false;

    const resolvedAnswer = answer || { items: [] };

    const isComplete = matrixSlider.isAnswerComplete(resolvedAnswer, question);

    if (!isComplete) return false;

    const sumValid = matrixSlider.isSumValid(resolvedAnswer, question);

    if (!sumValid) return false;

    // const filledOut = isOpenEndedFilledOut();

    // if (!filledOut) return false;

    return true;

    function isOpenEndedFilledOut() {
      const openEndedRows = question.matrixRows.filter(f => f.metadata.isOpenEnded);
      return !openEndedRows.some(r => {
        const rowAnswer = answer.items.find(s => s.matrixRowId === r.id);
        return rowAnswer && !rowAnswer.rowText;
      });
    }

  }, [
    answer,
    question,
    validateRationale,
  ]);

  return (
    <SurveyFormCanSubmitContext.Provider value={canSubmit}>
      <SurveyFormBackContainer>
        <SurveyFormSubmitContainer>
          {props.children}
        </SurveyFormSubmitContainer>
      </SurveyFormBackContainer>
    </SurveyFormCanSubmitContext.Provider>
  );
};

export default MatrixSliderFormContainer;