import type { AxiosResponse } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import * as $api from '@api/projects.conference';
import type { GetProjectConferenceTagsOverview } from '$admin/api/interfaces/projects';
import * as $admin from '$admin/api/projects.conference-tags';
import { useIsAdminPage } from '../hooks';

type Data = GetProjectConferenceTagsOverview.Response;
type Params = IProjectId;
type QueryKey = readonly [string, Params];

export function useProjectConferenceTagsOverview(
  data: Params,
  options?: UseQueryOptions<Data, AxiosResponse, Data, QueryKey>
) {

  const isAdminPage = useIsAdminPage();

  const queryKey = useMemo(() => isAdminPage
    ? AdminProjectConferenceTagsOverviewKey
    : ProjectConferenceTagsOverviewKey, [isAdminPage]);

  return useQuery({
    queryKey: [queryKey, data, isAdminPage] as const,
    queryFn: ({ queryKey }) => {
      const { projectId } = queryKey[1];

      return isAdminPage
        ? $admin.getProjectConferenceTagsOverview({ projectId })
        : $api.getTagsOverview({ projectId });

    },
    ...options,
  });
}

const AdminProjectConferenceTagsOverviewKey = `get:admin/projects/conference-tags/overview`;
const ProjectConferenceTagsOverviewKey = `get:projects/conference-tags/overview`;

export const isAdminPage = () => {
  const { pathname } = window.location;

  return pathname.startsWith('/admin');
};