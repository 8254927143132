import { useFetchMoreContentFeed, useContentFeedState } from '@containers/ContentFeed';
import { useInfiniteWindowScroll } from '@utils';

export const useFeedScroll = () => {
  const fetchMore = useFetchMoreContentFeed();
  const [state] = useContentFeedState();

  useInfiniteWindowScroll({
    canFetch: !state.loadingMore && !state.loading && !!state.items.length,
    fetchNext: fetchMore,
    bufferSize: 0.20,
  });
};

export default useFeedScroll;