import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { getLocationFor } from '@utils';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import { Header } from '@/components/Modal/Header';
import { useModal } from '@/components/Modal/hooks';
import { Button } from '@/components/Button';
import boostGraphic from 'static/images/website/project-entities/boosting-graphic-2.svg?url';
import { useBoostDismiss } from './hooks';
import styles from './style/Boosting.Modal.css';

type Props = {
  slug: string;
} & Pick<ModalProps, 'onClose' | 'open'>;
export const BoostingModal = ({ slug, open, onClose: onCloseParam }: Props) => {
  const history = useHistory();
  const onContinue = useCallback(() => {
    history.push(getLocationFor.project.entitiesOnboarding({ slug }));
  }, [history, slug]);

  const { dismiss } = useBoostDismiss();

  const onClose = useCallback(() => {
    onCloseParam();
    dismiss();
  }, [dismiss, onCloseParam]);

  return (
    <Modal open={open} onClose={onClose}>
      <Header className={styles.header}>Improve transcripts with medical term boosting</Header>
      <div className={styles.root}>
        <div className={styles.title}>Improve the accuracy of the automated transcripts on this project by providing the medical terms or drug names you expect to come up in interviews.</div>
        <img className={styles.boostGraphic} src={boostGraphic} />
        <Button
          className={styles.continueBtn}
          onClick={onContinue}
          variant='brick'
          color='primary'>
          Continue
        </Button>
      </div>
    </Modal>
  );
};

export const useBoostingModal = () => useModal(BoostingModal);