import { memo } from 'react';
import { FileIconProps } from './interfaces';

export const TranscriptFileIcon = memo(({
  className,
  size = 24,
}: FileIconProps) => {

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 19 22">
      <g
        id="Test-test"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g id="7" transform="translate(-109.000000, -322.000000)">
          <g id="Group-5" transform="translate(110.000000, 323.000000)">
            <line
              x1="5.3620255"
              y1="15.3350342"
              x2="3.3620255"
              y2="15.3350342"
              id="Path-6"
              stroke="#26AAE1"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round" />
            <line
              x1="6.3620255"
              y1="12.5069631"
              x2="3.3620255"
              y2="12.5069631"
              id="Path-6"
              stroke="#26AAE1"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round" />
            <path
              d="M15.1092721,8.98237715 C15.1092721,7.40351212 15.1092721,5.10927207 15.1092721,5.10927207 C13.9738783,3.97387828 12.2707876,2.27078759 10,0 L2,0 C0.8954305,0 3.37507799e-14,0.8954305 3.37507799e-14,2 L3.37507799e-14,18 C3.37507799e-14,19.1045695 0.8954305,20 2,20 C3.55401336,20 5.7829065,20 7,20"
              id="Path"
              stroke="#26AAE1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round" />
            <g
              id="Group-9"
              transform="translate(8.000000, 11.000000)"
              stroke="#546EB2">
              <path
                d="M4.5,9 C6.98528137,9 9,6.98528137 9,4.5 C9,2.01471863 6.98528137,0 4.5,0 C2.01471863,0 0,2.01471863 0,4.5 C0,6.98528137 2.01471863,9 4.5,9 Z"
                id="Oval"
                strokeWidth="2" />
              <line
                x1="5.69735516"
                y1="4.5"
                x2="4"
                y2="6.18277254"
                id="Path-4"
                strokeWidth="1.5"
                strokeLinecap="round" />
              <line
                x1="5.69735516"
                y1="4.5"
                x2="4"
                y2="3"
                id="Path-4"
                strokeWidth="1.5"
                strokeLinecap="round" />
            </g>
            <line
              x1="11.1159467"
              y1="5.5"
              x2="3.40497232"
              y2="5.5"
              id="Path-6"
              stroke="#26AAE1"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round" />
            <line
              x1="11.0587714"
              y1="8.5"
              x2="3.40497232"
              y2="8.5"
              id="Path-6"
              stroke="#26AAE1"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round" />
          </g>
        </g>
      </g>
    </svg>
  );
});

export default TranscriptFileIcon;