import Section from './Section';
import { Bold } from './Span';
import Title from './Title';
import styles from './style/Terms.css';

export default function Sentiment() {
  return (
    <div className={styles.wrap}>
      <div className={styles.terms}>
        <Section>
          <p>{`Trinity (“Company”, “we”, “us” or “our”) grants you permission as a user (“User”, “you” or “your”) to access and use our sites, software, applications, content, products and services (“Services”), which are owned or licensed by us on the following sites: `} <a href="https://www.sentimentglobal.com">https://www.sentimentglobal.com</a> {`(the “Site”). These terms govern your use of the Site and our provision of the Services on which these terms are posted. PLEASE READ THESE TERMS CAREFULLY BEFORE USING ANY of Trinity’s services.`}</p>
        </Section>
        <Section>
          <Title value={`YOUR ACCEPTANCE OF THESE TERMS OF USE`} />
          <p>{`These Terms of Use and our Privacy Policy apply each time you access the Sites or use the Services. When you access the Sites or use the Services, you accept these Terms of Use as if you had signed them, and you consent to our collecting and using your information as described in these Terms of Use and our Privacy Policy. If you do not agree to these Terms of Use or our Privacy Policy, you should not access the Sites or use the Services.`}</p>
        </Section>
        <Section>
          <Title value={`DESCRIPTION OF SERVICES`} />
          <p>{`The Services include market research services for the healthcare community. When using the Services, you shall be subject to any posted guidelines or rules applicable to such Services in addition to these Terms of Use.`}</p>
          <p>{`Neither the Company nor the Services provide medical advice or recommendations of particular drugs or products. All content is provided for information only and is intended for use by physicians and other healthcare professionals. The content includes information licensed or obtained from third parties, which may be updated periodically. The content should not be used as a substitute for clinical judgment, and healthcare professionals should independently evaluate and verify information obtained from the services.`}</p>
        </Section>
        <Section>
          <Title value={`DESCRIPTION OF SERVICES`} />
          <p>{`As a condition to using the Sites and Services, you may be required to register with us and provide a password and email address. If you register for a Service, you agree to receive emails, phone calls and/or text messages from us related to the Sites and the Services. You agree to provide and maintain accurate, current, and complete information, including your contact information for notices and other communications from us and your payment information (if applicable). Failure to do so shall constitute a breach of this Agreement, which may result in immediate termination of your account. We will handle any personally identifiable information you provide in accordance with our Privacy Policy. You may not (i) select or use as an account or a name of another person with the intent to impersonate that person; (ii) use as an email a name subject to any rights of another person without appropriate authorization; or (iii) misrepresent your licenses or qualifications. Company reserves the right to refuse registration of or cancel an account in our sole discretion. You are responsible for maintaining the confidentiality of your password and are solely responsible for all information and instructions transmitted through the Sites or the use of any Services using your password and email. All information and instructions communicated by use of your account will be considered to have been sent and authorized by you. You agree to promptly notify us of any unauthorized use of your account, password, or other account information, or of any other breach of security that you become aware of involving your account or the Services.`}</p>
        </Section>
        <Section>
          <Title value={`USER CONTENT`} />
          <p>{`The Services may allow you to communicate, submit, upload, or otherwise make available text, images, audio, video, or other content (“User Content”), which may be accessible and viewable by us. You may not submit or upload User Content that is defamatory, harassing, threatening, bigoted, hateful, violent, vulgar, obscene, pornographic, or otherwise offensive or that harms or can reasonably be expected to harm any person or entity, whether or not such material is protected by law.`}</p>
          <p>{`We do not claim ownership to your User Content; however, you grant us a non-exclusive, sublicensable, irrevocable and royalty-free worldwide license under all copyrights, trademarks, patents, trade secrets, privacy and publicity rights and other intellectual property rights to use, reproduce, transmit, print, publish, publicly display, exhibit, distribute, redistribute, copy, index, comment on, modify, adapt, translate, create derivative works based upon, publicly perform, make available and otherwise exploit such User Content, in whole or in part, in all media formats and channels now known or hereafter devised (including in connection with the Services and on third-party sites and platforms such as Facebook, YouTube, Twitter and such social media platforms), in any number of copies and without limit as to time, manner and frequency of use, without further notice to you, with or without attribution, and without the requirement of permission from or payment to you or any other person or entity.`}</p>
          <p>{`We have the right but not the obligation to monitor, screen, post, remove, modify, store and review User Content or communications using our Services, at any time and for any reason, including to ensure that the User Content or communication conforms to these terms, without prior notice to you. We are not responsible for, and do not endorse the opinions, views, advice, or recommendations posted or sent by users or guarantee the accuracy, integrity or quality of User Content. Under no circumstances will Company be liable in any way for any User Content, including, but not limited to, any errors or omissions in any User Content, or for any loss or damage of any kind incurred because of the use of any User Content posted, emailed or otherwise transmitted via any Service.`}</p>
          <p>{`You bear all the risks associated with the use of or exposure to any User Content. You acknowledge and agree that Company may preserve User Content and may also disclose User Content if required to do so by law or in the good faith belief that such preservation or disclosure is reasonably necessary to: (a) enforce these Terms of Use; (b) respond to claims that any User Content violates the rights of third-parties; or (c) protect the rights, property, or personal safety of the Company, its users and the public.`}</p>
        </Section>
        <Section>
          <Title value={`INTELLECTUAL PROPERTY OWNERSHIP`} />
          <p>{`The Sites and the Services contain proprietary and confidential information that: (a) is the sole property of the Company or its partners; (b) is protected under U.S. and international patent, copyright, trademark, trade secret and other intellectual property law; and (c) may only be used in accordance with these Terms of Use or our express written consent. You are granted only those rights necessary to access the Sites and use of the Services as permitted herein. All trademarks, service marks, trade names and trade dress (the "Marks"), whether registered or unregistered, appearing in the Sites are proprietary to us or our partners. You may not display or reproduce the Marks without our prior written consent, and you may not remove or otherwise modify any patent, copyright, trademark, other proprietary notice, or disclaimer from any content. All other rights not expressly granted herein are reserved.`}</p>
        </Section>
        <Section>
          <Title value={`WARRANTIES`} />
          <p>{`You warrant and represent that (i) you own all right, title, and interest in your User Content or have obtained the necessary rights to use your User Content on the Sites or with any part of the Services, (ii) your User Content does not violate any law or regulation, (iii) your User Content does not violate the intellectual property rights or any right of any third party, and (iv) your User Content is not designed to or will not knowingly have deleterious effect on Company, the Sites, the Services, other users or any computer, network, software or hardware.`}</p>
        </Section>
        <Section>
          <Title value={`INDEMNITY`} />
          <p>{`You agree to indemnify and hold Company and its officers, directors, parent(s), employees, agents, licensees, assigns, subsidiaries, affiliates, co-branders or other partners harmless from any claim, cause of action, suit, or demand, including reasonable attorney's fees, based on your breach of the foregoing warranties or these Terms of Use.`}</p>
        </Section>
        <Section>
          <Title value={`LINKS TO THIRD-PARTY SITES`} />
          <p>{`The Services may contain links to third party web sites ("Linked Sites"). The Linked Sites are not under the control of Company and Company is not responsible or liable for the communications or materials of any Linked Sites. You are responsible for adhering to the applicable terms of service for any other web site and are solely responsible and liable for any interactions you may have with such sites.`}</p>
        </Section>
        <Section>
          <Title value={`PRIVACY`} />
          <p>{`Your personal information is subject to our Privacy Policy, which you should read in its entirety. The most current version of the Privacy Policy always is posted on the Sites.`}</p>
        </Section>
        <Section>
          <Title value={`NOTICES OF ALLEGED INFRINGEMENT OF THIRD-PARTY RIGHTS`} />
          <p>{`We respect the intellectual property of others and ask our users to do the same. We will process and investigate notices of alleged infringement and will take appropriate actions under the Digital Millennium Copyright Act ("DMCA") and other applicable intellectual property laws. If you believe that any of the material contained on a Site infringes or violates any copyrights, trademarks or other intellectual property rights owned by you or any other rights, please email us the specifics of your claim to support@sentimentglobalcom.`}</p>
          <p>{`All the following information is required to process and investigate your claim:`}</p>
          <p>{`Identification of the protected work that you believe to be infringed. Please describe the work, and where possible include a copy or the location (e.g., URL) of an authorized version of the work.`}</p>
          <p>{`Identification of the material that you believe to be infringing and its location. Please describe the material and provide us with its URL or any other pertinent information that will allow us to locate the material.`}</p>
          <p>{`Your name, address, telephone number and (if available) e-mail address.`}</p>
          <p>{`A statement that you have a good faith belief that the complained of use of the materials is not authorized by the owner, its agent, or the law.`}</p>
          <p>{`A statement that the information that you have supplied is accurate, and indicating that "under penalty of perjury," you are the owner or are authorized to act on the owner's behalf.`}</p>
          <p>{`A signature or the electronic equivalent from the owner or authorized representative.`}</p>
        </Section>
        <Section>
          <Title value={`TERMINATION`} />
          <p>{`Company, in its sole discretion, may terminate your password, account (or any part thereof), use of any Service, or access to any Site, and remove and discard any User Content within any Service, for any reason, including, without limitation, for lack of use or if Company believes that you have violated or acted inconsistently with the letter or spirit of these Terms of Service. Company may also in its sole discretion and at any time discontinue providing any Service, or any part thereof, with or without notice. Company shall not be liable to you or any third party for any termination of your access to a Service. The provisions of these Terms of Use which by their nature should survive the termination of these terms shall survive such termination.`}</p>
        </Section>
        <Section>
          <Title value={`DISCLAIMERS`} />
          <p>{`YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT THE SITES AND THE SERVICES, INCLUDING ALL INFORMATION, PRODUCTS, AND OTHER CONTENT (INCLUDING THIRD PARTY INFORMATION AND USER CONTENT) ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS AND WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESSED OR IMPLIED. TO THE FULLEST EXTENT PERMISSABLE, PURSUANT TO APPLICABLE LAW, COMPANY DISCLAIMS ALL WARRANTIES, EXPRESSED OR IMPLIED, INCLUDING BUT NOT LIMITED TO: IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. COMPANY DOES NOT WARRANT THAT ANY PORTION OF ANY SITE OR ANY SERVICE WILL BE UNINTERRUPTED OR ERROR FREE, THAT DEFECTS WILL BE CORRECTED OR THAT ANY SITE OR ANY SERVICE THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL CODE. COMPANY DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE SITES OR THE SERVICES IN TERMS OF CORRECTNESS, ACCURACY, RELIABILITY OR OTHERWISE. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES IS DONE AT YOUR OWN DISCRETION AND RISK. SOME STATES MAY NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.`}</p>
          <p>{`We do not make any representation or warranty about whether the Sites or the Services are adequate to meet your privacy and security compliance obligations under the Health Insurance Portability and Accountability Act of 1996, the Health Information Technology for Economic and Clinical Health Act of 2009, and the security, privacy and other regulations related thereto (collectively, "HIPAA"), or any other standards, laws, rules, or regulations. You are responsible for assessing your privacy and security obligations and determining whether your use of the Sites or the Services complies.`}</p>
        </Section>
        <Section>
          <Title value={`LIMITATION OF LIABILITY`} />
          <p>{`IN NO EVENT SHALL COMPANY OR ANY OF ITS AFFILIATES, SUPPLIERS OR LICENSORS BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, CONSEQUENTIAL, PUNITIVE OR INCIDENTAL DAMAGES, LOST PROFITS OR THE COST TO PROCURE SUBSTITUTE GOODS AND SERVICES OR DAMAGES RESULTING FROM LOST DATA, LOST EMPLOYMENT OPPORTUNITY, OR BUSINESS INTERRUPTION RESULTING FROM OR ARISING UNDER OR IN CONNECTION WITH SERVICES OR THE USE OR ACCESS TO, OR THE INABILITY TO USE OR ACCESS, THE SITES AND/OR ANY DOCUMENT, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND WHETHER SUCH DAMAGES ARISE IN CONTRACT, NEGLIGENCE, TORT, UNDER STATUTE, IN EQUITY, AT LAW OR OTHERWISE. YOU ASSUME ALL RESPONSIBILITY AND RISK FOR YOUR USE OF THE SITES, THE INTERNET GENERALLY, AND THE INFORMATION THAT YOU POST, PROVIDE OR ACCESS AND FOR YOUR CONDUCT ON AND OFF THE SITES. IN NO EVENT SHALL OUR TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION, REGARDLESS OF THE FORM OF ACTION, EXCEED THE AMOUNT OF THE FEES PAID TO YOU DURING THE PREVIOUS TWELVE (12) MONTHS OR ONE THOUSAND U.S. DOLLARS (US $1,000), WHICHEVER IS GREATER.`}</p>
        </Section>
        <Section>
          <Title value={`ASSIGNMENT`} />
          <p>{`You may not assign or sublicense any of your rights or obligations hereunder, in whole or in part, by operation of law or change in control of you or otherwise, unless you obtain our prior written consent. We are free to assign all our rights and obligations hereunder, and upon such assignment we will be relieved of all liability under these Terms of Use, and all rights and obligations shall accrue to the successor or assignee. These Terms of Use are binding upon and inures to the benefit of our successors and assigns and your permitted successors and assigns.`}</p>
        </Section>
        <Section>
          <Title value={`FORCE MAJEURE`} />
          <p>{`If we are unable to perform any of our obligations hereunder (including without limitation loss of or failure to provide the Services) due to any event beyond our reasonable control, including without limitation, acts of God; acts of terrorism, war or the public enemy; flood; earthquake; tornado; storm; fire; civil disobedience; insurrections; riots; labor disputes; labor or material shortages; sabotage; restraint by court order or public authority (whether valid or invalid); acts or omissions of any government or agency thereof; power or telephone failures; or breakdown or malfunction of machines, transportation facilities or other equipment of any nature, then our performance shall be excused for the period of such event, but we will use our best commercial efforts to limit the duration of any such delay.`}</p>
        </Section>
        <Section>
          <Title value={`JURISDICTION`} />
          <p>{`This Agreement will be governed by and construed in accordance with the laws of the State of Massachusetts and the laws of the United States, without regard to principles of conflicts of laws. You expressly agree that exclusive jurisdiction for any dispute with Company, or in any way relating to your use of the Sites or the Services will be decided by a court of law sitting in Middlesex County, Massachusetts, or the federal judicial district in which that county is situated. EACH PARTY HEREBY IRREVOCABLY WAIVES ANY RIGHT TO TRIAL BY JURY IN CONNECTION WITH ANY ACTION OR LITIGATION IN ANY WAY RISING OUT OF, OR RELATED TO, THESE TERMS. In the event of such a dispute, the prevailing party shall pay all reasonable costs, including court costs and attorneys' fees, incurred by the non-prevailing party in the enforcement of the provisions of these Terms of Use. No action, regardless of form, arising out of these Terms of Use may be brought by you more than one year after the cause of action has occurred.`}</p>
        </Section>
        <Section>
          <Title value={`MISCELLANEOUS`} />
          <p>{`Titles and paragraph headings are for reference purposes only. These Terms contain the entire agreement of both parties and supersedes any and all previous oral and written communications and agreements with respect to the subject matter hereof. Any of our remedies are cumulative and not exclusive. No waiver of any breach of any provision of these Terms is a waiver of any prior, concurrent, or subsequent breach of the same or any other provision hereof and no waiver shall be effective unless made in writing. If any provision of these Terms is found to be invalid or otherwise unenforceable, the validity of any remaining portion shall not be affected and the remaining portions shall remain in full force and effect, as if invalid portions were deleted from these Terms.`}</p>
        </Section>
        <Section>
          <Title value={`CONTACT`} />
          <p>{`All comments, queries and requests relating to our policies and/or use of your information are welcomed and should be addressed as follows:`}</p>
          <br />
          <p><Bold>{`By mail:`}</Bold></p>
          <p>{`Trinity`}</p>
          <p>{`230 Third Avenue`}</p>
          <p>{`5th Floor`}</p>
          <p>{`Waltham, MA 02451-7528`}</p>
          <br />
          <p><Bold>{`By email: support@sentimentglobal.com`}</Bold></p>
          <p>{`Last updated: 1st of December, 2021`}</p>
        </Section>
      </div>
    </div>
  );
}

Sentiment.displayName = 'Legal.Terms.Sentiment';

export { Sentiment };