import { Link } from 'react-router-dom';
import { useSelectRespondentProps } from '@/containers/SurveyResponses/hooks/useSelectRespondentProps';
import { ResponsesViewAll } from '@presentation/ProjectSurveyResponses';
import { cx } from '@utils';
import type { HybridSurveyResponseUserId, RespondentProps } from '@/types';
import { RespondentBaseballCard as BaseballCard } from '@/components/BaseballCard';
import styles from './style/ResponseOptionUser.css';

type Props = {
  className?: string;
  renderItem?: (value: RespondentProps) => React.ReactNode;
  value?: string;
  userId: HybridSurveyResponseUserId;
};

export default function ResponseOptionUser(props: Props) {
  const selectRespondentProps = useSelectRespondentProps();
  const respondent = selectRespondentProps(props.userId);

  return (
    <div className={styles.respondent}>
      <Link
        className={styles.link}
        to={respondent.paths.response}
        onClick={respondent.onViewResponse}>
        <div className={cx(styles.response, props.className)}>
          {props.renderItem
            ? props.renderItem(respondent)
            : props.value
              ? <WithValue
                name={respondent.user.name}
                value={props.value}
                respondent={respondent} />
              : <WithoutValue
                name={respondent.user.name}
                respondent={respondent} />}
        </div>
      </Link>
    </div>
  );
}

function WithValue({ name, value, respondent }: WithValueProps) {
  return (
    <>
      <div className={styles.textblock}>
        <div className={styles.comment}>
          <div className={styles.quote}>
            {value}
          </div>
          <div className={styles.author}>
            <BaseballCard {...respondent}>
              {name}
            </BaseballCard>
          </div>
        </div>
      </div>
      <ResponsesViewAll />
    </>
  );
}

function WithoutValue({ name, respondent }: WithoutValueProps) {
  return (
    <>
      <div className={styles.name}>
        <BaseballCard {...respondent}>
          {name}
        </BaseballCard>
      </div>
      <ResponsesViewAll />
    </>
  );
}

type WithValueProps = {
  name: string;
  value: string;
  respondent: RespondentProps;
};

type WithoutValueProps = Omit<WithValueProps, 'value'>;

export { ResponseOptionUser };