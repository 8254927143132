import { useState } from 'react';
import type { GetMarkRange } from 'remirror';
import { TranscriptAutoCorrectContext } from './context';

export const TranscriptAutoCorrectContainer = (props: ChildrenProps) => {
  const [selectedMark, setMark] = useState<GetMarkRange>(null);

  return (
    <TranscriptAutoCorrectContext.Provider value={{ selectedMark, setMark }}>
      {props.children}
    </TranscriptAutoCorrectContext.Provider>
  );
};