import { useSelector } from 'react-redux';
import { SurveyTab } from '../SurveyTab';

const selectTabs = (state: Store.State) => {
  return [
    SurveyTab.Questions,
    SurveyTab.Logic,
    state.group.features.surveyQuotas && SurveyTab.Quotas,
    SurveyTab.Tagging,
    SurveyTab.Settings,
  ].filter(Boolean);
};

export const useProjectSurveyBuilderTabs = () => {
  return useSelector(selectTabs);
};