import { useState } from 'react';
import { path } from '@consts';
import { ButtonOutlined } from '@presentation';
import { cx } from '@utils';
import { Button } from '@/components/Button';
import bg1 from 'static/images/website/customer-research/product-feedback-mobile.png';
import bg2 from 'static/images/website/customer-research/product-feedback-tablet.png';
import bg3 from 'static/images/website/customer-research/product-feedback-desktop.png';
import styles from './style/Landing.css';

type Props = unknown;

export const Landing = (props: Props) => {
  const [loaded, setLoaded] = useState(false);

  const imgAlt = 'Streamline your research';

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.graphic}>
            <img
              alt={imgAlt}
              className={styles.imgS}
              src={bg1} />
            <img
              alt={imgAlt}
              className={styles.imgM}
              src={bg2} />
            <img
              alt={imgAlt}
              className={cx(styles.imgL, { [styles.ready]: loaded })}
              onLoad={() => setLoaded(true)}
              src={bg3} />
          </div>

          <div className={styles.info}>
            <h1 className={styles.title}>Streamline your research</h1>
            <div className={styles.subtitle}>Sentiment is used by many of the most sophisticated product and marketing teams to align their products with their market.</div>
            <div className={styles.btnset}>
              <ButtonOutlined
                className={styles.btn}
                color="blue"
                to={path.Website.Demo}
                variant="rounded">
                Demo
              </ButtonOutlined>
              <Button
                className={styles.btn}
                color="primary"
                to={path.Website.Pricing}>
                View Pricing
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Landing.displayName = 'CustomerResearch.Landing';