import { useCallback, useMemo } from 'react';
import type { SurveyQuestionType } from '@enums';
import { SurveyOptionType } from '@enums';
import type { ExclusiveOptionsQuestion as EO } from '@/types';
import { useSurveyFormQuestionAnswer } from '@/containers/SurveyForm';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import { CheckboxGroup } from '@/components/Checkbox';
import type { CheckboxGroupOnItemToggle, CheckboxGroupOnInputChange, CheckboxGroupItem } from '@/components/Checkbox/interfaces';
import { buildExclusivityMap } from './utils.exclusive-options';

type Props = {
  className?: string;
  question: EO.FormQuestion;
  sectionId: EO.OptionSection['identifier'];
};

function isExclusive(option: EO.FormOption) {
  return option.type === SurveyOptionType.NoneOfTheAbove ||
    option.metadata.isExclusive;
}

export default function ExclusiveOptionsMultiselect({ className, question, sectionId }: Props) {

  const [answer, setAnswer] = useSurveyFormQuestionAnswer<SurveyQuestionType.ExclusiveOptions>();

  const exclusivityMap = useMemo(() => buildExclusivityMap(question), [question]);

  const itemsMapped = useMemo(() => {
    return question.options
      .filter(o => o.metadata.sectionId === sectionId)
      .map<CheckboxGroupItem>(o => ({
      allowText: o.metadata.isOpenEnded,
      id: o.id,
      label: parseSurveyRichText(o.value),
      text: answer.items.find(ao => ao.optionId === o.id)?.text,
      disabled: exclusivityMap[o.base.identifier]?.some(v => answer?.items.some(i => i.optionId == v)),
    }));
  }, [answer, question.options, sectionId, exclusivityMap]);

  const selected =
    useMemo(() => ({
      items: answer.items,
      ids: answer.items.map(i => i.optionId),
    }), [answer.items]);

  const handleToggle: CheckboxGroupOnItemToggle
    = useCallback(item => {
      const optionId = +item.id;

      const option = question.options.find(f => f.id === optionId);

      if (selected.ids.includes(optionId)) {
        setAnswer({
          items: selected.items.filter(i => i.optionId !== optionId),
        });
      } else {
        if (isExclusive(option)) {
          setAnswer({
            items: [{ optionId }],
          });
        } else {
          setAnswer({
            items: [
              ...selected.items
                .filter(f => {
                  const o = question.options.find(ff => f.optionId === ff.id);
                  return !isExclusive(o);
                }),
              { optionId },
            ],
          });
        }
      }
    }, [
      question.options,
      selected,
      setAnswer,
    ]);

  const handleInputChange: CheckboxGroupOnInputChange = useCallback(item => {
    setAnswer({
      items: [
        ...selected.items.filter(i => i.optionId !== +item.id),
        { optionId: +item.id, text: item.text },
      ],
    });
  }, [selected.items, setAnswer]);

  return (
    <CheckboxGroup
      className={className}
      checkedItems={selected.ids}
      items={itemsMapped}
      onToggle={handleToggle}
      onInputChange={handleInputChange} />
  );
}

export { ExclusiveOptionsMultiselect };