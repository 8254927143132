import type { SurveyQuestionType } from '@enums';
import { convertToSurveyRichText } from '@/containers/Survey/utils';
import type { MaxDifferenceQuestion } from '@/types';
import type { Validation } from '../interfaces';
import { assertValidOptions } from './validation.options';
import { assertHasValue, returnAssertFailure } from './validation.base';

export const DefaultOptions = 5;
export const MinOptions = 5;
export const MaxOptions = 30;

export const MinOptionsPerSet = 2;
export const MaxOptionsPerSet = 10;

export const MinSets = 1;
export const MaxSets = 30;

export const OptionsOccurencesRoughly = 3;

export const DefaultSettings: MaxDifferenceQuestion.Settings = {
  optionsPerSet: 3,
  sets: 5,
  label: {
    left: convertToSurveyRichText('Least'),
    right: convertToSurveyRichText('Most'),
  },
};

export function calcOptionsPerSetLimits(item: MaxDifferenceQuestion.Question) {
  if (item.settings.sets === 1) {
    return {
      min: item.options.length,
      max: item.options.length,
    };
  }

  return {
    min: MinOptionsPerSet,
    max: Math.max(Math.min(item.options.length, MaxOptionsPerSet), MinOptionsPerSet),
  };
}

export function calcSetLimits(item: MaxDifferenceQuestion.Question) {
  return {
    min: MinSets,
    max: MaxSets,
  };
}

export function suggestSets(item: MaxDifferenceQuestion.Question) {
  const value = Math.floor(OptionsOccurencesRoughly * (item.options.length / item.settings.optionsPerSet));

  return Math.max(Math.min(value, MaxSets), MinSets);
}

export function validateQuestion(question: MaxDifferenceQuestion.Question): Validation.ValidateQuestionResult<SurveyQuestionType.MaxDifference> {
  const hasValue = assertHasValue(question);
  if (hasValue.success === false) {
    return returnAssertFailure(hasValue, question);
  }

  const hasValidOptions = assertValidOptions(question.options, MinOptions, MaxOptions);
  if (hasValidOptions.success === false) {
    return returnAssertFailure(hasValidOptions, question);
  }

  return {
    success: true,
    result: {
      question,
    },
  };
}

