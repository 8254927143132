import { useCallback } from 'react';
import { useCopyToClipboard } from '@utils';
import { useChainedCommands } from './useCommands';
import { useHelpers } from './useHelpers';

export const useCopyHighlightedText = () => {
  const commands = useChainedCommands();
  const { getFocusedHighlight } = useHelpers();
  const copyToClipboard = useCopyToClipboard();

  return useCallback(async () => {
    const highlight = getFocusedHighlight();
    await copyToClipboard(highlight.text);
    commands.blurHighlight().run();
  }, [commands, copyToClipboard, getFocusedHighlight]);
};