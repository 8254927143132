import { useCallback, Fragment } from 'react';
import { SurveyLogicExpressionNode, SurveyLogicExpression } from '@/types/survey.logic.expression';
import { SurveyLogic } from '@/types';
import { Condition } from './Logic.Condition';
import styles from './style/Logic.Item.css';

type Props = {
  conditions: SurveyLogic.ItemCondition[];
  expression: SurveyLogic.SurveyLogicExpression.RootNode;
};

export const CustomExpression = ({ conditions, expression }: Props) => {

  const renderNode = useCallback((node: SurveyLogicExpression.ParagraphContent) => {
    switch (node.type) {
      case SurveyLogicExpressionNode.ClosingParenthesis:
        return <span className={styles.paren}>)</span>;
      case SurveyLogicExpressionNode.OpeningParenthesis:
        return <span className={styles.paren}>(</span>;
      case SurveyLogicExpressionNode.Condition: {
        const condition = conditions.find(f => f.identifier === node.attrs.identifier);
        return <Condition condition={condition} />;
      }
      case SurveyLogicExpressionNode.LogicalOperator:
        return <span className={styles.operator}>{node.attrs.operator}</span>;

    }
  }, [conditions]);

  return (
    <div className={styles.expression}>
      {expression.content[0].content.map((node, i) => (
        <Fragment key={i}>
          {renderNode(node)}
        </Fragment>
      ))}
    </div>
  );
};