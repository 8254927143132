import { useEffect, useMemo, useState, ContextType } from 'react';
import { SurveyContext } from '@containers/Survey/Context';
import { useProjectOrLatestChild } from '@containers/GroupProject/hooks/useProjectState';
import { SurveyResponsesFilterDataContext, SurveyResponsesFilterDataContextValue } from '@containers/GroupProject/Context';
import { useFetchAllProjectSurveyResponses } from './hooks/useFetchAllProjectSurveyResponses';
import SurveyStarredAnswersContainer from './SurveyStarredAnswersContainer';
import { ProjectSurveyResponsesLoadingContext, ProjectSurveyResponsesContext } from './Context';
import SurveyRespondentContainer from './SurveyRespondentContainer';
import { useProjectSurveyResponsesState } from './hooks';

export const SurveyResponsesDataContainer = (props: ChildrenProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const project = useProjectOrLatestChild();
  const { query } = useProjectSurveyResponsesState();

  //TODO: use the existing filter and surveyId to further filter data
  const responsesQuery = useFetchAllProjectSurveyResponses({
    projectId: project.id,
  }, {
    enabled: !!query.surveyId,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setLoading(responsesQuery.isLoading || !responsesQuery.data);
  }, [
    responsesQuery.isLoading,
    responsesQuery.data,
  ]);

  const responses = useMemo(() => responsesQuery?.data?.[query.surveyId] || null, [responsesQuery.data, query.surveyId]);
  const surveys = useMemo(() => responsesQuery.data ? Object.values(responsesQuery.data).map(x => x.survey) : [], [responsesQuery.data]);

  const filterDataContextValue: SurveyResponsesFilterDataContextValue = useMemo(() => {
    const allQuestions: SurveyResponsesFilterDataContextValue['allQuestions'] = {};
    const allResponses: SurveyResponsesFilterDataContextValue['allResponses'] = {};

    if (responsesQuery.data) {
      Object.entries(responsesQuery.data).forEach(([k, v]) => {
        const surveyId = +k;
        allQuestions[surveyId] = v.survey.questions;
        allResponses[surveyId] = v.items;
      });
    }

    return {
      allQuestions,
      allResponses,
      surveyData: surveys,
      isLoading: responsesQuery.isInitialLoading,
    };

  }, [responsesQuery.data, surveys, responsesQuery.isInitialLoading]);

  return (
    <ProjectSurveyResponsesLoadingContext.Provider value={loading}>
      <ProjectSurveyResponsesContext.Provider value={responses?.items}>
        <SurveyResponsesFilterDataContext.Provider value={filterDataContextValue}>
          <SurveyContext.Provider value={responses?.survey || null}>
            <SurveyStarredAnswersContainer initial={responses?.starred || {}}>
              <SurveyRespondentContainer data={responses?.respondents || { values: {} }}>
                {props.children}
              </SurveyRespondentContainer>
            </SurveyStarredAnswersContainer>
          </SurveyContext.Provider>
        </SurveyResponsesFilterDataContext.Provider>
      </ProjectSurveyResponsesContext.Provider>
    </ProjectSurveyResponsesLoadingContext.Provider>
  );
};

export default SurveyResponsesDataContainer;