import {
  ExternalSurveyConfigurationContext,
  ExternalSurveyConfigurationRefetchContext,
} from '@containers/Project.ExternalSurvey';
import { useProjectOverview } from '$admin/containers/Project.Overview/hooks';
import { AdminOffPlatformSurveyMutationContainer } from './Mutations.Container';

// Note: This is used for the admin project settings page

export const OffPlatformSurveyContainer = (props: ChildrenProps) => {

  const [, info, query] = useProjectOverview();
  const projectId = info.project.id;

  if (!info.externalSurveyConfiguration?.id) {
    return null;
  }

  return (
    <AdminOffPlatformSurveyMutationContainer projectId={projectId}>
      <ExternalSurveyConfigurationRefetchContext.Provider value={query.refetch}>
        <ExternalSurveyConfigurationContext.Provider value={info.externalSurveyConfiguration}>
          {props.children}
        </ExternalSurveyConfigurationContext.Provider>
      </ExternalSurveyConfigurationRefetchContext.Provider>
    </AdminOffPlatformSurveyMutationContainer>
  );
};