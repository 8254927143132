import { Website } from '@consts/path';
import * as core from './core';

const landing = {
  button: {
    cta: core.main.landing.button.demo,
    login: core.main.landing.button.login,
  },
  text: {
    copy: core.main.landing.text.copy,
    title: core.main.landing.text.title,
  },
};

const banner = {
  button: {
    cta: {
      text: `Learn how it works`,
      to: `${Website.Demo}#`,
    },
  },
};

const benefits = {
  collection: {
    benefits: [{
      image: {
        icon: core.main.benefits.image.value,
      },
      text: {
        title: `Value`,
        copy: `Our platform is open for you to access experts with no upfront cost, pay-as-you go as you conduct research.`,
      },
    }, {
      image: {
        icon: core.main.benefits.image.quality,
      },
      text: {
        title: `Quality`,
        copy: `Browse from tens of thousands of advisors carefully indexed for their expertise, all have opted into Sentiment to help you.`,
      },
    }, {
      image: {
        icon: core.main.benefits.image.efficiency,
      },
      text: {
        title: `Service`,
        copy: `Utilize a best in class suite of digital research tools to help you create the best client deliverables.`,
      },
    }],
  },
  text: {
    title: `We help consulting teams create superior client deliverables and generate more profits from projects`,
  },
};

const funnel = {
  button: {
    cta: core.main.funnel.button.demo,
  },
  text: {
    cta: core.main.funnel.text.demo,
  },
};

export const content = {
  main: {
    banner,
    benefits,
    landing,
    funnel,
    press: core.main.press,
  },
  nav: {
    button: {
      cta: core.nav.button.demo,
      login: core.ui.button.login,
      signup: core.ui.button.signup,
    },
  },
  signup: null,
};