import { memo } from 'react';
import { X } from 'react-feather';
import styled from '@emotion/styled';
import { Constants } from '@/brand-insights/components/Theme';

type Props = {
  className?: string;
  onClick: () => void;
};

export const Close = memo(({ className, onClick }: Props) => {
  return (
    <Root
      className={className}
      onClick={onClick}>
      <StyledX
        size={30}
        strokeWidth={3} />
    </Root>
  );
});

const Root = styled.div`
  box-sizing: border-box;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${props => props.theme.palette.gray.light2};

  box-shadow: 3px 3px 3px 3px rgb(60 64 67 / 24%);

  padding: 8px;
  height: ${Constants.Modal.CloseButtonSize}px;
  width: ${Constants.Modal.CloseButtonSize}px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

const StyledX = styled(X)`
  color: ${props => props.theme.palette.black.light1};
`;