import { cx } from '@utils';
import { FeedItemComment } from '@/types/comments';
import { LikeSection } from '@/components/LikeSection';
import { Button } from './Comment.Button';
import styles from './style/Comment.css';

type Props = {
  className?:   string;
  item:         FeedItemComment;
  onClickReply: () => void;
};

export const Actions = (props: Props) => {

  return (
    <div className={cx(styles.actions, props.className)}>
      <LikeSection likeDescription={props.item.likeDescription} />
      <Button onClick={props.onClickReply} />
    </div>
  );
};

Actions.displayName = 'Comment.Actions';