/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import type { LocationDescriptor } from 'history';
import type { Middleware } from '@reduxjs/toolkit';
import { path } from '@consts';
import { history } from '@services/history';
import * as types from '@store/action-types';
import { isPlatformUser } from '@utils';

const routing: Middleware = store => next => action => {
  if (action.type === types.UPDATE_LOCATION) {
    if (action.redirect !== history.location.pathname) {
      return history.replace(action.redirect as LocationDescriptor);
    }
  }

  const user = store.getState()?.user as Store.User;
  const pathname = isPlatformUser(user)
    ? path.Website.PlatformLogin
    : path.Website.Login;

  next(action);

  if (action.type === types.USER_LOGOUT) {
    if (action.wasActionLogout) {
      history.replace(pathname);
    }
  }
};

const apply = (middleware: Middleware[]) => {
  return middleware.concat(routing);
};

export default {
  apply,
  middleware: routing,
};