import { useMemo } from 'react';
import type { SerializedStyles } from '@emotion/react';
import { useTheme } from '@mui/material/styles';

export { useTheme };

export const useThemedStyles = <T extends Styles>(styles: T) => {
  const theme = useTheme<Theme>();

  return useMemo(() => {
    const resolved = {} as ResolvedStyles<T>;

    for (const key in styles) {
      const style = styles[key];
      if (isThemedProvider(style)) {
        resolved[key] = style(theme);
      } else if (isStandardProvider(style)) {
        resolved[key] = style;
      }
    }

    return resolved;
  }, [styles, theme]);
};

function isThemedProvider(provider: StyleProviders): provider is (theme: Theme) => SerializedStyles {
  return typeof provider === 'function';
}

function isStandardProvider(provider: StyleProviders): provider is SerializedStyles {
  return typeof provider === 'object';
}

type StyleProviders =
  | SerializedStyles
  | ((theme: Theme) => SerializedStyles);

type Styles = {
  [key: string]: StyleProviders;
};

type ResolvedStyles<T> = {
  [K in keyof T]: SerializedStyles;
};