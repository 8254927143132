import { path } from '@consts';
import { ButtonOutlined } from '@presentation';
import styles from './style/BannerFooter.css';

type Props = unknown;

export const BannerFooter = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h3 className={styles.header}>
          Want to get in touch?
        </h3>
        <div className={styles.main}>
          <ButtonOutlined
            className={styles.btn}
            color="blue"
            to={path.Website.Contact}
            variant="rounded">
            Contact Us
          </ButtonOutlined>
        </div>
      </div>
    </div>
  );
};

BannerFooter.displayName = 'Careers.BannerFooter';