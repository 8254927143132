import { useMemo } from 'react';
import { useRepeatQuestionResponsesData, useRepeatSurveyResponsesState } from '@containers/RepeatSurveyResponses/hooks';
import { RepeatSurvey } from '@containers/RepeatSurveyResponses/interfaces';

export const useFilteredRankingOptions = () => {
  const { query } = useRepeatSurveyResponsesState<RepeatSurvey.QueryState.Ranking>();
  const { question } = useRepeatQuestionResponsesData();

  const options = useMemo(() => {
    return query.optionIds
      ? question.options
          .filter(f => query.optionIds.includes(f.base.id))
      : question.options;

  }, [
    query.optionIds,
    question.options,
  ]);

  return options;
};

export default useFilteredRankingOptions;