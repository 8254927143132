import { useContext } from 'react';
import { SearchLoadingTranscriptContext, SearchResultsTranscriptContext } from '@/containers/WorkspaceGlobalSearch/Context';
import { formatPlurality } from '@utils';
import Table from './Search.Table.Transcripts';
import styles from './style/Tab.css';

const max = 50;

export default function SearchTabTranscripts() {
  const transcripts = useContext(SearchResultsTranscriptContext);
  const loading = useContext(SearchLoadingTranscriptContext);

  const isLoading = loading.untouched && loading.loading;

  const data = transcripts?.items ?? [];
  const countText = !isLoading
    ? `(${getCountLabel(transcripts?.total ?? 0, 'Transcript')})`
    : null;
  const pagination = data.length > 25;

  return (
    <div className={styles.root}>
      <Table
        data={data}
        countText={countText}
        pagination={pagination}
        pageSize={25}
        isLoading={isLoading} />
    </div>
  );
}

function getCountLabel(total: number, label: string) {
  return total > max
    ? `+${max} ${formatPlurality(label, max)}`
    : `${total} ${formatPlurality(label, total)}`;
}

export { SearchTabTranscripts };