import type { ImageMarkupQuestion } from '@/types';

export type FillType = {
  stroke: string;
  fill: string;
  structureType: ImageMarkupQuestion.StructuredMarkupType;
  heatmap: string;
};

export const STRUCTURED_TYPES: Record<ImageMarkupQuestion.StructuredMarkupType, FillType> = {
  good: {
    stroke: 'green',
    fill: 'hsl(120deg 100% 50% / 30%)',
    structureType: 'good',
    heatmap: '#01b051',
  },
  neutral: {
    stroke: '#e9d700',
    fill: 'hsl(60 100% 50% / 30%)',
    structureType: 'neutral',
    heatmap: '#ffeb00',
  },
  bad: {
    stroke: 'red',
    fill: 'hsla(0, 100%, 50%, 0.3)',
    structureType: 'bad',
    heatmap: '#c00101',
  },
};

export const FILL_TYPES: FillType[] = [STRUCTURED_TYPES.good, STRUCTURED_TYPES.neutral, STRUCTURED_TYPES.bad];

export const COLOR_OPTIONS = ['#FAC712', '#E66800', '#FF0000', '#9410AC', '#0070C0', '#59CDFF', '#00BFA5', '#43A047', '#D9D9D9', '#A6A6A6', '#3B3838', '#000000'];
export const DEFAULT_COLOR = COLOR_OPTIONS[4];