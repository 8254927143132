import { useReducer } from 'react';
import { TopicTagsContext } from './Context';
import { TopicTags } from './interfaces';

type Props = {
  children: React.ReactNode;
};

export const TopicTagsContainer = (props: Props) => {
  const state = useReducer(reducer, initial);

  return (
    <TopicTagsContext.Provider value={state}>
      {props.children}
    </TopicTagsContext.Provider>
  );
};

TopicTagsContainer.displayName = 'PostCreation.Topic.Tags.Container';

function reducer(state: TopicTags.State, action: TopicTags.Action) {
  switch (action.type) {
    case 'add-topic':
      return {
        editing: false,
        items: [ ...state.items, action.item ],
      };

    case 'add-topics':
      return {
        editing: false,
        items: [...new Set([...state.items, ...action.items])],
      };

    case 'remove-topic':
      return {
        editing: state.editing,
        items: state.items.filter(f => f.id !== action.topicId),
      };

    case 'toggle-topic-editing':
      return {
        editing: !state.editing,
        items: state.items,
      };
  }
}

const initial = {
  editing: false,
  items: [],
};