import { useContext } from 'react';
import { MessageReviewStateContext } from '@containers/ComplianceReview/Context';

const useMessageReviewState = () => {
  const state = useContext(MessageReviewStateContext);

  return state;
};

export { useMessageReviewState };
export default useMessageReviewState;