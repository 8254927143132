import { memo } from 'react';
import styles from './style/Notifications.Empty.css';

export const EmptyNotifications = memo(() => (
  <div className={styles.root}>
    <div className={styles.wrap}>
      <div className={styles.content}>No new notifications</div>
    </div>
  </div>
));

export default EmptyNotifications;