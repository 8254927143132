import * as Publisher from './Publisher';
import { Body } from './Text.Compose.Body';
import { PublishButton } from './Text.Compose.PublishButton';
import { TopicTags } from './Topics.Tags';
import styles from './style/Frame.Text.Compose.css';

type Props = unknown;

export const Compose = (props: Props) => {
  return (
    <>
      <Publisher.Header>
        Create Post
      </Publisher.Header>

      <Body />

      <TopicTags className={styles.topics} />

      <Publisher.Footer>
        <PublishButton />
      </Publisher.Footer>
    </>
  );
};

Compose.displayName = 'PostCreation.Frame.Text.Compose';