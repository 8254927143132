import { UseDownloaderOptions, useQueryDownloader } from '@containers/QueryDownloader';
import * as api from '$admin/api';
import * as API from '$admin/api/interfaces';

type Params = Omit<API.Search.SearchMembersExport.Request, 'export'>;

export const useDownloadMembersSearchData = (params: Params, options: UseDownloaderOptions<API.Search.SearchMembersExport.Response> = {}) => {
  return useQueryDownloader({
    queryKey: [
      `post:admin/search/members`, {
        active: params.active,
        export: true,
        listId: params.listId,
      },
    ],
    queryFn: () => {
      return api.search.searchMembersExport({
        active: params.active,
        export: true,
        listId: params.listId,
      });
    },
    ...options,
    meta: {
      ...options.meta,
      downloader: true,
    },
    downloaderOptions: {
      onResponse: res => ({
        filename: res.filename,
        type: 'blob',
        value: res.blob,
      }),
    },
  });
};