import { useCallback, useEffect } from 'react';
import { UserSocketEvent } from '@/enums';
import { User } from '../interfaces';
import { user as $user } from '../user';

export const useUserSocketEvent = <T extends UserSocketEvent>(event: T, fn: User.RegisterHandler<T>, enabled = true) => {

  useEffect(() => {
    if (enabled) {
      $user.on(event, fn);
      return () => {
        $user.off(event, fn);
      };
    }
  }, [enabled, event, fn]);
};
