import { useEffect, useContext, useMemo } from 'react';
import { List } from 'react-feather';
import { TableEmpty } from '@presentation';
import * as $api from '@api';
import { useProjectOrLatestChild } from '@containers/GroupProject/hooks';
import { EntityQueryParamsContext } from './Context';
import { useBoostingModal } from './Boosting.Modal';
import { useBoostDismiss } from './hooks';

type Props = unknown;

export const EntitiesTableEmpty = (props: Props) => {
  const [open, Modal] = useBoostingModal();
  const project = useProjectOrLatestChild();
  const { isDismissed } = useBoostDismiss();

  const qpCtx = useContext(EntityQueryParamsContext);

  const isFiltering = useMemo(() => !!qpCtx?.params?.nameFilter, [qpCtx?.params?.nameFilter]);

  const projectId = project?.id;
  const projectSlug = project?.slug;

  useEffect(() => {
    if (!isDismissed && projectId) {
      $api.projects.entities.isProjectEntityBoostLocked({ projectId }).then(res => {
        if (!res.locked) {
          open();
        }
      });
    }
  }, [open, isDismissed, projectId]);

  return (
    <>
      <TableEmpty
        Icon={() => <List size={120} />}
        message={copy.message}
        note={isFiltering ? copy.filtersActive : copy.note} />
      <Modal slug={projectSlug} />
    </>
  );
};

EntitiesTableEmpty.displayName = 'Project.Entities.Table.Empty';

const copy = {
  message: `No entities available.`,
  note: `Import a series of entities to be boosted in interview transcripts or conduct interviews to see entities for the project.`,
  filtersActive: `Change your filters to see more entities.`,
};