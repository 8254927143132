import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import type { Location, Action } from 'history';
import { useBeforeUnload, useToggle } from '@utils';
import type { RouteLeavingGuardState } from './interfaces';

type Props = {
  children: (state: RouteLeavingGuardState) => React.ReactNode;
  block: boolean;
  checkLocation?: (l: Location, a: Action) => boolean;
};

export const RouteLeavingGuard = ({ block, checkLocation, children }: Props) => {
  const [showPrompt, togglePrompt] = useToggle(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const pendingLocation = useRef<Location>();
  const history = useHistory();

  useBeforeUnload({ block });

  useEffect(() => {
    if (confirmedNavigation) {
      setConfirmedNavigation(false);
      togglePrompt();
      history.push(pendingLocation.current);
    }
  }, [
    confirmedNavigation,
    history,
    togglePrompt,
  ]);

  const confirmNavigation = useCallback(() => {
    setConfirmedNavigation(true);
  }, []);

  const handleBlockNavigation = useCallback((location: Location, action: Action) => {
    const resolvedBlock = checkLocation
      ? block && checkLocation(location, action)
      : block;

    if (!confirmedNavigation && resolvedBlock) {
      togglePrompt();
      pendingLocation.current = location;

      return false;
    }

    return true;
  }, [
    block,
    checkLocation,
    confirmedNavigation,
    togglePrompt,
  ]);

  const guardState = useMemo(() => ({
    open: showPrompt,
    closePrompt: togglePrompt,
    pendingLocation,
    confirmNavigation,
  }), [
    showPrompt,
    pendingLocation,
    togglePrompt,
    confirmNavigation,
  ]);

  return (
    <>
      <Prompt
        when={block}
        message={handleBlockNavigation} />
      {children(guardState)}
    </>
  );
};

export default RouteLeavingGuard;