import { useCallback } from 'react';
import { usePersonaResearchData } from '@containers/SurveyBuilder/hooks';
import { SurveyTemplate } from '@/types/survey.templates';
import { minRoles, maxRoles } from '../utils';
import { useCanSaveList, useListElements } from './list-elements';

export const useCanSaveTargetRoles = (items: SurveyTemplate.TargetRole[]) => {

  return useCanSaveList({
    items: items,
    minItems: minRoles,
    maxItems: maxRoles,
  });
};

export const useTemplateRoles = () => {
  const [templateData, dispatch] = usePersonaResearchData();

  const {
    canAdd: canAddRole,
    canRemove: canRemoveRole,
    items: roles,
    onAddItem: onAddRole,
    onRemoveItem: onRemoveRole,
    onUpdateItem: onUpdateRole,
  } = useListElements({
    min: minRoles,
    max: maxRoles,
    initial: templateData.roles,
  });

  const onSubmit = useCallback(() => {
    dispatch({
      type: 'template-target-roles-updated',
      value: roles,
    });
  }, [dispatch, roles]);

  return {
    canAddRole,
    canRemoveRole,
    onAddRole,
    onRemoveRole,
    onUpdateRole,
    onSubmit,
    roles,
  };
};