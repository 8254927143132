import { useContext } from 'react';
import { CallDetailsContext } from './Context';
import * as Layout from './Layout';
import styles from './style/Field.css';

type Props = unknown;

export const Title = (props: Props) => {
  const ctx = useContext(CallDetailsContext);

  return (
    <div className={styles.row}>
      <Layout.Label>
        Conference Title
      </Layout.Label>
      <span>{ctx.query.data.call.title}</span>
    </div>
  );
};

Title.displayName = 'Call.Main.Field.Title';