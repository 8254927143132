import { useCallback, useState } from 'react';
import { differenceInMinutes } from 'date-fns';
import { cx, useInterval } from '@utils';
import { ConferenceSessionDurationProps } from './interfaces';
import styles from './style/Duration.css';

type Props =
    ConferenceSessionDurationProps;

export default function Duration(props: Props) {
  const [totalMinutes, setTotalMinutes] = useState<number>(0);

  const updateDuration = useCallback(() => {
    const totalMinutes = differenceInMinutes(Date.now(), props.startedOn);
    if (totalMinutes > 0) {
      setTotalMinutes(totalMinutes);
    }
  }, [props.startedOn, setTotalMinutes]);

  useInterval(updateDuration, 1000, true);

  const hours = Math.floor(totalMinutes / 60).toFixed().padStart(2, '0');
  const minutes = (totalMinutes % 60).toFixed().padStart(2, '0');

  return (
    <div className={cx(styles.root, props.className)}>
      <div className={styles.label}>Duration:</div>
      <div className={styles.duration}>{hours}:{minutes}</div>
    </div>
  );
}