import { transformMessageThread } from '@transformers';
import { Messages } from '$admin/api/interfaces';

export const transformMessageThreads = (data: Messages.ThreadsByChannelId<string>) => {
  return Object.keys(data).reduce<Messages.ThreadsByChannelId>((acc, channelId) => {
    const item = data[+channelId];

    return {
      ...acc,
      [channelId]: transformMessageThread(item),
    };
  }, {});
};

export { transformMessageItem } from '@transformers';