import { extend } from '@enums/extend';

export enum TranscriptionRevisionStatus {
  InQueue    = 1,
  InProgress = 2,
  Completed  = 3,
}

const map = new Map([
  [TranscriptionRevisionStatus.InQueue,    { name: 'In-Queue' }],
  [TranscriptionRevisionStatus.InProgress, { name: 'In-Progress' }],
  [TranscriptionRevisionStatus.Completed,  { name: 'Completed' }],
]);

export default {
  TranscriptionRevisionStatus: extend(map),
};