import { useCallback } from 'react';
import type { MatrixSliderQuestion as MSQ } from '@/types';
import { useSurveyFormQuestionAnswer } from '@/containers/SurveyForm';
import type { SurveyQuestionType } from '@/enums';
import { SurveyMatrixSliders } from '@/components/SurveyMatrixSliders';
import type { MatrixSliderQuestionProps } from './interfaces';
import { Rationale } from './Rationale';

type Props =
  MatrixSliderQuestionProps;

type AnswerUpdater = (old: MSQ.RespondentAnswer.Item) => MSQ.RespondentAnswer.Item;

export const MatrixSliderQuestion = ({ item }: Props) => {

  const [answer, setAnswer] = useSurveyFormQuestionAnswer<SurveyQuestionType.Sliders>();

  const updateRowAnswer = useCallback((rowId: number, updater: AnswerUpdater) => {
    const items = answer.items.reduce<MSQ.RespondentAnswer.Item[]>((acc, x) => {
      if (x.matrixRowId === rowId) {
        return acc.concat(updater(x));
      }

      return acc.concat(x);
    }, []);

    setAnswer({ items });

  }, [setAnswer, answer.items]);

  const updateRowAnswerValue = useCallback((rowId: number) => (value: number) => {
    return updateRowAnswer(rowId, (old: MSQ.RespondentAnswer.Item) => {
      return old
        ? { ...old, value }
        : { matrixRowId: rowId, rowText: '', value };
    });
  }, [updateRowAnswer]);

  const updateRowText = useCallback((rowId: number) => (rowText: string) => {
    return updateRowAnswer(rowId, (old: MSQ.RespondentAnswer.Item) => {
      return old
        ? { ...old, rowText }
        : { matrixRowId: rowId, rowText, value: null };
    });
  }, [updateRowAnswer]);

  return (
    <>
      <SurveyMatrixSliders
        answer={answer}
        question={item as MSQ.FormQuestion}
        rows={item.matrixRows}
        updateRowText={updateRowText}
        updateRowAnswer={updateRowAnswerValue} />
      <Rationale settings={item.settings.rationale} />
    </>
  );
};

export default MatrixSliderQuestion;