import { useCallback, useMemo } from 'react';
import useSearchFiltersQuery from '@/containers/WorkspaceGlobalSearch/hooks/useSearchFilterQuery';
import type { WorkspaceSearchFull } from '@/types';
import { SearchMainFiltersFile } from './Search.Main.Filters.File';
import { SearchMainFiltersKeyword } from './Search.Main.Filters.Keyword';
import { SearchMainFiltersTag } from './Search.Main.Filters.Tag';
import { useReducedFilterState } from './hooks';
import styles from './style/Main.Filters.css';

export const SearchMainFilters = () => {
  const [, setQuery] = useSearchFiltersQuery();
  const filters = useReducedFilterState();

  const handleClear = useCallback(() => {
    setQuery({}, 'push');
  }, [setQuery]);

  const showFileFilters = useMemo(() => {
    const tabs: WorkspaceSearchFull.SearchTab[] = ['all', 'files'];
    return tabs.includes(filters.tab);
  }, [filters.tab]);

  const showTagFilters = useMemo(() => {
    const tabs: WorkspaceSearchFull.SearchTab[] = ['all', 'transcripts', 'files'];
    return tabs.includes(filters.tab);
  }, [filters.tab]);

  const showClear = useMemo(() => {
    let clearables = 0;
    if (filters.tab !== 'all') clearables++;
    if (filters.keywords?.length) clearables++;
    if (showFileFilters && filters.filetypes?.length) clearables++;
    if (showTagFilters && filters.tags?.length) clearables++;
    return clearables > 1;
  }, [
    showFileFilters,
    showTagFilters,
    filters.tab,
    filters.keywords,
    filters.filetypes,
    filters.tags,
  ]);

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.title}>Search Filters</div>
        {showClear && <div className={styles.clear} onClick={handleClear}>Clear</div>}
      </div>
      <div className={styles.sections}>
        <SearchMainFiltersKeyword />
        {showTagFilters && <SearchMainFiltersTag />}
        {showFileFilters && <SearchMainFiltersFile />}
      </div>
    </div>
  );
};