import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { getLocationFor, useStepper } from '@utils';
import { useTagsOnboardingDismiss } from '@/components/Project.Tags/hooks';
import * as Onboarding from './Onboarding';
import { OnboardingStepperContext } from './Context';

type Props = IProjectId;

export const ProjectTagsOnboardingStepper = ({ projectId }: Props) => {
  const [Screen, actions, index] = useStepper(Screens);

  const onboarding = useTagsOnboardingDismiss();

  const history = useHistory();

  const redirect = useCallback(() => {
    onboarding.dismiss();
    history.replace(getLocationFor.project.tags({ projectId }));
  }, [
    history,
    projectId,
    onboarding,
  ]);

  const back = useCallback(() => {
    actions.back();
  }, [
    actions,
  ]);

  const next = useCallback(() => {
    if (index === StepsLength - 1) {
      redirect();
    } else {
      actions.next();
    }
  }, [
    actions,
    index,
    redirect,
  ]);

  const value = {
    back,
    next,
    projectId,
    redirect,
  };

  return (
    <OnboardingStepperContext.Provider value={value}>
      <Screen />
    </OnboardingStepperContext.Provider>
  );
};

ProjectTagsOnboardingStepper.displayName = 'Project.Tags.Onboarding.Stepper';

const Screens = [
  Onboarding.SubtypeSelection,
  Onboarding.Categories,
  Onboarding.Subcategories,
  Onboarding.ReviewSetup,
];

const StepsLength = Screens.length;