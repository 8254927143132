import { format } from 'date-fns';
import { useProjectOrLatestChild } from '@containers/GroupProject/hooks';
import { ProjectSummaryContainer } from '@containers/ProjectSummary';
import { ProjectSummaryContainer as InsightsContainer } from '@containers/ConferenceTag.Summary';
import * as Insights from './Insights';
import Summary from './Summary';
import { ProjectCompletedCalls } from './CompletedCalls';
import styles from './style/ProjectSummary.css';

export const ProjectSummary = () => {

  const project = useProjectOrLatestChild();

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>

        <div className={styles.section}>
          <ProjectCompletedCalls
            projectId={project.id} />
        </div>

        <div className={styles.section}>
          <InsightsContainer projectId={project.id}>
            <Insights.Panel
              actionsEnabled={false}
              projectId={project.id} />
          </InsightsContainer>

          {/* <ProjectSummaryContainer projectId={project.id}>
            <Summary
              downloadName={`${project.name} Summary ${format(new Date(), 'MM/dd/yyyy')}.docx`}
              summary={project.notesSummary} />
          </ProjectSummaryContainer> */}
        </div>

      </div>
    </div>
  );
};

export default ProjectSummary;