import { useCallback } from 'react';
import * as api from '@api';
import * as PostCreation from '@containers/PostCreation';
import { FetchContentFeedContext, FetchMoreContentFeedContext } from './Context';
import { ContentFeedStateContainer } from './ContentFeedState';
import { useContentFeedState } from './hooks';
import { usePublishSubscriber } from './hooks/usePublishSubscriber';
import * as utils from './utils';

type Props = {
  children: React.ReactNode;
};

export const ContentFeedContainer = (props: Props) => {
  return (
    <ContentFeedStateContainer>
      <StateDependentContainer {...props} />
    </ContentFeedStateContainer>
  );
};

ContentFeedContainer.displayName = 'ContentFeed.Container';

const StateDependentContainer = (props: Props) => {

  const [state, dispatch] = useContentFeedState();
  const subscriber = usePublishSubscriber();

  const fetch = useCallback(() => {
    dispatch({
      type: 'feed-refresh-started',
    });

    const size = 10;

    return api.feed.fetchPosts({
      from: 0,
      size,
      suggestedTopicsIndex: 0,
      suggestedTopicsSize: 8,
    })
    .then(data => {
      const totals = utils.totals(data.items);

      dispatch({
        ...data,
        morePostsToFetch: totals.content >= size,
        type: 'feed-refreshed',
      });
    });
  }, [dispatch]);

  const fetchMore = useCallback(() => {

    if (!state.morePostsToFetch) return;

    dispatch({
      type: 'fetch-more-started',
    });

    const size = 10;

    const totals = utils.totals(state.items);

    return api.feed.fetchPosts({
      from: totals.content,
      size,
      suggestedTopicsIndex: totals.platform,
      suggestedTopicsSize: 8,
    })
    .then(data => {
      const totals = utils.totals(data.items);

      dispatch({
        ...data,
        morePostsToFetch: totals.content >= size,
        type: 'more-posts-fetched',
      });
    });
  }, [dispatch, state.items, state.morePostsToFetch]);

  return (
    <FetchContentFeedContext.Provider value={fetch}>
      <FetchMoreContentFeedContext.Provider value={fetchMore}>
        <PostCreation.Container
          onPublished={subscriber.onPublished}
          onError={subscriber.onError}>
          {props.children}
        </PostCreation.Container>
      </FetchMoreContentFeedContext.Provider>
    </FetchContentFeedContext.Provider>
  );
};