import { useCallback } from 'react';
import { useStepper } from '@utils';
import { Questionnare } from './Questionnare';
import type { QuestionnareProps } from './interfaces';
import styles from './style.css';

type Props = {
  callIds: number[];
  onCompletion?: () => void;
};

const QuestionnareStepper = ({
  callIds,
  onCompletion,
}: Props) => {
  const steps = new Array<React.ComponentType<QuestionnareProps>>(callIds.length);
  steps.fill(Questionnare);
  const [Screen, { next }, step] = useStepper(steps);

  const handleSubmit = useCallback(() => {
    if (step === callIds.length - 1) {
      onCompletion();
    } else {
      next();
    }
  }, [
    callIds.length,
    next,
    onCompletion,
    step,
  ]);

  const renderProgress = useCallback(() => {
    if (callIds.length === 1) {
      return null;
    }

    return (
      <div className={styles.callCount}>
        {step + 1} of {callIds.length}
      </div>
    );
  }, [
    callIds.length,
    step,
  ]);

  if (!Screen) {
    return null;
  }

  return (
    <div className={styles.root}>
      {renderProgress()}
      <Screen
        key={callIds[step]}
        callId={callIds[step]}
        onSubmit={handleSubmit} />
    </div>
  );
};

export { QuestionnareStepper };
export default QuestionnareStepper;