import { memo, useMemo } from 'react';
import { File as GenericFileIcon } from 'react-feather';
import { Link } from 'react-router-dom';
import { FileIcon, TranscriptFileIcon } from '@/components/icons';
import useProjectState from '@/containers/GroupProject/hooks/useProjectState';
import { cx, getLocationFor } from '@/utils';
import type { IWorkspaceFileItem } from '@/types/workspace.table';
import { formatCreatedOnDate } from './Files.Table.Grid.utils';
import styles from './style/Files.Table.Grid.css';

type Props = {
  item: IWorkspaceFileItem;
};

export const File = ({ item }: Props) => {
  const state = useProjectState();

  const createdOn = useMemo(() => {
    return formatCreatedOnDate(item.object.createdOn);
  }, [item.object.createdOn]);

  const hasTranscript = useMemo(() => {
    return item.transcript.status === 'complete' &&
      !!item.transcript.id;
  }, [item.transcript]);

  const to = useMemo(() => {

    if (hasTranscript) {
      return getLocationFor.workspaces.transcript({
        transcriptId: item.transcript.id,
      });
    }

    return state?.project?.id
      ? getLocationFor.project.file({ fileId: item.file.id, projectId: state.project.id })
      : getLocationFor.workspaces.file({ fileId: item.file.id });
  }, [
    hasTranscript,
    item.file.id,
    item.transcript.id,
    state?.project?.id,
  ]);

  return (
    <Link
      className={cx(styles.link, styles.file)}
      to={to}
      draggable={false}>
      <div className={styles.thumb}>
        <GenericFileThumb />
      </div>
      <div className={styles.info}>
        <div className={styles.icon}>
          {hasTranscript && (
            <TranscriptFileIcon className={styles.icon} />
          )}
          {!hasTranscript && (
            <FileIcon
              className={styles.icon}
              extension={item.file.extension} />
          )}
        </div>
        <div className={styles.details}>
          <div className={styles.name}>{item.file.name}</div>
          <div className={styles.lastModified}>{createdOn}</div>
        </div>
      </div>
    </Link>
  );
};

const GenericFileThumb = memo(() => {
  return (
    <div className={styles.genericThumb}>
      <GenericFileIcon className={styles.icon} />
    </div>
  );
});