import type { ReactNode } from 'react';
import { useCallback, useContext, useEffect } from 'react';
import { ChatSocketEvent } from '@/brand-insights/enums';
import { ChatSessionSegmentationsContext } from '@/brand-insights/components/Chat/context';
import { ChatStateDispatchContext } from '@/brand-insights/components/Chat.State';
import type { SegmentationCompletedEvent, SegmentationResultEvent } from '@/brand-insights/types';
import { useChatSocket } from '@/brand-insights/websocket';

type Props = {
  children: ReactNode;
};

export const WebSocketContainer = (props: Props) => {
  const dispatch = useContext(ChatStateDispatchContext);
  const socket = useChatSocket();

  const handleSegmentationResult = useCallback((data: SegmentationResultEvent.Data) => {
    dispatch({
      type: 'segmentation/result',
      payload: data,
    });
  }, [dispatch]);

  const handleSegmentationCompleted = useCallback((data: SegmentationCompletedEvent.Data) => {

    dispatch({
      type: 'segmentation/completed',
      payload: data,
    });
  }, [dispatch]);

  useEffect(() => {
    if (!socket) return;

    socket.on(ChatSocketEvent.SegmentationResult, handleSegmentationResult);
    socket.on(ChatSocketEvent.SegmentationCompleted, handleSegmentationCompleted);

    return () => {
      socket.off(ChatSocketEvent.SegmentationResult, handleSegmentationResult);
      socket.off(ChatSocketEvent.SegmentationCompleted, handleSegmentationCompleted);
    };
  }, [
    socket,
    handleSegmentationResult,
    handleSegmentationCompleted,
  ]);

  return (
    <>
      {props.children}
    </>
  );
};