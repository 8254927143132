import { useAsyncState } from '@utils';
import * as api from '$admin/api';

export default function useFetchInternalUsers() {
  const [response, fetch] = useAsyncState(() => {
    return api.users.getInternal();
  }, []);

  return [response, fetch] as const;
}

export { useFetchInternalUsers };