import { useContext } from 'react';
import type { AxiosResponse } from 'axios';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import type { Transcripts } from '@api/interfaces';
import { TranscriptCommentActionsContext, TranscriptCommentsStateContext } from '@containers/Transcript.Commenting/Context';
import type { Comment } from '@/types/transcribe.rich-text';
import { TranscriptChainedCommandsContext } from '@/components/Transcript/context';
import { UnsavedHighlightColor, highlightColors } from '@/components/Transcript/utils';
import { useSaveHighlight } from './useSaveHighlight';

type Data = Comment;
type Variables = {
  comment: Transcripts.SaveTranscriptComment.Request['comment'];
};
type Options = UseMutationOptions<Data, AxiosResponse, Variables>;

function useSaveComment(options?: Options) {

  const { saveComment } = useContext(TranscriptCommentActionsContext);

  return useMutation(['save-transcript-comment'], ({ comment }) => {

    return saveComment({ comment });

  }, options);
}

const DefaultColor = highlightColors[0].highlight;
export function usePersistCommentHighlight() {
  const commands = useContext(TranscriptChainedCommandsContext);

  return useSaveHighlight({
    onSuccess: (highlight, v, c) => {
      commands
        .blurHighlight()
        .updateHighlight({
          id: highlight.id,
          color: DefaultColor,
          dbId: highlight.dbId,
        })
        .run();
    },
    onError: (e, v, ctx) => {
      commands
        .updateHighlight({
          id: v.highlight.id,
          color: UnsavedHighlightColor,
        })
        .run();
    },
  });
}

export function useSaveParentComment(options?: Options) {

  const [_, dispatch] = useContext(TranscriptCommentsStateContext);

  return useSaveComment({
    ...options,
    onSuccess: (res, v, ctx) => {
      dispatch({
        comment: res,
        type: 'comment-saved',
      });

      options?.onSuccess?.(res, v, ctx);
    },
  });
}

export function useSaveCommentReply(options?: Options) {

  const [_, dispatch] = useContext(TranscriptCommentsStateContext);

  return useSaveComment({
    ...options,
    onSuccess: (res, v, ctx) => {
      dispatch({
        comment: res,
        parentCommentId: v.comment.parentCommentId,
        type: 'reply-saved',
      });

      options?.onSuccess?.(res, v, ctx);
    },
  });
}