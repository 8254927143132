import { useCallback, useMemo, memo, Fragment } from 'react';
import type { Members as IMembers } from '$admin/interfaces/search';
import type { Members } from '../interfaces';
import { useFilters } from './hooks';
import { FiltersRemoveButton } from './Filters.Buttons';
import { FilterOperator, type FilterOperatorOption } from './Filter.Operator';
import type { FieldQualifierCoveredLivesOption, FieldQualifierMarketResearchOption, FieldQualifierPositionOption, FieldQualifierNameOption } from './Filter.Field.Qualifier';
import type { FieldOperatorOptionBoolean, FieldOperatorOptionComparison, FieldOperatorOptionMulti, FieldOperatorOptionMultiHybrid, FieldOperatorOptionText } from './Filter.Field';
import {
  FieldName, FieldOperatorMulti, FieldOperatorMultiHybrid, FieldOperatorBoolean, FieldOperatorComparison,
  FieldOperatorSingleCardMulti, FieldOperatorText, FieldOperatorKeyword, FieldValueBoolean, FieldValueCompany, FieldValueJobTitle,
  FieldQualifierCoveredLivesColumn, FieldQualifierCoveredLivesRow, FieldValueCoveredLives, FieldValueLeadStatus,
  FieldValueKeyword, FieldValuePayerAdminType, FieldQualifierMarketResearch, FieldQualifierPosition, FieldQualifierName, FieldValueSector, FieldValueIndustry,
  FieldValueSubIndustry, FieldValueMarketResearch, FieldValueProduct, FieldValueRole, FieldValueCountry, FieldValueHCPType,
} from './Filter.Field';
import styles from './style/Filters.css';

export const ActiveFilters = () => {
  const [state, dispatch] = useFilters();

  const handleOperatorChange = useCallback((value: HandleOperatorChange) => {
    dispatch({
      type: 'update-active-filter-operator',
      operator: value.id,
    });
  }, [dispatch]);

  const handleFieldChange = useCallback((index: number) => (value: HandleFieldChange) => {
    dispatch({
      index,
      type: 'update-active-filter-field',
      field: value.id,
      fieldQual: FilterMap[value.id].defaultQualifier,
      operator: FilterMap[value.id].defaultOperator,
    });
  }, [dispatch]);

  const handleRemoveFilter = useCallback((index: number) => () => {
    dispatch({
      index,
      type: 'remove-active-filter',
    });
  }, [dispatch]);

  // console.log('state.active: ', state.active);

  return (
    <Fragment>
      {state.active.map((a, i) => {
        const FieldComponent = FilterMap[a.field];
        return (
          <div
            className={styles.row}
            key={i}>
            <FilterOperator
              disabled={i !== 1}
              visible={i !== 0}
              value={a.op}
              onChange={handleOperatorChange} />
            <FieldComponent.Component
              index={i}
              item={a}
              dispatch={dispatch}
              onChangeField={handleFieldChange} />
            <FiltersRemoveButton
              onClick={handleRemoveFilter(i)} />
          </div>
        );
      })}
    </Fragment>
  );
};

const Company = memo(({ index, item, dispatch, onChangeField }: BaseFieldProps<'company'>) => {
  const handleQualifierChange = useCallback((index: number) => (value: HandleQualifierChange) => {
    dispatch({
      index,
      type: 'update-active-filter-field-qualifier',
      qualifier: value.id,
      operator: FilterMap['company'].defaultOperator,
    });
  }, [dispatch]);

  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionMulti) => {
    dispatch({
      index,
      type: 'update-active-filter-field-operator',
      operator: id,
    });
  }, [index, dispatch]);

  const handleEntityValueChange = useCallback((value: Descriptor[]) => {
    dispatch({
      index,
      type: 'update-active-filter-field-value',
      value,
    });
  }, [index, dispatch]);

  const handleKeywordValueChange = useCallback((value: string) => {
    dispatch({
      index,
      type: 'update-active-filter-field-value',
      value: value?.length ? [{ id: value, name: value }] : [],
    });
  }, [index, dispatch]);

  return (
    <Fragment>
      <FieldName
        value={item.field}
        onChange={onChangeField(index)} />
      <FieldQualifierPosition
        value={item.fieldQual}
        onChange={handleQualifierChange(index)} />
      {item.fieldQual === 'current'
        ? (
          <FieldOperatorSingleCardMulti
            value={item.fieldOp as IMembers.Filters.FieldOperatorsSingleCardMulti}
            onChange={handleOperatorChange} />
        )
        : (
          <FieldOperatorMultiHybrid
            value={item.fieldOp}
            onChange={handleOperatorChange} />
        )}
      {item.fieldOp !== 'c' &&
        <FieldValueCompany
          value={item.value as IMembers.Filters.FieldValueEntity[]}
          onChange={handleEntityValueChange} />}
      {item.fieldOp === 'c' &&
        <FieldValueKeyword
          value={item.value.length ? item.value[0].name : ''}
          onChange={handleKeywordValueChange} />}
    </Fragment>
  );
});

const CompanyFormer = memo(({ index, item, dispatch, onChangeField }: BaseFieldProps<'company-former'>) => {
  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionMulti) => {
    dispatch({
      index,
      type: 'update-active-filter-field-operator',
      operator: id,
    });
  }, [index, dispatch]);

  const handleEntityValueChange = useCallback((value: Descriptor[]) => {
    dispatch({
      index,
      type: 'update-active-filter-field-value',
      value,
    });
  }, [index, dispatch]);

  const handleKeywordValueChange = useCallback((value: string) => {
    dispatch({
      index,
      type: 'update-active-filter-field-value',
      value: value?.length ? [{ id: value, name: value }] : [],
    });
  }, [index, dispatch]);

  return (
    <Fragment>
      <FieldName
        value={item.field}
        onChange={onChangeField(index)} />
      <FieldOperatorMultiHybrid
        value={item.fieldOp}
        onChange={handleOperatorChange} />
      {item.fieldOp !== 'c' &&
        <FieldValueCompany
          value={item.value as IMembers.Filters.FieldValueEntity[]}
          onChange={handleEntityValueChange} />}
      {item.fieldOp === 'c' &&
        <FieldValueKeyword
          value={item.value.length ? item.value[0].name : ''}
          onChange={handleKeywordValueChange} />}
    </Fragment>
  );
});

export const CoveredLives = memo(({ index, item, dispatch, onChangeField }: BaseFieldProps<'covered-lives'>) => {
  const parsed = useMemo(() => {
    return {
      row: item.fieldQual ? item.fieldQual.split('_')[0] : '1',
      column: item.fieldQual ? item.fieldQual.split('_')[1] : '1',
    };
  }, [item]);

  const handleQualifierChange = useCallback(({ id }: FieldQualifierCoveredLivesOption) => {
    const [cell, val] = id.split('_');
    const encoded = cell === 'col'
      ? `${parsed.row}_${val}`
      : `${val}_${parsed.column}`;

    dispatch({
      index,
      type: 'update-active-filter-field-qualifier',
      qualifier: encoded,
      operator: FilterMap['covered-lives'].defaultOperator,
    });
  }, [
    dispatch,
    index,
    parsed,
  ]);

  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionComparison) => {
    dispatch({
      index,
      type: 'update-active-filter-field-operator',
      operator: id,
    });
  }, [
    dispatch,
    index,
  ]);

  const handleValueChange = useCallback((value: string) => {
    dispatch({
      index,
      type: 'update-active-filter-field-value',
      value: value?.length ? [{ id: value, name: value }] : [],
    });
  }, [
    dispatch,
    index,
  ]);

  return (
    <Fragment>
      <FieldName
        value={item.field}
        onChange={onChangeField(index)} />
      <FieldQualifierCoveredLivesRow
        value={parsed.row}
        onChange={handleQualifierChange} />
      <FieldQualifierCoveredLivesColumn
        value={parsed.column}
        onChange={handleQualifierChange} />
      <FieldOperatorComparison
        value={item.fieldOp}
        onChange={handleOperatorChange} />
      <FieldValueCoveredLives
        value={item.value.length ? item.value[0].name : ''}
        onChange={handleValueChange} />
    </Fragment>
  );
});

const LeadStatus = memo(({ index, item, dispatch, onChangeField }: BaseFieldProps<'lead-status'>) => {
  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionMulti) => {
    dispatch({
      index,
      type: 'update-active-filter-field-operator',
      operator: id,
    });
  }, [index, dispatch]);

  const handleValueChange = useCallback((value: Descriptor[]) => {
    dispatch({
      index,
      type: 'update-active-filter-field-value',
      value,
    });
  }, [index, dispatch]);

  return (
    <Fragment>
      <FieldName
        value={item.field}
        onChange={onChangeField(index)} />
      <FieldOperatorMulti
        value={item.fieldOp}
        onChange={handleOperatorChange} />
      <FieldValueLeadStatus
        value={item.value}
        onChange={handleValueChange} />
    </Fragment>
  );
});

const Name = memo(({ index, item, dispatch, onChangeField }: BaseFieldProps<'name'>) => {
  const handleQualifierChange = useCallback((index: number) => (value: HandleQualifierChange) => {
    dispatch({
      index,
      type: 'update-active-filter-field-qualifier',
      qualifier: value.id,
      operator: FilterMap['name'].defaultOperator,
    });
  }, [dispatch]);

  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionText) => {
    dispatch({
      index,
      type: 'update-active-filter-field-operator',
      operator: id,
    });
  }, [index, dispatch]);

  const handleValueChange = useCallback((value: string) => {
    dispatch({
      index,
      type: 'update-active-filter-field-value',
      value: value?.length ? [{ id: value, name: value }] : [],
    });
  }, [index, dispatch]);

  return (
    <Fragment>
      <FieldName
        value={item.field}
        onChange={onChangeField(index)} />
      <FieldQualifierName
        value={item.fieldQual}
        onChange={handleQualifierChange(index)} />
      <FieldOperatorText
        value={item.fieldOp}
        onChange={handleOperatorChange} />
      <FieldValueKeyword
        value={item.value.length ? item.value[0].name : ''}
        onChange={handleValueChange} />
    </Fragment>
  );
});

const JobTitle = memo(({ index, item, dispatch, onChangeField }: BaseFieldProps<'job-title'>) => {
  const handleQualifierChange = useCallback((index: number) => (value: HandleQualifierChange) => {
    dispatch({
      index,
      type: 'update-active-filter-field-qualifier',
      qualifier: value.id,
      operator: FilterMap['job-title'].defaultOperator,
    });
  }, [dispatch]);

  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionMulti) => {
    dispatch({
      index,
      type: 'update-active-filter-field-operator',
      operator: id,
    });
  }, [index, dispatch]);

  const handleEntityValueChange = useCallback((value: Descriptor[]) => {
    dispatch({
      index,
      type: 'update-active-filter-field-value',
      value,
    });
  }, [index, dispatch]);

  const handleKeywordValueChange = useCallback((value: string) => {
    dispatch({
      index,
      type: 'update-active-filter-field-value',
      value: value?.length ? [{ id: value, name: value }] : [],
    });
  }, [index, dispatch]);

  return (
    <Fragment>
      <FieldName
        value={item.field}
        onChange={onChangeField(index)} />
      <FieldQualifierPosition
        value={item.fieldQual}
        onChange={handleQualifierChange(index)} />
      <FieldOperatorMultiHybrid
        value={item.fieldOp}
        onChange={handleOperatorChange} />
      {item.fieldOp !== 'c' &&
        <FieldValueJobTitle
          value={item.value as IMembers.Filters.FieldValueEntity[]}
          onChange={handleEntityValueChange} />}
      {item.fieldOp === 'c' &&
        <FieldValueKeyword
          value={item.value.length ? item.value[0].name : ''}
          onChange={handleKeywordValueChange} />}
    </Fragment>
  );
});

const Sector = memo(({ index, item, dispatch, onChangeField }: BaseFieldProps<'sector'>) => {
  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionMulti) => {
    dispatch({
      index,
      type: 'update-active-filter-field-operator',
      operator: id,
    });
  }, [index, dispatch]);

  const handleValueChange = useCallback((value: Descriptor[]) => {
    dispatch({
      index,
      type: 'update-active-filter-field-value',
      value,
    });
  }, [index, dispatch]);

  return (
    <Fragment>
      <FieldName
        value={item.field}
        onChange={onChangeField(index)} />
      <FieldOperatorMulti
        value={item.fieldOp}
        onChange={handleOperatorChange} />
      <FieldValueSector
        value={item.value}
        onChange={handleValueChange} />
    </Fragment>
  );
});

const Industry = memo(({ index, item, dispatch, onChangeField }: BaseFieldProps<'industry'>) => {
  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionMulti) => {
    dispatch({
      index,
      type: 'update-active-filter-field-operator',
      operator: id,
    });
  }, [index, dispatch]);

  const handleValueChange = useCallback((value: Descriptor[]) => {
    dispatch({
      index,
      type: 'update-active-filter-field-value',
      value,
    });
  }, [index, dispatch]);

  return (
    <Fragment>
      <FieldName
        value={item.field}
        onChange={onChangeField(index)} />
      <FieldOperatorMulti
        value={item.fieldOp}
        onChange={handleOperatorChange} />
      <FieldValueIndustry
        value={item.value}
        onChange={handleValueChange} />
    </Fragment>
  );
});

const SubIndustry = memo(({ index, item, dispatch, onChangeField }: BaseFieldProps<'sub-industry'>) => {
  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionMulti) => {
    dispatch({
      index,
      type: 'update-active-filter-field-operator',
      operator: id,
    });
  }, [index, dispatch]);

  const handleValueChange = useCallback((value: Descriptor[]) => {
    dispatch({
      index,
      type: 'update-active-filter-field-value',
      value,
    });
  }, [index, dispatch]);

  return (
    <Fragment>
      <FieldName
        value={item.field}
        onChange={onChangeField(index)} />
      <FieldOperatorMulti
        value={item.fieldOp}
        onChange={handleOperatorChange} />
      <FieldValueSubIndustry
        value={item.value}
        onChange={handleValueChange} />
    </Fragment>
  );
});

const Product = memo(({ index, item, dispatch, onChangeField }: BaseFieldProps<'product'>) => {
  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionMulti) => {
    dispatch({
      index,
      type: 'update-active-filter-field-operator',
      operator: id,
    });
  }, [index, dispatch]);

  const handleValueChange = useCallback((value: Descriptor[]) => {
    dispatch({
      index,
      type: 'update-active-filter-field-value',
      value,
    });
  }, [index, dispatch]);

  return (
    <Fragment>
      <FieldName
        value={item.field}
        onChange={onChangeField(index)} />
      <FieldOperatorMulti
        value={item.fieldOp}
        onChange={handleOperatorChange} />
      <FieldValueProduct
        value={item.value}
        onChange={handleValueChange} />
    </Fragment>
  );
});

const Keyword = memo(({ index, item, dispatch, onChangeField }: BaseFieldProps<'keyword'>) => {
  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionText) => {
    dispatch({
      index,
      type: 'update-active-filter-field-operator',
      operator: id,
    });
  }, [index, dispatch]);

  const handleValueChange = useCallback((value: string) => {
    dispatch({
      index,
      type: 'update-active-filter-field-value',
      value: value?.length ? [{ id: value, name: value }] : [],
    });
  }, [index, dispatch]);

  return (
    <Fragment>
      <FieldName
        value={item.field}
        onChange={onChangeField(index)} />
      <FieldOperatorKeyword
        value={item.fieldOp}
        onChange={handleOperatorChange} />
      <FieldValueKeyword
        value={item.value.length ? item.value[0].name : ''}
        onChange={handleValueChange} />
    </Fragment>
  );
});

const ProjectCode = memo(({ index, item, dispatch, onChangeField }: BaseFieldProps<'project-code'>) => {
  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionText) => {
    dispatch({
      index,
      type: 'update-active-filter-field-operator',
      operator: id,
    });
  }, [index, dispatch]);

  const handleValueChange = useCallback((value: string) => {
    dispatch({
      index,
      type: 'update-active-filter-field-value',
      value: value?.length ? [{ id: value, name: value }] : [],
    });
  }, [index, dispatch]);

  return (
    <Fragment>
      <FieldName
        value={item.field}
        onChange={onChangeField(index)} />
      <FieldOperatorText
        value={item.fieldOp}
        onChange={handleOperatorChange} />
      <FieldValueKeyword
        value={item.value.length ? item.value[0].name : ''}
        onChange={handleValueChange} />
    </Fragment>
  );
});

const Role = memo(({ index, item, dispatch, onChangeField }: BaseFieldProps<'role'>) => {
  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionMulti) => {
    dispatch({
      index,
      type: 'update-active-filter-field-operator',
      operator: id,
    });
  }, [index, dispatch]);

  const handleValueChange = useCallback((value: Descriptor[]) => {
    dispatch({
      index,
      type: 'update-active-filter-field-value',
      value,
    });
  }, [index, dispatch]);

  return (
    <Fragment>
      <FieldName
        value={item.field}
        onChange={onChangeField(index)} />
      <FieldOperatorMulti
        value={item.fieldOp}
        onChange={handleOperatorChange} />
      <FieldValueRole
        value={item.value}
        onChange={handleValueChange} />
    </Fragment>
  );
});

const Country = memo(({ index, item, dispatch, onChangeField }: BaseFieldProps<'country'>) => {
  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionMulti) => {
    dispatch({
      index,
      type: 'update-active-filter-field-operator',
      operator: id,
    });
  }, [index, dispatch]);

  const handleValueChange = useCallback((value: IMembers.Filters.FieldValueText[]) => {
    dispatch({
      index,
      type: 'update-active-filter-field-value',
      value,
    });
  }, [index, dispatch]);

  return (
    <Fragment>
      <FieldName
        value={item.field}
        onChange={onChangeField(index)} />
      <FieldOperatorMulti
        value={item.fieldOp}
        onChange={handleOperatorChange} />
      <FieldValueCountry
        value={item.value}
        onChange={handleValueChange} />
    </Fragment>
  );
});

const MarketResearch = memo(({ index, item, dispatch, onChangeField }: BaseFieldProps<'market-research'>) => {
  const handleQualifierChange = useCallback((index: number) => (value: HandleQualifierChange) => {
    dispatch({
      index,
      type: 'update-active-filter-field-qualifier',
      qualifier: value.id,
      operator: FilterMap['market-research'].defaultOperator,
    });
    dispatch({
      index: -1,
      type: 'update-active-filter-field-value',
      value: [],
    });
  }, [dispatch]);

  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionMulti) => {
    dispatch({
      index,
      type: 'update-active-filter-field-operator',
      operator: id,
    });
  }, [index, dispatch]);

  const handleValueChange = useCallback((value: Descriptor[]) => {
    dispatch({
      index,
      type: 'update-active-filter-field-value',
      value,
    });
  }, [index, dispatch]);

  return (
    <Fragment>
      <FieldName
        value={item.field}
        onChange={onChangeField(index)} />
      <FieldQualifierMarketResearch
        value={item.fieldQual}
        onChange={handleQualifierChange(index)} />
      <FieldOperatorSingleCardMulti
        value={item.fieldOp}
        onChange={handleOperatorChange} />
      <FieldValueMarketResearch
        qualifier={item.fieldQual}
        value={item.value}
        onChange={handleValueChange} />
    </Fragment>
  );
});

const PayerAdminType = memo(({ index, item, dispatch, onChangeField }: BaseFieldProps<'payer-admin-type'>) => {
  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionMulti) => {
    dispatch({
      index,
      type: 'update-active-filter-field-operator',
      operator: id,
    });
  }, [index, dispatch]);

  const handleValueChange = useCallback((value: Descriptor[]) => {
    dispatch({
      index,
      type: 'update-active-filter-field-value',
      value,
    });
  }, [index, dispatch]);

  return (
    <Fragment>
      <FieldName
        value={item.field}
        onChange={onChangeField(index)} />
      <FieldOperatorMulti
        value={item.fieldOp}
        onChange={handleOperatorChange} />
      <FieldValuePayerAdminType
        value={item.value}
        onChange={handleValueChange} />
    </Fragment>
  );
});

const HCPType = memo(({ index, item, dispatch, onChangeField }: BaseFieldProps<'hcp-type'>) => {
  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionBoolean) => {
    dispatch({
      index,
      type: 'update-active-filter-field-operator',
      operator: id,
    });
  }, [index, dispatch]);

  const handleValueChange = useCallback((value: Descriptor[]) => {
    dispatch({
      index,
      type: 'update-active-filter-field-value',
      value,
    });
  }, [index, dispatch]);

  return (
    <Fragment>
      <FieldName
        value={item.field}
        onChange={onChangeField(index)} />
      <FieldOperatorBoolean
        value={item.fieldOp}
        onChange={handleOperatorChange} />
      <FieldValueHCPType
        value={item.value}
        onChange={handleValueChange} />
    </Fragment>
  );
});

const IsPayer = memo(({ index, item, dispatch, onChangeField }: BaseFieldProps<'is-payer'>) => {
  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionBoolean) => {
    dispatch({
      index,
      type: 'update-active-filter-field-operator',
      operator: id,
    });
  }, [index, dispatch]);
  const handleValueChange = useCallback((value: boolean) => {
    dispatch({
      index,
      type: 'update-active-filter-field-value',
      value: [{ id: value, name: value ? 'True' : 'False' }],
    });
  }, [index, dispatch]);

  return (
    <Fragment>
      <FieldName
        value={item.field}
        onChange={onChangeField(index)} />
      <FieldOperatorBoolean
        value={item.fieldOp}
        onChange={handleOperatorChange} />
      <FieldValueBoolean
        value={item.value}
        onChange={handleValueChange} />
    </Fragment>
  );
});

const IsKOL = memo(({ index, item, dispatch, onChangeField }: BaseFieldProps<'is-kol'>) => {
  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionBoolean) => {
    dispatch({
      index,
      type: 'update-active-filter-field-operator',
      operator: id,
    });
  }, [index, dispatch]);
  const handleValueChange = useCallback((value: boolean) => {
    dispatch({
      index,
      type: 'update-active-filter-field-value',
      value: [{ id: value, name: value ? 'True' : 'False' }],
    });
  }, [index, dispatch]);

  return (
    <Fragment>
      <FieldName
        value={item.field}
        onChange={onChangeField(index)} />
      <FieldOperatorBoolean
        value={item.fieldOp}
        onChange={handleOperatorChange} />
      <FieldValueBoolean
        value={item.value}
        onChange={handleValueChange} />
    </Fragment>
  );
});

const FilterMap: Record<IMembers.Filters.Field, FilterMapValue> = {
  'company': {
    Component: Company,
    defaultQualifier: 'any',
    defaultOperator: 'c',
  },
  'company-former': {
    Component: CompanyFormer,
    defaultQualifier: 'former',
    defaultOperator: 'c',
  },
  'covered-lives': {
    Component: CoveredLives,
    defaultQualifier: '1_1',
    defaultOperator: 'lt',
  },
  'lead-status': {
    Component: LeadStatus,
    defaultQualifier: null,
    defaultOperator: 'ioo',
  },
  'name': {
    Component: Name,
    defaultQualifier: 'full',
    defaultOperator: 'c',
  },
  'job-title': {
    Component: JobTitle,
    defaultQualifier: 'any',
    defaultOperator: 'c',
  },
  sector: {
    Component: Sector,
    defaultQualifier: null,
    defaultOperator: 'ioo',
  },
  industry: {
    Component: Industry,
    defaultQualifier: null,
    defaultOperator: 'ioo',
  },
  'sub-industry': {
    Component: SubIndustry,
    defaultQualifier: null,
    defaultOperator: 'ioo',
  },
  product: {
    Component: Product,
    defaultQualifier: null,
    defaultOperator: 'ioo',
  },
  keyword: {
    Component: Keyword,
    defaultQualifier: null,
    defaultOperator: 'c',
  },
  role: {
    Component: Role,
    defaultQualifier: null,
    defaultOperator: 'ioo',
  },
  'market-research': {
    Component: MarketResearch,
    defaultQualifier: 'in-person',
    defaultOperator: 'ioo',
  },
  'payer-admin-type': {
    Component: PayerAdminType,
    defaultQualifier: null,
    defaultOperator: 'ioo',
  },
  'is-payer': {
    Component: IsPayer,
    defaultQualifier: null,
    defaultOperator: 'i',
  },
  'is-kol': {
    Component: IsKOL,
    defaultQualifier: null,
    defaultOperator: 'i',
  },
  'hcp-type': {
    Component: HCPType,
    defaultQualifier: null,
    defaultOperator: 'i',
  },
  'project-code': {
    Component: ProjectCode,
    defaultQualifier: null,
    defaultOperator: 'c',
  },
  country: {
    Component: Country,
    defaultQualifier: null,
    defaultOperator: 'ioo',
  },
};

type HandleQualifierChange = FieldQualifierPositionOption | FieldQualifierNameOption | FieldQualifierMarketResearchOption;
type HandleOperatorChange = FilterOperatorOption;
type HandleFieldChange = Members.Filters.FieldNameOption;

type BaseFieldProps<F extends IMembers.Filters.Field> = {
  index: number;
  item: IMembers.Filters.Item<F>;
  dispatch: React.Dispatch<Members.Filters.Action>;
  onChangeField: (index: number) => (data: HandleFieldChange) => unknown;
};

type FilterMapValue = {
  Component: (props: BaseFieldProps<IMembers.Filters.Field>) => React.ReactNode;
  defaultQualifier: IMembers.Filters.FieldQualifier;
  defaultOperator: IMembers.Filters.FieldOperator;
};