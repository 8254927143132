import { useMemo } from 'react';
import { useParseSurveyRichText, useSurveyBuilderState } from '@containers/SurveyBuilder';
// import { SurveyActionType } from '@/enums';
import type { SurveyQuestion } from '@/types';
// import { useSurveyLogicItemState } from '../context';
import { LogicQuestionTypes } from '../utils.condition';

export const useAvailableConditionQuestions = () => {
  const [state] = useSurveyBuilderState();
  // const [logic] = useSurveyLogicItemState();
  const getQuestionText = useParseSurveyRichText();

  return useMemo(() => {
    return state.survey.questions
      .filter(q => LogicQuestionTypes.includes(q.typeId))
      // .filter(q => logic.action.type === SurveyActionType.ExcludeQuestionChoice ? filterExcludeChoiceQuestions(logic.action.question.identifier, q) : true)
      .map(m => ({
        base: m.base,
        ordinal: m.ordinal,
        value: getQuestionText(m.value),
      }));
  }, [
    getQuestionText,
    state.survey.questions,
    // logic.action,
  ]);
};

function filterExcludeChoiceQuestions(excludeIdentifier: string, item: SurveyQuestion) {
  return item.base.identifier !== excludeIdentifier;
}