import { useCallback, useState } from 'react';
import { SelectingConfirmation, SelectingDay, SelectingTime } from '@screens/SchedulingStepper';
import { useStepper } from '@utils';
import { useConfirmAdHocCall } from '@utils/api';
import { CalendarRange } from '@/types';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import { useAvailableTimes } from './hooks';
import { ConfirmationStepperContext } from './Context';
import { ConfirmationStepperContextValue } from './interfaces';

type Props = {
  scheduleeId: number;
  schedulerId: number;
} & ICallId;

const AdHocCallConfirmationStepper = (props: Props) => {
  const [day, setDay] = useState<Date>(null);
  const {
    data: availability,
    isLoading,
  } = useAvailableTimes({
    callId: props.callId,
    scheduleeId: props.scheduleeId,
    schedulerId: props.schedulerId,
  });
  const { mutateAsync: confirmTime } = useConfirmAdHocCall({ callId: props.callId });

  const handleSubmit = useCallback((slot: CalendarRange<Date>) => {
    return confirmTime({ slot });
  }, [
    confirmTime,
  ]);

  const [Screen, actions] = useStepper([
    SelectingDay,
    SelectingTime,
    SelectingConfirmation,
  ]);

  if (isLoading) return <ActivityIndicator show />;

  const context: ConfirmationStepperContextValue = {
    back: actions.back,
    callId: props.callId,
    day,
    events: availability,
    next: actions.next,
    setDay,
    onSubmit: handleSubmit,
  };

  return (
    <ConfirmationStepperContext.Provider value={context}>
      <Screen />
    </ConfirmationStepperContext.Provider>
  );
};

export { AdHocCallConfirmationStepper };
export default AdHocCallConfirmationStepper;