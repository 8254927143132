import { useCallback, useContext } from 'react';
import { Alert } from '@/components/Modal/Alert';
import { CallMarkCompleteContext } from './Context';

type Props = unknown;

export const Modal = (props: Props) => {
  const ctx = useContext(CallMarkCompleteContext);

  const handleSubmit = useCallback(() => {
    ctx.mutation.mutateAsync().then(ctx.resetContext);
  }, [
    ctx.mutation,
    ctx.resetContext,
  ]);

  return (
    <Alert
      confirmText={copy.button}
      message={copy.message}
      onConfirm={handleSubmit}
      onClose={ctx.resetContext}
      open />
  );
};

Modal.displayName = 'Call.MarkComplete.Modal';

const copy = {
  button: `Mark Complete`,
  message: `Are you sure you want to mark this call complete?`,
};