import { Header, Subtitle, Title } from '@screens/ProjectCreation';
import { cx } from '@utils';
import styles from './style/AccountProfileSetup.css';

type Props = {
  className?:         string;
  title?:             string;
  subtitle?:          string;
  titleClassName?:    string;
  subtitleClassName?: string;
};

const EmploymentSetupHeader = (props: Props) => {
  return (
    <Header className={cx(styles.header, props.className)}>
      <Title className={cx(styles.title, props.titleClassName)}>{props.title}</Title>
      <Subtitle className={cx(styles.subtitle, props.subtitleClassName)}>{props.subtitle}</Subtitle>
    </Header>
  );
};

const defaultProps = {
  title: `Work Experience`,
  subtitle: `Please provide your work experience to fill out your profile.`,
};

EmploymentSetupHeader.defaultProps = defaultProps;

export { EmploymentSetupHeader };
export default EmploymentSetupHeader;