import type { AxiosError } from 'axios';
import http from '@services/http';
import type { XHR } from '@services/http/interfaces';
import { transformDates } from '@services/http/transformers';
import * as cancellation from '@services/http/cancellation';
import { safeJsonDate } from '@utils';
import type { CallDataToRate } from '@/types';
import type { Users } from './interfaces';

export * from './users.connected-accounts';
export * as consent from './users.consent';
export * as geolocation from './users.geolocation';
export * as notification from './users.notifications';
export * as onboarding from './users.onboarding';
export * as profiles from './users.profiles';
export * as topics from './users.topics';
export * as verify from './users.verify';

export const createProjectInvite = (data: Users.CreateProjectInvite.Request): Promise<Users.CreateProjectInvite.Response> => {
  return http.post('/users/invite', data);
};

export const deleteAccount = (): Promise<Users.DeleteAccount.Response> => {
  return http.delete(`/users/me`);
};

export const getCallsToRate = (): Promise<CallDataToRate[]> => {
  return http.get(`/users/calls-to-rate`);
};

export const getOutstandingPostCallQuestionnares = (): Promise<Users.GetOutstandingPostCallQuestionnaires.Response> => {
  return http.get(`/users/post-call-questionnaires`);
};

export const searchContactsCancellable = (data: Users.SearchContacts.Request): ExtendedPromise<Users.SearchContacts.Response> => {
  return http.get<Users.SearchContacts.Response>(`/users/search-contacts?q=${data.query}`, {
    cancelToken: data.cancelToken,
  })
    .catch<Users.SearchContacts.Response>((e: AxiosError<XHR.RequestConfig>) => cancellation.ignoreRequestCancellationError(e, {} as Users.SearchContacts.Response));
};

export const searchContacts = (data: Users.SearchContacts.Request): Promise<Users.SearchContacts.Response> => {
  return http.get(`/users/search-contacts?q=${data.query}`);
};

export const updateAccountMetadata = (data: Users.UpdateAccountMetadata.Request): Promise<Users.UpdateAccountMetadata.Response> => {
  return http.patch(`/users/${data.userId}`, { meta: data });
};

export const updateContactInfo = (data: Users.UpdateContactInfo.Request): Promise<Users.UpdateContactInfo.Response> => {
  return http.patch('/users/me', data);
};

export const updateSettings = (data: Users.UpdateSettings.Request): Promise<Users.UpdateSettings.Response> => {
  return http.patch(`/users/settings`, data);
};

export const updatePrompts = (data: Users.UpdatePrompts.Request): Promise<Users.UpdatePrompts.Response> => {
  return http.patch(`/users/prompts`, data);
};

export const getRecentProfiles = (data: Users.GetRecentProfiles.Request): ExtendedPromise<Users.GetRecentProfiles.Response> => {
  return http.get('/users/recent-profiles', {
    cancelToken: data.cancelToken,
  })
    .then(({ items }: Users.GetRecentProfiles.Response<string>) => ({
      items: items.map(m => ({
        ...m,
        lastVisited: safeJsonDate(m.lastVisited),
      })),
    }));
};

export const getSessions = (): Promise<Users.GetSessions.Response> => {
  return http.get('/users/sessions')
    .then((response: Users.GetSessions.Response<string>) => ({
      items: response.items.map(item => ({
        ...item,
        created: safeJsonDate(item.created),
      })),
    }));
};

export const logoutSession = ({ sids }: Users.LogoutSession.Request): Promise<Users.LogoutSession.Response> => {
  return http.post(`/users/sessions/logout`, {
    sids,
  });
};

export const exportUserData = () => {
  return http.download('/users/documents/export');
};

export const getConnectedAccountToken = (data: Users.GetConnectedAccountToken.Request): Promise<Users.GetConnectedAccountToken.Response> => {
  return http.get(`/users/connected-account-token?id=${data.id}`);
};

export const getUpcomingCalls = () => {
  return http.get<Users.GetUpcomingCalls.Response>(`/users/upcoming-calls`)
    .then(res => {
      return res.items.map(m => ({
        ...m,
        call: {
          ...m.call,
          timeStart: safeJsonDate(m.call.timeStart),
          timeEnd: safeJsonDate(m.call.timeEnd),
        },
      }));
    });
};

export const getFeedbackItems = () => {
  return http.get<Users.GetFeedbackItems.Response>(`/users/feedback-items`);
};

export const snoozeSecondaryScreenPrompt = () => {
  return http.post(`/users/snooze-screener-invite`, { });
};

export const fetchContacts = (data: Users.FetchContacts.Request) => {
  return http.get<Users.FetchContacts.Response>(`/users/contacts`, {
    params: {
      userIds: data.userIds,
    },
  });
};

export const getRecentFiles = () => {
  return http.get<Users.GetRecentFiles.Response>(`/users/recent-files`, {
    transformResponse: transformDates,
  });
};

export const getRateLimitingStats = () => {
  return http.get<Users.GetRateLimitingStats.Response>(`/users/rate-limiting`);
};

export const registerClientUsers = (data: Users.RegisterClientUsers.Request) => {
  return http.post<Users.RegisterClientUsers.Response>(`/users/register-client-users`, data);
};