import { SurveyItemType } from '@/enums/Survey';
import { SurveyMessageScreen } from '@/screens/Survey/Message';
import { AlternateImageExerciseScreen } from '@/screens/Survey/AlternateImageExercise';
import { RichTextEditorRegistryContainer } from '@containers/SurveyBuilder/RichTextEditorRegistryContainer';
import { SurveyFormQuestionContainer } from './SurveyFormQuestion';
import { SurveyFormMessageContainer } from './Message';
import { SurveyFormAIEContainer } from './AlternateImageExercise';
import { useSurveyFormScreenContext } from './Context';

type Props = {
  QuestionScreenComponent: React.ComponentType;
  WrapperComponent?: React.ComponentType<WrapperProps>;
};

export const SurveyFormScreen = ({ QuestionScreenComponent, WrapperComponent = EmptyWrapper }: Props) => {

  const [state] = useSurveyFormScreenContext();

  switch (state.item.type) {
    case SurveyItemType.Question:
      return (
        <SurveyFormQuestionContainer>
          <RichTextEditorRegistryContainer>
            <WrapperComponent Component={QuestionScreenComponent} />
          </RichTextEditorRegistryContainer>
        </SurveyFormQuestionContainer>
      );

    case SurveyItemType.Message:
      return (
        <SurveyFormMessageContainer>
          <RichTextEditorRegistryContainer>
            <WrapperComponent Component={SurveyMessageScreen} />
          </RichTextEditorRegistryContainer>
        </SurveyFormMessageContainer>
      );

    case SurveyItemType.AlternateImageExercise:
      return (
        <SurveyFormAIEContainer>
          <WrapperComponent Component={AlternateImageExerciseScreen} />
        </SurveyFormAIEContainer>
      );

    default:
      return null;
  }

};

type WrapperProps = {
  Component: React.ComponentType;
};

const EmptyWrapper = (props: WrapperProps) => {
  return <props.Component />;
};