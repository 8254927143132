import { memo, useContext } from 'react';
import { TranscriptSummaryContainer } from '@containers/ConferenceTag.Summary';
import { useFetchTranscriptSummary } from '@containers/Transcript/hooks';
import { ButtonOutlined } from '@presentation/ButtonOutlined';
import { cx } from '@utils';
import { TranscriptIdContext } from '@/containers/Transcript/context';
import { ConferenceTagSummaries, RefreshSummaries } from '@/components/ConferenceTag.Summary';
import { useTranscriptTagSummariesModal } from '@/components/Transcript.Summary';
import { Skeleton } from '@/components/Transcript';
import styles from './style/Tab.Summary.css';

type Props = unknown;

export const Summary = (props: Props) => {
  const transcriptId = useContext(TranscriptIdContext);

  const query = useFetchTranscriptSummary({
    transcriptId,
  }, {
    enabled: !!transcriptId,
  });

  const isLoading = query.isInitialLoading && !!query.data;
  const hasData = !isLoading && query.data?.length > 0;
  const hasNoData = !isLoading && query.data?.length <= 0;

  return (
    <TranscriptSummaryContainer transcriptId={transcriptId}>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.header}>
            <div className={styles.title}>Summary</div>
            <div className={styles.actions}>
              {!isLoading && (
                <>
                  <RefreshSummaries />
                  {hasData &&
                    <Export transcriptId={transcriptId} />}
                </>
              )}
            </div>
          </div>
          <div className={styles.main}>
            {isLoading &&
              <Skeleton.Summary rows={3} />}
            {hasData &&
              <div className={cx({ [styles.updating]: query.isFetching })}>
                <ConferenceTagSummaries items={query.data} />
              </div>}
            {hasNoData &&
              <Placeholder />}
          </div>
        </div>
      </div>
    </TranscriptSummaryContainer>
  );
};

Summary.displayName = 'Transcript.Tab.Summary';

const Placeholder = memo(() => (
  <div className={styles.empty}>
    <div className={styles.placeholder}>Tag content in the transcript to generate</div>
    <div className={styles.placeholder}>an automated transcript summary.</div>
  </div>
));

type ExportProps = ITranscriptId;

const Export = ({ transcriptId }: ExportProps) => {
  const [toggleSummaryModal, SummaryModal] = useTranscriptTagSummariesModal();

  return (
    <>
      <ButtonOutlined
        className={cx(styles.action, styles.export)}
        color="silver"
        fontWeight="bold"
        onClick={toggleSummaryModal}>
        Export
      </ButtonOutlined>
      <SummaryModal
        transcriptId={transcriptId} />
    </>
  );
};

Export.displayName = 'Transcript.Export';