import { useCallback } from 'react';
import { useActive, useChainedCommands, useCommands } from '@remirror/react';

export const useOrderedListCommand = () => {
  const active = useActive();
  const { toggleOrderedList } = useCommands();
  const chain = useChainedCommands();

  const toggle = useCallback(() => {
    chain
      .toggleOrderedList()
      .focus()
      .run();
  }, [chain]);

  return {
    active: active.orderedList(),
    toggle,
    enabled: toggleOrderedList.enabled(),
  };
};