import type { AxiosResponse } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import * as api from '$admin/api/transcripts';
import type { GetTranscriptHighlights } from '$admin/api/interfaces/transcripts';

type Props = {
  transcriptId: number;
};

export const useTranscriptionHighlights = (props: Props, options: Options = {}) => {

  const query = useQuery({
    queryKey: ['admin.transcript-highlights', props],
    queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      return api.getTranscriptHighlights(params as Props)
        .then(res => res.highlights);
    },
    ...options,
    placeholderData: [],
  });

  return query;
};

type Data = GetTranscriptHighlights.Response['highlights'];
type QK = readonly [string, Props];

type Options = UseQueryOptions<Data, AxiosResponse, Data, QK>;