import { useCallback } from 'react';
import type { MultipleChoiceQuestion } from '@/types/survey';
import {
  useGetOptionDisplayLogic,
  useGetOptionsSelectedLogic,
  useGetAdditionalOptionsLogic,
  useQuestionLogic,
  useQuestionClassifications,
  useGetOptionsSelectedTagging,
  useGetAdditionalOptionsTagging,
} from './hooks';
import { Options } from './Options';
import { OptionMetadata } from './Metadata';
import { SurveyQuestionLogic } from './Logic';
import { SurveyQuestionClassifications } from './Tagging';
import { SurveySettings } from './Settings';

type Props = {
  item: MultipleChoiceQuestion.Question;
};

export const MultipleChoicePreview = ({ item }: Props) => {

  const questionLogic = useQuestionLogic(item.base.identifier);
  const questionClassifications = useQuestionClassifications(item.base.identifier);
  const getOptionsLogic = useGetOptionsSelectedLogic(questionLogic);
  const getOptionsTagging = useGetOptionsSelectedTagging(questionClassifications);
  const additionalLogic = useGetAdditionalOptionsLogic(questionLogic, item.base.identifier);
  const additionalTagging = useGetAdditionalOptionsTagging(questionClassifications);
  const getDisplayLogic = useGetOptionDisplayLogic(item.base.identifier);

  const renderExtras = useCallback((option: MultipleChoiceQuestion.Option) => {
    const logicItems = getOptionsLogic(option);
    const taggingItems = getOptionsTagging(option);
    const dispalyLogicItems = getDisplayLogic(option);

    return (
      <>
        <OptionMetadata
          isAnchored={option.metadata.isAnchored}
          isExclusive={false}
          isOpenEnded={option.metadata.isOpenEnded} />
        {dispalyLogicItems}
        {logicItems}
        {taggingItems}
      </>
    );
  }, [getDisplayLogic, getOptionsLogic, getOptionsTagging]);

  return (
    <>
      <Options
        item={item}
        renderOptionExtras={renderExtras} />
      <SurveySettings>
        {item.settings.orderOptionsBasedOnSource && <>Order options based on source question</>}
        {item.settings.randomization && <>Randomize options</>}
        {item.settings.rationale.enabled && <>Ask for rationale</>}
      </SurveySettings>
      <SurveyQuestionLogic items={additionalLogic} />
      <SurveyQuestionClassifications items={additionalTagging} />
    </>
  );
};