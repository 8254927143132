import type { CellProps } from 'react-table';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { LeadStatus } from '@enums';
import { Tooltip } from '@presentation/Tooltip';
import { cx } from '@utils';
import type { CellItem } from '$admin/Search/Leads/interfaces';
import styles from './style/Search.Leads.css';

type Props = CellProps<CellItem>;

export const Cell = (props: Props) => {
  const name = `${props.row.original.firstName} ${props.row.original.lastName}`;

  return (
    <div
      className={cx(styles.trunc, styles.name)}
      title={name}>
      <div>{name}</div>
      {showWarning(props.row.original.leadStatusId) &&
        <Tooltip title={copy[props.row.original.leadStatusId as keyof typeof copy]}>
          <div className={cx(styles.dnc, {
            [styles.caution]: true || props.row.original.leadStatusId === LeadStatus.DoNotContact,
            [styles.warn]: props.row.original.leadStatusId === LeadStatus.Inactive,
          })}>
            <WarningAmberIcon />
          </div>
        </Tooltip>}
    </div>
  );
};

Cell.displayName = 'Column.Name.Cell';

function showWarning(statusId: LeadStatus) {
  return [
    LeadStatus.DoNotContact,
    LeadStatus.Inactive,
  ].includes(statusId);
}

const copy = {
  [LeadStatus.Inactive]: `This respondent has been marked as Inactive.`,
  [LeadStatus.DoNotContact]: `This respondent has requested not to be contacted.`,
};