import { useCallback, useContext } from 'react';
import { Star } from 'react-feather';
import type { CellContext } from '@tanstack/react-table';
import { cx } from '@utils';
import type { ProjectClips } from '@/types/project.clips';
import { SetTaggedMomentEditParamsContext } from '@/containers/Transcript/context.tagged-moment';
import { ProjectClipsFiltersContext } from '@/containers/Project.Clips';
import styles from './style/Project.Clips.css';

type Props = CellContext<ProjectClips.Item, unknown>;

export const GoodQuoteCell = ({ row }: Props) => {

  const className = cx(styles.star, {
    [styles.active]: row.original.isGoodQuote,
  });

  const buildTagContext = useContext(SetTaggedMomentEditParamsContext);
  const filters = useContext(ProjectClipsFiltersContext);

  const handleAddGoodQuote = useCallback(() => {
    if (row.original.isGoodQuote) return;

    return buildTagContext({
      keyword: filters.keyword,
      isGoodQuote: true,
      transcriptId: row.original.transcript.id,
      range: row.original.range,
      tagId: null,
    });
  }, [
    filters.keyword,
    row.original.isGoodQuote,
    row.original.range,
    row.original.transcript.id,
    buildTagContext,
  ]);

  return (
    <Star
      onClick={handleAddGoodQuote}
      className={className} />
  );
};