import { MassRepeatResponsesDataContainer } from '@containers/RepeatSurveyResponses/MassSurveyDataContainer';
import { RepeatResponsesDataContainer } from '@containers/RepeatSurveyResponses/DataContainer';
import { SurveyResponsesDataContainer } from '@containers/SurveyResponses/DataContainer';
import { MassSurveyResponsesDataContainer } from '@containers/SurveyResponses/MassSurveyDataContainer';
import { SurveyResponsesStateContainer } from '@containers/SurveyResponses/SurveyResponsesState';
import { RepeatResponsesStateContainer } from '@containers/RepeatSurveyResponses/RepeatResponsesState';
import { ProjectType } from '@enums';
import { useIsMassSurvey, useProjectState, useProjectOrLatestChild } from './hooks';

export const DataContainer = (props: ChildrenProps) => {
  const project = useProjectOrLatestChild();

  if (!project?.projectType) {
    console.log('Survey responses container still awaiting project data');
  }

  const isSurveyLike = project?.projectType && [ProjectType.Survey, ProjectType.SurveyCall].includes(project?.projectType);

  if (!isSurveyLike) {
    return <div>{props.children}</div>;
  }
  else {
    return <InnerContainer>{props.children}</InnerContainer>;
  }
};

const InnerContainer = (props: ChildrenProps) => {
  const state = useProjectState();
  const isMassSurvey = useIsMassSurvey();

  if (state.project) {
    const Container = isMassSurvey
      ? MassSurveyResponsesDataContainer
      : SurveyResponsesDataContainer;

    return (
      <SurveyResponsesStateContainer>
        <Container>
          {props.children}
        </Container>
      </SurveyResponsesStateContainer>
    );
  } else {
    const Container = isMassSurvey
      ? MassRepeatResponsesDataContainer
      : RepeatResponsesDataContainer;

    return (
      <RepeatResponsesStateContainer>
        <Container>
          {props.children}
        </Container>
      </RepeatResponsesStateContainer>
    );
  }
};