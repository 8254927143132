import { createNamedContext } from '@utils';
import type { StepperContextValue } from '@/components/Stepper/interfaces';
import type { Step } from './Steps';
import type {
  AdditionalExpertise,
  Contact,
  Identification,
  MedicalBackgroundContextValue,
  MedicalBackgroundEnabledStateContextValue,
  MedicalOnboardingCompletionContextValue,
  MedicalExpertiseContextValue,
  Leadership,
  Practice,
} from './interfaces';

export const MedicalBackgroundContext = createNamedContext<MedicalBackgroundContextValue>(undefined, 'MedicalOnboarding.Background.Context');
export const MedicalBackgroundAdditionalExpertiseContext = createNamedContext<AdditionalExpertise.ContextValue>(undefined, 'MedicalOnboarding.Background.AdditionalExpertise.Context');
export const MedicalBackgroundContactContext = createNamedContext<Contact.ContextValue>(undefined, 'MedicalOnboarding.Background.Contact.Context');
export const MedicalBackgroundIdentificationContext = createNamedContext<Identification.ContextValue>(undefined, 'MedicalOnboarding.Background.Identification.Context');
export const MedicalBackgroundLeadershipContext = createNamedContext<Leadership.ContextValue>(undefined, 'MedicalOnboarding.Background.Leadership.Context');
export const MedicalBackgroundPracticeContext = createNamedContext<Practice.ContextValue>(undefined, 'MedicalOnboarding.Background.Practice.Context');
export const MedicalOnboardingCompletionContext = createNamedContext<MedicalOnboardingCompletionContextValue>(undefined, 'MedicalOnboarding.Completion.Context');
export const MedicalExpertiseContext = createNamedContext<MedicalExpertiseContextValue>(undefined, 'MedicalOnboarding.Expertise.Context');
export const MedicalBackgroundEnabledStateContext = createNamedContext<MedicalBackgroundEnabledStateContextValue>(undefined, 'MedicalOnboarding.Background.EnabledState.Context');
export const MedicalOnboardingScreensContext = createNamedContext<MedicalOnboardingScreensContextValue>(undefined, 'MedicalOnboarding.Screens.Context');

type MedicalOnboardingScreensContextValue = {
  lookup: Record<Step, boolean>;
  stepper: {
    context: StepperContextValue;
    route: {
      name: Step;
      next: string;
      path: string;
    };
    remaining: Step[];
  };
  steps:  Step[];
};