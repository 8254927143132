import { useState, useCallback } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { useQuery, useInfiniteQuery } from '@tanstack/react-query';
import { getTranscriptHighlights, getProjectTerms } from '@api/projects.transcripts';
import { TermFilterContext, ProjectTermsContext, HighlightQueryContext, TranscriptReplacementsContext, SelectedTermsContext } from './Context';
import type { TranscriptReplacement, GlobalReplacement, Mode, ConfirmedTerm } from './interfaces';

type Props = IProjectId & ChildrenProps;

export const TermsContainer = (props: Props) => {
  const [searchTerm, setSearchTerm] = useQueryParam('term', StringParam, { updateType: 'replaceIn' });
  const [replacementTerm, setReplacementTerm] = useState('');
  const [highlightTerm, setHighlightTerm] = useQueryParam('highlight', StringParam, { updateType: 'replaceIn' });
  const [mode, changeMode] = useState<Mode>('normal');

  const [replacements, setReplacements] = useState<TranscriptReplacement[]>([]);
  const [globalReplacements, setGlobalReplacements] = useState<GlobalReplacement[]>([]);
  const [confirmedTerms, setConfirmedTerms] = useState<ConfirmedTerm[]>([]);
  const [selectedTermValues, setSelectedTerms] = useState<string[]>([]);
  const [dictionaryWords, setDictionaryWords] = useState<string[]>([]);

  const addReplacements = useCallback((replacements: TranscriptReplacement[]) => {
    setReplacements(old => [...old, ...replacements]);
  }, []);

  const addGlobalReplacements = useCallback((replacements: GlobalReplacement[]) => {
    setGlobalReplacements(old => [...old, ...replacements]);
  }, []);

  const addConfirmedTerms = useCallback((terms: ConfirmedTerm[]) => {
    setConfirmedTerms(old => [...old, ...terms.filter(t => !old.some(o => o.term === t.term))]);
  }, []);

  const toggleTermValue = useCallback((val: string) => {
    setSelectedTerms(old => {
      if (old.includes(val)) {
        return old.filter(x => x !== val);
      } else {
        return [...old, val];
      }
    });
  }, []);

  const clearTermValues = useCallback(() => {
    setSelectedTerms([]);
  }, []);

  const addDictionaryWord = useCallback((val: string) => {
    setDictionaryWords(old => [...old, val]);
  }, []);

  const projectTermsQuery = useQuery({
    queryKey: ['get-project-terms', props.projectId],
    queryFn: () => {
      return getProjectTerms({ projectId: props.projectId });
    },
    placeholderData: { terms: [] },
    enabled: !!props.projectId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const infiniteHighlightsQuery = useInfiniteQuery(['get-project-term-highlights:infinite', props.projectId, highlightTerm], ctx => {
    return getTranscriptHighlights({ projectId: props.projectId, term: highlightTerm, searchAfter: ctx.pageParam as string[] });
  }, {
    enabled: highlightTerm?.length > 0,
    placeholderData: { pages: [], pageParams: [] },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    getNextPageParam: lastPage => lastPage.searchAfter,
  });

  return (
    <ProjectTermsContext.Provider value={{ query: projectTermsQuery }}>
      <TranscriptReplacementsContext.Provider value={{ replacements, addReplacements, projectId: props.projectId, mode, changeMode, globalReplacements, addGlobalReplacements, confirmedTerms, addConfirmedTerms, dictionaryWords, addDictionaryWord }}>
        <TermFilterContext.Provider value={{ searchTerm, setSearchTerm, replacement: replacementTerm, setReplacement: setReplacementTerm, highlightTerm, setHighlightTerm }}>
          <HighlightQueryContext.Provider value={{ query: infiniteHighlightsQuery }}>
            <SelectedTermsContext.Provider value={{ selectedTermValues, toggleTermValue, clearTermValues }}>
              {props.children}
            </SelectedTermsContext.Provider>
          </HighlightQueryContext.Provider>
        </TermFilterContext.Provider>
      </TranscriptReplacementsContext.Provider>
    </ProjectTermsContext.Provider>
  );
};