import { useCallback, useContext } from 'react';
import { SearchLoadingResetContext, SearchResultsResetContext } from '@/containers/WorkspaceGlobalSearch/Context';
import type { WorkspaceSearchFull } from '@/types';

export default function useSearchReset() {
  const loading = useContext(SearchLoadingResetContext);
  const results = useContext(SearchResultsResetContext);

  const reset = useCallback((tab: WorkspaceSearchFull.SearchTab) => {
    loading(tab);
    results(tab);
  }, [loading, results]);

  return reset;
}

export { useSearchReset };