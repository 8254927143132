import { useCallback, useContext } from 'react';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { BrandInsightsModalContext } from '@/brand-insights/components/BrandInsights/context';

export const MessageCitationMaybeLink = (props: LinkProps) => {
  const [_, toggleModal] = useContext(BrandInsightsModalContext);

  const handleClick = useCallback(_ => toggleModal(), [toggleModal]);

  if (props.to) {
    return (
      <Root {...props} onClick={handleClick}>
        {props.children}
      </Root>
    );
  } else {
    <>{props.children}</>;
  }
};

const Root = styled(Link)`
  display: inline-flex;

  &:hover div {
    color: ${props => props.theme.palette.hyperlink};
    text-decoration: underline;
  }
`;