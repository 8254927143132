import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRehydrateApp } from '@containers/Store';
import { useHydrateEffect, useAppReadyState } from '@containers/AppReadyState';
import { NotificationsSocketEvent } from '@enums';
import * as actions from '@store/actions';
import { Notification } from '@/types';
import { useNotificationsSocketEvent, notifications as $notifications } from '@services/websocket';
import { useFetchNotifications } from './hooks';

type Props =
  ChildrenProps;

const useFetchNotificationsOnHydrate = () => {
  const fetchNotifications = useFetchNotifications();
  useHydrateEffect(fetchNotifications);
};

export const NotificationsContainer = (props: Props) => {

  const dispatch = useDispatch();
  const rehydrateApp = useRehydrateApp();

  const { hydrated } = useAppReadyState();

  const handleDismiss = useCallback((data: { notificationIds: number[] }) => {
    dispatch(actions.notificationsDismissed({
      ids: data.notificationIds,
    }));
  }, [
    dispatch,
  ]);

  const handleSeen = useCallback((data: { notificationIds: number[] }) => {
    dispatch(actions.notificationsSeen({
      ids: data.notificationIds,
    }));
  }, [
    dispatch,
  ]);

  const handleNew = useCallback(async (notification: Notification) => {
    await rehydrateApp();

    dispatch(actions.notificationAdded({
      notification,
    }));
  }, [
    dispatch,
    rehydrateApp,
  ]);

  useNotificationsSocketEvent(NotificationsSocketEvent.New, handleNew, hydrated);
  useNotificationsSocketEvent(NotificationsSocketEvent.Dismiss, handleDismiss, hydrated);
  useNotificationsSocketEvent(NotificationsSocketEvent.Seen, handleSeen, hydrated);
  useNotificationsSocketEvent(NotificationsSocketEvent.AppRehydrate, rehydrateApp, hydrated);

  useEffect(() => {
    if (hydrated) {
      $notifications.open();
      return () => $notifications.close();
    }
  }, [hydrated]);

  useFetchNotificationsOnHydrate();

  return (
    <>{props.children}</>
  );
};

export default NotificationsContainer;