import * as enums from '@enums';
import { cx } from '@utils';
import styles from './style.css';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const StatusBadge = (props: Props) => (
  <div className={cx(styles.badge, props.className)}>
    {props.children}
  </div>
);

type PipelineStatusBadgeProps = {
  className?: string;
  statusId: enums.UserProjectStatus;
};

export const PipelineStatusBadge = (props: PipelineStatusBadgeProps) => (
  <StatusBadge className={props.className}>
    {enums.utils.UserProjectStatus.getName(props.statusId)}
  </StatusBadge>
);

type CallStatusBadgeProps = {
  className?: string;
  statusId: enums.CallStatus;
};

export const CallStatusBadge = (props: CallStatusBadgeProps) => (
  <StatusBadge className={props.className}>
    {enums.utils.CallStatus.getName(props.statusId)}
  </StatusBadge>
);

export { StatusBadge };
export default StatusBadge;