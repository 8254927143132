import { path } from '@consts';
import { RouteAuth } from '@enums';
import { SessionRouter } from '@routes/Routers';
import * as Routing from '@routes/interfaces';
import { GroupContacts } from '@screens/Group.Contacts';
import { roles } from '@utils';

const routes: Routing.Route[] = [
  {
    component: GroupContacts,
    path: path.Contacts.Root,
  },
];

export const router = SessionRouter(routes, {
  auth: RouteAuth.Authenticated,
  path: path.Contacts.Root,
  roles: roles.client,
});