import { useCallback } from 'react';
import type { MatrixRangeQuestion, SurveyQuestionOption } from '@/types';
import styles from './style/MatrixRange.css';
import { Rationale } from './Rationale';

type Props = {
  answer: MatrixRangeQuestion.Answer.Value;
  question: MatrixRangeQuestion.Question;
  rationale: string;
};

export const MatrixRangeAnswerValue = ({ answer, rationale, question }: Props) => {
  const getOptionFallbackValue = useCallback((optionBaseId: number) => {
    return question.options.find(o => o.base.id === optionBaseId)?.metadata?.helpText;
  }, [question.options]);

  return (
    <>
      {answer.items.map(item => (
        <div className={styles.item} key={item.row.id}>
          {`${item.options[0].value || getOptionFallbackValue(item.options[0].base.id)} - ${item.row.value}`}
        </div>
      ))}
      <Rationale value={rationale} />
    </>
  );
};