import { useCallback } from 'react';
import { ProjectReportType } from '@/types';
import { useSelectProject } from '@containers/Store';

type Props = {
  projectId: number;
};

export const useTrackProjectReportDownload = ({ projectId }: Props) => {
  const project = useSelectProject(projectId);

  return useCallback((format: ProjectReportType) => {
    // note: placeholder
  }, [
    project,
    projectId,
  ]);
};

export default useTrackProjectReportDownload;