import { Fragment, useContext, useLayoutEffect } from 'react';
import styled from '@emotion/styled';
import type { Chat } from '@/brand-insights/types';
import { ChatSessionPendingFindQuotesContext } from './context';
import { useChatPermissions, useScrollToBottomOfMessages } from './hooks';
import { InitialSystemMessage } from './Message.System.Initial';
import { ChatItem } from './Item';
import { BasicSystemUserMessage } from './Message.System.Basic';

type Props = {
  items: Chat.InstanceItem[];
};

export const MessageList = ({ items }: Props) => {
  const scrollToBottom = useScrollToBottomOfMessages();
  const chatPermissions = useChatPermissions();
  const findQuotesPending = useContext(ChatSessionPendingFindQuotesContext);

  useLayoutEffect(() => {
    scrollToBottom();
  }, [scrollToBottom]);

  const view = {
    regenerate: chatPermissions.canModify,
  };

  return (
    <Fragment>
      <MessageContainer>
        <InitialSystemMessage />
      </MessageContainer>
      {items.map((item, i) => (
        <ChatItem
          key={i}
          canRegenerate={view.regenerate && i === items.length - 1}
          item={item} />
      ))}
      {findQuotesPending && <FindQuotesMessage />}
    </Fragment>
  );
};

const FindQuotesMessage = () => {

  const message = 'Sure, what kind of quote are you looking for? Please provide a keyword or topic of interest and I can retrieve some relevant quotes from transcripts.';

  return (
    <MessageContainer>
      <BasicSystemUserMessage
        value={message} />
    </MessageContainer>
  );
};

const MessageContainer = styled.div`
  padding: 15px 20px;
`;