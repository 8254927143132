import { useRef } from 'react';
import FormatBold from '@mui/icons-material/FormatBold';
import FormatItalic from '@mui/icons-material/FormatItalic';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import FormatListNumbered from '@mui/icons-material/FormatListNumbered';
import FormatUnderlined from '@mui/icons-material/FormatUnderlined';
import InsertLink from '@mui/icons-material/InsertLink';
import InsertPhoto from '@mui/icons-material/InsertPhotoOutlined';
import {
  useImageHandler,
  useBoldCommand,
  useItalicCommand,
  useUnderlineCommand,
  useLinkCommand,
  useOrderedListCommand,
  useBulletListCommand,
} from '@/components/Remirror/hooks';
import { ToolbarButton } from './Toolbar.Button';
import styles from './style/Toolbar.css';

type Props = {
  className?: string;
};

export const BoldButton = ({ className }: Props) => {

  const {
    active,
    enabled,
    toggle,
  } = useBoldCommand();

  return (
    <ToolbarButton
      className={className}
      disabled={!enabled}
      enabled={active}
      onClick={toggle}>
      <FormatBold />
    </ToolbarButton>
  );
};

export const ItalicButton = ({ className }: Props) => {
  const {
    active,
    enabled,
    toggle,
  } = useItalicCommand();

  return (
    <ToolbarButton
      className={className}
      disabled={!enabled}
      enabled={active}
      onClick={toggle}>
      <FormatItalic />
    </ToolbarButton>
  );
};

export const UnderlineButton = ({ className }: Props) => {
  const {
    active,
    enabled,
    toggle,
  } = useUnderlineCommand();

  return (
    <ToolbarButton
      className={className}
      disabled={!enabled}
      enabled={active}
      onClick={toggle}>
      <FormatUnderlined />
    </ToolbarButton>
  );
};

export const LinkButton = () => {
  const {
    active,
    onClick,
  } = useLinkCommand();

  return (
    <ToolbarButton
      enabled={active}
      onClick={onClick}>
      <InsertLink />
    </ToolbarButton>
  );
};

export const ImageButton = (props: unknown) => {
  const input = useRef<HTMLInputElement>();

  const imageHandler = useImageHandler();

  return (
    <ToolbarButton>
      <InsertPhoto />
      <input
        className={styles.input}
        accept="image/png,image/jpeg,image/gif"
        name="image"
        onChange={imageHandler}
        onClick={() => input.current.value = ''}
        ref={input}
        type="file" />
    </ToolbarButton>
  );
};

export const ListBulletedButton = () => {

  const {
    active,
    enabled,
    toggle,
  } = useBulletListCommand();

  return (
    <ToolbarButton
      disabled={!enabled}
      enabled={active}
      onClick={toggle}>
      <FormatListBulleted />
    </ToolbarButton>
  );
};

export const ListOrderedButton = () => {
  const {
    active,
    enabled,
    toggle,
  } = useOrderedListCommand();

  return (
    <ToolbarButton
      disabled={!enabled}
      enabled={active}
      onClick={toggle}>
      <FormatListNumbered />
    </ToolbarButton>
  );
};

export { ColorButton } from './Toolbar.ColorButton';