import { SurveyLogic, SurveyBuilderVersion } from '@/types/survey';
import { validateLogicAction } from './validation.action';
import { validateConditions } from './validation.conditions';
import { validateRule } from './validation.rule';

export * from './validation.action';
export * from './validation.conditions';
export * from './validation.condition.sum';
export * from './validation.rule';

export function validateLogicItem(item: SurveyLogic.Item, survey: SurveyBuilderVersion) {

  const actionValidation = validateLogicAction(item, survey);
  const ruleValidation = validateRule(item.rule);

  const {
    map: conditionsMap,
    success: conditionsValid,
  } = validateConditions(item.conditions, survey);

  return {
    action: actionValidation,
    conditions: conditionsMap,
    rule: ruleValidation,
    success: actionValidation.success &&
      conditionsValid &&
      ruleValidation.success,
  };
}