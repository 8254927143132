export function roundToPlace(value: number, place: number) {
  const factor = Math.pow(10, place);
  return Math.round((value + Number.EPSILON) * factor) / factor;
}

export function sum(values: number[]): number {
  return values.reduce((sum, val) => sum + val, 0);
}

export function avg(values: number[]): number {
  if (!values.length) return 0;
  return values.reduce((a, b) => a + b) / values.length;
}