import { memo, useContext } from 'react';
import Skeleton from '@mui/material/Skeleton';
import styles from '@screens/Workspace.File/style/Header.Actions.css';
import { FolderLoadingContext, FolderStateContext } from '@/containers/Workspace.Folder/Context';
import { DiscoverInsights } from './Header.DiscoverInsights';
import { FolderContextMenu } from './ContextMenu';
import { Access } from './Header.Access';

type Props = unknown;

export const Actions = (props: Props) => {
  const isLoading = useContext(FolderLoadingContext);

  return isLoading
    ? <SkeletonActions />
    : <InteractiveActions />;
};

Actions.displayName = 'Folder.Actions';

const InteractiveActions = (props: Props) => {
  const state = useContext(FolderStateContext);

  return (
    <div className={styles.root}>
      {state?.features?.insightsChat && <DiscoverInsights />}
      <Access />
      <FolderContextMenu />
    </div>
  );
};

InteractiveActions.displayName = 'Folder.Actions.Interactive';

const SkeletonActions = memo((props: Props) => {
  return (
    <div className={styles.actions}>
      <Skeleton
        height={30}
        variant="rounded"
        width={70} />
      <Skeleton
        height={30}
        variant="rounded"
        width={70} />
      <Skeleton
        height={30}
        variant="rounded"
        width={70} />
    </div>
  );
});

SkeletonActions.displayName = 'Folder.Actions.Skeleton';