export enum NodeType {

  /* survey */
  AdditionalPriceLink = 'price-link',
  PipedResponse = 'piped',
  TemplateCategory = 'category',
  TemplateNode = 'template-value', // Use this going forward
  TemplateTarget = 'target',
  TemplateCompetitor = 'competitor',
  TPP = 'tpp',
  ViewStimulus = 'view-stimulus',
  ViewTPP = 'view-tpp',
  ViewTPP_RT = 'view-tpp-rt',
  FindAndReplace = 'find-and-replace',
  /* end survey */

  BulletList = 'bulletList',
  Doc = 'doc',
  ListItem = 'listItem',
  Paragraph = 'paragraph',
  Text = 'text',
  Image = 'image',
}

export type RootNodeContent = ParagraphNode;

export type RootNode<C = RootNodeContent> = {
  type: NodeType.Doc;
  content: C[];
};

export type BoldMark = {
  type: 'bold';
};

export type ItalicMark = {
  type: 'italic';
};

export type UnderLinkMark = {
  type: 'underline';
};

export type LinkMark = {
  attrs: {
    href: string;
  };
  type: 'link';
};

export type ParagraphNode<C = TextNode> = {
  content: C[];
  type: NodeType.Paragraph;
};

type TextMark = BoldMark | ItalicMark | UnderLinkMark | LinkMark;

export type TextNode<M = TextMark> = {
  marks?: M[];
  type: NodeType.Text;
  text: string;
};

export type ListItemNode<P = ParagraphNode> = {
  attrs: {
    closed: boolean;
    nested: boolean;
  };
  content: P[];
  type: NodeType.ListItem;
};

export type BulletListNode<P = ParagraphNode> = {
  content: ListItemNode<P>[];
  type: NodeType.BulletList;
};

export type ImageExtensionAttributes = {
  align?: 'center' | 'end' | 'justify' | 'left' | 'match-parent' | 'right' | 'start';
  alt?: string;
  height?: string | number;
  width?: string | number;
  rotate?: string;
  src: string;
  title?: string;

  /** The file name used to create the image. */
  fileName?: string;
};

export type ImageNode = {
  type: NodeType.Image;
  attrs: ImageExtensionAttributes;
};
