import { useContext } from 'react';
import { filterResponses } from '@containers/GroupProject/utils/survey-filtering';
import { ProjectSurveyResponsesContext } from '@containers/SurveyResponses/Context';
import { ProjectResponseFilterContext, SurveyResponsesFilterDataContext } from '@containers/GroupProject/Context';
import { useSurvey } from '@containers/Survey/hooks/useSurvey';

const useProjectSurveyResponses = () => {
  const survey = useSurvey();
  const responses = useContext(ProjectSurveyResponsesContext);
  const filterContext = useContext(ProjectResponseFilterContext);
  const filterDataContext = useContext(SurveyResponsesFilterDataContext);

  if (filterContext?.filter) {
    return filterResponses({
      filter: filterContext.filter,
      questions: survey.questions,
      responses,
      allData: { responses: filterDataContext.allResponses, questions: filterDataContext.allQuestions },
    });
  }

  return responses;
};

export { useProjectSurveyResponses };
export default useProjectSurveyResponses;