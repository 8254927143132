import { useCallback, useMemo } from 'react';
import useSearchFiltersQuery from '@/containers/WorkspaceGlobalSearch/hooks/useSearchFilterQuery';
import GroupTagAutocomplete from '@/components/AutoComplete/GroupTag';
import { Chip } from '@/presentation/Chip';
import type { GroupTag } from '@/types';
import { useReducedFilterState } from './hooks/userReducedFilterState';
import styles from './style/Main.Filters.Tag.css';

export const SearchMainFiltersTag = () => {
  const [, setQuery] = useSearchFiltersQuery();
  const filters = useReducedFilterState();

  const handleSelect = useCallback((tag: GroupTag) => {
    setQuery({
      tags: Array.from([...filters.tags, tag]),
    });
  }, [filters.tags, setQuery]);

  const handleTagClick = useCallback((tag: GroupTag) => () => {
    setQuery({
      tags: filters.tags.filter(t => t.id !== tag.id),
    });
  }, [filters.tags, setQuery]);

  const handleClear = useCallback(() => {
    setQuery({ tags: [] }, 'replaceIn');
  }, [setQuery]);

  const showClear = useMemo(() => filters.tags.length > 1, [filters.tags]);

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.title}>Tag</div>
        {showClear && <div className={styles.clear} onClick={handleClear}>Clear</div>}
      </div>
      <div className={styles.tag}>
        <GroupTagAutocomplete
          allowCustom={false}
          ignoreList={filters.tags}
          onSelect={handleSelect}
          placeholder="Enter a tag" />
      </div>
      <div className={styles.tags}>
        {filters.tags.map(tag => (
          <Chip
            className={styles.selected}
            color="selected"
            key={`tag_${tag.id}`}>
            <Chip.Body.Action
              className={styles.action}
              onClick={handleTagClick(tag)}
              variant="remove">
              <div className={styles.label}>{tag.name}</div>
            </Chip.Body.Action>
          </Chip>
        ))}
      </div>
    </div>
  );
};