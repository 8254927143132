import { path } from '@consts';
import { RouteAuth } from '@enums';
import { BasicRouter } from '@routes/Routers';
import * as Routing from '@routes/interfaces';
import { ProjectDisqualification, ProjectSuccess, ProjectQuotaLimit } from '@screens/ExternalProject';

export const routes: Routing.Route[] = [
  {
    component: ProjectDisqualification,
    exact: true,
    path: path.ExternalProject.Disqual,
  },
  {
    component: ProjectSuccess,
    exact: true,
    path: path.ExternalProject.Success,
  },
  {
    component: ProjectQuotaLimit,
    exact: true,
    path: path.ExternalProject.QuotaLimit,
  },
];

export const router = BasicRouter(routes, {
  auth: RouteAuth.Ignore,
  path: path.ExternalProject.Root,
});