import { useCallback } from 'react';
import * as $api from '@api';
import Toast from '@/components/Toast';
import type { RenameFileParams } from '../interfaces';

export const useRenameFile = () => {

  const renameFile = useCallback((data: RenameFileParams) => {
    return $api.workspaces.file.renameFile({
      fileId: data.fileId,
      name: data.name,
      workspaceId: data.workspaceId,
    }).then(({ file, object }) => {
      Toast.alert({
        title: 'File Renamed',
      });
      return data;
    }).catch(e => {
      Toast.error({
        title: 'Error',
        body: `We're sorry, there was an issue with your request`,
      });
      throw e;
    });

  }, []);

  return renameFile;
};

export default useRenameFile;