import { useCallback, useMemo } from 'react';
import type { CSSObjectWithLabel } from 'react-select';
import Select from 'react-select';
import type { SurveyQuestionOption } from '@/types';
import { parseSurveyRichText } from '@/containers/Survey/utils';

type Props = {
  disabled?: boolean;
  items: SurveyQuestionOption[];
  onChange: (options: SurveyQuestionOption[]) => void;
  placeholder?: string;
  values: SurveyQuestionOption[];
};

type DropdownItem = {
  id: string;
  value: string;
};

export const MultiOptionsDropdown = ({
  disabled,
  items,
  onChange,
  placeholder,
  values,
}: Props) => {

  const options = useMemo(() => {
    return items.map(m => ({
      id: m.base.identifier,
      value: parseSurveyRichText(m.value),
    }));
  }, [items]);

  const getItemValue = useCallback((item: DropdownItem) => {
    return item?.value || '';
  }, []);

  const selectedValues = useMemo(() => {
    return options.filter(o => values.some(v => v.base.identifier === o.id));
  }, [options, values]);

  const handleChange = useCallback((vals: string[]) => {
    return onChange(vals.map(v => items.find(i => i.base.identifier === v)));
  }, [items, onChange]);

  return (
    <>
      <Select
        isMulti={true}
        placeholder={placeholder || 'Select Option'}
        isDisabled={disabled}
        options={options}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: base => ({ ...base, zIndex: 8 } as CSSObjectWithLabel),
        }}
        onChange={vals => handleChange(vals.map(v => v.id))}
        getOptionLabel={getItemValue}
        value={selectedValues} />
    </>
  );
};

export default MultiOptionsDropdown;