import { useEffect } from 'react';
import { useProfileContact } from '@containers/AccountProfile';
import { UserProfilePostsContainer } from '@containers/UserProfile';
import { useContentFeedState, useFetchContentFeed } from '@containers/ContentFeed';
import { Column, FixedColumn, Scaffold } from '@screens/Main.Member/Layout';
import { Card } from '@screens/Main.Member/Card';
import { ProfilePreview } from '@/components/UserProfile';
import { AnimatedLoader } from '@/components/ActivityIndicator';
import Feed from './Feed';
import styles from './UserProfilePosts.css';

const UserProfilePosts = () => {

  const [state] = useContentFeedState();
  const contact = useProfileContact();

  const fetch = useFetchContentFeed();

  useEffect(() => {
    fetch();
  }, [fetch]);

  if (state.loading) return <AnimatedLoader className={styles.loading} />;

  return (
    <Scaffold>
      <FixedColumn>
        {contact &&
          <Card>
            <ProfilePreview
              user={contact} />
          </Card>
        }
      </FixedColumn>

      <Column>
        <Feed />
      </Column>

      <Column />
    </Scaffold>
  );
};

const Container = () => {

  return (
    <UserProfilePostsContainer>
      <UserProfilePosts />
    </UserProfilePostsContainer>
  );
};

export { Container as UserProfilePosts };
export default Container;