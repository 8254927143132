import { useCallback, useState, useMemo, memo } from 'react';
import MuiMenu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { cx } from '@utils';
import { ProjectActionsMenuContext } from './Context';
import styles from './style/Menu.css';

type Props = ChildrenProps;

export const Menu = memo(({ children }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = !!anchorEl;

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const value = useMemo(() => ({
    close: handleClose,
  }), [handleClose]);

  return (
    <ProjectActionsMenuContext.Provider value={value}>
      <div
        className={cx(styles.btn, { [styles.open]: open })}
        onClick={handleClick}>
        <div className={styles.btnLabel}>
          Actions
        </div>
        <Divider
          sx={{
            marginLeft: '4px',
            marginRight: '4px',
          }}
          variant="middle"
          orientation="vertical" />
        <KeyboardArrowDownIcon
          sx={{
            height: '20px',
            width: '20px',
          }} />
      </div>
      <MuiMenu
        disableAutoFocusItem
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transitionDuration={{
          appear: 0,
          enter: 0,
          exit: 0,
        }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: '4px',
              transition: 'duration 0 !important',
            },
          },
        }}
        MenuListProps={{
          sx: {
            padding: '0',
          },
        }}>
        {children}
      </MuiMenu>
    </ProjectActionsMenuContext.Provider>
  );
});