import { ProjectDisqualification } from './ProjectDisqualification';

const SurveyDisqualification = () => {

  return (
    <ProjectDisqualification title={title} />
  );
};

const title = `Hmm, looks like this survey may not be a fit for your particular experiences, but we will keep you posted on future opportunities.`;

export { SurveyDisqualification };
export default SurveyDisqualification;