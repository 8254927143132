import { useState, useRef, useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import * as $api from '@/services/api';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import { useModal } from '@/components/Modal/hooks/useModal';
import { Checkbox } from '@/components/Checkbox';
import Toast from '@/components/Toast';
import { ButtonActivityIndicator } from '@/presentation';
import { formatFileSize } from '@/utils';
import type { Integrations } from '@/types';
import styles from './style/ZoomRecordingPickerModal.css';

type SelectProps = {
  onSelect: (files: File[]) => void;
};
type Props = SelectProps & Pick<ModalProps, 'open' | 'onClose'>;

type PageTokenMap = Record<number, string>;

type SelectedRecording = {
  meetingId: string;
  recordingId: string;
};

export const ZoomRecordingPickerModal = (props: Props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRecordings, setSelectedRecordings] = useState<SelectedRecording[]>([]);
  const tokenMap = useRef<PageTokenMap>({
    1: null,
  });
  const { data, isLoading, isError } = useQuery([currentPage], async () => {
    return $api.integrations.getZoomRecordings({ paginationToken: tokenMap.current[currentPage] }).then(r => {
      if (r.nextPageToken) {
        tokenMap.current[currentPage + 1] = r.nextPageToken;
      }
      return r;
    });
  });

  const onCheckboxChange = useCallback((isChecked: boolean, meetingId: string, recordingId: string) => {
    if (isChecked) {
      setSelectedRecordings(r => [...r, { recordingId, meetingId }]);
    } else {
      setSelectedRecordings(r => r.filter(x => x.recordingId != recordingId));
    }
  }, [setSelectedRecordings]);

  const MeetingRow = useCallback(({ meeting, index }: { meeting: Integrations.ZoomMeeting; index: number }) => {
    function recordingName(recording: Integrations.ZoomRecording) {
      return recording.recordingType.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
    }

    function formatDate(date: Date) {
      return format(date, 'M/d/yyyy h:mm a')
    }

    const className = index % 2 == 0 ? styles.evenMeeting : styles.oddMeeting;

    return (
      <>
        <tr key={meeting.uuid} className={className}>
          <td />
          <td>
            {meeting.topic}
          </td>
          <td>
            {formatDate(meeting.startTime)}
          </td>
          <td />
          <td />
        </tr>
        {meeting.recordingFiles.filter(f => f.downloadUrl && f.status == 'completed').map((r, i) => (
          <tr key={r.id} className={className}>
            <td>
              <Checkbox
                checked={selectedRecordings.some(x => x.recordingId == r.id)}
                onChange={(_, checked) => onCheckboxChange(checked, meeting.uuid, r.id)} />
            </td>
            <td><span className={styles.recordingName}>{recordingName(r)}</span></td>
            <td>
              {formatDate(r.recordingStart)}
            </td>
            <td>
              {r.fileType}
            </td>
            <td>
              {formatFileSize(r.fileSize, true)}
            </td>
          </tr>
        ))}
      </>
    );
  }, [onCheckboxChange, selectedRecordings]);

  const onSubmit = useCallback(() => {
    for (const r of selectedRecordings) {
      $api.integrations.getZoomRecordingFile(r).then(r => {
        props.onSelect([new File([r.blob], r.filename)]);
      }).catch(err => {
        Toast.error({
          title: 'There was an error downloading the file you selected.',
        });
        throw err;
      });
    }

    props.onClose();
  }, [selectedRecordings, props]);

  const ModalContent = useCallback(() => {
    if (isLoading) {
      return <div>Loading</div>;
    } else if (isError) {
      return <div>There was an error fetching your recordings. Please view <a href='https://sentimentglobal.zendesk.com/hc/en-us/articles/4704288676375-How-do-I-integrate-my-Zoom-account-with-Vancery-' target={'_blank'} rel={'noreferrer'}>this FAQ</a> for troubleshooting.</div>;
    }
    else if (!data.meetings.length) {
      return <div>You have no recordings associated with this account.</div>;
    } else {
      return (
        <>
          <table className={styles.table}>
            <thead>
              <tr>
                <td />
                <td>
                  Name
                </td>
                <td>
                  Date
                </td>
                <td>
                  Type
                </td>
                <td>Size</td>
              </tr>
            </thead>
            <tbody>
              {data.meetings.map((m, i) => (
                <MeetingRow
                  meeting={m}
                  index={i}
                  key={m.id} />
              ))}
            </tbody>
          </table>
          <ButtonActivityIndicator disabled={!selectedRecordings.length} onClick={onSubmit}>
            Upload
          </ButtonActivityIndicator>
        </>
      );
    }
  }, [data, isLoading, isError, MeetingRow, onSubmit]);

  return (
    <Modal {...props}>
      <ModalContent />
    </Modal>);
};

export const useZoomRecordingsPickerModal = () => useModal(ZoomRecordingPickerModal);