import http from '@services/http';
import * as cancellation from '@services/http/cancellation';
import { normalizeMedia } from '@transformers/posts';
import { safeJsonDate } from '@utils';
import { UniversalSearch } from './interfaces';

export const bar = <R extends UniversalSearch.Bar.Response>(data: UniversalSearch.Bar.Request): ExtendedPromise<R> => {
  return http.post<R>(`/universal-search/bar`, {
    query: data.query,
  }, { cancelToken: data.cancelToken })
  .catch(e => cancellation.ignoreRequestCancellationError(e));
};

export const addPast = <R extends UniversalSearch.AddPast.Response>(data: UniversalSearch.AddPast.Request): ExtendedPromise<R> => {
  return http.post<R>(`/universal-search/past`, {
    query: data.query,
    entityId: data.entityId,
    item: data.item,
  }, { cancelToken: data.cancelToken })
  .catch(e => cancellation.ignoreRequestCancellationError(e));
};

export const getPast = (data?: UniversalSearch.GetPast.Request): ExtendedPromise<UniversalSearch.GetPast.Response> => {
  return http.get<UniversalSearch.GetPast.RawResponse>(`/universal-search/past`, {
    cancelToken: data?.cancelToken,
  })
  .then(items => items.items)
  .catch<UniversalSearch.GetPast.Response>(e => cancellation.ignoreRequestCancellationError(e, []));
};

export const experts = <R extends UniversalSearch.Experts.Response>(data: UniversalSearch.Experts.Request): ExtendedPromise<R> => {
  return http.post<R>(`/universal-search/experts`, {
    query: data.query,
    size: data.size,
  }, { cancelToken: data.cancelToken })
  .catch(e => cancellation.ignoreRequestCancellationError(e));
};

export const topics = <R extends UniversalSearch.Topics.Response>(data: UniversalSearch.Topics.Request): ExtendedPromise<R> => {
  return http.post<R>(`/universal-search/topics`, {
    query: data.query,
    size: data.size,
  }, { cancelToken: data.cancelToken })
  .catch(e => cancellation.ignoreRequestCancellationError(e));
};

export const posts = (data: UniversalSearch.Posts.Request): ExtendedPromise<UniversalSearch.Posts.Response> => {
  return http.post(`/universal-search/posts`, {
    query: data.query,
    size: data.size,
  }, { cancelToken: data.cancelToken })
  .then((result: UniversalSearch.Posts.Response<string>) => {
    return {
      items: result.items.map(item => ({
        ...item,
        metadata: {
          ...item.metadata,
          media: normalizeMedia(item.metadata.media),
          createdOn: safeJsonDate(item.metadata.createdOn),
        },
      })),
      total: result.total,
    };
  })
  .catch<UniversalSearch.Posts.Response>(e => cancellation.ignoreRequestCancellationError(e, null));
};