import { useCallback, useMemo } from 'react';
import cuid from 'cuid';
import type { ReactFrameworkOutput } from '@remirror/react';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { getFindAndReplaceGroupingId, getFindAndReplaceLabel } from '@/containers/Survey/utils/rich-text.find-and-replace';
import type { SurveyQuestion, SurveyQuestionMatrixRow, SurveyQuestionRowMetadata } from '@/types/survey';
import type { ContextMenuItemProps } from '@/components/SurveyBuilder/ContextMenu.Item';
import { useAddFindAndReplaceNode } from '@/components/Survey.RichText/hooks/useAddFindAndReplaceNode';
import { useFindAndReplaceEditModal } from '@/components/Survey.RichText/Node.FindAndReplace.Modal.Edit';
import type { SurveyRichTextEditorExtension } from '@/components/Survey.RichText/interfaces';

export const useOpenEndedRowMenuItem = (props: OpenEndedProps) => {

  const [_, dispatch] = useSurveyBuilderState();

  const isOpenEnded = !!props.row.metadata.isOpenEnded;

  const toggleOpenEnded = useCallback(() => {
    dispatch({
      type: 'row-openended-updated',
      payload: {
        row: { identifier: props.row.base.identifier },
        questionIdentifier: props.questionIdentifier,
        value: !isOpenEnded,
      },
    });
  }, [
    dispatch,
    props.questionIdentifier,
    props.row.base.identifier,
    isOpenEnded,
  ]);

  const openEndedMenuItem: ContextMenuItemProps = useMemo(() => {

    return {
      checked: isOpenEnded,
      children: 'Open ended',
      onClick: toggleOpenEnded,
    };
  }, [isOpenEnded, toggleOpenEnded]);

  return openEndedMenuItem;
};

export const useAnchoredRowMenuItem = (props: AnchorProps) => {

  const [_, dispatch] = useSurveyBuilderState();

  const isAnchored = !!props.row.metadata.isAnchored;

  const toggle = useCallback(() => {
    dispatch({
      type: 'row-anchor-updated',
      payload: {
        row: { identifier: props.row.base.identifier },
        questionIdentifier: props.question.base.identifier,
        value: !isAnchored,
      },
    });
  }, [
    dispatch,
    props.question.base.identifier,
    props.row.base.identifier,
    isAnchored,
  ]);

  const anchoredMenuItem: ContextMenuItemProps = useMemo(() => {
    return {
      checked: isAnchored,
      children: 'Anchored',
      onClick: toggle,
    };
  }, [isAnchored, toggle]);

  return anchoredMenuItem;
};

export const useIsLastRowInGridMenuItem = (props: LastRowInGridProps) => {
  const [_, dispatch] = useSurveyBuilderState();

  const isLastRowInCurrentGrid = !!props.row.metadata.isLastRowInCurrentGrid;

  const toggle = useCallback(() => {
    dispatch({
      type: 'last-row-in-grid-updated',
      payload: {
        row: { identifier: props.row.base.identifier },
        questionIdentifier: props.question.base.identifier,
        value: !isLastRowInCurrentGrid,
      },
    });
  }, [
    dispatch,
    props.question.base.identifier,
    props.row.base.identifier,
    isLastRowInCurrentGrid,
  ]);

  const anchoredMenuItem: ContextMenuItemProps = useMemo(() => {
    return {
      checked: isLastRowInCurrentGrid,
      children: 'Start new grid after this row',
      onClick: toggle,
    };
  }, [isLastRowInCurrentGrid, toggle]);

  return anchoredMenuItem;
};

type FindAndReplaceRowMenuItemProps = {
  getContext?: () => ReactFrameworkOutput<SurveyRichTextEditorExtension>;
};

export const useFindAndReplaceRowMenuItem = ({ getContext }: FindAndReplaceRowMenuItemProps = {}): ContextMenuItemProps => {
  const [toggleModal, Modal] = useFindAndReplaceEditModal();
  const addNode = useAddFindAndReplaceNode();

  const handleClick = useCallback(() => {
    toggleModal();
  }, [toggleModal]);

  const handleSubmit = useCallback((label: string) => {
    const identifier = cuid();

    const attrs = {
      identifier,
      groupingId: getFindAndReplaceGroupingId(label, label),
      label: getFindAndReplaceLabel(label, 1, 1),
      value: getFindAndReplaceLabel(label, 1, 1),
    };

    if (getContext) {
      const chain = getContext().chain;
      addNode(attrs, chain);
    } else {
      addNode(attrs);
    }

    toggleModal();
  }, [
    addNode,
    toggleModal,
    getContext,
  ]);

  return useMemo(() => ({
    children: 'Insert structured entry',
    onClick: handleClick,
    Modal: () => <Modal onSubmit={handleSubmit} />,
  }), [
    Modal,
    handleClick,
    handleSubmit,
  ]);
};

type OpenEndedProps = {
  questionIdentifier: string;
  row: SurveyQuestionMatrixRow<SurveyQuestionRowMetadata<{ isOpenEnded: boolean }>>;
};

type AnchorProps = {
  question: SurveyQuestion;
  row: SurveyQuestionMatrixRow<SurveyQuestionRowMetadata<{ isAnchored: boolean }>>;
};

type LastRowInGridProps = {
  question: SurveyQuestion;
  row: SurveyQuestionMatrixRow<SurveyQuestionRowMetadata<{ isLastRowInCurrentGrid: boolean }>>;
};