import { qs } from '@utils';
import http from '@services/http';
import { Branding } from '@api/interfaces/groups';

export const fetchBranding = (data: Branding.FetchBranding.Request) => {
  return http.get<Branding.FetchBranding.Response>(`/groups/${data.groupId}/branding`);
};

export const removeLogo = (data: Branding.RemoveLogo.Request) => {
  const q = qs.stringify({
    orientation: data.orientation,
  });

  return http.delete<Branding.RemoveLogo.Response>(`/groups/${data.groupId}/branding/logo?${q}`);
};

export const setColorCategoryTheme = (data: Branding.SetColorCategoryTheme.Request): Promise<Branding.SetColorCategoryTheme.Response> => {
  const { groupId, ...params } = data;

  return http.put(`/groups/${groupId}/branding/theming`, params);
};

export const updateLogo = (data: Branding.UpdateLogo.Request): Promise<Branding.UpdateLogo.Response> => {
  const formData = new FormData();
  formData.append('blob', data.blob);
  formData.append('orientation', String(data.orientation));

  return http.post(`/groups/${data.groupId}/branding/logo`, formData, {
    headers: {
      'Content-Type': `multipart/form-data`,
    },
  });
};

export * as palettes from './groups.branding.palettes';
export * as surveys from './groups.branding.surveys';