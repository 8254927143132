import { ProjectPipelineQueryContainer } from './Project.Pipeline.Query.Container';

type Props = {
  children: React.ReactNode;
  enabled:  boolean;
} & IProjectId;

export const ProjectPipelineContainer = (props: Props) => {
  return (
    <ProjectPipelineQueryContainer
      enabled={!!props.projectId && props.enabled}
      projectId={props.projectId}>
      {props.children}
    </ProjectPipelineQueryContainer>
  );
};

ProjectPipelineContainer.displayName = 'Project.Pipeline.Container';