const titlecaseByDelimiter = (k: string, delimiter: string) => {
  return k.split(delimiter)
    .map(m => m.charAt(0).toUpperCase() + m.slice(1)).join(delimiter);
};

const titlecase = (k: string) => {
  return titlecaseByDelimiter(titlecaseByDelimiter(k, ' '), '-');
};

export default {
  titlecase,
};

export {
  titlecase,
};