import { forwardRef, useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Download, UploadCloud } from 'react-feather';
import { CSVFileContext } from '@containers/Group.Contacts.Import/Context';
import { ImportOptionsToggleViewContext } from './ImportOptions.Context';
import { ImportOptionsToolSelect } from './ImportOptions.ToolSelect';
import styles from './style/ImportOptions.StartMenu.css';

type Props = unknown;

export const ImportOptionsStartMenu = (props: Props) => {
  const history = useHistory();
  const [, setFile] = useContext(CSVFileContext);
  const toggleView = useContext(ImportOptionsToggleViewContext);

  const handleFileInput = useCallback((file: File) => {
    setFile(file);

    if (file) {
      history.push(`/contacts/import/fields`);
    }
  }, [
    history,
    setFile,
  ]);

  const options = useMemo(() => {
    return [{
      Icon: UploadCloud,
      children: <CSVFileInput onInput={handleFileInput} />,
      title: `Upload a CSV File`,
      description: `Click to upload a .csv file.`,
    }, {
      Icon: Download,
      title: `Import from a CRM`,
      description: `Which CRM?`,
      onClick: toggleView,
    }];
  }, [
    handleFileInput,
    toggleView,
  ]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <ImportOptionsToolSelect items={options} />
        </div>
      </div>
    </div>
  );
};

ImportOptionsStartMenu.displayName = 'ImportOptionsStartMenu';

type CSVFileInputProps = {
  onInput: (file: File) => unknown;
};

const CSVFileInput = forwardRef<HTMLInputElement, CSVFileInputProps>((props, ref) => {

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const file = e.target.files[0];

    props.onInput(file);

  }, [props]);

  return (
    <input
      autoComplete="off"
      accept=".csv"
      className={styles.input}
      ref={ref}
      title=""
      type="file"
      onChange={handleChange} />
  );
});