import extend from '@enums/extend';

export enum ProfileSecondaryScreener {
  Payer = 1,
  Neurology = 2,
  Oncology = 3,
  Psychiatrist = 4,
}

const map = new Map([
  [ProfileSecondaryScreener.Payer, { name: `Payer` }],
  [ProfileSecondaryScreener.Neurology, { name: `Neurology` }],
  [ProfileSecondaryScreener.Oncology, { name: `Oncology` }],
  [ProfileSecondaryScreener.Psychiatrist, { name: `Psychiatrist` }],
]);

export default {
  ProfileSecondaryScreener: extend(map),
};