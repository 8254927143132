import { useContext, useEffect } from 'react';
import { EditingToolPaneContext } from '@containers/Transcript/context';
import { useTranscriptToolsPermission } from '@containers/Transcript/hooks';
import * as Tool from './Tool';
import styles from './style/Tool.css';

type Props = unknown;

export const EditingTools = (props: Props) => {
  const { enabled, inuse, reset } = useContext(EditingToolPaneContext);
  const permission = useTranscriptToolsPermission();

  useEffect(() => {
    if (!enabled && inuse) {
      reset();
    }
  }, [
    enabled,
    inuse,
    reset,
  ]);

  if (!enabled) return null;

  return (
    <div className={styles.tools}>
      {permission.speaker && <Tool.Speakers.Button />}
      <Tool.FindReplace.Button />
    </div>
  );
};

EditingTools.displayName = 'Transcript.Toolbar.EditingTools';