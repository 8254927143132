import { useCallback, useContext } from 'react';
import { BaseFileUploadEffectsContainer } from '@/containers/WorkspaceFileUpload/Effects.Base.Container';
import { ChatStateDispatchContext } from '@/brand-insights/components/Chat.State/context';
import type { WorkspaceFileUpload } from '@/types';

type Props = ChildrenProps;

export const FileUploadEffectsContainer = (props: Props) => {

  const dispatch = useContext(ChatStateDispatchContext);

  const onComplete = useCallback((data: WorkspaceFileUpload) => {
    dispatch({
      type: 'file-upload-completed',
      payload: {
        identifier: data.uploadIdentifier,
        fileIdentifier: data.identifier,
      },
    });
  }, [dispatch]);

  const onFailure = useCallback((data: WorkspaceFileUpload) => {
    dispatch({
      type: 'file-upload-errored',
      payload: {
        identifier: data.uploadIdentifier,
        fileIdentifier: data.identifier,
      },
    });
  }, [dispatch]);

  const onStart = useCallback((data: WorkspaceFileUpload) => {
    dispatch({
      type: 'file-upload-started',
      payload: {
        identifier: data.uploadIdentifier,
        fileIdentifier: data.identifier,
        fileId: data.fileId,
        fileUploadId: data.id,
      },
    });
  }, [dispatch]);

  return (
    <BaseFileUploadEffectsContainer
      onComplete={onComplete}
      onFailure={onFailure}
      onStart={onStart}>
      {props.children}
    </BaseFileUploadEffectsContainer>
  );
};