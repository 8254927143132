import { useSurveyLogicBuilderContext } from '@containers/SurveyBuilder.Logic/context';
import { AddLogicButton as Button } from '@/presentation/SurveyBuilder';

type Props = {
  className?: string;
};

export const AddLogicButton = ({ className }: Props) => {

  const ctx = useSurveyLogicBuilderContext();

  return (
    <Button
      className={className}
      onClick={ctx.addItem} />
  );
};