import type { CSSProperties } from 'react';
import { useRef, useMemo, useLayoutEffect } from 'react';

type Props = ChildrenProps & {
  observer: ResizeObserver;
  itemIdentifier: string;
  position: {
    width: number;
    x: number;
    y: number;
  };
  treatAsTool: boolean;
};

export const SidebarItemContainer = ({ observer, position, itemIdentifier, children, treatAsTool }: Props) => {
  const ref = useRef<HTMLDivElement>();

  useLayoutEffect(() => {
    if (ref.current) {
      const el = ref.current;
      observer.observe(el);

      return () => observer.unobserve(el);
    }
  }, [observer]);

  const style = useMemo(() => {
    return {
      position: 'absolute',
      width: treatAsTool ? undefined : `${position.width}px`,
      left: treatAsTool ? undefined : position.x,
      right: treatAsTool ? '10px' : undefined,
      top: position.y,
    } as CSSProperties;
  }, [position, treatAsTool]);

  return (
    <div
      data-sidebar-item-identifier={itemIdentifier}
      ref={ref}
      style={style}>
      {children}
    </div>
  );
};
