import { useVideoPostObjectURL, useVideoPostStateContext } from '@containers/PostCreation/hooks/usePostCreationContext';
import { NativePlayerPoster } from './Video.NativePlayerPoster';
import styles from './style/Video.ContentForm.ThumbnailFilePreview.css';

type Props = unknown;

export const ThumbnailFilePreview = (props: Props) => {
  const [state] = useVideoPostStateContext();
  const { thumbnail } = useVideoPostObjectURL();

  const src = thumbnail || state.snapshot;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        {src &&
          <div className={styles.preview}>
            <img
              className={styles.img}
              src={src} />
          </div>}
        {!src &&
          <NativePlayerPoster className={styles.video} />}
      </div>
    </div>
  );
};

ThumbnailFilePreview.displayName = 'PostCreation.Video.ContentForm.ThumbnailFilePreview';