import cuid from 'cuid';
import {
  SurveyActionType,
} from '@enums/Survey';
import {
  EmployeeSatisfactionQuestion,
  EmployeeSatisfactionTemplateSection,
  SurveyTemplateType,
} from '@enums/survey.template';
import type { SurveyTemplate } from '@/types/survey.templates';
import type {
  SurveyQuestion,
  SurveyBuilderVersion,
} from '@/types/survey';
import * as $question from '../utils/question';
import * as $template from './utils';

export const defaultTemplateData: SurveyTemplate.EmployeeSatisfaction = {
  target: {
    id: cuid(),
    value: '',
  },
};

// export const defaultTemplateData: SurveyTemplate.EmployeeSatisfaction = {
//   target: {
//     id: cuid(),
//     value: 'Target',
//   },
// };

export function generateSurveyData(data: SurveyTemplate.EmployeeSatisfaction): SurveyBuilderVersion {

  const section = $template.generateSection({
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: EmployeeSatisfactionTemplateSection.Main },
    },
    hidden: false,
    name: 'Main Section',
    ordinal: 1,
  });

  const baseSection = {
    identifier: section.identifier,
  };

  function targetNode() {
    return $template.generateTargetNode(data.target.value);
  }

  const {
    logic: currentEmployeeLogic,
    question: currentEmployeeQuestion,
  } = (function CurrentEmployeeQuestion() {

    const questionIdentifier = cuid();

    const yesOption = $template.generateMultipleChoiceOption({
      metadata: {
        canModifyValue: false,
        canDelete: false,
      },
      ordinal: 1,
      value: 'Yes',
    });
    const noOption = $template.generateMultipleChoiceOption({
      metadata: {
        canModifyValue: false,
        canDelete: false,
      },
      ordinal: 2,
      value: 'No',
    });

    const dqIfNoLogic = $template.generateOptionSelectedLogic({
      action: { type: SurveyActionType.Disqualification },
      question: { identifier: questionIdentifier },
      option: { identifier: noOption.base.identifier },
    });

    const logic = [dqIfNoLogic];

    const question = $template.generateMultipleChoiceQuestion({
      identifier: cuid(),
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: EmployeeSatisfactionQuestion.CurrentEmployee,
        },
      },
      options: [yesOption, noOption],
      section: {
        identifier: section.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode('Are you a current employee of '),
        targetNode(),
        $template.generateTextNode('?'),
      ]),
    });

    return { logic, question };
  })();

  function RespondentCommittedQuestion() {
    return generateAgreeDisagreeQuestion({
      key: EmployeeSatisfactionQuestion.RespondentCommitted,
      section: baseSection,
      value: $template.generateRichTextValue([
        $template.generateTextNode(`I feel committed to my organization's goals.`),
      ]),
    });
  }

  function ColleaguesCommittedQuestion() {
    return generateAgreeDisagreeQuestion({
      key: EmployeeSatisfactionQuestion.ColleaguesCommitted,
      section: baseSection,
      value: $template.generateRichTextValue([
        $template.generateTextNode(`The majority of my colleagues at my organization are committed to the organization's goals.`),
      ]),
    });
  }

  function OrgGoalsAlignmentQuestion() {
    return $template.generateShortTextQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: EmployeeSatisfactionQuestion.OrgGoalsAligment,
        },
      },
      section: {
        identifier: section.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`Please briefly elaborate on how you / your colleagues align with your organization's goals.`),
      ]),
    });
  }

  function WorkEngagingQuestion() {
    return generateAgreeDisagreeQuestion({
      key: EmployeeSatisfactionQuestion.WorkEngaging,
      section: baseSection,
      value: $template.generateRichTextValue([
        $template.generateTextNode(`I find my work engaging.`),
      ]),
    });
  }

  function CareerGrowthQuestion() {
    return generateAgreeDisagreeQuestion({
      key: EmployeeSatisfactionQuestion.CareerGrowth,
      section: baseSection,
      value: $template.generateRichTextValue([
        $template.generateTextNode(`I have adequate career growth and professional development opportunities at my organization.`),
      ]),
    });
  }

  function CareerGoalsQuestion() {
    return generateAgreeDisagreeQuestion({
      key: EmployeeSatisfactionQuestion.CareerGoals,
      section: baseSection,
      value: $template.generateRichTextValue([
        $template.generateTextNode(`My organization is in line with my personal career goals.`),
      ]),
    });
  }

  function CareerGoalsElaborationQuestion() {
    return $template.generateShortTextQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: EmployeeSatisfactionQuestion.CareerGoalsElaboration,
        },
      },
      section: {
        identifier: section.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`Please briefly elaborate on how you feel about professional development and career goals at your organization.`),
      ]),
    });
  }

  function NecessaryToolsQuestion() {
    return generateAgreeDisagreeQuestion({
      key: EmployeeSatisfactionQuestion.NecessaryTools,
      section: baseSection,
      value: $template.generateRichTextValue([
        $template.generateTextNode(`I am provided with the tools and information necessary to do my job effectively.`),
      ]),
    });
  }

  function EncouragedQuestion() {
    return generateAgreeDisagreeQuestion({
      key: EmployeeSatisfactionQuestion.Encouraged,
      section: baseSection,
      value: $template.generateRichTextValue([
        $template.generateTextNode(`I feel encouraged by my supervisor to offer suggestions and feedback.`),
      ]),
    });
  }

  function FeedbackConsideredQuestion() {
    return generateAgreeDisagreeQuestion({
      key: EmployeeSatisfactionQuestion.FeedbackConsidered,
      section: baseSection,
      value: $template.generateRichTextValue([
        $template.generateTextNode(`My feedback / suggestions are heard and considered when my team makes decisions.`),
      ]),
    });
  }

  function RecognizationQuestion() {
    return generateAgreeDisagreeQuestion({
      key: EmployeeSatisfactionQuestion.Recognization,
      section: baseSection,
      value: $template.generateRichTextValue([
        $template.generateTextNode(`I feel my accomplishments and contributions to the organization are recognized.`),
      ]),
    });
  }

  function RecognizationElaborationQuestion() {
    return $template.generateShortTextQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: EmployeeSatisfactionQuestion.RecognizationElaboration,
        },
      },
      section: {
        identifier: section.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`Please briefly elaborate on your feelings of recognition and inclusion in decision-making at your organization.`),
      ]),
    });
  }

  function FlexibilityQuestion() {
    return generateAgreeDisagreeQuestion({
      key: EmployeeSatisfactionQuestion.Flexibility,
      section: baseSection,
      value: $template.generateRichTextValue([
        $template.generateTextNode(`My organization gives me the flexibility to pursue a healthy work/life balance.`),
      ]),
    });
  }

  function InformedQuestion() {
    return generateAgreeDisagreeQuestion({
      key: EmployeeSatisfactionQuestion.Informed,
      section: baseSection,
      value: $template.generateRichTextValue([
        $template.generateTextNode(`When your organization makes changes, I feel informed and that they are communicated well.`),
      ]),
    });
  }

  function UnderstandsChangesQuestion() {
    return generateAgreeDisagreeQuestion({
      key: EmployeeSatisfactionQuestion.UnderstandsChanges,
      section: baseSection,
      value: $template.generateRichTextValue([
        $template.generateTextNode(`When my organization makes changes, I understand why.`),
      ]),
    });
  }

  function ChangesInLineQuestion() {
    return generateAgreeDisagreeQuestion({
      key: EmployeeSatisfactionQuestion.ChangesInLine,
      section: baseSection,
      value: $template.generateRichTextValue([
        $template.generateTextNode(`When my organization makes changes, they seem in line with the organization's mission.`),
      ]),
    });
  }

  function SupervisorCaresQuestion() {
    return generateAgreeDisagreeQuestion({
      key: EmployeeSatisfactionQuestion.SupervisorCares,
      section: baseSection,
      value: $template.generateRichTextValue([
        $template.generateTextNode(`I feel that my supervisor cares about me as a person.`),
      ]),
    });
  }

  function SupervisorFeedbackQuestion() {
    return generateAgreeDisagreeQuestion({
      key: EmployeeSatisfactionQuestion.SupervisorFeedback,
      section: baseSection,
      value: $template.generateRichTextValue([
        $template.generateTextNode(`I receive constructive feedback from my supervisor.`),
      ]),
    });
  }

  function MyResponsibilitiesClearQuestion() {
    return generateAgreeDisagreeQuestion({
      key: EmployeeSatisfactionQuestion.MyResponsibilitiesClear,
      section: baseSection,
      value: $template.generateRichTextValue([
        $template.generateTextNode(`My responsibilities are clear to me.`),
      ]),
    });
  }

  function TeammateResponsibilitiesClearQuestion() {
    return generateAgreeDisagreeQuestion({
      key: EmployeeSatisfactionQuestion.TeammateResponsibilitiesClear,
      section: baseSection,
      value: $template.generateRichTextValue([
        $template.generateTextNode(`My teammates' responsibilities are clear to them.`),
      ]),
    });
  }

  function LeadersTransparentQuestion() {
    return generateAgreeDisagreeQuestion({
      key: EmployeeSatisfactionQuestion.LeadersTransparent,
      section: baseSection,
      value: $template.generateRichTextValue([
        $template.generateTextNode(`I trust the senior leaders of your organization to be transparent with me.`),
      ]),
    });
  }

  function RecommendationQuestion() {
    return $template.generateSlidersQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: EmployeeSatisfactionQuestion.Recommend,
        },
      },
      matrixRows: [$template.generateSlidersRow({
        metadata: {
          canDelete: false,
          canModifyValue: false,
        },
        ordinal: 1,
        value: 'Likelihood',
      })],
      section: baseSection,
      settings: {
        ...$question.matrixSlider.DefaultSettings,
        randomization: false,
        slider: {
          displayPctOfTotal: false,
          ensureAnswerTotalEqualsMax: false,
          hideSlider: false,
          minValue: 1,
          maxValue: 10,
          increment: 1,
          label: null,
          total: $question.matrixSlider.DefaultSettings.slider.total,
        },
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`On a scale of 1-10, how likely are you to recommend your organization to others?`),
      ]),
    });
  }

  function EmployeeExperienceQuestion() {
    return $template.generateLongTextQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: EmployeeSatisfactionQuestion.EmployeeExperience,
        },
      },
      section: {
        identifier: section.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`Please elaborate on your employee experience.`),
      ]),
    });
  }

  const questions: SurveyQuestion[] = [
    currentEmployeeQuestion,
    RespondentCommittedQuestion(),
    ColleaguesCommittedQuestion(),
    OrgGoalsAlignmentQuestion(),
    WorkEngagingQuestion(),
    CareerGrowthQuestion(),
    CareerGoalsQuestion(),
    CareerGoalsElaborationQuestion(),
    NecessaryToolsQuestion(),
    EncouragedQuestion(),
    FeedbackConsideredQuestion(),
    RecognizationQuestion(),
    RecognizationElaborationQuestion(),
    FlexibilityQuestion(),
    InformedQuestion(),
    UnderstandsChangesQuestion(),
    ChangesInLineQuestion(),
    SupervisorCaresQuestion(),
    SupervisorFeedbackQuestion(),
    MyResponsibilitiesClearQuestion(),
    TeammateResponsibilitiesClearQuestion(),
    LeadersTransparentQuestion(),
    RecommendationQuestion(),
    EmployeeExperienceQuestion(),
  ];

  return {
    alternateImageExercises: [],
    classifications: [],
    createdOn: null,
    id: null,
    items: $template.generateItemsForQuestions(questions),
    language: $template.generateLanguage(),
    logic: [
      ...currentEmployeeLogic,
    ],
    messages: [],
    questions: questions.map((m, i) => ({
      ...m,
      ordinal: i + 1,
    })),
    quotas: [],
    sections: [section],
    surveyId: null,
    surveyDetails: null,
    tagging: [],
    template: {
      data,
      type: SurveyTemplateType.EmployeeSatisfaction,
    },
  };
}

function generateAgreeDisagreeQuestion({ key, section, value }: GenerateAgreeDisagreeQuestion) {
  const options = $template.AgreeDisagreeOptions;

  return $template.generateMultipleChoiceQuestion({
    metadata: {
      anchor: false,
      canModifyValue: false,
      canDelete: false,
      template: {
        key,
      },
    },
    options: options.map((value, i) => $template.generateMultipleChoiceOption({
      ordinal: i + 1,
      value,
    })),
    section,
    value,
  });
}

type GenerateAgreeDisagreeQuestion = {
  key: EmployeeSatisfactionQuestion;
} & Pick<SurveyQuestion, 'section' | 'value'>;