/** @jsxImportSource @emotion/react */
import { useCallback, useContext } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { MessageCitations } from '@/brand-insights/components/Chat.Message.Citations';
import { useCopyToClipboard } from '@/brand-insights/hooks';
import { getSegmentationQueryResponseAsText } from '@/brand-insights/utils';
import type { Chat } from '@/brand-insights/types';
import { MessageCitationsToggleContext } from './context';
import { CopyToClipboard } from './Message.CopyToClipboard';
import { BaseSystemUserMessage } from './Message.System';
import { SystemUserMessageFooterContainer } from './Message.System.Footer';
import { ChatResponsePane } from './ResponsePane';

type Props = {
  className?: string;
  children: React.ReactNode;
  status: Chat.Segmentation.Status;
  results: Chat.Segmentation.Result.ResultItem[];
  citations: Chat.Citation[];
};

export const SystemSegmentationMessage = (props: Props) => {
  const [citationsOpen, toggleCitationsOpen] = useContext(MessageCitationsToggleContext);
  const copyToClipboard = useCopyToClipboard();

  const handleCopy = useCallback(() => {
    copyToClipboard(getSegmentationQueryResponseAsText(props.results));
  }, [copyToClipboard, props.results]);

  const view = {
    actions: props.status === 'completed',
  };

  return (
    <BaseSystemUserMessage extendedWidth>
      <>
        {props.children}
        <SystemUserMessageFooterContainer>
          <>
            {view.actions && (
              <Layer>
                <ActionsContainer>
                  <StyledCopyToClipboard
                    onClick={handleCopy} />
                </ActionsContainer>
              </Layer>
            )}
            {!!props.citations.length && (
              <StyledCitationsPane
                title={`Citations (${props.citations.length})`}
                open={citationsOpen}
                toggleOpen={toggleCitationsOpen}>
                <StyledMessageCitations items={props.citations} />
              </StyledCitationsPane>
            )}
          </>
        </SystemUserMessageFooterContainer>
      </>
    </BaseSystemUserMessage>
  );
};

const StyledCitationsPane = styled(ChatResponsePane)`
  margin-top: 15px;
`;

const StyledMessageCitations = styled(MessageCitations)`
  margin-top: 10px;
`;

const Layer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCopyToClipboard = styled(CopyToClipboard)`
  margin-left: 10px;
`;