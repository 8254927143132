import { useQuery } from '@tanstack/react-query';
import * as $projects from '@api/projects';
import { ExternalSurveyConfigurationBootstrapContext } from './context';

type Props =
  ChildrenProps &
  IProjectId;

export const ExternalSurveyConfigurationBootstrapContainer = ({ projectId, ...props }: Props) => {

  const bootstrapQuery = useQuery(['bootstrap-external-project', projectId], () => {
    return $projects.external.bootstrapExternalProject({ projectId });
  }, {
    refetchOnWindowFocus: false,
  });

  return (
    <ExternalSurveyConfigurationBootstrapContext.Provider value={bootstrapQuery}>
      {props.children}
    </ExternalSurveyConfigurationBootstrapContext.Provider>
  );
};