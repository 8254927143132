import type { SurveyBuilder, SurveyLanguageBuilder } from '../interfaces';

export function languageReducer(state: SurveyBuilder.State, action: SurveyBuilder.Actions): SurveyLanguageBuilder.State {
  switch (action.type) {
    case 'languages-added': {
      const languageKeyMap = { ...state.survey.language.languageKeyMap };

      for (const l of action.payload.languages) {
        languageKeyMap[l.code] = {};
      }

      return {
        ...state.survey.language,
        languages: state.survey.language.languages.concat(action.payload.languages),
        languageKeyMap,
      };
    }

    case 'language-removed': {
      const languageKeyMap = { ...state.survey.language.languageKeyMap };

      delete languageKeyMap[action.payload.code];

      return {
        ...state.survey.language,
        languages: state.survey.language.languages.filter(l => l.code !== action.payload.code),
        languageKeyMap,
      };
    }

    case 'language-keys-imported': {
      const languageKeyMap = { ...state.survey.language.languageKeyMap };

      const map = languageKeyMap[action.payload.code] ? { ...languageKeyMap[action.payload.code] } : {};

      languageKeyMap[action.payload.code] = Object.assign(map, action.payload.values);

      return {
        ...state.survey.language,
        languageKeyMap,
      };
    }

    case 'language-key-updated': {
      const languageKeyMap = { ...state.survey.language.languageKeyMap };

      const map = languageKeyMap[action.payload.code] ? { ...languageKeyMap[action.payload.code] } : {};

      map[action.payload.key] = action.payload.value;

      languageKeyMap[action.payload.code] = map;

      return {
        ...state.survey.language,
        languageKeyMap,
      };
    }
  }

  return state.survey.language;
}