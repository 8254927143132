import { AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as api from '@api/projects';
import { Projects } from '@api/interfaces/projects';

type Data = Projects.CreateProjectResponse;
type Vars = Projects.CreateProjectRequest;

export function useCreateProject(
  options?: UseMutationOptions<Data, AxiosResponse, Vars>
) {

  return useMutation(['create-project'], params => {

    return api.createProject(params);

  }, options);
}