import { forwardRef } from 'react';
import Cancel from '@mui/icons-material/Cancel';
import { cx } from '@utils';
import styles from './style/CoverImage.css';

type Props = {
  editable?: boolean;
} & EditableProps
  & ReadOnlyProps;

type ReadOnlyProps = CoverImageReadOnlyProps;
type EditableProps = CoverImageEditableProps;

export const CoverImage = ({ editable = false, ...props }: Props) => {
  return editable
    ? <CoverImageEditable {...props} />
    : <CoverImageReadOnly {...props} />;
};

CoverImage.displayName = 'Post.Article.CoverImage';

type CoverImageBaseProps = {
  classes?: {
    root?:   string;
    image?:  string;
  };
  children?: React.ReactNode;
  src?:      string;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'className'>;

const CoverImageBase = ({ children, classes = {}, src, ...props }: CoverImageBaseProps) => {
  const classnames = {
    root: cx(styles.root, classes.root),
    image: cx(styles.image, classes.image),
  };

  const style = src
    ? { backgroundImage: `url(${src})` }
    : {};

  return (
    <div
      {...props}
      className={classnames.root}>
      <div
        className={classnames.image}
        style={style} />
      {children}
    </div>
  );
};

CoverImageBase.displayName = 'Post.Article.CoverImage.Base';

type CoverImageReadOnlyProps = Omit<CoverImageBaseProps, 'children'>;

const CoverImageReadOnly = (props: CoverImageReadOnlyProps) => {
  return (
    <CoverImageBase {...props} />
  );
};

CoverImageReadOnly.displayName = 'Post.Article.CoverImage.ReadOnly';

type CoverImageEditableProps = {
  classes?: {
    remove?: string;
  } & CoverImageBaseProps['classes'];
  onRemove?: (e?: React.MouseEvent<HTMLDivElement>) => unknown;
} & Omit<CoverImageBaseProps, 'classes' | 'children'>;

const CoverImageEditable = ({ classes = {}, onRemove, src, ...props }: CoverImageEditableProps) => {
  const classnames = {
    root: cx(styles.editable, classes.root),
    remove: cx(styles.remove, classes.remove),
  };

  return (
    <CoverImageBase
      {...props}
      classes={{
        root: classnames.root,
        image: classes.image,
      }}
      src={src}>
      <div
        className={classnames.remove}
        onClick={onRemove}>
        <Cancel />
      </div>
    </CoverImageBase>
  );
};

CoverImageEditable.displayName = 'Post.Article.CoverImage.Editable';