import { useChainedCommands } from './useCommands';
import type { SaveHighlightOptions } from './useSaveHighlight';
import { useSaveHighlight } from './useSaveHighlight';

type ExtraVariables = {
  previousColor: string;
};
type Options = SaveHighlightOptions<ExtraVariables>;

export function useUpdateHighlightColor(options?: Options) {

  const commands = useChainedCommands();

  return useSaveHighlight<ExtraVariables>({
    ...options,
    onMutate: v => {
      commands
        .updateHighlight({
          id: v.highlight.id,
          color: v.highlight.color,
        })
        .blurHighlight()
        .run();

      options?.onMutate?.(v);
    },
    onSuccess: (highlight, v, c) => {
      commands
        .updateHighlight({
          id: highlight.id,
          dbId: highlight.dbId,
        })
        .run();

      options?.onSuccess?.(highlight, v, c);
    },
    onError: (e, v, ctx) => {
      commands
        .updateHighlight({
          id: v.highlight.id,
          color: v.previousColor,
        })
        .run();

      options?.onError?.(e, v, ctx);
    },
  });

}