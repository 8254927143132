import queryString from 'query-string';
import { client } from './client';
import type * as ChatApi from './interfaces/chat';
import {
  prepareContextData,
  stringifyContextData,
  transformChatInstance,
  transformChatFileUpload,
  transformChatSegmentation,
  transformChatQuery,
} from './transformers';

export const submitChatQuery = async (data: ChatApi.SubmitChatQuery.Request) => {
  const postData = {
    ...data,
    context: prepareContextData(data.context),
  };
  const res = await client.post<ChatApi.SubmitChatQuery.Response>('/chat/submit-query', postData);
  return {
    identifier: res.data.identifier,
  };
};

export const regenerateQuery = async (data: ChatApi.RegenerateQuery.Request) => {
  const postData = {
    ...data,
    context: prepareContextData(data.context),
  };
  const res = await client.post<ChatApi.RegenerateQuery.Response>(`/chat/regenerate-query`, postData);
  return {
    identifier: res.data.identifier,
  };
};

export const getChatHistory = async (data: ChatApi.GetChatHistory.Request): Promise<ChatApi.GetChatHistory.Response<Date>> => {
  const context = stringifyContextData(prepareContextData(data.context));

  const qs = queryString.stringify({
    cursor: data.cursor,
    page_size: data.pageSize,
    user_id: data.userId,
    context,
    mode: data.mode,
    include_additional: data.includeAdditional,
  }, {
    skipNull: true,
  });

  const res = await client.get<ChatApi.GetChatHistory.Response>(`/chat/history?${qs}`);

  return {
    hasMore: res.data.hasMore,
    items: res.data.items.map(transformChatInstance),
    shared: res.data.shared.map(transformChatInstance),
  };
};

export const getChatHints = async (data: ChatApi.GetChatHints.Request) => {
  const context = stringifyContextData(prepareContextData(data.context));

  const qs = queryString.stringify({
    user_id: data.userId,
    context,
  }, {
    skipNull: true,
  });

  const res = await client.get<ChatApi.GetChatHints.Response>(`/chat/hints?${qs}`);
  return res.data;
};

export const uploadChatFiles = async (data: ChatApi.UploadFiles.Request): Promise<ChatApi.UploadFiles.Response> => {
  const postData = {
    ...data,
    context: prepareContextData(data.context),
  };
  const res = await client.post<ChatApi.UploadFiles.Response<string>>('/chat/upload-documents', postData);
  return {
    chatInstance: transformChatInstance(res.data.chatInstance),
    fileUpload: transformChatFileUpload(res.data.fileUpload),
  };
};

export const lookupContext = async (data: ChatApi.LookupContext.Request) => {
  const qs = queryString.stringify({
    context_type: data.contextType,
    entity_id: data.entityId,
    object_id: data.objectId,
  });

  const res = await client.get<ChatApi.LookupContext.Response>(`/chat/context?${qs}`);
  return res.data;
};

export const startSegmentation = async (data: ChatApi.StartSegmentation.Request) => {
  const res = await client.post<ChatApi.StartSegmentation.Response<string>>(`/chat/segmentation/start`, data);
  return {
    chatInstance: transformChatInstance(res.data.chatInstance),
    segmentation: transformChatSegmentation(res.data.segmentation),
  };
};

export const cancelSegmentation = async (data: ChatApi.CancelSegmentation.Request) => {
  const res = await client.post<ChatApi.CancelSegmentation.Response<string>>(`/chat/segmentation/cancel`, data);
  return {
    segmentation: transformChatSegmentation(res.data.segmentation),
  };
};

export const segmentationTagSelection = async (data: ChatApi.SegmentationTagSelection.Request) => {
  const res = await client.post<ChatApi.SegmentationTagSelection.Response<string>>(`/chat/segmentation/tag-selection`, data);
  return {
    segmentation: transformChatSegmentation(res.data.segmentation),
  };
};

export const segmentationDimensionSelection = async (data: ChatApi.SegmentationDimensionSelection.Request) => {
  const res = await client.post<ChatApi.SegmentationDimensionSelection.Response<string>>(`/chat/segmentation/dimension-selection`, data);
  return {
    segmentation: transformChatSegmentation(res.data.segmentation),
  };
};

export const segmentationConfirmation = async (data: ChatApi.SegmentationConfirmation.Request) => {
  const res = await client.post<ChatApi.SegmentationConfirmation.Response<string>>(`/chat/segmentation/confirmation`, data);
  return {
    segmentation: transformChatSegmentation(res.data.segmentation),
  };
};

export const getChatContextAccess = async (data: ChatApi.GetChatContextAccess.Request) => {

  const context = stringifyContextData(prepareContextData(data.context));

  const qs = queryString.stringify({
    context,
  }, {
    skipNull: true,
  });

  const res = await client.get<ChatApi.GetChatContextAccess.Response>(`/chat/context-access?${qs}`);
  return res.data;
};

export const getPendingQuery = async (data: ChatApi.GetPendingQuery.Request) => {
  return client.get<ChatApi.GetPendingQuery.Response>(`/chat/pending-query?query_identifier=${data.queryIdentifier}`)
    .then(res => ({
      chatInstance: transformChatInstance(res.data.chatInstance),
      query: transformChatQuery(res.data.query),
    }));
};