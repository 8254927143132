import { useCallback, useRef } from 'react';
import type { AnyExtension, RemirrorManager } from 'remirror';

export const useScrollToSpeakerBlock = (manager: RemirrorManager<AnyExtension>) => {

  const timeoutRef = useRef<NodeJS.Timeout>(null);

  return useCallback((position: number) => {
    const headerHeight = 225;

    const node = manager.view.dom.querySelector(`[data-ts-s="${position}"]`);
    const nodePosition = node?.getBoundingClientRect().top + window.scrollY;
    const targetPosition = nodePosition - headerHeight;

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth',
      });
    }, 500);
  }, [manager]);
};