import { memo } from 'react';
import { Frown } from 'react-feather';
import styles from './style.css';

export const LoadingError = memo(() => {
  return (
    <div className={styles.errorWrapper}>
      <div className={styles.wrong}>
        <h1>
          <div className={styles.frown}>
            <Frown />
          </div>
          {`We're sorry, something went wrong when loading this survey.`}
        </h1>
        {`If reloading the page doesn't resolve the issue please contact the survey administrator.`}
      </div>
    </div>
  );
});

export const NotValidError = memo(() => {
  return (
    <div className={styles.errorWrapper}>
      <div className={styles.wrong}>
        <h1>
          <div className={styles.frown}>
            <Frown />
          </div>
          {`We're sorry, this survey link is no longer valid.`}
        </h1>
        {`If you believe this is an error please contact the survey administrator.`}
      </div>
    </div>
  );
});