import http from '@services/http';
import { Projects } from '$admin/api/interfaces';

export const createHonoraria = (data: Projects.CreateHonoraria.Request) => {
  const { projectId, ...rest } = data;

  return http.post<Projects.CreateHonoraria.Response>(`/projects/${data.projectId}/billing/honoraria`, rest);
};

export const fetchHonoraria = (data: Projects.FetchHonoraria.Request) => {
  return http.get<Projects.FetchHonoraria.Response>(`/projects/${data.projectId}/billing/honoraria`);
};

export const updateHonoraria = (data: Projects.UpdateHonoraria.Request) => {
  const { projectId, ...rest } = data;

  return http.put<Projects.UpdateHonoraria.Response>(`/projects/${projectId}/billing/honoraria`, rest);
};