import { useEffect, useState } from 'react';
import Color from 'color';
import { distinct, groupBy } from '@/utils/array';
import type { HybridSurveyResponseUserId } from '@/types';
import { useSelectRespondentProps } from '@/containers/SurveyResponses/hooks';
import { PopperTooltip } from '@/components/Tooltip';
import { STRUCTURED_TYPES } from '@/components/SurveyForm/utils.image-markup';
import type { ImageMarkup } from './interfaces';
import styles from './style/ImageMarkup.css';

type Props = {
  imageUrl: string;
  structures: ImageMarkup.UserStructuredMarkup[];
};

type UserMap = HybridSurveyResponseUserId[][][];

export const Heatmap = (props: Props) => {
  const [canvas, setCanvas] = useState<HTMLCanvasElement>(null);
  const [usermap, setUserMap] = useState<UserMap>(null);
  const [hoverUsers, setHoverUsers] = useState<HybridSurveyResponseUserId[]>(null);

  useEffect(() => {
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const structureCount = groupBy(props.structures, k => k.type);

    const img = new Image();
    img.src = props.imageUrl;
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height);

      for (const structure of props.structures) {
        const baseColor = STRUCTURED_TYPES[structure.type].heatmap;
        const baseOpacity = MAX_OPACITY / structureCount[structure.type].length;
        const color = Color(baseColor).alpha(baseOpacity).rgb().string();
        ctx.fillStyle = color;
        ctx.fillRect(structure.start.x, structure.start.y, structure.end.x - structure.start.x, structure.end.y - structure.start.y);
      }

      const buildingUserMap: UserMap = [[]];
      for (let x = 1; x < img.width; x++) {
        for (let y = 1; y < img.height; y++) {
          const matchingUsers = distinct(props.structures.filter(s => s.start.x <= x && s.end.x >= x && s.start.y <= y && s.end.y >= y).map(s => s.userId));
          if (matchingUsers.length > 0) {
            if (!buildingUserMap[x]) buildingUserMap[x] = [];
            buildingUserMap[x][y] = matchingUsers;
          }
        }
      }

      setUserMap(buildingUserMap);
    };
  }, [props.structures, props.imageUrl, canvas]);

  useEffect(() => {
    function moveHandler(e: MouseEvent) {
      const scale = canvas.width / canvas.offsetWidth;
      const bounds = canvas.getBoundingClientRect();
      const xCoord = Math.round((e.pageX - bounds.left) * scale);
      const yCoord = Math.round((e.pageY - bounds.top) * scale);

      if (usermap[xCoord] && usermap[xCoord][yCoord]?.length) {
        const newVal = usermap[xCoord][yCoord];
        setHoverUsers(old => {
          //Hack to make sure we're keeping the same array ref if they contain the same values
          //Otherwise the reference updates and it rerenders a lot, slowing down the page a lot
          if (JSON.stringify(old) === JSON.stringify(newVal)) return old;
          return newVal;
        });
      } else {
        setHoverUsers(null);
      }
    }

    function leaveHandler() {
      setHoverUsers(null);
    }

    if (canvas && usermap) {
      canvas.addEventListener('mousemove', moveHandler);
      canvas.addEventListener('mouseleave', leaveHandler);

      return () => {
        canvas.removeEventListener('mousemove', moveHandler);
        canvas.removeEventListener('mouseleave', leaveHandler);
      };
    }
  }, [canvas, usermap]);

  return (
    <div>
      <PopperTooltip
        title={hoverUsers?.length ? <TooltipContent userIds={hoverUsers} /> : null}
        followCursor={true}
        placement='top'>
        <canvas className={styles.heatmapCanvas} ref={setCanvas} />
      </PopperTooltip>
    </div>
  );
};

type TooltipProps = {
  userIds: HybridSurveyResponseUserId[];
};

const TooltipContent = ({ userIds }: TooltipProps) => {
  const selectRespondent = useSelectRespondentProps();

  if (userIds?.length) {
    return (
      <div>
        {userIds.map(userId => {
          const respondent = selectRespondent(userId);
          return <div key={userId}>{respondent.user.name}</div>;
        })}
      </div>
    );
  }
};

const MAX_OPACITY = .6;
const MIN_OPACITY = .05;