import { SurveyOptionType, SurveyQuestionType } from '@enums';
import { DropdownQuestion, SurveyQuestion, SurveyQuestionOption } from '@/types/survey';
import { hasOptions } from '@/containers/Survey/utils/questions';
import { SurveyQuestionsBuilder, SurveySettingsBuilder } from '../interfaces';
import { generateUSStateOptions } from '../utils/presets.us-states';
import { generateNewOption } from './defaults';
import { updateQuestion } from './state.questions';

export function toggleNotApplicable(state: SurveyQuestionsBuilder.State, action: SurveySettingsBuilder.ToggleNotApplicable.Action): SurveyQuestionsBuilder.State {
  if (action.value) {
    return state.reduce((acc, q) => {
      if (q.base.identifier === action.questionIdentifier) {
        if (!hasOptions(q)) return acc.concat(q);
        return acc.concat({
          ...q,
          options: [
            ...q.options,
            generateNewOption({
              metadata: {
                canDelete: false,
                canModifyValue: false,
                template: {},
              },
              ordinal: q.options.length + 1,
              type: SurveyOptionType.NotApplicable,
              value: 'N/A',
            }, q.typeId),
          ],
          settings: {
            ...q.settings,
            includeNotApplicable: true,
          },
        });
      }

      return acc.concat(q);
    }, []);
  } else {
    return state.reduce((acc, q) => {
      if (q.base.identifier === action.questionIdentifier) {
        acc.push({
          ...q,
          options: q.options.filter(f => f.type !== SurveyOptionType.NotApplicable),
          settings: {
            ...q.settings,
            includeNotApplicable: false,
          },
        });
      } else {
        acc.push(q);
      }
      return acc;
    }, []);
  }
}

type UpdateSettings = {
  questionIdentifier: string;
  settings: Partial<SurveyQuestion['settings']>;
  state: SurveyQuestionsBuilder.State;
};

function updateSettings({
  questionIdentifier,
  settings,
  state,
}: UpdateSettings) {

  return state.reduce((acc, q) => {
    if (q.base.identifier === questionIdentifier) {
      return acc.concat({
        ...q,
        settings: {
          ...q.settings,
          ...settings,
        },
      });
    }

    return acc.concat(q);
  }, []);
}

export function toggleAllowEmptyValues(state: SurveyQuestionsBuilder.State, action: SurveySettingsBuilder.ToggleAllowEmptyValues.Action): SurveyQuestionsBuilder.State {

  return updateSettings({
    questionIdentifier: action.questionIdentifier,
    state,
    settings: {
      allowEmptyValues: action.value,
    },
  });

}

export function presetOptionsUpdated(state: SurveyQuestionsBuilder.State, action: SurveySettingsBuilder.PresetOptionsUpdated.Action): SurveyQuestionsBuilder.State {

  const question = state.find(f => f.base.identifier === action.questionIdentifier);

  if (question.typeId !== SurveyQuestionType.Dropdown) return state;

  const options: DropdownQuestion.Option[] = action.value === 'us-states'
    ? generateUSStateOptions()
    : [];

  const item: DropdownQuestion.Question = {
    ...question,
    options,
    settings: {
      ...question.settings,
      presetOptions: action.value,
    },
  };

  return updateQuestion(state, { item });

}

export function rationaleUpdated(state: SurveyQuestionsBuilder.State, action: SurveySettingsBuilder.RationaleUpdated.Action): SurveyQuestionsBuilder.State {

  return updateSettings({
    questionIdentifier: action.questionIdentifier,
    state,
    settings: {
      rationale: action.value,
    },
  });
}

export function randomizeUpdated(state: SurveyQuestionsBuilder.State, action: SurveySettingsBuilder.RandomizeUpdated.Action): SurveyQuestionsBuilder.State {

  return updateSettings({
    questionIdentifier: action.payload.questionIdentifier,
    state,
    settings: {
      randomization: action.payload.value,
    },
  });
}

export function reuseOptionsOrderUpdated(state: SurveyQuestionsBuilder.State, action: SurveySettingsBuilder.ReuseOptionsOrderUpdated.Action): SurveyQuestionsBuilder.State {

  return updateSettings({
    questionIdentifier: action.payload.questionIdentifier,
    state,
    settings: {
      orderOptionsBasedOnSource: action.payload.value,
    },
  });
}

export function reuseRowsOrderUpdated(state: SurveyQuestionsBuilder.State, action: SurveySettingsBuilder.ReuseRowsOrderUpdated.Action): SurveyQuestionsBuilder.State {

  return updateSettings({
    questionIdentifier: action.payload.questionIdentifier,
    state,
    settings: {
      orderRowsBasedOnSource: action.payload.value,
    },
  });
}