export const createInitialValue = (val: string) => {
  return val?.replace?.('#', '')?.toUpperCase?.();
};

export const validateColor = (str: string) => {
  const val = str?.trim?.()?.toUpperCase?.();

  if (!val) {
    return {
      hex: null,
      valid: false,
      value: null,
    };
  }

  const raw = val.replace('#', '').slice(0, 6);
  const valid = CSS.supports(`color`, `#${raw}`);

  if (valid && raw.length >= 3 && raw.length < 6) {
    const [r, g, b] = raw.split('');
    const value = `${r.repeat(2)}${g.repeat(2)}${b.repeat(2)}`;

    return {
      hex: `#${value}`,
      valid: true,
      value,
    };
  }

  return {
    hex: `#${raw}`,
    valid,
    value: raw,
  };
};