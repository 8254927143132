import { useContext } from 'react';
import { ProjectOnboardingContext } from '@containers/ProjectOnboarding/Context';
import * as utils from '@containers/ProjectOnboarding/utils';

function useProjectOnboarding() {
  const context = useContext(ProjectOnboardingContext);
  if (!context) {
    throw new Error('useProjectOnboardingContext must be used within a ProjectOnboardingStateProvider');
  }
  return context;
}

const useProjectOnboardingContext = Object.assign(useProjectOnboarding, utils);

export { useProjectOnboardingContext };
export default useProjectOnboardingContext;