import { useContext, useMemo } from 'react';
import { ChatSessionSegmentationsContext } from '@/brand-insights/components/Chat/context';

type Props = {
  segmentationIdentifier: string;
};

export const useSessionSegmentation = ({ segmentationIdentifier }: Props) => {
  const segmentations = useContext(ChatSessionSegmentationsContext);

  return useMemo(() => {
    return segmentations.find(s => s.identifier === segmentationIdentifier);
  }, [
    segmentations,
    segmentationIdentifier,
  ]);
};