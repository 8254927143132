import { forwardRef } from 'react';
import styles from './style/AppBar.css';

type Props = {
  children: React.ReactNode;
};

const LayoutRoot = (props: Props, ref: React.Ref<HTMLDivElement>) => {
  return (
    <>
      <div
        className={styles.root}
        ref={ref}>
        {props.children}
      </div>
      <div className={styles.main} />
      <div className={styles.pad} />
    </>
  );
};

LayoutRoot.displayName = 'Navbar.LayoutRoot';

const LayoutRootWithRef = forwardRef<HTMLDivElement, Props>(LayoutRoot);

export { LayoutRootWithRef as LayoutRoot };