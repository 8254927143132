import { useCallback, useMemo } from 'react';
import type { SurveyQuestionType } from '@enums';
import type {
  SubmitOverrides } from './Context';
import {
  useMaxDiffFormContext,
  useMaxDiffNavigationContext,
  SurveyFormItemBackContext,
  SurveyFormItemSubmitContext,
  SurveyFormShowCompleteContext,
  SurveyFormCanSubmitContext,
} from './Context';
import {
  useSubmitAnswer,
  useSurveyFormIsFirstItem,
  useSurveyFormIsLastItem,
  useSurveyFormQuestionContext,
  useSurveyQuestionGoBack,
} from './hooks';

export default function MaxDiffNavigationContainer({ children }: ChildrenProps) {

  const question = useSurveyFormQuestionContext<SurveyQuestionType.MaxDifference>();

  const {
    mutateAsync: submitAnswerMutateAsync,
    ...submitAnswer
  } = useSubmitAnswer();
  const { mutateAsync: goBackMutateAsync, ...goBack } = useSurveyQuestionGoBack();
  const { currentSet } = useMaxDiffFormContext();
  const { back: maxdiffBack, next: maxdiffNext, nextDisabled } = useMaxDiffNavigationContext();
  const isFirstQuestion = useSurveyFormIsFirstItem();
  const isLastQuestion = useSurveyFormIsLastItem();

  const back = useCallback(() => {
    if (currentSet === 1) {
      return goBackMutateAsync();
    } else {
      return Promise.resolve(maxdiffBack());
    }
  }, [
    currentSet,
    maxdiffBack,
    goBackMutateAsync,
  ]);

  const next = useCallback((overrides?: SubmitOverrides) => {
    if (currentSet === question.settings.sets) {
      return submitAnswerMutateAsync(overrides);
    } else {
      return Promise.resolve(maxdiffNext());
    }
  }, [
    currentSet, question.settings.sets,
    submitAnswerMutateAsync, maxdiffNext,
  ]);

  const backDisabled = useMemo(() => {
    return currentSet === 1 && isFirstQuestion;
  }, [
    currentSet,
    isFirstQuestion,
  ]);

  const showComplete = useMemo(() => {
    return currentSet === question.settings.sets
      ? isLastQuestion
      : false;
  }, [
    currentSet, question.settings.sets,
    isLastQuestion,
  ]);

  const backCtx = {
    disabled: backDisabled,
    isError: goBack.isError,
    isLoading: goBack.isLoading,
    handler: back,
  };

  const submitCtx = {
    isError: submitAnswer.isError,
    isLoading: submitAnswer.isLoading,
    handler: next,
  };

  return (
    <SurveyFormCanSubmitContext.Provider value={!nextDisabled}>
      <SurveyFormItemSubmitContext.Provider value={submitCtx}>
        <SurveyFormItemBackContext.Provider value={backCtx}>
          <SurveyFormShowCompleteContext.Provider value={showComplete}>
            {children}
          </SurveyFormShowCompleteContext.Provider>
        </SurveyFormItemBackContext.Provider>
      </SurveyFormItemSubmitContext.Provider>
    </SurveyFormCanSubmitContext.Provider>
  );
}

export { MaxDiffNavigationContainer };