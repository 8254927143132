import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import Skeleton from '@mui/material/Skeleton';
import { BrandStateContext, BrandLoadingContext } from '@/containers/Workspace.Brand/Context';
import { Actions } from './Header.Actions';
import { BrandBreadcrumbs } from './Header.Breadcrumbs';
import styles from './style/Header.css';

export const Header = () => {
  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <BrandBreadcrumbs />
          <div className={styles.details}>
            <div className={styles.header}>
              <Title />
            </div>
            <Actions />
          </div>
          <div className={styles.subtitle}>
            <Subtitle />
          </div>
        </div>
      </div>
    </div>
  );
};

Header.displayName = 'Workspace.Brand.Header';

const Subtitle = () => {
  const isLoading = useContext(BrandLoadingContext);

  if (isLoading) {
    return (
      <Skeleton
        height={30}
        width={100}
        variant="rectangular" />
    );
  }

  return (
    <>{`Brand`}</>
  );
};

const Title = () => {
  const isLoading = useContext(BrandLoadingContext);
  const { brand } = useContext(BrandStateContext);

  if (isLoading) {
    return (
      <Skeleton
        style={{ marginRight: 10 }}
        height={30}
        width={400}
        variant="rectangular" />
    );
  }

  return (
    <>
      <Helmet title={brand.displayName} />
      <h1 className={styles.title}>{brand.displayName}</h1>
    </>
  );
};

Header.displayName = 'Workspace.Brand.Header.Title';
