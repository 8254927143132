import { useCallback, useMemo } from 'react';
import { cx } from '@utils';
import type { SurveyQuestionType } from '@enums';
import { useSurveyFormQuestionAnswer } from '@/containers/SurveyForm';
import type { MultipleChoiceQuestion as MQ } from '@/types';
import { RichTextRadioGroup } from '@/components/Radio';
import type { RadioGroupOnOptionChange, RadioGroupOnInputChange } from '@/components/Radio/interfaces';
import type { MultipleChoiceProps } from './interfaces';
import { Rationale } from './Rationale';
import styles from './style/MultipleChoice.css';

type Props =
  MultipleChoiceProps;

export default function MultipleChoiceQuestion({
  className,
  question,
}: Props) {

  const [answer, setAnswer] = useSurveyFormQuestionAnswer<SurveyQuestionType.MultipleChoice>();

  const optionsMapped =
    useMemo(() => question.options.map((o: MQ.Option) => ({
      allowText: o.metadata.isOpenEnded,
      value: o.id,
      label: o.value,
      text: answer?.text,
    })), [answer, question.options]);

  const handleOptionChange: RadioGroupOnOptionChange =
    useCallback(item => setAnswer({ optionId: +item.optionId }), [setAnswer]);

  const handleInputChange: RadioGroupOnInputChange =
    useCallback(item => setAnswer({ optionId: +item.optionId, text: item.text }), [setAnswer]);

  return (
    <>
      <RichTextRadioGroup
        className={cx(styles.group, className)}
        selectedOption={answer?.optionId ?? ''}
        onOptionChange={handleOptionChange}
        onInputChange={handleInputChange}
        options={optionsMapped} />
      <Rationale settings={question.settings.rationale} />
    </>
  );
}

export { MultipleChoiceQuestion };