import { ProjectEntitiesQueryContainer } from './Project.Entities.Query.Container';

type Props = {
  children: React.ReactNode;
  enabled:  boolean;
} & IProjectId;

export const ProjectEntitiesContainer = (props: Props) => {
  return (
    <ProjectEntitiesQueryContainer
      enabled={!!props.projectId && props.enabled}
      projectId={props.projectId}>
      {props.children}
    </ProjectEntitiesQueryContainer>
  );
};

ProjectEntitiesContainer.displayName = 'Project.Entities.Container';