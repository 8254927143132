import { useCallback, useMemo } from 'react';
import type { NumberInputTableQuestion, SurveyQuestionFormMatrixRow, SurveyRichText } from '@/types/survey';
import { parseRichTextImages, parseSurveyRichText } from '@/containers/Survey/utils';
import { useNumberTableColumnTotals } from '@/containers/SurveyForm';
import { roundToPlace } from '@utils';
import { SurveyQuestionOption } from '../Survey.RichText';
import type { NumberInputTable } from './interfaces';
import { NumberInputTableGridRow } from './NumberInputTable.Grid.Row';
import styles from './style/NumberInputTable.Grid.css';

type MeasurementProps = {
  matrixRows: SurveyQuestionFormMatrixRow[];
  numOptions: number;
  maxCellWidth: number;
  nameWidthPct: number;
};

const IMAGE_WIDTH_LENGTH_MODIFIER = .5;

export const useNumberInputTableMeasurements = ({ maxCellWidth: mcw, nameWidthPct, ...item }: MeasurementProps) => {
  const cellWidth = useMemo(() => {
    const width = (100 - nameWidthPct) / item.numOptions;
    return `${width}%`;
  }, [
    item.numOptions,
    nameWidthPct,
  ]);

  const maxCellWidth = useMemo(() => {
    return `${mcw * item.matrixRows.length}px`;
  }, [
    item.matrixRows.length,
    mcw,
  ]);

  const firstCellStyles = useMemo(() => {
    //TODO: include the image size in the the maxRowLength calculation
    const maxRowLength = Math.max(...item.matrixRows.map(m => estimateRichTextLength(m.value)));
    const maxWidthPct = Math.max(Math.min(maxRowLength, 50), 20);
    return {
      maxWidth: `${maxWidthPct}%`,
      width: `${nameWidthPct}%`,
    };
  }, [
    item.matrixRows,
    nameWidthPct,
  ]);

  return {
    cellWidth,
    maxCellWidth,
    firstCellStyles,
  } as const;

  function estimateRichTextLength(node: SurveyRichText.RootNode) {
    const textLength = parseSurveyRichText(node).length;
    const imageNodeLengths = parseRichTextImages(node).map(i => IMAGE_WIDTH_LENGTH_MODIFIER * (isNaN(+i.attrs.width) ? 100 : +i.attrs.width));

    return Math.max(textLength, ...imageNodeLengths);
  }
};

export const NumberInputTableGrid = ({ handleChange, item, from, to, maxOptions }: NumberInputTable.GridProps) => {

  const slicedOptions = useMemo(() => {
    return item.options.slice(from, to);
  }, [
    item.options,
    from,
    to,
  ]);

  const totalsMap = useNumberTableColumnTotals();

  const {
    cellWidth,
    maxCellWidth,
    firstCellStyles,
  } = useNumberInputTableMeasurements({
    matrixRows: item.matrixRows,
    numOptions: maxOptions,
    maxCellWidth: 180,
    nameWidthPct: maxOptions === 1 ? 40 : 30,
  });

  const formatPctOfTotal = useCallback((value: number) => {

    const total = item.settings.total.comparate
      ? item.settings.total.comparate
      : value;

    if (!total) return 0;

    const pct = (value || 0) / total * 100;

    return roundToPlace(pct, 1);
  }, [item.settings.total]);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div
          className={styles.rowName}
          style={firstCellStyles} />
        {slicedOptions.map(option => (
          <div
            key={option.ordinal}
            style={{
              width: cellWidth,
              maxWidth: maxCellWidth,
            }}
            className={styles.cell}>
            <SurveyQuestionOption value={option.value} />
          </div>
        ))}
      </div>
      <div>
        {item.matrixRows.map((row: NumberInputTableQuestion.FormRow) => (
          <NumberInputTableGridRow
            key={row.id}
            firstCellStyles={firstCellStyles}
            optionCellStyles={{ width: cellWidth, maxWidth: maxCellWidth }}
            options={slicedOptions}
            settings={item.settings}
            row={row}
            onChange={handleChange} />
        ))}
      </div>
      {item.settings.displayTotals &&
        <div className={styles.footer}>
          <div
            className={styles.rowName}
            style={firstCellStyles}>
            Total
          </div>
          {slicedOptions.map(option => {
            const totalNumber = totalsMap[option.id];
            const unitSuffix = item.settings.numberSettings?.unitType;
            const hasUnitSuffix = unitSuffix || unitSuffix === '0';
            const totalDisplay = `${totalNumber}${hasUnitSuffix ? unitSuffix : ``}`;

            return (
              <div
                key={option.ordinal}
                style={{ width: cellWidth }}
                className={styles.cell}>
                <div className={styles.total}>{totalDisplay}</div>
                {item.settings.displayCellPct &&
                  <div className={styles.pct}>({formatPctOfTotal(totalNumber)}%)</div>
                }
              </div>
            );
          })}
        </div>
      }
    </div>
  );

};

export default NumberInputTableGrid;