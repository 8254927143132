import { Fragment, useCallback, useState } from 'react';
import { Node } from 'slate';
import { FeedItemComment } from '@/types/comments';
import { cx, sum } from '@utils';
import * as api from '@api';
import { Actions } from './Comment.Actions';
import { Body } from './Comment.Body';
import { ExpandReplies } from './Comment.ExpandReplies';
import { Header } from './Comment.Header';
import { Input } from './Comment.Input';
import { CommentContextMenuContext } from './Context';
import { useCommentReplyState } from './hooks/useCommentReplyState';
import styles from './style/Comment.css';

export type Props = {
  className?: string;
  item:       FeedItemComment;
  depth:      number;
};

export const Thread = ({ className, item, depth }: Props) => {
  const [replying, replymode] = useCommentReplyState(false);
  const [commentState, setCommentState] = useState(item);
  const [expanded, setExpanded] = useState(depth !== 0 || getChildrenCount(item) < 3);
  const [commentChildren, setChildrenState] = useState(item.children || []);

  const handleDelete = useCallback(() => {
    api.comments.deleteComment(item.id)
    .then(() => setCommentState(null));
  }, [
    item.id,
    setCommentState,
  ]);

  const handleSubmitReply = useCallback((value: Node[]) => {
    return api.comments.saveComment({
      comment: value,
      itemId: item.itemId,
      itemTypeId: item.itemTypeId,
      parentCommentId: item.id,
    })
    .then(data => {
      replymode.disable();

      setChildrenState([...commentChildren, data]);

      return data;
    });
  }, [
    commentChildren,
    item,
    replymode,
    setChildrenState,
  ]);

  if (!commentState) return null;

  const menu = {
    item,
    depth,
    onDelete: handleDelete,
    onReply: replymode.enable,
  };

  return (
    <CommentContextMenuContext.Provider value={menu}>
      <div className={cx(className, styles.root)}>
        <Header item={item} />

        <div className={styles.pad}>
          <Body value={item.comment} />
        </div>

        <Actions
          item={item}
          onClickReply={replymode.toggle} />

        {replying &&
          <Input onSubmit={handleSubmitReply} />}

        {expanded &&
          <Replies
            className={styles.reply}
            items={commentChildren}
            depth={depth + 1} />}
      </div>

      {!!(!expanded && commentChildren.length > 0) &&
        <ExpandReplies onClick={() => setExpanded(true)}>
          <span>See {getChildrenCount(item)} Replies</span>
        </ExpandReplies>}
    </CommentContextMenuContext.Provider>
  );
};

Thread.displayName = 'Comment.Thread';

type RepliesProps = {
  className?: string;
  depth:      number;
  items:      FeedItemComment[];
};

export const Replies = (props: RepliesProps) => {

  const content = props.items.map(x =>
    <Thread
      className={cx(styles.pad, props.className)}
      item={x}
      key={x.id}
      depth={props.depth} />
  );

  return (
    <Fragment>
      {content}
    </Fragment>
  );
};

Replies.displayName = 'Comment.Replies';

function getChildrenCount(comment: FeedItemComment) {
  return comment.children.length + sum(comment.children.map(c => getChildrenCount(c)));
}