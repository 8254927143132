import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import CancelIcon from '@mui/icons-material/Cancel';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import * as api from '@api';
import { ProjectPipelineQueryContext } from '@containers/Group.Project.Pipeline/Context';
import { isCallLike } from '@containers/GroupProject/utils/actions';
import { Tooltip } from '@presentation/Tooltip';
import { useToggle } from '@utils';
import { ParamsContext } from '$admin/components/Scheduling/Context';
import { Alert } from '@/components/Modal/Alert';
import type { TableCellItem } from './interfaces';
import styles from './style/Table.css';

type Props = TableCellItem;

export const Cell = (props: Props) => {
  if (!isCallLike(props.row.original.project)) {
    return (
      <SurveyResponseActions {...props} />
    );
  }

  return (
    <CallActions {...props} />
  );
};

Cell.displayName = 'Column.Actions.Cell';

const CallActions = (props: Props) => {
  const ctx = {
    scheduling: useContext(ParamsContext),
  };

  return (
    <button
      className={styles.action}
      onClick={() => ctx.scheduling.setContext({
        projectId: props.row.original.project.id,
        userId: props.row.original.respondent.id,
      })}>
      <Tooltip title="Schedule Call">
        <div className={styles.icon}>
          <div className={styles.bg}>
            <EventAvailableOutlinedIcon className={styles.svg} />
          </div>
        </div>
      </Tooltip>
    </button>
  );
};

const SurveyResponseActions = (props: Props) => {
  const ctx = {
    query: useContext(ProjectPipelineQueryContext),
  };
  const [open, toggle] = useToggle();

  const mutation = useMutation({
    mutationFn: () => api.projects.surveys.deleteSurveyResponse({
      projectId: props.row.original.project.id,
      surveyResponseId: props.row.original?.survey?.id,
    }),
    mutationKey: [
      `delete:projects/survey-responses`,
      props.row.original?.survey?.id,
    ],
    onSuccess: () => {
      ctx.query.refetch();
      toggle();
    },
  });

  if (!props.row.original?.survey?.id) {
    return null;
  }

  return (
    <>
      <button
        className={styles.action}
        onClick={toggle}>
        <Tooltip title="Remove survey response">
          <div className={styles.icon}>
            <div className={styles.bg}>
              <CancelIcon className={styles.svg} />
            </div>
          </div>
        </Tooltip>
      </button>
      <Alert
        message={`Remove survey response?`}
        loading={mutation.isLoading}
        onClose={toggle}
        open={open}
        onConfirm={mutation.mutate} />
    </>
  );
};