import styles from './style/NetworkTest.css';

export const NetworkTest = () => {
  return (
    <a
      className={styles.networkTest}
      href="/conference/network-test"
      target="_blank"
      rel="noreferrer">
      Test Your Connection
    </a>
  );
};