import { useContext, Dispatch } from 'react';
import { createNamedContext } from '@utils';
import { AudioPlayer } from './interfaces';

type AudioPlayerStateValue =
  [AudioPlayer.State, Dispatch<AudioPlayer.Actions>];

type AudioPlayerRefValue =
  [HTMLAudioElement, Dispatch<HTMLAudioElement>];

export const AudioPlayerRefContext = createNamedContext<AudioPlayerRefValue>(undefined, 'AudioPlayer.Context');
export const AudioPlayerStateContext = createNamedContext<AudioPlayerStateValue>(undefined, 'AudioPlayer.State.Context');

export const useAudioPlayerRef = () => useContext(AudioPlayerRefContext);
export const useAudioPlayerState = () => useContext(AudioPlayerStateContext);