import { useCallback, useContext, useMemo } from 'react';
import type { Chat } from '@/brand-insights/types';
import { useCopyToClipboard } from '@/brand-insights/hooks';
import { ChatCitationType } from '@/brand-insights/enums';
import { TranscriptGoodQuoteContainer } from '@/containers/Transcript/GoodQuote.Container';
import { TranscriptQuoteMomentIdContext, TranscriptToggleStarQuoteContext } from '@/containers/Transcript/context';
import { Quote } from './Message.Response.Quote';
import { CitationsContext, ClickCitationContext } from './context';

type Props = {
  className?: string;
  quote: Chat.Quote;
};

const ChatMessageResponseQuoteContainer = ({
  className,
  quote,
}: Props) => {

  const handleToggleStarQuote = useContext(TranscriptToggleStarQuoteContext);
  const starred = !!useContext(TranscriptQuoteMomentIdContext);

  const citations = useContext(CitationsContext);
  const goToCitation = useContext(ClickCitationContext);

  const copyToClipboard = useCopyToClipboard();

  const handleCopyToClipboard = useCallback(() => {
    copyToClipboard(quote.value);
  }, [copyToClipboard, quote.value]);

  const citationKey = useMemo(() => {

    const citation = citations.find(f => f.type === ChatCitationType.Transcript && f.id === quote.transcriptId.toString());

    if (!citation) return null;

    const snippetKey = `${citation.id}:${quote.startTs}:${quote.endTs}`;

    const snippet = quote.citationKey
      ? citation.snippets.find(f => f.key === quote.citationKey)
      : citation.snippets.find(f => f.key === snippetKey);

    console.log('snippet', snippet);

    if (!snippet) return null;

    const snippetAlpha = String.fromCharCode(96 + snippet.ordinal);

    console.log('snippetAlpha', snippetAlpha);

    const citationKey = `${citation.ordinal}${snippetAlpha}`;

    return citationKey;
  }, [citations, quote]);

  const onViewCitation = useCallback(() => {
    console.log('onViewCitation', citationKey);
    if (!citationKey) return;

    goToCitation(citationKey);
  }, [citationKey, goToCitation]);

  return (
    <Quote
      className={className}
      citationValue={citationKey}
      onCopy={handleCopyToClipboard}
      onToggleStar={handleToggleStarQuote}
      onViewCitation={onViewCitation}
      starred={starred}
      quote={quote} />
  );
};

const WithState = (props: Props) => {
  const range = useMemo(() => ({
    start: props.quote.startTs,
    end: props.quote.endTs,
  }), [props.quote.startTs, props.quote.endTs]);

  return (
    <TranscriptGoodQuoteContainer
      initialQuoteMomentId={props.quote.starredQuoteId}
      range={range}
      transcriptId={props.quote.transcriptId}>
      <ChatMessageResponseQuoteContainer {...props} />
    </TranscriptGoodQuoteContainer>
  );
};

export { WithState as ChatMessageResponseQuoteContainer };