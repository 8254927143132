import type { TextareaAutosizeProps } from '@mui/material/TextareaAutosize';
import TextareaAutosizeMUI from '@mui/material/TextareaAutosize';
import { cx } from '@utils';
import styles from './style.css';

export const TextareaAutosize = (props: TextareaAutosizeProps) => {
  const { className, ...otherProps } = props;

  return (
    <TextareaAutosizeMUI
      className={cx(styles.input, className)}
      {...otherProps} />
  );
};

export default TextareaAutosize;