import { DependencyList, useCallback } from 'react';

type AsyncReturn = (...args: unknown[]) => Promise<unknown>;

const usePromiseDelay = <T extends AsyncReturn>(fn: T, ms: number, deps?: DependencyList)  => {

  return useCallback((...args: Parameters<T>) => {
    return new Promise((resolve, reject) => {
      const start = Date.now();

      return fn(...args).then(x => {

        const elapsed = Math.abs(Date.now() - start);
        const remaining = ms - elapsed;

        remaining > 0
          ? setTimeout(() => resolve(x), remaining)
          : resolve(x);

      }).catch(reject);

    });

  }, [fn, ms]);
};

export { usePromiseDelay };
export default usePromiseDelay;