import http, { transformers } from '@services/http';
import { qs } from '@utils';
import type { GetProjectAccessUsers, Search, UpdateProjectAccessUsers, BackfillProjectCallAccess } from './interfaces/projects.access';

export const getProjectAccessUsers = (data: GetProjectAccessUsers.Request) => {
  return http.get<GetProjectAccessUsers.Response>(`/projects/${data.projectId}/access`);
};

export const search = (data: Search.Request) => {
  const { projectId, ...rest } = data;
  const q = qs.stringify(rest, { arrayFormat: 'bracket' });

  return http.get<Search.Response>(`projects/${projectId}/access/search?${q}`);
};

export const updateProjectAccessUsers = (data: UpdateProjectAccessUsers.Request) => {
  const { projectId, ...rest } = data;

  return http.put<UpdateProjectAccessUsers.Response>(`/projects/${projectId}/access`, rest, { transformResponse: transformers.transformDates });
};

export const backfillProjectCallAccess = (data: BackfillProjectCallAccess.Request) => {
  const { projectId, ...rest } = data;

  return http.post(`/projects/${projectId}/access/backfill-call-access`, rest);
};