import { forwardRef, useCallback, useState, useMemo, memo } from 'react';
import { cx } from '@utils';
import type { FancyInputProps, InputRefObject } from './interfaces';
import styles from './styles/style.css';

const FancyInput = ({
  autoComplete = 'off',
  autoFocus = false,
  className,
  classes = {},
  invalid = false,
  invalidClassName,
  onBlur,
  onFocus,
  readOnly,
  rootClassName,
  ...inputProps
}: FancyInputProps, ref: InputRefObject) => {
  const [focused, setFocus] = useState(autoFocus);

  const handleBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    setFocus(false);
    onBlur?.(e);
  }, [onBlur]);

  const handleFocus = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    setFocus(true);
    onFocus?.(e);
  }, [onFocus]);

  const invalidClasses = useMemo(() => cx(
    invalid && cx(invalidClassName, classes.invalid)
  ), [invalid, invalidClassName, classes.invalid]);

  const rootClasses = useMemo(() => cx(styles.root, rootClassName, classes.root), [rootClassName, classes.root]);
  const inputClasses = useMemo(() => cx(styles.input, styles.fancyInput, className, classes.input), [className, classes.input]);

  return (
    <div className={rootClasses}>
      <input
        {...inputProps}
        autoComplete={autoComplete}
        className={inputClasses}
        onBlur={handleBlur}
        onFocus={handleFocus}
        ref={ref} />
    </div>
  );
};

const InputRef = memo(forwardRef<HTMLInputElement, FancyInputProps>(FancyInput));

export { InputRef as FancyInput };