import { useContext } from 'react';
import { MedicalExperienceNavigationContext } from '@containers/ProjectOnboarding.MedicalExperience/Context';
import * as Layout from '@/components/MedicalProfile/Layout';
import { Button } from '@/components/Button';
import styles from './style/Experience.css';

type Props = unknown;

export const Overview = (props: Props) => {
  const ctx = useContext(MedicalExperienceNavigationContext);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Layout.Screen>
          <Layout.Header title={copy.title} />
          <div className={styles.overview}>
            {copy.paragraphs.map(text =>
              <div
                className={styles.paragraph}
                key={text}>
                {text}
              </div>)}
          </div>
          <Layout.Footer className={styles.footer}>
            <Button
              className={styles.btn}
              onClick={ctx.next}
              variant="brick">
              Get Started
            </Button>
          </Layout.Footer>
        </Layout.Screen>
      </div>
    </div>
  );
};

Overview.displayName = 'Experience.Overview';

const copy = {
  paragraphs: [
    `Get started by providing a bit of information on your background so we can determine whether you are the right fit for this project.`,
    `Thank you in advance and we look forward to welcoming you as the newest member of Sentiment!`,
  ],
  title: `Congratulations, on accepting your first potential project on Sentiment!`,
};