import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import * as API from '@api/interfaces';
import { ApprovalStatus } from '@enums';
import { ComplianceReviewItem } from '@/types';
import { useCallReviewDispatch } from './useCallReviewDispatch';
import { useCallReviewState } from './useCallReviewState';

type DispatchChangeParams = {
  call?:   API.Scheduling.Project.ApproveCall.Response['call'];
  project: Pick<ComplianceReviewItem<'call'>['project'], 'id'>;
} & Pick<API.Scheduling.Project.ApproveCall.Response, 'pipeline'>

const useCallApprovalActions = () => {
  const dispatch = useDispatch();
  const setItem = useCallReviewDispatch();
  const state = useCallReviewState();

  const handleChange = useCallback((data: DispatchChangeParams) => {

    const callUpdatedAction = data.call
        ? [ actions.callUpdated({ call: data.call }) ]
        : [ ];

    dispatch(actions.batchActions([
      actions.projectPipelineUpdated({
        pipeline: data.pipeline,
        projectId: data.project.id,
      }),
      ...callUpdatedAction as [ReturnType<typeof actions.callUpdated>],
    ]));

  }, [dispatch]);

  const approve = useCallback(() => {

    return api.scheduling.projectCall.approve({
      callId: state.call.id,
      notes: null,
      projectId: state.project.id,
    })
    .then(res => {

      handleChange({
        call: res.call,
        pipeline: res.pipeline,
        project: state.project,
      });

      setItem({
        ...state,
        call: {
          ...state.call,
          approvalStatusId: res.call?.approvalStatusId ?? ApprovalStatus.Approved,
        },
      });

      return res;

    });

  }, [
    handleChange,
    setItem,
    state,
  ]);

  const reject = useCallback(() => {

    return api.scheduling.projectCall.reject({
      callId: state.call.id,
      notes: null,
      projectId: state.project.id,
    })
    .then(res => {

      handleChange({
        pipeline: res.pipeline,
        project: state.project,
      });

      setItem({
        ...state,
        call: {
          ...state.call,
          approvalStatusId: ApprovalStatus.Rejected,
        },
      });

      return res;

    });

  }, [
    handleChange,
    setItem,
    state,
  ]);

  return {
    approve,
    reject,
  };

};

export { useCallApprovalActions };
export default useCallApprovalActions;