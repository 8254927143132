import { useState } from 'react';
import { AudioPlayerRefContext  } from './Context';
import { AudioPlayerState } from './State';

type Props =
  ChildrenProps;

const AudioPlayerContainer = (props: Props) => {

  const [ref, setRef] = useState<HTMLAudioElement>();

  return (
    <AudioPlayerState>
      <AudioPlayerRefContext.Provider value={[ref, setRef]}>
        {props.children}
      </AudioPlayerRefContext.Provider>
    </AudioPlayerState>
  );
};

export { AudioPlayerContainer };
export default AudioPlayerContainer;