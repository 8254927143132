import { useSelector } from 'react-redux';
import { selectMessageItems } from '@store/selectors';

const mapState = (state: Store.State) => selectMessageItems(state);

export function useSelectChatMessages(conversationSid: string) {
  const messages = useSelector(mapState);
  return messages[conversationSid] ?? [];
}

export default useSelectChatMessages;