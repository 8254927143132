import type { UseQueryOptions } from '@tanstack/react-query';
import { useIsFetching, useQuery } from '@tanstack/react-query';
import type { ConferenceTagSummaryItem } from '@/types';
import * as api from '@api';

type Props =
  & ITranscriptId;

type Data = ConferenceTagSummaryItem[];
type Options = UseQueryOptions<Data, unknown, Data, QueryKey>;
type QueryKey = readonly [string, number];

export function getTranscriptSummaryQueryKey(data: ITranscriptId) {
  return [
    `get:transcripts/summary`,
    data.transcriptId,
  ] as const;
}

export const useTranscriptSummaryLoading = (data: ITranscriptId) => {
  const key = getTranscriptSummaryQueryKey(data);

  return useIsFetching(key);
};

export const useFetchTranscriptSummary = (data: Props, options?: Options) => {
  const queryKey = getTranscriptSummaryQueryKey(data);

  return useQuery({
    queryKey,
    queryFn: ({ queryKey }) => {
      const [, transcriptId] = queryKey;

      return api.transcripts.fetchSummary({
        transcriptId,
      }).then(res => res.items);
    },
    ...options,
    refetchOnWindowFocus: false,
  });
};