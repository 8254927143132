import { useCallback, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ConferenceTagSummaryItem, ConferenceTagSummaryRating, TagSummaryFeedback } from '@/types/conferences.tags';
import * as $api from '@api';
import { useInvalidateTranscriptTagSummaries } from '@utils/api';
import { TagSummaryFeedbackModal } from '@/components/ConferenceTag.Summary/Modal.Feedback.Form';
import { SummaryFeedbackModalToggleContext } from './context';

type Props = ChildrenProps & ITranscriptId;

type Value = Pick<TagSummaryFeedback, 'notes' | 'structuredFeedback' | 'rating'>;

const initialData: Value = {
  notes: '',
  structuredFeedback: [],
  rating: null,
};

export const TranscriptSummaryFeedbackFormContainer = ({ children, transcriptId }: Props) => {

  const [feedback, setFeedback] = useState<Value>(initialData);
  const [openItem, setOpenItem] = useState<ConferenceTagSummaryItem>();
  const [initialRating, setInitialRating] = useState<ConferenceTagSummaryRating>(null);

  const invalidateSummaries = useInvalidateTranscriptTagSummaries({ transcriptId });

  const tagSummaryId = openItem?.summary?.id;

  const { mutateAsync, isLoading: isSubmitting } = useMutation(['transcript:update-tag-summary-feedback', {
    transcriptId,
    tagSummaryId,
  }], () => {
    return $api.transcripts.updateTagSummaryFeedback({
      transcriptId,
      tagSummaryId,
      ...feedback,
    });
  }, {
    onSuccess: () => {
      setInitialRating(null);
      setOpenItem(null);
      invalidateSummaries();
    },
  });

  const {
    isInitialLoading: isLoading,
    isFetching,
  } = useQuery(['get:transcript:tag-summaries:feedback', {
    transcriptId,
    tagSummaryId,
  }], ({ queryKey }) => {
    return $api.transcripts.getTagSummaryFeedback({
      transcriptId,
      tagSummaryId,
    });
  }, {
    enabled: !!tagSummaryId,
    onSuccess: data => {
      setFeedback({
        ...(data.feedback ?? initialData),
        rating: initialRating ?? data.feedback?.rating,
      });
    },
    refetchOnWindowFocus: false,
  });

  const handleOpen = useCallback((item: ConferenceTagSummaryItem, rating?: ConferenceTagSummaryRating) => {
    setFeedback(initialData);
    setOpenItem(item);
    setInitialRating(rating);
  }, []);

  const handleClose = useCallback(() => {
    setOpenItem(null);
    setInitialRating(null);
  }, []);

  const open = !!tagSummaryId;

  return (
    <SummaryFeedbackModalToggleContext.Provider value={handleOpen}>
      {children}
      {open && (
        <TagSummaryFeedbackModal
          summaryItem={openItem}
          canEdit={!isLoading && !isFetching}
          open={open}
          onClose={handleClose}
          value={feedback}
          onChange={setFeedback}
          isSubmitting={isSubmitting}
          onSubmit={mutateAsync} />
      )}
    </SummaryFeedbackModalToggleContext.Provider>
  );
};