import { useMemo } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useHasGroupFeature } from '@store/hooks';
import { useIsInternalUser } from '@/containers/Store/hooks';
import useSearchFiltersQuery from '@/containers/WorkspaceGlobalSearch/hooks/useSearchFilterQuery';
import {
  CellTranscriptCreatedOn,
  CellTranscriptOccurrences,
  CellOwner,
  CellTranscriptTags,
  CellTranscriptName,
  CellTranscriptProject,
} from '../Cells';

export default function useTranscriptsTableColumns() {
  const isDesktop = useMediaQuery('(min-width:900px)');
  const hasTagging = useHasGroupFeature('userTagging');
  const isInternalUser = useIsInternalUser();
  const [searchQuery] = useSearchFiltersQuery();

  return useMemo(() => [
    {
      accessor: 'name',
      id: 'name',
      Header: 'Name',
      Cell: CellTranscriptName,
      minWidth: 150,
      maxWidth: 2,
    },
    isDesktop ? {
      accessor: 'project',
      id: 'project',
      Header: 'Project',
      Cell: CellTranscriptProject,
      minWidth: 100,
      maxWidth: 1,
    } : null,
    isDesktop && hasTagging ? {
      accessor: 'tags',
      id: 'tags',
      Header: 'Tags',
      Cell: CellTranscriptTags,
      minWidth: 175,
      maxWidth: 0,
    } : null,
    isInternalUser && isDesktop ? {
      accessor: 'owner',
      id: 'owner',
      Header: 'Owner',
      Cell: CellOwner,
      minWidth: 100,
      maxWidth: 0,
    } : null,
    isDesktop ? {
      accessor: 'createdOn',
      id: 'createdOn',
      Header: 'Created On',
      Cell: CellTranscriptCreatedOn,
      minWidth: 125,
      maxWidth: 0,
    } : undefined,
    isDesktop && searchQuery.keywords.length && {
      accessor: 'occurrences',
      id: 'occurrences',
      Header: 'Highlights',
      Cell: CellTranscriptOccurrences,
      minWidth: 90,
      maxWidth: 0,
    },
  ].filter(Boolean), [
    isDesktop,
    isInternalUser,
    hasTagging,
    searchQuery.keywords,
  ]);
}

export { useTranscriptsTableColumns };