import { useState, useContext, useCallback } from 'react';
import { TranscriptReplacementsContext } from '@containers/Group.Project.Terms';
import type { TranscriptHighlight } from '@containers/Group.Project.Terms/interfaces';
import { Textarea } from '@/components/Textarea';
import type { UseModalProps } from '@/components/Modal';
import { Modal, Header, useModal } from '@/components/Modal';
import { Button } from '@/components/Button';
import styles from './styles/Highlights.EditModal.css';

type Props = UseModalProps & {
  highlight: TranscriptHighlight;
};

export const EditModal = ({ highlight, ...props }: Props) => {
  const [replacement, setReplacement] = useState(highlight.rawHighlight);
  const { addReplacements } = useContext(TranscriptReplacementsContext);

  const onSave = useCallback(() => {
    addReplacements([{
      replacementText: replacement,
      expectedText: highlight.rawHighlight,
      docPos: highlight.highlightDocPos,
      tsStart: highlight.highlightTimePos.fromTs,
      transcriptId: highlight.transcript.id,
    }]);
    props.onClose();
  }, [addReplacements, highlight.highlightDocPos, highlight.highlightTimePos.fromTs, highlight.rawHighlight, highlight.transcript.id, props, replacement]);

  return (
    <Modal {...props}>
      <Header>Replace Text</Header>
      <div className={styles.section}>
        <div className={styles.label}>Selection:</div>
        <Textarea value={highlight.rawHighlight} disabled={true} />
      </div>
      <div className={styles.section}>
        <div className={styles.label}>Replace with:</div>
        <Textarea value={replacement} onChange={e => setReplacement(e.target.value)} />
      </div>
      <div className={styles.btns}>
        <Button
          variant='brick'
          color='destructive'
          onClick={props.onClose}>Cancel
        </Button>
        <Button
          variant='brick'
          color='primary'
          onClick={onSave}>Save
        </Button>
      </div>
    </Modal>
  );
};

export const useEditModal = () => useModal(EditModal);