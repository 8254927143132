import { useCallback, useMemo, useState } from 'react';
import { useMessageTestingData } from '@containers/SurveyBuilder/hooks';
import { SurveyTemplate } from '@/types/survey';
import { useSubmitTemplateMedicalSpecialties } from './medical-specialties.submit';

export const useCanSaveMedicalSpecialties = (data: SurveyTemplate.MessageTesting.Specialties) => {

  return useMemo(() => {
    return !!data.enabled.length;
  }, [data]);
};

export const useTemplateMedicalSpecialties = () => {
  const [templateData] = useMessageTestingData();
  const [specialties, setSpecialties] = useState(templateData.specialties);

  const onSelect = useCallback((field: keyof SurveyTemplate.MessageTesting.Specialties) => (value: SurveyTemplate.LinkedEntity<'medical-specialty'>) => {
    setSpecialties(s => ({
      ...s,
      [field]: s[field].concat(value),
    }));
  }, []);

  const onRemove = useCallback((field: keyof SurveyTemplate.MessageTesting.Specialties) => (id: string) => {
    setSpecialties(s => ({
      ...s,
      [field]: s[field].filter(f => f.id !== id),
    }));
  }, []);

  const submit = useSubmitTemplateMedicalSpecialties();

  const onSubmit = useCallback(() => {
    submit(specialties);
  }, [
    specialties,
    submit,
  ]);

  return {
    onSelect,
    onRemove,
    onSubmit,
    value: specialties,
  };
};