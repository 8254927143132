import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import { useSelectUser } from '@containers/Store';
import { HandleSurveySubmittedParams } from '../interfaces';

export const useSubmitProjectSurvey = (projectId: number) => {
  const dispatch = useDispatch();
  const user = useSelectUser();

  return useCallback((data: HandleSurveySubmittedParams) => {
    return api.projects.surveys.saveProjectSurveyResponse({
      projectId,
      responseIdentifier: data.responseIdentifier,
      surveyVersionId: data.surveyVersionId,
      userId: user.id,
    })
    .then(data => {
      const { pipeline, ...updates } = data;
      dispatch(actions.batchActions([
        actions.projectUpdated({
          project: updates,
          projectId,
        }),
        actions.projectPipelineUpdated({
          pipeline,
          projectId,
        }),
      ]));
    });
  }, [
    dispatch,
    projectId,
    user.id,
  ]);
};

export default useSubmitProjectSurvey;