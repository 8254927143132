import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { format } from 'date-fns';
import Skeleton from '@mui/material/Skeleton';
import { CreatedByWhenLabel, CreatedByWhenSkeleton } from '@presentation';
import styles from '@screens/Workspace.File/style/Header.css';
import { FolderLoadingContext, FolderStateContext } from '@/containers/Workspace.Folder/Context';
import { FolderBreadcrumbs } from './Header.Breadcrumbs';
import { Actions } from './Header.Actions';

export const Header = () => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>

          <FolderBreadcrumbs />

          <div className={styles.details}>
            <div className={styles.info}>

              <div className={styles.header}>
                <Title />
              </div>

            </div>

            <Actions />
          </div>

          <div className={styles.subtitle}>
            <CreatedBy />
          </div>

        </div>
      </div>
    </div>
  );
};

const CreatedBy = (props: unknown) => {
  const isLoading = useContext(FolderLoadingContext);
  const { object } = useContext(FolderStateContext);

  if (isLoading) {
    return (
      <CreatedByWhenSkeleton />
    );
  }

  return (
    <CreatedByWhenLabel
      className={styles.age}
      by={object.owner.name}
      when={`on ${format(object.createdOn, 'M/d/yyyy')}`} />
  );
};

CreatedBy.displayName = 'Folder.Header.CreatedBy';

const Title = (props: unknown) => {
  const isLoading = useContext(FolderLoadingContext);
  const { folder } = useContext(FolderStateContext);

  if (isLoading) {
    return (
      <Skeleton
        style={{ marginRight: 10 }}
        height={30}
        width={400}
        variant="rectangular" />
    );
  }

  return (
    <>
      <Helmet title={folder.name} />
      <h1 className={styles.title}>{folder.name}</h1>
    </>
  );
};

Title.displayName = 'Folder.Header.Title';