import { useEffect, useCallback, useMemo } from 'react';
import cuid from 'cuid';
import { useDebounceCallback } from '@utils';
import type { ImageMarkupQuestion } from '@/types/survey.question.image-markup';
import { useImageMarkupEditorContext, useSurveyFormQuestionAnswer } from '@containers/SurveyForm/Context';
import type { SurveyQuestionType } from '@/enums';
import { FILL_TYPES } from '../utils.image-markup';

type ObjectAddedEvent = tuiImageEditor.ObjectAddedEvent;

export const useImageMarkupEditorEvents = () => {
  const { editor: instance } = useImageMarkupEditorContext();
  const [answer, setAnswer] = useSurveyFormQuestionAnswer<SurveyQuestionType.ImageMarkup>();
  const editingIdentifier = useMemo(() => cuid(), []);

  const buildStructuredObject = useCallback((obj: ObjectAddedEvent, type: ImageMarkupQuestion.StructuredMarkupType) => {
    const x = obj.left - (obj.width / 2);
    const y = obj.top - (obj.height / 2);
    const markupData: ImageMarkupQuestion.StructuredMarkup = {
      id: `${editingIdentifier}-${obj.id}`,
      start: {
        x,
        y,
      },
      end: {
        x: x + obj.width,
        y: y + obj.height,
      },
      type,
    };

    return markupData;
  }, [editingIdentifier]);

  const onObjectAdded = useCallback((obj: ObjectAddedEvent) => {
    if (obj.type !== 'rect') return;

    const matchingStructure = FILL_TYPES.find(f => f.fill === obj.fill.color && f.stroke === obj.stroke);

    if (matchingStructure) {
      const markupData = buildStructuredObject(obj, matchingStructure.structureType);
      setAnswer({
        ...answer,
        markupMetadata: {
          markupStructures: [...answer.markupMetadata.markupStructures, markupData],
        },
      });
    }
  }, [answer, setAnswer, buildStructuredObject]);

  const onObjectModified = useCallback((obj: ObjectAddedEvent) => {
    if (obj.type !== 'rect') return;

    const matchingStructure = FILL_TYPES.find(f => f.fill === obj.fill.color && f.stroke === obj.stroke);

    if (!matchingStructure) return;

    const markupStructure = buildStructuredObject(obj, matchingStructure.structureType);

    setAnswer({
      ...answer,
      markupMetadata: {
        ...answer.markupMetadata,
        markupStructures: answer.markupMetadata.markupStructures.map(m => m.id === markupStructure.id ? markupStructure : m),
      },
    });
  }, [answer, setAnswer, buildStructuredObject]);

  const onUndoStackChanged = useCallback(() => {
    //There's no event to track when an object is deleted so we need to check for orphaned objects every time the stack changes :(
    //Go through each tracked object and make sure it still exists in the editor.

    const toRemove = [];
    for (const markup of answer.markupMetadata.markupStructures) {
      const [identifier, id] = markup.id.split('-');
      if (identifier === editingIdentifier) {
        if (!instance.getObjectProperties(+id, [])) {
          toRemove.push(markup.id);
        }
      }
    }

    if (toRemove.length) {
      setAnswer({
        ...answer,
        markupMetadata: {
          markupStructures: answer.markupMetadata.markupStructures.filter(m => !toRemove.includes(m.id)),
        },
      });
    }
  }, [answer, editingIdentifier, instance, setAnswer]);

  const debouncedUndoStackChanged = useDebounceCallback(onUndoStackChanged, 100);

  useEffect(() => {
    if (instance) {
      instance.on('objectAdded', onObjectAdded);
      instance.on('objectMoved', onObjectModified);
      instance.on('objectScaled', onObjectModified);
      instance.on('undoStackChanged', debouncedUndoStackChanged);
      instance.on('executeCommand', c => { console.log('command executed'); console.log(c); });

      return () => {
        instance.off('objectAdded', onObjectAdded);
        instance.off('objectMoved', onObjectModified);
        instance.off('objectScaled', onObjectModified);
        instance.off('undoStackChanged', debouncedUndoStackChanged);
      };
    }
  }, [instance, onObjectAdded, onObjectModified, debouncedUndoStackChanged]);
};

export const useImageResizerEvents = () => {
  const { editor: instance } = useImageMarkupEditorContext();

  const onImageLoaded = useCallback(() => {
    const canvasSize = instance.getCanvasSize();

    instance.ui.resizeEditor({
      uiSize: {
        width: '100%',
        height: `${canvasSize.height}px`,
      },
    });
  }, [instance]);

  const onCommandExecuted = useCallback((c: { name: string }) => {
    if (c.name === 'loadImage') {
      onImageLoaded();
    }
  }, [onImageLoaded]);

  useEffect(() => {
    if (instance) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const invoker: tuiImageEditor.MyImageEditor = instance['_invoker'];

      if (invoker) {
        invoker.on('executeCommand', onCommandExecuted);

        return () => {
          invoker.off('executeCommand', onCommandExecuted);
        };
      }
    }
  }, [instance, onCommandExecuted]);
};

export const useVanillaEditorEvents = () => {
  const { editor: instance } = useImageMarkupEditorContext();

  const onObjectAdded = useCallback(() => {
    instance.stopDrawingMode();
    instance.changeSelectableAll(true);
  }, [instance]);

  useEffect(() => {
    if (instance) {
      instance.on('objectAdded', onObjectAdded);

      try {
        instance.registerIcons({
          'icon-star': 'M35,54.557999 l-19.912001,10.468 l3.804,-22.172001 l-16.108,-15.7 l22.26,-3.236 L35,3.746 l9.956,20.172001 l22.26,3.236 l-16.108,15.7 l3.804,22.172001  z ',
        });
      } catch (e) {
        //The editor is probably already destroyed, ideally we shouldn't be running this on a destroyed editor
      }

      return () => {
        instance.off('objectAdded', onObjectAdded);
      };
    }
  }, [instance, onObjectAdded]);
};