import { createRef, useCallback, useContext, useEffect, useState } from 'react';
import { useVideoPostObjectURL } from '@containers/PostCreation/hooks/usePostCreationContext';
import { cx } from '@utils';
import { VideoSnapshotContext } from './Video.Context';
import styles from './style/Video.NativePlayerPoster.css';

type Props = {
  className?: string;
};

export const NativePlayerPoster = (props: Props) => {
  const [snapshot, setSnapshot] = useContext(VideoSnapshotContext);
  const [state, dispatch] = useState(initial);
  const url = useVideoPostObjectURL();
  const canvas = createRef<HTMLCanvasElement>();
  const video = createRef<HTMLVideoElement>();

  const capture = useCallback(() => {
    const { videoWidth, videoHeight } = video.current;

    const h = 720;
    const w = videoWidth * (h / videoHeight);

    canvas.current.width = w;
    canvas.current.height = h;

    const ctx = canvas.current.getContext('2d');
    ctx.drawImage(video.current, 0, 0, w, h);

    const snapshot = canvas.current.toDataURL('image/png');

    setSnapshot(snapshot);

  }, [
    canvas,
    setSnapshot,
    video,
  ]);

  useEffect(() => {

    if (!snapshot && state.playing) {
      video.current.currentTime = 1;
      video.current.pause();
      capture();
    }

  }, [
    capture,
    snapshot,
    state.playing,
    video,
  ]);

  return (
    <>
      <img
        className={styles.img}
        src={snapshot} />
      <canvas
        className={styles.hidden}
        ref={canvas} />
      <video
        autoPlay
        className={cx(styles.video, props.className)}
        controls
        muted
        onPlaying={() => dispatch({ ...state, playing: true })}
        playsInline
        ref={video}
        style={{ visibility: 'hidden' }}
        src={url.video} />
    </>
  );
};

const initial = {
  playing: false,
};

NativePlayerPoster.displayName = 'PostCreation.Video.NativePlayerPoster';