import { useContext } from 'react';
import { ProjectActionsContext, ProjectActionsValidityContext } from '../Context';

export const useProjectActions = () => {
  const context = useContext(ProjectActionsContext);

  return context;
};

export const useProjectActionsValidity = () => {
  const context = useContext(ProjectActionsValidityContext);

  return context;
};

export default useProjectActions;