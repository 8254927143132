import { EditableText } from '@presentation/EditableText';
import * as Table from '@/components/Table';
import styles from './style/Draggable.css';

type Props = {
  editable:   boolean;
  isValid:    (val: string) => boolean;
  onSubmit:   (val: string) => void;
  value:      string;
};

export const Cell = (props: Props) => {
  return (
    <Table.Body.TD className={styles.title}>
      <EditableText
        className={styles.input}
        isEditable={props.editable}
        isValid={props.isValid}
        onSave={props.onSubmit}
        value={props.value} />
    </Table.Body.TD>
  );
};

Cell.displayName = 'Column.Title.Cell';

export const Header = (props: unknown) => {
  return (
    <Table.Header.TH className={styles.title}>
      Tag Title
    </Table.Header.TH>
  );
};

Header.displayName = 'Column.Title.Header';