import { SurveyQuestionConditionType } from '@/enums';
import type { SurveyLogic, SurveyBuilderVersion } from '@/types/survey';
import type { QuestionConditionValidation } from './interfaces';

export function validateQuestionCondition({ data: condition }: SurveyLogic.QuestionCondition, survey: SurveyBuilderVersion): QuestionConditionValidation {

  const validation: QuestionConditionValidation = {
    question: {
      success: true,
    },
    type: {
      success: true,
    },
    value: {
      success: true,
    },
  };

  if (!condition.question.identifier || condition.question.identifier === '') {
    validation.question = {
      message: 'Question must be set',
      success: false,
    };
  }

  if (!condition.type) {
    validation.type = {
      message: 'Condition type must be set',
      success: false,
    };
  }

  switch (condition.type) {

    case SurveyQuestionConditionType.MatrixValueChosen:
    case SurveyQuestionConditionType.MatrixValueNotChosen: {
      if (!condition.value.row.identifier || condition.value.row.identifier === '') {
        validation.value = {
          message: 'Row must be set',
          success: false,
        };
        break;
      }
      if (!condition.value.option.identifier || condition.value.option.identifier === '') {
        validation.value = {
          message: 'Option must be set',
          success: false,
        };
        break;
      }
      break;
    }

    case SurveyQuestionConditionType.NumberCellValue: {
      if (!condition.value.row.identifier || condition.value.row.identifier === '') {
        validation.value = {
          message: 'Row must be set',
          success: false,
        };
        break;
      }
      if (!condition.value.option.identifier || condition.value.option.identifier === '') {
        validation.value = {
          message: 'Option must be set',
          success: false,
        };
        break;
      }
      if (condition.value.operator === null) {
        validation.value = {
          message: 'Operator must be set',
          success: false,
        };
        break;
      }
      if (condition.value.comparate === null) {
        validation.value = {
          message: 'Value must be set',
          success: false,
        };
        break;
      }

      break;
    }

    case SurveyQuestionConditionType.NumberOfOptionsSelected: {

      if (condition.value.comparate === null) {
        validation.value = {
          message: 'Value must be set',
          success: false,
        };
        break;
      }

      break;
    }

    case SurveyQuestionConditionType.OptionNotSelected:
    case SurveyQuestionConditionType.OptionSelected:
    case SurveyQuestionConditionType.OptionRankedFirst:
    case SurveyQuestionConditionType.OptionRankedLast: {
      if (!condition.value.option.identifier || condition.value.option.identifier === '') {
        validation.value = {
          message: 'Option must be set',
          success: false,
        };
        break;
      }
      break;
    }

    case SurveyQuestionConditionType.OptionRankedTopX: {
      if (!condition.value.option.identifier || condition.value.option.identifier === '') {
        validation.value = {
          message: 'Option must be set',
          success: false,
        };
        break;
      }
      if (!condition.value.ordinal) {
        validation.value = {
          message: 'Ordinal must be set',
          success: false,
        };
        break;
      }
      const question = survey.questions.find(f => f.base.identifier === condition.question.identifier);
      if (condition.value.ordinal > question.options.length) {
        validation.value = {
          message: `Ordinal must be <= ${question.options.length}`,
          success: false,
        };
      }
      break;
    }

    case SurveyQuestionConditionType.SliderValue: {

      if (!condition.value.row.identifier || condition.value.row.identifier === '') {
        validation.value = {
          message: 'Row must be set',
          success: false,
        };
        break;
      }

      if (condition.value.operator === null) {
        validation.value = {
          message: 'Operator must be set',
          success: false,
        };
        break;
      }

      if (condition.value.comparate === null) {
        validation.value = {
          message: 'Value must be set',
          success: false,
        };
        break;
      }

      break;
    }

    case SurveyQuestionConditionType.SliderValuesTally: {
      if (condition.value.rows.operator === null) {
        validation.value = {
          message: 'Operator must be set',
          success: false,
        };
        break;
      }

      if (condition.value.rows.comparate === null) {
        validation.value = {
          message: 'Value must be set',
          success: false,
        };
        break;
      }

      if (condition.value.answer.operator === null) {
        validation.value = {
          message: 'Operator must be set',
          success: false,
        };
        break;
      }

      if (condition.value.answer.comparate === null) {
        validation.value = {
          message: 'Value must be set',
          success: false,
        };
        break;
      }

      validation.value = {
        success: true,
      };

      break;
    }

    case SurveyQuestionConditionType.Straightline: {
      validation.value = {
        success: true,
      };

      const question = survey.questions.find(f => f.base.identifier === condition.question.identifier);
      if (!question) break;

      if (condition.value.comparate && condition.value.comparate > question.matrixRows.length) {
        validation.value = {
          message: `Value must be <= ${question.matrixRows.length}`,
          success: false,
        };
      }

      break;
    }

    default:
      throw new UnreachableCaseError(condition);
  }

  return validation;
}