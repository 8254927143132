import { useCallback } from 'react';
import { MaxDiffChoiceClosed, MaxDiffChoiceOpen } from '@presentation/ProjectSurveyResponses';
import { useParseSurveyRichText } from '@/containers/SurveyBuilder';
import type { HybridSurveyResponseUserId, MaxDifferenceQuestion } from '@/types/survey';
import { ResponseOptionUser } from '@/components/SurveyResponses';
import MaxDiffUser from '@/components/SurveyResponses/MaxDiff.Data.User';
import styles from './style/Data.css';

export default function MaxDiffDataOptionChoices({ active, response, settings, onClick }: OptionChoicesProps) {

  const parseRichText = useParseSurveyRichText();

  const handleClick = useCallback((choice: MaxDifferenceQuestion.Choices) => () => {
    onClick(choice);
  }, [onClick]);

  const handleClose = useCallback(() => {
    onClick(undefined);
  }, [onClick]);

  return (
    <div className={styles.root}>
      <Choice
        active={active === 'most'}
        choice='most'
        label={parseRichText(settings.label.right)}
        value={response.right}
        userIds={response.responses.rightUserIds}
        userResponseMap={response.responses.right}
        onClick={handleClick}
        onClose={handleClose} />
      <Choice
        active={active === 'least'}
        choice='least'
        label={parseRichText(settings.label.left)}
        value={response.left}
        userIds={response.responses.leftUserIds}
        userResponseMap={response.responses.left}
        onClick={handleClick}
        onClose={handleClose} />
      <Choice
        active={active === 'nc'}
        choice='nc'
        label={`Not Selected`}
        value={response.nc}
        userIds={response.responses.ncUserIds}
        userResponseMap={response.responses.nc}
        onClick={handleClick}
        onClose={handleClose} />
    </div>
  );
}

function Choice({ active, choice, label, value, userIds, userResponseMap, onClick, onClose }: ChoiceProps) {
  return active
    ? (
      <>
        <MaxDiffChoiceOpen
          label={label}
          value={value}
          onClick={onClose} />
        {userIds.map(userId =>
          <ResponseOptionUser
            key={userId}
            renderItem={value => <MaxDiffUser {...value} count={userResponseMap[userId]} />}
            userId={userId} />)}
      </>
      )
    : <MaxDiffChoiceClosed
        label={label}
        value={value}
        onClick={onClick(choice)} />;
}

export { MaxDiffDataOptionChoices };

type OptionChoicesProps = {
  active: MaxDifferenceQuestion.Choices;
  response: MaxDifferenceQuestion.Aggregate.OptionResults;
  settings: MaxDifferenceQuestion.Settings;
  onClick: (choice: MaxDifferenceQuestion.Choices) => unknown;
};

type ChoiceProps = {
  active: boolean;
  choice: MaxDifferenceQuestion.Choices;
  label: string;
  value: number;
  userIds: HybridSurveyResponseUserId[];
  userResponseMap: { [userId: HybridSurveyResponseUserId]: number };
  onClick: (choice: MaxDifferenceQuestion.Choices) => () => unknown;
  onClose: () => unknown;
};