import { useCallback, useMemo } from 'react';
import { SurveyQuestionMatrixRow } from '@/types';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import DropDown from '@/components/DropDown';

type Props = {
  disabled?: boolean;
  items: SurveyQuestionMatrixRow[];
  onSelect: (identifier: string) => void;
  value: SurveyQuestionMatrixRow;
  placeholder?: string;
};

type DropdownItem = {
  id: string;
  value: string;
};

export const RowDropdown = ({
  disabled,
  items,
  onSelect,
  value,
  placeholder = 'Select Row',
}: Props) => {

  const options = useMemo(() => {
    return items.map(m => ({
      id: m.base.identifier,
      value: parseSurveyRichText(m.value),
    }));
  }, [items]);

  const getItemValue = useCallback((item: DropdownItem) => {
    return item?.value || '';
  }, []);

  const handleSelect = useCallback((item: DropdownItem) => {
    onSelect(item.id);
  }, [onSelect]);

  const text = useMemo(() => {
    return value?.value ? parseSurveyRichText(value.value) : null;
  }, [value]);

  return (
    <DropDown<DropdownItem>
      placeholder={placeholder}
      disabled={disabled}
      items={options}
      getItemValue={getItemValue}
      onSelect={handleSelect}
      text={text} />
  );
};

export default RowDropdown;