import format from 'date-fns-tz/format';
import { cx } from '@utils';
import styles from './style/Calendar.css';

type Props = {
  className?: string;
};

export const TimeZone = (props: Props) => {
  return (
    <div className={cx(styles.tz, props.className)}>
      {format(Date.now(), 'zzz')}
    </div>
  );
};