import * as surveyBuilder from '@containers/SurveyBuilder/utils';
import { useCanCreateFindAndReplace } from '@/containers/SurveyBuilder/hooks/useCanCreateFindAndReplace';
import { QuestionOptionsBuilderContainer } from '@containers/SurveyBuilder.Question/OptionsBuilderContainer';
import { QuestionSettingTitle } from '@presentation/SurveyBuilder';
import type { MultipleChoiceQuestion } from '@/types/survey';
import { ResponsePiping } from './ResponsePiping';
import { MultipleChoiceOptionContextMenu } from './MultipleChoice.Option.ContextMenu';
import QuestionOptionsBuilder from './OptionsBuilder';
import { ReuseQuestionOptions } from './ReuseQuestionValues';
import { FindAndReplaceOptions } from './FindAndReplace';
import {
  RationaleCheckbox,
  RandomizeCheckbox,
  OrderOptionsBasedOnSource,
} from './Settings';
import styles from './style/Multiselect.Question.css';
import { useHasLinkedOptions, useShowOptionOrdering } from './hooks';
import { LanguageEditingBarrier } from './LanguageEditingBarrier';

type Props = {
  item: MultipleChoiceQuestion.Question;
};

export const MultipleChoiceQuestionBuilder = ({ item }: Props) => {

  const hasLinkedOptions = useHasLinkedOptions(item);
  const showOptionOrdering = useShowOptionOrdering(item);
  const canFar = useCanCreateFindAndReplace();

  return (
    <>
      <QuestionSettingTitle title="Options">
        <ResponsePiping disabled={hasLinkedOptions} />
        <ReuseQuestionOptions />
        {canFar ? <FindAndReplaceOptions question={item} /> : null}
      </QuestionSettingTitle>

      <QuestionOptionsBuilderContainer
        maxOptions={surveyBuilder.multipleChoice.MaxOptions}
        minOptions={surveyBuilder.multipleChoice.MinOptions}>
        <QuestionOptionsBuilder
          renderAction={option => (
            <MultipleChoiceOptionContextMenu
              question={item}
              option={option as MultipleChoiceQuestion.Option} />
          )} />
      </QuestionOptionsBuilderContainer>

      <LanguageEditingBarrier>
        <RandomizeCheckbox
          disabled={item.settings.orderOptionsBasedOnSource}
          className={styles.checkbox} />

        {showOptionOrdering &&
          <OrderOptionsBasedOnSource
            disabled={item.settings.randomization}
            className={styles.checkbox} />
        }

        <RationaleCheckbox className={styles.checkbox} />
      </LanguageEditingBarrier>
    </>
  );
};

export default MultipleChoiceQuestionBuilder;