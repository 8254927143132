import { useEffect, useContext, useMemo } from 'react';
import { List } from 'react-feather';
import { TableEmpty } from '@presentation';
import * as $api from '@api';
import { useProjectOrLatestChild } from '@containers/GroupProject/hooks';
import { TermFilterContext } from '@containers/Group.Project.Terms';
import { useBoostingModal } from '@screens/Project.Entities/Boosting.Modal';
import { useBoostDismiss } from '@screens/Project.Entities/hooks';

type Props = unknown;

export const TermsTableEmpty = (props: Props) => {
  const [open, Modal] = useBoostingModal();
  const { slug, id: projectId } = useProjectOrLatestChild();
  const { isDismissed } = useBoostDismiss();

  const qpCtx = useContext(TermFilterContext);

  const isFiltering = useMemo(() => !!qpCtx?.searchTerm, [qpCtx?.searchTerm]);

  useEffect(() => {
    if (!isDismissed) {
      $api.projects.entities.isProjectEntityBoostLocked({ projectId }).then(res => {
        if (!res.locked) {
          open();
        }
      });
    }
  }, [open, isDismissed, projectId]);
  return (
    <>
      <TableEmpty
        Icon={() => <List size={120} />}
        message={copy.message}
        note={isFiltering ? copy.filtersActive : copy.note} />
      <Modal slug={slug} />
    </>
  );
};

TermsTableEmpty.displayName = 'Project.Entities.Table.Empty';

const copy = {
  message: `No entities available.`,
  note: `Import a series of entities to be boosted in interview transcripts or conduct interviews to see entities for the project.`,
  filtersActive: `Change your filters to see more entities.`,
};