import { Activity } from 'react-feather';
import { cx } from '@utils';
import type { LogsIconProps } from './interfaces';
import styles from './style.css';

export const LogsIcon = (props: LogsIconProps) => (
  <div
    className={cx(styles.icon, { [styles.active]: props.active }, props.className)}
    onClick={props.onClick}>
    <Activity size={16} />
  </div>
);