import { Helmet } from 'react-helmet';
import styles from './TransientMain.css';
import { TransientDashboard } from './Dashboard';

const TransientMain = () => {
  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Helmet title="Home" />
        <TransientDashboard />
      </div>
    </div>
  );
};

export { TransientMain };
export default TransientMain;