import { useDraggable } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { Draggable } from 'react-beautiful-dnd';
import { CSS } from '@dnd-kit/utilities';
import type { EntryProps } from './Editor.Entry';
import { Entry } from './Editor.Entry';
import { buildEntryIdentifier } from './utils';

export const DraggableEntry = (props: EntryProps) => {
  return (
    <Draggable draggableId={buildEntryIdentifier(props.entry)} index={props.entry.ordinal}>
      {
        (provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}>
            <Entry {...props} imageProps={provided.dragHandleProps} />
          </div>
        )
      }
    </Draggable>
  );
};