import { Fragment, useCallback } from 'react';
import fscreen from 'fscreen';
import type { IConference } from '@containers/Conference';
import { useConferenceInstance, useCoordinator, useTwilioVideo, useLiveRoomSelfDialOut, useCanEnd } from '@containers/Conference';
import { roleIsModeratorLike } from '@/components/Conference.Participants/utils';
import * as Buttons from '@/components/Conference.Buttons';
import { useScreenShareParticipant } from '@/components/Conference.Video/hooks';
import { ConferenceLeave } from '@/components/Modal/Conference.Leave';
import { useModal } from '@/components/Modal/hooks/useModal';
import { useYourParticipant } from './hooks';
import styles from './style/Controls.css';

export function Controls() {
  const {
    room,
    isSharingAudio,
    toggleLocalAudio,
    isSharingVideo,
    toggleLocalVideo,
    isSharingScreen,
    toggleScreenShare,
    audioSource,
    changeAudioSource,
    videoSource,
    changeVideoSource,
  } = useTwilioVideo();
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom>();
  const { leave, updateRecordingStatus } = useCoordinator();
  const { showModal } = useLiveRoomSelfDialOut();
  const you = useYourParticipant();

  const canEnd = useCanEnd();

  const screenShareParticipant = useScreenShareParticipant(room);

  const handleMicrophone = useCallback(() => toggleLocalAudio(_ => showModal()), [toggleLocalAudio, showModal]);

  const handleCamera = useCallback(() => toggleLocalVideo(), [toggleLocalVideo]);

  const handleScreenShare = useCallback(() => toggleScreenShare(), [toggleScreenShare]);

  const disableScreenShareButton = screenShareParticipant && screenShareParticipant !== room.localParticipant;
  const isScreenShareSupported = !!navigator.mediaDevices?.getDisplayMedia && fscreen.fullscreenEnabled;
  const screenDisabled = disableScreenShareButton || !isScreenShareSupported;

  const [toggleLeaveModal, ConferenceLeaveModal] = useModal(ConferenceLeave);

  const handleLeave = useCallback((end: boolean) => {
    leave({
      conferenceIdentifier: instance.conferenceIdentifier,
      end,
      title: instance.conference.title,
      redirect: end
        ? instance.conference.redirect
          ? 'location'
          : 'ended'
        : 'left',
      location: instance.conference.redirect,
      participant: instance.participant,
    });
  }, [instance.conference.title, instance.conference.redirect, instance.conferenceIdentifier, leave, instance.participant]);

  return (
    <Fragment>
      <div className={styles.root}>
        <Buttons.Microphone
          value={isSharingAudio}
          onClick={handleMicrophone}
          onSelectDevice={changeAudioSource}
          selectedDevice={audioSource}
          visible={true}
          disabledByInvisible={!you.isVisible && !isSharingAudio} />
        <Buttons.Camera
          value={isSharingVideo}
          onClick={handleCamera}
          onSelectDevice={changeVideoSource}
          selectedDevice={videoSource}
          disabledByInvisible={!isSharingVideo && !you.isVisible}
          visible={isSharingVideo || instance.features.cameraToggle} />
        <Buttons.Screen
          disabled={screenDisabled}
          value={isSharingScreen}
          onClick={handleScreenShare}
          visible={instance.features.allowScreenshare}
          supported={isScreenShareSupported} />
        <Buttons.Recording
          visible={roleIsModeratorLike(instance.participant.role)}
          value={instance.conference.record}
          onClick={val => updateRecordingStatus({ conferenceIdentifier: instance.conferenceIdentifier, recordingStatus: val })} />
        <Buttons.FSToggle />
        <Buttons.End
          onClick={() => toggleLeaveModal()}
          visible={true} />
      </div>
      <ConferenceLeaveModal
        isHost={you.isHost}
        canEnd={canEnd}
        onConfirm={handleLeave} />
    </Fragment>
  );
}