import { Mail, MapPin } from 'react-feather';
import { Helmet } from 'react-helmet';
import { IndiaAddress, LondonAddress, EUAddress, addressLine1, addressLine2, email } from '@consts/vancery-info';
import { cx } from '@utils';
import styles from './styles.css';

export const ContactUs = () => {
  return (
    <div className={styles.root}>
      <Helmet title='Contact Us - Sentiment' />
      <div className={styles.mapBackground}>
        <div className={styles.mapText}>
          Get in Touch
        </div>
      </div>
      <div className={styles.details}>
        <div className={cx(styles.title, styles.mapTitle)}>
          <MapPin className={styles.icon} /> Where to find us
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.colTitle}>
              Trinity Corporate Headquarters - USA
            </div>
            <div>{addressLine1}</div>
            <div>{addressLine2}</div>
          </div>
          <div className={styles.col}>
            <div className={styles.colTitle}>
              Trinity EU
            </div>
            {EUAddress.map((a, i) => (<div key={i}>{a}</div>))}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.colTitle}>
              Trinity India
            </div>
            {IndiaAddress.map((a, i) => (<div key={i}>{a}</div>))}
          </div>
          <div className={styles.col}>
            <div className={styles.colTitle}>
              Trinity London
            </div>
            {LondonAddress.map((a, i) => (<div key={i}>{a}</div>))}
          </div>
        </div>
        <div className={styles.title}>
          <Mail className={styles.icon} /> Write to us
        </div>
        <div className={styles.email}>
          {email}
        </div>
      </div>
    </div>
  );
};