import { cx } from '@utils';
import styles from './style/SectionHeader.css';

type Props = {
  children?: React.ReactNode;
  className?: string;
  title: string;
};

const SectionHeader = (props: Props) => {
  return (
    <div className={cx(styles.root, props.className)}>
      <div className={styles.wrap}>
        <div className={styles.title}>{props.title}</div>
        {props.children}
      </div>
    </div>
  );
};

const defaultOptions = {
  children: null,
};

SectionHeader.defaultOptions = defaultOptions;

export { SectionHeader };
export default SectionHeader;