import { useAvailableConditionTypes, useQuestionConditionContext } from '@containers/SurveyBuilder.Logic';
import { ConditionTypeDropdown as Dropdown } from '@presentation/SurveyBuilder';
import styles from './style/Builder.Condition.Question.css';

type Props = {
  disabled?: boolean;
};

export const ConditionTypeDropdown = ({ disabled }: Props) => {

  const { item, updateConditionType } = useQuestionConditionContext();

  const conditionTypes = useAvailableConditionTypes();

  return (
    <div className={styles.conditionTypeDropdown}>
      <Dropdown
        disabled={disabled}
        items={conditionTypes}
        onSelect={updateConditionType}
        value={item.data.type} />
    </div>
  );
};