import { io } from 'socket.io-client';
import { LifeCycleSocketEvent, TranscriptSocketEvent, SocketNamespace } from '@enums';
import { createLogger, resolveSocketOptions } from './utils';
import { Transcripts } from './interfaces';

const log = createLogger(SocketNamespace.Transcripts);

const socket = io(`${process.env.BACKEND_WS_URL}/${SocketNamespace.Transcripts}`, resolveSocketOptions());

export function open() {
  socket.on(LifeCycleSocketEvent.Connect, () => log.event(LifeCycleSocketEvent.Connect));
  socket.on(LifeCycleSocketEvent.ConnectError, (...args) => log.event(LifeCycleSocketEvent.ConnectError, args));
  socket.on(LifeCycleSocketEvent.Disconnect, () => log.event(LifeCycleSocketEvent.Disconnect));

  socket.prependAny((event: TranscriptSocketEvent, payload: unknown) => log.event(event, payload));
  socket.open();
}

export function close() {
  socket.close();

  socket.offAny();
  socket.removeAllListeners();
}

export const on = <T extends TranscriptSocketEvent>(event: T, handler: Transcripts.RegisterHandler<T>) => {
  return socket.on<TranscriptSocketEvent | LifeCycleSocketEvent>(event, handler);
};

export const off = <T extends TranscriptSocketEvent>(event: T, handler: Transcripts.RegisterHandler<T>) => {
  return socket.off<TranscriptSocketEvent | LifeCycleSocketEvent>(event, handler);
};

export const subscribe = (transcriptId: number) => {
  socket.emit('subscribe', transcriptId);
};

export const unsubscribe = (transcriptId: number) => {
  socket.emit('unsubscribe', transcriptId);
};