import { useMemo } from 'react';
import { useParams, useLocation, Redirect } from 'react-router-dom';
import type { QueryParamConfig } from 'use-query-params';
import { StringParam, useQueryParams, encodeString, decodeString, withDefault } from 'use-query-params';
import { CallRole } from '@enums';
import { useAppReadyState } from '@containers/AppReadyState/hooks';
import type { Coordinator } from '@containers/Conference/interfaces';
import { pathname, path } from '@consts';
import { BackLink } from '@presentation/Back';
import { ConferenceRating } from '@screens/Conference.Rating';
import styles from './style.css';

type Params = {
  conferenceIdentifier: string;
};

type StateParams = {
  redirect?: string;
  callRole: CallRole;
};

type Reason = Coordinator.Conference.Left['redirect'];

const reasonsToRate: Reason[] = ['ended', 'left', 'location'];
const rolesToRate: CallRole[] = [CallRole.PrimaryRespondent, CallRole.Scheduler];

const ReasonParam: QueryParamConfig<Reason, Reason> = {
  encode: (reason: Reason) => encodeString(reason),
  decode: (reason: string) => decodeString(reason) as Reason,
};

export function ConferenceCompleted() {
  const { conferenceIdentifier } = useParams<Params>();
  const [params] = useQueryParams({
    title: withDefault(StringParam, 'Conference'),
    reason: withDefault(ReasonParam, 'ended'),
  });
  const location = useLocation<StateParams>();
  const appState = useAppReadyState();

  const defaultRedirect = useMemo(() => appState.authenticated ? pathname.HOME : path.Website.ContactUs, [appState.authenticated]);

  const redirectLocation = useMemo(() => location.state?.redirect ?? defaultRedirect, [defaultRedirect, location.state?.redirect]);

  //If there's no role in the location state the user probably refreshed, just take them home
  if (!location?.state?.callRole) {
    return <Redirect to={defaultRedirect} />;
  }

  if (appState.authenticated && reasonsToRate.includes(params.reason) && rolesToRate.includes(location.state.callRole)) {
    return (
      <ConferenceRating conferenceId={+conferenceIdentifier} redirectLocation={redirectLocation} />
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        {params.title &&
          <div className={styles.title}>{params.title} Call</div>
        }
        <div className={styles.description}>{getDisplayReason(params.reason)}</div>
        <div className={styles.back}>
          <BackLink to={redirectLocation} />
        </div>
      </div>
    </div>
  );
}

function getDisplayReason(reason: Reason) {
  switch (reason) {
    case 'ended': return `The conference has been ended by the Host!`;
    case 'left': return `You have left the conference.`;
    case 'removed-waiting-room': return `You were not allowed entry into conference by Host.`;
    case 'removed-room': return `You were removed from the conference by the Host!`;
    case 'lost-connection': return `You lost connection to the server. There might be a network connection issue on your end`;
  }
}