import { memo } from 'react';
import { AudienceType } from '@enums';
import { cx } from '@utils';
import LogoTrinity from 'static/images/invite/logo-trinity.svg?url';
import LogoGHAB from 'static/images/invite/logo-ghab.svg?url';
import LogoSentiment from 'static/images/logo-sentiment-landscape.svg?url';
import styles from './style/GHAB.css';

type Props = {
  audienceType?: AudienceType;
};

const Banner = (props: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.item}>
            <div className={styles.logos}>
              <div className={styles.icon}>
                <Logo audienceType={props.audienceType} />
              </div>
              <div className={cx(styles.divider, { [styles.taller]: props.audienceType !== AudienceType.Payer })} />
              <div className={styles.icon}>
                <img
                  alt={`Trinity Life Sciences`}
                  className={cx(styles.img)}
                  src={LogoTrinity} />
              </div>
            </div>
          </div>
          <div className={cx(styles.item, styles.copy)}>
            <p>{`Sentiment is a healthcare research panel led by `}
              <a
                className={styles.link}
                href="https://www.trinitylifesciences.com"
                target="_blank"
                rel="noreferrer">
                Trinity Life Sciences
              </a>{`,`}
            </p>
            <p>{`a leader in global life sciences commercialization solutions. Sentiment's`}</p>
            <p>{`community of global advisors includes key opinion leaders, hospital`}</p>
            <p>{`and pharmacy directors, access and pricing committee members,`}</p>
            <p>{`health plan executives, and more.`}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const GHAB = memo(Banner);

const Logo = (props: Props) => {
  if (props.audienceType === AudienceType.Payer) {
    return (
      <img
        alt={`Global Advisors`}
        className={styles.ghab}
        src={LogoGHAB} />
    );
  }

  return (
    <div className={styles.sentiment}>
      <div className={styles.logo}>
        <div className={styles.whitespace}>
          <img
            alt={`Sentiment`}
            className={styles.img}
            src={LogoSentiment} />
        </div>
      </div>
    </div>
  );
};