import { useCallback, useContext, useMemo } from 'react';
import { MedicalBackgroundContext, MedicalOnboardingCompletionContext, MedicalBackgroundIdentificationContext, MedicalExpertiseContext } from '@containers/MedicalOnboarding/Context';
import { useOnSubmitIdentification, useIsNonMedicalProfessional } from '@containers/MedicalOnboarding/hooks';
import { ProjectOnboardingContext } from '@containers/ProjectOnboarding/Context';
import { useStepper } from '@utils';
import { MedicalExperienceNavigationContext } from './Context';
import { Step, useMedicalExperienceStepsSelector } from './Steps';

type Props = unknown;

export const MedicalExperienceStepper = (props: Props) => {
  const ctx = {
    background: useContext(MedicalBackgroundContext),
    completion: useContext(MedicalOnboardingCompletionContext),
    expertise: useContext(MedicalExpertiseContext),
    identification: useContext(MedicalBackgroundIdentificationContext),
    project: useContext(ProjectOnboardingContext),
  };

  const isNonMedical = useIsNonMedicalProfessional();

  const state = useMedicalExperienceStepsSelector(steps => {
    if (isNonMedical) {
      return steps.filter(step => [Step.Overview, Step.Role, Step.Identification].includes(step));
    } else {
      return steps;
    }
  });

  const [Screen, actions, index] = useStepper(state.screens);

  const handleSubmitIdentification = useSubmitBackgroundIdentification();

  const hasNextStep = useMemo(() => {
    return index !== state.screens.length - 1;
  }, [
    index,
    state.screens,
  ]);

  const current = useMemo(() => {
    return state.steps[index];
  }, [
    index,
    state.steps,
  ]);

  const navigateNext = useCallback(() => {
    if (hasNextStep) {
      actions.next();
    } else {
      // ctx.completion.mutation.mutateAsync();
      ctx.project.next();
    }
  }, [
    actions,
    // ctx.completion,
    ctx.project,
    hasNextStep,
  ]);

  const next = useCallback(() => {
    switch (current) {
      case Step.Identification:
        return handleSubmitIdentification().then(navigateNext);

      case Step.Specialty:
        return ctx.expertise.mutation.specialty.mutateAsync().then(navigateNext);
      /*
      case Step.Specialties:
      case Step.Subspecialties:
        return ctx.expertise.mutation.specialties.mutateAsync().then(navigateNext);
      */
      case Step.Role:
        return ctx.expertise.mutation.role.mutateAsync().then(navigateNext);

      default:
        navigateNext();
    }
  }, [
    ctx.expertise.mutation,
    current,
    handleSubmitIdentification,
    navigateNext,
  ]);

  const value = {
    back: actions.back,
    goTo: actions.goTo,
    hasNextStep,
    next,
  };

  return (
    <MedicalExperienceNavigationContext.Provider value={value}>
      <Screen />
    </MedicalExperienceNavigationContext.Provider>
  );
};

MedicalExperienceStepper.displayName = 'ProjectOnboarding.MedicalExperience.Stepper';

const useSubmitBackgroundIdentification = () => {
  const ctx = {
    background: useContext(MedicalBackgroundContext),
  };

  const submit = useOnSubmitIdentification(data => {
    return ctx.background.mutation.mutateAsync({
      identification: {
        clinicalPracticeStartedOn: data.clinicalPracticeStartedOn,
        country: data.country,
        deaNumber: data.deaNumber,
        meNumber: data.meNumber,
        npiNumber: data.npiNumber,
        stateLicenseNumber: data.stateLicenseNumber,
        stateOfPractice: data.stateOfPractice,
        isPayer: data.isPayer,
        payerAdminType: data.payerAdminType,
      },
    });
  }, [ctx.background.mutation]);

  return submit;
};