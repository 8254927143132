import { memo } from 'react';
import { InfoTooltip } from '@presentation/InfoTooltip';
import { cx } from '@utils';
import styles from './style/Section.Title.css';

type Props = {
  className?: string;
  helpText?: string;
  title: string;
} & Partial<ChildrenProps>;

export const SettingsTitle = memo(({ children, className, helpText, title }: Props) => {

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.left}>
        {title}
        {helpText &&
          <InfoTooltip
            iconStyle={styles.icon}
            text={helpText} />}
      </div>
      {children}
    </div>
  );
});

export default SettingsTitle;