import { Link } from 'react-router-dom';
import { ExternalLink } from 'react-feather';
import { cx } from '@utils';
import { useCanPreviewSurvey } from '@containers/SurveyBuilder';
import { getLocationFor } from '@utils';
import styles from './style/SurveyPreview.css';

export const SurveyPreview = () => {
  const { canPreview, surveyVersionId } = useCanPreviewSurvey();

  return (
    <div>
      <Link
        className={canPreview ? styles.link : cx(styles.disabledLink, styles.link)}
        to={surveyVersionId ? getLocationFor.project.surveyPreview({ surveyVersionId }) : '#'}
        target='_blank'>
        Preview Survey <ExternalLink className={styles.icon} size={14} />
      </Link>
    </div>
  );
};