import { useCallback, useState, useMemo } from 'react';
import PinIcon from '@mui/icons-material/FiberPin';
import PhoneIcon from '@mui/icons-material/Phone';
import { cx, pin } from '@utils';
import { ConferenceDialInfo } from '@/types';
import AdditionalLabel from './AdditionalPhoneNumbers.Label';
import AdditionalModal from './AdditionalPhoneNumbers.Modal';
import { PhoneNumberDisplay } from './PhoneNumber';
import styles from './style/PhoneInformation.css';

type Props = {
  className?: string;
} & ConferenceDialInfo;

export const PhoneInformation = (props: Props) => {
  if (!props.phoneNumbers?.length) return null;

  return (
    <>
      <div className={cx(props.className)}>
        <div className={styles.header}>
          Your Dial Information
        </div>
        <YourPhoneInformation {...props} />
      </div>
      {props.guestPin &&
        <div className={styles.guest}>
          <div className={styles.header}>
            Guest Dial Information
          </div>
          <GuestPhoneInformation  {...props} />
        </div>
      }
    </>
  );
};

export function YourPhoneInformation(props: Props) {
  const [modal, setModal] = useState<boolean>(false);

  const handleOpenModal = useCallback(() => setModal(true), []);
  const handleCloseModal = useCallback(() => setModal(false), []);

  const primary = props.phoneNumbers[0];
  const additional = useMemo(() => props.phoneNumbers.slice(1), [props.phoneNumbers]);

  const displayPin =
    useMemo(() => pin.format(props.pin), [props.pin]);

  return (
    <>
      <div className={styles.phone}>
        <PhoneIcon />
        <div className={styles.details}>
          <PhoneNumberDisplay
            countryCode={primary.countryCode}
            phone={primary.phone}
            pin={props.pin}
            tollFree={primary.tollFree} />
        </div>
      </div>
      {displayPin &&
        <div className={styles.pin}>
          <PinIcon />
          <div className={styles.details}>
            {displayPin}
          </div>
        </div>
      }
      {!!additional.length && (
        <div className={styles.additional}>
          <AdditionalLabel onClick={handleOpenModal} />
        </div>
      )}
      <AdditionalModal
        pin={displayPin}
        phoneNumbers={additional}
        open={modal}
        onClose={handleCloseModal} />
    </>
  );
}

export function GuestPhoneInformation(props: Props) {
  const [modal, setModal] = useState<boolean>(false);

  const handleOpenModal = useCallback(() => setModal(true), []);
  const handleCloseModal = useCallback(() => setModal(false), []);

  const primary = props.phoneNumbers[0];
  const additional = useMemo(() => props.phoneNumbers.slice(1), [props.phoneNumbers]);

  const displayPin =
    useMemo(() => pin.format(props.guestPin), [props.guestPin]);

  return (
    <>
      <div className={styles.phone}>
        <PhoneIcon />
        <div className={styles.details}>
          <PhoneNumberDisplay
            countryCode={primary.countryCode}
            phone={primary.phone}
            pin={props.guestPin}
            tollFree={primary.tollFree} />
        </div>
      </div>
      <div className={styles.pin}>
        <PinIcon />
        <div className={styles.details}>
          {displayPin}
        </div>
      </div>
      {!!additional.length && (
        <div className={styles.additional}>
          <AdditionalLabel onClick={handleOpenModal} />
        </div>
      )}
      <AdditionalModal
        pin={displayPin}
        phoneNumbers={additional}
        open={modal}
        onClose={handleCloseModal} />
    </>
  );
}