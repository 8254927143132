import { useCallback, useContext } from 'react';
import { WorkspaceFolderTree } from '@/components/Workspace.Folder/Folder.Tree';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import { Button } from '@/components/Button';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { Header } from '@/components/Modal/Header';
import { Modal } from '@/components/Modal/Modal';
import { WorkspaceSelect } from '@/components/Workspace/WorkspaceSelect';
import { useMoveObjectContext } from '@/containers/WorkspaceObject/hooks/useMoveObjectContext';
import { MoveObjectWorkspaceContext } from '@/containers/WorkspaceObject/Context';
import { useLock } from '@/utils';
import styles from './style/MoveObjectModal.css';

type Props = {
  onClose: () => unknown;
  open: boolean;
};

export const MoveObjectModal = ({ onClose, open }: Props) => {
  const [busy, lock] = useLock();
  const {
    loadingWorkspaces,
    onSelectWorkspace,
    selectedWorkspace,
    workspaces,
  } = useContext(MoveObjectWorkspaceContext);
  const {
    currentPath,
    data,
    object,
    onSave,
    loadingTree,
    parentObjectId,
    onSelectParentObject,
  } = useMoveObjectContext();

  const handleSave = useCallback(() => {
    return onSave()
      .then(onClose);
  }, [
    onClose,
    onSave,
  ]);

  const Footer = useCallback(() => {
    const canSave = !!parentObjectId &&
      parentObjectId !== object?.parentObjectId;
    return (
      <ButtonSet>
        <Button.Destructive
          className={styles.btn}
          onClick={onClose}
          variant="brick">
          Cancel
        </Button.Destructive>
        <Button.Primary
          className={styles.btn}
          disabled={!canSave}
          onClick={lock(handleSave)}
          variant="brick">
          Move
        </Button.Primary>
      </ButtonSet>
    );
  }, [
    handleSave,
    lock,
    object?.parentObjectId,
    onClose,
    parentObjectId,
  ]);

  const title = `Move ${object?.name}`;

  if (loadingTree || loadingWorkspaces) {
    return null;
  }

  return (
    <Modal
      className={styles.root}
      onClose={onClose}
      open={open}>
      <Header
        className={styles.header}
        text={title} />
      <div className={styles.item}>
        <div className={styles.label}>Current Location:</div>
        <div className={styles.path}>{currentPath[currentPath.length - 1]}</div>
      </div>
      <div className={styles.item}>
        <div className={styles.label}>Destination:</div>
        <WorkspaceSelect
          className={styles.select}
          value={selectedWorkspace}
          onChange={onSelectWorkspace}
          options={workspaces} />
      </div>
      <div className={styles.body}>
        {loadingTree || loadingWorkspaces && <ActivityIndicator show />}
        {!loadingTree && !loadingWorkspaces &&
          <WorkspaceFolderTree
            data={data}
            onNodeSelection={onSelectParentObject} />}
      </div>
      <Footer />
    </Modal>
  );
};

export default MoveObjectModal;