import { Link } from 'react-router-dom';
import styles from './style.css';

const FormSubmitted = props => {
  return (
    <div>
      <div className={styles.h5}>Thank you for your message</div>
      <div className={styles.submitted}>A member of our team will be in touch soon!</div>
    </div>
  );
};

export { FormSubmitted };
export default FormSubmitted;