import { useCallback, useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import { ButtonActivityIndicator, ButtonOutlined } from '@presentation';
import * as $api from '@api/projects.quant-analysis';
import { QuantAnalysisContext } from '@/containers/Project.QuantAnalysis';
import { useProjectState } from '@/containers/GroupProject/hooks';
import { useInvalidateProjectQuantAnalysisQuery } from '@/utils/api';
import { Body } from '@/components/Modal/Body';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import { useModal } from '@/components/Modal';

type Props = {
  projectId: number;
  quantAnalysisId: number;
  analysisItemId: number;
} &
  Pick<ModalProps,
    | 'onClose'
    | 'open'>;

export const DeleteQuantAnalysisItemModal = (props: Props) => {

  const quantAnalysis = useContext(QuantAnalysisContext);
  const state = useProjectState();

  const invalidateQuantAnalysis = useInvalidateProjectQuantAnalysisQuery();

  const deleteMutation = useMutation({
    mutationFn: () => {
      return $api.deleteQuantAnalysisItem({
        projectId: state.project.id,
        quantAnalysisId: quantAnalysis.id,
        itemId: props.analysisItemId,
      });
    },
    onSuccess: () => {
      invalidateQuantAnalysis({
        projectId: state.project.id,
        quantAnalysisId: quantAnalysis.id,
      });
      props.onClose();
    },
  });

  const handleDelete = useCallback(() => {
    deleteMutation.mutateAsync();
  }, [deleteMutation]);

  return (
    <Modal
      disableEscapeClose={deleteMutation.isLoading}
      disableOverlayClick={deleteMutation.isLoading}
      hideCloseIcon
      open={props.open}
      onClose={props.onClose}>
      <Body>
        <div>
          Are you sure you want to delete this analysis item?
        </div>
      </Body>
      <ButtonSet>
        <ButtonOutlined
          borderWidth="thin"
          disabled={deleteMutation.isLoading}
          color="silver"
          onClick={props.onClose}>
          Cancel
        </ButtonOutlined>
        <ButtonActivityIndicator
          color="destructive"
          indicatorSize={12}
          loading={deleteMutation.isLoading}
          onClick={handleDelete}>
          Confirm
        </ButtonActivityIndicator>
      </ButtonSet>
    </Modal>
  );
};

export const useDeleteQuantAnalysisItemModal = () => useModal(DeleteQuantAnalysisItemModal);