import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { LeadStatus } from '@enums';
import { Tooltip } from '@presentation/Tooltip';
import { cx, getLocationFor } from '@utils';
import { MaybeLink } from '@/components/MaybeLink';
import { UserAvatar } from '@/components/UserAvatar';
import type { TableCellItem } from './interfaces';
import styles from './style/Search.Members.css';

type Props = TableCellItem;

export const Cell = (props: Props) => {

  const to = getLocationFor.user.profile({
    slug: String(props.row.original.user.id),
  });

  return (
    <MaybeLink to={to}>
      <div className={styles.name}>
        <div className={styles.avatar}>
          <UserAvatar
            pictureUrl={props.row.original.user.pictureUrl}
            size={25} />
        </div>
        <div className={styles.link}>
          {props.row.original.user.fullname}
        </div>
        {showWarning(props.row.original.leadStatusId) &&
          <Tooltip title={copy[props.row.original.leadStatusId as keyof typeof copy]}>
            <div className={cx(styles.dnc, {
              [styles.caution]: caution(props.row.original.leadStatusId),
              [styles.warn]: props.row.original.leadStatusId === LeadStatus.Inactive,
            })}>
              <WarningAmberIcon />
            </div>
          </Tooltip>}
      </div>
    </MaybeLink>
  );
};

Cell.displayName = 'Column.Name.Cell';

function showWarning(statusId: LeadStatus) {
  return [
    LeadStatus.DoNotContact,
    LeadStatus.DoNotContactQual,
    LeadStatus.DoNotContactQuant,
    LeadStatus.DoNotContactSpecific,
    LeadStatus.Inactive,
  ].includes(statusId);
}

function caution(statusId: LeadStatus) {
  return [
    LeadStatus.DoNotContact,
    LeadStatus.DoNotContactQual,
    LeadStatus.DoNotContactQuant,
    LeadStatus.DoNotContactSpecific,
  ].includes(statusId);
}

const copy = {
  [LeadStatus.Inactive]: `This respondent has been marked as Inactive.`,
  [LeadStatus.DoNotContact]: `This respondent has requested not to be contacted.`,
  [LeadStatus.DoNotContactQual]: `This respondent has requested not to be contacted (DNR Qual).`,
  [LeadStatus.DoNotContactQuant]: `This respondent has requested not to be contacted (DNR Quant).`,
  [LeadStatus.DoNotContactSpecific]: `This respondent has requested not to be contacted (DNR Specific).`,
};