import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { SkeletonBreadcrumbs } from '@presentation/Breadcrumbs';
import { useHasComplianceRole } from '@containers/User/hooks';
import { ProjectStateLoadingContext } from '@/containers/GroupProject/Context';
import { WorkspaceBreadcrumbsContext } from '@containers/WorkspaceObject';
import { HomeBreadcrumb } from '@/components/Workspace/WorkspaceBreadcrumb';
import { WorkspaceObjectBreadcrumbs } from '@/components/Workspace/ObjectBreadcrumbs';
import styles from './style/ProjectHeader.css';

type Props = unknown;

export const Breadcrumbs = (props: Props) => {
  const isLoading = useContext(ProjectStateLoadingContext);

  return isLoading
    ? <SkeletonBreadcrumbs />
    : <InteractiveBreadcrumbs />;
};

Breadcrumbs.displayName = 'Project.Header.Breadcrumbs';

const InteractiveBreadcrumbs = (props: Props) => {
  const breadcrumbs = useContext(WorkspaceBreadcrumbsContext);
  const groupFeatures = useSelector((state: Store.State) => state.group.features);
  const hasComplianceRole = useHasComplianceRole();

  const isWorkspaceUser = groupFeatures.workspaces && !hasComplianceRole;

  return (
    <div className={styles.breadcrumbs}>
      {isWorkspaceUser
        ? <WorkspaceObjectBreadcrumbs items={breadcrumbs} />
        : <HomeBreadcrumb />
      }
    </div>
  );
};

InteractiveBreadcrumbs.displayName = 'Project.Header.Breadcrumbs.Interactive';