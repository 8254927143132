import { useEffect } from 'react';
import { ConferenceSocketEvent } from '@/enums';
import { useLogDebugData } from './hooks/useLogDebugData';
import { useSocket } from './hooks/useSocket';

export const LogContainer = ({ children }: ChildrenProps) => {
  const socket = useSocket();
  const logDebugData = useLogDebugData();

  useEffect(() => {
    const log = () => logDebugData();
    socket.raw.on(ConferenceSocketEvent.LogDebug, log);
    return () => {
      socket.raw.off(ConferenceSocketEvent.LogDebug, log);
    };
  });

  return <>{children}</>;
};