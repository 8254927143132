import { memo } from 'react';
import styled from '@emotion/styled';

type Props = {
  className?: string;
  count: number;
  onClick?: () => void;
};

export const HistoryChatCount = memo(({ className, count, onClick }: Props) => {
  return (
    <Root className={className} onClick={onClick}>
      <Wrap>
        <Count>{count}</Count>
      </Wrap>
    </Root>
  );
});

const Root = styled.button`
  width: 38px;
  height: 28px;
`;

const Wrap = styled.div`
  box-sizing: border-box;
  padding: 7px;
  background-color: ${({ theme }) => theme.palette.btnPrimary.bg1};
  border-radius: 15px;
  border-bottom-right-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.palette.gray.light1};
  }
`;

const Count = styled.span`
  color: ${({ theme }) => theme.palette.text.primary};
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: 14px;
`;