import { SurveyActionType, SurveyQuestionType } from '@enums/Survey';
import { SurveyLogic } from '@/types/survey';

export const LogicActions = [
  SurveyActionType.Flag,
  SurveyActionType.Disqualification,
  SurveyActionType.IncludeSection,
  SurveyActionType.SkipToEnd,
  SurveyActionType.SkipToQuestion,
  SurveyActionType.SkipToSection,
  SurveyActionType.ExcludeQuestionChoice,
  SurveyActionType.DisplayQuestionChoice,
];

export function generateDefaultAction(type: SurveyActionType): SurveyLogic.Action {

  switch (type) {

    case SurveyActionType.SkipToSection:
    case SurveyActionType.IncludeSection:
      return {
        type,
        section: {
          identifier: null,
        },
      };

    case SurveyActionType.SkipToQuestion:
      return {
        type,
        question: {
          identifier: null,
        },
      };

    case SurveyActionType.DisplayQuestionChoice:
    case SurveyActionType.ExcludeQuestionChoice:
      return {
        type,
        question: {
          identifier: null,
        },
        option: {
          identifier: null,
        },
        row: {
          identifier: null,
        },
      };

    case SurveyActionType.None:
    case SurveyActionType.Disqualification:
    case SurveyActionType.Flag:
    case SurveyActionType.SkipToEnd:
    case SurveyActionType.QuotaOverLimit:
      return {
        type,
      };

    default:
      throw new UnreachableCaseError(type);

  }

}

export type ActionWithSection =
  SurveyActionType.SkipToSection |
  SurveyActionType.IncludeSection;

export type ActionWithQuestion =
  SurveyActionType.SkipToQuestion;

export const SectionActions = [
  SurveyActionType.SkipToSection,
  SurveyActionType.IncludeSection,
];

export const QuestionActions = [
  SurveyActionType.SkipToQuestion,
];

export const ActionExcludeQuestionChoiceQuestionTypes = [
  SurveyQuestionType.MultipleChoice,
  SurveyQuestionType.Multiselect,
  SurveyQuestionType.MatrixGrid,
  SurveyQuestionType.Sliders,
  SurveyQuestionType.Ranking,
  SurveyQuestionType.MatrixMultiselect,
  SurveyQuestionType.NumberInputTable,
  SurveyQuestionType.ExclusiveOptions,
  SurveyQuestionType.Dropdown,
  SurveyQuestionType.MatrixRange,
];