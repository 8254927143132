import DropDown from '@/components/DropDown';
import styles from './style.css';

const defaultOptions = {
  most_relevant: {
    'desc': 'Most Relevant'
  },
  hourly_rate: {
    asc: 'Price Lowest',
    desc: 'Price Highest',
  },
}

const SortBy = ({
  value,
  direction,
  onChange,
  showRate,
}) => {
  const options = { ...defaultOptions };

  const getSelectedItem = () => {
    return options[value] && options[value][direction]
           ? options[value][direction]
           : '';
  }

  const getItemValue = item => item.label;

  const onSelect = item => {
    if (onChange) {
      onChange(item);
    }
  }

  const filterOptions = filter => {
    if (!showRate && filter === 'hourly_rate') return false;
    return true;
  }

  const getItems = () => {
    return Object.keys(options)
    .filter(filterOptions)
    .reduce((acc, curr) => {
      const items = Object.keys(options[curr]).map(i => ({
        id: `${curr}-${i}`,
        sort: curr,
        label: options[curr][i],
        dir: i
      }));
                         
      return acc.concat(items);
    }, []);
  }

  if (!showRate) return null;

  return (
    <div className={styles.root}>
      <div className={styles.label}>Sort By</div>
      <div className={styles.dropdown}>
        <DropDown
          items={getItems()}
          getItemValue={getItemValue}
          onSelect={onSelect}
          text={getSelectedItem()} />
      </div>
    </div>
  )
}

export default SortBy;