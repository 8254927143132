import { useMemo } from 'react';
import type { Row } from 'react-table';
import useMediaQuery from '@mui/material/useMediaQuery';
import { isAfter } from 'date-fns';
import { useIsInternalUser } from '@/containers/Store';
import type { ProjectsTableItem } from '../interfaces';
import { Cells } from '../ProjectsTableCells';

function sortProgress(a: Row<ProjectsTableItem>, b: Row<ProjectsTableItem>) {
  return a.original.metrics.progress.percentage - b.original.metrics.progress.percentage;
}

function sortCreatedOn(a: Row<ProjectsTableItem>, b: Row<ProjectsTableItem>) {
  return isAfter(a.original.createdOn, b.original.createdOn)
    ? 1
    : -1;
}

export const useProjectsTableColumns = () => {
  const isDesktop = useMediaQuery('(min-width:900px)');
  const isInternalUser = useIsInternalUser();

  return useMemo(() => [
    {
      accessor: 'name',
      id: 'name',
      Header: 'Name',
      Cell: Cells.Name,
      minWidth: 200,
      // width: 240,
    },
    {
      accessor: 'metrics.progress.percentage',
      id: 'progress',
      Header: 'Progress',
      Cell: Cells.Progress,
      width: 105,
      sortType: sortProgress,
    },
    isInternalUser && isDesktop ? {
      accessor: 'owner',
      id: 'owner',
      Header: 'Owner',
      Cell: Cells.Owner,
      width: 80,
    } : undefined,
    isDesktop ? {
      accessor: 'createdOn',
      id: 'createdOn',
      Header: 'Created On',
      Cell: Cells.CreatedOn,
      minWidth: 80,
      width: 80,
      sortType: sortCreatedOn,
    } : undefined,
    {
      id: 'actions',
      Cell: Cells.Actions,
      minWidth: 50,
      width: 50,
      disableSortBy: true,
    },
  ].filter(Boolean), [
    isInternalUser,
    isDesktop,
  ]);
};