import { useContext, useMemo } from 'react';
import { useTranscriptEntities, useTranscriptionComments, useTranscriptionHighlights, useTranscriptionTaggedMoments } from '@utils/api';
import { useTranscriptTagsContext } from '@containers/Transcript.Tagging/hooks';
import { TranscriptCommentsStateContext } from '@containers/Transcript.Commenting/Context';
import { useIsInternalUser } from '@containers/Store';
import { TranscriptHighlightActionsContainer } from './Highlights.Actions';
import { TranscriptMediaContainer } from './Media.Container';
import {
  TranscriptEntitiesContext,
  TranscriptHighlightsContext,
  TranscriptHydrationContext,
  TranscriptIdContext,
  TranscriptDocumentContext,
} from './context';

/* Note: These rely on the transcriptId being set from the main query. */

type Props = {
  audioOnly?: boolean;
} & ChildrenProps;

export const TranscriptDataContainer = ({ audioOnly, children }: Props) => {

  const transcriptId = useContext(TranscriptIdContext);
  const transcript = useContext(TranscriptDocumentContext);
  const isInternalUser = useIsInternalUser();

  const [_comments, dispatchComments] = useContext(TranscriptCommentsStateContext);
  const [_tags, dispatchTags] = useTranscriptTagsContext();

  const fetchAdditionalData = useMemo(() => {
    return isInternalUser && !!transcriptId;
  }, [isInternalUser, transcriptId]);

  const highlights = useTranscriptionHighlights({
    transcriptId,
  }, {
    enabled: fetchAdditionalData,
    placeholderData: [],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 1,
  });

  const comments = useTranscriptionComments({
    transcriptId,
  }, {
    enabled: fetchAdditionalData,
    onSuccess: res => {
      dispatchComments({
        type: 'comments-fetched',
        items: res,
      });
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 1,
  });

  const taggedMoments = useTranscriptionTaggedMoments({
    transcriptId,
    includeContent: false,
  }, {
    enabled: fetchAdditionalData,
    onSuccess: res => {
      dispatchTags({
        type: 'tags-fetched',
        items: res,
      });
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 1,
  });

  const entities = useTranscriptEntities({
    transcriptId,
  }, {
    enabled: fetchAdditionalData,
    placeholderData: {
      entities: [],
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 1,
  });

  const hydration = useMemo(() => {
    const transcriptLoaded = !!transcript;

    const state = {
      comments: comments.isSuccess || !fetchAdditionalData,
      entities: entities.isSuccess || !fetchAdditionalData,
      highlights: highlights.isSuccess || !fetchAdditionalData,
      tags: taggedMoments.isSuccess || !fetchAdditionalData,
      transcript: transcriptLoaded,
    };

    return {
      all: Object.values(state).every(Boolean),
      state,
    };

  }, [
    comments.isSuccess,
    entities.isSuccess,
    highlights.isSuccess,
    taggedMoments.isSuccess,
    fetchAdditionalData,
    transcript,
  ]);

  const entitiesCtx = useMemo(() => {
    return {
      items: entities.data?.entities || [],
      query: entities,
    };
  }, [entities]);

  return (
    <TranscriptHydrationContext.Provider value={hydration}>
      <TranscriptHighlightActionsContainer transcriptId={transcriptId}>
        <TranscriptHighlightsContext.Provider value={highlights.data}>
          <TranscriptEntitiesContext.Provider value={entitiesCtx}>
            <TranscriptMediaContainer audioOnly={audioOnly}>
              {children}
            </TranscriptMediaContainer>
          </TranscriptEntitiesContext.Provider>
        </TranscriptHighlightsContext.Provider>
      </TranscriptHighlightActionsContainer>
    </TranscriptHydrationContext.Provider>
  );
};