import { useCallback } from 'react';
import * as Branding from '@api/interfaces/groups.branding';
import { ColorPaletteType } from '@enums';
import { CreatePalette, useCreateChartPaletteMutation as useCreateChartPaletteMutationAPI } from '@utils/api/branding';
import { useQueryDataAccessor } from './useQueryDataAccessor';

export const useCreateChartPaletteMutation = (options: CreatePalette.Options = {}) => {
  const helpers = useMutationHelpers();

  const mutation = useCreateChartPaletteMutationAPI({
    ...options,
    onSuccess: (...args) => {
      helpers.onSuccess(...args);
      options.onSuccess?.(...args);
    },
  });

  return mutation;
};

const useMutationHelpers = () => {
  const accessor = useQueryDataAccessor();

  const onSuccess = useCallback((res: Branding.Palettes.CreatePalette.Response, variables, context) => {
    accessor.setQueryData(data => {
      const palette = {
        brand: data.theming.palettes.filter(x => x.typeId === ColorPaletteType.Brand),
        preset: data.theming.palettes.filter(x => x.typeId === ColorPaletteType.Preset),
      };

      return {
        ...data,
        theming: {
          palettes: [...palette.brand, res.palette, ...palette.preset],
          themes: data.theming.themes,
        },
      };
    });
  }, [accessor]);

  return {
    onSuccess,
  };
};