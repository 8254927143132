import { useContext, useMemo } from 'react';
import { MedicalExperienceContext } from '@containers/ProjectOnboarding.MedicalExperience/Context';
import { ProjectInviteValidationContext, ProjectResearchConsentContext, ProjectScreensContext } from './Context';
import { Step, useOnboardingStepsSelector, useStepperConfig } from './Steps';

type Props = {
  children: React.ReactNode;
  stepsFilter?: (step: Step) => boolean;
} & IProjectId;

export const ProjectOnboardingScreensContainer = (props: Props) => {
  const invite = useContext(ProjectInviteValidationContext);
  const medical = useContext(MedicalExperienceContext);
  const research = useContext(ProjectResearchConsentContext);

  const selectSteps = useOnboardingStepsSelector(steps => {
    return steps.filter(step => {
      if (step === Step.MedicalExperience) {
        return (step === Step.MedicalExperience
          ? medical.enabled
          : step) && (!props.stepsFilter || props.stepsFilter(step));
      }

      if (step === Step.MarketPrivacyPolicy) {
        return research.enabled;
      }

      return step && (!props.stepsFilter || props.stepsFilter(step));
    });
  });

  const ready = useMemo(() => {
    if (!invite.data?.valid) return false;

    return research.ready;
  }, [
    invite.data?.valid,
    research.ready,
  ]);

  const state = useStepperConfig(props.projectId, selectSteps, {
    enabled: ready,
  });

  const value = {
    screens: state.screens,
    steps: state.steps,
  };

  return (
    <ProjectScreensContext.Provider value={value}>
      {props.children}
    </ProjectScreensContext.Provider>
  );
};

ProjectOnboardingScreensContainer.displayName = 'Project.Onboarding.Screens.Container';