import { useCallback } from 'react';
import Select from 'react-select';
import type { ActionMeta, GetOptionLabel, GetOptionValue, GroupBase, InputActionMeta, MultiValue, StylesConfig } from 'react-select';

type Props = {
  className?: string;
  inputValue: string;
  menuPortalTarget?: HTMLElement;
  noOptionsMessage: (obj: { inputValue: string }) => string | null;
  onChange: (value: MultiValue<GroupTagFilterItem>, meta: ActionMeta<GroupTagFilterItem>) => void;
  onInputChange: (newValue: string, actionMeta: InputActionMeta) => void;
  options: GroupTagFilterItem[];
  selected: GroupTagFilterItem[];
};

export const GroupTagsMultiSelect = ({
  className,
  inputValue,
  menuPortalTarget,
  noOptionsMessage,
  options,
  onChange, onInputChange,
  selected,
}: Props) => {

  const getOptionValue: GetOptionValue<GroupTagFilterItem> = useCallback(o => `${o.id}`, []);
  const getOptionLabel: GetOptionLabel<GroupTagFilterItem> = useCallback(o => o.name, []);

  return (
    <Select<GroupTagFilterItem, true>
      className={className}
      components={{
        DropdownIndicator: null,
      }}
      // @ts-ignore
      styles={{ menu: base => ({ ...base, zIndex: 10 }) }}
      value={selected}
      inputValue={inputValue}
      isMulti
      options={options}
      placeholder={`Search Global Tags...`}
      noOptionsMessage={noOptionsMessage}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      onChange={onChange}
      onInputChange={onInputChange} />
  );
};

export type GroupTagFilterItem = {
  id: number;
  name: string;
};