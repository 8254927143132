import { useMemo } from 'react';
import { useVideoPostIdentifier, useVideoPostUploaderContext } from '@containers/PostCreation/hooks/usePostCreationContext';
import { PostingState } from '@containers/PostCreation/interfaces/post.video.uploader';

export const useVideoPostSubmitState = () => {
  const [state] = useVideoPostUploaderContext();
  const pid = useVideoPostIdentifier();

  const status = useMemo(() => {
    return state.media[pid]?.status;
  }, [
    pid,
    state.media,
  ]);

  const submitState = useMemo(() => {
    return {
      initializing: status === PostingState.Initializing,
      submitting: Number.isInteger(status),
      uploading: status === PostingState.Uploading,
    };
  }, [status]);

  return submitState;
};