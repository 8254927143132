import { useMemo } from 'react';
import { MedicalOnboardingBackgroundContainer } from '@containers/MedicalOnboarding/MedicalOnboarding.Background.Container';
import { MedicalOnboardingCompletionContainer } from '@containers/MedicalOnboarding/MedicalOnboarding.Completion.Container';
import { MedicalOnboardingExpertiseContainer } from '@containers/MedicalOnboarding/MedicalOnboarding.Expertise.Container';
import { useMedicalOnboardingQueriesState } from '@containers/MedicalOnboarding/hooks';
import { useSelectUser } from '@containers/Store';
import { MedicalExperienceContext } from './Context';
import { useMedicalExperienceEnabled } from './Steps';

type Props = {
  children: React.ReactNode;
};

export const MedicalExperienceContainer = (props: Props) => {

  const user = useSelectUser();

  return (
    <MedicalOnboardingCompletionContainer userId={user.id}>
      <MedicalOnboardingBackgroundContainer userId={user.id}>
        <MedicalOnboardingExpertiseContainer userId={user.id}>
          <MedicalExperienceStateContainer>
            {props.children}
          </MedicalExperienceStateContainer>
        </MedicalOnboardingExpertiseContainer>
      </MedicalOnboardingBackgroundContainer>
    </MedicalOnboardingCompletionContainer>
  );
};

MedicalExperienceContainer.displayName = 'ProjectOnboarding.MedicalExperience.Container';

const MedicalExperienceStateContainer = (props: Props) => {
  const user = useSelectUser();
  const { background, expertise } = useMedicalOnboardingQueriesState(user.id);

  const state = useMemo(() => ({
    clinicalPracticeStartedOn: background?.data?.identification?.clinicalPracticeStartedOn,
    country: background?.data?.identification?.country,
    meNumber: background?.data?.identification?.meNumber,
    npiNumber: background?.data?.identification?.npiNumber,
    role: expertise?.data?.professionalRole,
    specialty: expertise?.data?.specialty,
    specialties: expertise?.data?.expertise?.industries?.length,
    stateLicenseNumber: background?.data?.identification?.stateLicenseNumber,
    subspecialties: expertise?.data?.expertise?.subindustries?.length,
    isPayer: background?.data?.identification?.isPayer,
    payerAdminType: background?.data?.identification?.payerAdminType,
  }), [
    background,
    expertise,
  ]);

  const enabled = useMedicalExperienceEnabled(state);

  const value = {
    enabled,
    state,
  };

  return (
    <MedicalExperienceContext.Provider value={value}>
      {props.children}
    </MedicalExperienceContext.Provider>
  );
};

MedicalExperienceStateContainer.displayName = 'ProjectOnboarding.MedicalExperience.StateContainer';