import { useEffect, Fragment } from 'react';
import { useAppReadyState, useHydrateEffect } from '@containers/AppReadyState';
import { useFetchApp, useInitSentry, useValidSessionChecker } from '@containers/AppStateEffect/hooks';

type Props = {
  children: React.ReactNode;
};

export const AppStateEffectContainer = (props: Props) => {
  const app = useAppReadyState();
  const fetchApp = useFetchApp();

  const initSentry = useInitSentry();

  useHydrateEffect(() => {
    initSentry();
  });

  useEffect(() => {
    if (app.authenticated) {
      fetchApp();
    }
  }, [
    app.authenticated,
    fetchApp,
  ]);

  useValidSessionChecker({
    enabled: app.initialized && app.authenticated,
  });

  return (
    <Fragment>
      {props.children}
    </Fragment>
  );
};

AppStateEffectContainer.displayName = 'AppStateEffectContainer';