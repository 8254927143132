import { useCallback, useContext } from 'react';
import type { RouteProps } from 'react-router-dom';
import { cx, qs } from '@utils';
import { TranscriptHasMediaContext } from '@/containers/Transcript/context';
import type { TabViewRenderParams } from '@/components/TabView';
import { TabView } from '@/components/TabView';
import { useTabViewQueryState } from '@/components/TabView/hooks';
import type { TabRoute, TabKey } from './interfaces';
import styles from './style/TranscriptTabs.css';

type Props = {
  routes: TabRoute[];
};

export const TranscriptTabs = ({ routes }: Props) => {

  const [{ index }, setTab] = useTabViewQueryState<TabKey>({
    routes: routes.map(x => x.key),
    formatPath,
  });

  const renderTabView = useCallback((x: TabViewRenderParams<TabRoute>) => <x.route.component />, []);

  const hasMedia = useContext(TranscriptHasMediaContext);

  const classNames = {
    bar: cx(styles.bar, {
      [styles.noMedia]: !hasMedia,
    }),
    tab: styles.tab,
    tabs: styles.tabs,
  };

  return (
    <TabView
      classes={classNames}
      index={index}
      onIndexChange={setTab}
      routes={routes}
      renderTabView={renderTabView} />
  );
};

function formatPath(tab: TabKey, location: RouteProps['location']) {
  const search = qs.append(location.search, { tab });
  return `${location.pathname}${search}`;
}