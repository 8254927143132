import type { ChangeEvent } from 'react';
import { forwardRef, memo, useCallback, useContext, useMemo, useState } from 'react';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { usePopper } from 'react-popper';
import { useHistory } from 'react-router-dom';
import { cx, getLocationFor } from '@/utils';
import * as api from '@api';
import type { WorkspaceFolders } from '@api/interfaces/workspace.folder';
import { WhitelabelingPaletteContext } from '@containers/Branding/Context';
import { useQueryDownloader } from '@containers/QueryDownloader';
import { UploadFilesContext } from '@/containers/WorkspaceFileUpload/Context';
import { RateLimitingContext } from '@containers/RateLimiting/Context';
import { Button } from '@/components/Button';
import { FilesTableContext, FilesTableMetaContext } from '@/components/Workspace.FilesTable/context';
import { useZIndexModifier, PopperMenu, PopperMenuItem } from '@/components/Popper';
import { Portal } from '@/components/Portal';
import { useCreateFolderInsideParentModal } from '@/components/Workspace.Folder/NewFolderModal';
import Toast from '@/components/Toast';
import { WorkspaceObjectType } from '@/enums';
import { useCanUpload } from '@/containers/WorkspaceFileUpload/hooks';
import { useDownloadAllModal } from './DownloadAll.Modal';
import { usePublishModal } from './PublishModal';
import { useApplyTagsModal } from './ApplyTags.Modal';
import { useSelectedObjects, useUnpublishedTranscripts, useSelectObjectsEnabled } from './hooks';
import styles from './style/Files.Table.Actions.css';

type Props = {
  className?: string;
};

const AnchorButton = memo(forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const wl = useContext(WhitelabelingPaletteContext);
  const { query } = useContext(RateLimitingContext);

  if (wl?.theme) {
    return (
      <Button
        disabled={query.data?.documents?.exceeded}
        ref={ref}
        color="transparent"
        className={props.className}
        style={{ backgroundColor: wl.palette.primary.main, color: wl.palette.primary.text }}
        variant="brick">
        Actions
      </Button>
    );
  }

  return (
    <Button
      disabled={query.data?.documents?.exceeded}
      ref={ref}
      color="primary"
      className={props.className}
      variant="brick">
      Actions
    </Button>
  );
}));

type ButtonProps = {
  className?: string;
};

type MenuItem = {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  onClick: () => unknown;
};

export const FilesActionsButton = ({ className }: ButtonProps) => {

  const [open, setOpen] = useState(false);

  const history = useHistory();
  const baseCanUpload = useCanUpload();
  const onUpload = useContext(UploadFilesContext);
  const [toggleNewFolderModal, NewFolderModal] = useCreateFolderInsideParentModal();
  const [toggleDownloadAllModal, DownloadAllModal] = useDownloadAllModal();
  const selectObjectsEnabled = useSelectObjectsEnabled();
  const filesTableContext = useContext(FilesTableContext);
  const unpublishedTranscripts = useUnpublishedTranscripts();
  const selectedObjects = useSelectedObjects();
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);
  const meta = useContext(FilesTableMetaContext);
  const { query } = useContext(RateLimitingContext);
  const [togglePublishModal, PublishModal] = usePublishModal();
  const [toggleTagsModal, TagsModal] = useApplyTagsModal();
  const canDownload = useMemo(() => !!filesTableContext.parentObject, [filesTableContext.parentObject]);
  const canUpload = useMemo(() => baseCanUpload && !selectedObjects?.length, [baseCanUpload, selectedObjects?.length]);

  const zIndexModifier = useZIndexModifier({ zIndex: 8 });

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      zIndexModifier,
    ],
    placement: 'bottom-start',
  });

  const maxFileUploads = (query.data?.documents?.limit || 0) - (query.data?.documents?.total || 0);

  const displayMaxFileUploadsExceeded = useCallback(() => {
    Toast.alert({
      title: `Number of files selected exceeds the your current upload limit`,
      body: `The total amount of file uploads remaining is ${maxFileUploads}.`,
    });
  }, [maxFileUploads]);

  const handleBlur = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleUploadClick = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files.length <= maxFileUploads) {
      onUpload(Array.from(e.target.files));
    } else {
      displayMaxFileUploadsExceeded();
    }
    setOpen(false);
  }, [
    displayMaxFileUploadsExceeded,
    maxFileUploads,
    onUpload,
  ]);

  const handleImportTranscripts = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files.length <= maxFileUploads) {
      onUpload(Array.from(e.target.files), true);
    } else {
      displayMaxFileUploadsExceeded();
    }
    setOpen(false);
  }, [
    displayMaxFileUploadsExceeded,
    maxFileUploads,
    onUpload,
  ]);

  const uploadFileItem: MenuItem = useMemo(() => {
    const buttonText = `Upload File(s)`;
    return {
      className: !canUpload ? null : styles.noPadding,
      disabled: !canUpload,
      children: !canUpload ? buttonText : (
        <label className={styles.upload}>
          <input
            className={styles.fileInput}
            multiple={true}
            onChange={handleUploadClick}
            type="file" />
          {buttonText}
        </label>
      ),
      onClick: () => { },
    };
  }, [canUpload, handleUploadClick]);

  const handleAddFolderClick = useCallback(() => {
    toggleNewFolderModal();
    setOpen(false);
  }, [toggleNewFolderModal]);

  const addFolderItem: MenuItem = useMemo(() => {
    return {
      children: 'Add Folder',
      disabled: !canUpload,
      onClick: handleAddFolderClick,
    };
  }, [canUpload, handleAddFolderClick]);

  const { download } = useQueryDownloader({
    queryKey: [
      `get:workspaces/objects`, {
        objectId: filesTableContext.parentObject?.id,
        workspaceId: filesTableContext.parentObject?.workspaceId,
      },
    ],
    queryFn: () => {
      return api.workspaces.object.downloadObjectRecursive({
        objectId: filesTableContext.parentObject?.id,
        workspaceId: filesTableContext.parentObject?.workspaceId,
        objectIds: selectedObjects?.map(o => o.object.id),
      });
    },
    downloaderOptions: {
      onResponse: res => ({
        type: 'websocket',
        value: res.websocketKey,
      }),
    },
  });

  const handleDownloadAll = useCallback(() => {
    const size = meta?.size || 0;

    if (!selectedObjects?.length && (size >= 3e+8 || (meta?.count > 0 && size === 0))) {
      toggleDownloadAllModal();
    } else {
      download({
        extension: `zip`,
        name: `Workspace Files`,
        title: `Generating Archive`,
      });
    }

    setOpen(false);
  }, [download, meta?.count, meta?.size, selectedObjects?.length, toggleDownloadAllModal]);

  const downloadAll: MenuItem = useMemo(() => {
    if (!canDownload) return null;

    const enabled = !selectedObjects?.length || selectedObjects.some(o => DownloadableObjectTypes.includes(o.object.typeId));

    return {
      children: selectedObjects?.length ? 'Download Selected' : 'Download All',
      disabled: !enabled,
      onClick: handleDownloadAll,
    };
  }, [
    canDownload,
    handleDownloadAll,
    selectedObjects,
  ]);

  const publishTranscripts = useMemo<MenuItem>(() => {
    return {
      children: 'Publish Interviews',
      disabled: !unpublishedTranscripts.length || !filesTableContext.projectId,
      onClick: togglePublishModal,
    };
  }, [unpublishedTranscripts.length, filesTableContext.projectId, togglePublishModal]);

  const importTranscripts = useMemo<MenuItem>(() => {
    const acceptedFileTypes = [
      'pdf',
      'doc',
      'docx',
    ].map(type => `.${type}`).join(',');
    const buttonText = 'Import Transcripts';
    return {
      className: !canUpload ? null : styles.noPadding,
      disabled: !canUpload,
      children: !canUpload ? buttonText : (
        <label className={styles.upload}>
          <input
            className={styles.fileInput}
            accept={acceptedFileTypes}
            multiple={true}
            onChange={handleImportTranscripts}
            type="file" />
          {buttonText}
        </label>
      ),
      onClick: () => { },
    };
  }, [canUpload, handleImportTranscripts]);

  const massAddTags = useMemo<MenuItem>(() => {
    if (!selectObjectsEnabled) return null;

    const taggableObjectTypes = [WorkspaceObjectType.File, WorkspaceObjectType.Folder, WorkspaceObjectType.CallTranscript, WorkspaceObjectType.ProjectParent];
    const taggableObjects = selectedObjects.filter(o => taggableObjectTypes.includes(o.object.typeId));

    return {
      disabled: !taggableObjects.length,
      onClick: toggleTagsModal,
      children: 'Apply Tags',
    };
  }, [selectObjectsEnabled, selectedObjects, toggleTagsModal]);

  const items = useMemo(() => {
    return [
      uploadFileItem,
      addFolderItem,
      downloadAll,
      importTranscripts,
      publishTranscripts,
      massAddTags,
    ].filter(Boolean);
  }, [
    addFolderItem,
    downloadAll,
    importTranscripts,
    uploadFileItem,
    publishTranscripts,
    massAddTags,
  ]);

  const handleFolderCreated = useCallback((res: WorkspaceFolders.CreateFolder.Response<Date>) => {
    history.push(getLocationFor.workspaces.folder({
      folderId: res.folder.id,
    }));
  }, [history]);

  return (
    <>
      <div
        className={className}
        ref={setReferenceElement}
        onClick={handleClick}>
        <AnchorButton />
      </div>
      <NewFolderModal
        onSuccess={handleFolderCreated}
        parentObject={filesTableContext.parentObject} />
      <PublishModal />
      <TagsModal />
      {canDownload &&
        <DownloadAllModal
          objectId={filesTableContext.parentObject.id}
          workspaceId={filesTableContext.parentObject.workspaceId} />
      }
      {open &&
        <Portal>
          <ClickAwayListener
            mouseEvent='onMouseDown'
            onClickAway={handleBlur}
            touchEvent='onTouchStart'>
            <div
              ref={setPopperElement}
              style={popperStyles.popper}
              {...attributes.popper}>
              <PopperMenu>
                {items.map((m, i) => {
                  return (
                    <PopperMenuItem
                      key={i}
                      {...m}
                      className={cx(styles.menuItem, m.className)}
                      onClick={m.onClick} />
                  );
                })}
              </PopperMenu>
            </div>
          </ClickAwayListener>
        </Portal>
      }
    </>
  );
};

const DownloadableObjectTypes = [WorkspaceObjectType.File, WorkspaceObjectType.CallTranscript, WorkspaceObjectType.Folder];