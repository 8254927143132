import { useContext } from 'react';
import { FollowedTopicsContext } from '@containers/Topic/Context';
import { useToggleFollowing } from '@containers/Topic/hooks';
import { Item } from './Following.Item';

type Props = unknown;

export const Followed = (props: Props) => {
  const ctx = useContext(FollowedTopicsContext);
  const handleToggleFollowing = useToggleFollowing();

  return (
    <>
      {ctx.topics.map(x =>
        <Item
          following={true}
          item={x}
          onToggleFollowing={() => handleToggleFollowing(x)}
          key={x.slug} />)}
    </>
  );
};

Followed.displayName = 'Following.Followed';