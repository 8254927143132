import type { SurveyBuilder, SurveyRowsBuilder, SurveyQuestionsBuilder } from '../interfaces';
import * as $actions from './state.rows';

export function rowsReducer(state: SurveyBuilder.State, action: SurveyRowsBuilder.Action): SurveyQuestionsBuilder.State {

  switch (action.type) {
    case 'add-question-row':
      return $actions.addRow(state.survey.questions, action);

    case 'add-question-rows':
      return $actions.addRows(state.survey.questions, action);

    case 'remove-question-row':
      return $actions.removeRow(state.survey.questions, action);

    case 'update-question-row-value':
      return $actions.updateRowValue(state.survey.questions, action);

    case 'row-anchor-updated':
      return $actions.isAnchorUpdated(state.survey.questions, action.payload);

    case 'last-row-in-grid-updated':
      return $actions.isLastRowInGridUpdated(state.survey.questions, action.payload);

    case 'row-openended-updated':
      return $actions.isOpenEndedUpdated(state.survey.questions, action.payload);

    case 'matrix-range-row-metadata-updated':
      return $actions.matrixRangeRowMetadataUpdated(state.survey.questions, action.payload);

    default:
      return state.survey.questions;
  }
}