import { useCallback, useMemo, useEffect } from 'react';
import type { Socket } from 'socket.io-client';
import type { SocketEvent } from '@/enums';
import type { SocketNamespace } from '@enums';
import { LifeCycleSocketEvent } from '@enums';
import { createLogger } from '../utils';

export const useSetupDefaultSocketListeners = (socket: Socket, namespace: SocketNamespace) => {

  const logger = useMemo(() => {
    return createLogger(namespace);
  }, [namespace]);

  const logEvents = useCallback((event: SocketEvent, payload: unknown) => {
    logger.event(event, payload);
  }, [logger]);

  useEffect(() => {
    if (!socket) return;

    socket.on(LifeCycleSocketEvent.Connect, () => logger.event(LifeCycleSocketEvent.Connect));
    socket.on(LifeCycleSocketEvent.ConnectError, (...args) => logger.event(LifeCycleSocketEvent.ConnectError, args));
    socket.on(LifeCycleSocketEvent.Disconnect, () => logger.event(LifeCycleSocketEvent.Disconnect));

    socket.prependAny(logEvents);

    return () => {
      socket.offAny(logEvents);

      socket.off(LifeCycleSocketEvent.Connect);
      socket.off(LifeCycleSocketEvent.ConnectError);
      socket.off(LifeCycleSocketEvent.Disconnect);
    };
  }, [
    logger,
    logEvents,
    socket,
  ]);
};