import { useMemo } from 'react';
import { MultiselectQuestion } from '@/types/survey';
import { OptionContextMenu } from './Option.ContextMenu';
import { useAnchoredOptionMenuItem, useDisqualifyOptionItem, useExclusiveOptionMenuItem, useOpenEndedOptionMenuItem } from './hooks/option-menu';

type Props = {
  option: MultiselectQuestion.Option;
  question: MultiselectQuestion.Question;
};

export const MultiselectOptionContextMenu = (props: Props) => {
  const disqualifyingItems = useDisqualifyOptionItem(props);

  const openEndedMenuItem = useOpenEndedOptionMenuItem({
    option: props.option,
    questionIdentifier: props.question.base.identifier,
  });

  const anchoredMenuItem = useAnchoredOptionMenuItem({
    option: props.option,
    question: props.question,
  });

  const exclusiveMenuItem = useExclusiveOptionMenuItem({
    option: props.option,
    questionIdentifier: props.question.base.identifier,
  });

  const items = useMemo(() => {

    return [
      openEndedMenuItem,
      ...disqualifyingItems,
      anchoredMenuItem,
      exclusiveMenuItem,
    ].filter(Boolean);
  }, [
    anchoredMenuItem,
    disqualifyingItems,
    openEndedMenuItem,
    exclusiveMenuItem,
  ]);

  return (
    <OptionContextMenu
      option={props.option}
      items={items}
      excludeFindAndReplace={false} />
  );
};