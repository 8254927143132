import type { ShortTextResponseQuestion } from '@/types';
import { RationaleCheckbox } from './Settings.Rationale';
import { LanguageEditingBarrier } from './LanguageEditingBarrier';

export type Props = {
  item: ShortTextResponseQuestion.Question;
};

export const ShortTextQuestionBuilder = ({ item }: Props) => {

  return (
    <>
      <LanguageEditingBarrier>
        <RationaleCheckbox />
      </LanguageEditingBarrier>
    </>
  );
};

export default ShortTextQuestionBuilder;