import * as xform from '@transformers/helpers';
import { assignProfileProp } from '@transformers/users';

export function addBindingsToSearchResults(users) {
  const runTransforms = xform.compose(
    assignProfileProp,
    sortEmploymentJobFunctions,
  );

  return users.map(runTransforms);
}

function sortEmploymentJobFunctions(user) {
  if (!user.employment) {
    return Object.assign({}, user, { functions: [] });
  }

  const setOfFunctions = user.employment.sort((a, b) => {
    if (a.isCurrent && !b.isCurrent) return -1;
    else if (b.isCurrent && !a.isCurrent) return 1;
    else return 0;
  })
  .reduce((acc, item) => {
    const functions = item.jobFunction;
    if (functions) {
      functions.map(({ id, name }) => acc.set(id, { id, name }));
    }
    return acc;
  }, new Map());

  const functions = Array.from(setOfFunctions.values());
  return Object.assign({}, user, { functions });
}