import { useMemo } from 'react';
import { useConferenceInstance, useLiveRoomBar, IConference } from '@containers/Conference';
import { Views, Right } from '@screens/ConferenceBar';
import { cx } from '@utils';
import { BarRightChat } from '@screens/Conference.Common';
import { BarRightParticipants } from './Bar.Right.Participants';
import { BarRightSupport } from './Bar.Right.Support';
import styles from './style/Bar.Right.css';

export function BarRight() {
  const [bar] = useLiveRoomBar();

  const rootClassname = useMemo(() => cx(
    styles.right,
    bar.active ? styles.open : null,
  ), [bar.active]);

  const rootStyle = useMemo(() => (bar.active ? { width: '350px' } : {}), [bar.active]);

  return (
    <Right
      className={rootClassname}
      style={rootStyle}>
      {bar.active === 'information' && <Information />}
      {bar.active === 'participants' && <Participants />}
      {bar.active === 'chat' && <Chat />}
      {bar.active === 'support' && <Support />}
    </Right>
  );
}

function Information() {
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom>();

  return (
    <div className={styles.info}>
      <Views.Information
        conferenceIdentifier={instance.conferenceIdentifier}
        dial={instance.dial} />
    </div>
  );
}

function Participants() {
  return (
    <div className={styles.participants}>
      <BarRightParticipants />
    </div>
  );
}

function Chat() {
  return (
    <div className={styles.chat}>
      <BarRightChat />
    </div>
  );
}

function Support() {
  return <BarRightSupport />;
}