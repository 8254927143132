import { useCallback, useMemo } from 'react';
import { ExclusiveOptionsQuestion } from '@/types';
import Multiselect from './ExclusiveOptions.Multiselect';
import MultipleChoice from './ExclusiveOptions.MultipleChoice';
import styles from './style/ExclusiveOptions.css';
import { Rationale } from './Rationale';

type Props = {
  className?: string;
  question: ExclusiveOptionsQuestion.FormQuestion;
};

export const ExclusiveOptions = ({ question }: Props) => {

  const sortedGroups = useMemo(() => {
    return [...question.settings.optionSections]
      .sort((a, b) => a.ordinal - b.ordinal);
  }, [question.settings.optionSections]);

  const renderGroup = useCallback((group: ExclusiveOptionsQuestion.OptionSection) => {
    return (
      <div key={group.identifier} className={styles.group}>
        {question.settings.displayGroupTitles &&
          <div className={styles.title}>{group.value}</div>
        }
        {group.type === 'multi-select' &&
          <Multiselect
            sectionId={group.identifier}
            question={question} />
        }
        {group.type === 'single-select' &&
          <MultipleChoice
            sectionId={group.identifier}
            question={question} />
        }
      </div>
    );
  }, [question]);

  return (
    <>
      <div>
        {sortedGroups.map(renderGroup)}
      </div>
      <Rationale settings={question.settings.rationale} />
    </>
  );
};