import qs from 'query-string';
import http from '@services/http';
import * as xformAdmin from './transformers';
import type { Projects } from './interfaces';

export * as access from './projects.access';
export * as billing from './projects.billing';
export * as calls from './projects.calls';
export * as conferenceTags from './projects.conference-tags';
export * as details from './projects.details';
export * as external from './project.external-survey';
export * as externalSourcing from './project.external-sourcing';
export * as leads from './projects.leads';
export * as links from './projects.links';
export * as members from './projects.members';
export * as scheduling from './projects.scheduling';
export * as surveys from './projects.surveys';

export const autocomplete = (data: Projects.Autocomplete.Request): Promise<Projects.Autocomplete.Response> => {
  return http.get(`/admin/projects/autocomplete?${qs.stringify(data, { arrayFormat: 'bracket' })}`);
};
/*
export const createProjectLegacy = (data: Projects.CreateProjectLegacy.Request) => {
  return http.post(`/admin/projects/legacy`, data)
    .then((data: Projects.CreateProjectLegacy.Response<string>) => xformAdmin.projects.transformProjectDetailsLegacy(data));
};
*/
export const cloneProject = ({ projectParentId, ...body }: Projects.CloneProject.Request): Promise<Projects.CloneProject.Response> => {
  return http.post(`/admin/projects/parents/${projectParentId}/clone`, body);
};

export const fetchAccountManagers = (): Promise<Projects.FetchAccountManagers.Response> => {
  return http.get(`/admin/projects/managers`);
};

export const fetchProjectInfo = (data: Projects.FetchProjectInfo.Request): Promise<Projects.FetchProjectInfo.Response> => {
  return http.get(`/admin/projects/${data.projectId}/info`);
};

export const fetchProjectDetailsLegacy = (data: Projects.FetchProjectDetailsLegacy.Request) => {
  return http.get(`/admin/projects/${data.projectId}/details-legacy`)
    .then((data: Projects.FetchProjectDetailsLegacy.Response<string>) => xformAdmin.projects.transformProjectDetailsLegacy(data));
};

export const fetchProjects = (data: Projects.FetchProjects.Request): Promise<Projects.FetchProjects.Response> => {
  return http.get(`/admin/projects?${qs.stringify(data, { arrayFormat: 'bracket' })}`);
};
/*
export const updateProjectLegacy = (data: Projects.UpdateProjectLegacy.Request) => {
  return http.put(`/admin/projects/${data.id}`, data)
    .then((data: Projects.UpdateProjectLegacy.Response<string>) => xformAdmin.projects.transformProjectDetailsLegacy(data));
};
*/
export const updateProject = (data: Projects.UpdateProject.Request) => {
  return http.patch(`/admin/projects/${data.projectId}`, data)
    .then((data: Projects.UpdateProject.Response<string>) => xformAdmin.projects.transformProjectDetails(data));
};

export const updateSourcing = (data: Projects.UpdateSourcing.Request): Promise<Projects.UpdateSourcing.Response> => {
  return http.put(`admin/projects/${data.projectId}/attributes/sourcing`, data);
};

export const exportProjectParent = (data: Projects.ExportProjectParent.Request) => {
  return http.download(`/admin/projects/parents/${data.projectParentId}/export?${qs.stringify({ format: 'csv' })}`);
};

export const exportProject = (data: Projects.ExportProject.Request) => {
  return http.download(`/admin/projects/${data.projectId}/export?${qs.stringify({ format: data.format })}`);
};

export const updateProjectStatus = (data: Projects.UpdateProjectStatus.Request): Promise<Projects.UpdateProjectStatus.Response> => {
  return http.patch(`/admin/projects/${data.projectId}/status`, data);
};

export const searchNetSuiteProjects = (data: Projects.SearchNetSuiteProject.Request): Promise<Projects.SearchNetSuiteProject.Response> => {
  return http.get(`/admin/projects/netsuite-projects?name=${data.name}`);
};