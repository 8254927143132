import { useSearchParams } from '@containers';
import { Compat, FilterParamKey, SortParams } from '@containers/Search/hooks/useSearchParamsState';
import AdvancedFilters from '@/scenes/search/AdvancedFilters';

type Props = {
  className?: string;
  onHide:     () => unknown;
  visible:    boolean;
};

const SearchFilter = (props: Props) => {
  const [params, actions] = useSearchParams();

  const between = ({ low, high }: { low: number; high: number; }) => {
    actions.between('price', [low, high]);
  };

  const pop = ({ list: key, id }: Compat.ParamValueItemId<FilterParamKey>) => {
    actions.pop(key, id);
  };

  const push = ({ list: key, item }: Compat.ParamValueItem<FilterParamKey>) => {
    actions.push(key, item);
  };

  const sort = (data: SortParams) => {
    data.sort === params.sort
      ? actions.orderBy(data.sortDir)
      : actions.sortBy(data.sort);
  };

  return (
    <AdvancedFilters
      className={props.className}
      search={params}
      handlePriceChange={between}
      handleItemAdded={push}
      handleItemRemoved={pop}
      onClose={props.onHide}
      priceRange={{
        priceLow: params.priceLow,
        priceHigh: params.priceHigh,
      }}
      visible={props.visible}
      showRate={false}
      onResetFilters={actions.clear}
      onSortChange={sort} />
  );
};

export { SearchFilter };
export default SearchFilter;