import type {
  ApplySchemaAttributes,
  MarkExtensionSpec,
  MarkSpecOverride } from '@remirror/core';
import {
  command,
  extension,
  ExtensionTag,
  MarkExtension,
  PrimitiveSelection,
  CommandFunction,
  getTextSelection,
} from '@remirror/core';
import { MarkType } from '@/types/transcribe.rich-text';
import classes from './style/Redaction.css';

@extension({
  defaultOptions: {},
  disableExtraAttributes: true,
})
export class RedactionExtension extends MarkExtension {
  get name() {
    return MarkType.Redaction as const;
  }

  createTags() {
    return [ExtensionTag.FormattingMark];
  }

  createMarkSpec(extra: ApplySchemaAttributes, override: MarkSpecOverride): MarkExtensionSpec {
    return {
      ...override,
      toDOM: () => ['span',
        {
          class: classes.redacted,
          contentEditable: 'false',
          selectable: 'false',
        }],
    };
  }

  @command()
  setRedaction(selection?: PrimitiveSelection): CommandFunction {
    return ({ tr, dispatch }) => {
      const { from, to } = getTextSelection(selection ?? tr.selection, tr.doc);
      dispatch?.(tr.addMark(from, to, this.type.create()));

      return true;
    };
  }
}