import { io } from 'socket.io-client';
import type { FileSocketEvent } from '@enums';
import { LifeCycleSocketEvent, SocketNamespace } from '@enums';
import { createLogger, resolveSocketOptions } from './utils';
import type { Files } from './interfaces';

const log = createLogger(SocketNamespace.Files);

function getSocket() {

  const socket = io(`${process.env.BACKEND_WS_URL}/${SocketNamespace.Files}`, resolveSocketOptions());

  function open() {
    socket.on(LifeCycleSocketEvent.Connect, () => log.event(LifeCycleSocketEvent.Connect));
    socket.on(LifeCycleSocketEvent.ConnectError, (...args) => log.event(LifeCycleSocketEvent.ConnectError, args));
    socket.on(LifeCycleSocketEvent.Disconnect, () => log.event(LifeCycleSocketEvent.Disconnect));

    socket.prependAny((event: FileSocketEvent, payload: unknown) => log.event(event, payload));
    socket.open();
  }

  function close() {
    socket.close();

    socket.offAny();
    socket.removeAllListeners();
  }

  const on = <T extends FileSocketEvent>(event: T, handler: Files.RegisterHandler<T>) => {
    return socket.on<FileSocketEvent | LifeCycleSocketEvent>(event, handler);
  };

  const off = <T extends FileSocketEvent>(event: T, handler: Files.RegisterHandler<T>) => {
    return socket.off<FileSocketEvent | LifeCycleSocketEvent>(event, handler);
  };

  const once = <T extends FileSocketEvent>(event: T, handler: Files.RegisterHandler<T>) => {
    return socket.once<FileSocketEvent>(event, handler);
  };

  const subscribe = (data: IWorkspaceFileId) => {
    socket.emit('subscribe', data.fileId);
  };

  const unsubscribe = (data: IWorkspaceFileId) => {
    socket.emit('unsubscribe', data.fileId);
  };

  return {
    close,
    off,
    on,
    once,
    open,
    subscribe,
    unsubscribe,
  };
}

export const files = {
  getSocket,
  ...getSocket(),
};
export default files;