import { useCallback, useContext } from 'react';
import { ConferenceParticipantsTable, ConferenceParticipantsModal } from '@/components/Conference.Participants';
import { useModal } from '@/components/Modal/hooks';
import * as $conference from '$admin/containers/Call/utils';
import { CallDetailsContext } from './Context';
import * as Layout from './Layout';
import styles from './style/Conference.Participants.css';

type Props = unknown;

export const ConferenceParticipants = (props: Props) => {
  const ctx = useContext(CallDetailsContext);

  const [toggle, ManageParticipantsModal] = useModal(ConferenceParticipantsModal);

  const offPlatform = $conference.isOffPlatform(ctx.query.data.settings);

  const handleSubmit = useCallback(() => {
    ctx.query.refetch().then(() => toggle());
  }, [
    ctx.query,
    toggle,
  ]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <Layout.Title>Conference Participants</Layout.Title>
          {(ctx.editable && !!ctx.query.data?.call?.timeStart) &&
            <button
              className={styles.edit}
              onClick={toggle}>
              Edit Participants
            </button>}
        </div>
        <ConferenceParticipantsTable
          identifier={ctx.query.data.conference.identifier}
          items={ctx.query.data.conference.participants} />
        <ManageParticipantsModal
          callId={ctx.query.data.call.id}
          projectId={ctx.query.data.project.id}
          offPlatform={offPlatform}
          onSuccess={handleSubmit}
          statusId={ctx.query.data.call.statusId} />
      </div>
    </div>
  );
};

ConferenceParticipants.displayName = 'Conference.Participants';