import * as consts from '@consts';
import { ButtonActivityIndicator } from '@presentation';
import { qs } from '@utils';
import { AuthForm, Footer } from '$website/components';
import LogoTrinity from 'static/images/logo-trinity-white.svg?url';
import { SetPasswordInviteExpired } from './SetPasswordInvite.Expired';
import styles from './style/Auth0Error.css';

const { Layout } = AuthForm;

type Props = unknown;

export const Auth0Error = (props: Props) => {
  const qp = qs.parse<Params>(window.location.search);
  const hash = qs.parse<Hash>(window.location.hash.slice(1));

  const invite = hash?.type === 'invite';
  const failed = qp?.success === 'false';
  const hasDescription = !!qp?.error_description;

  const inviteExpired = hasDescription
    && qp.error_description.includes('expired')
    && !failed
    && invite;

  if (inviteExpired) {
    return (
      <SetPasswordInviteExpired
        email={hash?.email}
        userId={+hash?.userId} />
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.bg}>
        <div className={styles.col}>
          <div className={styles.logo}>
            <img
              src={LogoTrinity}
              alt="Trinity"
              className={styles.logoImage} />
          </div>

          <div className={styles.generic}>
            <Layout.Header className={styles.header}>
              <Layout.Row className={styles.title}>
                <Layout.Title>{qp?.error_description || 'Authentication Error'}</Layout.Title>
              </Layout.Row>
            </Layout.Header>

            <div className={styles.main}>
              <ButtonActivityIndicator
                className={styles.submit}
                replace
                to={consts.path.Website.PlatformLogin}>
                Log In
              </ButtonActivityIndicator>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </div>
  );
};

Auth0Error.displayName = 'Auth0Error';

type Params = {
  error_description?: string;
  message?: string;
  success?: string;
};

type Hash = {
  email: string;
  userId: string;
  type: string;
};