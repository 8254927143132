import { useContext } from 'react';
import { LoginSubmitContext } from '../Context';

type Params = unknown;

const useLoginSubmit = () => {
  const [response, submit] = useContext(LoginSubmitContext);

  return [response, submit] as const;
};

export { useLoginSubmit };
export default useLoginSubmit;