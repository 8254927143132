import type { AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { querykey } from '@/consts';
import * as $api from '@/services/api';
import type { WorkspaceFolders as WS } from '@/services/api/interfaces';

type Props = IWorkspaceFolderId;
type Data = WS.GetFolder.Response;
type QueryKey = readonly [string];
type Options = UseQueryOptions<Data, AxiosResponse, Data, QueryKey>;

export const useFetchFolder = (props: Props, options?: Options) => {
  const qk = querykey.Workspaces.Folder.Get(props);

  return useQuery(qk, ({ queryKey }) => {
    return $api.workspaces.folder.getFolder({
      folderId: props.folderId,
    });
  }, options);
};
