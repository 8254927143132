import { useCallback, useEffect, useRef, useState } from 'react';
import { StepperContext, StepSubscriberContext } from './Context';
import { Step as Route } from './Stepper.Route';
import { StepperContextValue } from './interfaces';

type Props = {
  children: React.ReactNode;
};

const Stepper = (props: Props) => {
  const [focused, setFocused] = useState<string>(null);
  const all = useRef<Set<string>>(new Set()).current;
  const [step, setStep] = useState<StepperContextValue>({
    count: 0,
    index: 0,
    key: null,
  });

  const register = useCallback((key: string) => all.add(key), [all]);
  const unregister = useCallback((key: string) => all.delete(key), [all]);

  const updateStep = useCallback((key: string) => {
    const next = {
      index: Array.from(all.values()).indexOf(key) || 0,
      count: all.size,
      key,
    };

    setStep(next);
  }, [all]);

  useEffect(() => {

    updateStep(focused);

  }, [
    focused,
    updateStep,
  ]);

  const subscriber = {
    register,
    setFocused,
    unregister,
  };

  return (
    <StepperContext.Provider value={step}>
      <StepSubscriberContext.Provider value={subscriber}>
        {props.children}
      </StepSubscriberContext.Provider>
    </StepperContext.Provider>
  );
};

Stepper.Route = Route;

export { Stepper };
export default Stepper;