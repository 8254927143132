import { useCallback, useContext, useMemo } from 'react';
import { Redirect, Route, Switch, generatePath, matchPath, useHistory, useLocation } from 'react-router-dom';
import { Profile } from '@consts/path';
import { TabView } from '@/components/TabView';
import { ProfileOverviewQueryContext } from './Context';
import * as Tab from './Tab';
import styles from './style/User.Profile.css';

type Props = IUserId;

export const TabViewContainer = (props: Props) => {
  const history = useHistory();
  const location = useLocation();

  const scenemap = useSceneMap({
    userId: props.userId,
  });
  const matchTabIndexFromLocation = useCallback((scenes: SceneMap) => {
    const matched = Object.values(scenes).find(x => matchPath(x.to, {
      path: location.pathname,
      exact: true,
    }));

    if (!matched) return 0;

    return Object.keys(scenes).indexOf(matched.key);
  }, [location.pathname]);

  const index = useMemo(() => {
    return matchTabIndexFromLocation(scenemap);
  }, [
    matchTabIndexFromLocation,
    scenemap,
  ]);

  const changeLocation = useCallback((idx: number) => {
    const tab = Object.keys(scenemap)[idx] as TabKey;
    const { to } = scenemap[tab];
    history.push(to);
  }, [
    history,
    scenemap,
  ]);

  const routes = Object.values(scenemap);

  const renderTabView = useCallback(() => {
    return (
      <Switch>
        {routes.map(route => (
          <Route
            component={route.component}
            exact
            key={route.key}
            path={route.path} />))}
        <Redirect
          from={Profile.Tabs.Root}
          to={Profile.Tabs.Professional} />
      </Switch>
    );
  }, [
    routes,
  ]);

  return (
    <div className={styles.tabview}>
      <TabView<Scene>
        classes={{ tab: styles.tab }}
        index={index}
        onIndexChange={changeLocation}
        renderTabView={renderTabView}
        routes={routes} />
    </div>
  );
};

TabViewContainer.displayName = 'TabView.Container';

export enum TabKey {
  Professional = 'Professional',
  Medical      = 'Medical',
  Payer        = 'Payer',
  Projects     = 'Projects',
}

export type Scene = {
  component: TabViewComponent;
  key:       TabKey;
  title:     string;
  path:      string;
  to:        string;
};

export type SceneMap = {
  [key in TabKey]?: Scene;
};

const useSceneMap = (params: IUserId) => {
  const query = useContext(ProfileOverviewQueryContext);

  const toPathname = useCallback((path: string) => {
    return generatePath(path, {
      userId: params.userId,
    });
  }, [params.userId]);

  return useMemo<SceneMap>(() => ({
    [TabKey.Professional]: {
      component: Tab.Professional,
      key: TabKey.Professional,
      path: Profile.Tabs.Professional,
      to: toPathname(Profile.Tabs.Professional),
      title: `Professional Profile`,
    },
    [TabKey.Medical]: {
      component: Tab.Medical,
      key: TabKey.Medical,
      path: Profile.Tabs.Medical,
      title: `Medical Profile`,
      to: toPathname(Profile.Tabs.Medical),
    },
    ...query.data?.classification?.payer ? {
      [TabKey.Payer]: {
        component: Tab.Payer,
        key: TabKey.Payer,
        path: Profile.Tabs.Payer,
        title: `Payer Profile`,
        to: toPathname(Profile.Tabs.Payer),
      },
    } : {},
    [TabKey.Projects]: {
      component: Tab.Projects,
      key: TabKey.Projects,
      path: Profile.Tabs.Projects,
      title: `Project History`,
      to: toPathname(Profile.Tabs.Projects),
    },
  }), [
    toPathname,
    query.data?.screeners,
  ]);
};

type TabViewComponent = {
  (props: ObjectLike): JSX.Element;
  displayName?:   string;
};