import { useChainedCommands as useChainedCommandsRM, useCommands as useCommandsRM } from '@remirror/react';
import type { TranscriptExtensions } from '@/components/Transcript/interfaces.extension.remirror';

export const useChainedCommands = () => {

  return useChainedCommandsRM<TranscriptExtensions>();
};

// Note: doesn't seem to be returning correct types
export const useCommands = () => {

  return useCommandsRM<TranscriptExtensions>();
};