import { memo } from 'react';
import { cx } from '@utils';
import styles from './style/Base.css';

type Props = {
  children?:  React.ReactNode;
  className?: string;
};

const Body = memo((props: Props) => {
  const className = cx(styles.content, props.className);
  return (
    <div className={className}>
      {props.children}
    </div>
  );
});

export { Body };
export default Body;