import { useCallback, useMemo } from 'react';
import { SurveyTagging } from '@/types/survey';
import { useSubmitSurveyDraft, useSurveyTaggingState } from '@containers/SurveyBuilder';
import { ContextMenuItemProps } from '@/components/SurveyBuilder/ContextMenu.Item';
import { SurveyBuilderContextMenu } from '@/components/SurveyBuilder/ContextMenu';

type Props = {
  className?: string;
  item: SurveyTagging.Item;
};

export const TaggingItemContextMenu = ({ className, item }: Props) => {

  const [state, dispatch] = useSurveyTaggingState();
  const submitDraft = useSubmitSurveyDraft();

  const handleEdit = useCallback(() => {
    dispatch({
      type: 'tagging-editing-toggled-on',
      payload: {
        identifier: item.identifier,
      },
    });
  }, [dispatch, item.identifier]);

  const handleDelete = useCallback(() => {
    dispatch({
      type: 'tagging-item-removed',
      payload: {
        identifier: item.identifier,
      },
    });
    submitDraft();
  }, [dispatch, item.identifier, submitDraft]);

  const editItem = useMemo(() => {

    const canEdit = !state.editing.identifier;

    return {
      children: 'Edit',
      disabled: !canEdit,
      onClick: handleEdit,
    };

  }, [handleEdit, state.editing]);

  const deleteItem = useMemo(() => {

    const canDelete = true;

    return {
      children: 'Delete',
      disabled: !canDelete,
      onClick: handleDelete,
    };
  }, [handleDelete]);

  const items: ContextMenuItemProps[] = useMemo(() => {

    return [
      editItem,
      deleteItem,
    ].filter(Boolean);
  }, [editItem, deleteItem]);

  return (
    <SurveyBuilderContextMenu
      className={className}
      items={items} />
  );
};