import { useCallback } from 'react';
import { useConferenceInstance, useCoordinator, useTwilioVoice, IConference } from '@containers/Conference';
import { ButtonActivityIndicator } from '@presentation';
import { SelectOptionProps } from '@/components/Select/interfaces';
import Toast from '@/components/Toast';
import { Tabs } from './Tabs';
import * as utils from './utils';
import styles from './style/Voice.css';

export function Voice() {
  const instance = useConferenceInstance<IConference.Coordinator.Conference.PreRoom>();
  const coordinator = useCoordinator();
  const { getAudioPermission, changeMicrophoneSource, microphoneSource } = useTwilioVoice();

  const handleMicrophone = useCallback((enabled: boolean) => {
    if (enabled) {
      getAudioPermission()
      .then(result => {
        if (!result) {
          coordinator.updatePreRoom({
            conferenceIdentifier: instance.conferenceIdentifier,
            settings: {
              microphone: false,
            },
          });
          Toast.error({
            title: `Failed to get browser permission to use your microphone!`,
          });
        }
      });
    }
    coordinator.updatePreRoom({
      conferenceIdentifier: instance.conferenceIdentifier,
      settings: {
        microphone: enabled,
      },
    });
  }, [getAudioPermission, instance.conferenceIdentifier, coordinator]);

  const handleVisibility = useCallback((value: SelectOptionProps) => {
    coordinator.updatePreRoom({
      conferenceIdentifier: instance.conferenceIdentifier,
      settings: {
        visibility: utils.visibilityToBool(value.id),
      },
    });
  }, [instance.conferenceIdentifier, coordinator]);

  const handleJoin = useCallback(() => {
    coordinator.join({
      conferenceIdentifier: instance.conferenceIdentifier,
      visibility: instance.settings.visibility,
    });
  }, [instance.conferenceIdentifier, instance.settings.visibility, coordinator]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <div className={styles.title}>
            {instance.conference.title} Call
          </div>
          <div className={styles.date}>
            {utils.formatCallTime(instance.call.start, instance.call.end)}
          </div>
          <div className={styles.subtitle}>
            {(instance.features.microphoneToggle || instance.features.visibilityToggle)
              ? `Join the conference with the settings below.`
              : `The settings below have been set for you by the Host.`}
          </div>
          <div className={styles.subtitle}>
            {(instance.features.microphoneToggle || instance.features.visibilityToggle)
              ? `You'll also be able to change these after joining.`
              : null}
          </div>
        </div>
        <div className={styles.content}>
          <Tabs
             disabledMicrophone={!instance.features.microphoneToggle}
             microphoneValue={instance.settings.microphone}
             microphoneSource={microphoneSource?.deviceId}
             disabledVisibility={!instance.features.visibilityToggle}
             visibilityValue={utils.boolToVisibility(instance.settings.visibility)}
             phoneInformation={instance.dial}
             visibilityVisible={instance.features.showVisibility}
             disabledNametag={!instance.features.editNametag}
             toggleMicrophone={handleMicrophone}
             changeMicrophoneSource={changeMicrophoneSource}
             onSelectVisibility={handleVisibility} />
        </div>
        <div className={styles.actions}>
          <ButtonActivityIndicator
            className={styles.join}
            loading={instance.joiningMeeting}
            variant="brick"
            onClick={handleJoin}>
            Join Conference
          </ButtonActivityIndicator>
        </div>
      </div>
    </div>
  );
}