import { memo, Fragment } from 'react';
import { Field, FieldProps } from 'formik';
import { Input } from '@/components/Input';
import styles from './style.css';

type Props = {
  editing: boolean;
  label: string;
  name: string;
  displayValue: React.ReactChild;
  min?: number;
  max?: number;
  step?: number;
};

export function FieldNumber({ editing, label, name, displayValue, min, max, step }: Props) {
  if (!editing) {
    return (
      <Display
        label={label}
        displayValue={displayValue} />
    );
  }

  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => {
        return (
          <div>
            <div className={styles.label}>
              {label} {meta.touched && meta.error && <span className={styles.formError}>{meta.error}</span>}
            </div>
            <div className={styles.value}>
              <Input
                className={styles.input}
                name={field.name}
                type="number"
                min={min}
                max={max}
                step={step}
                {...field}
                invalid={meta.touched && !!meta.error}
                invalidClassName={styles.inputError} />
            </div>
          </div>
        );
      }}
    </Field>
  );
}

const Display = memo(({ label, displayValue }: Pick<Props, 'label' | 'displayValue'>) => {
  return (
    <Fragment>
      <div className={styles.label}>{label}</div>
      <span className={styles.inputValue}>{displayValue ?? '-'}</span>
    </Fragment>
  );
});