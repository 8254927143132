import { useCallback, useState } from 'react';
import { useDimensions } from '@utils';

export const useFixedSidebar = () => {
  const [fixed, setFixed] = useState(false);

  const d = useDimensions();

  const calcFixed = useCallback((node: HTMLDivElement) => {
    const navbarHeight = Number(window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('--navbar-height')
      .replace('px', ''));

    const paddingTop = 20;

    const availableHeight = d.height - paddingTop - navbarHeight;

    const f = node?.scrollHeight < availableHeight;
    setFixed(f);

    return node;
  }, [d.height]);

  const ref = useCallback((node: HTMLDivElement) => {
    return calcFixed(node);
  }, [calcFixed]);

  return [ref, fixed] as const;
};

export default useFixedSidebar;