import { Payout } from '@api/interfaces';
import { safeJsonDate } from '@utils';

export function normalizePayoutHistory(data: Payout.GetPayoutHistory.Response<string>): Payout.GetPayoutHistory.Response {

  const items = data.items.map(m => {
    return Object.assign(m, {
      createdOn: safeJsonDate(m.createdOn),
    });
  });

  return Object.assign(data, {
    items,
  });
}