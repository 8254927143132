import { useCallback, useRef } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { usePreventScrollRestoration } from '@containers/ScrollRestoration';
import { usePrevious } from '@utils';

export const usePreventProjectScrollRestoration = () => {
  const location = useLocation();
  const locRef = useRef(new Set<Location['pathname']>());
  const match = !!useRouteMatch('/projects/:slug');
  const prevMatch = !!usePrevious(match);

  const customCompare = useCallback((previous, current) => {
    const noMatch = !prevMatch && !match;
    const matchOnFirstVisit = !prevMatch
                           && match
                           && !locRef.current.has(location.pathname);

    const shouldPreventRestoration = noMatch || matchOnFirstVisit
        ? false
        : true;

    locRef.current.add(location.pathname);

    return shouldPreventRestoration;
  }, [
    prevMatch,
    match,
    location.pathname,
  ]);

  usePreventScrollRestoration(customCompare);
};

export default usePreventProjectScrollRestoration;