import { Dispatch } from 'react';
import * as enums from '@enums';
import { createNamedContext } from '@utils';
import { GroupUser, Workspace, WorkspaceObject } from '@/types';
import { ObjectAccess } from './interfaces';

export type ObjectAccessStateContextValue =
  [ObjectAccess.State, Dispatch<ObjectAccess.Actions>];

export type ObjectAccessContextValue = {
  object: WorkspaceObject;
  workspace: Workspace;
};

export type UserObjectAccessContextValue = {
  roles: enums.WorkspaceObjectRole[];
  selectedRole: enums.WorkspaceObjectRole;
} & IUserId;

export type GroupUsersContext = {
  items: GroupUser[];
  fetch: (value?: string) => unknown;
};

type FetchAccess = () => unknown;

export const FetchObjectAccessContext = createNamedContext<FetchAccess>(undefined, 'fetch-object-access');
export const ObjectAccessContext = createNamedContext<ObjectAccessContextValue>(undefined, 'object-access');
export const ObjectAccessStateContext = createNamedContext<ObjectAccessStateContextValue>(undefined, 'object-access-state');
export const ObjectAccessLoadingContext = createNamedContext<boolean>(false, 'object-access-loading');
export const SaveObjectAccessContext = createNamedContext<ObjectAccess.SaveObjectAccess.Fn>(undefined, 'save-object-access');
export const UserObjectAccessContext = createNamedContext<UserObjectAccessContextValue>(undefined, 'user-object-access');
export const GroupUsersContext = createNamedContext<GroupUsersContext>(undefined, 'group-users');