import { useSelector } from 'react-redux';
import { selectProject, selectProjectParent } from '@store/selectors';

const useSelectProject = (projectId: number) => {
  const project = useSelector(selectProject(projectId));

  return project;
};

const useSelectProjectParent = (projectParentId: number) => {
  const parent = useSelector(selectProjectParent(projectParentId));

  return parent;
};

export { useSelectProject, useSelectProjectParent };
export default useSelectProject;