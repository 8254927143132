import { useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { RateLimitingContext } from '@containers/RateLimiting/Context';
import { useSelectUser } from '@containers/Store';
import { useToggle } from '@utils';
import { ChatSettingsToggleContext } from './context';

type Props = {
  children: React.ReactNode;
};

export const ChatSettingsContainer = (props: Props) => {
  return (
    <ChatSettingsToggleContext.Provider value={useToggle()}>
      {props.children}
    </ChatSettingsToggleContext.Provider>
  );
};

export const ChatSettings = (props: unknown) => {
  const ctx = useContext(RateLimitingContext);
  const [_, toggle] = useContext(ChatSettingsToggleContext);
  const user = useSelectUser();

  const stats = useMemo(() => {
    return {
      chats: {
        total: ctx.query.data?.chats?.total ?? 0,
        limit: ctx.query.data?.chats?.limit ?? 0,
      },
      documents: {
        total: ctx.query.data?.documents?.total ?? 0,
        limit: ctx.query.data?.documents?.limit ?? 0,
      },
    };
  }, [
    ctx.query.data.chats,
    ctx.query.data.documents,
  ]);

  return (
    <Root>
      <Header>
        <Back onClick={toggle}>{`< Back`}</Back>
      </Header>
      <Wrap>
        <Main>
          <Title>Chat Settings</Title>
          <Row>{`Username: ${user.contact.email}`}</Row>
          <Row>{`Submitted Chats: ${stats.chats.total.toLocaleString()} / ${stats.chats.limit.toLocaleString()}`}</Row>
          <Row>{`Uploaded Documents: ${stats.documents.total.toLocaleString()} / ${stats.documents.limit.toLocaleString()}`}</Row>
        </Main>
      </Wrap>
    </Root>
  );
};

ChatSettings.displayName = 'Chat.Settings';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 85%;
  width: calc(100% - 40px);
  border: 1px solid var(--sec-01);
  border-radius: 6px;
  background-color: var(--pri-01);
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
`;

const Title = styled.div`
  font-size: 22px;
  font-family: var(--font-bold);
`;

const Row = styled.div`
  font-size: 15px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  width: calc(100% - 40px);
`;

const Back = styled.div`
  cursor: pointer;
  color: var(--pri-02);
  font-size: 14px;
  font-family: var(--font-semibold);
`;