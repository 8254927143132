import { useCallback } from 'react';
// import { useSurveyQuestionCompleteEvent } from '@utils/analytics';
// import { useSurveyFormVersionId, useSurveyFormQuestionAnswer } from '../Context';
// import useSurveyFormQuestionContext from './useSurveyFormQuestionContext';

export const useSurveyFormQuestionCompleteEvent = () => {
  return useCallback(() => {}, []);
  // const questionCompleteEvent = useSurveyQuestionCompleteEvent();
  // const [answer] = useSurveyFormQuestionAnswer();
  // const question = useSurveyFormQuestionContext();
  // const surveyVersionId = useSurveyFormVersionId();

  // return useCallback(() => {
  //   questionCompleteEvent({
  //     id: question.id,
  //     response: answer,
  //     value: question.value,
  //     version: surveyVersionId,
  //   });
  // }, [
  //   answer,
  //   question.id,
  //   question.value,
  //   questionCompleteEvent,
  //   surveyVersionId,
  // ]);
};