import { User } from 'react-feather';
import CountBadge from './CountBadge';

type Props = {
  className?: string;
  count: number;
  to: string;
};

const UsersCount = (props: Props) => (
  <CountBadge
    className={props.className}
    count={props.count}
    icon={<User size={20} height="100%" />}
    to={props.to} />
);

export { UsersCount };
export default UsersCount;