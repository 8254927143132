import { Header } from '../Header';
import { ErrorDetails } from './ErrorDetails';
import { AddressBarIcon } from './AddressBarIcon';
import { useDeviceName } from './hooks';
import styles from './styles/PermissionDenied.css';

export const PermissionDenied = () => {
  const device = useDeviceName();
  return (
    <>
      <Header>Camera and/or microphone are blocked</Header>
      <div>
        This app requires access to your {device}.
      </div>
      <div>
        Click the <AddressBarIcon className={styles.icon} /> page info icon in your {`browser's`} address bar to toggle the permission on then try turning your {device} on.
      </div>
    </>
  );
};