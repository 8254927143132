import { useContext, useEffect, useMemo, useState } from 'react';
import { type Column as RTColumn, useFilters, useTable, useFlexLayout, usePagination, type TableOptions } from 'react-table';
import * as Table from '@/components/Table';
import { MembersQueryContext } from '$admin/Search/Context';
import { Filters } from '$admin/Search/Members/Filters';
import { ResultsPagination } from '$admin/Search/Members/Results.Pagination';
import { StateContainerFilters } from '$admin/Search/Members/StateContainer.Filters';
import { StateContainerFiltersOptions, Options as FilterOptions } from '$admin/Search/Members/StateContainer.Filters.Options';
import { StateContainerIndeterminateSelected } from '$admin/Search/Members/StateContainer.IndeterminateSelected';
import { StateContainerMatchList } from '$admin/Search/Members/StateContainer.MatchList';
import { StateContainerSelected } from '$admin/Search/Members/StateContainer.Selected';
import type { Search } from '$admin/interfaces';
import { ActionsMenu } from './Actions.Menu';
import * as Column from './Column';
import { SearchMembersQueryContainer } from './Search.Members.Query.Container';
import styles from './style/Search.Members.css';

type Props = unknown;

export const SearchMembers = (props: Props) => {
  return (
    <StateContainerFiltersOptions options={Options}>
      <StateContainerFilters>
        <StateContainerMatchList>
          <SearchMembersQueryContainer>
            <StateContainerSelected>
              <StateContainerIndeterminateSelected>
                <div className={styles.root}>
                  <div className={styles.wrap}>
                    <div className={styles.header}>
                      <div className={styles.tools}>
                        <div className={styles.filters}>
                          <Filters />
                        </div>
                        <div className={styles.actions}>
                          <ActionsMenu />
                        </div>
                      </div>
                    </div>
                    <div className={styles.main}>
                      <ResultsTable />
                    </div>
                  </div>
                </div>
              </StateContainerIndeterminateSelected>
            </StateContainerSelected>
          </SearchMembersQueryContainer>
        </StateContainerMatchList>
      </StateContainerFilters>
    </StateContainerFiltersOptions>
  );
};

SearchMembers.displayName = 'Search.Members';

const ids: Search.Members.Filters.Field[] = [
  'keyword',
  'company',
  'company-former',
  'covered-lives',
  'lead-status',
  'name',
  'job-title',
  // 'industry',
  // 'sub-industry',
  'role',
  'country',
  'market-research',
  'payer-admin-type',
  'project-code',
  'hcp-type',
  'is-kol',
];

const Options = FilterOptions.filter(x => ids.some(id => id === x.id));

const ResultsTable = (props: Props) => {
  const columns = useMemo<RTColumn<Search.Members.Results.Item>[]>(() => [
    {
      Header: `Name`,
      Cell: Column.Name.Cell,
      id: `name`,
      minWidth: 150,
      maxWidth: 150,
    },
    // {
    //   Header: `Rating`,
    //   Cell: Column.Rating.Cell,
    //   id: `rating`,
    //   minWidth: 100,
    //   maxWidth: 100,
    // },
    {
      Header: `Role`,
      Cell: Column.Role.Cell,
      id: `role`,
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: `Title`,
      Cell: Column.Title.Cell,
      id: `title`,
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: `Payer / Admin Type`,
      Cell: Column.PayerAdminType.Cell,
      id: `payer-admin-type`,
      minWidth: 150,
      maxWidth: 150,
    },
    // {
    //   Header: `Specialty`,
    //   Cell: Column.Specialty.Cell,
    //   id: `specialty`,
    //   minWidth: 100,
    //   maxWidth: 100,
    // },
    // {
    //   Header: `Subspecialty`,
    //   Cell: Column.Subspecialty.Cell,
    //   id: `subspecialty`,
    //   minWidth: 100,
    //   maxWidth: 100,
    // },
    {
      Header: `Rate`,
      Cell: Column.Rate.Cell,
      id: `rate`,
      minWidth: 60,
      maxWidth: 60,
    },
    {
      Header: `Country`,
      Cell: Column.Country.Cell,
      id: `country`,
      minWidth: 60,
      maxWidth: 60,
    },
    {
      Header: `Current Institution`,
      Cell: Column.CurrentInstitution.Cell,
      id: `current-institution`,
      minWidth: 100,
      maxWidth: 100,
    },
    // {
    //   Header: `History`,
    //   Cell: Column.History.Cell,
    //   id: `history`,
    //   minWidth: 60,
    //   maxWidth: 60,
    // },
    {
      Header: `Notes`,
      Cell: Column.Notes.Cell,
      id: `notes`,
      minWidth: 60,
      maxWidth: 60,
    },
  ], []);

  const query = useContext(MembersQueryContext);

  const params: TableOptions<Search.Members.Results.Item> = {
    columns,
    data: query.data?.items ?? [],
    initialState: {
      pageIndex: 0,
      /* pageSize: query.size, */
    },
    manualFilters: true,
    manualPagination: true,
    pageCount: query.data?.pagination?.pageCount ?? 0,
  };

  const {
    getTableBodyProps,
    headerGroups,
    pageOptions,
    prepareRow,
    rows,
    setPageSize,
  } = useTable<Search.Members.Results.Item>(
    params,
    useFilters,
    useFlexLayout,
    usePagination,
  );

  const [counts, setCounts] = useState([query.data.pagination.pageCount, query.data.pagination.totalCount]);

  useEffect(() => {
    if (!query.isPlaceholderData) {
      setCounts([query.data.pagination.pageCount, query.data.pagination.totalCount]);
    }

  }, [
    query.isPlaceholderData,
    query.data.pagination.pageCount,
    query.data.pagination.totalCount,
  ]);

  return (
    <Table.Layout.Box>
      <Table.Root
        empty={false}
        loading={false}>
        <Table.Header
          classes={{
            thead: styles.thead,
            tr: styles.tr,
          }}
          headerGroups={headerGroups} />
        <Table.Body
          {...getTableBodyProps()}
          classes={{ td: styles.td }}
          prepareRow={prepareRow}
          rows={rows} />
        <Table.Layout.Footer className={styles.footer}>
          <ResultsPagination
            className={styles.pagination}
            pageCount={counts[0]}
            totalCount={counts[1]} />
        </Table.Layout.Footer>
      </Table.Root>
    </Table.Layout.Box>
  );
};