import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import * as api from '@api';
import { Projects } from '@api/interfaces';

type Data = Projects.GetSurveyMetrics.Response;
type QueryKey = [string, number, number];

export const useProjectSurveyMetrics = (
  { projectId, surveyId }: IProjectId & Partial<ISurveyId>,
  options?: UseQueryOptions<Data, AxiosError, Data, QueryKey>
) => {

  return useQuery(['fetch-project-survey-metrics', projectId, surveyId], ({ queryKey }) => {
    const projectId = queryKey[1] as number;
    const surveyId = queryKey[2] as number;

    return api.projects.surveys.metrics.get({
      projectId, surveyId,
    });
  }, options);
};