import { FiltersContext } from './Context';
import useFiltersState from './hooks/useFilterState';

export default function SearchContainer({ children }: ChildrenProps) {
  const [filters, actions] = useFiltersState();

  return (
    <FiltersContext.Provider value={[filters, actions]}>
      {children}
    </FiltersContext.Provider>
  );
}

export { SearchContainer };
