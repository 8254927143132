import { useContext, useEffect, useMemo, useState } from 'react';
import { useAudioPlayerRef } from '@containers/AudioPlayer';
import {
  SetTranscriptMediaPlayerContext,
  TranscriptIdContext,
  TranscriptMediaPlayerContext,
  TranscriptMediaContext,
  InitialTranscriptPositionContext,
} from '@containers/Transcript/context';
import { useIsVideo } from '@utils';
import { useFetchTranscriptMedia } from '@utils/api';

type Props = {
  audioOnly?: boolean;
} & ChildrenProps;

export const TranscriptMediaContainer = (props: Props) => {
  const [videoPlayer, setVideoPlayer] = useState<HTMLVideoElement>();
  const [audioPlayer, setAudioPlayer] = useAudioPlayerRef();

  const transcriptId = useContext(TranscriptIdContext);
  const initialPosition = useContext(InitialTranscriptPositionContext);

  const query = useFetchTranscriptMedia({
    transcriptId,
  }, {
    enabled: !!transcriptId,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const isVideoUrl = useIsVideo(query.data?.url);

  const showVideo = useMemo(() => {
    if (typeof isVideoUrl !== 'boolean') return null;
    return !props.audioOnly && isVideoUrl;
  }, [props.audioOnly, isVideoUrl]);

  const isPlayerReady = useMemo(() => {
    return typeof isVideoUrl === 'boolean';
  }, [isVideoUrl]);

  const player = useMemo(() => {
    return showVideo ? videoPlayer : audioPlayer;
  }, [
    audioPlayer,
    videoPlayer,
    showVideo,
  ]);

  useEffect(() => {
    if (initialPosition && player && isPlayerReady) {
      player.currentTime = initialPosition;
    }
  }, [
    isPlayerReady,
    initialPosition,
    player,
  ]);

  const setMediaPlayer = useMemo(() => {
    return showVideo ? setVideoPlayer : setAudioPlayer;
  }, [showVideo, setAudioPlayer, setVideoPlayer]);

  const ctx = useMemo(() => ({
    captionsUrl: query.data?.captionsUrl,
    isVideo: showVideo,
    url: query.data?.url,
  }), [
    query.data?.captionsUrl,
    query.data?.url,
    showVideo,
  ]);

  return (
    <SetTranscriptMediaPlayerContext.Provider value={setMediaPlayer}>
      <TranscriptMediaContext.Provider value={ctx}>
        <TranscriptMediaPlayerContext.Provider value={player}>
          {props.children}
        </TranscriptMediaPlayerContext.Provider>
      </TranscriptMediaContext.Provider>
    </SetTranscriptMediaPlayerContext.Provider>
  );
};