import { useCallback } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder';
import { QuestionBuilderCancelContext } from './Context';
import { QuestionBuilderEditorContainer } from './EditorContainer';
import { QuestionBuilderSaveContainer } from './SaveContainer';

type Props =
  ChildrenProps;

export const QuestionBuilderContainer = (props: Props) => {

  const [_, dispatch] = useSurveyBuilderState();

  const handleCancel = useCallback(() => {
    dispatch({
      type: 'revert-uncommitted-changes',
    });
  }, [dispatch]);

  return (
    <QuestionBuilderCancelContext.Provider value={handleCancel}>
      <QuestionBuilderEditorContainer>
        <QuestionBuilderSaveContainer>
          {props.children}
        </QuestionBuilderSaveContainer>
      </QuestionBuilderEditorContainer>
    </QuestionBuilderCancelContext.Provider>
  );
};