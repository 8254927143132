import { useCallback, useMemo, useState } from 'react';
import { SurveyQuestionType } from '@enums';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { parseSurveyRichText } from '@/containers/SurveyBuilder/utils/rich-text';
import { GrayOutlineButton } from '@presentation/Buttons';
import type { SurveyQuestionMatrixRow, SurveySettings } from '@/types/survey';
import { useQuestionBuilderItemContext } from '@/containers/SurveyBuilder.Question';
import { QuestionDropdown, RowDropdown, OptionsDropdown, OperatorDropdown } from '@presentation/SurveyBuilder';
import { Button } from '@/components/Button';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import ModalHeader from '@/components/Modal/Header';
import { useModal } from '@/components/Modal/hooks';
import styles from './style/Settings.Total.Modal.css';

type Props = {
  initialValue: SurveySettings.TotalSetting<SurveySettings.PipedTotalValue>;
  onSubmit: (value: SurveySettings.TotalSetting<SurveySettings.PipedTotalValue>) => void;
} & Pick<ModalProps,
    'open' |
    'onClose'>;

export const TotalPipedAnswerModal = ({ initialValue, onClose, onSubmit, open }: Props) => {

  const [value, setValue] = useState<SurveySettings.PipedTotalValue>(initialValue.comparate);
  const [operator, setOperator] = useState<Operator>(initialValue.operator);

  const [state] = useSurveyBuilderState();
  const item = useQuestionBuilderItemContext();

  const canSubmit = useMemo(() => {
    return value?.question?.identifier && value?.row?.identifier && !!operator;
  }, [operator, value]);

  const handleSubmit = useCallback(() => {
    onSubmit({
      comparate: value,
      operator,
    });
    onClose();
  }, [operator, onClose, onSubmit, value]);

  const questionItems = useMemo(() => {
    return state.survey.questions.filter(f => {
      return [SurveyQuestionType.Sliders, SurveyQuestionType.NumberInputTable].includes(f.typeId) &&
        f.ordinal < item.ordinal;
    }).map(q => ({
      ...q,
      value: parseSurveyRichText(q.value, state.survey),
    }));
  }, [state.survey, item]);

  const selectedQuestion = useMemo(() => {
    return questionItems.find(f => f.base.identifier === value?.question?.identifier);
  }, [questionItems, value]);

  const rowItems = useMemo(() => {
    if (!selectedQuestion) return [];

    return selectedQuestion.matrixRows;
  }, [selectedQuestion]);

  const selectedRow = useMemo(() => {
    return (rowItems as SurveyQuestionMatrixRow[]).find(f => f.base.identifier === value?.row?.identifier);
  }, [rowItems, value]);

  const optionItems = useMemo(() => {
    if (!selectedQuestion || !selectedRow) return [];

    return selectedQuestion.options;
  }, [selectedRow, selectedQuestion]);

  const selectedOption = useMemo(() => {
    return optionItems.find(f => f.base.identifier === value?.option?.identifier);
  }, [optionItems, value]);

  const handleQuestionSelect = useCallback((identifier: string) => {
    setValue({
      question: { identifier },
      row: null,
    });
  }, []);

  const handleRowSelect = useCallback((identifier: string) => {
    setValue(s => ({
      ...s,
      row: { identifier },
    }));
  }, []);

  const handleOptionSelect = useCallback((identifier: string) => {
    setValue(s => ({
      ...s,
      option: { identifier },
    }));
  }, []);

  return (
    <Modal
      hideCloseIcon={true}
      className={styles.pipeModal}
      open={open}
      onClose={onClose}>

      <ModalHeader text="Required Sum" />

      <div className={styles.body}>
        <div className={styles.row}>
          <div className={styles.label}>Operator:</div>
          <div className={styles.operatorDropdown}>
            <OperatorDropdown
              value={operator}
              onSelect={setOperator} />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.label}>Question:</div>
          <div className={styles.questionDropdown}>
            <QuestionDropdown
              onSelect={handleQuestionSelect}
              items={questionItems}
              value={selectedQuestion} />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.label}>Value:</div>
          <div className={styles.rowDropdown}>
            <RowDropdown
              items={rowItems}
              onSelect={handleRowSelect}
              value={selectedRow} />
          </div>
          {!!optionItems.length &&
            <div className={styles.rowDropdown}>
              <OptionsDropdown
                items={optionItems}
                onSelect={handleOptionSelect}
                value={selectedOption} />
            </div>}
        </div>
      </div>

      <div className={styles.footer}>
        <GrayOutlineButton
          onClick={onClose}
          title="Cancel" />
        <Button.Primary
          className={styles.submit}
          disabled={!canSubmit}
          onClick={handleSubmit}
          title="Submit"
          variant="brick" />
      </div>

    </Modal>
  );
};

export const useTotalPipedAnswerModal = () => useModal(TotalPipedAnswerModal);