export const nonEmpty = <T>(data?: T[]) => {
  const isNonEmpty = Array.isArray(data) && data.length;

  return !!isNonEmpty;
};

export function shuffle<T>(items: T[]): T[] {
  const shuffled = new Array(...(items ?? []));

  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i);
    const temp = shuffled[i];
    shuffled[i] = shuffled[j];
    shuffled[j] = temp;
  }

  return shuffled;
}

export function chunk<T>(items: T[], chunkSize: number) {
  if (chunkSize < 1) throw new Error('ChunkSize must be positive');

  const chunked: T[][] = [];
  for (let i = 0; i < items.length; i += chunkSize) {
    chunked.push(items.slice(i, i + chunkSize));
  }
  return chunked;
}

export function groupBy<T, V = T>(
  items: T[],
  getKey: (item: T) => IndexType,
  getValue: (item: T) => V = (item: T) => item as unknown as V
): Record<IndexType, V[]> {
  if (!items) return {};

  return items.reduce<Record<IndexType, V[]>>((acc, item) => {
    const key = getKey(item);
    const value = getValue(item);
    acc[key] = acc[key] ? acc[key].concat(value) : [value];
    return acc;
  }, {});
}

export function indexBy<T, V = T>(
  items: T[],
  getKey: (item: T) => IndexType,
  getValue: (item: T) => V = (item: T) => item as unknown as V
): Record<IndexType, V> {
  if (!items) return {};

  return items.reduce<Record<IndexType, V>>((acc, item) => {
    const key = getKey(item);
    acc[key] = getValue(item);
    return acc;
  }, {});
}

type IndexType = string | number;

export function intersect<T>(arr1: T[], arr2: T[]): T[] {
  return arr1.filter(a => arr2.includes(a));
}

export function distinct<T extends number | string>(items: T[]): T[] {
  return Array.from(new Set(items));
}

export function range(size: number, startAt = 0): ReadonlyArray<number> {
  return [...Array(size).keys()].map(i => i + startAt);
}

export function areEqual<T>(arr1: T[], arr2: T[]) {
  return arr1.length === arr2.length
    ? [...arr1].sort().join('~') === [...arr2].sort().join('~')
    : false;
}