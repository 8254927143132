import { Remirror } from '@/types';
import { ConferenceDetailsEditor, ConferenceDetailsEditorContainer, useConferenceDetailsEditor } from '@/components/Conference';

type Props = {
  value: Remirror.RootNode;
};

export const AdditionalDetails = (props: Props) => {
  const { manager, state: details } = useConferenceDetailsEditor({
    initialState: props.value,
  });

  return (
    <ConferenceDetailsEditorContainer
      editable={false}
      manager={manager}
      state={details}>
      <ConferenceDetailsEditor />
    </ConferenceDetailsEditorContainer>
  );
};

AdditionalDetails.displayName = 'Confirmation.Details.AdditionalDetails';