import { UserTopic } from '@presentation/UserTopic';
import { JobFunction } from '@/types';
import styles from './style.css';

type Props = {
  items: JobFunction[];
  onClick?: (item: JobFunction) => unknown;
};

const ItemTopicTags = (props: Props) => {
  if (!props.items.length) return null;

  return (
    <UserTopic
      className={styles.topics}
      items={props.items}
      onClick={props.onClick} />
  );
};

export default ItemTopicTags;