import { useEffect } from 'react';
import { ReadyState } from '@containers/AppReadyState/interfaces';
import { useAppReadySubscription } from './useAppReadySubscription';

/**
 * @description - Accepts a function that is called when the
 * app's initial fetch completes.
 */
export const useHydrateEffect = (onInitialHydrate: () => unknown) => {
  const { off, on } = useAppReadySubscription();

  useEffect(() => {
    on(ReadyState.AppDataLoaded, onInitialHydrate);

    return () => {
      off(ReadyState.AppDataLoaded, onInitialHydrate);
    };
  }, [
    onInitialHydrate,
    off,
    on,
  ]);
};