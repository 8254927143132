import { useMemo, useRef, useState } from 'react';
import { EditorComponent } from '@remirror/react';
import { useTranscriptDocumentContext, useTranscriptToolsEnabled } from '@containers/Transcript/hooks';
import {
  TranscriptChainedCommandsContext,
  TranscriptHelpersContext,
  TranscriptEditorRefContext,
  TranscriptTimestampVisibilityContext,
  TagTimePickingContext,
} from './context';
import { ReassignSpeakerSelectionContainer } from './ReassignSpeakerSelection.Container';
import { TextReplaceContainer } from './TextReplace.Popper.Container';
import {
  useBlurCommentHighlight,
  useChainedCommands,
  useHelpers,
  useFocusHighlightEvent,
  useConfigureTranscriptHighlighting,
  useInitializeHighlights,
  useInitializeTimestampPositions,
  useMediaTimeChangeEvent,
  useSeekEvent,
  useConfidenceEvent,
  useFirstLoadSeek,
  useAutoCorrectEvent,
} from './hooks';
import { TranscriptSidebar } from './Sidebar';
import { HighlightTooltipContainer } from './Tooltip.Highlight.Container';
import { ConfidenceTooltipContainer } from './Tooltip.Confidence.Container';
import { AutoCorrectTooltipContainer } from './Tooltip.AutoCorrect.Container';
import { KaraokeMarker } from './KaraokeMarker';

type Props = unknown;

export const Editor = (props: Props) => {
  const commands = useChainedCommands();
  const helpers = useHelpers();
  const editorRef = useRef<HTMLDivElement>(null);
  const [pickingTime, setPickingTime] = useState(false);
  const toolsEnabled = useTranscriptToolsEnabled();
  const transcript = useTranscriptDocumentContext();

  const highlightEventsEnabled = useMemo(() => {
    return !pickingTime && toolsEnabled;
  }, [
    toolsEnabled,
    pickingTime,
  ]);

  useInitializeHighlights();
  useInitializeTimestampPositions();

  useFocusHighlightEvent(pickingTime);
  useConfigureTranscriptHighlighting({
    container: editorRef.current || undefined,
    enabled: highlightEventsEnabled,
  });
  useSeekEvent();
  useFirstLoadSeek();
  useConfidenceEvent();
  useMediaTimeChangeEvent();
  useBlurCommentHighlight();
  useAutoCorrectEvent();

  const tagTimPickingCtx = useMemo(() => ({ picking: pickingTime, setPicking: setPickingTime }), [pickingTime, setPickingTime]);

  const timestampVisibility = useMemo(() => {
    if (!transcript?.metadata) return true;

    return transcript.metadata.hasTimestamps;
  }, [transcript?.metadata]);

  return (
    <TranscriptEditorRefContext.Provider value={editorRef.current}>
      <TranscriptChainedCommandsContext.Provider value={commands}>
        <TranscriptHelpersContext.Provider value={helpers}>
          <TagTimePickingContext.Provider value={tagTimPickingCtx}>
            <TextReplaceContainer>
              <TranscriptTimestampVisibilityContext.Provider value={timestampVisibility}>
                <ReassignSpeakerSelectionContainer>
                  <div ref={editorRef}>
                    <EditorComponent />
                  </div>
                  <HighlightTooltipContainer />
                  <ConfidenceTooltipContainer />
                  <AutoCorrectTooltipContainer />
                  <KaraokeMarker />
                  <TranscriptSidebar />
                </ReassignSpeakerSelectionContainer>
              </TranscriptTimestampVisibilityContext.Provider>
            </TextReplaceContainer>
          </TagTimePickingContext.Provider>
        </TranscriptHelpersContext.Provider>
      </TranscriptChainedCommandsContext.Provider>
    </TranscriptEditorRefContext.Provider>
  );
};

Editor.displayName = 'Transcript.RichText.Editor';