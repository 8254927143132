import { useCallback, useMemo } from 'react';
import type { RankingQuestion } from '@/types/survey';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { SurveyPipedValueType } from '@/enums';
import { usePipingMenuState } from './context';
import { Menu, MenuItem } from './Editor.Piping.Menu';

type Props = {
  question: RankingQuestion.Question;
};

export const PipingOrdinalsMenu = ({ question }: Props) => {
  const [state] = useSurveyBuilderState();

  const { onPipingValueSelect } = usePipingMenuState();

  const handleItemClick = useCallback((value: number) => {
    onPipingValueSelect({
      value: {
        type: SurveyPipedValueType.RankedXOption,
        rank: value,
      },
      question: { identifier: question.base.identifier },
    });
  }, [onPipingValueSelect, question.base.identifier]);

  const renderItem = useCallback((option: RankingQuestion.Option, index: number) => {

    const value = index + 1;

    return (
      <MenuItem
        key={value}
        onClick={() => handleItemClick(value)}>
        {value}
      </MenuItem>
    );
  }, [handleItemClick]);

  return (
    <Menu>
      {question.options.map(renderItem)}
    </Menu>
  );
};