import type { OptionQuestionType, RowsQuestionType } from '@enums/Survey';
import { SurveyQuestionType } from '@enums/Survey';
import type {
  SurveyQuestion,
  ConjointAnalysisQuestion,
  MaxDifferenceQuestion,
  MultipleChoiceQuestion,
  MultiselectQuestion,
  MatrixGridQuestion,
  MatrixMultiselectQuestion,
  MatrixSliderQuestion,
  ShortTextResponseQuestion,
  LongTextResponseQuestion,
  TextResponseQuestion,
  RankingQuestion,
} from '@/types/survey';

export const isMultipleChoice = (data: SurveyQuestion): data is MultipleChoiceQuestion.Question => {
  return data.typeId === SurveyQuestionType.MultipleChoice;
};

export const isLongTextResponse = (data: SurveyQuestion): data is LongTextResponseQuestion.Question => {
  return data.typeId === SurveyQuestionType.LongTextResponse;
};

export const isShortTextResponse = (data: SurveyQuestion): data is ShortTextResponseQuestion.Question => {
  return data.typeId === SurveyQuestionType.ShortTextResponse;
};

export const isTextResponse = (data: SurveyQuestion): data is TextResponseQuestion.Question => {
  return isShortTextResponse(data) || isLongTextResponse(data);
};

export const isMultiSelect = (data: SurveyQuestion): data is MultiselectQuestion.Question => {
  return data.typeId === SurveyQuestionType.Multiselect;
};

export const isRanking = (data: SurveyQuestion): data is RankingQuestion.Question => {
  return data.typeId === SurveyQuestionType.Ranking;
};

export const isMatrixGridQuestion = (data: SurveyQuestion): data is MatrixGridQuestion.Question => {
  return data.typeId === SurveyQuestionType.MatrixGrid;
};

export const isMatrixSliderQuestion = (data: SurveyQuestion): data is MatrixSliderQuestion.Question => {
  return data.typeId === SurveyQuestionType.Sliders;
};

export const isMaxDifferenceQuestion = (data: SurveyQuestion): data is MaxDifferenceQuestion.Question => {
  return data.typeId === SurveyQuestionType.MaxDifference;
};

export const isConjointAnalysisQuestion = (data: SurveyQuestion): data is ConjointAnalysisQuestion.Question => {
  return data.typeId === SurveyQuestionType.ConjointAnalysis;
};

export const isSliderReversed = (settings: IsSliderReversed) => {
  return settings.slider.minValue > settings.slider.maxValue;
};

type IsSliderReversed = {
  slider: Pick<MatrixSliderQuestion.SliderSettings, 'minValue' | 'maxValue'>;
};

export type OptionsQuestion = SurveyQuestion<OptionQuestionType>;

type Question = Omit<SurveyQuestion, 'ordinal'>;

export const OptionQuestionTypes = [
  SurveyQuestionType.MatrixGrid,
  SurveyQuestionType.MatrixMultiselect,
  SurveyQuestionType.MaxDifference,
  SurveyQuestionType.Ranking,
  SurveyQuestionType.MultipleChoice,
  SurveyQuestionType.Multiselect,
  SurveyQuestionType.NumberInputTable,
  SurveyQuestionType.Dropdown,
  SurveyQuestionType.ExclusiveOptions,
  SurveyQuestionType.MatrixRange,
];

export function hasOptions(data: Question): data is OptionsQuestion {
  return OptionQuestionTypes.includes(data.typeId);
}

export const RowQuestionTypes = [
  SurveyQuestionType.MatrixGrid,
  SurveyQuestionType.MatrixMultiselect,
  SurveyQuestionType.Sliders,
  SurveyQuestionType.NumberInputTable,
  SurveyQuestionType.MatrixRange,
];

export type RowsQuestion = SurveyQuestion<RowsQuestionType>;

export function hasRows(data: Question): data is RowsQuestion {
  return RowQuestionTypes.includes(data.typeId);
}