import { useCallback } from 'react';
import { SurveyActionType } from '@enums';
import type { SurveyLogic, SurveyQuestionMatrixRow, SurveyQuestionOption } from '@/types';
import { SurveyMetadata } from '../Metadata';
import { SurveyConditions } from '../Logic.Conditions';
import { useActionsLogic } from './useActionsLogic';

export const useGetOptionDisplayLogic = (questionIdentifier: string) => {
  const actionsLogic = useActionsLogic(questionIdentifier);

  return useCallback((option: SurveyQuestionOption) => {

    const logicItems = actionsLogic.filter(({ action }) => {
      if (action.type === SurveyActionType.DisplayQuestionChoice ||
        action.type === SurveyActionType.ExcludeQuestionChoice) {
        return action.question.identifier === questionIdentifier &&
          action.option?.identifier === option.base.identifier;
      }

      return false;
    });

    return getLogicMetadata(logicItems);

  }, [actionsLogic, questionIdentifier]);
};

export const useGetRowDisplayLogic = (questionIdentifier: string) => {
  const actionsLogic = useActionsLogic(questionIdentifier);

  return useCallback((row: SurveyQuestionMatrixRow) => {

    const logicItems = actionsLogic.filter(({ action }) => {
      if (action.type === SurveyActionType.DisplayQuestionChoice ||
        action.type === SurveyActionType.ExcludeQuestionChoice) {
        return action.question.identifier === questionIdentifier &&
          action.row?.identifier === row.base.identifier;
      }

      return false;
    });

    return getLogicMetadata(logicItems);

  }, [actionsLogic, questionIdentifier]);
};

function getLogicMetadata(items: SurveyLogic.Item[]) {
  return items.map((m, i) => {

    const Item = (
      <>
        {m.action.type === SurveyActionType.DisplayQuestionChoice
          ? 'Display if '
          : 'Hide if '
        }
        <SurveyConditions
          rule={m.rule}
          conditions={m.conditions} />
      </>
    );

    return (
      <SurveyMetadata
        key={i}
        items={[Item]} />
    );
  });
}