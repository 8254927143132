import { memo, useCallback } from 'react';
import type { MultiselectQuestion } from '@/types/survey';
import {
  useGetOptionDisplayLogic,
  useGetOptionsSelectedLogic,
  useGetAdditionalOptionsLogic,
  useQuestionLogic,
  useQuestionClassifications,
  useGetOptionsSelectedTagging,
  useGetAdditionalOptionsTagging,
} from './hooks';
import { Options } from './Options';
import { OptionMetadata } from './Metadata';
import { SurveyQuestionLogic } from './Logic';
import { SurveyQuestionClassifications } from './Tagging';
import { SurveySettings } from './Settings';

type Props = {
  item: MultiselectQuestion.Question;
};

export const MultiselectPreview = ({ item }: Props) => {

  const questionLogic = useQuestionLogic(item.base.identifier);
  const questionClassifications = useQuestionClassifications(item.base.identifier);
  const getOptionsLogic = useGetOptionsSelectedLogic(questionLogic);
  const getOptionsTagging = useGetOptionsSelectedTagging(questionClassifications);
  const additionalLogic = useGetAdditionalOptionsLogic(questionLogic, item.base.identifier);
  const additionalTagging = useGetAdditionalOptionsTagging(questionClassifications);
  const getDisplayLogic = useGetOptionDisplayLogic(item.base.identifier);

  const renderExtras = useCallback((option: MultiselectQuestion.Option) => {
    const logicItems = getOptionsLogic(option);
    const taggingItems = getOptionsTagging(option);
    const dispalyLogicItems = getDisplayLogic(option);

    return (
      <>
        <OptionMetadata
          isAnchored={option.metadata.isAnchored}
          isExclusive={option.metadata.isExclusive}
          isOpenEnded={option.metadata.isOpenEnded} />
        {dispalyLogicItems}
        {logicItems}
        {taggingItems}
      </>
    );
  }, [getDisplayLogic, getOptionsLogic, getOptionsTagging]);

  return (
    <>
      <Options
        item={item}
        renderOptionExtras={renderExtras} />
      <Settings settings={item.settings} />
      <SurveyQuestionLogic items={additionalLogic} />
      <SurveyQuestionClassifications items={additionalTagging} />
    </>
  );
};

type SettingsProps = {
  settings: MultiselectQuestion.Settings;
};

const Settings = memo(({ settings }: SettingsProps) => {

  return (
    <SurveySettings>
      {settings.orderOptionsBasedOnSource && <>Order options based on source question</>}
      {settings.randomization && <>Randomize options</>}
      {(settings.minimumSelections !== null || settings.maximumSelections !== null) &&
        <>
          {[
            settings.minimumSelections !== null && `Min. ${settings.minimumSelections} selection(s)`,
            settings.maximumSelections !== null && `Max. ${settings.maximumSelections} selection(s)`,
          ].filter(Boolean).join(', ')}
        </>
      }
      {settings.rationale.enabled && <>Ask for rationale</>}
    </SurveySettings>
  );
});