import { AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as api from '@api';

type Data = unknown;
type Variables = {
  password: string;
};

export const useSubmitSurveyMonitoringAuth = (
  data: IProjectId,
  options?: UseMutationOptions<Data, AxiosResponse, Variables>
) => {

  return useMutation(['submit-survey-monitoring-auth'], (params: Variables) => {
    return api.projects.surveys.metrics.auth.post({
      password: params.password,
      projectId: data.projectId,
    });
  }, options);
};