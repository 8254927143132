import { useEffect, useState } from 'react';
import { useProjectOrLatestChild } from '@containers/GroupProject/hooks/useProjectState';
import { SurveyContext } from '@containers/Survey/Context';
import { ProjectSurveyResponsesLoadingContext, ProjectSurveyResponsesContext } from './Context';
import SurveyStarredAnswersContainer from './SurveyStarredAnswersContainer';
import { useFetchProjectSurveyResponses, useProjectSurveyResponsesState } from './hooks';
import SurveyRespondentContainer from './SurveyRespondentContainer';

export const MassSurveyResponsesDataContainer = (props: ChildrenProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const project = useProjectOrLatestChild();
  const { query } = useProjectSurveyResponsesState();

  const responses = useFetchProjectSurveyResponses({
    limit: 1,
    projectId: project.id,
    surveyId: query.surveyId,
    questionId: query.questionId,
  }, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setLoading(responses.isLoading || !responses.data);
  }, [
    responses.isLoading,
    responses.data,
  ]);

  return (
    <ProjectSurveyResponsesLoadingContext.Provider value={loading}>
      <ProjectSurveyResponsesContext.Provider value={responses.data?.items}>
        <SurveyContext.Provider value={responses.data?.survey || null}>
          <SurveyStarredAnswersContainer initial={responses.data?.starred || {}}>
            <SurveyRespondentContainer data={responses.data?.respondents || { values: {} }}>
              {props.children}
            </SurveyRespondentContainer>
          </SurveyStarredAnswersContainer>
        </SurveyContext.Provider>
      </ProjectSurveyResponsesContext.Provider>
    </ProjectSurveyResponsesLoadingContext.Provider>
  );
};

export default MassSurveyResponsesDataContainer;