import editorStyles from 'tui-image-editor/dist/tui-image-editor.css';
import colorStyles from 'tui-color-picker/dist/tui-color-picker.min.css';
import { useUseableCSS } from '@utils';
import type { ImageMarkupQuestion as IMQ } from '@/types';
import overrideStyles from 'static/css/useable/toast-image-editor-overrides.css';
import { useImageMarkupEditorEvents } from './hooks/useImageMarkupEditorEvents';
import { Toolbar } from './ImageMarkup.Toolbar';
import { VanillaEditor } from './ImageMarkup.Editor';

type Props = {
  className?: string;
  item: IMQ.FormQuestion;
};

export const ImageMarkupQuestion = ({ className, item }: Props) => {
  useUseableCSS(editorStyles, colorStyles, overrideStyles);

  useImageMarkupEditorEvents();

  return (
    <>
      <VanillaEditor />
      <Toolbar />
    </>
  );
};

export default ImageMarkupQuestion;