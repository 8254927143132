import { useCallback, memo } from 'react';
import { Helmet } from 'react-helmet';
import { MinusCircle, PlusCircle } from 'react-feather';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { email } from '@consts/vancery-info';
import { useToggle, cx } from '@utils';
import styles from './styles.css';

export const FAQ = () => {
  return (
    <div className={styles.root}>
      <Helmet title='Frequently Asked Questions - Sentiment' />
      <div className={styles.title}>
        Frequently Asked Questions
      </div>
      <div className={styles.divider} />
      <QuestionBlock
        question='What is Sentiment?'
        answer='Sentiment is a healthcare advisory research panel owned by Trinity Life Sciences. Originally founded in 1996 as Trinity Partners, Trinity has since grown to comprise a global team of advisors, technical experts, data integrators, and technology innovators. Our track record of consistent growth for over two decades has been fueled by our commitment to our clients, our passion for the life sciences, and our depth of expertise in the industry. Since the beginning, we have been evidence-forward in all our analyses and recommendations, focused on quality and accuracy first and foremost.' />
      <QuestionBlock
        question='Who can join Sentiment?'
        answer='Sentiment is currently comprised mostly of physicians, nurses, nurse practitioners, physician assistants, and pharmacists. We are actively seeking patients, practice managers, reimbursement specialists, healthcare executives, and other allied health professionals to join our growing healthcare research panel.' />
      <QuestionBlock
        question='I’m already a part of other research panels. What makes Sentiment different?'
        answer='Sentiment is not like other panel companies. We do not sell access to our panel to third parties – we only service our own research engagements. This means you’ll get a more personalized experience when working with us we’ll work tirelessly to be efficient with the time you give us. You’ll never have to worry about your inbox being flooded with underpaid survey opportunities or invitations for studies you don’t qualify for. We are a boutique, flexible panel that values quality over quantity.' />
      <QuestionBlock
        question='What types of studies do you offer?'
        answer='Sentiment offers a variety of research studies including online surveys, telephone interviews, web-assisted interviews, and online and in-person interviews and groups.' />
      <QuestionBlock
        question='How many invitations will I receive?'
        answer={`The frequency with which you are invited to participate in studies may vary. One thing we can promise is that we will never overwhelm you with invitations for studies you don't qualify for - our top priority is respecting your time and offering you opportunities that we feel are appropriate for your specialty and therapeutic area of expertise.`} />
      <QuestionBlock
        question='Am I expected to participate in every study I am invited to?'
        answer={`You are free to participate as little or as often as you wish. We hope you will find our opportunities engaging and thought-provoking and will want to participate on a regular basis, however it's entirely up to you.`} />
      <QuestionBlock
        question='How much will I earn on each study?'
        answer='Honorarium amounts will vary based on several factors but will always be competitive and in line with Fair Market Value.' />
      <QuestionBlock
        question='How will I be paid?'
        answer='Sentiment offers fast and flexible payment options, including direct to debit, virtual and physical gift cards, or standard check.' />
      <QuestionBlock
        question='Can I donate my compensation to another person or a cause I support?'
        answer='At this time, payments may only be made directly to you and in your name.' />
      <QuestionBlock
        question='Who do I contact if I need help participating in a study or have a question about a payment?'
        answer={`For questions or support please contact our helpful team at ${email}.`} />
      <QuestionBlock
        question='Can I forward study invitations to colleagues or friends who may be interested?'
        answer='Our invitations are only intended to be accessed by you, the recipient. If you have a colleague interested in participating in research opportunities, we invite you to suggest they register with our panel by visiting this website www.sentimentglobal.com to sign up.' />
      <QuestionBlock
        question='Are my responses anonymous?'
        answer='Yes, all responses are anonymous unless explicitly stated and agreed to by all parties ahead of time. Quantitative survey responses are reported in the aggregate. The privacy of our members is paramount, and we would never share your information with a third party without your express permission.' />
      <QuestionBlock
        question='What is your privacy policy?'
        answer='You can read our privacy policy by clicking on the following link: https://sentimentglobal.com/privacy' />
      <QuestionBlock
        question='How can I update my profile?'
        answer='Once you’ve logged into your account, please proceed to the “My Account” page and click on “Edit Profile”.' />
      <QuestionBlock
        question='How can I unsubscribe from future study invitations?'
        answer={`To unsubscribe from all future study invitations, please contact our team at ${email} for assistance. If you would like to discontinue receiving only phone calls or texts, we can assist with that as well.`} />
    </div>
  );
};

type Props = {
  question: string;
  answer: string;
};

const IconSize = 30;

const QuestionBlock = memo(({ question, answer }: Props) => {
  const [isOpen, toggle] = useToggle(false);

  const Icon = useCallback(() => {
    if (isOpen) {
      return <MinusCircle size={IconSize} />;
    } else {
      return <PlusCircle size={IconSize} />;
    }
  }, [isOpen]);

  return (
    <Accordion expanded={isOpen} className={styles.block}>
      <AccordionSummary onClick={toggle}>
        <div className={styles.question}>
          {question}
          <div className={styles.toggleIcon}>
            {isOpen}
            <Icon />
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className={cx(styles.answer, !isOpen ? styles.hidden : null)}>
          {answer}
        </div>
      </AccordionDetails>
    </Accordion>
  );
});