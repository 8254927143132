import { memo } from 'react';
import { Link } from 'react-router-dom';
import { LocationDescriptor } from 'history';
import { Node } from 'slate';
import { cx } from '@utils';
import { Activity } from '@/types';
import styles from './style/PostPreview.Text.css';

type Props = {
  className?: string;
  item:       Activity.Item.Post;
  to:         LocationDescriptor;
};

export const Text = memo(({ className, item, to }: Props) => {

  const description = item.body.map(node => Node.string(node)).join('\n');

  const displayURLPreview = !item.media &&
        description.length === 0 &&
        item.preview?.id;

  if (!displayURLPreview) {
    return (
      <Basic
        className={className}
        description={description}
        to={to} />
    );
  }

  return (
    <ArticlePreview
      className={className}
      item={item} />
  );
});

Text.displayName = 'Posts.PostPreview.Text';

type BasicProps = {
  description: string;
} & Omit<Props, 'item'>;

const Basic = (props: BasicProps) => {
  return (
    <div className={cx(styles.root, props.className)}>
      <Link to={props.to}>
        <div className={styles.body}>
          <div className={styles.clamped}>
            {props.description}
          </div>
        </div>
      </Link>
    </div>
  );
};

Basic.displayName = 'Posts.PostPreview.Text.Basic';

const ArticlePreview = (props: Omit<Props, 'to'>) => {
  const displayImage = props.item.preview.image?.url;
  const url = props.item.preview.url.startsWith('http')
    ? new URL(props.item.preview.url)
    : { pathname: props.item.preview.url };

  const classnames = {
    root: cx(styles.root, props.className, {
      [styles.noimage]: !displayImage,
    }),
  };

  return (
    <div className={classnames.root}>
      <Link to={url.pathname}>
        {displayImage &&
          <div
            className={styles.image}
            style={{ backgroundImage: `url(${props.item.preview.image.url})` }} />}
        <div className={styles.info}>
          <div className={styles.details}>
            <div className={styles.title}>{props.item.preview.title}</div>
            <div className={styles.description}>{props.item.preview.description}</div>
          </div>
        </div>
      </Link>
    </div>
  );
};

ArticlePreview.displayName = 'Posts.PostPreview.Text.ArticlePreview';