import { useContext, useMemo } from 'react';
import { MedicalBackgroundAdditionalExpertiseContext } from '@containers/MedicalOnboarding/Context';

export const useBackgroundAdditionalExpertiseValidation = () => {
  const additionalexpertise = useContext(MedicalBackgroundAdditionalExpertiseContext);

  const validation = useMemo(() => {
    const errors = {
      boardEligibility: !additionalexpertise.form.boardEligibility.answer,
      therapeuticAreas: !(additionalexpertise.form.therapeuticAreas.map(x => x.id).length === 3),
    };

    return {
      errors,
    };
  }, [additionalexpertise.form]);

  return validation;
};