import { useCallback } from 'react';
import { useSurveyBuilderState } from './useSurveyBuilderContext';

export const useHasUnsavedChanges = () => {
  const [state] = useSurveyBuilderState();

  const hasUnsavedChanges = useCallback(() => {
    return JSON.stringify(state.survey) !== JSON.stringify(state.savedSurvey);
  }, [
    state.savedSurvey,
    state.survey,
  ]);

  return hasUnsavedChanges;
};

export default useHasUnsavedChanges;