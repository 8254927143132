import { useSelector } from 'react-redux';
import { hasConsultantRole } from '@utils';

const mapState = (state: Store.State) => ({
  pipeline: state.pipeline,
  me: state.user,
});

const useSelectPipeline = <T extends Store.Pipeline.Me |  Store.Pipeline.Project>() => {
  const state = useSelector(mapState);

  if (!state.me.roles.length) return {} as T;

  return hasConsultantRole({ roles: state.me.roles })
    ? state.pipeline.me as T
    : state.pipeline.project as T;
};

export { useSelectPipeline };
export default useSelectPipeline;