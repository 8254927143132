import { useCallback, useMemo } from 'react';
import { createId } from '@paralleldrive/cuid2';
import type { Chat } from '@/brand-insights/types';
import { RegenerateMessageContext } from './context';
import { useRegenerateQueryMutation } from './hooks';

type Props = {
  children: React.ReactNode;
};

export const RegenerateMessageContainer = (props: Props) => {
  const { isLoading, mutateAsync } = useRegenerateQueryMutation();

  const handleRegenerate =  useCallback((query: Chat.Query) => {
    const identifier = createId();
    return mutateAsync({ identifier, sourceQuery: query });
  }, [mutateAsync]);

  const ctx = useMemo(() => ({
    canRegenerate: true,
    isRegenerating: isLoading,
    onRegenerate: handleRegenerate,
  }), [
    isLoading,
    handleRegenerate,
  ]);

  return (
    <RegenerateMessageContext.Provider value={ctx}>
      {props.children}
    </RegenerateMessageContext.Provider>
  );
};