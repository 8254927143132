import ActivityIndicator from '@/components/ActivityIndicator';
import styles from './style.css';

type Props = {
  visible: boolean;
};

const LoadingMoreIndicator = (props: Props) => {
  if (!props.visible) return null;

  return (
    <div className={styles.loading}>
      <ActivityIndicator show={true} />
    </div>
  );
};

export { LoadingMoreIndicator };
export default LoadingMoreIndicator;