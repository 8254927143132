import { useContext, useEffect } from 'react';
import { BookingSegmentContext } from './Context';
import { Custom, FromAvailability } from './Schedule';

type Props = unknown;

export const Schedule = (props: Props) => {
  const [segment, setSegment] = useContext(BookingSegmentContext);

  useEffect(() => {

    return () => setSegment(null);

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return segment?.start
    ? <FromAvailability />
    : <Custom />;
};

Schedule.displayName = 'Booking.Schedule';