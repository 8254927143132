import { useCallback, ChangeEvent } from 'react';
import { Input } from '@/components/Input';

type Props = {
  onChange: (value: string) => void;
  value: string;
};

export const TemplateTreatmentCategoryInput = ({ onChange, value }: Props) => {

  const placeholder = `Enter a target drug treatment category.`;

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  }, [onChange]);

  return (
    <Input
      // className={styles.input}
      placeholder={placeholder}
      onChange={handleChange}
      value={value} />
  );
};