import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as QK from '@consts/querykey.admin';
import * as utils from '@/components/Conference.Participants/utils';
import * as api from '$admin/api';
import { ParamsContext, ParticipantsContext, ReadyStateContext } from './Context';
import type { ParticipantItem } from './interfaces';

type Props = {
  children: React.ReactNode;
};

export const Participants = (props: Props) => {
  const ctx = {
    ready: useContext(ReadyStateContext),
    scheduling: useContext(ParamsContext),
  };
  const [items, setItems] = useState<ParticipantItem[]>([]);

  const query = useQuery(QK.Projects.Scheduling.Participants.Get({
    callId: ctx.scheduling.params.callId,
    projectId: ctx.scheduling.params.projectId,
    userId: ctx.scheduling.params.userId,
  }), () => {

    const params = ctx.scheduling.params.callId
        ? { callId: ctx.scheduling.params.callId }
        : ctx.scheduling.params.userId
          ? { respondentId: ctx.scheduling.params.userId }
          : {};

    return api.projects.scheduling.getCallParticipants({
      ...params,
      projectId: ctx.scheduling.params.projectId,
    });

  }, {
    enabled: ctx.ready,
    onSuccess: res => {
      const items = [...res.items].sort(utils.sortParticipants);

      setItems(items);
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {

    return () => {
      query.remove();
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const value = {
    items,
    query,
    setValue: setItems,
  };

  return (
    <ParticipantsContext.Provider value={value}>
      {props.children}
    </ParticipantsContext.Provider>
  );
};

Participants.displayName = 'Call.Scheduling.Participants';