import { memo } from 'react';
import styled from '@emotion/styled';
import { Star } from 'react-feather';

type Props = {
  className?: string;
  onClick: () => void;
  value: boolean;
};

const StarToggle = memo(({
  className,
  onClick,
}: Props) => {

  return (
    <Star
      className={className}
      onClick={onClick} />
  );
});

const StyledStarToggle = styled(StarToggle)(({ theme, value }) => ({
  cursor: 'pointer',
  strokeWidth: 1,

  color: value ? 'gold' : theme.palette.gray.light1,
  fill: value ? 'gold' : undefined,

  '&:hover': {
    color: 'gold',
    fill: value ? 'gold' : '#FFF6C9',
  },
}));

export { StyledStarToggle as StarToggle };