import { useCallback } from 'react';
import css from 'intl-tel-input/build/css/intlTelInput.css';
import { useUseableCSS } from '@utils';
import type { FocusableClasses } from '@/components/Focusable/interfaces';
import { TelInput } from '@/components/TelInput/TelInput';
import overrides from 'static/css/useable/intl-tel-input.css';
import type { TelInputProps, TelInputState } from './interfaces';

type Props = {
  className?:    string;
  classes?: {
    focusable?: FocusableClasses;
    input?:     string;
    invalid?:   string;
    root?:      string;
  };
  invalid?:     boolean;
  onChange:      (data: TelInputState) => void;
  value:         string;
  id?:           string;
} & Pick<TelInputProps,
  | 'name'>;

const PhoneInputControlled = ({
  classes = {},
  id = 'phone',
  invalid = false,
  onChange,
  value,
  ...props
}: Props) => {
  useUseableCSS(css, overrides);

  const handleChangeCountryCode = useCallback((data: TelInputState) => {
    onChange(data);
  }, [onChange]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, data: TelInputState) => {
    onChange(data);
  }, [onChange]);

  return (
    <TelInput
      classes={classes}
      id={id}
      name={props.name}
      onChange={handleChange}
      onChangeCountryCode={handleChangeCountryCode}
      invalid={invalid}
      value={value} />
  );
};

export { PhoneInputControlled };
export default PhoneInputControlled;