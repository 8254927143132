import { useCallback } from 'react';
import { useTargetProductProfileData } from '@containers/SurveyBuilder/hooks';
import { SurveyTemplate } from '@/types/survey.templates';
import { useCanSaveList, useListElements } from './list-elements';

const minimum = 1;
const maximum = 10;

export const useCanSaveDoseAdministration = (items: SurveyTemplate.LinkedEntity[]) => {

  return useCanSaveList({
    items,
    minItems: minimum,
    maxItems: maximum,
  });
};

export const useTemplateDoseAdministration = () => {
  const [templateData, dispatch] = useTargetProductProfileData();

  const {
    canAdd: canAddDoseAdministration,
    canRemove: canRemoveDoseAdministration,
    items,
    onAddItem: onAddDoseAdministration,
    onRemoveItem: onRemoveDoseAdministration,
    onUpdateItem: onUpdateDoseAdministration,
  } = useListElements({
    min: minimum,
    max: maximum,
    initial: templateData.doseAdministration,
  });

  const onSubmit = useCallback(() => {
    dispatch({
      type: 'template-dose-administration-updated',
      payload: {
        value: items,
      },
    });
  }, [dispatch, items]);

  return {
    canAddDoseAdministration,
    canRemoveDoseAdministration,
    onAddDoseAdministration,
    onRemoveDoseAdministration,
    onUpdateDoseAdministration,
    onSubmit,
    items,
  };
};