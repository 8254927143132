import { useCallback, useContext, forwardRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { ProjectActionsMenuContext } from './Context';
import { Archive, DownloadData, ManageAccess, MoveToFolder, Publish, Timeline, Unarchive } from './Item';
import { Item } from './interfaces';

type Props = {
  onManageAccess?: Item.OnClick;
  showManageAccess?: boolean;
  onActivate?: Item.OnClick;
  showActivate?: boolean;
  onArchive?: Item.OnClick;
  showArchive?: boolean;
  onMoveToFolder?: Item.OnClick;
  showMoveToFolder?: boolean;
  onDownloadData?: Item.OnClick;
  showDownloadData?: boolean;
  onMonitorSurvey?: Item.OnClick;
  showMonitorSurvey?: boolean;
  onPublish?: Item.OnClick;
  showPublish?: boolean;
};

export const Details = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { close } = useContext(ProjectActionsMenuContext);

  const closer = useCallback((handler: Item.OnClick) => () => {
    close();
    handler();
  }, [close]);

  return (
    <div ref={ref}>
      {props.showManageAccess && (
        <MenuItem
          disableRipple
          disabled={!props.onManageAccess}
          onClick={closer(props.onManageAccess)}>
          <ManageAccess />
        </MenuItem>
      )}
      {props.showArchive && (
        <MenuItem
          disableRipple
          disabled={!props.onArchive}
          onClick={closer(props.onArchive)}>
          <Archive />
        </MenuItem>
      )}
      {props.showActivate && (
        <MenuItem
          disableRipple
          disabled={!props.onActivate}
          onClick={closer(props.onActivate)}>
          <Unarchive title="Activate" />
        </MenuItem>
      )}
      {props.showPublish && (
        <MenuItem
          disableRipple
          onClick={closer(props.onPublish)}>
          <Publish title="Publish Draft" />
        </MenuItem>
      )}
      {props.showMoveToFolder && (
        <MenuItem
          disableRipple
          disabled={!props.onMoveToFolder}
          onClick={closer(props.onMoveToFolder)}>
          <MoveToFolder />
        </MenuItem>
      )}
      {props.showDownloadData && (
        <MenuItem
          disableRipple
          disabled={!props.onDownloadData}
          onClick={closer(props.onDownloadData)}>
          <DownloadData />
        </MenuItem>
      )}
      {props.showMonitorSurvey && (
        <MenuItem
          disableRipple
          disabled={!props.onMonitorSurvey}
          onClick={closer(props.onMonitorSurvey)}>
          <Timeline title="Survey Monitor" />
        </MenuItem>
      )}
    </div>
  );
});