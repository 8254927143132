import { forwardRef, useCallback, useMemo, useState } from 'react';
import {
  useHelpers,
  useUpdateHighlightColor,
} from './hooks';
import { highlightColors } from './utils';
import styles from './style/Tooltip.Highlight.css';

type Props = {
  arrow?: {
    className: string;
    ref: React.Ref<HTMLDivElement>;
  };
  config?: {
    colors: boolean;
    commenting: boolean;
    copy: boolean;
    redaction: boolean;
    replace: boolean;
    speaker: boolean;
    tagging: boolean;
  };
};

export const HighlightTooltip = forwardRef<HTMLDivElement, Props>(({ arrow, ...props }, ref) => {

  const [hoveredColor, setHoveredColor] = useState<string>(null);

  const { getFocusedHighlight } = useHelpers();
  const highlight = getFocusedHighlight();

  const {
    mutateAsync: saveColor,
  } = useUpdateHighlightColor();

  const handleColorClick = useCallback((color: string) => () => {

    return saveColor({
      highlight: {
        ...highlight,
        color,
      },
      previousColor: highlight.color,
    });
  }, [
    highlight,
    saveColor,
  ]);

  const config = useMemo(() => {
    return !props.config
      ? DefaultConfig
      : { ...DefaultConfig, ...props.config };
  }, [props.config]);

  const display = useMemo(() => {
    const colors = config.colors;
    const commenting = config.commenting;
    const copy = config.copy;
    const redaction = config.redaction;
    const replace = config.replace;
    const speaker = config.speaker;
    const tagging = config.tagging;
    const unhighlight = config.colors && highlight?.dbId;

    const divider = colors && [
      commenting,
      copy,
      redaction,
      replace,
      speaker,
      tagging,
      unhighlight,
    ].some(Boolean);

    return {
      colors,
      commenting,
      copy,
      divider,
      redaction,
      replace,
      speaker,
      tagging,
      unhighlight,
    };
  }, [
    config,
    highlight?.dbId,
  ]);

  const unsetHoveredColor = () => setHoveredColor(null);
  const getSetHoveredColor = (color: string) => () => setHoveredColor(color);

  return (
    <div
      ref={ref}
      className={styles.tooltip}>
      {arrow &&
        <div
          ref={arrow.ref}
          className={arrow.className} />}
      <div className={styles.wrap}>
        {display.colors &&
          <div className={styles.colors}>
            {highlightColors.map(color => (
              <div
                key={color.highlight}
                onMouseEnter={getSetHoveredColor(color.highlight)}
                onMouseLeave={unsetHoveredColor}
                onClick={handleColorClick(color.highlight)}
                style={{
                  backgroundColor: hoveredColor === color.highlight
                    ? color.hover
                    : color.menu,
                }}
                className={styles.color} />
            ))}
          </div>}
      </div>
    </div>
  );
});

export default HighlightTooltip;

const DefaultConfig: Props['config'] = {
  colors: true,
  commenting: true,
  copy: true,
  redaction: true,
  replace: true,
  speaker: true,
  tagging: true,
};