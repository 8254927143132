import { useCallback, useEffect, useRef, useReducer } from 'react';
import {
  useSearchFetchAll,
  useSearchFetchMembers,
  useSearchFetchFiles,
  useSearchFetchFolders,
  useSearchFetchTranscripts,
  useSearchFetchProjects,
  useSearchReset,
  useSearchFetchBrands,
} from '@/containers/WorkspaceGlobalSearch/hooks';
import { useSearchFiltersQuery } from '@/containers/WorkspaceGlobalSearch/hooks/useSearchFilterQuery';
import { ReducedFiltersContext } from './Context';
import SearchTabViewContainer from './Search.Tab.ViewContainer';
import { SearchMainFilters } from './Search.Main.Filters';
import * as FilterState from './Search.Main.Filters.state';
import type { IMainSearchFilters } from './interfaces';
import styles from './style/Main.css';

export default function SearchMain() {
  const reset = useSearchReset();
  const fetchAll = useSearchFetchAll();
  const fetchBrands = useSearchFetchBrands();
  const fetchProjects = useSearchFetchProjects();
  const fetchMembers = useSearchFetchMembers();
  const fetchFiles = useSearchFetchFiles();
  const fetchFolders = useSearchFetchFolders();
  const fetchTranscripts = useSearchFetchTranscripts();

  const [query] = useSearchFiltersQuery();
  const [filters, dispatch] = useReducer(FilterState.reducer, FilterState.getInitialState(query));
  const initialized = useRef<number>(0);

  const fetcher = useCallback(({ filters: { tab, keywords, filetypes, tags } }: FetcherData) => {
    const filters = {
      keywords,
      filetypes,
      tags,
    };

    switch (tab) {
      case 'all': fetchAll({ filters }); break;
      case 'brands': fetchBrands({ filters, size: 50 }); break;
      case 'projects': fetchProjects({ filters, size: 50 }); break;
      case 'members': fetchMembers({ filters, size: 50 }); break;
      case 'files': fetchFiles({ filters, size: 50 }); break;
      case 'folders': fetchFolders({ filters, size: 50 }); break;
      case 'transcripts': fetchTranscripts({ filters, size: 50 }); break;
    }

  }, [fetchAll, fetchBrands, fetchProjects, fetchMembers, fetchFiles, fetchFolders, fetchTranscripts]);

  useEffect(() => {
    dispatch({
      type: 'query-update',
      value: {
        tab: query.tab,
        keywords: query.keywords,
        filetypes: query.filetypes,
        tags: query.tags,
      },
    });
  }, [
    query.tab,
    query.keywords,
    query.filetypes,
    query.tags,
  ]);

  useEffect(() => {
    if (initialized.current < 1) { // skip first load
      initialized.current++;
      return;
    }
    reset(filters.tab);
    fetcher({ filters });
  }, [reset, fetcher, filters]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <ReducedFiltersContext.Provider value={filters}>
          <div className={styles.filters}>
            <SearchMainFilters />
          </div>
          <div className={styles.main}>
            <SearchTabViewContainer />
          </div>
        </ReducedFiltersContext.Provider>
      </div>
    </div>
  );
}

export { SearchMain };

type FetcherData = {
  filters: IMainSearchFilters.State;
};