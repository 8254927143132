import { path } from '@consts';
import { RouteAuth } from '@enums';
import { BasicRouter } from '@routes/Routers';
import * as Routing from '@routes/interfaces';
import { UserProfile, ProfileNotFound } from '@screens/UserProfile';
import { UserProfilePosts } from '@screens/UserProfilePosts';

export const routes: Routing.Route[] = [
  {
    exact: true,
    path: path.Profile.NotFound,
    component: ProfileNotFound,
  },
  {
    exact: true,
    path: [
      path.Profile.Tabs.Param,
      path.Profile.User,
    ],
    component: UserProfile,
  },
  {
    exact: true,
    path: path.Profile.UserPosts,
    component: UserProfilePosts,
  },
];

export const router = BasicRouter(routes, {
  auth: RouteAuth.Ignore,
  path: path.Profile.Root,
});