import * as types from './action-types';

export const updateSearchKeyword = data => {
  return { type: types.KEYWORD_SEARCH_UPDATE, ...data };
};

export const resetFilters = data => {
  return { type: types.RESET_SEARCH_FILTERS, ...data };
};

export const updateSort = data => {
  return { type: types.UPDATE_SEARCH_SORT, ...data };
};

export const setFilters = data => {
  return { type: types.SET_SEARCH_FILTERS, ...data };
};

export const applyAdvancedFilters = () => {
  return { type: types.APPLY_ADVANCED_FILTERS };
};

export const addToAdvancedFilterList = data => {
  return { type: types.ADD_TO_ADVANCED_FILTER_LIST, ...data };
};

export const removeFromAdvancedFilterList = data => {
  return { type: types.REMOVE_FROM_ADVANCED_FILTER_LIST, ...data };
};

export const setPriceRange = data => {
  return { type: types.SET_PRICE_RANGE, ...data };
};

export const setAdvancedFilterList = data => {
  return { type: types.SET_ADVANCED_FILTER_LIST, ...data };
};

export const batchActions = actions => {
  return { type: types.BATCH_ACTIONS, actions };
};