import type { CellProps } from 'react-table';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import type { Projects } from '@api/interfaces/projects';
import { ProjectActionsState } from '@containers/GroupProject/ProjectActionsContainer';
import { getLocationFor } from '@utils';
import { MoreHorizontalAnchorSmall } from '@presentation/Anchor';
import { ProjectAction } from '@/enums';
import { ProjectActionsContextMenuPopper, ProjectPopoverAnchor, ProjectProgressBar, ProjectsTableItem } from '@/components/GroupProject';
import { ProjectIcon } from '@/components/icons';
import styles from './style/ProjectsTable.css';

function NameCell({ row: { original: data } }: CellProps<Projects.FetchProjects.ProjectItem>) {
  const to = getLocationFor.project.root({
    slug: data.slug ?? String(data.id),
  });

  return (
    <div className={styles.name}>
      <ProjectIcon
        className={styles.icon}
        projectType={data.projectType} />
      <ProjectPopoverAnchor className={styles.anchor} item={data}>
        <Link className={styles.link} to={to}>
          <div className={styles.trunc}>{data.name}</div>
        </Link>
      </ProjectPopoverAnchor>
    </div>
  );

}

function OwnerCell({ row: { original: data } }: CellProps<Projects.FetchProjects.ProjectItem>) {
  return (
    <div className={styles.name}>
      <div className={styles.truc}>
        {typeof data.owner === 'object' ? data.owner.name : data.owner}
      </div>
    </div>
  );
}

function CreatedOnCell({ row: { original: data } }: CellProps<Projects.FetchProjects.ProjectItem>) {
  return format(data.createdOn, 'MM/dd/yyyy');
}

function ProgressCell({ row: { original: data } }: CellProps<Projects.FetchProjects.ProjectItem>) {
  return (
    <div className={styles.progress}>
      {data.metrics &&
        <ProjectProgressBar
          metrics={data.metrics}
          project={data} />}
    </div>
  );
}

function ActionsCell({ row: { original: data } }: CellProps<Projects.FetchProjects.ProjectItem>) {
  const { id, isExternal, name, projectStatusId, projectType } = data;

  const project = {
    id,
    isExternal,
    name,
    parentId: data.parent?.id,
    projectStatusId,
    projectType,
  };

  return (
    <ProjectActionsState
      capabilities={data.capabilities}
      parent={data.parent}
      object={data.object}
      project={project}>
      <ProjectActionsContextMenuPopper
        excluded={[ProjectAction.UploadQuantAnalysisFile]}
        Anchor={MoreHorizontalAnchorSmall} />
    </ProjectActionsState>
  );
}

export const Cells = {
  Actions: ActionsCell,
  CreatedOn: CreatedOnCell,
  Name: NameCell,
  Owner: OwnerCell,
  Progress: ProgressCell,
};