import type { Dispatch } from 'react';
import { useContext } from 'react';
import { createNamedContext } from '@utils';
import type { SurveyLogic } from '@/types/survey';
import type { SurveyConditionType, SurveyQuestionConditionType } from '@/enums';
import type { LogicItemBuilder, LogicItemValidations, SurveyMatchValidation, SurveyMatchBuilder } from './interfaces';
import { ConditionsBuilder } from './interfaces';

export type SurveyLogicItemStateContextValue =
  [LogicItemBuilder.State, Dispatch<LogicItemBuilder.Action>];

type SurveyConditionsMatchState =
  [SurveyMatchBuilder.State, Dispatch<SurveyMatchBuilder.Action>];

export type SurveyMatchContextValue =
  SurveyMatchBuilder.State;

export type SurveyConditionsBuilderContextValue = {
  canAddItem: boolean;
  canRemoveItem: boolean;

  items: SurveyLogic.SurveyCondition[];

  addItem: (conditionType: SurveyConditionType) => void;
  removeItem: (identifier: string) => () => void;
};

export type RemoveSurveyConditionContextValue = {
  canRemove: boolean;
  removeItem: () => void;
};

export type QuestionConditionBuilderContextValue = {
  item: SurveyLogic.QuestionCondition;
  updateQuestion: (questionIdentifier: string) => void;
  updateConditionType: (value: SurveyQuestionConditionType) => void;
  updateConditionValue: (value: SurveyLogic.QuestionConditionData['value']) => void;
};

export type SumConditionBuilderContextValue = {
  item: SurveyLogic.SumCondition;
  updateItems: (items: SurveyLogic.SumConditionItem[]) => void;
  updateComparate: (value: number) => void;
  updateOperator: (value: Operator) => void;
};

export type ClassificationBuilderContextValue = {
  item: SurveyLogic.ClassificationCondition;
  updateClassification: (value: string) => void;
};

export type RunSurveyLogicValidations = () => boolean;

export type SurveyLogicBuilderContextValue = {
  display: {
    hideActions: boolean;
  };
  items: SurveyLogic.Item[];
  editing: {
    identifier: string;
    isNew: boolean;
  };
  handleSubmit?: () => void;
  addItem: () => void;
  cloneItem: (identifier: string) => void;
  removeItem: (identifier: string) => void;
  saveItem: (item: SurveyLogic.Item) => void;
  toggleEditingOn: (identifier: string) => void;
  toggleEditingOff: (identifier: string) => void;
};

export type LogicItemSaveContextValue = {
  canSave: boolean;
  handleCancel: () => void;
  handleSubmit: () => void;
};

export const SurveyLogicBuilderContext = createNamedContext<SurveyLogicBuilderContextValue>(null, 'SurveyLogicBuilderContext');
export const RemoveSurveyConditionContext = createNamedContext<RemoveSurveyConditionContextValue>(null, 'RemoveSurveyConditionContext');
export const QuestionConditionBuilderContext = createNamedContext<QuestionConditionBuilderContextValue>(null, 'QuestionConditionBuilderContext');
export const SumConditionBuilderContext = createNamedContext<SumConditionBuilderContextValue>(null, 'SumConditionBuilderContext');
export const ClassificationBuilderContext = createNamedContext<ClassificationBuilderContextValue>(null, 'ClassificationBuilderContext');

export const SurveyConditionsMatchContext = createNamedContext<SurveyConditionsMatchState>(null, 'SurveyConditionsStateContext');

export const SurveyLogicItemStateContext = createNamedContext<SurveyLogicItemStateContextValue>(null, 'SurveyLogicItemStateContext');
export const SurveyConditionsBuilderContext = createNamedContext<SurveyConditionsBuilderContextValue>(null, 'SurveyConditionsBuilderContext');

export const SurveyLogicItemValidationsContext = createNamedContext<LogicItemValidations>(null, 'SurveyLogicItemValidationsContext');
export const SurveyConditionsMatchValidationsContext = createNamedContext<SurveyMatchValidation>(null, 'SurveyConditionsMatchValidationsContext');
export const RunSurveyLogicValidationsContext = createNamedContext<RunSurveyLogicValidations>(null, 'RunSurveyLogicValidationsContext');

export const SurveyMatchContext = createNamedContext<SurveyMatchContextValue>(null, 'SurveyMatchContext');
export const SurveyLogicItemSaveContext = createNamedContext<LogicItemSaveContextValue>(null, 'SurveyLogicItemSaveContext');

export const useSurveyLogicBuilderContext = () => useContext(SurveyLogicBuilderContext);
export const useSurveyLogicBuilderDisplaySettings = () => useSurveyLogicBuilderContext().display;

export const useSurveyLogicItemState = () => useContext(SurveyLogicItemStateContext);
export const useSurveyConditionsBuilder = () => useContext(SurveyConditionsBuilderContext);

export const useSurveyConditionsMatchState = () => useContext(SurveyConditionsMatchContext);

export const useSurveyConditionsState = () => {
  const [state, dispatch] = useSurveyConditionsMatchState();
  return [state.conditions, dispatch] as const;
};
export const useSurveyRuleState = () => {
  const [state, dispatch] = useSurveyConditionsMatchState();
  return [state.rule, dispatch] as const;
};

export const useRemoveSurveyCondition = () => useContext(RemoveSurveyConditionContext);
export const useQuestionConditionContext = () => useContext(QuestionConditionBuilderContext);
export const useSumConditionContext = () => useContext(SumConditionBuilderContext);
export const useClassificationConditionContext = () => useContext(ClassificationBuilderContext);

export const useSurveyMatchValidationContext = () => useContext(SurveyConditionsMatchValidationsContext);
export const useSurveyMatchContext = () => useContext(SurveyMatchContext);

export const useSurveyLogicValidations = () => useContext(SurveyLogicItemValidationsContext);
export const useSurveyConditionsValidations = () => {
  const ctx = useSurveyMatchValidationContext();
  return ctx.conditions;
};
export const useConditionsRuleValidations = () => {
  const ctx = useSurveyMatchValidationContext();
  return ctx.rule;
};
export const useRunLogicValidationsCtx = () => useContext(RunSurveyLogicValidationsContext);
export const useSurveyLogicItemSaveContext = () => useContext(SurveyLogicItemSaveContext);

export const useSurveyQuestionConditionValue = <T extends SurveyQuestionConditionType = SurveyQuestionConditionType>() => {
  const { item, updateConditionValue } = useQuestionConditionContext();

  return [
    item.data as SurveyLogic.QuestionConditionData<T>,
    updateConditionValue as (value: SurveyLogic.QuestionConditionData<T>['value']) => void,
  ] as const;
};