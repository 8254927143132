import { memo } from 'react';
import { Helmet } from 'react-helmet';
import type { FAQPage } from 'schema-dts';
import { helmetJsonLdProp } from 'react-schemaorg';
import { path } from '@consts';
import { PricingContainer } from '$website/containers';
import { FAQ } from './FAQ';
import { PricingCompact } from './Pricing.Compact';
import { PricingTable } from './Pricing.Table';
import { PlanModalForm } from './Plan.Modal.Form';
import { faqs } from './data';
import styles from './style/Pricing.css';

type Props = unknown;

const H = memo(() => {
  const description = ``;

  const faqMarkup = helmetJsonLdProp<FAQPage>({
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faqs.map(m => ({
      '@type': 'Question',
      name: m.label,
      acceptedAnswer: {
        '@type': 'Answer',
        text: m.value,
      },
    })),
  });

  return (
    <Helmet
      script={[faqMarkup]}
      title="Pricing">
      <link
        rel="canonical"
        href={`${process.env.FRONT_BASE_URL}${path.Website.Pricing}`} />
      {/* <meta
          name='description'
          content={description} />
        <meta
          name='og:description'
          content={description} /> */}
    </Helmet>
  );
});

export const Pricing = (props: Props) => {

  return (
    <PricingContainer>
      <H />
      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.header}>
            <h1 className={styles.title}>Pricing</h1>
            <div className={styles.subtitle}>{`Choose the plan that's right for your team.`}</div>
          </div>
          <PricingCompact />
          <PricingTable />
          <FAQ />
        </div>
      </div>
      <PlanModalForm />
    </PricingContainer>
  );
};

Pricing.displayName = 'Pricing';