export enum Breakpoint {
  Desktop = 1265,
  Tablet  = 800,
}

export const Height = {
  Multiplicand: 0.5625,
};

export const MaxHeight = {
  DesktopThumbnail: 180,
};

export const Width = {
  VideoRowItemGap: 30,
};

export enum ItemSize {
  Desktop = 4,
  Tablet  = 3,
  Phone   = 1,
}