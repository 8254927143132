import { cx } from '@utils';
import styles from './style/Section.css';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const SurveySection = (props: Props) => {
  return (
    <div className={cx(styles.root, props.className)}>
      {props.children}
    </div>
  );
};

export default SurveySection;