import { useCallback } from 'react';
import Select, { } from 'react-select/async-creatable';
import { searchTranscriptEntities } from '@api/transcripts.entities';
import { TranscriptEntityType } from '@/types/transcript.entities';

type Props = {
  className?: string;
  value: string;
  onChange: (val: Entity) => void;
};

type Entity = {
  name: string;
  entityTypeKey: string;
};

export const EntityName = ({ className, value, onChange }: Props) => {
  const loadOptions = useCallback((val: string) => {
    return searchTranscriptEntities({ term: val }).then(e => e.entities);
  }, []);

  return (
    <Select<Entity>
      classNames={
        {
          control: () => className,
        }
      }
      value={value ? { name: value, entityTypeKey: null } : null}
      placeholder={'Type in entity name'}
      loadOptions={loadOptions}
      //Use the label option first if it exists, otherwise use the name, formatCreateLabel basically sets a label
      getOptionLabel={option => option['label'] ?? option?.name}
      getOptionValue={e => e.name}
      onChange={e => onChange(e)}
      onCreateOption={val => onChange({ name: val, entityTypeKey: null })}
      isValidNewOption={(val, existing) => val?.length > 2 && !existing.some(e => e.name === val)}
      formatCreateLabel={val => `Create "${val}"`} />
  );
};