import { useState, useCallback, useMemo } from 'react';
import cuid from 'cuid';
import { Button, Buttons } from '@/components/Button';
import type { UseModalProps } from '@/components/Modal';
import { Modal, useModal, Header } from '@/components/Modal';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder';
import type { SurveyLanguage } from '@/types';
import { LanguageInput } from '@/components/AutoComplete/Language';
import AddItemButton from '@/presentation/AddItemButton';
import styles from './style/AddLanguageModal.css';

export const AddLanguageModal = (props: UseModalProps) => {
  const [state, dispatch] = useSurveyBuilderState();
  const [languages, setLanguages] = useState<SurveyLanguage[]>([generateNewLanguage()]);

  const existingLanguageCodes = useMemo(() => state.survey.language.languages.map(l => l.code).concat(languages.map(l => l.code)), [languages, state.survey.language.languages]);

  const onSave = useCallback(() => {
    dispatch({
      type: 'languages-added',
      payload: {
        languages,
      },
    });
    props.onClose();
  }, [languages, dispatch, props]);

  const updateLanguage = useCallback((rowNum: number, language: Partial<Omit<SurveyLanguage, 'identifier'>>) => {
    setLanguages(langs => {
      return langs.map((l, i) => {
        if (rowNum === i) {
          return { ...l, ...language };
        } else {
          return l;
        }
      });
    });
  }, []);

  const addBlankLanguage = useCallback(() => {
    setLanguages(old => [...old, generateNewLanguage()]);
  }, []);
  return (
    <Modal {...props}>
      <Header>Create Multilingual Survey Version</Header>
      <div className={styles.rows}>
        {languages.map((l, i) => (
          <div className={styles.langRow} key={i}>
            <div className={styles.rowTitle}>Language</div>
            <LanguageInput
              value={l.name}
              disableOption={o => existingLanguageCodes.includes(o.fullCode)}
              onChange={val => updateLanguage(i, { name: val.name, code: val.fullCode })} />
          </div>
        ))}
        <AddItemButton onClick={addBlankLanguage} label={`Add Multilingual Survey Version`} />
      </div>

      <Buttons>
        <Button
          variant='brick'
          color='destructive'
          onClick={props.onClose}>Cancel
        </Button>
        <Button
          variant='brick'
          color='affirmative'
          disabled={!languages.length || languages.some(l => !l.code)}
          onClick={onSave}>Save
        </Button>
      </Buttons>
    </Modal>
  );
};

function generateNewLanguage(): SurveyLanguage {
  return { base: { id: null }, isDefault: false, name: '', code: '' };
}

export const useAddLanguageModal = () => useModal(AddLanguageModal);