import { Fragment, useCallback, useMemo } from 'react';
import { SurveyQuestionType } from '@enums';
import { useSurveyQuestionResponsesData } from '@containers/SurveyResponses/hooks';
import { SurveyResponsesHeader } from '@presentation/ProjectSurveyResponses';
import { MatrixRangeQuestion } from '@/types';
// import { MatrixRangeChart } from './MatrixRange.Chart';
import { MatrixRangeData } from './MatrixRange.Data';
import { MatrixRangeChart } from './MatrixRange.Chart.Spectrum';
import styles from './style/Options.css';

export const MatrixRangeResponses = () => {

  const { question } = useSurveyQuestionResponsesData<SurveyQuestionType.MatrixRange>();

  return (
    <Fragment>
      <SurveyResponsesHeader
        question={question} />
      <MatrixRangeChart />
      <div className={styles.options}>
        <MatrixRangeData />
      </div>
    </Fragment>
  );
};

export default MatrixRangeResponses;