import { useCallback, useEffect } from 'react';
import { Color } from './Color';
import styles from './style/ColorPalette.css';

type Props = {
  colors:   string[];
  max?:     number;
  min?:     number;
  onChange: (colors: string[]) => void;
};

export const Palette = ({
  colors,
  max = 25,
  min = 5,
  onChange,
  ...props
}: Props) => {

  const handleChangeColor = useCallback((val: string, index: number) => {
    const copied = [...colors];

    copied[index] = val;

    onChange(copied);
  }, [
    colors,
    onChange,
  ]);

  const handleAddColor = useCallback(() => {

    const last = colors[colors.length - 1];

    onChange([ ...colors, last ]);

  }, [
    colors,
    onChange,
  ]);

  const handleRemoveColor = useCallback((index: number) => {
    const removed = colors.filter((x, i) => i !== index);

    onChange(removed);
  }, [
    colors,
    onChange,
  ]);

  const columnlength = Math.ceil(max / 2);

  const left = colors.slice(0, columnlength);
  const right = colors.slice(columnlength);
  const readonly = colors.length <= min;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.column}>
            {left.map((x, i) =>
              <Color
                index={i}
                key={`${i}-${x}`}
                onChangeColor={handleChangeColor}
                onRemoveColor={handleRemoveColor}
                readonly={readonly}
                value={x} />)}
          </div>
          <div className={styles.column}>
            {right.map((x, i) =>
              <Color
                index={i + columnlength}
                key={`${i + columnlength}-${x}`}
                onChangeColor={handleChangeColor}
                onRemoveColor={handleRemoveColor}
                readonly={readonly}
                value={x} />)}
            {colors.length < max &&
              <div className={styles.add}>
                <button
                  className={styles.btn}
                  onClick={handleAddColor}>
                  + Add Color
                </button>
              </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

Palette.displayName = 'ColorPalette.Palette';