import { useMutation, useQueryClient, UseMutationOptions } from '@tanstack/react-query';
import { Surveys } from '@consts/querykey';
import * as $api from '@api';
import { Surveys as ISurveys } from 'services/api/interfaces/surveys';

type Props = Partial<ISurveyId>;
type Variables = ISurveyVersionId;
type Options = UseMutationOptions<unknown, unknown, Variables>;

type QueryData = ISurveys.GetSurveyDrafts.Response;

export const useDiscardSurveyDraft = ({ surveyId }: Props, options: Options = {}) => {
  const queryClient = useQueryClient();
  return useMutation(({ surveyVersionId }: Variables) => {
    return $api.surveys.draft.discardSurveyDraft({ surveyVersionId });
  }, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData<QueryData>(Surveys.Drafts.Get({ surveyId }), queryData => {
        return queryData?.filter(d => d.surveyVersionId != variables.surveyVersionId);
      });

      if (options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};