import { useCallback, useEffect, useState } from 'react';
import { ChevronDown, Square, Circle, Edit2, RefreshCw, Trash, Star } from 'react-feather';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import { ClickAwayListener, Portal } from '@mui/base';
import type { SurveyQuestionType } from '@enums';
import { cx, useInterval } from '@utils';
import { useImageMarkupEditorContext, useSurveyFormQuestionAnswer, useSurveyFormQuestionContext } from '@/containers/SurveyForm';
import { Tooltip } from '@presentation/Tooltip';
import type { ImageMarkupQuestion as IMQ } from '@/types';
import { Button } from '@/components/Button';
import { useAlert } from '@/components/Modal/Alert';
import { PopperWrapper, usePopperState } from '@/components/Popper';
import type { FillType } from './utils.image-markup';
import { COLOR_OPTIONS, DEFAULT_COLOR, FILL_TYPES, STRUCTURED_TYPES } from './utils.image-markup';
import styles from './style/ImageMarkup.css';

type DrawingShape = 'rect' | 'circle' | 'good' | 'neutral' | 'bad';
type IObjectProps = tuiImageEditor.IObjectProps;

export const Toolbar = () => {
  const item = useSurveyFormQuestionContext<SurveyQuestionType.ImageMarkup>();
  const [answer, setAnswer] = useSurveyFormQuestionAnswer<SurveyQuestionType.ImageMarkup>();
  const { editor } = useImageMarkupEditorContext();
  const [color, setColor] = useState(DEFAULT_COLOR);
  const [undoEnabled, setUndoEnabled] = useState(false);
  const [redoEnabled, setRedoEnabled] = useState(false);
  const [activeObjectId, setActiveObjectId] = useState<number>(null);
  const [drawingMode, setDrawingMode] = useState<string>(null);
  const [drawingShape, setDrawingShape] = useState<DrawingShape>(null);

  const checkDrawingMode = useCallback(() => {
    const currentMode = editor.getDrawingMode();
    if (currentMode !== drawingMode) {
      setDrawingMode(currentMode);
    }
  }, [setDrawingMode, drawingMode, editor]);

  useInterval(checkDrawingMode, 250);

  const onColorChange = useCallback((color: string) => {
    setColor(color);
    editor.setBrush({ color, width: 2 });
  }, [editor]);

  const setRectangleDraw = useCallback(() => {
    editor.deactivateAll();
    editor.changeSelectableAll(false);
    editor.setDrawingShape('rect', {
      fill: 'transparent',
      stroke: color,
      strokeWidth: 2,
      isRegular: false,
    });
    editor.startDrawingMode('SHAPE');
    setDrawingShape('rect');
  }, [editor, color]);

  const setCircleDraw = useCallback(() => {
    editor.deactivateAll();
    editor.changeSelectableAll(false);
    editor.setDrawingShape('circle', {
      fill: 'transparent',
      stroke: color,
      strokeWidth: 2,
      isRegular: false,
    });
    editor.startDrawingMode('SHAPE');
    setDrawingShape('circle');
  }, [editor, color]);

  const setFreeDraw = useCallback(() => {
    if (editor.getDrawingMode() === 'FREE_DRAWING') {
      editor.stopDrawingMode();
      return;
    }

    editor.deactivateAll();
    editor.startDrawingMode('FREE_DRAWING', {
      color,
      width: 2,
    });
  }, [editor, color]);

  const setStarDraw = useCallback(() => {
    editor.deactivateAll();
    editor.changeSelectableAll(false);
    editor.setDrawingIcon('icon-star', color);
    editor.startDrawingMode('ICON', {
      color,
      width: 2,
    });
  }, [editor, color]);

  const startStructuredShape = useCallback((structureType: IMQ.StructuredMarkupType) => {
    const structure = FILL_TYPES.find(f => f.structureType === structureType);

    editor.deactivateAll();
    editor.changeSelectableAll(false);
    editor.setDrawingShape('rect', {
      fill: structure.fill,
      stroke: structure.stroke,
      strokeWidth: 1,
      isRegular: false,
    });
    editor.startDrawingMode('SHAPE');
    setDrawingShape(structureType);
  }, [editor]);

  const onRedoClick = useCallback(() => {
    editor.redo();
  }, [editor]);

  const onUndoClick = useCallback(() => {
    editor.undo();
  }, [editor]);

  const undoStackChanged = useCallback((length: number) => {
    setUndoEnabled(!!length);
  }, []);

  const redoStackChanged = useCallback((length: number) => {
    setRedoEnabled(!!length);
  }, []);

  const onObjectActivated = useCallback((obj: IObjectProps) => {
    setActiveObjectId(obj.id);
  }, []);

  const onEditorMouseDown = useCallback(() => {
    //There's no deselect event, so we have to listen for mousedown to clear the active object
    const objectId = editor['activeObjectId'] as number;
    setActiveObjectId(objectId);
  }, [editor]);

  useEffect(() => {
    if (editor) {
      editor.on('undoStackChanged', undoStackChanged);
      editor.on('redoStackChanged', redoStackChanged);
      editor.on('objectActivated', onObjectActivated);
      editor.on('mousedown', onEditorMouseDown);

      return () => {
        editor.off('undoStackChanged', undoStackChanged);
        editor.off('redoStackChanged', redoStackChanged);
        editor.off('objectActivated', onObjectActivated);
        editor.off('mousedown', onEditorMouseDown);
      };
    }
  }, [editor, undoStackChanged, redoStackChanged, onObjectActivated, onEditorMouseDown]);

  const [toggleAlert, Alert, alertOpen] = useAlert();

  const resetImage = useCallback(() => {
    setAnswer({
      ...answer,
      markedUpImage: null,
      markupMetadata: {
        ...answer.markupMetadata,
        markupStructures: [],
      },
    });
    setActiveObjectId(null);

    editor.loadImageFromURL(item.settings.initialImage, 'SampleImage');
    if (alertOpen) toggleAlert();
  }, [answer, setAnswer, editor, item.settings.initialImage, alertOpen, toggleAlert]);

  const deleteActiveObject = useCallback(() => {
    editor.removeActiveObject();
    editor.deactivateAll();
    setActiveObjectId(null);
  }, [editor]);

  const deleteAllObjects = useCallback(() => {
    editor.clearObjects();
    editor.deactivateAll();
    setActiveObjectId(null);
  }, [editor]);

  const StructuredButton = useCallback(({ onClick, fillType, name }: StructuredButtonProps) => {
    return (
      <ToolButton onClick={() => onClick(fillType.structureType)} active={drawingMode === 'SHAPE' && drawingShape === fillType.structureType}>
        <div className={styles.structuredButton}>
          <div className={styles.structuredIcon} style={{ borderColor: fillType.stroke, backgroundColor: fillType.fill }} />
          <div>{name}</div>
        </div>
      </ToolButton>
    );
  }, [drawingMode, drawingShape]);

  return (
    <div className={styles.toolbarRoot}>
      <div className={styles.toolbar}>
        <ColorButton color={color} onChange={onColorChange} />
        <ToolButton onClick={setFreeDraw} active={drawingMode === 'FREE_DRAWING'}>
          <Edit2 size={16} />
        </ToolButton>
        <ToolButton onClick={setRectangleDraw} active={drawingMode === 'SHAPE' && drawingShape === 'rect'}>
          <Square size={16} />
        </ToolButton>
        <ToolButton onClick={setCircleDraw} active={drawingMode === 'SHAPE' && drawingShape === 'circle'}>
          <Circle size={16} />
        </ToolButton>
        <ToolButton onClick={setStarDraw} active={drawingMode === 'ICON'}>
          <Star size={16} />
        </ToolButton>
        <ToolButton
          onClick={onUndoClick}
          disabled={!undoEnabled}
          tooltip='Undo'>
          <UndoIcon className={styles.muiIcon} />
        </ToolButton>
        <ToolButton
          onClick={onRedoClick}
          disabled={!redoEnabled}
          tooltip='Redo'>
          <RedoIcon className={styles.muiIcon} />
        </ToolButton>
        {activeObjectId &&
          <ToolButton onClick={deleteActiveObject}>
            <Trash size={16} />
          </ToolButton>}
      </div>
      <div className={styles.toolbar}>
        <StructuredButton
          fillType={STRUCTURED_TYPES.bad}
          name='Negative'
          onClick={() => startStructuredShape('bad')} />
        <StructuredButton
          fillType={STRUCTURED_TYPES.neutral}
          name='Neutral'
          onClick={() => startStructuredShape('neutral')} />
        <StructuredButton
          fillType={STRUCTURED_TYPES.good}
          name='Positive'
          onClick={() => startStructuredShape('good')} />
      </div>
      <div className={styles.spacer} />
      <div className={styles.rightButtons}>
        <Button
          onClick={toggleAlert}
          variant='brick'
          color='destructive'
          size='small'>
          Clear All
        </Button>
      </div>
      <Alert
        message='Are you sure you want to reset the image to the default? This cannot be undone.'
        onConfirm={resetImage} />
    </div>
  );
};

type ButtonProps = {
  onClick: () => void;
  disabled?: boolean;
  tooltip?: string;
  active?: boolean;
} & ChildrenProps;
const ToolButton = ({ onClick, disabled, active, tooltip, children }: ButtonProps) => {
  return (
    <Tooltip title={disabled ? null : tooltip}>
      <div className={cx(styles.button, disabled ? styles.disabled : null, active ? styles.pressed : null)} onClick={disabled ? () => { } : onClick}>
        {children}
      </div>
    </Tooltip>
  );
};

type ColorProps = {
  color: string;
  onChange: (color: string) => void;
};
const ColorButton = ({ color, onChange }: ColorProps) => {
  const { setPopperElement, setReferenceElement, open, setOpen, styles: popperStyles, attributes } = usePopperState({
    placement: 'top-start',
  });
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <div
          ref={setReferenceElement}
          className={styles.button}
          onClick={() => setOpen(!open)}>
          <div className={styles.colorRoot}>
            <div className={styles.colorCircle} style={{ backgroundColor: color }} />
            <ChevronDown size={16} />
          </div>
        </div>
        <Portal>
          {open &&
            <div
              ref={setPopperElement}
              {...attributes.popper}
              style={popperStyles.popper}>
              <div className={styles.colorMenu}>
                {COLOR_OPTIONS.map(c => (
                  <div
                    key={c}
                    className={styles.colorButton}
                    style={{ backgroundColor: c }}
                    onClick={() => {
                      onChange(c);
                      setOpen(false);
                    }} />
                ))}
              </div>
            </div>
          }
        </Portal>
      </div>
    </ClickAwayListener>
  );
};

type StructuredButtonProps = {
  onClick: (structureType: IMQ.StructuredMarkupType) => void;
  fillType: FillType;
  name: string;
};