import type { SurveyItemType } from '@enums/Survey';
import { SurveyPipedValueType, SurveyQuestionType, SurveyPipedQuestionValueType } from '@enums/Survey';
import type { SurveyQuestion } from '@/types/survey.question';
import type { PipedItemValue, PipedQuestionValue, PipedValue, SurveyRichText } from '@/types/survey.rich-text';
import type { SurveyBuilderVersion, SurveyItem, SurveyTemplate } from '@/types/survey';
import { formatCurrencyLegacy, CURRENCY_FORMATTERS_LEGACY, formatWithOrdinalSuffix } from '@/utils';
import { Remirror } from '@/types';

function bulletize(text: string) {
  return `\u2022 ${text}`;
}

export function parseSurveyRichText(value: string | SurveyRichText.RichTextValue, data: SurveyBuilderVersion): string {

  if (!value || typeof value === 'string') {
    return value as string;
  }
  const parse = parseParagraphContent(data);

  function parseParagraphNode(node: SurveyRichText.ParagraphNode) {
    return (node.content || []).map(parse).join(``);
  }

  return (value.content || []).map(node => {
    if (node.type === 'bulletList') {
      const bullets = node.content.map(listItem => listItem.content.map(x => {
        const text = parseParagraphNode(x);
        return bulletize(text);
      }));

      return ['', ...bullets].join(`\n`);
    } else if (node.type === 'paragraph') {
      return parseParagraphNode(node);
    }
  }).join('\n')
    .trim();
}

const parseParagraphContent = (data: SurveyBuilderVersion) => (node: SurveyRichText.ParagraphContent): string => {

  switch (node.type) {
    case Remirror.NodeType.Text:
      return node.text;

    case Remirror.NodeType.PipedResponse:
      return getResponsePipeNodeText(node.attrs, data);

    case Remirror.NodeType.TemplateCategory:
      return getCategoryNodeText(node, data);

    case Remirror.NodeType.TemplateTarget:
      return getTargetNodeText(node, data);

    case Remirror.NodeType.TemplateCompetitor:
      return getCompetitorNodeText(node, data);

    case Remirror.NodeType.TemplateNode:
      return getTemplateNodeText(node, data);

    case Remirror.NodeType.FindAndReplace:
      return node.attrs.touched ? parseSurveyRichText(node.attrs.value, data) : node.attrs.label;

    default:
      return '';
  }
};

function getTemplateNodeText(node: SurveyRichText.TemplateValueNode, data: Pick<SurveyBuilderVersion, 'template'>): string {

  const templateData = data.template.data as SurveyTemplate.TargetProductProfile;

  switch (node.attrs.nodeType) {

    case 'client':
      return templateData.client;

    case 'drug':
      return templateData.drug.value;

    case 'drug-indication':
      return templateData.drugIndication;

    case 'high-drug-price':
      return formatCurrencyLegacy(templateData.drugPrice.high, CURRENCY_FORMATTERS_LEGACY[templateData.drugPrice.currency]);

    case 'medium-drug-price':
      return formatCurrencyLegacy(templateData.drugPrice.medium, CURRENCY_FORMATTERS_LEGACY[templateData.drugPrice.currency]);

    case 'low-drug-price':
      return formatCurrencyLegacy(templateData.drugPrice.low, CURRENCY_FORMATTERS_LEGACY[templateData.drugPrice.currency]);

    case 'launch-year':
      return templateData.launchYear.toString();

    case 'medical-condition':
      return templateData.medicalCondition.value;

    case 'quality-of-life':
      return templateData.qualityOfLife;

    case 'trial-design':
      return templateData.trialDesign;

    case 'product':
      return (data.template.data as SurveyTemplate.MessageTesting.Data).product.value;

    case 'mt-medical-condition':
      return (data.template.data as SurveyTemplate.MessageTesting.Data).medicalConditions.primary.value;

    default:
      return '';
  }
}

export function getCategoryNodeText(node: SurveyRichText.CategoryNode, data: SurveyBuilderVersion) {
  return (data.template.data as SurveyTemplate.ICategory).category.value;
}

export function getCompetitorNodeText(node: SurveyRichText.CompetitorNode, data: Pick<SurveyBuilderVersion, 'template'>) {
  return (data.template.data as SurveyTemplate.ICompetitors).competitors.find(f => f.id === node.attrs.id).value;
}

export function getTargetNodeText(node: SurveyRichText.TargetNode, data: Pick<SurveyBuilderVersion, 'template'>) {
  return (data.template.data as SurveyTemplate.ITarget).target.value;
}

export function getResponsePipeNodeText(attrs: SurveyRichText.PipedResponseNode['attrs'], data: SurveyBuilderVersion) {
  if ('question' in attrs && attrs.question?.identifier) {
    const question = data.questions.find(f => f.base.identifier === attrs.question.identifier);

    const placeholder = getPipedValuePlaceholder({
      question,
      pipedValue: attrs.value,
    });

    return `[${placeholder}]`;
  } else if ('item' in attrs && attrs.item?.identifier) {
    const item = data.items.find(f => f.identifier === attrs.item.identifier);

    const placeholder = getPipedItemPlaceholder({
      item,
      pipedValue: attrs.value,
      survey: data,
    });
    return `[${placeholder}]`;
  }
}

type GetPipedValuePlaceholder<T extends SurveyQuestionType = SurveyQuestionType> = {
  pipedValue: PipedQuestionValue<T>['value'];
  question: SurveyQuestion<T>;
};

export function getPipedValuePlaceholder(data: GetPipedValuePlaceholder) {

  if (!data.question) return 'Invalid';

  const questionText = `Q${data.question.ordinal}`;

  switch (data.question.typeId) {

    case SurveyQuestionType.MultipleChoice: {
      assertData<SurveyQuestionType.MultipleChoice>(data);
      const conditiontext = data.pipedValue.type === SurveyPipedValueType.OptionsSelected
        ? 'Selected'
        : 'Not selected';

      return [questionText, conditiontext].join(`~`);
    }

    case SurveyQuestionType.Multiselect: {
      assertData<SurveyQuestionType.Multiselect>(data);
      const conditiontext = data.pipedValue.type === SurveyPipedValueType.OptionsSelected
        ? 'Selected'
        : 'Not selected';

      return [questionText, conditiontext].join(`~`);
    }

    case SurveyQuestionType.Dropdown: {
      assertData<SurveyQuestionType.Dropdown>(data);
      const conditiontext = data.pipedValue.type === SurveyPipedValueType.OptionsSelected
        ? 'Selected'
        : 'Not selected';

      return [questionText, conditiontext].join(`~`);
    }

    case SurveyQuestionType.MatrixMultiselect:
    case SurveyQuestionType.MatrixGrid: {
      assertData<SurveyQuestionType.MatrixGrid | SurveyQuestionType.MatrixMultiselect>(data);

      switch (data.pipedValue.type) {
        case SurveyPipedValueType.MatrixRowsNotSelected:
        case SurveyPipedValueType.MatrixRowsSelected: {
          const optionIdentifier = data.pipedValue.option.identifier;
          const option = data.question.options.find(f => f.base.identifier === optionIdentifier);
          const optionText = `A${option.ordinal}`;
          const conditiontext = data.pipedValue.type === SurveyPipedValueType.MatrixRowsSelected
            ? 'Selected'
            : 'Not selected';

          return [questionText, optionText, conditiontext].join(`~`);
        }

        case SurveyPipedValueType.MatrixOptionsNotSelected:
        case SurveyPipedValueType.MatrixOptionsSelected: {
          const rowIdentifier = data.pipedValue.row.identifier;
          const row = data.question.matrixRows.find(f => f.base.identifier === rowIdentifier);
          const rowText = `R${row.ordinal}`;
          const conditiontext = data.pipedValue.type === SurveyPipedValueType.MatrixOptionsSelected
            ? 'Selected'
            : 'Not selected';

          return [questionText, rowText, conditiontext].join(`~`);
        }

      }
    }

    case SurveyQuestionType.NumberInputTable: {
      assertData<SurveyQuestionType.NumberInputTable>(data);
      const row = data.question.matrixRows.find(f => f.base.identifier === data.pipedValue.row.identifier);
      const rowText = `R${row.ordinal}`;
      const option = data.question.options.find(f => f.base.identifier === data.pipedValue.option.identifier);
      const optionText = `A${option.ordinal}`;

      return [questionText, rowText, optionText].join(`~`);
    }

    case SurveyQuestionType.Sliders: {
      assertData<SurveyQuestionType.Sliders>(data);

      if (data.pipedValue.type === SurveyPipedValueType.SliderRowValue) {
        const row = data.question.matrixRows.find(f => f.base.identifier === (data.pipedValue as PipedValue.SliderRowValue).row.identifier);
        const rowText = `R${row.ordinal}`;
        return [questionText, rowText].join(`~`);
      } else {
        const conditionText = data.pipedValue.type === SurveyPipedValueType.SliderHighestValue
          ? 'Highest value'
          : 'Lowest value';

        return [questionText, conditionText].join(`~`);
      }
    }

    case SurveyQuestionType.MaxDifference:
    case SurveyQuestionType.Ranking: {
      assertData<SurveyQuestionType.Ranking>(data);

      if (data.pipedValue.type === SurveyPipedValueType.RankedXOption) {
        const rank = data.pipedValue.rank;
        return [questionText, `Ranked ${formatWithOrdinalSuffix(rank)}`].join(`~`);
      }

      const conditionText = {
        [SurveyPipedValueType.FirstRankedOption]: 'Ranked first',
        [SurveyPipedValueType.SecondRankedOption]: 'Ranked second',
        [SurveyPipedValueType.SecondLastRankedOption]: 'Ranked second last',
        [SurveyPipedValueType.LastRankedOption]: 'Ranked last',
        [SurveyPipedValueType.AllRankedOptions]: 'All ranked options',
      }[data.pipedValue.type];

      return [questionText, conditionText].join(`~`);
    }

    case SurveyQuestionType.MultiTextbox: {
      assertData<SurveyQuestionType.MultiTextbox>(data);

      const conditionText = {
        [SurveyPipedValueType.TextEntryValues]: 'Text entry values',
      }[data.pipedValue.type];

      return [questionText, conditionText].join(`~`);
    }

    default:
      return 'Invalid';
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  function assertData<T extends SurveyQuestionType>(d: GetPipedValuePlaceholder): asserts d is GetPipedValuePlaceholder<T> { }

}

type GetPipedItemValuePlaceholder<T extends SurveyItemType = SurveyItemType> = {
  pipedValue: PipedItemValue['value'];
  item: SurveyItem;
  survey: SurveyBuilderVersion;
};

export function getPipedItemPlaceholder(data: GetPipedItemValuePlaceholder) {
  if (!data.item) return 'Invalid';

  switch (data.pipedValue.type) {
    case SurveyPipedValueType.AlternateImageShown: {
      const itemText = `AIE${data.item.ordinal}`;

      const group = data.survey.alternateImageExercises.find(e => e.identifier === data.item.source.identifier)?.groups?.find(g => g.identifier === data.pipedValue.group.identifier);
      return [itemText, `C${group.name}`].join(`~`);
    }
  }
}