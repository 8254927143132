import { TranscriptPublishType, utils } from '@enums';
import { Select, type SelectProps } from '$admin/components/Select';

type Props = {
  value: TranscriptPublishType;
} & Pick<SelectProps<Record<TranscriptPublishType, string>, TranscriptPublishType>, 'onChange'>;

export const PublishSettingsSelect = (props: Props) => {
  return (
    <Select
      defaultValue={TranscriptPublishType.RecordingOnly}
      onChange={props.onChange}
      options={Options}
      value={props.value} />
  );
};

PublishSettingsSelect.displayName = 'PublishSettings.Select';

const Options = utils.TranscriptPublishType.values().reduce((acc, id) => ({
  ...acc,
  [id]: utils.TranscriptPublishType.getName(id),
}), {} as Record<TranscriptPublishType, string>);