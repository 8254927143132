import { useEffect } from 'react';
import * as ws from '@services/websocket/transcripts';
import { Transcripts } from '@services/websocket/interfaces/transcripts';

type Props = ITranscriptId & {
  handlers: EventHandlers;
} & ChildrenProps;

type EventHandlers = Partial<Transcripts.HandlerList>;

export const TranscriptRedactionSocketContainer = ({ transcriptId, handlers, children }: Props) => {
  useEffect(() => {
    if (transcriptId) {
      ws.open();
      ws.subscribe(transcriptId);

      Object.entries(handlers).forEach(([event, handler]) => {
        const key = event as keyof Transcripts.HandlerList;
        ws.on(key, handler);
      });

      return () => {
        ws.unsubscribe(transcriptId);

        Object.entries(handlers).forEach(([event, handler]) => {
          const key = event as keyof Transcripts.HandlerList;
          ws.off(key, handler);
        });
        ws.close();
      };
    }
  }, [transcriptId, handlers]);

  return (
    <>
      {children}
    </>
  );
};

TranscriptRedactionSocketContainer.displayName = 'Transcript.Redaction.Socket.Container';