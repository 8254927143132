import type { CSSProperties } from 'react';
import { memo } from 'react';
import { cx } from '@utils';
import styles from './style/QuestionNumber.css';

type Props = {
  className?: string;
  ordinal: number;
  style?: CSSProperties;
};

export const SurveyQuestionNumber = memo(({ ordinal, ...props }: Props) => {

  return (
    <SurveyNumber {...props}>
      {`Q${ordinal}`}
    </SurveyNumber>
  );
});

export const MessageNumber = memo((props: Omit<Props, 'ordinal'>) => {
  return (
    <SurveyNumber {...props}>
      {`TS`}
    </SurveyNumber>
  );
});

export const AIENumber = memo((props: Omit<Props, 'ordinal'>) => {
  return (
    <SurveyNumber {...props}>
      {`SS`}
    </SurveyNumber>
  );
});

export const SurveySectionNumber = memo(({ ordinal, ...props }: Props) => {
  return (
    <SurveyNumber {...props}>
      {`S${ordinal}`}
    </SurveyNumber>
  );
});

type ConceptNumberProps = Omit<Props, 'ordinal'> & {
  name: string;
};
export const ConceptNumber = memo(({ name, ...props }: ConceptNumberProps) => {
  return (
    <SurveyNumber {...props}>
      {`C ${name}`}
    </SurveyNumber>
  );
});

type BaseProps =
  Omit<Props, 'ordinal'> &
  ChildrenProps;

const SurveyNumber = memo(({
  style = {}, ...props
}: BaseProps) => {

  const className = cx(styles.root, props.className);

  return (
    <div
      className={className}
      style={style}>
      {props.children}
    </div>
  );
});