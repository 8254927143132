import http from '@services/http';
import { Uploads } from '$admin/api/interfaces';

export const parseLinkedInCSV = (data: Uploads.ParseLinkedInCSV.Request) => {
  return http.post(
    `/admin/uploads/parse-linkedin-csv`,
    data.formData,
    { headers: { 'Content-Type': `multipart/form-data;` }}
  );
};

export const updateAccountSpendGoals = (data: Uploads.UpdateAccountSpendGoals.Request): Promise<Uploads.UpdateAccountSpendGoals.Response> => {
  return http.post(
    `/admin/uploads/account-spend-goals`,
    data.formData,
    { headers: { 'Content-Type': `multipart/form-data;` }}
  );
};