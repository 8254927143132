import { memo } from 'react';
import { phone as $phone, useDevice } from '@utils';
import styles from './style/PhoneInformation.css';

export type Props = {
  countryCode?: string;
  phone: string;
  pin?: string;
  tollFree?: boolean;
};

export const PhoneNumberDisplay = memo(({ countryCode, phone, pin, tollFree }: Props) => {

  const device = useDevice();

  const getDisplayPhoneNumber = () => {
    const formatted = $phone.format(phone);
    return countryCode
      ? `${formatted} (${countryCode})${tollFree ? ' (Toll Free)' : ''}`
      : formatted;
  };

  const displayPhone = getDisplayPhoneNumber();

  if (device.phone) {
    const oneTap = $phone.createOneTapLink(phone, pin);
    return <a className={styles.link} href={oneTap}>{displayPhone}</a>;
  } else {
    return <>{displayPhone}</>;
  }
});