import type { CalendarState, Contact } from '@/types';
import { Calendar } from '@/components/Calendar/Calendar';
import { Providing } from './Providing';

type Props = {
  className?: string;
  onSubmit: (data: CalendarState) => Promise<unknown>;
  with:     Pick<Contact, 'id'>;
};

const Invitations = ({
  className = '',
  ...props
}: Props) => {

  const calendar = {
    with: props.with,
  };

  return (
    <Calendar calendar={calendar}>
      <Providing
        className={className}
        onSubmit={props.onSubmit} />
    </Calendar>
  );
};

export { Invitations };
export default Invitations;