import type { ChatState } from './interfaces';
import type { ChatSessionManagerState } from './interfaces.chat-session-manager';

export function chatSessionManagerReducer(state: ChatSessionManagerState.State, action: ChatState.Action): ChatSessionManagerState.State {
  switch (action.type) {
    case 'session-loading': {
      return {
        ...state,
        nextChatInstanceIdentifier: action.payload.chatInstanceIdentifier,
        status: 'loading',
      };
    }

    case 'session-loaded': {
      return {
        ...state,
        currentChatInstanceIdentifier: action.payload.chatInstanceIdentifier,
        nextChatInstanceIdentifier: null,
        status: 'loaded',
      };
    }

    case 'query-response-available': {
      if (state.status !== 'uninitialized') return state;
      return {
        ...state,
        currentChatInstanceIdentifier: action.payload.chatInstance.identifier,
        nextChatInstanceIdentifier: null,
        status: 'loaded',
      };
    }

    case 'instance-context-changed': {
      return {
        ...state,
        currentChatInstanceIdentifier: null,
        nextChatInstanceIdentifier: null,
        status: 'uninitialized',
      };
    }

    default:
      return state;
  }
}
