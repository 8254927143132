import http from '@/services/http';
import { stringify } from '@/utils/querystring';
import type { BrandInsights } from './interfaces';

export const downloadChatInstance = ({ chatInstanceId }: BrandInsights.DownloadChatInstance.Request) => {
  return http.download(`/brand-insights/chat/${chatInstanceId}/download`);
};

export const fileUploadPreCheck = ({ chatInstanceIdentifier, ...data }: BrandInsights.FileUploadPreCheck.Request) => {
  return http.post<BrandInsights.FileUploadPreCheck.Response>(`/brand-insights/chat/${chatInstanceIdentifier}/file-upload-pre-check`, data);
};

export const contextSearch = ({ type, q }: BrandInsights.ContextSearch.Request) => {
  return http.get<BrandInsights.ContextSearch.Response>(`/brand-insights/chat/context-search?${stringify({ type, q })}`);
};

export const filtersProjectSearch = ({ context, query }: BrandInsights.FiltersProjectSearch.Request) => {
  const qs = stringify({
    c: JSON.stringify(context),
    q: query,
  });
  return http.get<BrandInsights.FiltersProjectSearch.Response>(`/brand-insights/chat/filters/projects?${qs}`);
};