import { useCallback } from 'react';
import { maxLengths } from '@consts';
import { SearchType } from '@enums';
import { FormButtons } from '@presentation/FormButtons';
import { EducationForm } from '@/types';
import { TextareaCharacterLimit } from '@/components/Textarea';
import { useProfileEducationForm } from './Context';
import { DateRange as DR, ProfileAutocomplete as PA } from './interfaces';
import Field from './Profile.Form.Field';
import DateRange from './Profile.DateRange.Form';
import { ProfileFormAutocomplete as Autocomplete } from './Profile.Form.Autocomplete';
import Validation from './Profile.Form.Validation';
import styles from './style/Profile.Section.Multiple.css';

type Props =
  unknown;

export const ProfileEducationForm = (props: Props) => {

  const {
    canSave,
    item,
    onCancel,
    onDelete,
    onSubmit,
    setItem,
    validations,
  } = useProfileEducationForm();

  const handleAutocompleteSelect = useCallback((field: keyof EducationForm) => (data: PA.AutocompleteItem) => {
    setItem(s => ({
      ...s,
      [field]: data,
    }));
  }, [setItem]);

  const handleInputChange = useCallback((field: keyof EducationForm) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setItem(s => ({
      ...s,
      [field]: value,
    }));
  }, [setItem]);

  const handleDateChange: DR.OnChange = useCallback(value => {
    setItem(s => ({
      ...s,
      ...value,
    }));
  }, [setItem]);

  return (
    <div className={styles.root}>
      <div className={styles.columns}>
        <div className={styles.topItem}>
          <div className={styles.half}>
            <Field
              required
              className={styles.cell}
              title="School">
              <Autocomplete
                autoFocus
                name="school"
                onSelect={handleAutocompleteSelect('school')}
                placeholder="Ex: Boston College"
                type={SearchType.School}
                value={item.school} />
            </Field>
          </div>
          <div>
            <DateRange
              startMonth={item.startMonth}
              startYear={item.startYear}
              endMonth={item.endMonth}
              endYear={item.endYear}
              onChange={handleDateChange}
              currentText="I'm currently enrolled here" />
            {validations['during'] &&
              <Validation message={validations['during']} />}
          </div>
        </div>
      </div>

      <div className={styles.half}>
        <Field
          required
          className={styles.cell}
          title="Degree">
          <Autocomplete
            name="degree"
            onSelect={handleAutocompleteSelect('degree')}
            placeholder="Ex: Bachelor of Arts"
            type={SearchType.Degree}
            value={item.degree} />
        </Field>
      </div>

      <div className={styles.field}>
        <Field title="Description">
          <TextareaCharacterLimit
            className={styles.textarea}
            maxLength={maxLengths.EDUCATION_DESCRIPTION}
            name="description"
            onChange={handleInputChange('description')}
            value={item.description} />
        </Field>
      </div>

      <FormButtons
        deletable={!!item.id}
        disabled={!canSave}
        onCancel={onCancel}
        onDelete={onDelete}
        onSubmit={onSubmit} />

    </div>
  );
};

export default ProfileEducationForm;