import { nullableDate } from '@utils';
import { CallType } from '@enums';
import { Call } from '$admin/interfaces';

export const transformCallItem = <T extends CallType = CallType>(item: Call.CallAggregateItem<T, string>): Call.CallAggregateItem<T, Date> => {

  // @ts-ignore
  return {
    ...item,
    call: {
      ...item.call,
      createdAt: nullableDate(item.call.createdAt),
      timeEnd: nullableDate(item.call.timeEnd),
      timeStart: nullableDate(item.call.timeStart),
    },
  };
};