import { SurveyBuilder, SurveyMessagesBuilder } from '../interfaces';
import * as $actions from './state.messages';

export function messagesReducer(state: SurveyBuilder.State, action: SurveyBuilder.Actions): SurveyMessagesBuilder.State {
  const messages = state.survey.messages;

  switch (action.type) {

    case 'message-added':
      return $actions.messageAdded(messages, action);

    case 'message-removed':
      return $actions.messageRemoved(messages, action);

    case 'message-value-updated':
      return $actions.messageValueUpdated(messages, action);

    case 'message-metadata-updated':
      return $actions.messageMetadataUpdated(messages, action);

      // side effects

    case 'remove-section':
      return $actions.sectionRemoved(state.survey, action);

    case 'revert-uncommitted-changes':
      return state.committed.messages;

      // template actions

    case 'template-stimulus-updated':
      return $actions.stimulusUpdated(messages, action);

    default:
      return messages;
  }
}