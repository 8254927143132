import { Link, LinkProps } from 'react-router-dom';
import * as API from '@api/interfaces';
import { cx } from '@utils';
import { Content } from './Content';
import styles from './style/URLPreview.css';

enum Size {
  SM = 'small',
  LG = 'large',
}

type Props = {
  className?: string;
  item: Partial<API.Posts.FetchURLPreview.Response>;
  url?: string;
};

export const URLPreview = (props: Props) => {

  if (!props.url) return null;

  const size = !props.item?.image || props.item?.image?.width < 600 || props.item?.image?.height < 360
      ? Size.SM
      : Size.LG;

  const main = cx(styles.main, {
    [styles.base]: size === Size.SM,
    [styles.full]: size === Size.LG,
  });

  const title = props.item.title ||
        props.item.sitename ||
        (props.url.startsWith('http') && new URL(props.url).hostname.replace('www.', '')) ||
        '';

  const linkProps = props.url.startsWith('/')
      ? { to: props.url }
      : { href: props.url };

  return (
    <div className={cx(styles.root, props.className)}>
      <div className={styles.wrap}>
        <Hyperlink {...linkProps}>
          <div className={main}>
            <Content
              description={props.item.description}
              domain={props.item.domain}
              image={props.item.image}
              size={size}
              title={title} />
          </div>
        </Hyperlink>
      </div>
    </div>
  );
};

URLPreview.displayName = 'URLPreview';

type AnchorProps = {
  children: React.ReactNode;
  href:     string;
};

type AppLinkProps = {
  children: React.ReactNode;
  to: LinkProps['to'];
};

type HyperlinkProps =
  | (AnchorProps & Partial<AppLinkProps>)
  | (Partial<AnchorProps> & AppLinkProps);

const Hyperlink = (props: HyperlinkProps) => {
  if (props.href) {
    return (
      <a
        href={props.href}
        rel="noreferrer"
        target="_blank">
        {props.children}
      </a>
    );
  }

  return (
    <Link to={props.to}>
      {props.children}
    </Link>
  );
};

Hyperlink.displayName = 'URLPreview.Hyperlink';