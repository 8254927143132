import * as enums from '@enums';
import DropDown from '@/components/DropDown';
import styles from './style.css';

type Props = {
  include:  enums.VerificationStatus[];
  onUpdate: (value: enums.VerificationStatus) => unknown;
  value:    enums.VerificationStatus;
};

type VerificationItem = {
  id: enums.VerificationStatus;
  name: string;
};

const VerificationStatusDropdown = (props: Props) => {
  const handleUpdate = (item: VerificationItem) => {
    props.onUpdate(item.id);
  };

  const options = props.include.map<VerificationItem>(x => ({
    id: x,
    name: enums.utils.VerificationStatus.getName(x),
  }));

  return (
    <div className={styles.verificationDropdown}>
      <DropDown<VerificationItem>
        items={options}
        getItemValue={v => v.name}
        onSelect={handleUpdate}
        text={enums.utils.VerificationStatus.getName(props.value)} />
    </div>
  );
};

const defaultProps = {
  include: [
    enums.VerificationStatus.Verified,
    enums.VerificationStatus.Unverified,
  ],
};

VerificationStatusDropdown.defaultProps = defaultProps;

export { VerificationStatusDropdown };
export default VerificationStatusDropdown;