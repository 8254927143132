import { Link } from 'react-router-dom';
import { path } from '@consts';
import { Button } from '@/components/Button';
import styles from './style/Survey.Preview.css';

type Props = {
  onRetry: () => void;
  isDisqualified: boolean;
};

export const SurveyVersionPreviewComplete = ({ onRetry, isDisqualified }: Props) => {
  const displayText = isDisqualified ? 'Your answers resulted in you being disqualified from the survey.' : 'You have completed the survey!';

  return (
    <div className={styles.root}>
      <div className={styles.header}>{displayText}</div>
      <div className={styles.profile}>
        <Button variant={'brick'} onClick={onRetry}>Retry</Button>
      </div>
      <div className={styles.dashboard}>
        <Link to={path.Projects.Root}>Go to Dashboard</Link>
      </div>
    </div>
  );
};

SurveyVersionPreviewComplete.displayName = 'Survey.Version.Preview.Complete';