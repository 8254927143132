import { useContext, useMemo } from 'react';
import { TranscriptCommentsStateContext, TranscriptFocusedCommentContext } from '@containers/Transcript.Commenting/Context';

export const useTranscriptFocusedComment = () => {

  const [state] = useContext(TranscriptCommentsStateContext);
  const [focusedIdentifier] = useContext(TranscriptFocusedCommentContext);

  const focusedComment = useMemo(() => {
    return state.items.find(f => f.parentComment.identifier === focusedIdentifier);
  }, [
    focusedIdentifier,
    state.items,
  ]);

  return focusedComment;
};