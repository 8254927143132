import { useContext } from 'react';
import { cx } from '@utils';
import { QueryParamsContext } from './containers/Context';
import styles from './styles/Toolbar.css';

type Props = {
  className?: string;
};

export const SearchFilter = (props: Props) => {
  const [queryparams, setQueryParams] = useContext(QueryParamsContext);

  return (
    <div className={props.className}>
      <input
        autoComplete="off"
        autoCorrect="off"
        className={styles.input}
        defaultValue={queryparams.name}
        id="name"
        placeholder="Search name"
        onChange={e => setQueryParams({ ...queryparams, name: e.target.value, index: undefined, size: undefined }, 'replace')}
        type="search" />

      <input
        autoComplete="off"
        autoCorrect="off"
        className={styles.input}
        defaultValue={queryparams.title}
        id="title"
        placeholder="Search title"
        onChange={e => setQueryParams({ ...queryparams, title: e.target.value, index: undefined, size: undefined }, 'replace')}
        type="search" />
    </div>
  );
};

SearchFilter.displayName = 'Group.Contacts.Panel.Toolbar.Search';