import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { BoldExtension, BulletListExtension, HeadingExtension, ItalicExtension, LinkExtension, LinkOptions, OrderedListExtension, UnderlineExtension } from 'remirror/extensions';
import { CommandsExtension } from '@remirror/core';
import { useRemirror, Remirror, ThemeProvider } from '@remirror/react';
import * as API from '@api/interfaces';
import { path } from '@consts';
import { PostArticleDeleteContainer } from '@containers/Post.Article';
import { useSelectUser } from '@containers/Store';
import { cx } from '@utils';
import { useCreateViewArticlePostMutation } from '@utils/api';
import { useTransitionClasses } from '@/components/ContentFeed/hooks/useTransitionClasses';
import { Author, Comments, CoverImage, Topics } from '@/components/Post.Article';
import { ImageExtension, ImageOptions } from '@/components/Remirror';
import { useRemirrorStyles } from '@/components/Remirror/hooks';
import { ContextMenu } from './ContextMenu';
import styles from './style/PostArticle.css';

type Props = {
  item: API.Posts.Articles.FetchPost.Response['item'];
};

export const Content = (props: Props) => {
  const classes = useTransitionClasses();
  const { mutate: createViewEvent } = useCreateViewArticlePostMutation();
  const user = useSelectUser();

  const classnames = {
    root: cx(styles.root, classes),
  };

  useEffect(() => {
    createViewEvent(props.item.id);
  }, [
    createViewEvent,
    props.item.id,
  ]);

  return (
    <ThemeProvider>
      <DocumentHead item={props.item} />
      <div className={classnames.root}>
        <div className={styles.wrap}>
          <div className={styles.main}>
            {props.item.cover?.url &&
              <CoverImage src={props.item.cover.url} />}
            <div className={styles.header}>
              <h1 className={styles.title}>{props.item.title}</h1>
              {props.item.authorId === user.id &&
                <PostArticleDeleteContainer postId={props.item.id}>
                  <ContextMenu postId={props.item.id} />
                </PostArticleDeleteContainer>}
            </div>
            <Author item={props.item.author} />
            {props.item.topics.length > 0 &&
              <Topics items={props.item.topics} />}
            <ArticleBody content={props.item.content.html} />
            <Comments />
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

Content.displayName = 'Post.Article.Content';

type ArticleBodyProps = {
  content: string;
};

const ArticleBody = (props: ArticleBodyProps) => {

  const linkExtensionParams: LinkOptions = {
    autoLink: true,
  };

  const imageExtensionParams: ImageOptions = {
    enableResizing: false,
  };

  const { manager, state } = useRemirror({
    extensions: () => [
      new CommandsExtension({}),
      new HeadingExtension({}),
      new BoldExtension({}),
      new ItalicExtension(),
      new UnderlineExtension(),
      new BulletListExtension({}),
      new OrderedListExtension(),
      new ImageExtension(imageExtensionParams),
      new LinkExtension(linkExtensionParams),
    ],
    content: props.content,
    selection: 'end',
    stringHandler: 'html',
  });

  useRemirrorStyles();

  return (
    <Remirror
      autoRender="end"
      editable={false}
      initialContent={state}
      manager={manager} />
  );
};

ArticleBody.displayName = 'Post.Article.Content.ArticleBody';

const DocumentHead = (props: Props) => {
  const title = `${props.item.title} | Sentiment`;
  const image = props.item.cover?.url;

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta
        property="og:title"
        content={props.item.title} />
      <link
        rel="canonical"
        href={`${process.env.FRONT_BASE_URL}${path.Posts.Articles.Root}/${props.item.id}`} />
      <meta
        name="description"
        content={props.item.excerpt} />
      <meta
        property="og:description"
        content={props.item.excerpt} />
      <meta
        name="twitter:description"
        content={props.item.excerpt} />
      <meta
        name="image"
        content={image} />
      <meta
        property="og:image"
        content={image} />
      <meta
        name="twitter:image"
        content={image} />
      <meta
        property="og:site_name"
        content="Sentiment" />
    </Helmet>
  );
};

DocumentHead.displayName = 'Post.Article.Content.DocumentHead';