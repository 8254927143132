import {
  SurveyConditionType,
  SurveyItemType,
  SurveyQuestionType,
} from '@enums/Survey';
import type { SurveyQuestion, SurveyQuestionMatrixRow, SurveyRichText } from '@/types/survey';
import { NodeType } from '@/types/rich-text';
import type { SurveyBuilder, SurveyItemsBuilder, SurveyQuestionsBuilder, SurveySectionsBuilder } from '../interfaces';
import {
  getInitialAttributes,
  getInitialMatrixRows,
  getInitialQuestionOptions,
  getDefaultSettingsForType,
  generateNewQuestion,
  fillDefaultExclusiveOptionsQuestion,
} from './defaults';

export * from './state.questions.reuse';

type State = SurveyBuilder.State['survey']['questions'];

export function questionAdded(state: SurveyBuilder.State['survey'], action: SurveyQuestionsBuilder.QuestionAdded.Action): State {

  const question = generateNewQuestion({
    identifier: action.questionIdentifier,
    ordinal: 0,
    section: {
      identifier: action.sectionIdentifier,
    },
  });

  return state.questions.concat(question);
}

export function removeQuestion(state: State, action: SurveyQuestionsBuilder.QuestionRemoved.Action): State {

  return state
    .filter(f => f.base.identifier !== action.questionIdentifier)
    .map(removePipedConditions);

  function removePipedConditions(q: SurveyQuestion) {
    const filteredOptions = q.options.filter(f => !f.conditions.some(s => {
      if (s.conditionType === SurveyConditionType.Question) {
        return s.data.question.identifier === action.questionIdentifier;
      } else if (s.conditionType === SurveyConditionType.AggregatedSum) {
        return true;
      }
    }));
    const filteredRows = (q.matrixRows as SurveyQuestionMatrixRow[]).filter(f => !f.conditions.some(s => {
      if (s.conditionType === SurveyConditionType.Question) {
        return s.data.question.identifier === action.questionIdentifier;
      } else if (s.conditionType === SurveyConditionType.AggregatedSum) {
        return true;
      }
    }));

    return {
      ...q,
      value: removeRichTextPipeNodes(q.value, action.questionIdentifier),
      matrixRows: filteredRows,
      options: filteredOptions,
    } as SurveyQuestion;
  }
}

function removeRichTextPipeNodes(richText: SurveyRichText.RootNode, questionIdentifier: string) {
  return richText.content.reduce<SurveyRichText.RootNode>((acc, node) => {
    if (!node) return acc;

    if (node.type === NodeType.Paragraph) {
      const content = filterParagraphNode(node);

      if (content.length) {
        acc.content.push({
          ...node,
          content,
        });
      }
    } else {
      acc.content.push({
        ...node,
        content: node.content.map(x => ({
          ...x,
          content: x.content.map(p => ({
            ...p,
            content: filterParagraphNode(p),
          })),
        })),
      });
    }
    return acc;
  }, {
    content: [],
    type: NodeType.Doc,
  });

  function filterParagraphNode(node: SurveyRichText.ParagraphNode) {
    return node.content.reduce<SurveyRichText.ParagraphNode['content']>((acc, node) => {
      if (!node) return acc;

      if (node.type === NodeType.PipedResponse) {
        if ('question' in node.attrs && node.attrs.question.identifier !== questionIdentifier) {
          acc.push(node);
        }
      } else {
        acc.push(node);
      }
      return acc;
    }, []);
  }
}

export function updateQuestion(state: State, { item }: SurveyQuestionsBuilder.UpdateQuestion.State): State {
  return state.reduce((acc, x) => {
    if (x.base.identifier === item.base.identifier) {
      acc.push(item);
    } else {
      acc.push(x);
    }

    return acc;
  }, []);
}

export function updateQuestionType(state: State, action: SurveyQuestionsBuilder.UpdateQuestionType.State): State {
  const existing = state.find(f => f.base.identifier === action.questionIdentifier);

  const options = getInitialQuestionOptions(action.typeId);
  const matrixRows = getInitialMatrixRows(action.typeId);
  const attributes = getInitialAttributes(action.typeId);

  const settings = getDefaultSettingsForType(action.typeId);

  let item = {
    ...existing,
    attributes,
    options,
    matrixRows,
    settings,
    typeId: action.typeId,
  } as SurveyQuestion;

  if (item.typeId == SurveyQuestionType.ExclusiveOptions) {
    item = fillDefaultExclusiveOptionsQuestion(item);
  }

  return updateQuestion(state, { item });
}

export function updateQuestionIdentifier(state: State, action: SurveyQuestionsBuilder.UpdateQuestionIdentifier.Action): State {
  return state.reduce((acc, q) => {
    if (q.base.identifier === action.oldIdentifier) {
      acc.push({
        ...q,
        base: {
          id: null,
          identifier: action.newIdentifier,
        },
      });
    } else {
      acc.push(q);
    }
    return acc;
  }, []);
}

export function removeSection(state: SurveyBuilder.State['survey'], action: SurveySectionsBuilder.RemoveSection.State): State {

  return state.questions
    .filter(f => f.section.identifier !== action.identifier);
}

export function sectionBreakAdded(state: SurveyBuilder.State['survey'], action: SurveySectionsBuilder.SectionBreakAdded.Action): State {
  return state.questions.reduce<State>((acc, q) => {
    const isInNewSection = action.payload.items.some(s => s.type === SurveyItemType.Question && s.source.identifier === q.base.identifier);

    return isInNewSection
      ? acc.concat({ ...q, section: { identifier: action.payload.newIdentifier } })
      : acc.concat(q);
  }, []);
}

export function itemMoved(state: SurveyBuilder.State['survey'], action: SurveyItemsBuilder.ItemMoved.Action): State {

  const item = state.items.find(f => f.identifier === action.payload.identifier);

  if (item.type !== SurveyItemType.Question) return state.questions;

  const refItem = state.items.find(f => f.identifier === action.payload.to.ref);

  return state.questions.map(q => ({
    ...q,
    section: q.base.identifier === item.source.identifier ? { identifier: refItem.section.identifier } : q.section,
  }));
}

export function metadataUpdated(state: State, action: SurveyQuestionsBuilder.MetadataUpdated.Action): State {
  const question = state.find(f => f.base.identifier === action.payload.questionIdentifier);

  return updateQuestion(state, {
    item: {
      ...question,
      metadata: {
        ...question.metadata,
        ...action.payload.metadata,
      },
    },
  });
}