import { useCallback, useMemo } from 'react';
import { cx } from '@utils';
import { DropDown, DropDownOnSelect } from '@/components/DropDown';
import styles from './style/Input.LaunchYear.css';

type Props = {
  className?: string;
  onChange: (value: number) => void;
  value: number;
};

export const TemplateLaunchYearInput = ({ className, onChange, value }: Props) => {

  const handleSelect: DropDownOnSelect = useCallback(v => {
    onChange(v.id as number);
  }, [onChange]);

  const items = useMemo(() => {
    const startYear = new Date().getFullYear();
    const years = 50;

    return (new Array(years)).fill(0).map((m, i) => ({
      id: i + startYear,
    }));
  }, []);

  const text = value?.toString() || 'Select a year';

  return (
    <div className={cx(styles.dropdown, className)}>
      <DropDown
        items={items}
        getItemValue={v => v.id}
        onSelect={handleSelect}
        text={text} />
    </div>
  );
};