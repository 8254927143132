import { useCallback, useEffect, useRef } from 'react';
import { useLiveRoomBar, useLiveRoomScreen } from '@containers/Conference';
import { useOnResize } from '@utils';
import { settings } from '@/components/SupportChat';

export function BarRightSupport() {
  const anchor = useRef<HTMLDivElement>();
  const [_,setBar] = useLiveRoomBar();
  const { isFullscreen } = useLiveRoomScreen();

  const getWidgetHeight = useCallback(() => document.getElementById('webWidget')?.offsetHeight ?? 570, []);
  const getVerticalOffset = useCallback(() => anchor.current?.offsetHeight - getWidgetHeight() + 10, [getWidgetHeight]);

  const updateSettings = useCallback((value: Record<string, unknown>) => {
    window?.zE?.('webWidget', 'updateSettings', {
      ...settings,
      position: {
        horizontal: 'left',
        vertical: 'bottom',
      },
      ...value,
    });
  }, []);

  useOnResize(() =>
    updateSettings({
      offset: {
        horizontal: '48px',
        vertical: `${getVerticalOffset()}px`,
      },
    })
  );

  useEffect(() => {
    updateSettings({
      offset: {
        horizontal: '48px',
        vertical: `${getVerticalOffset()}px`,
      },
    });

    const resetSettings = () => window?.zE?.('webWidget', 'updateSettings', settings);

    // lazy but this will bind multiple times
    window?.zE?.('webWidget:on', 'close', () => {
      setBar(prev => ({ ...prev, active: null }));
      window?.zE?.('webWidget', 'hide');
      resetSettings();
    });

    window?.zE?.('webWidget', 'show');
    window?.zE?.('webWidget', 'open');

    return () => {
      window?.zE?.('webWidget', 'close');
      window?.zE?.('webWidget', 'hide');
      resetSettings();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFullscreen) {
      setBar(prev => ({ ...prev, active: null }));
      window?.zE?.('webWidget', 'close');
      window?.zE?.('webWidget', 'hide');
      window?.zE?.('webWidget', 'updateSettings', settings);
    }
  }, [setBar, isFullscreen]);

  return (
    <div
      style={{ height: '100%' }}
      ref={anchor} />
  );
}