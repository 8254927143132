import { BasicRouter } from '@routes/Routers';
import { path } from '@consts';
import { RouteAuth } from '@enums';
import { PersonalCalendar } from '@containers/Calendar/PersonalCalendar';
import * as Routing from '@routes/interfaces';

export const routes: Routing.Route[] = [
  {
    component: PersonalCalendar,
    exact: true,
    path: path.Calendar.Root,
  },
];

export const router = BasicRouter(routes, {
  auth: RouteAuth.Authenticated,
  path: path.Calendar.Root,
});