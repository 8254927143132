import { useCallback, useMemo } from 'react';
import { useCanCreateFindAndReplace } from '@/containers/SurveyBuilder/hooks/useCanCreateFindAndReplace';
import { useRichTextListBuilderContext } from '@containers/SurveyBuilder.Question/Context';
import { SurveyQuestionOption } from '@/types/survey';
import { SurveyBuilderContextMenu } from '@/components/SurveyBuilder/ContextMenu';
import { ContextMenuItemProps } from '@/components/SurveyBuilder/ContextMenu.Item';
import { useFindAndReplaceOptionMenuItem } from './hooks/option-menu';

type Props = {
  option: SurveyQuestionOption;
  items?: ContextMenuItemProps[];
  excludeFindAndReplace?: boolean;
};

export const OptionContextMenu = ({
  option,
  items = [],
  excludeFindAndReplace = true,
}: Props) => {
  const canFar = useCanCreateFindAndReplace();
  const findAndReplaceMenuItem = useFindAndReplaceOptionMenuItem();
  const { removeItem, ...context } = useRichTextListBuilderContext<SurveyQuestionOption>();

  const canRemove = useMemo(() => {
    return context.permissions.canRemoveItem &&
      option.metadata.canDelete;
  }, [context.permissions, option.metadata]);

  const removeOption = useCallback(() => {
    removeItem(option.base.identifier);
  }, [removeItem, option.base.identifier]);

  const menuItems = useMemo(() => {
    const values: ContextMenuItemProps[] = Array.from(items);

    if (!excludeFindAndReplace && findAndReplaceMenuItem && canFar) {
      values.unshift({
        children: findAndReplaceMenuItem.children,
        onClick: findAndReplaceMenuItem.onClick,
      });
    }

    if (canRemove) {
      values.push({
        children: 'Delete',
        onClick: removeOption,
      });
    }

    return values;
  }, [
    excludeFindAndReplace,
    canFar,
    findAndReplaceMenuItem,
    canRemove,
    removeOption,
    items,
  ]);

  return (
    <>
      <SurveyBuilderContextMenu
        items={menuItems} />
      {!excludeFindAndReplace && canFar && findAndReplaceMenuItem.Modal ? <findAndReplaceMenuItem.Modal /> : null}
    </>
  );
};