import Check from '@/components/icons/Check';
import Pencil from '@/components/icons/Pencil';
import XCircle from '@/components/icons/XCircle';
import styles from './style.css';

type EditProps = {
  onClick: () => void;
};

const Edit = (props: EditProps) => {
  return (
    <div className={styles.btns}>
      <button
        onClick={props.onClick}
        type="button">
        <div className={styles.btn}>
          <Pencil className={styles.pencil} />
        </div>
      </button>
    </div>
  );
};

type EditingProps = {
  disabled?: boolean;
  onCancel:  () => void;
  onSubmit:  () => void;
};

const Editing = (props: EditingProps) => {
  return (
    <div className={styles.btns}>
      <button onClick={props.onCancel}>
        <XCircle  />
      </button>
      {!props.disabled &&
        <button onClick={props.onSubmit}>
          <Check />
        </button>}
    </div>
  );
};

const Buttons = {
  Edit,
  Editing,
};

export { Buttons };
export { Buttons as  SectionHeaderButtons };