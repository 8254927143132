import { SurveyTemplate } from '@/types/survey.templates';
import { SurveyTemplateAutocompleteMulti } from './Input.Autocomplete.Multi';
import styles from './style/Input.MedicalSpecialties.css';

type Props = {
  onRemove: (field: keyof SurveyTemplate.MessageTesting.Specialties) => (id: string) => void;
  onSelect: (field: keyof SurveyTemplate.MessageTesting.Specialties) => (value: SurveyTemplate.LinkedEntity<'medical-specialty'>) => void;
  value: SurveyTemplate.MessageTesting.Specialties;
};

export const TemplateMedicalSpecialtyInput = ({ onRemove, onSelect, value }: Props) => {

  const placeholder = 'Enter a specialty';

  const source = {
    industries: true,
  };

  return (
    <>
      <div className={styles.label}>Specialties</div>
      <div className={styles.input}>
        <SurveyTemplateAutocompleteMulti
          allowCustomSelection={true}
          placeholder={placeholder}
          values={value.enabled}
          onRemove={onRemove('enabled')}
          onSelect={onSelect('enabled')}
          source={source} />
      </div>
      <div className={styles.label}>Disqualifying Specialties</div>
      <div className={styles.input}>
        <SurveyTemplateAutocompleteMulti
          allowCustomSelection={true}
          placeholder={placeholder}
          values={value.disabled}
          onRemove={onRemove('disabled')}
          onSelect={onSelect('disabled')}
          source={source} />
      </div>
    </>
  );
};
