import { useMemo } from 'react';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import * as api from '@api';
import * as API from '@api/interfaces';
import { ProjectType } from '@enums';

type Params = {
  projectType: ProjectType;
} & IProjectId;

type Options = UseQueryOptions<API.Pipeline.GetSurveyPayment.Response['value']>;

export const useProjectSurveyPayment = (params: Params, options: Options = {}) => {
  const enabled = useMemo(() => {
    return params.projectId
        && params.projectType === ProjectType.Survey;
  }, [
    params.projectId,
    params.projectType,
  ]);

  return useQuery(['get-project-survey-payment', params.projectId, params.projectType], () => {
    return api.projects.pipeline.getSurveyPayment({
      projectId: params.projectId,
    }).then(data => data.value);
  }, {
    ...options,
    enabled: !!enabled,
  });
};