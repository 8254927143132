import { ProjectSurveyRespondentContainer } from '@containers/GroupProject/SurveyRespondentContainer';
import { RespondentAnswers } from './RespondentAnswers';

type Props = unknown;

export const ProjectSurveyRespondent = (props: Props) => {
  return (
    <ProjectSurveyRespondentContainer>
      <RespondentAnswers />
    </ProjectSurveyRespondentContainer>
  );
};

ProjectSurveyRespondent.displayName = 'Group.Project.Survey.Respondent';