import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import type { BarMouseEventHandler, ComputedDatum, BarTooltipProps } from '@nivo/bar';
import { ResponsiveBar } from '@nivo/bar';
import type { OrdinalColorScaleConfigCustomFunction } from '@nivo/colors';
import type { GridValues } from '@nivo/axes';
import {
  calculateChartHeight,
  calculateChartPadding,
} from '@containers/SurveyResponses/utils/chart';
import { trunc } from '@utils';
import type { StackedBarChartData } from './interfaces';
import { theme, defs } from './Chart.Common';
import ChartWrapper from './ChartWrapper';
import { useChartAnimation } from './hooks';

type Props = {
  formatBottomAxisLabel?: (value: string) => string;
  handleSegmentClick: BarMouseEventHandler<SVGRectElement>;
  gridXValues?: GridValues<string | number>;
  items: StackedBarChartData[];
  maxValue?: number;
  minValue?: number;
  notApplicableKeys?: string[];
  keys: string[];
  renderTooltip: FC<BarTooltipProps<StackedBarChartData>>;
};

export const percentProps: Partial<Props> = {
  formatBottomAxisLabel: (value: string) => `${value}%`,
  gridXValues: [0, 25, 50, 75, 100],
  minValue: 0,
  maxValue: 100,
};

export const StackedBarChart = ({
  notApplicableKeys = [],
  ...props
}: Props) => {
  const padding = useMemo(() => {
    return calculateChartPadding(props.items.length);
  }, [props.items.length]);

  const colors: OrdinalColorScaleConfigCustomFunction<ComputedDatum<StackedBarChartData>> = useCallback(
    (x: ComputedDatum<StackedBarChartData>) => x.data.colors[x.id],
    [],
  );

  const handleMouseEnter: BarMouseEventHandler<SVGRectElement, StackedBarChartData> = useCallback((item, e) => {
    e.currentTarget.style.cursor = 'pointer';
  }, []);

  const formatLeftAxisLabel = useCallback((value: string) => {
    const item = props.items.find(f => f.indexId === value);

    return trunc(item.name, 10);
  }, [
    props.items,
  ]);

  const fill = useMemo(() => {
    return notApplicableKeys.map(k => ({
      match: {
        id: k,
      },
      id: 'NA',
    }));
  }, [notApplicableKeys]);

  const data = useMemo(() => [...props.items].reverse(), [props.items]);
  const animate = useChartAnimation();

  return (
    <ChartWrapper
      style={{ height: calculateChartHeight(props.items.length) }}>
      <ResponsiveBar
        data={data}
        keys={props.keys}
        theme={theme}
        groupMode="stacked"
        indexBy="indexId"
        layout="horizontal"
        colors={colors}
        defs={defs}
        fill={fill}
        enableLabel={false}
        enableGridX={true}
        gridXValues={props.gridXValues}
        enableGridY={false}
        minValue={props.minValue}
        maxValue={props.maxValue}
        margin={{ top: 0, right: 25, bottom: 30, left: 75 }}
        onClick={props.handleSegmentClick}
        padding={padding}
        axisBottom={{
          format: props.formatBottomAxisLabel,
          tickValues: props.gridXValues,
        }}
        axisLeft={{
          format: formatLeftAxisLabel,
        }}
        axisTop={null}
        axisRight={null}
        animate={animate}
        onMouseEnter={handleMouseEnter}
        tooltip={props.renderTooltip} />
    </ChartWrapper>
  );
};

export default StackedBarChart;