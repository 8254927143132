import { useCallback, useState } from 'react';
import { Node } from 'slate';
import { FeedItemComment } from '@/types/comments';
import { ContentFeed } from '@/types';
import * as Comment from './Comment';
import styles from './style/Comment.Section.css';

type Props = {
  item:     Pick<ContentFeed.FeedItem, 'comments'>;
  replying: boolean;
  onSubmit: (value: Node[]) => Promise<FeedItemComment>;
};

export const Commenting = ({ item, replying, onSubmit }: Props) => {
  const [comments, dispatch] = useState<FeedItemComment[]>(item.comments);

  const handleSubmit = useCallback((val: Node[]) => {
    return onSubmit(val)
    .then(comment => {

      dispatch([...comments, comment]);

      return comment;

    });
  }, [
    dispatch,
    comments,
    onSubmit,
  ]);

  return (
    <>
      {replying &&
        <Comment.Input onSubmit={handleSubmit} />}
      <div>
        {comments.map(x =>
          <Comment.Thread
            className={styles.comment}
            item={x}
            depth={0}
            key={x.id} />)}
      </div>
    </>
  );
};

Commenting.displayName = 'Feed.Commenting';