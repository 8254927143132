import * as surveyBuilder  from '@containers/SurveyBuilder/utils';
import { useConjointAnalysisQuestionBuilderContext } from '@containers/SurveyBuilder.Question/Context';
import { QuestionSettingTitle } from '@presentation/SurveyBuilder';
import { cx } from '@utils';
import { CheckboxLabel } from '@/components/Checkbox';
import Select from './ConjointAnalysis.Settings.Select';
import styles from './style/ConjointAnalysis.Settings.css';

type Props = {
  className?: string;
};

export const ConjointAnalysisSettingsBuilder = ({ className }: Props) => {
  const {
    item,
    toggleNoneOfTheAbove,
    updateConceptsPerSet,
    updateNumberOfSets,
  } = useConjointAnalysisQuestionBuilderContext();

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.item}>
        <QuestionSettingTitle
          title="Concepts per set" />
        <Select
          min={surveyBuilder.conjointAnalysis.MinConcepts}
          max={surveyBuilder.conjointAnalysis.MaxConcepts}
          onChange={updateConceptsPerSet}
          value={item.settings.conceptsPerSet} />
      </div>
      <div className={styles.item}>
        <QuestionSettingTitle
          title={`Number of Sets`} />
        <Select
          min={surveyBuilder.conjointAnalysis.MinSets}
          max={surveyBuilder.conjointAnalysis.MaxSets}
          onChange={updateNumberOfSets}
          value={item.settings.numberOfSets} />
      </div>
      <CheckboxLabel
        className={styles.checkbox}
        label={`Show a "none of the above" option`}
        onChange={toggleNoneOfTheAbove}
        checked={item.settings.includeNoneOfTheAbove} />
    </div>
  );
};

export default ConjointAnalysisSettingsBuilder;