import { useCallback, useMemo } from 'react';
import type { SurveyQuestionType } from '@enums';
import type { RankingQuestion as RQ } from '@/types/survey';
import { device, useDebugMount } from '@utils';
import { useSurveyFormQuestionAnswer } from '@/containers/SurveyForm';
import { RankingFormContainer } from './Ranking.Container';
import { RankingAltFormContainer } from './Ranking.Alt.Container';
import { RankingStory } from './Ranking.Story';
import { DontKnow } from './DontKnow';
import dontKnowStyles from './style/DontKnow.css';
import { RankingStoryContainer } from './Ranking.Story.Container';
import { Rationale } from './Rationale';

type Props = {
  question: RQ.FormQuestion;
};

export const RankingQuestionForm = ({ question }: Props) => {
  const [answer, setAnswer] = useSurveyFormQuestionAnswer<SurveyQuestionType.Ranking | SurveyQuestionType.MultiTextbox>();

  const handleToggle = useCallback(() => {

    const dontKnow = !answer.dontKnow;

    setAnswer({
      openEndedValues: {},
      options: [],
      dontKnow,
    });
  }, [answer.dontKnow, setAnswer]);

  const RankingComponent = useMemo(() => {
    switch (question.settings.displayFormat) {
      case 'story':
        return RankingStoryContainer;
      case 'lists':
        return RankingAltFormContainer;
      default:
        return RankingFormContainer;
    }
  }, [question.settings.displayFormat]);

  return (
    <>
      <RankingComponent question={question} />
      {question.settings.dontKnow?.enabled &&
        <DontKnow
          className={dontKnowStyles.left}
          message="If you do not know or do not have an answer to the question, select the checkbox below:"
          onChange={handleToggle}
          value={answer.dontKnow} />
      }
      {!answer.dontKnow && <Rationale settings={question.settings.rationale} />}
    </>
  );
};

export default RankingQuestionForm;