import { useCallback, ReactNode, memo } from 'react';
import { useSurveyTemplate, useInitializeTemplateSurvey } from '@containers/SurveyBuilder/hooks';
import { Header, Subtitle, Title } from '@screens/ProjectCreation/Header';
import {
  useCanSaveCategory,
  useCanSaveCompetitors,
  useCanSaveTarget,
  useTemplateCategory,
  useTemplateCompetitors,
  useTemplateTarget,
  TemplateCategoryInput,
  TemplateCompetitorsInput,
  TemplateTargetInput,
} from '@/components/Survey.Templates';
import { BackButton, Footer, NextButton } from './Footer';
import { useProjectSurveyStepperContext } from './context';
import commonStyles from './style.css';
import styles from './style/ProjectSurvey.Presets.css';

type BaseScreenProps = {
  children?: ReactNode;
  title: string;
  subtitles?: string[];
};

export const BasePresetScreen = ({
  children,
  title,
  subtitles = [],
}: BaseScreenProps) => {

  return (
    <div className={styles.section}>
      <div className={commonStyles.wrap}>
        <Header className={commonStyles.row}>
          <Title className={commonStyles.row}>{title}</Title>
          {subtitles.map((subtitle, i) => (
            <Subtitle
              key={i}
              className={commonStyles.row}>{subtitle}
            </Subtitle>
          ))}
        </Header>
      </div>
      {children}
    </div>
  );
};

type PresetScreenProps = {
  title: string;
  subtitle: string;
};

export const CategoryPresetScreen = ({ subtitle, title }: PresetScreenProps) => {

  const { back, next } = useProjectSurveyStepperContext();
  const {
    onChange,
    onSubmit,
    value,
  } = useTemplateCategory();
  const canSave = useCanSaveCategory(value);

  const handleNext = useCallback(() => {
    onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitle]}>
        <TemplateCategoryInput
          onChange={onChange}
          value={value} />
        <HelpText value="For example: Market Research Software" />
      </BasePresetScreen>
      <Footer>
        <BackButton onClick={handleBack} />
        <NextButton
          disabled={!canSave}
          onClick={handleNext} />
      </Footer>
    </>
  );
};

export const TargetPresetScreen = ({ subtitle, title }: PresetScreenProps) => {

  const { back, next } = useProjectSurveyStepperContext();

  const {
    onChange,
    onSubmit,
    value,
  } = useTemplateTarget();
  const [template] = useSurveyTemplate();
  const canSave = useCanSaveTarget(value);

  const handleNext = useCallback(() => {
    onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitle]}>
        <TemplateTargetInput
          template={template}
          onChange={onChange}
          value={value} />
      </BasePresetScreen>
      <Footer>
        <BackButton onClick={handleBack} />
        <NextButton
          disabled={!canSave}
          onClick={handleNext} />
      </Footer>
    </>
  );
};

export const CompetitorsPresetScreen = ({ subtitle, title }: PresetScreenProps) => {

  const { back, next } = useProjectSurveyStepperContext();

  const {
    onSubmit,
    ...competitorsProps
  } = useTemplateCompetitors();
  const canSave = useCanSaveCompetitors(competitorsProps.competitors);

  const handleNext = useCallback(() => {
    onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitle]}>
        <TemplateCompetitorsInput {...competitorsProps} />
      </BasePresetScreen>
      <Footer>
        <BackButton onClick={handleBack} />
        <NextButton
          disabled={!canSave}
          onClick={handleNext} />
      </Footer>
    </>
  );
};

export const ThanksPresetScreen = () => {

  const title = `Thanks, we've got everything we need to create the survey! Next, make any adjustments to the survey draft before launch.`;

  const actions = useProjectSurveyStepperContext();
  const initializeSurvey = useInitializeTemplateSurvey();

  const handleNext = useCallback(() => {

    initializeSurvey();

    actions.next();
  }, [
    actions,
    initializeSurvey,
  ]);

  return (
    <>
      <BasePresetScreen
        title={title} />
      <Footer>
        <BackButton onClick={actions.back} />
        <NextButton
          title="Review Draft"
          onClick={handleNext} />
      </Footer>
    </>
  );
};

export const HelpText = memo(({ value }: HelpTextProps) => {
  return (
    <div className={styles.helpText}>{value}</div>
  );
});

type HelpTextProps = {
  value: string;
};