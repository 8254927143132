import { useCallback, useMemo, useState } from 'react';
import * as api from '@services/api';
import { SearchType } from '@enums';
import { useAsyncDebounce } from '@utils';
import AutoComplete from '@/components/AutoComplete';

type Props = {
  ignoreList?: IgnoreListItem[];
  onSelect: (item: ProductItem) => unknown;
};

export default function ProductAutocomplete({ ignoreList = [], onSelect }: Props) {
  const [text, setText] = useState<string>('');
  const [items, setItems] = useState<ProductItem[]>([]);

  const ignoreListMapped = useMemo(() => ignoreList.map(item => `${item.id}`), [ignoreList]);

  const fetch = useCallback(async (text: string) => {
    const result = await api.search.getAutocompleteResults({
      type: SearchType.Product,
      text,
      ignoreList: ignoreListMapped,
    }).then(result => buildAutocompleteItems(text, ignoreList, result));
    setItems(result);
  }, [ignoreList, ignoreListMapped]);

  const fetchDebounced = useAsyncDebounce((text: string) => fetch(text), 150);

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setText(text);
    if (!text?.length) {
      setItems([]);
      return;
    }
    fetchDebounced(text);
  }, [fetchDebounced]);

  const handleSelect = useCallback((item: ProductItem) => {
    setItems([]);
    setText('');
    onSelect(item);
  }, [onSelect]);

  return (
    <AutoComplete
      getItemValue={(item: ProductItem) => item.isCustom ? `Add "${item.name}"` : item.name}
      items={items}
      name="products"
      onChange={handleSearch}
      onSelect={handleSelect}
      placeholder="Search for Products"
      value={text} />
  );
}

export { ProductAutocomplete };

function buildAutocompleteItems(text: string, ignore: IgnoreListItem[], items: ProductAutocompleteItem[]): ProductItem[] {
  const found = items.map(i => ({ id: i.id, name: i.name, isCustom: false }));

  const sanitizedText = text.trim().split(' ').map(c => c.charAt(0).toUpperCase() + c.slice(1)).join(' ');

  const showCustom
    = sanitizedText.length >= 3
    && !ignore.some(s => s.name.toLowerCase() === sanitizedText.toLowerCase())
    && !found.some(s => s.name.toLowerCase() === sanitizedText.toLowerCase());

  const custom
    = showCustom
      ? { id: `PRODUCT~${sanitizedText}`, name: `${sanitizedText}`, isCustom: true }
      : null;

  return [...found, custom].filter(Boolean);
}

type ProductAutocompleteItem = {
  id: number;
  name: string;
  type: 'product';
};

export type ProductItem = {
  id: number | string;
  name: string;
  isCustom?: boolean;
};

type IgnoreListItem =
  ProductItem;