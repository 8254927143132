import { cx } from '@utils';
import { Focusable } from '@/components/Focusable';
import { Select } from './Select';
import { Lookup, SelectProps } from './interfaces';
import styles from './style/Select.Underlined.css';

type Props<T extends Lookup = Lookup> = SelectProps<T>;

export const SelectUnderlined = <T extends Lookup>({ classes = {}, ...props }: Props<T>) => {
  const classnames = {
    ...classes,
    root: cx(styles.select, {
      [styles.placeholder]: props.placeholder && props.value === '',
    },
    classes.root),
    disabled: cx(styles.disabled, classes.disabled),
    li: cx(styles.li, classes.li),
  };

  return (
    <Focusable
      classes={{ root: cx(classnames.root, props.className) }}
      autoFocus={props.autoFocus}
      className={styles.root}
      disabled={props.disabled}>
      <Select
        {...props}
        className={cx(styles.input, props.className)}
        classes={classnames} />
    </Focusable>
  );
};

SelectUnderlined.displayName = 'Select.Underlined';