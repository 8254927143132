import { useContext } from 'react';
import { SearchLoadingAllContext, SearchResultsAllContext } from '@/containers/WorkspaceGlobalSearch/Context';
import { formatPlurality } from '@/utils';
import type { WorkspaceSearchFull } from '@/types';
// import MemberSearchBanner from './MemberSearchBanner';
import BrandsTable from './Search.Table.Brands';
import ProjectsTable from './Search.Table.Projects';
// import MembersTable from './Search.Table.Members';
import FilesTable from './Search.Table.Files';
import FoldersTable from './Search.Table.Folders';
import TranscriptsTable from './Search.Table.Transcripts';
import styles from './style/Tab.css';

const pageSize = 10;

export default function SearchTabAll() {
  const loading = useContext(SearchLoadingAllContext);
  const { brands, projects, transcripts, files, folders } = useContext(SearchResultsAllContext);

  const isLoading = loading.untouched && loading.loading;

  return (
    <div className={styles.root}>
      <Brands
        result={brands}
        isLoading={isLoading} />
      <Projects
        result={projects}
        isLoading={isLoading} />
      {/* <Members
        result={members}
        isLoading={isLoading} /> */}
      <Transcripts
        result={transcripts}
        isLoading={isLoading} />
      <Files
        result={files}
        isLoading={isLoading} />
      <Folders
        result={folders}
        isLoading={isLoading} />
    </div>
  );
}

function Brands({ result, isLoading }: BrandsProps) {
  const data = result?.items ?? [];
  const viewAll = result?.total > pageSize;
  const countText = !isLoading
    ? `(${getCountLabel(data.length, result?.total, 'Brand')})`
    : null;

  return (
    <BrandsTable
      data={data}
      countText={countText}
      pageSize={pageSize}
      isLoading={isLoading}
      viewAll={viewAll} />
  );
}

function Projects({ result, isLoading }: ProjectsProps) {
  const data = result?.items ?? [];
  const viewAll = result?.total > pageSize;
  const countText = !isLoading
    ? `(${getCountLabel(data.length, result?.total, 'Project')})`
    : null;

  return (
    <ProjectsTable
      data={data}
      countText={countText}
      pageSize={pageSize}
      isLoading={isLoading}
      viewAll={viewAll} />
  );
}

// function Members({ result, isLoading }: MembersProps) {
//   const data = result?.items ?? [];
//   const viewAll = result?.total > pageSize;
//   const countText = !isLoading
//     ? `(${getCountLabel(data.length, result?.total, 'Expert')})`
//     : null;

//   return (
//     <MembersTable
//       data={data}
//       countText={countText}
//       pageSize={pageSize}
//       isLoading={isLoading}
//       viewAll={viewAll} />
//   );
// }

function Files({ result, isLoading }: FilesProps) {
  const data = result?.items ?? [];
  const viewAll = result?.total > pageSize;
  const countText = !isLoading
    ? `(${getCountLabel(data.length, result?.total, 'File')})`
    : null;

  return (
    <FilesTable
      data={data}
      countText={countText}
      pageSize={pageSize}
      isLoading={isLoading}
      viewAll={viewAll} />
  );
}

function Folders({ result, isLoading }: FoldersProps) {
  const data = result?.items ?? [];
  const viewAll = result?.total > pageSize;
  const countText = !isLoading
    ? `(${getCountLabel(data.length, result?.total, 'Folder')})`
    : null;

  return (
    <FoldersTable
      data={data}
      countText={countText}
      pageSize={pageSize}
      isLoading={isLoading}
      viewAll={viewAll} />
  );
}

function Transcripts({ result, isLoading }: TranscriptProps) {
  const data = result?.items ?? [];
  const viewAll = result?.total > pageSize;
  const countText = !isLoading
    ? `(${getCountLabel(data.length, result?.total, 'Transcript')})`
    : null;

  return (
    <TranscriptsTable
      data={data}
      countText={countText}
      pageSize={pageSize}
      isLoading={isLoading}
      viewAll={viewAll} />
  );
}

function getCountLabel(pageItems: number, total: number, label: string) {
  return total > pageItems
    ? `+${pageSize} ${formatPlurality(label, pageSize)}`
    : `${pageItems} ${formatPlurality(label, pageItems)}`;
}

export { SearchTabAll };

type BrandsProps = {
  result: WorkspaceSearchFull.Brand.Results;
  isLoading: boolean;
};

type ProjectsProps = {
  result: WorkspaceSearchFull.Project.Results;
  isLoading: boolean;
};

// type MembersProps = {
//   result: WorkspaceSearchFull.Member.Results;
//   isLoading: boolean;
// };

type FilesProps = {
  result: WorkspaceSearchFull.File.Results;
  isLoading: boolean;
};

type FoldersProps = {
  result: WorkspaceSearchFull.Folder.Results;
  isLoading: boolean;
};

type TranscriptProps = {
  result: WorkspaceSearchFull.Transcript.Results;
  isLoading: boolean;
};