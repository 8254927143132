import { useAccountProfileEditState } from '@containers/AccountProfile';
import { PlusCircle } from '@presentation/FormButtons';
import { cx } from '@utils';
import styles from './style/AccountProfile.css';

type Props = {
  children?:  React.ReactNode;
  className?: string;
  onClick:    () => unknown;
};

const AddProfileEntryButton = (props: Props) => {

  const [entryId] = useAccountProfileEditState();

  if (entryId) return null;

  return (
    <PlusCircle
      className={cx(styles.add, props.className)}
      onClick={props.onClick}>
      {props.children}
    </PlusCircle>
  );
};

export { AddProfileEntryButton };
export default AddProfileEntryButton;