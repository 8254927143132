import { useContext } from 'react';
import { SurveyContext } from '../Context';

export function useSurvey() {
  const context = useContext(SurveyContext);
  if (context === undefined) {
    throw new Error('useSurvey must be used within a SurveyContext');
  }
  return context;
}

export default useSurvey;