import { useCallback } from 'react';
import { useActive, useChainedCommands } from '@remirror/react';
import { ensureAbsoluteHref } from '@utils';
import { useLinkModalContext } from '../Context';

type SubmitParams = {
  href: string;
  text: string;
};

export function useSubmitLink() {
  const chain = useChainedCommands();
  const {
    close: closeModal,
    selection: modalSelection,
  } = useLinkModalContext();
  const active = useActive();

  return useCallback(({ href, text }: SubmitParams) => {

    const range = modalSelection.range ?? undefined;
    const existingLink = active.link();

    const updatedHref = ensureAbsoluteHref({
      url: href,
    });

    if (existingLink) {
      chain.updateLink({ href: updatedHref, auto: false }, range);
    } else {
      chain
        .replaceText({
          content: text,
          keepSelection: true,
          range,
        })
        .updateLink({ href: updatedHref, auto: false }, {
          from: range.from,
          to: range.from + text.length,
        })
        .run();
    }

    chain.focus(range?.to).run();

    closeModal();
  }, [
    active,
    chain,
    closeModal,
    modalSelection,
  ]);
}