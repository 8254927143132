import { forwardRef } from 'react';
import styles from './style/Carousel.css';

type Props = {
  children: React.ReactNode;
  style?:   React.CSSProperties;
};

const Slider = ({
  style = {},
  ...props
}: Props, ref: React.Ref<HTMLDivElement>) => {

  return (
    <div
      className={styles.slider}
      ref={ref}
      style={style}>
      {props.children}
    </div>
  );
};

Slider.displayName = 'Carousel.Slider';

const SliderWithRef = forwardRef<HTMLDivElement, Props>(Slider);

export { SliderWithRef as Slider };