import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import * as api from '@api';
import * as QK from '@consts/querykey';
import { path } from '@consts';
import { useAppReadyState } from '@containers/AppReadyState';
import { PostArticleEditContainer } from '@containers/Post.Article.Form';
import { Skeleton } from '@screens/Post.Article/Article';
import { qs } from '@utils';
import { Form } from './Article.Form';

type Props = unknown;

export const PostEdit = (props: Props) => {
  const app = useAppReadyState();
  const params = useParams<{ postId: string; }>();
  const history = useHistory();
  const valid = Number.isInteger(+params.postId);

  const query = useQuery([QK.Posts.Articles.Edit.Get, +params.postId], () => {
    return api.posts.articles.fetchPostEditContext({ postId: +params.postId });
  }, {
    enabled: valid,
    onError: (e: AxiosResponse) => {
      if (e.status === 403) {
        history.push(path.Website.Login);
      }
    },
    onSuccess: () => {
      if (window.scrollY > 0) {
        window.scrollTo(0, 0);
      }
    },
    refetchOnWindowFocus: false,
    retry: 1,
  });

  if (!valid && !app.authenticated) {
    const q = qs.stringify({
      returnUrl: window.location.pathname,
    });

    return (
      <Redirect to={{
        pathname: path.Website.Login,
        search: `?${q}`,
      }} />
    );
  }

  if (!query.data?.post) {
    return (
      <Skeleton />
    );
  }

  return (
    <PostArticleEditContainer
      attachments={query.data.attachments}
      cover={query.data.post.cover}
      identifier={query.data.post.identifier}
      postId={query.data.post.id}
      title={query.data.post.title}>
      <Form item={query.data.post} />
    </PostArticleEditContainer>
  );
};

PostEdit.displayName = 'PostEdit';