import type { FileUploadStatus, TranscriptPublishType, TranscriptionRevisionStatus } from '@enums';
import type { GroupTagUpdateItem } from './tags';

export type WorkspaceAuthor = {
  affiliation: string;
  name: string;
};

export type WorkspaceFile<T = Date> = {
  authors: WorkspaceAuthor[];
  createdBy: number;
  createdOn: T;
  downloadCount: number;
  extension: string;
  id: number;
  lastDownloadedBy: number;
  lastDownloadedOn: T;
  lastModifiedBy: number;
  lastModifiedOn: T;
  name: string;
  size: number;
  publishedOn: Date;
  title: string;

} & IWorkspaceObjectId;

export type WorkspaceFilePreviewData = {
  audioUrl: string;
  imgPreviewUrl: string;
  inProgress: boolean;
  pdfPreviewUrl: string;
  workbookDataUrl: string;
  videoUrl: string;
};

export type WorkspaceFileSummaryStatus = 'unsupported' | 'uninitialized' | 'pending' | 'complete';

export type WorkspaceFileSummaryData = {
  status: WorkspaceFileSummaryStatus;
  sections: WorkspaceFileSummarySection[];
};

export type WorkspaceFileSummarySection = {
  title: string;
  text: string;
};

export type FileUploadOptions = {
  convertToTranscript: boolean;
  replaceExisting: boolean;
  transcribe: boolean;
  tags: GroupTagUpdateItem[];
};

export type FileUploadFile = {
  identifier: string;
  lastModified: number;
  name: string;
  size: number;
  type: string;
};

export type WorkspaceFileUpload<T = Date> = {
  bucket: string;
  data: {
    fileId: number;
    groupId: number;
    parentObjectId: number;
    workspaceId: number;
  };
  file: FileUploadFile;
  fileId: number;
  id: number;
  identifier: string;
  initiatedBy: number;
  initiatedOn: T;
  lastUpdatedOn: T;
  name: string;
  s3Key: string;
  s3VersionId: string;
  status: FileUploadStatus;
  uploadIdentifier: string;
};

export enum FileTranscriptStatus {
  InProgress = 'in-progress',
  Errored = 'errored',
  Complete = 'complete',
}

export type WorkspaceFileTranscript = {
  enhancement: {
    assigneeId: number;
    enabled: boolean;
    statusId: TranscriptionRevisionStatus;
  };
  id: number;
  settings: {
    publishType: TranscriptPublishType;
  };
  status: FileTranscriptStatus;
  meta: {
    canTranscribe: boolean;
    canConvertToTranscript: boolean;
  };
};

export type FileCitation = {
  id: number;
  ordinal: number;
  rawValue: string;
};