import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { pathname } from '@consts';
import { useProjectOnboardingContext } from '@containers/ProjectOnboarding/hooks/useProjectOnboardingContext';
import * as PayoutSetup from '@screens/PayoutSetup';
import { selectHasPayablePayoutAccount } from '@store/selectors';
import styles from './style/Billing.css';

export function Stepper() {
  const history = useHistory();
  const { next, hasNextStep, projectId } = useProjectOnboardingContext();

  const handleCompletion = useCallback(() => {
    if (hasNextStep) {
      next();
    } else {
      history.push(pathname.Home);
    }
  }, [hasNextStep, next, history]);

  const handleSkip = useCallback(() => {
    if (hasNextStep) {
      next();
    } else {
      history.push(pathname.Home);
    }
  }, [hasNextStep, next, history]);

  const hasPayoutAccount = useSelector(selectHasPayablePayoutAccount);

  return (
    <div className={styles.root}>
      <PayoutSetup.Stepper.ProjectCall
        onComplete={handleCompletion}
        onSkip={handleSkip}
        hasStepsAfter={hasNextStep}
        hasPayoutAccount={hasPayoutAccount}
        projectId={projectId} />
    </div>
  );
}

Stepper.displayName = 'ProjectOnboarding.Completion.Billing.Call.Stepper';