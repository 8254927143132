import { useContext } from 'react';
import { GroupProjectGoalContext } from '../Context';

const useProjectGoal = () => {
  const goal = useContext(GroupProjectGoalContext);

  return goal;
};

export { useProjectGoal };
export default useProjectGoal;