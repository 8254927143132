import { isValid, parseISO, parseJSON } from 'date-fns';

// https://github.com/date-fns/date-fns/pull/1463#issuecomment-551228882
export function safeJsonDate(value: string | number): Date {
  return value ? parseJSON(value) : null;
}

export function safeIsoDate(value: string): Date {
  return value ? parseISO(value) : null;
}

type NullableDate = Date | string | number | null;

export function nullableDate(value: NullableDate) {
  const date = safeJsonDate(value as string | number);

  return isValid(date)
    ? date
    : null;
}

export function nullableDateFromIsoString(value: string) {
  const date = safeIsoDate(value);

  return isValid(date)
    ? date
    : null;
}

export * from './date.age';