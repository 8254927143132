import { Dispatch } from 'react';
import { createNamedContext } from '@utils';
import { Search } from '@/types';
import { SearchInstanceContextValue, SearchParamsContextValue, SearchQueryContextValue, SearchQueryLegacyContextValue } from './interfaces';

export const SearchParamsContext = createNamedContext<SearchParamsContextValue | undefined>(undefined, 'SearchParamsContext');
export const SearchQueryContext = createNamedContext<SearchQueryContextValue | undefined>(undefined, 'SearchQueryContext');
export const SearchQueryLegacyContext = createNamedContext<SearchQueryLegacyContextValue | undefined>(undefined, 'SearchQueryLegacyContext');
export const SearchQueryStateContext = createNamedContext<Search.QueryState | undefined>(undefined, 'SearchQueryStateContext');
export const SearchQueryDispatchContext = createNamedContext<Dispatch<Search.QueryState> | undefined>(undefined, 'SearchQueryDispatchContext');
export const SearchInstanceContext= createNamedContext<SearchInstanceContextValue>(undefined, 'SearchInstanceContext');