import { SearchParamsContainer } from './SearchParamsContainer';
import { SearchQueryContainer } from './SearchQueryContainer';
import { SearchQueryLegacyContainer } from './SearchQueryLegacyContainer';

type Props = {
  children: React.ReactElement;
};

const SearchContainer = (props: Props) => {
  return (
    <SearchParamsContainer>
      <SearchQueryContainer>
        <SearchQueryLegacyContainer>
          {props.children}
        </SearchQueryLegacyContainer>
      </SearchQueryContainer>
    </SearchParamsContainer>
  );
};

export { SearchContainer };
export default SearchContainer;