import { useCallback, useContext } from 'react';
import { useHelpers } from '@remirror/react';
import { ArticleFeedPublisherContext } from '@containers/Post.Article.Form/Context';
import { useSubmitArticlePostMutation } from '@containers/Post.Article.Form/hooks';
import { useTopicTagsContext } from '@containers/PostCreation/hooks';
import { ButtonActivityIndicator } from '@presentation';
import {  useToggle } from '@utils';
import { FeedPublisher } from '@/components/Post.Article';
import Toast from '@/components/Toast';
import { useCanPublishArticle } from '@/components/Remirror/hooks';
import { ImageExtension } from '@/components/Remirror';
import styles from './style/Toolbar.css';

export const PublishButton = (props: unknown) => {
  const enabled = useCanPublishArticle();
  const helpers = useHelpers<ImageExtension>();
  const feed = useContext(ArticleFeedPublisherContext);
  const [topic] = useTopicTagsContext();
  const [open, toggle] = useToggle();

  const launchFeedPostModal = useCallback((data: LaunchFeedPostModal.Params) => {
    feed.bootstrapFeedPostForm({
      description: data.description,
      postId: data.postId,
      title: data.title,
      topics: topic.items,
    });

    toggle();

  }, [
    feed,
    topic.items,
    toggle,
  ]);

  const mutation = useSubmitArticlePostMutation({
    onMutate: () => {
      feed.resolvePreviewImageSrc({ helpers });
    },
    onError: e => {
      console.log('Error');
      console.log(e);
    },
    onSuccess: res => {
      Toast.success({
        title: `Your article has been published!`,
      });
      launchFeedPostModal({
        description: res.post.excerpt,
        postId: res.post.id,
        title: res.post.title,
      });
    },
  });

  return (
    <div className={styles.publish}>
      <ButtonActivityIndicator
        classes={{ root: styles.submit }}
        disabled={mutation.isSuccess || !enabled}
        implicitDisable={mutation.isLoading}
        indicatorSize={8}
        loading={mutation.isLoading}
        onClick={() => mutation.mutate()}>
        Publish
      </ButtonActivityIndicator>
      <FeedPublisher
        onClose={toggle}
        open={open} />
    </div>
  );
};

PublishButton.displayName = 'Post.New.Toolbar.PublishButton';

namespace LaunchFeedPostModal {
  export type Params = {
    description: string;
    postId:      number;
    title:       string;
  };
}