import { useCallback } from 'react';
import { SurveyLogic } from '@/types/survey';
import { RemoveConditionContainer } from './Condition.Remove';
import {
  useSurveyConditionsState,
  SumConditionBuilderContext,
  SumConditionBuilderContextValue,
} from './context';

type Props = {
  item: SurveyLogic.SumCondition;
} & ChildrenProps;

export const SumConditionBuilderContainer = (props: Props) => {

  const [_, dispatch] = useSurveyConditionsState();

  const updateItems = useCallback((items: SurveyLogic.SumConditionItem[]) => {
    dispatch({
      type: 'sum-condition-items-updated',
      payload: {
        identifier: props.item.identifier,
        value: items,
      },
    });
  }, [dispatch, props.item.identifier]);

  const updateComparate = useCallback((value: number) => {
    dispatch({
      type: 'sum-condition-comparate-updated',
      payload: {
        identifier: props.item.identifier,
        value,
      },
    });
  }, [dispatch, props.item.identifier]);

  const updateOperator = useCallback((operator: Operator) => {
    dispatch({
      type: 'sum-condition-operator-updated',
      payload: {
        identifier: props.item.identifier,
        value: operator,
      },
    });
  }, [dispatch, props.item.identifier]);

  const value: SumConditionBuilderContextValue = {
    item: props.item,
    updateItems,
    updateComparate,
    updateOperator,
  };

  return (
    <SumConditionBuilderContext.Provider value={value}>
      <RemoveConditionContainer item={props.item}>
        {props.children}
      </RemoveConditionContainer>
    </SumConditionBuilderContext.Provider>
  );
};