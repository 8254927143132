import { useCallback } from 'react';
import Select, { } from 'react-select/async';
import { BaseVICS } from '@/types';
import { VICS as VICSType } from '@/enums';
import * as $api from '@api';

export type Props = {
  values: BaseVICS[];
  onChange: (values: BaseVICS[]) => void;
  vicsType: VICSType;
};

export const VICSPicker = ({ values, vicsType, onChange }: Props) => {
  const fetch = useCallback((value: string) => {
    if (vicsType === VICSType.Industry) {
      return $api.autocomplete.industry({
        value,
        ids: [],
      })
      .then(res => res.map(x => ({ ...x, typeId: VICSType.Industry })));
    } else if (vicsType === VICSType.SubIndustry) {
      return $api.autocomplete.subindustry({
        value,
        ids: [],
      })
      .then(res => res.map(x => ({ ...x, typeId: VICSType.SubIndustry })));
    } else {
      throw new Error(`Cant use VICS Type ${vicsType.toString()}`);
    }
  }, [vicsType]);

  return (
    <Select
      loadOptions={fetch}
      onChange={onChange}
      value={values}
      isMulti={true}
      getOptionLabel={o => o.name}
      getOptionValue={o => o.id.toString()}
      defaultOptions />
  );
};