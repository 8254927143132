import { StateRouter } from '@routes/Routers';
import { path } from '@consts';
import { Permission, Role, RouteAuth } from '@enums';
import { BrandInsightsChat } from '@screens/BrandInsights.Chat';
import type * as Routing from '@routes/interfaces';
import { roles } from '@utils';

export const routes: Routing.Route[] = [
  {
    component: BrandInsightsChat,
    exact: true,
    path: [path.BrandInsights.Chat, path.BrandInsights.ChatInstance],
  },
];

const validator = (state: Store.State) => {
  if (state.group.features.insightsChat && state.user.roles.includes(Role.InsightsChatOnly)) {
    return routes;
  }
  if (
    state.user.roles.some(s => roles.client.includes(s)) &&
    state.user.permissions.includes(Permission.BrandInsightsFullpage) &&
    state.group.features.insightsChat) {
    return routes;
  }

  return [];
};

export const router = StateRouter(validator, {
  auth: RouteAuth.Authenticated,
  path: path.BrandInsights.Root,
});