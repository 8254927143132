import type {
  Transaction } from '@remirror/core';
import {
  assert,
} from '@remirror/core';
import { Decoration, DecorationSet } from '@remirror/pm/view';
import type * as HE from './interfaces.extension.highlight';
import { computeSegmentStyle } from './utils';

export class HighlightState<T extends HE.BaseHighlight> {

  initialized = false;

  focusedHighlight: HE.FocusedHighlight = null;

  highlights: Array<T> = [];

  decorationSet = DecorationSet.empty;

  map = new Map<string, T>();

  createDecorations(tr: Transaction, highlights: Array<T> = []): DecorationSet {

    const decos = highlights.map(h => {

      const isFocused = h.id === this.focusedHighlight?.id;

      const style = computeSegmentStyle({
        color: h.color,
        isFocused: isFocused,
      });

      return Decoration.inline(h.from, h.to, {
        nodeName: 'span',
        style,
      });
    });

    return DecorationSet.create(tr.doc, decos);
  }

  getHighlight(id: string) {
    return this.map.get(id);
  }

  addHighlight(action: HE.AddHighlight.State<T>) {
    this.map.set(action.highlight.id, action.highlight);
  }

  blurHighlight() {
    this.focusedHighlight = null;
  }

  focusHighlight(action: HE.FocusHighlight.State) {
    assert(this.map.has(action.highlight.id), `highlight does not exist ${action.highlight.id}`);

    this.focusedHighlight = action.highlight;
  }

  setHighlights(action: HE.SetHighlights.State<T>) {

    this.map.clear?.();
    this.map.forEach((_, id, map) => {
      map.delete(id);
    });

    action.highlights.forEach(highlight => {
      this.map.set(highlight.id, highlight);
    });

    this.initialized = true;
  }

  removeHighlights(action: HE.RemoveHighlights.State) {

    action.highlightIds.forEach(id => {
      this.map.delete(id);
    });
  }

  updateHighlight(action: HE.UpdateHighlight.State<T>) {
    assert(this.map.has(action.highlight.id), `highlight does not exist ${action.highlight.id}`);

    const existing = this.map.get(action.highlight.id);
    this.map.set(action.highlight.id, {
      ...existing,
      ...action.highlight,
    });
  }

  formatHighlights() {
    const highlights: Array<T> = [];

    this.map.forEach(highlight => {
      highlights.push(highlight);
    });

    return highlights;
  }

  apply({ tr, actions }: HE.ApplyProps<T>): this {

    if (!actions && !tr.docChanged) {
      return this;
    }

    this.highlights = this.highlights
      .filter(highlight => highlight.to !== highlight.from);

    const filteredActions = Array.isArray(actions)
      ? actions.filter(f => !!f?.type)
      : [];

    for (const action of filteredActions) {
      switch (action.type) {
        case 'add-highlight': {
          this.addHighlight(action);
          break;
        }
        case 'remove-highlights': {
          this.removeHighlights(action);
          break;
        }
        case 'set-highlights': {
          this.setHighlights(action);
          break;
        }
        case 'update-highlight': {
          this.updateHighlight(action);
          break;
        }
        case 'focus-highlight': {
          this.focusHighlight(action);
          break;
        }
        case 'blur-highlight': {
          this.blurHighlight();
          break;
        }
      }
    }

    if (filteredActions.length) {
      this.highlights = this.formatHighlights();
      this.decorationSet = this.createDecorations(tr, this.highlights);
    } else {
      this.decorationSet = this.decorationSet.map(tr.mapping, tr.doc);
    }

    return this;
  }
}