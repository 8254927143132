import type { GetProjectFiles } from '@api/interfaces/projects.files';

export const QuantAnalysis = {
  Get: (data: IProjectId & { quantAnalysisId: number }) => ['project-quant-analysis-items', {
    projectId: data.projectId,
    quantAnalysisId: data.quantAnalysisId,
  }] as const,
};

export const Project = {
  Base: `get:projects`,
  Get: (data: IProjectId) => [Project.Base, data] as const,
};

export const Access = {
  Get: `get:projects/access`,
  Put: `put:projects/access`,
  Search: {
    Get: `get:projects/access/search`,
  },
};

type ProjectFilesGetData = GetProjectFiles.Request;

export const Files = {
  Base: `get:project/files`,
  Get: (data: ProjectFilesGetData) => [Files.Base, data] as const,
};

export const FilePreview = {
  Base: (data: IWorkspaceFileId) => `get:project/file/preview/${data.fileId}` as const,
  Get: (data: IWorkspaceFileId & IProjectId) => [FilePreview.Base(data), data] as const,
};

export const Pipeline = {
  Project: {
    Hydrate: {
      Get: (data: IProjectId) => [
        `get:project/pipeline/hydrate`,
        data,
      ] as const,
    },
  },
  Users: {
    Hydrate: {
      Get: (data: IProjectId & IUserId) => [
        `get:projects/pipeline/users/hydrate`,
        data.projectId,
        data.userId,
      ],
    },
  },
};