import { memo } from 'react';

type Props = {
  className?: string;
  color?: string;
  strokeWidth?: string | number;
};

const Briefcase = memo(({
  className,
  color = 'var(--gray-m)',
  strokeWidth = '1.5',
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    x="0px"
    y="0px"
    width="70px"
    height="61px"
    viewBox="0 0 70 61">
    <g
id="Account-Setup-Flow"
stroke="none"
strokeWidth="1"
fill="none"
fillRule="evenodd">
      <g
id="Dashboard-Modal"
transform="translate(-419.000000, -711.000000)"
stroke={color}
strokeWidth={strokeWidth}>
        <g id="Group" transform="translate(420.000000, 712.000000)">
          <path
d="M21.4456558,12 L42,12 C44.7614237,12 47,14.2385763 47,17 L47,74 C47,76.7614237 44.7614237,79 42,79 L21,79 L21,79"
id="Rectangle"
strokeLinecap="round"
transform="translate(34.000000, 45.500000) rotate(-270.000000) translate(-34.000000, -45.500000) " />
          <path
d="M31.1028436,-7 L32,-7 C34.7614237,-7 37,-4.76142375 37,-2 L37,9 C37,11.7614237 34.7614237,14 32,14 L31,14 L31,14"
id="Rectangle"
strokeLinecap="round"
transform="translate(34.000000, 3.500000) rotate(-90.000000) translate(-34.000000, -3.500000) " />
          <path
d="M23,18.1987609 L28.9471485,-7.88905701 C29.3620288,-9.70897461 30.9804863,-11 32.8470944,-11 L40.6024782,-11 C43.363902,-11 45.6024782,-8.76142375 45.6024782,-6 L45.6024782,51 C45.6024782,53.7614237 43.363902,56 40.6024782,56 L32.7529697,56 C30.8992516,56 29.2884087,54.726369 28.8608314,52.9226371 L23,28.1987609 L23,28.1987609 L23,18.1987609 Z"
id="Rectangle"
strokeLinecap="round"
transform="translate(34.301239, 22.500000) rotate(-90.000000) translate(-34.301239, -22.500000) " />
          <rect
id="Rectangle"
fill="#FFFFFF"
x="29"
y="30"
width="10"
height="10"
rx="2" />
        </g>
      </g>
    </g>
  </svg>
));

export { Briefcase };
export default Briefcase;