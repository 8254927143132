import { SurveyOptionType } from '@enums/Survey';
import type { OptionsQuestion } from '@containers/Survey/utils/questions';
import { hasOptions, hasRows } from '@containers/Survey/utils/questions';
import type { SurveyQuestionOption, SurveyQuestion, MatrixRangeQuestion, SurveyQuestionMatrixRow } from '@/types/survey';
import { isOptionInCondition } from '@/containers/SurveyBuilder.Logic/utils.lookup';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import type { SurveyQuestionsBuilder, SurveyOptionsBuilder } from '../interfaces';
import { generateNewOption } from './defaults';

export * from './state.options.metadata';

function isExtendedOption(option: SurveyQuestionOption) {
  return option.type !== SurveyOptionType.Default;
}

type State = SurveyQuestionsBuilder.State;

export function addOption(state: State, action: SurveyOptionsBuilder.AddOption.State): State {

  const question = state.find(f => f.base.identifier === action.questionIdentifier);

  if (!hasOptions(question)) return state;

  const options = question.options.filter(f => !isExtendedOption(f));
  const ordinal = options.length + 1;

  const newOption = generateNewOption({
    ordinal,
  }, question.typeId);

  return addOptions(state, {
    questionIdentifier: action.questionIdentifier,
    options: [newOption],
    reorder: false,
  });
}

export function addOptions(state: State, action: SurveyOptionsBuilder.AddOptions.State): State {

  return state.reduce<State>((acc, q) => {
    if (q.base.identifier === action.questionIdentifier) {
      acc.push(updateQuestionOptions(q as OptionsQuestion));
    } else {
      acc.push(q);
    }

    return acc;
  }, []);

  function updateQuestionOptions(q: OptionsQuestion): SurveyQuestion {
    const existingOptions = q.options
      .filter(f => !isExtendedOption(f));

    let nonExtendedOptions: SurveyQuestionOption[] = [
      ...existingOptions,
      ...action.options,
    ];

    if (action.reorder) {
      nonExtendedOptions.sort(sortOptionsByEmptiness);
      nonExtendedOptions = nonExtendedOptions.map((m, i) => ({
        ...m,
        ordinal: i + 1,
      }));
    }

    // @ts-ignore
    return {
      ...q,
      options: [
        ...nonExtendedOptions,
        ...generateExtendedOptions(q),
      ],
    };
  }

  function sortOptionsByEmptiness(a: SurveyQuestionOption, b: SurveyQuestionOption) {
    const aIsNull = parseSurveyRichText(a.value) === '';
    const bIsNull = parseSurveyRichText(b.value) === '';

    if (aIsNull === bIsNull) {
      return a.ordinal - b.ordinal;
    } else if (aIsNull) {
      return 1;
    } else if (bIsNull) {
      return -1;
    }
  }

  function generateExtendedOptions(item: OptionsQuestion): SurveyQuestionOption[] {
    const naOption = item.options.find(f => f.type === SurveyOptionType.NotApplicable);
    const noneOption = item.options.find(f => f.type === SurveyOptionType.NoneOfTheAbove);

    let lastOrdinal = Math.max(...item.options.map((m: SurveyQuestionOption) => m.ordinal));

    return [
      generateOption(naOption),
      generateOption(noneOption),
    ].filter(Boolean);

    function generateOption(option: SurveyQuestionOption) {
      if (option) {
        lastOrdinal++;
        return {
          ...option,
          ordinal: lastOrdinal,
        };

      }
      return null;
    }
  }

}

export function replaceOptions(state: State, action: SurveyOptionsBuilder.ReplaceOptions.State): State {

  return state.reduce<State>((acc, q) => {
    if (q.base.identifier === action.questionIdentifier) {
      acc.push(updateQuestionOptions(q as OptionsQuestion));
    } else {
      acc.push(q);
    }

    return acc;
  }, []);

  function updateQuestionOptions(q: OptionsQuestion): SurveyQuestion {
    const val = { ...q };
    val.options = action.options;
    return val;
  }
}

export function removeOption(state: State, action: SurveyOptionsBuilder.RemoveOption.State): State {

  const question = state.find(f => f.base.identifier === action.questionIdentifier);

  const toRemove = question.options.find(f => f.base.identifier === action.option.identifier);

  if (!toRemove) return state;

  const ordinals = generateNewOrdinals();

  const options = question.options
    .filter(f => f.base.identifier !== action.option.identifier)
    .map<SurveyQuestionOption>(option =>
    ({
      ...option,
      ordinal: ordinals[option.ordinal],
    }));

  return state.reduce<State>((acc, q) => {
    if (q.base.identifier === action.questionIdentifier) {
      acc.push({
        ...q,
        options,
      } as OptionsQuestion);
    } else {
      // filter out options/rows with conditions reliant on deleted option (does this need to be recursive?)
      const filteredOptions = hasOptions(q) ?
        q.options
          .filter(f => !f.conditions.some(condition => isOptionInCondition({
            condition,
            option: { identifier: f.base.identifier },
          })))
        : q.options;
      const filteredRows = hasRows(q) ?
        (q.matrixRows as SurveyQuestionMatrixRow[])
          .filter(f => !f.conditions.some(condition => isOptionInCondition({
            condition,
            option: { identifier: f.base.identifier },
          })))
        : q.matrixRows;

      acc.push({
        ...q,
        matrixRows: filteredRows,
        options: filteredOptions,
      } as SurveyQuestion);
    }
    return acc;
  }, []);

  type OrdinalMap = { [ordinal: number]: number };
  function generateNewOrdinals() {
    return question.options.map(o => o as SurveyQuestionOption).reduce<OrdinalMap>((acc, x) => {
      return {
        ...acc,
        [x.ordinal]: x.ordinal < toRemove.ordinal
          ? x.ordinal
          : x.ordinal > toRemove.ordinal
            ? x.ordinal - 1
            : null,
      };
    }, {});
  }
}

export function updateOptionValue(state: State, action: SurveyOptionsBuilder.UpdateOptionValue.State): State {
  return state.reduce<State>((acc, q) => {
    if (q.base.identifier === action.questionIdentifier) {
      const options = q.options.map(o => o as SurveyQuestionOption).reduce((acc2, o) => {
        if (o.base.identifier === action.option.identifier) {
          acc2.push({
            ...o,
            value: action.value,
          });
        } else {
          acc2.push(o);
        }
        return acc2;
      }, []);
      acc.push({
        ...q,
        options,
      } as OptionsQuestion);
    } else {
      if (q.options.some(o => optionIsLinked(o))) {
        const options = q.options.map((o: SurveyQuestionOption) => o).reduce((acc2, o) => {
          if (optionIsLinked(o)) {
            acc2.push({
              ...o,
              value: action.value,
            });
          } else {
            acc2.push(o);
          }
          return acc2;
        }, []);
        acc.push({
          ...q,
          options,
        } as OptionsQuestion);
      } else {
        acc.push(q);
      }
    }

    return acc;
  }, []);

  function optionIsLinked(option: SurveyQuestionOption) {
    if (option.metadata?.linked && 'option' in option.metadata.linked) {
      return option.metadata.linked.option.identifier === action.option.identifier && option.metadata.linked.question.identifier === action.questionIdentifier;
    }

    return false;
  }
}

export function matrixRangeOptionMetadataUpdated(state: State, payload: SurveyOptionsBuilder.MatrixRangeOptionMetadataUpdated.Payload): State {

  return state.reduce<SurveyQuestion[]>((acc, q) => {
    if (q.base.identifier === payload.questionIdentifier) {
      const options = (q.options as MatrixRangeQuestion.Option[]).reduce((acc2, r) => {
        if (r.base.identifier === payload.option.identifier) {

          return acc2.concat({
            ...r,
            metadata: {
              ...r.metadata,
              helpText: payload.value,
            },
          });
        }
        return acc2.concat(r);
      }, []);
      return acc.concat({
        ...q,
        options,
      } as OptionsQuestion);
    }

    return acc.concat(q);
  }, []);
}