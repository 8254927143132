import qs from 'query-string';
import http from '@services/http';
import { nullableDate } from '@utils';
import { Surveys } from './interfaces';

export const updateSurveyResponseAnswer = (data: Surveys.UpdateSurveyResponseAnswer.Request): Promise<Surveys.UpdateSurveyResponseAnswer.Response> => {
  const { questionId, responseId, ...body } = data;
  return http.patch(`/admin/surveys/responses/${responseId}/answers/${questionId}`, body);
};

export const fetchSurveyModule = (data: Surveys.FetchSurveyModule.Request): Promise<Surveys.FetchSurveyModule.Response> => {
  const query = qs.stringify({ skipSanitization: data.skipSanitization });
  return http.get(`/admin/surveys/modules/${data.surveyId}?${query}`)
    .then((data: Surveys.FetchSurveyModule.Response<string>) => ({
      ...data,
      module: {
        ...data.module,
        editedOn: nullableDate(data.module.editedOn),
        createdOn: nullableDate(data.module.createdOn),
      },
      survey: {
        ...data.survey,
        createdOn: nullableDate(data.survey.createdOn),
      },
    }));
};

export const updateSurveyModule = (data: Surveys.UpdateSurveyModule.Request): Promise<Surveys.UpdateSurveyModule.Response> => {
  return http.post(`/admin/surveys/modules`, data)
    .then((data: Surveys.UpdateSurveyModule.Response<string>) => ({
      ...data,
      module: {
        ...data.module,
        editedOn: nullableDate(data.module.editedOn),
        createdOn: nullableDate(data.module.createdOn),
      },
      survey: {
        ...data.survey,
        createdOn: nullableDate(data.survey.createdOn),
      },
    }));
};

export const searchSurveyModuleCategories = (query: Surveys.SearchSurveyModuleCategories.Request): Promise<Surveys.SearchSurveyModuleCategories.Response> => {
  return http.get(`/admin/surveys/modules/search-categories?${qs.stringify(query)}`);
};

export const searchSurveyModulesByCategories = ({ categoryId, ...query }: Surveys.SearchSurveyModulesByCategory.Request): Promise<Surveys.SearchSurveyModulesByCategory.Response> => {
  return http.get(`/admin/surveys/modules/search-categories/${categoryId}/modules?${qs.stringify(query)}`)
  .then((data: Surveys.SearchSurveyModulesByCategory.Response<string>) => ({
    ...data,
    modules: data.modules.map(module => ({
      ...module,
      editedOn: nullableDate(module.editedOn),
      createdOn: nullableDate(module.createdOn),
    })),
  }));
};

export const fetchSurveyModuleDashboard = (query: Surveys.FetchSurveyModuleDashboard.Request): Promise<Surveys.FetchSurveyModuleDashboard.Response> => {
  return http.get(`/admin/surveys/modules/dashboard?${qs.stringify(query)}`)
  .then((data: Surveys.FetchSurveyModuleDashboard.Response<string>) => ({
    ...data,
    items: data.items.map(item => ({
      ...item,
      editedOn: nullableDate(item.editedOn),
      createdOn: nullableDate(item.createdOn),
    })),
  }));
};