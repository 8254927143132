import * as enums from '@enums';

export const isCallCreator = (state: Store.State) => (callId: number) => {
  return state.user.id === state.calls[callId].creatorUserId;
};

export const isRecordingConsentRequired = (status: enums.ApprovalStatus) => {
  return status === enums.ApprovalStatus.NeedsApproval;
};

export const isRecordingConsentResolved = (state: Store.State) => (callId: number) => {
  return !isRecordingConsentRequired(state.calls[callId].recordingConsentStatusId);
};