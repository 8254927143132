import { useState } from 'react';
import { SurveyFormItemBackContext, SurveyFormItemSubmitContext, AIENavigationContext, SurveyFormCanSubmitContext } from './Context';
import { useSubmitAIEScreen, useSurveyFormIsFirstItem, useSurveyMessageGoBack, useSurveyFormAIEItem } from './hooks';

type Props =
  ChildrenProps;

export const SurveyFormAIEContainer = (props: Props) => {

  const goBack = useSurveyMessageGoBack();
  const submit = useSubmitAIEScreen();
  const item = useSurveyFormAIEItem();

  const isFirstItem = useSurveyFormIsFirstItem();

  const [slideIndex, setSlideIndex] = useState(0);

  const backCtx = {
    disabled: isFirstItem,
    isError: goBack.isError,
    isLoading: goBack.isLoading,
    handler: goBack.mutateAsync,
  };

  const submitCtx = {
    isError: submit.isError,
    isLoading: submit.isLoading,
    handler: submit.mutateAsync,
  };

  return (
    <AIENavigationContext.Provider value={{ slideIndex, setSlideIndex }}>
      <SurveyFormItemSubmitContext.Provider value={submitCtx}>
        <SurveyFormItemBackContext.Provider value={backCtx}>
          <SurveyFormCanSubmitContext.Provider value={slideIndex + 1 >= item.content.exercise.entries.length}>
            {props.children}
          </SurveyFormCanSubmitContext.Provider>
        </SurveyFormItemBackContext.Provider>
      </SurveyFormItemSubmitContext.Provider>
    </AIENavigationContext.Provider>
  );
};