import { useCallback, useContext, useMemo, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { useMutation } from '@tanstack/react-query';
import * as api from '@api';
import { useToggle } from '@utils';
import { Remirror } from '@/types';
import * as $conference from '$admin/containers/Call/utils';
import { SectionEditButton, SectionFormButtons } from '$admin/shared';
import { ConferenceDetailsEditor, ConferenceDetailsEditorContainer, ConferenceDetailsEditorOnChange, Toolbar, defaultConferenceDetailsContent, useConferenceDetailsEditor } from '@/components/Conference';
import { ProfileEducation, ProfileEmployment } from '@/components/UserProfile';
import { ProfileOverviewQueryContext, ProfileProfessionalQueryContext } from './Context';
import { Tile } from './Layout';
import styles from './style/Tab.Professional.css';

type Props = unknown;

export const Professional = (props: Props) => {
  const query = useContext(ProfileProfessionalQueryContext);

  const display = useMemo(() => {
    return {
      summary: !!query.data?.summary,
      employment: !!query.data?.employment?.length,
      education: !!query.data?.education?.length,
    };
  }, [query.data]);

  const loading = query.isInitialLoading && !query.data;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <Tile.Root>
            <Tile.Header>Summary</Tile.Header>
            <Tile.Body>
              {loading &&
                <Skeleton
                  height={24}
                  width={100}
                  variant="rounded" />}
              {display.summary && query.data.summary}
              {(!loading && !display.summary) &&
                <div className={styles.na}>Not Available</div>}
            </Tile.Body>
          </Tile.Root>

          <UserNotes />

          <Tile.Root>
            <Tile.Header>Work Experience</Tile.Header>
            <Tile.Body>
              {loading &&
                <EntrySkeleton />}
              {display.employment && query.data.employment.map(({ functions, ...item }) =>
                <ProfileEmployment
                  key={item.id}
                  value={{ ...item, functions: [] }} />)}
              {(!loading && !display.employment) &&
                <div className={styles.na}>Not Available</div>}
            </Tile.Body>
          </Tile.Root>

          <Tile.Root>
            <Tile.Header>Education</Tile.Header>
            <Tile.Body>
              {loading &&
                <EntrySkeleton />}
              {display.education && query.data.education.map(x =>
                <ProfileEducation
                  key={x.id}
                  value={x} />)}
              {(!loading && !display.education) &&
                <div className={styles.na}>Not Available</div>}
            </Tile.Body>
          </Tile.Root>
        </div>
      </div>
    </div>
  );
};

Professional.displayName = 'Tab.Professional';

const EntrySkeleton = () => {
  return (
    <>
      <Skeleton
        height={24}
        width={100}
        variant="rounded" />
      <Skeleton
        height={16}
        style={{ marginTop: 10 }}
        width={75}
        variant="rounded" />
    </>
  );
};

const UserNotes = (props: unknown) => {
  const query = useContext(ProfileOverviewQueryContext);
  const [editing, toggle] = useToggle(false);

  const [value, setValue] = useState<Remirror.RootNode>(query.data.notes);

  const notes = useConferenceDetailsEditor({
    initialState: query.data.notes,
    placeholder: `Enter notes`,
  });

  const handleChange: ConferenceDetailsEditorOnChange = useCallback(params => {
    if (!params.tr?.docChanged) return;

    const value = params.state.doc.toJSON() as Remirror.RootNode;

    setValue(value);
  }, [setValue]);

  const handleReset = useCallback((content: Remirror.RootNode) => {
    if (notes.manager.view) {
      notes.manager.view.updateState(notes.manager.createState({
        content: content || defaultConferenceDetailsContent,
      }));
    }

    toggle();

  }, [
    notes.manager,
    toggle,
  ]);

  const mutation = useMutation([
    `put:users/profiles`,
    query.data.id,
    value,
  ], () => {
    return api.users.profiles.updateUserInfo({
      extended: {
        notes: value,
      },
      userId: query.data.id,
    })
    .then(() => query.refetch());
  }, {
    onSuccess: toggle,
  });

  return (
    <Tile.Root>
      <Tile.Header>
        <div className={styles.editable}>
          <div className={styles.title}>Notes</div>
          <div className={styles.btns}>
            {!editing &&
              <SectionEditButton onClick={toggle} />}
            {editing &&
              <SectionFormButtons
                onCancel={() => handleReset(query.data?.notes)}
                onSubmit={mutation.mutate} />}
          </div>
        </div>
      </Tile.Header>
      <Tile.Body>
        {query.isInitialLoading &&
          <Skeleton
            height={24}
            width={100}
            variant="rounded" />}
        {query.isFetched &&
          <ConferenceDetailsEditorContainer
            onChange={handleChange}
            editable={editing}
            manager={notes.manager}
            state={notes.state}>
            {editing && <Toolbar className={styles.toolbar} />}
            <ConferenceDetailsEditor />
          </ConferenceDetailsEditorContainer>}
      </Tile.Body>
    </Tile.Root>
  );
};