import { AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { querykey } from '@consts';
import * as api from '@api/integrations';
import * as Integrations from '@api/interfaces/integrations';
import { Integrations as Is } from '@/types';

type Data = Integrations.Slack.UpdateChannels.Response;
type Variables = {
  channels: string[];
};

export const useUpdateSlackChannels = (
  options?: UseMutationOptions<Data, AxiosResponse, Variables>
) => {

  const queryClient = useQueryClient();

  return useMutation(['update-slack-channels'], ({ channels }) => {
    return api.updateSlackChannels({ channels });
  }, {
    onSuccess: (res, variables, context) => {

      queryClient.setQueryData<Is.Integrations>([querykey.Integrations.Get], old => ({
        ...old,
        slack: res.slack,
      }));

      options?.onSuccess?.(res, variables, context);
    },
    ...options,
  });
};