export const Access = {
  Get: `get:admin/projects/access`,
  Put: `put:admin/projects/access`,
};

export const Calls = {
  Get: `get:admin/projects/calls`,
};

export const Scheduling = {
  Calendars: {
    Get: (data: Scheduling.Calendars.Get) => [
      `get:admin/projects/scheduling/calendars`,
      data.moderatorIds,
      data.projectId,
      data.userIds,
    ],
  },
  Confirmations: {
    Post: (data: Scheduling.Confirmations.Post) => [
      `post:admin/projects/scheduling/confirmations`,
      data.callId,
      data.projectId,
    ],
  },
  Notifications: {
    Post: (data: Scheduling.Notifications.Post) => [
      `post:admin/project/scheduling/notifications`,
      data.callId,
      data.projectId,
    ],
  },
  Participants: {
    Get: (data: Scheduling.Participants.Get) => [
      `get:admin/projects/scheduling/participants`,
      data.callId,
      data.projectId,
      data.userId,
    ],
  },
  RequestAvailability: {
    Post: (data: Scheduling.RequestAvailability.Post) => [
      `post:admin/projects/scheduling/request-availability`,
      data.projectId,
    ],
  },
  Settings: {
    Get: (data: Scheduling.Settings.Get) => [
      `get:projects/scheduling/settings`,
      data.projectId,
      data.callId,
    ],
  },
};

declare namespace Scheduling {

  export namespace Calendars {
    export type Get = {
      moderatorIds: number[];
      userIds:      number[];
    } & IProjectId;
  }

  export namespace Confirmations {
    export type Post =
      & ICallId
      & IProjectId;
  }

  export namespace Notifications {
    export type Post =
      & ICallId
      & IProjectId;
  }

  export namespace Participants {
    export type Get =
      & ICallId
      & IProjectId
      & Partial<IUserId>;
  }

  export namespace RequestAvailability {
    export type Post = IProjectId;
  }

  export namespace Settings {
    export type Get =
      & ICallId
      & IProjectId;
  }
}