import * as Twilio from '@twilio/conversations';
import { bindActionCreators } from '@reduxjs/toolkit';
import * as actions from '@actions';
import * as api from '@api';
import * as enums from '@enums';
import store from '@store';
import initialState from '@store/initial-state';
import * as utils from '@utils';
import { IChat } from '@/types';

const boundActions = bindActionCreators({
  batchActions: actions.batchActions,
  conversationAdded: actions.chatConversationAdded,
  conversationJoined: actions.chatConversationJoined,
  conversationRemoved: actions.chatConversationRemoved,
  conversationUpdated: actions.chatConversationUpdated,
  conversationsLoaded: actions.chatConversationsLoaded,
  chatConnectionStateChange: actions.chatConnectionStateChange,
  participantAdded: actions.chatConversationParticipantAdded,
  messageAdded: actions.chatMessageAdded,
  messageSent: actions.chatMessageSent,
  platformMessageAdded: actions.platformMessageAdded,
}, store.dispatch);

let client: Twilio.Client = null;
let activeConversation: Twilio.Conversation = null;
let user: Store.User = initialState.user;
let pendingMessages: number[] = [];

export function getClient() {
  return client;
}

export function getActiveConversation() {
  return activeConversation && activeConversation.sid;
}

export function setActiveConversation(conversation: Twilio.Conversation) {
  logCollapsed('set active conversation', conversation);
  activeConversation = conversation;
}

function handleConnectionStateChanged(connection: Twilio.ConnectionState) {
  boundActions.chatConnectionStateChange({ connection });
}

const initialize = async () => {
  const { token } = await getToken();

  client = new Twilio.Client(token);
  logInfo('Chat client instantiated');

  client.on('connectionStateChanged', handleConnectionStateChanged);
  client.on('connectionError', logError);

  const conversations = await client.getSubscribedConversations()
    .then(paginator => paginator.items);

  const messages: Store.Chat.Messages = await Promise.all(
    conversations.map(conversation => conversation.getMessages()
      .then(paginator => ({
        [conversation.sid]: paginator.items.map(message => {
          const attributes = message.attributes as IChat.ResolvedTwilioMessageAttributes;
          return {
            type: 'twilio' as const,
            data: {
              conversationSid: message.conversation.sid,
              sid: message.sid,
              userId: +message.author,
              body: message.body,
              timestamp: message.dateUpdated,
              attributes,
            },
          };
        }),
      })))
  ).then(items => items.reduce((acc, item) => ({ ...acc, ...item }), {}));

  const participantsByConversation = await Promise.all(
    conversations.map(conversation => conversation.getParticipants()
      .then(participants => ({
        [conversation.sid]: participants.map(m => +m.identity),
      })))
  ).then(items => items.reduce((acc, item) => ({ ...acc, ...item }), {}));

  const maps = conversations.reduce<ConversationParticipantMap>((acc, conversation) => {
    conversation.on('messageAdded', handleMessageAdded);

    const userIds = getConversationParticipantUserIds(conversation);
    const participantStatus = computeParticipantStatus(participantsByConversation[conversation.sid]);

    return {
      conversations: {
        ...acc.conversations,
        [conversation.sid]: conversation,
        ids: [...acc.conversations.ids, conversation.sid],
      } as ConversationParticipantMap['conversations'],
      users: userIds.reduce((usersMap, userId) => ({
        ...usersMap,
        [userId]: {
          userId,
          conversationSid: conversation.sid,
          status: participantStatus(userId),
        },
      }), acc.users),
    };
  }, {
    conversations: { ids: [] } as ConversationParticipantMap['conversations'],
    users: {},
  });

  boundActions.conversationsLoaded({
    conversations: maps.conversations,
    messages,
    participants: maps.users,
  });

  client.on('conversationAdded',     handleConversationAdded);
  client.on('conversationJoined',    handleConversationJoined);
  client.on('conversationRemoved',   handleConversationRemoved);
  client.on('conversationUpdated',   handleConversationUpdated);
  client.on('participantJoined',     handleParticipantJoined);
  client.on('tokenAboutToExpire',    onTokenExpiring);
};

const getStoreState = (): Store.State => {
  return store.getState();
};

const getToken = () => {
  return api.messages.getToken({ userId: getStoreState().user.id });
};

const onTokenExpiring = () => {
  getToken()
    .then(res => client.updateToken(res.token))
    .catch((e: Error) => logError(`Error updating chat token ${e.message}`));
};

const getConversationParticipantUserIds = (conversation: Twilio.Conversation) => {
  const user = getStoreState().user;
  const attributes = conversation.attributes as ConversationAttributes;
  return attributes.userIds
    .filter(id => id !== user.id);
};

const getExistingUserIds = (userIds: number[]) => {
  const contacts = getStoreState().contacts;
  return userIds.filter(userId => contacts[userId]);
};

const getParticipantStatus = (userId: number) => {
  return getStoreState().chat.participants[userId]?.status;
};

const getMissingUserIds = (userIds: number[]) => {
  const contacts = getStoreState().contacts;
  return userIds.filter(userId => !contacts[userId]);
};

const addPendingMembers = (message: Twilio.Message) => {
  const userIds = getConversationParticipantUserIds(message.conversation);
  const pendingIds = userIds.filter(id => getParticipantStatus(id) !== enums.ChatMemberStatus.Joined);

  if (pendingIds.length) logCollapsed(`Adding members ${pendingIds.join(', ')} to channel`);

  pendingIds.forEach(id => message.conversation.add(`${id}`)
            .catch((e: Error) => logError(`Twilio Error: ${e.message}`)));
};

const computeParticipantStatus = (memberUserIds: number[]) => (userId: number) => {
  const isMember = memberUserIds.includes(userId);

  return isMember
    ? enums.ChatMemberStatus.Joined
    : enums.ChatMemberStatus.NotJoined;
};

const shouldUpdatePendingMessage = (message: IChat.ResolvedTwilioMessage) => {
  return +message.data.userId === getStoreState().user.id
      && pendingMessages.length
      && pendingMessages.some(tempId => tempId ===  message.data.attributes.tempId);
};

const handleMessageAdded = (message: Twilio.Message) => {
  addPendingMembers(message);

  if (activeConversation && message.conversation.sid === activeConversation.sid) {
    if (!message.conversation.lastMessage || message.conversation.lastMessage.index !== message.index) {
      message.conversation.updateLastReadMessageIndex(message.index)
        .then(_ => api.messages.consumed({
          conversationSid: message.conversation.sid,
          index: message.index,
          timestamp: new Date(),
        }));
    }
  }

  const resolvedMessage: IChat.ResolvedTwilioMessage = {
    type: 'twilio' as const,
    data: {
      conversationSid: message.conversation.sid,
      sid: message.sid,
      userId: +message.author,
      body: message.body,
      timestamp: message.dateUpdated,
      attributes: message.attributes as IChat.ResolvedTwilioMessageAttributes,
    },
  };

  const data = {
    conversationSid: message.conversation.sid,
    message: resolvedMessage,
  };

  if (shouldUpdatePendingMessage(resolvedMessage)) {
    boundActions.messageSent(data);
    pendingMessages = pendingMessages.filter(tempId => tempId !== resolvedMessage.data.attributes.tempId);
  } else {
    boundActions.messageAdded(data);
  }
};

const handleConversationAdded = (conversation: Twilio.Conversation) => {
  const isChannelCreator = getStoreState().user.id === +conversation.createdBy;
  const memberStatus = !isChannelCreator
                     ? enums.ChatMemberStatus.Joined
                     : enums.ChatMemberStatus.NotJoined;

  const userIds = getConversationParticipantUserIds(conversation);
  const missingIds = getMissingUserIds(userIds);
  const existingIds = getExistingUserIds(userIds);

  const participants = existingIds.map(id => ({
    conversationSid: conversation.sid,
    userId: id,
    status: memberStatus,
  }));

  if (missingIds.length) {
    return Promise.all(missingIds.map(userId => api.profiles.getContactInfo({ userId })))
      .then(contacts => {
        boundActions.batchActions([
          actions.contactsAdded({ contacts }),
          actions.chatConversationAdded({
            conversation: conversation,
            conversationSid: conversation.sid,
            participants,
          }),
        ]);
      });
  }

  boundActions.conversationAdded({
    conversation,
    conversationSid: conversation.sid,
    participants,
  });
};

const handleConversationJoined = (conversation: Twilio.Conversation) => {
  conversation.getMessages()
    .then(paginator => {
      boundActions.conversationJoined({
        conversationSid: conversation.sid,
        messages: paginator.items.map(message => {
          const attributes = message.attributes as IChat.ResolvedTwilioMessageAttributes;
          return {
            type: 'twilio',
            data: {
              conversationSid: conversation.sid,
              sid: message.sid,
              userId: +message.author,
              body: message.body,
              timestamp: message.dateUpdated,
              attributes,
            },
          };
        }),
      });

      conversation.on('messageAdded', handleMessageAdded);
    });
};

const handleConversationRemoved = (conversation: Twilio.Conversation) => {
  const attributes = conversation.attributes as IChat.ConversationAttributes;
  boundActions.conversationRemoved({
    conversationSid: conversation.sid,
    userIds: attributes.userIds,
  });
};

const handleConversationUpdated = ({ conversation, updateReasons }: { conversation: Twilio.Conversation; updateReasons: Twilio.ConversationUpdateReason[] }) => {
  boundActions.conversationUpdated({
    conversationSid: conversation.sid,
    conversation,
  });
};

const handleParticipantJoined = (participant: Twilio.Participant) => {
  const userId = +participant.identity;

  if (userId !== getStoreState().user.id) {
    boundActions.participantAdded({
      participant: {
        conversationSid: participant.conversation.sid,
        userId,
        status: enums.ChatMemberStatus.Joined,
      },
    });
  }
};

const addPendingMessage = (tempId: number) => {
  pendingMessages = pendingMessages.concat(tempId);
};

export function createConversation({ withUserId }: { withUserId: number }): Promise<Twilio.Conversation> {
  const userIds =  [ getStoreState().user.id, withUserId ];
  const uniqueName = userIds.join('|');

  return client.createConversation({
    attributes: {
      // tempId: uniqueName,
      userIds,
    },
    uniqueName,
  })
  .then(conversation => {
    conversation.join()
      .catch((e: Error) => {
        logError(`Error joining conversation: ${e.message}`);
        return null;
      });
    return conversation;
  })
  .catch((e: Error) => {
    logError(`Error creating conversation ${e.message}`);
    return null;
  });
}

export function deleteConversation(conversationSid: string) {
  return client.getConversationBySid(conversationSid)
               .then(conversation => conversation.delete())
               .catch((e: Error) => logError(`Error removing conversation ${e.message}`));
}

export const sendMessage = async (data: { body: string; paid: boolean }) => {
  const activeUserId = getStoreState().user.id;
  const attributes = activeConversation.attributes as IChat.ConversationAttributes;
  const toUserId = attributes.userIds.find(id => id !== activeUserId);

  const result = await api.messages.send({
    userId: toUserId,
    body: data.body,
    paid: data.paid,
  });

  if (result.bypass) {
    const tempId = Date.now();

    const pendingMessage: IChat.PendingMessage = {
      type: 'pending',
      data: {
        conversationSid: activeConversation.sid,
        userId: activeUserId,
        body: data.body,
        timestamp: new Date(),
        attributes: {
          tempId,
        },
      },
    };

    boundActions.messageAdded({
      conversationSid: activeConversation.sid,
      message: pendingMessage,
    });

    addPendingMessage(tempId);

    activeConversation.sendMessage(data.body, { tempId });
  } else {
    if (result.platformMessage) {
      boundActions.platformMessageAdded({
        conversationSid: result.platformMessage.channelSid,
        message: result.platformMessage,
      });
    }
  }
};

const cleanup = () => {
  if (client) {
    logInfo('chat client closing');

    client.off('connectionStateChanged', handleConnectionStateChanged);
    client.off('connectionError', logError);
    client.off('conversationAdded', handleConversationAdded);
    client.off('conversationJoined', handleConversationJoined);
    client.off('conversationRemoved', handleConversationRemoved);
    client.off('conversationUpdated', handleConversationUpdated);
    client.off('participantJoined', handleParticipantJoined);
    client.off('tokenAboutToExpire', onTokenExpiring);

    client.shutdown();
  }
};

function isEnabled() {
  const state = getStoreState();
  const group = state.group;
  const user = state.user;

  if (!user.id) return false;

  const hasAccess = utils.hasChatAccess(state.user, group);

  return hasAccess;
}

const observe = () => {
  const state = getStoreState();

  if (!user.id && state.user.id) {
    if (isEnabled()) {
      initialize();
    }
  }

  if (user.id && !state.user.id) {
    cleanup();
  }

  user = state.user;
};

const subscribe = () => {
  return store.subscribe(observe);
};

subscribe();

const logInfo = (event: string) => {
  process.env.__DEV__ && console.info(
    `%c chat   %c${event.toUpperCase()} %c⌁`,
    'color: #979797;',
    'color: #00ffff; font-weight: bold;',
    'color: #d7ff00;'
  );
};

const logCollapsed = (label: string, ...args: unknown[]) => {
  if (!process.env.__DEV__) return;

  console.groupCollapsed(
    `%c chat   %c${label.toUpperCase()} %c⌁`,
    'color: #979797;',
    'color: #00ffff; font-weight: bold;',
    'color: #d7ff00;'
  );
  args.forEach(x => console.info(x));
  console.groupEnd();
};

const logError = (e: unknown) => {
  process.env.__DEV__ && console.error(e);
};

type ConversationParticipantMap = {
  conversations: Record<string, Twilio.Conversation> & { ids: string[] };
  users: Record<number, {
    userId: number;
    conversationSid: string;
    status: enums.ChatMemberStatus;
  }>;
};

type ConversationAttributes = {
  userIds: number[];
};