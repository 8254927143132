import { useCallback } from 'react';
import type { NumericFormatProps } from 'react-number-format';
import { useSurveyQuestionConditionValue } from '@containers/SurveyBuilder.Logic';
import type { SurveyLogic } from '@/types';
import { ComparateInput } from './Builder.ComparateInput';

type Props = {
  field?: 'comparate' | 'ordinal';
} & Omit<NumericFormatProps, 'value' | 'onValueChange' | 'onChange'>;

export const ValueInput = ({
  field = 'comparate',
  ...props
}: Props) => {

  const [condition, updateConditionValue] = useSurveyQuestionConditionValue<SurveyLogic.ConditionWithValue>();

  const value = condition.value?.[field] as number;

  const handleUpdate = useCallback((value: number) => {
    updateConditionValue({
      ...condition.value,
      [field]: value,
    });
  }, [field, updateConditionValue, condition]);

  return (
    <ComparateInput
      {...props}
      onChange={handleUpdate}
      value={value} />
  );
};