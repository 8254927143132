import { useContext } from 'react';
import * as $conference from '$admin/containers/Call/utils';
import { OffPlatform } from './Settings.OffPlatform';
import { OnPlatform } from './Settings.OnPlatform';
import { SettingsContext } from './Context';

type Props = unknown;

export const Form = (props: Props) => {
  const ctx = {
    settings: useContext(SettingsContext),
  };

  return $conference.isOffPlatform(ctx.settings.conference)
    ? <OffPlatform />
    : <OnPlatform />;
};

Form.displayName = 'ConferenceSettings.Form';