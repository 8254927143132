import { useSelector } from 'react-redux';

const mapState = (state: Store.State) => ({
  calls: state.calls,
  contacts: state.contacts,
});

const useOtherCallParticipant = (params: ICallId & IUserId) => {
  const state = useSelector(mapState);
  const call = state.calls[params.callId];
  const participantId = [ call.creatorUserId, call.userId ].find(id => id !== params.userId);

  return state.contacts[participantId];
};

export { useOtherCallParticipant };
export default useOtherCallParticipant;