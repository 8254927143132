import { useMemo } from 'react';
import { useSurveyBuilderState, useIsEditingSurvey } from '@containers/SurveyBuilder/hooks';
import { useCloneProject } from '@containers/GroupProject/hooks/useProjectCloning';
import { BackButton, Footer, Header, NextButton, Title } from '@screens/ProjectCreation';
import { useLock } from '@utils';
import { ProjectSurveyBuilder, useProjectSurveyBuilderTabs } from '@/components/SurveyBuilder';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import styles from './style/ProjectSurvey.css';
import { useProjectState } from '@/containers/GroupProject/hooks';

export const ProjectSurvey = () => {
  const clone = useCloneProject();
  const { project } = useProjectState();

  const [state] = useSurveyBuilderState();
  const isEditing = useIsEditingSurvey();

  const [_, lock] = useLock();

  const canSave = useMemo(() => {
    return state.survey &&
      !isEditing &&
      state.survey.sections
        .every(e => !!e.name && state.survey.items.some(s => s.section.identifier === e.identifier));
  }, [
    isEditing,
    state.survey,
  ]);

  const tabs = useProjectSurveyBuilderTabs();

  if (!state.survey.id) {
    return (
      <ActivityIndicator />
    );
  }

  return (
    <div>

      <Header className={styles.row}>
        <Title className={styles.row}>Survey Builder</Title>
      </Header>

      <div className={styles.row}>
        <ProjectSurveyBuilder tabs={tabs} projectId={project.id} />
      </div>

      <Footer>
        <BackButton />
        <NextButton
          disabled={!canSave}
          onClick={lock(clone)}>
          Complete
        </NextButton>
      </Footer>
    </div>
  );
};

export default ProjectSurvey;