import { useMemo, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ObjectAccessContainer } from '@containers/ObjectAccess';
import * as consts from '@consts';
import { WorkspaceFolder, WorkspaceObject } from '@/types/workspace';
import { useDeleteFolderPrompt } from '@/components/Workspace.Folder/hooks/useDeleteFolderPrompt';
import { useNewFolderModal } from '@/components/Workspace.Folder/hooks/useNewFolderModal';
import { useRenameFolderModal } from '@/components/Workspace.Folder/hooks/useRenameFolderModal';
import { MoveObjectContainer } from '@containers/WorkspaceObject';
import { useToggle } from '@/utils';
import { WorkspaceObjectType } from '@/enums';
import { useObjectOwnershipModal } from '@/components/WorkspaceObject/hooks';
import { MoveObjectModal } from '@/components/WorkspaceObject';
import { useObjectAccessModal } from '@/components/ObjectAccess';
import { RenameFolderContainer } from './RenameFolderContainer';
import { DeleteFolderContainer } from './DeleteFolderContainer';
import { FolderActionsContext, FolderMenuItemsContext } from './Context';

type Props =
  {
    folder: WorkspaceFolder;
    object: WorkspaceObject;
  } &
  ChildrenProps
;

export const FolderActionsContainer = ({ children, folder, object }: Props) => {

  const folderId = folder.id;
  const objectId = object.id;
  const workspaceId = object.workspaceId;

  const menu = useContext(FolderMenuItemsContext);

  const history = useHistory();

  const [toggleNewFolderModal, NewFolderModal] = useNewFolderModal({
    location: folder.name,
    parentObject: object,
  });
  const [toggleOwnershipModal, ObjectOwnershipModal] = useObjectOwnershipModal();
  const [toggleDeleteFolderPrompt, DeleteFolderPrompt] = useDeleteFolderPrompt();
  const [toggleRenameModal, RenameFolderModal] = useRenameFolderModal();
  const [toggleAccessModal, ManageAccessModal] = useObjectAccessModal();
  const [moveObjectModalOpen, toggleMoveObjectModal] = useToggle();

  const createProject = useCallback(() => {
    history.push(`${consts.path.Projects.New}?parentObjectId=${object.id}`);
  }, [
    history,
    object.id,
  ]);

  const actions = useMemo(() => ({
    changeOwner: toggleOwnershipModal,
    createFolder: toggleNewFolderModal,
    createProject,
    delete: toggleDeleteFolderPrompt,
    manageAccess: toggleAccessModal,
    rename: toggleRenameModal,
    move: toggleMoveObjectModal,
  }), [
    createProject,
    toggleDeleteFolderPrompt,
    toggleAccessModal,
    toggleNewFolderModal,
    toggleRenameModal,
    toggleOwnershipModal,
    toggleMoveObjectModal,
  ]);

  return (
    <FolderActionsContext.Provider value={actions}>
      {children}
      {menu.canAddChildren &&
        <NewFolderModal />}
      {menu.canDelete &&
        <DeleteFolderContainer folderId={folder.id} objectId={objectId} workspaceId={workspaceId}>
          <DeleteFolderPrompt />
        </DeleteFolderContainer>}
      {menu.canRename &&
        <RenameFolderContainer folder={folder}>
          <RenameFolderModal />
        </RenameFolderContainer>
      }
      {menu.canManageAccess && (
        <ObjectAccessContainer objectId={objectId} workspaceId={workspaceId}>
          <ManageAccessModal />
        </ObjectAccessContainer>
      )}
      {menu.canMove && (
        <MoveObjectContainer
          enabled={moveObjectModalOpen}
          objectId={objectId}
          objectType={WorkspaceObjectType.Folder}>
          <MoveObjectModal
            open={moveObjectModalOpen}
            onClose={toggleMoveObjectModal} />
        </MoveObjectContainer>
      )}
      {menu.canManageAccess &&
        <ObjectAccessContainer objectId={objectId} workspaceId={workspaceId}>
          <ManageAccessModal />
        </ObjectAccessContainer>
      }
    </FolderActionsContext.Provider>
  );
};