import { useMemo } from 'react';
import { useRepeatQuestionResponsesData, useRepeatSurveyResponsesState } from '@containers/RepeatSurveyResponses/hooks';
import { RepeatSurvey } from '@containers/RepeatSurveyResponses/interfaces';
import { SurveyQuestionType } from '@enums';

export const useFilteredRows = () => {
  const { query } = useRepeatSurveyResponsesState<RepeatSurvey.QueryState.MatrixSlider>();
  const { question } = useRepeatQuestionResponsesData<SurveyQuestionType.Sliders>();

  const rows = useMemo(() => {
    return query.rowIds
      ? question.matrixRows
          .filter(f => query.rowIds.includes(f.base.id))
      : question.matrixRows;
  }, [
    query.rowIds,
    question.matrixRows,
  ]);

  return rows;
};

export default useFilteredRows;