import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { pathname } from '@consts';

export const useIsSettingsRoot = () => {
  const location = useLocation();

  return useMemo(() => {
    return pathname.Settings === location.pathname;
  }, [
    location.pathname,
  ]);
};