import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import { path } from '@consts';
import { cx } from '@utils';
import styles from './style/Sidebar.css';

type Props = {
  className?: string;
};

export const GroupContactsSidebar = memo((props: Props) => {

  return (
    <div className={cx(styles.root, props.className)}>
      {items.map((x, i) =>
        <NavLink
          activeClassName={styles.active}
          className={styles.link}
          exact={x.exact}
          key={i}
          to={x.path}>
          <div className={styles.item}>
            <span>{x.name}</span>
          </div>
        </NavLink>
      )}
    </div>
  );
});

const items = [{
  exact: true,
  name: `Contacts`,
  path: path.Contacts.Root,
}];

GroupContactsSidebar.displayName = 'Group.Contacts.Sidebar';