import { useState, useCallback } from 'react';
import { useCoordinator } from '@containers/Conference/hooks';
import type { TelInputState } from '@/components/TelInput';
import { PhoneInputControlled } from '@/components/TelInput';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import { useModal } from '@/components/Modal/hooks';
import Toast from '@/components/Toast';
import { Button } from '@/components/Button';
import styles from './style/Modal.Dialout.css';

type Props = {
  text: string;
  onDialOut?: () => void;
  defaultPhone: string;
} & Pick<ModalProps, 'open' | 'onClose'>;

export const CallDialOutModal = ({ onClose, open, text, onDialOut, defaultPhone }: Props) => {
  const [state, setState] = useState<TelInputState>({
    empty: !!defaultPhone,
    valid: !!defaultPhone,
    value: defaultPhone,
  });
  const { dialOutParticipant, state: conferenceState } = useCoordinator();

  const dialOut = useCallback(() => {
    dialOutParticipant({
      dialOutValue: state.value,
      conferenceIdentifier: conferenceState.conference.conferenceIdentifier,
    });
    Toast.success({
      title: `We've dialed out to ${state.value}`,
      body: 'You should receive a call shortly',
    });
    onDialOut?.();
    onClose();
  }, [state.value, conferenceState.conference.conferenceIdentifier, dialOutParticipant, onClose, onDialOut]);

  return (
    <Modal
      className={styles.root}
      open={open}
      onClose={onClose}>
      <div className={styles.customText}>
        {text}
      </div>
      <div className={styles.enterText}>
        {`Enter your number and we'll call you and connect you to the conference.`}
      </div>
      <div className={styles.phoneInput}>
        <PhoneInputControlled
          value={state.value}
          onChange={data => setState(data)} />
      </div>
      <div>
        <Button
          disabled={!state.valid}
          variant='brick'
          onClick={dialOut}>Confirm
        </Button>
      </div>
    </Modal>
  );
};

export const useDialoutModal = () => useModal(CallDialOutModal, { keepMounted: false });