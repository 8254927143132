import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import * as $api from '@api/transcripts';
import type { Transcripts } from '@api/interfaces';

type Vars = Transcripts.StarTranscriptQuote.Request;
export type StarQuoteOptions = UseMutationOptions<Transcripts.StarTranscriptQuote.Response, AxiosResponse, Vars>;

export const useStarTranscriptQuoteMutation = (options?: StarQuoteOptions) => {

  return useMutation({
    mutationFn: (data: Vars) => {
      return $api.starTranscriptQuote({
        content: data.content,
        fromTs: data.fromTs,
        toTs: data.toTs,
        transcriptId: data.transcriptId,
      });
    },
    ...options,
  });
};