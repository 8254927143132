import { useCallback, useEffect, useRef } from 'react';
import { useInterval } from '@utils/hooks';
import { useTipaltiOnboardingPayeeUrl } from './useTipaltiOnboardingPayeeUrl';

type Props = {
  userId: number;
  onClosed?: () => void;
  onBlocked?: () => void;
};

export default function useTipaltiOnboardingPopup({ userId, onBlocked, onClosed }: Props) {
  const popup = useRef<Window>();
  const isBlocked = useRef<boolean>(false);
  const onLoadOpen = useRef<boolean>(false);

  const query = useTipaltiOnboardingPayeeUrl({
    userId,
    redirect: true,
    updating: false,
  });

  const open = useCallback(() => {
    isBlocked.current = false;

    const width = screen.availWidth >= 900 ? 700 : 500;
    const height = Math.max(400, screen.availHeight - 200);

    const options = {
      resizable: 1,
      scrollbars: 1,
      width,
      height,
      top: window.screenY + (window.outerHeight - height) / 2.5,
      left: window.screenX + (window.outerWidth - width) / 2,
    };

    const opts = Object.keys(options).map((k: keyof typeof options) => `${k}=${options[k]}`).join(',');

    try {
      popup.current = window.open(query.data.url, '', opts);

      if (!popup.current || popup.current.closed || typeof popup.current.closed === 'undefined') {
        isBlocked.current = true;
        onBlocked?.();
      } else {
        try {
          popup.current.focus();
        } catch (e) {
          isBlocked.current = true;
          onBlocked?.();
        }
      }
    } catch (e) {
      isBlocked.current = true;
      onBlocked?.();
    }
  }, [onBlocked, query.data?.url]);

  const close = useCallback(() => {
    if (popup.current) {
      popup.current.close();
    }
  }, []);

  useEffect(() => {
    if (!onLoadOpen.current && query.data?.url?.length) {
      onLoadOpen.current = true;
      open();
    }
  }, [open, query.data?.url]);

  const pollClosed = useCallback(() => {
    if (!isBlocked.current && popup.current && popup.current.closed) {
      onClosed?.();
    }
  }, [onClosed]);

  useInterval(pollClosed, 500);

  return [open, close] as const;
}

export { useTipaltiOnboardingPopup };