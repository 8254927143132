import {
  AvailableEventMetadata,
  CalendarDateEvent,
  CalendarDateSegment,
  CalendarISOEvent,
  CalendarISOSegment,
  ScheduledEventMetadata,
} from '@/types';
import { safeJsonDate } from '@/utils';

export const transformCalendarEvents = (items: CalendarISOEvent[]) => {
  return items.map(transformCalendarEvent);
};

export const transformCalendarEvent = (event: CalendarISOEvent): CalendarDateEvent => {
  return {
    ...event,
    ...transformRange(event),
    segments: {
      hour: transformEventSegments(event),
    },
    metadata: transformCalendarEventMetadata(event.metadata),
  };
};

export function transformCalendarEventMetadata(metadata: ScheduledEventMetadata<string> | AvailableEventMetadata)  {
  const castMetadata = (metadata as ScheduledEventMetadata<string>);
  const call = castMetadata.call;
  if (!call) return metadata as AvailableEventMetadata;

  return {
    ...castMetadata,
    call: {
      ...call,
      approvedOn: safeJsonDate(call.approvedOn),
      createdAt: safeJsonDate(call.createdAt),
      screenedOn: safeJsonDate(call.screenedOn),
      timeStart: safeJsonDate(call.timeStart),
      timeEnd: safeJsonDate(call.timeEnd),
    },
  } as ScheduledEventMetadata;
}

function transformEventSegments(event: CalendarISOEvent): CalendarDateSegment[] {
  const slots = event.segments?.hour ?? [];

  return slots.map(transformRange);
}

function transformRange(item: CalendarISOSegment) {
  return {
    start: new Date(item.start),
    end: new Date(item.end),
  };
}