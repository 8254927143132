import { isProduction } from '@utils/environment';
import { ConnectAccountProvider } from '@/components/ConnectAccount';
import { SettingsTitle } from '@/components/Settings';
import { GoogleCalendarIntegration } from './Settings.Integrations.GoogleCalendar';
import { GoogleContactsIntegration } from './Settings.Integrations.GoogleContacts';
import { OutlookCalendarIntegration } from './Settings.Integrations.OutlookCalendar';
import { OutlookContactsIntegration } from './Settings.Integrations.OutlookContacts';
import { SlackIntegration } from './Settings.Integrations.Slack';
import { TableauIntegration } from './Settings.Integrations.Tableau';
import { CalendlyIntegration } from './Settings.Integrations.Calendly';
import { S3Integration } from './Settings.Integrations.AWS.S3';
import { GoogleDriveIntegration } from './Settings.Integrations.GoogleDrive';
import { ZoomIntegration } from './Settings.Integrations.Zoom';
import styles from './style/Settings.Integrations.css';

export const Integrations = () => {
  const canZoom = !isProduction();
  return (
    <div className={styles.root}>
      <SettingsTitle
        title="Integrations" />

      <div className={styles.items}>
        <ConnectAccountProvider>
          <GoogleCalendarIntegration />
          <OutlookCalendarIntegration />
          <GoogleContactsIntegration />
          <OutlookContactsIntegration />
          <SlackIntegration />
          <TableauIntegration />
          <CalendlyIntegration />
          <S3Integration />
          <GoogleDriveIntegration />
          {canZoom && <ZoomIntegration />}
        </ConnectAccountProvider>
      </div>
    </div>
  );
};

export default Integrations;