import { useCallback, useContext, useMemo, useState } from 'react';
import MultiSelect from 'react-select/creatable';
import { useMutation, useQuery } from '@tanstack/react-query';
import { type CellProps, type Column as RTColumn, useTable, useFlexLayout } from 'react-table';
import * as api from '@api';
import { useSuspendInitialLoading } from '@containers/GroupProject/hooks';
import type { WorkspaceObjectRole } from '@enums';
import { ButtonActivityIndicator, ButtonOutlined } from '@presentation';
import type { SearchResultItem } from '@/services/api/interfaces/projects.access';
import { isValidEmail, arr } from '@utils';
import { Checkbox } from '@/components/Checkbox';
import { Header } from '@/components/Modal';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { ProjectAccessQueryContext } from '@/components/Project.Access/Context';
import * as Table from '@/components/Table';
import * as $api from '$admin/api';
import type * as API from '$admin/api/interfaces';
import { Select } from '$admin/components/Select';
import { usePublishModalNavigateNext } from './PublishModal.Navigation';
import { FilesTableContext } from './context';
import styles from './style/PublishModal.css';

type Props = {
  onClose: () => void;
};

export const CreateClientUsers = (props: Props) => {
  const { projectId } = useContext(FilesTableContext);
  const access = useContext(ProjectAccessQueryContext);
  const [keyword, setKeyword] = useState('');
  const [rows, setRows] = useState<RowItem[]>([]);
  const [notify, setNotify] = useState(true);
  const handleNext = usePublishModalNavigateNext(props.onClose);
  const [domains, setDomains] = useState<Record<string, number>>({});

  const exclude = useMemo(() => {
    return rows.map(x => x.email);
  }, [rows]);

  const query = useQuery({
    queryKey: [`get:groups/search`],
    queryFn: () => {
      return api.groups.fetchAccounts()
      .then(res => {
        const users = access.data?.items ?? [];
        console.log(users);
        const unregistered = users
        .filter(x => x.offPlatform)
        .map(x => {
          const [_, domain] = x.email.match(/@([^@]+)$/) || [];

          return {
            ...x,
            domain,
          };
        });

        const map = res.items.reduce((acc, x) => {
          if (!x.attributes.url) return acc;

          return {
            ...acc,
            [x.attributes.url]: x.id,
          };
        }, {} as Record<string, number>);

        setDomains(map);

        const initial = unregistered.reduce((acc, x) => {
          const groupId = map[x.domain];

          const user = {
            ...x,
            matchingGroupId: groupId ?? null,
          };

          return [...acc, user];
        }, [] as AccessItem[]);

        setRows(transform(initial));

        return res;
      });

    },
    refetchOnWindowFocus: false,
  });

  const search = useQuery({
    queryKey: [
      `get:admin/users/search/offplat`,
      keyword,
      exclude,
    ],
    queryFn: () => {
      return $api.search.users.offplat({
        name: keyword,
        exclude,
      });
    },
    enabled: !!keyword.trim(),
    refetchOnWindowFocus: false,
  });

  const count = useMemo(() => {
    return rows.filter(x => x.checked).length;
  }, [rows]);

  const toggleChecked = useCallback((email: string) => {
    setRows(items => items.map(x => x.email === email ? { ...x, checked: !x.checked } : x));
  }, []);

  const handleChangeGroup = useCallback((email: string, groupId: number) => {
    setRows(items => items.map(x => x.email === email ? { ...x, groupId } : x));
  }, []);

  const handleChangeText = useCallback((email: string, key: 'firstName' | 'lastName', value: string) => {
    setRows(items => items.map(x => x.email === email ? { ...x, [key]: value } : x));
  }, []);

  const mutation = useMutation({
    mutationFn: (data: API.Users.RegisterClientUsers.Request) => {
      return $api.users.registerClientUsers({
        items: data.items,
        notify: data.notify,
        projectId: data.projectId,
      })
      .then(() => access?.refetch?.());
    },
    mutationKey: [
      `post:admin/users/register-client-users`,
      projectId,
      notify,
      rows,
    ],
    onSuccess: handleNext,
  });

  const handleSubmit = useCallback(() => {
    if (!count) {
      handleNext();
    } else {
      mutation.mutate({
        items: rows.filter(x => x.checked),
        notify,
        projectId,
      });
    }
  }, [
    notify,
    rows,
    mutation,
    count,
    handleNext,
    projectId,
  ]);

  const getOptionLabel = useCallback((item: SearchItem & CreateOption) => {
    if (item.value) {
      return `Create ${item.value}`;
    } else {
      return `${item.name} (${item.email})`;
    }
  }, []);

  const isValidNewOption = useCallback((str: string) => {
    if (!isValidEmail(str)) return false;

    const value = str?.toLowerCase?.()?.trim();

    const exists = exclude.includes(value);

    const invalid = [
      `@trinitylifesciences.com`,
      `@trinitypartners.com`,
    ].some(email => value?.endsWith?.(email));

    return !exists && !invalid;
  }, [exclude]);

  const handleCreate = useCallback((value: string) => {
    const [_, domain] = value.match(/@([^@]+)$/) || [];
    const groupId = domains[domain];

    const custom = {
      checked: !!groupId,
      email: value,
      firstName: '',
      lastName: '',
      groupId: groupId ?? null,
      id: null,
      name: '',
    };
    setRows(x => [...x, custom]);
    setKeyword('');
  }, [
    domains,
    setRows,
  ]);

  const matchDomainFromEmail = useCallback((email: string) => {
    const [_, domain] = email.match(/@([^@]+)$/) || [];

    return domains[domain] ?? null;
  }, [
    domains,
  ]);

  const handleSelect = useCallback((item: SearchResultItem) => {
    const [_, domain] = item.email.match(/@([^@]+)$/) || [];
    const groupId = domains[domain];

    const custom = {
      checked: !!groupId,
      email: item.email,
      firstName: '',
      lastName: '',
      groupId: groupId ?? null,
      id: item.id,
      name: item.name,
    };
    setRows(x => [...x, custom]);
    setKeyword('');
  }, [
    domains,
    setRows,
    setKeyword,
  ]);

  const columns = useMemo<RTColumn<RowItem>[]>(() => [
    {
      id: 'checked',
      Header: '',
      Cell: ({ row }: CellProps<RowItem>) => {
        const match = matchDomainFromEmail(row.original.email);

        return (
          <div className={styles.checkbox}>
            <Checkbox
              disabled={!match}
              checked={row.original.checked}
              onChange={() => toggleChecked(row.original.email)} />
          </div>
        );
      },
      minWidth: 30,
      maxWidth: 30,
    },
    {
      id: 'email',
      Header: 'Email',
      Cell: ({ row }: CellProps<RowItem>) => {
        return (
          <div className={styles.email}>
            {row.original.email}
          </div>
        );
      },
      minWidth: 200,
      maxWidth: 200,
    },
    {
      id: 'firstName',
      Header: 'First Name',
      Cell: ({ row }: CellProps<RowItem>) => {
        return (
          <div className={styles.first}>
            <input
              className={styles.input}
              onChange={e => handleChangeText(row.original.email, 'firstName', e.target.value)}
              value={row.original.firstName} />
          </div>
        );
      },
      minWidth: 150,
      maxWidth: 150,
    },
    {
      id: 'lastName',
      Header: 'Last Name',
      Cell: ({ row }: CellProps<RowItem>) => {
        return (
          <div className={styles.last}>
            <input
              className={styles.input}
              onChange={e => handleChangeText(row.original.email, 'lastName', e.target.value)}
              value={row.original.lastName} />
          </div>
        );
      },
      minWidth: 150,
      maxWidth: 150,
    },
    {
      id: 'account',
      Header: 'Account',
      Cell: ({ row }: CellProps<RowItem>) => {
        const groups = query.data?.items ?? [];
        const match = groups.some(x => x.id === row.original.groupId);

        if (!match) {
          return (
            <div className={styles.account}>
              <div className={styles.nomatch}>Please contact product team to set up this user</div>
            </div>
          );
        }

        return (
          <div className={styles.account}>
            <Select
              classes={{
                root: styles.select,
                select: styles.select,
                disabled: styles.disabled,
              }}
              inputProps={{ style: { width: 140, color: '#000' } }}
              disabled
              onChange={e => handleChangeGroup(row.original.email, e.target.value)}
              options={groups}
              value={row.original.groupId} />
          </div>
        );
      },
      minWidth: 150,
      maxWidth: 150,
    },
  ], [
    handleChangeGroup,
    handleChangeText,
    matchDomainFromEmail,
    query.data?.items,
    toggleChecked,
  ]);

  const noOptionsMessage = useCallback((val: { inputValue: string }) => {
    const value = val.inputValue?.toLowerCase?.()?.trim();

    const exists = exclude.includes(value);

    const invalid = [
      `@trinitylifesciences.com`,
      `@trinitypartners.com`,
    ].some(email => value?.endsWith?.(email));

    return val.inputValue?.length
      ? !exists && invalid
        ? `Please add this user to the Trinity Account before adding them to this call.`
        : `No matches found.`
      : null;
  }, [exclude]);

  const loading = access.isInitialLoading && !access.data;
  const data = loading
    ? getLazyTableData()
    : rows ?? [];
  const empty = query.isFetchedAfterMount && !loading && !data.length;
  const suspended = useSuspendInitialLoading({
    // @ts-ignore
    data,
    empty,
    isInitialLoading: access.isInitialLoading,
    loading,
    pagination: {
      pageCount: 0,
      totalCount: 0,
    },
  }, 500);

  const params = {
    columns,
    data: suspended.data,
    initialState: {
      pageIndex: 0,
      pageSize: 0,
    },
    manualPagination: true,
    pageCount: 0,
  };

  const {
    getTableBodyProps,
    headerGroups,
    pageOptions,
    prepareRow,
    rows: tablerows,
    setPageSize,
  } = useTable<RowItem>(
    params,
    useFlexLayout,
  );

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Header>{copy.title}</Header>
        <div className={styles.subheader}>
          <div>{copy.subtitle1}</div>
          <div>{copy.subtitle2}</div>
        </div>
        <div className={styles.main}>
          <div className={styles.search}>
            <div className={styles.label}>
              Search or add email
            </div>
            <MultiSelect
              classNames={{
                menu: () => styles.userSelectMenu,
                menuList: () => styles.userSelectMenu,
                menuPortal: () => styles.userSelectMenu,
              }}
              createOptionPosition="first"
              placeholder={`Email`}
              filterOption={o => true}
              getOptionLabel={getOptionLabel}
              getOptionValue={val => `${val.id}`}
              isValidNewOption={isValidNewOption}
              menuPortalTarget={document.body}
              noOptionsMessage={noOptionsMessage}
              // @ts-ignore
              onChange={val => handleSelect(val)}
              onCreateOption={handleCreate}
              onInputChange={val => setKeyword(val)}
              // @ts-ignore
              options={search.data?.items ?? []}
              value={null} />
          </div>
          <div className={styles.table}>
            <Table.Root
              empty={suspended.empty}
              loading={suspended.loading}
              maxScrollHeight={0}>
              <Table.Header
                classes={{
                  th: styles.th,
                  thead: styles.thead,
                  // tr: styles.tr,
                }}
                headerGroups={headerGroups} />
              <Table.Body
                classes={{
                  tbody: styles.tbody,
                  tr: styles.tr,
                  td: styles.td,
                }}
                {...getTableBodyProps()}
                prepareRow={prepareRow}
                rows={tablerows} />
            </Table.Root>
          </div>
        </div>
        <div className={styles.notify}>
          {!!count &&
            <>
              <Checkbox
                checked={notify}
                onChange={() => setNotify(!notify)} />
              <div className={styles.note}>Send Access Email</div>
            </>}
        </div>
        <ButtonSet className={styles.footer}>
          <ButtonOutlined
            className={styles.btn}
            color="black"
            disabled={mutation.isLoading}
            fontWeight="bold"
            onClick={props.onClose}>
            Cancel
          </ButtonOutlined>
          <ButtonActivityIndicator
            color={count ? 'affirmative' : 'secondary'}
            className={styles.btn}
            implicitDisable={false}
            loading={mutation.isLoading}
            onClick={handleSubmit}>
            {!count
              ? 'Skip'
              : `Create ${count} User Account${count > 1 ? 's' : ''}`}
          </ButtonActivityIndicator>
        </ButtonSet>
      </div>
    </div>
  );
};

CreateClientUsers.displayName = 'PublishModal.CreateClientUsers';

type UnregisteredClientUser = {
  email: string;
  domain: string;
  matchingGroupId: number;
};

type RowItem = {
  checked: boolean;
  email: string;
  firstName: string;
  lastName: string;
  name: string;
  id: number;
} & IGroupId;

function transform(items: UnregisteredClientUser[]): RowItem[] {
  return items.map(x => ({
    checked: false,
    email: x.email,
    firstName: '',
    lastName: '',
    groupId: x.matchingGroupId,
    name: null,
    id: null,
  }));
}

const copy = {
  title: `Create New User Accounts?`,
  subtitle1: `Send an email to grant the following client users access to their InsightEDGE accounts.`,
  subtitle2: `Once they set up their account, users will be able to access published transcripts for this projects.`,
};

type AccessItem = {
  email: string;
  domain: string;
  id: number;
  matchingGroupId: number;
  name: string;
  offPlatform?: boolean;
  primaryModeratorEnabled?: boolean;
  roleId: WorkspaceObjectRole;
};

type CreateOption = {
  label: string;
  value: string;
};

type SearchItem = {
  offPlatform?: boolean;
} & Omit<SearchResultItem, 'offPlatform'>;

const getLazyTableData = <T extends RowItem>(pageSize = 2) => {
  return Array.from({ length: pageSize }, _ => ({} as T));
};