import { cx } from '@utils';
import * as enums from '@enums';
import Tag from '@/components/Tag';
import { Label } from '@presentation/Label';
import { trunc } from '@utils';
import { UserTopicProps, JobFunction } from './interfaces';
import styles from './style/UserTopic.css';

type Props =
    UserTopicProps;

export const UserTopic = (props: Props) => {
  const getItem = (item: JobFunction) => {
    return {
      id: item.id,
      name: trunc(item.name, 21),
      type: enums.SearchType.JobFunction,
    };
  };

  return (
    <Label
      className={cx(styles.root, props.className)}
      label="Topics">
      {props.items.map(x =>
        <Tag
          key={x.id}
          className={styles.tag}
          item={getItem(x)}
          /*
            `onClick` needs to be refactored so it doesn't
            require extra steps to be called with a value
            and which results in creating a new callback
          */
          onClick={props.onClick ? () => props.onClick(x) : undefined} />)}
    </Label>
  );
};