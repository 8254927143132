import { AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as api from '@api/calls';
import * as Calls from '@api/interfaces/calls';

type Data = unknown;
type Variable = Omit<Calls.SavePostCallSurveyResponseRequest, 'callId'>;

export function useSubmitPostCallSurveyResponse(
  data: ICallId,
  options?: UseMutationOptions<Data, AxiosResponse, Variable>
) {

  return useMutation(keySubmitPostCallSurveyResponse(data.callId), params => {

    return api.savePostCallSurveyResponse({
      callId: data.callId,
      items: params.items,
      surveyVersionId: params.surveyVersionId,
    });

  }, options);
}

export function keySubmitPostCallSurveyResponse(callId: number) {
  return ['submit-post-call-survey-response', callId];
}