import { ButtonActivityIndicator, ButtonActivityIndicatorProps } from '@presentation/Button.ActivityIndicator';
import { cx } from '@utils';
import { Button } from '@/components/Button';
import { ButtonProps } from '@/components/Button/interfaces';
import styles from './style/Comment.Actions.css';

type CancelProps =
  Pick<ButtonProps,
    'className' |
    'onClick'>;

export const CommentCancelButton = ({ className, ...props }: CancelProps) => {

  return (
    <Button.Destructive
      className={cx(styles.cancel, className)}
      variant="brick"
      {...props}>
      Cancel
    </Button.Destructive>
  );
};

type SaveProps =
  ButtonActivityIndicatorProps;

export const CommentSaveButton = ({ className, ...props }: SaveProps) => {
  return (
    <ButtonActivityIndicator
      className={cx(styles.btn, className)}
      {...props}>
      {props.children}
    </ButtonActivityIndicator>
  );
};

type ActionsProps = {
  className?: string;
} & ChildrenProps;

export const CommentActions = (props: ActionsProps) => {

  const className = cx(styles.actions, props.className);

  return (
    <div className={className}>
      {props.children}
    </div>
  );
};