import { useCallback } from 'react';
import * as api from '@api';
import type { WorkspaceFolders } from '@/services/api/interfaces';
import Toast from '@/components/Toast';
import type { RenameFolderParams } from '../interfaces';

type Props = {
  onSuccess?: (res: WorkspaceFolders.RenameFolder.Response<Date>) => void;
};

export const useRenameFolder = ({ onSuccess }: Props) => {

  const renameFolder = useCallback((data: RenameFolderParams) => {
    return api.workspaces.folder.renameFolder({
      folderId: data.folderId,
      name: data.name,
    }).then(res => {
      if (onSuccess) {
        onSuccess(res);
      } else {
        Toast.alert({
          title: 'Folder Renamed',
        });
      }
      return data;
    }).catch(e => {
      Toast.error({
        title: 'Error',
        body: `We're sorry, there was an issue with your request`,
      });
      throw e;
    });

  }, [onSuccess]);

  return renameFolder;
};

export default useRenameFolder;