import CopyLink from '@/components/CopyLink';
import { ShareByLinkProps } from './interfaces';
import styles from './style.css';

const ShareByLink = (props: ShareByLinkProps) => {
  return (
    <div className={styles.section}>
      <div className={styles.label}>Send a project invite link</div>
      <CopyLink
        disabled={props.copyDisabled}
        url={props.link ? props.link.url : ''} />
    </div>
  );
};

export default ShareByLink;