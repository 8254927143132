import { UserEmployment } from '@presentation/UserEmployment';
import { cx } from '@utils';
import { Contact } from '@/types';
import styles from './style/ConsultantCareer.css';

type Props = {
  className?: string;
} & Pick<Contact, 'career'>;

const ConsultantEmployments = (props: Props) => {
  const employments = [
    props.career.current,
    props.career.former,
  ]
  .filter(x => x.company)
  .map((x, i) =>
    <li
      className={cx(styles.job, props.className)}
      key={`c-${i}`}>
      <UserEmployment
        className={styles.text}
        company={x.company}
        tenure={x.duration}
        title={x.title} />
    </li>);

  if (!employments.length) return null;

  return (
    <ul className={styles.jobs}>
      {employments}
    </ul>
  );
};

export { ConsultantEmployments };
export default ConsultantEmployments;