import { useCallback, useEffect, useState } from 'react';
import { addDays } from 'date-fns';
import { setCookie, getCookie, SetCookieProps } from '@/brand-insights/utils/cookie';

type ReturnData = [
  string,
  (data: Omit<SetCookieProps, 'key'>) => void,
  () => void,
];

type Options = {
  watchForChanges: boolean;
};

export const useCookie = (key: string, defaultValue?: string, options: Options = { watchForChanges: false }): ReturnData => {
  const [value, setValue] = useState(() => {
    return getCookie(key);
  });

  const setCookieInternal = useCallback((data: Omit<SetCookieProps, 'key'>) => {
    setValue(data.value);
    setCookie({
      ...data,
      key,
    });
  }, [key]);

  const clearCookie = useCallback(() => {
    setCookieInternal({
      expires: addDays(new Date(), 14),
      value: '',
    });
    setValue(defaultValue);
  }, [defaultValue, setCookieInternal]);

  useEffect(() => {
    if (options.watchForChanges) {
      const interval = setInterval(() => {
        const readCookie = getCookie(key);
        if (readCookie != value) {
          setValue(readCookie);
        }
      }, 500);

      return () => clearInterval(interval);
    }
  }, [value, options.watchForChanges, key]);

  return [value, setCookieInternal, clearCookie];
};

export default useCookie;