import { useContext } from 'react';
import { SearchLoadingFolderContext, SearchResultsFolderContext } from '@/containers/WorkspaceGlobalSearch/Context';
import { formatPlurality } from '@/utils';
import Table from './Search.Table.Folders';
import styles from './style/Tab.css';

const max = 50;

export default function SearchTabFolders() {
  const folders = useContext(SearchResultsFolderContext);
  const loading = useContext(SearchLoadingFolderContext);

  const isLoading = loading.untouched && loading.loading;

  const data = folders?.items ?? [];
  const countText = !isLoading
    ? `(${getCountLabel(folders?.total ?? 0, 'Folder')})`
    : null;
  const pagination = data.length > 25;

  return (
    <div className={styles.root}>
      <Table
        data={data}
        countText={countText}
        pagination={pagination}
        pageSize={25}
        isLoading={isLoading} />
    </div>
  );
}

function getCountLabel(total: number, label: string) {
  return total > max
    ? `+${max} ${formatPlurality(label, max)}`
    : `${total} ${formatPlurality(label, total)}`;
}

export { SearchTabFolders };