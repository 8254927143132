import { useCallback, useState } from 'react';
import { useTargetProductProfileData, useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';

export const useCanSaveQualityOfLife = (value: string) => {

  return value.length >= 2;
};

export const useTemplateQualityOfLife = () => {
  const [templateData] = useTargetProductProfileData();
  const [qualityOfLife, setQualityOfLife] = useState(templateData.qualityOfLife);
  const [_, dispatch] = useSurveyBuilderState();

  const onSubmit = useCallback(() => {
    dispatch({
      type: 'template-qol-updated',
      value: qualityOfLife,
    });
  }, [
    qualityOfLife,
    dispatch,
  ]);

  return {
    onChange: setQualityOfLife,
    onSubmit,
    value: qualityOfLife,
  };
};