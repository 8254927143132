import { CSSProperties, useCallback, useState } from 'react';
import { X } from 'react-feather';
import { anchorRef, usePopupState, bindPopper, bindFocus } from 'material-ui-popup-state/hooks';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import { useSearchTopics, useTopicTagsContext } from '@containers/PostCreation/hooks';
import { cx, useDebouncedInputEvent, Target } from '@utils';
import { Topics } from '@/types';
import { TopicInputList } from './Topics.Input.List';
import styles from './style/Topics.Input.css';

type Props = {
  classes?: {
    root?:   string;
    input?: string;
    cancel?: string;
  };
};

export const TopicInput = ({ classes = {}, ...props }: Props) => {
  const [topic, dispatch] = useTopicTagsContext();
  const [results, setResults] = useState<Topics.Descriptor[]>([]);
  const searchTopics = useSearchTopics();
  const [listStyle, setListStyle] = useState<CSSProperties>({});

  const popupState = usePopupState({
    disableAutoFocus: true,
    popupId: 'topics-list',
    variant: 'popper',
  });

  const popperProps = bindPopper(popupState);

  const popupRef = anchorRef(popupState);

  const ref = useCallback((node: HTMLElement) => {
    if (!node) return;
    popupRef(node);
    setListStyle({
      width: node.offsetWidth,
    });
  }, [popupRef]);

  const addTopic = useCallback((item: Topics.Descriptor) => () => {
    dispatch({
      item,
      type: 'add-topic',
    });
    popupState.close();
  }, [dispatch, popupState]);

  const handleClose = useCallback(() => {
    dispatch({
      type: 'toggle-topic-editing',
    });
  }, [dispatch]);

  const runSearch = useCallback((e: React.ChangeEvent<Target>) => {
    const keyword = e.target.value.trim();

    if (!keyword) return setResults([]);

    return searchTopics({
      value: keyword,
      ids: [...topic.items].map(x => x.id),
    }).then(items => setResults(items));

  }, [
    searchTopics,
    topic.items,
  ]);

  const handleChange = useDebouncedInputEvent(runSearch);

  const classnames = {
    root: cx(styles.root, classes.root),
    wrap: cx(styles.wrap, classes.input),
    cancel: cx(styles.close, classes.cancel),
  };

  return (
    <div
      className={classnames.root}
      ref={ref}>
      <div className={classnames.wrap}>
        <input
          autoFocus
          autoComplete="off"
          name="topic"
          placeholder="Start typing a topic"
          type="text"
          className={styles.input}
          onChange={handleChange}
          {...bindFocus(popupState)} />
        <X
          className={classnames.cancel}
          onClick={handleClose} />
      </div>
      <Popper
        className={styles.popper}
        {...popperProps}
        open={popperProps.open && !!results.length}
        placement="bottom-start">
        {(props: { placement: PopperPlacementType }) => (
          <TopicInputList
            style={listStyle}
            items={results}
            placement={props.placement}
            onSelect={addTopic} />
        )}
      </Popper>
    </div>
  );
};

TopicInput.displayName = 'PostCreation.Topic.Input';