import { useCallback } from 'react';
import { SurveyLogic } from '@/types/survey';
import { RemoveConditionContainer } from './Condition.Remove';
import {
  useSurveyConditionsState,
  ClassificationBuilderContext,
  ClassificationBuilderContextValue,
} from './context';

type Props = {
  item: SurveyLogic.ClassificationCondition;
} & ChildrenProps;

export const ClassificationConditionBuilderContainer = (props: Props) => {

  const [_, dispatch] = useSurveyConditionsState();

  const handleClassificationUpdate = useCallback((value: string) => {
    dispatch({
      type: 'classification-updated',
      payload: {
        identifier: props.item.identifier,
        value,
      },
    });
  }, [dispatch, props.item.identifier]);

  const value: ClassificationBuilderContextValue = {
    item: props.item,
    updateClassification: handleClassificationUpdate,
  };

  return (
    <ClassificationBuilderContext.Provider value={value}>
      <RemoveConditionContainer item={props.item}>
        {props.children}
      </RemoveConditionContainer>
    </ClassificationBuilderContext.Provider>
  );
};