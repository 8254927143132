import * as consts from '@consts';
import { useAppReadyState } from '@containers/AppReadyState';
import { LabeledTagContainer } from '@presentation';
import { trunc } from '@utils';
import { Posts } from '@/types';
import * as Tag from '@/components/Tag/Link';
import styles from './style/Discovery.css';

type Props = {
  items: Posts.Post['topics'];
};

export const TopicTags = (props: Props) => {
  const app = useAppReadyState();

  if (!props.items?.length) return null;

  return (
    <LabeledTagContainer
      className={styles.tags}
      label="Topics">
      {props.items.map(x =>
        <Tag.Link
          className={styles.tag}
          key={x.id}
          to={app.authenticated ? `${consts.path.Topics.Root}/${x.slug}` : null}>
          {trunc(x.name, 21)}
        </Tag.Link>)}
    </LabeledTagContainer>
  );
};

TopicTags.displayName = 'Discovery.Post.TopicTags';