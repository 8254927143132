import { useCallback, useMemo, useState } from 'react';
import { GroupUser, WorkspaceObject } from '@/types';
import { ObjectOwnershipContext, ObjectOwnershipContextValue } from './Context';

type Props = {
  object: WorkspaceObject;
  fetchUsers: () => Promise<GroupUser[]>;
  onSave: (ownerId: number) => Promise<unknown>;
} & ChildrenProps;

export const BaseObjectOwnershipContainer = (props: Props) => {
  const [selectedUser, setUser] = useState<GroupUser>(null);
  const [users, setUsers] = useState<GroupUser[]>(null);

  const fetchUsers = useCallback(() => {
    return props.fetchUsers()
      .then(setUsers);
  }, [props.fetchUsers]);

  const filteredUsers = useMemo(() => {
    if (!users) return [];
    return [...users]
      .filter(f => f.id !== props.object.ownerId)
      .sort((a, b) => {
        if(a.firstName < b.firstName) { return -1; }
        if(a.firstName > b.firstName) { return 1; }
        return 0;
      });
  }, [
    props.object,
    users,
  ]);

  const handleSave = useCallback(() => {
    return props.onSave(selectedUser.id);
  }, [
    props.onSave,
    selectedUser,
  ]);

  const value: ObjectOwnershipContextValue = {
    fetchUsers,
    onUserSelect: setUser,
    save: handleSave,
    selectedUser,
    users: filteredUsers,
  };

  return (
    <ObjectOwnershipContext.Provider value={value}>
      {props.children}
    </ObjectOwnershipContext.Provider>
  );
};

export default BaseObjectOwnershipContainer;