import { useDownloadFile } from './hooks';
import { DownloadFileContext } from './Context';

type Props =
  IWorkspaceId &
  IWorkspaceFileId &
  ChildrenProps;

export const DownloadFileContainer = (props: Props) => {

  const handleDownload = useDownloadFile({
    workspaceId: props.workspaceId,
    fileId: props.fileId,
  });

  return (
    <DownloadFileContext.Provider value={handleDownload}>
      {props.children}
    </DownloadFileContext.Provider>
  );
};

export default DownloadFileContainer;