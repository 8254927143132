import type { SurveyQuestionType } from '@enums';
import type { NumberInputTableQuestion } from '@/types';
import { SurveyQuestionNumberType } from '@/types';
import type { Validation } from '../interfaces';
import { assertValidOptions } from './validation.options';
import { assertValidRows } from './validation.rows';
import { assertHasValue, returnAssertFailure } from './validation.base';
import { assertValidNumberSettings } from './validation.settings.number';

export const DefaultColumns = 3;
export const MaxColumns = 11;
export const MinColumns = 1;

export const DefaultRows = 5;
export const MaxRows = 30;
export const MinRows = 1;

export const DefaultSettings: NumberInputTableQuestion.Settings = {
  allowEmptyValues: false,
  displayCellPct: false,
  displayTotals: false,
  dropdowns: {
    enabled: false,
    increment: null,
  },
  numberSettings: {
    type: SurveyQuestionNumberType.Number,
    currency: 'USD',
  },
  orderRowsBasedOnSource: false,
  randomizeRows: false,
  randomizeOptions: false,
  rationale: {
    enabled: false,
    minimum: 75,
  },
  total: {
    comparate: null,
    operator: '=',
  },
  validations: {
    minValue: null,
    maxValue: null,
  },
};

export const DefaultRowMetadata: NumberInputTableQuestion.RowMetadata = {
  canDelete: true,
  canModifyValue: true,
  isAnchored: false,
  isOpenEnded: false,
  template: {},
};

export const DefaultOptionMetadata: NumberInputTableQuestion.OptionMetadata = {
  canDelete: true,
  canModifyValue: true,
  isReadOnly: false,
  template: {},
  validations: [],
};

export function assertValidSettings({ settings }: NumberInputTableQuestion.Question): Validation.AssertionResult {
  const { minValue, maxValue } = settings.validations;

  const hasMinValue = minValue !== null;
  const hasMaxValue = maxValue !== null;

  if (hasMinValue && hasMaxValue && minValue > maxValue) {
    return {
      message: `Minimum value can't be greater than maximum value`,
      success: false,
    };
  }

  if (settings.dropdowns.enabled) {

    if (!settings.dropdowns.increment) {
      return {
        message: `Dropdown increment value required`,
        success: false,
      };
    }

    if (!hasMinValue || !hasMaxValue) {
      return {
        message: `Min. and Max. values required`,
        success: false,
      };
    }

    const steps = (settings.validations.maxValue - settings.validations.minValue) / settings.dropdowns.increment;

    if (!Number.isInteger(steps)) {
      return {
        message: `Invalid dropdown settings`,
        success: false,
      };
    }

  }

  const hasValidNumberSettings = assertValidNumberSettings(settings);
  if (hasValidNumberSettings.success === false) {
    return hasValidNumberSettings;
  }

  return {
    success: true,
  };
}

export function validateQuestion(question: NumberInputTableQuestion.Question): Validation.ValidateQuestionResult<SurveyQuestionType.NumberInputTable> {
  const hasValue = assertHasValue(question);
  if (hasValue.success === false) {
    return returnAssertFailure(hasValue, question);
  }

  const hasValidOptions = assertValidOptions(question.options, MinColumns, MaxColumns);
  if (hasValidOptions.success === false) {
    return returnAssertFailure(hasValidOptions, question);
  }

  const hasValidRows = assertValidRows(question.matrixRows, MinRows, MaxRows);
  if (hasValidRows.success === false) {
    return returnAssertFailure(hasValidRows, question);
  }

  const hasValidSettings = assertValidSettings(question);
  if (hasValidSettings.success === false) {
    return returnAssertFailure(hasValidSettings, question);
  }

  return {
    success: true,
    result: {
      question,
    },
  };
}
