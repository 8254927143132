import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import * as $api from '@api/transcripts';
import type { Transcripts } from '@api/interfaces';

type Vars = Transcripts.UpdateTranscriptRangeTags.Request & {
  range: {
    start: number;
    end: number;
  };
};
export type UpdateTranscriptRangeTagsOptions = UseMutationOptions<Transcripts.UpdateTranscriptRangeTags.Response, AxiosResponse, Vars>;

export const useUpdateTranscriptRangeTagsMutation = (options?: UpdateTranscriptRangeTagsOptions) => {

  return useMutation({
    mutationFn: (data: Vars) => {
      return $api.updateTranscriptRangeTags({
        deletes: data.deletes,
        inserts: data.inserts,
        transcriptId: data.transcriptId,
      });
    },
    ...options,
  });
};