import { useCallback, useMemo } from 'react';
import { ProjectConferenceTag } from '@/types';
import { Checkbox } from '@/components/Checkbox';
import { useTagsToSummarize } from './context';
import styles from './style/ConferenceTagsOverview.css';

type Props = {
  items: Item[];
};

type Item = {
  tag: ProjectConferenceTag;
  instances: number;
};

export const TranscriptTagsSelection = ({ items }: Props) => {

  const [selected, setSelected] = useTagsToSummarize();

  const sortedTags = useMemo(() => {
    return [...items].sort((a, b) => {
      return b.tag.ordinal = a.tag.ordinal;
    });
  }, [items]);

  const handleRowToggleChecked = useCallback((tagId: number) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      setSelected(prev => {
        return [...prev, tagId];
      });
    } else {
      setSelected(prev => {
        return prev.filter(f => f !== tagId);
      });
    }
  }, [setSelected]);

  const allRowsChecked = useMemo(() => {
    return selected.length === items.length;
  }, [items.length, selected.length]);

  const handleHeaderToggleChecked = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      setSelected(items.map(m => m.tag.base.id));
    } else {
      setSelected([]);
    }
  }, [setSelected, items]);

  return (
    <div className={styles.tableScroll}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.checkbox}>
              <Checkbox
                checked={allRowsChecked}
                onChange={handleHeaderToggleChecked} />
            </th>
            <th className={styles.name}>Tag ({selected.length})</th>
            <th className={styles.tagged}>Instances Tagged</th>
          </tr>
        </thead>

        <tbody>
          {sortedTags.map(item => {
            const itemSelected = selected.includes(item.tag.base.id);
            return (
              <tr key={item.tag.id}>
                <td className={styles.checkbox}>
                  <Checkbox
                    checked={itemSelected}
                    onChange={handleRowToggleChecked(item.tag.base.id)} />
                </td>
                <td className={styles.name}>{item.tag.base.name}</td>
                <td className={styles.tagged}>{item.instances}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};