import { DependencyList, useEffect } from 'react';
import {
  AsyncFn,
  AsyncState,
  useAsyncStateLazy,
} from '@utils/hooks/useAsyncStateLazy';

const defaultState = {
  loading: true,
};

const useAsyncState: typeof useAsyncStateLazy = <T = unknown, U extends unknown[] = unknown[]>(
  fn: AsyncFn<T, U>,
  dependencies: DependencyList = [],
  initialState: AsyncState<T> = defaultState,
): [AsyncState<T>, AsyncFn<T, U>] => {
  const [state, cb] = useAsyncStateLazy(fn, dependencies, initialState);

  useEffect(() => {
    cb();
  }, []);

  return [state, cb];
};

export { useAsyncState };
export default useAsyncState;