import { useContext } from 'react';
import { EditableSurveyContext } from '../Context';

export function useEditableSurveyContext() {
  const context = useContext(EditableSurveyContext);
  if (!context) {
    throw new Error('useEditableSurveyContext must be used within a EditableSurveyProvider');
  }
  return context;
}

export default useEditableSurveyContext;