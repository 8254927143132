import { useConjointAnalysisQuestionBuilderContext } from '@containers/SurveyBuilder.Question/Context';
import * as surveyBuilder from '@containers/SurveyBuilder/utils';
import { AddItemButton } from '@presentation/AddItemButton';
import { QuestionSettingTitle } from '@presentation/SurveyBuilder';
import AttributesBuilder from './AttributesBuilder';
import Settings from './ConjointAnalysis.Settings';
import styles from './style/ConjointAnalysis.Question.css';

export const ConjointAnalysisQuestionBuilder = () => {

  const {
    item,
    canAddAttribute,
    canAddLevel,
    canRemoveAttribute,
    canRemoveLevel,
    addAttribute,
    addLevel,
    removeAttribute,
    removeLevel,
    renameAttribute,
    renameLevel,
  } = useConjointAnalysisQuestionBuilderContext();

  const title = `Add attributes (up to ${surveyBuilder.conjointAnalysis.MaxAttributes}) and levels (up to ${surveyBuilder.conjointAnalysis.MaxLevels})`;

  return (
    <>
      <QuestionSettingTitle
        title={title} />
      {item.attributes.map(attribute => {
        const id = attribute.base.identifier;
        return (
          <AttributesBuilder
            key={id}
            className={styles.attribute}
            canAddLevel={canAddLevel(id)}
            canRemoveAttribute={canRemoveAttribute}
            onRemoveAttribute={removeAttribute(id)}
            canRemoveLevel={canRemoveLevel(id)}
            item={attribute}
            onAddLevel={addLevel(id)}
            onRemoveLevel={removeLevel(id)}
            onUpdateAttribute={renameAttribute(id)}
            onUpdateLevel={renameLevel(id)} />
        );
      })}
      {canAddAttribute &&
        <AddItemButton
          className={styles.add}
          label="Add Attribute"
          onClick={addAttribute} />
      }
      <Settings className={styles.settings} />
    </>
  );
};

export default ConjointAnalysisQuestionBuilder;