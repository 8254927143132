import { useCallback } from 'react';
import { Star } from 'react-feather';
import CreatableSelect from 'react-select/creatable';
import type { CSSObjectWithLabel, FormatOptionLabelMeta } from 'react-select';
import { ConferenceTagType } from '@enums';
import { cx } from '@utils';
import type { ConferenceTag } from '@/types';
import { Tags as ITags } from './interfaces';
import styles from './style/Tags.EditPopper.css';
import { assertIsGoodQuoteTag } from './utils';

type Props = {
  isDisabled?: boolean;
  isLoading?: boolean;
  onChange: (value: ConferenceTag) => unknown;
  options: ConferenceTag[];
  value: ConferenceTag;
};

export const CreateableTagSelect = ({
  isDisabled,
  isLoading,
  onChange,
  options,
  value,
}: Props) => {

  const onCreateOption = useCallback((value: string) => {
    const createdTag: ConferenceTag = {
      id: null,
      typeId: ConferenceTagType.UserCreated,
      name: value,
      createdBy: null,
      color: ITags.defaultColor,
    };

    onChange(createdTag);
  }, [onChange]);

  const onSelect = useCallback((value: ConferenceTag) => {
    onChange(value);
  }, [onChange]);

  const renderOptionLabel = useCallback((tag: ConferenceTag, metadata: FormatOptionLabelMeta<ConferenceTag>) => {
    const isGoodQuoteTag = assertIsGoodQuoteTag(tag);
    const label = tag['label'] as string ?? tag?.name;

    const isSelected = metadata.context === 'value' &&
      metadata.selectValue.length &&
      metadata.selectValue[0].id === tag.id;

    return (
      <div className={cx(styles.tagOption, {
        [styles.selected]: isSelected,
      })}>
        {isGoodQuoteTag && <Star className={styles.star} />}
        {!isGoodQuoteTag && <div className={styles.color} style={{ backgroundColor: tag.color }} />}
        {label}
      </div>
    );
  }, []);

  const formatCreateLabel = useCallback((value: string) => `Create tag "${value}"`, []);
  const isValidNewOption = useCallback((value: string) => value.length > 3, []);
  const getOptionValue = useCallback((o: ConferenceTag) => `${o.id}`, []);
  const getOptionLabel = useCallback((o: ConferenceTag) => o.name, []);

  return (
    <CreatableSelect
      className={styles.tagSelector}
      //Pass in null if the tag doesnt have a name, otherwise it thinks theres a value and wont show the placeholder
      value={value?.name ? value : null}
      isLoading={isLoading}
      isDisabled={isDisabled}
      //Use the label option first if it exists, otherwise use the name, formatCreateLabel basically sets a label
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      onCreateOption={onCreateOption}
      onChange={onSelect}
      formatCreateLabel={formatCreateLabel}
      isValidNewOption={isValidNewOption}
      menuPortalTarget={document.body}
      formatOptionLabel={renderOptionLabel}
      styles={{
        menuPortal: base => ({ ...base, zIndex: 8 }) as CSSObjectWithLabel,
      }}
      options={options} />
  );
};