import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { usePrevious } from '@utils';
import { BannerHookReturnType } from './interfaces';

const selectProfileCompleted = (state: Store.State) => state.user.profile.status?.completed;
const selectUserId = (state: Store.State) => state.user.id;

const useProfileCompletedBanner = (): BannerHookReturnType => {
  const [hasCompletedProfile, setCompletedState] = useState(false);

  const userId = useSelector(selectUserId);
  const profileCompleted = useSelector(selectProfileCompleted);

  const prevUserId = usePrevious(userId);
  const prevProfileCompleted = usePrevious(profileCompleted);

  const checkValue = useCallback(() => {
    if (!hasCompletedProfile && !prevProfileCompleted && profileCompleted) {
      setCompletedState(true);
    }
  }, [hasCompletedProfile, prevProfileCompleted, profileCompleted]);

  useEffect(() => {
    if (prevUserId && userId) checkValue();
    if (prevUserId && !userId) setCompletedState(false);
  }, [checkValue, prevUserId, userId]);

  const dismiss = () => {
    setCompletedState(false);
  };

  return [hasCompletedProfile, dismiss];
};

export { useProfileCompletedBanner };
export default useProfileCompletedBanner;