import { useCallback } from 'react';
import { SurveyTaggingResultType } from '@enums';
import { cx } from '@utils';
import { useSurveyTaggingItemState } from '@/containers/SurveyBuilder.Tagging';
import { TaggingBuilderActionDropdown } from './Builder.Action.Dropdown';
import { ClassificationTagBuilder } from './Builder.Classification';
import styles from './style/Builder.Action.css';

type Props = {
  className?: string;
};

export const TaggingBuilderAction = ({ className }: Props) => {

  const [item] = useSurveyTaggingItemState();

  const renderDetails = useCallback(() => {

    if (!item.action.type) return null;

    if (item.action.type === SurveyTaggingResultType.Classification) {
      return <ClassificationTagBuilder />;
    }

  }, [item.action.type]);

  return (
    <div className={cx(styles.row, className)}>
      <TaggingBuilderActionDropdown />
      {renderDetails()}
    </div>
  );
};