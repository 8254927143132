import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { pathname } from '@consts';
import * as PayoutSetup from '@screens/PayoutSetup';
import { selectHasPayablePayoutAccount } from '@store/selectors';
import { useRequestCall } from './useCall';
import styles from './style/Payment.css';

export const AdHocCallPayoutSetup = () => {

  const call = useRequestCall();

  const hasPayoutAccount = useSelector(selectHasPayablePayoutAccount);

  if (!call.request.accepted) {
    return <Redirect to={pathname.Home} />;
  }

  return (
    <div className={styles.wrap}>
      <PayoutSetup.Stepper.AdHocCall
        hasPayoutAccount={hasPayoutAccount} />
    </div>
  );
};

export default AdHocCallPayoutSetup;