import { useProjectSurveyRespondent, useProjectState } from '@containers/GroupProject/hooks';
import { SurveyResponseRating } from '@/components/SurveyResponseRating';
import type { ProjectSurveyResponseRating } from '@/types/project-surveys';

type Props = {
  rating: ProjectSurveyResponseRating;
};

export const RatingSelector = (props: Props) => {

  const { projectSurveyResponseId } = useProjectSurveyRespondent();
  const { project } = useProjectState();

  return <SurveyResponseRating
    projectId={project.id}
    rating={props.rating}
    projectSurveyResponseId={projectSurveyResponseId} />;
};