import { useContext, useMemo } from 'react';
import { ProjectSurveyStarredAnswersStateContext } from '@containers/SurveyResponses/Context';

const useInitialStarredResponses = (questionId: number) => {
  const { initial } = useContext(ProjectSurveyStarredAnswersStateContext);

  const value = useMemo(() => {
    return initial[questionId];
  }, [
    initial,
    questionId,
  ]);

  return value;
};

export { useInitialStarredResponses };
export default useInitialStarredResponses;