import { useGroupEntityTags, useGroupEntityTagsUpdate, useGroupEntityTagsLoading, GroupTranscriptTagsContainer } from '@containers/GroupTags';
import { CardContent } from '@presentation/Main';
import styles from '@screens/Transcript/style/Tab.Details.css';
import { GroupSidebarTags } from '@/components/GroupSidebarTags';
import { useWorkspaceTranscriptParams } from './hooks/useWorkspaceTranscriptParams';

type Props = unknown;

export const Details = (props: Props) => {

  const { transcriptId } = useWorkspaceTranscriptParams();

  return (
    <GroupTranscriptTagsContainer transcriptId={transcriptId}>
      <DetailsContent />
    </GroupTranscriptTagsContainer>
  );
};

Details.displayName = 'Transcript.Tab.Details';

const DetailsContent = (props: Props) => {

  const tags = useGroupEntityTags();
  const update = useGroupEntityTagsUpdate();
  const tagsLoading = useGroupEntityTagsLoading();

  if (tagsLoading) return null;

  return (
    <CardContent className={styles.details}>

      <GroupSidebarTags
        tags={tags}
        onSave={update} />

    </CardContent>
  );
};

DetailsContent.displayName = 'Transcript.Tab.Details.Content';