import { Trash2 as TrashFeather, IconProps as FeatherProps } from 'react-feather';
import { cx } from '@utils';
import styles from './style.css';

type Props = {
  trashProps?: FeatherProps;
  className?: string;
  onClick?: () => unknown;
  size?: number;
};

export default function Trash({ trashProps = {}, ...props }: Props) {
  const { className: trashClassName, ...otherTrashProps } = trashProps;

  return (
    <div
      className={cx(styles.root, props.className)}
      onClick={props.onClick}>
      <TrashFeather
        className={cx(styles.trash, trashClassName)}
        size={props.size}
        {...otherTrashProps} />
    </div>
  );
}

export { Trash };