import Skeleton from '@mui/material/Skeleton';
import { cx } from '@utils';
import { ListItem } from './interfaces';
import { Item } from './OnboardingChecklist.Item';
import styles from './style/OnboardingChecklist.css';

type Props = {
  className?: string;
  items:      ListItem[];
  loading?:   boolean;
};

export const List = (props: Props) => {

  return (
    <div className={cx(styles.root, props.className)}>

      {!props.loading &&
        props.items.map(x =>
          <Item
            complete={x.complete}
            key={x.title}
            onClick={x.onClick}
            title={x.title} />)}

      {props.loading &&
        <Loading />}

    </div>
  );
};

List.displayName = 'OnboardingChecklist.List';

const Loading = (props: unknown) => {
  return (
    <>
      <Skeleton
        height={28}
        width="70%"
        variant="text" />
      <Skeleton
        height={28}
        width="70%"
        variant="text" />
      <Skeleton
        height={28}
        width="70%"
        variant="text" />
    </>
  );
};

Loading.displayName = 'OnboardingChecklist.Loading';