import http from '@services/http';
import type * as QA from './interfaces/projects.quant-analysis';

export const uploadProjectQuantAnalysisFile = (data: QA.UploadQuantAnalysisFile.Request) => {

  const formData = new FormData();

  formData.append('quantFile', data.file, data.file.name);
  formData.append('name', data.name);

  return http.put<QA.UploadQuantAnalysisFile.Response>(`/projects/${data.projectId}/quant-analysis`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getProjectQuantAnalysis = (data: QA.GetProjectQuantAnalysis.Request) => {
  return http.get<QA.GetProjectQuantAnalysis.Response>(`/projects/${data.projectId}/quant-analysis/${data.quantAnalysisId}`);
};

export const deleteQuantAnalysis = (data: QA.DeleteQuantAnalysis.Request) => {
  return http.delete(`/projects/${data.projectId}/quant-analysis/${data.quantAnalysisId}`);
};

export const reparseQuantAnalysis = (data: QA.GetProjectQuantAnalysis.Request) => {
  return http.post<QA.UploadQuantAnalysisFile.Response>(`/projects/${data.projectId}/quant-analysis/${data.quantAnalysisId}/reparse`, {});
};

export const deleteQuantAnalysisItem = (data: QA.DeleteQuantAnalysisItem.Request) => {
  return http.delete(`/projects/${data.projectId}/quant-analysis/${data.quantAnalysisId}/items/${data.itemId}`);
};

export const renameQuantAnalysisItem = (data: QA.RenameQuantAnalysisItem.Request) => {
  return http.patch(`/projects/${data.projectId}/quant-analysis/${data.quantAnalysisId}/items/${data.itemId}/name`, {
    name: data.name,
  });
};