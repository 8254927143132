import { forwardRef, useCallback } from 'react';
import { Code } from 'react-feather';
import { ToolbarButton } from '@/components/Remirror/Toolbar.Button';
import { Button } from '@/components/Button';
import type { ButtonProps } from '@/components/Button/interfaces';
import { usePipingMenuState } from './context';

type Props = {
  className?: string;
};

export const PipingButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {

  const { setOpen } = usePipingMenuState();

  const handleClick = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  return (
    <ToolbarButton
      ref={ref}
      className={props.className}
      onClick={handleClick}>
      <Code />
    </ToolbarButton>
  );
});

type CustomProps = Props & {
  buttonProps: ButtonProps;
} & ChildrenProps;

export const CustomPipingButton = forwardRef<HTMLButtonElement, CustomProps>((props, ref) => {
  const { setOpen } = usePipingMenuState();

  const handleClick = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  return (
    <Button
      ref={ref}
      {...props.buttonProps}
      onClick={handleClick}>
      {props.children}
    </Button>
  );
});