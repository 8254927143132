import { useMemo } from 'react';
import type { CSSObjectWithLabel } from 'react-select';
import Select from 'react-select';
import { useLanguagesQuery } from '@utils/api';

type Item = Descriptor & { fullCode: string };

type Props = {
  value: string;
  onChange: (val: Item) => void;
  disableOption?: (val: Item) => boolean;
};

export const LanguageInput = (props: Props) => {
  const query = useLanguagesQuery();

  const options = useMemo(() => {
    if (!props.disableOption || !query.data) return query.data;

    return query.data.map(i => ({ ...i, isDisabled: props.disableOption(i) }));
  }, [props, query.data]);

  return (
    <Select
      options={options}
      getOptionLabel={o => o.name}
      getOptionValue={o => o.name}
      onChange={val => props.onChange(val)}
      value={{ name: props.value, id: null, fullCode: null }}
      menuPortalTarget={document.body}
      styles={{
        menuPortal: base => ({ ...base, zIndex: 8 } as CSSObjectWithLabel),
      }}
      isLoading={query.isInitialLoading} />
  );
};