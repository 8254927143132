import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as $api from '@api/projects';
import {
  ExternalSourcingConfigsContext,
  ExternalSourcingRefetchContext,
  OffPlatformSourcingMutationsContainer,
} from '@containers/Project.ExternalSourcing';
import {
  ExternalSurveyConfigurationRefetchContext,
  ExternalSurveyConfigurationContext,
  OffPlatformSurveyMutationContainer,
} from '@containers/Project.ExternalSurvey';
import {
  ProjectHonorariaRefetchContext,
  ProjectHonorariaContext,
} from '@containers/Project.Honoraria/context';
import { ProjectHonorariaContainer } from './Honoraria.Container';
import { ProjectSurveySettingsContext, SurveySettingsFormRefetchContext } from './context';
import { SurveySettingsFormContainer } from './Form.Container';

type Props = {
  projectId: number;
} & ChildrenProps;

export const ProjectSurveySettingsContainer = (props: Props) => {

  const { refetch, ...query } = useQuery({
    queryKey: ['project.survey-settings', props.projectId],
    queryFn: () => {
      return $api.getProjectSurveySettings({ projectId: props.projectId });
    },
  });

  const handleRefetch = useCallback(() => {
    return refetch();
  }, [refetch]);

  const handleRefetchHonoraria = useCallback(() => {
    return refetch().then(res => {
      return res.data.honoraria;
    });
  }, [refetch]);

  if (query.isLoading) {
    return null;
  }

  const ctx = {
    attributes: query.data.attributes,
    capabilities: query.data.capabilities,
    project: query.data.project,
    meta: {
      ...query.data.meta,
      charge: query.data.meta.charge.value,
      payout: query.data.meta.payout.value,
    },
  };

  return (
    <ProjectSurveySettingsContext.Provider value={ctx}>
      <OffPlatformSurveyMutationContainer projectId={props.projectId}>
        <OffPlatformSourcingMutationsContainer projectId={props.projectId}>
          <ProjectHonorariaRefetchContext.Provider value={handleRefetchHonoraria}>
            <ExternalSurveyConfigurationRefetchContext.Provider value={handleRefetch}>
              <SurveySettingsFormRefetchContext.Provider value={handleRefetch}>
                <ExternalSourcingRefetchContext.Provider value={handleRefetch}>
                  <ProjectHonorariaContext.Provider value={query.data.honoraria}>
                    <ExternalSourcingConfigsContext.Provider value={query.data.externalSourcingConfigurations}>
                      <ExternalSurveyConfigurationContext.Provider value={query.data.externalSurveyConfiguration}>
                        <SurveySettingsFormContainer>
                          <ProjectHonorariaContainer projectId={props.projectId}>
                            {props.children}
                          </ProjectHonorariaContainer>
                        </SurveySettingsFormContainer>
                      </ExternalSurveyConfigurationContext.Provider>
                    </ExternalSourcingConfigsContext.Provider>
                  </ProjectHonorariaContext.Provider>
                </ExternalSourcingRefetchContext.Provider>
              </SurveySettingsFormRefetchContext.Provider>
            </ExternalSurveyConfigurationRefetchContext.Provider>
          </ProjectHonorariaRefetchContext.Provider>
        </OffPlatformSourcingMutationsContainer>
      </OffPlatformSurveyMutationContainer>
    </ProjectSurveySettingsContext.Provider>
  );
};