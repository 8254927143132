import cuid from 'cuid';
import { SurveyLogic } from '@/types';
import { SurveyConditionType, SurveyLogicRule, SurveyQuestionConditionType } from '@/enums';

export function generateLogicItem({
  rule = {
    expression: null,
    type: SurveyLogicRule.AllOfTheAbove,
  },
  ...data
}: GenerateLogicItem): SurveyLogic.Item {

  return {
    action: data.action,
    id: null,
    identifier: cuid(),
    conditions: data.conditions,
    metadata: {
      canDelete: false,
      canEdit: false,
    },
    rule,
  };
}

export function generateLogicItemQuestionCondition(data: GenerateLogicItemCondition): SurveyLogic.ItemCondition<SurveyConditionType.Question> {

  return {
    id: null,
    identifier: cuid(),
    conditionType: SurveyConditionType.Question,
    data: data.data,
  };
}

export function generateOptionSelectedLogic(data: GenerateOptionSelectedLogic): SurveyLogic.Item {
  return {
    action: data.action,
    id: null,
    identifier: cuid(),
    conditions: [generateLogicItemQuestionCondition({
      data: {
        question: data.question,
        type: SurveyQuestionConditionType.OptionSelected,
        value: {
          option: data.option,
        },
      },
    })],
    metadata: {
      canDelete: false,
      canEdit: false,
    },
    rule: {
      expression: null,
      type: SurveyLogicRule.AllOfTheAbove,
    },
  };
}

export function generateMatrixOptionSelectedLogic(data: GenerateMatrixOptionSelectedLogic): SurveyLogic.Item {
  return {
    action: data.action,
    id: null,
    identifier: cuid(),
    conditions: [generateLogicItemQuestionCondition({
      data: {
        question: data.question,
        type: SurveyQuestionConditionType.MatrixValueChosen,
        value: {
          option: data.option,
          row: data.row,
        },
      },
    })],
    metadata: {
      canDelete: false,
      canEdit: false,
    },
    rule: {
      expression: null,
      type: SurveyLogicRule.AllOfTheAbove,
    },
  };
}

type GenerateLogicItem =
  Partial<Pick<SurveyLogic.Item, 'rule'>> &
  Omit<SurveyLogic.Item, 'id' | 'identifier' | 'metadata' | 'rule'>;

type GenerateLogicItemCondition =
  Pick<SurveyLogic.ItemCondition<SurveyConditionType.Question>, 'data'>;

type GenerateOptionSelectedLogic = {
  action: SurveyLogic.Action;
  question: { identifier: string };
  option: { identifier: string };
};

type GenerateMatrixOptionSelectedLogic = {
  action: SurveyLogic.Action;
  question: { identifier: string };
  option: { identifier: string };
  row: { identifier: string };
};
