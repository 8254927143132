import { useState } from 'react';
import { MatrixSliderQuestion } from '@/types/survey';

function generateAnswerMap(answer: MatrixSliderQuestion.RespondentAnswer.Value): TextAnswers {
  return answer.items.reduce((acc, a) => {
    if (a.value) {
      return {
        ...acc,
        [a.matrixRowId]: a.value.toString(),
      };
    }
    return acc;
  }, {} as TextAnswers);
}

export const useSliderTextAnswers = (initialAnswers: MatrixSliderQuestion.RespondentAnswer.Value) => {
  const state = useState<TextAnswers>(generateAnswerMap(initialAnswers));

  return state;
};

type TextAnswers = {
  [id: number]: string;
};

export default useSliderTextAnswers;