import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as selectors from '@store/selectors';
import { ProjectSurveyResponseStatus } from '@enums';
import { PipelineRespondentsMap } from '@/types';
import { useProjectOrLatestChild, useProjectState } from './useProjectState';

const cutoff = 300;
const validResponseStatuses = [ProjectSurveyResponseStatus.Active];

export const useIsMassSurvey = () => {
  const project = useProjectOrLatestChild();
  const state = useProjectState();
  // const respondents: PipelineRespondentsMap = useSelector(selectors.pipeline.client.respondents(project.id));

  const isMassSurvey = useMemo(() => Object.values(state.pipeline.respondents).filter(r => validResponseStatuses.includes(r.projectSurveyResponse.statusId)).length >= cutoff, [state.pipeline.respondents]);

  return isMassSurvey;
};