import type { CallRole } from '@enums';
import type { Participant } from '@/types/conferences.live';
import type { ConferenceLogRecord } from '@/types';

type BuildGroupConversationIdentifier = {
  conferenceIdentifier: number;
  role: CallRole;
};

export const buildGroupConversationIdentifier = ({ conferenceIdentifier, role }: BuildGroupConversationIdentifier) => {
  return `role_conversation_${conferenceIdentifier}_${role}`;
};

type BuildDirectConversationIdentifier = {
  conferenceIdentifier: number;
  participants: [Participant, Participant];
};

export const buildDirectConversationIdentifier = ({ conferenceIdentifier, participants }: BuildDirectConversationIdentifier) => {
  return `direct_conversation_${conferenceIdentifier}_${participants.map(buildParticipantIdentifier).sort().join('_')}`;
};

export function buildParticipantIdentifier(participant: Participant) {
  switch (participant.auth) {
    case 'authorized-member':
    case 'outside-member': return `user:${participant.userId}`;
    case 'off-platform': return `offplatform:${participant.offPlatformUserId}`;
    default: return `guest:${participant.id}`;
  }
}

export const transformLogRecord = (event: ConferenceLogRecord) => {
  return {
    ...event,
    logTime: new Date(event.logTime),
  };
};