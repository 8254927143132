import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import * as QKey from '@consts/querykey';
import { useSelectGroup } from '@containers/Store';
import { transformPalette } from '@containers/Branding/utils';
import { WhitelabelingPaletteContext } from './Context';

type Props = {
  children: React.ReactNode;
};

export const WhitelabelingPaletteContainer = (props: Props) => {
  const group = useSelectGroup();

  const query = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [
      QKey.Groups.Branding.Survey.Theming.Get,
      group.id,
    ],
    queryFn: () => {
      return api.groups.branding.surveys.fetchSurveyTheming({
        groupId: group.id,
      })
      .then(res => {
        return {
          logos: res.logos,
          page: res.page,
          palette: transformPalette(res.palette),
        };
      });
    },
  });

  const value = useMemo(() => {
    if (!query.data?.palette) {
      return {
        palette: null,
        theme: false,
      };
    }

    return {
      palette: query.data?.palette,
      theme: !!query.data?.palette,
    };
  }, [query.data?.palette]);

  return (
    <WhitelabelingPaletteContext.Provider value={value}>
      {props.children}
    </WhitelabelingPaletteContext.Provider>
  );
};

WhitelabelingPaletteContainer.displayName = 'Whitelabeling.Palette.Container';