import { useCallback } from 'react';
import { useDownloadTranscriptTagClip } from '@utils/api';
import { DownloadTaggedMomentMediaClipContext } from './context';

type Props = {
  momentId: number;
  tag: { name: string };
} & ITranscriptId & ChildrenProps;

export const DownloadTranscriptTaggedMomentContainer = (props: Props) => {

  const { download } = useDownloadTranscriptTagClip({
    momentId: props.momentId,
    transcriptId: props.transcriptId,
  });

  const handleDownload = useCallback(() => {
    return download({
      extension: `mp4`,
      name: `Transcript (${props.tag.name})`,
    });
  }, [download, props.tag]);

  return (
    <DownloadTaggedMomentMediaClipContext.Provider value={handleDownload}>
      {props.children}
    </DownloadTaggedMomentMediaClipContext.Provider>
  );
};