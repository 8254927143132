import type { ComponentType } from 'react';
import Archive from './Archive';
import Audio from './Audio';
import Default from './Default';
import Doc from './Doc';
import Image from './Image';
import Pdf from './Pdf';
import Presentation from './Presentation';
import Spreadsheet from './Spreadsheet';
import Video from './Video';
import type { FileIconProps } from './interfaces';

export const ArchiveExtensions = new Set([
  '7z',
  'gz',
  'pkg',
  'rar',
  'tar',
  'zip',
]);

export const DocExtensions = new Set([
  'doc',
  'docm',
  'docx',
  'pages',
  'txt',
  'wpd',
  'xml',
]);

export const SpreadsheetExtensions = new Set([
  'csv',
  'numbers',
  'ods',
  'wk3',
  'wks',
  'xlr',
  'xls',
  'xlsb',
  'xlsm',
  'xlsx',
]);

export const PdfExtensions = new Set([
  'pdf',
]);

export const PresentationExtensions = new Set([
  'key',
  'odp',
  'pps',
  'ppsx',
  'ppt',
  'pptm',
  'pptx',
]);

export const VideoExtensions = new Set([
  'avi',
  'flv',
  'h264',
  'm4v',
  'mkv',
  'mov',
  'mp4',
  'mpg',
  'mpeg',
  'webm',
  'wmv',
]);

export const AudioExtensions = new Set([
  'flac',
  'm4a',
  'm4b',
  'mid',
  'midi',
  'mp2',
  'mp3',
  'wav',
]);

export const ImgExtensions = new Set([
  'ai',
  'bmp',
  'gif',
  'ico',
  'jpeg',
  'jpg',
  'png',
  'svg',
]);

export function getIconByExtension(extension: string): ComponentType<FileIconProps> {
  if (!extension) return Default;

  const lowercaseExtension = extension.toLowerCase();
  if (ImgExtensions.has(lowercaseExtension)) {
    return Image;
  } else if (AudioExtensions.has(lowercaseExtension)) {
    return Audio;
  } else if (VideoExtensions.has(lowercaseExtension)) {
    return Video;
  } else if (PdfExtensions.has(lowercaseExtension)) {
    return Pdf;
  } else if (SpreadsheetExtensions.has(lowercaseExtension)) {
    return Spreadsheet;
  } else if (DocExtensions.has(lowercaseExtension)) {
    return Doc;
  } else if (PresentationExtensions.has(lowercaseExtension)) {
    return Presentation;
  } else if (ArchiveExtensions.has(lowercaseExtension)) {
    return Archive;
  }

  return Default;
}