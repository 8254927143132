import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { LocationState } from 'history';
import * as api from '@api';
import { useSelectUser } from '@containers/Store';
import { AccountProfileFieldsPopulationContext, AccountProfileRefetchContext } from '@containers/AccountProfile/Context';
import { useAsyncStateLazy, usePromiseDelay } from '@utils';
import { UI } from '@/types';

type Params<S = LocationState> = UI.AbstractEffectParam<S>;

const useAccountProfilePopulateFields = <T>(params: Params<T>) => {
  const history = useHistory();
  const user = useSelectUser();
  const [_, setFieldsPopulated] = useContext(AccountProfileFieldsPopulationContext);
  const refetch = useContext(AccountProfileRefetchContext);

  const handlePopulated = () => {
    setFieldsPopulated(true);

    typeof params === 'function'
      ? params()
      : history.replace(params);
  };

  const populate = useCallback(() => {
    return api.profiles.enrichment.populate({ userId: user.id })
    .then(() => refetch());
  }, [
    refetch,
    user,
  ]);

  const startFn = usePromiseDelay(populate, 3000);

  const [response, populateWithDelay] = useAsyncStateLazy(() => {
    return startFn().then(handlePopulated);
  }, [
    handlePopulated,
    startFn,
  ]);

  return [response, populateWithDelay] as const;

};

export { useAccountProfilePopulateFields };
export default useAccountProfilePopulateFields;