import { useCallback, useMemo } from 'react';
import { useProjectOrLatestChild } from '@containers/GroupProject/hooks';
import { useSelectProject } from '@containers/Store';
import type { HybridSurveyResponseUserId } from '@/types';
import type { RespondentProps } from '@/types/project-surveys';
import { getLocationFor } from '@utils';
import { useTrackViewResponse } from './useTrackViewResponse';
import { useSelectSurveyRespondent } from './useSelectSurveyRespondentContext';
import { useProjectSurveyResponsesState } from './useProjectSurveyResponsesState';

export const useSelectRespondentProps = () => {
  const project = useResponsesProject();
  const handleViewResponse = useTrackViewResponse();

  const { getRespondentUser } = useSelectSurveyRespondent();

  const getSurveyResponseLocation = useCallback((projectSurveyResponseId: number) => {
    return getLocationFor.project.surveyResponse({
      slug: project.slug,
      projectSurveyResponseId,
    });
  }, [project.slug]);

  const selector = useCallback((userId: HybridSurveyResponseUserId): RespondentProps => {
    const respondent = getRespondentUser(userId);

    if (!respondent) return null;

    switch (respondent.type) {
      case 'internal': {
        return {
          type: 'internal' as const,
          user: respondent.user,
          onViewResponse: handleViewResponse({ id: respondent.user.id, name: respondent.user.name }),
          paths: {
            profile: null,
            response: getSurveyResponseLocation(respondent.responseId),
          },
          responseId: respondent.responseId,
          projectId: project.id,
          anonymous: respondent.anonymous,
        };
      }
      case 'external': {
        return {
          type: 'external' as const,
          user: respondent.user,
          onViewResponse: () => {},
          paths: {
            profile: null,
            response: getSurveyResponseLocation(respondent.responseId),
          },
          responseId: respondent.responseId,
          projectId: project.id,
          anonymous: respondent.anonymous,
        };
      }
    }
  }, [
    handleViewResponse,
    getRespondentUser,
    getSurveyResponseLocation,
    project.id,
  ]);

  return selector;
};

const useResponsesProject = () => {
  const project  = useProjectOrLatestChild();
  const { query } = useProjectSurveyResponsesState();
  const queryProject = useSelectProject(query.projectId);

  const data = useMemo(() => {
    return query.projectId
      ? queryProject
      : project;
  }, [
    project,
    query.projectId,
    queryProject,
  ]);

  return data;
};