import * as $session from '@services/auth/session';

export const calls: Store.Calls = {
  ids: [],
};

export const chat = {
  conversations: { ids: [] } as Store.Chat.Conversations,
  connections: { ids: [] } as Store.Chat.Connections,
  connectionState: null as Store.Chat.ConnectionState,
  initialized: false,
  messages: {} as Store.Chat.Messages,
  participants: {} as Store.Chat.Participants,
  platformMessages: {} as Store.Chat.PlatformMessages,
};

export const contacts = {
  ids: [],
};

export const documents = {
  group: { ids: [] },
};

export const group: Store.Group = {
  billing: {} as Store.Group.Billing,
  blacklistedCompanies: [],
  branding: {} as Store.Group.Branding,
  createdOn: null,
  document: {} as Store.Group.Document,
  id: null,
  features: {} as Store.Group.Features,
  name: null,
  settings: {} as Store.Group.Settings,
  typeId: null,
};

export const network = {
  isConnected: window.navigator.onLine,
};

export const notifications = {
  initialized: false,
  items: [] as Store.Notifications.Items,
};

export const onboarding = {
  completed: false,
  completedOn: null,
};

export const pipeline = {
  me: {} as Store.Pipeline.Me,
  project: {} as Store.Pipeline.Project,
};

export const platform = {
  alerts: {} as Store.Platform.Alerts,
};

export const projects = {
  ids: [],
  parents: {
    ids: [],
  },
};

export const routing = {
  routes: [],
};

export const settings = {
  email: {} as Store.Settings.Email,
  sms: {} as Store.Settings.SMS,
};

export const user: Store.User = {
  createdOn: null,
  connected: [],
  contact: {} as Store.User.Contact,
  id: null,
  meta: {
    emailReverificationRequired: null,
    isEmailVerified: null,
    isNew: null,
  },
  payout: {} as Store.User.Payout,
  permissions: [],
  profile: {} as Store.User.Profile,
  referral: null,
  referrer: {} as Store.User.Referrer,
  roles: [],
  settings: {} as Store.User.Settings,
  state: {
    authenticated: $session.isAuthenticated(),
    fetching: false,
    initialized: false,
    wasActionLogout: false,
  },
};

export const workspaces: Store.Workspaces = {
  group: null,
  personal: null,
  values: {},
};

export default {
  calls,
  chat,
  contacts,
  documents,
  group,
  network,
  notifications,
  onboarding,
  pipeline,
  platform,
  projects,
  routing,
  settings,
  user,
  workspaces,
};