import { memo } from 'react';
import { cx } from '@utils';
import styles from './style/Card.css';

type Props = {
  onClick?: () => void;
};

export const TargetProductProfileCard = memo(({ onClick }: Props) => {

  const title = `Target Product Profile`;

  return (
    <div
      className={styles.root}
      onClick={onClick}>
      <div className={cx(styles.poster, styles.tpp)}>
        <Icon />
      </div>
      <div className={styles.footer}>
        <div className={styles.title}>{title}</div>
      </div>
    </div>
  );
});

type IconProps = {
  color?: string;
};

const Icon = memo(({
  color = '#199b9b',
}: IconProps) => (
  <svg
    width="45px"
    height="27px"
    viewBox="0 0 45 27"
    version="1.1">
    <g
      id="TPP-Survey"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd">
      <g
        id="1"
        transform="translate(-513.000000, -219.000000)"
        stroke="#1D8F7F"
        strokeWidth="2">
        <g id="Group-3" transform="translate(512.000000, 217.000000)">
          <g id="Group-13">
            <path
              d="M13.4395448,1.5341394 C16.8916331,1.55088086 19.6859612,4.34520902 19.7027027,7.7972973 L19.7631579,20.2631579 C19.779691,23.6722829 17.0294519,26.4493275 13.6203268,26.4658606 C13.6003697,26.4659574 13.5804123,26.4659574 13.5604552,26.4658606 C10.1083669,26.4491191 7.31403875,23.654791 7.2972973,20.2027027 L7.23684211,7.73684211 C7.22030901,4.32771709 9.97054815,1.5506725 13.3796732,1.5341394 C13.3996303,1.53404262 13.4195877,1.53404262 13.4395448,1.5341394 Z"
              id="Rectangle"
              transform="translate(13.500000, 14.000000) rotate(-315.000000) translate(-13.500000, -14.000000) " />
            <path
              d="M2.6131865,12.593428 L15.0790471,12.6224556 L15.1370061,18.3607987 C15.1713437,21.7604672 12.443204,24.5442793 9.04353553,24.5786169 C9.01803361,24.5788745 8.99253033,24.5789736 8.96702718,24.5789142 C5.51221487,24.5708695 2.70603842,21.7864166 2.67114547,18.3317711 L2.6131865,12.593428 L2.6131865,12.593428 Z"
              id="Rectangle"
              fill="#94CDC7"
              transform="translate(8.906572, 18.593428) rotate(-315.000000) translate(-8.906572, -18.593428) " />
            <line
              x1="9"
              y1="10"
              x2="17"
              y2="18"
              id="Path-13" />
          </g>
          <line
            x1="29"
            y1="28"
            x2="40"
            y2="17"
            id="Path-15"
            strokeLinecap="round" />
          <line
            x1="25"
            y1="17.5"
            x2="32"
            y2="17.5"
            id="Path-14"
            strokeLinecap="round" />
          <line
            x1="28.5"
            y1="21"
            x2="28.5"
            y2="14"
            id="Path-14"
            strokeLinecap="round" />
          <line
            x1="38"
            y1="26"
            x2="45"
            y2="26"
            id="Path-15"
            strokeLinecap="round" />
        </g>
      </g>
    </g>
  </svg>
));