import { useCallback, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import * as api from '@api';
import { ButtonActivityIndicator } from '@presentation';
import { AuthForm } from '$website/components';
import { Spinner } from '@/components/ActivityIndicator';
import LogoTrinity from 'static/images/logo-trinity-white.svg?url';
import styles from './style/Auth0Error.css';

const { Layout } = AuthForm;

type Props = {
  email: string;
} & IUserId;

export const SetPasswordInviteExpired = (props: Props) => {
  const [resent, setResent] = useState(false);

  const query = useQuery([
    `get:users/verify/emails/state`,
    props.userId,
  ], () => {
    return api.users.verify.fetchEmailVerificationState({
      userId: props.userId,
    });
  }, {
    enabled: true,
    refetchOnWindowFocus: false,
  });

  const mutation = useMutation([
    `post:users/verify/emails/password/resend`,
    props.userId,
  ], () => {
    return api.users.verify.resendInviteEmailVerification({
      userId: props.userId,
    });
  }, {
    onSuccess: () => setResent(true),
  });

  const email = props.email || query.data?.data?.email;

  return (
    <div className={styles.root}>
      <div className={styles.bg}>
        <div className={styles.col}>
          <div className={styles.logo}>
            <img
              src={LogoTrinity}
              alt="Trinity"
              className={styles.logoImage} />
          </div>

          {query.isLoading &&
            <div className={styles.form}>
              <Spinner />
            </div>}

          {(!query.isLoading && !query.data?.data?.verified) &&
            <div className={styles.form}>
              <Layout.Header className={styles.header}>
                <Layout.Row className={styles.title}>
                  <Layout.Title>It looks like the link expired.</Layout.Title>
                </Layout.Row>
              </Layout.Header>

              <div className={styles.main}>

                <Layout.Row className={styles.row}>
                  <Layout.Subtitle className={styles.message}>{`We can send a new link to verify your account to ${email}`}</Layout.Subtitle>
                </Layout.Row>
                <Layout.Row className={styles.row}>
                  <Layout.Subtitle className={styles.subtitle}>
                    <span>{`Just click on the link in that email to complete your signup. If you don't see it, you may need to`}</span>
                    <span className={styles.em}> check your spam </span>
                    <span>folder.</span>
                  </Layout.Subtitle>
                </Layout.Row>
                <Layout.Row className={styles.row}>
                  <Layout.Subtitle className={styles.subtitle}>{`Still can't find the email? No problem.`}</Layout.Subtitle>
                </Layout.Row>

                <Layout.Row className={styles.resend}>
                  {resent &&
                    <div className={styles.resent}>
                      <MarkEmailReadIcon />
                      <span>Email Resent</span>
                    </div>}

                  {!resent &&
                    <ButtonActivityIndicator
                      className={styles.submit}
                      loading={mutation.isLoading}
                      onClick={() => mutation.mutate()}>
                      Resend Verification Email
                    </ButtonActivityIndicator>}
                </Layout.Row>
              </div>
            </div>}
        </div>
      </div>
    </div>
  );
};

SetPasswordInviteExpired.displayName = 'SetPasswordInvite.Expired';