import { Fragment, memo } from 'react';
import { cx } from '@utils';
import styles from './style/GDPR.css';

export const PrivacyNotice = memo(() => {
  return (
    <Fragment>
      <div className={styles.p}>
        {`This Research Privacy Policy (the "Policy") is intended to provide you with information about what personal data Trinity (referred to here as “Trinity,” “we” or “us”) collects about you and how it is used.`}
      </div>
      <div className={styles.p}>
        {`This Policy describes how we may obtain personal data, the types of personal data we may obtain, how we use, share, and protect the personal data, the rights of data subjects with respect to their personal data, and how to contact us about our privacy practices.`}
      </div>
      <div className={styles.p}>
        {`This Policy does not apply to personal data other than the personal data relating to primary research. For the Privacy Policy for personnel or representatives of our clients, suppliers and other entities with whom we have entered, or may enter, business transactions, please see our Business Associate Privacy Policy.`}
      </div>
      <div className={styles.p}>
        {`We may revise this Privacy Policy at any time, without notice to you. You are responsible for reviewing it regularly. Your access of the Sites following the posting of changes means you agree to abide by those changes.`}
      </div>
      <div className={styles.p}>
        {`This Privacy Policy was last revised on December 1, 2021.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`About Trinity:`}
      </div>
      <div className={styles.p}>
        {`Trinity operates a panel of members (called “Sentiment”) who take part in "Activities" (including market research, non-interventional studies, observational studies, and other surveys) on behalf of our clients. This Privacy Policy relates to the products and services offered by Trinity and its research panel Sentiment via www.sentimentglobal.com (the “Site”).`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`The Types of Personal Data We May Obtain and Process:`}
      </div>
      <div className={styles.p}>
        {`Trinity, through its research panel Sentiment, collects personal data about its members and those that participate in our Activities. We use this personal data to provide you with services as described below. We do not sell personal data to anyone and only share it with third parties who are facilitating the delivery of our services or when you give us explicit additional permission.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Personal data you give us about yourself when you register:`}
      </div>
      <div className={styles.p}>
        {`We collect the personal data you provide on the registration form; your name, address, telephone number, email address, and if you are a healthcare professional information about your occupation and NPI information so that we can target and tailor invitations to participate in Activities. (See “Personal data you give us within your membership profile” for more information about how we select Activity invitations for you)`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Personal data you give us within your membership profile:`}
      </div>
      <div className={styles.p}>
        {`Trinity collects additional personal data either before Activities or within the site. This is used to allow us to invite you to participate in Activities that you are more likely to be interested in and qualify for. For example, for physicians, this may include information about your medical experience and the types of patient you treat.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Personal data you give us within and immediately before or after online activities:`}
      </div>
      <div className={styles.p}>
        {`Activities should not contain requests for you to provide personal data. However, Trinity maintains systems that control access to and measure outcomes (for example; if you completed the Activity or failed to qualify) so we may need to collect personal data before and/or after the Activity. This data is not linked directly to the Activity data and is only passed to third parties with your explicit consent and/or as laid out in this policy. (See also “Payment details you give us for compensation payments” and “Personal Data we retain for an extended period”)`}
      </div>
      <div className={styles.p}>
        {`Online Activities offered to members of www.sentimentglobal.com may be on a website provided by Trinity or one provided by a third party. In the latter case, any personal data collected will be subject to the host’s privacy policy. If you take part in other forms of market research with Trinity, for example, discussion groups or telephone interviews, then you will be asked to provide specific consent for any necessary processing of your personal data.`}
      </div>
      <div className={styles.p}>
        {`Personal data related to your previous participation in activities:`}
      </div>
      <div className={styles.p}>
        {`We collect and store records of the Activities you have been invited to participate in, whether you responded to them, qualified for them, completed or partially completed them. We keep this data so that we make it available via the Sites and can respond to member enquiries. On occasions we may also send you invitations to new Activities based upon your participation in previous Activities.`}
      </div>
      <div className={styles.p}>
        {`We use this data in aggregate to understand how many individuals of any type may participate in any future Activity and to understand trends in and patterns of participation.`}
      </div>
      <div className={styles.p}>
        {`We also maintain records of any data quality issues that may have been identified in the data you provided within an Activity so we can manage the quality of the data we provide to clients – this may affect your invitation to future Activities.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Personal data created about you by your use of our systems:`}
      </div>
      <div className={styles.p}>
        {`We use a variety of technical means to collect and/or store personal data about you. These include cookies, web beacons and device fingerprinting/watermarking. Additionally, we keep records of your use of our systems. These are used to improve the security of our systems, prevent duplicate and fraudulent Activity responses, and to improve the experience we offer you.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Payment details you give us for compensation payments:`}
      </div>
      <div className={styles.p}>
        {`We collect and use specific personal data so that we can process payment of your Activity compensation. The personal data we need to use varies depending on the form of payment but can include bank account details, name, mailing address and email address. This is used only to process these payments but may be shared with payment processing partners to process the payment. (e.g., we must share bank account details with our bank to process a bank transfer to your account.) This personal financial data is retained only for financial reporting, audit and tax purposes.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Personal data we get from third parties: `}
      </div>
      <div className={styles.p}>
        {`Clients sometimes send us lists of healthcare professionals who they would like us to include or exclude in specific Activities. We also subscribe to various lists of healthcare professionals that we use to validate registrations and to append a variety of unique identification numbers to assist with deduplicating registrations, matching client lists and with legal reporting obligations. Lists of healthcare professionals are also used for direct marketing purposes in the USA.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Personal data we retain for an extended period:`}
      </div>
      <div className={styles.p}>
        {`In order to manage its business, Trinity needs to retain limited personal data about previous members for longer periods of time. We have carefully limited the personal data retained and the circumstances in which it is needed to the following:`}
      </div>
      <ul className={styles.ul}>
        <li className={styles.li}>{`Requests by individuals to not email them`}</li>
        <li className={styles.li}>{`Requests by individuals not to be contacted by Trinity or Sentiment`}</li>
        <li className={styles.li}>{`Individuals who have objected to Trinity processing their personal data (where there is not an overwhelming interest in us continuing to do so)`}</li>
        <li className={styles.li}>{`Individuals who have breached the Sites Terms of Use, including those suspected of fraudulent behavior`}</li>
      </ul>
      <div className={styles.p}>
        {`This personal data is not shared with anyone and is only used to honor your requests or to prevent subsequent Activity participation.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Personal data we may collate about you:`}
      </div>
      <div className={styles.p}>
        {`We use a variety of public sources to collate information about healthcare professionals we believe may be suitable to complete specific Activities or may wish to become members of www.sentimentglobal.com. We may also receive a referral from one of your colleagues and process that information in a similar way. The information would include your name, contact information (address, phone number(s) and possibly email address) and your healthcare specialty.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Personal data created by your use of third-party platforms:`}
      </div>
      <div className={styles.p}>
        {`Your use of third-party websites and services associated with Trinity may expose your personal data. These sites are beyond our control and you should see their respective privacy policies.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Purpose and legal basis for processing your personal data:`}
      </div>
      <div className={styles.p}>
        {`This data is used to manage our contact with you, so that, for example, multiple attempts to contact you regarding the same services are eliminated or you are not re-contacted after informing us you are not interested in our services.`}
      </div>
      <div className={styles.p}>
        {`The legal bases we rely upon to offer these services to you are:`}
      </div>
      <ul className={styles.ul}>
        <li className={styles.li}>{`Where we have a legitimate interest (i.e., we have a valid business reason) and we have carefully balanced your individual rights against this need:`}</li>
        <li className={styles.li}>{`To fulfill a contractual obligation that we have with you; and/or`}</li>
        <li className={styles.li}>{`Your consent for direct marketing.`}</li>
      </ul>
      <div className={styles.p}>
        {`We will only process your personal data for the purpose it was first collected. If we process the personal data for a new purpose, we will ensure it is either compatible with your original purpose or gain your consent.`}
      </div>
      <div className={styles.p}>
        {`We may use the personal data we obtain to:`}
      </div>
      <ul className={styles.ul}>
        <li className={styles.li}>{`Manage our existing and prospective relationships;`}</li>
        <li className={styles.li}>{`Communicate with Market Research Participants for marketing, newsletters and company update;`}</li>
        <li className={styles.li}>{`Process compensation payments;`}</li>
        <li className={styles.li}>{`Safeguard and defend Trinity’s interests; and`}</li>
        <li className={styles.li}>{`Comply with applicable legal requirements, industry standards and our policies.`}</li>
      </ul>

      <div className={cx(styles.p, styles.b)}>
        {`How We May Share Personal Data:`}
      </div>
      <div className={styles.p}>
        {`Trinity does not sell, rent, or trade member’s personal data. We may share your personal data only with:`}
      </div>
      <ul className={styles.ul}>
        <li className={styles.li}>{`Affiliates of Trinity to whom it is reasonably necessary or desirable for Trinity to disclose the personal data`}</li>
        <li className={styles.li}>{`Service providers that Trinity has retained to perform services on its behalf, such as, but not limited to, IT service providers. Service providers are not permitted to use the personal data for their own purposes and are prohibited from onward transfer of the personal data without our written consent in each instance`}</li>
        <li className={styles.li}>{`Law enforcement and other government authorities if required by law or reasonably necessary to protect the rights, property, and safety of others or ourselves. This includes lawful requests by public authorities, including to meet national security or law enforcement requirements`}</li>
        <li className={styles.li}>{`Our clients or carefully selected partners, which will, where possible be anonymized data only. No identifiable personal data will be shared without your consent.`}</li>
      </ul>

      <div className={cx(styles.p, styles.b)}>
        {`Links to other websites:`}
      </div>
      <div className={styles.p}>
        {`Our website may contain links to other websites. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting other websites and these other websites are not governed by this privacy policy. You should exercise caution and look at the privacy statement applicable to the website in question.`}
      </div>
      <div className={styles.p}>
        {`Trinity submits to being subject to the investigatory and enforcement powers of the FTC or any other U.S. authorized statutory body.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`To clients and partners:`}
      </div>
      <div className={styles.p}>
        {`As laid out in this Privacy Policy, we may from time to time share limited information with our clients or carefully selected partners.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`How We Protect Personal Data:`}
      </div>
      <div className={styles.p}>
        {`We maintain appropriate technical and organizational security safeguards designed to protect personal data against accidental, unlawful, or unauthorized destruction, loss, alteration, access, disclosure, or use. We update and test our security technology on an ongoing basis. We limit access to your personal data to those employees who need access to provide benefits or services to you. In addition, we train our employees about the importance of confidentiality and maintaining the privacy and security of your personal data.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`How Long We Retain Personal Data:`}
      </div>
      <div className={styles.p}>
        {`We store personal data for as long as necessary to fulfil the purposes for which we collect the data (see "How we use the Personal Data we obtain"), except if required otherwise by law. Your personal data may be kept for 10 years after the working relationship has ceased.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Changes and updates to the Privacy Policy:`}
      </div>
      <div className={styles.p}>
        {`As our organization, membership and benefits change from time to time, this Privacy Policy and our Terms of Use is expected to change as well. We reserve the right to amend the Privacy Policy and Terms of Use at any time, for any reason, without notice to you, other than the posting of the amended Privacy Policy and Terms of Use at this Site. We may e-mail periodic reminders of our policy and terms and will e-mail members of material changes to it, but you should check our Site frequently to see the current Privacy Policy and Terms of Use that are in effect and any changes that may have been made to them.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Your Rights:`}
      </div>
      <div className={styles.p}>
        {`If you wish to confirm that Trinity is processing your personal data, or to have access to the personal data Trinity may have about you, please contact us.`}
      </div>
      <div className={styles.p}>
        {`You may request information about:`}
      </div>
      <ul className={styles.ul}>
        <li className={styles.li}>{`the purpose of the processing;`}</li>
        <li className={styles.li}>{`the legal basis for that processing;`}</li>
        <li className={styles.li}>{`the categories of personal data and the data subject concerned;`}</li>
        <li className={styles.li}>{`information on the type or identity of third parties to which your data may be disclosed to and the protection provided;`}</li>
        <li className={styles.li}>{`the source of the personal information (if you didn’t provide it directly to us); and`}</li>
        <li className={styles.li}>{`how long it will be stored.`}</li>
      </ul>

      <div className={styles.p}>
        {`You have a right to:`}
      </div>
      <ul className={styles.ul}>
        <li className={styles.li}>{`access your personal data`}</li>
        <li className={styles.li}>{`have inaccurate personal data rectified`}</li>
        <li className={styles.li}>{`request erasure of personal data`}</li>
        <li className={styles.li}>{`restrict the processing of your personal data`}</li>
        <li className={styles.li}>{`object to the processing your personal data`}</li>
        <li className={styles.li}>{`data portability`}</li>
        <li className={styles.li}>{`opt out of data being transferred to a third party, unless there is a legal reason to do so (see "How we may share Personal Data")`}</li>
        <li className={styles.li}>{`opt out of direct marketing`}</li>
      </ul>

      <div className={styles.p}>
        {`To exercise your rights, you can write us at support@sentimentglobal.com.`}
      </div>

      <div className={styles.p}>
        {`Reasonable access to your personal data will be provided at no cost to you upon your request. Trinity will provide the information to you within the legal time frame. If for some reason access is denied, Trinity will provide an explanation as to why access has been denied.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Independent Dispute Resolution Mechanism:`}
      </div>
      <div className={styles.p}>
        {`Trinity commits to resolve complaints about our collection or use of your personal data.`}
      </div>
      <div className={styles.p}>
        {`If you have a privacy or data use concern that we have not addressed satisfactorily, please contact us at support@sentimentglobal.com.`}
      </div>
      <div className={styles.p}>
        {`Under certain conditions, you may invoke binding arbitration when other dispute resolution procedures have been exhausted.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Contact Information:`}
      </div>
      <div className={styles.p}>
        {`If you have questions about this Privacy Policy, please email us at support@sentimentglobal.com.`}
      </div>
      <div className={styles.p}>
        {`For all other questions regarding your account, preference changes, compensation for surveys or our services, please e-mail support@sentimentglobal.com.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`SUPPLEMENTAL PRIVACY NOTICE FOR CALIFORNIA RESIDENTS ONLY`}
      </div>
      <div className={styles.p}>
        {`Effective Date: January 1, 2020`}
      </div>
      <div className={styles.p}>
        {`Last Revised: December 1, 2021`}
      </div>
      <br />
      <div className={styles.p}>
        {`This notice supplements the information contained in the main Trinity Privacy Policy (the "Main Privacy Policy") set forth above and applies solely to residents of the State of California ("California Privacy Policy"). We have adopted our California Privacy Policy in order to comply with the California Consumer Privacy Act of 2018 ("CCPA"`}
      </div>
      <div className={styles.p}>
        {`Our California Privacy Policy is intended to fulfill CCPA's objectives of providing California consumers with a comprehensive disclosure of the collection, sharing, disclosure, and sale of their personal information by particular businesses (as CCPA defines those terms), and of the rights that California consumers have regarding their personal information ("California Privacy Rights").`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`I. California Privacy Rights Under CCPA`}
      </div>
      <div className={styles.p}>
        {`Right to Know About Personal Information Collected, Disclosed or Sold ("Right to Know")`}
      </div>
      <div className={styles.p}>
        {`Scope of Applicable Personal Information`}
      </div>
      <div className={styles.p}>
        {`CCPA provides you the right to request that we disclose to you any or all the following about your personal information for the 12-month period preceding your request:`}
      </div>
      <ul className={styles.ul}>
        <li className={styles.li}>{`the specific pieces of personal information that we have collected about you;`}</li>
        <li className={styles.li}>{`the categories of personal information we have collected about you;`}</li>
        <li className={styles.li}>{`the categories of sources from which we have collected your personal information;`}</li>
        <li className={styles.li}>{`the categories of personal information that we have sold or disclosed for a business purpose about you;`}</li>
        <li className={styles.li}>{`the categories of third parties to whom your personal information was sold or disclosed for a business purpose; and`}</li>
        <li className={styles.li}>{`the business purpose for collecting or selling your personal information.`}</li>
      </ul>
      <div className={styles.p}>
        {`CCPA also provides you the right to receive information about any financial incentives (i.e., compensation payments) that we may offer in connection with use of your personal information for participating in certain of our Activities (as that term is defined in "About Trinity" in our Main Privacy Policy).`}
      </div>
      <div className={styles.p}>
        {`For detailed information about the personal information that we collect and the purposes for which we collect it, please see Part II ("Information We Collect and What We May Do With It") below.`}
      </div>
      <br />
      <div className={styles.p}>
        {`If you exercise your Right To Know, after we confirm your identity, as explained more fully in Part III ("Exercising Your California Privacy Rights"), we will: (a) disclose to you the information we have collected about you during the preceding 12 months that corresponds to your request; and (b) deliver, free of charge to you, by mail, or electronically, the personal information that you have requested, in the manner required by CCPA and as further described below in Part III ("Exercising Your California Privacy Rights").`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Exclusions from Scope of Personal Information`}
      </div>
      <div className={styles.p}>
        {`CCPA excludes the following from its definition of "personal information":`}
      </div>
      <ul className={styles.ul}>
        <li className={styles.li}>{`Publicly available information from government records`}</li>
        <li className={styles.li}>{`De-identified or aggregated consumer information`}</li>
        <li className={styles.li}>{`Information excluded from CCPA's scope, such as`}</li>
        <li className={styles.li}>{`Health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data; and`}</li>
        <li className={styles.li}>{`Personal information regulated by other sector-specific laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA), California Financial Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994`}</li>
      </ul>

      <div className={cx(styles.p, styles.b)}>
        {`Right to Have Personal Information Deleted ("Right to Delete")`}
      </div>
      <div className={styles.p}>
        {`You have the right to request the deletion of your personal information that we collect or maintain.`}
      </div>
      <div className={styles.p}>
        {`If you exercise your Right to Delete, after we confirm your identity, as explained more fully below in Part III ("Exercising Your California Privacy Rights"), we will, in the manner specified by CCPA, permanently delete your personal information from our records, unless one of the CCPA exceptions applies to your deletion request applies.`}
      </div>
      <div className={styles.p}>
        {`Also, to the extent required by CCPA, we will direct deletion of your personal information by third parties with whom we have shared the personal information that you have requested that we delete.`}
      </div>
      <div className={styles.p}>
        {`Exceptions to your Right To Delete include, but are not limited to, instances when the personal information is necessary to complete a transaction or provide the service requested by you for which the subject personal information was collected or provided (such as to participate in our market research Activities), detect security incidents or protect against deceptive, fraudulent, or illegal activity, or to comply with a legal obligation under CCPA, the California Electronic Communications Privacy Act, or other California or US federal law.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Right to Opt-Out of the Sale of Personal Information ("Right to Opt-Out")`}
      </div>
      <div className={styles.p}>
        {`Under CCPA, you have the right to opt-out of the "sale" (as defined by CCPA) by a CCPA-subject business of your personal information to third parties. CCPA defines a "sale" of personal information as occurring when a business exchanges personal information of California residents to a third party for monetary or other valuable consideration.`}
      </div>
      <div className={styles.p}>
        {`Trinity does not "sell" your personal information, in either the CCPA sense, nor any other sense.`}
      </div>
      <div className={styles.p}>
        {`When you consent to participate in one of our market research Activities, we may share your personal information with our clients and carefully selected service providers solely for business purposes and as otherwise required by law.`}
      </div>
      <div className={styles.p}>
        {`Except as set forth herein, all data that we share will be deidentified information (meaning, data that has been carefully encrypted so it no longer identifies you or is linkable to you) or aggregate information (which means data from a group of individuals from which all individual identities have been carefully removed so no particular person may be identified). Neither deidentified information nor aggregate consumer information are considered personal information as defined in CCPA.`}
      </div>
      <div className={styles.p}>
        {`Personal information will never be shared in any way, or for any purpose, other than for what has been specifically consented for us to do with it.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Right to Not Be Discriminated Against for Exercising Your California Privacy Rights ("Right to Non-Discrimination")`}
      </div>
      <div className={styles.p}>
        {`You have the right to not receive discriminatory treatment if and when you exercise any of your California Privacy Rights under CCPA. This would include, as examples, imposing a penalty fee or denying services to you because you exercised a CCPA right.`}
      </div>
      <div className={styles.p}>
        {`We will never penalize or otherwise subject you to discriminatory treatment for exercising any of your California Privacy Rights.`}
      </div>
      <div className={styles.p}>
        {`Please note that exercising your right to delete some or all your personal information may mean that you are not able to participate in certain market research opportunities that we offer.`}
      </div>
      <div className={styles.p}>
        {`For example, if we do not have enough information to determine whether you meet the criteria for a study, you will not receive invitations to participate. In addition, certain studies may require that certain aspects of your personal information be shared with the client (such as a focus group opportunity, where your name, contact information, medical background, and practice location are needed in order to convene a desired focus group).`}
      </div>
      <div className={styles.p}>
        {`Financial incentives, such as per-survey compensation payments, are made for certain of our market research Activities. Market research compensation payment amounts are set at levels determined by considering many variables such as (by way of examples only) the complexity of the subject matter of the research inquiry, the professional education, training, and experience level desired for the respondents, the number of respondents needed, and the number of qualified respondents available.`}
      </div>
      <div className={styles.p}>
        {`If you have questions about your Right to Non-Discrimination, please contact us as described in Part III ("Exercising Your California Privacy Rights") below.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`II. Information We Collect and What We May Do With It`}
      </div>
      <div className={styles.p}>
        {`Trinity, and our affiliates, as well as our third-party business partners with whom we conduct our regular business operations, may collect, share, or disclose personal information solely for business purposes as described in the following sections: `}
      </div>
      <div className={styles.p}>
        {`Categories of Third Parties refers to the following persons or entities with whom we may share personal information for business purposes:`}
      </div>
      <div className={styles.p}>
        {`Business Support Consultants (examples include our consultants, advisors, and/or vendors who help us by (as examples only): (a) preventing fraud or violations of our Terms of Service; (b) analyzing site user data and behavior, and tracking page performance; (c) identifying site issues and repair errors that impair intended functionality; (d) providing marketing and survey panel recruitment assistance, such as moderators and translators; (e) providing customer service to our Site users; (f) providing CCPA-required and other verifications of consumers or consumer consent; and/or (g) providing data storage and/or data processing services.`}
      </div>
      <div className={styles.p}>
        {`All of our Business Support Consultants have written agreements with us which:`}
      </div>
      <ul className={styles.ul}>
        <li className={styles.li}>{`require them to comply with all legal privacy requirements to which we are subject, including those provided in CCPA;`}</li>
        <li className={styles.li}>{`limit their access to personal information to the minimum necessary in order to perform their services for us; and`}</li>
        <li className={styles.li}>{`restrict them from making any use of personal information beyond fulfilling the specific services to us for which they are engaged;`}</li>
        <li className={styles.li}>{`Business Partners (examples include clients, prospective clients and their representatives who may engage us to conduct market research or provide other services for CCPA-permitted business purposes); and`}</li>
        <li className={styles.li}>{`Public Authorities (examples include law enforcement and other government authorities if required by law or reasonably necessary to protect the rights, property, and safety of others or ourselves)`}</li>
      </ul>

      <div className={cx(styles.p, styles.b)}>
        {`Business Purposes for Collecting Personal Information:`}
      </div>
      <div className={styles.p}>
        {`Trinity will only collect and use your personal information for the following business purposes listed below:`}
      </div>
      <ul className={styles.ul}>
        <li className={styles.li}>{`Providing you with the services and support that you may request, and processing and validating your transactions;`}</li>
        <li className={styles.li}>{`Communicating with you about your account or transactions, changes to our policies and other administrative matters, or responding to your questions and comments;`}</li>
        <li className={styles.li}>{`Understanding better your interests in order to provide you with customized market research opportunities and other content;`}</li>
        <li className={styles.li}>{`Administering our research surveys and studies, and improving all of our products, services and operations;`}</li>
        <li className={styles.li}>{`Ensuring compliance with our policies and Website Terms and Condition, or as we believe is necessary to protect, enforce or defend the legal rights, privacy, safety or property of ourselves and others;`}</li>
        <li className={styles.li}>{`Complying with our legal and regulatory requirements; and`}</li>
        <li className={styles.li}>{`Helping us better search for, communicate with, and validate prospective panel members in our systems in order to match them with particular market research needs.`}</li>
      </ul>
      <div className={styles.p}>
        {`These business purposes are indicated by numeral, as applicable, in the third and fourth columns (respectively) of the charts in the next two sections.`}
      </div>
      <div className={styles.p}>
        {`We will never use your personal information differently than what we have disclosed to you in this notice unless we first notify you and obtain your consent to a different use.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Notice at Collection (Personal Information Collected By Us Upon Your Visiting Our Site)`}
      </div>
      <div className={styles.p}>
        {`CATEGORY OF PERSONAL INFORMATION from CCPA §1798.140(o)(1)(A-K)PERSONAL INFORMATION COLLECTED BY US UPON YOUR VISITING OUR SITEBUSINESS PURPOSE FOR WHICH PERSONAL INFORMATION WILL BE USED.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Personal Information We Have Collected Over the Past Twelve Months.`}
      </div>
      <div className={styles.p}>
        {`CATEGORY OF PERSONAL INFORMATION from CCPA §1798.140(o)(1)(A-K)EXAMPLES OF PERSONAL INFORMATION COLLECTED BY US OVER THE PAST 12 MONTHS CATEGORY OF SOURCES FROM WHICH COLLECTED BUSINESS PURPOSE FOR WHICH COLLECTED CATEGORY OF THIRD PARTIES TO WHICH DISCLOSED`}
      </div>
      <div className={styles.p}>
        {`A. Identifiers Name, postal address, e-mail address, telephone numbers, registration details for Healthcare Professionals, Internet protocol address, records of the Activities (as defined in the Main Privacy Policy) participated in, qualified for, completed or partially completed.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Sales of Personal Information During Past 12 Months`}
      </div>
      <div className={styles.p}>
        {`Trinity does not ever "sell" personal information, as described in more detail above in Part I.C ("Right to Opt-Out of the Sale of Personal Information").`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`III. Exercising Your California Privacy Rights`}
      </div>
      <div className={styles.p}>
        {`Exercising Rights to Opt-Out of Sale of Personal Information: As Trinity does not "sell" any personal information, as that term is defined in CCPA, we have not provided consumers with a means to "opt-out" of such sales. However, we will be glad to answer any questions you may have about this or any of your California Privacy Rights. Please use one of the contact methods described below to reach us for any questions.`}
      </div>
      <div className={styles.p}>
        {`In order to exercise your Right to Know, Right to Delete, or Right To Non-Discrimination, please submit your request to us in any one of the ways listed below.`}
      </div>

      <div className={cx(styles.p, styles.b)}>{`By mail:`}</div>
      <div className={styles.p}>{`Trinity`}</div>
      <div className={styles.p}>{`230 Third Avenue`}</div>
      <div className={styles.p}>{`5th Floor`}</div>
      <div className={styles.p}>{`Waltham, MA 02451-7528`}</div>
      <br />
      <div className={cx(styles.p, styles.b)}>{`By email: support@sentimentglobal.com`}</div>
      <br />
      <div className={styles.p}>
        {`Only you, or a person (or a business entity registered with the California Secretary of State) whom you have authorized to act on your behalf may make a consumer request about your personal information. Consistent with CCPA's provisions, we may require either (or both) that you provide your authorized agent with written permission to act for you and verify your own identity to us (or provide a power of attorney signed by you stating your authorized agent's power to act for you).`}
      </div>
      <div className={styles.p}>
        {`If you have questions or concerns about your rights under our California Privacy Policy, or any of the provisions or practices described in either our California Privacy Policy or our Main Privacy Policy, please contact us via the telephone number, e-mail address, or mail/courier address provided above.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`IV. Responding to Right to Know and Right to Delete Requests`}
      </div>
      <div className={styles.p}>
        {`Within ten (10) business days of receipt of your Right to Know or Right to Delete request, we will confirm our receipt, provide you details about the process we will follow to respond to your request, and/or ask questions, or for additional information, that may be required.`}
      </div>
      <div className={styles.p}>
        {`We plan to fulfill these requests within 45 calendar days of receiving the request. If we need more time to respond to your request, we will let you know the reason and, within the first 45 calendar days following receipt of your request, estimate how much additional time we will need (in any event, we will complete your request within 90 calendar days in total from receipt of the request). `}
      </div>
      <div className={styles.p}>
        {`If we deny your Right to Know or Right to Delete request, in whole or in part, we will provide you with the reason for this, and if our denial is partial, we will disclose to you, or delete (as applicable), the other information you have requested.`}
      </div>
      <div className={styles.p}>
        {`Verification Process: To assert your Right to Know or your Right to Delete, you will be asked to verify your identity before fulfilling your request. This verification process may require you to provide pieces of personal information that we already hold on you, or, in certain circumstances, a signed declaration under penalty of perjury that you are the consumer whose personal information is the subject of the request.`}
      </div>
      <div className={styles.p}>
        {`Procedure; Frequency of Submitting Right to Know Requests: You may make requests regarding your Right to Know only twice within any 12-month period.`}
      </div>
      <div className={styles.p}>
        {`We do not charge a fee to process or respond to your Right to Know requests, unless a particular request is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request. `}
      </div>
      <div className={styles.p}>
        {`Procedure; Frequency of Submitting Right to Delete Requests: You may make a Right to Delete request to us at any time. Following verification of your request, we will immediately and permanently delete the personal information requested by you, unless one of CCPA's exception to this right applies to your request.`}
      </div>
      <div className={styles.p}>
        {`If we cannot verify the identity of the consumer requesting deletion of personal information, we may deny the Right to Delete request, and will inform the requestor of this.`}
      </div>
      <div className={styles.p}>
        {`Once we verify the identity of the consumer making the Right to Delete request, we will respond to their request by either:`}
      </div>
      <ul className={styles.ul}>
        <li className={styles.li}>{`permanently and completing erasing the personal information from our systems and records, in the manner specific by CCPA;`}</li>
        <li className={styles.li}>{`de-identifying the personal information (meaning, carefully encrypting the personal information so it no longer identifiable or linkable to a particular consumer); or`}</li>
        <li className={styles.li}>{`aggregating the personal information individual (meaning, we will carefully remove any consumer-identifiers from it, and group it together with other similarly-stripped data from which the resulting data group contains no particular consumer's personal information in an identifiable fashion).`}</li>
      </ul>
      <div className={styles.p}>
        {`We will inform you of the manner in which we have deleted your personal information following a Right to Delete request.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`V. Updates to this Supplementary Privacy Notice`}
      </div>
      <div className={styles.p}>
        {`We may reserve the right to periodically update our California Privacy Policy. Please reference the "LAST UPDATED" legend above for the effective date of the most recent updates. Your continued use of our Site following these changes means that you accept the revised California Privacy Policy. `}
      </div>
      <div className={styles.p}>
        {`California's full CCPA statute, 1798 Cal. Civ. Code § 1978.100 - 1798.199, may be found here:`}
      </div>
      <div className={styles.p}>
        {a(`https://leginfo.legislature.ca.gov/faces/codes_displayText.xhtml?lawCode=CIV&division=3.&title=1.81.5.&part=4`)}
      </div>
      <div className={styles.p}>
        {`For information on preparing a Power of Attorney, see Cal. Probate Code § 4000-4465 ("Powers of Attorney"), here:`}
      </div>
      <div className={styles.p}>
        {a(`http://leginfo.legislature.ca.gov/faces/codes_displayexpandedbranch.xhtml?tocCode=PROB&division=4.5`)}
      </div>
    </Fragment>
  );
});

// function b(text: string) {
//   return <span className={styles.b}>{text}</span>;
// }

// function u(text: string) {
//   return <span className={styles.u}>{text}</span>;
// }

// function i(text: string) {
//   return <span className={styles.i}>{text}</span>;
// }

function a(url: string) {
  return (
    <a
      className={styles.i}
      href={url}
      target="_blank"
      rel="noreferrer">
      {url}
    </a>
  );
}

type Props = {
  className?: string;
};

export const PrivacyNoticeHeader = memo((props: Props) => {
  return (
    <div className={cx(styles.header, props.className)}>
      <div className={styles.p}>To be consistent with data protection laws, we’re asking you to take a moment to review key points of Sentiment’s GDPR Privacy Notice.</div>
      <div className={styles.p}>Scroll down and click “I agree” when you’re ready to continue.</div>
    </div>
  );
});