export class CompletionStatus {
  static get NotRequired() {
    return 1;
  }

  static get Incomplete() {
    return 2;
  }

  static get Complete() {
    return 3;
  }

  static CompletionStatusMap = new Map([
    [CompletionStatus.NotRequired, { name: 'Not Required' }],
    [CompletionStatus.Incomplete, { name: 'Incomplete' }],
    [CompletionStatus.Complete, { name: 'Complete' }],
  ]);

  static getName(id) {
    return (CompletionStatus.CompletionStatusMap.get(Number(id)) || {}).name;
  }

  static keys() {
    return Array.from(CompletionStatus.CompletionStatusMap.values()).map(({ name }) => name);
  }

  static values() {
    return Array.from(CompletionStatus.CompletionStatusMap.keys());
  }
}