import { useCallback, useContext } from 'react';
import { RemoveContactsContext } from '@containers/Group.Contacts/Context';
import styles from './style/Panel.css';

type Props = {
  children:  React.ReactNode;
  disabled?: boolean;
  onClose:   () => void;
};

export const Remove = ({ onClose, ...props }: Props) => {

  const { toggle } = useContext(RemoveContactsContext);

  const handleClick = useCallback(() => {
    onClose();
    toggle();
  }, [
    onClose,
    toggle,
  ]);

  return (
    <div>
      <button
        className={styles.btn}
        disabled={props.disabled}
        onClick={handleClick}>
        {props.children}
      </button>
    </div>
  );
};

Remove.displayName = 'Group.Contacts.Table.Toolbar.Action.Remove';