import { Input as ModalInput } from '@/components/Input';

type Props = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => unknown;
  value:    string;
};

export const Input = (props: Props) => {

  return (
    <ModalInput
      onChange={props.onChange}
      value={props.value} />
  );
};

Input.displayName = 'Editor.Input';