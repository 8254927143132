import type { ComponentType } from 'react';
import { useCallback } from 'react';
import { useToggle } from '@utils';
import type { ModalProps } from '@/components/Modal/Modal';
import type { UseModalProps } from '../interfaces';

type Props = UseModalProps;

type Options = {
  keepMounted?: boolean;
};

export const useModal = <T extends Props = Props, K = Omit<T, 'onClose' | 'open'>>(ModalComponent: ComponentType<Props & K>, options: Options = {}) => {
  const [visible, toggleVisibility] = useToggle();

  const Modal = useCallback((props: K) => {
    if (!options.keepMounted && !visible) {
      return null;
    }

    return (
      <ModalComponent
        onClose={toggleVisibility}
        open={visible}
        {...props} />
    );
  }, [
    ModalComponent,
    options.keepMounted,
    toggleVisibility,
    visible,
  ]);

  /* eslint-disable-next-line */
  // @ts-ignore
  Modal.displayName = `useModal`;

  return [toggleVisibility, Modal, visible] as const;
};