import { EditorComponent } from '@remirror/react';
import styles from './style/Expression.Editor.css';

export const ExpressionEditor = () => {

  return (
    <div className={styles.root}>
      <EditorComponent />
    </div>
  );
};