import { useContext } from 'react';
import { createNamedContext } from '@utils';
import { FetchSuggestedMentions } from './interfaces.mention';

export type LinkSelection = {
  href: string;
  range: {
    from: number;
    to: number;
  };
  text: string;
};

export type LinkPopperContextValue = {
  close: () => void;
  open: (params: LinkSelection) => void;
  selection: LinkSelection;
};

export type LinkModalContextValue = {
  close: () => void;
  open: (params: LinkSelection) => void;
  selection: LinkSelection;
};

export type SuggestedMentionsContextValue = [string, FetchSuggestedMentions];

export const SuggestedMentionsContext = createNamedContext<SuggestedMentionsContextValue>(undefined, 'SuggestedMentionsContext');

export const LinkPopperContext = createNamedContext<LinkPopperContextValue>(undefined, 'LinkPopperContext');
export const LinkModalContext = createNamedContext<LinkModalContextValue>(undefined, 'LinkModalContext');

export const useSuggestedMentionsContext = () => useContext(SuggestedMentionsContext);
export const useLinkPopperContext = () => useContext(LinkPopperContext);
export const useLinkModalContext = () => useContext(LinkModalContext);