import { useCallback } from 'react';
import { Trash2 } from 'react-feather';
import { useSelector } from 'react-redux';
import * as selectors from '@store/selectors';
import { cx } from '@utils';
import { useDisconnectUserAccount } from '@utils/api';
import { AnimatedLoader } from '@/components/ActivityIndicator';
import { useGoogleCalendarSync, useGoogleContactSync } from '@/components/ConnectAccount/hooks';
import google from 'static/icons/google.svg?url';
import { SyncButtonProps } from './interfaces';
import styles from './style/ConnectAccount.css';

export const GoogleCalendarSync = ({
  className,
  onConnected,
}: SyncButtonProps) => {

  const connected = useSelector(selectors.selectGoogleCalendar);

  const {
    connect,
    isLoading,
  } = useGoogleCalendarSync({ onConnected });
  const { mutateAsync: disconnect } = useDisconnectUserAccount();

  const handleDisconnect = useCallback((id: number) => () => {
    if (isLoading) return;

    disconnect({ id });
  }, [
    disconnect,
    isLoading,
  ]);

  if (connected) {
    return (
      <div
        className={cx(styles.connection, className)}>
        <img
          className={styles.btnImg}
          src={google} />
        <div
          className={styles.connectionText}>
          {connected.email}
        </div>
        <div
          className={styles.trash}
          onClick={handleDisconnect(connected.id)}>
          <Trash2 />
        </div>
      </div>
    );
  }

  const buttonClass = cx({
    [styles.btn]: !isLoading,
    [styles.btnLoading]: isLoading,
  }, className);

  return (
    <button
      className={buttonClass}
      onClick={isLoading ? () => {} : connect}>
      <img
        className={styles.btnImg}
        src={google} />
      <div
        className={styles.addText}>
        Connect with Google{isLoading && <AnimatedLoader size={10} />}
      </div>
    </button>
  );
};

export const GoogleContactSync = ({
  className,
  onConnected,
}: SyncButtonProps) => {

  const connected = useSelector(selectors.selectGoogleContacts);

  const {
    connect,
    isLoading,
  } = useGoogleContactSync({ onConnected });
  const { mutateAsync: disconnect } = useDisconnectUserAccount();

  const handleDisconnect = useCallback((id: number) => () => {
    if (isLoading) return;

    disconnect({ id });
  }, [
    disconnect,
    isLoading,
  ]);

  if (connected) {
    return (
      <div
        className={cx(styles.connection, className)}>
        <img
          className={styles.btnImg}
          src={google} />
        <div
          className={styles.connectionText}>
          {connected.email}
        </div>
        <div
          className={styles.trash}
          onClick={handleDisconnect(connected.id)}>
          <Trash2 />
        </div>
      </div>
    );
  }

  const buttonClass = cx({
    [styles.btn]: !isLoading,
    [styles.btnLoading]: isLoading,
  }, className);

  return (
    <button
      className={buttonClass}
      onClick={isLoading ? () => {} : connect}>
      <img
        className={styles.btnImg}
        src={google} />
      <div
        className={styles.addText}>
        Connect with Google{isLoading && <AnimatedLoader size={10} />}
      </div>
    </button>
  );
};