import { useMemo, useCallback } from 'react';
import type { BarTooltipDatum, BarMouseEventHandler, BarDatum } from '@nivo/bar';
import { MatrixChartTooltip } from '@presentation/ProjectSurveyResponses';
import StackedBarChart from './StackedBarChart';
import type { StackedBarChartData, Colors, MatrixGroupItem } from './interfaces';

type Props = {
  keys: {
    id: number;
    isNotApplicable: boolean;
  }[];
  responses: MatrixGroupItem[];
  onSegmentClick: (rowId: number, key: string) => void;
};

export const MatrixMultiselectResponsesChart = ({
  onSegmentClick,
  ...props
}: Props) => {

  const items: StackedBarChartData[] = useMemo(() => {

    return props.responses.map(m => {

      const values: BarDatum = {};
      m.options.forEach(o => {
        values[o.id] = o.userIds.length;
      });

      const colors: Colors = {};
      m.options.forEach(o => {
        colors[o.id] = o.color;
      });

      return Object.assign(values, {
        colors,
        indexId: m.id.toString(),
        name: m.name,
      }) as StackedBarChartData;
    });

  }, [props.responses]);

  const notApplicableKeys = useMemo(() => {
    const naOption = props.keys.find(f => f.isNotApplicable);

    return naOption
      ? [naOption.id.toString()]
      : [];
  }, [
    props.keys,
  ]);

  const handleSegmentClick: BarMouseEventHandler<SVGRectElement, StackedBarChartData> = useCallback((item, e) => {
    onSegmentClick(+item.data.indexId, item.id.toString());
  }, [onSegmentClick]);

  const renderTooltip = useCallback((item: BarTooltipDatum) => {

    const data = props.responses
      .find(f => f.id === +item.data.indexId).options.find(f => f.id.toString() === item.id);

    return (
      <MatrixChartTooltip
        rowName={item.data.name}
        count={data.userIds.length}
        optionName={data.name}
        percent={data.pct} />
    );
  }, [props.responses]);

  const maxValue = useMemo(() => {
    const max = Math.max(...props.responses.map(m => m.count));
    return Math.round(max + (max * 0.1 < 1 ? 1 : max * 0.1));
  }, [props.responses]);

  const formatBottomAxisLabel = useCallback((value: string) => {
    return Number.isInteger(+value)
      ? value
      : '';
  }, []);

  const keys = props.keys.map(m => m.id.toString());

  // console.log('KEYS', keys);
  // console.log('ITEMS', items);

  return (
    <StackedBarChart
      formatBottomAxisLabel={formatBottomAxisLabel}
      minValue={0}
      maxValue={maxValue}
      handleSegmentClick={handleSegmentClick}
      items={items}
      keys={keys}
      notApplicableKeys={notApplicableKeys}
      renderTooltip={renderTooltip} />
  );
};

export default MatrixMultiselectResponsesChart;