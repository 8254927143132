import { EditorComponent, Remirror } from '@remirror/react';
import { useRichTextEditorRegister } from '@/containers/SurveyBuilder/hooks/useRichTextEditorRegister';
import { cx } from '@utils';
import type { SurveyRichText } from '@/types/survey.rich-text';
import type { DisplayOptions } from '@/components/Remirror/Extension.Image.DisplayView';
import { SurveyRichTextEditor } from './Editor';
import { SurveyRichTextEditorContainer } from './Editor.Container';
import { useSurveyRichTextEditor } from './hooks/useSurveyRichTextEditor';
import styles from './style/Editor.css';
import { SurveyRichTextUpdater } from './Editor.Updater';

type ReadonlyProps = {
  className?: string;
  editorClassName?: string;
  value: SurveyRichText.RichTextValue;
  imageDisplayOptions?: DisplayOptions;
};

export const SurveyQuestionText = ({
  className,
  editorClassName,
  value: initialState,
}: ReadonlyProps) => {

  const { manager, state, onChange } = useSurveyRichTextEditor({
    initialState,
  });

  return (
    <div className={cx(styles.root, className)}>
      <Remirror
        classNames={[styles.editor, editorClassName]}
        editable={false}
        manager={manager}
        initialContent={state}
        onChange={onChange}>
        <EditorComponent />
      </Remirror>
    </div>
  );
};

export const SurveyQuestionOption = ({
  className,
  editorClassName,
  value: initialState,
  ...rtOptions
}: ReadonlyProps) => {

  const { manager, state, onChange } = useSurveyRichTextEditor({
    initialState,
    ...rtOptions,
  });

  return (
    <div className={cx(styles.root, className)}>
      <Remirror
        classNames={[styles.editor, editorClassName]}
        editable={false}
        manager={manager}
        initialContent={state}
        onChange={onChange}>
        <EditorComponent />
      </Remirror>
    </div>
  );
};

type BuilderReadonlyProps = {
  className?: string;
  editorClassName?: string;
  value: SurveyRichText.RichTextValue;
  identifier: string;
};

export const BuilderSurveyQuestionText = ({
  className,
  editorClassName,
  value: initialState,
  identifier,
}: BuilderReadonlyProps) => {
  const { manager, state, onChange } = useSurveyRichTextEditor({
    initialState,
  });

  const editorRef = useRichTextEditorRegister({
    identifier,
  });

  return (
    <div className={cx(styles.root, className)}>
      <SurveyRichTextEditorContainer
        manager={manager}
        state={state}
        editable={false}
        onChange={onChange}
        editorRef={editorRef}>
        <SurveyRichTextEditor className={cx(styles.editor, editorClassName)} />
      </SurveyRichTextEditorContainer>
    </div>
  );
};

export const SurveyBuilderQuestionOption = ({
  className,
  editorClassName,
  value: initialState,
  identifier,
}: BuilderReadonlyProps) => {
  const { manager, state, onChange } = useSurveyRichTextEditor({
    initialState,
  });

  const editorRef = useRichTextEditorRegister({
    identifier,
  });

  return (
    <div className={cx(styles.root, className)}>
      <SurveyRichTextEditorContainer
        manager={manager}
        state={state}
        editable={false}
        onChange={onChange}
        editorRef={editorRef}>
        <SurveyRichTextUpdater value={initialState}>
          <SurveyRichTextEditor className={cx(styles.editor, editorClassName)} />
        </SurveyRichTextUpdater>
      </SurveyRichTextEditorContainer>
    </div>
  );
};

export const SurveyBuilderQuestionRow = ({
  className,
  editorClassName,
  value: initialState,
  identifier,
}: BuilderReadonlyProps) => {
  const { manager, state, onChange } = useSurveyRichTextEditor({
    initialState,
  });

  const editorRef = useRichTextEditorRegister({
    identifier,
  });

  return (
    <div className={cx(styles.root, className)}>
      <SurveyRichTextEditorContainer
        manager={manager}
        state={state}
        editable={false}
        onChange={onChange}
        editorRef={editorRef}>
        <SurveyRichTextEditor className={cx(styles.editor, editorClassName)} />
      </SurveyRichTextEditorContainer>
    </div>
  );
};

export const SurveyBuilderQuestionMetadata = ({
  className,
  editorClassName,
  value: initialState,
  identifier,
}: BuilderReadonlyProps) => {
  const { manager, state, onChange } = useSurveyRichTextEditor({
    initialState,
  });

  const editorRef = useRichTextEditorRegister({
    identifier,
  });

  return (
    <div className={cx(styles.root, className)}>
      <SurveyRichTextEditorContainer
        manager={manager}
        state={state}
        editable={false}
        onChange={onChange}
        editorRef={editorRef}>
        <SurveyRichTextEditor className={cx(styles.editor, editorClassName)} />
      </SurveyRichTextEditorContainer>
    </div>
  );
};

export const SurveyBuilderQuestionSettings = ({
  className,
  editorClassName,
  value: initialState,
  identifier,
}: BuilderReadonlyProps) => {
  const { manager, state, onChange } = useSurveyRichTextEditor({
    initialState,
  });

  const editorRef = useRichTextEditorRegister({
    identifier,
  });

  return (
    <div className={cx(styles.root, className)}>
      <SurveyRichTextEditorContainer
        manager={manager}
        state={state}
        editable={false}
        onChange={onChange}
        editorRef={editorRef}>
        <SurveyRichTextEditor className={cx(styles.editor, editorClassName)} />
      </SurveyRichTextEditorContainer>
    </div>
  );
};