import { Fragment, memo } from 'react';
import { Results } from '../interfaces/members';

export const Cell = memo(({ row }: Results.TableCellProps) => {
  return (
    <Fragment>
      {row.original.current ? row.original.current.title : '-'}
    </Fragment>
  );
});

export const Filter = memo(() => null);

export const Header = memo(() => {
  return (
    <Fragment>
      Title
    </Fragment>
  );
});

Cell.displayName = 'Column.Title.Cell';
Filter.displayName = 'Column.Title.Filter';
Header.displayName = 'Column.Title.Header';