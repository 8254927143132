import { useSaveProjectSurvey as useSaveSurvey } from '@utils/api';
import { useSurveyBuilderState } from '@containers/SurveyBuilder';

export const useSaveProjectSurvey = (projectId: number) => {
  const [_, dispatch] = useSurveyBuilderState();

  return useSaveSurvey({ projectId }, {
    onSuccess: res => {
      dispatch({
        type: 'reset-survey',
        value: res.survey,
      });
    },
  });
};