import { cx } from '@utils';
import styles from './style/Tooltip.PurchaseOrder.css';

type Props = unknown;

export const PurchaseOrder = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          {copy.title}
        </div>
        <div className={styles.main}>
          <div className={styles.addr}>
            <div className={styles.label}>{copy.label}</div>
            <div className={styles.email}>
              <a
                className={styles.link}
                href={`mailto:${copy.email}`}>
                {copy.email}
              </a>
            </div>
          </div>

          <div className={styles.info}>{copy.instructions.label}</div>
          <ul className={styles.list}>
            {copy.instructions.items.map(a =>
              <li
                className={cx(styles.item, a.className)}
                key={a.text}>
                <div className={styles.bp}>{a.text}</div>

                {a.items &&
                  <ul className={styles.dash}>
                    {a.items.map(b =>
                      <li
                        className={styles.dash}
                        key={b.text}>
                        <div className={styles.bp}>{b.text}</div>

                        {b.items &&
                          <ul className={styles.sm}>
                            {b.items.map(c =>
                              <li
                                className={styles.sm}
                                key={c.segments[0].text}>
                                <div className={styles.bp}>
                                  {c.segments.map(seg =>
                                    <span
                                      className={seg.className}
                                      key={seg.text}>
                                      {seg.text}
                                    </span>)}
                                </div>
                              </li>)}
                          </ul>}

                      </li>)}
                  </ul>}

              </li>)}
          </ul>

        </div>
      </div>
    </div>
  );
};

PurchaseOrder.displayName = 'Tooltip.PurchaseOrder';

const copy = {
  title: `How To Open A PO?`,
  label: `Send PO request to:`,
  email: `_PMRVendorPORequests@trinitylifesciences.com`,
  instructions: {
    label: `Please include the following in the email:`,
    items: [
      {
        text: `Subject Line: PO Request + Project Code`,
      },
      {
        items: [
          {
            text: `Vendor Name`,
          },
          {
            items: [
              {
                segments: [
                  { text: `POs include ` },
                  { className: styles.em, text: `line-item detail` },
                ],
              },
            ],
            text: `Vendor Cost Grid and or Vendor Bid`,
          },
        ],
        text: `Body: Include Vendor Information`,
      },
      {
        className: styles.italic,
        text: `Reminder: POs will be created for internal services (Sentiment, Internal Programming, PMR Technology fee)`,
      },
    ],
  },
};