import { memo } from 'react';
import { cx } from '@utils';
import styles from './style/Button.Pulsate.css';

type Props = {
  animate?:   boolean;
  className?: string;
  children?:  React.ReactNode;
  color?:     'blue' | 'green' | 'purple';
  rounded?:   boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const ButtonPulsate = memo(({
  animate = true,
  className,
  children,
  color = 'blue',
  rounded = true,
  ...props
}: Props) => {
  const classnames = {
    root: cx(className, {
      [styles.btn]: true,
      [styles.brick]: !rounded,
      [styles.rounded]: rounded,
      [styles.disabled]: props.disabled,
    }),
    pulse: cx({
      [styles.brick]: !rounded,
      [styles.rounded]: rounded,
      [styles.blue]: color === 'blue',
      [styles.purple]: color === 'purple',
      [styles.green]: color === 'green',
      [styles.pulse]: !props.disabled,
      [styles.animate]: animate,
    }),
  };

  return (
    <button
      className={classnames.root}
      {...props}>
      <div className={styles.content}>{children}</div>
      <div className={classnames.pulse} />
    </button>
  );
});

ButtonPulsate.displayName = 'Button.Pulsate';