import { useCallback, useContext } from 'react';
import { ButtonOutlined } from '@presentation';
import { cx } from '@utils';
import { PricingModalContext, PricingModalPlanContext } from '$website/containers/Pricing/Context';
import * as Pricing from '$website/containers/Pricing/interfaces';
import { Button } from '@/components/Button';
import styles from './style/Column.Plan.css';

type Props = {
  id: Exclude<Pricing.ColumnIdentifer, Pricing.ColumnIdentifer.Service>;
};

export const PlanButton = (props: Props) => {
  const [open, toggle] = useContext(PricingModalContext);
  const [plan, setPlan] = useContext(PricingModalPlanContext);

  const handleClick = useCallback(() => {
    setPlan(props.id);
    toggle();
  }, [
    props.id,
    setPlan,
    toggle,
  ]);

  const buttons = {
    [Pricing.ColumnIdentifer.Customer]: ButtonOutlined,
    [Pricing.ColumnIdentifer.Enterprise]: Button,
    [Pricing.ColumnIdentifer.Market]: ButtonOutlined,
  };
  const classnames = {
    [Pricing.ColumnIdentifer.Customer]: cx(styles.btn, styles.transparent),
    [Pricing.ColumnIdentifer.Enterprise]: styles.btn,
    [Pricing.ColumnIdentifer.Market]: cx(styles.btn, styles.transparent),
  };

  const FooterButton = buttons[props.id];

  if (!FooterButton) return null;

  return (
    <FooterButton
      className={classnames[props.id]}
      variant="brick"
      onClick={handleClick}>
      Get Started
    </FooterButton>
  );
};

PlanButton.displayName = 'Pricing.Column.Plan.Button';