import { useMemo } from 'react';
import { surveys } from '@consts';
import type { SurveyQuestionType } from '@/enums';
import { useSurveyFormQuestionContext, useValidateRationale } from './hooks';
import { SurveyFormCanSubmitContext, useSurveyFormQuestionAnswer } from './Context';
import { SurveyFormBackContainer, SurveyFormSubmitContainer } from './BaseNavigation';

const NotApplicableOrdinal = surveys.NotApplicableRankingOrdinal;

type Props =
  ChildrenProps;

export const RankingFormContainer = (props: Props) => {

  const [answer] = useSurveyFormQuestionAnswer<SurveyQuestionType.Ranking>();
  const question = useSurveyFormQuestionContext<SurveyQuestionType.Ranking>();

  const validateRationale = useValidateRationale();

  const canSubmit = useMemo(() => {

    if (answer.dontKnow) return true;

    const isRationaleValid = validateRationale();

    if (!isRationaleValid) return false;

    const rankedItems = answer.options.filter(f => f.ordinal !== null);
    const rankedItemsExcludingNA = rankedItems.filter(f => f.ordinal !== NotApplicableOrdinal);

    const hasMinRank = !!question.settings.minRank;

    if (hasMinRank && rankedItemsExcludingNA.length < question.settings.minRank) {
      return false;
    }

    const hasMaxRank = !!question.settings.maxRank;

    if (hasMaxRank && rankedItemsExcludingNA.length > question.settings.maxRank) {
      return false;
    }

    if (!hasMinRank && !hasMaxRank && answer.options.length !== question.options.length) {
      return false;
    }

    const hasGaps = new Array(rankedItemsExcludingNA.length).fill(0).some((_, i) => {
      const ordinal = i + 1;
      const ordinalFulfilled = !!rankedItemsExcludingNA.find(f => f.ordinal === ordinal);
      return !ordinalFulfilled;
    });

    if (hasGaps) {
      return false;
    }

    return true;
  }, [
    answer,
    question.options,
    question.settings.minRank,
    question.settings.maxRank,
    validateRationale,
  ]);

  return (
    <SurveyFormCanSubmitContext.Provider value={canSubmit}>
      <SurveyFormBackContainer>
        <SurveyFormSubmitContainer>
          {props.children}
        </SurveyFormSubmitContainer>
      </SurveyFormBackContainer>
    </SurveyFormCanSubmitContext.Provider>
  );
};

export default RankingFormContainer;