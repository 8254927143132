import qs from 'query-string';
import http, { xhr } from '@services/http';
import { ProfileEntry } from '@enums';
import * as xform from '@transformers';
import { Profiles } from './interfaces';

export * as activity from './profiles.activity';
export * as bio from './profiles.bio';
export * as enrichment from './profiles.enrichment';

export const fetchUserProfile = (data: Profiles.FetchUserProfile.Request) => {
  return http.get(`/profiles/${data.userId}`)
  .then((res: Profiles.FetchUserProfile.Response) => ({
    bio: res.bio,
    education: res.education,
    employment: xform.toEmptyArray(res.employment, 'functions'),
    id: res.id,
    industry: xform.setIndustryFields(res.industry),
    profile: res.profile,
    product: xform.setProductFields(res.product),
  }));
};

/*
export const fetchVICS = (data: Profiles.FetchVICS.Request) => {
  return http.get(`/profiles/${data.userId}/vics`)
  .then((res: Profiles.FetchVICS.Response) => ({
    vics: xform.mapVICSByType(res.vics),
  }));
};
*/

export const getUserProfile = (data: Profiles.FetchUserProfile.Request) => {
  return xhr.get<Profiles.FetchUserProfile.Response>(`/profiles/${data.userId}`)
  .then(res => ({
    ...res.data,
    employment: xform.toEmptyArray(res.data.employment, 'functions'),
    profile: res.data.profile,
  }));
};

export const logProfileUpdate = (data: Profiles.LogProfileUpdate.Request): Promise<Profiles.LogProfileUpdate.Response> => {
  return http.put(`/profiles/${data.userId}/checkup`, data);
};

export const suggestHourlyRate = (data: Profiles.SuggestHourlyRateRequest): Promise<Profiles.SuggestHourlyRateResponse> => {
  return http.post(`/profiles/suggest-hourly-rate`, data);
};

export const updateProfile = <T extends ProfileEntry = ProfileEntry>(data: Profiles.UpdateProfileRequest<T>) => {
  return http.post(`/profiles/${data.userId}`, data);
};

export const trackProfileVisit = (data: Profiles.TrackProfileVisit.Request) => {
  return xhr.post(`/profiles/${data.userId}/visit`, {});
};

export const updateProfileAvatar = (data: Profiles.UpdateProfileAvatar.Request): Promise<Profiles.UpdateProfileAvatar.Response> => {
  const formData = new FormData();
  formData.append('blob', data.blob);

  return http.post(`/profiles/${data.userId}/avatar`, formData, {
    headers: {
      'Content-Type': `multipart/form-data`,
    },
  });
};

export const deleteEntry = (data: Profiles.DeleteEntry.Request) => {
  return http.delete(`/profiles/${data.userId}/${data.type}/${data.id}`);
};

export const getContactInfo = ({ userId, ...query }: Profiles.GetContactInfo.Request): Promise<Profiles.GetContactInfo.Response> => {
  return http.get(`/profiles/${userId}/contact-info?${qs.stringify(query, { arrayFormat: 'bracket' })}`);
};