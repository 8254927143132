import { useCallback, useMemo } from 'react';
import { SurveyQuestionType } from '@enums';
import {
  SurveyFormShowCompleteContext,
  useConjointAnalysisFormContext,
  useConjointAnalysisNavigationContext,
  useSurveyFormQuestionAnswer,
} from './Context';
import {
  useSubmitAnswer,
  useSurveyQuestionGoBack,
  useSurveyFormIsFirstItem,
  useSurveyFormIsLastItem,
  useSurveyFormQuestionContext,
} from './hooks';
import { SurveyFormItemBackContext, SurveyFormItemSubmitContext, SurveyFormCanSubmitContext } from './Context';

type Props =
  ChildrenProps;

export const ConjointAnalysisNavigationContainer = (props: Props) => {
  const [answer] = useSurveyFormQuestionAnswer<SurveyQuestionType.ConjointAnalysis>();
  const question = useSurveyFormQuestionContext<SurveyQuestionType.ConjointAnalysis>();

  const submitAnswer = useSubmitAnswer();
  const goBack = useSurveyQuestionGoBack();
  const { currentSet } = useConjointAnalysisFormContext();
  const conjoint = useConjointAnalysisNavigationContext();
  const isFirstQuestion = useSurveyFormIsFirstItem();
  const isLastQuestion = useSurveyFormIsLastItem();

  const back = useCallback(() => {
    if (currentSet === 1) {
      return goBack.mutateAsync();
    } else {
      return Promise.resolve(conjoint.back());
    }
  }, [
    conjoint.back,
    currentSet,
    goBack.mutateAsync,
  ]);

  const next = useCallback(() => {
    if (currentSet === question.sets.length) {
      return submitAnswer.mutateAsync();
    } else {
      return Promise.resolve(conjoint.next());
    }
  }, [
    answer,
    currentSet,
    conjoint.next,
    question.sets.length,
    submitAnswer.mutateAsync,
  ]);

  const backDisabled = useMemo(() => {
    return currentSet === 1 && isFirstQuestion;
  }, [
    currentSet,
    isFirstQuestion,
  ]);

  const showComplete = useMemo(() => {
    return currentSet === question.sets.length
      ? isLastQuestion
      : false
    ;
  }, [
    currentSet,
    question.sets.length,
    isLastQuestion,
  ]);

  const backCtx = {
    disabled: backDisabled,
    isError: goBack.isError,
    isLoading: goBack.isLoading,
    handler: back,
  };

  const submitCtx = {
    isError: submitAnswer.isError,
    isLoading: submitAnswer.isLoading,
    handler: next,
  };

  return (
    <SurveyFormCanSubmitContext.Provider value={!conjoint.nextDisabled}>
      <SurveyFormItemBackContext.Provider value={backCtx}>
        <SurveyFormItemSubmitContext.Provider value={submitCtx}>
          <SurveyFormShowCompleteContext.Provider value={showComplete}>
            {props.children}
          </SurveyFormShowCompleteContext.Provider>
        </SurveyFormItemSubmitContext.Provider>
      </SurveyFormItemBackContext.Provider>
    </SurveyFormCanSubmitContext.Provider>
  );
};

export default ConjointAnalysisNavigationContainer;