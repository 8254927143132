import { useMemo } from 'react';
import { AddItemButton } from '@presentation/AddItemButton';
import { SurveyTemplate } from '@/types/survey.templates';
import { TextEntryList } from '@/components/TextEntryList';
import styles from './style/Presets.css';

type Props = {
  canAddRole: boolean;
  canRemoveRole: boolean;
  roles: SurveyTemplate.TargetRole[];
  onAddRole: () => void;
  onRemoveRole: (id: string) => () => void;
  onUpdateRole: (id: string) => (v: string) => void;
};

export const TemplateRolesInput = ({
  canAddRole,
  canRemoveRole,
  onAddRole,
  onRemoveRole,
  onUpdateRole,
  roles,
}: Props) => {

  const items = useMemo(() => {
    return roles.map(m => ({
      key: m.id,
      value: m.value,
    }));
  }, [roles]);

  return (
    <>
      <TextEntryList
        items={items}
        placeholder="Enter a role"
        removeItem={onRemoveRole}
        removeItemEnabled={canRemoveRole}
        updateItemValue={onUpdateRole} />
      {canAddRole &&
        <AddItemButton
          className={styles.add}
          label="Add"
          onClick={onAddRole} />}
    </>
  );
};