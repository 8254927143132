import { SurveyPipedValueType, SurveyPipedQuestionValueType } from '@enums/Survey';

export const rowTypes = [
  SurveyPipedValueType.NumberTableCellValue,
  SurveyPipedValueType.SliderRowValue,
  SurveyPipedValueType.MatrixOptionsNotSelected,
  SurveyPipedValueType.MatrixOptionsSelected,
];

export type RowTypes = SurveyPipedValueType.NumberTableCellValue | SurveyPipedValueType.SliderRowValue | SurveyPipedValueType.MatrixOptionsNotSelected | SurveyPipedValueType.MatrixOptionsSelected;

export const optionTypes = [
  SurveyPipedValueType.MatrixRowsNotSelected,
  SurveyPipedValueType.MatrixRowsSelected,
];

export type OptionTypes = SurveyPipedValueType.MatrixRowsNotSelected | SurveyPipedValueType.MatrixRowsSelected;

export const ordinalTypes = [
  SurveyPipedValueType.RankedXOption,
];

export type OrdinalTypes = SurveyPipedValueType.RankedXOption;