import { BasicRouter } from '@routes/Routers';
import { path } from '@consts';
import { RouteAuth } from '@enums';
import { AccessManage } from '@screens/Access.Manage';
import { AccessRequest } from '@screens/Access.Request';
import * as Routing from '@routes/interfaces';

export const routes: Routing.Route[] = [
  {
    component: AccessManage,
    exact: true,
    path: path.Access.Manage,
  },
  {
    component: AccessRequest,
    exact: true,
    path: path.Access.Request,
  },
];

export const router = BasicRouter(routes, {
  auth: RouteAuth.Authenticated,
  path: path.Access.Root,
});