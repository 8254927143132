import http from '@services/http';
import { transformDates } from '@services/http/transformers';
import type { TranscribeRT } from '@/types';
import { qs, safeJsonDate } from '@utils';
import type { Transcripts } from './interfaces';

export * as cleanup from './transcripts.cleanup';
export * as enhancements from './transcripts.enhancements';
export * from './transcripts.summary';
export * as settings from './transcripts.settings';
export * as text from './transcripts.text';

export const downloadTranscriptReport = (data: Transcripts.DownloadTranscriptReport.Request): Promise<Transcripts.DownloadTranscriptReport.Response> => {
  return http.get(`/transcripts/${data.transcriptId}/report?format=${data.format}`);
};

export const saveTranscriptHighlight = ({ transcriptId, highlight }: Transcripts.SaveTranscriptHighlight.Request): Promise<Transcripts.SaveTranscriptHighlight.Response> => {
  return http.post(`/transcripts/${transcriptId}/highlights`, highlight);
};

export const removeTranscriptHighlight = ({ transcriptId, highlightId }: Transcripts.RemoveTranscriptHighlight.Request): Promise<Transcripts.RemoveTranscriptHighlight.Response> => {
  return http.delete(`/transcripts/${transcriptId}/highlights/${highlightId}`);
};

export const getTranscriptHighlights = ({ transcriptId }: Transcripts.GetTranscriptHighlights.Request): Promise<Transcripts.GetTranscriptHighlights.Response> => {
  return http.get(`/transcripts/${transcriptId}/highlights`);
};

export const getTranscriptComments = ({ transcriptId }: Transcripts.GetTranscriptComments.Request) => {
  return http.get<Transcripts.GetTranscriptComments.Response>(`/transcripts/${transcriptId}/comments`)
    .then(res => {
      return {
        comments: res.comments.map(item => ({
          ...item,
          parentComment: {
            ...item.parentComment,
            createdOn: safeJsonDate(item.parentComment.createdOn),
          },
          children: item.children.map(comment => ({
            ...comment,
            createdOn: safeJsonDate(comment.createdOn),
          })),
        })),
      };
    });
};

export const saveTranscriptComment = ({ transcriptId, ...body }: Transcripts.SaveTranscriptComment.Request): Promise<TranscribeRT.Comment> => {
  return http.post(`/transcripts/${transcriptId}/comments`, body)
    .then((res: Transcripts.SaveTranscriptComment.Response) => {
      return {
        ...res.comment,
        createdOn: safeJsonDate(res.comment.createdOn),
      };
    });
};

export const updateTranscriptComment = ({ commentId, transcriptId, ...body }: Transcripts.UpdateTranscriptComment.Request): Promise<TranscribeRT.Comment> => {
  return http.patch(`/transcripts/${transcriptId}/comments/${commentId}`, body)
    .then((res: Transcripts.UpdateTranscriptComment.Response) => {
      return {
        ...res.comment,
        createdOn: safeJsonDate(res.comment.createdOn),
      };
    });
};

export const removeTranscriptComment = ({ transcriptId, commentId }: Transcripts.RemoveTranscriptComment.Request) => {
  return http.delete(`/transcripts/${transcriptId}/comments/${commentId}`);
};

export const getTranscriptTaggedMoments = ({ transcriptId, ...params }: Transcripts.GetTranscriptTaggedMoments.Request) => {
  return http.get<Transcripts.GetTranscriptTaggedMoments.Response>(`/transcripts/${transcriptId}/tags?${qs.stringify(params, { arrayFormat: 'bracket' })}`, {
    transformResponse: transformDates,
  });
};

export const searchTranscriptTags = ({ transcriptId }: Transcripts.SearchTranscriptTags.Request) => {
  return http.get<Transcripts.SearchTranscriptTags.Response>(`/transcripts/${transcriptId}/tags/search`);
};

export const saveTaggedMoment = ({ transcriptId, ...body }: Transcripts.SaveTaggedMoment.Request) => {
  return http.patch<Transcripts.SaveTaggedMoment.Response>(`/transcripts/${transcriptId}/tags/moment`, body);
};

export const deleteTaggedMoment = ({ momentId, transcriptId }: Transcripts.DeleteTaggedMoment.Request) => {
  return http.delete(`/transcripts/${transcriptId}/tags/moment?momentId=${momentId}`);
};

export const getTranscriptSupplementalData = ({ transcriptId }: Transcripts.GetTranscriptSupplementalData.Request) => {
  return http.get<Transcripts.GetTranscriptSupplementalData.Response>(`/transcripts/${transcriptId}/supplemental-data`);
};

export const getTranscriptUploadCredentials = ({ transcriptId, newKey }: Transcripts.GetTranscriptUploadCredentials.Request) => {
  return http.get<Transcripts.GetTranscriptUploadCredentials.Response>(`/transcripts/${transcriptId}/upload-credentials${newKey ? '?newKey=true' : ''}`);
};

export const completeTranscriptRedaction = ({ transcriptId, tempTranscriptKey, redactRanges }: Transcripts.CompleteTranscriptRedaction.Request) => {
  return http.post<Transcripts.CompleteTranscriptRedaction.Response>(`/transcripts/${transcriptId}/complete-redaction`, {
    tempTranscriptKey,
    redactRanges,
  });
};

export const getTranscriptEntities = ({ transcriptId }: Transcripts.GetTranscriptEntities.Request) => {
  return http.get<Transcripts.GetTranscriptEntities.Response>(`/transcripts/${transcriptId}/entities`);
};

export const updateTranscriptEntity = ({ entityId, transcriptId, ...data }: Transcripts.UpdateTranscriptEntity.Request) => {
  return http.put<Transcripts.UpdateTranscriptEntity.Response>(`/transcripts/${transcriptId}/entities/${entityId}`, data);
};

export const downloadTranscriptTags = ({ transcriptId, format }: Transcripts.DownloadTranscriptTags.Request) => {
  return http.get<Transcripts.DownloadTranscriptTags.Response>(`/transcripts/${transcriptId}/tags/export`);
};

export const getTranscriptTagsOverview = ({ transcriptId }: Transcripts.GetTranscriptTagsOverview.Request) => {
  return http.get<Transcripts.GetTranscriptTagsOverview.Response>(`/transcripts/${transcriptId}/tags/overview`);
};

export const updateTranscriptSpeakers = ({ transcriptId, ...data }: Transcripts.UpdateTranscriptSpeakers.Request) => {
  return http.put<Transcripts.UpdateTranscriptSpeakers.Response>(`/transcripts/${transcriptId}/speakers`, data);
};

export const updateTranscriptSpeakerSelection = ({ transcriptId, ...data }: Transcripts.UpdateTranscriptSpeakerSelection.Request) => {
  return http.put<Transcripts.UpdateTranscriptSpeakerSelection.Response>(`/transcripts/${transcriptId}/speakers/selection`, data);
};

export const getTranscriptMedia = ({ transcriptId }: Transcripts.GetTranscriptMedia.Request) => {
  return http.get<Transcripts.GetTranscriptMedia.Response>(`/transcripts/${transcriptId}/media`);
};

export const downloadTagClip = ({ transcriptId, ...data }: Transcripts.DownloadConferenceTagClip.Request) => {
  return http.post<Transcripts.DownloadConferenceTagClip.Response>(`/transcripts/${transcriptId}/tags/moment/generate-clip`, data);
};

export const getMetadata = (data: Transcripts.GetMetadata.Request) => {
  return http.get<Transcripts.GetMetadata.Response>(`/transcripts/${data.transcriptId}/metadata`, {
    transformResponse: transformDates,
  });
};

export const updateMetadata = (data: Transcripts.UpdateMetadata.Request) => {
  return http.patch(`/transcripts/${data.transcriptId}/metadata`, {
    publishedOn: data.publishedOn,
    title: data.title,
  });
};

export const updatePublishedOn = (data: Transcripts.UpdatePublishedOn.Request) => {
  return http.patch(`/transcripts/${data.transcriptId}/metadata/published-on`, {
    publishedOn: data.publishedOn,
  });
};

export const starTranscriptQuote = ({ transcriptId, ...data }: Transcripts.StarTranscriptQuote.Request): Promise<Transcripts.StarTranscriptQuote.Response> => {
  return http.post(`/transcripts/${transcriptId}/quotes`, data);
};

export const unstarTranscriptQuote = (data: Transcripts.UnstarTranscriptQuote.Request) => {
  return http.delete(`/transcripts/${data.transcriptId}/quotes/${data.quoteId}`);
};

export const downloadTranscriptClip = ({ transcriptId, ...params }: Transcripts.DownloadTranscriptClip.Request): Promise<Transcripts.DownloadTranscriptClip.Response> => {
  return http.get(`/transcripts/${transcriptId}/clip?${qs.stringify(params)}`);
};

export const updateTranscriptRangeTags = ({ transcriptId, ...params }: Transcripts.UpdateTranscriptRangeTags.Request): Promise<Transcripts.UpdateTranscriptRangeTags.Response> => {
  return http.post(`/transcripts/${transcriptId}/tags/range`, params);
};

export const getTranscriptTaggedMomentDetails = ({ transcriptId, ...params }: Transcripts.GetTaggedMomentDetails.Request): Promise<Transcripts.GetTaggedMomentDetails.Response> => {
  return http.get(`/transcripts/${transcriptId}/tags/moment-details?${qs.stringify(params)}`);
};