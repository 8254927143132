import { useContext } from 'react';
import { createNamedContext } from '@utils';
import { FormContextValue } from '@/components/Form/interfaces';
import { LeadFormData } from '$website/types';

type ContextValue = {
  isSubmitted: boolean;
} & FormContextValue<LeadFormData>;

export const LeadFormContext = createNamedContext<ContextValue>(undefined, 'LeadFormContext');

export const useLeadFormContext = () => useContext(LeadFormContext);