import { useContext, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import * as consts from '@consts';
import { CompanyOverviewContext } from '@containers/Company/Context';
import ActivityIndicator from '@/components/ActivityIndicator';

type Props = unknown;

export function Overview(props: Props) {
  const { query, ready } = useContext(CompanyOverviewContext);

  if (!ready) return <ActivityIndicator show />;

  if (query.data.meta.hasCurrentEmployees) {
    return <Redirect to={slugify(query.data.item.slug, consts.path.Companies.CurrentEmployees.Root)} />;
  }

  if (query.data.meta.hasFormerEmployees) {
    return <Redirect to={slugify(query.data.item.slug, consts.path.Companies.FormerEmployees.Root)} />;
  }

  return <Redirect to={consts.pathname.Home} />;
}

function slugify(slug: string, path: string) {
  return path.replace(':slug', slug);
}