import { useContext, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as QK from '@consts/querykey.admin';
import { CallPayoutOverrideType } from '@enums';
import * as api from '$admin/api';
import type * as API from '$admin/api/interfaces';
import * as $conference from '$admin/containers/Call/utils';
import { ConfirmContext, ParamsContext, ParticipantsContext, RespondentSetupContext, SettingsContext } from './Context';
import type { RespondentSetup, OnSuccess } from './interfaces';

type Props = {
  children:   React.ReactNode;
  onSuccess?: OnSuccess;
};

export const Confirm = (props: Props) => {
  const ctx = {
    participants: useContext(ParticipantsContext),
    respondent: useContext(RespondentSetupContext),
    scheduling: useContext(ParamsContext),
    settings: useContext(SettingsContext),
  };
  const [confirmation, setConfirmation] = useState<API.Projects.Scheduling.ScheduleCall.Response>(null);

  const mutation = useMutation(QK.Projects.Scheduling.Confirmations.Post({
    callId: ctx.scheduling.params?.callId,
    projectId: ctx.scheduling.params?.projectId,
  }), () => {
    return api.projects.scheduling.scheduleCall({
      callId: ctx.scheduling.params.callId,
      conference: $conference.resolveConferenceSettings(ctx.settings.conference),
      end: ctx.settings.time.end,
      participants: ctx.participants.items,
      payout: resolvePayoutOverride({
        callId: ctx.scheduling.params.callId,
        honorarium: ctx.respondent.form.honorarium,
        payout: ctx.respondent.form.payout,
        userId: ctx.respondent.form.userId,
      }),
      projectId: ctx.scheduling.params.projectId,
      respondentEmail: ctx.respondent.form.email,
      respondentId: ctx.scheduling.params.userId,
      start: ctx.settings.time.start,
      title: ctx.settings.conference.title,
      tags: ctx.settings.tags,
    });
  }, {
    onSuccess: res => {
      setConfirmation(res);

      props.onSuccess?.(res);

      return res;
    },
  });

  const value = {
    confirmation,
    mutation,
  };

  return (
    <ConfirmContext.Provider value={value}>
      {props.children}
    </ConfirmContext.Provider>
  );
};

Confirm.displayName = 'CallScheduling.Confirm';

type ResolvePayoutOverride =
  & Partial<ICallId>
  & Partial<IUserId>
  & Pick<RespondentSetup.Form,
    | 'honorarium'
    | 'payout'>;

function resolvePayoutOverride(data: ResolvePayoutOverride) {
  if (data.callId || !data.userId) return;

  if (!data.payout) {
    return {
      currency: `USD`,
      type: CallPayoutOverrideType.Custom,
      value: 0,
    };
  }

  return {
    currency: data.honorarium.currency,
    type: CallPayoutOverrideType.Custom,
    value: +data.honorarium.value,
  };
}