/** @jsxImportSource @emotion/react */
import { useCallback, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import Skeleton from '@mui/material/Skeleton';
import { CancelAction } from '@/brand-insights/components/presentation';
import type { Chat } from '@/brand-insights/types';
import { ActiveChatSessionContext } from './context';
import { UserMessage } from './Message.User';
import { MessageResponse } from './Message.Response';
import { SystemMessageContainer } from './Message.System.Container';
import { SystemSegmentationMessage } from './Message.System.Segmentation';
import { useCancelSegmentationMutation, useScrollToBottomOfMessages, useSessionSegmentation } from './hooks';

type Props = {
  identifier: Chat.Segmentation.Segmentation['identifier'];
  tagSelection: Chat.Segmentation.TagSelection.ScreenData;
  dimensionSelection: Chat.Segmentation.DimensionSelection.ScreenData;
  data: Chat.Segmentation.Result.ScreenData;
  readonly: boolean;
};

export const SegmentationResult = (props: Props) => {
  const segmentation = useSessionSegmentation({ segmentationIdentifier: props.identifier });
  const citations = useMemo(() => props.data.results.flatMap(r => r.findings.flatMap(f => f.citations)), [props.data.results]);

  return (
    <>
      <MessageContainer>
        <UserMessage value={<SegmentationResultUserMessage {...props} />} />
      </MessageContainer>
      <MessageContainer>
        <SystemMessageContainer citations={citations}>
          <SystemSegmentationMessage
            results={props.data.results}
            citations={citations}
            status={segmentation.status}>
            <SegmentationResultSystemMessage {...props} />
          </SystemSegmentationMessage>
        </SystemMessageContainer>
      </MessageContainer>
    </>
  );
};

export const SegmentationResultUserMessage = (props: Props) => {
  const selectedTags = useMemo(() => props.tagSelection.tags.filter(t => t.selected), [props.tagSelection.tags]);

  return (
    <>
      <UserHeaderCopy>
        {`Yes, let's analyze`} {selectedTags.map((t, i) => (
          <span key={t.tag.id}>
            <b>{t.tag.name}</b>{i !== selectedTags.length - 1 ? `, ` : null}
          </span>
        ))} {`tagged data on the following dimensions:`}
      </UserHeaderCopy>
      <Dimensions css={{ marginBottom: 0 }}>
        {props.dimensionSelection.dimensions.map((dimension, idx) => (
          <DimensionItem key={`${idx}`}>
            {dimension}
          </DimensionItem>
        ))}
      </Dimensions>

    </>
  );
};

export const SegmentationResultSystemMessage = (props: Props) => {
  const chat = useContext(ActiveChatSessionContext);
  const segmentation = useSessionSegmentation({ segmentationIdentifier: props.identifier });
  const cancel = useCancelSegmentationMutation();
  const scrollToBottom = useScrollToBottomOfMessages();

  const handleCancel = useCallback(() => {
    cancel.mutateAsync({
      chatInstanceIdentifier: chat.identifier,
      context: chat.context,
      segmentation,
    })
    .then(() => scrollToBottom());
  }, [
    cancel,
    chat.identifier,
    chat.context,
    segmentation,
    scrollToBottom,
  ]);

  const view = {
    actions: segmentation.status === 'pending' && !props.readonly,
  };

  return (
    <Root>
      <SystemHeaderCopy>
        {copy.system.header}
      </SystemHeaderCopy>
      <Results>
        {props.data.results.map(r => (
          <Result key={r.identifier}>
            <ResultLabel>{r.title}</ResultLabel>
            <Findings>
              {r.findings.map(f => (
                <Finding key={f.identifier}>
                  {f.status === 'completed'
                    ? (
                      <>
                        <FindingLabel>{f.label}</FindingLabel>
                        <MessageResponse item={f.response} />
                      </>
                    )
                    : <StyledLoader animation={segmentation.status === 'cancelled' ? false : undefined} variant="text" />}
                </Finding>
              ))}
            </Findings>
          </Result>
        ))}
      </Results>
      {view.actions && (
        <Actions>
          <CancelAction
            displayValue={copy.cancel}
            onClick={handleCancel} />
        </Actions>
      )}
    </Root>
  );
};

export const copy = {
  user: {
    header: `I'd like to analyze the following topics:`,
  },
  system: {
    header: `Ok, this will take a few moments! You can wait to view the results, or if you navigate away, you'll receive an e-mail with a link back to the analysis once it's complete!`,
  },
  cancel: `Actually, I'd like to do something else.`,
};

const MessageContainer = styled.div`
  padding: 15px 20px;
`;

const Root = styled.div`
  box-sizing: border-box;
`;

const UserHeaderCopy = styled.div`
  box-sizing: border-box;
  margin-bottom: 10px;
`;

const SystemHeaderCopy = styled.div`
  box-sizing: border-box;
  border-bottom: 1px solid ${props => props.theme.palette.gray.light1};
  padding-bottom: 10px;
  margin-bottom: 10px;
`;

const Dimensions = styled.ul`
  padding-inline-start: 1em;
  margin: 0 0 20px 0;

  li + li {
    margin-top: 10px;
  }
`;

const DimensionItem = styled.li`
`;

const Results = styled.div`
`;

const Result = styled.div`
  :not(:last-child) {
    margin-bottom: 20px; 
  }
`;

const ResultLabel = styled.div`
  font-family: ${props => props.theme.fonts.bold};
  margin-bottom: 10px;
`;

const StyledLoader = styled(Skeleton)`
  background-color: ${props => props.theme.palette.green.light3};
  height: 40px;
  width: 65%;
`;

const Findings = styled.div`
  box-sizing: border-box;
`;

const Finding = styled.div`
  :not(:last-child) {
    margin-bottom: 20px; 
  }
`;

const FindingLabel = styled.div`
  font-family: ${props => props.theme.fonts.semiBold};
  margin-bottom: 10px;
`;

const Actions = styled.div`
  margin-top: 20px;

  > :not(:last-child) {
    margin-bottom: 10px;
  }
`;