import { useEffect, useState } from 'react';
import { cx } from '@utils';
import styles from '@/components/ContentFeed/style/Feed.css';

export const useTransitionClasses = () => {
  const [ready, setReady] = useState(false);

  useEffect(() => {

    if (!ready) {
      setReady(true);
    }

  }, [ready]);

  return cx(styles.item, {
    [styles.in]: ready,
  });
};