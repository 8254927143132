import { useState } from 'react';
import { cx } from '@utils';
import bg1 from 'static/images/website/collaboration-tools/transcripts-comments-s.png';
import bg2 from 'static/images/website/collaboration-tools/transcripts-comments-m.png';
import bg3 from 'static/images/website/collaboration-tools/transcripts-comments-l.png';
import styles from './style/Transcription.css';

type Props = unknown;

export const Transcription = (props: Props) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.content}>
          <div className={styles.main}>
            <div className={styles.graphic}>
              <picture>
                <source
                  media="(min-width: 1100px)"
                  srcSet={bg3} />
                <source
                  media="(min-width: 800px)"
                  srcSet={bg2} />
                <img
                  alt="Highlight and comment on interview transcripts"
                  className={cx(styles.img, { [styles.ready]: loaded })}
                  onLoad={() => setLoaded(true)}
                  src={bg1} />
              </picture>
            </div>

            <div className={styles.info}>
              <h2 className={styles.title}>Highlight and comment on interview transcripts to surface insights for your team</h2>
              <div className={styles.subtitle}>Surface key takeaways of your research directly in Sentiment and assign followup actions to team experts.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Transcription.displayName = 'CollaborationTools.Transcription';