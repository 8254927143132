import { AdminExternalSurveyConfigurationBootstrapContainer } from './Bootstrap.Container';
import { AdminOffPlatformSurveyMutationContainer } from './Mutations.Container';

type Props =
  ChildrenProps &
  IProjectId;

export const AdminExternalSurveyConfigurationContainer = ({ projectId, ...props }: Props) => {

  return (
    <AdminExternalSurveyConfigurationBootstrapContainer projectId={projectId}>
      <AdminOffPlatformSurveyMutationContainer projectId={projectId}>
        {props.children}
      </AdminOffPlatformSurveyMutationContainer>
    </AdminExternalSurveyConfigurationBootstrapContainer>
  );
};