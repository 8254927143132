import type { ChangeEventHandler } from 'react';
import { memo } from 'react';
import styles from './style.css';

type Props = {
  accept?: string;
  className?: string;
  multiple?: boolean;
  onSelect: ChangeEventHandler<HTMLInputElement>;
};

export const FilePicker = memo(({ accept, className, multiple, onSelect }: Props) => {

  return (
    <div className={className}>
      <div className={styles.input}>
        <input
          type='file'
          accept={accept}
          multiple={multiple}
          onChange={onSelect} />
        <span className={styles.span}>Choose File</span>
      </div>
    </div>
  );
});