import { Inbox } from 'react-feather';
import styles from './style/Invitations.Placeholder.css';

export const InvitationsPlaceholder = () => {
  return (
    <div className={styles.root}>
      <Inbox
        className={styles.inbox}
        size={120} />
      <div className={styles.title}>Stay tuned for project invitations!</div>
      <div className={styles.text}>You will be notified as soon as you are selected for a project.</div>
    </div>
  );
};

export default InvitationsPlaceholder;