import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { TranscriptSocketEvent } from '@enums';
import { useFetchTranscriptSummary } from '@utils/api';
import * as api from '@api';
import * as $ws from '@websocket';
import { useTranscriptsSocketEvent } from '@websocket/hooks';
import { RefreshSummariesContext } from './context';

type Props =
  ITranscriptId &
  ChildrenProps;

type Key = {
  transcriptId: number;
  tagId: number;
};

export const TranscriptSummaryRefreshContainer = (props: Props) => {

  const [websocketKey, setWebsocketKey] = useState<Key>();

  const {
    isLoading: isGenerating,
    mutateAsync: refresh,
  } = useMutation(['post:transcripts/conference-tags/summaries/generate', {
    transcriptId: props.transcriptId,
  }], (tagId?: number) => {
    return api.transcripts.generateTagSummaries({
      transcriptId: props.transcriptId,
      tagIds: tagId ? [tagId] : undefined,
    });
  }, {
    onMutate: (tagId?: number) => {
      setWebsocketKey({
        transcriptId: props.transcriptId,
        tagId,
      });
    },
    onError: () => {
      setWebsocketKey(null);
    },
  });

  const { refetch: refreshTagSummaries, isLoading: isLoadingSummaries } = useFetchTranscriptSummary({
    transcriptId: props.transcriptId,
  }, {
    enabled: false,
  });

  const handleTagSummariesAvailable = useCallback(() => {
    refreshTagSummaries();
    setWebsocketKey(null);
  }, [refreshTagSummaries]);

  useTranscriptsSocketEvent(TranscriptSocketEvent.TagSummariesAvailable, handleTagSummariesAvailable, !!websocketKey);

  useEffect(() => {
    const key = websocketKey;
    if (key) {
      $ws.transcripts.subscribe(key.transcriptId);
      $ws.transcripts.open();

      return () => {
        $ws.transcripts.unsubscribe(key.transcriptId);
        $ws.transcripts.close();
      };
    }
  }, [websocketKey]);

  const isRefreshing = useMemo(() => {
    return isGenerating || isLoadingSummaries || !!websocketKey;
  }, [
    isGenerating,
    isLoadingSummaries,
    websocketKey,
  ]);

  const ctx = useMemo(() => ({
    isRefreshing,
    refresh,
    tagRefreshing: websocketKey?.tagId,
  }), [
    isRefreshing,
    refresh,
    websocketKey,
  ]);

  return (
    <RefreshSummariesContext.Provider value={ctx}>
      {props.children}
    </RefreshSummariesContext.Provider>
  );
};