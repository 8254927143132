import type { QueryParamConfig } from 'use-query-params';
import { encodeArray, decodeArray,
  encodeString, decodeString, useQueryParams, withDefault,
} from 'use-query-params';
import type { GroupTag, WorkspaceSearchFull } from '@/types';

const TabParam: QueryParamConfig<WorkspaceSearchFull.SearchTab> = {
  encode: (tab: WorkspaceSearchFull.SearchTab) => encodeString(tab),
  decode: (tab: string) => decodeString(tab) as WorkspaceSearchFull.SearchTab,
};

const KeywordsParam: QueryParamConfig<string[]> = {
  encode: (keywords: string[]) => encodeArray(keywords),
  decode: (keywords: string | string[]) => {
    if (!keywords) return [];
    return decodeArray(keywords);
  },
};

const TagsParam: QueryParamConfig<GroupTag[]> = {
  encode: (tags: GroupTag[]) => encodeURIComponent(JSON.stringify(tags || [])),
  decode: (tags: string) => {
    if (!tags) return [];
    return JSON.parse(decodeURIComponent(tags)) as GroupTag[];
  },
};

const FileTypesParam: QueryParamConfig<WorkspaceSearchFull.File.FileTypes[]> = {
  encode: (filetypes: WorkspaceSearchFull.File.FileTypes[]) => encodeArray(filetypes),
  decode: (filetypes: string | string[]) => {
    if (!filetypes) return [];
    return decodeArray(filetypes) as WorkspaceSearchFull.File.FileTypes[];
  },
};

export default function useSearchFiltersQuery() {
  const [query, setQuery] = useQueryParams({
    keywords: KeywordsParam,
    tags: TagsParam,
    filetypes: FileTypesParam,
    tab: withDefault(TabParam, 'all'),
  });

  return [query, setQuery] as const;
}

export type FiltersQuery = ReturnType<typeof useSearchFiltersQuery>[0];

export { useSearchFiltersQuery };