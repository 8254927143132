import {
  GrayOutlineButton,
  GreenOutlineButton,
  type OutlinedButtonProps,
  RedOutlineButton,
} from '@presentation/Buttons';
import { cx } from '@utils';
import styles from './style/Buttons.css';

type Props =
  OutlinedButtonProps;

export const AcceptResponseButton = (props: Props) => {
  return (
    <GreenOutlineButton {...props}>
      Approve
    </GreenOutlineButton>
  );
};

export const RejectResponseButton = (props: Props) => {
  return (
    <RedOutlineButton {...props}>
      Reject
    </RedOutlineButton>
  );
};

export const HideAnswerButton = ({
  className,
  ...props
}: Props) => (
  <RedOutlineButton
    className={cx(className, styles.flag)}
    {...props}>
    Hide
  </RedOutlineButton>
);

export const ShowAnswerButton = ({
  className,
  ...props
}: Props) => (
  <GrayOutlineButton
    className={cx(className, styles.flag)}
    {...props}>
    Show
  </GrayOutlineButton>
);

export const ApproveResponseButton = (props: Props) => {
  return (
    <GreenOutlineButton {...props}>
      Approve
    </GreenOutlineButton>
  );
};

export const FlagResponseButton = (props: Props) => {
  return (
    <RedOutlineButton {...props}>
      Flag
    </RedOutlineButton>
  );
};

export const UndoApprovalButton = (props: Props) => {
  return (
    <GrayOutlineButton {...props}>
      Undo
    </GrayOutlineButton>
  );
};

export const ResetDisqualificationButton = (props: Props) => {
  return (
    <GrayOutlineButton {...props}>
      Reset
    </GrayOutlineButton>
  );
};