import type {
  CallRole, ConferenceMediaType, ConferencePlatform,
  ConferenceType, ConferenceFeatureShareType, ConferenceTagType,
} from '@enums';
import type * as Remirror from './rich-text';

export * from './conferences.tags';

export type ConferenceStatus = 'initial' | 'negotiating' | 'pre-room' | 'waiting-to-open' | 'waiting-room' | 'meeting-room' | 'left';

export type ConferenceDialNumber = {
  countryCode: string;
  displayOrder: number;
  locality: string;
  phone: string;
  tollFree: boolean;
};

export type ConferenceDialInfo = {
  phoneNumbers: ConferenceDialNumber[];
  pin: string;
  guestPin?: string;
  guestPins: Record<CallRole, string>;
};

export type ConferenceParticipant = {
  active: string[];
  email: string;
  internal?: boolean;
  name: string;
  offPlatform: boolean;
  organizer: boolean;
  pin: string;
  roleId: CallRole;
  userId: number;
  visibility: boolean;
  verified?: boolean;
};

export type ConferenceSession<T = Date> = {
  endedOn: T;
  startedOn: T;
};

export type ConferenceValidity<T = Date> = {
  end: T;
  start: T;
};

export type WebConferenceSettings = {
  chat: boolean;
};

export type BaseConference = {
  conferenceIdentifier: number;
  id: number;
} & ICallId;

export type ConferenceRecord = BaseConference & ConferenceSession;

export type Conference<T = Date> = {
  dial: ConferenceDialInfo;
  participants: ConferenceParticipant[];
  record: boolean;
  session: ConferenceSession<T>;
  settings: WebConferenceSettings;
  mediaTypeId: ConferenceMediaType;
  validity: ConferenceValidity<T>;
} & BaseConference;

export type ConferenceIdentityMode = 'phone' | 'web' | 'web-hidden';

export type WaveformData = number[];

export type ConferenceSettings = {
  clientNotes: Remirror.RootNode;
  conferenceLink: string;
  conferenceDetails: Remirror.RootNode;
  conferenceDialIn: string;
  conferenceMediaType: ConferenceMediaType;
  conferencePlatform: ConferencePlatform;
  conferenceType: ConferenceType;
  defaultDuration: number;
  languages: Descriptor[];
  videoShareTypeId: ConferenceFeatureShareType;
  screenShareTypeId: ConferenceFeatureShareType;
  transcriptionQa: boolean;
};

export type ConferenceTag = {
  id: number;
  typeId: ConferenceTagType;
  createdBy: number;
  name: string;
  color: string;
};

export type TaggedConferenceMoment = {
  id: number;
  conferenceId: number;
  tagId: number;
  tagStart: Date;
  tagEnd: Date;
};

export enum ConferenceLogType {
  ParticipantJoined = 1,
  ParticipantLeft = 2,
  Error = 3,
  Info = 4,
}

type ConferenceLogContextMap = {
  [ConferenceLogType.ParticipantLeft]: { reason: string };
  [ConferenceLogType.Error]: { message: string };
  [ConferenceLogType.Info]: { message: string };
};

export type ConferenceLogContext<T extends ConferenceLogType = ConferenceLogType> = T extends keyof ConferenceLogContextMap ? ConferenceLogContextMap[T] : unknown;

export type ConferenceLogRecord<T extends ConferenceLogType = ConferenceLogType> = {
  id: number;
  typeId: T;
  logTime: Date;
  loggedBy: string;
  context: ConferenceLogContext<T>;
};

export namespace TwilioVideo {
  export type RoomInstance = {
    id: number;
    sid: string;
    startedOn: Date;
    endedOn: Date;
  };
}