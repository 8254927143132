import { InfoTooltip } from '@presentation/InfoTooltip';
import { cx } from '@utils';
import * as Layout from './Layout';
import styles from './style/Overview.css';

type Props = {
  className?: string;
  children:   React.ReactNode;
  label:      string;
  tooltip?:   React.ReactNode;
  alert?:      string;
  classes?: {
    root?: string;
    alert?: string;
  };
  tooltipStyle?: Record<string, unknown>;
};

export const Field = ({ classes = {}, tooltipStyle = {}, ...props }: Props) => {
  const classnames = {
    root: cx(styles.field, classes.root, props.className),
    alert: cx(styles.alert, classes.alert),
  };

  return (
    <Layout.Row className={classnames.root}>
      <div className={styles.header}>
        <div className={styles.label}>
          {props.label}
          {props.alert && <span className={classnames.alert}>{props.alert}</span>}
        </div>
        {props.tooltip &&
          <InfoTooltip
            className={styles.tooltip}
            text={props.tooltip}
            tooltipStyle={tooltipStyle} />}
      </div>
      {props.children}
    </Layout.Row>
  );
};

Field.displayName = 'Overview.Field';