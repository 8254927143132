import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import type { Column, CellProps } from 'react-table';
import { useTable, useFlexLayout, useSortBy, usePagination } from 'react-table';
import type { UseQueryResult } from '@tanstack/react-query';
import { format } from 'date-fns';
import * as consts from '@consts';
import type { GetBrands } from '@api/interfaces/workspace.brand';
import { Brand } from '@/components/icons';
import { Card, CardContent, Divider, GroupCardHeader } from '@presentation/Main';
import { useIsInternalUser } from '@/containers/Store';
import * as Table from '@/components/Table';
import { getLocationFor } from '@/utils';
import { BrandsTable } from './BrandsTable';
import styles from './style/ActiveProjectsPanel.css';

const MaxProjects = 5;

type Props = {
  query: UseQueryResult<GetBrands.Response>;
};

export const ActiveBrandsPanel = (props: Props) => {
  const data = useMemo(() => {
    if (props.query.isInitialLoading) {
      return Array.from({ length: 4 }, _ => ({} as GetBrands.BrandItem));
    } else {
      return props.query.data.brands.slice(0, 5);
    }
  }, [props.query.data?.brands, props.query.isInitialLoading]);

  const showMore = useMemo(() => props.query.data?.brands?.length > MaxProjects, [props.query.data?.brands?.length]);

  return (
    <Card>
      <GroupCardHeader className={styles.header}>
        <div>Brands</div>
      </GroupCardHeader>
      <CardContent fluid>
        <BrandsTable
          items={data}
          isEmpty={data.length === 0}
          isLoading={props.query.isInitialLoading} />
        {showMore &&
          <Table.Layout.Footer>
            <div className={styles.footer}>
              <Link to={consts.path.Brands.Root}>View More...</Link>
            </div>
          </Table.Layout.Footer>}
      </CardContent>
    </Card>
  );
};

export default ActiveBrandsPanel;