import { useCallback, useMemo } from 'react';
import { ChevronLeft, Plus, Minus, Settings, Check, ExternalLink, ArrowRight } from 'react-feather';
import { generatePath } from 'react-router';
import { cx } from '@utils';
import * as consts from '@consts';
import type { SurveyFormPreview } from '@/types';
import { SurveyItemType } from '@/enums';
import { SurveyQuestionNumber, MessageNumber, AIENumber, ActivityBlocker } from '@/presentation';
import Toast from '@/components/Toast';
import { NumberInput } from '@/components/Input';
import { groupBy } from '@/utils/array';
import { Accordion } from '@/components/Accordion';
import { useLeftBarContext, useLeftBarActionsContext, useLeftBarWindowContext } from './context';
import styles from './style/LeftBar.css';

export const LeftBar = () => {
  const { open } = useLeftBarContext();
  const { window: childWindow, thisRole } = useLeftBarWindowContext();

  if (childWindow && thisRole === 'parent') return null;

  return open ? <Open /> : <Collapsed />;
};

const Open = () => {
  const { setOpen, state, callId } = useLeftBarContext();
  const { onItemClick } = useLeftBarActionsContext();
  const { window: childWindow, thisRole, setWindow } = useLeftBarWindowContext();

  const popoutBar = useCallback(() => {
    const path = generatePath(consts.path.Conference.CaptureSheetPopout, { callId, surveyId: 1 });
    const newWindow = window.open(path, '_blank', `height=${screen.height},width=300`);

    if (!newWindow) {
      Toast.error({
        title: 'Could not open a new window. Check if your browser is blocking popups',
      });
    } else {
      newWindow.opener = window;
      setWindow(newWindow);

    }
  }, [callId, setWindow]);

  const isLoading = state.loading;

  const showBarControls = thisRole === 'parent' && !childWindow;

  const sections = useMemo(() => {
    const sectionGroups = groupBy(state.surveyItems, k => k.section.identifier);

    return Object.entries(sectionGroups).map(([sectionIdentifier, items]) => {
      const section = state.surveySections.find(s => s.identifier === sectionIdentifier);
      return {
        section,
        name: section ? (section.name ?? `Section ${section.ordinal}`) : `Unknown Section`,
        items,
      };
    });
  }, [state.surveyItems, state.surveySections]);

  return (
    <>
      <div className={styles.root}>
        <div className={cx(styles.section, styles.settingsSection)}>
          <div className={styles.title}>
            Settings
            {showBarControls &&
              <ExternalLink
                className={styles.popout}
                size={16}
                onClick={popoutBar} />}
          </div>
          {showBarControls &&
            <>
              <ChevronLeft className={styles.toggleCollapsed} onClick={() => setOpen(false)} />
            </>
          }
        </div>
        <ZoomSection />
        <div className={cx(styles.section, styles.itemsSection)}>
          <div className={styles.title}>
            Sections
          </div>
          <div className={styles.items}>
            {sections.map(group => (
              <Accordion
                label={group.name}
                key={group.name}
                showCount={false}
                grows={false}>
                {group.items.map(item => (
                  <div
                    className={cx(styles.item, item.id === state.activeItemId && styles.active)}
                    key={item.id}

                    onClick={item.id !== state.activeItemId ? () => onItemClick(item.id) : () => { }}>
                    <div className={styles.itemNumber}>
                      <ItemNumber item={item} />
                    </div>
                    <div className={styles.itemValue} style={item.itemColor ? { backgroundColor: item.itemColor } : null}>
                      {item.value}
                    </div>
                    <ItemIcon item={item} />
                  </div>
                ))}
              </Accordion>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.spacer} />
      {isLoading && <ActivityBlocker />}
    </>
  );
};

type ItemProps = {
  item: SurveyFormPreview['items'][0];
};

const ItemNumber = ({ item }: ItemProps) => {
  switch (item.type) {
    case SurveyItemType.Question:
      return <SurveyQuestionNumber ordinal={item.itemOrdinal} />;
    case SurveyItemType.Message:
      return <MessageNumber />;
    case SurveyItemType.AlternateImageExercise:
      return <AIENumber />;
    default:
      throw new UnreachableCaseError(item.type);
  }
};

const ItemIcon = ({ item }: ItemProps) => {
  const { state } = useLeftBarContext();

  const iconProps = { size: 16, strokeWidth: 4 };

  if (state.activeItemId === item.id) {
    return (
      <div className={styles.activeIcon}>
        <ArrowRight color='white' {...iconProps} />
      </div>
    );
  } else if (state.completedItemIds.includes(item.id)) {
    return (
      <div className={styles.completedIcon}>
        <Check color='white' {...iconProps} />
      </div>
    );
  } else {
    return (
      <div className={styles.emptyIcon} />
    );
  }
};

const ZoomSection = () => {
  const { zoomValue, setZoomValue } = useLeftBarContext();

  const incrementZoomValue = useCallback((value: number) => {
    setZoomValue(zoomValue + value);
  }, [setZoomValue, zoomValue]);

  return (
    <div className={cx(styles.section, styles.zoomSection)}>
      <div className={styles.title}>Zoom</div>
      <div className={styles.zoomRow}>
        <NumberInput
          classes={{
            root: styles.zoomInputRoot,
          }}
          value={zoomValue}
          onValueChange={e => setZoomValue(e.floatValue)}
          focusableClasses={{
            root: styles.focusable,
          }}
          suffix='%' />
        <div className={styles.zoomBtn} onClick={() => incrementZoomValue(-10)}>
          <Minus />
        </div>
        <div className={styles.zoomBtn} onClick={() => incrementZoomValue(10)}>
          <Plus />
        </div>
      </div>
    </div>
  );
};

export const Collapsed = () => {
  const { setOpen } = useLeftBarContext();

  return (
    <div className={styles.collapsed}>
      <Settings className={styles.toggleCollapsed} onClick={() => setOpen(true)} />
    </div>
  );
};