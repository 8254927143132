import { createNamedContext } from '@utils';

export type TreeViewContextValue = {
  // isDisabled: (nodeId: number) => boolean;
  // isExpandable: (nodeId: number) => boolean;
  isExpanded: (nodeId: number) => boolean;
  isSelected: (nodeId: number) => boolean;

  selectNode: (nodeId: number) => unknown;
  toggleExpansion: (nodeId: number) => unknown;

};

export type TreeItemContextValue = {
  disabled: boolean;
  expandable: boolean;
  expanded: boolean;
  selected: boolean;

  // toggleExpanded: () => unknown;
  // toggleSelected: () => unknown;
};

export const TreeViewContext = createNamedContext<TreeViewContextValue>(undefined, 'tree-view');
export const TreeItemContext = createNamedContext<TreeItemContextValue>(undefined, 'tree-item');