import { useMutation } from '@tanstack/react-query';
import { goBack } from '@api/surveys.capture-sheet';
import { Surveys } from '@api/interfaces/surveys';

type Props = ICallId & ISurveyId;

export const useGoBackMutation = ({ surveyId, callId }: Props) => {
  return useMutation(['capture-sheet-go-back', surveyId, callId], (data: Surveys.CaptureSheets.GoBack.Request['data']) => {
    return goBack({
      data,
      callId,
    });
  });
};