import { useCallback } from 'react';
import { WorkspaceObjectType } from '@enums';
import * as api from '@api';
import Toast from '@/components/Toast';

type MoveFolderParams = {
  parentObjectId: number;
} & IWorkspaceId
  & IWorkspaceObjectId;

export const useMoveObject = (objectType: WorkspaceObjectType) => {

  const moveObject = useCallback((params: MoveFolderParams) => {
    return api.workspaces.object.moveObject({
      objectId: params.objectId,
      parentObjectId: params.parentObjectId,
      workspaceId: params.workspaceId,
    }).then(data => {
      const typeMap: Record<WorkspaceObjectType, string> = {
        [WorkspaceObjectType.File]: 'File',
        [WorkspaceObjectType.Folder]: 'Folder',
        [WorkspaceObjectType.ProjectParent]: 'Project',
        [WorkspaceObjectType.CallTranscript]: 'Transcript',
        [WorkspaceObjectType.Workspace]: 'Workspace',
        [WorkspaceObjectType.Brand]: 'Brand',
      };
      Toast.alert({
        title: `${typeMap[objectType]} Moved`,
      });
      return data;
    }).catch(e => {
      Toast.error({
        title: 'Error',
        body: `We're sorry, there was an issue with your request`,
      });
      throw e;
    });

  }, [objectType]);

  return moveObject;
};

export default useMoveObject;