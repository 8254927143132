import { useReducer } from 'react';
import cuid from 'cuid';
import { TextStateContext } from './Context';
import * as PostCreation from './interfaces';

type Props = {
  children: React.ReactNode;
};

export const StateContainer = (props: Props) => {
  const state = useReducer(reducer, initialState);

  return (
    <TextStateContext.Provider value={state}>
      {props.children}
    </TextStateContext.Provider>
  );
};

StateContainer.displayName = 'PostCreation.Post.Text.StateContainer';

function reducer(state: PostCreation.Text.State, action: PostCreation.Text.Action) {
  switch (action.type) {
    case 'update-body':
      return {
        form: {
          ...state.form,
          body: action.value,
        },
      };
  }
}

const initialBody = [
  {
    type: 'paragraph',
    children: [
      {
        text: '',
      },
    ],
  },
];

const initialState: PostCreation.Text.State = {
  form: {
    identifier: cuid(),
    body: { value: initialBody },
    media: null,
  },
};