import { useMemo } from 'react';
import { useSurveyBuilderState, useSurveyBuilderItem } from '@containers/SurveyBuilder';
import { QuestionBuilderItemContext } from './Context';
import { QuestionBuilderDeleteContainer } from './Question.Delete.Container';

type Props =
  ChildrenProps;

export const SurveyQuestionContainer = (props: Props) => {

  const [state] = useSurveyBuilderState();
  const item = useSurveyBuilderItem();

  const question = useMemo(() => {
    return state.survey.questions
      .find(f => f.base.identifier === item.source.identifier);

  }, [
    item.source.identifier,
    state.survey.questions,
  ]);

  return (
    <QuestionBuilderItemContext.Provider value={question}>
      <QuestionBuilderDeleteContainer>
        {props.children}
      </QuestionBuilderDeleteContainer>
    </QuestionBuilderItemContext.Provider>
  );
};