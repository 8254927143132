import { AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as api from '@api';
import { Topics } from '@api/interfaces';

type Data = Topics.FollowTopic.Response;
type Vars = {
  follow: boolean;
}

export default function useFollowTopic(
  slug: string,
  options?: UseMutationOptions<Data, AxiosResponse, Vars>
) {

  return useMutation(['follow-topic', slug], ({ follow }) => {
    if (follow) {
      return api.topics.followTopic({ slug });
    } else {
      return api.topics.unfollowTopic({ slug });
    }
  }, options);
}

export { useFollowTopic };