import { useRef, useCallback, useEffect } from 'react';
import { ChevronRight, ChevronLeft } from 'react-feather';
import { Carousel } from 'react-responsive-carousel';
import carouselStyles from 'react-responsive-carousel/lib/styles/carousel.css';
import { useSurveyFormAIEItem } from '@/containers/SurveyForm/hooks/useSurveyFormAIEItem';
import { useAIENavigation } from '@containers/SurveyForm/Context';
import { cx, useUseableCSS } from '@utils';
import Navigation from './Navigation';
import styles from './style.css';

export const AlternateImageExerciseScreen = () => {
  const { setSlideIndex, slideIndex } = useAIENavigation();
  const item = useSurveyFormAIEItem();
  useUseableCSS(carouselStyles);

  const containerRef = useRef<HTMLDivElement>(null);

  const resetCarouselWidth = useCallback(() => {
    const activeImage = containerRef.current.querySelector('.slide.selected img');

    const width = activeImage?.getBoundingClientRect()?.width;

    if (width) {
      containerRef.current.style.width = `${width}px`;
    } else {
      containerRef.current.style.width = 'auto';
    }
  }, []);

  const onSlideChange = useCallback((index: number) => {
    setSlideIndex(index);
    //resetCarouselWidth();
  }, [setSlideIndex]);

  useEffect(() => {
    resetCarouselWidth();
  }, [resetCarouselWidth, slideIndex]);

  return (
    <div className={cx(styles.root, styles.messageRoot)}>
      <div className={styles.aieContent} ref={containerRef}>
        <Carousel
          className={styles.carousel}
          axis='horizontal'
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          infiniteLoop={false}
          autoPlay={!!item.content.exercise.settings.delaySeconds}
          interval={item.content.exercise.settings.delaySeconds * 1000}
          showArrows={!item.content.exercise.settings.delaySeconds}
          dynamicHeight={true}
          stopOnHover={false}
          renderArrowNext={renderArrowNext}
          renderArrowPrev={renderArrowPrev}
          onChange={onSlideChange}>
          {item.content.exercise.entries.map((entry, index) => (
            <div key={index} className={styles.imgContainer}>
              <img
                onLoad={resetCarouselWidth}
                className={styles.aieImage}
                src={entry.imageUrl} />
            </div>
          ))}
        </Carousel>
      </div>
      <div className={styles.footer}>
        <Navigation />
      </div>
    </div>
  );
};

type ClickHandler = () => void;
const ArrowSize = 36;

const renderArrowNext = (clickHandler: ClickHandler, hasNext: boolean) => {
  if (!hasNext) return null;
  return (
    <div className={styles.carouselAction} onClick={clickHandler}>
      <ChevronRight size={ArrowSize} />
    </div>
  );
};

const renderArrowPrev = (clickHandler: ClickHandler, hasPrev: boolean) => {
  if (!hasPrev) return null;
  return (
    <div className={styles.carouselAction} onClick={clickHandler}>
      <ChevronLeft size={ArrowSize} />
    </div>
  );
};

export default AlternateImageExerciseScreen;