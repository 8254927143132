import { useContext } from 'react';
import { TermFilterContext } from '@containers/Group.Project.Terms';

type Props = {
  termValue: string;
};

export const useIsTermActive = (props: Props) => {
  const { highlightTerm } = useContext(TermFilterContext);

  return highlightTerm === props?.termValue;
};