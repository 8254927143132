import styled from '@emotion/styled';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CloseIcon from '@mui/icons-material/Close';
import { shouldForwardProp } from '@/brand-insights/utils/emotion';
import type { Theme } from '@/brand-insights/types/theme';

type WrapProps = {
  isFullPageChat: boolean;
  isDisabled: boolean;
};

type IconProps = {
  isDisabled: boolean;
};

export const Root = styled.button`
  display: block;
`;

export const Wrap = styled('div', { shouldForwardProp: shouldForwardProp('isFullPageChat', 'isDisabled') })<WrapProps>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  color: ${props => props.theme.palette.text.primary};
  cursor: ${props => props.isDisabled ? 'default' : 'pointer'};
  border-radius: 2px;
  font-size: ${props => props.isFullPageChat ? '15px' : '13px'};
  font-family: ${props => props.theme.fonts.regular};
  padding: 6px 8px;
  transition: background-color 0.2s;
  text-align: left;
`;

export const TextStyledIcon = styled(ArrowForwardIcon, { shouldForwardProp: shouldForwardProp('isDisabled') })<IconProps>`
  height: 16px;
  width: 16px;
  font-size: 18px;
  margin-right: 4px;
`;

export const AnalysisStyledIcon = styled(AutoAwesomeIcon, { shouldForwardProp: shouldForwardProp('isDisabled') })<IconProps>`
  height: 16px;
  width: 16px;
  font-size: 18px;
  margin-right: 4px;
`;

export const CancelStyledIcon = styled(CloseIcon, { shouldForwardProp: shouldForwardProp('isDisabled') })<IconProps>`
  height: 16px;
  width: 16px;
  font-size: 18px;
  margin-right: 4px;
`;

export const Colors = (theme: Theme.Theme) => ({
  Text: {
    enabled: {
      backgroundColor: theme.palette.gray.light5,
      hover: {
        backgroundColor: theme.palette.gray.light6,
      },
    },
    disabled: {
      backgroundColor: theme.palette.gray.light2,
      hover: {
        backgroundColor: theme.palette.gray.light2,
      },
    },
  },
  TextIcon: {
    enabled: {
      color: theme.palette.purple.light8,
    },
    disabled: {
      color: theme.palette.gray.main,
    },
  },
  Analysis: {
    enabled: {
      backgroundColor: theme.palette.green.light3,
      hover: {
        backgroundColor: theme.palette.green.light2,
      },
    },
    disabled: {
      backgroundColor: theme.palette.gray.light2,
      hover: {
        backgroundColor: theme.palette.gray.light2,
      },
    },
  },
  AnalysisIcon: {
    enabled: {
      color: theme.palette.green.dark2,
    },
    disabled: {
      color: theme.palette.gray.main,
    },
  },
  Cancel: {
    enabled: {
      backgroundColor: theme.palette.gray.light1,
      hover: {
        backgroundColor: theme.palette.gray.light1,
      },
    },
    disabled: {
      backgroundColor: theme.palette.gray.light2,
      hover: {
        backgroundColor: theme.palette.gray.light2,
      },
    },
  },
  CancelIcon: {
    enabled: {
      color: theme.palette.black.main,
    },
    disabled: {
      color: theme.palette.gray.main,
    },
  },
});