import { useCallback, useEffect } from 'react';
import { useLeftBarWindowContext } from '../context';
import type { LeftBarActions, LeftBarWindowMessages } from '../interfaces';
import { useLeftBarState } from './useLeftBarState';

export const useMultiWindowLeftBarState = () => {
  const [state, dispatch] = useLeftBarState();
  const { window: childWindow } = useLeftBarWindowContext();

  const syncedDispatch = useCallback((action: LeftBarActions.Actions) => {
    dispatch(action);

    if (childWindow) {
      childWindow.postMessage({
        type: 'dispatch',
        action,
      });
    }
  }, [dispatch, childWindow]);

  useEffect(() => {
    function onMessage(message: MessageEvent<LeftBarWindowMessages.Message>) {
      if (message.data?.type === 'dispatch') {
        dispatch(message.data.action);
      }
    }

    if (window) {
      window.addEventListener('message', onMessage);

      return () => window.removeEventListener('message', onMessage);
    }
  }, [dispatch]);

  return [state, syncedDispatch] as const;
};