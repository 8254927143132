import { useCallback, useContext, useState } from 'react';
import { MedicalBackgroundAdditionalExpertiseContext, MedicalBackgroundContext } from './Context';
import { FormState, QueryData, SetValueParams } from './interfaces.additional-expertise';

type Props = {
  children: React.ReactNode;
};

export const StateContainer = (props: Props) => {
  const ctx = useContext(MedicalBackgroundContext);

  const [additionalExpertise, setAdditionalExpertise] = useState<FormState>(getStateFromQueryData(ctx.query.data.additionalExpertise));

  const rehydrate = useCallback(() => {
    return ctx.query.refetch()
    .then(res => setAdditionalExpertise(getStateFromQueryData(res.data.additionalExpertise)));
  }, [
    ctx.query,
    setAdditionalExpertise,
  ]);

  const handleChange = useCallback((data: SetValueParams) => {
    setAdditionalExpertise({
      boardEligibility: {
        answer: data.boardEligibility ?? additionalExpertise.boardEligibility.answer,
        question: additionalExpertise.boardEligibility.question,
      },
      description: data.description ?? additionalExpertise.description,
      therapeuticAreas: data.therapeuticAreas ?? additionalExpertise.therapeuticAreas,
    });
  }, [additionalExpertise]);

  const value = {
    form: additionalExpertise,
    rehydrate,
    setValue: handleChange,
  };

  return (
    <MedicalBackgroundAdditionalExpertiseContext.Provider value={value}>
      {props.children}
    </MedicalBackgroundAdditionalExpertiseContext.Provider>
  );
};

StateContainer.displayName = 'MedicalBackground.AdditionalExpertise.StateContainer';

function getStateFromQueryData(data: QueryData): FormState {
  return {
    boardEligibility: {
      answer: data.boardEligibility.answer?.value,
      question: data.boardEligibility.question,
    },
    description: data.description ?? '',
    therapeuticAreas: data.therapeuticAreas,
  };
}