import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router';
import { Home } from '@consts/pathname';
import { ProjectOnboardingContext } from '@containers/ProjectOnboarding/Context';

export const useNavigateNextOrRedirect = () => {
  const ctx = useContext(ProjectOnboardingContext);
  const history = useHistory();

  return useCallback((pathname: string = Home) => {
    ctx.hasNextStep
      ? ctx.next()
      : history.push(pathname);
  }, [
    ctx,
    history,
  ]);
};