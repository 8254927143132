import { useMemo } from 'react';
import type { SurveyBuilderEditing } from '../interfaces';
import { useSurveyBuilderState } from './useSurveyBuilderContext';

export const useIsEditingSurvey = () => {
  const [state] = useSurveyBuilderState();

  const isEditing = useMemo(() => {
    return isEditingSurvey(state.editing);
  }, [
    state.editing,
  ]);

  return isEditing;
};

export function isEditingSurvey(data: SurveyBuilderEditing.State) {
  if (!data) return false;

  return !!data.tagging.identifier ||
    !!data.itemIdentifier ||
    !!data.quota.identifier ||
    !!data.logic.identifier ||
    !!data.tagging.identifier;
}

export default useIsEditingSurvey;