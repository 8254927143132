import { BasicRouter } from '@routes/Routers';
import { path } from '@consts';
import { RouteAuth } from '@enums';
import { GroupBrands } from '@screens/Group.Brands';
import type * as Routing from '@routes/interfaces';

export const routes: Routing.Route[] = [
  {
    component: GroupBrands,
    exact: true,
    path: path.Brands.Root,
  },
];

export const router = BasicRouter(routes, {
  auth: RouteAuth.Authenticated,
  path: path.Brands.Root,
});