import type { Dispatch, SetStateAction } from 'react';
import { useContext } from 'react';
import type { SurveyActionType, SurveyQuestionType } from '@enums';
import { createNamedContext } from '@utils';
import type { SurveyFormScreenState, SurveyFormVersion, SurveyFormMetadata, RespondentAnswer, SurveyVersion } from '@/types/survey';
import type { Surveys } from '@/services/api/interfaces';
import type { SubmitSurveyParams } from './interfaces';

export * from './context.number-input-table';

export type ConjointAnalysisFormContextValue = {
  currentSet: number;
  setSetAnswer: (setNo: number) => (conceptNo: number | null) => unknown;
};

export type ConjointAnalysisNavigationContextValue = {
  back: () => void;
  next: () => void;
  nextDisabled: boolean;
};

export type MaxDiffFormContextValue = {
  currentSet: number;
  setSetAnswer: (setNumber: number) => (choice: 'left' | 'right', value: number) => unknown;
};

export type MaxDiffNavigationContextValue = {
  back: () => void;
  next: () => void;
  nextDisabled: boolean;
};

export type ImageMarkupEditorValue = tuiImageEditor.MyImageEditor;

export type ImageMarkupEditorContextValue = {
  editor: ImageMarkupEditorValue;
  setEditor: (instance: ImageMarkupEditorValue) => void;
};

export type SurveyFormBackContextValue = {
  disabled: boolean;
  isError: boolean;
  isLoading: boolean;
  handler: () => Promise<unknown>;
};

export type SubmitOverrides = {
  answer?: Partial<RespondentAnswer>;
  itemId?: number;
};

export type SurveyFormSubmitContextValue = {
  isError: boolean;
  isLoading: boolean;
  handler: (options?: SubmitOverrides) => Promise<unknown>;
};

export type SurveyFormApiContextValue = {
  goBack: (data: Surveys.Response.GoBack.Request) => Promise<Surveys.Response.GoBack.Response>;
  submitAIEScreen: (data: Surveys.Response.SubmitAIEScreen.Request) => Promise<Surveys.Response.SubmitAIEScreen.Response>;
  submitAnswer: (data: Surveys.Response.SubmitAnswer.Request) => Promise<Surveys.Response.SubmitAnswer.Response>;
  submitMessageScreen: (data: Surveys.Response.SubmitMessageScreen.Request) => Promise<Surveys.Response.SubmitMessageScreen.Response>;
};

export type AIENavigationContextValue = {
  slideIndex: number;
  setSlideIndex: (val: number) => void; //This should only be used by the carousel, this will not actually control the carousel
};

export type SubmitSurvey = (data: SubmitSurveyParams) => Promise<void>;

export type SurveyFormScreenContextValue =
  [SurveyFormScreenState, Dispatch<SetStateAction<SurveyFormScreenState>>];

type SurveyFormQuestionAnswerContextValue<T extends SurveyQuestionType = SurveyQuestionType> =
  [RespondentAnswer<T>, Dispatch<RespondentAnswer<T>>];

type SurveyFormAnswerRationaleContextValue =
  [string, Dispatch<string>];

type SurveyFormRootElementContextValue = HTMLDivElement;

type SurveyFormTemplateContextValue = SurveyVersion['template'];

export type ToggleMatrixValue = (rowId: number, optionId: number) => void;

export const SurveyResponseIdentifierContext = createNamedContext<string>(undefined, 'SurveyResponseIdentifierContext');
export const SurveyFormContext = createNamedContext<SurveyFormVersion>(undefined, 'SurveyFormContext');
export const SurveyFormScreenContext = createNamedContext<SurveyFormScreenContextValue>(undefined, 'SurveyFormScreenContext');
export const SurveyFormVersionIdContext = createNamedContext<number>(undefined, 'SurveyFormVersionIdContext');
export const SurveyFormMetadataContext = createNamedContext<SurveyFormMetadata>(undefined, 'SurveyFormMetadataContext');
export const SurveyFormTemplateContext = createNamedContext<SurveyFormTemplateContextValue>(undefined, 'SurveyFormTemplateContext');
export const SurveyFormRootElementContext = createNamedContext<SurveyFormRootElementContextValue>(undefined, 'SurveyFormWidthContext');

export const SurveyFormQuestionAnswerContext = createNamedContext<SurveyFormQuestionAnswerContextValue>(undefined, 'SurveyFormQuestionAnswerContext');
export const SurveyFormAnswerRationaleContext = createNamedContext<SurveyFormAnswerRationaleContextValue>(undefined, 'SurveyFormAnswerRationaleContext');

export const SurveyFormCanSubmitContext = createNamedContext<boolean>(true, 'SurveyFormCanSubmitContext');
export const SurveyFormItemSubmitContext = createNamedContext<SurveyFormSubmitContextValue>(undefined, 'SurveyFormSubmitItemContext');
export const SurveyFormItemBackContext = createNamedContext<SurveyFormBackContextValue>(undefined, 'SurveyFormItemBackContext');
export const SurveyFormShowCompleteContext = createNamedContext<boolean>(null, 'SurveyFormShowCompleteContext');

export const SurveyFormApiContext = createNamedContext<SurveyFormApiContextValue>(undefined, 'SurveyFormApiContext');

export const ConjointAnalysisFormContext = createNamedContext<ConjointAnalysisFormContextValue>(undefined, 'ConjointAnalysisFormContext');
export const ConjointAnalysisNavigationContext = createNamedContext<ConjointAnalysisNavigationContextValue>(undefined, 'ConjointAnalysisNavigationContext');

export const MaxDiffFormContext = createNamedContext<MaxDiffFormContextValue>(undefined, 'MaxDiffFormContext');
export const MaxDiffNavigationContext = createNamedContext<MaxDiffNavigationContextValue>(undefined, 'MaxDiffNavigationContext');

export const ImageMarkupEditorContext = createNamedContext<ImageMarkupEditorContextValue>(undefined, 'ImageMarkupEditorContext');

export const ToggleMatrixValueContext = createNamedContext<ToggleMatrixValue>(undefined, 'ToggleMatrixValueContext');

export const AIENavigationContext = createNamedContext<AIENavigationContextValue>(undefined, 'AIENavigationContext');

export const HideSurveyFormBackContext = createNamedContext<boolean>(false, 'HideSurveyFormBackContext');

export type SaveCompletedSurveyResponse = () => unknown;
export type SaveDisqualifiedSurveyResponse = (actionType: SurveyActionType) => unknown;

export const SaveCompletedSurveyResponseContext = createNamedContext<SaveCompletedSurveyResponse>(undefined, 'SaveCompletedSurveyResponseContext');
export const SaveDisqualifiedSurveyResponseContext = createNamedContext<SaveDisqualifiedSurveyResponse>(undefined, 'SaveDisqualifiedSurveyResponseContext');

export const useSaveCompletedSurveyResponse = () => useContext(SaveCompletedSurveyResponseContext);
export const useSaveDisqualifiedSurveyResponse = () => useContext(SaveDisqualifiedSurveyResponseContext);

export const useSurveyFormContext = () => useContext(SurveyFormContext);
export const useSurveyFormVersionId = () => useContext(SurveyFormVersionIdContext);
export const useSurveyFormMetadata = () => useContext(SurveyFormMetadataContext);
export const useSurveyFormScreenContext = () => useContext(SurveyFormScreenContext);
export const useSurveyFormRootElementContext = () => useContext(SurveyFormRootElementContext);

export const useCanSubmitSurveyForm = () => useContext(SurveyFormCanSubmitContext);
export const useSurveyFormQuestionAnswer = <
  T extends SurveyQuestionType = SurveyQuestionType,
>() => useContext(SurveyFormQuestionAnswerContext) as SurveyFormQuestionAnswerContextValue<T>;
export const useSurveyFormAnswerRationale = () => useContext(SurveyFormAnswerRationaleContext);

export const useConjointAnalysisFormContext = () => useContext(ConjointAnalysisFormContext);
export const useConjointAnalysisNavigationContext = () => useContext(ConjointAnalysisNavigationContext);

export const useMaxDiffFormContext = () => useContext(MaxDiffFormContext);
export const useMaxDiffNavigationContext = () => useContext(MaxDiffNavigationContext);

export const useImageMarkupEditorContext = () => useContext(ImageMarkupEditorContext);

export const useSurveyFormItemBack = () => useContext(SurveyFormItemBackContext);
export const useSurveyFormItemSubmit = () => useContext(SurveyFormItemSubmitContext);

export const useSurveyFormApi = () => useContext(SurveyFormApiContext);

export const useSurveyResponseIdentifier = () => useContext(SurveyResponseIdentifierContext);

export const useToggleMatrixValue = () => useContext(ToggleMatrixValueContext);

export const useAIENavigation = () => useContext(AIENavigationContext);