import { useCallback, useMemo, useState } from 'react';
import * as api from '@api';
import { useQueryDownloader } from '@containers/QueryDownloader';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { ProjectReportType } from '@/types';
import { DropDown } from '@/components/DropDown';
import { useModal } from '@/components/Modal/hooks';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import { Header } from '@/components/Modal/Header';
import Toast from '@/components/Toast';
import styles from './style/ProjectReportModal.css';

type Props = IProjectId & Pick<ModalProps, 'open' | 'onClose'>;

type ReportType = 'xlsx';

type DropdownItem = {
  id: ReportType;
  value: string;
};

export const ExportProjectHighlightsModal = (props: Props) => {
  return (
    <ModalContents {...props} />
  );
};

export const ModalContents = ({ onClose, open, projectId }: Props) => {
  const [format, setFormat] = useState<ProjectReportType>();

  const { isFetching, download } = useQueryDownloader({
    queryKey: [`get:projects/tagged-moments/export`, projectId],
    queryFn: () => api.projects.conference.exportProjectTaggedMoments({ projectId }),
    downloaderOptions: {
      onResponse: res => ({
        type: 'websocket',
        value: res.websocketKey,
      }),
    },
    onSuccess: () => onClose(),
    onError: () => {
      Toast.error({
        body: 'Project Report',
        title: 'There was an error trying to download the report.',
      });
    },
  });

  const handleSubmit = useCallback(() => {
    download({
      extension: format,
      name: `Project Highlights`,
      title: `Generating Report`,
    });
  }, [
    download,
    format,
  ]);

  const dropdownItems: DropdownItem[] = useMemo(() => {
    return exportFormats
      .map(m => ({
        id: m,
        value: m.toLocaleUpperCase(),
      }));
  }, []);

  const handleSelect = useCallback((item: DropdownItem) => {
    setFormat(item.id);
  }, []);

  return (
    <Modal
      className={styles.root}
      open={open}
      onClose={onClose}>
      <Header text="Project Report" />
      <div className={styles.dropdown}>
        <DropDown<DropdownItem>
          items={dropdownItems}
          onSelect={handleSelect}
          text={format?.toLocaleUpperCase() || 'Choose Format'}
          getItemValue={(item: DropdownItem) => item.value} />
      </div>
      <div className={styles.btns}>
        <ButtonActivityIndicator
          className={styles.btn}
          disabled={!format}
          onClick={handleSubmit}
          loading={isFetching}>
          Download
        </ButtonActivityIndicator>
      </div>
    </Modal>
  );
};

const exportFormats: ReportType[] = ['xlsx'];

export const useExportProjectHighlightsModal = () => useModal(ExportProjectHighlightsModal);

export default ExportProjectHighlightsModal;