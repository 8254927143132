import { format } from 'date-fns';
import { CallStatus, UserProjectStatus } from '@enums';
import { cx } from '@utils';
import { TableCellItem } from './interfaces';
import styles from './style/Table.css';

type Props = TableCellItem;

export const Cell = (props: Props) => {
  if (props.row.original.respondent.statusId === UserProjectStatus.Invited) {
    return `Awaiting Expert Project Acceptance`;
  }

  if (!props.row.original.call.timeStart) return 'Not Set';

  const start = format(new Date(props.row.original.call.timeStart), 'MM/dd/yy @ hh:mm aaa');
  const end = format(new Date(props.row.original.call.timeEnd), 'hh:mm aaa');

  const time = [start, end].join(' - ');

  const deemphasize = [
    CallStatus.Cancelled,
    CallStatus.Rescheduling,
  ].includes(props.row.original.call.statusId);

  const classnames = cx({
    [styles.deemphasis]: deemphasize,
  });

  return (
    <div className={classnames}>{time}</div>
  );
};

Cell.displayName = 'Column.Time.Cell';