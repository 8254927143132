import { AxiosResponse } from 'axios';
import { useInfiniteQuery, UseInfiniteQueryOptions } from '@tanstack/react-query';
import * as api from '@api';
import { Topics } from '@api/interfaces';

type Data = Topics.FetchMembers.Response;

export function useEnumerateTopicMembers<TData = Data>(
  slug: string,
  options?: UseInfiniteQueryOptions<Data, AxiosResponse, TData>
) {

  return useInfiniteQuery(['topic', slug, 'experts'], ctx => {
    return api.topics.fetchMembers({
      slug,
      cursor: +(ctx.pageParam ?? 1),
    });
  }, options);
}