import { useState } from 'react';
import { FormErrorContext } from './context';

type Props =
  ChildrenProps;

const FormError = (props: Props) => {
  const [sector, setSectorError] = useState('');
  const [industry, setIndustryError] = useState('');
  const [subindustry, setSubIndustryError] = useState('');

  function clearErrors() {
    setSectorError('');
    setIndustryError('');
    setSubIndustryError('');
  }

  const ctx = {
    clearErrors,
    errors: {
      industry,
      sector,
      subindustry,
    },
    setSectorError,
    setIndustryError,
    setSubIndustryError,
  };

  return (
    <FormErrorContext.Provider value={ctx}>
      {props.children}
    </FormErrorContext.Provider>
  );
};

export {
  FormError,
};