import { useCallback, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useActive, useChainedCommands, useCommands } from '@remirror/react';
import Divider from '@mui/material/Divider';
import Menu, { menuClasses } from '@mui/material/Menu';
import MenuItem, { menuItemClasses, MenuItemProps } from '@mui/material/MenuItem';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import styles from './style/Toolbar.css';

const StyledMenu = styled(Menu)({
  [`& .${menuClasses.paper}`]: {
    borderRadius: '4px',
    transition: 'duration 0 !important',
    width: '145px',
  },
});

const StyledMenuItem = (props: MenuItemProps) => (
  <MenuItem
    {...props}
    sx={{
      fontFamily: 'var(--font-reg)',
      color: 'var(--gray-d)',
    }} />
);

export const Heading = (props: unknown) => {
  const [anchor, setAnchor] = useState<HTMLElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const active = useActive();
  const { toggleHeading } = useCommands();
  const chain = useChainedCommands();
  const [level, setLevel] = useState<number>(null);

  const enabled = toggleHeading.enabled();

  const handleClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setAnchor(e.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchor(null);
  }, []);

  const setHeading = useCallback((level: number) => {
    if (!level) return;

    chain
      .toggleHeading({ level })
      .focus()
      .run();
    setAnchor(null);
  }, [chain]);

  const updateLevel = useCallback(() => {

    if (!enabled) return null;

    const level = active.heading({ level: 1 })
      ? 1
      : active.heading({ level: 2 })
        ? 2
        : null;
    setLevel(level);

  }, [
    active,
    enabled,
  ]);

  useEffect(() => {

    updateLevel();

  }, [
    active,
    enabled,
    updateLevel,
  ]);

  return (
    <>
      <div
        className={styles.heading}
        onClick={handleClick}
        ref={ref}>
        <StyledMenuItem
          disableRipple>
          {active.heading({ level: 1 })
            ? <span className={styles.h1}>Heading 1</span>
            : active.heading({ level: 2 })
              ? <span className={styles.h2}>Heading 2</span>
                : `Normal`}
        </StyledMenuItem>
        <div className={styles.indicator}>
          <KeyboardArrowDown sx={{
            height: '25px',
            width: '25px',
            color: 'var(--trolley)',
          }} />
          <Divider
            sx={{
              marginLeft: '4px',
              marginRight: '4px',
              height: '28px',
            }}
            orientation="vertical"
            variant="middle" />
        </div>
      </div>
      <StyledMenu
        anchorEl={anchor}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        className={styles.root}
        disableAutoFocusItem
        onClose={handleClose}
        open={!!anchor}
        transitionDuration={{
          appear: 0,
          enter: 0,
          exit: 0,
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}>
        <StyledMenuItem
          onClick={() => {
            if (enabled && (active.heading({ level: 1 }) || active.heading({ level: 2 }))) {
              chain
                .toggleHeading()
                .run();
            }

            setAnchor(null);
          }}
          disableRipple>
          Normal
        </StyledMenuItem>
        <StyledMenuItem
          disableRipple
          onClick={() => setHeading(1)}>
          <span className={styles.h1}>Heading 1</span>
        </StyledMenuItem>
        <StyledMenuItem
          disableRipple
          onClick={() => setHeading(2)}>
          <span className={styles.h2}>Heading 2</span>
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};