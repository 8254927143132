import { cx, device } from '@utils';
import screenshot from 'static/images/tutorials/scheduling-desktop.png';
import mobileScreenshot from 'static/images/tutorials/scheduling-mobile.png';
import Button from '@/components/Button';
import styles from './style.css';

type Props = {
  nextScreen: () => void;
};

const SchedulingTutorial = ({ nextScreen }: Props) => {
  const lineTwoText = `Click and drag on the calendar to propose availability.`;
  const lineTwoMobileText = `Tap and drag on the calendar to propose availability.`;

  const classes = cx(styles.screenshot, {
    [styles.hidden]: device.phone,
  });

  const title = `Ready to propose times for the call?`;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.title}>{title}</div>
        <div className={styles.lineOne}>
          Please propose up to three weeks of availability to ensure that the client will be able to find a time that works for their schedule.
        </div>
        <div className={styles.lineTwo}>{lineTwoText}</div>
        <div className={styles.lineTwoMobile}>{lineTwoMobileText}</div>

        <div className={classes}>
          <img
            className={styles.img}
            src={screenshot} />
          <img
            className={styles.mobileImg}
            src={mobileScreenshot} />
        </div>

        <div className={styles.done}>
          <div className={styles.bottom}>
            <Button
              className={styles.btn}
              onClick={nextScreen}
              variant="brick">
              Propose Times
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { SchedulingTutorial };
export default SchedulingTutorial;