import { memo, useCallback } from 'react';
import { ColorLabelProps, SeriesTooltipProps } from './interfaces';
import { LabelWithColor } from './ColorIndicator';
import styles from './style/SeriesTooltip.css';

const ColorLabel = memo(({ color, value }: ColorLabelProps) => {
  return (
    <LabelWithColor
      color={{
        value: color,
        size: 18,
      }}
      label={{
        className: styles.label,
        value,
      }} />
  );
});

const MaxSeries = 5;
export const SeriesTooltip = memo(({ getSerieLabel, getSerieStat, series }: SeriesTooltipProps) => {

  const renderValues = useCallback(() => {
    return series
      .filter((_, i) => i < MaxSeries)
      .map(serie => (
        <div key={serie.id} className={styles.lines}>
          <ColorLabel
            key={serie.id}
            color={serie.color}
            value={getSerieLabel(serie)} />
          <div className={styles.row}>
            <div className={styles.colorOffset} />
            <div>{getSerieStat(serie)}</div>
          </div>
        </div>
      ));
  }, [
    getSerieLabel,
    getSerieStat,
    series,
  ]);

  const renderMore = useCallback(() => {
    if (series.length <= MaxSeries) {
      return null;
    }

    const amt = series.length - MaxSeries;

    return (
      <div className={styles.more}>...{amt} others</div>
    );
  }, [series.length]);

  return (
    <div className={styles.root}>
      {renderValues()}
      {renderMore()}
    </div>
  );
});