import { forwardRef, memo } from 'react';
import { AddItemButton, AddItemButtonProps } from '@presentation/AddItemButton';
import { cx } from '@utils';
import styles from './style/Buttons.css';

type ButtonProps = Omit<AddItemButtonProps, 'label'>;

export const AddQuestionRowButton = memo((props: ButtonProps) => (
  <AddItemButton
    label="Add Row"
    {...props} />
));

export const AddQuestionColumnButton = memo((props: ButtonProps) => (
  <AddItemButton
    label="Add Column"
    {...props} />
));

export const AddQuestionOptionButton = memo((props: ButtonProps) => (
  <AddItemButton
    label="Add Option"
    {...props} />
));

export const AddQuestionButton = memo((props: ButtonProps) => (
  <AddItemButton
    textClassName={styles.bold}
    label="Add Question"
    {...props} />
));

export const AddLogicButton = memo((props: ButtonProps) => {
  const { className, ...rest } = props;
  return (
    <AddItemButton
      className={cx(styles.bordered, className)}
      textClassName={styles.bold}
      label="Add Logic"
      {...rest} />
  );
});

export const AddTagButton = memo((props: ButtonProps) => {
  const { className, ...rest } = props;
  return (
    <AddItemButton
      className={cx(styles.bordered, className)}
      textClassName={styles.bold}
      label="Add Tag"
      {...rest} />
  );
});

export const AddQuotaButton = memo((props: ButtonProps) => {
  const { className, ...rest } = props;
  return (
    <AddItemButton
      className={cx(styles.bordered, className)}
      textClassName={styles.bold}
      label="Add Quota"
      {...rest} />
  );
});

export const AddConditionButton = memo((props: ButtonProps) => {
  const { className, ...rest } = props;
  return (
    <AddItemButton
      className={className}
      textClassName={styles.bold}
      label="Add Condition"
      {...rest} />
  );
});

export const AddQuotaSetButton = memo((props: ButtonProps) => {
  const { className, ...rest } = props;
  return (
    <AddItemButton
      className={cx(styles.bordered, styles.aql, className)}
      textClassName={styles.bold}
      label="Add Question Logic"
      {...rest} />
  );
});

export const AddQuestionButtonBordered = memo((props: ButtonProps) => {
  const { className, ...rest } = props;
  return (
    <AddQuestionButton
      className={cx(styles.bordered, className)}
      {...rest} />
  );
});

export const AddSurveyItemButton = memo(forwardRef<HTMLDivElement, ButtonProps>(({ className, ...props }, ref) => (
  <AddItemButton
    ref={ref}
    textClassName={styles.bold}
    className={cx(styles.bordered, className)}
    label="Add Item"
    {...props} />
)));

export const AddSectionButton = memo(forwardRef<HTMLDivElement, ButtonProps>(({ className, ...props }, ref) => (
  <AddItemButton
    ref={ref}
    className={cx(styles.section, className)}
    label="Add Section"
    onClick={props.onClick}  />
)));

export const AddSectionButtonBordered = memo(forwardRef<HTMLDivElement, ButtonProps>(({ className, ...props }, ref) => (
  <AddSectionButton
    ref={ref}
    className={cx(styles.bordered, className)}
    {...props} />
)));