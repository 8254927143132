import { MinusCircle } from 'react-feather';
import { cx } from '@utils';
import { useRemoveSurveyCondition } from '@containers/SurveyBuilder.Logic';
import styles from './style/Builder.Condition.Question.css';

type Props = {
  className?: string;
} & ChildrenProps;

export const LogicBuilderCondition = ({ children, className }: Props) => {

  const { canRemove, removeItem } = useRemoveSurveyCondition();

  return (
    <div className={cx(styles.root, className)}>
      {children}
      {canRemove &&
        <MinusCircle
          onClick={removeItem}
          className={styles.remove} />
      }
    </div>
  );
};