import { memo } from 'react';
import { Helmet } from 'react-helmet';
import { path } from '@consts';
import { Landing } from './Landing';
import { Platform } from './Platform';
import { QualitativeEngagement } from './QualitativeEngagement';
import { Tools } from './Tools';
import { BannerPricing } from './BannerPricing';
import { SurveyFeatures } from './SurveyFeatures';
import { InterviewFeatures } from './InterviewFeatures';
import { Insights } from './Insights';
import { BannerFooter } from './BannerFooter';
import styles from './style/Quant2Qual.css';

type Props = unknown;

const H = memo(() => {
  const description = ``;

  return (
    <Helmet
      title="Quant2Qual">
      <link
        rel="canonical"
        href={`${process.env.FRONT_BASE_URL}${path.Website.Quant2Qual}`} />
      {/* <meta
        name='description'
        content={description} />
      <meta
        name='og:description'
        content={description} /> */}
    </Helmet>
  );
});

export const Quant2Qual = (props: Props) => {

  return (
    <div className={styles.root}>
      <H />
      <Landing />
      <Platform />
      <QualitativeEngagement />
      <Tools />
      <BannerPricing />
      <SurveyFeatures />
      <InterviewFeatures />
      <Insights />
      <BannerFooter />
    </div>
  );
};

Quant2Qual.displayName = 'Quant2Qual';