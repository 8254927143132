import type { Reducer } from 'react';
import { useMemo, useReducer } from 'react';
import { SurveyConditionsState, useSurveyConditionsMatchState } from '@containers/SurveyBuilder.Logic';
import { SurveyTaggingItemStateContext } from './context';
import type { TaggingItemBuilder } from './interfaces';
import { surveyTaggingReducer } from './reducer';

type Props = {
  initial: TaggingItemBuilder.State;
} & ChildrenProps;

const SurveyTaggingBuilderState = ({
  children,
  initial,
}: Props) => {

  const [state, dispatch] = useReducer<Reducer<TaggingItemBuilder.State, TaggingItemBuilder.Action>>(surveyTaggingReducer, initial);

  const [matchState] = useSurveyConditionsMatchState();

  const mergedState = useMemo(() => {
    return {
      ...state,
      conditions: matchState.conditions,
      rule: matchState.rule,
    };
  }, [state, matchState]);

  return (
    <SurveyTaggingItemStateContext.Provider value={[mergedState, dispatch]}>
      {children}
    </SurveyTaggingItemStateContext.Provider>
  );
};

const WithConditionsState = (props: Props) => {

  const initial = {
    conditions: props.initial.conditions,
    rule: props.initial.rule,
  };

  return (
    <SurveyConditionsState initial={initial}>
      <SurveyTaggingBuilderState initial={props.initial}>
        {props.children}
      </SurveyTaggingBuilderState>
    </SurveyConditionsState>
  );
};

export { WithConditionsState as SurveyTaggingBuilderState };