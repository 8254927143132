import { useMemo } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CellExpertActions, CellExpert } from '../Cells';

export default function useExpertsTableColumns() {
  const isDesktop = useMediaQuery('(min-width:900px)');

  return useMemo(() => [
    {
      accessor: 'name',
      id: 'name',
      Header: 'Name',
      Cell: CellExpert,

    },
    isDesktop ? {
      accessor: 'actions',
      id: 'actions',
      Cell: CellExpertActions,
      minWidth: 20,
      width: 20,
    } : null,
  ].filter(Boolean), [isDesktop]);
}

export { useExpertsTableColumns };