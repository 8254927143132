import * as consts from '@consts';
import { BackLink } from '@presentation/Back';
import { ProjectSurveyDetailsInternalUser } from '@/types';
import { CompletedProjectSurveyReviewInternalUser, ProjectSurveyResponseSection } from '@/scenes/surveys/CompletedSurvey/ProjectReview';
import { ComplianceScreener } from '@/scenes/surveys/CompletedSurvey';
import styles from './style.css';

type Props = {
  item: ProjectSurveyDetailsInternalUser;
};

const ProjectSurveyApprovalView = (props: Props) => {

  const renderSurveyBody = () => (
    <>
      {props.item.complianceSurvey &&
        <ComplianceScreener survey={props.item.complianceSurvey} />}
      <ProjectSurveyResponseSection survey={props.item.survey} />
    </>
  );

  const renderBody = () => {
    if (!props.item) return null;

    return (
      <CompletedProjectSurveyReviewInternalUser
        data={props.item}
        renderBody={renderSurveyBody}
        renderButtons={() => <></>} />
    );
  };

  return (
    <div className={styles.root}>
      <BackLink
        className={styles.back}
        to={consts.pathname.HOME} />
      <div className={styles.body}>
        {renderBody()}
      </div>
    </div>
  );
};

export { ProjectSurveyApprovalView };
export default ProjectSurveyApprovalView;