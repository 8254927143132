import { useCallback, useMemo } from 'react';
import { Route, Switch, useHistory, matchPath, useLocation } from 'react-router-dom';
import { Topics } from '@api/interfaces';
import * as consts from '@consts';
import { TabView } from '@/components/TabView';
import * as Tab from './Tab';
import { BuildSceneMap, Scene, SceneMap, TopicTab } from './interfaces';
import styles from './style/TopicLanding.css';

type Props =
  Pick<Topics.FetchOverview.Response,
  | 'item'
  | 'meta'>;

export const Tabs = ({ item, meta }: Props) => {
  const history = useHistory();
  const location = useLocation();

  const scenemap = useMemo(() => {
    return buildSceneMap({
      hasMembers: meta.hasExperts,
      hasNews: meta.hasNews,
      hasPosts: meta.hasPosts,
      slug: item.slug,
    });
  }, [
    meta.hasExperts,
    meta.hasNews,
    meta.hasPosts,
    item.slug,
  ]);

  const matchTabIndexFromLocation = useCallback((scenes: SceneMap) => {
    const matchedScene = Object.values(scenes).find(s => matchPath(s.path, {
      path: location.pathname,
      exact: true,
    }));
    if (!matchedScene) return 0;

    return Object.keys(scenes).indexOf(matchedScene.key);
  }, [
    location.pathname,
  ]);

  const index = useMemo(() => {
    return matchTabIndexFromLocation(scenemap);
  }, [
    matchTabIndexFromLocation,
    scenemap,
  ]);

  const changeLocation = useCallback((idx: number) => {
    const tab = Object.keys(scenemap)[idx] as TopicTab;
    const path = scenemap[tab].path;
    history.push(path);
  }, [
    history,
    scenemap,
  ]);

  const routes = Object.values(scenemap);
  const postsAsRoot = !routes.find(x => x.key === TopicTab.Overview);

  const renderTabView = useCallback(() => {
    return (
      <div className={styles.tabview}>
        <Switch>
          <Route
            component={postsAsRoot ? Tab.Posts : Tab.Overview}
            exact
            path={consts.path.Topics.Topic} />
          <Route
            component={Tab.Members}
            exact
            path={consts.path.Topics.Experts.Root} />
          <Route
            component={Tab.Posts}
            exact
            path={consts.path.Topics.Posts.Root} />
          <Route
            component={Tab.News}
            exact
            path={consts.path.Topics.News.Root} />
        </Switch>
      </div>
    );
  }, [postsAsRoot]);

  return (
    <TabView<Scene>
      index={index}
      onIndexChange={changeLocation}
      renderTabView={renderTabView}
      routes={routes} />
  );
};

Tabs.displayName = 'Topic.Tabs';

function buildSceneMap({ hasMembers, hasNews, hasPosts, slug }: BuildSceneMap.Params): BuildSceneMap.Return {
  function slugify(path: string) {
    return path.replace(':slug', slug);
  }

  const scenemap = {
    [TopicTab.Overview]: hasMembers || hasPosts ? {
      key: TopicTab.Overview,
      component: Tab.Overview,
      title: 'Overview',
      path: slugify(consts.path.Topics.Topic),
    } : null,
    [TopicTab.Experts]: hasMembers ? {
      key: TopicTab.Experts,
      component: Tab.Members,
      title: 'Experts',
      path: slugify(consts.path.Topics.Experts.Root),
    } : null,
    [TopicTab.Posts]: {
      key: TopicTab.Posts,
      component: Tab.Posts,
      title: 'Posts',
      path: slugify(consts.path.Topics.Posts.Root),
    },
    [TopicTab.News]: hasNews ? {
      key: TopicTab.News,
      component: Tab.News,
      title: 'News',
      path: slugify(consts.path.Topics.News.Root),
    } : null,
  };

  return Object.entries(scenemap).reduce((acc, [key, val]) => {
    return val
      ? { ...acc, [key]: val }
      : acc;
  }, {} as SceneMap);
}