import { useCallback, useState } from 'react';
import { TranscriptSidebarPositionsContext } from './context.sidebar';
import type { Transcript } from './interfaces';

type Props =
  ChildrenProps;

export const TranscriptSidebarPositionsContainer = (props: Props) => {

  const [positions, setPositions] = useState<Transcript.Sidebar.PositionMap>({});

  const setSidebarPosition: Transcript.Sidebar.SetPosition = useCallback(identifier => position => {
    setPositions(p => ({
      ...p,
      [identifier]: position,
    }));
  }, []);

  return (
    <TranscriptSidebarPositionsContext.Provider value={[positions, setSidebarPosition]}>
      {props.children}
    </TranscriptSidebarPositionsContext.Provider>
  );
};