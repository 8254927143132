import extend from '@enums/extend';

export enum TranscriptEntityType {
  BankingInformation = 'banking_information',
  BloodType = 'blood_type',
  CreditCardCVV = 'credit_card_cvv',
  CreditCardExpiration = 'credit_card_expiration',
  CreditCardNumber = 'credit_card_number',
  Date = 'date',
  DateOfBirth = 'date_of_birth',
  DriversLicense = 'drivers_license',
  Drug = 'drug',
  Event = 'event',
  EmailAddress = 'email_address',
  Injury = 'injury',
  Language = 'language',
  Location = 'location',
  MedicalCondition = 'medical_condition',
  MedicalProcess = 'medical_process',
  MoneyAmount = 'money_amount',
  Nationality = 'nationality',
  Occupation = 'occupation',
  Organization = 'organization',
  PersonAge = 'person_age',
  PersonName = 'person_name',
  PhoneNumber = 'phone_number',
  PoliticalAffiliation = 'political_affiliation',
  Religion = 'religion',
  UsSocialSecurityNumber = 'us_social_security_number',

  Unknown = 'unknown',
}

export enum TranscriptSource {
  AssemblyAI = 'assemblyai',
  AWSTranscribe = 'aws-transcribe',
}

export enum TranscriptPromptTemplate {
  UnmetNeedsSummary = 1,
}

const templateMap = new Map([
  [TranscriptPromptTemplate.UnmetNeedsSummary, { name: 'Unmet Needs' }],
]);

const entityTypeMap = new Map([
  [TranscriptEntityType.BankingInformation, { name: 'Banking Information' }],
  [TranscriptEntityType.BloodType, { name: 'Blood Type' }],
  [TranscriptEntityType.CreditCardCVV, { name: 'Credit Card CVV' }],
  [TranscriptEntityType.CreditCardExpiration, { name: 'Credit Card Expiration' }],
  [TranscriptEntityType.CreditCardNumber, { name: 'Credit Card Number' }],
  [TranscriptEntityType.Date, { name: 'Date' }],
  [TranscriptEntityType.DateOfBirth, { name: 'Date of Birth' }],
  [TranscriptEntityType.DriversLicense, { name: 'Driver\'s License' }],
  [TranscriptEntityType.Drug, { name: 'Drug' }],
  [TranscriptEntityType.Event, { name: 'Event' }],
  [TranscriptEntityType.EmailAddress, { name: 'Email Address' }],
  [TranscriptEntityType.Injury, { name: 'Injury' }],
  [TranscriptEntityType.Language, { name: 'Language' }],
  [TranscriptEntityType.Location, { name: 'Location' }],
  [TranscriptEntityType.MedicalCondition, { name: 'Medical Condition' }],
  [TranscriptEntityType.MedicalProcess, { name: 'Medical Process' }],
  [TranscriptEntityType.MoneyAmount, { name: 'Money Amount' }],
  [TranscriptEntityType.Nationality, { name: 'Nationality' }],
  [TranscriptEntityType.Occupation, { name: 'Occupation' }],
  [TranscriptEntityType.Organization, { name: 'Organization' }],
  [TranscriptEntityType.PersonAge, { name: 'Person Age' }],
  [TranscriptEntityType.PersonName, { name: 'Person Name' }],
  [TranscriptEntityType.PhoneNumber, { name: 'Phone Number' }],
  [TranscriptEntityType.PoliticalAffiliation, { name: 'Political Affiliation' }],
  [TranscriptEntityType.Religion, { name: 'Religion' }],
  [TranscriptEntityType.Unknown, { name: 'Unknown' }],
  [TranscriptEntityType.UsSocialSecurityNumber, { name: 'US Social Security Number' }],
]);

export enum StructuredSummaryFeedback {
  TooBroad = 1,
  TooSpecific = 2,
  TooWordy = 3,
  IrrelevantTopic = 4,
  FalseStatements = 5,
  OverGeneralization = 6,
  ToneIssues = 7,
  FormattingIssues = 8,
}

const structuredFeedbackMap = new Map([
  [StructuredSummaryFeedback.TooBroad, { name: 'Too Broad' }],
  [StructuredSummaryFeedback.TooSpecific, { name: 'Too Specific' }],
  [StructuredSummaryFeedback.TooWordy, { name: 'Too Wordy' }],
  [StructuredSummaryFeedback.IrrelevantTopic, { name: 'Irrelevant Topic' }],
  [StructuredSummaryFeedback.FalseStatements, { name: 'False Statements' }],
  [StructuredSummaryFeedback.OverGeneralization, { name: 'Over Generalization' }],
  [StructuredSummaryFeedback.ToneIssues, { name: 'Tone Issues' }],
  [StructuredSummaryFeedback.FormattingIssues, { name: 'Formatting Issues' }],
]);

export enum TranscriptType {
  VoiceCall = 'voice-call',
  VideoCall = 'video-call',
  PostMedia = 'post-media',
  VoiceCallTrack = 'voice-call-track',
  RoomTrack = 'room-track',
  UserParticipantTrack = 'user-participant-track',
  Debug = 'debug',
  WorkspaceFile = 'workspace-file',
}

export enum TranscriptPublishType {
  RecordingAndTranscript = 1,
  RecordingOnly = 2,
  TranscriptOnly = 3,
  None = 4,
}

const publishTypeMap = new Map([
  [TranscriptPublishType.RecordingAndTranscript, { name: 'Recording + Transcript' }],
  [TranscriptPublishType.RecordingOnly, { name: 'Recording Only' }],
  [TranscriptPublishType.TranscriptOnly, { name: 'Transcript Only' }],
  [TranscriptPublishType.None, { name: 'None' }],
]);

export default {
  StructuredSummaryFeedback: extend(structuredFeedbackMap),
  TranscriptEntityType: extend(entityTypeMap),
  TranscriptPromptTemplate: extend(templateMap),
  TranscriptPublishType: extend(publishTypeMap),
};