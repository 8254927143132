import { useContext, useEffect, useMemo } from 'react';
import { useQueryParams, NumberParam } from 'use-query-params';
import { RateLimitingContext } from '@containers/RateLimiting/Context';
import { useInitiateFilesReview } from '@containers/WorkspaceFileUpload/hooks/useInitiateFilesReview';
import { CanUploadFilesContext, UploadFilesContext } from '@/containers/WorkspaceFileUpload/Context';
import { FileUploadReviewContainer } from '@/containers/WorkspaceFileUpload/Upload.Review';
import { useProjectState } from '@containers/GroupProject/hooks';
import { useFetchProjectFiles } from '@utils/api';
import {
  FilesTableContext,
  FilesTableDataContext,
  FilesTableFiltersContext,
  FilesTableLoadingContext,
  FilesTableMetaContext,
  SetFilesTableFiltersContext,
  SelectedObjectsContainer,
} from '@/components/Workspace.FilesTable';
import { WorkspaceBreadcrumbsContext } from '@containers/WorkspaceObject/Context';
import { useDevice } from '@/utils';

type Props =
  ChildrenProps;

const ProjectFilesContainer = (props: Props) => {
  const [params, setQueryParams] = useParams();

  const state = useProjectState();
  const workspaceId = state.workspace?.id;
  const projectId = state.project?.id;
  const resolvedProjectParentId = params.parentObjectId || state.projectParent?.objectId;
  const { query: rlq } = useContext(RateLimitingContext);

  useEffect(() => {

    rlq?.refetch();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const query = useFetchProjectFiles({
    parentObjectId: resolvedProjectParentId,
    projectId,
  });

  const uploadFiles = useInitiateFilesReview({
    parentObjectId: resolvedProjectParentId,
    workspaceId,
  });

  const device = useDevice();
  const canUpload = useMemo(() => {
    return !device.phone && !device.tablet && state.capabilities.canAddChildren;
  }, [device, state.capabilities]);

  const parentObject = {
    id: resolvedProjectParentId,
    workspaceId,
  };

  const loading = query.isInitialLoading;
  const tableData = query.data?.items || [];
  const breadcrumbs = params.parentObjectId
    ? query.data?.breadcrumbs || []
    : [];

  return (
    <SelectedObjectsContainer>
      <WorkspaceBreadcrumbsContext.Provider value={breadcrumbs}>
        <FilesTableDataContext.Provider value={tableData}>
          <FilesTableLoadingContext.Provider value={loading}>
            <FilesTableFiltersContext.Provider value={params}>
              <SetFilesTableFiltersContext.Provider value={setQueryParams}>
                <FilesTableContext.Provider value={{ parentObject, projectId }}>
                  <UploadFilesContext.Provider value={uploadFiles}>
                    <CanUploadFilesContext.Provider value={canUpload}>
                      <FilesTableMetaContext.Provider value={query.data?.meta}>
                        {props.children}
                      </FilesTableMetaContext.Provider>
                    </CanUploadFilesContext.Provider>
                  </UploadFilesContext.Provider>
                </FilesTableContext.Provider>
              </SetFilesTableFiltersContext.Provider>
            </FilesTableFiltersContext.Provider>
          </FilesTableLoadingContext.Provider>
        </FilesTableDataContext.Provider>
      </WorkspaceBreadcrumbsContext.Provider>
    </SelectedObjectsContainer>
  );
};

const useParams = () => {
  return useQueryParams({
    parentObjectId: NumberParam,
  });
};

const WithState = (props: Props) => {
  return (
    <FileUploadReviewContainer>
      <ProjectFilesContainer {...props} />
    </FileUploadReviewContainer>
  );
};

export { WithState as ProjectFilesContainer };