import survey from 'static/images/website/q2q/icon-run-surveys.svg?url';
import click from 'static/images/website/q2q/icon-click-data.svg?url';
import talk from 'static/images/website/q2q/icon-talk-to-respondents.svg?url';
import styles from './style/QualitativeEngagement.css';

type Props = unknown;

export const QualitativeEngagement = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <h2 className={styles.title}>
            High quality quantitative data with qualitative optionality
          </h2>
          <div className={styles.subheader}>
            Gather faster, better insights with Sentiment’s perfect survey data quality, linked respondent profiles, and the option to follow up via qualitative engagement for deeper engagement.
          </div>
        </div>
        <div className={styles.main}>
          {items.map(x =>
            <div
              className={styles.item}
              key={x.text}>
              <img
                className={styles.icon}
                src={x.src} />
              <div className={styles.text}>{x.text}</div>
            </div>)}
        </div>
      </div>
    </div>
  );
};

QualitativeEngagement.displayName = 'Quant2Qual.QualitativeEngagement';

const items = [{
  src: survey,
  text: `Run surveys`,
}, {
  src: click,
  text: `Drill into data`,
}, {
  src: talk,
  text: `Talk to respondents`,
}];