import { useCallback, useMemo, useState } from 'react';
import cuid from 'cuid';
import { useMessageTestingData } from '@containers/SurveyBuilder/hooks';
import { SurveyTemplate } from '@/types/survey';
import { useSubmitMessageCategories } from './message-categories.submit';

export const useCanSaveMessageCategories = (categories: SurveyTemplate.MessageTesting.Category[]) => {

  return useMemo(() => {
    return !!categories.length &&
      categories.every(e => {
        return !!e.messages.length &&
         !!e.label.length &&
         !!e.name.length &&
         e.messages.every(m => !!m.value.length);
      });

  }, [categories]);
};

const generateMessage = (): SurveyTemplate.LinkedEntity => ({
  id: cuid(),
  value: '',
});

export const useTemplateMessageCategories = () => {
  const [templateData] = useMessageTestingData();
  const [categories, setCategories] = useState(templateData.categories);

  const submit = useSubmitMessageCategories();

  const onSubmit = useCallback(() => {
    submit(categories);
  }, [
    categories,
    submit,
  ]);

  const addCategory = useCallback(() => {
    setCategories(s => s.concat({
      identifier: cuid(),
      label: '',
      name: '',
      messages: [generateMessage(), generateMessage()],
    }));
  }, []);

  const removeCategory = useCallback((identifier: string) => () => {
    setCategories(categories => categories.filter(f => f.identifier !== identifier));
  }, []);

  const updateCategoryName = useCallback((identifier: string) => (value: string) => {
    setCategories(categories => categories.map(m => ({
      ...m,
      name: m.identifier === identifier ? value : m.name,
    })));
  }, []);

  const updateCategoryLabel = useCallback((identifier: string) => (value: string) => {
    setCategories(categories => categories.map(m => ({
      ...m,
      label: m.identifier === identifier ? value : m.label,
    })));
  }, []);

  const addMessage = useCallback((identifier: string) => () => {
    setCategories(categories => {
      return categories.reduce((acc, category) => {
        if (category.identifier !== identifier) return acc.concat(category);

        return acc.concat({
          ...category,
          messages: category.messages.concat(generateMessage()),
        });
      }, []);
    });
  }, []);

  const removeMessage = useCallback((categoryIdentifier: string) => (messageIdentifier: string) => () => {
    setCategories(categories => {
      return categories.reduce((acc, category) => {
        if (category.identifier !== categoryIdentifier) return acc.concat(category);

        return acc.concat({
          ...category,
          messages: category.messages.filter(f => f.id !== messageIdentifier),
        });
      }, []);
    });
  }, []);

  const updateMessageValue = useCallback((categoryIdentifier: string) => (messageIdentifier: string) => (value: string) => {
    setCategories(categories => {
      return categories.reduce((acc, category) => {
        if (category.identifier !== categoryIdentifier) return acc.concat(category);

        return acc.concat({
          ...category,
          messages: category.messages.map(m => ({
            ...m,
            value: m.id === messageIdentifier ? value : m.value,
          })),
        });
      }, []);
    });
  }, []);

  return {
    handlers: {
      addCategory,
      addMessage,
      removeCategory,
      removeMessage,
      updateCategoryLabel,
      updateCategoryName,
      updateMessageValue,
    },
    onSubmit,
    value: categories,
  };
};