import { useContext, useMemo } from 'react';
import { MedicalBackgroundContext, MedicalBackgroundPracticeContext } from '@containers/MedicalOnboarding/Context';
import { CompletionMedicalStepperContext } from '@containers/ProjectOnboarding.Completion/Context';
import * as Field from '@/components/MedicalProfile/Field';
import * as Layout from '@/components/MedicalProfile/Layout';
import { Label } from '@/components/MedicalProfile/Label';
import { RadioGroup } from '@/components/MedicalProfile/RadioGroup';
import styles from './style/Medical.css';

type Props = unknown;

export const Practice = (props: Props) => {
  const ctx = {
    background: useContext(MedicalBackgroundContext),
    practice: useContext(MedicalBackgroundPracticeContext),
    stepper: useContext(CompletionMedicalStepperContext),
  };

  const validation = useMemo(() => {
    const errors = {
      practiceTime: !(ctx.practice.form.practiceTime.answer.items.reduce((acc, x) => x.value + acc, 0) === 100),
      medicalFacilityType: !ctx.practice.form.medicalFacilityType.answer,
      employer: false, //!ctx.practice.form.employer?.name,
    };

    const disabled = Object.values(errors).some(x => x === true);

    return {
      disabled,
      errors,
    };
  }, [
    ctx.practice.form,
  ]);

  return (
    <Layout.Screen>
      <Layout.Header title={copy.title} />
      <div className={styles.item}>
        <Label required>
          {ctx.practice.form.practiceTime.question.value}
        </Label>
        <div className={styles.field}>
          <Field.PracticeTime />
        </div>
      </div>
      <div className={styles.item}>
        <Label required>
          {ctx.practice.form.medicalFacilityType.question.value}
        </Label>
        <div className={styles.field}>
          <RadioGroup
            onChange={optionId => ctx.practice.setValue({ medicalFacilityType: optionId })}
            options={ctx.practice.form.medicalFacilityType.question.options}
            selected={ctx.practice.form.medicalFacilityType.answer} />
        </div>
      </div>

      <div className={styles.item}>
        <Label>
          {copy.label.employer}
        </Label>
        <div className={styles.field}>
          <Field.Hospitals
            className={styles.input}
            placeholder={copy.placeholder.employer} />
        </div>
      </div>
      <Layout.Footer className={styles.footer}>
        <Layout.BackButton onClick={ctx.stepper.back} />
        <Layout.NextButton
          disabled={validation.disabled}
          implicitDisable={false}
          loading={ctx.background.mutation.isLoading}
          onClick={ctx.stepper.next}>
          Next
        </Layout.NextButton>
      </Layout.Footer>
    </Layout.Screen>
  );
};

Practice.displayName = 'Medical.Practice';

const copy = {
  label: {
    employer: `If you are comfortable, please tell us the name of the hospital or practice you are currently employed by.`,
  },
  placeholder: {
    employer: `Hospital or Practice`,
  },
  title: `Next we’d like to know a bit more about your practice and medical activities for your role.`,
};