import { useCallback, useEffect, useState, Fragment, memo } from 'react';
import { OnChangeValue } from 'react-select';
import { Field, FieldProps, useFormikContext } from 'formik';
import { useQuery } from '@tanstack/react-query';
import { useHasInternalAdminRole } from '@containers/User';
import * as $adminApi from '$admin/api';
import * as $api from '@api';
import { SelectSearchUnderlined } from '$admin/components/Select';
import styles from './style.css';

type SourcingVendor = {
  id: number;
  name: string;
};

type Props = {
  editing: boolean;
  label: string;
  name: string;
  displayValue: SourcingVendor;
};

export function FieldSourcingVendors({ editing, label, name, displayValue }: Props) {
  const { setFieldValue } = useFormikContext();
  const [search, setSearch] = useState('');

  const hadAdminRole = useHasInternalAdminRole();

  const query = useQuery(['get:/projects/external-sourcing/vendor', search], () => {
    if (hadAdminRole) {
      return $adminApi.projects.externalSourcing.searchSourcingVendors({
        name: search,
      });
    } else {
      return $api.projects.externalSourcing.searchSourcingVendors({
        name: search,
      });
    }
  }, {
    enabled: !!search.trim(),
    refetchOnWindowFocus: false,
    staleTime: 10000,
  });

  const handleChange = useCallback((item: OnChangeValue<SourcingVendor, false>) => {
    setFieldValue(name, item);
    setSearch('');
  }, [name, setFieldValue]);

  useEffect(() => {
    query.refetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!editing) {
    return (
      <Display
        label={label}
        displayValue={displayValue} />
    );
  }

  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps<SourcingVendor>) => (
        <div>
          <div className={styles.label}>
            {label} {meta.touched && meta.error && <span className={styles.formError}>{meta.error}</span>}
          </div>
          <div className={styles.value}>
            <SelectSearchUnderlined
              isClearable
              formatCreateLabel={value => value}
              getOptionLabel={(item: SourcingVendor) =>  item.name}
              getOptionValue={(item: SourcingVendor) => `${item.id}`}
              inputValue={search}
              noOptionsMessage={x => x.inputValue?.length ? `No matches found.` : null}
              onChange={handleChange}
              onInputChange={setSearch}
              onBlur={() => form.setFieldTouched(name, true)}
              options={query.data?.vendors || []}
              placeholder="Vendor"
              value={field.value} />
          </div>
        </div>
      )}
    </Field>
  );
}

const Display = memo(({ label, displayValue }: Pick<Props, 'label' | 'displayValue'>) => {
  return (
    <Fragment>
      <div className={styles.label}>{label}</div>
      <span className={styles.dateValue}>
        {displayValue &&
          <>
            {displayValue.name}
          </>
        }
        {!displayValue &&
          <>
            Not Set
          </>
        }
      </span>
    </Fragment>
  );
});