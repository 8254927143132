import { Phone } from 'react-feather';
import CountBadge from './CountBadge';

type Props = {
  className?: string;
  count: number;
  to: string;
};

const CallsCount = (props: Props) => (
  <CountBadge
    className={props.className}
    count={props.count}
    icon={<Phone width={20} height="100%" />}
    to={props.to} />
);

export { CallsCount };
export default CallsCount;