import { useCallback, useEffect, useMemo, useState } from 'react';
import type { RouteProps } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useQueryParam, StringParam } from 'use-query-params';

type Props<T extends string> = {
  formatPath: (tab: T, location: RouteProps['location']) => string;
  routes: T[];
};

export const useTabViewQueryState = <T extends string>({ formatPath, routes }: Props<T>) => {
  const [tab] = useQueryParam('tab', StringParam);
  const history = useHistory();

  const tabIndex = useMemo(() => {
    const idx = routes.indexOf(tab as T);

    return idx >= 0 && idx < routes.length
      ? idx
      : 0;
  }, [
    routes,
    tab,
  ]);

  const [index, setIndex] = useState(tabIndex);

  const setTabIndex = useCallback(() => {
    setIndex(tabIndex);
  }, [
    setIndex,
    tabIndex,
  ]);

  const setLocation = useCallback((idx: number) => {
    const key = routes[idx];
    const path = formatPath(key, history.location);

    history.push(path);
  }, [
    history,
    formatPath,
    routes,
  ]);

  useEffect(() => {
    setTabIndex();
  }, [
    tab,
    setTabIndex,
  ]);

  const state = {
    index,
    routes,
  };

  return [state, setLocation] as const;
};

export default useTabViewQueryState;