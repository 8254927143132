import { path } from '@consts';
import { Button } from '@/components/Button';
import styles from './style/BannerFooter.css';

type Props = unknown;

export const BannerFooter = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h3 className={styles.header}>
          Take your research process to the next level.
        </h3>
        <div className={styles.main}>
          <Button
            className={styles.btn}
            color="primary"
            to={path.Website.Pricing}
            variant="pill">
            View Pricing
          </Button>
        </div>
      </div>
    </div>
  );
};

BannerFooter.displayName = 'Quant2Qual.BannerFooter';