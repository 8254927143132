import { useCallback } from 'react';
import { useAlert } from '@/components/Modal/Alert';
import { useDeleteTagMutation } from '@/components/Project.Tags/hooks';

type Props = {
  tagId: number;
} & IProjectId;

export const useDeleteTagModal = (props: Props) => {
  const deleteTagMutation = useDeleteTagMutation();

  const [toggleDeleteAlert, DeleteAlert] = useAlert();

  const onDeleteClick = useCallback(() => {
    deleteTagMutation.mutate({
      projectTagId: props.tagId,
      projectId: props.projectId,
    });
  }, [
    deleteTagMutation,
    props.projectId,
    props.tagId,
  ]);

  const DeleteTagModal = useCallback(() => {
    return (
      <DeleteAlert
        message={copy.message}
        onConfirm={onDeleteClick} />
    );
  }, [
    DeleteAlert,
    onDeleteClick,
  ]);

  return [toggleDeleteAlert, DeleteTagModal] as const;
};

const copy = {
  message: `Deleting a tag is irreversible and removes tagged data from transcripts.`,
};