import { memo, useMemo } from 'react';
import { Briefcase } from 'react-feather';
import { Link } from 'react-router-dom';
import { ProjectIcon } from '@/components/icons';
import { cx, getLocationFor } from '@/utils';
import { IWorkspaceProjectItem } from '@/types/workspace.table';
import { formatCreatedOnDate } from './Files.Table.Grid.utils';
import styles from './style/Files.Table.Grid.css';

type Props = {
  item: IWorkspaceProjectItem;
};

export const Project = ({ item }: Props) => {

  const createdOn = useMemo(() => {
    return formatCreatedOnDate(item.object.createdOn);
  }, [item.object.createdOn]);

  const to = useMemo(() => {
    return getLocationFor.project.rootWithId({ id: item.project.id });
  }, [item.project.id]);

  return (
    <Link
      className={cx(styles.link, styles.file)}
      to={to}
      draggable={false}>
      <div className={styles.thumb}>
        <GenericProjectThumb />
      </div>
      <div className={styles.info}>
        <div className={styles.icon}>
          <ProjectIcon
            className={styles.icon}
            projectType={item.project.projectType} />
        </div>
        <div className={styles.details}>
          <div className={styles.name}>{item.object.name}</div>
          <div className={styles.lastModified}>{createdOn}</div>
        </div>
      </div>
    </Link>
  );
};

const GenericProjectThumb = memo(() => {
  return (
    <div className={styles.genericThumb}>
      <Briefcase className={styles.icon} strokeWidth={1} />
    </div>
  );
});