import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useHasDataStewardRole } from '@containers/Store/hooks';
import { CallTranscriptDetailsContext } from '@containers/Transcript/context';
import { Breadcrumb, Breadcrumbs, SkeletonBreadcrumbs } from '@presentation/Breadcrumbs';
import { getLocationFor } from '@utils';
import styles from '@screens/Transcript/style/Transcript.css';
import { HomeBreadcrumb } from '@/components/Workspace/WorkspaceBreadcrumb';

type Props = unknown;

export const CallTranscriptBreadcrumbs = (props: Props) => {

  const data = useContext(CallTranscriptDetailsContext);

  const isLoading = !data?.project?.name;

  if (isLoading) {
    return (
      <SkeletonBreadcrumbs />
    );
  }

  return (
    <InteractiveBreadcrumb />
  );
};

CallTranscriptBreadcrumbs.displayName = 'Call.Transcript.Breadcrumbs';

const InteractiveBreadcrumb = (props: Props) => {
  const hasDataStewardRole = useHasDataStewardRole();

  return (
    <div className={styles.breadcrumbs}>
      <Breadcrumbs>
        <HomeBreadcrumb />
        {!hasDataStewardRole &&
          <ProjectBreadcrumb />}
      </Breadcrumbs>
    </div>
  );
};

const ProjectBreadcrumb = (props: Props) => {
  const location = useLocation<{ from: string }>();
  const data = useContext(CallTranscriptDetailsContext);

  const summaryLocation = location.state?.from || getLocationFor.project.summary({ slug: String(data?.project?.id) });

  return (
    <Link to={summaryLocation}>
      <Breadcrumb>
        {data.project.name}
      </Breadcrumb>
    </Link>
  );
};