import { ButtonOutlined } from '@presentation/ButtonOutlined';
import { cx } from '@utils';
import { useAccountDeletionModal } from './AccountDeletion.Modal';
import styles from './style/AccountDeletion.css';

type Props = {
  className?: string;
};

const AccountDeletion = ({ className }: Props) => {
  const [toggleModal, AccountDeletionModal] = useAccountDeletionModal();

  return (
    <>
      <ButtonOutlined
        className={cx(styles.btn, className)}
        borderWidth="thin"
        color="silver"
        fontWeight="light"
        onClick={toggleModal}>
        Delete Account
      </ButtonOutlined>
      <AccountDeletionModal />
    </>
  );
};

export { AccountDeletion };
export default AccountDeletion;