import { Component } from 'react';
import { addHours, isAfter, isSameDay, addSeconds, endOfDay, getMinutes, startOfMinute } from 'date-fns';
import { Calendar as FullCalendar } from '@fullcalendar/core';
import * as api from '@api';
import { device } from '@utils';
import CalendarBase from '@/components/Calendar/CalendarBase';
import { CancelAdHocCall as CancelAdHocCallModal } from '@/components/Modal/CancelAdHocCall';
import { CancelProjectCall as CancelProjectCallModal } from '@/components/Modal/CancelProjectCall';
import Toast from '@/components/Toast';
import EventPopover from './EventPopover';
import MoratoriumHover from './MoratoriumHover';
import { ViewingMain } from './ViewingMain';
import { ViewingMainResponsive } from './ViewingMainResponsive';
import styles from './style/Calendar.css';
import {
  getEventAvailabilityId,
  getFullCalSubmitButtonElement,
  getNewEvents,
  getProvidingDefaults,
  getRemovedEvents,
  getResponsiveCompatibleView,
  transformEvent,
  transformExternalEvents,
} from './utils';

class Viewing extends Component {
  state = {
    cancelAdHocCallModal: false,
    cancelProjectCallModal: false,
    events: [],
    externalEvents: [],
    selectedExternalCalendars: [],
    removedEvents: [],
  };

  constructor(props) {
    super();
    this.state.selectedExternalCalendars = [props.user.id];
  }

  componentDidMount() {
    this.fetchCalendar({ initial: true });
    this.renderCalendar();
  }

  componentDidUpdate() {
    this.calendar.refetchEvents();
  }

  componentWillUnmount() {
    if (this.calendar) {
      this.calendar.destroy();
    }
    if (this.pastTimer) {
      clearInterval(this.pastTimer);
    }
  }

  dismissPopover = () => {
    this.setState({
      popover: null,
    });
  };

  fetchCalendar = data => {
    return api.calendars.getPersonalCalendar()
    .then(resp => {
      this.setState({
        events: [
          ...(data && data.initial ? this.state.events : []),
          ...resp.events.map(e => transformEvent({
            group: this.props.group,
            event: e,
            user: this.props.user,
          })),
        ],
        externalEvents: transformExternalEvents({ userId: this.props.user.id, events: resp.external.events }),
      });
    });
  };

  generateExternalEventSource = () => {
    return Object.keys(this.state.externalEvents)
    .filter(u => this.state.selectedExternalCalendars.includes(+u))
    .reduce((acc, u) => {
      return acc.concat(...this.state.externalEvents[u]);
    }, []);
  };

  getCancelModalData = () => {
    if (!this.state.popover) return {};

    const md = EventPopover.getEventMetadata(this.state.popover.event);

    if (!md.call || !md.with) return {};

    return {
      call: md.call,
      project: md.project,
      respondent: {
        id: md.respondent.id,
        name: md.respondent.profile.fullname,
      },
    };
  };

  handleAvailablityUpdated = () => {
    return this.fetchCalendar().then(_ => {
      this.setState({ removedEvents: [] });

      Toast.alert({
        title: 'Calendar Updated',
        body: `Your calendar changes have been saved.`,
      });
    });
  };

  handleCancelAdHocCall = () => {
    const md = EventPopover.getEventMetadata(this.state.popover.event);

    return api.scheduling.adHocCall.cancel({
      callId: md.call.id,
    })
    .then(data => {
      this.props.updateCall({ call: data.call });
      this.toggleCancelAdHocCallModal();
      this.dismissPopover();
      this.fetchCalendar().then(() => {
        this.fetchCalendar().then(() => {
          this.props.adHocCallNotifier.cancellation.success(md.call.id);
        });
      });
    })
    .catch(e => {
      this.fetchCalendar().then(() => {
        this.fetchCalendar().then(() => {
          this.props.adHocCallNotifier.cancellation.error();
        });
      });
      console.error(e);
    });
  };

  handleCancelProjectCall = () => {

    const md = EventPopover.getEventMetadata(this.state.popover.event);

    return api.scheduling.projectCall.cancel({
      callId: md.call.id,
    })
    .then(data => {
      this.props.updateCall({ call: data.call });
      this.toggleCancelProjectCallModal();
      this.dismissPopover();
      this.fetchCalendar().then(() => {
        this.props.projectCallNotifier.cancellation.success();
      });
    })
    .catch(e => {
      this.fetchCalendar().then(() => {
        this.props.projectCallNotifier.cancellation.error();
      });
      console.error(e);
    });
  };

  handleCalendarClick = e => {
    if (this.state.popover) {
      this.dismissPopover();
    }
  };

  handleExternalSelection = data => {
    this.setState({
      selectedExternalCalendars: data.selected
      ? [...this.state.selectedExternalCalendars, data.id]
      : [...this.state.selectedExternalCalendars.filter(u => u !== data.id)],
    });
  };

  handleClick = (el, event) => {
    if (['external', 'past', 'moratorium'].includes(event.extendedProps.type)) {
      return;
    }

    this.setState({
      popover: {
        event,
        el: el.id,
      },
    });
  };

  handleSelect = info => {
    this.setState({
      events: this.state.events.concat({
        end: isSameDay(info.end, info.start) ? info.end : addSeconds(endOfDay(info.start), 1),
        id: CalendarBase.createId(),
        start: info.start,
        editable: true,
        startEditable: true,
        title: 'Available',
        extendedProps: {
          isNew: true,
          canRemove: true,
          type: 'available',
        },
      }),
      popover: null,
    });

    this.calendar.unselect();
  };

  handleSave = () => {
    return api.calendars.updatePersonalCalendar({
      new: getNewEvents(this.state.events),
      removed: getRemovedEvents(this.state.removedEvents),
    })
    .then(this.handleAvailablityUpdated)
    .catch(e => {
      this.fetchCalendar();
      Toast.error({
        title: 'Error',
        body: `We're sorry. There was an issue attempting to update your calendar. Please try again.`,
      });
      console.error(e);
    });
  };

  removeEventAvailability = () => {
    const event = this.state.popover.event;

    if (event.extendedProps.canRemove) {
      const availId = getEventAvailabilityId(event);

      if (availId) {
        const toRemove = this.state.events.filter(e => getEventAvailabilityId(e) == availId);

        this.setState({
          events: this.state.events.filter(e => !toRemove.map(m => m.id).includes(e.id)),
          removedEvents: this.state.removedEvents.concat(toRemove),
        });
      } else {
        this.setState({ events: this.state.events.filter(e => e.id != event.id) });
      }
    }
    this.setState({ popover: null });
  };

  renderCalendar = () => {

    this.calendar = new FullCalendar(document.getElementById(styles.calendar), {
      ...getProvidingDefaults(),
      customButtons: {
        save: {
          text: 'Save',
          click: this.handleSave,
        },
      },
      eventClick: info => this.handleClick(info.el, info.event),
      eventDrop: info => {
        this.setState({
          events: this.state.events.filter(e => e.id != info.event.id).concat(info.event),
          popover: null,
        });
      },
      eventOverlap: (still, moving) => {
        return still.extendedProps.type === 'external' &&
               moving.extendedProps.type === 'available';
      },
      eventSources:[
        { id: 'events', events: (_, cb) => cb(this.state.events) },
        { id: 'external', events: (_, cb) => cb(this.generateExternalEventSource()) },
        { id: 'past', events: (_, cb) => cb(CalendarBase.generateBackgroundEventSource()) },
        { id: 'moratorium', events: (_, cb) => cb(CalendarBase.generateMoratoriumEventSource({ minutes: this.props.user.settings.callSchedulingMoratorium })) },
      ],
      eventResize: info => {
        this.setState({
          events: this.state.events.filter(e => e.id != info.event.id).concat(info.event),
          popover: null,
        });
      },
      select: this.handleSelect,
      selectAllow: info => {
        if (isAfter(Date.now(), new Date(info.start))) {
          return false;
        }

        return true;
      },
      windowResize: v => this.calendar.changeView(getResponsiveCompatibleView()),
    });

    this.calendar.render();
    CalendarBase.scrollToNow();
    this.scrollable = CalendarBase.getScrollableElement();

    if (this.pastTimer) {
      clearInterval(this.pastTimer);
    }

    this.pastTimer = setInterval(() => {
      const pastEventSource = this.calendar.getEventSourceById('past');
      if (pastEventSource) {
        pastEventSource.refetch();
      }
      const moratoriumEventSource = this.calendar.getEventSourceById('moratorium');
      if (moratoriumEventSource) {
        moratoriumEventSource.refetch();
      }
    }, 60*1000);

    if (this.props.testMode) {
      const now = Date.now();
      const delta = 30 - (getMinutes(now) % 30); // match slotDuration
      const start = startOfMinute(now + (delta * 60 * 1000));

      this.handleSelect({
        start,
        end: addHours(start, 1),
      });
    }
  };

  toggleCancelProjectCallModal = () => {
    this.setState({ cancelProjectCallModal: !this.state.cancelProjectCallModal });
  };

  toggleCancelAdHocCallModal = () => {
    this.setState({ cancelAdHocCallModal: !this.state.cancelAdHocCallModal });
  };

  render() {
    const props = {
      calendar: this.props.calendar,
      className: this.props.className,
      events: this.state.events,
      gotoDate: date => {
        this.calendar?.gotoDate?.(date);
        const btn = getFullCalSubmitButtonElement();

        if (btn) {
          const newEvents = getNewEvents(this.state.events);
          const canSubmit = newEvents.length
                         || this.state.removedEvents.length;

          btn.disabled = !canSubmit;
        }
      },
      id: styles.calendar,
      onChangeExternalCalendar: this.handleExternalSelection,
      onClickContainer: this.handleCalendarClick,
      onSubmit: this.handleSave,
      removedEvents: this.state.removedEvents,
    };

    return (
      <>
        {device.phone
          ? <ViewingMainResponsive {...props} />
          : <ViewingMain {...props} />}
        {this.state.cancelProjectCallModal &&
          <CancelProjectCallModal
            onClose={this.toggleCancelProjectCallModal}
            onConfirm={this.handleCancelProjectCall}
            open={this.state.cancelProjectCallModal}
            {...this.getCancelModalData()} />
        }
        {this.state.cancelAdHocCallModal &&
          <CancelAdHocCallModal
            onClose={this.toggleCancelAdHocCallModal}
            onConfirm={this.handleCancelAdHocCall}
            open={this.state.cancelAdHocCallModal}
            {...this.getCancelModalData()} />
        }
        {!!this.state.popover &&
          <EventPopover
            cancelAdHocCall={this.toggleCancelAdHocCallModal}
            cancelProjectCall={this.toggleCancelProjectCallModal}
            dismiss={this.dismissPopover}
            popover={this.state.popover}
            remove={this.removeEventAvailability}
            scrollable={this.scrollable} />}
        <MoratoriumHover
          selector={styles.moratorium}
          moratorium={this.props.user.settings.callSchedulingMoratorium} />
        {this.state.popover && <div
          className={styles.eventOverlay}
          onClick={this.dismissPopover} />}
      </>
    );
  }
}

export { Viewing };
export default Viewing;