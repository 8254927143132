import { useCallback, useContext } from 'react';
import cuid from 'cuid';
import { useSelectUser } from '@containers/Store/hooks';
import { TranscriptCommentsStateContext, TranscriptFocusedCommentContext } from '@containers/Transcript.Commenting/Context';
import type { Highlight } from '@/types/transcribe.rich-text';
import type { HighlightWithText } from '../interfaces.extension.highlight';
import { useChainedCommands } from './useCommands';

export const useAddNewComment = (highlight: HighlightWithText) => {
  const commands = useChainedCommands();
  const [_, dispatch] = useContext(TranscriptCommentsStateContext);
  const [__, setFocusedComment] = useContext(TranscriptFocusedCommentContext);

  const me = useSelectUser();

  const addComment = useCallback((h: Highlight) => {
    const identifier = cuid();
    dispatch({
      type: 'comment-added',
      creator: {
        id: me.id,
        profile: me.profile,
      },
      highlight: {
        id: h.dbId,
        identifier: h.id,
      },
      identifier,
    });
    setFocusedComment(identifier);
  }, [
    dispatch,
    me.id,
    me.profile,
    setFocusedComment,
  ]);

  const saveHighlightAndAddComment = useCallback(() => {
    addComment(highlight);
    commands.blurHighlight().run();
    // if (highlight.dbId) {
    //   addComment(highlight);
    //   commands.blurHighlight().run();
    // } else {
    //   saveHighlight({
    //     highlight: {
    //       ...highlight,
    //       color: DefaultColor,
    //     },
    //   });
    // }

  }, [
    addComment,
    commands,
    highlight,
  ]);

  return saveHighlightAndAddComment;
};