import { useContext, useState } from 'react';
import { format } from 'date-fns';
import { Skeleton } from '@mui/material';
import { WorkspaceTranscriptDetailsContext, TranscriptIdContext, TranscriptMediaContext, TranscriptMetadataContext, TranscriptHasMediaContext } from '@containers/Transcript/context';
import { TranscriptDownloadMenu } from '@screens/Transcript/DownloadMenu';
import styles from '@screens/Transcript/style/Header.css';
import { useIsInternalUser } from '@/containers/Store/hooks';
import { DiscoverInsights } from '@/components/GroupProject/DiscoverInsights';
import { PublishedDate } from './Header.PublishedOn';

export const TranscriptHeader = () => {
  const transcriptId = useContext(TranscriptIdContext);
  const details = useContext(WorkspaceTranscriptDetailsContext);
  const media = useContext(TranscriptMediaContext);
  const metadata = useContext(TranscriptMetadataContext);
  const hasMedia = useContext(TranscriptHasMediaContext);

  const isInternalUser = useIsInternalUser();

  const [editingPublished, setEditingPublished] = useState<boolean>(false);

  const has = {
    insightsChat: !!details?.features?.insightsChat,
    object: !!details?.object,
  };

  return (
    <>
      <div className={styles.row}>
        <div className={styles.field}>
          {!has.object
            ? <Skeleton
              className={styles.skel}
              width={200}
              height={30} />
            : <div className={styles.label}>Title:</div>}
          {!has.object
            ? <Skeleton width={250} height={30} />
            : <div className={styles.value}>{details.object.name}</div>}
        </div>
        <div className={styles.actions}>
          {!has.object && !isInternalUser && (
            <Skeleton
              height={30}
              width={50}
              variant="rounded" />
          )}
          {(has.object && has.insightsChat && !isInternalUser) &&
            <DiscoverInsights className={styles.discover} />}
          <TranscriptDownloadMenu
            callId={null}
            projectId={null}
            publishType={details?.settings?.publishType}
            transcriptId={transcriptId}
            source={hasMedia ? {
              type: 'video',
              url: media.url,
            } : null} />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.field}>
          {!has.object
            ? <Skeleton
              className={styles.skel}
              width={200}
              height={30} />
            : <div className={styles.label}>Uploaded On:</div>}
          {!has.object
            ? <Skeleton width={250} height={30} />
            : <div className={styles.value}>{format(new Date(details.object.createdOn), 'MMM do yyyy hh:mm aa')}</div>}
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.field}>
          {!has.object || metadata.isInitialLoading
            ? <Skeleton
              className={styles.skel}
              width={200}
              height={30} />
            : <div className={styles.label}>Published On:</div>}
          {!has.object || metadata.isInitialLoading
            ? <Skeleton width={250} height={30} />
            : (
              <div className={styles.value}>
                <PublishedDate
                  editing={editingPublished}
                  setEditing={setEditingPublished}
                  onCancel={() => {
                    setEditingPublished(false);
                  }}
                  onSubmit={value => {
                    metadata.updateMetadata({ publishedOn: value });
                    setEditingPublished(false);
                  }}
                  value={metadata.publishedOn} />
              </div>
            )}
        </div>
      </div>
    </>
  );
};

TranscriptHeader.displayName = 'Workspace.Transcript.Header';