import { CallRole } from '@enums';
import { ConferenceParticipant } from '@/types';
import { ParticipantSortElement } from './interfaces';

export const formatPIN = (pin: string) => {
  if (!pin) return 'N/A';

  const re = /(\d{3})[- ]?(\d{3})/g;
  return pin.replace(re, '$1-$2');
};

export const sortCallAttendees = <T extends ConferenceParticipant>(a: T, b: T) => {
  return +b.organizer - +a.organizer
      || +b.active - +a.active
      || +b.visibility - +a.visibility
      || a.name.localeCompare(b.name);
};

export const sortParticipants = <T extends ParticipantSortElement>(a: T, b: T) => {
  return +(b.roleId === CallRole.Scheduler) - +(a.roleId === CallRole.Scheduler)
      || +(b.roleId === CallRole.PrimaryRespondent) - +(a.roleId === CallRole.PrimaryRespondent)
      || +(b.roleId === CallRole.Respondent) - +(a.roleId === CallRole.Respondent)
      || +(b.roleId === CallRole.ModeratorAttendee) - +(a.roleId === CallRole.ModeratorAttendee)
      || +(!b.offPlatform) - +(!a.offPlatform)
      || a.name.localeCompare(b.name);
};

export const roleIsModeratorLike = (roleId: CallRole) => {
  return [
    CallRole.Scheduler,
    CallRole.ModeratorAttendee,
  ].includes(roleId);
};

export const roleIsRespondentLike = (roleId: CallRole) => {
  return [
    CallRole.Respondent,
    CallRole.PrimaryRespondent,
  ].includes(roleId);
};