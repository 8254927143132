import { combineReducers } from '@reduxjs/toolkit';
import calls from './calls';
import chat from './chat';
import contacts from './contacts.js';
import group from './group';
import documents from './documents';
import network from './network';
import notifications from './notifications';
import onboarding from './onboarding';
import pipeline from './pipeline';
import platform from './platform';
import projects from './projects';
import routing from './routing.js';
import settings from './settings';
import user from './user';
import workspaces from './workspaces';

export default combineReducers({
  calls,
  chat,
  contacts,
  documents,
  group,
  network,
  notifications,
  onboarding,
  pipeline,
  platform,
  projects,
  routing,
  settings,
  user,
  workspaces,
});