import extend from '@enums/extend';

export enum BillingPayoutApprovalStep {
  ProjectManagerReview = 1,
  TeamLeadReview  = 2,
  FinanceReview = 3,
  Approved = 4,
  Rejected = 5,
}

const map = new Map([
  [BillingPayoutApprovalStep.ProjectManagerReview, { name: 'PM Review' }],
  [BillingPayoutApprovalStep.TeamLeadReview, { name: 'TL Review' }],
  [BillingPayoutApprovalStep.FinanceReview, { name: 'Finance Review' }],
  [BillingPayoutApprovalStep.Approved, { name: 'Approved' }],
  [BillingPayoutApprovalStep.Rejected, { name: 'Rejected' }],
]);

export default {
  BillingPayoutApprovalStep: extend(map),
};