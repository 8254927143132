import { useSelector } from 'react-redux';
import { useProfileState } from './useProfileState';

const mapState = (state: Store.State) => state.user;

const useIsOwnProfile = () => {
  const me = useSelector(mapState);
  const [state] = useProfileState();

  return me.id === state.id;
};

export { useIsOwnProfile };
export default useIsOwnProfile;