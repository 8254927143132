import { useContext, useEffect } from 'react';
import { FetchObjectAccessContext, ObjectAccessContainer, ObjectAccessStateContext } from '@containers/ObjectAccess';
import { TranscriptAccessContext } from '@containers/Transcript/context';
import { useObjectAccessModal } from '@/components/ObjectAccess';
import { ObjectAccessButton, SkeletonAccess } from '@/components/ObjectAccess/Button';

type Props = {
  loading?: boolean;
};

export const Access = (props: Props) => {
  return props.loading
    ? <SkeletonAccess />
    : <InteractiveAccessContainer />;
};

Access.displayName = 'Call.Transcript.Header.Access';

const InteractiveAccessContainer = (props: Props) => {
  const access = useContext(TranscriptAccessContext);

  if (!access.capabilities?.canManageAccess) {
    return null;
  }

  return (
    <>
      <ObjectAccessContainer
        objectId={access.object.id}
        workspaceId={access.workspace.id}>
        <InteractiveAccess />
      </ObjectAccessContainer>
    </>
  );
};

InteractiveAccessContainer.displayName = 'Call.Transcript.Header.Access.Interactive.Container';

const InteractiveAccess = (props: unknown) => {
  const [toggleAccessModal, ObjectAccessModal] = useObjectAccessModal();
  const fetchAccess = useContext(FetchObjectAccessContext);
  const [state] = useContext(ObjectAccessStateContext);

  useEffect(() => {

    fetchAccess();

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <>
      <ObjectAccessButton
        count={state.items.length ?? 0}
        onClick={toggleAccessModal} />
      <ObjectAccessModal />
    </>
  );
};

InteractiveAccess.displayName = 'Call.Transcript.Header.Access.Interactive';