import { useCallback, useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircleSharp';
import PhoneIcon from '@mui/icons-material/Phone';
import PinIcon from '@mui/icons-material/FiberPin';
import { format } from 'date-fns/fp';
import * as api from '@api';
import { path } from '@consts';
import { CallType } from '@enums';
import { ConfirmationStepperContext } from '@containers/Calendar/Context';
import { useAsyncState } from '@utils';
import { Button } from '@/components/Button';
import { AdditionalPhoneNumbersLabel, AdditionalPhoneNumbersModal } from '@/components/Conference.Entry/AdditionalPhoneNumbers';
import styles from './style/SelectingConfirmation.css';

const mapState = (state: Store.State) => ({
  calls: state.calls,
  selectProject: (projectId: number) => state.projects[projectId],
});

type Props = unknown;

const SelectingConfirmation = (props: Props) => {
  const [modal, setModal] = useState<boolean>(false);
  const ctx = useContext(ConfirmationStepperContext);
  const { calls, selectProject } = useSelector(mapState);
  const call = calls[ctx.callId];

  const handleOpenModal = useCallback(() => setModal(true), []);
  const handleCloseModal = useCallback(() => setModal(false), []);

  const [conf] = useAsyncState(() => {
    return api.calls.fetchDialIn({ callId: ctx.callId });
  });

  function DialInInformation() {
    if (conf.loading) return null;

    const dial = conf?.value?.dial;

    const primary = dial?.phoneNumbers?.[0];
    const additional = dial?.phoneNumbers?.slice(1);

    return dial && (
      <div className={styles.info}>
        <div className={styles.h4}>Dial In Infomation</div>
        <div className={styles.conf}>
          <PhoneIcon className={styles.icon} />
          <div className={styles.num}>{primary.displayPhone}</div>
        </div>
        <div className={styles.conf}>
          <PinIcon className={styles.icon} />
          <div className={styles.num}>{dial.displayPin}</div>
        </div>
        {!!additional.length && (
          <div className={styles.additional}>
            <AdditionalPhoneNumbersLabel onClick={handleOpenModal} />
          </div>
        )}
        <AdditionalPhoneNumbersModal
          pin={dial.displayPin}
          phoneNumbers={additional}
          open={modal}
          onClose={handleCloseModal} />
      </div>
    );
  }

  const title = useMemo(() => {
    if (call.typeId === CallType.Project) {
      const project = selectProject(call.projectId);

      return `Call Booked for the ${project.name} project.`;
    } else if (call.typeId === CallType.AdHoc) {
      return `Call Booked`;
    }
  }, [
    call.projectId,
    call.typeId,
    selectProject,
  ]);

  const fTime = format('h:mm a');

  const fulldate = format('MMMM d, y')(ctx.day);
  const time = `${fTime(call.timeStart)} - ${fTime(call.timeEnd)}`;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <div className={styles.title}>{title}</div>
        </div>
        <div className={styles.main}>
          <div className={styles.graphic}>
            <CheckCircleIcon className={styles.check} />
          </div>
          <div className={styles.details}>
            <div className={styles.day}>{fulldate}</div>
            <div className={styles.time}>{time}</div>
          </div>

          <div className={styles.message}>{msg1}</div>
          <div className={styles.message}>{msg2}</div>

          <DialInInformation />
        </div>
      </div>

      <div className={styles.done}>
        <div className={styles.bottom}>
          <Button
            replace
            className={styles.btn}
            to={path.Projects.Root}
            variant="brick">
            Done
          </Button>
        </div>
      </div>

    </div>
  );
};

const msg1 = `Congratulations, we’re looking forward to your call!`;
const msg2 = `You have been sent a calendar invitation as a reminder and can use the following information to dial in at the scheduled time.`;

export { SelectingConfirmation };
export default SelectingConfirmation;