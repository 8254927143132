import { useMemo } from 'react';
import { Datum, Serie } from '@nivo/line';
import { SurveyQuestionType } from '@enums';
import { useRepeatQuestionResponsesData } from '@containers/RepeatSurveyResponses/hooks/useRepeatQuestionResponsesData';
import * as chart from '@containers/SurveyResponses/utils';
import { roundToPlace } from '@utils';

export default function useRepeatMaxDiffChartData() {
  const { projectIds, question, historical } =
    useRepeatQuestionResponsesData<SurveyQuestionType.MaxDifference>();

  const chartData = useMemo(() => {
    return question.options.reduce<Serie[]>((agg, option) => {
      const data = projectIds.reduce<Datum[]>((agg2, projectId, i) => {
        const projectData = historical[projectId].data;
        const optionData = projectData.options[option.ordinal];

        const hasResponses =
          optionData.responses.leftUserIds.length ||
          optionData.responses.rightUserIds.length ||
          optionData.responses.ncUserIds.length;

        return hasResponses
          ? [...agg2, { x: i + 1, y: roundToPlace(optionData.score, 2) }]
          : agg2;
      }, []);

      return [
        ...agg,
        {
          color: chart.maxdiff.getOptionColor(option.ordinal),
          data,
          id: option.base.id,
        },
      ];
    }, []);
  }, [
    projectIds,
    historical,
    question.options,
  ]);

  return chartData;
}

export { useRepeatMaxDiffChartData };