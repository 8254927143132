import { Fragment, useCallback } from 'react';
import { SurveyQuestionType } from '@enums';
import { useProjectSurveyResponsesState } from '@containers/SurveyResponses/hooks/useProjectSurveyResponsesState';
import { SurveyResponsesHeader } from '@presentation/ProjectSurveyResponses';
import { RankingQuestion, SurveyAggregate } from '@/types';
import styles from './style/Options.css';
import ResponseRankingOption from './Ranking.Option';
import ResponseRankingChart from './Ranking.Chart';
import { SegmentedDataTooltip } from './SegmentedDataTooltip';

type Props = {
  question: RankingQuestion.Question;
  responses: SurveyAggregate.StackedBarChart.Data;
};

function sortOptions(a: SurveyAggregate.StackedBarChart.IndexData, b: SurveyAggregate.StackedBarChart.IndexData) {
  return a.aggregate.avg > b.aggregate.avg
    ? 1
    : a.aggregate.avg === b.aggregate.avg
      ? a.value > b.value
        ? 1
        : -1
      : -1
  ;
}

export const ResponseRanking = ({ question, responses }: Props) => {
  const {
    resetQueryState,
    setQueryState,
    ...ctx
  } = useProjectSurveyResponsesState<SurveyQuestionType.Ranking>();

  const handleSegmentClick = useCallback((optionId: number, key: string) => {
    setQueryState({
      key,
      optionId,
    });
  }, [setQueryState]);

  const renderRow = useCallback((optionId: number, key?: string) => {
    return (
      <ResponseRankingOption
        responses={responses}
        optionId={optionId}
        selectedKey={key}
        onReset={resetQueryState} />
    );
  }, [resetQueryState, responses]);

  return (
    <Fragment>
      <SurveyResponsesHeader
        question={question}>
        <SegmentedDataTooltip questionIdentifier={question.base.identifier} />
      </SurveyResponsesHeader>
      <ResponseRankingChart
        responses={responses}
        onClick={handleSegmentClick} />
      <div className={styles.options}>
        {ctx.query.optionId && ctx.query.key
          ? renderRow(ctx.query.optionId, ctx.query.key)
          : responses.indexes.ids
            .sort((a, b) => sortOptions(responses.indexes.values[a], responses.indexes.values[b]))
            .map(optionId => (
              <Fragment key={optionId}>
                {renderRow(optionId)}
              </Fragment>
            ))
        }
      </div>
    </Fragment>
  );
};

export default ResponseRanking;