import { useCallback } from 'react';
import { SurveyActionType } from '@enums';
import {
  SurveyFormMetadataContext,
  SurveyFormVersionIdContext,
  SurveyFormStepperState,
  SurveyResponseIdentifierContext,
  SaveCompletedSurveyResponseContext,
  SaveDisqualifiedSurveyResponseContext,
} from '@containers/SurveyForm';
import { ComplianceSurveyQuestion } from '@screens/Survey';
import { useFetchComplianceSurveyForm } from '@utils/api';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import {
  useProjectOnboardingContext,
  useSubmitComplianceSurvey,
  useTerminateProjectOnboarding,
} from './hooks';

export const ComplianceSurveyStepper = () => {
  const { next: nextProjectScreen, projectId } = useProjectOnboardingContext();
  const {
    data: res,
    isLoading,
    isError,
  } = useFetchComplianceSurveyForm({
    preview: false,
    projectId,
  }, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const submitSurvey = useSubmitComplianceSurvey(projectId);
  const { disqualify } = useTerminateProjectOnboarding({ projectId });

  const handleSubmitSurvey = useCallback(() => {
    return submitSurvey({
      responseIdentifier: res.response.identifier,
      surveyVersionId: res.survey.id,
    });
  }, [
    res?.response?.identifier,
    res?.survey?.id,
    submitSurvey,
  ]);

  const handleDisqualification = useCallback((actionType: SurveyActionType) => {
    return handleSubmitSurvey()
    .then(() => disqualify(actionType));
  }, [disqualify, handleSubmitSurvey]);

  const handleCompletion = useCallback(() => {
    return handleSubmitSurvey()
    .then(nextProjectScreen);
  }, [nextProjectScreen, handleSubmitSurvey]);

  if (isLoading) {
    return <ActivityIndicator show />;
  }

  return (
    <SurveyFormVersionIdContext.Provider value={res.survey.id}>
      <SurveyFormMetadataContext.Provider value={res.metadata}>
        <SaveCompletedSurveyResponseContext.Provider value={handleCompletion}>
          <SaveDisqualifiedSurveyResponseContext.Provider value={handleDisqualification}>
            <SurveyResponseIdentifierContext.Provider value={res.response.identifier}>
              <SurveyFormStepperState
                state={res.response.state}
                QuestionScreenComponent={ComplianceSurveyQuestion} />
            </SurveyResponseIdentifierContext.Provider>
          </SaveDisqualifiedSurveyResponseContext.Provider>
        </SaveCompletedSurveyResponseContext.Provider>
      </SurveyFormMetadataContext.Provider>
    </SurveyFormVersionIdContext.Provider>
  );
};

export default ComplianceSurveyStepper;