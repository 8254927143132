import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Button } from '@/components/Button';
import { RowsPerPage } from '@/components/TablePagination';
import type { PaginationProps } from './interfaces';
import styles from './style/Table.css';

type Props = PaginationProps;

const Pagination = (props: Props) => {

  return (
    <div className={styles.pagination}>
      {(props.pageSizeOptions?.length && props.setPageSize) &&
        <RowsPerPage
          options={props.pageSizeOptions}
          onSelect={props.setPageSize}
          value={props.pageSize} />}
      <span>{props.pageCount ? props.pageIndex + 1 : 0} of {props.pageOptions.length}</span>
      <div className={styles.btns}>
        <Button
          className={styles.btn}
          disabled={!props.canPreviousPage}
          onClick={props.previousPage}
          variant="text">
          <ChevronLeft fontSize="large" />
        </Button>
        <Button
          className={styles.btn}
          disabled={!props.canNextPage}
          onClick={props.nextPage}
          variant="text">
          <ChevronRight fontSize="large" />
        </Button>
      </div>
    </div>
  );
};

export { Pagination };
export type { PaginationProps };
export default Pagination;