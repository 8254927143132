import { useCallback, useContext, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import * as QK from '@consts/querykey.admin';
import * as API from '$admin/api/interfaces';
import * as CallEditSettings from '$admin/components/Call.EditSettings';
import { CallDetailsContext } from '$admin/containers/Call/Context';
import { Call as CD } from '$admin/interfaces';

type Props = {
  children: React.ReactNode;
} & ICallId;

export const CallEditSettingsContainer = (props: Props) => {
  const { query: { refetch } } = useContext(CallDetailsContext);

  const qc = useQueryClient();

  const querykey = useMemo(() => {
    return QK.Calls.Call.Get({
      callId: props.callId,
    });
  }, [props.callId]);

  const setQueryData = useCallback((res: API.Projects.UpdateCallSettings.Response) => {
    qc.setQueryData(querykey, (old: CD.ProjectCallDetails) => ({
      ...old,
      call: {
        ...old.call,
        ...res.call,
      },
      conferenceSettings: {
        ...old.conferenceSettings,
        ...res.conference,
      },
    }));
  }, [
    qc,
    querykey,
  ]);

  const handleSuccess = useCallback((res: API.Projects.UpdateCallSettings.Response) => {
    if (props.callId === res.call.id) {
      setQueryData(res);
      refetch();
    }

  }, [
    props.callId,
    refetch,
    setQueryData,
  ]);

  return (
    <CallEditSettings.Container onSuccess={handleSuccess}>
      {props.children}
    </CallEditSettings.Container>
  );
};

CallEditSettingsContainer.displayName = 'Call.EditSettings.Container';