import { useSelectUser } from '@containers/Store';
import { cx, hasConsultantRole } from '@utils';
import CalendarButton from './Button';
import { CalendarMainProps } from './interfaces';
import styles from './style/MainResponsive.css';
import { getAvailableEvents } from './utils';

type Props = {
  className?: string;
} & CalendarMainProps;

const ProvidingMainResponsive = (props: Props) => {
  const user = useSelectUser();

  function SubmitButton() {
    if (!hasConsultantRole(user)) return null;

    const canSubmit = getAvailableEvents(props.events).length;

    return (
      <CalendarButton
        className={styles.btn}
        disabled={!canSubmit}
        onClick={props.onSubmit}
        variant="pill">
        Send
      </CalendarButton>
    );
  }

  return (
    <div className={cx(props.className, styles.main)}>
      <div id={props.id} />
      <SubmitButton />
    </div>
  );
};

export { ProvidingMainResponsive };
export default ProvidingMainResponsive;
