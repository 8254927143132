import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { cx } from '@utils';
import { MatchTagProps } from './interfaces';
import styles from './style/Tag.css';

type Props =
    MatchTagProps;

export const MatchTag = (props: Props) => {

  const renderTag = useCallback(() => {
    const variants = {
      selected: styles.selected,
      selectable: styles.selectable,
      readonly: styles.readonly,
    };

    const root = cx(styles.root, variants[props.variant], {
      [props.className]: !props.to,
    });

    return (
      <button
        className={root}
        onClick={props.onClick}>
        {props.children || props.text}
        {props.icon || null}
      </button>
    );
  }, [
    props,
  ]);

  if (props.to) {
    return (
      <Link className={props.className} to={props.to}>
        {renderTag()}
      </Link>
    );
  } else {
    return (
      <>{renderTag()}</>
    );
  }

};