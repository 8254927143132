import queryString from 'query-string';
import http from '@/services/http';
import type { Brands } from './interfaces';

export const createBrand = (data: Brands.CreateBrand.Request) => {
  return http.post(`/admin/brands/new`, data)
    .then((res: Brands.CreateBrand.Response) => res);
};

export const doesBrandExist = (data: Brands.DoesBrandExist.Request) => {
  const qs = queryString.stringify({
    name: data.name,
    groupId: data.groupId,
  });
  return http.get(`/admin/brands/existing?${qs}`)
    .then((res: Brands.DoesBrandExist.Response) => res);
};

export const searchBrands = (data: Brands.SearchBrands.Request) => {
  return http.get<Brands.SearchBrands.Response>(`/admin/brands?${queryString.stringify(data)}`);
};

export const getBrandDetails = (data: Brands.GetBrandOverview.Request) => {
  return http.get<Brands.GetBrandOverview.Response>(`/admin/brands/${data.brandId}`);
};

export const updateBrandDetails = ({ brandId, ...data }: Brands.UpdateBrandDetails.Request) => {
  return http.patch(`/admin/brands/${brandId}`, data);
};