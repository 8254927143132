import { ConferenceMediaType, utils as enumUtils } from '@enums';
import { EnumDropDown } from '@/components/DropDown';

type Props = {
  items?: ConferenceMediaType[];
  onSelect: (value: ConferenceMediaType) => void;
  value: ConferenceMediaType;
};

export const ConferenceMediaTypeDropdown = ({
  items = enumUtils.ConferenceMediaType.values(),
  onSelect,
  value,
}: Props) => {

  return (
    <EnumDropDown
      enum={enumUtils.ConferenceMediaType}
      items={items}
      onSelect={onSelect}
      value={value} />
  );
};

export default ConferenceMediaTypeDropdown;