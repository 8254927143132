import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import { ProjectCallsQueryContext, ProjectCallsQueryParamsContext } from './Context';

type Props = {
  children: React.ReactNode;
  enabled:  boolean;
} & IProjectId;

export const ProjectCallsQueryContainer = (props: Props) => {
  const [qp, setQuery] = useQueryParams({
    index: withDefault(NumberParam, 0),
    size: withDefault(NumberParam, 25),
  });

  const query = useQuery([
    `get:projects/calls`,
    props.projectId,
    qp.index,
    qp.size,
  ], () => {
    return api.projects.fetchCalls({
      projectId: props.projectId,
      index: qp.index,
      size: qp.size,
    });
  }, {
    enabled: props.enabled,
    keepPreviousData: true,
  });

  return (
    <ProjectCallsQueryParamsContext.Provider value={[qp, setQuery]}>
      <ProjectCallsQueryContext.Provider value={query}>
        {props.children}
      </ProjectCallsQueryContext.Provider>
    </ProjectCallsQueryParamsContext.Provider>
  );
};

ProjectCallsQueryContainer.displayName = 'Project.Calls.Query.Container';