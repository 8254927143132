import { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as actions from '@actions';
import * as api from '@api';
import * as API from '@api/interfaces';

type Data = API.Profiles.UpdateProfileAvatar.Response;
type Variables = {
  blob: Blob;
};

type Options = UseMutationOptions<Data, AxiosResponse, Variables>;

const selectMyId = (state: Store.State) => state.user.id;

export const useUpdateUserAvatar = (userId: number, options?: Options) => {

  const dispatch = useDispatch();
  const meId = useSelector(selectMyId);

  return useMutation(['update-user-avatar'], ({ blob }: Variables) => {

    return api.profiles.updateProfileAvatar({
      blob,
      userId,
    });
  }, {
    onSuccess: (res, variables, context) => {

      if (meId === userId) {
        dispatch(actions.userInfoChange({
          profile: { pictureUrl: res.pictureUrl },
        }));
      }

      options?.onSuccess(res, variables, context);
    },
    ...options,
  });
};