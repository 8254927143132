import styles from '$admin/shared/style/TextAreaField.css';
import { TextareaAutosize } from '@/components/Textarea';
import { Label, TextLabel } from './Label';

type Props = {
  editing: boolean;
  label: string;
  name: HTMLTextAreaElement['name'];
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  rowsMin?: number;
};

const TextAreaField = (props: Props) => {

  function DisplayField({ label, value }: Pick<Props, 'label' | 'value'>) {
    return (
      <TextLabel
        className={styles.section}
        title={label}
        value={value} />
    );
  }

  return (
    <>
      {!props.editing &&
        <DisplayField
          label={props.label}
          value={props.value} />}

      {props.editing &&
        <Label
          className={styles.section}
          title={props.label}>
          <div className={styles.input}>
            <TextareaAutosize
              className={styles.textarea}
              autoComplete="off"
              name={props.name}
              onChange={props.onChange}
              value={props.value ?? ''}
              minRows={props.rowsMin} />
          </div>
        </Label>}
    </>
  );
};

type TextAreaFieldLabelProps = {
  children: React.ReactElement;
} & Pick<Props, 'label'>;

function TextAreaFieldLabel(props: TextAreaFieldLabelProps) {
  return (
    <Label
      className={styles.section}
      title={props.label}>
      {props.children}
    </Label>
  );
}

type TextAreaFieldValueProps = {
  children: React.ReactNode;
};

function TextAreaFieldValue(props: TextAreaFieldValueProps) {
  return (
    <div className={styles.field}>
      {props.children}
    </div>
  );
}

const defaultProps = {
  editing: false,
  value: '',
};

TextAreaField.defaultProps = defaultProps;
TextAreaField.Label = TextAreaFieldLabel;
TextAreaField.Value = TextAreaFieldValue;

export { TextAreaField };
export default TextAreaField;