import { useCallback } from 'react';
import type { Column, Row } from 'react-table';
import { useTable, useFlexLayout, usePagination } from 'react-table';
import { Card, CardContent, GroupCardHeader } from '@presentation/Main';
import { GroupTable, GroupTableFooter } from '@presentation/GroupTable';
import { GroupTableCell, GroupTableRow } from '@presentation/GroupTable/Body';
import { cx } from '@utils';
import { AnimatedLoader } from '@/components/ActivityIndicator';
import ViewAll from './ViewAll';
import type { TranscriptsTableItem } from './interfaces';
import { useTranscriptsTableColumns } from './hooks';
import { TranscriptRowContainer } from './Container.TranscriptRow';
import styles from './style/Table.css';

type Props = {
  data: TranscriptsTableItem[];
  countText: string;
  pagination?: boolean;
  pageSize: number;
  isLoading: boolean;
  viewAll?: boolean;
};

const initialState = {
  pageIndex: 0,
  pageSize: 25,
};

export default function SearchTableTranscripts({ data, countText, pagination, pageSize, isLoading, viewAll }: Props) {
  const columns = useTranscriptsTableColumns() as Column<TranscriptsTableItem>[];

  const tableInstance = useTable<TranscriptsTableItem>(
    {
      columns,
      data,
      initialState,
    },
    useFlexLayout,
    usePagination,
  );

  const tableClasses = {
    body: styles.body,
    td: styles.td,
    tr: styles.tr,
  };

  const footerClasses = {
    root: styles.footer,
    pageSize: styles.rows,
  };

  const renderRow = useCallback((row: Row<TranscriptsTableItem>) => {
    const { key: rowKey, ...rowProps } = row.getRowProps();

    return (
      <TranscriptRowContainer key={rowKey} data={row.original}>
        <GroupTableRow
          {...rowProps}
          className={cx(rowProps.className, styles.tr)}>
          {row.cells.map(cell => {
            const { key: cellKey, ...cellProps } = cell.getCellProps();
            return (
              <GroupTableCell
                key={cellKey}
                {...cellProps}
                className={cx(cellProps.className, styles.td)}>
                {cell.render('Cell')}
              </GroupTableCell>
            );
          })}
        </GroupTableRow>
      </TranscriptRowContainer>
    );
  }, []);

  return (
    <Card className={styles.card}>
      <GroupCardHeader className={styles.header}>
        <div className={styles.left}>
          <div>Transcripts <span className={styles.count}>{countText}</span></div>
        </div>
        {viewAll && (
          <div className={styles.right}>
            <ViewAll tab="transcripts" />
          </div>)}
      </GroupCardHeader>
      <CardContent className={styles.content} fluid>
        {!isLoading && !!data.length &&
          <GroupTable
            classes={tableClasses}
            instance={tableInstance}
            renderRow={renderRow} />}
        {isLoading && (<AnimatedLoader className={styles.loading} />)}
        {!isLoading && pagination && !!data.length &&
          <GroupTableFooter
            classes={footerClasses}
            instance={tableInstance} />}
      </CardContent>
    </Card>
  );
}

export { SearchTableTranscripts };