import { useCallback } from 'react';
import { LeftBarContext } from './context';
import { useMultiWindowLeftBarState } from './hooks';

type Props = ICallId & ChildrenProps;

export const LeftBarContainer = ({ callId, children }: Props) => {
  const [state, dispatch] = useMultiWindowLeftBarState();

  const setOpen = useCallback((open: boolean) => {
    dispatch({
      type: 'bar-status-updated',
      status: open ? 'shown' : 'hidden',
    });
  }, [dispatch]);

  const setZoomValue = useCallback((value: number) => {
    dispatch({
      type: 'zoom-value-updated',
      value,
    });
  }, [dispatch]);

  return (
    <LeftBarContext.Provider value={{ open: state.barStatus === 'shown', setOpen, callId, zoomValue: state.zoomValue, setZoomValue, state, dispatch }}>
      {children}
    </LeftBarContext.Provider>
  );
};