import { memo, useCallback } from 'react';
import fscreen from 'fscreen';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { useLiveRoomScreen } from '@containers/Conference';
import { Tooltip } from '@presentation/Tooltip';
import { cx } from '@utils';
import styles from './style/FSToggle.css';

type Props = {
  className?: string;
  children?: React.ReactNode;
  visible?: boolean;
};

export const FSToggle = memo(({ className, children, visible = true }: Props) => {
  const { toggle } = useLiveRoomScreen();
  const supported = !fscreen.fullscreenEnabled;
  const handleClick = useCallback(() => {
    toggle();
  }, [toggle]);

  if (!visible) return null;

  return (
    <div
      className={cx(styles.screen, className)}
      onClick={!supported ? handleClick : undefined}>
      {children ?? (
        <ScreenIcon supported={supported} />
      )}
    </div>
  );
});

const ScreenIcon = ({ supported }: { supported: boolean }) => {
  const { isFullscreen } = useLiveRoomScreen();

  const Icon = isFullscreen
    ? (
      <div className={styles.iconScreen}>
        <FullscreenExitIcon />
      </div>
    )
    : (
      <div className={styles.iconScreenOff}>
        <FullscreenIcon />
      </div>
    );

  let tooltip: string = null;

  if (supported) {
    tooltip = 'Full screen is not supported with this browser';
  }

  if (tooltip) {
    return (
      <Tooltip
        style={{ cursor: supported ? 'not-allowed' : 'pointer' }}
        title={tooltip}
        placement="top">
        {Icon}
      </Tooltip>
    );
  }

  return Icon;
};