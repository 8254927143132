import { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import { pathname } from '@consts';
import { useSelectUser } from '@containers/Store';
import { ButtonActivityIndicator } from '@presentation';
import ActivityIndicator from '@/components/ActivityIndicator';
import { Logo } from '@/components/Branding';
import Toast from '@/components/Toast';
import styles from './style/ReverifyEmail.css';

type Props = unknown;

export const ReverifyEmail = (props: Props) => {
  const params = useParams<{ token: string }>();
  const history = useHistory();
  const user = useSelectUser();
  const dispatch = useDispatch();

  const refresh = useQuery({
    queryKey: [
      `post:users/verify/emails/refresh`,
      user.id,
    ],
    queryFn: () => {
      return api.users.verify.refreshEmailVerification({
        userId: user.id,
      })
      .then(data => {
        const action = actions.userInfoChange({
          meta: {
            emailReverificationRequired: data.emailReverificationRequired,
            isEmailVerified: data.emailVerified,
          },
        });

        dispatch(action);

        return data;
      });
    },
    enabled: user.meta.emailReverificationRequired,
  });

  const reverify = useMutation({
    mutationKey: [
      `post:users/verify/emails/reverify`,
      params?.token,
      user.id,
    ],
    mutationFn: ({ token }: { token: string }) => {
      return api.users.verify.reverifyEmailToken({
        token,
        userId: user.id,
      })
      .then(data => {
        const action = actions.userInfoChange({
          meta: {
            emailReverificationRequired: data.emailReverificationRequired,
            isEmailVerified: data.isEmailVerified,
          },
        });
        dispatch(action);
      });
    },
  });

  const resend = useMutation({
    mutationKey: [
      `post:users/verify/emails/reverify/resend`,
      user.id,
    ],
    mutationFn: () => {
      return api.users.verify.resendEmailReverification({
        userId: user.id,
      });
    },
  });

  const handleResendEmail = useCallback(() => {
    resend.mutateAsync()
    .then(() => {
      Toast.success({
        body: ``,
        title: `Email resent`,
      });
    })
    .catch(() => {
      Toast.error({
        body: ``,
        title: `There was an error resending the email.`,
      });
    });
  }, [resend]);

  useEffect(() => {
    if (params?.token) {
      reverify.mutate({
        token: params.token,
      });
    }
  }, [params?.token]);

  useEffect(() => {
    if (!user.meta.emailReverificationRequired) {
      history.replace({
        pathname: pathname.Home,
      });
    }
  }, [
    history,
    user.meta.emailReverificationRequired,
  ]);

  if (reverify.isLoading || refresh.isInitialLoading) {
    return (
      <ActivityIndicator show />
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <Logo.HorizonalTrinity className={styles.logo} />
        </div>
        <div className={styles.main}>
          <div className={styles.row}>
            <div className={styles.title}>{copy.title}</div>
          </div>
          <div className={styles.row}>
            {copy.message}
          </div>
          <div className={styles.row}>
            {copy.notice}<span className={styles.email}>{user.contact.email}</span>{`.`}
          </div>
          <div className={styles.row}>
            {copy.action}
          </div>
          <div className={styles.row}>
            {copy.info1}
            <span
              className={styles.link}
              onClick={handleResendEmail}>
              click here
            </span>
            {copy.info2}
          </div>
          <div className={styles.footer}>
            <ButtonActivityIndicator
              className={styles.btn}
              loading={refresh.isFetching}
              onClick={() => refresh.refetch()}>
              Refresh Page
            </ButtonActivityIndicator>
          </div>
        </div>
      </div>
    </div>
  );
};

const copy = {
  action: `Click the link in the email to confirm your account.`,
  info1: `If you do not receive a verification email after 5 minutes, check your spam folder or`,
  info2: `to resend the email.`,
  message: `As an account security measure, we periodically ask clients to re-confirm their account access.`,
  notice: `We have sent an email to`,
  title: `Insight EDGE Account Confirmation`,
};