import { path } from '@consts';
import { LinkLabeledIcon } from '$website/components';
import bg1 from 'static/images/website/collaboration-tools/q2q-s.png';
import bg2 from 'static/images/website/collaboration-tools/q2q-m.png';
import bg3 from 'static/images/website/collaboration-tools/q2q-l.png';
import styles from './style/ResearchWorkflow.css';

type Props = unknown;

export const ResearchWorkflow = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.content}>
          <div className={styles.main}>

            <div className={styles.graphic}>
              <picture>
                <source
                  media="(min-width: 1100px)"
                  srcSet={bg3} />
                <source
                  media="(min-width: 800px)"
                  srcSet={bg2} />
                <img
                  alt="quantitative and qualitative research"
                  className={styles.img}
                  src={bg1} />
              </picture>
            </div>

            <div className={styles.info}>
              <h2 className={styles.title}>Upgrade your quantitative and qualitative research into a single workflow</h2>
              <div className={styles.subtitle}>Click into datapoints to see full respondent profiles and understand outliers in your data through follow up interviews.</div>
              <LinkLabeledIcon to={path.Website.Quant2Qual}>
                Learn More
              </LinkLabeledIcon>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

ResearchWorkflow.displayName = 'CollaborationTools.ResearchWorkflow';