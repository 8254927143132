import { getLocationFor } from '@utils';
import { MemberBaseballCard } from '@/components/BaseballCard';
import { MaybeLink } from '@/components/MaybeLink';
import { UserAvatar } from '@/components/UserAvatar';
import type { TableCellItem } from './interfaces';
import styles from './style/Table.css';

type Props = TableCellItem;

export const Cell = (props: Props) => {
  if (!props.row.original.respondent?.statusId) {
    return (
      <div className={styles.name}>
        <div className={styles.avatar}>
          <UserAvatar
            pictureUrl={props.row.original.respondent.pictureUrl}
            size={25} />
        </div>
        <div className={styles.trunc}>
          {props.row.original.respondent.name}
        </div>
      </div>
    );
  }

  const to = getLocationFor.user.profile({
    slug: String(props.row.original.respondent.id),
  });

  return (
    <MemberBaseballCard.Client
      name={props.row.original.respondent.name}
      pictureUrl={props.row.original.respondent.pictureUrl}
      projectId={props.row.original.project.id}
      userId={props.row.original.respondent.id}>
      <MaybeLink to={to}>
        <div className={styles.name}>
          <div className={styles.avatar}>
            <UserAvatar
              pictureUrl={props.row.original.respondent.pictureUrl}
              size={25} />
          </div>
          <div className={styles.link}>
            {props.row.original.respondent.name}
          </div>
        </div>
      </MaybeLink>
    </MemberBaseballCard.Client>
  );
};

Cell.displayName = 'Column.Name.Cell';