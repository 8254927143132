import { useContext } from 'react';
import { ProjectSurveyResponsesLoadingContext } from '@containers/SurveyResponses/Context';

const useProjectSurveyResponsesLoading = () => {
  const context = useContext(ProjectSurveyResponsesLoadingContext);

  return context;
};

export { useProjectSurveyResponsesLoading };
export default useProjectSurveyResponsesLoading;