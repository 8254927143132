import { useCallback, useContext } from 'react';
import { useEditorState } from '@remirror/react';
import { TranscriptFocusedTagContext } from '@containers/Transcript.Tagging/Context';
import { useTranscriptTagsContext } from '@containers/Transcript.Tagging/hooks';
import type { HighlightWithText } from '@/components/Transcript/interfaces.extension.highlight';
import { useChainedCommands } from './useCommands';
import { useMapDocPosToTimePos } from './useMapPositions';

export const useAddTag = (highlight: HighlightWithText) => {
  const [_, dispatch] = useTranscriptTagsContext();
  const [__, setFocusedTag] = useContext(TranscriptFocusedTagContext);
  const mapDocPosToTimePos = useMapDocPosToTimePos();
  const state = useEditorState();

  const commands = useChainedCommands();

  return useCallback(() => {
    const { start: fromTs, end: toTs } = mapDocPosToTimePos({
      from: highlight.from,
      to: highlight.to,
      state,
    });

    dispatch({
      type: 'temp-tag-added',
      fromTs,
      toTs,
      highlightIdentifier: highlight.id,
    });
    setFocusedTag(highlight.id);
    commands
      .blurHighlight()
      .addHighlight(highlight)
      .focusHighlight(highlight.id, false).run();
    //Need to set a proper default color,
    //Need to add proper event handlers for discarding it from the state
    //Need to change the display to not show an empty tag
  }, [state, highlight, mapDocPosToTimePos, dispatch, setFocusedTag, commands]);
};