import extend from '@enums/extend';

export enum LeadStatus {
  Active = 1,
  Inactive = 2,
  Target = 3,
  Retired = 4,
  DoNotContact = 5,
  DoNotContactQual = 6,
  DoNotContactQuant = 7,
  DoNotContactSpecific = 8,
}

const map = new Map([
  [LeadStatus.Active,       { name: 'Active' }],
  [LeadStatus.Inactive,     { name: 'Inactive' }],
  [LeadStatus.Target,       { name: 'Target' }],
  [LeadStatus.Retired,      { name: 'Retired' }],
  [LeadStatus.DoNotContact, { name: 'Do Not Contact (Complete)' }],
  [LeadStatus.DoNotContactQual, { name: 'Do Not Contact (Qual)' }],
  [LeadStatus.DoNotContactQuant, { name: 'Do Not Contact (Quant)' }],
  [LeadStatus.DoNotContactSpecific, { name: 'Do Not Contact (Specific)' }],
]);

export default {
  LeadStatus: extend(map),
};