import type { SearchNetSuiteProject } from '$admin/api/interfaces/projects';
import { NetSuiteProjects } from '$admin/components/Autocomplete';
import { Field } from './Overview.Field';

type Props = {
  label: string;
  onChange: (value: Item) => unknown;
  tooltip?: string;
  value: Item;
};

export const Netsuite = (props: Props) => {

  return (
    <Field label={props.label}>
      <NetSuiteProjects
        onSelect={props.onChange}
        selected={props.value} />
    </Field>
  );
};

Netsuite.displayName = 'Project.Creation.Details.Field.Netsuite';

type Item = SearchNetSuiteProject.Response[number];