import { SurveyItemType } from '@/enums';
import { SurveyMessage } from '@/types/survey';
import { SurveyBuilder, SurveyMessagesBuilder, SurveySectionsBuilder } from '../interfaces';
import { generateNewMessage } from './defaults';

export * from './state.messages.template';

export function messageAdded(state: State, action: SurveyMessagesBuilder.MessageAdded.Action): State {

  const message = generateNewMessage(action.messageIdentifier);

  return state.concat(message);

}

export function messageRemoved(state: State, action: SurveyMessagesBuilder.MessageRemoved.Action): State {

  return state.filter(f => f.identifier !== action.messageIdentifier);
}

export function messageValueUpdated(state: State, action: SurveyMessagesBuilder.MessageValueUpdated.Action): State {

  return state.reduce((acc, x) => {

    return x.identifier === action.messageIdentifier
      ? acc.concat({
        ...x,
        value: action.value,
      }) : acc.concat(x);

  }, []);
}

export function messageMetadataUpdated(state: State, action: SurveyMessagesBuilder.MessageMetadataUpdated.Action): State {

  return state.reduce((acc, x) => {

    return x.identifier === action.messageIdentifier
      ? acc.concat({
        ...x,
        metadata: {
          ...x.metadata,
          ...action.metadata,
        },
      }) : acc.concat(x);

  }, []);
}

export function sectionRemoved(state: SurveyBuilder.State['survey'], action: SurveySectionsBuilder.RemoveSection.Action): State {

  const itemsToDelete = state.items.filter(f => f.section.identifier === action.identifier);

  return state.messages.filter(f => !itemsToDelete.some(s => s.type === SurveyItemType.Message && s.source.identifier === f.identifier));
}

type State = SurveyBuilder.State['survey']['messages'];