import { Checkbox } from '@/components/Checkbox';
import * as Table from '@/components/Table';
import styles from './style/Draggable.css';

type Props = {
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Cell = (props: Props) => {
  return (
    <Table.Body.TD className={styles.summary}>
      <Checkbox
        checked={props.checked}
        onChange={props.onChange} />
    </Table.Body.TD>
  );
};

Cell.displayName = 'Column.Summary.Cell';

export const Header = (props: unknown) => {
  return (
    <Table.Header.TH className={styles.summary}>
      Include Summary
    </Table.Header.TH>
  );
};

Header.displayName = 'Column.Summary.Header';