import { useContext } from 'react';
import { createNamedContext } from '@utils';
import type {
  ProjectConsultantReferralSourcingContextValue,
  ProjectInviteContextValue,
  ProjectInviteValidationContextValue,
  ProjectOnboardingContextValue,
  ProjectResearchConsentContextValue,
  ProjectScreensContextValue,
} from './interfaces';

export const ProjectOnboardingContext = createNamedContext<ProjectOnboardingContextValue | undefined>(undefined, 'ProjectOnboarding.Context');
export const ProjectConsultantReferralSourcingContext = createNamedContext<ProjectConsultantReferralSourcingContextValue | undefined>(undefined, 'ProjectOnboarding.ConsultantReferralSourcing.Context');
export const ProjectResearchConsentContext = createNamedContext<ProjectResearchConsentContextValue>(undefined, 'ProjectOnboarding.ResearchConsent.Context');
export const ProjectScreensContext = createNamedContext<ProjectScreensContextValue>(undefined, 'Project.Screens.Context');
export const ProjectInviteValidationContext = createNamedContext<ProjectInviteValidationContextValue>(undefined, 'Project.InviteValidation.Context');
export const ProjectInviteContext = createNamedContext<ProjectInviteContextValue>(undefined, 'Project.Invite.Context');
export const SurveyProjectContext = createNamedContext<{
  id: number;
  name: string;
}>(undefined, 'SurveyProject.Context');

export const useProjectInviteContext = () => useContext(ProjectInviteContext);