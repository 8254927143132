import { AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as API from '@api/interfaces';
import * as api from '@api';

type Data = API.Projects.FetchRepeatSurveyResponses.Response;
type QueryKey = [string, Props];
type Props = {
  limit?: number;
  projectParentId: number;
  questionId?: number;
} & ISurveyId;

export function useFetchRepeatSurveyResponses<TData = Data>(
  props: Props,
  options?: UseQueryOptions<Data, AxiosResponse, TData, QueryKey>
) {

  return useQuery(keyUseFetchRepeatSurveyResponses(props), ({ queryKey }) => {
    const params = queryKey[1] as Props;
    return api.projects.surveys.fetchRepeatResponses(params);
  }, options);
}

export function keyUseFetchRepeatSurveyResponses(props: Props) {
  return ['repeat-project-survey-responses', props];
}

export default useFetchRepeatSurveyResponses;