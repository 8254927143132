import type { ComponentType } from 'react';
import { useCallback, useMemo, useState } from 'react';
import type { NodeViewComponentProps } from '@remirror/react';
import type { FindAndReplaceNodeMatches, FindAndReplaceGroupedLabelMatches } from '@/containers/Survey/utils/rich-text.find-and-replace';
import * as $far from '@/containers/Survey/utils/rich-text.find-and-replace';
import { useIsEditingSurvey, useRichTextEditorRegistryEditor, useSubmitSurveyDraft, useSurveyBuilderOptions, useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import { SurveyQuestionType } from '@/enums';
import type { SurveyRichText } from '@/types/survey.rich-text';
import type { MatrixRangeQuestion, MatrixSliderQuestion, MultiTextboxQuestion, SurveyQuestionMatrixRow } from '@/types/survey';
import { convertToSurveyRichText, parseSurveyRichText } from '@/containers/Survey/utils';
import { TemplateItemLabel } from '@/components/Survey.Templates/Label.Base';
import { TemplateLabelColor } from '@/components/Survey.Templates/Label.colors';
import { BasicRichText } from '@/components/Survey.RichText/ReadOnly.Basic';
import { useUpdateFindAndReplaceNode } from './hooks/useUpdateFindAndReplaceNode';
import { useRemoveFindAndReplaceNode } from './hooks/useRemoveFindAndReplaceNode';
import { useFindAndReplaceEditModal } from './Node.FindAndReplace.Modal.Edit';
import { useFindAndReplaceInsertModal } from './Node.FindAndReplace.Modal.Insert';
import { useUploadSurveyImages } from './hooks/useUploadImages';
import { getFindAndReplaceRichTextIdentifier } from './utils';
import styles from './style/Node.FindAndReplace.css';

export const FindAndReplace: ComponentType<NodeViewComponentProps> = props => {
  const ctx = useSurveyBuilderState();
  const options = useSurveyBuilderOptions();

  return ctx
    ? options.isModule
      ? <ModuleBuilderNode {...props} />
      : <BuilderNode {...props} />
    : <Node {...props} />;
};

function ModuleBuilderNode({ node }: NodeViewComponentProps) {
  const editing = useIsEditingSurvey();
  const [toggleModal, Modal] = useFindAndReplaceEditModal();
  const updateNode = useUpdateFindAndReplaceNode();
  const removeNode = useRemoveFindAndReplaceNode();

  const attrs = (node.attrs as SurveyRichText.FindAndReplaceNode['attrs']);

  const handleSubmit = useCallback((label: string, groupingId: string) => {
    updateNode({
      ...attrs,
      groupingId: $far.getFindAndReplaceGroupingId(groupingId, label),
      label: $far.getFindAndReplaceLabel(label, 1, 1),
      value: $far.getFindAndReplaceLabel(label, 1, 1),
    });
    toggleModal();
  }, [attrs, updateNode, toggleModal]);

  const handleRemove = useCallback(() => {
    removeNode();
    toggleModal();
  }, [removeNode, toggleModal]);

  const handleClick = useCallback(() => {
    toggleModal();
  }, [toggleModal]);

  return (
    <>
      <TemplateItemLabel
        disabled={!editing}
        label={attrs.label}
        onClick={handleClick} />
      <Modal
        label={$far.getFindAndReplaceLabelToEdit(attrs.label)}
        groupingId={attrs.groupingId}
        onSubmit={handleSubmit}
        onRemove={handleRemove} />
    </>
  );
}

function BuilderNode({ node }: NodeViewComponentProps) {
  const submitDraft = useSubmitSurveyDraft();

  const [toggleModal, InsertModal] = useFindAndReplaceInsertModal();
  const { getEditorByIdentifier } = useRichTextEditorRegistryEditor();

  const [state, dispatch] = useSurveyBuilderState();

  const [modalState, setModalState] = useState<{ matches: FindAndReplaceNodeMatches; grouped: FindAndReplaceGroupedLabelMatches }>({
    matches: { questions: [], messages: [] },
    grouped: { labels: [], questions: {}, messages: {} },
  });

  const attrs = (node.attrs as SurveyRichText.FindAndReplaceNode['attrs']);

  const uploadSurveyImages = useUploadSurveyImages();

  const handleSubmit = useCallback(async (items: Array<{ label: string; value: string | SurveyRichText.RichTextValue }>) => {
    for (const item of items) {
      const sourceEditor = getEditorByIdentifier(getFindAndReplaceRichTextIdentifier(item.label));

      const { value, updated } = await uploadSurveyImages(sourceEditor);

      const questionNodes = modalState.grouped.questions[item.label] || [];
      for (const node of questionNodes) {
        const matchQuestion = modalState.matches.questions[node.matchIndex];
        const question = state.survey.questions.find(q => q.base.identifier === matchQuestion.questionIdentifier);

        if (matchQuestion.matches.find(m => m.node.attrs.identifier === node.node.attrs.identifier)) {
          const updatedValue = $far.updateRichTextValueNodes(question.value, node, value);
          dispatch({
            type: 'update-question-value',
            identifier: question.base.identifier,
            value: updatedValue,
          });
          const editor = getEditorByIdentifier(question.base.identifier);
          editor.getContext().setContent(updatedValue);
        }

        for (const matchedSetting of matchQuestion.settings) {
          if (matchedSetting.matches.find(m => m.node.attrs.identifier === node.node.attrs.identifier)) {
            if (matchedSetting.type === SurveyQuestionType.MultiTextbox) {
              const settings = (question.settings as MultiTextboxQuestion.Settings);
              const settingsValue = settings[matchedSetting.key];
              const updatedValue = $far.updateRichTextValueNodes(settingsValue, node, value);
              dispatch({
                type: 'update-question-settings',
                questionIdentifier: question.base.identifier,
                settings: {
                  ...settings,
                  [matchedSetting.key]: updatedValue,
                },
              });
              const editor = getEditorByIdentifier(matchedSetting.identifier);
              editor.getContext().setContent(updatedValue);
            }
            else if (matchedSetting.type === SurveyQuestionType.Sliders) {
              const settings = (question.settings as MatrixSliderQuestion.Settings);
              const settingsValue = settings['slider'][matchedSetting.key];
              const updatedValue = $far.updateRichTextValueNodes(settingsValue, node, value);
              dispatch({
                type: 'update-question-settings',
                questionIdentifier: question.base.identifier,
                settings: {
                  ...settings,
                  slider: {
                    ...settings.slider,
                    [matchedSetting.key]: updatedValue,
                  },
                },
              });
              const editor = getEditorByIdentifier(matchedSetting.identifier);
              editor.getContext().setContent(updatedValue);
            }
            else if (matchedSetting.type === SurveyQuestionType.MatrixRange) {
              const settings = (question.settings as MatrixRangeQuestion.Settings);
              const settingsValue = settings[matchedSetting.key];
              const updatedValue = $far.updateRichTextValueNodes(settingsValue, node, value);
              dispatch({
                type: 'update-question-settings',
                questionIdentifier: question.base.identifier,
                settings: {
                  ...settings,
                  [matchedSetting.key]: updatedValue,
                },
              });
              const editor = getEditorByIdentifier(matchedSetting.identifier);
              editor.getContext().setContent(updatedValue);
            }
          }
        }

        for (const matchOption of matchQuestion.options) {
          if (matchOption.matches.find(m => m.node.attrs.identifier === node.node.attrs.identifier)) {
            const option = question.options.find(o => o.base.identifier === matchOption.optionIdentifier);
            const updatedValue = $far.updateRichTextValueNodes(option.value, node, value);
            dispatch({
              type: 'update-question-option-value',
              questionIdentifier: question.base.identifier,
              option: {
                identifier: option.base.identifier,
              },
              value: updatedValue,
            });
            const editor = getEditorByIdentifier(option.base.identifier);
            editor.getContext().setContent(updatedValue);
          }
        }

        for (const matchRow of matchQuestion.rows) {
          const row = (question.matrixRows as SurveyQuestionMatrixRow[]).find(o => o.base.identifier === matchRow.rowIdentifier);

          if (matchRow.matches.find(m => m.node.attrs.identifier === node.node.attrs.identifier)) {
            const updatedValue = $far.updateRichTextValueNodes(row.value, node, value);
            dispatch({
              type: 'update-question-row-value',
              questionIdentifier: question.base.identifier,
              row: {
                identifier: row.base.identifier,
              },
              value: updatedValue,
            });
            const editor = getEditorByIdentifier(row.base.identifier);
            editor.getContext().setContent(updatedValue);
          }

          for (const matchedRowMd of matchRow.metadata) {
            if (matchedRowMd.matches.find(m => m.node.attrs.identifier === node.node.attrs.identifier)) {
              if (matchedRowMd.type === SurveyQuestionType.MatrixRange) {
                const metadataValue = (row.metadata as MatrixRangeQuestion.RowMetadata)[matchedRowMd.key];
                const updatedValue = $far.updateRichTextValueNodes(metadataValue, node, value);
                dispatch({
                  type: 'matrix-range-row-metadata-updated',
                  payload: {
                    questionIdentifier: question.base.identifier,
                    row: {
                      identifier: row.base.identifier,
                    },
                    field: matchedRowMd.key,
                    value: updatedValue,
                  },
                });
                const editor = getEditorByIdentifier(matchedRowMd.identifier);
                editor.getContext().setContent(updatedValue);
              }
            }
          }
        }
      }

      const messageNodes = modalState.grouped.messages[item.label] || [];
      for (const node of messageNodes) {
        const matchMessage = modalState.matches.messages[node.matchIndex];
        const message = state.survey.messages.find(m => m.identifier === matchMessage.messageIdentifier);
        const updatedValue = $far.updateRichTextValueNodes(message.value, node, value);

        dispatch({
          type: 'message-value-updated',
          messageIdentifier: message.identifier,
          value: updatedValue,
        });

        const editor = getEditorByIdentifier(message.identifier);
        editor.getContext().setContent(updatedValue);
      }
    }
    submitDraft();
    toggleModal();
  }, [
    dispatch,
    state.survey.questions,
    state.survey.messages,
    modalState.grouped,
    modalState.matches,
    toggleModal,
    getEditorByIdentifier,
    submitDraft,
    uploadSurveyImages,
  ]);

  const handleClick = useCallback(() => {
    const matches = $far.searchFindAndReplaceNodes(state, attrs.groupingId);
    const grouped = $far.groupLabelsFromMatches(matches);
    setModalState({ matches, grouped });
    toggleModal();
  }, [state, attrs.groupingId, toggleModal]);

  const labelColor = useMemo(() => attrs.touched ? TemplateLabelColor.One : TemplateLabelColor.Four, [attrs.touched]);
  const displayLabel = useMemo(() => {
    if (attrs.touched) {
      if (typeof attrs.value === 'string') {
        return attrs.value;
      } else {
        return parseSurveyRichText(attrs.value);
      }
    } else {
      return attrs.label;
    }
  }, [attrs.touched, attrs.value, attrs.label]);

  const items = useMemo(() => {
    return modalState.grouped.labels.map(label => {
      const n = $far.getFirstNodeFromLabel(modalState.grouped, label);
      const value = n?.attrs?.touched ? convertToSurveyRichText(n.attrs.value) : convertToSurveyRichText('');
      const minOrdinal = Math.min(...(modalState.grouped.questions[label]?.map(g => g.questionOrdinal) || []));

      return {
        label,
        value,
        canRemove: false,
        minOrdinal,
      };
    });
  }, [modalState.grouped]);

  return (
    <>
      <TemplateItemLabel
        color={labelColor}
        disabled={false}
        label={displayLabel}
        onClick={handleClick} />
      <InsertModal
        items={items}
        onSubmit={handleSubmit} />
    </>
  );
}

function Node({ node }: NodeViewComponentProps) {
  const attrs = (node.attrs as SurveyRichText.FindAndReplaceNode['attrs']);
  if (typeof attrs.value === 'string') {
    return <span>{attrs.value}</span>;
  } else {
    return <BasicRichText className={styles.inlineEditor} value={attrs.value} />;
  }
}

export default FindAndReplace;