import { useCallback } from 'react';
import { ConjointAnalysisQuestion } from '@/types';
import { Tooltip } from '@presentation/Tooltip';
import { cx } from '@utils';
import styles from './style/ConjointAnalysis.Chart.Attribute.css';

type Props = {
  attribute: ConjointAnalysisQuestion.Attribute;
  className?: string;
  levelColors: { [levelId: number]: string; };
  maxUtility: number;
  onLevelClick: (levelId: number) => () => unknown;
  responses: ConjointAnalysisQuestion.Aggregate.Data;
};

export const ConjointAnalysisChartAttribute = ({
  attribute,
  className,
  levelColors,
  maxUtility,
  onLevelClick,
  responses,
}: Props) => {

  const renderLevel = useCallback((level: ConjointAnalysisQuestion.Level) => {
    const backgroundColor = levelColors[level.base.id];
    const utility = responses.levels[level.base.id].utility;
    const width = `${(utility / maxUtility) * 100}%`;

    return (
      <div key={level.base.id} className={styles.level}>
        <Tooltip title={level.value} arrow={false} placement="bottom-start">
          <div className={styles.label}>{level.value}</div>
        </Tooltip>
        <div className={styles.barRoot}>
          <div className={styles.barWrapper}>
            {!!utility &&
              <div
                className={styles.bar}
                onClick={onLevelClick(level.base.id)}
                style={{ backgroundColor, width }} />
            }
            <div className={styles.utility}>{utility}</div>
          </div>
        </div>
      </div>
    );
  }, [
    levelColors,
    onLevelClick,
    maxUtility,
    responses.levels,
  ]);

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.title}>
        {attribute.value} <span className={styles.subtitle}>({responses.attributes[attribute.base.id].importance}%)</span>
      </div>
      {attribute.levels.map(renderLevel)}
    </div>
  );
};

export default ConjointAnalysisChartAttribute;