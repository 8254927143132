export const settings = {
  chat: {
    hideWhenOffline: false,
    suppress: false,
    profileCard: {
      avatar: true,
      rating: false,
    },
    title: 'Sentiment Support',
    concierge: {
      name: 'Having a problem?',
      title: {
        '*': 'Let us know and we can help!',
      },
    },
  },
  position: {
    horizontal: 'right',
    vertical: 'bottom',
  },
  offset: {
    horizontal: '0px',
    vertical: `0px`,
  },
  navigation: {
    popoutButton: {
      enabled: false,
    },
  },
  launcher: {
    label: {
      '*': 'Help',
    },
    chatLabel: {
      '*': 'Help',
    },
  },
  color: {
    theme: '#4C4C4C',
  },
  zIndex: 6,
  helpCenter: { suppress: true },
  talk: { suppress: true },
  answerBot: { suppress: true },
};