import SurveyResponseAnswer from './Answer';
import SurveyResponseQuestionText from './QuestionText';
import {
  EditableSurveyResponseProps,
  SurveyResponseItemProps,
  SurveyResponseProps,
} from './interfaces';
import EditableSurveyResponse from './EditableResponse';
import styles from './style/Compliance.css';

export const ComplianceSurveyResponseItem = ({ item }: SurveyResponseItemProps) => {
  return (
    <div className={styles.question}>
      <SurveyResponseQuestionText
        item={item.question} />
      <SurveyResponseAnswer
        className={styles.answer}
        item={item} />
    </div>
  );
};

export const EditableComplianceSurveyResponse = (props: Omit<EditableSurveyResponseProps, 'QuestionComponent'>) => {
  return (
    <EditableSurveyResponse
      {...props}
      QuestionComponent={ComplianceSurveyResponseItem} />
  );
};

export const ComplianceSurveyResponse = ({ response }: SurveyResponseProps) => {
  return (
    <>
      {response.items.map(m => (
        <ComplianceSurveyResponseItem
          key={m.question.id}
          item={m} />
      ))}
    </>
  );
};