import { useQueryDownloader } from '@/containers/QueryDownloader';
import * as $api from '@/services/api/brand-insights';

type Params = {
  chatInstanceId: number;
};

export const useDownloadChatInstance = (params: Params) => {
  return useQueryDownloader({
    queryKey: [
      `brand-insights:chat:download`,
      params.chatInstanceId,
    ],
    queryFn: ({ queryKey: [, chatInstanceId] }) => {
      return $api.downloadChatInstance({ chatInstanceId });
    },
    meta: {
      downloader: true,
    },
    downloaderOptions: {
      onResponse: res => ({
        filename: res.filename,
        type: 'blob',
        value: res.blob,
      }),
    },
  });
};