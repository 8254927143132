import { useCallback, useContext } from 'react';
import type { ClickMarkEventHandler } from '@remirror/extension-events';
import { useEditorEvent } from '@remirror/react';
import type { Confidence } from '@/types/transcribe.rich-text';
import { MarkType } from '@/types/transcribe.rich-text';
import { TranscriptConfidenceContext } from '@containers/Transcript/context';
import { useHelpers } from './useHelpers';

export const useConfidenceEvent = () => {

  const ctx = useContext(TranscriptConfidenceContext);
  const { getConfidenceThreshold, isConfidenceEnabled } = useHelpers();

  const enabled = isConfidenceEnabled();

  const threshold = getConfidenceThreshold();
  const handleClick: ClickMarkEventHandler = useCallback((event, state) => {

    if (!ctx || !enabled) return;

    const markRange = state.getMark(MarkType.Confidence);

    if (!markRange) {
      return false;
    }

    const attrs = markRange.mark.attrs as Confidence.Attributes;

    if (attrs.c < threshold) {
      ctx.setMark(markRange);
    }

    return false;
  }, [ctx, threshold, enabled]);

  useEditorEvent('clickMark', handleClick);

};