import { AddItemButton } from '@/presentation/AddItemButton';
import { cx } from '@utils';
import { SurveyTemplate } from '@/types/survey';
import { Input } from '@/components/Input';
import { TextareaAutosize } from '@/components/Textarea';
import { MinusCircle } from '@/components/icons';
import styles from './style/Input.MessageCategories.css';

type Props = {
  className?: string;
  addCategory: () => void;
  removeCategory: (identifier: string) => () => void;
  updateCategoryName: (identifier: string) => (value: string) => void;
  updateCategoryLabel: (identifier: string) => (value: string) => void;
  addMessage: (categoryIdentifier: string) => () => void;
  removeMessage: (categoryIdentifier: string) => (messageIdentifier: string) => () => void;
  updateMessageValue: (categoryIdentifier: string) => (messageIdentifier: string) => (value: string) => void;
  value: SurveyTemplate.MessageTesting.Category[];
};

export const MessageCategoriesInput = (props: Props) => {
  return (
    <div className={cx(styles.root, props.className)}>
      {props.value.map(category => (
        <CategoryInput
          key={category.identifier}
          value={category}
          addMessage={props.addMessage(category.identifier)}
          removeMessage={props.removeMessage(category.identifier)}
          updateMessageValue={props.updateMessageValue(category.identifier)}
          updateCategoryName={props.updateCategoryName(category.identifier)}
          updateCategoryLabel={props.updateCategoryLabel(category.identifier)}
          removeCategory={props.removeCategory(category.identifier)} />
      ))}
      <AddItemButton
        className={styles.add}
        textClassName={styles.bold}
        onClick={props.addCategory}
        label="Add Category" />
    </div>
  );
};

type InputProps = {
  removeCategory: () => void;
  updateCategoryName: (value: string) => void;
  updateCategoryLabel: (value: string) => void;
  addMessage: () => void;
  removeMessage: (messageIdentifier: string) => () => void;
  updateMessageValue: (messageIdentifier: string) => (value: string) => void;
  value: SurveyTemplate.MessageTesting.Category;
};

const CategoryInput = (props: InputProps) => {

  return (
    <div className={styles.category}>
      <div className={styles.row}>
        <div className={styles.name}>
          <Input
            className={styles.input}
            placeholder="Title"
            onChange={e => props.updateCategoryName(e.target.value)}
            value={props.value.name} />
        </div>
        <div className={styles.label}>
          <Input
            className={styles.input}
            placeholder="Label"
            onChange={e => props.updateCategoryLabel(e.target.value)}
            value={props.value.label} />
        </div>
        <MinusCircle
          className={styles.remove}
          onClick={props.removeCategory} />
      </div>
      {props.value.messages.map(message => (
        <div key={message.id} className={styles.row}>
          <div className={styles.message}>
            <TextareaAutosize
              placeholder="Enter message"
              onChange={e => props.updateMessageValue(message.id)(e.target.value)}
              value={message.value} />
          </div>
          <MinusCircle
            className={styles.remove}
            onClick={props.removeMessage(message.id)} />
        </div>
      ))}
      <AddItemButton
        className={styles.addMessage}
        textClassName={styles.bold}
        onClick={props.addMessage}
        label="Add Message" />
    </div>
  );
};