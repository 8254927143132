import { useContext, useMemo, useState, useCallback } from 'react';
import { Upload as UploadIcon } from 'react-feather';
import {
  ExternalSurveyConfigurationContext,
  ExternalSurveyConfigurationMutationsContext,
} from '@containers/Project.ExternalSurvey';
import { Uploader } from '@containers/Uploader';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { cx } from '@utils';
import { Button } from '@/components/Button';
import { Modal, type ModalProps } from '@/components/Modal/Modal';
import { useModal } from '@/components/Modal/hooks';
import { Input } from '@/components/Input';
import { TabView, type TabViewRenderParams } from '@/components/TabView';
import Toast from '@/components/Toast';
import styles from './styles.css';

type Props = {
  fixedLinks?: boolean;
  onSuccess: (result: LinkImportResult) => void;
};

export const ExternalLinkUpload = ({ onSuccess, ...props }: Props) => {
  const [toggleModal, Modal] = useModal(UploadModal);

  const handleSuccess = useCallback((result: LinkImportResult) => {
    onSuccess?.(result);
    toggleModal();
  }, [onSuccess, toggleModal]);

  return (
    <>
      <UploadIcon
        size={20}
        className={styles.icon}
        onClick={toggleModal} />
      <Modal
        {...props}
        onSuccess={handleSuccess} />
    </>
  );
};

const UploadModal = (props: Pick<ModalProps, 'open' | 'onClose'> & Props) => {
  const [index, setIndex] = useState(0);

  const config = useContext(ExternalSurveyConfigurationContext);

  const fixedLinks = config?.fixedLinks || props.fixedLinks;

  const routes = useMemo(() => {
    return [
      {
        component: UploadFile,
        key: 'file',
        title: 'Upload File',
      },
      !fixedLinks ? {
        component: UploadLink,
        key: 'link',
        title: 'Upload Link',
      } : null,
    ].filter(Boolean);
  }, [fixedLinks]);

  const onSuccess = useCallback((result: LinkImportResult) => {
    props.onSuccess(result);
    Toast.success({
      title: 'Success!',
      body: `${result.numInserted} links created.`,
    });
  }, [props]);

  const renderTabView = useCallback((params: TabViewRenderParams<typeof routes[number]>) => {
    return (
      <params.route.component
        fixedLinks={fixedLinks}
        onSuccess={onSuccess} />
    );
  }, [
    fixedLinks,
    onSuccess,
  ]);

  return (
    <Modal {...props}>
      <TabView
        classes={{ tab: cx(styles.tab, { [styles.single]: fixedLinks }), tabs: styles.tabs }}
        renderTabView={renderTabView}
        index={index}
        onIndexChange={setIndex}
        routes={routes} />
    </Modal>
  );
};

type TabParams = {
  fixedLinks?: boolean;
  onSuccess: (result: LinkImportResult) => void;
};

const UploadFile = (props: TabParams) => {
  const {
    importLinksMutation: { mutateAsync },
  } = useContext(ExternalSurveyConfigurationMutationsContext);

  const handleUpload = useCallback((file: File) => {
    return mutateAsync({
      file,
      fixed: !!props.fixedLinks,
    });
  }, [
    props,
    mutateAsync,
  ]);

  const text = props?.fixedLinks
    ? `File must be in CSV format and contain a "Link" column and a "User ID" column.`
    : `File must be in CSV format and contain a single column titled "Links".`;

  return (
    <div className={styles.uploadSection}>
      <div className={styles.uploadTitle}>
        {text}
      </div>
      <Uploader<LinkImportResult>
        validFileTypes={['.csv']}
        onUpload={handleUpload}
        onSuccess={props.onSuccess}>
        <Button variant='brick'>Upload File</Button>
      </Uploader>
    </div>
  );
};

const UploadLink = (props: TabParams) => {
  const [link, setLink] = useState<string>('');

  const {
    importSingleLinkMutation: { mutateAsync, isLoading },
  } = useContext(ExternalSurveyConfigurationMutationsContext);

  const handleUpload = useCallback(() => {
    return mutateAsync(link, {
      onSuccess: data => {
        if (data.success) {
          props.onSuccess(data);
        } else {
          Toast.error({
            title: 'There was an error uploading your link',
            body: data.msg,
          });
        }
      },
      onError: () => {
        Toast.error({
          title: 'There was an error uploading your link',
        });
      },
    });
  }, [
    link,
    mutateAsync,
    props.onSuccess,
  ]);

  return (
    <div className={styles.uploadSection}>
      <div className={styles.uploadTitle}>
        {`Enter the survey link and we'll make it unique for each user.`}
      </div>
      <Input
        classes={{ root: styles.uploadInput }}
        type={'url'}
        placeholder={'https://example.com'}
        value={link}
        onChange={e => setLink(e.target.value)} />
      <div>
        <ButtonActivityIndicator
          onClick={handleUpload}
          loading={isLoading}>
          Upload
        </ButtonActivityIndicator>
      </div>
    </div>
  );
};

type LinkImportResult = {
  numInserted: number;
};