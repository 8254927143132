import { useCallback } from 'react';
import {
  NumberInputTableDropdownsContainer,
  NumberInputTableWarningsContainer,
  NumberInputTableTotalsContainer,
  NumberInputTableAnswerMapContainer,
} from '@containers/SurveyForm/NumberInputTable';
import { SurveyQuestionType } from '@enums';
import { QuestionText } from '@presentation/Survey';
import type { NumberInputTableQuestion } from '@/types/survey';
import {
  MatrixGridQuestion,
  MatrixSliderQuestion,
  MultipleChoiceQuestion,
  ShortTextResponseQuestion,
  MultiselectQuestion,
  RankingQuestionForm,
  MatrixMultiselectQuestionForm,
  NumberInputTableQuestionForm,
  MultiTextbox,
} from '@/components/SurveyForm';
import type { EditingQuestionItemProps } from './interfaces';
import styles from './style.css';

export const EditingQuestionItem = (props: EditingQuestionItemProps) => {

  const renderContent = useCallback(() => {
    switch (props.question.typeId) {
      case SurveyQuestionType.MultipleChoice:
        return (
          <MultipleChoiceQuestion
            question={props.question} />
        );

      case SurveyQuestionType.ShortTextResponse:
        return (
          <ShortTextResponseQuestion
            item={props.question} />
        );

      case SurveyQuestionType.LongTextResponse:
        return (
          <ShortTextResponseQuestion
            item={props.question} />
        );

      case SurveyQuestionType.Multiselect:
        return (
          <MultiselectQuestion
            question={props.question} />
        );

      case SurveyQuestionType.Ranking:
        return (
          <RankingQuestionForm
            question={props.question} />
        );

      case SurveyQuestionType.Sliders: {
        return (
          <MatrixSliderQuestion
            item={props.question} />
        );
      }

      case SurveyQuestionType.MatrixGrid: {
        return (
          <MatrixGridQuestion
            item={props.question} />
        );
      }

      case SurveyQuestionType.MatrixMultiselect:
        return (
          <MatrixMultiselectQuestionForm
            item={props.question} />
        );

      case SurveyQuestionType.NumberInputTable: {
        const item: NumberInputTableQuestion.FormQuestion = {
          ...props.question,
          settings: {
            ...props.question.settings,
            total: {
              comparate: 0,
              operator: '=',
            },
          },
        };
        return (
          <NumberInputTableWarningsContainer question={item}>
            <NumberInputTableAnswerMapContainer question={item}>
              <NumberInputTableTotalsContainer question={item}>
                <NumberInputTableDropdownsContainer question={item}>
                  <NumberInputTableQuestionForm
                    item={item} />
                </NumberInputTableDropdownsContainer>
              </NumberInputTableTotalsContainer>
            </NumberInputTableAnswerMapContainer>
          </NumberInputTableWarningsContainer>
        );
      }

      case SurveyQuestionType.MultiTextbox:
        return (
          <MultiTextbox question={props.question} />
        );

      default:
        return null;
    }
  }, [
    props.question,
  ]);

  return (
    <>
      <QuestionText
        textClassName={styles.questionText}
        item={props.question} />
      <div className={styles.content}>
        {renderContent()}
      </div>
    </>
  );
};

export default EditingQuestionItem;