import { useCallback, useLayoutEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { qs } from '@utils';
import { SearchParamsContainer } from './SearchParamsContainer';
import { useSearchParams } from './hooks';
import { SearchInstanceContext } from './Context';
import { useSearchQueryState } from './hooks/useSearchQueryState';

type Props = {
  children: React.ReactNode;
};

const SearchInstanceContainer = (props: Props) => {
  const [params, actions] = useSearchParams();
  const location = useLocation();
  const initialized = useRef(false);

  function getParams() {
    const urlParams = qs.base64Decode(location.search);

    return {
      ...SearchParamsContainer.initialState,
      ...(urlParams ?? {}),
    };
  }

  const initialParams = getParams();

  useLayoutEffect(() => {

    actions.init(initialParams);

    fetch({
      ...initialParams,
      searchAfter: null,
      size: 10,
    })
    .finally(() => initialized.current = true);

  /* eslint-disable-next-line */
  }, []);

  const [query, fetch] = useSearchQueryState();
  const paramsRef = useRef(JSON.stringify(initialParams));

  const hasChangedParams = useCallback(() => {
    const currentParams = JSON.stringify(params);
    const hasChanged = paramsRef.current !== currentParams;

    if (hasChanged) {
      paramsRef.current = currentParams;
    }

    return hasChanged;

  }, [params]);

  const computeQueryMetadata = useCallback(() => {
    const searchAfter = !query.value?.items?.length || hasChangedParams()
        ? null
        : query.value.items[query.value.items.length - 1].query.sort;

    return { searchAfter };

  }, [
    hasChangedParams,
    query.value?.items,
  ]);

  const fetchQuery = useCallback(() => {
    if (!initialized.current) return;

    const metadata = computeQueryMetadata();

    return fetch({
      ...params,
      ...metadata,
      size: 10,
    });

  }, [
    computeQueryMetadata,
    fetch,
    params,
  ]);

  return (
    <SearchInstanceContext.Provider value={[query, fetchQuery]}>
      {props.children}
    </SearchInstanceContext.Provider>
  );
};

export { SearchInstanceContainer };
export default SearchInstanceContainer;