import { useEffect } from 'react';
import { NotificationsSocketEvent } from '@/enums';
import { Notifications } from '../interfaces';
import * as $notifications from '../notifications';

export const useNotificationsSocketEvent = <T extends NotificationsSocketEvent>(event: T, fn: Notifications.RegisterHandler<T>, enabled = true) => {

  useEffect(() => {
    if (enabled) {
      $notifications.on(event, fn);
      return () => {
        $notifications.off(event, fn);
      };
    }
  }, [enabled, event, fn]);
};