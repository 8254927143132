import { path, pathname } from '@consts';
import { RouteAuth } from '@enums';
import { BasicRouter } from '@routes/Routers';
import * as Routing from '@routes/interfaces';
import { CallMain, CallNotFound } from '@screens/Call.Main';

export const routes: Routing.Route[] = [
  {
    component: CallNotFound,
    exact: true,
    path: path.Calls.NotFound,
  },
  {
    component: CallMain,
    path: path.Calls.Call,
  },
];

export const router = BasicRouter(routes, {
  auth: RouteAuth.Authenticated,
  path: pathname.Calls,
});