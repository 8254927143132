import { useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { pathname } from '@consts';
import { ProjectOnboardingContext, ProjectScreensContext } from '@containers/ProjectOnboarding/Context';
import { useMatchProjectIdFromRoute } from '@containers/ProjectOnboarding/hooks';
import { useStepper } from '@utils';

type Props = unknown;

export const ProjectCompletionStepper = (props: Props) => {
  const { projectId } = useMatchProjectIdFromRoute();
  const state = useContext(ProjectScreensContext);
  const history = useHistory();

  const [Screen, actions, index] = useStepper(state.screens);

  const hasNextStep = useMemo(() => {
    return index !== state.screens.length - 1;
  }, [
    state.screens,
    index,
  ]);

  const current = useMemo(() => {
    return state.steps[index];
  }, [
    index,
    state.steps,
  ]);

  const next = useCallback(() => {
    if (!hasNextStep) {
      history.replace(pathname.Home);
    } else {
      actions.next();
    }
  }, [
    actions,
    hasNextStep,
    history,
  ]);

  const value = {
    back: actions.back,
    goTo: actions.goTo,
    hasNextStep,
    next,
    projectId,
  };

  if (!state.steps.length) return null;

  return (
    <ProjectOnboardingContext.Provider value={value}>
      <Screen />
    </ProjectOnboardingContext.Provider>
  );
};

ProjectCompletionStepper.displayName = 'ProjectOnboarding.Completion.Stepper';