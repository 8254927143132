import { memo, useCallback } from 'react';
import { LabelWithColor } from './ColorIndicator';
import type { ColorLabelProps, PointsTooltipProps } from './interfaces';
import styles from './style/PointsTooltip.css';

const ColorLabel = memo(({ color, value }: ColorLabelProps) => {
  return (
    <LabelWithColor
      className={styles.item}
      color={{
        value: color,
        size: 18,
      }}
      label={{
        value,
      }} />
  );
});

const MaxPoints = 5;
export const PointsTooltip = memo(({ getPointLabel, points, pointsValueText }: PointsTooltipProps) => {

  const renderValues = useCallback(() => {
    return points
      .filter((_, i) => i < MaxPoints)
      .map(point => (
        <ColorLabel
          key={point.id}
          color={point.serieColor}
          value={getPointLabel(point)} />
      ));
  }, [getPointLabel, points]);

  const renderMore = useCallback(() => {
    if (points.length <= MaxPoints) {
      return null;
    }

    const amt = points.length - MaxPoints;

    return (
      <div className={styles.more}>
        <div className={styles.colorOffset} />
        <div>...{amt} others</div>
      </div>
    );
  }, [points.length]);

  const renderHeader = useCallback(() => {
    return (
      <div className={styles.header}>
        Version {points[0].data.x.toString()}: {pointsValueText}
      </div>
    );
  }, [
    points,
    pointsValueText,
  ]);

  return (
    <div className={styles.root}>
      {renderHeader()}
      {renderValues()}
      {renderMore()}
    </div>
  );
});