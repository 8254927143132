import { memo } from 'react';
import Popover from '@mui/material/Popover';
import PopupState from 'material-ui-popup-state';
import * as enums from '@enums';
import { trunc } from '@utils';
import type { Project } from '@/types';
import styles from './style/ProjectPopover.css';

type AnchorProps = {
  className?: string;
} & ChildrenProps
  & PopoverProps;

export const ProjectPopoverAnchor = ({ children, className, item }: AnchorProps) => {
  return (
    <PopupState variant="popover" popupId="project-progress-popover">
      {popupState => (
        <>
          <div
            className={className}
            onMouseEnter={popupState.open}
            onMouseLeave={popupState.close}>
            {children}
          </div>
          <Popover
            sx={{
              pointerEvents: 'none',
              marginTop: '10px',
            }}
            slotProps={{
              paper: {
                sx: {
                  backgroundColor: 'var(--pri-01)',
                  border: '1px solid transparent',
                  borderRadius: '4px',
                  boxShadow: '0 2px 6px 2px rgba(60,64,67,.15)',
                },
              },
            }}
            disableScrollLock
            open={popupState.isOpen}
            anchorEl={popupState.anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={popupState.close}
            disableRestoreFocus>
            <ProjectPopover
              item={item} />
          </Popover>
        </>
      )}
    </PopupState>
  );
};

type PopoverProps = {
  item: Pick<Project, 'name' | 'description' | 'projectType'>;
};

export const ProjectPopover = memo(({ item }: PopoverProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.name}>{item.name}</div>
      <div className={styles.type}>
        Type: {enums.utils.ProjectType.getName(item.projectType)}
      </div>
      <div className={styles.description}>{trunc(item.description, 100)}</div>
    </div>
  );
});