import { useState } from 'react';
import * as api from '@api';
import * as API from '@api/interfaces';
import { SearchQueryContainer } from '@containers/Search/SearchQueryContainer';
import { useAsyncStateLazy } from '@utils';
import { Search } from '@/types';

const useFetchSearchQueryResults = () => {
  const [state, dispatch] = useState<Search.QueryState>(SearchQueryContainer.initialState);

  return useAsyncStateLazy(async (data: API.Search.FetchQueryResults.Request) => {

    const res = await api.search.fetchQueryResults(data);

    const items = data.searchAfter
        ? state.items.concat(res.items)
        : res.items;

    const value: Search.QueryState = {
      items,
      new: !data.searchAfter,
      total: res.total,
    };

    dispatch(value);

    return value;

  }, [
    dispatch,
    state,
  ]);

};

export { useFetchSearchQueryResults };
export default useFetchSearchQueryResults;