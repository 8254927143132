import { TopicTags } from '@/components/PostCreation/Topics.Tags';
import styles from './style/Form.Topics.css';

type Props = unknown;

export const Topics = (props: Props) => {
  const classes = {
    cancel: styles.cancel,
    editing: styles.editing,
    edit: styles.edit,
    input: styles.input,
    root: styles.topics,
    tags: styles.tags,
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <TopicTags
            buttonPosition="before-tags"
            classes={classes}
            maxItems={10} />
        </div>
      </div>
    </div>
  );
};

Topics.displayName = 'Post.Article.New.Form.Topics';