import { AxiosResponse } from 'axios';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { fetchSurveyTemplateData } from '@api/search';
import { Search } from '@api/interfaces/search';

export const useFetchSurveyTemplateRefData = (props: Props, options: Options = {}) => {

  return useQuery(['survey-template-ref-data', props], ({ queryKey }) => {

    const query = queryKey[1];

    if (!query.value.length) return [];

    return fetchSurveyTemplateData(query)
      .then(res => dedupeItems(res.items));

  }, options);

};

function dedupeItems(items: Data) {

  const deduped: Data = [];
  const uniqueValues = new Set<string>();

  for (const item of items) {
    if (!uniqueValues.has(item.name)) {
      uniqueValues.add(item.name);
      deduped.push(item);
    }
  }

  return deduped;
}

type Props = Search.FetchSurveyTemplateData.Request;

type QueryKey = readonly [string, Props];
type Data = Search.FetchSurveyTemplateData.Response['items'];
type Error = AxiosResponse;
type Variables = unknown;
type Options = UseQueryOptions<Variables, Error, Data, QueryKey>;