import { useCallback, useState } from 'react';
import { useSlackChannels } from '@utils/api';
import { useAsyncDebounce } from '@utils';
import { Slack } from '@/types';
import AutoComplete from '@/components/AutoComplete';
import { ActivityIndicator } from '@/components/ActivityIndicator';

type Props = {
  onSelect: (item: Slack.Channel) => void;
  teamId: string;
};

export const SlackChannelsAutocomplete = ({ onSelect, teamId }: Props) => {

  const [items, setItems] = useState<Slack.Channel[]>([]);

  const {
    data: channels,
    isFetching,
  } = useSlackChannels(teamId, {
    enabled: !!teamId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: channels => {
      setItems(channels.slice(0, 20));
    },
  });

  const [text, setText] = useState<string>('');

  const fetch = useCallback(async (text: string) => {

    const filtered = channels.filter(
      channel =>
        channel.name.toLowerCase().startsWith(text.toLowerCase())
    );

    setItems(filtered);

    return Promise.resolve();
  }, [channels]);

  const fetchDebounced = useAsyncDebounce((text: string) => fetch(text), 150);

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setText(text);
    if (!text?.length) {
      setItems(channels.slice(0, 20));
      return;
    }
    fetchDebounced(text);
  }, [channels, fetchDebounced]);

  const handleSelect = useCallback((item: Slack.Channel) => {
    setItems(channels.slice(0, 20));
    setText('');
    onSelect(item);
  }, [channels, onSelect]);

  if (isFetching) {
    return <ActivityIndicator show />;
  }

  return (
    <AutoComplete
      getItemValue={(item: Slack.Channel) => item.name}
      items={items}
      name="slack-channels"
      onChange={handleSearch}
      onSelect={handleSelect}
      placeholder="Search Channels"
      value={text} />
  );

};

export default SlackChannelsAutocomplete;