export enum NotificationEvent {
  AdHocCallAccepted                 = 'ad-hoc-call-accepted',
  AdHocCallCancelled                = 'ad-hoc-call-cancelled',
  AdHocCallRequested                = 'ad-hoc-call-requested',
  AdHocCallRejected                 = 'ad-hoc-call-rejected',
  AdHocCallRescheduleRequested      = 'ad-hoc-call-reschedule-requested',
  AdHocCallScheduled                = 'ad-hoc-call-scheduled',
  AdHocCallTimesProposed            = 'ad-hoc-call-times-proposed',

  ProjectCallCancelled      = 'call-cancelled',
  ProjectCallInitialRequest = 'call-initial-request',
  ProjectCallRejected       = 'call-rejected',
  ProjectCallRequested      = 'call-requested',
  ProjectCallScheduled      = 'call-scheduled',
  ProjectCallTimeChange     = 'call-time-change',

  OnboardingCompleted = 'onboarding-completed',
  OnboardingRequested = 'onboarding-requested',

  ProjectRequested = 'project-requested',

  MessageRejected  = 'message-rejected',
}

export enum NotificationResponse {
  Ephemeral   = 'emphemeral',
  Interactive = 'interactive',
}