import type { LinkProps } from 'react-router-dom';
import { Chip } from '@presentation/Chip';
import { MaybeLink } from '@/components/MaybeLink';

type Props = {
  children: string | number;
  className?: string;
} & Pick<LinkProps, 'to' | 'onClick'>;

export const Link = (props: Props) => {
  return (
    <MaybeLink
      to={props.to}
      onClick={props.onClick}>
      <Chip
        className={props.className}
        clickable={!!props.to}
        color="basic"
        readonly={!props.to}>
        <Chip.Body.Basic>
          {props.children}
        </Chip.Body.Basic>
      </Chip>
    </MaybeLink>
  );
};

Link.displayName = 'Tag.Link';