import { useContext, useState } from 'react';
import { format } from 'date-fns';
import { useGroupEntityTags, useGroupEntityTagsUpdate } from '@/containers/GroupTags/hooks';
import { DatePicker } from '@/components/DatePicker';
import Pencil from '@/components/icons/Pencil';
import { GroupSidebarTags } from '@/components/GroupSidebarTags';
import { useWorkspaceFileState } from '@/containers/Workspace.File.Preview/hooks';
import { UpdateFilePublishedOnContext } from '@/containers/Workspace.File/Context';
import { UpdateFilePublishedOnContainer } from '@/containers/Workspace.File/UpdateFilePublishedDateContainer';
import { FormButtons } from '@/presentation/FormButtons';
import * as Tab from '@/presentation/Workspace.Tab.Pane';
import { useHasGroupFeature } from '@/store/hooks';
import { useConditionalRender } from '@/utils';
import styles from './style/Tab.Details.css';

const StateDependent = () => {
  const { file, object } = useWorkspaceFileState();
  const { update } = useContext(UpdateFilePublishedOnContext);

  const [editingPublished, setEditingPublished] = useState<boolean>(false);

  const filteredAuthors = file.authors.filter(f => !!f.name || !!f.affiliation);

  return (
    <Tab.Panes.Container>
      <Tab.Pane.Pane>
        <Tab.Pane.Title>Details</Tab.Pane.Title>
        <Tab.Items.Container>
          <Tab.Item.Container>
            <Tab.Item.TextLabel>Name</Tab.Item.TextLabel>
            <Tab.Item.Value>{object.name}</Tab.Item.Value>
          </Tab.Item.Container>

          <Tab.Item.Container>
            <Tab.Item.TextLabel>Owner</Tab.Item.TextLabel>
            <Tab.Item.Value>{object.owner.name}</Tab.Item.Value>
          </Tab.Item.Container>

          <Tab.Item.Container>
            <Tab.Item.TextLabel>Authors</Tab.Item.TextLabel>
            <Tab.Item.Value>
              {filteredAuthors.length ? (
                filteredAuthors.map(({ name, affiliation }, index) => (
                  <div key={index}>
                    {[name, affiliation].filter(Boolean).join(', ')}
                  </div>
                ))
              ) : (
                <>None</>
              )}
            </Tab.Item.Value>
          </Tab.Item.Container>

          <Tab.Item.Container>
            <Tab.Item.TextLabel>Title</Tab.Item.TextLabel>
            <Tab.Item.Value>{file.title || 'Unknown'}</Tab.Item.Value>
          </Tab.Item.Container>

          <Tab.Item.Container>
            <Tab.Item.TextLabel>Uploaded On</Tab.Item.TextLabel>
            <Tab.Item.Value>{format(object.createdOn, 'M/d/yyyy')}</Tab.Item.Value>
          </Tab.Item.Container>

          <PublishedDate
            editing={editingPublished}
            setEditing={setEditingPublished}
            value={file.publishedOn}
            onCancel={() => {
              setEditingPublished(false);
            }}
            onSubmit={value => {
              update(value);
              setEditingPublished(false);
            }} />
        </Tab.Items.Container>
      </Tab.Pane.Pane>
      <TagPane />
    </Tab.Panes.Container>
  );
};

function TagPane() {
  const $Tags = useConditionalRender(useHasGroupFeature('userTagging'));

  const tags = useGroupEntityTags();
  const updateTags = useGroupEntityTagsUpdate();

  return (
    <$Tags>
      <Tab.Pane.Pane>
        <GroupSidebarTags
          tags={tags}
          onSave={updateTags} />
      </Tab.Pane.Pane>
    </$Tags>
  );
}

type PublishedDateProps = {
  editing: boolean;
  setEditing: (value: boolean) => void;
  onCancel: () => void;
  onSubmit: (value: Date) => void;
  value: Date;
};

const PublishedDate = ({
  setEditing,
  onCancel,
  onSubmit,
  editing,
  value,
}: PublishedDateProps) => {
  return (
    <Tab.Item.Container>
      <Tab.Item.TextLabel>Published On</Tab.Item.TextLabel>
      <Tab.Item.Value>
        {!editing && (
          <div className={styles.dateFieldValue} onClick={() => setEditing(true)}>
            {format(value, 'M/d/yyyy')}
            <div className={styles.pencil}>
              <Pencil size={16} />
            </div>
          </div>
        )}
        {editing && (
          <EditingPublishedDate
            editing={editing}
            value={value}
            onSubmit={onSubmit}
            onCancel={onCancel}
            setEditing={setEditing} />
        )}
      </Tab.Item.Value>
    </Tab.Item.Container>
  );
};

type DatePickerState = {
  value: Date;
};

const EditingPublishedDate = ({
  onCancel,
  onSubmit,
  value,
}: PublishedDateProps) => {
  const [state, setState] = useState<DatePickerState>({
    value,
  });

  return (
    <>
      <div className={styles.dateFieldValue}>
        <div className={styles.datePicker}>
          <DatePicker
            inline={false}
            open={true}
            onChange={value => setState({ value })}
            placeholderText="Select a date"
            selected={state.value} />
        </div>
        <FormButtons
          className={styles.btns}
          size={16}
          disabled={false}
          onCancel={onCancel}
          onSubmit={() => onSubmit(state.value)} />
      </div>
    </>
  );
};

export const FileTabDetails = () => {
  const { file, object } = useWorkspaceFileState();

  return (
    <UpdateFilePublishedOnContainer
      file={file}
      workspaceId={object.workspaceId}>
      <StateDependent />
    </UpdateFilePublishedOnContainer>
  );
};
