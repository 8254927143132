import { SurveySettings, SurveyQuestionNumberType } from '@/types';
import { Validation } from '../interfaces';

export const assertValidNumberSettings = ({ numberSettings }: SurveySettings.NumberSetting): Validation.AssertionResult => {
  if (numberSettings.type == SurveyQuestionNumberType.Currency && !numberSettings.currency) {
    return {
      message: 'A currency must be provided',
      success: false,
    };
  }

  if (numberSettings.type === SurveyQuestionNumberType.Unit && !numberSettings.unitType) {
    return {
      message: 'A unit must be provided',
      success: false,
    };
  }

  return {
    success: true,
  };
};