import { useContext, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { Grow, Portal } from '@mui/material';
import { useOwnProjectAccessRole, useProjectState } from '@containers/GroupProject/hooks';
import { ProjectTagsQueryContext } from '@containers/Group.Project.Tags/Context';
import { ConferenceTagType, WorkspaceObjectRole } from '@enums';
import { ButtonOutlined } from '@presentation';
import { ConditionalTooltip } from '@presentation/Tooltip';
import { getLocationFor } from '@utils';
import { useZIndexModifier } from '@/components/Popper';
import { useTagsOnboardingEnabled } from '@/components/Project.Tags/hooks';
import { TagRowsGraphic } from './TagRowsGraphic';
import styles from './style/TabTitle.css';

type Props = unknown;

export const TabTitle = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [anchor, setAnchor] = useState<HTMLDivElement>();
  const [popover, setPopover] = useState<HTMLDivElement>();
  const zIndexModifier = useZIndexModifier({ zIndex: 8 });
  const popper = usePopper(anchor, popover, {
    modifiers: [zIndexModifier],
    placement: 'bottom',
  });

  const { enabled } = useTagsOnboardingEnabled();

  return (
    <div className={styles.root}>
      <div className={styles.title}>Tags</div>
      <div
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}>
        <div
          className={styles.badge}
          ref={setAnchor}>
          Beta
        </div>
        {open &&
          <Portal>
            <div onClick={e => e.stopPropagation()}>
              <div
                ref={setPopover}
                style={popper.styles.popper}
                {...popper.attributes.popper}>
                <Popover
                  onboardingEnabled={enabled}
                  open={open} />
              </div>
            </div>
          </Portal>}
      </div>
    </div>
  );
};

TabTitle.displayName = 'Project.Tags.TabTitle';

type PopoverProps = {
  onboardingEnabled?: boolean;
  open: boolean;
};

const Popover = (props: PopoverProps) => {
  const ctx = useProjectState();
  const roleId = useOwnProjectAccessRole();

  const disabled = useMemo(() => {
    return ![
      WorkspaceObjectRole.Owner,
      WorkspaceObjectRole.Collaborator,
    ].includes(roleId);
  }, [roleId]);

  return (
    <Grow in={props.open}>
      <div className={styles.popover}>
        <div className={styles.arrow}>
          <div className={styles.triangle} />
        </div>
        <div className={styles.menu}>
          <div className={styles.header}>
            {copy.title}
          </div>
          <div className={styles.body}>
            <div className={styles.subtitle}>
              {copy.subtitle}
            </div>
            <TagRowsGraphic />
            {props.onboardingEnabled &&
              <ConditionalTooltip
                enabled={disabled}
                title={copy.tooltip}>
                <span>
                  <ButtonOutlined
                    className={styles.btn}
                    color="blue"
                    fontWeight="bold"
                    to={getLocationFor.project.tagsOnboarding({ projectId: ctx?.project?.id })}>
                    Get Started
                  </ButtonOutlined>
                </span>
              </ConditionalTooltip>}
          </div>
        </div>
      </div>
    </Grow>
  );
};

Popover.displayName = 'Project.Tags.Popover';

const copy = {
  title: `Tagging Interviews Accelerates Qualitative Research`,
  subtitle: `Set up tags to structure quotes from interviews and access Generative AI powered transcript summaries.`,
  tooltip: `Please update your project access to collaborator`,
};