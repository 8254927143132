import { useEffect } from 'react';
import { SocketNamespace, type ReportSocketEvent } from '@/enums';
import type { Reports } from '../interfaces';
import * as $reports from '../reports';
import { useSocket } from './useSocket';

export const useReportsSocketEvent = <T extends ReportSocketEvent>(event: T, fn: Reports.RegisterHandler<T>, enabled = true) => {

  useEffect(() => {
    if (enabled) {
      $reports.on(event, fn);
      return () => {
        $reports.off(event, fn);
      };
    }
  }, [enabled, event, fn]);
};

export const useReportsSocket = () => {
  return useSocket(SocketNamespace.Reports);
};