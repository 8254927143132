import { useContext, useState, useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as consts from '@consts';
import { TranscriptReplacementsContext } from '@containers/Group.Project.Terms';
import { saveTranscriptReplacements } from '@api/projects.transcripts';
import { ActivityBlocker } from '@presentation/ActivityBlocker';
import type { SkippedReplacements } from '@/types/transcript.cleanup';
import { Button } from '@/components/Button';
import Toast from '@/components/Toast';
import { Modal, Header } from '@/components/Modal';
import { Alert } from '@/components/Modal/Alert';
import { RouteLeavingGuard } from '@/components/RouteLeavingGuard';
import { WebSocketContext, RouteGuardContext } from './Context';

export const EditHeader = () => {
  const { replacements, globalReplacements, confirmedTerms, dictionaryWords, projectId } = useContext(TranscriptReplacementsContext);
  const { setEventKey } = useContext(WebSocketContext);

  const [skippedReplacements, setSkippedReplacements] = useState<SkippedReplacements>();
  const { shouldSkipGuard, setSkipGuard } = useContext(RouteGuardContext);

  const changeCount = useMemo(() => {
    return replacements.length + globalReplacements.length + confirmedTerms.length;
  }, [confirmedTerms.length, globalReplacements.length, replacements.length]);

  const saveReplacementsMutation = useMutation({
    mutationKey: ['save-transcript-replacements'],
    mutationFn: () => {
      return saveTranscriptReplacements({ replacements, projectId, confirmedWords: confirmedTerms, globalReplacements, dictionaryWords });
    }, onError: () => {
      Toast.error({
        title: 'There was an error saving the transcripts.',
      });
    }, onSuccess: resp => {
      if (resp.async === true) {
        setEventKey(resp.eventKey);
      } else if (resp.async === false) {
        if (Object.values(resp.skippedReplacements).flat().length > 0) {
          setSkippedReplacements(resp.skippedReplacements);
        } else {
          setSkipGuard();
          setTimeout(() => window.location.reload(), 100);
        }
      }
    },
  });

  if (changeCount <= 0) return null;

  return (
    <>
      <div>{changeCount} Edits</div>
      <Button
        variant='brick'
        color='affirmative'
        onClick={() => saveReplacementsMutation.mutateAsync()}>
        Save Edits
      </Button>
      <RouteLeavingGuard block={changeCount > 0 && !shouldSkipGuard} checkLocation={(l, a) => a !== 'REPLACE'}>
        {guardProps => (
          <Alert
            message={`You have unsaved changes to the transcripts. Are you sure you want to navigate away without saving?`}
            open={guardProps.open}
            onClose={guardProps.closePrompt}
            onConfirm={guardProps.confirmNavigation} />
        )}
      </RouteLeavingGuard>
      <SkippedReplacementsModal skippedReplacements={skippedReplacements} />
      {saveReplacementsMutation.isLoading && <ActivityBlocker />}
    </>
  );
};

type SkippedReplacementsModalProps = {
  skippedReplacements: SkippedReplacements;
};
const SkippedReplacementsModal = (props: SkippedReplacementsModalProps) => {
  return (
    <Modal
      open={!!props.skippedReplacements}
      blocking={true}>
      <Header>There were some replacements we were unable to apply:</Header>
      <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
        {Object.entries(props.skippedReplacements ?? {}).map(([k, replacements]) => (
          <div key={k}>
            <div>
              TranscriptId: {k}
            </div>
            <div>
              {replacements.map(r => (
                <div key={k + '-' + r.docPos.from.toString()}>
                  {`${r.expectedText} -> ${r.replacementText} (${r.docPos.from}, ${r.docPos.to})`}
                </div>
              ))}
            </div>
          </div>
        ))
        }
      </div>
      <div>{`Please refresh the page and try again. If the problem persists please contact ${consts.emails.EngineeringSupportEmail}.`}</div>
      <div>
        <Button variant='brick' onClick={() => window.location.reload()}>
          Refresh
        </Button>
      </div>
    </Modal>
  );
};
