import { useMemo } from 'react';
import { ProjectType } from '@/enums';

type Props = {
  projectType: ProjectType;
};

export function useSetupLabels({ projectType }: Props) {
  return useMemo(() => ({
    owner: [ProjectType.Call, ProjectType.SurveyCall].includes(projectType) ? `Moderator` : `Project Owner`,
  }), [
    projectType,
  ]);
}