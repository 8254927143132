import { Dispatch, SetStateAction } from 'react';
import { createNamedContext } from '@utils';
import { TaggedProject } from '@containers/TaggedProjects';

export type TaggedProjectsContextValue = {
  editable: boolean;
  editing: boolean;
  items: TaggedProject[];
};

export type TaggedProjectsUpdateContextValue = [Dispatch<TaggedProject[]>, boolean];

export type TaggedProjectsResetContextValue = () => void;

export const TaggedProjectsContext = createNamedContext<TaggedProjectsContextValue>(undefined, 'TaggedProjectsContext');
export const TaggedProjectsDispatchContext = createNamedContext<Dispatch<SetStateAction<TaggedProjectsContextValue>>>(undefined, 'TaggedProjectsDispatchContext');
export const TaggedProjectsUpdateContext = createNamedContext<TaggedProjectsUpdateContextValue>(undefined, 'TaggedProjectsUpdateContext');
export const TaggedProjectsResetContext = createNamedContext<TaggedProjectsResetContextValue>(undefined, 'TaggedProjectsResetContext');