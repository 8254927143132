import { useState, useCallback, useMemo } from 'react';
import type { CellContext, ColumnDef } from '@tanstack/react-table';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import cuid from 'cuid';
import { MinusCircle } from 'react-feather';
import { distinct, indexBy } from '@utils/array';
import { oxfordCommaJoin } from '@utils';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder';
import type { SurveySection } from '@/types';
import { SortableTable } from '@/presentation/Tables';
import { AddItemButton } from '@presentation/AddItemButton';
import type { UseModalProps } from '@/components/Modal';
import { Modal, Header, useModal } from '@/components/Modal';
import { Checkbox } from '@/components/Checkbox';
import { Button } from '@/components/Button';
import styles from './style/Modal.Randomization.css';

type Props = UseModalProps;
export const SectionRandomizationModal = (props: UseModalProps) => {
  const [state, dispatch] = useSurveyBuilderState();
  const [sectionRandomization, setSectionRandomization] = useState<Record<string, string>>(buildSectionRandomization(state.survey.sections));
  const [groups, setGroups] = useState(buildRandomizationGroups(state.survey.sections));

  const updateSectionRandomization = useCallback((section: SurveySection, group: string) => {
    setSectionRandomization(old => ({
      ...old,
      [section.identifier]: group,
    }));
  }, []);

  const applySettings = useCallback(() => {
    dispatch({
      type: 'batch-actions',
      actions: Object.entries(sectionRandomization).map(([k, v]) => ({
        type: 'section-metadata-updated',
        payload: {
          identifier: k,
          metadata: {
            randomizeSelf: v ? {
              group: v,
            } : null,
          },
        },
      })),
    });
    props.onClose();
  }, [dispatch, sectionRandomization, props]);

  const addGroup = useCallback(() => {
    setGroups(old => [...old, cuid()]);
  }, []);

  const removeGroup = useCallback((group: string) => {
    setGroups(old => old.filter(g => g !== group));
    setSectionRandomization(old => {
      const newVal: Record<string, string> = {};

      for (const [k, v] of Object.entries(old)) {
        if (v !== group) newVal[k] = v;
      }

      return newVal;
    });
  }, []);

  const getRandomizedSectionText = useCallback((group: string) => {
    const ordinals = Object.entries(sectionRandomization).filter(([k, v]) => v === group).map(([k, v]) => state.survey.sections.find(s => s.identifier === k));

    return oxfordCommaJoin(ordinals.map(s => s.ordinal.toString()), 'or');
  }, [sectionRandomization, state.survey.sections]);

  const GroupCheckboxCell = useCallback((group: string) => {
    return (cellProps: CellContext<SurveySection, unknown>) => {
      const s = cellProps.row.original;
      return (
        <Checkbox checked={sectionRandomization[s.identifier] === group} onChange={e => updateSectionRandomization(s, e.target.checked ? group : null)} />
      );
    };
  }, [sectionRandomization, updateSectionRandomization]);

  const ColHeader = useCallback((group: string) => {
    return (
      <div className={styles.header}>Randomize {groups.length > 1 &&
        <MinusCircle
          className={styles.minus}
          size={14}
          onClick={() => removeGroup(group)} />}
      </div>
    );
  }, [groups.length, removeGroup]);

  const columns = useMemo<ColumnDef<SurveySection>[]>(() => {
    return [
      ...groups.map(g => ({
        id: `checkbox-${g}`,
        header: () => ColHeader(g),
        cell: GroupCheckboxCell(g),
      })),
      {
        id: 'order',
        header: 'Order',
        cell: props => sectionRandomization[props.row.original.identifier] ? getRandomizedSectionText(sectionRandomization[props.row.original.identifier]) : props.row.original.ordinal,
      }, {
        id: 'name',
        header: 'Name',
        cell: props => props.row.original.name ?? `Section ${props.row.original.ordinal}`,
      }];
  }, [ColHeader, GroupCheckboxCell, getRandomizedSectionText, groups, sectionRandomization]);

  const table = useReactTable({
    columns,
    data: state.survey.sections,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Modal {...props}>
      <Header>Randomize Sections</Header>
      <SortableTable table={table} />
      <AddItemButton
        className={styles.addGroup}
        onClick={addGroup}
        label={'Add Group'} />
      <div className={styles.buttons}>
        <Button
          variant='brick'
          color='destructive'
          onClick={props.onClose}>
          Cancel
        </Button>
        <Button
          variant='brick'
          color='affirmative'
          disabled={Object.values(sectionRandomization).filter(x => x).length === 1}
          onClick={applySettings}>
          Update
        </Button>
      </div>
    </Modal>
  );
};

function buildSectionRandomization(sections: SurveySection[]) {
  return indexBy(sections, k => k.identifier, v => v.metadata?.randomizeSelf?.group);
}

function buildRandomizationGroups(sections: SurveySection[]) {
  const groups = distinct(sections.map(s => s.metadata.randomizeSelf?.group).filter(Boolean));

  return groups.length ? groups.sort() : ['default'];
}

export const useSectionRandomizationModal = () => useModal(SectionRandomizationModal);