import { useCallback, useEffect, useMemo } from 'react';
import { useQueryParam, NumberParam } from 'use-query-params';
import { useHistory } from 'react-router-dom';
import { useSuspendInitialLoading } from '@containers/GroupProject/hooks';
import { useProjectQuantAnalysisQuery } from '@/utils/api';
import {
  QuantAnalysisItemsContext,
  QuantAnalysisLoadingContext,
  QuantAnalysisContext,
  SelectedQuantAnalysisItemContext,
  SetSelectedQuantAnalysisItemContext,
} from './context';

type Props = {
  projectId: number;
  quantAnalysisId: number;
} & ChildrenProps;

export const ProjectQuantAnalysisContainer = (props: Props) => {

  const [selectedItemId, setSelectedItemId] = useQueryParam('analysisItemId', NumberParam);

  const updateSelectedItemId = useCallback((id: number) => {
    setSelectedItemId(id, 'replace');
  }, [setSelectedItemId]);

  const query = useProjectQuantAnalysisQuery({
    projectId: props.projectId,
    quantAnalysisId: props.quantAnalysisId,
  }, {
    placeholderData: {
      analysis: null,
      items: [],
    },
  });

  const history = useHistory();

  useEffect(() => {
    if (!selectedItemId && query.data.items.length) {
      const firstItemId = query.data.items[0].id;
      updateSelectedItemId(firstItemId);
    }
  }, [query.data.items, selectedItemId, updateSelectedItemId]);

  const loading = useMemo(() => {
    return query.isInitialLoading && !query.data;
  }, [
    query.isInitialLoading,
    query.data,
  ]);

  const empty = useMemo(() => {
    return query.isFetchedAfterMount && !loading && !query.data.items.length;
  }, [
    query.isFetchedAfterMount,
    loading,
    query.data?.items,
  ]);

  const suspended = useSuspendInitialLoading({
    data: query.data.items,
    empty,
    isInitialLoading: query.isInitialLoading,
    loading,
  }, 500);

  return (
    <QuantAnalysisLoadingContext.Provider value={suspended.loading}>
      <SetSelectedQuantAnalysisItemContext.Provider value={updateSelectedItemId}>
        <SelectedQuantAnalysisItemContext.Provider value={selectedItemId}>
          <QuantAnalysisItemsContext.Provider value={suspended.data}>
            <QuantAnalysisContext.Provider value={query.data.analysis}>
              {props.children}
            </QuantAnalysisContext.Provider>
          </QuantAnalysisItemsContext.Provider>
        </SelectedQuantAnalysisItemContext.Provider>
      </SetSelectedQuantAnalysisItemContext.Provider>
    </QuantAnalysisLoadingContext.Provider>
  );
};