import { useContext } from 'react';
import { LoginFormContext } from '$website/containers/Auth/Context';

const useLoginForm = () => {
  const context = useContext(LoginFormContext);

  if (!context && process.env.__DEV__) {
    throw new Error('`useLoginForm` must be wrapped inside an `AuthContainer`');
  }

  return context;
};

export { useLoginForm };
export default useLoginForm;