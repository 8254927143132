import type { SurveyPipedItemValueType } from '@/enums';
import { SurveyItemType, SurveyPipedValueType, SurveyQuestionType } from '@/enums';
import type { PipedValue, PipedItem } from '@/types/survey';

export const pipingTypeMap: Record<SurveyQuestionType, SurveyPipedValueType[]> = {
  [SurveyQuestionType.MatrixGrid]: [
    SurveyPipedValueType.MatrixRowsNotSelected,
    SurveyPipedValueType.MatrixRowsSelected,
    SurveyPipedValueType.MatrixOptionsNotSelected,
    SurveyPipedValueType.MatrixOptionsSelected,
  ],
  [SurveyQuestionType.MatrixMultiselect]: [
    SurveyPipedValueType.MatrixRowsNotSelected,
    SurveyPipedValueType.MatrixRowsSelected,
    SurveyPipedValueType.MatrixOptionsNotSelected,
    SurveyPipedValueType.MatrixOptionsSelected,
  ],

  [SurveyQuestionType.MultipleChoice]: [SurveyPipedValueType.OptionsSelected, SurveyPipedValueType.OptionsNotSelected],
  [SurveyQuestionType.Multiselect]: [SurveyPipedValueType.OptionsSelected, SurveyPipedValueType.OptionsNotSelected],
  [SurveyQuestionType.Dropdown]: [SurveyPipedValueType.OptionsSelected, SurveyPipedValueType.OptionsNotSelected],
  [SurveyQuestionType.Sliders]: [SurveyPipedValueType.SliderRowValue, SurveyPipedValueType.SliderLowestValue, SurveyPipedValueType.SliderHighestValue],

  [SurveyQuestionType.NumberInputTable]: [SurveyPipedValueType.NumberTableCellValue],

  [SurveyQuestionType.Ranking]: [
    SurveyPipedValueType.FirstRankedOption,
    SurveyPipedValueType.SecondRankedOption,
    SurveyPipedValueType.SecondLastRankedOption,
    SurveyPipedValueType.LastRankedOption,
    SurveyPipedValueType.AllRankedOptions,
    SurveyPipedValueType.RankedXOption,
  ],
  [SurveyQuestionType.MaxDifference]: [
    SurveyPipedValueType.FirstRankedOption,
    SurveyPipedValueType.SecondRankedOption,
    SurveyPipedValueType.SecondLastRankedOption,
    SurveyPipedValueType.LastRankedOption,
  ],

  [SurveyQuestionType.ConjointAnalysis]: [],
  [SurveyQuestionType.MultiTextbox]: [SurveyPipedValueType.TextEntryValues],
  [SurveyQuestionType.MatrixRange]: [],
  [SurveyQuestionType.ShortTextResponse]: [],
  [SurveyQuestionType.LongTextResponse]: [],
  [SurveyQuestionType.ExclusiveOptions]: [],
  [SurveyQuestionType.ImageMarkup]: [],
};

export function getPipingTypesForQuestionType(typeId: SurveyQuestionType) {
  return pipingTypeMap[typeId];
}

export const pipingValueMap: Partial<Record<SurveyPipedValueType, PipedValue.Value>> = {
  [SurveyPipedValueType.FirstRankedOption]: {
    type: SurveyPipedValueType.FirstRankedOption,
  },
  [SurveyPipedValueType.SecondRankedOption]: {
    type: SurveyPipedValueType.SecondRankedOption,
  },
  [SurveyPipedValueType.SecondLastRankedOption]: {
    type: SurveyPipedValueType.SecondLastRankedOption,
  },
  [SurveyPipedValueType.LastRankedOption]: {
    type: SurveyPipedValueType.LastRankedOption,
  },
  [SurveyPipedValueType.AllRankedOptions]: {
    type: SurveyPipedValueType.AllRankedOptions,
  },
  [SurveyPipedValueType.OptionsNotSelected]: {
    type: SurveyPipedValueType.OptionsNotSelected,
  },
  [SurveyPipedValueType.OptionsSelected]: {
    type: SurveyPipedValueType.OptionsSelected,
  },
  [SurveyPipedValueType.SliderHighestValue]: {
    type: SurveyPipedValueType.SliderHighestValue,
  },
  [SurveyPipedValueType.SliderLowestValue]: {
    type: SurveyPipedValueType.SliderLowestValue,
  },
  [SurveyPipedValueType.TextEntryValues]: {
    type: SurveyPipedValueType.TextEntryValues,
  },
  [SurveyPipedValueType.RankedXOption]: {
    type: SurveyPipedValueType.RankedXOption,
    rank: null,
  },
};

export const pipingItemTypeMap: Record<SurveyItemType, SurveyPipedItemValueType[]> = {
  [SurveyItemType.AlternateImageExercise]: [
    SurveyPipedValueType.AlternateImageShown,
  ],
  [SurveyItemType.Message]: [],
  [SurveyItemType.Question]: [],
};

export const getPipingTypesForItemType = (typeId: SurveyItemType) => {
  return pipingItemTypeMap[typeId];
};

export const pipingItemValueMap: Partial<Record<SurveyPipedItemValueType, PipedItem.Value>> = {
  [SurveyPipedValueType.AlternateImageShown]: {
    type: SurveyPipedValueType.AlternateImageShown,
    group: { identifier: '' },
  },
};