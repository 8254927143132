import { useState, useCallback } from 'react';
import { SurveyBuilderScreenStateContext } from './Context';

export const ScreenStateContainer = (props: ChildrenProps) => {
  const [additionalLanguageCodes, setAdditionalLanguageCodes] = useState<string[]>([]);

  const toggleAdditionalLanguageCode = useCallback((code: string) => {
    setAdditionalLanguageCodes(old => {
      if (old.includes(code))
        return old.filter(x => x !== code);
      else
        return [...old, code];
    });
  }, []);

  return <SurveyBuilderScreenStateContext.Provider value={{ additionalLanguageCodes, toggleAdditionalLanguageCode }}>{props.children}</SurveyBuilderScreenStateContext.Provider>;
};