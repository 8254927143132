import { forwardRef, useCallback, useMemo, Ref } from 'react';
import Popper from '@mui/material/Popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import { PopupState as PopupStateProps } from 'material-ui-popup-state/hooks';
import { useIsInternalUser } from '@/containers/Store/hooks';
import { PopperMenu, PopperMenuItem } from '@/components/Popper';
import { useFolderMenuItems } from '@/containers/Workspace.Folder/hooks/useFolderMenuItems';
import { useFolderActions } from '@/containers/Workspace.Folder/hooks/useFolderActions';
import { useShowFolderMenu } from '@/containers/Workspace.Folder/hooks/useShowFolderMenu';
import { MoreHorizontalAnchorSmall } from '@/presentation/Anchor';
import type { FolderActionsMenuProps, FolderActionsPopperProps } from './interfaces';
import styles from './style/FolderActionsContextMenu.css';

const FolderActionsContextMenu = forwardRef(({ onClose }: FolderActionsMenuProps, ref: Ref<HTMLDivElement>) => {

  const valid = useFolderMenuItems();
  const actions = useFolderActions();

  const isInternalUser = useIsInternalUser();

  const handleClick = useCallback((fn: () => unknown) => () => {
    onClose();
    return fn();
  }, [onClose]);

  const subfolder = useMemo(() => {
    return {
      children: 'New Folder',
      onClick: actions.createFolder,
    };
  }, [actions.createFolder]);

  const project = useMemo(() => {
    return {
      children: 'New Project',
      onClick: actions.createProject,
    };
  }, [actions.createProject]);

  const remove = useMemo(() => {
    return {
      children: 'Remove',
      onClick: actions.delete,
    };
  }, [actions.delete]);

  const ownership = useMemo(() => {
    return {
      children: 'Change Owner',
      onClick: actions.changeOwner,
    };
  }, [actions.changeOwner]);

  const rename = useMemo(() => {
    return {
      children: 'Rename',
      onClick: actions.rename,
    };
  }, [actions.rename]);

  const move = useMemo(() => {
    return {
      children: 'Move',
      onClick: actions.move,
    };
  }, [actions.move]);

  const manageAccess = useMemo(() => {
    return {
      children: 'Manage Access',
      onClick: actions.manageAccess,
    };
  }, [actions.manageAccess]);

  const items = useMemo(() => {
    return [
      valid.canAddChildren && subfolder,
      valid.canAddChildren && isInternalUser && project,
      valid.canRename && rename,
      valid.canDelete && remove,
      valid.canMove && move,
      valid.canManageAccess && manageAccess,
      // valid.canChangeOwner && ownership,
    ].filter(Boolean);
  }, [
    isInternalUser,
    valid,
    move,
    project,
    remove,
    rename,
    subfolder,
    manageAccess,
  ]);

  return (
    <PopperMenu ref={ref}>
      {items.map((item, i) => (
        <PopperMenuItem
          key={i}
          onClick={handleClick(item.onClick)}>
          {item.children}
        </PopperMenuItem>
      ))}
    </PopperMenu>
  );
});

export const FolderActionsContextMenuPopper = ({
  placement = 'bottom-end',
  renderAnchor,
}: FolderActionsPopperProps) => {

  const showMenu = useShowFolderMenu();

  const defaultAnchor = useCallback((state: PopupStateProps) => {
    return (
      <MoreHorizontalAnchorSmall open={state.isOpen} />
    );
  }, []);

  if (!showMenu) {
    return null;
  }

  return (
    <PopupState variant="popper" popupId="folder-actions-popper">
      {popupState => (
        <div>
          <div {...bindToggle(popupState)}>
            {(renderAnchor || defaultAnchor)(popupState)}
          </div>
          <Popper {...bindPopper(popupState)} placement={placement} className={styles.popper}>
            <ClickAwayListener
              onClickAway={popupState.close}>
              <FolderActionsContextMenu
                onClose={popupState.close} />
            </ClickAwayListener>
          </Popper>
        </div>
      )}
    </PopupState>
  );
};