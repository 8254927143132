import { cx } from '@utils';
import Close from './Close';
import ViewAll from './ViewAll';
import styles from './style/MaxDiff.Option.css';

export function MaxDiffOptionOpen({ label, score, onClick }: Props) {
  return (
    <div className={cx(styles.root, styles.open)}>
      <div className={styles.label}>
        <span className={styles.labelValue}>{label}</span>
        <span className={styles.score}>(Score: {score})</span>
      </div>
      <Close onClick={onClick} />
    </div>
  );
}

export function MaxDiffOptionClosed({ label, score, onClick }: Props) {
  return (
    <div className={cx(styles.root, styles.closed)} onClick={onClick}>
      <div className={styles.label}>
        <span className={styles.labelValue}>{label}</span>
        <span className={styles.score}>(Score: {score})</span>
      </div>
      <ViewAll
        onClick={onClick}
        title="View Choices" />
    </div>
  );
}

type Props = {
  label: string;
  score: number;
  onClick: () => unknown;
}
