import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  selectWorkspace,
  selectWorkspaces,
  selectGroupWorkspace,
  selectPersonalWorkspace,
} from '@store/selectors';

export const useSelectWorkspaces = () => {
  const workspaces = useSelector(selectWorkspaces);

  return workspaces;
};

export const useSelectWorkspace = (workspaceId: number) => {
  const workspace = useSelector(selectWorkspace(workspaceId));

  return workspace;
};

export const useSelectGroupWorkspace = () => {
  const workspace = useSelector(selectGroupWorkspace);

  return workspace;
};

export const useSelectPersonalWorkspace = () => {
  const workspace = useSelector(selectPersonalWorkspace);

  return workspace;
};

export const useSelectWorkspaceType = () => {
  const workspaces = useSelectWorkspaces();

  return useCallback((workspaceId: number) => {
    return workspaceId === workspaces.personal.id
    ? 'personal'
    : workspaceId === workspaces.group.id
      ? 'group'
      : 'shared';
  }, [workspaces]);

};