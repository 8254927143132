import { useCallback, useContext, useState } from 'react';
import { format } from 'date-fns';
import { ButtonOutlined, ButtonActivityIndicator } from '@presentation';
import { cx, getCurrentTimezone } from '@utils';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import * as $conference from '$admin/containers/Call/utils';
import * as Conference from './Settings.Form';
import { ConfirmContext, FrameContext, SettingsContext } from './Context';
import { FrameKey } from './interfaces';
import styles from './style/ConferenceSettings.css';

type Props = unknown;

export const ConferenceSettings = (props: Props) => {
  const ctx = {
    confirm: useContext(ConfirmContext),
    frame: useContext(FrameContext),
    settings: useContext(SettingsContext),
  };

  const state = ctx.settings;

  const [isValid, setValid] = useState(true);

  const handleSubmit = useCallback(() => {

    const valid = $conference.ConferenceSettingsSchema.isValidSync(state.conference);

    if (!valid) {
      setValid(false);
      return;
    }

    return ctx.confirm.mutation.mutateAsync().then(() => {
      ctx.frame.goToFrame({ frame: FrameKey.Confirmation });
    });

  }, [
    ctx.frame,
    ctx.confirm.mutation,
    state.conference,
  ]);

  return (
    <div className={styles.root}>
      <div className={cx(styles.wrap, {
        [styles.scroll]: $conference.isOffPlatform(state.conference),
      })}>
        <div className={styles.main}>
          <div className={styles.row}>
            <div className={styles.label}>Time</div>
            {formatCallTime(state.time.start, state.time.end)}
          </div>

          <Conference.Form />

          {!isValid &&
            <div className={styles.validation}>
              Details are invalid
            </div>}
        </div>
        <ButtonSet className={styles.footer}>
          <ButtonOutlined
            className={styles.btn}
            color="silver"
            disabled={ctx.confirm.mutation.isLoading}
            onClick={ctx.frame.back}>
            Back
          </ButtonOutlined>
          <ButtonActivityIndicator
            className={styles.btn}
            loading={ctx.confirm.mutation.isLoading}
            implicitDisable={false}
            onClick={handleSubmit}>
            Confirm
          </ButtonActivityIndicator>
        </ButtonSet>
      </div>
    </div>
  );
};

function formatCallTime(start: Date, end: Date) {
  const pt1 = format(start, 'EEE MMM d, h:mm b');
  const pt2 = format(end, 'h:mm b');

  return `${pt1} to ${pt2} ${getCurrentTimezone()}`;
}

ConferenceSettings.displayName = 'Frame.ConferenceSettings';