import { useCallback } from 'react';
import { X } from 'react-feather';
import { FilterEntry } from '@/types/survey.filtering';
import { SurveyQuestionType } from '@/enums';
import { Selector } from './Selector';
import { FilterProps } from './utils';
import { useFilterQuestion, useValidFilterSurveyQuestions, useValidFilterSurveys } from './hooks';
import styles from './style/SurveyResponseFilter.css';

type Props = FilterProps<SurveyQuestionType> & ChildrenProps;

export const CommonRow = ({ filterActions, filterEntry, children }: Props) => {
  const question = useFilterQuestion(filterEntry);
  const validSurveys = useValidFilterSurveys();
  const validQuestions = useValidFilterSurveyQuestions(filterEntry.surveyId);

  const onQuestionChange = useCallback((questionId: number) => {
    filterActions.changeFilter({
      questionId,
      surveyId: filterEntry.surveyId,
      order: filterEntry.order,
      filter: null,
    });
  }, [filterActions, filterEntry.order, filterEntry.surveyId]);

  const onSurveyChange = useCallback((surveyId: number) => {
    filterActions.changeFilter({
      surveyId,
      questionId: validSurveys.find(s => s.surveyId === surveyId).questions[0].base.id,
      order: filterEntry.order,
      filter: null,
    });
  }, [filterActions, filterEntry.order, validSurveys]);
  return (
    <div key={filterEntry.questionId} className={styles.filterRow}>
      <div className={styles.surveyRow}>
        <Selector
          buttonClass={styles.surveySelectorButton}
          items={validSurveys}
          displayValue={v => v.name}
          onSelect={s => onSurveyChange(s.surveyId)}
          value={validSurveys.find(s => s.surveyId === filterEntry.surveyId)} />
      </div>
      <div className={styles.questionRow}>
        <Selector
          buttonClass={styles.questionSelectorButton}
          items={validQuestions}
          displayValue={v => `Q${v.ordinal}`}
          onSelect={q => onQuestionChange(q.base.id)}
          value={question} />
        {children}
        <div className={styles.deleteFilter} onClick={filterActions.deleteFilter}>
          <X />
        </div>
      </div>
    </div>
  );
};