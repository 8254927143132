import { EditorComponent, Remirror } from '@remirror/react';
import { cx } from '@utils';
import type { SurveyRichText } from '@/types';
import { useBasicSurveyRichTextEditor } from './hooks/useBasicSurveyRichTextEditor';
import styles from './style/Editor.css';

type Props = {
  value: SurveyRichText.RootNode;
  className?: string;
  editorClassName?: string;
};

export const BasicRichText = ({ value, className, editorClassName }: Props) => {
  const { manager, state, onChange } = useBasicSurveyRichTextEditor({
    initialState: value,
  });

  return (
    <div className={cx(styles.root, className)}>
      <Remirror
        classNames={[styles.editor, editorClassName]}
        editable={false}
        manager={manager}
        initialContent={state}
        onChange={onChange}>
        <EditorComponent />
      </Remirror>
    </div>
  );
};