import { useCallback, memo } from 'react';
import { GithubPicker } from 'react-color';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import Popper from '@mui/material/Popper';
import { ClickAwayListener } from '@mui/material';
import {
  useTextColorCommands,
} from '@/components/Remirror/hooks';
import { usePopperState, PopperWrapper } from '@/components/Popper';
import { ToolbarButton } from './Toolbar.Button';
import styles from './style/Toolbar.ColorButton.css';

export const ColorButton = () => {
  const { activeColor, setTextColor: setTextColorCommand } = useTextColorCommands();

  const { referenceElement, setReferenceElement, setPopperElement, styles: popperStyles, attributes, open, setOpen } = usePopperState({ placement: 'top' });

  const setTextColor = useCallback((color: string) => {
    setTextColorCommand(color);
    setOpen(false);
  }, [setTextColorCommand, setOpen]);

  return (
    <>
      <div ref={setReferenceElement}>
        <ToolbarButton onClick={() => setOpen(true)}>
          <ColorIcon color={activeColor} />
        </ToolbarButton>
      </div>
      <Popper
        open={open}
        ref={setPopperElement}
        style={popperStyles.popper}
        anchorEl={referenceElement}
        {...attributes}>
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div>
            <ColorPopperContent color={activeColor} onChange={setTextColor} />
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

const ColorIcon = memo(({ color }: { color: string }) => {
  return (
    <>
      <FormatColorTextIcon htmlColor={color} />
    </>
  );
});

const GITHUB_COLORS = ['#B80000', '#DB3E00', '#FCCB00', '#008B02', '#006B76', '#1273DE', '#004DCF', '#5300EB', '#000000', '#EB9694', '#FAD0C3', '#FEF3BD', '#C1E1C5', '#BEDADC', '#C4DEF6', '#BED3F3', '#D4C4FB', '#FFFFFF'];

const GitHubPickerContent = (props: ColorProps) => {
  return (
    <GithubPicker
      colors={GITHUB_COLORS}
      width={'225px'}
      color={props.color}
      onChange={e => props.onChange(e.hex)}
      triangle='hide' />
  );
};

type ColorProps = {
  color: string;
  onChange: (color: string) => void;
};

const ColorPopperContent = (props: ColorProps) => {
  return <GitHubPickerContent {...props} />;
};