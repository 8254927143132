import { Chip } from '@presentation/Chip';
import { Label } from '@presentation/Label';
import { cx, trunc } from '@utils';
import * as Tag from '@/components/Tag/Link';
import styles from './style/LabeledTags.css';

type TagItem =
  & Pick<Descriptor, 'id'>
  & Partial<Pick<Descriptor, 'name'>>
  & { children?: string; }
  & { text?:     string; };

type Props<T extends TagItem = TagItem> = {
  className?:      string;
  items:           T[];
  label:           string;
  labelClassName?: string;
  maxTagLength:    number;
  onClick?:        (item: T) => unknown;
  showEmpty:       boolean;
  tagClassName?:   string;
};

export const LabeledTags = <T extends TagItem = TagItem>(props: Props<T>) => {
  if (!props.items.length && !props.showEmpty) return null;

  function getChild(item: T) {
    return trunc(item.children || item.name || item.text, props.maxTagLength);
  }

  const classes = cx(
    styles.root,
    props.className,
  );

  const labelClasses = cx(
    styles.label,
    props.labelClassName,
  );

  const tagClasses = cx(
    {
      [styles.static]: !props.onClick,
      [styles.clickable]: !!props.onClick,
    },
    props.tagClassName,
  );

  return (
    <Label
      className={classes}
      label={props.label}
      labelStyle={labelClasses}>
      {props.items.map(x =>
        <Chip key={x.id} className={tagClasses} readonly>
          <Chip.Body.Basic>
            {getChild(x)}
          </Chip.Body.Basic>
        </Chip>
      )}
    </Label>
  );
};

const defaultProps = {
  maxTagLength: 21,
  showEmpty: false,
};

LabeledTags.defaultProps = defaultProps;

type LabeledTagContainerProps = {
  children: React.ReactNode;
} & Pick<Props, 'className' | 'label' | 'labelClassName'>;

export const LabeledTagContainer = (props: LabeledTagContainerProps) => {
  const classes = cx(
    styles.root,
    props.className,
  );

  const labelClasses = cx(
    styles.label,
    props.labelClassName,
  );

  return (
    <Label
      className={classes}
      label={props.label}
      labelStyle={labelClasses}>
      {props.children}
    </Label>
  );
};

LabeledTagContainer.displayName = 'LabeledTag.Container';