export const format = (str: string) => {
  const cleaned = ('' + str).replace(/\D/g, '');
  if (cleaned.length === 6) {
    const match = cleaned.match(/^(\d{3})(\d{3})$/);

    if (match) {
      return [match[1], '-', match[2]].join('');
    }
  }
  else if (cleaned.length === 8) {
    const match = cleaned.match(/^(\d{4})(\d{4})$/);

    if (match) {
      return [match[1], '-', match[2]].join('');
    }
  }

  return str;
};

export const parseDigits = (str: string) => {
  const cleaned = ('' + str).replace(/\D/g, '');
  return cleaned.substring(0, 6);
};