import { useMemo } from 'react';
import { FieldSelectNumber } from './Field.Select';
import styles from './style.css';

type Props = {
  classes?: {
    label?: string;
  };
  isEditing: boolean;
  displayValue: string;
  onChange?: (value: number) => void;
};

export function FieldCallDuration({ classes = {}, isEditing, displayValue, onChange }: Props) {
  const options = useMemo(() => {

    return [15, 30, 45, 60, 75, 90, 105, 120, 150, 180, 210, 240].reduce<{ [k: string]: string }>((acc, curr) => {
      acc[curr] = curr.toString();
      return acc;
    }, {});
  }, []);

  return (
    <div className={styles.section}>
      <FieldSelectNumber
        classes={classes}
        editing={isEditing}
        label="Call Duration"
        name="defaultDuration"
        displayValue={displayValue}
        onChange={value => onChange?.(+value)}
        options={options} />
    </div>
  );
}