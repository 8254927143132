import { Popper, styled } from '@mui/material';/*  */

const PREFIX = 'htc';

export const classes = {
  popper: `${PREFIX}-popper`,
  arrow: `${PREFIX}-arrow`,
};

export const StyledPopper = styled(Popper)(({ theme }) => {
  const bgColor = `var(--pri-01)`;
  const borderColor = `var(--gray-l)`;

  const borderWidth = 1;
  const size = 10;

  const borderSize = size + Math.round(borderWidth * Math.sqrt(2));

  return {
    [`& .${classes.popper}`]: {
      [`&[data-popper-placement="top"] ${classes.arrow}`]: {
        bottom: 0,
        '&::before': {
          top: '100%',
          left: '50%',
          borderTopColor: borderColor,
          marginLeft: `-${borderSize}px`,
        },
        '&::after': {
          top: '100%',
          left: '50%',
          borderTopColor: bgColor,
          marginLeft: `-${size}px`,
        },
      },
    },
    [`& ${classes.arrow}`]: {
      position: 'absolute',
      background: bgColor,
      border: `${borderWidth}px solid transparent`,
      '&::before': {
        border: 'solid transparent',
        content: '""',
        height: 0,
        width: 0,
        position: 'absolute',
        pointerEvents: 'none',
        borderWidth: `${borderSize}px`,
      },
      '&::after': {
        border: 'solid transparent',
        content: '""',
        height: 0,
        width: 0,
        position: 'absolute',
        pointerEvents: 'none',
        borderWidth: `${size}px`,
      },
    },
  };
});