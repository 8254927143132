export enum SocketNamespace {
  Conference = 'conference',
  Files = 'files',
  Notifications = 'notifications-v2',
  Pipeline = 'pipeline',
  Projects = 'projects',
  Reports = 'reports',
  Transcripts = 'transcripts',
  User = 'user',
}

export enum LifeCycleSocketEvent {
  Authenticated = 'authenticated',
  Authenticate  = 'authenticate',
  Connect       = 'connect',
  ConnectError  = 'connect_error',
  Disconnect    = 'disconnect',
  Unauthorized  = 'unauthorized',
}

export enum NotificationsSocketEvent {
  AppRehydrate  = 'app-rehydrate',
  Dismiss       = 'dismiss',
  New           = 'new',
  Seen          = 'seen',
}

export enum PipelineSocketEvent {
  RecordUpdated = 'record-updated',
  RecordCreated = 'record-created',
}

export enum UserSocketEvent {
  InfoUpdated = 'info-updated',
}

export enum FileSocketEvent {
  ChatAvailable = 'file-chat-available',
  PreviewAvailable = 'preview-available',
  TranscriptAvailable = 'transcript-available',
}

export enum ReportSocketEvent {
  ReportAvailable = 'report-available',
  ReportError = 'report-error',
}

export enum ConferenceSocketEvent {
  Negotiate                    = 'negotiate',
  NegotiateReconnect           = 'negotiate-reconnect',
  Join                         = 'join',
  WaitingRoomParticipant       = 'waiting-room-participant',
  WaitingRoomAdmit             = 'waiting-room-admit',
  WaitingRoomReject            = 'waiting-room-reject',
  WaitingRoomStatus            = 'waiting-room-status',
  Enter                        = 'enter',
  ParticipantEntered           = 'participant-entered',
  ParticipantUpdated           = 'participant-updated',
  ParticipantLeft              = 'participant-left',
  ParticipantsList             = 'participants-list',
  MuteParticipant              = 'mute-participant',
  RemoveParticipant            = 'remove-participant',
  UpdateParticipantVisibility  = 'update-participant-visibility',
  UpdateParticipantName        = 'update-participant-name',
  GiveHost                     = 'give-host',
  ConferenceUpdate             = 'conference-update',
  Leave                        = 'leave',
  ConferenceEnd                = 'conference-end',
  ConferenceDialOut            = 'conference-dial-out',
  ChatToken                    = 'chat-token',
  InitializeConversation       = 'initialize-conversation',
  RecordingStatusChange        = 'recording-status-change',
  ChangeParticipantRole        = 'change-participant-role',
  LogDebug                     = 'log-debug',
  RequestLog                   = 'request-log',
  UpdateSurvey                 = 'update-survey',
  LogEvent                     = 'log-event',
  ParticipantDevicesUpdated    = 'participant-devices-updated',
}

export enum TranscriptSocketEvent {
  RedactionComplete = 'redaction-complete',
  RedactionError = 'redaction-error',
  TagSummariesAvailable = 'tag-summaries-available',
}

export enum ProjectSocketEvent {
  TagSummariesAvailable = 'tag-summaries-available',
  TranscriptCleanupEvent = 'transcript-cleanup-event',
}

export type SocketEvent =
  LifeCycleSocketEvent |
  NotificationsSocketEvent |
  PipelineSocketEvent |
  ConferenceSocketEvent |
  FileSocketEvent |
  ProjectSocketEvent |
  ReportSocketEvent |
  TranscriptSocketEvent |
  UserSocketEvent
;