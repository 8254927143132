import { useContext, useMemo } from 'react';
import { SelectedObjectsContext, FilesTableDataContext } from '../context';

export const useSelectedObjects = () => {
  const items = useContext(FilesTableDataContext);
  const selectedCtx = useContext(SelectedObjectsContext);

  return useMemo(() => {
    if (!selectedCtx?.selectedObjectIds) return [];

    return items.filter(i => selectedCtx.selectedObjectIds.includes(i.object.id));
  }, [items, selectedCtx?.selectedObjectIds]);

};