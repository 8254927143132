import qs from 'query-string';
import http from '@services/http';
import { ContentFeed } from '@/types';
import { FeedItemType } from '@enums';
import * as Feed from './interfaces';
import { normalizePost } from './transformers';

export const fetchPost = (data: Feed.FetchPost.Request) => {
  return http.get<Feed.FetchPost.Response<string>>(`/feed/${data.postId}`)
  .then(data => {
    if (data?.post?.contentType === FeedItemType.Post) {
      return {
        post: normalizePost(data.post),
      };
    }
  });
};

export const fetchPosts = (data: Feed.FetchPosts.Request) => {
  return http.get<Feed.FetchPosts.Response>(`/feed?${qs.stringify(data, { arrayFormat: 'bracket' })}`)
    .then(data => ({
      items: data.items.map<ContentFeed.FeedItem>(i => {
        if (i.contentType == FeedItemType.Post) {
          return normalizePost(i) as ContentFeed.FeedItem;
        }
        return i;
      }),
    }));
};