import { Fragment, memo } from 'react';
import format from 'date-fns/format';
import Popover from 'material-ui-popup-state/HoverPopover';
import { bindHover, bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import { User } from '$admin/components/Table/Cell';
import type { Results } from '$admin/Search/interfaces/members';
import styles from './style/Table.css';

export const Cell = memo(({ row }: Results.TableCellProps) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'employments-popover',
  });

  const hoverProps = row.original.employments?.length
    ? bindHover(popupState)
    : undefined;

  return (
    <Fragment>
      <div {...hoverProps}>
        <User
          id={row.original.user.id}
          name={row.original.user.fullname}
          pictureUrl={row.original.user.pictureUrl} />
      </div>
      <Popover
        {...bindPopover(popupState)}
        sx={{
          pointerEvents: 'none',
          cursor: 'default',
        }}
        slotProps={{
          paper: {
            sx: {
              backgroundColor: 'var(--pri-01)',
              border: '1px solid transparent',
              borderRadius: '4px',
              boxShadow: `
                  0px 5px 5px -3px rgba(0,0,0,0.2),
                  0px 8px 10px 1px rgba(0,0,0,0.14),
                  0px 3px 14px 2px rgba(0,0,0,0.12)`,
            },
          },
        }}
        anchorOrigin={{
          vertical: 35,
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <div className={styles.employments}>
          {row.original.employments.map((e, i) => (
            <div
              key={i}
              className={styles.employmentsRow}>
              <div className={styles.employmentsLabel}>{e.title}, <span className={styles.company}>{e.company}</span> ({formatDate(e.start)} - {formatDate(e.end)})</div>
            </div>
          ))}
        </div>
      </Popover>
    </Fragment>
  );
});

function formatDate(value: Date) {
  if (!value) return 'Current';
  return format(value, 'MMM yyyy');
}

export const Filter = memo(() => null);

export const Header = memo(() => {
  return (
    <Fragment>
      Name
    </Fragment>
  );
});

Cell.displayName = 'Column.Name.Cell';
Filter.displayName = 'Column.Name.Filter';
Header.displayName = 'Column.Name.Header';