import * as surveyBuilder from '@containers/SurveyBuilder/utils';
import { useCanCreateFindAndReplace } from '@/containers/SurveyBuilder/hooks/useCanCreateFindAndReplace';
import { QuestionOptionsBuilderContainer } from '@containers/SurveyBuilder.Question/OptionsBuilderContainer';
import { QuestionRowsBuilderContainer } from '@containers/SurveyBuilder.Question/RowsBuilderContainer';
import { QuestionSettingTitle } from '@presentation/SurveyBuilder';
import type { MatrixGridQuestion } from '@/types';
import { QuestionColumnsBuilder } from './OptionsBuilder';
import { ResponsePiping } from './ResponsePiping';
import { QuestionRowsBuilder } from './RowsBuilder';
import {
  MatrixNotApplicable,
  MatrixDisplaySetting,
  MatrixGroupBySetting,
  RandomizeOptionsCheckbox,
  RandomizeRowsCheckbox,
  OrderRowsBasedOnSource,
  RationaleCheckbox,
} from './Settings';
import styles from './style/MatrixGrid.Question.css';
import { useHasLinkedRows } from './hooks';
import { ReuseQuestionRows } from './ReuseQuestionValues';
import { FindAndReplaceOptions, FindAndReplaceRows } from './FindAndReplace';
import { MatrixGridRowContextMenu } from './MatrixGrid.Row.ContextMenu';
import { OptionContextMenu } from './Option.ContextMenu';
import { LanguageEditingBarrier } from './LanguageEditingBarrier';

type Props = {
  item: MatrixGridQuestion.Question;
};

export const MatrixGridQuestionBuilder = ({ item }: Props) => {

  const hasLinkedRows = useHasLinkedRows(item);
  const canFar = useCanCreateFindAndReplace();

  return (
    <>
      <QuestionSettingTitle title="Rows">
        <ResponsePiping
          disabled={hasLinkedRows}
          type="rows" />
        <ReuseQuestionRows />
        {canFar ? <FindAndReplaceRows question={item} /> : null}
      </QuestionSettingTitle>

      <QuestionRowsBuilderContainer
        maxRows={surveyBuilder.matrixGrid.MaxRows}
        minRows={surveyBuilder.matrixGrid.MinRows}>
        <QuestionRowsBuilder
          className={styles.rows}
          renderAction={row => (
            <MatrixGridRowContextMenu
              question={item}
              row={row as MatrixGridQuestion.Row} />
          )} />
      </QuestionRowsBuilderContainer>

      <LanguageEditingBarrier>
        <RandomizeRowsCheckbox
          disabled={item.settings.orderRowsBasedOnSource}
          className={styles.checkbox} />

        {hasLinkedRows &&
          <OrderRowsBasedOnSource
            disabled={item.settings.randomizeRows}
            className={styles.checkbox} />
        }

        <QuestionSettingTitle title="Columns">
          <ResponsePiping />
          {canFar ? <FindAndReplaceOptions question={item} /> : null}
        </QuestionSettingTitle>

        <QuestionOptionsBuilderContainer
          maxOptions={surveyBuilder.matrixGrid.MaxColumns}
          minOptions={surveyBuilder.matrixGrid.MinColumns}>
          <QuestionColumnsBuilder
            className={styles.options}
            renderAction={option => (
              <OptionContextMenu option={option} excludeFindAndReplace={false} />
            )} />
        </QuestionOptionsBuilderContainer>

        <MatrixNotApplicable
          className={styles.checkbox} />

        <RandomizeOptionsCheckbox
          label="Randomize Columns"
          className={styles.checkbox} />

        <MatrixGroupBySetting
          className={styles.checkbox}
          value={item.settings.groupBy} />

        <MatrixDisplaySetting
          className={styles.checkbox}
          value={item.settings.display} />

        <RationaleCheckbox className={styles.checkbox} />
      </LanguageEditingBarrier>
    </>
  );
};

export default MatrixGridQuestionBuilder;