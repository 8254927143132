import { Invitation } from './Invitation';
import { SchedulingProvidingComponentProps } from './interfaces';
import styles from './style/ProjectOnboardingResponsive.css';

type Props =
    SchedulingProvidingComponentProps;

const ProjectOnboardingResponsive = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Invitation
          className={styles.calendar}
          creatorId={props.creatorId}
          onSubmit={props.onSubmit}
          scheduleeId={props.scheduleeId}
          schedulerId={props.schedulerId}
          userId={props.userId} />
      </div>
    </div>
  );
};

export { ProjectOnboardingResponsive };
export default ProjectOnboardingResponsive;