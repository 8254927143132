import { useLock } from '@utils';
import { Button } from '@/components/Button';
import type { ButtonBaseProps } from '@/components/Button/interfaces';
import { useFullCalButtonState } from '@/components/Calendar/hooks/useFullCalButtonState';

type Props = {
  children: React.ReactNode;
  disabled: boolean;
  className?: string;
  onClick: () => Promise<unknown>;
} & Pick<ButtonBaseProps, 'color' | 'variant'>;

const CalendarButton = ({
  color = 'affirmative',
  variant = 'brick',
  ...props
}: Props) => {
  const [isBusy, lock] = useLock();

  /*
  * Hack to update the button created in the
  * header of the full calendar component.
  */
  useFullCalButtonState(props.disabled);

  return (
    <Button
      className={props.className}
      color={color}
      disabled={props.disabled}
      onClick={lock(props.onClick)}
      variant={variant}>
      {props.children}
    </Button>
  );
};

export default CalendarButton;