import { useCallback } from 'react';
import { Button } from '@/components/Button';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { ModalProps } from '@/components/Modal/Modal';
import { Prompt } from '@/components/Modal/Prompt';
import { useDeleteFolderContext } from '@/containers/Workspace.Folder/hooks/useDeleteFolderContext';
import { useLock, useMounted } from '@/utils';
import styles from './style/Prompt.css';

type Props =
  Pick<ModalProps, 'open' | 'onClose'>;

export const DeleteFolderPrompt = ({
  onClose,
  open,
}: Props) => {
  const onConfirm = useDeleteFolderContext();
  const isMounted = useMounted();
  const [busy, lock] = useLock();

  const handleSave = useCallback(() => {
    return onConfirm()
      .then(_ => {
        if (isMounted()) {
          onClose();
        }
      });
  }, [
    isMounted,
    onClose,
    onConfirm,
  ]);

  const Footer = useCallback(() => {
    return (
      <ButtonSet>
        <Button.Destructive
          className={styles.btn}
          onClick={onClose}
          variant="brick">
          Cancel
        </Button.Destructive>
        <Button.Primary
          className={styles.btn}
          onClick={lock(handleSave)}
          variant="brick">
          Remove
        </Button.Primary>
      </ButtonSet>
    );
  }, [
    handleSave,
    lock,
    onClose,
  ]);

  return (
    <Prompt
      body="Are you sure you want to remove this folder?"
      footer={Footer()}
      header="Remove Folder"
      onClose={onClose}
      visible={open} />
  );
};

export default DeleteFolderPrompt;