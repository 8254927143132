import { Fragment } from 'react';
import { TableCellItem } from './interfaces';
import styles from './style/Search.Members.css';

type Props = TableCellItem;

export const Cell = (props: Props) => {

  return (
    <Fragment>
      {props.row.original.specialty.length ? props.row.original.specialty.join(', ') : '-'}
    </Fragment>
  );
};

Cell.displayName = 'Column.Specialty.Cell';