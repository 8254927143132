import { useContext } from 'react';
import { BrandThemingContext } from '@containers/Branding/Context';
import { CompletionImage } from './Settings.Branding.Survey.CompletionImage';
import { CompletionText } from './Settings.Branding.Survey.CompletionText';
import { Palette } from './Settings.Branding.Survey.Palette';
import styles from './style/Settings.Branding.Survey.css';

type Props = unknown;

export const Survey = (props: Props) => {
  const { query } = useContext(BrandThemingContext);

  if (!query.data) return null;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <Row>
            <Palette />
          </Row>

          <Row>
            <CompletionText />
          </Row>

          <Row>
            <CompletionImage />
          </Row>
        </div>
      </div>
    </div>
  );
};

Survey.displayName = 'Settings.Branding.Survey';

type RowProps = {
  children?: React.ReactNode;
};

const Row = (props: RowProps) => {
  return (
    <div className={styles.row}>
      {props.children}
    </div>
  );
};

Row.displayName = 'Settings.Branding.Survey.Row';