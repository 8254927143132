import { useCallback } from 'react';
import { usePlatformMessageField } from '@containers/PlatformMessage/hooks/usePlatformMessageField';
import * as PlatformMessage from '@containers/PlatformMessage/interfaces';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { cx } from '@utils';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import styles from '@/components/Modal/style/PaidPlatformMessage.css';

type Props = {
  onComplete: () => unknown;
  onError?:   (e: Error) => unknown;
  user:       PlatformMessage.RecipientUser;
} & Pick<ModalProps,
    | 'className'
    | 'onClose'
    | 'open'>;

export const PaidPlatformMessage = (props: Props) => {
  const ctx = usePlatformMessageField({
    paid: true,
    user: props.user,
  });

  const trimmed = ctx.value.trim();

  const handleSubmit = useCallback(() => {
    if (!trimmed) return;

    ctx.onSubmit()
    .then(props.onComplete)
    .catch(props.onError);

  }, [
    ctx,
    props.onComplete,
    props.onError,
    trimmed,
  ]);

  if (!props.user) return null;

  return (
    <Modal
      className={styles.root}
      open={props.open}
      onClose={props.onClose}>
      <div className={styles.wrap}>

        <div className={styles.header}>
          <div className={styles.row}>
            <div className={styles.title}>
              {`Ask ${props.user.profile.firstName} a Question `}
            </div>
          </div>
        </div>

        <div className={cx(styles.row, styles.stretch)}>
          <textarea
            className={styles.textarea}
            onChange={ctx.onChange}
            placeholder="Customize the invitation message."
            rows={10}
            name="message"
            value={ctx.value} />
        </div>

        <div className={cx(styles.row, styles.footer)}>
          <ButtonActivityIndicator
            className={styles.btn}
            color="affirmative"
            disabled={!trimmed || ctx.submitting}
            implicitDisable={ctx.submitting}
            loading={ctx.submitting}
            onClick={handleSubmit}>
            Send
          </ButtonActivityIndicator>
        </div>

        <div className={styles.row}>
          <div className={styles.subtitle}>
            {`We'll only charge you if ${props.user.profile.firstName} responds!`}
          </div>
        </div>
      </div>
    </Modal>
  );
};

PaidPlatformMessage.displayName = 'PlatformMessage.PaidPlatformMessage';