import { useMemo } from 'react';
import type { SurveyBuilderCapabilitiesContextValue } from './Context';
import { SurveyBuilderCapabilitiesContext } from './Context';
import { getDefaultCapabilities } from './state/defaults';

type Props = Partial<SurveyBuilderCapabilitiesContextValue> & ChildrenProps;

export const CapabilitiesContainer = ({ children, ...props }: Props) => {
  const capabilities = useMemo(() => {
    return { ...getDefaultCapabilities(), ...props };
  }, [props]);

  return (
    <SurveyBuilderCapabilitiesContext.Provider value={capabilities}>
      {children}
    </SurveyBuilderCapabilitiesContext.Provider>
  );
};