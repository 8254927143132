import { useCallback } from 'react';
import { useReactTable, createColumnHelper, getCoreRowModel } from '@tanstack/react-table';
import { Edit2, Trash } from 'react-feather';
import { useMutation } from '@tanstack/react-query';
import { utils } from '@enums';
import { BaseProjectSurvey } from '@/types';
import { useSurveyBuilderState, useUpdateTemplate } from '@containers/SurveyBuilder/hooks';
import * as $api from '@api';
import * as Table from '@/components/Table';
import Toast from '@/components/Toast';
import { useAlert } from '@/components/Modal/Alert';
import { useProjectFormContext, useProjectSurveyStepperContext } from './context';
import styles from './style/ProjectSurvey.Table.css';

type TableItem = BaseProjectSurvey;
const columnHelper = createColumnHelper<TableItem>();
const columns = [
  columnHelper.accessor('name', {
    cell: info => <NameCell item={info.row.original} />,
    header: 'Survey Name',
    meta: {
      className: styles.nameCell,
    },
  }),
  columnHelper.accessor('typeId', {
    cell: info => utils.SurveyType.getName(info.row.original.typeId),
    header: 'Type',
    meta: {
      className: styles.typeCell,
    },
  }),
  columnHelper.display({
    id: 'actions',
    cell: info => <SurveyTableActions surveyId={info.row.original.id} />,
    header: '',
    meta: {
      className: styles.actionsCell,
    },
  }),
];

export const SurveyTable = () => {
  const [form] = useProjectFormContext();
  const surveys = form.surveys;

  const table = useReactTable({
    data: surveys,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (!surveys.length) return null;

  return (
    <div>
      <Table.Root className={styles.table}>
        <Table.Header
          classes={{
            thead: styles.thead,
            tr: styles.tr,
            th: styles.th,
          }}
          getHeaderGroups={table.getHeaderGroups}
          getHeaderCellStyles={def => def.meta?.['className']} />
        <Table.Body
          classes={{ tbody: styles.tbody }}
          getBodyCellStyles={def => def.meta?.['className']}
          getRowModel={table.getRowModel} />
      </Table.Root>
    </div>
  );
};

type NameCellProps = { item: TableItem };
const NameCell = ({ item }: NameCellProps) => {
  const onClick = useEditSurvey({ surveyId: item.id });
  return (
    <div className={styles.name} onClick={onClick}>{item.name}</div>
  );
};

const SurveyTableActions = ({ surveyId }: ISurveyId) => {
  const onEdit = useEditSurvey({ surveyId });
  const onDelete = useDeleteSurvey({ surveyId });
  const [toggleAlert, Alert] = useAlert();

  return (
    <>
      <div className={styles.actions}>
        <Edit2 className={styles.action} onClick={onEdit} />
        <Trash className={styles.action} onClick={toggleAlert} />
      </div>
      <Alert message={`Deleting a survey is not reversible. Are you sure you want to delete it?`} onConfirm={onDelete} />
    </>
  );
};

const useEditSurvey = ({ surveyId }: ISurveyId) => {
  const [_, dispatch] = useSurveyBuilderState();
  const stepperActions = useProjectSurveyStepperContext();

  const loadSurvey = useMutation(['load-survey', surveyId], () => {
    return $api.surveys.fetchSurvey({ surveyId });
  });

  const onEdit = useCallback(() => {
    loadSurvey.mutate(undefined, {
      onSuccess: result => {
        dispatch({
          type: 'reset-survey',
          value: result,
        });
        stepperActions.next();
      }, onError: () => {
        Toast.error({
          title: 'There was an error loading the survey.',
        });
      },
    });
  }, [dispatch, stepperActions, loadSurvey]);

  return onEdit;
};

const useDeleteSurvey = ({ surveyId }: ISurveyId) => {
  //TODO: mutation to delete the survey
  const [form, dispatch] = useProjectFormContext();
  return useCallback(() => {
    dispatch({
      surveys: form.surveys.filter(s => s.id != surveyId),
    });
  }, [surveyId, form.surveys, dispatch]);
};