import { useContext, useMemo } from 'react';
import type { CSSObjectWithLabel } from 'react-select';
import Select from 'react-select';
import { Checkbox } from '@/components/Checkbox';
import { Input } from '@/components/Input';
import { ColorSelect } from '@/components/Conference.Tagging/Tagging.ColorSelect';
import { TagFormContext, TagStateContext } from '@/components/Project.Tags/Context';
import styles from './style/CreateTagModal.css';

type Props = {
  disableParent?: boolean;
  excludeParentIds?: number[];
};

export const TagForm = ({ disableParent = false, excludeParentIds = [] }: Props) => {
  const { data, dispatch } = useContext(TagFormContext);
  const { state } = useContext(TagStateContext);

  const parentOptions = useMemo(() => {
    return state.tags.filter(t => !excludeParentIds.includes(t.id)).map(t => ({
      value: t.id,
      label: t.base.name,
    }));
  }, [state, excludeParentIds]);

  const parentValue = parentOptions.find(o => o.value === data.parentId);
  return (
    <>
      <Section title='Tag Title'>
        <Input
          value={data.title}
          onChange={e => dispatch({ title: e.target.value })}
          placeholder='Input Title' />
      </Section>
      <Section title='Parent'>
        <Select
          isDisabled={disableParent}
          value={parentValue ?? { value: null, label: 'None' }}
          options={parentOptions}
          getOptionLabel={o => o.label}
          isClearable={true}
          menuPortalTarget={document.body}
          styles={{ menuPortal: base => ({ ...base, zIndex: 8 }) as CSSObjectWithLabel }}
          onChange={v => dispatch({ parentId: v?.value })} />
      </Section>
      <Section title='Color'>
        <ColorSelect color={data.color} onChange={c => dispatch({ color: c })} />
      </Section>
      <Section title='Include Summary'>
        <Checkbox checked={data.includeSummary} onChange={c => dispatch({ includeSummary: c.target.checked })} />
      </Section>
    </>
  );
};

type SectionProps = { title: string } & ChildrenProps;
const Section = (props: SectionProps) => {
  return (
    <div>
      <div className={styles.section}>
        <div className={styles.title}>{props.title}</div>
        {props.children}
      </div>
    </div>
  );
};