import { useMemo } from 'react';
import { useSelectUser } from '@containers/Store/hooks';

type Params = {
  authorId: number;
  depth: number;
  onDelete: () => void;
  onReply: () => void;
};

export const useCommentContextMenu = (params: Params) => {
  const me = useSelectUser();

  const reply = useMemo(() => {
    if (params.depth > 2) return null;

    return {
      children: `Reply`,
      onClick: params.onReply,
    };
  }, [
    params.depth,
    params.onReply,
  ]);

  const remove = useMemo(() => {
    if (me.id !== params.authorId) return null;

    return {
      children: `Delete`,
      onClick: params.onDelete,
    };
  }, [
    params.authorId,
    params.onDelete,
    me.id,
  ]);

  const items = [
    remove,
    reply,
  ].filter(Boolean);

  return items;
};

type MenuItem = {
  children: string;
  onClick: () => void;
};