import { useMemo } from 'react';
import type { SurveyQuestionType } from '@enums';
import { useSurveyFormQuestionContext, useValidateRationale } from './hooks';
import { SurveyFormCanSubmitContext, useSurveyFormQuestionAnswer } from './Context';
import { SurveyFormBackContainer, SurveyFormSubmitContainer } from './BaseNavigation';

type Props =
  ChildrenProps;

export const MultiTextboxFormContainer = (props: Props) => {
  const [answer] = useSurveyFormQuestionAnswer<SurveyQuestionType.MultiTextbox>();
  const question = useSurveyFormQuestionContext<SurveyQuestionType.MultiTextbox>();

  const validateRationale = useValidateRationale();

  const canSubmit = useMemo(() => {

    if (answer.dontKnow) return true;

    const isRationaleValid = validateRationale();

    if (!isRationaleValid) return false;

    const withValues = answer.values.filter(f => !!f && f !== '');

    if (withValues.length < question.settings.minimumEntries ||
      withValues.length > question.settings.maximumEntries) return false;

    return true;
  }, [
    answer,
    question.settings,
    validateRationale,
  ]);

  return (
    <SurveyFormCanSubmitContext.Provider value={canSubmit}>
      <SurveyFormBackContainer>
        <SurveyFormSubmitContainer>
          {props.children}
        </SurveyFormSubmitContainer>
      </SurveyFormBackContainer>
    </SurveyFormCanSubmitContext.Provider>
  );
};

export default MultiTextboxFormContainer;