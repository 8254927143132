import { useCallback } from 'react';
import { useInvalidateFilesQueries } from '@utils/api';
import { useDeleteFile } from './hooks';
import { DeleteFileContext } from './Context';

type Props =
  IWorkspaceId &
  IWorkspaceObjectId &
  IWorkspaceFileId &
  ChildrenProps;

export const DeleteFileContainer = (props: Props) => {

  const deleteFile = useDeleteFile();
  const invalidateQueries = useInvalidateFilesQueries();

  const handleDelete = useCallback(() => {
    return deleteFile({
      fileId: props.fileId,
      objectId: props.objectId,
      workspaceId: props.workspaceId,
    })
    .then(() => {
      invalidateQueries();
    });
  }, [
    deleteFile,
    invalidateQueries,
    props.fileId,
    props.objectId,
    props.workspaceId,
  ]);

  return (
    <DeleteFileContext.Provider value={handleDelete}>
      {props.children}
    </DeleteFileContext.Provider>
  );
};

export default DeleteFileContainer;