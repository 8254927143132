import http from '@services/http';
import { qs } from '@utils';
import { Groups } from './interfaces';

export const fetch = (data: Groups.Contacts.Fetch.Request) => {
  const { groupId, ...params } = data;
  const q = qs.stringify(params);

  return http.get<Groups.Contacts.Fetch.Response>(`/groups/${groupId}/contacts?${q}`);
};

export const fetchRecord = (data: Groups.Contacts.FetchRecord.Request) => {
  return http.get<Groups.Contacts.FetchRecord.Response>(`/groups/${data.groupId}/contacts/records/${data.contactId}`);
};

export const remove = (data: Groups.Contacts.Remove.Request) => {
  const q = qs.stringify({ ids: data.ids }, { arrayFormat: 'bracket' });

  return http.delete<Groups.Contacts.Remove.Response>(`/groups/${data.groupId}/contacts?${q}`);
};

export const updateRecord = (data: Groups.Contacts.UpdateRecord.Request) => {
  const { contactId, groupId, ...rest } = data;

  return http.patch<Groups.Contacts.UpdateRecord.Response>(`/groups/${groupId}/contacts/records/${contactId}`, rest);
};

export const updateRecordTags = (data: Groups.Contacts.UpdateRecordTags.Request) => {
  const { contactId, groupId, ...rest } = data;

  return http.post<Groups.Contacts.UpdateRecordTags.Response>(`/groups/${groupId}/contacts/records/${contactId}/tags`, rest);
};

export const upload = (data: Groups.Contacts.Upload.Request) => {
  const form = new FormData();

  form.append('contacts', data.file, data.file.name);

  return http.post<Groups.Contacts.Upload.Response>(`/groups/${data.groupId}/contacts/import`, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const analyzeCSV = (data: Groups.Contacts.CSVAnalyze.Request) => {
  const form = new FormData();

  form.append('contacts', data.file, data.file.name);

  return http.post<Groups.Contacts.CSVAnalyze.Response>(`/groups/${data.groupId}/contacts/import/analyze`, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const finalizeCSV = (data: Groups.Contacts.CSVFinalize.Request) => {
  const form = new FormData();

  form.append('contacts', data.file, data.file.name);
  form.append('email', data.email);

  return http.post<Groups.Contacts.CSVFinalize.Response>(`/groups/${data.groupId}/contacts/import/finalize`, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const importCSV = (data: Groups.Contacts.CSVImport.Request) => {
  const form = new FormData();

  form.append('contacts', data.file, data.file.name);
  form.append('email', data.email);
  form.append('company', data.company);
  form.append('firstName', data.firstName);
  form.append('lastName', data.lastName);
  form.append('phone', data.phone);
  form.append('title', data.title);
  form.append('tagging', JSON.stringify(data.tagging));

  return http.post<Groups.Contacts.CSVImport.Response>(`/groups/${data.groupId}/contacts/import/run`, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const searchTags = (data: Groups.Contacts.SearchTags.Request) => {
  const { groupId, ...params } = data;

  const q = qs.stringify(params, { arrayFormat: 'bracket' });

  return http.get<Groups.Contacts.SearchTags.Response>(`/groups/${groupId}/contacts/tags?${q}`);
};

export const finalizeHubSpot = (data: IGroupId) => {
  return http.get<Groups.Contacts.CSVFinalize.Response>(`/groups/${data.groupId}/contacts/import/hubspot/finalize`);
};

export const finalizeSalesforce = (data: IGroupId) => {
  return http.get<Groups.Contacts.CSVFinalize.Response>(`/groups/${data.groupId}/contacts/import/salesforce/finalize`);
};

export const importHubSpot = (data: IGroupId) => {
  return http.post<Groups.Contacts.CSVFinalize.Response>(`/groups/${data.groupId}/contacts/import/hubspot/run`, {});
};

export const importSalesforce = (data: IGroupId) => {
  return http.post<Groups.Contacts.CSVFinalize.Response>(`/groups/${data.groupId}/contacts/import/salesforce/run`, {});
};