import { cx } from '@utils';
import { useFixedSidebar } from './hooks';
import styles from './style/Layout.css';

type Props = {
  children: React.ReactNode;
};

export const Scaffold = (props: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export const FixedColumn = (props: Props) => {
  const [ref, fixed] = useFixedSidebar();

  const fixedClassName = cx({
    [styles.fixed]: fixed,
  });

  return (
    <div className={styles.col}>
      <div
        className={fixedClassName}
        ref={ref}>
        {props.children}
      </div>
    </div>
  );
};

export const Column = (props: Partial<Props>) => {

  return (
    <div className={styles.col}>
      {props.children}
    </div>
  );
};