import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { cx } from '@utils';
import { UserAvatar } from '@/components/UserAvatar';
import {
  ConferenceBarParticipant,
  ParticipantsProps,
  ParticipantsIconProps,
} from './interfaces';
import styles from './style.css';

const AttendeeTag = () => (
  <div className={styles.attendee}>
    Attendee
  </div>
);

const OrganizerTag = () => (
  <div className={styles.organizer}>
    Organizer
  </div>
);

export function parseIdentity(value: string) {
  const [ _, userId, mode, phone ] = value?.split('_');

  return {
    mode,
    phone,
    userId: Number(userId),
  };
}

const BarParticipant = (item: ConferenceBarParticipant) => {
  const subtitle = item.metadata.hasMultipleActive
    ? `(${item.metadata.index})`
    : '';

  return (
    <div className={styles.row}>
      <UserAvatar
        className={styles.avatar}
        pictureUrl={item.contact.profile.pictureUrl} />
      <div className={styles.details}>
        <div className={styles.name}>
          {item.contact.profile.fullname}{subtitle}
        </div>
        {item.organizer ? <OrganizerTag /> : <AttendeeTag />}
      </div>
    </div>
  );
};

export function Participants(props: ParticipantsProps) {
  return (
    <div className={cx(styles.participants, props.className)}>
      <div className={styles.header}>Particpants</div>
      {props.participants
        .map(p => <BarParticipant key={`${p.contact.id}_${p.metadata.identity}`} {...p} />)}
    </div>
  );
}

export const ParticipantsIcon = (props: ParticipantsIconProps) => (
  <div
    className={cx(styles.icon, { [styles.active]: props.active }, props.className)}
    onClick={props.onClick}>
    <PeopleAltOutlinedIcon />
    {props.alert
      ? <div className={styles.badgeAlert}>{props.alert}</div>
      : props.participants > 0
        ? <div className={styles.badge}>{props.participants}</div>
        : null}
  </div>
);

Participants.defaultProps = {
  active: false,
};

export default Participants;