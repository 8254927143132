import { useCallback, useState } from 'react';
import { ProfileValidation } from '../interfaces';

type Props<T> = {
  validate: (value: T) => ProfileValidation.Validation[];
};

export const useProfileFormValidation = <T>({
  validate,
}: Props<T>) => {

  const [validations, setValidations] = useState<ProfileValidation.ValidationsMap>({});

  const runValidations = useCallback((value: T, updateOnFailure = false) => {
    const v = validate(value);

    if (v.length) {
      const map = v.reduce((acc, { field, message }) => ({
        ...acc,
        [field]: message,
      }), {});

      if (updateOnFailure) {
        setValidations(map);
      }
      return false;
    }

    setValidations({});
    return true;
  }, [validate]);

  return [validations, runValidations] as const;
};

export default useProfileFormValidation;