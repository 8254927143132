import { memo, useMemo } from 'react';
import type { CellContext } from '@tanstack/react-table';
import Markdown, { type Options as MarkdownOptions } from 'react-markdown';
import type { ProjectClips } from '@/types/project.clips';
import { Collapser, SeeMore } from '@/presentation/SeeMore';
import styles from './style/Cell.Content.css';

type Props = CellContext<ProjectClips.Item, unknown>;

export const ContentCell = ({ row }: Props) => {

  return (
    <ClipContent
      value={row.original.content} />
  );
};

type ContentProps = {
  value: ProjectClips.Monologue[];
};

const ClipContent = memo(({ value }: ContentProps) => {

  return (
    <div className={styles.root}>
      <SeeMore
        CollapserComp={Collapser}
        expanderClassName={styles.seeMore}
        lineHeight={20}
        maxLines={8}>
        {value.map((monologue, index) => (
          <Monologue
            key={index}
            item={monologue} />
        ))}
      </SeeMore>
    </div>
  );
});

const Monologue = memo(({ item }: { className?: string; item: ProjectClips.Monologue }) => {

  return (
    <div className={styles.item}>
      <div className={styles.header}>
        {item.speaker.name} [{secondsToTime(item.range.start)} - {secondsToTime(item.range.end)}]
      </div>
      <div className={styles.content}>
        <MonologueMarkdown text={item.text} />
      </div>
    </div>
  );
});

const MonologueMarkdown = memo(({ text }: { text: string }) => {

  const components = useMemo((): MarkdownOptions['components'] => ({
    p: props => (
      <p className={styles.paragraph}>
        {props.children}
      </p>
    ),
    strong: props => (
      <span className={styles.highlight}>
        {props.children}
      </span>
    ),
  }), []);

  return (
    <Markdown
      components={components}>
      {text}
    </Markdown>
  );
});

function secondsToTime(value: number) {
  const time = new Date(value * 1000).toISOString().substring(11, 19);
  return time.startsWith('00') ? time.substring(3) : time;
}