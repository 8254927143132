import { useContext } from 'react';
import { createNamedContext } from '@utils';
import type { SurveyAlternateImageExercise } from '@/types';

type AlternateImagesBuilderDelete = () => void;
type AlternateImagesBuilderSaveContextValue = {
  canSave: {
    val: boolean;
    message?: string;
  };
  onSave: () => void;
};
type CancelValue = () => void;

export const SurveyAlternateImagesBuilderItemContext = createNamedContext<SurveyAlternateImageExercise>(undefined, 'SurveyAlternateImagesBuilderItemContext');
export const SurveyAlternateImagesBuilderDeleteContext = createNamedContext<AlternateImagesBuilderDelete>(undefined, 'SurveyAlternateImagesBuilderDeleteContext');
export const SurveyAlternateImagesBuilderSaveContext = createNamedContext<AlternateImagesBuilderSaveContextValue>(undefined, 'SurveyAlternateImagesBuilderSaveContext');
export const SurveyAlternateImagesBuilderCancelContext = createNamedContext<CancelValue>(undefined, 'SurveyAlternateImagesBuilderCancelContext');

export const useSurveyAlternateImagesBuilderItemContext = () => useContext(SurveyAlternateImagesBuilderItemContext);
export const useDeleteSurveyAlternateImages = () => useContext(SurveyAlternateImagesBuilderDeleteContext);
export const useSaveAlternateImagesContext = () => useContext(SurveyAlternateImagesBuilderSaveContext);
export const useCancelAlternateImagesEditing = () => useContext(SurveyAlternateImagesBuilderCancelContext);

