import { useCallback, useContext, useMemo, useState } from 'react';
import { Download } from 'react-feather';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { bindToggle, usePopupState } from 'material-ui-popup-state/hooks';
import { usePopper } from 'react-popper';
import * as api from '@api';
import { TranscriptPublishType } from '@enums';
import { cx } from '@utils';
import { useDownloader } from '@utils/hooks';
import { useTranscriptDownloadModal } from '@screens/ProjectSummary/TranscriptDownloadModal';
import { useCallChatLogDownloadModal } from '@screens/ProjectSummary/CallChatLogDownloadModal';
import { useTranscriptHighlightsDownloadModal } from '@screens/ProjectSummary/TranscriptHighlightsDownloadModal';
import { useProjectConferenceTagSummariesModal, useProjectResponseGridModal } from '@/components/Project.ConferenceTags.Overview';
import { useQueryDownloader } from '@containers/QueryDownloader';
import { DownloadTranscriptMediaContext } from '@/containers/Transcript/context';
import { useIsInternalUser } from '@/containers/Store/hooks';
import { Portal } from '@/components/Portal';
import { PopperMenuDivider, PopperMenu, PopperMenuItem } from '@/components/Popper';
import styles from './style/DownloadMenu.css';

type Props = {
  callId: number;
  source: {
    url: string | (() => Promise<string>);
    type: 'audio' | 'video';
  };
  hasNotes?: boolean;
  projectId: number;
  publishType?: TranscriptPublishType;
  transcriptId: number;
  iconClassName?: string;
} & ITranscriptId;

export const TranscriptDownloadMenu = ({
  callId,
  hasNotes,
  projectId,
  publishType,
  source,
  iconClassName,
  transcriptId,
}: Props) => {

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);
  const [toggleDownloadTranscriptModal, DownloadTranscriptModal] = useTranscriptDownloadModal();
  const [toggleChatLogModal, ChatLogModal] = useCallChatLogDownloadModal();
  const [toggleTranscriptHighlightsModal, TranscriptHighlightsModal] = useTranscriptHighlightsDownloadModal();
  const [toggleTagSummariesModal, TagSummariesModal] = useProjectConferenceTagSummariesModal();
  const [toggleResponseGridModal, ResponseGridModal] = useProjectResponseGridModal();
  const downloadMediaContext = useContext(DownloadTranscriptMediaContext);

  const isInternalUser = useIsInternalUser();

  const popupState = usePopupState({
    popupId: `transcript-actions-menu`,
    variant: 'popper',
  });

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 5],
        },
      },
    ],
    placement: 'bottom-start',
  });

  const handleDownloadReportClicked = useCallback(() => {
    toggleDownloadTranscriptModal();
    popupState.close();
  }, [toggleDownloadTranscriptModal, popupState]);

  const handleDownloadHighlightsClicked = useCallback(() => {
    toggleTranscriptHighlightsModal();
    popupState.close();
  }, [toggleTranscriptHighlightsModal, popupState]);

  const handleDownloadChatLog = useCallback(() => {
    toggleChatLogModal();
    popupState.close();
  }, [popupState, toggleChatLogModal]);

  const handleDownloadTagSummaries = useCallback(() => {
    toggleTagSummariesModal();
    popupState.close();
  }, [popupState, toggleTagSummariesModal]);

  const handleDownloadResponseGrid = useCallback(() => {
    toggleResponseGridModal();
    popupState.close();
  }, [popupState, toggleResponseGridModal]);

  const closePopupWrapper = useCallback((func: () => void) => {
    return () => {
      func();
      popupState.close();
    };
  }, [popupState]);

  const downloadNotes = useQueryDownloader({
    queryKey: [
      `get:calls/notes`,
      callId,
    ],
    queryFn: () => {
      return api.calls.downloadCallNotes({ callId });
    },
    meta: {
      downloader: true,
    },
    downloaderOptions: {
      onResponse: res => ({
        filename: res.filename,
        type: 'blob',
        value: res.blob,
      }),
    },
  });

  const handleDownloadNotes = useCallback(() => {
    downloadNotes.download({
      extension: `docx`,
      name: `Call Notes`,
      title: `Generating Report`,
    });
  }, [downloadNotes]);

  const download = useDownloader();

  const handleDownloadSource = useCallback(() => {
    if (typeof source.url === 'function') {
      source.url().then(url => {
        download({ url });
        popupState.close();
      });
    } else {
      download({ url: source.url });
      popupState.close();
    }
  }, [
    source,
    download,
    popupState,
  ]);

  const access = useMemo(() => {
    return {
      recording: isInternalUser || canAccessRecording(publishType),
      transcription: isInternalUser || canAccessTranscription(publishType),
    };
  }, [
    isInternalUser,
    publishType,
  ]);

  if (!transcriptId) return null;

  const canDownloadSource = !!callId && access.recording && source?.url && source?.type != 'video';
  const canDownloadMedia = access.recording && source?.type === 'video';

  return (
    <>
      <div
        {...bindToggle(popupState)}
        ref={setReferenceElement}>
        <Download className={cx(styles.download, iconClassName)} />
      </div>
      {popupState.isOpen &&
        <Portal>
          <div
            ref={setPopperElement}
            className={styles.popper}
            style={popperStyles.popper}
            {...attributes.popper}>
            <ClickAwayListener
              onClickAway={popupState.close}>
              <PopperMenu>
                {canDownloadSource &&
                  <PopperMenuItem onClick={handleDownloadSource}>
                    Download {source.type === 'audio' ? 'Recording' : 'Video'}
                  </PopperMenuItem>}
                {canDownloadMedia &&
                  <PopperMenuItem onClick={closePopupWrapper(downloadMediaContext)}>
                    Download Media
                  </PopperMenuItem>
                }
                {(access.transcription && transcriptId) &&
                  <PopperMenuItem onClick={handleDownloadReportClicked}>
                    Download Transcript
                  </PopperMenuItem>
                }
                {transcriptId &&
                  <PopperMenuItem onClick={handleDownloadHighlightsClicked}>
                    Download Highlights
                  </PopperMenuItem>
                }
                {isInternalUser && !!callId && (
                  <PopperMenuItem onClick={handleDownloadChatLog}>
                    Download Chat Log
                  </PopperMenuItem>
                )}

                {!!callId && (
                  <>
                    <PopperMenuDivider />

                    <PopperMenuItem onClick={handleDownloadTagSummaries}>
                      Download Project Summary
                    </PopperMenuItem>
                    {isInternalUser && (
                      <PopperMenuItem onClick={handleDownloadResponseGrid}>
                        Download Response Grid
                      </PopperMenuItem>
                    )}
                    {hasNotes &&
                      <PopperMenuItem onClick={handleDownloadNotes}>
                        Download Call Notes
                      </PopperMenuItem>
                    }
                  </>
                )}
              </PopperMenu>
            </ClickAwayListener>
          </div>
        </Portal>
      }
      <DownloadTranscriptModal
        transcriptId={transcriptId} />
      <ChatLogModal callId={callId} />
      <TranscriptHighlightsModal transcriptId={transcriptId} />
      <TagSummariesModal projectId={projectId} />
      <ResponseGridModal projectId={projectId} />
    </>
  );
};

export default TranscriptDownloadMenu;

function canAccessRecording(typeId: TranscriptPublishType) {
  return typeId === TranscriptPublishType.RecordingAndTranscript
    || typeId === TranscriptPublishType.RecordingOnly;
}

function canAccessTranscription(typeId: TranscriptPublishType) {
  return typeId === TranscriptPublishType.RecordingAndTranscript
    || typeId === TranscriptPublishType.TranscriptOnly;
}