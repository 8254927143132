import { useEffect } from 'react';

const useOnResize = (func: () => void) => {
  useEffect(() => {
    const handler = () => {
      func();
    };

    window.addEventListener('resize', handler);
    window.addEventListener('orientationchange', handler);

    return () => {
      window.removeEventListener('resize', handler);
      window.removeEventListener('orientationchange', handler);
    };
  }, [func]);
};

export { useOnResize };
export default useOnResize;