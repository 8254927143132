import { Header } from '../Header';
import { useDeviceName } from './hooks';

export const GenericError = () => {
  const device = useDeviceName();

  return (
    <>
      <Header>{`There was an error using your ${device}`}</Header>
    </>
  );
};