import { useCallback, useContext } from 'react';
import { ProjectFilesContainer } from '@containers/Group.Project.Files';
import { FilesTable, FilesTableLoadingContext } from '@/components/Workspace.FilesTable';
import { WorkspaceBreadcrumbsContext } from '@containers/WorkspaceObject/Context';
import { TabViewTableSkeleton } from '@/components/TabView';
import { ProjectFilesBreadcrumbs } from './Breadcrumbs';
import styles from './style/Project.Files.css';

const ProjectFiles = () => {

  const loading = useContext(FilesTableLoadingContext);
  const breadcrumbs = useContext(WorkspaceBreadcrumbsContext);

  const renderBreadcrumbs = useCallback(() => {
    return (
      <ProjectFilesBreadcrumbs items={breadcrumbs} />
    );
  }, [breadcrumbs]);

  return (
    <div className={styles.tableWrap}>
      {loading
        ? <TabViewTableSkeleton />
        : <FilesTable renderHeaderTitle={breadcrumbs.length ? renderBreadcrumbs : undefined} />
      }
    </div>
  );
};

const WithContainer = () => {

  return (
    <ProjectFilesContainer>
      <ProjectFiles />
    </ProjectFilesContainer>
  );
};

export { WithContainer as ProjectFiles };