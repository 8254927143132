export enum ChatCitationType {
  Document = 'document',
  SurveyQuestionResponses = 'survey_question_responses',
  Transcript = 'transcript',
}

export enum ChatSkin {
  Sentiment = 'sentiment',
  Standalone = 'standalone',
}

export enum ChatContextType {
  Brand = 'brand',
  Document = 'document',
  Folder = 'folder',
  Global = 'global',
  Project = 'project',
  Transcript = 'transcript',
}

export enum ChatInstanceItemType {
  Query = 1,
  FileUpload = 2,
  Segmentation = 3,
}

export enum ChatFileUploadStatus {
  Errored = 'errored',
  Initiated = 'initiated',
  Started = 'started',
  Ready = 'ready',
  Uploaded = 'uploaded',
}