import qs from 'query-string';
import http from '@services/http';
import { Projects } from './interfaces';

export const getProjectConferenceTagsOverview = (data: Projects.GetProjectConferenceTagsOverview.Request) => {
  return http.get<Projects.GetProjectConferenceTagsOverview.Response>(`/admin/projects/${data.projectId}/conferences/tags/overview`);
};

export const exportProjectConferenceTagsSummaries = ({ projectId, ...data }: Projects.ExportProjectConferenceTagsSummaries.Request) => {
  return http.get(`/admin/projects/${projectId}/conferences/tags/summaries/export?${qs.stringify({
    format: data.format,
    tagIds: data.tagIds,
  }, { arrayFormat: 'bracket' })}`);
};