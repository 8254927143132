/* eslint-disable key-spacing */
import * as pathname from '@consts/pathname';
import { join } from '@utils/path';

const admin = join(pathname.Admin);
const experts = join(admin(`/experts`));
const posts = join(admin(`/posts`));
const products = join(admin(`/products`));
const projects = join(admin(`/projects`));
const search = join(admin(`/search`));
const surveymodules = join(admin(`/survey-modules`));

const Experts = {
  Acquisition: experts(`/acquisition`),
  Activity: {
    Invited: experts('/activity/invited'),
    Recommended: experts('/activity/recommended'),
    Unverified: experts('/activity/unverified'),
  },
  Claim: experts(`/:userId/claim`),
  Expert: {
    Billing:    experts(`/:userId/billing`),
    Details:    experts(`/:userId/details`),
    Emails:     experts(`/:userId/emails`),
    Experience: experts(`/:userId/experience`),
    Profile:    experts(`/:userId/profile`),
    Projects:   experts(`/:userId/projects`),
    Root:       experts(`/:userId`),
  },
  Root: experts(),
};

const Posts = {
  Root: posts(),
  Videos: {
    Root: posts(`/videos`),
    New: posts(`/videos/new`),
  },
};

const Products = {
  Root: products(),
};

const Projects = {
  Root: projects(),
  Charges: projects(`/:projectId/charges`),
  New: projects(`/new`),
  Overview: projects(`/:projectId`),
  Edit: projects(`/:projectId/edit`),
  SurveyResponses: projects(`/:projectId/survey-responses`),
  Survey: {
    Root: projects(`/:projectId/survey`),
    Questions: projects(`/:projectId/survey/questions`),
    Logic: projects(`/:projectId/survey/logic`),
    Quotas: projects(`/:projectId/survey/quotas`),
    Tagging: projects(`/:projectId/survey/tagging`),
  },
  Calls: projects(`/:projectId/calls`),
  Cloning: projects(`/:projectId/clone`),
  ComplianceSurveyMem: projects(`/:projectId/experts/:userId/compliance-survey`),
  Details: projects(`/:projectId/details`),
  Experts: projects(`/:projectId/experts`),
  Leads: projects(`/:projectId/leads`),
  Monitoring: projects(`/:projectId/monitoring`),
  Payouts: projects(`/:projectId/payouts`),
  Pipeline: projects(`/:projectId/pipeline`),
};

const Search = {
  Root: search(),
  Experts: search(`/experts`),
  Leads: search(`/leads`),
};

const SurveyModules = {
  New: surveymodules(`/new`),
  EditModule: surveymodules(`/:surveyId`),
  Root: surveymodules(),
};

export const Admin = {
  AccountsActivity:             admin(`/accounts/activity`),
  AccountDetails:               admin(`/accounts/:groupId`),
  AccountUserDetails:           admin(`/accounts/:groupId/users/:userId`),
  AccountUsers:                 admin(`/accounts/:groupId/users`),
  AccountBalance:               admin('/accounts/:groupId/balance-account'),
  Accounts:                     admin('/accounts'),
  Billing:                      admin('/billing'),
  BillingCharge:                admin('/billing/charge/:id'),
  BillingCharges:               admin('/billing/charges'),
  BillingChargeable:            admin('/billing/chargeable/:id'),
  BillingChargeables:           admin('/billing/chargeables'),
  BillingInvoice:               admin('/billing/invoice/:id'),
  BillingInvoiceNew:            admin('/billing/invoice'),
  BillingInvoices:              admin('/billing/invoices'),
  BillingPackages:              admin('/billing/packages'),
  BillingPayout:                admin('/billing/(payout|payouts)/:id'),
  BillingPayouts:               admin('/billing/payouts'),
  BillingPayoutable:            admin('/billing/payoutable/:id'),
  BillingPayoutables:           admin('/billing/payoutables'),
  BillingReports:               admin('/billing/reports'),
  BillingReview:                admin('/billing/payout-review'),
  Brand:                        admin('/brands/:brandId/:tab?'),
  Brands:                       admin('/brands'),
  BrandCreate:                  admin('/brands/new'),
  Call:                         admin(`/calls/:callId`),
  Calls:                        admin(`/calls`),
  CallTranscript:               admin(`/calls/:callId/transcripts`),
  Companies:                    admin('/companies'),
  CompanyDetails:               admin('/companies/:companyId'),
  CompanyNew:                   admin('/companies/new'),
  Conferences:                  admin('/conferences'),
  ConferenceCues:               admin('/conference-cues'),
  ConferenceStats:              admin('/conference/:conferenceId/stats'),
  Directory:                    admin('/directory'),
  GMV:                          admin(`/gmv`),
  GroupComplianceSurvey:        admin(`/accounts/:groupId/compliance-survey`),
  GroupPostCallQuestions:       admin(`/accounts/:groupId/post-call-questions`),
  Industries:                   admin(`/industries`),
  IndustryCreate:               admin(`/industries/new`),
  IndustryDetails:              admin(`/industries/:vicsId`),
  Keywords:                     admin(`/keywords`),
  LeadsRecord:                  admin(`/leads/:leadId`),
  LinkedinUpload:               admin(`/linkedin-upload`),
  Experts,
  Messages:                     admin(`/messages`),
  MessagesAll:                  admin(`/messages/all`),
  MessagesAwaitingReply:        admin(`/messages/awaiting-reply`),
  MessagesRejected:             admin(`/messages/rejected`),
  MessagesReview:               admin(`/messages/review`),
  MessagesCompliance:           admin(`/messages/compliance`),
  NewAccount:                   admin(`/accounts/new`),
  NewAccountUser:               admin(`/accounts/:groupId/users/new`),
  NewAccountDocument:           admin(`/accounts/:groupId/new-document`),
  NewPackage:                   admin(`/billing/packages/new`),
  Package:                      admin(`/billing/packages/:packageId`),
  OpenAICompletions:            admin(`/openai-completions`),
  Posts,
  ProfileScreeners:             admin(`/profile-screeners`),
  Products,
  Projects,
  ProjectSurveyResponse:        admin(`/project-survey-response/:responseId`),
  ProjectSurveyPartialResponse: admin(`/project-survey-partial-response/:surveyVersionId/:userId`),
  Questions:                    admin(`/questions`),
  RecruitmentDashboard:         admin(`/recruitment-dashboard`),
  Referrals:                    admin(`/referrals`),
  Root:                         admin(),
  Search,
  SurveyModules,
  Topics:                       admin(`/topics`),
  TopicCreate:                  admin(`/topics/new`),
  TopicDetails:                 admin(`/topics/:topicId`),
  Users:                        admin(`/users`),
  UserScheduling:               admin(`/users/:userId/scheduling`),
};
