import { ClosedResponseOptionRow } from '@presentation/ProjectSurveyResponses';
import { MatrixSliderItem } from './interfaces';
import styles from './style/MatrixSlider.Data.css';

type Props = {
  items: MatrixSliderItem[];
  onClick: (rowId: number) => void;
};

export const MatrixSliderAggregateData = ({
  items,
  onClick,
}: Props) => {

  return (
    <div className={styles.options}>
      {items.map(item => (
        <ClosedResponseOptionRow
          key={item.id}
          onViewAll={() => onClick(item.id)}
          color={item.color}
          subtitle={`(Avg: ${item.displayAvg})`}
          label={item.name} />
      ))}
    </div>
  );

};

export default MatrixSliderAggregateData;