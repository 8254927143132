import type React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { useRafState } from '@utils/hooks/useRafState';

type Props = {
  canFetch: boolean;
  fetchNext: () => unknown;
  /**
   * @param bufferSize - Percent as decimal (default: 0.20)
  */
  bufferSize?: number;
  container: React.MutableRefObject<HTMLElement>;
};

export default function useInfiniteElementScroll({ canFetch, fetchNext, container, bufferSize = 0.20 }: Props) {
  const element = container.current;

  const scrollPos = useScrollPosition(element);

  useEffect(() => {
    if (!scrollPos || !element) return;

    const scrolledToEnd = Math.ceil(scrollPos.scrollTop) >= Math.ceil(element.scrollHeight - element.offsetHeight) * (1 - bufferSize);

    if (scrolledToEnd && canFetch) {
      fetchNext();
    }
  }, [fetchNext, scrollPos, canFetch, bufferSize, element]);
}

const useScrollPosition = (element: HTMLElement) => {
  const [yPos, setYPos] = useRafState({
    scrollTop: element?.scrollTop,
  });

  const handleScroll = useCallback(() => {
    setYPos({
      scrollTop: element?.scrollTop,
    });
  }, [element, setYPos]);

  useEffect(() => {
    if (element) {
      element.addEventListener('scroll', handleScroll,
        {
          capture: false,
          passive: true,
        });

      return () => {
        element.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll, element]);

  return yPos;
};

export { useInfiniteElementScroll };