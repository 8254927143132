import { useCallback } from 'react';

type Params = {
  blob?:     Blob;
  filename?: string;
  url?:      string;
};

const useDownloader = () => {

  const download = useCallback((params: Params) => {

    const url = params.blob
      ? window.URL.createObjectURL(params.blob)
      : params.url;

    const link = document.createElement('a');

    link.href = url;

    if (params.filename) {
      link.setAttribute('download', params.filename);
    }
    document.body.appendChild(link);
    link.click();

    setTimeout(() => {

      if (params.blob) {
        window.URL.revokeObjectURL(url);
      }
      document.body.removeChild(link);

    }, 3000);

  }, []);

  return download;

};

export { useDownloader };
export default useDownloader;