import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateUserSMSSettings } from '@utils/api';
import { SettingsTitle } from '@/components/Settings';
import { Switch } from '@/components/Switch';
import styles from './style/Settings.SMS.css';

const selectSettings = (state: Store.State) => state.settings.sms;

export const SMSSettings = () => {

  const settings = useSelector(selectSettings);

  const { mutateAsync } = useUpdateUserSMSSettings();

  const toggle = useCallback((field: string) => (value: boolean) => {
    return mutateAsync({
      [field]: value,
    });
  }, [mutateAsync]);

  return (
    <>
      <SettingsTitle
        title="SMS Settings" />
      <div className={styles.item}>
        <Switch
          className={styles.toggle}
          onClick={toggle('callReminders')}
          active={settings.callReminders} />
        Enable SMS Call Reminders
      </div>
    </>
  );
};

export default SMSSettings;