import { useCallback, useContext, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import styled from '@emotion/styled';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import * as $api from '@/brand-insights/api';
import { SecondaryButton, PrimaryButton } from '@/brand-insights/components/presentation';
import type { Chat } from '@/brand-insights/types';
import type { BaseModalProps } from '@/brand-insights/hooks/useModal';
import { useModal } from '@/brand-insights/hooks/useModal';
import Toast from '@/components/Toast';
import { ChatSkinContext } from '@/brand-insights/components/BrandInsights/context';
import { useIsFullPageChat } from '@/brand-insights/components/Chat/hooks';
import { ModalShareChatPicker } from './Modal.ShareChat.Picker';

type Props = {
  chatInstance: Pick<Chat.Instance, 'id' | 'name'>;
} & BaseModalProps;

export const ShareChatModal = ({ chatInstance, open, onClose }: Props) => {
  const [selected, setSelected] = useState<Chat.User[]>([]);

  const isFullPageChat = useIsFullPageChat();

  const shareMutation = useMutation<unknown, unknown, { chatInstanceId: number; userIds: number[] }>({
    mutationFn: ({ chatInstanceId, userIds }) => {
      return $api.shareChatInstance({
        chatInstanceId,
        standaloneLink: isFullPageChat,
        userIds,
      });
    },
    onError: () => {
      Toast.error({
        title: `We ran into an error trying to share this chat!`,
      });
    },
    onSuccess: () => {
      Toast.success({
        title: `Chat shared!`,
      });
      onClose();
    },
  });

  const handleSend = useCallback(() => {
    return shareMutation.mutateAsync({
      chatInstanceId: chatInstance.id,
      userIds: selected.map(u => u.id),
    });
  }, [chatInstance, selected, shareMutation]);

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleAddUser = useCallback((user: Chat.User) => {
    setSelected(prev => prev.some(u => u.id === user.id) ? prev : [...prev, user]);
  }, []);

  const handleRemoveUser = useCallback((user: Chat.User) => {
    setSelected(prev => prev.filter(u => u.id !== user.id));
  }, []);

  const enables = {
    send: !!selected.length && !shareMutation.isLoading,
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      onClose={onClose}
      open={open}>

      <Title>Share Chat Thread</Title>

      <DialogContent>
        <Box sx={{ minHeight: '375px' }}>
          <ModalShareChatPicker
            chatInstanceId={chatInstance.id}
            selected={selected}
            onAddUser={handleAddUser}
            onRemoveUser={handleRemoveUser} />
        </Box>
      </DialogContent>

      <Footer>
        <SecondaryButton
          onClick={handleCancel}>
          Cancel
        </SecondaryButton>
        <PrimaryButton
          disabled={!enables.send}
          onClick={handleSend}>
          Send
        </PrimaryButton>
      </Footer>

    </Dialog>
  );
};

const Title = styled(DialogTitle)(({ theme }) => ({
  marginBottom: 10,
  fontFamily: theme.fonts.bold,
  fontSize: 22,
  textAlign: 'center',
  color: theme.palette.text.primary,
}));

const Footer = styled(DialogActions)(({
  display: 'flex',
  justifyContent: 'center',
}));

export const useShareChatModal = () => useModal(ShareChatModal);