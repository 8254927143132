import { useCallback, useContext } from 'react';
import type { ComponentType } from 'react';
import type { NodeViewComponentProps } from '@remirror/react';
import { useTranscriptSpeaker, useTranscriptToolsPermission } from '@containers/Transcript/hooks';
import type { MonologueDetails as MD } from '@/types/transcribe.rich-text';
import { TranscriptHasMediaContext } from '@/containers/Transcript/context';
import { StaticTimeRange, TimeRange } from './TimeRange';
import { MonologueDetailsEditSpeaker } from './MonologueDetails.EditSpeaker';
import { Speaker } from './MonologueDetails.Speaker';
import styles from './style/Monologue.css';
import { TranscriptTimestampVisibilityContext } from './context';

export const MonologueDetails: ComponentType<NodeViewComponentProps> = ({ node }) => {
  const {
    s: start,
    e: end,
    spk: speakerIndex,
  } = node.attrs as MD.Attributes;

  const permission = useTranscriptToolsPermission();
  const speaker = useTranscriptSpeaker(speakerIndex);
  const hasMedia = useContext(TranscriptHasMediaContext);
  const showTimestamp = useContext(TranscriptTimestampVisibilityContext);

  const renderTimestamp = useCallback(() => {
    if (!showTimestamp) {
      return null;
    }

    if (!hasMedia) {
      return (
        <StaticTimeRange
          className={styles.duration}
          startSeconds={start}
          endSeconds={end} />
      );
    }

    return (
      <TimeRange
        className={styles.duration}
        startSeconds={start}
        endSeconds={end} />
    );
  }, [end, hasMedia, showTimestamp, start]);

  return (
    <div
      className={styles.details}>
      {!permission.speaker && (
        <Speaker
          speaker={speaker.speaker}
          displayName={speaker.displayName} />
      )}
      {permission.speaker &&
        <MonologueDetailsEditSpeaker
          end={end}
          speakerIndex={speakerIndex}
          start={start} />}
      {renderTimestamp()}
    </div>
  );
};

export default MonologueDetails;

export const MonologueDetailsReadOnly: ComponentType<NodeViewComponentProps> = ({ node }) => {
  const {
    s: start,
    e: end,
    spk: speakerIndex,
  } = node.attrs as MD.Attributes;

  const speaker = useTranscriptSpeaker(speakerIndex);

  return (
    <div
      className={styles.details}>
      <Speaker {...speaker} />
      <TimeRange
        className={styles.duration}
        startSeconds={start}
        endSeconds={end} />
    </div>
  );
};

export const TaggedMomentBuilderMonologueDetails: ComponentType<NodeViewComponentProps> = ({ node }) => {
  const {
    s: start,
    e: end,
    spk: speakerIndex,
  } = node.attrs as MD.Attributes;

  const speaker = useTranscriptSpeaker(speakerIndex);

  return (
    <div
      className={styles.momentDetails}>
      <Speaker
        className={styles.momentSpeaker}
        {...speaker} />
      <StaticTimeRange
        className={styles.duration}
        startSeconds={start}
        endSeconds={end} />
    </div>
  );
};