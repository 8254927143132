import { Dispatch, SetStateAction } from 'react';
import { Contact } from '@/types';

export type AuthConnectRecipientUser = {
  profile: Pick<Contact['profile'], 'firstName' | 'fullname' | 'lastName' | 'pictureUrl'>;
} & Pick<Contact, 'id'>;

export enum Step {
  SignupStrategy  = 'signup.auth-strategy',
  LoginStratgey   = 'login.auth-strategy',
  SignupEmailForm = 'signup.email-form',
}

export type FormNavigationContextValue = Dispatch<SetStateAction<Step>>;