import { SurveyTemplateType } from '@enums/survey.template';
import type {
  SurveyTemplate,
  SurveyBuilderVersion,
} from '@/types/survey';
import * as $template from './template';

export const getInitialTemplateData = (template: SurveyTemplateType): SurveyTemplate.Data => {

  if (!template) return $template.custom.defaultTemplateData;

  switch (template) {
    case SurveyTemplateType.CompetitiveIntelligence:
      return $template.competitiveIntelligence.defaultTemplateData;

    case SurveyTemplateType.PersonaResearch:
      return $template.personaResearch.defaultTemplateData;

    case SurveyTemplateType.NetPromoterScore:
      return $template.netPromoterScore.defaultTemplateData;

    case SurveyTemplateType.EmployeeSatisfaction:
      return $template.employeeSatisfaction.defaultTemplateData;

    case SurveyTemplateType.PricingStrategy:
      return $template.pricingStrategy.defaultTemplateData;

    case SurveyTemplateType.MarketingCampaignEffectiveness:
      return $template.marketingCampaignEffectiveness.defaultTemplateData;

    case SurveyTemplateType.MarketAnalysis:
      return $template.marketAnalysis.defaultTemplateData;

    case SurveyTemplateType.TargetProductProfile:
      return $template.targetProductProfile.generateDefaultTemplateData(false);

    case SurveyTemplateType.MessageTesting:
      return $template.messageTesting.generateDefaultTemplateData(false);

    case SurveyTemplateType.ExternalSurvey:
      return null;

    default:
      throw new UnreachableCaseError(template);
  }
};

export const getIntialSurvey = (template: SurveyTemplateType, data: SurveyTemplate.Data): SurveyBuilderVersion => {

  if (!template) return $template.custom.initialSurveyData;

  switch (template) {
    case SurveyTemplateType.CompetitiveIntelligence:
      return $template.competitiveIntelligence.generateSurveyData(data as SurveyTemplate.CompetitiveIntelligenceTemplate);

    case SurveyTemplateType.PersonaResearch:
      return $template.personaResearch.generateSurveyData(data as SurveyTemplate.PersonaResearchTemplate);

    case SurveyTemplateType.NetPromoterScore:
      return $template.netPromoterScore.generateSurveyData(data as SurveyTemplate.NetPromoterScore);

    case SurveyTemplateType.EmployeeSatisfaction:
      return $template.employeeSatisfaction.generateSurveyData(data as SurveyTemplate.EmployeeSatisfaction);

    case SurveyTemplateType.PricingStrategy:
      return $template.pricingStrategy.generateSurveyData(data as SurveyTemplate.PricingStrategy);

    case SurveyTemplateType.MarketingCampaignEffectiveness:
      return $template.marketingCampaignEffectiveness.generateSurveyData(data as SurveyTemplate.MarketingCampaignEffectiveness);

    case SurveyTemplateType.MarketAnalysis:
      return $template.marketAnalysis.generateSurveyData(data as SurveyTemplate.MarketAnalysis);

    case SurveyTemplateType.TargetProductProfile:
      return $template.targetProductProfile.generateSurveyData(data as SurveyTemplate.TargetProductProfile);

    case SurveyTemplateType.MessageTesting:
      return $template.messageTesting.generateSurveyData(data as SurveyTemplate.MessageTesting.Data);

    case SurveyTemplateType.ExternalSurvey:
      return null;

    default:
      throw new UnreachableCaseError(template);
  }
};