import { VICS } from '@enums/VICS';
import { SearchType } from '@enums/SearchType';

export class VICSType {
  static get Sector() {
    return VICS.Sector;
  }

  static get Industry() {
    return VICS.Industry;
  }

  static get SubIndustry() {
    return VICS.SubIndustry;
  }

  static VICSTypeMap = new Map([
    [VICS.Sector,      { name: 'Sector'      }],
    [VICS.Industry,    { name: 'Specialty'    }],
    [VICS.SubIndustry, { name: 'Subspecialty' }],
  ]);

  static VICSPluralNameMap = new Map([
    [VICS.Sector,      { name: 'Sectors'      }],
    [VICS.Industry,    { name: 'Specialties'    }],
    [VICS.SubIndustry, { name: 'Subspecialties' }],
  ]);

  static searchTypeMap = new Map([
    [VICS.Sector, SearchType.Sector],
    [VICS.Industry, SearchType.Industry],
    [VICS.SubIndustry, SearchType.SubIndustry],
  ]);

  static maxSelectionMap = new Map([
    [VICS.Sector, 2],
    [VICS.Industry, 1],
    [VICS.SubIndustry, 5],
  ]);

  static getName(id: VICSType) {
    return (VICSType.VICSTypeMap.get(Number(id)) || {}).name;
  }

  static getPluralName(id: VICSType) {
    return (VICSType.VICSPluralNameMap.get(Number(id)) || {}).name;
  }

  static getSearchType(id: VICSType) {
    return (VICSType.searchTypeMap.get(Number(id)));
  }

  static getMaxSelections(id: VICSType) {
    return (VICSType.maxSelectionMap.get(Number(id)));
  }

  static keys() {
    return Array.from(VICSType.VICSTypeMap.values()).map(({ name }) => name);
  }

  static values() {
    return Array.from(VICSType.VICSTypeMap.keys());
  }
}