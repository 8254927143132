import { useMemo } from 'react';
import { SurveyQuestionConditionType } from '@enums';
import { toTitleCase } from '@utils';
import { getAlternateOperatorText, getOperatorText } from '@utils/operator';
import { useSurveyBuilderState, useParseSurveyRichText } from '@/containers/SurveyBuilder';
import type { SurveyLogic, SurveyQuestionMatrixRow } from '@/types/survey';
import { ConditionTooltip } from './Logic.ConditionTooltip';

type Props = {
  condition: SurveyLogic.QuestionCondition;
};

export const QuestionCondition = ({ condition }: Props) => {

  const [state] = useSurveyBuilderState();

  const parseSurveyRichText = useParseSurveyRichText();

  const question = useMemo(() => {
    return state.survey.questions.find(f => f.base.identifier === condition.data.question.identifier);
  }, [condition.data.question, state.survey.questions]);

  const questionText = parseSurveyRichText(question.value);

  const { data } = condition;

  switch (data.type) {

    case SurveyQuestionConditionType.OptionRankedFirst: {
      const option = question.options.find(f => f.base.identifier === data.value.option.identifier);

      const tooltipLabel = [
        `Q: ${questionText}`,
        `A: ${parseSurveyRichText(option.value)}`,
      ].join(`\n`);
      const tooltipText = `Q${question.ordinal}:A${option.ordinal}`;

      return (
        <>
          <ConditionTooltip
            text={tooltipText}
            label={tooltipLabel} />
          &nbsp;ranked first
        </>
      );
    }

    case SurveyQuestionConditionType.OptionRankedLast: {
      const option = question.options.find(f => f.base.identifier === data.value.option.identifier);

      const tooltipLabel = [
        `Q: ${questionText}`,
        `A: ${parseSurveyRichText(option.value)}`,
      ].join(`\n`);
      const tooltipText = `Q${question.ordinal}:A${option.ordinal}`;

      return (
        <>
          <ConditionTooltip
            text={tooltipText}
            label={tooltipLabel} />
          &nbsp;ranked last
        </>
      );
    }

    case SurveyQuestionConditionType.OptionSelected: {
      const option = question.options.find(f => f.base.identifier === data.value.option.identifier);

      const tooltipLabel = [
        `Q: ${questionText}`,
        `A: ${parseSurveyRichText(option.value)}`,
      ].join(`\n`);
      const tooltipText = `Q${question.ordinal}:A${option.ordinal}`;

      return (
        <>
          <ConditionTooltip
            text={tooltipText}
            label={tooltipLabel} />
          &nbsp;selected
        </>
      );
    }

    case SurveyQuestionConditionType.OptionNotSelected: {
      const option = question.options.find(f => f.base.identifier === data.value.option.identifier);

      const tooltipLabel = [
        `Q: ${questionText}`,
        `A: ${parseSurveyRichText(option.value)}`,
      ].join(`\n`);
      const tooltipText = `Q${question.ordinal}:A${option.ordinal}`;

      return (
        <>
          <ConditionTooltip
            text={tooltipText}
            label={tooltipLabel} />
          &nbsp;not selected
        </>
      );
    }

    case SurveyQuestionConditionType.NumberOfOptionsSelected: {
      const operator = getOperatorText(data.value.operator);
      const tooltipText = `Q${question.ordinal}: Number of options selected is ${operator} ${data.value.comparate}`;

      return (
        <>
          {tooltipText}
        </>
      );
    }

    case SurveyQuestionConditionType.MatrixValueChosen: {
      const row = (question.matrixRows as SurveyQuestionMatrixRow[]).find(f => f.base.identifier === data.value.row.identifier);
      const option = question.options.find(f => f.base.identifier === data.value.option.identifier);

      const tooltipLabel = [
        `Q: ${questionText}`,
        `R: ${parseSurveyRichText(row.value)}`,
        `A: ${parseSurveyRichText(option.value)}`,
      ].join(`\n`);
      const tooltipText = `Q${question.ordinal}:R${row.ordinal}/A${option.ordinal}`;

      return (
        <>
          <ConditionTooltip
            text={tooltipText}
            label={tooltipLabel} />
          &nbsp;selected
        </>
      );
    }

    case SurveyQuestionConditionType.MatrixValueNotChosen: {
      const row = (question.matrixRows as SurveyQuestionMatrixRow[]).find(f => f.base.identifier === data.value.row.identifier);
      const option = question.options.find(f => f.base.identifier === data.value.option.identifier);

      const tooltipLabel = [
        `Q: ${questionText}`,
        `R: ${parseSurveyRichText(row.value)}`,
        `A: ${parseSurveyRichText(option.value)}`,
      ].join(`\n`);
      const tooltipText = `Q${question.ordinal}:R${row.ordinal}/A${option.ordinal}`;

      return (
        <>
          <ConditionTooltip
            text={tooltipText}
            label={tooltipLabel} />
          &nbsp;not selected
        </>
      );
    }

    case SurveyQuestionConditionType.NumberCellValue: {
      const row = (question.matrixRows as SurveyQuestionMatrixRow[]).find(f => f.base.identifier === data.value.row.identifier);
      const option = question.options.find(f => f.base.identifier === data.value.option.identifier);
      const operator = getOperatorText(data.value.operator);

      const tooltipLabel = [
        `Q: ${questionText}`,
        `R: ${parseSurveyRichText(row.value)}`,
        `C: ${parseSurveyRichText(option.value)}`,
      ].join(`\n`);
      const tooltipText = `Q${question.ordinal}:R${row.ordinal}/C${option.ordinal}`;

      return (
        <>
          <ConditionTooltip
            text={tooltipText}
            label={tooltipLabel} />
          &nbsp;answer is {operator} {data.value.comparate}
        </>
      );
    }

    case SurveyQuestionConditionType.SliderValue: {
      const row = (question.matrixRows as SurveyQuestionMatrixRow[]).find(f => f.base.identifier === data.value.row.identifier);
      const operator = getOperatorText(data.value.operator);

      const tooltipLabel = [
        `Q: ${questionText}`,
        `R: ${parseSurveyRichText(row.value)}`,
      ].join(`\n`);
      const tooltipText = `Q${question.ordinal}:R${row.ordinal}`;

      return (
        <>
          <ConditionTooltip
            text={tooltipText}
            label={tooltipLabel} />
          &nbsp;answer is {operator} {data.value.comparate}
        </>
      );
    }

    case SurveyQuestionConditionType.SliderValuesTally: {
      const tooltipLabel = [
        `Q: ${questionText}`,
      ].join(`\n`);
      const tooltipText = `Q${question.ordinal}`;

      const rowsOperatorText = getAlternateOperatorText(data.value.rows.operator);
      const valueOperatorText = getOperatorText(data.value.answer.operator);

      const text = `${toTitleCase(rowsOperatorText)} ${data.value.rows.comparate} row(s) where answer is ${valueOperatorText} ${data.value.answer.comparate}`;

      return (
        <>
          <ConditionTooltip
            text={tooltipText}
            label={tooltipLabel} />
          &nbsp;{text}
        </>
      );
    }

    case SurveyQuestionConditionType.Straightline: {
      const tooltipLabel = [
        `Q: ${questionText}`,
      ].join(`\n`);
      const tooltipText = `Q${question.ordinal}`;
      const straightlineText = ` if same response for ${data.value.comparate ? data.value.comparate.toString() : 'all'} rows`;

      return (
        <>
          <ConditionTooltip
            text={tooltipText}
            label={tooltipLabel} />
          &nbsp;{straightlineText}
        </>
      );
    }

    case SurveyQuestionConditionType.OptionRankedTopX: {
      const option = question.options.find(f => f.base.identifier === data.value.option.identifier);

      const tooltipLabel = [
        `Q: ${questionText}`,
        `A: ${parseSurveyRichText(option.value)}`,
      ].join(`\n`);
      const tooltipText = `Q${question.ordinal}:A${option.ordinal}`;

      return (
        <>
          <ConditionTooltip
            text={tooltipText}
            label={tooltipLabel} />
          &nbsp;ranked top {data.value.ordinal}
        </>
      );
    }

    default: {
      const error = new UnreachableCaseError(data);
      throw error;
    }
  }

};

