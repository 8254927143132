import { useCallback } from 'react';
import type { SurveyRichText } from '@/types/survey.rich-text';
import { parseSurveyRichText } from '@/containers/SurveyBuilder/utils/rich-text';
import { useSurveyBuilderState } from './useSurveyBuilderContext';

export const useParseSurveyRichText = () => {

  const [state] = useSurveyBuilderState();

  return useCallback((value: SurveyRichText.RichTextValue) => {
    return parseSurveyRichText(value, state.survey);
  }, [state.survey]);
};