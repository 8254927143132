import { useCallback, useMemo } from 'react';
import {
  useRunLogicValidationsCtx,
  useSurveyLogicItemState,
  useSurveyLogicBuilderContext,
  SurveyLogicItemSaveContext,
} from './context';

type Props =
  ChildrenProps;

export const LogicItemSaveContainer = (props: Props) => {
  const { saveItem, toggleEditingOff } = useSurveyLogicBuilderContext();
  const [item] = useSurveyLogicItemState();

  const runValidations = useRunLogicValidationsCtx();

  const canSave = useMemo(() => {
    return !!item.conditions.length;
  }, [item.conditions.length]);

  const handleSubmit = useCallback(() => {
    const valid = runValidations();

    if (valid) {
      saveItem(item);
    }
  }, [item, runValidations, saveItem]);

  const handleCancel = useCallback(() => {
    toggleEditingOff(item.identifier);
  }, [toggleEditingOff, item.identifier]);

  const ctx = {
    canSave,
    handleSubmit,
    handleCancel,
  };

  return (
    <SurveyLogicItemSaveContext.Provider value={ctx}>
      {props.children}
    </SurveyLogicItemSaveContext.Provider>
  );
};