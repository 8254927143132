import { WorkspaceContainer } from '@containers/Workspace/WorkspaceContainer';
import { useWorkspaceState } from '@containers/Workspace/hooks/useWorkspaceState';
import { WorkspaceBreadcrumb } from '@/components/Workspace/WorkspaceBreadcrumb';
import WorkspaceTabViewContainer from './WorkspaceTabViewContainer';
import WorkspaceHeader from './Header';

const StateDependent = () => {
  const { workspace } = useWorkspaceState();

  return (
    <>
      <WorkspaceHeader>
        <WorkspaceBreadcrumb workspace={workspace} />
      </WorkspaceHeader>
      <WorkspaceTabViewContainer />
    </>
  );
};

export const WorkspaceMain = () => (
  <WorkspaceContainer>
    <StateDependent />
  </WorkspaceContainer>
);

export default WorkspaceMain;