import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import { useSelectGroup } from '@containers/Store/hooks';
import type { SurveyBuilderVersion } from '@/types';
import { EditableComplianceSurvey } from '@/scenes/surveys/EditableSurvey';
import styles from './style.css';

const GroupSurvey = () => {
  const dispatch = useDispatch();
  const group = useSelectGroup();

  const deleteSurvey = () => {
    return api.groups.updateGroupSettings({
      groupId: group.id,
      complianceSurveyId: null,
    })
      .then(settings => {
        dispatch(actions.updateGroupSettings({ settings }));
      });
  };

  const saveSurvey = (data: Pick<SurveyBuilderVersion, 'questions' | 'sections'>) => {
    return api.groups.saveGroupComplianceSurvey(data)
      .then(survey => {
        const settings = {
          ...group.settings,
          complianceSurveyId: survey.id,
        };
        dispatch(actions.updateGroupSettings({ settings }));

        return survey;
      });
  };

  const fetchSurvey = () => {
    return api.groups.getGroupComplianceSurvey();
  };

  return (
    <div className={styles.content}>
      <EditableComplianceSurvey
        onDelete={deleteSurvey}
        onFetch={fetchSurvey}
        onSave={saveSurvey} />
    </div>
  );
};

export default GroupSurvey;