import { cx } from '@utils';
import styles from './style/Form.Input.css';

type Props =
  React.InputHTMLAttributes<HTMLInputElement>;

export const Input = (props: Props) => {

  return (
    <div className={cx(styles.root, props.className)}>
      <input
        {...props}
        className={styles.input} />
    </div>
  );
};

export default Input;