import { useContext, useMemo } from 'react';
import { MedicalBackgroundAdditionalExpertiseContext, MedicalBackgroundContext } from '@containers/MedicalOnboarding/Context';
import { useBackgroundAdditionalExpertiseValidation } from '@containers/MedicalOnboarding/hooks';
import { CompletionMedicalStepperContext } from '@containers/ProjectOnboarding.Completion/Context';
import * as Field from '@/components/MedicalProfile/Field';
import * as Layout from '@/components/MedicalProfile/Layout';
import { Label } from '@/components/MedicalProfile/Label';
import { RadioGroup } from '@/components/MedicalProfile/RadioGroup';
import styles from './style/Medical.css';

type Props = unknown;

export const AdditionalExpertise = (props: Props) => {
  const ctx = {
    additionalexpertise: useContext(MedicalBackgroundAdditionalExpertiseContext),
    background: useContext(MedicalBackgroundContext),
    stepper: useContext(CompletionMedicalStepperContext),
  };

  const { errors } = useBackgroundAdditionalExpertiseValidation();

  const disabled = useMemo(() => {

    return Object.values(errors).some(x => x === true);

  }, [errors]);

  return (
    <Layout.Screen>
      <Layout.Header title={copy.title} />
      <div className={styles.item}>
        <Label required>
          {copy.label.therapeuticAreas}
        </Label>
        <div className={styles.field}>
          <Field.Conditions
            className={styles.input}
            placeholder={copy.placeholder.therapeuticAreas} />
        </div>

      </div>
      <div className={styles.item}>
        <div className={styles.row}>
          <div className={styles.label}>{copy.label.description}</div>
        </div>
        <div className={styles.field}>
          <textarea
            className={styles.input}
            onChange={e => ctx.additionalexpertise.setValue({ description: e.target.value })}
            placeholder={copy.placeholder.description}
            value={ctx.additionalexpertise.form.description}
            rows={3} />
        </div>

      </div>
      <div className={styles.item}>
        <Label required>
          {ctx.additionalexpertise.form.boardEligibility.question.value}
        </Label>
        <div className={styles.field}>
          <RadioGroup
            onChange={optionId => ctx.additionalexpertise.setValue({ boardEligibility: optionId })}
            options={ctx.additionalexpertise.form.boardEligibility.question.options}
            selected={ctx.additionalexpertise.form.boardEligibility.answer} />
        </div>
      </div>
      <Layout.Footer>
        <Layout.BackButton onClick={ctx.stepper.back} />
        <Layout.NextButton
          disabled={disabled}
          implicitDisable={false}
          loading={ctx.background.mutation.isLoading}
          onClick={ctx.stepper.next}>
          Next
        </Layout.NextButton>
      </Layout.Footer>
    </Layout.Screen>
  );
};

AdditionalExpertise.displayName = 'Medical.AdditionalExpertise';

const copy = {
  title: `Provide a bit of information about your areas of expertise to talior our project recommendations.`,
  label: {
    therapeuticAreas: `What are the top 3 therapeutic areas you treat?`,
    description: `Is there any additional information about your practice, area of expertise, or patient focus that you would like to share?`,
  },
  placeholder: {
    therapeuticAreas: `Enter the top 3 therapeutic areas you treat`,
    description: `Enter any other additional information about your practice, area of experise, or patient focus that you would like to share?`,
  },
};