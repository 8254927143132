import { memo, useCallback, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import type { Chat } from '@/brand-insights/types';
import { pluralize } from '@/brand-insights/utils';
import { getLocationFor } from '@/utils';
import { ChatContextType } from '@/brand-insights/enums';
import { Link } from '@/brand-insights/components/presentation';
import { useRenameFolderModal } from '@/components/Workspace.Folder/hooks';
import RenameFolderContainer from '@/containers/Workspace.Folder/RenameFolderContainer';
import type { WorkspaceFolders } from '@/services/api/interfaces';
import { ChatStateDispatchContext } from '@/brand-insights/components/Chat.State';
import { ActiveChatSessionContext } from './context';
import { FileUploadFileContainer } from './FileUpload.File.Container';

type Props = {
  className?: string;
  item: Chat.InstanceFileUploadsItem;
  folder: {
    id: number;
    name: string;
  };
};

const FileUpload = memo(({ className, folder, item }: Props) => {

  const folderLocation = getLocationFor.workspaces.folder({
    folderId: folder.id,
  });

  const [toggleRenameModal, RenameFolderModal] = useRenameFolderModal();

  return (
    <>
      <div className={className}>
        <div>
          <div>
            Uploading {item.items.length} {pluralize('file', item.items.length)} to the <Link to={folderLocation}>{folder.name}</Link> folder.
          </div>

          <EditName onClick={toggleRenameModal}>
            Edit Name
          </EditName>

          Files must finish being uploaded and processed before being available in the chat. This may take a few minutes.
        </div>

        <UploadList>
          {item.items.map(file => (
            <StyledFileUpload
              key={file.identifier}
              uploadIdentifier={item.identifier}
              value={file} />
          ))}
        </UploadList>
      </div>
      <RenameFolderModal disablePortal={true} />
    </>
  );
});

const EditName = styled.div(({ theme }) => ({
  textDecoration: 'underline',
  color: theme.palette.hyperlink,
  cursor: 'pointer',

  margin: '10px 0',
}));

const UploadList = styled.div(({ theme }) => ({
  border: `2px solid ${theme.palette.gray.light2}`,
  borderRadius: 6,

  marginTop: 20,
}));

const StyledFileUpload = styled(FileUploadFileContainer)(({ theme }) => ({
  borderBottom: `2px solid ${theme.palette.gray.light2}`,

  '&:last-child': {
    borderBottom: 'none',
  },
}));

type BaseProps = Omit<Props, 'folder'>;

const FileUploadContainer = (props: BaseProps) => {

  const folder = useChatFolder();
  const dispatch = useContext(ChatStateDispatchContext);

  const handleRenameSuccess = useCallback((res: WorkspaceFolders.RenameFolder.Response<Date>) => {
    dispatch({
      type: 'folder-name-updated',
      payload: {
        value: res.folder.name,
      },
    });
  }, [dispatch]);

  return (
    <RenameFolderContainer folder={folder} onSuccess={handleRenameSuccess}>
      <FileUpload {...props} folder={folder} />
    </RenameFolderContainer>
  );
};

const useChatFolder = () => {
  const chat = useContext(ActiveChatSessionContext);

  return useMemo(() => {
    return {
      id: chat.context.type === ChatContextType.Folder
        ? chat.context.data.folderId
        : undefined,
      name: chat.context.type === ChatContextType.Folder
        ? chat.context.metadata.name
        : undefined,
    };
  }, [chat.context.type, chat.context.data, chat.context.metadata]);
};

export { FileUploadContainer as FileUpload };