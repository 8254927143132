import { useCallback } from 'react';
import type { SurveySettings } from '@/types/survey';
import { useSurveyFormAnswerRationale } from '../Context';
import useSurveyFormQuestionContext from './useSurveyFormQuestionContext';

export const useValidateRationale = () => {

  const [rationale] = useSurveyFormAnswerRationale();
  const question = useSurveyFormQuestionContext();

  return useCallback(() => {
    const setting = (question.settings as SurveySettings.AskForRationale).rationale;

    if (!setting?.enabled) return true;

    const minimum = 0;

    if (!rationale) return false;

    if (rationale.length < minimum) {

      return false;
    }

    return true;

  }, [rationale, question.settings]);
};