import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useDownloader } from '@utils/hooks';
import { AsyncActivity } from '@/enums';
import { getLocationFor } from '@/utils';
import type { Reports } from '@/services/websocket/interfaces';
import { useInvalidateProjectQuantAnalysisQuery } from '@/utils/api/project.quant-analysis';
import { FileDownloadActivityItem } from '@/components/FileDownloadActivity';
import { useInvalidateProjectState } from '../GroupProject/hooks/useInvalidateProjectState';
import { QueryDownloaderContext } from './Context';
import { SocketSubscriber } from './SocketSubscriber';
import type { DownloaderItem } from './interfaces';

type Props = {
  index: number;
  item: DownloaderItem;
};

export const QueryDownloaderItem = ({ index, item }: Props) => {
  const { remove, set } = useContext(QueryDownloaderContext);
  const download = useDownloader();
  const history = useHistory();

  const { extension, id, status, websocketKey } = item;

  const invalidateProjectState = useInvalidateProjectState();
  const invalidateQuantAnalysis = useInvalidateProjectQuantAnalysisQuery();

  const onRemove = useCallback(() => {
    remove(id);
  }, [id, remove]);

  const handleComplete = useCallback(async (event: Reports.ReportAvailable.Payload) => {

    if (event.type === AsyncActivity.QuantAnalysisFileUpload) {
      console.log('quant upload complete');

      await invalidateProjectState(event.data.projectId);
      await invalidateQuantAnalysis(event.data);

      history.push(getLocationFor.project.quantAnalysis({
        slug: event.data.projectId.toString(),
        quantAnalysisId: event.data.quantAnalysisId,
      }));

      onRemove();
    } else if (event.type === AsyncActivity.FileDownload) {
      download({
        url: event.data.url,
      });
      set(id, {
        status: `transferring`,
      });
    }
  }, [download, history, id, invalidateQuantAnalysis, invalidateProjectState, onRemove, set]);

  const handleError = useCallback(() => {
    set(id, {
      status: `error`,
      websocketKey: null,
    });
  }, [
    id,
    set,
  ]);

  const renderItem = useCallback(() => {
    return (
      <FileDownloadActivityItem
        extension={extension}
        filename={item.name}
        index={index}
        onCancel={onRemove}
        onRemove={onRemove}
        status={status}
        title={item.title} />
    );
  }, [
    extension,
    index,
    item.name,
    item.title,
    onRemove,
    status,
  ]);

  if (!websocketKey) {
    return renderItem();
  }

  return (
    <SocketSubscriber
      onError={handleError}
      onReady={handleComplete}
      socketKey={websocketKey}>
      {renderItem()}
    </SocketSubscriber>
  );
};

QueryDownloaderItem.displayName = 'QueryDownloader.Item';