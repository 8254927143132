import { useContext } from 'react';
import { ProjectStateLoadingContext } from '@containers/GroupProject/Context';
import { useRegisterBrandInsights } from '@containers/GroupProject/hooks';
import { ProjectTabsContainer } from '@/containers/GroupProject/ProjectTabsContainer';
import { TabViewSkeleton } from '@/components/TabView';
import Header from './ProjectHeader';
import { Actions } from './Project.Actions';
import styles from './style/ProjectTab.css';
import ProjectTabView from './ProjectTabView';

type Props = unknown;

export const ProjectTabs = (props: Props) => {

  useRegisterBrandInsights();

  const isLoading = useContext(ProjectStateLoadingContext);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Header>
          <Actions />
        </Header>
        {isLoading
          ? <TabViewSkeleton />
          : (
            <ProjectTabsContainer>
              <ProjectTabView />
            </ProjectTabsContainer>
          )}
      </div>
    </div>
  );
};

ProjectTabs.displayName = 'Group.Project.Tabs';