import { useMemo } from 'react';
import { generatePath } from 'react-router';
import { path } from '@consts';
import { usePopupWindow } from '@utils/hooks/usePopupWindow';

type Params = {
  onOpen?:  (win?: Window) => void;
} & ICallId;

const width = '400px';

export const usePopupTaggingWindow = ({ callId, onOpen }: Params) => {

  const url = useMemo(() => {
    return generatePath(path.Conference.MiniTagging, { callId });
  }, [callId]);

  const { open, close, isOpen, popup } = usePopupWindow({
    url,
    onOpened: onOpen,
    windowOptions: {
      height: `${window.innerHeight}px`,
      resizable: false,
      width,
    },
  });

  return { isOpen, open, close, popup };
};