import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as api from '@api';
import * as actions from '@store/actions';

export const useFetchNotifications = () => {
  const dispatch = useDispatch();
  const fetchNotifications = useCallback(() => {
    api.users.notification.getNotifications()
      .then(items => {
        dispatch(actions.notificationsLoaded({
          initialized: true,
          items,
        }));
      });
  }, [
    dispatch,
  ]);

  return fetchNotifications;
};

export default useFetchNotifications;