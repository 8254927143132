import { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as api from '@api/scheduling.ad-hoc';
import * as Scheduling from '@api/interfaces/scheduling.ad-hoc';
import * as actions from '@store/actions';

type Data = Scheduling.RejectCallRequest.Response;

export function useRejectAdHocCallRequest(
  data: ICallId,
  options?: UseMutationOptions<Data, AxiosResponse, void>
) {
  const dispatch = useDispatch();

  const onSuccess = useCallback((res: Data, v: void, ctx) => {
    dispatch(actions.adHocCallUpdated({
      call: res.call,
    }));

    options?.onSuccess(res, v, ctx);
  }, [dispatch, options?.onSuccess]);

  return useMutation(keyAdHocCallRejection(data.callId), () => {

    return api.adHocCall.reject({
      callId: data.callId,
    });

  }, {
    ...options,
    onSuccess,
  });
}

export function keyAdHocCallRejection(callId: number) {
  return ['reject-ad-hoc-call-request', callId];
}