import { useCallback, useMemo, useState } from 'react';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { useSurveySectionBuilderContext } from '@/containers/SurveyBuilder.Section/context';
import { Button } from '@/components/Button';
import { Header } from '@/components/Modal/Header';
import { TextareaAutosize } from '@/components/Textarea';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import { useModal } from '@/components/Modal/hooks/useModal';
import styles from './style/Modal.Name.css';

type Props = Pick<ModalProps, 'open' | 'onClose'>;

export const SectionNameModal = ({ open, onClose }: Props) => {

  const ctx = useSurveySectionBuilderContext();
  const [_, dispatch] = useSurveyBuilderState();

  const [value, setValue] = useState(ctx.section.name || '');

  const canSubmit = useMemo(() => {
    return !!value.length;
  }, [value]);

  const handleSubmit = useCallback(() => {
    dispatch({
      type: 'section-name-updated',
      payload: {
        identifier: ctx.section.identifier,
        value,
      },
    });
    onClose();
  }, [
    dispatch,
    ctx.section.identifier,
    onClose,
    value,
  ]);

  return (
    <Modal
      className={styles.modal}
      open={open}
      onClose={onClose}>
      <Header text="Section name" />

      <div className={styles.body}>
        <div className={styles.input}>
          <TextareaAutosize
            onChange={e => setValue(e.target.value)}
            value={value} />
        </div>
      </div>

      <div className={styles.footer}>
        <Button.Primary
          variant="brick"
          className={styles.btn}
          disabled={!canSubmit}
          title="Save"
          onClick={handleSubmit} />
      </div>
    </Modal>
  );
};

export const useSectionNameModal = () => useModal(SectionNameModal);