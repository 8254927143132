import { Accordion } from '@/components/Accordion';
import type { ErrorProps } from './interfaces';
import styles from './styles/ErrorDetails.css';

export const ErrorDetails = (props: ErrorProps) => {

  return (
    <Accordion
      label={`Error Details`}
      showCount={false}
      collapsed={true}
      grows={false}>
      <div className={styles.root}>
        <div>{props.error.name}</div>
        <div>{props.error.message}</div>
        <div>{props.error.stack}</div>
      </div>
    </Accordion>
  );
};