import { useContext, useEffect, useMemo } from 'react';
import { usePastSearches } from '@containers/WorkspaceGlobalSearch/hooks/usePastSearches';
import { WorkspaceGlobalSearchKeyboardContext, WorkspaceGlobalSearchBarResetContext } from './Context';
import ResultsItems from './Results.Items';
import styles from './style/Past.css';

export default function PastSearches() {
  const { items } = usePastSearches();
  const { index } = useContext(WorkspaceGlobalSearchKeyboardContext);
  const { updatedResultsReset } = useContext(WorkspaceGlobalSearchBarResetContext);

  useEffect(() => {
    updatedResultsReset(items.length);
  }, [updatedResultsReset, items.length]);

  const resultItems = useMemo(() => items.map(i => i.item), [items]);

  if (!items.length) {
    return (
      <div className={styles.root}>
        <div className={styles.title}>Past Searches</div>
        <div className={styles.nothing}>No past searches.</div>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.title}>Past Searches</div>
      <ResultsItems
        activeIndex={index}
        items={resultItems} />
    </div>
  );
}

export { PastSearches };