import {
  extension,
  ApplySchemaAttributes,
  ExtensionTag,
  NodeExtension,
  NodeExtensionSpec,
  NodeSpecOverride,
} from '@remirror/core';
import * as Remirror from '@/types/rich-text';
// eslint-disable-next-line import/no-cycle
import { ViewStimulusNode } from './Node.ViewStimulus';

@extension({
  defaultOptions: {},
})
export class ViewStimulusExtension extends NodeExtension {

  get name() {
    return Remirror.NodeType.ViewStimulus;
  }

  createTags() {
    return [ExtensionTag.InlineNode];
  }

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    return {
      ...override,
      atom: true,
      attrs: {
        ...extra.defaults(),
      },
      inline: true,
      toDOM: node => ['span', {
        ...extra.dom(node),
        contentEditable: 'false',
        selectable: 'false',
      }],
    };
  }

  ReactComponent = ViewStimulusNode;
}