import { useCallback, useContext, useMemo, useState } from 'react';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { usePopper } from 'react-popper';
import { ProjectClipsFiltersContext } from '@/containers/Project.Clips';
import { useZIndexModifier } from '@/components/Popper';
import { Portal } from '@/components/Portal';
import { cx } from '@utils';
import { Filter } from './Filter.Icon';
import { AdditionalFiltersMenu } from './AdditionalFilters.Menu';
import styles from './style/AdditionalFilters.css';

export const AdditionalFilters = () => {
  const filters = useContext(ProjectClipsFiltersContext);

  const [filterMenuOpen, setFilterMenuOpen] = useState(false);

  const [filterElement, setFilterElement] = useState<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement>(null);

  const hasAdditionalFilters = useMemo(() => {
    return filters.groupTagIds.length > 0 || filters.speakerRole !== 'all';
  }, [filters.speakerRole, filters.groupTagIds]);

  const zIndexModifier = useZIndexModifier({ zIndex: 8 });
  const { styles: popperStyles, attributes } = usePopper(filterElement, popperElement, {
    modifiers: [
      zIndexModifier,
    ],
    placement: 'bottom-start',
  });

  const handleBlur = useCallback(() => {
    setFilterMenuOpen(false);
  }, []);

  const handleFilterClick = useCallback(() => {
    setFilterMenuOpen(true);
  }, []);

  return (
    <>
      <div
        ref={setFilterElement}
        onClick={handleFilterClick}>
        <Filter
          isActive={hasAdditionalFilters} />
      </div>
      {filterMenuOpen &&
        <Portal>
          <ClickAwayListener
            mouseEvent='onMouseDown'
            onClickAway={handleBlur}
            touchEvent='onTouchStart'>
            <div
              ref={setPopperElement}
              style={popperStyles.popper}
              {...attributes.popper}>
              <AdditionalFiltersMenu onClose={handleBlur} />
            </div>
          </ClickAwayListener>
        </Portal>
      }
    </>
  );
};