import type { CSSObjectWithLabel, StylesConfig } from 'react-select';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const styles: StylesConfig<any> = {
  menu: ex => ({
    ...ex,
    width: 'max-content',
    minWidth: '100%',
    zIndex: 2,
  } as CSSObjectWithLabel),
  container: ex => ({
    ...ex,
    maxWidth: '100%',
  } as CSSObjectWithLabel),
  valueContainer: ex => ({
    ...ex,
    overflow: 'hidden',
  } as CSSObjectWithLabel),
};

export default styles;