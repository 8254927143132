import { useCallback } from 'react';
import cuid from 'cuid';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import { getFindAndReplaceGroupingId, getFindAndReplaceLabel } from '@/containers/Survey/utils/rich-text.find-and-replace';
import { generateDefaultOptionMetadata } from '@containers/SurveyBuilder/state/defaults';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import { OptionQuestionType, SurveyOptionType } from '@/enums';
import { Tooltip } from '@presentation/Tooltip';
import { Remirror, SurveyQuestion, SurveyQuestionOption, SurveyRichText } from '@/types';
import { useFindAndReplaceEditModal } from '@/components/Survey.RichText/Node.FindAndReplace.Modal.Edit';
import styles from './style/FindAndReplace.css';

type Props = {
  question: SurveyQuestion<OptionQuestionType>;
};

export const FindAndReplaceOptions = ({ question }: Props) => {
  const [toggleModal, Modal] = useFindAndReplaceEditModal();
  const [state, dispatch] = useSurveyBuilderState();

  const handleClick = useCallback(() => {
    toggleModal();
  }, [toggleModal]);

  const handleSubmit = useCallback((label: string, groupingId: string, count: number) => {
    const metadata = generateDefaultOptionMetadata(question.typeId);
    const startingOrdinal = Math.max(...(question.options as SurveyQuestionOption[]).map(o => o.ordinal)) + 1;

    const options: SurveyQuestionOption[] = new Array(count).fill(0).map((_, idx) => {
      const value = {
        type: Remirror.NodeType.Doc,
        content: [{
          type: Remirror.NodeType.Paragraph,
          content: [{
            type: Remirror.NodeType.FindAndReplace,
            attrs: {
              identifier: cuid(),
              groupingId: getFindAndReplaceGroupingId(groupingId, label),
              label: getFindAndReplaceLabel(label, count, idx + 1),
              value: getFindAndReplaceLabel(label, count, idx + 1),
            },
          }],
        }],
      } as SurveyRichText.RootNode;

      return {
        base: {
          id: null,
          identifier: cuid(),
        },
        conditions: [],
        id: null,
        metadata: metadata,
        ordinal: startingOrdinal + idx,
        type: SurveyOptionType.Default,
        value,
      };
    });

    dispatch({
      type: 'add-question-options',
      questionIdentifier: question.base.identifier,
      options,
      reorder: true,
    });

    toggleModal();
  }, [
    dispatch,
    toggleModal,
    question.base.identifier,
    question.options,
    question.typeId,
  ]);

  return (
    <>
      <Tooltip title="Insert Structured Entry">
        <div className={styles.root}>
          <LabelOutlinedIcon
            className={styles.icon}
            onClick={handleClick} />
        </div>
      </Tooltip>
      <Modal showCount onSubmit={handleSubmit} />
    </>
  );
};