import { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { startOfDay, addSeconds, differenceInSeconds } from 'date-fns';
import type { Node } from '@remirror/pm/model';
import type { EditorState } from '@remirror/pm/state';
import { useRemirrorContext, useEditorState } from '@remirror/react';
import MouseIcon from '@mui/icons-material/Mouse';
import { useBlockPage } from '@utils/hooks';
import type { Timestamp } from '@/types/transcribe.rich-text';
import { MarkType } from '@/types/transcribe.rich-text';
import { Button } from '@/components/Button';
import { TimePicker } from '@/components/Input';
import { TranscriptEditorRefContext, TagTimePickingContext } from './context';
import styles from './style/Tag.TimeSelector.css';

type Props = {
  value: number;
  onChange: (val: number) => void;
  isValid: (val: number) => boolean;
};

export const TimeSelector = ({ value, onChange, isValid }: Props) => {
  const startOfDayMemo = useMemo(() => {
    return startOfDay(new Date());
  }, []);

  const editorContainerRef = useContext(TranscriptEditorRefContext);
  const [pickingViaClick, _setPickingViaClick] = useState(false);
  const { picking: globallyPicking, setPicking: setPickingCtx } = useContext(TagTimePickingContext);
  const { view } = useRemirrorContext();
  const state = useEditorState();

  const setPickingState = useCallback((val: boolean) => {
    _setPickingViaClick(val);
    setPickingCtx(val);
  }, [_setPickingViaClick, setPickingCtx]);

  const { block, unBlock } = useBlockPage({
    excludeElements: [{ element: editorContainerRef, onBlockedClass: styles.showcasedEditor }],
    onUnBlock: () => {
      //wrap this in a timeout since the clickaway listener is firing late and thinking that we should close outside
      setTimeout(() => {
        setPickingState(false);
      }, 0);
    },
  });

  const setPickingViaClick = useCallback((val: boolean) => {
    setPickingState(val);

    (val ? block : unBlock)();
  }, [setPickingState, block, unBlock]);

  useEffect(() => {
    if (pickingViaClick) {
      const onMouseUp = (e: MouseEvent) => {

        const editorRect = view.dom.getBoundingClientRect();

        const docPos = view.posAtCoords({
          left: Math.min(Math.max(e.x, editorRect.x), editorRect.x + editorRect.width),
          top: Math.min(Math.max(e.y, editorRect.y), editorRect.y + editorRect.height),
        }).pos;

        const ts = getBestTimestamp(docPos, state);

        if (ts) {
          if (isValid(ts)) {
            onChange(ts);
            //wrap this in a timeout since the clickaway listener is firing late and thinking that we should close outside
            setTimeout(() => {
              setPickingViaClick(false);
            }, 0);
          } else {
            //Maybe add some sort of cue to let the user know they picked an invalid number
          }
        }
      };
      editorContainerRef.addEventListener('click', onMouseUp);

      return () => {
        editorContainerRef.removeEventListener('click', onMouseUp);
      };
      //Hook up a click event on the view to figure out what time it matches up to
      //On click success pass the time back to onChange and set picking to false
      //Find a way to disable the clickaway listener for the edit tag too
    }

  }, [pickingViaClick, editorContainerRef, state, view, setPickingViaClick, onChange, isValid]);

  const pickerOnChange = useCallback((e: Date) => {
    const ts = differenceInSeconds(e, startOfDayMemo);
    if (isValid(ts)) {
      onChange(ts);
    }
  }, [startOfDayMemo, isValid, onChange]);

  return (
    <div className={styles.root}>
      <TimePicker
        value={addSeconds(startOfDayMemo, value)}
        onChange={pickerOnChange} />
      <Button
        className={styles.clickBtn}
        onClick={() => setPickingViaClick(true)}
        variant='brick'
        disabled={globallyPicking}>
        <MouseIcon fontSize='small' />
      </Button>
    </div>
  );
};

function getBestTimestamp(pos: number, state: EditorState) {
  if (pos < 1) return;
  const node = state.doc.nodeAt(pos);
  if (!node) {
    return;
  }
  let timeStamps = getNodeTimestamps(node);

  if (!timeStamps) {
    const beforeNode = state.doc.nodeAt(pos - 1);
    timeStamps = getNodeTimestamps(beforeNode);

    if (!timeStamps) {
      const afterNode = state.doc.nodeAt(pos + node.nodeSize);
      timeStamps = getNodeTimestamps(afterNode);
    }
  }

  if (timeStamps) {
    return timeStamps.s;
  }
}

function getNodeTimestamps(node: Node) {
  return node?.marks?.find(m => m.type.name === MarkType.Timestamp)?.attrs as Timestamp.Attributes;
}