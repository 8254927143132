import { useMemo, useCallback } from 'react';
import { BrandingContainer } from '@containers/Branding';
import { RepeatQuestionResponsesContainer } from '@containers/RepeatSurveyResponses/QuestionResponsesContainer';
import { QuestionResponsesContainer } from '@containers/SurveyResponses/QuestionResponsesContainer';
import SurveyResponses from '@screens/GroupProject/ProjectTabSurvey';
import { BaseProjectSurvey } from '@/types';
import { SurveyResponseFilterContainer } from '@/screens/GroupProject/SurveyResponseFilter';
import { SurveyQuerySelector } from '@/components/SurveySelector';
import { SurveyActions } from './SurveyActions';
import { useProjectState } from './hooks';
import styles from './styles/SurveyResponsesContainer.css';

export const SurveyResponsesContainer = () => {
  const state = useProjectState();

  const ResponsesComponent = useMemo(() => {
    return state.project
      ? QuestionResponsesContainer
      : RepeatQuestionResponsesContainer;
  }, [state.project]);

  return (
    <BrandingContainer>
      <SurveyQuerySelector
        projectId={state.project.id}
        classes={{ dropdownContainer: styles.dropdownContainer }}
        ActionsComponent={ActionsComponent}>
        <SurveyResponses ResponsesComponent={ResponsesComponent} />
      </SurveyQuerySelector>
    </BrandingContainer>
  );
};

const ActionsComponent = ({ survey }: { survey: BaseProjectSurvey }) => {
  const state = useProjectState();
  return (
    <div className={styles.actions}>
      <SurveyResponseFilterContainer />
      <SurveyActions survey={survey} project={state.project} />
    </div>
  );
};