import { AxiosResponse } from 'axios';
import * as api from '@api';
import * as API from '@api/interfaces';
import { UseDownloaderOptions, useQueryDownloader } from '@containers/QueryDownloader';

type Params = API.Calls.DownloadConferenceTagClip.Request;
type Data = API.Calls.DownloadConferenceTagClip.Response;

type QueryKey = readonly [string, Params];
type Options = UseDownloaderOptions<Data, AxiosResponse, Data, QueryKey>;

export const useDownloadConferenceTagClip = (params: Params, options: Options = {}) => {
  return useQueryDownloader({
    queryKey: [
      `get:calls/tag/clip`,
      params,
    ],
    queryFn: () => {
      return api.calls.downloadTagClip({
        type: params.type,
        momentId: params.momentId,
        callId: params.callId,
      });
    },
    downloaderOptions: {
      onResponse: res => ({
        type: 'websocket',
        value: res.websocketKey,
      }),
    },
    ...options,
  });
};