import * as xform from '@transformers/helpers';
import { safeJsonDate } from '@utils';
import {
  UnparsedPipelineProjectAttributes,
  UnparsedPipeline,
  UnparsedPipelineUser,
  PipelineProjectAttributes,
} from '@/types/pipeline';

export const normalizePipeline = (data: UnparsedPipeline) => {
  if (!data) return null;

  const run = xform.compose<Store.Pipeline, UnparsedPipeline>(
    parsePipeline,
  );

  return run(data);
};

export function parseProjectPipelineAttributes(item: UnparsedPipelineProjectAttributes): PipelineProjectAttributes {
  const data = {
    anonymity: item.anonymity,
    respondents: item.respondents,
    users: Object.keys(item.users).reduce<Store.Pipeline.Project.Users>((acc, userId) => {
      acc[userId] = parseUserRecord(item.users[userId]);
      return acc;
    }, {}),
  };

  return data;
}

function parsePipeline(pipeline: UnparsedPipeline) {
  const me = Object.keys(pipeline.me).reduce<Store.Pipeline.Me>((acc, projectId) => {
    acc[projectId] = parseUserRecord(pipeline.me[projectId]);
    return acc;
  }, {});

  const project = Object.keys(pipeline.project).reduce<Store.Pipeline.Project>((acc, projectId) => {
    const item = pipeline.project[projectId] as UnparsedPipelineProjectAttributes;

    acc[projectId] = parseProjectPipelineAttributes(item);
    return acc;
  }, {});

  return {
    me,
    project,
  };
}

function parseUserRecord(record: UnparsedPipelineUser) {
  return Object.assign(record, {
    acceptedOn: safeJsonDate(record.acceptedOn),
  });
}