import { cx } from '@utils';
import { Button } from '@/components/Button';
import { ActionProps } from './interfaces';
import styles from './style/Navbar.css';

type Props = ActionProps;

export const Login = (props: Props) => {
  return (
    <Button
      className={cx(styles.login, props.className)}
      to={props.to}
      href={props.href}
      onClick={props.onClick}
      color='destructive'
      variant="brick">
      {props.children}
    </Button>
  );
};