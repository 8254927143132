import { useState } from 'react';
import { AuthContainer } from '$website/containers/Auth/AuthContainer';
import { SocialAuthContainer } from '$website/containers/Auth/SocialAuthContainer';
import { LoginModalContext, SignupModalContext } from './Context';
import { LoginModal } from './LoginModal';
import { SignupModal } from './SignupModal';

type Props = {
  blocking?: boolean;
  children: React.ReactNode;
};

export const AuthModalContainer = (props: Props) => {
  const login = useState(false);
  const signup = useState(false);

  return (
    <LoginModalContext.Provider value={login}>
      <SignupModalContext.Provider value={signup}>
        {props.children}
        <AuthContainer>
          <SocialAuthContainer>
            <LoginModal blocking={props.blocking} />
            <SignupModal blocking={props.blocking} />
          </SocialAuthContainer>
        </AuthContainer>
      </SignupModalContext.Provider>
    </LoginModalContext.Provider>
  );
};