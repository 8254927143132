/** @jsxImportSource @emotion/react */
import isMobile from 'ismobilejs';
import type { Chat } from '@/brand-insights/types';
import { SystemAuthor } from './Message.Author';
import { useStyles } from './Message.System.styles';
import { SystemUserMessageFooterContainer } from './Message.System.Footer';
import { MessageQueryHints } from './QueryHints';

type Props = {
  className?: string;
  value: string;
  hints?: Chat.QueryHint.Item[];
};

export const BasicSystemUserMessage = ({ className, value, hints = [] }: Props) => {
  const device = isMobile();
  const styles = useStyles();

  return (
    <div css={styles.root} className={className}>
      {!device.phone && (
        <div css={styles.authorWrap}>
          <SystemAuthor css={styles.author} />
        </div>
      )}
      <div css={styles.body}>
        {value}
        {!!hints.length && (
          <SystemUserMessageFooterContainer>
            <MessageQueryHints hints={hints} />
          </SystemUserMessageFooterContainer>
        )}
      </div>
    </div>
  );
};