import { ExternalSurveyConfigurationBootstrapContainer } from './Bootstrap.Container';
import { OffPlatformSurveyMutationContainer } from './Mutations.Container';

type Props =
  ChildrenProps &
  IProjectId;

export const ExternalSurveyConfigurationContainer = ({ projectId, ...props }: Props) => {

  return (
    <ExternalSurveyConfigurationBootstrapContainer projectId={projectId}>
      <OffPlatformSurveyMutationContainer projectId={projectId}>
        {props.children}
      </OffPlatformSurveyMutationContainer>
    </ExternalSurveyConfigurationBootstrapContainer>
  );
};