import { useCallback } from 'react';
import cuid from 'cuid';
import { useUploadSurveyImages, useUploadImageObjectsMutation } from '@/components/Survey.RichText/hooks';
import { useRichTextEditorRegistryEditor, useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import { SurveyQuestionType } from '@/enums';
import type { SurveyBuilder } from '../SurveyBuilder/interfaces/interfaces.state';
import { useQuestionBuilderItemContext } from './Context';

export const useUploadQuestionImages = () => {
  const [_, dispatch] = useSurveyBuilderState();
  const item = useQuestionBuilderItemContext();
  const { getEditorByIdentifier } = useRichTextEditorRegistryEditor();

  const uploadImages = useUploadSurveyImages();
  const uploadImagesMutation = useUploadImageObjectsMutation();

  const handleSubmit = useCallback(async () => {
    const actions: SurveyBuilder.NonBatchActions[] = [];

    for (const option of item.options) {
      const editor = getEditorByIdentifier(option.base.identifier);
      if (editor) {
        const { updated, value } = await uploadImages({
          getContext: editor.getContext,
        });

        if (updated) {
          actions.push({
            type: 'update-question-option-value',
            questionIdentifier: item.base.identifier,
            option: {
              identifier: option.base.identifier,
            },
            value,
          });
        }
      }
    }

    for (const row of item.matrixRows) {
      const editor = getEditorByIdentifier(row.base.identifier);
      if (editor) {
        const { updated, value } = await uploadImages({
          getContext: editor.getContext,
        });

        if (updated) {
          actions.push({
            type: 'update-question-row-value',
            questionIdentifier: item.base.identifier,
            row: {
              identifier: row.base.identifier,
            },
            value,
          });
        }
      }
    }

    if (item.typeId === SurveyQuestionType.ImageMarkup) {
      if (typeof item.settings.initialImage === 'string') {
        if (item.settings.initialImage.startsWith('blob:')) {
          const [image] = await uploadImagesMutation.mutateAsync({
            images: [{
              cid: cuid(),
              src: item.settings.initialImage,
            }],
          });

          actions.push({
            type: 'update-question-settings',
            questionIdentifier: item.base.identifier,
            settings: {
              initialImage: image.src,
            },
          });
        }
      }
    }

    const { updated, value } = await uploadImages({
      getContext: getEditorByIdentifier(item.base.identifier).getContext,
    });

    if (updated) {
      actions.push({
        type: 'update-question-value',
        identifier: item.base.identifier,
        value,
      });
    }

    if (actions.length) {
      dispatch({
        actions,
        type: 'batch-actions',
      });
    }
  }, [
    dispatch,
    getEditorByIdentifier,
    uploadImages,
    item.base.identifier,
    item.typeId,
    item.matrixRows,
    item.options,
    item.settings,
    uploadImagesMutation,
  ]);

  return handleSubmit;
};