import { useRouteMatch } from 'react-router-dom';
import { Edit } from 'react-feather';
import * as consts from '@consts';
import { Tooltip } from '@presentation/Tooltip';
import { getLocationFor } from '@utils';
import { MaybeLink } from '@/components/MaybeLink';
import type { TableCellItem } from './interfaces';
import styles from './style/Table.css';

type Props = TableCellItem;

export const Cell = (props: Props) => {
  const adminRoute = useRouteMatch(consts.path.Admin.Root);

  if (!props.row.original.survey?.id) return null;

  const admin = getLocationFor.admin.project.survey.responses({
    projectId: props.row.original.project.id,
    surveyResponseId: props.row.original.survey?.id,
    surveyId: props.row.original.survey?.surveyId,
  });

  const nonadmin = getLocationFor.project.surveyResponse({
    projectSurveyResponseId: props.row.original.survey?.id,
    slug: String(props.row.original.project.id),
  });

  const to = adminRoute ? admin : nonadmin;

  return (
    <MaybeLink to={to}>
      <Tooltip title="View Response">
        <div className={styles.icon}>
          <div className={styles.bg}>
            <Edit className={styles.svg} />
          </div>
        </div>
      </Tooltip>
    </MaybeLink>
  );
};

Cell.displayName = 'Column.Response.Cell';