import { useContext } from 'react';
import { cx } from '@utils';
import { MatchListMutationContext, MatchListQueryParamsContext } from '$admin/Search/Context';
import { FiltersRemoveButton } from './Filters.Buttons';
import styles from './style/Filters.css';

type Props = unknown;

export const MatchListFilter = (props: Props) => {
  const mutation = useContext(MatchListMutationContext);
  const [list, setList] = useContext(MatchListQueryParamsContext);

  if (!list && !mutation.isLoading) return null;

  return (
    <div className={cx(styles.row, styles.match)}>
      <div className={styles.item}>
        <div className={styles.field}>
          List Match
        </div>
      </div>

      <div className={styles.item}>
        <div className={styles.field}>
          {mutation.isLoading
            ? `Importing...`
            : list?.name}
        </div>
      </div>

      <div className={styles.btn}>
        <div className={styles.remove}>
          <FiltersRemoveButton
            disabled={mutation.isLoading}
            onClick={() => setList(null)} />
        </div>
      </div>
    </div>
  );
};

MatchListFilter.displayName = 'MatchListFilter';