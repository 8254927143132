import { cx } from '@utils';
import styles from './style/Table.css';

type TableProps = {
  children: React.ReactNode;
  className?: string;
};

export const Table = (props: TableProps) => (
  <div className={cx(styles.root, props.className)}>
    {props.children}
  </div>
);
