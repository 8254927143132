import ProjectSurveyResponseReviewItem from './QuestionItem';
import {
  ProjectSurveyProps,
} from './interfaces';

const ProjectSurveyResponse = (props: ProjectSurveyProps) => {

  return (
    <>
      {
        props.survey.items.map(q => (
          <ProjectSurveyResponseReviewItem
            key={q.question.id}
            item={q}>
            {props.renderQuestionButton && props.renderQuestionButton(q)}
          </ProjectSurveyResponseReviewItem>
        ))
      }
    </>
  );
};

export default ProjectSurveyResponse;