import { useCallback, useContext, useState } from 'react';
import { MedicalBackgroundContext, MedicalBackgroundContactContext, MedicalBackgroundIdentificationContext } from '@containers/MedicalOnboarding/Context';
import { useBackgroundIdentificationValidation, useOnSubmitIdentification } from '@containers/MedicalOnboarding/hooks';
import { validators, Length } from '@containers/MedicalOnboarding/utils';
import type { ProfessionalRole } from '@/enums';
import { DatePicker } from '@/components/MedicalProfile/DatePicker';
import * as Field from '@/components/MedicalProfile/Field';
import * as Input from '@/components/MedicalProfile/Input';
import * as Select from '@/components/MedicalProfile/Select';
import { Label } from '@/components/MedicalProfile/Label';
import * as Layout from '@/components/MedicalProfile/Layout';
import type { BackNavigationProps, NextNavigationProps, OnSuccessHandler } from './interfaces';
import styles from './style/Identification.css';

type Props = {
  back?:      BackNavigationProps;
  next?:      NextNavigationProps;
  onSuccess?: OnSuccessHandler<'identification'>;
  expertise: {
    professionalRole: ProfessionalRole;
  };
};

export const Identification = ({ back, next = {}, onSuccess, ...props }: Props) => {
  const [showErrors, setShowErrors] = useState(false);
  const ctx = useContext(MedicalBackgroundContext);
  const contact = useContext(MedicalBackgroundContactContext);
  const identification = useContext(MedicalBackgroundIdentificationContext);

  const submitIdentification = useOnSubmitIdentification(data => {
    ctx.mutation.mutate({
      identification: {
        clinicalPracticeStartedOn: data.clinicalPracticeStartedOn,
        country: data.country,
        deaNumber: data.deaNumber,
        meNumber: data.meNumber,
        npiNumber: data.npiNumber,
        stateLicenseNumber: data.stateLicenseNumber,
        stateOfPractice: data.stateOfPractice,
        payerAdminType: data.payerAdminType,
        isPayer: data.isPayer,
      },
    }, {
      onSuccess: (data, variables) => {
        onSuccess?.(variables);
      },
    });
  }, [
    ctx.mutation,
    onSuccess,
  ]);

  const { errors, meta } = useBackgroundIdentificationValidation(props.expertise.professionalRole);

  const handleSubmit = useCallback(() => {
    const hasErrors = Object.values(errors).some(x => x === true);

    if (hasErrors) {
      setShowErrors(true);
      return;
    }

    setShowErrors(false);

    return submitIdentification();
  }, [errors, submitIdentification]);

  return (
    <Layout.Screen>
      <Layout.Header title={copy.title(props.expertise.professionalRole)} />

      <div className={styles.item}>
        <Label required>
          {copy.label.country}
        </Label>
        <div className={styles.field} style={{ width: '350px' }}>
          <Field.Country
            isInvalid={showErrors && errors.country}
            onChange={item => {
              const beforeCountry = identification.form.country;
              identification.setValue({ country: item.value });
              if (beforeCountry !== item.value) {
                contact.setValue({ timezone: '' });
              }
            }}
            value={identification.form.country} />
        </div>
      </div>

      {meta.stateOfPractice.visible &&
        <div className={styles.item}>
          <Label required>
            {copy.label.stateOfPractice}
          </Label>
          <div className={styles.field}>
            <Field.StateOfPractice
              className={styles.select}
              isInvalid={showErrors && errors.stateOfPractice}
              onChange={item => identification.setValue({ stateOfPractice: item.value })}
              value={identification.form.stateOfPractice} />
          </div>
        </div>}

      {meta.meNumber.visible &&
        <div className={styles.item}>
          <Label tooltip={copy.tooltip.me}>
            {copy.label.meNumber}
          </Label>
          <div className={styles.field}>
            <Input.Text
              isInvalid={showErrors && errors.meNumber}
              maxLength={meta.meNumber.length}
              minLength={meta.meNumber.length}
              placeholder="Enter ME Number"
              onChange={e => identification.setValue({ meNumber: e.target.value })}
              value={identification.form.meNumber} />
          </div>
        </div>}

      {meta.npiNumber.visible &&
        <div className={styles.item}>
          <Label
            required={meta.npiNumber.required}
            tooltip={copy.tooltip.npi}>
            {copy.label.npiNumber}
          </Label>
          <div className={styles.field}>
            <Input.Text
              isInvalid={showErrors && errors.npiNumber}
              maxLength={meta.npiNumber.length}
              minLength={meta.npiNumber.length}
              placeholder="Enter NPI Number"
              onChange={e => identification.setValue({ npiNumber: e.target.value })}
              value={identification.form.npiNumber} />
          </div>
        </div>}

      {meta.stateLicenseNumber.visible &&
        <div className={styles.item}>
          <Label required={meta.stateLicenseNumber.required}>
            {copy.label.stateLicenseNumber}
          </Label>
          <div className={styles.field}>
            <Input.Text
              isInvalid={showErrors && errors.stateLicenseNumber}
              placeholder="Enter State License Number"
              onChange={e => identification.setValue({ stateLicenseNumber: e.target.value })}
              value={identification.form.stateLicenseNumber} />
          </div>
        </div>}

      {meta.clinicalPracticeStartedOn.visible &&
        <div className={styles.item}>
          <Label required={meta.clinicalPracticeStartedOn.required}>
            {copy.label.clinicalPracticeStartedOn}
          </Label>
          <div className={styles.field}>
            <DatePicker
              isInvalid={showErrors && errors.clinicalPracticeStartedOn}
              onChange={value => identification.setValue({ clinicalPracticeStartedOn: value })}
              value={identification.form.clinicalPracticeStartedOn} />
          </div>
        </div>}

      {meta.usaPayer.visible &&
        <div className={styles.item}>
          <Label required={meta.usaPayer.required}>
            {copy.label.usaPayer}
          </Label>
          <div className={styles.field}>
            <Select.Input
              isInvalid={showErrors && errors.usaPayer}
              value={identification.form.isPayer == undefined ? '' : identification.form.isPayer ? 'y' : 'n'}
              options={[{ id: 'y', name: 'Yes' }, { id: 'n', name: 'No' }]}
              onChange={e => identification.setValue({ isPayer: e.target.value === 'y' })}
              placeholder="Yes or No" />
          </div>
        </div>}

      {(meta.usaPayer.visible && identification.form.isPayer) &&
        <div className={styles.item}>
          <Label required>
            {copy.label.usaPayerRole}
          </Label>
          <div className={styles.field} style={{ width: '350px' }}>
            <Field.USAPayerAdmin
              isInvalid={showErrors && errors.payerAdminType}
              onChange={item => identification.setValue({ payerAdminType: item.value })}
              value={identification.form.payerAdminType}  />
          </div>
        </div>}
      <Layout.Footer>
        {back && <Layout.BackButton />}
        <Layout.NextButton
          implicitDisable={false}
          loading={ctx.mutation.isLoading}
          onClick={handleSubmit}>
          {next.text}
        </Layout.NextButton>
      </Layout.Footer>
    </Layout.Screen>
  );
};

const copy = {
  label: {
    clinicalPracticeStartedOn: `Please select the approximate month and year that you started practicing after completing your medical training, residency or fellowship.`,
    country: `What is your country?`,
    deaNumber: `What is your ${Length.dea} digit DEA Number?`,
    meNumber: `What is your ${Length.me} digit ME Number?`,
    npiNumber: `What is your ${Length.npi} digit NPI Number?`,
    stateLicenseNumber: `What is your State License Number?`,
    stateOfPractice: `What is your state of primary practice?`,
    usaPayer: `Are you currently or were you in past a decision-maker or advisor in the determination of pricing, reimbursement, or funding of drug products in your country, region, or institution?`,
    usaPayerRole: `How would you classify this role?`,
  },
  title: (role: ProfessionalRole) => validators.isNonMedical(role) ? `Help us verify your background with the following information.` : `Help us verify your medical background with the following information.`,
  tooltip: {
    dea: `We request this information to validate that you are a practicing physician. It is vital to our research to ensure that our respondents have the expertise necessary to participate in studies. Your privacy and security are important to us - we will never provide this information to third parties.`,
    me: `Your ME (Medical Education) number is issued by the AMA and can be located on your AMA journal. We request this information to validate that you are a practicing physician. It is vital to our research to ensure that our respondents have the expertise necessary to participate in studies. Your privacy and security are important to us - we will never provide this information to third parties.`,
    npi: `We request this information to validate that you are a practicing health care provider. It is vital to our research to ensure that our respondents have the expertise necessary to participate in studies. Your privacy and security are important to us - we will never provide this information to third parties`,
  },
};