import * as enums from '@enums';
import { Project } from '@/types';

export const hasCallsEnabled = (projectId: number, state: Store.State) => {
  const project = state.projects[projectId];
  return !isProjectSurveyType(project);
};

export const needsSurveyTerms = (projectId: number, state: Store.State) => {
  return isComplianceSurveyRequired(projectId, state) ||
    isDocumentSigningRequired(projectId, state);
};

const hasComplianceSurvey = (projectId: number, state: Store.State) => {
  const project = state.projects[projectId];
  return !!project.complianceSurveyVersionId;
};

export const hasPendingSignatureRequest = (projectId: number, state: Store.State) => {
  if (!isDocumentSigningRequired(projectId, state)) return false;

  return isAwaitingSignatureRequest(projectId, state);
};

const hasProjectSurvey = (projectId: number, state: Store.State) => {
  const project = state.projects[projectId];
  return !!project.surveyVersionId;
};

export const isAwaitingSignatureRequest = (projectId: number, state: Store.State) => {
  const project = state.projects[projectId];
  const signature = state.documents.group[project.groupId];

  const required = signature && signature.statusCode === enums.HelloSignSignatureStatus.AwaitingSignature;

  return !!required;
};

export const isComplianceSurveyIncomplete = (projectId: number, state: Store.State) => {
  const project = state.projects[projectId];
  return project.complianceSurveyStatus === enums.SurveyStatus.Incomplete;
};

export const isComplianceSurveyRequired = (projectId: number, state: Store.State) => {
  const required = hasComplianceSurvey(projectId, state)
                && isComplianceSurveyIncomplete(projectId, state);

  return !!required;
};

export const isDocumentSigningRequired = (projectId: number, state: Store.State) => {
  const record = state.pipeline.me[projectId];
  return record.documentSigning === enums.DocumentSigning.Required;
};

export const isProjectSurveyIncomplete = (projectId: number, state: Store.State) => {
  const project = state.projects[projectId];
  return project.surveyStatus === enums.SurveyStatus.Incomplete;
};

export const isProjectSurveyRequired = (projectId: number, state: Store.State) => {

  const required = hasProjectSurvey(projectId, state)
                && isProjectSurveyIncomplete(projectId, state)
                && !isExternalProject(projectId, state);

  return !!required;
};

export const isProjectMixedType = (item: Pick<Project, 'projectType'>) => {
  return item.projectType === enums.ProjectType.SurveyCall;
};

export const isProjectSurveyType = (item: Pick<Project, 'projectType'>) => {
  return item.projectType === enums.ProjectType.Survey;
};

export const isExternalProject = (projectId: number, state: Store.State) => {
  const project = state.projects[projectId];
  return project.isExternal;
};

export const isExternalSurveyRequired = (projectId: number, state: Store.State) => {
  return isExternalProject(projectId, state) && state.projects[projectId].surveyStatus != enums.SurveyStatus.Completed;
};

export const isNonMixedSurvey = (projectId: number, state: Store.State) => {
  const project = state.projects[projectId];
  return isProjectSurveyType({ projectType: project.projectType });
};