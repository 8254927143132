import { Redirect } from 'react-router-dom';
import { path } from '@consts';
import { AccountProfileContainer, ProfileFeaturedPostsContainer } from '@containers/AccountProfile';
import { useMatchUserIdFromUrl, useUserProfileTracking } from './hooks';

type Props =
  ChildrenProps;

const StateDependentContainer = (props: Props) => {

  useUserProfileTracking();

  return (
    <>
      {props.children}
    </>
  );
};

const UserProfileContainer = (props: Props) => {

  const userId = useMatchUserIdFromUrl();

  if (!userId) {
    return (
      <Redirect to={path.Profile.NotFound} />
    );
  }

  return (
    <AccountProfileContainer userId={userId}>
      <StateDependentContainer>
        <ProfileFeaturedPostsContainer userId={userId}>
          {props.children}
        </ProfileFeaturedPostsContainer>
      </StateDependentContainer>
    </AccountProfileContainer>
  );
};

export { UserProfileContainer };
export default UserProfileContainer;