import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '@actions';
import * as consts from '@consts';
import {
  SearchParamsContainer,
  SearchQueryLegacyContainer,
  useCompatSearchParams,
  useCompatSearchQuery,
} from '@containers/Search';
import * as enums from '@enums';
import * as utils from '@utils';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import AdvancedFilters from '@/scenes/search/AdvancedFilters';
import { ProjectSearchResultItem } from '@/scenes/search/ResultItem';
import { ProjectSearchListFooter, LoadingMoreIndicator } from '@/scenes/search/SearchListFooter';
import { ProjectSearchListHeader } from '@/scenes/search/SearchListHeader';
import {
  actions as searchActions,
  getAdvancedSearchBody,
} from '@/scenes/search/SearchProvider';
import { Header, Main, Screen, Scroll, Sidebar } from '@/scenes/search/ContainerView';
import { getSearchQueryParamsDiff } from '@/scenes/search/utils';
// import ButtonSet from './ButtonSet';
import styles from './style.css';

const itemsPerRequest = 10;

const distanceToBottomBeforeLoadMore = 50;

const initialFetchState = {
  fetchCount: 0,
  loading: true,
  loadingMore: false,
  loadingInitial: true,
  results: [],
  total: 0,
};

const defaultSortOption = {
  sort: 'most_relevant',
  sortDir: 'desc',
};
const mapState = state => ({
  group: state.group,
  pipeline: state.pipeline,
  projects: state.projects,
  user: state.user,
});

const mapDispatch = {
  batchActions: actions.batchActions,
};

const ProjectSearch = props => {
  const projectId = Number(props.match.params.slug.split('-').reverse()[0]);
  const project = props.projects[projectId];

  const initialSearch = () => {

    return {
      ...SearchParamsContainer.initialState,
      formerCompany: project.companies.filter(f => !f.isCurrent).map(m => ({ id: m.id, name: m.name })),
      company: project.companies.filter(f => f.isCurrent).map(m => ({ id: m.id, name: m.name })),
      sector: project.vics.filter(f => f.typeId === enums.VICSType.Sector).map(m => ({ id: m.id, name: m.name })),
      industry: project.vics.filter(f => f.typeId === enums.VICSType.Industry).map(m => ({ id: m.id, name: m.name })),
      subindustry: project.vics.filter(f => f.typeId === enums.VICSType.SubIndustry).map(m => ({ id: m.id, name: m.name })),
      title: project.titles.map(m => ({ id: m.id, name: m.name })),
      keyword: project.keywords.map(k => ({ id: k })),
    };
  };

  const [params, dispatch] = useCompatSearchParams();
  const [query, fetch] = useCompatSearchQuery();
  const initialized = useRef(false);
  const initialParams = initialSearch();

  useLayoutEffect(() => {

    dispatch(searchActions.setFilters(initialParams));

  /* eslint-disable-next-line */
  }, []);

  const search = {
    params: initialized.current ? params : initialParams,
    ...(query.value ? query.value : SearchQueryLegacyContainer.initialState),
  };

  const [filtersVisible, setFiltersVisible] = useState(false);
  const [fetchState, setFetchState] = useState(initialFetchState);
  const [usersToExclude, setUsersToExclude] = useState(project.userIds.filter(f => !isNaN(f)));

  const addToAdvancedFilterList = data => dispatch(searchActions.addToAdvancedFilterList(data));
  const removeFromAdvancedFilterList = data => dispatch(searchActions.removeFromAdvancedFilterList(data));
  const updateSort = data => dispatch(searchActions.updateSort(data));
  const resetFilters = () => dispatch(searchActions.resetFilters(defaultSortOption));
  const searchBatchActions = data => dispatch(searchActions.batchActions(data));

  const onScroll = useCallback(e => {
    const scrolledToEnd = (document.body.scrollHeight - distanceToBottomBeforeLoadMore) <= Math.ceil(e.currentTarget.scrollY + window.innerHeight);
    const { loading, loadingMore, total, results, fetchCount } = fetchState;

    if (scrolledToEnd && !loadingMore && !loading &&
      total !== results.length && fetchCount > 0) {
      fetchMore();
    }
  }, [fetchState]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll, false);

    return () => {
      window.removeEventListener('scroll', onScroll, false);
    };
  }, [onScroll]);

  useEffect(() => {

    fetchOnce();

  /* eslint-disable-next-line */
  }, []);

  useEffect(() => {

    if (initialized.current) {
      fetchAll();
    }

  /* eslint-disable-next-line */
  }, [params]);

  if (!utils.hasClientRole(props.user) || !project) {
    return props.history.replace(consts.pathname.HOME);
  }

  const clearResults = () => {
    setFetchState({
      ...fetchState,
      loading: true,
      results: [],
      selected: [],
    });
  };

  const getSearchParams = intial => {
    const data = getAdvancedSearchBody({
      search: search.params,
      showRate: props.group.features?.showConsultantRates,
    });

    return {
      ...data,
      usersToExclude,
      size: itemsPerRequest,
      searchAfter: getSearchAfter(intial),
    };
  };

  const fetchOnce = () => {
    const data = getAdvancedSearchBody({
      search: initialParams,
      showRate: props.group.features?.showConsultantRates,
    });

    const params = {
      ...data,
      searchAfter: false,
      size: itemsPerRequest,
      usersToExclude,
    };

    return fetch(params)
    .then(data => updateResults({
      fetchCount: data.items.length,
      results: data.items,
      total: data.total,
    }))
    .then(() => initialized.current = true);

  };

  const fetchAll = () => {
    clearResults();

    return fetch(getSearchParams(true))
    .then(data => updateResults({
      fetchCount: data.items.length,
      results: data.items,
      total: data.total,
    }));
  };

  const fetchMore = () => {
    setFetchState({
      ...fetchState,
      loadingMore: true,
    });

    return fetch(getSearchParams(false))
    .then(data => updateResults({
      fetchCount: data.items.length,
      results: data.items,
      total: data.total,
    }));
  };

  const getSearchAfter = initial => {
    return !initial
           ? fetchState.results[fetchState.results.length - 1].sort
           : null;
  };

  const handlePriceChange = priceRange => {
    searchBatchActions([
      searchActions.setPriceRange({
        high: priceRange.high,
        low: priceRange.low,
      }),
      searchActions.applyAdvancedFilters(),
    ]);
  };

  const handleSortChange = item => {
    updateSort({
      sort: item.sort,
      sortDir: item.dir,
    });
  };

  const addItemToSearchFilter = item => {
    addToAdvancedFilterList({
      item,
      list: enums.SearchType.JobFunction,
    });
  };

  const toggleFiltersVisibility = () => {
    setFiltersVisible(!filtersVisible);
  };

  const updateResults = data => {
    setTimeout(() => setFetchState({
      loading: false,
      loadingMore: false,
      results: data.results,
      total: data.total,
      fetchCount: data.fetchCount,
    }), 100);
  };

  return (
    <Screen>

      <Sidebar>
        <AdvancedFilters
          search={search.params}
          handlePriceChange={handlePriceChange}
          handleItemAdded={addToAdvancedFilterList}
          handleItemRemoved={removeFromAdvancedFilterList}
          onClose={toggleFiltersVisibility}
          priceRange={{
            priceLow: search.params.priceLow,
            priceHigh: search.params.priceHigh,
          }}
          visible={filtersVisible}
          showRate={props.group.features?.showConsultantRates}
          onResetFilters={resetFilters}
          onSortChange={handleSortChange} />
      </Sidebar>

      <Main>
        <Header>
          <ProjectSearchListHeader
            project={project}
            onClick={() => setFiltersVisible(true)} />
        </Header>

        <Scroll className={styles.scroll}>
          {fetchState.results.map((item, idx) =>
            <ProjectSearchResultItem
              item={item}
              position={idx + 1}
              project={project}
              key={item.id}
              onClickTag={addItemToSearchFilter}>
              {/* <ButtonSet
                projectId={project.id}
                userId={item.id} /> */}
            </ProjectSearchResultItem>)}

          <LoadingMoreIndicator visible={fetchState.loadingMore} />

          <ProjectSearchListFooter
            projectId={project.id}
            resultsCount={fetchState.results.length}
            totalCount={fetchState.total}
            visible={!fetchState.loading} />

          <ActivityIndicator show={fetchState.loading} />
        </Scroll>
      </Main>

    </Screen>
  );
};

export default connect(mapState, mapDispatch)(ProjectSearch);