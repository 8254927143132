import { useContext } from 'react';
import { useQueryParams } from 'use-query-params';
import { BrandStateContext } from '@/containers/Workspace.Brand/Context';
import { useInitiateFilesReview } from '@/containers/WorkspaceFileUpload/hooks/useInitiateFilesReview';
import { CanUploadFilesContext, UploadFilesContext } from '@/containers/WorkspaceFileUpload/Context';
import {
  FilesTableContext,
  FilesTableDataContext,
  SetFilesTableFiltersContext,
  FilesTableFiltersContext,
  FilesTableLoadingContext,
  SelectedObjectsContainer,
} from '@/components/Workspace.FilesTable';
import { useFetchObjectChildren } from '@/utils/api/workspace.objects';
import { WorkspaceObjectType, utils } from '@/enums';

type Props = ChildrenProps;

const useParams = () => {
  return useQueryParams({

  });
};

export const BrandFilesContainer = (props: Props) => {
  const [params, setQueryParams] = useParams();

  const state = useContext(BrandStateContext);

  const uploadFiles = useInitiateFilesReview({
    parentObjectId: state.object.id,
    workspaceId: state.object.workspaceId,
  });

  const query = useFetchObjectChildren({
    parentObjectId: state.object.id,
    workspaceId: state.object.workspaceId,
    filters: {
      typeIds: utils.WorkspaceObjectType.values().filter(ot => ot !== WorkspaceObjectType.ProjectParent),
      ...params,
    },
  }, {
    refetchOnWindowFocus: false,
    placeholderData: {
      items: [],
      meta: {
        count: 0,
        size: 0,
      },
    },
  });

  return (
    <SelectedObjectsContainer>
      <FilesTableDataContext.Provider value={query.data?.items || []}>
        <FilesTableLoadingContext.Provider value={query.isLoading}>
          <FilesTableFiltersContext.Provider value={params}>
            <SetFilesTableFiltersContext.Provider value={setQueryParams}>
              <FilesTableContext.Provider value={{ parentObject: state.object }}>
                <UploadFilesContext.Provider value={uploadFiles}>
                  <CanUploadFilesContext.Provider value={state.capabilities.canAddChildren}>
                    {props.children}
                  </CanUploadFilesContext.Provider>
                </UploadFilesContext.Provider>
              </FilesTableContext.Provider>
            </SetFilesTableFiltersContext.Provider>
          </FilesTableFiltersContext.Provider>
        </FilesTableLoadingContext.Provider>
      </FilesTableDataContext.Provider>
    </SelectedObjectsContainer>
  );
};