import Skel from '@mui/material/Skeleton';
import { Card } from '@screens/Main.Member/Card';
import { cx } from '@utils';
import styles from './style/Feed.Skeleton.css';

type Props = {
  className?: string;
};

export const Skeleton = (props: Props) => {

  return (
    <Card className={cx(styles.root, props.className)}>
      <div className={styles.wrap}>
        <div className={styles.info}>
          <div className={styles.circle}>
            <Skel
              variant="circular"
              height={48}
              width={48} />
          </div>
          <div className={styles.text}>
            <div className={styles.sub}>
              <Skel
                height={20}
                width="25%"
                variant="text" />
              <Skel
                height={20}
                width="40%"
                variant="text" />
            </div>
          </div>
        </div>

        <div className={styles.desc}>
          <Skel
            height={22}
            width="70%"
            variant="text" />
        </div>

        <div className={styles.item}>
          <div className={styles.box}>
            <div className={styles.content}>
              <Skel
                height="100%"
                variant="rectangular" />
            </div>
          </div>
        </div>

        <div className={styles.body}>
          <Skel
            height={22}
            width="100%"
            variant="text" />
          <Skel
            height={22}
            width="100%"
            variant="text" />
          <Skel
            height={22}
            width="75%"
            variant="text" />
        </div>
      </div>
    </Card>
  );
};

Skeleton.displayName = 'Feed.Skeleton';