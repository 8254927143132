export function isValidEmail(email: string) {
  if (!email) return false;
  const re = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i);
  return !!re.exec(email);
}

export function isValidPaypal(email: string) {
  if (!email) return false;
  if (email.toLowerCase().endsWith('@privaterelay.appleid.com')) return false;
  return isValidEmail(email);
}