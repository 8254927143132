import { useQuery } from '@tanstack/react-query';
import { fetchLanguages } from '@api/search';

export const useLanguagesQuery = () => {
  return useQuery({
    queryKey: ['fetch-languages'], queryFn: async () => {
      const res = await fetchLanguages();
      const primaryLanguages = [
        `en-US`,
        `es-ES`,
        `es-US`,
        `pt-PT`,
        `pt-BR`,
      ];

      return [
        ...res.items.filter(f => primaryLanguages.includes(f.fullCode)),
        ...res.items.filter(f => !primaryLanguages.includes(f.fullCode)),
      ];
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};