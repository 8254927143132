import { useCallback, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import cuid from 'cuid';
import * as api from '@api';
import { IConference } from '@containers/Conference';
import { ConferenceTagType } from '@enums';
import { ConferenceTag } from '@/types';
import { TaggingContext } from './Context';
import { buildQueryKey, useFetchAvailableConferenceTags } from './hooks';
import { Tagging, Tags } from './interfaces';

type Props = {
  children: React.ReactNode;
  enabled:  boolean;
  initial?: Tagging.Tag[];
  instance: Pick<IConference.Coordinator.Conference.MeetingRoom, 'conferenceIdentifier'>;
  popup?:   boolean;
};

export const TaggingContainer = ({ instance, initial = [], popup = false, ...props }: Props) => {
  const [focus, setFocus] = useState<Tagging.Tag['identifier']>(null);
  const [items, setItems] = useState<Tagging.Tag[]>(initial);
  const qc = useQueryClient();

  const { query } = useFetchAvailableConferenceTags({
    conferenceIdentifier: instance.conferenceIdentifier,
  }, {
    enabled: props.enabled,
  });

  const insert = useCallback(() => {
    const initial = createInitialTag();

    setItems(tags => [...tags, initial]);
    setFocus(initial.identifier);

  }, []);

  const discard = useCallback((identifier: string) => {
    setItems(tags => tags.filter(x => x.identifier !== identifier));
  }, []);

  const removeSavedTag = useCallback((identifier: string) => {
    const remaining = items.filter(x => x.identifier !== identifier);

    setItems(remaining);
  }, [items]);

  const setTagValue: Tagging.SetValue = useCallback((key, value) => {
    setItems(tags => {
      return tags.map(item => {
        if (item.identifier !== focus) return item;

        switch (key) {
          case 'color':
            return {
              ...item,
              color: value as Tagging.Tag['color'],
            };

          case 'end':
            return {
              ...item,
              end: value as Tagging.Tag['end'],
            };

          case 'id': {
            const reuseable = query.data?.find?.(x => x.id === value);

            return {
              ...reuseable,
              identifier: item.identifier || cuid(),
              start: item.start,
              end: item.end,
            };
          }

          case 'name':
            return {
              ...item,
              id: null,
              name: value as Tagging.Tag['name'],
            };

          case 'start':
            return {
              ...item,
              start: value as Tagging.Tag['start'],
            };

          default:
            return item;
        }
      });
    });

    if (key === 'start') {
      const tag = items.find(x => x.identifier === focus);

      if (tag?.name) {
        setFocus(null);
      }
    }
  }, [
    focus,
    items,
    query.data,
  ]);

  const mutation = useMutation({
    mutationFn: (data: Tagging.MutationParams) => {
      return api.conferences.saveTag({
        ...getTagIdParam(data.item.id),
        color: data.item.color,
        conferenceIdentifier: instance.conferenceIdentifier,
        name: data.item.name,
        tagEnd: data.item.end,
        tagStart: data.item.start,
        typeId: data.item.typeId,
      });
    },
    onMutate: variables => {
      setItems(tags => tags.map(tag => {
        if (tag.identifier !== variables.identifier) return tag;

        return {
          ...tag,
          end: variables.item.end,
        };
      }));

      return {
        ...getTagIdParam(variables.item.id),
        conferenceIdentifier: instance.conferenceIdentifier,
      };
    },
    onSuccess: (data, variables, context) => {
      if (!context.tagId) {
        qc.setQueryData(buildQueryKey(context.conferenceIdentifier), (prev: ConferenceTag[]) => {
          return (prev ?? [])
            .filter(t => t.id !== data.tag.id)
            .concat(data.tag);
        });
      }
    },
  });

  const handleFocus = useCallback((identifier: string) => {
    if (focus === identifier) return;

    const unstarted = items.find(x => !x.start && focus === x.identifier);

    if (unstarted) {
      /* discard(unstarted.identifier); */
    }

    setFocus(identifier);
  }, [
    /* discard, */
    items,
    focus,
    setFocus,
  ]);

  const replace = useCallback((items: Tagging.Tag[]) => {
    setItems(items);
  }, []);

  const value = {
    discard,
    focus,
    insert,
    items,
    mutation,
    queries: {
      available: query,
    },
    popup,
    replace,
    remove: removeSavedTag,
    setFocus: handleFocus,
    setValue: setTagValue,
  };

  return (
    <TaggingContext.Provider value={value}>
      {props.children}
    </TaggingContext.Provider>
  );
};

TaggingContainer.displayName = 'Tagging.Container';

function createInitialTag() {
  return {
    color: Tags.defaultColor,
    createdBy: null,
    end: null,
    id: null,
    identifier: cuid(),
    name: null,
    start: null,
    typeId: ConferenceTagType.UserCreated,
  };
}

function getTagIdParam(id?: number) {
  return id
    ? { tagId: id }
    : {};
}