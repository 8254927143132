import { useEffect } from 'react';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { usePastSearches } from '@containers/WorkspaceGlobalSearch/hooks/usePastSearches';
import { WorkspaceGlobalSearchBarPopupContext } from './Context';
import Containers from './Containers';
import Popper from './Popper';
import styles from './style.css';

export default function WorkspaceGlobalSearchBar() {
  const { fetchPast } = usePastSearches();

  const popupState = usePopupState({
    variant: 'popper',
    popupId: styles.portal,
    disableAutoFocus: true,
  });

  const value = {
    isOpen: popupState.isOpen,
    setOpen: popupState.setOpen,
  };

  useEffect(() => {
    fetchPast();
  }, []);

  return (
    <WorkspaceGlobalSearchBarPopupContext.Provider value={value}>
      <Containers>
        <Popper popupState={popupState} />
      </Containers>
    </WorkspaceGlobalSearchBarPopupContext.Provider>
  );
}

export { WorkspaceGlobalSearchBar };