import { useCallback, memo } from 'react';
import { generatePath } from 'react-router-dom';
import { Copy } from 'react-feather';
import * as consts from '@consts';
import { ExternalProjectSecretType } from '@enums';
import { cx } from '@utils';
import Toast from '@/components/Toast';
import type { ExternalSurveyConfiguration } from '@/types';
import styles from './styles/RedirectLinks.css';

type Secret = {
  value: string;
  type: ExternalProjectSecretType;
};

type SecretLinkProps = {
  projectId: number;
} & Secret;

type Props = {
  externalSurveyConfiguration: ExternalSurveyConfiguration;
} & IProjectId;

export const RedirectLinks = ({ externalSurveyConfiguration, projectId }: Props) => {
  return (
    <div>
      <div className={styles.secret}>
        <div className={styles.secretLabel}>Disqualify:</div>
        <SecretLink
          value={externalSurveyConfiguration.nafSecret}
          type={ExternalProjectSecretType.Naf}
          projectId={projectId} />
      </div>
      <div className={styles.secret}>
        <div className={styles.secretLabel}>Overquota:</div>
        <SecretLink
          value={externalSurveyConfiguration.quotaExceededSecret}
          type={ExternalProjectSecretType.QuotaExceeded}
          projectId={projectId} />
      </div>
      <div className={styles.secret}>
        <div className={styles.secretLabel}>Completion:</div>
        <SecretLink
          value={externalSurveyConfiguration.completionSecret}
          type={ExternalProjectSecretType.Success}
          projectId={projectId} />
      </div>
    </div>
  );
};

const SecretLink = memo(({ value, type, projectId }: SecretLinkProps) => {
  const url = buildSecretUrl({ value, type }, projectId);

  const handleClick = useCallback(() => {
    navigator.clipboard.writeText(url);
    Toast.success({
      title: 'Copied',
    });
  }, [url]);

  return (
    <div
      className={styles.link}
      onClick={handleClick}>
      {url}
      <Copy
        className={cx(styles.icon, styles.copyIcon)}
        size={18} />
    </div>
  );
});

function getSecretTemplateUrl(type: ExternalProjectSecretType) {
  switch (type) {
    case ExternalProjectSecretType.Success:
      return `${process.env.FRONT_BASE_URL}${consts.path.ExternalProject.Success}`;
    case ExternalProjectSecretType.QuotaExceeded:
      return `${process.env.FRONT_BASE_URL}${consts.path.ExternalProject.QuotaLimit}`;
    case ExternalProjectSecretType.Naf:
      return `${process.env.FRONT_BASE_URL}${consts.path.ExternalProject.Disqual}`;
  }
}

function buildSecretUrl(secret: Secret, projectId: number) {
  return generatePath(getSecretTemplateUrl(secret.type), { secret: secret.value, projectId });
}