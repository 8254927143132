/* eslint-disable space-before-function-paren */
import { useEffect, useRef, useCallback } from 'react';
import { Logger } from 'twilio-video';
import { ConferenceTwilioLogContext } from './Context';
import type { TwilioLogs } from './interfaces';

const LOG_LEVEL = 'warn';

type Log = TwilioLogs.Log;

export const TwilioLogContainer = (props: ChildrenProps) => {
  const logRef = useRef<Log[]>([]);

  useEffect(() => {
    const logger = Logger.getLogger('twilio-video');

    const originalFactory = logger.methodFactory;
    logger.methodFactory = function (methodName, logLevel, loggerName) {
      const method = originalFactory(methodName, logLevel, loggerName);
      return function (datetime, logLevel: string, component, message: string, data) {
        let dataStr = '';

        if (data) {
          try {
            dataStr = JSON.stringify(data);
          } catch {
            dataStr = '[stringification error]';
          }
        }

        logRef.current.push({
          type: logLevel,
          message: message + dataStr,
        });
        method(datetime, logLevel, component, message, data);
      };
    };

    // set the logger to log debug messages and above
    logger.setLevel(LOG_LEVEL);

    return () => {
      logger.methodFactory = originalFactory;
      logger.setLevel(LOG_LEVEL);
    };
  }, []);

  const pushLog = useCallback((log: Log) => {
    console.log(log);
    logRef.current.push(log);
  }, []);

  return (
    <ConferenceTwilioLogContext.Provider value={{ getLogs: () => logRef.current, pushLog }}>
      {props.children}
    </ConferenceTwilioLogContext.Provider>
  );
};