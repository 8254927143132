import http from '@services/http';
import * as xform from '@transformers';
import type { SearchType } from '@enums';
import { qs } from '@utils';
import type { Search as S } from '@/types';
import type { Search } from './interfaces';

export * as medical from './search.medical';
export * as members from './search.members';
export * as transcriptions from './search.transcriptions';
export * from './search.vics';

export const fetchIndividualQueryResults = (data: Search.FetchQueryResults.Request): Promise<Search.FetchIndividualQueryResults.Response> => {
  return fetchQueryResults(data);
};

export const fetchEnterpriseQueryResults = (data: Search.FetchQueryResults.Request) => {
  return fetchQueryResults(data)
    .then((res: Search.FetchEnterpriseQueryResults.Response) => ({
      items: xform.addBindingsToSearchResults(res.results) as S.Legacy.QueryResultItem[],
      total: res.total,
    }));
};

export const fetchEnterpriseQueryResultsExport = (data: Search.FetchQueryResultsExport.Request): Promise<Search.FetchQueryResultsExport.Response> => {
  const query = qs.base64Encode(data);
  return http.download(`/search?${query}`);
};

export const fetchQueryResults = (data: Search.FetchQueryResults.Request) => {
  type Response =
    Search.FetchIndividualQueryResults.Response &
    Search.FetchEnterpriseQueryResults.Response;

  const query = qs.base64Encode(data);

  return http.get<Response>(`/search?${query}`);
};

export const fetchSectorFields = (): Promise<Search.FetchSectorFields.Response> => {
  return http.get(`/search/sectors`);
};

export const fetchCountries = () => {
  return http.get<Search.FetchCountries.Response>(`/search/countries`);
};

export const fetchCurrencies = () => {
  return http.get<Search.FetchCurrencies.Response>(`/search/currencies`);
};

export const fetchMarkets = () => {
  return http.get<Search.FetchMarkets.Response>(`/search/markets`);
};

export const fetchSpecialties = (data: Search.FetchSpecialties.Request) => {
  return http.get<Search.FetchSpecialties.Response>(`/search/specialties`, {
    params: data,
  });
};

export const fetchStates = () => {
  return http.get<Search.FetchStates.Response>(`/search/states`);
};

export const fetchTimezones = (data?: Search.FetchTimezones.Request) => {
  return http.get<Search.FetchTimezones.Response>(`/search/timezones${(data?.code || data?.country) ? `?${qs.stringify(data)}` : ''}`);
};

export const fetchTimezonesUS = () => {
  return http.get<Search.FetchTimezonesUS.Response>(`/search/timezones/us`);
};

export const fetchLanguages = (data?: Search.FetchLanguages.Request) => {
  return http.get<Search.FetchLanguages.Response>(`/search/languages${data?.term ? `?${qs.stringify(data)}` : ''}`);
};

export const getAutocompleteResults = <T extends SearchType>(data: Search.GetAutocompleteResults.Request<T>) => {
  return http.get<Search.GetAutocompleteResults.Response<T>>(`/search/autocomplete?${qs.stringify(data, { arrayFormat: 'bracket' })}`)
    .then(res => res.results);
};

export const getCompanySectors = (data: Search.GetCompanySectors.Request) => {
  return http.post(`/search/company-sectors`, data)
    .then((resp: Search.GetCompanySectors.Response) => resp.results);
};

export const fetchSurveyTemplateData = (data: Search.FetchSurveyTemplateData.Request) => {
  const query = qs.stringify(data);
  return http.get<Search.FetchSurveyTemplateData.Response>(`/search/survey-template-data?${query}`);
};