import { useCallback, useMemo, useState } from 'react';
import { useHelpers } from '@remirror/react';
import { GrayOutlineButton } from '@presentation/Buttons';
import { SurveyLogic } from '@/types';
import { useSurveyRuleState, validateLogicExpression, Validation } from '@/containers/SurveyBuilder.Logic';
import { Button } from '@/components/Button';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import ModalHeader from '@/components/Modal/Header';
import { useModal } from '@/components/Modal/hooks';
import { ExpressionEditorContainer } from '../../containers/SurveyBuilder.Logic/Expression.EditorContainer';
import { Toolbar } from './Expression.Toolbar';
import { ExpressionEditor } from './Expression.Editor';
import { LogicValidation } from './Builder.Validation';
import styles from './style/ExpressionModal.css';
import { useSurveyExpressionEditor } from './hooks';

type Props =
  Pick<ModalProps,
    'open' |
    'onClose'>;

const LogicExpressionModal = ({ open, onClose }: Props) => {

  const [_, dispatch] = useSurveyRuleState();
  const [validation, setValidation] = useState<Validation>({ success: true });

  const { getJSON } = useHelpers();

  const handleSubmit = useCallback(() => {
    const value = getJSON() as SurveyLogic.SurveyLogicExpression.RichTextValue;

    const valid = validateLogicExpression(value);

    if (!valid.success) {
      setValidation(valid);
    } else {
      dispatch({
        type: 'rule-expression-updated',
        payload: { value },
      });
      onClose();
    }
  }, [dispatch, getJSON, onClose]);

  return (
    <Modal
      className={styles.modal}
      open={open}
      onClose={onClose}>

      <ModalHeader text="Expression Editor" />

      <div className={styles.body}>
        <Toolbar className={styles.toolbar} />
        <ExpressionEditor />
        <LogicValidation value={validation} />
      </div>

      <div className={styles.footer}>
        <GrayOutlineButton
          className={styles.btn}
          onClick={onClose}>
          Cancel
        </GrayOutlineButton>
        <Button.Primary
          variant="brick"
          onClick={handleSubmit}
          className={styles.btn}>
          Save
        </Button.Primary>
      </div>
    </Modal>
  );
};

const Container = (props: Props) => {

  const [ruleState] = useSurveyRuleState();
  const { manager, state, setState } = useSurveyExpressionEditor({
    initialState: ruleState.expression,
  });

  return (
    <ExpressionEditorContainer
      manager={manager}
      setState={setState}
      state={state}>
      <LogicExpressionModal {...props} />
    </ExpressionEditorContainer>
  );
};

export const useLogicExpressionModal = () => useModal(Container);
export { Container as LogicExpressionModal };