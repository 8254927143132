import { cx } from '@utils';
import styles from './style.css';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const Screen = (props: Props) => {
  return (
    <div className={cx(styles.root, props.className)}>
      {props.children}
    </div>
  );
};

const Header = (props: Props) => {
  return (
    <div className={cx(styles.header, props.className)}>
      <div className={styles.main}>
        {props.children}
      </div>
    </div>
  );
};

const Main = (props: Props) => {
  return (
    <div className={styles.wrap}>
      {props.children}
    </div>
  );
};

const Scroll = (props: Props) => {
  return (
    <div className={styles.items}>
      <div className={props.className}>
        {props.children}
      </div>
    </div>
  );
};

const Sidebar = (props: Props) => {
  return (
    <div className={cx(styles.sidebar, props.className)}>
      {props.children}
    </div>
  );
};

export { Header };
export { Main };
export { Screen };
export { Scroll };
export { Sidebar };