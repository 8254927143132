import { useCallback, useMemo, useState } from 'react';
import cuid from 'cuid';
import { SurveyTemplate } from '@/types/survey.templates';

type Props = {
  initial: BaseItem[];
  max: number;
  min: number;
};

export const useListElements = ({
  initial,
  min, max,
}: Props) => {

  const [items, setItems] = useState<BaseItem[]>(initial);

  const canAdd = useMemo(() => items.length < max, [items, max]);
  const canRemove = useMemo(() => items.length > min, [items, min]);

  const onRemoveItem = useCallback((id: string) => () => {
    setItems(items => items.filter(f => f.id !== id));
  }, []);

  const onUpdateItem = useCallback((id: string) => (value: string) => {
    setItems(items => {
      return items.reduce<BaseItem[]>((acc, c) => {
        if (c.id === id) {
          return acc.concat({
            id: c.id,
            value,
          });
        }
        else return acc.concat(c);
      }, []);
    });
  }, []);

  const onAddItem = useCallback(() => {
    setItems(items => items.concat({
      id: cuid(),
      value: '',
    }));
  }, []);

  return {
    canAdd,
    canRemove,
    items,
    onAddItem,
    onRemoveItem,
    onUpdateItem,
    setItems,
  } as const;
};

type CanSave = {
  items: SurveyTemplate.LinkedEntity[];
  maxItems: number;
  minItems: number;
};

export const useCanSaveList = ({
  items,
  minItems,
  maxItems,
}: CanSave) => {

  return useMemo(() => {
    const numberValid = items.length >= minItems
      && items.length <= maxItems;

    if (!numberValid) return false;

    const contentValid = !items
      .some((s, i) => {
        const isValid = s.value.length >= 2;

        if (!isValid) return true;

        const isDupe = items.some((ss, ii) => ss.value === s.value && i !== ii);

        return isDupe;
      });

    return contentValid;
  }, [
    items,
    minItems,
    maxItems,
  ]);
};

type BaseItem = {
  id: string;
  value: string;
};