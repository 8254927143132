import { useCallback, useEffect, useRef, useState } from 'react';
import { useInterval } from '@utils/hooks';

type WindowOptions = {
  height: string;
  width: string;
  resizable: boolean;
  scrollbars: boolean;
  top: number;
};

type Props = {
  url: string;
  onClosed?: () => void;
  onBlocked?: () => void;
  onOpened?: (win: Window) => void;
  windowOptions?: Partial<WindowOptions>;
};

export default function usePopupWindow({ url, onBlocked, onClosed: onClosedParam, onOpened, windowOptions }: Props) {
  const popup = useRef<Window>();
  const isBlocked = useRef<boolean>(false);
  const [isOpen, setOpen] = useState(false);

  const onClosed = useCallback(() => {
    setOpen(false);
    popup.current = null;
    onClosedParam?.();
  }, [onClosedParam]);

  const open = useCallback(() => {
    isBlocked.current = false;

    const options = resolveWindowOptions(windowOptions);

    const opts = Object.keys(options).map((k: keyof typeof options) => `${k}=${options[k]}`).join(',');

    try {
      popup.current = window.open(url, '', opts);

      if (popup.current) {
        popup.current.onload = () => {
          onOpened?.(popup.current);
        };
      }

      if (!popup.current || popup.current.closed || typeof popup.current.closed === 'undefined') {
        isBlocked.current = true;
        onBlocked?.();
      } else {
        try {
          popup.current.focus();
          popup.current.onbeforeunload = onClosed;
          setOpen(true);
        } catch (e) {
          isBlocked.current = true;
          onBlocked?.();
        }
      }
    } catch (e) {
      isBlocked.current = true;
      onBlocked?.();
    }
  }, [onBlocked, url, windowOptions, onClosed, onOpened]);

  const close = useCallback(() => {
    if (popup.current) {
      popup.current.close();
    }
    onClosed();
  }, [onClosed]);

  const pollClosed = useCallback(() => {
    if (!isBlocked.current && popup.current && popup.current.closed) {
      onClosed?.();
    }
  }, [onClosed]);

  useInterval(pollClosed, 500);

  return { open, close, isOpen, popup: popup.current };
}

export { usePopupWindow };

function resolveWindowOptions(options: Partial<WindowOptions>) {
  function boolToNumberOption(val: boolean) {
    if (val === undefined) return undefined;

    return val ? 1 : 0;
  }

  const width = screen.availWidth >= 900 ? 700 : 500;
  const height = Math.max(400, screen.availHeight - 200);

  const defaultOptions = {
    resizable: 1,
    scrollbars: 1,
    width,
    height,
    top: window.screenY + (window.outerHeight - height) / 2.5,
    left: window.screenX + (window.outerWidth - width) / 2,
  };

  if (!options) return defaultOptions;

  const { resizable, scrollbars, ...rest } = options;
  const transformedOptions = {
    resizable: boolToNumberOption(resizable),
    scrollbars: boolToNumberOption(scrollbars),
    ...rest,
  };

  return Object.assign(defaultOptions, transformedOptions);
}