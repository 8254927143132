import { useEffect } from 'react';
import cuid from 'cuid';
import { convertToSurveyRichText } from '@/containers/Survey/utils';
import { SurveyConditionType, SurveyOptionType, SurveyQuestionConditionType, SurveyQuestionType, SurveyTaggingResultType } from '@/enums';
import type { MultipleChoiceQuestion } from '@/types';
import { indexBy } from '@/utils/array';
import type { SurveyBuilder } from '../interfaces';

type Props = {
  state: SurveyBuilder.State;
  dispatch: (action: SurveyBuilder.Actions) => void;
};

export const useSyncLanguageQuestion = ({ state, dispatch }: Props) => {
  useEffect(() => {
    const languages = state.survey.language.languages;
    //perform the necessary actions to make sure that the first question of the survey is the language question if there are more than 1 languages
    const existingLanguageQuestion = state.survey.questions.find(q => q.metadata.derivedType === 'language');

    let languageCodeOptions: Record<string, string> = {};
    let questionIdentifier = existingLanguageQuestion?.base?.identifier;

    const actions: SurveyBuilder.NonBatchActions[] = [];
    if (languages.length > 1) {
      if (existingLanguageQuestion) {
        const existingingOptions = (existingLanguageQuestion.options as MultipleChoiceQuestion.Option[]).filter(o => languages.some(l => l.code === o.metadata.languageCode));
        const options = languages.map<MultipleChoiceQuestion.Option>((l, i) => {
          const existingOption = existingingOptions.find(o => o.metadata.languageCode === l.code);
          if (existingOption) return { ...existingOption, ordinal: i + 1 };

          return {
            id: null,
            base: {
              id: null,
              identifier: cuid(),
            },
            value: convertToSurveyRichText(l.name),
            conditions: [],
            metadata: { canDelete: false, canModifyValue: false, isAnchored: false, isOpenEnded: false, template: {}, languageCode: l.code },
            ordinal: i + 1,
            type: SurveyOptionType.Default,
          };
        });

        languageCodeOptions = indexBy(options, k => k.metadata.languageCode, v => v.base.identifier);

        //Update the options to match the languages available
        actions.push({
          type: 'replace-options',
          options,
          questionIdentifier: existingLanguageQuestion.base.identifier,
        });
      } else {
        const sectionIdentifier = cuid();
        questionIdentifier = cuid();
        const itemIdentifier = cuid();
        //Create the question and a containing section
        actions.push({
          type: 'section-added',
          value: {
            ordinal: 1,
            hidden: false,
            identifier: sectionIdentifier,
            name: 'Language Selector',
            metadata: {
              canAddQuestion: false,
              canDelete: false,
              canModifyVisibility: false,
              linkedOrder: null,
              randomize: false,
              randomizeSelf: null,
              template: {},
            },
            id: null,
            isScreener: false,
          },
        });

        actions.push({
          type: 'question-added',
          questionIdentifier,
          sectionIdentifier,
          item: {
            identifier: itemIdentifier,
            ordinal: 1,
          },
        });

        actions.push({
          type: 'question-metadata-updated',
          payload: {
            questionIdentifier,
            metadata: {
              derivedType: 'language',
              canDelete: false,
              canModifyValue: false,
            },
          },
        });

        actions.push({
          type: 'update-question-value',
          value: convertToSurveyRichText('Please select your language'),
          identifier: questionIdentifier,
        });

        actions.push({
          type: 'update-question-type',
          questionIdentifier,
          typeId: SurveyQuestionType.MultipleChoice,
        });

        const options = languages.map<MultipleChoiceQuestion.Option>((l, i) => ({
          id: null,
          base: {
            id: null,
            identifier: cuid(),
          },
          value: convertToSurveyRichText(l.name),
          conditions: [],
          metadata: { canDelete: false, canModifyValue: false, isAnchored: false, isOpenEnded: false, template: {}, languageCode: l.code },
          ordinal: i + 1,
          type: SurveyOptionType.Default,
        }));

        languageCodeOptions = indexBy(options, k => k.metadata.languageCode, v => v.base.identifier);

        actions.push({
          type: 'replace-options',
          questionIdentifier,
          options,
        });
      }
    } else {
      if (existingLanguageQuestion) {
        const item = state.survey.items.find(i => i.source.identifier === existingLanguageQuestion.base.identifier);
        actions.push({
          type: 'question-removed',
          questionIdentifier: existingLanguageQuestion.base.identifier,
          itemIdentifier: item.identifier,
        });

        actions.push({
          type: 'remove-section',
          identifier: existingLanguageQuestion.section.identifier,
        });
      }
    }

    syncClassifications();

    if (actions.length) {
      actions.push({
        type: 'draft-status-updated',
        status: 'queued',
      });

      dispatch({
        type: 'batch-actions',
        actions,
      });
    }

    function syncClassifications() {
      //Don't do anything if we aren't multilingual or the question identifier isn't set
      if (languages.length < 2 || !questionIdentifier) return;

      //We don't handle deleting classifications right now since there might be existing logic using those classifications and we don't want to destroy it
      const existingClassificationCodes = state.survey.classifications.map(c => c.meta?.languageCode).filter(Boolean);

      const languagesToAdd = languages.filter(l => !existingClassificationCodes.includes(l.code));

      for (const l of languagesToAdd) {
        const classificationIdentifier = cuid();
        actions.push({
          type: 'classification-added',
          payload: {
            value: {
              id: null,
              identifier: classificationIdentifier,
              name: `Language: ${l.name}`,
              meta: { languageCode: l.code },
            },
          },
        });

        const optionIdentifier = languageCodeOptions[l.code];

        actions.push({
          type: 'tagging-item-added',
          payload: {
            identifier: cuid(),
            action: {
              type: SurveyTaggingResultType.Classification,
              value: {
                identifier: classificationIdentifier,
              },
            },
            conditions: [
              {
                conditionType: SurveyConditionType.Question,
                identifier: cuid(),
                data: {
                  question: {
                    identifier: questionIdentifier,
                  },
                  type: SurveyQuestionConditionType.OptionSelected,
                  value: {
                    option: {
                      identifier: optionIdentifier,
                    },
                  },
                },
              },
            ],
          },
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.survey.language.languages]);
};