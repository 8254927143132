import qs from 'query-string';
import http from '@services/http';
import { safeJsonDate } from '@/utils';
import type { Calls } from './interfaces';

export * as bookings from './calls.bookings';

export const fetchDialIn = (data: Calls.FetchDialInRequest): Promise<Calls.FetchDialInResponse> => {
  return http.get(`/calls/${data.callId}/dial-in`);
};

export const savePostCallSurveyResponse = (data: Calls.SavePostCallSurveyResponseRequest): Promise<void> => {
  const { callId, ...body } = data;
  return http.post(`/calls/${callId}/post-call-survey-response`, body);
};

export const submitCallRating = (data: Calls.SubmitCallRatingRequest) => {
  const { callId, ...body } = data;
  return http.post(`/calls/${callId}/rating`, body);
};

export const getRecordingUrl = ({ callId, ...data }: Calls.GetRecordingUrl.Request): Promise<Calls.GetRecordingUrl.Response> => {
  return http.get(`/calls/${callId}/audio?${qs.stringify(data)}`);
};

export const getVideoUrl = ({ callId, ...data }: Calls.GetVideoUrl.Request): Promise<Calls.GetVideoUrl.Response> => {
  return http.get(`/calls/${callId}/video?${qs.stringify(data)}`);
};

export const getDownloadUrls = ({ callId }: Calls.GetDownloadUrls.Request): Promise<Calls.GetDownloadUrls.Response> => {
  return http.get(`/calls/${callId}/download-urls`);
};

export const getConferenceParticipants = (data: Calls.GetConferenceParticipants.Request): Promise<Calls.GetConferenceParticipants.Response> => {
  return http.get(`/calls/${data.callId}/conference-participants`);
};

export const getCallAccess = (data: Calls.GetCallAccess.Request): Promise<Calls.GetCallAccess.Response> => {
  return http.get(`/calls/${data.callId}/access`);
};

export const getCallDetails = (data: Calls.GetCallDetails.Request) => {
  return http.get<Calls.GetCallDetails.Response<string>>(`/calls/${data.callId}`)
    .then(res => {
      return {
        ...res,
        call: {
          ...res.call,
          timeEnd: safeJsonDate(res.call.timeEnd),
          timeStart: safeJsonDate(res.call.timeStart),
        },
      };
    });
};

export const updateCallDetails = (data: Calls.UpdateCallDetails.Request) => {
  const { callId, ...rest } = data;
  return http.patch<Calls.UpdateCallDetails.Response>(`/calls/${callId}`, rest);
};

export const downloadCallChatLog = (data: Calls.DownloadCallChatLog.Request) => {
  const { callId, format } = data;
  return http.get<Calls.DownloadCallChatLog.Response>(`/calls/${callId}/chat-log/report?format=${format}`);
};

export const downloadCallNotes = (data: ICallId) => {
  return http.get<Blob>(`/calls/${data.callId}/notes`, { responseType: 'blob' })
  .then(res => ({
    filename: `Call Notes.docx`,
    blob: res,
  }));
};

export const getCallTranscript = ({ callId }: Calls.GetCallTranscript.Request): Promise<Calls.GetCallTranscript.Response> => {
  return http.get(`/calls/${callId}/transcripts`);
};

export const downloadTagClip = ({ callId, ...data }: Calls.DownloadConferenceTagClip.Request) => {
  return http.post<Calls.DownloadConferenceTagClip.Response>(`/calls/${callId}/moment/generate-clip`, data);
};