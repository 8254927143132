import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as $api from '@/services/api';
import { Payout } from '@services/api/interfaces';

type Props = {
  userId: number;
  redirect: boolean;
  updating: boolean;
};

export default function useTipaltiOnboardingPayeeUrl({ userId, redirect, updating }: Props, options: UseQueryOptions<Payout.OnboardingPayeeUrl.Response> = {}) {
  return useQuery([`onboarding-payee-url`, userId], () => {
    return $api.payout.onboardingPayeeUrl({ userId, redirect, updating });
  }, {
    enabled: !!userId,
    refetchOnWindowFocus: false,
    ...options,
  });
}

export { useTipaltiOnboardingPayeeUrl };