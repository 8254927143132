import { services } from '$website/containers/Pricing/content';
import { buildFeatureGroup, buildTieredGroup, buildUsageGroup, createHeaderRow, createSpacerRow } from './pricing.table.utils';

export const items = [
  createHeaderRow({
    id: 'features',
    name: 'Features',
  }),
  ...buildTieredGroup(services.tiered),
  createSpacerRow(),
  ...buildFeatureGroup(services.support),
  createSpacerRow(),
  ...buildFeatureGroup(services.custom),
  createSpacerRow(),
  ...buildFeatureGroup(services.tools),
];