import { useContext } from 'react';
import { ProjectInviteReferralLinkContext } from './Context';
import { Clipboard } from './Link.Clipboard';
import { LinkedIn } from './Link.LinkedIn';
import { Twitter } from './Link.Twitter';
import styles from './style/Link.css';

type Props = {
  message?: string;
};

export const LinkShareButtonGroup = (props: Props) => {
  const { data } = useContext(ProjectInviteReferralLinkContext);

  return (
    <div className={styles.group}>
      <Clipboard url={data?.link?.url} />
      <LinkedIn url={data?.link?.url} />
      <Twitter
        message={props.message}
        url={data?.link?.url} />
    </div>
  );
};

LinkShareButtonGroup.displayName = 'LinkShareButtonGroup';