import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDimensions } from '@utils';
import { Breakpoint, ItemSize } from '../constants';

export const useCarousel = (count = 0) => {
  const dimensions = useDimensions();

  const [itemSize, setItemSize] = useState(computeTotalVisible(dimensions.width));

  const maxPosition = useMemo(() => {
    const max = count - itemSize;
    return max > -1 ? max : 0;
  }, [
    count,
    itemSize,
  ]);
  const [position, setPosition] = useState(0);

  const backEnabled = useMemo(() => {
    return position > 0;
  }, [position]);

  const nextEnabled = useMemo(() => {

    return position < count - itemSize && position > -1;

  }, [
    count,
    position,
    itemSize,
  ]);

  useEffect(() => {
    const t = computeTotalVisible(dimensions.width);

    setItemSize(t);

  }, [dimensions.width]);

  useEffect(() => {

    if (position > maxPosition) {
      setPosition(maxPosition);
    }

  }, [
    dimensions.width,
    position,
    maxPosition,
  ]);

  const back = useCallback(() => {
    if (backEnabled) {
      setPosition(position - 1);
    }
  }, [
    position,
    backEnabled,
  ]);

  const next = useCallback(() => {
    if (nextEnabled) {
      setPosition(position + 1);
    }
  }, [
    nextEnabled,
    position,
  ]);

  return {
    back,
    canGoBack: backEnabled,
    canGoNext: nextEnabled,
    itemSize,
    next,
    position,
  };

};

function computeTotalVisible(width: number) {
  return width >= Breakpoint.Tablet && width < Breakpoint.Desktop
    ? ItemSize.Tablet
    : width >= Breakpoint.Desktop
      ? ItemSize.Desktop
      : ItemSize.Phone;
}