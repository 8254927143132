import { useMemo } from 'react';
import { useEditorState } from '@remirror/react';

export const useIsEditorEmpty = () => {

  const editor = useEditorState();

  return useMemo(() => {
    return !editor.doc.textContent.length;
  }, [editor.doc.textContent]);
};