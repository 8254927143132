import { useEffect, useCallback } from 'react';
import { useParams } from 'react-router';
import { LeftBarWindowContext, useLeftBarWindowContext, LeftBarActionContext } from './context';
import type { LeftBarWindowMessages } from './interfaces';
import { LeftBar } from './LeftBar';
import { LeftBarContainer } from './LeftBarContainer';
import styles from './style/Popout.css';

type RouteParams = Stringify<ISurveyId & ICallId>;

export const PopoutLeftBar = () => {
  if (!window.opener || !((window.opener as Window)?.postMessage)) {
    return <div>Could not find the parent window.</div>;
  }

  return (
    <LeftBarWindowContext.Provider value={{ window: window.opener as Window, thisRole: 'child', setWindow: null }}>
      <PopoutContent />
    </LeftBarWindowContext.Provider>
  );
};

const PopoutContent = () => {
  const params = useParams<RouteParams>();
  const { window: parentWindow } = useLeftBarWindowContext();

  useEffect(() => {
    if (parentWindow) {
      const message: LeftBarWindowMessages.RequestState = {
        type: 'request-state',
      };
      parentWindow.postMessage(message, '*');
    }
  }, [parentWindow]);

  const onItemClick = useCallback((itemId: number) => {
    const message: LeftBarWindowMessages.ClickItem = {
      type: 'click-item',
      itemId,
    };

    parentWindow.postMessage(message, '*');
  }, [parentWindow]);

  return (
    <div className={styles.root}>
      <LeftBarContainer callId={+params.callId}>
        <LeftBarActionContext.Provider value={{ onItemClick }}>
          <LeftBar />
        </LeftBarActionContext.Provider>
      </LeftBarContainer>
    </div>
  );
};