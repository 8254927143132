import { memo, useContext } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { WorkspaceFilePreviewLoadingContext, WorkspaceFileStateContext } from '@/containers/Workspace.File.Preview/Context';
import { DiscoverInsights } from '@/components/GroupProject/DiscoverInsights';
import { useIsInternalUser } from '@/containers/Store/hooks';
import { FilePreviewContextMenu } from './ContextMenu';
import { Access } from './Header.Access';
import styles from './style/Header.Actions.css';

type Props = unknown;

export const Actions = (props: Props) => {
  const isLoading = useContext(WorkspaceFilePreviewLoadingContext);

  return isLoading
    ? <SkeletonActions />
    : <InteractiveActions />;
};

Actions.displayName = 'File.Actions';

const InteractiveActions = (props: Props) => {

  const isInternalUser = useIsInternalUser();
  const state = useContext(WorkspaceFileStateContext);

  return (
    <div className={styles.root}>
      {(state.features.insightsChat && !isInternalUser) &&
        <DiscoverInsights className={styles.discover} />}
      <Access />
      <FilePreviewContextMenu />
    </div>
  );
};

InteractiveActions.displayName = 'File.Actions.Interactive';

const SkeletonActions = memo((props: Props) => {
  return (
    <div className={styles.actions}>
      <Skeleton
        height={30}
        variant="rounded"
        width={70} />
      <Skeleton
        height={30}
        variant="rounded"
        width={70} />
      <Skeleton
        height={30}
        variant="rounded"
        width={70} />
    </div>
  );
});

SkeletonActions.displayName = 'File.Actions.Skeleton';