import { useCallback } from 'react';
import { useBadgeNotificationStats } from '@containers/Notifications/Context';
import type { MenuItem, MenuItemConfig } from './interfaces';
import { iconMap } from './utils';

export const useBuildMenuItem = () => {
  const stats = useBadgeNotificationStats();

  return useCallback((item: MenuItemConfig, ...predicates: boolean[]) => {
    if (!predicates.every(Boolean)) return;

    return {
      ...item,
      count: stats[item.name],
      icon: iconMap[item.name],
      key: item.name,
    } as MenuItem;
  }, [
    stats,
  ]);
};