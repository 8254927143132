import { useState } from 'react';
import { LiveRoomScreenContainer } from '@containers/Conference';
import { cx } from '@utils';
import type { Tagging as T } from '@/components/Conference.Tagging/interfaces';
import { TaggingContainer } from '@/components/Conference.Tagging';
import { PanelToolsHeader } from './Pane.Header';
import { Popup } from './Pane.Tagging';
import type { InstanceState } from './interfaces';
import styles from './style/Panel.Tools.css';

type Props = unknown;

type WindowMessage = {
  type: 'tagging';
  instance: InstanceState;
  tagging: {
    items: T.Tag[];
  };
} & ICallId;

export const Tagging = (props: Props) => {
  const [instance, setInstance] = useState<InstanceState>(null);
  const [items, setItems] = useState<T.Tag[]>();

  window.addEventListener('message', (e: MessageEvent<WindowMessage>) => {
    if (e.origin !== window.location.origin) return;

    if (e.data.type === 'tagging') {
      setInstance(e.data?.instance);
      if (e.data?.tagging?.items) {
        setItems(e.data.tagging.items);
      }
    }
  }, false);

  if (!instance || !items) {
    return (
      <div>One moment...</div>
    );
  }

  return (
    <LiveRoomScreenContainer>
      <TaggingContainer
        enabled={true}
        initial={items}
        instance={instance}
        popup>
        <div className={cx(styles.root, styles.popup)}>
          <PanelToolsHeader title="Insert Tag" />
          <Popup started={instance.conference.started} />
        </div>
      </TaggingContainer>
    </LiveRoomScreenContainer>
  );
};

Tagging.displayName = 'Conference.Panel.Popup.Tagging';