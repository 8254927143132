import { OpenedResponseOptionRow } from '@presentation/ProjectSurveyResponses';
import { formatPlurality } from '@utils';
import styles from './style/Matrix.Data.css';
import ResponseOptionUser from './ResponseOptionUser';
import { MatrixOptionItem } from './interfaces';

type Props = {
  item: MatrixOptionItem;
  onReset: () => void;
  group: {
    value: string;
  };
};

function formatOptionSubtitle(count: number) {
  return `(${count} ${formatPlurality('Respondent', count)})`;
}

export const MatrixGroupItemData = ({ item, onReset, group }: Props) => {

  const count = item.userIds.length;
  const subtitle = formatOptionSubtitle(count);

  return (
    <>
      <div className={styles.row}>{group.value}</div>
      <OpenedResponseOptionRow
        color={item.color}
        label={item.name}
        subtitle={subtitle}
        onClose={onReset} />
      {item.userIds.map(userId => (
        <ResponseOptionUser
          key={userId}
          className={styles.user}
          userId={userId} />
      ))}
    </>
  );
};

export default MatrixGroupItemData;