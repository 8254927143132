import { path } from '@consts';
import { RouteAuth } from '@enums';
import { BasicRouter } from '@routes/Routers';
import * as Routing from '@routes/interfaces';
import { SelfServeSurvey } from '@screens/SelfServeSurvey';

export const routes: Routing.Route[] = [{
  component: SelfServeSurvey,
  path: path.SelfServeSurvey.Root,
}];

export const router = BasicRouter(routes, {
  auth: RouteAuth.Ignore,
  path: path.SelfServeSurvey.Root,
});