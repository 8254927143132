import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import * as actions from '@actions';
import * as $api from '@/services/api';

type UseCompleteOnboardingPayeeParams = {
  userId: number;
};

export default function useCompleteOnboardingPayee() {
  const dispatch = useDispatch();

  return useMutation(({ userId }: UseCompleteOnboardingPayeeParams) => {
    return $api.payout.completeOnboardingPayee({ userId });
  }, {
    onSuccess: result => {
      dispatch(actions.userInfoChange({
        payout: result.payout,
      }));
    },
  });
}

export { useCompleteOnboardingPayee };