import { useContext, useMemo } from 'react';
import { SurveyFormShowCompleteContext, useSurveyFormMetadata, useSurveyFormScreenContext } from '../Context';

export const useSurveyFormIsFirstItem = () => {
  const [state] = useSurveyFormScreenContext();

  return useMemo(() => {
    return state.item.ordinal === 1;
  }, [state.item.ordinal]);
};

export const useSurveyFormIsLastItem = () => {
  const metadata = useSurveyFormMetadata();
  const [state] = useSurveyFormScreenContext();

  const isLastItem = useMemo(() => {
    return state.item.ordinal === metadata.itemsCount;
  }, [state.item.ordinal, metadata.itemsCount]);

  return isLastItem;
};

export const useSurveyFormShowComplete = () => {
  const ctx = useContext(SurveyFormShowCompleteContext);
  const isLastItem = useSurveyFormIsLastItem();

  return useMemo(() => {
    return ctx !== null ? ctx : isLastItem;
  }, [ctx, isLastItem]);
};