import { memo } from 'react';

type Props = {
  className?: string;
  size: number;
};

export const DownloadFile = memo(({ className, size }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      viewBox="2 3 20 20"
      fill="currentColor"
      className={className}>
      <path d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M17,11l-1.41-1.41L13,12.17V4h-2v8.17L8.41,9.59L7,11l5,5 L17,11z" />
    </svg>
  );
});