import { useSelector } from 'react-redux';
import { hasClientRole } from '@utils';
import Toast from '@/components/Toast';

const selector = (state: Store.State) => {

  return {
    cancellation: {
      error: () => {
        return Toast.error({
          title: 'Error',
          body: `We're sorry. There was an issue attempting to cancel this call. Please try again.`,
        });
      },
      success: () => {
        return Toast.success({
          title: 'Call Cancelled',
          body: `The ${hasClientRole(state.user) ? 'expert' : 'client'} has been sent a notification that the scheduled call has been cancelled.`,
        });
      },
    },
  };
};

export const useProjectCallSchedulingNotifier = () => {
  return useSelector(selector);
};