import { useState, useRef, useCallback, useMemo } from 'react';
import { Check, X, Edit2 } from 'react-feather';
import { cx } from '@utils';
import { Input } from '@/components/Input';
import styles from './styles.css';

type Props = {
  className?: string;
  value: string;
  onSave: (newVal: string) => void;
  isValid?: (newVal: string) => boolean;
  displayClass?: string;
  isEditable?: boolean;
};

export const EditableText = ({ isEditable = true, ...props }: Props) => {
  const [isEditing, setEditing] = useState(false);

  if (isEditing) {
    return <EditingComponent {...props} endEditing={() => setEditing(false)} />;
  } else {
    return (
      <span
        className={isEditable ? styles.editTrigger : null}
        onClick={isEditable ? () => setEditing(true) : null}>
        {props.value}
        <Edit2 className={styles.editIcon} size={12} />
      </span>
    );
  }
};

type EditingProps = Props & { endEditing: () => void };

const EditingComponent = ({ isValid, value, endEditing, onSave, ...props }: EditingProps) => {
  const inputRef = useRef<HTMLInputElement>();
  const [canSave, setCanSave] = useState(false);

  const recalcSave = useCallback(() => {
    const canSave = () => {
      if (!inputRef.current) return false;
      return inputRef.current.value != value
        && (!isValid || isValid(inputRef.current.value));
    };

    setCanSave(canSave());
  }, [isValid, value]);

  const saveValue = useCallback(() => {
    onSave(inputRef.current.value);
    endEditing();
  }, [onSave, endEditing]);

  return (
    <div className={cx(styles.editingContainer, props.className)}>
      <Input
        classes={{
          input: styles.inputElement,
        }}
        ref={inputRef}
        defaultValue={value}
        onChange={recalcSave} />
      <Check className={canSave ? styles.enabled : styles.disabled} onClick={canSave ? saveValue : null} />
      <X className={styles.cancel} onClick={endEditing} />
    </div>
  );
};