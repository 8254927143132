import { createRef, useCallback, useEffect, useState } from 'react';
import { Link, matchPath } from 'react-router-dom';
import * as API from '@api/interfaces';
import * as consts from '@consts';
import { Chip } from '@presentation/Chip';
import { cx } from '@utils';
import styles from './style/TopicNavigation.css';

type Props = {
  items: API.Discovery.FetchVideoTopics.Response['items'];
};

export const TopicNavigation = (props: Props) => {
  const ref = createRef<HTMLDivElement>();
  const [visible, setVisible] = useState(false);

  const handleScroll = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    const val = e.currentTarget?.scrollLeft;
    if (visible && val === 0) {
      setVisible(false);
    } else if (!visible && val > 0) {
      setVisible(true);
    }
  }, [
    visible,
    setVisible,
  ]);

  useEffect(() => {
    const scroll = ref.current;

    scroll.addEventListener('scroll', handleScroll as unknown as Parameters<typeof scroll.addEventListener>[1]);

    return () => {
      scroll.removeEventListener('scroll', handleScroll as unknown as Parameters<typeof scroll.removeEventListener>[1]);
    };
  }, [
    handleScroll,
    ref,
  ]);

  const classes = cx(styles.fade, {
    [styles.visible]: visible,
  });

  return (
    <div className={styles.root}>
      <div className={classes} />
      <div className={styles.fade} />
      <div
        className={styles.wrap}
        ref={ref}>
        {props.items.map(x => {

          const pathname = `${consts.path.Discovery.Topics.Root}/${x.slug}`;
          const active = matchPath(pathname, {
            path: window.location.pathname,
            exact: true,
          });
          const location = active
              ? consts.path.Discovery.Root
              : pathname;
          return (
            <Chip
              className={cx(styles.chip, { [styles.active]: !!active })}
              color={active ? 'selected' : 'basic'}
              key={x.id}>
              <Link to={location}>
                <div className={styles.main}>
                  {x.name}
                </div>
              </Link>
            </Chip>
          );
        })}
      </div>
    </div>
  );
};