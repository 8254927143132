import { useEffect, useMemo } from 'react';
import {
  useRegisterChatPageContext,
  type BrandInsightsPageContext,
  ChatContextType,
} from '@/brand-insights';

export const useRegisterGlobalChatContext = () => {
  const globalContext: BrandInsightsPageContext.Global = useMemo(() => ({
    type: ChatContextType.Global,
  }), []);

  const register = useRegisterChatPageContext();
  useEffect(() => {
    register(globalContext);
  }, [register, globalContext]);
};