import * as api from '@api';
import { useLogout } from '@containers/AppStateEffect/hooks';
import { ButtonOutlined } from '@presentation';
import { useAsyncStateLazy } from '@utils';
import { Button } from '@/components/Button';
import { useModal } from '@/components/Modal/hooks';
import { Spinner } from '@/components/ActivityIndicator';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import M from '@/components/Modal/Modal.Legacy';
import styles from './style/AccountDeletion.css';

type Props =
  Pick<ModalProps, 'open' | 'onClose'>;

export function ConfirmAccountDeletionModal(props: Props) {
  const logout = useLogout();

  const [response, submit] = useAsyncStateLazy(() => {
    return api.users.deleteAccount()
    .then(() => logout({ sideEffects: true }));
  });

  function handleClose() {
    !response.loading && props.onClose();
  }

  return (
    <Modal
      onClose={handleClose}
      open={props.open}>
      <M.Header className={styles.header}>
        {text.title}
      </M.Header>
      <M.Body className={styles.body}>
        <div className={styles.msg}>{text.msg1}</div>
        <div className={styles.msg}>{text.msg2}</div>
      </M.Body>
      <M.ButtonSet className={styles.footer}>
        <ButtonOutlined
          disabled={response.loading}
          borderWidth="medium"
          className={styles.action}
          color="silver"
          onClick={props.onClose}>
          Cancel
        </ButtonOutlined>
        <Button
          color="primary"
          className={styles.action}
          disabled={response.loading}
          onClick={submit}
          variant="brick">
          {response.loading
            ? <Spinner color="var(--pri-01)" />
            : 'Delete Account'}
        </Button>
      </M.ButtonSet>
    </Modal>
  );
}

export const useAccountDeletionModal = () => useModal(ConfirmAccountDeletionModal);

const text = {
  title: `Are you sure you want to delete your account?`,
  msg1: `We're sorry to lose you, and hope you will rejoin in the future.`,
  msg2: `Deleting your account will immediately log you out of Sentiment and you will lose your profile and activity history.`,
};
