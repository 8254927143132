import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as $api from '$admin/api/leads';
import { LeadsResultsContext, QueryParamsContext } from './Context';

type Props = {
  children: React.ReactNode;
  source?: string;
};

export const QueryContainer = (props: Props) => {
  const query = useFetchContactsQuery({ source: props.source });

  return (
    <LeadsResultsContext.Provider value={query}>
      {props.children}
    </LeadsResultsContext.Provider>
  );
};

QueryContainer.displayName = 'Group.Contacts.Query.Container';

type Params = {
  source?: string;
};

const useFetchContactsQuery = (params: Params) => {
  const [queryparams, _] = useContext(QueryParamsContext);

  const query = useQuery([
    'fetch-admin-leads',
    queryparams.index,
    queryparams.name,
    queryparams.size,
    queryparams.title,
    queryparams.statusId,
    params.source,
  ], () => {
    return $api.fetchLeads({
      pageIndex: queryparams.index ?? 0,
      pageSize: queryparams.size ?? 25,
      name: queryparams.name,
      title: queryparams.title,
      statusId: queryparams.statusId ? queryparams.statusId : undefined,
      source: params.source,
    });
  }, {
    enabled: true,
    keepPreviousData: true,
  });

  return query;
};