import { cx } from '@utils';
import { Button }  from '@/components/Button';
import type { StaticButtonProps, ButtonProps } from '@/components/Button/interfaces';
import styles from './style/Button.css';

export type OutlinedButtonProps =
  Omit<StaticButtonProps, 'variant'>;

export const RedOutlineButton = (props: OutlinedButtonProps) => {
  const { className, ...otherProps } = props;
  return (
    <Button.Secondary
      className={cx(styles.red, props.className)}
      variant='brick'
      {...otherProps} />
  );
};

export const GreenOutlineButton = (props: OutlinedButtonProps) => {
  const { className, ...otherProps } = props;
  return (
    <Button.Secondary
      className={cx(styles.green, props.className)}
      variant='brick'
      {...otherProps} />
  );
};

export const GrayOutlineButton = (props: OutlinedButtonProps) => {
  const { className, ...otherProps } = props;
  return (
    <Button.Secondary
      className={cx(styles.gray, props.className)}
      variant='brick'
      {...otherProps} />
  );
};

export const BlueOutlineButton = (props: OutlinedButtonProps) => {
  const { className, ...otherProps } = props;
  return (
    <Button.Secondary
      className={cx(styles.blue, props.className)}
      variant='brick'
      {...otherProps} />
  );
};

type LinkProps =
  Omit<ButtonProps, 'variant'>;

export const ButtonLinkVariant = (props: LinkProps) => {
  const { className, ...otherProps } = props;
  return (
    <Button
      className={cx(styles.link, props.className)}
      {...otherProps} />
  );
};