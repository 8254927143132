import { useCallback, useContext, useMemo } from 'react';
import type { DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd';
import Color from 'color';
import { cx, useToggle } from '@utils';
import type { ProjectConferenceTagWithMetadata } from '@/types';
import { ConferenceTagType } from '@/enums';
import { StarCell } from '@/components/Project.Tags/Column.Color';
import { Tags } from '@/components/Conference.Tagging/interfaces';
import * as Column from '@/components/Project.Tags/Column';
import * as Table from '@/components/Table';
import { useModal } from '@/components/Modal/hooks';
import { TagStateContext } from '@/components/Project.Tags/Context';
import { useReorderTagsMutation, useInlineUpdateTagMutation } from '@/components/Project.Tags/hooks';
import type { TagItem } from '@/components/Project.Tags/interfaces';
import { MenuItems } from './TagContextMenu';
import * as Modal from './Modal';
import { useDeleteTagModal } from './Modal.DeleteTag';
import { TagsTableEmpty } from './Tags.Table.Empty';
import styles from './style/ProjectTagsTab.css';
import { isTagEditable } from './utils';

const reorder = <T, >(list: T[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

type Props = {
  loading?: boolean;
  loadingStateRowCount?: number;
};

export const TagsTable = (props: Props) => {
  const { state, projectId } = useContext(TagStateContext);

  const { mutate: reorderTags } = useReorderTagsMutation({ projectId });

  const onDragEnd = useCallback((result: DropResult, provided: ResponderProvided) => {
    if (!result.destination) return;

    const reorderedParents = reorder(state.tags, result.source.index, result.destination.index);

    //rebalance ordinals
    let ordinal = 1;
    const ordinalMap: Record<number, number> = {};

    for (const t of reorderedParents) {
      ordinalMap[t.id] = ordinal++;
      for (const c of t.children) {
        ordinalMap[c.id] = ordinal++;
      }
    }

    reorderTags({
      ordinalMap,
    });

  }, [state.tags, reorderTags]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='root-project-tag-drop'>
        {(provided, snapshot) =>
          <Table.Root
            {...provided.droppableProps}
            EmptyComponent={TagsTableEmpty}
            empty={!props.loading && !state.tags.length}
            loading={props.loading}
            ref={provided.innerRef}
            maxScrollHeight={59}>

            <Table.Header.THead className={styles.thead}>
              <Table.Header.TR className={styles.tr}>
                <Column.DragHandle.Header />
                <Column.Ordinal.Header />
                <Column.Color.Header />
                <Column.Title.Header />
                <Column.Summary.Header />
                <Column.Actions.Header />
                <Column.AccordionToggle.Header />
              </Table.Header.TR>
            </Table.Header.THead>

            {props.loading &&
              <LoadingState rows={props.loadingStateRowCount} />}

            {!props.loading &&
              state.tags.map((t, i) =>
                <ParentTagRow
                  key={t.id}
                  listIndex={i}
                  isLast={state.tags.length - 1 === i}
                  tag={t} />)}
            <Table.Body.TBody className={styles.tbody}>
              {provided.placeholder}
            </Table.Body.TBody>
          </Table.Root>}
      </Droppable>
    </DragDropContext>
  );
};

type ParentRowProps = {
  tag: TagItem;
  listIndex: number;
  isLast: boolean;
};

const ParentTagRow = ({ isLast, tag, listIndex }: ParentRowProps) => {
  const { projectId } = useContext(TagStateContext);
  const [childrenOpen, toggleChildrenOpen] = useToggle(true);

  const { mutate: reorderTags } = useReorderTagsMutation({ projectId });

  const onDragEnd = useCallback((result: DropResult, provided: ResponderProvided) => {
    if (!result.destination) return;

    const reorderedChildren = reorder(tag.children, result.source.index, result.destination.index);

    //rebalance ordinals
    let ordinal = tag.ordinal + 1;
    const ordinalMap: Record<number, number> = {};

    for (const t of reorderedChildren) {
      ordinalMap[t.id] = ordinal++;
    }

    reorderTags({
      ordinalMap,
    });
  }, [tag, reorderTags]);
  return (
    <>
      <Draggable
        key={tag.id}
        draggableId={tag.id.toString()}
        index={listIndex}>
        {(provided, snapshot) =>
          <>
            <Table.Body.TBody className={styles.tbody}>
              <Table.Body.TR
                ref={provided.innerRef}
                {...provided.draggableProps}
                className={cx(styles.parent, styles.tr, {
                  [styles.hidden]: tag.hidden,
                  [styles.last]: isLast && tag.children.length === 0,
                })}>
                <Column.DragHandle.Cell {...provided.dragHandleProps} />
                <TagCells tag={tag} hasChildren={tag.children.length > 0} />
                <Column.AccordionToggle.Cell
                  hidden={!tag.children.length}
                  open={childrenOpen}
                  onClick={toggleChildrenOpen} />
              </Table.Body.TR>
            </Table.Body.TBody>

            {childrenOpen ?
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={`children-project-tag-drop-${tag.id}`}>
                  {(provided, snapshot) => (
                    <Table.Body.TBody
                      className={styles.tbody}
                      {...provided.droppableProps}
                      ref={provided.innerRef}>
                      {tag.children.map((c, i) =>
                        <ChildTagRow
                          key={c.id}
                          listIndex={i}
                          orderable={tag.children.length > 1}
                          isLast={isLast && tag.children.length - 1 === i}
                          tag={c} />)}
                      {provided.placeholder}
                    </Table.Body.TBody>)}
                </Droppable>
              </DragDropContext>
              : null}
          </>}
      </Draggable>
    </>
  );
};

type ChildRowProps = {
  tag: ProjectConferenceTagWithMetadata;
  listIndex: number;
  orderable: boolean;
  isLast: boolean;
};
const ChildTagRow = ({ tag, listIndex, orderable, ...props }: ChildRowProps) => {
  return (
    <Draggable
      key={tag.id}
      draggableId={tag.id.toString()}
      isDragDisabled={!orderable}
      index={listIndex}>
      {(provided, snapshot) =>
        <Table.Body.TR
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={cx(styles.tr, styles.child, {
            [styles.hidden]: tag.hidden,
            [styles.last]: props.isLast,
          })}>
          <Column.DragHandle.Cell
            depth={2}
            hidden={!orderable}
            {...provided.dragHandleProps} />
          <TagCells
            hasChildren={false}
            tag={tag} />
          <Column.AccordionToggle.Cell hidden />
        </Table.Body.TR>}
    </Draggable>
  );
};

const TagCells = ({ tag, hasChildren }: { tag: ProjectConferenceTagWithMetadata; hasChildren: boolean }) => {
  const { projectId } = useContext(TagStateContext);
  const {
    mutate: updateTag,
  } = useInlineUpdateTagMutation({
    projectId,
    tag,
  });

  const canEdit = isTagEditable(tag);

  const [openEditModal, EditModal] = useModal(Modal.EditTag, { keepMounted: false });
  const [openCreateModal, CreateModal] = useModal(Modal.CreateTag, { keepMounted: false });
  const [toggleDeleteAlert, DeleteModal] = useDeleteTagModal({
    projectId,
    tagId: tag.id,
  });

  const defaultColor = useMemo(() => new Color(tag.base.color).lighten(Tags.LIGHTEN_FACTOR).hex(), [tag.base.color]);

  const canDelete = useMemo(() => {
    return !hasChildren;
  }, [hasChildren]);

  const renderColorCell = useCallback(() => {

    if (tag.base.typeId === ConferenceTagType.Global && tag.base.name === 'Good Quote') {
      return (
        <StarCell />
      );
    }

    return (
      <Column.Color.Cell
        editable={canEdit}
        color={tag.base.color}
        onChange={color => updateTag({ color })} />
    );
  }, [
    canEdit,
    tag,
    updateTag,
  ]);

  return (
    <>
      <Column.Ordinal.Cell value={tag.ordinal} />
      {renderColorCell()}
      <Column.Title.Cell
        editable={canEdit}
        isValid={title => title.length > 3}
        onSubmit={title => updateTag({ title })}
        value={tag.base.name} />
      <Column.Summary.Cell
        checked={tag.includeSummary}
        onChange={e => updateTag({ includeSummary: e.target.checked })} />
      <Column.Actions.Cell>
        <MenuItems
          tag={tag}
          onCreateTag={openCreateModal}
          onEditTag={openEditModal}
          onDeleteTag={canDelete ? toggleDeleteAlert : null} />
      </Column.Actions.Cell>
      <EditModal tag={tag} hasChildren={hasChildren} />
      <CreateModal parentTagId={tag.id} defaultColor={defaultColor} />
      <DeleteModal />
    </>
  );
};

type LoadingStateProps = {
  rows: number;
};

const LoadingState = ({ rows = 25 }: LoadingStateProps) => {
  const Data = Array.from({ length: rows }, (_, i) => ({ id: i, base: {}, children: [] } as TagItem));

  return (
    <>
      {Data.map(x =>
        <Table.Body.TBody
          className={styles.tbody}
          key={x.id}>
          <Table.Body.TR className={cx(styles.parent, styles.tr)}>
            <Column.DragHandle.Cell />
            <TagCells tag={x} hasChildren={x.children.length > 0} />
            <Column.AccordionToggle.Cell
              hidden
              open={false} />
          </Table.Body.TR>
        </Table.Body.TBody>)}
    </>
  );
};