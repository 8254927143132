import { useCallback, useMemo, Fragment } from 'react';
import type { SurveyQuestionType } from '@enums';
import { useSelectRespondentProps, useSelectStarredProjectSurveyAnswer, useInitialStarredResponses, useProjectSurveyResponsesState } from '@containers/SurveyResponses/hooks';
import type { ResponseTextItemProps } from '@presentation/ProjectSurveyResponses';
import { CustomResponseItem, ResponseTextItem } from '@presentation/ProjectSurveyResponses';
import type { SurveyAggregate, SurveyQuestion, ImageMarkupQuestion, HybridSurveyResponseUserId } from '@/types';
import { ControlledTablePagination } from '@presentation/TablePagination';
import { TabBarTitle } from '@/components/TabView/TabBarTitle';
import type { ControlledTabProps } from '@/components/TabView';
import { ControlledTabBar } from '@/components/TabView';
import { ResponseTextList } from './ResponseText';
import { Heatmap } from './ImageMarkup.Heatmap';
import styles from './style/ImageMarkup.css';

type Props = {
  question: ImageMarkupQuestion.Question;
  aggregateData: ImageMarkupQuestion.Aggregate.Data;
};

type ResponseItem = {
  rationale?: string;
  value: string;
  userId: HybridSurveyResponseUserId;
};

type HeatmapType = 'good' | 'bad';

export const useSortStarrableResponses = (questionId: number) => {
  const starred = useInitialStarredResponses(questionId);

  const selectRespondentProps = useSelectRespondentProps();

  const sort = useCallback((items: ResponseItem[]) => {
    return items
      .sort((a, b) => {
        const responseIdA = selectRespondentProps(a.userId).responseId;
        const respondeIdB = selectRespondentProps(b.userId).responseId;
        const aStarred = starred.includes(responseIdA);
        const bStarred = starred.includes(respondeIdB);
        return (aStarred === bStarred) ? 0 : aStarred ? -1 : 1;
      });
  }, [
    selectRespondentProps,
    starred,
  ]);

  return sort;
};

const pageSize = 50;

export const ImageMarkupResponses = (props: Props) => {

  const { query, setQueryState } = useProjectSurveyResponsesState<SurveyQuestionType.ImageMarkup>();
  const page = useMemo(() => query.page || 1, [query.page]);

  const sortValues = useSortStarrableResponses(props.question.base.id);

  const items: ResponseItem[] = useMemo(() => {
    return Object.keys(props.aggregateData.userData).map(userId => {
      const data = props.aggregateData.userData[userId];
      return {
        userId,
        value: data.markedUpImage,
      };
    });
  }, [
    props.aggregateData.userData,
  ]);

  const sortedItems = useMemo(() => {
    return sortValues(items);
  }, [
    items,
    sortValues,
  ]);

  const paginatedItems = useMemo(() => {
    return sortedItems
      .slice((page - 1) * pageSize, page * pageSize);
  }, [
    page,
    sortedItems,
  ]);

  const renderImage = useCallback((data: ResponseTextItemProps) => {
    return <></>;
    //return <img className={styles.individualImage} src={data.value} />;
  }, []);

  const renderItem = useCallback((data: ResponseTextItemProps) => {
    return (
      <CustomResponseItem
        {...data}
        renderItem={() => renderImage(data)} />
    );
  }, [renderImage]);

  const handlePageChange = useCallback((pageIndex: number) => {
    setQueryState({ page: pageIndex + 1 });
  }, [
    setQueryState,
  ]);

  const pageCount = useMemo(() => {
    return Math.ceil(items.length / pageSize);
  }, [items.length]);

  const heatmapData = useMemo(() => {
    return Object.entries(props.aggregateData.userData).flatMap(([userId, data]) => {
      const structures = query.heatmapType ? data.structures.filter(s => s.type === query.heatmapType) : data.structures;
      return structures.map(structure => {
        return {
          ...structure,
          userId,
        };
      });
    });
  }, [props.aggregateData, query.heatmapType]);

  const tabProps = useMemo<ControlledTabProps<string>[]>(() => [
    {
      title: 'All',
      onClick: () => setQueryState({ heatmapType: undefined }),
      key: 'all',
    }, {
      title: 'Positive',
      onClick: () => setQueryState({ heatmapType: 'good' }),
      key: 'good',
    }, {
      title: 'Negative',
      onClick: () => setQueryState({ heatmapType: 'bad' }),
      key: 'bad',
    },
  ], [setQueryState]);

  return (
    <div className={styles.root}>
      <div>
        <ControlledTabBar tabs={tabProps} activeKey={query.heatmapType ?? 'all'} />
      </div>
      <div className={styles.heatmapContainer}>
        {typeof props.question.settings.initialImage === 'string' ?
          <Heatmap imageUrl={props.question.settings.initialImage} structures={heatmapData} />
          : <div>Heatmap not available when using piped images</div>}
      </div>
      <ResponseTextList
        rationaleMap={{}}
        items={paginatedItems}
        question={props.question}
        renderItem={renderItem} />
      {items.length > pageSize &&
        <div className={styles.footer}>
          <ControlledTablePagination
            goToPage={handlePageChange}
            pageIndex={page - 1}
            pageCount={pageCount}
            pageSize={pageSize}
            totalCount={items.length} />
        </div>
      }
    </div>
  );
};