import { useSurveyBuilderCapabilities, useSurveyBuilderItemEditing } from '@containers/SurveyBuilder/Context';
import { FormButtons } from '@presentation/FormButtons';
import {
  useCancelAlternateImagesEditing,
  useSaveAlternateImagesContext,
} from '@/containers/SurveyBuilder.AIE/context';
import { AlternateImagesBuilderContainer, AEIBuilderEditorContainer } from '@/containers/SurveyBuilder.AIE';
import { AIENumber } from '@presentation/Survey';
import { Editor } from './Editor';
import { Preview } from './Preview';
import { SurveyMessageContextMenu } from './ContextMenu';
import styles from './style/AIEBuilder.css';

export const AIEBuilder = () => {

  const { editing } = useSurveyBuilderItemEditing();

  return (
    <div className={styles.root}>
      {editing && <Editing />}
      {!editing && <NotEditing />}
    </div>
  );

};

const NotEditing = () => {
  const { canEditItems } = useSurveyBuilderCapabilities();

  return (
    <AEIBuilderEditorContainer>

      <div className={styles.text}>
        <AIENumber className={styles.number} />
        <Preview />
      </div>

      {canEditItems && <SurveyMessageContextMenu className={styles.contextMenu} />}

    </AEIBuilderEditorContainer>
  );
};

const Editing = () => {
  return (
    <AEIBuilderEditorContainer>
      <AlternateImagesBuilderContainer>
        <Editor />
        <AIEBuilderFormButtons />
      </AlternateImagesBuilderContainer>
    </AEIBuilderEditorContainer>
  );
};

const AIEBuilderFormButtons = () => {

  const handleCancel = useCancelAlternateImagesEditing();
  const { canSave, onSave } = useSaveAlternateImagesContext();

  return (
    <div className={styles.buttons}>
      <FormButtons
        archivable={false}
        disabled={!canSave.val}
        disabledTooltip={canSave.message}
        deletable={false}
        onSubmit={onSave}
        onCancel={handleCancel} />
    </div>
  );
};

export default AIEBuilder;