import { memo, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { } from '@/brand-insights/components/Chat/Chat.Guard';
import { Constants } from '@/brand-insights/components/Theme';
import { buildMessageItems, buildMessageQueries } from '@/brand-insights/utils/queries';
import { LinkContext } from '@/brand-insights/components/BrandInsights/context';
import { toTitleCase } from '@/utils';
import {
  ChatSessionSelectedQueryContext,
  ChatSessionFileUploadsContext,
  ChatSessionQueriesContext,
  MessagesListRefContext,
  ChatSessionSegmentationsContext,
  ChatHasAccessContext,
  ActiveChatSessionContext,
} from './context';
import { UnsubmittedMessage } from './Input';
import { MessageList } from './MessageList';
import { useChatPermissions } from './hooks';

export const ChatInstance = () => {
  const selected = useContext(ChatSessionSelectedQueryContext);
  const queriesMap = useContext(ChatSessionQueriesContext);
  const fileUploads = useContext(ChatSessionFileUploadsContext);
  const segmentations = useContext(ChatSessionSegmentationsContext);
  const hasAccess = useContext(ChatHasAccessContext);
  const link = useContext(LinkContext);
  const chatPermissions = useChatPermissions();

  const [_, setMessagesRef] = useContext(MessagesListRefContext);

  const queries = useMemo(() => buildMessageQueries({
    selectedQueryIdentifier: selected?.identifier,
    queries: queriesMap,
  }), [selected?.identifier, queriesMap]);

  const items = useMemo(() => buildMessageItems({
    fileUploads,
    queries,
    segmentations,
  }), [fileUploads, queries, segmentations]);

  return (
    <Root>
      {!hasAccess && <NoAccessBanner />}
      <Messages>
        <MessagesWrap ref={setMessagesRef}>
          {link.initialized && (
            <MessageList
              items={items} />
          )}
        </MessagesWrap>
      </Messages>

      <Footer>
        {chatPermissions.canModify && <UnsubmittedMessage />}
      </Footer>
    </Root>
  );
};

const NoAccessBanner = memo(() => {
  const chat = useContext(ActiveChatSessionContext);

  return (
    <NoAccessBannerRoot>
      You do not have access to this {toTitleCase(chat.context.type)}.
    </NoAccessBannerRoot>
  );
});

const MaxContainerWidth = 1100;

const NoAccessBannerRoot = styled.div(({ theme }) => ({
  width: '100%',
  padding: 15,
  color: theme.palette.gray.dark1,
  fontFamily: theme.fonts.bold,
  backgroundColor: theme.palette.red.secondary,
  border: `1px solid ${theme.palette.red.main}`,
  boxSizing: 'border-box',
  borderRadius: 4,
}));

const Root = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  height: `calc(100% - ${Constants.Modal.HeaderHeight}px)`,
  width: '100%',
  margin: '0 auto',
  boxSizing: 'border-box',
}));

const Messages = styled.div(({
  boxSizing: 'border-box',
  height: '100%',
  overflowY: 'auto',

  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 40px, 100% 40px, 100% 14px, 100% 14px',
  backgroundAttachment: 'local, local, scroll, scroll',
}));

const MessagesWrap = styled.div(({
  height: '100%',
  width: '100%',
  maxWidth: MaxContainerWidth,
  margin: '0 auto',
}));

const Footer = styled.div(({
  padding: '20px 27px 15px 20px',
  boxSizing: 'border-box',
  width: '100%',
  maxWidth: MaxContainerWidth,
  margin: '0 auto',
}));