import { useCallback, useContext } from 'react';
import type { WorkspaceFileUpload } from '@/types';
import { FileUploadsDispatchContext } from './Context';
import { BaseFileUploadEffectsContainer } from './Effects.Base.Container';

export const FileUploadEffectsContainer = (props: ChildrenProps) => {

  const dispatch = useContext(FileUploadsDispatchContext);

  const handleComplete = useCallback((fileUpload: WorkspaceFileUpload) => {
    dispatch({
      type: 'update-valid-upload',
      fileUpload,
    });
  }, [dispatch]);

  const handleAbort = useCallback((fileUpload: WorkspaceFileUpload) => {
    dispatch({
      type: 'update-valid-upload',
      fileUpload,
    });
  }, [dispatch]);

  const handleFailure = useCallback((fileUpload: WorkspaceFileUpload) => {
    dispatch({
      type: 'update-valid-upload',
      fileUpload,
    });
  }, [dispatch]);

  const handleStart = useCallback((fileUpload: WorkspaceFileUpload) => {
    dispatch({
      type: 'update-valid-upload',
      fileUpload,
    });
  }, [dispatch]);

  const handleQueue = useCallback((fileUpload: WorkspaceFileUpload) => {
    dispatch({
      type: 'add-valid-upload',
      fileUpload,
    });
  }, [dispatch]);

  return (
    <BaseFileUploadEffectsContainer
      onAbort={handleAbort}
      onComplete={handleComplete}
      onFailure={handleFailure}
      onStart={handleStart}
      onQueue={handleQueue}>
      {props.children}
    </BaseFileUploadEffectsContainer>
  );
};