import { useRef, useState } from 'react';
import type { AxiosResponse } from 'axios';
import Toast from '@/components/Toast';
import { UploadContext } from './UploadContext';
import styles from './style.css';

type UploaderProps<T> = {
  onUpload: (f: File) => Promise<T>;
  onSuccess: (result: T) => void;
  validFileTypes?: string[];
} & ChildrenProps;

type ErrorResp = {
  message?: string;
};

export const Uploader = <T extends Record<string, unknown>>({ onUpload, onSuccess, children, validFileTypes }: UploaderProps<T>) => {
  const ref = useRef<HTMLInputElement>();
  const [posting, changePosting] = useState(false);
  const contextValue = {
    isUploading: posting,
  };
  return (
    <span onClick={() => { ref.current.value = null; ref.current.click(); }}>
      <UploadContext.Provider value={contextValue}>
        <input
          className={styles.input}
          type={'file'}
          ref={ref}
          disabled={posting}
          autoComplete={'off'}
          accept={validFileTypes ? validFileTypes.join(',') : null}
          onChange={e => {
            const files = e.target.files;
            if (files.length > 0) {
              changePosting(true);
              onUpload(files[0])
                .then(x => {
                  onSuccess(x);
                })
                .catch((e: AxiosResponse<ErrorResp>) => {
                  Toast.error({
                    title: 'Error',
                    body: e?.data?.message ?? 'There was an error uploading the file.',
                    autoClose: 10000,
                  });
                }).finally(() => {
                  if (ref.current) {
                    ref.current.value = null;
                  }
                  changePosting(false);
                });
            } else {
              alert('No files selected');
            }
          }} />
        {children}
      </UploadContext.Provider>
    </span>
  );
};