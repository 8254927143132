/* import { useCallback } from 'react'; */
import { useSelector } from 'react-redux';
/* import { ProjectType } from '@enums'; */
/* import { useRequestQuant2Qual } from '@containers/Call/hooks/useRequestQuant2Qual'; */
import { PaidMessagingContainer } from '@containers/PlatformMessage';
import { RequestCallContainer } from '@containers/RequestCall';
import { useSelectContact, useSelectProject } from '@containers/Store';
/* import { useProfileMessagingAction, useProfileRequestCallAction } from '@/components/UserProfile/hooks'; */
import { MemberCardContent } from './MemberCard.Content';
import { CardTooltip } from './HtmlTooltip';

type Props = {
  classes?: {
    container?: string;
    tooltip?: string;
  };
  children: React.ReactNode;
  name?: string;
  pictureUrl?: string;
  userId: number;
  projectId: number;
};

const selectShowRate = (contactId: number) => (state: Store.State) => {
  return state.group.features.showConsultantRates &&
    state.user.id != contactId;
};

const MemberBaseballCardWithState = ({
  children,
  classes,
  projectId,
  userId,
  ...props
}: Props) => {
  const contact = useSelectContact(userId);
  /*
  const project = useSelectProject(projectId);
  const showRate = useSelector(selectShowRate(userId));
  const params = {
    user: {
      id: contact.id,
      profile: contact.profile,
    },
  };
  const [canInitiateMessages, initiateMessage] = useProfileMessagingAction(params);
  const [canInitiateCalls, initiateCall] = useProfileRequestCallAction(params);
  const requestQuant2Qual = useRequestQuant2Qual();

  // TODO: handle no project passed in
  const handleCallInitiated = useCallback(() => {
    if (!projectId) return null;

    if (project.projectType === ProjectType.Call) {
      initiateCall();
    } else {
      requestQuant2Qual({
        expertId: userId,
        expertName: contact.profile.fullname,
        projectId,
      });
    }
  }, [
    contact.profile.fullname,
    initiateCall,
    projectId,
    project?.projectType,
    requestQuant2Qual,
    userId,
  ]);

  */

  const attrs = contact || {
    id: userId,
    carreer: {},
    profile: {
      creditRate: 0,
      slug: String(userId),
      fullname: props.name,
      pictureUrl: props.pictureUrl,
    },
  };

  return (
    <>
      <CardTooltip
        classes={classes}
        title={(
          <MemberCardContent
            canInitiateCalls={false /* canInitiateCalls */}
            canInitiateMessages={false /* canInitiateMessages */}
            contact={attrs}
            initiateCall={() => {} /* handleCallInitiated */}
            initiateMessage={() => {} /* initiateMessage */}
            showRate={true} />
        )}>
        {children}
      </CardTooltip>
    </>
  );
};

export const MemberBaseballCard = (props: Props) => (
  <PaidMessagingContainer>
    <RequestCallContainer>
      <MemberBaseballCardWithState {...props} />
    </RequestCallContainer>
  </PaidMessagingContainer>
);

export default MemberBaseballCard;