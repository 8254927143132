import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import * as QKey from '@consts/querykey';
import { ProjectLinkURLState } from '@containers/ProjectLinkInvitation/interfaces';

export const useCacheProjectInviteTheming = () => {
  const qc = useQueryClient();

  const cacheSurveyTheming = (data: Omit<ProjectLinkURLState['data'], 'payment'>) => {
    qc.setQueryData([QKey.Groups.Branding.Survey.Theming.Get, data.project.groupId], data.theming);
  };

  return useCallback(cacheSurveyTheming, [qc]);
};