import { memo } from 'react';
import { PlusCircle } from 'react-feather';
import { cx } from '@utils';
import styles from './style/Card.css';

type Props = {
  onClick?: () => void;
};

export const CustomSurveyCard = memo(({ onClick }: Props) => {

  const title = `Create Custom Survey`;

  return (
    <div
      className={styles.root}
      onClick={onClick}>
      <div className={cx(styles.poster, styles.lightBlue)}>
        <PlusCircle className={styles.plus} />
      </div>
      <div className={styles.footer}>
        <div className={cx(styles.title, styles.custom)}>{title}</div>
      </div>
    </div>
  );
});