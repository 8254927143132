/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import type { Chat } from '@/brand-insights/types';
import { UserMessage } from './Message.User';
import { BaseSystemUserMessage } from './Message.System';

type Props = {
  identifier: Chat.Segmentation.Segmentation['identifier'];
  data: Chat.Segmentation.Cancelled.ScreenData;
};

export const SegmentationCancelled = (props: Props) => {
  return (
    <>
      <MessageContainer>
        <UserMessage value={<SegmentationCancelledUserMessage {...props} />} />
      </MessageContainer>
      <MessageContainer>
        <BaseSystemUserMessage>
          <SegmentationCancelledSystemMessage {...props} />
        </BaseSystemUserMessage>
      </MessageContainer>
    </>
  );
};

export const SegmentationCancelledUserMessage = (props: Props) => {
  return (
    <>
      <UserCopy>
        {copy.user.pre}
      </UserCopy>
    </>
  );
};

export const SegmentationCancelledSystemMessage = (props: Props) => {
  return (
    <SystemCopy>
      {copy.system.pre}
    </SystemCopy>
  );
};

export const copy = {
  user: {
    pre: `Actually, I'd like to do something else.`,
    pending: `Actually, let's cancel this analysis.`,
  },
  system: {
    pre: `I've cancelled this analysis!`,
  },
};

const MessageContainer = styled.div`
  padding: 15px 20px;
`;

const UserCopy = styled.div`
  box-sizing: border-box;
`;

const SystemCopy = styled.div`
  box-sizing: border-box;
`;