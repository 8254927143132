import { memo, useMemo, Fragment, useCallback } from 'react';
import styled from '@emotion/styled';
import type { Chat } from '@/brand-insights/types';
import { ChatMarkdown } from '@/brand-insights/components/presentation/Markdown';
import { parseResponse } from './Message.Response.utils';
import type { ResponseItem } from './Message.Response.utils';
import { FindQuotesResponse } from './Message.Response.Quotes';

type MessageResponse = {
  item: Chat.QueryResponse;
};

export const MessageResponse = ({ item }: MessageResponse) => {
  switch (item.type) {
    case 'raw-text': return <MessageResponseRawText value={item.value} />;
    case 'markdown': return <ChatMarkdown value={item.value} />;
    case 'find-quotes-response': return <FindQuotesResponse data={item.value} />;

    default: return <Fragment>N/A</Fragment>;
  }
};

type MessageResponseRawTextProps = {
  value: string;
};

export const MessageResponseRawText = memo(({ value }: MessageResponseRawTextProps) => {

  const items = useMemo(() => {
    return parseResponse(value);
  }, [value]);

  const renderItem = useCallback((item: ResponseItem, i: number) => {
    switch (item.type) {
      case 'bullets': {
        return (
          <StyledUl key={i}>
            {item.value.map((value, j) => (
              <StyledLi key={j}>{value}</StyledLi>
            ))}
          </StyledUl>
        );
      }
      case 'numbered': {
        return (
          <StyledOl key={i}>
            {item.value.map((value, j) => (
              <StyledLi key={j}>{value}</StyledLi>
            ))}
          </StyledOl>
        );
      }
      case 'text': {
        if (item.value === '') return <br key={i} />;
        return (
          <div key={i}>{item.value}</div>
        );
      }
    }
  }, []);

  return (
    <Fragment>
      {items.map(renderItem)}
    </Fragment>
  );
});

const StyledLi = styled('li')({
  marginBottom: 4,

  '&:last-child': {
    marginBottom: 0,
  },
});

const StyledOl = styled('ol')({
  paddingLeft: 20,

  [`& > li`]: {
    paddingLeft: 5,
    marginBottom: 8,
    '&::marker': {
      fontFamily: 'var(--font-semibold)',
    },
  },
});

const StyledUl = styled('ul')({
  paddingInlineStart: 20,
});
