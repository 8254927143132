import { useCallback, useContext } from 'react';
import { BrandThemingContext } from '@containers/Branding/Context';
import { useUpdateSurveyCompletionTextMutation } from '@containers/Branding/hooks';
import { OptimisticIndicator } from '@presentation';
import { useDebouncedInputEvent } from '@utils';
import { Textarea } from '@/components/Textarea';
import styles from './style/Settings.Branding.Survey.CompletionText.css';

type Props = unknown;

export const CompletionText = (props: Props) => {
  const { query } = useContext(BrandThemingContext);
  const mutation = useUpdateSurveyCompletionTextMutation();

  const handleChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    mutation.mutate({ value: e.target.value });
  }, [mutation]);

  const debouncedChange = useDebouncedInputEvent(handleChange, 500);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.label}>
          <h2 className={styles.header}>Survey Thank You Page Text</h2>
          <OptimisticIndicator
            classes={{ root: styles.indicator }}
            isError={mutation.isError}
            isLoading={mutation.isLoading}
            isSuccess={mutation.isSuccess} />
        </div>
        <div className={styles.main}>
          <Textarea
            className={styles.textarea}
            defaultValue={query.data.survey.page.completion.text}
            maxLength={500}
            onChange={debouncedChange} />
        </div>
      </div>
    </div>
  );
};

CompletionText.displayName = 'Settings.Branding.Survey.CompletionText';