import { useCallback } from 'react';
import * as api from '@api';
import type { CompleteUploadParams } from '../interfaces';

export const useCompleteFileUpload = () => {

  const completeUpload = useCallback((data: CompleteUploadParams) => {
    return api.workspaces.fileUpload.completeFileUpload({
      fileUploadId: data.fileUploadId,
      s3VersionId: data.s3VersionId,
      workspaceId: data.workspaceId,
    }).then(res => {
      return { fileUpload: res.fileUpload };
    });
  }, []);

  return completeUpload;
};

export default useCompleteFileUpload;