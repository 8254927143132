import { useCallback, useContext } from 'react';
import { TranscriptFocusedTagContext } from '@containers/Transcript.Tagging/Context';
import { useTranscriptTagsContext } from '@containers/Transcript.Tagging/hooks';
import { useMapTimePosToDocPos } from './useMapPositions';
import { useChainedCommands } from './useCommands';
import { useHelpers } from './useHelpers';

export const useFocusTag = () => {
  const [tags] = useTranscriptTagsContext();
  const [focusedTag, setFocusedTag] = useContext(TranscriptFocusedTagContext);
  const commands = useChainedCommands();
  const helpers = useHelpers();

  const mapTimePosToDocPos = useMapTimePosToDocPos();

  return useCallback((tagIdentifier: string) => {
    const matchingTag = tags.items.find(t => t.identifier === tagIdentifier);

    setFocusedTag(tagIdentifier);

    if (focusedTag && focusedTag != tagIdentifier) {
      commands.removeHighlights([focusedTag]);
    }
    if (!helpers.getBasicHighlight(tagIdentifier)) {
      const { from, to } = mapTimePosToDocPos(matchingTag);
      commands.addHighlight({
        id: tagIdentifier,
        dbId: null,
        derived: true,
        color: matchingTag.color,
        from,
        to,
      });
    }

    commands.run();
    //Remove an old highlight if it exists
    //Add the highlight if it doesn't exist already
  }, [setFocusedTag, commands, helpers, mapTimePosToDocPos, focusedTag, tags]);
};

export const useUnfocusTag = () => {
  const [focusedTag, setFocusedTag] = useContext(TranscriptFocusedTagContext);
  const hideTagHighlight = useHideTagHighlight();
  return useCallback(() => {
    setFocusedTag(null);
    hideTagHighlight(focusedTag);
  }, [setFocusedTag, hideTagHighlight, focusedTag]);
};

export const useShowTagHighlight = () => {
  const [tags] = useTranscriptTagsContext();
  const commands = useChainedCommands();
  const helpers = useHelpers();

  const mapTimePosToDocPos = useMapTimePosToDocPos();
  return useCallback((tagIdentifier: string) => {
    if (!helpers.getBasicHighlight(tagIdentifier)) {
      const matchingTag = tags.items.find(t => t.identifier === tagIdentifier);
      const { from, to } = mapTimePosToDocPos(matchingTag);
      commands.addHighlight({
        id: tagIdentifier,
        dbId: null,
        derived: true,
        color: matchingTag.color,
        from,
        to,
      }).run();
    }
  }, [commands, helpers, mapTimePosToDocPos, tags]);
};

export const useHideTagHighlight = () => {
  const commands = useChainedCommands();
  const helpers = useHelpers();
  return useCallback((tagIdentifier: string) => {
    if (helpers.getBasicHighlight(tagIdentifier)) {
      commands.removeHighlights([tagIdentifier]).run();
    }
  }, [commands, helpers]);
};