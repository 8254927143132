import { useCallback } from 'react';
import * as $api from '@api';
import Toast from '@/components/Toast';
import type { UpdatePublishedFileOnParams } from '../interfaces';

export const useUpdateFilePublishedOn = () => {
  const updatePublishedOn = useCallback((data: UpdatePublishedFileOnParams) => {
    return $api.workspaces.file.updatePublishedOn({
      fileId: data.fileId,
      publishedOn: data.publishedOn,
      workspaceId: data.workspaceId,
    }).then(({ file, object }) => {
      Toast.alert({
        title: 'File Published On Updated',
      });
      return data;
    }).catch(e => {
      Toast.error({
        title: 'Error',
        body: `We're sorry, there was an issue with your request`,
      });
      throw e;
    });
  }, []);

  return updatePublishedOn;
};

export default useUpdateFilePublishedOn;