import { createPortal } from 'react-dom';

type Props = {
  anchorEl?: Element;
  disablePortal?: boolean;
} & ChildrenProps;

export const Portal = ({
  anchorEl,
  children,
  disablePortal,
}: Props) => {

  if (!disablePortal) {
    return createPortal(children, anchorEl || document.body);
  } else {
    return (
      <>
        {children}
      </>
    );
  }
};