import { Folder } from 'react-feather';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { WorkspaceGlobalSearchBarResultFolder } from '@/types';
import { cx } from '@utils';
import ClickContainer from './Results.ClickContainer';
import styles from './style/Results.Folder.css';

export default function ResultsFolder({ className, index, item, highlighted }: ResultsFolderProps) {
  return (
    <ClickContainer index={index} item={item}>
      <div className={cx(styles.root, { [styles.highlighted]: highlighted }, className)}>
        <Folder
          className={styles.icon}
          color="#737373"
          size={28} />
        <div className={styles.details}>
          <div className={styles.name}>{item.name}</div>
          <div className={styles.type}>Folder</div>
        </div>
        {highlighted && (
          <div className={styles.return}>
            <KeyboardReturnIcon />
          </div>
        )}
      </div>
    </ClickContainer>
  );
}

export { ResultsFolder };

type ResultsFolderProps = {
  className?: string;
  index: number;
  item: WorkspaceGlobalSearchBarResultFolder;
  highlighted?: boolean;
}