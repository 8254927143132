import { useState } from 'react';
import { ExternalLink } from 'react-feather';
import * as $api from '@services/api/integrations';
import { S3ConnectionParams, S3Connection } from '@/types/integrations';
import { Button } from '@/components/Button';
import { useModal } from '@/components/Modal/hooks';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import { Header } from '@/components/Modal/Header';
import { Spinner } from '@/components/ActivityIndicator';
import { Input } from '@/components/Input';
import styles from './style/S3IntegrationModal.css';

type Props = {
  onConnect: () => void;
  originalState: S3Connection;
} & Pick<ModalProps, 'open' | 'onClose'>;

type ConnectionParam = keyof S3ConnectionParams;

function paramsEqual(a: S3ConnectionParams, b: S3ConnectionParams) {
  const props: Array<ConnectionParam> = ['accessKey', 'accessSecret', 'bucket', 'region'];

  return props.every(p => a[p] == b[p]);
}

export const S3IntegrationModal = (props: Props) => {
  const [originalConnection] = useState(props.originalState);
  const [connecting, setConnectingState] = useState(false);
  const [error, setError] = useState<string>();
  const [canSave, setCanSave] = useState<boolean>(false);
  const [connectionParams, setConnectionParams] = useState<S3ConnectionParams>(props.originalState ?? {
    accessKey: '',
    accessSecret: '',
    region: '',
    bucket: '',
  });

  const paramsValid = !!connectionParams && !!connectionParams.accessKey && !!connectionParams.accessSecret && !!connectionParams.bucket && !!connectionParams.region;

  const canTest = paramsValid/* && (
    !originalConnection.id || !paramsEqual(originalConnection, connectionParams)
  )*/;

  function testConnection() {
    setConnectingState(true);
    $api.testS3Credentials(connectionParams).then(r => {
      if (!r.success) {
        setError(r.msg);
      } else {
        setCanSave(true);
        setError(null);
      }
    }).catch(() => {
      setError('There was an error testing the credentials');
    }).finally(() => {
      setConnectingState(false);
    });
  }

  function saveConnection() {
    setConnectingState(true);
    $api.saveS3Credentials(connectionParams).then(() => {
      props.onConnect();
    }).catch(() => {
      setError('There was an error saving your connection');
    }).finally(() => {
      setConnectingState(false);
    });
  }

  function deleteConnection() {
    setConnectingState(true);
    $api.deleteS3Credentials(originalConnection)
      .then(() => {
        props.onConnect();
      }).catch(() => {
        setError('There was an error deleting your connection');
      }).finally(() => {
        setConnectingState(false);
      });
  }

  const setState = (prop: ConnectionParam) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const newVal = {
        ...connectionParams,
        [prop]: e.target.value,
      };
      setConnectionParams(newVal);
      setError(null);
      setCanSave(false);
    };
  };

  return (
    <Modal {...props}>
      <Header text={'S3 Integration'} />
      <div className={styles.instruction}>You can receive daily automated project exports to your private AWS S3 Bucket</div>
      <div className={styles.instruction}>To received the daily exports you must create a dedicated IAM user that has read/write access to the target destination bucket.</div>
      <div className={styles.externalDoc}>For more details on how to set up these permissions you can follow this <ExternalDocLink link={'https://aws.amazon.com/premiumsupport/knowledge-center/s3-console-access-certain-bucket/'} text={'access guide'} />.</div>

      <div className={styles.form}>
        <Input
          rootClassName={styles.input}
          type={'text'}
          placeholder={'Access Key ID'}
          onChange={setState('accessKey')}
          value={connectionParams?.accessKey} />
        <Input
          rootClassName={styles.input}
          type={'text'}
          placeholder={'Access Key Secret'}
          onChange={setState('accessSecret')}
          value={connectionParams?.accessSecret} />
        <Input
          rootClassName={styles.input}
          type={'text'}
          placeholder={'Region'}
          onChange={setState('region')}
          value={connectionParams?.region} />
        <Input
          rootClassName={styles.input}
          type={'text'}
          placeholder={'Bucket'}
          onChange={setState('bucket')}
          value={connectionParams?.bucket} />
        {!canSave &&
          <Button
            disabled={!canTest || connecting}
            onClick={testConnection}
            variant={'brick'}>
            Test Connection
          </Button>
        }
        {canSave &&
          <Button
            onClick={saveConnection}
            disabled={connecting}
            variant={'brick'}>
            Save Connection
          </Button>
        }
        {connecting &&
          <Spinner
            className={styles.spinner}
            size={24} />
        }
        {error &&
          <div className={styles.errorMsg}>
            {error}
          </div>}

        {originalConnection &&
          <Button.Destructive
            onClick={deleteConnection}
            disabled={connecting}
            className={styles.deleteConnection}
            variant={'brick'}>
            Remove Connection
          </Button.Destructive>
        }
      </div>
    </Modal>
  );
};

type DocLinkProps = {
  link: string;
  text: string;
};
const ExternalDocLink = ({ link, text }: DocLinkProps) => {
  return (
    <a
      className={styles.externalLink}
      target={'_blank'}
      href={link}
      rel={'noreferrer'}>
      {text}<ExternalLink className={styles.externalIcon} size={14} />
    </a>);
};

export const useS3Modal = () => useModal(S3IntegrationModal);