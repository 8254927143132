import React from 'react';
import { cx } from '@utils';
import styles from './style/ImportStepper.css';

type Props = {
  className?: string;
  children:   React.ReactNode;
};

export const Header = (props: Props) => {

  return (
    <h2 className={cx(styles.header, props.className)}>
      {props.children}
    </h2>
  );
};

Header.displayName = 'Layout.Header';

export const Subheader = (props: Props) => {

  return (
    <div className={cx(styles.subheader, props.className)}>
      {props.children}
    </div>
  );
};

Subheader.displayName = 'Layout.Subheader';