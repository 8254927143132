import { FileTranscriptStatus, type WorkspaceFileTranscript } from '@/types/workspace';

export function assertCanImportTranscript(transcript: WorkspaceFileTranscript) {
  return !transcript.id &&
    transcript.meta.canConvertToTranscript &&
    ![FileTranscriptStatus.Complete, FileTranscriptStatus.InProgress].includes(transcript?.status);
}

export function assertCanTranscribeFile(transcript: WorkspaceFileTranscript) {
  return !transcript.id &&
    transcript.meta.canTranscribe &&
    ![FileTranscriptStatus.Complete, FileTranscriptStatus.InProgress].includes(transcript?.status);
}