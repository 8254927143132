import type { ModalBaseProps } from './ModalBase';
import { ModalBase } from './ModalBase';

type Props = {
  className?: string;
  disablePortal?: boolean;
} & Omit<ModalBaseProps, 'disableOverlayClick' | 'onClose'>
;

const Dialog = ({
  disablePortal = false,
  ...props
}: Props) => {
  return (
    <ModalBase
      classes={{ root: props.className }}
      disableOverlayClick
      disablePortal={disablePortal}
      open={props.open}>
      {props.children}
    </ModalBase>
  );
};

export type DialogProps = Props;

export { Dialog };
export default Dialog;