import { createElement, useEffect } from 'react';
import { RouteComponentProps, useRouteMatch } from 'react-router-dom';
import { Website } from '@consts/path';
import { useResolvedProjectInvite  } from '@containers/ProjectLinkInvitation/hooks/useResolvedProjectInvite';
import { ProjectType } from '@enums';
import { useSignupPing } from '$website/containers/Auth/hooks/useSignupPing';
import { Signup } from '$website/screens/Auth';
import { ResearchCommunity } from '$website/screens/ResearchCommunity';
import { CallInvite, SurveyInvite } from '$website/screens/ProjectInvite';

type Props = RouteComponentProps;

const SignupRouter = (props: Props) => {
  const params = useResolvedProjectInvite();
  const ping = useSignupPing();

  const referral = useRouteMatch(Website.ReferralSignup);

  useEffect(() => {

    ping();

  }, [ping]);

  if (params?.token) {
    return ProjectType.Survey === params?.data?.project?.projectType
        ? createElement(SurveyInvite)
        : createElement(CallInvite);
  }

  if (referral) return createElement(ResearchCommunity);

  return createElement(Signup.Visitor);
};

SignupRouter.displayName = 'Router.Signup';

export { SignupRouter as Signup };