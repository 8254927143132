import { useCallback } from 'react';
import type { SurveyActionType } from '@enums';
import {
  SurveyFormMetadataContext,
  SurveyFormVersionIdContext,
  SurveyFormStepperState,
  SurveyResponseIdentifierContext,
  SaveCompletedSurveyResponseContext,
  SaveDisqualifiedSurveyResponseContext,
  SurveyFormTemplateContext,
} from '@containers/SurveyForm';
import { ProjectSurveyQuestion } from '@screens/Survey';
import { useFetchProjectSurveyForm } from '@utils/api';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import {
  useProjectOnboardingContext,
  useSubmitProjectSurvey,
  useCompleteSurveyProjectOnboarding,
  useTerminateProjectOnboarding,
} from './hooks';
import { SurveyProjectContext } from './Context';

export const ProjectSurveyStepper = () => {
  const {
    next: nextProjectScreen,
    projectId,
  } = useProjectOnboardingContext();
  const {
    data: res,
    isLoading,
    isError,
  } = useFetchProjectSurveyForm({
    preview: false,
    projectId,
  }, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });

  const submitSurvey = useSubmitProjectSurvey(projectId);
  const completeOnboarding = useCompleteSurveyProjectOnboarding(projectId);
  const { disqualify } = useTerminateProjectOnboarding({ projectId });

  const handleSubmitSurvey = useCallback(() => {
    return submitSurvey({
      responseIdentifier: res.response.identifier,
      surveyVersionId: res.survey.id,
    });
  }, [
    res?.response?.identifier,
    res?.survey?.id,
    submitSurvey,
  ]);

  const handleDisqualification = useCallback((actionType: SurveyActionType) => {
    return handleSubmitSurvey()
      .then(() => disqualify(actionType));
  }, [disqualify, handleSubmitSurvey]);

  const handleCompletion = useCallback(() => {
    return handleSubmitSurvey()
      .then(completeOnboarding)
      .then(nextProjectScreen);
  }, [completeOnboarding, nextProjectScreen, handleSubmitSurvey]);

  if (isLoading) {
    return <ActivityIndicator show />;
  }

  return (
    <SurveyProjectContext.Provider value={res.project}>
      <SurveyFormVersionIdContext.Provider value={res.survey.id}>
        <SurveyFormMetadataContext.Provider value={res.metadata}>
          <SaveCompletedSurveyResponseContext.Provider value={handleCompletion}>
            <SaveDisqualifiedSurveyResponseContext.Provider value={handleDisqualification}>
              <SurveyResponseIdentifierContext.Provider value={res.response.identifier}>
                <SurveyFormTemplateContext.Provider value={res.survey.template}>
                  <SurveyFormStepperState
                    state={res.response.state}
                    QuestionScreenComponent={ProjectSurveyQuestion} />
                </SurveyFormTemplateContext.Provider>
              </SurveyResponseIdentifierContext.Provider>
            </SaveDisqualifiedSurveyResponseContext.Provider>
          </SaveCompletedSurveyResponseContext.Provider>
        </SurveyFormMetadataContext.Provider>
      </SurveyFormVersionIdContext.Provider>
    </SurveyProjectContext.Provider>
  );
};

export default ProjectSurveyStepper;