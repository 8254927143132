import { useCallback } from 'react';
import type { SurveyLogic } from '@/types';
import { SurveyLogicBuilderContext, useLogicBuilderProps } from '@containers/SurveyBuilder.Logic';
import { isQuestionInCondition } from '@containers/SurveyBuilder.Logic/utils.lookup';

type Props = {
  questionIdentifier: string;
} & ChildrenProps;

function isLogicItemRelevant(item: SurveyLogic.Item, questionIdentifier: string) {
  return item.conditions.some(condition => isQuestionInCondition({
    condition,
    question: { identifier: questionIdentifier },
  }));
}

export const QuestionLogicContainer = (props: Props) => {

  const testItemRelevancy = useCallback((item: SurveyLogic.Item) => {
    return isLogicItemRelevant(item, props.questionIdentifier);
  }, [props.questionIdentifier]);

  const builderProps = useLogicBuilderProps({ testItemRelevancy });

  const value = {
    ...builderProps,
    display: {
      hideActions: false,
    },
  };

  return (
    <SurveyLogicBuilderContext.Provider value={value}>
      {props.children}
    </SurveyLogicBuilderContext.Provider>
  );
};