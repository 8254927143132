import { memo } from 'react';
import { cx } from '@utils';
import img from 'static/images/logo-trinity-grey.svg?url';
import { useFooterScreenContainer } from './useFooterScreenContainer';
import styles from './style/Footer.PoweredBy.css';

type Props = unknown;

const Footer = memo((props: Props) => {

  return (
    <footer className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.content}>
            <span className={styles.span}>Powered by</span>
            <img
              className={styles.img}
              src={img} />
          </div>
        </div>
      </div>
    </footer>
  );
});

Footer.displayName = 'Branding.Footer.PoweredBy.Footer';

type PoweredByProps = {
  children: React.ReactNode;
  className?: string;
  fullHeight?: boolean;
};

export const PoweredBy = ({ fullHeight = true, ...props }: PoweredByProps) => {
  const ScreenContainer = useFooterScreenContainer(Footer);

  const classnames = cx({
    [styles.screen]: fullHeight,
  }, props.className);

  return (
    <ScreenContainer className={classnames}>
      {props.children}
    </ScreenContainer>
  );
};