import { createRef, useCallback, useMemo, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { useSurveyBuilderState } from '@containers/SurveyBuilder';
import { parseSurveyRichText } from '@/containers/SurveyBuilder/utils/rich-text';
import { cx, trunc } from '@utils';
import type { SurveyItem, SurveyQuestion } from '@/types/survey';
import { Portal } from '@/components/Portal';
import { useZIndexModifier } from '@/components/Popper';
import { Menu, MenuItem } from './Editor.Piping.Menu';
import { QuestionConditionsMenu } from './Editor.Piping.ConditionsMenu';
import { ItemConditionsMenu } from './Editor.Piping.ItemsMenu';
import styles from './style/Editor.Piping.Menu.css';

type Props = {
  questions: SurveyQuestion[];
  items: SurveyItem[];
};

type OpenIdentifier = {
  identifier: string;
  type: 'question' | 'item';
};

export const PipingQuestionsMenu = ({ items, questions }: Props) => {

  const [openIdentifier, setOpenIdentifier] = useState<OpenIdentifier>(null);
  const menuRef = useRef<HTMLDivElement>();

  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);

  const refMap = useMemo(() => {
    const map = questions.reduce<Map>((acc, x) => {
      acc[buildIdentifier(x.base.identifier, 'question')] = createRef<HTMLDivElement>();
      return acc;
    }, {});

    return items.reduce<Map>((acc, x) => {
      acc[buildIdentifier(x.identifier, 'item')] = createRef<HTMLDivElement>();
      return acc;
    }, map);

    type Map = {
      [identifier: string]: React.RefObject<HTMLDivElement>;
    };
  }, [questions, items]);

  const referenceElement = useMemo(() => {
    if (!openIdentifier) return null;
    return refMap[buildIdentifier(openIdentifier.identifier, openIdentifier.type)];
  }, [refMap, openIdentifier]);

  const [state] = useSurveyBuilderState();

  const zIndexModifier = useZIndexModifier({ zIndex: 8 });

  const { styles: popperStyles, attributes } = usePopper(referenceElement?.current, popperElement, {
    modifiers: [
      zIndexModifier,
    ],
    placement: 'right-start',
  });

  const handleClick = useCallback((identifier: OpenIdentifier) => () => {
    setOpenIdentifier(identifier);
  }, []);

  const renderQuestionMenuItem = useCallback((item: SurveyQuestion) => {
    const questionText = parseSurveyRichText(item.value, state.survey);
    const active = openIdentifier?.type === 'question' && openIdentifier.identifier === item.base.identifier;

    const text = `Q${item.ordinal}: ${trunc(questionText, 50)}`;

    return (
      <MenuItem
        key={item.base.identifier}
        ref={refMap[buildIdentifier(item.base.identifier, 'question')]}
        active={active}
        hasChildren={true}
        onClick={handleClick({ identifier: item.base.identifier, type: 'question' })}>
        {text}
      </MenuItem>
    );
  }, [
    handleClick,
    openIdentifier,
    refMap,
    state.survey,
  ]);

  const renderItemMenuItem = useCallback((item: SurveyItem) => {
    const active = openIdentifier?.type === 'item' && openIdentifier.identifier === item.identifier;

    const text = `SS${item.ordinal}`;

    return (
      <MenuItem
        key={item.identifier}
        ref={refMap[buildIdentifier(item.identifier, 'item')]}
        active={active}
        hasChildren={true}
        onClick={handleClick({ identifier: item.identifier, type: 'item' })}>
        {text}
      </MenuItem>
    );
  }, [handleClick, openIdentifier, refMap]);

  const handleBlur = useCallback((e: MouseEvent) => {
    if (!menuRef.current.contains(e.target as Node)) {
      setOpenIdentifier(null);
    }
  }, []);

  return (
    <>
      <Menu
        ref={menuRef}
        className={cx({
          [styles.overflow]: !openIdentifier,
        })}>
        {questions.map(renderQuestionMenuItem)}
        {items.map(renderItemMenuItem)}
      </Menu>
      {!!openIdentifier &&
        <Portal>
          <ClickAwayListener
            mouseEvent='onMouseDown'
            onClickAway={handleBlur}
            touchEvent='onTouchStart'>
            <div
              ref={setPopperElement}
              style={popperStyles.popper}
              {...attributes.popper}>
              {openIdentifier.type === 'question' ?
                <QuestionConditionsMenu item={questions.find(f => f.base.identifier === openIdentifier.identifier)} />
                : <ItemConditionsMenu item={items.find(f => f.identifier === openIdentifier.identifier)} />}
            </div>
          </ClickAwayListener>
        </Portal>
      }
    </>
  );
};

function buildIdentifier(identifier: string, type: 'question' | 'item') {
  return `${type}:${identifier}`;
}