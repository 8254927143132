import { useContext } from 'react';
import * as Context from '@containers/PostCreation/Context';

export const usePublishPostContext = () => useContext(Context.TextPublishContext);
export const useTextPostStateContext = () => useContext(Context.TextStateContext);

export const useTopicTagsContext = () => useContext(Context.TopicTagsContext);

export const useSearchConsultants = () => useContext(Context.SearchConsultantsContext);
export const useSearchTopics = () => useContext(Context.SearchTopicsContext);

export const useVideoPostIdentifier = () => useContext(Context.VideoPostIdentifierContext);
export const useVideoPostObjectURL = () => useContext(Context.VideoObjectURLContext);
export const useVideoPostStateContext = () => useContext(Context.VideoStateContext);
export const useVideoPostStepperContext = () => useContext(Context.VideoStepperContext);
export const useVideoPostUploaderContext = () => useContext(Context.VideoUploaderContext);