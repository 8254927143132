import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { pathname } from '@consts';
import { ProjectConsultantReferralSourcingContext } from '@containers/ProjectOnboarding/Context';
import { useIsFreeProject } from '@containers/ProjectOnboarding/hooks';
import { PayoutSetupContainer } from '@containers/PayoutSetup';
import { usePayoutSetup } from '@containers/PayoutSetup/hooks';
import { useSelectProject } from '@containers/Store';
import { useStepper } from '@utils';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import { PayoutSetupStepperContext, PayoutSetupProjectContext } from './Context';
import { InviteReferral } from './InviteReferral';
import * as Overview from './Overview';
import * as Setup from './Setup';
import * as Done from './Done';
import { ProjectStepperProps } from './interfaces';

export default function ProjectCall(props: ProjectStepperProps) {
  return (
    <PayoutSetupContainer>
      <Stepper {...props } />
    </PayoutSetupContainer>
  );
}

function Stepper({ hasPayoutAccount, hasStepsAfter, projectId, onComplete, onSkip }: ProjectStepperProps) {
  const project = useSelectProject(projectId);
  const sourcing = useContext(ProjectConsultantReferralSourcingContext);
  const isFreeCall = useIsFreeProject(projectId);

  const options = {
    index: hasPayoutAccount || isFreeCall ? 2 : 0,
  };

  const [Screen, actions] = useStepper([
    CallOverview,
    CallSetup,
    CallDone,
    ...(sourcing.data?.referrals ? [ProjectCallInviteReferral] : []),
  ], options);

  const value = {
    ...actions,
    hasPayoutAccount,
    hasStepsAfter,
    onComplete,
    onSkip,
  };

  if (sourcing.isLoading) {
    return (
      <ActivityIndicator />
    );
  }

  return (
    <PayoutSetupStepperContext.Provider value={value}>
      <PayoutSetupProjectContext.Provider value={{ project }}>
        <Screen />
      </PayoutSetupProjectContext.Provider>
    </PayoutSetupStepperContext.Provider>
  );
}

function CallOverview() {
  const { next } = useContext(PayoutSetupStepperContext);
  const { project } = useContext(PayoutSetupProjectContext);

  const navigation = {
    back: null,
    next,
    skip: null,
  };

  return (
    <Overview.ProjectCall
      navigation={navigation}
      projectName={project.name} />
  );
}

function CallSetup() {
  const { back, next } = useContext(PayoutSetupStepperContext);

  const navigation = {
    back,
    next,
    skip: null,
  };

  return (
    <Setup.Setup navigation={navigation} />
  );
}

function CallDone() {
  const history = useHistory();
  const { hasStepsAfter, next, onComplete } = useContext(PayoutSetupStepperContext);
  const sourcing = useContext(ProjectConsultantReferralSourcingContext);
  const { project } = useContext(PayoutSetupProjectContext);
  const { refetch } = usePayoutSetup();

  const handleNext = useCallback(() => {
    refetch();

    if (sourcing.data?.referrals) {
      next();
    } else if (onComplete) {
      onComplete();
    } else {
      history.push(pathname.Home);
    }
  }, [
    history,
    next,
    onComplete,
    refetch,
    sourcing.data?.referrals,
  ]);

  const navigation = {
    back: null,
    next: handleNext,
    skip: null,
  };

  return (
    <Done.ProjectCall
      navigation={navigation}
      nextLabel={hasStepsAfter ? 'Next' : 'Back to Home'}
      projectName={project.name}
      referrals={sourcing.data?.referrals} />
  );
}

const ProjectCallInviteReferral = () => {
  const ctx = useContext(PayoutSetupProjectContext);
  const { back } = useContext(PayoutSetupStepperContext);

  const navigation = {
    back,
    next: null,
    skip: null,
  };

  return (
    <InviteReferral
      navigation={navigation}
      projectId={ctx.project.id} />
  );
};

export { ProjectCall };