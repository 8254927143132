import { useContext, useMemo } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import * as $api from '@api';
import { TranscriptIdContext, TranscriptMetadataContext } from './context';
import type { Transcript } from './interfaces';

type Props = ChildrenProps;

export const TranscriptMetadataContainer = ({ children }: Props) => {
  const transcriptId = useContext(TranscriptIdContext);

  const query = useTranscriptMetadata(transcriptId);

  const updateMetadataMutation = useMutation({
    mutationKey: [
      `patch:transcripts/metadata`,
      transcriptId,
    ],
    mutationFn: (params: Transcript.Metadata.Data) => {
      return $api.transcripts.updateMetadata({
        transcriptId,
        publishedOn: params.publishedOn,
        title: params.title,
      })
        .then(() => query.refetch());
    },
  });

  const value = useMemo(() => ({
    title: query.data?.metadata?.title,
    publishedOn: query.data?.metadata?.publishedOn,
    updateMetadata: updateMetadataMutation.mutateAsync,
    isInitialLoading: query.isInitialLoading || query.isPlaceholderData || !query.data,
  }), [
    query.data,
    query.isInitialLoading,
    query.isPlaceholderData,
    updateMetadataMutation,
  ]);

  return (
    <TranscriptMetadataContext.Provider value={value}>
      {children}
    </TranscriptMetadataContext.Provider>
  );
};

const useTranscriptMetadata = (transcriptId: number) => {
  return useQuery({
    queryKey: [
      `get:transcripts/metadata`,
      transcriptId,
    ],
    queryFn: () => $api.transcripts.getMetadata({ transcriptId }),
    placeholderData: {
      metadata: {
        publishedOn: null,
        title: null,
      },
    },
    refetchOnWindowFocus: false,
    enabled: !!transcriptId,
  });
};