import { useMemo } from 'react';
import { useSurveyBuilderState, useCanAddQuestion, useSurveySection, useSectionItems, useCanAddMessage, useCanAddAIE } from '@containers/SurveyBuilder/hooks';
import { SurveySectionDeleteContainer } from './DeleteContainer';
import { SurveySectionBuilderContext } from './context';

type Props = {
  identifier: string;
} & ChildrenProps;

export const SurveySectionBuilderContainer = (props: Props) => {

  const [state] = useSurveyBuilderState();

  const section = useSurveySection(props.identifier);
  const items = useSectionItems(props.identifier);

  const canAddQuestionBase = useCanAddQuestion();

  const canAddQuestion = useMemo(() => {
    return canAddQuestionBase &&
      section.metadata.canAddQuestion;
  }, [
    canAddQuestionBase,
    section.metadata.canAddQuestion,
  ]);

  const canAddMessage = useCanAddMessage();
  const canAddAIE = useCanAddAIE();

  const lastOrdinal = useMemo(() => {
    if (items.length) {
      return Math.max(...items.map(m => m.ordinal));
    } else {
      const previous = state.survey.items.filter(f => {
        const fSection = state.survey.sections.find(s => s.identifier === f.section.identifier);
        return fSection.ordinal === section.ordinal - 1;
      }).map(m => m.ordinal);
      return previous.length
        ? Math.max(...previous)
        : 0;
    }

  }, [
    state.survey.items,
    state.survey.sections,
    items,
    section.ordinal,
  ]);

  const ctx = {
    canAddAIE,
    canAddMessage,
    canAddQuestion,
    items,
    lastOrdinal,
    section,
  };

  return (
    <SurveySectionBuilderContext.Provider value={ctx}>
      <SurveySectionDeleteContainer>
        {props.children}
      </SurveySectionDeleteContainer>
    </SurveySectionBuilderContext.Provider>
  );
};

export default SurveySectionBuilderContainer;