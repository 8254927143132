import { useCallback } from 'react';
import { useSurveyQuestionConditionValue, useConditionQuestion } from '@containers/SurveyBuilder.Logic';
import { RowDropdown as Dropdown } from '@presentation/SurveyBuilder';
import { SurveyLogic, SurveyQuestionMatrixRow } from '@/types';
import styles from './style/Builder.Condition.Question.css';

export const RowsDropdown = () => {

  const [condition, updateConditionValue] = useSurveyQuestionConditionValue<SurveyLogic.ConditionWithRow>();

  const question = useConditionQuestion();
  const selectedRow = (question.matrixRows as SurveyQuestionMatrixRow[]).find(f => f.base.identifier === condition.value?.row?.identifier);

  const handleUpdate = useCallback((rowIdentifier: string) => {
    updateConditionValue({
      ...condition.value,
      row: { identifier: rowIdentifier },
    });
  }, [updateConditionValue, condition]);

  return (
    <div className={styles.rowsDropdown}>
      <Dropdown
        items={question.matrixRows}
        onSelect={handleUpdate}
        value={selectedRow} />
    </div>
  );
};