import { useEffect, useRef } from 'react';
import { DownloadCloud } from 'react-feather';
import { qs, useDownloader } from '@utils';
import styles from './style/Download.css';

type Props = unknown;

export const Download = (props: Props) => {
  const params = qs.base64Decode<DecodedParams>(location.search);

  const transferring = useRef(false);
  const download = useDownloader();

  useEffect(() => {

    if (!transferring.current && params?.url) {
      transferring.current = true;
      download({
        url: params.url,
      });
    }

  }, [params?.url]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.row}>
            <DownloadCloud
              className={styles.icon}
              size={130}
              strokeWidth={1} />
          </div>
          <div className={styles.row}>
            <div className={styles.title}>{copy.title}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.msg}>{copy.msg}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.link}>{params?.filename}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

Download.displayName = 'Inbound.Download';

const copy = {
  msg: `If your download doesn't begin in a few seconds, click below.`,
  title: `Your download has started.`,
};

type DecodedParams = {
  filename: string;
  resource: string;
  url: string;
};