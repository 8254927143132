import { memo, useContext } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import * as consts from '@consts';
import { useAppReadyState } from '@containers/AppReadyState';
import { SurveyThemingContext } from '@containers/Branding/Context';
import { useSelectGroup } from '@containers/Store';
import { path } from '@utils';
import { useHasClientRole, useHasConsultantRole, useHasChatOnlyRole } from '@containers/User/hooks';
import * as Branding from '@/components/Branding';
import * as Whitelabel from '@/components/Whitelabel';
import { useNavbarLogo } from '@/components/Whitelabel/hooks';
import styles from './style/Navbar.css';

type Props = unknown;

export const Logo = memo((props: Props) => {

  const hasChatOnlyRole = useHasChatOnlyRole();

  if (hasChatOnlyRole) {
    return (
      <div className={styles.col}>
        <Brand />
      </div>
    );
  }

  return (
    <div className={styles.col}>
      <Link to={consts.pathname.Home}>
        <Brand />
      </Link>
    </div>
  );
});

Logo.displayName = 'Nav.Logo';

const Brand = (props: Props) => {
  const app = useAppReadyState();
  const ctx = useContext(SurveyThemingContext);
  const enterprise = useHasClientRole();
  const member = useHasConsultantRole();
  const group = useSelectGroup();
  const access = !!useRouteMatch(path.match.any([consts.path.Admin.Root, consts.path.Research.Root]));
  const branding = useNavbarLogo(group.branding?.logos);
  const whitelabel = useNavbarLogo(ctx?.theming?.data?.logos as Store.Group.Branding['logos']);

  if (app.hydrating || ctx?.theming?.loading) {
    return (
      <div className={styles.logo}>
        <div className={styles.loading}>
          <Skeleton
            animation="wave"
            height={40}
            variant="circular"
            width={40} />
        </div>
      </div>
    );
  }

  if (app.hydrated &&
    enterprise &&
    group.features.whitelabeling &&
    group.settings.whitelabeling &&
    branding?.url &&
    !access) {

    return (
      <Whitelabel.NavbarLogo
        classes={{
          root: styles.logo,
          landscape: styles.landscape,
        }}
        orientation={branding.orientation}
        url={branding.url} />
    );
  }

  if (app.hydrated && member && whitelabel?.url) {

    return (
      <Whitelabel.NavbarLogo
        classes={{
          root: styles.logo,
          landscape: styles.landscape,
        }}
        orientation={whitelabel.orientation}
        url={whitelabel.url} />
    );
  }

  return (
    <Branding.Logo.Responsive />
  );
};

Brand.displayName = 'Nav.Logo.Brand';