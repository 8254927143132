import { useContext, useMemo } from 'react';
import { BrandThemingContext } from '@containers/Branding/Context';
import { useSelectGroup } from '@containers/Store';
import * as chart from '@containers/SurveyResponses/utils';
import { BrandColorCategory, ColorPaletteType } from '@enums';
import * as color from '@utils/color';
import type { ExclusiveOptionsQuestion } from '@/types';

export const useOptionsColors = () => {
  const { query } = useContext(BrandThemingContext);
  const enabled = useThemingEnabled();

  const options = useMemo(() => {
    if (!enabled || !query.data?.theming) {
      return lookup[BrandColorCategory.Options];
    }

    const cat = query.data.theming.themes.find(x => x.categoryId === BrandColorCategory.Options);
    const pal = query.data.theming.palettes.find(x => x.id === cat.paletteId);

    if (pal.typeId === ColorPaletteType.Preset) {
      return lookup[BrandColorCategory.Options];
    }

    return pal.data.colors;
  }, [
    enabled,
    query.data,
  ]);

  return options;
};

const lookup = {
  [BrandColorCategory.Options]: chart.options.OptionsColors,
  [BrandColorCategory.Matrix]: chart.matrix.MatrixColors,
  [BrandColorCategory.Ranking]: {
    extendedStrong: chart.ranking.ExtendedStrongRankingColors,
    extendedWeak: chart.ranking.ExtendedWeakRankingColors,
    strong: chart.ranking.StrongRankingColors,
    weak: chart.ranking.WeakRankingColors,
  },
};

const createRankingColors = (colors: string[]) => {
  const mid = Math.ceil(colors.length / 2);

  const exstrong = colors.slice(0, mid);
  const exweak = colors.slice(mid).reverse();

  const truncs = Math.ceil(exstrong.length * 0.6);
  const truncw = Math.ceil(exweak.length * 0.6);

  const strong = pickDistributedColors(exstrong, truncs);
  const weak = pickDistributedColors(exweak, truncw);

  return {
    extendedStrong: exstrong,
    extendedWeak: exweak,
    weak,
    strong,
  };
};

export const useRankingColors = () => {
  const { query } = useContext(BrandThemingContext);
  const enabled = useThemingEnabled();

  const ranking = useMemo(() => {
    if (!enabled || !query.data?.theming) {
      return lookup[BrandColorCategory.Ranking];
    }

    const cat = query.data.theming.themes.find(x => x.categoryId === BrandColorCategory.Ranking);
    const pal = query.data.theming.palettes.find(x => x.id === cat.paletteId);

    if (pal.typeId === ColorPaletteType.Preset) {
      return lookup[BrandColorCategory.Ranking];
    }

    return createRankingColors(pal.data.colors);
  }, [
    enabled,
    query.data,
  ]);

  return ranking;
};

const createMatrixColors = (colors: string[]) => {
  let i = 0;
  const obj = {};

  while (i < 12) {
    i += 1;

    obj[i] = pickDistributedColors(colors, i);
  }

  return obj;
};

export const useMatrixColors = () => {
  const { query } = useContext(BrandThemingContext);
  const enabled = useThemingEnabled();

  const matrix = useMemo(() => {
    if (!enabled || !query.data?.theming) {
      return lookup[BrandColorCategory.Matrix];
    }
    const cat = query.data.theming.themes.find(x => x.categoryId === BrandColorCategory.Matrix);
    const pal = query.data.theming.palettes.find(x => x.id === cat.paletteId);

    if (pal.typeId === ColorPaletteType.Preset) {
      return lookup[BrandColorCategory.Matrix];
    }

    return createMatrixColors(pal.data.colors);
  }, [
    enabled,
    query.data,
  ]);

  return matrix;
};

export const useExclusiveOptionColors = (settings: ExclusiveOptionsQuestion.Settings) => {
  const colors = useOptionsColors();

  return (opt: ExclusiveOptionsQuestion.Option) => {
    return colors[settings.optionSections.find(s => s.identifier === opt.metadata.sectionId).ordinal - 1];
  };
};

const useThemingEnabled = () => {
  const group = useSelectGroup();

  const enabled = useMemo(() => {
    return group.features.whitelabeling
      && group.settings.whitelabeling;
  }, [
    group.features.whitelabeling,
    group.settings.whitelabeling,
  ]);

  return enabled;
};

function getColorIndices(start: number, end: number, num: number) {
  if (num < 2) return [start];

  const max = num - 1 > end
    ? end + 1
    : num;
  const step = (end - start) / (max - 1);
  const indices: number[] = [];

  for (let i = 0; i < max; i++) {
    /* @ts-ignore */
    const index = parseInt((start + (step * i)));
    indices.push(index);
  }

  return indices;
}

function pickDistributedColors(items: string[], num: number) {
  const end = items.length - 1;
  const indices = getColorIndices(0, end, num);

  return indices.map(x => items[x]);
}