import type { ErrorInfo } from 'react';
import { memo, Component } from 'react';
import { Tooltip } from '@/presentation/Tooltip';
import type { SurveyLogic } from '@/types/survey';
import { SurveyConditionType, utils as $enums } from '@enums';

type Props = {
  condition: SurveyLogic.SurveyCondition;
} & ChildrenProps;
type State = {
  error: Error;
};

export class ConditionErrorBoundary extends Component<Props, State>{
  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.log('Caught Condition Error');
    console.error(error, info);
  }

  render() {
    if (this.state.error) {
      return (
        <ConditionFallback
          condition={this.props.condition}
          error={this.state.error} />
      );
    }

    return this.props.children;
  }
}

type FProps = {
  error: Error;
} & Omit<Props, 'children'>;

const ConditionFallback = memo((props: FProps) => {
  let conditionText = `[Invalid condition]`;

  if (props.condition.conditionType === SurveyConditionType.Question) {
    conditionText = `[Invalid "${$enums.SurveyQuestionConditionType.getName(props.condition.data.type)}" condition]`;
  }

  const title = [props.error.message, props.error.stack].join('\n\n');

  return (
    <Tooltip title={title}>
      <span style={{ color: 'red', fontFamily: 'var(--font-bold)' }}>{conditionText}</span>
    </Tooltip>
  );
});