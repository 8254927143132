import { memo } from 'react';
import styles from './style/NetworkQualityLevel.css';

type NetworkQualityLevelProps = {
  borderColor?: string;
  qualityLevel: number;
};

const STEP = 3;

export const NetworkQualityLevel = memo(({ borderColor = 'var(--black)', qualityLevel }: NetworkQualityLevelProps) => {
  if (qualityLevel === undefined || qualityLevel === null) return null;

  return (
    <div className={styles.root}>
      {[0, 1, 2, 3, 4].map(level => (
        <div
          key={level}
          style={{
            height: `${STEP * (level + 1)}px`,
            background: qualityLevel > level ? '#0c0' : '#326932',
            border: `1px solid ${borderColor}`,
          }} />
      ))}
    </div>
  );
});

export default NetworkQualityLevel;