import { useMemo } from 'react';
import { Point } from '@nivo/line';

type Options = {
  activePoints: number[];
  activeSeries: number[];
};

const pointSize = 10;
const activePointSize = 13;
const inactivePointSize = 0;
const pointBorderWidth = 3;
const activePointBorderWidth = 4;
const inactivePointBorderWidth = 0;

export const usePointStyle = ({ activePoints, activeSeries }: Options) => {

  const normalStyle = useMemo(
    () => ({
      borderWidth: pointBorderWidth,
      opacity: 1,
      size: pointSize,
    }),
    []
  );
  const activeStyle = useMemo(
    () => ({
      borderWidth: activePointBorderWidth,
      opacity: 1,
      size: activePointSize,
    }),
    []
  );
  const inactiveStyle = useMemo(
    () => ({
      borderWidth: inactivePointBorderWidth,
      opacity: 0,
      size: inactivePointSize,
    }),
    []
  );

  return useMemo(() => {
    return (point: Point) => {
      if (activePoints.length === 0 && activeSeries.length == 0) return normalStyle;
      if (activePoints.includes(+point.id) ||
        (!activePoints.length && activeSeries.includes(+point.serieId))) return activeStyle;
      return inactiveStyle;
    };
  }, [
    normalStyle,
    activeStyle,
    inactiveStyle,
    activePoints,
    activeSeries,
  ]);
};