import { AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as api from '@api';
import { querykey } from '@consts';
import { Integrations } from '@api/interfaces';

type Data = Integrations.GetIntegrations.Response;

export function useFetchIntegrations(
  options?: UseQueryOptions<Data, AxiosResponse, Data>
) {

  return useQuery(querykey.Integrations.Get, () => {
    return api.integrations.getIntegrations();
  }, options);
}