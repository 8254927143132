import { useContext } from 'react';
import { CompletionMedicalStepperContext } from '@containers/ProjectOnboarding.Completion/Context';
import { Skip } from '@presentation';
import * as Layout from '@/components/MedicalProfile/Layout';
import styles from './style/Medical.css';

type Props = unknown;

export const Prompt = (props: Props) => {
  const ctx = {
    stepper: useContext(CompletionMedicalStepperContext),
  };

  return (
    <Layout.Screen>
      <div className={styles.prompt}>{copy.title}</div>
      <div className={styles.prompt}>{copy.message}</div>
      <Layout.Footer>
        <Layout.NextButton onClick={ctx.stepper.next}>
          Continue
        </Layout.NextButton>
        <Skip
          className={styles.skip}
          onClick={ctx.stepper.skip}>
          {`I'll do this later`}
        </Skip>
      </Layout.Footer>
    </Layout.Screen>
  );
};

Prompt.displayName = 'Medical.Prompt';

const copy = {
  message: `Want to improve your chances of being selected for future projects? Complete a couple of additional questions so that we can place you on future paid research opportunities!`,
  title: `Thank you, you're all set for this project!`,
};