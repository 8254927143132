import { path } from '@consts';
import { RouteAuth } from '@enums';
import { BasicRouter } from '@routes/Routers';
import * as Routing from '@routes/interfaces';
import { Notifications } from '@screens/Notifications';

export const routes: Routing.Route[] =  [
  {
    component: Notifications,
    path: path.Notifications.Root,
  },
];

export const router = BasicRouter(routes, {
  auth: RouteAuth.Authenticated,
  path: path.Notifications.Root,
});