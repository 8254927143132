import { useCallback } from 'react';
import { path } from '@consts';
import { useAppReadyState } from '@containers/AppReadyState';
import * as Topic from '@/types/topics';
import * as Tag from '@/components/Tag/Link';
import styles from './style/Topics.css';

type Props = {
  items: Topic.Base[];
};

export const Topics = (props: Props) => {
  const app = useAppReadyState();

  const getLocation = useCallback((slug: string) => {
    const location = app.authenticated
        ? getTopicLocation(slug)
        : null;

    return location;
  }, [app.authenticated]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.label}>Topics</div>
          {props.items.map((x, i) =>
            <Tag.Link
              className={styles.tag}
              key={x.id}
              to={getLocation(x.slug)}>
              {x.name}
            </Tag.Link>)}
        </div>
      </div>
    </div>
  );
};

Topics.displayName = 'Post.Article.Topics';

function getTopicLocation(slug: string) {
  return `${path.Topics.Root}/${slug}`;
}