import { useCallback, useContext, useMemo } from 'react';
import { ConferenceDetailsEditor, ConferenceDetailsEditorContainer, type ConferenceDetailsEditorOnChange, Toolbar, useConferenceDetailsEditor } from '@/components/Conference';
import { Input } from '@/components/Input';
import { GroupTagsSelector } from '@/components/Group.Tags';
import { Switch } from '@/components/Switch';
import { SearchType, utils } from '@/enums';
import type { Remirror } from '@/types';
import * as $api from '@/services/api';
import { SelectUnderlined, fromEnum, type OptionsMap } from '$admin/components/Select';
import { SelectLanguage } from '$admin/Project.Creation/Field.Setup.Language';
import * as $conference from '$admin/containers/Call/utils';
import { SettingsContext } from './Context';
import type { SetConferenceValue } from './interfaces';
import styles from './style/ConferenceSettings.css';

type Props = unknown;

export const OnPlatform = (props: Props) => {
  const ctx = {
    settings: useContext(SettingsContext),
  };

  const state = ctx.settings;

  const setValue = useCallback((data: SetConferenceValue.Params) => {
    ctx.settings.setValue(form => ({
      ...form,
      conference: {
        clientNotes: data.clientNotes ?? form.conference.clientNotes,
        conferenceDetails: data.conferenceDetails ?? form.conference.conferenceDetails,
        conferenceDialIn: data.conferenceDialIn ?? form.conference.conferenceDialIn,
        conferenceLink: data.conferenceLink ?? form.conference.conferenceLink,
        conferenceMediaType: data.conferenceMediaType ?? form.conference.conferenceMediaType,
        conferencePlatform: data.conferencePlatform ?? form.conference.conferencePlatform,
        conferenceType: data.conferenceType ?? form.conference.conferenceType,
        dialInValid: data.dialInValid ?? form.conference.dialInValid,
        defaultDuration: data.defaultDuration ?? form.conference.defaultDuration,
        title: data.title ?? form.conference.title,
        languages: data.languages ?? form.conference.languages,
        videoShareTypeId: data.videoShareTypeId ?? form.conference.videoShareTypeId,
        screenShareTypeId: data.screenShareTypeId ?? form.conference.screenShareTypeId,
        transcriptionQa: data.transcriptionQa ?? form.conference.transcriptionQa,
      },
    }));
  }, [ctx.settings]);

  const handleChangeNotes: ConferenceDetailsEditorOnChange = useCallback(params => {
    if (!params.tr?.docChanged) return;

    const value = params.state.doc.toJSON() as Remirror.RootNode;

    setValue({ clientNotes: value });

  }, [setValue]);

  const notes = useConferenceDetailsEditor({
    initialState: state.conference.clientNotes,
    placeholder: `Enter client notes`,
  });

  const options = useMemo(() => {
    const types = $conference.getConferenceTypes({
      conferencePlatform: state.conference.conferencePlatform,
    });

    return types.reduce<OptionsMap>((acc, x) => ({
      ...acc,
      [x]: utils.ConferenceType.getName(x),
    }), {});
  }, [state.conference.conferencePlatform]);

  return (
    <div className={styles.grid}>
      <div className={styles.full}>
        <div className={styles.label}>External Event Title <span className={styles.flag}>{copy.titleRestricted}</span></div>
        <Input
          onChange={e => setValue($conference.conferenceTitleChanged(e.target.value, state.conference))}
          value={state.conference.title} />
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Platform</div>
        <SelectUnderlined
          onChange={e => setValue($conference.platformChanged(+e.target.value, state.conference))}
          options={fromEnum(utils.ConferencePlatform)}
          value={state.conference.conferencePlatform} />
      </div>

      <div className={styles.col}>
        <div className={styles.label}>Conference Type</div>
        <SelectUnderlined
          onChange={e => setValue($conference.conferenceTypeChange(+e.target.value, state.conference))}
          options={options}
          value={state.conference.conferenceType} />
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Video Sharing</div>
        <SelectUnderlined
          onChange={e => setValue($conference.conferenceVideoShareChanged(+e.target.value, state.conference))}
          options={fromEnum(utils.ConferenceFeatureShareType)}
          value={state.conference.videoShareTypeId} />
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Screen Sharing</div>
        <SelectUnderlined
          onChange={e => setValue($conference.conferenceScreenShareChanged(+e.target.value, state.conference))}
          options={fromEnum(utils.ConferenceFeatureShareType)}
          value={state.conference.screenShareTypeId} />
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Primary Transcription Language</div>
        <SelectLanguage
          onChange={vals => setValue($conference.conferenceLanguagesChanged(vals, state.conference))}
          defaultValues={state.conference.languages.map(l => l.id)} />
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Enhanced Human Transcription</div>
        <Switch
          active={state.conference.transcriptionQa}
          onClick={value => setValue({ transcriptionQa: value })} />
      </div>

      {$conference.showConferenceMediaType(state.conference) &&
        <div className={styles.col}>
          <div className={styles.label}>Media Type</div>
          <SelectUnderlined
            onChange={e => setValue({ conferenceMediaType: +e.target.value })}
            options={fromEnum(utils.ConferenceMediaType)}
            value={state.conference.conferenceMediaType} />
        </div>}

      <div className={styles.full}>
        <div className={styles.label}>Client Notes <span className={styles.flag}>{copy.restricted}</span></div>
        <ConferenceDetailsEditorContainer
          onChange={handleChangeNotes}
          editable={true}
          manager={notes.manager}
          state={notes.state}>
          <Toolbar className={styles.toolbar} />
          <ConferenceDetailsEditor />
        </ConferenceDetailsEditorContainer>
      </div>

      <div className={styles.full}>
        <div className={styles.label}>Conference Tags <span className={styles.flag}>{copy.internal}</span></div>
        <GroupTagsSelector
          queryKeyIdentifier='call'
          value={ctx.settings.tags}
          onChange={tags => {
            ctx.settings.setValue(form => ({
              ...form,
              tags: tags.map((t, i) => ({
                // eslint-disable-next-line no-underscore-dangle
                id: t.__isNew__ ? -1 * (i + 1) : t.id,
                // eslint-disable-next-line no-underscore-dangle
                name: t.__isNew__ ? t.label : t.name,
              })),
            }));
          }}
          fetcher={(value: string) => {
            return $api.search.getAutocompleteResults<SearchType.GroupTag>({
              type: SearchType.GroupTag,
              text: value,
              ignoreList: [],
            }).then(result => result.map(i => ({ id: i.id, name: i.name })));
          }}
          menuPlacement="top"
          maxMenuHeight={250} />
      </div>
    </div>
  );
};

OnPlatform.displayName = 'ConferenceSettings.Form.OnPlatform';

const copy = {
  titleRestricted: `(Displayed Only In Client & Trinity Invitations)`,
  restricted: `(Displayed Only In Client Invitations)`,
  internal: `(Not Displayed In Invitations)`,
};