import { useContext } from 'react';
import { OnboardingChecklistContainer } from '@/components/OnboardingChecklist/OnboardingChecklist.Container';
import { OnboardingChecklistContext } from '@/components/OnboardingChecklist/Context';

type Props = {
  children: React.ReactNode;
};

export const Onboarding = (props: Props) => {
  return (
    <OnboardingChecklistContainer>
      <OnboardingContent>
        {props.children}
      </OnboardingContent>
    </OnboardingChecklistContainer>
  );
};

Onboarding.displayName = 'Main.Member.Onboarding';

const OnboardingContent = (props: Props) => {
  const ctx = useContext(OnboardingChecklistContext);

  return !ctx.loading && !ctx.remaining.length
    ? null
    : props.children as JSX.Element;
};

OnboardingContent.displayName = 'Main.Member.Onboarding.Content';