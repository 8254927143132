import { Fragment } from 'react';
import type * as API from '@api/interfaces';
import { cx } from '@/utils';
import { Item } from './Creator.Item';
import styles from './style/Creator.css';

type Props = {
  classes?: {
    items?: string;
    root?:  string;
  };
  name: string;
} & Pick<API.Discovery.FetchFeaturedCreators.Response, 'items'>;

export const Row = (props: Props) => {
  return (
    <div className={props.classes.root}>
      <div className={styles.header}>{props.name}</div>
      <div className={cx(styles.items, props.classes.items)}>
        {props.items.map(x =>
          <Item
            item={x}
            key={x.id} />)}

        {props.items.length === 2 &&
          <Fragment>
            <div className={cx(styles.wrap, styles.phantom)} />
            <div className={cx(styles.wrap, styles.phantom)} />
          </Fragment>}

      </div>
    </div>
  );
};

const defaultProps = {
  classes: {},
};

Row.defaultProps = defaultProps;
Row.displayName = 'Creator.Row';