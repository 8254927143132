import { GroupTag, GroupTags } from '@presentation/GroupTag';
import { useGroupTags } from './hooks';
import { ListProps } from './interfaces';
import styles from './style.css';

export default function List({ selected, onClick, onRemove }: ListProps) {
  const { tags } = useGroupTags();

  if (!tags.length) {
    return (
      <div className={styles.tags}>
        <div className={styles.empty}>No tags added.</div>
      </div>
    );
  }

  return (
    <GroupTags className={styles.tags}>
      {tags.map(t => (
        <GroupTag
          key={t.id}
          selected={selected}
          onClick={onClick ? () => onClick(t) : null}
          onRemove={() => onRemove?.(t)}>
          {t.name}
        </GroupTag>))}
    </GroupTags>
  );
}

export { List };

