import type { AxiosResponse } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useDownloader } from '@utils/hooks';
import type { UseDownloaderOptions } from '@containers/QueryDownloader';
import { useQueryDownloader } from '@containers/QueryDownloader';
import * as api from '@api';
import type { ProjectReportType, ProjectReportDataType, HybridSurveyResponseUserId } from '@/types';
import type { ExportRecruitmentGrid } from '@/services/api/interfaces/projects.reports';

type Data = {
  blob: Blob;
  filename: string;
};
type QueryKey<T = Params> = [string, T];

type Params = {
  format: ProjectReportType;
  isParentReport: boolean;
  projectId: number;
  projectParentId: number;
  filteredUsers?: HybridSurveyResponseUserId[];
  surveyId?: number;
};

export function useDownloadProjectReport(data: Params, options?: UseQueryOptions<Data, AxiosResponse, Data, QueryKey>) {

  const download = useDownloader();

  return useQuery<Data, AxiosResponse, Data, QueryKey>(['download-project', data], ({ queryKey }) => {

    const {
      format,
      isParentReport,
      projectId,
      projectParentId,
    } = queryKey[1];

    if (isParentReport) {
      return api.projects.exportProjectParentData({
        format,
        projectParentId,
        userIds: data.filteredUsers,
      });
    } else {
      return api.projects.exportProjectData({
        format,
        projectId,
        userIds: data.filteredUsers,
      });
    }

  }, {
    enabled: false,
    retry: 1,
    ...options,
    onSuccess: res => {
      download({
        blob: res.blob,
        filename: res.filename,
      });
      options?.onSuccess?.(res);
    },
  });
}

type AsyncData = {
  key: string;
};

export function useDownloadProjectReportAsync(data: Params, options?: UseQueryOptions<AsyncData, AxiosResponse, AsyncData, QueryKey>) {
  return useQuery<AsyncData, AxiosResponse, AsyncData, QueryKey>(['download-project-async', data], ({ queryKey }) => {
    const {
      format,
      isParentReport,
      projectId,
      projectParentId,
      surveyId,
    } = queryKey[1];

    if (isParentReport) {
      return api.projects.exportProjectParentDataAsync({
        format,
        projectParentId,
        userIds: data.filteredUsers,
        surveyId,
      });
    } else {
      return api.projects.exportProjectDataAsync({
        format,
        projectId,
        userIds: data.filteredUsers,
        surveyId,
      });
    }
  }, {
    enabled: false,
    retry: 1,
    ...options,
  });
}

type ProjectDataParams = {
  dataType: ProjectReportDataType;
} & Params;

export function useDownloadProjectTypeReport(data: ProjectDataParams, options?: UseQueryOptions<AsyncData, AxiosResponse, AsyncData, QueryKey<ProjectDataParams>>) {
  return useQueryDownloader<AsyncData, AxiosResponse, AsyncData, QueryKey<ProjectDataParams>>({
    queryKey: ['download-project-data-type', data],
    queryFn: ({ queryKey }) => {
      const {
        format,
        isParentReport,
        projectId,
        projectParentId,
        dataType,
        surveyId,
      } = queryKey[1];

      if (isParentReport) {
        return api.projects.exportProjectParentDataType({
          format,
          projectParentId,
          dataType,
          userIds: data.filteredUsers,
          surveyId,
        });
      } else {
        return api.projects.exportProjectDataType({
          format,
          projectId,
          dataType,
          userIds: data.filteredUsers,
          surveyId,
        });
      }
    },
    retry: 1,
    ...options,
    downloaderOptions: {
      onResponse: res => ({
        type: `websocket`,
        value: res.key,
      }),
    },
  });
}

type RecruitmentGridParams = {
  filename: string;
} & IProjectId
  & Partial<ISurveyId>;

type RecruitmentGridOptions =
  UseDownloaderOptions<
    ExportRecruitmentGrid.Response,
    AxiosResponse,
    ExportRecruitmentGrid.Response,
    readonly [string, RecruitmentGridParams]>;

export const useDownloadRecruitmentGrid = (props: RecruitmentGridParams, options: RecruitmentGridOptions = {}) => {
  return useQueryDownloader({
    queryKey: [
      `get:projects/export/recruitment-grids`,
      props,
    ],
    queryFn: ({ queryKey }) => {
      const [_, { filename, projectId, surveyId }] = queryKey;
      return api.projects.exportRecruitmentGrid({
        filename,
        projectId,
        surveyId,
      });
    },
    meta: {
      downloader: true,
    },
    downloaderOptions: {
      onResponse: res => ({
        filename: res.name,
        type: 'blob',
        value: res.file,
      }),
    },
    ...options,
  });
};

export default useDownloadProjectReport;