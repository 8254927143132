import qs from 'query-string';
import http from '@services/http';
import { transformDates } from '@services/http/transformers';
import type { Leads } from './interfaces';

export const fetchLeads = async (data: Leads.FetchLeads.Request) => {
  return http.get<Leads.FetchLeads.Response>(`/admin/leads?${qs.stringify(data)}`, { transformResponse: transformDates });
};

export const fetchLeadsCSV = async (data: Leads.FetchLeadsCSV.Request) => {
  return http.download(`/admin/leads/csv?${qs.stringify(data)}`);
};

export const fetchLead = async (data: Leads.FetchLead.Request) => {
  return http.get<Leads.FetchLead.Response>(`/admin/leads/${data.leadId}`, { transformResponse: transformDates });
};

export const updateLead = async (data: Leads.UpdateLead.Request) => {
  return http.patch<Leads.UpdateLead.Response>(`/admin/leads`, data, { transformResponse: transformDates });
};

export const saveLeadTags = async ({ leadId, tags }: Leads.SaveLeadTags.Request) => {
  return http.post<Leads.SaveLeadTags.Response>(`/admin/leads/${leadId}/tags`, tags, { transformResponse: transformDates });
};

export const updateLeadMedical = async ({ leadId, details }: Leads.UpdateLeadMedical.Request) => {
  return http.post<Leads.UpdateLeadMedical.Response>(`/admin/leads/${leadId}/medical`, details, { transformResponse: transformDates });
};

export const deleteLeads = async ({ leadIds }: Leads.DeleteLeads.Request) => {
  return http.post('/admin/leads', leadIds);
};

export const uploadLeads = async ({ file }: Leads.UploadLeads.Request) => {
  const formData = new FormData();
  formData.append('leadsFile', file, file.name);
  await http.put(`/admin/leads`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};