import ppt from 'static/images/website/collaboration-tools/icon-ppt.svg?url';
import sheet from 'static/images/website/collaboration-tools/icon-csv.svg?url';
import doc from 'static/images/website/collaboration-tools/icon-doc.svg?url';
import video from 'static/images/website/collaboration-tools/icon-mov.svg?url';
import headphones from 'static/images/website/collaboration-tools/icon-mp3.svg?url';
import folder from 'static/images/website/collaboration-tools/icon-folder.svg?url';
import styles from './style/StorageTools.css';

type Props = unknown;

export const StorageTools = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.content}>
          <div className={styles.main}>
            <div className={styles.items}>
              {items.map(x =>
                <div
                  className={styles.item}
                  key={x.text}>
                  <img
                    alt={x.text.toLowerCase()}
                    className={styles.icon}
                    src={x.src} />
                  <div className={styles.text}>{x.text}</div>
                </div>)}
            </div>

            <div className={styles.info}>
              <h2 className={styles.title}>Upload key research files and manage team access</h2>
              <div className={styles.subtitle}>Utilize our file storage tools to share research documents across teams and to centralize the insights of your organization.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

StorageTools.displayName = 'CollaborationTools.StorageTools';

const items = [{
  src: ppt,
  text: `Presentations`,
}, {
  src: sheet,
  text: `Spreadsheets`,
}, {
  src: doc,
  text: `Documents`,
}, {
  src: video,
  text: `Videos`,
}, {
  src: headphones,
  text: `Recordings`,
}, {
  src: folder,
  text: `Folders`,
}];