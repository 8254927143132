import { } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { ConnectedAccountType, ConnectedAccountProvider } from '@enums';
import { useGoogleDrivePicker as useDrivePicker } from '@utils/hooks/useGoogleDrivePicker';
import * as services from '@api';

const selector = (state: Store.State) => state.user.connected.find(c => c.typeId == ConnectedAccountType.Files && c.providerId == ConnectedAccountProvider.Google);

type Props = {
  onSelect: (files: File[]) => void;
};

export const useGoogleDriveUpload = ({ onSelect }: Props) => {
  const account = useSelector(selector);

  const { data, isLoading } = useQuery(['connected-account-token', account], () => {
    if (account) {
      return services.users.getConnectedAccountToken(account);
    } else {
      return null;
    }
  }, { refetchOnWindowFocus: false, refetchOnMount: false });

  const { showPicker, isLoading: isPickerLoading } = useDrivePicker({
    oauthToken: data?.accessToken,
    onSelect: selected => {
      const file = selected[0];
      downloadFile({ file, accessToken: data.accessToken, onDownload: f => onSelect([f]) });
    },
  });

  return { openPicker: showPicker, loading: isLoading || isPickerLoading };
};

type DownloadParams = {
  accessToken: string;
  file: google.picker.DocumentObject;
  onDownload: (file: File) => void;
};

const DefaultMimeMapValue: MimeMapValue = {
  mimeType: 'application/pdf',
  fileExtention: '.pdf',
};

type MimeMapValue = {
  mimeType: string;
  fileExtention: string;
};

//TODO: fill these in with actual open format file types for docs, spreadsheets, etc.
const MimeMapping: Record<string, MimeMapValue> = {
  'application/vnd.google-apps.drawing': { mimeType: 'image/png', fileExtention: '.png' },
};

const downloadFile = ({ file, accessToken, onDownload }: DownloadParams) => {
  if (file.type == google.picker.Type.DOCUMENT) {
    const xhr = new XMLHttpRequest();
    const mimeValue = MimeMapping[file.mimeType] ?? DefaultMimeMapValue;
    xhr.open('GET', 'https://www.googleapis.com/drive/v3/files/' + file.id + '/export?alt=media&mimeType=' + encodeURIComponent(mimeValue.mimeType), true);
    xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      const downloadedFile = new File([xhr.response as Blob], file.name + mimeValue.fileExtention);
      onDownload(downloadedFile);
    };
    xhr.send();
  } else {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', 'https://www.googleapis.com/drive/v3/files/' + file.id + '?alt=media', true);
    xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      const downloadedFile = new File([xhr.response as Blob], file.name);
      onDownload(downloadedFile);
    };
    xhr.send();
  }
};