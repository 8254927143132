import { useMemo } from 'react';
import { cx } from '@utils';
import type { ConjointAnalysisQuestion } from '@/types';
import Attribute from './ConjointAnalysis.Chart.Attribute';
import styles from './style/ConjointAnalysis.Chart.css';

type Props = {
  attributes: ConjointAnalysisQuestion.Attribute[];
  className?: string;
  levelColors: { [levelId: number]: string };
  onLevelClick: (levelId: number) => () => unknown;
  responses: ConjointAnalysisQuestion.Aggregate.Data;
};

export const ConjointAnalysisChart = ({
  attributes,
  className,
  levelColors,
  onLevelClick,
  responses,
}: Props) => {

  const maxUtility = useMemo(() => {
    return attributes.reduce((acc, attr) => {
      return attr.levels.reduce((acc2, l) => {
        const v = responses.levels[l.base.id].utility;
        return v > acc2 ? v : acc2;
      }, acc);
    }, 0);
  }, [
    attributes,
    responses.levels,
  ]);

  return (
    <div className={cx(styles.root, className)}>
      {
        attributes.map(attribute => (
          <Attribute
            key={attribute.base.id}
            className={styles.attribute}
            levelColors={levelColors}
            maxUtility={maxUtility}
            onLevelClick={onLevelClick}
            attribute={attribute}
            responses={responses} />
        ))
      }
    </div>
  );
};

export default ConjointAnalysisChart;