import { useContext } from 'react';
import VoiceOutlinedIcon from '@mui/icons-material/RecordVoiceOverOutlined';
import { EditingToolPaneContext } from '@containers/Transcript/context';
import { EditSpeakers } from '@/components/Transcript';
import { Header } from './Tool.Header';
import styles from './style/Tool.css';

type Props = unknown;

export const Pane = (props: Props) => {
  const pane = useContext(EditingToolPaneContext);

  const { active, toggle } = pane.speakers;

  if (!active) return null;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Header title='Edit Speakers' onClose={toggle} />
        <div className={styles.main}>
          <EditSpeakers />
        </div>
      </div>
    </div>
  );
};

Pane.displayName = 'Tool.Speakers.Pane';

export const Button = (props: Props) => {
  const pane = useContext(EditingToolPaneContext);

  return (
    <button
      className={styles.action}
      onClick={pane.speakers.toggle}>
      <VoiceOutlinedIcon />
      <span>Edit Speakers</span>
    </button>
  );
};

Button.displayName = 'Tool.Speakers.Button';