import { useCallback, useMemo } from 'react';
import { useCookie } from '@utils';
import { cookies } from '@consts';

export type AudioCuePreferences = {
  playWaitingRoomEntry: boolean;
  playNewMessageSound: boolean;
  disableAudioFilter: boolean;
};

const defaultPreferences: AudioCuePreferences = {
  playWaitingRoomEntry: true,
  playNewMessageSound: false,
  disableAudioFilter: false,
};

export const useConferenceAudioCuePreferences = () => {
  const [cookieVal, setCookie] = useCookie(cookies.conferenceAudioCuePreferences, JSON.stringify(defaultPreferences), { watchForChanges: true });

  const preferences = useMemo(() => cookieVal ? JSON.parse(cookieVal) as AudioCuePreferences : defaultPreferences, [cookieVal]);

  const setPreferences = useCallback((partialPref: Partial<AudioCuePreferences>) => {
    const unified = Object.assign({}, preferences, partialPref);
    setCookie({
      value: JSON.stringify(unified),
      expires: cookies.MAX_COOKIE_EXPIRATION,
    });
  }, [setCookie, preferences]);

  return [preferences, setPreferences] as const;
};