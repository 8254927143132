import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { querykey } from '@consts';

export const useInvalidateProjectState = () => {

  const queryClient = useQueryClient();

  return useCallback((projectId: number) => {
    return queryClient.invalidateQueries({
      queryKey: querykey.Projects.Project.Get({ projectId }),
    });
  }, [queryClient]);
};