import { useCallback } from 'react';
import { RouteKey } from '../interfaces';
import { useProjectOrLatestChild } from './useProjectState';

export const useTrackTabChange = () => {

  const project = useProjectOrLatestChild();

  const track = useCallback((route: RouteKey) => {
    const data = {
      project: {
        id: project.id,
        name: project.name,
      },
    };
    switch (route) {
      case RouteKey.Pipeline: {
        break;
      }
      case RouteKey.SurveyResponses: {
        break;
      }
      case RouteKey.Calls: {
        break;
      }
      case RouteKey.Summary: {
        break;
      }
    }
  }, [
    project.id,
    project.name,
  ]);

  return track;
};

export default useTrackTabChange;