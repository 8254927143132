import { useCallback } from 'react';
import * as api from '@api';
import { useDownloader } from '@utils/hooks';
import Toast from '@/components/Toast';

type Props =
  IWorkspaceId &
  IWorkspaceFileId;

export const useDownloadFile = (props: Props) => {

  const download = useDownloader();

  const handleDownload = useCallback(() => {
    return api.workspaces.file.downloadFile({
      fileId: props.fileId,
      workspaceId: props.workspaceId,
    }).then(({ file, url }) => {
      download({
        url,
        filename: file.name,
      });
    }).catch(e => {
      Toast.error({
        title: 'Error',
        body: `We're sorry, there was an issue with your request`,
      });
      throw e;
    });
  }, [
    download,
    props.workspaceId,
    props.fileId,
  ]);

  return handleDownload;
};