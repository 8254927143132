export const pluralize = (word: string, count: number) => {
  return count == 1
    ? word
    : `${word}s`;
};

export const trunc = (text: string, max = Infinity) => {
  if (!text) return '';

  return text.length > max
    ? text.substring(0, max).trimEnd() + '...'
    : text;
};

export const toAlphabetic = (num: number) => {
  return String.fromCharCode(num + 64);
};