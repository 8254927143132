import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import * as consts from '@consts';
import * as utils from '@utils';

const mapState = (state: Store.State) => ({
  calls: state.calls,
  user: state.user,
});

type Params = {
  callId: string;
  projectId: string;
};

type Props =
    RouteComponentProps<Params>
  & ReturnType<typeof mapState>;

class InboundCalendarRouter extends Component<Props> {
  componentDidMount() {
    if (this.props.user) {
      this.handleRedirect();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.user.state.initialized && this.props.user.state.initialized) {
      this.handleRedirect();
    }
  }

  handleRedirect = () => {
    const { params } = this.props.match;
    const queryParams = new URLSearchParams(this.props.location.search);
    const scheduleeId = +queryParams.get('scheduleeId');
    const schedulerId = +queryParams.get('schedulerId');

    const to = utils.getLocationFor.scheduling.selecting({
      callId: +params.callId,
      projectId: +params.projectId,
      scheduleeId,
      schedulerId,
    });

    this.props.history.replace({
      ...to,
      search: `?from=${consts.pathname.HOME}`,
    });
  }

  render() {
    return (
      <div>stop</div>
    );
  }
}

export const Confirmation = connect(mapState)(InboundCalendarRouter);