import { Transaction } from '@remirror/core';
import { Decoration, DecorationSet } from '@remirror/pm/view';
import { ApplyProps, Position } from './interfaces.extension.karaoke';

export class KaraokeState {

  position: Position = null;

  decorationSet = DecorationSet.empty;

  createDecorations(tr: Transaction, position: Position): DecorationSet {

    if (position) {
      const decoration = Decoration.inline(position.from, position.to, {
        style: 'color: #28AAE1',
      });
      return DecorationSet.create(tr.doc, [decoration]);
    } else {
      return DecorationSet.empty;
    }

  }

  apply({ tr, action }: ApplyProps): this {

    if (!action && !tr.docChanged) {
      return this;
    }

    switch (action?.type) {
      case 'update-karaoke-position':
        this.position = action.position;
        break;
    }

    if (action) {
      this.decorationSet = this.createDecorations(tr, this.position);
    } else {
      this.decorationSet = this.decorationSet.map(tr.mapping, tr.doc);
    }

    return this;
  }
}