import axios from 'axios';
import http, { xhr } from '@services/http';
import type { Conferences as C } from './interfaces';

export function getNotes(data: C.GetConferenceNotes): Promise<C.GetConferenceNotesResponse> {
  return http.get(`/conferences/${data.callId}/notes`);
}

export function updateNotes(data: C.UpdateConferenceNotes): Promise<C.UpdateConferenceNotesResponse> {
  const { callId, ...rest } = data;
  return http.put(`/conferences/${callId}/notes`, rest);
}

export function createChatToken(data: C.CreateChatToken): Promise<C.CreateChatTokenResponse> {
  return xhr.post(`/conferences/chat-token`, data).then(r => r.data);
}

export function getConferenceEntry({ conferenceIdentifier }: C.GetConferenceEntry.Request): Promise<C.GetConferenceEntry.Response> {
  return xhr.get(`/conferences/${conferenceIdentifier}/entry`).then(({ data }) => data);
}

export function getConferenceDetails({ conferenceId }: C.GetConferenceDetails.Request): Promise<C.GetConferenceDetails.Response> {
  return http.get(`/conferences/${conferenceId}/details`);
}

export function getAvailableTags(data: C.Tags.GetAvailableTags.Request) {
  return http.get<C.Tags.GetAvailableTags.Response>(`/conferences/${data.conferenceIdentifier}/tags`);
}

export function saveTag({ conferenceIdentifier, ...data }: C.Tags.SaveTag.Request): Promise<C.Tags.SaveTag.Response> {
  return http.post(`/conferences/${conferenceIdentifier}/tags`, data);
}