import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as api from '@api';
import ActivityIndicator from '@/components/ActivityIndicator';
import { EntityTypesContext } from './Context';

export const EntityTypesContainer = (props: ChildrenProps) => {
  const entities = useQuery({
    queryKey: [`get:search/transcriptions/entities`],
    queryFn: api.search.transcriptions.fetchEntityTypes,
    refetchOnWindowFocus: false,
  });

  if (entities.isLoading) {
    return <ActivityIndicator />;
  }

  return (
    <EntityTypesContext.Provider value={entities.data}>
      {props.children}
    </EntityTypesContext.Provider>
  );
};