import { Link } from 'react-router-dom';
import { cx, getLocationFor } from '@utils';
import { ProjectBase } from '@/types';
import { Button } from '@/components/Button';
import { ProjectIcon } from '@/components/icons';
import styles from './style/Invitations.Item.css';

type Props = {
  className?: string;
  item: ProjectBase;
};

export const ProjectInvitation = ({ className, item }: Props) => {

  const location = getLocationFor.onboarding.project({ projectId: item.id });

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.wrap}>
        <div className={styles.content}>
          <ProjectIcon
            projectType={item.projectType} />
          <div className={styles.details}>
            <Link to={location} className={styles.title}>
              {item.name}
            </Link>
            <div className={styles.description}>
              A client has selected you to participate in this project!
            </div>
          </div>
        </div>
        <Button.Secondary
          className={styles.btn}
          variant="brick"
          to={location}>
          Review
        </Button.Secondary>
      </div>
    </div>
  );
};

export default ProjectInvitation;