import type { SurveyBuilder } from '../interfaces';
import {
  committedReducer,
  draftReducer,
  editingReducer,
  savedSurveyReducer,
  surveyReducer,
} from './state';

export function surveyStateReducer(oldState: SurveyBuilder.State, action: SurveyBuilder.Actions): SurveyBuilder.State {

  const newState: SurveyBuilder.State = action.type === 'replace-state' ? action.state : {
    editing: editingReducer(oldState, action),
    committed: committedReducer(oldState, action),
    savedSurvey: savedSurveyReducer(oldState, action),
    survey: surveyReducer(oldState, action),
    draft: draftReducer(oldState, action),
  };

  logAction(oldState, newState, action);

  return newState;
}

function logAction(oldState: SurveyBuilder.State, newState: SurveyBuilder.State, action: SurveyBuilder.Actions) {
  if (process.env.VANCERY_ENV !== 'production') {
    console.groupCollapsed('Survey Builder Action', action);
    console.log('Old State', oldState);
    console.log('New State', newState);
    console.groupEnd();
  }
}