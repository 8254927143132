import { useCallback, useMemo, useState } from 'react';
import { useAudioPlayerRef, useAudioPlayerState } from '../Context';

export const useAudioPlayerScan = () => {

  const [playerRef] = useAudioPlayerRef();

  const [scanPosition, setScanPositionPosition] = useState<number>(null);
  const [{ currentTime }, dispatch] = useAudioPlayerState();

  const seek = useCallback((p: number) => {
    // pre-empt player event to keep state in sync
    dispatch({
      time: p,
      type: 'time-change',
    });
    playerRef.currentTime = p;
    setScanPositionPosition(null);
  }, [dispatch, playerRef]);

  const scan = useCallback((p: number) => {
    setScanPositionPosition(p);
  }, []);

  const position = useMemo(() => {
    return scanPosition ?? currentTime;
  }, [
    currentTime,
    scanPosition,
  ]);

  return {
    scan,
    seek,
    position,
  } as const;
};
