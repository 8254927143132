export enum ExternalSourcingVendor {
  AlphaSights = 1,
  ColemanResearch = 2,
  GLG = 3,
  Toluna = 4,
  Trinity = 5,
  Dynata = 6,
  M3 = 7,
  MedSurvey = 8,
  Reckner = 9,
  SHG = 10,
  Medefield = 11,
  AllGlobal = 12,
  AtlasPrimary = 13,
  Sago = 14,
}