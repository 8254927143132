import { memo, useCallback } from 'react';
import { cx } from '@utils';
import { ProjectMetrics } from '@/types';
import styles from './style/Table.css';

type Props = {
  metrics: ProjectMetrics.Metrics;
};

export const SurveyProgressTable = memo(({ metrics }: Props) => {

  const renderRow = useCallback((title: string, value: number) => {
    return (
      <tr>
        <td>{title}</td>
        <td>{value}</td>
      </tr>
    );
  }, []);

  return (
    <table className={cx(styles.table, styles.progress)}>
      <thead>
        <tr>
          <th>Status</th>
          <th>Total</th>
        </tr>
      </thead>

      <tbody>
        {renderRow('Qualified', metrics.surveys.activeCount)}
        {renderRow('In Progress', metrics.surveys.pendingCount)}
        {renderRow('Disqualified', metrics.surveys.disqualifiedCount)}
        {renderRow('Over Quota', metrics.surveys.overQuotaCount)}
        {renderRow('Rejected', metrics.surveys.rejectedCount)}
      </tbody>
    </table>
  );
});

export default SurveyProgressTable;