import { Chip } from '@presentation/Chip';

type Props = {
  children: string | number;
  className?: string;
};

export const ReadOnly = (props: Props) => {
  return (
    <Chip
      className={props.className}
      color="basic"
      readonly>
      <Chip.Body.Basic>
        {props.children}
      </Chip.Body.Basic>
    </Chip>
  );
};

ReadOnly.displayName = 'Tag.ReadOnly';