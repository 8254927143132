import { useEffect, useMemo } from 'react';
import { Redirect, generatePath, useHistory, useParams } from 'react-router-dom';
import { path } from '@consts';
import {
  useRegisterChatPageContext,
  ChatContextType,
  type BrandInsightsPageContext,
} from '@/brand-insights';
import { GroupFolderTagsContainer } from '@/containers/GroupTags';
import { WorkspaceBreadcrumbsContext } from '@/containers/WorkspaceObject/Context';
import { useFetchFolder } from '@utils/api';
import { FolderLoadingContext, FolderStateContext } from './Context';

type Props = ChildrenProps;

export const WorkspaceFolderContainer = (props: Props) => {

  const params = useFolderParams();
  const folderId = params.folderId;
  const history = useHistory();

  const query = useFetchFolder({
    folderId,
  }, {
    onError: error => {
      if (error.status === 404) {
        history.replace(path.Workspaces.FolderNotFound);
      }
    },
  });

  const state = {
    capabilities: query.data?.capabilities,
    features: query.data?.features,
    meta: query.data?.meta,
    folder: query.data?.folder,
    object: query.data?.object,
  };

  const folderContext: BrandInsightsPageContext.Folder = useMemo(() => {
    if (!query.data?.features?.insightsChat) return null;

    return {
      type: ChatContextType.Folder,
      data: {
        objectId: query.data?.folder?.objectId,
        folderId: query.data?.folder?.id,
      },
      metadata: {
        supportsUploads: !!query.data?.folder?.metadata?.linkedChatInstanceIdentifier,
        name: query.data?.folder?.name,
      },
    };
  }, [query.data?.folder, query.data?.features]);

  const register = useRegisterChatPageContext();
  useEffect(() => {
    if (!folderContext) return;

    if (folderContext.data.objectId && folderContext.metadata.name) {
      register(folderContext);
    }
  }, [register, folderContext]);

  if (query.isFetched && !query.data?.folder) {
    const e = query.error;
    const data = e?.data as IWorkspaceObjectId;
    const forbidden = query.isError && e.status === 403;
    const hasObjectId = data !== null && typeof data === 'object' && !!data?.objectId;

    return forbidden && hasObjectId
      ? <Redirect to={generatePath(path.Access.Request, data)} />
      : <Redirect to={path.Workspaces.FolderNotFound} />;
  }

  return (
    <WorkspaceBreadcrumbsContext.Provider value={query.data?.breadcrumbs}>
      <FolderLoadingContext.Provider value={query.isLoading}>
        <FolderStateContext.Provider value={state}>
          <GroupFolderTagsContainer folderId={params.folderId}>
            {props.children}
          </GroupFolderTagsContainer>
        </FolderStateContext.Provider>
      </FolderLoadingContext.Provider>
    </WorkspaceBreadcrumbsContext.Provider>
  );
};

type RouteParams = {
  folderId: string;
};

const useFolderParams = () => {
  const params = useParams<RouteParams>();
  return {
    folderId: +params.folderId,
  };
};
