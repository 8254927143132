import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { MembersIndeterminateSelectedContext, MatchListQueryParamsContext, MatchListSelectedContext, MembersQueryContext, MembersSelectedContext } from '$admin/Search/Context';

type Props = {
  children: React.ReactNode;
};

export const StateContainerIndeterminateSelected = (props: Props) => {
  const query = useContext(MembersQueryContext);
  const [selected] = useContext(MembersSelectedContext);

  const rows = useMemo(() => {
    return query.data.items ?? [];
  }, [query.data.items]);

  const checked = useMemo(() => {
    return !!selected.ids.size;
  }, [selected.ids.size]);

  const indeterminate = useMemo(() => {
    return selected.ids.size > 0
        && selected.ids.size !== query.data?.pagination?.totalCount;
  }, [
    query.data?.pagination?.totalCount,
    selected.ids.size,
  ]);

  const rowsOnPage = useMemo(() => {
    return rows.map(({ user }) => ({ user }));
  }, [rows]);

  const userIdsOnPage = useMemo(() => {
    return rowsOnPage.map(m => m.user.id);
  }, [rowsOnPage]);

  const value = {
    checked,
    indeterminate,
    count: {
      rows: userIdsOnPage.length,
      selected: selected.ids.size,
    },
  };

  return (
    <MembersIndeterminateSelectedContext.Provider value={value}>
      <MatchListSelectedContainer>
        {props.children}
      </MatchListSelectedContainer>
    </MembersIndeterminateSelectedContext.Provider>
  );
};

StateContainerIndeterminateSelected.displayName = 'StateContainer.IndeterminateSelected';

const MatchListSelectedContainer = (props: Props) => {
  const [list] = useContext(MatchListQueryParamsContext);
  const checkbox = useContext(MembersIndeterminateSelectedContext);
  const [page, setPageOnly] = useState(true);

  const setSelectPageOnly = useCallback((val: boolean) => {
    if (!list?.id) return;

    setPageOnly(val);
  }, [
    list?.id,
  ]);

  useEffect(() => {

    if (!page && checkbox.count.rows !== checkbox.count.selected) {
      setSelectPageOnly(true);
    }

  }, [
    checkbox.count.rows,
    checkbox.count.selected,
    page,
    setSelectPageOnly,
  ]);

  const selected = {
    enabled: !!list?.id,
    page,
    setSelectPageOnly,
  };

  return (
    <MatchListSelectedContext.Provider value={selected}>
      {props.children}
    </MatchListSelectedContext.Provider>
  );
};

MatchListSelectedContainer.displayName = 'MatchList.Selected.Container';