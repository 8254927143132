import { createNamedContext } from '@utils';
import type {
  BookingSegmentContextValue,
  CalendarsContextValue,
  ConfirmContextValue,
  FrameContextValue,
  PersonalCalendarContextValue,
  ParamsContextValue,
  ParticipantsContextValue,
  ReadyStateContextValue,
  RespondentSetupContextValue,
  SettingsContextValue,
  UnconfirmedCallsContextValue,
} from './interfaces';

export const CalendarsContext = createNamedContext<CalendarsContextValue>(undefined, 'Call.Scheduling.Calendars.Context');
export const ConfirmContext = createNamedContext<ConfirmContextValue>(undefined, 'Confirm.Context');
export const FrameContext = createNamedContext<FrameContextValue>(undefined, 'Frame.Context');
export const ParamsContext = createNamedContext<ParamsContextValue>(undefined, 'Params.Context');
export const ParticipantsContext = createNamedContext<ParticipantsContextValue>(undefined, 'Call.Scheduling.Participants.Context');
export const ReadyStateContext = createNamedContext<ReadyStateContextValue>(undefined, 'Call.Scheduling.ReadyState.Context');
export const RespondentSetupContext = createNamedContext<RespondentSetupContextValue>(undefined, 'Call.Scheduling.RespondentSetup.Context');
export const SettingsContext = createNamedContext<SettingsContextValue>(undefined, 'Call.Scheduling.Settings.Context');
export const UnconfirmedCallsContext = createNamedContext<UnconfirmedCallsContextValue>(undefined, 'Call.Scheduling.UnconfirmedCalls.Context');

export const BookingSegmentContext = createNamedContext<BookingSegmentContextValue>(undefined, 'Booking.Segment.Context');
export const PersonalCalendarContext = createNamedContext<PersonalCalendarContextValue>(undefined, 'PersonalCalendar.Context');