import type { Dispatch } from 'react';
import { useCallback, useReducer } from 'react';
import { indexBy } from '@/utils/array';
import { useSubmitSurveyDraft, useSurveyBuilderState } from './hooks';
import { getTranslatedSurveyBuilderVersion, getSurveyTextKeys, extractItemTextKeys } from './utils/language';
import { SurveyBuilderStateContext, SurveyLanguageEditorContext, SaveSurveyDraftContext } from './Context';
import { surveyStateReducer } from './state';
import type { SurveyBuilder } from './interfaces';

type Props = {
  languageCode: string;
} & ChildrenProps;

export const LanguageEditingContainer = (props: Props) => {
  const [originalState, dispatchOriginal] = useSurveyBuilderState();
  const submitSurveyDraft = useSubmitSurveyDraft();

  const translateBuilderVersion = useCallback(() => {
    return {
      ...originalState,
      survey: getTranslatedSurveyBuilderVersion(props.languageCode, originalState.survey),
    };
  }, [originalState, props.languageCode]);

  const [state, dispatch] = useReducer(surveyStateReducer, null, translateBuilderVersion);

  const clearState = useCallback(() => {
    dispatch({
      type: 'replace-state',
      state: translateBuilderVersion(),
    });
  }, [dispatch, translateBuilderVersion]);

  const saveLanguageChanges = useCallback(() => {
    const keys = getSurveyTextKeys(state.survey);
    dispatchOriginal({
      type: 'language-keys-imported',
      payload: {
        code: props.languageCode,
        values: indexBy(keys, k => k.key, v => v.value),
      },
    });

    submitSurveyDraft();
  }, [dispatchOriginal, props.languageCode, state.survey, submitSurveyDraft]);

  const interceptedDispatch: Dispatch<SurveyBuilder.Actions> = useCallback(action => {
    if (action.type === 'toggle-editing' && !action.itemIdentifier) {
      const currItem = state.editing.itemIdentifier;

      const keys = extractItemTextKeys(state.survey, currItem);

      dispatchOriginal({
        type: 'language-keys-imported',
        payload: {
          code: props.languageCode,
          values: indexBy(keys, k => k.key, v => v.value),
        },
      });
    }

    dispatch(action);
  }, [dispatchOriginal, props.languageCode, state.editing.itemIdentifier, state.survey]);

  return (
    <SurveyBuilderStateContext.Provider value={[state, interceptedDispatch, clearState]}>
      <SurveyLanguageEditorContext.Provider value={{ saveLanguageChanges }}>
        <SaveSurveyDraftContext.Provider value={noop}>
          {props.children}
        </SaveSurveyDraftContext.Provider>
      </SurveyLanguageEditorContext.Provider>
    </SurveyBuilderStateContext.Provider>
  );
};

const noop = () => { };