import { useEffect } from 'react';
import { useContentFeedState, useFetchContentFeed } from '@containers/ContentFeed';
import { ContentFeedItem, Feed } from '@/components/ContentFeed';
import { useFeedScroll } from '@/components/ContentFeed/hooks/useFeedScroll';
import styles from './style/ContentFeed.css';

type Props =
  unknown;

const ContentFeed = (props: Props) => {
  const [state] = useContentFeedState();
  const fetch = useFetchContentFeed();

  useFeedScroll();

  useEffect(() => {

    fetch();

    /* eslint-disable-next-line */
  }, []);

  if (state.loading) {
    return (
      <>
        <Feed.Skeleton />
        <Feed.Skeleton />
        <Feed.Skeleton />
      </>
    );
  }

  return (
    <div className={styles.root}>
      {state.items.map((x, i) => (
        <ContentFeedItem
          item={x}
          key={x.id} />
      ))}
      {state.morePostsToFetch &&
        <>
          <Feed.Skeleton />
          <Feed.Skeleton />
        </>}
    </div>
  );
};

export { ContentFeed };
export default ContentFeed;