import styled from '@emotion/styled';
import type { WorkspaceFileSummarySection } from '@/types/workspace.file';

type Props = {
  className?: string;
  item: WorkspaceFileSummarySection;
};

export function SummarySection({ className, item }: Props) {
  return (
    <Root className={className}>
      <Title>{item.title}</Title>
      <Text>{item.text}</Text>
    </Root>
  );
}

const Root = styled.div`
  
`;

const Title = styled.div`
  font-family: var(--font-bold);
  font-size: 16px;
  margin-bottom: 6px;
`;

const Text = styled.div`
  font-family: var(--font-normal);
  font-size: 14px;
  line-height: 20px;
`;