import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import type { FileUploads } from '@api/interfaces/workspace.file-upload';
import * as $api from '@api/workspaces';
import type { FilesMap } from '../interfaces';

export const usePreFileUploadMutation = (options?: Options) => {

  const mutation = useMutation({
    mutationFn: data => {
      const files = Object.keys(data.files).map(identifier => {
        const file = data.files[identifier];
        return {
          identifier,
          name: file.name,
          size: file.size,
          type: file.type,
        };
      });

      return $api.fileUpload.fileUploadPreCheck({
        ...data,
        files,
      });
    }, ...options });

  return mutation;
};

type Data = FileUploads.FileUploadPreCheck.Response;
type Vars = Omit<FileUploads.FileUploadPreCheck.Request, 'files'> & {
  files: FilesMap;
  isTranscriptImport: boolean;
};
type Options = UseMutationOptions<Data, AxiosResponse, Vars>;