import { useCallback, useMemo, useState } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import { SurveyTemplate } from '@/types/survey.templates';

export const useTemplateCategory = () => {
  const [state, dispatch] = useSurveyBuilderState();
  const [category, setCategory] = useState<SurveyTemplate.Category>((state.survey.template.data as SurveyTemplate.ICategory).category);

  const onChange = useCallback((value: SurveyTemplate.Category) => {
    setCategory(value);
  }, []);

  const onSubmit = useCallback(() => {

    dispatch({
      type: 'template-category-updated',
      value: category,
    });
  }, [
    category,
    dispatch,
  ]);

  return {
    onChange,
    onSubmit,
    value: category,
  };
};

export const useCanSaveCategory = (category: SurveyTemplate.Category) => {

  return useMemo(() => {
    return category.value.length >= 2;
  }, [category]);
};