import { useState } from 'react';
import { Login } from '$website/components/AuthForm/Frame.Login';
import * as Signup from '$website/components/AuthForm/Frame.Signup';
import styles from './style/AuthFrame.css';

type Props = unknown;

export const AuthFrame = (props: Props) => {
  const [view, setView] = useState<View>('signup:strategy');

  const content = view === 'signup:strategy'
      ? <Signup.Strategy onChangeView={setView} />
      : view === 'signup:email'
        ? <Signup.Email />
        : view === 'login'
          ? <Login />
          : null;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        {content}
      </div>
    </div>
  );
};

AuthFrame.displayName = 'Auth.Frame';

type View = 'login' | 'signup:email' | 'signup:strategy';