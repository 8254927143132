import { useEffect } from 'react';

const useUseableCSS = <T extends Useable>(...styles: T[]) => {

  useEffect(() => {

    styles.forEach(style => style.use());

    return () => styles.forEach(style => style.unuse());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

};

export type Useable = {
  unuse: () => void;
  use:   () => void;
};

export { useUseableCSS };
export default useUseableCSS;