import { useContext } from 'react';
import { usePositioner } from '@remirror/react';
import type { Positioner } from '@remirror/extension-positioner';
import { selectionPositioner, hasStateChanged } from '@remirror/extension-positioner';
import { usePreviousDistinct } from '@utils/hooks';
import { TranscriptConfidenceContext } from '@containers/Transcript/context';

export const useConfidenceTooltipPositioner = () => {
  const ctx = useContext(TranscriptConfidenceContext);

  const mark = ctx.selectedMark;
  const previousMark = usePreviousDistinct(mark);

  const position = usePositioner((): Positioner<{ from: Coords; to: Coords }> => {
    return selectionPositioner.clone({
      hasChanged: props => {
        return hasStateChanged(props) || previousMark !== mark;
      },
      getActive: ({ helpers, view }) => {
        if (!mark) {
          return [];
        }

        const from = view.coordsAtPos(mark.from);
        const to = view.coordsAtPos(mark.to);

        return [{ from, to }];
      },
    });

  }, [
    mark,
    previousMark,
  ]);

  return position;
};

type Coords = {
  left: number;
  right: number;
  top: number;
  bottom: number;
};