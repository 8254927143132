import { ModalProps } from '@/components/Modal/Modal';
import { Alert } from '@/components/Modal/Alert';
import { useModal } from '@/components/Modal/hooks';

type Props = {
  onConfirm: () => void;
} & Pick<ModalProps,
    'onClose' |
    'open'>;

export const SurveyTemplateChangeAlert = ({ onClose, onConfirm, open }: Props) => {

  const message = `Are you sure you want to switch templates? Any previously entered survey questions will be erased upon switching`;

  return (
    <Alert
      confirmText="Confirm"
      message={message}
      onClose={onClose}
      onConfirm={onConfirm}
      open={open} />
  );
};

export const useSurveyTemplateChangeAlert = () => useModal(SurveyTemplateChangeAlert);