import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import { useSelectProject, useSelectUser } from '@containers/Store';

export const useDeclineProjectRequest = (projectId: number) => {
  const dispatch = useDispatch();
  const user = useSelectUser();
  const project = useSelectProject(projectId);

  const declineProjectRequest = () => {
    return api.projects.pipeline.declineRequest({
      projectId: project.id,
      groupId: project.groupId,
      userId: user.id,
    })
    .then(_ => dispatch(actions.projectRemoved({
      callIds: project.callIds,
      projectId: project.id,
    })));
  };

  return declineProjectRequest;
};

export default useDeclineProjectRequest;