import styled from '@emotion/styled';

type Props = {
  className?: string;
} & ChildrenProps;

export function Container({ className, children }: Props) {
  return (
    <Root className={className}>
      {children}
    </Root>
  );
}

const Root = styled.div`
  box-sizing: border-box;
  font-size: 16px;
`;