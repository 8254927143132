import { memo } from 'react';
import { cx } from '@utils';
import { LabelWithColor, LabelWithSubtitle } from './ColorIndicator';
import {
  ClosedResponseOptionRowProps,
  ResponseOptionProps,
  ResponseOptionRowProps,
  OpenedResponseOptionRowProps,
} from './interfaces';
import CloseRow from './Close';
import ViewAll from './ViewAll';
import styles from './style/ResponseOption.css';

export const ResponseOption = memo(({ color, label, subtitle }: ResponseOptionProps) => {
  const value = (
    <LabelWithSubtitle
      label={label}
      subtitle={subtitle} />
  );

  return (
    <LabelWithColor
      color={{
        value: color,
        size: 25,
      }}
      label={{
        className: styles.label,
        value,
      }} />
  );
});

export const ResponseOptionRow = (props: ResponseOptionRowProps) => {
  return (
    <div
      className={cx(styles.root, props.className)}
      onClick={props.onClick}>
      <ResponseOption
        color={props.color}
        label={props.label}
        subtitle={props.subtitle} />
      {props.children}
    </div>
  );
};

export const ClosedResponseOptionRow = (props: ClosedResponseOptionRowProps) => {
  const { className, onViewAll, ...otherProps } = props;
  return (
    <ResponseOptionRow
      className={cx(styles.closed, className)}
      onClick={onViewAll}
      {...otherProps}>
      <ViewAll
        title="View All Respondents"
        onClick={onViewAll} />
    </ResponseOptionRow>
  );
};

export const OpenedResponseOptionRow = (props: OpenedResponseOptionRowProps) => {
  const { onClose, ...otherProps } = props;
  return (
    <ResponseOptionRow
      {...otherProps}>
      <CloseRow onClick={onClose} />
    </ResponseOptionRow>
  );
};