import { useContext } from 'react';
import { WorkspaceObjectBreadcrumbs } from '@/components/Workspace/ObjectBreadcrumbs';
import { useObjectBreadcrumbsContext } from '@/containers/WorkspaceObject/Context';
import { SkeletonBreadcrumbs } from '@/presentation/Breadcrumbs';
import styles from '@screens/Workspace.File/style/Header.css';
import { BrandLoadingContext } from '@/containers/Workspace.Brand/Context';

export const BrandBreadcrumbs = () => {
  const breadcrumbs = useObjectBreadcrumbsContext();
  const isLoading = useContext(BrandLoadingContext);

  return (
    <>
      {isLoading
        ? <SkeletonBreadcrumbs />
        : (
          <div className={styles.breadcrumbs}>
            <WorkspaceObjectBreadcrumbs items={breadcrumbs} />
          </div>
        )
      }
    </>
  );
};