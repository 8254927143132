import { useCallback } from 'react';
import { useSubmitSurveyDraft, useTargetProductProfileData } from '@containers/SurveyBuilder/hooks';
import { GrayOutlineButton } from '@presentation/Buttons';
import { SurveyRichText } from '@/types';
import { useToggle } from '@/utils';
import { Button } from '@/components/Button';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import ModalHeader from '@/components/Modal/Header';
import { Toolbar } from '@/components/SurveyBuilder.Message/Toolbar';
import { useModal } from '@/components/Modal/hooks';
import { SurveyRichTextEditor } from '@/components/Survey.RichText/Editor';
// eslint-disable-next-line import/no-cycle
import { TemplateStimulusInputContainer } from './Input.Stimulus';
import styles from './style/Modal.Stimulus.css';
import { useCanSaveStimulus, useSubmitStimulus } from './hooks/stimulus';

type Props = {
  stimulus: SurveyRichText.RootNode;
} & Pick<ModalProps,
    'open' |
    'onClose'>;

const TemplateStimulusModal = ({
  onClose,
  open,
  stimulus,
}: Props) => {

  return (
    <Modal
      classes={{
        root: styles.modalWrap,
      }}
      open={open}
      onClose={onClose}>

      {/* <ModalHeader text="Stimulus" /> */}

      <div className={styles.body}>
        <TemplateStimulusInputContainer
          initialValue={stimulus}
          editable={false}>
          <SurveyRichTextEditor />
        </TemplateStimulusInputContainer>
      </div>

    </Modal>
  );
};

type EditableProps = {
  canEdit: boolean;
  editing: boolean;
  toggleEditing: () => void;
} & Pick<ModalProps,
    'open' |
    'onClose'>;

const EditableTemplateStimulusModal = ({
  canEdit,
  editing,
  onClose,
  open,
  toggleEditing,
}: EditableProps) => {

  const onSubmit = useSubmitStimulus();
  const submitDraft = useSubmitSurveyDraft();
  const canSubmit = useCanSaveStimulus();

  const handleSubmit = useCallback(async () => {
    await onSubmit();
    submitDraft();
    toggleEditing();
  }, [toggleEditing, onSubmit, submitDraft]);

  return (
    <Modal
      classes={{
        root: styles.modalWrap,
      }}
      open={open}
      onClose={onClose}>

      {/* <ModalHeader text="Stimulus" /> */}

      <div className={styles.body}>
        {canEdit && !editing &&
          <div className={styles.link} onClick={toggleEditing}>
            Edit Stimulus
          </div>
        }

        {editing &&
          <Toolbar
            disablePiping={true}
            className={styles.toolbar} />}
        <SurveyRichTextEditor />
      </div>

      {editing &&
        <div className={styles.footer}>
          <GrayOutlineButton
            onClick={onClose}
            title="Cancel" />
          <Button.Primary
            className={styles.submit}
            disabled={!canSubmit}
            onClick={handleSubmit}
            title="Submit"
            variant="brick" />
        </div>
      }

    </Modal>
  );
};

type ContainerProps = {
  canEdit: boolean;
} & Pick<ModalProps,
    'open' |
    'onClose'>;

const ModalContainer = (props: ContainerProps) => {

  const [editing, toggleEditing] = useToggle(false);
  const [templateData] = useTargetProductProfileData();

  return (
    <TemplateStimulusInputContainer
      initialValue={templateData.stimulus}
      editable={editing}>
      <EditableTemplateStimulusModal
        canEdit={props.canEdit}
        editing={editing}
        toggleEditing={props.canEdit ? toggleEditing : null}
        onClose={props.onClose}
        open={props.open} />
    </TemplateStimulusInputContainer>
  );
};

export const useTemplateStimulusModal = () => useModal(TemplateStimulusModal);
export const useEditableTemplateStimulusModal = () => useModal(ModalContainer);
