import extend from '@enums/extend';

export enum ChargeableType {
  ProjectCall = 1,
  Survey = 2,
  Message = 3,
  Miscellaneous = 4,
  RefundCredit = 5,
  AdHocCall = 6,
  AdminSurveyQuestionLimit = 7,
  BillingPackageLicense = 8,
  BillingPackageFunding = 9
}

const map = new Map([
  [ChargeableType.ProjectCall, { name: 'Project Call' }],
  [ChargeableType.Survey, { name: 'Survey' }],
  [ChargeableType.Message, { name: 'Message' }],
  [ChargeableType.Miscellaneous, { name: 'Miscellaneous' }],
  [ChargeableType.RefundCredit, { name: 'Credit' }],
  [ChargeableType.AdHocCall, { name: 'Ad Hoc Call' }],
  [ChargeableType.AdminSurveyQuestionLimit, { name: 'Admin Survey Programming Fee' }],
  [ChargeableType.BillingPackageLicense, { name: 'Billing Package License' }],
  [ChargeableType.BillingPackageFunding, { name: 'Billing Package Account Balance Funding' }],
]);

export default {
  ChargeableType: extend(map),
};