import { useCallback, useState } from 'react';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { usePopper } from 'react-popper';
import { PopperMenu, PopperMenuItem } from '@/components/Popper';
import { Portal } from '@/components/Portal';
import { cx } from '@utils';
import { TagBadge } from '@/components/Transcript';
import type { TaggedMoment } from '@/types/transcribe.rich-text';
import styles from './style/Cell.Tags.css';

type Props = {
  onDelete: () => void;
  onEdit: () => void;
  tag: Pick<TaggedMoment, 'color' | 'typeId' | 'name'>;
};

export const TagBadgeMenu = ({ onDelete, onEdit, tag }: Props) => {

  const [open, setOpen] = useState(false);

  const [filterElement, setFilterElement] = useState<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement>(null);

  const { styles: popperStyles, attributes } = usePopper(filterElement, popperElement, {
    placement: 'bottom-start',
  });

  const handleBlur = useCallback(() => {
    setOpen(false);
  }, []);

  const handleFilterClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleEdit = useCallback(() => {
    onEdit();
    setOpen(false);
  }, [onEdit]);

  const handleDelete = useCallback(() => {
    onDelete();
    setOpen(false);
  }, [onDelete]);

  return (
    <>
      <div
        ref={setFilterElement}
        onClick={handleFilterClick}>
        <TagBadge
          className={styles.tag}
          tag={tag} />
      </div>
      {open &&
        <Portal>
          <ClickAwayListener
            mouseEvent='onMouseDown'
            onClickAway={handleBlur}
            touchEvent='onTouchStart'>
            <div
              ref={setPopperElement}
              style={popperStyles.popper}
              {...attributes.popper}>
              <PopperMenu>
                <PopperMenuItem onClick={handleEdit}>
                  Edit
                </PopperMenuItem>
                <PopperMenuItem onClick={handleDelete}>
                  Delete
                </PopperMenuItem>
              </PopperMenu>
            </div>
          </ClickAwayListener>
        </Portal>
      }
    </>
  );
};