import { useState } from 'react';
import { useToggle } from '@utils/hooks';
import { useModal } from '@/components/Modal/hooks';
import { ModalProps } from '@/components/Modal/Modal';
import { TagToSummarizeContext } from './context';
import { useExportTranscriptSummaryModal } from './Modal.Export';
import { TranscriptTagsOverviewModal } from './Modal.Overview';

type Props =
  & ITranscriptId
  & Pick<ModalProps, 'onClose' | 'open'>;

export const TranscriptTagSummariesModal = (props: Props) => {

  const [tagsToSummarize, setTagsToSummarize] = useState<number[]>([]);
  const [toggleExportModal, ExportModal] = useExportTranscriptSummaryModal();
  const [overviewModalOpen, toggleOverviewModal] = useToggle(true);

  return (
    <TagToSummarizeContext.Provider value={[tagsToSummarize, setTagsToSummarize]}>
      <TranscriptTagsOverviewModal
        open={overviewModalOpen}
        onClose={toggleOverviewModal}
        transcriptId={props.transcriptId}
        onDownload={toggleExportModal} />
      <ExportModal
        transcriptId={props.transcriptId}
        tagIds={tagsToSummarize} />
    </TagToSummarizeContext.Provider>
  );
};

export const useTranscriptTagSummariesModal = () => useModal(TranscriptTagSummariesModal);