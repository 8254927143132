import { useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import * as enums from '@enums';
import { FormButtons } from '@presentation';
import { Editable } from '@/components/Editable';
import Tag from '@/components/Tag';
import ProjectFilterEditableItems from './ProjectFilterEditableItems';
import styles from './style.css';

const mapDispatch = {
  projectUpdated: actions.projectUpdated,
};

const ProjectFilters = ({
  headerTextClass,
  item,
}) => {
  const filterVICS = typeId => item.vics
    .filter(f => f.typeId === typeId)
    .map(m => ({
      id: m.id,
      name: m.name,
    }));

  const renderTags = ({
    items,
    type,
  }) => {
    if (!items.length) {
      return (
        <div className={styles.filtersRow}>
          <div className={styles.noneText}>None</div>
        </div>
      );
    }

    return (
      <div className={styles.filtersRow}>
        <div className={styles.tags}>
          {items.map(x =>
            <Tag
              key={x.id}
              item={{
                id: x.id,
                name: x.name,
                type,
              }}
              editable={false} />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.readOnlyRoot}>
      <div className={styles.body}>
        <div className={styles.projectFiltersHeader}>Project Tags</div>
        <div className={styles.label}>Current Company</div>
        {renderTags({
          items: [],
          type: enums.SearchType.Company,
        })}
        <div className={styles.label}>Former Company</div>
        {renderTags({
          items: [],
          type: enums.SearchType.Company,
        })}
        <div className={styles.label}>Job Title</div>
        {renderTags({
          items: [],
          type: enums.SearchType.Title,
        })}
        <div className={styles.label}>Keywords</div>
        {renderTags({
          items: [],
          type: enums.SearchType.Keyword,
        })}
        <div className={styles.label}>Sector</div>
        {renderTags({
          items: [],
          type: enums.SearchType.Sector,
        })}
        <div className={styles.label}>Industry</div>
        {renderTags({
          items: [],
          type: enums.SearchType.Industry,
        })}
        <div className={styles.label}>Sub-Industry</div>
        {renderTags({
          items: [],
          type: enums.SearchType.SubIndustry,
        })}
      </div>
    </div>
  );
};

const ProjectFiltersEditable = ({
  headerTextClass,
  item,
  onCancel,
  onSave,
}) => {
  const inititalValues = {
    vics: item.vics,
    currentCompanies: item.companies.filter(f => f.isCurrent).map(m => ({
      id: m.id,
      name: m.name,
    })),
    formerCompanies: item.companies.filter(f => !f.isCurrent).map(m => ({
      id: m.id,
      name: m.name,
    })),
    titles: item.titles.map(m => ({
      id: m.id,
      name: m.name,
    })),
    keywords: item.keywords,
  };

  const [formValues, setFormValues] = useState(inititalValues);

  const canSubmitChanges = () => {
    return JSON.stringify(inititalValues) !== JSON.stringify(formValues);
  };

  const handleSave = () => {
    const companies = formValues.currentCompanies.map(m => ({
      ...m,
      isCurrent: true,
    })).concat(formValues.formerCompanies.map(m => ({
      ...m,
      isCurrent: false,
    })));

    onSave({
      vics: formValues.vics,
      companies,
      titles: formValues.titles,
      keywords: formValues.keywords,
    });
  };

  const addItem = field => data => {
    setFormValues({
      ...formValues,
      [field]: formValues[field].concat(data),
    });
  };

  const removeItem = field => data => () => {
    setFormValues({
      ...formValues,
      [field]: formValues[field].filter(f => f.id !== data.id),
    });
  };

  const removeKeyword = keyword => () => {
    setFormValues({
      ...formValues,
      keywords: formValues.keywords.filter(f => f !== keyword),
    });
  };

  const handleKeywordSelected = text => {
    if (!formValues.keywords.some(s => s === text)) {
      addItem('keywords')(text);
    }
  };

  return (
    <div className={styles.editableRoot}>
      <div className={styles.body}>
        <div className={styles.projectFiltersHeader}>Project Tags</div>
        <ProjectFilterEditableItems
          headerTextClass={styles.label}
          item={formValues}
          addItem={addItem}
          removeItem={removeItem}
          onKeywordRemoved={removeKeyword}
          onKeywordSelected={handleKeywordSelected} />
      </div>
      <FormButtons
        disabled={!canSubmitChanges()}
        onCancel={onCancel}
        onSubmit={handleSave} />
    </div>
  );
};

const ProjectFiltersContainer = ({
  editable,
  item,
  projectUpdated,
}) => {
  const [editing, setEditing] = useState(false);

  const handleSave = data => {
    api.project.updateProjectSupplementalData({
      projectId: Number(item.id),
      companies: data.companies,
      keywords: data.keywords,
      roles: data.roles || [],
      titles: data.titles,
      vics: data.vics,
    })
    .then(res => {
      const { project, ...rest } = res;
      return {
        ...item,
        keywords: project.keywords,
        ...rest,
      };
    })
    .then(data => {
      projectUpdated({
        projectId: data.id,
        project: data,
      });
    })
    .then(toggleEditing);
  };

  const toggleEditing = () => {
    setEditing(!editing);
  };

  const renderBody = () => {
    if (editing) {
      return (
        <ProjectFiltersEditable
          headerTextClass={styles.projectFiltersLabel}
          item={item}
          onCancel={toggleEditing}
          onSave={handleSave} />
      );
    }

    if (editable) {
      return (
        <Editable onClick={toggleEditing}>
          <ProjectFilters
            headerTextClass={styles.projectFiltersLabel}
            item={item} />
        </Editable>
      );
    }

    return (
      <ProjectFilters
        headerTextClass={styles.projectFiltersLabel}
        item={item} />
    );
  };

  return (
    <div className={styles.card}>
      {renderBody()}
    </div>
  );
};

export default connect(null, mapDispatch)(ProjectFiltersContainer);
