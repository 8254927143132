import { useCallback } from 'react';
import { X } from 'react-feather';
import type { SurveyAIEEntry, SurveyAIEGroup } from '@/types';
import { useSurveyBuilderState } from '@containers/SurveyBuilder';
import { useSurveyAlternateImagesBuilderItemContext } from '@containers/SurveyBuilder.AIE';
import { Input } from '@/components/Input';
import styles from './style/Entry.css';

export type EntryProps = {
  entry: SurveyAIEEntry;
  group: SurveyAIEGroup;
  imageProps?: object;
};

export const Entry = (props: EntryProps) => {
  const [, dispatch] = useSurveyBuilderState();
  const item = useSurveyAlternateImagesBuilderItemContext();

  const onRemove = useCallback(() => {
    dispatch({
      type: 'aie-entry-removed',
      exerciseIdentifier: item.identifier,
      groupIdentifier: props.group.identifier,
      entryIdentifier: props.entry.identifier,
    });
  }, [dispatch, item.identifier, props.entry.identifier, props.group.identifier]);

  const onNameUpdate: React.ChangeEventHandler<HTMLInputElement> = useCallback(e => {
    dispatch({
      type: 'aie-entry-updated',
      exerciseIdentifier: item.identifier,
      groupIdentifier: props.group.identifier,
      entryIdentifier: props.entry.identifier,
      entry: {
        name: e.target.value,
      },
    });
  }, [dispatch, item.identifier, props.entry.identifier, props.group.identifier]);

  return (
    <div className={styles.root}>
      <div className={styles.name}>
        <Input
          className={styles.nameInput}
          onChange={onNameUpdate}
          value={props.entry.name ?? ''} />
      </div>
      <div className={styles.imgContainer}>
        <img
          className={styles.image}
          src={props.entry.imageUrl}
          {...(props.imageProps ?? {})} />
        <X
          className={styles.remove}
          size={28}
          onClick={onRemove} />
      </div>
    </div>
  );
};