import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateUserSettings } from '@utils/api';
import { DropDown } from '@/components/DropDown';
import { SettingsTitle } from '@/components/Settings';
import styles from './style/Settings.Calendar.css';

const selectSettings = (state: Store.State) => state.user.settings;

export const CalendarSettings = () => {

  const settings = useSelector(selectSettings);

  const { mutateAsync: updateSettings } = useUpdateUserSettings();

  const handleMoratoriumUpdate = useCallback(({ id }: { id: number }) => {
    updateSettings({
      callSchedulingMoratorium: id,
    })
  }, [updateSettings]);

  return (
    <>
      <SettingsTitle
        title="Calendar Settings" />
      <div className={styles.item}>
        Required notice for call bookings
        <div className={styles.dropdown}>
          <DropDown
            items={moratoriumItems}
            getItemValue={getMoratoriumText}
            onSelect={handleMoratoriumUpdate}
            text={getMoratoriumText({ id: settings.callSchedulingMoratorium })} />
        </div>
      </div>
    </>
  );
};

function getMoratoriumText({ id }: { id: number }) {
  return moratoriumItems.find(f => f.id === id).text;
}

const moratoriumItems = [
  { id: 0, text: 'None' },
  { id: 60 * 1, text: '1 hour' },
  { id: 60 * 12, text: '12 hours' },
  { id: 60 * 24, text: '24 hours' },
  { id: 60 * 48, text: '48 hours' },
];

export default CalendarSettings;