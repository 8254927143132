import { useContext } from 'react';
import { MedicalExperienceNavigationContext } from '@containers/ProjectOnboarding.MedicalExperience/Context';
import { MedicalExpertiseContext } from '@containers/MedicalOnboarding/Context';
import * as Layout from '@/components/MedicalProfile/Layout';
import * as Field from '@/components/MedicalProfile/Field';
import styles from './style/Experience.css';

type Props = unknown;

export const Role = (props: Props) => {
  const ctx = {
    expertise: useContext(MedicalExpertiseContext),
    navigation: useContext(MedicalExperienceNavigationContext),
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Layout.Screen>
          <Layout.Header
            subtitle={copy.subtitle}
            title={copy.title} />

          <div className={styles.main}>
            <Field.Role
              className={styles.role}
              onChange={item => ctx.expertise.onProfessionalRoleUpdated(item.value)}
              value={ctx.expertise.professionalRole} />
          </div>

          <Layout.Footer className={styles.footer}>
            <Layout.BackButton
              onClick={ctx.navigation.back} />
            <Layout.NextButton
              className={styles.btn}
              disabled={!ctx.expertise.professionalRole}
              implicitDisable={false}
              loading={ctx.expertise.mutation.role.isLoading}
              onClick={ctx.navigation.next}
              variant="brick">
              Next
            </Layout.NextButton>
          </Layout.Footer>
        </Layout.Screen>
      </div>
    </div>
  );
};

Role.displayName = 'Experience.Role';

const copy = {
  subtitle: `Please select your role to optimize your profile for project searches.`,
  title: `Which of the following best describes your role?`,
};