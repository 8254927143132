import { memo, useContext, useMemo, useCallback } from 'react';
import { cx } from '@utils';
import { TranscriptMediaPlayerContext } from '@containers/Transcript/context';
import styles from './style/TimeRange.css';

type Props = {
  className?: string;
  startSeconds: number;
  endSeconds: number;
};

export const TimeRange = ({ startSeconds: start, endSeconds: end, className }: Props) => {
  const player = useContext(TranscriptMediaPlayerContext);

  const handleSeek = (time: number) => (e: React.MouseEvent<HTMLSpanElement>) => {
    if (!player) return;
    player.currentTime = time;
    player.play();
  };

  const startDisplay = useMemo(() => {
    return secondsToTime(start);
  }, [start]);

  const endDisplay = useMemo(() => {
    return secondsToTime(end);
  }, [end]);

  const handleMouseDown = useCallback((e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
  }, []);

  const handleSeekStart = handleSeek(start);
  const handleSeekEnd = handleSeek(end);

  return (
    <div
      className={cx(styles.timestamp, className)}
      data-ts-s={start}
      data-ts-e={end}>
      [
      <span
        onMouseDown={handleMouseDown}
        onClick={handleSeekStart}
        className={styles.seek}>
        {startDisplay}
      </span>
      {` - `}
      <span
        onMouseDown={handleMouseDown}
        onClick={handleSeekEnd}
        className={styles.seek}>
        {endDisplay}
      </span>
      ]
    </div>
  );
};

export const StaticTimeRange = memo((props: Props) => {
  const start = secondsToTime(props.startSeconds);
  const end = secondsToTime(props.endSeconds);

  return (
    <div
      className={cx(styles.timestamp, props.className)}
      data-ts-s={props.startSeconds}
      data-ts-e={props.endSeconds}>
      [
      <span>
        {start}
      </span>
      {` - `}
      <span>
        {end}
      </span>
      ]
    </div>
  );
});

function secondsToTime(value: number) {
  const time = new Date(value * 1000).toISOString().substring(11, 19);
  return time.startsWith('00') ? time.substring(3) : time;
}