import extend from '@enums/extend';
import * as MessageTesting from './survey.template-message-testing';

export { MessageTesting };

export enum SurveyTemplateType {
  None = 0,
  CompetitiveIntelligence = 1,
  PersonaResearch = 2,
  NetPromoterScore = 3,
  EmployeeSatisfaction = 4,
  PricingStrategy = 5,
  MarketingCampaignEffectiveness = 6,
  MarketAnalysis = 7,
  TargetProductProfile = 8,
  ExternalSurvey = 9,
  MessageTesting = 10,
}

const surveyTemplateTypeMap = new Map([
  [SurveyTemplateType.None,                             { name: 'None' }],
  [SurveyTemplateType.CompetitiveIntelligence,          { name: 'Competitive Intelligence' }],
  [SurveyTemplateType.PersonaResearch,                  { name: 'Persona Research' }],
  [SurveyTemplateType.NetPromoterScore,                 { name: 'Net Promoter Score' }],
  [SurveyTemplateType.EmployeeSatisfaction,             { name: 'Employee Satisfaction' }],
  [SurveyTemplateType.PricingStrategy,                  { name: 'Pricing Strategy' }],
  [SurveyTemplateType.MarketingCampaignEffectiveness,   { name: 'Marketing Campaign Effectiveness' }],
  [SurveyTemplateType.MarketAnalysis,                   { name: 'Market Analysis' }],
  [SurveyTemplateType.TargetProductProfile,             { name: 'Target Product Profile' }],
  [SurveyTemplateType.ExternalSurvey,                   { name: 'Use External Survey Platform' }],
  [SurveyTemplateType.MessageTesting,                   { name: 'Message Testing' }],
]);

export type SurveyTemplateSection =
  CompetitiveIntelligenceTemplateSection |
  PersonaResearchTemplateSection |
  NetPromoterScoreTemplateSection |
  EmployeeSatisfactionTemplateSection |
  PricingStrategySection |
  MarketingCampaignEffectivenessSection |
  MarketAnalysisSection |
  TargetProductProfileSection |
  MessageTesting.Section
;

export type SurveyTemplateQuestion =
  CommonTemplateQuestion |
  CompetitiveIntelligenceQuestion |
  PersonaResearchQuestion |
  NetPromoterScoreQuestion |
  EmployeeSatisfactionQuestion |
  PricingStrategyQuestion |
  MarketingCampaignEffectivenessQuestion |
  MarketAnalysisQuestion |
  TargetProductProfileQuestion |
  MessageTesting.Question
;

export type SurveyTemplateMessage =
  TargetProductProfileMessage |
  MessageTesting.Message
;

export type SurveyTemplateLogic =
  MessageTesting.Logic
;

export enum CompetitiveIntelligenceTemplateSection {
  Screening            = 'ci-screening',
  CompetitorIndexing   = 'ci-competitor-indexing',
  CompetitorRanking    = 'ci-competitor-ranking',
  CurrentCustomer      = 'ci-current-customer',
  FormerCustomer       = 'ci-former-customer',
}

export enum PersonaResearchTemplateSection {
  Screening            = 'pr-screening',
  BudgetSpend          = 'pr-budget-spend',
  RoleEval             = 'pr-role-eval',
  DecisionMaking       = 'pr-decision-making',
}

export enum NetPromoterScoreTemplateSection {
  Screening             = 'nps-screening',
  NetPromoterTargt      = 'nps-net-promoter-target',
  NetPromoterCompetitor = 'nps-net-promoter-competitor',
}

export enum EmployeeSatisfactionTemplateSection {
  Main  = 'es-main',
}

export enum PricingStrategySection {
  Main  = 'ps-main',
}

export enum MarketingCampaignEffectivenessSection {
  Targeting          = 'mce-targeting',
  Solutions          = 'mce-solutions',
  InHouseSolution    = 'mce-in-hous',
  ThirdPartySolution = 'mce-third-party',
  NoSolution         = 'mce-no-solution',
  AnySolution        = 'mce-any-solution',
  Distribution       = 'mce-distribution',
}

export enum MarketAnalysisSection {

}

export enum TargetProductProfileSection {
  Intro               = 'tpp-intro',
  EUScreener          = 'tpp-eu-screener',
  NonEUScreener       = 'tpp-non-eu-screener',
  UnmetNeeds          = 'tpp-unmet-needs',
  TPPAssessment       = 'tpp-assessment',
  ClinicalComparators = 'tpp-clinical-comparators',

  USPricing           = 'tpp-us-pricing',
  FrancePricing       = 'tpp-france-pricing',
  SpainPricing        = 'tpp-spain-pricing',
  ItalyPricing        = 'tpp-italy-pricing',
  GermanyPricing      = 'tpp-germany-pricing',
  UKPricing           = 'tpp-uk-pricing',

  Canada              = 'tpp-canada',

  EUAcceptablePrice   = 'tpp-eu-acceptable-price',
  EUPricing           = 'tpp-eu-pricing',
  EUPricingComparator = 'tpp-eu-pricing-comparator',

  GDPRDataController  = 'tpp-gdpr-controller',

  Access              = 'tpp-access',
}

export enum SurveyTemplateOptionKey {
  CurrentCustomer          = 'current-customer',
  FormerCustomer           = 'former-customer',
}

export enum SurveyTemplateEntityType {
  Category                   = 'category',
  PricingComparator          = 'pricing-comparator',
  TreatmentComparator        = 'treatment-comparator',
  Competitor                 = 'competitor',
  DrugIndication             = 'drug-indication',
  DrugPrice                  = 'drug-price',
  PrimaryEfficacyEndpoint    = 'primary-efficacy-endpoint',
  CoPrimaryEfficacyEndpoint  = 'co-primary-efficacy-endpoint',
  SecondaryEfficacyEndpoint  = 'secondary-efficacy-endpoint',
  EvaluationCriteria         = 'evaluation-criteria',
  OtherAttribute             = 'other-attribute',
  PartnershipCriteria        = 'partnership-criteria',
  QualityOfLife              = 'quality-of-life',
  SafetyEndpoint             = 'safety-endpoint',
  Target                     = 'target',
  TargetRole                 = 'target-role',
  TrialDesign                = 'trial-design',
  DoseAdministration         = 'dose-administration',
  MedicalSpecialty           = 'medical-specialty',
  MedicalCondition           = 'medical-condition',
  CategoryMessage            = 'category-message',
  Attitudinal                = 'attitudinal',
}

export enum CommonTemplateQuestion {
  OrganizationRevenue                 = 'organization-revenue',
  OrganizationPrimaryIndustry         = 'organization-industry',
  RespondentRole                      = 'respondent-role',
  RespondentDepartment                = 'respondent-department',
}

export enum PersonaResearchQuestion {
  OrgCurrentCustomer                  = 'pr-organization-current-customer',

  RespondentInvolvement               = 'pr-respondent-involvement',
  DepartmentBudget                    = 'pr-department-budget',
  DepartmentSolutionsBudget           = 'pr-department-solutions-budget',
  OrganizationProducts                = 'pr-organization-products',
  OrganizationBusinessModel           = 'pr-organization-business-model',
  TopPriorities                       = 'pr-top-priorities',
  TopChallenges                       = 'pr-top-challenges',
  EvaluationCriteria                  = 'pr-evaluation-critria',
  OtherEvaluationCriteria             = 'pr-other-evaluation-criteria',
  PartnershipCriteria                 = 'pr-partnership-criteria',
  PartnershipCriteriaReason           = 'pr-partnership-criteria-reason',
  TargetRole                          = 'pr-target-role',
  FinalDecisionMaker                  = 'pr-final-decision-maker',
}

export enum CompetitiveIntelligenceQuestion {
  OrgCurrentCustomer                  = 'ci-organization-current-customer',

  SubjectAwareness                    = 'ci-subject-awareness',
  SubjectBrandStrength                = 'ci-subject-brand-strength',
  SubjectBrandStrengthReason          = 'ci-subject-brand-strength-reason',
  SubjectPrice                        = 'ci-subject-price',
  SubjectPriceReason                  = 'ci-subject-price-reason',
  SubjectServiceQuality               = 'ci-subject-service-quality',
  SubjectServiceQualityReason         = 'ci-subject-service-quality-reason',
  SubjectProductValue                 = 'ci-subject-product-value',
  SubjectProductValueReason           = 'ci-subject-product-value-reason',

  CurrentCustomerRationale              = 'ci-current-customer-rationale',
  CurrentCustomerReplacementWillingness = 'ci-current-customer-replacement-willingness',
  CurrentCustomerReplacementRationale   = 'ci-current-customer-replacement-rationale',
  CurrentCustomerReplacement            = 'ci-current-customer-replacement',

  FormerCustomerRationale             = 'ci-former-customer-rationale',
  FormerCustomerReplacement           = 'ci-former-customer-replacement',
}

export enum NetPromoterScoreQuestion {
  RespondentEvaluationRole            = 'nps-evaluation-role',
  OrgCurrentCustomer                  = 'nps-organization-current-customer',

  TargetRecommendation                = 'nps-target-recommendation',
  TargetRecommendationReason          = 'nps-target-recommendation-reason',
  TargetHigherRating                  = 'nps-target-higher-rating',

  CompetitorRecommendation                = 'nps-competitor-recommendation',
  CompetitorRecommendationReason          = 'nps-competitor-recommendation-reason',
  CompetitorHigherRating                  = 'nps-competitor-higher-rating',
}

export enum EmployeeSatisfactionQuestion {
  CurrentEmployee                 = 'es-current-employee',
  RespondentCommitted             = 'es-respondent-committed',
  ColleaguesCommitted             = 'es-colleagues-commited',
  OrgGoalsAligment                = 'es-org-goals-alignment',
  WorkEngaging                    = 'es-work-engaging',
  CareerGrowth                    = 'es-career-growth',
  CareerGoals                     = 'es-career-goals',
  CareerGoalsElaboration          = 'es-career-goals-elaboration',
  NecessaryTools                  = 'es-necessary-tools',
  Encouraged                      = 'es-encouraged',
  FeedbackConsidered              = 'es-feedback-considered',
  Recognization                   = 'es-recognization',
  RecognizationElaboration        = 'es-recognization-elaboration',
  Flexibility                     = 'es-flexibility',
  Informed                        = 'es-informed',
  UnderstandsChanges              = 'es-understands-changes',
  ChangesInLine                   = 'es-changes-in-line',
  SupervisorCares                 = 'es-supervisor-cares',
  SupervisorFeedback              = 'es-supervisor-feedback',
  MyResponsibilitiesClear         = 'es-my-responsibilities-clear',
  TeammateResponsibilitiesClear   = 'es-teammate-responsibilities-clear',
  LeadersTransparent              = 'es-leaders-transparent',
  Recommend                       = 'es-recommend',
  EmployeeExperience              = 'es-employee-experience',
}

export enum PricingStrategyQuestion {
  RecentCustomer                  = 'ps-rececnt-customer',
  EvaluationInvolvement           = 'ps-evaluation-involvement',
  PerceivedValue                  = 'ps-perceived-value',
  Awareness                       = 'ps-awareness',
  GeneralPerception               = 'ps-general-perception',
  PoorQuality                     = 'ps-poor-quality',
  GoodDeal                        = 'ps-good-deal',
  ApproachingExpensive            = 'ps-approaching-expensive',
  BeyondConsideraton              = 'ps-beyond-consideration',
  OtherInfluence                  = 'ps-other-influence',
}

export enum MarketingCampaignEffectivenessQuestion {
  Solutions                       = 'mce-solutions',
  InHouseSolution                 = 'mce-in-house-solution',
  ThirdPartySolution              = 'mce-third-party-solution',
  NoSolution                      = 'mce-no-solution',
  SolutionRating                  = 'mce-solution-rating',
  SolutionRatingExplanation       = 'mce-solution-rating-explanation',
  ImprovementAreas                = 'mce-improvement-areas',
  MainReason                      = 'mce-main-reason',
  Channels                        = 'mce-channels',
  ChannelsUsage                   = 'mce-channels-usage',
  ChannelsRanked                  = 'mce-channels-ranked',
}

export enum MarketAnalysisQuestion {

}

export enum TargetProductProfileQuestion {
  CurrentLocation                 = 'tpp-current-location',
  EUScreener                      = 'tpp-eu-screener',
  NonEUScreener                   = 'tpp-non-eu-screener',

  ConditionUnmetNeeds             = 'tpp-condition-unmet-needs',
  ConditionEconomicBurden         = 'tpp-condition-economic-burden',

  DrugStrengths                   = 'tpp-drug-strengths',
  DrugWeaknesses                  = 'tpp-drug-weaknessses',
  RateAttributes                  = 'tpp-rate-attributes',
  RatingRationale                 = 'tpp-rating-rationale',
  ClinincalComparatorsAppropriate = 'tpp-cc-appropriate',
  ClinicalComparatorsOE           = 'tpp-cc-oe',
  ClinicalComparators             = 'ttp-cc',

  ProductManage                   = 'tpp-product-manage',
  AcceptableWACPrice              = 'tpp-acceptable-wac-price',
  PricingComparatorsOE            = 'tpp-pricing-comparator-oe',
  PricingComparators              = 'tpp-pricing-comparator',
  ProductLowPrice                 = 'tpp-product-low-price',
  ProductMediumPrice              = 'tpp-product-mid-price',
  ProductHighPrice                = 'tpp-high-price',

  HTAFrance                       = 'tpp-hta-france',
  HTAGermany                      = 'tpp-hta-germany',
  MoHReimbursement                = 'tpp-moh-reimbursement',
  AIFAReimbursement               = 'tpp-aifa-reimbursement',
  InnovativeStatus                = 'tpp-innovative-status',
  UKOutcomes                      = 'tpp-uk-outcomes',

  CanadaReimbursement             = 'tpp-canada-reimbursement',
  CanadaUnaidedPricing            = 'tpp-canada-unaided-pricing',

  ProductAcceptableListPrice      = 'tpp-product-acceptable-list-price',
  ProductPremiumDiscount          = 'tpp-product-premium-discount',

  RegionalAccessVariation         = 'tpp-regional-access-variation',
  InsititutionalFormulary         = 'tpp-institutional-formulary',
}

export enum TargetProductProfileMessage {
  Stimulus = 'tpp-stimulus',
  TPP = 'tpp-tpp',
}

export default {
  SurveyTemplateType: extend(surveyTemplateTypeMap),
};
