import { useContext, useMemo } from 'react';
import { TranscriptionRevisionStatus, WorkspaceObjectType } from '@enums';
import type { IWorkspaceTranscriptItem } from '@/types/workspace.table';
import { FilesTableDataContext } from '../context';
import { useSelectedObjects } from './useSelectedObjects';

export const useUnpublishedTranscripts = () => {
  const items = useContext(FilesTableDataContext);

  const selectedObjects = useSelectedObjects();

  return useMemo(() => {
    const filterable = selectedObjects?.length ? selectedObjects : items;
    return filterable.filter(i =>
      [WorkspaceObjectType.CallTranscript, WorkspaceObjectType.File].includes(i.object.typeId) &&
      !!(i as IWorkspaceTranscriptItem).transcript?.id &&
      (i as IWorkspaceTranscriptItem).transcript?.enhancement?.statusId !== TranscriptionRevisionStatus.Completed) as IWorkspaceTranscriptItem[];
  }, [items, selectedObjects]);
};