import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as api from '@api';
import * as enums from '@enums';
import EmailInvite from '@/components/EmailInvite';
import Modal from '@/components/Modal/Modal.Legacy';
import CallRate from './CallRate';
import SurveyRate from './SurveyRate';
import ShareByLink from './ShareByLink';
import type { ModalProps, ProjectLink } from './interfaces';
import styles from './style.css';

const DefaultRate = 250;

const selectGroupFeatures = (state: Store.State) => state.group.features;

const ShareWithExpertModal = ({ project, onClose }: ModalProps) => {
  const groupFeatures = useSelector(selectGroupFeatures);

  const showCallRate = project.projectType !== enums.ProjectType.Survey &&
    groupFeatures.projectLinkRates;
  const showSurveyRate = project.projectType === enums.ProjectType.Survey && groupFeatures.projectLinkRates;
  const initialCallRate = showCallRate ? DefaultRate : null;
  const initialSurveyRate = showSurveyRate ? DefaultRate : null;

  const [hourlyRate, setHourlyRate] = useState<number>(initialCallRate);
  const [hourlyRateDisplay, setHourlyRateDisplay] = useState<number>(initialCallRate);
  const [surveyCharge, setSurveyCharge] = useState<number>(initialSurveyRate);
  const [surveyChargeDisplay, setSurveyChargeDisplay] = useState<number>(initialSurveyRate);
  const [link, setLink] = useState<ProjectLink>(null);
  const [email, setEmail] = useState<string>('');
  const [recipients, setRecipients] = useState<string[]>([]);
  const [invitationMessage, setInvitationMessage] = useState<string>('');

  useEffect(() => {
    api.projects.links.get({
      hourlyRate,
      surveyCharge,
      projectId: project.id,
    })
    .then(setLink);
  }, [hourlyRate, project.id, surveyCharge]);

  const sendEmailInvite = () => {
    api.projects.links.sendSelfSourceInviteEmail({
      hourlyRate,
      message: invitationMessage,
      projectId: project.id,
      recipients,
    }).then(_ => {
      onClose();
    });
  };

  const handleEmailChange = (email: string, submit?: boolean) => {
    if (submit) {
      setEmail('');
      setRecipients([...recipients, email]);
    }
    else {
      setEmail(email);
    }
  };

  const handleSubmitNewEmail = () => {
    setRecipients([...recipients, email]);
    setEmail('');
  };

  const handleRemoveRecipient = (recipient: string) => {
    setRecipients(recipients.filter(f => f !== recipient));
  };

  const sendInvitationEnabled = () => (
    recipients.length > 0
  );

  return (
    <Modal
      onClose={onClose}
      visible={true}>
      <div className={styles.root}>
        <h1
          className={styles.header}>
          {`Onboard experts for the ${project.name} project`}
        </h1>
        {!showCallRate || <CallRate
          allowFree={groupFeatures.allowFreeCalls}
          hourlyRate={hourlyRateDisplay}
          onDragEnd={() => setHourlyRate(hourlyRateDisplay)}
          setDisplayRate={setHourlyRateDisplay}
          setHourlyRate={setHourlyRate} />}
        {!showSurveyRate || <SurveyRate
          allowFree={groupFeatures.allowFreeCalls}
          surveyCharge={surveyChargeDisplay}
          onDragEnd={() => setSurveyCharge(surveyChargeDisplay)}
          setDisplayRate={setSurveyChargeDisplay}
          setHourlyRate={setSurveyCharge} />}
        <ShareByLink
          copyDisabled={false}
          link={link} />
        <div className={styles.label}>Or send an invite to your contacts</div>
        <EmailInvite
          email={email}
          message={invitationMessage}
          recipients={recipients}
          onEmailChange={handleEmailChange}
          onEmailSubmit={handleSubmitNewEmail}
          onMessageChange={setInvitationMessage}
          onRemoveRecipient={handleRemoveRecipient}
          onSend={sendEmailInvite}
          sendEnabled={sendInvitationEnabled()} />
      </div>
    </Modal>
  );
};

export { ShareWithExpertModal as ShareProjectWithExpert };
export default ShareWithExpertModal;