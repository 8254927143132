import { useCallback } from 'react';
import * as surveyBuilder from '@containers/SurveyBuilder/utils';
import { useRichTextEditorRegister } from '@/containers/SurveyBuilder/hooks/useRichTextEditorRegister';
import { useCanCreateFindAndReplace } from '@/containers/SurveyBuilder/hooks/useCanCreateFindAndReplace';
import { QuestionOptionsBuilderContainer } from '@containers/SurveyBuilder.Question/OptionsBuilderContainer';
import { useMaxDiffQuestionBuilderContext } from '@containers/SurveyBuilder.Question/Context';
import { QuestionSettingTitle } from '@presentation/SurveyBuilder';
import type { SurveyRichText } from '@/types/survey';
import type { SurveyRichTextEditorOnChange } from '@/components/Survey.RichText';
import {
  SurveyRichTextEditorContainer,
  useSurveyRichTextEditor, SurveyRichTextEditor,
} from '@/components/Survey.RichText';
import { FindAndReplaceButton } from '@/components/Survey.RichText/Editor.FindAndReplace.Button';
import { BoldButton, UnderlineButton } from '@/components/Remirror';
import { QuestionAttributesBuilder } from './OptionsBuilder';
import Settings from './MaxDiff.Settings';
import { ReuseQuestionOptions } from './ReuseQuestionValues';
import { FindAndReplaceOptions } from './FindAndReplace';
import { OptionContextMenu } from './Option.ContextMenu';
import styles from './style/MaxDiff.Question.css';

export default function MaxDiffQuestionBuilder() {
  const {
    item,
    updateLeastLabel,
    updateMostLabel,
  } = useMaxDiffQuestionBuilderContext();

  const canFar = useCanCreateFindAndReplace();

  return (
    <>
      <div className={styles.labelItem}>
        <div className={styles.label}>Left Label:</div>
        <EditorContainer
          onChange={updateLeastLabel}
          value={item.settings.label.left}
          identifier={`${item.base.identifier}:settings:label:left`} />
      </div>
      <div className={styles.labelItem}>
        <div className={styles.label}>Right Label:</div>
        <EditorContainer
          onChange={updateMostLabel}
          value={item.settings.label.right}
          identifier={`${item.base.identifier}:settings:label:right`} />
      </div>

      <div className={styles.row}>
        <QuestionSettingTitle
          title={`Attributes (${surveyBuilder.maxDiff.MinOptions} minimum)`}>
          <ReuseQuestionOptions />
          {canFar ? <FindAndReplaceOptions question={item} /> : null}
        </QuestionSettingTitle>

        <QuestionOptionsBuilderContainer
          maxOptions={surveyBuilder.maxDiff.MaxOptions}
          minOptions={surveyBuilder.maxDiff.MinOptions}>
          <QuestionAttributesBuilder renderAction={option => (
            <OptionContextMenu
              option={option}
              excludeFindAndReplace={false} />
          )} />
        </QuestionOptionsBuilderContainer>
      </div>

      <Settings />
    </>
  );
}

type EditorProps = {
  onChange: (value: SurveyRichText.RichTextValue) => void;
  value: SurveyRichText.RichTextValue;
  identifier: string;
};

const EditorContainer = ({ onChange, value, identifier }: EditorProps) => {
  const canFar = useCanCreateFindAndReplace();

  const { manager, state, setState } = useSurveyRichTextEditor({
    initialState: value,
    placeholder: 'Enter Label',
  });

  const handleChange: SurveyRichTextEditorOnChange = useCallback(params => {
    if (!params.tr?.docChanged) return;

    setState(params.state);

    const value = params.state.doc.toJSON() as SurveyRichText.RichTextValue;

    onChange(value);
  }, [
    onChange,
    setState,
  ]);

  const editorRef = useRichTextEditorRegister({
    identifier,
  });

  return (
    <SurveyRichTextEditorContainer
      manager={manager}
      onChange={handleChange}
      state={state}
      editorRef={editorRef}>
      <SurveyRichTextEditor className={styles.editor} />
      <BoldButton className={styles.editorBtn} />
      <UnderlineButton className={styles.editorBtn} />
      {canFar ? <FindAndReplaceButton className={styles.editorBtn} /> : null}
    </SurveyRichTextEditorContainer>
  );
};

export { MaxDiffQuestionBuilder };