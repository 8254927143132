import { Component } from 'react';
import { connect } from 'react-redux';
import { NotificationResponse } from '@enums';
import * as api from '@api';
import { Notification, Notifications as NS } from '@/types';
import { NotificationEvent, NotificationType } from '@enums';
import { AdHocCallNotification } from './Notification.AdHocCall';
import { AdHocCallReschedulingNotification } from './Notification.AdHocCallRescheduling';
import { AdHocCallTimesProposedNotification } from './Notification.AdHocCallTimesProposed';
import { AdHocCallAcceptedNotification } from './Notification.AdHocCallAccepted';
import { AdHocCallRequestedNotification } from './Notification.AdHocCallRequested';
import { MessageNotification } from './Notification.Message';
import { ProjectRequestNotification } from './Notification.ProjectRequest';
import { ProjectCallNotification } from './Notification.ProjectCall';
import { ProjectCallInitialRequestNotification } from './Notification.ProjectCallInitialRequest';
import { ProjectCallTimeChangeNotification } from './Notification.ProjectCallTimeChange';
import EmptyNotifications from './Notifications.Empty';
import styles from './style/Notifications.css';

const mapState = (state: Store.State) => ({
  user: state.user,
});

type OwnProps = {
  dropdown?: boolean;
  items: Notification[];
  open?: boolean;
};

type Props =
  OwnProps &
  ReturnType<typeof mapState>;

class Notifications extends Component<Props> {
  static defaultProps = {
    dropdown: false,
    items: [],
  }

  componentDidMount() {
    if (!this.props.dropdown) {
      this.markAsSeen();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.dropdown && !prevProps.open && this.props.open) {
      this.markAsSeen();
    }

    if (this.props.dropdown && prevProps.open && !this.props.open) {
      this.dismissSeen();
    }
  }

  componentWillUnmount() {
    if (!this.props.dropdown) {
      this.dismissSeen();
    }
  }

  dismissSeen = () => {
    const toDismiss = this.props.items
      .reduce((acc, item) => {

        if (!item.payload) {
          return acc;
        }

        let shouldDismiss: boolean;

        if (legacyTypes.includes(item.typeId)) {
          // @ts-ignore
          shouldDismiss = item.payload.statusId && item.seenOn
          // @ts-ignore
            || item.payload.userId
            // @ts-ignore
            && item.payload.userId !== this.props.user.id
            // @ts-ignore
            && item.event.response !== NotificationResponse.Interactive;
        } else {
          // @ts-ignore
          shouldDismiss = item.payload.event.response === NotificationResponse.Ephemeral;
        }

        return shouldDismiss ? acc.concat(item.id) : acc;
      }, [] as number[]);

    if (toDismiss.length) {
      api.users.notification.dismissNotifications({
        notificationIds: toDismiss,
      });
    }
  }

  getStyles = () => {
    if (this.props.dropdown) {
      return `${styles.root} ${styles.dropdown}`;
    }

    return styles.root;
  }

  markAsSeen = () => {
    const notificationIds = this.props.items
      .filter(x => !x.seenOn)
      .map(x => x.id);

    if (notificationIds.length) {
      api.users.notification.markNotificationsSeen({
        notificationIds,
      });
    }
  }

  renderItem = (n: Notification) => {
    if (n.typeId === NotificationType.AdHocCallRequested) {

      return <AdHocCallRequestedNotification item={n} />;
    } else if (n.typeId === NotificationType.AdHocCallAccepted) {

      return <AdHocCallAcceptedNotification item={n} />;
    } else if (n.typeId === NotificationType.AdHocCallTimesProposed) {

      return <AdHocCallTimesProposedNotification item={n} />;
    } else if (n.typeId === NotificationType.AdHocCallRescheduleRequested) {

      return <AdHocCallReschedulingNotification item={n} />;
    } else if (n.typeId === NotificationType.AdHocCallCancelled ||
      n.typeId === NotificationType.AdHocCallRejected ||
      n.typeId === NotificationType.AdHocCallScheduled) {

      return <AdHocCallNotification item={n} />;
    }

    switch (n.event.type) {
      case NotificationEvent.MessageRejected:
        return (
          <MessageNotification item={n} />
        );

      case NotificationEvent.ProjectRequested:
        return (
          <ProjectRequestNotification
            item={n as NS.ProjectRequested.Item} />
        );

      case NotificationEvent.ProjectCallCancelled:
      case NotificationEvent.ProjectCallRejected:
      case NotificationEvent.ProjectCallScheduled:
        return (
          <ProjectCallNotification
            item={n} />
        );

      case NotificationEvent.ProjectCallInitialRequest:
        return (
          <ProjectCallInitialRequestNotification
            item={n as NS.ProjectCallInitialRequest.Item} />
        );

      case NotificationEvent.ProjectCallTimeChange:
        return (
          <ProjectCallTimeChangeNotification
            item={n as NS.ProjectCallRescheduled.Item} />
        );

      default: return null;
    }
  }

  renderItems = () => {
    if (!this.props.items.length) {
      return <EmptyNotifications />;
    }

    return this.props.items.map(x => (
      <div className={styles.item} key={x.id}>
        {this.renderItem(x)}
      </div>
    ));
  }

  render() {
    return (
      <div className={this.getStyles()}>
        {this.renderItems()}
      </div>
    );
  }
}

const legacyTypes = [
  NotificationType.CompleteOnboarding,
  NotificationType.MessageRejected,
  NotificationType.ProjectCallRequest,
  NotificationType.ProjectRequest,
];

export default connect(mapState, null)(Notifications);