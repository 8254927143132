import { Fragment, useState } from 'react';
import { useUpdateUserContactInfo } from '@utils/api';
import { useHydrateEffect } from '@containers/AppReadyState';
import { useSelectUser } from '@containers/Store';
import { NameRequiredForCurrentUser } from '@/components/Modal/NameRequiredForCurrentUser';

type Props = unknown;

export const CompleteCoreUserInfo = (props: Props) => {
  const [open, setOpen] = useState(false);
  const user = useSelectUser();

  const mutation = useUpdateUserContactInfo({
    onSuccess: () => {
      setOpen(false);
    },
  });

  useHydrateEffect(() => {
    if (!user?.profile?.firstName?.trim?.() && !user?.profile?.lastName?.trim?.()) {
      setOpen(true);
    }
  });

  return (
    <Fragment>
      {open &&
        <NameRequiredForCurrentUser
          onSubmit={mutation.mutate}
          submitting={mutation.isLoading} />}
    </Fragment>
  );
};

CompleteCoreUserInfo.displayName = 'CompleteCoreUserInfo';