import { Site, Strategy } from '$website/containers/MarketSegmentation/interfaces';
import { useAssertStrategy } from '$website/containers/MarketSegmentation/hooks/useAssertStrategy';
import { useSegmentedContent } from '$website/containers/MarketSegmentation/hooks/useSegmentedContent';
import { useLoginModal } from '$website/components/AuthModal/useLoginModal';
import { useSignupModal } from '$website/components/AuthModal/useSignupModal';
import * as Action from './Action';
import styles from './style/Navbar.css';

type Props = unknown;

export const Actions = (props: Props) => {
  const content = useButtonAttributes();

  return (
    <div className={styles.actions}>
      <Action.Login
        href={content.nav.button.login.href}
        to={content.nav.button.login.to}
        onClick={content.nav.button.login.onClick}>
        {content.nav.button.login.text}
      </Action.Login>
      <Action.Signup
        to={content.nav.button.cta.to}
        href={content.nav.button.cta.href}
        onClick={content.nav.button.cta.onClick}>
        {content.nav.button.cta.text}
      </Action.Signup>
    </div>
  );
};

Actions.displayName = 'Nav.Actions';

const useButtonAttributes = () => {
  const content = useSegmentedContent();
  const [l, setLoginModalOpen] = useLoginModal();
  const [s, setSignupModalOpen] = useSignupModal();
  const assert = useAssertStrategy();

  const action = assert(Strategy.Enterprise)
      ? { to: content.nav.button.cta.to }
      : { onClick: () => setSignupModalOpen(true) };

  const nav = {
    button: {
      cta: {
        onClick: action.onClick,
        text: content.nav.button.cta.text,
        to: action.to,
      },
      login: {
        onClick: () => setLoginModalOpen(true),
        text: content.nav.button.login.text,
      },
    },
  };

  return { nav } as NavButtonContent;
};

type ButtonAttributeOverrides = {
  onClick?: () => void;
} & Pick<Site.ButtonAttributes, 'text'>
  & Partial<Omit<Site.ButtonAttributes, 'text'>>;

type NavButtonOverrides = {
  cta: ButtonAttributeOverrides;
  login: ButtonAttributeOverrides;
};

type NavButtonContent = {
  nav: {
    button: NavButtonOverrides;
  };
};