import { useCallback, useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as $api from '@api';
import {
  SurveyFormVersionIdContext,
  SurveyFormMetadataContext,
  SurveyFormStepperState,
  SurveyResponseIdentifierContext,
  SaveCompletedSurveyResponseContext,
  SaveDisqualifiedSurveyResponseContext,
  SurveyFormTemplateContext,
  SurveyFormApiContext,
  HideSurveyFormBackContext,
} from '@containers/SurveyForm';
import { SurveyActionType } from '@/enums';
import { ProjectSurveyQuestion } from '@screens/Survey';
import type { Surveys } from '@/services/api/interfaces';
import { SurveyProjectContext } from '@/containers/ProjectOnboarding/Context';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import { LoadingError } from './Error';

type Props = {
  projectId: number;
  userId: string;
  completionUrl: string;
  screenOutUrl: string;
  overquotaUrl: string;
};

export const ProjectSurvey = (props: Props) => {
  const {
    data,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['external-sourcing-get-project-survey-form', props.projectId, props.userId],
    queryFn: () => {
      return $api.projects.externalSourcing.getProjectSurveyForm({
        projectId: props.projectId,
        userId: props.userId,
      }).then(data => data.data);
    },
    enabled: false,
    retry: 1,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (props.userId) {
      refetch();
    }
  }, [
    props.userId,
    refetch,
  ]);

  const handleGoBack = useCallback((data: Surveys.Response.GoBack.Request) => {
    return $api.projects.externalSourcing.goBack({
      projectId: props.projectId,
      userId: props.userId,
      data,
    }).then(response => response.data);
  }, [
    props.projectId,
    props.userId,
  ]);

  const handleSubmitAnswer = useCallback((data: Surveys.Response.SubmitAnswer.Request) => {
    return $api.projects.externalSourcing.submitAnswer({
      projectId: props.projectId,
      userId: props.userId,
      data,
    }).then(response => response.data);
  }, [
    props.projectId,
    props.userId,
  ]);

  const handleSubmitMessageScreen = useCallback((data: Surveys.Response.SubmitMessageScreen.Request) => {
    return $api.projects.externalSourcing.submitMessageScreen({
      projectId: props.projectId,
      userId: props.userId,
      data,
    }).then(response => response.data);
  }, [
    props.projectId,
    props.userId,
  ]);

  const handleSubmitAIEScreen = useCallback((data: Surveys.Response.SubmitAIEScreen.Request) => {
    return $api.projects.externalSourcing.submitAIEScreen({
      projectId: props.projectId,
      userId: props.userId,
      data,
    }).then(response => response.data);
  }, [props.projectId, props.userId]);

  const apis = useMemo(() => ({
    goBack: handleGoBack,
    submitAnswer: handleSubmitAnswer,
    submitMessageScreen: handleSubmitMessageScreen,
    submitAIEScreen: handleSubmitAIEScreen,
  }), [
    handleGoBack,
    handleSubmitAnswer,
    handleSubmitMessageScreen,
    handleSubmitAIEScreen,
  ]);

  const handleSaveSurvey = useCallback(() => {
    return $api.projects.externalSourcing.saveProjectSurveyResponse({
      projectId: props.projectId,
      userId: props.userId,
      data: {
        projectId: props.projectId,
        responseIdentifier: data.response.identifier,
        surveyVersionId: data.survey.id,
      },
    }).then(response => response.data);
  }, [
    props.projectId,
    props.userId,
    data?.response.identifier,
    data?.survey.id,
  ]);

  const handleCompletion = useCallback(() => {
    handleSaveSurvey()
      .then(() => {
        window.location.href = props.completionUrl;
      });
  }, [handleSaveSurvey, props.completionUrl]);

  const handleDisqualification = useCallback((actionType: SurveyActionType) => {
    handleSaveSurvey()
      .then(() => {
        window.location.href = (actionType === SurveyActionType.QuotaOverLimit) ? props.overquotaUrl : props.screenOutUrl;
      });
  }, [handleSaveSurvey, props.screenOutUrl, props.overquotaUrl]);

  if (isError) {
    return <LoadingError />;
  }

  if (isLoading || !data) {
    return (
      <ActivityIndicator show />
    );
  }

  return (
    <SurveyProjectContext.Provider value={data.project}>
      <SurveyFormVersionIdContext.Provider value={data.survey.id}>
        <SurveyFormMetadataContext.Provider value={data.metadata}>
          <SaveCompletedSurveyResponseContext.Provider value={handleCompletion}>
            <SaveDisqualifiedSurveyResponseContext.Provider value={handleDisqualification}>
              <SurveyResponseIdentifierContext.Provider value={data.response.identifier}>
                <SurveyFormTemplateContext.Provider value={data.survey.template}>
                  <SurveyFormApiContext.Provider value={apis}>
                    <HideSurveyFormBackContext.Provider value={true}>
                      <SurveyFormStepperState
                        state={data.response.state}
                        QuestionScreenComponent={ProjectSurveyQuestion} />
                    </HideSurveyFormBackContext.Provider>
                  </SurveyFormApiContext.Provider>
                </SurveyFormTemplateContext.Provider>
              </SurveyResponseIdentifierContext.Provider>
            </SaveDisqualifiedSurveyResponseContext.Provider>
          </SaveCompletedSurveyResponseContext.Provider>
        </SurveyFormMetadataContext.Provider>
      </SurveyFormVersionIdContext.Provider>
    </SurveyProjectContext.Provider>
  );
};

export default ProjectSurvey;