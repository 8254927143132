import { memo } from 'react';
import { cx } from '@utils';
import { Button } from '@/components/Button';
import { StaticButtonProps } from '@/components/Button/interfaces';
import styles from './style/HeaderButton.css';

type Props = {
  className?: string;
} & Omit<StaticButtonProps,
  'children' |
  'variant'>;

export const GroupTableHeaderButton = memo(({ className, ...btnProps }: Props) => {

  return (
    <Button
      {...btnProps}
      variant="brick"
      className={cx(styles.btn, className)} />
  );
});

export default GroupTableHeaderButton;