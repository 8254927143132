import { Navbar } from '@/access/website/components';
import { HorizonalTrinity } from '@/components/Branding/Logo';
import styles from './style/SurveyCompletion.css';

export const SurveyCompletion = () => {

  return (
    <>
      <Navbar.LayoutRoot>
        <div className={styles.nav}>
          <HorizonalTrinity />
        </div>
      </Navbar.LayoutRoot>
      <div className={styles.root}>
        <div className={styles.message}>
          Thank you, you have completed the survey!
        </div>
      </div>
    </>
  );
};