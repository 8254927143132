import { useRepeatSurveyResponsesState } from '@containers/RepeatSurveyResponses/hooks';
import { RepeatSurvey } from '@containers/RepeatSurveyResponses/interfaces';
import Aggregate from './MaxDiff.Data.Aggregate';
import Version from './MaxDiff.Data.Version';
import styles from './style/Data.css';

export default function MaxDiffRepeatData() {
  const { query } = useRepeatSurveyResponsesState<RepeatSurvey.QueryState.MaxDiff>();

  return (
    <div className={styles.root}>
      {query.projectId
        ? <Version />
        : <Aggregate />}
    </div>
  );
}

export { MaxDiffRepeatData };