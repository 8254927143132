import { useContext, useMemo } from 'react';
import { useProjectTagsQuery } from '@/utils/api/project.tags';
import type { ProjectConferenceTagWithMetadata } from '@/types';
import { ProjectTagsQueryContext } from './Context';

type Props = {
  children: React.ReactNode;
  enabled:  boolean;
} & IProjectId;

export const ProjectTagsQueryContainer = (props: Props) => {
  const query = useProjectTagsQuery({
    projectId: props.projectId,
    enabled: props.enabled,
  });

  return (
    <ProjectTagsQueryContext.Provider value={query}>
      {props.children}
    </ProjectTagsQueryContext.Provider>
  );
};

export const useProjectTagsWithChildren = () => {
  const query = useContext(ProjectTagsQueryContext);
  return useMemo(() => {
    if (!query.data) return [];
    return sortTags(query.data.tags.filter(t => !t.parent?.id).map(t => ({
      ...t,
      children: sortTags(query.data.tags.filter(c => c.parent?.id === t.id)),
    })));
  }, [query.data]);
};

function sortTags<T extends ProjectConferenceTagWithMetadata>(tags: T[]) {
  return tags.sort((a, b) => (a.ordinal ?? Number.MAX_VALUE) - (b.ordinal ?? Number.MAX_VALUE));
}

ProjectTagsQueryContainer.displayName = 'Project.Tags.Query.Container';
