import { useCallback, useMemo } from 'react';
import cuid from 'cuid';
import {
  useSubmitSurveyDraft,
  useSurveyLogicState,
} from '@containers/SurveyBuilder';
import type { SurveyLogic } from '@/types';
import { SurveyLogicBuilderContext } from './context';
import { generateDefaultItem } from './hooks';

type Props =
  ChildrenProps;

export const SurveyLogicBuilderContainer = (props: Props) => {

  const [state, dispatch] = useSurveyLogicState();
  const submitDraft = useSubmitSurveyDraft();

  const handleAddItem = useCallback(() => {
    dispatch({
      type: 'logic-unsaved-item-added',
      payload: {
        identifier: cuid(),
      },
    });
  }, [dispatch]);

  const handleRemoveItem = useCallback((identifier: string) => {
    dispatch({
      type: 'logic-item-removed',
      payload: {
        identifier,
      },
    });
    submitDraft();
  }, [dispatch, submitDraft]);

  const handleSave = useCallback((item: SurveyLogic.Item) => {
    dispatch({
      type: 'logic-item-saved',
      payload: { value: item },
    });
    submitDraft();
  }, [dispatch, submitDraft]);

  const toggleEditingOn = useCallback((identifier: string) => {
    dispatch({
      type: 'logic-editing-toggled-on',
      payload: {
        identifier,
      },
    });
  }, [dispatch]);

  const toggleEditingOff = useCallback((identifier: string) => {
    dispatch({
      type: 'logic-editing-toggled-off',
      payload: {
        identifier,
      },
    });
  }, [dispatch]);

  const handleCloneItem = useCallback((identifier: string) => {
    const newItem = generateDefaultItem();
    const item = state.items.find(i => i.identifier === identifier);

    const cloned = {
      ...newItem,
      action: item.action,
      conditions: item.conditions.map(x => {
        return {
          ...x,
          identifier: cuid(),
        };
      }),
      metadata: item.metadata,
      rule: item.rule,
    };

    dispatch({
      type: 'logic-item-added',
      payload: {
        item: cloned,
      },
    });
  }, [state.items, dispatch]);

  const ctx = {
    addItem: handleAddItem,
    cloneItem: handleCloneItem,
    removeItem: handleRemoveItem,
    saveItem: handleSave,
    items: state.items,
    editing: state.editing,
    toggleEditingOn,
    toggleEditingOff,
    display: {
      hideActions: false,
    },
  };

  return (
    <SurveyLogicBuilderContext.Provider value={ctx}>
      {props.children}
    </SurveyLogicBuilderContext.Provider>
  );
};