import { useContext } from 'react';
import { SaveSurveyContext } from '../Context';

const useSaveSurvey = () => {
  const saveSurvey = useContext(SaveSurveyContext);

  return saveSurvey;
};

export { useSaveSurvey };
export default useSaveSurvey;