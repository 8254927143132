import { useState } from 'react';
import { cx } from '@utils';
import connections from 'static/images/website/about/network.png';
import styles from './style/Landing.css';

type Props = unknown;

export const Landing = (props: Props) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.graphic}>
            <img
              alt="infrastructure for collaboration"
              className={cx(styles.img, { [styles.ready]: loaded })}
              onLoad={() => setLoaded(true)}
              src={connections} />
          </div>

          <div className={styles.info}>
            <h1 className={styles.title}>We empower professionals with the infrastructure for <span>collaboration</span></h1>
            <div className={styles.subtitle}>{content.copy1}</div>
            <div className={styles.subtitle}>{content.copy2}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

Landing.displayName = 'About.Landing';

const content = {
  copy1: `Sentiment is dedicated to becoming the best-in-class solution for our customers' research and decision making. How quickly and precisely our customers identify customer needs and ship products determines their success.`,
  copy2: `Our unique quantitative, qualitative research and paid networking technologies are designed to incorporate the voice of the market into the daily decision making of our customers to maximize their success.`,
};