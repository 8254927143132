import { Helmet } from 'react-helmet';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import { pathname } from '@consts';
import { BackLink } from '@presentation/Back';
import styles from './style/Folder.NotFound.css';

export default function FolderNotFound() {
  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Helmet title="Folder not found" />
        <div className={styles.icon}><ErrorIcon /></div>
        <div className={styles.copy}>File does not exist</div>
        <div className={styles.back}>
          <BackLink to={`${pathname.Home}`} />
        </div>
      </div>
    </div>
  );
}

export { FolderNotFound };