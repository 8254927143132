import { useCallback } from 'react';
import { useToggle } from '@utils';
import { MoveObjectModal } from '@/components/WorkspaceObject';

type Props =
  unknown;

export const useMoveObjectModal = () => {
  const [open, toggleVisibility] = useToggle();

  const Modal = useCallback(() => {

    return (
      <MoveObjectModal
        onClose={toggleVisibility}
        open={open} />
    );
  }, [
    open,
    toggleVisibility,
  ]);

  return [toggleVisibility, Modal] as const;
};

export default useMoveObjectModal;