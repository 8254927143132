import { Tag } from 'react-feather';
import { TableEmpty } from '@presentation';

type Props = unknown;

export const TagsTableEmpty = (props: Props) => {
  return (
    <TableEmpty
      Icon={() => <Tag size={120} />}
      message={copy.message}
      note={copy.note} />
  );
};

TagsTableEmpty.displayName = 'Project.Tags.Table.Empty';

const copy = {
  message: `No tags available.`,
  note: `Set up tags to structure quotes from interviews.`,
};