import { useCallback } from 'react';
import { useEditorState } from '@remirror/react';
import { useTranscriptRedactingContext } from '@containers/Transcript.Redaction/hooks';
import type { HighlightWithText } from '@/components/Transcript/interfaces.extension.highlight';
import { useChainedCommands } from './useCommands';
import { useMapDocPosToTimePos } from './useMapPositions';

export const useRedactHighlight = () => {
  const commands = useChainedCommands();

  const state = useEditorState();

  const redactingContext = useTranscriptRedactingContext();

  const mapDocPosToTimePos = useMapDocPosToTimePos();

  return useCallback((highlight: HighlightWithText) => {
    //Mark a highlight or some redaction style on the transcript
    //Convert the physical positions to time positions
    //Mark the time range in the redaction context

    const c = commands
      .setRedaction(highlight)
      .removeHighlights([highlight.id])
      .blurHighlight()
      .run();

    const timeRange = mapDocPosToTimePos({
      from: highlight.from,
      to: highlight.to,
      state,
    });

    redactingContext.addTimeRange(timeRange);
    redactingContext.addSelection(highlight);
  }, [commands, state, redactingContext, mapDocPosToTimePos]);
};

export const useRedactHighlightUndo = () => {
  const commands = useChainedCommands();
  const { selections, setRedacting } = useTranscriptRedactingContext();

  return useCallback(() => {
    for (const s of selections) {
      commands
        .setText(s.text, s)
        .removeHighlights([s.id]);
    }
    commands.run();

    setRedacting(false);
  }, [
    commands,
    selections,
    setRedacting,
  ]);
};