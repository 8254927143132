import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import { useSelectUser } from '@containers/Store/hooks/useSelectUser';

const useUpdateNewUser = () => {
  const me = useSelectUser();
  const dispatch = useDispatch();

  const updateUserIsNew = () => {
    return api.users.updateAccountMetadata({
      isNew: false,
      userId: me.id,
    })
    .then(data => dispatch(actions.userInfoChange({ meta: data.meta })))
    .catch(e => console.log(e));
  };

  return useCallback(updateUserIsNew, [
    dispatch,
    me.id,
  ]);
};

export { useUpdateNewUser };
export default useUpdateNewUser;