import { useCallback } from 'react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import * as utils from '@utils';
import { Call } from '@/types';
import Toast from '@/components/Toast';

const useNotifier = () => {
  const state = useSelector((state: Store.State) => state);

  const callRequestSent = useCallback((record: IProjectId & IUserId) => {
    const project = state.projects[record.projectId];
    const user = state.contacts[record.userId];

    return Toast.alert({
      title: 'Call Request Sent',
      body: `You have sent a call invitation to ${user.profile.fullname} for the ${project.name} Project.`,
    });
  }, [state.projects, state.contacts]);

  const callReschedule =  useCallback((data: Pick<Call, 'timeStart'>) => {
    const role = utils.hasClientRole(state.user) ? 'expert' : 'client';

    if (data.timeStart) {
      Toast.alert({
        title: 'Call Reschedule Request Sent',
        body: `The ${role} has been sent your rescheduling request.`,
      });
    } else {
      Toast.alert({
        title: 'New Times Sent',
        body: `The ${role} has been notified of your proposed new times.`,
      });
    }
  }, [state.user]);

  const callScheduled =  useCallback((data: Pick<Call, 'projectId' | 'timeStart'>) => {
    const date = format(data.timeStart, 'M/d/yyyy');
    const time = format(data.timeStart, 'h:mm a');

    const project = state.projects[data.projectId];

    Toast.alert({
      title: 'Call Scheduled',
      body: `You have successfully scheduled a call for the ${project.name} project call on ${date} at ${time}. A calendar invitation has been sent to you as a reminder.`,
    });
  }, [state.projects]);

  const error = useCallback(() => {
    Toast.error({
      title: 'Error',
      body: `We're sorry. There was an issue attempting to create this call request. Please try again.`,
    });
  }, []);

  return {
    callRequestSent,
    callReschedule,
    callScheduled,
    error,
  };
};

export { useNotifier };