import { useContext, useMemo } from 'react';
import { useSurveyFormQuestionContext } from '@containers/SurveyForm';
import { SurveyProjectContext } from '@/containers/ProjectOnboarding/Context';
import Question from './Question';

type Props = {
  hideNavigation?: boolean;
};

export const ProjectSurveyQuestion = (props: Props) => {

  const question = useSurveyFormQuestionContext();
  const project = useContext(SurveyProjectContext);

  const title = useMemo(() => {

    if (question.isScreening) {
      return 'Screening Question';
    }

    if (project) {
      return `${project.name} Survey`;
    }

    return 'Project Survey';
  }, [project, question.isScreening]);

  return (
    <Question
      hideNavigation={props.hideNavigation}
      title={title} />
  );
};

export default ProjectSurveyQuestion;