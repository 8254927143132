import { useCallback } from 'react';
import { useToggle } from '@utils';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import { DeleteSectionAlert } from '@/components/SurveyBuilder.Section/DeleteSectionAlert';
import { useSurveySectionBuilderContext, DeleteSurveySectionContext } from './context';

type Props =
  ChildrenProps;

export const SurveySectionDeleteContainer = (props: Props) => {

  const { section } = useSurveySectionBuilderContext();

  const [_, dispatch] = useSurveyBuilderState();
  const [showAlert, toggleAlert] = useToggle(false);

  const confirmDeletion = useCallback(() => {
    dispatch({
      type: 'remove-section',
      identifier: section.identifier,
    });
    toggleAlert();
  }, [dispatch, toggleAlert, section.identifier]);

  return (
    <DeleteSurveySectionContext.Provider value={toggleAlert}>
      {showAlert &&
        <DeleteSectionAlert
          open={true}
          onConfirm={confirmDeletion}
          onClose={toggleAlert} />
      }
      {props.children}
    </DeleteSurveySectionContext.Provider>
  );
};
