import { useContext } from 'react';
import { ContentPublisherContext } from '../Context';
import * as PostCreation from '../interfaces';

export const useContentPublisherState = (key: PostCreation.PostContentType) => {
  const ctx = useContext(ContentPublisherContext);

  if (!ctx && process.env.__DEV__) {
    throw new Error(`Context value for \`ContentPublisherContext\` is \`undefined\``);
  }

  const state = ctx?.[key];

  if (!state && process.env.__DEV__) {
    throw new Error(`\`${key}\` is not a valid content type.`);
  }

  return state;
};

export const useTextContentPublisher = () => {
  const state = useContentPublisherState(PostCreation.PostContentType.Text);

  return [state.active, state.toggle] as const;
};

export const useVideoContentPublisher = () => {
  const state =  useContentPublisherState(PostCreation.PostContentType.Video);

  return [state.active, state.toggle] as const;
};

export const useContentPublisherContext = () => {
  return useContext(ContentPublisherContext);
};