import { type UserSettingsProps, Currencies } from '@/types';
import { getCurrencySymbol } from '@/utils';
import { Select } from '@/components/Select';
import { NumberInput } from '@/components/Input';
import styles from './style.css';

export type PickerValue = Pick<UserSettingsProps, 'hourlyRate' | 'rateCurrency'>;

type Props = {
  className?: string;
  value: PickerValue;
  onChange: (val: PickerValue) => void;
  minValue?: number;
  maxValue?: number;
};

export const Picker = ({
  value,
  onChange,
  minValue = 0,
  maxValue = 2500,
}: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.currency}>
        <Select
          value={(CurrencyOptions.find(o => o.id === value.rateCurrency) ?? buildUnknownCurrencyOption(value.rateCurrency)).displayVal}
          onSelect={v => onChange({ rateCurrency: v.id, hourlyRate: value.hourlyRate })}
          getItemValue={o => o.displayVal}
          options={CurrencyOptions} />
      </div>
      <div className={styles.value}>
        <NumberInput
          onValueChange={e => onChange({ hourlyRate: e.floatValue, rateCurrency: value.rateCurrency })}
          value={value.hourlyRate}
          isAllowed={val => (val.floatValue >= minValue && val.floatValue <= maxValue)}
          min={minValue}
          max={maxValue} />
      </div>
    </div>
  );
};

const CurrencyOptions = Currencies.CuratedCurrencies.map(code => ({
  id: code,
  displayVal: `${code} (${getCurrencySymbol(code)})`,
}));

function buildUnknownCurrencyOption(code: string) {
  return {
    id: code,
    displayVal: code,
  };
}