import { useCallback, useMemo } from 'react';
import { cookies } from '@/consts';
import { useCookie } from '@/utils';

export type CameraPreferences = {
  blurBackground: boolean;
};

function getDefaultPreferences(): CameraPreferences {
  return {
    blurBackground: false,
  };
}

export const useConferenceCameraPreferences = () => {
  const [cookieVal, setCookie] = useCookie(cookies.conferenceCameraPreferences, JSON.stringify(getDefaultPreferences()), { watchForChanges: true });
  const preferences = useMemo(() => cookieVal ? JSON.parse(cookieVal) as CameraPreferences : getDefaultPreferences(), [cookieVal]);

  const setPreferences = useCallback((partialPref: Partial<CameraPreferences>) => {
    const unified = Object.assign({}, preferences, partialPref);
    setCookie({
      value: JSON.stringify(unified),
      expires: cookies.MAX_COOKIE_EXPIRATION,
    });
  }, [setCookie, preferences]);

  return [preferences, setPreferences] as const;
};