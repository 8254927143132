import { useContext } from 'react';
import { ProjectSurveyRespondentContext } from '../Context';

export const useProjectSurveyRespondent = () => {
  const state = useContext(ProjectSurveyRespondentContext);

  return state;
};

export default useProjectSurveyRespondent;
