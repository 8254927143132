import { useCallback, useState, memo } from 'react';
import { format } from 'date-fns';
import IconButton from '@mui/material/IconButton';
import ChevronLeft from '@mui/icons-material/ChevronLeftRounded';
import ChevronRight from '@mui/icons-material/ChevronRightRounded';
import { useSelectUser } from '@containers/Store/hooks';
import * as enums from '@enums';
import { UserPayoutHistoryItem } from '@/types';
import { cx } from '@utils';
import { usePaginatedUserPayoutHistory } from '@utils/api';
import { AnimatedLoader } from '@/components/ActivityIndicator';
import styles from './style/Settings.UserBilling.PayoutList.css';

export default function PayoutList() {
  const [page, setPage] = useState<number>(1);
  const user = useSelectUser();
  const history = usePaginatedUserPayoutHistory(user.id, page, {
    refetchOnWindowFocus: false,
  });

  const handlePage = useCallback((mode: 'prev' | 'next') => () => {
    setPage(prev => mode === 'prev' ? Math.max(1, prev - 1) : prev + 1);
  }, []);

  if (history.isLoading) return <Loading />;
  if (!history.data?.items?.length) return <Empty />;

  return (
    <div className={styles.root}>
      <div className={styles.pagination}>
        <div className={styles.controls}>
          <IconButton
            className={styles.iconBtn}
            disableRipple={true}
            disabled={page === 1}
            onClick={handlePage('prev')}>
            <ChevronLeft className={styles.chevron} />
          </IconButton>
          <IconButton
            className={styles.iconBtn}

            disableRipple={true}
            disabled={history.data?.next == null}
            onClick={handlePage('next')}>
            <ChevronRight className={styles.chevron} />
          </IconButton>
        </div>
      </div>
      <div className={styles.table}>
        <div className={styles.thead}>
          <div className={styles.tr}>
            <div className={styles.th}>Id</div>
            <div className={styles.th}>Description</div>
            <div className={styles.th}>Amount</div>
            <div className={styles.th}>Status</div>
            <div className={styles.th}>Date</div>
          </div>
        </div>
        <div className={styles.tbody}>
          {history.data?.items?.map(payout => <Payout key={`${payout.id}`} {...payout} />)}
        </div>
      </div>
    </div>
  );
}

type PayoutProps =
  UserPayoutHistoryItem;

const Payout = memo(({ id, amount, description, statusId, createdOn }: PayoutProps) => {
  const status = getPayoutStatusLabel(statusId);
  const amountFormatted = `$${parseFloat(amount.toString()).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

  return (
    <div className={styles.tr}>
      <div className={styles.td}>{id}</div>
      <div className={styles.td}>{description}</div>
      <div className={styles.td}>{amountFormatted}</div>
      <div className={styles.td}>
        <div className={styles[status.toLowerCase()]}>{status}</div>
      </div>
      <div className={styles.td}>{format(createdOn, 'yyyy-MM-dd')}</div>
    </div>
  );
});

const Empty = memo(() => {
  return (
    <div className={styles.nocharges}>
      <div>No payout history available.</div>
      <div>When interactions are completed, your payout history will display here.</div>
    </div>
  );
});

const Loading = memo(() => {
  return (
    <div className={cx(styles.root, styles.loading)}>
      <AnimatedLoader />
    </div>
  );
});

function getPayoutStatusLabel(value: enums.PayoutStatus) {
  switch (value) {
    case enums.PayoutStatus.Paid: return 'Paid';
    case enums.PayoutStatus.Failed: return 'Failed';
    case enums.PayoutStatus.Open: return 'Open';
    case enums.PayoutStatus.Refunded: return 'Refunded';
    default: return 'Pending';
  }
}

export { PayoutList };