import { useContext } from 'react';
import type { SurveyQuestionType } from '@enums';
import { ProjectSurveyResponsesStateContext } from '@containers/SurveyResponses/Context';
import type { SurveyResponsesState } from '@containers/SurveyResponses/interfaces';

export const useProjectSurveyResponsesState = <T extends SurveyQuestionType = SurveyQuestionType>() => {
  const context = useContext(ProjectSurveyResponsesStateContext);
  return context as SurveyResponsesState<T>;
};

export default useProjectSurveyResponsesState;