import { applyMiddleware, compose } from '@reduxjs/toolkit';
import { composeWithDevTools } from '@redux-devtools/extension';
import alerts from './alerts';
import logger from './logger';
import routing from './routing';
import thunk from './thunk';

const middleware = [ thunk, routing, alerts, logger ]
  .reduce((acc, mw) => mw.apply(acc), []);

const isProd = process.env.VANCERY_ENV === 'production';

const composeEnhancers = (!isProd && composeWithDevTools) || compose;

const enhancer = composeEnhancers(applyMiddleware(...middleware));

export default enhancer;