import styles from './styles/NetworkTest.css';

export const NetworkTest = () => {
  return (
    <iframe
      className={styles.iframe}
      src="https://networktest.twilio.com/"
      allow='microphone; camera; autoplay; encrypted-media; display-capture'
      title="Twilio Network Test" />
  );
};