import { createElement, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import ButtonBase from './ButtonBase';
import type { LinkButtonProps, StaticButtonAttributes } from './interfaces';

type Props = LinkButtonProps;

const LinkButton = ({
  color,
  href,
  replace = false,
  target,
  to,
  variant,
  ...rest
}: Props, ref: React.Ref<StaticButtonAttributes>) => {
  const isExternal = !!href;
  const element = isExternal ? 'a' : Link;
  const disjointedProps = isExternal
      ? getAnchorProps({ target })
      : { replace };
  const linkProps = { href, to, ...disjointedProps };
  const btnProps = { color, variant };

  const isLinkVariant = variant === 'link';

  const props = isLinkVariant
      ? { ...linkProps, ...rest, ref }
      : linkProps;
  const children = isLinkVariant
      ? rest.children
      : <ButtonBase
        {...btnProps}
        {...rest}
        ref={ref} />;

  return createElement(element, props, children);
};

LinkButton.displayName = 'Button.Link';

const LinkButtonWithRef = forwardRef<StaticButtonAttributes, Props>(LinkButton);

export { LinkButtonWithRef as LinkButton };

function getAnchorProps(props: Pick<LinkButtonProps, 'target'>) {
  if (!props.target) return {};

  return {
    rel: 'noreferrer',
    target: props.target,
  };
}