import { useContext, useMemo, useState } from 'react';
import { format } from 'date-fns';
import styled from '@emotion/styled';
import { GroupSidebarTags } from '@/components/GroupSidebarTags';
import Pencil from '@/components/icons/Pencil';
import { Input } from '@/components/Input';
import { GroupCallTagsContainer, useGroupEntityTags, useGroupEntityTagsUpdate, useGroupEntityTagsLoading } from '@/containers/GroupTags';
import { useIsInternalUser, useSelectUser } from '@/containers/Store';
import { CallParticipantsContainer } from '@/containers/Transcript';
import { CallParticipantsQueryContext, CallTranscriptDetailsContext, TranscriptIdContext, TranscriptMetadataContext } from '@/containers/Transcript/context';
import { FormButtons } from '@/presentation/FormButtons';
import * as Tab from '@/presentation/Workspace.Tab.Pane';
import { PublishedDate } from '@screens/Workspace.Transcript/Header.PublishedOn';
import { useHasGroupFeature } from '@/store/hooks';
import { useConditionalRender, hasInternalAdminRole } from '@/utils';
import { PublishSettingsSelectInline } from '@/components/Transcript/Publishing';
import { CallDetailsParticipants as Participants } from './Details.Participants';
import { useCallTranscriptParams } from './hooks/useCallTranscriptParams';

type Props = unknown;

export const Details = (props: Props) => {

  const { callId } = useCallTranscriptParams();

  return (
    <GroupCallTagsContainer callId={callId}>
      <CallParticipantsContainer callId={callId}>
        <DetailsContent />
      </CallParticipantsContainer>
    </GroupCallTagsContainer>
  );
};

Details.displayName = 'Transcript.Tab.Details';

const DetailsContent = (props: Props) => {
  const user = useSelectUser();

  const transcriptId = useContext(TranscriptIdContext);
  const participants = useContext(CallParticipantsQueryContext);
  const tags = useGroupEntityTags();
  const update = useGroupEntityTagsUpdate();
  const tagsLoading = useGroupEntityTagsLoading();
  const details = useContext(CallTranscriptDetailsContext);
  const metadata = useContext(TranscriptMetadataContext);
  const [editingPublished, setEditingPublished] = useState<boolean>(false);
  const [editingTitle, setEditingTitle] = useState<boolean>(false);

  const isAdmin = useMemo(() => hasInternalAdminRole(user), [user]);
  const $Owner = useConditionalRender(isAdmin);
  const $Tags = useConditionalRender(useHasGroupFeature('userTagging'));

  const isInternalUser = useIsInternalUser();

  if (tagsLoading || participants.isLoading) return null;

  return (
    <Tab.Panes.Container>
      {details.call &&
        <Tab.Pane.Pane>
          <Tab.Pane.Title>Details</Tab.Pane.Title>
          <Tab.Items.Container>
            <Tab.Item.Container>
              <Tab.Item.TextLabel>Title</Tab.Item.TextLabel>
              <Tab.Item.Value>
                <TranscriptTitle
                  editing={editingTitle}
                  setEditing={setEditingTitle}
                  onCancel={() => {
                    setEditingTitle(false);
                  }}
                  onSubmit={value => {
                    metadata.updateMetadata({ title: value }).then(() => {
                      setEditingTitle(false);
                    });
                  }}
                  value={metadata.title} />
              </Tab.Item.Value>
            </Tab.Item.Container>
            <$Owner>
              <Tab.Item.Container>
                <Tab.Item.TextLabel>Owner</Tab.Item.TextLabel>
                <Tab.Item.Value>{details.call.owner.name}</Tab.Item.Value>
              </Tab.Item.Container>
            </$Owner>
            <Tab.Item.Container>
              <Tab.Item.TextLabel>Created</Tab.Item.TextLabel>
              <Tab.Item.Value>{format(new Date(details.call.createdOn), 'MM/dd/yy')}</Tab.Item.Value>
            </Tab.Item.Container>
            <Tab.Item.Container>
              <Tab.Item.TextLabel>Published</Tab.Item.TextLabel>
              <Tab.Item.Value>
                <PublishedDate
                  editing={editingPublished}
                  setEditing={setEditingPublished}
                  onCancel={() => {
                    setEditingPublished(false);
                  }}
                  onSubmit={value => {
                    metadata.updateMetadata({ publishedOn: value });
                    setEditingPublished(false);
                  }}
                  value={metadata.publishedOn} />
              </Tab.Item.Value>
            </Tab.Item.Container>
            {isInternalUser &&
              <Tab.Item.Container>
                <Tab.Item.TextLabel>Publish Setting</Tab.Item.TextLabel>
                <Tab.Item.Value>
                  <PublishSettingsSelectInline
                    publishType={details?.settings?.publishType}
                    transcriptId={transcriptId} />
                </Tab.Item.Value>
              </Tab.Item.Container>}
          </Tab.Items.Container>
        </Tab.Pane.Pane>}
      {isInternalUser &&
        <Tab.Pane.Pane>
          <Participants />
        </Tab.Pane.Pane>}
      <$Tags>
        <Tab.Pane.Pane>
          <GroupSidebarTags
            tags={tags}
            onSave={update} />
        </Tab.Pane.Pane>
      </$Tags>
    </Tab.Panes.Container>
  );
};

DetailsContent.displayName = 'Transcript.Tab.Details.Content';

type TitleProps = {
  editing: boolean;
  setEditing: (value: boolean) => void;
  onCancel: () => void;
  onSubmit: (value: string) => void;
  value: string;
};

const TranscriptTitle = (props: TitleProps) => {
  if (!props.editing) {
    return (
      <TranscriptTitleRoot>
        <div>
          {props.value
            ? props.value
            : <TranscriptTitleNotSet>{`Not Set`}</TranscriptTitleNotSet>}
        </div>
        <TranscriptTitlePencil
          onClick={() => props.setEditing(true)}
          size={16} />
      </TranscriptTitleRoot>
    );
  }

  return (
    <EditingTranscriptTitle
      editing={props.editing}
      value={props.value}
      onSubmit={props.onSubmit}
      onCancel={props.onCancel}
      setEditing={props.setEditing} />
  );
};

const EditingTranscriptTitle = (props: TitleProps) => {
  const [state, setState] = useState<string>(props.value ?? '');

  const invalid = state.trim().length < 3;

  return (
    <TranscriptTitleRoot>
      <TranscriptTitleEditInputWrap>
        <Input
          onChange={e => setState(e.target.value)}
          value={state} />
      </TranscriptTitleEditInputWrap>
      <StyledFormButtons
        size={16}
        disabled={invalid}
        onCancel={props.onCancel}
        onSubmit={() => props.onSubmit(state)} />
    </TranscriptTitleRoot>
  );
};

const TranscriptTitlePencil = styled(Pencil)`
  cursor: pointer;
  color: var(--pri-02);
  margin-left: 10px;
  opacity: 0;
  transition: all 200ms;
  height: 16px;
  width: 16px;
`;

const TranscriptTitleRoot = styled.div`
    display: flex;
    align-items: center;

    &:hover:not(:disabled) > ${TranscriptTitlePencil} {
      opacity: 1;
    }
`;

const TranscriptTitleEditInputWrap = styled.div`
  width: 500px;

  input {
    font-size: 14px;
    letter-spacing: normal;
  }
`;

const TranscriptTitleNotSet = styled.span`
  color: var(--gray);
`;

const StyledFormButtons = styled(FormButtons)`
  margin-left: 5px;
`;