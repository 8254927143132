// eslint-disable-next-line import/newline-after-import
require('./sentry');
import { memo } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { withProfiler } from '@sentry/react';
import * as $env from '@/consts/environment';
import { AppSubscriber } from '@/containers/Subscriber';
import { ZendeskScript } from '@/containers/Zendesk';
import { useAppRoutes } from '@/routes/hooks';
import { PathSajak, useGetReturnRoute } from '@/routes';
import { BuildInfo } from '@/components/BuildInfo';
import { CookieConsent } from '@/components/CookieConsent';
import Toast from '@/components/Toast';
import '@/services/chat';
import '@/services/network';
import styles from '@/static/css/global/style.css';

const UnprofiledApp = () => {
  const returnRoute = useGetReturnRoute();

  if (returnRoute) {
    return <Redirect to={returnRoute} />;
  }

  return (
    <>
      <AppSubscriber>
        <WithoutSubscriptions />
      </AppSubscriber>
      <Toast />
    </>
  );
};

const WithoutSubscriptions = () => {
  const routes = useAppRoutes();

  return (
    <>
      <div className={styles.root}>
        <DocumentHelmet />
        <ZendeskScript />
        <BuildInfo />
        <CookieConsent />

        <PathSajak routes={routes} />

      </div>
    </>
  );
};

export const App = withProfiler(UnprofiledApp);

const DocumentHelmet = memo(() => {
  const description = `Sentiment is a healthcare research panel organized and led by Trinity Life Sciences.`;

  const isProd = process.env.VANCERY_ENV === $env.Production;

  return (
    <Helmet
      defaultTitle="Sentiment"
      titleTemplate="%s | Sentiment">
      {!isProd &&
        <meta name="robots" content="noindex" />
      }
      {isProd &&
        <link
          rel="sitemap"
          href={`${process.env.PUBLIC_URL}/sitemap.xml`}
          type="application/xml" />
      }
      <link
        rel="canonical"
        href={`${process.env.FRONT_BASE_URL}/`} />
      <meta
        name='description'
        content={description} />
      <meta
        name='og:description'
        content={description} />
    </Helmet>
  );
});