import { usePersistentProjectInvite, useProjectInviteWhitelabelState } from '@containers/ProjectLinkInvitation/hooks';
import { useSignupModal } from '$website/components';
import { Button } from '@/components/Button';
import { ButtonColor } from '@/components/Button/interfaces';
import styles from './style/ResearchCommunity.css';

type Props = unknown;

export const SignUpButton = (props: Props) => {
  const [s, setSignupModalOpen] = useSignupModal();
  const whitelabel = useProjectInviteWhitelabelState();
  const urlstate = usePersistentProjectInvite();
  const state = urlstate.get();

  const btn = {
    color: whitelabel.enabled ? `transparent` : `primary` as ButtonColor,
    style: whitelabel.enabled ? {
      backgroundColor: state.data.theming.palette.primary.main,
      borderColor: state.data.theming.palette.primary.main,
      color: state.data.theming.palette.primary.text,
    } : {},
  };

  return (
    <Button
      className={styles.signup}
      color={btn.color}
      onClick={() => setSignupModalOpen(true)}
      variant="pill"
      style={btn.style}>
      Sign Up
    </Button>
  );
};

SignUpButton.displayName = 'ResearchCommunity.SignUpButton';