import { useCallback, useMemo } from 'react';
import { generatePath } from 'react-router';
import { path } from '@consts';
import { usePopupWindow } from '@utils/hooks/usePopupWindow';
import { useConferenceNotes } from './useConferenceNotes';

type Params = {
  onWindowClose?: () => void;
} & ICallId;

const height = '400px';
const width = '400px';

export const usePopupNotesWindow = ({ callId, onWindowClose: windowCloseParam }: Params) => {
  const { query } = useConferenceNotes(callId);
  const url = useMemo(() => generatePath(path.Conference.MiniNotes, { callId }), [callId]);

  const onWindowClose = useCallback(() => {
    query.refetch();
    if (windowCloseParam)
      windowCloseParam();
  }, [windowCloseParam, query]);

  const { close, isOpen, open } = usePopupWindow({
    onClosed: onWindowClose,
    url,
    windowOptions: {
      height, width,
    },
  });

  return { close, isOpen, open };
};