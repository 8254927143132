import { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as actions from '@actions';
import * as api from '@api';
import * as API from '@api/interfaces';
import { Settings } from '@/types';

type Data = API.Settings.UpdateEmailSettings.Response;
type Variables = Partial<Settings.Email>;
type Context = Settings.Email;

type Options = UseMutationOptions<Data, AxiosResponse, Variables, Context>;

const selectEmailSettings = (state: Store.State) => state.settings.email;

export const useUpdateUserEmailSettings = (options?: Options) => {
  const settings = useSelector(selectEmailSettings);
  const dispatch = useDispatch();

  return useMutation(['update-user-email-settings'], (data: Variables) => {

    dispatch(actions.preferencesSettingChanged({
      email: {
        ...settings,
        ...data,
      },
    }));

    return api.settings.updateEmailSettings(data)
      .catch(e => {
        dispatch(actions.preferencesSettingChanged({ email: settings }));
        throw e;
      });
  }, options);
};