/** @jsxImportSource @emotion/react */
import isMobile from 'ismobilejs';
import type { SerializedStyles} from '@emotion/react';
import { css } from '@emotion/react';
import type { ReactNode } from 'react';
import { useThemedStyles } from '../Theme';
import { UserAuthor } from './Message.Author';
import { authorSpacing, messageStyles } from './Message.styles';
import { useChatInstanceAuthor } from './hooks';

type Props = {
  classes?: {
    body?: SerializedStyles;
    root?: SerializedStyles;
  };
  value: ReactNode;
};

export const UserMessage = ({
  classes = {
    body: null,
    root: null,
  },
  value,
}: Props) => {

  const device = isMobile();
  const styles = useStyles();

  const author = useChatInstanceAuthor();

  return (
    <div css={css(styles.root, classes.root)}>
      <div css={css(styles.body, classes.body)}>
        {value}
      </div>
      {!device.phone && (
        <div css={styles.authorWrap}>
          <UserAuthor
            author={author}
            css={styles.author} />
        </div>
      )}
    </div>
  );
};

const authorWrapCss = css([messageStyles.authorWrap, {
  marginLeft: authorSpacing,
}]);

const authorCss = css(messageStyles.author);

const rootCss = css([messageStyles.root, {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-end',
}]);

const bodyCss = (theme: Theme) => css([messageStyles.body, {
  backgroundColor: theme.palette.white.main,
  border: `2px solid ${theme.palette.gray.light2}`,
}]);

const useStyles = () => useThemedStyles({
  author: authorCss,
  authorWrap: authorWrapCss,
  body: bodyCss,
  root: rootCss,
});