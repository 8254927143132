import type { SurveyRichText } from '@/types/survey.rich-text';
import { Remirror } from '@/types';
import { NodeType } from '@/types/rich-text';

export function isSurveyRichTextEmpty(value: SurveyRichText.RichTextValue) {

  function isParagraphNodeEmpty(node: SurveyRichText.ParagraphNode) {
    const content = (node.content || []);
    if (!content.length) return true;

    return content.every(isParagraphContentEmpty);
  }

  return (value.content || []).reduce((acc, node) => {
    if (!acc) return acc;

    if (node.type === Remirror.NodeType.BulletList) {
      const isEmpty = node.content
        .some(listItem => listItem.content.every(isParagraphNodeEmpty));

      return isEmpty;
    } else if (node.type === Remirror.NodeType.Paragraph) {
      const isEmpty = isParagraphNodeEmpty(node);
      return isEmpty;
    }
  }, true);
}

function isParagraphContentEmpty(node: SurveyRichText.ParagraphContent) {

  if (node.type === Remirror.NodeType.Text) {
    return !node.text.trim().length;
  }
  else if (node.type === Remirror.NodeType.FindAndReplace) {
    return !node.attrs.label.trim().length;
  }

  return false;
}

function isRichTextAnImage(node: SurveyRichText.RootNode) {
  if (node.content.length !== 1) return false;

  const content = node.content[0];

  if (content.content.length !== 1) return false;

  const innerContent = content.content[0];

  return innerContent.type === NodeType.Image;
}