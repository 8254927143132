import { cx } from '@utils';
import styles from './style.css';

type ButtonsProps = {
  className?: string;
  gap?: number;
} & ChildrenProps;

export const Buttons = ({ gap = 20, ...props }: ButtonsProps) => {
  return (
    <div className={cx(styles.buttons, props.className)} style={{ gap }}>
      {props.children}
    </div>
  );
};