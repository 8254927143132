import { ProfileEntry } from '@enums';
import { useAccountProfileEditState } from './useAccountProfileEditState';

const useAccountProfileNewEntry = (id: ProfileEntry) => {
  const [entryId, onEditStateChange] = useAccountProfileEditState();

  const newEntryKey = `${id}-new`;

  const toggleNewEntry = onEditStateChange(newEntryKey);
  const editingNewEntry = entryId === newEntryKey;

  return [editingNewEntry, toggleNewEntry] as const;
};

export { useAccountProfileNewEntry };
export default useAccountProfileNewEntry;