import { Route, Redirect, Switch } from 'react-router-dom';
import { path } from '@consts';
import { BackLink } from '@presentation/Back';
import { cx } from '@utils';
import { useSettingsMenuItems } from './routes';
import { useIsDesktopSettings, useIsSettingsRoot } from './hooks';
import styles from './style/Content.css';

type Props = {
  className?: string;
};

export const SettingsContent = ({ className }: Props) => {

  const items = useSettingsMenuItems();
  const isDesktop = useIsDesktopSettings();
  const isSettingsRoot = useIsSettingsRoot();

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.wrap}>
        {!isDesktop && !isSettingsRoot &&
          <BackLink
            className={styles.back}
            to={path.Settings.Root} />}
        <Switch>
          {items.map(x =>
            <Route
              key={String(x.path)}
              component={x.component}
              exact={x.exact}
              path={x.path} />)}
          {isDesktop &&
            <Redirect to={path.Settings.Account} />}
        </Switch>
      </div>
    </div>
  );
};

export default SettingsContent;