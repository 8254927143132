import queryString from 'query-string';
import * as enums from '@enums';
import http from '@services/http';
import * as xform from '@transformers';

export const updateUserLinkedin = data => {
  return http.patch(`/admin/users/${data.userId}/linkedin`, data);
};

export const getKeywords = data => {
  return http
    .get(`/admin/keywords?${queryString.stringify(data)}`)
    .then(d => {
      return d.map(k => ({
        ...k,
        name: xform.titlecase(k.name),
      }));
    });
};

export const updateKeywordVerificationStatus = data => {
  return http.patch(`/admin/keywords/${data.id}`, { verificationStatusId: data.verificationStatusId });
};

export const getReferralToken = data => {
  return http.get(`/admin/members/${data.userId}/referral-token`)
  .then(r => ({
    ...(xform.assignLinkUrl({ token: { value: r, typeId: enums.TokenType.Referral }})),
  }));
};