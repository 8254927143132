import { useCallback, useMemo } from 'react';
import { ResponseOptionFreeTextItem, ResponseTextItemProps } from '@presentation/ProjectSurveyResponses';
import type { SurveyQuestion, SurveyQuestionOption, SurveyAggregate, SurveySettings, HybridSurveyResponseUserId } from '@/types';
import { ResponseTextList, useSortStarrableResponses } from './ResponseText';
import { ResponseOptionUser } from './ResponseOptionUser';

type Props = {
  option: SurveyQuestionOption;
  question: SurveyQuestion;
  rationaleMap: SurveyAggregate.RationaleMap;
  text?: {
    [userId: HybridSurveyResponseUserId]: string;
  };
  userIds: HybridSurveyResponseUserId[];
};

export const ResponseOptionUsers = ({ option, question, rationaleMap, text = {}, userIds }: Props) => {

  const sortValues = useSortStarrableResponses(question.base.id);

  const items = useMemo(() => {
    return userIds.map(userId => {
      return {
        userId,
        value: text[userId],
      };
    });
  }, [text, userIds]);

  const sortedItems = useMemo(() => {
    return sortValues(items);
  }, [
    items,
    sortValues,
  ]);

  const renderFreeTextItem = useCallback((data: ResponseTextItemProps) => {
    return (
      <ResponseOptionFreeTextItem {...data} />
    );
  }, []);

  type IsOpenEnded = { isOpenEnded: boolean };
  // @ts-ignore
  const isOther = (option.metadata as IsOpenEnded).isOpenEnded;
  const hasRationale = (question.settings as SurveySettings.AskForRationale).rationale?.enabled;
  const showAdvancedRow = isOther || hasRationale;

  if (showAdvancedRow) {
    return (
      <ResponseTextList
        items={sortedItems}
        question={question}
        rationaleMap={rationaleMap}
        renderItem={renderFreeTextItem} />
    );
  } else {
    return (
      <>
        {items.map(m => (
          <ResponseOptionUser
            key={m.userId}
            userId={m.userId}
            value={m.value} />
        ))}
      </>
    );
  }
};

export default ResponseOptionUsers;