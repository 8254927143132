import { AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as api from '@api';
import { GetVideoUrl } from '@api/interfaces/calls';

type Props = GetVideoUrl.Request;

type Data = GetVideoUrl.Response;
type QueryKey = readonly [string, Props];

export function useCallVideoUrl(
  data: Props,
  options?: UseQueryOptions<Data, AxiosResponse, Data, QueryKey>
) {

  return useQuery(['call-video-url', data], ({ queryKey }) => {
    const params = queryKey[1];

    return api.calls.getVideoUrl(params);
  }, options);
}
