import styled from '@emotion/styled';
import type { WorkspaceFileSummarySection } from '@/types/workspace.file';
import { SummarySection } from './Summary.Section';

type Props = {
  className?: string;
  items: WorkspaceFileSummarySection[];
};

export function SummarySections({ className, items }: Props) {
  return (
    <Root className={className}>
      {items.map((item, i) => (
        <SummarySection key={`${i}`} item={item} />
      ))}
    </Root>
  );
}

const Root = styled.div`
  > :not(:last-child) {
    margin-bottom: 20px;
  }
`;