import { useCallback, useState } from 'react';
import { useTargetProductProfileData, useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';

export const useCanSaveTrialDesign = (value: string) => {

  return value.length >= 2;
};

export const useTemplateTrialDesign = () => {
  const [templateData] = useTargetProductProfileData();
  const [trialDesign, setTrialDesign] = useState(templateData.trialDesign);
  const [_, dispatch] = useSurveyBuilderState();

  const onSubmit = useCallback(() => {
    dispatch({
      type: 'template-trial-design-updated',
      value: trialDesign,
    });
  }, [
    trialDesign,
    dispatch,
  ]);

  return {
    onChange: setTrialDesign,
    onSubmit,
    value: trialDesign,
  };
};