import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { menu } from '@consts';
import { selectUnreadMessagesCount } from '@store/selectors';
import { BadgeNotificationsContext } from './Context';

const selectUnreadNotifications = (state: Store.State) => state.notifications.items.length;

type Props = {
  children: React.ReactElement;
};

export const BadgeNotificationsContainer = (props: Props) => {
  const unreadNotifications = useSelector(selectUnreadNotifications);
  const unreadMessages = useSelector(selectUnreadMessagesCount);

  const ctx = useMemo(() => {
    const total = unreadNotifications + unreadMessages;

    return {
      [menu.Messages]: unreadMessages,
      [menu.Notifications]: unreadNotifications,
      total,
    };
  }, [unreadNotifications, unreadMessages]);

  return (
    <BadgeNotificationsContext.Provider value={ctx}>
      {props.children}
    </BadgeNotificationsContext.Provider>
  );
};