import { Twitter as TwitterIcon } from 'react-feather';
import qs from 'query-string';
import { cx } from '@utils';
import { Button } from '@/components/Button';
import styles from './style/Link.css';

type Props = {
  classes?: {
    root?: string;
    icon?: string;
  };
  message?: string;
  url:      string;
};

export const Twitter = (props: Props) => {

  const url = qs.stringifyUrl({
    url: shareUrl,
    query: {
      text: `${props.message} ${props.url}`,
    },
  });

  return (
    <Button
      className={cx(styles.twitter, props.classes.root)}
      color="transparent"
      href={url}
      target="_blank"
      variant="brick">
      <TwitterIcon className={cx(styles.icon, props.classes.icon)} />
      Twitter
    </Button>
  );
};

const defaultProps = {
  classes: {},
};

Twitter.defaultProps = defaultProps;
Twitter.displayName = 'ProjectInviteReferral.Link.Twitter';

const shareUrl = `https://twitter.com/intent/tweet`;