import { useCallback, useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Chat } from '@/brand-insights/types';
import * as $api from '@/brand-insights/api';
import { ChatStateDispatchContext } from '@/brand-insights/components/Chat.State';
import { ChatSessionQueriesContext } from '@/brand-insights/components/Chat/context';

type Props = {
  chatInstanceId: number;
  queryIdentifier: string;
  onSuccess?: () => void;
};

export const useUpdateQueryFeedbackMutation = ({ chatInstanceId, queryIdentifier, onSuccess }: Props) => {

  const sessionQueries = useContext(ChatSessionQueriesContext);
  const dispatch = useContext(ChatStateDispatchContext);

  const dispatchRating = useCallback((rating: Chat.Rating) => {
    dispatch({
      type: 'query-rated',
      payload: {
        queryIdentifier,
        rating,
      },
    });
  }, [dispatch, queryIdentifier]);

  const mutation = useMutation(['query:patch:feedback'], (feedback: Chat.QueryFeedback) => {
    return $api.updateQueryFeedback({
      chatInstanceId,
      queryIdentifier,
      feedback,
    });
  }, {
    onMutate: feedback => {
      const query = sessionQueries[queryIdentifier];
      dispatchRating(feedback.rating);
      return { oldRating: query.rating };
    },
    onError: (res, variables, context) => {
      dispatchRating(context.oldRating);
    },
    onSuccess: res => {
      dispatchRating(res.query.rating);
      onSuccess();
    },
  });

  return mutation;
};