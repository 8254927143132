import { useCallback } from 'react';
import type { SurveyQuestionConditionType } from '@enums';
import type { SurveyLogic } from '@/types/survey';
import { RemoveConditionContainer } from './Condition.Remove';
import type {
  QuestionConditionBuilderContextValue,
} from './context';
import {
  QuestionConditionBuilderContext,
  useSurveyConditionsState,
} from './context';

type Props = {
  item: SurveyLogic.QuestionCondition;
} & ChildrenProps;

export const QuestionConditionBuilderContainer = (props: Props) => {

  const [_, dispatch] = useSurveyConditionsState();

  const updateConditionType = useCallback((type: SurveyQuestionConditionType) => {
    dispatch({
      type: 'question-condition-type-updated',
      payload: {
        identifier: props.item.identifier,
        type,
      },
    });
  }, [dispatch, props.item.identifier]);

  const updateQuestion = useCallback((questionIdentifier: string) => {
    dispatch({
      type: 'condition-question-updated',
      payload: {
        identifier: props.item.identifier,
        question: { identifier: questionIdentifier },
      },
    });
  }, [dispatch, props.item.identifier]);

  const updateConditionValue = useCallback((value: SurveyLogic.QuestionConditionData['value']) => {
    dispatch({
      type: 'question-condition-value-updated',
      payload: {
        identifier: props.item.identifier,
        value,
      },
    });
  }, [dispatch, props.item.identifier]);

  const value: QuestionConditionBuilderContextValue = {
    item: props.item,
    updateConditionType,
    updateConditionValue,
    updateQuestion,
  };

  return (
    <QuestionConditionBuilderContext.Provider value={value}>
      <RemoveConditionContainer item={props.item}>
        {props.children}
      </RemoveConditionContainer>
    </QuestionConditionBuilderContext.Provider>
  );
};