import { createNamedContext } from '@utils';
import { FeedItemComment } from '@/types/comments';

type CommentContextMenuContextValue = {
  depth:    number;
  item:     FeedItemComment;
  onDelete: () => void;
  onReply:  () => void;
};

export const CommentContextMenuContext = createNamedContext<CommentContextMenuContextValue | undefined>(undefined, 'Comment.ContextMenu.Context');