import { useContext } from 'react';
import type { StepperActions } from '@utils';
import { createNamedContext } from '@utils';
import type { ProjectType } from '@/enums';
import type { ProjectCreationForm, ProjectCreationSteps } from './interfaces';

type ProjectFormContextValue = [
  ProjectCreationForm,
  (data: Partial<ProjectCreationForm>) => void,
];

type FormErrorContextValue = {
  clearErrors: () => void;
  errors: {
    sector: string;
    industry: string;
    subindustry: string;
  };
  setSubIndustryError: (v: string) => void;
  setIndustryError: (v: string) => void;
  setSectorError: (v: string) => void;
};

type ProjectCreationNavigation = {
  back: () => void;
  index: number;
  step: ProjectCreationSteps;
  next: () => void;
};

type ProjectSurveyStepperContextValue = {
  includeExternalSurvey: boolean;
  onComplete: (isExternal: boolean) => void;
  project: {
    id: number;
    projectType: ProjectType;
  };
  step: number;
  totalSteps: number;
} & StepperActions;

export const FormErrorContext = createNamedContext<FormErrorContextValue>(undefined, 'FormErrorContext');
export const ProjectFormContext = createNamedContext<ProjectFormContextValue>(undefined, 'ProjectFormContext');
export const ProjectCreationNavigationContext = createNamedContext<ProjectCreationNavigation>(undefined, 'ProjectCreationNavigationContext');
export const ProjectSurveyStepperContext = createNamedContext<ProjectSurveyStepperContextValue>(undefined, 'ProjectSurveyStepperContext');
export const ParentObjectIdContext = createNamedContext<number>(undefined, 'ParentObjectIdContext');

export const useProjectFormContext = () => useContext(ProjectFormContext);
export const useProjectFormErrorContext = () => useContext(FormErrorContext);
export const useProjectCreationNavigation = () => useContext(ProjectCreationNavigationContext);
export const useProjectSurveyStepperContext = () => useContext(ProjectSurveyStepperContext);
export const useProjectParentId = () => useContext(ParentObjectIdContext);