import extend from '@enums/extend';

export enum ConferenceType {
  Web = 1,
  Telephone = 2,
  WebAndPhone = 3,
  InPerson = 4,
}

const conferenceTypeMap = new Map([
  [ConferenceType.Web, { name: 'Web' }],
  [ConferenceType.Telephone, { name: 'Telephone' }],
  [ConferenceType.WebAndPhone, { name: 'Web + Phone' }],
  [ConferenceType.InPerson, { name: 'In-person' }],
]);

export enum ConferenceMediaType {
  Video = 1,
  Voice = 2,
}

const conferenceMediaTypeMap = new Map([
  [ConferenceMediaType.Video, { name: 'Video' }],
  [ConferenceMediaType.Voice, { name: 'Voice' }],
]);

export enum ConferencePlatform {
  Sentiment = 1,
  OffPlatform = 2,
}

const conferencePlatformMap = new Map([
  [ConferencePlatform.Sentiment, { name: 'Sentiment' }],
  [ConferencePlatform.OffPlatform, { name: 'Off Platform' }],
]);

export enum ConferenceFeatureShareType {
  EnabledForModerator = 1,
  EnabledForModeratorAndRespondent = 2,
  EnabledForAll = 3,
  DisabledForAll = 4,
}

const conferenceFeatureShareMap = new Map([
  [ConferenceFeatureShareType.EnabledForModerator, { name: 'Enabled for Moderator' }],
  [ConferenceFeatureShareType.EnabledForModeratorAndRespondent, { name: 'Enabled for Moderator + Respondent' }],
  [ConferenceFeatureShareType.EnabledForAll, { name: 'Enabled for All' }],
  [ConferenceFeatureShareType.DisabledForAll, { name: 'Disabled for All' }],
]);

export enum ConferenceCue {
  EnterPin = 1,
  InvalidPin = 2,
  Joined = 3,
  NoInput = 4,
  NotAuthorized = 5,
  Recording = 6,
  UnableToConnect = 7,
  WelcomeEnterPin = 8,
  WaitingRoomForApproval = 9,
  WaitingRoomForStart = 10,
}

const conferenceCueMap = new Map([
  [ConferenceCue.EnterPin, { name: 'Enter Pin' }],
  [ConferenceCue.InvalidPin, { name: 'Invalid Pin' }],
  [ConferenceCue.Joined, { name: 'Joined' }],
  [ConferenceCue.NoInput, { name: 'No Input' }],
  [ConferenceCue.NotAuthorized, { name: 'Not Authorized' }],
  [ConferenceCue.Recording, { name: 'Recording' }],
  [ConferenceCue.UnableToConnect, { name: 'Unable To Connect' }],
  [ConferenceCue.WelcomeEnterPin, { name: 'Welcome Enter Pin' }],
  [ConferenceCue.WaitingRoomForApproval, { name: 'Waiting Room for Approval' }],
  [ConferenceCue.WaitingRoomForStart, { name: 'Waiting Room for Start' }],
]);

export default {
  ConferenceType: extend(conferenceTypeMap),
  ConferenceMediaType: extend(conferenceMediaTypeMap),
  ConferencePlatform: extend(conferencePlatformMap),
  ConferenceFeatureShareType: extend(conferenceFeatureShareMap),
  ConferenceCue: extend(conferenceCueMap),

};

export enum ConferenceChatType {
  Group = 1,
  Direct = 2,
}

export enum ConferenceTagType {
  Project = 1,
  UserCreated = 2,
  Module = 3,
  Global = 4,
}