import { cx } from '@utils';
import type { QuantAnalysisItem } from '@/types/project.quant-analysis';
import { AnalysisTable } from './Analysis.Table';
import { AnalysisContextMenu as ContextMenu } from './Analysis.ContextMenu';
import styles from './style/Analysis.Content.css';

type Props = {
  className?: string;
  value: QuantAnalysisItem;
};

export const AnalysisContent = (props: Props) => {

  return (
    <div className={cx(styles.root, props.className)}>
      <div className={styles.header}>
        <div className={styles.title}>
          {props.value.name}
        </div>
        <ContextMenu analysisItem={props.value} />
      </div>

      <AnalysisTable
        className={styles.table}
        data={props.value.data} />

      <div className={styles.footer}>
        {props.value.footer.map(f => (
          <div key={f} className={styles.footerItem}>{f}</div>
        ))}
      </div>
    </div>
  );
};
