import { Fragment } from 'react';
import { AlertCircle } from 'react-feather';
import Popover from 'material-ui-popup-state/HoverPopover';
import PopupState from 'material-ui-popup-state';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { bindHover, bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import { ConferenceDetailsEditor, ConferenceDetailsEditorContainer, useConferenceDetailsEditor } from '@/components/Conference';
import type { TableCellItem } from './interfaces';
import styles from './style/Search.Members.css';

type Props = TableCellItem;

export const Cell = (props: Props) => {
  return (
    <div className={styles.icons}>
      <Notes {...props} />
      <Info {...props} />
    </div>
  );
};

Cell.displayName = 'Column.Notes.Cell';

const Notes = (props: Props) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'history-popover',
  });

  const notes = useConferenceDetailsEditor({
    initialState: props.row.original.user.notes,
    placeholder: ``,
  });

  if (!props.row.original.user.notes) return null;

  return (
    <Fragment>
      <div
        className={styles.historyIcon}
        {...bindHover(popupState)}>
        <AlertCircle size={22} />
      </div>
      <Popover
        {...bindPopover(popupState)}
        sx={{
          pointerEvents: 'none',
          cursor: 'default',
        }}
        slotProps={{
          paper: {
            sx: {
              backgroundColor: 'var(--pri-01)',
              border: '1px solid transparent',
              borderRadius: '4px',
              boxShadow: `
                  0px 5px 5px -3px rgba(0,0,0,0.2),
                  0px 8px 10px 1px rgba(0,0,0,0.14),
                  0px 3px 14px 2px rgba(0,0,0,0.12)`,
            },
          },
        }}
        anchorOrigin={{
          vertical: 26,
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <div className={styles.notes}>
          <ConferenceDetailsEditorContainer
            editable={false}
            manager={notes.manager}
            state={notes.state}>
            <ConferenceDetailsEditor />
          </ConferenceDetailsEditorContainer>
        </div>
      </Popover>
    </Fragment>
  );
};

const Info = (props: Props) => {
  if (!props.row.original.unstructured) return null;

  return (
    <PopupState
      variant="popover"
      popupId="unstructured-popup-popover">
      {popupState => (
        <div>
          <AlertCircle
            {...bindHover(popupState)}
            color="var(--gray)"
            size={22}
            style={{ transform: 'rotate(180deg)' }} />
          <HoverPopover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}>
            <div className={styles.notes}>
              <div className={styles.section}>
                <div className={styles.header}>Imported Fields</div>
                {Object.entries(props.row.original.unstructured).sort(([k1, v1], [k2, v2]) => k1.localeCompare(k2)).map(([key, value]) =>
                  <div
                    key={key}
                    className={styles.field}>
                    <div className={styles.label}>{key}:</div>
                    <div>{value || 'N/A'}</div>
                  </div>)}
              </div>
            </div>
          </HoverPopover>
        </div>)}
    </PopupState>
  );
};