import { useMemo, useState } from 'react';
import { plans } from './content';
import * as pricing from './pricing';
import { PricingContext } from './Context';
import { PricingFormContainer } from './Pricing.Form.Container';
import { PricingItem } from './interfaces';

type Props = {
  children: React.ReactNode;
};

export const PricingContainer = (props: Props) => {
  const [monthly, setMonthly] = useState(false);

  const data = useMemo<PricingItem[]>(() => pricing.table.items, []);

  const context = {
    compact: {
      items: pricing.compact.items,
    },
    monthly,
    plans: {
      customer: plans.customer,
      market: plans.market,
      enterprise: plans.enterprise,
    },
    setMonthly,
    table: {
      items: data,
    },
  };

  return (
    <PricingContext.Provider value={context}>
      <PricingFormContainer>
        {props.children}
      </PricingFormContainer>
    </PricingContext.Provider>
  );
};