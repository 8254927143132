import { useCallback, useContext } from 'react';
import { ChatStateDispatchContext } from '@/brand-insights/components/Chat.State';
import type { ChatHistory } from '@/brand-insights/types';
import { ChatHistoryManagerContext } from '../context';

export function useChatHistoryMode() {
  const dispatch = useContext(ChatStateDispatchContext);
  const historyManager = useContext(ChatHistoryManagerContext);

  const handleUpdate = useCallback((mode: ChatHistory.Mode) => {
    dispatch({
      type: 'chat-history-mode-changed',
      payload: {
        mode,
      },
    });
  }, [dispatch]);

  return [historyManager.mode, handleUpdate] as const;
}