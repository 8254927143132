import { Switch, Route } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { path, pathname } from '@consts';
import { CallStatus, CallType } from '@enums';
import { AdHocCallRequestDetails } from './Details';
import { AdHocCallRequestAvailability } from './Availability';
import { AdHocCallPayoutSetup } from './PayoutSetup';
import { AdHocCallRequestSchedulingTutorial } from './SchedulingTutorial';
import { useRequestCall } from './useCall';

export const AdHocCallRequest = () => {

  const call = useRequestCall();

  if (call?.typeId !== CallType.AdHoc ||
    call?.statusId !== CallStatus.Pending) {
    return <Redirect to={pathname.Home} />;
  }

  return (
    <Switch>
      <Route
        component={AdHocCallRequestDetails}
        exact={true}
        path={[path.CallOnboarding.Details, path.CallOnboarding.Call]} />
      <Route
        exact={true}
        component={AdHocCallRequestAvailability}
        path={path.CallOnboarding.Availability} />
      <Route
        exact={true}
        component={AdHocCallPayoutSetup}
        path={path.CallOnboarding.PayoutSetup} />
      <Route
        exact={true}
        component={AdHocCallRequestSchedulingTutorial}
        path={path.CallOnboarding.SchedulingTutorial} />
    </Switch>
  );
};

export default AdHocCallRequest;