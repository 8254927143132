import { useCallback, useContext } from 'react';
import {
  WorkspaceGlobalSearchBarResetContext, WorkspaceGlobalSearchBarQueryContext,
  WorkspaceGlobalSearchBarPopupContext, WorkspaceGlobalSearchKeyboardContext,
  WorkspaceGlobalSearchBarDispatchContext, WorkspaceGlobalSearchBarFiltersContext,
} from './Context';

export default function Reset({ children }: ChildrenProps) {
  const popper = useContext(WorkspaceGlobalSearchBarPopupContext);
  const { clearQuery } = useContext(WorkspaceGlobalSearchBarQueryContext);
  const { resetKeyboard } = useContext(WorkspaceGlobalSearchKeyboardContext);
  const [_, clearResults] = useContext(WorkspaceGlobalSearchBarDispatchContext);
  const { clearActiveFilters, clearAggregationsFilters } = useContext(WorkspaceGlobalSearchBarFiltersContext);

  const clickedAwayReset = useCallback(() => {
    if (popper.isOpen) {
      popper.setOpen(false);
    }
  }, [popper]);

  const clickedResultReset = useCallback(() => {
    clearQuery();
    resetKeyboard();
  }, [clearQuery, resetKeyboard]);

  const updatedResultsReset = useCallback((items?: number) => {
    resetKeyboard(items);
  }, [resetKeyboard]);

  const emptyResultReset = useCallback(() => {
    clearResults();
    clearActiveFilters();
    clearAggregationsFilters();
  }, [clearResults, clearActiveFilters, clearAggregationsFilters]);

  const value = {
    clickedAwayReset,
    clickedResultReset,
    updatedResultsReset,
    emptyResultReset,
  };

  return (
    <WorkspaceGlobalSearchBarResetContext.Provider value={value}>
      {children}
    </WorkspaceGlobalSearchBarResetContext.Provider>
  );
}

export { Reset };