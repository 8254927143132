import { Organization } from 'schema-dts';
import { helmetJsonLdProp } from 'react-schemaorg';
import * as urls from '@consts/urls';

export const organizationProp = helmetJsonLdProp<Organization>({
  '@context': 'https://schema.org',
  '@id': 'https://www.sentimentglobal.com',
  '@type': 'Organization',
  email: 'support@sentimentglobal.com',
  parentOrganization:
  {
    '@type': 'Organization',
    'name': 'Trinity',
    'url': 'https://trinitylifesciences.com',
  },
  name: 'Sentiment',
  url: 'https://www.sentimentglobal.com',
  logo: 'https://cdn.app.sentimentglobal.com/images/vancery-600x600.png',
  sameAs: [
    urls.LinkedIn,
    urls.Twitter,
    urls.Facebook,
    urls.YouTube,
  ],
});