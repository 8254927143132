import { useCallback } from 'react';
import type { DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { Droppable } from 'react-beautiful-dnd';
import { cx } from '@utils';
import { useAltRankingFormContext } from './context';
import type { AltRankingForm } from './interfaces';
import { DraggableStartAreaItem } from './Ranking.Alt.StartArea.Item';
import { StartDroppableId } from './utils.ranking.alt';
import styles from './style/Ranking.Alt.StartArea.css';

type Props = {
  className?: string;
  items: AltRankingForm.DraggableItem[];
};

export const StartArea = ({ className, items }: Props) => {

  const { disabled } = useAltRankingFormContext();

  const renderItems = useCallback(() => {
    if (!items.length) {
      return (
        <div className={styles.placeholder}>No items left to rank</div>
      );
    }

    return items.map(m => (
      <DraggableStartAreaItem
        key={m.id}
        className={styles.item}
        isDisabled={disabled}
        item={m} />
    ));
  }, [disabled, items]);

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.header}>
        <span className={styles.title}>Items</span> <span className={styles.subtitle}>({items.length} unranked)</span>
      </div>
      <Droppable
        droppableId={StartDroppableId}
        isDropDisabled={true}>
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
          <div
            {...provided.droppableProps}
            className={styles.items}
            ref={provided.innerRef}>
            {renderItems()}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};