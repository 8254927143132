import { useMemo } from 'react';
import { getOperatorText } from '@utils/operator';
import * as surveyForm  from '@containers/SurveyForm/utils';
import { MatrixSliderQuestion } from '@/types/survey';

export const useSliderValidation = (answer: MatrixSliderQuestion.RespondentAnswer.Value, question: MatrixSliderQuestion.FormQuestion) => {

  const message = useMemo(() => {
    const isAnswerComplete = surveyForm.matrixSlider.isAnswerComplete(answer, question);
    const isSumValid = surveyForm.matrixSlider.isSumValid(answer, question);

    const operatorText = getOperatorText(question.settings.slider.total.operator);

    return isAnswerComplete && !isSumValid
      ? `Please ensure the total is ${operatorText} ${question.settings.slider.total.comparate}`
      : null;
  }, [answer, question]);

  return message;
};

export default useSliderValidation;