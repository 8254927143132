import { memo } from 'react';
import { Helmet } from 'react-helmet';
import { path } from '@consts';
import { Landing } from './Landing';
import { Innovation } from './Innovation';
import { Values } from './Values';
import { Opportunities } from './Opportunities';
import { BannerFooter } from './BannerFooter';
import styles from './style/Careers.css';

type Props = unknown;

const H = memo(() => {
  const description = ``;

  return (
    <Helmet
      title="Careers">
      <link
        rel="canonical"
        href={`${process.env.FRONT_BASE_URL}${path.Website.Careers}`} />
      {/* <meta
        name='description'
        content={description} />
      <meta
        name='og:description'
        content={description} /> */}
    </Helmet>
  );
});

export const Careers = (props: Props) => {

  return (
    <div className={styles.root}>
      <H />
      <Landing />
      <Innovation />
      <div className={styles.multi}>
        <Values />
        <Opportunities />
        <div className={styles.bg} />
      </div>
      <BannerFooter />
    </div>
  );
};

Careers.displayName = 'Careers';