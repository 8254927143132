import { useRef, useEffect } from 'react';

type Lock = Awaited<ReturnType<typeof navigator.wakeLock.request>>;

export const useLockScreen = () => {
  const lock = useRef<Lock>();

  useEffect(() => {
    if (navigator.wakeLock) {
      try {
        navigator.wakeLock.request('screen').then(l => {
          lock.current = l;
        }).catch((err: Error) => {
          console.log(`Could not request wakeLock: ` + err?.message);
        });
      } catch (err) {
        console.log('Could not request wakeLock');
      }

      return () => {
        if (lock.current) {
          lock.current.release();
        }
      };
    }
  }, []);
};