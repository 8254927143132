import { memo } from 'react';
import styled from '@emotion/styled';
import { Constants } from '@/brand-insights/components/Theme';
import { roundToPlace } from '@/brand-insights/utils';

type Props = {
  className?: string;
  score: number;
};

export const MessageCitationScore = memo(({ className, score }: Props) => {
  const value = roundToPlace(score * 100, 0);

  return (
    <Root className={className}>
      <Text>
        {value}%
      </Text>
    </Root>
  );
});

const Root = styled.div`
  box-sizing: border-box;
  padding-left: 4px;
  width: ${Constants.Message.CitationScoreWidth}px;
`;

const Text = styled.span`
  color: ${props => props.theme.palette.primary.light6};
  font-family: ${props => props.theme.fonts.semiBold};
  font-size: 13px;
`;