import { memo } from 'react';
import styles from './style/ColorRing.css';

type Props = {
  size?: number;
};

export const ColorRing = memo(({ size = 50 }: Props) => {
  const style = {
    height: size,
    width: size,
  };

  return (
    <div
      style={style}
      className={styles.spinner} />
  );
});