import { useEffect, useState } from 'react';
import { RemoteParticipant, Room } from 'twilio-video';

export function useDominantSpeaker(room: Room) {
  const [dominantSpeaker, setDominantSpeaker] = useState(room.dominantSpeaker);

  useEffect(() => {
    const handleDominantSpeakerChanged = (newDominantSpeaker: RemoteParticipant) => {
      if (newDominantSpeaker !== null) {
        setDominantSpeaker(newDominantSpeaker);
      }
    };

    const handleParticipantDisconnected = (participant: RemoteParticipant) => {
      setDominantSpeaker(prevDominantSpeaker => {
        return prevDominantSpeaker === participant ? null : prevDominantSpeaker;
      });
    };

    room.on('dominantSpeakerChanged', handleDominantSpeakerChanged);
    room.on('participantDisconnected', handleParticipantDisconnected);

    return () => {
      room.off('dominantSpeakerChanged', handleDominantSpeakerChanged);
      room.off('participantDisconnected', handleParticipantDisconnected);
    };
  }, [room]);

  return dominantSpeaker;
}

export default useDominantSpeaker;