import { memo } from 'react';
import { cx } from '@utils';
import styles from './style/Card.css';

type Props = {
  onClick?: () => void;
};

export const MarketAnalysisCard = memo(({ onClick }: Props) => {

  const title = `Market Analysis`;

  return (
    <div
      className={styles.root}
      onClick={onClick}>
      <div className={cx(styles.poster, styles.marketAnalysis)}>
        <Icon />
      </div>
      <div className={styles.footer}>
        <div className={styles.title}>{title}</div>
      </div>
    </div>
  );
});

type IconProps = {
  color?: string;
};

const Icon = memo(({
  color = '#FA8F4D',
}: IconProps) => (
  <svg
    width="40px"
    height="39px"
    viewBox="0 0 32 31"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g
      id="Survey-Templates---Market-Analysis"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round">
      <g
        id="1"
        transform="translate(-515.000000, -376.000000)"
        stroke={color}
        strokeWidth="2">
        <g
          id="Group-8"
          transform="translate(516.776934, 376.935279)">
          <path
            d="M2.22306623,28.0647206 L2.22306623,19.0647206 C2.22306623,18.5124358 2.67078148,18.0647206 3.22306623,18.0647206 L6.22306623,18.0647206 C6.77535098,18.0647206 7.22306623,18.5124358 7.22306623,19.0647206 L7.22306623,28.0647206"
            id="Path" />
          <path
            d="M12.2230662,28.0647206 L12.2230662,15.0647206 C12.2230662,14.5124358 12.6707815,14.0647206 13.2230662,14.0647206 L16.2230662,14.0647206 C16.775351,14.0647206 17.2230662,14.5124358 17.2230662,15.0647206 L17.2230662,28.0647206"
            id="Path" />
          <path
            d="M22.2230662,28.0647206 L22.2230662,12.0647206 C22.2230662,11.5124358 22.6707815,11.0647206 23.2230662,11.0647206 L26.2230662,11.0647206 C26.775351,11.0647206 27.2230662,11.5124358 27.2230662,12.0647206 L27.2230662,28.0647206"
            id="Path" />
          <path
            d="M3.28433243,10.4453781 L8.87095999,5.7628721 C9.22398458,5.46697986 9.73346133,5.45051162 10.1048578,5.72298781 L14.1340458,8.67901455 C14.4849005,8.93642022 14.9619386,8.93738742 15.3138342,8.68140658 L25.0971069,1.56472058 L25.0971069,1.56472058"
            id="Path-5"
            strokeLinejoin="round" />
          <polyline
            id="Path"
            strokeLinejoin="round"
            transform="translate(23.297519, 3.000000) rotate(8.000000) translate(-23.297519, -3.000000) "
            points="21.2975187 1 25.2975187 1 25.2975187 5" />
          <line
            x1="-5.68284497e-14"
            y1="29.0647206"
            x2="29.2417337"
            y2="29.0647206"
            id="Path-7" />
        </g>
      </g>
    </g>
  </svg>
));