import { useCallback, useContext, useMemo } from 'react';
import { useEditorState } from '@remirror/react';
import { useDownloadTranscriptClip } from '@/containers/Transcript/hooks';
import { TranscriptIdContext } from '@/containers/Transcript/context';
import type { HighlightWithText } from '../interfaces.extension.highlight';
import { TranscriptChainedCommandsContext } from '../context';
import { useMapDocPosToTimePos } from './useMapPositions';

export const useDownloadHighlight = (highlight: HighlightWithText) => {
  const transcriptId = useContext(TranscriptIdContext);
  const commands = useContext(TranscriptChainedCommandsContext);
  const mapDocPosToTimePos = useMapDocPosToTimePos();

  const state = useEditorState();

  const highlightTimePos = useMemo(() => {
    if (!highlight) {
      return null;
    }
    return mapDocPosToTimePos({
      from: highlight.from,
      to: highlight.to,
      state,
    });
  }, [
    highlight,
    mapDocPosToTimePos,
    state,
  ]);

  const { download: downloadClip } = useDownloadTranscriptClip({
    transcriptId,
    fromTs: highlightTimePos?.start,
    toTs: highlightTimePos?.end,
  });

  const handleDownloadClip = useCallback(() => {

    downloadClip({
      extension: `mp4`,
      name: `Transcript Clip`,
    });

    commands.removeHighlights([highlight.id]).run();
  }, [commands, downloadClip, highlight?.id]);

  return handleDownloadClip;
};