import type { LocalAudioTrack, Participant, RemoteAudioTrack, Room } from 'twilio-video';
import { AudioLevelBorder } from '@/components/Conference.Video';
import { usePublications, useTrack } from '@/components/Conference.Video/hooks';
import { SessionParticipantStripParticipant } from './Session.ParticipantStrip.Participant';
import { useSelectedParticipant } from './hooks';
import styles from './style/GalleryParticipant.css';

type Props = {
  participant: Participant;
  room: Room;
  mainSpeakerSid: string;
};

export const GalleryParticipant = ({ participant, room, mainSpeakerSid }: Props) => {
  const [selectedParticipant, setSelected] = useSelectedParticipant();
  const publications = usePublications(participant);
  const audioPublication = publications.find(p => p.kind === 'audio');
  const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack;
  return (
    <AudioLevelBorder audioTrack={audioTrack}>
      <SessionParticipantStripParticipant
        className={styles.stripParticipant}
        isSelected={selectedParticipant === participant}
        onClick={() => setSelected(participant)}
        participant={participant}
        mainSpeakerSid={mainSpeakerSid}
        room={room} />
    </AudioLevelBorder>
  );
};