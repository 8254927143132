import { Remirror } from '@/types';

export function isProjectNotesEmpty(value: Remirror.RootNode) {

  if (!value) return true;

  function isParagraphNodeEmpty(node: Remirror.ParagraphNode) {
    const content = (node.content || []);
    if (!content.length) return true;

    return content.every(isParagraphContentEmpty);
  }

  return (value.content || []).reduce((acc, node) => {
    if (!acc) return acc;

    const isEmpty = isParagraphNodeEmpty(node);
    return isEmpty;
  }, true);
}

function isParagraphContentEmpty(node: Remirror.TextNode) {

  if (node.type === Remirror.NodeType.Text) {
    return !node.text.trim().length;
  }

  return false;
}