import { useCallback, useContext } from 'react';
import { useFileDragDrop } from '@/components/FileDragDrop/hooks/useFileDragDrop';
import { cx } from '@/utils';
import type { IWorkspaceBrandItem, IWorkspaceFileItem, IWorkspaceFolderItem, IWorkspaceProjectItem, IWorkspaceTranscriptItem } from '@/types/workspace.table';
import { WorkspaceObjectType } from '@/enums';
import { RateLimitingContext } from '@containers/RateLimiting/Context';
import FileTranscriptStatusContainer from '@/containers/Workspace.File/TranscriptAvailableContainer';
import type { IFilesTable } from './interfaces';
import { Brand } from './Files.Table.Grid.Brand';
import { File } from './Files.Table.Grid.File';
import { Folder } from './Files.Table.Grid.Folder';
import { Project } from './Files.Table.Grid.Project';
import { Transcript } from './Files.Table.Grid.Transcript';
import styles from './style/Files.Table.Grid.css';

type FilesTableGridProps = {
  classes?: {
    root?: string;
    files?: string;
  };
  items: IFilesTable.Item[];
  canUpload: boolean;
  onUpload?: (files: File[]) => unknown;
};

export const FilesTableGrid = ({ classes = {}, ...props }: FilesTableGridProps) => {
  const renderItem = useCallback((data: IFilesTable.Item) => {
    switch (data.object.typeId) {
      case WorkspaceObjectType.Brand: {
        return <Brand key={data.object.id} item={data as IWorkspaceBrandItem} />;
      }
      case WorkspaceObjectType.File: {
        const item = data as IWorkspaceFileItem;
        return (
          <FileTranscriptStatusContainer fileId={data.object.entityId} transcript={item.transcript}>
            <File key={data.object.id} item={item} />
          </FileTranscriptStatusContainer>
        );
      }

      case WorkspaceObjectType.ProjectParent:
        return <Project key={data.object.id} item={data as IWorkspaceProjectItem} />;

      case WorkspaceObjectType.Folder:
        return <Folder key={data.object.id} item={data as IWorkspaceFolderItem} />;

      case WorkspaceObjectType.CallTranscript:
        return <Transcript key={data.object.id} item={data as IWorkspaceTranscriptItem} />;

      case WorkspaceObjectType.Workspace:
        return null;

      default:
        throw new UnreachableCaseError(data.object.typeId);
    }
  }, []);

  return (
    <UploadableFiles
      canUpload={props.canUpload}
      onUpload={props.onUpload}>
      <div className={cx(styles.root, classes.root)}>
        <div className={cx(styles.files, classes.files)}>
          {props.items.map(renderItem)}
        </div>
      </div>
    </UploadableFiles>
  );
};

type UploadableFilesProps = {
  canUpload: boolean;
  onUpload?: (files: File[]) => unknown;
  children: React.ReactNode;
};

const UploadableFiles = ({ children, onUpload, canUpload }: UploadableFilesProps) => {
  const { query } = useContext(RateLimitingContext);

  const { dragging, dropRef } = useFileDragDrop({
    disabled: query.data?.documents?.exceeded,
    onDrop: onUpload,
  });

  if (canUpload) {
    return (
      <div
        ref={dropRef}
        className={cx({
          [styles.dragging]: dragging,
        })}>
        {children}
      </div>
    );
  }

  return <>{children}</>;
};

export default FilesTableGrid;