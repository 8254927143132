import styled from '@emotion/styled';

type Props = {
  className?: string;
} & ChildrenProps;

export function TextLabel({ className, children }: Props) {
  return (
    <Root className={className}>
      {children}
    </Root>
  );
}

const Root = styled.div`
  box-sizing: border-box;
  margin-bottom: 8px;
  font-family: var(--font-bold);
`;