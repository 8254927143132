import { useCallback } from 'react';
import { useRichTextEditorRegistryEditor, useSurveyBuilderState } from '@containers/SurveyBuilder';
import { SurveyMessageBuilderCancelContext, useSurveyMessageBuilderItemContext } from './context';
import { MessageBuilderSaveContainer } from './SaveContainer';

type Props =
  ChildrenProps;

export const MessageBuilderContainer = (props: Props) => {

  const [state, dispatch] = useSurveyBuilderState();
  const message = useSurveyMessageBuilderItemContext();
  const { getEditorByIdentifier } = useRichTextEditorRegistryEditor();

  const getEditor = useCallback(() => getEditorByIdentifier(message.identifier), [getEditorByIdentifier, message.identifier]);

  const handleCancel = useCallback(() => {
    const committed = state.committed.messages.find(f => f.identifier === message.identifier);

    if (committed) {
      const editor = getEditor().getContext().manager;
      const state = editor.createState({ content: committed.value });
      editor.view.updateState(state);
    }

    dispatch({
      type: 'revert-uncommitted-changes',
    });
  }, [
    dispatch,
    getEditor,
    message.identifier,
    state.committed.messages,
  ]);

  return (
    <SurveyMessageBuilderCancelContext.Provider value={handleCancel}>
      <MessageBuilderSaveContainer>
        {props.children}
      </MessageBuilderSaveContainer>
    </SurveyMessageBuilderCancelContext.Provider>
  );
};