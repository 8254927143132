import { useState, useCallback } from 'react';
import type { PipedSurveyValue } from '@/types';
import { PipingMenuContext } from './context';
import { useAddPipedResponseNode } from './hooks/useAddPipedResponseNode';

type Props = {
  onPipingValueSelect?: (value: PipedSurveyValue) => void;
} & ChildrenProps;

export const PipingMenuContainer = (props: Props) => {

  const [open, setOpen] = useState(false);

  const addPipedResponseNode = useAddPipedResponseNode();

  const onPipingValueSelect = useCallback((value: PipedSurveyValue) => {
    (props.onPipingValueSelect ?? addPipedResponseNode)(value);
    setOpen(false);
  }, [addPipedResponseNode, props.onPipingValueSelect]);

  return (
    <PipingMenuContext.Provider value={{ open, setOpen, onPipingValueSelect }}>
      {props.children}
    </PipingMenuContext.Provider>
  );
};