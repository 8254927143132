import { Link } from 'react-router-dom';
import { getLocationFor } from '@utils';
import styles from './style.css';

type ProfileLinkProps = {
  id: number;
  name: string;
};

const ProfileLink = ({ id, name }: ProfileLinkProps) => {
  const to = getLocationFor.admin.member.details({ userId: id });
  return (
    <Link
      className={styles.name}
      to={to}>
      {name}
    </Link>
  );
};

export { ProfileLink, ProfileLinkProps };
export default ProfileLink;