import { memo } from 'react';
import { Fragment } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { cx } from '@utils';
import styles from './style/Skeleton.css';

type Props = {
  className?: string;
  rows: number;
};

export const ProjectEntities = memo((props: Props) => {
  return (
    <div className={cx(styles.project, styles.entities, props.className)}>
      <Skeleton
        height={35}
        sx={{ marginBottom: '5px' }}
        variant="rectangular"
        width="100%" />
      {Array.from({ length: props.rows }).map((_, i) =>
        <Fragment key={i}>
          <Row index={0} />
          <Row index={1} />
          <Row index={2} />
        </Fragment>
      )}
    </div>
  );
});

ProjectEntities.displayName = 'Transcript.Skeleton.ProjectEntities';

const widths = [
  ['100px', '140px', '90px', '45px', '30px'],
  ['160px', '130px', '60px', '30px', '30px'],
  ['150px', '100px', '75px', '40px', '30px'],
];

type RowProps = {
  index: 0 | 1 | 2;
};

const Row = memo((props: RowProps) => {
  const width = widths[props.index];

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        <Skeleton
          sx={{ fontSize: '28px', width: width[0] }}
          variant="text" />
      </div>
      <div className={styles.col}>
        <Skeleton
          sx={{ fontSize: '28px', width: width[1] }}
          variant="text" />
      </div>
      <div className={styles.col}>
        <Skeleton
          sx={{ fontSize: '28px', width: width[2] }}
          variant="text" />
      </div>
      <div className={styles.col}>
        <Skeleton
          sx={{ fontSize: '28px', width: width[3] }}
          variant="text" />
      </div>
      <div className={styles.col}>
        <Skeleton
          sx={{ fontSize: '28px', width: width[4] }}
          variant="text" />
      </div>
    </div>
  );
});

Row.displayName = 'Transcript.Skeleton.ProjectEntities.Row';