import { useMemo } from 'react';
import { useSurveyBuilderState } from './useSurveyBuilderContext';

export const useSurveyClassifications = () => {
  const [state] = useSurveyBuilderState();

  return state.survey.classifications;
};

export const useSurveyClassification = (identifier: string) => {
  const classifications = useSurveyClassifications();

  return useMemo(() => {
    return classifications.find(f => f.identifier === identifier);
  }, [classifications, identifier]);
};