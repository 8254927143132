import { useCallback, useEffect } from 'react';
import type { ProjectSocketEvent } from '@/enums';
import type { Projects } from '../interfaces';
import * as $projects from '../projects';

export const useProjectsSocketEvent = <T extends ProjectSocketEvent>(event: T, fn: Projects.RegisterHandler<T>, enabled = true) => {

  useEffect(() => {
    if (enabled) {
      $projects.on(event, fn);
      return () => {
        $projects.off(event, fn);
      };
    }
  }, [enabled, event, fn]);
};
