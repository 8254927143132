import { memo, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import type { ActionMeta, MultiValue } from 'react-select';
import type { ProjectClips } from '@/types';
import { ProjectClipsFiltersContext, SetProjectClipsFiltersContext } from '@/containers/Project.Clips';
import { getProjectTranscriptGroupTags } from '@/services/api/projects';
import { useProjectOrLatestChild } from '@/containers/GroupProject/hooks';
import { Button } from '@/components/Button';
import { CheckboxLabel } from '@/components/Checkbox';
import { GroupTagsMultiSelect } from './GroupTags.MultiSelect';
import type { GroupTagFilterItem } from './GroupTags.MultiSelect';
import styles from './style/AdditionalFilters.css';

type Props = {
  onClose: () => void;
};

export const AdditionalFiltersMenu = memo(({ onClose }: Props) => {

  const filters = useContext(ProjectClipsFiltersContext);
  const setFilters = useContext(SetProjectClipsFiltersContext);

  const [groupTagIds, setGroupTagIds] = useState<number[]>(filters.groupTagIds);
  const [speakerRole, setSpeakerRole] = useState<ProjectClips.SpeakerFilter>(filters.speakerRole);

  const menuRef = useRef<HTMLDivElement>(null);

  const handleSpeakerRoleSelected = useCallback((role: ProjectClips.SpeakerFilter) => () => {
    setSpeakerRole(role);
  }, [setSpeakerRole]);

  const handleSubmit = useCallback(() => {
    setFilters({
      groupTagIds,
      speakerRole,
    });
    onClose();
  }, [onClose, setFilters, groupTagIds, speakerRole]);

  const canApplySpeakersFilter = useMemo(() => {
    return filters.keyword.length > 0;
  }, [filters.keyword]);

  return (
    <div ref={menuRef} className={styles.menu}>

      <div className={styles.section}>
        <div className={styles.label}>Global Tags</div>
        <GroupTagsFilter
          menuRef={menuRef}
          value={groupTagIds}
          onChange={setGroupTagIds} />
      </div>

      <div className={styles.section}>
        <div className={styles.label}>Speaker</div>
        <div className={styles.speakers}>
          <CheckboxLabel
            className={styles.checkbox}
            disabled={!canApplySpeakersFilter}
            checked={speakerRole === 'all'}
            label='All'
            onChange={handleSpeakerRoleSelected('all')} />
          <CheckboxLabel
            className={styles.checkbox}
            disabled={!canApplySpeakersFilter}
            checked={speakerRole === 'respondent'}
            label='Respondent'
            onChange={handleSpeakerRoleSelected('respondent')} />
          <CheckboxLabel
            className={styles.checkbox}
            disabled={!canApplySpeakersFilter}
            checked={speakerRole === 'moderator'}
            label='Moderator'
            onChange={handleSpeakerRoleSelected('moderator')} />
        </div>
      </div>

      <div className={styles.footer}>
        <Button
          className={styles.submit}
          variant='brick'
          onClick={handleSubmit}>
          Apply
        </Button>
      </div>
    </div>
  );
});

type GroupTagFilterProps = {
  value: number[];
  onChange: (value: number[]) => unknown;
  menuRef: React.RefObject<HTMLDivElement>;
};

const GroupTagsFilter = ({ menuRef, onChange, value }: GroupTagFilterProps) => {
  const project = useProjectOrLatestChild();
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = useCallback((newValue: string) => setInputValue(newValue), []);
  const optionsQuery = useQuery({
    queryKey: [`get:group-tags`, project.id],
    queryFn: () => {
      const projectId = project.id;
      return getProjectTranscriptGroupTags({
        projectId,
      }).then(res => res.tags);
    }, placeholderData: [], refetchOnReconnect: false, refetchOnWindowFocus: false, staleTime: 60000,
  });

  const selected = useMemo(() => {
    return value.map(id => optionsQuery.data.find(o => o.id === id)).filter(Boolean);
  }, [optionsQuery, value]);

  const handleChange = useCallback((value: MultiValue<GroupTagFilterItem>, meta: ActionMeta<GroupTagFilterItem>) => {
    onChange(Array.from(value).map(v => v.id));
  }, [onChange]);

  const handleNoOptionsMessage = useCallback((obj: { inputValue: string }) => {
    if (optionsQuery.isFetching) return `Loading...`;
    return obj.inputValue?.length ? `No tags found` : null;
  }, [optionsQuery.isFetching]);

  return (
    <GroupTagsMultiSelect
      inputValue={inputValue}
      menuPortalTarget={menuRef.current}
      noOptionsMessage={handleNoOptionsMessage}
      onChange={handleChange}
      onInputChange={handleInputChange}
      options={optionsQuery.data}
      selected={selected} />
  );
};