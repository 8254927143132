import { useCallback } from 'react';
import { useActive, useChainedCommands, useCommands } from '@remirror/react';

export const useItalicCommand = () => {
  const active = useActive();
  const { toggleItalic } = useCommands();
  const chain = useChainedCommands();

  const toggle = useCallback(() => {
    chain
      .toggleItalic()
      .focus()
      .run();
  }, [chain]);

  return {
    active: active.italic(),
    toggle,
    enabled: toggleItalic.enabled(),
  };
};