import { useCallback, useContext } from 'react';
import { ProjectOnboardingContext, ProjectResearchConsentContext } from '@containers/ProjectOnboarding/Context';
import { ButtonActivityIndicator } from '@presentation';
import styles from './style/MarketResearchPrivacyPolicy.css';

type Props = unknown;

export const MarketResearchPrivacyPolicy = (props: Props) => {
  const ctx = {
    project: useContext(ProjectOnboardingContext),
    research: useContext(ProjectResearchConsentContext),
  };

  const handleSubmit = useCallback(() => {
    ctx.research.mutation.mutateAsync().then(ctx.project.next);
  }, [
    ctx.project.next,
    ctx.research.mutation,
  ]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div
          className={styles.copy}
          dangerouslySetInnerHTML={{ __html: copy.message }} />
        </div>
        <div className={styles.footer}>
          <ButtonActivityIndicator
            className={styles.btn}
            color="affirmative"
            loading={ctx.research.mutation.isLoading}
            onClick={handleSubmit}
            variant="brick">
            Confirm
          </ButtonActivityIndicator>
        </div>
      </div>
    </div>
  );
};

MarketResearchPrivacyPolicy.displayName = 'MarketResearchPrivacyPolicy';

const URL = `https://app.sentimentglobal.com/privacy`;

const copy = {
  message: `I agree to Trinity Partners, LLC. (“Trinity”), staying connected with me by email, phone, or mail regarding market research studies, news, thought leadership, promotions, offers, and events related to such market research.  I agree to Trinity transferring my data to Trinity locations outside of the EEA (including the US) for the purpose stated above. I have read and agree to Trinity’s Market Research Participant Privacy Policy (<a class="${styles.link}" target="_blank" rel="noopener noreferrer" href="${URL}">linked here</a>) and understand that, irrespective of whether you want us to delete your personal data, you consent to our use of your responses to market research questions in an anonymized and aggregated way for the period of time of the answer’s relevance.`,
};