import { createElement } from 'react';
import { cx } from '@utils';
import styles from './style.css';

type Props<T = number> = {
  className?: string;
  Icon: React.ComponentType<unknown>;
  size: T;
};

const ToastIcon = ({
  className,
  size = 20,
  Icon,
}: Props) => {
  const icon = createElement(Icon, { size: size } as unknown);

  return (
    <div className={cx(styles.icon, className)}>
      {icon}
    </div>
  );
};

ToastIcon.displayName = 'Toast.Base.Icon';

export { ToastIcon as Icon };