import { useCallback } from 'react';
import { QuestionRowsBuilderContainer } from '@containers/SurveyBuilder.Question/RowsBuilderContainer';
import * as surveyBuilder from '@containers/SurveyBuilder/utils';
import { useCanCreateFindAndReplace } from '@/containers/SurveyBuilder/hooks/useCanCreateFindAndReplace';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks/useSurveyBuilderContext';
import { QuestionSettingTitle } from '@presentation/SurveyBuilder';
import type { MatrixSliderQuestion } from '@/types';
import styles from './style/MatrixSlider.Question.css';
import MatrixSliderSettings from './MatrixSlider.Settings';
import NumberSettingsBuilder from './NumberSettingsBuilder';
import { ResponsePiping } from './ResponsePiping';
import { QuestionRowsBuilder } from './RowsBuilder';
import { RandomizeCheckbox } from './Settings.Randomize';
import { SlidersRowContextMenu } from './MatrixSlider.Row.ContextMenu';
import { useHasLinkedRows } from './hooks';
import { ReuseQuestionRows } from './ReuseQuestionValues';
import { OrderRowsBasedOnSource } from './Settings.ReuseRowsOrder';
import { FindAndReplaceRows } from './FindAndReplace';
import { RationaleCheckbox } from './Settings.Rationale';
import { LanguageEditingBarrier } from './LanguageEditingBarrier';

export type Props = {
  item: MatrixSliderQuestion.Question;
};

const MatrixSliderQuestionBuilder = ({ item }: Props) => {
  const [_, dispatch] = useSurveyBuilderState();

  const updateSettings = useCallback((settings: MatrixSliderQuestion.SliderSettings) => {
    dispatch({
      type: 'update-question-settings',
      questionIdentifier: item.base.identifier,
      settings: {
        ...item.settings,
        slider: settings,
      },
    });
  }, [item.settings, item.base.identifier, dispatch]);

  const hasLinkedRows = useHasLinkedRows(item);
  const canFar = useCanCreateFindAndReplace();

  return (
    <>
      <QuestionSettingTitle
        title="Slider" />
      <MatrixSliderSettings
        className={styles.slider}
        settings={item.settings.slider}
        updateSettings={updateSettings}
        identifier={item.base.identifier} />
      <NumberSettingsBuilder item={item} />

      <QuestionSettingTitle title="Rows">
        <ResponsePiping
          disabled={hasLinkedRows}
          type="rows" />
        <ReuseQuestionRows />
        {canFar ? <FindAndReplaceRows question={item} /> : null}
      </QuestionSettingTitle>

      <QuestionRowsBuilderContainer
        maxRows={surveyBuilder.matrixSlider.MaxRows}
        minRows={surveyBuilder.matrixSlider.MinRows}>
        <QuestionRowsBuilder
          className={styles.rows}
          renderAction={row => (
            <SlidersRowContextMenu
              question={item}
              row={row as MatrixSliderQuestion.Row} />
          )} />
      </QuestionRowsBuilderContainer>

      <LanguageEditingBarrier>
        <RandomizeCheckbox
          disabled={item.settings.orderRowsBasedOnSource}
          className={styles.checkbox}
          label="Randomize Rows" />

        {hasLinkedRows &&
          <OrderRowsBasedOnSource
            disabled={item.settings.randomization}
            className={styles.checkbox} />
        }

        <RationaleCheckbox className={styles.checkbox} />
      </LanguageEditingBarrier>
    </>
  );
};

export default MatrixSliderQuestionBuilder;