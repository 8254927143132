import { useMemo } from 'react';
import { AddItemButton } from '@presentation/AddItemButton';
import type { SurveyTemplate } from '@/types/survey.templates';
import { TextEntryList } from '@/components/TextEntryList';
import styles from './style/Presets.css';

type Props = {
  canAdd: boolean;
  canRemove: boolean;
  items: SurveyTemplate.LinkedEntity[];
  onAddItem: () => void;
  onRemoveItem: (id: string) => () => void;
  onUpdateItem: (id: string) => (v: string) => void;
};

export const TemplateEfficacyPrimaryEndpointsInput = ({
  canAdd,
  canRemove,
  onAddItem,
  onRemoveItem,
  onUpdateItem,
  ...props
}: Props) => {

  const items = useMemo(() => {
    return props.items.map(m => ({
      key: m.id,
      value: m.value,
    }));
  }, [props.items]);

  return (
    <>
      <TextEntryList
        items={items}
        placeholder="Enter a primary endpoint"
        removeItem={onRemoveItem}
        removeItemEnabled={canRemove}
        updateItemValue={onUpdateItem} />
      {canAdd &&
        <AddItemButton
          className={styles.add}
          label="Add co-primary endpoint"
          onClick={onAddItem} />}
    </>
  );
};

export const TemplateEfficacySecondaryEndpointsInput = ({
  canAdd,
  canRemove,
  onAddItem,
  onRemoveItem,
  onUpdateItem,
  ...props
}: Props) => {

  const items = useMemo(() => {
    return props.items.map(m => ({
      key: m.id,
      value: m.value,
    }));
  }, [props.items]);

  return (
    <>
      <TextEntryList
        items={items}
        placeholder="Enter a secondary endpoint"
        removeItem={onRemoveItem}
        removeItemEnabled={canRemove}
        updateItemValue={onUpdateItem} />
      {canAdd &&
        <AddItemButton
          className={styles.add}
          label="Add secondary endpoint"
          onClick={onAddItem} />}
    </>
  );
};

type InputProps = {
  primary: Props;
  secondary: Props;
};

export const TemplateEfficacyEndpointsInput = ({ primary, secondary }: InputProps) => {

  return (
    <>
      <div className={styles.label}>Primary Endpoints</div>
      <TemplateEfficacyPrimaryEndpointsInput {...primary} />
      <div className={styles.label}>Secondary Endpoints</div>
      <TemplateEfficacySecondaryEndpointsInput {...secondary} />
    </>
  );
};