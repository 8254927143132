import { ChevronUp, XCircle } from 'react-feather';
import CheckIcon from '@mui/icons-material/Check';
import { ButtonActivityIndicator } from '@presentation';
import { cx } from '@utils';
import styles from './style/Tagging.Tag.css';

type CollapseActionProps = {
  onClick: () => void;
};

export const Collapse = (props: CollapseActionProps) => {
  return (
    <button
      className={styles.collapse}
      onClick={props.onClick}>
      <ChevronUp
        className={styles.caret}
        size={24} />
    </button>
  );
};

type DiscardActionProps = {
  disabled?: boolean;
  onClick:   () => void;
};

export const Discard = (props: DiscardActionProps) => {
  return (
    <button
      className={styles.remove}
      disabled={props.disabled}
      onClick={props.onClick}>
      <XCircle
        className={styles.x}
        size={24} />
    </button>
  );
};

Discard.displayName = 'Tag.Action.Discard';

type EndActionProps = {
  disabled: boolean;
  loading:  boolean;
  onClick:  () => void;
  saved:    boolean;
};

export const End = (props: EndActionProps) => {
  if (props.saved) {
    return (
      <div className={cx(styles.btn, styles.check)}>
        <CheckIcon />
      </div>
    );
  }
  return (
    <ButtonActivityIndicator
      classes={{
        root: styles.btn,
        indicator: styles.indicator,
      }}
      disabled={props.disabled}
      implicitDisable={props.loading}
      indicatorSize={4}
      loading={props.loading}
      onClick={props.onClick}>
      {props.saved
        ? <CheckIcon />
        : `End`}
    </ButtonActivityIndicator>
  );
};

End.displayName = 'Tag.Action.End';

type StartActionProps = {
  onClick: () => void;
};

export const Start = (props: StartActionProps) => {
  return (
    <ButtonActivityIndicator
      className={styles.btn}
      implicitDisable={false}
      indicatorSize={4}
      onClick={props.onClick}>
      Start
    </ButtonActivityIndicator>
  );
};

Start.displayName = 'Tag.Action.Start';