import { useCallback, useMemo } from 'react';
import { SurveyQuestion } from '@/types';
import { trunc } from '@utils';
import DropDown from '@/components/DropDown';

type DropdownItem = {
  id: string;
  value: string;
};

export type AIEDropdownItem = {
  ordinal: number;
  identifier: string;
};

type Props = {
  disabled?: boolean;
  items: AIEDropdownItem[];
  onSelect: (identifier: string) => void;
  value: AIEDropdownItem;
};

function formatDisplay(e: AIEDropdownItem) {
  return e
    ? `SS${e.ordinal}`
    : null;
}

export const AIEDropdown = ({
  disabled = false,
  items,
  onSelect,
  value,
}: Props) => {

  const getItemValue = useCallback((item: DropdownItem) => {
    const q = items.find(f => f.identifier === item.id);
    return formatDisplay(q);
  }, [items]);

  const handleSelect = useCallback((item: DropdownItem) => {
    onSelect(item.id);
  }, [onSelect]);

  const mappedItems = useMemo(() => {
    return items
      .map(m => ({
        id: m.identifier,
        value: `SS${m.ordinal}`,
      }));
  }, [items]);

  const text = useMemo(() => {
    return formatDisplay(value);
  }, [value]);

  return (
    <DropDown<DropdownItem>
      placeholder="Select stimuli display"
      disabled={disabled}
      items={mappedItems}
      getItemValue={getItemValue}
      onSelect={handleSelect}
      text={text} />
  );
};

export default AIEDropdown;