import { useCallback, useState } from 'react';
import { useMounted } from '@utils/hooks/useMounted';

type AsyncFn = <A extends unknown[], T = unknown>(fn: (...a: A) => Promise<T>) => (...a: A) => ReturnType<typeof fn>;
type UseLockReturn = [
  boolean,
  AsyncFn,
  (value: boolean) => void,
];

const useLock = (value = false): UseLockReturn => {
  const [isBusy, setState] = useState(value);
  const isMounted = useMounted();

  const lock = useCallback(fn => {
    return (...args) => {
      if (isBusy) return;

      setState(true);

      return fn(...args)
      .then(res => {
        if (isMounted()) setState(false);

        return res;
      })
      .catch(() => {
        if (isMounted()) setState(false);
      });
    };
  }, [isBusy, setState, isMounted]) as AsyncFn;

  return [isBusy, lock, setState];
};

export { useLock };
export default useLock;