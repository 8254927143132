import { memo } from 'react';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import MedicationIcon from '@mui/icons-material/Medication';

type Props = SvgIconProps;

export const Brand = memo((props: Props) => {
  return (
    <MedicationIcon {...props} />
  );
});