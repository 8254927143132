import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { UserProjectStatus } from '@enums';

const selectPipeline = (state: Store.State) => state.pipeline.me;

export const useProjectCompletionRouteValidator = () => {
  const pipeline = useSelector(selectPipeline);

  const validator = (projectId: number) => {
    const statuses = [
      UserProjectStatus.Active,
      UserProjectStatus.Inactive,
      UserProjectStatus.InReview,
    ];

    const record = pipeline[projectId];

    return statuses.includes(record?.statusId);
  };

  return useCallback(validator, [pipeline]);
};