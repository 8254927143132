import { useState } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { UTM } from '@/types';
import { UTMParametersContext } from './Context';

type Props =
  ChildrenProps;

export const UTMTrackingContainer = (props: Props) => {

  const [values] = useQueryParams({
    utm_campaign: StringParam,
    utm_content: StringParam,
    utm_medium: StringParam,
    utm_source: StringParam,
    utm_term: StringParam,
  });

  const [state] = useState<UTM>({
    campaign: values.utm_campaign,
    content: values.utm_content,
    medium: values.utm_medium,
    source: values.utm_source,
    term: values.utm_term,
  });

  return (
    <UTMParametersContext.Provider value={state}>
      {props.children}
    </UTMParametersContext.Provider>
  );
};

export default UTMTrackingContainer;