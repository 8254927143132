import type { ComponentType } from 'react';
import { useMemo } from 'react';
import { useSurveyTemplate } from '@/containers/SurveyBuilder';
import { useUrlStepper } from '@/utils';
import { SurveyTemplateType } from '@/enums';
import { CompetitiveIntelligenceScreens } from '../ProjectSurvey.Presets.CompetitiveIntelligence';
import { PersonaResearchScreens } from '../ProjectSurvey.Presets.PersonaResearch';
import { NetPromoterScoreScreens } from '../ProjectSurvey.Presets.NetPromotoreScore';
import { EmployeeSatisfactionScreens } from '../ProjectSurvey.Presets.EmployeeSatisfaction';
import { PricingStrategyScreens } from '../ProjectSurvey.Presets.PricingStrategy';
import { MarketingCampaignEffectivenessScreens } from '../ProjectSurvey.Presets.MCE';
import { MarketAnalysisScreens } from '../ProjectSurvey.Presets.MarketAnalysis';
import { TargetProductProfileScreens } from '../ProjectSurvey.Presets.TargetProductProfile';
import { ExternalSurveyScreens } from '../ProjectSurvey.Presets.ExternalSurvey';
import { MessageTestingScreens } from '../ProjectSurvey.Presets.MessageTesting';

type Props = {
  SurveyBuilderScreen?: React.ForwardRefExoticComponent<React.RefAttributes<unknown>>;
  SurveyTemplateScreen?: (props?: unknown) => JSX.Element;
};

export const useProjectSurveyStepper = ({ SurveyBuilderScreen, SurveyTemplateScreen }: Pick<Props, 'SurveyBuilderScreen' | 'SurveyTemplateScreen'>) => {
  const [template] = useSurveyTemplate();

  const screens: ComponentType[] = useMemo(() => {

    const templateScreensMap: Record<SurveyTemplateType, ComponentType[]> = {
      [SurveyTemplateType.None]: [],
      [SurveyTemplateType.CompetitiveIntelligence]: CompetitiveIntelligenceScreens,
      [SurveyTemplateType.PersonaResearch]: PersonaResearchScreens,
      [SurveyTemplateType.NetPromoterScore]: NetPromoterScoreScreens,
      [SurveyTemplateType.EmployeeSatisfaction]: EmployeeSatisfactionScreens,
      [SurveyTemplateType.PricingStrategy]: PricingStrategyScreens,
      [SurveyTemplateType.MarketingCampaignEffectiveness]: MarketingCampaignEffectivenessScreens,
      [SurveyTemplateType.MarketAnalysis]: MarketAnalysisScreens,
      [SurveyTemplateType.TargetProductProfile]: TargetProductProfileScreens,
      [SurveyTemplateType.ExternalSurvey]: ExternalSurveyScreens,
      [SurveyTemplateType.MessageTesting]: MessageTestingScreens,
    };

    const templateScreens = templateScreensMap[template];

    return [
      SurveyTemplateScreen,
      ...templateScreens,
      template === SurveyTemplateType.ExternalSurvey ? null : SurveyBuilderScreen,
    ].filter(Boolean);

  }, [
    SurveyBuilderScreen,
    SurveyTemplateScreen,
    template,
  ]);

  const [Screen, actions, step] = useUrlStepper(screens, { index: 0, min: 0 });

  return {
    actions,
    step,
    Screen,
    total: screens.length,
  };
};