import extend from '@enums/extend';

export enum ApprovalStatus {
  NeedsApproval  = 1,
  Approved       = 2,
  NotRequired    = 4,
  ReadyToApprove = 5,
  ReadyToReject  = 6,
  Rejected       = 3,
}

const map = new Map([
  [ApprovalStatus.NeedsApproval,    { name: 'Needs Approval'   }],
  [ApprovalStatus.Approved,   { name: 'Approved'  }],
  [ApprovalStatus.Rejected,  { name: 'Rejected' }],
  [ApprovalStatus.NotRequired,  { name: 'Not Required' }],
  [ApprovalStatus.ReadyToApprove, { name: 'Ready To Accept' }],
  [ApprovalStatus.ReadyToReject, { name: 'Ready to Reject' }],
]);

export default {
  ApprovalStatus: extend(map),
};