import { useMemo, memo, Fragment } from 'react';
import { Field, FieldProps, useFormikContext } from 'formik';
import { cx } from '@utils';
import { SelectLanguage } from '$admin/Project.Creation/Field.Setup.Language';
import styles from './style.css';

type Props = {
  classes?: {
    label?: string;
    value?: string;
  };
  editing?: boolean;
  label?: string;
  name: string;
  onChange?: (value: Descriptor[]) => void;
  values: Descriptor[];
};

export const FieldConferenceLanguage = (props: Props) => {
  return !props.editing
    ? <Display {...props} />
    : <Editable {...props} />;
};

const Editable = ({ classes = {}, label, name }: Props) => {
  const { setFieldValue } = useFormikContext();

  return (
    <Field name={name}>
      {({ field, meta }: FieldProps<Descriptor[]>) => (
        <div>
          <div className={cx(styles.label, classes.label)}>
            {label} {meta.touched && meta.error && <span className={styles.formError}>{meta.error}</span>}
          </div>
          <div className={styles.value}>
            <SelectLanguage
              onChange={vals => setFieldValue(field.name, vals)}
              //onBlur={field.onBlur}
              defaultValues={(field.value ?? []).map(d => d.id)} />
          </div>
        </div>
      )}
    </Field>
  );
};

const Display = memo(({ classes = {}, label, values }: Pick<Props, 'classes' | 'label' | 'values'>) => {

  const displayValue = useMemo(() => values ? values.map(v => v.name).join(', ') : 'Not Set', [values]);

  return (
    <Fragment>
      <div className={cx(styles.label, classes.label)}>{label}</div>
      <span className={cx(styles.dateValue, classes.value)}>
        {!!values?.length &&
          <>
            {displayValue}
          </>
        }
        {!values?.length &&
          <>
            Not Set
          </>
        }
      </span>
    </Fragment>
  );
});