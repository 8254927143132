import type { ProjectFrequency, ProjectSubtype } from '@enums';
import type {
  Project,
  TargetCompany,
  Title,
  VICS,
  ConferenceSettings,
  BaseProjectSurvey,
} from '@/types';

export enum ProjectCreationSteps {
  ProjectOverview = 'overview',
  Description = 'description',
  ScreeningQuestions = 'screening-questions',
  Survey = 'survey',
  AdditionalFilters = 'search-filters',
}

export type ProjectCreationScreen<T = unknown> = {
  component: React.ComponentType<T>;
  ignoreRef?: boolean;
  key: ProjectCreationSteps;
};

export type AutoFocusRefObject = {
  ref: React.Ref<HTMLInputElement | HTMLTextAreaElement>;
};

type FilterHandler<S> = {
  onAdd: (item: S) => void;
  onRemove: (item: S) => void;
};

type RKey = 'roles';
type SKey = 'suggested';
type TKey = 'titles';
type VKey = 'vics';

type GetFilterHandler = {
  (key: RKey): FilterHandler<Descriptor>;
  (key: TKey): FilterHandler<Title>;
  (key: SKey | VKey): FilterHandler<VICS>;
};

type FiltersConfig = {
  limit: { [type: number]: number };
};

export type ProjectCreationForm = {
  accountId: number;
  currentCompanies: [];
  formerCompanies: [];
  frequency: ProjectFrequency;
  goal: number;
  conferenceSettings: ConferenceSettings;
  // projectCode:        string;
  // startDate:          Date;
  netsuite?: {
    id: number;
    name: string;
    identifier: string;
  };
  projectSubtype: ProjectSubtype;
  purchaseOrder: string;
  roles: Descriptor[];
  suggested: VICS[];
  surveys: BaseProjectSurvey[];
  targetCompany: TargetCompany;
  targetCompleteDate: Date;
  titles: Title[];
  vics: VICS[];
} & Pick<Project,
  | 'name'
  | 'description'
  | 'projectType'
  | 'clientName'>;

type FiltersState = Pick<ProjectCreationForm, AdditionalFilter>;

export type ProjectFiltersContainerProps = {
  form: FiltersState;
  onChange: (data: Partial<FiltersState>) => void;
};

export type ProjectFiltersProps = {
  filters: FiltersConfig;
  form: FiltersState;
  getFilterHandler: GetFilterHandler;
};

export type AdditionalFilter =
  keyof Pick<ProjectCreationForm,
    | 'roles'
    | 'suggested'
    | 'titles'
    | 'vics'>;