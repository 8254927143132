import { useMutation } from '@tanstack/react-query';
import { externalSourcing as $external } from '@api/projects';
import {
  ExternalSourcingMutationsContext,
} from './context';
import type {
  ExternalSourcingMutations,
  ExternalSourcingConfigurationFormValues as ConfigurationFormValues,
} from './interfaces';

type Props =
  ChildrenProps &
  IProjectId;

export const OffPlatformSourcingMutationsContainer = ({ projectId, ...props }: Props) => {

  const deleteMutation = useMutation({
    mutationKey: ['delete-external-sourcing', projectId], mutationFn: (configId: number) => {
      return $external.deleteExternalSourcingConfiguration({
        configId,
        projectId,
      });
    },
  });

  const generateLinksMutation = useMutation({
    mutationKey: ['gen-external-sourcing-links', projectId], mutationFn: (configId: number) => {
      return $external.generateExternalSourcingLinks({
        configId,
        projectId,
        count: 250,
      });
    },
  });

  const downloadLinksMutation = useMutation({
    mutationKey: ['download-external-sourcing-links', projectId], mutationFn: (configId: number) => {
      return $external.downloadExternalSourcingLinks({
        configId,
        projectId,
      });
    },
  });

  const deleteUnusedLinksMutation = useMutation({
    mutationKey: ['delete-external-sourcing-unused-links', projectId], mutationFn: (configId: number) => {
      return $external.deleteExternalSourcingLinks({
        configId,
        projectId,
      });
    },
  });

  const saveMutation = useMutation({
    mutationKey: ['save-external-sourcing-configuration', projectId], mutationFn: (values: ConfigurationFormValues) => {
      return $external.saveExternalSourcingConfiguration({
        configId: values.id,
        projectId,
        label: values.label,
        linkRate: +values.rate,
        branding: values.branding,
        vendor: values.vendor,
        completionUrl: values.completionUrl,
        disqualifyUrl: values.disqualifyUrl,
        overquotaUrl: values.overquotaUrl,
        appendVidToLinks: values.appendVidToLinks,
        lazyLinkAssignment: values.lazyLinkAssignment,
        metadata: values.metadata,
      });
    },
  });

  const ctx: ExternalSourcingMutations = {
    deleteMutation,
    generateLinksMutation,
    downloadLinksMutation,
    deleteUnusedLinksMutation,
    saveMutation,
  };

  return (
    <ExternalSourcingMutationsContext.Provider value={ctx}>
      {props.children}
    </ExternalSourcingMutationsContext.Provider>
  );
};