import { useCallback, useContext, useMemo, useState } from 'react';
import { useChainedCommands, usePositioner } from '@remirror/react';
import type { EditorState } from '@remirror/pm';
import type { Positioner } from 'remirror/extensions';
import { hasStateChanged, selectionPositioner } from 'remirror/extensions';
import { resolveWordSnappingFromSelection } from '@containers/Transcript/utils';
import { usePreviousDistinct } from '@utils';
import type { WordSnapping } from '@/types/transcribe.rich-text';
import { TextReplacePopperContext, TextReplacePopperPositionerContext } from '@/components/Transcript/context';
import type { TextReplacePositionerData, TextReplaceSelection } from '@/components/Transcript/interfaces';

export const useTextReplaceHighlight = () => {
  const { setSelection } = useContext(TextReplacePopperContext);
  const commands = useChainedCommands();

  const handleTextReplaceHighlight = (highlight: TextReplaceSelection) => {
    setSelection(highlight);

    commands.blurHighlight().run();
  };

  return useCallback(handleTextReplaceHighlight, [
    commands,
    setSelection,
  ]);
};

export const useTextReplacePositioner = () => {
  const { selection } = useContext(TextReplacePopperContext);
  const open = !!selection;
  const previousOpen = usePreviousDistinct(open);

  return usePositioner<TextReplacePositionerData>((): Positioner<TextReplacePositionerData> => {
    return selectionPositioner.clone({
      hasChanged: props => {
        return hasStateChanged(props) || open !== previousOpen;
      },
      getActive: ({ helpers, view }) => {

        if (!selection) return [];

        const from = view.coordsAtPos(selection.from);
        const to = view.coordsAtPos(selection.to);

        return [{ from, to }];
      },
    });

  }, [
    open,
    previousOpen,
    selection,
  ]);
};

export const useSelectionWordSnapping = (selection: SelectionRange, state: EditorState) => {
  const { active } = useContext(TextReplacePopperPositionerContext);

  const wordsnap = useMemo(() => {
    if (!active) return {} as Partial<WordSnapping>;

    return resolveWordSnappingFromSelection(selection, state);
  }, [
    active,
    state,
    selection,
  ]);

  return wordsnap;
};

export const useHighlightWordSnappingSelection = (wordsnap: SelectionRange = {} as SelectionRange) => {
  const { active } = useContext(TextReplacePopperPositionerContext);
  const { selection } = useContext(TextReplacePopperContext);
  const { updateHighlight } = useChainedCommands();

  const [applied, setApplied] = useState(false);

  const mismatched = useMemo(() => {
    const outOfSync = selection?.from !== wordsnap.from || selection?.to !== wordsnap.to;
    const valid = Number.isInteger(selection?.from ?? selection?.to) && Number.isInteger(wordsnap.from ?? wordsnap.to);

    return valid && !applied && active && outOfSync;
  }, [
    active,
    applied,
    selection,
    wordsnap.from,
    wordsnap.to,
  ]);

  const setHighlightToWordSnappingSelection = useCallback(() => {

    updateHighlight({
      ...selection,
      from: wordsnap.from,
      to: wordsnap.to,
    }).run();

    setApplied(true);

  }, [
    selection,
    updateHighlight,
    wordsnap.from,
    wordsnap.to,
  ]);

  return [mismatched, setHighlightToWordSnappingSelection] as const;
};

type SelectionRange = {
  from: number;
  to:   number;
};