import { useCallback } from 'react';
import { FormButtons } from '@presentation/FormButtons';
import { useGroupTags, useGroupTagsDispatch, useGroupTagsUpdate, useGroupTagsReset } from './hooks';
import styles from './style.css';

export default function Editing() {
  const { tags } = useGroupTags();
  const dispatch = useGroupTagsDispatch();
  const [update, canUpdate] = useGroupTagsUpdate();
  const reset = useGroupTagsReset();

  const handleSave = useCallback(() => {
    update(tags);
    dispatch(state => ({ ...state, editing: false }));
  }, [dispatch, update, tags]);

  const handleCancel = useCallback(() => {
    reset();
    dispatch(state => ({ ...state, editing: false }));
  }, [dispatch, reset]);

  return (
    <div className={styles.buttons}>
      <FormButtons
        deletable={false}
        disabled={!canUpdate}
        onCancel={handleCancel}
        onSubmit={handleSave} />
    </div>
  );
}

export { Editing };