import type { SurveyItemType, SurveyQuestionType } from '@enums';
import type { SurveyFormItem, SurveyFormQuestion } from '@/types/survey';
import { useSurveyFormScreenContext } from '../Context';

export function useSurveyFormQuestionContext<T extends SurveyQuestionType = SurveyQuestionType>() {
  const [state] = useSurveyFormScreenContext();

  const item = state.item as SurveyFormItem<SurveyItemType.Question>;

  return {
    ...(item.content.question) as SurveyFormQuestion<T>,
    ordinal: state.ordinal,
    isScreening: item.content.isScreening,
  };

}

export default useSurveyFormQuestionContext;