import { useCallback } from 'react';
import { useTargetProductProfileData } from '@containers/SurveyBuilder/hooks';
import { SurveyTemplate } from '@/types/survey.templates';
import { useCanSaveList, useListElements } from './list-elements';

const minPrimary = 0;
const maxPrimary = 2;

const minSecondary = 0;
const maxSecondary = 10;

export const useCanSaveEfficacyEndpoints = (data: SurveyTemplate.EfficacyEndpoints) => {

  const canSavePrimary = useCanSaveList({
    items: data.primary,
    minItems: minPrimary,
    maxItems: maxPrimary,
  });

  const canSaveSecondary = useCanSaveList({
    items: data.secondary,
    minItems: minSecondary,
    maxItems: maxSecondary,
  });

  return canSavePrimary && canSaveSecondary;
};

export const useTemplateEfficacyEndpoints = () => {
  const [templateData, dispatch] = useTargetProductProfileData();

  const primary = useListElements({
    min: minPrimary,
    max: maxPrimary,
    initial: templateData.efficacyEndpoints.primary,
  });

  const secondary = useListElements({
    min: minSecondary,
    max: maxSecondary,
    initial: templateData.efficacyEndpoints.secondary,
  });

  const onSubmit = useCallback(() => {
    dispatch({
      type: 'template-efficacy-endpoints-updated',
      value: {
        primary: primary.items,
        secondary: secondary.items,
      },
    });
  }, [
    dispatch,
    primary.items,
    secondary.items,
  ]);

  return {
    onSubmit,
    primary,
    secondary,
  };
};