import { OptionsInput } from '@fullcalendar/core';
import dayGrid from '@fullcalendar/daygrid';
import timeGrid from '@fullcalendar/timegrid';
import interaction from '@fullcalendar/interaction';
import { addMinutes, addMonths, getMinutes, startOfMinute } from 'date-fns';
import { device } from '@utils';
import {
  createColumnHeader,
  ColumnHeader,
  ColumnHeaderResponsive,
} from './ColumnHeader';
import { EventAPI } from './interfaces';

export const defaultEventRender: OptionsInput['eventRender'] = info => {
  if (!info.el.id && info.event.id) {
    info.el.id = `calendar-event-${info.event.id}`;
  }
};

export const getCalendarDefaults = (): OptionsInput => {
  return {
    allDaySlot: false,
    columnHeaderHtml: createColumnHeader(ColumnHeader),
    defaultView: getCalendarView(),
    editable: true,
    eventColor: 'var(--pri-02)',
    eventRender: defaultEventRender,
    header: {
      left: '',
      center: 'title',
      right: 'prev,next',
    },
    height: 'parent',
    longPressDelay: 150,
    nowIndicator: true,
    plugins: [ dayGrid, interaction, timeGrid ],
    scrollTime: '08:00:00',
    selectable: true,
    selectMirror: true,
    selectOverlap: (event: EventAPI) => event.extendedProps.type === 'external',
    slotDuration: '00:30',
    slotLabelFormat: {
      hour: 'numeric',
      meridiem: 'long',
      minute: '2-digit',
      omitZeroMinute: true,
    },
    titleFormat: {
      year: 'numeric',
      month: 'long',
    },
    validRange: {
      start: new Date(),
      end: addMonths(new Date(), 6),
    },
    views: {
      timeGridThreeDay: {
        type: 'timeGrid',
        duration: { days: 3 },
      },
    },
    weekends: true,
  };
};

export const getCalendarView = () => {
  return window.innerWidth < 800
       ? 'timeGridDay'
       : 'timeGridWeek';
};

export const getFullCalSubmitButtonElement = (): HTMLButtonElement => {
  const selector = '.fc-save-button';

  return document.querySelector(selector);
};

export const getResponsiveCompatibleView = () => {
  return device.phone
       ? 'timeGridThreeDay'
       : getCalendarView();
};

export const getResponsiveCompatibleDefaults = () => {
  const defaults = getCalendarDefaults();

  if (!device.phone) return defaults;

  return {
    ...defaults,
    columnHeaderHtml: createColumnHeader(ColumnHeaderResponsive),
    defaultView: 'timeGridThreeDay',
    eventColor: 'var(--pri-02)',
    header: {
      left: 'title',
      center: '',
      right: 'prev,next',
    },
    height: 'parent',
  };
};

export const getProvidingDefaults = (options: OptionsInput = {}): OptionsInput => {

  if (!device.phone) {
    return {
      ...getCalendarDefaults(),
      contentHeight: 'auto',
      ...options,

      header: {
        left: 'save',
        center: 'title',
        right: 'prev,next',
      },
    };
  } else {
    return {
      ...getCalendarDefaults(),
      contentHeight: 'auto',
      ...options,

      columnHeaderHtml: createColumnHeader(ColumnHeaderResponsive),
      defaultView: 'timeGridThreeDay',
      eventColor: 'var(--pri-02)',
      header: {
        left: 'title',
        center: '',
        right: 'prev,next',
      },
      height: 'parent',
    };
  }
};

export * from './events';
export * from './transformers';