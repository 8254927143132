import cuid from 'cuid';
import { SurveyOptionType, SurveyQuestionType } from '@enums/Survey';
import type {
  LongTextResponseQuestion,
  SurveyQuestionMatrixRow,
  SurveyQuestionOption,
  MultipleChoiceQuestion,
  MultiselectQuestion,
  RankingQuestion,
  MatrixGridQuestion,
  MatrixSliderQuestion,
  ShortTextResponseQuestion,
  MultiTextboxQuestion,
  NumberInputTableQuestion,
  ExclusiveOptionsQuestion,
  SurveyQuestionOptionMetadata,
  SurveyQuestionRowMetadata,
  MaxDifferenceQuestion,
} from '@/types/survey';
import { convertToSurveyRichText } from '@/containers/Survey/utils';
import * as question from '../utils/question';

export function generateOption<T extends SurveyQuestionOptionMetadata = SurveyQuestionOptionMetadata>({
  id = null,
  conditions = [],
  base = {
    id: null,
    identifier: cuid(),
  },
  metadata,
  ordinal = 0,
  type = SurveyOptionType.Default,
  value,
}: GenerateOption<T>): SurveyQuestionOption<T> {
  return {
    id,
    base,
    conditions,
    metadata,
    ordinal,
    type,
    value: convertToSurveyRichText(value),
  };
}

export function generateOptionGroupOption(data: GenerateOptionGroupsOption) {
  return generateOption<ExclusiveOptionsQuestion.OptionMetadata>({
    ...data,
    metadata: {
      canDelete: true,
      canModifyValue: true,
      isOpenEnded: false,
      isExclusive: false,
      template: {},
      ...data.metadata,
    },
  });
}

export function generateMultipleChoiceOption(data: GenerateMultipleChoiceOption) {
  return generateOption<MultipleChoiceQuestion.OptionMetadata>({
    ...data,
    metadata: {
      canDelete: true,
      canModifyValue: true,
      isAnchored: false,
      isOpenEnded: false,
      template: {},
      ...(data.metadata || {}),
    },
  });
}

export function generateRankingOption(data: GenerateRankingOption) {
  return generateOption<RankingQuestion.OptionMetadata>({
    ...data,
    metadata: {
      canDelete: true,
      canModifyValue: true,
      isAnchored: false,
      isOpenEnded: false,
      template: {},
      ...(data.metadata || {}),
    },
  });
}

export function generateMatrixGridRow(data: GenerateSlidersRow) {
  return generateRow<MatrixGridQuestion.RowMetadata>({
    ...data,
    metadata: {
      canDelete: true,
      canModifyValue: true,
      isAnchored: false,
      isOpenEnded: false,
      isLastRowInCurrentGrid: false,
      template: {},
      ...(data.metadata || {}),
    },
  });
}

export function generateMatrixMultiselectRow(data: GenerateSlidersRow) {
  return generateRow<MatrixGridQuestion.RowMetadata>({
    ...data,
    metadata: {
      canDelete: true,
      canModifyValue: true,
      isAnchored: false,
      isOpenEnded: false,
      isLastRowInCurrentGrid: false,
      template: {},
      ...(data.metadata || {}),
    },
  });
}

export function generateSlidersRow(data: GenerateSlidersRow) {
  return generateRow<MatrixSliderQuestion.RowMetadata>({
    ...data,
    metadata: {
      canDelete: true,
      canModifyValue: true,
      isAnchored: false,
      isOpenEnded: false,
      template: {},
      ...(data.metadata || {}),
    },
  });
}

export function generateNumberInputTableRow(data: GenerateNumberInputTableRow) {
  return generateRow<NumberInputTableQuestion.RowMetadata>({
    ...data,
    metadata: {
      canDelete: true,
      canModifyValue: true,
      isAnchored: false,
      isOpenEnded: false,
      template: {},
      ...(data.metadata || {}),
    },
  });
}

export function generateMultiselectOption(data: GenerateMultiselectOption) {
  return generateOption<MultiselectQuestion.OptionMetadata>({
    ...data,
    metadata: {
      canDelete: true,
      canModifyValue: true,
      isAnchored: false,
      isExclusive: false,
      isOpenEnded: false,
      template: {},
      ...(data.metadata || {}),
    },
  });
}

export function generateOptionRichText<T extends SurveyQuestionOptionMetadata = SurveyQuestionOptionMetadata>({
  id = null,
  conditions = [],
  base = {
    id: null,
    identifier: cuid(),
  },
  metadata,
  ordinal = 0,
  type = SurveyOptionType.Default,
  value,
}: GenerateOptionRichText<T>): SurveyQuestionOption<T> {
  return {
    id,
    base,
    conditions,
    metadata,
    ordinal,
    type,
    value,
  };
}

export function generateRowRichText<T extends SurveyQuestionRowMetadata = SurveyQuestionRowMetadata>({
  id = null,
  conditions = [],
  base = {
    id: null,
    identifier: cuid(),
  },
  metadata,
  ordinal,
  value,
}: GenerateRowRichText<T>): SurveyQuestionMatrixRow<T> {
  return {
    id,
    base,
    conditions,
    metadata,
    ordinal,
    value,
  };
}

export function generateRow<T extends SurveyQuestionRowMetadata = SurveyQuestionRowMetadata>({ value, ...data }: GenerateRow<T>): SurveyQuestionMatrixRow<T> {
  return generateRowRichText({
    ...data,
    value: convertToSurveyRichText(value),
  });
}

export function generateMultipleChoiceQuestion({
  identifier = cuid(),
  ordinal = 1,
  settings = question.multipleChoice.DefaultSettings,
  ...q
}: GenerateMultipleChoiceQuestion): MultipleChoiceQuestion.Question {
  return {
    id: null,
    base: {
      id: null,
      identifier,
    },
    ordinal,
    settings,
    typeId: SurveyQuestionType.MultipleChoice,
    captureSheetColor: null,
    captureSheetLabel: null,
    ...q,
  };
}

export function generateMultiselectQuestion({
  identifier = cuid(),
  ordinal = 1,
  settings = question.multiselect.DefaultSettings,
  ...q
}: GenerateMultiselectQuestion): MultiselectQuestion.Question {
  return {
    ...q,
    id: null,
    base: {
      id: null,
      identifier,
    },
    ordinal,
    settings,
    typeId: SurveyQuestionType.Multiselect,
    captureSheetLabel: null,
    captureSheetColor: null,
  };
}

export function generateRankingQuestion({
  identifier = cuid(),
  ordinal = 1,
  settings = question.ranking.DefaultSettings,
  ...q
}: GenerateRankingQuestion): RankingQuestion.Question {
  return {
    id: null,
    base: {
      id: null,
      identifier,
    },
    ordinal,
    settings,
    typeId: SurveyQuestionType.Ranking,
    captureSheetLabel: null,
    captureSheetColor: null,
    ...q,
  };
}

export function generateShortTextQuestion({
  ordinal = 1,
  settings = question.shortText.DefaultSettings,
  ...q
}: GenerateShortTextQuestion): ShortTextResponseQuestion.Question {

  return {
    id: null,
    base: {
      id: null,
      identifier: cuid(),
    },
    ordinal,
    settings,
    typeId: SurveyQuestionType.ShortTextResponse,
    captureSheetLabel: null,
    captureSheetColor: null,
    ...q,
  };
}

export function generateLongTextQuestion({
  ordinal = 1,
  settings = question.longText.DefaultSettings,
  ...q
}: GenerateLongTextQuestion): LongTextResponseQuestion.Question {

  return {
    id: null,
    base: {
      id: null,
      identifier: cuid(),
    },
    ordinal,
    settings,
    typeId: SurveyQuestionType.LongTextResponse,
    captureSheetLabel: null,
    captureSheetColor: null,
    ...q,
  };
}

export function generateSlidersQuestion({
  identifier = cuid(),
  ordinal = 1,
  settings = question.matrixSlider.DefaultSettings,
  ...q
}: GenerateSlidersQuestion): MatrixSliderQuestion.Question {
  return {
    id: null,
    base: {
      id: null,
      identifier,
    },
    ordinal,
    settings,
    typeId: SurveyQuestionType.Sliders,
    captureSheetLabel: null,
    captureSheetColor: null,
    ...q,
  };
}

export function generateMatrixGridQuestion({
  identifier = cuid(),
  ordinal = 1,
  settings = question.matrixGrid.DefaultSettings,
  ...q
}: GenerateMatrixGridQuestion): MatrixGridQuestion.Question {
  return {
    id: null,
    base: {
      id: null,
      identifier,
    },
    ordinal,
    settings,
    typeId: SurveyQuestionType.MatrixGrid,
    captureSheetLabel: null,
    captureSheetColor: null,
    ...q,
  };
}

export function generateMultiTextBoxesQuestion({
  ordinal = 1,
  settings = question.multitextbox.DefaultSettings,
  ...q
}: GenerateMultiTextBoxesQuestion): MultiTextboxQuestion.Question {
  return {
    id: null,
    base: {
      id: null,
      identifier: cuid(),
    },
    ordinal,
    settings,
    typeId: SurveyQuestionType.MultiTextbox,
    captureSheetLabel: null,
    captureSheetColor: null,
    ...q,
  };
}

export function generateMaxDiffQuestion({
  identifier = cuid(),
  ordinal = 1,
  settings = question.maxDiff.DefaultSettings,
  ...q
}: GenerateMaxDiffQuestion): MaxDifferenceQuestion.Question {
  return {
    id: null,
    base: {
      id: null,
      identifier,
    },
    ordinal,
    settings,
    typeId: SurveyQuestionType.MaxDifference,
    captureSheetLabel: null,
    captureSheetColor: null,
    ...q,
  };
}

export function generateExclusiveOptionsQuestion({
  ordinal = 1,
  settings = question.exclusiveOptions.DefaultSettings,
  ...q
}: GenerateExclusiveOptionsQuestion): ExclusiveOptionsQuestion.Question {
  return {
    id: null,
    base: {
      id: null,
      identifier: cuid(),
    },
    ordinal,
    settings,
    typeId: SurveyQuestionType.ExclusiveOptions,
    captureSheetLabel: null,
    captureSheetColor: null,
    ...q,
  };
}

export function generateOptionGroup({
  ordinal = 1,
  ...data
}: GenerateOptionGroup): ExclusiveOptionsQuestion.OptionSection {
  return {
    identifier: data.identifier || cuid(),
    ordinal,
    ...data,
  };
}

export function generateNumberInputTableQuestion({
  identifier = cuid(),
  ordinal = 1,
  settings = question.numberInputTable.DefaultSettings,
  ...data
}: GenerateNumberInputTableQuestion): NumberInputTableQuestion.Question {
  return {
    id: null,
    base: {
      id: null,
      identifier,
    },
    ordinal,
    settings,
    typeId: SurveyQuestionType.NumberInputTable,
    captureSheetLabel: null,
    captureSheetColor: null,
    ...data,
  };
}

type GenerateOptionGroup =
  Pick<ExclusiveOptionsQuestion.OptionSection, 'value' | 'type' | 'settings'> &
  Partial<Pick<ExclusiveOptionsQuestion.OptionSection, 'identifier' | 'ordinal'>>;

type GenerateOptionRichText<T extends SurveyQuestionOptionMetadata = SurveyQuestionOptionMetadata> =
  Pick<SurveyQuestionOption<T>, 'metadata' | 'value'> &
  Partial<Pick<SurveyQuestionOption, 'id' | 'base' | 'conditions' | 'ordinal' | 'type'>>;

type GenerateOption<T extends SurveyQuestionOptionMetadata = SurveyQuestionOptionMetadata> =
  { value: string } &
  Omit<GenerateOptionRichText<T>, 'value'>;

type GenerateMultipleChoiceOption =
  Omit<GenerateOption, 'metadata'> & {
    metadata?: Partial<MultipleChoiceQuestion.OptionMetadata>;
  };

type GenerateMultiselectOption =
  Omit<GenerateOption, 'metadata'> & {
    metadata?: Partial<MultiselectQuestion.OptionMetadata>;
  };

type GenerateRankingOption =
  Omit<GenerateOption, 'metadata'> & {
    metadata?: Partial<RankingQuestion.OptionMetadata>;
  };

type GenerateOptionGroupsOption =
  Omit<GenerateOption, 'metadata'> & {
    metadata: Partial<ExclusiveOptionsQuestion.OptionMetadata> & Pick<ExclusiveOptionsQuestion.OptionMetadata, 'sectionId'>;
  };

type GenerateSlidersRow =
  Omit<GenerateRow, 'metadata'> & {
    metadata?: Partial<MatrixSliderQuestion.RowMetadata>;
  };

type GenerateNumberInputTableRow =
  Omit<GenerateRow, 'metadata'> & {
    metadata?: Partial<NumberInputTableQuestion.RowMetadata>;
  };

type GenerateRowRichText<T extends SurveyQuestionRowMetadata = SurveyQuestionRowMetadata> =
  Pick<SurveyQuestionMatrixRow<T>, 'metadata' | 'ordinal' | 'value'> &
  Partial<Pick<SurveyQuestionMatrixRow, 'id' | 'base' | 'conditions'>>;

type GenerateRow<T extends SurveyQuestionRowMetadata = SurveyQuestionRowMetadata> =
  { value: string } &
  Omit<GenerateRowRichText<T>, 'value'>;

type GenerateMultipleChoiceQuestion =
  { identifier?: string } &
  Omit<MultipleChoiceQuestion.Question, 'id' | 'base' | 'settings' | 'typeId' | 'ordinal' | 'captureSheetLabel' | 'captureSheetColor'> &
  Partial<Pick<MultipleChoiceQuestion.Question, 'settings' | 'ordinal'>>;

type GenerateNumberInputTableQuestion =
  { identifier?: string } &
  Omit<NumberInputTableQuestion.Question, 'id' | 'base' | 'settings' | 'typeId' | 'ordinal' | 'captureSheetLabel' | 'captureSheetColor'> &
  Partial<Pick<NumberInputTableQuestion.Question, 'settings' | 'ordinal'>>;

type GenerateMultiselectQuestion =
  { identifier?: string } &
  Omit<MultiselectQuestion.Question, 'id' | 'base' | 'settings' | 'typeId' | 'ordinal' | 'captureSheetLabel' | 'captureSheetColor'> &
  Partial<Pick<MultiselectQuestion.Question, 'settings' | 'ordinal'>>;

type GenerateRankingQuestion =
  { identifier?: string } &
  Omit<RankingQuestion.Question, 'id' | 'base' | 'settings' | 'typeId' | 'ordinal' | 'captureSheetLabel' | 'captureSheetColor'> &
  Partial<Pick<RankingQuestion.Question, 'settings' | 'ordinal'>>;

type GenerateShortTextQuestion =
  Omit<ShortTextResponseQuestion.Question, 'id' | 'base' | 'settings' | 'typeId' | 'ordinal' | 'captureSheetLabel' | 'captureSheetColor'> &
  Partial<Pick<ShortTextResponseQuestion.Question, 'settings' | 'ordinal'>>;

type GenerateLongTextQuestion =
  Omit<LongTextResponseQuestion.Question, 'id' | 'base' | 'settings' | 'typeId' | 'ordinal' | 'captureSheetLabel' | 'captureSheetColor'> &
  Partial<Pick<LongTextResponseQuestion.Question, 'settings' | 'ordinal'>>;

type GenerateSlidersQuestion =
  { identifier?: string } &
  Omit<MatrixSliderQuestion.Question, 'id' | 'base' | 'settings' | 'typeId' | 'ordinal' | 'captureSheetLabel' | 'captureSheetColor'> &
  Partial<Pick<MatrixSliderQuestion.Question, 'settings' | 'ordinal'>>;

type GenerateMatrixGridQuestion =
  { identifier?: string } &
  Omit<MatrixGridQuestion.Question, 'id' | 'base' | 'settings' | 'typeId' | 'ordinal' | 'captureSheetLabel' | 'captureSheetColor'> &
  Partial<Pick<MatrixGridQuestion.Question, 'settings' | 'ordinal'>>;

type GenerateMultiTextBoxesQuestion =
  Omit<MultiTextboxQuestion.Question, 'id' | 'base' | 'settings' | 'typeId' | 'ordinal' | 'captureSheetLabel' | 'captureSheetColor'> &
  Partial<Pick<MultiTextboxQuestion.Question, 'settings' | 'ordinal'>>;

type GenerateExclusiveOptionsQuestion =
  Omit<ExclusiveOptionsQuestion.Question, 'id' | 'base' | 'settings' | 'typeId' | 'ordinal' | 'captureSheetLabel' | 'captureSheetColor'> &
  Partial<Pick<ExclusiveOptionsQuestion.Question, 'settings' | 'ordinal'>>;

type GenerateMaxDiffQuestion =
  { identifier?: string } &
  Omit<MaxDifferenceQuestion.Question, 'id' | 'base' | 'settings' | 'typeId' | 'ordinal' | 'captureSheetLabel' | 'captureSheetColor'> &
  Partial<Pick<MaxDifferenceQuestion.Question, 'settings' | 'ordinal'>>;