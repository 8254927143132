import React, { useCallback, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { ZIndex } from '@consts';
import { Portal } from '@/components/Portal';
import { useSameWidthModifier, useZIndexModifier, PopperMenu, PopperMenuItem } from '@/components/Popper';

type Props = {
  children: React.ReactNode | RenderPopperChildren;
  canAddAIE: boolean;
  canAddMessage: boolean;
  canAddQuestion: boolean;
  onAddAIE: () => void;
  onAddMessage: () => void;
  onAddQuestion: () => void;
  onBreakSection?: () => void;
  sameWidth?: boolean;
};

type RenderPopperChildren = (props: RenderSurveyItemPopperProps, ref: React.Ref<HTMLElement>) => React.ReactNode;

export type RenderSurveyItemPopperProps = {
  disabled: boolean;
  onClick: () => void;
  popperOpen: boolean;
};

export const AddSurveyItemPopper = ({
  canAddAIE,
  canAddMessage,
  canAddQuestion,
  children,
  onAddAIE,
  onAddMessage,
  onAddQuestion,
  onBreakSection,
  sameWidth,
}: Props) => {

  const [popperOpen, setPopperOpen] = useState(false);

  const [referenceElement, setReferenceElement] = useState<HTMLElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);

  const zIndexModifier = useZIndexModifier({ zIndex: ZIndex.Popper });
  const sameWidthModifier = useSameWidthModifier();

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      zIndexModifier,
      sameWidth && sameWidthModifier,
    ].filter(Boolean),
    placement: 'bottom',
  });

  const disabled = useMemo(() => {
    return !canAddMessage && !canAddQuestion && !onBreakSection;
  }, [canAddMessage, canAddQuestion, onBreakSection]);

  const handleAnchorClick = useCallback(() => {
    if (disabled) return;
    setPopperOpen(true);
  }, [disabled]);

  const handleClickAway = useCallback((e: MouseEvent) => {
    if (!referenceElement.contains(e.target as Node)) {
      setPopperOpen(false);
    }
  }, [referenceElement]);

  const renderAnchorContent = useCallback(() => {

    if (typeof children === 'function') {
      return children({
        disabled,
        onClick: handleAnchorClick,
        popperOpen,
      }, setReferenceElement);
    }

    return (
      <div
        ref={setReferenceElement}
        onClick={handleAnchorClick}>
        {children}
      </div>
    );
  }, [
    children,
    disabled,
    handleAnchorClick,
    popperOpen,
  ]);

  const handleAddQuestion = useCallback(() => {
    onAddQuestion();
    setPopperOpen(false);
  }, [onAddQuestion]);

  const handleAddMessage = useCallback(() => {
    onAddMessage();
    setPopperOpen(false);
  }, [onAddMessage]);

  const handleAddAIE = useCallback(() => {
    onAddAIE();
    setPopperOpen(false);
  }, [onAddAIE]);

  const handleSectionBreak = useCallback(() => {
    if (!onBreakSection) return;

    onBreakSection();
    setPopperOpen(false);
  }, [onBreakSection]);

  return (
    <>
      {renderAnchorContent()}
      {popperOpen &&
        <Portal>
          <div
            ref={setPopperElement}
            style={popperStyles.popper}
            {...attributes.popper}>
            <ClickAwayListener onClickAway={handleClickAway}>
              <PopperMenu>
                {canAddQuestion &&
                  <PopperMenuItem
                    onClick={handleAddQuestion}>
                    Question
                  </PopperMenuItem>}
                {canAddMessage &&
                  <PopperMenuItem
                    onClick={handleAddMessage}>
                    Text Screen
                  </PopperMenuItem>}
                {canAddAIE &&
                  <PopperMenuItem
                    onClick={handleAddAIE}>
                    Stimuli Sequence
                  </PopperMenuItem>}
                {!!onBreakSection &&
                  <PopperMenuItem
                    onClick={handleSectionBreak}>
                    Split Section
                  </PopperMenuItem>}
              </PopperMenu>
            </ClickAwayListener>
          </div>
        </Portal>
      }
    </>
  );
};