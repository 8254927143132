import { useCallback, useEffect, useState, useRef } from 'react';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import Popper from '@mui/material/Popper';
import { useSearchFiltersQuery } from '@/containers/WorkspaceGlobalSearch/hooks';
import { GroupTag } from '@/presentation';
import type { GroupTag as IGroupTag } from '@/types';
import { cx } from '@/utils';
import tagStyles from '@/presentation/style/GroupTag.css';
import styles from './style/Cell.Member.Tags.css';

type HiddenTagProps = {
  tags: IGroupTag[];
  onClick: (tag: IGroupTag) => () => unknown;
};

const HiddenTags = ({ tags, onClick }: HiddenTagProps) => {
  const [popperOpen, setPopperOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  const handleClickAway = useCallback(() => setPopperOpen(false), []);
  const handleTogglePopper = useCallback(() => setPopperOpen(prev => !prev), []);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div style={{ marginLeft: '6px' }}>
        <button className={cx(tagStyles.root, styles.tag)} ref={ref}>
          <div className={cx(tagStyles.content, tagStyles.clickable)} onClick={handleTogglePopper}>
            + {tags.length}
          </div>
        </button>
        <Popper
          className={styles.hiddenPopper}
          open={popperOpen}
          anchorEl={ref.current}>
          {tags.map(tag => (
            <TagComponent
              key={tag.id}
              tag={tag}
              onClick={onClick} />
          ))}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

type TagComponentProps = {
  tag: IGroupTag;
  onClick: (tag: IGroupTag) => () => unknown;
};

const TagComponent = ({ tag, onClick }: TagComponentProps) => {
  return (
    <GroupTag
      className={styles.tag}
      onClick={onClick(tag)}>
      {tag.name}
    </GroupTag>
  );
};

type Props = {
  tags: IGroupTag[];
  maxTags: number;
};

export default function Tags({ tags, maxTags }: Props) {
  const [, setQuery] = useSearchFiltersQuery();

  const [visible, setVisible] = useState<IGroupTag[]>((tags || []).slice(0, maxTags));
  const [hidden, setHidden] = useState<IGroupTag[]>((tags || []).slice(maxTags));

  const handleClick = useCallback((tag: IGroupTag) => () => {
    setQuery({ tags: [tag] });
  }, [setQuery]);

  useEffect(() => {
    setVisible((tags || []).slice(0, maxTags));
    setHidden((tags || []).slice(maxTags));
  }, [tags, maxTags]);

  return (
    <div className={styles.root}>
      {visible.map(tag => (
        <TagComponent
          key={tag.id}
          tag={tag}
          onClick={handleClick} />
      ))}
      {!!hidden.length &&
        <HiddenTags
          tags={hidden}
          onClick={handleClick} />}
    </div>
  );
}

export { Tags };