import { Star } from 'react-feather';
import { ColorCircle, ColorSelect, InlineColorSelectBtn } from '@/components/Conference.Tagging/Tagging.ColorSelect';
import * as Table from '@/components/Table';
import styles from './style/Draggable.css';

type Props = {
  color:    string;
  editable: boolean;
  onChange: (color: string) => void;
};

export const Cell = (props: Props) => {
  if (!props.editable) {
    return (
      <Table.Body.TD className={styles.color}>
        <div className={styles.cell}>
          <ColorCircle color={props.color} />
        </div>
      </Table.Body.TD>
    );
  }

  return (
    <Table.Body.TD className={styles.color}>
      <div className={styles.cell}>
        <ColorSelect
          BtnComponent={InlineColorSelectBtn}
          color={props.color}
          onChange={props.onChange} />
      </div>
    </Table.Body.TD>
  );
};

export const StarCell = () => {
  return (
    <Table.Body.TD className={styles.color}>
      <div className={styles.cell}>
        <Star className={styles.star} />
      </div>
    </Table.Body.TD>
  );
};

Cell.displayName = 'Column.Color.Cell';

export const Header = (props: unknown) => {
  return (
    <Table.Header.TH className={styles.color}>
      Color
    </Table.Header.TH>
  );
};

Header.displayName = 'Column.Color.Header';