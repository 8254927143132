import cuid from 'cuid';
import {
  SurveyActionType, SurveyLogicRule, SurveyPipedValueType, SurveyQuestionConditionType,
} from '@enums/Survey';
import {
  MessageTesting,
  SurveyTemplateEntityType,
  SurveyTemplateType,
} from '@enums/survey.template';
import type { SurveyTemplate } from '@/types/survey.templates';
import type {
  SurveyBuilderVersion,
  SurveySection,
  SurveyLogic,
  SurveyQuestionOption,
  MatrixGridQuestion } from '@/types';
import {
  Remirror,
  SurveyQuestionNumberType,
} from '@/types';
import { toTitleCase } from '@/utils';
import * as $question from '../utils/question';
import * as $template from './utils';

const defaultTemplateData: SurveyTemplate.MessageTesting.Data = {
  attitudinals: $template.generateInitialTextItems(4),
  categories: [
    {
      identifier: cuid(),
      label: 'C1',
      name: '',
      messages: $template.generateInitialTextItems(4),
    },
    {
      identifier: cuid(),
      label: 'C2',
      name: '',
      messages: $template.generateInitialTextItems(4),
    },
  ],
  maxDiff: {
    attributesPerSet: null,
    dimension: '',
    sets: null,
  },
  medicalConditions: {
    primary: {
      id: cuid(),
      value: '',
    },
    other: [],
  },
  messageAnalysis: [
    {
      id: cuid(),
      dimension: '',
      leftAxis: '',
      rightAxis: '',
    },
    {
      id: cuid(),
      dimension: '',
      leftAxis: '',
      rightAxis: '',
    },
  ],
  product: $template.generateLinkedEntity(),
  qualification: 'surgical-procedures',
  specialties: {
    enabled: [],
    disabled: [],
  },
  tpp: $template.generateRichTextNode([$template.generateParagraphNode()]),
};

const defaultTemplateDataWithValues: SurveyTemplate.MessageTesting.Data = {
  attitudinals: [
    'I will consider tumor location, respectability, number of recurrences, patient age and comorbidities before making a treatment decision for my patient',
    'I prefer systemic therapy over surgery when a patient has had a recurrence',
    'I prefer systemic therapy when a tumor may not be able to be completely resected with surgery emotionally',
    `Patient comorbidities are part of my evaluation criteria when selecting treatment for a patient and motivate me to route them to systemic therapy over surgery`,
    `I choose surgery only when I am confident that the outcomes will improve the patient's functionality and not cause joint worsening`,
    'To pick the right treatment plan for my patients I will evaluate complex TGCT cases with my oncology colleagues',
    'I have a strong relationship with a local sarcoma center of excellence',
  ].map(value => ({
    id: cuid(),
    value,
  })),
  categories: [
    {
      identifier: cuid(),
      label: 'C1',
      name: 'Way In',
      messages: [
        'Repeat surgeries due to recurrent TGCT/PVNS may lead to severe joint damage or loss of function',
        'The treatment risk/benefit profile may not be straightforward for TGCT/PVNS. Consulting with a multidisciplinary team of experts who understand TGCT/PVNS subtypes and systemic treatment options can lead to an optimized treatment plan',
        'With TGCT/PVNS, complete resection may not be possible, and recurrence is a concern; discuss both surgical and systemic options with a multidisciplinary team of experts before treatment initiation',
      ].map(m => $template.generateLinkedEntity(m)),
    },
    {
      identifier: cuid(),
      label: 'C2',
      name: 'Disease Background',
      messages: [
        'To manage TGCT/PVNS confidently, classify your patients by localized or diffuse subtype based on the extent and location of disease to know when surgical outcomes may not benefit the patient',
        'TGCT/PVNS is a heterogeneous disease diagnosed as 1 of 2 subtypes based on the extent and location of the tumor',
        'To determine appropriate treatment, it is important to properly classify TGCT/PVNS to understand the potential for recurrence and/or poor surgical outcomes',
        'After resection, recurrence rates can be as high as 15% for localized TGCT/PVNS and 55% for diffuse TGCT/PVNS',
        'Before initiating surgery, consider what can influence surgical outcomes in TGCT/PVNS: tumor locations, subtype, patient comorbidities, potential joint worsening, and poor functional outcomes.',
      ].map(m => $template.generateLinkedEntity(m)),
    },
    {
      identifier: cuid(),
      label: 'C3',
      name: 'Multi-disciplinary Treatment Team',
      messages: [
        'TGCT/PVNS is complex and prone to recurrence; optimal outcomes may require a consult from a multidisciplinary treatment team of medical and surgical oncologists',
        'When a good surgical outcome in TGCT/PVNS is unlikely, maximize patient chances for optimal results by consulting an expert treatment team of medical and surgical oncologists prior to treatment initiation',
        'TGCT/PVNS patient characteristics that can compromise surgical outcomes, such as extent of joint involvement, prior history, comorbidities, and age, should be addressed together with a multidisciplinary treatment team before initiating treatment',
        'Before TGCT/PVNS becomes aggressive, involve a multidisciplinary treatment team of medical and surgical oncologists to get a broader perspective and evaluate all available options',
        'TGCT/PVNS is complex and prone to recurrence; optimal outcomes may require a team-based collaboration with a local oncology center of excellence',
      ].map(m => $template.generateLinkedEntity(m)),
    },
    {
      identifier: cuid(),
      label: 'C4',
      name: 'Key Clinical Data',
      messages: [
        'PRODUCT X: The only oral, FDA-approved, systemic therapy for TGCT/PVNS with proven efficacy and safety in a phase 3 trial in TGCT/PVNS patients not amenable to improvement with surgery',
        'PRODUCT X was studied in patients with and without prior surgery and with symptomatic localized or diffuse TGCT/PVNS',
        'PRODUCT X was proven effective in reducing tumor size and improving joint function in a phase 3 trial, with most responders having an ongoing response at 38 months of follow-up',
        '100% of patients on PRODUCT X experienced some reduction in tumor length',
        'With PRODUCT X, 38% of patients demonstrated a reduction in tumor length of 30% or more, and with a longer ~3-year follow-up, 62% of patients achieved that level of response',
        'PRODUCT X delivered complete tumor reduction in 15% of patients at 25 weeks, and with a longer ~3-year follow-up, 30% of patients achieved complete response',
        'In clinical trials for PRODUCT X, serious liver enzyme elevations were consistently seen within a predictable 2-month window and were manageable and reversible',
      ].map(m => $template.generateLinkedEntity(m)),
    },
    {
      identifier: cuid(),
      label: 'C5',
      name: 'Response Rate and Range of Motion Improvement',
      messages: [
        'PRODUCT X improved mobility and achieved a robust overall tumor response rate. Tumor reduction was defined by 2 metrics: 38% of patients demonstrated a reduction in tumor length, and 56% in tumor volume',
        'PRODUCT X showed significant improvement in patient range of motion and a positive overall response defined by reduction in tumor length and tumor volume',
        'Regardless of tumor response, patients on PRODUCT X gained a significant increase in their range of motion',
        'Both partial and complete tumor response resulted in increased range of motion for patients treated with PRODUCT X',
        'PRODUCT X delivered improved mobility and long-term tumor reduction',
      ].map(m => $template.generateLinkedEntity(m)),
    },
  ],
  maxDiff: {
    attributesPerSet: 4,
    dimension: 'Motivating',
    sets: 15,
  },
  medicalConditions: {
    primary: {
      id: cuid(),
      value: 'Tenosynovial Giant Cell Tumor (TGCT)',
    },
    other: [{
      id: cuid(),
      value: 'Ewing Sarcoma',
    }, {
      id: cuid(),
      value: 'Lymphoma',
    }],
  },
  messageAnalysis: [
    {
      id: cuid(),
      dimension: 'believability for',
      leftAxis: 'Not at all believable',
      rightAxis: 'Extremely believable',
    },
    {
      id: cuid(),
      dimension: 'uniqueness to',
      leftAxis: 'Not at all unique',
      rightAxis: 'Extremely unique',
    },
  ],
  product: $template.generateLinkedEntity('Product'),
  qualification: 'surgical-procedures',
  specialties: {
    enabled: [
      'General Orthopedics',
      'Orthopedic Oncology',
      'Orthopedic Surgeon',
      'Rheumatology',
      'Sports Medicine',
    ].map(value => ({
      id: cuid(),
      value,
    })),
    disabled: [
      'Primary Care',
      'Neurology',
    ].map(value => ({
      id: cuid(),
      value,
    })),
  },
  tpp: $template.generateRichTextNode([$template.generateParagraphNode([
    $template.generateBoldTextNode('TPP Placeholder'),
  ])]),
};

export function generateDefaultTemplateData(withValues: boolean) {
  return withValues ? defaultTemplateDataWithValues : defaultTemplateData;
}

function generateSections(data: SurveyTemplate.MessageTesting.Data): SurveySection[] {

  const screener = $template.generateSection({
    isScreener: true,
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: MessageTesting.Section.Screener },
    },
    name: 'Screener',
  });

  const message = $template.generateSection({
    isScreener: false,
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: MessageTesting.Section.MessageAnalysis },
    },
    name: 'Message Analysis',
  });

  const maxDiff = $template.generateSection({
    isScreener: false,
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: MessageTesting.Section.MaxDiffExercise },
    },
    name: 'Max-Diff Exercise',
  });

  const ordering = $template.generateSection({
    isScreener: false,
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: MessageTesting.Section.OrderingExercise },
    },
    name: 'Ordering Exercise',
  });

  const demographics = $template.generateSection({
    isScreener: false,
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: MessageTesting.Section.Demographics },
    },
    name: 'Demographics/Clinigraphics',
  });

  return [
    screener,
    message,
    maxDiff,
    ordering,
    demographics,
  ]
    .map((m, i) => ({
      ...m,
      ordinal: i + 1,
    }));
}

export function generateSurveyData(data: SurveyTemplate.MessageTesting.Data): SurveyBuilderVersion {

  function generatePrimaryConditionNode(marks?: Remirror.BoldMark[]) {
    return $template.generateTemplateNode({
      nodeType: `mt-medical-condition`,
      value: data.medicalConditions.primary.value,
    }, marks);
  }

  function generateProductNode(marks?: Remirror.BoldMark[]) {
    return $template.generateTemplateNode({
      nodeType: `product`,
      value: data.product.value,
    }, marks);
  }

  const sections = generateSections(data);
  const sectionsMap = sections.reduce<SectionsMap>((acc, x) => ({
    ...acc,
    [x.metadata.template.key]: x,
  }), {});

  const PrimarySpecialty = (function() {

    const questionIdentifier = cuid();
    const section = sectionsMap[MessageTesting.Section.Screener];

    const disabledOptions = data.specialties.disabled.map((m, i) => $template.generateMultipleChoiceOption({
      metadata: {
        canModifyValue: false,
        canDelete: false,
        template: {
          linkedEntity: {
            id: m.id,
            type: SurveyTemplateEntityType.MedicalSpecialty,
          },
        },
      },
      ordinal: i + 1,
      value: m.value,
    }));
    const enabledOptions = data.specialties.enabled.map((m, i) => $template.generateMultipleChoiceOption({
      metadata: {
        canModifyValue: false,
        canDelete: false,
        template: {
          linkedEntity: {
            id: m.id,
            type: SurveyTemplateEntityType.MedicalSpecialty,
          },
        },
      },
      ordinal: disabledOptions.length + i + 1,
      value: m.value,
    }));

    const otherOption = $template.generateMultiselectOption({
      metadata: {
        canDelete: true,
        canModifyValue: false,
        isAnchored: true,
        isOpenEnded: true,
      },
      ordinal: data.specialties.enabled.length + data.specialties.disabled.length + 1,
      value: 'Other',
    });

    const options = [
      ...disabledOptions,
      ...enabledOptions,
      otherOption,
    ].filter(Boolean);

    const question = $template.generateMultipleChoiceQuestion({
      identifier: questionIdentifier,
      metadata: {
        anchor: false,
        canModifyValue: true,
        canDelete: true,
        template: {
          key: MessageTesting.Question.PrimarySpecialty,
        },
      },
      options,
      section: {
        identifier: section.identifier,
      },
      value: {
        type: Remirror.NodeType.Doc,
        content: [
          $template.generateParagraphNode([
            $template.generateTextNode(`Which of the following best describes your `),
            $template.generateBoldTextNode(`primary`),
            $template.generateTextNode(` medical specialty?`),
          ]),
        ],
      },
    });

    const logic: SurveyLogic.Item[] = [{
      action: { type: SurveyActionType.Disqualification },
      id: null,
      identifier: cuid(),
      conditions: [
        ...disabledOptions.map(m => $template.generateLogicItemQuestionCondition({
          data: {
            question: { identifier: questionIdentifier },
            type: SurveyQuestionConditionType.OptionSelected,
            value: {
              option: { identifier: m.base.identifier },
            },
          },
        })),
        $template.generateLogicItemQuestionCondition({
          data: {
            question: { identifier: questionIdentifier },
            type: SurveyQuestionConditionType.OptionSelected,
            value: {
              option: { identifier: otherOption.base.identifier },
            },
          },
        }),
      ].filter(Boolean),
      metadata: {
        canDelete: true,
        canEdit: true,
        template: { key: MessageTesting.Logic.PrimarySpecialty },
      },
      rule: {
        expression: null,
        type: SurveyLogicRule.AnyOfTheAbove,
      },
    }];

    const item = $template.generateQuestionItem(question);

    return { logic, item, question };
  }());

  const Qualification = (function() {

    const section = sectionsMap[MessageTesting.Section.Screener];

    const textMap: Record<SurveyTemplate.MessageTesting.Qualification, string> = {
      ['surgical-procedures']: 'How many surgical procedures have you conducted in the past year?',
      ['patients-treated']: 'How many patients have you treated in the past year?',
      ['interventions']: 'How many interventions have you conducted in the past year?',
      ['other']: '',
    };

    const rowMap: Record<SurveyTemplate.MessageTesting.Qualification, string> = {
      ['surgical-procedures']: 'Surgical Procedures',
      ['patients-treated']: 'Patients Treated',
      ['interventions']: 'Interventions',
      ['other']: 'Value',
    };

    const questionText = textMap[data.qualification];

    const question = $template.generateSlidersQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: MessageTesting.Question.Qualification,
        },
      },
      matrixRows: [$template.generateSlidersRow({
        metadata: {
          canDelete: false,
          canModifyValue: false,
        },
        ordinal: 1,
        value: rowMap[data.qualification],
      })],
      section: {
        identifier: section.identifier,
      },
      settings: {
        ...$question.matrixSlider.DefaultSettings,
        slider: {
          ...$question.matrixSlider.DefaultSettings.slider,
          minValue: 0,
          maxValue: 100,
          increment: 1,
          hideSlider: true,
        },
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(questionText),
        ]),
      ]),
    });

    const item = $template.generateQuestionItem(question);

    return { item, question };
  }());

  const BoardCertified = (function() {

    const questionIdentifier = cuid();
    const section = sectionsMap[MessageTesting.Section.Screener];

    const yesOption = $template.generateMultipleChoiceOption({
      metadata: {
        canDelete: false,
        canModifyValue: false,
        isAnchored: false,
        isOpenEnded: false,
      },
      ordinal: 1,
      value: 'Yes',
    });

    const noOption = $template.generateMultipleChoiceOption({
      metadata: {
        canDelete: false,
        canModifyValue: false,
        isAnchored: false,
        isOpenEnded: false,
      },
      ordinal: 2,
      value: 'No',
    });

    const question = $template.generateMultipleChoiceQuestion({
      identifier: questionIdentifier,
      metadata: {
        anchor: false,
        canModifyValue: true,
        canDelete: true,
        template: {
          key: MessageTesting.Question.Certified,
        },
      },
      options: [yesOption, noOption],
      section: {
        identifier: section.identifier,
      },
      value: {
        type: Remirror.NodeType.Doc,
        content: [
          $template.generateParagraphNode([
            $template.generateTextNode(`Are you board-certified or board-eligible in your primary medical specialty?`),
          ]),
        ],
      },
    });

    const logic = [$template.generateOptionSelectedLogic({
      question: { identifier: questionIdentifier },
      action: { type: SurveyActionType.Disqualification },
      option: { identifier: noOption.base.identifier },
    })];

    const item = $template.generateQuestionItem(question);

    return { logic, item, question };
  }());

  const ClinicalPractice = (function() {

    const questionIdentifier = cuid();
    const section = sectionsMap[MessageTesting.Section.Screener];

    const row = $template.generateSlidersRow({
      metadata: {
        canDelete: false,
        canModifyValue: false,
      },
      ordinal: 1,
      value: 'Years',
    });

    const question = $template.generateSlidersQuestion({
      identifier: questionIdentifier,
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: MessageTesting.Question.PracticeYears,
        },
      },
      matrixRows: [row],
      section: {
        identifier: section.identifier,
      },
      settings: {
        ...$question.matrixSlider.DefaultSettings,
        slider: {
          ...$question.matrixSlider.DefaultSettings.slider,
          minValue: 0,
          maxValue: 50,
          increment: 1,
          hideSlider: true,
        },
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`For how many years have you been in active clinical practice (post-residency)?`),
        ]),
      ]),
    });

    const logic: SurveyLogic.Item[] = [{
      action: { type: SurveyActionType.Disqualification },
      id: null,
      identifier: cuid(),
      conditions: [
        $template.generateLogicItemQuestionCondition({
          data: {
            question: { identifier: questionIdentifier },
            type: SurveyQuestionConditionType.SliderValue,
            value: {
              comparate: 1,
              operator: '<',
              row: { identifier: row.base.identifier },
            },
          },
        }),
        $template.generateLogicItemQuestionCondition({
          data: {
            question: { identifier: questionIdentifier },
            type: SurveyQuestionConditionType.SliderValue,
            value: {
              comparate: 33,
              operator: '>',
              row: { identifier: row.base.identifier },
            },
          },
        }),
      ],
      metadata: {
        canDelete: true,
        canEdit: true,
      },
      rule: {
        expression: null,
        type: SurveyLogicRule.AnyOfTheAbove,
      },
    }];

    const item = $template.generateQuestionItem(question);

    return { logic, item, question };
  }());

  const ProfessionalTime = (function() {

    const questionIdentifier = cuid();
    const section = sectionsMap[MessageTesting.Section.Screener];

    const rows = [
      'Providing direct patient care',
      'Administrative functions',
      'Other activities',
    ].map((value, i) => $template.generateNumberInputTableRow({
      metadata: {
        canDelete: false,
        canModifyValue: false,
      },
      ordinal: i + 1,
      value,
    }));

    const question = $template.generateSlidersQuestion({
      identifier: questionIdentifier,
      metadata: {
        anchor: false,
        canModifyValue: true,
        canDelete: true,
        template: {
          key: MessageTesting.Question.ProfessionalActivities,
        },
      },
      matrixRows: rows,
      section: {
        identifier: section.identifier,
      },
      settings: {
        ...$question.matrixSlider.DefaultSettings,
        numberSettings: {
          type: SurveyQuestionNumberType.Unit,
          unitType: '%',
        },
        slider: {
          ...$question.matrixSlider.DefaultSettings.slider,
          hideSlider: true,
          minValue: 0,
          maxValue: 100,
          increment: 1,
          label: $template.generateRichTextValue([
            $template.generateTextNode('% spent'),
          ]),
          total: {
            operator: '=',
            comparate: 100,
          },
        },
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`What percent of your professional time do you spend performing each of the following activities?`),
        ]),
      ]),
    });

    const item = $template.generateQuestionItem(question);

    return { item, question };
  }());

  const PatientConditions = (function() {
    const questionIdentifier = cuid();
    const section = sectionsMap[MessageTesting.Section.Screener];

    const primaryRow = $template.generateMTMedicalConditionRow({
      ordinal: 1,
      item: data.medicalConditions.primary,
    });

    const otherRows = data.medicalConditions.other.map((m, i) => $template.generateMTMedicalConditionRow({
      ordinal: i + 2,
      item: m,
    }));

    const question = $template.generateSlidersQuestion({
      identifier: questionIdentifier,
      matrixRows: [primaryRow, ...otherRows],
      metadata: {
        anchor: false,
        canModifyValue: true,
        canDelete: true,
        template: {
          key: MessageTesting.Question.PatientConditions,
        },
      },
      section: {
        identifier: section.identifier,
      },
      settings: {
        ...$question.matrixSlider.DefaultSettings,
        slider: {
          ...$question.matrixSlider.DefaultSettings.slider,
          minValue: 0,
          maxValue: 1000,
          increment: 1,
          hideSlider: true,
        },
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`How many unique patients do you treat in 3 years with the following conditions?`),
        ]),
      ]),
    });

    const logic: SurveyLogic.Item[] = [{
      action: { type: SurveyActionType.Disqualification },
      id: null,
      identifier: cuid(),
      conditions: [
        $template.generateLogicItemQuestionCondition({
          data: {
            question: { identifier: questionIdentifier },
            type: SurveyQuestionConditionType.SliderValue,
            value: {
              comparate: 3,
              operator: '<',
              row: { identifier: primaryRow.base.identifier },
            },
          },
        }),
      ],
      metadata: {
        canDelete: true,
        canEdit: true,
      },
      rule: {
        expression: null,
        type: SurveyLogicRule.AnyOfTheAbove,
      },
    }];

    const item = $template.generateQuestionItem(question);

    return { logic, item, question };
  }());

  const Willingness = (function() {
    const questionIdentifier = cuid();
    const section = sectionsMap[MessageTesting.Section.Screener];

    const options = (new Array(9)).fill(0).map((m, i) => {
      const ordinal = i + 1;
      const value = $template.generateRichTextNode([
        ordinal === 1 && $template.generateParagraphNode([
          $template.generateBoldTextNode('Not at all willing'),
        ]),
        ordinal === 9 && $template.generateParagraphNode([
          $template.generateBoldTextNode('Completely willing'),
        ]),
        $template.generateParagraphNode([
          $template.generateTextNode(ordinal.toString()),
        ]),
      ].filter(Boolean));

      return $template.generateOptionRichText({
        metadata: {
          canDelete: false,
          canModifyValue: false,
          template: {},
        },
        ordinal,
        value,
      });
    });

    const rows = [$template.generateMatrixGridRow({
      metadata: {
        canDelete: false,
        canModifyValue: true,
        template: {},
      },
      ordinal: 1,
      value: 'Willingness',
    })];

    const question = $template.generateMatrixGridQuestion({
      identifier: questionIdentifier,
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: MessageTesting.Question.TherapyWillingness,
        },
      },
      matrixRows: rows,
      options,
      section: {
        identifier: section.identifier,
      },
      settings: {
        ...$question.matrixGrid.DefaultSettings,
        display: 'grid',
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`On a scale of 1-9, where 1="not at all willing" and 9="completely willing", how would you rate your `),
          $template.generateBoldTextNode(`willingness to refer systemic therapy for your patients with `),
          generatePrimaryConditionNode([{ type: 'bold' }]),
          $template.generateBoldTextNode(` when no longer amenable to surgery?`),
        ]),
      ]),
    });

    const item = $template.generateQuestionItem(question);

    return { item, question };
  }());

  const Employment = (function() {

    const questionIdentifier = cuid();
    const section = sectionsMap[MessageTesting.Section.Screener];

    const mainOptions = [
      'Pharmaceutical manufacturer or contract research organization (CRO)',
      'Medical equipment manufacturer',
      'Market research or advertising firm',
      'FDA or EU equivalent',
      'Government Agency',
    ].map((value, i) => $template.generateMultiselectOption({
      metadata: {
        canDelete: true,
        canModifyValue: true,
        isAnchored: false,
        isOpenEnded: false,
        template: {},
      },
      ordinal: i + 1,
      value,
    }));

    const noneOfTheAboveOption = $template.generateMultiselectOption({
      metadata: {
        canDelete: true,
        canModifyValue: true,
        isAnchored: true,
        isOpenEnded: false,
        isExclusive: true,
        template: {},
      },
      ordinal: mainOptions.length + 1,
      value: 'None of the above',
    });

    const question = $template.generateMultiselectQuestion({
      identifier: questionIdentifier,
      metadata: {
        anchor: false,
        canModifyValue: true,
        canDelete: true,
        template: {
          key: MessageTesting.Question.EmployerCheck,
        },
      },
      options: [...mainOptions, noneOfTheAboveOption],
      section: {
        identifier: section.identifier,
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`Are you currently employed by any of the following (excluding research honoraria, consultancy, speaking engagements, and advisory board participation)?`),
        ]),
      ]),
    });

    const item = $template.generateQuestionItem(question);

    const logic: SurveyLogic.Item[] = [{
      action: { type: SurveyActionType.Disqualification },
      id: null,
      identifier: cuid(),
      conditions: mainOptions.map(option => $template.generateLogicItemQuestionCondition({
        data: {
          question: { identifier: questionIdentifier },
          type: SurveyQuestionConditionType.OptionSelected,
          value: {
            option: { identifier: option.base.identifier },
          },
        },
      })),
      metadata: {
        canDelete: true,
        canEdit: true,
      },
      rule: {
        expression: null,
        type: SurveyLogicRule.AnyOfTheAbove,
      },
    }];

    return { logic, item, question };
  }());

  const MarketResearch = (function() {
    const questionIdentifier = cuid();
    const section = sectionsMap[MessageTesting.Section.Screener];

    const yesOption = $template.generateMultipleChoiceOption({
      metadata: {
        canDelete: false,
        canModifyValue: false,
        isAnchored: false,
        isOpenEnded: false,
      },
      ordinal: 1,
      value: 'Yes',
    });

    const noOption = $template.generateMultipleChoiceOption({
      metadata: {
        canDelete: false,
        canModifyValue: false,
        isAnchored: false,
        isOpenEnded: false,
      },
      ordinal: 2,
      value: 'No',
    });

    const question = $template.generateMultipleChoiceQuestion({
      identifier: questionIdentifier,
      metadata: {
        anchor: false,
        canModifyValue: true,
        canDelete: true,
        template: {
          key: MessageTesting.Question.MarketResearchCheck,
        },
      },
      options: [yesOption, noOption],
      section: {
        identifier: section.identifier,
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`Have you participated in any market research studies on `),
          generatePrimaryConditionNode(),
          $template.generateTextNode(` in the past 30 days?`),
        ]),
      ]),
    });

    const item = $template.generateQuestionItem(question);

    return { item, question };
  }());

  const DemoMessage = (function() {

    const section = sectionsMap[MessageTesting.Section.Demographics];

    const message = $template.generateMessage({
      metadata: {
        anchor: false,
        canDelete: true,
        canEdit: true,
        template: {
          key: MessageTesting.Message.DemoIntro,
        },
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`Doctor, thank you very much for taking the time to complete this survey. The following final section aims to gather some standard demographics / clinigraphics for our research.`),
        ]),
      ]),
    });

    const item = $template.generateMessageItem({
      ...message,
      section: {
        identifier: section.identifier,
      },
    });

    return { item, message };
  }());

  const Attitudinals = (function() {
    const questionIdentifier = cuid();
    const section = sectionsMap[MessageTesting.Section.Demographics];

    const options = (new Array(9)).fill(0).map((m, i) => {
      const ordinal = i + 1;
      const value = $template.generateRichTextNode([
        ordinal === 1 && $template.generateParagraphNode([
          $template.generateBoldTextNode('Strongly Disagree'),
        ]),
        ordinal === 9 && $template.generateParagraphNode([
          $template.generateBoldTextNode('Strongly Agree'),
        ]),
        $template.generateParagraphNode([
          $template.generateTextNode(ordinal.toString()),
        ]),
      ].filter(Boolean));

      return $template.generateOptionRichText({
        metadata: {
          canDelete: false,
          canModifyValue: false,
          template: {},
        },
        ordinal,
        value,
      });
    });

    const rows = data.attitudinals.map((m, i) => $template.generateAttitudinalRow({
      ordinal: i + 1,
      item: m,
    }));

    const question = $template.generateMatrixGridQuestion({
      identifier: questionIdentifier,
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: true,
        template: {
          key: MessageTesting.Question.Attitudinals,
        },
      },
      matrixRows: rows,
      options,
      section: {
        identifier: section.identifier,
      },
      settings: {
        ...$question.matrixGrid.DefaultSettings,
        display: 'grid',
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`Doctor, how much do you agree or disagree with each of the following statements related to your approach to `),
          generatePrimaryConditionNode(),
          $template.generateTextNode(` treatment?`),
        ]),
        $template.generateParagraphNode(),
        $template.generateParagraphNode([
          $template.generateTextNode(`Please review the statements and indicate your level of agreement on a 9-point scale, where 1="Strongly disagree" and 9="Strongly agree".`),
        ]),
      ]),
    });

    const item = $template.generateQuestionItem(question);

    return { item, question };
  }());

  const PracticePhysicians = (function() {
    const questionIdentifier = cuid();
    const section = sectionsMap[MessageTesting.Section.Demographics];

    const question = $template.generateSlidersQuestion({
      identifier: questionIdentifier,
      metadata: {
        anchor: false,
        canModifyValue: true,
        canDelete: true,
        template: {
          key: MessageTesting.Question.PracticePhysicians,
        },
      },
      matrixRows: [$template.generateSlidersRow({
        metadata: {
          canDelete: false,
          canModifyValue: false,
        },
        ordinal: 1,
        value: `Physicians`,
      })],
      section: {
        identifier: section.identifier,
      },
      settings: {
        ...$question.matrixSlider.DefaultSettings,
        slider: {
          ...$question.matrixSlider.DefaultSettings.slider,
          minValue: 0,
          maxValue: 999,
          increment: 1,
          hideSlider: true,
        },
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`How many other physicians practice at your primary practice setting?`),
        ]),
      ]),
    });

    const item = $template.generateQuestionItem(question);

    return { item, question };
  }());

  const PracticeSettings = (function() {

    const questionIdentifier = cuid();
    const section = sectionsMap[MessageTesting.Section.Demographics];

    const options = ['Urban', 'Suburban', 'Rural'].map((value, i) => $template.generateMultipleChoiceOption({
      metadata: {
        canDelete: true,
        canModifyValue: true,
        isAnchored: false,
        isOpenEnded: false,
        template: {},
      },
      ordinal: i + 1,
      value,
    }));

    const question = $template.generateMultipleChoiceQuestion({
      identifier: questionIdentifier,
      metadata: {
        anchor: false,
        canModifyValue: true,
        canDelete: true,
        template: {
          key: MessageTesting.Question.PracticeSetting,
        },
      },
      options,
      section: {
        identifier: section.identifier,
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`Would you describe your primary practice setting as urban, suburban, or rural?`),
        ]),
      ]),
    });

    const item = $template.generateQuestionItem(question);

    return { item, question };
  }());

  const PracticeEmployees = (function() {

    const questionIdentifier = cuid();
    const section = sectionsMap[MessageTesting.Section.Demographics];

    const rows = [
      `Nurse practitioners`,
      `PA (Physician Assistants)`,
      `Nurse Navigator`,
      `Medical assistant`,
    ].map((value, i) => $template.generateSlidersRow({
      metadata: {
        canDelete: true,
        canModifyValue: true,
        isAnchored: false,
        isOpenEnded: false,
      },
      ordinal: i + 1,
      value,
    }));

    const question = $template.generateSlidersQuestion({
      identifier: questionIdentifier,
      metadata: {
        anchor: false,
        canModifyValue: true,
        canDelete: true,
        template: {
          key: MessageTesting.Question.PracticeEmployees,
        },
      },
      matrixRows: rows,
      section: {
        identifier: section.identifier,
      },
      settings: {
        ...$question.matrixSlider.DefaultSettings,
        slider: {
          ...$question.matrixSlider.DefaultSettings.slider,
          minValue: 0,
          maxValue: 100,
          increment: 1,
          hideSlider: true,
        },
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`How many of the following does your practice have?`),
        ]),
      ]),
    });

    const item = $template.generateQuestionItem(question);

    return { item, question };
  }());

  const AdminStaff = (function() {

    const questionIdentifier = cuid();
    const section = sectionsMap[MessageTesting.Section.Demographics];

    const options = [
      $template.generateMultipleChoiceOption({
        metadata: {
          canDelete: true,
          canModifyValue: true,
          isAnchored: false,
          isOpenEnded: true,
        },
        ordinal: 1,
        value: 'Yes (Enter number of dedicated personnel)',
      }),
      $template.generateMultipleChoiceOption({
        metadata: {
          canDelete: true,
          canModifyValue: true,
          isAnchored: false,
          isOpenEnded: false,
        },
        ordinal: 2,
        value: 'No',
      }),
    ];

    const question = $template.generateMultipleChoiceQuestion({
      identifier: questionIdentifier,
      metadata: {
        anchor: false,
        canModifyValue: true,
        canDelete: true,
        template: {
          key: MessageTesting.Question.AdministrativeStaff,
        },
      },
      options,
      section: {
        identifier: section.identifier,
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`Do you have staff that assists with reimbursement and associated administrative issues?`),
        ]),
      ]),
    });

    const item = $template.generateQuestionItem(question);

    return { item, question };
  }());

  const Gender = (function() {

    const questionIdentifier = cuid();
    const section = sectionsMap[MessageTesting.Section.Demographics];

    const options = ['Male', 'Female'].map((value, i) => $template.generateMultipleChoiceOption({
      metadata: {
        canDelete: true,
        canModifyValue: true,
        isAnchored: false,
        isOpenEnded: false,
      },
      ordinal: i + 1,
      value,
    }));

    const question = $template.generateMultipleChoiceQuestion({
      identifier: questionIdentifier,
      metadata: {
        anchor: false,
        canModifyValue: true,
        canDelete: true,
        template: {
          key: MessageTesting.Question.Gender,
        },
      },
      options,
      section: {
        identifier: section.identifier,
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`Please select your gender`),
        ]),
      ]),
    });

    const item = $template.generateQuestionItem(question);

    return { item, question };
  }());

  const Age = (function() {

    const questionIdentifier = cuid();
    const section = sectionsMap[MessageTesting.Section.Demographics];

    const options = [
      'Under 30',
      '30 to 39',
      '40 to 49',
      '50 to 59',
      '60+',
    ].map((value, i) => $template.generateMultipleChoiceOption({
      metadata: {
        canDelete: true,
        canModifyValue: true,
        isAnchored: false,
        isOpenEnded: false,
      },
      ordinal: i + 1,
      value,
    }));

    const question = $template.generateMultipleChoiceQuestion({
      identifier: questionIdentifier,
      metadata: {
        anchor: false,
        canModifyValue: true,
        canDelete: true,
        template: {
          key: MessageTesting.Question.Age,
        },
      },
      options,
      section: {
        identifier: section.identifier,
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`Into which of the following categories does your age fall?`),
        ]),
      ]),
    });

    const item = $template.generateQuestionItem(question);

    return { item, question };
  }());

  const TPPMessage = (function() {
    const section = sectionsMap[MessageTesting.Section.MessageAnalysis];

    const message = $template.generateMessage({
      metadata: {
        anchor: false,
        canDelete: true,
        canEdit: true,
        template: {
          key: MessageTesting.Message.TPPIntro,
        },
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`Please review the following product, and then answer the following questions. If at any time you would like to review the product again, click on the "View Product" on the screen.`),
        ]),
        $template.generateParagraphNode([]),
        ...data.tpp.content,
      ]),
    });

    const item = $template.generateMessageItem({
      ...message,
      section: {
        identifier: section.identifier,
      },
    });

    return { item, message };
  }());

  const MaxDiff = (function() {

    const questionIdentifier = cuid();
    const section = sectionsMap[MessageTesting.Section.MaxDiffExercise];

    const options = data.categories.reduce<SurveyQuestionOption[]>((acc, category) => {
      const items = category.messages.map((m, i) => $template.generateOption({
        metadata: {
          canDelete: false,
          canModifyValue: false,
          template: {
            linkedEntity: {
              id: m.id,
              type: SurveyTemplateEntityType.CategoryMessage,
            },
          },
        },
        ordinal: acc.length + i + 1,
        value: m.value,
      }));

      return acc.concat(items);
    }, []);

    const question = $template.generateMaxDiffQuestion({
      identifier: questionIdentifier,
      metadata: {
        anchor: false,
        canModifyValue: true,
        canDelete: false,
        template: {
          key: MessageTesting.Question.MaxDiff,
        },
      },
      options,
      section: {
        identifier: section.identifier,
      },
      settings: {
        ...$question.maxDiff.DefaultSettings,
        optionsPerSet: data.maxDiff.attributesPerSet,
        sets: data.maxDiff.sets,
        label: {
          left: $template.generateRichTextNode([
            $template.generateParagraphNode([
              $template.generateTextNode(`LEAST ${toTitleCase(data.maxDiff.dimension)} CLAIM`, [{ type: 'bold' }, { type: 'underline' }]),
              $template.generateTextNode(` to refer for systemic therapy for `),
              generatePrimaryConditionNode(),
            ]),
          ]),
          right: $template.generateRichTextNode([
            $template.generateParagraphNode([
              $template.generateTextNode(`MOST ${toTitleCase(data.maxDiff.dimension)} CLAIM`, [{ type: 'bold' }, { type: 'underline' }]),
              $template.generateTextNode(` to refer for systemic therapy for `),
              generatePrimaryConditionNode(),
            ]),
          ]),
        },
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`Please select the messages which are `),
          $template.generateTextNode(`LEAST`, [{ type: 'bold' }, { type: 'underline' }]),
          $template.generateTextNode(` and `),
          $template.generateTextNode(`MOST`, [{ type: 'bold' }, { type: 'underline' }]),
          $template.generateTextNode(` ${data.maxDiff.dimension.toLowerCase()} to refer for systemic therapy for `),
          generatePrimaryConditionNode(),
          $template.generateTextNode(` patients that are no longer amenable to surgery.`),
        ]),
        $template.generateParagraphNode(),
        $template.generateParagraphNode([
          $template.generateBoldTextNode(`Please only select one in the most ${data.maxDiff.dimension.toLowerCase()} column and one in the least ${data.maxDiff.dimension.toLowerCase()} column in terms of your selection of a treatment for `),
          generatePrimaryConditionNode([{ type: 'bold' }]),
          $template.generateBoldTextNode(`.`),
        ]),
        $template.generateParagraphNode([]),
        $template.generateParagraphNode([$template.generateViewTPP_RTNode()]),
      ]),
    });

    const item = $template.generateQuestionItem(question);

    return { item, question };
  }());

  const MaxDiffMM = (function() {
    const section = sectionsMap[MessageTesting.Section.MaxDiffExercise];

    const question = $template.generateLongTextQuestion({
      metadata: {
        anchor: false,
        canModifyValue: true,
        canDelete: false,
        template: {
          key: MessageTesting.Question.MaxDiffMost,
        },
      },
      section: {
        identifier: section.identifier,
      },
      settings: {
        ...$question.longText.DefaultSettings,
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generatePipedQuestionResponseNode({
            pipedValue: {
              type: SurveyPipedValueType.FirstRankedOption,
            },
            questionIdentifier: MaxDiff.question.base.identifier,
          }),
        ]),
        $template.generateParagraphNode(),
        $template.generateParagraphNode([
          $template.generateTextNode(`You rated the above message as the most ${data.maxDiff.dimension.toLowerCase()} to refer systemic therapy for `),
          generatePrimaryConditionNode(),
          $template.generateTextNode(` patients that are no longer amenable to surgery. Please explain your rationale below:`),
        ]),
        $template.generateParagraphNode([]),
        $template.generateParagraphNode([$template.generateViewTPP_RTNode()]),
      ]),
    });

    const item = $template.generateQuestionItem(question);

    return { item, question };
  }());

  const MaxDiffSM = (function() {
    const section = sectionsMap[MessageTesting.Section.MaxDiffExercise];

    const question = $template.generateLongTextQuestion({
      metadata: {
        anchor: false,
        canModifyValue: true,
        canDelete: false,
        template: {
          key: MessageTesting.Question.MaxDiffSecondMost,
        },
      },
      section: {
        identifier: section.identifier,
      },
      settings: {
        ...$question.longText.DefaultSettings,
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generatePipedQuestionResponseNode({
            pipedValue: {
              type: SurveyPipedValueType.SecondRankedOption,
            },
            questionIdentifier: MaxDiff.question.base.identifier,
          }),
        ]),
        $template.generateParagraphNode(),
        $template.generateParagraphNode([
          $template.generateTextNode(`You rated the above message as the second most ${data.maxDiff.dimension.toLowerCase()} to refer systemic therapy for `),
          generatePrimaryConditionNode(),
          $template.generateTextNode(` patients that are no longer amenable to surgery. Please explain your rationale below:`),
        ]),
        $template.generateParagraphNode([]),
        $template.generateParagraphNode([$template.generateViewTPP_RTNode()]),
      ]),
    });

    const item = $template.generateQuestionItem(question);

    return { item, question };
  }());

  const MaxDiffLM = (function() {
    const section = sectionsMap[MessageTesting.Section.MaxDiffExercise];

    const question = $template.generateLongTextQuestion({
      metadata: {
        anchor: false,
        canModifyValue: true,
        canDelete: false,
        template: {
          key: MessageTesting.Question.MaxDiffLeast,
        },
      },
      section: {
        identifier: section.identifier,
      },
      settings: {
        ...$question.longText.DefaultSettings,
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generatePipedQuestionResponseNode({
            pipedValue: {
              type: SurveyPipedValueType.LastRankedOption,
            },
            questionIdentifier: MaxDiff.question.base.identifier,
          }),
        ]),
        $template.generateParagraphNode(),
        $template.generateParagraphNode([
          $template.generateTextNode(`You rated the above message as the least ${data.maxDiff.dimension.toLowerCase()} to refer systemic therapy for `),
          generatePrimaryConditionNode(),
          $template.generateTextNode(` patients that are no longer amenable to surgery. Please explain your rationale below:`),
        ]),
        $template.generateParagraphNode([]),
        $template.generateParagraphNode([$template.generateViewTPP_RTNode()]),
      ]),
    });

    const item = $template.generateQuestionItem(question);

    return { item, question };
  }());

  const MaxDiffSLM = (function() {
    const section = sectionsMap[MessageTesting.Section.MaxDiffExercise];

    const question = $template.generateLongTextQuestion({
      metadata: {
        anchor: false,
        canModifyValue: true,
        canDelete: false,
        template: {
          key: MessageTesting.Question.MaxDiffSecondLeast,
        },
      },
      section: {
        identifier: section.identifier,
      },
      settings: {
        ...$question.longText.DefaultSettings,
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generatePipedQuestionResponseNode({
            pipedValue: {
              type: SurveyPipedValueType.SecondLastRankedOption,
            },
            questionIdentifier: MaxDiff.question.base.identifier,
          }),
        ]),
        $template.generateParagraphNode(),
        $template.generateParagraphNode([
          $template.generateTextNode(`You rated the above message as the second least ${data.maxDiff.dimension.toLowerCase()} to refer systemic therapy for `),
          generatePrimaryConditionNode(),
          $template.generateTextNode(` patients that are no longer amenable to surgery. Please explain your rationale below:`),
        ]),
        $template.generateParagraphNode([]),
        $template.generateParagraphNode([$template.generateViewTPP_RTNode()]),
      ]),
    });

    const item = $template.generateQuestionItem(question);

    return { item, question };
  }());

  const MessageDimensionOne = (function() {

    const identifier = cuid();
    const section = sectionsMap[MessageTesting.Section.MessageAnalysis];

    const messageData = data.messageAnalysis[0];

    const options = (new Array(9)).fill(0).map((m, i) => {
      const ordinal = i + 1;
      const value = $template.generateRichTextNode([
        ordinal === 1 && $template.generateParagraphNode([
          $template.generateBoldTextNode(messageData.leftAxis),
        ]),
        ordinal === 9 && $template.generateParagraphNode([
          $template.generateBoldTextNode(messageData.rightAxis),
        ]),
        $template.generateParagraphNode([
          $template.generateTextNode(ordinal.toString()),
        ]),
      ].filter(Boolean));

      return $template.generateOptionRichText({
        metadata: {
          canDelete: false,
          canModifyValue: false,
          template: {},
        },
        ordinal,
        value,
      });
    });

    const rows = data.categories.reduce<MatrixGridQuestion.Row[]>((acc, category) => {
      const items = category.messages.map((m, i) => $template.generateMatrixGridRow({
        metadata: {
          canDelete: false,
          canModifyValue: false,
          template: {
            linkedEntity: {
              id: m.id,
              type: SurveyTemplateEntityType.CategoryMessage,
            },
          },
        },
        ordinal: acc.length + i + 1,
        value: m.value,
      }));

      return acc.concat(items);
    }, []);

    const question = $template.generateMatrixGridQuestion({
      identifier,
      metadata: {
        anchor: false,
        canModifyValue: true,
        canDelete: true,
        template: {
          key: MessageTesting.Question.MessageDimensionOne,
        },
      },
      matrixRows: rows,
      options,
      section: {
        identifier: section.identifier,
      },
      settings: {
        ...$question.matrixGrid.DefaultSettings,
        display: 'grid',
        randomizeRows: true,
        // scaleDisplayFrequency: 5,
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`Please rate each of the following messages on `),
          $template.generateBoldTextNode(`its ${messageData.dimension.toLocaleLowerCase()} `),
          generateProductNode(),
          $template.generateTextNode(` for the treatment of `),
          generatePrimaryConditionNode(),
          $template.generateTextNode(`. Please use a 1-9 scale where 1="${messageData.leftAxis}", and 9="${messageData.rightAxis}".`),
        ]),
        $template.generateParagraphNode([]),
        $template.generateParagraphNode([$template.generateViewTPP_RTNode()]),
      ]),
    });

    const item = $template.generateQuestionItem(question);

    return { item, question };
  }());

  const MessageDimensionTwo = (function() {

    const identifier = cuid();
    const section = sectionsMap[MessageTesting.Section.MessageAnalysis];

    const messageData = data.messageAnalysis[1];

    const options = (new Array(9)).fill(0).map((m, i) => {
      const ordinal = i + 1;
      const value = $template.generateRichTextNode([
        ordinal === 1 && $template.generateParagraphNode([
          $template.generateBoldTextNode(messageData.leftAxis),
        ]),
        ordinal === 9 && $template.generateParagraphNode([
          $template.generateBoldTextNode(messageData.rightAxis),
        ]),
        $template.generateParagraphNode([
          $template.generateTextNode(ordinal.toString()),
        ]),
      ].filter(Boolean));

      return $template.generateOptionRichText({
        metadata: {
          canDelete: false,
          canModifyValue: false,
          template: {},
        },
        ordinal,
        value,
      });
    });

    const rows = MessageDimensionOne.question.matrixRows.map(row => $template.generateMatrixGridRow({
      metadata: {
        canDelete: false,
        canModifyValue: false,
        linked: {
          question: { identifier: MessageDimensionOne.question.base.identifier },
          row: { identifier: row.base.identifier },
        },
        template: row.metadata.template,
      },
      value: (row.value.content[0].content[0] as Remirror.TextNode).text,
      ordinal: row.ordinal,
    }));

    const question = $template.generateMatrixGridQuestion({
      identifier,
      metadata: {
        anchor: false,
        canModifyValue: true,
        canDelete: true,
        linked: {
          rows: {
            question: { identifier: MessageDimensionOne.question.base.identifier },
            source: 'rows',
          },
        },
        template: {
          key: MessageTesting.Question.MessageDimensionTwo,
        },
      },
      matrixRows: rows,
      options,
      section: {
        identifier: section.identifier,
      },
      settings: {
        ...$question.matrixGrid.DefaultSettings,
        display: 'grid',
        orderRowsBasedOnSource: true,
        // scaleDisplayFrequency: 5,
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`Please rate each of the following messages on `),
          $template.generateBoldTextNode(`its ${messageData.dimension.toLocaleLowerCase()} `),
          generateProductNode(),
          $template.generateTextNode(` for the treatment of `),
          generatePrimaryConditionNode(),
          $template.generateTextNode(`. Please use a 1-9 scale where 1="${messageData.leftAxis}", and 9="${messageData.rightAxis}".`),
        ]),
        $template.generateParagraphNode([]),
        $template.generateParagraphNode([$template.generateViewTPP_RTNode()]),
      ]),
    });

    const item = $template.generateQuestionItem(question);

    return { item, question };
  }());

  const Ordering = (function() {

    const identifier = cuid();
    const section = sectionsMap[MessageTesting.Section.OrderingExercise];

    const options = MessageDimensionOne.question.matrixRows.map(row => $template.generateRankingOption({
      metadata: {
        canDelete: false,
        canModifyValue: false,
        linked: {
          question: { identifier: MessageDimensionOne.question.base.identifier },
          row: { identifier: row.base.identifier },
        },
        template: row.metadata.template,
      },
      value: (row.value.content[0].content[0] as Remirror.TextNode).text,
      ordinal: row.ordinal,
    }));

    const question = $template.generateRankingQuestion({
      identifier,
      metadata: {
        anchor: false,
        canModifyValue: true,
        canDelete: true,
        linked: {
          options: {
            question: { identifier: MessageDimensionOne.question.base.identifier },
            source: 'rows',
          },
        },
        template: {
          key: MessageTesting.Question.OrderingExercise,
        },
      },
      options,
      section: {
        identifier: section.identifier,
      },
      settings: {
        ...$question.ranking.DefaultSettings,
        minRank: 2,
        displayFormat: 'lists',
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`Doctor, please rank as many or as few (at least 2 messages) of the `),
          generateProductNode(),
          $template.generateTextNode(` messages such that they are organized to `),
          $template.generateBoldTextNode(`best motivate you to refer systemic therapy for patients with `),
          generatePrimaryConditionNode([{ type: 'bold' }]),
          $template.generateBoldTextNode(` that are no longer amenable to surgery.`),
        ]),
        $template.generateParagraphNode([]),
        $template.generateParagraphNode([$template.generateViewTPP_RTNode()]),
      ]),
    });

    const item = $template.generateQuestionItem(question);

    return { item, question };
  }());

  const items = [
    PrimarySpecialty.item,
    Qualification.item,
    BoardCertified.item,
    ClinicalPractice.item,
    ProfessionalTime.item,
    PatientConditions.item,
    Willingness.item,
    Employment.item,
    MarketResearch.item,
    //
    TPPMessage.item,
    MessageDimensionOne.item,
    MessageDimensionTwo.item,
    //
    MaxDiff.item,
    MaxDiffMM.item,
    MaxDiffSM.item,
    MaxDiffLM.item,
    MaxDiffSLM.item,
    //
    Ordering.item,
    //
    DemoMessage.item,
    Attitudinals.item,
    PracticePhysicians.item,
    PracticeSettings.item,
    PracticeEmployees.item,
    AdminStaff.item,
    Gender.item,
    Age.item,
  ].filter(Boolean)
    .map((m, i) => ({
      ...m,
      ordinal: i + 1,
    }));

  return {
    alternateImageExercises: [],
    classifications: [],
    createdOn: null,
    id: null,
    items,
    language: $template.generateLanguage(),
    logic: [
      ...PrimarySpecialty.logic,
      ...BoardCertified.logic,
      ...ClinicalPractice.logic,
      ...Employment.logic,
      ...PatientConditions.logic,
    ].filter(Boolean),
    messages: [
      TPPMessage.message,
      DemoMessage.message,
    ].filter(Boolean),
    questions: [
      PrimarySpecialty.question,
      Qualification.question,
      BoardCertified.question,
      ClinicalPractice.question,
      ProfessionalTime.question,
      PatientConditions.question,
      Willingness.question,
      Employment.question,
      MarketResearch.question,
      //
      MessageDimensionOne.question,
      MessageDimensionTwo.question,
      //
      MaxDiff.question,
      MaxDiffMM.question,
      MaxDiffSM.question,
      MaxDiffLM.question,
      MaxDiffSLM.question,
      //
      Ordering.question,
      //
      Attitudinals.question,
      PracticePhysicians.question,
      PracticeSettings.question,
      PracticeEmployees.question,
      AdminStaff.question,
      Gender.question,
      Age.question,
    ].filter(Boolean),
    quotas: [],
    sections,
    surveyId: null,
    surveyDetails: null,
    tagging: [],
    template: {
      data,
      type: SurveyTemplateType.MessageTesting,
    },
  };
}

type SectionsMap = {
  [key: string]: SurveySection;
};