import Skeleton from '@mui/material/Skeleton';
import { cx } from '@utils';
import styles from './style/Skeleton.css';

type Props = {
  className?: string;
  classes?: {
    root?: string;
    item?: string;
  };
};

export const Row = (props: Props) => {
  return (
    <div className={cx(styles.root, props.className, props.classes.root)}>
      {items.map(x =>
        <RowItem
          className={props.classes.item}
          key={x} />)}
    </div>
  );
};

const defaultProps = {
  classes: {},
};

Row.defaultProps = defaultProps;

const items = new Array(4)
  .fill(0)
  .map((_, i) => `row-${i + 1}`);

type RowItemProps = {
  className?: string;
};

export const RowItem = (props: RowItemProps) => {
  return (
    <div className={cx(styles.contain, props.className)}>
      <div className={styles.item}>
        <div className={styles.box}>
          <div className={styles.content}>
            <Skeleton
              height="100%"
              variant="rectangular" />
          </div>
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles.circle}>
          <Skeleton
            variant="circular"
            height={42}
            width={42} />
        </div>
        <div className={styles.text}>
          <div className={styles.title}>
            <Skeleton
              height={30}
              variant="text" />
            <Skeleton
              height={30}
              width="90%"
              variant="text" />
          </div>
          <Skeleton
            height={22}
            width="50%" />
        </div>
      </div>
    </div>
  );
};

Row.displayName = 'Skeleton.Row';
RowItem.displayName = 'Skeleton.RowItem';