import { useCallback, useEffect } from 'react';
import { useCanSubmitSurveyForm } from '@/containers/SurveyForm';
import { useGoToItem } from '@containers/SurveyForm/hooks';
import Toast from '@/components/Toast';
import { LeftBarActionContext, useLeftBarContext } from './context';
import { useSubmitItem, useSyncSurveyState, useParentWindowEvents } from './hooks';

export const LeftBarMainActionContainer = (props: ChildrenProps) => {
  const { callId, dispatch } = useLeftBarContext();
  const canSubmit = useCanSubmitSurveyForm();
  const submitItem = useSubmitItem({
    callId,
    onError: () => {
      Toast.error({
        title: 'Error',
        body: `There was an error navigating to the item. Does the item rely on an unanswered item?`,
      });
    },
  });

  const goToItem = useGoToItem({
    onError: () => {
      Toast.error({
        title: 'Error',
        body: `There was an error navigating to the item. Does the item rely on an unanswered item?`,
      });
    },
  });

  const onItemClick = useCallback((itemId: number) => {
    if (canSubmit) {
      submitItem.mutate({
        itemId,
      });
    } else {
      goToItem.mutate({
        itemId,
      });
    }
  }, [canSubmit, goToItem, submitItem]);

  useEffect(() => {
    dispatch({
      type: 'loading-status-updated',
      loading: submitItem.isLoading || goToItem.isLoading,
    });
  }, [submitItem.isLoading, goToItem.isLoading, dispatch]);

  useSyncSurveyState();

  return (
    <LeftBarActionContext.Provider value={{ onItemClick }}>
      <ParentEventsContainer>
        {props.children}
      </ParentEventsContainer>
    </LeftBarActionContext.Provider>
  );
};

const ParentEventsContainer = (props: ChildrenProps) => {
  useParentWindowEvents();

  return props.children;
};