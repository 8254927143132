// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-datepicker__header--time {
  display: none;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
  line-height: 30px;
  margin-bottom: 0;
  font-family: var(--font-reg);
  font-size: 16px;
}

.react-datepicker {
  border-radius: 4px;
  box-shadow: var(--shadow);
  border: none;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: var(--pri-02);
  font-weight: 400;
}

.react-datepicker__time-container {
  width: 100px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 100px;
}`, "",{"version":3,"sources":["webpack://./src/static/css/useable/datepicker.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;EAChB,4BAA4B;EAC5B,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,+BAA+B;EAC/B,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd","sourcesContent":[".react-datepicker__header--time {\n  display: none;\n}\n\n.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {\n  height: 30px;\n  padding: 5px 10px;\n  white-space: nowrap;\n  line-height: 30px;\n  margin-bottom: 0;\n  font-family: var(--font-reg);\n  font-size: 16px;\n}\n\n.react-datepicker {\n  border-radius: 4px;\n  box-shadow: var(--shadow);\n  border: none;\n}\n\n.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {\n  background-color: var(--pri-02);\n  font-weight: 400;\n}\n\n.react-datepicker__time-container {\n  width: 100px;\n}\n\n.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {\n  width: 100px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
