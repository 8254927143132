import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import BarChartOutlined from '@mui/icons-material/BarChartOutlined';
import { cx } from '@utils';
import type { SupportIconProps } from './interfaces';
import styles from './style.css';

export const SurveysIcon = forwardRef(({ active, className, ...props }: SupportIconProps, ref: ForwardedRef<HTMLDivElement>) => (
  <div
    ref={ref}
    className={cx(styles.icon, { [styles.active]: active }, className)}
    {...props}>
    <BarChartOutlined />
  </div>
));