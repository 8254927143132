import { List } from 'react-feather';
import { useContext } from 'react';
import { TableEmpty } from '@presentation/Table.Empty';
import { ProjectClipsFiltersContext } from '@/containers/Project.Clips';

export const ClipsTableEmpty = () => {
  const filters = useContext(ProjectClipsFiltersContext);

  const copy = filters.keyword ? searchCopy : tagsCopy;

  return (
    <TableEmpty
      Icon={() => <List size={120} />}
      message={copy.message}
      note={copy.note} />
  );
};

ClipsTableEmpty.displayName = 'Project.Clips.Table.Empty';

const tagsCopy = {
  message: `No clips available.`,
  note: `Tag and highlight transcripts to view highlights from the interview.`,
};
const searchCopy = {
  message: `No results found.`,
  note: `Try updating filters or searching for a different keyword.`,
};