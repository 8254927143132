import type { Chat } from '@/brand-insights/types';
import type { ChatState, SegmentationState } from './interfaces';

export function segmentationsReducer(state: SegmentationState.State, action: ChatState.Action): Chat.Segmentation.Segmentation[] {
  switch (action.type) {
    case 'segmentation/screen/started': {
      return Array.from(state.segmentations).concat(action.payload.segmentation);
    }
    case 'segmentation/cancel': {
      const others = state.segmentations.filter(s => s.identifier !== action.payload.segmentation.identifier);
      return Array.from(others).concat(action.payload.segmentation);
    }
    case 'segmentation/screen/tag-selection': {
      const others = state.segmentations.filter(s => s.identifier !== action.payload.segmentation.identifier);
      return Array.from(others).concat(action.payload.segmentation);
    }
    case 'segmentation/screen/updated': {
      return Array.from(state.segmentations.map(segmentation => {
        if (segmentation.identifier !== action.payload.identifier) return segmentation;
        const updated: Chat.Segmentation.Segmentation = {
          ...segmentation,
          screens: segmentation.screens.map(screen => {
            if (screen.item.identifier !== action.payload.screen.item.identifier) return screen;
            const updatedScreen: Chat.Segmentation.InstanceScreenItem = {
              ...screen,
              ...action.payload.screen,
            };
            return updatedScreen;
          }),
        };
        return updated;
      }));
    }
    case 'segmentation/screen/dimension-selection': {
      const others = state.segmentations.filter(s => s.identifier !== action.payload.segmentation.identifier);
      return Array.from(others).concat(action.payload.segmentation);
    }
    case 'segmentation/screen/confirmation': {
      const others = state.segmentations.filter(s => s.identifier !== action.payload.segmentation.identifier);
      return Array.from(others).concat(action.payload.segmentation);
    }
    case 'segmentation/result': {
      return Array.from(state.segmentations.map(segmentation => {
        if (segmentation.identifier !== action.payload.identifier) return segmentation;
        if (segmentation.status === 'cancelled') return segmentation;

        const updated: Chat.Segmentation.Segmentation = {
          ...segmentation,
          screens: segmentation.screens.map(screen => {
            if (screen.type === 'result') {
              const updatedScreen: Chat.Segmentation.InstanceScreenItem = {
                ...screen,
                item: {
                  ...screen.item,
                  results: screen.item.results.map(result => {
                    if (result.identifier !== action.payload.resultItemIdentifier) return result;
                    return {
                      ...result,
                      findings: result.findings.map(finding => {
                        if (finding.identifier !== action.payload.findingItemIdentifier) return finding;
                        return {
                          ...finding,
                          status: 'completed',
                          response: action.payload.query.response,
                          citations: action.payload.query.citations,
                        };
                      }),
                    };
                  }),
                },
              };
              return updatedScreen;
            }
            return screen;
          }),
        };
        return updated;
      }));
    }
    case 'segmentation/completed': {
      return Array.from(state.segmentations.map(segmentation => {
        if (segmentation.identifier !== action.payload.identifier) return segmentation;
        if (segmentation.status === 'cancelled') return segmentation;

        return {
          ...segmentation,
          status: 'completed',
        };
      }));
    }
    default: return state.segmentations;
  }
}