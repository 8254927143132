import { RequestCallContainer } from '@containers/RequestCall';
import type { HybridSurveyResponseUserId } from '@/types';
import { AnonymousCardContent } from './AnonymousCardContent';
import { CardTooltip } from './HtmlTooltip';

type Props = {
  classes?: {
    container?: string;
    tooltip?: string;
  };
  children: React.ReactNode;
  user: {
    id: HybridSurveyResponseUserId;
    name: string;
    creditRate?: number;
  };
  subtext: string;
};

export const AnonymousBaseballCard = (props: Props) => {
  return (
    <RequestCallContainer>
      <CardTooltip classes={props.classes} title={(<AnonymousCardContent {...props} />)}>
        {props.children}
      </CardTooltip>
    </RequestCallContainer>
  );
};