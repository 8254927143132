import { useCallback, useEffect, useMemo, useState } from 'react';
import { GrayOutlineButton } from '@/presentation/Buttons';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import type { AIEDropdownItem } from '@presentation/SurveyBuilder';
import { AIEDropdown, AIEGroupDropdown } from '@presentation/SurveyBuilder';
import { Button } from '@/components/Button';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import ModalHeader from '@/components/Modal/Header';
import { useModal } from '@/components/Modal/hooks';
import { Checkbox } from '@/components/Checkbox';
import styles from './style/ReuseAIEImages.Modal.css';

type Props = {
  onSubmit: (sourceIdentifier: string, groupIdentifier: string) => void;
  exercises: AIEDropdownItem[];
} & Pick<ModalProps,
  'open' |
  'onClose'>;

export const ReuseAIEImagesModal = ({ onSubmit, open, onClose, exercises }: Props) => {

  const [exerciseIdentifier, setExerciseIdentifier] = useState<string>(exercises[0]?.identifier);
  const [selectedGroupIdentifier, setSelectedGroupIdentifier] = useState<string>();
  const [useAll, setUseAll] = useState<boolean>(false);

  const [state] = useSurveyBuilderState();

  const selectedExercise = useMemo(() => {
    return {
      ...state.survey.alternateImageExercises.find(f => f.identifier === exerciseIdentifier),
      ordinal: state.survey.items.find(i => i.source.identifier === exerciseIdentifier).ordinal,
    };
  }, [
    exerciseIdentifier,
    state.survey.alternateImageExercises,
    state.survey.items,
  ]);

  const handleSubmit = useCallback(() => {
    onSubmit(exerciseIdentifier, useAll ? null : selectedGroupIdentifier);
    onClose();
  }, [
    onClose,
    onSubmit,
    exerciseIdentifier,
    selectedGroupIdentifier,
    useAll,
  ]);

  const groups = useMemo(() => {
    if (!selectedExercise) return [];

    return selectedExercise.groups.filter(g => g.entries.length > 1);
  }, [selectedExercise]);

  useEffect(() => {
    if (!useAll) {
      setSelectedGroupIdentifier(selectedExercise.groups[0]?.identifier);
    }
  }, [selectedExercise, useAll]);

  const previewEntries = useMemo(() => {
    if (!selectedExercise) return [];

    if (useAll) return selectedExercise.groups.flatMap(g => g.entries);

    return selectedExercise.groups.find(g => g.identifier === selectedGroupIdentifier)?.entries ?? [];
  }, [selectedExercise, selectedGroupIdentifier, useAll]);

  const canSubmit = !!exerciseIdentifier && !!selectedGroupIdentifier;

  return (
    <Modal
      open={open}
      onClose={onClose}>

      <ModalHeader text="Reuse Stimuli Display Values" />

      <label className={styles.row}>
        Use all images
        <Checkbox checked={useAll} onChange={e => setUseAll(e.target.checked)} />
      </label>

      <div className={styles.row}>
        <div>
          <AIEDropdown
            items={exercises}
            onSelect={setExerciseIdentifier}
            value={selectedExercise ? {
              identifier: selectedExercise.identifier,
              ordinal: selectedExercise.ordinal,
            } : null} />
        </div>
        <div>
          {selectedExercise && !useAll && (
            <AIEGroupDropdown
              items={groups}
              onSelect={setSelectedGroupIdentifier}
              value={groups.find(g => g.identifier === selectedGroupIdentifier)} />
          )}
        </div>
      </div>

      <div className={styles.images}>
        {previewEntries.map(entry => (
          <img
            key={entry.identifier}
            src={entry.imageUrl}
            className={styles.image} />
        ))}
      </div>

      <div className={styles.footer}>
        <GrayOutlineButton
          onClick={onClose}
          title="Cancel" />
        <Button.Primary
          className={styles.submit}
          disabled={!canSubmit}
          onClick={handleSubmit}
          title="Submit"
          variant="brick" />
      </div>

    </Modal>
  );
};

export const useReuseAIEImagesModal = () => useModal(ReuseAIEImagesModal);