import type { RankingQuestion } from '@/types/survey';
import type { RankingOrdinalDropdownItem } from './interfaces';
import RankingListDropArea from './Ranking.List.DropArea';
import { DraggableRankingListItem } from './Ranking.ListItem.Draggable';

export type Props = {
  dragDisabled?: boolean;
  getDropdownItems: (option: RankingQuestion.FormOption) => number[];
  items: RankingQuestion.FormOption[];
  onOrdinalSelect: (index: number) => (item: RankingOrdinalDropdownItem) => void;
  rankingDisabled: boolean;
};

export const RankingList = (props: Props) => {
  return (
    <RankingListDropArea>
      {
        props.items.map((option, index) => (
          <DraggableRankingListItem
            key={option.id}
            index={index}
            rankingDisabled={props.rankingDisabled}
            dragDisabled={props.dragDisabled}
            item={option}
            ordinalItems={props.getDropdownItems(option)}
            onOrdinalSelect={props.onOrdinalSelect(index)} />
        ))
      }
    </RankingListDropArea>
  );
};

export default RankingList;