export enum PostContentType {
  Text  = 'text',
  Video = 'video',
}

export type ContentPublisherState = {
  active: boolean;
  toggle: () => unknown;
};

export type ContentPublisherContextValue = {
  [PostContentType.Text]:  ContentPublisherState;
  [PostContentType.Video]: ContentPublisherState;
};