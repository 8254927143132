import * as scheduler from '@containers/Scheduler/utils';
import { CallType, InvitationType } from '@enums';
import { AdHocCall, ProjectCall, Invitations } from '@/types';

export function selectConsultantInvitations(state: Store.State): Invitations.Item[] {
  return [
    ...selectAdHocCallTimeProposals(state),
    ...selectAdHocCallRequests(state),
    ...selectProjectCallInvites(state),
    ...selectProjectInvites(state),
  ].filter(Boolean);
}

export function selectAdHocCallTimeProposals(state: Store.State) {
  return state.calls.ids.reduce((acc, id) => {
    const item = state.calls[id] as AdHocCall;

    if (item.typeId === CallType.AdHoc &&
      requiresAction(item)) {
      acc.push({
        item,
        type: InvitationType.AdHocCallTimeProposal,
      });
    }

    return acc;
  }, [] as [] as Invitations.Item<InvitationType.AdHocCallTimeProposal>[]);

  function requiresAction(item: AdHocCall) {
    return (
      (scheduler.calls.isPending(item) && item.request.accepted) ||
      scheduler.calls.isRescheduling(item)
    ) && !scheduler.calls.isLastModifiedBy(item, state.user);
  }
}

export function selectAdHocCallRequests(state: Store.State) {
  return state.calls.ids.reduce((acc, id) => {
    const item = state.calls[id] as AdHocCall;

    if (item.typeId === CallType.AdHoc &&
      requiresAction(item)) {
      acc.push({
        item,
        type: InvitationType.AdHocCallRequest,
      });
    }

    return acc;
  }, [] as [] as Invitations.Item<InvitationType.AdHocCallRequest>[]);

  function requiresAction(item: AdHocCall) {
    return scheduler.calls.isPending(item) &&
      item.request.userId === state.user.id &&
      !item.request.accepted;
  }
}

export function selectProjectCallInvites(state: Store.State) {
  return state.calls.ids.reduce((acc, id) => {
    const item = state.calls[id] as ProjectCall;

    if (item.typeId === CallType.Project &&
      requiresAction(item)) {
      acc.push({
        item,
        type: InvitationType.ProjectCall,
      });
    }

    return acc;
  }, [] as Invitations.Item<InvitationType.ProjectCall>[]);

  function requiresAction(item: ProjectCall) {
    return scheduler.calls.isActivelyScheduling(item)
        && !scheduler.calls.isLastModifiedBy(item, state.user);
  }
}

export function selectProjectInvites(state: Store.State) {
  return state.projects.ids.reduce((acc, id) => {
    const item = state.projects[id];
    const record = state.pipeline.me[item.id];

    return scheduler.projects.isAwaitingConsultantAction(record)
      ? acc.concat({
        item,
        type: InvitationType.ProjectRequest,
      })
      : acc;
  }, [] as Invitations.Item<InvitationType.ProjectRequest>[]);
}