import { pathname } from '@consts';
import { isProjectSurveyType } from '@containers/ProjectOnboarding/utils';
import { useSelectProject } from '@containers/Store';
import { Back } from '@presentation/Back';
import { Project } from '@/types';
import { Button } from '@/components/Button';
import { ImportContacts } from '@/components/ConnectAccount/ImportContacts';
import { Email, Link } from '@/components/ProjectInviteReferral';
import { useSubmitConsultantInviteReferralEmail } from '@/components/ProjectInviteReferral/hooks';
import { ScreenNavigation } from './interfaces';
import styles from './style/InviteReferral.css';

type Props = {
  navigation?: ScreenNavigation;
} & IProjectId;

const InviteReferralScreen = (props: Props) => {
  const project = useSelectProject(props.projectId);
  const subtitle = getSubtitleCopy(project.projectType);

  const mutation = useSubmitConsultantInviteReferralEmail();

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>

          <div className={styles.header}>
            <h1 className={styles.title}>
              {`Invite your colleagues to participate in the ${project.name} project!`}
            </h1>
            <h2 className={styles.subtitle}>{subtitle}</h2>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>Start inviting colleagues</div>
            <div className={styles.import}>
              <div className={styles.label}>Import contacts:</div>
              <ImportContacts />
            </div>
          </div>

          <div className={styles.email}>
            <Email.AddressInput />
            <Email.SubmitButton
              loading={mutation.isLoading}
              onClick={() => mutation.mutate(props.projectId)} />
          </div>

          <div className={styles.message}>
            <Email.MessageTextArea />
          </div>

          <Email.AddressTags />

          <div className={styles.break}>
            <div className={styles.line} />
            <div className={styles.sub}>or</div>
            <div className={styles.line} />
          </div>

          <div className={styles.row}>
            <div className={styles.label}>Share a project invite link</div>
          </div>
          <Link.Container projectId={props.projectId}>
            <Link.LinkShareButtonGroup message={`Invitation to join the ${project.name} on Sentiment`} />
          </Link.Container>
        </div>
        <Navigation navigation={props.navigation} />
      </div>
    </div>
  );
};

const Navigation = (props: Pick<Props, 'navigation'>) => {
  if (!props.navigation) return null;

  return (
    <div className={styles.navigation}>
      <Back
        className={styles.back}
        onClick={props.navigation.back} />
      <Button
        className={styles.link}
        to={pathname.Home}
        variant="link">
        Home
      </Button>
    </div>
  );
};

function getSubtitleCopy(type: Project['projectType']) {
  const value = isProjectSurveyType({ projectType: type })
      ? 25
      : 50;

  return `Refer up to 100 colleagues, and for each that is able to successfully complete a project, you will receive a $${value} payment.`;
}

export const InviteReferral = ({ navigation, projectId }: Props) => {
  return (
    <Email.Container>
      <InviteReferralScreen
        navigation={navigation}
        projectId={projectId} />
    </Email.Container>
  );
};

InviteReferral.displayName = 'InviteReferral';