import { useCallback, useContext } from 'react';
import { Logger, type TwilioError } from 'twilio-video';
import * as Sentry from '@sentry/react';
import { ConferenceLogType } from '@/types';
import Toast from '@/components/Toast';
import { useGetTwilioLogs } from './useGetTwilioLogs';
import { useLogConferenceEvent } from './useLogConferenceEvent';

export const useOnError = () => {
  const getTwilioLogs = useGetTwilioLogs();
  const logConferenceEvent = useLogConferenceEvent();

  return useCallback((error: Error | TwilioError) => {
    const logs = getTwilioLogs();

    Sentry.captureException(error, {
      extra: {
        twilioLogs: logs,
      },
    });
    console.error(`VIDEO ERROR: ${error.message}`, error);
    Toast.error({
      title: 'Video Error',
      body: `${error.message}`,
    });
    logConferenceEvent(ConferenceLogType.Error, { message: error.message });
  }, [getTwilioLogs, logConferenceEvent]);
};