import ps from 'static/images/website/research-community/personalized-support.png';
import tr from 'static/images/website/research-community/transformative-research.png';
import wg from 'static/images/website/research-community/white-glove.png';
import * as Layout from './Layout';
import { SignUpButton } from './SignUpButton';
import styles from './style/ValuePropositions.css';

type Props = unknown;

export const ValuePropositions = (props: Props) => {

  return (
    <Layout.Screen className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          {items.map(item =>
            <div
              className={styles.content}
              key={item.title}>

              <div className={styles.info}>
                <div className={styles.header}>
                  <Layout.Title className={styles.title}>
                    {item.title}
                  </Layout.Title>
                </div>
                <div className={styles.copy}>
                  {item.paragraphs.map((p, i) =>
                    <div
                      className={styles.paragraph}
                      key={`p-${i}`}>
                      {p.map(x =>
                        <span
                          className={x.className}
                          key={x.text}>
                          {x.text}
                        </span>)}
                    </div>)}
                </div>
                <div className={styles.btn}>
                  <SignUpButton />
                </div>
              </div>

              <div className={styles.graphic}>
                <img
                  className={styles.img}
                  src={item.image} />
              </div>
            </div>)}
        </div>
      </div>
    </Layout.Screen>
  );
};

ValuePropositions.displayName = 'ValuePropositions';

const items = [{
  image: ps,
  title: `Fast & Flexible Payments`,
  paragraphs: [
    [
      {
        text: `Our engagements pay `,
      },
      {
        className: styles.em,
        text: `competitive, fair market rates, `,
      },
      {
        text: `and we process honoraria payments almost immediately. You can choose from `,
      },
      {
        className: styles.em,
        text: `several payment types, `,
      },
      {
        text: `including ACH transfer, standard check, and Paypal (depending on your location). `,
      },
    ], [
      {
        text: `Our `,
      },
      {
        className: styles.em,
        text: `user-friendly, online portal `,
      },
      {
        text: `allows you to view your detailed project history and payment status.`,
      },
    ],
  ],
}, {
  image: wg,
  title: `White Glove Service`,
  paragraphs: [
    [
      {
        className: styles.em,
        text: `We treat you like a partner, `,
      },
      {
        text: `not a respondent.`,
      },
    ], [
      {
        text: `Our dedicated team is here to provide you with `,
      },
      {
        className: styles.em,
        text: `timely and personalized support. `,
      },
      {
        text: `You won't be waiting days for a response to your message, and you'll likely get to know our research team by name.`,
      },
    ],  [
      {
        text: `Because we oversee every aspect of the engagement, we can offer you `,
      },
      {
        className: styles.em,
        text: `an exceptional, high-touch experience. `,
      },
      {
        text: `You'll never feel like “just another number” in our database.`,
      },
    ],
  ],
}, {
  image: tr,
  title: `Transformative Research`,
  paragraphs: [
    [
      {
        text: `Sentiment offers `,
      },
      {
        className: styles.em,
        text: `interesting and relevant studies `,
      },
      {
        text: `for you to participate in at every engagement.`,
      },
    ], [
      {
        text: `Your contributions through Sentiment can help generate high quality, scientific publication-grade research. `,
      },
    ], [
      {
        text: `This research will transform the healthcare landscape by `,
      },
      {
        className: styles.em,
        text: `informing unmet needs `,
      },
      {
        text: `and disease burden, `,
      },
      {
        className: styles.em,
        text: `enabling treatment access `,
      },
      {
        text: `for patients, and `,
      },
      {
        className: styles.em,
        text: `conveying the value story `,
      },
      {
        text: `of novel treatments.`,
      },
    ],
  ],
}];
