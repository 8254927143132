import { useCallback } from 'react';
import { useInvalidateFilesQueries, useInvalidateFolderQueries } from '@utils/api';
import type { WorkspaceFolder } from '@/types/workspace';
import type { WorkspaceFolders } from '@/services/api/interfaces';
import { useRenameFolder } from './hooks';
import { RenameFolderContext } from './Context';

type Props =
  {
    folder: Pick<WorkspaceFolder, 'id' | 'name'>;
    onSuccess?: (res: WorkspaceFolders.RenameFolder.Response<Date>) => void;
  } &
  ChildrenProps;

export const RenameFolderContainer = (props: Props) => {

  const renameFolder = useRenameFolder({ onSuccess: props.onSuccess });
  const invalidateQueries = useInvalidateFilesQueries();
  const invalidateFolderQueries = useInvalidateFolderQueries();

  const handleRename = useCallback((name: string) => {
    return renameFolder({
      folderId: props.folder.id,
      name,
    }).then(() => {
      invalidateFolderQueries({
        folderId: props.folder.id,
      });
      invalidateQueries();
    });
  }, [
    invalidateQueries,
    invalidateFolderQueries,
    renameFolder,
    props.folder.id,
  ]);

  const value = {
    folder: props.folder,
    rename: handleRename,
  };

  return (
    <RenameFolderContext.Provider value={value}>
      {props.children}
    </RenameFolderContext.Provider>
  );
};

export default RenameFolderContainer;