import { SurveyQuestionConditionType, SurveyQuestionType } from '@enums/Survey';

export const QuestionTypeConditionMap: Record<SurveyQuestionType, SurveyQuestionConditionType[]> = {
  [SurveyQuestionType.Dropdown]: [SurveyQuestionConditionType.OptionSelected, SurveyQuestionConditionType.OptionNotSelected],
  [SurveyQuestionType.MultipleChoice]: [SurveyQuestionConditionType.OptionSelected, SurveyQuestionConditionType.OptionNotSelected],
  [SurveyQuestionType.Multiselect]: [SurveyQuestionConditionType.OptionSelected, SurveyQuestionConditionType.OptionNotSelected, SurveyQuestionConditionType.NumberOfOptionsSelected],
  [SurveyQuestionType.Ranking]: [
    SurveyQuestionConditionType.OptionRankedFirst,
    SurveyQuestionConditionType.OptionRankedLast,
    SurveyQuestionConditionType.OptionSelected,
    SurveyQuestionConditionType.OptionNotSelected,
    SurveyQuestionConditionType.OptionRankedTopX,
  ],
  [SurveyQuestionType.MatrixMultiselect]: [SurveyQuestionConditionType.MatrixValueChosen, SurveyQuestionConditionType.MatrixValueNotChosen],
  [SurveyQuestionType.MatrixGrid]: [
    SurveyQuestionConditionType.MatrixValueChosen,
    SurveyQuestionConditionType.MatrixValueNotChosen,
    SurveyQuestionConditionType.Straightline,
  ],
  [SurveyQuestionType.MatrixRange]: [
    SurveyQuestionConditionType.MatrixValueChosen,
    SurveyQuestionConditionType.MatrixValueNotChosen,
    SurveyQuestionConditionType.Straightline,
  ],
  [SurveyQuestionType.Sliders]: [SurveyQuestionConditionType.SliderValue, SurveyQuestionConditionType.SliderValuesTally],
  [SurveyQuestionType.NumberInputTable]: [SurveyQuestionConditionType.NumberCellValue],
  [SurveyQuestionType.ExclusiveOptions]: [SurveyQuestionConditionType.OptionSelected, SurveyQuestionConditionType.OptionNotSelected],
  [SurveyQuestionType.ShortTextResponse]: [],
  [SurveyQuestionType.LongTextResponse]: [],
  [SurveyQuestionType.ConjointAnalysis]: [],
  [SurveyQuestionType.MaxDifference]: [],
  [SurveyQuestionType.MultiTextbox]: [],
  [SurveyQuestionType.ImageMarkup]: [],
};

export const LogicQuestionTypes = Object.keys(QuestionTypeConditionMap)
  .map(m => +m as SurveyQuestionType)
  .filter(f => !!QuestionTypeConditionMap[f].length)
  ;

export function getConditionsForQuestionType(questionType: SurveyQuestionType) {
  return QuestionTypeConditionMap[questionType];
}

export const OptionConditions = [
  SurveyQuestionConditionType.OptionSelected,
  SurveyQuestionConditionType.OptionNotSelected,
  SurveyQuestionConditionType.MatrixValueChosen,
  SurveyQuestionConditionType.MatrixValueNotChosen,
  SurveyQuestionConditionType.NumberCellValue,
];

export const RowConditions = [
  SurveyQuestionConditionType.MatrixValueChosen,
  SurveyQuestionConditionType.MatrixValueNotChosen,
  SurveyQuestionConditionType.NumberCellValue,
  SurveyQuestionConditionType.SliderValue,
];