import { useCallback, useState } from 'react';
import * as api from '@api';
import * as API from '@api/interfaces';
import { useAsyncStateLazy } from '@utils';
import { Search } from '@/types';
import { SearchQueryLegacyContext } from './Context';

type Props = {
  children: React.ReactNode;
};

const SearchQueryLegacyContainer = (props: Props) => {
  const [state, dispatch] = useState<Search.Legacy.QueryState>(SearchQueryLegacyContainer.initialState);

  const [response, fetch] = useAsyncStateLazy(async (data: API.Search.FetchEnterpriseQueryResults.Request) => {

    const res = await api.search.fetchEnterpriseQueryResults(data);

    const items = data.searchAfter
        ? state.items.concat(res.items)
        : res.items;

    const value: Search.Legacy.QueryState = {
      items,
      new: !data.searchAfter,
      total: res.total,
    };

    dispatch(value);

    return value;

  }, [
    dispatch,
    state,
  ]);

  const fetchExport = useCallback((data: API.Search.FetchEnterpriseQueryResults.Request) => {
    return api.search.fetchEnterpriseQueryResultsExport({ ...data, export: true });
  }, []);

  const value = {
    loading: response.loading,
    value: state,
  };

  return (
    <SearchQueryLegacyContext.Provider value={[value, fetch, fetchExport]}>
      {props.children}
    </SearchQueryLegacyContext.Provider>
  );
};

const initial: Search.Legacy.QueryState = {
  items: [],
  new: false,
  total: 0,
};

SearchQueryLegacyContainer.initialState = initial;

export { SearchQueryLegacyContainer };
export default SearchQueryLegacyContainer;