import { useProvidedDevices } from '@containers/DeviceProvider';
import { useIdentifyBrowser } from '@utils/hooks';
import { Accordion } from '@/components/Accordion';
import styles from './style/BrowserDeviceHelp.css';

type Props = {
  className?: string;
};

export const BrowserDeviceHelp = (props: Props) => {
  const devices = useProvidedDevices();
  const browser = useIdentifyBrowser();

  return (
    <div className={props.className}>
      <Accordion
        label={'Chrome'}
        collapsed={!browser.chrome}
        showCount={false}>
        <div>
          For full details refer to  <ExternalLink href={ChromeHelpPage}>{`Chrome's help page`}</ExternalLink> on using camera and microphone in the browser.
        </div>
      </Accordion>
      <Accordion
        label={'Firefox'}
        collapsed={!browser.firefox}
        showCount={false}>
        <ExternalLink href={FirefoxHelpPage}>{`For full details refer to Mozilla's help page on managing camera and microphone permissions in Firefox`}</ExternalLink>
      </Accordion>
      <Accordion
        label={'Edge'}
        collapsed={true}
        showCount={false}>
        <ExternalLink href={EdgeHelpPage}>{`For full details refer to Microsoft's help page on managing camera and microphone permissons in Edge.`}</ExternalLink>
      </Accordion>
      <Accordion
        label={'Safari'}
        collapsed={!browser.safari}
        showCount={false}>
        <ExternalLink href={SafariHelpPage}>{`For full details refer to Safari's help page on managing camera and microphone permissions.`}</ExternalLink>
      </Accordion>
    </div>
  );
};

type ExternalLinkProps = {
  href: string;
} & ChildrenProps;
const ExternalLink = (props: ExternalLinkProps) => {
  return (
    <a
      className={styles.externalLink}
      href={props.href}
      target="_blank"
      rel="noreferrer">
      {props.children}
    </a>
  );
};

const ChromeSettingsPage = `chrome://settings/content/siteDetails?site=${encodeURIComponent(window.location.origin)}`;
const ChromeHelpPage = 'https://support.google.com/chrome/answer/2693767?hl=en-GB&co=GENIE.Platform%3DDesktop&oco=1';
const FirefoxHelpPage = 'https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions';
const SafariHelpPage = 'https://support.apple.com/guide/safari/websites-ibrwe2159f50';
const EdgeHelpPage = `https://support.microsoft.com/en-us/windows/windows-camera-microphone-and-privacy-a83257bc-e990-d54a-d212-b5e41beba857#:~:text=In%20Microsoft%20Edge%2C%20select%20Settings,Permissions%2C%20select%20Camera%20or%20Microphone.`;