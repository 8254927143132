import { Conference, Discovery, Inbound, Posts, Profile, SelfServeSurvey, Unsubscribe } from '@consts/path';
import type { PathConfig } from '@containers/Routing/interfaces';
import { omit } from '@utils/path';

export const Paths: PathConfig = {
  any: [
    Discovery.Root,
    Inbound.Confirmation,
    Posts.Root,
    Profile.User,
    SelfServeSurvey.Root,
    Unsubscribe.Root,
  ],
  exact: [
    ...Object.values(omit(Conference, 'MiniNotes', 'MiniTagging', 'CaptureSheet', 'CaptureSheetPopout')),
  ],
};