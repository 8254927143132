import { memo } from 'react';
import type { FileIconProps } from './interfaces';
import { getIconByExtension } from './utils';

type Props = {
  extension: string;
} & FileIconProps;

export const FileIcon = memo(({
  extension,
  size = 28,
  ...iconProps
}: Props) => {

  const Icon = getIconByExtension(extension);

  return (
    <Icon
      size={size}
      {...iconProps} />
  );

});

export default FileIcon;