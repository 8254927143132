import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import MUIMenuItem from '@mui/material/MenuItem';
import type { SearchResultItem } from '@api/interfaces/projects.access';
import * as QK from '@consts/querykey';
import { WorkspaceObjectRole, utils } from '@enums';
import { BadgeExternal, BadgeOffPlatform, BadgeUnverified, BadgeVerified } from '@presentation';
import { useEvalUserVerification, useShouldDisplayExternalBadge } from '@/components/ObjectAccess/hooks';
import { Checkbox } from '@/components/Checkbox';
import * as api from '$admin/api';
import { Select } from '$admin/components/Select';
import { Picker } from './Project.Access.Picker';
import type { Entry, Item } from './interfaces';
import styles from './style/ProjectAccess.css';

type Props = {
  ContextMenu?: React.ComponentType<{ item: Entry }>;
  children?: React.ReactNode;
  getItemDisabled?: (item: Entry) => boolean;
  items: Entry[];
  updater: (items: Entry[]) => unknown;
  showPrimary?: boolean;
  optionsType?: 'All' | 'Participants' | 'TranscriptPublishing';
};

export const ProjectAccess = ({ ContextMenu = null, optionsType = 'Participants', getItemDisabled, items, updater, showPrimary = true, ...props }: Props) => {
  const [keyword, setKeyword] = useState('');
  const [selected, setSelected] = useState<SearchResultItem[]>([]);

  const exclude = useMemo(() => {
    return items.map(x => x.email);
  }, [items]);

  const keywordQuery = useQuery({
    queryKey: [
      QK.Admin.Search.Users.XPlat.Get,
      keyword,
      exclude,
    ],
    queryFn: () => {
      return api.search.users.xplat({
        exclude,
        keyword,
      });
    },
    enabled: !!keyword.trim(),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const handleChangeRole = useCallback((item: Entry, value: WorkspaceObjectRole) => {
    if (value === RemoveOptionId) {
      const removed = items.filter(x => x.email !== item.email);

      updater(removed);

    } else if (value === WorkspaceObjectRole.Owner) {
      const updated = items.map(x => {
        return x.roleId === WorkspaceObjectRole.Owner
          ? { ...x, roleId: WorkspaceObjectRole.Collaborator }
          : x.email === item.email
            ? { ...x, roleId: value }
            : x;
      });

      updater(updated);
    } else {
      const updated = items.map(x => {
        const { primaryModeratorEnabled, ...rest } = x;

        return x.email === item.email
          ? { ...rest, roleId: value }
          : x;
      });

      updater(updated);
    }
  }, [
    items,
    updater,
  ]);

  const handleChangeModeratorEnabled = useCallback((item: Entry, checked: boolean) => {
    const updated = items.map(x => {
      return x.email === item.email
        ? { ...x, primaryModeratorEnabled: checked }
        : x;
    });

    updater(updated);
  }, [
    items,
    updater,
  ]);

  const multiAdd = useCallback((toAdd: Omit<Item, 'roleId'>[], role: WorkspaceObjectRole) => {
    const selections = toAdd.map(item => ({
      id: item.id,
      internal: item.internal,
      name: item.name,
      email: item.email,
      offPlatform: !!item.offPlatform,
      registerable: item.registerable,
      roleId: role,
      verified: item.verified,
    }));

    updater([...items, ...selections]);
    setKeyword('');
  }, [items, updater]);

  const totals = useMemo(() => {
    return {
      moderators: items.filter(x => roleIsModeratorLike(x.roleId) && x.primaryModeratorEnabled).length,
    };
  }, [items]);

  const getRoleDisabled = useCallback((item: Entry) => {
    const modlike = roleIsModeratorLike(item.roleId);
    const moderator = modlike && item.primaryModeratorEnabled;

    if (item.roleId === WorkspaceObjectRole.Owner) return true;

    if (totals.moderators <= 1 && moderator) return true;

    if (totals.moderators > 1 && moderator) {
      return moderator && totals.moderators <= 1;
    }

    return getItemDisabled?.(item);
  }, [
    getItemDisabled,
    totals,
  ]);

  const getOptions = useCallback((item: Entry) => {
    if (item.offPlatform) return Options.Restricted;

    if (roleIsOwner(item.roleId)) return Options.All;

    return Options[optionsType];
  }, [optionsType]);

  const isRoleValid = useCallback((item: SearchResultItem, role: WorkspaceObjectRole) => {
    if (item.offPlatform) return Options.Restricted.some(r => r.id === role);

    return true;
  }, []);

  const pastedEmailsQuery = useMutation({
    mutationKey: [
      QK.Admin.Search.Users.XPlat.Get,
      exclude,
    ],
    mutationFn: (emails: string[]) => {
      return api.search.users.xplat({
        exclude,
        emails,
      });
    },
  });

  const handlePaste = useCallback((emails: string[], role: WorkspaceObjectRole) => {
    pastedEmailsQuery.mutateAsync(emails)
    .then(res => {
      const all = new Set([
        ...res.items.map(x => x.email),
        ...selected.map(x => x.email),
        ...exclude,
      ]);

      const additions = emails.reduce((acc, email) => {
        return all.has(email)
          ? acc
          : [...acc, toUnsavedOffPlat(email)];
      }, res.items);

      const invalidAdditions = additions.filter(item => !isRoleValid(item, role));

      if (invalidAdditions.length) {
        alert(`The following emails cannot be added with the ${utils.WorkspaceObjectRole.getName(role)} role: \n\n${invalidAdditions.map(x => x.email).join('\n')}`);
        return;
      }

      setSelected([...selected, ...additions]);
    });
  }, [
    exclude,
    isRoleValid,
    pastedEmailsQuery,
    selected,
  ]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.field}>
            <div className={styles.label}>Enter Name or Email</div>
            <Picker
              existingItems={items}
              isRoleValid={isRoleValid}
              onAdd={multiAdd}
              onChange={setSelected}
              onPaste={handlePaste}
              roleOptions={Options[optionsType]}
              search={{
                loading: pastedEmailsQuery.isLoading,
                results: keywordQuery.data?.items || [],
                keyword,
                setKeyword,
              }}
              selected={selected} />
          </div>

          <div className={styles.table}>
            <div className={styles.header}>
              <div className={styles.name}>
                <div className={styles.label}>Name</div>
              </div>
              <div className={styles.fields}>
                <div className={styles.role}>
                  <div className={styles.label}>Role</div>
                </div>
                {showPrimary &&
                  <div className={styles.checkbox}>
                    <div className={styles.label}>Primary</div>
                  </div>
                }
              </div>
            </div>

            <div className={styles.items}>
              {items.map(x =>
                <div
                  className={styles.item}
                  key={x.id || x.email}>
                  <div className={styles.name}>
                    <div className={styles.text}>{x.name?.trim?.() || x.email}</div>
                    <UserVerificationInfo item={x} />
                    {ContextMenu && <ContextMenu item={x} />}
                  </div>
                  <div className={styles.fields}>
                    <div className={styles.role}>
                      <Select
                        classes={{
                          disabled: styles.disabled,
                          root: styles.select,
                        }}
                        disabled={getRoleDisabled(x)}
                        onChange={e => handleChangeRole(x, +e.target.value)}
                        options={getOptions(x)}
                        value={x.roleId}>
                        {!getRoleDisabled(x) &&
                          <MUIMenuItem
                            key={RemoveOptionId}
                            style={{
                              borderTop: `1px solid var(--sec-01)`,
                              minHeight: 36,
                              cursor: 'pointer',
                            }}
                            value={RemoveOptionId}>
                            {RemoveOptionName}
                          </MUIMenuItem>}
                      </Select>
                    </div>
                    {showPrimary &&
                      <div className={styles.checkbox}>
                        {(roleIsModeratorLike(x.roleId) && !x.offPlatform) &&
                          <Checkbox
                            checked={x.primaryModeratorEnabled}
                            disabled={totals.moderators <= 1 && x.primaryModeratorEnabled}
                            disabledColor="var(--gray-l)"
                            onChange={(e, checked) => handleChangeModeratorEnabled(x, checked)} />}
                      </div>
                    }
                  </div>
                </div>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ProjectAccess.displayName = 'ProjectAccess';

const RemoveOptionId = 0 as WorkspaceObjectRole;
const RemoveOptionName = 'Remove';

const makeOption = (role: WorkspaceObjectRole, displayName?: string) => ({
  id: role,
  name: displayName ?? utils.WorkspaceObjectRole.getName(role),
});

const Options = {
  All: [
    makeOption(WorkspaceObjectRole.Attendee),
    makeOption(WorkspaceObjectRole.Viewer),
    makeOption(WorkspaceObjectRole.Collaborator),
    makeOption(WorkspaceObjectRole.Owner),
  ],
  Restricted: [
    makeOption(WorkspaceObjectRole.Attendee),
    makeOption(WorkspaceObjectRole.Viewer),
  ],
  Participants: [
    makeOption(WorkspaceObjectRole.Attendee),
    makeOption(WorkspaceObjectRole.Viewer),
    makeOption(WorkspaceObjectRole.Collaborator),
  ],
  TranscriptPublishing: [
    makeOption(WorkspaceObjectRole.Viewer),
    makeOption(WorkspaceObjectRole.Collaborator),
    makeOption(WorkspaceObjectRole.Attendee, `No File Access`),
  ],
  TranscriptPublishingSearch: [
    makeOption(WorkspaceObjectRole.Viewer),
    makeOption(WorkspaceObjectRole.Collaborator),
  ],
};

const roleIsOwner = (roleId: WorkspaceObjectRole) => {
  return roleId === WorkspaceObjectRole.Owner;
};

const roleIsModeratorLike = (roleId: WorkspaceObjectRole) => {
  return [
    WorkspaceObjectRole.Owner,
    WorkspaceObjectRole.Collaborator,
  ].includes(roleId);
};

const toUnsavedOffPlat = (email: string) => ({
  email,
  id: null,
  name: email,
  offPlatform: true,
});

type UserVerificationInfoProps = {
  item: Entry;
};

const UserVerificationInfo = ({ item }: UserVerificationInfoProps) => {
  const evalUserVerification = useEvalUserVerification();
  const shouldDisplayExternalBadge = useShouldDisplayExternalBadge();

  const verif = evalUserVerification(item);
  const external = shouldDisplayExternalBadge(item.internal);

  return (
    <>
      {verif.isUnverified && <BadgeUnverified />}
      {verif.isVerified && <BadgeVerified />}
      {verif.isOffPlatform && <BadgeOffPlatform />}
      {external && <BadgeExternal />}
    </>
  );
};