import { client } from './client';
import type * as QueryApi from './interfaces/queries';
import { transformChatInstance, transformChatQuery } from './transformers';

export const updateQueryRating = ({ chatInstanceId, queryIdentifier, ...data }: QueryApi.UpdateQueryRating.Request) => {
  return client.patch<QueryApi.UpdateQueryRating.Response>(`/chat/${chatInstanceId}/queries/${queryIdentifier}/rating`, data)
    .then(res => ({
      query: transformChatQuery(res.data.query),
    }));
};

export const updateQueryFeedback = ({ chatInstanceId, queryIdentifier, ...data }: QueryApi.UpdateQueryFeedback.Request) => {
  return client.patch<QueryApi.UpdateQueryFeedback.Response>(`/chat/${chatInstanceId}/queries/${queryIdentifier}/feedback`, data)
    .then(res => ({
      query: transformChatQuery(res.data.query),
    }));
};

export const getQueryFeedback = ({ chatInstanceId, queryIdentifier }: QueryApi.GetQueryFeedback.Request) => {
  return client.get<QueryApi.GetQueryFeedback.Response>(`/chat/${chatInstanceId}/queries/${queryIdentifier}/feedback`)
    .then(res => ({
      feedback: res.data.feedback,
    }));
};

export const getQuery = ({ chatInstanceId, queryIdentifier }: QueryApi.GetQuery.Request) => {
  return client.get<QueryApi.GetQuery.Response>(`/chat/${chatInstanceId}/queries/${queryIdentifier}`)
    .then(res => ({
      chatInstance: transformChatInstance(res.data.chatInstance),
      query: transformChatQuery(res.data.query),
    }));
};