import { Link } from 'react-router-dom';
import * as consts from '@consts';
import { cx } from '@utils';
import styles from './style.css';

type Props = {
  className?: string;
  item: {
    id: number;
    name: string;
  };
}

const ProjectLink = (props: Props) => (
  <Link
    className={cx(styles.name, props.className)}
    to={`${consts.path.Admin.Projects.Root}/${props.item.id}`}
    title={props.item.name}>
    {props.item.name}
  </Link>
);

export { ProjectLink, Props as ProjectLinkProps };
export default ProjectLink;