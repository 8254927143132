import { createRef, useCallback, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import type { SurveyPipedQuestionValueType } from '@enums/Survey';
import { SurveyPipedValueType, SurveyPipedItemValueType } from '@enums/Survey';
import type { PipedValue, RankingQuestion, SurveyQuestion, SurveyQuestionOption } from '@/types/survey';
import { getPipingTypesForQuestionType, pipingValueMap } from '@/containers/SurveyBuilder/utils';
import { Portal } from '@/components/Portal';
import { useZIndexModifier } from '@/components/Popper';
import type { RowTypes, OptionTypes, OrdinalTypes } from './consts';
import { rowTypes, optionTypes, ordinalTypes } from './consts';
import { usePipingMenuState } from './context';
import { Menu, MenuItem } from './Editor.Piping.Menu';
import { PipingRowsMenu } from './Editor.Piping.RowsMenu';
import { PipingOptionsMenu } from './Editor.Piping.OptionsMenu';
import { PipingOrdinalsMenu } from './Editor.Piping.OrdinalsMenu';

type Props = {
  item: SurveyQuestion;
};

type OpenTypes = RowTypes | OptionTypes | OrdinalTypes;

export const QuestionConditionsMenu = ({ item }: Props) => {

  const [openValue, setOpenValue] = useState<OpenTypes>(null);

  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);

  const items = useMemo(() => {
    return getPipingTypesForQuestionType(item.typeId);
  }, [item.typeId]);

  const refMap = useMemo(() => {
    return items.reduce<Map>((acc, x) => {
      acc[x] = createRef<HTMLDivElement>();
      return acc;
    }, {});

    type Map = {
      [condition: string]: React.RefObject<HTMLDivElement>;
    };
  }, [items]);

  const referenceElement = useMemo(() => {
    return refMap[openValue];
  }, [refMap, openValue]);

  const zIndexModifier = useZIndexModifier({ zIndex: 8 });

  const { styles: popperStyles, attributes } = usePopper(referenceElement?.current, popperElement, {
    modifiers: [
      zIndexModifier,
    ],
    placement: 'right-start',
  });

  const { onPipingValueSelect } = usePipingMenuState();

  const handleMatrixOptionClicked = useCallback((option: SurveyQuestionOption) => {
    const value = {
      type: openValue,
      option: {
        identifier: option.base.identifier,
      },
    } as PipedValue.MatrixRowsNotSelected | PipedValue.MatrixRowsNotSelected;
    onPipingValueSelect({
      value,
      question: {
        identifier: item.base.identifier,
      },
    });
  }, [item.base.identifier, onPipingValueSelect, openValue]);

  const handleItemClick = useCallback((type: SurveyPipedQuestionValueType) => () => {

    const value = pipingValueMap[type];

    onPipingValueSelect({
      value,
      question: {
        identifier: item.base.identifier,
      },
    });
  }, [item.base.identifier, onPipingValueSelect]);

  const renderItem = useCallback((type: SurveyPipedQuestionValueType) => {
    const active = type === openValue;
    const hasChildren = [
      ...rowTypes,
      ...optionTypes,
      ...ordinalTypes,
    ].includes(type);

    const onClick = hasChildren
      ? () => setOpenValue(type as OpenTypes)
      : handleItemClick(type);

    return (
      <MenuItem
        key={type}
        ref={refMap[type]}
        active={active}
        hasChildren={hasChildren}
        onClick={onClick}>
        {displayTextMap[type]}
      </MenuItem>
    );
  }, [
    handleItemClick,
    openValue,
    refMap,
  ]);

  const handleBlur = useCallback(() => {
    setOpenValue(null);
  }, []);

  return (
    <>
      <Menu>
        {items.map(renderItem)}
      </Menu>
      {openValue !== null &&
        <Portal>
          <ClickAwayListener
            mouseEvent='onMouseDown'
            onClickAway={handleBlur}
            touchEvent='onTouchStart'>
            <div
              ref={setPopperElement}
              style={popperStyles.popper}
              {...attributes.popper}>
              {rowTypes.includes(openValue) &&
                <PipingRowsMenu
                  type={openValue as RowTypes}
                  item={item} />
              }
              {optionTypes.includes(openValue) &&
                <PipingOptionsMenu
                  type={openValue}
                  onClick={handleMatrixOptionClicked}
                  item={item} />
              }
              {openValue === SurveyPipedValueType.RankedXOption && (
                <PipingOrdinalsMenu
                  question={item as RankingQuestion.Question} />
              )}
            </div>
          </ClickAwayListener>
        </Portal>
      }
    </>
  );
};

const displayTextMap: Record<SurveyPipedValueType, string> = {
  [SurveyPipedValueType.FirstRankedOption]: 'Ranked first',
  [SurveyPipedValueType.SecondRankedOption]: 'Ranked second',
  [SurveyPipedValueType.SecondLastRankedOption]: 'Ranked second last',
  [SurveyPipedValueType.LastRankedOption]: 'Ranked last',
  [SurveyPipedValueType.AllRankedOptions]: 'All ranked options',
  [SurveyPipedValueType.RankedXOption]: 'Ranked X option',

  [SurveyPipedValueType.MatrixRowsNotSelected]: 'Rows Not selected',
  [SurveyPipedValueType.MatrixRowsSelected]: 'Rows Selected',
  [SurveyPipedValueType.MatrixOptionsNotSelected]: 'Options Not selected',
  [SurveyPipedValueType.MatrixOptionsSelected]: 'Options Selected',

  [SurveyPipedValueType.NumberTableCellValue]: 'Cell value',
  [SurveyPipedValueType.OptionsNotSelected]: 'Not selected',
  [SurveyPipedValueType.OptionsSelected]: 'Selected',
  [SurveyPipedValueType.SliderHighestValue]: 'Highest value',
  [SurveyPipedValueType.SliderLowestValue]: 'Lowest value',
  [SurveyPipedValueType.SliderRowValue]: 'Value',

  [SurveyPipedValueType.TextEntryValues]: 'Text entry values',

  [SurveyPipedValueType.AlternateImageShown]: 'Shown image',
};