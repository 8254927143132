import { useCallback, useState } from 'react';
import { useTargetProductProfileData, useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';

export const useCanSaveClient = (value: string) => {

  return value.length >= 2;
};

export const useTemplateClient = () => {
  const [templateData] = useTargetProductProfileData();
  const [value, setValue] = useState(templateData.client);
  const [_, dispatch] = useSurveyBuilderState();

  const onSubmit = useCallback(() => {
    dispatch({
      type: 'template-client-updated',
      value,
    });
  }, [
    value,
    dispatch,
  ]);

  return {
    onChange: setValue,
    onSubmit,
    value,
  };
};