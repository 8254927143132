import { memo, useCallback, useState } from 'react';
import { usePopper } from 'react-popper';
import { ChevronDown } from 'react-feather';
import { ClickAwayListener } from '@mui/material';
import { Button } from '@/components/Button';
import { PopperMenu, useZIndexModifier } from '@/components/Popper';
import { Portal } from '@/components/Portal';
import { Tags } from './interfaces';
import styles from './style/Tags.ColorSelector.css';

type Props = {
  disabled?: boolean;
  color: string;
  onChange: (color: string) => void;
};

export const TagColorSelector = ({ disabled = false, color, onChange }: Props) => {
  const [popperState, setPopperState] = useState(false);
  const [toggleEl, setToggleEl] = useState<HTMLElement>();
  const [popperEl, setPopperEl] = useState<HTMLDivElement>();
  const zIndexModifier = useZIndexModifier({ zIndex: 8 });
  const { styles: popperStyles, attributes } = usePopper(toggleEl, popperEl, {
    modifiers: [zIndexModifier],
  });

  const togglePopper = () => setPopperState(!popperState);
  const closePopper = () => setPopperState(false);

  if (disabled) {
    return <DisabledSelector color={color} />;
  }

  return (
    <div>
      <Button
        ref={r => setToggleEl(r as HTMLElement)}
        className={styles.colorBtn}
        size='small'
        variant='brick'
        color='destructive'
        onClick={togglePopper}>
        <ColorCircle color={color} />
        <ChevronDown size={20} />
      </Button>
      {popperState && (
        <Portal>
          <ClickAwayListener onClickAway={closePopper}>
            <div
              ref={setPopperEl}
              style={popperStyles.popper}
              {...attributes}>
              <PopperMenu>
                <ColorPopper closePopper={closePopper} onChange={onChange} />
              </PopperMenu>
            </div>
          </ClickAwayListener>
        </Portal>
      )
      }
    </div>
  );
};

type ColorPopperProps = {
  closePopper: () => void;
  onChange: (color: string) => void;
};

const ColorPopper = memo(({ closePopper, onChange }: ColorPopperProps) => {
  const selectColor = useCallback((color: string) => {
    onChange(color);
    closePopper();
  }, [onChange, closePopper]);
  return (
    <div className={styles.colorPopper}>
      {Tags.tagColors.map((color, i) => (
        <ColorCircle
          color={color.highlight}
          key={color.highlight}
          onClick={() => selectColor(color.highlight)} />
      ))}
    </div>
  );
});

const ColorCircle = memo(({ color, onClick }: { color: string } & Pick<React.HTMLAttributes<HTMLDivElement>, 'onClick'>) => {
  return (
    <div
      className={styles.colorCircle}
      style={{ backgroundColor: color ?? Tags.defaultColor }}
      onClick={onClick} />
  );
});

type DisabledProps = {
  color: string;
};
const DisabledSelector = memo(({ color }: DisabledProps) => {
  return (
    <div className={styles.disabledColorSelector}>
      <ColorCircle color={color} />
    </div>
  );
});