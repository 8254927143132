import { useRef, useState } from 'react';
import { MenuContext } from './Context';

type Props = {
  children: React.ReactNode;
};

export const Container = (props: Props) => {
  const hamburgerRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const menu = {
    open,
    ref: hamburgerRef,
    setOpen,
  };

  return (
    <MenuContext.Provider value={menu}>
      {props.children}
    </MenuContext.Provider>
  );
};

Container.displayNamee = 'Menu.Container';