import { useAccountProfileLoading, useProfileFeaturedPostsLoading, useProfileFeaturedPosts, useProfileEditableState } from '@containers/AccountProfile';
import { ProfileEntry } from '@enums';
import { cx } from '@utils';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import { ProfilePosts, ProfileSectionMultiEntry, ProfileSectionSingleEntry } from '@/components/UserProfile';
import Header from './Profile.Header';
import { Card } from './Layout';
import styles from './style/Layout.css';

export const Profile = () => {

  const loading = useAccountProfileLoading();
  const featuredPosts = useProfileFeaturedPosts();
  const postsLoading = useProfileFeaturedPostsLoading();
  const editable = useProfileEditableState();

  if (loading || postsLoading) return <ActivityIndicator show />;

  const className = cx({
    [styles.padding]: editable,
  });

  return (
    <div className={className}>
      <Card>
        <Header />
      </Card>

      <Card>
        <ProfileSectionSingleEntry
          type={ProfileEntry.Bio} />
      </Card>

      {!!featuredPosts.items.length &&
        <Card>
          <ProfilePosts />
        </Card>}

      <Card>
        <ProfileSectionMultiEntry
          type={ProfileEntry.Employment} />
      </Card>

      <Card>
        <ProfileSectionMultiEntry
          type={ProfileEntry.Education} />
      </Card>

      <Card>
        <ProfileSectionSingleEntry
          type={ProfileEntry.Industry} />
      </Card>

      <Card>
        <ProfileSectionSingleEntry
          type={ProfileEntry.Product} />
      </Card>
    </div>
  );
};

export default Profile;