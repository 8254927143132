import { useFormikContext } from 'formik';
import { ConferenceSettings } from '@/types';
import { Field } from '$admin/components/Conference.Settings';
import { Manager, State } from '@/components/Conference';
import styles from './style.css';

type Props = {
  editing: boolean;
  manager: Manager;
  state: State;
};

export function FieldConferenceDetails({ editing, manager, state }: Props) {
  const { setFieldValue, values } = useFormikContext<ConferenceSettings>();

  return (
    <div>
      <div className={styles.label}>
        Conference Details
      </div>
      <div className={styles.value}>
        <Field.Details
          editing={editing}
          manager={manager}
          setFieldValue={setFieldValue}
          state={state}
          values={values} />
      </div>
    </div>
  );
}