import { memo } from 'react';
import { Link } from 'react-router-dom';
import { Contact } from '@/types';
import { getLocationFor, slugify, trunc } from '@utils';
import { UserAvatar } from '@/components/UserAvatar';
import styles from './style/Creator.css';

type Props = {
  item: Omit<Contact, 'bio' | 'persona' | 'topics'>;
};

export const Item = memo((props: Props) => {
  const pathname = getLocationFor.user.profile({
    slug: slugify({
      id: props.item.id,
      name: props.item.profile.fullname,
    }),
  });

  // const vics = props.item.vics.slice(0, 4).map(x => x.name).join(', ');
  const vics = [].join(', ');

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Link to={pathname}>
          <div className={styles.main}>
            <div className={styles.user}>
              <UserAvatar
                className={styles.avatar}
                pictureUrl={props.item.profile.pictureUrl} />
            </div>
            <div className={styles.info}>
              <div className={styles.details}>
                <div className={styles.name}>{props.item.profile.fullname}</div>
                <div className={styles.text}>{trunc(props.item.career.current.display, 80)}</div>
              </div>
              <div className={styles.details}>
                <div className={styles.subheader}>Expertise:</div>
                <div className={styles.text}>{trunc(vics, 80)}</div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
});

Item.displayName = 'Creator.Item';