import { useDispatch } from 'react-redux';
import { useSelectGroup } from '@containers/Store/hooks';
import * as actions from '@actions';
import * as api from '@api';
import type { SurveyBuilderVersion } from '@/types';
import { EditablePostCallSurvey } from '@/scenes/surveys/EditableSurvey';
import styles from './style.css';

const PostCallQuestions = () => {
  const dispatch = useDispatch();

  const group = useSelectGroup();

  const deleteSurvey = () => {
    return api.groups.updateGroupSettings({
      groupId: group.id,
      postCallAnalystSurveyId: null,
    })
      .then(settings => {
        dispatch(actions.updateGroupSettings({ settings }));
      });
  };

  const saveSurvey = (data: Pick<SurveyBuilderVersion, 'questions' | 'sections'>) => {
    return api.groups.saveGroupPostCallAnalystSurvey(data)
      .then(survey => {
        const settings = {
          ...group.settings,
          postCallAnalystSurveyId: survey.id,
        };
        dispatch(actions.updateGroupSettings({ settings }));

        return survey;
      });
  };

  const fetchSurvey = () => {
    return api.groups.getGroupPostCallAnalystSurvey();
  };

  return (
    <div className={styles.content}>
      <EditablePostCallSurvey
        onDelete={deleteSurvey}
        onFetch={fetchSurvey}
        onSave={saveSurvey} />
    </div>
  );
};

export default PostCallQuestions;