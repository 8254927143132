import { memo } from 'react';
import { cx } from '@utils';
import styles from './style/Bullet.css';

type Props = {
  className?: string;
  text: string;
};

export const Bullet = memo(({ className, text }: Props) => (
  <div className={cx(styles.bullet, className)}>{text}</div>
));