import { memo } from 'react';
import { useSelectUser } from '@containers/Store';
import { SearchType } from '@enums';
import { cx } from '@utils';
import { MaybeSearchTag } from '@/components/Tag';
import styles from './style/Profile.Tags.css';

type Props = {
  className?: string;
  items: {
    id: number | string;
    name: string;
  }[];
  type: SearchType;
};

export const ProfileTags = memo(({ className, items, type }: Props) => {
  const me = useSelectUser();

  return (
    <div className={cx(styles.tags, className)}>
      {items.map(item => (
        <MaybeSearchTag
          key={item.id}
          className={styles.tag}
          disabled={!me.state.authenticated}
          type={type}
          item={item} />
      ))}
    </div>
  );
});

export default ProfileTags;