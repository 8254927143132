import { useRemirror } from '@remirror/react';
import {
  MentionAtomExtension, MentionAtomOptions,
  PlaceholderExtension, PlaceholderOptions,
} from 'remirror/extensions';
import { CommentDocument } from '@/types/transcript.rich-text.comments';

type Props = {
  initialState?: CommentDocument.RootNode;
  placeholder?: string;
};

export const useCommentEditor = ({ initialState, placeholder }: Props) => {

  const placeholderExtensionParams: PlaceholderOptions = {
    placeholder,
  };

  const mentionAtomExtensionParams: MentionAtomOptions = {
    matchers: [{ name: 'at', char: '@', matchOffset: 0 }],
  };

  return useRemirror({
    content: initialState,
    extensions: () => [
      new PlaceholderExtension(placeholderExtensionParams),
      new MentionAtomExtension(mentionAtomExtensionParams),
    ],
  });

};