import { useModal } from '@/components/Modal/hooks';
import { Alert } from '@/components/Modal/Alert';
import type { ModalProps } from '@/components/Modal/Modal';

type Props = {
  isSubmitting: boolean;
  onSubmit: () => unknown;
} & Pick<ModalProps, 'open' | 'onClose'>;

export const DeleteCommentAlert = ({ isSubmitting, open, onClose, onSubmit }: Props) => {

  return (
    <Alert
      open={open}
      onClose={onClose}
      onConfirm={onSubmit}
      message="Are you sure you want to remove this comment?"
      confirmText="Remove" />
  );
};

export const useDeleteCommentAlert = () => useModal(DeleteCommentAlert);