import * as $api from '@api/transcripts';
import { useQueryDownloader } from '@containers/QueryDownloader';
import type { DownloadTranscriptClipParams } from '../context';

export const useDownloadTranscriptClip = (props: DownloadTranscriptClipParams) => {

  return useQueryDownloader({
    queryKey: [
      `get:transcript/clip`,
      props,
    ],
    queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      return $api.downloadTranscriptClip(params);
    },
    downloaderOptions: {
      onResponse: res => ({
        type: 'websocket',
        value: res.websocketKey,
      }),
    },
  });
};