import { useCallback, useMemo } from 'react';
import { cx } from '@utils';
import type { SurveyActionType } from '@enums/Survey';
import {
  useIncludeSectionItems,
  useSkipToQuestionItems,
  useSkipToSectionItems,
  useSurveyLogicItemState,
} from '@containers/SurveyBuilder.Logic';
import { QuestionDropdown, SectionDropdown } from '@presentation/SurveyBuilder';
import type { SurveyLogic, SurveySection } from '@/types/survey';
import styles from './style/Builder.Action.css';

type SectionDropdownProps = {
  action: SurveyLogic.Action<SurveyActionType.SkipToSection | SurveyActionType.IncludeSection>;
  items: SurveySection[];
};

function ActionSectionDropdown({ action, items }: SectionDropdownProps) {
  const [_, dispatch] = useSurveyLogicItemState();

  const selectedItem = useMemo(() => {
    return items.find(f => f.identifier === action.section.identifier);
  }, [items, action.section.identifier]);

  const handleSelect = useCallback((identifier: string) => {
    dispatch({
      type: 'action-updated',
      payload: {
        section: { identifier },
        type: action.type,
      },
    });
  }, [dispatch, action.type]);

  return (
    <div className={cx(styles.dropdown, styles.choice)}>
      <SectionDropdown
        items={items}
        onSelect={handleSelect}
        value={selectedItem} />
    </div>
  );
}

export function SkipToSectionDropdown() {
  const items = useSkipToSectionItems();
  const [item] = useSurveyLogicItemState();
  const action = item.action as SurveyLogic.Action<SurveyActionType.SkipToSection>;

  if (!items.length) return <div className={styles.none}>No available sections.</div>;

  return (
    <ActionSectionDropdown
      action={action}
      items={items} />
  );
}

export function IncludeSectionDropdown() {
  const items = useIncludeSectionItems();
  const [item] = useSurveyLogicItemState();
  const action = item.action as SurveyLogic.Action<SurveyActionType.IncludeSection>;

  if (!items.length) return <div className={styles.none}>No available sections.</div>;

  return (
    <ActionSectionDropdown
      action={action}
      items={items} />
  );
}

export function ActionQuestionDropdown() {
  const items = useSkipToQuestionItems();

  const [item, dispatch] = useSurveyLogicItemState();
  const action = item.action as SurveyLogic.Action<SurveyActionType.SkipToQuestion>;

  const selectedItem = useMemo(() => {
    return items.find(f => f.base.identifier === action.question.identifier);
  }, [items, action.question.identifier]);

  const handleSelect = useCallback((identifier: string) => {
    dispatch({
      type: 'action-updated',
      payload: {
        question: { identifier },
        type: action.type,
      },
    });
  }, [dispatch, action.type]);

  if (!items.length) return <div className={styles.none}>No available questions.</div>;

  return (
    <div className={cx(styles.dropdown, styles.choice)}>
      <QuestionDropdown
        items={items}
        onSelect={handleSelect}
        value={selectedItem} />
    </div>
  );
}