/* eslint-disable react/jsx-max-props-per-line */
import { memo } from 'react';
import { ProjectIconProps } from './interfaces';

export const CallProjectIcon = memo(({ className, size }: ProjectIconProps) => {

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 25">
      <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
        <g id="Group-5" transform="translate(3.000000, 2.000000)" strokeWidth="2">
          <path d="M0,17.106894 C0.0717019778,16.2764842 0.572005606,15.5438646 1.31937562,15.1748663 L3.46968443,14.1131964 C3.89359769,13.9038982 4.21026381,13.5263609 4.34259388,13.072492 C4.47592257,12.6151981 4.42859644,12.1241689 4.21038401,11.700757 L4.10968392,11.5053621 C3.82016391,10.9435874 3.66910914,10.3207497 3.66910914,9.6887587 L3.66910914,8.37855917 C3.66910914,7.16578985 4.31883944,6.0459705 5.37171668,5.44409101 L5.54030096,5.34771945 C6.48698066,4.80654801 7.6527594,4.82167277 8.58508055,5.38722228 L8.67882994,5.44409101 C9.67498809,6.04836428 10.2833357,7.12891454 10.2833357,8.29402279 L10.2833357,9.70702184 C10.2833357,10.328437 10.1470909,10.9422956 9.88419739,11.5053621 L9.80332823,11.678568 C9.59909443,12.1159969 9.5718413,12.6154376 9.72726555,13.072492 C9.88090164,13.524288 10.225384,13.8857014 10.6692947,14.0608212 L13.2546355,15.0807207 C14.2725914,15.4822974 14.8715214,16.5405926 14.6917249,17.6200235 C14.5138372,18.687994 13.5898671,19.4707918 12.507183,19.4707918 L11.1769747,19.4707918 L11.1769747,19.4707918 L2.36389779,19.4707918 C1.05835309,19.4707918 1.59883114e-16,18.4124387 0,17.106894 L0,17.106894 L0,17.106894 Z" id="Path-9" stroke="#626262" strokeLinejoin="round" />
          <line x1="15.3248706" y1="11.1590655" x2="17.7824235" y2="9.93028907" id="Path-4" stroke="#28AAE0" transform="translate(16.553647, 10.544677) scale(1, -1) rotate(4.000000) translate(-16.553647, -10.544677) " />
          <line x1="13.088114" y1="2.48991869" x2="15.5477888" y2="1.26008131" id="Path-4" stroke="#5E5FA9" transform="translate(14.317951, 1.875000) rotate(-24.000000) translate(-14.317951, -1.875000) " />
          <polyline id="Path-4" stroke="#5977B4" transform="translate(17.339164, 5.648966) rotate(-15.000000) translate(-17.339164, -5.648966) " points="19.5266636 5.64896642 18.0683303 5.64896642 15.1516636 5.64896642" />
        </g>
      </g>
    </svg>
  );
});

export default CallProjectIcon;