import { ConferenceTagType } from '@enums';
import { ProjectConferenceTag } from '@/types';
import { TagFormData } from '@/components/Project.Tags/Context';

const editableTagTypes = [ConferenceTagType.Project, ConferenceTagType.UserCreated];
export const isTagEditable = (tag: ProjectConferenceTag) => {
  return editableTagTypes.includes(tag.base.typeId);
};

export const tagFormIsValid = (form: TagFormData) => {
  return form.title?.length > 3
    && form.title.length < 64
    && form.title.replace(/\s/g, '')?.length > 3;
};