import { useState, useCallback } from 'react';
import { defaultColor } from '@/components/Conference.Tagging/Tagging.ColorSelect';
import { TagFormContext, TagFormData } from '@/components/Project.Tags/Context';

type Props = {
  defaultState?: TagFormData;
} & ChildrenProps;

const DefaultState: TagFormData = {
  title: '',
  parentId: null,
  includeSummary: true,
  color: defaultColor,
};

export const TagFormContainer = ({ children, defaultState = DefaultState }: Props) => {
  const [state, setState] = useState<TagFormData>(defaultState);

  const updateState = useCallback((data: Partial<TagFormData>) => {
    setState(old => ({ ...old, ...data }));
  }, [setState]);

  return (
    <TagFormContext.Provider value={{ data: state, dispatch: updateState }}>
      {children}
    </TagFormContext.Provider>
  );
};