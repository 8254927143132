import type { ApplySchemaAttributes, NodeExtensionSpec, NodeSpecOverride } from '@remirror/core';
import { command, extension, ExtensionTag, NodeExtension, PrimitiveSelection, CommandFunction, getTextSelection } from '@remirror/core';

@extension({
  defaultOptions: {},
})
export class TextReplaceExtension extends NodeExtension {
  get name() {
    return 'correction' as const;
  }

  createTags() {
    return [ExtensionTag.TextBlock];
  }

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    return {
      attrs: {
        s: {},
        e: {},
        spk: {},
        ...extra.defaults(),
      },
      toDOM: node => ['div', {
        ...extra.dom(node),
        contentEditable: 'false',
        selectable: 'false',
      }],
    };
  }

  @command()
  setText(text: string, selection?: PrimitiveSelection): CommandFunction {
    return ({ tr, dispatch, state }) => {
      const { from, to } = getTextSelection(selection ?? tr.selection, tr.doc);
      const node = state.schema.text(text);

      const transaction = tr.replaceRangeWith(from, to, node);

      dispatch(transaction);
      return true;
    };
  }
}