import { CornerDownRight } from 'react-feather';
import { cx } from '@utils';
import styles from './style/Comment.css';

type Props = {
  children:   React.ReactNode;
  className?: string;
  onClick:    () => void;
};

export const ExpandReplies = (props: Props) => {

  return (
    <button
      className={cx(styles.expand, props.className)}
      onClick={props.onClick}>
      <CornerDownRight height={18} />
      {props.children}
    </button>
  );
};

ExpandReplies.displayName = 'Comment.ExpandReplies';