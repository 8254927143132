import { SurveyItemType } from '@enums';
import { SurveyFormItem } from '@/types/survey';
import { useSurveyFormScreenContext } from '../Context';

export function useSurveyFormMessageItem() {
  const [state] = useSurveyFormScreenContext();

  return state.item as SurveyFormItem<SurveyItemType.Message>;

}

export default useSurveyFormMessageItem;