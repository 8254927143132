import { useEffect, useState } from 'react';
import { useProjectOnboardingContext } from '@containers/ProjectOnboarding/hooks';
import * as services from '@services/api';
import { AnimatedLoader } from '@/components/ActivityIndicator';
import { ExternalSurveyError } from './ExternalSurvey.Error';
import styles from './style/ExternalSurvey.css';

type Props = unknown;

enum LinkState {
  Loading,
  Error,
  Missing,
  Loaded
}

export const ExternalSurvey = (props: Props) => {
  const ctx = useProjectOnboardingContext();
  const [state, setState] = useState<LinkState>(LinkState.Loading);
  const [link, setLink] = useState<string>(null);
  useEffect(() => {
    services.projects.external.getExternalLink({ projectId: ctx.projectId }).then(l => {
      if (l.link) {
        setLink(l.link);
        window.location.href = transformLink(l.link);
      } else {
        setState(LinkState.Missing);
      }
    }).catch(e => {
      setState(LinkState.Error);
    }
    );
  }, [ctx.projectId]);

  if (state == LinkState.Missing || state == LinkState.Error) {
    return (
      <ExternalSurveyError />
    );
  } else if (state == LinkState.Loaded) {
    return (
      <div className={styles.centerText}>
        <div>
          You are now being redirected to {link}.
        </div>
      </div>
    );
  }
  return <AnimatedLoader className={styles.loading} />;
};

function transformLink(link: string) {
  if (link.indexOf('://') === -1) {
    return 'https://' + link;
  } else {
    return link;
  }
}