import { forwardRef, memo } from 'react';
import { ChevronRight } from 'react-feather';
import { cx } from '@utils';
import styles from './style/Editor.Piping.Menu.css';

type MenuProps = {
  className?: string;
} & ChildrenProps;

export const Menu = memo(forwardRef<HTMLDivElement, MenuProps>(({
  children,
  className,
}, ref) => {

  return (
    <div
      ref={ref}
      className={cx(styles.menu, className)}>
      {children}
    </div>
  );
}));

type MenuItemProps = {
  active?: boolean;
  hasChildren?: boolean;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const MenuItem = memo(forwardRef<HTMLDivElement, MenuItemProps>(({
  active,
  children,
  hasChildren,
  className,
  ...props
}, ref) => {

  const clazz = cx(styles.item, className, {
    [styles.active]: active,
  });

  return (
    <div
      ref={ref}
      className={clazz}
      {...props}>
      {children}
      {hasChildren && <ChevronRight className={styles.icon} />}
    </div>
  );
}));