import http from '@services/http';
import * as xform from '@transformers';
import { transformDates } from '@services/http/transformers';
import type { WorkspaceFiles as WS } from './interfaces';

export const deleteFile = (data: WS.DeleteFile.Request) => {
  return http.delete(`/workspaces/${data.workspaceId}/files/${data.fileId}`);
};

export const downloadFile = (data: WS.DownloadFile.Request): Promise<WS.DownloadFile.Response> => {
  return http.get(`/workspaces/${data.workspaceId}/files/${data.fileId}/download`);
};

export const renameFile = (data: WS.RenameFile.Request) => {
  return http.patch(`/workspaces/${data.workspaceId}/files/${data.fileId}/name`, {
    name: data.name,
  }).then((data: WS.RenameFile.Response) => ({
    file: xform.normalizeFile(data.file),
    object: xform.normalizeWorkspaceObject(data.object),
  }));
};

export const getFilePreview = (data: WS.GetFilePreview.Request): Promise<WS.GetFilePreview.Response> => {
  return http.get(`/workspaces/file-preview/${data.fileId}`, {
    transformResponse: transformDates,
  });
};

export const getFileSummary = (data: WS.GetFileSummary.Request): Promise<WS.GetFileSummary.Response> => {
  return http.get<WS.GetFileSummary.Response>(`/workspaces/file-summary/${data.fileId}`);
};

export const regenerateFileSummary = (data: WS.RegenerateFileSummary.Request): Promise<WS.RegenerateFileSummary.Response> => {
  return http.post(`/workspaces/file-summary/${data.fileId}`, {});
};

export const downloadFileSummary = (data: WS.RegenerateFileSummary.Request) => {
  return http.download(`/workspaces/file-summary/${data.fileId}/download`);
};

export const getFileProjects = (data: WS.GetFileProjects.Request): Promise<WS.GetFileProjects.Response> => {
  return http.get(`/workspaces/${data.workspaceId}/files/${data.fileId}/projects`);
};

export const saveFileProjects = ({ fileId, workspaceId, ...body }: WS.SaveFileProjects.Request): Promise<WS.SaveFileProjects.Response> => {
  return http.post(`/workspaces/${workspaceId}/files/${fileId}/projects`, body);
};

export const transcribeFile = ({ fileId, workspaceId }: WS.TranscribeFile.Request) => {
  return http.post(`/workspaces/${workspaceId}/files/${fileId}/transcribe`, {});
};

export const parseIntoTranscript = ({ fileId, workspaceId }: WS.TranscribeFile.Request) => {
  return http.post(`/workspaces/${workspaceId}/files/${fileId}/parse-transcript`, {});
};

export const bookmarkObject = (data: WS.BookmarkObject.Request) => {
  return http.patch(`/workspaces/${data.workspaceId}/objects/${data.objectId}/bookmark`, data)
    .then((response: WS.BookmarkObject.Response) => {
      return { bookmarked: response.bookmarked };
    });
};

export const updatePublishedOn = (data: WS.UpdatePublishedOn.Request) => {
  return http.patch(`/workspaces/${data.workspaceId}/files/${data.fileId}/published-on`, {
    publishedOn: data.publishedOn,
  }).then((data: WS.UpdatePublishedOn.Response) => ({
    file: xform.normalizeFile(data.file),
    object: xform.normalizeWorkspaceObject(data.object),
  }));
};

export const getFileCitations = (data: WS.GetFileCitations.Request): Promise<WS.GetFileCitations.Response> => {
  return http.get(`/workspaces/${data.workspaceId}/files/${data.fileId}/citations`);
};