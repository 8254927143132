import { useContext } from 'react';
import { SocialAuthContext } from '$website/containers/Auth/Context';

export const useSocialAuth = () => {
  const context = useContext(SocialAuthContext);

  if (!context && process.env.__DEV__) {
    throw new Error('`useSocialAuth` must be wrapped inside a `SocialAuthContainer`');
  }

  return context;
};