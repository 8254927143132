import { useCallback } from 'react';
import { useSurveyBuilderState, useSubmitSurveyDraft } from '@containers/SurveyBuilder/hooks';
import { useToggle } from '@utils/hooks';
import { useSurveyBuilderItem } from '@containers/SurveyBuilder/Context';
import { DeleteQuestionAlert } from '@/components/SurveyBuilder.Question/DeleteQuestionAlert';
import { QuestionBuilderDeleteContext, useQuestionBuilderItemContext } from './Context';

type Props =
  ChildrenProps;

export const QuestionBuilderDeleteContainer = (props: Props) => {

  const [_, dispatch] = useSurveyBuilderState();

  const submitDraft = useSubmitSurveyDraft();
  const question = useQuestionBuilderItemContext();
  const item = useSurveyBuilderItem();

  const [showAlert, toggleAlert] = useToggle(false);

  const confirmDelete = useCallback(() => {
    dispatch({
      type: 'question-removed',
      itemIdentifier: item.identifier,
      questionIdentifier: question.base.identifier,
    });
    submitDraft();
    toggleAlert();
  }, [
    dispatch,
    toggleAlert,
    question.base.identifier,
    item.identifier,
    submitDraft,
  ]);

  return (
    <>
      <QuestionBuilderDeleteContext.Provider value={toggleAlert}>
        {props.children}
      </QuestionBuilderDeleteContext.Provider>
      {showAlert &&
        <DeleteQuestionAlert
          open={true}
          onConfirm={confirmDelete}
          onClose={toggleAlert} />
      }
    </>
  );
};