import { useEffect } from 'react';
import {
  useAudioPlayerRef,
  useAudioPlayerScan,
  useAudioPlayerState,
  useBootstrapAudioPlayer,
  useToggleAudioPlaying,
} from '@containers/AudioPlayer';

export const useKeyboardShortcuts = () => {
  const [player] = useAudioPlayerRef();
  const togglePlaying = useToggleAudioPlaying();
  const { seek } = useAudioPlayerScan();
  useEffect(() => {
    if (player) {
      const onKeyPress = (e: KeyboardEvent) => {
        const key = e.key;

        const source = e.target as HTMLElement;

        if (tagSourceToExclude.includes(source?.tagName?.toLowerCase())) return;

        switch (key) {
          case 'l': {
            seek(player.currentTime + 10);
            break;
          }
          case 'j': {
            seek(player.currentTime - 10);
            break;
          }
          case ' ':
          case 'k': {
            togglePlaying();
            break;
          }
        }
      };

      document.body.addEventListener('keydown', onKeyPress);

      return () => document.body.removeEventListener('keydown', onKeyPress);
    }
  }, [seek, togglePlaying, player]);
};

const tagSourceToExclude = ['input', 'textarea'];