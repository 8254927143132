import { useCallback, useEffect, useRef, useState } from 'react';

type Props = {
  disabled?: boolean;
  onDrop: (files: File[]) => void;
};

export const useFileDragDrop = ({
  disabled,
  onDrop,
}: Props) => {
  const [dragging, setDragging] = useState<boolean>(false);

  const dragCountRef = useRef<number>(0);
  const dropRef = useRef<HTMLDivElement>();

  const handleDragOver = useCallback((e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback((e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    onDrop(Array.from(e.dataTransfer?.files));

    dragCountRef.current = 0;
    setDragging(false);
  }, [
    onDrop,
  ]);

  const handleDragEnter = useCallback((e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    dragCountRef.current = dragCountRef.current + 1;

    setDragging(true);
  }, []);

  const handleDragLeave = useCallback((e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    dragCountRef.current = dragCountRef.current - 1;

    if (!dragCountRef.current) {
      setDragging(false);
    }
  }, []);

  useEffect(() => {
    const drop = dropRef.current;

    if (!disabled) {
      drop?.addEventListener('dragover', handleDragOver);
      drop?.addEventListener('drop', handleDrop);
      drop?.addEventListener('dragenter', handleDragEnter);
      drop?.addEventListener('dragleave', handleDragLeave);
    }

    return () => {
      drop?.removeEventListener('dragover', handleDragOver);
      drop?.removeEventListener('drop', handleDrop);
      drop?.removeEventListener('dragenter', handleDragEnter);
      drop?.removeEventListener('dragleave', handleDragLeave);
    };

  }, [
    disabled,
    handleDragEnter,
    handleDragLeave,
    handleDragOver,
    handleDrop,
  ]);

  return {
    dragging,
    dropRef,
  } as const;
};

export default useFileDragDrop;