import { useContext } from 'react';
import { createNamedContext } from '@utils';
import { SurveyItem, SurveySection } from '@/types';

type DeleteSection = () => void;

type SurveyBuilderSectionContextValue = {
  canAddAIE: boolean;
  canAddQuestion: boolean;
  canAddMessage: boolean;
  items: SurveyItem[];
  lastOrdinal: number;
  section: SurveySection;
};

export const DeleteSurveySectionContext = createNamedContext<DeleteSection>(undefined, 'DeleteSurveySectionContext');
export const SurveySectionBuilderContext = createNamedContext<SurveyBuilderSectionContextValue>(undefined, 'SurveySectionBuilderContext');

export const useDeleteSurveySection = () => useContext(DeleteSurveySectionContext);
export const useSurveySectionBuilderContext = () => useContext(SurveySectionBuilderContext);