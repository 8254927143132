import { useContext, useEffect } from 'react';
import { Route, RouteProps, useRouteMatch } from 'react-router-dom';
import { StepSubscriberContext } from './Context';

type Props = {
  path: string;
} & Omit<RouteProps, 'path'>;

const Step = (props: Props) => {
  const ctx = useContext(StepSubscriberContext);
  const match = useRouteMatch(props.path);

  useEffect(() => {

    ctx.register(props.path);

    return () => ctx.unregister(props.path);

  }, [
    ctx,
    props.path,
  ]);

  useEffect(() => {

    if (match?.isExact) {
      ctx.setFocused(props.path);
    }

  }, [
    match,
    props.path,
    ctx,
  ]);

  return (
    <Route {...props} />
  );
};

export { Step };
export default Step;