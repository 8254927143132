import { ControlledTablePagination } from '@presentation/TablePagination';
import { usePagination } from './hooks/usePagination';
import styles from './style/Members.css';

type Props = {
  pageCount: number;
  totalCount: number;
  className?: string;
};

export const ResultsPagination = ({
  pageCount,
  totalCount,
  className = styles.pagination,
}: Props) => {
  const pagination = usePagination();

  return (
    <ControlledTablePagination
      className={className}
      goToPage={pagination.goToPage}
      pageCount={pageCount ?? 0}
      pageIndex={pagination.pageIndex}
      pageSize={pagination.pageSize}
      totalCount={totalCount ?? 0} />
  );
};