import { useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ProjectAction, ProjectType, WorkspaceObjectType } from '@enums';
import { MoveObjectContainer, ObjectOwnershipContainer } from '@containers/WorkspaceObject';
import { useHasSchedulingPermission, useIsInternalUser } from '@containers/Store/hooks';
import { getLocationFor, useToggle } from '@utils';
import type { Project, ProjectCapabilities, ProjectParent, WorkspaceObject } from '@/types';
import { useProjectResponseGridModal, useProjectConferenceTagSummariesModal } from '@/components/Project.ConferenceTags.Overview';
import { useProjectReportModal } from '@/components/GroupProject/ProjectReportModal';
import { useUploadQuantAnalysisFileModal } from '@/components/Project.QuantAnalysis';
import { useExportCallModal, useExportSurveyModal } from '@/components/GroupProject/ExportProjectDataModal';
import { useCloneProjectPrompt } from '@/components/GroupProject/hooks/useCloneProjectPrompt';
import { useExportProjectHighlightsModal } from '@/components/GroupProject/ExportProjectHighlightsModal';
import { useShareWithExpertModal } from '@/components/Modal/ShareProjectWithExpert/useShareWithExpertModal';
import { MoveObjectModal } from '@/components/WorkspaceObject';
import { useObjectOwnershipModal } from '@/components/WorkspaceObject/hooks';
import { useExportProjectInsightsModal } from '@/components/Project.Insights';
import { useDisplayrDashboardModal } from '@/components/Displayr';
import { RespondentSetupContext } from '$admin/components/Scheduling/Context';
import * as CallScheduling from '$admin/components/Scheduling';
import type { ProjectActionsContextValue } from './Context';
import {
  ProjectActionsContext,
  ProjectActionsValidityContext,
  ProjectDisplayrDashboardIdContext,
} from './Context';
import { useActivateGroupProjectPrompt, useArchiveGroupProjectPrompt, useProjectActionsValidity } from './hooks';
import * as utils from './utils';

const selectWorkspacesEnabled = (state: Store.State) => state.group.features.workspaces;

type ProjectMinimumProps =
  Pick<Project,
    | 'id'
    | 'isExternal'
    | 'name'
    | 'parentId'
    | 'projectStatusId'
    | 'projectType'>;

type ValidityProps = {
  children: React.ReactNode;
  capabilities: ProjectCapabilities;
  parent: Pick<ProjectParent, 'frequency'>;
  project: ProjectMinimumProps;
};

type Props = {
  children: React.ReactNode;
  actionOnParent?: boolean;
  capabilities: ProjectCapabilities;
  parent: Pick<ProjectParent, 'frequency'>;
  project: ProjectMinimumProps;
  object: WorkspaceObject;
};

export const ProjectActionsValidityContainer = ({ capabilities, children, parent, project }: ValidityProps) => {
  const canSchedule = useHasSchedulingPermission();
  const worskpacesEnabled = useSelector(selectWorkspacesEnabled);
  const isInternalUser = useIsInternalUser();

  const value: Record<ProjectAction, boolean> = useMemo(() => ({
    [ProjectAction.Activate]: utils.canActivate(project) && capabilities.canManage && isInternalUser,
    [ProjectAction.Archive]: utils.canArchive(project) && capabilities.canManage && isInternalUser,
    [ProjectAction.ChangeOwner]: capabilities.canChangeOwner,
    [ProjectAction.Clone]: utils.canClone(parent) && capabilities.canManage && isInternalUser,
    [ProjectAction.CreateCall]: utils.isCallLike(project) && canSchedule && isInternalUser,
    [ProjectAction.DownloadCallReport]: utils.canDownload(project) && utils.isCallLike(project) && isInternalUser,
    [ProjectAction.DownloadHighlightsReport]: utils.isCallLike(project),
    [ProjectAction.DownloadInsightsReport]: utils.isCallLike(project),
    [ProjectAction.DownloadTagSummariesReport]: utils.isCallLike(project) && isInternalUser,
    [ProjectAction.DownloadResponseGrid]: utils.isCallLike(project) && isInternalUser,
    [ProjectAction.DownloadReport]: utils.canDownload(project),
    [ProjectAction.DownloadSurveyReport]: utils.canDownload(project) && utils.isSurveyLike(project),
    [ProjectAction.EditSurvey]: utils.canEditSurvey(project) && capabilities.canManage && isInternalUser,
    [ProjectAction.ManageAccess]: utils.canManage(project) && capabilities.canManageAccess,
    [ProjectAction.MonitorSurvey]: utils.canMonitorSurvey(project) && isInternalUser,
    [ProjectAction.Move]: worskpacesEnabled && capabilities.canMove,
    [ProjectAction.Share]: utils.canShare(project) && isInternalUser,
    [ProjectAction.Preview]: false, //utils.canPreview(project) && isInternalUser,
    [ProjectAction.ManageDisplayrDashboard]: capabilities.canManage && utils.isSurveyLike(project) && isInternalUser,
    [ProjectAction.ManageSurveySettings]: utils.canManage(project) && utils.isSurveyLike(project) && capabilities.canManage && isInternalUser,
    [ProjectAction.UploadQuantAnalysisFile]: isInternalUser && project.projectType === ProjectType.Survey,
  }), [
    capabilities,
    canSchedule,
    isInternalUser,
    parent,
    project,
    worskpacesEnabled,
  ]);

  return (
    <ProjectActionsValidityContext.Provider value={value}>
      {children}
    </ProjectActionsValidityContext.Provider>
  );
};

export const ProjectActionsContainer = ({ actionOnParent, project, children, object }: Omit<Props, 'capabilities'>) => {

  const respondent = useContext(RespondentSetupContext);

  const validity = useProjectActionsValidity();

  const history = useHistory();

  const [toggleActivatePrompt, ActivateProjectPrompt] = useActivateGroupProjectPrompt({ project });
  const [toggleArchivePrompt, ArchiveProjectPrompt] = useArchiveGroupProjectPrompt({ project });
  const [toggleClonePrompt, CloneProjectPrompt] = useCloneProjectPrompt({ project });
  const [toggleShareModal, ShareWithExpertModal] = useShareWithExpertModal({ project: project });
  const [toggleOwnershipModal, OwnershipModal] = useObjectOwnershipModal();
  const [toggleReportModal, ReportModal] = useProjectReportModal();
  const [toggleCallReportModal, CallReportModal] = useExportCallModal();
  const [toggleSurveyReportModal, SurveyReportModal] = useExportSurveyModal();
  const [toggleExportHighlightsModal, ExportHighlightsModal] = useExportProjectHighlightsModal();
  const [toggleTagSummariesModal, TagSummariesModal] = useProjectConferenceTagSummariesModal();
  const [toggleResponseGridModal, ResponseGridModal] = useProjectResponseGridModal();
  const [toggleInsightsModal, InsightsModal] = useExportProjectInsightsModal();
  const [moveObjectModalOpen, toggleMoveObjectModal] = useToggle();
  const [toggleDisplayrDashboardModal, DisplayrDashboardModal] = useDisplayrDashboardModal();

  const displayrDashboardId = useContext(ProjectDisplayrDashboardIdContext);

  const toggleCallScheduling = useCallback(() => {
    respondent.setContext({ projectId: project.id });
  }, [
    respondent,
    project,
  ]);

  const editSurvey = useCallback(() => {
    history.push(getLocationFor.project.surveys({ slug: String(project.id) }));
  }, [
    history,
    project.id,
  ]);

  const monitorSurvey = useCallback(() => {
    history.push(getLocationFor.project.surveyMonitoring({ slug: String(project.id) }));
  }, [
    history,
    project.id,
  ]);

  const manageSurveySettings = useCallback(() => {
    history.push(getLocationFor.project.surveySettings({ projectId: project.id }));
  }, [history, project.id]);

  const [toggleQuantAnalysisFileUpload, UploadQuantAnalysisFileModal] = useUploadQuantAnalysisFileModal();

  const value: ProjectActionsContextValue = useMemo(() => ({
    [ProjectAction.Activate]: toggleActivatePrompt,
    [ProjectAction.Archive]: toggleArchivePrompt,
    [ProjectAction.ChangeOwner]: toggleOwnershipModal,
    [ProjectAction.Clone]: toggleClonePrompt,
    [ProjectAction.CreateCall]: toggleCallScheduling,
    [ProjectAction.EditSurvey]: editSurvey,
    [ProjectAction.DownloadCallReport]: toggleCallReportModal,
    [ProjectAction.DownloadHighlightsReport]: toggleExportHighlightsModal,
    [ProjectAction.DownloadInsightsReport]: toggleInsightsModal,
    [ProjectAction.DownloadSurveyReport]: toggleSurveyReportModal,
    [ProjectAction.DownloadReport]: toggleReportModal,
    [ProjectAction.DownloadTagSummariesReport]: toggleTagSummariesModal,
    [ProjectAction.DownloadResponseGrid]: toggleResponseGridModal,
    [ProjectAction.Move]: toggleMoveObjectModal,
    [ProjectAction.Share]: toggleShareModal,
    [ProjectAction.MonitorSurvey]: monitorSurvey,
    [ProjectAction.ManageDisplayrDashboard]: toggleDisplayrDashboardModal,
    [ProjectAction.ManageAccess]: null,
    [ProjectAction.Preview]: null,
    [ProjectAction.ManageSurveySettings]: manageSurveySettings,
    [ProjectAction.UploadQuantAnalysisFile]: toggleQuantAnalysisFileUpload,
    projectId: project.id,
  }), [
    editSurvey,
    toggleActivatePrompt,
    toggleArchivePrompt,
    toggleCallReportModal,
    toggleCallScheduling,
    toggleClonePrompt,
    toggleExportHighlightsModal,
    toggleMoveObjectModal,
    toggleOwnershipModal,
    toggleTagSummariesModal,
    toggleResponseGridModal,
    toggleReportModal,
    toggleShareModal,
    toggleSurveyReportModal,
    toggleInsightsModal,
    toggleDisplayrDashboardModal,
    toggleQuantAnalysisFileUpload,
    project.id,
    monitorSurvey,
    manageSurveySettings,
  ]);

  return (
    <>
      <ProjectActionsContext.Provider value={value}>
        {children}
      </ProjectActionsContext.Provider>
      <ReportModal
        isParentReport={actionOnParent}
        project={project} />
      <CallReportModal
        isParentReport={actionOnParent}
        project={project} />
      <SurveyReportModal
        isParentReport={actionOnParent}
        surveyId={null} //TODO: fill this in with either a selected survey or the known primary survey
        project={project} />
      {validity[ProjectAction.UploadQuantAnalysisFile] && (
        <UploadQuantAnalysisFileModal projectId={project.id} />
      )}
      {validity[ProjectAction.DownloadHighlightsReport] && <ExportHighlightsModal projectId={project.id} />}
      {validity[ProjectAction.DownloadResponseGrid] && <ResponseGridModal projectId={project.id} />}
      {validity[ProjectAction.DownloadTagSummariesReport] && <TagSummariesModal projectId={project.id} />}
      {validity[ProjectAction.Archive] && <ArchiveProjectPrompt />}
      {validity[ProjectAction.Activate] && <ActivateProjectPrompt />}
      {validity[ProjectAction.Clone] && <CloneProjectPrompt />}
      {validity[ProjectAction.Share] && <ShareWithExpertModal />}
      {validity[ProjectAction.DownloadInsightsReport] && <InsightsModal projectId={project.id} />}
      {
        validity[ProjectAction.ManageDisplayrDashboard] && (
          <DisplayrDashboardModal
            initialDashboardId={displayrDashboardId}
            projectId={project.id} />
        )
      }
      {
        validity[ProjectAction.ChangeOwner] &&
        <ObjectOwnershipContainer object={object} workspaceId={object.workspaceId}>
          <OwnershipModal />
        </ObjectOwnershipContainer>
      }
      {
        validity[ProjectAction.Move] &&
        <MoveObjectContainer
          enabled={moveObjectModalOpen}
          objectId={object.id}
          objectType={WorkspaceObjectType.ProjectParent}>
          <MoveObjectModal
            open={moveObjectModalOpen}
            onClose={toggleMoveObjectModal} />
        </MoveObjectContainer>
      }
    </>
  );
};

export const ProjectActionsState = ({ children, ...props }: Props) => {
  return (
    <ProjectActionsValidityContainer {...props}>
      <CallScheduling.Container>
        <ProjectActionsContainer {...props}>
          {children}
        </ProjectActionsContainer>
      </CallScheduling.Container>
    </ProjectActionsValidityContainer>
  );
};

export default ProjectActionsContainer;