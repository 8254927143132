import * as types from '@store/action-types';
import {
  ContactsAddedAction,
  ContactsAddedState,
} from '@store/interfaces';

export const contactsAdded = (data: ContactsAddedState): ContactsAddedAction => {
  return {
    type: types.CONTACTS_ADDED,
    contacts: data.contacts,
  };
};