import { toSingleElementArray } from '@transformers/helpers';
import { Product, VICS } from '@/types';

export const setIndustryFields = (items: VICS[]) => {
  if (!items.length) {
    return [];
  }

  const industry = {
    id: 'industry',
    vics: items,
  };

  return toSingleElementArray(industry);
};

export function setProductFields(items: Product[]) {
  if (!items.length) {
    return [];
  }

  const product = {
    id: 'product',
    products: items,
  };

  return toSingleElementArray(product);
}