import { UGCImpressionType, UGCViewType } from '@enums';
import http, { xhr } from '@services/http';

namespace CreateViewEvent {
  export type Request = {
    itemId: number;
    typeId: UGCViewType;
  };
}

export const createViewEvent = (data: CreateViewEvent.Request) => {
  return xhr.post<void>(`/posts/analytics/views`, data);
};

namespace CreateImpressionEvent {
  export type Request = {
    itemId: number;
    typeId: UGCImpressionType;
  };
}

export const createImpressionEvent = (data: CreateImpressionEvent.Request): Promise<void> => {
  return http.post(`/posts/analytics/impressions`, data);
};