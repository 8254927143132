import { useCallback, useState, useMemo } from 'react';
import { Button } from '@/components/Button';
import { Input } from '@/components/Input';
import { Modal } from '@/components/Modal/Modal';
import { useModal } from '@/components/Modal/hooks';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { Header } from '@/components/Modal/Header';
import { WorkspaceSelect } from '@/components/Workspace/WorkspaceSelect';
import { useCreateFolder } from '@/containers/Workspace.Folder/hooks/useCreateFolder';
import { useLock, useMounted } from '@/utils';
import type { Workspace } from '@/types';
import type { NewFolderModalProps, CreateFolderInsideParentModalProps } from './interfaces';
import styles from './style/NewFolderModal.css';

type Props =
  NewFolderModalProps;

export const CreateFolderInsideParentModal = ({
  onClose,
  onSuccess,
  open,
  parentObject,
}: CreateFolderInsideParentModalProps) => {
  const [name, setName] = useState<string>('');

  const { isLoading, mutateAsync } = useCreateFolder({
    onSuccess: res => {
      onSuccess?.(res);
      onClose();
    },
  });

  const canSave = useMemo(() => {
    return !!name || isLoading;
  }, [name, isLoading]);

  const handleNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  const handleSave = useCallback(() => {
    return mutateAsync({
      name,
      parentObjectId: parentObject.id,
      workspaceId: parentObject.workspaceId,
    });
  }, [mutateAsync, name, parentObject.id, parentObject.workspaceId]);

  return (
    <Modal onClose={onClose} open={open}>
      <Header
        className={styles.header}
        text="New Folder" />
      <div className={styles.body}>
        <div className={styles.label}>Title</div>
        <div className={styles.input}>
          <Input
            name="folder-name"
            onChange={handleNameChange}
            value={name} />
        </div>
        {/* <div className={styles.label}>Location</div>
        {!!parentObject && location} */}
      </div>
      <ButtonSet className={styles.footer}>
        <Button.Destructive
          className={styles.btn}
          onClick={onClose}
          variant="brick">
          Cancel
        </Button.Destructive>
        <Button.Affirmative
          className={styles.btn}
          disabled={!canSave}
          onClick={handleSave}
          variant="brick">
          Create
        </Button.Affirmative>
      </ButtonSet>
    </Modal>
  );
};

export const useCreateFolderInsideParentModal = () => useModal(CreateFolderInsideParentModal);

export const NewFolderModal = ({
  location,
  onConfirm,
  onClose,
  open,
  parentObject,
  workspaces,
}: Props) => {
  const [name, setName] = useState<string>('');
  const [workspace, setWorkspace] = useState<Workspace>(workspaces[0]);
  const [busy, lock] = useLock();
  const isMounted = useMounted();

  const handleSave = useCallback(() => {
    const params = parentObject
      ? {
        parentObjectId: parentObject.id,
        workspaceId: parentObject.workspaceId,
      } : {
        parentObjectId: workspace.objectId,
        workspaceId: workspace.id,
      };

    return onConfirm({
      ...params,
      name,
    }).then(_ => {
      if (isMounted()) {
        onClose();
      }
    });
  }, [
    isMounted,
    name,
    onClose,
    onConfirm,
    parentObject,
    workspace,
  ]);

  const canSave = useMemo(() => {
    return (!!parentObject ||
      !!workspace) && name;
  }, [
    name,
    parentObject,
    workspace,
  ]);

  const Footer = useCallback(() => {

    return (
      <ButtonSet className={styles.footer}>
        <Button.Destructive
          className={styles.btn}
          onClick={onClose}
          variant="brick">
          Cancel
        </Button.Destructive>
        <Button.Affirmative
          className={styles.btn}
          disabled={!canSave}
          onClick={lock(handleSave)}
          variant="brick">
          Create
        </Button.Affirmative>
      </ButtonSet>
    );
  }, [
    canSave,
    lock,
    handleSave,
    onClose,
  ]);

  const handleNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  return (
    <Modal onClose={onClose} open={open}>
      <Header
        className={styles.header}
        text="New Folder" />
      <div className={styles.body}>
        <div className={styles.label}>Title</div>
        <div className={styles.input}>
          <Input
            name="folder-name"
            onChange={handleNameChange}
            value={name} />
        </div>
        <div className={styles.label}>Location</div>
        {!!parentObject && location}
        {!parentObject &&
          <WorkspaceSelect
            className={styles.select}
            onChange={setWorkspace}
            options={workspaces}
            value={workspace} />
        }
      </div>
      <Footer />
    </Modal>
  );
};

export default NewFolderModal;