import { usePositioner } from '@remirror/react';
import type { Positioner } from '@remirror/extension-positioner';
import { selectionPositioner, hasStateChanged } from '@remirror/extension-positioner';
import { usePreviousDistinct } from '@utils/hooks';
import type { TranscriptHelpers } from '../interfaces';
import { useHelpers } from './useHelpers';

export const useHighlightTooltipPositioner = () => {

  const {
    isHighlightFocused,
  } = useHelpers();
  const focused = isHighlightFocused();
  const previousFocused = usePreviousDistinct(focused);

  const position = usePositioner<{ from: Coords; to: Coords }>((): Positioner<{ from: Coords; to: Coords }> => {
    return selectionPositioner.clone({
      hasChanged: props => {
        return hasStateChanged(props) || previousFocused !== focused;
      },
      getActive: ({ helpers, view }) => {
        const highlight = (helpers as TranscriptHelpers.Helpers).getFocusedHighlight();

        if (!highlight?.showTooltip || highlight?.derived) {
          return [];
        }

        const from = view.coordsAtPos(highlight.from);
        const to = view.coordsAtPos(highlight.to);

        return [{ from, to }];
      },
    });

  }, [
    focused,
    previousFocused,
  ]);

  return position;
};

type Coords = {
  left: number;
  right: number;
  top: number;
  bottom: number;
};