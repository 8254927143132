import type { CSSProperties } from 'react';
import { useMemo } from 'react';
import type { MatrixRangeQuestion } from '@/types';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import { InfoTooltip } from '@presentation/InfoTooltip';
import { Tooltip } from '@/presentation/Tooltip';
import { useProjectSurveyResponsesState, useSurveyQuestionResponsesData } from '@/containers/SurveyResponses/hooks';
import type { SurveyQuestionType } from '@/enums';
import { roundToPlace } from '@/utils';
import styles from './style/MatrixRange.Chart.css';

type RowProps = {
  range: {
    left: number;
    right: number;
  };
  row: MatrixRangeQuestion.Row;
  data: MatrixRangeQuestion.Aggregate.RowResult;
  onClick: () => unknown;
};

const TickWidth = 10;

export const MatrixRangeRow = ({ data, onClick, row, range }: RowProps) => {

  const tickStyles: CSSProperties = useMemo(() => {

    const pct = ((range.right - data.avg) / (range.right - range.left) * 100);

    const right = `calc(${pct}% + ${TickWidth / 2}px)`;

    return {
      position: 'absolute',
      width: `${TickWidth}px`,
      right,
      top: 0,
    };
  }, [data.avg, range]);

  const roundedAvg = roundToPlace(data.avg, 1);

  return (
    <div className={styles.rowData}>
      <div className={styles.rowName}>{parseSurveyRichText(row.value)}</div>
      <div className={styles.spectrumRow}>
        <InfoTooltip text={parseSurveyRichText(row.metadata.leftStatement)} />
        <div className={styles.spectrum} onClick={onClick}>
          <div className={styles.tick} style={tickStyles} />
        </div>
        <InfoTooltip text={parseSurveyRichText(row.metadata.rightStatement)} />
      </div>
      <div className={styles.rowAvg}>(Avg. {roundedAvg})</div>
    </div>
  );
};

export const MatrixRangeChart = () => {

  const { question, responses } = useSurveyQuestionResponsesData<SurveyQuestionType.MatrixRange>();

  const { setQueryState } = useProjectSurveyResponsesState<SurveyQuestionType.MatrixRange>();

  const range = useMemo(() => {
    const values = question.options.map(m => +parseSurveyRichText(m.value));

    const left = values[0];
    const right = values[values.length - 1];

    return { left, right };
  }, [question.options]);

  return (
    <div className={styles.root}>
      {question.matrixRows.map(row => {
        const data = responses[row.base.id];
        return (
          <MatrixRangeRow
            key={row.base.id}
            onClick={() => setQueryState({ rowId: row.base.id, optionId: null })}
            range={range}
            row={row}
            data={data} />
        );
      })}
    </div>
  );
};