import { TranscriptSidebarPositionsContainer } from '@containers/Transcript/Sidebar.Positions';
import { TranscriptCommentingFocusContainer } from './Commenting.Focus.Container';
import { TranscriptCommentingStateContainer } from './Commenting.State.Container';

type Props = {
  children: React.ReactNode;
};

export const TranscriptCommentingContainer = (props: Props) => {
  return (
    <TranscriptCommentingStateContainer>
      <TranscriptCommentingFocusContainer>
        <TranscriptSidebarPositionsContainer>
          {props.children}
        </TranscriptSidebarPositionsContainer>
      </TranscriptCommentingFocusContainer>
    </TranscriptCommentingStateContainer>
  );
};

TranscriptCommentingContainer.displayName = 'Transcript.Commenting.Container';