import { Fragment, useCallback, memo } from 'react';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import Popper from '@mui/material/Popper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import { utils as enumUtils } from '@enums';
import { useCoordinator, useConferenceInstance, IConference } from '@containers/Conference';
import { ModeratedParticipantName, useVisibleParticipants } from '@screens/Conference.Common';
import { MoreHorizontalAnchor } from '@presentation/Anchor';
import { Participant as LiveParticipant } from '@/types/conferences.live';
import { PopperMenu, PopperMenuItem } from '@/components/Popper';
import { UserAvatar } from '@/components/UserAvatar';
import Toast from '@/components/Toast';
import { useYourParticipant, useHostCount } from './hooks';
import styles from './style/Bar.Right.Participants.css';

export function BarRightParticipantsInConference() {
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom>();
  const you = useYourParticipant();
  const hostCount = useHostCount();
  const visibleParticipants = useVisibleParticipants();

  const participants = visibleParticipants.filter(p => p.status === 'meeting-room');

  if (!you.isHost) {
    return (
      <Fragment>
        {participants.map(liveParticipant => {
          return (
            <InConferenceParticipant
              key={liveParticipant.id}
              isYou={you.id === liveParticipant.id}
              participant={liveParticipant} />
          );
        })}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className={styles.subHeader}>
        In Conference
      </div>
      {participants.map(liveParticipant => {
        return (
          <InConferenceParticipant
            key={liveParticipant.id}
            isYou={you.id === liveParticipant.id}
            participant={liveParticipant}
            hostCount={hostCount}
            hostAbilities />
        );
      })}
    </Fragment>
  );
}

type InConferenceParticipantProps = {
  isYou: boolean;
  participant: LiveParticipant;
  hostAbilities?: boolean;
  hostCount?: number;
};

const InConferenceParticipant = memo(({ isYou, participant, hostCount = 1, hostAbilities = false }: InConferenceParticipantProps) => {
  const type = participant.status === 'waiting-room'
    ? ''
    : participant.isHost
      ? ` (${hostCount > 1 ? 'Co-Host' : 'Host'})`
      : `${enumUtils.CallRole.getName(participant.role) ?? 'Unknown'}`;

  const name = `${participant.name}${type}`;

  if (!hostAbilities) {
    return (
      <div className={styles.row}>
        <UserAvatar
          className={styles.avatar}
          pictureUrl={participant.auth === 'authorized-member' || participant.auth === 'outside-member' ? participant.pictureUrl : null}
          size={30} />
        <div className={styles.details}>
          <div className={styles.name}>
            {name}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.row}>
      <UserAvatar
        className={styles.avatar}
        pictureUrl={participant.auth === 'authorized-member' || participant.auth === 'outside-member' ? participant.pictureUrl : null}
        size={30} />
      <div className={styles.details}>
        <div className={styles.name}>
          <ModeratedParticipantName participant={participant} displayName={name} />
        </div>
        <div className={styles.actions}>
          <div className={styles.micPlaceholder} />
          <InConferenceParticipantPopper
            participant={participant}
            canMakeHost={!participant.isHost}
            canRemove={!isYou} />
        </div>
      </div>
    </div>
  );
});

type InConferenceParticipantPopperProps = {
  participant: LiveParticipant;
  canMakeHost: boolean;
  canRemove: boolean;
};

function InConferenceParticipantPopper({ participant, canMakeHost, canRemove }: InConferenceParticipantPopperProps) {
  const { giveHost, removeParticipant } = useCoordinator();

  const handleGiveHost = useCallback((close: () => void) => () => {
    giveHost({
      conferenceIdentifier: participant.conferenceIdentifier,
      pid: participant.id,
      selfKeepHost: true,
    });
    Toast.success({
      title: `You've given host to ${participant.name}`,
    });
    close();
  }, [giveHost, participant]);

  const handleRemove = useCallback((close: () => void) => () => {
    removeParticipant({
      conferenceIdentifier: participant.conferenceIdentifier,
      pid: participant.id,
    });
    Toast.success({
      title: `You've removed ${participant.name} from the conference`,
    });
    close();
  }, [removeParticipant, participant]);

  if (!canMakeHost && !canRemove) return <div className={styles.popperPlaceholder} />;

  return (
    <PopupState
      variant="popper"
      popupId="participant-actions-popper">
      {popupState => (
        <div>
          <div {...bindToggle(popupState)}>
            <MoreHorizontalAnchor open={popupState.isOpen} />
          </div>
          <Popper
            {...bindPopper(popupState)}
            placement="bottom-end"
            className={styles.popper}>
            <ClickAwayListener
              onClickAway={popupState.close}>
              <PopperMenu>
                {canMakeHost && (
                  <PopperMenuItem onClick={handleGiveHost(popupState.close)}>
                    Make Host
                  </PopperMenuItem>
                )}
                {canRemove && (
                  <PopperMenuItem onClick={handleRemove(popupState.close)}>
                    Remove
                  </PopperMenuItem>
                )}
              </PopperMenu>
            </ClickAwayListener>
          </Popper>
        </div>
      )}
    </PopupState>
  );
}