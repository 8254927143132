import { useState } from 'react';
import { RequestCallContainer } from '@containers/RequestCall';
import { SearchInstanceContainer } from '@containers';
import { ResultsHeader } from './ResultsHeader';
import { ResultsPane } from './ResultsPane';
import { SearchFilter } from './SearchFilter';
import styles from './style/IndividualSearch.css';

type Props = unknown;

const IndividualSearch = (props: Props) => {
  const [visible, setFiltersVisible] = useState(false);

  const toggleFilterPane = () => {
    setFiltersVisible(!visible);
  };

  return (
    <SearchInstanceContainer>
      <RequestCallContainer>
        <div className={styles.root}>
          <div className={styles.pane}>
            <SearchFilter
              onHide={toggleFilterPane}
              visible={visible} />
          </div>
          <div className={styles.wrap}>
            <div className={styles.main}>
              <ResultsHeader onShow={toggleFilterPane} />
              <ResultsPane />
            </div>
          </div>
        </div>
      </RequestCallContainer>
    </SearchInstanceContainer>
  );
};

export { IndividualSearch };
export default IndividualSearch;