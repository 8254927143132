import { memo } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { cx } from '@utils';
import styles from './style/Project.Clips.css';

type Props = {
  children: React.ReactNode;
  className?: string;
  onClick:  (e: unknown) => void;
  sortable:  boolean;
  sorted:   'asc' | 'desc' | false;
};

export const SortableHeader = memo(({
  onClick,
  ...props
}: Props) => {
  const desc = props.sorted === 'desc';

  const className = cx(styles.sortable, props.className);

  return (
    <div
      className={className}
      onClick={onClick}>
      {props.children}
      {(props.sorted && !desc) && <ArrowDropUpIcon />}
      {(props.sorted && desc) && <ArrowDropDownIcon />}
    </div>
  );
});