import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonOutlined } from '@presentation';
import { useSelectUser } from '@containers/Store/hooks';
import * as consts from '@consts';
import { useUpdateNewUser } from '@utils/api';
import styles from './style.css';

type Props = unknown;

const WelcomeScreen = (props: Props) => {
  const updateNewUser = useUpdateNewUser();

  const startRateOnboarding = () => {
    updateNewUser();
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h1 className={styles.earn}>{copy.title}</h1>
        <div className={styles.copy}>{copy.paragraph1}</div>
        <div className={styles.copy}>{copy.paragraph2}</div>
        <ButtonOutlined
          className={styles.btn}
          fontWeight="bold"
          onClick={startRateOnboarding}
          to={consts.path.Onboarding.Expertise.Root}>
          Get Started
        </ButtonOutlined>
      </div>
    </div>
  );
};

const Welcome = () => {
  const history = useHistory();
  const user = useSelectUser();

  useEffect(() => {

    if (!user.meta.isNew) history.replace(consts.pathname.HOME);

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <WelcomeScreen />
  );
};

const copy = {
  title: `Thank you for verifying your account and welcome to Sentiment!`,
  paragraph1: `First we'd like to collect a bit of information to help us to set up your account and ensure you are receiving market research invitations that are relevant to your specialty and practice setting. It should only take a few short minutes to complete.`,
  paragraph2: `Thank you in advance and we look forward to welcoming you as the newest member of Sentiment!`,
};

export { Welcome };
export default Welcome;