import { useQuery } from '@tanstack/react-query';
import { ExternalSurveyConfigurationBootstrapContext } from '@containers/Project.ExternalSurvey';
import * as $projects from '$admin/api/projects';

type Props =
  ChildrenProps &
  IProjectId;

export const AdminExternalSurveyConfigurationBootstrapContainer = ({ projectId, ...props }: Props) => {

  const bootstrapQuery = useQuery(['admin.bootstrap-external-project', projectId], () => {
    return $projects.external.bootstrapExternalProject({ projectId });
  }, {
    refetchOnWindowFocus: false,
  });

  return (
    <ExternalSurveyConfigurationBootstrapContext.Provider value={bootstrapQuery}>
      {props.children}
    </ExternalSurveyConfigurationBootstrapContext.Provider>
  );
};