import { useContentFeedState } from '@containers/ContentFeed/hooks/useContentFeedState';
import { ContentFeedItem } from '@/components/ContentFeed';
import { useFeedScroll } from '@/components/ContentFeed/hooks/useFeedScroll';

const ContentFeed = () => {
  const [state] = useContentFeedState();

  useFeedScroll();

  return (
    <div>
      {state.items.map(x => (
        <ContentFeedItem
          item={x}
          key={x.id} />
      ))}
    </div>
  );
};

export { ContentFeed };
export default ContentFeed;