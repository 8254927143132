import type { useDevices } from '@utils';
import { createNamedContext } from '@utils';

type UseDevicesValue = ReturnType<typeof useDevices>;
export type ContextValue = Omit<UseDevicesValue, 'errorState' | 'loading'> & {
  audioLoading: UseDevicesValue['loading'];
  audioErrorState: UseDevicesValue['errorState'];
  videoLoading: UseDevicesValue['loading'];
  videoErrorState: UseDevicesValue['errorState'];
};

export const DeviceProviderContext = createNamedContext<ContextValue>(undefined, 'DeviceProviderContext');