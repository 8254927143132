import { useMemo } from 'react';
import { AddItemButton } from '@presentation/AddItemButton';
import { SurveyTemplate } from '@/types/survey.templates';
import { TextEntryList } from '@/components/TextEntryList';
import styles from './style/Presets.css';

type Props = {
  canAdd: boolean;
  canRemove: boolean;
  values: SurveyTemplate.LinkedEntity[];
  onAddItem: () => void;
  onRemoveItem: (id: string) => () => void;
  onUpdateItem: (id: string) => (v: string) => void;
};

export const TemplateAttitudinalsInput = ({
  canAdd,
  canRemove,
  onAddItem,
  onRemoveItem,
  onUpdateItem,
  values,
}: Props) => {

  const items = useMemo(() => {
    return values.map(m => ({
      key: m.id,
      value: m.value,
    }));
  }, [values]);

  return (
    <>
      <TextEntryList
        items={items}
        placeholder="Enter an attitudinal"
        removeItem={onRemoveItem}
        removeItemEnabled={canRemove}
        updateItemValue={onUpdateItem} />
      {canAdd &&
        <AddItemButton
          className={styles.add}
          label="Add"
          onClick={onAddItem} />}
    </>
  );
};