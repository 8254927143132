import { useContext } from 'react';
import { MedicalExpertiseContext } from '@containers/MedicalOnboarding/Context';
import { ButtonProps, RouteNavigation } from '@screens/MedicalOnboarding.Background/interfaces';
import * as Field from '@/components/MedicalProfile/Field';
import * as Layout from '@/components/MedicalProfile/Layout';
import styles from './style/Role.css';

type Props = {
  button:     ButtonProps;
  navigation: RouteNavigation;
};

export const Role = (props: Props) => {
  const ctx = useContext(MedicalExpertiseContext);

  return (
    <Layout.Screen>
      <Layout.Header
        subtitle={copy.subtitle}
        title={copy.title} />
      <div className={styles.select}>
        <Field.Role
          autoFocus
          onChange={item => ctx.onProfessionalRoleUpdated(item.value)}
          value={ctx.professionalRole} />
      </div>
      <Layout.Footer>
        <Layout.BackButton
          onClick={props.navigation.back} />
        <Layout.NextButton
          disabled={props.button.disabled}
          implicitDisable={false}
          loading={props.button.loading}
          onClick={props.navigation.onClick}
          to={props.navigation.to}>
          {props.button.text}
        </Layout.NextButton>
      </Layout.Footer>
    </Layout.Screen>
  );
};

const copy = {
  title: `Which of the following best describes your professional role?`,
  subtitle: `Please select your professional role to optimize your profile for client project searches.`,
};