export function parseDigits(value: string) {
  return ('' + value).replace(/\D/g, '').substring(0, 15);
}

export function format(value: string) {
  const sanitized = parseDigits(value);

  const match = /^(\+?\d{1,3})?(\d{3})(\d{3})(\d{4})$/.exec(sanitized);
  if (!match) return value;

  return ['+', match[1] ?? '1', ' ', '(', match[2], ') ', match[3], '-', match[4]].join('');
}

export function createOneTapLink(phone: string, pin?: string) {
  return `tel:${phone}${pin ? `,,${pin}#` : ``}`;
}

export const phone = {
  format,
  createOneTapLink,
  parseDigits,
};