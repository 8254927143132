import { useMemo } from 'react';
import { useSurveyBuilderState } from './useSurveyBuilderContext';
import useIsEditingSurvey from './useIsEditingSurvey';
import useHasUnsavedChanges from './useHasUnsavedChanges';

export const useCanSaveSurvey = () => {
  const [state] = useSurveyBuilderState();
  const isEditing = useIsEditingSurvey();
  const hasUnsavedChanges = useHasUnsavedChanges();

  const canSave = useMemo(() => {
    return !isEditing &&
      hasUnsavedChanges() &&
      (state.survey.sections
        .every(e => !!e.name && state.survey.items.some(s => s.section.identifier === e.identifier))
        || !state.survey.items.length
      ) && !!state.survey.surveyDetails?.name
      && !!state.survey.surveyDetails?.typeId;
  }, [
    hasUnsavedChanges,
    isEditing,
    state.survey.sections,
    state.survey.items,
    state.survey.surveyDetails?.name,
    state.survey.surveyDetails?.typeId,
  ]);

  return canSave;
};

export default useCanSaveSurvey;