import { memo, Suspense, useCallback } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import type { Route as RouteProp } from './interfaces';

type Props = {
  routes: RouteProp[];
};

const Loader = memo(() => <ActivityIndicator show />);

export const PathSajak = memo(({ routes }: Props) => {
  const renderRoute = useCallback((config: RouteProp) => {
    return (
      <Route
        key={String(config.path)}
        component={config.component}
        exact={config.exact}
        path={config.path} />
    );
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {routes.map(renderRoute)}

        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Suspense>
  );
});

export default PathSajak;