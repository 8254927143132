import { useTemplateEfficacyEndpointsModal } from '@/components/Survey.Templates/Modal.EfficacyEndpoints';
import { TemplateItemLabel } from './Label.Base';
import { TemplateLabelColor } from './Label.colors';

type Props = {
  className?: string;
  disabled: boolean;
  value: string;
};

export const TemplateEfficacyEndpoint = ({ className, disabled, value }: Props) => {

  const [toggleModal, Modal] = useTemplateEfficacyEndpointsModal();

  return (
    <>
      <TemplateItemLabel
        className={className}
        color={TemplateLabelColor.Four}
        disabled={disabled}
        label={value}
        tooltip="Efficacy Endpoint"
        onClick={toggleModal} />
      {!disabled && <Modal />}
    </>
  );
};