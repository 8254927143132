import { path } from '@consts';
import { RouteAuth } from '@enums';
import { BasicRouter } from '@routes/Routers';
import type { Routing } from '@containers/Routing';
import { ActionableLinkContainer } from '@containers/ActionableLink';
import {
  AdHocScheduling,
  Auth,
  Download,
  HubSpot,
  OutlookCalendar,
  ProjectReport,
  Salesforce,
  Scheduling,
  SlackConnected,
  SurveyCompletion,
  ThankYou,
  Tipalti,
} from '@screens/Inbound';
import { Auth0Error, SetPasswordInviteResult } from '$website/screens/Auth0.Redirect';

const all: Routing.Route[] = [{
  component: ActionableLinkContainer,
  exact: true,
  path: path.Inbound.Actionable.Token,
}, {
  component: Auth.Apple,
  exact: true,
  path: path.Inbound.Apple,
}, {
  component: Auth0Error,
  exact: true,
  path: path.Inbound.AuthError,
}, {
  component: SetPasswordInviteResult,
  exact: true,
  path: path.Inbound.AuthPW,
}, {
  component: Auth.Facebook,
  exact: true,
  path: path.Inbound.Facebook,
}, {
  component: Auth.Google,
  exact: true,
  path: path.Inbound.Google,
}, {
  component: Auth.OAuth2Logout,
  exact: true,
  path: path.Inbound.OAuth2Logout,
}, {
  component: Auth.OAuth2,
  exact: true,
  path: path.Inbound.OAuth2,
}, {
  component: Download,
  exact: true,
  path: path.Inbound.Downloads,
}, {
  component: ThankYou,
  exact: true,
  path: path.Inbound.Confirmation,
}, {
  component: SurveyCompletion,
  exact: true,
  path: path.Inbound.SurveyCompletion,
}];

const auth: Routing.Route[] = [
  {
    component: AdHocScheduling.Confirmation,
    exact: true,
    path: path.Inbound.Scheduling.AdHoc.Confirmation,
  },
  {
    component: AdHocScheduling.Proposal,
    exact: true,
    path: path.Inbound.Scheduling.AdHoc.Proposal,
  },
  {
    component: OutlookCalendar,
    exact: true,
    path: path.Inbound.Connect,
  },
  {
    component: Scheduling.Confirmation,
    exact: true,
    path: path.Inbound.Scheduling.Confirmation,
  },
  {
    component: HubSpot,
    exact: true,
    path: path.Inbound.HubSpot,
  },
  {
    component: Scheduling.Invitation,
    exact: true,
    path: path.Inbound.Scheduling.Invitation,
  },
  {
    component: ProjectReport,
    exact: true,
    path: path.Inbound.ProjectReport,
  },
  {
    component: Salesforce,
    exact: true,
    path: path.Inbound.Salesforce,
  },
  {
    component: Scheduling.Proposal,
    exact: true,
    path: path.Inbound.Scheduling.Proposal,
  },
  {
    component: SlackConnected,
    exact: true,
    path: path.Inbound.Slack,
  },
  {
    component: Tipalti,
    exact: true,
    path: path.Inbound.Tipalti,
  },
];

export const routes = {
  all,
  auth,
};

export const router = {
  all: BasicRouter(routes.all, {
    auth: RouteAuth.Ignore,
    path: [
      path.Inbound.Actionable.Root,
      path.Inbound.Auth,
      path.Inbound.Confirmation,
      path.Inbound.Downloads,
      path.Inbound.SurveyCompletion,
    ],
  }),
  auth: BasicRouter(routes.auth, {
    auth: RouteAuth.Authenticated,
    path: [
      path.Inbound.Connect,
      path.Inbound.HubSpot,
      path.Inbound.ProjectReport,
      path.Inbound.Salesforce,
      path.Inbound.Scheduling.Root,
      path.Inbound.Slack,
      path.Inbound.Tipalti,
    ],
  }),
};
