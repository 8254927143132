import { memo, useCallback, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { MessageQueryHintContainer, TextQueryHint } from '@/brand-insights/components/presentation';
import { ChatActionType, ChatContextType, ChatHintType } from '@/brand-insights/enums';
import type { Chat } from '@/brand-insights/types';
import { trunc } from '@/brand-insights/utils';
import { ChatPageContextDataContext, SubmitActionContext } from './context';

type Props = {
  className?: string;
  query: string;
  items: Chat.SuggestedActions.QuerySurveyQuestion[];
};

export const SurveyQuestionSuggestedActions = (props: Props) => {

  const submitAction = useSubmitAction(props.query);

  const groupedItems = useMemo(() => {
    const map: Record<string, Chat.SuggestedActions.QuerySurveyQuestion[]> = {};

    function formatKey(item: Chat.SuggestedActions.QuerySurveyQuestion) {
      return `${item.project.id}-${item.survey.id}`;
    }

    props.items.forEach(item => {
      const key = formatKey(item);
      if (!map[key]) {
        map[key] = [];
      }
      map[key].push(item);
    });

    return map;
  }, [props.items]);

  return (
    <div className={props.className}>
      {Object.keys(groupedItems).map(key => {
        return (
          <StyledProjectQuestionsCard
            key={key}
            project={groupedItems[key][0].project}
            survey={groupedItems[key][0].survey}
            items={groupedItems[key]}
            onSubmit={submitAction} />
        );
      })}
    </div>
  );
};

const useSubmitAction = (query: string) => {

  const ctx = useContext(SubmitActionContext);
  const contextData = useContext(ChatPageContextDataContext);

  const handleSubmit = useCallback((item: Chat.SuggestedActions.QuerySurveyQuestion) => () => {
    const fullQuery = [
      `Please answer the following question:`,
      ``,
      `"${query}"`,
      ``,
      `Using the responses from the following survey question:`,
      ``,
      contextData.type !== ChatContextType.Project ? `Project: "${trunc(item.project.name, 150)}"` : null,
      `Q${item.question.ordinal}: "${trunc(item.question.value, 150)}"`,
    ].filter(f => f !== null).join('\n');

    return ctx({
      query,
      projectId: item.project.id,
      surveyId: item.survey.id,
      surveyQuestionId: item.question.base.id,
      type: ChatActionType.QuerySurveyQuestion,
    }, fullQuery);
  }, [ctx, query, contextData.type]);

  return handleSubmit;
};

type ProjectQuestionsCardProps = {
  className?: string;
  items: Chat.SuggestedActions.QuerySurveyQuestion[];
  onSubmit: (item: Chat.SuggestedActions.QuerySurveyQuestion) => () => void;
  project: Chat.SuggestedActions.QuerySurveyQuestion['project'];
  survey: Chat.SuggestedActions.QuerySurveyQuestion['survey'];
};

const ProjectQuestionsCard = memo((props: ProjectQuestionsCardProps) => {

  const contextData = useContext(ChatPageContextDataContext);

  return (
    <ProjectQuestionsCardRoot className={props.className}>
      <ProjectQuestionsCardHeader>
        {contextData.type === ChatContextType.Global && (
          <ProjectQuestionsCardHeaderItem>
            <ProjectQuestionsCardLabel>Project:</ProjectQuestionsCardLabel>
            {props.project.name}
          </ProjectQuestionsCardHeaderItem>
        )}
        <ProjectQuestionsCardHeaderItem>
          <ProjectQuestionsCardLabel>Survey:</ProjectQuestionsCardLabel>
          {props.survey.name}
        </ProjectQuestionsCardHeaderItem>
      </ProjectQuestionsCardHeader>
      <MessageQueryHintContainer>
        {props.items.map(item => {
          const trimmed = trunc(item.question.value, 150);
          const hint: Chat.QueryHint.Item = {
            displayValue: `Q${item.question.ordinal}: ${trimmed}`,
            type: ChatHintType.Text,
          };
          return (
            <TextQueryHint
              key={item.question.id}
              hint={hint}
              onClick={props.onSubmit(item)} />
          );
        })}
      </MessageQueryHintContainer>
    </ProjectQuestionsCardRoot>
  );
});

const StyledProjectQuestionsCard = styled(ProjectQuestionsCard)(({ theme }) => ({
  marginBottom: 10,

  '&:last-child': {
    marginBottom: 0,
  },
}));

const ProjectQuestionsCardRoot = styled('div')(({ theme }) => ({
}));

const ProjectQuestionsCardHeader = styled('div')(({ theme }) => ({
  marginBottom: 8,
}));

const ProjectQuestionsCardHeaderItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

const ProjectQuestionsCardLabel = styled('div')(({ theme }) => ({
  marginRight: 5,
  fontFamily: theme.fonts.semiBold,
}));