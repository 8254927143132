import { useCallback, useEffect } from 'react';
import { ReadyState, ReadyStateChangeHandler } from '@containers/AppReadyState/interfaces';
import { useAppReadySubscription } from './useAppReadySubscription';
import { useAppReadyState } from './useAppReadyState';

export const useAuthChangeEffect = (handler: ReadyStateChangeHandler) => {
  const app = useAppReadyState();
  const { off, on } = useAppReadySubscription();

  const handleAuth = useCallback(() => handler(true), [handler]);

  // const handleError = useCallback(() => {
  //   const code = app.error.code
  //     ? `[${app.error.code}]`
  //     : null;
  //   const message = app.error.offline
  //     ? `Could not connect to API`
  //     : `${app.error.message ?? 'Unknown'}`;

  //   const joined = [code, message].filter(Boolean).join(' ');

  //   throw new Error(joined);
  // }, [app.error]);

  const handleUnauth = useCallback(() => handler(false), [handler]);

  // useEffect(() => {
  //   on(ReadyState.Error, handleError);

  //   return () => {
  //     off(ReadyState.Error, handleError);
  //   };
  // }, [
  //   handleError,
  //   off,
  //   on,
  // ]);

  useEffect(() => {
    on(ReadyState.NoCredentialsFound, handleUnauth);
    on(ReadyState.UserSignedOut, handleUnauth);

    return () => {
      off(ReadyState.NoCredentialsFound, handleUnauth);
      off(ReadyState.UserSignedOut, handleUnauth);
    };
  }, [
    handleUnauth,
    off,
    on,
  ]);

  useEffect(() => {
    on(ReadyState.UserSignedIn, handleAuth);

    return () => {
      off(ReadyState.UserSignedIn, handleAuth);
    };
  }, [
    handleAuth,
    off,
    on,
  ]);
};