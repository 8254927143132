import { Helmet } from 'react-helmet';
import { useProjectInviteWhitelabelState } from '@containers/ProjectLinkInvitation/hooks';
import { Footer } from '@/components/Branding';
import { CallBenefits } from './Call.Benefits';
import { FAQ } from './FAQ';
import { Funnel } from './Funnel';
import { CallLanding } from './Call.Landing';
import { GHAB } from './GHAB';
import { Navbar } from './Navbar';
import { useProjectAudienceType } from './hooks/audience-type';
import styles from './style/ProjectInvite.css';

type Props = unknown;

export const CallInvite = (props: Props) => {
  const whitelabel = useProjectInviteWhitelabelState();
  const audienceType = useProjectAudienceType();

  if (whitelabel.enabled) {
    return (
      <Footer.PoweredBy
        className={styles.theme}
        fullHeight={false}>
        <div className={styles.root}>
          <Navbar />
          <CallLanding />
        </div>
      </Footer.PoweredBy>
    );
  }

  return (
    <div className={styles.root}>
      <Helmet title="Interview Invite" />
      <Navbar />
      <CallLanding />
      <GHAB audienceType={audienceType} />
      <CallBenefits />
      <FAQ />
      <Funnel />
    </div>
  );
};

CallInvite.displayName = 'ProjectInvite.CallInvite';