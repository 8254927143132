import { memo, useMemo, CSSProperties } from 'react';
import { cx } from '@utils';
import styles from './style/ColorIndicator.css';

type ColorIndicatorProps = {
  className?: string;
  size?: number;
  value: string;
};

export const ColorIndicator = memo(({
  className,
  value,
  size = 25,
}: ColorIndicatorProps) => {
  const style: CSSProperties = useMemo(() => {
    return {
      backgroundColor: value,
      borderRadius: '50%',
      height: size,
      minHeight: size,
      minWidth: size,
      width: size,
    };
  }, [value, size]);

  return (
    <div
      className={className}
      style={style} />
  );
});

type LabelProps = {
  className?: string;
  value: React.ReactNode;
};

const Label = memo(({ className, value }: LabelProps) => {
  return (
    <div className={cx(styles.label, className)}>{value}</div>
  );
});

type LabelWithColorProps = {
  className?: string;
  color: ColorIndicatorProps;
  label: LabelProps;
};

export const LabelWithColor = memo(({
  className,
  color,
  label,
}: LabelWithColorProps) => {

  return (
    <div className={cx(styles.row, className)}>
      <ColorIndicator {...color} />
      <Label {...label} />
    </div>
  );
});

type LabelWithSubtitleProps = {
  className?: string;
  label: string;
  subtitle: string;
};

export const LabelWithSubtitle = memo(({
  label,
  subtitle,
}: LabelWithSubtitleProps) => {
  return (
    <div className={styles.wrapped}>
      <span className={styles.bold}>{label}</span> <span className={styles.subtitle}>{subtitle}</span>
    </div>
  );
});