import { useCallback, useMemo, useState } from 'react';
import cuid from 'cuid';
import { utils as $enumUtils } from '@enums';
import { GrayOutlineButton } from '@presentation/Buttons';
import { useSubmitSurveyDraft, useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { SurveyItem, SurveyQuestion } from '@/types';
import { toTitleCase } from '@utils';
import { SurveyItemsDropdown } from '@presentation/SurveyBuilder';
import { Button } from '@/components/Button';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import ModalHeader from '@/components/Modal/Header';
import { useModal } from '@/components/Modal/hooks';
import { DropDown } from '@/components/DropDown';
import styles from './style/Modal.MoveItem.css';

type Props = {
  item: SurveyItem;
  question: SurveyQuestion;
} &
  Pick<ModalProps,
    'open' |
    'onClose'>;

export const CloneSurveyQuestionModal = ({
  item,
  question,
  onClose,
  open,
}: Props) => {

  const submitDraft = useSubmitSurveyDraft();
  const [_, dispatch] = useSurveyBuilderState();

  const copyQuestion = useCallback(() => {
    const targetItemIdentifier = cuid();
    const targetQuestionIdentifier = cuid();

    dispatch({
      type: 'question-copied',
      payload: {
        sourceQuestionIdentifier: question.base.identifier,
        targetItemIdentifier,
        targetQuestionIdentifier,
      },
    });

    dispatch({
      type: 'item-moved',
      payload: {
        identifier: targetItemIdentifier,
        to: {
          position: 'after',
          ref: item.identifier,
        },
      },
    });

    submitDraft();
    onClose();
  }, [dispatch, question, item, submitDraft, onClose]);

  const itemType = $enumUtils.SurveyItemtype.getName(item.type);

  const title = `Clone ${itemType}`;

  return (
    <Modal
      open={open}
      onClose={onClose}>

      <ModalHeader text={title} />

      <div className={styles.body}>
        <div className={styles.note}>
          Cloning this question will not copy any logic, quotas, or conditions.
        </div>
      </div>

      <div className={styles.footer}>
        <GrayOutlineButton
          onClick={onClose}
          title="Cancel" />
        <Button.Primary
          className={styles.submit}
          onClick={copyQuestion}
          title="Clone"
          variant="brick" />
      </div>
    </Modal>
  );
};

export const useCloneSurveyQuestionModal = () => useModal(CloneSurveyQuestionModal);

export default CloneSurveyQuestionModal;