import { memo } from 'react';
import { cx } from '@utils';
import styles from './style/Toolbar.css';

type Props = {
  className?: string;
} & ChildrenProps;

export const Toolbar = memo(({ children, className }: Props) => {

  return (
    <div className={cx(styles.toolbar, className)}>
      {children}
    </div>
  );
});