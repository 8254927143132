export function ensureAbsoluteHref({
  url,
  defaultProtocol = '',
}: {
  url: string;
  defaultProtocol?: Protocol;
}): string {
  const startsWithProtocol = /^((?:https?|ftp)?:)\/\//.test(url);

  const isEmail = !startsWithProtocol && url.includes('@');

  if (isEmail) {
    return `mailto:${url}`;
  }

  return startsWithProtocol ? url : `${defaultProtocol}//${url}`;
}

type Protocol = 'https' | 'http' | '';