import { Fragment, memo } from 'react';
import { useSelector } from 'react-redux';
import { useHasConsultantRole, useHasComplianceRole } from '@containers/User';
import { hasConsultantRole, hasClientRole, hasInternalAdminRole } from '@utils';
import { SettingsTitle } from '@/components/Settings';
import AnalystBuckets from './Settings.Email.Analyst';
import ComplianceBuckets from './Settings.Email.Compliance';
import MemberBuckets from './Settings.Email.Member';
import { SlackNotificationSettings } from './Settings.Notifications.Slack';
import { SMSSettings } from './Settings.SMS';

const selectSMS = (state: Store.State) => {
  return state.user.contact.phone &&
    hasConsultantRole(state.user);
};

const selectEmail = (state: Store.State) => {
  return hasClientRole(state.user) || hasInternalAdminRole(state.user) || hasConsultantRole(state.user);
};

export function NotificationSettings() {
  const isMember = useHasConsultantRole();
  const isCompliance = useHasComplianceRole();
  const sms = useSelector(selectSMS);
  const email = useSelector(selectEmail);

  return (
    <>
      {sms && <SMSSettings />}
      {email &&
        <>
          <SettingsTitle
            title="Email Settings" />
          {isMember
            ? <Member />
            : isCompliance
              ? <Compliance />
              : <Analyst /> }
        </>
      }
    </>
  );
}

const Compliance = memo(() => {
  return (
    <Fragment>
      <ComplianceBuckets />
      <SlackNotificationSettings />
    </Fragment>
  );
});

const Analyst = memo(() => {
  return (
    <Fragment>
      <AnalystBuckets />
      <SlackNotificationSettings />
    </Fragment>
  );
});

const Member = memo(() => {
  return (
    <Fragment>
      <MemberBuckets />
    </Fragment>
  );
});

export default NotificationSettings;