import { useMemo, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { getLocationFor } from '@utils';
import { useGroupEntityTags, useGroupEntityTagsUpdate } from '@containers';
import { StarredAnswersContainer } from '@containers/SurveyResponse';
import { useProjectSurveyRespondent, useProjectState, useProjectAnonymity } from '@containers/GroupProject/hooks';
import { Breadcrumb, Breadcrumbs } from '@presentation/Breadcrumbs';
import { GroupSurveyResponseTagsContainer } from '@containers/GroupTags/GroupSurveyResponseTagsContainer';
import { HomeBreadcrumb } from '@/components/Workspace/WorkspaceBreadcrumb';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import { ProjectSurveyResponse } from '@/components/SurveyResponse';
import { GroupSidebarTags } from '@/components/GroupSidebarTags';
import Header from './Header';
import { AnswersHeader } from './AnswersHeader';
import styles from './style/RespondentAnswers.css';

export const RespondentAnswers = () => {
  const {
    projectSurveyResponseId,
    response,
    respondent,
  } = useProjectSurveyRespondent();
  const { project } = useProjectState();
  const projectAnonymity = useProjectAnonymity();
  const location = useLocation<{ from: string }>();

  const [prevLocation] = useState(location.state?.from);
  const [originalProject] = useState(project);

  const back = useMemo(() => {
    return prevLocation ||
      getLocationFor.project.surveyResponses({ slug: originalProject.slug });
  }, [prevLocation, originalProject]);

  if (!response) {
    return (
      <ActivityIndicator show />
    );
  }

  function Tags() {
    const tags = useGroupEntityTags();
    const update = useGroupEntityTagsUpdate();

    return (
      <div className={styles.tagsContainer}>
        <GroupSidebarTags
          tags={tags}
          onSave={update} />
      </div>
    );
  }

  return (
    <GroupSurveyResponseTagsContainer surveyResponseId={response.id}>
      <div className={styles.root}>
        <Breadcrumbs className={styles.breadcrumbs}>
          <HomeBreadcrumb />
          <Link to={back}>
            <Breadcrumb>
              {originalProject.name}
            </Breadcrumb>
          </Link>
        </Breadcrumbs>
        <div className={styles.wrap}>
          <div className={styles.main}>
            <Header />
            <StarredAnswersContainer
              initialValues={response.starred}
              projectId={project.id}
              projectSurveyResponseId={projectSurveyResponseId}>
              <div className={styles.subSectionContainer}>
                <AnswersHeader />
                <ProjectSurveyResponse
                  response={response} />
              </div>
            </StarredAnswersContainer>
          </div>
          {!projectAnonymity &&
            <Tags />
          }
        </div>
      </div>
    </GroupSurveyResponseTagsContainer>
  );
};