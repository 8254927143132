import * as enums from '@enums';

const selectContacts = (state: Store.State, provider: enums.ConnectedAccountProvider) => {
  return state.user.connected.find(c => c.providerId === provider && c.typeId === enums.ConnectedAccountType.Contacts);
};

const selectCalendar = (state: Store.State, provider: enums.ConnectedAccountProvider) => {
  return state.user.connected.find(c => c.providerId === provider && c.typeId === enums.ConnectedAccountType.Calendar);
};

const selectFiles = (state: Store.State, provider: enums.ConnectedAccountProvider) => {
  return state.user.connected.find(c => c.providerId === provider && c.typeId === enums.ConnectedAccountType.Files);
};

export const selectGoogleContacts = (state: Store.State) => {
  return selectContacts(state, enums.ConnectedAccountProvider.Google);
};

export const selectGoogleCalendar = (state: Store.State) => {
  return selectCalendar(state, enums.ConnectedAccountProvider.Google);
};

export const selectGoogleDrive = (state: Store.State) => {
  return selectFiles(state, enums.ConnectedAccountProvider.Google);
};

export const selectMicrosoftContacts = (state: Store.State) => {
  return selectContacts(state, enums.ConnectedAccountProvider.Microsoft);
};

export const selectMicrosoftCalendar = (state: Store.State) => {
  return selectCalendar(state, enums.ConnectedAccountProvider.Microsoft);
};

export const connectedCalendar = (state: Store.State) => {
  return state.user.connected.find(c => c.typeId === enums.ConnectedAccountType.Calendar);
};

export const connectedContacts = (state: Store.State) => {
  return state.user.connected.find(c => c.typeId === enums.ConnectedAccountType.Contacts);
};

export const selectCalendlyCalendar = (state: Store.State) => {
  return selectCalendar(state, enums.ConnectedAccountProvider.Calendly);
};

export const selectZoomFiles = (state: Store.State) => {
  return selectFiles(state, enums.ConnectedAccountProvider.Zoom);
};