type Props = {
  className?: string;
  size?: number;
};

const GraduationCap = ({
  className,
  size = 24,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill="#626262"
    width={size}
    height={size}
    viewBox="0 0 24 24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M5 13.18v4L12 21l7-3.82v-4L12 17l-7-3.82zM12 3L1 9l11 6 9-4.91V17h2V9L12 3z"/>
  </svg>
);

export { GraduationCap };
export default GraduationCap;