import { useState } from 'react';
import { useSelectUser } from '@containers/Store';
import { AccountProfileContainer } from './AccountProfileContainer';
import { AccountProfileFieldsPopulationContext } from './Context';

type Props = {
  children: React.ReactNode;
};

const AccountProfileFieldsStepper = (props: Props) => {
  const [populated, setFieldsPopulated] = useState(false);
  const me = useSelectUser();

  return (
    <AccountProfileFieldsPopulationContext.Provider value={[populated, setFieldsPopulated]}>
      <AccountProfileContainer userId={me.id}>
        {props.children}
      </AccountProfileContainer>
    </AccountProfileFieldsPopulationContext.Provider>
  );
};

export { AccountProfileFieldsStepper };
export default AccountProfileFieldsStepper;