import { useCallback, useMemo } from 'react';
import type { DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { DragDropContext } from 'react-beautiful-dnd';
import * as consts from '@consts';
import type { RankingQuestion } from '@/types/survey';
import { useSurveyFormQuestionAnswer } from '@/containers/SurveyForm';
import type { SurveyQuestionType } from '@/enums';
import type { RankingOrdinalDropdownItem } from './interfaces';
import type { RefreshItems } from './utils.ranking';
import * as utils from './utils.ranking';
import RankingList from './Ranking.List';

const NotApplicableOrdinal = consts.surveys.NotApplicableRankingOrdinal;

type Props = {
  question: RankingQuestion.FormQuestion;
};

export const RankingFormContainer = ({ question }: Props) => {

  const [answer, setAnswer] = useSurveyFormQuestionAnswer<SurveyQuestionType.Ranking>();

  const hasMinMaxSettings = useMemo(() => {
    return !!question.settings.maxRank || !!question.settings.minRank;
  }, [question.settings]);

  const handleUpdateAnswerOptions = useCallback((options: RankingQuestion.RespondentAnswer.Option[]) => {
    setAnswer({
      dontKnow: answer.dontKnow,
      options,
      openEndedValues: answer.openEndedValues,
    });
  }, [setAnswer, answer]);

  const items = useMemo(() => {

    return question.options.map((option: RankingQuestion.Option) => {
      const optionAnswer = answer.options.find(f => f.id === option.id);
      return {
        ...option,
        ordinal: optionAnswer ? optionAnswer.ordinal : null,
        value: option.value,
      };
    });

  }, [question.options, answer]);

  const sortedItems = useMemo(() => {
    const withOrdinals = items.filter(f => !!f.ordinal);
    const withoutOrdinals = items.filter(f => !f.ordinal);
    return [
      ...withOrdinals.sort((a, b) => a.ordinal > b.ordinal ? 1 : -1),
      ...withoutOrdinals,
    ];
  }, [items]);

  const reorderRankedOnly = useCallback((sourceIndex: number, targetOrdinal: number) => {
    const updatedItems = utils.reorderItemsRankedOnly({
      items: sortedItems,
      sourceIndex,
      targetOrdinal,
    });

    handleUpdateAnswerOptions(updatedItems);
  }, [handleUpdateAnswerOptions, sortedItems]);

  const reorder = useCallback(({
    sourceIndex,
    targetIndex,
    targetOrdinal,
  }: Omit<RefreshItems, 'items' | 'settings'>) => {
    if (sourceIndex === targetIndex &&
      sortedItems[targetIndex].ordinal === targetOrdinal) {
      return;
    }

    const updatedItems = utils.refreshItems({
      items: sortedItems,
      settings: question.settings,
      sourceIndex,
      targetIndex,
      targetOrdinal,
    });

    handleUpdateAnswerOptions(updatedItems);
  }, [
    question.settings,
    sortedItems,
    handleUpdateAnswerOptions,
  ]);

  const handleDragEnd = useCallback((result: DropResult, provided: ResponderProvided) => {
    if (!result.destination) {
      return;
    }

    reorder({
      sourceIndex: result.source.index,
      targetIndex: result.destination.index,
      targetOrdinal: result.destination.index + 1,
    });
  }, [reorder]);

  const handleDropdownSelect = useCallback((sourceIndex: number) => (item: RankingOrdinalDropdownItem) => {
    const targetOrdinal = item.id;

    if (hasMinMaxSettings) {
      return reorderRankedOnly(sourceIndex, item.id);
    }

    if (item.id !== NotApplicableOrdinal) {
      reorder({
        sourceIndex,
        targetIndex: item.id - 1,
        targetOrdinal,
      });
    } else {
      const targetIndex = Math.max(...sortedItems.map((m, index) => (m.ordinal || index)));
      reorder({
        sourceIndex,
        targetIndex,
        targetOrdinal: NotApplicableOrdinal,
      });
    }
  }, [
    hasMinMaxSettings,
    reorder,
    reorderRankedOnly,
    sortedItems,
  ]);

  const getDropdownItems = useCallback((option: RankingQuestion.FormOption) => {

    const maxOrdinal = question.settings.maxRank || items.length;

    const ordinalItems = new Array(maxOrdinal).fill(0).map((_, i) => i + 1);

    if (hasMinMaxSettings && option.ordinal !== null) {
      ordinalItems.push(null);
    }

    if (!hasMinMaxSettings) {
      ordinalItems.push(NotApplicableOrdinal);
    }

    return ordinalItems;
  }, [
    hasMinMaxSettings,
    items.length,
    question.settings,
  ]);

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <RankingList
        rankingDisabled={answer.dontKnow}
        dragDisabled={hasMinMaxSettings || answer.dontKnow}
        getDropdownItems={getDropdownItems}
        items={sortedItems}
        onOrdinalSelect={handleDropdownSelect} />
    </DragDropContext>
  );
};

export default RankingFormContainer;