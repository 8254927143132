import { useCallback } from 'react';
import type { MatrixGridQuestion as MGQ } from '@/types';
import { useSurveyFormQuestionAnswer } from '@/containers/SurveyForm';
import type { SurveyQuestionType } from '@/enums';
import { SurveyMatrixGridContainer } from '@/components/SurveyMatrixGrid';
import { MatrixFormContext } from './context';
import type { MatrixGridQuestionProps, MatrixForm } from './interfaces';
import { Rationale } from './Rationale';

type Props =
  MatrixGridQuestionProps;

export const MatrixGridQuestion = ({ item }: Props) => {

  const [answer, setAnswer] = useSurveyFormQuestionAnswer<SurveyQuestionType.MatrixGrid>();

  const updateRowAnswer: MatrixForm.OnSelect = useCallback(selected => {
    const groupByRows = item.settings.groupBy === 'row';
    const groupByColumns = item.settings.groupBy === 'column';
    const values: MGQ.RespondentAnswer.Map = answer.values;

    if (groupByRows) {
      item.options.forEach(option => {
        values[selected.rowId][option.id] = false;
      });
      values[selected.rowId][selected.optionId] = true;
    } else if (groupByColumns) {
      item.matrixRows.forEach(row => {
        values[row.id][selected.optionId] = false;
      });
      values[selected.rowId][selected.optionId] = true;
    }

    // console.log('Selected', selected);
    // console.log('Previous', answer.values);
    // console.log('Next', items);
    setAnswer({
      values,
      rowText: answer.rowText,
    });
  }, [
    answer,
    setAnswer,
    item.matrixRows,
    item.options,
    item.settings.groupBy,
  ]);

  const updateRowTextValue = useCallback((rowId: number) => (value: string) => {

    setAnswer({
      rowText: {
        ...answer.rowText,
        [rowId]: value,
      },
      values: answer.values,
    });
  }, [setAnswer, answer]);

  const ctx: MatrixForm.ContextValue = {
    display: item.settings.display,
    groupedBy: item.settings.groupBy,
    onSelect: updateRowAnswer,
    onUpdateRowText: updateRowTextValue,
    options: item.options,
    rows: item.matrixRows,
    rowTextMap: answer.rowText,
    values: answer.values,
    variant: 'single',
  };

  return (
    <>
      <MatrixFormContext.Provider value={ctx}>
        <SurveyMatrixGridContainer />
      </MatrixFormContext.Provider>
      <Rationale settings={item.settings.rationale} />
    </>
  );
};

export default MatrixGridQuestion;