import { useCallback } from 'react';
import { useChainedCommands } from '@remirror/react';

export function useRemoveLink() {
  const chain = useChainedCommands();

  return useCallback(() => {
    return chain
      .removeLink()
      .focus()
      .run();
  }, [chain]);
}