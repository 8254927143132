import { memo } from 'react';
import { X } from 'react-feather';
import { cx } from '@utils';
import styles from './style/DraftNotification.css';

type Props = {
  className?: string;
  onClick:   () => unknown;
  onDismiss: () => unknown;
};

export const DraftBanner = memo((props: Props) => {
  return (
    <div className={cx(styles.notification, props.className)}>
      <div className={styles.notificationText}>
        You have some unsaved drafts. <span className={styles.viewDrafts} onClick={props.onClick}>View drafts.</span>
      </div>
      <X className={styles.dismiss} onClick={props.onDismiss} />
    </div>
  );
});

DraftBanner.displayName = 'Draft.Banner';