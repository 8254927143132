type PathObject<T = string> = {
  [k: string]: T;
};

type NestedPathObject = PathObject<string | PathObject<string>>;

type PathExtendedObject<T extends NestedPathObject> = {
  [K in keyof T]: T[K];
} & PathObjectValuesProp;

type PathObjectValuesProp = {
  Any: string;
};

type OmitPath<T extends PathObject, P extends keyof T> = Omit<T, P>;

function any(paths: string[]) {
  return `(${paths.join('|')})`;
}

function extend<T extends NestedPathObject>(obj: T) {
  const extended = Object.defineProperty(obj, 'Any', {
    get() {

      function values(o: NestedPathObject): string[] {
        return Object.values(o).reduce((acc, x) => {
          return typeof x === 'object'
            ? acc.concat(values(x))
            : acc.concat(x);
        }, [] as string[]);
      }

      return any(values(obj));

    },
  }) as PathExtendedObject<T>;

  return extended;
}

function join(route: string) {
  return (...args: string[]) => args.reduce((acc, x) => `${acc}${x}`, route);
}

function omit<T extends PathObject, P extends keyof T>(obj: T, ...keys: P[]) {
  const omitted = Object.keys(obj).reduce<OmitPath<T, P>>((acc, key: P & string) => {
    return keys.includes(key)
         ? acc
         : { ...acc, [key]: obj[key] };
  }, {} as OmitPath<T, P>);

  return obj.Any
       ? extend(omitted)
       : omitted;

}

export const match = { any };

export { extend };
export { join };
export { omit };