import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox } from './Checkbox';
import type { CheckboxLabelProps } from './interfaces';
import styles from './style/CheckboxLabel.css';

export const CheckboxLabel = ({
  className,
  label = '',
  checked = false,
  disabled = false,
  onChange = () => {},
  style,
}: CheckboxLabelProps) => {
  return (
    <FormControlLabel
      className={className}
      sx={{
        marginLeft: 0,
        [`& .MuiFormControlLabel-label`]: {
          fontSize: 16,
          fontFamily: 'var(--font-reg)',
          width: '100%',
        },
      }}
      control={
        <Checkbox
          className={checked ? styles.checked : styles.unchecked}
          checked={checked}
          style={{
            marginRight: 10,
          }}
          disabled={disabled}
          onChange={onChange} />
      }
      label={label}
      style={style} />
  );
};

export default CheckboxLabel;