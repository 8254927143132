import { createRef, useCallback, useState } from 'react';

type Params = {
  size: number;
};

export const useVideoRowItemDimensions = <T extends HTMLDivElement>() => {
  const ref = createRef<T>();
  const [dimensions, setDimensions] = useState({
    height: 0,
    width: 0,
  });

  const calc = useCallback((params: Params) => {

    if (ref.current) {
      const calculatedWidth = ref.current.offsetWidth / params.size;
      const calculatedHeight = calculatedWidth * 0.5625;

      setDimensions({
        height: calculatedHeight,
        width: calculatedWidth,
      });
    }

  }, [ref]);

  return [ref, dimensions, calc] as const;

};