import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useSelectUser } from '@containers/Store';
import { Admin } from '@consts/path';
import { getLocationFor } from '@utils';
import * as admin from '@utils/permissions.admin';
import { ProjectSurveyDetailsExternalUser, ProjectSurveyDetailsInternalUser } from '@/types';
import { hasInternalAdminRole } from '@utils';
import { MaybeLink } from '@/components/MaybeLink';
import { SurveyDetail } from '@/scenes/surveys/CompletedSurvey/SurveyDetail';
import { UserDetailsProps } from './interfaces';
import styles from './style.css';

export const InternalUserDetails = (props: UserDetailsProps<ProjectSurveyDetailsInternalUser>) => {
  const me = useSelectUser();
  const permitted = admin.hasAccessFor.experts.dashboard(me);

  const loc = useLocation();
  const adminRoute = matchPath(loc.pathname, {
    path: Admin.Root,
  });

  const to = !adminRoute || permitted
      ? getLocationFor.user.profile({ slug: props.user.profile.slug })
      : null;

  return (
    <MaybeLink
      className={styles.name}
      to={to}>
      {`${props.user.profile.firstName} ${props.user.profile.lastName}`}
    </MaybeLink>
  );
};

export const ExternalUserDetails = (props: UserDetailsProps<ProjectSurveyDetailsExternalUser>) => {
  const user = useSelectUser();

  const userId = useMemo(() => props.user.id.replace(/-/g, ''), [props.user.id]);

  return (
    <div>
      <SurveyDetail name="User">
        {userId}
      </SurveyDetail>
      {hasInternalAdminRole(user)
        ? (
          <SurveyDetail name="Source User">
            {props.user.vendorUserId || 'Not Set'}
          </SurveyDetail>
          )
        : null
      }
    </div>
  );
}