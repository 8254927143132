import { useContext } from 'react';
/* import { Calendar } from 'react-feather'; */
import { useSelector } from 'react-redux';
import { cx } from '@utils';
/* import { useRequestQuant2Qual } from '@containers/Call/hooks/useRequestQuant2Qual'; */
import type { HybridSurveyResponseUserId } from '@/types';
import { CreditRateWithTooltip } from '@/components/ExpertRate/Credit';
/* import { useProfileRequestCallAction } from '@/components/UserProfile/hooks'; */
import { UserAvatar } from '@/components/UserAvatar';
import styles from './BaseballCard.css';
/* import { CardContext } from './CardContext'; */

const mapState = (state: Store.State) => ({
  group: state.group,
  me: state.user,
});

type Props = {
  user: {
    id: HybridSurveyResponseUserId;
    name: string;
    creditRate?: number;
  };
  subtext?: string;
};

export const AnonymousCardContent = ({ user, subtext }: Props) => {
  /*
  const cardRef = useContext(CardContext);
  const requestQuant2Qual = useRequestQuant2Qual();

  function onClick(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    cardRef.closeCard();
    requestQuant2Qual({
      expertId: userId,
      expertName: displayName || profile.fullname,
      projectId,
    });
  }

  const params = {
    user: {
      id: userId,
      profile: { ...profile, firstName: displayName, lastName: displayName },
    },
  };

  const [canInitiateCalls, initiateCall] = useProfileRequestCallAction(params);
  */
  const { group, me } = useSelector(mapState);
  const showRate = group?.features?.showConsultantRates && me.id != user.id;

  return (
    <div className={styles.contentContainer}>
      <div className={styles.textContainer}>
        <div>
          <UserAvatar
            className={cx(styles.avatar, styles.anonAvatar)}
            pictureUrl={undefined}
            size={50} />
        </div>
        <div>
          <div>
            <span className={styles.expertName}>{user.name}</span> {showRate && <CreditRateWithTooltip rate={user.creditRate} /> }
          </div>
          {subtext &&
            <div className={styles.subtext}>{subtext}</div>
          }
        </div>
      </div>
      {/* canInitiateCalls &&
        <div className={styles.btns}>
          {canInitiateCalls &&
            <button
              className={styles.btn}
              onClick={onClick}>
              <Calendar />
              Request Call
      </button>}
        </div>
      */}
    </div>);
};