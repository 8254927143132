import { cx } from '@utils';
import styles from './style/Toolbar.css';

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
  enabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => unknown;
};

export const Button = (props: Props) => {
  const classes = cx(styles.btn, {
    [styles.enabled]: props.enabled,
  });

  return (
    <button
      className={classes}
      disabled={props.disabled}
      onClick={props.onClick}>
      {props.children}
    </button>
  );
};

Button.displayName = 'Toolbar.Button';