import { useMemo } from 'react';

export const useOverviewTooltipText = (surveyProjects = true) => {

  const tooltip = useMemo(() => {
    const type = [
      `The Interviews project type allows you to conduct calls on the platform, but does not require experts to submit answers to screening questions.`,
      surveyProjects ? `The Online Survey project type allows you to collect survey responses from experts without call scheduling functions.` : null,
      surveyProjects ? `The Screener + Interviews project type allows for both survey responses and calls to be conducted on the same project.` : null,
    ];

    const subtype = 'This field captures the type of research that a team will be conducting and is used to pre-populate tags for the project.';

    const goal = surveyProjects
      ? `This represents the targeted number of interviews or survey responses you would like to complete for this project.`
      : `This represents the targeted number of interviews you would like to complete for this project.`;

    const frequency = `Receive survey answers from respondents over multiple time periods to understand shifts in the markets or topics you are researching.`;

    return {
      frequency,
      goal,
      subtype,
      title: `This is an expert facing project title. Experts are far more receptive to projects with industry title names vs company title names (i.e. "Streaming Media" vs. "Netflix").`,
      clientTitle: `This field is not required but can be used if you want clients to see a different title than what respondents see.`,
      type: type.filter(Boolean).join('\n\n'),
    };
  }, [
    surveyProjects,
  ]);

  return tooltip;
};