import styles from './style.css';

type Props = {
  value: string;
  label: string;
};

export function ReadOnlyField({ value, label }: Props) {
  return (
    <div className={styles.root}>
      <div className={styles.label}>{label}</div>
      <div className={styles.value}>{value}</div>
    </div>
  );
}

export default ReadOnlyField;