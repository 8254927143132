import { CurrencyLegacy as SurveyCurrencyLegacy } from '@/types/survey.currency';
import { CurrencyCode } from '@/types/currencies';

/**
 * @deprecated Use formatCurrency instead
 */
export const CURRENCIES_LEGACY: SurveyCurrencyLegacy[] = ['USD', 'USD_WITH_CENTS', 'EUR', 'EUR_WITH_CENTS', 'CAD'];

/**
 * @deprecated Use formatCurrency instead
 */
export const CURRENCY_FORMATTERS_LEGACY = {
  CAD: Intl.NumberFormat('en-US', { style: 'currency', currency: 'CAD', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
  USD: Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
  USD_WITH_CENTS: Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
  EUR: Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
  EUR_WITH_CENTS: Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
};

/**
 * @deprecated Use formatCurrency instead
 */
export const CURRENCY_FORMATTER_NAMES_LEGACY: Record<SurveyCurrencyLegacy, string> = {
  'CAD': 'CAD',
  'USD': 'USD',
  'USD_WITH_CENTS': 'USD with cents',
  'EUR': 'EUR',
  'EUR_WITH_CENTS': 'EUR with cents',
};

/**
 * @deprecated Use formatCurrency instead
 */
export function formatCurrencyLegacy(value: number, formatter = CURRENCY_FORMATTERS_LEGACY.USD_WITH_CENTS) {
  if (!formatter) throw new Error('Formatter must not be null.');
  return formatter.format(value);
}

/**
 * @deprecated Use formatCurrency instead
 */
export function getCurrencySymbolLegacy(format: keyof typeof CURRENCY_FORMATTERS_LEGACY) {
  const formatterOptions = CURRENCY_FORMATTERS_LEGACY[format].resolvedOptions();
  return (0).toLocaleString(formatterOptions.locale, { style: 'currency', currency: formatterOptions.currency, minimumFractionDigits: 0, maximumFractionDigits: 0 })
    .replace(/\d/g, '')
    .trim();
}

type FormatOptions = {
  locale: string;
  style: 'currency' | 'decimal' | 'percent' | 'unit';
  currencyDisplay: 'symbol' | 'narrowSymbol' | 'code' | 'name';
  minimumFractionDigits: number;
  maximumFractionDigits: number;
};

const CurrencyFormattersCache: Record<string, Intl.NumberFormat> = {};

function getCurrencyFormatter(currency: CurrencyCode, options: Partial<FormatOptions>) {
  const { locale = 'en-US', ...opts } = options;

  const mergedOptions = {
    style: 'currency',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency,
    ...opts,
  };

  const key = [
    locale,
    currency,
    mergedOptions.style,
    mergedOptions.currencyDisplay,
    mergedOptions.minimumFractionDigits,
    mergedOptions.maximumFractionDigits,
  ].map(v => v ?? '').join(':');

  if (CurrencyFormattersCache[key]) {
    return CurrencyFormattersCache[key];
  } else {
    const formatter = new Intl.NumberFormat(locale, mergedOptions);
    CurrencyFormattersCache[key] = formatter;
    return formatter;
  }
}

export function formatCurrency(value: number, currency: CurrencyCode, options: Partial<FormatOptions> = {}) {
  const formatter = getCurrencyFormatter(currency, options);
  return formatter.format(value);
}

export function getCurrencySymbol(currency: CurrencyCode, locale = 'en-US') {
  return (0).toLocaleString(locale, { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 })
    .replace(/\d/g, '')
    .trim();
}