import { useMemo } from 'react';
import { useSurveyBuilderState, LanguageEditingContainer, CapabilitiesContainer, useSurveyBuilderScreenState } from '@/containers/SurveyBuilder';
import { useIsAdminPage, cx } from '@/utils';
import { SurveySectionsBuilder } from './SectionsBuilder';
import { LanguageCodeSection } from './LanguageSection';
import styles from './style/MultiLanguageBuilder.css';

export const MultiLanguageBuilder = () => {
  const [state, dispatch] = useSurveyBuilderState();
  const { additionalLanguageCodes, toggleAdditionalLanguageCode } = useSurveyBuilderScreenState();

  const isAdminPage = useIsAdminPage();

  const sectionStyles = useMemo(() => ({ width: `${Math.floor(100 / (additionalLanguageCodes.length + 1))}%` }), [additionalLanguageCodes.length]);

  const defaultLanguageCode = state.survey.language.languages.find(l => l.isDefault)?.code;

  return (
    <div className={cx(styles.root, isAdminPage ? null : styles.clientRoot)}>
      <CapabilitiesContainer
        canAddItems={false}
        canEditItems={false}
        canEditSections={false}>
        <div style={sectionStyles}>
          <LanguageCodeSection languageCode={defaultLanguageCode}>
            <SurveySectionsBuilder />
          </LanguageCodeSection>
        </div>
      </CapabilitiesContainer>
      <CapabilitiesContainer canAddItems={false} canEditSections={false}>
        {additionalLanguageCodes.map(c => (
          <LanguageEditingContainer key={c} languageCode={c}>
            <div style={sectionStyles}>
              <LanguageCodeSection languageCode={c} onClose={() => toggleAdditionalLanguageCode(c)}>
                <SurveySectionsBuilder />
              </LanguageCodeSection>
            </div>
          </LanguageEditingContainer>
        ))}
      </CapabilitiesContainer>
    </div>
  );
};