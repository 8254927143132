import { useMemo } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder';
import { useToggle } from '@utils';
import { useGetSurveyDrafts, useDiscardSurveyDrafts } from '@utils/api/survey';
import { Alert } from '@/components/Modal/Alert';
import { DraftBanner } from './Draft.Banner';
import { useDraftModal } from './DraftNotification.Modal';

type Props = {
  outerClassName?: string;
};

export const DraftNotification = ({ outerClassName }: Props) => {
  const [surveyState] = useSurveyBuilderState();
  const surveyId = useMemo(() => surveyState.survey?.surveyId, [surveyState]);
  const [closeWarningOpen, toggleCloseWarning] = useToggle(false);

  const query = useGetSurveyDrafts({ surveyId });

  const discardDrafts = useDiscardSurveyDrafts({ surveyId }, {
    onSuccess: () => {
      query.refetch();
      toggleCloseWarning();
    },
  });

  const [toggleDraftModal, DraftModal] = useDraftModal();

  if (surveyState.draft.surveyVersionId || surveyState.survey.template?.type) {
    return null;
  }

  if (query.isLoading || !query.data?.length) {
    return null;
  }

  return (
    <>
      <DraftBanner
        className={outerClassName}
        onClick={toggleDraftModal}
        onDismiss={toggleCloseWarning} />
      <DraftModal
        drafts={query.data}
        onDraftDiscarded={() => query.refetch()}
        surveyId={surveyId} />
      <Alert
        open={closeWarningOpen}
        message={'This will discard all drafts in progress. Are you sure?'}
        confirmText='Yes'
        onConfirm={() => discardDrafts.mutate()}
        onClose={toggleCloseWarning} />
    </>
  );
};