import { useCallback } from 'react';
import { useMaxDiffSurveyResponses } from '@containers/SurveyResponses/hooks/useSurveyResponsesContext';
import { SurveyResponsesHeader } from '@presentation/ProjectSurveyResponses';
import { MaxDifferenceQuestion } from '@/types';
import Chart from './MaxDiff.Chart';
import Data from './MaxDiff.Data';

export default function MaxDiffSurveyResponses() {
  const {
    onOptionClick,
    question,
    responses,
    sortedOptions,
  } = useMaxDiffSurveyResponses();

  const handleClick = useCallback((optionId: number, choice: MaxDifferenceQuestion.Choices) => {
    onOptionClick(optionId)(choice);
  }, [onOptionClick]);

  return (
    <>
      <SurveyResponsesHeader
        question={question} />
      <Chart
        options={sortedOptions}
        responses={responses}
        onClick={handleClick} />
      <Data />
    </>
  );
}

export { MaxDiffSurveyResponses };