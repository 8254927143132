import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { MedicalBackgroundIdentificationContext, MedicalBackgroundContactContext, MedicalBackgroundContext } from '@containers/MedicalOnboarding/Context';
import { useBackgroundContactValidation, useOnSubmitContact, useResearchParticipationOptions } from '@containers/MedicalOnboarding/hooks';
import { ContactMethod } from '@enums';
import { cx } from '@utils';
import { Checkbox } from '@/components/Checkbox';
import * as Field from '@/components/MedicalProfile/Field';
import * as Input from '@/components/MedicalProfile/Input';
import * as Select from '@/components/MedicalProfile/Select';
import * as Layout from '@/components/MedicalProfile/Layout';
import { Label } from '@/components/MedicalProfile/Label';
import { RadioGroup } from '@/components/MedicalProfile/RadioGroup';
import { TelInputState } from '@/components/TelInput';
import { BackNavigationProps, NextNavigationProps, OnSuccessHandler } from './interfaces';
import styles from './style/Contact.css';

type Props = {
  back?:      BackNavigationProps;
  next?:      NextNavigationProps;
  onSuccess?: OnSuccessHandler<'contact'>;
};

export const Contact = ({ back = {}, next = {}, onSuccess, ...props }: Props) => {
  const ctx = useContext(MedicalBackgroundContext);
  const identification = useContext(MedicalBackgroundIdentificationContext);
  const contact = useContext(MedicalBackgroundContactContext);
  const [phone, setPhone] = useState<Omit<TelInputState, 'value'>>({
    empty: !contact.form.phone,
    valid: true,
  });
  const [phoneAlternate, setPhoneAlternate] = useState<Omit<TelInputState, 'value'>>({
    empty: !contact.form.phoneAlternate,
    valid: true,
  });

  const participation = useResearchParticipationOptions();

  const [tzCountry, setTzCountry] = useState<string>(identification.form.country);

  useEffect(() => {
    setTzCountry(identification.form.country);
  }, [identification.form.country]);

  const handleSubmit = useOnSubmitContact(data => {
    ctx.mutation.mutate({
      contact: {
        contactMethod: data.contactMethod,
        market: data.market,
        phone: data.phone,
        phoneAlternate: data.phoneAlternate,
        reminderMethod: data.reminderMethod,
        smsConsent: data.smsConsent,
        timezone: data.timezone,
        inPersonResearch: data.inPersonResearch,
        virtualResearch: data.virtualResearch,
        surveyResearch: data.surveyResearch,
        phoneResearch: data.phoneResearch,
      },
    }, {
      onSuccess: (data, variables) => {
        onSuccess?.(variables);
      },
    });
  }, [
    ctx.mutation,
    onSuccess,
  ]);

  const handleChange = useCallback((name: 'phone' | 'phoneAlternate', data: TelInputState) => {
    if (name === 'phone') {
      setPhone({
        empty: data.empty,
        valid: data.valid,
      });
    } else if (name === 'phoneAlternate') {
      setPhoneAlternate({
        empty: data.empty,
        valid: data.valid,
      });
    }

    contact.setValue({ [name]: data.value });
  }, [
    contact,
    setPhone,
    setPhoneAlternate,
  ]);

  const { errors, meta } = useBackgroundContactValidation({
    phone,
    phoneAlternate,
  });

  const disabled = useMemo(() => {

    return Object.values(errors).some(x => x === true);

  }, [errors]);

  return (
    <Layout.Screen>
      <Layout.Header title={copy.title} />
      <div className={styles.item}>
        <Label required>
          {copy.label.phone}
        </Label>
        <div className={cx(styles.field, styles.phone)}>
          <Input.Phone
            defaultValue={contact.form.phone}
            name="phone"
            id="phone-number"
            invalid={!phone.valid}
            onChange={(e, data) => handleChange('phone', data)}
            onChangeCountryCode={data => handleChange('phone', data)} />
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.row}>
          <div className={styles.label}>{copy.label.phoneAlternate}</div>
        </div>
        <div className={cx(styles.field, styles.phone)}>
          <Input.Phone
            defaultValue={contact.form.phoneAlternate}
            name="phone-alternate"
            id="alternate-number"
            invalid={!phoneAlternate.valid && !phoneAlternate.empty}
            onChange={(e, data) => handleChange('phoneAlternate', data)}
            onChangeCountryCode={data => handleChange('phoneAlternate', data)} />
        </div>
      </div>

      <div className={styles.item}>
        <Label required>
          {copy.label.timezone} {tzCountry && <span className={styles.tzall} onClick={() => setTzCountry(null)}>(Show All)</span>}
        </Label>
        <div className={styles.field}>
          <Field.Timezone
            className={styles.select}
            country={tzCountry}
            onChange={item => contact.setValue({ timezone: item.value })}
            value={contact.form.timezone} />
        </div>
      </div>
      <div className={styles.item}>
        <Label required>
          {copy.label.contactMethod}
        </Label>
        <div className={styles.field}>
          <RadioGroup
            onChange={id => contact.setValue({ contactMethod: +id })}
            options={options}
            selected={contact.form.contactMethod} />
        </div>
      </div>
      <div className={styles.item}>
        <Label required>
          {copy.label.reminderMethod}
        </Label>
        <div className={styles.field}>
          <RadioGroup
            onChange={id => contact.setValue({ reminderMethod: +id })}
            options={options}
            selected={contact.form.reminderMethod} />
        </div>
      </div>
      {meta.smsConsent.visible &&
        <div className={styles.item}>
          <Label required>
            {copy.label.smsConsent}
          </Label>
          <div className={styles.field}>
            <div className={styles.sms}>
              <Checkbox
                checked={contact.form.smsConsent}
                onChange={e => contact.setValue({ smsConsent: e.target.checked })} />
              <div className={styles.consent}>{copy.consent}</div>
            </div>
          </div>
        </div>}
      <div className={styles.item}>
        <Label required>
          {copy.label.virtualResearch}
        </Label>
        <div className={styles.field}>
          <Select.Input
            className={styles.research}
            id="virtualResearch"
            name="virtualResearch"
            onChange={e => contact.setValue({ virtualResearch: +e.target.value })}
            options={participation.virtual}
            value={contact.form.virtualResearch ? String(contact.form.virtualResearch) : ''}
            placeholder={'Select Preference'} />
        </div>
      </div>
      <div className={styles.item}>
        <Label required>
          {copy.label.inPersonResearch}
        </Label>
        <div className={styles.field}>
          <Select.Input
            className={styles.research}
            id="inPerson"
            name="inPerson"
            onChange={e => contact.setValue({ inPersonResearch: +e.target.value })}
            options={participation.inPerson}
            value={contact.form.inPersonResearch ? String(contact.form.inPersonResearch) : ''}
            placeholder={'Select Preference'} />
        </div>
      </div>
      <div className={styles.item}>
        <Label required>
          {copy.label.surveyResearch}
        </Label>
        <div className={styles.field}>
          <Select.Input
            className={styles.research}
            id="surveyResearch"
            name="surveyResearch"
            onChange={e => contact.setValue({ surveyResearch: +e.target.value })}
            options={participation.survey}
            value={contact.form.surveyResearch ? String(contact.form.surveyResearch) : ''}
            placeholder={'Select Preference'} />
        </div>
      </div>
      <div className={styles.item}>
        <Label required>
          {copy.label.phoneResearch}
        </Label>
        <div className={styles.field}>
          <Select.Input
            className={styles.research}
            id="phoneResearch"
            name="phoneResearch"
            onChange={e => contact.setValue({ phoneResearch: +e.target.value })}
            options={participation.phone}
            value={contact.form.phoneResearch ? String(contact.form.phoneResearch) : ''}
            placeholder={'Select Preference'} />
        </div>
      </div>
      <Layout.Footer className={styles.footer}>
        {back && <Layout.BackButton />}
        <Layout.NextButton
          disabled={disabled}
          implicitDisable={false}
          loading={ctx.mutation.isLoading}
          onClick={handleSubmit}>
          {next.text}
        </Layout.NextButton>
      </Layout.Footer>
    </Layout.Screen>
  );
};

Contact.displayName = 'MedicalOnboarding.Background.Contact';

const copy = {
  consent: `By checking this box, you agree to accept text messages from Sentiment including new study invitations, appointment confirmations, payment status updates, and other research related communications. We will never sell this information or provide access to a third party.`,
  title: `Lastly, please provide your contact preferences so that the Sentiment team can optimize future outreach.`,
  label: {
    contactMethod: `Preference for contact`,
    phone: `Phone Number`,
    phoneAlternate: `Alternate Number`,
    reminderMethod: `Preference for Appointment Reminders`,
    timezone: `Time Zone`,
    smsConsent: `SMS Opt-in`,
    inPersonResearch: `What best describes your personal preference around In-person research`,
    virtualResearch: `What best describes your personal preference around engaging in virtual research`,
    surveyResearch: `What best describes your personal preference around Survey-based research`,
    phoneResearch: `What best describes your personal preference around Telephone-based research`,
  },
};

const options = [{
  id: ContactMethod.Phone,
  value: `Phone Calls`,
}, {
  id: ContactMethod.Text,
  value: `Text Message`,
}, {
  id: ContactMethod.Email,
  value: `Email`,
}];