import { useSelectCall, useSelectUser } from '@containers/Store';
import { CallType } from '@enums';
import { getLocationFor } from '@utils';
import { useOtherCallParticipant } from './useOtherCallParticipant';

type Params =
  & ICallId;

const useProposeTimesLocation = (params: Params) => {
  const me = useSelectUser();
  const call = useSelectCall(params.callId);

  const participant = useOtherCallParticipant({
    callId: params.callId,
    userId: me.id,
  });

  if (call.typeId === CallType.AdHoc) {
    return getLocationFor.scheduling.adHoc.proposing({
      callId: params.callId,
      scheduleeId: participant.id,
      schedulerId: me.id,
    });
  } else if (call.typeId === CallType.Project) {
    return getLocationFor.scheduling.proposing({
      callId: params.callId,
      projectId: call.projectId,
      scheduleeId: participant.id,
      schedulerId: me.id,
    });
  }

};

export { useProposeTimesLocation };
export default useProposeTimesLocation;