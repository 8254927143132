import { useSelector } from 'react-redux';
import * as enums from '@enums';
import { Copy } from '@screens/ProjectDetails/Copy';
import { ShareProject } from '@screens/ProjectDetails/ShareProject';
import { Title } from '@screens/ProjectDetails/Title';
import { VICSTags } from '@screens/ProjectDetails/VICSTags';
import { BackButton } from '@/components/BackButton';
import styles from './style.css';

const mapState = (state: Store.State) => ({
  projects: state.projects,
});

type Props =
    IProjectId;

const ProjectStaticDetails = (props: Props) => {
  const { projects } = useSelector(mapState);
  const project = projects[props.projectId];

  function buildVICSMap() {
    return project.vics.reduce((acc, x) => {
      const key = x.typeId;
      acc[key] = acc[key].concat(x);
      return acc;
    }, {
      [enums.VICS.Sector]: [],
      [enums.VICS.Industry]: [],
      [enums.VICS.SubIndustry]: [],
    });
  }

  function renderSharing() {
    const isSourcing = project.projectStatusId === enums.ProjectStatus.Active;

    if (!project.memberReferrals || !isSourcing) {
      return null;
    }

    return (
      <>
        <div className={styles.divider} />
        <div className={styles.right}>
          <ShareProject project={project} />
        </div>
      </>
    );
  }

  if (!project) return null;

  const vicsMap = buildVICSMap();
  const sectors = vicsMap[enums.VICS.Sector];
  const industries = vicsMap[enums.VICS.Industry];
  const subIndustries = vicsMap[enums.VICS.SubIndustry];

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.link}>
          <BackButton />
        </div>
        <div className={styles.main}>
          <div className={styles.left}>
            <div className={styles.row}>
              <Title text="Title" />
              <Copy text={project.name} />
            </div>
            <div className={styles.row}>
              <Title text="Description" />
              <Copy text={project.description} />
            </div>
            <VICSTags
              items={sectors}
              title={enums.VICSType.getName(enums.VICSType.Sector)} />
            <VICSTags
              items={industries}
              title={enums.VICSType.getName(enums.VICSType.Industry)} />
            <VICSTags
              items={subIndustries}
              title={enums.VICSType.getName(enums.VICSType.SubIndustry)} />
          </div>
          {renderSharing()}
        </div>
      </div>
    </div>
  );
};

export { ProjectStaticDetails };
export default ProjectStaticDetails;