import { format } from 'date-fns/fp';
import { cx } from '@utils';
import { MessageComponentType, PlatformMessageThread } from './interfaces';
import styles from './style/Thread.css';

type Props = {
  className?: string;
  children:   MessageComponentType[];
  item:       PlatformMessageThread;
};

const Thread = (props: Props) => {
  return (
    <div className={styles.root}>
      <div className={cx(styles.wrap, props.className)}>

        <div className={styles.row}>
          <span className={styles.label}>Sent On: </span>
          <span className={styles.text}>{format('MMM do, y h:mm a', props.item.lastMessage.timestamp)}</span>
        </div>
        <div className={styles.row}>
          <span className={styles.label}>Sent By: </span>
          <span className={styles.text}>{props.item.lastMessage.sender.name}</span>
        </div>
        <div className={styles.row}>
          <span className={styles.label}>Sent To: </span>
          <span className={styles.text}>{props.item.lastMessage.recipient.name}</span>
        </div>

        <div className={styles.divider}>Message Response</div>

        <div className={styles.main}>
          {props.children}
        </div>

      </div>
    </div>
  );
};

export { Thread };
export default Thread;