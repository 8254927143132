import { useState, useCallback, useRef } from 'react';
import { Room, TwilioError, LocalVideoTrack, LocalAudioTrack } from 'twilio-video';
import { generateScreenTrackName } from '@/components/Conference.Video/constants';

type ErrorCallback = (err: Error) => void;

export function useScreenShareToggle(room: Room, onError: ErrorCallback) {
  const [isAcquiring, setIsAcquiring] = useState<boolean>(false);
  const [isSharing, setIsSharing] = useState<boolean>(false);
  const stopScreenShareRef = useRef<() => void>(null);

  const shareScreen = useCallback(() => {
    if (!isAcquiring) {
      setIsAcquiring(true);
      navigator.mediaDevices
        .getDisplayMedia({
          audio: true,
          video: true,
        })
        .then(stream => {
          const videoTrack = new LocalVideoTrack(stream.getVideoTracks()[0], {
            name: generateScreenTrackName(),
          });
          const streamAudioTrack = stream.getAudioTracks()[0];

          const audioTrack = streamAudioTrack ? new LocalAudioTrack(streamAudioTrack) : null;

          const tracks = [videoTrack, audioTrack].filter(Boolean);

          return room.localParticipant
            .publishTracks(tracks)
            .then(trackPublications => {
              for (const track of trackPublications) {
                if (track.kind === 'video')
                  track.setPriority('high');
              }
              stopScreenShareRef.current = () => {
                for (const track of tracks) {
                  room.localParticipant.unpublishTrack(track);
                  track.stop();
                }
                // TODO: remove this if the SDK is updated to emit this event
                for (const trackPublication of trackPublications) {
                  room.localParticipant.emit('trackUnpublished', trackPublication);
                }
                setIsSharing(false);
              };

              videoTrack.mediaStreamTrack.onended = stopScreenShareRef.current;
              setIsSharing(true);
            })
            .catch(onError);
        })
        .catch(err => {
          const error = err as TwilioError;
          if (error.name !== 'AbortError' && error.name !== 'NotAllowedError') {
            onError(error);
          }
        })
        .finally(() => setIsAcquiring(false));
    }
  }, [room, isAcquiring, onError]);

  const toggleScreenShare = useCallback(() => {
    if (room) {
      !isSharing ? shareScreen() : stopScreenShareRef.current();
    }
  }, [isSharing, shareScreen, room]);

  return [isSharing, toggleScreenShare] as const;
}

export default useScreenShareToggle;