import { useContext, useMemo, useEffect } from 'react';
import { useTable, useFlexLayout, useSortBy } from 'react-table';
import { RateLimitingContext } from '@containers/RateLimiting/Context';
import { useFileDragDrop } from '@/components/FileDragDrop/hooks/useFileDragDrop';
import { GroupTable } from '@/presentation/GroupTable';
import { cx } from '@/utils';
import { useFilesTableColumns } from './hooks/useFilesTableColumns';
import type { IFilesTable } from './interfaces';
import styles from './style/Files.Table.css';

type Props = {
  classes?: {
    root?: string;
  };
  canUpload: boolean;
  items: IFilesTable.Item[];
  onUpload?: (files: File[]) => unknown;
  pagination: {
    pageIndex: number;
    pageSize: number;
    sortBy: IFilesTable.SortingRule[];
    updateSortBy: (sortBy: IFilesTable.SortingRule) => unknown;
  };
};

export const FilesTableList = ({ canUpload, items, pagination: { pageIndex, pageSize, sortBy, updateSortBy }, onUpload, classes = {} }: Props) => {
  const columns = useFilesTableColumns();
  const { query } = useContext(RateLimitingContext);

  const { dragging, dropRef } = useFileDragDrop({
    disabled: query.data?.documents?.exceeded,
    onDrop: onUpload,
  });

  const tableInstance = useTable(
    {
      columns,
      data: items,
      initialState: {
        pageIndex,
        pageSize,
        sortBy: sortBy.map(sb => ({ id: sb.field, desc: sb.desc })),
      },
      manualSortBy: true,
    },
    useFlexLayout,
    useSortBy,
  );

  useEffect(() => {
    const sort = tableInstance.state.sortBy[0];
    updateSortBy({
      field: sort.id as IFilesTable.SortingRule['field'],
      desc: sort.desc,
    });
  }, [
    tableInstance.state.sortBy,
    updateSortBy,
  ]);

  const tableClasses = useMemo(() => ({
    body: cx(styles.body, classes.root),
    td: styles.td,
  }), [classes.root]);

  if (canUpload) {
    return (
      <div
        ref={dropRef}
        className={cx({
          [styles.dragging]: dragging,
        })}>
        <GroupTable
          classes={tableClasses}
          instance={tableInstance} />
      </div>
    );
  } else {
    return (
      <GroupTable
        classes={tableClasses}
        instance={tableInstance} />
    );
  }
};

export default FilesTableList;