import * as api from '@api';
import * as API from '@api/interfaces';
import { UseDownloaderOptions, useQueryDownloader } from '@containers/QueryDownloader';

type Params = API.Transcripts.DownloadTranscriptTags.Request;

export const useDownloadTranscriptHighlights = (params: Params, options: UseDownloaderOptions<API.Transcripts.DownloadTranscriptTags.Response> = {}) => {
  return useQueryDownloader({
    queryKey: [
      `get:transcripts/tags/export`,
      params,
    ],
    queryFn: () => {
      return api.transcripts.downloadTranscriptTags({
        format: params.format,
        transcriptId: params.transcriptId,
      });
    },
    downloaderOptions: {
      onResponse: res => ({
        type: 'websocket',
        value: res.websocketKey,
      }),
    },
    ...options,
  });
};