import { SurveyAdvancedQuotaBuilderState } from './State.Advanced';
import { AdvancedQuotaValidationsContainer } from './AdvancedValidationsContainer';
import { SurveyAdvancedQuotaBuilder } from './interfaces';

type Props = {
  initial: SurveyAdvancedQuotaBuilder.State;
} & ChildrenProps;

export const AdvancedQuotaContainer = (props: Props) => {

  return (
    <SurveyAdvancedQuotaBuilderState initial={props.initial}>
      <AdvancedQuotaValidationsContainer>
        {props.children}
      </AdvancedQuotaValidationsContainer>
    </SurveyAdvancedQuotaBuilderState>
  );
};