import { Reducer, useCallback, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFetchComplianceMessageThreadReview } from '@utils/api';
import { MessageReviewDispatchContext, MessageReviewStateContext } from './Context';
import { ComplianceReview, MessageReviewParams } from './interfaces';

const mapState = (state: Store.State) => state.group;

type Props = {
  children: React.ReactNode;
};

const MessageReviewContainer = (props: Props) => {
  const group = useSelector(mapState);
  const params = useParams<MessageReviewParams>();
  const [response, fetch] = useFetchComplianceMessageThreadReview();
  const [state, dispatch] = useReducer<Reducer<ComplianceReview.MessageReviewState | undefined, ComplianceReview.MessageAction>>(thread, response.value);

  const fetchItem = useCallback(() => {

    fetch({
      groupId: group.id,
      threadId: +params.threadId,
    });

  }, [
    fetch,
    group,
    params,
  ]);

  useEffect(() => {

    fetchItem();

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {

    if (response.value) {
      dispatch({
        data: response.value,
        type: 'init',
      });
    }

  }, [
    dispatch,
    response.value,
  ]);

  return (
    <MessageReviewDispatchContext.Provider value={dispatch}>
      <MessageReviewStateContext.Provider value={state}>
        {props.children}
      </MessageReviewStateContext.Provider>
    </MessageReviewDispatchContext.Provider>
  );
};

function thread(acc: ComplianceReview.MessageReviewState, action: ComplianceReview.MessageAction) {
  switch (action.type) {
    case 'init':
      return action.data;

    case 'update': {
      const { message } = action.data;

      const messages = acc.thread.messages.map(item => {
        if (item.message.id !== message.id) return item;

        return {
          ...item,
          message: {
            ...item.message,
            ...message,
          },
        };
      });

      return {
        ...acc,
        thread: {
          ...acc.thread,
          messages,
        },
      };
    }

    default:
      return acc;
  }
}

export { MessageReviewContainer };
export default MessageReviewContainer;