import { useMemo, useRef } from 'react';
import { PersistentURLStateContext } from './Context';

type Props = {
  children: React.ReactNode;
};

const PersistentURLStateContainer = (props: Props) => {
  const ref = useRef(new Map<string, unknown>());

  const map = useMemo(() => ({
    get: <T extends unknown>(key: string) => {
      return ref.current.get(key) as T;
    },
    set: <T extends unknown>(key: string, val: T) => {
      ref.current.set(key, val);
    },
    unset: (key: string) => {
      ref.current.has(key) && ref.current.delete(key);
    },
  }), [ref]);

  return (
    <PersistentURLStateContext.Provider value={map}>
      {props.children}
    </PersistentURLStateContext.Provider>
  );
};

export { PersistentURLStateContainer };
export default PersistentURLStateContainer;