import * as types from '@store/action-types';

export const locationChange = data => {
  return { type: types.LOCATION_CHANGE, ...data };
};

export const updateLocation = data => {
  return { type: types.UPDATE_LOCATION, ...data };
};

export const popFromHistory = () => {
  return { type: types.POP_FROM_HISTORY };
};
