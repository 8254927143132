import type { Auth0Error } from 'auth0-js';

export const signup = {
  invalid_password: `Password must be at least 8 characters and contain a lower case letter (a-z), upper case letter (A-Z) and number (0-9)`,
  password_dictionary_error: `Password is too common`,
  password_no_user_info_error: `Password should not contain user information`,
  password_strength_error: `The chosen password is too weak`,
  unauthorized: `Unauthorized`,
  user_exists: `User already exists`,
  username_exists: `Username is already in use`,
};

export const login = {
  access_denied: `Access denied`,
  invalid_grant: 'Wrong email or password',
  invalid_user_password: `Incorrect username or password`,
  mfa_invalid_code: `Multi-factor authentication code is invalid`,
  mfa_registration_required: `Multi-factor authentication is required`,
  mfa_required: `Multi-factor authentication code required`,
  password_leaked: `Password reset needed`,
  PasswordHistoryError: `Password has already been used`,
  PasswordStrengthError: `Password provided does not match strength requirements`,
  too_many_attempts: `Too many attempts to sign in`,
  unauthorized: `Unauthorized`,
};

export const rethrow = (e: Auth0Error) => {
  const original = e.original as DetailedAuth0Error;

  if (original?.response?.body) {
    const body = original?.response?.body;
    if ('friendly_message' in body) {
      throw new Error(body.friendly_message ?? body.message ?? body.name);
    }
  }

  if (typeof e.description === 'string') {
    throw new Error(format(e.description));
  } else if (typeof e.description === 'object') {
    // TODO: parse error message to display password rules
    if (e.code === 'invalid_password') {
      throw new Error(signup[e.code]);
    }
  }

  throw e;
};

function format(message: string) {
  return message[0].toUpperCase() + message.slice(1);
}

type ExtensibilityError = {
  name: string;
  statusCode: number;
  code: string;
  message: string;
  friendly_message: string;
  description: string;
};

type GeneralError<D = unknown> = {
  name: string;
  statusCode: number;
  code: string;
  message: string;
  policy?: string;
  description: D;
};

type DetailedAuth0Error<D = unknown> = {
  response: {
    body: ExtensibilityError | GeneralError<D>;
  };
};

// {
//   "name": "InternalExtensibilityError",
//   "statusCode": 400,
//   "code": "extensibility_error",
//   "message": "sso-already-exists",
//   "friendly_message": "Please sign in with your existing Google, Facebook, or Apple account",
//   "description": "sso-already-exists",
//   "fromSandbox": true
// }

// {
//   "name": "PasswordStrengthError",
//   "message": "Password is too weak",
//   "code": "invalid_password",
//   "description": {
//     "rules": [
//       {
//         "message": "At least %d characters in length",
//         "format": [
//           4
//         ],
//         "code": "lengthAtLeast",
//         "verified": false
//       }
//     ],
//     "verified": false
//   },
//   "policy": "* At least 4 characters in length",
//   "statusCode": 400
// }