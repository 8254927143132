import { useCallback, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import type * as API from '@api/interfaces';
import { ButtonOutlined } from '@presentation';
import { MoreHorizontalAnchor } from '@presentation/Anchor';
import { useToggle } from '@utils/hooks';
import { Spinner } from '@/components/ActivityIndicator';
import { Button } from '@/components/Button';
import { Checkbox } from '@/components/Checkbox';
import { useUserVerificationContextMenu } from '@/components/ObjectAccess/hooks';
import { PopperMenu, PopperMenuItem, useZIndexModifier } from '@/components/Popper';
import { Portal } from '@/components/Portal';
import type { Entry } from './interfaces';
import styles from './style/ProjectAccess.css';

type Props = {
  busy?: boolean;
  item: Entry;
  onBeforeCreateClientUser?: (params: { email: string }) => void;
  onSuccessCreateClientUser?: (res: API.Users.RegisterClientUsers.Response) => void;
} & IProjectId;

export const ProjectAccessContextMenu = (props: Props) => {
  const userVerificationContextMenu = useUserVerificationContextMenu({
    projectId: props.projectId,
    onBeforeCreateClientUser: props.onBeforeCreateClientUser,
    onSuccessCreateClientUser: props.onSuccessCreateClientUser,
  });
  const menu = userVerificationContextMenu(props.item);

  if (!menu.visible) return null;

  if (props.busy) {
    return (
      <Spinner
        color="#979797"
        size={18} />
    );
  }

  return (
    <ContextMenu
      item={props.item}
      onClickCreateClientUser={menu.onClickCreateClientUser}
      onClickSendInviteEmail={menu.onClickSendInviteEmail} />
  );
};

type HigherOrderParams = {
  activeClientUserCreationEmail?: string;
  onBeforeCreateClientUser?: (params: { email: string }) => void;
  onSuccessCreateClientUser?: (res: API.Users.RegisterClientUsers.Response) => void;
} & IProjectId;

export const useProjectAccessContextMenu = (params: HigherOrderParams) => {
  const ProjectAccessContextMenuWrapper = useMemo(() => {
    return (props: Pick<Props, 'item'>) => {
      return (
        <ProjectAccessContextMenu
          busy={params.activeClientUserCreationEmail === props.item.email}
          onBeforeCreateClientUser={params.onBeforeCreateClientUser}
          onSuccessCreateClientUser={params.onSuccessCreateClientUser}
          projectId={params.projectId}
          {...props} />
      );
    };
  }, [params]);

  return ProjectAccessContextMenuWrapper;
};

type ContextMenuProps = {
  item: Entry;
  onClickCreateClientUser?: (data: { firstName?: string; lastName?: string; notify: boolean }) => void;
  onClickSendInviteEmail?: () => void;
};

const ContextMenu = ({ item, onClickCreateClientUser, onClickSendInviteEmail }: ContextMenuProps) => {
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [clientName, toggleClientName] = useToggle();

  const zIndexModifier = useZIndexModifier({ zIndex: 8 });
  const { styles: popperStyles, attributes: popperAttributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      zIndexModifier,
    ],
    placement: 'bottom-start',
  });

  const wrapClick = useCallback((handler: (() => void)) => {
    if (typeof handler === 'function') {
      return () => {
        handler();
        setOpen(false);
      };
    }
  }, []);

  const items = useMemo(() => {
    return [
      {
        children: 'Send Verification Email',
        onClick: wrapClick(onClickSendInviteEmail),
      },
      {
        children: 'Create New User Account',
        onClick: onClickCreateClientUser && wrapClick(toggleClientName),
      },
    ].filter(x => !!x.onClick);
  }, [
    onClickCreateClientUser,
    onClickSendInviteEmail,
    toggleClientName,
    wrapClick,
  ]);

  return (
    <>
      <div
        ref={setReferenceElement}
        onClick={() => setOpen(true)}>
        <MoreHorizontalAnchor
          className={styles.anchor}
          open={open} />
      </div>
      {open &&
        <Portal>
          <ClickAwayListener
            onClickAway={() => setOpen(false)}>
            <div
              ref={setPopperElement}
              style={popperStyles.popper}
              {...popperAttributes.popper}>
              <PopperMenu>
                {items.map((m, i) => (
                  <PopperMenuItem
                    key={i}
                    onClick={m.onClick}>
                    {m.children}
                  </PopperMenuItem>
                ))}
              </PopperMenu>
            </div>
          </ClickAwayListener>
        </Portal>}
      {clientName &&
        <CreateClientUserDialogBox
          item={item}
          onClose={toggleClientName}
          onSubmit={onClickCreateClientUser} />}
    </>
  );
};

type CreateClientUserDialogBoxProps = {
  item: Entry;
  onClose: () => void;
  onSubmit: (data: Parameters<ContextMenuProps['onClickCreateClientUser']>[0]) => void;
};

const CreateClientUserDialogBox = ({ item, onClose, onSubmit }: CreateClientUserDialogBoxProps) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [notify, setNotify] = useState(true);

  const handleSubmit = useCallback(() => {
    onSubmit({
      firstName,
      lastName,
      notify,
    });
    onClose();
  }, [
    firstName,
    lastName,
    notify,
    onClose,
    onSubmit,
  ]);

  return (
    <Portal>
      <div className={styles.dialog}>
        <div className={styles.inner}>
          <div className={styles.title}>Create New Client User Account</div>
          <div className={styles.field}>
            <div className={styles.dlabel}>Email</div>
            <div>{item.email}</div>
          </div>
          <div className={styles.field}>
            <div className={styles.dlabel}>First Name</div>
            <input
              className={styles.input}
              onChange={e => setFirstName(e.target.value)}
              value={firstName} />
          </div>
          <div className={styles.field}>
            <div className={styles.dlabel}>Last Name</div>
            <input
              className={styles.input}
              onChange={e => setLastName(e.target.value)}
              value={lastName} />
          </div>
          <div className={styles.checkboxRow}>
            <Checkbox
              checked={notify}
              onChange={(_, checked) => setNotify(checked)} />
            Send Verification Email
          </div>
          <div className={styles.footer}>
            <ButtonOutlined
              className={styles.btn}
              color="silver"
              fontWeight="bold"
              onClick={onClose}>
              Cancel
            </ButtonOutlined>
            <Button
              className={styles.btn}
              onClick={handleSubmit}>
              Create Account
            </Button>
          </div>
        </div>
      </div>
    </Portal>
  );
};