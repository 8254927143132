import { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import * as actions from '@actions';
import * as api from '@api';
import { Users } from '@api/interfaces';
import { UserSettingsProps } from '@/types';

type Data = Users.UpdateSettings.Response;
type Variables = Partial<UserSettingsProps>;
type Context = UserSettingsProps;

type Options = UseMutationOptions<Data, AxiosResponse, Variables, Context>;

const selectUserId = (state: Store.State) => state.user.id;
const selectUserSettings = (state: Store.State) => state.user.settings;

export const useUpdateUserSettings = (options?: Options) => {
  const settings = useSelector(selectUserSettings);
  const userId = useSelector(selectUserId);
  const dispatch = useDispatch();

  return useMutation(['update-user-settings'], (data: Variables) => {

    dispatch(actions.userSettingsUpdated({
      settings: {
        ...settings,
        ...data,
      },
    }));

    return api.users.updateSettings({
      ...data,
      userId,
    }).catch(e => {
      dispatch(actions.userSettingsUpdated({ settings }));
      throw e;
    });
  }, options);
};