import { useCallback, useContext } from 'react';
import type { ClickMarkEventHandler } from '@remirror/extension-events';
import { useEditorEvent } from '@remirror/react';
import type { AutoCorrect } from '@/types/transcribe.rich-text';
import { MarkType } from '@/types/transcribe.rich-text';
import { TranscriptAutoCorrectContext } from '@containers/Transcript/context';

export const useAutoCorrectEvent = () => {

  const ctx = useContext(TranscriptAutoCorrectContext);

  const handleClick: ClickMarkEventHandler = useCallback((event, state) => {

    if (!ctx) return;

    const markRange = state.getMark(MarkType.AutoCorrect);

    if (!markRange) {
      return false;
    }

    ctx.setMark(markRange);

    return false;
  }, [ctx]);

  useEditorEvent('clickMark', handleClick);

};