import { useState } from 'react';
import type { AxiosError, AxiosResponse } from 'axios';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const buildQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: (failureCount, error: AxiosError | AxiosResponse) => {

          const status = (error as AxiosResponse)?.status ||
            (error as AxiosError)?.response?.status;

          if ([403, 404].includes(status)) {
            return false;
          }

          return failureCount < 3;
        },
      },
    },
    queryCache: new QueryCache({
      onSuccess: (res, query) => {
        if (query?.meta?.downloader && typeof query?.meta?.onResponse === 'function') {
          query.meta.onResponse(res);
        }
      },
    }),
  });
};

export default function ReactQueryClient({ children }: ChildrenProps) {

  const [queryClient] = useState(buildQueryClient);

  return (
    <QueryClientProvider client={queryClient}>
      <>
        {children}
        {/* <ReactQueryDevtools /> */}
      </>
    </QueryClientProvider>
  );
}