import { ApprovalStatus, UserProjectStatus as UserStatus, utils } from '@enums';
import { cx } from '@utils';
import { Project } from '$admin/interfaces';
import styles from './style/Badge.UserProjectStatus.css';

type Props = {
  children?:   React.ReactNode;
  className?:  string;
  scheduling?: Project.Members.Item['calls']['scheduling'];
  survey:      Project.Members.Item['survey'];
} & Pick<Project.Members.Item['pipeline'], 'statusId'>;

export const UserProjectStatus = (props: Props) => {
  const classes = cx(badgestyle[props.statusId]);

  const status = getDisplayStatus({
    scheduling: props.scheduling,
    survey: props.survey,
    statusId: props.statusId,
  });

  return (
    <div className={cx(styles.root, props.className)}>
      <div className={classes}>
        {status}
      </div>
      {props.children}
    </div>
  );
};

UserProjectStatus.displayName = 'Badge.UserProjectStatus';

const badgestyle = {
  [UserStatus.Active]: styles.joined,
  [UserStatus.Added]: styles.bookmarked,
  [UserStatus.Disqualified]: styles.rejected,
  [UserStatus.Inactive]: styles.inactive,
  [UserStatus.InReview]: styles.inreview,
  [UserStatus.Invited]: styles.invited,
  [UserStatus.Recommended]: styles.bookmarked,
  [UserStatus.Rejected]: styles.rejected,
  [UserStatus.Staging]: styles.staging,
};

function getDisplayStatus(params: Omit<Props, 'children'>) {
  if (params.statusId !== UserStatus.InReview) {
    return utils.UserProjectStatus.getName(params.statusId);
  }

  const screeningRequired = [
    params.scheduling?.screeningStatusId,
    params.survey?.screeningStatusId,
  ].includes(ApprovalStatus.NeedsApproval);
  const complianceRequired = [
    params.scheduling?.approvalStatusId,
    params.survey?.approvalStatusId,
  ].includes(ApprovalStatus.NeedsApproval);

  if (screeningRequired) {
    return `Sentiment Review`;
  } else if (complianceRequired) {
    return `Compliance Review`;
  } else {
    return utils.UserProjectStatus.getName(params.statusId);
  }
}