import { useCallback } from 'react';
import {
  MessageTesting,
  SurveyTemplateQuestion,
  SurveyTemplateEntityType,
} from '@enums/survey.template';
import { Remirror } from '@/types';
import {
  SurveyTemplate,
  SurveyBuilderVersion,
} from '@/types/survey';
import { SurveyBuilder } from '@containers/SurveyBuilder/interfaces';
import * as $templateUtils from '@containers/SurveyBuilder/state/state.template.utils';
import * as $template from '@containers/SurveyBuilder/template/utils';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';

export const useSubmitMessageCategories = () => {
  const [state, dispatch] = useSurveyBuilderState();

  return useCallback((value: SurveyTemplate.MessageTesting.Category[]) => {

    const actions = computeActions({
      saved: state.survey,
      value,
    });
    // console.log('actions', actions);

    dispatch({
      actions,
      type:'batch-actions',
    });
  }, [
    dispatch,
    state.survey,
  ]);
};

type ComputeActions = {
  saved: SurveyBuilderVersion;
  value: SurveyTemplate.MessageTesting.Category[];
};

function flattenMessages(categories: SurveyTemplate.MessageTesting.Category[]): SurveyTemplate.LinkedEntity[] {
  return categories.reduce<SurveyTemplate.LinkedEntity[]>((acc, category) => {
    return acc.concat(category.messages);
  }, []);
}

function computeActions({ saved, value }: ComputeActions): SurveyBuilder.NonBatchActions[] {

  const baseQuestion = saved.questions.find(f => f.metadata.template.key === MessageTesting.Question.MessageDimensionOne);
  const secondaryQuestion = saved.questions.find(f => f.metadata.template.key === MessageTesting.Question.MessageDimensionTwo);
  const orderingQuestion = saved.questions.find(f => f.metadata.template.key === MessageTesting.Question.OrderingExercise);

  const actions: SurveyBuilder.NonBatchActions[] = [];

  actions.push({
    type: 'template-message-categories-updated',
    payload: { value },
  });

  if (!baseQuestion) return actions;

  const messages = flattenMessages(value);
  const oldMessages = flattenMessages((saved.template.data as SurveyTemplate.MessageTesting.Data).categories);

  const {
    added: addedMessages,
    removed: removedMessages,
    updated: updatedMessages,
  } = $templateUtils.computeChangedValues({
    oldValues: oldMessages,
    values: messages,
  });

  const rowQuestions: SurveyTemplateQuestion[] = [
    MessageTesting.Question.MessageDimensionOne,
    MessageTesting.Question.MessageDimensionTwo,
  ];

  const removedRows = $templateUtils.computeRemovedRows({
    key: SurveyTemplateEntityType.CategoryMessage,
    questions: saved.questions,
    removedItems: removedMessages,
  });

  const baseRowsToAdd = addedMessages.map((m, i) => $template.generateRow({
    metadata: {
      canDelete: false,
      canModifyValue: false,
      template: {
        linkedEntity: {
          id: m.id,
          type: SurveyTemplateEntityType.CategoryMessage,
        },
      },
    },
    ordinal: i + 1 + baseQuestion.matrixRows.length,
    value: m.value,
  }));

  const secondaryRowsToAdd = baseRowsToAdd.map((row, i) => $template.generateRow({
    metadata: {
      canDelete: false,
      canModifyValue: false,
      linked: {
        question: { identifier: baseQuestion.base.identifier },
        row: { identifier: row.base.identifier },
      },
      template: row.metadata.template,
    },
    value: (row.value.content[0].content[0] as Remirror.TextNode).text,
    ordinal: row.ordinal,
  }));

  const addedRowActions = [
    {
      questionIdentifier: baseQuestion.base.identifier,
      reorder: false,
      rows: baseRowsToAdd,
    },
    {
      questionIdentifier: secondaryQuestion.base.identifier,
      reorder: false,
      rows: secondaryRowsToAdd,
    },
  ];

  const updatedRows = $templateUtils.computeUpdatedRows({
    questions: saved.questions,
    toCheck: rowQuestions,
    updatedItems: updatedMessages,
  });

  const optionQuestions: SurveyTemplateQuestion[] = [
    MessageTesting.Question.OrderingExercise,
  ];

  const removedOptions = $templateUtils.computeRemovedOptions({
    key: SurveyTemplateEntityType.CategoryMessage,
    questions: saved.questions,
    removedItems: removedMessages,
  });

  const updatedOptions = $templateUtils.computeUpdatedOptions({
    questions: saved.questions,
    toCheck: optionQuestions,
    updatedItems: updatedMessages,
  });

  const optionsToAdd = baseRowsToAdd.map((row, i) => $template.generateOption({
    metadata: {
      canDelete: false,
      canModifyValue: false,
      linked: {
        question: { identifier: baseQuestion.base.identifier },
        row: { identifier: row.base.identifier },
      },
      template: row.metadata.template,
    },
    value: (row.value.content[0].content[0] as Remirror.TextNode).text,
    ordinal: row.ordinal,
  }));

  const addedOptionActions = [
    {
      questionIdentifier: orderingQuestion.base.identifier,
      reorder: false,
      options: optionsToAdd,
    },
  ];

  removedOptions.forEach(f => {
    actions.push({
      type: 'remove-question-option',
      ...f,
    });
  });
  removedRows.forEach(f => {
    actions.push({
      type: 'remove-question-row',
      ...f,
    });
  });
  addedRowActions.forEach(f => {
    actions.push({
      type: 'add-question-rows',
      ...f,
    });
  });
  addedOptionActions.forEach(f => {
    actions.push({
      type: 'add-question-options',
      ...f,
    });
  });
  updatedRows.forEach(f => {
    actions.push({
      type: 'update-question-row-value',
      ...f,
    });
  });
  updatedOptions.forEach(f => {
    actions.push({
      type: 'update-question-option-value',
      ...f,
    });
  });

  return actions;
}