import { useContext } from 'react';
import { SiteStrategyStateContext } from '../Context';

type Params = unknown;

const useSiteStrategyState = () => {
  const state = useContext(SiteStrategyStateContext);

  return state;
};

export { useSiteStrategyState };
export default useSiteStrategyState;