import { useCallback, useEffect, useState } from 'react';
import { useSearchParams, useSearchQuery } from '@containers';
import { ActivityIndicator, AnimatedLoader } from '@/components/ActivityIndicator';
import { ResultsFooter } from './ResultsFooter';
import { ResultsItem } from './ResultsItem';
import styles from './style/ResultsPane.css';

type Props = unknown;

const ResultsPane = (props: Props) => {
  const [params] = useSearchParams();
  const [query, fetch] = useSearchQuery();
  const [loading, setLoading] = useState(false);

  const fetchNext = useCallback(async () => {
    setLoading(true);

    await fetch();

    setLoading(false);

  }, [fetch]);

  useEffect(() => {

    if (!query.loading) {
      fetch();
    }

  /* eslint-disable-next-line */
  }, [params]);

  const renderItems = () => {
    return (query.value?.items ?? []).map((x, i) =>
      <ResultsItem
        key={x.id}
        item={x} />
    );
  };

  const handleScrollPosition = (e: React.UIEvent<HTMLDivElement>) => {
    const scrollHeight = e.currentTarget.scrollHeight;
    const offsetHeight = e.currentTarget.offsetHeight;
    const scrollTop = e.currentTarget.scrollTop;

    const more = !query.loading
              && !loading
              && query.value?.total > query.value?.items?.length
              && query.value?.items?.length > 0
              && scrollHeight - (scrollTop + offsetHeight) <= 50;

    if (more) {
      fetchNext();
    }
  };

  return (
    <div
      className={styles.root}
      onScroll={handleScrollPosition}>
      <div className={styles.items}>
        {(!query.loading || (query.loading && loading)) && renderItems()}

        {query.loading &&
        !loading &&
          <ActivityIndicator show />}

        {loading &&
          <div className={styles.loader}>
            <AnimatedLoader className={styles.indicator} />
          </div>}

        {!(query.loading ||
          loading) &&
          <ResultsFooter />}
      </div>
    </div>
  );
};

export { ResultsPane };
export default ResultsPane;