import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { CompanyOverviewContainer } from '@containers/Company';
import { CompanyOverviewContext } from '@containers/Company/Context';
import { Header } from './Header';
import { Column, ScreenLayout } from './Layout';
import { Tabs } from './Tabs';
import { RouteParams } from './interfaces';
import styles from './style/CompanyLanding.css';

export function CompanyLanding() {
  const params = useParams<RouteParams>();

  return (
    <CompanyOverviewContainer
      enabled={!!params.slug}
      slug={params.slug}>
      <CompanyScreen />
    </CompanyOverviewContainer>
  );
}

function CompanyScreen() {
  const { query, ready } = useContext(CompanyOverviewContext);

  if (!ready) return null;

  return (
    <>
      <Helmet title={query.data.item?.name ?? 'Company'} />
      <ScreenLayout>
        <Column>
          <div className={styles.header}>
            <Header
              item={query.data.item} />
          </div>
          <Tabs
            item={query.data.item}
            meta={query.data.meta} />
        </Column>
      </ScreenLayout>
    </>
  );
}