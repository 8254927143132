import { useContext } from 'react';
import { ProjectOnboardingContext } from '@containers/ProjectOnboarding/Context';
import { MedicalExperienceNavigationContext } from '@containers/ProjectOnboarding.MedicalExperience/Context';
import { isProjectSurveyType } from '@containers/ProjectOnboarding/utils';
import { useSelectProject } from '@containers/Store';
import * as Layout from '@/components/MedicalProfile/Layout';

type Props = unknown;

export const ThankYou = (props: Props) => {
  const ctx = {
    navigation: useContext(MedicalExperienceNavigationContext),
    project: useContext(ProjectOnboardingContext),
  };
  const project = useSelectProject(ctx.project.projectId);

  const title = isProjectSurveyType({ projectType: project?.projectType })
      ? copy.survey
      : copy.screening;

  return (
    <Layout.Screen>
      <Layout.Header title={title} />
      <Layout.Footer>
        <Layout.NextButton onClick={ctx.navigation.next}>
          Continue
        </Layout.NextButton>
      </Layout.Footer>
    </Layout.Screen>
  );
};

ThankYou.displayName = 'Experience.ThankYou';

const copy = {
  screening: `Thank you, we have what we need on your background. Next we’ll go through a few specific screening questions for the project.`,
  survey: `Thank you, we have what we need on your background. Next you’ll be directed to complete the survey.`,
};