import { useContext, useMemo } from 'react';
import { MedicalBackgroundIdentificationContext } from '@containers/MedicalOnboarding/Context';
import { Length, Omitted, Optional, Required, test, validators } from '@containers/MedicalOnboarding/utils';
import { type ProfessionalRole } from '@enums';

type Params = ProfessionalRole;

export const useBackgroundIdentificationValidation = (role: Params) => {
  const identification = useContext(MedicalBackgroundIdentificationContext);

  const country = useMemo(() => !identification.form.country, [identification.form.country]);

  const isCountryUSA = useMemo(() => validators.isCountryUSA(identification.form.country), [identification.form.country]);

  const meta = useMemo(() => ({
    clinicalPracticeStartedOn: {
      visible: !Omitted.clinicalPracticeStartedOn.includes(role),
      required: isCountryUSA && !Optional.clinicalPracticeStartedOn.includes(role),
    },
    deaNumber: {
      length: Length.dea,
      visible: isCountryUSA && Optional.deaNumber.includes(role) && !Omitted.deaNumber.includes(role),
      required: false,
    },
    npiNumber: {
      length: Length.npi,
      required: isCountryUSA && !Optional.npiNumber.includes(role),
      visible: isCountryUSA && !Omitted.npiNumber.includes(role),
    },
    meNumber: {
      length: Length.me,
      visible: isCountryUSA && !Omitted.meNumber.includes(role),
      required: false,
    },
    stateLicenseNumber: {
      visible: isCountryUSA && Required.stateLicenseNumber.includes(role),
      required: isCountryUSA,
    },
    stateOfPractice: {
      visible: isCountryUSA && Required.stateOfPractice.includes(role),
      required: isCountryUSA,
    },
    usaPayer: {
      visible: isCountryUSA,
      required: isCountryUSA,
    },
  }), [
    role,
    isCountryUSA,
  ]);

  const clinicalPracticeStartedOn = useMemo(() => {
    if (!meta.clinicalPracticeStartedOn.visible) return false;
    if (!meta.clinicalPracticeStartedOn.required) return false;

    return !identification.form.clinicalPracticeStartedOn;
  }, [
    identification.form.clinicalPracticeStartedOn,
    meta,
  ]);

  const meNumber = useMemo(() => {
    if (!meta.meNumber.visible) return false;
    if (!meta.meNumber.required && identification.form.meNumber.length === 0) return false;

    return !(identification.form.meNumber.length === Length.me && test.digits(identification.form.meNumber));
  }, [
    identification.form.meNumber,
    meta,
  ]);

  const npiNumber = useMemo(() => {
    if (!meta.npiNumber.visible) return false;
    if (!meta.npiNumber.required && identification.form.npiNumber.length === 0) return false;

    return !(identification.form.npiNumber.length === Length.npi && test.digits(identification.form.npiNumber));
  }, [
    identification.form.npiNumber,
    meta,
  ]);

  const stateLicenseNumber = useMemo(() => {
    if (!meta.stateLicenseNumber.visible) return false;
    if (!meta.stateLicenseNumber.required) return false;

    return !identification.form.stateLicenseNumber.length;
  }, [
    identification.form.stateLicenseNumber,
    meta,
  ]);

  const stateOfPractice = useMemo(() => {
    if (!meta.stateOfPractice.visible) return false;
    if (!meta.stateOfPractice.required) return false;

    return !identification.form.stateOfPractice;
  }, [
    identification.form.stateOfPractice,
    meta,
  ]);

  const usaPayer = useMemo(() => {
    if (!meta.usaPayer.visible) return false;
    if (!meta.usaPayer.required) return false;

    return identification.form.isPayer == undefined;
  }, [
    identification.form.isPayer,
    meta,
  ]);

  const payerAdminType = useMemo(() => {
    if (!meta.usaPayer.visible) return false;
    if (!meta.usaPayer.required) return false;
    if (identification.form.isPayer !== true) return false;

    return !identification.form.payerAdminType;
  }, [
    identification.form.isPayer,
    identification.form.payerAdminType,
    meta,
  ]);

  return {
    meta,
    errors: {
      clinicalPracticeStartedOn,
      country,
      meNumber,
      npiNumber,
      payerAdminType,
      stateLicenseNumber,
      stateOfPractice,
      usaPayer,
    },
  };
};