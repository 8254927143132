import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { cx } from '@utils';
import type { ChatIconProps } from './interfaces';
import styles from './style.css';

export const ChatIcon = (props: ChatIconProps) => (
  <div
    className={cx(styles.icon, { [styles.active]: props.active }, props.className)}
    onClick={props.onClick}>
    <ChatBubbleOutlineOutlinedIcon />
    {!props.active && props.unread > 0 && <div className={styles.badge}>{props.unread}</div>}
  </div>
);