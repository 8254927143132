import { forwardRef } from 'react';
import styles from './style/URLPreview.css';

type Props = {
  description?: string;
  domain?:      string;
  image?:       {
    url: string;
  };
  size?:  'large' | 'small';
  title?: string;
};

const PreviewContent = ({ size = 'small', title = '', ...props }: Props, ref: React.RefObject<HTMLImageElement>) => {
  const showDomain = !props.description ||
        size === 'small' ||
        (size === 'large' && title.length < 30);

  return (
    <>
      <div className={styles.header}>
        {props.image?.url &&
          <img
            className={styles.image}
            crossOrigin="anonymous"
            ref={ref}
            src={props.image.url} />}
      </div>
      <div className={styles.info}>
        <div className={styles.details}>
          <div className={styles.content}>
            <div className={styles.title}>{title}</div>
            {showDomain &&
              <div className={styles.url}>{props.domain}</div>}
            <div className={styles.description}>{props.description}</div>
          </div>
        </div>
      </div>
    </>
  );
};

PreviewContent.displayName = 'URLPreview.Content';

export const Content = forwardRef(PreviewContent);