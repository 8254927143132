import { memo } from 'react';

type Props = {
  className?: string;
  size: number;
};

export const VideoCall = memo(({ className, size }: Props) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="1 1 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round">
        <g
          transform="translate(2, 6)"
          stroke="currentColor"
          strokeWidth="2">
          <polygon
            points="22 2 15 7 22 12" />
          <rect
            x="0"
            y="0"
            width="15"
            height="14"
            rx="2" />
        </g>
      </g>
    </svg>
  );
});