import { useCallback, useContext } from 'react';
import { VideoFileContext } from './Video.Context';
import { NativePlayerPoster } from './Video.NativePlayerPoster';
import styles from './style/Video.UploadVideo.VideoFilePreview.css';

type Props = unknown;

export const VideoFilePreview = (props: Props) => {
  const [_, setFile] = useContext(VideoFileContext);

  const handleDelete = useCallback(() => {
    setFile(null);
  }, [setFile]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <NativePlayerPoster className={styles.video} />

          <button
            className={styles.delete}
            onClick={handleDelete}>
            <div className={styles.x}>✕</div>
          </button>
        </div>
      </div>
    </div>
  );
};

VideoFilePreview.displayName = 'PostCreation.Video.UploadVideo.VideoFilePreview';