import { useCallback } from 'react';
import { useEditorEvent } from '@remirror/react';
import type { ClickEventHandler } from '@remirror/extension-events';
import { useTranscriptToolsPermission } from '@containers/Transcript/hooks';
import { NodeType } from '@/types/transcribe.rich-text';
import { useChainedCommands } from './useCommands';
import { useHelpers } from './useHelpers';

export const useFocusHighlightEvent = (disable = false) => {

  const { getHighlightsAt } = useHelpers();
  const commands = useChainedCommands();
  const permission = useTranscriptToolsPermission();

  const handleClick: ClickEventHandler = useCallback((e, state) => {

    if (!permission.highlight) return;

    const highlights = getHighlightsAt(state.pos);

    if (state.nodeWithPosition.node.type.name !== NodeType.MonologueText) return;

    if ((e.target as HTMLElement).nodeName === 'P') return;

    if (disable) return;

    if (highlights.length) {

      const shortest = highlights
        .sort((h1, h2) => {
          return (h1.text ?? '').length - (h2.text ?? '').length;
        })[0];

      commands
        .focusHighlight(shortest.id, true)
        .run();

    }
  }, [commands, disable, getHighlightsAt, permission.highlight]);

  useEditorEvent('click', handleClick);

};