import { useMemo, useCallback } from 'react';
import type { ColumnDef, CellContext } from '@tanstack/react-table';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';
import { MinusCircle } from 'react-feather';
import { SortableTable } from '@/presentation/Tables';
import { SectionHeader } from '@/access/admin/Project.Details/SectionHeader';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder';
import type { SurveyLanguage } from '@/types/survey.language';
import { useAddLanguageModal } from './AddLanguageModal';
import styles from './style/SurveySettings.css';

export const LanguageSettings = () => {
  const [state, dispatch] = useSurveyBuilderState();

  const columns = useMemo<ColumnDef<SurveyLanguage>[]>(() => [
    {
      cell: props => props.row.original.name,
      header: 'Language',
    }, {
      cell: props => props.row.original.code,
      header: 'Code',
    }, {
      id: 'actions',
      cell: LanguageActions,
      header: ' ',
      meta: {
        className: styles.actionsCell,
      },
    },
  ], []);

  const table = useReactTable({
    columns,
    data: state.survey.language.languages,
    getCoreRowModel: getCoreRowModel(),
  });

  const [toggleAddLanguage, AddLanguageModal] = useAddLanguageModal();

  return (
    <div className={styles.sectionRoot}>
      <SectionHeader
        title='Multilingual Settings'
        canEdit={true}
        canSave={false}
        canCreate={true}
        editing={true}
        onEdit={null}
        onReset={null}
        onSave={null}
        onCreate={toggleAddLanguage} />
      <div>
        <SortableTable table={table} classes={{ cell: styles.cell, row: styles.row }} />
      </div>
      <AddLanguageModal />
    </div>
  );
};

const LanguageActions = (props: CellContext<SurveyLanguage, unknown>) => {
  const [, dispatch] = useSurveyBuilderState();
  const item = props.row.original;

  if (item.isDefault) return null;

  return <MinusCircle className={styles.action} onClick={() => dispatch({ type: 'language-removed', payload: { code: item.code } })} />;
};