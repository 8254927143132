import { memo, useCallback, useMemo } from 'react';
import type { PopupState as PopupStateProps } from 'material-ui-popup-state/hooks';
import type { TableOptions, Column } from 'react-table';
import { useTable, useFlexLayout } from 'react-table';
import { cx } from '@utils';
import { useRecentFiles } from '@utils/api';
import type { RecentFilesItem } from '@/types/workspace.table';
import { WorkspacesContextMenuPopper } from '@/components/Workspace/WorkspacesMenu';
import * as Table from '@/components/Table';
import { useRecentFilesColumns } from '@/components/Workspace.RecentFiles';
import styles from './style/RecentFiles.css';

export const RecentFiles = () => {

  const query = useRecentFiles();

  const loading = useMemo(() => query.isInitialLoading && !query.data, [query.data, query.isInitialLoading]);

  const data = useMemo(() => {
    return loading
      ? getLazyTableData(5)
      : query.data?.items ?? [];
  }, [loading, query.data?.items]);

  const empty = useMemo(() => {
    return query.isFetchedAfterMount && !loading && !data.length;
  }, [data.length, loading, query.isFetchedAfterMount]);

  const columns = useRecentFilesColumns();

  const renderJumpToAnchor = useCallback((state: PopupStateProps) => {
    const className = cx(styles.jumpTo, {
      [styles.open]: state.isOpen,
    });
    return (
      <div className={className}>
        Jump To
        <div className={styles.chevron} />
      </div>
    );
  }, []);

  const params: TableOptions<RecentFilesItem> = {
    columns: columns as Column<RecentFilesItem>[],
    data,
    initialState: {
      pageIndex: 0,
    },
    manualFilters: true,
    manualPagination: true,
  };

  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable<RecentFilesItem>(
    params,
    useFlexLayout,
  );

  return (
    <Table.Layout.Box className={styles.box}>
      <Table.Layout.Header
        title="Recently Uploaded Files">
        <WorkspacesContextMenuPopper renderAnchor={renderJumpToAnchor} />
      </Table.Layout.Header>

      <Table.Root
        EmptyComponent={EmptyState}
        empty={empty}
        loading={loading}>
        <Table.Header headerGroups={headerGroups} />
        <Table.Body
          {...getTableBodyProps()}
          classes={{
            tbody: styles.body,
            td: styles.td,
          }}
          prepareRow={prepareRow}
          rows={rows} />
      </Table.Root>
    </Table.Layout.Box>
  );
};

const getLazyTableData = (pageSize = 5) => {
  return Array.from({ length: pageSize }, _ => ({} as RecentFilesItem));
};

export const EmptyState = memo(() => {

  return (
    <div className={styles.empty}>
      <div className={styles.title}>No recent files</div>
      <div className={styles.text}>Upload a file to get started</div>
    </div>
  );
});