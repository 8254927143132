import { cx } from '@utils';
import styles from './style.css';

type Props = {
  className?: string;
  max?: number;
  min?: number;
  orientation?: 'horizontal' | 'vertical';
  value: number;
};

const Meter = (props: Props) => {
  const num = +props.value;

  const pct = num >= props.max ? props.max : num < props.min ? props.min : num;
  const value = `${pct}%`;

  const rootClass = {
    horizontal: styles.horizontal,
    vertical: styles.vertical,
  }[props.orientation];

  const style = {
    horizontal: { width: value },
    vertical: { height: value },
  }[props.orientation];

  const className = cx({
    [styles.low]: pct < 40,
    [styles.mid]: pct >= 40 && pct <= 79,
    [styles.hi]: pct > 79,
  });

  return (
    <div className={cx(rootClass, props.className)}>
      <div
        className={className}
        style={style} />
    </div>
  );
};

const defaultProps = {
  max: 100,
  min: 0,
  orientation: 'horizontal',
  value: 0,
};

Meter.defaultProps = defaultProps;

export { Meter };
export default Meter;