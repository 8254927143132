import { useCallback, useContext, useMemo } from 'react';
import { createId } from '@paralleldrive/cuid2';
import { useMutation } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import { UploadFilesContainer as WorkspaceUploadContainer } from '@/containers/WorkspaceFileUpload';
import { UploadFilesBaseContext } from '@/containers/WorkspaceFileUpload/Context';
import * as $bi from '@/brand-insights/api';
import { fileUploadPreCheck } from '@api/brand-insights';
import type { BrandInsightsInstanceContext, Chat } from '@/brand-insights/types';
import type { FileUploadPreCheck } from '@api/interfaces/brand-insights';
import type { UploadFiles } from '@/brand-insights/api/interfaces/chat';
import { ChatStateDispatchContext } from '@/brand-insights/components/Chat.State';
import { ChatContextType } from '@/brand-insights/enums';
import type { FilesMap } from '@/containers/WorkspaceFileUpload/interfaces.base';
import {
  ActiveChatSessionContext,
  IsPreparingUploadContext,
  UploadFilesContext,
} from './context';
import { FileUploadEffectsContainer } from './Chat.UploadEffects.Container';
import { useScrollToBottomOfMessages } from './hooks';

const UploadFilesContainer = (props: ChildrenProps) => {

  const chat = useContext(ActiveChatSessionContext);
  const dispatch = useContext(ChatStateDispatchContext);
  const scrollToBottom = useScrollToBottomOfMessages();
  const uploadFiles = useContext(UploadFilesBaseContext);

  const {
    mutateAsync: runPrecheck,
    ...preCheckMutation
  } = useMutation<
    FileUploadPreCheck.Response,
    AxiosResponse,
    FileUploadPreCheck.Request
  >({
    mutationFn: data => {
      return fileUploadPreCheck({
        chatInstanceIdentifier: data.chatInstanceIdentifier,
        files: data.files,
        parentObjectId: data.parentObjectId,
      });
    },
  });

  const {
    mutateAsync: registerUpload,
    ...uploadMutation
  } = useMutation<
    UploadFiles.Response,
    AxiosResponse,
    UploadFiles.Request
  >({
    mutationFn: data => {
      return $bi.uploadChatFiles({
        chatInstanceIdentifier: data.chatInstanceIdentifier,
        files: data.files,
        context: data.context,
        identifier: data.identifier,
      });
    },
  });

  const isPreparingUpload = useMemo(() => {
    return preCheckMutation.isLoading || uploadMutation.isLoading;
  }, [
    preCheckMutation.isLoading,
    uploadMutation.isLoading,
  ]);

  const handleUpload = useCallback(async (files: File[]) => {

    const currentChatSupportsUploads = resolveChatFileUploadSupport(chat);

    const chatInstanceIdentifier = currentChatSupportsUploads
      ? chat.identifier
      : createId();

    const uploadIdentifier = createId();

    const filesMap = files.reduce<FilesMap>((acc, file) => {
      const identifier = createId();
      acc[identifier] = file;
      return acc;
    }, {});

    const fileObjects = Object.keys(filesMap).map(identifier => ({
      identifier,
      name: filesMap[identifier].name,
    }));

    const {
      folder,
      object: folderObject,
    } = await runPrecheck({
      chatInstanceIdentifier,
      files: fileObjects,
      parentObjectId: currentChatSupportsUploads
        ? (chat.context as BrandInsightsInstanceContext.FolderWithMetadata).data.objectId
        : null,
    });

    const chatInstanceContext = currentChatSupportsUploads
      ? chat.context
      : generateNewChatContext({
        chatInstanceIdentifier,
        folderObjectId: folderObject.id,
        folderName: folder.name,
        folderId: folder.id,
      });

    const {
      chatInstance,
      fileUpload,
    } = await registerUpload({
      identifier: uploadIdentifier,
      chatInstanceIdentifier,
      context: chatInstanceContext,
      files: fileObjects,
    });

    if (chat.identifier !== chatInstanceIdentifier) {
      dispatch({
        type: 'new-chat-added',
        payload: {
          chatInstance,
          queries: {},
          fileUploads: [fileUpload],
          segmentations: [],
        },
      });
    } else {
      dispatch({
        type: 'file-upload-initiated',
        payload: {
          fileUpload,
        },
      });
    }

    scrollToBottom();

    uploadFiles({
      isTranscriptImport: false,
      files: filesMap,
      parentObjectId: folderObject.id,
      replaceExisting: false,
      tags: [],
      transcribeable: [],
      uploadIdentifier,
      workspaceId: folderObject.workspaceId,
    });

  }, [
    chat,
    dispatch,
    registerUpload,
    runPrecheck,
    scrollToBottom,
    uploadFiles,
  ]);

  return (
    <UploadFilesContext.Provider value={handleUpload}>
      <IsPreparingUploadContext.Provider value={isPreparingUpload}>
        {props.children}
      </IsPreparingUploadContext.Provider>
    </UploadFilesContext.Provider>
  );
};

const WithState = (props: ChildrenProps) => {
  return (
    <FileUploadEffectsContainer>
      <WorkspaceUploadContainer>
        <UploadFilesContainer>
          {props.children}
        </UploadFilesContainer>
      </WorkspaceUploadContainer>
    </FileUploadEffectsContainer>
  );
};

export { WithState as UploadFilesContainer };

type CreateNewContext = {
  chatInstanceIdentifier: string;
  folderObjectId: number;
  folderName: string;
  folderId: number;
};

function resolveChatFileUploadSupport(chat: Chat.Instance): boolean {
  return chat.context.type === ChatContextType.Folder && chat.context.metadata.supportsUploads;
}

function generateNewChatContext(data: CreateNewContext): BrandInsightsInstanceContext.FolderWithMetadata {
  return {
    data: {
      objectId: data.folderObjectId,
      folderId: data.folderId,
      workspaceId: null,
    },
    metadata: {
      supportsUploads: true,
      name: data.folderName,
    },
    type: ChatContextType.Folder,
  };
}
