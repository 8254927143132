import { Fragment, useCallback } from 'react';
import { useVideoPostStateContext, useVideoPostStepperContext } from '@containers/PostCreation/hooks/usePostCreationContext';
import { Button } from '@/components/Button';
import * as Publisher from './Publisher';
import { VideoFileContext, VideoSnapshotContext } from './Video.Context';
import { VideoFileInput } from './Video.UploadVideo.VideoFileInput';
import { VideoFilePreview } from './Video.UploadVideo.VideoFilePreview';
import styles from './style/Video.Frame.UploadVideo.css';

type Props = unknown;

export const UploadVideo = (props: Props) => {
  const [state, dispatch] = useVideoPostStateContext();
  const actions = useVideoPostStepperContext();

  const file = state.video;
  const setFile = useCallback((file: File) => {
    dispatch({
      file,
      type: 'set-video-file',
    });
  }, [dispatch]);

  const setSnapshot = useCallback((snapshot: string) => {
    dispatch({
      src: snapshot,
      type: 'set-video-snapshot',
    });
  }, [dispatch]);

  return (
    <Fragment>

      <Publisher.Header>
        Create Post
      </Publisher.Header>

      <Publisher.Author className={styles.author} />

      <VideoFileContext.Provider value={[file, setFile]}>
        <VideoSnapshotContext.Provider value={[state.snapshot, setSnapshot]}>
          {!file && <VideoFileInput />}
          {file && <VideoFilePreview />}
          {file &&
            <Publisher.Footer>
              <Button
                className={styles.btn}
                disabled={!file}
                onClick={actions.next}
                variant="brick">
                Next
              </Button>
            </Publisher.Footer>}
        </VideoSnapshotContext.Provider>
      </VideoFileContext.Provider>

    </Fragment>
  );
};

UploadVideo.displayName = 'PostCreation.Frame.Video.UploadVideo';