import { ConferenceType, utils as enumUtils } from '@enums';
import { EnumDropDown } from '@/components/DropDown';

type Props = {
  items?: ConferenceType[];
  onSelect: (value: ConferenceType) => void;
  value: ConferenceType;
};

export const ConferenceTypeDropdown = ({
  items = enumUtils.ConferenceType.values(),
  onSelect,
  value,
}: Props) => {

  return (
    <EnumDropDown
      enum={enumUtils.ConferenceType}
      items={items}
      onSelect={onSelect}
      value={value} />
  );
};

export default ConferenceTypeDropdown;