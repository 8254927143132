import { useCallback, useContext } from 'react';
import { ParamsContext, ParticipantsContext } from '$admin/components/Scheduling/Context';

export const useSchedulerUserIdsSelector = () => {
  const ctx = {
    participants: useContext(ParticipantsContext),
    scheduling: useContext(ParamsContext),
  };

  const selectSchedulerUserIds = () => {
    return ctx.participants.items.reduce<CalendarsQueryParams>((acc, item) => {
      const scheduler = item.scheduler || item.id === ctx.scheduling.params.userId;

      return {
        moderatorIds: item.scheduler ? acc.moderatorIds.concat(item.id) : acc.moderatorIds,
        userIds: scheduler ? acc.userIds.concat(item.id) : acc.userIds,
      };
    }, {
      userIds: [],
      moderatorIds: [],
    } as CalendarsQueryParams);
  };

  return useCallback(selectSchedulerUserIds, [
    ctx.participants.items,
    ctx.scheduling.params.userId,
  ]);
};

export type CalendarsQueryParams = {
  moderatorIds: number[];
  userIds:      number[];
};