import { memo } from 'react';
import { QuotedText } from '@presentation/QuotedText';
import styles from './style/Rationale.css';

type Props = {
  value: string;
};

export const Rationale = memo(({ value }: Props) => {

  if (!value) return null;

  return (
    <div className={styles.root}>
      <div className={styles.label}>Rationale</div>
      <QuotedText>{value}</QuotedText>
    </div>
  );
});