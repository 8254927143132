import { SurveyConditionType } from '@enums';
import type { SurveyLogic } from '@/types/survey';
import { ConditionErrorBoundary } from './ErrorBoundary.Condition';
import { SurveyQuestionCondition } from './Preview.Condition.Question';
import { SurveySumCondition } from './Preview.Condition.Sum';
import { SurveyClassificationCondition } from './Preview.Condition.Classification';

type Props = {
  item: SurveyLogic.SurveyCondition;
};

const SurveyCondition = ({ item }: Props) => {

  if (item.conditionType === SurveyConditionType.Question) {
    return (
      <SurveyQuestionCondition
        item={item} />
    );
  } else if (item.conditionType === SurveyConditionType.AggregatedSum) {
    return (
      <SurveySumCondition
        item={item} />
    );
  } else if (item.conditionType === SurveyConditionType.Classification) {
    return (
      <SurveyClassificationCondition
        item={item} />
    );
  }
};

const SurveyConditionWithErrorBoundary = (props: Props) => {

  return (
    <ConditionErrorBoundary condition={props.item}>
      <SurveyCondition
        {...props} />
    </ConditionErrorBoundary>
  );
};

export { SurveyConditionWithErrorBoundary as SurveyCondition };