import { useMemo, useReducer, Reducer } from 'react';
import { SurveyLogicItemStateContext, useSurveyConditionsMatchState } from './context';
import { LogicItemBuilder } from './interfaces';
import { surveyLogicReducer } from './reducer';
import { SurveyConditionsState } from './State.Conditions';

type Props = {
  initial: LogicItemBuilder.State;
} & ChildrenProps;

const SurveyLogicBuilderState = ({
  children,
  initial,
}: Props) => {

  const [state, dispatch] = useReducer<Reducer<LogicItemBuilder.State, LogicItemBuilder.Action>>(surveyLogicReducer, initial);

  const [matchState] = useSurveyConditionsMatchState();

  const mergedState = useMemo(() => {
    return {
      ...state,
      conditions: matchState.conditions,
      rule: matchState.rule,
    };
  }, [matchState, state]);

  return (
    <SurveyLogicItemStateContext.Provider value={[mergedState, dispatch]}>
      {children}
    </SurveyLogicItemStateContext.Provider>
  );
};

const WithConditionsState = (props: Props) => {

  const initial = {
    conditions: props.initial.conditions,
    rule: props.initial.rule,
  };

  return (
    <SurveyConditionsState initial={initial}>
      <SurveyLogicBuilderState initial={props.initial}>
        {props.children}
      </SurveyLogicBuilderState>
    </SurveyConditionsState>
  );
};

export { WithConditionsState as SurveyLogicBuilderState };