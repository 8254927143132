import type { CSSProperties } from 'react';
import { memo, useCallback, useMemo, useState, useRef } from 'react';
import { cx, useIdentifyBrowser, useToggle, useOnResize } from '@utils';
import styles from './style/SeeMore.css';

export type SeeMoreProps = {
  lineHeight: number;
  maxLines: number;
};

type Props = {
  className?: string;
  expanderClassName?: string;
  CollapserComp?: React.ComponentType<Omit<ExpanderProps, 'text'>>;
  ExpanderComp?: React.ComponentType<Omit<ExpanderProps, 'text'>>;
} & ChildrenProps
  & SeeMoreProps;

type ExpanderProps = {
  className?: string;
  onClick: () => unknown;
  text: string;
};

export const useTruncatedTextContainer = ({ lineHeight, maxLines }: SeeMoreProps) => {
  const [collapsed, toggleCollapsed] = useToggle(true);
  const [hasOverflow, setOverflow] = useState<boolean>(null);
  const { firefox } = useIdentifyBrowser();
  const internalRef = useRef<HTMLDivElement>();
  useOnResize(useCallback(() => setOverflow(null), []));

  const calculateOverflow = useCallback(() => {
    const node = internalRef.current;
    if (!node) return;

    // NOTE: Firefox seems to render our divs with 1px more than it should be.
    //       Give it a small allowance before we consider it to be overflowing
    const fudgeFactor = firefox ? 1 : 0;

    const maxHeight = lineHeight * maxLines;

    setOverflow(node.scrollHeight > (maxHeight + fudgeFactor));
  }, [setOverflow, firefox, lineHeight, maxLines]);

  const ref = (node: HTMLDivElement) => {
    internalRef.current = node;
    calculateOverflow();

    if (node) {
      const observer = new ResizeObserver(() => calculateOverflow());
      observer.observe(node);
      return () => observer.disconnect();
    }
  };

  const style: CSSProperties = useMemo(() => ({
    lineHeight: `${lineHeight}px`,
    maxHeight: collapsed && `${lineHeight * maxLines}px`,
    overflow: hasOverflow && collapsed ? 'hidden' : '',
  }), [
    collapsed,
    hasOverflow,
    lineHeight,
    maxLines,
  ]);

  return {
    collapsed,
    hasOverflow,
    toggleCollapsed,
    props: {
      ref,
      style,
    },
  } as const;
};

export const SeeMore = memo(({
  lineHeight,
  maxLines,
  CollapserComp,
  ExpanderComp = Expander,
  ...props
}: Props) => {

  const container = useTruncatedTextContainer({
    lineHeight,
    maxLines,
  });

  const className = cx(styles.root, props.className);

  if (!props.children) return null;

  return (
    <div className={className} {...container.props}>
      {props.children}
      {container.collapsed && container.hasOverflow &&
        <ExpanderComp
          className={props.expanderClassName}
          onClick={container.toggleCollapsed} />
      }
      {!container.collapsed && CollapserComp &&
        <CollapserComp
          className={props.expanderClassName}
          onClick={container.toggleCollapsed} />
      }
    </div>
  );
});

export const Collapser = memo((props: Omit<ExpanderProps, 'text'>) => (
  <BaseExpander
    {...props}
    text="See less" />
));

export const Expander = memo((props: Omit<ExpanderProps, 'text'>) => (
  <BaseExpander
    {...props}
    text="...See more" />
));

const BaseExpander = memo(({ className, onClick, text }: ExpanderProps) => {

  return (
    <div
      className={cx(styles.more, className)}
      onClick={onClick}>
      {text}
    </div>
  );
});

export default SeeMore;