import { useCallback, useMemo, useState } from 'react';
import type { ActionMeta, MultiValue } from 'react-select';
import Select, { type CreatableProps } from 'react-select/creatable';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { useQuery } from '@tanstack/react-query';
import type { TagOption, NewTagOption } from './interfaces';
import * as styles from './styles';

type CombinedOption = TagOption & Partial<NewTagOption>;

type Props = {
  className?: string;
  value: CombinedOption[];
  onChange: (value: CombinedOption[], meta: ActionMeta<CombinedOption>) => unknown;
  queryKeyIdentifier: string;
  fetcher: (value: string) => Promise<TagOption[]>;
} & Omit<CreatableProps<CombinedOption, true, undefined>, 'options' | 'onChange' | 'onInputChange' | 'value'>;

export function GroupTagsSelector({
  className,
  value,
  onChange,
  fetcher,
  queryKeyIdentifier,
  ...props
}: Props) {
  const selectStyles = useMemo(() => styles.buildSelectStyles(), []);
  const selectTheme = useMemo(() => styles.buildSelectTheme(), []);

  const handleChange = useCallback((value: MultiValue<CombinedOption>, meta: ActionMeta<CombinedOption>) => {
    onChange(Array.from(value), meta);
  }, [onChange]);

  const handleInputChange = useCallback((newValue: string) => setInputValue(newValue), []);

  const [inputValue, setInputValue] = useState('');
  const optionsQuery = useQuery({
    queryKey: [`get:group-tags-selector/${queryKeyIdentifier}`, inputValue],
    queryFn: () => fetcher(inputValue),
    placeholderData: [],
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });

  const handleNoOptionsMessage = useCallback((obj: { inputValue: string }) => {
    if (optionsQuery.isFetching) return `Loading...`;
    return obj.inputValue?.length ? `No tags found` : null;
  }, [optionsQuery.isFetching]);

  const handleIsValidNewOption = useCallback((value: string, options: TagOption[]) => {
    const sanitizedValue = value?.trim?.();
    if (!sanitizedValue.length) return false;
    return !options.some(o => o.name.toLowerCase() === sanitizedValue.toLowerCase());
  }, []);

  const selected = useMemo(() => value || [], [value]);

  return (
    <Root className={className}>
      <Select<CombinedOption, true>
        styles={selectStyles}
        components={{
          DropdownIndicator: null,
        }}
        theme={selectTheme}
        value={selected}
        isMulti
        options={optionsQuery.data}
        placeholder={`Search tags...`}
        formatCreateLabel={value => `Create tag "${value}"`}
        isValidNewOption={handleIsValidNewOption}
        noOptionsMessage={handleNoOptionsMessage}
        // eslint-disable-next-line no-underscore-dangle
        getOptionLabel={o => o.__isNew__ ? o.label : o.name}
        // eslint-disable-next-line no-underscore-dangle
        getOptionValue={o => o.__isNew__ ? o.value : `${o.id}`}
        onChange={handleChange}
        onInputChange={handleInputChange}
        {...props} />
    </Root>
  );
}

const Root = styled.div`
`;