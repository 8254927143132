import { useCallback, useMemo } from 'react';
import { useRepeatQuestionResponsesData, useRepeatSurveyResponsesState } from '@containers/RepeatSurveyResponses/hooks';
import * as chart from '@containers/SurveyResponses/utils';
import { parseSurveyRichText } from '@containers/Survey/utils';
import { RepeatSurvey } from '@containers/RepeatSurveyResponses/interfaces';
import { SurveyQuestionType } from '@enums';
import { ProjectVersionHeaderRow, ResponseOptionRow } from '@presentation/ProjectSurveyResponses';
import { formatPlurality, formatNumber } from '@utils';
import Respondent from '@/components/SurveyResponses/ResponseOptionUser';
import { useProjectVersion } from './hooks';
import styles from './style/Data.css';

export const MatrixSliderBucketData = () => {
  const { query, resetQueryState } = useRepeatSurveyResponsesState<RepeatSurvey.QueryState.MatrixSlider>();
  const version = useProjectVersion(query.projectId);
  const { historical, question } = useRepeatQuestionResponsesData<SurveyQuestionType.Sliders>();

  const versionData = useMemo(() => {
    return historical[query.projectId].data;
  }, [
    historical,
    query.projectId,
  ]);

  const row = useMemo(() => {
    return question.matrixRows.find(f => f.base.id === query.rowIds[0]);
  }, [
    query.rowIds,
    question,
  ]);

  const rowData = useMemo(() => {
    return versionData.rows[row.base.id];
  }, [row.base.id, versionData]);

  const renderBucket = useCallback((data: chart.matrixSlider.BucketData) => {
    const bucket = chart.matrixSlider.formatBucketFromKey(query.key);
    const userIds = data.map[query.key];

    return (
      <>
        <ResponseOptionRow
          className={styles.row}
          color="var(--pri-01)"
          label={chart.matrixSlider.formatBucketDisplay(bucket)}
          subtitle={`(${userIds.length} ${formatPlurality('Respondent', userIds.length)})`} />
        {userIds.map(userId => (
          <Respondent
            key={userId}
            value={rowData.responses[userId].toString()}
            userId={userId} />
        ))}
      </>
    );
  }, [
    rowData,
    query.key,
  ]);

  const renderValue = useCallback((data: chart.matrixSlider.ValueData) => {
    const userIds = data.map[+query.key];

    return (
      <>
        <ResponseOptionRow
          className={styles.row}
          color="var(--pri-01)"
          label={query.key}
          subtitle={`(${userIds.length} ${formatPlurality('Respondent', userIds.length)})`} />
        {userIds.map(userId => (
          <Respondent
            key={userId}

            userId={userId} />
        ))}
      </>
    );
  }, [
    query.key,
  ]);

  const renderData = useCallback(() => {
    const data = chart.matrixSlider.getRowData(question, rowData.responses);

    if (data.useBuckets) {
      return renderBucket(data);
    } else {
      return renderValue(data as chart.matrixSlider.ValueData);
    }
  }, [
    question,
    renderBucket,
    renderValue,
    rowData,
  ]);

  return (
    <>
      <ProjectVersionHeaderRow
        className={styles.row}
        onClick={resetQueryState}
        version={version} />
      <ResponseOptionRow
        className={styles.row}
        color={chart.options.OptionsColors[row.ordinal - 1]}
        label={parseSurveyRichText(row.value)}
        subtitle={`(Avg. ${formatNumber(rowData.avg, 1)})`} />
      {renderData()}
    </>
  );
};

export default MatrixSliderBucketData;