import { useCallback, useMemo } from 'react';
import { useSumConditionContext, useSurveySumConditionValidations } from '@containers/SurveyBuilder.Logic';
import { OperatorDropdown } from '@presentation/SurveyBuilder';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { SurveyQuestionType, SurveySumConditionItemType } from '@/enums';
import { SurveyLogic, SurveyQuestion } from '@/types';
import { ComparateInput } from './Builder.ComparateInput';
import { useSumConditionModal } from './Builder.Condition.Sum.Modal';
import { LogicValidation } from './Builder.Validation';
import styles from './style/Builder.Condition.Sum.css';

export const LogicBuilderSumCondition = () => {

  const {
    item,
    updateComparate,
    updateItems,
    updateOperator,
  } = useSumConditionContext();
  const [state] = useSurveyBuilderState();

  const [toggleSumModal, SumModal] = useSumConditionModal();

  const validations = useSurveySumConditionValidations(item.identifier);

  const sumText = useMemo(() => {
    if (!item.data.items.length) return 'None selected';

    const text = getSumConditionValuesText(item.data.items, state.survey.questions);
    return text;
  }, [item.data.items, state.survey.questions]);

  return (
    <div>
      <div className={styles.sumRow}>
        <div className={styles.label}>Sum of </div>
        <div className={styles.sumText}>({sumText})</div>
        <div className={styles.edit} onClick={toggleSumModal}>Edit</div>
      </div>
      <LogicValidation value={validations.items} />

      <div className={styles.compareRow}>
        <div className={styles.operatorDropdown}>
          <OperatorDropdown
            value={item.data.operator}
            onSelect={updateOperator} />
        </div>
        <ComparateInput
          className={styles.comparateInput}
          onChange={updateComparate}
          value={item.data.comparate} />
      </div>
      <LogicValidation value={validations.comparate} />
      <LogicValidation value={validations.operator} />

      <SumModal
        initial={item.data.items}
        onSubmit={updateItems} />
    </div>
  );
};

export function getSumConditionValuesText(items: SurveyLogic.SumConditionItem[], questions: SurveyQuestion[]) {
  return items.reduce<string[]>((acc, x) => {
    const question = questions.find(f => f.base.identifier === x.question.identifier);

    switch (x.type) {
      case SurveySumConditionItemType.NumberTableCellValue: {
        const q = question as SurveyQuestion<SurveyQuestionType.NumberInputTable>;
        const questionItems = x.values.map(m => {
          const row = q.matrixRows.find(f => f.base.identifier === m.row.identifier);
          const option = q.options.find(f => f.base.identifier === m.option.identifier);
          return `Q${question.ordinal}~R${row.ordinal}/C${option.ordinal}`;
        });
        return acc.concat(questionItems);
      }

      case SurveySumConditionItemType.SliderRowValue: {
        const q = question as SurveyQuestion<SurveyQuestionType.Sliders>;
        const questionItems = x.values.map(m => {
          const row = q.matrixRows.find(f => f.base.identifier === m.row.identifier);
          return `Q${question.ordinal}~R${row.ordinal}`;
        });
        return acc.concat(questionItems);
      }

      default:
        throw new UnreachableCaseError(x);
    }
  }, []).join(' + ');
}