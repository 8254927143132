import { useCallback, useContext, useMemo } from 'react';
import { RichTextEditorRegistryContext } from '../Context';

export const useRichTextEditorRegistryEditor = () => {
  const { getEditor } = useContext(RichTextEditorRegistryContext);

  const getEditorByIdentifier = useCallback((identifier: string) => {
    return getEditor(identifier);
  }, [getEditor]);

  return useMemo(() => ({
    getEditorByIdentifier,
  }), [
    getEditorByIdentifier,
  ]);
};