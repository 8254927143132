import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { ProjectSocketEvent } from '@enums';
import { useProjectConferenceTagSummaries } from '@utils/api';
import * as api from '@api';
import * as $ws from '@websocket';
import { useProjectsSocketEvent } from '@websocket/hooks';
import { RefreshSummariesContext } from './context';

type Props =
  IProjectId &
  ChildrenProps;

type Key = {
  projectId: number;
  tagId: number;
};

export const ProjectSummaryRefreshContainer = (props: Props) => {

  const [websocketKey, setWebsocketKey] = useState<Key>();

  const {
    isLoading: isGenerating,
    mutateAsync: refresh,
  } = useMutation({
    mutationKey: ['post:projects/conference-tags/summaries/generate', props.projectId],
    mutationFn: (tagId?: number) => {
      return api.projects.conference.generateTagSummaries({
        projectId: props.projectId,
        tagIds: tagId ? [tagId] : undefined,
      });
    }, onError: () => {
      setWebsocketKey(null);
    }, onMutate: (tagId?: number) => {
      setWebsocketKey({
        projectId: props.projectId,
        tagId,
      });
    },
  });

  const { refetch: refetchProjectTagSummaries, isLoading: isLoadingSummaries } = useProjectConferenceTagSummaries({ projectId: props.projectId }, {
    enabled: false,
  });

  const handleTagSummariesAvailable = useCallback(() => {
    refetchProjectTagSummaries();
    setWebsocketKey(null);
  }, [refetchProjectTagSummaries]);

  useProjectsSocketEvent(ProjectSocketEvent.TagSummariesAvailable, handleTagSummariesAvailable, !!websocketKey);

  useEffect(() => {
    const key = websocketKey;
    if (key) {
      $ws.projects.subscribe(key.projectId);
      $ws.projects.open();

      return () => {
        $ws.projects.unsubscribe(key.projectId);
        $ws.projects.close();
      };
    }
  }, [websocketKey]);

  const isRefreshing = useMemo(() => {
    return isGenerating || isLoadingSummaries || !!websocketKey;
  }, [
    isGenerating,
    isLoadingSummaries,
    websocketKey,
  ]);

  const ctx = useMemo(() => ({
    isRefreshing,
    refresh,
    tagRefreshing: websocketKey?.tagId,
  }), [
    isRefreshing,
    refresh,
    websocketKey,
  ]);

  return (
    <RefreshSummariesContext.Provider value={ctx}>
      {props.children}
    </RefreshSummariesContext.Provider>
  );
};