import { path, pathname } from '@consts';
import { RouteAuth } from '@enums';
import { BasicRouter } from '@routes/Routers';
import * as Routing from '@routes/interfaces';
import { TopicLanding, TopicNotFound } from '@screens/Topic.Landing';

export const routes: Routing.Route[] = [
  {
    component: TopicNotFound,
    exact: true,
    path: path.Topics.NotFound,
  },
  {
    component: TopicLanding,
    path: path.Topics.Topic,
  },
];

export const router = BasicRouter(routes, {
  auth: RouteAuth.Authenticated,
  path: pathname.Topics,
});