import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import { useHistory } from 'react-router';
import { captureException } from '@sentry/react';
import { ConferenceSocketEvent } from '@enums';
import { nullableDate, getLocationFor, cdn } from '@utils';
import { DeviceProviderContainer } from '@/containers/DeviceProvider/DeviceProviderContainer';
import type { Participant, ParticipantWeb } from '@/types/conferences.live';
import Toast from '@/components/Toast';
import { useConferenceAudioCuePreferences } from '@/components/Conference/hooks';
import { ConferenceCoordinatorContext, ConferenceCoordinatorEventContext } from './Context';
import { useSocket } from './hooks/useSocket';
import { useTwilioVideo } from './hooks/useTwilioVideo';
import { useTwilioVoice } from './hooks/useTwilioVoice';
import { useReconnectHandler } from './hooks/useReconnectHandler';
import * as $session from './Session';
import type { Coordinator, MultiChat } from './interfaces';
import { useLeaveOnNavigate } from './hooks/useLeaveOnNavigate';
import { LogContainer } from './LogContainer';
import { transformLogRecord } from './utils';
import { DeviceLogger } from './DeviceLogger';

export function ConferenceCoordinatorContainer({ children }: ChildrenProps) {
  const [state, dispatch] = useReducer(coordinator, createInitialState());

  const sock = useSocket();
  const video = useTwilioVideo();
  const voice = useTwilioVoice();

  const history = useHistory();

  const listeners = useRef({
    leave: new Set<Coordinator.Event.Handler>(),
  }).current;

  const run = useCallback((e: Coordinator.Event.Type) => {
    listeners[e].forEach(fn => fn());
  }, [listeners]);

  const on = useCallback((e: Coordinator.Event.Type, callback: Coordinator.Event.Handler) => {
    listeners[e].add(callback);
  }, [listeners]);

  const off = useCallback((e: Coordinator.Event.Type, callback: Coordinator.Event.Handler) => {
    listeners[e].delete(callback);
  }, [listeners]);

  const shouldUseAudio = useMemo(() => {
    if (state.conference?.status === 'pre-room' || state.conference?.status === 'meeting-room') {
      return state.conference.features.microphoneToggle || state.conference.settings.microphone;
    }

    return false;
  }, [state.conference]);

  const shouldUseVideo = useMemo(() => {
    if (state.conference?.status === 'pre-room' || state.conference?.status === 'meeting-room') {
      return state.conference.features.cameraToggle || state.conference.settings.camera;
    }

    return false;
  }, [state.conference]);

  const handleDisconnect = useCallback((data: Pick<Coordinator.Actions.LeaveData, 'conferenceIdentifier' | 'end'>) => {
    sock.raw.emit(ConferenceSocketEvent.Leave, {
      conferenceIdentifier: data.conferenceIdentifier,
      end: data.end,
    });

    video.disconnect();
    voice.disconnect();
  }, [video, voice, sock]);

  const handleLeave = useCallback((data: Coordinator.Actions.LeaveData) => {
    run('leave');
    handleDisconnect(data);

    sock.close();

    dispatch({
      type: 'leave',
      data: {
        conferenceIdentifier: data.conferenceIdentifier,
        title: data.title,
        redirect: data.redirect,
        location: data.location,
        participant: data.participant,
      },
    });
  }, [
    handleDisconnect,
    run,
    sock,
  ]);

  const handleRestartParticipation = useCallback((conferenceIdentifier: number) => {
    sock.close();
    video.disconnect();
    voice.disconnect();

    history.push(getLocationFor.call.conference(conferenceIdentifier));
  }, [sock, video, voice, history]);

  const stateRef = useRef(state);
  const videoRef = useRef(video);
  const voiceRef = useRef(voice);
  const leaveRef = useRef(handleLeave);
  const restartRef = useRef(handleRestartParticipation);

  useEffect(() => {
    leaveRef.current = handleLeave;
  }, [handleLeave]);

  useEffect(() => {
    videoRef.current = video;
  }, [video]);

  useEffect(() => {
    voiceRef.current = voice;
  }, [voice]);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const onReconnectTimeout = useCallback(() => {
    const instance = stateRef.current.conference;
    if (instance.status === 'meeting-room' || instance.status === 'waiting-room') {
      handleLeave({
        redirect: 'lost-connection',
        participant: instance.participant,
        conferenceIdentifier: instance.conferenceIdentifier,
        title: instance.conference.title,
      });
    }
  }, [handleLeave]);

  const [onReconnecting, onReconnected] = useReconnectHandler({ onReconnectTimeout });
  const playWaitingRoomChime = usePlayWaitingRoomChime();

  useEffect(() => {
    if (sock.state.reconnecting === true) onReconnecting();

    if (sock.state.reconnecting === false) onReconnected();
  }, [sock.state.reconnecting, onReconnecting, onReconnected]);

  const handleNegotiate = useCallback((data: Coordinator.Actions.NegotiateData) => {
    return new Promise<Coordinator.Actions.NegotiateResponseData>(resolve => {
      dispatch({
        type: 'negotiate',
        data,
      });

      sock.initialize();

      setTimeout(() => {
        if (!sock.raw.connected) {
          //This might be too noisy depending on how long it takes to connect
          captureException('Took longer than 5 seconds for conference socket to open, something likely went wrong', {
            extra: {
              latestError: sock.state.latestError,
            },
          });
        }
      }, 5000);

      sock.raw.once(ConferenceSocketEvent.Negotiate, response => {
        if (response.success !== true) {
          resolve({
            success: false,
            reason: response.reason,
          });
        } else if (response.success) {
          $session.setSession(response.conferenceIdentifier, response.sessionId);

          sock.raw.io.on('reconnect', () => {
            sock.raw.emit(ConferenceSocketEvent.NegotiateReconnect, {
              conferenceIdentifier: data.conferenceIdentifier,
              sessionId: $session.getSession(data.conferenceIdentifier),
              clientConferenceStatus: stateRef.current.conference.status,
            });
          });

          dispatch({
            type: 'join-pre-room',
            data: {
              conferenceIdentifier: response.conferenceIdentifier,
              joiningMeeting: false,
              pid: response.pid,
              call: {
                ...response.call,
                start: nullableDate(response.call.start),
                end: nullableDate(response.call.end),
              },
              conference: {
                ...response.conference,
                started: nullableDate(response.conference.started),
              },
              dial: response.dial,
              settings: response.settings,
              features: response.features,
              participant: response.participant,
              surveys: response.surveys,
              logs: response.logs.map(transformLogRecord),
            },
          });

          sock.raw.on(ConferenceSocketEvent.ConferenceUpdate, response => {
            dispatch({
              type: 'update-conference',
              data: {
                conferenceIdentifier: data.conferenceIdentifier,
                conference: {
                  ...response.conference,
                  started: nullableDate(response.conference.started),
                },
              },
            });
          });

          sock.raw.on(ConferenceSocketEvent.ConferenceEnd, response => {
            const instance = stateRef.current.conference;
            if (instance.status === 'meeting-room') {
              leaveRef.current({
                conferenceIdentifier: response.conferenceIdentifier,
                title: instance.conference.title,
                redirect: instance.conference.redirect
                  ? 'location'
                  : 'ended',
                location: instance.conference.redirect,
                participant: instance.participant,
              });
            } else {
              leaveRef.current({
                conferenceIdentifier: response.conferenceIdentifier,
                title: instance.status === 'waiting-room' ? instance.conference.title : null,
                redirect: 'ended',
                participant: null,
              });
            }
          });

          sock.raw.on(ConferenceSocketEvent.LogEvent, response => {
            dispatch({
              type: 'append-logs',
              logs: [transformLogRecord(response.event)],
            });
          });

          sock.raw.on(ConferenceSocketEvent.ParticipantDevicesUpdated, response => {
            dispatch({
              type: 'participant-updated',
              data: {
                conferenceIdentifier: data.conferenceIdentifier,
                participant: {
                  id: response.participantIdentifier,
                  deviceInfo: response.deviceInfo,
                } as PartialExcept<ParticipantWeb, 'id'>,
              },
            });
          });

          resolve({ success: true });
        } else {
          captureException('Received an invalid negotiate response', {
            extra: {
              response,
            },
          });
        }
      });

      sock.raw.emit(ConferenceSocketEvent.Negotiate, {
        conferenceIdentifier: data.conferenceIdentifier,
        name: data.name,
        pin: data.pin,
      });
    });
  }, [sock]);

  const handleUpdatePreRoom = useCallback((data: Coordinator.Actions.UpdatePreRoomData) => {
    dispatch({
      type: 'update-pre-room',
      data: {
        conferenceIdentifier: data.conferenceIdentifier,
        settings: data.settings,
        joiningMeeting: data.joiningMeeting,
      },
    });
    return Promise.resolve();
  }, []);

  const setupEnterHandler = useCallback(() => {
    sock.raw.once(ConferenceSocketEvent.Enter, async response => {
      if (response.type === 'video') {
        try {
          const instance = stateRef.current.conference as Coordinator.Conference.PreRoom;

          videoRef.current.disconnect();

          const room = await videoRef.current.connect(response.token, response.roomName);

          //If there is no room the error handler in the connect function should already log it
          if (room) {
            videoRef.current.setupLocalTracks(instance.settings.microphone, instance.settings.camera, room);
          }

          sock.raw.on(ConferenceSocketEvent.MuteParticipant, response => {
            Toast.info({
              title: `You were muted by the Host`,
            });
            videoRef.current.muteSelf();
          });
        } catch (err) {
          console.error(err);
        }
      } else if (response.type === 'voice') {
        try {
          const instance = stateRef.current.conference as Coordinator.Conference.PreRoom;

          await voiceRef.current.connect(response.token, instance.settings.microphone, voiceRef.current.microphoneSource?.deviceId);
        } catch (err) {
          console.error(err);
        }
      }

      dispatch({
        type: 'join-meeting-room',
        data: {
          conferenceIdentifier: response.conferenceIdentifier,
          participants: response.participants,
        },
      });

      sock.raw.on(ConferenceSocketEvent.ParticipantEntered, response => {
        dispatch({
          type: 'participant-entered',
          data: {
            conferenceIdentifier: response.conferenceIdentifier,
            participant: response.participant,
          },
        });
      });

      sock.raw.on(ConferenceSocketEvent.WaitingRoomParticipant, response => {
        playWaitingRoomChime();
        dispatch({
          type: 'participant-entered',
          data: {
            conferenceIdentifier: response.conferenceIdentifier,
            participant: response.participant,
          },
        });
      });

      sock.raw.on(ConferenceSocketEvent.ParticipantUpdated, response => {
        dispatch({
          type: 'participant-updated',
          data: {
            conferenceIdentifier: response.conferenceIdentifier,
            participant: response.participant,
          },
        });
      });

      sock.raw.on(ConferenceSocketEvent.ParticipantLeft, response => {
        const instance = stateRef.current.conference;
        if (instance.status === 'meeting-room' && response.pid === instance.pid) {
          if (response.reason === 'role-changed') {
            restartRef.current(instance.conferenceIdentifier);
          } else {
            leaveRef.current({
              conferenceIdentifier: response.conferenceIdentifier,
              title: instance.conference.title,
              redirect: 'removed-room',
              participant: instance.participant,
            });
          }
        } else {
          dispatch({
            type: 'participant-left',
            data: {
              conferenceIdentifier: response.conferenceIdentifier,
              pid: response.pid,
            },
          });
        }
      });

      sock.raw.on(ConferenceSocketEvent.ParticipantsList, response => {
        dispatch({
          type: 'participants-list',
          data: {
            conferenceIdentifier: response.conferenceIdentifier,
            participants: response.participants,
          },
        });
      });

      sock.raw.on(ConferenceSocketEvent.RecordingStatusChange, response => {
        dispatch({
          type: 'update-recording-status',
          data: {
            conferenceIdentifier: response.conferenceIdentifier,
            recordingStatus: response.recordingStatus,
          },
        });
      });

      sock.raw.on(ConferenceSocketEvent.UpdateSurvey, response => {
        dispatch({
          type: 'update-survey',
          surveyId: response.surveyId,
          data: response.data,
        });
      });
    });
  }, [sock, playWaitingRoomChime]);

  const handleJoin = useCallback((data: Coordinator.Actions.JoinData) => {
    return new Promise<void>(resolve => {

      if (stateRef.current.conference.status === 'pre-room') {
        dispatch({
          type: 'update-pre-room',
          data: {
            conferenceIdentifier: data.conferenceIdentifier,
            joiningMeeting: true,
          },
        });
      }

      sock.raw.emit(ConferenceSocketEvent.Join, {
        conferenceIdentifier: data.conferenceIdentifier,
        visibility: data.visibility,
      });

      setupEnterHandler();

      sock.raw.once(ConferenceSocketEvent.Join, response => {
        if (response.joined === 'waiting-room') {
          sock.raw.once(ConferenceSocketEvent.WaitingRoomReject, response => {
            leaveRef.current({
              conferenceIdentifier: response.conferenceIdentifier,
              title: stateRef.current.conference.status === 'waiting-room' ? stateRef.current.conference.conference.title : null,
              redirect: 'removed-waiting-room',
              participant: stateRef.current.conference.status === 'waiting-room' ? stateRef.current.conference.participant : null,
            });
          });

          sock.raw.on(ConferenceSocketEvent.WaitingRoomStatus, response => {
            dispatch({
              type: 'update-waiting-room-status',
              data: {
                conferenceIdentifier: response.conferenceIdentifier,
                waitingType: response.waitingType,
              },
            });
          });

          dispatch({
            type: 'join-waiting-room',
            data: {
              conferenceIdentifier: response.conferenceIdentifier,
              waitingType: response.waitingType,
            },
          });
        }
        else if (response.joined === 'meeting-room') {
          // do nothing wait for enter socket event
        }
        resolve();
      });
    });
  }, [sock, setupEnterHandler]);

  const handleWaitingRoomAdmit = useCallback((data: Coordinator.Actions.WaitingRoomAdmitData) => {
    sock.raw.emit(ConferenceSocketEvent.WaitingRoomAdmit, {
      conferenceIdentifier: data.conferenceIdentifier,
      pid: data.pid,
    });
  }, [sock]);

  const handleWaitingRoomReject = useCallback((data: Coordinator.Actions.WaitingRoomRejectData) => {
    sock.raw.emit(ConferenceSocketEvent.WaitingRoomReject, {
      conferenceIdentifier: data.conferenceIdentifier,
      pid: data.pid,
    });
  }, [sock]);

  const handleMuteParticipant = useCallback((data: Coordinator.Actions.MuteParticipantData) => {
    sock.raw.emit(ConferenceSocketEvent.MuteParticipant, {
      conferenceIdentifier: data.conferenceIdentifier,
      sid: data.sid,
      pid: data.pid,
      enabled: data.enabled,
    });
  }, [sock]);

  const handleGiveHost = useCallback((data: Coordinator.Actions.GiveHostData) => {
    sock.raw.emit(ConferenceSocketEvent.GiveHost, {
      conferenceIdentifier: data.conferenceIdentifier,
      pid: data.pid,
      selfKeepHost: true,
    });
  }, [sock]);

  const handleRemoveParticipant = useCallback((data: Coordinator.Actions.RemoveParticipantData) => {
    sock.raw.emit(ConferenceSocketEvent.RemoveParticipant, {
      conferenceIdentifier: data.conferenceIdentifier,
      pid: data.pid,
    });
  }, [sock]);

  const handleChangeParticipantVisibility = useCallback((data: Coordinator.Actions.ChangeParticipantVisibilityData) => {
    sock.raw.emit(ConferenceSocketEvent.UpdateParticipantVisibility, {
      conferenceIdentifier: data.conferenceIdentifier,
      pid: data.pid,
      visibility: data.visibility,
    });
  }, [sock]);

  const dialOutParticipant = useCallback((data: Coordinator.Actions.DialOutParticipantData) => {
    sock.raw.emit(ConferenceSocketEvent.ConferenceDialOut, {
      conferenceIdentifier: data.conferenceIdentifier,
      dialOutValue: data.dialOutValue,
      role: data.role,
    });
  }, [sock]);

  const updateParticipantName = useCallback((data: Coordinator.Actions.UpdateParticipantName['data']) => {
    sock.raw.emit(ConferenceSocketEvent.UpdateParticipantName, {
      conferenceIdentifier: data.conferenceIdentifier,
      pid: data.pid,
      name: data.name,
    });

    if (state.conference.status === 'meeting-room') {
      dispatch({
        type: 'participant-updated',
        data: {
          conferenceIdentifier: data.conferenceIdentifier,
          participant: {
            ...state.conference.participants.find(p => p.id === data.pid),
            name: data.name,
          },
        },
      });
    }
  }, [sock, state.conference]);

  const requestChatToken = useCallback(({ conferenceIdentifier }: Coordinator.Actions.RequestChatTokenData) => {
    return new Promise<{ token: string; chatSettings: MultiChat.ChatAvailabilitySettings }>(resolve => {
      sock.raw.emit(ConferenceSocketEvent.ChatToken, { conferenceIdentifier });

      sock.raw.once(ConferenceSocketEvent.ChatToken, resolve);
    });
  }, [sock.raw]);

  const initializeChatChannel = useCallback((data: Coordinator.Actions.InitializeChatChannel) => {
    sock.raw.emit(ConferenceSocketEvent.InitializeConversation, data);
  }, [sock.raw]);

  const updateRecordingStatus = useCallback((data: Coordinator.Actions.UpdateRecordingStatusData) => {
    sock.raw.emit(ConferenceSocketEvent.RecordingStatusChange, {
      conferenceIdentifier: data.conferenceIdentifier,
      recordingStatus: data.recordingStatus,
    });
  }, [sock.raw]);

  const changeParticipantRole = useCallback((data: Coordinator.Actions.ChangeParticipantRole) => {
    sock.raw.emit(ConferenceSocketEvent.ChangeParticipantRole, {
      conferenceIdentifier: data.conferenceIdentifier,
      role: data.role,
      pid: data.pid,
    });
  }, [sock.raw]);

  const handleLeaveOnNavigate = useCallback(() => {
    handleDisconnect({
      conferenceIdentifier: state.conference?.conferenceIdentifier,
      end: false,
    });
  }, [state.conference?.conferenceIdentifier, handleDisconnect]);

  useLeaveOnNavigate({
    onLeave: handleLeaveOnNavigate,
  });

  const value = {
    state,
    negotiate: handleNegotiate,
    updatePreRoom: handleUpdatePreRoom,
    join: handleJoin,
    waitingRoomAdmit: handleWaitingRoomAdmit,
    waitingRoomReject: handleWaitingRoomReject,
    muteParticipant: handleMuteParticipant,
    giveHost: handleGiveHost,
    removeParticipant: handleRemoveParticipant,
    changeParticipantVisibility: handleChangeParticipantVisibility,
    leave: handleLeave,
    dialOutParticipant,
    updateParticipantName,
    requestChatToken,
    initializeChatChannel,
    updateRecordingStatus,
    changeParticipantRole,
  };

  return (
    <ConferenceCoordinatorContext.Provider value={value}>
      <ConferenceCoordinatorEventContext.Provider value={{ off, on }}>
        <DeviceProviderContainer useAudio={shouldUseAudio} useVidio={shouldUseVideo}>
          <LogContainer>
            <DeviceLogger enabled={shouldUseAudio || shouldUseVideo}>
              {children}
            </DeviceLogger>
          </LogContainer>
        </DeviceProviderContainer>
      </ConferenceCoordinatorEventContext.Provider>
    </ConferenceCoordinatorContext.Provider>
  );
}

// negotiate -> pre-room -> waiting room -> meeting room
// -or-
// negotiate -> pre-room -> meeting room
function coordinator(state: Coordinator.State, action: Coordinator.Actions.Action): Coordinator.State {
  switch (action.type) {
    case 'negotiate': {
      return {
        ...state,
        conference: {
          conferenceIdentifier: action.data.conferenceIdentifier,
          status: 'negotiating',
          name: action.data.name,
        },
      };
    }
    case 'join-pre-room': {
      return {
        ...state,
        conference: {
          conferenceIdentifier: state.conference.conferenceIdentifier,
          pid: action.data.pid,
          status: 'pre-room',
          joiningMeeting: action.data.joiningMeeting,
          call: action.data.call,
          conference: action.data.conference,
          dial: action.data.dial,
          settings: action.data.settings,
          features: action.data.features,
          participant: action.data.participant,
          surveys: action.data.surveys,
          logs: action.data.logs,
        },
      };
    }
    case 'update-pre-room': {
      if (state.conference.status !== 'pre-room') return state;
      return {
        ...state,
        conference: {
          ...state.conference,
          settings: action.data.settings != null
            ? {
              ...state.conference.settings,
              ...action.data.settings,
            }
            : state.conference.settings,
          joiningMeeting: action.data.joiningMeeting != null
            ? action.data.joiningMeeting
            : state.conference.joiningMeeting,
        },
      };
    }
    case 'join-waiting-room': {
      if (state.conference.status !== 'pre-room') return state;
      return {
        ...state,
        conference: {
          ...state.conference,
          status: 'waiting-room',
          waitingType: action.data.waitingType,
        },
      };
    }
    case 'join-meeting-room': {
      if (state.conference.status !== 'pre-room' && state.conference.status !== 'waiting-room') return state;
      return {
        ...state,
        conference: {
          ...state.conference,
          status: 'meeting-room',
          participants: action.data.participants,
        },
      };
    }
    case 'participant-entered':
    case 'participant-updated': {
      if (state.conference.status !== 'meeting-room') return state;
      const excluded = state.conference.participants.filter(p => p.id !== action.data.participant.id);
      const existing = state.conference.participants.find(p => p.id === action.data.participant.id);

      const participants = [...excluded, { ...(existing ?? {}), ...action.data.participant } as Participant];

      return {
        ...state,
        conference: {
          ...state.conference,
          participants,
        },
      };
    }
    case 'participant-left': {
      if (state.conference.status !== 'meeting-room') return state;
      return {
        ...state,
        conference: {
          ...state.conference,
          participants: state.conference.participants.map(p => {
            if (p.id === action.data.pid) {
              return {
                ...p,
                status: 'disconnected',
              };
            } else {
              return p;
            }
          }),
        },
      };
    }
    case 'participants-list': {
      if (state.conference.status !== 'meeting-room') return state;
      return {
        ...state,
        conference: {
          ...state.conference,
          participants: action.data.participants,
        },
      };
    }
    case 'leave': {
      return {
        ...state,
        conference: {
          conferenceIdentifier: action.data.conferenceIdentifier,
          status: 'left',
          title: action.data.title,
          redirect: action.data.redirect,
          location: action.data.location,
          participant: action.data.participant,
        },
      };
    }
    case 'update-conference': {
      if (state.conference.status !== 'pre-room' && state.conference.status !== 'waiting-room' && state.conference.status !== 'meeting-room') return state;
      return {
        ...state,
        conference: {
          ...state.conference,
          conference: {
            ...state.conference.conference,
            ...action.data.conference,
          },
        },
      };
    }
    case 'update-waiting-room-status': {
      if (state.conference.status !== 'waiting-room') return state;
      return {
        ...state,
        conference: {
          ...state.conference,
          waitingType: action.data.waitingType,
        },
      };
    }

    case 'update-recording-status': {
      if (state.conference.status !== 'meeting-room') return state;
      return {
        ...state,
        conference: {
          ...state.conference,
          conference: {
            ...state.conference.conference,
            record: action.data.recordingStatus,
          },
        },
      };
    }
    case 'update-survey': {
      if (!('surveys' in state.conference)) return state;

      return {
        ...state,
        conference: {
          ...state.conference,
          surveys: state.conference.surveys.map(s => {
            if (s.id !== action.surveyId) {
              return s;
            } else {
              return {
                ...s,
                ...action.data,
              };
            }
          }),
        },
      };
    }
    case 'append-logs': {
      if (!('logs' in state.conference)) return state;

      return {
        ...state,
        conference: {
          ...state.conference,
          logs: [...state.conference.logs.filter(l => !action.logs.find(l2 => l2.id === l.id)), ...action.logs],
        },
      };
    }
  }
}

function createInitialState(): Coordinator.State {
  return {
    conference: null,
  };
}

const waitingRoomEntryChime = cdn.buildAudioCueUrl('waiting-room-enter.mp3');

const usePlayWaitingRoomChime = () => {
  const [preferences] = useConferenceAudioCuePreferences();

  return useCallback(() => {
    if (preferences.playWaitingRoomEntry) {
      const chime = new Audio(waitingRoomEntryChime);
      chime.volume = .5;

      chime.play();
    }
  }, [preferences]);
};