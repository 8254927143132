import { memo, useCallback, useContext, useMemo } from 'react';
import { PlusCircle } from 'react-feather';
import type { CellContext } from '@tanstack/react-table';
import type { ProjectClips } from '@/types/project.clips';
import { cx } from '@utils';
import { SetTaggedMomentEditParamsContext } from '@/containers/Transcript/context.tagged-moment';
import { ProjectClipsFiltersContext } from '@/containers/Project.Clips';
import { useDeleteTranscriptMomentMutation, useInvalidateProjectClipsQuery } from '@/utils/api';
import { VisibleOnHoverOverlay } from '@/components/Editable';
import { TagBadgeMenu } from './Tag.Menu';
import styles from './style/Cell.Tags.css';

type Props = CellContext<ProjectClips.Item, unknown>;

export const TagsCell = ({ row }: Props) => {

  const buildTagContext = useContext(SetTaggedMomentEditParamsContext);
  const filters = useContext(ProjectClipsFiltersContext);

  const tagsWithCounts = useMemo(() => {
    const map: {
      [id: number]: {
        count: number;
        tag: ProjectClips.Tag;
      };
    } = {};
    row.original.tags.forEach(tag => {
      const count = (map[tag.id]?.count || 0) + 1;
      map[tag.id] = {
        count,
        tag: {
          ...tag,
          name: count > 1 ? `${tag.name} (${count})` : tag.name,
        },
      };
    });
    const tags = Object.values(map).map(m => m.tag);

    return tags.sort((a, b) => a.name.localeCompare(b.name));
  }, [row.original.tags]);

  const handleAddTag = useCallback(() => {
    buildTagContext({
      keyword: filters.keyword,
      momentId: null,
      tagId: null,
      range: row.original.range,
      transcriptId: row.original.transcript.id,
    });
  }, [buildTagContext, filters.keyword, row.original.range, row.original.transcript.id]);

  const handleEditTag = useCallback((item: ProjectClips.Tag) => () => {
    buildTagContext({
      keyword: filters.keyword,
      momentId: item.moment.id,
      tagId: item.id,
      range: row.original.range,
      transcriptId: row.original.transcript.id,
    });
  }, [buildTagContext, filters.keyword, row.original.range, row.original.transcript.id]);

  const invalidateClipsQuery = useInvalidateProjectClipsQuery();

  const deleteMutation = useDeleteTranscriptMomentMutation({
    transcriptId: row.original.transcript.id,
  }, {
    onSuccess: () => {
      invalidateClipsQuery();
    },
  });
  const { mutateAsync: deleteTag } = deleteMutation;

  const handleDeleteTag = useCallback((item: ProjectClips.Tag) => () => {
    deleteTag({
      momentId: item.moment.id,
    });
  }, [deleteTag]);

  return (
    <ClipTags
      onAddTag={handleAddTag}
      onDeleteTag={handleDeleteTag}
      onEditTag={handleEditTag}
      items={tagsWithCounts} />
  );
};

type TagsProps = {
  onAddTag: () => void;
  onDeleteTag: (item: ProjectClips.Tag) => () => void;
  onEditTag: (item: ProjectClips.Tag) => () => void;
  items: ProjectClips.Tag[];
};

const ClipTags = memo(({
  onAddTag,
  onDeleteTag,
  onEditTag,
  items,
}: TagsProps) => {

  const showEditPencil = !!items.length;

  const className = cx(styles.root, {
    [styles.editable]: showEditPencil,
  });

  return (
    <VisibleOnHoverOverlay
      enabled={showEditPencil}
      Component={AddTagIcon}
      onClick={onAddTag}>
      <div className={className}>
        {items.map((tag, index) => (
          <TagBadgeMenu
            key={index}
            onDelete={onDeleteTag(tag)}
            onEdit={onEditTag(tag)}
            tag={tag} />
        ))}
        {!items.length && (
          <div className={styles.addTags} onClick={onAddTag}>
            <PlusCircle
              className={styles.plus}
              size={18} />
            Add Tag
          </div>
        )}
      </div>
    </VisibleOnHoverOverlay>
  );
});

type IconProps = {
  className?: string;
  onClick: () => void;
};
const AddTagIcon = memo(({ className, onClick }: IconProps) => {

  return (
    <PlusCircle
      onClick={onClick}
      className={cx(styles.plus, className)}
      size={18} />
  );
});