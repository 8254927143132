import { useCallback, useContext, useEffect } from 'react';
import type { BrandInsightsPageContext } from '@/brand-insights/types';
import { DispatchPageContextDataContext } from '../context';

export function useRegisterChatPageContext() {
  const setChatContext = useContext(DispatchPageContextDataContext);

  useEffect(() => {
    return () => {
      setChatContext(null);
    };
  }, [setChatContext]);

  return useCallback((context: BrandInsightsPageContext.Item) => {
    setChatContext(context);
  }, [setChatContext]);
}