import { memo } from 'react';
import styled from '@emotion/styled';
import { Constants } from '@/brand-insights/components/Theme';

type Props = {
  className?: string;
  isLoading?: boolean;
  onClick?: () => void;
};

export const ViewMoreHistory = memo(({ className, isLoading, onClick }: Props) => {
  return (
    <Root className={className} onClick={onClick}>
      <Wrap>
        {!isLoading && <>{`Load more history`}</>}
      </Wrap>
    </Root>
  );
});

const Root = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  margin: 0 ${Constants.Sidebar.ItemHorizontalPadding}px;
  display: flex;
  justify-content: center;
  height: 35px;
  border-radius: 8px;

  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.palette.btnPrimary.bg};
  }
`;

const Wrap = styled.div`
  padding: 8px;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.semiBold};

  color: ${({ theme }) => theme.palette.btnPrimary.color};
`;