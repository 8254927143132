import { useContext, useMemo } from 'react';
import { ExternalLink } from 'react-feather';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { ChatContextType } from '@/brand-insights/enums';
import { ActiveChatSessionContext } from '@/brand-insights/components/Chat/context';
import { getLocationFor } from '@utils';
import { useIsFullPageChat } from '@/brand-insights/components/Chat/hooks';

export const JumpToPage = () => {

  const to = useJumpToLocation();
  const isFullpageChat = useIsFullPageChat();

  if (!to) {
    return null;
  }

  return (
    <Link to={to}>
      <TextContainer>
        {isFullpageChat && <Text>Jump to Page </Text>}
        <StyledExternalLink />
      </TextContainer>
    </Link>
  );
};

const useJumpToLocation = () => {
  const chat = useContext(ActiveChatSessionContext);

  return useMemo(() => {
    switch (chat.context.type) {
      case ChatContextType.Document: {
        return chat.context.data.projectId
          ? getLocationFor.project.file({
            projectId: chat.context.data.projectId,
            fileId: chat.context.data.documentId,
          })
          : getLocationFor.workspaces.file({
            fileId: chat.context.data.documentId,
          });
      }

      case ChatContextType.Project: {
        return getLocationFor.project.root({
          slug: String(chat.context.data.projectId),
        });
      }

      case ChatContextType.Brand: {
        return getLocationFor.brand.root({
          brandId: chat.context.data.brandId,
        });
      }

      case ChatContextType.Transcript: {
        return chat.context.data.callId && chat.context.data.projectId
          ? getLocationFor.call.transcript({
            callId: chat.context.data.callId,
            slug: chat.context.data.projectId.toString(),
          })
          : getLocationFor.workspaces.transcript({
            transcriptId: chat.context.data.transcriptId,
          });
      }

      case ChatContextType.Folder: {
        return chat.context.data.folderId
          ? getLocationFor.workspaces.folder({
            folderId: chat.context.data.folderId,
          })
          : null;
      }

      case ChatContextType.Global:
        return null;

      default:
        throw new UnreachableCaseError(chat.context);

    }
  }, [chat?.context]);
};

const Text = styled.div`
  color: ${({ theme }) => theme.palette.sentiment.primary.main};
  margin-right: 5px;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledExternalLink = styled(ExternalLink)`
  color: ${({ theme }) => theme.palette.sentiment.primary.main};
`;