import isToday from 'date-fns/isToday';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import format from 'date-fns/format';

export function formatCreatedOnDate(createdOn: Date) {
  if (isToday(createdOn)) {
    return `Created ${formatDistanceToNowStrict(createdOn)} ago`;
  } else {
    return `Created on ${format(createdOn, 'M/d/yyyy')}`;
  }
}
