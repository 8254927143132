import type { ChatState } from './interfaces';

export function inputReducer(state: string, action: ChatState.Action): string {

  switch (action.type) {

    case 'input-changed':
      return action.payload.value;

    case 'query-submitted':
      return '';

    default:
      return state;
  }
}