import { useCallback, useMemo } from 'react';
import { TreeItemContextValue } from './Context';
import { useTreeViewContext } from './hooks/useTreeViewContext';

type Classes = {
  children?: string;
  node?: string;
};

type Props = {
  children: React.ReactNode[];
  classes?: Classes;
  disabled?: boolean;
  nodeId: number;
  renderNode: (ctx: TreeItemContextValue) => React.ReactNode;
};

export const TreeItem = ({
  children,
  classes = {},
  disabled,
  nodeId,
  renderNode,
}: Props) => {

  const {
    selectNode,
    toggleExpansion,
    isExpanded,
    isSelected,
  } = useTreeViewContext();

  const expanded = useMemo(() => isExpanded(nodeId), [isExpanded, nodeId]);
  const expandable = useMemo(() => !!children.length, [children.length]);
  const selected = useMemo(() => isSelected(nodeId), [isSelected, nodeId]);

  const onClick = useCallback(() => {
    if (!disabled) {
      selectNode(nodeId);
    }
    if (expandable) {
      toggleExpansion(nodeId);
    }
  }, [
    disabled,
    expandable,
    nodeId,
    selectNode,
    toggleExpansion,
  ]);

  const ctx: TreeItemContextValue = useMemo(() => ({
    disabled,
    expanded,
    expandable,
    selected,
  }), [
    disabled,
    expandable,
    expanded,
    selected,
  ]);

  return (
    <>
      <div className={classes.node} onClick={onClick}>
        {renderNode(ctx)}
      </div>
      {expandable &&
        <div className={classes.children}>
          {expanded && children}
        </div>}
    </>
  );
};

export default TreeItem;