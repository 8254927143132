import { useEffect } from 'react';
import { useSelectUser } from '@containers/Store';
import { useFetchContactInfo } from '@utils/api';
import * as Article from '@/components/Post.Article';

type Props = unknown;

export const Author = (props: Props) => {
  const user = useSelectUser();
  const { refetch, ...query } = useFetchContactInfo({
    properties: ['career'],
    userId: user.id,
  }, {
    enabled: false,
  });

  useEffect(() => {

    refetch();

  }, [refetch]);

  const item = {
    career: query.data?.career,
    id: user.id,
    profile: user?.profile,
  };

  return (
    <Article.Author item={item} />
  );
};

Author.displayName = 'Post.Article.New.Author';