import { Helmet } from 'react-helmet';
import { useProfileState } from '@containers';

export const UserProfileHelmet = () => {
  const [user] = useProfileState();

  const display = user?.profile?.fullname || 'Profile';

  return (
    <Helmet
      defer={false}
      title={display} />
  );
};

export default UserProfileHelmet;