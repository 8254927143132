import { useCallback, useContext, useState } from 'react';
import { Button } from '@/components/Button';
import { Header } from '@/components/Modal/Header';
import { FileUploadReviewStateContext, FileUploadReviewDispatchContext } from '@containers/WorkspaceFileUpload/Context';
import type { GroupTagUpdateItem } from '@/types';
import { GroupTagSelect } from '@/components/AutoComplete/GroupTagSelect';
import { FileUploadReviewStepperActionsContext } from './context';
import styles from './style/Review.ExistingFiles.css';

export const TagsReview = () => {

  const [tags, setTags] = useState<GroupTagUpdateItem[]>([]);

  const { next: nextStep } = useContext(FileUploadReviewStepperActionsContext);

  const reviewState = useContext(FileUploadReviewStateContext);
  const dispatch = useContext(FileUploadReviewDispatchContext);

  const handleConfirm = useCallback((addTags: boolean) => () => {
    dispatch({
      type: 'tags-updated',
      payload: { tags: addTags ? tags : [] },
    });
    nextStep();
  }, [dispatch, nextStep, tags]);

  const fileCount = Object.keys(reviewState.files).length;

  return (
    <>
      <Header
        className={styles.header}
        text={`Add Tags to ${fileCount} files?`} />

      <div className={styles.body}>
        <GroupTagSelect onChange={setTags} />
      </div>

      <div className={styles.footer}>
        <div className={styles.actions}>
          <Button.Secondary
            className={styles.btn}
            onClick={handleConfirm(false)}
            variant="brick">
            No Thanks
          </Button.Secondary>
          <Button.Affirmative
            className={styles.btn}
            disabled={!tags.length}
            onClick={handleConfirm(true)}
            variant="brick">
            Add Tags
          </Button.Affirmative>
        </div>
      </div>
    </>
  );
};