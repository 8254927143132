import { DecodedValueMap, QueryParamConfig, SetQuery } from 'use-query-params';
import { UseQueryResult } from '@tanstack/react-query';
import * as API from '@api/interfaces';
import { createNamedContext } from '@utils';

type QPConfig = {
  index: QueryParamConfig<number, number>;
  size:  QueryParamConfig<number, number>;
};

type ProjectCallsQueryParamsContextValue = [
  DecodedValueMap<QPConfig>,
  SetQuery<QPConfig>,
];

type ProjectCallsQueryContextValue = UseQueryResult<API.Projects.FetchCalls.Response>;

export const ProjectCallsQueryContext = createNamedContext<ProjectCallsQueryContextValue>(undefined, 'Project.Calls.Query.Context');
export const ProjectCallsQueryParamsContext = createNamedContext<ProjectCallsQueryParamsContextValue>(undefined, 'Project.Calls.QueryParams.Context');