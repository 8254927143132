import { lazy } from 'react';
import * as consts from '@consts';
import { RouteAuth } from '@enums';
import { BasicRouter } from '@routes/Routers';
import * as Routing from '@routes/interfaces';

const Admin = lazy(() => import(
  /* webpackChunkName: "admin" */
  '$admin/Admin')
);

export const routes: Routing.Route[] = [
  {
    component: Admin,
    path: consts.path.Admin.Root,
  },
];

export const router = BasicRouter(routes, {
  auth: RouteAuth.Ignore,
  path: consts.path.Admin.Root,
});