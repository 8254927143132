import { compareAsc, format } from 'date-fns';
import { useCallback } from 'react';
import { VideoRoomMonitor } from '@twilio/video-room-monitor';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import type { IConference } from '@/containers/Conference';
import { useConferenceInstance, useTwilioVideo } from '@/containers/Conference';
import { LogItem } from '@/components/Conference';
import styles from './style/Bar.Right.Logs.css';

export const Logs = () => {
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom>();
  const video = useTwilioVideo();

  const onMonitorClick = useCallback(() => {
    if (VideoRoomMonitor.isOpen) {
      VideoRoomMonitor.closeMonitor();
    } else {
      VideoRoomMonitor.registerVideoRoom(video.room);
      VideoRoomMonitor.openMonitor();
    }
  }, [video.room]);

  const pidNameMapper = usePidNameMapper();

  return (
    <div className={styles.root}>
      <div className={styles.header}>Logs <TroubleshootIcon className={styles.monitorIcon} onClick={onMonitorClick} /></div>
      <div className={styles.logs}>
        {instance.logs.sort((a, b) => compareAsc(a.logTime, b.logTime)).map(r => (<LogItem
          key={r.id}
          record={r}
          pidNameMapper={pidNameMapper} />))}
      </div>
    </div>
  );
};

const usePidNameMapper = () => {
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom>();

  return useCallback((pid: string) => {
    return instance.participants.find(p => p.id === pid)?.name ?? 'Unknown';
  }, [instance.participants]);
};