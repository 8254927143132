import { useContext, useEffect, useRef } from 'react';
import { useRemirrorContext } from '@remirror/react';
import { cx } from '@utils';
import { FindReplaceContext } from './context';
import { } from './utils';
import styles from './style/FindReplace.css';

export const Preview = () => {
  const ctx = useContext(FindReplaceContext);

  return (
    <div className={styles.previews}>
      {ctx.ranges.map((m, i) => (
        <RangePreview
          key={`${m.from}-${m.to}`}
          from={m.from}
          to={m.to}
          index={i} />
      ))}
    </div>
  );
};

type RangePreviewProps = {
  from: number;
  to: number;
  index: number;
};

const SNIPPET_BUFFER = 20;

const RangePreview = (props: RangePreviewProps) => {
  const ref = useRef<HTMLDivElement>();
  const { activeIndex, findIndex } = useContext(FindReplaceContext);
  const remirror = useRemirrorContext();

  const doc = remirror.view.state.doc;

  const active = props.index === activeIndex;

  useEffect(() => {
    if (props.index === activeIndex) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [props.index, activeIndex]);

  //TODO: snap to words

  const node = doc.nodeAt(props.from);

  const resolved = doc.resolve(props.from);

  const blockStart = resolved.start();
  const blockEnd = resolved.end();

  const highlighted = doc.textBetween(props.from, props.to);
  const before = doc.textBetween(Math.max(props.from - SNIPPET_BUFFER, blockStart), props.from);
  const after = doc.textBetween(props.to, Math.min(props.to + SNIPPET_BUFFER, blockEnd));

  return (
    <div
      ref={ref}
      onClick={() => findIndex(props.index)}
      className={cx(styles.preview, active ? styles.active : null)}>{before}<span className={styles.highlighted}>{highlighted}</span>{after}
    </div>
  );
};