import { SearchFiltersContext } from './Context';
import { useSearchFiltersQuery } from './hooks/useSearchFilterQuery';

export default function Filters({ children }: ChildrenProps) {
  const [filters, actions] = useSearchFiltersQuery();

  return (
    <SearchFiltersContext.Provider value={[filters, actions]}>
      {children}
    </SearchFiltersContext.Provider>
  );
}

export { Filters };
