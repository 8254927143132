import { path, pathname } from '@consts';
import { RouteAuth } from '@enums';
import { BasicRouter } from '@routes/Routers';
import * as Routing from '@routes/interfaces';
import { CompanyLanding, CompanyNotFound } from '@screens/Company.Landing';

export const routes: Routing.Route[] = [
  {
    component: CompanyNotFound,
    exact: true,
    path: path.Companies.NotFound,
  },
  {
    component: CompanyLanding,
    path: path.Companies.Company,
  },
];

export const router = BasicRouter(routes, {
  auth: RouteAuth.Authenticated,
  path: pathname.Companies,
});