import { useMemo } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { useSelectUser } from '@containers/Store';
import { hasCallTranscriptWritePermission, hasDataStewardRole } from '@utils';

export const useEditingToolsDisplayable = () => {
  const [tab] = useQueryParam('tab', StringParam);
  const user = useSelectUser();

  const displayable = useMemo(() => {
    const access = hasCallTranscriptWritePermission(user);
    const datasteward = hasDataStewardRole(user);

    return (access || datasteward)
        && (tab === 'transcript' || tab === undefined);
  }, [
    tab,
    user,
  ]);

  return displayable;
};