import { useCallback, useState } from 'react';
import { ConferenceTagSummaryItem } from '@/types/conferences.tags';
import { useFetchTranscriptTagSummaryCitations } from '@utils/api';
import { TagSummaryCitationsModal } from '@/components/ConferenceTag.Summary/Modal.Citations';
import { SummaryCitationsClickedContext } from './context';

type Props = ChildrenProps & ITranscriptId;

export const TranscriptSummaryCitationsContainer = ({ children, transcriptId }: Props) => {

  const [openItem, setOpenItem] = useState<ConferenceTagSummaryItem>();
  const tagSummaryId = openItem?.summary?.id;

  const {
    data,
    isInitialLoading: isLoading,
  } = useFetchTranscriptTagSummaryCitations({
    transcriptId,
    tagSummaryId,
  }, {
    enabled: !!tagSummaryId,
    placeholderData: {
      citations: [],
    },
    refetchOnWindowFocus: false,
  });

  const handleOpen = useCallback((item: ConferenceTagSummaryItem) => {
    setOpenItem(item);
  }, []);

  const handleClose = useCallback(() => {
    setOpenItem(null);
  }, []);

  const open = !!tagSummaryId;

  return (
    <SummaryCitationsClickedContext.Provider value={handleOpen}>
      {children}
      {open && (
        <TagSummaryCitationsModal
          projectId={null}
          hideSourceColumn={true}
          summaryItem={openItem}
          open={open}
          onClose={handleClose}
          items={data.citations.map(c => ({ ...c, callId: null }))} />
      )}
    </SummaryCitationsClickedContext.Provider>
  );
};