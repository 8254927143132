import {
  PersonaResearchQuestion,
  SurveyTemplateEntityType,
  SurveyTemplateQuestion,
} from '@enums/survey.template';
import { hasOptions, hasRows } from '@containers/Survey/utils/questions';
import { SurveyTemplate, SurveyQuestion, SurveyQuestionOptionMetadata, SurveyQuestionOption } from '@/types/survey';
import {
  SurveyBuilder,
  SurveyTemplateBuilder,
} from '../interfaces';
import * as $template from '../template/utils';
import * as $templateUtils from './state.template.utils';
import * as $utils from './state.questions.template.utils';

export * from './state.questions.template.tpp';

export function templateTargetUpdated(state: SurveyBuilder.State, action: SurveyTemplateBuilder.TemplateTargetUpdated.State): SurveyQuestion[] {

  return state.survey.questions.reduce<SurveyQuestion[]>((acc, q) => {

    const options = hasOptions(q) ?
      q.options.map((m: SurveyQuestionOption) => ({
        ...m,
        value: m.metadata.template.linkedEntity?.type === SurveyTemplateEntityType.Target
          ? action.value
          : m.value,
      }))
      : q.options;

    const matrixRows = hasRows(q) ?
      q.matrixRows.map(m => ({
        ...m,
        value: m.metadata.template.linkedEntity?.type === SurveyTemplateEntityType.Target ? action.value : m.value,
      }))
      : q.matrixRows;

    return acc.concat({
      ...q,
      matrixRows,
      options,
    } as SurveyQuestion);
  }, []);

}

export function templateEvaluationCriteriaUpdated(state: SurveyBuilder.State, action: SurveyTemplateBuilder.EvaluationCriteriaUpdated.State): SurveyQuestion[] {
  const existingCriteria = (state.survey.template.data as SurveyTemplate.PersonaResearchTemplate).evaluationCriteria;

  const {
    added: addedCriteria,
    removed: removedCriteria,
    updated: updatedCriteria,
  } = $templateUtils.computeChangedValues({
    oldValues: existingCriteria,
    values: action.value,
  });

  const optionQuestions: SurveyTemplateQuestion[] = [
    PersonaResearchQuestion.EvaluationCriteria,
  ];

  const removedOptions = $templateUtils.computeRemovedOptions({
    key: SurveyTemplateEntityType.EvaluationCriteria,
    questions: state.survey.questions,
    removedItems: removedCriteria,
  });
  const addedOptions = $templateUtils.computeAddedOptions({
    generateOption: $template.generateEvaluationCriteriaOption,
    addedItems: addedCriteria,
    questions: state.survey.questions,
    toCheck: optionQuestions,
  });
  const updatedOptions = $templateUtils.computeUpdatedOptions({
    questions: state.survey.questions,
    toCheck: optionQuestions,
    updatedItems: updatedCriteria,
  });

  return $utils.applyChangesToSurvey({
    addedOptions,
    survey: state.survey,
    removedOptions,
    updatedOptions,
  });
}

export function templatePartnershipCriteriaUpdated(state: SurveyBuilder.State, action: SurveyTemplateBuilder.PartnershipCriteriaUpdated.State): SurveyQuestion[] {
  const existingCriteria = (state.survey.template.data as SurveyTemplate.PersonaResearchTemplate).partnershipCriteria;

  const {
    added: addedCriteria,
    removed: removedCriteria,
    updated: updatedCriteria,
  } = $templateUtils.computeChangedValues({
    oldValues: existingCriteria,
    values: action.value,
  });

  const optionQuestions: SurveyTemplateQuestion[] = [
    PersonaResearchQuestion.PartnershipCriteria,
  ];

  const removedOptions = $templateUtils.computeRemovedOptions({
    key: SurveyTemplateEntityType.PartnershipCriteria,
    questions: state.survey.questions,
    removedItems: removedCriteria,
  });
  const addedOptions = $templateUtils.computeAddedOptions({
    generateOption: $template.generatePartnershipCriteriaOption,
    addedItems: addedCriteria,
    questions: state.survey.questions,
    toCheck: optionQuestions,
  });
  const updatedOptions = $templateUtils.computeUpdatedOptions({
    questions: state.survey.questions,
    toCheck: optionQuestions,
    updatedItems: updatedCriteria,
  });

  return $utils.applyChangesToSurvey({
    addedOptions,
    survey: state.survey,
    removedOptions,
    updatedOptions,
  });
}

export function templateTargetRolesUpdated(state: SurveyBuilder.State, action: SurveyTemplateBuilder.TargetRolesUpdated.State): SurveyQuestion[] {
  const existingRoles = (state.survey.template.data as SurveyTemplate.PersonaResearchTemplate).roles;

  const {
    added: addedRoles,
    removed: removedRoles,
    updated: updatedRoles,
  } = $templateUtils.computeChangedValues({
    oldValues: existingRoles,
    values: action.value,
  });

  const optionQuestions: SurveyTemplateQuestion[] = [
    PersonaResearchQuestion.TargetRole,
    PersonaResearchQuestion.FinalDecisionMaker,
  ];

  const removedOptions = $templateUtils.computeRemovedOptions({
    key: SurveyTemplateEntityType.TargetRole,
    questions: state.survey.questions,
    removedItems: removedRoles,
  });
  const addedOptions = $templateUtils.computeAddedOptions({
    generateOption: $template.generateTargetRoleOption,
    addedItems: addedRoles,
    questions: state.survey.questions,
    toCheck: optionQuestions,
  });
  const updatedOptions = $templateUtils.computeUpdatedOptions({
    questions: state.survey.questions,
    toCheck: optionQuestions,
    updatedItems: updatedRoles,
  });

  return $utils.applyChangesToSurvey({
    addedOptions,
    survey: state.survey,
    removedOptions,
    updatedOptions,
  });

}