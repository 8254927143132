import { Redirect, Route, Switch } from 'react-router-dom';
import * as consts from '@consts';
import { Overview } from './Settings.UserBilling.Overview';

const routes = [
  {
    name: 'Overview',
    to: consts.path.Settings.UserBilling,
    component: Overview,
    exact: true,
  },
];

export function UserBilling() {
  return (
    <Switch>
      {routes.map(route =>
        <Route
          exact={route.exact}
          key={route.name}
          path={route.to}
          component={route.component} />)}
      <Redirect to={consts.path.Settings.Account} />
    </Switch>
  );
}