import { useSlate } from 'slate-react';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { cx } from '@utils';
import * as utils from './utils';
import styles from './style.css';

export type BlockButtonFormat = 'bulleted-list' | 'numbered-list';

export type BlockButtonProps = {
  className?: string;
  format: BlockButtonFormat;
}

const BulletedList = () => (
  <div className={styles.bulletedList}>
    <FormatListBulletedIcon />
  </div>
);

const NumberedList = () => (
  <div className={styles.numberedList}>
    <FormatListNumberedIcon />
  </div>
);

export const BlockButton = (props: BlockButtonProps) => {
  const editor = useSlate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.TouchEvent<HTMLButtonElement>) => {
    event.preventDefault();
    utils.toggleBlock(editor, props.format);
  };

  function Icon() {
    switch (props.format) {
      case 'bulleted-list': return <BulletedList />;
      case 'numbered-list': return <NumberedList />;
      default: return null;
    }
  }

  const active = utils.isBlockActive(editor, props.format);

  return (
    <button
      className={cx(styles.icon, { [styles.active]: active }, props.className)}
      onTouchStart={handleClick}
      onMouseDown={handleClick}>
      <Icon />
    </button>
  );
};

export default BlockButton;