import { useContext } from 'react';
import { Chip } from '@presentation/Chip';
import { cx } from '@utils';
import { ProjectInviteReferralEmailContext } from './Context';
import styles from './style/Email.Tags.css';

type Props = {
  className?: string;
};

export const AddressTags = (props: Props) => {
  const ctx = useContext(ProjectInviteReferralEmailContext);

  return (
    <div className={cx(styles.root, props.className)}>
      {ctx.data.addresses.map(x =>
        <Chip
          className={styles.tag}
          color="selected"
          key={x}>
          <Chip.Body.Action
            onClick={() => ctx.removeAddress(x)}
            variant="remove">
            {x}
          </Chip.Body.Action>
        </Chip>)}
    </div>
  );
};

AddressTags.displayName = 'ProjectInviteReferral.Email.AddressTags';