import { useContext, useMemo } from 'react';
import { ActiveChatSessionContext, ChatPermissionsContext } from './context';

type Props = ChildrenProps;

export function ChatPermissionsContainer({ children }: Props) {
  const chat = useContext(ActiveChatSessionContext);

  const value = useMemo(() => ({
    canModify: !chat?.shared,
  }), [chat?.shared]);

  return (
    <ChatPermissionsContext.Provider value={value}>
      {children}
    </ChatPermissionsContext.Provider>
  );
}