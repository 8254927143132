/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import styled from '@emotion/styled';

type SelectionDimensionProps = {
  className?: string;
  id: number;
  value: string;
  onChange: (id: number, value: string) => unknown;
  readonly: boolean;
};

export const SegmentationDimensionSelectionDimension = (props: SelectionDimensionProps) => {
  const { onChange, id } = props;

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(id, event.target.value);
  }, [id, onChange]);

  return (
    <Root className={props.className}>
      <Input
        name={`dim-${props.id}`}
        type="text"
        value={props.value}
        onChange={handleChange}
        placeholder="Enter a question or topic to analyze."
        disabled={props.readonly}
        tabIndex={props.id + 1} />
    </Root>
  );
};

const Root = styled.div`
  box-sizing: border-box;
  padding: 5px;
`;

const Input = styled.input(({ theme }) => ({
  boxSizing: 'border-box',
  border: `1px solid ${theme.palette.gray.light1}`,
  borderRadius: '4px',
  padding: '6px',
  fontSize: '15px',
  fontFamily: theme.fonts.regular,
  width: '100%',

  '::placeholder': {
    color: theme.palette.gray.main,
  },
}));