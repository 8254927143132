import http from '@services/http';
import * as xform from '@transformers';
import { GroupSignatureRequest } from '@/types';
import { nullableDate } from '@/utils';
import { Application } from './interfaces';

/**
 * When set to true, the folowing will be omitted from the response:
 *
 *    * Calls
 *    * Contacts
 *    * Pipeline
 *    * Projects
 *
 * This is currently ignored if the user has an Expert role.
 */
const Experimental = true;

export const fetchApp = () => {
  process.env.__DEV__ && console.time('Application State');
  return http.get(`/users/me`, {
    params: {
      experimental: Experimental,
    },
  })
    .then(({ user: { onboarding, ...user }, ...res }: Application.FetchAppResponse<string>) => {

      // console.time('normalize');

      const normalized = {
        calls: xform.normalizeCalls(res.calls),
        chat: xform.normalizeChat(res.chat),
        contacts: xform.normalizeContacts(res.contacts),
        documents: { group: xform.toMap<number, GroupSignatureRequest>(res.documents.group, 'groupId') },
        group: res.group,
        onboarding: xform.normalizeOnboarding(onboarding),
        pipeline: xform.normalizePipeline(res.pipeline),
        platform: res.platform,
        projects: xform.normalizeProjects(res),
        settings: res.settings,
        user: xform.normalizeUser(user),
        workspaces: xform.normalizeWorkspaces(res.workspaces),
        session: res.session ? {
          ...res.session,
          expiry: nullableDate(res.session.expiry),
        } : undefined,
      };

      // console.timeEnd('normalize');

      process.env.__DEV__ && console.timeEnd('Application State');
      return normalized;
    });
};

export const rehydrateApp = () => {
  return http.get(`/users/me/hydrate`, {
    params: {
      experimental: Experimental,
    },
  })
    .then((res: Application.RehydrateAppResponse<string>) => {

      const normalized = {
        calls: xform.normalizeCalls(res.calls),
        chat: xform.normalizeChat(res.chat),
        contacts: xform.normalizeContacts(res.contacts),
        documents: { group: xform.toMap<number, GroupSignatureRequest>(res.documents.group, 'groupId') },
        group: res.group,
        pipeline: xform.normalizePipeline(res.pipeline),
        platform: res.platform,
        projects: xform.normalizeProjects(res),
        settings: res.settings,
        user: res.user, // needs xform.addUserBindings when not partial
        workspaces: xform.normalizeWorkspaces(res.workspaces),
        session: res.session ? {
          ...res.session,
          expiry: nullableDate(res.session.expiry),
        } : undefined,
      };

      return normalized;
    });
};