import { useCallback, useRef } from 'react';

export const useThrottleCallback = <CallbackReturnType extends unknown | void, CallbackArgs extends unknown[] = []>(
  callback: (...args: CallbackArgs) => Promise<CallbackReturnType>,
  maxConcurrency,
): ((...args: CallbackArgs) => Promise<CallbackReturnType>) => {
  const promises = useRef<Promise<unknown>[]>([]);

  const throttled = useCallback(async (...args: CallbackArgs) => {
    while (promises.current.length >= maxConcurrency) {
      await Promise.race(promises.current).catch(() => {});
    }

    const p = callback(...args);
    promises.current.push(p);
    await p.catch(() => {});
    promises.current = promises.current.filter(pending => pending !== p);

    return p;
  }, [
    callback,
    maxConcurrency,
  ]);

  return throttled;
};

export default useThrottleCallback;