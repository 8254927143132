import { Check, Copy } from 'react-feather';
import { useCopy, useToggle } from '@utils/hooks';
import { Button } from '@/components/Button';
import { useModal } from '@/components/Modal/hooks';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import { Header } from '@/components/Modal/Header';
import { useProjectSurveyMetricsPassword } from './hooks/useFetchDashboardPassword';
import styles from './style/AccessModal.css';

type Props = {
  project: {
    id: number;
  };
} & Pick<ModalProps, 'open' | 'onClose'>;

export const SurveyMonitoringAccessModal = ({
  open,
  onClose,
  project,
}: Props) => {
  const [showPassword, toggleShowPassword] = useToggle(false);
  const {
    copy: copyLink,
    copied: linkCopied,
    target: linkRef,
  } = useCopy();
  const {
    copy: copyPassword,
    copied: passwordCopied,
    target: passwordRef,
  } = useCopy();

  const password = useProjectSurveyMetricsPassword({
    projectId: project.id,
  }, {
    enabled: showPassword,
  });

  return (
    <Modal open={open} onClose={onClose}>
      <div className={styles.root}>
        <Header className={styles.header}>Dashboard Access</Header>

        <div className={styles.link}>
          <input
            readOnly
            ref={linkRef}
            className={styles.input}
            value={window.location.href} />
          <Button.Secondary
            className={styles.btn}
            variant="brick"
            onClick={copyLink}>
            {linkCopied ? 'Copied' : 'Copy'}
          </Button.Secondary>
        </div>

        <div className={styles.password}>
          {!showPassword &&
            <div className={styles.reveal} onClick={toggleShowPassword}>Show Password</div>
          }
          {showPassword && !password.isInitialLoading &&
            <>
              <span className={styles.title}>Password:</span>
              <input
                readOnly
                ref={passwordRef}
                className={styles.input}
                value={password.data.value} />
              {passwordCopied &&
                <Check
                  size={20}
                  className={styles.checkIcon} />
              }
              {!passwordCopied &&
                <Copy
                  size={20}
                  className={styles.copyIcon}
                  onClick={copyPassword} />
              }
            </>
          }
        </div>
      </div>
    </Modal>
  );
};

export const useSurveyMonitoringAccessModal = () => useModal(SurveyMonitoringAccessModal);

export default SurveyMonitoringAccessModal;