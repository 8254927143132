import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Proposal } from './Proposal';
import { SchedulingProposingComponentProps } from './interfaces';
import styles from './style/Responsive.css';

type Props =
  Omit<SchedulingProposingComponentProps, 'schedulerId'>;

const ProposalResponsive = (props: Props) => {
  const history = useHistory();

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <button
            className={styles.back}
            onClick={history.goBack}>
            <ArrowBackIcon className={styles.arrow} />
          </button>
          <div className={styles.title}>Provide Your Availability</div>
        </div>
        <Proposal
          callId={props.callId}
          className={styles.calendar}
          onSubmit={props.onSubmit}
          scheduleeId={props.scheduleeId} />
      </div>
    </div>
  );
};

export { ProposalResponsive };
export default ProposalResponsive;