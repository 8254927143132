import { useCallback } from 'react';
import { SurveyTagging } from '@/types/survey';
import { SurveyTaggingItemContainer } from '@containers/SurveyBuilder.Tagging';
import { useSurveyTaggingState } from '@containers/SurveyBuilder/Context';
import { SurveyMatchContainer } from '@/containers/SurveyBuilder.Logic';
import { AddTagButton, SurveyTaggingItemBuilder, SurveyTaggingItemPreview } from '@/components/SurveyBuilder.Tagging';
import styles from './style/QuotasBuilder.css';

export const SurveyTaggingBuilder = () => {
  const [state] = useSurveyTaggingState();

  const renderItem = useCallback((item: SurveyTagging.Item) => {
    if (item.identifier === state.editing.identifier) {
      return (
        <SurveyTaggingItemContainer initial={item}>
          <SurveyTaggingItemBuilder />
        </SurveyTaggingItemContainer>
      );
    } else {
      return (
        <SurveyMatchContainer value={{
          conditions: item.conditions,
          rule: item.rule,
        }}>
          <SurveyTaggingItemPreview
            hideContextMenu={!!state.editing.identifier}
            item={item} />
        </SurveyMatchContainer>
      );
    }
  }, [state.editing]);

  const canAddTag = !state.editing.identifier;

  return (
    <div className={styles.root}>
      {state.items.map((item, i) => (
        <div key={item.identifier}>
          {renderItem(item)}
          {i !== (state.items.length - 1) && <div className={styles.divider} />}
        </div>
      ))}
      <div className={styles.addItem}>
        {canAddTag && <AddTagButton />}
      </div>
    </div>
  );
};

export default SurveyTaggingBuilder;