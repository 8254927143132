import { useCallback } from 'react';
import { InvalidContentHandler } from '@remirror/core';
import { useRemirror } from '@remirror/react';
import {
  DocExtension, TextExtension,
  ParagraphExtension, PlaceholderExtension,
  BoldExtension, ItalicExtension,
  LinkExtension, UnderlineExtension,
  PlaceholderOptions, LinkOptions,
} from 'remirror/extensions';
import { Remirror } from '@/types';

type Props = {
  initialState?: Remirror.RootNode;
  placeholder?: string;
};

export const defaultProjectNotesContent: Remirror.RootNode = {
  type: Remirror.NodeType.Doc,
  content: [{
    content: [],
    type: Remirror.NodeType.Paragraph,
  }],
};

export const useProjectNotesEditor = ({ initialState, placeholder }: Props) => {

  const onError: InvalidContentHandler = useCallback(({ json, invalidContent, transformers }) => {
    console.error('Invalid Content', invalidContent);
    return transformers.remove(json, invalidContent);
  }, []);

  const linkExtensionParams: LinkOptions = {
    autoLink: true,
    defaultTarget: '_blank',
    // defaultProtocol: 'https:',
  };

  const placeholderExtensionParams: PlaceholderOptions = {
    placeholder,
  };

  return useRemirror({
    content: initialState || defaultProjectNotesContent,
    extensions: () => [
      new DocExtension({}),
      new PlaceholderExtension(placeholderExtensionParams),
      new ParagraphExtension({}),
      new TextExtension(),
      new BoldExtension({}),
      new ItalicExtension(),
      new UnderlineExtension(),
      new LinkExtension(linkExtensionParams),
    ],
    onError,
  });

};