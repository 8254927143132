import type { AxiosError } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import type { Surveys } from '@api/interfaces';

type Data = Surveys.GetSurveyForm.Response;
type QueryKey = [string, number];

type Props = {
  preview: boolean;
  ordinal?: number;
} & ISurveyVersionId;

const useFetchSurveyVersionForm = (
  {
    preview,
    surveyVersionId,
    ordinal,
  }: Props,
  options?: UseQueryOptions<Data, AxiosError, Data, QueryKey>
) => {

  return useQuery({
    queryKey: ['fetch-survey-version-form', surveyVersionId, ordinal, preview], queryFn: ({ queryKey }) => {
      const surveyVersionId = queryKey[1] as number;
      const ordinal = queryKey[2] as number;
      const preview = queryKey[3] as boolean;

      return api.surveys.version.getSurveyVersionForm({
        preview,
        surveyVersionId,
        ordinal,
      });
    }, ...options,
  });
};

export { useFetchSurveyVersionForm };
export default useFetchSurveyVersionForm;