import { useContext, useMemo } from 'react';
import { Notes, Edit } from '@mui/icons-material';
import { cx } from '@utils';
import { Tagging as TaggingIcon } from '@/components/icons';
import { TaggingContext } from '@/components/Conference.Tagging/Context';
import { PanelToolsContext } from './Context';
import styles from './style/Panel.Toolbar.css';

type Props = unknown;

export const Toolbar = (props: Props) => {
  const { notes, tagging } = useContext(PanelToolsContext);
  const ctx = useContext(TaggingContext);

  const count = useMemo(() => {
    const active = ctx.items.filter(x => !!x.start);

    return active.length;
  }, [ctx.items]);

  if (!notes?.enabled && !tagging?.enabled) return null;

  return (
    <div className={styles.root}>
      {tagging?.enabled &&
        <TaggingToggle
          count={count}
          onClick={tagging.toggle}
          visible={tagging.visible} />}
      {notes?.enabled &&
        <NotesToggle
          onClick={notes.toggle}
          visible={notes.visible} />}
    </div>
  );
};

Toolbar.displayName = 'Panel.Toolbar';

type NotesToggleProps = {
  onClick: () => void;
  visible: boolean;
};

const NotesToggle = (props: NotesToggleProps) => {
  return (
    <div onClick={props.onClick}>
      <div className={cx(styles.icon, { [styles.open]: props.visible })}>
        <Notes className={styles.notes} />
        <Edit className={styles.edit} />
      </div>
    </div>
  );
};

NotesToggle.displayName = 'Panel.Toolbar.NotesToggle';

type TaggingProps = {
  count:   number;
  onClick: () => void;
  visible: boolean;
};

export const TaggingToggle = (props: TaggingProps) => {
  return (
    <div
      className={cx(styles.icon, { [styles.open]: props.visible })}
      onClick={props.onClick}>
      <TaggingIcon
        className={cx(styles.tagging, { [styles.active]: props.count > 0 })}
        size={28} />
      {props.count > 0 &&
        <div className={styles.badge}>{props.count}</div>}
    </div>
  );
};

TaggingToggle.displayName = 'Panel.Toolbar.TaggingToggle';