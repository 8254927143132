import { useCallback } from 'react';
import * as api from '@api';
import { useContentFeedState } from '@containers/ContentFeed/hooks/useContentFeedState';
import * as PC from '@containers/PostCreation/interfaces';
import { FeedItemType } from '@enums';
import Toast from '@/components/Toast';

export const usePublishSubscriber = () => {
  const [_, dispatch] = useContentFeedState();

  const onPublished = async (data: PC.Subscription.OnPublished.Params) => {
    Toast.alert({
      title: 'Post Created',
    });

    const response = await api.feed.fetchPost({
      postId: data.post.id,
    });

    const item = response?.post
        ? response.post
        : data.post;

    dispatch({
      item: {
        ...item,
        contentType: FeedItemType.Post,
      },
      type: 'post-created',
    });

    return item;
  };

  const onError = (e: Error) => {
    Toast.error({
      title: 'Error',
      body: `We're sorry, there was an issue with your request`,
    });

    throw e;
  };

  return {
    onPublished: useCallback(onPublished, [dispatch]),
    onError: useCallback(onError, []),
  };
};
