import { useContext } from 'react';
import { format } from 'date-fns/fp';
import { CalendarRange } from '@/types';
import * as Action from './AvailabilitySchedule.Action';
import { BookingSegmentContext } from './Context';
import styles from './style/AvailabilitySchedule.css';

type Props = CalendarRange<Date>;

export const Segment = (props: Props) => {
  const [, setRange] = useContext(BookingSegmentContext);

  return (
    <>
      <div className={styles.range}>
        {`${formatTime(props.start)} - ${formatTime(props.end)}`}
      </div>
      <div className={styles.range} />
      <Action.Schedule onClick={() => setRange(props)} />
    </>
  );
};

Segment.displayName = 'AvailabilitySchedule.Segment';

const fTime = format('h:mm a');

function formatTime(datestring: Date | string) {
  return fTime(new Date(datestring));
}