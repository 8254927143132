import { useCallback } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder';
import { SurveyAlternateImagesBuilderCancelContext } from './context';
import { AlternateImagesBuilderSaveContainer } from './SaveContainer';

type Props =
  ChildrenProps;

export const AlternateImagesBuilderContainer = (props: Props) => {

  const [, dispatch] = useSurveyBuilderState();

  const handleCancel = useCallback(() => {
    dispatch({
      type: 'revert-uncommitted-changes',
    });
  }, [
    dispatch,
  ]);

  return (
    <SurveyAlternateImagesBuilderCancelContext.Provider value={handleCancel}>
      <AlternateImagesBuilderSaveContainer>
        {props.children}
      </AlternateImagesBuilderSaveContainer>
    </SurveyAlternateImagesBuilderCancelContext.Provider>
  );
};