import { Fragment, useCallback, useMemo } from 'react';
import type { SurveyQuestionType } from '@enums';
import { SurveyOptionType } from '@enums';
import { getSegmentColor } from '@containers/SurveyResponses/utils/matrix';
import { useProjectSurveyResponsesState } from '@containers/SurveyResponses/hooks/useProjectSurveyResponsesState';
import { SurveyResponsesHeader } from '@presentation/ProjectSurveyResponses';
import type { MatrixMultiselectQuestion } from '@/types';
import { parseSurveyRichText } from '@/containers/Survey/utils/rich-text';
import { useMatrixColors } from './hooks/usePaletteColors';
import type { MatrixOptionItem, MatrixGroupItem } from './interfaces';
import styles from './style/Options.css';
import Chart from './MatrixMultiselect.Chart';
import { MatrixAggregateData } from './Matrix.Data.Aggregate';
import { MatrixGroupItemData } from './Matrix.Data.Option';
import { SegmentedDataTooltip } from './SegmentedDataTooltip';

type Props = {
  question: MatrixMultiselectQuestion.Question;
  responses: MatrixMultiselectQuestion.Aggregate.Data;
};

export const MatrixMultiselectResponses = (props: Props) => {
  const {
    query,
    resetQueryState,
    setQueryState,
  } = useProjectSurveyResponsesState<SurveyQuestionType.MatrixMultiselect>();

  const handleSegmentClick = useCallback((groupId: number, key: string) => {
    setQueryState({
      key,
      groupId,
    });
  }, [setQueryState]);

  const colors = useMatrixColors();

  const items: MatrixGroupItem[] = useMemo(() => {

    const groups = props.question.settings.groupBy === 'row'
      ? props.question.matrixRows.map(m => ({
        id: m.base.id,
        value: m.value,
      }))
      : props.question.options.map(m => ({
        id: m.base.id,
        value: m.value,
      }));

    const items = props.question.settings.groupBy === 'row'
      ? props.question.options.map(m => ({
        id: m.base.id,
        isNotApplicable: m.type === SurveyOptionType.NotApplicable,
        value: m.value,
      }))
      : props.question.matrixRows.map(m => ({
        id: m.base.id,
        isNotApplicable: false,
        value: m.value,
      }));

    return groups
      .map(group => {
        const groupData = props.responses[group.id];

        const options = items.reduce<MatrixOptionItem[]>((acc, item) => {
          const itemData = groupData.items[item.id];

          return acc.concat({
            color: getSegmentColor({
              colors,
              option: item,
              options: items,
            }),
            id: item.id,
            name: parseSurveyRichText(item.value),
            userIds: itemData.userIds,
          });
        }, []);

        return {
          count: groupData.count,
          id: group.id,
          name: parseSurveyRichText(group.value),
          options,
        } as MatrixGroupItem;
      });
  }, [
    colors,
    props.question,
    props.responses,
  ]);

  const renderData = useCallback(() => {
    if (query.groupId && query.key) {
      const groupValue = props.question.settings.groupBy === 'row'
        ? props.question.matrixRows.find(f => f.base.id === query.groupId).value
        : props.question.options.find(f => f.base.id === query.groupId).value;
      const item = items.find(f => f.id === query.groupId).options.find(f => f.id.toString() === query.key);

      return (
        <MatrixGroupItemData
          item={item}
          onReset={resetQueryState}
          group={{
            value: parseSurveyRichText(groupValue),
          }} />
      );
    } else {
      return (
        <MatrixAggregateData
          items={items}
          onClick={handleSegmentClick} />
      );
    }
  }, [
    items,
    handleSegmentClick,
    props.question.options,
    props.question.matrixRows,
    props.question.settings.groupBy,
    resetQueryState,
    query,
  ]);

  const keys = useMemo(() => {
    return props.question.settings.groupBy === 'row'
      ? props.question.options.map(m => ({
        id: m.base.id,
        isNotApplicable: m.type === SurveyOptionType.NotApplicable,
      }))
      : props.question.matrixRows.map(m => ({
        id: m.base.id,
        isNotApplicable: false,
      }));
  }, [props.question.matrixRows, props.question.options, props.question.settings.groupBy]);

  return (
    <Fragment>
      <SurveyResponsesHeader
        question={props.question}>
        <SegmentedDataTooltip questionIdentifier={props.question.base.identifier} />
      </SurveyResponsesHeader>
      <Chart
        keys={keys}
        responses={items}
        onSegmentClick={handleSegmentClick} />
      <div className={styles.options}>
        {renderData()}
      </div>
    </Fragment>
  );
};

export default MatrixMultiselectResponses;