import { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@tanstack/react-query';
import { SearchType } from '@/enums';
import { GroupTagsSelector } from '@/components/Group.Tags';
import * as $api from '@/services/api';
import type { GroupTag } from '@/types';

type Props = {
  callId: number;
  onChange: (value: GroupTag[]) => unknown;
};

export function Tags({ callId, onChange }: Props) {
  const [tags, setTags] = useState<GroupTag[]>([]);

  const handleChange = useCallback((tags: GroupTag[]) => {
    setTags(tags);
    onChange(tags);
  }, [onChange]);

  useQuery({
    queryKey: ['get:group/tags/call', callId],
    queryFn: () => $api.tags.group.getCallTags({ callId }),
    onSuccess: result => handleChange(result.tags),
  });

  return (
    <Root>
      <Title>Tag This Call</Title>
      <GroupTagsSelector
        queryKeyIdentifier='call'
        value={tags}
        onChange={tags => {
          handleChange(tags.map((t, i) => ({
            // eslint-disable-next-line no-underscore-dangle
            id: t.__isNew__ ? -1 * (i + 1) : t.id,
            // eslint-disable-next-line no-underscore-dangle
            name: t.__isNew__ ? t.label : t.name,
          })));
        }}
        fetcher={(value: string) => {
          return $api.search.getAutocompleteResults<SearchType.GroupTag>({
            type: SearchType.GroupTag,
            text: value,
            ignoreList: [],
          }).then(result => result.map(i => ({ id: i.id, name: i.name })));
        }} />
    </Root>
  );
}

const Root = styled.div`
  margin-bottom: 40px;
`;

const Title = styled.h2`
  font-size: 16px;
  font-family: var(--font-semibold);
  margin-bottom: 10px;
`;