import { useCallback, useMemo } from 'react';
import {
  differenceInCalendarDays, differenceInCalendarMonths, differenceInCalendarYears,
  subDays, subMonths, subYears,
} from 'date-fns';
import Select, { type ActionMeta } from 'react-select';
import { css } from '@emotion/css';
import { useTheme } from '@/brand-insights/components/Theme';
import * as Presentation from './Presentation';
import type { PublishedDateFilter } from './interfaces';

type Props = {
  defaultValue: Date;
  value: Date;
  onChange: (value: Date, meta: ActionMeta<PublishedDateFilter.Item>) => unknown;
};

export function PublishedDate({ defaultValue, value, onChange }: Props) {
  const theme = useTheme();

  const selectStyles = useMemo(() => Presentation.buildSelectStyles(theme), [theme]);
  const selectTheme = useMemo(() => Presentation.buildSelectTheme(theme), [theme]);

  const options: PublishedDateFilter.Item[] = useMemo(() => [
    { id: 'all-time',       label: 'All Time' },
    { id: 'last-30-days',   label: 'Last 30 Days' },
    { id: 'last-90-days',   label: 'Last 90 Days' },
    { id: 'last-6-months',  label: 'Last 6 Months' },
    { id: 'last-12-months', label: 'Last 12 Months' },
    { id: 'last-2-years',   label: 'Last 2 Years' },
    { id: 'last-5-years',   label: 'Last 5 Years' },
  ], []);

  const selected = useMemo(() => {
    if (value) {
      const now = new Date();
      const days = differenceInCalendarDays(now, value);
      if (days <= 30) {
        return options.find(o => o.id === 'last-30-days');
      } else if (days <= 90) {
        return options.find(o => o.id === 'last-90-days');
      }

      const months = differenceInCalendarMonths(now, value);
      if (months <= 6) {
        return options.find(o => o.id === 'last-6-months');
      } else if (months <= 12) {
        return options.find(o => o.id === 'last-12-months');
      }

      const years = differenceInCalendarYears(now, value);
      if (years <= 2) {
        return options.find(o => o.id === 'last-2-years');
      } else if (years <= 5) {
        return options.find(o => o.id === 'last-5-years');
      }
    }
    return options.find(o => o.id === 'all-time');
  }, [value, options]);

  const handleChange = useCallback((value: PublishedDateFilter.Item, meta: ActionMeta<PublishedDateFilter.Item>) => {
    switch (value?.id) {
      case 'last-30-days': return onChange(subDays(new Date(), 30), meta);
      case 'last-90-days': return onChange(subDays(new Date(), 90), meta);
      case 'last-6-months': return onChange(subMonths(new Date(), 6), meta);
      case 'last-12-months': return onChange(subMonths(new Date(), 12), meta);
      case 'last-2-years': return onChange(subYears(new Date(), 2), meta);
      case 'last-5-years': return onChange(subYears(new Date(), 5), meta);
      default: return onChange(defaultValue, meta);
    }
  }, [defaultValue, onChange]);

  return (
    <Presentation.Row>
      <Presentation.Label>Published Date</Presentation.Label>
      <Presentation.Value>
        <Select<PublishedDateFilter.Item>
          className={css({ maxWidth: '165px' })}
          styles={selectStyles}
          theme={selectTheme}
          options={options}
          value={selected}
          getOptionLabel={o => o.label}
          getOptionValue={o => `${o.id}`}
          isSearchable={false}
          onChange={handleChange}
          menuPlacement="auto" />
      </Presentation.Value>
    </Presentation.Row>
  );
}

