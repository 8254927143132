import { useContext, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { CallTranscriptContainer } from '@containers/Transcript';
import { useHasDataStewardRole, useIsInternalUser } from '@containers/Store/hooks';
import { CallTranscriptDetailsContext, TranscriptIdContext, TranscriptMediaContext } from '@containers/Transcript/context';
import { TranscriptLockContext } from '@containers/Transcript.Redaction/Context';
import { TranscriptPublishType } from '@enums';
import { cx } from '@utils';
import { TranscriptLocked } from '@screens/Transcript/TranscriptLocked';
import { TranscriptMedia as Media } from '@screens/Transcript/Transcript.Media';
import { TranscriptTabs } from '@screens/Transcript/TranscriptTabs';
import { TranscriptToolbar as Toolbar } from '@screens/Transcript/Transcript.Toolbar';
import { TranscriptScreenContainer } from '@screens/Transcript/Container';
import styles from '@screens/Transcript/style/Transcript.css';
import { CallTranscriptBreadcrumbs as Breadcrumbs } from './Breadcrumbs';
import { useCallTranscriptParams } from './hooks/useCallTranscriptParams';
import { useCallTranscriptTabs } from './hooks';
import { TranscriptHeader as Header } from './Header';

const CallTranscript = () => {

  const hasDataStewardRole = useHasDataStewardRole();
  const ctx = useContext(CallTranscriptDetailsContext);
  const transcriptId = useContext(TranscriptIdContext);
  const [isLocked] = useContext(TranscriptLockContext);
  const media = useContext(TranscriptMediaContext);
  const routes = useCallTranscriptTabs();

  const isInternalUser = useIsInternalUser();

  const access = useMemo(() => {
    return {
      recording: isInternalUser || canAccessRecording(ctx?.settings?.publishType),
    };
  }, [
    ctx?.settings?.publishType,
    isInternalUser,
  ]);

  const mediaClassName = cx({
    [styles.video]: media.isVideo === true,
    [styles.audio]: media.isVideo === false,
  });

  return (
    <>
      <Helmet title="Call Transcript" />

      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.main}>

            <div className={styles.toolbar}>
              <Breadcrumbs />

              <Toolbar />
            </div>

            <div className={mediaClassName}>
              <div className={styles.transcript}>
                <div className={styles.header}>
                  {!hasDataStewardRole && <Header />}

                  {access.recording && <Media />}

                  <div className={styles.tabview}>
                    {isLocked && <TranscriptLocked />}
                    {!isLocked && <TranscriptTabs routes={routes} />}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

const WithContainer = () => {
  const { callId } = useCallTranscriptParams();

  return (
    <CallTranscriptContainer callId={callId}>
      <TranscriptScreenContainer>
        <CallTranscript />
      </TranscriptScreenContainer>
    </CallTranscriptContainer>
  );
};

CallTranscript.displayName = 'Call.Transcript';

export { WithContainer as CallTranscript };

function canAccessRecording(typeId: TranscriptPublishType) {
  return typeId === TranscriptPublishType.RecordingAndTranscript
      || typeId === TranscriptPublishType.RecordingOnly;
}