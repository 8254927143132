import { useContext } from 'react';
import { TranscriptHydrationContext } from '@containers/Transcript/context';
import { RichText, SidebarContainer, Skeleton } from '@/components/Transcript';
import { FindReplaceContainer } from '@/components/Transcript/FindReplace.Container';
import * as Tool from './Tool';
import { Toolbar } from './Toolbar';
import styles from './style/Tab.Transcript.css';

type Props = unknown;

export const Transcript = (props: Props) => {
  const hydration = useContext(TranscriptHydrationContext);

  if (!(hydration.state.transcript && hydration.state.highlights)) {
    return (
      <Skeleton.Transcript rows={4} />
    );
  }

  return (
    <RichText.Container>
      <FindReplaceContainer>
        <Toolbar />
        <div className={styles.wrap}>
          <div className={styles.main}>
            <RichText.Editor />
          </div>
          <div className={styles.sidebar}>
            <SidebarContainer />
          </div>
        </div>
        <Tool.FindReplace.Pane />
        <Tool.Speakers.Pane />
      </FindReplaceContainer>
    </RichText.Container>
  );
};

Transcript.displayName = 'Transcript.Tab.Transcript';