import {
  command,
  extension,
  getTextSelection,
  ApplySchemaAttributes,
  CommandFunction,
  ExtensionTag,
  NodeExtension,
  NodeExtensionSpec,
  NodeSpecOverride,
} from '@remirror/core';
import { SurveyLogicExpression, SurveyLogicExpressionNode } from '@/types/survey.logic.expression';
import styles from './style/Expression.Extension.css';

@extension({
  defaultOptions: {},
})
export class OpeningParenthesisExtension extends NodeExtension {

  get name() {
    return SurveyLogicExpressionNode.OpeningParenthesis;
  }

  createTags() {
    return [ExtensionTag.InlineNode];
  }

  @command()
  insertOpeningParenthesis(): CommandFunction {
    return ({ tr, dispatch }) => {
      const node = this.type.create();
      const { from, to } = getTextSelection(tr.selection, tr.doc);

      dispatch?.(tr.replaceRangeWith(from, to, node));

      return true;
    };
  }

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    return {
      ...override,
      atom: true,
      attrs: extra.defaults(),
      draggable: false,
      inline: true,
      toDOM: node => ['span', {
        ...extra.dom(node),
        class: styles.parenthesis,
        contentEditable: 'false',
        // selectable: 'false',
      }, '('],
    };
  }
}

@extension({
  defaultOptions: {},
})
export class ClosingParenthesisExtension extends NodeExtension {

  get name() {
    return SurveyLogicExpressionNode.ClosingParenthesis;
  }

  createTags() {
    return [ExtensionTag.InlineNode];
  }

  @command()
  insertClosingParenthesis(): CommandFunction {
    return ({ tr, dispatch }) => {
      const node = this.type.create();
      const { from, to } = getTextSelection(tr.selection, tr.doc);

      dispatch?.(tr.replaceRangeWith(from, to, node));

      return true;
    };
  }

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    return {
      ...override,
      atom: true,
      attrs: extra.defaults(),
      inline: true,
      draggable: false,
      toDOM: node => ['span', {
        ...extra.dom(node),
        class: styles.parenthesis,
        contentEditable: 'false',
        // selectable: 'false',
      }, ')'],
    };
  }
}