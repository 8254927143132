import { useContext } from 'react';
import { format } from 'date-fns';
import { Skeleton } from '@mui/material';
import { CallTranscriptDetailsContext, TranscriptAccessContext, TranscriptIdContext, TranscriptMediaContext } from '@containers/Transcript/context';
import { TranscriptDownloadMenu } from '@screens/Transcript/DownloadMenu';
import styles from '@screens/Transcript/style/Header.css';
import { DiscoverInsights } from '@/components/GroupProject/DiscoverInsights';
import { useIsInternalUser } from '@/containers/Store/hooks';
import { Access } from './Header.Access';

export const TranscriptHeader = () => {
  const access = useContext(TranscriptAccessContext);
  const data = useContext(CallTranscriptDetailsContext);
  const transcriptId = useContext(TranscriptIdContext);
  const media = useContext(TranscriptMediaContext);

  const isInternalUser = useIsInternalUser();

  const has = {
    call: !!data?.call?.timeEnd,
    chat: !!access.features?.insightsChat,
    project: !!data?.project?.name,
    object: !!access.object?.id,
  };

  return (
    <>
      <div className={styles.row}>
        <div className={styles.field}>
          {!has.project
            ? <Skeleton
              className={styles.skel}
              width={200}
              height={30} />
            : <div className={styles.label}>Title:</div>}
          {!has.project
            ? <Skeleton width={250} height={30} />
            : <div className={styles.value}>{data.call.title || data.project.name}</div>}
        </div>
        <div className={styles.actions}>
          {!has.object && !isInternalUser && (
            <Skeleton
              height={30}
              width={50}
              variant="rounded" />
          )}
          {has.object && has.chat && !isInternalUser && (
            <DiscoverInsights className={styles.discover} />
          )}
          <Access loading={!has.object} />
          <TranscriptDownloadMenu
            callId={data?.call?.id}
            projectId={data?.project?.id}
            publishType={data?.settings?.publishType}
            transcriptId={transcriptId}
            source={{
              type: 'video',
              url: media.url,
            }} />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.field}>
          {!has.call
            ? <Skeleton
              className={styles.skel}
              width={200}
              height={30} />
            : <div className={styles.label}>Completed On:</div>}
          {!has.call
            ? <Skeleton width={250} height={30} />
            : <div className={styles.value}>{format(new Date(data.call.timeEnd), 'MMM do yyyy hh:mm aa')}</div>}
        </div>
      </div>
    </>
  );
};

TranscriptHeader.displayName = 'Call.Transcript.Header';