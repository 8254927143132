import { useParams } from 'react-router-dom';
import { useSelectAdHocCall } from '@containers/Store';

type Params = {
  callId: string;
};

export const useRequestCall = () => {
  const params = useParams<Params>();

  return useSelectAdHocCall(+params.callId);
};

export default useRequestCall;