import { useCallback, useContext } from 'react';
import { MedicalBackgroundContext, MedicalBackgroundIdentificationContext } from '@containers/MedicalOnboarding/Context';
import { cx } from '@utils';
import { DatePicker } from '@/components/MedicalProfile/DatePicker';
import styles from './style/Input.DeaNumber.css';

type Props = unknown;

export const ClinicalPracticeStartedOn = (props: Props) => {
  const ctx = useContext(MedicalBackgroundContext);
  const identification = useContext(MedicalBackgroundIdentificationContext);

  const handleChange = useCallback((value: Date) => {
    ctx.mutation.mutate({
      identification: {
        ...identification.form,
        clinicalPracticeStartedOn: value,
      },
    }, {
      onSuccess: identification.rehydrate,
    });

    identification.setValue({
      clinicalPracticeStartedOn: value,
    });
  }, [
    ctx.mutation,
    identification,
  ]);

  return (
    <DatePicker
      onChange={value => handleChange(value)}
      value={identification.form.clinicalPracticeStartedOn} />
  );
};

ClinicalPracticeStartedOn.displayName = 'Input.ClinicalPracticeStartedOn';