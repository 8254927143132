import { useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { pathname, querykey as QK } from '@consts';
import { MedicalExpertiseContext } from '@containers/MedicalOnboarding/Context';
import { Step, Steps, useMedicalOnboardingStepsSelector } from '@containers/MedicalOnboarding/Steps';
import { Stepper } from '@/components/Stepper';
import { useStepperContext } from '@/components/Stepper/hooks/useStepperContext';
import { MedicalOnboardingScreensContext } from './Context';
import { getPathFromStep, getStepFromPath } from './Steps';
import { MedicalOnboardingScreensBackgroundContainer } from './MedicalOnboarding.Screens.Background.Container';
import { useIsNonMedicalProfessional, useResetMedicalOnboardingQueries } from './hooks';

type Props = {
  children: React.ReactNode;
};

export const MedicalOnboardingScreensContainer = (props: Props) => {
  const resetQueries = useResetMedicalOnboardingQueries();

  useEffect(() => {

    return () => {
      resetQueries();
    };

  }, [resetQueries]);

  return (
    <Stepper>
      {/* <MedicalOnboardingScreensBackgroundContainer> */}
        <MedicalOnboardingScreensStateContainer>
          {props.children}
        </MedicalOnboardingScreensStateContainer>
      {/* </MedicalOnboardingScreensBackgroundContainer> */}
    </Stepper>
  );
};

MedicalOnboardingScreensContainer.displayName = 'MedicalOnboarding.Screens.Container';

const MedicalOnboardingScreensStateContainer = (props: Props) => {
  const stepper = useStepperContext();
  const isNonMedical = useIsNonMedicalProfessional();

  const ctx = useContext(MedicalExpertiseContext);

  const steps = useMedicalOnboardingStepsSelector(ctx.userId, steps => {
    if (isNonMedical) {
      return steps.filter(step => [Step.ExpertiseRole, Step.BackgroundIdentification].includes(step));
    } else {
      return steps;
    }
  });

  const lookup = useMemo(() => {
    return Steps.all.reduce((acc, name) => {
      return {
        ...acc,
        [name]: steps.includes(name),
      };
    }, {} as Record<Step, boolean>);

  }, [steps]);

  const state = useStepperState(steps);

  const value = {
    lookup,
    steps,
    stepper: {
      context: stepper,
      remaining: state.remaining,
      route: state.route,
    },
  };

  return (
    <MedicalOnboardingScreensContext.Provider value={value}>
      {props.children}
    </MedicalOnboardingScreensContext.Provider>
  );
};

MedicalOnboardingScreensStateContainer.displayName = 'MedicalOnboarding.Screens.StateContainer';

const useStepperState = (steps: Step[]) => {
  const location = useLocation();
  const stepper = useStepperContext();

  const state = useMemo(() => {
    const invalid = !steps.includes(getStepFromPath(location.pathname));

    const current = steps[stepper.index];

    const nextIndex = invalid
        ? 0
        : stepper.index + 1;
    const remaining = steps.slice(nextIndex);

    const next = remaining.length
        ? getPathFromStep(remaining[0])
        : pathname.Home;

    return {
      route: {
        name: current,
        next,
        path: getPathFromStep(current),
      },
      remaining,
    };

  }, [
    location.pathname,
    stepper.index,
    steps,
  ]);

  return state;
};