import { Personal } from '@/components/Calendar';
import styles from './style.css';

type Props = unknown;

const PersonalCalendar = (props: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <Personal />
        </div>
      </div>
    </div>
  );
};

export { PersonalCalendar };
export default PersonalCalendar;