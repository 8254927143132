import { useEffect } from 'react';
import { useSelectUser } from '@containers/Store';
import { useUpdateNewUser } from '@utils/api';

const useEmitUpdateNewUserFromReferrer = () => {
  const user = useSelectUser();
  const updateNewUser = useUpdateNewUser();

  useEffect(() => {

    if (user.meta.isNew) updateNewUser();
    /*eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

};

export { useEmitUpdateNewUserFromReferrer };
export default useEmitUpdateNewUserFromReferrer;