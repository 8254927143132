import { memo } from 'react';
import { SurveyQuestionConditionType } from '@enums';
import { Tooltip } from '@presentation/Tooltip';
import { toTitleCase } from '@utils';
import { getAlternateOperatorText, getOperatorText } from '@utils/operator';
import type { SurveyLogic, SurveyQuestion, SurveyQuestionMatrixRow } from '@/types';
import { useParseSurveyRichText } from '@/containers/SurveyBuilder';
import styles from './style/Preview.Condition.css';

type Props = {
  condition: SurveyLogic.QuestionConditionData;
  question: SurveyQuestion;
};

export const SurveyConditionText = memo(({ condition, question }: Props) => {

  const parseRichText = useParseSurveyRichText();

  switch (condition.type) {

    case SurveyQuestionConditionType.OptionRankedFirst:
    case SurveyQuestionConditionType.OptionRankedLast:
    case SurveyQuestionConditionType.OptionSelected:
    case SurveyQuestionConditionType.OptionNotSelected: {
      const option = question.options.find(f => f.base.identifier === condition.value.option.identifier);

      const optionValue = parseRichText(option.value);

      return <>{optionValue}</>;
    }

    case SurveyQuestionConditionType.OptionRankedTopX: {
      const option = question.options.find(f => f.base.identifier === condition.value.option.identifier);

      const optionValue = parseRichText(option.value);

      return <>{optionValue} ranked top {condition.value.ordinal}</>;
    }

    case SurveyQuestionConditionType.NumberOfOptionsSelected: {
      const operator = getOperatorText(condition.value.operator);
      return <>is {operator} {condition.value.comparate}</>;
    }

    case SurveyQuestionConditionType.MatrixValueChosen:
    case SurveyQuestionConditionType.MatrixValueNotChosen: {
      const row = (question.matrixRows as SurveyQuestionMatrixRow[]).find(f => f.base.identifier === condition.value.row.identifier);
      const option = question.options.find(f => f.base.identifier === condition.value.option.identifier);

      return (
        <>
          <ConditionTooltip
            text={`R${row.ordinal}`}
            label={parseRichText(row.value)} />
          /
          <ConditionTooltip
            text={`A${option.ordinal}`}
            label={parseRichText(option.value)} />
        </>
      );
    }

    case SurveyQuestionConditionType.NumberCellValue: {
      const row = (question.matrixRows as SurveyQuestionMatrixRow[]).find(f => f.base.identifier === condition.value.row.identifier);
      const option = question.options.find(f => f.base.identifier === condition.value.option.identifier);

      const operator = getOperatorText(condition.value.operator);

      return (
        <>
          <ConditionTooltip
            text={`R${row.ordinal}`}
            label={parseRichText(row.value)} />
          /
          <ConditionTooltip
            text={`A${option.ordinal}`}
            label={parseRichText(option.value)} />
          &nbsp;
          is {operator} {condition.value.comparate}
        </>
      );
    }

    case SurveyQuestionConditionType.SliderValue: {
      const row = (question.matrixRows as SurveyQuestionMatrixRow[]).find(f => f.base.identifier === condition.value.row.identifier);

      const operator = getOperatorText(condition.value.operator);

      return (
        <>
          <ConditionTooltip
            text={`R${row.ordinal}`}
            label={parseRichText(row.value)} />
          &nbsp;
          is {operator} {condition.value.comparate}
        </>
      );
    }

    case SurveyQuestionConditionType.SliderValuesTally: {

      const rowsOperatorText = getAlternateOperatorText(condition.value.rows.operator);
      const valueOperatorText = getOperatorText(condition.value.answer.operator);

      const text = `${toTitleCase(rowsOperatorText)} ${condition.value.rows.comparate} row(s) where answer is ${valueOperatorText} ${condition.value.answer.comparate}`;

      return (
        <>{text}</>
      );
    }

    case SurveyQuestionConditionType.Straightline:
      return (
        <>{condition.value.comparate ?? 'All Rows'}</>
      );

    default:
      throw new UnreachableCaseError(condition);
  }

});

type TooltipProps = {
  label: string;
  text: string;
};

const ConditionTooltip = ({ label, text }: TooltipProps) => (
  <Tooltip title={label}>
    <span className={styles.tooltip}>{text}</span>
  </Tooltip>
);