import { useMemo, useReducer } from 'react';
import { EditingToolPaneContext } from './context';

type Props = {
  children: React.ReactNode;
  enabled?: boolean;
};

export const EditingToolPaneContainer = ({ enabled = true, ...props }: Props) => {
  const [modal, dispatch] = useReducer(reducer, null);

  const state = useMemo(() => ({
    enabled,
    find: {
      active: `find` === modal,
      toggle: () => dispatch(`find`),
    },
    inuse: !!modal,
    reset: () => dispatch(null),
    speakers: {
      active: `speakers` === modal,
      toggle: () => dispatch(`speakers`),
    },
  }), [
    enabled,
    modal,
  ]);

  return (
    <EditingToolPaneContext.Provider value={state}>
      {props.children}
    </EditingToolPaneContext.Provider>
  );
};

EditingToolPaneContainer.displayName = 'EditingTool.Pane.Container';

type PaneType = `find` | `speakers`;

const reducer = (active: PaneType, key: PaneType) => {
  return active === key
    ? null
    : key;
};