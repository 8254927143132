import { useMemo } from 'react';
import { Topics } from '@/types';
import { useToggleFollowing } from './useToggleFollowing';

type Params = {
  data:     Topics.Following[];
  followed: FollowedItem[];
};

export const useCreateTopicsState = (params: Params) => {
  const handleToggleFollowing = useToggleFollowing();

  const items = useMemo(() => {
    return params.data.map(item => ({
      item,
      onToggleFollowing: () => handleToggleFollowing(item),
      following: !!params.followed.find(x => x.id === item.id),
    }));
  }, [
    params.followed,
    handleToggleFollowing,
    params.data,
  ]);

  return items;
};

type FollowedItem =
  & Pick<Topics.Following, 'id'>
  & Partial<Omit<Topics.Following, 'id'>>;