import { useContext, useMemo } from 'react';
import { ProjectClipsSortContext } from '@/containers/Project.Clips';

export const useIsColumnSorted = (column: string) => {
  const { sort, sortDesc } = useContext(ProjectClipsSortContext);

  const isColumnSorted = useMemo(() => {
    if (sort === column) {
      return sortDesc ? 'desc' : 'asc';
    }

    return false;
  }, [column, sort, sortDesc]);

  return isColumnSorted;
};