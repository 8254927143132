import { useMemo } from 'react';
import type { SurveyQuestionType } from '@/enums';
import {
  useSurveyFormQuestionContext,
  useValidateRationale,
} from './hooks';
import { SurveyFormCanSubmitContext, useSurveyFormQuestionAnswer } from './Context';
import { SurveyFormBackContainer, SurveyFormSubmitContainer } from './BaseNavigation';

type Props =
  ChildrenProps;

export const MatrixGridFormContainer = (props: Props) => {

  const [answer] = useSurveyFormQuestionAnswer<SurveyQuestionType.MatrixGrid>();
  const question = useSurveyFormQuestionContext<SurveyQuestionType.MatrixGrid>();

  const validateRationale = useValidateRationale();

  const canSubmit = useMemo(() => {

    const isRationaleValid = validateRationale();

    if (!isRationaleValid) return false;

    if (question.settings.groupBy === 'row') {
      const openEndedRows = question.matrixRows.filter(row => row.metadata.isOpenEnded);

      if (openEndedRows.length) {
        const filledOut = openEndedRows.every(row => {
          return !!answer.rowText[row.id];
        });
        if (!filledOut) {
          return false;
        }
      }

      const isFullyAnswered = question.matrixRows.every(row => {
        return question.options.some(option => {
          return answer.values[row.id][option.id];
        });
      });

      return isFullyAnswered;
    } else if (question.settings.groupBy === 'column') {
      return question.options.every(option => {
        return question.matrixRows.some(row => {
          return answer.values[row.id][option.id];
        });
      });
    }
  }, [answer, question, validateRationale]);

  return (
    <SurveyFormCanSubmitContext.Provider value={canSubmit}>
      <SurveyFormBackContainer>
        <SurveyFormSubmitContainer>
          {props.children}
        </SurveyFormSubmitContainer>
      </SurveyFormBackContainer>
    </SurveyFormCanSubmitContext.Provider>
  );
};

export default MatrixGridFormContainer;