import { useCallback } from 'react';
import { GrayOutlineButton } from '@presentation/Buttons';
import { useSubmitSurveyDraft } from '@/containers/SurveyBuilder';
import { Button } from '@/components/Button';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import ModalHeader from '@/components/Modal/Header';
import { useModal } from '@/components/Modal/hooks';
import { useCanSaveMedicalSpecialties, useTemplateMedicalSpecialties } from './hooks/medical-specialties';
import { TemplateMedicalSpecialtyInput } from './Input.MedicalSpecialties';
import styles from './style/Modal.css';

type Props =
  Pick<ModalProps,
    'open' |
    'onClose'>;

export const TemplateMedicalSpecialtiesModal = ({
  onClose,
  open,
}: Props) => {

  const {
    onSubmit,
    ...inputProps
  } = useTemplateMedicalSpecialties();

  const submitDraft = useSubmitSurveyDraft();

  const handleSubmit = useCallback(() => {

    onSubmit();
    submitDraft();
    onClose();
  }, [
    onSubmit,
    onClose,
    submitDraft,
  ]);

  const canSubmit = useCanSaveMedicalSpecialties(inputProps.value);

  return (
    <Modal
      open={open}
      onClose={onClose}>

      <ModalHeader text="Update Medical Specialties" />

      <div className={styles.body}>
        <TemplateMedicalSpecialtyInput
          {...inputProps} />
      </div>

      <div className={styles.footer}>
        <GrayOutlineButton
          onClick={onClose}
          title="Cancel" />
        <Button.Primary
          className={styles.submit}
          disabled={!canSubmit}
          onClick={handleSubmit}
          title="Submit"
          variant="brick" />
      </div>
    </Modal>
  );
};

export const useTemplateMedicalSpecialtiesModal = () => useModal(TemplateMedicalSpecialtiesModal);

export default TemplateMedicalSpecialtiesModal;