import extend from '@enums/extend';

export enum ChargeStatus {
  Draft = 1,
  Open = 2,
  Pending = 3,
  Paid = 4,
  Refunded = 5,
  Failed = 6,
  Void = 7,
}

const map = new Map([
  [ChargeStatus.Draft,    { name: 'Draft' }],
  [ChargeStatus.Open,     { name: 'Open' }],
  [ChargeStatus.Pending,  { name: 'Pending' }],
  [ChargeStatus.Paid,     { name: 'Paid' }],
  [ChargeStatus.Refunded, { name: 'Refunded'}],
  [ChargeStatus.Failed,   { name: 'Failed'}],
  [ChargeStatus.Void,     { name: 'Void'}],
]);

export default {
  ChargeStatus: extend(map),
};