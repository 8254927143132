import { useEffect, useContext, useMemo, useRef, useState } from 'react';
import { X } from 'react-feather';
import { TranscriptMediaContext, TranscriptMediaPlayerContext, SetTranscriptMediaPlayerContext } from '@containers/Transcript/context';
import { TranscriptCommentsRefPointContext } from '@containers/Transcript.Commenting/Context';
import { cx, useToggle, useOnScreen } from '@utils';
import { AnimatedLoader } from '@/components/ActivityIndicator/AnimatedLoader';
import { AudioPlayer } from '@/components/AudioPlayer';
import { VideoPlayer } from '@/components/VideoPlayer';
import styles from './style/Header.css';

export const TranscriptMedia = () => {
  const media = useContext(TranscriptMediaContext);

  const ready = useMemo(() => {
    return typeof media.isVideo === 'boolean';
  }, [media.isVideo]);

  const classnames = {
    media: cx(styles.media, {
      [styles.ready]: ready,
      [styles.sticky]: media.isVideo === false,
    }),
    player: cx(styles.player, {
      [styles.audio]: media.isVideo === false,
      [styles.video]: media.isVideo === true,
    }),
  };

  return (
    <div className={classnames.media}>
      <div className={classnames.player}>

        {!ready &&
          <div className={styles.loader}>
            <AnimatedLoader color="var(--sec-01)" />
          </div>}

        {(ready && !media.isVideo) &&
          <AudioPlayer audio={{ src: media.url }} />}

        {(ready && media.isVideo) &&
          <Video />}

      </div>
    </div>
  );
};

TranscriptMedia.displayName = 'Call.Transcript.Media';

const Video = () => {
  const containerRef = useRef<HTMLDivElement>();
  const ref = useContext(TranscriptMediaPlayerContext);
  const [ready, toggle] = useToggle();
  const onScreen = useOnScreen(containerRef.current);
  const { bounds } = useContext(TranscriptCommentsRefPointContext);
  const [overrideDockedVideo, setOverrideDockedVideo] = useState(false);
  const setMediaPlayerContext = useContext(SetTranscriptMediaPlayerContext);
  const media = useContext(TranscriptMediaContext);

  const classes = cx(styles.item, {
    [styles.in]: ready,
  });

  const dockVideo = useMemo(() => ready && !onScreen && !overrideDockedVideo, [ready, onScreen, overrideDockedVideo]);

  const videoStyles = useMemo<React.CSSProperties>(() => {
    if (dockVideo) {
      return {
        left: bounds.left,
      };
    } else {
      return null;
    }
  }, [bounds, dockVideo]);

  useEffect(() => {
    if (ref && !ready) {
      ref.addEventListener('loadeddata', toggle);
    }

    return () => {
      if (ref) {
        ref.removeEventListener('loadeddata', toggle);
      }
    };
  }, [
    ready,
    ref,
    toggle,
  ]);

  return (
    <div ref={containerRef} className={classes}>
      <div className={dockVideo ? styles.offScreen : null} style={videoStyles}>
        <VideoPlayer
          ref={setMediaPlayerContext}
          controls
          src={media.url}>
          {media.captionsUrl &&
            <track
              default
              kind="subtitles"
              src={media.captionsUrl} />}
        </VideoPlayer>
        <X className={styles.hideDockedVideo} onClick={() => setOverrideDockedVideo(true)} />
      </div>
    </div>
  );
};

Video.displayName = 'Transcript.Media.Video';