import { Helmet } from 'react-helmet';
import { useSettingsMenuItems } from './routes';
import { useIsDesktopSettings, useIsSettingsRoot } from './hooks';
import { SettingsMenu } from './Menu';
import { SettingsContent } from './Content';
import styles from './style/Settings.css';

export const Settings = () => {

  const items = useSettingsMenuItems();
  const isDesktop = useIsDesktopSettings();
  const isSettingsRoot = useIsSettingsRoot();

  return (
    <>
      <Helmet title="Settings" />
      <div className={styles.root}>
        <div className={styles.wrap}>
          {(isDesktop || isSettingsRoot) &&
            <SettingsMenu
              className={styles.menu}
              items={items} />
          }
          <div className={styles.contentWrapper}>
            <SettingsContent
              className={styles.content} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;