import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { path } from '@consts';
import { usePersistentProjectInvite } from '@containers/ProjectLinkInvitation/hooks/usePersistentProjectInvite';
import { ButtonActivityIndicator } from '@presentation';
import { cx } from '@utils';
import { Logo } from '@/components/Branding';
import { AuthButton, AuthForm } from '$website/components';
// import styles from '$website/components/AuthModal/style/AuthModal.css';
import { AuthContainer, SocialAuthContainer, PersistentLinkState } from '$website/containers';
import { useSignupSubmit, useSocialAuth } from '$website/containers/Auth/hooks';
import { SignupError } from './SignupError';
import styles from './style/SignUpNew.css';

const { Layout } = AuthForm;

type Props = unknown;

type TokenParams = {
  referral?: string;
};

const SignUpVisitor = (props: Props) => {
  return (
    <AuthContainer>
      <SocialAuthContainer>
        <Form />
      </SocialAuthContainer>
    </AuthContainer>
  );
};

function Form() {
  const [form, submit] = useSignupSubmit();
  const social = useSocialAuth();
  const urlstate = usePersistentProjectInvite();
  const state = urlstate.get();
  const tokenParams = useParams<TokenParams>();

  function handleForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    submit({ token: state?.token ?? tokenParams?.referral });
  }

  return (
    <div className={styles.bg}>
      <div className={styles.col}>
        <div className={styles.logo}>
          <Link to={`/`}>
            <Logo.White className={styles.logoImage} />
          </Link>
        </div>
        <div className={styles.form}>
          <Layout.Header>
            <Layout.Row className={styles.row}>
              <Layout.Title>Sign up</Layout.Title>
            </Layout.Row>

            <Layout.Row className={styles.row}>
              <Layout.Subtitle>
                <span>or </span>
                <Link
                  className={styles.link}
                  to={path.Website.Login}>
                  log in
                </Link>
              </Layout.Subtitle>
            </Layout.Row>
          </Layout.Header>
          <div className={styles.body}>
            {/* <Layout.Row className={styles.row}>
              <AuthButton.Google
                className={cx(styles.visitor, styles.btn)}
                onClick={social.authorizeGoogle}>
                Sign up with Google
              </AuthButton.Google>
            </Layout.Row> */}

            {/* <Layout.Row className={styles.row}>
              <AuthButton.Facebook
                className={cx(styles.visitor, styles.btn)}
                onClick={social.authorizeFacebook}>
                Sign up with Facebook
              </AuthButton.Facebook>
            </Layout.Row> */}

            {/* <Layout.Row className={styles.row}>
              <AuthButton.Apple
                className={cx(styles.visitor, styles.btn)}
                onClick={social.authorizeApple}>
                Sign up with Apple
              </AuthButton.Apple>
            </Layout.Row> */}

            {/* <Layout.Row className={styles.row}>
              <Layout.ThematicBreak>
                or
              </Layout.ThematicBreak>
            </Layout.Row> */}

            <form onSubmit={handleForm}>
              <AuthForm.Email.Signup className={cx(styles.visitor, styles.input)} />
              <div className={cx(styles.signup, styles.row)}>
                <SignupError className={styles.error} />
              </div>
              <Layout.Row className={styles.row}>
                <ButtonActivityIndicator
                  className={styles.submit}
                  loading={form.loading}>
                  Sign Up
                </ButtonActivityIndicator>
              </Layout.Row>
            </form>
          </div>
          <AuthForm.PrivacyPolicy />
        </div>
      </div>
    </div>
  );
}

export const Visitor = PersistentLinkState(SignUpVisitor, 'Signup.Visitor');