import { Component } from 'react';
import { cx } from '@utils';
import { Portal } from '@/components/Portal';
import { Body } from './Body';
import { Header } from './Header';
import { ButtonSet } from './ButtonSet';
import { ModalPropsLegacy } from './interfaces';
import styles from './style/Base.css';

type Props = {
  children?: React.ReactNode;
} & Omit<ModalPropsLegacy, 'children'>;

class Modal extends Component<Props> {
  static defaultProps = {
    blocking: false,
    className: '',
    onClose: () => {},
    visible: true,
    usePortal: true,
  }

  static get overflowHidden() {
    return window
      .getComputedStyle(document.body)
      .getPropertyValue('overflow-y') === 'hidden';
  }

  state = {
    hidden: false,
  }

  componentDidMount() {
    if (this.props.visible) {
      this.setOverflowHidden();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { visible } = this.props;

    if (prevProps.visible && !visible) {
      this.setOverflowVisible();
    } else if (!prevProps.visible && visible) {
      this.setOverflowHidden();
    }
  }

  componentWillUnmount() {
    if (Modal.overflowHidden) {
      this.setOverflowVisible();
    }
  }

  static displayName = 'Modal.Legacy';

  static Body = Body;
  static ButtonSet = ButtonSet;
  static Header = Header;

  setOverflowHidden = () => {
    if (Modal.overflowHidden) {
      this.setState({ hidden: true });
    } else {
      document.body.style.overflowY = 'hidden';
    }
  }

  setOverflowVisible = () => {
    if (!this.state.hidden) {
      document.body.style.overflowY = '';
    }

    this.setState({ hidden: false });
  }

  render() {
    if (!this.props.visible) return null;

    return (
      <Portal disablePortal={!this.props.usePortal}>
        <div className={styles.root}>
          <div className={cx(styles.wrap, this.props.className)}>
            <div className={styles.main}>
              {!this.props.blocking &&
                <div className={styles.closeContainer}>
                  <div
                    className={styles.close}
                    onClick={this.props.onClose}>
                    ✕
                  </div>
                </div>}
              {this.props.children}
            </div>
          </div>
          <div
            className={styles.overlay}
            onClick={this.props.blocking ? () => {} : this.props.onClose} />
        </div>
      </Portal>
    );
  }
}

export default Modal;
export type { ModalPropsLegacy };