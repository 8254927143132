import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import type { WorkspaceGlobalSearchBarResultTranscript } from '@/types';
import { cx } from '@utils';
import { TranscriptFileIcon } from '@/components/icons';
import ClickContainer from './Results.ClickContainer';
import styles from './style/Results.Transcript.css';

export default function ResultsTranscript({ className, index, item, highlighted }: Props) {
  return (
    <ClickContainer index={index} item={item}>
      <div className={cx(styles.root, { [styles.highlighted]: highlighted }, className)}>
        <TranscriptFileIcon
          className={styles.icon} />
        <div className={styles.details}>
          <div className={styles.name}>{item.name}</div>
          <div className={styles.type}>Transcript</div>
        </div>
        {highlighted && (
          <div className={styles.return}>
            <KeyboardReturnIcon />
          </div>
        )}
      </div>
    </ClickContainer>
  );
}

export { ResultsTranscript };

type Props = {
  className?: string;
  index: number;
  item: WorkspaceGlobalSearchBarResultTranscript;
  highlighted?: boolean;
};