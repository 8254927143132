import { Fragment } from 'react';
import type * as API from '@api/interfaces';
import * as Category from '@/screens/Discovery.Main/Category';
import * as Creator from '@/screens/Discovery.Main/Creator';
import styles from './style/DiscoveryTopic.css';

type Props = {
  video:    API.Discovery.FetchVideoTopicContent.Response;
  creators: API.Discovery.FetchVideoTopicCreators.Response;
};

export const TopicContent = (props: Props) => {

  return (
    <Fragment>
      <div className={styles.header}>{props.video.name}</div>
      <div className={styles.items}>
        {props.video.items.map(x =>
          <Category.Item
            className={styles.item}
            item={x}
            key={x.post.id} />)}
      </div>
      <Creator.Row
        name="Experts"
        items={props.creators.items} />
    </Fragment>
  );
};