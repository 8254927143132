import { memo } from 'react';
import { cx } from '@utils';
import styles from './style/AnalysesList.Item.css';

type PanelItemProps = {
  className?: string;
  onSelect: () => void;
  selected: boolean;
  name: string;
};

export const QuantAnalysesPanelItem = memo(({
  className,
  onSelect,
  selected,
  name,
}: PanelItemProps) => {

  const resolvedClassName = cx(styles.root, className, {
    [styles.selected]: selected,
  });

  return (
    <div className={resolvedClassName} onClick={onSelect}>
      <div className={styles.wrap}>
        <div className={styles.name}>{name}</div>
      </div>
    </div>
  );
});