import * as types from '@store/action-types';
import connectedReducer from '@store/reducers/connected-accounts';
import { user as initialState } from '@store/initial-state';
import {
  UserReducerActions,
} from '@store/interfaces';

type UserReducerState =
  Omit<Store.User,
  | 'connected'
  | 'meta'
  | 'profile'
  | 'state'
  | 'settings'>;

function userReducer(state: UserReducerState = initialState, action: UserReducerActions) {
  switch (action.type) {
    case types.APP_DATA_FETCHED:
      return { ...state, ...action.user };
    case types.APP_STATE_REHYDRATED:
      return { ...state, ...action.user };

    case types.USER_AUTHENTICATION_CHANGE:
    case types.USER_CHECK_FINISHED:
    case types.USER_INFO_CHANGE: {
      const { type, state: userState, ...payload } = action;
      return { ...state, ...payload };
    }

    default:
      return state;
  }
}

function settingsReducer(state: Store.User.Settings, action: UserReducerActions) {
  switch (action.type) {
    case types.APP_DATA_FETCHED:
      return { ...state, ...action.user.settings };

    case types.USER_INFO_CHANGE:
      return { ...state, ...action.settings };

    case types.USER_SETTINGS_UPDATED:
      return {
        ...state,
        ...action.settings,
      };

    default:
      return state;
  }
}

function stateReducer(state: Store.User.State, action: UserReducerActions) {
  switch (action.type) {
    case types.APP_DATA_FETCHED:
      return {
        ...state,
        ...action.user.state,
        fetching: false,
        initialized: true,
        wasActionLogout: undefined,
      };

    case types.USER_AUTHENTICATION_CHANGE:
      return {
        ...state,
        ...action.state,
        fetching: false,
      };

    case types.USER_CHECK_STARTED:
      return {
        ...state,
        error: undefined,
        wasActionLogout: false,
      };

    case types.USER_CHECK_FINISHED:
      return {
        ...action.state,
        fetching : false,
        initialized: !action.state.error ? true : false,
      };

    case types.USER_LOGOUT:
      return {
        ...state,
        authenticated: false,
        initialized: false,
        wasActionLogout: action.wasActionLogout,
      };

    default:
      return state;
  }
}

function metaReducer(state: Store.User.Meta, action: UserReducerActions) {
  switch (action.type) {
    case types.APP_DATA_FETCHED:
      return { ...state, ...(action.user.meta ?? {}) };

    case types.USER_INFO_CHANGE:
      return { ...state, ...(action.meta ?? {}) };

    default:
      return state;
  }
}

function profileReducer(state: Store.User.Profile, action: UserReducerActions) {
  switch (action.type) {
    case types.APP_DATA_FETCHED:
      return { ...state, ...action.user.profile };

    case types.USER_INFO_CHANGE: {
      const profile = action.profile
        ? action.profile
        : {};

      const hourlyRate = action.settings?.hourlyRate
        ? { hourlyRate: action.settings.hourlyRate }
        : {};

      return {
        ...state,
        ...profile,
        ...hourlyRate,
      };
    }

    default:
      return state;
  }
}

const user = (state: Store.User = initialState, action: UserReducerActions) => {
  const { connected, meta, profile, settings, state: auth, ...other } = state;

  return {
    ...userReducer(other, action),
    connected: connectedReducer(connected, action),
    meta: metaReducer(meta, action),
    profile: profileReducer(profile, action),
    settings: settingsReducer(settings, action),
    state: stateReducer(auth, action),
  };
};

export default user;