import cuid from 'cuid';
import { SurveyConditionType, SurveyQuestionConditionType } from '@enums';
import { SurveyQuota } from '@/types';

export function getDefaultBasicItem(): SurveyQuota.Basic.Item {
  return {
    conditionType: SurveyConditionType.Question,
    identifier: cuid(),
    data: {
      question: { identifier: '' },
      type: null,
      value: null,
    },
  };
}

export function getDefaultAdvancedSet(): SurveyQuota.Advanced.Set {
  return {
    identifier: cuid(),
    items: [],
    question: null,
  };
}

export function getDefaultAdvancedSetItem(): SurveyQuota.Advanced.SetItem {
  return {
    condition: {
      question: { identifier: '' },
      type: SurveyQuestionConditionType.OptionSelected,
      value: {
        option: null,
      },
    },
    identifier: cuid(),
    pct: 0,
  };
}