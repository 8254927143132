export * as actions from './actions';

const searchKeyFilter = ({ key, showRate }) => {
  if (!showRate && ['priceLow', 'priceHigh'].includes(key)) {
    return false;
  }

  return true;
}

export const getAdvancedSearchBody = ({ search, showRate }) => {
  return Object.keys(search)
               .filter(key => searchKeyFilter({ key, showRate }))
               .reduce((obj, key) => ({
                 ...obj,
                 [key]: search[key],
               }), {});
};