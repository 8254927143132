import { ArrowLeft, ArrowRight } from 'react-feather';
import type { HTMLProps } from 'react';
import { cx } from '@utils';
import { useTwilioVideo } from '@containers/Conference';
import { ParticipantTracks } from '@/components/Conference.Video';
import { usePagination, useGalleryViewLayout, useGalleryParticipants, useMainSpeaker } from './hooks';
import { GalleryParticipant } from './GalleryParticipant';
import { GALLERY_VIEW_ASPECT_RATIO, GALLERY_VIEW_MARGIN, MAX_PARTICIPANTS_PER_PAGE } from './constants';
import styles from './style/Session.ParticipantGallery.css';

export const ParticipantGallery = () => {
  const twilio = useTwilioVideo();

  const participants = useGalleryParticipants();

  const { paginatedParticipants, setCurrentPage, currentPage, totalPages } = usePagination(participants);

  const galleryViewLayoutParticipantCount =
    currentPage === 1 ? paginatedParticipants.length : MAX_PARTICIPANTS_PER_PAGE;

  const { participantVideoWidth, containerRef } = useGalleryViewLayout(galleryViewLayoutParticipantCount);

  const mainSpeaker = useMainSpeaker(twilio.room);

  const participantWidth = `${participantVideoWidth}px`;
  const participantHeight = `${Math.floor(participantVideoWidth * GALLERY_VIEW_ASPECT_RATIO)}px`;

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={cx(styles.buttonContainer, styles.buttonContainerLeft)}>
          {!(currentPage === 1) && (
            <IconButton onClick={() => setCurrentPage(page => page - 1)}>
              <ArrowLeft />
            </IconButton>
          )}
        </div>
        <div className={cx(styles.buttonContainer, styles.buttonContainerRight)}>
          {!(currentPage === totalPages) && (
            <IconButton onClick={() => setCurrentPage(page => page + 1)}>
              <ArrowRight />
            </IconButton>
          )}
        </div>
        <div className={styles.paginationContainer} />
        <div className={styles.participantContainer} ref={containerRef}>
          {paginatedParticipants.map(p => (
            <div key={p.sid} style={{ width: participantWidth, height: participantHeight, margin: GALLERY_VIEW_MARGIN }}>
              <GalleryParticipant
                participant={p}
                room={twilio.room}
                mainSpeakerSid={mainSpeaker?.sid} />
            </div>
          ))}
        </div>
        <div className={styles.hiddenParticipants}>
          {participants.filter(p => !paginatedParticipants.find(p2 => p2.identity === p.identity)).map(p => (
            <ParticipantTracks
              key={p.identity}
              participant={p}
              room={twilio.room}
              disableVideo={true} />
          ))}
        </div>
      </div>
    </div>
  );
};

type IconButtonProps = HTMLProps<HTMLDivElement> & ChildrenProps;
const IconButton = ({ children, className, ...props }: IconButtonProps) => {
  return (
    <div className={cx(className, styles.paginationButton)} {...props}>
      {children}
    </div>
  );
};