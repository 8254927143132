import { useContext, useState, useMemo, useCallback } from 'react';
import { useTranscriptTagsContext } from '@containers/Transcript.Tagging/hooks';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import type { ConferenceTag } from '@/types';
import type { TaggedMoment } from '@/types/transcribe.rich-text';
import { Header } from '@/screens/Transcript/Tool.Header';
import { TranscriptIdContext } from '@/containers/Transcript/context';
import { Button } from '@/components/Button';
import { useAlert } from '@/components/Modal/Alert';
import {
  useBuildTagContent,
  useSaveTaggedMoment,
  useDeleteTaggedMoment,
  useChainedCommands,
  useMapTimePosToDocPos,
  useSearchCallTags,
} from './hooks';
import styles from './style/Tags.EditPopper.css';
import { TagColorSelector } from './Tag.ColorSelector';
import { TimeSelector } from './Tag.TimeSelector';
import { assertIsGoodQuoteTag } from './utils';
import { CreateableTagSelect } from './Tag.Creatable';

type Props = {
  initialState: TaggedMoment;
  closePopper: () => void;
};

function buildSaveCompareJSON(tag: TaggedMoment) {
  return {
    name: tag.name,
    fromTs: tag.fromTs,
    toTs: tag.toTs,
    color: tag.color,
    id: tag.id,
  };
}

export const EditPopper = ({ initialState, closePopper }: Props) => {
  const [tag, setTag] = useState<TaggedMoment>(initialState);
  const transcriptId = useContext(TranscriptIdContext);
  const [_, dispatch] = useTranscriptTagsContext();
  const commands = useChainedCommands();
  const mapTimePosToDocPos = useMapTimePosToDocPos();

  const goodQuoteTagSelected = useMemo(() => {
    return assertIsGoodQuoteTag(tag);
  }, [tag]);

  const updateTag = useCallback((update: Partial<TaggedMoment>) => {
    setTag(t => ({ ...t, ...update }));

    if (!!update.fromTs || !!update.toTs || !!update.color) {
      const docPos = mapTimePosToDocPos({ fromTs: update.fromTs ?? tag.fromTs, toTs: update.toTs ?? tag.toTs });
      if (docPos) {
        commands.updateHighlight({
          id: tag.identifier,
          ...docPos,
          color: update.color ?? tag.color,
        }).run();
      }
    }
  }, [commands, mapTimePosToDocPos, tag]);

  const updateSelectedTag = useCallback(({ id, ...tag }: ConferenceTag) => {
    setTag(t => ({
      ...t, ...tag, tagId: id,
    }));
  }, []);

  const discardChanges = useCallback(() => {
    if (!initialState.id) {
      dispatch({ type: 'tag-removed', highlightIdentifier: initialState.identifier });
      commands.blurHighlight().removeHighlights([initialState.identifier]).run();
    }

    closePopper();
  }, [closePopper, initialState, dispatch, commands]);

  const { mutate: mutateSaveTaggedMoment, isLoading: isSaving } = useSaveTaggedMoment({ transcriptId });

  const buildTagContent = useBuildTagContent();
  const saveTaggedMoment = useCallback(() => {
    const content = buildTagContent(tag.fromTs, tag.toTs);
    mutateSaveTaggedMoment({
      momentId: tag.id,
      fromTs: tag.fromTs,
      toTs: tag.toTs,
      tag: {
        ...tag,
        id: tag.tagId,
      },
      transcriptId,
      highlightIdentifier: tag.identifier,
      content,
    }, {
      onSuccess: () => {
        closePopper();
      },
    });
  }, [mutateSaveTaggedMoment, tag, transcriptId, closePopper, buildTagContent]);

  const { mutate: mutateDeleteTaggedMoment, isLoading: isDeleting } = useDeleteTaggedMoment({ transcriptId });

  const deleteTaggedMoment = useCallback(() => {
    mutateDeleteTaggedMoment({
      momentId: tag.id,
      highlightIdentifier: tag.identifier,
    }, {
      onSuccess: closePopper,
    });
  }, [mutateDeleteTaggedMoment, tag, closePopper]);

  const [toggleDeleteAlert, DeleteAlert] = useAlert();

  const isValid = useMemo(() => {
    return tag.fromTs < tag.toTs
      && (tag.id || tag.name?.length > 0);
  }, [tag]);

  const areThereChanges = useMemo(() => {
    return JSON.stringify(buildSaveCompareJSON(tag)) !== JSON.stringify(buildSaveCompareJSON(initialState));
  }, [tag, initialState]);

  const handleTagChange = useCallback((tag: ConferenceTag) => {
    updateSelectedTag(tag);
  }, [updateSelectedTag]);

  const handleFromChange = useCallback((ts: number) => {
    updateTag({ fromTs: ts });
  }, [updateTag]);

  const handleToChange = useCallback((ts: number) => {
    updateTag({ toTs: ts });
  }, [updateTag]);

  const handleColorChange = useCallback((color: string) => {
    updateTag({ color });
  }, [updateTag]);

  const tagsQuery = useSearchCallTags({ transcriptId });

  return (
    <div className={styles.root}>
      <Header title={`${tag.id ? 'Edit' : 'Create'} Tag`} onClose={discardChanges} />
      <div className={styles.main}>
        <div className={styles.row}>
          <CreateableTagSelect
            isLoading={tagsQuery.isLoading}
            isDisabled={tagsQuery.isLoading || tagsQuery.isError}
            options={tagsQuery.data ?? []}
            value={tag}
            onChange={handleTagChange} />
        </div>
        <div className={styles.row}>
          <div className={styles.label}>Start</div>
          <TimeSelector
            value={tag.fromTs}
            isValid={val => val < tag.toTs}
            onChange={handleFromChange} />
        </div>
        <div className={styles.row}>
          <div className={styles.label}>End</div>
          <TimeSelector
            value={tag.toTs}
            isValid={val => val > tag.fromTs}
            onChange={handleToChange} />
        </div>
        <div className={styles.row}>
          <TagColorSelector
            disabled={goodQuoteTagSelected}
            color={tag.color}
            onChange={handleColorChange} />
        </div>
        <div className={styles.row}>
          {tag.id &&
            <ButtonActivityIndicator
              variant='brick'
              color='destructive'
              size='small'
              implicitDisable={false}
              loading={isDeleting}
              onClick={() => toggleDeleteAlert()}>
              Delete
            </ButtonActivityIndicator>
          }
          {!tag.id &&
            <Button
              variant='brick'
              color='destructive'
              size='small'
              onClick={discardChanges}>
              Discard
            </Button>
          }
          <ButtonActivityIndicator
            variant='brick'
            size='small'
            implicitDisable={false}
            disabled={!isValid || !areThereChanges}
            loading={isSaving}
            onClick={saveTaggedMoment}>
            Save
          </ButtonActivityIndicator>
        </div>
      </div>
      <DeleteAlert onConfirm={deleteTaggedMoment} message='Are you sure you want to delete this tag?' />
    </div>
  );
};