import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  useFetchIntegrations,
} from '@utils/api';
import * as selectors from '@store/selectors';
import { useDisconnectUserAccount } from '@utils/api';
import * as actions from '@actions';
import { Zoom } from '@/components/icons';
import { useAlert } from '@/components/Modal/Alert';
import { Integration } from '@/components/Settings';
import { useConnectZoom } from '@/components/ConnectAccount/hooks';
import Toast from '@/components/Toast';
import type { ConnectedAccount } from '@/types';
import styles from './style/Settings.Integrations.css';

export const ZoomIntegration = () => {

  const connect = useConnectZoom();
  const [toggleAlert, Alert] = useAlert();
  const dispatch = useDispatch();
  const connectedAccounts = useSelector(selectConnectedAccounts);

  const connected = useSelector(selectors.selectZoomFiles);

  const {
    data,
    isLoading,
    refetch,
  } = useFetchIntegrations({
    refetchOnMount: false,
  });

  const { mutateAsync: disconnect } = useDisconnectUserAccount({
    onSuccess: _ => {
      toggleAlert();
    },
  });

  const handleDisconnect = useCallback(() => {
    if (!connected) return;
    return disconnect({
      id: connected.id,
    });
  }, [connected, disconnect]);

  const onConnect = useCallback((conn: ConnectedAccount) => {
    refetch();
    Toast.success({
      title: 'You\'ve successfully connected your Zoom account!',
    });
    dispatch(actions.connectedAccountsChanged({
      connected: connectedAccounts.concat(conn),
    }));
  }, [refetch, dispatch, connectedAccounts]);

  return (
    <>
      <Integration
        className={styles.item}
        icon={Zoom}
        title={title}
        description={description}
        connected={!!connected}
        disabled={false}
        loading={isLoading}
        onConnect={() => connect({ onConnect })}
        onDisconnect={toggleAlert} />
      <Alert
        onConfirm={handleDisconnect}
        message="Are you sure you want to disconnect your Zoom account?" />
    </>
  );
};

const selectConnectedAccounts = (state: Store.State) => state.user.connected;

const title = `Zoom Recordings Sync`;
const description = `Easily upload your Zoom recordings to your Sentiment workspace.`;

export default ZoomIntegration;