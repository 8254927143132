import { useMemo } from 'react';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder/hooks';
import { useQuestionConditionContext } from '../context';
import { getConditionsForQuestionType } from '../utils.condition';

export const useConditionQuestion = () => {
  const { item } = useQuestionConditionContext();
  const [state] = useSurveyBuilderState();

  return useMemo(() => {
    return state.survey.questions.find(f => f.base.identifier === item.data.question.identifier);
  }, [item.data.question.identifier, state.survey.questions]);
};

export const useAvailableConditionTypes = () => {
  const question = useConditionQuestion();

  const conditionTypes = useMemo(() => {
    if (!question) return [];
    return getConditionsForQuestionType(question.typeId);
  }, [question]);

  return conditionTypes;
};