import { useMemo, useState, useCallback } from 'react';
import cuid from 'cuid';
import type { SurveyQuestionType } from '@enums';
import { SurveyOptionType } from '@enums';
import { useUploadImageObjectsMutation } from '@/components/Survey.RichText';
import { useSubmitAnswer, useSurveyFormQuestionContext, useValidateRationale } from './hooks';
import type { ImageMarkupEditorValue, SubmitOverrides } from './Context';
import { SurveyFormCanSubmitContext, useSurveyFormQuestionAnswer, ImageMarkupEditorContext, SurveyFormItemSubmitContext, useImageMarkupEditorContext } from './Context';
import { SurveyFormBackContainer, SurveyFormSubmitContainer } from './BaseNavigation';

type Props =
  ChildrenProps;

export const ImageMarkupFormContainer = (props: Props) => {
  const [answer] = useSurveyFormQuestionAnswer<SurveyQuestionType.ImageMarkup>();
  const question = useSurveyFormQuestionContext<SurveyQuestionType.ImageMarkup>();
  const [editor, setEditor] = useState<ImageMarkupEditorValue>(null);

  const canSubmit = useMemo(() => {
    //Do we need to require any markup at all? Maybe a timed delay?
    return true;
  }, []);

  return (
    <ImageMarkupEditorContext.Provider value={{ editor, setEditor }}>
      <SurveyFormCanSubmitContext.Provider value={canSubmit}>
        <SurveyFormBackContainer>
          <ImageMarkupSubmitContainer>
            {props.children}
          </ImageMarkupSubmitContainer>
        </SurveyFormBackContainer>
      </SurveyFormCanSubmitContext.Provider>
    </ImageMarkupEditorContext.Provider>
  );
};

const ImageMarkupSubmitContainer = (props: ChildrenProps) => {
  const { editor } = useImageMarkupEditorContext();
  const [answer, setAnswer] = useSurveyFormQuestionAnswer<SurveyQuestionType.ImageMarkup>();

  const uploadImagesMutation = useUploadImageObjectsMutation();

  const uploadMarkupImage = useCallback(async () => {
    const uploaded = await uploadImagesMutation.mutateAsync({
      images: [
        {
          src: editor.toDataURL(),
          cid: cuid(),
        },
      ],
    });

    const newAnswer = {
      ...answer,
      markedUpImage: uploaded[0].src,
    };

    setAnswer(newAnswer);

    return newAnswer;
  }, [uploadImagesMutation, editor, setAnswer, answer]);

  const submitAnswerMutation = useSubmitAnswer();

  const handler = useCallback(async (options: SubmitOverrides = {}) => {
    const answer = await uploadMarkupImage();
    await submitAnswerMutation.mutateAsync({
      ...options,
      answer,
    });
  }, [uploadMarkupImage, submitAnswerMutation]);

  return (
    <SurveyFormItemSubmitContext.Provider value={{ handler, isLoading: submitAnswerMutation.isLoading || uploadImagesMutation.isLoading, isError: submitAnswerMutation.isError || uploadImagesMutation.isError }}>
      {props.children}
    </SurveyFormItemSubmitContext.Provider>
  );
};

export default ImageMarkupFormContainer;