import { useCallback, useEffect, useState } from 'react';

type Params = React.RefObject<HTMLVideoElement>;

const useVideoPlayback = (ref: Params) => {
  const [paused, setPaused] = useState(false);

  const handlePaused = useCallback(() => setPaused(true), []);
  const handleUnpaused = useCallback(() => setPaused(false), []);
  const handlePlaying = useCallback(e => setPaused(false), []);

  useEffect(() => {
    const player = ref.current;

    if (!player) return;

    player.addEventListener('loadeddata', handlePaused);
    player.addEventListener('pause', handlePaused);
    player.addEventListener('play', handleUnpaused);
    player.addEventListener('playing', handlePlaying);

    return () => {
      player.removeEventListener('loadeddata', handlePaused);
      player.removeEventListener('pause', handlePaused);
      player.removeEventListener('play', handleUnpaused);
      player.removeEventListener('playing', handlePlaying);
    };
  }, [
    handlePlaying,
    handlePaused,
    handleUnpaused,
    ref,
  ]);

  return !paused;
};

export { useVideoPlayback };
export default useVideoPlayback;