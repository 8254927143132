import { KeyboardListener } from './Containers.KeyboardListener';
import { Query } from './Containers.Query';
import { Reset } from './Containers.Reset';
import { Results } from './Containers.Results';

export default function Containers({ children }: ChildrenProps) {
  return (
    <Query>
      <Results>
        <KeyboardListener>
          <Reset>
            {children}
          </Reset>
        </KeyboardListener>
      </Results>
    </Query>
  );
}