import axios, { AxiosHeaders } from 'axios';
import * as $session from '@services/auth/session';
import { nullableDate } from '@utils';
import type { SessionHttp } from './interfaces';

const xhr = axios.create({
  baseURL: process.env.BACKEND_BASE_URL,
  withCredentials: true,
});

xhr.interceptors.response.use(
  res => {
    if (process.env.__DEV__) {
      if (res.headers instanceof AxiosHeaders) {
        const xsid = res.headers.get('X-SID') as string;
        $session.setXSID(xsid);
      }
    }
    return res;
  }
);

xhr.interceptors.request.use(
  config => {
    if (process.env.__DEV__) {
      const xsid = $session.getXSID();
      if (xsid) {
        config.headers.set('X-SID', xsid);
      }
    }
    return config;
  },
);

// switch app session to an authenticated vancery session/cookie after getting the JWT from Auth0
export function jwt({ token }: SessionHttp.JWT.Params): Promise<SessionHttp.JWT.Response> {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  // this request will set secure cookie and return, in response body, non-secret details about session
  return xhr.post<SessionHttp.JWT.Response<string>>(`/auth/session`, {}, { headers })
    .then(({ data }) => ({
      authenticated: data.authenticated,
      csrf: data.csrf,
      expiry: nullableDate(data.expiry),
      userId: data.userId,
    }));
}

export function oauth2(data: SessionHttp.OAuth2.Params): Promise<SessionHttp.OAuth2.Response> {
  return xhr.post<SessionHttp.OAuth2.Response<string>>(`/auth/oauth2`, data)
    .then(({ data }) => ({
      authenticated: data.authenticated,
      csrf: data.csrf,
      expiry: nullableDate(data.expiry),
      userId: data.userId,
      returnUrl: data.returnUrl,
      endSessionUrl: data.endSessionUrl,
    }));
}

export function getSessionInfo(): Promise<SessionHttp.GetSessionInfo.Response> {
  return xhr.get<SessionHttp.GetSessionInfo.Response<string>>(`/auth/session`)
    .then(({ data }) => ({
      authenticated: data.authenticated,
      expiry: nullableDate(data.expiry),
      userId: data.userId,
    }));
}