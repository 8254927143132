import { useCallback, useContext } from 'react';
import { format } from 'date-fns/fp';
import { CallStatus, utils } from '@enums';
import { ButtonOutlined, Skip } from '@presentation';
import { getCurrentTimezone, getLocationFor } from '@utils';
import { Button } from '@/components/Button';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { FrameContext, ParamsContext, UnconfirmedCallsContext } from './Context';
import { FrameKey } from './interfaces';
import styles from './style/UnconfirmedCalls.css';

type Props = unknown;

export const UnconfirmedCalls = (props: Props) => {
  const ctx = {
    frame: useContext(FrameContext),
    scheduling: useContext(ParamsContext),
    unconfirmed: useContext(UnconfirmedCallsContext),
  };

  const getCallLocation = useCallback((callId: number) => {
    return getLocationFor.admin.call.details({ callId });
  }, []);

  const items = ctx.unconfirmed.data.items.filter(x => x.statusId === CallStatus.Rescheduling);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>

          <div className={styles.headers}>
            <div  className={styles.id}>
              <div className={styles.label}>ID</div>
            </div>
            <div  className={styles.date}>
              <div className={styles.label}>Date</div>
            </div>
            <div  className={styles.time}>
              <div className={styles.label}>Time</div>
            </div>
            <div  className={styles.status}>
              <div className={styles.label}>Status</div>
            </div>
            <div  className={styles.call} />
          </div>

          {items.map(x =>
            <div
              className={styles.fields}
              key={x.id}>
              <div  className={styles.id}>
                <Button
                  className={styles.link}
                  to={getCallLocation(x.id)}
                  variant="link">
                  {x.id}
                </Button>
              </div>

              <div  className={styles.date}>
                <div className={styles.field}>{formatDate(x.timeStart)}</div>
              </div>

              <div  className={styles.time}>
                <div className={styles.field}>{formatTime(x)}</div>
              </div>

              <div  className={styles.status}>
                <div className={styles.field}>{utils.CallStatus.getName(x.statusId)}</div>
              </div>

              <div className={styles.call}>
                <Skip
                  className={styles.skip}
                  to={getCallLocation(x.id)}>
                  Jump to Call
                </Skip>
              </div>
            </div>)}

        </div>
        <ButtonSet className={styles.footer}>
          <ButtonOutlined
            className={styles.btn}
            color="silver"
            onClick={ctx.scheduling.resetContext}>
            Cancel
          </ButtonOutlined>
          <Button
            color="primary"
            className={styles.btn}
            variant="brick"
            onClick={() => ctx.frame.goToFrame({ frame: FrameKey.ModeratorSchedulers })}>
            Create New
          </Button>
        </ButtonSet>
      </div>
    </div>
  );
};

UnconfirmedCalls.displayName = 'Frame.UnconfirmedCalls';

const formatTimeStart = format('h:mm');
const formatTimeEnd = format('h:mm a');
const formatDay = format('MM/d/yyyy');

const formatDate = (date: Date) => {
  return !date
    ? '-'
    : formatDay(date);
};

const formatTime = (data: FormatTimeParams) => {
  if (!data.timeStart) return '-';

  const start = formatTimeStart(data.timeStart);
  const end = formatTimeEnd(data.timeEnd);
  const tz = getCurrentTimezone();

  return `${start}-${end} ${tz}`;
};

type FormatTimeParams = {
  timeEnd:   Date;
  timeStart: Date;
};