import { useEnumerateCompanyEmployees } from '@utils/api';
import { CompanyCurrentEmployeesContext } from './Context';

type Props = {
  enabled: boolean;
  slug: string;
} & ChildrenProps;

export function CompanyCurrentEmployeesContainer({ enabled, children, slug }: Props) {
  const query = useEnumerateCompanyEmployees(slug, true, {
    getNextPageParam: lastPage => lastPage.next,
    refetchOnWindowFocus: false,
    enabled,
  });

  const value = {
    query,
    ready: !(query.status === 'loading'),
  };

  return (
    <CompanyCurrentEmployeesContext.Provider value={value}>
      {children}
    </CompanyCurrentEmployeesContext.Provider>
  );
}