import { RequiredEntitiesForScheduling } from '@/consts/conference';
import { useSelectUser } from '@/containers/Store/hooks';
import { ConferencePlatform, Permission } from '@/enums';
import type { ConferenceSettings } from '@/types';

type Props = {
  conferenceSettings: ConferenceSettings;
  entityCount: number;
};
export const useSchedulingRequiresBoosting = (props: Props) => {
  const user = useSelectUser();

  return user.permissions.includes(Permission.CallRequireBoosting) && props.entityCount < RequiredEntitiesForScheduling && props.conferenceSettings?.conferencePlatform === ConferencePlatform.Sentiment;
};