import { memo  } from 'react';
import { SurveyLogic } from '@/types';
import { SurveyConditionType } from '@enums';
import { ConditionErrorBoundary } from '@/components/SurveyBuilder.Logic';
import { ClassificationCondition } from './Logic.Condition.Classification';
import { SumCondition } from './Logic.Condition.Sum';
import { QuestionCondition } from './Logic.Condition.Question';

type Props = {
  condition: SurveyLogic.ItemCondition;
};

const Condition = memo(({ condition }: Props) => {
  switch (condition.conditionType) {
    case SurveyConditionType.Question:
      return <QuestionCondition condition={condition} />;

    case SurveyConditionType.AggregatedSum:
      return <SumCondition condition={condition} />;

    case SurveyConditionType.Classification:
      return <ClassificationCondition condition={condition} />;

    default:
      throw new UnreachableCaseError(condition);
  }
});

const ConditionWithErrorBoundary = (props: Props) => {

  return (
    <ConditionErrorBoundary condition={props.condition}>
      <Condition {...props} />
    </ConditionErrorBoundary>
  );
};

export { ConditionWithErrorBoundary as Condition };