import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Invitation } from './Invitation';
import { SchedulingProvidingComponentProps } from './interfaces';
import styles from './style/Responsive.css';

type Props =
    SchedulingProvidingComponentProps;

const InvitationResponsive = (props: Props) => {
  const history = useHistory();

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <button
            className={styles.back}
            onClick={() => history.goBack()}>
            <ArrowBackIcon className={styles.arrow} />
          </button>
          <div className={styles.title}>Provide Your Availability</div>
        </div>
        <Invitation
          className={styles.calendar}
          creatorId={props.creatorId}
          onSubmit={props.onSubmit}
          scheduleeId={props.scheduleeId}
          schedulerId={props.schedulerId}
          userId={props.userId} />
      </div>
    </div>
  );
};

export { InvitationResponsive };
export default InvitationResponsive;