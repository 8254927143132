import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { GroupProjectRouteParams } from '@containers/GroupProject/interfaces';

type ProjectIds = {
  projectId: number;
  projectParentId: number;
  slug: string;
};

export const useMatchProjectIdFromUrl = (): ProjectIds => {
  const params = useParams<GroupProjectRouteParams>();

  return useMemo(() => {
    const param = params.projectId || params.slug;
    const [id] = param.split('-').reverse();

    if (id.startsWith('P')) {
      return {
        projectId: null,
        projectParentId: Number(id.substring(1, id.length)),
        slug: param,
      };
    } else {
      return {
        projectId: +id,
        projectParentId: null,
        slug: param,
      };
    }
  }, [
    params.projectId,
    params.slug,
  ]);
};