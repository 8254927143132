import { useCallback, useContext, useMemo } from 'react';
import type { Column as RTColumn, TableOptions, UsePaginationInstanceProps, CellProps } from 'react-table';
import { useTable, useFlexLayout, usePagination } from 'react-table';
import { format } from 'date-fns';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import { StarRatingPreview } from '@/components/StarRating';
import { MaybeLink } from '@/components/MaybeLink';
import * as Table from '@/components/Table';
import { utils } from '@/enums';
import * as $utils from '@/utils';
import type { Member } from '$admin/interfaces';
import { Pagination } from '$admin/Project.Aggregate/Pagination';
import { ProfileProjectHistoryQueryContext } from './Context';
import styles from './style/Tab.Projects.css';

type Props = unknown;

export const Projects = (props: Props) => {
  const query = useContext(ProfileProjectHistoryQueryContext);

  const [qp, setQuery] = useQueryParams({
    index: withDefault(NumberParam, 0),
    size: withDefault(NumberParam, 25),
  });

  const pagination = {
    pageCount: query.data?.pagination?.pageCount ?? 0,
    totalCount: query.data?.pagination?.totalCount ?? 0,
  };

  const loading = query.isInitialLoading && !query.data;
  const data = query.data?.items ?? [];
  const empty = query.isFetchedAfterMount && !loading && !data.length;

  const getProjectUrl = useCallback((project: Member.Project.Item['project']) => {
    if (project.isExternal) return null;
    return $utils.getLocationFor.project.rootWithId(project);
  }, []);

  const columns = useMemo<RTColumn<Member.Project.Item>[]>(() => [
    {
      Cell: props => {
        return !props.row.original.project.code
          ? <>-</>
          : props.row.original.project.code;
      },
      Header: `Code`,
      accessor: `code`,
      id: `code`,
    },
    {
      Cell: props => {
        const to = getProjectUrl(props.row.original.project);
        return (
          <MaybeLink classes={{ link: styles.link }} to={to}>
            {props.row.original.project.name}
          </MaybeLink>
        );
      },
      Header: `Project`,
      accessor: `project`,
      id: `project`,
    },
    {
      Cell: (props: CellProps<Member.Project.Item>) => {
        return (
          <>
            {utils.UserProjectStatus.getName(props.row.original.pipeline.statusId)}
          </>
        );
      },
      Header: `Status`,
      accessor: x => x.pipeline.statusId,
      id: `status`,
      width: 80,
    },
    {
      Cell: (props: CellProps<Member.Project.Item>) => {
        return (
          <>
            {utils.ProjectType.getName(props.row.original.project.projectType)}
          </>
        );
      },
      Header: `Type`,
      accessor: x => x.project.projectType,
      id: `type`,
      width: 100,
    },
    {
      Cell: (props: CellProps<Member.Project.Item>) => {
        return (
          <>
            {format(new Date(props.row.original.pipeline.addedOn), 'M/d/yy')}
          </>
        );
      },
      Header: `Added`,
      accessor: x => x.pipeline.addedOn,
      id: `added`,
      width: 80,
    },
    {
      Cell: (props: CellProps<Member.Project.Item>) => {
        return !props.row.original.calls.avgCallRating
          ? <>-</>
          : <StarRatingPreview rating={props.row.original.calls.avgCallRating} />;
      },
      Header: `Rating`,
      accessor: x => x.calls.avgCallRating,
      id: `rating`,
      width: 100,
    },
  ], [getProjectUrl]);

  const params: TableOptions<Member.Project.Item> = {
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: 25,
    },
    manualFilters: true,
    manualPagination: true,
    pageCount: pagination.pageCount,
  };

  const {
    getTableBodyProps,
    headerGroups,
    pageOptions,
    prepareRow,
    rows,
    setPageSize,
  } = useTable<Member.Project.Item>(
    params,
    useFlexLayout,
    usePagination,
  );

  const nextPage = useCallback(() => {
    setQuery({ index: qp.index + 1 }, 'pushIn');
  }, [
    qp.index,
    setQuery,
  ]);

  const previousPage = useCallback(() => {
    const prevIndex = qp.index - 1;
    const index = prevIndex > 0
      ? prevIndex
      : undefined;
    setQuery({ index }, 'pushIn');
  }, [
    qp.index,
    setQuery,
  ]);

  const canPreviousPage = qp.index > 0;
  const canNextPage = qp.index + 1 < pagination.pageCount;

  const paginationProps: PaginationProps = {
    canNextPage,
    canPreviousPage,
    nextPage,
    pageCount: pagination.pageCount,
    pageIndex: qp.index,
    pageSize: qp.size,
    previousPage,
    pageOptions,
    setPageSize,
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <Table.Layout.Box>
            <Table.Root
              empty={empty}
              loading={loading}>
              <Table.Header
                classes={{
                  thead: styles.thead,
                  tr: styles.tr,
                }}
                headerGroups={headerGroups} />
              <Table.Body
                {...getTableBodyProps()}
                classes={{ td: styles.td }}
                prepareRow={prepareRow}
                rows={rows} />
              <Table.Layout.Footer>
                <Pagination {...paginationProps} />
              </Table.Layout.Footer>
            </Table.Root>
          </Table.Layout.Box>
        </div>
      </div>
    </div>
  );
};

Projects.displayName = 'Tab.Projects';

type PaginationProps = {
  pageIndex: number;
  pageSize: number;
  pageSizeOptions?: number[];
} & Pick<UsePaginationInstanceProps<Member.Project.Item>,
  | 'canNextPage'
  | 'canPreviousPage'
  | 'nextPage'
  | 'previousPage'
  | 'pageCount'
  | 'pageOptions'>
  & Partial<Pick<UsePaginationInstanceProps<Member.Project.Item>,
    | 'setPageSize'>>;