import { SurveyBuilder, SurveySavedBuilder } from '../interfaces';

export function savedSurveyReducer(state: SurveyBuilder.State, action: SurveyBuilder.Actions): SurveySavedBuilder.State {

  switch (action.type) {

    case 'reset-survey':
      return action.value;

    default:
      return state.savedSurvey;

  }
}