import type { DecodedValueMap, QueryParamConfig, SetQuery } from 'use-query-params';
import type { UseQueryResult } from '@tanstack/react-query';
import type * as API from '@api/interfaces';
import { createNamedContext } from '@utils';

type QPConfig = {
  index: QueryParamConfig<number, number>;
  size:  QueryParamConfig<number, number>;
};

type ProjectPipelineQueryParamsContextValue = [
  DecodedValueMap<QPConfig>,
  SetQuery<QPConfig>,
];

type ProjectPipelineQueryContextValue = UseQueryResult<API.Projects.FetchRespondents.Response>;
type ProjectPipelineStateContextValue = UseQueryResult<API.Groups.Projects.FetchRespondentPipelineState.Response>;

export const ProjectPipelineQueryContext = createNamedContext<ProjectPipelineQueryContextValue>(undefined, 'Project.Pipeline.Query.Context');
export const ProjectPipelineQueryParamsContext = createNamedContext<ProjectPipelineQueryParamsContextValue>(undefined, 'Project.Pipeline.QueryParams.Context');
export const ProjectPipelineStateContext = createNamedContext<ProjectPipelineStateContextValue>(undefined, 'Project.Pipeline.State.Context');