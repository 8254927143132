import { useCallback } from 'react';
import { NumericFormatProps } from 'react-number-format';
import { cx } from '@utils';
import { NumberInput, OnNumberInputValueChange } from '@/components/Input';
import styles from './style/Builder.Condition.Question.css';

type Props = {
  className?: string;
  onChange: (value: number) => void;
  value: number;
} & Omit<NumericFormatProps, 'value' | 'onValueChange' | 'onChange'>;

export const ComparateInput = ({ className, onChange, value, ...formatProps }: Props) => {

  const handleUpdate: OnNumberInputValueChange = useCallback(value => {
    onChange(value.floatValue);
  }, [onChange]);

  return (
    <div className={cx(styles.comparateInput, className)}>
      <NumberInput
        placeholder="-"
        allowLeadingZeros={false}
        {...formatProps}
        onValueChange={handleUpdate}
        value={value} />
    </div>
  );
};