import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import * as api from '@api/pipeline';
import { Pipeline } from '@api/interfaces';

type Props = IProjectId;

export const useFetchProjectInvite = ({ projectId }: Props, options?: Options) => {

  return useQuery(['project.invite', projectId], () => {
    return api.getProjectRequest({ projectId });
  }, options);

};

type Data = Pipeline.GetProjectRequest.Response;
type Error = AxiosResponse;
type QueryKey = readonly [string, number];
type Options = UseQueryOptions<Data, Error, Data, QueryKey>;