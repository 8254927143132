import type {
  PipedValue,
  SurveyRichText,
  SurveyTemplate,
} from '@/types/survey';
import { Remirror } from '@/types';
import { formatCurrencyLegacy, CURRENCY_FORMATTERS_LEGACY } from '@/utils';
import type { SurveyPipedQuestionValueType } from '@/enums';
import { SurveyPipedValueType } from '@/enums';

export function generateRichTextValue(content: SurveyRichText.ParagraphContent[]): SurveyRichText.RichTextValue {
  return {
    type: Remirror.NodeType.Doc,
    content: [generateParagraphNode(content)],
  };
}

export function generateRichTextNode(content: SurveyRichText.RootNodeContent[]): SurveyRichText.RichTextValue {
  return {
    type: Remirror.NodeType.Doc,
    content: content.filter(Boolean),
  };
}

export function generateParagraphNode(content: SurveyRichText.ParagraphContent[] = []): SurveyRichText.ParagraphNode {
  return {
    content,
    type: Remirror.NodeType.Paragraph,
  };
}

export function generateTextNode(text: string, marks?: SurveyRichText.TextMark[]): SurveyRichText.TextNode {
  return {
    marks,
    text,
    type: Remirror.NodeType.Text,
  };
}

export function generateBoldTextNode(text: string) {
  return generateTextNode(text, [{
    type: 'bold',
  }]);
}

export function generateItalicTextNode(text: string) {
  return generateTextNode(text, [{
    type: 'italic',
  }]);
}

export function generateListItemNode(data: Omit<SurveyRichText.ListItemNode, 'type'>): SurveyRichText.ListItemNode {
  return {
    attrs: data.attrs,
    content: data.content,
    type: Remirror.NodeType.ListItem,
  };
}

export function generateBulletListNode(items: SurveyRichText.ParagraphNode[]): SurveyRichText.BulletListNode {
  const content = items.map(m => generateListItemNode({
    attrs: {
      closed: false,
      nested: false,
    },
    content: [m],
  }));

  return {
    content,
    type: Remirror.NodeType.BulletList,
  };
}

export function generateLinkNode(data: GenerateLinkNode): SurveyRichText.TextNode {
  return {
    marks: [{
      attrs: {
        href: data.href,
      },
      type: 'link',
    }],
    text: data.text || data.href,
    type: Remirror.NodeType.Text,
  };
}

type GenerateLinkNode = {
  href: string;
  text?: string;
};

export function generateCategoryNode(category: SurveyTemplate.Category): SurveyRichText.CategoryNode {
  return {
    attrs: {
      category: category.value,
    },
    type: Remirror.NodeType.TemplateCategory,
  };
}

export function generateTemplateNode(attrs: SurveyRichText.TemplateValueNode['attrs'], marks?: SurveyRichText.TemplateValueNodeMark[]): SurveyRichText.TemplateValueNode {
  return {
    marks,
    attrs,
    type: Remirror.NodeType.TemplateNode,
  };
}

export function generateMedicalConditionNode(data: SurveyTemplate.LinkedEntity, marks?: SurveyRichText.TemplateValueNodeMark[]): SurveyRichText.TemplateValueNode {
  return generateTemplateNode({
    nodeType: 'medical-condition',
    value: data.value,
  }, marks);
}

export function generatePricingComparatorNode(data: SurveyTemplate.LinkedEntity, marks?: SurveyRichText.TemplateValueNodeMark[]): SurveyRichText.TemplateValueNode {
  return generateTemplateNode({
    id: data.id,
    nodeType: 'pricing-comparator',
    value: data.value,
  }, marks);
}

export function generateDrugNode(data: SurveyTemplate.LinkedEntity, marks?: SurveyRichText.TemplateValueNodeMark[]): SurveyRichText.TemplateValueNode {
  return generateTemplateNode({
    nodeType: 'drug',
    value: data.value,
  }, marks);
}

export function generateLaunchYearNode(value: number, marks?: SurveyRichText.TemplateValueNodeMark[]): SurveyRichText.TemplateValueNode {
  return generateTemplateNode({
    nodeType: 'launch-year',
    value: value.toString(),
  }, marks);
}

export function generateDrugPriceNode(data: SurveyTemplate.DrugPrice, key: keyof Omit<SurveyTemplate.DrugPrice, 'currency'>, marks?: SurveyRichText.TemplateValueNode['marks']) {
  return generateTemplateNode({
    nodeType: `${key}-drug-price`,
    value: formatCurrencyLegacy(data.high, CURRENCY_FORMATTERS_LEGACY[data.currency]),
  }, marks);
}

export function generateDataControllerNode(value: string) {
  return generateTemplateNode({
    nodeType: `client`,
    value,
  });
}

export function generateTargetNode(text: string): SurveyRichText.TargetNode {
  return {
    attrs: {
      target: text,
    },
    type: Remirror.NodeType.TemplateTarget,
  };
}

export function generateCompetitorNode(item: SurveyTemplate.Competitor): SurveyRichText.CompetitorNode {
  return {
    attrs: {
      id: item.id,
      competitor: item.value,
    },
    type: Remirror.NodeType.TemplateCompetitor,
  };
}

export function generatePipedQuestionResponseNode<T extends SurveyPipedQuestionValueType = SurveyPipedQuestionValueType>(data: GeneratePipedResponseNode<T>): SurveyRichText.PipedResponseNode {
  return {
    attrs: {
      value: data.pipedValue,
      question: {
        identifier: data.questionIdentifier,
      },
    },
    type: Remirror.NodeType.PipedResponse,
  };
}

type GeneratePipedResponseNode<T extends SurveyPipedQuestionValueType = SurveyPipedQuestionValueType> = {
  pipedValue: PipedValue.Value<T>;
  questionIdentifier: string;
};

export function generateLegacyPipedText({ questionOrdinal, sectionOrdinal, type = 'selected' }: GenerateLegacyPipedText) {
  return `\${Q${questionOrdinal}:${type}}`;
}

type GenerateLegacyPipedText = {
  questionOrdinal: number;
  sectionOrdinal?: number;
  type?: 'selected';
};

export function generateViewStimulusNode(): SurveyRichText.ViewStimulusNode {
  return {
    type: Remirror.NodeType.ViewStimulus,
  };
}

export function generateViewTPPNode(): SurveyRichText.ViewTPPNode {
  return {
    type: Remirror.NodeType.ViewTPP,
  };
}

export function generateTPPNode(): SurveyRichText.TPPNode {
  return {
    type: Remirror.NodeType.TPP,
  };
}

export function generateViewTPP_RTNode(): SurveyRichText.ViewTPP_RTNode {
  return {
    type: Remirror.NodeType.ViewTPP_RT,
  };
}