type Props = {
  className?: string;
  size?:      number;
};

export const PaintRoller = ({ size = 24, ...props }: Props) => {
  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 21 27"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round">
        <g
          transform="translate(-34.000000, -585.000000)"
          stroke="currentcolor"
          strokeWidth="2">
          <path d="M35.6718643,586.088771 L53.3046753,586.088771 L53.3046753,590.072646 L35.6718643,590.072646 L35.6718643,595.673508 L42.4496701,599.356586 L42.4496701,610.085829 C43.087727,610.695276 43.7257839,611 44.3638409,611 C45.0018978,611 45.6399547,610.695276 46.2780116,610.085829 L46.2780116,599.356586 L52.9066902,595.673508" />
        </g>
      </g>
    </svg>
  );
};