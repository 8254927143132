import { useContext, useMemo } from 'react';
import { ProjectStatus } from '@enums';
import { ProjectSurveySettingsContext } from '../context';

export const useCanEditSurveySettings = () => {

  const ctx = useContext(ProjectSurveySettingsContext);

  return useMemo(() => {
    return ctx.capabilities.canManage && [
      ProjectStatus.Active,
      ProjectStatus.Draft,
    ].includes(ctx.project.projectStatusId);
  }, [ctx.capabilities.canManage, ctx.project.projectStatusId]);

};