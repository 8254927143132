import { useState, useCallback } from 'react';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { useCoordinator, useConferenceInstance } from '@containers/Conference';
import { Participant as LiveParticipant } from '@/types/conferences.live';
import { ConditionalTooltip } from '@presentation/Tooltip';
import { Input } from '@/components/Input';

type Props = {
  participant: LiveParticipant;
  displayName?: string;
};

const EditableParticipantName = ({ participant, displayName = participant.name }: Props) => {
  const [isEditing, changeEditing] = useState(false);
  const { updateParticipantName } = useCoordinator();
  const conference = useConferenceInstance();
  const changeName = useCallback((name: string) => {
    if (name && name != participant.name) {
      updateParticipantName({
        conferenceIdentifier: conference.conferenceIdentifier,
        pid: participant.id,
        name,
      });
    }
    changeEditing(false);
  }, [changeEditing, updateParticipantName, conference.conferenceIdentifier, participant.id, participant.name]);

  if (!isEditing) {
    return (
      <span
        onClick={() => changeEditing(true)}
        style={{ cursor: 'pointer' }}>
        {displayName}
      </span>
    );
  } else {
    return (
      <EditingParticipant participant={participant} onEditFinish={changeName} />
    );
  }
};

type EditingProps = Props & {
  onEditFinish: (name: string) => void;
};

const EditingParticipant = ({ participant, onEditFinish }: EditingProps) => {
  const [newName, setNewName] = useState(participant.name);

  return (
    <ClickAwayListener onClickAway={() => onEditFinish(newName)}>
      <Input value={newName} onChange={e => setNewName(e.target.value)} />
    </ClickAwayListener>
  );
};

export const ModeratedParticipantName = ({ participant, displayName }: Props) => {
  return (
    <ConditionalTooltip
      title={`This name is only visible to moderators. Attendees and other respondents will see "${participant.name}"`}
      placement='right'
      enabled={participant.name !== participant.originalName}>
      <span>
        <EditableParticipantName participant={participant} displayName={displayName} />
      </span>
    </ConditionalTooltip>
  );
};