import { memo, useCallback, useMemo } from 'react';
import { Role } from '@enums';
import { useSelectUser } from '@containers/Store';
import { cx } from '@utils';
import { useCoordinator, useConferenceInstance } from '@containers/Conference/hooks';
import { Coordinator } from '@/containers/Conference/interfaces';
import { Input } from '@/components/Input';
import styles from './style/Nametag.css';

type Props = {
  className?: string;
  disabled: boolean;
};

export const Nametag = memo(({ className, disabled }: Props) => {
  const { state, updateParticipantName } = useCoordinator();
  const instance = useConferenceInstance<Coordinator.Conference.PreRoom>();

  const me = useSelectUser();
  const defaultName = useMemo(() => {
    return instance.participant?.name ?? me.profile.firstName;
  }, [me, instance.participant]);

  const saveNameInput = useCallback((name: string) => {
    if (state.conference.status === 'pre-room' && name) {
      updateParticipantName({
        conferenceIdentifier: state.conference.conferenceIdentifier,
        pid: state.conference.pid,
        name,
      });
    }
  }, [state, updateParticipantName]);

  return (
    <div className={cx(className)}>
      <div className={styles.header}>
        <div className={styles.optionLabel}>Nametag</div>
      </div>
      <div>
        <Input
          defaultValue={defaultName}
          disabled={disabled}
          onBlur={e => saveNameInput(e.target.value)} />
      </div>
    </div>
  );
});