import type { SurveyQuestionType } from '@enums';
import type { ImageMarkupQuestion } from '@/types';
import type { Validation } from '../interfaces';
import { assertHasValue, returnAssertFailure } from './validation.base';

export const DefaultSettings: ImageMarkupQuestion.Settings = {
  initialImage: '',
};

export function assertValidSettings({ settings }: ImageMarkupQuestion.Question): Validation.AssertionResult {
  if (!settings.initialImage) {
    return {
      message: `Initial image required`,
      success: false,
    };
  }

  return {
    success: true,
  };
}

export function validateQuestion(question: ImageMarkupQuestion.Question): Validation.ValidateQuestionResult<SurveyQuestionType.NumberInputTable> {
  const hasValue = assertHasValue(question);
  if (hasValue.success === false) {
    return returnAssertFailure(hasValue, question);
  }

  const hasValidSettings = assertValidSettings(question);
  if (hasValidSettings.success === false) {
    return returnAssertFailure(hasValidSettings, question);
  }

  return {
    success: true,
    result: {
      question,
    },
  };
}
