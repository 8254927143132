import { useCallback, useContext } from 'react';
import { utils } from '@enums';
import { ParticipantsContext } from '$admin/components/Scheduling/Context';

export const useSchedulerCallRoleSelector = () => {
  const ctx = {
    participants: useContext(ParticipantsContext),
  };

  const selectSchedulerRole = (email: string) => {
    const participant = ctx.participants.items.find(x => x.email === email);

    return {
      id: participant?.roleId,
      name: utils.CallRole.getName(participant?.roleId),
    };
  };

  return useCallback(selectSchedulerRole, [
    ctx.participants,
  ]);
};