import type { SurveyBuilder, SurveyAIEBuilder } from '../interfaces';
import * as $actions from './state.aie';

export function aieReducer(state: SurveyBuilder.State, action: SurveyBuilder.Actions): SurveyAIEBuilder.State {
  const exercises = state.survey.alternateImageExercises;

  switch (action.type) {

    case 'aie-added':
      return $actions.exerciseAdded(exercises, action);

    case 'aie-removed':
      return $actions.exerciseRemoved(exercises, action);

    case 'aie-group-added':
      return $actions.groupAdded(exercises, action);

    case 'aie-group-removed':
      return $actions.groupRemoved(exercises, action);

    case 'aie-entry-added':
      return $actions.entryAdded(exercises, action);

    case 'aie-entry-removed':
      return $actions.entryRemoved(exercises, action);

    case 'aie-entry-updated':
      return $actions.entryUpdated(exercises, action);

    case 'aie-settings-updated':
      return $actions.exerciseSettingsUpdated(exercises, action);

    case 'aie-group-name-updated':
      return $actions.groupNameUpdated(exercises, action);

    case 'aie-group-settings-updated':
      return $actions.groupSettingsUpdated(exercises, action);

    case 'aie-updated':
      return $actions.exerciseUpdated(exercises, action);

    case 'revert-uncommitted-changes':
      return state.committed.alternateImageExercises;

    case 'aie-entry-moved':
      return $actions.entryMoved(exercises, action);

    default:
      return exercises;
  }
}