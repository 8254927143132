import { useCallback } from 'react';
import cuid from 'cuid';
import { SurveyActionType, SurveyLogicRule } from '@enums';
import { SurveyLogic } from '@/types';
import { SurveyLogicBuilderContext, useLogicBuilderProps } from '@containers/SurveyBuilder.Logic';
import { useSurveySectionBuilderContext } from '@/containers/SurveyBuilder.Section/context';

type Props =
  ChildrenProps;

function isLogicItemRelevant(item: SurveyLogic.Item, sectionIdentifier: string) {
  return item.action.type === SurveyActionType.IncludeSection &&
    item.action.section.identifier === sectionIdentifier;
}

export const VisibilityLogicContainer = (props: Props) => {
  const ctx = useSurveySectionBuilderContext();

  const testItemRelevancy = useCallback((item: SurveyLogic.Item) => {
    return isLogicItemRelevant(item, ctx.section.identifier);
  }, [ctx.section.identifier]);

  const generateNewItem = useCallback((): SurveyLogic.Item => {
    const identifier = cuid();
    return {
      action: {
        section: { identifier: ctx.section.identifier },
        type: SurveyActionType.IncludeSection,
      },
      conditions: [],
      id: null,
      identifier,
      metadata: {
        canDelete: true,
        canEdit: true,
      },
      rule: {
        expression: null,
        type: SurveyLogicRule.AllOfTheAbove,
      },
    };
  }, [ctx.section.identifier]);

  const builderProps = useLogicBuilderProps({
    generateNewItem,
    testItemRelevancy,
  });

  const value = {
    ...builderProps,
    display: {
      hideActions: true,
    },
  };

  return (
    <SurveyLogicBuilderContext.Provider value={value}>
      {props.children}
    </SurveyLogicBuilderContext.Provider>
  );
};