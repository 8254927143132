import { useCallback, useContext } from 'react';
import { PostCreationSubscriptionContext } from '@containers/PostCreation/Context';
import { useTopicTagsContext, useVideoPostStateContext, useVideoPostIdentifier } from '@containers/PostCreation/hooks/usePostCreationContext';

export const useVideoPostPublish = () => {
  const [state] = useVideoPostStateContext();
  const [topic] = useTopicTagsContext();
  const identifier = useVideoPostIdentifier();
  const ctx = useContext(PostCreationSubscriptionContext);

  const publishVideoPost = useCallback(() => {

    ctx.video.submit({
      ...state,
      identifier,
      topics: topic.items.map(x => x.id),
    });

  }, [
    ctx.video,
    identifier,
    state,
    topic.items,
  ]);

  return publishVideoPost;
};