import { useCallback } from 'react';
import { GrayOutlineButton } from '@presentation/Buttons';
import { useSubmitSurveyDraft } from '@/containers/SurveyBuilder';
import { Button } from '@/components/Button';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import ModalHeader from '@/components/Modal/Header';
import { useModal } from '@/components/Modal/hooks';
import { useCanSaveDoseAdministration, useTemplateDoseAdministration } from './hooks/dose-administration';
import { TemplateDoseAdministrationsInput } from './Input.DoseAdministration';
import styles from './style/Modal.css';

type Props =
  Pick<ModalProps,
    'open' |
    'onClose'>;

export const TemplateDoseAdministrationModal = ({
  onClose,
  open,
}: Props) => {

  const {
    onSubmit,
    ...inputProps
  } = useTemplateDoseAdministration();

  const submitDraft = useSubmitSurveyDraft();

  const handleSubmit = useCallback(() => {

    onSubmit();
    submitDraft();
    onClose();
  }, [
    onSubmit,
    onClose,
    submitDraft,
  ]);

  const canSubmit = useCanSaveDoseAdministration(inputProps.items);

  return (
    <Modal
      open={open}
      onClose={onClose}>

      <ModalHeader text="Update Dosing & Administration" />

      <div className={styles.body}>
        <TemplateDoseAdministrationsInput
          {...inputProps} />
      </div>

      <div className={styles.footer}>
        <GrayOutlineButton
          onClick={onClose}
          title="Cancel" />
        <Button.Primary
          className={styles.submit}
          disabled={!canSubmit}
          onClick={handleSubmit}
          title="Submit"
          variant="brick" />
      </div>
    </Modal>
  );
};

export const useTemplateDoseAdministrationModal = () => useModal(TemplateDoseAdministrationModal);

export default TemplateDoseAdministrationModal;