import { useCallback, useContext, useState } from 'react';
import { ButtonActivityIndicator, ButtonOutlined } from '@presentation';
import { Checkbox } from '@/components/Checkbox';
import { Header } from '@/components/Modal/Header';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { Modal as RootModal } from '@/components/Modal/Modal';
import { CallCancellationContext } from './Context';
import styles from './style/Cancellation.Modal.css';

type Props = unknown;

export const Modal = (props: Props) => {
  const ctx = useContext(CallCancellationContext);
  const [notify, setNotify] = useState(true);

  const handleSubmit = useCallback(() => {
    ctx.mutation.mutate({ notify }, {
      onSuccess: ctx.resetContext,
    });
  }, [
    ctx.mutation,
    ctx.resetContext,
    notify,
  ]);

  return (
    <RootModal
      classes={{
        root: styles.root,
        close: styles.close,
      }}
      disableEscapeClose={ctx.mutation.isLoading}
      disableOverlayClick={ctx.mutation.isLoading}
      onClose={ctx.resetContext}
      open>
      <div className={styles.wrap}>
        <Header>Cancel Call?</Header>
        <div className={styles.main}>
          {ctx.params.externalRespondent &&
            <div className={styles.info}>
              {copy.external}
            </div>}
          <div className={styles.checkbox}>
            <Checkbox
              checked={notify}
              onChange={(e, checked) => setNotify(checked)} />
            <div className={styles.message}>{copy.notify}</div>
          </div>
        </div>
        <ButtonSet className={styles.footer}>
          <ButtonOutlined
            className={styles.btn}
            color="silver"
            disabled={ctx.mutation.isLoading}
            onClick={ctx.resetContext}>
            Cancel
          </ButtonOutlined>
          <ButtonActivityIndicator
            className={styles.btn}
            loading={ctx.mutation.isLoading}
            implicitDisable={false}
            onClick={handleSubmit}>
            Confirm
          </ButtonActivityIndicator>
        </ButtonSet>
      </div>
    </RootModal>
  );
};

Modal.displayName = 'Call.Cancellation.Modal';

const copy = {
  external: `External respondent attendees without platform accounts should be manually notified of any scheduling changes and will not receive Cancelling notifications.`,
  notify: `Send cancellation notifications`,
};