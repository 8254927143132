import { memo, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Copy } from 'react-feather';
import { Link } from 'react-router-dom';
import Markdown, { type Options as MarkdownOptions } from 'react-markdown';
import { SetTaggedMomentEditParamsContext, TranscriptQuoteMomentIdContext } from '@/containers/Transcript/context';
import { TranscriptType } from '@/enums';
import { SeeMore } from '@/presentation/SeeMore';
import { StarToggle } from '@/presentation/StarToggle';
import { Tooltip } from '@/presentation/Tooltip';
import { cx, getLocationFor } from '@/utils';
import type { TranscriptHighlightItem } from '@/types';
import type { TranscriptsTableItem } from './interfaces';
import styles from './style/Transcript.Highlight.css';

type Props = {
  className?: string;
  data: TranscriptsTableItem;
  highlight: TranscriptHighlightItem;
  onCopy: () => void;
};

export const TranscriptHighlight = memo(({ className, data, highlight, onCopy }: Props) => {

  const quoteMomentId = useContext(TranscriptQuoteMomentIdContext);
  const buildTagContext = useContext(SetTaggedMomentEditParamsContext);

  const starred = !!quoteMomentId;

  const handleStarQuote = useCallback(() => {
    buildTagContext({
      isGoodQuote: true,
      momentId: null,
      tagId: null,
      range: highlight.range,
      transcriptId: data.transcript.id,
    });
  }, [buildTagContext, data.transcript.id, highlight.range]);

  const transcriptLocation = useMemo(() => {
    if (data.transcript.type === TranscriptType.VideoCall) {
      return getLocationFor.call.transcript({
        slug: data.metadata.project.id.toString(),
        callId: data.metadata.call.id,
        position: highlight.range.start,
      });
    }
    if (data.transcript.type === TranscriptType.WorkspaceFile) {
      return getLocationFor.workspaces.transcript({
        transcriptId: data.transcript.id,
        position: highlight.range.start,
      });
    }
  }, [
    data.transcript,
    data.metadata.call,
    data.metadata.project,
    highlight.range,
  ]);

  return (
    <div className={cx(styles.root, className)}>
      <StarToggle
        className={styles.star}
        onClick={handleStarQuote}
        value={starred} />
      <Quote>
        <div className={styles.quoteWrap}>
          <Content item={highlight} />
          <CopyToClipboard
            onCopy={onCopy} />
        </div>
      </Quote>
      <Link
        className={styles.viewMore}
        to={transcriptLocation}>
        {`View >`}
      </Link>
    </div>
  );
});

type ContentProps = {
  item: TranscriptHighlightItem;
};

const Content = memo(({ item }: ContentProps) => {

  return (
    <div className={styles.item}>
      <div className={styles.header}>
        {item.speaker} [{secondsToTime(item.range.start)} - {secondsToTime(item.range.end)}]
      </div>
      <SeeMore
        lineHeight={20}
        maxLines={8}>
        <div className={styles.content}>
          <MonologueMarkdown text={item.text} />
        </div>
      </SeeMore>
    </div>
  );
});

type CopyProps = {
  className?: string;
  onCopy: () => void;
};

const CopyToClipboard = memo(({ className, onCopy }: CopyProps) => {

  const [showCopied, setShowCopied] = useState<boolean>(false);
  const timer = useRef<NodeJS.Timeout>();

  const handleClick = useCallback(() => {
    setShowCopied(true);
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => setShowCopied(false), 2000);
    onCopy();
  }, [onCopy]);

  return (
    <Tooltip
      title={showCopied ? `Copied to clipboard` : `Copy to clipboard`}
      enterDelay={1000}
      enterNextDelay={500}>
      <Copy
        className={cx(styles.copy, className)}
        onClick={handleClick} />
    </Tooltip>
  );

});

type QuoteProps = {
  className?: string;
} & ChildrenProps;

const Quote = memo((props: QuoteProps) => {
  return (
    <div className={cx(styles.quote, props.className)}>
      {props.children}
    </div>
  );
});

const MonologueMarkdown = memo(({ text }: { text: string }) => {

  const components = useMemo((): MarkdownOptions['components'] => ({
    p: props => (
      <p className={styles.paragraph}>
        {props.children}
      </p>
    ),
    strong: props => (
      <span className={styles.highlight}>
        {props.children}
      </span>
    ),
  }), []);

  return (
    <Markdown
      components={components}>
      {text}
    </Markdown>
  );
});

function secondsToTime(value: number) {
  const time = new Date(value * 1000).toISOString().substring(11, 19);
  return time.startsWith('00') ? time.substring(3) : time;
}