import { useCallback } from 'react';
import { useInvalidateFilesQueries, useInvalidateFilePreviewQueries } from '@utils/api';
import { WorkspaceFile } from '@/types';
import { useUpdateFilePublishedOn } from './hooks';
import { UpdateFilePublishedOnContext } from './Context';

type Props =
  IWorkspaceId &
  { file: WorkspaceFile } &
  ChildrenProps;

export const UpdateFilePublishedOnContainer = (props: Props) => {
  const updatePublishedDate = useUpdateFilePublishedOn();
  const invalidateFilesQueries = useInvalidateFilesQueries();
  const invalidateFilePreviewQueries = useInvalidateFilePreviewQueries();

  const handleUpdate = useCallback((publishedOn: Date) => {
    return updatePublishedDate({
      fileId: props.file.id,
      publishedOn,
      workspaceId: props.workspaceId,
    })
    .then(() => {
      invalidateFilePreviewQueries({ fileId: props.file.id });
      invalidateFilesQueries();
    });
  }, [
    invalidateFilesQueries,
    invalidateFilePreviewQueries,
    updatePublishedDate,
    props.file.id,
    props.workspaceId,
  ]);

  const value = {
    file: props.file,
    update: handleUpdate,
  };

  return (
    <UpdateFilePublishedOnContext.Provider value={value}>
      {props.children}
    </UpdateFilePublishedOnContext.Provider>
  );
};

export default UpdateFilePublishedOnContainer;