import { useCallback } from 'react';
import { useRepeatSurveyResponsesState } from '@containers/RepeatSurveyResponses/hooks/useRepeatSurveyResponsesState';
import { RepeatSurvey } from '@containers/RepeatSurveyResponses/interfaces';
import RankingAggregateData from './RankingAggregateData';
import RankingVersionData from './RankingVersionData';
import RankingRankData from './RankingRankData';
import styles from './style/Data.css';

export const RankingData = () => {
  const { query } = useRepeatSurveyResponsesState<RepeatSurvey.QueryState.Ranking>();

  const renderContent = useCallback(() => {
    if (query.rank !== undefined) {
      return <RankingRankData />;
    } else if (query.projectId) {
      return <RankingVersionData />;
    } else {
      return <RankingAggregateData />;
    }
  }, [query]);

  return (
    <div className={styles.root}>
      {renderContent()}
    </div>
  );
};

export default RankingData;