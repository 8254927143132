import type { ChangeEvent } from 'react';
import { useCallback, useMemo, useState } from 'react';
import * as $api from '@api/projects.quant-analysis';
import { useAsyncMutationDownloader } from '@/containers/QueryDownloader';
import { ReportFormat } from '@/enums';
import { Button } from '@/components/Button';
import { Input } from '@/components/Input';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import { useModal } from '@/components/Modal/hooks';
import { Header } from '@/components/Modal/Header';
import { FilePicker } from '@/components/FilePicker';
import styles from './style/Modal.UploadAnalysisFile.css';

type Props = IProjectId & Pick<ModalProps, 'onClose' | 'open'>;

export const UploadQuantAnalysisFileModal = ({ onClose, open, projectId }: Props) => {

  const [file, setFile] = useState<File>(null);
  const [name, setName] = useState<string>('');

  const { mutateAsync: startUpload } = useAsyncMutationDownloader({
    mutationFn: async () => {

      return $api.uploadProjectQuantAnalysisFile({
        projectId,
        name,
        file,
      });
    },
  });

  const handleFileSelected = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0) {
      return;
    }

    const file = e.target.files[0];

    setFile(file);
  }, []);

  const canSubmit = useMemo(() => {
    return !!file && !!name;
  }, [file, name]);

  const handleUpload = useCallback(() => {
    const format = ReportFormat.Excel;
    startUpload({
      meta: {
        format,
        name: file.name,
        title: `Uploading Analysis File`,
      },
    });
    onClose();
  }, [file?.name, onClose, startUpload]);

  const handleNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  return (
    <Modal
      onClose={onClose}
      open={open}>

      <Header>Upload Analysis File</Header>

      <div className={styles.body}>

        <div className={styles.input}>
          <div className={styles.label}>Analysis Name</div>
          <Input
            onChange={handleNameChange}
            placeholder="Enter analysis name"
            value={name} />
        </div>

        <FilePicker
          accept=".xlsx,.xls"
          className={styles.filePicker}
          onSelect={handleFileSelected} />
        {file && <div className={styles.fileName}>{file.name}</div>}
      </div>

      <ButtonSet className={styles.footer}>
        <Button
          className={styles.action}
          variant="brick"
          disabled={!canSubmit}
          color="primary"
          onClick={handleUpload}>
          Upload
        </Button>
      </ButtonSet>
    </Modal>
  );
};

export const useUploadQuantAnalysisFileModal = () => useModal(UploadQuantAnalysisFileModal);