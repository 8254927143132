import { useCallback } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks/useSurveyBuilderContext';
import { useQuestionBuilderItem } from '@containers/SurveyBuilder.Question';
import { MatrixGridQuestion, MatrixMultiselectQuestion } from '@/types/survey';
import { CheckboxLabel } from '@/components/Checkbox';

type Props = {
  className?: string;
};

export const MatrixNotApplicable = ({ className }: Props) => {
  const [_, dispatch] = useSurveyBuilderState();

  const item = useQuestionBuilderItem() as MatrixGridQuestion.Question | MatrixMultiselectQuestion.Question;

  const toggleNotApplicable = useCallback((_, checked: boolean) => {
    dispatch({
      type: 'toggle-not-applicable',
      questionIdentifier: item.base.identifier,
      value: checked,
    });
  }, [
    dispatch,
    item.base.identifier,
  ]);

  return (
    <CheckboxLabel
      className={className}
      disabled={item.settings.groupBy === 'column'}
      checked={item.settings.includeNotApplicable}
      label="Include N/A as choice"
      onChange={toggleNotApplicable} />
  );
};