import { forwardRef, useCallback } from 'react';
import { useBlockLayout, useResizeColumns, useTable } from 'react-table';
import { cx } from '@utils';
import { Worksheet as Sheet } from '@/types';
import { useWorksheetColumns } from './hooks/useWorksheetColumns';
import { useWorksheetData } from './hooks/useWorksheetData';
import styles from './style/Worksheet.css';

type Props = {
  rowLimit?: number;
  sheet: Sheet;
};

export const Worksheet = forwardRef<HTMLDivElement, Props>(({ rowLimit, sheet }, ref) => {

  const columns = useWorksheetColumns(sheet);
  const data = useWorksheetData(sheet, { rowLimit });

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      columns,
      data,
    },
    useBlockLayout,
    useResizeColumns,
  );

  const renderHeader = useCallback(() => {
    return (
      (<thead>
        {headerGroups.map(headerGroup => (
          // eslint-disable-next-line react/jsx-key
          (<tr
            {...headerGroup.getHeaderGroupProps({
              style: {
                display: 'table-row',
              },
            })}>
            {headerGroup.headers.map(column => {
              const { key: headerGroupKey, ...rest } = column.getHeaderProps({
                style: {
                  position: 'sticky',
                },
              });
              const resizerClass = cx(styles.resizer, {
                [styles.resizing]: column.isResizing,
              });
              return (
                <th
                    key={headerGroupKey}
                    {...rest}>
                  <div className={styles.cell}>
                    {column.render('Header')}
                    {column.canResize &&
                    <div
                      {...column.getResizerProps()}
                      className={resizerClass} />}
                  </div>
                </th>
              );
            })}
          </tr>)
        ))}
      </thead>)
    );
  }, [
    headerGroups,
  ]);

  const renderBody = useCallback(() => {
    return (
      <tbody>
        {rows.map((row, rowNum) => {
          prepareRow(row);
          const { key: rowKey, ...rowProps } = row.getRowProps();
          return (
            <tr key={rowKey} {...rowProps}>
              {row.cells.map((cell, i) => {
                const { key: cellKey, ...cellProps } = cell.getCellProps();

                if (i === 0) {
                  return (
                    <th key={cellKey} {...cellProps}>
                      <div className={styles.cell}>{rowNum + 1}</div>
                    </th>
                  );
                } else {
                  return (
                    <td key={cellKey} {...cellProps}>
                      {cell.render('Cell')}
                    </td>
                  );
                }
              })}
            </tr>
          );
        })}
      </tbody>
    );
  }, [
    prepareRow,
    rows,
  ]);

  return (
    <div className={styles.root} ref={ref}>
      <table className={styles.table} {...getTableProps()}>
        {renderHeader()}
        {renderBody()}
      </table>
      {sheet.rows > rowLimit &&
        <div className={styles.limit}>
          Number of rows limited in preview. Download file for full version.
        </div>
      }
    </div>
  );
});

export default Worksheet;