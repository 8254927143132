import { Link } from 'react-router-dom';
import { path } from '@consts';
import { TopicLogo } from '@presentation/Topic.Logo';
import { cx } from '@utils';
import { Topics } from '@/types';
import styles from './style/SuggestedTopics.Card.css';

type Props = {
  className?:        string;
  following:         boolean;
  item:              Topics.Following;
  onClick?:          () => unknown;
  onToggleFollowing: () => void;
};

export const Card = (props: Props) => {
  const classes = {
    btn: cx(styles.btn, { [styles.unfollow]: props.following }),
  };

  return (
    <div className={cx(styles.root, props.className)}>
      <Link
        to={`${path.Topics.Root}/${props.item.slug}`}
        onClick={props.onClick}>
        <div className={styles.wrap}>
          <div className={styles.main}>
            <TopicLogo
              classes={{
                root: styles.logo,
                img: styles.img,
              }}
              url={props.item.thumbnailUrl} />
            <div
              className={styles.name}
              title={props.item.name}>
              {props.item.name}
            </div>
          </div>
        </div>
      </Link>
      <button
        className={classes.btn}
        onClick={props.onToggleFollowing}>
        {props.following
            ? 'Following'
            : 'Follow'}
      </button>
    </div>
  );
};

Card.displayName = 'SuggestedTopics.Card';