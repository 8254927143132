import { Component } from 'react';
import Dropdown from '@/components/DropDown';
import styles from './style.css';

class DropdownField extends Component {
  static defaultProps = {
    getItemValue: () => {},
    items: [],
    label: '',
    onSelect: () => {},
    selected: '',
  }

  render() {
    return (
      <div className={styles.root}>
        <div className={styles.label}>{this.props.label}</div>
        <div className={styles.select}>
          <Dropdown
            items={this.props.items}
            getItemValue={this.props.getItemValue}
            onSelect={this.props.onSelect}
            text={this.props.selected} />
        </div>
      </div>
    );
  }
}

export default DropdownField;