import { useMemo } from 'react';
import { useSurveyBuilderCapabilities } from '../Context';
import useIsEditingSurvey from './useIsEditingSurvey';

export const useCanAddSection = () => {
  const isEditing = useIsEditingSurvey();
  const { canAddItems } = useSurveyBuilderCapabilities();

  const canAddSection = useMemo(() => {
    return !isEditing && canAddItems;
  }, [
    isEditing,
    canAddItems,
  ]);

  return canAddSection;
};

export default useCanAddSection;