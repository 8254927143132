import { memo } from 'react';
import src from 'static/icons/calendly.png';

type Props = {
  className?: string;
  onClick?: () => void;
};

const Calendly = memo(({
  className,
  onClick,
}: Props) => {
  return (
    <img
      className={className}
      onClick={onClick}
      src={src} />
  );
});

export { Calendly };
export default Calendly;