import { useCallback, useState } from 'react';
import { FrameContext } from './Context';
import { Action, FrameKey } from './interfaces';

type Props = {
  children: React.ReactNode;
};

export const Frame = (props: Props) => {
  const [view, setFrame] = useState<Action>({ frame: FrameKey.ModeratorSchedulers });
  const [stack, setStack] = useState<Action[]>([]);

  const back = useCallback(() => {
    const stackCopy = [...stack];
    const action = stackCopy.pop();

    if (action) {
      setFrame(action);
      setStack(stackCopy);
    }
  }, [stack]);

  const goToFrame = useCallback((action: Action) => {
    setFrame(action);
    setStack(s => {
      return s.concat(view);
    });
  }, [view]);

  const value = {
    back,
    goToFrame,
    stack,
    view,
  };

  return (
    <FrameContext.Provider value={value}>
      {props.children}
    </FrameContext.Provider>
  );
};

Frame.displayName = 'Call.Scheduling.Frame';