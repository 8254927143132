import { useCallback, useContext, useState } from 'react';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { usePopper } from 'react-popper';
import * as $api from '@api/projects.quant-analysis';
import { ButtonOutlined } from '@presentation/ButtonOutlined';
import { useAsyncMutationDownloader } from '@/containers/QueryDownloader';
import { QuantAnalysisContext } from '@/containers/Project.QuantAnalysis';
import { useProjectState } from '@/containers/GroupProject/hooks';
import { ReportFormat } from '@/enums';
import { useDeleteQuantAnalysisModal } from '@/components/Project.QuantAnalysis';
import { useZIndexModifier, PopperMenu, PopperMenuItem } from '@/components/Popper';
import { Portal } from '@/components/Portal';

type Props = {
  className?: string;
};

export const QuantAnalysisActions = ({ className }: Props) => {

  const [open, setOpen] = useState(false);

  const zIndexModifier = useZIndexModifier({ zIndex: 8 });

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);
  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      zIndexModifier,
    ],
    placement: 'bottom-start',
  });

  const handleBlur = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const quantAnalysis = useContext(QuantAnalysisContext);
  const state = useProjectState();

  const [toggleDeleteQuantAnalysisModal, DeleteQuantAnalysisModal] = useDeleteQuantAnalysisModal();

  const { mutateAsync: startReparsingFile } = useAsyncMutationDownloader({
    mutationFn: async () => {

      return $api.reparseQuantAnalysis({
        projectId: state.project.id,
        quantAnalysisId: quantAnalysis.id,
      });
    },
  });

  const handleReparseFile = useCallback(() => {
    const format = ReportFormat.Excel;
    startReparsingFile({
      meta: {
        format,
        name: quantAnalysis.name,
        title: `Uploading Analysis File`,
      },
    });
    handleBlur();
  }, [handleBlur, quantAnalysis, startReparsingFile]);

  return (
    <>
      <div
        className={className}
        ref={setReferenceElement}
        onClick={handleClick}>
        <ButtonOutlined>Actions</ButtonOutlined>
      </div>
      {open &&
        <Portal>
          <ClickAwayListener
            mouseEvent='onMouseDown'
            onClickAway={handleBlur}
            touchEvent='onTouchStart'>
            <div
              ref={setPopperElement}
              style={popperStyles.popper}
              {...attributes.popper}>
              <PopperMenu>
                <PopperMenuItem onClick={toggleDeleteQuantAnalysisModal}>
                  Delete Analysis
                </PopperMenuItem>
                <PopperMenuItem onClick={handleReparseFile}>
                  Reparse File
                </PopperMenuItem>
              </PopperMenu>
            </div>
          </ClickAwayListener>
        </Portal>
      }
      <DeleteQuantAnalysisModal />
    </>
  );
};