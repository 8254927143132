import { useCallback, memo } from 'react';
import CameraOnIcon from '@mui/icons-material/Videocam';
import CameraOffIcon from '@mui/icons-material/VideocamOff';
import Popper from '@mui/material/Popper';
import { bindPopper, usePopupState } from 'material-ui-popup-state/hooks';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { cx } from '@utils';
import { useProvidedDevices } from '@containers/DeviceProvider';
import { Tooltip } from '@/presentation/Tooltip';
import { PopperMenu, PopperMenuItem } from '@/components/Popper';
import { DeviceMenuList } from './DeviceMenuList';
import styles from './style/Camera.css';

type Props = {
  className?: string;
  children?: React.ReactNode;
  value: boolean;
  visible?: boolean;
  disabledByInvisible?: boolean;
  onClick?: (value: boolean) => void;
  selectedDevice: MediaDeviceInfo;
  onSelectDevice: (device: MediaDeviceInfo) => void;
};

export const Camera = memo(({ className, children, value, visible, onClick, selectedDevice, onSelectDevice, disabledByInvisible }: Props) => {
  const handleClick = useCallback(() => {
    onClick?.(!value);
  }, [onClick, value]);

  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'microphone-source-popper',
  });

  const devices = useProvidedDevices();

  const handleRightClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (devices.hasVideoInputDevices) {
      popupState.open(e);
    }
  }, [popupState, devices]);

  const selectDevice = useCallback((device: MediaDeviceInfo) => {
    popupState.close();
    onSelectDevice(device);
  }, [popupState, onSelectDevice]);

  if (!visible) return null;

  if (disabledByInvisible) {
    return (
      <Tooltip title="You cannot turn on video while invisible" placement='top'>
        <div className={cx(styles.camera, className)}>
          {children ?? <CameraIcon value={false} disabled={true} />}
        </div>
      </Tooltip>
    );
  }

  return (
    <>
      <div
        className={cx(styles.camera, className)}
        onClick={handleClick}
        onContextMenuCapture={handleRightClick}>
        {children ?? <CameraIcon value={value} />}
      </div>
      <Popper
        {...bindPopper(popupState)}
        style={{ zIndex: 99 }}
        placement="top-start">
        <ClickAwayListener
          onClickAway={popupState.close}>
          <PopperMenu>
            <DeviceMenuList
              devices={devices.videoInputDevices}
              onSelectDevice={selectDevice}
              selectedDeviceId={selectedDevice?.deviceId} />
          </PopperMenu>
        </ClickAwayListener>
      </Popper>
    </>
  );
});

const CameraIcon = memo(({ value, disabled = false }: { value: boolean; disabled?: boolean }) => {
  return value
    ? (
      <div className={cx(styles.iconCamera, disabled ? styles.disabled : null)}>
        <CameraOnIcon />
      </div>
    )
    : (
      <div className={cx(styles.iconCameraOff, disabled ? styles.disabled : null)}>
        <CameraOffIcon />
      </div>
    );
});