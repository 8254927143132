import * as consts from '@consts';
import BaseModal from '@/components/Modal/Modal.Legacy';
import { ConnectAccountProvider } from '@/components/ConnectAccount';
import ShareWithOthers from '@/scenes/referrals/ShareWithOthers';
import type { ExpertProps, ReferralModalOnSendProps } from './interfaces';

const ReferralExpertModal = ({
  subtitle = `Invite your peers to join our network and get paid $${consts.referral.REFERRAL_AMOUNT} for every verified account.`,
  ...props
}: ExpertProps) => {
  return (
    <BaseModal onClose={props.onClose} visible={props.visible}>
      <ConnectAccountProvider>
        <ShareWithOthers onSend={props.onSend} subtitle={subtitle} />
      </ConnectAccountProvider>
    </BaseModal>
  );
};

export type { ReferralModalOnSendProps };
export { ReferralExpertModal };
export default ReferralExpertModal;