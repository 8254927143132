import { useCallback, useEffect } from 'react';
import { TranscriptSocketEvent } from '@/enums';
import { Transcripts } from '../interfaces';
import * as $transcripts from '../transcripts';

export const useTranscriptsSocketEvent = <T extends TranscriptSocketEvent>(event: T, fn: Transcripts.RegisterHandler<T>, enabled = true) => {

  useEffect(() => {
    if (enabled) {
      $transcripts.on(event, fn);
      return () => {
        $transcripts.off(event, fn);
      };
    }
  }, [enabled, event, fn]);
};
