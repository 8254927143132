import { useContext, useMemo } from 'react';
import { FileUploadsStateContext } from '../Context';

export const useFileUploadProgress = (fileIdentifier: string) => {
  const { progress: progressMap } = useContext(FileUploadsStateContext);

  const progress = useMemo(() => {
    return progressMap[fileIdentifier] || 0;
  }, [
    fileIdentifier,
    progressMap,
  ]);

  return progress;
};

export default useFileUploadProgress;