import { useCallback, useContext, Fragment } from 'react';
import { ThumbnailFileContext } from './Video.Context';
import styles from './style/Video.ContentForm.ThumbnailFileInput.css';

type Props = unknown;

export const ThumbnailFileInput = (props: Props) => {
  const [file, setFile] = useContext(ThumbnailFileContext);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const [data] = Array.from(e.target.files);

    setFile(data);
  }, [setFile]);

  const handleDelete = useCallback(() => {
    setFile(null);
  }, [setFile]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>

        <div className={styles.main}>
          <div className={styles.btn}>
            {!file
              ? <AddFileButton onClick={handleChange} />
              : <RemoveFileButton onClick={handleDelete} />}
          </div>

          <div className={styles.subtitle}>Upload a Thumbnail (formats: JPG, PNG)</div>
        </div>
      </div>
    </div>
  );
};

ThumbnailFileInput.displayName = 'PostCreation.Video.ContentForm.ThumbnailFileInput';

type AddFileButtonProps = {
  onClick: (e: React.ChangeEvent<HTMLInputElement>) => unknown;
};

function AddFileButton(props: AddFileButtonProps) {
  return (
    <Fragment>
      <button className={styles.label}>Add a thumbnail</button>
      <input
        className={styles.input}
        onChange={props.onClick}
        accept=".jpg,.png"
        type="file" />
    </Fragment>
  );
}

type RemoveFileButtonProps = {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => unknown;
};

function RemoveFileButton(props: RemoveFileButtonProps) {
  return (
    <button
      className={styles.label}
      onClick={props.onClick}>
      Remove thumbnail
    </button>
  );
}