import { Dispatch, SetStateAction } from 'react';
import { createNamedContext } from '@utils';
import {
  TaggedProject,
  TaggedProjectsContextValue,
  TaggedProjectsFetchContextValue,
  TaggedProjectsUpdateContextValue,
} from './interfaces';

export const TaggedProjectsDispatchContext = createNamedContext<Dispatch<SetStateAction<TaggedProject[]>>>(undefined, 'GroupEntityTagsDispatchContext');
export const TaggedProjectsFetchContext = createNamedContext<TaggedProjectsFetchContextValue>(undefined, 'GroupEntityTagsFetchContext');
export const TaggedProjectsLoadingContext = createNamedContext<boolean>(false, 'GroupEntityTagsLoadingContext');
export const TaggedProjectsUpdateContext = createNamedContext<TaggedProjectsUpdateContextValue>(undefined, 'GroupEntityTagsUpdateContext');
export const TaggedProjectsContext = createNamedContext<TaggedProjectsContextValue>(undefined, 'GroupEntityTagsContext');