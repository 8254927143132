import { useCallback, useMemo } from 'react';
import RSelect from 'react-select';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { SurveyType } from '@/enums';
import { useQuestionBuilderItemContext } from '@containers/SurveyBuilder.Question/Context';
import { FancyInput } from '@/components/Input';
import { ColorCircle } from '@/components/Conference.Tagging/Tagging.ColorSelect';
import { Tags } from '@/components/Conference.Tagging/interfaces';
import { Select } from '$admin/components/Select';
import styles from './style/CaptureSheet.Fields.css';

export const CaptureSheetFields = () => {
  const [state, dispatch] = useSurveyBuilderState();
  const item = useQuestionBuilderItemContext();

  const onLabelChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'update-question-capture-sheet-label',
      questionIdentifier: item.base.identifier,
      label: e.target.value,
    });
  }, [dispatch, item.base.identifier]);

  const onColorChange = useCallback((selectedColor: ColorItem) => {
    dispatch({
      type: 'update-question-capture-sheet-color',
      questionIdentifier: item.base.identifier,
      color: selectedColor.id,
    });
  }, [dispatch, item.base.identifier]);

  const colorOptions = useMemo(() => Tags.baseColors.map(c => ({ id: c.menu, name: c.menu })), []);

  if (state.survey.surveyDetails?.typeId !== SurveyType.ConferenceQuant) return null;

  return (
    <div className={styles.root}>
      <FancyInput
        className={styles.label}
        value={item.captureSheetLabel ?? ''}
        onChange={onLabelChange}
        placeholder='Capture Sheet Label' />
      <Select
        classes={{
          root: styles.colorRoot,
          select: styles.colorRoot,
        }}
        className={styles.colorInput}
        options={colorOptions}
        renderOption={(i: { id: string }) => <ColorCircle color={i.id} />}
        placeholder={'-'}
        renderValue={(v: string) => {
          return <ColorCircle color={v} />;
        }}
        value={item.captureSheetColor ?? ''}
        onChange={e => { console.log(e); onColorChange({ id: e.target.value.toString() }); }} />
    </div>
  );
};

type ColorItem = {
  id: string;
};