import { useCallback, useContext, useEffect, useState } from 'react';
import { createId } from '@paralleldrive/cuid2';
import type { FileUploadFile } from '@/types';
import { FileUploadReviewModal, useFilesUploadValidationsModal } from '@/components/Workspace.File.Upload';
import { usePreFileUploadMutation } from './hooks';
import type { UploadFilesForReviewParams } from './interfaces';
import { FileUploadReviewStateContext, FileUploadReviewDispatchContext, InitiateFilesReviewContext, UploadFilesBaseContext, FileUploadsDispatchContext } from './Context';
import { FileUploadReviewState } from './Upload.Review.State';
import { UploadFilesContainer } from './Upload.Container';
import { FileUploadEffectsContainer } from './Effects.Container';
import { validateFile } from './utils';

type Props = ChildrenProps;

type ValidationItem = {
  fileName: string;
  errors: string[];
};

const FileUploadReviewContainer = (props: Props) => {

  const state = useContext(FileUploadReviewStateContext);
  const [validations, setValidations] = useState<ValidationItem[]>([]);
  const dispatch = useContext(FileUploadReviewDispatchContext);
  const uploadFiles = useContext(UploadFilesBaseContext);

  const [toggleValidationsModal, ValidationsModal] = useFilesUploadValidationsModal();

  const { mutateAsync: runFileReviewMutation } = usePreFileUploadMutation({
    onSuccess: (data, vars) => {
      //const needsReview = !!(Object.keys(data.existing).length || data.transcribeable.length);
      const needsReview = true;

      if (needsReview) {
        dispatch({
          type: 'initialized',
          payload: {
            files: vars.files,
            existing: data.existing,
            tags: [],
            transcribeable: data.transcribeable,
            replaceExisting: false,
            showModal: true,
            workspaceId: vars.workspaceId,
            parentObjectId: vars.parentObjectId,
            uploadInitiated: false,
            isTranscriptImport: vars.isTranscriptImport,
          },
        });
      } else {
        uploadFiles({
          isTranscriptImport: vars.isTranscriptImport,
          parentObjectId: vars.parentObjectId,
          workspaceId: vars.workspaceId,
          files: vars.files,
          tags: [],
          transcribeable: [],
          replaceExisting: false,
          uploadIdentifier: createId(),
        });
      }
    },
  });

  const runValidations = useCallback(async (files: File[]) => {
    const uploadValidations: ValidationItem[] = [];
    for (const file of files) {
      const result = await validateFile(file);

      if (!result.success) {
        uploadValidations.push({
          fileName: file.name,
          errors: [result.message],
        });
      }
    }

    return uploadValidations;
  }, []);

  const handleReview = useCallback(async (data: UploadFilesForReviewParams) => {

    const uploadValidations = await runValidations(data.files);

    setValidations(uploadValidations);
    if (uploadValidations.length) {
      toggleValidationsModal();
      return;
    }

    const filesMap = data.files.reduce((acc, file) => {
      const identifier = createId();
      acc[identifier] = file;
      return acc;
    }, {});

    return runFileReviewMutation({
      isTranscriptImport: data.isTranscriptImport,
      files: filesMap,
      parentObjectId: data.parentObjectId,
      workspaceId: data.workspaceId,
    });
  }, [runFileReviewMutation, runValidations, toggleValidationsModal]);

  const handleCloseModal = useCallback(() => {
    dispatch({ type: 'modal-dismissed' });
  }, [dispatch]);

  useEffect(() => {
    if (state.uploadInitiated) {
      uploadFiles({
        isTranscriptImport: state.isTranscriptImport,
        parentObjectId: state.parentObjectId,
        workspaceId: state.workspaceId,
        files: state.files,
        replaceExisting: state.replaceExisting,
        tags: state.tags,
        transcribeable: state.transcribeable,
        uploadIdentifier: createId(),
      });
      dispatch({ type: 'upload-queued' });
    }
  }, [
    dispatch,
    state.isTranscriptImport,
    state.uploadInitiated,
    state.parentObjectId,
    state.workspaceId,
    state.files,
    state.replaceExisting,
    state.tags,
    state.transcribeable,
    uploadFiles,
  ]);

  return (
    <InitiateFilesReviewContext.Provider value={handleReview}>
      {props.children}
      {state.showModal && (
        <FileUploadReviewModal
          onClose={handleCloseModal}
          open={state.showModal} />
      )}
      <ValidationsModal items={validations} />
    </InitiateFilesReviewContext.Provider>
  );
};

const WithState = (props: Props) => {
  const dispatch = useContext(FileUploadsDispatchContext);

  const handleInvalidFile = useCallback((file: FileUploadFile) => {
    dispatch({
      type: 'add-invalid-file',
      file,
      reason: `Couldn't upload file`,
    });
  }, [dispatch]);

  return (
    <FileUploadEffectsContainer>
      <UploadFilesContainer onInvalidFile={handleInvalidFile}>
        <FileUploadReviewState>
          <FileUploadReviewContainer {...props} />
        </FileUploadReviewState>
      </UploadFilesContainer>
    </FileUploadEffectsContainer>
  );
};

export { WithState as FileUploadReviewContainer };