import { useCallback, useContext, useMemo } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ProjectFrequency, utils as $enums } from '@enums';
import {
  type SurveySettingsFormValues,
  SurveySettingsFormContext,
  SurveySettingsFormEditingContext,
  SurveySettingsFormRefetchContext,
  UpdateSurveySettingsFormMutationContext,
} from '@containers/Project.SurveySettings';
import { FieldNumber, FieldSelect, FieldSwitch, FieldMoney } from './Field';
import { SectionHeader } from './SectionHeader';
import styles from './style.css';

type FormValues = Omit<SurveySettingsFormValues, 'repeatSurveys'>;

export function SettingsSurvey() {

  const {
    canEdit,
    editing: isEditing,
    toggleEditing,
  } = useContext(SurveySettingsFormEditingContext);
  const refetchForm = useContext(SurveySettingsFormRefetchContext);
  const formValues = useContext(SurveySettingsFormContext);

  const handleReset = useCallback((resetForm: () => unknown) => () => {
    resetForm();
    toggleEditing();
  }, [toggleEditing]);

  const { mutateAsync } = useContext(UpdateSurveySettingsFormMutationContext);

  const handleSubmit = useCallback((values: FormValues) => {
    return mutateAsync(values, {
      onSuccess: () => {
        refetchForm();
        toggleEditing();
      },
    });
  }, [refetchForm, mutateAsync, toggleEditing]);

  return (
    <>
      <Formik<FormValues>
        initialValues={{
          anonymity: formValues.anonymity,
          length: formValues.length || 0,
          externalSourcing: formValues.externalSourcing,
          fixedLinks: formValues.fixedLinks,
          offPlatform: formValues.offPlatform,
          frequency: formValues.frequency ?? ProjectFrequency.None,
          memberReferrals: formValues.memberReferrals,
          charge: formValues.charge || 0,
          payout: formValues.payout || 0,
        }}
        onSubmit={handleSubmit}
        validationSchema={SettingsSchema}
        enableReinitialize={true}>
        {props => (
          <form
            id="settings"
            onSubmit={props.handleSubmit}
            onReset={props.handleReset}>
            <SectionHeader
              title="Settings"
              canEdit={canEdit}
              canSave={props.isValid}
              editing={isEditing}
              onEdit={toggleEditing}
              onReset={handleReset(props.resetForm)}
              onSave={props.handleSubmit} />
            <div className={styles.grid}>
              <div className={styles.section50}>
                <FieldSwitch
                  editing={isEditing}
                  label="Anonymize Survey Participants"
                  name="anonymity"
                  displayValue={props.values.anonymity ? 'Enabled' : 'Disabled'} />
              </div>
              <div className={styles.section50}>
                <FieldSwitch
                  editing={isEditing}
                  label="Expert Referrals"
                  name="memberReferrals"
                  displayValue={props.values.memberReferrals ? 'Enabled' : 'Disabled'} />
              </div>
              <div className={styles.section50}>
                <div className={styles.label}>Off Platform Survey</div>
                {props.values.offPlatform ? 'Enabled' : 'Disabled'}
              </div>
              <div className={styles.section50}>
                <FieldSwitch
                  editing={isEditing}
                  label="Off Platform Sourcing"
                  name="externalSourcing"
                  displayValue={props.values.externalSourcing ? 'Enabled' : 'Disabled'} />
              </div>
              {props.values.offPlatform &&
                <div className={styles.section50}>
                  <FieldSwitch
                    editing={isEditing}
                    label="Fixed Survey Link Assignments"
                    name="fixedLinks"
                    displayValue={props.values.fixedLinks ? 'Enabled' : 'Disabled'} />
                </div>}
              {formValues.repeatSurveys
                ? (
                  <Frequency
                    isEditing={isEditing}
                    value={props.values.frequency} />
                )
                : null}
            </div>
            {/* <div className={styles.section50}>
              <FieldNumber
                editing={isEditing}
                label="Survey Charge"
                name="charge"
                displayValue={`$${info.surveySettings.charge.type === 'default' ? info.surveySettings.charge.value : props.values.charge} (${toTitleCase(info.surveySettings.charge.type)})`} />
            </div> */}
            {/*<div className={styles.section50}>
              <FieldMoney
                editing={isEditing}
                label="Survey Honoraria"
                name="payout"
                displayValue={`$${info.surveySettings.payout.type === 'default' ? info.surveySettings.payout.value : props.values.payout} (${toTitleCase(info.surveySettings.payout.type)})`} />
            </div> */}
            <div className={styles.grid}>
              <div className={styles.section50}>
                <FieldNumber
                  editing={isEditing}
                  label="Estimated Length (Minutes)"
                  name="length"
                  displayValue={formValues.length === null ? 'Not set' : `${formValues.length} Minutes`} />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

type FrequencyProps = {
  isEditing: boolean;
  value: ProjectFrequency;
};

function Frequency({ isEditing, value }: FrequencyProps) {
  const options = useMemo(() => {
    const items = [
      ProjectFrequency.None,
      ProjectFrequency.Weekly,
      ProjectFrequency.Monthly,
      ProjectFrequency.Quarterly,
      ProjectFrequency.AdHoc,
    ];

    return items.reduce<{ [k: string]: string }>((acc, curr) => {
      acc[curr] = $enums.ProjectFrequency.getName(curr);
      return acc;
    }, {});
  }, []);

  return (
    <div className={styles.section}>
      <FieldSelect
        editing={isEditing}
        label="Frequency"
        name="frequency"
        displayValue={$enums.ProjectFrequency.getName(value ?? ProjectFrequency.None)}
        options={options} />
    </div>
  );
}

const SettingsSchema = Yup.object().shape({
  surveyCharge: Yup.number()
    .typeError('Must be a number')
    .min(0, `Must be greater than or equal to $0`)
    .max(1000, `Must be less than or equal to $1000`),
  surveyPayout: Yup.number()
    .typeError('Must be a number')
    .min(0, `Must be greater than or equal to $0`)
    .max(1000, `Must be less than or equal to $1000`),
});