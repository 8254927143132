import Select from 'react-select';
import type { ActionMeta, GetOptionLabel, GetOptionValue, MultiValue } from 'react-select';
import { useCallback, useContext, useMemo, useState } from 'react';
import {
  ProjectClipsFiltersContext,
  ProjectClipsTranscriptSearchContext,
  SetProjectClipsFiltersContext,
} from '@containers/Project.Clips/context';
import { Button } from '@/components/Button';
import styles from './style/Filter.css';

type FilterItem = {
  id: number;
  name: string;
};

type Props = {
  onSubmit: () => void;
};

export const SourcesFilterMenu = ({ onSubmit }: Props) => {

  const transcripts = useContext(ProjectClipsTranscriptSearchContext);

  const filters = useContext(ProjectClipsFiltersContext);
  const setFilters = useContext(SetProjectClipsFiltersContext);

  const [selectedIds, setSelectedIds] = useState<number[]>(filters.transcriptIds);

  const selected = useMemo(() => {
    return transcripts.filter(f => selectedIds.includes(f.id));
  }, [selectedIds, transcripts]);

  const options = useMemo(() => {
    return transcripts;
  }, [transcripts]);

  const handleChange = useCallback((value: MultiValue<FilterItem>, meta: ActionMeta<FilterItem>) => {
    setSelectedIds(value.map(v => v.id));
  }, []);

  const [inputValue, setInputValue] = useState('');

  const handleInputChange = useCallback((newValue: string) => setInputValue(newValue), []);

  const handleNoOptionsMessage = useCallback((obj: { inputValue: string }) => {
    return '';
    // if (tagsQuery.isFetching) return `Loading...`;
    // return obj.inputValue?.length ? `No tags found` : null;
  }, []);

  const handleSubmit = useCallback(() => {
    setFilters({
      transcriptIds: selected.map(s => s.id),
    });
    onSubmit();
  }, [onSubmit, selected, setFilters]);

  const getOptionLabel: GetOptionLabel<FilterItem> = o => o.name;
  const getOptionValue: GetOptionValue<FilterItem> = o => `${o.id}`;

  return (
    <div className={styles.menu}>
      <Select<FilterItem, true>
        components={{
          DropdownIndicator: null,
        }}
        value={selected}
        inputValue={inputValue}
        isMulti
        options={options}
        placeholder={`Search transcripts...`}
        // noOptionsMessage={handleNoOptionsMessage}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        onChange={handleChange}
        onInputChange={handleInputChange} />
      <div className={styles.footer}>
        <Button
          className={styles.submit}
          onClick={handleSubmit}
          variant='brick'
          color='primary'>
          Apply
        </Button>
      </div>
    </div>
  );
};