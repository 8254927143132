import { useMemo, memo } from 'react';
import { cx } from '@utils';
import styles from './style.css';

export type CompanyLogoProps = {
  className?: string;
  pictureUrl: string | null;
  size?: number;
};

type Props = CompanyLogoProps;

export const CompanyLogo = memo((props: Props) => {
  const className = useMemo(() => cx(styles.root, props.className), [props.className]);
  const style = useMemo(() => {
    return props.size ? {
      height: props.size,
      width: props.size,
    } : null;
  }, [props.size]);

  return (
    <div
      className={className}
      style={style}>
      <img
        className={styles.logo}
        src={props.pictureUrl} />
    </div>
  );
});

export default CompanyLogo;
