import { useCallback, useState } from 'react';

export const useCommentReplyState = (initial = false) => {
  const [replying, dispatch] = useState(initial);

  const disable = useCallback(() => {
    dispatch(false);
  }, [dispatch]);

  const enable = useCallback(() => {
    dispatch(true);
  }, [dispatch]);

  const toggle = useCallback(() => {
    dispatch(!replying);
  }, [
    dispatch,
    replying,
  ]);

  const actions = {
    disable,
    enable,
    toggle,
  };

  return [replying, actions] as const;

};