import { memo } from 'react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { shouldForwardProp } from '@/brand-insights/utils/emotion';

type Props = {
  className?: string;
  color?: string;
  size?: number;
};

export const ActivityIndicator = memo(({ className, color, size = 12 }: Props) => {
  return (
    <div className={className}>
      <LeadingDot
        color={color}
        size={size} />
      <Dot
        color={color}
        size={size} />
      <TrailingDot
        color={color}
        size={size} />
    </div>
  );
});

const inflate = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
`;

type DotProps = {
  className?: string;
  color?: string;
  size: number;
};

const Dot = memo(
  styled('span', { shouldForwardProp: shouldForwardProp('color', 'size') })<DotProps>`
    background-color: ${props => props.color || props.theme.palette.blue.dark1};
    height: ${props => props.size}px;
    width: ${props => props.size}px;

    display: inline-block;
    border-radius: 50%;
    margin: 0 7px;
    animation: ${inflate} 1.7s infinite ease-in-out both;
  `
);

const LeadingDot = styled(Dot)({
  animationDelay: '-0.16s',
});

const TrailingDot = styled(Dot)({
  animationDelay: '0.16s',
});