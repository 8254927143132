import { useCallback, useMemo } from 'react';
import type { ActionMeta, OnChangeValue } from 'react-select';
import ReactSelect from 'react-select';
import { cx } from '@utils';
import type * as Details from '$admin/interfaces/project.details';
import { createSelectStyles, createSelectTheme } from '@/components/MedicalProfile/select-styles';
import styles from './style/Popper.css';

type Props = {
  className?:     string;
  defaultValue?:  Details.Honorarium;
  disabled?:      boolean;
  options:        Details.Honorarium[];
  onChange:       (value: Details.Honorarium) => unknown;
  onSelectCreate: () => unknown;
  placeholder?:   string;
  value:          Details.Honorarium;
};

export const Select = ({ defaultValue = CreateNewOption, onChange, onSelectCreate, options = [], value, ...props }: Props) => {
  const config = {
    styles: useMemo(() => createSelectStyles(), []),
    theme: useMemo(() => createSelectTheme(), []),
  };

  const handleChange = useCallback((option: OnChangeValue<Details.Honorarium, false>, meta: ActionMeta<Details.Honorarium>) => {
    if (option.id === CreateNewOption.id) {
      onSelectCreate();
    } else {
      onChange(option);
    }
  }, [
    onChange,
    onSelectCreate,
  ]);

  return (
    <ReactSelect<Details.Honorarium>
      className={cx(styles.select, props.className)}
      defaultValue={defaultValue}
      getOptionLabel={x => x.title}
      getOptionValue={x => String(x.id)}
      isDisabled={props.disabled}
      noOptionsMessage={() => `No options found.`}
      onChange={handleChange}
      options={options.concat(CreateNewOption)}
      placeholder={props.placeholder}
      styles={config.styles}
      theme={config.theme}
      value={options.find(x => x.id === value?.id)} />
  );
};

Select.displayName = 'Project.Share.Field.Select';

const CreateNewOption = {
  currency: null,
  id: 0,
  title: `Create New`,
  value: null,
};