import qs from 'query-string';
import http from '@services/http';
import type * as Projects from './interfaces/projects.transcripts';

export const getUsersToNotify = (data: Projects.GetUsersToNotify.Request) => {
  return http.get<Projects.GetUsersToNotify.Response>(`/projects/${data.projectId}/transcripts/users-to-notify`);
};

export const notifyUsers = ({ projectId, ...body }: Projects.NotifyUsers.Request) => {
  return http.post(`/projects/${projectId}/transcripts/notify-users`, body);
};

export const getProjectTerms = (data: Projects.GetProjectTerms.Request) => {
  return http.get<Projects.GetProjectTerms.Response>(`/projects/${data.projectId}/transcripts/terms`);
};

export const getTranscriptHighlights = ({ projectId, ...data }: Projects.GetTranscriptsTermHighlights.Request) => {
  return http.get<Projects.GetTranscriptsTermHighlights.Response>(`/projects/${projectId}/transcripts/term-highlights?${qs.stringify(data)}`);
};

export const saveTranscriptReplacements = ({ projectId, ...body }: Projects.SaveTranscriptReplacements.Request) => {
  return http.post<Projects.SaveTranscriptReplacements.Response>(`/projects/${projectId}/transcripts/replacements`, body);
};

export const getSpellCheckSuggestions = ({ projectId, term }: Projects.GetSpellCheckSuggestions.Request) => {
  return http.get<Projects.GetSpellCheckSuggestions.Response>(`/projects/${projectId}/transcripts/spell-check?term=${encodeURIComponent(term)}`);
};