import { useMemo, useCallback } from 'react';
import { SurveyLogicRule, utils as enumUtils } from '@enums';
import DropDown from '@/components/DropDown';

export const LogicRuleDropdown = ({
  disabled,
  items = enumUtils.SurveyLogicRule.values(),
  onSelect,
  value,
}: Props) => {

  const getItemValue = useCallback((item: DropdownItem) => {
    return enumUtils.SurveyLogicRule.getName(item.id);
  }, []);

  const handleSelect = useCallback((item: DropdownItem) => {
    onSelect(item.id);
  }, [onSelect]);

  const mappedItems = useMemo(() => {
    return items.map(m => ({ id: m }));
  }, [items]);

  const text = value !== null
    ? enumUtils.SurveyLogicRule.getName(value)
    : null;

  return (
    <DropDown
      disabled={disabled}
      placeholder="Select rule"
      items={mappedItems}
      getItemValue={getItemValue}
      onSelect={handleSelect}
      text={text} />
  );
};

type DropdownItem = {
  id: SurveyLogicRule;
};

type Props = {
  disabled?: boolean;
  items?: SurveyLogicRule[];
  onSelect: (item: SurveyLogicRule) => void;
  value: SurveyLogicRule;
};

export default LogicRuleDropdown;