import { Fragment, useCallback, useEffect, useMemo, useState, memo } from 'react';
import type { LocalVideoTrack } from 'twilio-video';
import { useTwilioVideo } from '@containers/Conference';
import { cx } from '@utils';
import { useConferenceCameraPreferences } from '@/components/Conference/hooks/useConferenceCameraPreferences';
import { isBlurBackgroundSupported } from '@/components/Conference.Video/video-processors';
import { VideoTrack } from '@/components/Conference.Video';
import { Switch } from '@/components/Switch';
import styles from './style/Camera.Preview.css';

type Props = {
  className?: string;
};

export function CameraPreview({ className }: Props) {
  const [error, setError] = useState<Error>();
  const { previewTrack, enablePreview, disablePreview } = useTwilioVideo();

  useEffect(() => {
    enablePreview()
    .catch(err => {
      const error = err as Error;
      console.error(error);
      setError(error);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      disablePreview();
    };
  }, [disablePreview]);

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.title}>Preview</div>
      <div className={styles.content}>
        {error
          ? <CameraError error={error} />
          : <Camera track={previewTrack} />}
      </div>
    </div>
  );
}

type CameraProps = {
  track: LocalVideoTrack;
};

const Camera = memo(({ track }: CameraProps) => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (track) {
      const timeout = setTimeout(() => setLoading(false), 750);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [track]);

  return (
    <Fragment>
      {loading && <CameraLoading />}
      {!loading && !!track && (
        <div className={styles.previewTrack}>
          <VideoTrack track={track} isLocal />
        </div>
      )}
      {!loading && track && <BlurBackground />}
    </Fragment>
  );
});

const CameraLoading = memo(() => {
  return (
    <div
      style={{ height: 45, width: 45 }}
      className={styles.spinner} />
  );
});

type CameraErrorProps = {
  error: Error;
};

const CameraError = memo(({ error }: CameraErrorProps) => {
  const text =
    error?.message.includes('not allowed') || error?.message.includes('denied')
      ? 'Web browser denied request to use camera.'
      : 'Could not render camera preview.';

  return (
    <div className={styles.error}>
      {text}
    </div>
  );
});

const BlurBackground = () => {
  const [cameraPreferences, updateCameraPreferences] = useConferenceCameraPreferences();
  const isCameraBlurSupported = useMemo(() => isBlurBackgroundSupported(), []);

  const handleBlurChange = useCallback(() => {
    updateCameraPreferences({
      blurBackground: !cameraPreferences.blurBackground,
    });
  }, [cameraPreferences.blurBackground, updateCameraPreferences]);

  if (!isCameraBlurSupported) return null;

  return (
    <div className={styles.blurContainer}>
      <Switch
        className={styles.blurSwitch}
        active={cameraPreferences.blurBackground}
        onClick={handleBlurChange} />
      <div className={styles.blurLabel}>Blur background</div>
    </div>
  );
};