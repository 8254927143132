import { useCallback, useContext } from 'react';
import {
  QuantAnalysisItemsContext,
  SelectedQuantAnalysisItemContext,
  SetSelectedQuantAnalysisItemContext,
} from '@containers/Project.QuantAnalysis';
import { cx } from '@utils';
import type { QuantAnalysisItem } from '@/types';
import { QuantAnalysesPanelItem } from './AnalysesList.Item';
import styles from './style/AnalysesList.css';

type Props = {
  className?: string;
};

export const AnalysesPanel = (props: Props) => {

  const items = useContext(QuantAnalysisItemsContext);
  const selectedItemId = useContext(SelectedQuantAnalysisItemContext);
  const setSelectedItemId = useContext(SetSelectedQuantAnalysisItemContext);

  const handleItemSelected = useCallback((id: number) => () => {
    setSelectedItemId(id);
  }, [setSelectedItemId]);

  const renderItem = useCallback((item: QuantAnalysisItem) => {
    const isSelected = item.id === selectedItemId;
    const handleSelect = handleItemSelected(item.id);

    return (
      <QuantAnalysesPanelItem
        key={item.id}
        className={styles.item}
        name={item.name}
        selected={isSelected}
        onSelect={handleSelect} />
    );
  }, [selectedItemId, handleItemSelected]);

  return (
    <div className={cx(styles.root, props.className)}>
      {items.map(renderItem)}
    </div>
  );
};