type Props = {
  className?: string;
  size?: number;
};

const Building = ({
  className,
  size = 24,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill="#626262"
    width={size}
    height={size}
    viewBox="0 0 24 24">
    <path fill="none" d="M0 0h24v24H0V0z"/>
    <path d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z"/>
  </svg>
);

export { Building };
export default Building;