import { memo } from 'react';
import { FormButtons, type FormButtonsProps } from '@presentation/FormButtons';
import Edit from '@/components/icons/Edit';
import { SectionHeader } from './SectionHeader';

type Props = {
  className?:  string;
  disabled?:   boolean;
  editable?:   boolean;
  editing:     boolean;
  onEdit:      () => void;
  onReset:     () => void;
  onSave:      () => void;
  submitting?: boolean;
  title:       string;
};

type EditProps = {
  onClick: () => void;
};

export const SectionEditButton = memo(({ onClick }: EditProps) => {
  return (
    <Edit
      size={14}
      onClick={onClick} />
  );
});

type FormProps =
  Pick<FormButtonsProps, 'disabled' | 'onCancel' | 'onSubmit'>;

export const SectionFormButtons = memo((props: FormProps) => {
  return (
    <FormButtons
      size={14}
      archivable={false}
      disabled={props.disabled}
      deletable={false}
      onCancel={props.onCancel}
      onSubmit={props.onSubmit} />
  );
});

const SectionFormHeader = (props: Props) => {

  const Buttons = () => {
    if (!props.editing) {
      return (
        <SectionEditButton
          onClick={props.onEdit} />
      );
    }

    return (
      <SectionFormButtons
        disabled={props.disabled || props.submitting}
        onCancel={!props.submitting ? props.onReset : undefined}
        onSubmit={props.onSave} />
    );
  };

  return (
    <SectionHeader
      className={props.className}
      title={props.title}>
      <Buttons />
    </SectionHeader>
  );
};

export { SectionFormHeader };
export default SectionFormHeader;