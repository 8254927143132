import { format } from 'date-fns';
import { getCurrentTimezone, getLocationFor, phone } from '@utils';
import styles from './style/Confirmation.css';

type Props = {
  end:   Date;
  start: Date;
};

export const Time = (props: Props) => {

  return (
    <div className={styles.time}>
      {formatCallTime(props.start, props.end)}
    </div>
  );
};

Time.displayName = 'Confirmation.Details.Time';

function formatCallTime(start: Date, end: Date) {
  if (!start || !end) return '';

  const pt1 = format(start, 'EEE MMM d, h:mm b');
  const pt2 = format(end, 'h:mm b');

  return `${pt1} to ${pt2} ${getCurrentTimezone()}`;
}