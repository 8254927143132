import CloseIcon from '@mui/icons-material/Close';
import styles from './style/Tool.css';

type Props = {
  onClose: () => void;
  title: string;
};

export const Header = ({ title, onClose }: Props) => {
  return (
    <div className={styles.header}>
      <div className={styles.bar}>
        <div className={styles.title}>{title}</div>
        {onClose &&
          <button
            className={styles.btn}
            onClick={onClose}>
            <CloseIcon />
          </button>
        }
      </div>
    </div>
  );
};