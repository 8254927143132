import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { ProjectStateContext } from '@containers/GroupProject/Context';
import { getLocationFor } from '@utils';
import { Button } from '@/components/Button';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import { Header } from '@/components/Modal/Header';
import { Lightbulb } from '@/components/icons';
import { TagRowsGraphic } from './TagRowsGraphic';
import styles from './style/Modal.SetupTags.css';

type Props = Pick<ModalProps, 'onClose' | 'open'>;

export const SetupTags = ({
  onClose,
  open,
}: Props) => {
  const history = useHistory();
  const { project } = useContext(ProjectStateContext);

  const handleNavigate = useCallback(() => {
    history.push(getLocationFor.project.tagsOnboarding({
      projectId: project.id,
    }));
    onClose();
  }, [
    history,
    onClose,
    project,
  ]);

  return (
    <Modal
      className={styles.modal}
      disableEscapeClose
      disableOverlayClick
      open={open}
      onClose={onClose}>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <Header className={styles.title}>
            {copy.title}
          </Header>

          <div className={styles.main}>
            <div className={styles.subtitle}>{copy.subtitle}</div>

            <div className={styles.content}>
              <div className={styles.graphic}>
                <TagRowsGraphic />
              </div>

              <div className={styles.icon}>
                <ArrowForwardIosRoundedIcon />
              </div>

              <div className={styles.bulb}>
                <Lightbulb size={30} />
              </div>
            </div>
          </div>

          <div className={styles.footer}>
            <Button
              className={styles.btn}
              onClick={handleNavigate}
              variant="brick">
              Continue
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

SetupTags.displayName = 'Modal.SetupTags';

const copy = {
  subtitle: `Set up tags to structure quotes from interviews and access Generative AI powered transcript summaries.`,
  title: `Set up your interview tags`,
};