import { useCallback } from 'react';
// import { useProjectOrLatestChild } from '@containers/GroupProject/hooks/useProjectState';
import { ViewUserResponseItem } from '@containers/SurveyResponses/interfaces';
// import { useActiveQuestion } from './useActiveQuestion';

export const useTrackViewResponse = () => {
  // const project = useProjectOrLatestChild();
  // const question = useActiveQuestion();

  const handleViewResponse = useCallback((user: ViewUserResponseItem) => () => {
    // note: placeholder
  }, []);

  return handleViewResponse;
};

export default useTrackViewResponse;