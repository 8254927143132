import { useCallback, useState } from 'react';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { usePopper } from 'react-popper';
import { MoreVerticalAnchorSmall } from '@presentation/Anchor';
import { cx } from '@utils';
import { useZIndexModifier, PopperMenu } from '@/components/Popper';
import { Portal } from '@/components/Portal';
import type { ContextMenuItemProps } from './ContextMenu.Item';
import { ContextMenuItem } from './ContextMenu.Item';

type Props = {
  className?: string;
  items: ContextMenuItemProps[];
};

export const SurveyBuilderContextMenu = (props: Props) => {

  const [open, setOpen] = useState(false);

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);

  const zIndexModifier = useZIndexModifier({ zIndex: 8 });

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      zIndexModifier,
    ],
    placement: 'bottom-start',
  });

  const handleBlur = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  if (!props.items.length) return null;

  return (
    <>
      <div
        className={props.className}
        ref={setReferenceElement}
        onClick={handleClick}>
        <MoreVerticalAnchorSmall
          open={open} />
      </div>
      {open &&
        <Portal>
          <ClickAwayListener
            mouseEvent='onMouseDown'
            onClickAway={handleBlur}
            touchEvent='onTouchStart'>
            <div
              ref={setPopperElement}
              style={popperStyles.popper}
              {...attributes.popper}>
              <PopperMenu>
                {props.items.map((m, i) => {
                  return (
                    <ContextMenuItem
                      key={i}
                      {...m}
                      onClick={() => {
                        m.onClick();
                        setOpen(false);
                      }} />
                  );
                })}
              </PopperMenu>
            </div>
          </ClickAwayListener>
        </Portal>
      }
    </>
  );
};

export * from './ContextMenu.Item';