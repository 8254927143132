import extend from '@enums/extend';

export enum HCPType {
  Academic = 1,
  Community = 2,
}

const map = new Map([
  [HCPType.Academic, { name: `Academic` }],
  [HCPType.Community, { name: `Community` }],
]);

export default {
  HCPType: extend(map),
};