import { useCallback, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import { GroupProjectTagsContainer } from '@/containers/GroupTags';
import { useFetchProjectGoal } from '@utils/api';
import { ProjectTabDetails } from '@/scenes/projects/Project';
import { GroupProjectGoalContext, ProjectDetailsContext, SaveProjectOverviewContext } from './Context';
import { useProjectOrLatestChild } from './hooks';
import type { ProjectOverviewFormState } from './interfaces';

export const ProjectDetailsContainer = () => {
  const project = useProjectOrLatestChild();

  const [goalResponse, fetchProjectGoal] = useFetchProjectGoal();
  const dispatch = useDispatch();

  const { query } = useContext(ProjectDetailsContext);

  useEffect(() => {

    fetchProjectGoal(project.id);
  }, [
    fetchProjectGoal,
    project.id,
  ]);

  const saveOverview = useCallback((form: ProjectOverviewFormState) => {
    return api.projects.updateProjectOverview({
      accountId: form.accountId,
      clientName: form.clientName,
      description: form.description,
      frequency: form.frequency,
      goal: form.goal,
      name: form.name,
      netsuiteId: form.netsuite?.id,
      projectId: project.id,
      projectType: project.projectType,
      purchaseOrder: form.purchaseOrder,
    })
      .then(async data => {

        await query.refetch();

        dispatch(actions.batchActions([
          actions.projectOverviewUpdated({
            object: data.object,
            projectId: project.id,
            project: {
              name: data.project.name,
              description: data.project.description,
            },
          }),
          actions.projectParentUpdated({
            parent: data.projectParent,
          }),
        ]));

        return data;
      });
  }, [
    dispatch,
    project.id,
    project.projectType,
    query,
  ]);

  return (
    <GroupProjectGoalContext.Provider value={goalResponse?.value}>
      <SaveProjectOverviewContext.Provider value={saveOverview}>
        <GroupProjectTagsContainer projectParentId={project.parentId}>
          <ProjectTabDetails />
        </GroupProjectTagsContainer>
      </SaveProjectOverviewContext.Provider>
    </GroupProjectGoalContext.Provider>
  );
};

export default ProjectDetailsContainer;