import { forwardRef, useMemo } from 'react';
import { cx } from '@utils';
import { TextareaProps } from './interfaces';
import styles from './style.css';
import Textarea from './Textarea';

export const TextAreaCharacterMinimum = forwardRef<HTMLTextAreaElement, MinimumProps>(({
  minLength,
  ...props
}, ref) => {

  const remainingUntilValid = useMemo(() => {
    return minLength - (props.value as string).length;
  }, [minLength, props.value]);

  const hasTooFewCharacters = useMemo(() => {
    return remainingUntilValid > 0;
  }, [remainingUntilValid]);

  const count = hasTooFewCharacters && `${(props.value as string).length}/${minLength}`;

  return (
    <TextAreaWithCharacters
      ref={ref}
      count={count}
      {...props} />
  );
});

export const TextareaCharacterLimit = forwardRef<HTMLTextAreaElement, LimitProps>(({
  disabled = false,
  maxLength,
  ...props
}, ref) => {

  const hasTooManyCharacters = useMemo(() => {
    return (props.value as string).length > maxLength;
  }, [maxLength, props.value]);

  const count = (maxLength - (props.value as string).length)?.toString();

  const countClass = cx({
    [styles.warning]: hasTooManyCharacters,
  });

  return (
    <TextAreaWithCharacters
      ref={ref}
      classes={{
        count: countClass,
      }}
      count={count}
      {...props} />
  );
});

const TextAreaWithCharacters = forwardRef<HTMLTextAreaElement, BaseProps>(({
  classes = {},
  count,
  ...props
}, ref) => {

  const countClass = cx(styles.count, classes.count);

  const rootClass = cx(styles.limitRoot, classes.root);
  const textareaClass = cx(classes.textarea);

  return (
    <div className={rootClass}>
      <Textarea
        ref={ref}
        className={textareaClass}
        {...props} />
      <div className={countClass}>
        {count}
      </div>
    </div>
  );
});

type BaseProps = {
  classes?: ClassProps;
  count: string;
} & Omit<TextareaProps, 'className'>;

type ClassProps = {
  count?: string;
  root?: string;
  textarea?: string;
};

type MinimumProps = {
  classes?: ClassProps;
  minLength: number;
} & Omit<TextareaProps, 'className'>;

type LimitProps = {
  maxLength: number;
} & TextareaProps;