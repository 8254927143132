import { createNamedContext } from '@utils';
import { ConfirmationStepperContextValue } from './interfaces';

export const ConfirmationStepperContext = createNamedContext<ConfirmationStepperContextValue>({
  back:         () => {},
  callId:       null,
  day:          null,
  events:       {},
  next:         () => {},
  onSubmit:     (x => {}) as ConfirmationStepperContextValue['onSubmit'],
  setDay:       () => {},
}, 'Calendar.ConfirmationStepper.Context');