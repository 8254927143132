import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { path } from '@consts';
import { ProjectType } from '@enums';
import { trunc } from '@utils';
import { useIsInternalUser } from '@/containers/Store';
import { TabTitle as TagsTitle } from '@screens/Project.Tags/TabTitle';
import { TabTitle as EntitiesTitle } from '@screens/Project.Entities/TabTitle';
import { ProjectQuantAnalysesContext, ProjectDisplayrDashboardIdContext, ProjectTabsContext } from './Context';
import type { TabRouteItem } from './interfaces';
import { RouteKey } from './interfaces';
import { useProjectOrLatestChild } from './hooks';

const selectShowProjectFiles = (state: Store.State) => state.group.features.projectFiles;
const selectProjectTabSettings = (state: Store.State) => state.group.features.projectTabs;

type Props = ChildrenProps;

export const ProjectTabsContainer = (props: Props) => {
  const project = useProjectOrLatestChild();
  const showProjectFiles = useSelector(selectShowProjectFiles);
  const tabSettings = useSelector(selectProjectTabSettings);
  const isInternalUser = useIsInternalUser();
  const displayrDashboardId = useContext(ProjectDisplayrDashboardIdContext);
  const quantAnalyses = useContext(ProjectQuantAnalysesContext);

  const showPipeline = useMemo(() => {
    return isInternalUser;
  }, [isInternalUser]);

  const showDetails = useMemo(() => {
    return true;
  }, []);

  const showTags = useMemo(() => {
    return tabSettings.tags;
  }, [tabSettings.tags]);

  const showSurveyResponses = useMemo(() => {
    if (project?.projectType === ProjectType.SurveyCall && !isInternalUser) return false;

    return tabSettings.surveyResponses;
  }, [isInternalUser, project?.projectType, tabSettings.surveyResponses]);

  const showDisplayrDashboard = useMemo(() => {
    return !!displayrDashboardId;
  }, [displayrDashboardId]);

  const showSummary = useMemo(() => {
    return tabSettings.summary;
  }, [tabSettings.summary]);

  const showHighlights = useMemo(() => {
    return tabSettings.highlights;
  }, [tabSettings.highlights]);

  const showEntities = useMemo(() => {
    return tabSettings.entities;
  }, [tabSettings.entities]);

  const showScheduling = useMemo(() => {
    return isInternalUser && tabSettings.scheduling;
  }, [
    isInternalUser,
    tabSettings.scheduling,
  ]);

  const visibleRoutes = useMemo(() => {
    type VisibilityMap = Record<RouteKey, boolean>;
    const configRoutes = projectTypeRoutes[project?.projectType];

    const visibilityMap: VisibilityMap = {
      [RouteKey.Dashboard]: showDisplayrDashboard,
      [RouteKey.Pipeline]: showPipeline,
      [RouteKey.SurveyResponses]: showSurveyResponses,
      [RouteKey.Files]: showProjectFiles,
      [RouteKey.Details]: showDetails,
      [RouteKey.Tags]: showTags,
      [RouteKey.Calls]: showScheduling,
      [RouteKey.Summary]: showSummary,
      [RouteKey.Highlights]: showHighlights,
      [RouteKey.Entities]: showEntities,
    };

    const routeKeys = configRoutes.filter(f => visibilityMap[f]);

    return routeKeys;
  }, [
    showDisplayrDashboard,
    showPipeline,
    showSurveyResponses,
    showProjectFiles,
    showDetails,
    showTags,
    showSummary,
    showHighlights,
    showEntities,
    showScheduling,
    project?.projectType,
  ]);

  const quantAnalysisRoutes = useMemo(() => {
    if (!quantAnalyses) return [];

    return quantAnalyses.map(qa => ({
      key: `quant-analysis/${qa.id}`,
      path: path.ProjectTabs.QuantAnalysis,
      title: trunc(qa.name, 20),
    }));
  }, [quantAnalyses]);

  const routeItems = useMemo(() => {
    const baseRoutes = visibleRoutes.map(route => routeDetailsMap[route]);
    return [...baseRoutes, ...quantAnalysisRoutes];
  }, [quantAnalysisRoutes, visibleRoutes]);

  return (
    <ProjectTabsContext.Provider value={routeItems}>
      {props.children}
    </ProjectTabsContext.Provider>
  );
};

const projectTypeRoutes = {
  [ProjectType.Call]: [
    RouteKey.Pipeline,
    RouteKey.Calls,
    RouteKey.Summary,
    RouteKey.Highlights,
    RouteKey.Tags,
    RouteKey.Entities,
    RouteKey.Files,
    RouteKey.Details,
  ],

  [ProjectType.Survey]: [
    RouteKey.Dashboard,
    RouteKey.Pipeline,
    RouteKey.SurveyResponses,
    RouteKey.Files,
    RouteKey.Details,
  ],

  [ProjectType.SurveyCall]: [
    RouteKey.Dashboard,
    RouteKey.Pipeline,
    RouteKey.SurveyResponses,
    RouteKey.Calls,
    RouteKey.Summary,
    RouteKey.Highlights,
    RouteKey.Tags,
    RouteKey.Entities,
    RouteKey.Files,
    RouteKey.Details,
  ],
};

const routeDetailsMap: Record<RouteKey, TabRouteItem> = {
  [RouteKey.Calls]: {
    key: RouteKey.Calls,
    path: path.ProjectTabs.Calls,
    title: 'Scheduling',
  },
  [RouteKey.Dashboard]: {
    key: RouteKey.Dashboard,
    path: path.ProjectTabs.Dashboard,
    title: 'Dashboard',
  },
  [RouteKey.Details]: {
    key: RouteKey.Details,
    path: path.ProjectTabs.Details,
    title: 'Details',
  },
  [RouteKey.Entities]: {
    key: RouteKey.Entities,
    path: path.ProjectTabs.Entities,
    title: <EntitiesTitle />,
  },
  [RouteKey.Highlights]: {
    key: RouteKey.Highlights,
    path: path.ProjectTabs.Highlights,
    title: 'Highlights',
  },
  [RouteKey.Pipeline]: {
    key: RouteKey.Pipeline,
    path: path.ProjectTabs.Pipeline,
    title: 'Pipeline',
  },
  [RouteKey.Summary]: {
    key: RouteKey.Summary,
    path: path.ProjectTabs.Summary,
    title: 'Summary',
  },
  [RouteKey.SurveyResponses]: {
    key: RouteKey.SurveyResponses,
    path: path.ProjectTabs.SurveyResponses,
    title: 'Survey Responses',
  },
  [RouteKey.Tags]: {
    key: RouteKey.Tags,
    path: path.ProjectTabs.Tags,
    title: <TagsTitle />,
  },
  [RouteKey.Files]: {
    key: RouteKey.Files,
    path: path.ProjectTabs.Files,
    title: 'Files',
  },
};