import { SurveyTaggingResultType } from '@enums';
import { SurveyTagging } from '@/types';

export function generateDefaultTaggingAction(type: SurveyTaggingResultType): SurveyTagging.Action {
  if (type === SurveyTaggingResultType.Classification) {
    return {
      type: SurveyTaggingResultType.Classification,
      value: {
        identifier: null,
      },
    };
  }

  return null;
}