import { Fragment, memo, useMemo } from 'react';
import type { MatrixGridQuestion } from '@/types/survey';
import styles from './style/MatrixGrid.css';
import { Rationale } from './Rationale';

type Props = {
  answer: MatrixGridQuestion.Answer.Value;
  rationale: string;
};

const sortOptions = (a: MatrixGridQuestion.Answer.Item, b: MatrixGridQuestion.Answer.Item) => {
  const optionA = a.selected[0];
  const optionB = b.selected[0];

  return optionA.isNotApplicable
    ? 1
    : optionB.isNotApplicable
      ? -1
      : optionB.id > optionA.id
        ? -1
        : 1
    ;
};

type ItemProps = {
  item: MatrixGridQuestion.Answer.Item;
};

const MatrixGridAnswerOption = memo(({ item }: ItemProps) => {
  const openEndedRowValue = item.group.openEndedValue
    ? `[${item.group.openEndedValue}]`
    : '';

  const answerValue = `${item.selected[0].value} - ${item.group.value}`;

  const joined = [answerValue, openEndedRowValue].join(' ');

  return (
    <div className={styles.item}>
      {joined}
    </div>
  );
});

export const MatrixGridAnswerValue = ({ answer, rationale }: Props) => {
  const sortedItems = useMemo(() => {
    return answer.items
      .filter(f => f.selected.length > 0)
      .sort(sortOptions);
  }, [answer.items]);

  return (
    <>
      {sortedItems.map(item => {
        return (
          <Fragment key={item.group.id}>
            <MatrixGridAnswerOption item={item} />
          </Fragment>
        );
      })}
      <Rationale value={rationale} />
    </>
  );
};

export default MatrixGridAnswerValue;