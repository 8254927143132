import { useCallback } from 'react';
import { FieldChangeHandler } from '@mui/x-date-pickers/internals';
import { TimeField, TimeFieldProps } from '@mui/x-date-pickers/TimeField';
import { TimeValidationError } from '@mui/x-date-pickers';

type Props = {
  onChange: (value: Date) => void;
  value: Date;
} & Pick<TimeFieldProps<unknown>, 'format' | 'onChange'>;

// TODO: Customize styles
// TODO: Figure out changing selections

export const TimePicker = ({
  format = 'HH:mm:ss',
  value,
  onChange,
}: Props) => {

  const handleChange: FieldChangeHandler<unknown, TimeValidationError> = useCallback((value, context) => {
    onChange(value as Date);
  }, [onChange]);

  return (
    <TimeField
      sx={{ width: '75px', fontFamily: 'var(--font-reg)' }}
      inputProps={{ style: { fontFamily: 'var(--font-reg)' } }}
      formatDensity="spacious"
      variant="standard"
      size="small"
      format={format}
      value={value}
      onError={(e, v) => console.log('onError', e, v)}
      onChange={handleChange} />
  );
};

export default TimePicker;