import { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import { useSelectUser } from '@containers/Store';
import { useAsyncStateLazy } from '@utils';
import ActivityIndicator from '@/components/ActivityIndicator';
import { useResolveRedirect } from './hooks';

type Params = {
  token: string;
};

type Props = {
  children: React.ReactNode;
};

const EmailVerificationContainer = (props: Props) => {
  const params = useParams<Params>();
  const dispatch = useDispatch();
  const [redirect, resolveRedirect] = useResolveRedirect();
  const user = useSelectUser();

  const [responseRefresh, fetchRefresh] = useAsyncStateLazy(() => {
    return api.users.verify.refreshEmailVerification({ userId: user.id })
    .then(data => {

      const action = actions.userInfoChange({
        meta: {
          emailReverificationRequired: data.emailReverificationRequired,
          isEmailVerified: data.emailVerified,
        },
      });

      dispatch(action);
    });
  });

  const [responseVerify, fetchVerify] = useAsyncStateLazy(() => {
    return api.users.verify.verifyEmailToken({
      token: params.token,
      userId: user.id,
    })
    .then(data => {
      const action = actions.userInfoChange({
        meta: {
          isEmailVerified: data.emailVerified,
        },
      });
      dispatch(action);
    });
  });

  useEffect(() => {
    if (params.token) {
      fetchVerify();
    }
  }, [
    params.token,
    fetchVerify,
  ]);

  useEffect(() => {
    if (user.meta.isEmailVerified) {
      resolveRedirect();
    } else {
      fetchRefresh();
    }
  }, [
    fetchRefresh,
    resolveRedirect,
    user.meta.isEmailVerified,
    user.state.initialized,
  ]);

  if (redirect) return <Redirect to={redirect} />;

  if (responseVerify.loading || responseRefresh.loading || !!redirect) return <ActivityIndicator show />;

  return props.children as JSX.Element;
};

export { EmailVerificationContainer };
export default EmailVerificationContainer;