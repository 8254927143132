import { useCallback } from 'react';
import type { Participant } from 'twilio-video';
import { useTwilioVideo } from '@containers/Conference';
import { useParticipants } from '@/components/Conference.Video/hooks';
import { SessionParticipantStripParticipant } from './Session.ParticipantStrip.Participant';
import { useMainSpeaker, useSelectedParticipant } from './hooks';
import styles from './style/Session.ParticipantStrip.css';

export function SessionParticipantStrip() {
  const twilio = useTwilioVideo();
  const mainSpeaker = useMainSpeaker(twilio.room);

  const participants = useParticipants(twilio.room);
  const [selected, setSelected] = useSelectedParticipant();

  const handleSelection = useCallback((particpant: Participant) => () => setSelected(particpant), [setSelected]);

  return (
    <div className={styles.root}>
      <SessionParticipantStripParticipant
        disableAudio
        isSelected={selected === twilio.room.localParticipant}
        onClick={handleSelection(twilio.room.localParticipant)}
        participant={twilio.room.localParticipant}
        mainSpeakerSid={mainSpeaker?.sid}
        room={twilio.room} />
      {participants.map(participant => (
        <SessionParticipantStripParticipant
          key={participant.sid}
          isSelected={selected === participant}
          onClick={handleSelection(participant)}
          participant={participant}
          mainSpeakerSid={mainSpeaker?.sid}
          room={twilio.room} />
      ))}
    </div>
  );
}