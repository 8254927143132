import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import * as api from '@api';
import * as API from '@api/interfaces';
import * as QK from '@consts/querykey';

namespace Mutation {
  export type Variables = API.Posts.Articles.DeleteCoverImage.Request;

  export type Options =
    UseMutationOptions<
      API.Posts.Articles.DeleteCoverImage.Response,
      unknown,
      Variables>;
}

export const useDeleteCoverImageMutation = (options: Mutation.Options = {}) => {
  const mutation = useMutation([QK.Posts.Articles.Attachments.CoverImage.Delete], (params: Mutation.Variables) => {
    return api.posts.articles.deleteCoverImage({
      postId: params.postId,
    });
  }, options);

  return mutation;
};