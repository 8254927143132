import { memo } from 'react';
import { Validation } from '@containers/SurveyBuilder.Logic/interfaces';
import styles from './style/Builder.Validation.css';

type Props = {
  value: Validation;
};

export const LogicValidation = memo(({ value }: Props) => {

  if (!value) return null;

  if (value.success === true) return null;

  return (
    <div className={styles.root}>{value.message}</div>
  );
});