export const brightness = (hex: string, pct: number) => {

  const prefixed = hex.startsWith('#');

  const value = prefixed
      ? hex.slice(1)
      : hex;

  const [r1, g1, b1] = hexToRGBArray(value);

  const r = numToHex(r1 + pct);
  const g = numToHex(g1 + pct);
  const b = numToHex(b1 + pct);

  const unprefixed = `${r}${g}${b}`;

  return prefixed
    ? `#${unprefixed}`
    : unprefixed;
};

const clamp = (value: number) => {
  return value > 255
    ? 255
    : value < 0
      ? 0
      : value;
};

const numToHex = (value: number) => {
  const hex = clamp(value).toString(16);

  return hex.length === 1
    ? `0${hex}`
    : hex;
};

export const isDark = (hex: string) => {
  const prefixed = hex.startsWith('#');

  const value = prefixed
      ? hex.slice(1)
      : hex;

  const red = parseInt(value.substring(0, 2), 16);
  const green = parseInt(value.substring(2, 4), 16);
  const blue = parseInt(value.substring(4, 6), 16);
  const brightness = red * 0.299 + green * 0.587 + blue * 0.114;

  return brightness <= 180;
};

const hexToRGBArray = (hex: string) => {
  const prefixed = hex.startsWith('#');

  const value = prefixed
      ? hex.slice(1)
      : hex;

  const r = parseInt(value.substring(0, 2), 16);
  const g = parseInt(value.substring(2, 4), 16);
  const b = parseInt(value.substring(4, 6), 16);

  return [r, g, b] as const;
};