import { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import * as $session from '@services/auth/session';
import { RehydrateAppContext } from './Context';

export const RehydrationContainer = (props: ChildrenProps) => {
  const dispatch = useDispatch();
  const loading = useRef(false);

  const rehydrateApp = useCallback(() => {

    if (!loading.current) {
      loading.current = true;
      return api.rehydrateApp()
        .then(data => {
          if (data.session) {
            $session.set(data.session);
          }
          dispatch(actions.appStateRehydrated(data));
          loading.current = false;
        }).catch(e => {
          loading.current = false;
          throw e;
        });
    }
  }, [
    dispatch,
  ]);

  return (
    <RehydrateAppContext.Provider value={rehydrateApp}>
      {props.children}
    </RehydrateAppContext.Provider>
  );
};

export default RehydrationContainer;