import { path } from '@consts';
import { LinkLabeledIcon } from '$website/components';
import c1 from 'static/images/website/customer-research/collab-commenting-desktop.png';
import c2 from 'static/images/website/customer-research/collab-document-upload-desktop.png';
import styles from './style/CollaborationTools.css';

type Props = unknown;

export const CollaborationTools = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h2 className={styles.header}>
          We help our customers sync their products to their market and maintain alignment from design to launch.
        </h2>
        <div className={styles.main}>
          {items.map(x =>
            <div
              className={styles.row}
              key={x.title}>
              <div className={styles.item}>
                <div className={styles.box}>
                  <img
                    alt={x.alt}
                    className={styles.img}
                    src={x.src} />

                  {x.url &&
                    <LinkLabeledIcon
                      classes={{
                        root: styles.hyperlink,
                        link: styles.link,
                      }}
                      to={x.url}>
                      Learn about our Collaboration Tools
                    </LinkLabeledIcon>}

                </div>
                <div className={styles.box}>
                  <div className={styles.title}>{x.title}</div>
                  <div className={styles.text}>{x.text}</div>
                </div>
              </div>
            </div>)}
        </div>
      </div>
    </div>
  );
};

CollaborationTools.displayName = 'CustomerResearch.CollaborationTools';

const items = [{
  alt: 'Collaborate with your team',
  src: c1,
  text: `Share projects with your peers and allow others to access insights generated from surveys and user interviews.`,
  title: `Collaborate with your team and share insights`,
}, {
  alt: 'Upload key research files',
  src: c2,
  text: `Utilize our file storage tools to share research documents across teams and to centralize the insights of your organization.`,
  title: `Upload key research files and manage team access`,
  url: path.Website.CollaborationTools,
}];