import { useCallback, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHasClientRole } from '@containers/User';
import { PipelineSocketEvent } from '@enums';
import * as actions from '@store/actions';
import { useAppReadyState } from '@containers/AppReadyState';
import * as ws from '@services/websocket';
import { WS } from '@/types';
import { usePipelineSocketEvent } from '@services/websocket';

type Props =
  ChildrenProps;

export const PipelineSocketContainer = (props: Props) => {
  const dispatch = useDispatch();
  const hasClientRole = useHasClientRole();

  const { hydrated } = useAppReadyState();

  const handleRecordUpdated = useCallback((record: WS.Pipeline.RecordUpdated.Payload) => {
    dispatch(actions.projectPipelineItemChanged({
      record,
    }));
  }, [
    dispatch,
  ]);

  const handleRecordAdded = useCallback((payload: WS.Pipeline.RecordCreated.Payload) => {
    dispatch(actions.batchActions([
      actions.projectUpdated({
        project: payload.project,
        projectId: payload.project.id,
      }),
      actions.projectPipelineItemChanged({
        record: payload.pipeline,
      }),
    ]));
  }, [dispatch]);

  const enabled = useMemo(() => {
    return hydrated && !hasClientRole;
  }, [hasClientRole, hydrated]);

  usePipelineSocketEvent(PipelineSocketEvent.RecordUpdated, handleRecordUpdated, enabled);
  usePipelineSocketEvent(PipelineSocketEvent.RecordCreated, handleRecordAdded, enabled);

  useEffect(() => {
    if (enabled) {
      ws.pipeline.open();
      return () => ws.pipeline.close();
    }
  }, [enabled]);

  return (
    <>
      {props.children}
    </>
  );
};
