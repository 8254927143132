import { memo } from 'react';
import { cx } from '@utils';
import styles from './style/QuotedText.css';

export type QuotedTextProps = {
  children?: React.ReactNode;
  quoteClassName?: string;
};

export const QuotedText = memo(({ children, quoteClassName }: QuotedTextProps) => (
  <>
    <span className={cx(styles.root, quoteClassName)}>“</span>
    {children}
    <span className={cx(styles.root, quoteClassName)}>”</span>
  </>
));

export default QuotedText;