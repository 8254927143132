import { useContext } from 'react';
import * as AvailabilitySchedule from './AvailabilitySchedule';
import { CalendarsContext } from './Context';
import styles from './style/Booking.ViewAvailability.css';

type Props = unknown;

export const Matches = (props: Props) => {
  const ctx = {
    calendars: useContext(CalendarsContext),
  };

  return (
    <AvailabilitySchedule.Section
      items={ctx.calendars.query.data.matches}
      renderEmpty={() => <div className={styles.empty}>None</div>}
      renderHeader={() => <Header />} />
  );
};

Matches.displayName = 'Booking.ViewAvailability.Matches';

const Header = (props: Props) => {
  return (
    <div className={styles.header}>
      <div className={styles.content}>
        <div className={styles.title}>Matched Items</div>
      </div>
    </div>
  );
};

Header.displayName = 'Booking.ViewAvailability.Matches.Header';