import { memo } from 'react';
import { SurveyTagging } from '@/types';
import { SurveyTaggingItem } from './Tagging.Item';
import styles from './style/Logic.css';

type Props = {
  items: SurveyTagging.Item[];
};

export const SurveyQuestionClassifications = memo(({ items }: Props) => {

  if (!items.length) return null;

  return (
    <>
      <div className={styles.label}>Classifications</div>
      {items.map(item => (
        <div key={item.identifier} className={styles.item}>
          <span className={styles.bullet}>•</span>
          <SurveyTaggingItem
            item={item} />
        </div>
      ))}
    </>
  );
});