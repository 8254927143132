import type { Ref } from 'react';
import { forwardRef, useCallback, useContext, useMemo } from 'react';
import type { LinkProps } from 'react-router-dom';
import { MoreHorizontal } from 'react-feather';
import Popper from '@mui/material/Popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { bindToggle, bindPopper } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { path } from '@consts';
import { DeleteArticleAlertToggleContext } from '@containers/Post.Article/Context';
import { Anchor } from '@presentation/Anchor';
import { Button } from '@/components/Button';
import { PopperMenu, PopperMenuItem } from '@/components/Popper';
import styles from './style/PostArticle.css';

type Props = {
  postId: number;
};

export const ContextMenu = (props: Props) => {
  const popup = usePopupState({
    popupId: 'article-post-popper',
    variant: 'popper',
  });

  const toggleDeleteModal = useContext(DeleteArticleAlertToggleContext);

  const edit = useMemo(() => {
    return {
      children: `Edit`,
      to: `${path.Posts.Articles.Root}/${props.postId}/edit`,
    };
  }, [props.postId]);

  const remove = useMemo(() => {
    return {
      children: 'Delete',
      onClick: toggleDeleteModal,
    };
  }, [toggleDeleteModal]);

  const items: MenuItemProps[] = [
    edit,
    remove,
  ];

  return (
    <div className={styles.anchor}>
      <div {...bindToggle(popup)}>
        <Anchor
          className={styles.icon}
          Icon={MenuAnchor}
          open={popup.isOpen} />
      </div>
      <Popper
        {...bindPopper(popup)}
        placement="bottom-end">
        <ClickAwayListener onClickAway={popup.close}>
          <MenuItems
            items={items}
            onClose={popup.close} />
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

type ContextMenuItemsProps = {
  items: MenuItemProps[];
  onClose: () => unknown;
};

const ContextMenuItems = ({ onClose, ...props }: ContextMenuItemsProps, ref: Ref<HTMLDivElement>) => {

  const handleClick = useCallback((fn?: () => unknown) => () => {
    onClose();
    return fn?.();
  }, [onClose]);

  return (
    <PopperMenu
      className={styles.menu}
      ref={ref}>
      {props.items.map((item, i) =>
        <MenuItem
          key={i}
          onClick={handleClick(item.onClick)}
          to={item.to}>
          {item.children}
        </MenuItem>)}
    </PopperMenu>
  );
};

ContextMenuItems.displayName = 'Post.Article.Content.ContextMenu.Items';

const MenuItems = forwardRef<HTMLDivElement, ContextMenuItemsProps>(ContextMenuItems);

const MenuAnchor = (props: unknown) => {
  return (
    <MoreHorizontal size={32} />
  );
};

type MenuItemProps = {
  children: React.ReactNode;
  onClick?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => unknown;
} & Partial<Pick<LinkProps, 'to'>>;

const MenuItem = (props: MenuItemProps) => {
  if (props.to) {
    return (
      <Button
        to={props.to}
        variant="link">
        <div className={styles.item}>
          {props.children}
        </div>
      </Button>
    );
  }

  return (
    <PopperMenuItem onClick={props.onClick}>
      {props.children}
    </PopperMenuItem>
  );
};

ContextMenu.displayName = 'Post.Article.Content.ContextMenu';