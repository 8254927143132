import { format } from 'date-fns';
import { utils as $enums } from '@enums';
import { useIsInternalUser } from '@/containers/Store';
import { useGroupEntityTags, useGroupEntityTagsUpdate } from '@/containers/GroupTags/hooks';
import { ProjectDetailsContainer } from '@containers/GroupProject/ProjectDetailsContainer';
import * as Tab from '@/presentation/Workspace.Tab.Pane';
import { useProjectOrLatestChild } from '@/containers/GroupProject/hooks';
import { useHasGroupFeature } from '@/store/hooks';
import { useConditionalRender } from '@/utils';
import { GroupSidebarTags } from '@/components/GroupSidebarTags';
import { GroupProjectTagsContainer } from '@/containers';

export const GroupProjectDetails = () => {
  const isInternalUser = useIsInternalUser();

  const project = useProjectOrLatestChild();

  if (isInternalUser) {
    return <ProjectDetailsContainer />;
  }

  return (
    <Tab.Panes.Container>
      <Tab.Pane.Pane>
        <Tab.Pane.Title>Details</Tab.Pane.Title>
        <Tab.Items.Container>

          <Tab.Item.Container>
            <Tab.Item.TextLabel>Name</Tab.Item.TextLabel>
            <Tab.Item.Value>{project.name}</Tab.Item.Value>
          </Tab.Item.Container>
          <Tab.Item.Container>
            <Tab.Item.TextLabel>Methodology</Tab.Item.TextLabel>
            <Tab.Item.Value>{$enums.ProjectType.getName(project.projectType)}</Tab.Item.Value>
          </Tab.Item.Container>
          <Tab.Item.Container>
            <Tab.Item.TextLabel>Created On</Tab.Item.TextLabel>
            <Tab.Item.Value>{format(project.createdOn, 'MM/dd/yyyy')}</Tab.Item.Value>
          </Tab.Item.Container>

        </Tab.Items.Container>

      </Tab.Pane.Pane>

      <GroupProjectTagsContainer projectParentId={project.parentId}>
        <TagPane />
      </GroupProjectTagsContainer>

    </Tab.Panes.Container>
  );
};

function TagPane() {
  const $Tags = useConditionalRender(useHasGroupFeature('userTagging'));

  const tags = useGroupEntityTags();
  const updateTags = useGroupEntityTagsUpdate();

  return (
    <$Tags>
      <Tab.Pane.Pane>
        <GroupSidebarTags
          tags={tags}
          onSave={updateTags} />
      </Tab.Pane.Pane>
    </$Tags>
  );
}