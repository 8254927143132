import { useCallback, useMemo, useRef } from 'react';
import type { EditorImperativeRef } from '@/components/Survey.RichText/interfaces';
import { RichTextEditorRegistryContext } from './Context';

type Props = ChildrenProps;

export const RichTextEditorRegistryContainer = ({ children }: Props) => {
  const refs = useRef<Record<string, EditorImperativeRef>>({});

  const handleRegister = useCallback((identifier: string, editor: EditorImperativeRef) => {
    refs.current[identifier] = editor;
  }, []);

  const handleUnregister = useCallback((identifier: string) => {
    refs.current[identifier] = null;
    delete refs.current[identifier];
  }, []);

  const getEditor = useCallback((identifier: string) => {
    return refs.current[identifier];
  }, []);

  const value = useMemo(() => ({
    register: handleRegister,
    unregister: handleUnregister,
    getEditor,
  }), [
    handleRegister,
    handleUnregister,
    getEditor,
  ]);

  return (
    <RichTextEditorRegistryContext.Provider value={value}>
      {children}
    </RichTextEditorRegistryContext.Provider>
  );
};