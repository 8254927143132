import { useContext, useMemo } from 'react';
import type { Transcribe } from '@/types';
import { TranscriptSpeakersContext } from '@containers/Transcript/context';
import { utils } from '@enums';

export const useTranscriptSpeaker = (speakerIndex: number) => {
  const speakers = useContext(TranscriptSpeakersContext);

  return useMemo(() => {

    const speaker = speakers?.[speakerIndex];
    const displayName = buildTranscriptSpeakerName(speaker, speakerIndex);

    return {
      displayName,
      speaker,
    };
  }, [speakers, speakerIndex]);
};

export function buildTranscriptSpeakerName(speaker: Pick<Transcribe.Speaker, 'name' | 'role'>, speakerIndex: number) {
  const fallback = `Speaker ${speakerIndex + 1}`;

  if (!speaker) return fallback;

  if (speaker.name) return speaker.name;

  if (typeof speaker.role === 'number') {
    return utils.CallRole.getName(speaker.role);
  }

  return fallback;
}