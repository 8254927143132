import { useCallback } from 'react';
import type { ConnectedAccount } from '@/types';

type Props = {
  onConnect: (conn: ConnectedAccount) => void;
};

export const useConnectZoom = () => {
  return useCallback((props: Props) => {
    const clientId = process.env.ZOOM_CLIENT_ID;
    const frontendUrl = window.location.protocol + '//' + window.location.host;
    const url = `https://zoom.us/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${frontendUrl}/callback/connect/zoom`;

    if (!url) return;

    const width = 500;
    const height = 550;

    const options = {
      resizable: 1,
      scrollbars: 1,
      width,
      height,
      top: window.screenY + (window.outerHeight - height) / 2.5,
      left: window.screenX + (window.outerWidth - width) / 2,
    };

    const optionsArray = Object.keys(options).map((k: keyof typeof options) => `${k}=${options[k]}`);

    window.onZoomConnect = props.onConnect;
    window.open(url, '', optionsArray.join(','));
  }, []);
};