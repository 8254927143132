import { forwardRef, useCallback, useContext, useState, useMemo } from 'react';
import MuiMenu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import * as api from '@api';
import { useQueryDownloader } from '@containers/QueryDownloader';
import { cx } from '@utils';
import { MembersFilterContext, MembersQueryContext } from '$admin/Search/Context';
import { DownloadMembersCSV } from '$admin/components/ProjectActionsMenu';
import styles from '$admin/components/ProjectActionsMenu/style/Menu.css';

type Props = unknown;

export const ActionsMenu = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = !!anchorEl;

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <div
        className={cx(styles.btn, { [styles.open]: open })}
        onClick={handleClick}>
        <div className={styles.btnLabel}>
          Actions
        </div>
        <Divider
          sx={{
            marginLeft: '4px',
            marginRight: '4px',
          }}
          variant="middle"
          orientation="vertical" />
        <KeyboardArrowDownIcon
          sx={{
            height: '20px',
            width: '20px',
          }} />
      </div>
      <MuiMenu
        disableAutoFocusItem
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transitionDuration={{
          appear: 0,
          enter: 0,
          exit: 0,
        }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: '4px',
              transition: 'duration 0 !important',
            },
          },
        }}
        MenuListProps={{
          sx: {
            padding: '0',
          },
        }}>
        <MenuItems onClose={handleClose} />
      </MuiMenu>
    </>
  );
};

ActionsMenu.displayName = 'Actions.Menu';

type MenuItemsProps = {
  children?: React.ReactNode;
  onClose: () => void;
};

const MenuItems = forwardRef<HTMLDivElement, MenuItemsProps>(({ onClose, ...props }, ref) => {
  const [filters] = useContext(MembersFilterContext);
  const query = useContext(MembersQueryContext);

  const { download } = useQueryDownloader({
    queryKey: [
      `post:search/members`, {
        active: filters.active,
        export: true,
      },
    ],
    queryFn: () => {
      return api.search.members.searchMembersExport({
        active: filters.active,
        export: true,
      });
    },
    meta: {
      downloader: true,
    },
    downloaderOptions: {
      onResponse: res => ({
        filename: res.filename,
        type: 'blob',
        value: res.blob,
      }),
    },
  });

  const closer = useCallback((handler: () => unknown) => () => {
    onClose();
    handler();
  }, [onClose]);

  const handleDownloadMembersCSV = useCallback(() => {
    download({
      extension: `csv`,
      name: `Experts Search Results`,
      title: `Generating Report`,
    });
  }, [download]);

  return (
    <div ref={ref}>
      <MenuItem
        disableRipple
        disabled={!query.data?.items?.length}
        onClick={closer(handleDownloadMembersCSV)}>
        <DownloadMembersCSV />
      </MenuItem>
    </div>
  );
});