import Modal from '@/components/Modal/Modal.Legacy';
import CopyLink from '@/components/CopyLink';
import type { ClientProps } from './interfaces';
import styles from './style.css';

const ReferralClientModal = ({
  url,
  onClose,
  visible,
}: ClientProps) => (
  <Modal onClose={onClose} visible={visible}>
    <div className={styles.wrap}>
      <h1 className={styles.title}>Share a sign up link</h1>
      <div className={styles.body}>
        <div className={styles.section}>
          <CopyLink
            url={url} />
        </div>
      </div>
    </div>
  </Modal>
);

export { ReferralClientModal };
export default ReferralClientModal;