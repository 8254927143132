import { useCallback } from 'react';
import { useChainedCommands } from '@remirror/react';
import type { SurveyRichText } from '@/types/survey';
import type { FindAndReplaceExtension } from '../Extension.FindAndReplace';

export function useUpdateFindAndReplaceNode() {
  const chain = useChainedCommands<FindAndReplaceExtension>();

  const update = useCallback((attrs: SurveyRichText.FindAndReplaceNode['attrs']) => {
    chain
      .updateFindAndReplaceNode(attrs)
      .focus()
      .run();
  }, [chain]);

  return update;
}