import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as api from '@api';
import { useSelectUser } from '@containers/Store';
import { RefetchTranscriptContext } from '@containers/Transcript/context';
import type { TranscriptPublishType, TranscriptionRevisionStatus } from '@enums';

type MutationParams = {
  publishType?: TranscriptPublishType;
  statusId: Exclude<TranscriptionRevisionStatus, TranscriptionRevisionStatus.InQueue>;
};

export const useTranscriptEnhancementStateMutation = ({ transcriptId }: ITranscriptId) => {
  const refetch = useContext(RefetchTranscriptContext);
  const user = useSelectUser();

  const mutation = useMutation({
    mutationKey: [
      `put:transcripts/enhancements/state`,
      transcriptId,
      user?.id,
    ],
    mutationFn: (params: MutationParams) => {
      return api.transcripts.enhancements.updateState({
        assigneeId: user.id,
        statusId: params.statusId,
        publishType: params.publishType,
        transcriptId,
      })
      .then(() => refetch());
    },
  });

  return mutation;
};