import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import type { ColumnDef, SortingState } from '@tanstack/react-table';
import { useReactTable, getCoreRowModel, getSortedRowModel, getPaginationRowModel } from '@tanstack/react-table';
import type { LocationDescriptor } from 'history';
import type { TranscribeRT } from '@/types';
import { getLocationFor } from '@/utils';
import { SortableTable } from '@presentation/Tables';
import { ReactTablev8Pagination } from '@presentation/TablePagination';
import { TaggedMomentEntity } from '@/types/transcribe.rich-text';
import { StandaloneTranscriptSnippet, StaticTimeRange } from '@/components/Transcript';
import styles from './style/CitationsTable.css';

type Props = {
  hideSourceColumn?: boolean;
  items: TableItem[];
} & IProjectId;

type TableItem = TranscribeRT.TaggedMoment & Partial<ICallId>;

export const TagSummaryCitationsTable = ({
  hideSourceColumn = false,
  items,
  projectId,
}: Props) => {

  const [sorting, setSorting] = useState<SortingState>([]);

  const columns = useMemo<ColumnDef<TableItem>[]>(() => {
    return [
      {
        id: 'source',
        header: 'Source',
        cell: ({ row }) => {
          let to: LocationDescriptor;
          let value: string;
          console.log(row.original);
          if (row.original.callId) {
            to = getLocationFor.call.transcript({
              callId: row.original.callId,
              slug: String(projectId),
            });
            value = `Call ${row.original.callId}`;
          } else if (row.original.meta?.type === TaggedMomentEntity.Conference) {
            to = getLocationFor.call.transcript({
              callId: row.original.meta.callId,
              slug: String(projectId),
            });
            value = `Call ${row.original.meta.callId}`;
          } else if (row.original.meta?.type === TaggedMomentEntity.Transcript) {
            to = getLocationFor.workspaces.transcript({
              transcriptId: row.original.meta.transcriptId,
            });
            value = `Transcript ${row.original.meta.transcriptId}`;
          } else {
            return <div>Unavailable</div>;
          }

          return (
            <Link className={styles.link} to={to}>
              <>{value}</>
            </Link>
          );
        },
        accessorFn: t => t.tagId,
        meta: {
          className: styles.callCell,
        },
      } as ColumnDef<TableItem>,
      {
        id: 'timestamp',
        header: 'Timestamp',
        cell: ({ row }) => {
          return <StaticTimeRange startSeconds={row.original.fromTs} endSeconds={row.original.toTs} />;
        },
        accessorFn: t => t.fromTs,
        meta: {
          className: styles.timeCell,
        },
      } as ColumnDef<TableItem>,
      {
        id: 'snippet',
        header: 'Text',
        cell: ({ row }) => {
          if (!row.original.content) {
            return <div>Unavailable</div>;
          } else {
            return <StandaloneTranscriptSnippet content={row.original.content} speakers={row.original.speakers} />;
          }
        },
        meta: {
          className: styles.textCell,
        },
      } as ColumnDef<TableItem>,
    ].filter(f => !hideSourceColumn || f.id !== 'source');
  }, [hideSourceColumn, projectId]);

  const table = useReactTable({
    data: items,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    columns,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <div className={styles.root}>
      <SortableTable table={table} />
      <div className={styles.footer}>
        <ReactTablev8Pagination
          className={styles.pagination}
          tableInstance={table}
          totalCount={items.length} />
      </div>
    </div>
  );
};