import type { ProjectType, CallRole, TranscriptType } from '@/enums';
import type { GroupTag, WorkspaceSearchFull } from '@/types';

export type BrandsTableItem = {
  id: string;
  name: string;
  path: string;
  tags: GroupTag[];
  owner: string;
  createdOn: Date;
};

export type ProjectsTableItem = {
  name: string;
  path: string;
  metadata: {
    type: ProjectType;
  };
  tags: GroupTag[];
  owner: string;
  createdOn: Date;
};

export type FilesTableItem = {
  name: string;
  path: string;
  metadata: {
    extension: string;
  };
  tags: GroupTag[];
  owner: string;
  createdOn: Date;
};

export type FoldersTableItem = {
  name: string;
  path: string;
  metadata: Record<string, unknown>;
  tags: GroupTag[];
  owner: string;
  createdOn: Date;
};

export type MembersTableItem = {
  entityId: number;
  name: string;
  path: string;
  metadata: {
    first: string;
    last: string;
    pictureUrl: string;
    title: string;
  };
  tags: GroupTag[];
};

export type CallParticipant = {
  userId: number;
  name: string;
  particpantType: CallRole;
  isExpert: boolean;
};

export type TranscriptsTableItem = {
  transcript: {
    id: number;
    type: TranscriptType;
  };
  name: string;
  occurrences: number;
  path: string;
  metadata: {
    call: {
      id: number;
    };
    project: {
      id: number;
      name: string;
      description: string;
      projectType: ProjectType;
    };
  };
  owner: string;
  createdOn: Date;
  tags: GroupTag[];
};

export type TableItem =
  | BrandsTableItem
  | ProjectsTableItem
  | FilesTableItem
  | FoldersTableItem
  | MembersTableItem
  | TranscriptsTableItem;

export enum SearchRouteKey {
  All           = 'all',
  Brands        = 'brands',
  Projects      = 'projects',
  // Members       = 'members',
  Files         = 'files',
  Folders       = 'folders',
  Transcripts   = 'transcripts',
}

export type SearchSceneMap = {
  [key in SearchRouteKey]: TabScene;
};

export type TabScene = {
  component: React.ComponentType;
  title:     string | React.ReactNode;
};

export declare namespace IMainSearchFilters {
  export type State = {
    tab: WorkspaceSearchFull.SearchTab;
    keywords: string[];
    tags: GroupTag[];
    filetypes: WorkspaceSearchFull.File.FileTypes[];
  };

  export type Action =
    | { type: 'query-update'; value: Partial<State> };
}

export type ReducedFiltersContextValue = IMainSearchFilters.State;