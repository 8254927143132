import http from '@services/http';
import type * as Groups from './interfaces/groups';

export const fetchDraft = (data: Groups.FetchProjectDraft.Request) => {
  return http.get<Groups.FetchProjectDraft.Response>(`/admin/groups/${data.groupId}/projects/${data.projectId}`);
};

export const saveDraft = (data: Groups.SaveProjectDraft.Request) => {
  return http.post<Groups.SaveProjectDraft.Response>(`/admin/groups/${data.groupId}/projects`, data);
};

export const updateSurvey = (data: Groups.UpdateProjectSurvey.Request) => {
  const { groupId, projectId, ...params } = data;

  return http.put<Groups.UpdateProjectSurvey.Response>(`/admin/groups/${groupId}/projects/${projectId}/survey`, params);
};