import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import NotificationsList from '@/components/Notifications/Notifications';
import styles from './style.css';

const selectNotifications = (state: Store.State) => state.notifications.items;

type Props = unknown;

const Notifications = (props: Props) => {
  const notifications = useSelector(selectNotifications);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Helmet title="Notifications" />
        <div className={styles.header}>
          <div className={styles.title}>Notifications</div>
        </div>
        <NotificationsList items={notifications}  />
      </div>
    </div>
  );
};

export { Notifications };
export default Notifications;