import {
  extension,
  ApplySchemaAttributes,
  ExtensionTag,
  NodeExtension,
  NodeExtensionSpec,
  NodeSpecOverride,
} from '@remirror/core';
import * as Remirror from '@/types/rich-text';
import { TemplateAdditionalPriceLinks } from './Node.Template.AdditionalPriceLink';

@extension({
  defaultOptions: {},
})
export class TemplatePriceLinkExtension extends NodeExtension {

  get name() {
    return Remirror.NodeType.AdditionalPriceLink;
  }

  createTags() {
    return [ExtensionTag.InlineNode];
  }

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    return {
      ...override,
      atom: true,
      attrs: {
        href: {},
        key: {},
        text: {},
        ...extra.defaults(),
      },
      inline: true,
      toDOM: node => ['span', {
        ...extra.dom(node),
        contentEditable: 'false',
        selectable: 'false',
      }],
    };
  }

  ReactComponent = TemplateAdditionalPriceLinks;
}