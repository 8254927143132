import { forwardRef, useMemo } from 'react';
import { cx } from '@utils';
import { TextareaProps } from './interfaces';
import styles from './style.css';

export const Textarea = forwardRef((props: TextareaProps, ref: React.Ref<HTMLTextAreaElement>) => {
  const { className, invalid, invalidClassName, ...otherProps } = props;

  const classes = useMemo(() => {
    return cx(styles.root, className, invalid && cx(invalidClassName));
  }, [className, invalid, invalidClassName]);

  return (
    <textarea
      className={classes}
      ref={ref}
      {...otherProps} />
  );
});

export default Textarea;