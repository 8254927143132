import { useCallback, useContext } from 'react';
import type { ClickMarkEventHandler } from '@remirror/extension-events';
import { useEditorEvent } from '@remirror/react';
import type { Confidence, Timestamp } from '@/types/transcribe.rich-text';
import { MarkType } from '@/types/transcribe.rich-text';
import { TranscriptMediaPlayerContext } from '@containers/Transcript/context';
import { useHelpers } from './useHelpers';

export const useSeekEvent = () => {

  const player = useContext(TranscriptMediaPlayerContext);
  const { getConfidenceThreshold, isConfidenceEnabled } = useHelpers();

  const handleClick: ClickMarkEventHandler = useCallback((event, state) => {

    if (!player) return;

    const markRange = state.getMark(MarkType.Timestamp);

    if (!markRange) {
      return;
    }

    const confidenceMark = state.getMark(MarkType.Confidence);

    if (confidenceMark && isConfidenceEnabled()) {
      const confidenceAttrs = confidenceMark.mark.attrs as Confidence.Attributes;
      if (confidenceAttrs.c < getConfidenceThreshold()) {
        return;
      }
    }

    const attrs = markRange.mark.attrs as Timestamp.Attributes;

    if (attrs?.s) {
      player.currentTime = Number(attrs.s);
      // player.play();

      return false;
    }

    return false;
  }, [player, getConfidenceThreshold, isConfidenceEnabled]);

  useEditorEvent('clickMark', handleClick);

};