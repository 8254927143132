import { useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import { path } from '@consts';
import { FollowedTopicsContext } from '@containers/Topic/Context';
import { TopicLogo } from '@presentation/Topic.Logo';
import { Topics } from '@/types';
import { useTopicFollowingModal } from '@/components/Topic/hooks';
import styles from './style/Following.css';

type Props = unknown;

export const Following = (props: Props) => {
  const ctx = useContext(FollowedTopicsContext);
  const [expanded, setExpanded] = useState(false);
  const [toggle, Modal] = useTopicFollowingModal();

  const hasItems = ctx.topics.length > 0;
  const hasTruncation = ctx.topics.length > 6;

  const items = useMemo(() => {

    return !expanded && hasTruncation
      ? ctx.topics.slice(0, 6)
      : ctx.topics;

  }, [
    ctx.topics,
    hasTruncation,
    expanded,
  ]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          {ctx.query.isLoading
            ? <Skeleton
                height={23}
                width="50%"
                variant="text" />
            : <div className={styles.title}>Following</div>}
        </div>

        <div className={styles.main}>
          {hasItems &&
            items.map(x =>
              <Item
                item={x}
                key={x.id} />)}

          {ctx.query.isLoading &&
            <Loading />}

          {(!ctx.query.isLoading && !hasItems) &&
            <div className={styles.empty}>{copy.empty}</div>}

          {hasTruncation &&
            <div className={styles.item}>
              <button onClick={() => setExpanded(!expanded)}>
                <div className={styles.name}>
                  {expanded
                    ?`See less`
                    : `See more`}
                </div>
              </button>
            </div>}
        </div>

        <div className={styles.footer}>
          {ctx.query.isLoading &&
            <Skeleton
                height={23}
                width="50%"
                variant="text" />}
          {!ctx.query.isLoading &&
            <button
              className={styles.btn}
              onClick={toggle}>
              Discover Topics
            </button>}
          <Modal />
        </div>
      </div>
    </div>
  );
};

Following.displayName = 'Main.Member.Following';

type ItemProps = {
  item: Topics.Following;
};

const Item = (props: ItemProps) => {
  const location = `${path.Topics.Root}/${props.item.slug}`;

  return (
    <div className={styles.item}>
      <Link to={location}>
        <TopicLogo
          classes={{
            root: styles.thumb,
            img: styles.img,
          }}
          fallback={{
            icon: styles.icon,
            size: 25,
          }}
          url={props.item.thumbnailUrl} />
      </Link>
      <Link to={location}>
        <div
          className={styles.name}
          title={props.item.name}>
          {props.item.name}
        </div>
      </Link>
    </div>
  );
};

Item.displayName = 'Main.Member.Following.Item';

const Loading = (props: Props) => {
  return (
    <>
      <Skeleton
        height={28}
        width="50%"
        variant="text" />
      <Skeleton
        height={28}
        width="70%"
        variant="text" />
    </>
  );
};

Loading.displayName = 'Main.Member.Following.Loading';

const copy = {
  empty: `Start following topics to customize your newsfeed!`,
};