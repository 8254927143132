import { useCallback, useMemo } from 'react';
import type { SurveyQuestionType } from '@enums';
import type { NumberInputTableQuestion } from '@/types';
import { useNumberInputTableDropdownOptions, useSubmitAnswer, useSurveyFormQuestionContext, useValidateRationale } from './hooks';
import type { SubmitOverrides } from './Context';
import {
  useNumberTableAnswerMap,
  NumberInputDropdownValuesContext,
  NumberTableAnswerMapContext, NumberTableColumnTotalsContext,
  NumberTableWarningsContext, SurveyFormCanSubmitContext,
  useSurveyFormQuestionAnswer, SurveyFormItemSubmitContext,
} from './Context';
import { useNumberTableValidation } from './hooks';
import type { NumberTable } from './interfaces';
import { SurveyFormBackContainer } from './BaseNavigation';

type Props = {
  question: NumberInputTableQuestion.FormQuestion;
} & ChildrenProps;

export const NumberInputTableWarningsContainer = ({ children, question }: Props) => {

  const [cellValidations, totalsValidations] = useNumberTableValidation({ item: question });

  return (
    <NumberTableWarningsContext.Provider value={{
      cell: cellValidations,
      totals: totalsValidations,
    }}>
      {children}
    </NumberTableWarningsContext.Provider>
  );
};

export const NumberInputTableTotalsContainer = ({ question, ...props }: Props) => {

  const answerMap = useNumberTableAnswerMap();

  const totalsMap = useMemo(() => {
    return question.options.reduce<NumberTable.TotalsMap>((acc, o) => {
      const total = question.matrixRows.reduce((sum, r) => {
        const answer = answerMap[r.id][o.id];
        return sum + (answer || 0);
      }, 0);

      return {
        ...acc,
        [o.id]: total,
      };
    }, {});

  }, [
    answerMap,
    question.options,
    question.matrixRows,
  ]);

  return (
    <NumberTableColumnTotalsContext.Provider value={totalsMap}>
      {props.children}
    </NumberTableColumnTotalsContext.Provider>
  );
};

export const NumberInputTableAnswerMapContainer = ({ question, ...props }: Props) => {
  const [answer] = useSurveyFormQuestionAnswer<SurveyQuestionType.NumberInputTable>();

  const answerMap = useMemo(() => {

    return question.matrixRows.reduce<NumberTable.AnswerMap>((acc, row) => {

      const rowMap = question.options.reduce<{ [optionId: number]: number }>((acc2, option) => {

        const v = (answer.items || [])
          .find(f => f.optionId === option.id && f.rowId === row.id);

        return {
          ...acc2,
          [option.id]: v?.value,
        };
      }, {});

      return {
        ...acc,
        [row.id]: rowMap,
      };
    }, {});

  }, [
    answer,
    question.matrixRows,
    question.options,
  ]);

  return (
    <NumberTableAnswerMapContext.Provider value={answerMap}>
      {props.children}
    </NumberTableAnswerMapContext.Provider>
  );
};

export const NumberInputTableDropdownsContainer = ({ question, ...props }: Props) => {
  const options = useNumberInputTableDropdownOptions({ settings: question.settings });

  return (
    <NumberInputDropdownValuesContext.Provider value={options}>
      {props.children}
    </NumberInputDropdownValuesContext.Provider>
  );
};

export const NumberInputTableFormContainer = (props: ChildrenProps) => {
  const [answer] = useSurveyFormQuestionAnswer<SurveyQuestionType.NumberInputTable>();
  const question = useSurveyFormQuestionContext<SurveyQuestionType.NumberInputTable>();

  const {
    isError: isSubmitError,
    isLoading: isSubmitting,
    mutateAsync: submitAnswer,
  } = useSubmitAnswer();

  const [cellValidations, totalsValidations, runValidations] = useNumberTableValidation({ item: question });

  const handleSubmit = useCallback((overrides?: SubmitOverrides) => {
    const isValid = runValidations(answer);

    if (isValid) {
      return submitAnswer(overrides);
    }
  }, [
    answer,
    runValidations,
    submitAnswer,
  ]);

  const validateRationale = useValidateRationale();

  const canSubmit = useMemo(() => {

    const isRationaleValid = validateRationale();

    if (!isRationaleValid) return false;

    if (!answer?.items) return false;

    return true;

  }, [
    answer,
    validateRationale,
  ]);

  const submitCtx = {
    isError: isSubmitError,
    isLoading: isSubmitting,
    handler: handleSubmit,
  };

  const warningsCtx = {
    cell: cellValidations,
    totals: totalsValidations,
  };

  return (
    <SurveyFormCanSubmitContext.Provider value={canSubmit}>
      <SurveyFormItemSubmitContext.Provider value={submitCtx}>
        <NumberTableWarningsContext.Provider value={warningsCtx}>
          <NumberInputTableAnswerMapContainer question={question}>
            <NumberInputTableTotalsContainer question={question}>
              <NumberInputTableDropdownsContainer question={question}>
                <SurveyFormBackContainer>
                  {props.children}
                </SurveyFormBackContainer>
              </NumberInputTableDropdownsContainer>
            </NumberInputTableTotalsContainer>
          </NumberInputTableAnswerMapContainer>
        </NumberTableWarningsContext.Provider>
      </SurveyFormItemSubmitContext.Provider>
    </SurveyFormCanSubmitContext.Provider>
  );
};