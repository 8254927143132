import { useSurveyBuilderScreenState } from '@containers/SurveyBuilder';
import { MultiLanguageBuilder } from './MultiLanguageBuilder';
import { SurveySectionsBuilder } from './SectionsBuilder';

export const QuestionBuilderView = () => {
  const { additionalLanguageCodes } = useSurveyBuilderScreenState();

  if (additionalLanguageCodes.length) {
    return <MultiLanguageBuilder />;
  } else {
    return <SurveySectionsBuilder />;
  }
};