import styles from './style/Profile.Section.Header.css';

type TitleProps = {
  title: string;
};

export const Title = ({ title }: TitleProps) => {
  return (
    <div className={styles.title}>{title}</div>
  );
};

type HeaderProps =
  TitleProps &
  Partial<ChildrenProps>;

export const Header = (props: HeaderProps) => {
  return (
    <div className={styles.header}>
      <Title title={props.title} />
      {props.children}
    </div>
  );
};