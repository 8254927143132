import { memo, useCallback, Fragment } from 'react';
import { Field, FieldProps, useFormikContext } from 'formik';
import { Remirror } from '@/types';
import {
  ProjectNotesEditorContainer,
  ProjectNotesEditor,
  ProjectNotesEditorOnChange,
  Toolbar,
  Manager,
  State,
  isProjectNotesEmpty,
} from '$admin/components/ProjectNotes';
import styles from './style.css';

type Props = {
  editing: boolean;
  label?: string;
  manager: Manager;
  name: string;
  state: State;
};

export function FieldProjectNotes({ editing, label, manager, name, state }: Props) {
  const { setFieldValue, values } = useFormikContext();

  const handleChange: ProjectNotesEditorOnChange = useCallback(params => {

    if (!params.tr?.docChanged) return;

    const value = params.state.doc.toJSON() as Remirror.RootNode;

    setFieldValue(name, value);
  }, [name, setFieldValue]);

  const initialValue = values[name] as Remirror.RootNode;

  if (!editing && (!initialValue || isProjectNotesEmpty(initialValue))) {
    return <NotSet label={label} />;
  }

  return (
    <div>
      {label &&
        <div className={styles.label}>
          Project Notes
        </div>}
      <div className={styles.value}>
        <ProjectNotesEditorContainer
          onChange={handleChange}
          editable={editing}
          manager={manager}
          state={state}>
          {editing && <Toolbar className={styles.toolbar} />}
          <ProjectNotesEditor />
        </ProjectNotesEditorContainer>
      </div>
    </div>
  );
}

const NotSet = memo(({ label }: { label: string; }) => {
  return (
    <Fragment>
      {label && <div className={styles.label}>{label}</div>}
      <span className={styles.inputValue}>No notes</span>
    </Fragment>
  );
});