import { Fragment } from 'react';
import { utils } from '@enums';
import { TableCellItem } from './interfaces';

type Props = TableCellItem;

export const Cell = (props: Props) => {
  if (!props.row.original.professionalRole) return null;

  return (
    <Fragment>
      {utils.ProfessionalRole.getName(props.row.original.professionalRole)}
    </Fragment>
  );
};

Cell.displayName = 'Column.Role.Cell';