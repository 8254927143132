import { useContext } from 'react';
import { TabViewTabBarSkeleton } from '@/components/TabView';
import { ProfileOverviewQueryContext } from './Context';
import { Tile } from './Layout';
import { ProfileHeader } from './Profile.Header';
import { TabViewContainer } from './TabView.Container';
import { UserProfileContainer } from './User.Profile.Container';
import styles from './style/User.Profile.css';

type Props = unknown;

export const UserProfile = (props: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <UserProfileContainer>
            <ProfileTabs />
          </UserProfileContainer>
        </div>
      </div>
    </div>
  );
};

UserProfile.displayName = 'User.Profile';

const ProfileTabs = (props: Props) => {
  const query = useContext(ProfileOverviewQueryContext);

  return (
    <div className={styles.stack}>
      <Tile.Root>
        <ProfileHeader
          hourlyRate={query.data?.hourlyRate}
          loading={query.isInitialLoading}
          name={query.data?.name}
          pictureUrl={query.data?.pictureUrl}
          publicUrl={query.data?.publicUrl}
          rating={query.data?.rating}
          rateCurrency={query.data?.rateCurrency} />
      </Tile.Root>
      {query.isInitialLoading
        ? <ProfileTabsSkeleton />
        : <TabViewContainer userId={query.data.id} />}
    </div>
  );
};

const ProfileTabsSkeleton = (props: Props) => {
  return (
    <div className={styles.skel}>
      <TabViewTabBarSkeleton />
      <div className={styles.view}>
        <Tile.Root className={styles.tile} />
      </div>
    </div>
  );
};