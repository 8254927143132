import { useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { usePrevious, useToggle } from '@utils';
import { FileUploadsStateContext } from '../Context';
import { useUploadInProgress } from './useUploadInProgress';

export const useShowUploadDisplay = () => {
  const [show, toggleShow] = useToggle(false);

  const inProgress = useUploadInProgress();
  const state = useContext(FileUploadsStateContext);
  const itemCount = useMemo(() => {
    return state.invalid.length + state.valid.length;
  }, [state.invalid.length, state.valid.length]);
  const previousCount = usePrevious(itemCount);

  const location = useLocation();
  const previousLocation = usePrevious(location);

  useEffect(() => {
    if (itemCount > previousCount && !show) {
      toggleShow();
    }

  }, [
    itemCount,
    previousCount,
    show,
    toggleShow,
  ]);

  useEffect(() => {

    if (location !== previousLocation) {
      if (show && !inProgress) {
        toggleShow();
      }
    }
  }, [
    inProgress,
    location,
    previousLocation,
    show,
    toggleShow,
  ]);

  return [show, toggleShow] as const;
};

export default useShowUploadDisplay;