import { useCallback, useMemo, useState } from 'react';
import { CSVFieldMappingContext } from './Context';
import { CSV } from './interfaces';

type Props = {
  children: React.ReactNode;
};

export const CSVFieldMappingContainer = (props: Props) => {
  const [map, setMap] = useState<CSV.FieldMappingLookup>(createInitialMap());

  const setValue = useCallback((key: CSV.MappedColumnKey, value: string) => {
    map.forEach((v, k, m) => {
      if (v === value) {
        m.delete(k);
      }
    });

    const updated = map.set(key, value);

    setMap(new Map(updated));
  }, [
    map,
    setMap,
  ]);

  const values = useMemo(<T extends CSV.FieldMappingContextValue['values']>() => {
    return [...map.keys()].reduce<T>((acc, key) => ({
      ...acc,
      [key]: map.get(key),
    }), {} as T);
  }, [map]);

  const getMappedValue = useCallback(<R extends CSV.MappedColumnOption>(column: string) => {
    return Object.entries(values).reduce((acc, [key, value]: [R, string]) => {
      if (acc !== 'none') return acc;

      return value === column
          ? key
          : acc;
    }, 'none' as R);
  }, [values]);

  const valid = useMemo(() => {
    const keys = [...map.entries()].reduce((acc, [key, value]) => {
      return value
        ? acc.concat(key)
        : acc;
    }, []);

    return required.every(key => keys.includes(key));
  }, [map]);

  const value = {
    getMappedValue,
    setValue,
    values,
    valid,
  };

  return (
    <CSVFieldMappingContext.Provider value={value}>
      {props.children}
    </CSVFieldMappingContext.Provider>
  );
};

CSVFieldMappingContainer.displayName = 'CSV.FieldMapping.Container';

const createInitialMap = () => {
  const initial = keys.map(k => [k, null] as [CSV.MappedColumnKey, string]);

  return new Map<CSV.MappedColumnKey, string>(initial);
};

const keys = [
  'company',
  'email',
  'firstName',
  'lastName',
  'phone',
  'title',
];

const required = ['email', 'firstName', 'lastName'];