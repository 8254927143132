export enum TabKey {
  Transcript = 'transcript',
  Highlights = 'highlights',
  Entities = 'entities',
  Summary = 'summary',
  Details = 'details',
}

export type TabRoute = {
  key: TabKey;
  title: string;
  component: {
    (props: unknown): JSX.Element;
    displayName: string;
  };
};