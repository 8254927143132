import { useCallback, useMemo, useRef, useState, CSSProperties } from 'react';

const itemWidth = 280;
const itemMargin = 20;

function computeItemStyles(position: number, container: DOMRect): CSSProperties {

  if (!container) return null;

  // Distance between center of item and center of slider
  const itemCenter = ((itemWidth + itemMargin)) * position + (itemWidth / 2);
  const containerCenter = container.width / 2;

  const transformX = position === 0
    ? 0
    : containerCenter - itemCenter;

  return {
    transform: `translateX(${transformX}px)`,
  };
}

type Props = {
  size: number;
};

export const usePostsCarousel = (props: Props) => {
  const [position, setPosition] = useState(0);
  const ref = useRef<HTMLDivElement>();

  const style = useMemo(() => computeItemStyles(position, ref.current?.getBoundingClientRect()), [position]);

  const canGoBack = useMemo(() => {
    return position > 0;
  }, [position]);

  const canGoNext = useMemo(() => {
    return position < props.size - 1;
  }, [props.size, position]);

  const back = useCallback(() => {
    setPosition(s => s - 1);
  }, []);

  const next = useCallback(() => {
    setPosition(s => s + 1);
  }, []);

  return {
    actions: {
      back,
      canGoBack,
      canGoNext,
      next,
    },
    ref,
    style,
  } as const;
};