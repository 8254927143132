import type { Ref } from 'react';
import { forwardRef, useCallback, useContext, useMemo, useState } from 'react';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import Popper from '@mui/material/Popper';
import { usePopper } from 'react-popper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import type { PopupState as PopupStateProps } from 'material-ui-popup-state/hooks';
import { useToggle } from '@/utils';
import { ParentPopperMenuItem, PopperMenu, PopperMenuItem, useZIndexModifier } from '@/components/Popper';
import { useFileMenuItems, useFileActions, useShowFileMenu } from '@/containers/Workspace.File/hooks';
import { MoreHorizontalAnchorSmall } from '@/presentation/Anchor';
import { ImportTranscriptContext, TranscribeFileContext, ViewFileTranscriptContext } from '@/containers/Workspace.File/Context';
import { Portal } from '@/components/Portal';
import type { FileActionsMenuProps, FileActionsPopperProps } from './interfaces';
import styles from './style/FileActionsContextMenu.css';

const FileActionsContextMenu = forwardRef(({ onClose }: FileActionsMenuProps, ref: Ref<HTMLDivElement>) => {
  const valid = useFileMenuItems();
  const actions = useFileActions();

  const transcribeFileContext = useContext(TranscribeFileContext);
  const viewTranscriptContext = useContext(ViewFileTranscriptContext);

  const nestedMenus = useMemo(() => {
    return valid.canViewTranscript;
  }, [valid.canViewTranscript]);

  const [downloadItemElement, setDownloadItemElement] = useState<HTMLDivElement>(null);
  const [downloadMenuOpen, toggleDownloadMenuOpen] = useToggle();

  const [viewItemElement, setViewItemElement] = useState<HTMLDivElement>(null);
  const [viewMenuOpen, toggleViewMenuOpen] = useToggle();

  const handleClick = useCallback((fn: () => unknown) => () => {
    onClose();
    return fn();
  }, [onClose]);

  const remove = useMemo(() => {
    return {
      children: 'Remove',
      onClick: actions.delete,
    };
  }, [actions.delete]);

  const ownership = useMemo(() => {
    return {
      children: 'Change Owner',
      onClick: actions.changeOwner,
    };
  }, [actions.changeOwner]);

  const move = useMemo(() => {
    return {
      children: 'Move',
      onClick: actions.move,
    };
  }, [
    actions.move,
  ]);

  const download = useMemo(() => {
    return {
      children: 'Download',
      onClick: actions.download,
    };
  }, [
    actions.download,
  ]);

  const rename = useMemo(() => {
    return {
      children: 'Rename',
      onClick: actions.rename,
    };
  }, [actions.rename]);

  const manageAccess = useMemo(() => {
    return {
      children: 'Manage Access',
      onClick: actions.manageAccess,
    };
  }, [actions.manageAccess]);

  const transcribe = useMemo(() => {
    return {
      children: 'Transcribe',
      onClick: transcribeFileContext,
    };
  }, [transcribeFileContext]);

  const viewTranscript = useMemo(() => {
    return {
      children: 'View Transcript',
      onClick: viewTranscriptContext,
    };
  }, [viewTranscriptContext]);

  const viewFile = useMemo(() => {
    return {
      children: 'View File',
      onClick: actions.viewFile,
    };
  }, [actions.viewFile]);

  const convertToTranscriptContext = useContext(ImportTranscriptContext);
  const convertToTranscript = useMemo(() => {
    return {
      children: 'Import Transcript',
      onClick: convertToTranscriptContext,
    };
  }, [convertToTranscriptContext]);

  const items = useMemo(() => {
    return [
      !nestedMenus && viewFile,
      valid.canChangeOwner && ownership,
      valid.canDownload && !nestedMenus && download,
      valid.canMove && move,
      valid.canDelete && remove,
      valid.canRename && rename,
      valid.canManageAccess && manageAccess,
      valid.canTranscribe && transcribe,
      valid.canImportTranscript && convertToTranscript,
    ].filter(Boolean);
  }, [
    convertToTranscript,
    nestedMenus,
    valid,
    download,
    manageAccess,
    move,
    ownership,
    remove,
    rename,
    transcribe,
    viewFile,
  ]);

  return (
    <>
      <PopperMenu ref={ref}>
        {valid.canDownload && nestedMenus && (
          <ParentPopperMenuItem onClick={toggleDownloadMenuOpen} ref={setDownloadItemElement}>
            Download
          </ParentPopperMenuItem>
        )}
        {nestedMenus && (
          <ParentPopperMenuItem onClick={toggleViewMenuOpen} ref={setViewItemElement}>
            View
          </ParentPopperMenuItem>
        )}
        {items.map((item, i) => (
          <PopperMenuItem
            key={i}
            onClick={handleClick(item.onClick)}>
            {item.children}
          </PopperMenuItem>
        ))}
      </PopperMenu>
      {downloadMenuOpen && (
        <DownloadContextMenu
          referenceElement={downloadItemElement}
          onClose={toggleDownloadMenuOpen} />
      )}
      {viewMenuOpen && (
        <ViewContextMenu
          referenceElement={viewItemElement}
          onClose={toggleViewMenuOpen} />
      )}
    </>
  );
});

type DownloadMenuProps = {
  onClose: () => unknown;
  referenceElement?: HTMLDivElement;
};

const DownloadContextMenu = ({ onClose, referenceElement }: DownloadMenuProps) => {

  const actions = useFileActions();

  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);

  const zIndexModifier = useZIndexModifier({ zIndex: 8 });

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      zIndexModifier,
      {
        name: 'offset',
        options: {
          offset: [-7, 0],
        },
      },
    ],
    placement: 'right-start',
  });

  return (
    <Portal>
      <ClickAwayListener
        mouseEvent='onMouseDown'
        onClickAway={onClose}
        touchEvent='onTouchStart'>
        <div
          ref={setPopperElement}
          style={popperStyles.popper}
          {...attributes.popper}>
          <PopperMenu>
            <PopperMenuItem onClick={actions.download}>
              File
            </PopperMenuItem>
            <PopperMenuItem onClick={actions.downloadTranscript}>
              Transcript
            </PopperMenuItem>
          </PopperMenu>
        </div>
      </ClickAwayListener>
    </Portal>
  );

};

const ViewContextMenu = ({ onClose, referenceElement }: DownloadMenuProps) => {

  const actions = useFileActions();
  const viewTranscriptContext = useContext(ViewFileTranscriptContext);

  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);

  const zIndexModifier = useZIndexModifier({ zIndex: 8 });

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      zIndexModifier,
      {
        name: 'offset',
        options: {
          offset: [-7, 0],
        },
      },
    ],
    placement: 'right-start',
  });

  return (
    <Portal>
      <ClickAwayListener
        mouseEvent='onMouseDown'
        onClickAway={onClose}
        touchEvent='onTouchStart'>
        <div
          ref={setPopperElement}
          style={popperStyles.popper}
          {...attributes.popper}>
          <PopperMenu>
            <PopperMenuItem onClick={actions.viewFile}>
              File
            </PopperMenuItem>
            <PopperMenuItem onClick={viewTranscriptContext}>
              Transcript
            </PopperMenuItem>
          </PopperMenu>
        </div>
      </ClickAwayListener>
    </Portal>
  );

};

export const FileActionsContextMenuPopper = ({
  placement = 'bottom-end',
  renderAnchor,
}: FileActionsPopperProps) => {

  const showMenu = useShowFileMenu();

  const defaultAnchor = useCallback((state: PopupStateProps) => {
    return (
      <MoreHorizontalAnchorSmall open={state.isOpen} />
    );
  }, []);

  if (!showMenu) {
    return null;
  }

  return (
    <>
      <PopupState variant="popper" popupId="folder-actions-popper">
        {popupState => (
          <div>
            <div {...bindToggle(popupState)}>
              {(renderAnchor || defaultAnchor)(popupState)}
            </div>
            <Popper
              {...bindPopper(popupState)}
              placement={placement}
              className={styles.popper}>
              <ClickAwayListener
                onClickAway={popupState.close}>
                <FileActionsContextMenu
                  onClose={popupState.close} />
              </ClickAwayListener>
            </Popper>
          </div>
        )}
      </PopupState>
    </>
  );
};