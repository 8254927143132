import type { Reducer } from 'react';
import { useReducer } from 'react';
import { ConferenceTagType } from '@enums';
import { defaultColor } from '@/components/Conference.Tagging/Tagging.ColorSelect';
import { TranscriptTagsContext } from './Context';
import type { Actions, State } from './interfaces';

type Props = {
  children: React.ReactNode;
};

export const TranscriptTaggingStateContainer = (props: Props) => {
  const [state, dispatch] = useReducer<Reducer<State, Actions>>(tagsReducer, initialState);

  return (
    <TranscriptTagsContext.Provider value={[state, dispatch]}>
      {props.children}
    </TranscriptTagsContext.Provider>
  );
};

TranscriptTaggingStateContainer.displayName = 'Transcript.Tagging.State.Container';

const initialState: State = {
  items: [],
};

function tagsReducer(state: State, action: Actions): State {

  switch (action.type) {
    case 'tags-fetched': {
      return {
        items: action.items,
      };
    }
    case 'tag-updated': {
      return {
        items: state.items.filter(f => f.id !== action.tag.id).concat(action.tag),
      };
    }
    case 'tag-removed': {
      if ('momentId' in action) {
        return {
          items: state.items.filter(f => f.id !== action.momentId),
        };
      } else {
        return {
          items: state.items.filter(f => f.identifier !== action.highlightIdentifier),
        };
      }
    }

    case 'temp-tag-added': {
      return {
        items: state.items.concat({
          id: null,
          identifier: action.highlightIdentifier,
          tagId: null,
          typeId: ConferenceTagType.UserCreated,
          fromTs: action.fromTs,
          toTs: action.toTs,
          createdBy: null,
          color: defaultColor,
          name: null,
          speakers: {},
          meta: null,
        }),
      };
    }

    case 'tag-added': {
      return {
        items: state.items.concat(action.tag),
      };
    }

    default: return state;
  }
}