import type { Ref } from 'react';
import { Remirror } from '@remirror/react';
import type { Manager, SurveyRichTextEditorOnChange, State, EditorImperativeRef } from './interfaces';
import { EditorImperativeHandle } from './Editor.ImperativeHandle';
import styles from './style/Editor.css';

type Props = {
  autoFocus?: boolean;
  className?: string;
  editable?: boolean;
  onChange?: SurveyRichTextEditorOnChange;
  manager: Manager;
  state: State;
  editorRef?: Ref<EditorImperativeRef>;
} & ChildrenProps;

export const SurveyRichTextEditorContainer = ({
  autoFocus,
  children,
  className,
  editable = true,
  manager,
  onChange,
  state,
  editorRef,
}: Props) => {
  return (
    <Remirror
      classNames={[styles.editor, className]}
      autoFocus={autoFocus}
      editable={editable}
      manager={manager}
      onChange={onChange}
      initialContent={state}>
      {children}
      <EditorImperativeHandle ref={editorRef} />
    </Remirror>
  );
};