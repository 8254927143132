import AntDatePicker from 'antd/lib/date-picker';
//import css from 'antd/lib/date-picker/style/index.css';
import dayjs from 'dayjs';
import { cx, useUseableCSS } from '@utils';
import styles from './style/DatePicker.css';

type Props = {
  className?: string;
  isInvalid?: boolean;
  onChange: (date: Date) => unknown;
  value: Date;
};

export const DatePicker = (props: Props) => {
  //useUseableCSS(css);

  const className = cx(styles.picker, props.className, {
    [styles.invalid]: props.isInvalid,
  });

  return (
    <AntDatePicker
      className={className}
      disabledDate={(currentDate => currentDate.isAfter(dayjs(new Date())))}
      onChange={(date, dateString) => props.onChange(date?.toDate?.() ?? null)}
      picker="month"
      size="large"
      value={props.value ? dayjs(props.value) : null} />
  );
};

DatePicker.displayName = 'MedicalProfile.DatePicker';