import { createNamedContext } from '@utils';
import type { WorkspaceFile, WorkspaceFileSummaryData } from '@/types/workspace';

export type FileActionsContextValue = {
  changeOwner: () => unknown;
  delete: () => unknown;
  download: () => Promise<unknown>;
  downloadTranscript: () => unknown;
  manageAccess: () => unknown;
  move: () => unknown;
  rename: () => unknown;
  viewFile: () => unknown;
};

export type FileMenuItemsContextValue = {
  canChangeOwner: boolean;
  canDelete: boolean;
  canDownload: boolean;
  canManageAccess: boolean;
  canMove: boolean;
  canRename: boolean;
  canTranscribe: boolean;
  canImportTranscript: boolean;
  canViewTranscript: boolean;
};

export type RenameFileContextValue = {
  file: WorkspaceFile;
  rename: (name: string) => Promise<unknown>;
};

export type FileSummaryContextValue = {
  summary: WorkspaceFileSummaryData;
  isLoading: boolean;
  download: () => Promise<unknown>;
  refetch: () => Promise<unknown>;
  regenerate: () => Promise<unknown>;
  isRegenerating: boolean;
};

export type UpdateFilePublishedOnContextValue = {
  file: WorkspaceFile;
  update: (date: Date) => Promise<unknown>;
};

type DeleteFile = () => Promise<unknown>;
type DownloadFile = () => Promise<unknown>;
type TranscribeFile = () => Promise<unknown>;
type ImportTranscript = () => Promise<unknown>;
type ViewFileTranscript = () => unknown;

export const DeleteFileContext = createNamedContext<DeleteFile>(undefined, 'delete-file');
export const DownloadFileContext = createNamedContext<DownloadFile>(undefined, 'download-file');
export const FileActionsContext = createNamedContext<FileActionsContextValue>(undefined, 'file-actions');
export const FileMenuItemsContext = createNamedContext<FileMenuItemsContextValue>(undefined, 'file-menu-items');
export const RenameFileContext = createNamedContext<RenameFileContextValue>(undefined, 'rename-file');
export const TranscribeFileContext = createNamedContext<TranscribeFile>(undefined, 'transcribe-file');
export const ImportTranscriptContext = createNamedContext<ImportTranscript>(undefined, 'import-transcript');
export const ViewFileTranscriptContext = createNamedContext<ViewFileTranscript>(undefined, 'view-file-transcript');
export const FileSummaryContext = createNamedContext<FileSummaryContextValue>(undefined, 'file-summary');
export const UpdateFilePublishedOnContext = createNamedContext<UpdateFilePublishedOnContextValue>(undefined, 'update-file-published-on');