import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { arr } from '@utils';

const selectPermissions = (state: Store.State) => state.user.permissions;

const usePermissionValidator = () => {
  const permissions = useSelector(selectPermissions);

  const validator = useCallback((data?: Store.User.Permissions) => {

    return !arr.nonEmpty(data) || permissions.some(x => data.includes(x));

  }, [permissions]);

  return validator;
};

export { usePermissionValidator };
export default usePermissionValidator;
