import { useState, useLayoutEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export const useMeasure = <T extends Element>() => {
  const [element, ref] = useState<T>();
  const [bounds, setBounds] = useState<UseMeasureRect>(initialState);

  const [observer] = useState(() => new ResizeObserver(([entry]: ResizeObserverEntry[]) => {
    if (entry) {
      const { x, y, width, height, top, right, bottom, left } = entry.target.getBoundingClientRect();
      setBounds({ x, y, width, height, top, right, bottom, left });
    }
  }));

  useLayoutEffect(() => {
    if (!element) return;
    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [element, observer]);

  return [ref, bounds] as const;
};

const initialState = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
} as DOMRect;

export type UseMeasureRect =
  Pick<DOMRectReadOnly,
    'x' | 'y' | 'top' | 'left' | 'right' | 'bottom' | 'height' | 'width'
  >;

export default useMeasure;