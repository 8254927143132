import { SurveyBasicQuotaBuilderState } from './State.Basic';
import { BasicQuotaValidationsContainer } from './BasicValidationsContainer';
import { SurveyBasicQuotaBuilder } from './interfaces';

type Props = {
  initial: SurveyBasicQuotaBuilder.State;
} & ChildrenProps;

export const BasicQuotaContainer = (props: Props) => {

  return (
    <SurveyBasicQuotaBuilderState initial={props.initial}>
      <BasicQuotaValidationsContainer>
        {props.children}
      </BasicQuotaValidationsContainer>
    </SurveyBasicQuotaBuilderState>
  );
};