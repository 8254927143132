import { useCallback, useContext } from 'react';
import { Button } from '@/components/Button';
import { Header } from '@/components/Modal/Header';
import { FileUploadReviewStateContext, FileUploadReviewDispatchContext } from '@containers/WorkspaceFileUpload/Context';
import { FileUploadReviewStepperActionsContext } from './context';
import styles from './style/Review.ExistingFiles.css';

export const ExistingFilesReview = () => {

  const { next: nextStep } = useContext(FileUploadReviewStepperActionsContext);

  const reviewState = useContext(FileUploadReviewStateContext);
  const dispatch = useContext(FileUploadReviewDispatchContext);

  const handleConfirm = useCallback((replaceExisting: boolean) => () => {
    dispatch({
      type: 'existing-action-confirmed',
      payload: { replaceExisting },
    });
    nextStep();
  }, [dispatch, nextStep]);

  const items = reviewState.existing.map(k => reviewState.files[k]);

  return (
    <>
      <Header
        className={styles.header}
        text="Duplicate Files" />

      <div className={styles.body}>
        <div className={styles.message}>The following files match existing items in this folder. Would you like to replace the existing items or upload them separately?</div>
        <ul className={styles.items}>
          {items.map(f => (
            <li key={f.name} className={styles.item}>{f.name}</li>
          ))}
        </ul>
      </div>

      <div className={styles.footer}>
        <div className={styles.actions}>
          <Button.Secondary
            className={styles.btn}
            onClick={handleConfirm(false)}
            variant="brick">
            Keep Separate
          </Button.Secondary>
          <Button.Affirmative
            className={styles.btn}
            onClick={handleConfirm(true)}
            variant="brick">
            Replace Existing
          </Button.Affirmative>
        </div>
      </div>
    </>
  );
};