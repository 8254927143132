import http from '@services/http';
import * as xform from '@transformers';
import type { Users } from './interfaces';

export const fetchExpertise = (data: Users.Onboarding.FetchExpertise.Request) => {
  return http.get<Users.Onboarding.FetchExpertise.Response>(`/users/${data.userId}/onboarding/expertise`)
    .then(res => ({
      expertise: xform.mapVICSByType(res.expertise),
      professionalRole: res.professionalRole,
      specialty: res.specialty,
    }));
};

export const fetchBackground = (data: Users.Onboarding.FetchBackground.Request) => {
  return http.get<Users.Onboarding.FetchBackground.Response>(`/users/${data.userId}/onboarding/background`);
};

export const fetchChecklist = (data: Users.Onboarding.FetchChecklist.Request) => {
  return http.get<Users.Onboarding.FetchChecklist.Response>(`/users/${data.userId}/onboarding/checklist`);
};

export const updateCompletion = (data: Users.Onboarding.UpdateCompletion.Request) => {
  const { userId, ...rest } = data;

  return http.put<Users.Onboarding.UpdateCompletion.Response>(`/users/${userId}/onboarding/statuses`, rest);
};

export const updateExpertise = (data: Users.Onboarding.UpdateExpertise.Request) => {
  return http.post<Users.Onboarding.UpdateExpertise.Response>(`/users/${data.userId}/onboarding/expertise`, data);
};

export const updateBackground = (data: Users.Onboarding.UpdateBackground.Request) => {
  const { userId, ...rest } = data;

  return http.put<Users.Onboarding.UpdateBackground.Response>(`/users/${userId}/onboarding/background`, rest);
};

export const updateRole = (data: Users.Onboarding.UpdateProfessionalRole.Request) => {
  const { userId, ...rest } = data;

  return http.put<Users.Onboarding.UpdateProfessionalRole.Response>(`/users/${userId}/onboarding/expertise/role`, rest);
};

export const getInvitedScreeners = () => {
  return http.get<Users.Onboarding.GetInvitedScreeners.Response>(`/users/screeners`);
};