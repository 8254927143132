import { useMemo } from 'react';
import { SurveyOptionType } from '@enums';
import { cx } from '@utils';
import { useMatrixFormContext } from '@/components/SurveyForm/context';
import SurveyMatrixGrid from './Grid';
import { SurveyMatrixList } from './List';
import styles from './style/Main.css';

const MaxGridOptions = 6;

export const SurveyMatrixGridContainer = () => {

  const ctx = useMatrixFormContext();

  const displayGrid = useMemo(() => {
    const filteredOptions = ctx.options.filter(f => f.type !== SurveyOptionType.NotApplicable);

    return (ctx.display === 'grid' ||
      filteredOptions.length <= MaxGridOptions) &&
      ctx.display !== 'list';
  }, [
    ctx.display,
    ctx.options,
  ]);

  const gridClassName = cx({
    [styles.hide]: !displayGrid,
    [styles.desktop]: displayGrid,
  });

  const listClassName = cx({
    [styles.mobile]: displayGrid,
  });

  return (
    <>
      <SurveyMatrixList className={listClassName} />
      <SurveyMatrixGrid className={gridClassName} />
    </>
  );
};

export default SurveyMatrixGridContainer;