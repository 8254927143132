import { DispatchTooltipContext } from './Context';
import { useTooltipHandlers } from './hooks';
import TooltipWrapper from './Wrapper';

type Props =
  ChildrenProps;

export const TooltipContainer = (props: Props) => {
  const {
    anchor,
    content: tooltipContent,
    position,
    visible: tooltipVisible,
    ...dispatch
  } = useTooltipHandlers();

  return (
    <DispatchTooltipContext.Provider value={dispatch}>
      {tooltipVisible &&
        <TooltipWrapper
          anchor={anchor}
          position={position}>
          {tooltipContent}
        </TooltipWrapper>}
      {props.children}
    </DispatchTooltipContext.Provider>
  );
};

export default TooltipContainer;