import { CardContent, Divider } from '@presentation/Main';
import Editing from './Tags.Editing';
import Readonly from './Tags.Readonly';
import { useGroupTags } from './hooks';
import styles from './style.css';

export default function Tags() {
  const { editing } = useGroupTags();

  return (
    <>
      <Divider />
      <CardContent className={styles.content}>
        {editing
          ? <Editing />
          : <Readonly />}
      </CardContent>
    </>
  );
}

export { Tags };