import { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import * as $api from '@api/projects.conference';
import { ConferenceTags } from '@api/interfaces/projects';

type Data = ConferenceTags.GetProjectConferenceTagSummaries.Response;
type Params = IProjectId;
type QueryKey = readonly [string, Params];

export function useProjectConferenceTagSummaries(
  data: Params,
  options?: UseQueryOptions<Data, AxiosResponse, Data, QueryKey>
) {

  const qk = projectTagSummariesQueryKey(data);

  return useQuery(qk, ({ queryKey }) => {
    const { projectId } = queryKey[1];

    return $api.getTagSummaries({ projectId });

  }, options);
}

export const projectTagSummariesQueryKey = (data: Params): QueryKey => ['get:projects/conference-tags/summaries', data];

type Updater = (old: ConferenceTags.GetProjectConferenceTagSummaries.Response) => ConferenceTags.GetProjectConferenceTagSummaries.Response;

export const useUpdateStoredProjectTagSummaries = ({ projectId }: Params) => {
  const qk = projectTagSummariesQueryKey({ projectId });

  const queryClient = useQueryClient();

  const handleUpdate = useCallback((updater: Updater) => {
    queryClient.setQueryData(qk, updater);
  }, [queryClient, qk]);

  return handleUpdate;
};

export const useInvalidateProjectTagSummaries = (props: Params) => {
  const qk = projectTagSummariesQueryKey(props);

  const queryClient = useQueryClient();

  const handleInvalidate = useCallback(() => {
    return queryClient.invalidateQueries(qk);
  }, [queryClient, qk]);

  return handleInvalidate;
};