import { memo } from 'react';

type Props = {
  className?: string;
  size: number;
};

export const Upload = memo(({ className, size }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="3 2 18 19"
      className={className}>
      <path
        d="M9 16h6v-6h4l-7-7-7 7h4v6zm3-10.17L14.17 8H13v6h-2V8H9.83L12 5.83zM5 18h14v2H5z"
        fill="currentColor" />
    </svg>
  );
});

