import { BackButton, Footer, NextButton } from '@screens/ProjectCreation/Footer';

type Props = unknown;

export const TaggingNavigation = (props: Props) => {

  return (
    <Footer>
      <BackButton />
      <NextButton to="/contacts/import/summary">
        Next
      </NextButton>
    </Footer>
  );
};

TaggingNavigation.displayName = 'TaggingNavigation';