import { memo } from 'react';
import { cx } from '@utils';
import styles from './style/Card.css';

type Props = {
  onClick?: () => void;
};

export const NetPromoterScoreCard = memo(({ onClick }: Props) => {

  const title = `Brand Score`;

  return (
    <div
      className={styles.root}
      onClick={onClick}>
      <div className={cx(styles.poster, styles.netPromoter)}>
        <Icon />
      </div>
      <div className={styles.footer}>
        <div className={styles.title}>{title}</div>
      </div>
    </div>
  );
});

type IconProps = {
  color?: string;
};

const Icon = memo(({
  color = '#346394',
}: IconProps) => (
  <svg
    width="53px"
    height="28px"
    viewBox="0 0 44 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g
      id="Survey-Templates---Net-Promoter"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd">
      <g
        id="1"
        transform="translate(-678.000000, -376.000000)"
        stroke={color}
        strokeWidth="2">
        <g
          id="Group-4"
          transform="translate(679.000000, 377.000000)">
          <path
            d="M35.2156003,21 C39.7385334,21 42,21 42,21 C42,9.40202025 32.5979797,0 21,0 C9.40202025,0 0,9.40202025 0,21 C0,21 2.27872424,21 6.83617272,21 C7,21 7,21 7,21 C7,13.2680135 13.2680135,7 21,7 C28.7319865,7 35,13.2680135 35,21 L35.2156003,21 Z"
            id="Path" />
          <line
            x1="22.3019912"
            y1="18.9768928"
            x2="28.1398053"
            y2="16.3823088"
            id="Path-12"
            strokeLinecap="round" />
          <circle
            id="Oval"
            cx="20"
            cy="20"
            r="2" />
          <path
            d="M6.46753048,6.45815039 L11.204044,11.1946639 M30.8172796,11.1733403 L35.5324695,6.45815039"
            id="Shape" />
        </g>
      </g>
    </g>
  </svg>
));