import { SurveyTypeOld } from '@enums';
import http from '@services/http';
import type { Surveys } from './interfaces/surveys';

export const getSurveyResponseStatus = (data: Surveys.CaptureSheets.GetSurveyResponseStatus.Request) => {
  return http.get<Surveys.CaptureSheets.GetSurveyResponseStatus.Response>(
    `/conferences/${data.callId}/capture-sheet/${data.surveyId}/status`
  );
};

export const getSurveyForm = (data: Surveys.CaptureSheets.GetSurveyForm.Request) => {
  return http.get<Surveys.CaptureSheets.GetSurveyForm.Response>(
    `/conferences/${data.callId}/capture-sheet/${data.surveyId}/survey-form`
  );
};

export const getSurveyFormPreview = (data: Surveys.CaptureSheets.GetSurveyFormPreview.Request) => {
  return http.get<Surveys.CaptureSheets.GetSurveyFormPreview.Response>(
    `/conferences/${data.callId}/capture-sheet/survey-version/${data.surveyVersionId}/preview`
  );
};

export const goBack = ({ callId, data }: Surveys.CaptureSheets.GoBack.Request) => {
  return http.patch<Surveys.CaptureSheets.GoBack.Response>(`/conferences/${callId}/capture-sheet/survey-versions/${data.surveyVersionId}/responses`, {
    type: 'back',
    body: data,
  });
};

export const goToItem = ({ callId, data }: Surveys.CaptureSheets.GoToItem.Request) => {
  return http.patch<Surveys.CaptureSheets.GoBack.Response>(`/conferences/${callId}/capture-sheet/survey-versions/${data.surveyVersionId}/responses`, {
    type: 'go-to-item',
    body: data,
  });
};

export const submitMessageScreen = ({ callId, data }: Surveys.CaptureSheets.SubmitMessageScreen.Request) => {
  return http.patch<Surveys.CaptureSheets.SubmitMessageScreen.Response>(`/conferences/${callId}/capture-sheet/survey-versions/${data.surveyVersionId}/responses`, {
    type: 'submit-message-screen',
    body: data,
  });
};

export const submitAnswer = ({ callId, data }: Surveys.CaptureSheets.SubmitAnswer.Request) => {
  return http.patch<Surveys.CaptureSheets.SubmitAnswer.Response>(`/conferences/${callId}/capture-sheet/survey-versions/${data.surveyVersionId}/responses`, {
    type: 'submit-answer',
    body: data,
  });
};

export const submitAIEScreen = ({ callId, data }: Surveys.CaptureSheets.SubmitAlternateImageExercise.Request) => {
  return http.patch<Surveys.CaptureSheets.SubmitAlternateImageExercise.Response>(`/conferences/${callId}/capture-sheet/survey-versions/${data.surveyVersionId}/responses`, {
    type: 'submit-aie-screen',
    body: data,
  });
};

export const saveProjectSurveyResponse = ({ callId, data }: Surveys.CaptureSheets.SaveProjectSurveyResponse.Request) => {
  return http.patch<Surveys.CaptureSheets.SaveProjectSurveyResponse.Response>(
    `/conferences/${callId}/capture-sheet/${data.surveyVersionId}/response?type=${SurveyTypeOld.Project}`,
    data
  );
};