import { useContext } from 'react';
import { CallRole } from '@enums';
import { createNamedContext } from '@utils';

type TagsToSummarizeContextValue = [number[], React.Dispatch<React.SetStateAction<number[]>>];

export const TagToSummarizeContext = createNamedContext<TagsToSummarizeContextValue>(null, 'TagToSummarizeContext');
export const useTagsToSummarize = () => useContext(TagToSummarizeContext);

type RoleFilterContextValue = [CallRole[], React.Dispatch<React.SetStateAction<CallRole[]>>];

export const RoleFilterContext = createNamedContext<RoleFilterContextValue>(null, 'RoleFilterContext');
export const useRoleFilterContext = () => useContext(RoleFilterContext);