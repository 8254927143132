import { useCallback } from 'react';
import { ButtonActivityIndicator } from '@presentation';
import { useAsyncStateLazy } from '@utils';
import * as api from '$admin/api';
import Button from '@/components/Button';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { Header } from '@/components/Modal/Header';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import { Project } from '$admin/interfaces';
import styles from './style/Project.RejectMember.css';

type InviteItem = {
  user: Pick<Project.Members.Item['user'], 'id'>;
};

type Props = {
  projectId: number;
  items: InviteItem[];
  onConfirm?: () => unknown;
} & Pick<ModalProps, 'open' | 'onClose'>;

export const InviteMembers = ({ projectId, items, open, onClose, onConfirm }: Props) => {
  const handleConfirm = useCallback(() => {
    return api.projects.members.inviteUsers({
      projectId: projectId,
      userIds: items.map(i => i.user.id),
    })
    .finally(onConfirm);
  }, [projectId, items, onConfirm]);

  const [response, confirm] = useAsyncStateLazy(handleConfirm, [handleConfirm]);

  return (
    <Modal
      onClose={onClose}
      open={open}>
      <Header>{`Invite ${items.length} expert${items.length > 1 ? 's' : ''}?`}</Header>
      <ButtonSet className={styles.buttonset}>
        <Button
          className={styles.cancel}
          color="destructive"
          onClick={onClose}
          variant="brick">
          Cancel
        </Button>
        <ButtonActivityIndicator
          className={styles.btn}
          color="primary"
          loading={response.loading}
          onClick={confirm}
          variant="brick">
          Invite
        </ButtonActivityIndicator>
      </ButtonSet>
    </Modal>
  );
};

InviteMembers.displayName = 'Modal.Project.InviteMembers';