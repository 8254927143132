import { memo, useMemo } from 'react';
import { animated } from '@react-spring/web';
import { useAnimatedPath } from '@utils/hooks/useAnimatedPath';
import { SerieLineItemProps } from './interfaces';

export const SerieLineItem = memo(({
  lineGenerator,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onMouseMove,
  serie,
}: SerieLineItemProps) => {

  const linePath = useMemo(() => {
    const points = serie.data.map(m => m.position);
    return lineGenerator(points);
  }, [
    lineGenerator,
    serie.data,
  ]);

  const animatedPath = useAnimatedPath(linePath);

  return (
    <>
      <animated.path
        fill="none"
        d={animatedPath}
        stroke={serie.color}
        strokeWidth={serie.style.lineWidth}
        strokeLinecap="round"
        strokeOpacity={serie.style.opacity} />
      <path
        fill="none"
        stroke="red"
        cursor="pointer"
        strokeOpacity={0}
        strokeWidth={15}
        d={linePath}
        onClick={onClick}
        strokeLinecap="butt"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove} />
    </>
  );
});

export default SerieLineItem;