import { useCallback, useContext, useState } from 'react';
import styled from '@emotion/styled';
import { useToggle } from '@/brand-insights/hooks';
import type { Chat } from '@/brand-insights/types';
import { ChatStateDispatchContext } from '@/brand-insights/components/Chat.State';
import { MessageQueryHintContainer, MessageQueryHint } from '@/brand-insights/components/presentation';
import { ChatResponsePane } from '@/brand-insights/components/Chat/ResponsePane';
import { ChatHintType } from '@/brand-insights/enums';
import { RateLimitingContext } from '@containers/RateLimiting/Context';
import { ActiveChatSessionContext } from './context';
import { useStartSegmentationMutation } from './hooks';
import { useScrollToBottomOfMessages } from './hooks';

type Props = {
  className?: string;
  hints: Chat.QueryHint.Item[];
};

export const MessageQueryHints = ({ className, hints }: Props) => {
  const chat = useContext(ActiveChatSessionContext);
  const dispatch = useContext(ChatStateDispatchContext);
  const { query } = useContext(RateLimitingContext);
  const [disabled, setDisabled] = useState<boolean>(query.data?.chats?.exceeded);

  const startSegmentation = useStartSegmentationMutation();
  const scrollToBottom = useScrollToBottomOfMessages();

  const handleHint = useCallback((hint: Chat.QueryHint.Item) => () => {
    if (hint.type === ChatHintType.Text) {
      dispatch({
        type: 'input-changed',
        payload: { value: hint.displayValue },
      });
    } else if (hint.type === ChatHintType.FindQuotes) {
      dispatch({
        type: 'pending-find-quotes/set',
        payload: { value: true },
      });
      scrollToBottom({ nthFromBottom: 1 });
    } else if (hint.type === ChatHintType.AnalysisTags) {
      setDisabled(true);
      startSegmentation.mutateAsync({
        chatInstanceIdentifier: chat.identifier,
        context: chat.context,
      })
      .finally(() => {
        setDisabled(false);
      });
    }
  }, [
    dispatch,
    scrollToBottom,
    startSegmentation,
    chat.identifier,
    chat.context,
  ]);

  return (
    <MessageQueryHintContainer className={className}>
      {hints.map((hint, i) => (
        <MessageQueryHint
          key={`${i}`}
          hint={hint}
          onClick={handleHint(hint)}
          disabled={disabled || hint.disabled} />
      ))}
    </MessageQueryHintContainer>
  );
};

export const RelatedQuestionsPane = (props: Props) => {
  const [open, toggleOpen] = useToggle(true);

  return (
    <ChatResponsePane
      className={props.className}
      open={open}
      toggleOpen={toggleOpen}
      title="Related Questions">
      <PaneHints hints={props.hints} />
    </ChatResponsePane>
  );
};

const PaneHints = styled(MessageQueryHints)(({ theme }) => ({
  minHeight: 0,
  marginTop: 10,
  transition: `height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
}));