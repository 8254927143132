import { useMemo } from 'react';
import { SurveyBuilderItemEditingContext, SurveyBuilderItemContext } from './Context';
import { useSurveyBuilderState } from './hooks';

type Props = {
  itemIdentifier: string;
} & ChildrenProps;

export const SurveyBuilderItemContainer = (props: Props) => {

  const [state] = useSurveyBuilderState();

  const item = useMemo(() => {
    return state.survey.items.find(f => f.identifier === props.itemIdentifier);
  }, [props.itemIdentifier, state.survey.items]);

  const canEdit = useMemo(() => !state.editing.itemIdentifier, [state.editing.itemIdentifier]);
  const editing = useMemo(() => state.editing.itemIdentifier === props.itemIdentifier, [state.editing.itemIdentifier, props.itemIdentifier]);

  return (
    <SurveyBuilderItemEditingContext.Provider value={{ canEdit, editing }}>
      <SurveyBuilderItemContext.Provider value={item}>
        {props.children}
      </SurveyBuilderItemContext.Provider>
    </SurveyBuilderItemEditingContext.Provider>
  );
};