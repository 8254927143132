import { Posts as IPosts } from '@/types';

export enum SearchRouteKey {
  All     = 'all',
  Members = 'members',
  Topics  = 'topics',
  Posts   = 'posts',
}

export type SearchSceneMap = {
  [key in SearchRouteKey]: TabScene;
};

export type TabScene = {
  component: React.ComponentType;
  title:     string | React.ReactNode;
};

export type ExpertsTableItem = {
  entityId: number;
  name: string;
  path: string;
  metadata: {
    first: string;
    last: string;
    pictureUrl: string;
    title: string;
  };
}

export type TopicsTableItem = {
  description: string;
  entityId: number;
  name: string;
  path: string;
  metadata: {
    pictureUrl: string;
  };
}

export type PostsTableItem = {
  entityId: number;
  path: string;
  metadata: {
    author: IPosts.Post['author'];
    body: IPosts.Post['body'];
    media: IPosts.Post['media'];
    createdOn: Date;
  };
}

export type TableItem =
  | ExpertsTableItem
  | TopicsTableItem
  | PostsTableItem;