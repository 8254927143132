import { useContext, useMemo } from 'react';
import { format, formatDistanceStrict } from 'date-fns';
import * as scheduler from '@containers/Scheduler/utils';
import { useSelectUser } from '@containers/Store';
import { CallStatus } from '@enums';
import { cx, hasSchedulingPermission, hasInternalAdminRole } from '@utils';
import * as CallScheduling from '$admin/components/Scheduling';
import { CallDetailsContext } from './Context';
import * as Layout from './Layout';
import styles from './style/Field.css';

type Props = unknown;

export const Time = (props: Props) => {
  const ctx = useContext(CallDetailsContext);

  const { call } = ctx.query.data;

  const time = useMemo(() => {

    if (!call.timeEnd || !call.timeStart) return 'Not Set';

    const start = format(new Date(call.timeStart), 'M/d/yy hh:mm a');
    const end = format(new Date(call.timeEnd), 'hh:mm a');
    const duration = formatDistanceStrict(new Date(call.timeEnd), new Date(call.timeStart), {
      unit: 'minute',
    });

    return `${start} - ${end} (${duration})`;

  }, [call]);

  const deemphasize = [
    CallStatus.Cancelled,
    CallStatus.Rejected,
    CallStatus.Rescheduling,
  ].includes(call.statusId);

  const classnames = cx({
    [styles.deemphasis]: deemphasize,
  });

  return (
    <div className={styles.row}>
      <Layout.Label>Scheduled Time</Layout.Label>
      <div className={styles.time}>
        <span className={classnames}>{time}</span>
        <ScheduleCallButton />
      </div>
    </div>
  );
};

Time.displayName = 'Call.Main.Field.Time';

const ScheduleCallButton = (props: Props) => {
  const ctx = useContext(CallDetailsContext);
  const user = useSelectUser();

  const canScheduleCall = useMemo(() => {
    if (!ctx.editable) return false;

    const isAdmin = hasInternalAdminRole(user);
    const isOwner = hasSchedulingPermission(user)
      && user.id === ctx.query.data?.call?.owner?.id;
    const externalRespondent = ctx.query.data?.call?.respondent?.offPlatform;

    const permissionToSchedule = isAdmin
      || (isOwner && externalRespondent);

    if (!permissionToSchedule) return false;

    return scheduler.calls.isScheduleable({
      call: {
        approvalStatusId: ctx.query.data?.call?.approvalStatusId,
        statusId: ctx.query.data?.call?.statusId,
      },
      memberStatusId: ctx.query.data?.call?.respondent?.statusId,
    });
  }, [
    ctx,
    user,
  ]);

  if (!canScheduleCall) return null;

  return (
    <CallScheduling.Button
      callId={ctx.query.data.call.id}
      projectId={ctx.query.data.project.id}
      userId={ctx.query.data.call.respondent.id} />
  );
};