import { useCallback, useEffect, useState, useRef, memo } from 'react';
import { useEventListener } from '@/utils/hooks';
import { AnimatedLoader } from '@/components/ActivityIndicator';
import { useTipaltiOnboardingPayeeUrl } from './hooks/useTipaltiOnboardingPayeeUrl';

type Props = {
  userId: number;
  width?: number;
};

export const PayeeDashboard = ({ userId, width = Constants.Defaults.desktopWidth }: Props) => {
  const [height] = useIFrameResizer();

  const query = useTipaltiOnboardingPayeeUrl({
    userId,
    redirect: false,
    updating: true,
  });

  const visible = useIFrameVisibility({
    url: query.data?.url,
    reportedHeight: height,
  });

  return (
    <>
      <WaitingOnIFrame isLoading={!visible} />
      <iframe
        height={height}
        width={width}
        style={{
          border: 'none',
          display: visible ? 'inherit' : 'none',
        }}
        src={query?.data?.url} />
    </>
  );
};

type WaitingOnIFrameProps = {
  isLoading: boolean;
};

const WaitingOnIFrame = memo(({ isLoading }: WaitingOnIFrameProps) => {
  if (!isLoading) return null;

  return <AnimatedLoader />;
});

type UseIFrameVisibilityProps = {
  url: string;
  reportedHeight?: number;
};

function useIFrameVisibility({ url, reportedHeight }: UseIFrameVisibilityProps) {
  const timer = useRef<NodeJS.Timeout>();
  const fallbackTimer = useRef<NodeJS.Timeout>();
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!visible && url?.length && reportedHeight > (Constants.loadingHeight + Constants.Defaults.bottomMargin)) {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        setVisible(true);
      }, 250);
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [visible, reportedHeight, url]);

  useEffect(() => {
    if (!visible && url?.length) {
      if (fallbackTimer.current) {
        clearTimeout(fallbackTimer.current);
      }
      fallbackTimer.current = setTimeout(() => {
        setVisible(true);
      }, 5000);
    }

    return () => {
      if (fallbackTimer.current) {
        clearTimeout(fallbackTimer.current);
      }
    };
  }, [visible, url]);

  return visible;
}

function useIFrameResizer() {
  const [height, setHeight] = useState<number>(Constants.Defaults.height);

  const handleWindowEvent = useCallback((event: IFrameTipaltiMessage) => {
    if (event.data?.TipaltiIframeInfo) {
      const eventHeight = event.data.TipaltiIframeInfo?.height;

      if (eventHeight) {
        const updatedHeight = Math.max(Constants.minHeight, eventHeight + Constants.Defaults.bottomMargin);
        if (height !== updatedHeight) {
          setHeight(updatedHeight);
        }
      }
    }
  }, [height]);

  useEventListener('message', handleWindowEvent);

  return [height] as const;
}

const Constants = {
  Defaults: {
    height: 200,
    desktopWidth: 650,
    bottomMargin: 50,
  },
  loadingHeight: 200,
  minHeight: 200,
};

interface IFrameTipaltiMessage extends Event {
  data?: {
    TipaltiIframeInfo?: {
      height: number;
      width: number;
    };
    caller: 'PaymentDetails';
  };
}