import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { ProjectHonorariaContext, ProjectHonorariaRefetchContext } from '@containers/Project.Honoraria';
import * as api from '$admin/api';
import * as API from '$admin/api/interfaces';
import * as Field from '$admin/components/Project.Share/Field';
import { Honorarium } from '$admin/components/Project.Share/Project.Share.Honorarium';
import * as Details from '$admin/interfaces/project.details';
import styles from './style/Project.Rate.css';

type Rate = {
  currency: string;
  value:    number;
};

type Props = {
  onChange: (rate: Rate) => void;
} & IProjectId;

export const ProjectRate = ({ onChange, ...props }: Props) => {
  const ctxItems = useContext(ProjectHonorariaContext);
  const refetch = useContext(ProjectHonorariaRefetchContext);
  const [selected, setSelected] = useState<Details.Honorarium>(ctxItems[0] ?? null);
  const [creating, setCreating] = useState<boolean>(false);

  const handleRateChange = useCallback((data: Details.Honorarium) => {
    const rate = data
        ? { currency: data?.currency?.code, value: data?.value }
        : null;

    setSelected(data);
    onChange?.(rate);
  }, [
    onChange,
    setSelected,
  ]);

  const mutation = useMutation([
    `post:admin/projects/billing/honoraria`,
    props.projectId,
  ], (params: Omit<API.Projects.CreateHonoraria.Request, 'projectId'>) => {
    return api.projects.billing.createHonoraria({
      currency:  params.currency,
      projectId: props.projectId,
      title:     params.title,
      value:     params.value,
    });
  }, {
    onSuccess: async (_, variables) => {
      const response = await refetch();

      const honorarium = response.find(x => {
        return x.currency.code === variables.currency
            && x.value === variables.value
            && x.title === variables.title;
      });

      if (honorarium) {
        setCreating(false);
        handleRateChange(honorarium);
      }
    },
  });

  const handleSelectCreate = useCallback(() => {
    handleRateChange(null);
    setCreating(true);
  }, [handleRateChange]);

  const handleChange = useCallback((params: Details.Honorarium) => {
    handleRateChange(params);

    if (creating) {
      setCreating(false);
    }
  }, [
    creating,
    handleRateChange,
  ]);

  const options = useMemo(() => {
    return !props.projectId // || ctx.query.isRefetching
      ? []
      : ctxItems;
  }, [
    props.projectId,
    ctxItems,
  ]);

  useEffect(() => {

    if (!props.projectId && !!selected) {
      handleRateChange(null);
      setCreating(false);
    }

  }, [
    handleRateChange,
    props.projectId,
    selected,
  ]);

  useEffect(() => {

    if (props.projectId && !creating && !selected && options.length) {
      handleRateChange(options[0]);
    }

  }, [
    creating,
    handleRateChange,
    options,
    props.projectId,
    selected,
  ]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.row}>
            <Field.Select
              className={styles.select}
              defaultValue={null}
              disabled={!props.projectId}
              onChange={handleChange}
              onSelectCreate={handleSelectCreate}
              options={options}
              placeholder="Select Honoraria"
              value={selected || undefined} />
          </div>
          {(creating && !!props.projectId)
            ? <Honorarium
                titles={options.map(x => x.title)}
                loading={mutation.isLoading}
                onSubmit={mutation.mutate} />
            : <DisplayRate selected={selected} />}
        </div>
      </div>
    </div>
  );
};

ProjectRate.displayName = 'Project.Rate';

type DisplayRateProps = {
  selected: Details.Honorarium;
};

const DisplayRate = ({ selected, ...props }: DisplayRateProps) => {

  const display = useMemo(() => {
    const sign = selected?.currency?.sign ?? ``;
    const value = selected?.value ?? ``;
    const code = selected?.currency?.code ? ` ${selected.currency.code}` : ``;

    return value
        ? `${sign}${value}${code}`
        : ``;
  }, [selected]);

  if (!display) return null;

  return (
    <div className={styles.readonly}>
      {display}
    </div>
  );
};

DisplayRate.displayName = 'Project.Rate.DisplayRate';