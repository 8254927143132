import { ChangeEvent, useCallback, useState, useEffect, useMemo, useRef } from 'react';
import ReactCrop, { Crop, PercentCrop, PixelCrop, ReactCropProps, convertToPixelCrop } from 'react-image-crop';
import reactCropCss from 'react-image-crop/dist/ReactCrop.css';
import { cx } from '@utils';
import { useUseableCSS } from '@utils/hooks';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import ActivityIndicator from '@/components/ActivityIndicator';
import { FilePicker } from '@/components/FilePicker';
import { useCreateCropPreview, useLoadImage } from '@/components/ImageCrop/hooks';
import { checkContentType, centerAspectCrop, getBlob } from './utils';
import styles from './style.css';

type Props = {
  classes?: {
    root?:     string;
    original?: string;
    picker?:   string;
    preview?: {
      root?: string;
      image?: string;
    };
  };
  crop?:         Pick<Crop, 'height' | 'width'>;
  imageUrl:      string;
  isSubmitting?: boolean;
  onSave: (data: {
    blob: Blob;
    url: string;
  }) => unknown;
  placeholders?: boolean;
  previewClassName?: string;
} & Pick<ReactCropProps,
      'aspect'
    | 'circularCrop'
    | 'ruleOfThirds'>;

export const ImageCrop = ({
  aspect = DefaultOpts.aspect,
  classes = { preview: {} },
  isSubmitting = false,
  onSave,
  ...props
}: Props) => {
  const imgRef = useRef<HTMLImageElement>();
  const previewCanvasRef = useRef<HTMLCanvasElement>();
  const [previewUrl, setPreviewUrl] = useState<string>(props.imageUrl);
  const [contentType, setContentType] = useState<string>(null);
  const [crop, setCrop] = useState<PercentCrop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  const createCropPreview = useCreateCropPreview();
  const { load: loadSource, ...source } = useLoadImage({
    maxHeight: props.crop?.height,
    maxWidth: props.crop?.width,
  });

  useUseableCSS(reactCropCss);

  const isContentTypeValid = useMemo(() => !contentType || checkContentType(contentType), [contentType]);

  const reloadSource = useCallback(async (data: File | Blob | string) => {
    return loadSource(data)
      .then(({ height, width }) => {
        const newPctCrop = centerAspectCrop(width, height, aspect);
        setCrop(newPctCrop);
        setCompletedCrop(convertToPixelCrop(newPctCrop, imgRef.current?.width, imgRef.current?.height));
      });
  }, [
    aspect,
    loadSource,
  ]);

  useEffect(() => {
    if (props.imageUrl) {
      // note(brian): needed for CORS to force an OPTIONS
      reloadSource(`${props.imageUrl}?_=${new Date().getTime()}`);
    }
  }, [
    props.imageUrl,
    reloadSource,
  ]);

  useEffect(() => {
    if (completedCrop &&
      imgRef.current &&
      previewCanvasRef.current) {
      createCropPreview({
        canvas: previewCanvasRef.current,
        contentType,
        crop: completedCrop,
        image: imgRef.current,
      })
      .then(setPreviewUrl);
    }
  }, [
    contentType,
    createCropPreview,
    completedCrop,
    setPreviewUrl,
  ]);

  const clearCanvas = useCallback(() => {
    const canvas = previewCanvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  }, []);

  const onSelectFile = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setContentType(file.type);

      if (checkContentType(file.type)) {
        reloadSource(file);
      } else {
        setPreviewUrl(null);
        clearCanvas();
      }
    }
  }, [clearCanvas, reloadSource]);

  const onCropComplete = useCallback((data: PixelCrop) => {
    setCompletedCrop(data);
  }, []);

  const onCropChange = useCallback((crop: PixelCrop, percentageCrop: PercentCrop) => {
    setCrop(percentageCrop);
  }, [setCrop]);

  const handleImageLoad = useCallback((e: React.ChangeEvent<HTMLImageElement>) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }, [aspect]);

  const handleSaveClicked = useCallback(() => {
    return getBlob(previewUrl)
    .then(blob => {
      onSave({
        blob,
        url: previewUrl,
      });
    });
  }, [onSave, previewUrl]);

  const renderOriginal = () => {

    const show = source.value && !source.loading
      && isContentTypeValid;

    const pickerclasses = cx({
      [styles.originalImageContainer]: !classes.picker,
    }, classes.picker);

    const originalclasses = cx({
      [styles.originalImage]: !classes.original,
    }, classes.original);

    return (
      <div className={pickerclasses}>
        <FilePicker
          className={styles.filePicker}
          onSelect={onSelectFile} />
        <ActivityIndicator show={source.loading} />
        {show && (
          <ReactCrop
            aspect={aspect}
            crop={crop}
            onComplete={onCropComplete}
            onChange={onCropChange}
            className={originalclasses}
            maxHeight={175}
            circularCrop={props.circularCrop}
            ruleOfThirds={props.ruleOfThirds}>
            <img
              ref={imgRef}
              onLoad={handleImageLoad}
              src={source.value} />
          </ReactCrop>
        )}
        {(!show && props.placeholders) &&
          <div
            className={cx(originalclasses, styles.placeholder)}
            style={{
              height: props.crop.height,
              width: props.crop.width,
            }} />
        }
      </div>
    );
  };

  const renderPreview = useCallback(() => {
    const previewclasses = cx({
      [styles.croppedImageContainer]: !classes.preview?.root,
    }, classes.preview?.root);
    const imageclasses = cx(
      { [styles.croppedImage]: !classes.preview?.image },
      props.previewClassName,
      classes.preview?.image,
    );

    const showPlaceholder = !previewUrl && props.placeholders;
    const showPreviewText = showPlaceholder || previewUrl;

    return (
      <div className={previewclasses}>
        {showPreviewText &&
          <>
            <div className={styles.previewTop} />
            <div className={styles.previewText}>Preview</div>
          </>
        }
        {showPlaceholder && <div className={cx(imageclasses, styles.placeholder)} />}
        <canvas
          ref={previewCanvasRef}
          className={imageclasses}
          style={{
            objectFit: 'contain',
          }} />
      </div>
    );
  }, [
    classes.preview?.image,
    classes.preview?.root,
    previewUrl,
    props.placeholders,
    props.previewClassName,
    // source.loading,
  ]);

  const saveButtonEnabled = useMemo(() => {
    return previewUrl !== null &&
      isContentTypeValid;
  }, [
    isContentTypeValid,
    previewUrl,
  ]);

  const renderWarning = useCallback(() => {
    let warning: string = null;
    if (contentType && !isContentTypeValid) {
      warning = 'Only png and jpeg image formats are supported';
    } else if (source.error) {
      warning = 'There was an error loading the image';
    }

    return <div className={styles.warning}>{warning}</div>;

  }, [contentType, isContentTypeValid, source.error]);

  const rootclasses = cx({
    [styles.container]: !classes.root,
  }, styles.content, classes.root);

  return (
    <div className={styles.root}>
      <div className={rootclasses}>
        {renderOriginal()}
        {renderPreview()}
      </div>
      {renderWarning()}
      <div className={styles.footer}>
        <ButtonActivityIndicator
          className={styles.btn}
          disabled={!saveButtonEnabled}
          implicitDisable={isSubmitting}
          loading={isSubmitting}
          onClick={handleSaveClicked}>
          Save
        </ButtonActivityIndicator>
      </div>
    </div>
  );
};

const DefaultOpts = {
  aspect: 1,
  height: 230,
  width: 230,
};