import { useCallback, useMemo } from 'react';
import {
  useCanSaveMedicalCondition,
  useCanSaveDrug,
  useTemplateDrug,
  useTemplateLaunchYear,
  useTemplateMedicalCondition,
  useTemplateTrialDesign,
  useTemplateQualityOfLife,
  useTemplateSafetyEndpoints,
  useTemplateEfficacyEndpoints,
  TemplateMedicalConditionInput,
  TemplateDrugInput,
  TemplateDrugIndicationInput,
  TemplateLaunchYearInput,
  TemplateTrialDesignInput,
  TemplateQualityOfLifeInput,
  TemplateOtherAttributesInput,
  TemplateSafetyEndpointsInput,
  TemplateEfficacyEndpointsInput,
  useCanSaveLaunchYear,
  useCanSaveTrialDesign,
  useCanSaveQualityOfLife,
  useCanSaveOtherAttributes,
  useCanSaveSafetyEndpoints,
  useTemplateOtherAttributes,
  useTemplateDrugIndication,
  useCanSaveDrugIndication,
  useCanSaveEfficacyEndpoints,
  useCanSaveComparators,
  useTemplatePricingComparators,
  useTemplateTreatmentComparators,
  TemplateComparatorsInput,
  useTemplateDrugPrice,
  useCanSaveDrugPrice,
  TemplateDrugPriceInput,
  useCanSaveTreatmentCategory,
  useTemplateTreatmentCategory,
  TemplateTreatmentCategoryInput,
  TemplateStimulusInput,
  TemplateStimulusInputContainer,
  useCanSaveStimulus,
  useSubmitStimulus,
  useCanSaveClient,
  useTemplateClient,
  TemplateClientInput,
  TPPTableSelection,
  useTemplateTPP,
  useCanSaveDoseAdministration,
  useTemplateDoseAdministration,
  TemplateDoseAdministrationsInput,
  useAdditionalPriceInputs,
  AdditionPriceInputs,
} from '@/components/Survey.Templates';
import { useTargetProductProfileData } from '@/containers/SurveyBuilder';
import {
  HelpText,
  ThanksPresetScreen,
} from './ProjectSurvey.Presets.Shared';
import { BackButton, Footer, NextButton, SkipStep } from './Footer';
import { useProjectSurveyStepperContext } from './context';
import { BasePresetScreen } from './ProjectSurvey.Presets.Shared';
import styles from './style/ProjectSurvey.Presets.css';

const LetsGetStarted = () => {

  const title = `Great, let's get started building your survey!`;
  const subtitleOne = `To set up your Target Product Profile Survey template, we’ll ask a couple of questions which will be used to pre-populate the survey draft.`;
  const subtitleTwo = `You'll then be able to customize the survey draft with specific questions through our survey builder. Let's jump in!`;

  const actions = useProjectSurveyStepperContext();

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitleOne, subtitleTwo]} />
      <Footer>
        <BackButton onClick={actions.back} />
        <NextButton
          title="Get Started"
          onClick={actions.next} />
      </Footer>
    </>
  );
};

function stepText(step: number, value: string) {
  return `Step ${step} of ${totalSteps}: ${value}`;
}

const MedicalConditionScreen = () => {

  const { back, next, step } = useProjectSurveyStepperContext();

  const title = stepText(step - 1, `Medical Condition`);
  const subtitle = `What is the target medical condition you are researching?`;

  const {
    onChange,
    onSubmit,
    value,
  } = useTemplateMedicalCondition();

  const canSave = useCanSaveMedicalCondition(value);

  const handleNext = useCallback(() => {
    onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitle]}>
        <TemplateMedicalConditionInput
          onChange={onChange}
          value={value} />
        <HelpText value="For example: Diabetes" />
      </BasePresetScreen>
      <Footer>
        <BackButton onClick={handleBack} />
        <NextButton
          disabled={!canSave}
          onClick={handleNext} />
      </Footer>
    </>
  );
};

const DrugScreen = () => {

  const { back, next, step } = useProjectSurveyStepperContext();

  const title = stepText(step - 1, `Drug Name`);
  const subtitle = `What is the name of the drug you’re researching?`;

  const {
    onChange,
    onSubmit,
    value,
  } = useTemplateDrug();

  const canSave = useCanSaveDrug(value);

  const handleNext = useCallback(() => {
    onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitle]}>
        <TemplateDrugInput
          onChange={onChange}
          value={value} />
        <HelpText value="For example: Lisinopril" />
      </BasePresetScreen>
      <Footer>
        <BackButton onClick={handleBack} />
        <NextButton
          disabled={!canSave}
          onClick={handleNext} />
      </Footer>
    </>
  );
};

const LaunchYearScreen = () => {

  const { back, next, step } = useProjectSurveyStepperContext();

  const title = stepText(step - 1, `Drug Launch Timing`);
  const subtitle = `In which year is the target drug scheduled to launch?`;

  const {
    onChange,
    onSubmit,
    value,
  } = useTemplateLaunchYear();

  const canSave = useCanSaveLaunchYear(value);

  const handleNext = useCallback(() => {
    onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitle]}>
        <TemplateLaunchYearInput
          onChange={onChange}
          value={value} />
      </BasePresetScreen>
      <Footer>
        <BackButton onClick={handleBack} />
        <NextButton
          disabled={!canSave}
          onClick={handleNext} />
      </Footer>
    </>
  );
};

const TrialDesignScreen = () => {

  const { back, next, step } = useProjectSurveyStepperContext();

  const title = stepText(step - 1, `Clinical Trial Design`);
  const subtitle = `What is the trial design for the target drug?`;

  const {
    onChange,
    onSubmit,
    value,
  } = useTemplateTrialDesign();

  const canSave = useCanSaveTrialDesign(value);

  const handleNext = useCallback(() => {
    onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitle]}>
        <TemplateTrialDesignInput
          onChange={onChange}
          value={value} />
      </BasePresetScreen>
      <Footer>
        <BackButton onClick={handleBack} />
        <NextButton
          disabled={!canSave}
          onClick={handleNext} />
      </Footer>
    </>
  );
};

const QualityOfLifeScreen = () => {

  const { back, next, step } = useProjectSurveyStepperContext();

  const title = stepText(step - 1, `Quality-of-Life`);
  const subtitle = `What is the Quality of Life (QoL) endpoint of this drug?`;

  const {
    onChange,
    onSubmit,
    value,
  } = useTemplateQualityOfLife();
  const [_, setTemplateData] = useTargetProductProfileData();
  const canSubmit = useCanSaveQualityOfLife(value);

  const handleNext = useCallback(() => {
    onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  const handleSkip = useCallback(() => {
    setTemplateData({
      type: 'template-qol-updated',
      value: null,
    });
    next();
  }, [next, setTemplateData]);

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitle]}>
        <TemplateQualityOfLifeInput
          onChange={onChange}
          value={value} />
      </BasePresetScreen>
      <Footer>
        <BackButton onClick={handleBack} />
        <div>
          <NextButton
            disabled={!canSubmit}
            onClick={handleNext} />
          <SkipStep onClick={handleSkip} />
        </div>
      </Footer>
    </>
  );
};

export const DoseAdministrationScreen = () => {

  const { back, next, step } = useProjectSurveyStepperContext();

  const title = stepText(step - 1, `Dosing & Administration`);
  const subtitle = `What are the dosing & administration endpoints for the target drug?`;

  const {
    onSubmit,
    ...inputProps
  } = useTemplateDoseAdministration();

  const canSubmit = useCanSaveDoseAdministration(inputProps.items);

  const [_, setTemplateData] = useTargetProductProfileData();

  const handleNext = useCallback(() => {
    onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  const handleSkip = useCallback(() => {
    setTemplateData({
      type: 'template-dose-administration-updated',
      payload: { value: [] },
    });
    next();
  }, [next, setTemplateData]);

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitle]}>
        <TemplateDoseAdministrationsInput {...inputProps} />
      </BasePresetScreen>
      <Footer>
        <BackButton onClick={handleBack} />
        <div>
          <NextButton
            disabled={!canSubmit}
            onClick={handleNext} />
          <SkipStep onClick={handleSkip} />
        </div>
      </Footer>
    </>
  );
};

export const SafetyEndpointsScreen = () => {

  const { back, next, step } = useProjectSurveyStepperContext();

  const title = stepText(step - 1, `Safety & Tolerability`);
  const subtitle = `What are the safety & tolerability endpoints for the target drug?`;

  const {
    onSubmit,
    ...inputProps
  } = useTemplateSafetyEndpoints();

  const canSubmit = useCanSaveSafetyEndpoints(inputProps.safetyEndpoints);

  const [_, setTemplateData] = useTargetProductProfileData();

  const handleNext = useCallback(() => {
    onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  const handleSkip = useCallback(() => {
    setTemplateData({
      type: 'template-safety-endpoints-updated',
      value: [],
    });
    next();
  }, [next, setTemplateData]);

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitle]}>
        <TemplateSafetyEndpointsInput {...inputProps} />
      </BasePresetScreen>
      <Footer>
        <BackButton onClick={handleBack} />
        <div>
          <NextButton
            disabled={!canSubmit}
            onClick={handleNext} />
          <SkipStep onClick={handleSkip} />
        </div>
      </Footer>
    </>
  );
};

export const OtherAttributesScreen = () => {

  const { back, next, step } = useProjectSurveyStepperContext();

  const title = stepText(step - 1, `Other Attributes`);
  const subtitle = `Are there any other endpoint(s) to be shown in this TPP?`;

  const {
    onSubmit,
    ...inputProps
  } = useTemplateOtherAttributes();

  const [_, setTemplateData] = useTargetProductProfileData();

  const canSubmit = useCanSaveOtherAttributes(inputProps.otherAttributes);

  const handleNext = useCallback(() => {
    onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  const handleSkip = useCallback(() => {
    setTemplateData({
      type: 'template-other-attributes-updated',
      value: [],
    });
    next();
  }, [next, setTemplateData]);

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitle]}>
        <TemplateOtherAttributesInput {...inputProps} />
      </BasePresetScreen>
      <Footer>
        <BackButton onClick={handleBack} />
        <div>
          <NextButton
            disabled={!canSubmit}
            onClick={handleNext} />
          <SkipStep onClick={handleSkip} />
        </div>
      </Footer>
    </>
  );
};

export const DrugIndicationScreen = () => {

  const { back, next, step } = useProjectSurveyStepperContext();

  const title = stepText(step - 1, `Drug Indication`);
  const subtitle = `What is the indication of the drug you’re researching?`;

  const {
    onChange,
    onSubmit,
    value,
  } = useTemplateDrugIndication();

  const canSave = useCanSaveDrugIndication(value);

  const handleNext = useCallback(() => {
    onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitle]}>
        <TemplateDrugIndicationInput
          onChange={onChange}
          value={value} />
      </BasePresetScreen>
      <Footer>
        <BackButton onClick={handleBack} />
        <NextButton
          disabled={!canSave}
          onClick={handleNext} />
      </Footer>
    </>
  );
};

const EfficacyEndpointsScreen = () => {

  const { back, next, step } = useProjectSurveyStepperContext();

  const title = stepText(step - 1, `Drug Efficacy`);
  const subtitle = `What are the efficacy endpoints for the target drug?`;

  const [_, setTemplateData] = useTargetProductProfileData();

  const {
    onSubmit,
    ...inputProps
  } = useTemplateEfficacyEndpoints();

  const canSubmit = useCanSaveEfficacyEndpoints({
    primary: inputProps.primary.items,
    secondary: inputProps.secondary.items,
  });

  const handleNext = useCallback(() => {
    onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  const handleSkip = useCallback(() => {
    setTemplateData({
      type: 'template-efficacy-endpoints-updated',
      value: {
        primary: [],
        secondary: [],
      },
    });
    next();
  }, [next, setTemplateData]);

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitle]}>
        <TemplateEfficacyEndpointsInput {...inputProps} />
      </BasePresetScreen>
      <Footer>
        <BackButton onClick={handleBack} />
        <div>
          <NextButton
            disabled={!canSubmit}
            onClick={handleNext} />
          <SkipStep onClick={handleSkip} />
        </div>
      </Footer>
    </>
  );
};

const TreatmentComparatorsScreen = () => {

  const { back, next, step } = useProjectSurveyStepperContext();

  const [_, setTemplateData] = useTargetProductProfileData();

  const title = stepText(step - 1, `Treatment Comparators`);
  const subtitle = `What are the treatment comparators in this treatment landscape?`;

  const {
    onSubmit,
    ...inputProps
  } = useTemplateTreatmentComparators();

  const canSubmit = useCanSaveComparators(inputProps.comparators);

  const handleNext = useCallback(() => {
    onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  const handleSkip = useCallback(() => {
    setTemplateData({
      type: 'template-treatment-comparators-updated',
      value: [],
    });
    next();
  }, [next, setTemplateData]);

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitle]}>
        <TemplateComparatorsInput {...inputProps} />
      </BasePresetScreen>
      <Footer>
        <BackButton onClick={handleBack} />
        <div>
          <NextButton
            disabled={!canSubmit}
            onClick={handleNext} />
          <SkipStep onClick={handleSkip} />
        </div>
      </Footer>
    </>
  );
};

const PricingComparatorsScreen = () => {

  const { back, next, step } = useProjectSurveyStepperContext();

  const [templateData, setTemplateData] = useTargetProductProfileData();

  const title = stepText(step - 1, `Pricing Comparators`);
  const subtitle = `What are the pricing comparators in this treatment landscape?`;

  const {
    onCopyTreatmentComparators,
    onSubmit,
    ...inputProps
  } = useTemplatePricingComparators();

  const canSubmit = useCanSaveComparators(inputProps.comparators);

  const handleNext = useCallback(() => {
    onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  const handleSkip = useCallback(() => {
    setTemplateData({
      type: 'template-pricing-comparators-updated',
      value: [],
    });
    next();
  }, [next, setTemplateData]);

  const canCopyTreatmentComparators = useMemo(() => {
    return !!templateData.treatmentComparators.length;
  }, [templateData.treatmentComparators]);

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitle]}>
        {canCopyTreatmentComparators &&
          <div
            className={styles.copyValues}
            onClick={onCopyTreatmentComparators}>
            Click to re-use treatment comparator values
          </div>}
        <TemplateComparatorsInput {...inputProps} />
      </BasePresetScreen>
      <Footer>
        <BackButton onClick={handleBack} />
        <div>
          <NextButton
            disabled={!canSubmit}
            onClick={handleNext} />
          <SkipStep onClick={handleSkip} />
        </div>
      </Footer>
    </>
  );
};

export const DrugPriceScreen = () => {

  const { back, next, step } = useProjectSurveyStepperContext();

  const title = stepText(step - 1, `Drug Price`);
  const subtitle = `What are the low, medium, and high prices for this TPP?`;

  const {
    onSubmit,
    ...inputProps
  } = useTemplateDrugPrice();

  const canSave = useCanSaveDrugPrice(inputProps.value);

  const handleNext = useCallback(() => {
    onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitle]}>
        <TemplateDrugPriceInput {...inputProps} />
      </BasePresetScreen>
      <Footer>
        <BackButton onClick={handleBack} />
        <NextButton
          disabled={!canSave}
          onClick={handleNext} />
      </Footer>
    </>
  );
};

const TreatmentCategoryScreen = () => {

  const { back, next, step } = useProjectSurveyStepperContext();

  const title = stepText(step - 1, `Drug Treatment Category`);
  const subtitle = `What is the name of the primary drug treatment category you would like to collect data on?`;

  const {
    onSubmit,
    ...inputProps
  } = useTemplateTreatmentCategory();

  const canSubmit = useCanSaveTreatmentCategory(inputProps.value);

  const handleNext = useCallback(() => {
    onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitle]}>
        <TemplateTreatmentCategoryInput {...inputProps} />
        <HelpText value="For example: Alpha-glucosidase inhibitor" />
      </BasePresetScreen>
      <Footer>
        <BackButton onClick={handleBack} />
        <NextButton
          disabled={!canSubmit}
          onClick={handleNext} />
      </Footer>
    </>
  );
};

const StimulusScreenWithState = () => {

  const [templateData] = useTargetProductProfileData();

  return (
    <TemplateStimulusInputContainer
      initialValue={templateData.stimulus}>
      <StimulusScreen />
    </TemplateStimulusInputContainer>
  );
};

const StimulusScreen = () => {

  const { back, next, step } = useProjectSurveyStepperContext();

  const title = stepText(step - 1, `Drug Landscape & Stimulus`);
  const subtitle = `Please upload any additional stiumulus for this TPP including an overview of the drug landscape.`;

  const canSubmit = useCanSaveStimulus();

  const onSubmit = useSubmitStimulus();

  const handleNext = useCallback(async () => {
    await onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitle]}>
        <TemplateStimulusInput />
      </BasePresetScreen>
      <Footer>
        <BackButton onClick={handleBack} />
        <NextButton
          disabled={!canSubmit()}
          onClick={handleNext} />
      </Footer>
    </>
  );
};

const DataControllerScreen = () => {

  const { back, next, step } = useProjectSurveyStepperContext();

  const title = stepText(step - 1, `Data Controller`);
  const subtitle1 = `Besides Trinity, who will the data controller for this research project be?`;
  const helpText = `(This will be displayed to respondents in accordance with GDPR)`;

  const {
    onSubmit,
    ...inputProps
  } = useTemplateClient();

  const canSubmit = useCanSaveClient(inputProps.value);

  const handleNext = useCallback(() => {
    onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitle1, helpText]}>
        <TemplateClientInput {...inputProps} />
      </BasePresetScreen>
      <Footer>
        <BackButton onClick={handleBack} />
        <NextButton
          disabled={!canSubmit}
          onClick={handleNext} />
      </Footer>
    </>
  );
};

const TPPScreen = () => {

  const title = `Review TPP Data`;
  const subtitle = `Please review the generated TPP table. You can upload an image as an alternative.`;

  const { back, next, step } = useProjectSurveyStepperContext();

  const {
    handleSubmit,
    ...inputProps
  } = useTemplateTPP();

  const handleNext = useCallback(() => {
    handleSubmit();
    next();
  }, [
    next,
    handleSubmit,
  ]);

  const handleBack = useCallback(() => {
    handleSubmit();
    back();
  }, [
    back,
    handleSubmit,
  ]);

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitle]}>
        <TPPTableSelection
          {...inputProps} />
      </BasePresetScreen>
      <Footer>
        <BackButton onClick={handleBack} />
        <NextButton
          onClick={handleNext} />
      </Footer>
    </>
  );
};

export const AdditionalPricesScreen = () => {
  const title = `Drug Pricing By Market`;
  const subtitle = `Please upload any additional drug pricing stimuli for non-US markets.`;

  const { back, next, step } = useProjectSurveyStepperContext();
  const { value, onChange, onSubmit } = useAdditionalPriceInputs();
  const [_, setTemplateData] = useTargetProductProfileData();

  const handleNext = useCallback(() => {
    onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  const handleSkip = useCallback(() => {
    setTemplateData({
      type: 'template-additional-prices-updated',
      payload: {
        value: {
          canada: null,
          france: null,
          italy: null,
          germany: null,
          spain: null,
          uk: null,
        },
      },
    });
    next();
  }, [next, setTemplateData]);

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitle]}>
        <AdditionPriceInputs
          onChange={onChange}
          value={value} />
      </BasePresetScreen>
      <Footer>
        <BackButton onClick={handleBack} />
        <div>
          <NextButton
            // disabled={!canSubmit}
            onClick={handleNext} />
          <SkipStep onClick={handleSkip} />
        </div>
      </Footer>
    </>
  );
};

export const TargetProductProfileScreens = [
  LetsGetStarted,
  DataControllerScreen,
  MedicalConditionScreen,
  TreatmentCategoryScreen,
  DrugScreen,
  LaunchYearScreen,
  DrugIndicationScreen,
  TrialDesignScreen,
  EfficacyEndpointsScreen,
  SafetyEndpointsScreen,
  QualityOfLifeScreen,
  OtherAttributesScreen,
  DrugPriceScreen,
  AdditionalPricesScreen,
  TreatmentComparatorsScreen,
  PricingComparatorsScreen,
  DoseAdministrationScreen,
  StimulusScreenWithState,
  TPPScreen,
  ThanksPresetScreen,
];

const totalSteps = TargetProductProfileScreens.length - 2;