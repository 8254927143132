import { ComponentType, useCallback, useState } from 'react';

type StepProp<T extends string = string> = Record<T, ComponentType>;

type StepMap<T extends StepProp = StepProp> = {
  [K in keyof T]: T[K];
};

type Params<T extends StepMap = StepMap, K extends keyof T = keyof T> = {
  initial: K;
  map: StepMap<T>;
};

export const useMappedStepper = <K extends string, T extends StepProp<K> = StepProp<K>>(params: Params<StepMap<T>>) => {
  const [step, dispatch] = useState(params.initial);

  const reset = useCallback(() => dispatch(params.initial), [params.initial]);

  const actions = {
    dispatch,
    reset,
  };

  return [params.map[step], actions, step] as const;
};