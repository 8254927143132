import { useMemo } from 'react';
import { SurveyItemType } from '@enums/Survey';
import { useSurveyBuilderCapabilities } from '../Context';
import { useSurveyBuilderOptions, useSurveyBuilderState } from './useSurveyBuilderContext';
import useIsEditingSurvey from './useIsEditingSurvey';

export const useCanAddQuestion = () => {
  const [state] = useSurveyBuilderState();
  const { maxQuestions } = useSurveyBuilderOptions();
  const isEditing = useIsEditingSurvey();
  const { canAddItems } = useSurveyBuilderCapabilities();

  const canAddQuestion = useMemo(() => {
    return !isEditing && canAddItems &&
      state.survey.questions.length < maxQuestions;
  }, [canAddItems, isEditing, maxQuestions, state.survey.questions.length]);

  return canAddQuestion;
};

export const useCanAddMessage = () => {
  const isEditing = useIsEditingSurvey();
  const options = useSurveyBuilderOptions();
  const { canAddItems } = useSurveyBuilderCapabilities();

  const canAddMessage = useMemo(() => {
    return !isEditing && canAddItems &&
      options.itemTypes.includes(SurveyItemType.Message);

  }, [canAddItems, isEditing, options.itemTypes]);

  return canAddMessage;
};

export const useCanAddAIE = () => {
  const isEditing = useIsEditingSurvey();
  const options = useSurveyBuilderOptions();
  const { canAddItems } = useSurveyBuilderCapabilities();

  const canAddAIE = useMemo(() => {
    return !isEditing && canAddItems &&
      options.itemTypes.includes(SurveyItemType.AlternateImageExercise);

  }, [canAddItems, isEditing, options.itemTypes]);

  return canAddAIE;
};