import styles from './style/Panel.css';

type OverviewProps = {
  text: string;
}

export function Overview({ text }: OverviewProps) {
  return (
    <div className={styles.text}>
      {text}
    </div>
  );
}