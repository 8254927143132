export function getOperatorText(value: Operator) {
  return map[value];
}

export function getAlternateOperatorText(value: Operator) {
  return alternateMap[value];
}

const map = {
  '>': 'greater than',
  '<': 'less than',
  '=': 'equal to',
  '>=': 'greater than or equal to',
  '<=': 'less than or equal to',
} as Record<Operator, string>;

const alternateMap = {
  '>': 'more than',
  '<': 'less than',
  '=': '',
  '>=': 'at least',
  '<=': 'at most',
} as Record<Operator, string>;

export function compareValuesUsingOperator({ comparate, operator, value }: CompareValues) {
  switch (operator) {
    case '>':
      return value > comparate;

    case '=':
      return value === comparate;

    case '<':
      return value < comparate;

    case '>=':
      return value >= comparate;

    case '<=':
      return value <= comparate;

    default:
      return false;
  }
}

type CompareValues = {
  comparate: number;
  operator: Operator;
  value: number;
};