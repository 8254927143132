export function calculateChartHeight(count: number) {
  return count < 12
    ? 230
    : 230 + ((count - 12) * 30)
  ;
}

export function calculateChartPadding(count: number) {
  const val =
    count <= 5
      ? 6 - count
      : 1;

  return val * 0.1;
}