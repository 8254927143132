/** @jsxImportSource @emotion/react */
import type { Chat } from '@/brand-insights/types';
import { SegmentationConfirmation } from './Item.Segmentation.Confirmation';
import { SegmentationDimensionSelection } from './Item.Segmentation.DimensionSelection';
import { SegmentationResult } from './Item.Segmentation.Result';
import { SegmentationTagSelection } from './Item.Segmentation.TagSelection';
import { SegmentationCancelled } from './Item.Segmentation.Cancelled';

type Props = {
  item: Chat.Segmentation.Item;
};

export const SegmentationItem = ({ item }: Props) => {
  return (
    <>
      {item.segmentation.screens.map((screenItem, i) => {
        const isLastScreen =  item.segmentation.screens.length - 1 === i;
        const isCancelled = item.segmentation.status === 'cancelled';
        const isReadOnly = isCancelled || !isLastScreen;

        switch (screenItem.type) {
          case 'tag-selection': {
            return (
              <SegmentationTagSelection
                key={screenItem.item.identifier}
                identifier={item.segmentation.identifier}
                data={screenItem.item}
                readonly={isReadOnly} />
            );
          }
          case 'dimension-selection': {
            const tagSelection = getPreviousScreenData(item.segmentation.screens, screenItem.item.identifier, 'tag-selection') as Chat.Segmentation.TagSelection.ScreenData;
            return (
              <SegmentationDimensionSelection
                key={screenItem.item.identifier}
                identifier={item.segmentation.identifier}
                data={screenItem.item}
                tagSelection={tagSelection}
                readonly={isReadOnly} />
            );
          }
          case 'confirmation': {
            const tagSelection = getPreviousScreenData(item.segmentation.screens, screenItem.item.identifier, 'tag-selection') as Chat.Segmentation.TagSelection.ScreenData;
            const dimSelection = getPreviousScreenData(item.segmentation.screens, screenItem.item.identifier, 'dimension-selection') as Chat.Segmentation.DimensionSelection.ScreenData;
            return (
              <SegmentationConfirmation
                key={screenItem.item.identifier}
                identifier={item.segmentation.identifier}
                data={screenItem.item}
                tagSelection={tagSelection}
                dimensionSelection={dimSelection}
                readonly={isReadOnly} />
            );
          }
          case 'result': {
            const tagSelection = getPreviousScreenData(item.segmentation.screens, screenItem.item.identifier, 'tag-selection') as Chat.Segmentation.TagSelection.ScreenData;
            const dimSelection = getPreviousScreenData(item.segmentation.screens, screenItem.item.identifier, 'dimension-selection') as Chat.Segmentation.DimensionSelection.ScreenData;
            return (
              <SegmentationResult
                key={screenItem.item.identifier}
                identifier={item.segmentation.identifier}
                data={screenItem.item}
                tagSelection={tagSelection}
                dimensionSelection={dimSelection}
                readonly={isReadOnly} />
            );
          }
          case 'cancelled': {
            return (
              <SegmentationCancelled
                key={screenItem.item.identifier}
                identifier={item.segmentation.identifier}
                data={screenItem.item} />
            );
          }
          default: throw new UnreachableCaseError(screenItem);
        }
      })}
    </>
  );
};

function getPreviousScreenData(
  items: Chat.Segmentation.InstanceScreenItem[],
  currentScreenIdentifier: string,
  findScreenType: Chat.Segmentation.InstanceScreenItem['type'],
): Chat.Segmentation.InstanceScreenItem['item'] {
  let start = false;
  for (let i = items.length - 1; i >= 0; i--) {
    if (!start && items[i].item.identifier === currentScreenIdentifier) {
      start = true;
      continue;
    }
    if (start && items[i].type === findScreenType) {
      return items[i].item;
    }
  }
}