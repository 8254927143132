import { format } from 'date-fns';
import { nullableDate } from '@utils';
import { TableCellItem } from './interfaces';
import styles from './style/Table.css';

type Props = TableCellItem;

export const Cell = (props: Props) => {
  const value = format(nullableDate(props.row.original.transcript.createdOn), 'MM/dd/yy hh:mm aa');

  return (
    <div
      className={styles.value}
      title={value}>
      {value}
    </div>
  );
};

Cell.displayName = 'Column.AddedOn.Cell';