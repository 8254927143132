import { memo } from 'react';
import { PlusCircle as PlusCircleIcon } from 'react-feather';

type Props = {
  className?: string;
  size?: number;
};

export const PlusCircle = memo(({ className, size = 24 }: Props) => {
  return (
    <PlusCircleIcon
      className={className}
      size={size} />
  );
});