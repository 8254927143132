import { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as paths from '@consts/pathname';
import { useSelectProject } from '@containers/Store';
import { ProjectStaticDetails } from './ProjectStaticDetails';

export const ProjectDetails = () => {

  const params = useParams<Params>();
  const history = useHistory();

  const projectId = useMemo(() => {

    if (!params.slug) return null;

    const [ id ] = params.slug.split('-').reverse();

    return +id;

  }, [params.slug]);

  const project = useSelectProject(projectId);

  useEffect(() => {
    if (!project) {
      history.push(paths.Home);
    }
  }, [
    history,
    project,
  ]);

  return (
    <ProjectStaticDetails
      projectId={projectId} />
  );
};

type Params = {
  slug: string;
};