import { useContext, useEffect, useState } from 'react';
import { MedicalExpertiseContext } from '../Context';
import { validators } from '../utils';
import { useMedicalOnboardingQueriesState } from './useMedicalOnboardingQueriesState';

export default function useIsNonMedicalProfessional() {
  const ctx = useContext(MedicalExpertiseContext);
  const state = useMedicalOnboardingQueriesState(ctx.userId);

  const [isNonMedical, setIsNonMedical] = useState<boolean>(validators.isNonMedical(ctx.professionalRole));

  useEffect(() => {

    if (state.expertise?.data?.professionalRole && !ctx.professionalRole) {
      setIsNonMedical(validators.isNonMedical(state.expertise.data.professionalRole));
    } else if (ctx.professionalRole) {
      setIsNonMedical(validators.isNonMedical(ctx.professionalRole));
    }

  }, [
    ctx.professionalRole,
    state.expertise?.data?.professionalRole,
  ]);

  return isNonMedical;
}

export { useIsNonMedicalProfessional };