import { useCallback } from 'react';
import * as Post from '@containers/Post';
import { useDeletePost } from './hooks/useDeletePost';

type Props = {
  children: React.ReactNode;
  postId:   number;
};

export const DeleteContainer = ({ children, postId }: Props) => {

  const deletePost = useDeletePost();

  const value = useCallback(() => {
    return deletePost({
      postId,
    });
  }, [
    deletePost,
    postId,
  ]);

  return (
    <Post.DeleteContainer
      postId={postId}
      onConfirm={value}>
      {children}
    </Post.DeleteContainer>
  );
};

DeleteContainer.displayName = 'ContentFeed.Post.DeleteContainer';