import { memo } from 'react';
import { cx } from '@utils';
import styles from './style/Card.css';

type Props = {
  onClick?: () => void;
};

export const EmployeeSatisfactionCard = memo(({ onClick }: Props) => {

  const title = `Employee Satisfaction`;

  return (
    <div
      className={styles.root}
      onClick={onClick}>
      <div className={cx(styles.poster, styles.employeeSatisfaction)}>
        <Icon />
      </div>
      <div className={styles.footer}>
        <div className={styles.title}>{title}</div>
      </div>
    </div>
  );
});

type IconProps = {
  color?: string;
};

const Icon = memo(({
  color = '#de674f',
}: IconProps) => (
  <svg
    width="54px"
    height="24px"
    viewBox="0 0 45 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g
      id="Survey-Templates---Employee-Satisfaction"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round">
      <g
        id="1"
        transform="translate(-508.000000, -540.000000)"
        stroke={color}
        strokeWidth="2">
        <g
          id="Group-5"
          transform="translate(509.000000, 541.000000)">
          <g
            id="star"
            transform="translate(12.000000, 0.000000)">
            <polygon
              id="Path"
              points="9.5 0 12.4355 5.92429022 19 6.88012618 14.25 11.488959 15.371 18 9.5 14.9242902 3.629 18 4.75 11.488959 0 6.88012618 6.5645 5.92429022" />
          </g>
          <g
            id="star"
            transform="translate(0.000000, 2.000000)">
            <polyline
              id="Path"
              points="13.4915367 8.25924329 12 9.712 12.944 15.216 8 12.616 3.056 15.216 4 9.712 0 5.816 5.528 5.008 8 0 10.472 5.008 12.8777344 5.35963411" />
          </g>
          <g
            id="star"
            transform="translate(36.000000, 10.000000) scale(-1, 1) translate(-36.000000, -10.000000) translate(29.000000, 2.000000)">
            <polyline
              id="Path"
              points="13.4915367 8.25924329 12 9.712 12.944 15.216 8 12.616 3.056 15.216 4 9.712 0 5.816 5.528 5.008 8 0 10.472 5.008 12.8777344 5.35963411" />
          </g>
        </g>
      </g>
    </g>
  </svg>
));