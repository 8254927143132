import { useReducer } from 'react';
import { ProjectConferenceTagWithMetadata } from '@/types';
import { ProjectTagsActions, TagStateValue } from '@/components/Project.Tags/interfaces';

export const useTagStateReducer = (initial = { tags: [] }) => {
  return useReducer(reducer, initial);
};

function reducer(state: TagStateValue, action: ProjectTagsActions): TagStateValue {
  switch (action.type) {
    case 'initialize': return action.state;

    case 'add-tag': {
      if (action.tag.parent?.id) {
        let foundParent = false;
        const newState: TagStateValue = {
          tags: state.tags.map(t => {
            if (t.id === action.tag.parent?.id) {
              foundParent = true;
              return {
                ...t,
                children: [...t.children, action.tag],
              };
            } else {
              return t;
            }
          }),
        };

        if (!foundParent) throw new Error(`Tried to add child tag ${JSON.stringify(action.tag)} but could not find parent in state`);

        return newState;
      } else {
        return {
          tags: [...state.tags, { ...action.tag, children: [] }],
        };
      }
    }
    case 'remove-tag': {
      return {
        tags: state.tags.filter(t => t.id != action.projectTagId).map(t => ({
          ...t,
          children: t.children.filter(c => c.id != action.projectTagId),
        })),
      };
    }
    case 'replace-state': {
      return action.state;
    }
    case 'update-ordinals': {
      return {
        tags: sortTags(state.tags.map(t => ({
          ...t,
          ordinal: action.ordinals[t.id] ?? t.ordinal,
          children: sortTags(t.children.map(c => ({
            ...c,
            ordinal: action.ordinals[c.id] ?? c.ordinal,
          }))),
        }))),
      };
    }
    case 'update-tag': {
      return {
        tags: state.tags.map(t => {
          if (t.id === action.projectTagId) {
            return { ...t, ...action.partialTag, base: { ...t.base, ...action.baseTag } };
          } else {
            return {
              ...t,
              children: t.children.map(c => {
                if (c.id === action.projectTagId) {
                  return { ...c, ...action.partialTag, base: { ...c.base, ...action.baseTag } };
                } else {
                  return c;
                }
              }),
            };
          }
        }),
      };
    }
    default: {
      throw new UnreachableCaseError(action);
    }
  }
}

function sortTags<T extends ProjectConferenceTagWithMetadata>(tags: T[]) {
  return tags.sort((a, b) => (a.ordinal ?? Number.MAX_VALUE) - (b.ordinal ?? Number.MAX_VALUE));
}