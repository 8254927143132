import { useCallback, useMemo } from 'react';
import type { SurveyQuestionType } from '@enums';
import { SurveyOptionType } from '@enums';
import { useSurveyFormQuestionContext, useValidateRationale } from './hooks';
import { SurveyFormBackContainer, SurveyFormSubmitContainer } from './BaseNavigation';
import { SurveyFormCanSubmitContext, useSurveyFormQuestionAnswer } from './Context';

type Props =
  ChildrenProps;

export const MatrixMultiselectFormContainer = (props: Props) => {
  const [answer] = useSurveyFormQuestionAnswer<SurveyQuestionType.MatrixMultiselect>();
  const question = useSurveyFormQuestionContext<SurveyQuestionType.MatrixMultiselect>();

  const assertSelectionCountIsValid = useCallback((selectedCount: number) => {
    if (question.settings.maximumSelections &&
      selectedCount > question.settings.maximumSelections) {
      return false;
    }

    if (question.settings.minimumSelections &&
      selectedCount < question.settings.minimumSelections) {
      return false;
    }

    return true;
  }, [
    question.settings.maximumSelections,
    question.settings.minimumSelections,
  ]);

  const validateRationale = useValidateRationale();

  const canSubmit = useMemo(() => {

    const isRationaleValid = validateRationale();

    if (!isRationaleValid) return false;

    if (question.settings.groupBy === 'row') {
      const naOptionId = question.options.find(f => f.type === SurveyOptionType.NotApplicable)?.id;

      return question.matrixRows.every(row => {
        const rowAnswerValues = answer.values[row.id];

        if (!rowAnswerValues) return false;

        const naChecked = rowAnswerValues[naOptionId];
        if (naChecked) return true;

        const selectedCount = question.options.filter(option => answer.values[row.id][option.id]).length;

        return assertSelectionCountIsValid(selectedCount);
      });
    } else if (question.settings.groupBy === 'column') {
      return question.options.every(option => {
        const selectedCount = question.matrixRows.filter(row => answer.values[row.id][option.id]).length;
        return assertSelectionCountIsValid(selectedCount);
      });
    }

  }, [
    answer,
    assertSelectionCountIsValid,
    question.options,
    question.matrixRows,
    question.settings.groupBy,
    validateRationale,
  ]);

  return (
    <SurveyFormBackContainer>
      <SurveyFormSubmitContainer>
        <SurveyFormCanSubmitContext.Provider value={canSubmit}>
          {props.children}
        </SurveyFormCanSubmitContext.Provider>
      </SurveyFormSubmitContainer>
    </SurveyFormBackContainer>
  );
};

export default MatrixMultiselectFormContainer;