import { useContext, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { EditingToolPaneContext } from '@containers/Transcript/context';
import { FindReplaceContext } from '@/components/Transcript/context';
import { FindReplace } from '@/components/Transcript';
import { Header } from './Tool.Header';
import styles from './style/Tool.css';

type Props = unknown;

export const Pane = (props: Props) => {
  const pane = useContext(EditingToolPaneContext);
  const { query, stopFind } = useContext(FindReplaceContext);

  const { active, toggle } = pane.find;

  useEffect(() => {

    if (query && !active) {
      stopFind();
    }
  }, [
    active,
    query,
    stopFind,
  ]);

  if (!active) return null;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Header title='Find' onClose={toggle} />
        <div className={styles.main}>
          <FindReplace />
        </div>
      </div>
    </div>
  );
};

Pane.displayName = 'Tool.FindReplace.Pane';

export const Button = (props: unknown) => {
  const pane = useContext(EditingToolPaneContext);

  return (
    <button
      className={styles.action}
      onClick={pane.find.toggle}>
      <SearchIcon />
      <span>Find</span>
    </button>
  );
};

Button.displayName = 'Tool.FindReplace.Button';