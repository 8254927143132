import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import * as QK from '@consts/querykey';
import { GroupConnectedAppsContext } from '@containers/Group.Contacts/Context';
import { useSelectGroup } from '@containers/Store';
import { BackButton } from '@screens/ProjectCreation/Footer';
import { cx } from '@utils';
import { HubSpot, Salesforce } from '@/components/icons';
import { ImportOptionsToggleViewContext } from './ImportOptions.Context';
import styles from './style/ImportOptions.CRM.css';

type Props = unknown;

export const ImportOptionsCRM = (props: Props) => {
  const history = useHistory();
  const { query } = useContext(GroupConnectedAppsContext);
  const toggleView = useContext(ImportOptionsToggleViewContext);

  const salesforce = useInstallSalesforceQuery();
  const hubspot = useInstallHubSpotQuery();

  const apps = [{
    connected: query.data?.connected?.hubspot,
    icon: <HubSpot
      className={styles.logo}
      size={48} />,
    name: `HubSpot`,
    onClick: () => {
      if (!query.data?.connected?.hubspot) {
        hubspot.refetch();
      } else {
        history.push(`/contacts/import/summary?app=hubspot`);
      }
    },
  }, {
    connected: query.data?.connected?.salesforce,
    icon: <Salesforce
      className={styles.logo}
      size={48} />,
    name: `Salesforce`,
    onClick: () => {
      if (!query.data?.connected?.salesforce) {
        salesforce.refetch();
      } else {
        history.push(`/contacts/import/summary?app=salesforce`);
      }
    },
  }];

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          {apps.map(x =>
            <AppCard
              connected={x.connected}
              icon={x.icon}
              key={x.name}
              name={x.name}
              onClick={x.onClick} />)}
        </div>
        <div className={styles.footer}>
          <BackButton onClick={toggleView} />
        </div>
      </div>
    </div>
  );
};

ImportOptionsCRM.displayName = 'Group.Contacts.ImportStepper.ImportOptions.CRM';

type AppCardProps = {
  connected: boolean;
  icon:      React.ReactNode;
  name:      string;
  onClick?:  () => void;
};

const AppCard = (props: AppCardProps) => {
  const classes = {
    item: cx(styles.item, { [styles.connected]: props.connected }),
  };

  return (
    <div className={classes.item}>
      <div
        className={styles.box}
        onClick={props.onClick}>
        <div className={styles.content}>
          {props.icon}
          <div className={styles.name}>
            {props.name}
          </div>
        </div>
      </div>
      <button className={styles.btn}>
        {props.connected
          ? `Connected`
          : `Connect`}
      </button>
    </div>
  );
};

AppCard.displayName = 'Group.Contacts.ImportStepper.ImportOptions.CRM.AppCard';

export const useInstallHubSpotQuery = () => {
  const group = useSelectGroup();

  const query = useQuery([QK.Groups.Apps.HubSpot.Install, group.id], () => {
    return api.groups.apps.hubspot.fetchInstallURL({
      groupId: group.id,
      state: `/contacts/import/summary?app=hubspot`,
    })
    .then(res => {
      if (res?.url) {
        window.location.href = res.url;
      }
    });
  }, {
    enabled: false,
  });

  return query;
};

export const useInstallSalesforceQuery = () => {
  const group = useSelectGroup();

  const query = useQuery([QK.Groups.Apps.Salesforce.Install, group.id], () => {
    return api.groups.apps.salesforce.fetchInstallURL({
      groupId: group.id,
      state: `/contacts/import/summary?app=salesforce`,
    })
    .then(res => {
      if (res?.url) {
        window.location.href = res.url;
      }
    });
  }, {
    enabled: false,
  });

  return query;
};