import { useCallback } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';

type Props = {
  exerciseIdentifier: string;
  groupIdentifier: string;
};

export const useAddImages = ({ exerciseIdentifier, groupIdentifier }: Props) => {
  const [, dispatch] = useSurveyBuilderState();

  return useCallback((fileList: FileList) => {
    for (const file of fileList) {
      const imageUrl = URL.createObjectURL(file);

      dispatch({
        type: 'aie-entry-added',
        exerciseIdentifier,
        groupIdentifier,
        imageUrl,
      });
    }
  }, [exerciseIdentifier, groupIdentifier, dispatch]);
};