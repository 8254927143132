import { useRepeatQuestionResponsesData } from '@containers/RepeatSurveyResponses/hooks/useRepeatQuestionResponsesData';
import { SurveyResponsesHeader } from '@presentation/ProjectSurveyResponses';
import { SegmentedDataTooltip } from '@/components/SurveyResponses/SegmentedDataTooltip';
import RepeatRankingLineChart from './RankingChart';
import RankingData from './RankingData';

export const RepeatRanking = () => {
  const { question } = useRepeatQuestionResponsesData();

  return (
    <>
      <SurveyResponsesHeader
        question={question}>
        <SegmentedDataTooltip questionIdentifier={question.base.identifier} />
      </SurveyResponsesHeader>
      <RepeatRankingLineChart />
      <RankingData />
    </>
  );
};

export default RepeatRanking;