import { compareValuesUsingOperator } from '@utils/operator';
import { MatrixSliderQuestion } from '@/types/survey';

export function isAnswerComplete(data: MatrixSliderQuestion.RespondentAnswer.Value, item: MatrixSliderQuestion.FormQuestion) {
  return data.items.length === item.matrixRows.length &&
    data.items.every(e => e.value !== null);
}

export function getRunningTotal(data: MatrixSliderQuestion.RespondentAnswer.Value) {
  return data.items.reduce((acc, x) => acc + (x.value || 0), 0);
}

export function isSumValid(data: MatrixSliderQuestion.RespondentAnswer.Value, item: MatrixSliderQuestion.FormQuestion) {

  if (item.settings.slider.ensureAnswerTotalEqualsMax && item.settings.slider.maxValue !== null) {
    const sum = getRunningTotal(data);
    return sum === item.settings.slider.maxValue;
  }

  if (item.settings.slider.total.comparate !== null) {
    const sum = getRunningTotal(data);

    return compareValuesUsingOperator({
      comparate: item.settings.slider.total.comparate,
      operator: item.settings.slider.total.operator,
      value: sum,
    });
  }

  return true;
}