import { useCallback, useState } from 'react';
import { useExtensionEvent, useEditorView } from '@remirror/react';
import type { ShortcutHandlerProps, LinkClickData } from 'remirror/extensions';
import { LinkExtension } from 'remirror/extensions';
import type {
  LinkSelection } from './Context';
import {
  LinkModalContext,
  LinkPopperContext,
  useLinkModalContext,
  useLinkPopperContext,
} from './Context';
import { LinkModalContainer } from './Link.Modal.Container';
import { LinkPopperContainer } from './Link.Popper.Container';

type Props = {
  children: React.ReactNode;
};

const LinkPopperState = (props: ChildrenProps) => {

  const [selection, setSelection] = useState<LinkSelection>(null);

  const close = useCallback(() => setSelection(null), []);

  const value = {
    open: setSelection,
    close,
    selection,
  };

  return (
    <LinkPopperContext.Provider value={value}>
      {props.children}
    </LinkPopperContext.Provider>
  );
};

const LinkModalState = (props: ChildrenProps) => {
  const [selection, setSelection] = useState<LinkSelection>(null);

  const close = useCallback(() => setSelection(null), []);

  const value = {
    open: setSelection,
    close,
    selection,
  };

  return (
    <LinkModalContext.Provider value={value}>
      {props.children}
    </LinkModalContext.Provider>
  );
};

const useLinkShortcutEvent = () => {
  const {
    open: openModal,
  } = useLinkModalContext();

  const handleShortcut = useCallback((data: ShortcutHandlerProps) => {
    return openModal({
      href: data.activeLink.attrs.href,
      text: data.selectedText,
      range: {
        from: data.from,
        to: data.to,
      },
    });
  }, [openModal]);

  useExtensionEvent(LinkExtension, 'onShortcut', handleShortcut);
};

const useLinkClickEvent = () => {
  const editorView = useEditorView();
  const {
    open: openPopper,
  } = useLinkPopperContext();

  const handleLinkClick = useCallback((_, props: LinkClickData) => {
    if (!editorView.editable) return false;
    openPopper({
      href: props.href,
      text: props.text,
      range: {
        from: props.from,
        to: props.to,
      },
    });

    return false;
  }, [
    editorView.editable,
    openPopper,
  ]);

  useExtensionEvent(LinkExtension, 'onClick', handleLinkClick);
};

const LinkEventsContainer = (props: Props) => {

  useLinkClickEvent();
  // useLinkShortcutEvent();

  return (
    <>
      {props.children}
    </>
  );
};

export const LinkContainer = (props: Props) => {

  return (
    <LinkPopperState>
      <LinkModalState>
        <LinkPopperContainer>
          <LinkModalContainer>
            <LinkEventsContainer>
              {props.children}
            </LinkEventsContainer>
          </LinkModalContainer>
        </LinkPopperContainer>
      </LinkModalState>
    </LinkPopperState>
  );
};