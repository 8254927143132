import LockIcon from '@mui/icons-material/Lock';
import styles from './style.css';

export function SecureConnection() {
  return (
    <div className={styles.root}>
      <div className={styles.title}>Secure Connection</div>
      <div className={styles.lock}>
        <LockIcon />
      </div>
    </div>
  );
}

export default SecureConnection;