import { parseSurveyRichText } from '@containers/Survey/utils';
import { QuestionHelpText } from './HelpText';
import QuestionItem from './QuestionItem';
import type { QuestionItemProps } from './interfaces';
import styles from './style/SurveyForm.css';

export const QuestionnaireItem = (props: QuestionItemProps) => {
  return (
    <div>
      <div className={styles.questionText}>
        {parseSurveyRichText(props.item.value)}
        <QuestionHelpText item={props.item} />
      </div>
      <QuestionItem {...props} />
    </div>
  );
};

export default QuestionnaireItem;