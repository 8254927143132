import { Info } from 'react-feather';
import styles from './style/AdditionalPhoneNumbers.Label.css';

type Props = {
  onClick?: () => unknown;
};

export default function AdditionalPhoneNumbersLabel({ onClick }: Props) {
  return (
    <div
      className={styles.root}
      onClick={onClick}>
      <Info className={styles.icon} />
      <div className={styles.text}>Additional Dial In Numbers</div>
    </div>
  );
}

export { AdditionalPhoneNumbersLabel };