import * as enums from '@enums';
import { VICS } from '@/types';
import {
  ProjectCreationForm,
  ProjectFiltersProps,
} from './interfaces';

export function getSuggestedItemsLimit(data: Pick<ProjectFiltersProps, 'filters'> & Pick<ProjectCreationForm, 'vics'>) {
  return (type: enums.VICS) => {
    const count = data.vics.filter(x => x.typeId === type).length;

    return (data.filters.limit[type] * 2) - count;
  };
}

export function getVICSIdsByType(items: VICS[], type: enums.VICS) {
  return getItemsByType<number>(items, type, (item: VICS) => item.id);
}

export function getVICSItemsByType(items: VICS[], type: enums.VICS) {
  return getItemsByType(items, type);
}

function getItemsByType<T = VICS>(items: VICS[], type: enums.VICS, fn: <T>(x: VICS) => T = x => x) {
  return items.reduce<T[]>((acc, item) =>  item.typeId === type ? acc.concat(fn(item)) : acc, []);
}

type FiltersQualityParams =
    Pick<ProjectCreationForm,
    | 'roles'
    | 'titles'
    | 'vics'>;

export function calculateFiltersQuality(data: FiltersQualityParams) {
  const roles = data.roles.length;
  const industry = getVICSItemsByType(data.vics, enums.VICS.Industry).length;
  const subindustry = getVICSItemsByType(data.vics, enums.VICS.SubIndustry).length;
  const title = data.titles.length;

  const rolePct = roles * 0.15;
  const industryPct = industry * 0.07;
  const subindustryPct = subindustry * 0.07;
  const titlePct = title * 0.07;

  const r = rolePct > 0.5 ? 0.5 : rolePct;
  const i = industryPct > 0.1 ? 0.1 : industryPct;
  const b = subindustryPct > 0.15 ? 0.15 : subindustryPct;
  const t = titlePct > 0.15 ? 0.15 : titlePct;

  return (r + i + b + t + 0.11) * 100;
}