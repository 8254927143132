import { useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import useOnResize from './useOnResize';

type Props = {
  element: HTMLElement;
  handler: () => void;
};

export const useOnElementResize = ({ element, handler }: Props) => {
  useOnResize(handler);

  useEffect(() => {
    if (element) {
      const observer = new ResizeObserver(handler);

      observer.observe(element);

      return () => {
        observer.unobserve(element);
      };
    }
  }, [element, handler]);
};