import { createNamedContext } from '@utils';

type ThumbnailFileContextValue = [
  File,
  React.Dispatch<React.SetStateAction<File>>,
];

type FileContextValue = [
  File,
  React.Dispatch<React.SetStateAction<File>>,
];

type SnapshotContextValue = [
  string,
  React.Dispatch<React.SetStateAction<string>>,
];

export const ThumbnailFileContext = createNamedContext<ThumbnailFileContextValue | undefined>(undefined, 'Frame.Video.ContentForm.ThumbnailFile.Context');
export const VideoFileContext = createNamedContext<FileContextValue | undefined>(undefined, 'Frame.Video.UploadVideo.VideoFile.Context');
export const VideoSnapshotContext = createNamedContext<SnapshotContextValue | undefined>(undefined, 'Frame.Video.UploadVideo.Snapshot.Context');