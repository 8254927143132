import { useMemo } from 'react';
import type { Track } from 'twilio-video';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { useLiveRoomScreen, useTwilioVideo } from '@containers/Conference';
import { ParticipantTracks } from '@/components/Conference.Video';
import { useScreenShareParticipant } from '@/components/Conference.Video/hooks';
import { useMainSpeaker, useSelectedParticipant } from './hooks';
import styles from './style/Session.MainParticipant.css';

export function SessionMainParticipant() {
  const twilio = useTwilioVideo();

  const mainParticipant = useMainSpeaker(twilio.room);

  const [selectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant(twilio.room);

  const videoPriority: Track.Priority = useMemo(() =>
    !screenShareParticipant ||
      mainParticipant === selectedParticipant ||
      mainParticipant === screenShareParticipant
      ? 'high'
      : null, [mainParticipant, screenShareParticipant, selectedParticipant]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.actions}>
          <div className={styles.topRight}>
            <FullScreen />
          </div>
        </div>
        <div className={styles.video}>
          <ParticipantTracks
            disableAudio
            isMainParticipant={true}
            participant={mainParticipant}
            room={twilio.room}
            videoPriority={videoPriority} />
        </div>
      </div>
    </div>
  );
}

const FullScreen = () => {
  const { toggle, isFullscreen } = useLiveRoomScreen();

  return (
    <div
      className={styles.fs}
      onClick={toggle}>
      {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
    </div>
  );
};