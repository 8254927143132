import { useMemo } from 'react';
import { SurveyQuestionConditionType, utils as enumUtils } from '@enums';
import { cx, trunc } from '@utils';
import { SurveyLogic } from '@/types';
import { useSurveyBuilderState, useParseSurveyRichText } from '@/containers/SurveyBuilder';
import styles from './style/Preview.Condition.css';
import { SurveyConditionText } from './Preview.Condition.Question.Value';

type Props = {
  className?: string;
  item: SurveyLogic.QuestionCondition;
};

export const SurveyQuestionCondition = ({ className, item }: Props) => {

  const [state] = useSurveyBuilderState();

  const getRawQuestionText = useParseSurveyRichText();

  const question = useMemo(() => {
    return state.survey.questions.find(f => f.base.identifier === item.data.question.identifier);
  }, [item.data.question, state.survey.questions]);

  const questionText = useMemo(() => {
    if (!question?.value) return '';
    const parsed = getRawQuestionText(question.value);
    const truncatedText = trunc(parsed, 50);

    return `Q${question.ordinal}: ${truncatedText}`;
  }, [
    question?.ordinal,
    question?.value,
    getRawQuestionText,
  ]);

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.row}>
        <div className={styles.label}>Question:</div>
        {questionText}
      </div>

      <div className={styles.row}>
        <div className={styles.label}>Type:</div>
        {enumUtils.SurveyQuestionConditionType.getName(item.data.type)}
      </div>

      <div className={styles.row}>
        <div className={styles.label}>Value:</div>
        <SurveyConditionText
          condition={item.data}
          question={question} />
      </div>

    </div>
  );
};