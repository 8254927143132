import { PencilButton } from '@/components/icons/Pencil';
import { cx } from '@utils';
import { UserAvatar } from './UserAvatar';
import styles from './style.css';

type Props = {
  className?: string;
  onClick: () => void;
  pictureUrl: string;
  size: number;
};

export const EditableUserAvatar = ({ className, onClick, pictureUrl, size }: Props) => {

  return (
    <div className={cx(styles.editable, className)}>
      <UserAvatar
        size={size}
        onClick={onClick}
        pictureUrl={pictureUrl} />
      <PencilButton
        size={16}
        className={styles.edit}
        onClick={onClick} />
    </div>
  );
};

export default EditableUserAvatar;