import { memo } from 'react';
import { Copy as CopyIcon } from 'react-feather';

type Props = {
  className?: string;
  size: number;
};

export const Copy = memo(({ className, size }: Props) => {
  return (
    <CopyIcon
      className={className}
      size={size} />
  );
});