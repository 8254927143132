import type { DraggableProvided, DraggingStyle, DraggableStateSnapshot } from 'react-beautiful-dnd';
import type { ReactElement } from 'react';
import { useEffect, useRef, useMemo } from 'react';
import { createPortal } from 'react-dom';

export const useDraggableInPortal = (disabled = false) => {
  const element = useRef<HTMLDivElement>(document.createElement('div')).current;
  const rootElement = useMemo(() => document.body, []);

  useEffect(() => {
    if (element) {
      element.style.pointerEvents = 'none';
      element.style.position = 'absolute';
      element.style.height = '100%';
      element.style.width = '100%';
      element.style.top = '0';

      rootElement.appendChild(element);

      return () => {
        rootElement.removeChild(element);
      };
    }
  }, [element, rootElement]);

  return (render: (provided: DraggableProvided, snapshot: DraggableStateSnapshot) => ReactElement) => (provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
    const result = render(provided, snapshot);
    const style = provided.draggableProps.style as DraggingStyle;
    if (style.position === 'fixed' && !disabled) {
      return createPortal(result, element);
    }
    return result;
  };
};