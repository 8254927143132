import { useCallback } from 'react';
import { SurveyQuestionContainer } from '@containers/SurveyBuilder.Question/QuestionContainer';
import { useSurveyBuilderState, useCanAddQuestion, SurveyBuilderItemContainer, useAddSurveyQuestion } from '@containers/SurveyBuilder';
import { AddQuestionButton } from '@presentation';
import { QuestionBuilderItem } from '@/components/SurveyBuilder.Question/QuestionBuilderItem';
import styles from './style/SurveyQuestionsBuilder.css';

export const SurveyQuestionsBuilder = () => {
  const [state, dispatch] = useSurveyBuilderState();
  const canAddQuestion = useCanAddQuestion();
  const section = state.survey.sections[0];
  const addQuestion = useAddSurveyQuestion(section.identifier);

  const renderItems = useCallback(() => {
    return (
      <div>
        {state.survey.items.map(item =>
          <div
            className={styles.question}
            key={item.identifier}>
            <SurveyBuilderItemContainer itemIdentifier={item.identifier}>
              <SurveyQuestionContainer>
                <QuestionBuilderItem />
              </SurveyQuestionContainer>
            </SurveyBuilderItemContainer>
          </div>)}
      </div>
    );
  }, [state.survey.items]);

  const handleAddQuestion = addQuestion(state.survey.items.length + 1);

  return (
    <div>
      {renderItems()}
      {canAddQuestion &&
        <AddQuestionButton
          className={styles.add}
          onClick={handleAddQuestion} />
      }
    </div>
  );
};

export default SurveyQuestionsBuilder;