import { Fragment, useCallback, useMemo } from 'react';
import { useProfileFeaturedPosts, useProfileState } from '@containers/AccountProfile';
import { FeedItemType, PostMediaType } from '@enums';
import { getLocationFor, slugify } from '@utils';
import { ContentFeed } from '@/types';
import { Button } from '@/components/Button';
import { Carousel } from '@/components/Discovery';
import { PostPreview } from '@/components/Posts';
import { usePostsCarousel } from '@/components/Posts/hooks/usePostsCarousel';
import { Header } from './Profile.Section.Header';
import styles from './style/Profile.Posts.css';

export const ProfilePosts = () => {
  const { items } = useProfileFeaturedPosts();
  const [user] = useProfileState();

  const carousel = usePostsCarousel({
    size: items.length,
  });

  const renderItem = useCallback((item: ContentFeed.CarouselItem) => {
    if (item.contentType === FeedItemType.ArticlePost) {
      return (
        <PostPreview.ArticlePost
          className={styles.post}
          item={item} />
      );
    }

    switch (item.media?.typeId) {
      case PostMediaType.Video:
        return (
          <PostPreview.Video
            className={styles.post}
            to={getLocationFor.discovery.video({ postId: item.id })}
            item={item} />
        );

      default:
        return (
          <PostPreview.Text
            className={styles.post}
            to={getLocationFor.post.root({ postId: item.id })}
            item={item} />
        );
    }

  }, []);

  const seeAllLink = useMemo(() => {
    const base = getLocationFor.user.profile({
      slug: slugify({
        id: user.id,
        name: user.profile.fullname,
      }),
    });

    return `${base.pathname}/posts`;
  }, [user.profile.fullname, user.id]);

  return (
    <div>
      <Header title="Posts">
        <div className={styles.right}>
          <Button.Secondary
            variant="brick"
            className={styles.seeAll}
            to={seeAllLink}>
            See All
          </Button.Secondary>
          {(carousel.actions.canGoBack || carousel.actions.canGoNext) &&
            <Carousel.ButtonSet
              {...carousel.actions} />
          }
        </div>
      </Header>
      <div
        className={styles.carousel}
        ref={carousel.ref}>
        <div
          className={styles.items}
          style={carousel.style}>
          {items.map(item => (
            <Fragment key={item.id}>
              {renderItem(item)}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProfilePosts;