export enum CallChargeOverrideType {
  Custom       = 'custom-rate',
  Group        = 'group-rate',
  Project      = 'project-rate',
  ProjectLink  = 'project-link-rate',
}

export enum CallPayoutOverrideType {
  Custom       = 'custom-rate',
  Project      = 'project-rate',
  ProjectLink  = 'project-link-rate',
}