import { useCallback, useMemo } from 'react';
import type { HybridSurveyResponseUserId, SurveyAggregate } from '@/types';
import { SurveyRespondentContext } from './Context';

type Props = {
  data: SurveyAggregate.RespondentMap;
} & ChildrenProps;

export const SurveyRespondentContainer = (props: Props) => {
  const getRespondentUser = useCallback((userId: HybridSurveyResponseUserId) => {
    return props.data.values[userId];
  }, [props.data.values]);

  const value = useMemo(() => ({
    getRespondentUser,
  }), [getRespondentUser]);

  return (
    <SurveyRespondentContext.Provider value={value}>
      {props.children}
    </SurveyRespondentContext.Provider>
  );
};

export default SurveyRespondentContainer;