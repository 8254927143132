import { useCallback, useState } from 'react';
import { TreeViewContext } from './Context';

export type TreeViewProps = {
  children: React.ReactNode;
  className?: string;
  defaultExpanded?: number[];
  defaultSelected?: number;
  onNodeSelection?: (nodeId: number) => unknown;
};

export const TreeView = ({
  children,
  className,
  defaultExpanded,
  defaultSelected,
  onNodeSelection,
}: TreeViewProps) => {
  const [expanded, setExpanded] = useState<number[]>(defaultExpanded || []);
  const [selected, setSelected] = useState<number>(defaultSelected);

  const toggleExpansion = useCallback((nodeId: number) => {
    setExpanded(e => {
      return e.includes(nodeId)
        ? e.filter(f => f !== nodeId)
        : [...e, nodeId];
    });
  }, []);

  const selectNode = useCallback((nodeId: number) => {
    if (onNodeSelection) { onNodeSelection(nodeId); }
    setSelected(nodeId);
  }, [onNodeSelection]);

  const isExpanded = useCallback((nodeId: number) => {
    return expanded.includes(nodeId);
  }, [expanded]);

  const isSelected = useCallback((nodeId: number) => selected === nodeId, [selected]);

  const ctx = {
    isExpanded,
    isSelected,
    selectNode,
    toggleExpansion,
  };

  return (
    <div className={className}>
      <TreeViewContext.Provider value ={ctx}>
        {children}
      </TreeViewContext.Provider>
    </div>
  );
};

export default TreeView;