import * as consts from '@consts';
import { RouteAuth } from '@enums';
import { SessionRouter } from '@routes/Routers';
import * as Routing from '@routes/interfaces';
import { ComplianceCallReview } from '@screens/ComplianceCallReview';
import { ComplianceMessageReview } from '@screens/ComplianceMessageReview';
import { ComplianceSurveyReview } from '@screens/ComplianceSurveyReview';
import * as utils from '@utils';

export const routes: Routing.Route[] = [
  {
    component: ComplianceCallReview,
    exact: true,
    path: consts.path.Compliance.Review.Call,
  },
  {
    component: ComplianceMessageReview,
    exact: true,
    path: consts.path.Compliance.Review.Message,
  },
  {
    component: ComplianceSurveyReview,
    exact: true,
    path: consts.path.Compliance.Review.Survey,
  },
];

export const router = SessionRouter(routes, {
  auth: RouteAuth.Authenticated,
  path: consts.path.Compliance.Root,
  roles: utils.roles.compliance,
});