import { useMemo } from 'react';
import { cx, trunc } from '@utils';
import { SurveyLogic } from '@/types';
import { useSurveyBuilderState, useSurveyClassifications } from '@/containers/SurveyBuilder';

type Props = {
  className?: string;
  item: SurveyLogic.ClassificationCondition;
};

export const SurveyClassificationCondition = ({ className, item }: Props) => {

  const classifications = useSurveyClassifications();

  const classification = useMemo(() => {
    return classifications.find(f => f.identifier === item.data.identifier);
  }, [classifications, item.data.identifier]);

  return (
    <div>
      Classified as {classification.name}
    </div>
  );
};