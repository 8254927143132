import { useMemo } from 'react';
import { cx } from '@utils';
import type { ConjointAnalysisQuestion } from '@/types';
import { TextEntryList, TextEntryListItem } from '@/components/TextEntryList';
import styles from './style/AttributesBuilder.css';

type Props = {
  canAddLevel: boolean;
  canRemoveAttribute: boolean;
  canRemoveLevel: boolean;
  className?: string;
  item: ConjointAnalysisQuestion.Attribute;
  onAddLevel: () => unknown;
  onRemoveAttribute: () => unknown;
  onRemoveLevel: (index: string) => () => unknown;
  onUpdateAttribute: (value: string) => unknown;
  onUpdateLevel: (index: string) =>  (value: string) => unknown;
};

export const AttributesBuilder = ({
  canAddLevel,
  canRemoveAttribute,
  canRemoveLevel,
  className,
  item,
  onAddLevel,
  onRemoveAttribute,
  onRemoveLevel,
  onUpdateAttribute,
  onUpdateLevel,
}: Props) => {

  const levels = useMemo(() => {
    return item.levels.map(m => ({
      key: m.base.identifier,
      value: m.value,
    }));
  }, [item.levels]);

  return (
    <div className={cx(styles.root, className)}>
      <TextEntryListItem
        item={{
          key: item.base.identifier,
          value: item.value,
        }}
        inputClassName={styles.attribute}
        placeholder="Enter an attribute"
        onUpdate={onUpdateAttribute}
        onRemove={onRemoveAttribute}
        removeEnabled={canRemoveAttribute} />
      <TextEntryList
        items={levels}
        placeholder="Enter a level"
        removeItem={onRemoveLevel}
        removeItemEnabled={canRemoveLevel}
        updateItemValue={onUpdateLevel} />
      {canAddLevel &&
        <div className={styles.addLevel} onClick={onAddLevel}>
          + Add a level
        </div>}
    </div>
  );
};

export default AttributesBuilder;