import { memo } from 'react';
import { Helmet } from 'react-helmet';
import { path } from '@consts';
import { AppIntegration } from './AppIntegration';
import { BannerFooter } from './BannerFooter';
import { ContentPanel } from './ContentPanel';
import { GlobalSearch } from './GlobalSearch';
import { Landing } from './Landing';
import { ResearchWorkflow } from './ResearchWorkflow';
import { StorageTools } from './StorageTools';
import { TaggingTools } from './TaggingTools';
import { Transcription } from './Transcription';
import styles from './style/CollaborationTools.css';

type Props = unknown;

const H = memo(() => {
  const description = ``;

  return (
    <Helmet
      title="Collaboration Tools">
      <link
        rel="canonical"
        href={`${process.env.FRONT_BASE_URL}${path.Website.CollaborationTools}`} />
      <meta
        name='description'
        content={description} />
      <meta
        name='og:description'
        content={description} />
    </Helmet>
  );
});

export const CollaborationTools = (props: Props) => {

  return (
    <div className={styles.root}>
      <H />
      <Landing />
      <GlobalSearch />
      <StorageTools />
      <ContentPanel />
      <TaggingTools />
      <AppIntegration />
      <ResearchWorkflow />
      <Transcription />
      <BannerFooter />
    </div>
  );
};

CollaborationTools.displayName = 'CollaborationTools';