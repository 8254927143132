import { useEffect, useMemo, useState, useCallback } from 'react';
import * as enums from '@enums';
import * as utils from './utils';

export const useSuggestedRate = (suggestedHourlyRate: number, seniority: enums.Seniority) => {
  const [hourlyRate, setHourlyRate] = useState<number>(suggestedHourlyRate);

  useEffect(() => {
    setHourlyRate(suggestedHourlyRate);
  }, [suggestedHourlyRate]);

  const minRate = useMemo(() => {
    return utils.calculateMinSliderRate(suggestedHourlyRate);
  }, [suggestedHourlyRate]);

  const maxRate = useMemo(() => {
    return utils.calculateMaxSliderRate(suggestedHourlyRate, seniority);
  }, [suggestedHourlyRate, seniority]);

  const handleRateDragEnd = useCallback(() => {}, []);

  return {
    handleRateDragEnd,
    hourlyRate,
    minimumHourlyRate: minRate,
    maximumHourlyRate: maxRate,
    updateHourlyRate: setHourlyRate,
  };
};

export default useSuggestedRate;