import { useMemo } from 'react';
import { getUserAgent, checkChrome, checkFirefox, checkSafari } from '@utils/browser';

export function useIdentifyBrowser() {
  return useMemo(() => {
    const ua = getUserAgent();
    return {
      chrome: checkChrome(ua),
      firefox: checkFirefox(ua),
      safari: checkSafari(ua),
    };
  }, []);
}