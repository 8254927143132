import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { WorkspaceObjectType } from '@enums';
import { Brand as BrandIcon } from '@/components/icons';
import { Breadcrumb, Breadcrumbs } from '@presentation/Breadcrumbs';
import { getLocationFor, trunc } from '@utils';
import type { WorkspaceObject } from '@/types';
import { SharedBreadcrumb, WorkspaceBreadcrumb } from './WorkspaceBreadcrumb';
import { ObjectPathPopper } from './ObjectPathPopper';
import styles from './style/Breadcrumbs.css';

type Props = {
  items: WorkspaceObject[];
  levelsToShow?: number;
};

const selectMyId = (state: Store.State) => state.user.id;
const selectMeGroupId = (state: Store.State) => state.group.id;

export const WorkspaceObjectBreadcrumbs = ({
  items,
  levelsToShow = 1,
}: Props) => {

  const myId = useSelector(selectMyId);
  const myGroupId = useSelector(selectMeGroupId);

  const renderEllipses = useCallback(() => {
    const ellipsesItems = items.slice(1, items.length - levelsToShow);
    return (
      <div className={styles.item}>
        <ObjectPathPopper
          items={ellipsesItems} />
      </div>
    );
  }, [
    items,
    levelsToShow,
  ]);

  const renderWorkspaceItem = useCallback((item: WorkspaceObject) => {
    const isGroupWorkspace = !item.ownerId;

    if (isGroupWorkspace && myGroupId !== item.groupId) {
      return (
        <div className={styles.item}>
          <SharedBreadcrumb />
        </div>
      );
    }

    if (!isGroupWorkspace && item.ownerId !== myId) {
      return (
        <div className={styles.item}>
          <SharedBreadcrumb />
        </div>
      );
    }

    return (
      <div className={styles.item}>
        <WorkspaceBreadcrumb
          workspace={{
            name: item.name,
          }} />
      </div>
    );
  }, [
    myId,
    myGroupId,
  ]);

  const generateBrandPath = useCallback((item: WorkspaceObject) => {
    return getLocationFor.workspaces.brand({
      brandId: item.entityId,
    });
  }, []);

  const generateFolderPath = useCallback((item: WorkspaceObject) => {
    return getLocationFor.workspaces.folder({
      folderId: item.entityId,
    });
  }, []);

  const renderItem = useCallback((object: WorkspaceObject) => {
    if (object.typeId === WorkspaceObjectType.Brand) {
      const to = generateBrandPath(object);
      return (
        <div key={object.id} className={styles.item}>
          <Link to={to}>
            <Breadcrumb>
              <BrandIcon /> {trunc(object.name, 30)}
            </Breadcrumb>
          </Link>
        </div>
      );
    } else if (object.typeId === WorkspaceObjectType.Folder) {
      const to = generateFolderPath(object);
      return (
        <div key={object.id} className={styles.item}>
          <Link to={to}>
            <Breadcrumb>
              {trunc(object.name, 30)}
            </Breadcrumb>
          </Link>
        </div>
      );
    } else if (object.typeId === WorkspaceObjectType.ProjectParent) {
      const to = getLocationFor.project.files({ id: object.entityId });
      return (
        <div key={object.id} className={styles.item}>
          <Link to={to}>
            <Breadcrumb>
              {trunc(object.name, 35)}
            </Breadcrumb>
          </Link>
        </div>
      );
    } else if (object.typeId === WorkspaceObjectType.Workspace) {
      return renderWorkspaceItem(object);
    } else {
      return null;
    }
  }, [
    generateBrandPath,
    generateFolderPath,
    renderWorkspaceItem,
  ]);

  const renderChildren = useCallback(() => {
    const itemsToShow = items.slice(items.length - levelsToShow, items.length);

    return itemsToShow.map(renderItem);
  }, [
    items,
    levelsToShow,
    renderItem,
  ]);

  return (
    <Breadcrumbs>
      {renderItem(items[0])}
      {items.length > levelsToShow + 1 && renderEllipses()}
      {items.length > levelsToShow && renderChildren()}
    </Breadcrumbs>
  );
};

export default WorkspaceObjectBreadcrumbs;