import http from '@services/http';
import { Branding } from '@api/interfaces/groups';

export const createPalette = (data: Branding.Palettes.CreatePalette.Request): Promise<Branding.Palettes.CreatePalette.Response> => {
  const { groupId, ...params } = data;

  return http.post(`/groups/${groupId}/branding/palettes`, params);
};

export const deletePalette = (data: Branding.Palettes.DeletePalette.Request) => {
  const { groupId, paletteId } = data;

  return http.delete<Branding.Palettes.DeletePalette.Response>(`/groups/${groupId}/branding/palettes/${paletteId}`);
};

export const updatePalette = (data: Branding.Palettes.UpdatePalette.Request): Promise<Branding.Palettes.UpdatePalette.Response> => {
  const { groupId, ...params } = data;

  return http.put(`/groups/${groupId}/branding/palettes/${params.paletteId}`, params);
};