import { useContext } from 'react';
import { TabViewContext } from '@/components/TabView/Context';
import { ContextValue, TabViewRoute } from '@/components/TabView/interfaces';

export const useTabViewContext = () => {
  const context = useContext<ContextValue>(TabViewContext);

  if (!context) throw new Error('useTabViewContext must be used within a TabView child component');

  return context;
};
