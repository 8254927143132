import { useEffect } from 'react';

export const useKeyboardShortcuts = (ref: React.MutableRefObject<HTMLVideoElement | HTMLAudioElement>) => {
  useEffect(() => {
    if (ref.current) {
      const onKeyPress = (e: KeyboardEvent) => {
        const key = e.key;

        const source = e.target as HTMLElement;

        const videoEl = ref.current;

        if (tagSourceToExclude.includes(source?.tagName?.toLowerCase())) return;

        switch (key) {
          case 'l': {
            videoEl.currentTime = videoEl.currentTime + 10;
            break;
          }
          case 'j': {
            videoEl.currentTime = Math.max(0, videoEl.currentTime - 10);
            break;
          }
          case ' ':
          case 'k': {
            if (videoEl.paused) {
              videoEl.play();
            } else {
              videoEl.pause();
            }
            break;
          }
        }
      };

      document.body.addEventListener('keypress', onKeyPress);

      return () => document.body.removeEventListener('keypress', onKeyPress);
    }
  }, [ref]);
};

const tagSourceToExclude = ['input', 'textarea'];