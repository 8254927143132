import { useCommands } from '@remirror/react';
import { useCallback } from 'react';

export const useImageHandler = () => {
  const commands = useCommands();

  const imageHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;

    if (!files) return;

    const file = files[0];
    commands.uploadImage(async () => {
      const result = await uploadFile(file);

      return result;
    });
  }, [
    commands,
  ]);

  return imageHandler;
};

function uploadFile(file: File) {
  const reader = new FileReader();

  return new Promise<{ src: string }>((resolve, reject) => {
    reader.onload = () => resolve({ src: reader.result as string });
    reader.onerror = () => reject(reader.error);
    setTimeout(() => reader.readAsDataURL(file), 1500);
  });
}