import cuid from 'cuid';
import { arrayMove } from '@dnd-kit/sortable';
import { SurveyItemType } from '@/enums';
import type { SurveyAIEGroup, SurveyAIEEntry } from '@/types/survey';
import { SurveyAlternateImageExercise } from '@/types/survey';
import type { SurveyBuilder, SurveyAIEBuilder } from '../interfaces';
import { SurveySectionsBuilder } from '../interfaces';
import { generateNewAIE } from './defaults';

type State = SurveyBuilder.State['survey']['alternateImageExercises'];

export function exerciseAdded(state: State, action: SurveyAIEBuilder.ExerciseAdded.Action): State {

  const exercise = generateNewAIE(action.exerciseIdentifier);

  return state.concat(exercise);

}

export function exerciseRemoved(state: State, action: SurveyAIEBuilder.ExerciseRemoved.Action): State {
  return state.filter(f => f.identifier !== action.exerciseIdentifier);
}

export function groupAdded(state: State, action: SurveyAIEBuilder.ExceriseGroupAdded.Action): State {
  const exercise = state.find(f => f.identifier === action.exerciseIdentifier);

  if (!exercise) {
    return state;
  }

  const ordinal = action.targetOrdinal ?? Math.max(...exercise.groups.map(g => g.ordinal)) + 1;

  const group: SurveyAIEGroup = {
    identifier: cuid(),
    name: action.name ?? String.fromCharCode(64 + ordinal),
    entries: action.entries ?? [],
    ordinal,
    settings: {
      randomize: true,
      anchor: false,
    },
  };

  return state.map(m => {
    if (m.identifier === action.exerciseIdentifier) {
      if (action.targetOrdinal) {
        return {
          ...m,
          groups: insertAt(m.groups, group, action.targetOrdinal),
        };
      } else {
        return {
          ...m,
          groups: m.groups.concat(group),
        };
      }
    } else {
      return m;
    }
  });
}

export function groupRemoved(state: State, action: SurveyAIEBuilder.ExerciseGroupRemoved.Action): State {
  const exercise = state.find(f => f.identifier === action.exerciseIdentifier);

  if (!exercise) {
    return state;
  }

  return state.map(m => {
    if (m.identifier === action.exerciseIdentifier) {
      return {
        ...m,
        groups: balanceOrdinals(m.groups.filter(f => f.identifier !== action.groupIdentifier)),
      };
    } else {
      return m;
    }
  });
}

export function entryAdded(state: State, action: SurveyAIEBuilder.ExcerciseEntryAdded.Action): State {
  const exercise = state.find(f => f.identifier === action.exerciseIdentifier);

  if (!exercise) {
    return state;
  }

  const group = exercise.groups.find(f => f.identifier === action.groupIdentifier);

  const ordinal = group.entries.length + 1;

  const entry: SurveyAIEEntry = {
    identifier: cuid(),
    imageUrl: action.imageUrl,
    ordinal,
    name: '',
  };

  return state.map(m => {
    if (m.identifier === action.exerciseIdentifier) {
      return {
        ...m,
        groups: m.groups.map(g => {
          if (g.identifier === action.groupIdentifier) {
            return {
              ...g,
              entries: g.entries.concat(entry),
            };
          } else {
            return g;
          }
        }),
      };
    } else {
      return m;
    }
  });
}

export function entryRemoved(state: State, action: SurveyAIEBuilder.ExerciseEntryRemoved.Action): State {
  const exercise = state.find(f => f.identifier === action.exerciseIdentifier);

  if (!exercise) {
    return state;
  }

  return state.map(m => {
    if (m.identifier === action.exerciseIdentifier) {
      return {
        ...m,
        groups: m.groups.map(g => {
          if (g.identifier === action.groupIdentifier) {
            return {
              ...g,
              entries: balanceOrdinals(g.entries.filter(f => f.identifier !== action.entryIdentifier)),
            };
          } else {
            return g;
          }
        }),
      };
    } else {
      return m;
    }
  });
}

export function entryUpdated(state: State, action: SurveyAIEBuilder.ExcerciseEntryUpdated.Action): State {
  const exercise = state.find(f => f.identifier === action.exerciseIdentifier);

  if (!exercise) {
    return state;
  }

  return state.map(m => {
    if (m.identifier === action.exerciseIdentifier) {
      return {
        ...m,
        groups: m.groups.map(g => {
          if (g.identifier === action.groupIdentifier) {
            return {
              ...g,
              entries: g.entries.map(e => {
                if (e.identifier === action.entryIdentifier) {
                  return {
                    ...e,
                    ...action.entry,
                  };
                } else {
                  return e;
                }
              }),
            };
          } else {
            return g;
          }
        }),
      };
    } else {
      return m;
    }
  });
}

export function exerciseSettingsUpdated(state: State, action: SurveyAIEBuilder.ExerciseSettingsUpdated.Action): State {
  const exercise = state.find(f => f.identifier === action.exerciseIdentifier);

  if (!exercise) {
    return state;
  }

  return state.map(m => {
    if (m.identifier === action.exerciseIdentifier) {
      return {
        ...m,
        settings: { ...m.settings, ...action.settings },
      };
    } else {
      return m;
    }
  });
}

export function groupNameUpdated(state: State, action: SurveyAIEBuilder.ExerciseGroupNameUpdated.Action): State {
  const exercise = state.find(f => f.identifier === action.exerciseIdentifier);

  if (!exercise) {
    return state;
  }

  return state.map(m => {
    if (m.identifier === action.exerciseIdentifier) {
      return {
        ...m,
        groups: m.groups.map(g => {
          if (g.identifier === action.groupIdentifier) {
            return {
              ...g,
              name: action.name,
            };
          } else {
            return g;
          }
        }),
      };
    } else {
      return m;
    }
  });
}

export function groupSettingsUpdated(state: State, action: SurveyAIEBuilder.ExerciseGroupSettingsUpdated.Action): State {
  const exercise = state.find(f => f.identifier === action.exerciseIdentifier);

  if (!exercise) {
    return state;
  }

  return state.map(m => {
    if (m.identifier === action.exerciseIdentifier) {
      return {
        ...m,
        groups: m.groups.map(g => {
          if (g.identifier === action.groupIdentifier) {
            return {
              ...g,
              settings: { ...g.settings, ...action.settings },
            };
          } else {
            return g;
          }
        }),
      };
    } else {
      return m;
    }
  });
}

export function exerciseUpdated(state: State, action: SurveyAIEBuilder.ExerciseUpdated.Action): State {
  return state.map(m => {
    if (m.identifier === action.item.identifier) {
      return action.item;
    } else {
      return m;
    }
  });
}

export function entryMoved(state: State, action: SurveyAIEBuilder.ExerciseEntryMoved.Action): State {
  return state.map(e => {
    if (e.identifier === action.exerciseIdentifier) {

      const { entry, group } = getSourceEntry(e.groups, action.entryIdentifier);
      if (!entry) return e;

      return {
        ...e,
        groups: e.groups.map(g => {
          if (g.identifier === action.targetGroupIdentifier) {
            if (action.targetOrdinal) {
              if (action.targetOrdinal === entry.ordinal && group.identifier === g.identifier) return g; //Everything is already good, don't mess with it

              let sortedEntries: SurveyAIEEntry[];

              if (group.identifier === g.identifier) {
                //The entry is already in the group, just use the source ordinal to swap
                sortedEntries = arrayMove([...g.entries], entry.ordinal - 1, action.targetOrdinal - 1);
              } else {
                sortedEntries = arrayMove([entry, ...g.entries], 0, action.targetOrdinal - 1);
              }

              const entries = resetOrdinals(sortedEntries);

              return {
                ...g,
                entries,
              };
            } else {
              if (!g.entries.some(e => e.identifier === entry.identifier)) {
                return {
                  ...g,
                  entries: [...g.entries, { ...entry, ordinal: (Math.max(...g.entries.map(e => e.ordinal), 0)) + 1 }],
                };
              } else {
                return g;
              }
            }
          } else {
            return {
              ...g,
              entries: balanceOrdinals(g.entries.filter(e => e.identifier !== action.entryIdentifier)),
            };
          }
        }),
      };
    } else {
      return e;
    }
  });

  function getSourceEntry(groups: SurveyAIEGroup[], entryIdentifier: string) {
    for (const group of groups) {
      for (const entry of group.entries) {
        if (entry.identifier === entryIdentifier) {
          return { entry, group };
        }
      }
    }
  }
}

function balanceOrdinals<T extends IOrdinal>(arr: T[]) {
  return resetOrdinals(arr.sort((a, b) => a.ordinal - b.ordinal));
}

function resetOrdinals<T extends IOrdinal>(arr: T[]) {
  let ordinal = 1;
  return arr.map(i => {
    return {
      ...i,
      ordinal: ordinal++,
    };
  });
}

function insertAt<T extends IOrdinal>(arr: T[], toInsert: T, targetOrdinal: number) {
  let inserted = false;
  const insertedArr = [...arr].sort((a, b) => a.ordinal - b.ordinal).flatMap(el => {
    if (el.ordinal === targetOrdinal && !inserted) {
      inserted = true;
      return [toInsert, el];
    } else {
      return [el];
    }
  });

  if (!inserted) {
    insertedArr.push(toInsert);
  }

  return balanceOrdinals(insertedArr);
}

type IOrdinal = {
  ordinal: number;
};