import { useCallback, useMemo } from 'react';
import type { SurveyQuestionType } from '@enums';
import { cx } from '@utils';
import type { MultiselectQuestion as MQ } from '@/types';
import { useSurveyFormQuestionAnswer } from '@/containers/SurveyForm';
import { RichTextCheckboxGroup } from '@/components/Checkbox';
import type { CheckboxGroupOnItemToggle, RichTextCheckboxGroupOnInputChange } from '@/components/Checkbox/interfaces';
import { Rationale } from './Rationale';
import styles from './style/Multiselect.css';

type Props = {
  className?: string;
  question: MQ.FormQuestion | MQ.Question;
};

export default function MultiselectQuestion({ className, question }: Props) {

  const [answer, setAnswer] = useSurveyFormQuestionAnswer<SurveyQuestionType.Multiselect>();

  const itemsMapped = useMemo(() => {
    const exclusiveOptions = question.options.filter(f => f.metadata.isExclusive);
    const exclusiveOptionSelected = answer.items.some(f => exclusiveOptions.map(m => m.id).includes(f.optionId));

    return question.options.map((o: MQ.FormOption | MQ.Option) => ({
      allowText: o.metadata.isOpenEnded,
      id: o.id,
      label: o.value,
      text: answer?.items.find(ao => ao.optionId === o.id)?.text,
      disabled: !o.metadata.isExclusive && exclusiveOptionSelected,
    }));
  }, [answer, question.options]);

  const selected =
    useMemo(() => ({
      items: answer?.items ?? [],
      ids: (answer?.items ?? []).map(i => i.optionId),
    }), [answer?.items]);

  const handleToggle: CheckboxGroupOnItemToggle
    = useCallback(item => {
      const optionId = +item.id;

      const option = question.options.find(f => f.id === optionId);

      if (selected.ids.includes(optionId)) {
        setAnswer({
          items: selected.items.filter(i => i.optionId !== optionId),
        });
      } else {
        if (option.metadata.isExclusive) {
          setAnswer({
            items: [{ optionId }],
          });
        } else {
          setAnswer({
            items: [
              ...selected.items
                .filter(f => {
                  const o = question.options.find(ff => f.optionId === ff.id);
                  return !o.metadata.isExclusive;
                }),
              { optionId },
            ],
          });
        }
      }
    }, [
      question.options,
      selected,
      setAnswer,
    ]);

  const handleInputChange: RichTextCheckboxGroupOnInputChange
    = useCallback(item => setAnswer({
      items: [
        ...selected.items.filter(i => i.optionId !== +item.id),
        { optionId: +item.id, text: item.text },
      ],
    }), [selected, setAnswer]);

  return (
    <>
      <RichTextCheckboxGroup
        className={cx(className, question.settings.gridFormat ? styles.gridForm : styles.defaultForm)}
        checkedItems={selected.ids}
        items={itemsMapped}
        onToggle={handleToggle}
        onInputChange={handleInputChange} />
      <Rationale settings={question.settings.rationale} />
    </>
  );
}

export { MultiselectQuestion };