import { useContext } from 'react';
import { createNamedContext } from '@utils';

type ContextValue =
  [
    boolean,
    React.Dispatch<boolean>,
  ];

export const SurveyMonitoringPasswordRequiredContext = createNamedContext<ContextValue>(null, 'SurveyMonitoringPasswordRequiredContext');

export const useSurveyMonitoringPasswordRequired = () => useContext(SurveyMonitoringPasswordRequiredContext);