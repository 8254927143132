import cuid from 'cuid';
import { useRef, useEffect, useMemo, useCallback } from 'react';
import JSEditor from 'tui-image-editor';
import { useSurveyFormQuestionAnswer, useImageMarkupEditorContext, useSurveyFormQuestionContext, useSurveyFormRootElementContext } from '@/containers/SurveyForm';
import type { SurveyQuestionType } from '@/enums';
import { useVanillaEditorEvents } from './hooks/useImageMarkupEditorEvents';
import styles from './style/ImageMarkup.css';

type Options = tuiImageEditor.IOptions;

export const VanillaEditor = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const rootElement = useSurveyFormRootElementContext();
  const identifier = useMemo(() => cuid(), []);
  const { setEditor } = useImageMarkupEditorContext();

  const item = useSurveyFormQuestionContext<SurveyQuestionType.ImageMarkup>();
  const [answer] = useSurveyFormQuestionAnswer<SurveyQuestionType.ImageMarkup>();

  useVanillaEditorEvents();

  useEffect(() => {
    let containerMaxWidth = divRef.current.parentElement.offsetWidth - 30;

    if (rootElement) {
      rootElement.style.flexGrow = '1';

      containerMaxWidth = rootElement.offsetWidth - 30 - 50; //50px for the form left margin

      rootElement.style.flexGrow = null;
    }

    const editor = new JSEditor('#' + identifier, { ...DEFAULT_OPTIONS, cssMaxWidth: containerMaxWidth });

    editor.loadImageFromURL(answer.markedUpImage ?? item.settings.initialImage, 'MarkupImage').then(async val => {
      if (divRef.current === null) return;

      const width = Math.min(containerMaxWidth, val.newWidth);
      const height = val.newHeight * (width / val.newWidth);

      //Set the container to the target size, otherwise the library might try and shrink it down
      divRef.current.style.width = `${width}px`;
      divRef.current.style.height = `${height}px`;

      await editor.resizeCanvasDimension({
        width,
        height,
      });

      const canvasHeight = (divRef.current.querySelector<HTMLCanvasElement>('.lower-canvas')).offsetHeight;
      divRef.current.style.height = `${canvasHeight}px`;
    });

    setEditor(editor as tuiImageEditor.MyImageEditor);

    return () => {
      //This order is important, if we destroy the editor before setting the state we might accidentally perform useEffect actions on a destroyed editor
      setEditor(null);
      setTimeout(() => editor.destroy(), 50); //Wrap this in a timeout so that any effects or pending events don't try to work on a destroyed instance
    };
  }, [setEditor, identifier, answer.markedUpImage, item.settings.initialImage, rootElement]);

  return (
    <div className={styles.editorContainer}>
      <div
        ref={divRef}
        className={styles.vanillaEditor}
        id={identifier} />
    </div>
  );
};

const SELECTION_CORNER_COLOR = '#626262';
const SELECTION_BORDER_COLOR = '#d3d3d3';

const DEFAULT_OPTIONS: Options = {
  cssMaxHeight: 400,
  cssMaxWidth: 950,
  selectionStyle: {
    cornerStyle: 'circle',
    cornerSize: 12,
    cornerColor: SELECTION_CORNER_COLOR,
    cornerStrokeColor: SELECTION_CORNER_COLOR,
    transparentCorners: false,
    lineWidth: 2,
    borderColor: SELECTION_BORDER_COLOR,
  },
  usageStatistics: false,
};