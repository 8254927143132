import { useCallback } from 'react';
import { useIsEditingSurvey, useParseSurveyRichText } from '@containers/SurveyBuilder/hooks';
import { cx } from '@utils';
import { getTemplateEntityLabelComponent } from '@/components/Survey.Templates/Label';
import { SurveyBuilderQuestionOption } from '@/components/Survey.RichText/ReadOnly';
import type { QuestionOptionProps } from './interfaces';
import styles from './style/QuestionOption.css';

export const QuestionOption = (props: QuestionOptionProps) => {

  const editing = useIsEditingSurvey();
  const parseSurveyRichText = useParseSurveyRichText();

  const renderValue = useCallback(() => {
    const entityType = props.item.metadata.template.linkedEntity?.type;

    if (!entityType) {
      return (
        <>
          <SurveyBuilderQuestionOption
            value={props.item.value}
            identifier={props.item.base.identifier} />
          {props.children}
        </>
      );
    }

    const TemplateComponent = getTemplateEntityLabelComponent(entityType);

    return (
      <>
        <TemplateComponent
          disabled={editing}
          value={parseSurveyRichText(props.item.value)} />
        {props.children}
      </>
    );

  }, [
    editing,
    parseSurveyRichText,
    props.item.value,
    props.item.base.identifier,
    props.item.metadata,
    props.children,
  ]);

  return (
    <div className={cx(styles.root, props.className)}>
      <div className={styles.bullet}>{`A${props.item.ordinal}`}</div>
      <div className={styles.value}>
        {renderValue()}
      </div>
    </div>
  );
};