import { path } from '@consts';
import { RouteAuth } from '@enums';
import { BasicRouter } from '@routes/Routers';
import * as Routing from '@routes/interfaces';
import { AccountProfileSetup } from '@screens/AccountProfileSetup';
import { AccountProfileCheckup } from '@screens/AccountProfileCheckup';

export const routes: Routing.Route[] = [
  {
    component: AccountProfileCheckup,
    exact: true,
    path: path.Account.Profile.Checkup,
  },
  {
    component: AccountProfileSetup,
    path: path.Account.Profile.Setup,
  },
];

export const router = BasicRouter(routes, {
  auth: RouteAuth.Authenticated,
  path: path.Account.Root,
});