import { useCallback, useMemo } from 'react';
import type { MenuProps as MUIMenuProps } from '@mui/material/Menu';
import MUIMenuItem from '@mui/material/MenuItem';
import MUISelect from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import { cx } from '@utils';
import type { DescriptorLike, Lookup, SelectChangeEvent, SelectProps } from './interfaces';
import styles from './style/Select.css';

type Props<T extends Lookup, K extends keyof T = keyof T> = SelectProps<T, K>;

export const Select = <T extends Lookup, K extends keyof T = keyof T>({ classes = {}, ...props }: Props<T>) => {
  const getDisplayValue = useCallback((value: keyof T) => {
    return Array.isArray(props?.options)
      ? props?.options?.find?.(x => String(x.id) === String(value))?.name
      : props?.options?.[value];
  }, [
    props?.options,
  ]);

  const renderValue = useCallback((value: keyof T) => {
    const render = typeof props.renderValue === 'function'
      ? props.renderValue
      : _ => _;

    if (props.placeholder) {
      return !value
        ? props.placeholder
        : render(getDisplayValue(value));
    } else {
      return render(getDisplayValue(value));
    }
  }, [
    getDisplayValue,
    props.placeholder,
    props.renderValue,
  ]);

  const { li, ul, root, ...classnames } = classes;

  const CustomMenuProps: Partial<MUIMenuProps> = useMemo(() => ({
    classes: {
      root: classes.selectMenu,
      paper: cx(styles.root, styles.paper, li),
      list: cx(styles.root, styles.options, ul),
      disabled: styles.disabled,
      select: styles.select,
    },
    elevation: 0,
    MenuListProps: {
      classes: {
        root: cx(styles.root, styles.menu),
      },
    },
    PopoverClasses: {
      root: cx(styles.root, styles.options),
    },
    transitionDuration: 80,
    disableScrollLock: true,
    disableAutoFocusItem: props.disableAutoFocusItem,
  }), [
    classes.selectMenu,
    li,
    props.disableAutoFocusItem,
    ul,
  ]);

  const options = useMemo<DescriptorLike[]>(() => {
    if (Array.isArray(props?.options)) {
      return props.options;
    } else if (props?.options !== null && typeof props?.options === 'object') {
      return Object.keys(props.options).map(key => ({
        id: key,
        name: props.options[key] as string,
      }));
    } else {
      return [];
    }
  }, [props?.options]);

  return (
    <MUISelect
      className={cx(styles.select, props.className)}
      classes={{ ...classnames, select: root }}
      disabled={props.disabled}
      displayEmpty={!!props.placeholder}
      MenuProps={CustomMenuProps}
      defaultValue={props.defaultValue}
      id={props.id}
      input={<SelectInput />}
      inputProps={props.inputProps}
      name={props.name}
      onChange={e => props.onChange(e as unknown as SelectChangeEvent<K>)}
      onBlur={props.onBlur}
      renderValue={renderValue}
      style={props.style}
      value={props.value}
      variant={props.variant}>
      {options.map(x =>
        <MUIMenuItem
          key={x.id}
          style={{ minHeight: 36 }}
          value={x.id}>
          {props.renderOption ? props.renderOption(x) : x.name}
        </MUIMenuItem>)}
      {props.children}
    </MUISelect>
  );
};

const SelectInput = styled(InputBase)(({ theme }) => ({
  [`& .MuiSelect-select`]: {
    backgroundColor: 'var(--pri-01)',
    border: '1px solid var(--gray-l)',
    borderRadius: 4,
    color: 'var(--black-l)',
    fontFamily: 'var(--font-reg)',
    fontSize: 16,
    padding: '5px 26px 5px 12px',
    position: 'relative',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      backgroundColor: 'var(--pri-01)',
      borderColor: 'var(--gray-l)',
      borderRadius: 4,
      boxShadow: 'none',
    },
  },
}));

Select.Input = SelectInput;