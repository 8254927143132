import { useCallback, useContext } from 'react';
import { MedicalBackgroundContext, MedicalBackgroundIdentificationContext } from '@containers/MedicalOnboarding/Context';
import { cx } from '@utils';
import * as Field from '@/components/MedicalProfile/Field';
import styles from './style/Input.DeaNumber.css';

type Props = unknown;

export const StateOfPractice = (props: Props) => {
  const ctx = useContext(MedicalBackgroundContext);
  const identification = useContext(MedicalBackgroundIdentificationContext);

  const handleChange = useCallback((value: string) => {
    ctx.mutation.mutate({
      identification: {
        ...identification.form,
        stateOfPractice: value,
      },
    }, {
      onSuccess: identification.rehydrate,
    });

    identification.setValue({
      stateOfPractice: value,
    });
  }, [
    ctx.mutation,
    identification,
  ]);

  return (
    <Field.StateOfPractice
      classes={{
        container: styles.select,
        control: cx(styles.select, styles.control),
        input: styles.input,
        menu: styles.menu,
        option: styles.option,
        singleValue: styles.single,
        valueContainer: styles.value,
      }}
      menuPlacement="auto"
      onChange={item => handleChange(item.value)}
      value={identification.form.stateOfPractice} />
  );
};

StateOfPractice.displayName = 'Member.Experience.Input.StateOfPractice';