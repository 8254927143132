import { useCallback, forwardRef } from 'react';
import { usePopupState, bindToggle, bindPopper } from 'material-ui-popup-state/hooks';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import Popper from '@mui/material/Popper';
import { MoreVertical } from 'react-feather';
import { useSurveyQuotasState, useSubmitSurveyDraft } from '@containers/SurveyBuilder';
import { Anchor } from '@presentation/Anchor';
import { PopperMenu, PopperMenuItem } from '@/components/Popper';
import { useDeleteQuotaAlert } from './DeleteQuotaAlert';

type MenuProps = {
  identifier: string;
  onClose: () => unknown;
};

export const QuotaContextMenu = forwardRef<HTMLDivElement, MenuProps>(({ identifier, onClose }, ref) => {
  const [_, dispatch] = useSurveyQuotasState();
  const [toggleAlert, DeleteQuotaAlert] = useDeleteQuotaAlert();
  const submitDraft = useSubmitSurveyDraft();

  const toggleEditing = useCallback(() => {
    dispatch({
      identifier,
      type: 'toggle-quota-editing-on',
    });
  }, [dispatch, identifier]);

  const handleDelete = useCallback(() => {
    dispatch({
      identifier,
      type: 'remove-quota-item',
    });
    submitDraft();
  }, [dispatch, identifier, submitDraft]);

  return (
    <>
      <PopperMenu ref={ref}>
        <PopperMenuItem onClick={toggleEditing}>Edit</PopperMenuItem>
        <PopperMenuItem onClick={toggleAlert}>Delete</PopperMenuItem>
      </PopperMenu>
      <DeleteQuotaAlert
        onSubmit={handleDelete} />
    </>
  );
});

type PopperProps = {
  className?: string;
  identifier: string;
};

export const QuotaContextMenuPopper = ({ className, identifier }: PopperProps) => {

  const popupState = usePopupState({
    popupId: `quota-${identifier}-menu`,
    variant: 'popper',
  });

  return (
    <div className={className}>
      <div {...bindToggle(popupState)}>
        <Anchor
          Icon={MoreVertical}
          open={popupState.isOpen} />
      </div>
      <Popper
        {...bindPopper(popupState)}
        placement="bottom-end"
        style={{ zIndex: 2 }}>
        <ClickAwayListener
          onClickAway={popupState.close}>
          <QuotaContextMenu
            identifier={identifier}
            onClose={popupState.close} />
        </ClickAwayListener>
      </Popper>
    </div>
  );
};