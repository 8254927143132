import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useSearchFiltersQuery from '@/containers/WorkspaceGlobalSearch/hooks/useSearchFilterQuery';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import * as $api from '@api';
import type { TranscriptHighlightItem } from '@/types';
import { useCopyToClipboard } from '@/utils';
import { TranscriptGoodQuoteContainer } from '@/containers/Transcript/GoodQuote.Container';
import { TaggedMomentBuilderContainer } from '@/containers/Transcript/TaggedMoment.Modal';
import { SetQuoteMomentIdContext } from '@/containers/Transcript/context';
import type { SaveTaggedMoment } from '@/services/api/interfaces/transcripts';
import { RowExpandedContext, TranscriptHighlightsContext } from './Context';
import { TranscriptHighlight } from './Search.Table.Transcript.Highlight';
import styles from './style/Transcript.ExpandedRow.css';
import type { TranscriptsTableItem } from './interfaces';

type Props =
  { data: TranscriptsTableItem } &
  ChildrenProps;

export const TranscriptRowContainer = (props: Props) => {

  const [rowOpen, setRowOpen] = useState(false);
  const [highlights, setHighlights] = useState<TranscriptHighlightItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [highightsFetched, setHighlightsFetched] = useState(false);
  const [searchQuery] = useSearchFiltersQuery();

  const getHighlights = useCallback(() => {
    return $api.workspaces.globalSearch.transcriptHighlights({
      transcriptId: props.data.transcript.id,
      keywords: searchQuery.keywords,
    }).then(res => res.highlights);
  }, [props.data.transcript.id, searchQuery.keywords]);

  useEffect(() => {
    if (rowOpen && !highightsFetched) {
      setIsLoading(true);
      getHighlights()
        .then(setHighlights)
        .finally(() => {
          setIsLoading(false);
          setHighlightsFetched(true);
        });
    }
  }, [
    getHighlights,
    highightsFetched,
    rowOpen,
  ]);

  return (
    <RowExpandedContext.Provider value={[rowOpen, setRowOpen]}>
      <TranscriptHighlightsContext.Provider value={highlights}>
        {props.children}
        {rowOpen && (
          <RowContent
            items={highlights}
            data={props.data} />
        )}
        {/* {rowOpen && isLoading && (
          <PlaceholderRow />
        )} */}
      </TranscriptHighlightsContext.Provider>
    </RowExpandedContext.Provider>
  );
};

type ContentProps = {
  data: TranscriptsTableItem;
  items: TranscriptHighlightItem[];
};

const RowContent = (props: ContentProps) => {

  const copyToClipboard = useCopyToClipboard();

  const handleCopy = useCallback((item: TranscriptHighlightItem) => async () => {
    await copyToClipboard(getQuoteText(item));
  }, [copyToClipboard]);

  const [expanded, setExpanded] = useState(false);

  const defaultDisplayCount = 5;

  const canExpand = useMemo(() => {
    return props.items.length > defaultDisplayCount;
  }, [props.items.length]);

  const displayItems = useMemo(() => {
    return expanded ? props.items : props.items.slice(0, defaultDisplayCount);
  }, [props.items, expanded]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        {displayItems.map((item, index) => {
          return (
            <TranscriptGoodQuoteContainer
              key={index}
              initialQuoteMomentId={item.starredMomentId}
              range={item.range}
              transcriptId={props.data.transcript.id}>
              <BuildTagContainer>
                <TranscriptHighlight
                  className={styles.highlight}
                  onCopy={handleCopy(item)}
                  highlight={item}
                  data={props.data} />
              </BuildTagContainer>
            </TranscriptGoodQuoteContainer>
          );
        })}
        {canExpand && !expanded && (
          <div className={styles.footer}>
            <div className={styles.expand} onClick={() => setExpanded(true)}>
              View more
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const BuildTagContainer = (props: ChildrenProps) => {

  const setQuoteMomentId = useContext(SetQuoteMomentIdContext);

  const handleSuccess = useCallback((data: SaveTaggedMoment.Response) => {
    return Promise.resolve(setQuoteMomentId(data.tag.id));
  }, [setQuoteMomentId]);

  return (
    <TaggedMomentBuilderContainer onSuccess={handleSuccess}>
      {props.children}
    </TaggedMomentBuilderContainer>
  );
};

const getQuoteText = (item: TranscriptHighlightItem) => {
  return item.text.replaceAll('*', '');
};

const PlaceholderRow = memo(() => {
  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <ActivityIndicator />
      </div>
    </div>
  );
});
