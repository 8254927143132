import { createElement, useCallback, useContext, useMemo, useState } from 'react';
import * as Discovery from '@containers/Discovery';
import { VideoAdditionalDetailsContext } from '@containers/Discovery/Context';
import { TabView, TabViewRenderParams } from '@/components/TabView';
import * as Tab from './Tab';

type Props = {
  hasTranscript: boolean;
  mediaId:       number;
};

export const AdditionalDetails = (props: Props) => {
  return (
    <Discovery.Video.AdditionalDetailsContainer
      hasTranscript={props.hasTranscript}
      mediaId={props.mediaId}>
      <AdditionalDetailsTabs />
    </Discovery.Video.AdditionalDetailsContainer>
  );
};

AdditionalDetails.displayName = 'Discovery.Post.AdditionalDetails';

const AdditionalDetailsTabs = (props: unknown) => {
  const { comments, transcript } = useContext(VideoAdditionalDetailsContext);
  const [index, setIndex] = useState(0);

  const routes = useMemo(() => {

    const count = comments.query.data?.items?.length ?? 0;

    const commentstab = comments.enabled ? [{
      component: Tab.Comments,
      key: 'comments',
      title: count > 0 ? `Comments (${count})` : `Comments`,
    }] : [];
    const transcripttab = transcript.enabled ? [{
      component: Tab.Transcript,
      key: 'transcript',
      title: 'Transcript',
    }] : [];

    return [
      ...commentstab,
      ...transcripttab,
    ];

  }, [
    comments.enabled,
    comments.query.data?.items?.length,
    transcript.enabled,
  ]);

  const renderTabView = useCallback((params: TabViewRenderParams<typeof routes[number]>) => {
    return createElement(params.route.component);
  }, []);

  if (!routes.length) return null;

  return (
    <TabView
      index={index}
      onIndexChange={setIndex}
      renderTabView={renderTabView}
      routes={routes} />
  );
};

AdditionalDetailsTabs.displayName = 'Discovery.Post.AdditionalDetails.Tabs';