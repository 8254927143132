import extend from '@enums/extend';

export enum PayoutStatus {
  Draft = 1,
  Open = 2,
  Pending = 3,
  Paid = 4,
  Refunded = 5,
  Failed = 6,
  Void = 7,
}

const map = new Map([
  [PayoutStatus.Draft,    { name: 'Draft' }],
  [PayoutStatus.Open,     { name: 'Open' }],
  [PayoutStatus.Pending,  { name: 'Pending' }],
  [PayoutStatus.Paid,     { name: 'Paid' }],
  [PayoutStatus.Refunded, { name: 'Refunded'}],
  [PayoutStatus.Failed,   { name: 'Failed'}],
  [PayoutStatus.Void,     { name: 'Void'}],
]);

export default {
  PayoutStatus: extend(map),
};