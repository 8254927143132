import { useHistory } from 'react-router-dom';
import { path } from '@consts';
import { useDeclineProjectRequest, useProjectOnboardingContext } from '@containers/ProjectOnboarding/hooks';
import { ProjectRequestReview } from '@screens/ProjectDetails';
import Toast from '@/components/Toast';

type Props = unknown;

export const Review = (props: Props) => {
  const ctx = useProjectOnboardingContext();
  const history = useHistory();
  const declineProjectRequest = useDeclineProjectRequest(ctx.projectId);

  const handleCompletion = () => {
    return declineProjectRequest()
      .then(_ => {
        Toast.alert({
          title: 'Project Rejected',
          body: 'The client has been sent a notification that you do not wish to participate on this project.',
        });

        history.replace(path.Projects.Root);
      });
  };

  return (
    <ProjectRequestReview
      projectId={ctx.projectId}
      onRequestAccepted={ctx.next}
      onRequestDeclined={handleCompletion} />
  );
};