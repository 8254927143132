import { useEffect, useMemo, useState } from 'react';
import { differenceInMilliseconds } from 'date-fns';
import { useDebounceValue } from '@utils';

export type SuspendedData<T extends ObjectLike> = {
  data: T[];
  empty: boolean;
  isInitialLoading: boolean;
  loading: boolean;
  pagination?: {
    pageCount: number;
    totalCount: number;
  };
};

export const useSuspendInitialLoading = <T extends ObjectLike>(params: SuspendedData<T>, ms = 1000) => {
  const [start, setStart] = useState<Date>(null);

  const delayed = useDebounceValue(params, ms);

  const started = useMemo(() => {
    return params.isInitialLoading && !delayed.isInitialLoading;
  }, [params.isInitialLoading, delayed.isInitialLoading]);

  const ended = useMemo(() => {
    return !params.isInitialLoading && delayed.isInitialLoading;
  }, [params.isInitialLoading, delayed.isInitialLoading]);

  const suspended = useMemo(() => {
    return started
      ? params
      : ended
        ? differenceInMilliseconds(Date.now(), start) < ms ? delayed : params
        : params;
  }, [
    delayed,
    ended,
    ms,
    params,
    start,
    started,
  ]);

  useEffect(() => {

    if (params.isInitialLoading && !start) {
      setStart(new Date());
    }

  }, [
    params.isInitialLoading,
    start,
  ]);

  return suspended;
};