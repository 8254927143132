import { SearchTabView } from './Search.TabView';
import styles from './style/Search.Main.css';

type Props = unknown;

export const Search = (props: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <h1 className={styles.title}>Payer Database Search</h1>
        </div>
        <div className={styles.main}>
          <SearchTabView />
        </div>
      </div>
    </div>
  );
};

Search.displayName = 'Search.Main';