import extend from '@enums/extend';

export enum AccountPaymentMethod {
  Automated       = 1,
  OffPlatformWire = 2,
  ThirdParty      = 3,
}

const map = new Map([
  [AccountPaymentMethod.Automated,       { name: 'Automated'   }],
  [AccountPaymentMethod.OffPlatformWire, { name: 'Off Platform Wire / ACH'  }],
  [AccountPaymentMethod.ThirdParty,      { name: 'Third Party' }],
]);

export default {
  AccountPaymentMethod: extend(map),
};