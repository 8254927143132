import { useContext } from 'react';
import { CallReviewStateContext } from '@containers/ComplianceReview/Context';

const useCallReviewState = () => {
  const state = useContext(CallReviewStateContext);

  return state;
};

export { useCallReviewState };
export default useCallReviewState;