import { useCallback, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { SurveyQuestionType } from '@/enums';
import { SurveySettings } from '@/types/survey';
import { useQuestionBuilderItemContext } from '@/containers/SurveyBuilder.Question';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { Portal } from '@/components/Portal';
import { PopperMenu, PopperMenuItem, useZIndexModifier } from '@/components/Popper';
import { useTotalNumberValueModal } from './Settings.Total.NumberModal';
import styles from './style/MatrixSlider.Settings.Slider.css';
import { useTotalPipedAnswerModal } from './Settings.Total.PipingModal';
import { useGetDisplayTotal } from './hooks';

type Props = {
  onChange: (value: SurveySettings.TotalSetting) => void;
  value: SurveySettings.TotalSetting;
};

export const TotalsPopper = ({ onChange, value: total }: Props) => {

  const dropdownItems = useTotalPipedQuestions();

  const [toggleNumberModal, NumberModal] = useTotalNumberValueModal();
  const [togglePipeModal, PipedAnswerModal] = useTotalPipedAnswerModal();
  const [state] = useSurveyBuilderState();

  const [open, setOpen] = useState(false);

  const [referenceElement, setReferenceElement] = useState<HTMLElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);

  const zIndexModifier = useZIndexModifier({ zIndex: 8 });

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [zIndexModifier],
    placement: 'bottom-start',
  });

  const getDisplayTotal = useGetDisplayTotal(total);

  const handleAnchorClick = useCallback((e: React.MouseEvent) => {
    if (!dropdownItems.length) {
      toggleNumberModal();
    } else {
      setOpen(true);
    }
  }, [dropdownItems.length, toggleNumberModal]);

  const handleBlur = useCallback(() => {
    setOpen(false);
  }, []);

  const handleNumberClick = useCallback(() => {
    toggleNumberModal();
    setOpen(false);
  }, [toggleNumberModal]);

  const handlePipeClick = useCallback(() => {
    togglePipeModal();
    setOpen(false);
  }, [togglePipeModal]);

  return (
    <>
      <div ref={setReferenceElement} onClick={handleAnchorClick}>
        <div className={styles.totalInput}>{getDisplayTotal()}</div>
      </div>
      {open &&
        <Portal>
          <ClickAwayListener
            mouseEvent='onMouseDown'
            onClickAway={handleBlur}>
            <div
              ref={setPopperElement}
              style={popperStyles.popper}
              {...attributes.popper}>
              <PopperMenu>
                <PopperMenuItem onClick={handleNumberClick}>Enter number</PopperMenuItem>
                <PopperMenuItem onClick={handlePipeClick}>Use previous answer</PopperMenuItem>
              </PopperMenu>
            </div>
          </ClickAwayListener>
        </Portal>
      }
      <NumberModal
        initialValue={
          typeof total.comparate === 'number'
            ? total as SurveySettings.TotalSetting<number>
            : { comparate: null, operator: '=' }
        }
        onSubmit={onChange} />
      <PipedAnswerModal
        initialValue={
          total.comparate !== null && typeof total.comparate === 'object'
            ? total as SurveySettings.TotalSetting<SurveySettings.PipedTotalValue>
            : { comparate: null, operator: '=' }
        }
        onSubmit={onChange} />
    </>
  );
};

export function useTotalPipedQuestions() {
  const item = useQuestionBuilderItemContext();
  const [state] = useSurveyBuilderState();

  return useMemo(() => {
    return state.survey.questions.filter(f => {
      return [SurveyQuestionType.Sliders, SurveyQuestionType.NumberInputTable].includes(f.typeId) &&
        f.ordinal < item.ordinal;
    });
  }, [state.survey.questions, item.ordinal]);
}