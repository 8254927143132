import { useMemo } from 'react';
import { Column as RTColumn } from 'react-table';
import { RecentFilesItem } from '@/types/workspace.table';
import { useIsInternalUser } from '@/containers/Store/hooks';
import { Cell } from '../Cells';

export function useRecentFilesColumns() {

  const isInternalUser = useIsInternalUser();

  return useMemo(() => {
    return [
      {
        id: 'name',
        Header: 'Name',
        Cell: Cell.Name,
        minWidth: 200,
      },
      {
        id: 'createdOn',
        Header: 'Created On',
        Cell: Cell.CreatedOn,
        minWidth: 80,
        maxWidth: 80,
      },
      isInternalUser && {
        id: 'owner',
        Header: 'Owner',
        Cell: Cell.Owner,
        minWidth: 120,
        maxWidth: 120,
      },
    ].filter(Boolean);
  }, [isInternalUser]);
}