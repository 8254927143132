import { useCallback, useState } from 'react';
import { useTargetProductProfileData, useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';

export const useCanSaveTreatmentCategory = (value: string) => {

  return value.length >= 2;
};

export const useTemplateTreatmentCategory = () => {
  const [templateData] = useTargetProductProfileData();
  const [treatmentCategory, setTreatmentCategory] = useState(templateData.treatmentCategory);
  const [_, dispatch] = useSurveyBuilderState();

  const onSubmit = useCallback(() => {
    dispatch({
      type: 'template-treatment-category-updated',
      value: treatmentCategory,
    });
  }, [
    treatmentCategory,
    dispatch,
  ]);

  return {
    onChange: setTreatmentCategory,
    onSubmit,
    value: treatmentCategory,
  };
};