import qs from 'query-string';
import http, { xhr } from '@services/http';
import { normalizePost } from '@transformers';
import { Posts } from './interfaces';

export const publishPost = (data: Posts.PublishPost.Request) => {
  return http.post('/posts', data)
    .then(({ item }: Posts.PublishPost.Response) => normalizePost(item));
};

export const deletePost = (data: Posts.DeletePost.Request) => {
  return http.delete(`/posts/${data.postId}`);
};

export const getPost = (data: Posts.GetPost.Request) => {
  return xhr.get<Posts.GetPost.Response>(`/posts/${data.postId}`)
    .then(res => normalizePost(res.data.item));
};

export const fetchURLPreview = (data: Posts.FetchURLPreview.Request) => {
  const q = qs.stringify(data);

  return http.get<Posts.FetchURLPreview.Response>(`/posts/external?${q}`);
};

export * as analytics from './posts.analytics';
export * as articles from './posts.articles';
export * as uploads from './posts.uploads';