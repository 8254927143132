import type { ManagerOptions, SocketOptions } from 'socket.io-client';
import type { SocketEvent, SocketNamespace } from '@/enums';
import * as $session from '@/services/auth/session';

type Logger = (namespace: SocketNamespace) => (event: SocketEvent, data?: unknown) => void;

const logEvent: Logger = namespace => (event, data) => {
  const types = {
    connect: logInfo,
    disconnect: logWarn,
    authenticated: logInfo,
    unauthorized: logWarn,
  } as { [event: string]: Logger };

  const logger: Logger = types[event] || logInfo;
  logger(namespace)(event, data);
};

const logInfo: Logger = namespace => (event, data) => {
  if (!process.env.__DEV__) return;

  console.groupCollapsed(
    `%c socket:${namespace} %c${event.toUpperCase()} %c⌁`,
    'color: #979797;',
    'color: #aaffff; font-weight: bold;',
    'color: #d7ff00;'
  );
  if (data) console.info(data);
  console.groupEnd();
};

const logWarn: Logger = namespace => (event, data) => {
  if (!process.env.__DEV__) return;

  console.groupCollapsed(
    `%c socket:${namespace} %c${event.toUpperCase()} %c⌁`,
    'color: #979797;',
    'color: #aaffff; font-weight: bold;',
    'color: #c10d34',
  );
  if (data) console.info(data);
  console.groupEnd();
};

const logGeneric = (namespace: SocketNamespace) => (value: string) => {
  console.log(
    `%c socket:${namespace} %c${value} %c⌁`,
    'color: #979797;',
    'color: #aaffff; font-weight: bold;',
    'color: #c10d34',
  );
};

export const createLogger = (namespace: SocketNamespace) => ({
  event: logEvent(namespace),
  log: logGeneric(namespace),
  info: logInfo(namespace),
  warn: logWarn(namespace),
});

export function resolveSocketOptions(opts: Partial<ManagerOptions & SocketOptions> = {}) {
  return {
    path: process.env.BACKEND_WS_PATH,
    auth: {
      'X-CSRF-Token': $session.getCSRF(),
      'X-SID': process.env.__DEV__ ? $session.getXSID() : undefined,
    },
    autoConnect: false,
    withCredentials: true,
    transports: ['websocket', 'polling'],
    ...opts,
  };
}