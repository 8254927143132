import { memo } from 'react';
import { Link } from 'react-router-dom';
import { LocationDescriptor } from 'history';
import { Node } from 'slate';
import { VideoPreviewOverlay } from '@presentation/VideoPreviewOverlay';
import { cx } from '@utils';
import { Activity } from '@/types';
import styles from './style/PostPreview.Video.css';

type Props = {
  className?: string;
  item:       Activity.Item.Post;
  to:         LocationDescriptor;
};

export const Video = memo(({ className, item, to }: Props) => {

  const media = item.media;
  const description = media.content.description.map(node => Node.string(node)).join('\n');

  return (
    <div className={cx(styles.root, className)}>
      <VideoPreviewOverlay
        className={styles.preview}
        previewUrl={media.thumbnail.url}
        to={to} />

      <Link to={to}>
        <div className={styles.details}>
          <div className={styles.title}>{media.title}</div>
          <div className={styles.description}>{description}</div>
        </div>
      </Link>
    </div>
  );
});

Video.displayName = 'Posts.PostPreview.Video';