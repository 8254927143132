import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as api from '@api';
import { path } from '@consts';
import { useAsyncStateLazy } from '@utils';
import { Discovery } from '@/types';
import styles from './style/VideoList.css';

type Props = unknown;

const VideoList = (props: Props) => {
  const [items, setItems] = useState<Discovery.RelatedItem[]>([]);
  const params = useParams<{ postId: string; }>();

  const [_, fetchRelatedItems] = useAsyncStateLazy(() => {

    return api.discovery.fetchRelatedItems({ postId: +params.postId })
    .then(res => setItems(res.items));

  }, [
    params.postId,
    setItems,
  ]);

  useEffect(() => {

    fetchRelatedItems();

  }, [
    fetchRelatedItems,
    params.postId,
  ]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h2 className={styles.header}>Related Videos</h2>
        {items.map(x =>
          <VideoListItem
            key={x.post.id}
            {...x} />)}
      </div>
    </div>
  );
};

const VideoListItem = (props: Discovery.RelatedItem) => {
  return (
    <div className={styles.row}>
      <Link to={`${path.Discovery.Root}/${props.post.id}`}>
        <div className={styles.item}>
          <div className={styles.thumb}>
            <img
              className={styles.img}
              src={props.preview.url} />
          </div>
          <div className={styles.info}>
            <div className={styles.title}>{props.post.name}</div>
            <div className={styles.author}>{props.author.name}</div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export { VideoList };
export default VideoList;