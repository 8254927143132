import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { SurveyActionType, UserProjectStatus } from '@enums';
import * as consts from '@consts';
import * as selectors from '@store/selectors';
import { useStepper } from '@utils';
import { SurveyQuotaOverLimit } from '@screens/ProjectDisqualification';
import { ProjectDisqualification } from '@screens/ProjectDisqualification';
import { ProjectOnboardingContext } from './Context';
import { useMatchProjectIdFromRoute } from './hooks';
import { ProjectOnboardingContextValue } from './interfaces';

type State = {
  actionType: SurveyActionType;
};

const useValidateRoute = (projectId: number) => {
  const history = useHistory();

  const record = useSelector(selectors.pipeline.expert.record(projectId));

  const validate = useCallback(() => {
    const statuses = [
      UserProjectStatus.Disqualified,
    ];

    if (!statuses.includes(record?.statusId)) {
      history.replace(consts.pathname.HOME);
    }
  }, [
    history,
    record?.statusId,
  ]);

  return validate;
};

export const ProjectOnboardingDisqualificationContainer = () => {

  const { projectId } = useMatchProjectIdFromRoute();
  const location = useLocation<State>();

  const validateRoute = useValidateRoute(projectId);

  useEffect(validateRoute, [validateRoute]);

  const screens = useMemo(() => {
    if (location.state?.actionType === SurveyActionType.QuotaOverLimit) {
      return [SurveyQuotaOverLimit];
    }

    return [ProjectDisqualification];
  }, [
    location.state,
  ]);

  const [Screen, stepperActions, step] = useStepper(screens);

  const context: ProjectOnboardingContextValue = {
    back: stepperActions.back,
    goTo: stepperActions.goTo,
    hasNextStep: false,
    next: stepperActions.next,
    projectId,
  };

  return (
    <ProjectOnboardingContext.Provider value={context}>
      <Screen />
    </ProjectOnboardingContext.Provider>
  );
};

export default ProjectOnboardingDisqualificationContainer;