import { useCallback, useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { ButtonActivityIndicator, ButtonOutlined } from '@presentation';
import * as $api from '@api/projects.quant-analysis';
import { QuantAnalysisContext } from '@/containers/Project.QuantAnalysis';
import { useProjectState } from '@/containers/GroupProject/hooks';
import { useInvalidateProjectState } from '@containers/GroupProject/hooks/useInvalidateProjectState';
import { getLocationFor } from '@/utils';
import { Body } from '@/components/Modal/Body';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import { useModal } from '@/components/Modal';
import styles from './style/Modal.DeleteAnalysis.css';

type Props =
  Pick<ModalProps,
    | 'onClose'
    | 'open'>;

export const DeleteQuantAnalysisModal = (props: Props) => {

  const quantAnalysis = useContext(QuantAnalysisContext);
  const state = useProjectState();
  const history = useHistory();

  const invalidateProjectState = useInvalidateProjectState();

  const mutation = useMutation({
    mutationFn: () => {
      return $api.deleteQuantAnalysis({
        projectId: state.project.id,
        quantAnalysisId: quantAnalysis.id,
      });
    }, onSuccess: () => {
      history.push(getLocationFor.project.root({ slug: state.project.slug }));
      invalidateProjectState(state.project.id);
      props.onClose();
    },
  });

  const handleDelete = useCallback(() => {
    mutation.mutateAsync();
  }, [mutation]);

  return (
    <Modal
      className={styles.root}
      disableEscapeClose={mutation.isLoading}
      disableOverlayClick={mutation.isLoading}
      hideCloseIcon
      open={props.open}
      onClose={props.onClose}>
      <Body className={styles.body}>
        <div className={styles.message}>
          Are you sure you want to delete these analyses?
        </div>
      </Body>
      <ButtonSet className={styles.btns}>
        <ButtonOutlined
          borderWidth="thin"
          className={styles.btn}
          disabled={mutation.isLoading}
          color="silver"
          onClick={props.onClose}>
          Cancel
        </ButtonOutlined>
        <ButtonActivityIndicator
          className={styles.btn}
          color="destructive"
          indicatorSize={12}
          loading={mutation.isLoading}
          onClick={handleDelete}>
          Confirm
        </ButtonActivityIndicator>
      </ButtonSet>
    </Modal>
  );
};

export const useDeleteQuantAnalysisModal = () => useModal(DeleteQuantAnalysisModal);