import { useCallback } from 'react';
import { SurveyActionType } from '@enums';
import { useSurveyBasicQuotaState } from '@containers/SurveyBuilder.Quota';
import { SurveyLogicBuilderConditionsContainer } from '@/containers/SurveyBuilder.Logic';
import { LogicBuilderConditionsMatch } from '@/components/SurveyBuilder.Logic/Builder.Match';
import { SurveyQuotaBuilder } from './Builder';
import { BasicQuotaFormButtons as FormButtons } from './Builder.Basic.FormButtons';
import styles from './style/Builder.Basic.css';

export const BasicSurveyQuotaBuilder = () => {
  const [state, dispatch] = useSurveyBasicQuotaState();

  const updateName = useCallback((value: string) => {
    dispatch({
      type: 'update-name',
      value,
    });
  }, [dispatch]);

  const updateLimit = useCallback((value: number) => {
    dispatch({
      type: 'update-limit',
      value,
    });
  }, [dispatch]);

  const updateAction = useCallback((value: SurveyActionType) => {
    dispatch({
      type: 'update-action',
      value,
    });
  }, [dispatch]);

  return (
    <>
      <SurveyQuotaBuilder
        onActionUpdate={updateAction}
        onUpdateLimit={updateLimit}
        onUpdateName={updateName}
        state={state}>
        <SurveyLogicBuilderConditionsContainer>
          <LogicBuilderConditionsMatch className={styles.conditions} />
        </SurveyLogicBuilderConditionsContainer>
      </SurveyQuotaBuilder>
      <FormButtons />
    </>
  );
};

export default BasicSurveyQuotaBuilder;