import { Fragment, useCallback } from 'react';
import type { SurveyQuestionType } from '@enums';
import { useProjectSurveyResponsesState } from '@containers/SurveyResponses/hooks';
import { SurveyResponsesHeader } from '@presentation/ProjectSurveyResponses';
import type { ExclusiveOptionsQuestion, SurveyAggregate } from '@/types';
import { useExclusiveOptionColors } from './hooks/usePaletteColors';
import ResponseOptionsChart from './ResponseOptionsChart';
import OptionsOptionData from './OptionsOptionData';
import { SegmentedDataTooltip } from './SegmentedDataTooltip';
import OptionsAggregateData from './OptionsAggregateData';
import styles from './style/Options.css';

type Props = {
  question: ExclusiveOptionsQuestion.Question;
  responses: SurveyAggregate.Options.Map;
};

export default function ResponseExclusiveOptions({ question, responses }: Props) {
  const colors = useExclusiveOptionColors(question.settings);

  const { query, setQueryState } = useProjectSurveyResponsesState<SurveyQuestionType.ExclusiveOptions>();

  const handleBarClick = useCallback((optionId: number) => {
    setQueryState({ optionId });
  }, [setQueryState]);

  return (
    <Fragment>
      <SurveyResponsesHeader
        question={question}>
        <SegmentedDataTooltip questionIdentifier={question.base.identifier} />
      </SurveyResponsesHeader>
      <ResponseOptionsChart
        colors={colors}
        question={question}
        responses={responses}
        onClick={handleBarClick} />
      <div className={styles.options}>
        {query.optionId
          ? <OptionsOptionData colorFn={colors} />
          : <OptionsAggregateData colorFn={colors} />}
      </div>
    </Fragment>
  );
}

export { ResponseExclusiveOptions };