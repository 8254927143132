import { useCallback, useMemo } from 'react';
import { SurveyQuestionOption, SurveyQuestionOptionMetadata } from '@/types/survey';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { ContextMenuItemProps } from '@/components/SurveyBuilder/ContextMenu.Item';

type Props = {
  questionIdentifier: string;
  option: SurveyQuestionOption<SurveyQuestionOptionMetadata<{ isReadOnly: boolean }>>;
};

export const useReadOnlyOptionMenuItem = (props: Props) => {

  const [_, dispatch] = useSurveyBuilderState();

  const value = !!props.option.metadata.isReadOnly;

  const toggle = useCallback(() => {
    dispatch({
      type: 'option-readonly-updated',
      payload: {
        option: { identifier: props.option.base.identifier },
        questionIdentifier: props.questionIdentifier,
        value: !value,
      },
    });
  }, [
    dispatch,
    props.questionIdentifier,
    props.option.base.identifier,
    value,
  ]);

  const menuItem: ContextMenuItemProps = useMemo(() => {

    return {
      checked: value,
      children: 'Read Only',
      onClick: toggle,
    };
  }, [value, toggle]);

  return menuItem;
};