export enum TopicTab {
  Overview = 'Overview',
  Experts  = 'Experts',
  Posts    = 'Posts',
  News     = 'News',
}

export type Scene = {
  component: React.ComponentType;
  key:       TopicTab;
  path:      string;
  title:     string;
};

export type SceneMap = {
  [key in TopicTab]: Scene;
};

export type RouteParams = {
  slug: string;
};

export declare namespace BuildSceneMap {
  export type Params = {
    hasMembers: boolean;
    hasNews:    boolean;
    hasPosts:   boolean;
    slug:       string;
  };

  export type Return = SceneMap;
}