import { useContext, useMemo } from 'react';
import { Redirect, generatePath } from 'react-router-dom';
import { useIsInternalUser } from '@containers/Store';
import { path } from '@consts';
import { TranscriptLockContext } from '@containers/Transcript.Redaction/Context';
import { useCallTranscript } from '@utils/api';
import { TranscriptType } from '@/enums';
import { CallTranscriptMentionsContainer } from './Call.Mentions';
import {
  CallTranscriptDetailsContext,
  RefetchTranscriptContext,
  TranscriptAccessContext,
  TranscriptDocumentContext,
  TranscriptEnhancementStateContext,
  TranscriptIdContext,
  TranscriptSpeakersContext,
  TranscriptTypeContext,
} from './context';
import { DownloadCallMediaContainer } from './Call.Media';
import { TranscriptDataContainer } from './Transcript.Data';
import { TranscriptStateContainer } from './Transcript.State';
import { TranscriptMetadataContainer } from './Metadata.Container';

/* Note: This container is for any contexts/containers that are specific to the call transcript page */

type Props =
  ICallId &
  ChildrenProps;

const CallTranscriptContainer = ({ callId, children }: Props) => {

  const [_, setTranscriptLocked] = useContext(TranscriptLockContext);
  const isInternalUser = useIsInternalUser();

  const query = useCallTranscript({
    callId,
  }, {
    onSuccess: res => {
      setTranscriptLocked(res.isLocked);
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const transcriptId = useMemo(() => {
    return query.data?.transcript?.id;
  }, [query.data]);

  const access = useMemo(() => {
    return {
      capabilities: query.data?.capabilities,
      features: query.data?.features,
      object: query.data?.object,
      workspace: query.data?.workspace,
    };
  }, [query.data]);

  if (query.isFetched && !query.data?.transcript?.id) {
    const e = query.error;
    const data = e?.data as IWorkspaceObjectId;
    const forbidden = query.isError && e.status === 403;
    const hasObjectId = data !== null && typeof data === 'object' && !!data?.objectId;

    return forbidden && hasObjectId
      ? <Redirect to={generatePath(path.Access.Request, data)} />
      : <Redirect to={path.Projects.Root} />;
  }

  return (
    <TranscriptAccessContext.Provider value={access}>
      <CallTranscriptDetailsContext.Provider value={{
        call: query.data?.call,
        features: query.data?.features,
        project: query.data?.project,
        settings: query.data?.settings,
      }}>
        <TranscriptEnhancementStateContext.Provider value={query.data?.enhancement}>
          <RefetchTranscriptContext.Provider value={query.refetch}>
            <TranscriptIdContext.Provider value={transcriptId}>
              <TranscriptDocumentContext.Provider value={query.data?.transcript}>
                <TranscriptSpeakersContext.Provider value={query.data?.speakers}>
                  <TranscriptDataContainer audioOnly={!isInternalUser}>
                    <TranscriptMetadataContainer>
                      {children}
                    </TranscriptMetadataContainer>
                  </TranscriptDataContainer>
                </TranscriptSpeakersContext.Provider>
              </TranscriptDocumentContext.Provider>
            </TranscriptIdContext.Provider>
          </RefetchTranscriptContext.Provider>
        </TranscriptEnhancementStateContext.Provider>
      </CallTranscriptDetailsContext.Provider>
    </TranscriptAccessContext.Provider>
  );
};

const WithState = ({ children, ...props }: Props) => {
  return (
    <TranscriptStateContainer>
      <TranscriptTypeContext.Provider value={TranscriptType.VideoCall}>
        <CallTranscriptMentionsContainer callId={props.callId}>
          <CallTranscriptContainer {...props}>
            <DownloadCallMediaContainer callId={props.callId}>
              {children}
            </DownloadCallMediaContainer>
          </CallTranscriptContainer>
        </CallTranscriptMentionsContainer>
      </TranscriptTypeContext.Provider>
    </TranscriptStateContainer>
  );
};

export { WithState as CallTranscriptContainer };