import { useTwilioVideo } from '@containers/Conference';
import { useScreenShareParticipant } from '@/components/Conference.Video/hooks';
import { useSelectedParticipant } from './hooks';
import { SessionMainParticipant } from './Session.MainParticipant';
import { SessionParticipantStrip } from './Session.ParticipantStrip';
import { ParticipantGallery } from './Session.ParticipantGallery';
import styles from './style/Session.Video.css';

export function SessionVideo() {
  const twilio = useTwilioVideo();
  const screenShareParticipant = useScreenShareParticipant(twilio.room);
  const [selectedParticipant] = useSelectedParticipant();

  if ((screenShareParticipant && twilio.room.localParticipant != screenShareParticipant) || selectedParticipant) {
    return (
      <div className={styles.mainRoot}>
        <SessionMainParticipant />
        <SessionParticipantStrip />
      </div>
    );
  }

  return (
    <div className={styles.galleryRoot}>
      <ParticipantGallery />
    </div>
  );
}