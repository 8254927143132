import { ComponentType } from 'react';
import { NodeViewComponentProps } from '@remirror/react';
import { useSurveyBuilderState, useIsEditingSurvey, useTargetProductProfileData } from '@containers/SurveyBuilder/hooks';
import { SurveyRichText, SurveyTemplate } from '@/types/survey';
import { useTemplatePriceLinksModal } from '@/components/Survey.Templates/Modal.PriceLinks';
import styles from './style/Node.PriceLink.css';

export const TemplateAdditionalPriceLinks: ComponentType<NodeViewComponentProps> = props => {

  const ctx = useSurveyBuilderState();

  return ctx
    ? <BuilderNode {...props} />
    : <Node {...props} />;
};

function BuilderNode({ node }: NodeViewComponentProps) {
  const editing = useIsEditingSurvey();

  const attrs = (node.attrs as SurveyRichText.AdditionalPriceLinkNode['attrs']);

  const [toggleModal, Modal] = useTemplatePriceLinksModal();

  return (
    <>
      <div className={styles.link} onClick={toggleModal}>{attrs.text}</div>
      {!editing && <Modal />}
    </>
  );
}

function Node({ node }: NodeViewComponentProps) {
  const attrs = (node.attrs as SurveyRichText.AdditionalPriceLinkNode['attrs']);

  return (
    <a
      className={styles.link}
      href={attrs.href}
      target="_blank"
      rel="noopener noreferrer">
      {attrs.text}
    </a>
  );
}

export default TemplateAdditionalPriceLinks;