import { useContext } from 'react';
import { CallParticipantsQueryContext } from '@containers/Transcript/context';
import { Divider, SidebarHeader } from '@presentation/Main';
import { hasConsultantRole } from '@utils';
import { CallRole } from '@enums';
import styles from '@screens/Transcript/style/Tab.Details.css';

export const CallDetailsParticipants = () => {

  const participants = useContext(CallParticipantsQueryContext);

  return (
    <>
      <SidebarHeader title="Participants" />
      <Divider />

      <div className={styles.participants}>
        {participants.data.participants
          .map(p => {
            const isMember = hasConsultantRole(p);

            return (
              <div
                key={p.id}
                className={styles.participant}>
                {p.profile.fullname}
                {isMember && <span className={styles.member}>(Expert)</span>}
              </div>
            );
          })}
        {participants.data.offPlatformParticipants
          .map(p => {
            return (
              <div
                key={`off-platform-${p.userId}`}
                className={styles.participant}>
                {p.email}
                {p.callRoleId === CallRole.PrimaryRespondent && <span className={styles.member}>(Expert)</span>}
              </div>
            );
          })}
      </div>
    </>
  );
};