import { format } from 'date-fns';
import { ConferenceLogType } from '@/types';
import type { ConferenceLogRecord } from '@/types';
import { Tooltip } from '@presentation/Tooltip';

type LogItemProps<T extends ConferenceLogType> = {
  pidNameMapper: (pid: string) => string;
  record: ConferenceLogRecord<T>;
  classes?: {
    root?: string;
    timestamp?: string;
  };
};

export const LogItem = <T extends ConferenceLogType>({ record, pidNameMapper, classes = {} }: LogItemProps<T>) => {

  return (
    <div className={classes.root}>
      <div className={classes.timestamp}>
        <Tooltip title={format(new Date(record.logTime), 'PPpp')} placement='top'>
          <div>{format(new Date(record.logTime), 'pp')}</div>
        </Tooltip>
      </div>
      <div>
        <LogItemContent record={record} pidNameMapper={pidNameMapper} />
      </div>
    </div>
  );
};

const LogItemContent = <T extends ConferenceLogType>({ record, pidNameMapper: getPidName }: LogItemProps<T>) => {
  switch (record.typeId) {
    case ConferenceLogType.ParticipantJoined:
      return <>{getPidName(record.loggedBy)} has joined the conference.</>;
    case ConferenceLogType.ParticipantLeft:
      assertRecordType<ConferenceLogType.ParticipantLeft>(record);
      return <>{getPidName(record.loggedBy)} has left the conference. Reason: {record.context.reason}</>;
    case ConferenceLogType.Error:
      assertRecordType<ConferenceLogType.Error>(record);
      return <>{getPidName(record.loggedBy)} error: {record.context.message}</>;
    case ConferenceLogType.Info:
      assertRecordType<ConferenceLogType.Info>(record);
      return <>{getPidName(record.loggedBy)} info: {record.context.message}</>;

    default: {
      const type: never = record.typeId; //Just for case exhaustion
      return <Tooltip title={JSON.stringify(record)}><div>Unknown Log Type</div></Tooltip>;
    }
  }
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
function assertRecordType<T extends ConferenceLogType>(record: ConferenceLogRecord): asserts record is ConferenceLogRecord<T> {

}