import { useTable, useFlexLayout, usePagination, Column } from 'react-table';
import { Card, CardContent, GroupCardHeader } from '@presentation/Main';
import { GroupTable, GroupTableFooter } from '@presentation/GroupTable';
import { AnimatedLoader } from '@/components/ActivityIndicator';
import SeeAll from './SeeAll';
import { TopicsTableItem } from './interfaces';
import { useTopicsTableColumns } from './hooks';
import styles from './style/Table.css';

type Props = {
  data: TopicsTableItem[];
  countText: string;
  pagination?: boolean;
  pageSize: number;
  isLoading: boolean;
  seeAll?: boolean;
};

export default function SearchTableTopics({ data, countText, pagination, pageSize, isLoading, seeAll }: Props) {
  const columns = useTopicsTableColumns() as Column<TopicsTableItem>[];

  const tableInstance = useTable<TopicsTableItem>(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize,
      },
    },
    useFlexLayout,
    usePagination,
  );

  const tableClasses = {
    body: styles.body,
    td: styles.td,
    tr: styles.tr,
  };

  const footerClasses = {
    root: styles.footer,
    pageSize: styles.rows,
  };

  return (
    <Card className={styles.card}>
      <GroupCardHeader className={styles.header}>
        <div className={styles.left}>
          <div>Topics <span className={styles.count}>{countText}</span></div>
        </div>
        {seeAll && (
          <div className={styles.right}>
            <SeeAll tab="topics" />
          </div>)}
      </GroupCardHeader>
      <CardContent className={styles.content} fluid>
        {!isLoading && !!data.length &&
          <GroupTable
            classes={tableClasses}
            instance={tableInstance}
            header={false} />}
        {isLoading && (<AnimatedLoader className={styles.loading} />)}
        {!isLoading && pagination && !!data.length &&
          <GroupTableFooter
            classes={footerClasses}
            instance={tableInstance} />}
      </CardContent>
    </Card>
  );
}

export { SearchTableTopics };