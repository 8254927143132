import { memo } from 'react';

type Props = {
  className?: string;
  size?: number;
};

const AvatarOutline = memo(({
  className,
  size = 70,
}: Props) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 68 68">
    <g id="Client-Homepage" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="1" transform="translate(-839.000000, -526.000000)" stroke="#CCCCCC" strokeWidth="2">
        <g id="Group-2" transform="translate(840.000000, 527.000000)">
          <circle id="Oval" cx="33" cy="33" r="33"></circle>
            <path
            d="M12,58.1799216 L12,51.4074835 C12,48.3854282 13.702949,45.6212959 16.4021373,44.2621784 L25.2529195,39.8055599 C25.9277166,39.4657805 26.3534538,38.7747475 26.3534538,38.0192337 L26.3534538,33.302023 L26.3534538,33.302023 C24.6469439,31.6242791 23.6857811,29.3314365 23.6857811,26.9383212 L23.6857811,21.8396591 C23.6857811,16.9576502 27.6434312,13 32.5254402,13 L33.0492208,13 C37.8092017,13 41.7460963,16.7064403 42.0328722,21.4577745 L42.3640712,26.9451168 C42.5072621,29.317518 41.6166009,31.6357744 39.9217833,33.302023 L39.9217833,33.302023 L39.9217833,33.302023 L39.9217833,38.0299988 C39.9217833,38.7798825 40.3412626,39.4667589 41.0083725,39.8092348 L49.6536431,44.2474788 C52.3220831,45.6173824 54,48.3648882 54,51.3644228 L54,58.1799216 L54,58.1799216"
            id="Path-9"
            strokeLinecap="round"
            strokeLinejoin="round"></path>
        </g>
      </g>
    </g>
  </svg>
));

export { AvatarOutline };
export default AvatarOutline;