import type { ChangeEvent } from 'react';
import { useCallback, useState, useMemo } from 'react';
import { Trash2 as Trash } from 'react-feather';
import { GrayOutlineButton } from '@presentation/Buttons';
import { Button } from '@/components/Button';
import { Input } from '@/components/Input';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import ModalHeader from '@/components/Modal/Header';
import { useModal } from '@/components/Modal/hooks';
import styles from './style/Modal.css';

type EditProps = {
  label?: string;
  groupingId?: string;
  showCount?: boolean;
  onSubmit: (label: string, groupingId: string, count: number) => void;
  onRemove?: () => void;
};

type Props =
  & EditProps
  & Pick<ModalProps, 'open' | 'onClose'>;

export const FindAndReplaceEditModal = ({
  showCount,
  onSubmit,
  onClose,
  open,
  ...props
}: Props) => {
  const [label, setLabel] = useState(props.label || '');
  const [groupingId, setGroupingId] = useState(props.groupingId || '');
  const [count, setCount] = useState('1');

  const canSubmit = useMemo(() => {
    const castedCount = +count || 0;

    if (!label?.length) return false;
    if (castedCount < 1) return false;

    return true;
  }, [label, count]);

  const handleSubmit = useCallback(() => {
    onSubmit((label + '').trim(), (groupingId + '').trim(), Math.floor(+count || 1) || 1);
  }, [onSubmit, label, groupingId, count]);

  const handleLabelChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setLabel(e.target.value);
  }, []);

  const handleGroupingIdChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setGroupingId(e.target.value);
  }, []);

  const handleCountChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCount(e.target.value);
  }, []);

  const editing = props.label?.length;

  const title = editing
    ? `Edit Structured Entry`
    : `Insert Structured Entry`;

  return (
    <Modal
      open={open}
      onClose={onClose}>

      <ModalHeader text={title} />

      <div className={styles.body}>
        {editing
          ? (
            <>
              <div className={styles.label}>Grouping ID</div>
              <div className={styles.input}>
                <Input
                  autoFocus
                  placeholder={`Input a grouping id`}
                  onChange={handleGroupingIdChange}
                  value={groupingId} />
              </div>
            </>
            )
          : null}
        <div className={styles.label}>Label</div>
        <div className={styles.input}>
          <Input
            autoFocus
            placeholder={`Input a label`}
            onChange={handleLabelChange}
            value={label} />
        </div>
        {showCount
          ? (
            <>
              <div className={styles.label}>Number of Values</div>
              <div className={styles.input}>
                <Input
                  autoComplete="off"
                  autoCapitalize="off"
                  autoCorrect="off"
                  placeholder={`Number of Values`}
                  onChange={handleCountChange}
                  value={`${count}`} />
              </div>
            </>
          ) : null}
      </div>

      <div className={styles.footer}>
        <div className={styles.buttons}>
          <GrayOutlineButton
            onClick={onClose}
            title="Cancel" />
          <Button.Primary
            className={styles.submit}
            disabled={!canSubmit}
            onClick={handleSubmit}
            title="Save"
            variant="brick" />
        </div>
        {props.onRemove
          ? (
            <div className={styles.remove}>
              <Trash
                className={styles.removeIcon}
                size={24}
                onClick={props.onRemove} />
            </div>
            )
          : null}
      </div>
    </Modal>
  );
};

export const useFindAndReplaceEditModal = () => useModal(FindAndReplaceEditModal);

export default FindAndReplaceEditModal;