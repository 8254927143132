import { QuestionText } from '@presentation/Survey';
import type { SurveyFormQuestion } from '@/types';
import { QuestionHelpText } from './HelpText';

type Props = {
  className?: string;
  item: SurveyFormQuestion;
  textClassName?: string;
};

export function FormQuestionText({ className, item, textClassName }: Props) {

  return (
    <QuestionText
      item={item}
      className={className}
      textClassName={textClassName}>
      <QuestionHelpText item={item} />
    </QuestionText>
  );
}

export default FormQuestionText;