import { useCallback, useContext, useEffect, useMemo } from 'react';
import { ConferenceCoordinatorEventContext } from '@containers/Conference/Context';
import { ConferenceTagType } from '@enums';
import { TaggingContext } from '@/components/Conference.Tagging/Context';
import { PanelToolsContext } from './Context';
import { usePopupNotesWindow, usePopupTaggingWindow } from './hooks';
import type { InstanceState } from './interfaces';

type Props = {
  children: React.ReactNode;
  instance: InstanceState;
  notes: ToggleState;
  tagging: ToggleState;
} & ICallId;

export const Container = (props: Props) => {
  const { off, on } = useContext(ConferenceCoordinatorEventContext);
  const ctx = useContext(TaggingContext);
  const params = useMemo(() => {
    return props.instance.status === 'meeting-room'
      ? { callId: props.callId }
      : null;
  }, [
    props.callId,
    props.instance.status,
  ]);

  const transfer = useMemo(() => {
    return {
      callId: props.callId,
      instance: props.instance,
      tagging: {
        items: ctx.items,
      },
    };
  }, [
    props.callId,
    ctx.items,
    props.instance,
  ]);

  const win = usePopupTaggingWindow({
    callId: props.callId,
    onOpen: (win: Window) => {
      if (win) {
        win.postMessage({
          type: `tagging`,
          callId: transfer.callId,
          instance: transfer.instance,
          tagging: transfer.tagging,
        }, '*');
      }
    },
  });

  const onMessage = useCallback((e: MessageEvent) => {
    /* eslint-disable-next-line */
    if (e.data.type === 'tagging') {
      /* eslint-disable-next-line */
      if (e.data.action === 'unload') {
        /* eslint-disable-next-line */
        ctx.replace(e.data.tagging.items);
      }
    }
  }, [ctx]);

  const handleLeaveEvent = useCallback(() => {
    const unsaved = ctx.items
      .filter(tag => tag.start && tag.name && !tag.end)
      .map(tag => ({ ...tag, end: new Date() }));

    unsaved.forEach(tag => {
      ctx.mutation.mutate({
        identifier: tag.identifier,
        item: {
          color: tag.color,
          end: tag.end,
          id: tag.id,
          name: tag.name,
          start: tag.start,
          typeId: ConferenceTagType.UserCreated,
        },
      });
    });
  }, [
    ctx.items,
    ctx.mutation,
  ]);

  useEffect(() => {

    if (on) {
      on('leave', handleLeaveEvent);
    }

    return () => {
      if (off) {
        off('leave', handleLeaveEvent);
      }
    };

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [
    off,
    on,
  ]);

  useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [
    win?.popup,
    onMessage,
  ]);

  const mini = {
    notes: usePopupNotesWindow(params),
    tagging: win,
  };

  const value = useMemo(() => ({
    notes: {
      enabled: props.instance.status === 'meeting-room' && props.instance.features.notes && !mini.notes.isOpen,
      popout: mini.notes.open,
      toggle: props.notes.toggle,
      visible: props.instance.status === 'meeting-room' && props.instance.features.notes && !mini.notes.isOpen && props.notes.visible,
    },
    tagging: {
      enabled: props.instance.status === 'meeting-room' && props.instance.features.tags && !mini.tagging.isOpen,
      popout: mini.tagging.open,
      toggle: props.tagging.toggle,
      visible: props.instance.status === 'meeting-room' && props.instance.features.tags && !mini.tagging.isOpen && props.tagging.visible,
    },
  }), [
    mini.notes.open,
    mini.notes.isOpen,
    mini.tagging.open,
    mini.tagging.isOpen,
    props.instance.features.notes,
    props.instance.features.tags,
    props.instance.status,
    props.notes.visible,
    props.notes.toggle,
    props.tagging.toggle,
    props.tagging.visible,
  ]);

  return (
    <PanelToolsContext.Provider value={value}>
      {props.children}
    </PanelToolsContext.Provider>
  );
};

Container.displayName = 'Panel.Container';

type ToggleState = {
  toggle: () => void;
  visible: boolean;
};