import cuid from 'cuid';
import { SurveyConditionType, SurveyQuestionConditionType, SurveySumConditionItemType } from '@enums';
import type { SurveyLogic } from '@/types/survey';
import type { SurveyCondition } from '@/types/survey.logic.condition';

type GetDefaultQuestionConditionData<T extends SurveyQuestionConditionType = SurveyQuestionConditionType> = {
  type: T;
  question: { identifier: string };
};

type GetDefaultCondition<T extends SurveyConditionType = SurveyConditionType> = {
  conditionType: T;
};

export function getDefaultCondition<T extends SurveyConditionType = SurveyConditionType>(data: GetDefaultCondition<T>): SurveyCondition {
  if (data.conditionType === SurveyConditionType.Question) {
    const value: SurveyLogic.QuestionCondition = {
      conditionType: SurveyConditionType.Question,
      data: {
        question: { identifier: '' },
        type: null,
        value: null,
      },
      identifier: cuid(),
    };
    return value;
  } else if (data.conditionType === SurveyConditionType.AggregatedSum) {
    const value: SurveyLogic.SumCondition = {
      conditionType: SurveyConditionType.AggregatedSum,
      data: {
        comparate: null,
        operator: null,
        items: [],
      },
      identifier: cuid(),
    };
    return value;
  } else if (data.conditionType === SurveyConditionType.Classification) {
    return {
      conditionType: SurveyConditionType.Classification,
      data: {
        identifier: '',
      },
      identifier: cuid(),
    };
  } else {
    throw new UnreachableCaseError(data.conditionType);
  }
}

export function getDefaultQuestionConditionData<T extends SurveyQuestionConditionType = SurveyQuestionConditionType>({ type, question }: GetDefaultQuestionConditionData<T>): SurveyLogic.QuestionConditionData {

  switch (type) {
    case SurveyQuestionConditionType.MatrixValueChosen:
      return {
        question,
        type: SurveyQuestionConditionType.MatrixValueChosen,
        value: {
          row: { identifier: '' },
          option: { identifier: '' },
        },
      };

    case SurveyQuestionConditionType.MatrixValueNotChosen:
      return {
        question,
        type: SurveyQuestionConditionType.MatrixValueNotChosen,
        value: {
          row: { identifier: '' },
          option: { identifier: '' },
        },
      };

    case SurveyQuestionConditionType.NumberCellValue:
      return {
        question,
        type: SurveyQuestionConditionType.NumberCellValue,
        value: {
          row: { identifier: '' },
          option: { identifier: '' },
          operator: null,
          comparate: null,
        },
      };

    case SurveyQuestionConditionType.NumberOfOptionsSelected:
      return {
        question,
        type: SurveyQuestionConditionType.NumberOfOptionsSelected,
        value: {
          operator: null,
          comparate: null,
        },
      };

    case SurveyQuestionConditionType.OptionNotSelected:
    case SurveyQuestionConditionType.OptionSelected:
    case SurveyQuestionConditionType.OptionRankedFirst:
    case SurveyQuestionConditionType.OptionRankedLast: {
      return {
        question,
        type,
        value: {
          option: { identifier: '' },
        },
      };
    }

    case SurveyQuestionConditionType.OptionRankedTopX: {
      return {
        question,
        type: SurveyQuestionConditionType.OptionRankedTopX,
        value: {
          option: { identifier: '' },
          ordinal: null,
        },
      };
    }

    case SurveyQuestionConditionType.SliderValue: {
      return {
        question,
        type: SurveyQuestionConditionType.SliderValue,
        value: {
          row: { identifier: '' },
          operator: null,
          comparate: null,
        },
      };
    }

    case SurveyQuestionConditionType.SliderValuesTally: {
      return {
        question,
        type: SurveyQuestionConditionType.SliderValuesTally,
        value: {
          answer: {
            operator: null,
            comparate: null,
          },
          rows: {
            operator: null,
            comparate: null,
          },
        },
      };
    }

    case SurveyQuestionConditionType.Straightline:
      return {
        question,
        type: SurveyQuestionConditionType.Straightline,
      } as SurveyLogic.QuestionConditionData;

    default:
      throw new UnreachableCaseError(type);

  }
}

export function getDefaultItemValue(type: SurveySumConditionItemType) {
  if (type === SurveySumConditionItemType.NumberTableCellValue) {
    return getDefaultNumberTableItemValue();
  } else if (type === SurveySumConditionItemType.SliderRowValue) {
    return getDefaultSlidersItemValue();
  }
}

export function getDefaultSlidersItemValue(): SurveyLogic.SumConditionSlidersQuestionItemValue {
  return {
    identifier: cuid(),
    row: { identifier: '' },
  };
}

export function getDefaultNumberTableItemValue(): SurveyLogic.SumConditionNumberTableItemValue {
  return {
    identifier: cuid(),
    row: { identifier: '' },
    option: { identifier: '' },
  };
}