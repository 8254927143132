import { useLanguagesQuery } from '@utils/api';
import { SelectSearchUnderlined } from '$admin/components/Select/Select.Search.Underlined';

type Props = {
  defaultValues: number[];
  onChange: (vals: Descriptor[]) => void;
};

export const SelectLanguage = ({ defaultValues, onChange }: Props) => {
  const query = useLanguagesQuery();

  if (query.isLoading) {
    return (
      <SelectSearchUnderlined
        key={'disabled-language-select'}
        isLoading={true}
        options={[]}
        isMulti={false}
        isDisabled={true} />
    );
  }

  return (
    <SelectSearchUnderlined
      key={'enabled-language-select'}
      isLoading={query.isLoading}
      options={query.data}
      defaultValue={query.data.filter(o => defaultValues.includes(o.id))}
      onChange={(d: Descriptor) => onChange([d])}
      getOptionLabel={o => o.name}
      getOptionValue={o => o.id.toString()}
      isMulti={false} />
  );
};