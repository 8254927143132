import { useMemo } from 'react';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { isQuestionInCondition } from '@/containers/SurveyBuilder.Logic/utils.lookup';
import { SurveyTaggingResultType } from '@/enums';

export const useQuestionClassifications = (questionIdentifier: string) => {
  const [state] = useSurveyBuilderState();

  const logic = useMemo(() => {
    return state.survey.tagging
      .filter(item => {
        return item.action.type === SurveyTaggingResultType.Classification &&
          item.conditions.some(condition => isQuestionInCondition({
          condition,
          question: { identifier: questionIdentifier },
        }));
      });
  }, [questionIdentifier, state.survey.tagging]);

  return logic;
};