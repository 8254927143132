import { useContext } from 'react';
import { AccountProfileFieldsStateContext, AccountProfileRefetchContext } from '@containers/AccountProfile/Context';

const useProfileState = () => {
  const [state, dispatch] = useContext(AccountProfileFieldsStateContext);
  const refetch = useContext(AccountProfileRefetchContext);

  return [state, dispatch, refetch] as const;
};

export { useProfileState };
export default useProfileState;