import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as $facebook from '@services/auth/facebook';
import { qs } from '@utils';
import { Spinner } from '@/components/ActivityIndicator';
import styles from './style/Inbound.css';

type DecodedHash = {
  access_token: string;
};

export const Facebook = () => {
  const location = useLocation();

  useEffect(() => {
    const params = qs.parse<DecodedHash>(location.hash);

    if (params.access_token) {
      $facebook.callback({
        hash: location.hash,
      });
    }

  }, [location.hash]);

  return (
    <div className={styles.root}>
      <Spinner />
    </div>
  );
};