import { forwardRef, useCallback, useContext } from 'react';
import { ProjectConferenceTagWithMetadata } from '@/types';
import { Tooltip } from '@presentation/Tooltip';
import { PopperMenu, PopperMenuItem } from '@/components/Popper';
import { TagStateContext } from '@/components/Project.Tags/Context';
import { useToggleTagVisiblityMutation } from '@/components/Project.Tags/hooks';
import { isTagEditable } from './utils';

type Props = {
  close?: () => void;
  onEditTag: () => void;
  onCreateTag: () => void;
  onDeleteTag: () => void;
  tag: ProjectConferenceTagWithMetadata;
};

export const MenuItems = forwardRef(({ close, tag, onEditTag, onCreateTag, onDeleteTag }: Props, ref: React.Ref<HTMLDivElement>) => {
  const ctx = useContext(TagStateContext);
  const toggleTagVisibilityMutation = useToggleTagVisiblityMutation({ projectId: ctx.projectId, projectTagId: tag.id });

  const closeWrapper = useCallback((fn: () => void) => {
    return () => {
      fn();
      close();
    };
  }, [close]);

  const onToggleVisibilityClick = useCallback((visible: boolean) => {
    toggleTagVisibilityMutation.mutate({
      visible,
    });
    close();
  }, [toggleTagVisibilityMutation, close]);

  const disableEditing = !isTagEditable(tag);
  const disableDelete = !onDeleteTag;

  return (
    <PopperMenu ref={ref}>
      <Tooltip title={disableEditing ? disabledEditingHelpText : null} placement='top'>
        <div>
          <PopperMenuItem disabled={disableEditing} onClick={closeWrapper(onEditTag)}>
            <span>Edit</span>
          </PopperMenuItem>
        </div>
      </Tooltip>
      {!tag.parent?.id ?
        <PopperMenuItem onClick={closeWrapper(onCreateTag)}>
          Add Subtag
        </PopperMenuItem>
        : null}
      <PopperMenuItem onClick={() => onToggleVisibilityClick(!!tag.hidden)}>
        {tag.hidden ? 'Unhide' : 'Hide'}
      </PopperMenuItem>
      <Tooltip title={disableDelete ? disabledTagDeleteText : null} placement='top'>
        <PopperMenuItem disabled={disableDelete} onClick={closeWrapper(onDeleteTag)}>
          Delete
        </PopperMenuItem>
      </Tooltip>
    </PopperMenu>
  );
});

const disabledEditingHelpText = 'This tag is a global or project type defined tag and cannot be edited';
const disabledTagDeleteText = 'Tags with children cannot be deleted';