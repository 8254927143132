import { useCallback, useMemo, useState, useEffect } from 'react';
import { maxLengths } from '@consts';
import { ProfileEntry } from '@enums';
import { EducationForm, EducationRecord } from '@/types';
import { ProfileEducationForm } from './Profile.Education.Form';
import { validateEducation } from './Profile.Education.Form.validation';
import { Section } from './interfaces';
import { generateDuring } from './Profile.DateRange.Form';
import { ProfileEducationFormContext } from './Context';
import { useProfileFormValidation } from './hooks';

type Props =
  Section.MultiEntry.FormProps<ProfileEntry.Education>;

const ProfileEducationFormContainer = ({ onSave, ...props }: Props) => {
  const [item, setItem] = useState<EducationForm>(transformItem(props.value) || createItem());
  const [validations, runValidations] = useProfileFormValidation<EducationForm>({
    validate: validateEducation,
  });

  const save = useCallback(() => {
    const { startMonth, startYear, endMonth, endYear, ...rest } = item;

    const during = generateDuring(item);
    return onSave({
      duration: {
        startDate: during[0],
        endDate: during[1],
      },
      ...rest,
    });
  }, [onSave, item]);

  const handleSubmit = useCallback(() => {
    const passed = runValidations(item, true);

    if (passed) {
      return save();
    }
  }, [
    item,
    runValidations,
    save,
  ]);

  useEffect(() => {
    runValidations(item, false);
  }, [item, runValidations]);

  const canSave = useMemo(() => {
    return item.description.length <= maxLengths.EDUCATION_DESCRIPTION &&
      item.school?.name &&
      item.degree?.name &&
      !!item.startYear;
  }, [
    item,
  ]);

  const value = {
    canSave,
    item,
    onCancel: props.onCancel,
    onDelete: props.onDelete,
    onSubmit: handleSubmit,
    setItem,
    validations,
  };

  return (
    <ProfileEducationFormContext.Provider value={value}>
      <ProfileEducationForm />
    </ProfileEducationFormContext.Provider>
  );
};

function transformItem(item: EducationRecord): EducationForm {
  if (!item) return null;

  return {
    id: item.id,
    school: {
      id: item.schoolId,
      name: item.school,
    },
    degree: {
      id: item.degreeId,
      name: item.degree,
    },
    description: item.description || '',
    startMonth: item.startMonth,
    startYear: item.startYear,
    endMonth: item.endMonth,
    endYear: item.endYear,
  };
}

function createItem(): EducationForm {
  return {
    id: null,
    school: null,
    degree: null,
    description: '',
    startMonth: new Date().getMonth() + 1,
    startYear: new Date().getFullYear() - 1,
    endMonth: new Date().getMonth() + 1,
    endYear: new Date().getFullYear(),
  };
}
export { ProfileEducationFormContainer };
export default ProfileEducationFormContainer;