import { useCallback } from 'react';
import { GrayOutlineButton } from '@presentation/Buttons';
import { Button } from '@/components/Button';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import ModalHeader from '@/components/Modal/Header';
import { useModal } from '@/components/Modal/hooks';
import {
  useCanSavePartnershipCriteria,
  useTemplatePartnershipCriteria,
} from './hooks/partnership-criteria';
import {
  useCanSaveEvaluationCriteria,
  useTemplateEvaluationCriteria,
} from './hooks/evaluation-criteria';
import { TargetCriteriaInputProps, TemplateCriteriaInput } from './Input.Criteria';
import styles from './style/Modal.css';

type Props =
  Pick<ModalProps,
    'open' |
    'onClose'>;

type BaseProps = {
  canSubmit: boolean;
  onSubmit: () => void;
  title: string;
} & TargetCriteriaInputProps & Props;

const TemplateCriteriaModal = ({
  canSubmit,
  onSubmit,
  open,
  onClose,
  title,
  ...inputProps
}: BaseProps) => {

  return (
    <Modal
      open={open}
      onClose={onClose}>

      <ModalHeader text={title} />

      <div className={styles.body}>
        <TemplateCriteriaInput
          {...inputProps} />
      </div>

      <div className={styles.footer}>
        <GrayOutlineButton
          onClick={onClose}
          title="Cancel" />
        <Button.Primary
          className={styles.submit}
          disabled={!canSubmit}
          onClick={onSubmit}
          title="Submit"
          variant="brick" />
      </div>
    </Modal>
  );
};

export const TemplateEvaluationCriterialModal = ({
  onClose,
  open,
}: Props) => {

  const {
    onSubmit,
    ...inputProps
  } = useTemplateEvaluationCriteria();

  const handleSubmit = useCallback(() => {
    onSubmit();
    onClose();
  }, [
    onSubmit,
    onClose,
  ]);

  const canSubmit = useCanSaveEvaluationCriteria(inputProps.criteria);

  return (
    <TemplateCriteriaModal
      title="Update Template Evaluation Criteria"
      canSubmit={canSubmit}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      {...inputProps} />
  );
};

export const TemplatePartnershipCriterialModal = ({
  onClose,
  open,
}: Props) => {

  const {
    onSubmit,
    ...inputProps
  } = useTemplatePartnershipCriteria();

  const handleSubmit = useCallback(() => {
    onSubmit();
    onClose();
  }, [
    onSubmit,
    onClose,
  ]);

  const canSubmit = useCanSavePartnershipCriteria(inputProps.criteria);

  return (
    <TemplateCriteriaModal
      title="Update Template Partnership Criteria"
      canSubmit={canSubmit}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      {...inputProps} />
  );
};

export const useTemplateEvaluationCriteriaModal = () => useModal(TemplateEvaluationCriterialModal);
export const useTemplatePartnershipCriteriaModal = () => useModal(TemplatePartnershipCriterialModal);