import { useCallback } from 'react';
import { SurveyFormQuestionAnswerContext, SurveyFormAnswerRationaleContext } from '@containers/SurveyForm';
import { SurveyFormQuestion, RespondentAnswer } from '@/types';
import { SurveyFormProps } from './interfaces';
import QuestionnaireItem from './QuestionnaireItem';
import styles from './style/SurveyForm.css';

export const Questionnaire = ({ answers, questions, setAnswer }: SurveyFormProps) => {
  const renderQuestion = useCallback((item: SurveyFormQuestion) => {
    const updateAnswer = (value: RespondentAnswer) => {
      setAnswer({
        questionId: item.id,
        value,
      });
    };

    const answer = answers[item.id];

    return (
      <div key={item.id} className={styles.question}>
        <SurveyFormAnswerRationaleContext.Provider value={['', () => {}]}>
          <SurveyFormQuestionAnswerContext.Provider value={[answer, updateAnswer]}>
            <QuestionnaireItem
              item={item} />
          </SurveyFormQuestionAnswerContext.Provider>
        </SurveyFormAnswerRationaleContext.Provider>
      </div>
    );
  }, [setAnswer, answers]);

  return (
    <>{questions.map(renderQuestion)}</>
  );
};

export default Questionnaire;