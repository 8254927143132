import { useMemo, useCallback } from 'react';
import { useSurveyClassifications } from '@/containers/SurveyBuilder/hooks';
import DropDown from '@/components/DropDown';

export const ClassificationDropdown = ({
  onSelect,
  value,
}: Props) => {

  const classifications = useSurveyClassifications();

  const items = useMemo(() => {
    return classifications.map(m => ({
      id: m.identifier,
      name: m.name,
    }));
  }, [classifications]);

  const handleSelect = useCallback((item: ClassificationDropdownItem) => {
    onSelect(item.id);
  }, [onSelect]);

  const text = items.find(f => f.id === value)?.name;

  return (
    <DropDown
      placeholder="Select classification"
      items={items}
      getItemValue={v => v.name}
      onSelect={handleSelect}
      text={text} />
  );
};

type ClassificationDropdownItem = {
  id: string;
  name: string;
};

type Props = {
  // items?: Operator[];
  onSelect: (identifier: string) => void;
  value: string;
};

export default ClassificationDropdown;