import { ConsultantsContainer } from './Search.Consultants.Container';
import { TopicsContainer } from './Search.Topics.Container';

type Props = {
  children: React.ReactNode;
};

export const Container = (props: Props) => {
  return (
    <ConsultantsContainer>
      <TopicsContainer>
        {props.children}
      </TopicsContainer>
    </ConsultantsContainer>
  );
};

Container.displayName = 'PostCreation.Search.Container';