import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import * as QK from '@consts/querykey';
import { useAppReadyState } from '@containers/AppReadyState';
import { VideoAdditionalDetailsContext } from './Context';

type Props = {
  children:      React.ReactNode;
  hasTranscript: boolean;
  mediaId:       number;
};

export const AdditionalDetailsContainer = (props: Props) => {
  const app = useAppReadyState();

  const comments = useQuery([QK.Discovery.Videos.Comments.Get, props.mediaId], () => {
    return api.discovery.fetchVideoComments({
      mediaId: props.mediaId,
    });
  }, {
    enabled: app.authenticated && !!props.mediaId,
  });

  const transcript = useQuery([QK.Discovery.Videos.Transcripts.Get, props.mediaId], () => {
    return api.discovery.fetchVideoTranscript({
      mediaId: props.mediaId,
    });
  }, {
    enabled: props.hasTranscript,
    refetchOnWindowFocus: false,
  });

  const context = {
    comments: {
      enabled: app.authenticated,
      query: comments,
    },
    mediaId: props.mediaId,
    transcript: {
      enabled: props.hasTranscript,
      query: transcript,
    },
  };

  return (
    <VideoAdditionalDetailsContext.Provider value={context}>
      {props.children}
    </VideoAdditionalDetailsContext.Provider>
  );
};

AdditionalDetailsContainer.displayName = 'VideoAdditionalDetailsContainer';