import { useCallback, useMemo } from 'react';
import { SurveyQuestionOption, SurveyQuestionOptionMetadata, SurveySettings } from '@/types/survey';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { ContextMenuItemProps } from '@/components/SurveyBuilder/ContextMenu.Item';

type Props = {
  questionIdentifier: string;
  option: SurveyQuestionOption<SurveyQuestionOptionMetadata<{ isExclusive: boolean }>>;
};

export const useExclusiveOptionMenuItem = (props: Props) => {

  const [_, dispatch] = useSurveyBuilderState();

  const isExclusive = !!props.option.metadata.isExclusive;

  const toggle = useCallback(() => {
    dispatch({
      type: 'option-exclusive-updated',
      payload: {
        option: { identifier: props.option.base.identifier },
        questionIdentifier: props.questionIdentifier,
        value: !isExclusive,
      },
    });
  }, [
    dispatch,
    props.questionIdentifier,
    props.option.base.identifier,
    isExclusive,
  ]);

  const exclusiveMenuItem: ContextMenuItemProps = useMemo(() => {

    return {
      checked: isExclusive,
      children: 'Exclusive',
      onClick: toggle,
    };
  }, [isExclusive, toggle]);

  return exclusiveMenuItem;
};