import { useSurveyFormQuestionContext } from '@containers/SurveyForm';
import { SurveyFormItem } from '@/components/SurveyForm';
import Navigation from './Navigation';
import styles from './style.css';

type Props = {
  hideNavigation?: boolean;
};

export const CaptureSheetQuestionScreen = (props: Props) => {
  const question = useSurveyFormQuestionContext();

  return (
    <div className={styles.captureSheetRoot}>
      <div className={styles.content}>
        <SurveyFormItem
          key={question.id}
          item={question} />
      </div>
      {!props.hideNavigation &&
        <div className={styles.footer}>
          <Navigation />
        </div>}
    </div>
  );
};

export default CaptureSheetQuestionScreen;