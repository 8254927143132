import { useCallback } from 'react';
import { captureMessage } from '@sentry/react';
import { ConferenceSocketEvent } from '@/enums';
import { useProvidedDevices } from '@containers/DeviceProvider';
import { TWILIO_TRACK_ATTRIBUTE } from '@/components/Conference.Video/constants';
import { useConferenceInstance } from './useConferenceInstance';
import { useSocket } from './useSocket';
import { useTwilioVideo } from './useTwilioVideo';
import { useGetTwilioLogs } from './useGetTwilioLogs';

export const useLogDebugData = () => {
  const video = useTwilioVideo();
  const socket = useSocket();
  const instance = useConferenceInstance();
  const devices = useProvidedDevices();
  const getTwilioLogs = useGetTwilioLogs();

  return useCallback(() => {
    const audioElementMap = {};
    document.querySelectorAll<HTMLAudioElement>(`audio[${TWILIO_TRACK_ATTRIBUTE}]`).forEach(e => {
      audioElementMap[e.attributes.getNamedItem(TWILIO_TRACK_ATTRIBUTE).value] = {
        playing: !e.paused,
      };
    });

    const twilioLogs = getTwilioLogs();

    socket.raw.emit(ConferenceSocketEvent.LogDebug, {
      data: {
        room: video?.room,
        localTracks: video?.localTracks,
        audioElementMap,
        instance,
        deviceUsage: {
          devices,
          selectedAudio: video.audioSource ?? null,
          selectedVideo: video.videoSource ?? null,
        },
        twilioLogs,
      },
    });

    //Capture a message so that we have access to their console.log data
    captureMessage('User logged conference debug data');
  }, [video, socket, instance, devices, getTwilioLogs]);
};