import { cx } from '@utils';
import styles from './style/RangeIndicator.css';

export type RangeIndicatorProps = {
  className?: string;
  values: string[];
};

export const RangeIndicator = (props: RangeIndicatorProps) => {
  return (
    <div className={cx(styles.root, props.className)}>
      <div className={styles.ticks}>
        {props.values.map((v, i) => (
          <div
            key={i}
            className={styles.tick}>
            <div
              key={i}
              className={cx(styles.valueWrap, {
                [styles.middle]: i !== 0 && i !== props.values.length - 1,
              })}>
              {i === 0 &&
                <div className={styles.label}>Low</div>}
              {i === props.values.length - 1 &&
                <div className={styles.label}>High</div>}
              <div className={styles.value}>{v}</div>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.border} />
    </div>
  );
};

export default RangeIndicator;