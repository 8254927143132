import { useContext } from 'react';
import { format } from 'date-fns';
import { GroupSidebarTags } from '@/components/GroupSidebarTags';
import { useGroupEntityTags, useGroupEntityTagsUpdate } from '@/containers/GroupTags/hooks';
import { FolderStateContext } from '@/containers/Workspace.Folder/Context';
import * as Tab from '@/presentation/Workspace.Tab.Pane';
import { useHasGroupFeature } from '@/store/hooks';
import { useConditionalRender } from '@/utils';

export const FolderTabDetails = () => {
  const { object } = useContext(FolderStateContext);

  return (
    <Tab.Panes.Container>
      <Tab.Pane.Pane>
        <Tab.Pane.Title>Details</Tab.Pane.Title>
        <Tab.Items.Container>
          <Tab.Item.Container>
            <Tab.Item.TextLabel>
              Name
            </Tab.Item.TextLabel>
            <Tab.Item.Value>
              {object.name}
            </Tab.Item.Value>
          </Tab.Item.Container>
          <Tab.Item.Container>
            <Tab.Item.TextLabel>
              Owner
            </Tab.Item.TextLabel>
            <Tab.Item.Value>
              {object.owner.name}
            </Tab.Item.Value>
          </Tab.Item.Container>
          <Tab.Item.Container>
            <Tab.Item.TextLabel>
              Created On
            </Tab.Item.TextLabel>
            <Tab.Item.Value>
              {format(object.createdOn, 'M/d/yyyy')}
            </Tab.Item.Value>
          </Tab.Item.Container>
        </Tab.Items.Container>
      </Tab.Pane.Pane>
      <TagPane />
    </Tab.Panes.Container>
  );
};

function TagPane() {
  const $Tags = useConditionalRender(useHasGroupFeature('userTagging'));

  const tags = useGroupEntityTags();
  const updateTags = useGroupEntityTagsUpdate();

  return (
    <$Tags>
      <Tab.Pane.Pane>
        <GroupSidebarTags
          tags={tags}
          onSave={updateTags} />
      </Tab.Pane.Pane>
    </$Tags>
  );
}