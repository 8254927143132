import { Currencies } from '@/types';
import { SelectSearchUnderlined } from '$admin/components/Select';

type Props<T extends Currencies.Item> = {
  options:      T[];
  onChange:     (val: T) => unknown;
  placeholder?: string;
  value:        T;
};

export const Currency = <T extends Currencies.Item>(props: Props<T>) => {
  return (
    <SelectSearchUnderlined
      styles={{
        control: {
          letterSpacing: `-0.25px`,
          minHeight: `32px`,
          lineHeight: `32px`,
        },
        input: {
          margin: 0,
          padding: 0,
        },
        singleValue: {
          marginLeft: 0,
        },
      }}
      getOptionLabel={option => option.sign ? `${option.code} (${option.sign})` : option.code}
      getOptionValue={option => option.code}
      onChange={(option: T) => props.onChange(option)}
      options={props.options}
      placeholder={props.placeholder ?? 'Currency'}
      value={props.value} />
  );
};

Currency.displayName = 'Project.Honoraria.Field.Currency';