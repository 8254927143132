import { forwardRef, memo } from 'react';
import { Filter as FilterIcon } from 'react-feather';
import { cx } from '@utils';
import styles from './style/Project.Clips.css';

type Props = {
  className?: string;
  iconClassName?: string;
  disabled?: boolean;
  isActive: boolean;
};

export const Filter = memo(forwardRef<HTMLDivElement, Props>(({
  className,
  iconClassName,
  disabled,
  isActive,
}, ref) => {
  const filterClassName = cx(styles.filterIcon, {
    [styles.active]: isActive,
    [styles.disabled]: disabled,
  }, iconClassName);

  return (
    <div ref={ref} className={className}>
      <FilterIcon
        size={20}
        className={filterClassName} />
    </div>
  );
}));