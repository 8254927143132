import qs from 'query-string';
import http from '@services/http';
import * as cancellation from '@services/http/cancellation';
import * as members from '@transformers/members';
import { Members } from './interfaces';

export const search = ({ cancelToken, ...data }: Members.Search.Request) => {
  const query = qs.stringify(data);

  return http.get<Members.Search.ResponseUnparsed>(`/members/search?${query}`, {
    cancelToken,
  })
  .then<Members.Search.Response>(members.transformProfileProps)
  .catch<null>(e => cancellation.ignoreRequestCancellationError(e, null));
};
