import { ProjectInviteReferralLinkContext } from './Context';
import { useFetchProjectInviteLink } from './hooks/useFetchProjectInviteLink';

type Props = {
  children: React.ReactNode;
} & IProjectId;

export const Container = (props: Props) => {

  const query = useFetchProjectInviteLink(props.projectId);

  return (
    <ProjectInviteReferralLinkContext.Provider value={query}>
      {props.children}
    </ProjectInviteReferralLinkContext.Provider>
  );
};

Container.displayName = 'ProjectInviteReferral.Link.Container';