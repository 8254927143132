import { Link } from 'react-router-dom';
import CalendarIcon from '@mui/icons-material/PermContactCalendar';
import styles from './style.css';

type Props =
  IUserId;

const CalendarIconLink = (props: Props) => {
  return (
    <Link
      className={styles.calendar}
      to={`/admin/users/${props.userId}/scheduling`}>
      <CalendarIcon />
    </Link>
  );
};

export { CalendarIconLink };
export default CalendarIconLink;