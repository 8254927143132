const year = new Date().getFullYear();

!process.env.__DEV__ && console.log(`%c
:^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^. 
:JYYYYYYYYYYJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJ?.
:JJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJ??????????:
:JJJJJJJJJJ7777777777777777777777777777??????????:
:JJJJJJJJJ7                            !J????????:
:JJJJJJJJJJ???7777777777777777777777777??????????:
:JJJJJJJJJJ??????????????????????????????????????:
:JJJJJJJJJ7                            !?????????:
:JJJJJJJJJ?!!!!!!!!!!!!!!!!!!!!!!!!!!!!7?????????:
:JJJJJJJJJJJJJJJJJJJJJJJJJJ??????????????????????:
:JJJJJJJJJ7............................!?????????:
:JJJJJJJJJ7~~~~~~~~~~~~~~~~~~~~~~~^^^^^7?????????:
:JJJJJJJJJJJJJJJJJJJJJJJJ???????????????????????7:
:JJJJJJJJJJJJJJJJJ???????????????????????????????:
 ^!!!!!!!!!!!!!!!!7??????????????????7~~!!~~~~~~^ 
                   .:^~!!7??????7777?7^           
                         .:^~!77???????7^         
                               .:^~!77???!:       
                                     .:^~!7~.     
                                           ..     
                                            S E N T I M E N T

initializing....                                       © ${year}`,
`color: #28AAE1; font-size: 10pt; font-family: monospace; white-space: pre;`);