import http from '@services/http';
import { qs } from '@utils';
import { Groups } from './interfaces';

export const fetchInstallURL = (data: Groups.Apps.FetchSalesforceInstallURL.Request) => {
  const { state } = data;

  const q = state
      ? `?${qs.stringify({ state })}`
      : '';

  return http.get<{ url: string; }>(`/groups/${data.groupId}/apps/salesforce/install${q}`);
};

export const connect = (data: Groups.Apps.ConnectSalesforce.Request) => {
  return http.post(`/groups/${data.groupId}/apps/salesforce/connect`, data);
};