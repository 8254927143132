import { GroupProjectContainer } from '@containers/GroupProject';
import { ProjectTabs } from '@screens/GroupProject/ProjectTabs';

type Props = unknown;

export const GroupProject = (props: Props) => {
  return (
    <GroupProjectContainer>
      <ProjectTabs />
    </GroupProjectContainer>
  );
};

GroupProject.displayName = 'Group.Project';