import { useCallback, useMemo } from 'react';
import { Redirect, Route, RouteProps, Switch, useHistory, useLocation } from 'react-router-dom';
import * as consts from '@consts';

type Routes = {
  path: string;
} & Pick<RouteProps, 'component' | 'exact'>

type StepperRoutesProps = {
  routes: Routes[];
};

const StepperRoutes = ({ routes }: StepperRoutesProps) => {
  return (
    <Switch>
      {routes.map(x =>
        <Route
          key={x.path}
          component={x.component}
          exact={x.exact}
          path={x.path} />)}
      <Redirect to={routes[0].path} />
    </Switch>
  );
};

export const useRouteStepper = (routes: Routes[]) => {
  const location = useLocation();
  const history = useHistory();

  const step = useMemo(() => {
    return routes.findIndex(f => f.path === location.pathname) || 0;
  }, [
    routes,
    location.pathname,
  ]);

  const previousStep = useCallback(() => {

    if (step === 0) {
      history.push(consts.pathname.HOME);
    } else {
      history.replace(routes[step - 1].path);
    }
  }, [
    history,
    routes,
    step,
  ]);

  const nextStep = useCallback(() => {

    if (step === routes.length - 1) {
      history.push(consts.pathname.HOME);
    } else {
      history.push(routes[step + 1].path);
    }
  }, [
    history,
    routes,
    step,
  ]);

  const goToStep = useCallback((target: number) => {
    const path = routes[target].path;
    history.push(path);
  }, [
    history,
    routes,
  ]);

  const actions = useMemo(() => ({
    back: previousStep,
    goTo: goToStep,
    next: nextStep,
  }), [
    goToStep,
    nextStep,
    previousStep,
  ]);

  const Routes: React.FC = useCallback(() => {
    return (
      <StepperRoutes routes={routes} />
    );
  }, [routes]);

  return [Routes, actions, step] as const;
};