import { useSelector } from 'react-redux';
import { ProjectType } from '@enums';

const selectIsProjectCallLike = (projectId: number) => (state: Store.State) => {
  const projectType = state.projects[projectId]?.projectType;

  return [
    ProjectType.Call,
    ProjectType.SurveyCall,
  ].includes(projectType);
};

const useIsProjectTypeCallLike = (projectId: number) => {
  return useSelector(selectIsProjectCallLike(projectId));

};

export { useIsProjectTypeCallLike };
export default useIsProjectTypeCallLike;