import { useCallback, useMemo, useState } from 'react';
import { SurveyQuestionType } from '@/enums';
import { MaxDifferenceQuestion } from '@/types';
import { MaxDiffFormContext, MaxDiffNavigationContext, useSurveyFormQuestionAnswer } from './Context';
import NavigationContainer from './MaxDiff.Navigation';

type SetAnswer = MaxDifferenceQuestion.RespondentAnswer.SetAnswer;

export default function MaxDiffFormContainer({ children }: ChildrenProps) {
  const [answer, setAnswer] = useSurveyFormQuestionAnswer<SurveyQuestionType.MaxDifference>();
  const [currentSet, setCurrentSet] = useState<number>(1);

  const setSetAnswer = useCallback((setNumber: number) => (choice: 'left' | 'right', value: number) => {

    const sets = answer.sets.reduce<SetAnswer[]>((answers, set, i) => {
      if (i + 1 !== setNumber) {
        return answers.concat(set);
      }

      switch (choice) {
        case 'left': {
          const left = value;
          const right = set.right !== value ? set.right : null;
          const options = set.options;

          return answers.concat({ left, right, options });
        }
        case 'right': {
          const left = set.left !== value ? set.left : null;
          const right = value;
          const options = set.options;

          return answers.concat({ left, right, options });
        }
      }
    }, []);

    setAnswer({ sets, version: answer.version });
  }, [answer, setAnswer]);

  const back = useCallback(() => {
    return setCurrentSet(s => s - 1);
  }, []);

  const next = useCallback(() => {
    return setCurrentSet(s => s + 1);
  }, []);

  const nextDisabled = useMemo(() => {
    return !answer.sets[currentSet - 1].left || !answer.sets[currentSet - 1].right;
  }, [answer.sets, currentSet]);

  const navigation = {
    back,
    next,
    nextDisabled,
  };

  const form = {
    currentSet,
    setSetAnswer,
  };

  return (
    <MaxDiffFormContext.Provider value={form}>
      <MaxDiffNavigationContext.Provider value={navigation}>
        <NavigationContainer>
          {children}
        </NavigationContainer>
      </MaxDiffNavigationContext.Provider>
    </MaxDiffFormContext.Provider>
  );
}

export { MaxDiffFormContainer };