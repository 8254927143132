import { useContext, useEffect } from 'react';
import { useRemirrorContext } from '@remirror/react';
import { UpdateTranscriptTimestampMapsContext } from '@containers/Transcript/context';

export const useInitializeTimestampPositions = () => {

  const { view } = useRemirrorContext();
  const updatePositions = useContext(UpdateTranscriptTimestampMapsContext);

  useEffect(() => {
    updatePositions(view);
  }, [updatePositions, view]);
};