import { useContext } from 'react';
import * as $conference from '$admin/containers/Call/utils';
import { ConfirmContext } from './Context';
import * as Details from './Confirmation.Details';
import styles from './style/Confirmation.css';

type Props = unknown;

export const OffPlatform = (props: Props) => {
  const ctx = {
    confirm: useContext(ConfirmContext),
  };

  const settings = ctx.confirm.confirmation.conference.settings;

  return (
    <>
      <div className={styles.row}>
        <div className={styles.label}>Time</div>
        <Details.Time
          end={ctx.confirm.confirmation.call?.timeEnd}
          start={ctx.confirm.confirmation.call?.timeStart} />
      </div>
      {$conference.showConferenceDialIn(settings) &&
        <div className={styles.row}>
          <div className={styles.label}>Dial In:</div>
          {settings.conferenceDialIn}
        </div>
      }
      {$conference.showConferenceLink(settings) &&
        <div className={styles.row}>
          <div className={styles.label}>Conference Link:</div>
          {settings.conferenceLink}
        </div>
      }
      {settings.clientNotes &&
        <div className={styles.row}>
          <div className={styles.label}>Client Notes:</div>
          <Details.AdditionalDetails value={settings.clientNotes} />
        </div>}
      {$conference.showConferenceDetails(settings) && settings.conferenceDetails &&
        <div className={styles.row}>
          <div className={styles.label}>Additional Details:</div>
          <Details.AdditionalDetails value={settings.conferenceDetails} />
        </div>
      }
      <div className={styles.row}>
        <div className={styles.label}>Attendees</div>
        <Details.Participants items={ctx.confirm.confirmation.participants} />
      </div>
    </>
  );
};

OffPlatform.displayName = 'Confirmation.OffPlatform';