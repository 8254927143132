import { useReducer } from 'react';
import { FileUploadReviewStateContext, FileUploadReviewDispatchContext } from './Context';
import type { FileUploadReview } from './interfaces.review';

type Props =
  ChildrenProps;

const initialState: FileUploadReview.State = {
  existing: [],
  files: {},
  isTranscriptImport: false,
  replaceExisting: false,
  showModal: false,
  tags: [],
  transcribeable: [],
  uploadInitiated: false,
  parentObjectId: null,
  workspaceId: null,
};

export const FileUploadReviewState = (props: Props) => {

  const [state, dispatch] = useReducer(fileUploadReviewReducer, initialState);

  return (
    <FileUploadReviewDispatchContext.Provider value={dispatch}>
      <FileUploadReviewStateContext.Provider value={state}>
        {props.children}
      </FileUploadReviewStateContext.Provider>
    </FileUploadReviewDispatchContext.Provider>
  );

};

function fileUploadReviewReducer(oldState: FileUploadReview.State, action: FileUploadReview.Action): FileUploadReview.State {

  const newState = baseReducer(oldState, action);

  logAction(oldState, newState, action);

  return newState;
}

function logAction(oldState: FileUploadReview.State, newState: FileUploadReview.State, action: FileUploadReview.Action) {
  if (process.env.VANCERY_ENV !== 'production') {
    console.groupCollapsed('File Review Action', action);
    console.log('Old State', oldState);
    console.log('New State', newState);
    console.groupEnd();
  }
}

function baseReducer(state: FileUploadReview.State, action: FileUploadReview.Action): FileUploadReview.State {

  switch (action.type) {

    case 'initialized': {
      return action.payload;
    }

    case 'upload-initiated': {
      return {
        ...state,
        uploadInitiated: true,
      };
    }

    case 'upload-queued': {
      return {
        ...state,
        uploadInitiated: false,
        showModal: false,
      };
    }

    case 'modal-dismissed': {
      return initialState;
    }

    case 'existing-action-confirmed':
      return {
        ...state,
        replaceExisting: action.payload.replaceExisting,
      };

    case 'file-transcribe-toggled':
      return {
        ...state,
        transcribeable: state.transcribeable.includes(action.payload.identifier)
          ? state.transcribeable.filter(i => i !== action.payload.identifier)
          : [...state.transcribeable, action.payload.identifier],
      };

    case 'transcribeable-updated':
      return {
        ...state,
        transcribeable: action.payload.value,
      };

    case 'tags-updated':
      return {
        ...state,
        tags: action.payload.tags,
      };

    default:
      return state;
  }
}