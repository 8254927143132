import { useCallback, useContext, useMemo, useEffect } from 'react';
import * as $ws from '@/services/websocket';
import { FileSocketEvent } from '@/enums';
import { ChatFileUploadStatus } from '@/brand-insights/enums';
import type { Chat } from '@/brand-insights/types';
import { useFileUploadProgress } from '@/containers/WorkspaceFileUpload/hooks';
import { ChatStateDispatchContext } from '@/brand-insights/components/Chat.State/context';
import { FileUploadItem } from './FileUpload.File';

type Props = {
  className?: string;
  uploadIdentifier: string;
  value: Chat.FileUploadItem;
};

export const FileUploadFileContainer = ({ className, uploadIdentifier, value }: Props) => {

  const pctComplete = useFileUploadProgress(value.identifier);

  useFileReadySocketEvent(value, uploadIdentifier);

  return (
    <FileUploadItem
      className={className}
      fileId={value.fileId}
      name={value.name}
      status={value.status}
      pctComplete={pctComplete} />
  );
};

const useFileReadySocketEvent = (value: Chat.FileUploadItem, uploadIdentifier: string) => {
  const dispatch = useContext(ChatStateDispatchContext);

  const socket = useMemo(() => {
    return $ws.files.getSocket();
  }, []);

  const handleFileAvailable = useCallback((event: { fileIdentifier: string }) => {
    dispatch({
      type: 'file-upload-chat-ready',
      payload: {
        identifier: uploadIdentifier,
        fileIdentifier: event.fileIdentifier,
      },
    });
  }, [dispatch, uploadIdentifier]);

  const enabled = useMemo(() => {
    return value.status === ChatFileUploadStatus.Uploaded &&
      !!value.fileId;
  }, [value.fileId, value.status]);

  useEffect(() => {
    if (enabled) {
      socket.on(FileSocketEvent.ChatAvailable, handleFileAvailable);
      return () => {
        socket.off(FileSocketEvent.ChatAvailable, handleFileAvailable);
      };
    }
  }, [enabled, handleFileAvailable, socket]);

  useEffect(() => {
    if (enabled && socket) {
      socket.subscribe({ fileId: value.fileId });
      socket.open();

      return () => {
        socket.unsubscribe({ fileId: value.fileId });
        socket.close();
      };
    }
  }, [enabled, socket, value.fileId]);
};