import { memo } from 'react';
import { XCircle } from 'react-feather';
import { cx } from '@utils';
import { PlusCircle } from '@/components/icons/PlusCircle';
import styles from './style/Filter.Button.css';

type AddProps = {
  disabled?: boolean;
  onClick: () => unknown;
};

export const FiltersAddButton = memo(({ onClick, disabled }: AddProps) => {
  return (
    <div
      className={styles.root}
      onClick={!disabled ? onClick : undefined}>
      <PlusCircle
        className={cx(styles.add, { [styles.disabled]: disabled })}
        size={28} />
    </div>
  );
});

type RemoveProps = {
  disabled?: boolean;
  onClick: () => unknown;
};

export const FiltersRemoveButton = memo(({ onClick, disabled }: RemoveProps) => {
  return (
    <div
      className={styles.root}
      onClick={!disabled ? onClick : undefined}>
      <XCircle
        className={cx(styles.remove, { [styles.disabled]: disabled })}
        size={28} />
    </div>
  );
});