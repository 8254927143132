import { useCallback } from 'react';
import { useEditorState } from '@remirror/react';
import { useRichTextEditorRegistryEditor, useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { isSurveyRichTextEmpty } from '@/containers/SurveyBuilder/utils/rich-text.validate';
import { useUploadSurveyImages } from '@/components/Survey.RichText/hooks/useUploadImages';
import type { SurveyRichText } from '@/types';

export const useCanSaveStimulus = () => {

  const editor = useEditorState();

  return useCallback(() => {
    const value = editor.doc.toJSON() as SurveyRichText.RichTextValue;
    const isEmpty = isSurveyRichTextEmpty(value);
    return !isEmpty;
  }, [editor.doc]);
};

export const useSubmitStimulus = () => {

  const [_, dispatch] = useSurveyBuilderState();
  const { getEditorByIdentifier } = useRichTextEditorRegistryEditor();

  const getEditor = useCallback(() => getEditorByIdentifier(`tpp-stimulus-input`), [getEditorByIdentifier]);

  const uploadImages = useUploadSurveyImages();

  const handleSubmit = useCallback(async () => {
    const { updated, value } = await uploadImages({
      getContext: getEditor().getContext,
    });

    if (updated) {
      dispatch({
        type: 'template-stimulus-updated',
        value,
      });
    }
  }, [
    dispatch,
    uploadImages,
    getEditor,
  ]);

  return handleSubmit;
};