import extend from '@enums/extend';

export enum TokenType {
  ProjectOnboard = 1,
  Referral       = 2,
  Attribution    = 3,
}

const map = new Map([
  [TokenType.ProjectOnboard, { name: 'project-onboard' }],
  [TokenType.Referral, { name: 'referral' }],
  [TokenType.Attribution, { name: 'attribution' }],
]);

export default {
  TokenType: extend(map),
};