import { useCallback } from 'react';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import { NumberInputTableQuestion } from '@/types/survey';
import { useNumberTableColumnTotals } from '@/containers/SurveyForm';
import { NumberInputTable } from './interfaces';
import { NumberInputTableListRowInput } from './NumberInputTable.List.Row';
import styles from './style/NumberInputTable.List.css';

export const NumberInputTableList = ({ handleChange, item }: NumberInputTable.Props) => {

  const totalsMap = useNumberTableColumnTotals();

  const renderItem = useCallback((option: NumberInputTableQuestion.FormOption) => {

    const totalNumber = totalsMap[option.id];
    const unitSuffix = item.settings.numberSettings?.unitType;
    const hasUnitSuffix = unitSuffix || unitSuffix === '0';
    const totalDisplay = `${totalNumber}${hasUnitSuffix ? unitSuffix : ``}`;

    return (
      <div
        key={option.ordinal}
        className={styles.item}>
        <div className={styles.header}>{parseSurveyRichText(option.value)}</div>
        <div>
          {item.matrixRows.map((row: NumberInputTableQuestion.FormRow) => (
            <NumberInputTableListRowInput
              key={row.id}
              row={row}
              option={option}
              settings={item.settings}
              onChange={handleChange} />
          ))}
        </div>
        {item.settings.displayTotals &&
          <div className={styles.footer}>
            <div className={styles.name}>Total</div>
            <div className={styles.cell}>{totalDisplay}</div>
          </div>
        }
      </div>
    );
  }, [
    handleChange,
    item.matrixRows,
    item.settings,
    totalsMap,
  ]);

  return (
    <div className={styles.root}>
      {item.options.map(renderItem)}
    </div>
  );
};

export default NumberInputTableList;