import { isSupported, Pipeline, GaussianBlurBackgroundProcessor } from '@twilio/video-processors';

export function isBlurBackgroundSupported() {
  return isSupported;
}

export function createGaussianBlurBackgroundProcessor() {
  return new GaussianBlurBackgroundProcessor({
    assetsPath: `https://cdn.app.sentimentglobal.com/js/@twilio/video-processors/assets@1.0.0`,
    pipeline: Pipeline.WebGL2,
    debounce: true,
    blurFilterRadius: 30,
    maskBlurRadius: 10,
  });
}