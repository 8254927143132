import { Accordion } from '@/components/Accordion';
import ProjectSurveyResponse from './Survey';
import {
  ProjectSurveyProps,
} from './interfaces';
import styles from './style.css';

export const ProjectSurveyResponseSection = (props: ProjectSurveyProps) => {
  return (
    <Accordion
      className={styles.accordion}
      grows={false}
      label='Survey Responses'
      showCount={false}>
      <ProjectSurveyResponse
        renderQuestionButton={props.renderQuestionButton}
        survey={props.survey} />
    </Accordion>
  );
};

export default ProjectSurveyResponseSection;