import { QuotaValidationsContext, RunQuotaValidationsContext } from './Context';
import { useAdvancedQuotaValidations } from './hooks';

type Props = ChildrenProps;

export const AdvancedQuotaValidationsContainer = (props: Props) => {

  const {
    validations,
    runValidations,
  } = useAdvancedQuotaValidations();

  return (
    <QuotaValidationsContext.Provider value={validations}>
      <RunQuotaValidationsContext.Provider value={runValidations}>
        {props.children}
      </RunQuotaValidationsContext.Provider>
    </QuotaValidationsContext.Provider>
  );
};