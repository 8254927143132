import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as api from '@api';
import { ContentFeed } from '@/types';

type Data = {
  items: ContentFeed.CarouselItem[];
};

export default function useProfileFeaturedPosts<TData = Data>(
  userId: number,
  options?: UseQueryOptions<Data, AxiosError, TData>
) {

  return useQuery(['profile-featured-posts', userId], async () => {
    return api.profiles.activity.fetchFeaturedItems({
      userId,
    });
  }, options);
}

export { useProfileFeaturedPosts };