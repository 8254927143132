import { useMemo } from 'react';
import { AddItemButton } from '@presentation/AddItemButton';
import { SurveyTemplate } from '@/types/survey.templates';
import { TextEntryList } from '@/components/TextEntryList';
import styles from './style/Presets.css';

type Props = {
  canAddDoseAdministration: boolean;
  canRemoveDoseAdministration: boolean;
  items: SurveyTemplate.LinkedEntity[];
  onAddDoseAdministration: () => void;
  onRemoveDoseAdministration: (id: string) => () => void;
  onUpdateDoseAdministration: (id: string) => (v: string) => void;
};

export const TemplateDoseAdministrationsInput = ({
  canAddDoseAdministration,
  canRemoveDoseAdministration,
  onAddDoseAdministration,
  onRemoveDoseAdministration,
  onUpdateDoseAdministration,
  items: rawItems,
}: Props) => {

  const items = useMemo(() => {
    return rawItems.map(m => ({
      key: m.id,
      value: m.value,
    }));
  }, [rawItems]);

  return (
    <>
      <TextEntryList
        items={items}
        placeholder="Enter an administration"
        removeItem={onRemoveDoseAdministration}
        removeItemEnabled={canRemoveDoseAdministration}
        updateItemValue={onUpdateDoseAdministration} />
      {canAddDoseAdministration &&
        <AddItemButton
          className={styles.add}
          label="Add"
          onClick={onAddDoseAdministration} />}
    </>
  );
};
