import * as xform from '@transformers/helpers';
import type { BaseVICS, Contact } from '@/types';

export const addSlugToProfileProp = (item: Contact) => {
  Object.assign(item.profile, { slug: generateSlug(item) });

  return item;
};

function generateSlug(item: Contact) {
  return [
    item.profile.firstName,
    item.profile.lastName,
    item.id,
  ]
  .map(x => String(x).toLowerCase().replace(/\W/g, ''))
  .join('-');
}

export const normalizeContacts = (contacts: Contact[]): Store.Contacts => {
  return xform.toMap<number, Contact>(contacts);
};

export default {
  addSlugToProfileProp,
};