import { useRouteMatch } from 'react-router-dom';
import { ProjectTabs } from '@consts/path.main';
import { ProjectCallsContainer } from '@containers/Group.Project.Calls';
import { ProjectEntitiesContainer } from '@containers/Group.Project.Entities';
import { ProjectPipelineContainer } from '@containers/Group.Project.Pipeline';
import { ProjectTagsContainer } from '@containers/Group.Project.Tags';
import { ProjectStateContainer } from './Project.State.Container';
import { ProjectToolsContainer } from './Project.Tools.Container';
import { SurveyResponseFilterContainer } from './Survey.ResponseFilter.Container';
import { DataContainer } from './Survey.ResponsesState.Container';
import { usePreventProjectScrollRestoration, useProjectState } from './hooks';

type Props = {
  children: React.ReactNode;
};

const GroupProjectContainer = (props: Props) => {
  const state = useProjectState();

  const calls = useRouteMatch({
    exact: true,
    path: ProjectTabs.Calls,
  });
  const entities = useRouteMatch({
    exact: true,
    path: ProjectTabs.Entities,
  });
  const pipeline = useRouteMatch({
    exact: true,
    path: ProjectTabs.Pipeline,
  });
  const files = useRouteMatch({
    path: ProjectTabs.Files,
  });
  const tags = useRouteMatch({
    exact: true,
    path: ProjectTabs.Tags,
  });

  usePreventProjectScrollRestoration();

  const projectId = state.project?.id;
  const isReady = !!projectId;

  return (
    <DataContainer>
      <SurveyResponseFilterContainer>
        <ProjectPipelineContainer
          enabled={!!pipeline && isReady}
          projectId={projectId}>
          <ProjectCallsContainer
            enabled={!!calls && isReady}
            projectId={projectId}>
            <ProjectEntitiesContainer
              enabled={!!entities && isReady}
              projectId={projectId}>
              <ProjectTagsContainer
                enabled={!!tags && isReady}
                projectId={projectId}>
                <ProjectToolsContainer>
                  {props.children}
                </ProjectToolsContainer>
              </ProjectTagsContainer>
            </ProjectEntitiesContainer>
          </ProjectCallsContainer>
        </ProjectPipelineContainer>
      </SurveyResponseFilterContainer>
    </DataContainer>
  );
};

const WithState = (props: Props) => (
  <ProjectStateContainer>
    <GroupProjectContainer {...props} />
  </ProjectStateContainer>
);

export { WithState as GroupProjectContainer };

WithState.displayName = 'Group.Project.Container';