import { Fragment, memo, useMemo } from 'react';
import * as consts from '@consts';
import type { RankingQuestion } from '@/types';
import type { RankingAnswerValueProps, RankingAnswerOptionProps } from './interfaces';
import styles from './style/Ranking.css';
import { Rationale } from './Rationale';

const sortOptions = (a: RankingQuestion.Answer.Item, b: RankingQuestion.Answer.Item) => {
  return a.ordinal === consts.surveys.NotApplicableRankingOrdinal
    ? 1
    : b.ordinal === consts.surveys.NotApplicableRankingOrdinal
      ? -1
      : b.ordinal > a.ordinal
        ? -1
        : 1
    ;
};

const RankingAnswerOption = memo((props: RankingAnswerOptionProps) => {
  const ordinal = props.item.ordinal === consts.surveys.NotApplicableRankingOrdinal
    ? '-'
    : `${props.item.ordinal})`;

  const displayValue = [
    props.item.option.value,
    props.item.option.openEndedValue && ` (${props.item.option.openEndedValue})`,
  ].filter(Boolean).join(' ');

  return (
    <div className={styles.item}>
      <div className={styles.ordinal}>{ordinal}</div>
      {displayValue}
    </div>
  );
});

export const RankingAnswerValue = ({ answer, rationale }: RankingAnswerValueProps) => {
  const sortedItems = useMemo(() => {
    return answer.items
      .filter(f => !!f.ordinal || f.ordinal === consts.surveys.NotApplicableRankingOrdinal)
      .sort(sortOptions);
  }, [answer.items]);

  if (answer.dontKnow) {
    return (
      <>{`"I don't know" selected.`}</>
    );
  }

  return (
    <>
      {sortedItems.map(item => (
        <Fragment key={item.option.id}>
          <RankingAnswerOption item={item} />
        </Fragment>
      ))}
      <Rationale value={rationale} />
    </>
  );
};

export default RankingAnswerValue;