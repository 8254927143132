import { useCallback, useState } from 'react';
import { Trash2 as Trash } from 'react-feather';
import { GrayOutlineButton } from '@presentation/Buttons';
import type { SurveyRichText } from '@/types';
import { Button } from '@/components/Button';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import ModalHeader from '@/components/Modal/Header';
import { useModal } from '@/components/Modal/hooks';
import { Editor } from './Node.FindAndReplace.Editor';
import type { FARInsertItem } from './interfaces';
import styles from './style/Modal.css';

type InsertProps = {
  items: FARInsertItem[];
  onSubmit: (items: FARInsertItem[]) => void;
};

type Props =
  & InsertProps
  & Pick<ModalProps, 'open' | 'onClose'>;

export const FindAndReplaceInsertModal = ({
  onSubmit,
  onClose,
  open,
  items,
}: Props) => {
  const [state, setState] = useState<FARInsertItem[]>(items);

  const handleSubmit = useCallback(() => {
    onSubmit(state);
  }, [onSubmit, state]);

  const handleRichTextChange = useCallback((index: number) => (value: SurveyRichText.RootNode) => {
    setState(prev => [
      ...prev.slice(0, index),
      { ...prev[index], value },
      ...prev.slice(index + 1),
    ]);
  }, []);

  const handleRemove = useCallback((index: number) => () => {
    setState(prev => [...prev.slice(0, index), ...prev.slice(index + 1)]);
  }, []);

  const title = items.length === 1 ? `Insert entry` : `Insert entries`;

  return (
    <Modal
      open={open}
      onClose={onClose}>

      <ModalHeader text={title} />

      <div className={styles.body}>
        <div className={styles.label}>{items.length === 1 ? `Entry` : `Entries`}</div>
        {state.map((item, i) => (
          <div key={i} style={{ marginBottom: '15px' }}>
            <Editor
              initialState={item.value}
              onChange={handleRichTextChange(i)}
              item={item} />
            {item.canRemove
              ? (
                <div style={{ display: 'flex', width: '40px' }}>
                  <Trash
                    className={styles.removeIcon}
                    size={22}
                    onClick={handleRemove(i)} />
                </div>
              )
              : <div style={{ display: 'flex', width: '40px' }} />}
          </div>
        ))}
      </div>

      <div className={styles.footer}>
        <div className={styles.buttons}>
          <GrayOutlineButton
            onClick={onClose}
            title="Cancel" />
          <Button.Primary
            className={styles.submit}
            disabled={false}
            onClick={handleSubmit}
            title="Save"
            variant="brick" />
        </div>
      </div>
    </Modal>
  );
};

export const useFindAndReplaceInsertModal = () => useModal(FindAndReplaceInsertModal);

export default FindAndReplaceInsertModal;