import { NumberInput } from '@/components/Input';
import styles from './style/Frame.Setup.css';

type Props = {
  onChange: (value: string) => unknown;
  value:    string;
};

export const PurchaseOrder = (props: Props) => {

  return (
    <NumberInput
      allowLeadingZeros
      classes={{ input: styles.po }}
      decimalScale={0}
      maxLength={5}
      minLength={5}
      onValueChange={item => props.onChange(item.value)}
      value={props.value} />
  );
};

PurchaseOrder.displayName = 'Field.Setup.PurchaseOrder';