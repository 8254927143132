import { createContext, useContext } from 'react';
import type { UseQueryResult } from '@tanstack/react-query';
import type * as API from '@api/interfaces';
import type * as enums from '@enums';
import type { StepperActions } from '@utils';
import { createNamedContext } from '@utils';
import type {
  IProjectSurveyResponseId,
  LegacyGroupProjectState,
  PipelineProjectAttributes,
  Project,
  ProjectGoal,
  ProjectParent,
  ProjectSurveyResponse,
  SurveyBuilderVersion,
  SurveyFiltering,
  SurveyQuestion,
  SurveyAggregate,
  Workspace,
  WorkspaceObject,
  WorkspaceObjectCapabilities,
  SurveyVersion,
  ConferenceSettings,
  QuantAnalysis,
} from '@/types';
import type { ProjectOverviewFormState, TabRouteItem } from './interfaces';

export type ProjectState = {
  features: {
    insightsChat: boolean;
  };
  pipeline: PipelineProjectAttributes;
  latestProject: {
    creator: Creator;
    entityCount: number;
    conferenceSettings: ConferenceSettings;
  } & Project<LegacyGroupProjectState>;
  project: {
    creator: Creator;
    entityCount: number;
    conferenceSettings: ConferenceSettings;
  } & Project<LegacyGroupProjectState> | null;
  projectParent: ProjectParent;
  versions: Project<LegacyGroupProjectState>[];
  capabilities: WorkspaceObjectCapabilities<enums.WorkspaceObjectType.ProjectParent>;
  object: WorkspaceObject;
  workspace: Workspace;
};

type Creator = {
  firstName: string;
  id: number;
  lastName: string;
  name: string;
};

type GroupProjectGoal = {
  goal: ProjectGoal;
};

export type ProjectCloningStepperContextValue =
  StepperActions;

export type BaseGroupProjectCloningForm =
  Pick<Project,
    'id'
    | 'name'
    | 'description'> &
  {
    frequency: enums.ProjectFrequency;
    goal: number;
    targetCompleteDate: Date;
  };

export type GroupProjectCloningForm =
  BaseGroupProjectCloningForm &
  {
    survey: Pick<SurveyBuilderVersion,
      'alternateImageExercises' |
      'classifications' |
      'items' |
      'logic' |
      'messages' |
      'questions' |
      'quotas' |
      'sections' |
      'tagging' |
      'template'>;
  };

export type GroupProjectCloningFormContextValue = {
  form: GroupProjectCloningForm;
  setFormState: (data: Partial<BaseGroupProjectCloningForm>) => void;
};

export type CloneGroupProjectContextValue = () => Promise<void>;

export type GroupProjectCloningContextValue = {
  actions: StepperActions;
  project: Project<LegacyGroupProjectState>;
  projectParent: ProjectParent;
  step: number;
  Routes: React.ComponentType;
};

export type SaveProjectOverview = (data: ProjectOverviewFormState) => Promise<Omit<API.Projects.UpdateProjectOverview.Response, 'object'>>;

export type ProjectSurveyRespondentContextValue = {
  projectSurveyResponseId: number;
  refetch: () => void;
  response: ProjectSurveyResponse;
  respondent: SurveyAggregate.RespondentUser;
  versions: Array<IProjectSurveyResponseId & IProjectId>;
  statusId: enums.ProjectSurveyResponseStatus;
} & IProjectId
  & IProjectSurveyResponseId;

export type ProjectActionsContextValue = Record<enums.ProjectAction, () => void> & IProjectId;

export type ProjectActionsValidityContextValue = Record<enums.ProjectAction, boolean>;

export type SurveyQuestionFilterContextValue = {
  filter: SurveyFiltering.FilterEntries;
  resetFilter: () => void;
  setFilter: (filter: SurveyFiltering.FilterEntries) => void;
  //questionData: SurveyQuestion[];
};

export type SurveyResponsesFilterDataContextValue = {
  surveyData: SurveyVersion[];
  allResponses: Record<number, SurveyAggregate.QuestionMap>;
  allQuestions: Record<number, SurveyQuestion[]>;
  isLoading: boolean;
};

type ProjectDetailsContextValue = {
  query: UseQueryResult<Omit<API.Projects.FetchProject.Response, 'breadcrumbs'>>;
};

export const ProjectTabsContext = createNamedContext<TabRouteItem[]>(undefined, 'ProjectTabsContext');

export const SetDisplayDashboardIdContext = createNamedContext<(dashboardId: string) => void>(undefined, 'SetDisplayDashboardIdContext');
export const ProjectDisplayrDashboardIdContext = createNamedContext<string>(undefined, 'ProjectDisplayrDashboardIdContext');

export const ProjectStateLoadingContext = createNamedContext<boolean>(undefined, 'ProjectStateLoadingContext');
export const ProjectResponseFilterContext = createNamedContext<SurveyQuestionFilterContextValue>(undefined, 'ProjectResponseFilterContext');
export const SurveyResponsesFilterDataContext = createNamedContext<SurveyResponsesFilterDataContextValue>(undefined, 'SurveyResponsesFilterDataContext');
export const ProjectActionsContext = createNamedContext<ProjectActionsContextValue>(undefined, 'Project.Actions.Context');
export const ProjectActionsValidityContext = createContext<ProjectActionsValidityContextValue>(undefined);

export const ProjectDetailsContext = createNamedContext<ProjectDetailsContextValue>(undefined, 'Project.Details.Context');
export const GroupProjectGoalContext = createContext<GroupProjectGoal | undefined>(undefined);
export const ProjectStateContext = createContext<ProjectState | undefined>(undefined);
export const SaveProjectOverviewContext = createContext<SaveProjectOverview | undefined>(undefined);

export const GroupProjectCloningFormContext = createContext<GroupProjectCloningFormContextValue | undefined>(undefined);
export const CloneGroupProjectContext = createContext<CloneGroupProjectContextValue | undefined>(undefined);
export const GroupProjectCloningContext = createContext<GroupProjectCloningContextValue | undefined>(undefined);

export const ProjectSurveyRespondentContext = createContext<ProjectSurveyRespondentContextValue | undefined>(undefined);
/* export const ProjectPipelineItemContext = createContext<PipelineItemContextValue | undefined>(undefined); */

export const ProjectQuantAnalysesContext = createContext<QuantAnalysis[]>(undefined);

export const useProjectSurveyResponsesFilterContext = () => useContext(ProjectResponseFilterContext);
export const useSurveyFilterDataContext = () => useContext(SurveyResponsesFilterDataContext);