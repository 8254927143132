import { useContext } from 'react';
import * as $settings from '$admin/components/Conference.Settings/utils';
import { ConfirmContext } from './Context';
import * as Details from './Confirmation.Details';
import styles from './style/Confirmation.css';

type Props = unknown;

export const OnPlatform = (props: Props) => {
  const ctx = {
    confirm: useContext(ConfirmContext),
  };

  const { conference } = ctx.confirm.confirmation;

  const dialin = $settings.onplatform.resolveDialIn(conference?.details);
  const link = $settings.onplatform.resolveLinks(conference?.details);

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.label}>Time</div>
          <Details.Time
            end={ctx.confirm.confirmation.call?.timeEnd}
            start={ctx.confirm.confirmation.call?.timeStart} />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.label}>Dial In:</div>
          <div className={styles.scroll}>{dialin}</div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.label}>Conference Link:</div>
          <div className={styles.scroll}>{link.member}</div>
        </div>
      </div>
      {conference.settings.clientNotes &&
        <div className={styles.row}>
          <div className={styles.label}>Client Notes:</div>
          <Details.AdditionalDetails value={conference.settings.clientNotes} />
        </div>}
      <div className={styles.row}>
        <div className={styles.label}>Attendees</div>
        <Details.Participants items={ctx.confirm.confirmation.participants} />
      </div>
    </>
  );
};

OnPlatform.displayName = 'Confirmation.OnPlatform';