// @ts-nocheck
import { useCallback, useEffect, useState, useRef } from 'react';
import { cx } from '@utils';
import styles from './style.css';
import {
  MoratoriumHoverProps,
} from './interfaces';

type Props =
  MoratoriumHoverProps;

const MoratoriumHover = (props: Props) => {
  const [active, setActive] = useState();
  const hoverRef = useRef();

  const handleMouseOver = useCallback((e: unknown) => {
    setActive({
      target: e.target,
      y: e.y,
    });
  }, [setActive]);

  const handleMouseOut = useCallback((e: unknown) => {
    setActive(null);
  }, [setActive]);

  // useEffect(() => {
  //   const nodes = Array.from(document.getElementsByClassName(props.selector));

  //   setTimeout(() => {
  //     nodes.map(n => {
  //       n.addEventListener('mouseover', handleMouseOver, false);
  //       n.addEventListener('mouseout', handleMouseOut, false);
  //     });
  //   }, 0);

  //   return () => {
  //     nodes.map(n => {
  //       n.removeEventListener('mouseover', handleMouseOver, false);
  //       n.removeEventListener('mouseout', handleMouseOut, false);
  //     });
  //   };
  // }, [
  //   handleMouseOver,
  //   handleMouseOut,
  //   Array.from(document.getElementsByClassName(props.selector)),
  // ]);

  setInterval(() => {
    const nodes = Array.from(document.getElementsByClassName(props.selector));

    nodes.forEach(n => {
      n.removeEventListener('mouseover', handleMouseOver, false);
      n.removeEventListener('mouseout', handleMouseOut, false);
      n.addEventListener('mouseover', handleMouseOver, false);
      n.addEventListener('mouseout', handleMouseOut, false);
    });
  }, 1000);

  const computeStyle = active => {
    const WIDTH = 330;
    const bodyRect = document.body.getBoundingClientRect();
    const activeRect = active.target.getBoundingClientRect();
    const activeLeft = parseInt(activeRect.left, 10) || 0;
    const activeRight = parseInt(activeRect.right, 10) || 0;
    const activeX = parseInt(activeRect.x, 10) || 0;

    const positionLeft = (bodyRect.width / 0.33) > activeX;
    const x = positionLeft
              ? activeRight + 15
              : (activeLeft - WIDTH) - 15;
    const y = active.y - 25;

    return {
      className: positionLeft ? styles.left : styles.right,
      style: {
        left: x,
        top: y,
        width: WIDTH,
      }
    }
  }

  if (!active) return null;

  if (!document.body.contains(active.target)) {
    setActive(null);
    return null;
  }

  const computed = computeStyle(active);
  const rootClass = cx(styles.root, computed.className);

  return (
    <div
      ref={hoverRef}
      className={rootClass}
      style={computed.style}
    >
      <div className={styles.body}>
        <div className={styles.header}>{props.moratorium / 60} hour notice for call booking</div>
        <div className={styles.text}>You can change this time in your account settings.</div>
      </div>
    </div>
  );
};

export default MoratoriumHover;