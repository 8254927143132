import { useMemo } from 'react';
import * as survey from '@containers/Survey/utils';
import { cx } from '@utils';
import { Editable } from '@/components/Editable';
import { SurveyResponseAnswer, SurveyResponseQuestionText } from '@/components/SurveyResponse';
import { EditableSurveyAnswerProps } from './interfaces';
import styles from './style.css';

type Props = EditableSurveyAnswerProps;

export const EditableProjectSurveyResponseReviewItem = ({ children, item, onEdit }: Props) => {

  const className = useMemo(() => cx(styles.question, {
    [styles.hidden]: survey.response.isAnswerHidden(item),
  }), [item]);

  return (
    <div className={className}>
      <Editable
        className={styles.responseItem}
        onClick={onEdit}>
        <SurveyResponseQuestionText
          item={item.question} />
        <SurveyResponseAnswer
          className={styles.answer}
          item={item}
          showHidden={true} />
      </Editable>
      {children}
    </div>
  );
};

export const ProjectSurveyResponseReviewItem = ({ children, item }: Omit<Props, 'onEdit'>) => {
  const className = useMemo(() => cx(styles.question, {
    [styles.hidden]: survey.response.isAnswerHidden(item),
  }), [item]);

  return (
    <div className={className}>
      <div
        className={styles.responseItem}>
        <SurveyResponseQuestionText
          item={item.question} />
        <SurveyResponseAnswer
          className={styles.answer}
          item={item}
          showHidden={true} />
      </div>
      {children}
    </div>
  );
};

export default EditableProjectSurveyResponseReviewItem;