import { MediaOrientation } from '@enums';
import { cx } from '@utils';

type Props = {
  classes: {
    root:      string;
    landscape: string;
  };
  orientation: Omit<MediaOrientation, 'portrait'>;
  url:         string;
};

export const NavbarLogo = (props: Props) => {
  return props.orientation === MediaOrientation.Landscape
    ? <Landscape
        classes={props.classes}
        url={props.url} />
    : <Square
        classes={props.classes}
        url={props.url} />;
};

NavbarLogo.displayName = 'Whitelabel.NavbarLogo';

const Landscape = (props: Omit<Props, 'orientation'>) => {
  const classes = cx(props.classes.root, props.classes.landscape);

  return (
    <div className={classes}>
      <img
        className={classes}
        draggable={false}
        src={props.url} />
    </div>
  );
};

Landscape.displayName = 'Whitelabel.NavbarLogo.Landscape';

const Square = (props: Omit<Props, 'orientation'>) => {
  return (
    <div className={props.classes.root}>
      <img
        className={props.classes.root}
        draggable={false}
        src={props.url} />
    </div>
  );
};

Square.displayName = 'Whitelabel.NavbarLogo.Square';