import { memo } from 'react';
import { cx } from '@utils';
import Section from '$website/screens/Main/Section';
import styles from './style/Benefits.css';

type Props = {
  items: {
    image: {
      className?: string;
      icon: string;
    };
    text: {
      copy: string;
      title: string;
    };
  }[];
};

export const Benefits = memo(({ items }: Props) => {

  return (
    <Section>
      <div className={styles.wrap}>
        <h2 className={styles.headline}>How it works</h2>

        <div className={styles.main}>
          {items.map(x =>
            <div
              className={styles.benefit}
              key={x.text.copy}>
              <div className={styles.icon}>
                <img
                  alt={`Sentiment ${x.text.title}`}
                  className={cx(styles.img, x.image.className)}
                  src={x.image.icon} />
              </div>
              <h3 className={styles.caption}>{x.text.title}</h3>
              <div className={styles.copy}>{x.text.copy}</div>
            </div>)}
        </div>
      </div>
    </Section>
  );
});

export default Benefits;