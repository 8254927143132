import { memo } from 'react';
import styled from '@emotion/styled';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';

export const Textarea = memo(
  styled(TextareaAutosize)`
    width: 100%;
    box-sizing: border-box;
    border-style: solid;
    border-width: 2px;
    border-radius: 7px;
    padding: 8px;
    outline: none;
    resize: none;

    line-height: 22px;
    font-family: ${props => props.theme.fonts.regular};
    font-size: 16px;
    appearance: none;

    border-color: ${props => props.theme.palette.gray.light1};

    &:focus {
      border-color: ${props => props.theme.palette.sentiment.primary.main};
    }
  `
);