import { cx } from '@utils';
import { Checkbox as ReactCheckbox } from '@/components/Checkbox';
import styles from './style/Modal.PickRespondent.css';

type Props = {
  checked:    boolean;
  className?: string;
  disabled?:  boolean;
  label:      string;
  onChange:   (checked: boolean) => void;
};

export const Checkbox = (props: Props) => {
  return (
    <div className={cx(styles.option, props.className)}>
      <ReactCheckbox
        checked={props.checked}
        disabled={props.disabled}
        disabledColor="var(--gray-l)"
        onChange={(e, checked) => props.onChange(checked)} />
      <div className={cx(styles.message, { [styles.disabled]: props.disabled })}>
        {props.label}
      </div>
    </div>
  );
};

Checkbox.displayName = 'PickRespondent.Checkbox';