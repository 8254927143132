import type { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { cx } from '@utils';
import styles from './style/Input.css';

type Props = {
  isInvalid?: boolean;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const Text = ({ className, isInvalid, ...props }: Props) => {

  const resolvedClassName = cx(styles.input, className, {
    [styles.invalid]: isInvalid,
  });

  return (
    <input
      className={resolvedClassName}
      {...props} />
  );
};

Text.displayName = 'MedicalProfile.Input.Text';