import { useCallback, useState } from 'react';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import { useRichTextEditorRegister } from '@/containers/SurveyBuilder/hooks';
import type { SurveyRichText } from '@/types/survey.rich-text';
import { cx, useToggle } from '@utils';
import type { RenderRichTextEntryListEditorProps, RichTextEntryItem } from '@/components/TextEntryList';
import { RichTextEntryListEditor } from '@/components/TextEntryList/RichTextEntryListItem';
import type { SurveyRichTextEditorOnChange } from '@/components/Survey.RichText';
import { useSurveyRichTextEditor } from '@/components/Survey.RichText/hooks/useSurveyRichTextEditor';
import { SurveyRichTextEditorContainer, RichTextToggleButton } from '@/components/Survey.RichText';
import { Toolbar } from './Toolbar';
import styles from './style/ListBuilder.css';
import { LanguageEditingBarrier } from './LanguageEditingBarrier';

type ItemProps<T extends RichTextEntryItem = RichTextEntryItem> = {
  classes?: {
    editor?: string;
    root?: string;
  };
  disabled?: boolean;
  item: T;
  placeholder?: string;
  renderAction?: () => React.ReactNode;
  renderEditor?: (props: RenderRichTextEntryListEditorProps) => React.ReactNode;
  onUpdate: (value: SurveyRichText.RichTextValue) => void;
} & Partial<ChildrenProps>;

export const RichListBuilderItem = <T extends RichTextEntryItem = RichTextEntryItem>({ children, onUpdate, classes = {}, ...props }: ItemProps<T>) => {
  const { manager, state, onChange: rmOnChange } = useSurveyRichTextEditor({
    initialState: props.item.value,
    placeholder: props.placeholder,
  });

  const [isRichTextEnabled, toggleRichTextEnabled] = useState(false);

  const handleChange: SurveyRichTextEditorOnChange = useCallback(params => {
    rmOnChange(params);
    if (!params.tr?.docChanged) return;

    const value = params.state.doc.toJSON() as SurveyRichText.RichTextValue;
    onUpdate(value);
  }, [rmOnChange, onUpdate]);

  const editorRef = useRichTextEditorRegister({
    identifier: props.item.key.toString(),
  });

  const editorProps: RenderRichTextEntryListEditorProps<T> = {
    className: cx(styles.textarea, classes.editor),
    children,
    disabled: props.disabled,
    placeholder: props.placeholder,
    item: props.item,
  };

  return (
    <div className={cx(styles.root, classes.root)}>
      <SurveyRichTextEditorContainer
        manager={manager}
        state={state}
        onChange={handleChange}
        editorRef={editorRef}>
        {isRichTextEnabled && <Toolbar className={styles.toolbar} />}
        <div className={styles.item}>
          {props.renderEditor ? props.renderEditor(editorProps) : <RichTextEntryListEditor {...editorProps} />}
          <RichTextToggleButton enabled={isRichTextEnabled} onChange={toggleRichTextEnabled} />
          <LanguageEditingBarrier>
            <div className={styles.action}>
              {props.renderAction?.()}
            </div>
          </LanguageEditingBarrier>
        </div>
      </SurveyRichTextEditorContainer>
    </div>
  );
};

type RichListBuilderProps<T extends RichTextEntryItem = RichTextEntryItem> = {
  className?: string;
  items: T[];
  placeholder?: string;
  updateItemValue: (key: string | number) => (value: SurveyRichText.RichTextValue) => void;
};

export const RichListBuilder = <T extends RichTextEntryItem = RichTextEntryItem>(props: RichListBuilderProps<T>) => {
  return (
    <div className={props.className}>
      {
        props.items.map(item => (
          <RichListBuilderItem
            key={item.key}
            item={item}
            onUpdate={props.updateItemValue(item.key)}
            placeholder={props.placeholder} />
        ))
      }
    </div>
  );
};