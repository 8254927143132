import { useCallback, useMemo } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSurveyFormQuestionAnswer } from '@containers/SurveyForm/Context';
import type { NumberInputTableQuestion } from '@/types/survey';
import type { SurveyQuestionType } from '@/enums';
import Grid from './NumberInputTable.Grid';
import List from './NumberInputTable.List';
import { NumberInputTableWarnings } from './NumberInputTable.Warnings';
import { Rationale } from './Rationale';

type Props = {
  item: NumberInputTableQuestion.FormQuestion;
};

export function NumberInputTableQuestionForm({ item }: Props) {

  const [answer, setAnswer] = useSurveyFormQuestionAnswer<SurveyQuestionType.NumberInputTable>();

  const handleChange = useCallback((rowId: number, optionId: number) => (value: number) => {
    const items = (answer?.items || []).reduce<NumberInputTableQuestion.RespondentAnswer.Item[]>((acc, x) => {

      if (x.optionId === optionId && x.rowId === rowId) {
        return acc;
      }

      return acc.concat(x);
    }, [{ optionId, rowId, value }]);

    setAnswer({ items, rowText: answer?.rowText });
  }, [
    answer,
    setAnswer,
  ]);

  const params = useMemo(() => ({
    handleChange,
    item,
  }), [
    handleChange,
    item,
  ]);

  const showGrid = useMediaQuery('(min-width:800px)');

  const renderGrid = useCallback(() => {
    if (!showGrid) {
      return null;
    }

    const splitUpGrid = item.options.length > MaxGridOptions;
    const optionsPerGrid = splitUpGrid
      ? Math.ceil(item.options.length / 2)
      : item.options.length;

    return (
      <>
        <Grid
          {...params}
          maxOptions={optionsPerGrid}
          from={0}
          to={optionsPerGrid} />
        {splitUpGrid &&
          <Grid
            {...params}
            maxOptions={optionsPerGrid}
            from={optionsPerGrid}
            to={item.options.length} />
        }
      </>
    );

  }, [
    item,
    params,
    showGrid,
  ]);

  return (
    <>
      {renderGrid()}
      {!showGrid &&
        <List {...params} />}
      <NumberInputTableWarnings item={item} />
      <Rationale settings={item.settings.rationale} />
    </>
  );
}

const MaxGridOptions = 6;

export default NumberInputTableQuestionForm;