import { UseDownloaderOptions, useQueryDownloader } from '@containers/QueryDownloader';
import * as api from '$admin/api';
import * as API from '$admin/api/interfaces';

type Params = API.Projects.ExportProjectParent.Request;

export const useDownloadProjectData = (params: Params, options: UseDownloaderOptions<API.Projects.ExportProjectParent.Response> = {}) => {
  return useQueryDownloader({
    queryKey: [
      `get:admin/projects/parents/export`,
      params,
    ],
    queryFn: () => {
      return api.projects.exportProjectParent({
        projectParentId: params.projectParentId,
      });
    },
    ...options,
    meta: {
      ...options.meta,
      downloader: true,
    },
    downloaderOptions: {
      onResponse: res => ({
        filename: res.filename,
        type: 'blob',
        value: res.blob,
      }),
    },
  });
};