import { type CSSProperties } from 'react';
import { useMemo } from 'react';
import type { CSSObjectWithLabel, Props as SelectProps, StylesConfig, Theme  } from 'react-select';
import Select from 'react-select';
import type { CreatableProps } from 'react-select/creatable';
import Creatable from 'react-select/creatable';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { cx } from '@utils';
import { Focusable } from '@/components/Focusable';
import styles from './style/Select.Search.css';

type Props<Option = unknown, Multi extends boolean = false> = {
  creatable?: boolean;
  showIcon?:  boolean;
  styles?: {
    control?:     CSSProperties;
    input?:       CSSProperties;
    singleValue?: CSSProperties;
  } & Partial<Pick<CreatableProps<Option, Multi, { options: Option[]; label?: string }>['styles'], 'menuPortal'>>;
} & Omit<SelectProps<Option>, 'styles'>
  & Omit<CreatableProps<Option, Multi, { options: Option[]; label?: string }>, 'styles'>;

export const SelectSearchUnderlined = <T, M extends boolean = false>({ showIcon = true, styles: selectstyles, ...props }: Props<T, M>) => {

  const selectStyles = useMemo(() => {
    return createSelectStyles<T>({ styles: selectstyles });
  }, [selectstyles]);

  const selectTheme = useMemo(() => {
    return createSelectTheme();
  }, []);

  return (
    <Focusable disabled={props.tabIndex === -1}>
      <div className={styles.root}>
        {props.creatable
          ? <Creatable
            {...props}
            styles={selectStyles}
            theme={selectTheme} />
          : <Select
            {...props}
            styles={selectStyles}
            theme={selectTheme} />}
        {showIcon && <ArrowDropDownIcon className={styles.icon} />}
      </div>
    </Focusable>
  );
};

SelectSearchUnderlined.displayName = 'Select.Search.Underlined';

function createSelectStyles<T>({ styles = {} }: Pick<Props, 'styles'>): StylesConfig<T, false> {
  return {
    menu: ex => ({
      ...ex,
      width: 'max-content',
      minWidth: '100%',
      zIndex: 2,
    } as CSSObjectWithLabel),
    control: ex => ({
      ...ex,
      border: 'none',
      borderRadius: '0px',
      boxShadow: 'none',
      minHeight: '33px',
      padding: '0 24px 0 0',
      ...styles.control,
    } as CSSObjectWithLabel),
    input: ex => ({
      ...ex,
      ...styles.input,
    } as CSSObjectWithLabel),
    dropdownIndicator: ex => ({
      display: 'none',
    }),
    indicatorSeparator: ex => ({
      display: 'none',
    }),
    container: ex => ({
      ...ex,
      maxWidth: '100%',
    } as CSSObjectWithLabel),
    placeholder: ex => ({
      ...ex,
      color: 'var(--gray)',
    } as CSSObjectWithLabel),
    singleValue: ex => ({
      ...ex,
      ...styles.singleValue,
    } as CSSObjectWithLabel),
    valueContainer: ex => ({
      ...ex,
      overflow: 'hidden',
      padding: 0,
    } as CSSObjectWithLabel),
  };
}

export const createSelectTheme = () => (theme: Theme) => {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary: 'var(--pri-02)',
      primary25: 'var(--blue-l)',
      primary50: 'var(--blue-l)',
      primary75: 'var(--blue-l)',
    },
  };
};