export enum CompanyTab {
  Overview = 'Overview',
  CurrentEmployees  = 'Current Employees',
  FormerEmployees  = 'Former Employees',
}

export type Scene = {
  component: React.ComponentType;
  key:       CompanyTab;
  path:      string;
  title:     string;
};

export type SceneMap = {
  [key in CompanyTab]: Scene;
};

export type RouteParams = {
  slug: string;
};

export declare namespace BuildSceneMap {
  export type Params = {
    hasCurrentEmployees: boolean;
    hasFormerEmployees: boolean;
    slug: string;
  };

  export type Return = SceneMap;
}