import { useCallback, useMemo, useState } from 'react';
import AutoComplete from '@/components/AutoComplete';
import { ImportedContact, ProjectInviteReferralEmailContextValue } from './interfaces';
import { useSearchImportedContacts } from './hooks/useSearchImportedContacts';
import styles from './style/Email.Input.css';

type Props = {
  onAdd: ProjectInviteReferralEmailContextValue['addAddress'];
} & Pick<ProjectInviteReferralEmailContextValue['data'], 'addresses'>;

export const AutoSuggest = ({ addresses, onAdd }: Props) => {
  const [value, setValue] = useState('');

  const req = useSearchImportedContacts({
    exclude: addresses,
    value,
  });

  const handleSelect = useCallback((item: ImportedContact) => {
    const email = item.isCustom
        ? item.name
        : item.value;

    onAdd(email, addresses => {
      if (addresses.includes(email)) {
        setValue('');
      }
    });
  }, [
    onAdd,
    setValue,
  ]);

  const items = useMemo(() => {
    return value
      ? req.data.items.filter(x => !addresses.includes(x.value))
      : [];
  }, [
    addresses,
    req.data.items,
    value,
  ]);

  return (
    <AutoComplete.Multi
      classes={{
        input: styles.input,
      }}
      focusableClasses={{
        root: styles.focusable,
      }}
      getItemValue={(item: ImportedContact) => item.name}
      items={items}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value) }
      onSelect={handleSelect}
      placeholder="Add Email Addresses" />
  );
};

AutoSuggest.displayName = 'ProjectInviteReferral.Email.Input.AutoSuggest';