import { useCallback, useContext, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { ButtonActivityIndicator, ButtonOutlined } from '@presentation';
import * as $api from '@api/projects.quant-analysis';
import { QuantAnalysisContext } from '@/containers/Project.QuantAnalysis';
import { useProjectState } from '@/containers/GroupProject/hooks';
import { useInvalidateProjectQuantAnalysisQuery } from '@/utils/api';
import type { QuantAnalysisItem } from '@/types/project.quant-analysis';
import { Input } from '@/components/Input';
import { Header } from '@/components/Modal/Header';
import { Body } from '@/components/Modal/Body';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import { useModal } from '@/components/Modal';
import styles from './style/Modal.RenameAnalysisItem.css';

type Props = {
  projectId: number;
  quantAnalysisId: number;
  analysisItem: QuantAnalysisItem;
} &
  Pick<ModalProps,
    | 'onClose'
    | 'open'>;

export const RenameQuantAnalysisItemModal = (props: Props) => {

  const [input, setInput] = useState('');

  const quantAnalysis = useContext(QuantAnalysisContext);
  const state = useProjectState();

  const invalidateQuantAnalysis = useInvalidateProjectQuantAnalysisQuery();

  const renameMutation = useMutation({
    mutationFn: () => {
      return $api.renameQuantAnalysisItem({
        projectId: state.project.id,
        quantAnalysisId: quantAnalysis.id,
        itemId: props.analysisItem.id,
        name: input,
      });
    },
    onSuccess: () => {
      invalidateQuantAnalysis({
        projectId: state.project.id,
        quantAnalysisId: quantAnalysis.id,
      });
      props.onClose();
    },
  });

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    renameMutation.mutateAsync();
  }, [renameMutation]);

  const canSubmit = useMemo(() => {
    return input.trim().length > 0;
  }, [input]);

  return (
    <Modal
      disableEscapeClose={renameMutation.isLoading}
      disableOverlayClick={renameMutation.isLoading}
      hideCloseIcon
      open={props.open}
      onClose={props.onClose}>
      <Body>
        <Header>
          {`Rename "${props.analysisItem.name}"`}
        </Header>

        <div className={styles.input}>
          <Input
            autoFocus
            onChange={handleInputChange}
            placeholder="Enter a new name"
            value={input} />
        </div>
      </Body>
      <ButtonSet>
        <ButtonOutlined
          borderWidth="thin"
          disabled={renameMutation.isLoading}
          color="silver"
          onClick={props.onClose}>
          Cancel
        </ButtonOutlined>
        <ButtonActivityIndicator
          color="destructive"
          indicatorSize={12}
          disabled={!canSubmit}
          implicitDisable={renameMutation.isLoading}
          loading={renameMutation.isLoading}
          onClick={handleSubmit}>
          Submit
        </ButtonActivityIndicator>
      </ButtonSet>
    </Modal>
  );
};

export const useRenameQuantAnalysisItemModal = () => useModal(RenameQuantAnalysisItemModal);