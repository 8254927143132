import { cx } from '@utils';
import { Button } from '@/components/Button';
import type { ButtonOutlinedProps } from './interfaces/Button';
import styles from './style/Button.css';

type Props = ButtonOutlinedProps;

export const ButtonOutlined = ({
  className,
  borderWidth,
  fontWeight,
  color,
  variant = 'brick',
  ...rest
}: Props) => {

  const colorStyles = {
    black: styles.black,
    blue: styles.blue,
    gray: styles.gray,
    green: styles.green,
    red: styles.red,
    silver: styles.silver,
  }[color] || styles.blue;

  const borderStyles = {
    medium: styles.medium,
    thick: styles.thick,
    thin: styles.thin,
  }[borderWidth] || styles.medium;

  const fontStyles = {
    light: styles.light,
    regular: styles.regular,
    semibold: styles.semibold,
    bold: styles.bold,
  }[fontWeight] || styles.semibold;

  const classnames = cx(
    colorStyles,
    borderStyles,
    fontStyles,
    className,
  );

  const variantProp = variant === 'rounded'
      ? 'pill'
      : variant;

  return (
    <Button
      {...rest}
      color="secondary"
      className={classnames}
      variant={variantProp} />
  );
};