export { default as Left } from './Left';
export { default as Right } from './Right';

import { ChatIcon } from './Chat';
import { Information, InformationIcon } from './Information';
import { LogsIcon } from './Logs';
import { Participants, ParticipantsIcon } from './Participants';
import { SupportIcon } from './Support';
import { SurveysIcon } from './Surveys';

export const Views = {
  Information,
  Participants,
};

export const Icons = {
  Chat: ChatIcon,
  Information: InformationIcon,
  Logs: LogsIcon,
  Participants: ParticipantsIcon,
  Support: SupportIcon,
  Surveys: SurveysIcon,
};