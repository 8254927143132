import { useSelector } from 'react-redux';
import * as selectors from '@store/selectors';
import { useGoogleContactSync } from './useGoogleSync';
import { useOutlookContactSync } from './useMicrosoftSync';

export const useConnectAccountContactSync = () => {

  const connectedContacts = useSelector(selectors.connectedContacts);

  const google = useGoogleContactSync();
  const outlook = useOutlookContactSync();

  const provider = {
    google: {
      connect: google.connect,
      loading: google.isLoading,
    },
    outlook: {
      connect: outlook.connect,
      loading: outlook.isLoading,
    },
  };

  return {
    connection: connectedContacts,
    provider,
  };
};