import { memo, ComponentType } from 'react';
import { ProjectType } from '@enums';
import { cx } from '@utils';
import Call from './Call';
import Mixed from './Mixed';
import Survey from './Survey';
import { ProjectIconProps } from './interfaces';
import styles from './style.css';

type Props = {
  projectType: ProjectType;
} & ProjectIconProps;

export const ProjectIcon = memo(({
  className,
  projectType,
  size = 28,
  ...iconProps
}: Props) => {

  let Icon: ComponentType<ProjectIconProps>;
  if (projectType === ProjectType.Call) {
    Icon = Call;
  } else if (projectType === ProjectType.Survey) {
    Icon = Survey;
  } else if (projectType === ProjectType.SurveyCall) {
    Icon = Mixed;
  }

  return (
    <Icon
      className={cx(styles.root, className)}
      size={size}
      {...iconProps} />
  );
});

export default ProjectIcon;