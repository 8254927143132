import { useCallback } from 'react';
import { SurveyActionType } from '@enums/Survey';
import { cx } from '@utils';
import {
  useSurveyLogicItemState,
  generateDefaultAction,
  LogicActions,
} from '@containers/SurveyBuilder.Logic';
import { ActionTypeDropdown } from '@presentation/SurveyBuilder';
import { SurveyLogic } from '@/types/survey';
import { SkipToSectionDropdown, IncludeSectionDropdown, ActionQuestionDropdown } from './Builder.Action.Dropdowns';
import { ExcludeQuestionChoiceDropdown } from './Builder.Action.Dropdowns.ExcludeQuestionChoice';
import styles from './style/Builder.Action.css';

type Props = {
  className?: string;
};

export const LogicBuilderAction = ({ className }: Props) => {

  const [item, dispatch] = useSurveyLogicItemState();

  const updateAction = useCallback((value: SurveyLogic.Action) => {
    dispatch({
      type: 'action-updated',
      payload: value,
    });
  }, [dispatch]);

  const handleActionTypeUpdate = useCallback((value: SurveyActionType) => {
    const action = generateDefaultAction(value);
    updateAction(action);
  }, [updateAction]);

  return (
    <div className={className}>
      <div className={styles.row}>
        <div className={styles.action}>
          <ActionTypeDropdown
            items={LogicActions}
            onSelect={handleActionTypeUpdate}
            value={item.action.type} />
        </div>
        {item.action.type === SurveyActionType.SkipToQuestion &&
          <ActionQuestionDropdown />
        }
        {item.action.type === SurveyActionType.SkipToSection &&
          <SkipToSectionDropdown />
        }
        {item.action.type === SurveyActionType.IncludeSection &&
          <IncludeSectionDropdown />
        }
      </div>
  
      {(item.action.type === SurveyActionType.ExcludeQuestionChoice ||
        item.action.type === SurveyActionType.DisplayQuestionChoice) && (
          <div className={cx(styles.row, styles.excludeRow)}>
            <ExcludeQuestionChoiceDropdown />
          </div>
        ) 
      }
    </div>
  );
};