import { useCallback } from 'react';
import type { SurveyQuestionType } from '@enums';
import { SurveyOptionType } from '@enums';
import type { MatrixMultiselectQuestion } from '@/types';
import { useSurveyFormQuestionAnswer } from '@/containers/SurveyForm';
import { SurveyMatrixGridContainer } from '@/components/SurveyMatrixGrid';
import { MatrixFormContext } from './context';
import type { MatrixForm } from './interfaces';
import { Rationale } from './Rationale';

type Props = {
  item: MatrixMultiselectQuestion.FormQuestion | MatrixMultiselectQuestion.Question;
};

export const MatrixMultiselectQuestionForm = ({ item }: Props) => {

  const [answer, setAnswer] = useSurveyFormQuestionAnswer<SurveyQuestionType.MatrixMultiselect>();

  const updateRowAnswer: MatrixForm.OnSelect = useCallback(({ optionId, rowId }) => {

    const naOption = item.options.find(f => f.type === SurveyOptionType.NotApplicable);

    const option = item.options.find(f => f.id === optionId);
    const isNA = option.type === SurveyOptionType.NotApplicable;

    const selected = !answer.values[rowId][optionId];

    const values: MatrixMultiselectQuestion.RespondentAnswer.Map = answer.values;

    if (naOption) {
      values[rowId][naOption.id] = false;
    }

    if (isNA && selected) {
      item.options.forEach(option => {
        values[rowId][option.id] = false;
      });
    }

    values[rowId][optionId] = selected;

    setAnswer({ values, rowText: answer.rowText });

  }, [
    answer,
    item.options,
    setAnswer,
  ]);

  const updateRowTextValue = useCallback((rowId: number) => (value: string) => {

    setAnswer({
      rowText: {
        ...answer.rowText,
        [rowId]: value,
      },
      values: answer.values,
    });
  }, [setAnswer, answer]);

  const ctx: MatrixForm.ContextValue = {
    display: item.settings.display,
    groupedBy: item.settings.groupBy,
    onSelect: updateRowAnswer,
    onUpdateRowText: updateRowTextValue,
    options: item.options,
    rows: item.matrixRows,
    rowTextMap: answer.rowText,
    values: answer.values,
    variant: 'multiselect',
  };

  return (
    <>
      <MatrixFormContext.Provider value={ctx}>
        <SurveyMatrixGridContainer />
      </MatrixFormContext.Provider>
      <Rationale settings={item.settings.rationale} />
    </>
  );
};

export default MatrixMultiselectQuestionForm;