import { forwardRef, useCallback, useContext } from 'react';
import Popper from '@mui/material/Popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import { MoreHorizontalAnchor } from '@presentation/Anchor';
import type { ConferenceTag } from '@/types';
import { DownloadTaggedMomentMediaClipContext } from '@/containers/Transcript/context';
import { TaggedMomentDownloadContainer } from '@/containers/Transcript/TaggedMoment.Clip';
import { PopperMenu, PopperMenuItem } from '@/components/Popper';
import styles from './styles.css';

type Props = {
  momentId: number;
  tag: ConferenceTag;
} & ITranscriptId;

export const TaggedMomentMenu = ({ momentId, tag }: Props) => {

  return (
    <TaggedMomentDownloadContainer tag={tag} momentId={momentId}>
      <PopupState variant="popper" popupId="tagged-moment-actions-popper">
        {popupState => (
          <div>
            <div {...bindToggle(popupState)}>
              <MoreHorizontalAnchor open={popupState.isOpen} />
            </div>
            <Popper
              {...bindPopper(popupState)}
              placement={'bottom-end'}
              className={styles.popper}>
              <ClickAwayListener
                onClickAway={popupState.close}>
                <MomentActionsMenu
                  onClose={popupState.close} />
              </ClickAwayListener>
            </Popper>
          </div>
        )}
      </PopupState>
    </TaggedMomentDownloadContainer>
  );
};

type MomentActionsProps = {
  onClose: () => void;
};
const MomentActionsMenu = forwardRef(({ onClose }: MomentActionsProps, ref: React.Ref<HTMLDivElement>) => {
  const closeWrapper = useCallback((fn: () => void) => {
    return () => {
      fn();
      onClose();
    };
  }, [onClose]);

  const downloadClip = useContext(DownloadTaggedMomentMediaClipContext);

  return (
    <PopperMenu ref={ref}>
      <PopperMenuItem onClick={closeWrapper(downloadClip)}>
        Download Video Clip
      </PopperMenuItem>
    </PopperMenu>
  );
});