import http from '@services/http';
import { safeJsonDate } from '@utils';
import { Groups } from '$admin/api/interfaces';

export const getBalanceAccountOverview = ({ groupId }: Groups.GetBalanceAccountOverview.Request): Promise<Groups.GetBalanceAccountOverview.Response> => {
  return http.get<Groups.GetBalanceAccountOverview.Response<string>>(`/admin/groups/${groupId}/balance-account/overview`)
  .then(result => ({
    ...result,
    items: result.items.map(item => ({
      ...item,
      createdOn: safeJsonDate(item.createdOn),
    })),
  }));
};

export const addBalanceFunding = (data: Groups.AddBalanceFunding.Request): Promise<Groups.AddBalanceFunding.Response> => {
  const { groupId, ...rest } = data;
  return http.post(`/admin/groups/${groupId}/balance-account/funding`, rest)
  .then((result: Groups.AddBalanceFunding.Response<string>) => ({
    ...result,
    item: {
      ...result.item,
      createdOn: safeJsonDate(result.item.createdOn),
    },
  }));
};

export const removeBalanceFunding = ({ groupId, id }: Groups.RemoveBalanceFunding.Request): Promise<Groups.RemoveBalanceFunding.Response> => {
  return http.delete(`/admin/groups/${groupId}/balance-account/funding/${id}`);
};