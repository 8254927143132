import { useCallback } from 'react';
import type { MatrixGridQuestion } from '@/types/survey';
import MatrixRows from './Rows';
import Options from './Options';
import { SurveyQuestionLogic } from './Logic';
import { SurveyQuestionClassifications } from './Tagging';
import styles from './style/MatrixQuestion.css';
import {
  useGetRowDisplayLogic,
  useGetOptionDisplayLogic,
  useQuestionClassifications,
  useQuestionLogic,
} from './hooks';
import { SurveySettings } from './Settings';
import { SurveyMetadata } from './Metadata';

type Props = {
  item: MatrixGridQuestion.Question;
};

export const MatrixGridPreview = ({ item }: Props) => {
  const questionLogic = useQuestionLogic(item.base.identifier);
  const questionClassifications = useQuestionClassifications(item.base.identifier);

  const getRowDisplayLogic = useGetRowDisplayLogic(item.base.identifier);
  const getOptionDisplayLogic = useGetOptionDisplayLogic(item.base.identifier);

  const renderRowExtras = useCallback((row: MatrixGridQuestion.Row) => {
    const items = [
      row.metadata.isOpenEnded && 'Open-ended',
      row.metadata.isAnchored && 'Anchored',
    ].filter(Boolean);
    const displayLogicItems = getRowDisplayLogic(row);

    return (
      <>
        <SurveyMetadata items={items} />
        {displayLogicItems}
      </>
    );
  }, [getRowDisplayLogic]);

  const renderOptionExtras = useCallback((option: MatrixGridQuestion.Option) => {
    const displayLogicItems = getOptionDisplayLogic(option);

    return (
      <>
        {displayLogicItems}
      </>
    );
  }, [getOptionDisplayLogic]);

  return (
    <>
      <div className={styles.header}>Rows</div>
      <div className={styles.rows}>
        <MatrixRows
          item={item}
          renderRowExtras={renderRowExtras} />
      </div>
      <div className={styles.header}>Choices</div>
      <Options
        item={item}
        renderOptionExtras={renderOptionExtras} />
      <SurveySettings>
        {item.settings.scaleDisplayFrequency && <>Show scale every {item.settings.scaleDisplayFrequency} rows</>}
        {item.settings.orderRowsBasedOnSource && <>Order rows based on source question</>}
        {item.settings.randomizeRows && <>Randomize rows</>}
        {item.settings.randomizeOptions && <>Randomize choices</>}
        {item.settings.display &&
          <>
            {{
              grid: 'Always show respondents grid form',
              list: 'Always show respondents list form',
            }[item.settings.display]}
          </>}
        {{
          column: 'Group answers by column',
          row: 'Group answers by row',
        }[item.settings.groupBy]}
      </SurveySettings>
      <SurveyQuestionLogic items={questionLogic} />
      <SurveyQuestionClassifications items={questionClassifications} />
    </>
  );
};