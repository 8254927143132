import { useEffect } from 'react';
import { useWindowScroll } from '@utils/hooks/useWindowScroll';

type Props = {
  canFetch: boolean;
  fetchNext: () => unknown;
  /**
   * @param bufferSize - Percent as decimal (default: 0.20)
  */
  bufferSize?: number;
};

export default function useInfiniteWindowScroll({ canFetch, fetchNext, bufferSize = 0.20 }: Props) {
  const scroll = useWindowScroll();

  useEffect(() => {
    const buffer = document.body.scrollHeight * bufferSize;

    const scrolledToEnd = (document.body.scrollHeight - buffer) <= Math.ceil(scroll.y + window.innerHeight);

    if (scrolledToEnd && canFetch) {
      fetchNext();
    }
  }, [
    bufferSize,
    scroll.y,
    canFetch,
    fetchNext,
  ]);
}

export { useInfiniteWindowScroll };