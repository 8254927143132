import { Fragment, memo } from 'react';
import Popover from 'material-ui-popup-state/HoverPopover';
import { bindHover, bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import { History } from '@/components/icons';
import { Results } from '../interfaces/members';
import styles from './style/Table.css';

export const Cell = memo(({ row }: Results.TableCellProps) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'history-popover',
  });

  return (
    <Fragment>
      <div
        className={styles.historyIcon}
        {...bindHover(popupState)}>
        <History />
      </div>
      <Popover
        {...bindPopover(popupState)}
        sx={{
          pointerEvents: 'none',
          cursor: 'default',
        }}
        slotProps={{
          paper: {
            sx: {
              backgroundColor: 'var(--pri-01)',
              border: '1px solid transparent',
              borderRadius: '4px',
              boxShadow: `
                  0px 5px 5px -3px rgba(0,0,0,0.2),
                  0px 8px 10px 1px rgba(0,0,0,0.14),
                  0px 3px 14px 2px rgba(0,0,0,0.12)`,
            },
          },
        }}
        anchorOrigin={{
          vertical: 26,
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <div className={styles.history}>
          <div className={styles.historyRow}>
            <div className={styles.historyLabel}>Survey Completes:</div>
            <div>{row.original.history.surveyCompletes}</div>
          </div>
          <div className={styles.historyRow}>
            <div className={styles.historyLabel}>Completed Calls:</div>
            <div>{row.original.history.completedCalls}</div>
          </div>
          <div className={styles.historyRow}>
            <div className={styles.historyLabel}>Survey Responses:</div>
            <div>{row.original.history.surveyResponses}</div>
          </div>
          <div className={styles.historyRow}>
            <div className={styles.historyLabel}>Accepted Calls:</div>
            <div>{row.original.history.acceptedCalls}</div>
          </div>
          <div className={styles.historyRow}>
            <div className={styles.historyLabel}>Total Projects:</div>
            <div>{row.original.history.projects}</div>
          </div>
        </div>
      </Popover>
    </Fragment>
  );
});

export const Filter = memo(() => null);

export const Header = memo(() => {
  return (
    <Fragment>
      History
    </Fragment>
  );
});

Cell.displayName = 'Column.History.Cell';
Filter.displayName = 'Column.History.Filter';
Header.displayName = 'Column.History.Header';