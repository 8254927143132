import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useFetchSurveyRespondentAnswers } from '@utils/api';
import { ProjectSurveyRespondentContext } from './Context';
import { ProjectStateContainer } from './Project.State.Container';
import { useProjectState } from './hooks';

type RouteParams = {
  slug: string;
  projectSurveyResponseId: string;
};

const ProjectSurveyRespondentState = (props: ChildrenProps) => {

  const [response, fetchRespondentAnswers] = useFetchSurveyRespondentAnswers();

  const params = useParams<RouteParams>();

  const projectSurveyResponseId = +params.projectSurveyResponseId;
  const projectId = +params.slug;

  const { project } = useProjectState();

  // const anonymity = useProjectAnonymity();
  // const contact = useSelectContact(response?.value?.userId);

  // const user = useMemo(() => {
  //   if (!response.value) { return null; }

  //   if (!anonymity) {
  //     return contact;
  //   }

  //   contact.career.current
  //   return {
  //     id: contact.id,
  //     profile: { fullname: `Respondent #${response.value.order}`, creditRate: contact.profile.creditRate },
  //     career: {
  //       current: contact.career.current ? {
  //         title: Seniority[contact.career.current.seniority],
  //       } : null,
  //       former: contact.career.former ? {
  //         title: Seniority[contact.career.former.seniority],
  //       } : null,
  //     },
  //   };
  // }, [response.value]);

  const refetch = useCallback(() => {
    fetchRespondentAnswers({
      projectId,
      projectSurveyResponseId,
    });
  }, [
    fetchRespondentAnswers,
    projectId,
    projectSurveyResponseId,
  ]);

  useEffect(() => {
    fetchRespondentAnswers({
      projectId,
      projectSurveyResponseId,
    });
  }, [
    fetchRespondentAnswers,
    projectId,
    projectSurveyResponseId,
  ]);

  const value = useMemo(() => ({
    projectId: response.value?.projectId,
    projectSurveyResponseId,
    refetch,
    response: response.value,
    respondent: response.value?.respondent,
    versions: response.value?.versions,
    statusId: response.value?.statusId,
  }), [
    projectSurveyResponseId,
    response.value,
  ]);

  if (!project) {
    return null;
  }

  return (
    <ProjectSurveyRespondentContext.Provider value={value}>
      {props.children}
    </ProjectSurveyRespondentContext.Provider>
  );
};

type Props = {
  children: React.ReactNode;
};

export const ProjectSurveyRespondentContainer = (props: Props) => {

  return (
    <ProjectStateContainer>
      <ProjectSurveyRespondentState>
        {props.children}
      </ProjectSurveyRespondentState>
    </ProjectStateContainer>
  );
};