import { Switch } from '@/components/Switch';
import styles from './style/SwitchField.css';

type Props = {
  editing: boolean;
  label: string;
  value: boolean;
  onChange: (bool: boolean) => unknown;
};

const InlineSwitchField = (props: Props) => {
  return (
    <div className={styles.inline}>
      <div className={styles.label}>{props.label}</div>
      <Switch
        disabled={!props.editing}
        onClick={props.onChange}
        active={props.value} />
    </div>
  );
};

export { InlineSwitchField };
export default InlineSwitchField;