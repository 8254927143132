import type { CSSProperties } from 'react';
import { memo } from 'react';
import type { FileUploadFile } from '@/types/workspace';
import { getFileExtension } from '@/utils';
import { FileIcon } from '@/components/icons';
import { ItemHeight } from './consts';
import styles from './style/FileUpload.css';

type Props = {
  children?: React.ReactNode;
  file: FileUploadFile;
  subtitle?: React.ReactNode;
};

export const FileUploadBase = memo(({
  children,
  file,
  subtitle,
}: Props) => {

  const style: CSSProperties = {
    height: ItemHeight,
  };

  const extension = getFileExtension(file.name);

  return (
    <div className={styles.root} style={style}>
      <div className={styles.content}>
        <div className={styles.file}>
          <FileIcon
            className={styles.icon}
            extension={extension}
            size={24} />
          <div className={styles.name}>
            {file.name}
            <div className={styles.subtitle}>{subtitle}</div>
          </div>
        </div>
      </div>
      {children}
    </div>
  );
});

export default FileUploadBase;