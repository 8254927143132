import extend from '@enums/extend';
import {
  surveyActionTypeMap,
  surveyConditionTypeMap,
  surveyLogicRuleMap,
  surveyTaggingResultTypeMap,
} from './survey.logic';

export * from './survey.logic';

export enum SurveyQuestionType {
  ShortTextResponse = 1,
  MultipleChoice = 2,
  LongTextResponse = 3,
  Multiselect = 4,
  Ranking = 5,
  MatrixGrid = 6,
  MaxDifference = 7,
  ConjointAnalysis = 8,
  Sliders = 9,
  MatrixMultiselect = 10,
  NumberInputTable = 11,
  MultiTextbox = 12,
  ExclusiveOptions = 13,
  Dropdown = 14,
  MatrixRange = 15,
  ImageMarkup = 16,
}

export type OptionQuestionType =
  SurveyQuestionType.MatrixGrid |
  SurveyQuestionType.MatrixMultiselect |
  SurveyQuestionType.MaxDifference |
  SurveyQuestionType.Ranking |
  SurveyQuestionType.MultipleChoice |
  SurveyQuestionType.Multiselect |
  SurveyQuestionType.NumberInputTable |
  SurveyQuestionType.ExclusiveOptions |
  SurveyQuestionType.Dropdown |
  SurveyQuestionType.MatrixRange;

export type RowsQuestionType =
  SurveyQuestionType.MatrixGrid |
  SurveyQuestionType.MatrixMultiselect |
  SurveyQuestionType.Sliders |
  SurveyQuestionType.NumberInputTable |
  SurveyQuestionType.MatrixRange
  ;

const surveyQuestionTypeMap = new Map([
  [SurveyQuestionType.ShortTextResponse, { name: 'Short Text Response' }],
  [SurveyQuestionType.MultipleChoice, { name: 'Multiple Choice' }],
  [SurveyQuestionType.LongTextResponse, { name: 'Long Text Response' }],
  [SurveyQuestionType.Multiselect, { name: 'Multiselect' }],
  [SurveyQuestionType.Ranking, { name: 'Ranking' }],
  [SurveyQuestionType.MatrixGrid, { name: 'Matrix Grid' }],
  [SurveyQuestionType.MaxDifference, { name: 'MaxDiff' }],
  [SurveyQuestionType.ConjointAnalysis, { name: 'Conjoint Analysis' }],
  [SurveyQuestionType.Sliders, { name: 'Sliders' }],
  [SurveyQuestionType.MatrixMultiselect, { name: 'Matrix Multiselect' }],
  [SurveyQuestionType.NumberInputTable, { name: 'Number Input Table' }],
  [SurveyQuestionType.MultiTextbox, { name: 'Multiple Textboxes' }],
  [SurveyQuestionType.ExclusiveOptions, { name: 'Exclusive Options' }],
  [SurveyQuestionType.Dropdown, { name: 'Dropdown' }],
  [SurveyQuestionType.MatrixRange, { name: 'Matrix Range' }],
  [SurveyQuestionType.ImageMarkup, { name: 'Image Markup' }],
]);

export enum SurveyTypeOld {
  Compliance = 'compliance',
  Project = 'project',
}

export enum SurveyType {
  GroupCompliance = 1,
  Project = 2,
  ProjectScreening = 3,
  AnalystPostCall = 4,
  Module = 5,
  ConferenceQuant = 6,
}

const surveyTypeMap = new Map([
  [SurveyType.GroupCompliance, { name: 'Group Compliance' }],
  [SurveyType.Project, { name: 'Survey/Screener' }],
  [SurveyType.ProjectScreening, { name: 'Project Screener' }],
  [SurveyType.AnalystPostCall, { name: 'Analyst Post Call' }],
  [SurveyType.Module, { name: 'Module' }],
  [SurveyType.ConferenceQuant, { name: 'Capture Sheet' }],
]);

export enum SurveyStatus {
  NotRequired = 1,
  Incomplete = 2,
  Completed = 3,
}

export enum SurveyQuestionTaggingActionType {
  ProductPositiveAffinity = 1,
  ProductNegativeAffinity = 2,
}

export enum SurveyQuotaType {
  Basic = 1,
  Advanced = 2,
}

export enum SurveyOptionType {
  Default = 1,
  NotApplicable = 2,
  // Other = 3,
  NoneOfTheAbove = 4,
}

export enum SurveyItemType {
  Question = 1,
  Message = 2,
  AlternateImageExercise = 3,
}

const surveyItemTypeMap = new Map([
  [SurveyItemType.Question, { name: 'Question' }],
  [SurveyItemType.Message, { name: 'Message' }],
  [SurveyItemType.AlternateImageExercise, { name: 'Stimuli Sequence' }],
]);

export enum DerivedResponseStatus {
  active = 'active',
  compliance = 'compliance',
  disqualified = 'disqualified',
  ready = 'ready',
  rejected = 'rejected',
  removed = 'removed',
  review = 'review',
  quotaoverlimit = 'quotaoverlimit',
  incomplete = 'incomplete',
}

export default {
  SurveyActionType: extend(surveyActionTypeMap),
  SurveyItemtype: extend(surveyItemTypeMap),
  SurveyLogicRule: extend(surveyLogicRuleMap),
  SurveyQuestionConditionType: extend(surveyConditionTypeMap),
  SurveyQuestionType: extend(surveyQuestionTypeMap),
  SurveyTaggingResultType: extend(surveyTaggingResultTypeMap),
  SurveyType: extend(surveyTypeMap),
};