import { memo } from 'react';
import { cx } from '@utils';
import styles from './style/TableHeaderCell.css';

type TableHeaderCellProps = {
  children?: React.ReactNode;
  className?: string;
  title?: string;
};

export const TableHeaderCell = memo((props: TableHeaderCellProps) => (
  <div className={cx(styles.root, props.className)}>
    {props.children || props.title}
  </div>
));

export default TableHeaderCell;