import { useCallback } from 'react';
import type { RankingQuestion } from '@/types';
import { capitalize } from '@/utils';
import {
  useGetOptionDisplayLogic,
  useGetRankingOptionLogic,
  useGetAdditionalRankingLogic,
  useQuestionLogic,
  useQuestionClassifications,
} from './hooks';
import { OptionMetadata } from './Metadata';
import { Options } from './Options';
import { SurveyQuestionLogic } from './Logic';
import { SurveySettings } from './Settings';
import { SurveyQuestionClassifications } from './Tagging';

type Props = {
  item: RankingQuestion.Question;
};

export const RankingPreview = ({ item }: Props) => {

  const questionLogic = useQuestionLogic(item.base.identifier);
  const questionClassifications = useQuestionClassifications(item.base.identifier);
  const getOptionsLogic = useGetRankingOptionLogic(questionLogic);
  const additionalLogic = useGetAdditionalRankingLogic(questionLogic);
  const getOptionDisplayLogic = useGetOptionDisplayLogic(item.base.identifier);

  const renderExtras = useCallback((option: RankingQuestion.Option) => {
    const logicItems = getOptionsLogic(option);
    const displayLogicItems = getOptionDisplayLogic(option);

    return (
      <>
        <OptionMetadata
          isAnchored={option.metadata.isAnchored}
          isExclusive={false}
          isOpenEnded={option.metadata.isOpenEnded} />
        {displayLogicItems}
        {logicItems}
      </>
    );
  }, [getOptionDisplayLogic, getOptionsLogic]);

  return (
    <>
      <Options
        item={item}
        renderOptionExtras={renderExtras} />
      <SurveySettings>
        {item.settings.orderOptionsBasedOnSource && <>Order options based on source question</>}
        {item.settings.randomization && <>Randomize options</>}
        {item.settings.minRank && <>Must rank at least {item.settings.minRank} options</>}
        {item.settings.maxRank && <>Rank at most {item.settings.maxRank} options</>}
        <>Display: {capitalize(item.settings.displayFormat)}</>
        {item.settings.dontKnow.enabled && <>Show &quot;{item.settings.dontKnow.label}&quot;</>}
      </SurveySettings>
      <SurveyQuestionLogic items={additionalLogic} />
      <SurveyQuestionClassifications items={questionClassifications} />
    </>
  );
};