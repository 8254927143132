import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTimeout } from '@utils/hooks/useTimeout';

type Props = {
  loadInDelay?: number;
  src: string;
};

export const useImageState = ({ loadInDelay, src }: Props) => {
  const handleImgLoaded = useCallback(() => {
    setImgState('loaded');
  }, []);

  const handleImgError = useCallback(() => {
    setImgState('error');
  }, []);

  const [_, setImgDelayed, clearTimer] = useTimeout(handleImgLoaded, loadInDelay);
  const [imgState, setImgState] = useState<ImgState>(null);

  useEffect(() => {
    clearTimer();
    setImgState('loading');
  }, [
    clearTimer,
    src,
  ]);

  const imgProps = useMemo(() => {
    return {
      onError: handleImgError,
      onLoad: loadInDelay ? setImgDelayed : handleImgLoaded,
      src,
    };
  }, [
    handleImgLoaded,
    handleImgError,
    loadInDelay,
    setImgDelayed,
    src,
  ]);

  return {
    props: imgProps,
    state: imgState,
  } as const;
};

type ImgState =
  'loading' |
  'error' |
  'loaded';

export default useImageState;