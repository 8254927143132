import { AxiosResponse } from 'axios';
import { useInfiniteQuery, UseInfiniteQueryOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as api from '@api';
import { Companies } from '@api/interfaces';

type FetchCompanyOverviewData = Companies.FetchOverview.Response;

export function useFetchCompanyOverview<TData = FetchCompanyOverviewData>(
  slug: string,
  options?: UseQueryOptions<FetchCompanyOverviewData, AxiosResponse, TData>
) {

  return useQuery(keyUseFetchCompanyOverview(slug), () => {
    return api.companies.fetchOverview({
      slug,
    });
  }, options);
}

export function keyUseFetchCompanyOverview(slug: string) {
  return ['company', slug];
}

type EnumerateCompanyEmployeesData = Companies.FetchEmployees.Response;

export function useEnumerateCompanyEmployees<TData = EnumerateCompanyEmployeesData>(
  slug: string,
  current: boolean,
  options?: UseInfiniteQueryOptions<EnumerateCompanyEmployeesData, AxiosResponse, TData>
) {

  return useInfiniteQuery(keyUseEnumerateCompanyEmployees(slug, current), ctx => {
    return api.companies.fetchEmployees({
      slug,
      current,
      cursor: +(ctx.pageParam ?? 1),
    });
  }, options);
}

export function keyUseEnumerateCompanyEmployees(slug: string, current: boolean) {
  return ['company', slug, current ? 'current-employees' : 'former-employees'];
}