import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { cx } from '@utils';
import { ConferenceSessionRecordProps } from './interfaces';
import styles from './style/Record.css';

type Props =
    ConferenceSessionRecordProps;

export default function Record(props: Props) {
  if (!props.visible) return null;

  return (
    <div className={cx(styles.root, props.className)}>
      <div className={styles.wrap}>
        <div className={styles.rec}>REC</div>
        <div className={styles.icon}>
          <FiberManualRecordIcon />
        </div>
      </div>
    </div>
  );
}