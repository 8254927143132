import { useCallback, memo } from 'react';
import VisibilityOnIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { cx } from '@utils';
import { Select, SelectOptionProps } from '@/components/Select';
import styles from './style/Visibility.css';

type Props = {
  className?: string;
  value: number;
  disabled?: boolean;
  visible?: boolean;
  onSelect: (value: SelectOptionProps) => void;
};

export const Visibility = memo(({ className, value, onSelect, visible = true, disabled = false }: Props) => {
  const getOptions = useCallback((): SelectOptionProps[] => {
    return [
      VisibleToAttendeesOption,
      HiddenFromAttendeesOption,
    ];
  }, []);

  if (!visible) return null;

  const options = getOptions();
  const valueOption = options.find(o => o.id === value);
  const isVisibleOption = value === VisibleToAttendeesOption.id;

  return (
    <div className={cx(className)}>
      <div className={styles.header}>
        <div className={styles.optionLabel}>Visibility</div>
        <div className={cx(styles.visibility, { [styles.off]: !isVisibleOption })}>
          {isVisibleOption ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
        </div>
      </div>
      <div>
        {disabled
          ? (
            <div className={styles.optionDisabled}>
              {valueOption?.name}
            </div>
          )
          : (
            <Select
              className={styles.select}
              value={valueOption?.name}
              getItemValue={x => x.name}
              onSelect={onSelect}
              options={options} />
            )}
      </div>
    </div>
  );
});

export const HiddenFromAttendeesOption: SelectOptionProps = { id: 1, name: 'Hidden from Attendees' };
export const VisibleToAttendeesOption: SelectOptionProps = { id: 2, name: 'Visible to Attendees' };