import { useCallback, useMemo, useState } from 'react';
import type { SurveyFiltering } from '@/types';
import { ProjectResponseFilterContext } from './Context';

type Props = {
  children: React.ReactNode;
};

export const SurveyResponseFilterContainer = (props: Props) => {
  const [filter, setFilter] = useState<SurveyFiltering.FilterEntries>({ filters: [] });

  const resetFilter = useCallback(() => {
    setFilter({ filters: [] });
  }, []);

  const filterContextValue = useMemo(() => ({
    filter,
    setFilter,
    resetFilter,
  }), [filter, resetFilter]);

  return (
    <ProjectResponseFilterContext.Provider value={filterContextValue}>
      {props.children}
    </ProjectResponseFilterContext.Provider>
  );
};

SurveyResponseFilterContainer.displayName = 'Project.Survey.ResponseFilter.Container';