import { useState } from 'react';
import { useSurveyThemingPalette } from '@containers/Branding/hooks/useSurveyThemingPalette';
import { Button } from '@/components/Button';
import { ButtonColor } from '@/components/Button/interfaces';
import { CheckboxLabel } from '@/components/Checkbox';
import styles from './style/Compliance.SurveyTerms.css';

type Props = {
  onSubmit: () => void;
};

export const SurveyTerms = (props: Props) => {
  const [accepted, setAccepted] = useState<boolean>(false);
  const { palette, theme } = useSurveyThemingPalette();

  const btn = {
    color: theme ? `transparent` : `affirmative` as ButtonColor,
    style: theme ? {
      backgroundColor: accepted ? palette.primary.main : `var(--gray-l)`,
      color: accepted ? palette.primary.text : `var(--pri-01)`,
    } : {},
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.scroll}>
          <div className={styles.title}>Terms of Engagement</div>
          <div className={styles.copy}>
            <p>
              Sentiment connects investment and business professionals for an exchange on a particular
              subject. To ensure that all such exchanges take place within a secure and compliance
              environment, we ask that all participants adhere to our Terms and Privacy Policy which
              govern every interaction with our clients.
            </p>
            <p>
              We therefore ask you to review our Terms and Privacy Policy and only accept them provided
              you can additionally confirm the following principles of professional conduct in relation to
              this interaction.
            </p>
          </div>
          <div className={styles.subtitle}>Compliance Terms</div>
          <ul>
            <li>
              My participation as an individual is permissible and does not violate any obligations owed
              to third parties.
            </li>
            <li>
              My participation in an interaction with this particular scope is appropriate for me, does not
              violate any law or regulation and does not give rise to any conflict. If during an interaction I
              determine a conflict, I will discontinue my participation.
            </li>
            <li>
              I will not disclose information that is relatable to any company of which I am currently an
              officer director or employee.
            </li>
            <li>
              I will not disclose any material non-public information concerning a company whose securities are
              traded on a recognized exchange or a quoted instrument or other information I have a duty to keep
              confidential or have obtained from a person who expects me to keep such information confidential or
              could reasonably expect to be considered confidential.
            </li>
            <li>I will not give investment legal medical or other professional advice in the context of the interaction.</li>
            <li>
              I agree not to disclose my participation in the interaction, the subject matter or the identity
              and details relating to the client to any third-party, or to use, benefit from, ordeal based on any
              information that becomes known to me in the context of the interaction.
            </li>
            <li>
              I confirm that any written material that I provide to the client in the context of an
              interaction will be fully owned or licensed by me.
            </li>
            <li>I agree that Sentiment may use my information to facilitate my participation in interactions.</li>
          </ul>
          <div className={styles.checkbox}>
            <CheckboxLabel
              checked={accepted}
              label="I understand and agree to the Terms and Privacy policy."
              onChange={() => setAccepted(!accepted)} />
          </div>
        </div>
        <div className={styles.navigation}>
          <Button
            className={styles.submit}
            color={btn.color}
            disabled={!accepted}
            onClick={props.onSubmit}
            style={btn.style}
            variant="brick">
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

SurveyTerms.displayName = 'Compliance.SurveyTerms';