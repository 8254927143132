import { useSelectUser } from '@containers/Store';
import { getLocationFor } from '@utils';
import { UserAvatarProps } from './interfaces';
import { UserAvatar } from './UserAvatar';

type Props = {
  disableLink?: boolean;
} & Omit<UserAvatarProps, 'pictureUrl' | 'to'>;

export const MyAvatar = ({ disableLink, ...props }: Props) => {
  const me = useSelectUser();

  const url = getLocationFor.user.profile({
    slug: me.profile.slug,
  });

  return (
    <UserAvatar
      to={disableLink ? undefined : url}
      pictureUrl={me.profile.pictureUrl}
      {...props} />
  );
};

export default MyAvatar;