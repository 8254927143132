import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@actions';
import type { Call } from '@/types';
import {
  useAdHocCallSchedulingNotifier,
  useProjectCallSchedulingNotifier,
} from '@containers/Scheduler/Notifier';
import { Calendar } from '@/components/Calendar/Calendar';
import { Viewing } from './Viewing.js';

const mapState = (state: Store.State) => ({
  group: state.group,
  calls: state.calls,
  user: state.user,
});

type Props = {
  className?: string;
};

const Personal = ({
  className = '',
}: Props) => {
  const dispatch = useDispatch();
  const state = useSelector(mapState);
  const location = useLocation();
  const history = useHistory();

  const adHocCallNotifier = useAdHocCallSchedulingNotifier();
  const projectCallNotifier = useProjectCallSchedulingNotifier();

  const callUpdated = (data: { call: Call }) => {
    dispatch(actions.callUpdated(data));
  };

  return (
    <Calendar>
      <Viewing
        group={state.group}
        calls={state.calls}
        className={className}
        history={history}
        location={location}
        updateCall={callUpdated}
        user={state.user}
        adHocCallNotifier={adHocCallNotifier}
        projectCallNotifier={projectCallNotifier}
      />
    </Calendar>
  );
};

export { Personal };
export default Personal;