import { useLocation, useParams } from 'react-router-dom';
import { RecordingConsent } from '@screens/Scheduling';
import { device } from '@utils';
import { ProjectCallConfirmationCalendar } from './ProjectCall.ConfirmationCalendar';
import { ProjectCallConfirmationStepper } from './ProjectCall.ConfirmationStepper';
import { useRecordingConsentStatusId } from './hooks';

type Params = {
  callId: string;
  projectId: string;
};

type LocationState = {
  scheduleeId: number;
  schedulerId: number;
};

/**
 * @summary  Confirm a timeslot from a participant's provided availability
 * @param scheduleeId Current user
 * @param schedulerId Participant's calendar
 * @param ...rest
**/

const ProjectCallSchedulingConfirmation = () => {
  const params = useParams<Params>();
  const location = useLocation<LocationState>();

  const callId = +params.callId;
  const projectId = +params.projectId;
  const { scheduleeId, schedulerId } = location.state;

  const [recordingConsentStatusId, onRecordingConsentChange] = useRecordingConsentStatusId(callId);

  const ScreenContainer = device.phone
      ? ProjectCallConfirmationStepper
      : ProjectCallConfirmationCalendar;

  return (
    <RecordingConsent
      callId={callId}
      projectId={projectId}
      onChange={onRecordingConsentChange}>
      <ScreenContainer
        callId={callId}
        projectId={projectId}
        recordingConsentStatusId={recordingConsentStatusId}
        scheduleeId={scheduleeId}
        schedulerId={schedulerId} />
    </RecordingConsent>
  );
};

export { ProjectCallSchedulingConfirmation };
export default ProjectCallSchedulingConfirmation;