import * as ArticleSkeleton from '@/components/Post.Article/Skeleton';
import styles from './style/PostArticle.css';

type Props = unknown;

export const Skeleton = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <ArticleSkeleton.CoverImage />
          <ArticleSkeleton.ArticleTitle />
          <ArticleSkeleton.Author />
          <ArticleSkeleton.ArticleBody />
        </div>
      </div>
    </div>
  );
};

Skeleton.displayName = 'Post.Article.Skeleton';