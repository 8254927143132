import { useCallback } from 'react';
import type { ChainedFromExtensions } from '@remirror/core';
import { useChainedCommands } from '@remirror/react';
import type { SurveyRichText } from '@/types/survey';
import type { FindAndReplaceExtension } from '../Extension.FindAndReplace';

export function useAddFindAndReplaceNode() {
  const ctxChain = useChainedCommands<FindAndReplaceExtension>();

  const add = useCallback((attrs: SurveyRichText.FindAndReplaceNode['attrs'], chain: ChainedFromExtensions<FindAndReplaceExtension> = ctxChain) => {
    chain
      .addFindAndReplaceNode(attrs)
      .focus()
      .run();
  }, [ctxChain]);

  return add;
}