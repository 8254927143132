import { memo, Fragment } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { cx } from '@utils';
import styles from './style/Skeleton.css';

type Props = {
  className?: string;
  rows: number;
};

export const Entities = memo((props: Props) => {
  return (
    <div className={cx(styles.entities, props.className)}>
      {Array.from({ length: props.rows }).map((_, i) =>
        <Fragment key={i}>
          <Row index={0} />
          <Row index={1} />
          <Row index={2} />
        </Fragment>)}
    </div>
  );
});

Entities.displayName = 'Transcript.Skeleton.Entities';

const widths = [
  ['100px', '140px', '90px'],
  ['160px', '130px', '60px'],
  ['150px', '100px', '75px'],
];

type RowProps = {
  index: 0 | 1 | 2;
};

const Row = memo((props: RowProps) => {
  const width = widths[props.index];

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        <Skeleton
          sx={{ fontSize: '28px', width: width[0] }}
          variant="text" />
      </div>
      <div className={styles.col}>
        <Skeleton
          sx={{ fontSize: '28px', width: width[1] }}
          variant="text" />
      </div>
      <div className={styles.col}>
        <Skeleton
          sx={{ fontSize: '28px', width: width[2] }}
          variant="text" />
      </div>
    </div>
  );
});

Row.displayName = 'Transcript.Skeleton.Entities.Row';