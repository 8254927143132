import { useMemo } from 'react';
import type { SurveyLogic } from '@/types/survey';
import { useSurveyLogicBuilderContext } from '@/containers/SurveyBuilder.Logic';
import type { ContextMenuItemProps } from '@/components/SurveyBuilder/ContextMenu.Item';
import { SurveyBuilderContextMenu } from '@/components/SurveyBuilder/ContextMenu';

type Props = {
  className?: string;
  item: SurveyLogic.Item;
};

export const LogicItemContextMenu = ({ className, item }: Props) => {

  const {
    cloneItem,
    editing,
    removeItem,
    toggleEditingOn,
  } = useSurveyLogicBuilderContext();

  const clone = useMemo(() => {

    return {
      children: 'Clone',
      disabled: false,
      onClick: () => cloneItem(item.identifier),
    };

  }, [cloneItem, item.identifier]);

  const editItem = useMemo(() => {

    const canEdit = item.metadata.canEdit &&
      !editing.identifier;

    return {
      children: 'Edit',
      disabled: !canEdit,
      onClick: () => toggleEditingOn(item.identifier),
    };

  }, [editing, item.identifier, item.metadata.canEdit, toggleEditingOn]);

  const deleteItem = useMemo(() => {

    const canDelete = item.metadata.canDelete;

    return {
      children: 'Delete',
      disabled: !canDelete,
      onClick: () => removeItem(item.identifier),
    };
  }, [item.metadata.canDelete, removeItem, item.identifier]);

  const items: ContextMenuItemProps[] = useMemo(() => {

    return [
      editItem,
      clone,
      deleteItem,
    ].filter(Boolean);
  }, [clone, editItem, deleteItem]);

  return (
    <SurveyBuilderContextMenu
      className={className}
      items={items} />
  );
};