import m1 from 'static/images/website/q2q/qnq-video-call.png';
import m2 from 'static/images/website/customer-research/call-recording-desktop.png';
import m3 from 'static/images/website/customer-research/tagging-product-card-desktop.png';
import m4 from 'static/images/website/customer-research/time-series-product-card-desktop.png';
import styles from './style/Services.css';

type Props = unknown;

export const Services = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          {items.map(x =>
            <div
              className={styles.row}
              key={x.title}>
              <div
                className={styles.item}>
                <div className={styles.box}>
                  <img
                    alt={x.title}
                    className={styles.img}
                    src={x.src} />
                </div>
                <div className={styles.box}>
                  <div className={styles.title}>{x.title}</div>
                  <div className={styles.text}>{x.text}</div>
                </div>
              </div>
            </div>)}
        </div>
      </div>
    </div>
  );
};

Services.displayName = 'CustomerResearch.Services';

const items = [{
  title: `Conduct video interviews with customers`,
  text: `Collect qualitative feedback from customers through an embedded video conferencing solution.`,
  src: m1,
}, {
  title: `Access call recordings and transcriptions`,
  text: `Revisit and share the insights from conversations with real time call recordings and transcriptions.`,
  src: m2,
}, {
  title: `Segment your customers with custom tags`,
  text: `Utilize our tagging tools to segment your customers into lists and engage them in followup studies.`,
  src: m3,
}, {
  title: `Monitor customer preferences over time`,
  text: `Run time series surveys to track customer preferences in real time and adapt quickly to changes in the market.`,
  src: m4,
}];