import { useState, useEffect } from 'react';

export const useChartAnimation = () => {
  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    setTimeout(() =>
    {
      setAnimate(true);
    }, 0);
  }, []);

  return animate;
};