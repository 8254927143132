import { memo, Fragment } from 'react';
import { cx } from '@utils';
import styles from './style/Metadata.css';

type Props = {
  classes?: {
    root?: string;
    red?: boolean;
  };
  items: React.ReactNode[];
};

export const SurveyMetadata = memo((props: Props) => {

  if (!props.items.length) return null;

  const className = cx(styles.root, props.classes?.root, {
    [styles.red]: props.classes?.red,
  });

  return (
    <span className={className}>
      ({props.items.map((m, i) => (
        <Fragment key={i}>
          {m}
          {i !== props.items.length - 1 && <>, </>}
        </Fragment>
      ))})
    </span>
  );
});

type OptionProps = {
  isAnchored?: boolean;
  isExclusive?: boolean;
  isOpenEnded?: boolean;
  isReadOnly?: boolean;
};

export const OptionMetadata = memo((props: OptionProps) => {
  const items = [
    props.isExclusive && 'Exclusive',
    props.isAnchored && 'Anchored',
    props.isOpenEnded && 'Open-ended',
    props.isReadOnly && 'Read Only',
  ].filter(Boolean);

  return (
    <SurveyMetadata items={items} />
  );
});