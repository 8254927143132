import { memo } from 'react';
import { cx } from '@utils';
import styles from './style/ProgressRing.css';

type Props = {
  backgroundColor?: string;
  className?: string;
  progress: number;
  progressColor?: string;
  size: number;
  strokeWidth?: number;
};

export const ProgressRing = memo(({
  backgroundColor = 'var(--gray-xl)',
  className,
  progress,
  progressColor = 'var(--pri-02)',
  size,
  strokeWidth = 3,
}: Props) => {

  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;

  const offset = (((100 - (progress || 0)) / 100) * circumference);

  return (
    <div className={cx(styles.root, className)}>
      <svg
        height={size}
        width={size}>
        <circle
          className={styles.circle}
          stroke={backgroundColor}
          strokeWidth={strokeWidth}
          r={radius}
          cx={center}
          cy={center} />
        <circle
          className={styles.circle}
          stroke={progressColor}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap="round"
          r={radius}
          cx={center}
          cy={center} />
      </svg>
    </div>
  );
});

export default ProgressRing;