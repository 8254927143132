import { useEffect } from 'react';
import styles from './style/Inbound.css';

type  TipaltiCallbackMessage = {
  SentimentInfo: {
    success: boolean;
  };
  caller: string;
};

interface WindowOpener {
  postMessage: (message: TipaltiCallbackMessage, targetOrigin: string) => void;
}

export default function Tipalti() {
  useEffect(() => {
    const message = {
      SentimentInfo: {
        success: true,
      },
      caller: 'TipaltiInbound',
    };
    (window.opener as WindowOpener).postMessage(message, '*');
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.redirecting}>
        Loading...
      </div>
    </div>
  );
}

export { Tipalti };