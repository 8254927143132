import { memo } from 'react';

type Props = {
  className?: string;
  size?: number;
};

export const AccelerateIcon = memo(({ className, size = 40 }: Props) => {

  return (
    <svg
      className={className}
      width={size}
      height={size}
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 32 32"
      enableBackground="new 0 0 32 32"
      xmlSpace="preserve">
      <path
id="natural--language--understanding"
d="M14.36,30V14c0-0.199-0.161-0.36-0.36-0.36H1c-0.199,0-0.36,0.161-0.36,0.36v16
	c0,0.199,0.161,0.36,0.36,0.36h13C14.199,30.36,14.36,30.199,14.36,30z M1.36,14.36h12.28v15.28H1.36V14.36z M31.36,29v-3
	c0-0.199-0.161-0.36-0.36-0.36h-9c-0.199,0-0.36,0.161-0.36,0.36v3c0,0.199,0.161,0.36,0.36,0.36h9
	C31.199,29.36,31.36,29.199,31.36,29z M22.36,26.36h8.279v2.279H22.36V26.36z M12,25.64H3v0.721h9V25.64z M25.36,23v-3
	c0-0.199-0.161-0.36-0.36-0.36h-7c-0.199,0-0.36,0.161-0.36,0.36v3c0,0.199,0.161,0.36,0.36,0.36h7
	C25.199,23.36,25.36,23.199,25.36,23z M18.36,20.36h6.279v2.279H18.36V20.36z M12,21.64H3v0.721h9V21.64z M12,17.641H3v0.721h9
	V17.641z M31.36,17v-3c0-0.199-0.161-0.36-0.36-0.36h-9c-0.199,0-0.36,0.161-0.36,0.36v3c0,0.199,0.161,0.36,0.36,0.36h9
	C31.199,17.36,31.36,17.199,31.36,17z M22.36,14.36h8.279v2.28H22.36V14.36z M31.36,11V8c0-0.199-0.161-0.36-0.36-0.36h-9
	c-0.199,0-0.36,0.161-0.36,0.36v3c0,0.199,0.161,0.36,0.36,0.36h9C31.199,11.36,31.36,11.199,31.36,11z M22.36,8.36h8.279v2.28
	H22.36V8.36z M14.509,4l-3.255,3.254l-0.509-0.509l2.385-2.386H5C4.096,4.36,3.36,5.096,3.36,6v5H2.64V6
	c0-1.301,1.059-2.36,2.36-2.36h8.131l-2.385-2.386l0.509-0.509L14.509,4z M25.36,5V2c0-0.199-0.161-0.36-0.36-0.36h-7
	c-0.199,0-0.36,0.161-0.36,0.36v3c0,0.199,0.161,0.36,0.36,0.36h7C25.199,5.36,25.36,5.199,25.36,5z M18.36,2.36h6.279v2.28H18.36
	V2.36z" />
      <rect
        fill="none"
        id="_Transparent_Rectangle"
        width="32"
        height="32" />
    </svg>
  );
});