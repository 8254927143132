import { useContext } from 'react';
import { AppBarContext } from './Context';

export const Banner = () => {
  const ctx = useContext(AppBarContext);

  const toggle = () => {
    setTimeout(() => {
      if (ctx.root.ref?.current) {
        const height = ctx.root.ref.current.clientHeight;
        const root = document.documentElement;
        root.style.setProperty('--navbar-height', `${height}px`);
      }
    }, 5);
  };

  return (
    <></>
  );
};

Banner.displayName = 'Navbar.Banner';