import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';
import type { ExternalSourcingVendor } from '@/enums';
import { cx } from '@/utils';
import type {
  ExternalSourcingConfigurationFormValues,
  ExternalSourcingConfigurationDisplay,
  ExternalSourcingConfigurationForm,
} from '@containers/Project.ExternalSourcing';
import { FieldText } from './Field';
import styles from './style/Section.Settings.Survey.ExternalSourcing.css';
import rootStyles from './style.css';

export const OffPlatformSourcingSagoDisplay = (untyped: ExternalSourcingConfigurationDisplay) => {
  const props = untyped as ExternalSourcingConfigurationDisplay<ExternalSourcingVendor.Sago>;

  return (
    <>
      <div className={rootStyles.section100}>
        <div className={rootStyles.label}>Sago Secret</div>
        <div>{props.metadata.secret}</div>
      </div>
      <div className={rootStyles.section50}>
        <div className={rootStyles.label}>Append Vendor User ID to User Links</div>
        <div>{props.appendVidToLinks ? 'Yes' : 'No'}</div>
      </div>
      <div className={rootStyles.section100}>
        <div className={cx(styles.label, styles.link)}>Completion Template Link</div>
        <div className={styles.value}>{props.completionUrl}</div>
      </div>
      <div className={rootStyles.section100}>
        <div className={cx(styles.label, styles.link)}>Disqualify Template Link</div>
        <div className={styles.value}>{props.disqualifyUrl}</div>
      </div>
      <div className={rootStyles.section100}>
        <div className={cx(styles.label, styles.link)}>Overquota Template Link</div>
        <div className={styles.value}>{props.overquotaUrl}</div>
      </div>
    </>
  );
};

export const OffPlatformSourcingSagoForm = (props: ExternalSourcingConfigurationForm) => {
  const form = useFormikContext<ExternalSourcingConfigurationFormValues<ExternalSourcingVendor.Sago>>();

  useEffect(() => {
    form.setFieldValue('appendVidToLinks', true);
    props.setConfigurationSchema(getValidationSchema());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={rootStyles.section100}>
        <FieldText
          editing={true}
          label="Sago Secret"
          name="metadata.secret"
          displayValue={form.values.metadata.secret} />
      </div>
    </>
  );
};

function getValidationSchema() {
  return Yup.object().shape({
    label: Yup.string().required('Label is required'),
    rate: Yup.number().min(0, 'Rate must be a positive number').required('Rate is required'),
    metadata: Yup.object({
      secret: Yup.string().required('Sago secret is required'),
    }),
  });
}