import { ProjectType } from '@enums';
import { GoalInput } from '@/components/GroupProject';
import { Field } from './Overview.Field';
import styles from './style/Overview.css';

type Props = {
  label:        string;
  onChange:     (value: number) => unknown;
  tooltip?:     string;
  projectType:  ProjectType;
  value:        number;
};

export const Goal = (props: Props) => {

  return (
    <Field
      label={props.label}
      tooltip={props.tooltip}>
      <div className={styles.type}>
        <GoalInput
          onChange={props.onChange}
          type={props.projectType}
          value={props.value} />
      </div>
    </Field>
  );
};

Goal.displayName = 'Project.Creation.Details.Field.Goal';