import { useCallback } from 'react';
import { SurveyTemplate } from '@/types';
import { Input, NumberInput } from '@/components/Input';
import styles from './style/Input.MT-MaxDiff.css';

type Props = {
  onChange: <T extends keyof SurveyTemplate.MessageTesting.MaxDiff = keyof SurveyTemplate.MessageTesting.MaxDiff, V = SurveyTemplate.MessageTesting.MaxDiff[T]>(key: T) => (value: V) => void;
  value: SurveyTemplate.MessageTesting.MaxDiff;
};

export const MaxDiffInputs = ({ onChange, value }: Props) => {

  return (
    <>
      <div className={styles.row}>
        <div className={styles.label}>Dimension</div>
        <Input
          placeholder="Enter Dimension"
          onChange={e => onChange('dimension')(e.target.value)}
          value={value.dimension} />
      </div>
      <div className={styles.row}>
        <div className={styles.label}># of Sets</div>
        <NumberInput
          allowLeadingZeros={false}
          allowNegative={false}
          placeholder="Enter sets"
          onValueChange={values => onChange('sets')(values.floatValue)}
          value={value.sets} />
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Attributes per set</div>
        <NumberInput
          allowLeadingZeros={false}
          allowNegative={false}
          placeholder="Enter attributes"
          onValueChange={values => onChange('attributesPerSet')(values.floatValue)}
          value={value.attributesPerSet} />
      </div>
    </>
  );
};