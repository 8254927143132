import { useCallback } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@presentation/Tooltip';
import { useConferenceAudioCuePreferences, AudioCuePreferences } from '@/components/Conference/hooks/useConferenceAudioCuePreferences';
import { Switch } from '@/components/Switch';
import styles from './styles/Settings.css';

export const Settings = () => {
  const [audioCuePreferences, updateAudioCuePreferences] = useConferenceAudioCuePreferences();

  return (
    <div className={styles.root}>
      <div className={styles.header}>Settings</div>
      <div className={styles.row}>
        <div>Play sound on new message:</div>
        <Switch
          active={audioCuePreferences.playNewMessageSound}
          onClick={active => {
            updateAudioCuePreferences({
              playNewMessageSound: active,
            });
          }} />
      </div>
      <div className={styles.row}>
        <div>Play sound on waiting room join:</div>
        <Switch
          active={audioCuePreferences.playWaitingRoomEntry}
          onClick={active => {
            updateAudioCuePreferences({
              playWaitingRoomEntry: active,
            });
          }} />
      </div>
      <div className={styles.row}>
        <div className={styles.settingText}>Use outgoing audio filter:
          <Tooltip title={'Reduces background mic noise and echoing.'}>
            <InfoIcon className={styles.helpIcon} fontSize='small' />
          </Tooltip>
        </div>
        <Switch
          active={!audioCuePreferences.disableAudioFilter}
          onClick={active => {
            updateAudioCuePreferences({
              disableAudioFilter: !active,
            });
          }} />
      </div>
    </div>
  );
};