import { useMemo } from 'react';
import { path } from '@consts';
import type { SceneMap } from './interfaces';
import { RouteKey } from './interfaces';
import { FolderTabDetails } from './Tab.Details';
import { FolderTabFiles } from './Tab.Files';

export const useFolderTabsMap = () => {

  const map: SceneMap = useMemo(() => ({
    [RouteKey.Files]: {
      component: FolderTabFiles,
      path: path.WorkspaceFolderTabs.Files,
      title: 'Files',
    },
    [RouteKey.Details]: {
      component: FolderTabDetails,
      path: path.WorkspaceFolderTabs.Details,
      title: 'Details',
    },
  }), []);

  return map;
};