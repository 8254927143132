import { AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as API from '@api/interfaces';
import * as api from '@api';

type Data = API.Projects.FetchMultiSurveyResponses.Response;
type QueryKey = [string, Props];
type Props = {
  limit?: number;
  projectId: number;
  questionId?: number;
};

export function useFetchAllProjectSurveyResponses<TData = Data>(
  props: Props,
  options?: UseQueryOptions<Data, AxiosResponse, TData, QueryKey>
) {

  return useQuery(keyUseFetchAllSurveyResponses(props), ({ queryKey }) => {
    const params = queryKey[1] as Props;
    return api.projects.surveys.fetchAllResponses(params);
  }, options);
}

function keyUseFetchAllSurveyResponses(props: Props) {
  return ['project-all-survey-responses', props];
}

export default useFetchAllProjectSurveyResponses;