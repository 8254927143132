import { SurveyQuestionType } from '@enums';
import {
  SurveyQuestion,
} from '@/types';
import { Validation } from '../interfaces';
import * as surveyBuilder from './question';
import { returnAssertFailure } from './validation.base';

export function validateQuestion(question: SurveyQuestion): Validation.ValidateQuestionResult {
  if (!question.typeId) {
    return returnAssertFailure({ message: 'No question type given.', success: false }, question);
  }

  const typeId = question.typeId;
  switch (typeId) {
    case SurveyQuestionType.MultipleChoice:
      return surveyBuilder.multipleChoice.validateQuestion(question);

    case SurveyQuestionType.ShortTextResponse:
      return surveyBuilder.shortText.validateQuestion(question);

    case SurveyQuestionType.LongTextResponse:
      return surveyBuilder.longText.validateQuestion(question);

    case SurveyQuestionType.Multiselect:
      return surveyBuilder.multiselect.validateQuestion(question);

    case SurveyQuestionType.Ranking:
      return surveyBuilder.ranking.validateQuestion(question);

    case SurveyQuestionType.MatrixGrid:
      return surveyBuilder.matrixGrid.validateQuestion(question);

    case SurveyQuestionType.Sliders:
      return surveyBuilder.matrixSlider.validateQuestion(question);

    case SurveyQuestionType.ConjointAnalysis:
      return surveyBuilder.conjointAnalysis.validateQuestion(question);

    case SurveyQuestionType.MaxDifference:
      return surveyBuilder.maxDiff.validateQuestion(question);

    case SurveyQuestionType.MatrixMultiselect:
      return surveyBuilder.matrixMultiselect.validateQuestion(question);

    case SurveyQuestionType.NumberInputTable:
      return surveyBuilder.numberInputTable.validateQuestion(question);

    case SurveyQuestionType.MultiTextbox:
      return surveyBuilder.multitextbox.validateQuestion(question);

    case SurveyQuestionType.ExclusiveOptions:
      return surveyBuilder.exclusiveOptions.validateQuestion(question);

    case SurveyQuestionType.Dropdown:
      return surveyBuilder.dropdown.validateQuestion(question);

    case SurveyQuestionType.MatrixRange:
      return surveyBuilder.matrixRange.validateQuestion(question);

    case SurveyQuestionType.ImageMarkup:
      return surveyBuilder.imageMarkup.validateQuestion(question);

    default:
      throw new UnreachableCaseError(typeId);
  }
}