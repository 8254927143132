import { UseDownloaderOptions, useQueryDownloader } from '@containers/QueryDownloader';
import * as api from '$admin/api';
import * as API from '$admin/api/interfaces';

type Params = API.Billing.DownloadInvoices.Request;

export const useDownloadBillingInvoices = (params: Params, options: UseDownloaderOptions<API.Billing.DownloadInvoices.Response> = {}) => {
  return useQueryDownloader({
    queryKey: [
      `get:admin/billing/invoices/download`,
      params,
    ],
    queryFn: () => {
      return api.downloadInvoices({
        format: params.format,
        ids: params.ids,
      });
    },
    ...options,
    meta: {
      ...options.meta,
      downloader: true,
    },
    downloaderOptions: {
      onResponse: res => ({
        filename: res.filename ?? params.format === 'xero' ? `Invoices Export.csv` : `Invoices Export.${params.format}`,
        type: 'blob',
        value: res.blob,
      }),
    },
  });
};