import { useFetchWorkbookData } from './hooks';
import { WorkbookDataContext, WorkbookDataLoadingContext } from './Context';

type Props = {
  url: string;
} & ChildrenProps;

export const WorkbookContainer = ({ children, url }: Props) => {

  const [response] = useFetchWorkbookData(url);

  return (
    <WorkbookDataContext.Provider value={response.value}>
      <WorkbookDataLoadingContext.Provider value={response.loading}>
        {children}
      </WorkbookDataLoadingContext.Provider>
    </WorkbookDataContext.Provider>
  );
};

export default WorkbookContainer;