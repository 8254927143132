import * as api from '@api';
import type * as API from '@api/interfaces';
import type { UseDownloaderOptions } from '@containers/QueryDownloader/hooks';
import { useQueryDownloader } from '@containers/QueryDownloader/hooks';

type Params = API.Calls.DownloadCallChatLog.Request;

export const useDownloadCallChatReport = (params: Params, options: UseDownloaderOptions<API.Calls.DownloadCallChatLog.Response> = {}) => {
  return useQueryDownloader({
    queryKey: [
      `get:calls/chat-log/report`,
      params,
    ],
    queryFn: () => {
      return api.calls.downloadCallChatLog({
        callId: params.callId,
        format: params.format,
      });
    },
    downloaderOptions: {
      onResponse: res => ({
        type: 'websocket',
        value: res.websocketKey,
      }),
    },
    ...options,
  });
};