import { useSurveyLogicItemSaveContext } from '@containers/SurveyBuilder.Logic';
import { FormButtons } from '@presentation/FormButtons';
import styles from './style/Builder.css';

export const LogicFormButtons = () => {

  const ctx = useSurveyLogicItemSaveContext();

  return (
    <FormButtons
      className={styles.btns}
      disabled={!ctx.canSave}
      onSubmit={ctx.handleSubmit}
      onCancel={ctx.handleCancel} />
  );
};