import type { Ref } from 'react';
import { forwardRef, useCallback, Fragment } from 'react';
import type { PopperProps } from '@mui/material/Popper';
import Popper from '@mui/material/Popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { Link } from 'react-router-dom';
import { bindToggle, bindPopper } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { Brand as BrandIcon } from '@/components/icons';
import { PopperMenu, PopperMenuItem } from '@/components/Popper';
import { MoreHorizontalAnchorSmall } from '@presentation/Anchor';
import { getLocationFor, trunc } from '@utils';
import type { WorkspaceObject } from '@/types';
import { WorkspaceObjectType } from '@/enums';
import styles from './style/Breadcrumbs.css';

type MenuProps = {
  items: WorkspaceObject[];
  onClose: () => unknown;
  renderItem?: (item: WorkspaceObject) => JSX.Element;
};

const ObjectPathMenu = forwardRef(({ items, onClose, ...props }: MenuProps, ref: Ref<HTMLDivElement>) => {

  const generateFolderPath = useCallback((item: WorkspaceObject) => {
    return getLocationFor.workspaces.folder({
      folderId: item.entityId,
    });
  }, []);

  const generateBrandPath = useCallback((item: WorkspaceObject) => {
    return getLocationFor.workspaces.brand({
      brandId: item.entityId,
    });
  }, []);

  const renderDefaultItemContent = useCallback((item: WorkspaceObject) => {
    if (item.typeId === WorkspaceObjectType.Brand) {
      return (
        <PopperMenuItem className={styles.item}>
          <BrandIcon />
          {trunc(item.name, 50)}
        </PopperMenuItem>
      );
    }

    return (
      <PopperMenuItem className={styles.item}>
        {trunc(item.name, 50)}
      </PopperMenuItem>
    );
  }, []);

  const getItemLocation = useCallback((item: WorkspaceObject) => {
    if (item.typeId === WorkspaceObjectType.Brand) {
      return generateBrandPath(item);
    } else if (item.typeId === WorkspaceObjectType.Folder) {
      return generateFolderPath(item);
    } else if (item.typeId === WorkspaceObjectType.ProjectParent) {
      return getLocationFor.project.files({ id: item.entityId });
    }

    return generateFolderPath(item);
  }, [
    generateBrandPath,
    generateFolderPath,
  ]);

  const renderItem = useCallback((item: WorkspaceObject) => {
    if (props.renderItem) {
      return (
        <div onClick={onClose}>
          {props.renderItem(item)}
        </div>
      );
    }

    const to = getItemLocation(item);

    return (
      <Link
        to={to}
        onClick={onClose}>
        {renderDefaultItemContent(item)}
      </Link>
    );

  }, [
    renderDefaultItemContent,
    getItemLocation,
    onClose,
    props,
  ]);

  return (
    <PopperMenu ref={ref}>
      {items.map(item => (
        <Fragment key={item.id}>
          {renderItem(item)}
        </Fragment>
      ))}
    </PopperMenu>
  );
});

type Props = {
  items: WorkspaceObject[];
  renderItem?: (item: WorkspaceObject) => JSX.Element;
} & Pick<PopperProps, 'placement'>;

export const ObjectPathPopper = ({
  renderItem,
  items,
  placement = 'bottom-end',
}: Props) => {

  const popupState = usePopupState({
    popupId: 'object-path-popper',
    variant: 'popper',
  });

  return (
    <div>
      <div {...bindToggle(popupState)}>
        <MoreHorizontalAnchorSmall open={popupState.isOpen} />
      </div>
      <Popper
        {...bindPopper(popupState)}
        placement={placement}
        style={{ zIndex: 2 }}>
        <ClickAwayListener
          onClickAway={popupState.close}>
          <ObjectPathMenu
            items={items}
            renderItem={renderItem}
            onClose={popupState.close} />
        </ClickAwayListener>
      </Popper>
    </div>
  );
};