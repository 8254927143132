import type { ImageMarkupQuestion } from '@/types/survey';
import { getResponsePipeNodeText } from '@/containers/SurveyBuilder/utils/rich-text.parse';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder';
import styles from './style/ImageMarkup.css';

type Props = {
  item: ImageMarkupQuestion.Question;
};

export const ImageMarkupPreview = ({ item }: Props) => {
  const [state] = useSurveyBuilderState();
  if (typeof item.settings.initialImage !== 'string') {
    return <div>Image Piped From: {getResponsePipeNodeText(item.settings.initialImage, state.survey)}</div>;
  } else {
    return (
      <>
        <img className={styles.image} src={item.settings.initialImage} />
      </>
    );
  }
};