import { useMemo } from 'react';
import styled from '@emotion/styled';
import { useIsFullPageChat } from '@/brand-insights/components/Chat/hooks';
import * as Presentation from './presentation';

type Props = {
  className?: string;
  displayValue: string;
  onClick: () => unknown;
  disabled?: boolean;
};

export const CancelAction = ({ className, displayValue, disabled, onClick }: Props) => {
  const isFullPageChat = useIsFullPageChat();
  return (
    <Presentation.Root className={className} onClick={disabled ? undefined : onClick}>
      <CancelWrap isDisabled={disabled} isFullPageChat={isFullPageChat}>
        <CancelIcon isDisabled={disabled} /> {displayValue}
      </CancelWrap>
    </Presentation.Root>
  );
};

const CancelWrap = styled(Presentation.Wrap)(({ isDisabled, theme }) => {
  const { Cancel } = useMemo(() => Presentation.Colors(theme), [theme]);

  const enabled = Cancel.enabled;
  const disabled = Cancel.disabled;

  return {
    backgroundColor: `${isDisabled ? disabled.backgroundColor : enabled.backgroundColor}`,
    '&:hover': {
      backgroundColor: `${isDisabled ? disabled.hover.backgroundColor : enabled.hover.backgroundColor}`,
    },
  };
});

const CancelIcon = styled(Presentation.CancelStyledIcon)(({ isDisabled, theme }) => {
  const { CancelIcon } = useMemo(() => Presentation.Colors(theme), [theme]);

  const enabled = CancelIcon.enabled;
  const disabled = CancelIcon.disabled;

  return {
    color: `${isDisabled ? disabled.color : enabled.color}`,
  };
});