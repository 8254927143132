import extend from '@enums/extend';

export enum Seniority {
  CLevel      = 1,
  Executive   = 2,
  Director    = 3,
  Manager     = 4,
  NonManager  = 5,
  Other       = 6,
}

const seniorityMap = new Map([
  [Seniority.CLevel, { name: 'C-Level' }],
  [Seniority.Executive, { name: 'Executive' }],
  [Seniority.Director, { name: 'Director' }],
  [Seniority.Manager, { name: 'Manager' }],
  [Seniority.NonManager, { name: 'Non Manager' }],
  [Seniority.Other, { name: 'Other' }],
]);

export default {
  Seniority: extend(seniorityMap),
};