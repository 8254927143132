import { useCallback, useContext, useState } from 'react';
import { MedicalBackgroundContext, MedicalBackgroundLeadershipContext } from './Context';
import { FormState, QueryData, SetValueParams } from './interfaces.leadership';

type Props = {
  children: React.ReactNode;
};

export const StateContainer = (props: Props) => {
  const ctx = useContext(MedicalBackgroundContext);

  const [leadership, setleadership] = useState(getStateFromQueryData(ctx.query.data.leadership));

  const rehydrate = useCallback(() => {
    return ctx.query.refetch()
    .then(res => setleadership(getStateFromQueryData(res.data.leadership)));
  }, [
    ctx.query,
    setleadership,
  ]);

  const handleChange = useCallback((data: SetValueParams) => {
    const items = leadership.items.map(x => {
      if (x.question.identifier !== data.identifier) return x;

      return {
        answer: data.value,
        question: x.question,
      };
    });

    setleadership({ items });
  }, [leadership]);

  const value = {
    form: leadership,
    rehydrate,
    setValue: handleChange,
  };

  return (
    <MedicalBackgroundLeadershipContext.Provider value={value}>
      {props.children}
    </MedicalBackgroundLeadershipContext.Provider>
  );
};

StateContainer.displayName = 'MedicalBackground.Leadership.StateContainer';

function getStateFromQueryData(data: QueryData): FormState {
  return {
    items: data.map(x => ({
      answer: x.answer?.value,
      question: x.question,
    })),
  };
}