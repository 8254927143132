import { useCallback } from 'react';
import { useSurveyTemplate, useSubmitSurveyDraft } from '@containers/SurveyBuilder/hooks';
import { GrayOutlineButton } from '@presentation/Buttons';
import { Button } from '@/components/Button';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import ModalHeader from '@/components/Modal/Header';
import { useModal } from '@/components/Modal/hooks';
import { useCanSaveTarget, useTemplateTarget } from './hooks/target';
import { TemplateTargetInput } from './Input.Target';
import styles from './style/Modal.css';

type Props =
  Pick<ModalProps,
    'open' |
    'onClose'>;

export const TemplateTargetModal = ({
  onClose,
  open,
}: Props) => {

  const {
    onChange,
    onSubmit,
    value,
  } = useTemplateTarget();
  const [template] = useSurveyTemplate();

  const submitDraft = useSubmitSurveyDraft();

  const handleSubmit = useCallback(() => {

    onSubmit();
    submitDraft();
    onClose();
  }, [
    onSubmit,
    onClose,
    submitDraft,
  ]);

  const canSubmit = useCanSaveTarget(value);

  return (
    <Modal
      open={open}
      onClose={onClose}>

      <ModalHeader text="Update Template Target" />

      <div className={styles.body}>
        <TemplateTargetInput
          template={template}
          onChange={onChange}
          value={value} />
      </div>

      <div className={styles.footer}>
        <GrayOutlineButton
          onClick={onClose}
          title="Cancel" />
        <Button.Primary
          className={styles.submit}
          disabled={!canSubmit}
          onClick={handleSubmit}
          title="Submit"
          variant="brick" />
      </div>
    </Modal>
  );
};

export const useTemplateTargetModal = () => useModal(TemplateTargetModal);

export default TemplateTargetModal;