import { cloneElement } from 'react';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import Popper from '@mui/material/Popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { MoreHorizontalAnchor } from '@presentation/Anchor';
import * as Table from '@/components/Table';
import styles from './style/Draggable.css';

type Props = {
  children: React.ReactElement;
};

export const Cell = (props: Props) => {
  return (
    <Table.Body.TD className={styles.actions}>
      <PopupState
        popupId="project-tags-actions-popper"
        variant="popper">
        {popup =>
          <div>
            <div {...bindToggle(popup)}>
              <MoreHorizontalAnchor
                className={styles.more}
                open={popup.isOpen} />
            </div>
            <Popper
              {...bindPopper(popup)}
              placement="bottom">
              <ClickAwayListener onClickAway={popup.close}>
                {cloneElement(props.children, { close: popup.close })}
              </ClickAwayListener>
            </Popper>
          </div>}
      </PopupState>
    </Table.Body.TD>
  );
};

Cell.displayName = 'Column.Actions.Cell';

export const Header = (props: unknown) => {
  return (
    <Table.Header.TH className={styles.actions} />
  );
};

Header.displayName = 'Column.Actions.Header';