import { useCallback, memo } from 'react';
import { Circle } from 'react-feather';
import { cx } from '@utils';
import { useAlert } from '@/components/Modal/Alert';
import styles from './style/Recording.css';

type Props = {
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  value: boolean;
  visible?: boolean;
  supported?: boolean;
  onClick?: (value: boolean) => void;
};

export const Recording = memo(({ className, children, disabled, value, visible, onClick }: Props) => {
  const [toggleAlert, Alert, alertOpen] = useAlert();

  const handleClick = useCallback(() => {
    if (value) {
      toggleAlert();
    } else {
      onClick?.(!value);
    }
  }, [value, onClick, toggleAlert]);

  const onConfirm = useCallback(() => {
    onClick?.(false);
    if (alertOpen) {
      toggleAlert();
    }
  }, [onClick, alertOpen, toggleAlert]);

  if (!visible) return null;

  return (
    <>
      <div
        className={cx(styles.icon, !value ? styles.iconOff : null)}
        onClick={!disabled ? handleClick : undefined}>
        {children ?? (
          <RecordingIcon
            disabled={!value} />
        )}
      </div>
      <Alert
        message='This will disable recording for the call. You will need to toggle it back on to enable recording. Are you sure you want to stop recording?'
        onConfirm={onConfirm} />
    </>
  );
});

type RecordingIconProps = {
  disabled: boolean;
};
const RecordingIcon = ({ disabled }: RecordingIconProps) => {
  const color = disabled ? 'var(--gray-d)' : 'var(--pri-04)';
  return (
    <div className={styles.iconContent}>
      <span className={styles.iconText}>REC</span>
      <Circle
        size={14}
        color={color}
        fill={color} />
    </div>
  );
};