import { format } from 'date-fns';
import tzFormat from 'date-fns-tz/format';
import { HiddenFromAttendeesOption, VisibleToAttendeesOption } from '@/components/Conference.Entry/Visibility';

export function visibilityToBool(value: number): boolean {
  return value === VisibleToAttendeesOption.id;
}

export function boolToVisibility(value: boolean): number {
  return value ? VisibleToAttendeesOption.id : HiddenFromAttendeesOption.id;
}

export function formatCallTime(start: Date, end: Date) {
  const s = format(start, 'MMM do h:mm aaa');
  const e = format(end, 'h:mm aaa');
  const tz = tzFormat(Date.now(), 'zzz');
  return `${s} - ${e} ${tz}`;
}