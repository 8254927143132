import { Fragment } from 'react';
import { utils } from '@enums';
import type { TableCellItem } from './interfaces';

type Props = TableCellItem;

export const Cell = (props: Props) => {
  if (!props.row.original.payerAdminType) return null;

  return (
    <Fragment>
      {utils.PayerAdminType.getName(props.row.original.payerAdminType)}
    </Fragment>
  );
};

Cell.displayName = 'Column.PayerAdminType.Cell';