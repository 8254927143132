import { useCallback } from 'react';
import { useChainedCommands } from './useCommands';
import { useHelpers } from './useHelpers';

export const useBlurHighlight = () => {

  const commands = useChainedCommands();
  const { getFocusedHighlight } = useHelpers();

  return useCallback(() => {
    const focusedHighlight = getFocusedHighlight();

    if (focusedHighlight) {

      if (focusedHighlight.dbId) {
        commands.blurHighlight().run();
      } else {
        commands
          .blurHighlight()
          .removeHighlights([focusedHighlight.id])
          .run();
      }
    }
  }, [
    commands,
    getFocusedHighlight,
  ]);

};