import extend from '@enums/extend';

export enum BillingPeriod {
  Monthly = 1,
  Quarterly = 2,
  BiAnnual = 3,
  Annual = 4
}

const map = new Map([
  [BillingPeriod.Monthly, { name: 'Monthly' }],
  [BillingPeriod.Quarterly, { name: 'Quarterly' }],
  [BillingPeriod.BiAnnual, { name: 'Bi-Annual' }],
  [BillingPeriod.Annual, { name: 'Annual' }],
]);

export default {
  BillingPeriod: extend(map),
};