import { useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import * as $api from '@api';
import {
  GroupEntityTagsContext,
  GroupEntityTagsDispatchContext,
  GroupEntityTagsFetchContext,
  GroupEntityTagsLoadingContext,
  GroupEntityTagsUpdateContext,
} from './Context';
import type { GroupTagsContextValue, GroupTagUpdateItem } from './interfaces';

type Props = IProjectParentId & ChildrenProps;

export default function GroupProjectTagsContainer({ children, projectParentId }: Props) {
  const [state, dispatch] = useState<GroupTagsContextValue>(GroupProjectTagsContainer.initialState);

  const { isSuccess, refetch } = useQuery({
    queryKey: ['get:group/tags/project-parent', projectParentId],
    queryFn: () => {
      return $api.tags.group.getProjectParentTags({ projectParentId });
    },
    onSuccess: result => {
      dispatch(result.tags);
    },
    enabled: !!projectParentId,
  });

  const { mutateAsync } = useMutation({
    mutationFn: (tags: GroupTagUpdateItem[]) => {
      return $api.tags.group.updateProjectParentTags({
        projectParentId,
        tags,
      });
    },
    onSuccess: result => {
      dispatch(result.tags);
    },
  });

  return (
    <GroupEntityTagsContext.Provider value={state}>
      <GroupEntityTagsLoadingContext.Provider value={!isSuccess}>
        <GroupEntityTagsFetchContext.Provider value={refetch}>
          <GroupEntityTagsUpdateContext.Provider value={mutateAsync}>
            <GroupEntityTagsDispatchContext.Provider value={dispatch}>
              {children}
            </GroupEntityTagsDispatchContext.Provider>
          </GroupEntityTagsUpdateContext.Provider>
        </GroupEntityTagsFetchContext.Provider>
      </GroupEntityTagsLoadingContext.Provider>
    </GroupEntityTagsContext.Provider>
  );
}

const initial: GroupTagsContextValue = [];

GroupProjectTagsContainer.initialState = initial;

export { GroupProjectTagsContainer };