import { useContext } from 'react';
import { cx } from '@utils';
import { Button as Btn } from '@/components/Button';
import { ParamsContext } from './Context';
import styles from './style/CallScheduling.Button.css';

type Props = {
  children?:  React.ReactNode;
  className?: string;
  disabled?:  boolean;
} & IProjectId
  & IUserId
  & Partial<ICallId>;

export const Button = (props: Props) => {
  const ctx = {
    scheduling: useContext(ParamsContext),
  };

  const content = props.children !== undefined
    ? props.children
    : 'Schedule Call';

  return (
    <Btn
      className={cx(styles.btn, props.className)}
      disabled={props.disabled}
      onClick={() => ctx.scheduling.setContext({
        callId: props.callId,
        projectId: props.projectId,
        userId: props.userId,
      })}
      variant="brick">
      {content}
    </Btn>
  );
};

Button.displayName = 'Call.Scheduling.Button';