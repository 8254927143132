import styles from './style/ResponsesEmpty.css';

const ResponsesEmpty = () => {
  return (
    <div className={styles.root}>
      No responses have been provided for this question.
    </div>
  );
};

const NotEnoughResponses = () => {
  return (
    <div className={styles.root}>
      Not enough responses have been provided for this question.
    </div>
  );
};

export { NotEnoughResponses, ResponsesEmpty };
export default ResponsesEmpty;