import { useContext, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { format } from 'date-fns';
import Skeleton from '@mui/material/Skeleton';
import { useProjectOrLatestChild, useProjectState } from '@containers/GroupProject/hooks';
import { useIsInternalUser } from '@/containers/Store/hooks';
import * as enums from '@enums';
import { CreatedByWhenLabel, CreatedByWhenSkeleton } from '@presentation';
import { ProjectStateLoadingContext } from '@/containers/GroupProject/Context';
import { Breadcrumbs } from './Header.Breadcrumbs';
import ProjectVersion from './ProjectVersion';
import styles from './style/ProjectHeader.css';

type Props = {
  children: React.ReactNode;
};

const ProjectHeader = (props: Props) => {

  const isInternalUser = useIsInternalUser();

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>

          <Breadcrumbs />

          <div className={styles.details}>
            <div className={styles.info}>

              <div className={styles.header}>
                <Title />
              </div>

            </div>

            {props.children}
          </div>

          <div className={styles.subtitle}>
            {isInternalUser && <CreatedBy />}
            <Version />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectHeader;

const CreatedBy = (props: unknown) => {
  const isLoading = useContext(ProjectStateLoadingContext);
  const project = useProjectOrLatestChild();

  if (isLoading) {
    return (
      <CreatedByWhenSkeleton />
    );
  }

  return (
    <CreatedByWhenLabel
      className={styles.age}
      by={project.creator.name}
      when={`on ${format(project.createdOn, 'M/d/yyyy')}`} />
  );
};

CreatedBy.displayName = 'Project.Header.CreatedBy';

const Title = (props: unknown) => {
  const isLoading = useContext(ProjectStateLoadingContext);
  const { latestProject } = useProjectState();

  if (isLoading) {
    return (
      <Skeleton
        style={{ marginRight: 10 }}
        height={30}
        width={400}
        variant="rectangular" />
    );
  }

  return (
    <>
      <Helmet title={latestProject.name} />
      <h1 className={styles.title}>{latestProject.name}</h1>
    </>
  );
};

Title.displayName = 'Project.Header.Title';

const Version = (props: unknown) => {
  const isLoading = useContext(ProjectStateLoadingContext);
  const { projectParent, versions } = useProjectState();

  const showVersion = useMemo(() => {
    if (isLoading) return false;

    return projectParent.frequency !== enums.ProjectFrequency.None
      && versions.length > 1;
  }, [
    projectParent?.frequency,
    isLoading,
    versions?.length,
  ]);

  return showVersion
    ? <ProjectVersion className={styles.version} />
    : null;
};

Version.displayName = 'Project.Header.Version';