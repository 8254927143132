import { useMemo } from 'react';
import { useSurveyBuilderOptions } from '@/containers/SurveyBuilder/hooks/useSurveyBuilderContext';
import { RankingQuestion } from '@/types/survey';
import { OptionContextMenu } from './Option.ContextMenu';
import { useAnchoredOptionMenuItem, useOpenEndedOptionMenuItem } from './hooks/option-menu';

type Props = {
  option: RankingQuestion.Option;
  question: RankingQuestion.Question;
};

export const RankingOptionContextMenu = (props: Props) => {
  const openEndedMenuItem = useOpenEndedOptionMenuItem({
    option: props.option,
    questionIdentifier: props.question.base.identifier,
  });

  const anchoredMenuItem = useAnchoredOptionMenuItem({
    option: props.option,
    question: props.question,
  });

  const items = useMemo(() => {
    return [
      openEndedMenuItem,
      anchoredMenuItem,
    ].filter(Boolean);
  }, [
    anchoredMenuItem,
    openEndedMenuItem,
  ]);

  return (
    <OptionContextMenu
      option={props.option}
      items={items}
      excludeFindAndReplace={false} />
  );
};