import type { ChangeEvent } from 'react';
import { useCallback } from 'react';
import { X } from 'react-feather';
import { TextareaAutosize } from '@/components/Textarea';
import { SurveyQuestionOption } from '@/components/Survey.RichText';
import type { AltRankingForm } from './interfaces';
import styles from './style/Ranking.Alt.DropArea.Item.css';
import { DraggableItem } from './Ranking.Alt.Item';
import { useRankingOptionOpenEndedValue } from './hooks/useRankingOpenEndedValue';

type Props = {
  className?: string;
  item: AltRankingForm.DraggableItem;
  onRemove: () => void;
};

export const DraggableDropAreaItem = ({ className, item, onRemove }: Props) => {

  const [openEndedValue, setOpenEndedValue] = useRankingOptionOpenEndedValue(item.id);

  const handleValueUpdated = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setOpenEndedValue(e.target.value);
  }, [setOpenEndedValue]);

  return (
    <DraggableItem className={className} item={item}>
      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.ordinal}>{item.index})</div>
          <div className={styles.textContent}>
            <SurveyQuestionOption value={item.value} editorClassName={styles.editor} />
            {item.isOpenEnded &&
              <div className={styles.openEndedInput}>
                <TextareaAutosize
                  onChange={handleValueUpdated}
                  value={openEndedValue} />
              </div>
            }
          </div>
        </div>
        <X
          className={styles.remove}
          onClick={onRemove} />
      </div>
    </DraggableItem>
  );
};