import { useTemplateQualityOfLifeModal } from '@/components/Survey.Templates/Modal.QualityOfLife';
import { TemplateItemLabel } from './Label.Base';
import { TemplateLabelColor } from './Label.colors';

type Props = {
  className?: string;
  disabled: boolean;
  value: string;
};

export const TemplateQualityOfLife = ({ className, disabled, value }: Props) => {

  const [toggleModal, Modal] = useTemplateQualityOfLifeModal();

  return (
    <>
      <TemplateItemLabel
        className={className}
        color={TemplateLabelColor.Six}
        disabled={disabled}
        label={value}
        tooltip="Quality of life"
        onClick={toggleModal} />
      {!disabled && <Modal />}
    </>
  );
};