import { useContext } from 'react';
import type { SurveyQuestionType } from '@enums/Survey';
import { createNamedContext } from '@utils';
import type { SurveyQuestion, ConjointAnalysisQuestion, MaxDifferenceQuestion, SurveyRichText } from '@/types';

type QuestionBuilderDelete = () => void;
type QuestionBuilderSaveContextValue = {
  canSave: boolean;
  validationErrors: string[];
  onSave: () => Promise<void>;
};
type CancelValue = () => void;

export type ConjointAnalysisQuestionBuilderContextValue = {
  item: ConjointAnalysisQuestion.Question;

  canAddLevel: (attributeId: string) => boolean;
  canAddAttribute: boolean;
  canRemoveLevel: (attributeId: string) => boolean;
  canRemoveAttribute: boolean;

  addAttribute: () => unknown;
  removeAttribute: (attributeId: string) => () => unknown;

  addLevel: (attributeId: string) => () => unknown;
  removeLevel: (attributeId: string) => (levelId: string) => () => unknown;

  renameAttribute: (attributeId: string) => (value: string) => unknown;
  renameLevel: (attributeId: string) => (levelId: string) => (value: string) => unknown;

  updateConceptsPerSet: (value: number) => unknown;
  updateNumberOfSets: (value: number) => unknown;
  toggleNoneOfTheAbove: () => unknown;
};

export type MaxDiffQuestionBuilderContextValue = {
  item: MaxDifferenceQuestion.Question;

  updateLeastLabel: (value: SurveyRichText.RichTextValue) => unknown;
  updateMostLabel: (value: SurveyRichText.RichTextValue) => unknown;

  updateOptionsPerSet: (value: number) => unknown;
  updateSets: (value: number) => unknown;
};

export type TextListBuilderContextValue<T extends Record<string, unknown> = Record<string, unknown>> = {
  permissions: {
    canAddItem: boolean;
    canRemoveItem: boolean;
  };
  items: T[];
  addItem: () => void;
  removeItem: (identifier: string) => void;
  updateItemValue: (identifier: string, value: string) => void;
};

export type RichTextListBuilderContextValue<T = unknown> = {
  permissions: {
    canAddItem: boolean;
    canRemoveItem: boolean;
  };
  items: T[];
  addItem: () => void;
  removeItem: (identifier: string) => void;
  updateItemValue: (identifier: string, value: SurveyRichText.RichTextValue) => void;
  updateItemPlainValue: (identifier: string, value: string) => void;
};

export const QuestionBuilderItemContext = createNamedContext<SurveyQuestion>(undefined, 'QuestionBuilderItemContext');
export const QuestionBuilderDeleteContext = createNamedContext<QuestionBuilderDelete>(undefined, 'QuestionBuilderDeleteContext');
export const QuestionBuilderSaveContext = createNamedContext<QuestionBuilderSaveContextValue>(undefined, 'QuestionBuilderSaveContext');
export const QuestionBuilderCancelContext = createNamedContext<CancelValue>(undefined, 'QuestionBuilderCancelContext');

export const ConjointAnalysisQuestionBuilderContext = createNamedContext<ConjointAnalysisQuestionBuilderContextValue>(undefined, 'ConjointAnalysisQuestionBuilderContext');
export const MaxDiffQuestionBuilderContext = createNamedContext<MaxDiffQuestionBuilderContextValue>(undefined, 'MaxDiffQuestionBuilderContext');

export const TextListBuilderContext = createNamedContext<TextListBuilderContextValue>(undefined, 'TextListBuilderContext');
export const RichTextListBuilderContext = createNamedContext<RichTextListBuilderContextValue>(undefined, 'RichTextListBuilderContext');

export const useQuestionBuilderItemContext = () => useContext(QuestionBuilderItemContext);
export const useDeleteQuestion = () => useContext(QuestionBuilderDeleteContext);
export const useSaveQuestionContext = () => useContext(QuestionBuilderSaveContext);
export const useCancelQuestionEditing = () => useContext(QuestionBuilderCancelContext);

export const useConjointAnalysisQuestionBuilderContext = () => useContext(ConjointAnalysisQuestionBuilderContext);
export const useMaxDiffQuestionBuilderContext = () => useContext(MaxDiffQuestionBuilderContext);

export const useTextListBuilderContext = <T extends Record<string, unknown>>() => {
  return useContext(TextListBuilderContext) as TextListBuilderContextValue<T>;
};

export const useRichTextListBuilderContext = <T = unknown>() => {
  return useContext(RichTextListBuilderContext) as RichTextListBuilderContextValue<T>;
};

export const useQuestionBuilderItem = <T extends SurveyQuestionType = SurveyQuestionType>() => {
  const ctx = useQuestionBuilderItemContext();
  return ctx as SurveyQuestion<T>;
};