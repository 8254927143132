import { Helmet } from 'react-helmet';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import { pathname } from '@consts';
import { BackLink } from '@presentation/Back';
import styles from './style/ConferenceEntry.NotFound.css';

export function ConferenceNotFound() {
  return (
    <div>
      <Helmet title="Conference not found" />
      <div className={styles.wrap}>
        <div className={styles.icon}><ErrorIcon /></div>
        <div className={styles.copy}>Conference not found, has already completed, or you do not have permission to join.</div>
        <div className={styles.back}>
          <BackLink to={`${pathname.Home}`} />
        </div>
      </div>
    </div>
  );
}