import { useCallback, useRef } from 'react';
import useTimedToggle from '@utils/hooks/useTimedToggle';

const timeoutMillis = 1250;

export const useCopyToClipboard = () => {
  return useCallback((text: string) => {
    return navigator.clipboard.writeText(text);
  }, []);
};

export const useCopy = <T extends HTMLTextAreaElement | HTMLInputElement = HTMLInputElement>() => {
  const [copied, toggleCopied] = useTimedToggle(false);

  const targetRef = useRef<T>(null);

  const copyToClipboard = useCopyToClipboard();

  const copy = useCallback(async () => {
    if (targetRef.current) {
      if (navigator.clipboard) {
        const text = targetRef.current.value;
        await copyToClipboard(text);
      } else {
        targetRef.current.select();
        document.execCommand('copy');
        targetRef.current.blur();
      }

      toggleCopied(timeoutMillis);
    }
  }, [copyToClipboard, toggleCopied]);

  return {
    copied,
    copy,
    target: targetRef,
  } as const;
};
