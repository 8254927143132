import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import { useSelectGroup } from '@containers/Store';
import { MediaOrientation } from '@enums';
import { RemoveLogo, useRemoveLogoMutation as useRemoveLogoMutationAPI } from '@utils/api';
import { useQueryDataAccessor } from './useQueryDataAccessor';

export const useRemoveLogoMutation = <Context extends Mutation.Context>(options: Mutation.Options<Context> = {}) => {
  const helpers = useMutationHelpers();

  const mutation = useRemoveLogoMutationAPI<Context>({
    ...options,
    onError: (...args) => {
      helpers.onError(...args);
      options.onError?.(...args);
    },
    onMutate: (variables: RemoveLogo.Variables) => {
      const ctx = helpers.onMutate(variables);

      options.onMutate?.(variables);

      return ctx;
    },
  });

  return mutation;
};

const useMutationHelpers = <O extends Helpers<Mutation.Context>>() => {
  const accessor = useQueryDataAccessor();
  const store = useStoreHelpers();

  const onError: O['onError'] = useCallback((e, variables, context) => {
    accessor.setQueryData(data => {
      return {
        ...data,
        logos: context.logos,
      };
    });

    store.onError.dispatch(e, variables, context);

  }, [
    accessor,
    store.onError,
  ]);

  const onMutate = useCallback((variables: Mutation.Variables) => {
    const previous = accessor.getQueryData();

    const updated = createEmptyOrientationState(variables.orientation);

    accessor.setQueryData(data => {
      return {
        ...data,
        logos: {
          ...data.logos,
          ...updated,
        },
      };
    });

    store.onMutate.dispatch(variables);

    return {
      logos: previous.logos,
    };
  }, [
    accessor,
    store.onMutate,
  ]);

  return {
    onError,
    onMutate,
  };
};

const useStoreHelpers = () => {
  const dispatch = useDispatch();
  const { branding } = useSelectGroup();

  const onError = useCallback((e: unknown | Error, variables: Mutation.Variables, context: Mutation.Context) => {
    dispatch(actions.brandingUpdated({
      branding: {
        logos: context.logos,
      },
    }));
  }, [dispatch]);

  const onMutate = useCallback((variables: Mutation.Variables) => {

    const updated = createEmptyOrientationState(variables.orientation);

    dispatch(actions.brandingUpdated({
      branding: {
        logos: {
          landscape: {
            url: branding?.logos?.landscape?.url,
          },
          square: {
            url: branding?.logos?.square?.url,
          },
          ...updated,
        },
      },
    }));
  }, [
    branding?.logos,
    dispatch,
  ]);

  return {
    onError: { dispatch: onError },
    onMutate: { dispatch: onMutate },
  };
};

declare namespace Mutation {
  export type Options<C = unknown> = RemoveLogo.Options<C>;

  export type Variables = RemoveLogo.Variables;

  export type Context = Pick<Store.Group.Branding, 'logos'>;
}

type Helpers<C extends Mutation.Context, T extends RemoveLogo.Options<C> = RemoveLogo.Options<C>> = {
  onError:  (...args: Parameters<T['onError']>) => unknown;
  onMutate: (variables: RemoveLogo.Variables) => C;
};

function createEmptyOrientationState(orientation: MediaOrientation) {
  return  orientation === MediaOrientation.Landscape
    ? { landscape: { url: null } }
    : orientation === MediaOrientation.Portrait
      ? { portrait: { url: null } }
      : { square: { url: null } };
}