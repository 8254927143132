import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import * as API from '@api/interfaces';
import * as api from '@api';
import { ProjectStateContext } from '@containers/GroupProject/Context';
import { ConferenceTagType } from '@enums';
import { ButtonActivityIndicator } from '@presentation';
import { cx } from '@utils';
import { ProjectConferenceTagWithMetadata } from '@/types';
import { defaultColor as ColorSelectDefault } from '@/components/Conference.Tagging/Tagging.ColorSelect';
import { PlusCircle } from '@/components/icons';
import { Input } from '@/components/Input';
import * as Layout from '@/components/MedicalProfile/Layout';
import { OnboardingFormContext, OnboardingStepperContext } from './Context';
import styles from './style/Onboarding.Categories.css';

type Props = unknown;

export const Categories = (props: Props) => {
  const stepper = useContext(OnboardingStepperContext);
  const form = useContext(OnboardingFormContext);
  const state = useContext(ProjectStateContext);
  const fields = useRef(Math.max(8 - form.categories.length, 1));
  const [values, setValues] = useState<string[]>(Array.from({ length: fields.current }, _ => ''));

  const mutation = useMutation([
    `put:projects/conferences/tags/bulk`,
    state?.project?.id,
  ], (items: API.ConferenceTags.CreateTags.Request['items']) => {
    return api.projects.conference.createTags({
      items,
      projectId: state.project.id,
    });
  }, {
    onSuccess: res => {
      const tags = res.tags.filter(x => x.base.typeId !== ConferenceTagType.Global);

      const categories = sortTags(tags.filter(t => !t.parent?.id).map(t => ({
        ...t,
        children: sortTags(tags.filter(c => c.parent?.id === t.id)),
      })));

      form.replaceCategoriesState(categories);
    },
  });

  const handleNavigateNext = useCallback(() => {
    const items = values.filter(x => !!x?.trim?.()?.length);

    if (!items.length) {
      stepper.next();
    } else {
      const lastOrd = Math.max(0, ...form.categories.map(x => x.ordinal));

      mutation.mutateAsync(items.map((val, i) => ({
        baseTag: {
          name: val,
          color:  ColorSelectDefault,
          id: null,
        },
        parentTagId: null,
        ordinal: lastOrd + (i + 1),
      })))
      .then(stepper.next);
    }
  }, [
    form,
    values,
    mutation,
    stepper,
  ]);

  const handlePaste = useCallback((e: React.ClipboardEvent, index: number) => {
    e.preventDefault();
    const paste = e.clipboardData.getData('text');

    const [first, ...rest] = paste.split('\n');

    if (rest.length > 0) {
      setValues(items => {
        return items
          .map((current, ii) => ii === index ? first : current)
          .filter(Boolean)
          .concat(rest);
      });
    } else {
      setValues(items => items.map((current, ii) => ii === index ? first : current));
    }
  }, [
    setValues,
  ]);

  const disabled = useMemo(() => {
    const hasPresets = form.categories.length > 0;
    const hasValues = values.filter(x => !!x?.trim?.()?.length).length > 0;

    return !hasPresets && !hasValues;
  }, [
    form.categories,
    values,
  ]);

  return (
    <Layout.Screen>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <Layout.Header
            subtitle={copy.subtitle}
            title={copy.title} />
          <div className={styles.main}>
            <div className={styles.items}>
              {form.categories.map(x =>
                <PresetCategory
                  key={x.id}
                  name={x.base.name}
                  onClickRemove={() => form.onRemovePresetCategory(x.id)} />)}
              {values.map((val, i) =>
                <EditableCategory
                  key={i}
                  value={val}
                  onChange={str => setValues(items => items.map((current, ii) => ii === i ? str : current))}
                  onClickRemove={() => setValues(items => items.filter((val, ii) => ii !== i))}
                  onPaste={e => handlePaste(e, i)} />)}
              <div
                className={styles.add}
                onClick={() => setValues(items => items.concat(''))}>
                <PlusCircle size={30} />
                <span>Add Category</span>
              </div>
            </div>
          </div>
          <Layout.Footer>
            <div
              className={styles.cancel}
              onClick={stepper.back}>
              {`< Back`}
            </div>
            <ButtonActivityIndicator
              className={styles.btn}
              color="primary"
              disabled={disabled}
              implicitDisable={false}
              loading={mutation.isLoading}
              onClick={handleNavigateNext}>
              Next
            </ButtonActivityIndicator>
          </Layout.Footer>
        </div>
      </div>
    </Layout.Screen>
  );
};

Categories.displayName = 'Onboarding.Categories';

const copy = {
  title: `Input the high-level topic categories of the interview`,
  subtitle: `These categories should align to the high level topic areas you plan to cover in the interview. You’ll be able to input subcategories on the next screen. We recommend up to 25 categories.`,
};

type PresetCategoryProps = {
  name: string;
  onClickRemove: () => void;
};

const PresetCategory = (props: PresetCategoryProps) => {
  return (
    <div className={styles.item}>
      <div className={cx(styles.text, styles.uneditable)}>{props.name}</div>
      <div
        className={styles.remove}
        onClick={props.onClickRemove}>
        <RemoveCircleOutlineIcon />
      </div>
    </div>
  );
};

type EditableCategoryProps = {
  onChange: (value: string) => void;
  onClickRemove: () => void;
  onPaste: (e: React.ClipboardEvent) => void;
  value: string;
};

const EditableCategory = (props: EditableCategoryProps) => {
  return (
    <div className={styles.item}>
      <Input
        classes={{ input: styles.text }}
        onChange={e => props.onChange(e.target.value)}
        onPaste={props.onPaste}
        placeholder="Enter a category"
        value={props.value ?? ''} />
      <div
        className={styles.remove}
        onClick={props.onClickRemove}>
        <RemoveCircleOutlineIcon />
      </div>
    </div>
  );
};

EditableCategory.displayName = 'Onboarding.Categories.EditableCategory';

function sortTags<T extends ProjectConferenceTagWithMetadata>(tags: T[]) {
  return tags.sort((a, b) => (a.ordinal ?? Number.MAX_VALUE) - (b.ordinal ?? Number.MAX_VALUE));
}