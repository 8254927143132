import { path } from '@consts';
import { LinkLabeledIcon } from '$website/components';
import bg1 from 'static/images/website/collaboration-tools/global-search-s.png';
import bg2 from 'static/images/website/collaboration-tools/global-search-m.png';
import bg3 from 'static/images/website/collaboration-tools/global-search-l.png';
import styles from './style/GlobalSearch.css';

type Props = unknown;

export const GlobalSearch = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.content}>
          <div className={styles.main}>

            <div className={styles.graphic}>
              <picture>
                <source
                  media="(min-width: 1100px)"
                  srcSet={bg3} />
                <source
                  media="(min-width: 800px)"
                  srcSet={bg2} />
                <img
                  alt="centralized searchable repository"
                  className={styles.img}
                  src={bg1} />
              </picture>
            </div>

            <div className={styles.info}>
              <h1 className={styles.title}>Access a centralized searchable repository of your firm’s research</h1>
              <div className={styles.subtitle}>B2B research data generated or uploaded onto Sentiment is interactive, discoverable, and downloadable across a customer’s organization.</div>
              <LinkLabeledIcon to={path.Website.Demo}>
                Request a demo today
              </LinkLabeledIcon>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

GlobalSearch.displayName = 'CollaborationTools.GlobalSearch';