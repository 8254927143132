import {
  useMaxDiffFormContext,
  useSurveyFormQuestionAnswer,
  useSurveyFormQuestionContext,
} from '@containers/SurveyForm';
import { SurveyQuestionType } from '@enums';
import Set from './MaxDiff.Set';
import styles from './style/MaxDiff.css';

export default function MaxDiffForm() {
  const [answer] = useSurveyFormQuestionAnswer<SurveyQuestionType.MaxDifference>();
  const question = useSurveyFormQuestionContext<SurveyQuestionType.MaxDifference>();
  const { currentSet, setSetAnswer } = useMaxDiffFormContext();

  const progressText = `Set ${currentSet} of ${question.settings.sets}`;

  return (
    <div className={styles.root}>
      <div className={styles.progress}>{progressText}</div>
      <Set
        onChange={setSetAnswer(currentSet)}
        set={question.design.sets[currentSet - 1]}
        value={answer.sets[currentSet - 1]}
        settings={question.settings} />
    </div>
  );
}

export { MaxDiffForm };