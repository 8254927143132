import { useAltRankingFormContext } from './context';
import { StartArea } from './Ranking.Alt.StartArea';
import { DestinationDropArea } from './Ranking.Alt.DropArea';
import styles from './style/Ranking.Alt.css';

export const RankingAltForm = () => {

  const { leftItems, rightItems } = useAltRankingFormContext();

  const message = `Rank the items below by dragging them to the list on the right.`;

  return (
    <div className={styles.root}>
      <div className={styles.message}>{message}</div>
      <div className={styles.lists}>
        <StartArea
          className={styles.start}
          items={leftItems} />
        <DestinationDropArea
          className={styles.destination}
          items={rightItems} />
      </div>
    </div>
  );
};