import { useTable, useFlexLayout, usePagination, type Column } from 'react-table';
import { Card, CardContent, GroupCardHeader } from '@presentation/Main';
import { GroupTable, GroupTableFooter } from '@presentation/GroupTable';
import { AnimatedLoader } from '@/components/ActivityIndicator';
import ViewAll from './ViewAll';
import { useBrandsTableColumns } from './hooks';
import type { BrandsTableItem } from './interfaces';
import styles from './style/Table.css';

type Props = {
  data: BrandsTableItem[];
  countText: string;
  pagination?: boolean;
  pageSize: number;
  isLoading: boolean;
  viewAll?: boolean;
};

export default function SearchTableBrands({ data, countText, pagination, pageSize, isLoading, viewAll }: Props) {
  const columns = useBrandsTableColumns() as Column<BrandsTableItem>[];

  const tableInstance = useTable<BrandsTableItem>(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize,
      },
    },
    useFlexLayout,
    usePagination,
  );

  const tableClasses = {
    body: styles.body,
    td: styles.td,
    tr: styles.tr,
  };

  const footerClasses = {
    root: styles.footer,
    pageSize: styles.rows,
  };

  return (
    <Card className={styles.card}>
      <GroupCardHeader className={styles.header}>
        <div className={styles.left}>
          <div>Brands <span className={styles.count}>{countText}</span></div>
        </div>
        {viewAll && (
          <div className={styles.right}>
            <ViewAll tab="brands" />
          </div>)}
      </GroupCardHeader>
      <CardContent className={styles.content} fluid>
        {!isLoading && !!data.length &&
          <GroupTable
            classes={tableClasses}
            instance={tableInstance} />}
        {isLoading && (<AnimatedLoader className={styles.loading} />)}
        {!isLoading && pagination && !!data.length &&
          <GroupTableFooter
            classes={footerClasses}
            instance={tableInstance} />}
      </CardContent>
    </Card>
  );
}

export { SearchTableBrands };