import { useEffect } from 'react';
import { useSelectUser } from '@containers/Store';
import { AuthModalContainer, useLoginModal, useSignupModal } from '$website/components/AuthModal';

type Props =
  ChildrenProps;

const StateDependent = (props: Props) => {

  const me = useSelectUser();
  const [_, setSignupModal] = useSignupModal();
  const [__, setLoginModal] = useLoginModal();

  useEffect(() => {
    setSignupModal(!me.state.authenticated);

    if (me.state.authenticated) {
      setSignupModal(false);
      setLoginModal(false);
    }
  }, [
    setLoginModal,
    setSignupModal,
    me.state.authenticated,
  ]);

  return (
    <>
      {props.children}
    </>
  );
};

export const AuthSignupGuard = (props: Props) => {
  return (
    <AuthModalContainer blocking={true}>
      <StateDependent {...props} />
    </AuthModalContainer>
  );
};

export default AuthSignupGuard;