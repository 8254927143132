import { useSelector } from 'react-redux';
import { useProjectOnboardingContext } from '@containers/ProjectOnboarding/hooks/useProjectOnboardingContext';
import * as PayoutSetup from '@screens/PayoutSetup';
import { useNavigateNextOrRedirect } from '@screens/ProjectOnboarding/hooks/useNavigateNextOrRedirect';
import { selectHasPayablePayoutAccount } from '@store/selectors';
import styles from './style/Billing.css';

export function Stepper() {
  const { hasNextStep, projectId } = useProjectOnboardingContext();
  const next = useNavigateNextOrRedirect();

  const hasPayoutAccount = useSelector(selectHasPayablePayoutAccount);

  return (
    <div className={styles.root}>
      <PayoutSetup.Stepper.Survey
        hasPayoutAccount={hasPayoutAccount}
        hasStepsAfter={hasNextStep}
        onComplete={() => next()}
        onSkip={() => next()}
        projectId={projectId} />
    </div>
  );
}

Stepper.displayName = 'ProjectOnboarding.Completion.Billing.Survey.Stepper';