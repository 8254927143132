export const splitTextByWordType = (text: string) => {
  return text.match(/\w+|\s+|[^\s\w]+/g);
};

export const text = {
  isNonWord: (text: string) => {
    if (text.length !== 1) return false;
    return [' ', '.', ',', '?', '!'].includes(text[0]);
  },
  isPuncuation: (text: string) => {
    if (text.length !== 1) return false;
    return ['.', ',', '?', '!'].includes(text[0]);
  },
  isSpace: (text: string) => {
    if (text.length !== 1) return false;
    return [' '].includes(text[0]);
  },
  isWord: (text: string) => {
    if (!text.length) return false;
    return /^(\w|-)+$/.test(text);
  },
  isNumber: (text: string) => {
    if (!text.length) return false;
    return /^\d+$/.test(text);
  },
};