import { Archive as ArchiveFeather } from 'react-feather';
import styles from './style.css';

type Props = {
  onClick: () => unknown;
  size?: number;
};

export function ArchiveLegacy({ onClick, size }: Props) {
  return (
    <div
      className={styles.root}
      onClick={onClick}>
      <ArchiveFeather
        className={styles.icon}
        size={size} />
    </div>
  );
}

export default ArchiveLegacy;