import { useCallback } from 'react';
import { useTargetProductProfileData } from '@containers/SurveyBuilder/hooks';
import { AnimatedLoader } from '@/components/ActivityIndicator';
import { FilePicker } from '@/components/FilePicker';
import { ResolvedTPP } from './TPPTable';
import styles from './style/Input.TPP.css';
import { useHandleTPPFileEvent } from './hooks/tpp';

type Props = {
  onFileSelected?: () => void;
  tppUrl: string;
  setTppUrl: (value: string) => void;
};

export const TPPTableSelection = ({ onFileSelected, setTppUrl, tppUrl }: Props) => {

  const [templateData] = useTargetProductProfileData();

  const {
    handleFileSelect,
    isLoading,
  } = useHandleTPPFileEvent({
    onSuccess: res => {
      setTppUrl(res.attachment.url);
      onFileSelected?.();
    },
  });

  const handleUseTable = useCallback(() => {
    setTppUrl(null);
  }, [setTppUrl]);

  return (
    <>
      <div className={styles.top}>
        <div>Upload an image to replace TPP table:</div>
        {!isLoading &&
          <FilePicker
            className={styles.picker}
            onSelect={handleFileSelect} />}
        {isLoading &&
          <AnimatedLoader className={styles.loader} />
        }
        {tppUrl &&
          <div
            onClick={handleUseTable}
            className={styles.link}>
            Use generated table
          </div>
        }
      </div>

      <ResolvedTPP data={{
        ...templateData,
        tppUrl,
      }} />
    </>
  );
};