import { memo, useCallback, useMemo, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import styled from '@emotion/styled';
import type { Chat } from '@/brand-insights/types';
import { shouldForwardProp } from '@/brand-insights/utils';
import { ChatMessageResponseQuoteContainer as Quote } from './Message.Response.Quote.Container';

type Props = {
  pageSize: number;
  quotes: Chat.Quote[];
};

export const PaginatedQuotes = memo(({ pageSize, quotes }: Props) => {

  const [pageIndex, setPageIndex] = useState(0);
  const pages = useMemo(() => {
    const pages: { [pageIndex: number]: Chat.Quote[] } = {};
    for (let i = 0; i < quotes.length; i += 1) {
      const page = Math.floor(i / pageSize);
      if (!pages[page]) {
        pages[page] = [];
      }
      pages[page].push(quotes[i]);
    }
    return pages;
  }, [pageSize, quotes]);
  const pageCount = useMemo(() => Object.keys(pages).length, [pages]);

  const goToPage = useCallback((pageIndex: number) => {
    setPageIndex(pageIndex);
  }, []);

  const pageQuotes = pages[pageIndex];

  return (
    <div>
      {pageQuotes.map((quote, i) => (
        <StyledQuote key={quote.citationKey} quote={quote} />
      ))}
      {pageCount > 1 && (
        <Pagination
          pageCount={pageCount}
          pageIndex={pageIndex}
          goToPage={goToPage} />
      )}
    </div>
  );
});

type PaginationProps = {
  pageCount: number;
  pageIndex: number;
  goToPage: (pageIndex: number) => void;
};

const Pagination = memo(({
  pageCount,
  pageIndex,
  goToPage,
}: PaginationProps) => {

  const canPreviousPage = pageIndex > 0;
  const canNextPage = pageIndex + 1 < pageCount;
  const previousPage = () => goToPage(pageIndex - 1);
  const nextPage = () => goToPage(pageIndex + 1);

  const pages = useMemo(() => {
    const pages: number[] = [];
    for (let i = 0; i < pageCount; i += 1) {
      pages.push(i);
    }
    return pages;
  }, [pageCount]);

  const jumpToPage = useCallback((page: number) => () => {
    goToPage(page);
  }, [goToPage]);

  return (
    <PaginationRoot>
      <StyledChevronLeft
        disabled={!canPreviousPage}
        onClick={previousPage} />
      <PagesRoot>
        {pages.map(page => (
          <StyledPage
            key={page}
            onClick={jumpToPage(page)}
            active={page === pageIndex}>
            {page + 1}
          </StyledPage>
        ))}
      </PagesRoot>
      <StyledChevronRight
        disabled={!canNextPage}
        onClick={nextPage} />
    </PaginationRoot>
  );
});

const PagesRoot = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
});

const PaginationRoot = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 10,
  marginTop: 15,
}));

const StyledQuote = styled(Quote)({
  marginTop: 15,
});

const StyledChevronLeft = styled(ChevronLeft, { shouldForwardProp: shouldForwardProp('disabled') })<ChevronProps>(({ disabled, theme }) => ({
  cursor: disabled ? 'default' : 'pointer',
  color: disabled ? theme.palette.gray.light1 : theme.palette.gray.dark1,
}));
const StyledChevronRight = styled(ChevronRight, { shouldForwardProp: shouldForwardProp('disabled') })<ChevronProps>(({ disabled, theme }) => ({
  cursor: disabled ? 'default' : 'pointer',
  color: disabled ? theme.palette.gray.light1 : theme.palette.gray.dark1,
}));

type ChevronProps = {
  disabled: boolean;
} & React.ComponentProps<typeof ChevronLeft>;

type PageProps = {
  active: boolean;
};

const StyledPage = styled('div', { shouldForwardProp: shouldForwardProp('active') })<PageProps>(({ active, theme }) => ({
  color: active ? theme.palette.sentiment.primary.main : theme.palette.gray.main,
  cursor: 'pointer',
  fontFamily: active ? theme.fonts.semiBold : theme.fonts.regular,
}));