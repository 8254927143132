import * as types from '@store/action-types';
import initialState from '@store/initial-state';
import { Notifications } from '../interfaces';

type Actions =
  Notifications.NotificationAdded.Action |
  Notifications.NotificationsDismissed.Action |
  Notifications.NotificationsSeen.Action |
  Notifications.NotificationsLoaded.Action;

function notifications(state = initialState.notifications, action: Actions) {

  switch (action.type) {
    case types.NOTIFICATIONS_LOADED:
      return {
        ...state,
        initialized: action.initialized,
        items: action.items,
      };

    case types.NOTIFICATIONS_DISMISSED:
      return {
        ...state,
        items: state.items.filter(x => !action.ids.includes(x.id)),
      };

    case types.NOTIFICATIONS_SEEN:
      return {
        ...state,
        items: state.items.reduce<Store.Notifications.Items>((acc, x) => {
          const item = !action.ids.includes(x.id)
            ? x
            : {
              ...x,
              seenOn: new Date(),
            };
          return [...acc, item];
        }, []),
      };

    case types.NOTIFICATION_ADDED: {
      return {
        ...state,
        items: state.items.some(s => s.id === action.notification.id)
          ? state.items
          : [action.notification].concat(state.items),
      };
    }

    default:
      return state;
  }

}

export default notifications;