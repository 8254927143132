import { useTemplateDrugPriceModal } from '@/components/Survey.Templates/Modal.DrugPrice';
import { TemplateItemLabel } from './Label.Base';
import { TemplateLabelColor } from './Label.colors';

type Props = {
  className?: string;
  disabled: boolean;
  value: string;
};

export const TemplateDrugPrice = ({ className, disabled, value }: Props) => {

  const [toggleModal, Modal] = useTemplateDrugPriceModal();

  return (
    <>
      <TemplateItemLabel
        className={className}
        color={TemplateLabelColor.One}
        disabled={disabled}
        label={value}
        tooltip="Drug Price"
        onClick={toggleModal} />
      {!disabled && <Modal />}
    </>
  );
};