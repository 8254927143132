import type { EditorState, ProsemirrorNode } from '@remirror/pm';
import type { Schema } from '@remirror/pm/model';
import type { Node } from '@remirror/pm/model';
import type { Timestamp } from '@/types/transcribe.rich-text';
import * as $utils from './utils.parser';
import type { PositionRange } from './interfaces';

export function resolveWordSnappingFromSelection(selection: PositionRange, state: EditorState) {
  const times: number[] = [];
  const nodes: ProsemirrorNode[] = [];
  const positions: number[] = [];
  let topPos: number = null;

  state.doc.nodesBetween(selection.from, selection.to, (node, pos) => {
    if (topPos === null) {
      topPos = pos;
    }

    nodes.push(node);

    if (node.type.name === 'text') {
      positions.push(pos);
    }

    const ts = node.marks.find(x => x.type.name === 'ts');

    if (ts) {
      times.push(ts.attrs.s as number, ts.attrs.e as number);
    }
  });

  let i = 0;
  let index: number = null;
  state.doc.descendants((node, pos) => {
    if (!node.isTextblock) {
      return true;
    }

    if (pos === topPos) {
      index = i;
    }

    i += 1;

    return false;
  });

  const text = nodes.map(x => x.text).join('');
  const from = Math.min(...positions);

  return {
    pos: {
      from: from + index + 1,
      to: (from + index + 1) + text.length,
    },
    selection: {
      from,
      to: from + text.length,
    },
    text,
    ts: {
      start: Math.min(...times),
      end: Math.max(...times),
    },
  };
}

export const buildNormalizedTextTimestampNodes = (text: string, schema: Schema, fromTs: number, toTs: number) => {
  const tokens = $utils.splitTextByWordType(text);

  const count = getWordCount(tokens);
  const marks = buildTSMarks(fromTs, toTs, count);
  const values = buildTextNodes(tokens, schema, marks);

  return values;
};

function getWordCount(tokens: string[]) {
  return tokens.reduce((acc, x) => !$utils.text.isNonWord(x) ? acc + 1 : acc, 0);
}

function buildTSMarks(from: number, to: number, count: number) {
  const diff = to - from;
  const increment = diff / count;

  const build = (start: number, end: number, i = 0, acc: TSMark[] = []): TSMark[] => {
    const round = i + 1;

    const mark = {
      s: start,
      e: start + increment,
    };

    const next = acc.concat(mark);

    if (round >= count) return next;

    return build(mark.e, end, round, next);
  };

  return build(from, to);
}

function buildTextNodes(tokens: string[], schema: Schema, marks: TSMark[] = []) {
  let i = 0;

  return tokens.map(text => {
    return $utils.text.isNonWord(text)
      ? schema.text(text)
      : schema.text(text, [schema.marks.ts.create(marks[i++]), schema.marks.conf.create({ c: 1 })]);
  });
}

type Params = {
  from: number;
  to: number;
  doc: Node;
};

export const getTimeRangeForPosRange = ({ from, to, doc }: Params) => {
  const tsAttrs: Timestamp.Attributes[] = [];

  doc.nodesBetween(from, to, n => {
    const attrs = getTimeMarkFromNode(n);
    if (attrs) tsAttrs.push(attrs);
  });

  const start = Math.min(...tsAttrs.map(a => a.s).filter(Boolean));
  const end = Math.max(...tsAttrs.map(a => a.e).filter(Boolean));

  return { start, end };
};

function getTimeMarkFromNode(node: Node) {
  return node.marks.find(m => m.type.name === 'ts')?.attrs as Timestamp.Attributes;
}

type TSMark = { s: number; e: number };