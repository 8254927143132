import { SearchParamsContext } from './Context';
import { useSearchParamsState, initial } from './hooks/useSearchParamsState';

type Props = {
  children: React.ReactNode;
};

const SearchParamsContainer = (props: Props) => {
  const [params, actions] = useSearchParamsState();

  return (
    <SearchParamsContext.Provider value={[params, actions]}>
      {props.children}
    </SearchParamsContext.Provider>
  );
};

SearchParamsContainer.initialState = initial;

export { SearchParamsContainer };
export default SearchParamsContainer;