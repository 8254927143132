/** @jsxImportSource @emotion/react */
import { useCallback, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { ChatStateDispatchContext } from '@/brand-insights/components/Chat.State';
import { CancelAction, MessageQueryHint } from '@/brand-insights/components/presentation';
import { ChatHintType } from '@/brand-insights/enums';
import type { Chat } from '@/brand-insights/types';
import { ActiveChatSessionContext } from './context';
import { UserMessage } from './Message.User';
import { BaseSystemUserMessage } from './Message.System';
import { SegmentationDimensionSelectionDimension } from './Item.Segmentation.Dimension';
import { useCancelSegmentationMutation, useScrollToBottomOfMessages, useSegmentationDimensionSelectionMutation, useSessionSegmentation } from './hooks';

type Props = {
  identifier: Chat.Segmentation.Segmentation['identifier'];
  tagSelection: Chat.Segmentation.TagSelection.ScreenData;
  data: Chat.Segmentation.DimensionSelection.ScreenData;
  readonly: boolean;
};

export const SegmentationDimensionSelection = (props: Props) => {
  return (
    <>
      <MessageContainer>
        <UserMessage value={copy.user(props.tagSelection.tags.filter(t => t.selected).map(t => t.tag.name))} />
      </MessageContainer>
      <MessageContainer>
        <BaseSystemUserMessage extendedWidth>
          <SegmentationDimensionSelectionSystemMessage {...props} />
        </BaseSystemUserMessage>
      </MessageContainer>
    </>
  );
};

export const SegmentationDimensionSelectionSystemMessage = (props: Props) => {
  const chat = useContext(ActiveChatSessionContext);
  const segmentation = useSessionSegmentation({ segmentationIdentifier: props.identifier });
  const dimensionSelection = useSegmentationDimensionSelectionMutation();
  const cancel = useCancelSegmentationMutation();

  const handleOnChange = useUpdateDimensions(props);
  const handleAddDimension = useAddDimension(props);
  const handleRemoveDimension = useRemoveDimension(props);

  const scrollToBottom = useScrollToBottomOfMessages();

  const handleContinue = useCallback(() => {
    dimensionSelection.mutateAsync({
      chatInstanceIdentifier: chat.identifier,
      context: chat.context,
      segmentation,
    })
    .then(() => scrollToBottom());
  }, [
    dimensionSelection,
    chat.identifier,
    chat.context,
    segmentation,
    scrollToBottom,
  ]);

  const handleCancel = useCallback(() => {
    cancel.mutateAsync({
      chatInstanceIdentifier: chat.identifier,
      context: chat.context,
      segmentation,
    })
    .then(() => scrollToBottom());
  }, [
    cancel,
    chat.identifier,
    chat.context,
    segmentation,
    scrollToBottom,
  ]);

  const canContinue = useMemo(() => !cancel.isLoading && !dimensionSelection.isLoading && props.data.dimensions.filter(d => !!d?.trim().length).length > 0, [
    cancel.isLoading,
    dimensionSelection.isLoading,
    props.data.dimensions,
  ]);

  const view = {
    actions: !props.readonly,
    removeDimension: !props.readonly && props.data.dimensions.length > 1,
    addDimension: !props.readonly && props.data.dimensions.length < 5,
  };

  return (
    <Root>
      <HeaderCopy>
        {copy.system.header}
      </HeaderCopy>
      <PickCopy>
        {copy.system.pick}
      </PickCopy>
      <Dimensions>
        {props.data.dimensions.map((value, idx) => (
          <DimensionRow key={`${idx}`}>
            <SegmentationDimensionSelectionDimension
              css={{ width: '100%' }}
              id={idx}
              value={value}
              onChange={handleOnChange}
              readonly={props.readonly} />
            {view.removeDimension && (
              <DimensionRemove onClick={handleRemoveDimension(idx)}>
                <StyledRemoveIcon />
              </DimensionRemove>
            )}
          </DimensionRow>
        ))}
      </Dimensions>
      {view.addDimension && (
        <AddDimensionRow>
          <AddDimension onClick={handleAddDimension}>
            <StyledAddIcon /> Add Question
          </AddDimension>
        </AddDimensionRow>
      )}
      {view.actions && (
        <Actions>
          <MessageQueryHint
            disabled={!canContinue}
            hint={{ type: ChatHintType.AnalysisTags, displayValue: copy.confirm }}
            onClick={handleContinue} />
          <CancelAction
            displayValue={copy.cancel}
            onClick={handleCancel} />
        </Actions>
      )}
    </Root>
  );
};

const useUpdateDimensions = (props: Props) => {
  const dispatch = useContext(ChatStateDispatchContext);

  return useCallback((id: number, value: string) => {
    dispatch({
      type: 'segmentation/screen/updated',
      payload: {
        identifier: props.identifier,
        screen: {
          type: 'dimension-selection',
          item: {
            ...props.data,
            dimensions: props.data.dimensions.map((dimension, idx) => idx === id ? value : dimension),
          },
        },
      },
    });
  }, [dispatch, props.identifier, props.data]);
};

const useAddDimension = (props: Props) => {
  const dispatch = useContext(ChatStateDispatchContext);

  return useCallback(() => {
    dispatch({
      type: 'segmentation/screen/updated',
      payload: {
        identifier: props.identifier,
        screen: {
          type: 'dimension-selection',
          item: {
            ...props.data,
            dimensions: props.data.dimensions.concat(''),
          },
        },
      },
    });
  }, [
    dispatch,
    props.identifier,
    props.data,
  ]);
};

const useRemoveDimension = (props: Props) => {
  const dispatch = useContext(ChatStateDispatchContext);

  return useCallback((idx: number) => () => {
    dispatch({
      type: 'segmentation/screen/updated',
      payload: {
        identifier: props.identifier,
        screen: {
          type: 'dimension-selection',
          item: {
            ...props.data,
            dimensions: props.data.dimensions.filter((_, index) => index !== idx),
          },
        },
      },
    });
  }, [
    dispatch,
    props.identifier,
    props.data,
  ]);
};

export const copy = {
  user: (tags: string[]) => `I'd like to analyze ${tags.join(', ')} tagged data.`,
  system: {
    header: `Ok, great! What topics or questions would you like to analyze?`,
    pick: `Input up to five dimensions of the analysis.`,
  },
  cancel: `Actually, I'd like to do something else.`,
  confirm: `Let's analyze these dimensions.`,
};

const MessageContainer = styled.div`
  padding: 15px 20px;
`;

const Root = styled.div`
  box-sizing: border-box;
`;

const HeaderCopy = styled.div`
  box-sizing: border-box;
  border-bottom: 1px solid ${props => props.theme.palette.gray.light1};
  padding-bottom: 10px;
  margin-bottom: 10px;
`;

const PickCopy = styled.div`
  font-size: 15px;
  font-family: ${props => props.theme.fonts.semiBold};
  margin-bottom: 10px;
`;

const Dimensions = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  :not(:last-child) {
    margin-bottom: 10px;
  }
`;

const DimensionRow = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
`;

const DimensionRemove = styled.button`
  display: inline-flex;
  box-sizing: border-box;
  margin-left: 5px;  
`;

const StyledRemoveIcon = styled(RemoveCircleOutlineIcon)`
  color: ${props => props.theme.palette.text.primary};
`;

const AddDimensionRow = styled.div`
  box-sizing: border-box;
  padding: 0 5px;
`;

const AddDimension = styled.button`
  box-sizing: border-box;
  color: ${props => props.theme.palette.blue.main};
  display: inline-flex;
  align-items: center;
  font-family: ${props => props.theme.fonts.semiBold};
`;

const StyledAddIcon = styled(AddCircleOutlineIcon)`
  color: ${props => props.theme.palette.blue.main};
`;

const Actions = styled.div`
  margin-top: 15px;

  > :not(:last-child) {
    margin-bottom: 10px;
  }
`;