import { cx } from '@utils';
import styles from './style/ResearchCommunity.css';

type Props = {
  children:   React.ReactNode;
  className?: string;
};

export const Screen = (props: Props) => {
  return (
    <div className={cx(styles.root, props.className)}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

Screen.displayName = 'ResearchCommunity.Layout.Screen';

export const Title = (props: Props) => {
  return (
    <h1 className={cx(styles.title, props.className)}>
      {props.children}
    </h1>
  );
};

export const Subtitle = (props: Props) => {
  return (
    <div className={cx(styles.subtitle, props.className)}>
      {props.children}
    </div>
  );
};