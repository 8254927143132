import type { ApplySchemaAttributes, MarkExtensionSpec } from '@remirror/core';
import {
  helper,
  Helper,
  extension,
  ExtensionTag,
  MarkExtension,
} from '@remirror/core';
import type { Confidence } from '@/types/transcribe.rich-text';
import { MarkType } from '@/types/transcribe.rich-text';
import classes from './style/Confidence.css';

type Params = {
  defaultThreshold?: number;
  enabled: boolean;
};

@extension({
  defaultOptions: {},
  disableExtraAttributes: true,
})
export class ConfidenceExtension extends MarkExtension {
  get name() {
    return MarkType.Confidence as const;
  }

  confidenceThreshold: number;
  enabled: boolean;

  constructor(data: Params) {
    super();
    this.confidenceThreshold = data.defaultThreshold ?? .22;
    this.enabled = data.enabled;
  }

  createTags() {
    return [ExtensionTag.FormattingMark];
  }

  createMarkSpec(extra: ApplySchemaAttributes): MarkExtensionSpec {
    return {
      attrs: {
        c: { default: null },
        s: { default: [] },
      },
      parseDOM: [
        {
          tag: 'span',
          getAttrs: extra.parse,
        },
      ],
      toDOM: mark => {
        const attrs = mark.attrs as Confidence.Attributes;
        const className = attrs.c && attrs.c < this.confidenceThreshold && this.enabled
          ? classes.lowConfidence
          : null;

        return ['span', {
          class: className,
          // 'data-st': attrs.s.toString(),
          // 'data-et': attrs.e.toString(),
          ...extra.dom(mark),
        }, 0];
      },
    };
  }

  @helper()
  getConfidenceThreshold(): Helper<number> {
    return this.confidenceThreshold;
  }

  @helper()
  isConfidenceEnabled(): Helper<boolean> {
    return this.enabled;
  }
}