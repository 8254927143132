import { memo } from 'react';
import { Helmet } from 'react-helmet';
import { path } from '@consts';
import { Landing } from './Landing';
import { Leadership } from './Leadership';
import { BannerCareers } from './BannerCareers';
import { BannerFooter } from './BannerFooter';
import styles from './style/About.css';

type Props = unknown;

const H = memo(() => {
  const description = `Sentiment is dedicated to becoming the best-in-class solution for our customers' research and decision making.`;

  return (
    <Helmet
      title="About Us">
      <link
        rel="canonical"
        href={`${process.env.FRONT_BASE_URL}${path.Website.About}`} />
      <meta
        name='description'
        content={description} />
      <meta
        name='og:description'
        content={description} />
    </Helmet>
  );
});

export const About = (props: Props) => {

  return (
    <div className={styles.root}>
      <H />
      <Landing />
      <Leadership />
      <BannerCareers />
      <BannerFooter />
    </div>
  );
};

About.displayName = 'About';