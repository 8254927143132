import { useCallback, useEffect } from 'react';
import * as enums from '@/enums';
import { Input } from '@/components/Input';

type Props = {
  onChange: (value: number | string) => unknown;
  type:     enums.ProjectType;
  value:    number;
};

const GoalInput = (props: Props) => {
  const max = validateGoalsInput({ goal: Infinity, projectType: props.type });

  const updateGoalValue = useCallback((value: number) => {
    const goal = validateGoalsInput({
      goal: value,
      projectType: props.type,
    });

    props.onChange(goal);
  }, [props.onChange, props.type]);

  useEffect(() => {

    updateGoalValue(0);

  }, [props.type]);

  useEffect(() => {

    updateGoalValue(props.value ?? 0);

  }, []);

  function handleBlur({ target }: React.ChangeEvent<HTMLInputElement>) {
    const value = parseInt(target.value);
    const num = Number.isNaN(value) ? 0 : value;

    updateGoalValue(num);
  }

  function handleChange({ target }: React.ChangeEvent<HTMLInputElement>) {
    const value = parseInt(target.value);

    props.onChange(Number.isNaN(value) ? target.value : value);
  }

  return (
    <Input
      min={1}
      max={max}
      name="goals"
      onBlur={handleBlur}
      onChange={handleChange}
      step="1"
      type="number"
      value={props.value} />
  );
};

export { GoalInput };
export default GoalInput;

type ValidateParams = {
  goal: number;
  projectType: enums.ProjectType;
};

function validateGoalsInput(data: ValidateParams) {
  const min = {
    [enums.ProjectType.Call]: 1,
    [enums.ProjectType.SurveyCall]: 1,
    [enums.ProjectType.Survey]: 10,
  }[data.projectType];

  const max = {
    [enums.ProjectType.Call]: 100,
    [enums.ProjectType.SurveyCall]: 100,
    [enums.ProjectType.Survey]: 1000,
  }[data.projectType];

  return data.goal >= 1 && data.goal <= max
       ? data.goal
       : data.goal > max
         ? max
         : min;
}