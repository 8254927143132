import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import * as actions from '@actions';
import * as api from '@api';
import { useAppReadyState } from '@containers/AppReadyState';
import { canJoinConference } from '@containers/Scheduler/utils.calls';
import { useSelectUser, useIsInternalUser } from '@containers/Store';
import * as selectors from '@store/selectors';
import { hasClientRole, useToggle } from '@utils';
import { useUpcomingCalls } from '@utils/api';
import { ConnectAccountProvider } from '@/components/ConnectAccount';
import { useOutlookCalendarSync } from '@/components/ConnectAccount/hooks';
import { ConnectOutlookCalendar } from '@/components/Modal/ConnectOutlookCalendar';
import Toast from '@/components/Toast';

type Props = unknown;

const OutlookCalendarPrompt = (props: Props) => {
  const user = useSelectUser();
  const dispatch = useDispatch();
  const connected = useSelector(selectors.selectMicrosoftCalendar);
  const calls = useSelector(selectCalls);
  const { initialized } = useAppReadyState();
  const [open, toggle] = useToggle();
  const hasDismissed = useSelector(selectConnectCalendarAlert);
  const [dismissed, setDismissed] = useState(hasDismissed);
  const [busy, setBusy] = useState(true);

  useUpcomingCalls({
    refetchOnWindowFocus: false,
    onSuccess: res => {
      const isBusy = res.some(item => canJoinConference(calls[item.call.id]));
      setBusy(isBusy);
    },
  });

  const handleConnected = useCallback(() => {
    Toast.success({
      title: `Outlook Calendar Connected`,
    });

    if (open) toggle();
  }, [
    open,
    toggle,
  ]);

  const mutation = useMutation([
    `patch:users/prompts`,
    user?.id,
  ], () => {
    return api.users.updatePrompts({
      connectCalendarDismissed: true,
    });
  }, {
    onMutate: () => {
      setDismissed(true);

      if (open) toggle();
    },
    onSuccess: res => {
      dispatch(actions.platformAlertChanged({
        connectCalendarDismissed: res.connectCalendarDismissed,
      }));
    },
  });

  const handleClose = useCallback(() => {
    setDismissed(true);

    if (open) toggle();
  }, [
    open,
    setDismissed,
    toggle,
  ]);

  const handleDismiss = useCallback(() => {
    mutation.mutate();
  }, [
    mutation,
  ]);

  const outlook = useOutlookCalendarSync({
    onConnected: handleConnected,
  });

  useEffect(() => {

    if (initialized && !busy && !connected && !dismissed && !open) {
      toggle();
    }

  }, [
    busy,
    connected,
    initialized,
    dismissed,
    open,
    toggle,
  ]);

  return (
    <ConnectOutlookCalendar
      onClose={handleClose}
      onConnect={outlook.connect}
      onDismiss={handleDismiss}
      open={open} />
  );
};

OutlookCalendarPrompt.displayName = 'OutlookCalendarIntegration.Prompt';

export const OutlookCalendarIntegration = (props: Props) => {
  const { initialized } = useAppReadyState();
  const internal = useIsInternalUser();

  if (!initialized) return null;

  if (!internal) return null;

  return (
    <ConnectAccountProvider>
      <OutlookCalendarPrompt />
    </ConnectAccountProvider>
  );
};

OutlookCalendarIntegration.displayName = 'OutlookCalendarIntegration';

function selectConnectCalendarAlert(state: Store.State) {
  return state.platform.alerts.connectCalendarDismissed;
}

function selectCalls(state: Store.State) {
  return state.calls;
}