import { useMemo } from 'react';
import * as enums from '@enums';
import {
  UserObjectAccessContext,
} from './Context';
import { useObjectAccessContext } from './hooks';

type Props = {
  selectedRole: enums.WorkspaceObjectRole;
} & IUserId
  & ChildrenProps;

export const UserObjectAccessContainer = (props: Props) => {
  const { object } = useObjectAccessContext();

  const roles = useMemo(() => {
    if (object.typeId === enums.WorkspaceObjectType.Folder) {
      return [enums.WorkspaceObjectRole.Viewer];
    } else {
      return [
        enums.WorkspaceObjectRole.Collaborator,
        enums.WorkspaceObjectRole.Viewer,
      ];
    }
  }, [
    object.typeId,
  ]);

  const value = {
    roles,
    selectedRole: props.selectedRole,
    userId: props.userId,
  };

  return (
    <UserObjectAccessContext.Provider value={value}>
      {props.children}
    </UserObjectAccessContext.Provider>
  );
};

export default UserObjectAccessContainer;