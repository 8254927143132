import { memo } from 'react';
import { IconProps } from 'react-feather';
import { cx } from '@utils';
import styles from './style/Notification.Base.css';

type Props = {
  className?: string;
  Icon: React.ComponentType<IconProps>;
  text: string;
  title: string;
} & Partial<ChildrenProps>;

export const Notification = memo(({
  className,
  children,
  Icon,
  text,
  title,
}: Props) => {

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.wrap}>
        <div className={styles.content}>
          <Icon className={styles.icon} />
          <div className={styles.body}>
            <div className={styles.title}>{title}</div>
            <div className={styles.text}>{text}</div>
          </div>
        </div>

        {children}
      </div>
    </div>
  );
});

export default Notification;