import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as api from '@api';
import * as actions from '@actions';
import * as $chat from '@services/chat';

type Params = {
  body: string;
  paid: boolean;
} & IUserId;

export function useSendMessage() {
  const dispatch = useDispatch();

  return useCallback((params: Params) => {
    return api.messages.send({
      userId: params.userId,
      body: params.body,
      paid: params.paid,
    })
    .then(async result => {
      if (result.bypass) {
        const conversation = await $chat.getClient().getConversationBySid(result.conversationSid);

        conversation.sendMessage(params.body, {
          tempId: Date.now(),
        });

        return;
      }
      dispatch(
        actions.platformMessageAdded({
          conversationSid: result.platformMessage.channelSid,
          message: result.platformMessage,
        })
      );
    });
  }, [dispatch]);
}

export default useSendMessage;