import type { SurveyQuestion } from '@/types/survey';
import type {
  SurveyBuilder,
} from '../interfaces';
import { conjointAnalysisReducer } from './state.conjoint-analysis';
import { exclusiveOptionsReducer } from './state.exclusive-options';
import { maxDiffReducer } from './state.maxdiff';
import { optionsReducer } from './state.options.reducer';
import { rowsReducer } from './state.rows.reducer';
import { settingsReducer } from './state.settings.reducer';
import * as $actions from './state.questions';
import * as $template from './state.questions.template';

export function questionsReducer(state: SurveyBuilder.State, action: SurveyBuilder.Actions): SurveyQuestion[] {
  const questions = state.survey.questions;

  switch (action.type) {

    case 'question-added':
      return $actions.questionAdded(state.survey, action);

    case 'question-removed':
      return $actions.removeQuestion(questions, action);

    case 'question-copied':
      return $actions.questionCopied(state, action);

    case 'question-metadata-updated':
      return $actions.metadataUpdated(questions, action);

    case 'update-question':
      return $actions.updateQuestion(questions, action);

    case 'update-question-value':
      return $actions.updateQuestion(questions, {
        item: {
          ...questions.find(f => f.base.identifier === action.identifier),
          value: action.value,
        },
      });

    case 'update-question-capture-sheet-label':
      return $actions.updateQuestion(questions, {
        item: {
          ...questions.find(f => f.base.identifier === action.questionIdentifier),
          captureSheetLabel: action.label,
        },
      });

    case 'update-question-capture-sheet-color':
      return $actions.updateQuestion(questions, {
        item: {
          ...questions.find(f => f.base.identifier === action.questionIdentifier),
          captureSheetColor: action.color,
        },
      });

    case 'update-question-settings':
      return $actions.updateQuestion(questions, {
        item: {
          ...questions.find(f => f.base.identifier === action.questionIdentifier),
          settings: action.settings,
        } as SurveyQuestion,
      });

    case 'update-question-type':
      return $actions.updateQuestionType(questions, action);

    case 'update-question-identifier':
      return $actions.updateQuestionIdentifier(questions, action);

    case 'revert-uncommitted-changes':
      return state.committed.questions;

    case 'remove-section':
      return $actions.removeSection(state.survey, action);

    case 'section-break-added':
      return $actions.sectionBreakAdded(state.survey, action);

    case 'item-moved':
      return $actions.itemMoved(state.survey, action);

    case 'maxdiff-add-option':
    case 'maxdiff-remove-option':
    case 'maxdiff-update-least-label':
    case 'maxdiff-update-most-label':
    case 'maxdiff-update-option-name':
    case 'maxdiff-update-options-per-set':
    case 'maxdiff-update-sets':
      return maxDiffReducer(state, action);

    case 'conjoint-add-attribute':
    case 'conjoint-remove-attribute':
    case 'conjoint-add-level':
    case 'conjoint-remove-level':
    case 'conjoint-update-attribute-name':
    case 'conjoint-update-level-name':
    case 'conjoint-update-concepts-per-set':
    case 'conjoint-toggle-none-of-the-above':
    case 'conjoint-update-number-of-sets':
      return conjointAnalysisReducer(state, action);

    case 'exclusive-options-add-option':
    case 'exclusive-options-add-section':
    case 'exclusive-options-delete-section':
      return exclusiveOptionsReducer(state, action);

    case 'add-question-option':
    case 'add-question-options':
    case 'remove-question-option':
    case 'update-question-option-value':
    case 'option-anchor-updated':
    case 'option-exclusive-updated':
    case 'option-openended-updated':
    case 'option-readonly-updated':
    case 'matrix-range-option-metadata-updated':
    case 'number-input-table-option-validations-updated':
    case 'replace-options':
      return optionsReducer(state, action);

    case 'add-question-row':
    case 'add-question-rows':
    case 'remove-question-row':
    case 'update-question-row-value':
    case 'row-anchor-updated':
    case 'last-row-in-grid-updated':
    case 'row-openended-updated':
    case 'matrix-range-row-metadata-updated':
      return rowsReducer(state, action);

    case 'toggle-not-applicable':
    case 'toggle-allow-empty-values':
    case 'rationale-updated':
    case 'preset-options-updated':
    case 'randomize-updated':
    case 'reuse-options-order-updated':
    case 'reuse-rows-order-updated':
      return settingsReducer(state, action);

    case 'question-options-copied':
      return $actions.questionOptionsCopied(state, action);
    case 'question-options-copied-from-aie':
      return $actions.questionOptionsCopiedFromAIE(state, action);
    case 'question-rows-copied':
      return $actions.questionRowsCopied(questions, action);
    case 'copied-options-removed':
      return $actions.copiedOptionsRemoved(questions, action);
    case 'copied-rows-removed':
      return $actions.copiedRowsRemoved(questions, action);

    case 'template-target-updated':
      return $template.templateTargetUpdated(state, action);
    case 'template-partnership-criteria-updated':
      return $template.templatePartnershipCriteriaUpdated(state, action);
    case 'template-eval-criteria-updated':
      return $template.templateEvaluationCriteriaUpdated(state, action);
    case 'template-target-roles-updated':
      return $template.templateTargetRolesUpdated(state, action);

    case 'template-drug-indication-updated':
      return $template.drugIndicationUpdated(state, action);
    case 'template-qol-updated':
      return $template.qualityOfLifeUpdated(state, action);
    case 'template-trial-design-updated':
      return $template.trialDesignUpdated(state, action);
    case 'template-safety-endpoints-updated':
      return $template.safetyEndpointsUpdated(state, action);
    case 'template-efficacy-endpoints-updated':
      return $template.efficacyEndpointsUpdated(state, action);
    case 'template-other-attributes-updated':
      return $template.otherAttributesUpdated(state, action);
    case 'template-pricing-comparators-updated':
      return $template.pricingComparatorsUpdated(state, action);
    case 'template-treatment-comparators-updated':
      return $template.treatmentComparatorsUpdated(state, action);
    case 'template-dose-administration-updated':
      return $template.doseAdministrationUpdated(state, action);

    default:
      return questions;

  }
}