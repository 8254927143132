export const APP_DATA_FETCHED = 'APP_DATA_FETCHED';
export const APP_STATE_REHYDRATED = 'APP_STATE_REHYDRATED';

export const BATCH_ACTIONS = 'BATCH_ACTIONS';

export const ADHOC_CALL_CREATED = 'ADHOC_CALL_CREATED';
export const ADHOC_CALL_UPDATED = 'ADHOC_CALL_UPDATED';
export const PROJECT_CALL_CREATED = 'PROJECT_CALL_CREATED';
export const CALL_UPDATED = 'CALL_UPDATED';

export const CHAT_CONVERSATIONS_LOADED = 'CHAT_CONVERSATIONS_LOADED';
export const CHAT_CONVERSATION_ADDED = 'CHAT_CONVERSATION_ADDED';
export const CHAT_CONVERSATION_JOINED = 'CHAT_CONVERSATION_JOINED';
export const CHAT_CONVERSATION_REMOVED = 'CHAT_CONVERSATION_REMOVED';
export const CHAT_CONVERSATION_UPDATED = 'CHAT_CONVERSATION_UPDATED';
export const CHAT_CONNECTION_STATE_CHANGE = 'CHAT_CONNECTION_STATE_CHANGE';
export const CHAT_CONNECTION_ADDED = 'CHAT_CONNECTION_ADDED';

export const CONNECTED_ACOUNTS_CHANGED = 'CONNECTED_ACOUNTS_CHANGED';

export const CONFERENCE_ADDED = 'CONFERENCE_ADDED';
export const CONFERENCE_UPDATED = 'CONFERENCE_UPDATED';

export const CONNECTION_CHANGE = 'CONNECTION_CHANGE';

export const CONTACTS_ADDED = 'CONTACTS_ADDED';

export const DOCUMENTS_FETCHED = 'DOCUMENTS_FETCHED';

export const GROUP_BRANDING_UPDATED = 'GROUP_BRANDING_UPDATED';

export const LOCATION_CHANGE = 'LOCATION_CHANGE';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const POP_FROM_HISTORY = 'POP_FROM_HISTORY';

export const CHAT_PARTICIPANT_ADDED = 'CHAT_PARTICIPANT_ADDED';

export const CHAT_MESSAGE_ADDED = 'CHAT_MESSAGE_ADDED';
export const CHAT_MESSAGE_SENT = 'CHAT_MESSAGE_SENT';
export const CHAT_MESSAGE_UPDATED = 'CHAT_MESSAGE_UPDATED';

export const PLATFORM_ALERT_CHANGED = 'PLATFORM_ALERT_CHANGED';

export const CHAT_PLATFORM_MESSAGE_ADDED = 'CHAT_PLATFORM_MESSAGE_ADDED';
export const CHAT_PLATFORM_MESSAGE_UPDATED = 'CHAT_PLATFORM_MESSAGE_UPDATED';
export const CHAT_PLATFORM_MESSAGE_REMOVED = 'CHAT_PLATFORM_MESSAGE_REMOVED';

export const NOTIFICATIONS_LOADED = 'NOTIFICATIONS_LOADED';
export const NOTIFICATIONS_DISMISSED = 'NOTIFICATIONS_DISMISSED';
export const NOTIFICATIONS_SEEN = 'NOTIFICATIONS_SEEN';
export const NOTIFICATION_ADDED = 'NOTIFICATION_ADDED';

export const ONBOARDING_COMPLETED = 'ONBOARDING_STEP_COMPLETED';

export const PREFERENCES_SETTING_CHANGED = 'PREFERENCES_SETTING_CHANGED';

export const PROJECT_CLONED = 'PROJECT_CLONED';
export const PROJECT_CREATED = 'PROJECT_CREATED';
export const PROJECT_REMOVED = 'PROJECT_REMOVED';
export const PROJECT_UPDATED = 'PROJECT_UPDATED';
export const PROJECT_PIPELINE_UPDATED = 'PROJECT_PIPELINE_UPDATED';
export const PROJECT_PIPELINE_ITEM_CHANGED = 'PROJECT_PIPELINE_ITEM_CHANGED';
export const PROJECT_OVERVIEW_UPDATED = 'PROJECT_OVERVIEW_UPDATED';
export const USER_PIPELINE_UPDATED = 'USER_PIPELINE_UPDATED';

export const PROJECT_PARENT_UPDATED = 'PROJECT_PARENT_UPDATED';

export const USER_AUTHENTICATION_CHANGE = 'USER_AUTHENTICATION_CHANGE';
export const USER_CHECK_FINISHED = 'USER_CHECK_FINISHED';
export const USER_CHECK_STARTED = 'USER_CHECK_STARTED';
export const USER_INFO_CHANGE = 'USER_INFO_CHANGE';
export const USER_SETTINGS_UPDATED = 'USER_SETTINGS_UPDATED';
export const USER_LOGOUT = 'USER_LOGOUT';

export const NOOP = 'NOOP';

export const UPDATE_GROUP_SETTINGS = 'UPDATE_GROUP_SETTINGS';