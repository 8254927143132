import * as api from '@api';
import type * as API from '@api/interfaces';
import type { UseDownloaderOptions } from '@containers/QueryDownloader';
import { useQueryDownloader } from '@containers/QueryDownloader';

type Params = API.Transcripts.DownloadTranscriptReport.Request;

export const useDownloadTranscriptReport = (params: Params, options: UseDownloaderOptions<API.Transcripts.DownloadTranscriptReport.Response> = {}) => {
  return useQueryDownloader({
    queryKey: [
      `get:calls/transcripts/report`,
      params,
    ],
    queryFn: () => {
      return api.transcripts.downloadTranscriptReport({
        format: params.format,
        transcriptId: params.transcriptId,
      });
    },
    downloaderOptions: {
      onResponse: res => ({
        type: 'websocket',
        value: res.key,
      }),
    },
    ...options,
  });
};