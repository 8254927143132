import { useContext } from 'react';
import { ProjectStateLoadingContext } from '@containers/GroupProject/Context';
import { useHasInternalAdminRole } from '@containers/User/hooks';
import { useProjectState } from '@/containers/GroupProject/hooks';
import { ObjectAccessButton, SkeletonAccess } from '@/components/ObjectAccess/Button';
import { ProjectAccessModalContext, ProjectAccessQueryContext } from '@/components/Project.Access/Context';

type Props = {
  className?: string;
};

export const Access = (props: Props) => {
  const isLoading = useContext(ProjectStateLoadingContext);

  return isLoading
    ? <SkeletonAccess />
    : <InteractiveAccess className={props.className} />;
};

Access.displayName = 'Group.Project.Header.Access';

const InteractiveAccess = (props: Props) => {
  const ctx = {
    query: useContext(ProjectAccessQueryContext),
    toggle: useContext(ProjectAccessModalContext),
  };

  const project = useProjectState();
  const isInternalAdmin = useHasInternalAdminRole();

  if (!project.capabilities.canManageAccess && !isInternalAdmin) {
    return null;
  }

  return (
    <ObjectAccessButton
      className={props.className}
      count={ctx.query.data?.items?.length}
      onClick={ctx.toggle} />
  );
};

InteractiveAccess.displayName = 'Project.Header.Access.Interactive';