import { Fragment, useEffect } from 'react';
import { useHasConsultantRole } from '@containers/User';
import { useRecentProfiles, usePastUniversalSearch } from '@utils/api';

type Props = ChildrenProps;

export default function UniversalSearch({ children }: Props) {
  const isConsultant = useHasConsultantRole();

  const pastSearches = usePastUniversalSearch({ enabled: false });
  const recentProfiles = useRecentProfiles({ enabled: false });

  useEffect(() => {
    if (isConsultant) {
      pastSearches.refetch();
      recentProfiles.refetch();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConsultant]);

  return (
    <Fragment>
      {children}
    </Fragment>
  );
}

UniversalSearch.displayName = 'UniversalSearch.Container';

