import { memo, useCallback } from 'react';
import { Check } from 'react-feather';
import styled from '@emotion/styled';
import Hourglass from '@mui/icons-material/HourglassEmpty';
import { ChatFileUploadStatus } from '@/brand-insights/enums';
import { FileIcon } from '@/components/icons/File';
import { ProgressRing } from '@presentation/ProgressRing';
import { Link } from '@/brand-insights/components/presentation';
import { Tooltip } from '@presentation/Tooltip';
import { trunc } from '@/brand-insights/utils';
import { getLocationFor } from '@utils';

type Props = {
  className?: string;
  name: string;
  status: ChatFileUploadStatus;
  pctComplete: number;
  fileId: number;
};

export const FileUploadItem = memo(({ className, fileId, name, pctComplete, status }: Props) => {

  const extension = name ? name.split('.').pop() || '' : '';

  const renderStatus = useCallback(() => {

    switch (status) {
      case ChatFileUploadStatus.Initiated:
      case ChatFileUploadStatus.Started:
        return (
          <ProgressRing
            progress={pctComplete}
            size={24} />
        );

      case ChatFileUploadStatus.Uploaded:
        return (
          <ProgressRing
            progress={100}
            size={24} />
        );

      case ChatFileUploadStatus.Ready:
        return (
          <CompleteIcon />
        );

      case ChatFileUploadStatus.Errored:
        return (
          <ErrorIcon />
        );

      default:
        throw new UnreachableCaseError(status);
    }

  }, [pctComplete, status]);

  const renderName = useCallback(() => {
    return (
      <Name>{trunc(name, 50)}</Name>
    );
  }, [name]);

  const to = getLocationFor.workspaces.file({ fileId });

  return (
    <Root className={className}>

      <File>
        <StyledFileIcon
          extension={extension} />
        {fileId
          ? <Link to={to}>{renderName()}</Link>
          : renderName()}
      </File>

      <Status>
        {renderStatus()}
      </Status>
    </Root>
  );
});

const StyledHourglass = styled(Hourglass)(({ theme }) => ({
  color: theme.palette.gray.main,
}));

const InitiatedIcon = memo(() => (
  <InitiatedRoot>
    <StyledHourglass />
  </InitiatedRoot>
));

const CompleteIcon = memo(() => (
  <CheckRoot>
    <StyledCheck size={16} />
  </CheckRoot>
));

const UploadedIcon = memo(() => (
  <UploadedRoot>
    <StyledCheck size={16} />
  </UploadedRoot>
));

const ErrorIcon = memo(() => (
  <ErrorRoot>!</ErrorRoot>
));

const iconSize = 24;

const Status = styled.div({
  marginLeft: 15,
});

const StyledCheck = styled(Check)(({ theme }) => ({
  display: 'flex',
  flexShrink: 0,
  color: theme.palette.white.main,
}));

const StyledFileIcon = styled(FileIcon)({
  display: 'flex',
  flexShrink: 0,
});

const InitiatedRoot = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: iconSize,
  width: iconSize,
});

const UploadedRoot = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: iconSize,
  width: iconSize,
  borderRadius: '50%',
  backgroundColor: 'var(--pri-04)',
});

const CheckRoot = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: iconSize,
  width: iconSize,
  borderRadius: '50%',
  backgroundColor: theme.palette.green.main,
}));

const ErrorRoot = styled.div(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: iconSize,
  width: iconSize,
  borderRadius: '50%',
  fontFamily: theme.fonts.bold,
  color: theme.palette.red.main,
  border: `2px solid ${theme.palette.red.main}`,
}));

const Root = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: 10,
});

const File = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const Name = styled.div({
  marginLeft: 8,
  fontSize: 14,
});