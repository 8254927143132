import { Card as BaseCard } from '@presentation/Main';
import { cx } from '@utils';
import styles from './style/Layout.css';

type CardProps = {
  className?: string;
} & ChildrenProps;

export function Card({ className, children }: CardProps) {
  return (
    <BaseCard className={cx(styles.card, className)}>
      {children}
    </BaseCard>
  );
}

type ColumnProps = {
  className?: string;
} & ChildrenProps;

export function Column({ className, children }: ColumnProps) {
  return (
    <div className={cx(styles.col, className)}>
      {children}
    </div>
  );
}

type RowProps = {
  className?: string;
} & ChildrenProps;

export function Row({ className, children }: RowProps) {
  return (
    <div className={cx(styles.row, className)}>
      {children}
    </div>
  );
}

type HeaderProps = {
  className?: string;
  text: string;
} & Partial<ChildrenProps>;

export function Header({ className, children, text }: HeaderProps) {
  return (
    <div className={cx(styles.header, className)}>
      <div className={styles.title}>{text}</div>
      {children}
    </div>
  );
}

type ScreenLayoutProps = ChildrenProps;

export function ScreenLayout({ children }: ScreenLayoutProps) {
  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          {children}
        </div>
      </div>
    </div>
  );
}