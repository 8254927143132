import { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import * as consts from '@consts';
import { usePreventScrollRestoration } from '@containers/ScrollRestoration';
import { qs } from '@utils';
import {
  SiteStrategyDispatchContext,
  SiteStrategyStateContext,
} from './Context';
import {
  useAssertStrategy,
  useSiteStrategyDispatch,
} from './hooks';
import { SegmentQueryValue, Strategy } from './interfaces';

type Props = {
  children: React.ReactElement;
};

const MarketStrategySubscriber = (props: Props) => {
  const dispatch = useSiteStrategyDispatch();
  const assert = useAssertStrategy();

  usePreventScrollRestoration(useCallback((prev, next) => {
    return prev.pathname == consts.path.Website.Enterprise
        && [
          consts.path.Website.Consulting,
          consts.path.Website.Corporate,
          consts.path.Website.Investment,
        ].includes(next.pathname);
  }, []));

  const match = useRouteMatch({
    path: [
      consts.path.Website.Consulting,
      consts.path.Website.Corporate,
      consts.path.Website.Investment,
      ...individualPaths,
      ...enterprisePaths,
    ],
  });

  const matchRoot = useRouteMatch({
    path: consts.path.Website.Root,
  });

  const matchSignup = useRouteMatch({
    path: consts.path.Website.Signup.Root,
  });

  const handleIndividuals = useCallback(() => {
    if (assert(Strategy.Individuals)) return false;

    const matchesPath = individualPaths.some(path => path === match?.path);
    const matchesRoot = matchRoot?.path === consts.path.Website.Root
                     && matchRoot?.isExact;

    return matchesPath || matchesRoot;
  }, [
    assert,
    match?.path,
    matchRoot?.isExact,
    matchRoot?.path,
  ]);

  const handleInvestment = useCallback(() => {
    const matches = match?.path === consts.path.Website.Investment;

    return matches && !assert(Strategy.Investment);
  }, [
    assert,
    match?.path,
  ]);

  const handleEnterprise = useCallback(() => {

    const matches = enterprisePaths.some(path => path === match?.path);

    return matches && !assert(Strategy.Enterprise);

  }, [
    assert,
    match?.path,
  ]);

  const handleConsulting = useCallback(() => {
    const matches = match?.path === consts.path.Website.Consulting;

    return matches && !assert(Strategy.Consulting);
  }, [
    assert,
    match?.path,
  ]);

  const handleCorporate = useCallback(() => {

    if (assert(Strategy.Corporate)) return false;

    const matchesPath = match?.path === consts.path.Website.Corporate;
    const matchesQuery = matchSignup?.path === consts.path.Website.Signup.Root
                      && qs.getParam('s') === SegmentQueryValue.Corporate;

    return matchesPath || matchesQuery;

  }, [
    assert,
    match?.path,
    matchSignup?.path,
  ]);

  useEffect(() => {

    if (handleIndividuals()) {
      dispatch(Strategy.Individuals);
    } else if (handleEnterprise()) {
      dispatch(Strategy.Enterprise);
    } else if (handleCorporate()) {
      dispatch(Strategy.Corporate);
    } else if (handleConsulting()) {
      dispatch(Strategy.Consulting);
    } else if (handleInvestment()) {
      dispatch(Strategy.Investment);
    }

  }, [
    dispatch,
    handleConsulting,
    handleCorporate,
    handleEnterprise,
    handleIndividuals,
    handleInvestment,
    match,
  ]);

  return props.children;
};

const individualPaths = [
  consts.path.Website.About,
  consts.path.Website.Careers,
  consts.path.Website.Individuals,
];

const enterprisePaths = [
  consts.path.Website.Enterprise,
  consts.path.Website.CollaborationTools,
  consts.path.Website.Compliance,
  consts.path.Website.CustomerResearch,
  consts.path.Website.Pricing,
  consts.path.Website.Quant2Qual,
];

const MarketStrategySubscription = (props: Props) => {
  const [state, dispatch] = useState<Strategy>(Strategy.Individuals);

  return (
    <SiteStrategyStateContext.Provider value={state}>
      <SiteStrategyDispatchContext.Provider value={dispatch}>
        <MarketStrategySubscriber>
          {props.children}
        </MarketStrategySubscriber>
      </SiteStrategyDispatchContext.Provider>
    </SiteStrategyStateContext.Provider>
  );
};

const MarketStrategyContainer = (props: Props) => {
  return (
    <MarketStrategySubscription>
      {props.children}
    </MarketStrategySubscription>
  );
};

export { MarketStrategyContainer };
export default MarketStrategyContainer;