import graphic from 'static/icons/empty-search.svg?url';
import styles from './style.css';

type Props = {
  resultsCount: number;
  totalCount:   number;
};

const SearchListEmpty = (props: Props) => {
  const empty = props.resultsCount === 0 &&
                props.totalCount === 0;

  if (!empty) return null;

  return (
    <div className={styles.empty}>
      <div className={styles.graphic}>
        <img
          className={styles.image}
          src={graphic} />
      </div>

      <div className={styles.message}>Try expanding your search criteria</div>
    </div>
  );
};

export { SearchListEmpty };
export default SearchListEmpty;