import { useMemo } from 'react';
import { useProjectCloning } from '@containers/GroupProject/hooks';
import { Sidebar } from '@presentation';
import { LinearProgress } from '@/components/Progress/LinearProgress';
import styles from './style/ProjectCloning.css';

const steps = [
  {
    key: 0,
    title: 'Overview',
  },
  {
    key: 1,
    title: 'Survey Builder',
  },
];

export const ProjectCloning = () => {

  const {
    step,
    Routes,
  } = useProjectCloning();

  const progress = useMemo(() => {
    return (step + 1 / steps.length) * 100;
  }, [step]);

  return (
    <div className={styles.root}>
      <LinearProgress
        className={styles.progress}
        value={progress} />
      <div className={styles.sidebar}>
        <Sidebar
          className={styles.sidebarWrap}
          index={step}
          steps={steps} />
      </div>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.content}>
            <Routes />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCloning;