import { Modal, ModalProps } from '@/components/Modal/Modal';
import { Header } from '@/components/Modal/Header';
import { useModal } from '@/components/Modal/hooks';
import styles from './style/Validations.Modal.css';

type Props = {
  items: {
    fileName: string;
    errors: string[];
  }[];
} & Pick<ModalProps, 'open' | 'onClose'>;

export const FilesUploadValidationsModal = ({ items, onClose, open }: Props) => {

  return (
    <Modal onClose={onClose} open={open}>
      <Header text="Upload Errors" />

      <div className={styles.message}>
        {`The file(s) you selected couldn't be uploaded because of the following issues:`}
      </div>

      <div className={styles.items}>
        {items.map((item, i) => (
          <div key={i} className={styles.item}>
            <div className={styles.name}>{item.fileName}</div>
            <div className={styles.error}>{item.errors[0]}</div>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export const useFilesUploadValidationsModal = () => useModal(FilesUploadValidationsModal);