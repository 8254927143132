import { useCallback, useState } from 'react';
import type { SetContext } from './interfaces';
import { ParamsContext } from './Context';

type Props =
  ChildrenProps;

export const Params = (props: Props) => {

  const [params, dispatch] = useState<SetContext.Params>(null);

  const resetContext = useCallback(() => {
    dispatch(null);
  }, []);

  const ctx = {
    params,
    resetContext,
    setContext: dispatch,
  };

  return (
    <ParamsContext.Provider value={ctx}>
      {props.children}
    </ParamsContext.Provider>
  );
};

Params.displayName = 'Call.Scheduling.Params';