import bg1 from 'static/images/website/collaboration-tools/tagging-s.png';
import bg3 from 'static/images/website/collaboration-tools/tagging-l.png';
import styles from './style/TaggingTools.css';

type Props = unknown;

export const TaggingTools = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.content}>
          <div className={styles.main}>
            <div className={styles.graphic}>
              <picture>
                <source
                  media="(min-width: 800px)"
                  srcSet={bg3} />
                <img
                  alt="Customized lists of research participants"
                  className={styles.img}
                  src={bg1} />
              </picture>
            </div>
            <div className={styles.info}>
              <h2 className={styles.title}>Create customized lists of research participants</h2>
              <div className={styles.subtitle}>Utilize tagging tools to create reusable rosters of professionals for future surveys and qualitative interviews.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TaggingTools.displayName = 'CollaborationTools.TaggingTools';