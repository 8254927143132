import { Switch } from '@/components/Switch';
import styles from './style/SwitchField.css';

type Props = {
  label: string;
  onChange: (active: boolean) => unknown;
  value: boolean;
  disabled?: boolean;
};

const SwitchField = (props: Props) => {
  return (
    <div className={styles.section}>
      <div className={styles.label}>{props.label}</div>
      <Switch
        disabled={props.disabled}
        active={props.value}
        onClick={props.onChange} />
    </div>
  );
};

export { SwitchField };
export default SwitchField;