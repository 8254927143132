import type { SurveyQuestionsBuilder, SurveyOptionsBuilder, SurveyBuilder } from '../interfaces';
import * as $actions from './state.options';

export function optionsReducer(state: SurveyBuilder.State, action: SurveyOptionsBuilder.Action): SurveyQuestionsBuilder.State {

  switch (action.type) {
    case 'add-question-option':
      return $actions.addOption(state.survey.questions, action);

    case 'add-question-options':
      return $actions.addOptions(state.survey.questions, action);

    case 'replace-options':
      return $actions.replaceOptions(state.survey.questions, action);

    case 'remove-question-option':
      return $actions.removeOption(state.survey.questions, action);

    case 'update-question-option-value':
      return $actions.updateOptionValue(state.survey.questions, action);

    case 'option-anchor-updated':
      return $actions.isAnchorUpdated(state.survey.questions, action.payload);

    case 'option-exclusive-updated':
      return $actions.isExclusiveUpdated(state.survey.questions, action.payload);

    case 'option-openended-updated':
      return $actions.isOpenEndedUpdated(state.survey.questions, action.payload);

    case 'option-readonly-updated':
      return $actions.readonlyUpdated(state.survey.questions, action.payload);

    case 'matrix-range-option-metadata-updated':
      return $actions.matrixRangeOptionMetadataUpdated(state.survey.questions, action.payload);

    case 'number-input-table-option-validations-updated':
      return $actions.numberInputTableOptionValidationsUpdated(state.survey.questions, action.payload);

    default:
      return state.survey.questions;
  }
}