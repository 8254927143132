import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { path } from '@consts';
import { usePersistentProjectInvite } from '@containers/ProjectLinkInvitation/hooks/usePersistentProjectInvite';

export const useProjectInviteWhitelabelState = () => {
  const urlstate = usePersistentProjectInvite();
  const state = urlstate.get();

  const match = useRouteMatch({
    exact: true,
    path: [
      path.Invite.Root,
      path.Invite.Token,
      path.Website.Signup.Root,
    ],
  });

  const enabled = useMemo(() => {
    return !!match && !!state?.data?.theming;
  }, [
    match,
    state?.data?.theming,
  ]);

  return {
    enabled,
  };
};