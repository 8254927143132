import { Redirect, Route, Switch } from 'react-router-dom';
import * as consts from '@consts';
import { Inactive } from './Inactive';
import { NotAFit } from './NotAFit';

function SelfServeSurveyRouter() {
  return (
    <Switch>
      <Route
        component={Inactive}
        exact
        path={consts.path.SelfServeSurvey.Inactive} />
      <Route
        component={NotAFit}
        exact
        path={consts.path.SelfServeSurvey.NotAFit} />
      <Redirect to={consts.pathname.Home} />
    </Switch>
  );
}

SelfServeSurveyRouter.displayName = 'Router.SelfServeSurvey';

export { SelfServeSurveyRouter as SelfServeSurvey };