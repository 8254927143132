import type { SurveyQuestionType } from '@enums';
import type { MatrixRangeQuestion } from '@/types/survey';
import { parseSurveyRichText, convertToSurveyRichText } from '@/containers/Survey/utils';
import type { Validation } from '../interfaces';
import { assertNoDuplicateOptions, assertValidNumberOfOptions } from './validation.options';
import { assertValidRows } from './validation.rows';
import { assertHasValue, returnAssertFailure } from './validation.base';
import { isSurveyRichTextEmpty } from './rich-text.validate';

export const DefaultColumns = 5;
export const MaxColumns = 13;
export const MinColumns = 2;

export const DefaultRows = 5;
export const MaxRows = 30;
export const MinRows = 1;

export const DefaultSettings: MatrixRangeQuestion.Settings = {
  rightColumnLabel: convertToSurveyRichText(''),
  leftColumnLabel: convertToSurveyRichText(''),
  orderRowsBasedOnSource: false,
  randomizeRows: false,
  randomizeOptions: false,
  rationale: {
    enabled: false,
    minimum: 75,
  },
};

export const DefaultRowMetadata: MatrixRangeQuestion.RowMetadata = {
  canDelete: true,
  canModifyValue: true,
  isAnchored: false,
  template: {},
  leftStatement: convertToSurveyRichText(''),
  rightStatement: convertToSurveyRichText(''),
};

export const DefaultOptionMetadata: MatrixRangeQuestion.OptionMetadata = {
  canDelete: true,
  canModifyValue: true,
  helpText: '',
  template: {},
};

export function assertMatrixRangeSettings(question: MatrixRangeQuestion.Question): Validation.AssertionResult {
  if (isSurveyRichTextEmpty(question.settings.leftColumnLabel)) {
    return {
      success: false,
      message: 'Invalid Left Label',
    };
  }

  if (isSurveyRichTextEmpty(question.settings.rightColumnLabel)) {
    return {
      success: false,
      message: 'Invalid Right Label',
    };
  }

  return { success: true };
}

function assertMatrixRangeRows(question: MatrixRangeQuestion.Question): Validation.AssertionResult {
  const hasValidRows = assertValidRows(question.matrixRows, MinRows, MaxRows);
  if (hasValidRows.success === false) {
    return hasValidRows;
  }

  const hasEmptyLabels = question.matrixRows.some(s => {
    const rightStatement = parseSurveyRichText(s.metadata.rightStatement);
    if (!rightStatement || rightStatement === '') return true;

    const leftStatement = parseSurveyRichText(s.metadata.leftStatement);
    if (!leftStatement || leftStatement === '') return true;

    return false;
  });

  if (hasEmptyLabels) {
    return {
      success: false,
      message: 'Some row labels missing values',
    };
  }

  return { success: true };
}

export function assertValidOptions(options: MatrixRangeQuestion.Option[], min: number, max: number): Validation.AssertionResult {
  const hasNumberOfOptions
    = assertValidNumberOfOptions(options, min, max);

  if (hasNumberOfOptions.success === false) {
    return {
      success: false,
      message: hasNumberOfOptions.message,
    };
  }

  const hasUniqueOptions
    = assertNoDuplicateOptions(options);

  if (hasUniqueOptions.success === false) {
    return {
      success: false,
      message: hasUniqueOptions.message,
    };
  }

  return { success: true };
}

export function validateQuestion(question: MatrixRangeQuestion.Question): Validation.ValidateQuestionResult<SurveyQuestionType.MatrixGrid> {
  const hasValue = assertHasValue(question);
  if (hasValue.success === false) {
    return returnAssertFailure(hasValue, question);
  }

  const hasValidOptions = assertValidOptions(question.options, MinColumns, MaxColumns);
  if (hasValidOptions.success === false) {
    return returnAssertFailure(hasValidOptions, question);
  }

  const hasValidRows = assertMatrixRangeRows(question);
  if (hasValidRows.success === false) {
    return returnAssertFailure(hasValidRows, question);
  }

  const hasValidSettings = assertMatrixRangeSettings(question);
  if (hasValidSettings.success === false) {
    return returnAssertFailure(hasValidSettings, question);
  }

  return {
    success: true,
    result: {
      question,
    },
  };
}
