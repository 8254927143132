import http from '@services/http';

type APIKey = {
  key: string;
};

export const getTableauAPIKey = () => {
  return http.get<APIKey>(`/tableau/api-key`);
};

export const requestNewAPIKey = () => {
  return http.post(`/tableau/request-api-key`, {}).then(r => {
    return r as APIKey;
  });
};

export const deleteAPIKeys = () => {
  return http.delete(`/tableau/api-keys`);
};