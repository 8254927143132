import { ComponentType, ReactNode } from 'react';
import { WorkspacePathValue } from '@/types';

export enum FileRouteKey {
  // Details = 'details',
  Preview = 'preview',
  // Tags    = 'tags',
}

export enum FolderRouteKey {
  Details  = 'details',
  Files    = 'files',
}

export enum WorkspaceRouteKey {
  Files    = 'files',
}

export enum SharedRouteKey {
  Files    = 'files',
}

export type FileSceneMap = {
  [key in FileRouteKey]: TabScene;
};

export type WorkspaceSceneMap = {
  [key in WorkspaceRouteKey]: TabScene;
};

export type FolderSceneMap = {
  [key in FolderRouteKey]: TabScene;
};

export type SharedSceneMap = {
  [key in SharedRouteKey]: TabScene;
};

export type FileRouteParams = {
  fileId: string;
  tab?: FileRouteKey;
};

export type WorkspaceRouteParams = {
  tab?: WorkspaceRouteKey;
  workspace: WorkspacePathValue;
};

export type FolderRouteParams = {
  folderId: string;
  tab?: FolderRouteKey;
};

export type SharedRouteParams = {
  tab?: FolderRouteKey;
};

export type TabScene = {
  component: ComponentType;
  exact?:    boolean;
  path:      string;
  title:     string | ReactNode;
};