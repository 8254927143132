import { useCallback, useContext } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { querykey as QK } from '@consts';
import { MedicalExpertiseContext } from '@containers/MedicalOnboarding/Context';

export const useResetMedicalOnboardingQueries = () => {
  const ctx = useContext(MedicalExpertiseContext);
  const qc = useQueryClient();

  const resetQueries = () => {
    qc.resetQueries(QK.Users.Onboarding.Background.Get({
      userId: ctx.userId,
    }));
    qc.resetQueries(QK.Users.Onboarding.Expertise.Get({
      userId: ctx.userId,
    }));
  };

  return useCallback(resetQueries, [
    ctx.userId,
    qc,
  ]);
};