import * as api from '@api';
import { useSelectUser } from '@containers/Store/hooks/useSelectUser';
import { useAsyncStateLazy } from '@utils';

const useFetchAccountProfileSyncState = () => {

  const me = useSelectUser();

  const [response, fetchSyncState] = useAsyncStateLazy(() => {
    return api.profiles.enrichment.fetchSyncState({
      userId: me.id,
    });
  }, [me]);

  return [response, fetchSyncState] as const;

};

export { useFetchAccountProfileSyncState };
export default useFetchAccountProfileSyncState;