import type { RefObject } from 'react';
import { createElement, forwardRef, useContext } from 'react';
import type { Row as RTRow } from 'react-table';
import type { ColumnDef, RowModel, Table } from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';
import { cx } from '@utils';
import { TableContext } from './Context';
import { Cell } from './Table.Body.Cell';
import styles from './style/Table.css';
import v8Styles from './style/V8Table.css';

type Props<T extends ObjectLike> = {
  classes?: {
    tbody?: string;
    td?: string;
    tr?: string;
  };
  prepareRow?: (row: RTRow<T>) => void;
  rows?: RTRow<T>[];
  getRowModel?: <U = T>() => RowModel<U>;
  getBodyCellStyles?: (def: ColumnDef<T>) => string;
  getRowClass?: (row: RTRow<T>) => string;
};

const Body = forwardRef(<T extends ObjectLike>({ classes = {}, prepareRow, ...props }: Props<T>, ref?: RefObject<HTMLDivElement>) => {
  const ctx = useContext(TableContext);

  if (ctx.empty && ctx.EmptyComponent) {
    return (
      <TBody
        className={classes.tbody}
        ref={ref}>
        {createElement(ctx.EmptyComponent)}
      </TBody>
    );
  }

  if (typeof props.getRowModel === 'function') {
    return (
      <TBody
        className={classes.tbody}
        ref={ref}>
        {props.getRowModel().rows.map(row =>
          <Row
            className={cx(classes.tr, v8Styles.tr)}
            key={row.id}>
            {row.getVisibleCells().map(cell =>
              <Cell
                className={cx(classes.td, props.getBodyCellStyles?.(cell.column.columnDef))}
                loading={ctx.loading}
                key={cell.id}
                rowIndex={cell.row.index}>
                {flexRender(
                  cell.column.columnDef.cell,
                  cell.getContext()
                )}
              </Cell>)}
          </Row>)}
      </TBody>
    );
  }

  return (
    <TBody
      className={classes.tbody}
      ref={ref}>
      {props.rows.map((row, i) => {

        prepareRow?.(row);

        return (
          <Row
            className={cx(classes.tr, props.getRowClass ? props.getRowClass(row) : null)}
            key={i}
            style={row.getRowProps()?.style}>
            {row.cells.map((cell, i) =>
              <Cell
                className={classes.td}
                loading={ctx.loading}
                key={cell.column.id}
                rowIndex={cell.row.index}
                style={cell.getCellProps?.()?.style}>
                {cell.render('Cell')}
              </Cell>)}
          </Row>
        );
      })}
    </TBody>
  );
});

Body.displayName = 'Table.Body';

type TBodyProps = {
  children?: React.ReactNode;
  className?: string;
};

const TBody = forwardRef((props: TBodyProps, ref?: RefObject<HTMLDivElement>) => {
  return (
    <div
      className={cx(styles.body, props.className)}
      ref={ref}
      role="rowgroup">
      {props.children}
    </div>
  );
});

TBody.displayName = 'Table.Body.TBody';

type RowProps = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  [dataAttribute: `data-${string}`]: string;
};

const Row = forwardRef(({ children, className, style = {}, ...props }: RowProps, ref?: RefObject<HTMLDivElement>) => {
  return (
    <div
      className={cx(styles.tr, className)}
      ref={ref}
      role="row"
      style={style}
      {...props}>
      {children}
    </div>
  );
});

Row.displayName = 'Table.Body.Row';

const BodyNamespace = Object.assign(Body, {
  TBody,
  TD: Cell,
  TR: Row,
});

export { BodyNamespace as Body };