import { useCallback } from 'react';
import type { MatrixRangeQuestion } from '@/types/survey';
import { useParseSurveyRichText } from '@/containers/SurveyBuilder/hooks';
import { SurveyBuilderQuestionMetadata, SurveyBuilderQuestionRow, SurveyBuilderQuestionSettings } from '@/components/Survey.RichText/ReadOnly';
import { Bullet } from './Bullet';
import { SurveyQuestionLogic } from './Logic';
import { SurveyQuestionClassifications } from './Tagging';
import styles from './style/MatrixRange.css';
import {
  useQuestionClassifications,
  useQuestionLogic,
} from './hooks';
import { SurveySettings } from './Settings';

type Props = {
  item: MatrixRangeQuestion.Question;
};

export const MatrixRangePreview = ({ item }: Props) => {

  const parseSurveyRichText = useParseSurveyRichText();
  const questionLogic = useQuestionLogic(item.base.identifier);
  const questionClassifications = useQuestionClassifications(item.base.identifier);

  const renderRow = useCallback((row: MatrixRangeQuestion.Row) => {
    return (
      <div key={row.base.identifier} className={styles.rowItem}>
        <div className={styles.row}>
          <div className={styles.label}>Name:</div>
          <SurveyBuilderQuestionRow
            value={row.value}
            identifier={row.base.identifier} />
        </div>
        <div className={styles.row}>
          <div className={styles.label}>Left Label:</div>
          <SurveyBuilderQuestionMetadata
            value={row.metadata.leftStatement}
            identifier={`${row.base.identifier}:metadata:leftStatement`} />
        </div>
        <div className={styles.row}>
          <div className={styles.label}>Right Label:</div>
          <SurveyBuilderQuestionMetadata
            value={row.metadata.rightStatement}
            identifier={`${row.base.identifier}:metadata:rightStatement`} />
        </div>
      </div>
    );
  }, []);

  const renderOption = useCallback((option: MatrixRangeQuestion.Option) => {
    const text = [parseSurveyRichText(option.value), option.metadata.helpText]
      .filter(Boolean)
      .join(' - ');

    return (
      <div key={option.base.identifier} className={styles.optionItem}>
        <Bullet className={styles.bullet} text={`A${option.ordinal}`} />
        <div className={styles.value}>{text}</div>
      </div>
    );
  }, [parseSurveyRichText]);

  return (
    <>
      <div className={styles.row}>
        <div className={styles.columnLabel}>Left Column Label:</div>
        <SurveyBuilderQuestionSettings
          value={item.settings.leftColumnLabel}
          identifier={`${item.base.identifier}:settings:leftColumnLabel`} />
      </div>
      <div className={styles.row}>
        <div className={styles.columnLabel}>Right Column Label:</div>
        <SurveyBuilderQuestionSettings
          value={item.settings.rightColumnLabel}
          identifier={`${item.base.identifier}:settings:rightColumnLabel`} />
      </div>
      <div className={styles.header}>Rows</div>
      <div>
        {item.matrixRows.map(renderRow)}
      </div>
      <div className={styles.header}>Choices</div>
      {item.options.map(renderOption)}
      <SurveySettings>
        {item.settings.orderRowsBasedOnSource && <>Order rows based on source question</>}
        {item.settings.randomizeRows && <>Randomize rows</>}
        {item.settings.randomizeOptions && <>Randomize choices</>}
      </SurveySettings>
      <SurveyQuestionLogic items={questionLogic} />
      <SurveyQuestionClassifications items={questionClassifications} />
    </>
  );
};