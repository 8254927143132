import { hasRows, RowsQuestion } from '@containers/Survey/utils/questions';
import { parseSurveyRichText } from '@containers/Survey/utils';
import { MatrixRangeQuestion, SurveyQuestion, SurveyQuestionMatrixRow } from '@/types/survey';
import { SurveyRowsBuilder, SurveyQuestionsBuilder } from '../interfaces';
import { generateNewMatrixRow } from './defaults';

export * from './state.rows.metadata';

export function addRow(state: SurveyQuestionsBuilder.State, action: SurveyRowsBuilder.AddRow.State): SurveyQuestionsBuilder.State {

  const question = state
    .find(f => f.base.identifier === action.questionIdentifier);

  if (!hasRows(question)) return state;

  const newRow = generateNewMatrixRow(question.matrixRows.length + 1, question.typeId);

  return addRows(state, {
    questionIdentifier: action.questionIdentifier,
    rows: [newRow],
    reorder: false,
  });

}

export function addRows(state: SurveyQuestionsBuilder.State, action: SurveyRowsBuilder.AddRows.State): SurveyQuestionsBuilder.State {

  return state.reduce<SurveyQuestionsBuilder.State>((acc, q) => {
    if (q.base.identifier === action.questionIdentifier) {
      acc.push(updateQuestionRows(q as RowsQuestion));
    } else {
      acc.push(q);
    }

    return acc;
  }, []);

  function updateQuestionRows(q: RowsQuestion): SurveyQuestion {

    const matrixRowsCasted = (q.matrixRows as SurveyQuestionMatrixRow[]);

    let rows: SurveyQuestionMatrixRow[] = matrixRowsCasted.concat(action.rows);

    if (action.reorder) {
      rows.sort(sortRowsByEmptiness);
      rows = rows.map((m, i) => ({
        ...m,
        ordinal: i + 1,
      }));
    }

    return {
      ...q,
      matrixRows: rows,
    } as SurveyQuestion;
  }

  function sortRowsByEmptiness(a: SurveyQuestionMatrixRow, b: SurveyQuestionMatrixRow) {
    const aIsNull = parseSurveyRichText(a.value) === '';
    const bIsNull = parseSurveyRichText(b.value) === '';

    if (aIsNull === bIsNull) {
      return a.ordinal - b.ordinal;
    } else if (aIsNull) {
      return 1;
    } else if (bIsNull) {
      return -1;
    }
  }

}

export function removeRow(state: SurveyQuestionsBuilder.State, action: SurveyRowsBuilder.RemoveRow.State): SurveyQuestionsBuilder.State {

  const question = state
    .find(f => f.base.identifier === action.questionIdentifier) as RowsQuestion;

  const matrixRowsCasted = (question.matrixRows as SurveyQuestionMatrixRow[]);

  const toRemove = matrixRowsCasted.find(f => f.base.identifier === action.row.identifier);

  if (!toRemove) return state;

  const ordinals = generateNewOrdinals();

  const matrixRows = matrixRowsCasted
    .filter(f => f.base.identifier !== action.row.identifier)
    .map(row => ({
      ...row,
      ordinal: ordinals[row.ordinal],
    }));

  return state.reduce<SurveyQuestionsBuilder.State>((acc, q) => {
    if (q.base.identifier === action.questionIdentifier) {
      acc.push({
        ...q,
        matrixRows,
      } as SurveyQuestion);
    } else {
      acc.push(q);
    }
    return acc;
  }, []);

  type OrdinalMap = { [ordinal: number]: number };
  function generateNewOrdinals() {
    return question.matrixRows.map(m => m as SurveyQuestionMatrixRow).reduce<OrdinalMap>((acc, x) => {
      return {
        ...acc,
        [x.ordinal]: x.ordinal < toRemove.ordinal
          ? x.ordinal
          : x.ordinal > toRemove.ordinal
            ? x.ordinal - 1
            : null,
      };
    }, {});
  }
}

export function updateRowValue(state: SurveyQuestionsBuilder.State, action: SurveyRowsBuilder.UpdateRowValue.State): SurveyQuestionsBuilder.State {
  return state.reduce((acc, q) => {
    if (q.base.identifier === action.questionIdentifier) {
      const matrixRows = (q.matrixRows as SurveyQuestionMatrixRow[]).reduce((acc2, o) => {
        if (o.base.identifier === action.row.identifier) {
          acc2.push({
            ...o,
            value: action.value,
          });
        } else {
          acc2.push(o);
        }
        return acc2;
      }, []);
      acc.push({
        ...q,
        matrixRows,
      } as RowsQuestion);
    } else {
      acc.push(q);
    }

    return acc;
  }, [] as SurveyQuestion[]);
}

export function matrixRangeRowMetadataUpdated(state: SurveyQuestionsBuilder.State, payload: SurveyRowsBuilder.MatrixRangeRowMetadataUpdated.Payload): SurveyQuestionsBuilder.State {

  return state.reduce<SurveyQuestion[]>((acc, q) => {
    if (q.base.identifier === payload.questionIdentifier) {
      const matrixRows = (q.matrixRows as MatrixRangeQuestion.Row[]).reduce((acc2, r) => {
        if (r.base.identifier === payload.row.identifier) {
          return acc2.concat({
            ...r,
            metadata: {
              ...r.metadata,
              [payload.field]: payload.value,
            },
          });
        }
        return acc2.concat(r);
      }, []);
      return acc.concat({
        ...q,
        matrixRows,
      } as RowsQuestion);
    }

    return acc.concat(q);
  }, []);
}