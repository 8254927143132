import type { SurveyRichText } from '@/types/survey.rich-text';
import { Remirror } from '@/types';

export function convertToSurveyRichText(value: string | SurveyRichText.RichTextValue): SurveyRichText.RichTextValue {
  if (typeof value === 'object') {
    return value;
  } else {
    return {
      type: Remirror.NodeType.Doc,
      content: [{
        type: Remirror.NodeType.Paragraph,
        content: value?.length
          ? [{ type: Remirror.NodeType.Text, text: value }]
          : [],
      }],
    };
  }
}

export function parseSurveyRichText(value: SurveyRichText.RichTextValue, trim = true): string {

  function parseNodeText(t: SurveyRichText.ParagraphContent): string {
    switch (t.type) {

      case Remirror.NodeType.Text:
        return t.text;

      case Remirror.NodeType.TemplateCategory:
        return t.attrs.category;

      case Remirror.NodeType.TemplateCompetitor:
        return t.attrs.competitor;

      case Remirror.NodeType.TemplateTarget:
        return t.attrs.target;

      case Remirror.NodeType.PipedResponse:
        if (typeof t.attrs.text === 'string') {
          return t.attrs.text;
        }
        else {
          return parseSurveyRichText(t.attrs.text);
        }

      case Remirror.NodeType.TemplateNode:
        return t.attrs.value;

      case Remirror.NodeType.AdditionalPriceLink:
        return t.attrs.text;

      case Remirror.NodeType.FindAndReplace:
        if (t.attrs.touched) {
          return typeof t.attrs.value === 'string' ? t.attrs.value : parseSurveyRichText(t.attrs.value);
        } else {
          return t.attrs.label;
        }

      case Remirror.NodeType.Image:
        return `[${t.attrs.src}]`;

      default:
        return '';
    }
  }

  function bulletize(text: string) {
    return `\u2022 ${text}`;
  }

  function parseParagraphNode(node: SurveyRichText.ParagraphNode) {
    return (node.content || []).map(parseNodeText).join(``);
  }

  const text = (value.content || []).map(node => {
    if (node.type === 'bulletList') {
      const bullets = node.content.map(listItem => listItem.content.map(x => {
        const text = parseParagraphNode(x);
        return bulletize(text);
      }));

      return ['', ...bullets].join(`\n`);
    } else if (node.type === 'paragraph') {
      return parseParagraphNode(node);
    }
  }).join('\n');

  return trim ? text.trim() : text;
}

export function parseRichTextImages(value: SurveyRichText.RichTextValue): Remirror.ImageNode[] {
  if (!value.content) return [];

  return value.content.map(node => node.content).flat().filter(node => node.type === Remirror.NodeType.Image) as Remirror.ImageNode[];
}

export function removeNodesFromResponseQuestion(value: SurveyRichText.RichTextValue): SurveyRichText.RichTextValue {

  const nodesToRemove = [Remirror.NodeType.ViewStimulus, Remirror.NodeType.ViewTPP];

  const filteredContent = (value.content || []).map(node => {
    if (node.type === 'paragraph') {
      const content = (node.content || []).reduce<SurveyRichText.ParagraphContent[]>((acc, pNode) => {
        return nodesToRemove.includes(pNode.type)
          ? acc
          : acc.concat(pNode);
      }, []);
      return {
        content,
        type: node.type,
      };
    }

    return node;
  }, []);

  let hasValue = false;
  const trimmedContent: SurveyRichText.RootNodeContent[] = [];
  [...filteredContent].reverse().forEach(node => {
    if (node.type === 'bulletList') {
      hasValue = true;
      trimmedContent.push(node);
    } else if (node.type === 'paragraph') {
      const nodeIsEmpty = !node.content.length;
      if (!nodeIsEmpty || hasValue) trimmedContent.push(node);
      hasValue = !nodeIsEmpty;
    }
  });

  return {
    content: trimmedContent.reverse(),
    type: Remirror.NodeType.Doc,
  };
}

export function richTextHasImages(value: SurveyRichText.RootNode) {
  if (!value.type) return false;

  return value.content.some(node => {
    if (node.type === Remirror.NodeType.Paragraph && node.content) {
      return node.content.some(n => n.type === Remirror.NodeType.Image);
    }

    return false;
  });
}