import { memo } from 'react';
import { useParseSurveyRichText, useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { RowQuestionTypes } from '@containers/Survey/utils/questions';
import { Tooltip } from '@presentation/Tooltip';
import { SurveyActionType, utils as enumUtils } from '@enums';
import type { SurveyLogic, SurveyQuestionMatrixRow } from '@/types/survey';
import { ActionErrorBoundary } from './ErrorBoundary.Action';
import styles from './style/Preview.Condition.css';

type Props = {
  action: SurveyLogic.Action;
  className?: string;
};

const SurveyAction = ({ action, className }: Props) => {

  const [state] = useSurveyBuilderState();
  const parseRichText = useParseSurveyRichText();

  const renderContent = function() {
    switch (action.type) {

      case SurveyActionType.SkipToSection: {
        const section = state.survey.sections.find(f => f.identifier === action.section.identifier);
        return (
          <>
            Skip to&nbsp;
            <ActionTooltip
              text={`S${section.ordinal}`}
              label={section.name} />
          </>
        );
      }

      case SurveyActionType.SkipToQuestion: {
        const question = state.survey.questions.find(f => f.base.identifier === action.question.identifier);
        const text = parseRichText(question.value);
        return (
          <>
            Skip to&nbsp;
            <ActionTooltip
              text={`Q${question.ordinal}`}
              label={text} />
          </>
        );
      }

      case SurveyActionType.IncludeSection: {
        const section = state.survey.sections.find(f => f.identifier === action.section.identifier);
        return (
          <>
            Include&nbsp;
            <ActionTooltip
              text={`S${section.ordinal}`}
              label={section.name} />
          </>
        );
      }

      case SurveyActionType.DisplayQuestionChoice:
      case SurveyActionType.ExcludeQuestionChoice: {
        const question = state.survey.questions.find(f => f.base.identifier === action.question.identifier);
        const questionText = parseRichText(question.value);

        const option = question.options?.find(f => f.base.identifier === action.option?.identifier);
        const optionText = option ? parseRichText(option.value) : null;

        const matixRows = question.matrixRows as SurveyQuestionMatrixRow[];
        const row = matixRows?.find(f => f.base.identifier === action.row?.identifier);
        const rowText = row ? parseRichText(row.value) : null;

        const optionPrefix = RowQuestionTypes.includes(question.typeId) ? `C` : `A`;
        const noun = row ? `row` : `choice`;

        const actionText = SurveyActionType.ExcludeQuestionChoice === action.type ? `Exclude` : `Display`;

        return (
          <>
            {actionText} question&nbsp;
            <ActionTooltip
              text={`Q${question.ordinal}`}
              label={questionText} />
            &nbsp;{noun}&nbsp;
            {option ? (
              <ActionTooltip
                text={`${optionPrefix}${option.ordinal}`}
                label={optionText} />
            ) : null}
            {row ? (
              <ActionTooltip
                text={`R${row.ordinal}`}
                label={rowText} />
            ) : null}
          </>
        );
      }

      case SurveyActionType.None:
      case SurveyActionType.Disqualification:
      case SurveyActionType.Flag:
      case SurveyActionType.SkipToEnd:
      case SurveyActionType.QuotaOverLimit:
        return (
          <>{enumUtils.SurveyActionType.getName(action.type)}</>
        );

      default:
        throw new UnreachableCaseError(action);
    }
  };

  return (
    <span className={className}>
      {renderContent()}
    </span>
  );
};

type TooltipProps = {
  label: string;
  text: string;
};

const ActionTooltip = memo(({ label, text }: TooltipProps) => (
  <Tooltip title={label}>
    <span className={styles.tooltip}>{text}</span>
  </Tooltip>
));

const ActionWithErrorBoundary = (props: Props) => {

  return (
    <ActionErrorBoundary action={props.action}>
      <SurveyAction {...props} />
    </ActionErrorBoundary>
  );
};

export { ActionWithErrorBoundary as SurveyAction };