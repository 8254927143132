import { cx } from '@utils';
import { Content } from './Content';
import styles from './style/URLPreview.css';

type Props = {
  url: string;
};

export const Placeholder = (props: Props) => {
  const domain = new URL(props.url).hostname.replace('www.', '');

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <a
          href={props.url}
          rel="noreferrer"
          target="_blank">
          <div className={cx(styles.main, styles.base)}>
            <Content domain={domain} />
          </div>
        </a>
      </div>
    </div>
  );
};

Placeholder.displayName = 'URLPreview.Placeholder';