import { useCallback, useMemo } from 'react';
import { Clock, Clipboard, FileText, PlayCircle, XCircle } from 'react-feather';
import { format } from 'date-fns';
import { AudioPlayerContainer } from '@containers/AudioPlayer';
import * as enums from '@enums';
import { Tooltip } from '@presentation/Tooltip';
import { getLocationFor } from '@utils';
import type { CompletedCall } from '@/types';
import { MaybeLink } from '@/components/MaybeLink';
import { useIsInternalUser } from '@/containers/Store/hooks';
import { MiniStarRating } from '@/components/StarRating';
import { Tagging } from '@/components/icons';
import Spinner from '@/components/ActivityIndicator/Spinner';
import { Downloader as FilesDownloader } from './CallItem.Downloader';
import { EnhancementStatus, VisibilityStatus } from './CallItem.EnhancementStatus';
import styles from './style/CallItem.css';

type Props = {
  item: CompletedCall;
} & IProjectId;

export const CallItem = (props: Props) => {

  const validNotes = useMemo(() => props.item.notes.filter(s => s.body?.length || s.rich?.length), [props.item.notes]);

  const isInternalUser = useIsInternalUser();

  const hasNotes = useMemo(() => {
    return isInternalUser && !!validNotes.length;
  }, [isInternalUser, validNotes]);

  const hasRecording = useMemo(() => {
    return props.item.conference.record &&
      (props.item.conference.hasAudio ||
        props.item.conference.hasVideo);
  }, [props.item]);

  const transcriptLink = useMemo(() => {
    if (!props.item?.transcript?.isAvailable) return;

    const isVisibleToClient = props.item.transcript?.enhancement?.statusId === enums.TranscriptionRevisionStatus.Completed;

    if (isInternalUser || isVisibleToClient) {
      return getLocationFor.call.transcript({
        callId: props.item.id,
        slug: String(props.projectId),
      });
    }
  }, [
    isInternalUser,
    props.item,
    props.projectId,
  ]);

  const renderDate = useCallback(() => {
    const time = `${format(props.item.timeStart, 'M/dd/yy')} at ${format(props.item.timeStart, 'h:mma')}`;

    if (!isInternalUser) {
      return time;
    }

    return `${time} with ${props.item.creator.name}`;
  }, [
    isInternalUser,
    props.item.creator,
    props.item.timeStart,
  ]);

  const renderRating = useCallback(() => {

    if (!props.item.rating) {
      return (
        <Tooltip title={`Call Hasn't Been Rated`}>
          <span className={styles.iconTooltip}>
            <Clock className={styles.icon} />
          </span>
        </Tooltip>
      );
    } else if (props.item.rating.isSkipped || !props.item.rating.rating) {
      return (
        <Tooltip title={`Skipped Rating`}>
          <span className={styles.iconTooltip}>
            <XCircle className={styles.icon} />
          </span>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={`${props.item.rating.rating} Star Rating Given`}>
          <span className={`${styles.iconTooltip}`}>
            <MiniStarRating
              className={styles.iconRating}
              size={20}
              rating={props.item.rating.rating} />
          </span>
        </Tooltip>
      );
    }
  }, [
    props.item.rating,
  ]);

  const renderRecorded = useCallback(() => {
    const size = 30;
    const title = !hasRecording ? 'No recording available' : props.item.conference.mediaTypeId === enums.ConferenceMediaType.Video ? 'Video Recorded' : `Audio Recorded`;
    return (
      <Tooltip title={title}>
        <PlayCircle
          size={size}
          color={'var(--gray)'}
          strokeWidth={1} />
      </Tooltip>
    );
  }, [hasRecording, props.item.conference.mediaTypeId]);

  const renderIcons = useCallback(() => {
    if (props.item.statusId != enums.CallStatus.Completed) {
      return (
        <>
          <Tooltip title={`Call is processing`}>
            <div>
              <Spinner />
            </div>
          </Tooltip>
        </>
      );
    }

    const isAvailable = props.item.transcript?.isAvailable;

    const showDownload = isInternalUser && isAvailable
      || !isInternalUser && isAvailable && props.item.transcript?.enhancement?.statusId === enums.TranscriptionRevisionStatus.Completed;

    return (
      <>
        {props.item.transcript ?
          <Tooltip title={`Transcript`}>
            <span className={styles.iconTooltip}>
              <FileText size={20} className={styles.icon} />
            </span>
          </Tooltip> : null
        }
        {hasNotes ?
          <Tooltip title={`Call Notes`}>
            <span className={styles.iconTooltip}>
              <Clipboard size={20} className={styles.icon} />
            </span>
          </Tooltip> : null
        }
        {renderRating()}
        {props.item.tagCount ?
          <Tooltip title={`${props.item.tagCount} Tags`}>
            <span className={styles.iconTooltip}>
              <Tagging size={20} className={styles.icon} />
            </span>
          </Tooltip> : null
        }
        {showDownload &&
          <FilesDownloader
            hasNotes={hasNotes}
            item={props.item} />}
      </>
    );
  }, [
    hasNotes,
    isInternalUser,
    props.item,
    renderRating,
  ]);

  return (
    <AudioPlayerContainer>
      <div className={styles.root}>
        <div className={styles.call}>
          <div className={styles.recordingCol}>
            {renderRecorded()}
          </div>
          <div className={styles.wrapper}>
            <div className={styles.name}>
              <MaybeLink to={transcriptLink}>
                {props.item.title}
              </MaybeLink>
              <div className={styles.date}>
                <MaybeLink to={transcriptLink}>
                  {renderDate()}
                </MaybeLink>
              </div>
              <div className={styles.icons}>
                {renderIcons()}
              </div>
            </div>
          </div>
          {props.item?.transcript?.isAvailable &&
            <PublishStatus
              enhancementEnabled={props.item.transcript?.enhancement?.enabled}
              statusId={props.item.transcript?.enhancement?.statusId} />}
        </div>
      </div>
    </AudioPlayerContainer>
  );
};

CallItem.displayName = 'Project.Summary.CallItem';

type PublishStatusProps = {
  enhancementEnabled: boolean;
  statusId: enums.TranscriptionRevisionStatus;
};

const PublishStatus = (props: PublishStatusProps) => {
  const isInternalUser = useIsInternalUser();

  const statusProps = {
    className: styles.status,
    statusId: props.statusId,
  };

  if (!isInternalUser) {
    return (
      <VisibilityStatus {...statusProps} />
    );
  }

  return props.enhancementEnabled
    ? <EnhancementStatus {...statusProps} />
    : <VisibilityStatus {...statusProps} />;
};