import { cx } from '@utils';
import CalendarButton from './Button';
import { CalendarMainProps } from './interfaces';
import styles from './style/MainResponsive.css';
import { getNewEvents } from './utils';

type Props = {
  className?: string;
} & CalendarMainProps;

const ViewingMainResponsive = (props: Props) => {

  function SubmitButton() {
    const canSubmit = getNewEvents(props.events).length
                   || props.removedEvents.length;

    return (
      <CalendarButton
        className={styles.btn}
        disabled={!canSubmit}
        onClick={props.onSubmit}
        variant="pill">
        Save
      </CalendarButton>
    );
  }

  return (
    <div className={cx(props.className, styles.main)}>
      <div id={props.id} />
      <SubmitButton />
    </div>
  );
};

export { ViewingMainResponsive };
export default ViewingMainResponsive;
