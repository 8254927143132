import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { CalendarEvent } from '@/types';
import * as api from '@api';
import { transformCalendarEvents } from '@containers/Calendar/transformers';
import {
  buildAvailabilitySegments,
  buildBusySegments,
} from '../timeslots';
import {
  buildEventsMap,
  getAvailabilityMap,
} from '../mapping';
import { AvailableEventsMap } from '../interfaces';

type Params = {
  scheduleeId: number;
  schedulerId: number;
} & ICallId;

function buildAvailabilityMap({ participant, personal }: {
  participant: CalendarEvent[];
  personal: CalendarEvent[];
}): AvailableEventsMap {
  const availability = buildEventsMap(buildAvailabilitySegments(participant));
  const busy = buildEventsMap(buildBusySegments(personal));
  const omitted = getAvailabilityMap(participant, 30);

  return Object.keys(availability).reduce<AvailableEventsMap>((acc, key) => {
    const availableSegments = availability[key];
    const busySegments = busy[key];
    const omissions = omitted[key];

    if (!busySegments && !omissions) {
      return {
        ...acc,
        [key]: availableSegments,
      };
    }

    const excluded = [
      ...(busySegments ?? []).map(event => event.start),
      ...(omissions ?? []),
    ].map(x => x.toISOString());
    const filteredSegments = availableSegments.filter(event => !excluded.includes(new Date(event.start).toISOString()));

    if (!filteredSegments.length) {
    /*
    * Availability on this date contains durations
    * shorter than 1 hour and will be excluded
    * from displaying on the calendar.
    */
      return acc;
    }

    return {
      ...acc,
      [key]: filteredSegments,
    };
  }, {} as AvailableEventsMap);
}

type Key = ['available-times', Params];

export const availableTimesKey = (data: Params) => {
  return ['available-times', data] as const;
};

const useAvailableTimes = (
  params: Params,
  options?: UseQueryOptions<AvailableEventsMap, AxiosResponse, AvailableEventsMap, Key>
) => {

  return useQuery(availableTimesKey(params), ({ queryKey }) => {
    const params = queryKey[1];
    return Promise.all([
      api.calendars.getPersonalCalendar({
        scheduleeId: params.scheduleeId,
      }),
      api.calendars.getUserCalendarAvailability({
        callId: params.callId,
        userId: params.schedulerId,
      }),
    ]).then(([personal, participant]) => {
      return buildAvailabilityMap({
        participant: transformCalendarEvents(participant.events),
        personal: transformCalendarEvents(personal.events),
      });
    });
  }, {
    ...options,
    initialData: {},
  });

};

export { useAvailableTimes };
export default useAvailableTimes;