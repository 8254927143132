import { useContext, useState, useMemo, useCallback, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { cx, useDebounceCallback, getLocationFor } from '@utils';
import { TermsContainer, TermFilterContext, ProjectTermsContext, TranscriptReplacementsContext, SelectedTermsContext } from '@containers/Group.Project.Terms';
import { SearchInput } from '@/components/Input';
import ActivityIndicator from '@/components/ActivityIndicator';
import { useProjectOrLatestChild } from '@/containers/GroupProject/hooks';
import DropDown from '@/components/DropDown';
import { TabViewTableSkeleton } from '@/components/TabView';
import { Button } from '@/components/Button';
import { TransformedProjectTermsContext, BoostOverridesContext } from './Context';
import { ContextMenu } from './ContextMenu';
import { Highlights } from './Highlights';
import { TermsTable } from './TermsTable';
import { EditHeader } from './EditHeader';
import styles from './styles/Project.Terms.css';
import type { PaginationResult } from './hooks/usePagination';
import { usePagination, useHighlightsOpen } from './hooks';
import { SocketContainer } from './SocketContainer';
import { RouteGuardContainer } from './RouteGuardContainer';

export const ProjectTerms = () => {
  const project = useProjectOrLatestChild();
  return (
    <TermsContainer projectId={project.id}>
      <ProjectTermsContent />
    </TermsContainer>
  );
};

const ProjectTermsContent = () => {
  const { mode, globalReplacements, confirmedTerms, projectId } = useContext(TranscriptReplacementsContext);
  const { query: projectTermsQuery } = useContext(ProjectTermsContext);
  const { searchTerm } = useContext(TermFilterContext);
  const highlightsOpen = useHighlightsOpen();

  const [boostOverrides, setBoostOverrides] = useState<Record<string, boolean>>({});

  const setTermBoost = useCallback((term: string, boosted: boolean) => {
    setBoostOverrides(old => ({
      ...old,
      [term]: boosted,
    }));
  }, []);

  const transformedTerms = useMemo(() => {
    return projectTermsQuery.data.terms.map(t => {
      const replacement = globalReplacements.find(r => r.originalText === t.termValue);
      if (replacement) {
        return {
          ...t,
          termValue: replacement.replacementText,
          term: {
            id: t.term?.id,
            type: 'replaced',
          },
          boosted: boostOverrides[replacement.replacementText] ?? t.boosted,
        };
      } else {
        if (boostOverrides[t.termValue] !== undefined) {
          return { ...t, boosted: boostOverrides[t.termValue] };
        } else {
          return t;
        }
      }
    });
  }, [globalReplacements, projectTermsQuery.data.terms, boostOverrides]);

  const filteredTerms = useMemo(() => {
    let terms = transformedTerms;

    if (mode === 'typo-cleanup') {
      terms = terms.filter(t => t.term?.type === 'spell-check' && !confirmedTerms.some(ct => t.termValue === ct.term));
    }

    if (searchTerm) {
      terms = terms.filter(t => t.termValue.toLowerCase().includes(searchTerm.toLowerCase()));

      if (!terms.some(t => t.termValue.toLowerCase() === searchTerm)) {
        terms = [{
          term: null,
          entities: [],
          transcriptIds: [],
          termValue: searchTerm,
          occurrences: null,
          boosted: false,
        }, ...terms];
      }
    }

    return terms;
  }, [transformedTerms, mode, searchTerm, confirmedTerms]);

  const pagination = usePagination({
    initialPageSize: 25,
    totalCount: filteredTerms.length,
  });

  const paginatedTerms = useMemo(() => {
    return filteredTerms.slice(pagination.slice.from, pagination.slice.to);
  }, [pagination.slice.from, pagination.slice.to, filteredTerms]);

  if (!projectTermsQuery.isFetched) return <div className={styles.root}><TabViewTableSkeleton /></div>;

  return (
    <RouteGuardContainer>
      <SocketContainer projectId={projectId}>
        <TransformedProjectTermsContext.Provider value={{ terms: transformedTerms }}>
          <BoostOverridesContext.Provider value={{ boostOverrides, setTermBoost }}>
            <div className={styles.root}>
              <div className={styles.wrap}>
                <Header />
                <div className={styles.tableWrapper}>
                  <table className={styles.mainTable}>
                    <tr>
                      <td>
                        <TermsTable terms={paginatedTerms} collapsed={highlightsOpen} />
                      </td>
                      {highlightsOpen &&
                        <td className={styles.highlightsCell}>
                          <table className={styles.subTable}>
                            <thead>
                              <tr>
                                <th>
                                  Text
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <Highlights />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>}
                    </tr>
                  </table>
                </div>
                <Footer {...pagination} />
              </div>
            </div>
          </BoostOverridesContext.Provider>
        </TransformedProjectTermsContext.Provider>
      </SocketContainer>
    </RouteGuardContainer>
  );
};

const Header = () => {
  const project = useProjectOrLatestChild();
  const { setSearchTerm, setHighlightTerm, searchTerm } = useContext(TermFilterContext);
  const { query } = useContext(ProjectTermsContext);
  const [inputVal, setInputVal] = useState(searchTerm);

  const debouncedUpdateFilters = useDebounceCallback((val: string) => {
    setSearchTerm(val);
    setHighlightTerm(val);
  }, 300);

  const onSearchChange = useCallback((val: string) => {
    setInputVal(val);
    debouncedUpdateFilters(val);
  }, [debouncedUpdateFilters]);

  useEffect(() => {
    setInputVal(searchTerm);
  }, [searchTerm]);

  return (
    <div className={styles.header}>
      <div className={styles.title}>Terminology</div>
      <div>
        <SearchInput
          placeholder='Search'
          value={inputVal}
          onChange={onSearchChange} />
      </div>
      <div className={styles.spacer} />
      <EditHeader />
      <div className={styles.actions}>
        {query.isFetched && !query.data.terms.length ?
          <Button
            className={styles.btn}
            color="secondary"
            to={getLocationFor.project.entitiesOnboarding({ slug: project.slug })}
            variant="brick">
            Get Started
          </Button>
          : <ContextMenu />
        }
      </div>
    </div>
  );
};

const Footer = (props: PaginationResult) => {
  return (
    <div className={styles.footer}>
      <div className={styles.pagination}>
        <div>
          Rows Per Page
        </div>
        <div>
          <DropDown
            text={props.pageSize.toString()}
            getItemValue={i => i.id}
            items={[{ id: 10 }, { id: 25 }, { id: 50 }]}
            onSelect={i => props.setPageSize(i.id)} />
        </div>
        <div>
          {props.slice.from + 1} - {Math.min(props.slice.to, props.totalCount)} of {props.totalCount}
        </div>
        <ChevronLeft className={cx(styles.pageBtn, props.canGoBack ? null : styles.disabled)} onClick={props.canGoBack ? props.goBack : null} />
        <ChevronRight className={cx(styles.pageBtn, props.canGoNext ? null : styles.disabled)} onClick={props.canGoNext ? props.goNext : null} />
      </div>
    </div>
  );
};