import { Card as BaseCard } from '@presentation/Main';
import { cx } from '@utils';
import styles from './style/Main.css';

type Props = {
  children:   React.ReactNode;
  className?: string;
  desktop?:   boolean;
};

export const Card = (props: Props) => {
  const className = cx(styles.card, props.className, {
    [styles.desktop]: props.desktop,
  });

  return (
    <BaseCard
      className={className}>
      {props.children}
    </BaseCard>
  );
};

export default Card;