import { useReducer } from 'react';
import { TaggedMomentFormDispatchContext, TaggedMomentFormStateContext } from './context';
import type { TaggedMomentState } from './interfaces';

type Props = ChildrenProps;

export const TaggedMomentFormState = ({ children }: Props) => {

  const [state, dispatch] = useReducer(taggedMomentReducer, {
    keyword: '',
    fromTs: null,
    toTs: null,
    momentId: null,
    tag: null,
    tags: [],
    transcript: null,
  });

  return (
    <TaggedMomentFormDispatchContext.Provider value={dispatch}>
      <TaggedMomentFormStateContext.Provider value={state}>
        {children}
      </TaggedMomentFormStateContext.Provider>
    </TaggedMomentFormDispatchContext.Provider>
  );
};

function taggedMomentReducer(state: TaggedMomentState.Value, action: TaggedMomentState.Action): TaggedMomentState.Value {
  switch (action.type) {
    case 'tag-updated':
      return {
        ...state,
        tag: action.payload.tag,
      };

    case 'color-updated':
      return {
        ...state,
        tag: {
          ...state.tag,
          color: action.payload.value,
        },
      };

    case 'range-updated':
      return {
        ...state,
        fromTs: action.payload.value.start,
        toTs: action.payload.value.end,
      };

    case 'state-reset':
      return action.payload.value;

    default:
      return state;
  }
}