import { useCallback, useMemo } from 'react';
import { Check, X } from 'react-feather';
import { ProjectConferenceTag } from '@/types';
import { Checkbox } from '@/components/Checkbox';
import { useTagsToSummarize } from './context';
import styles from './style/ConferenceTagsOverview.css';

type Props = {
  items: Item[];
  transcriptIds: number[];
};

type Item = {
  canSelect: boolean;
  canSummarize: boolean;
  tag: ProjectConferenceTag;
  transcriptIds: number[];
};

export const ProjectConferenceTagsSelection = ({ items, transcriptIds }: Props) => {

  const [selected, setSelected] = useTagsToSummarize();

  const selectableTags = useMemo(() => {
    return items.filter(f => f.canSelect).map(m => m.tag.base.id);
  }, [items]);

  const sortedTags = useMemo(() => {
    return [...items].sort((a, b) => {
      return b.tag.ordinal = a.tag.ordinal;
    });
  }, [items]);

  const handleRowToggleChecked = useCallback((tagId: number) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      setSelected(prev => {
        return [...prev, tagId];
      });
    } else {
      setSelected(prev => {
        return prev.filter(f => f !== tagId);
      });
    }
  }, [setSelected]);

  const allRowsChecked = useMemo(() => {
    return selected.length === selectableTags.length;
  }, [selectableTags.length, selected.length]);

  const handleHeaderToggleChecked = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      setSelected(selectableTags);
    } else {
      setSelected([]);
    }
  }, [setSelected, selectableTags]);

  return (
    <div className={styles.tableScroll}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.checkbox}>
              <Checkbox
                checked={allRowsChecked}
                onChange={handleHeaderToggleChecked} />
            </th>
            <th className={styles.name}>Tag ({selected.length})</th>
            <th className={styles.tagged}>Transcripts Tagged</th>
            <th className={styles.summary}>Summary Available</th>
          </tr>
        </thead>

        <tbody>
          {sortedTags.map(item => {
            const itemSelected = selected.includes(item.tag.base.id);
            return (
              <tr key={item.tag.base.id}>
                <td className={styles.checkbox}>
                  <Checkbox
                    disabled={!item.canSelect}
                    checked={itemSelected}
                    onChange={handleRowToggleChecked(item.tag.base.id)} />
                </td>
                <td className={styles.name}>{item.tag.base.name}</td>
                <td className={styles.tagged}>{item.transcriptIds.length} / {transcriptIds.length}</td>
                <td className={styles.summary}>
                  {
                    item.canSummarize
                      ? <Check color="green" />
                      : <X color="red" />
                  }
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};