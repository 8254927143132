import type {
  Project,
  Workbook,
  WorkspaceFile,
  WorkspaceFilePreviewData,
  WorkspaceFileSummaryData,
  WorkspaceObject,
  FileCapabilities,
  WorkspaceFileTranscript,
} from '@/types';
import { createNamedContext } from '@/utils';

type FileStateContextValue = {
  capabilities: FileCapabilities;
  features: {
    insightsChat: boolean;
  };
  file: WorkspaceFile;
  meta: {
    accessCount: number;
  };
  object: WorkspaceObject;
  project?: Project;
  transcript: WorkspaceFileTranscript;
};

type FetchPreview = () => Promise<unknown>;

export const WorkspaceFilePreviewReloadContext = createNamedContext<FetchPreview>(undefined, 'WorkspaceFilePreviewReloadContext');
export const WorkspaceFilePreviewLoadingContext = createNamedContext<boolean>(false, 'file-preview-loading');
export const WorkspaceFilePreviewContext = createNamedContext<WorkspaceFilePreviewData>(undefined, 'file-preview-data');

export const WorkspaceFileStateContext = createNamedContext<FileStateContextValue>(undefined, 'file-state');

export const WorkbookDataContext = createNamedContext<Workbook>(undefined, 'workbook-data');
export const WorkbookDataLoadingContext = createNamedContext<boolean>(undefined, 'workbook-data-loading');
