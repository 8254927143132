import { useCallback, useMemo, useState } from 'react';
import { type CoveredLivesPlans, CoveredLivesTypes, utils } from '@enums';
import { cx } from '@utils';
import type { CoveredLivesItem } from '@/types/medical.covered';
import * as Input from './Input';
import styles from './style/Table.CoveredLives.css';

type Props = {
  editing?: boolean;
  items: CoveredLivesItem[];
  onChange?: (data: CoveredLivesItem) => unknown;
};

export const CoveredLives = ({ items, onChange, ...props }: Props) => {
  const rows = useMemo(() => {
    return buildRows(items);
  }, [items]);

  const handleChange = useCallback((data: IdentifierFields) => {
    return (value: number) => {
      onChange?.({
        planId: data.planId,
        typeId: data.typeId,
        value,
      });
    };
  }, [
    onChange,
  ]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.h}>
            <div className={styles.row}>
              <div className={cx(styles.cell, styles.r)}>Covered Lives</div>
              {ColumnNames.map(x =>
                <div
                  className={cx(styles.cell, styles.name, styles.c)}
                  key={x}>
                  {x}
                </div>)}
            </div>
          </div>
          <div className={styles.b}>
            {rows.map(x =>
              <div
                className={styles.row}
                key={x.name}>
                <div className={cx(styles.cell, styles.name, styles.r)}>{x.name}</div>
                <CellValue
                  editing={props.editing}
                  onChange={handleChange(x.organization)}
                  planId={x.organization.planId}
                  typeId={x.organization.typeId}
                  value={x.organization.value} />
                <CellValue
                  editing={props.editing}
                  onChange={handleChange(x.personal)}
                  planId={x.personal.planId}
                  typeId={x.personal.typeId}
                  value={x.personal.value} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

CoveredLives.displayName = 'MedicalProfile.Table.CoveredLives';

type CellValueProps = {
  editing?: boolean;
  onChange?: (value: number) => unknown;
  planId: CoveredLivesPlans;
  typeId: CoveredLivesTypes;
  value: number;
};

const CellValue = (props: CellValueProps) => {
  if (!props.editing) {
    const value = Number.isInteger(props.value)
      ? props.value
      : '-';

    return (
      <div className={cx(styles.cell, styles.c)}>{value}</div>
    );
  }

  return (
    <div className={cx(styles.cell, styles.c)}>
      <Input.Text
        className={styles.input}
        onChange={e => props.onChange?.(Number(e.target.value))}
        type="number"
        value={props.value ?? ''} />
    </div>
  );
};

CellValue.displayName = 'MedicalProfile.Table.CoveredLives.CellValue';

const ColumnNames = utils.CoveredLivesTypes.values()
  .map(x => utils.CoveredLivesTypes.getName(x));

function buildRows(items: CoveredLivesItem[]) {
  return utils.CoveredLivesPlans.values().map(id => ({
    name: utils.CoveredLivesPlans.getName(id),
    organization: findRowItem(items, id, CoveredLivesTypes.EntireOrganization),
    personal: findRowItem(items, id, CoveredLivesTypes.DirectlyResponsible),
  }));
}

type IdentifierFields = Pick<CoveredLivesItem, 'planId' | 'typeId'>;

function findRowItem(items: CoveredLivesItem[], planId: CoveredLivesPlans, typeId: CoveredLivesTypes) {
  const item = items.find(x => x.planId === planId && x.typeId === typeId);

  if (!item) {
    return {
      planId,
      typeId,
      value: null,
    };
  }

  return item;
}