import { Node } from 'slate';
import { PostBody } from '@containers/PostCreation/interfaces/post.text';

export const MinBodyLength = 3;
export const MaxBodyLength = 1500;

export function getCharacterCount(body: PostBody) {
  const raw = body.value.map(node => Node.string(node)).join('');
  return raw.length;
}

export function isValid(body: PostBody) {
  const count = getCharacterCount(body);

  return count >= MinBodyLength &&
    count <= MaxBodyLength;
}

export function isTooLong(body: PostBody) {
  const count = getCharacterCount(body);
  return count > MaxBodyLength;
}

export function isEmpty(body: PostBody) {
  const count = getCharacterCount(body);
  return count === 0;
}

export function charactersRemaining(body: PostBody) {
  const count = getCharacterCount(body);

  return MaxBodyLength - count;
}