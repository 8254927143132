import type { ChatSessionState, ChatState } from './interfaces';
import { chatInstanceReducer } from './state.chat-instance';
import { queriesReducer } from './state.queries';
import { filtersReducer } from './state.filters';
import { generateEmptyChatSessionState } from './state.initial-state';
import { inputReducer } from './state.input';
import { fileUploadsReducer } from './state.file-uploads';
import { segmentationsReducer } from './state.segmentation';

export function chatSessionReducer(state: ChatSessionState.State, action: ChatState.Action): ChatSessionState.State {
  switch (action.type) {
    case 'new-chat-added':
    case 'chat-loaded': {
      return {
        chatInstance: action.payload.chatInstance,
        input: '',
        selectedQueryIdentifier: selectedQueryReducer(state, action),
        queries: action.payload.queries,
        filters: filtersReducer(state, action),
        fileUploads: action.payload.fileUploads,
        pendingFindQuotes: false,
        segmentations: action.payload.segmentations,
      };
    }

    case 'instance-context-changed':
    case 'new-chat-started': {
      return generateEmptyChatSessionState({
        context: action.payload.context,
      });
    }

    case 'chat-instance-removed': {
      if (state.chatInstance.id === action.payload.chatInstance.id) {
        return generateEmptyChatSessionState();
      } else {
        return state;
      }
    }

    default:
      return {
        chatInstance: chatInstanceReducer(state.chatInstance, action),
        input: inputReducer(state.input, action),
        selectedQueryIdentifier: selectedQueryReducer(state, action),
        queries: queriesReducer(state.queries, action),
        filters: filtersReducer(state, action),
        fileUploads: fileUploadsReducer(state.fileUploads, action),
        pendingFindQuotes: pendingQuotesReducer(state, action),
        segmentations: segmentationsReducer(state, action),
      };
  }
}

function pendingQuotesReducer(state: ChatSessionState.State, action: ChatState.Action): boolean {
  switch (action.type) {
    case 'pending-find-quotes/set':
      return action.payload.value;
    case 'query-submitted':
      return false;

    default: return state.pendingFindQuotes;
  }
}

function selectedQueryReducer(state: ChatSessionState.State, action: ChatState.Action): string {
  switch (action.type) {

    case 'new-chat-added':
    case 'chat-loaded': {
      return action.payload.chatInstance.currentQueryIdentifier;
    }
    case 'query-submitted':
    case 'query-variant-submitted': {
      return action.payload.identifier;
    }
    case 'query-errored':
    case 'query-variant-errored': {
      return action.payload.queryIdentifier;
    }
    case 'query-response-available':
    case 'query-variant-response-available': {
      return action.payload.chatInstance.currentQueryIdentifier;
    }
    case 'message-variant-selected': {
      return action.payload.queryIdentifier;
    }
    default: return state.selectedQueryIdentifier;
  }
}