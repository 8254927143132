import { SurveyQuestionType } from '@enums';
import ConjointAnalysis from './ConjointAnalysis';
import { ExclusiveOptions } from './ExclusiveOptions';
import LongTextResponse from './LongTextResponse';
import MatrixGridQuestion from './MatrixGrid';
import MatrixSliderQuestion from './MatrixSlider';
import MaxDiff from './MaxDiff';
import MultipleChoiceQuestion from './MultipleChoice';
import { MultiTextbox } from './MultiTextbox';
import { RankingQuestionForm } from './Ranking';
import ShortTextResponse from './ShortTextResponse';
import type { QuestionItemProps } from './interfaces';
import styles from './style/SurveyForm.css';
import MultiselectQuestion from './Multiselect';
import MatrixMultiselectQuestion from './MatrixMultiselect';
import NumberInputTableQuestion from './NumberInputTable';
import { DropdownQuestion } from './Dropdown';
import { MatrixRangeForm } from './MatrixRange';
import { ImageMarkupQuestion } from './ImageMarkup';

export const QuestionItem = (props: QuestionItemProps) => {

  switch (props.item.typeId) {
    case SurveyQuestionType.MultipleChoice:
      return (
        <MultipleChoiceQuestion
          question={props.item} />
      );

    case SurveyQuestionType.Multiselect:
      return (
        <MultiselectQuestion
          question={props.item} />
      );

    case SurveyQuestionType.Ranking:
      return (
        <RankingQuestionForm
          question={props.item} />
      );

    case SurveyQuestionType.Sliders:
      return (
        <MatrixSliderQuestion
          item={props.item} />
      );

    case SurveyQuestionType.MatrixGrid:
      return (
        <MatrixGridQuestion
          item={props.item} />
      );

    case SurveyQuestionType.LongTextResponse:
      return (
        <LongTextResponse
          item={props.item} />
      );

    case SurveyQuestionType.ShortTextResponse:
      return (
        <ShortTextResponse
          className={styles.shortInput}
          item={props.item} />
      );

    case SurveyQuestionType.MaxDifference:
      return <MaxDiff />;

    case SurveyQuestionType.ConjointAnalysis:
      return (
        <ConjointAnalysis />
      );

    case SurveyQuestionType.MatrixMultiselect:
      return (
        <MatrixMultiselectQuestion
          item={props.item} />
      );

    case SurveyQuestionType.MultiTextbox:
      return (
        <MultiTextbox
          question={props.item} />
      );

    case SurveyQuestionType.NumberInputTable:
      return (
        <NumberInputTableQuestion
          item={props.item} />
      );

    case SurveyQuestionType.ExclusiveOptions:
      return (
        <ExclusiveOptions
          question={props.item} />
      );

    case SurveyQuestionType.Dropdown: {
      return <DropdownQuestion item={props.item} />;
    }

    case SurveyQuestionType.MatrixRange:
      return <MatrixRangeForm item={props.item} />;

    case SurveyQuestionType.ImageMarkup:
      return <ImageMarkupQuestion item={props.item} />;

    default:
      throw new UnreachableCaseError(props.item);

  }
};

export default QuestionItem;