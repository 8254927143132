import { path } from '@consts';
import type { Navigation } from '@utils/interfaces';
import { stringify } from '@utils/querystring';
import type { Call } from '$admin/interfaces';
import { currentLocation, fromQuery } from './navigation.shared';

const getAdminMemberDetailsLocation = (data: Navigation.AdminConsultantDetailsParams) => {
  return {
    pathname: `${path.Admin.Experts.Root}/${data.userId}`,
    state: {
      from: currentLocation(),
    },
  };
};

const getAdminProjectDetailsLocation = (data: Navigation.AdminProjectDetailsParams) => {
  return {
    pathname: `${path.Admin.Projects.Root}/${data.projectId}`,
    state: {
      from: currentLocation(),
    },
  };
};

const getAdminProjectLeadsLocation = (data: Navigation.AdminProjectDetailsParams) => {
  return {
    pathname: `${path.Admin.Projects.Root}/${data.projectId}/leads`,
    state: {
      from: currentLocation(),
    },
  };
};

const getAdminProjectCloningLocation = (data: IProjectId) => {
  return {
    pathname: `${path.Admin.Projects.Root}/${data.projectId}/clone`,
    state: {
      from: currentLocation(),
    },
  };
};

const getAdminProjectCallsLocation = (data: IProjectId) => {
  return {
    pathname: path.Admin.Calls,
    search: `projectId=${data.projectId}`,
    state: {
      from: currentLocation(),
    },
  };
};

const getAdminCallTranscriptLocation = (data: ICallId) => {
  return {
    pathname: `${path.Admin.Calls}/${data.callId}/transcripts`,
    state: {
      from: currentLocation(),
    },
  };
};

const getProjectMonitoringLocation = (data: IProjectId) => {
  return {
    pathname: `${path.Admin.Projects.Root}/${data.projectId}/monitoring`,
    state: {
      from: currentLocation(),
    },
  };
};

const getAdminComplianceSurveyResponseLocation = (data: Navigation.AdminComplianceSurveyResponseParams) => {
  return {
    pathname: `${path.Admin.Projects.Root}/${data.projectId}/experts/${data.userId}/compliance-survey`,
    state: {
      from: currentLocation(),
    },
  };
};

const getSurveyResponseLocation = (data: Navigation.AdminSurveyResponseParams) => {
  return {
    pathname: `${path.Admin.Projects.Root}/${data.projectId}/survey-responses`,
    state: {
      from: currentLocation(),
    },
    search: 'surveyResponseId' in data ? `?responseId=${data.surveyResponseId}&surveyId=${data.surveyId}` : null,
  };
};

const getSurveyPartialResponseLocation = (data: Navigation.AdminSurveyPartialResponseParams) => {
  return {
    pathname: `${path.Admin.Root}/project-survey-partial-response/${data.surveyVersionId}/${data.userId}`,
    state: {
      from: currentLocation(),
    },
  };
};

const getAdminCallDetailsLocation = ({ callId }: ICallId) => {
  return {
    pathname: `${path.Admin.Calls}/${callId}`,
    state: {
      from: currentLocation(),
    },
  };
};

const getAdminCallsLocation = (data: Call.AggregateFilter) => {

  return {
    pathname: `${path.Admin.Calls}`,
    search: `?${stringify(data)}`,
    state: {
      from: currentLocation(),
    },
  };
};

const getAdminBillingPayoutDetailsLocation = (data: { payoutId: number }) => {
  return {
    pathname: `${path.Admin.BillingPayouts}/${data.payoutId}`,
    state: {
      from: currentLocation(),
    },
  };
};

const getAdminBillingPayoutableDetailsLocation = (data: { payoutableId: number }) => {
  return {
    pathname: `${path.Admin.Billing}/payoutable/${data.payoutableId}`,
    state: {
      from: currentLocation(),
    },
  };
};

const getAdminBillingChargeDetailsLocation = (data: { chargeId: number }) => {
  return {
    pathname: `${path.Admin.Billing}/charge/${data.chargeId}`,
    state: {
      from: currentLocation(),
    },
  };
};

const getAdminBillingChargeableDetailsLocation = (data: { chargeableId: number }) => {
  return {
    pathname: `${path.Admin.Billing}/chargeable/${data.chargeableId}`,
    state: {
      from: currentLocation(),
    },
  };
};

const getAdminBillingPackageLocation = (data: IPackageId) => {
  return {
    pathname: `${path.Admin.Billing}/packages/${data.packageId}`,
    state: {
      from: currentLocation(),
    },
  };
};

const getAdminAccountDetailsLocation = ({ groupId }: IGroupId) => {
  return {
    pathname: `${path.Admin.Accounts}/${groupId}`,
    state: {
      from: currentLocation(),
    },
  };
};

const getAdminAccountUserLocation = ({ groupId, userId }: IGroupId & IUserId) => {
  return {
    pathname: `${path.Admin.Accounts}/${groupId}/users/${userId}`,
    state: {
      from: currentLocation(),
    },
  };
};

const getAdminAccountUsersLocation = ({ groupId }: IGroupId) => {
  return {
    pathname: `${path.Admin.Accounts}/${groupId}/users`,
    state: {
      from: currentLocation(),
    },
  };
};

const getAdminVICSDetailsLocation = (id: number) => {
  return {
    pathname: path.Admin.IndustryDetails.replace(':vicsId', id.toString()),
    state: {
      from: currentLocation(),
    },
  };
};

const getTopicsLocation = () => {
  return {
    pathname: path.Admin.Topics,
    state: {
      from: currentLocation(),
    },
  };
};

const getTopicDetailsLocation = (topicId: number) => {
  return {
    pathname: path.Admin.TopicDetails.replace(':topicId', topicId.toString()),
    state: {
      from: currentLocation(),
    },
  };
};

const getLeadsRecordLocation = (leadId: number) => {
  return {
    pathname: path.Admin.LeadsRecord.replace(':leadId', leadId.toString()),
    state: {
      from: currentLocation(),
    },
  };
};

const getAdminAccountsLocation = () => {
  return {
    pathname: path.Admin.Accounts,
    state: {
      from: currentLocation(),
    },
  };
};

export const admin = {
  account: {
    aggregate: getAdminAccountsLocation,
    details: getAdminAccountDetailsLocation,
    user: getAdminAccountUserLocation,
    users: getAdminAccountUsersLocation,
  },
  billing: {
    charges: {
      details: getAdminBillingChargeDetailsLocation,
    },
    chargeables: {
      details: getAdminBillingChargeableDetailsLocation,
    },
    packages: {
      details: getAdminBillingPackageLocation,
    },
    payouts: {
      details: getAdminBillingPayoutDetailsLocation,
    },
    payoutables: {
      details: getAdminBillingPayoutableDetailsLocation,
    },
  },
  call: {
    aggregate: getAdminCallsLocation,
    details: getAdminCallDetailsLocation,
    transcript: getAdminCallTranscriptLocation,
  },
  leads: {
    details: getLeadsRecordLocation,
  },
  member: {
    details: getAdminMemberDetailsLocation,
  },
  project: {
    calls: getAdminProjectCallsLocation,
    clone: getAdminProjectCloningLocation,
    details: getAdminProjectDetailsLocation,
    leads: getAdminProjectLeadsLocation,
    monitoring: getProjectMonitoringLocation,
    survey: {
      complianceSurveyResponse: getAdminComplianceSurveyResponseLocation,
      responses: getSurveyResponseLocation,
      partialResponse: getSurveyPartialResponseLocation,
    },
  },
  topic: {
    dashboard: getTopicsLocation,
    details: getTopicDetailsLocation,
  },
  vics: {
    details: getAdminVICSDetailsLocation,
  },
};