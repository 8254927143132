import { ProfessionalRole } from '@enums';

export const Length = {
  dea: 9,
  npi: 10,
  me: 11,
};

export const Omitted = {
  clinicalPracticeStartedOn: [
    ProfessionalRole.AdministrativeStaff,
    ProfessionalRole.Patient,
    ProfessionalRole.PatientCaregiver,
    ProfessionalRole.Payer,
    ProfessionalRole.Professor,
    ProfessionalRole.HealthEconomist,
    ProfessionalRole.FormerExec,
  ],
  deaNumber: [
    ProfessionalRole.AdministrativeStaff,
    ProfessionalRole.Patient,
    ProfessionalRole.PatientCaregiver,
    ProfessionalRole.Payer,
    ProfessionalRole.Professor,
    ProfessionalRole.HealthEconomist,
    ProfessionalRole.FormerExec,
  ],
  meNumber: [
    ProfessionalRole.AdministrativeStaff,
    ProfessionalRole.Patient,
    ProfessionalRole.PatientCaregiver,
    ProfessionalRole.Payer,
    ProfessionalRole.Professor,
    ProfessionalRole.HealthEconomist,
    ProfessionalRole.FormerExec,
    ProfessionalRole.Nurse,
    ProfessionalRole.NursePractitioner,
    ProfessionalRole.Pharmacist,
    ProfessionalRole.PhysiciansAssistant,
    ProfessionalRole.Other,
  ],
  npiNumber: [
    ProfessionalRole.AdministrativeStaff,
    ProfessionalRole.Patient,
    ProfessionalRole.PatientCaregiver,
    ProfessionalRole.Payer,
    ProfessionalRole.Professor,
    ProfessionalRole.HealthEconomist,
    ProfessionalRole.FormerExec,
  ],
};

export const Optional = {
  clinicalPracticeStartedOn: [
    ProfessionalRole.Other,
  ],
  deaNumber: [
    ProfessionalRole.Physician,
    ProfessionalRole.NursePractitioner,
    ProfessionalRole.PhysiciansAssistant,
    ProfessionalRole.PatientCaregiver,
    ProfessionalRole.Other,
  ],
  npiNumber: [
    ProfessionalRole.Nurse,
    ProfessionalRole.Pharmacist,
    ProfessionalRole.Other,
  ],
  specialtyGroup: [
    ProfessionalRole.Nurse,
    ProfessionalRole.NursePractitioner,
    ProfessionalRole.PhysiciansAssistant,
  ],
};

export const Required = {
  stateLicenseNumber: [
    ProfessionalRole.Nurse,
    ProfessionalRole.Pharmacist,
  ],
  stateOfPractice: [
    ProfessionalRole.Physician,
  ],
};

export const test = {
  alpha(value: string) {
    return /^[a-zA-Z]+$/.test(value);
  },
  digits(value: string) {
    return /^\d+$/.test(value);
  },
};

export const validators = {
  deaNumber: (value: string) => {
    if (value.length === 0) return false;

    const alpha = value.slice(0, 2);
    const numeric = value.slice(2);

    return !(test.alpha(alpha) && alpha.length === 2 && test.digits(numeric) && numeric.length === 7);
  },
  isCountryUSA: (country: string) => {
    return ['United States', 'US', 'USA'].includes(country);
  },
  isNonMedical: (role: ProfessionalRole) => {
    return [
      ProfessionalRole.AdministrativeStaff,
      ProfessionalRole.Patient,
      ProfessionalRole.PatientCaregiver,
      ProfessionalRole.Payer,
      ProfessionalRole.Professor,
      ProfessionalRole.HealthEconomist,
      ProfessionalRole.FormerExec,
    ].includes(role);
  },
  meNumber: (value: string) => {
    if (value.length === 0) return false;

    return !(value.length === Length.me && test.digits(value));
  },
  npiNumber: (value: string) => {
    if (value.length === 0) return false;

    return !(value.length === Length.npi && test.digits(value));
  },
};