import { useCallback, useMemo } from 'react';
import Select from 'react-select';
import { Star } from 'react-feather';
import type { ActionMeta, FormatOptionLabelMeta, GetOptionLabel, GetOptionValue, InputActionMeta, MultiValue, StylesConfig } from 'react-select';
import { cx } from '@utils';
import { assertIsGoodQuoteTag, computeTranscriptColor } from '@/components/Transcript/utils';
import type { ConferenceTagType } from '@/enums';
import styles from './style/Tags.MultiSelect.css';

type Props = {
  className?: string;
  inputValue: string;
  noOptionsMessage: (obj: { inputValue: string }) => string | null;
  onChange: (value: MultiValue<TagsMultiselectItem>, meta: ActionMeta<TagsMultiselectItem>) => void;
  onInputChange: (newValue: string, actionMeta: InputActionMeta) => void;
  options: TagsMultiselectItem[];
  placeholder?: string;
  selected: TagsMultiselectItem[];
};

export const TagsMultiSelect = ({
  className,
  inputValue,
  noOptionsMessage,
  options,
  onChange, onInputChange,
  placeholder = 'Search tags...',
  selected,
}: Props) => {

  // @ts-ignore
  const colorStyles: StylesConfig<TagsMultiselectItem, true> = useMemo(() => ({
    // @ts-ignore
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: computeTranscriptColor({ color: data.color, opacity: 10 }),
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: computeTranscriptColor({ color: data.color, opacity: 40 }),
        padding: 0,
      };
    },
    // @ts-ignore
    multiValueLabel: (styles, { data }) => {
      return {
        ...styles,
        fontFamily: 'var(--font-semibold)',
        color: 'var(--black)',
        padding: 6,
      };
    },
    // @ts-ignore
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      borderLeftStyle: 'solid',
      borderLeftWidth: 1,
      borderLeftColor: computeTranscriptColor({ color: data.color, opacity: 40 }),
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      cursor: 'pointer',
      color: 'var(--black)',
      ':hover': {
        // color: 'white',
      },
    }),
  }), []);

  const renderOptionLabel = useCallback((tag: TagsMultiselectItem, metadata: FormatOptionLabelMeta<TagsMultiselectItem>) => {
    const isGoodQuoteTag = assertIsGoodQuoteTag(tag);
    const label = tag['label'] as string ?? tag?.name;

    const isSelected = metadata.context === 'value' &&
      metadata.selectValue.length &&
      metadata.selectValue[0].id === tag.id;

    return (
      <div className={cx(styles.tagOption, {
        [styles.selected]: isSelected,
      })}>
        {isGoodQuoteTag && (
          <Star className={styles.star} />
        )}
        {!isGoodQuoteTag && metadata.context === 'menu' && (
          <div
            className={styles.color}
            style={{ backgroundColor: tag.color }} />
        )}
        {label}
      </div>
    );
  }, []);

  const getOptionValue: GetOptionValue<TagsMultiselectItem> = useCallback(o => `${o.id}`, []);
  const getOptionLabel: GetOptionLabel<TagsMultiselectItem> = useCallback(o => o.name, []);

  return (
    <Select<TagsMultiselectItem, true>
      className={className}
      components={{
        DropdownIndicator: null,
      }}
      styles={colorStyles}
      value={selected}
      inputValue={inputValue}
      isMulti
      options={options}
      placeholder={placeholder}
      formatOptionLabel={renderOptionLabel}
      noOptionsMessage={noOptionsMessage}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      onChange={onChange}
      onInputChange={onInputChange} />
  );
};

export type TagsMultiselectItem = {
  color: string;
  id: number;
  name: string;
  typeId: ConferenceTagType;
  range?: {
    start: number;
    end: number;
  };
};