import { memo } from 'react';

type Props = {
  className?: string;
  size: number;
};

export const Search = memo(({ className, size }: Props) => {
  return (
    <svg
      className={className}
      preserveAspectRatio="xMidYMid"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="11" cy="11" r="8" />
      <line x1="21" y1="21" x2="16.65" y2="16.65" />
    </svg>
  );
});

export default Search;