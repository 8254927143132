import { Tooltip } from '@presentation/Tooltip';
import { cx, getLocationFor } from '@utils';
import { MaybeLink } from '@/components/MaybeLink';
import type { TableCellItem } from './interfaces';
import styles from './style/Table.css';

type Props = TableCellItem;

export const Cell = (props: Props) => {
  const loc = props.row.original.respondent.statusId
    ? getLocationFor.user.profile({ slug: String(props.row.original.respondent.id) })
    : null;

  return (
    <Tooltip title={props.row.original.respondent.id}>
      <div className={styles.trunc}>
        <MaybeLink
          className={cx(styles.id, { [styles.link]: !!loc })}
          to={loc}>
          {props.row.original.respondent.id}
        </MaybeLink>
      </div>
    </Tooltip>
  );
};

Cell.displayName = 'Column.Id.Cell';