import { useCallback, useContext, useEffect, useRef } from 'react';
import { RefreshCw } from 'react-feather';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { FileSummaryContext } from '@/containers/Workspace.File/Context';

function usePollingRefetch(enabled: boolean) {
  const timer = useRef<NodeJS.Timeout>();
  const ctx = useContext(FileSummaryContext);

  useEffect(() => {
    const cleanup = () => {
      if (timer.current) clearInterval(timer.current);
    };

    cleanup();

    if (enabled) {
      timer.current = setInterval(() => {
        ctx.refetch();
      }, 5000);
    }

    return cleanup;
  }, [ctx, enabled]);
}

export function SummaryActionRefresh() {
  const ctx = useContext(FileSummaryContext);

  const isRefreshing = ctx?.isLoading || ctx?.isRegenerating || ctx?.summary?.status === 'pending';

  usePollingRefetch(ctx?.summary?.status === 'pending');

  const handleRegenerate = useCallback(() => {
    if (ctx) {
      ctx.regenerate();
    }
  }, [ctx]);

  return (
    <Root
      isRefreshing={isRefreshing}
      disabled={isRefreshing}
      onClick={!isRefreshing ? handleRegenerate : undefined}>
      <span>Refresh</span>
      <StyledIcon
        isRefreshing={isRefreshing}
        strokeWidth={3} />
    </Root>
  );
}

type RootProps = {
  isRefreshing: boolean;
};

const Root = styled('button', { shouldForwardProp: p => p !== 'isRefreshing' })<RootProps>`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${props => !props.disabled || props.isRefreshing ? `#5C73AD` : `var(--gray)`};
  cursor: ${props => !props.disabled ? `pointer` : `initial`};
`;

type IconProps = {
  isRefreshing: boolean;
};

const spin = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
});

const StyledIcon = styled(RefreshCw, { shouldForwardProp: p => p !== 'isRefreshing' })<IconProps>`
  animation-name: ${props => props.isRefreshing ? spin : undefined};
  animation-duration: ${props => props.isRefreshing ? '800ms' : undefined};
  animation-iteration-count: ${props => props.isRefreshing ? 'infinite' : undefined};
  animation-timing-function: ${props => props.isRefreshing ? 'linear' : undefined};
`;