import { useContext } from 'react';
import { SearchInstanceContext } from '../Context';

type Params = unknown;

const useSearchQuery = () => {
  return useContext(SearchInstanceContext);
};

export { useSearchQuery };
export default useSearchQuery;