import { memo } from 'react';
import styled from '@emotion/styled';
import { SvgIcon } from '@mui/material';

export const ChatFooter = memo(() => {
  return (
    <Root>
      <Wrap>
        <Icon />
        <Divider />
        <Trademark>
          &copy; &nbsp;
          {new Date().getFullYear()} Trinity. All rights reserved.
        </Trademark>
        <LinksContainer>
          <StyledLink href="https://trinitylifesciences.com/privacy-policy/" target="_blank">Privacy Policy</StyledLink>
          <StyledLink href="https://trinitylifesciences.com/about/" target="_blank">About</StyledLink>
          <StyledLink href="https://trinitylifesciences.com/contact/" target="_blank">Contact</StyledLink>
        </LinksContainer>
      </Wrap>
    </Root>
  );
});

const Icon = memo(() => (
  <SvgIcon viewBox="0 0 247.35 60.66" style={{ width: 'auto' }}>
    <defs>
      <linearGradient
        id="a"
        x1={-6.9}
        x2={36.82}
        y1={51.99}
        y2={8.26}
        gradientUnits="userSpaceOnUse">
        <stop offset={0} stopColor="#20a9e2" />
        <stop offset={0.41} stopColor="#3c84c5" />
        <stop offset={0.78} stopColor="#5167b0" />
        <stop offset={1} stopColor="#595da8" />
      </linearGradient>
    </defs>
    <g>
      <path d="M60.79 49.79h-3.36V23.45h-9.87v-3.07h23.1v3.07h-9.87ZM102.2 49.79l-8.53-11.43h-8.4v11.43H82V20.38h12.6c6.47 0 10.63 3.49 10.63 8.78 0 4.92-3.36 7.77-8 8.61l9 12Zm-7.85-26.34h-9.08v11.93h9c4.42 0 7.57-2.27 7.57-6.09.03-3.66-2.75-5.84-7.49-5.84ZM121 20.38v29.41h-3.3V20.38ZM156.34 20.38h3.23v29.41h-2.64l-19-24.12v24.12h-3.23V20.38h3.1L156.34 44ZM176.58 20.38v29.41h-3.32V20.38ZM201.4 49.79H198V23.45h-9.87v-3.07h23.1v3.07h-9.83ZM235.13 49.79h-3.36V38.15l-12.23-17.77h4l9.91 14.7 10-14.7h3.86l-12.18 17.73Z" />
      <path
        d="M6 30.45v27.19a3 3 0 1 1-6 0V30.45a3 3 0 1 1 6 0Zm9-22a3 3 0 0 0-3 3v37.2a3 3 0 1 0 6 0V11.48a3 3 0 0 0-3-3.02ZM27.31 0a3 3 0 0 0-3 3v27.39a3 3 0 1 0 6 0V3a3 3 0 0 0-3-3Z"
        fill="url(#a)" />
    </g>
  </SvgIcon>
));

export const FooterHeight = 48;

const Trademark = styled.div({
  display: 'flex',
  alignItems: 'center',
  fontSize: 14,
});

const Divider = styled.hr({
  marginLeft: 8,
  borderWidth: '0px thin 0px 0px',
  height: 14,
  borderStyle: 'solid',
  marginRight: 16,
});

const Root = styled.div(({ theme }) => ({
  background: theme.palette.gray.light2,
  width: '100%',
  height: FooterHeight,
  boxSizing: 'border-box',
}));

const Wrap = styled.div({
  display: 'flex',
  padding: `8px 20px`,
  boxSizing: 'border-box',
});

const LinksContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 14,
  marginLeft: 'auto',
});

const StyledLink = styled.a(({ theme }) => ({
  color: '#27AAE1',
  fontSize: 15,
}));