import * as types from '@store/action-types';

type ConnectionChangeState = {
  isConnected: boolean;
};

type ConnectionChangeAction = {
  type: typeof types.CONNECTION_CHANGE;
} & ConnectionChangeState;

export const connectionChange = (data: ConnectionChangeState): ConnectionChangeAction => {
  return {
    isConnected: data.isConnected,
    type: types.CONNECTION_CHANGE,
  };
};