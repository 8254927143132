import { useContext, useMemo } from 'react';
import { ChatSkinContext } from '@/brand-insights/components/BrandInsights/context';
import { ChatSkin } from '@/brand-insights/enums';

export const useIsFullPageChat = () => {
  const chatSkinContext = useContext(ChatSkinContext);

  return useMemo(() => {
    return chatSkinContext === ChatSkin.Standalone;
  }, [chatSkinContext]);
};