import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import type { GroupProjectRouteParams } from '@containers/GroupProject/interfaces';
import { ProjectTabsContext } from '../Context';
import { useTrackTabChange } from './useTrackTabChange';

export const useGroupProjectTabViewState = () => {
  const params = useParams<GroupProjectRouteParams>();
  const history = useHistory();
  const routes = useContext(ProjectTabsContext);
  const keys = useMemo(() => routes.map(x => x.key), [routes]);
  const trackTabChange = useTrackTabChange();

  const tabIndex = useMemo(() => {
    const idx = keys.indexOf(params.tab);

    return idx >= 0 && idx < routes.length
      ? idx
      : 0;
  }, [
    keys,
    params.tab,
    routes.length,
  ]);

  const [index, setIndex] = useState(tabIndex);

  const setTabIndex = useCallback(() => {

    setIndex(tabIndex);
  }, [
    setIndex,
    tabIndex,
  ]);

  const setLocation = useCallback((idx: number) => {
    const route = routes[idx];

    history.push(`/projects/${params.slug}/${route.key ?? ''}`);
  }, [
    history,
    params,
    routes,
  ]);

  useEffect(() => {

    if (params.tab) {
      setTabIndex();
      trackTabChange(params.tab);
    }

  }, [
    params.tab,
    setTabIndex,
    trackTabChange,
  ]);

  const state = {
    index,
    routes,
  };

  return [state, setLocation] as const;
};