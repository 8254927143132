import { useCallback } from 'react';
import { Remirror } from '@remirror/react';
import { SurveyLogic } from '@/types';
import { ExpressionEditor } from '@/components/SurveyBuilder.Logic/interfaces';
import styles from '@/components/SurveyBuilder.Logic/style/Expression.Editor.css';

type Props = {
  editable?: boolean;
  onChange?: ExpressionEditor.OnChange;
  manager: ExpressionEditor.Manager;
  setState: ExpressionEditor.SetState;
  state: ExpressionEditor.State;
} & ChildrenProps;

export const ExpressionEditorContainer = ({
  children,
  editable = true,
  manager,
  onChange,
  setState,
  state,
}: Props) => {

  const handleChange: ExpressionEditor.OnChange = useCallback(params => {

    if (params.tr?.docChanged) {
      const hasText = !!params.state.doc.textContent.length;
      const hasMultipleLines = params.state.doc.content.childCount > 1;

      if (hasText || hasMultipleLines) {
        manager.view.updateState(params.previousState);
      }
    }
  }, [manager]);

  return (
    <Remirror
      classNames={[styles.editor]}
      autoFocus={true}
      editable={editable}
      manager={manager}
      onChange={handleChange}
      initialContent={state}>
      {children}
    </Remirror>
  );
};