import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { menu, path, pathname } from '@consts';
import { useLogout } from '@containers/AppStateEffect/hooks';
import { useBadgeNotificationStats } from '@containers/Notifications/Context';
import { useHasInternalAdminRole } from '@containers/User/hooks/useHasInternalAdminRole';
import { useHasConsultantRole } from '@containers/User/hooks';
import { getLocationFor, hasTransientRole, usePrevious } from '@utils';
import { useWhitelabelMenuValidator } from '@/components/Whitelabel/hooks/useWhitelabelMenuValidator';
import { useSelectUser } from '../Store';
import type { MenuItemConfig } from './interfaces';
import { useBuildMenuItem } from './useBuildMenuItem';
import { memoizedSelectValidator } from './utils';
import { MenuContext, defaultValue } from './Context';

const selectOnboardingCompleted = (state: Store.State) => !!state.onboarding.completedOn;

type Props = {
  children: React.ReactNode;
};

export const MenuProvider = (props: Props) => {
  const [context, setMenu] = useState(defaultValue);
  const stats = useBadgeNotificationStats();
  const prevStats = usePrevious(stats);
  const build = useBuildMenuItem();
  const whiteLabelValidator = useWhitelabelMenuValidator();
  const validator = useSelector(memoizedSelectValidator);

  const user = useSelectUser();
  const previousUserId = usePrevious(user?.id);

  const onboardingCompleted = useSelector(selectOnboardingCompleted);
  const prevOnboardingCompleted = usePrevious(onboardingCompleted);

  const hasInternalAdminRole = useHasInternalAdminRole();
  const hasConsultantRole = useHasConsultantRole();

  const admin = useCallback((config: MenuItemConfig, ...args: boolean[]) => {
    return hasInternalAdminRole
      && build(config, ...args);
  }, [build, hasInternalAdminRole]);

  const consultant = useCallback((config: MenuItemConfig, ...args: boolean[]) => {
    return hasConsultantRole
      && build(config, ...args);
  }, [build, hasConsultantRole]);

  const logout = useLogout();

  const getProfileParams = useCallback(() => {
    return getLocationFor.me.profile({
      slug: user.profile.slug,
      userId: user.id,
    });
  }, [user.id, user.profile.slug]);

  const handleLogout = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    logout({
      sideEffects: true,
    });
  }, [logout]);

  const getMenu = useCallback(() => {
    if (hasTransientRole(user)) {
      const items = [
        build({ name: menu.Logout, to: path.Website.PlatformLogin, onClick: handleLogout }),
      ];

      return {
        ...defaultValue,
        account: items,
        drawer: items,
        menu: {
          ...defaultValue.menu,
          drawer: items,
        },
      };
    }

    const platformUser = !(hasConsultantRole || hasInternalAdminRole);

    const account = [
      admin({ name: menu.Admin, to: path.Admin.Root }, validator.admin),
      build({ name: menu.Research, to: path.Research.Members }, validator.research),
      build({ name: menu.Settings, to: path.Settings.Account }),
      build({ name: menu.Logout, to: platformUser ? path.Website.PlatformLogin : path.Website.Login, onClick: handleLogout }),
    ]
      .filter(x => whiteLabelValidator(x?.key))
      .filter(Boolean);

    const drawer = [
      build({ name: menu.Home, to: pathname.HOME }, validator.home),
      consultant({ name: menu.Share, to: pathname.REFER }),
      build({ name: menu.Projects, to: pathname.PROJECTS }, validator.projects),
      build({ name: menu.Calendar, to: pathname.CALENDAR }, validator.calendar),
      build({ name: menu.Messages, to: pathname.MESSAGES }, validator.messages),
      build({ name: menu.Search, to: pathname.SEARCH }, validator.search),
      build({ name: menu.Notifications, to: pathname.NOTIFICATIONS }, validator.notifications),
      consultant({ name: menu.Profile, to: getProfileParams() }),
      build({ name: menu.Settings, to: path.Settings.Root }),
      build({ name: menu.Logout, to: platformUser ? path.Website.PlatformLogin : path.Website.Login, onClick: handleLogout }),
    ]
      .filter(x => whiteLabelValidator(x?.key))
      .filter(Boolean);

    const nav = [
      build({ name: menu.Home, to: pathname.HOME }, validator.home),
      build({ name: menu.InsightsChat, to: path.BrandInsights.Chat }, validator.insightsChat),
      build({ name: menu.Calendar, to: pathname.CALENDAR }, validator.calendar),
      build({ name: menu.Messages, to: pathname.MESSAGES }, validator.messages),
      build({ name: menu.Projects, to: pathname.PROJECTS }, validator.projects),
    ]
      .filter(x => whiteLabelValidator(x?.key))
      .filter(Boolean);

    const notifications = build({ name: menu.Notifications }, validator.notifications, whiteLabelValidator(menu.Notifications));

    const navbar = [
      ...nav,
      notifications,
    ].filter(Boolean);

    const legacy = {
      drawer,
      dropdown: account,
      navbar,
    };

    return {
      account,
      drawer,
      menu: legacy,
      nav,
      notifications,
    };

  }, [
    admin,
    build,
    consultant,
    getProfileParams,
    handleLogout,
    hasConsultantRole,
    hasInternalAdminRole,
    user,
    validator,
    whiteLabelValidator,
  ]);

  const setNavigation = useCallback(() => setMenu(getMenu()), [getMenu]);

  useEffect(() => {
    if (user.id && (!Object.values(defaultValue.menu).flat().length)) {
      setNavigation();
    }
  }, [user?.id, setNavigation]);

  useEffect(() => {
    if (!previousUserId && user.id) {
      setNavigation();
    }

    if (previousUserId && !user.id) {
      setMenu(defaultValue);
    }

    if (user.id) {
      if ((prevStats?.total ?? 0) !== stats.total ||
        prevOnboardingCompleted !== onboardingCompleted) {
        setNavigation();
      }
    }
  }, [
    prevOnboardingCompleted,
    previousUserId,
    prevStats?.total,
    setNavigation,
    onboardingCompleted,
    user.id,
    stats.total,
  ]);

  return (
    <MenuContext.Provider value={context}>
      {props.children}
    </MenuContext.Provider>
  );
};

MenuProvider.whyDidYouRender = true;