import { useCallback, useMemo } from 'react';
import { OnboardingChecklistItem } from '@enums';
import { OnboardingChecklistContext } from './Context';
import { useFetchOnboardingChecklist } from './hooks/useFetchOnboardingChecklist';

type Props = {
  children: React.ReactNode;
};

export const OnboardingChecklistContainer = (props: Props) => {

  const query = useFetchOnboardingChecklist({
    placeholderData: [] as OnboardingChecklistItem[],
  });

  const remaining = query.data;

  const isComplete = (key: OnboardingChecklistItem) => {
    return !remaining.includes(key);
  };

  const count = useMemo(() => {
    const total = Object.keys(OnboardingChecklistItem).length;
    const incomplete = remaining.length;
    const complete = total - incomplete;

    return {
      complete,
      incomplete,
      total,
    };

  }, [remaining]);

  const value = {
    count,
    isComplete: useCallback(isComplete, [remaining]),
    loading: query.isLoading,
    remaining,
  };

  return (
    <OnboardingChecklistContext.Provider value={value}>
      {props.children}
    </OnboardingChecklistContext.Provider>
  );
};

OnboardingChecklistContainer.displayName = 'OnboardingChecklist.Container';