import { memo } from 'react';
import Select, { ActionMeta } from 'react-select';
import { Members } from '$admin/interfaces/search';
import selectStyles from './SelectStyles';
import styles from './style/Filters.css';

type Props = {
  disabled?: boolean;
  visible?: boolean;
  value: Members.Filters.Operator;
  onChange: (value: FilterOperatorOption, meta: ActionMeta<FilterOperatorOption>) => unknown;
};

export const FilterOperator = memo(({ disabled, visible = true, value, onChange }: Props) => {
  if (!visible) return null;

  return (
    <div className={styles.filterOp}>
      <Select<FilterOperatorOption>
        className={styles.select}
        isDisabled={disabled}
        options={options}
        value={options.find(o => o.id === value)}
        getOptionLabel={o => o.name}
        getOptionValue={o => o.id}
        onChange={onChange}
        isSearchable={false}
        components={{
          DropdownIndicator: null,
        }}
        styles={selectStyles} />
    </div>
  );
});

export type FilterOperatorOption = { id: Members.Filters.Operator; name: string; };

const options: FilterOperatorOption[] = [
  { id: 'a', name: 'And' },
  { id: 'o', name: 'Or' },
];