import { useMemo } from 'react';
import type { IConference } from '@containers/Conference';
import { useTwilioVideo, useConferenceInstance } from '@containers/Conference';
import { roleIsModeratorLike } from '@/components/Conference.Participants/utils';

type Props = {
  twilioIdentity: string;
  hasAudio?: boolean;
  hasCamera?: boolean;
  hasScreen?: boolean;
};

type Identity = {
  name: string;
  pictureUrl: string;
  type: 'phone' | 'web';
  visible: boolean;
};

export function useParticipantIdentity({ twilioIdentity, hasAudio, hasCamera, hasScreen }: Props): Identity {
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom>();
  const twilio = useTwilioVideo();

  return useMemo(() => {
    const participant = instance.participants.find(p => p.twilioIdentity === twilioIdentity);

    if (!participant) {
      return {
        name: 'Participant',
        type: 'web',
        pictureUrl: null,
        visible: true,
      };
    }

    const visible = participant.type === 'phone'
      ? participant.isVisible // phone always have audio/track which would override hidden
      : (hasAudio || hasCamera || hasScreen)
        ? true
        : participant.isVisible;

    const viewerModerator = roleIsModeratorLike(instance.participant.role);

    return {
      name: twilio.room.localParticipant.identity === twilioIdentity ? 'You' : viewerModerator ? participant.originalName : participant.name,
      type: participant.type,
      pictureUrl: participant.auth === 'authorized-member' || participant.auth === 'outside-member' ? participant.pictureUrl : null,
      visible,
    };
  }, [
    hasAudio,
    hasCamera,
    hasScreen,
    twilio.room.localParticipant.identity,
    twilioIdentity,
    instance.participants,
    instance.participant.role,
  ]);
}