import { useCallback } from 'react';
import { SurveyQuestionType } from '@enums';
import { useSurveyQuestionResponsesData } from '@containers/SurveyResponses/hooks/useSurveyQuestionResponsesData';
import { NumberInputTable } from '../interfaces';

export const useGetNumberInputTableBucketUsers = () => {

  const {
    responses,
  } = useSurveyQuestionResponsesData<SurveyQuestionType.NumberInputTable>();

  return useCallback((rowId: number, optionId: number) => ({ minValue, maxValue }: NumberInputTable.Bucket) => {
    const allUsers = responses[rowId].options[optionId].users;
    return Object.keys(allUsers).reduce((acc, userId) => {
      const value = allUsers[+userId];

      if (value > minValue && value <= maxValue) {
        return {
          ...acc,
          [+userId]: value,
        };
      }

      return acc;
    }, {} as {
      [userId: number]: number;
    });
  }, [
    responses,
  ]);

};