import { useCallback, useMemo } from 'react';
import { NumberFormatValues, SourceInfo } from 'react-number-format';
import { CurrencyLegacy } from '@/types/survey.currency';
import { SurveyTemplate } from '@/types/survey.templates';
import { getCurrencySymbolLegacy } from '@utils/format';
import { CurrenciesDropdown } from '@/components/Currency';
import { NumberInput } from '@/components/Input';
import styles from './style/Presets.css';

type Props = {
  onCurrencyChange: (value: CurrencyLegacy) => void;
  onChange: (key: keyof SurveyTemplate.DrugPrice) => (value: number) => void;
  value: SurveyTemplate.DrugPrice;
};

export const TemplateDrugPriceInput = ({ onCurrencyChange, onChange, value }: Props) => {

  return (
    <>
      <div className={styles.label}>Currency</div>
      <div className={styles.currencies}>
        <CurrenciesDropdown
          onChange={onCurrencyChange}
          value={value.currency} />
      </div>
      <div className={styles.label}>High Price</div>
      <DrugPriceInput
        currency={value.currency}
        onChange={onChange('high')}
        value={value.high} />
      <div className={styles.label}>Medium Price</div>
      <DrugPriceInput
        currency={value.currency}
        onChange={onChange('medium')}
        value={value.medium} />
      <div className={styles.label}>Low Price</div>
      <DrugPriceInput
        currency={value.currency}
        onChange={onChange('low')}
        value={value.low} />
    </>
  );
};

type InputProps = {
  currency: CurrencyLegacy;
  onChange: (value: number) => void;
  value: number;
};

const DrugPriceInput = ({ currency, onChange, value }: InputProps) => {

  const hasDecimals = useMemo(() => {
    return ['USD_WITH_CENTS', 'EUR_WITH_CENTS'].includes(currency);
  }, [currency]);

  const handleChange = useCallback((values: NumberFormatValues, sourceInfo: SourceInfo) => {
    onChange(values.floatValue);
  }, [onChange]);

  return (
    <div className={styles.drugPrice}>
      <NumberInput
        allowNegative={false}
        decimalScale={hasDecimals ? 2 : 0}
        fixedDecimalScale={true}
        prefix={getCurrencySymbolLegacy(currency)}
        thousandSeparator=","
        placeholder="Enter price"
        onValueChange={handleChange}
        value={value} />
    </div>
  );
};