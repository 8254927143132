import { useCallback } from 'react';
import { useSurveyTemplate, useSubmitSurveyDraft } from '@containers/SurveyBuilder/hooks';
import { GrayOutlineButton } from '@presentation/Buttons';
import { Button } from '@/components/Button';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import ModalHeader from '@/components/Modal/Header';
import { useModal } from '@/components/Modal/hooks';
import { useCanSaveProduct, useTemplateProduct } from './hooks/product';
import { TemplateProduct } from './Input.Product';
import styles from './style/Modal.css';

type Props =
  Pick<ModalProps,
    'open' |
    'onClose'>;

export const TemplateProductModal = ({
  onClose,
  open,
}: Props) => {

  const {
    onChange,
    onSubmit,
    value,
  } = useTemplateProduct();
  const [template] = useSurveyTemplate();

  const submitDraft = useSubmitSurveyDraft();

  const handleSubmit = useCallback(() => {

    onSubmit();
    submitDraft();
    onClose();
  }, [
    onSubmit,
    onClose,
    submitDraft,
  ]);

  const canSubmit = useCanSaveProduct(value);

  return (
    <Modal
      open={open}
      onClose={onClose}>

      <ModalHeader text="Update Template Product" />

      <div className={styles.body}>
        <TemplateProduct
          onChange={onChange}
          value={value} />
      </div>

      <div className={styles.footer}>
        <GrayOutlineButton
          onClick={onClose}
          title="Cancel" />
        <Button.Primary
          className={styles.submit}
          disabled={!canSubmit}
          onClick={handleSubmit}
          title="Submit"
          variant="brick" />
      </div>
    </Modal>
  );
};

export const useTemplateProductModal = () => useModal(TemplateProductModal);

export default TemplateProductModal;