import { cx } from '@utils';
import {
  OpeningParenthesisButton, ClosingParenthesisButton,
  OrOperatorButton, AndOperatorButton, ConditionButton,
} from './Expression.ToolbarCommands';
import styles from './style/Expression.Toolbar.css';

type Props = {
  className?: string;
};

export const Toolbar = ({ className }: Props) => {

  return (
    <div className={cx(styles.toolbar, className)}>
      <OpeningParenthesisButton />
      <ClosingParenthesisButton />

      <div className={styles.divider} />

      <AndOperatorButton />
      <OrOperatorButton />

      <div className={styles.divider} />

      <ConditionButton />
    </div>
  );
};