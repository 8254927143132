import { Fragment, memo } from 'react';
import { Results } from '../interfaces/members';

export const Cell = memo(({ row }: Results.TableCellProps) => {
  return (
    <Fragment>
      {row.original.countryCodes.length ? row.original.countryCodes.join(', ') : '-'}
    </Fragment>
  );
});

export const Filter = memo(() => null);

export const Header = memo(() => {
  return (
    <Fragment>
      Country
    </Fragment>
  );
});

Cell.displayName = 'Column.Country.Cell';
Filter.displayName = 'Column.Country.Filter';
Header.displayName = 'Column.Country.Header';