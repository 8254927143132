import { useRef, useContext, useCallback } from 'react';
import { Download, MessageCircle, Edit2, Trash2, Scissors, Copy, Star } from 'react-feather';
import VoiceOutlinedIcon from '@mui/icons-material/RecordVoiceOverOutlined';
import { useElementBoundingRect } from '@utils';
import { PopperMenu, PopperMenuItem } from '@/components/Popper';
import { Tagging, Highlight } from '@/components/icons';
import { FloatingWrapper } from '@/components/Remirror';
import { StyledPopper, classes } from './StyledPopper';
import { TranscriptEditorRefContext, MenuColorDisplayContext } from './context';
import {
  useDownloadHighlight,
  useMarkGoodQuote,
  useCopyHighlightedText,
  useHighlightTooltipPositioner,
  useHelpers,
  useAddTag,
  useAddNewComment,
  useRedactHighlight,
  useTextReplaceHighlight,
  useHandleReassignSpeaker,
  useTooltipHighlightConfig,
} from './hooks';
import styles from './style/Highlight.Menu.css';

const ICON_SIZE = 22;

type Props = {
  onRemove: () => void;
};

export const Menu = ({ onRemove }: Props) => {
  const position = useHighlightTooltipPositioner();
  const containerRef = useRef<HTMLDivElement>();
  const editor = useContext(TranscriptEditorRefContext);
  const [, setColorTooltipShown] = useContext(MenuColorDisplayContext);

  const helpers = useHelpers();

  const highlight = helpers.getFocusedHighlight();

  const markGoodQuote = useMarkGoodQuote(highlight);
  const copyText = useCopyHighlightedText();
  const addTag = useAddTag(highlight);
  const addComment = useAddNewComment(highlight);
  const redactHighlight = useRedactHighlight();
  const textReplaceHighlight = useTextReplaceHighlight();
  const reassignSpeaker = useHandleReassignSpeaker();
  const downloadClip = useDownloadHighlight(highlight);

  const handleRedact = useCallback(() => {
    redactHighlight(highlight);
  }, [highlight, redactHighlight]);

  const handleTextReplaceHighlight = useCallback(() => {
    textReplaceHighlight(highlight);
  }, [
    highlight,
    textReplaceHighlight,
  ]);

  const handleReassignSpeaker = useCallback(() => {
    reassignSpeaker(highlight);
  }, [highlight, reassignSpeaker]);

  const handleShowColors = useCallback(() => {
    setColorTooltipShown(true);
  }, [setColorTooltipShown]);

  const editorBoundingRect = useElementBoundingRect(editor);

  const leftPos = editorBoundingRect ? editorBoundingRect.left + editorBoundingRect.width : null;

  const config = useTooltipHighlightConfig();
  const canRemove = config.colors && highlight?.dbId;

  return (
    <FloatingWrapper
      position={position}
      containerRef={containerRef}
      renderOutsideEditor={true}
      positionOverrides={{ left: leftPos }}>
      <StyledPopper
        id="highlight-menu"
        className={classes.popper}
        container={containerRef.current}
        placement="right"
        open={position.visible}
        anchorEl={position.element}
        style={{
          zIndex: 2,
        }}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 15],
            },
          },
        ]}>
        <PopperMenu>
          {config.colors &&
            <PopperMenuItem className={styles.menuItem} onClick={handleShowColors}>
              <Highlight size={ICON_SIZE} />
              Highlight
            </PopperMenuItem>
          }
          {config.copy &&
            <PopperMenuItem className={styles.menuItem} onClick={copyText}>
              <Copy size={ICON_SIZE} />
              Copy Text
            </PopperMenuItem>
          }
          {config.tagging &&
            <PopperMenuItem className={styles.menuItem} onClick={addTag}>
              <Tagging size={ICON_SIZE} />
              Add Tag
            </PopperMenuItem>
          }
          {config.commenting &&
            <PopperMenuItem className={styles.menuItem} onClick={addComment}>
              <MessageCircle size={ICON_SIZE} />
              Add Comment
            </PopperMenuItem>
          }
          {config.redaction &&
            <PopperMenuItem className={styles.menuItem} onClick={handleRedact}>
              <Scissors size={ICON_SIZE} />
              Redact Text
            </PopperMenuItem>
          }
          {config.replace &&
            <PopperMenuItem className={styles.menuItem} onClick={handleTextReplaceHighlight}>
              <Edit2 size={ICON_SIZE} />
              Edit Text
            </PopperMenuItem>
          }
          {config.speaker &&
            <PopperMenuItem className={styles.menuItem} onClick={handleReassignSpeaker}>
              <VoiceOutlinedIcon style={{ height: `${ICON_SIZE}px` }} />
              Edit Speaker
            </PopperMenuItem>
          }
          {canRemove &&
            <PopperMenuItem className={styles.menuItem} onClick={onRemove}>
              <Trash2 size={ICON_SIZE} />
              Remove
            </PopperMenuItem>
          }
          {config.tagging && !highlight.dbId && (
            <PopperMenuItem className={styles.menuItem} onClick={markGoodQuote}>
              <Star size={ICON_SIZE} />
              Mark Good Quote
            </PopperMenuItem>
          )}
          {config.clip && (
            <PopperMenuItem className={styles.menuItem} onClick={downloadClip}>
              <Download size={ICON_SIZE} />
              Download Clip
            </PopperMenuItem>
          )}
        </PopperMenu>
      </StyledPopper>
    </FloatingWrapper>
  );
};