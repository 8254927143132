import { cx } from '@utils';
import styles from './style.css';

type Props = {
  children:   React.ReactNode;
  className?: string;
};

export const Header = (props: Props) => {
  const className = cx(styles.h, props.className);

  return (
    <div className={className}>
      {props.children}
    </div>
  );
};

export const Title = (props: Props) => {
  const className = cx(styles.hTitle, props.className);

  return (
    <div className={className}>
      {props.children}
    </div>
  );
};

export const Subtitle = (props: Props) => {
  const className = cx(styles.hSubtitle, props.className);

  return (
    <div className={className}>
      {props.children}
    </div>
  );
};

export const Alert = (props: Props) => {
  const className = cx(styles.hAlert, props.className);

  return (
    <span className={className}>
      {props.children}
    </span>
  );
};