import {
  useState,
  Component,
  useLayoutEffect,
  useCallback,
  useRef,
  useContext,
} from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
/* import * as api from '@api/search'; */
import * as consts from '@consts';
import { SearchParamsContainer, SearchQueryLegacyContainer, useCompatSearchParams, useCompatSearchQuery } from '@containers';
import { RequestCallContainer } from '@containers/RequestCall/RequestCallContainer';
import { RequestCallContext } from '@containers/RequestCall/Context';
import * as enums from '@enums';
import * as selectors from '@store/selectors';
import * as utils from '@utils';
import ActivityIndicator from '@/components/ActivityIndicator';
/* import { Button } from '@/components/Button'; */
/* import ModalAddToProject from '@/components/Modal/AddToProject'; */
import AdvancedFilters from './AdvancedFilters';
import { Header, Main, Screen, Scroll, Sidebar } from './ContainerView';
import { GeneralSearchResultItem } from './ResultItem';
import { GeneralSearchListFooter, LoadingMoreIndicator } from './SearchListFooter';
import { GeneralSearchListHeader } from './SearchListHeader';
import { actions, getAdvancedSearchBody } from './SearchProvider';
import styles from './style.css';
/* import { getSearchQueryParamsDiff } from './utils'; */

const mapState = state => ({
  group: state.group,
  projects: selectors.selectProjectsSortedByName(state),
  routes: state.routing.routes,
  user: state.user,
});

const SearchWrapper = ({
  group,
  projects,
  user,
  history,
  location,
  routes,
}) => {

  const [params, dispatch] = useCompatSearchParams();
  const [query, fetch, fetchExport] = useCompatSearchQuery();
  const initialized = useRef(false);
  const [_, requestCall] = useContext(RequestCallContext);

  const initialSearch = () => {
    const urlParams = utils.qs.base64Decode(location.search);

    return {
      ...SearchParamsContainer.initialState,
      ...(urlParams ?? {}),
    };
  };

  const initialParams = initialSearch();

  const search = {
    ...(query.value ? query.value : SearchQueryLegacyContainer.initialState),
    params: initialized.current ? params : initialParams,
  };

  useLayoutEffect(() => {

    dispatch(actions.setFilters(search.params));

  /* eslint-disable-next-line */
  }, []);

  const addToAdvancedFilterList = data => dispatch(actions.addToAdvancedFilterList(data));
  const removeFromAdvancedFilterList = data => dispatch(actions.removeFromAdvancedFilterList(data));
  const setFilters = data => dispatch(actions.setFilters(data));
  const updateSort = data => dispatch(actions.updateSort(data));
  const resetFilters = data => dispatch(actions.resetFilters(data));
  const batchActions = data => dispatch(actions.batchActions(data));

  const [loading] = useState(false);

  function fetchOnce() {
    const params = getAdvancedSearchBody({
      search: initialParams,
      showRate: group.features?.showConsultantRates,
    });

    const query = utils.qs.base64Encode({ q: params });

    history.replace(`${consts.pathname.SEARCH}?${query}`);

    return fetch(params)
    .finally(() => initialized.current = true);
  }

  const fetchExportHandler = useCallback(() => fetchExport(params), [fetchExport, params]);

  const handleRequestCall = useCallback(item => () => requestCall(item), [requestCall]);

  return (
    <Search
      initialized={initialized.current}
      fetchOnce={fetchOnce}
      fetchExport={fetchExportHandler}
      params={search.params}
      search={{ fetchSearchResults: fetch, ...query, params }}
      batchActions={batchActions}
      addToAdvancedFilterList={addToAdvancedFilterList}
      removeFromAdvancedFilterList={removeFromAdvancedFilterList}
      setFilters={setFilters}
      updateSort={updateSort}
      resetFilters={resetFilters}
      history={history}
      location={location}
      group={group}
      projects={projects}
      routes={routes}
      user={user}
      loading={loading}
      requestCall={handleRequestCall} />
  );
};

const itemsPerRequest = 10;

const distanceToBottomBeforeLoadMore = 50;

const defaultSortOption = {
  sort: 'most_relevant',
  sortDir: 'desc',
};

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filtersVisible: false,
      loading: true,
      loadingMore: false,
      results: [],
      showProjectModal: false,
      selected: [],
      total: 0,
      fetchCount: 0,
    };
  }

  fetching = false;

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, false);

    this.props.fetchOnce()
    .then(data => this.updateResults({
      fetchCount: data.items.length,
      results: data.items,
      total: data.total,
    }));
  }

  componentDidUpdate(prevProps) {
    if (this.didFiltersChange(prevProps)) {
      this.updateParams();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false);
    this.resetFilters();
  }

  allResultsFetched = () => {
    return !this.state.loading
        && this.state.results.length === this.state.total
        && this.state.total > 0;
  }

  clearResults = () => {
    this.setState({
      loading: true,
      results: [],
      selected: [],
    });
  }

  fetchAll = () => {
    if (!this.props.initialized) return;

    this.clearResults();

    return this.getSearchResults(true)
    .then(data => this.updateResults({
      fetchCount: data.items.length,
      results: data.items,
      total: data.total,
    }));
  }

  fetchMore = () => {
    this.setState({ loadingMore: true });

    return this.getSearchResults(false)
    .then(data => this.updateResults({
      fetchCount: data.items.length,
      results: data.items,
      total: data.total,
    }));
  }

  getSearchResults = initial => {
    const params = getAdvancedSearchBody({
      search: this.props.params,
      showRate: this.showRate(),
    });

    return this.props.search.fetchSearchResults({
      ...params,
      size: itemsPerRequest,
      searchAfter: this.getSearchAfter(initial),
    });
  }

  getSelectedItems = () => {
    return this.state.results
      .filter(f => this.state.selected.includes(f.id));
  }

  didFiltersChange = prevProps => {
    return JSON.stringify(this.props.params) !== JSON.stringify(prevProps.params);
  }

  getActiveProjects = () => {
    return this.props.projects.filter(p => p.projectStatusId === enums.ProjectStatus.Active);
  }

  isEmptySearch = params => {
    return JSON.stringify(SearchParamsContainer.initialState) === JSON.stringify(params);
  }

  getSearchAfter = initial => {
    return !initial
           ? this.state.results[this.state.results.length - 1].sort
           : null;
  }

  handleSortChange = item => {
    this.props.updateSort({
      sort: item.sort,
      sortDir: item.dir,
    });
  }

  markItemSelected = item => () => {
    const { selected } = this.state;
    const updated = selected.includes(item.id)
      ? selected.filter(id => id !== item.id)
      : selected.concat(item.id);

    this.setState({ selected: updated });
  }

  onScroll = e => {
    const scrolledToEnd = (document.body.scrollHeight - distanceToBottomBeforeLoadMore) <= Math.ceil(e.currentTarget.scrollY + window.innerHeight);

    if (scrolledToEnd && !this.fetching && !this.state.loading && this.state.total !== this.state.results.length && this.state.fetchCount > 0) {
      this.fetching = true;
      this.fetchMore();
    }
  }

  resetFilters = () => {
    this.props.resetFilters(defaultSortOption);
  }

  addItemToSearchFilter = item => {
    this.props.addToAdvancedFilterList({
      list: enums.SearchType.JobFunction,
      item,
    });
  };

  showRate = () => {
    return this.props.group.features?.showConsultantRates;
  }

  shouldShowButtons = () => {
    const roles = [
      enums.Role.FirmAnalyst,
      enums.Role.InternalAdmin,
      enums.Role.FirmCompliance,
    ];

    return this.props.user.roles.some(r => roles.includes(r));
  }

  toggleFiltersVisibility = () => {
    this.setState({ filtersVisible: !this.state.filtersVisible });
  }

  toggleProjectModal = visible => () => {
    this.setState({ showProjectModal: visible });
  }

  updateParams = () => {
    const params = getAdvancedSearchBody({
      search: this.props.params,
      showRate: this.showRate(),
    });

    const encoded = utils.qs.base64Encode({ q: params });

    this.props.history.replace(`${consts.pathname.SEARCH}?${encoded}`);

    this.fetchAll();
  }

  updateResults = data => {
    this.setState({
      loading: false,
      loadingMore: false,
      results: data.results,
      total: data.total,
      fetchCount: data.fetchCount,
    }, () => this.fetching = false);
  }

  handlePriceChange = priceRange => {
    this.props.batchActions([
      actions.setPriceRange({
        low: priceRange.low,
        high: priceRange.high,
      }),
      actions.applyAdvancedFilters(),
    ]);
  }

  render = () => {
    if (!utils.hasClientRole(this.props.user)) {
      return <Redirect to={consts.pathname.HOME} />;
    }

    return (
      <Screen>

        <Sidebar>
          <AdvancedFilters
            search={this.props.params}
            handlePriceChange={this.handlePriceChange}
            handleItemAdded={this.props.addToAdvancedFilterList}
            handleItemRemoved={this.props.removeFromAdvancedFilterList}
            onClose={this.toggleFiltersVisibility}
            priceRange={{
              priceLow: this.props.params.priceLow,
              priceHigh: this.props.params.priceHigh,
            }}
            visible={this.state.filtersVisible}
            showRate={this.showRate()}
            onResetFilters={this.resetFilters}
            onSortChange={this.handleSortChange} />
        </Sidebar>

        <Main>
          <Header className={styles.generalHeader}>
            <GeneralSearchListHeader
              onAddToProject={this.toggleProjectModal(true)}
              onShowFilters={this.toggleFiltersVisibility}
              resultsCount={this.state.results.length}
              selectedCount={this.state.selected.length}
              totalCount={this.state.total}
              downloadCSV={this.props.user.roles.some(r => [enums.Role.InternalAdmin].includes(r)) ? this.props.fetchExport : undefined} />
          </Header>

          <Scroll className={styles.scroll}>
            {this.state.results.map((item, idx) =>
              <GeneralSearchResultItem
                checked={this.state.selected.includes(item.id)}
                key={item.id}
                item={item}
                position={idx + 1}
                onClickTag={this.addItemToSearchFilter}
                onClickCheckBox={this.markItemSelected(item)}>
                {/* <Button
                  color="primary"
                  onClick={this.props.requestCall(item)}>
                  Request Call
                </Button> */}
              </GeneralSearchResultItem>)}

            <LoadingMoreIndicator visible={this.state.loadingMore} />

            <GeneralSearchListFooter
              resultsCount={this.state.results.length}
              totalCount={this.state.total}
              visible={!this.state.loading} />

            <ActivityIndicator show={this.state.loading} />
          </Scroll>
        </Main>

        {/* this.state.showProjectModal &&
          <ModalAddToProject
            onClose={this.toggleProjectModal(null)}
            users={this.getSelectedItems()} /> */}

      </Screen>
    );
  };
}

const SearchWithContainer = props => {
  return (
    <RequestCallContainer>
      <SearchWrapper {...props} />
    </RequestCallContainer>
  );
};

export default connect(mapState)(SearchWithContainer);