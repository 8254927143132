import { useCallback, useContext, useEffect } from 'react';
import { useChainedCommands } from '@remirror/react';
import { TranscriptMediaPlayerContext, TranscriptTimestampPositionsContext } from '@containers/Transcript/context';
import type { KaraokeExtension } from '../Extension';

export const useMediaTimeChangeEvent = () => {
  const player = useContext(TranscriptMediaPlayerContext);
  const timestampPositions = useContext(TranscriptTimestampPositionsContext);
  const commands = useChainedCommands<KaraokeExtension>();

  const handleTimeUpdate = useCallback((e: Event) => {

    const currentTime = (e.target as HTMLAudioElement).currentTime;

    let closestTs: number = null;
    const timestamps = Array.from(timestampPositions.keys());
    for (let i = timestamps.length - 1; i >= 0; i--) {
      const ts = timestamps[i];

      if (ts <= currentTime) {
        closestTs = ts;
        break;
      }
    }

    if (closestTs) {

      const pos = timestampPositions.get(closestTs);

      commands.setKaraokePosition(pos).run();

    } else {
      commands.setKaraokePosition(null).run();
    }

  }, [
    commands,
    timestampPositions,
  ]);

  useEffect(() => {

    if (player) {

      player.addEventListener('timeupdate', handleTimeUpdate);

      return () => {
        player.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
  }, [
    handleTimeUpdate,
    player,
  ]);

};