import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as consts from '@consts';
import { MedicalOnboardingBackgroundContainer } from '@containers/MedicalOnboarding/MedicalOnboarding.Background.Container';
import { MedicalOnboardingCompletionContainer } from '@containers/MedicalOnboarding/MedicalOnboarding.Completion.Container';
import { MedicalOnboardingExpertiseContainer } from '@containers/MedicalOnboarding/MedicalOnboarding.Expertise.Container';
import { MedicalOnboardingScreensBackgroundContainer } from '@containers/MedicalOnboarding/MedicalOnboarding.Screens.Background.Container';
import { ProjectConsultantReferralSourcingContext, ProjectScreensContext } from '@containers/ProjectOnboarding/Context';
import * as Footer from '@containers/ProjectOnboarding/Footer';
import { useAssertProjectAudienceTypeLookup, useMatchProjectIdFromRoute } from '@containers/ProjectOnboarding/hooks';
import { useSelectUser } from '@containers/Store';
import { useFetchConsultantReferralSourcingState } from '@/components/ProjectInviteReferral/hooks';
import { CompletionMedicalEnabledStateContext } from './Context';
import { ProjectCompletionStepper } from './ProjectOnboarding.Completion.Stepper';
import { Step, useCompletedStepsSelector, useStepperConfig } from './Steps';
import { useSelectMedicalBackgroundSteps } from './Steps.Medical';
import { useProjectCompletionRouteValidator } from './hooks';

type Props = unknown;

export const ProjectCompletionContainer = (props: Props) => {
  const history = useHistory();
  const { projectId } = useMatchProjectIdFromRoute();
  const record = useSelector((state: Store.State) => state.pipeline.me[projectId]);
  const user = useSelectUser();

  const validator = useProjectCompletionRouteValidator();

  useEffect(() => {

    if (!record || !validator(projectId)) {
      history.replace(consts.pathname.Home);
    }

  }, [
    history,
    projectId,
    record,
    validator,
  ]);

  const sourcing = useFetchConsultantReferralSourcingState(projectId);
  const audience = useAssertProjectAudienceTypeLookup(projectId);
  const decorate = audience.payer || audience.practitioner;

  if (!record) return null;

  return (
    <MedicalOnboardingCompletionContainer userId={user.id}>
      <MedicalOnboardingBackgroundContainer userId={user.id}>
        <MedicalOnboardingExpertiseContainer userId={user.id}>
          {/* <MedicalOnboardingScreensBackgroundContainer> */}
          <ProjectConsultantReferralSourcingContext.Provider value={sourcing}>
            <Footer.PoweredBy.ScreenDecorator decorate={decorate}>
              <ProjectCompletionScreensContainer>
                <ProjectCompletionStepper />
              </ProjectCompletionScreensContainer>
            </Footer.PoweredBy.ScreenDecorator>
          </ProjectConsultantReferralSourcingContext.Provider>
          {/* </MedicalOnboardingScreensBackgroundContainer> */}
        </MedicalOnboardingExpertiseContainer>
      </MedicalOnboardingBackgroundContainer>
    </MedicalOnboardingCompletionContainer>
  );
};

ProjectCompletionContainer.displayName = 'ProjectOnboarding.Completion.Container';

type ProjectCompletionScreensContainerProps = {
  children: React.ReactNode;
};

const ProjectCompletionScreensContainer = (props: ProjectCompletionScreensContainerProps) => {
  const { projectId } = useMatchProjectIdFromRoute();

  const medicalsteps = useSelectMedicalBackgroundSteps();

  const selectSteps = useCompletedStepsSelector(steps => {
    return steps.filter(step => {
      return step === Step.MedicalContinuation
        ? medicalsteps.length
        : step;
    });
  });

  const state = useStepperConfig(projectId, selectSteps);

  const value = {
    screens: state.screens,
    steps: state.steps,
  };

  const medicalstepper = {
    enabled: !!medicalsteps.length,
    steps: medicalsteps,
  };

  return (
    <ProjectScreensContext.Provider value={value}>
      <CompletionMedicalEnabledStateContext.Provider value={medicalstepper}>
        {props.children}
      </CompletionMedicalEnabledStateContext.Provider>
    </ProjectScreensContext.Provider>
  );
};

ProjectCompletionScreensContainer.displayName = 'ProjectOnboarding.Completion.Screens.Container';