import extend from '@enums/extend';

export enum AccountInvoicingMethod {
  PreFunding        = 1,
  PreProject        = 2,
  EndOfMonth        = 3,
  ProjectCompletion = 4,
}

const map = new Map([
  [AccountInvoicingMethod.PreFunding,        { name: 'Pre-Funding'   }],
  [AccountInvoicingMethod.PreProject,        { name: 'Pre-Project'  }],
  [AccountInvoicingMethod.EndOfMonth,        { name: 'End of Month' }],
  [AccountInvoicingMethod.ProjectCompletion, { name: 'Project Completion' }],
]);

export default {
  AccountInvoicingMethod: extend(map),
};