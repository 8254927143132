import { LongTextResponseQuestion } from '@/types/survey';
import { SurveySettings } from './Settings';

type Props = {
  item: LongTextResponseQuestion.Question;
};

export const LongTextResponsePreview = ({ item }: Props) => {
  return (
    <SurveySettings>
      {item.settings.rationale.enabled && <>Ask for rationale</>}
      <>Require at least {item.settings.characterMinimum} characters</>
    </SurveySettings>
  );
};