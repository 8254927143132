import { memo } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { cx } from '@utils';
import styles from './style/Skeleton.css';

type Props = {
  className?: string;
  rows: number;
};

export const Transcript = memo((props: Props) => {
  return (
    <div className={cx(styles.transcript, props.className)}>
      {Array.from({ length: props.rows }).map((_, i) =>
        <Row key={i} />)}
    </div>
  );
});

Transcript.displayName = 'Transcript.Skeleton.Transcript';

const Row = memo((props: unknown) => {
  return (
    <div className={styles.row}>
      <Skeleton
        variant="text"
        sx={{ fontSize: '16px', lineHeight: '30px', width: '150px' }} />
      <Skeleton variant="text" sx={{ fontSize: '16px', lineHeight: '28px', width: '90%' }} />
      <Skeleton variant="text" sx={{ fontSize: '16px', lineHeight: '28px' }} />
      <Skeleton variant="text" sx={{ fontSize: '16px', lineHeight: '28px', width: '45%' }} />
    </div>
  );
});

Row.displayName = 'Transcript.Skeleton.Transcript.Row';