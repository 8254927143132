import { WebAuth } from 'auth0-js';

// eslint-disable-next-line no-underscore-dangle
let _client: WebAuth = null;

export function getClient() {
  if (!_client) {
    _client = new WebAuth({
      audience: process.env.AUTH0_AUDIENCE,
      clientID: process.env.AUTH0_CLIENT_ID,
      domain: process.env.AUTH0_DOMAIN,
      responseType: 'token id_token',
    });
  }
  return _client;
}