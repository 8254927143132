import { createElement, useCallback, useMemo, useState, MouseEvent } from 'react';
import { ComputedSerie } from '@nivo/line';
import { useRepeatChartRef } from '@containers/RepeatSurveyResponses/hooks/useRepeatSurveyResponsesContext';
import { CustomChartLayerProps, EnhancedPoint, EnhancedSerie } from '@/components/SurveyResponses.Repeat/interfaces';
import { distanceToLine } from '@utils';
import { Point, Line } from '@/types';
import { useTooltip } from '@/components/Tooltip/hooks/useTooltip';

type Props =
  Pick<CustomChartLayerProps,
    'points' |
    'series' |
    'onPointClick' |
    'onSerieClick' |
    'PointsTooltip' |
    'SeriesTooltip'
    >;

export const useChartEventHandlers = ({
  onPointClick,
  onSerieClick,
  points,
  series,
  PointsTooltip,
  SeriesTooltip,
}: Props) => {
  const [activeSeries, setActiveSeries] = useState<number[]>([]);
  const [activePoints, setActivePoints] = useState<number[]>([]);
  const { hideTooltip, showTooltipFromEvent } = useTooltip();
  const chartRef = useRepeatChartRef();

  const adjustNivoPoint = useCallback((point: Point): Point => {
    const chartRect = chartRef.current.getBoundingClientRect();
    const leftOffset = chartRect.left + 40;
    const topOffset = chartRect.top + 15;

    return {
      x: point.x + leftOffset,
      y: point.y + topOffset,
    };
  }, [
    chartRef,
  ]);

  const linePoints = useMemo(() => {
    return series.reduce((acc, serie) => {
      const values = serie.data.reduce((acc2, point, i) => {
        if (i !== 0) {
          const previousPoint = serie.data[i - 1];

          const line: Line = {
            s: previousPoint.position,
            e: point.position,
          };
          return [...acc2, line];
        }
        return acc2;
      }, [] as Line[]);
      return {
        ...acc,
        [serie.id]: values,
      };
    }, {} as { [serieId: string] : Line[]; });
  }, [
    series,
  ]);

  const computeActiveSeries = useCallback((point: Point) => {
    const tolerance = 10;
    const checkDistance = shouldCheckDistance(point);
    return series
      .reduce((acc, serie) => {
        const activelines = linePoints[serie.id]
          .map(m => ({
            s: adjustNivoPoint(m.s),
            e: adjustNivoPoint(m.e),
          }))
          .filter(checkDistance)
          .reduce((acc2, line) => {
            if (acc2.length) { return acc2; }
            return distanceToLine(point, line) <= tolerance
              ? [...acc2, serie]
              : acc2;
          }, [] as ComputedSerie[]);

        return [...acc, ...activelines];
      }, [] as ComputedSerie[]);
  }, [
    adjustNivoPoint,
    linePoints,
    series,
  ]);

  const handlePointMouseOver = useCallback((point: EnhancedPoint) => (e: MouseEvent<SVGCircleElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const activePoints = points.filter(f => f.x === point.x && f.y === point.y);

    setActiveSeries(activePoints.map(m => +m.serieId));
    setActivePoints(activePoints.map(m => +m.id));

    showTooltipFromEvent(createElement(PointsTooltip, { points: activePoints }), e);
  }, [
    points,
    setActivePoints,
    setActiveSeries,
    showTooltipFromEvent,
    PointsTooltip,
  ]);

  const handlePointMouseLeave = useCallback((e: MouseEvent<SVGCircleElement>) => {
    hideTooltip();
    setActiveSeries([]);
    setActivePoints([]);
  }, [
    hideTooltip,
    setActivePoints,
    setActiveSeries,
  ]);

  const handleSerieMouseOver = useCallback((serie: EnhancedSerie) => (e: MouseEvent<SVGPathElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const series = computeActiveSeries({
      x: e.clientX,
      y: e.clientY,
    });

    setActiveSeries(series.map(m => +m.id));
    if (series.length) {
      showTooltipFromEvent(createElement(SeriesTooltip, { series }), e);
    }
  }, [
    computeActiveSeries,
    showTooltipFromEvent,
    SeriesTooltip,
  ]);

  const handleSerieMouseLeave = useCallback((e: MouseEvent<SVGPathElement>) => {
    hideTooltip();
    setActiveSeries([]);
  }, [
    hideTooltip,
    setActiveSeries,
  ]);

  const handlePointClick = useCallback(() => {
    onPointClick(activePoints.map(id => points.find(f => +f.id === id)));
  }, [
    activePoints,
    points,
    onPointClick,
  ]);

  const handleSerieClick = useCallback(() => {
    onSerieClick(activeSeries.map(id => series.find(f => +f.id === id)));
  }, [
    activeSeries,
    series,
    onSerieClick,
  ]);

  return {
    activePoints,
    activeSeries,
    pointHandlers: {
      onClick: handlePointClick,
      onMouseEnter: handlePointMouseOver,
      onMouseLeave: handlePointMouseLeave,
      onMouseOver: handlePointMouseOver,
    },
    serieHandlers: {
      onClick: handleSerieClick,
      onMouseEnter: handleSerieMouseOver,
      onMouseLeave: handleSerieMouseLeave,
      onMouseMove: handleSerieMouseOver,
    },
  } as const;
};

const tolerance = 10;
const shouldCheckDistance = (point: Point) => (line: Line) => {
  return point.x >= line.s.x - tolerance &&
    point.x <= line.e.x + tolerance;
};

export default useChartEventHandlers;