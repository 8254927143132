import { useCallback, useEffect, useState, useMemo } from 'react';
import { Permission } from '@enums';
import { useUserPermissions } from '@containers/User';
import { useFetchBuildInfo } from '@utils/api';
import styles from './style.css';

export function BuildInfo() {
  const permissions = useUserPermissions();
  const [state, fetch] = useFetchBuildInfo();
  const [dismissed, setDismissed] = useState<boolean>(false);

  const visible = useMemo(() => permissions.includes(Permission.DeveloperBuildInfo), [permissions]);

  const handleDismiss = useCallback(() => {
    setDismissed(true);
  }, []);

  useEffect(() => {
    if (visible) {
      fetch();
    }
  }, [visible, fetch]);

  if (dismissed) return null;
  if (!visible) return null;
  if (!state.value) return null;

  return (
    <div
      className={styles.info}
      onClick={handleDismiss}>
      {state.value.web && (
        <span className={styles.hash}>
          <span className={styles.label}>UI:</span>{state.value.web}
        </span>
      )}
      {state.value.api && (
        <span className={styles.hash}>
          <span className={styles.label}>API:</span>{state.value.api}
        </span>
      )}
      <span className={styles[state.value.env]}>
        <span className={styles.label}>ENV:</span>{state.value.env}
      </span>
    </div>
  );
}