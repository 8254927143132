import { useMutation } from '@tanstack/react-query';
import * as $api from '@api';
import {
  ProjectHonorariaEditingContext,
  ProjectHonorariaMutationContext,
} from '@containers/Project.Honoraria/context';
import { useToggle } from '@utils';
import * as API from '$admin/api/interfaces';
import { useCanEditSurveySettings } from './hooks';

type Props =
  ChildrenProps &
  IProjectId;

export const ProjectHonorariaContainer = (props: Props) => {

  const [isEditing, toggleEditing] = useToggle(false);
  const editable = useCanEditSurveySettings();

  const mutation = useMutation([
    `put:project/billing/honoraria`,
    props.projectId,
  ], (params: Omit<API.Projects.UpdateHonoraria.Request, 'projectId'>) => {
    return $api.projects.billing.updateHonoraria({
      items: params.items,
      projectId: props.projectId,
    });
  });

  const editingCtx = {
    editable,
    editing: isEditing,
    toggle: toggleEditing,
  };

  return (
    <ProjectHonorariaMutationContext.Provider value={mutation}>
      <ProjectHonorariaEditingContext.Provider value={editingCtx}>
        {props.children}
      </ProjectHonorariaEditingContext.Provider>
    </ProjectHonorariaMutationContext.Provider>
  );
};