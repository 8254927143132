import type { SurveyQuestionType } from '@enums';
import type { MatrixSliderQuestion } from '@/types';
import { SurveyQuestionNumberType } from '@/types';
import { convertToSurveyRichText } from '@/containers/Survey/utils';
import type { Validation } from '../interfaces';
import { assertValidRows } from './validation.rows';
import { assertHasValue, returnAssertFailure } from './validation.base';
import { assertValidNumberSettings } from './validation.settings.number';

export const DefaultRows = 5;
export const MaxRows = 25;
export const MinRows = 1;
export const MaxSteps = 200;

export const DefaultSettings: MatrixSliderQuestion.Settings = {
  randomization: false,
  rationale: {
    enabled: false,
    minimum: 75,
  },
  slider: {
    displayPctOfTotal: false,
    ensureAnswerTotalEqualsMax: false,
    hideSlider: false,
    increment: null,
    label: convertToSurveyRichText(''),
    maxValue: null,
    minValue: null,
    total: {
      comparate: null,
      operator: '=',
    },
  },
  numberSettings: {
    type: SurveyQuestionNumberType.Number,
    currency: 'USD',
  },
  orderRowsBasedOnSource: false,
};

export const DefaultRowMetadata: MatrixSliderQuestion.RowMetadata = {
  canDelete: true,
  canModifyValue: true,
  isAnchored: false,
  isOpenEnded: false,
  template: {},
};

export function validateQuestion(question: MatrixSliderQuestion.Question): Validation.ValidateQuestionResult<SurveyQuestionType.Sliders> {
  const hasValue = assertHasValue(question);
  if (hasValue.success === false) {
    return returnAssertFailure(hasValue, question);
  }

  const hasValidRows = assertValidRows(question.matrixRows, MinRows, MaxRows);
  if (hasValidRows.success === false) {
    return returnAssertFailure(hasValidRows, question);
  }

  const hasValidSettings = assertValidMatrixSliderSettings(question.settings.slider);
  if (hasValidSettings.success === false) {
    return returnAssertFailure(hasValidSettings, question);
  }

  const hasValidNumberSettings = assertValidNumberSettings(question.settings);
  if (hasValidNumberSettings.success === false) {
    return returnAssertFailure(hasValidNumberSettings, question);
  }

  return {
    success: true,
    result: {
      question,
    },
  };
}

function assertValidMatrixSliderSettings(settings: MatrixSliderQuestion.SliderSettings): Validation.AssertionResult {
  if (incomplete()) {
    return {
      success: false,
      message: 'Incomplete slider settings.',
    };
  }

  const steps = (settings.maxValue - settings.minValue) / settings.increment;

  if (invalidIncrement()) {
    return {
      success: false,
      message: 'Invalid slider increment.',
    };
  }

  if (invalidSteps()) {
    return {
      success: false,
      message: 'Invalid slider steps.',
    };
  }

  if (equalValues()) {
    return {
      success: false,
      message: 'Slider minimum and maximum are equal.',
    };
  }

  return { success: true };

  function incomplete() {
    if (settings.hideSlider) return false;
    return !settings.increment ||
      settings.maxValue === null ||
      settings.minValue === null;
  }

  function invalidIncrement() {
    if (settings.hideSlider) return false;
    return settings.increment < 0.25;
  }

  function invalidSteps() {
    if (settings.hideSlider) return false;
    return !Number.isInteger(steps) ||
      Math.abs(steps) < 2 ||
      Math.abs(steps) > MaxSteps;
  }

  function equalValues() {
    return settings.minValue === settings.maxValue;
  }
}
