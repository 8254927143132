import type { SurveyLogic } from '@/types';
import { SurveyLogicRule } from '@enums';
import { Condition } from './Logic.Condition';
import { CustomExpression } from './Logic.CustomExpression';
import styles from './style/Logic.Item.css';

type Props = {
  conditions: SurveyLogic.ItemCondition[];
  rule: SurveyLogic.SurveyConditionsRule;
};

export const SurveyConditions = ({ conditions, rule }: Props) => {

  switch (rule.type) {
    case SurveyLogicRule.AllOfTheAbove:
      return (
        <>
          {conditions.map((condition, i) => (
            <span key={condition.identifier}>
              <Condition condition={condition} />
              {i !== conditions.length - 1 && <span className={styles.operator}>~AND~</span>}
            </span>
          ))}
        </>
      );

    case SurveyLogicRule.AnyOfTheAbove:
      return (
        <>
          {conditions.map((condition, i) => (
            <span key={condition.identifier}>
              <Condition condition={condition} />
              {i !== conditions.length - 1 && <span className={styles.operator}>~OR~</span>}
            </span>
          ))}
        </>
      );

    case SurveyLogicRule.CustomExpression:
      return (
        <CustomExpression
          conditions={conditions}
          expression={rule.expression} />
      );

    default:
      throw new UnreachableCaseError(rule.type);
  }
};