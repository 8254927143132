import { forwardRef, useCallback, useState } from 'react';
import { ButtonActivityIndicator, ButtonOutlined } from '@presentation';
import { Header } from '@/screens/Transcript/Tool.Header';
import { useToggle, cx } from '@/utils';
import { TextareaAutosize } from '@/components/Textarea';
import { Checkbox } from '@/components/Checkbox';
import styles from './style/TextReplace.Popper.css';

type Props = {
  loading: boolean;
  text: string;
  onCancel: () => void;
  onSubmit: (value: string, addToDict: boolean) => void;
};

export const TextReplaceExtensionPopper = forwardRef<HTMLDivElement, Props>(({
  onCancel,
  onSubmit,
  text,
  ...props
}, ref) => {
  const [value, setValue] = useState(text);
  const [addToDict, toggleAddToDict] = useToggle(false);

  const handleSubmit = useCallback(() => {
    onSubmit(value, addToDict);
  }, [addToDict, onSubmit, value]);

  return (
    <div
      className={styles.root}
      ref={ref}>
      <Header title='Replace' onClose={onCancel} />
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.row}>
            <div className={styles.label}>Selection:</div>
            <TextareaAutosize
              className={styles.input}
              contentEditable={false}
              readOnly
              value={text} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>Replace with:</div>
          <TextareaAutosize
            autoFocus
            className={styles.input}
            onChange={e => setValue(e.target.value)}
            value={value} />
        </div>
        {textIsValidForDict(text) &&
          <div className={cx(styles.row, styles.dictRow)}>
            <Checkbox
              checked={addToDict}
              onClick={toggleAddToDict}
              disabled={!textIsValidForDict(value)} />
            Add to Medical Dictionary
          </div>
        }
        <div className={styles.btns}>
          <ButtonOutlined
            className={styles.btn}
            color="silver"
            disabled={props.loading}
            onClick={onCancel}>
            Cancel
          </ButtonOutlined>
          <ButtonActivityIndicator
            className={styles.btn}
            color="primary"
            loading={props.loading}
            onClick={handleSubmit}>
            Save
          </ButtonActivityIndicator>
        </div>
      </div>
    </div>
  );
});

function textIsValidForDict(text: string) {
  return text.trim().split(' ').length === 1;
}

TextReplaceExtensionPopper.displayName = 'TextReplace.Extension.Popper';