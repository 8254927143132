import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useIsInternalUser } from '@/containers/Store/hooks';
import { useUpdateUserEmailSettings } from '@utils/api';
import Bucket from './Settings.Email.Bucket';

const selectSettings = (state: Store.State) => state.settings.email;

export function ComplianceBuckets() {
  const settings = useSelector(selectSettings);
  const { mutateAsync } = useUpdateUserEmailSettings();

  const isInternalUser = useIsInternalUser();

  const toggle = useCallback((field: string) => (value: boolean) => {
    return mutateAsync({
      [field]: value,
    });
  }, [mutateAsync]);

  return (
    <>
      {isInternalUser && (
        <Bucket
          active={settings.scheduling}
          name='Scheduling'
          description='Receive emails for pre-call scheduling correspondence'
          onClick={toggle('scheduling')} />
      )}
      <Bucket
        active={settings.callReminders}
        name='Call Reminders'
        description='Receive reminder emails for upcoming calls'
        onClick={toggle('callReminders')} />
      {isInternalUser && (
        <Bucket
          active={settings.callEvent}
          name='Call Event'
          description='Receive emails for call event confirmations and cancellations'
          onClick={toggle('callEvent')} />
      )}
      {isInternalUser && (
        <Bucket
          active={settings.newMessages}
          name='New Message'
          description='Receive emails when experts message you on the platform'
          onClick={toggle('newMessages')} />
      )}
      <Bucket
        active={settings.projectSummary}
        name='Project Summary'
        description={`This email is sent once weekly to recap activity on the projects you're working on`}
        onClick={toggle('projectSummary')} />
      <Bucket
        active={settings.complianceReviewProcess}
        name='Compliance Review Process'
        description='Receive compliance review emails for New Experts (call), New Surveys, and New Messages'
        onClick={toggle('complianceReviewProcess')} />
    </>
  );
}

export default ComplianceBuckets;