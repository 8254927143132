import { useHasClientRole } from '@containers/User';
import { Chip } from '@presentation/Chip';
import { cx } from '@utils';
import { MaybeSearchTag } from './MaybeSearchTag';
import { TagProps } from './interfaces';
import styles from './style.css';

const TagLegacy = ({
  className,
  editable = false,
  item,
  onClick,
}: TagProps) => {

  const hasClientRole = useHasClientRole();

  if (editable) {
    return (
      <Chip color='selected' className={cx(styles.override, className)}>
        <Chip.Body.Action variant='remove' onClick={onClick}>
          {item.name}
        </Chip.Body.Action>
      </Chip>
    );
  }

  if (onClick) {
    return (
      <Chip clickable className={cx(styles.override, className)} onClick={onClick}>
        <Chip.Body.Basic>
          {item.name}
        </Chip.Body.Basic>
      </Chip>
    );
  }

  return (
    <MaybeSearchTag
      className={cx(styles.override, className)}
      disabled={!hasClientRole}
      item={item}
      type={item.type} />
  );
};

export { MaybeSearchTag };
export default TagLegacy;