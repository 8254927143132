import styles from './style/Profile.Section.Multiple.css';

type Props = {
  message: string;
};

export const ProfileFormValidation = ({ message }: Props) => {

  return (
    <div className={styles.validation}>
      {message}
    </div>
  );
};

export default ProfileFormValidation;