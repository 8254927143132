import {
  SurveyConditionsMatchValidationsContext,
} from '@containers/SurveyBuilder.Logic/context';
import { QuotaValidationsContext, RunQuotaValidationsContext } from './Context';
import { useBasicQuotaValidations } from './hooks';

type Props = ChildrenProps;

export const BasicQuotaValidationsContainer = (props: Props) => {

  const {
    validations,
    matchValidation,
    runValidations,
  } = useBasicQuotaValidations();

  return (
    <QuotaValidationsContext.Provider value={validations}>
      <SurveyConditionsMatchValidationsContext.Provider value={matchValidation}>
        <RunQuotaValidationsContext.Provider value={runValidations}>
          {props.children}
        </RunQuotaValidationsContext.Provider>
      </SurveyConditionsMatchValidationsContext.Provider>
    </QuotaValidationsContext.Provider>
  );
};