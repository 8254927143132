import { useMemo } from 'react';
import type { MatrixMultiselectQuestion } from '@/types/survey';
import { RowContextMenu } from './Row.ContextMenu';
import { useAnchoredRowMenuItem, useIsLastRowInGridMenuItem, useOpenEndedRowMenuItem } from './hooks/row-menu';

type Props = {
  row: MatrixMultiselectQuestion.Row;
  question: MatrixMultiselectQuestion.Question;
};

export const MatrixMultiselectRowContextMenu = (props: Props) => {
  const openEndedMenuItem = useOpenEndedRowMenuItem({
    row: props.row,
    questionIdentifier: props.question.base.identifier,
  });

  const anchoredMenuItem = useAnchoredRowMenuItem({
    row: props.row,
    question: props.question,
  });

  const isLastRowInCurrentGridMenuItem = useIsLastRowInGridMenuItem({
    row: props.row,
    question: props.question,
  });

  const items = useMemo(() => {

    return [
      props.question.settings.randomizeRows && anchoredMenuItem,
      openEndedMenuItem,
      isLastRowInCurrentGridMenuItem,
    ].filter(Boolean);
  }, [
    anchoredMenuItem,
    isLastRowInCurrentGridMenuItem,
    openEndedMenuItem,
    props.question.settings.randomizeRows,
  ]);

  return (
    <RowContextMenu
      row={props.row}
      items={items}
      excludeFindAndReplace={false} />
  );
};