import { useCallback } from 'react';
import {
  MessageTesting,
  SurveyTemplateQuestion,
  SurveyTemplateType,
  SurveyTemplateEntityType,
} from '@enums/survey.template';
import {
  SurveyTemplate,
  SurveyBuilderVersion,
  SurveyLogic,
} from '@/types/survey';
import { SurveyBuilder } from '@containers/SurveyBuilder/interfaces';
import * as $templateUtils from '@containers/SurveyBuilder/state/state.template.utils';
import * as $template from '@containers/SurveyBuilder/template/utils';
import { parseSurveyRichText } from '@containers/SurveyBuilder/utils/rich-text.parse';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';

export const useSubmitMedicalConditionsMT = () => {
  const [state, dispatch] = useSurveyBuilderState();

  return useCallback((value: SurveyTemplate.MessageTesting.Conditions) => {

    const actions = computeActions({
      saved: state.survey,
      value,
    });
    // console.log('actions', actions);

    dispatch({
      actions,
      type:'batch-actions',
    });
  }, [
    dispatch,
    state.survey,
  ]);
};

type ComputeActions = {
  saved: SurveyBuilderVersion;
  value: SurveyTemplate.MessageTesting.Conditions;
};

function computeActions({ saved, value }: ComputeActions): SurveyBuilder.NonBatchActions[] {

  const actions: SurveyBuilder.NonBatchActions[] = [];

  actions.push({
    type: 'template-mt-conditions-updated',
    payload: { value },
  });

  const question = saved.questions.find(f => f.metadata.template.key === MessageTesting.Question.PatientConditions);

  if (!question) return actions;

  const oldOther = (saved.template.data as SurveyTemplate.MessageTesting.Data).medicalConditions.other;

  const {
    added: addedOther,
    removed: removedOther,
    updated: updatedOther,
  } = $templateUtils.computeChangedValues({
    oldValues: oldOther,
    values: value.other,
  });

  const rowQuestions: SurveyTemplateQuestion[] = [
    MessageTesting.Question.PatientConditions,
  ];

  const removedRows = $templateUtils.computeRemovedRows({
    key: SurveyTemplateEntityType.MedicalCondition,
    questions: saved.questions,
    removedItems: removedOther,
  });

  const addedRows = $templateUtils.computeAddedRows({
    generateRow: $template.generateMTMedicalConditionRow,
    addedItems: addedOther,
    questions: saved.questions,
    toCheck: rowQuestions,
  });

  const updatedRows = $templateUtils.computeUpdatedRows({
    questions: saved.questions,
    toCheck: rowQuestions,
    updatedItems: [...updatedOther, value.primary],
  });

  removedRows.forEach(f => {
    actions.push({
      type: 'remove-question-row',
      ...f,
    });
  });
  addedRows.forEach(f => {
    actions.push({
      type: 'add-question-rows',
      ...f,
    });
  });
  updatedRows.forEach(f => {
    actions.push({
      type: 'update-question-row-value',
      ...f,
    });
  });

  return actions;
}