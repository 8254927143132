import { useCallback, useMemo, useRef } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import type { SurveyAlternateImageExercise } from '@/types';
import { useSurveyBuilderState } from '@containers/SurveyBuilder';
import { useSurveyAlternateImagesBuilderItemContext } from '@containers/SurveyBuilder.AIE';
import { Input } from '@/components/Input';
import type { ContextMenuItemProps } from '@/components/SurveyBuilder/ContextMenu';
import { SurveyBuilderContextMenu } from '@/components/SurveyBuilder/ContextMenu';
import { DraggableEntry } from './Editor.DraggableEntry';
import { AddEntry } from './Editor.Entry.Add';
import { useAddImages } from './hooks';
import { buildEntryIdentifier, buildGroupIdentifier } from './utils';
import styles from './style/Group.css';

type Group = SurveyAlternateImageExercise['groups'][0];

type Props = {
  group: Group;
};

export const Group = (props: Props) => {
  const [, dispatch] = useSurveyBuilderState();
  const item = useSurveyAlternateImagesBuilderItemContext();

  const imageInputRef = useRef<HTMLInputElement>(null);
  const addImages = useAddImages({ exerciseIdentifier: item.identifier, groupIdentifier: props.group.identifier });

  const onNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'aie-group-name-updated',
      exerciseIdentifier: item.identifier,
      groupIdentifier: props.group.identifier,
      name: e.target.value,
    });
  }, [dispatch, item.identifier, props.group.identifier]);

  const updateGroupSettings = useCallback((settings: Partial<Group['settings']>) => {
    dispatch({
      type: 'aie-group-settings-updated',
      exerciseIdentifier: item.identifier,
      groupIdentifier: props.group.identifier,
      settings,
    });
  }, [dispatch, item.identifier, props.group.identifier]);

  const menuItems = useMemo<ContextMenuItemProps[]>(() => [
    {
      children: 'Upload Image',
      onClick: () => {
        imageInputRef.current?.click();
      },
    },
    {
      children: 'Randomize Images',
      checked: props.group.settings.randomize,
      onClick: () => {
        updateGroupSettings({ randomize: !props.group.settings.randomize });
      },
    }, item.settings.randomize ? {
      children: 'Anchored',
      checked: props.group.settings.anchor,
      onClick: () => {
        updateGroupSettings({ anchor: !props.group.settings.anchor });
      },
    } : null, {
      children: 'Delete',
      onClick: () => {
        dispatch({
          type: 'aie-group-removed',
          groupIdentifier: props.group.identifier,
          exerciseIdentifier: item.identifier,
        });
      },
    },
  ].filter(Boolean), [updateGroupSettings, props.group.settings, props.group.identifier, dispatch, item.identifier, item.settings.randomize]);

  const sortedItems = useMemo(() => props.group.entries.map(e => buildEntryIdentifier(e)), [props.group.entries]);

  return (
    <Droppable droppableId={buildGroupIdentifier(props.group)} direction={'horizontal'}>
      {(provided, snapshot) => (
        <div
          className={styles.root}
          ref={provided.innerRef}
          {...provided.droppableProps}>
          <div className={styles.nameRow}>
            <div>Concept</div>
            <Input
              classes={{ root: styles.nameInput }}
              value={props.group.name}
              onChange={onNameChange} />
          </div>
          <div className={styles.entries}>
            {props.group.entries.map(e => (
              <DraggableEntry
                key={e.identifier}
                group={props.group}
                entry={e} />
            ))}
            {provided.placeholder}
            {!props.group.entries.length && <AddEntry group={props.group} />}
          </div>
          <SurveyBuilderContextMenu className={styles.contextMenu} items={menuItems} />
          <input
            ref={imageInputRef}
            type='file'
            accept='image/*'
            multiple={true}
            onChange={e => addImages(e.target.files)}
            hidden={true} />
        </div>
      )}
    </Droppable>
  );
};