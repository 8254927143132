import type { ChangeEvent } from 'react';
import { useCallback, useContext, useState } from 'react';
import { X } from 'react-feather';
import { ProjectClipsFiltersContext, SetProjectClipsFiltersContext } from '@/containers/Project.Clips';
import { useDebounceCallback } from '@/utils';
import { Search } from '@/components/icons';
import styles from './style/KeywordSearch.css';

export const KeywordSearch = () => {

  const filters = useContext(ProjectClipsFiltersContext);
  const setFilters = useContext(SetProjectClipsFiltersContext);
  const [value, setValue] = useState(filters.keyword);

  const updateFilters = useCallback((keyword: string) => {
    setFilters({
      keyword,
      speakerRole: keyword ? filters.speakerRole : 'all',
    });
  }, [filters.speakerRole, setFilters]);

  const clearFilter = useCallback(() => {
    setValue('');
    setFilters({
      keyword: '',
    });
  }, [setFilters]);

  const debouncedUpdateFilters = useDebounceCallback(updateFilters, 300);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    debouncedUpdateFilters(e.target.value);
  }, [debouncedUpdateFilters]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.left}>
          <Search
            className={styles.icon}
            size={22} />
          <input
            className={styles.input}
            autoComplete="off"
            name="highlights-search"
            placeholder="Search transcript text"
            type="text"
            onChange={handleChange}
            value={value} />
        </div>
        {value && (
          <X
            className={styles.x}
            onClick={clearFilter}
            size={22} />
        )}
      </div>
    </div>
  );
};