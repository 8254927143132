import { useCallback } from 'react';
import { indexBy } from '@utils/array';
import { useSurveyAlternateImagesBuilderItemContext } from '@containers/SurveyBuilder.AIE/context';
import { useUploadImageObjectsMutation } from '@/components/Survey.RichText';
import { useSurveyBuilderState } from '../../SurveyBuilder/hooks';

export const useUploadImages = () => {
  const [, dispatch] = useSurveyBuilderState();
  const item = useSurveyAlternateImagesBuilderItemContext();
  const { mutateAsync: upload } = useUploadImageObjectsMutation();
  return useCallback(async () => {
    const toUpload = item.groups.flatMap(g => g.entries).filter(e => e.imageUrl.startsWith('blob:'));

    if (!toUpload.length) {
      return;
    }

    const entryGroupIndex = indexBy(item.groups.flatMap(g => g.entries.map(e => ({ groupId: g.identifier, entryId: e.identifier }))), k => k.entryId, v => v.groupId);

    const images = await upload({
      images: toUpload.map(f => ({
        cid: f.identifier,
        src: f.imageUrl,
      })),
    });

    for (const i of images) {
      dispatch({
        type: 'aie-entry-updated',
        entryIdentifier: i.cid,
        groupIdentifier: entryGroupIndex[i.cid],
        exerciseIdentifier: item.identifier,
        entry: {
          imageUrl: i.src,
        },
      });
    }
  }, [item.groups, upload, dispatch, item.identifier]);
};