import { Buffer } from 'buffer/';
import querystring, { StringifyOptions } from 'query-string';

export const append = (search: string, params: { [key: string]: string }) => {
  return '?' + querystring.stringify({
    ...querystring.parse(search),
    ...params,
  });
};

type Encoding = 'base64';

export const encode = <T>(data: T, encoding: Encoding = 'base64') => {
  switch (encoding) {
    case 'base64': return Buffer.from(JSON.stringify(data)).toString('base64');
    default: return data;
  }
};

/**
 * @description
 *    * When `data` has **exactly one property** the property value
 *      will be base64 encoded using the property key as the param name.
 *    * When `data` has **more than one property** it will be base64
 *      encoded using the param name `q`.
 */
export const base64Encode = <T extends ObjectLike = ObjectLike, K extends keyof T = keyof T>(data: T) => {
  const keys = Object.keys(data);

  if (keys.length > 1) {
    return stringify({ q: encode(data) });
  }

  const [name] = keys;

  return stringify({ [name as K]: encode(data[name]) });
};

export const base64Decode = <T extends ObjectLike = ObjectLike>(str = location.search) => {
  try {
    const query: ObjectLike<string, string> = parse(str);
    const keys = Object.keys(query);

    const [name] = keys.length > 1
        ? ['q']
        : keys;

    return JSON.parse(Buffer.from(query?.[name] || '', 'base64').toString()) as T;
  } catch (e) {
    return null;
  }
};

export const from = (path: string, search?: string | null) => {
  return '?' + querystring.stringify({
    from: `${path}${search ? search : ''}`,
  });
};

export const getParam = (param: string) => {
  return new URLSearchParams(window.location.search).get(param);
};

export const parse = <T>(query: string, options?: Parameters<typeof querystring.parse>[1]) => {
  return querystring.parse(query, options) as unknown as T;
};

export const setParams = <T extends { [key: string]: string }>(path: string, params: T) => {
  return `${path}?${querystring.stringify(params)}`;
};

export const stringify = <T extends Record<string, any>>(data: T, options?: StringifyOptions) => {
  return querystring.stringify(data, options);
};