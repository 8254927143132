import { memo } from 'react';

type Props = {
  className?: string;
  color?: string;
};

export const InsightsChatIcon = memo(({ className, color = 'var(--black-l)' }: Props) => {

  return (
    <svg
      className={className}
      width="25px"
      height="24px"
      viewBox="0 0 25 24"
      version="1.1">
      <g
        id="Chat"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g
          id="9"
          style={{ transition: 'stroke 0.3s linear' }}
          transform="translate(-697.000000, -10.000000)"
          stroke={color}>
          <g id="Group-3" transform="translate(697.000000, 10.000000)">
            <path
              d="M16,1 C18.209139,1 20.209139,1.8954305 21.6568542,3.34314575 C23.1045695,4.790861 24,6.790861 24,9 L24,9 L24,21 L16.8173877,17 L9,17 C6.790861,17 4.790861,16.1045695 3.34314575,14.6568542 C1.8954305,13.209139 1,11.209139 1,9 C1,6.790861 1.8954305,4.790861 3.34314575,3.34314575 C4.790861,1.8954305 6.790861,1 9,1 L9,1 Z"
              id="Rectangle"
              strokeWidth="2" />
            <path
              d="M12.5,4.75 C13.2593915,4.75 13.9468915,5.05780423 14.4445436,5.55545635 C14.9421958,6.05310847 15.25,6.74060847 15.25,7.5 C15.25,8.25939153 14.9421958,8.94689153 14.4445436,9.44454365 C14.2758502,9.61323705 13.8909496,10.0591703 13.2921647,10.7845815 L13.2921647,10.7845815 L11.7078353,10.7845815 C11.1090504,10.0591703 10.7241498,9.61323705 10.5554564,9.44454365 C10.0578042,8.94689153 9.75,8.25939153 9.75,7.5 C9.75,6.74060847 10.0578042,6.05310847 10.5554564,5.55545635 C11.0531085,5.05780423 11.7406085,4.75 12.5,4.75 Z"
              id="Oval"
              strokeWidth="1.5" />
            <line
              x1="12"
              y1="13.2961554"
              x2="13.111994"
              y2="13.2961554"
              id="Path-7"
              strokeWidth="2"
              strokeLinecap="round" />
          </g>
        </g>
      </g>
    </svg>
  );
});