import { Dispatch, useCallback, useContext, useRef, useState } from 'react';
import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { Edit2 } from 'react-feather';
import CheckCircle from '@mui/icons-material/CheckCircleOutlineOutlined';
import RemoveCircle from '@mui/icons-material/HighlightOffOutlined';
import * as API from '@api/interfaces';
import * as api from '@api';
import { useSelectGroup } from '@containers/Store';
import { createNamedContext, cx } from '@utils';
import { Input } from '@/components/Input';
import { GroupContactRecordContext } from './Context';
import * as Layout from './Layout';
import styles from './style/Section.Contact.css';

type Props = unknown;

export const Contact = (props: Props) => {
  const ctx = useContext(GroupContactRecordContext);
  const [editing, setEditing] = useState<Id>(null);

  if (!ctx.query.data) return null;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Layout.Header>
          <Layout.Title>Contact</Layout.Title>
        </Layout.Header>
        <div className={styles.main}>
          <EditContext.Provider value={[editing, setEditing]}>
            <Field
              id="firstName"
              label="First Name"
              required>
              {ctx.query.data.contact.firstName}
            </Field>
            <Field
              id="lastName"
              label="Last Name"
              required>
              {ctx.query.data.contact.lastName}
            </Field>
            <Field
              id="profile"
              label="Expert Profile"
              readonly>
              {null}
            </Field>
            <Field
              id="email"
              label="Email"
              readonly>
              {ctx.query.data.contact.email}
            </Field>
            <Field
              id="phone"
              label="Phone">
              {ctx.query.data.contact.phone}
            </Field>
            <Field
              id="title"
              label="Title">
              {ctx.query.data.contact.title}
            </Field>
            <Field
              id="company"
              label="Company">
              {ctx.query.data.contact.company}
            </Field>
          </EditContext.Provider>
        </div>
      </div>
    </div>
  );
};

Contact.displayName = 'Group.Contacts.Record.Section.Contact';

type FieldProps = {
  children:  React.ReactNode;
  id:        Id;
  label:     string;
  readonly?: boolean;
  required?: boolean;
};

const Field = (props: FieldProps) => {
  const [editing, setEditing] = useContext(EditContext);

  const children = !props.readonly && editing === props.id
      ? <InputField {...props} />
      : <DisplayField {...props} />;

  return (
    <div className={styles.item}>
      <div className={styles.label}>{props.label}</div>
      {children}
    </div>
  );
};

const DisplayField = (props: FieldProps) => {
  const [editing, setEditing] = useContext(EditContext);
  const isText = typeof props.children === 'string';

  const classes = {
    btn: cx(styles.btn, {
      [styles.editable]: !props.readonly && !editing,
    }),
    value: cx(styles.value, {
      [styles.full]: !!props.children,
      [styles.trunc]: isText,
    }),
  };

  const title = isText
      ? props.children as string
      : null;

  return (
    <div className={styles.contents}>
      <div
        className={classes.value}
        title={title}>
        {props.children}
      </div>
      <div className={styles.icon}>
        <div
          className={classes.btn}
          onClick={() => setEditing(props.id)}>
          <Edit2 size={20} />
        </div>
      </div>
    </div>
  );
};

const InputField = (props: FieldProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const [editing, toggleEdit] = useContext(EditContext);
  const [disabled, setDisabled] = useState(false);

  const mutation = useContactRecordMutation({
    onSuccess: () => toggleEdit(null),
  });

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const empty = e.target.value.trim().length < 1;

    if (!disabled && props.required && empty) {
      setDisabled(true);
    } else if (disabled && !empty) {
      setDisabled(false);
    }

  }, [
    disabled,
    props.required,
  ]);

  const handleSubmit = useCallback(() => {
    const value = ref.current.value;

    mutation.mutate({
      [props.id]: value.trim(),
    });

  }, [
    mutation,
    props.id,
  ]);

  return (
    <div className={styles.contents}>
      <Input
        classes={{
          input: styles.input,
          root: styles.inputroot,
        }}
        defaultValue={props.children as string}
        onChange={handleChange}
        ref={ref} />
      <div className={styles.icon}>
        <button
          className={styles.save}
          disabled={disabled}
          onClick={handleSubmit}>
          <CheckCircle />
        </button>
      </div>
      <div className={styles.icon}>
        <button
          className={styles.cancel}
          disabled={mutation.isLoading}
          onClick={() => toggleEdit(null)}>
          <RemoveCircle />
        </button>
      </div>
    </div>
  );
};

Field.displayName = 'Group.Contacts.Record.Section.Contact.Field';

const EditContext = createNamedContext<EditContextValue>(undefined, 'Edit.Context');

type EditContextValue = [
  Id,
  Dispatch<Id>,
];

type Id =
  | 'firstName'
  | 'lastName'
  | 'profile'
  | 'email'
  | 'phone'
  | 'title'
  | 'company';

declare namespace Mutation {
  export type Variables =
    Omit<API.Groups.Contacts.UpdateRecord.Request,
    | 'contactId'
    | 'groupId'>;

  export type Data = API.Groups.Contacts.UpdateRecord.Response;

  export type Options =
    UseMutationOptions<
      Data,
      unknown,
      Variables>;
}

const useContactRecordMutation = (options: Mutation.Options = {}) => {
  const ctx = useContext(GroupContactRecordContext);
  const group = useSelectGroup();

  const qc = useQueryClient();

  const mutation = useMutation<Mutation.Data, unknown, Mutation.Variables>([
    `patch:groups/contacts/records`,
    group?.id,
    ctx.query.data?.id,
  ], data => {
    return api.groups.contacts.updateRecord({
      contactId: ctx.query.data.id,
      groupId: group.id,
      ...data,
    });
  }, {
    ...options,
    onSuccess: (res, variables, context) => {
      qc.setQueryData<API.Groups.Contacts.FetchRecord.Response>([
        `get:groups/contacts/records`,
        group.id,
        ctx.query.data.id,
      ], data => ({
        ...data,
        contact: {
          ...data.contact,
          ...variables,
        },
      }));

      options?.onSuccess(res, variables, context);
    },
  });

  return mutation;
};