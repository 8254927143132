import { useMemo } from 'react';
import { useQuestionBuilderItem, RichTextListBuilderContext } from './Context';
import { useAddRow, useRemoveRow, useUpdateRowRichValue, useUpdateRowValue } from './hooks';

type Props = {
  className?: string;
  maxRows: number;
  minRows: number;
} & ChildrenProps;

export default function QuestionRowsBuilderContainer(props: Props) {
  const question = useQuestionBuilderItem();

  const addRow = useAddRow(question.base.identifier);
  const removeRow = useRemoveRow(question.base.identifier);
  const updateRowRichValue = useUpdateRowRichValue(question.base.identifier);
  const updateRowValue = useUpdateRowValue(question.base.identifier);

  const canAddRow = useMemo(() => {
    return question.matrixRows.length < props.maxRows;
  }, [question.matrixRows.length, props.maxRows]);

  const canRemoveRow = useMemo(() => {
    return question.matrixRows.length > props.minRows;
  }, [question.matrixRows.length, props.minRows]);

  const ctx = {
    permissions: {
      canAddItem: canAddRow,
      canRemoveItem: canRemoveRow,
    },
    items: question.matrixRows,
    addItem: addRow,
    removeItem: removeRow,
    updateItemValue: updateRowRichValue,
    updateItemPlainValue: updateRowValue,
  };

  return (
    <RichTextListBuilderContext.Provider value={ctx}>
      {props.children}
    </RichTextListBuilderContext.Provider>
  );
}

export { QuestionRowsBuilderContainer };