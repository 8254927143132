import { memo } from 'react';
import { cx } from '@utils';
import styles from './style/Divider.css';

type Props = {
  className?: string;
};

export const PopperMenuDivider = memo((props: Props) => {

  const className = cx(styles.divider, props.className);

  return (
    <div className={className} />
  );
});