import { useCallback, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProjectStatus, ProjectType } from '@enums';
import { useDebounceValue } from '@utils';
import { Autocomplete as IAutocomplete } from '$admin/api/interfaces/projects';
import * as api from '$admin/api';
import AutoComplete from '@/components/AutoComplete';

type Item = IAutocomplete.Response[number];

type Props = {
  excludeIds: number[];
  onSelect: (item: Item) => unknown;
  projectType?: ProjectType[];
  statusId?: ProjectStatus[];
};

export const Projects = ({ excludeIds, onSelect, projectType, statusId }: Props) => {
  const [text, setText] = useState('');
  const [items, setItems] = useState<Item[]>([]);
  const name = useDebounceValue(text, 150);

  useQuery(['get:admin/projects/autocomplete', excludeIds, name, projectType, statusId], async () => {
    const result = await api.projects.autocomplete({
      name,
      projectType,
      isSourcing: true,
      statusId,
    });

    setItems(result.filter(r => !excludeIds.includes(r.id)).slice(0, 5));

    return result;
  }, {
    enabled: !!name.length,
    refetchOnWindowFocus: false,
    cacheTime: 5000,
  });

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length) {
      setText(value);
    } else {
      setItems([]);
      setText('');
    }
  }, []);

  const handleSelect = useCallback((item: Item) => {
    onSelect(item);
    setText('');
  }, [onSelect]);

  return (
    <AutoComplete
      getItemValue={(item: Item) => {
        const code = item.code ? ` - ${item.code}` : '';
        const meta = `(${item.id})${code}`;
        return `${item.name} ${meta}`;
      }}
      items={items}
      name="projects"
      onChange={handleChange}
      onSelect={handleSelect}
      placeholder="Search for a project"
      value={text} />
  );
};

Projects.displayName = 'Autocomplete.Projects';