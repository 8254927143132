import { matchPath, useLocation } from 'react-router-dom';
import { Admin, Calls } from '@consts/path';
import { getLocationFor } from '@utils';

export const useCallRecordLocation = (callId: number) => {
  const loc = useLocation();

  const admin = matchPath(loc.pathname, {
    path: Admin.Root,
  });

  const details = admin && !matchPath(loc.pathname, {
    path: Admin.Call,
    exact: true,
  });

  const main = !admin && !matchPath(loc.pathname, {
    path: Calls.Call,
    exact: true,
  });

  return details
    ? getLocationFor.admin.call.details({ callId })
    : main
      ? getLocationFor.call.root({ callId })
      : null;
};