import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as consts from '@consts';
import { Back } from '@presentation/Back';

const mapState = (state: Store.State) => ({
  routing: state.routing.routes,
});

type Props = {
  className?: string;
  fallback?: string;
};

const BackButton = (props: Props) => {
  const { routing } = useSelector(mapState);
  const history = useHistory();

  function Fallback() {
    const to = props.fallback ? props.fallback : consts.pathname.HOME;
    return (
      <Link to={to}>
        <Back />
      </Link>
    );
  }

  function GoBack() {
    return (
      <a onClick={() => history.goBack()}>
        <Back />
      </a>
    );
  }

  return (
    <div className={props.className}>
      {routing.length > 1
        ? <GoBack />
        : <Fallback />}
    </div>
  );
};

export { BackButton };
export default BackButton;