import { useContext, useMemo } from 'react';
import * as Topic from '@containers/Topic';
import { TopicItemContext, TopicPostsContext } from '@containers/Topic/Context';
import { useInfiniteWindowScroll } from '@utils';
import ActivityIndicator from '@/components/ActivityIndicator';
import { StartPost } from '@/components/ContentFeed/StartPost';
import { Card } from './Layout';
import { PostsFeed as PostsPanel } from './Panel';
import styles from './style/Related.css';

type Props = unknown;

export const Posts = (props: Props) => {
  const { query: overview } = useContext(TopicItemContext);
  const { query } = useContext(TopicPostsContext);

  useInfiniteWindowScroll({
    canFetch: query.hasNextPage && !query.isFetchingNextPage,
    fetchNext: query.fetchNextPage,
    bufferSize: 0.3,
  });

  const ready = useMemo(() => {
    return !overview.data.meta.hasPosts
        || !query.isLoading && query.isSuccess;
  }, [
    overview.data.meta.hasPosts,
    query.isLoading,
    query.isSuccess,
  ]);

  const items = useMemo(() => {
    return overview.data.meta.hasPosts
      ? query.data?.pages.flatMap(p => p.items)
      : [];
  }, [
    query.data?.pages,
    overview.data.meta.hasPosts,
  ]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Card className={styles.create}>
          <Topic.Posts.PostCreationContainer>
            <StartPost />
          </Topic.Posts.PostCreationContainer>
        </Card>
        {!ready
            ? <ActivityIndicator show />
            : overview.data.meta.hasPosts
              ? <PostsPanel items={items} />
              : <div className={styles.empty}>{copy.empty}</div>}
      </div>
    </div>
  );
};

Posts.displayName = 'Tab.Posts';

const copy = {
  empty: `Post a comment to start the conversation!`,
};