import { useCallback, useMemo } from 'react';
import { Point } from '@nivo/line';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import { useRepeatQuestionResponsesData } from '@containers/RepeatSurveyResponses/hooks/useRepeatQuestionResponsesData';
import { SurveyQuestionType } from '@enums';
import { PointsTooltip } from '@presentation/ProjectSurveyResponses';
import { roundToPlace } from '@utils';
import { PointsTooltipProps } from './interfaces';

type Props =
  PointsTooltipProps;

export default function MaxDiffChatPointTooltip({ points: rawPoints }: Props) {
  const { question } = useRepeatQuestionResponsesData<SurveyQuestionType.MaxDifference>();

  const getPointLabel = useCallback((point: Point) => {
    const option = question.options.find(f => f.base.id === +point.serieId);
    return parseSurveyRichText(option.value);
  }, [question.options]);

  const pointsValueText = useMemo(() => {
    const score = roundToPlace(+rawPoints[0].data.y, 2);
    return `${score} Score`;
  }, [
    rawPoints,
  ]);

  return (
    <PointsTooltip
      points={rawPoints}
      getPointLabel={getPointLabel}
      pointsValueText={pointsValueText} />
  );
}

export { MaxDiffChatPointTooltip };