import { useContext, useMemo } from 'react';
import { MonologueDetailsEditSpeakerContext, TranscriptDocumentContext } from '@containers/Transcript/context';
import { useGetSpeakerLabel } from '@containers/Transcript/hooks';
import type { TranscribeRT } from '@/types';

type Props = {
  children: React.ReactNode;
};

export const MonologueDetailsEditSpeakerContainer = (props: Props) => {
  const ctx = useContext(TranscriptDocumentContext);

  const keys = useMemo(() => {
    const spks = (ctx?.document?.content || [])
      .filter(x => x.type === 'md')
      .map((x: TranscribeRT.MonologueDetails.Node) => x.attrs?.spk);

    const unique = Array.from(new Set(spks));

    return [...unique].sort((a, b) => a - b);
  }, [ctx?.document]);

  const getSpeakerLabel = useGetSpeakerLabel();

  const options = keys.map(idx => ({
    id: idx,
    name: getSpeakerLabel(idx),
  }));

  const value = {
    keys,
    options,
  };

  return (
    <MonologueDetailsEditSpeakerContext.Provider value={value}>
      {props.children}
    </MonologueDetailsEditSpeakerContext.Provider>
  );
};

MonologueDetailsEditSpeakerContainer.displayName = 'MonologueDetails.EditSpeaker.Container';