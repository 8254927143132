import { useMemo } from 'react';
import type { SurveyQuestionType } from '@/enums';
import {
  useSurveyFormQuestionContext,
  useValidateRationale,
} from './hooks';
import { SurveyFormCanSubmitContext, useSurveyFormQuestionAnswer } from './Context';
import { SurveyFormBackContainer, SurveyFormSubmitContainer } from './BaseNavigation';

type Props =
  ChildrenProps;

export const DropdownFormContainer = (props: Props) => {

  const [answer] = useSurveyFormQuestionAnswer<SurveyQuestionType.Dropdown>();
  const question = useSurveyFormQuestionContext<SurveyQuestionType.Dropdown>();
  const validateRationale = useValidateRationale();

  const canSubmit = useMemo(() => {

    const hasSelection = assertHasSelection();

    if (!hasSelection) return false;

    const hasMissingFreeText = assertHasMissingFreeText();

    if (!hasMissingFreeText) return false;

    const isRationaleValid = validateRationale();

    if (!isRationaleValid) return false;

    return true;

    function assertHasSelection() {
      return !!answer?.optionId;
    }

    function assertHasMissingFreeText() {
      const selectedOption = question.options.find(f => f.id === answer.optionId);

      if (selectedOption.metadata.isOpenEnded) {
        const isMissingText = !answer.text;

        if (isMissingText) return false;
      }

      return true;
    }

  }, [answer, question.options, validateRationale]);

  return (
    <SurveyFormCanSubmitContext.Provider value={canSubmit}>
      <SurveyFormBackContainer>
        <SurveyFormSubmitContainer>
          {props.children}
        </SurveyFormSubmitContainer>
      </SurveyFormBackContainer>
    </SurveyFormCanSubmitContext.Provider>
  );
};

export default DropdownFormContainer;