import http from '@services/http';

export const updateProjectScreeningQuestions = data => {
  const { projectId, ...rest } = data;
  return http.patch(`/projects/${projectId}/screening-questions`, rest);
};

export const updateProjectSupplementalData = data => {
  const { projectId, ...rest } = data;
  return http.post(`/projects/${projectId}/supplemental-data`, rest);
};