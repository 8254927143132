import { Fragment } from 'react';
import type { TableCellItem } from './interfaces';

type Props = TableCellItem;

export const Cell = (props: Props) => {
  return (
    <Fragment>
      {props.row.original.current?.company || '-'}
    </Fragment>
  );
};

Cell.displayName = 'Column.CurrentInstitution.Cell';