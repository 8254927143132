import { useCallback, useContext, useEffect } from 'react';
import cuid from 'cuid';
import { EditorComponent, useEditorFocus, useEditorState } from '@remirror/react';
import { TranscriptParentCommentIdentifiersContext, TranscriptCommentReplyVisibilityContext, TranscriptCommentReplyActionsVisibilityContext } from '@containers/Transcript.Commenting/Context';
import { useTranscriptCommentFocus } from '@containers/Transcript.Commenting/hooks';
import { cx } from '@utils';
import { MentionsAutocomplete } from '@/components/Remirror';
import { useIsEditorEmpty } from '@/components/Remirror/hooks';
import { CommentActions, CommentCancelButton as CancelButton, CommentSaveButton as SaveButton } from './Comment.Actions';
import { useCanSaveComment, useCommentEditor, useResetCommentEditor, useSaveCommentReply } from './hooks';
import { CommentEditor } from './Comment.Editor';
import styles from './style/Comment.Reply.css';

type Props = {
  className?: string;
};

const ContainerWithEditorState = (props: ChildrenProps) => {

  const editorIsEmpty = useIsEditorEmpty();

  const { parentComment } = useContext(TranscriptParentCommentIdentifiersContext);
  const [isCommentFocused] = useTranscriptCommentFocus(parentComment.identifier);
  const [isOpen, setIsOpen] = useContext(TranscriptCommentReplyVisibilityContext);
  const [actionsVisible, setActionsVisible] = useContext(TranscriptCommentReplyActionsVisibilityContext);

  const [editorFocused] = useEditorFocus({
    blurOnInactive: true,
  });

  useEffect(() => {
    if (!isCommentFocused && editorIsEmpty) {
      setIsOpen(false);
      setActionsVisible(false);
    }
  }, [
    editorIsEmpty,
    isCommentFocused,
    setActionsVisible,
    setIsOpen,
  ]);

  useEffect(() => {
    if (editorFocused) {
      setActionsVisible(true);
    }
  }, [
    editorFocused,
    setActionsVisible,
  ]);

  return (
    <>
      {props.children}
    </>
  );
};

const ReplyToComment = ({ className }: Props) => {

  const { highlight, parentComment } = useContext(TranscriptParentCommentIdentifiersContext);

  const editor = useEditorState();
  const [actionsVisible, setActionsVisible] = useContext(TranscriptCommentReplyActionsVisibilityContext);

  const resetState = useResetCommentEditor();

  const {
    isLoading: isSaving,
    mutateAsync: saveReply,
  } = useSaveCommentReply({
    onSuccess: resetState,
  });

  const canSave = useCanSaveComment();

  const handleCancel = useCallback(() => {
    resetState();
    setActionsVisible(false);
  }, [resetState, setActionsVisible]);

  const handlePost = useCallback(() => {

    return saveReply({
      comment: {
        highlightId: highlight.id,
        identifier: cuid(),
        value: editor.doc,
        parentCommentId: parentComment.id,
      },
    });

  }, [
    highlight.id,
    parentComment.id,
    editor,
    saveReply,
  ]);

  return (
    <div className={cx(styles.root, className)}>
      <EditorComponent />
      <MentionsAutocomplete />

      {actionsVisible &&
        <CommentActions>
          <CancelButton
            onClick={handleCancel} />
          <SaveButton
            disabled={!canSave}
            implicitDisable={isSaving}
            loading={isSaving}
            onClick={handlePost}>
            Post
          </SaveButton>
        </CommentActions>
      }
    </div>
  );
};

const WithState = (props: Props) => {

  const { manager } = useCommentEditor({
    placeholder: 'Reply or add others with @',
  });

  const [isOpen] = useContext(TranscriptCommentReplyVisibilityContext);

  if (!isOpen) return null;

  return (
    <CommentEditor
      editable={true}
      manager={manager}>
      <ContainerWithEditorState>
        <ReplyToComment {...props} />
      </ContainerWithEditorState>
    </CommentEditor>
  );
};

export { WithState as ReplyToComment };