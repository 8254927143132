export const custom = [{
  id: `video-and-audio-downloads`,
  name: `Video and audio downloads`,
}, {
  id: `csv-spss-import-export`,
  name: `CSV & SPSS import / export`,
}, {
  id: `highlights-tags-charts`,
  name: `Highlights/tags/charts`,
}, {
  id: `notes`,
  name: `Notes`,
}, {
  id: `full-text-search-w-filters`,
  name: `Full text search w/ filters`,
}, {
  id: `comments-and-sharing`,
  name: `Comments and sharing`,
}, {
  id: `cross-project-tags`,
  name: `Cross-project tags`,
}, {
  id: `project-templates`,
  name: `Project templates`,
}, {
  id: `user-settings`,
  name: `User settings`,
}];

export const support = [{
  id: `incentive-management`,
  name: `Incentive Management`,
}, {
  id: `participant-management`,
  name: `Participant Management`,
}, {
  id: `email-support`,
  name: `Email support`,
}, {
  id: `recruiting-support`,
  name: `Recruiting support`,
}, {
  id: `privacy-support-response`,
  name: `Privacy support response`,
}, {
  id: `service-level-agreement`,
  name: `Service-level agreement`,
}];

export const tiered = [{
  id: `viewer-seats`,
  name: `Viewer Seats`,
}, {
  id: `video-image-files`,
  name: `Video/Image/Files`,
}, {
  id: `projects`,
  name: `Projects`,
}, {
  id: `tracked-research-participants`,
  name: `Tracked Research Participants`,
}];

export const tools = [{
  id: `encrypted-data-and-backups`,
  name: `Encrypted data and backups`,
}, {
  id: `data-processing-privacy`,
  name: `Data processing/privacy`,
}, {
  id: `custom-compliance-settings`,
  name: `Custom compliance settings`,
}, {
  id: `flexible-payment-options`,
  name: `Flexible payment options`,
}];

export const usage = [{
  id: `included-seats`,
  name: `Included Seats`,
}, {
  id: `additional-seats`,
  name: `Additional Seats`,
}];