export const crop = (src: string) => {
  return new Promise<ImageResource>((resolve, reject) => {

    const timeout = setTimeout(() => {
      console.log('Timeout running');
      resolve(null);
    }, 7000);

    const image = new Image();
    image.crossOrigin = 'anonymous';

    image.onload = () => {
      clearTimeout(timeout);

      const width = image.naturalWidth;
      const height = image.naturalHeight;

      const small = width < 600 || height < 360;
      const ratio = small
          ? 1
          : 1.91;

      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;

      const aspectratio = width / height;

      if (aspectratio > ratio) {
        canvas.width = height * ratio;
        canvas.height = height;
      } else if (aspectratio < ratio) {
        canvas.width = width;
        canvas.height = width / ratio;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      const x = (canvas.width - width) * 0.5;
      const y = (canvas.height - height) * 0.5;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(image, x, y);

      const type = `image/png`;
      const dataurl = canvas.toDataURL(type);

      return resolve({
        original: {
          url: src,
          width,
          height,
        },
        format: `png`,
        height: canvas.height,
        url: dataurl,
        width: canvas.width,
        type,
      });
    };

    image.src = src;
  });
};

type PreparedImage = {
  height: number;
  url:    string;
  width:  number;
};

export type ImageResource = {
  original: PreparedImage;
  format:   string;
  type:     string;
} & PreparedImage;