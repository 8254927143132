import extend from '@enums/extend';

export enum ProjectAction {
  Activate = 'activate',
  Archive = 'archive',
  ChangeOwner = 'change-owner',
  Clone = 'clone',
  CreateCall = 'create-call',
  DownloadCallReport = 'download-call-report',
  DownloadHighlightsReport = 'download-highlights-report',
  DownloadInsightsReport = 'download-insights-report',
  DownloadSurveyReport = 'download-survey-report',
  DownloadReport = 'download-report',
  DownloadResponseGrid = 'download-response-grid',
  DownloadTagSummariesReport = 'download-tag-summaries-report',
  EditSurvey = 'edit-survey',
  Move = 'move',
  ManageAccess = 'manage-access',
  ManageSurveySettings = 'manage-survey-settings',
  MonitorSurvey = 'monitor-survey',
  ManageDisplayrDashboard = 'manage-displayr-dashboard',
  Preview = 'preview',
  Share = 'share',
  UploadQuantAnalysisFile = 'updaload-quant-file',
}

const projectActions = new Map(
  [
    [ProjectAction.Activate, { name: 'Activate' }],
    [ProjectAction.Archive, { name: 'Archive' }],
    [ProjectAction.ChangeOwner, { name: 'Change Owner' }],
    [ProjectAction.Clone, { name: 'Clone' }],
    [ProjectAction.CreateCall, { name: 'Create Call' }],
    [ProjectAction.DownloadCallReport, { name: 'Download Call Report' }],
    [ProjectAction.DownloadHighlightsReport, { name: 'Download Highlights Report' }],
    [ProjectAction.DownloadInsightsReport, { name: 'Download Insights Report' }],
    [ProjectAction.DownloadSurveyReport, { name: 'Download Survey Report' }],
    [ProjectAction.DownloadReport, { name: 'Download Report' }],
    [ProjectAction.DownloadResponseGrid, { name: 'Download Response Grid' }],
    [ProjectAction.DownloadTagSummariesReport, { name: 'Download Tag Summaries Report' }],
    [ProjectAction.EditSurvey, { name: 'Edit Survey' }],
    [ProjectAction.Move, { name: 'Move' }],
    [ProjectAction.Share, { name: 'Share' }],
    [ProjectAction.ManageAccess, { name: 'Manage Access' }],
    [ProjectAction.ManageSurveySettings, { name: 'Manage Survey Settings' }],
    [ProjectAction.MonitorSurvey, { name: 'Monitor Survey' }],
    [ProjectAction.ManageDisplayrDashboard, { name: 'Manage Displayr Dashboard' }],
    [ProjectAction.Preview, { name: 'Preview' }],
    [ProjectAction.UploadQuantAnalysisFile, { name: 'Upload Quant Analysis File' }],
  ],
);

export default {
  ProjectAction: extend(projectActions),
};