import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MedicalExperienceStepper } from '@containers/ProjectOnboarding.MedicalExperience/MedicalExperience.Stepper';
import * as enums from '@enums';
import { MarketResearchPrivacyPolicy } from '@screens/ProjectOnboarding/MarketResearchPrivacyPolicy';
import { ProjectDisqualification } from '@screens/ProjectDisqualification';
import { AccountProfile, Compliance, ExternalSurvey, ProjectRequest, Scheduling } from '@screens/ProjectOnboarding';
import { ComplianceSurveyStepper } from './ComplianceSurveyStepper';
import { ProjectSurveyStepper } from './ProjectSurveyStepper';
import { StepCheck, StepperScreensState } from './interfaces';
import * as utils from './utils';

export enum Step {
  AccountProfileSetup = 'AccountProfile.Stepper',
  ComplianceSurvey    = 'ComplianceSurvey.Stepper',
  Disqualification    = 'Project.Disqualification',
  ExternalSurvey      = 'ExternalSurvey',
  MarketPrivacyPolicy = 'MarketResearch.PrivacyPolicy',
  MedicalExperience   = 'MedicalExperience.Stepper',
  ProjectSurvey       = 'ProjectSurvey.Stepper',
  Review              = 'Project.RequestReview',
  Scheduling          = 'Scheduling.InvitationCalendar',
  SchedulingTutorial  = 'Scheduling.Tutorial',
  Signature           = 'Compliance.EmbeddedSign',
  SurveyTerms         = 'Compliance.SurveyTerms',
}

export const StepScreens = {
  [Step.AccountProfileSetup]: AccountProfile.Stepper,
  [Step.ComplianceSurvey]: ComplianceSurveyStepper,
  [Step.Disqualification]: ProjectDisqualification,
  [Step.ExternalSurvey]: ExternalSurvey,
  [Step.MarketPrivacyPolicy]: MarketResearchPrivacyPolicy,
  [Step.MedicalExperience]: MedicalExperienceStepper,
  [Step.ProjectSurvey]: ProjectSurveyStepper,
  [Step.Review]: ProjectRequest.Review,
  [Step.SchedulingTutorial]: Scheduling.Tutorial,
  [Step.Scheduling]: Scheduling.InvitationCalendar,
  [Step.Signature]: Compliance.EmbeddedSign,
  [Step.SurveyTerms]: Compliance.SurveyTerms,
};

const StepChecks: StepCheck<typeof Step> = {
  /* [Step.AccountProfileSetup]: utils.isAccountProfileSetupRequired, */
  [Step.ComplianceSurvey]: utils.isComplianceSurveyRequired,
  [Step.ExternalSurvey]: utils.isExternalSurveyRequired,
  [Step.ProjectSurvey]: utils.isProjectSurveyRequired,
  [Step.Signature]: utils.hasPendingSignatureRequest,
  [Step.SurveyTerms]: utils.needsSurveyTerms,
};

export const StepsMap = {
  [enums.ProjectType.Call]: [
    Step.Review,
    Step.MedicalExperience,
    Step.MarketPrivacyPolicy,
    Step.ComplianceSurvey,
    Step.Signature,
    Step.SchedulingTutorial,
    Step.Scheduling,
  ],
  [enums.ProjectType.Survey]: [
    Step.Review,
    Step.SurveyTerms,
    Step.MedicalExperience,
    Step.MarketPrivacyPolicy,
    Step.ComplianceSurvey,
    Step.Signature,
    Step.ProjectSurvey,
    Step.ExternalSurvey,
  ],
  [enums.ProjectType.SurveyCall]: [
    Step.Review,
    Step.MedicalExperience,
    Step.MarketPrivacyPolicy,
    Step.ComplianceSurvey,
    Step.Signature,
    Step.ProjectSurvey,
    Step.ExternalSurvey,
    Step.SchedulingTutorial,
    Step.Scheduling,
  ],
};

const selectProjectOnboardingSteps = (state: Store.State) => (projectId: number): Step[]  => {
  const project = state.projects[projectId];

  return StepsMap[project.projectType].filter(step => {
    const validator = StepChecks[step];

    return validator
      ? validator(projectId, state)
      : true;
  });
};

export const useOnboardingStepsSelector = (filter?: StepsFilter) => {
  const getSteps = useSelector(selectProjectOnboardingSteps);

  const selectSteps = (projectId: number) => {
    const steps = getSteps(projectId);

    return typeof filter === 'function'
      ? filter(steps)
      : steps;
  };

  return useCallback(selectSteps, [
    filter,
    getSteps,
  ]);
};

const options = {
  enabled: true,
};

export const useStepperConfig = (
  projectId: number,
  selector: UseStepperConfig.Selector,
  { enabled = options.enabled }: UseStepperConfig.Options = options,
) => {
  const [state, dispatch] = useState<StepperScreensState>({
    screens: [],
    steps: [],
  });

  const initialize = useCallback(() => {
    const steps = selector(projectId);
    const screens = steps.map(step => StepScreens[step]);

    dispatch({
      screens,
      steps,
    });
  }, [
    dispatch,
    projectId,
    selector,
  ]);

  useEffect(() => {

    if (enabled && !state.steps.length) {
      initialize();
    }

  }, [
    enabled,
    initialize,
    state.steps,
  ]);

  return state;

};

type StepsFilter = (steps: Step[]) => Step[];

declare namespace UseStepperConfig {
  export type Selector = (projectId: number) => Step[];

  export type Options = {
    enabled?: boolean;
  };
}