import { useConferenceParticipants } from '@utils/api';
import { CallParticipantsQueryContext } from './context';

type Props =
  ChildrenProps &
  ICallId;

export const CallParticipantsContainer = ({ callId, children }: Props) => {

  const participants = useConferenceParticipants({
    callId,
  }, {
    enabled: !!callId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  return (
    <CallParticipantsQueryContext.Provider value={participants}>
      {children}
    </CallParticipantsQueryContext.Provider>
  );
};