import styles from './style/TabTitle.css';

type Props = unknown;

export const TagRowsGraphic = (props: Props) => {
  return (
    <div className={styles.graphic}>
      <TagRow color="#f6c0cd" />
      <TagRow color="#b0e1f6" />
      <TagRow color="#e4f2d0" />
    </div>
  );
};

TagRowsGraphic.displayName = 'TagRowsGraphic';

type TagRowProps = {
  color?: string;
};

const TagRow = (props: TagRowProps) => {
  return (
    <div className={styles.row}>
      <div className={styles.bullet}>
        <div
          className={styles.circ}
          style={{ backgroundColor: props.color || 'var(--sec-01)' }} />
      </div>
      <div className={styles.line}>
        <div className={styles.skel} />
      </div>
    </div>
  );
};

TagRow.displayName = 'Project.Tags.Popover.TagRow';