import { useMemo } from 'react';
import { format } from 'date-fns';
import { ProjectType } from '@/enums';
import { WorkspaceExpertFilesSurvey } from '@/types';
import { ProjectIcon } from '@/components/icons';
import ClickContainer from './ClickContainer';
import styles from './style.css';

type Props = {
  item: WorkspaceExpertFilesSurvey;
}

export default function Survey({ item }: Props) {
  const time = useMemo(() => format(item.timestamp, 'M/d/yyyy'), [item.timestamp]);

  return (
    <ClickContainer className={styles.file} item={item}>
      <ProjectIcon
        className={styles.icon}
        projectType={ProjectType.Survey} />
      <div className={styles.details}>
        <div className={styles.name}>{item.name}</div>
        <div className={styles.type}><span className={styles.time}>{time}</span></div>
      </div>
    </ClickContainer>
  );
}

export { Survey };