import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Card } from '@screens/Main.Member/Card';
import { Column, Scaffold } from '@screens/Main.Member/Layout';
import { useCreateViewFeedPostMutation } from '@utils/api';
import { AuthSignupGuard } from '$website/components/AuthSignupGuard';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import { Feed } from '@/components/ContentFeed';
import { ProfilePreview } from '@/components/UserProfile';
import { usePost } from './usePost';

type Props = unknown;

export const PostScreen = (prop: Props) => {
  return (
    <AuthSignupGuard>
      <Helmet title="Post" />
      <StateDependent />
    </AuthSignupGuard>
  );
};

const StateDependent = () => {
  const { mutate: createViewEvent } = useCreateViewFeedPostMutation();
  const query = usePost();

  useEffect(() => {
    if (query.data?.id) {
      createViewEvent(query.data.id);
    }
  }, [
    createViewEvent,
    query.data?.id,
  ]);

  if (query.isLoading) return <ActivityIndicator show />;

  return (
    <Scaffold>
      <Column>
        <Card>
          <ProfilePreview user={query.data.author} />
        </Card>
      </Column>

      <Column>
        <Card>
          <Feed.Post item={query.data} />
          {query.data.comments
            && <Feed.SupplementalActions item={query.data} />}
        </Card>
      </Column>

      <Column />
    </Scaffold>
  );
};