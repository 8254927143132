import { useMemo } from 'react';
import { Star } from 'react-feather';
import { cx } from '@utils';
import type { TaggedMoment } from '@/types/transcribe.rich-text';
import { assertIsGoodQuoteTag, computeTranscriptColor } from './utils';
import styles from './style/TagBadge.css';

type Props = {
  className?: string;
  tag: Pick<TaggedMoment, 'color' | 'name' | 'typeId'>;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'style'>;

export const TagBadge = ({ tag, className, ...rest }: Props) => {

  const isGoodQuoteTag = useMemo(() => {
    return assertIsGoodQuoteTag({
      name: tag.name,
      typeId: tag.typeId,
    });
  }, [tag.typeId, tag.name]);

  return (
    <div
      className={cx(styles.tag, rest.onClick ? styles.clickable : null, className)}
      style={{
        backgroundColor: computeTranscriptColor({ color: tag.color, opacity: 10 }),
        borderColor: computeTranscriptColor({ color: tag.color, opacity: 40 }),
      }}
      {...rest}>
      {isGoodQuoteTag && <Star className={styles.star} />}
      {tag.name}
    </div>
  );
};