import { useCallback, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import * as enums from '@enums';
import { cx } from '@utils';
import { Label } from '@/components/MedicalProfile/Label';
import * as Select from '@/components/MedicalProfile/Select';
import styles from './style/Autosuggest.css';

type Props = {
  classes?: {
    root?:   string;
    field?:  string;
    label?:  string;
    name?:   string;
    select?: string;
  };
  group:        SpecialtyField;
  name?:        string;
  onChange:     (value: Specialty) => unknown;
  readonly?:    boolean;
  showAll?:     boolean;
  subspecialty: SpecialtyField;
};

export const Specialty = ({ classes = {}, onChange, ...props }: Props) => {
  const group = props.group.value;
  const subspecialty = props.subspecialty.value;

  const [groupKeyword, setGroupKeyword] = useState('');
  const [subspecialtyKeyword, setSubspecialtyKeyword] = useState('');

  const groups = useQuery([
    `get:search/specialties`, {
      type: enums.VICS.Industry,
      keyword: groupKeyword,
      showAll: props.showAll,
    },
  ], () => {
    return api.search.fetchSpecialties({
      type: enums.VICS.Industry,
      keyword: groupKeyword,
      showAll: props.showAll,
    });
  });

  const subspecialties = useQuery([
    `get:search/specialties`, {
      type: enums.VICS.SubIndustry,
      keyword: subspecialtyKeyword,
      parentId: group?.id,
      showAll: props.showAll,
    },
  ], () => {
    return api.search.fetchSpecialties({
      type: enums.VICS.SubIndustry,
      keyword: subspecialtyKeyword,
      parentId: group?.id,
      showAll: props.showAll,
    });
  }, {
    enabled: !!group?.id,
  });

  const handleChangeGroup = useCallback((item: Descriptor) => {
    onChange({
      group: item,
      subspecialty: initial.subspecialty,
    });
  }, [onChange]);

  const handleChangeSubspecialty = useCallback((item: Descriptor) => {
    onChange({
      group,
      subspecialty: item,
    });
  }, [
    group,
    onChange,
  ]);

  const handleReset = useCallback((field: 'group' | 'subspecialty') => {
    const state = field === 'group'
      ? initial
      : { group, subspecialty: initial.subspecialty };

    onChange(state);
  }, [
    group,
    onChange,
  ]);

  return (
    <div className={cx(styles.specialty, classes.root)}>
      <div className={cx(styles.field, classes.field)}>
        <Label
          classes={{
            root: classes.label,
            text: classes.name,
          }}
          required={props.group.required}>
          {props.group.label}
        </Label>
        <Select.Clearable
          classes={{ root: classes.select }}
          disabled={props.group.disabled}
          inputValue={groupKeyword}
          name={props.name ? `${props.name}-group` : `group`}
          onChange={x => handleChangeGroup(x)}
          onInputChange={x => setGroupKeyword(x)}
          onReset={() => handleReset('group')}
          options={groups.data?.items ?? []}
          placeholder={props.readonly ? 'Not Set' : 'Select a specialty group'}
          readonly={props.readonly}
          value={group} />
      </div>
      <div className={cx(styles.field, classes.field)}>
        <Label
          classes={{
            root: classes.label,
            text: classes.name,
          }}
          required={props.subspecialty.required}>
          {props.subspecialty.label}
        </Label>
        <Select.Clearable
          classes={{ root: classes.select }}
          disabled={props.subspecialty.disabled}
          inputValue={subspecialtyKeyword}
          name={props.name ? `${props.name}-subspecialty` : `subspecialty`}
          onChange={x => handleChangeSubspecialty(x)}
          onInputChange={x => setSubspecialtyKeyword(x)}
          onReset={() => handleReset('subspecialty')}
          options={subspecialties.data?.items ?? []}
          placeholder={props.readonly ? 'Not Set' : 'Select a specialty'}
          readonly={props.readonly}
          value={subspecialty} />
      </div>
    </div>
  );
};

Specialty.displayName = 'MedicalProfile.Field.Specialty';

type Specialty = {
  group:        Descriptor;
  subspecialty: Descriptor;
};

const initial = {
  group: {} as Descriptor,
  subspecialty: {} as Descriptor,
};

type SpecialtyField = {
  disabled?: boolean;
  label:     string;
  required?: boolean;
  value:     Descriptor;
};