import { useCallback, useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Edit2, Slash } from 'react-feather';
import Popper from '@mui/material/Popper';
import { ClickAwayListener } from '@mui/material';
import { TermsContainer, TermFilterContext, ProjectTermsContext, TranscriptReplacementsContext } from '@containers/Group.Project.Terms';
import type { ProjectTerm } from '@containers/Group.Project.Terms/interfaces';
import { getSpellCheckSuggestions } from '@api/projects.transcripts';
import { FancyInput } from '@/components/Input';
import * as consts from '@consts';
import { Checkbox } from '@/components/Checkbox';
import { usePopperState, PopperMenu, PopperMenuItem, useZIndexModifier } from '@/components/Popper';
import type { UseModalProps } from '@/components/Modal';
import { Modal, useModal, Header } from '@/components/Modal';
import { useAlert } from '@/components/Modal/Alert';
import { Button } from '@/components/Button';
import styles from './styles/CorrectionHover.css';

type Props = {
  term: ProjectTerm;
};

export const CorrectionHover = (props: Props) => {
  const { addConfirmedTerms, addGlobalReplacements, projectId } = useContext(TranscriptReplacementsContext);
  const zIndexModifier = useZIndexModifier({ zIndex: consts.ZIndex.Popper });
  const popperState = usePopperState({ placement: 'bottom-start', modifiers: [zIndexModifier] });
  const [replacementState, setReplacementState] = useState<string>('');

  const onIgnore = useCallback(() => {
    addConfirmedTerms([{ term: props.term.termValue, transcriptIds: props.term.transcriptIds }]);
    popperState.setOpen(false);
  }, [addConfirmedTerms, popperState, props.term.termValue, props.term.transcriptIds]);

  const suggestionsQuery = useQuery({
    queryKey: ['get-spell-check-suggestions', props.term.termValue, projectId],
    queryFn: () => {
      return getSpellCheckSuggestions({ projectId, term: props.term.termValue });
    },
    enabled: popperState.open,
    placeholderData: { suggestions: [] },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const [toggleEditModal, EditModal] = useModal(EditTermModal);
  const [toggleAlert, ReplaceAlert, alertOpen] = useAlert();

  const triggerReplaceConfirmation = useCallback((replacement: string) => {
    setReplacementState(replacement);
    popperState.setOpen(false);
    toggleAlert();
  }, [popperState, toggleAlert]);

  const onReplace = useCallback((replacement: string) => {
    addGlobalReplacements([{ originalText: props.term.termValue, replacementText: replacement, transcriptIds: props.term.transcriptIds, sourceEntities: props.term.entities }]);
    popperState.setOpen(false);
    setReplacementState('');
    if (alertOpen) {
      toggleAlert();
    }
  }, [addGlobalReplacements, alertOpen, popperState, props.term.entities, props.term.termValue, props.term.transcriptIds, toggleAlert]);

  const onEditText = useCallback(() => {
    toggleEditModal();
    popperState.setOpen(false);
  }, [popperState, toggleEditModal]);

  return (
    <>
      <ClickAwayListener onClickAway={() => popperState.setOpen(false)}>
        <div>
          <div
            className={styles.hoverContainer}
            ref={popperState.setReferenceElement}>
            <Edit2
              className={styles.icon}
              size={16}
              onClick={() => popperState.setOpen(true)} />
            {popperState.open && (
              <Popper
                open={popperState.open}
                ref={popperState.setPopperElement}
                style={popperState.styles.popper}
                anchorEl={popperState.referenceElement}
                placement={'bottom-start'}
                {...popperState.attributes.popper}>
                <PopperMenu>
                  {suggestionsQuery.data.suggestions.length || suggestionsQuery.isLoading ? suggestionsQuery.data.suggestions.map(s => (
                    <PopperMenuItem key={s} onClick={() => triggerReplaceConfirmation(s)}>
                      {`"${s}"`}
                    </PopperMenuItem>
                  ))
                    : <PopperMenuItem disabled={true}>No suggestions available</PopperMenuItem>
                  }
                  <PopperMenuItem className={styles.menuItem} onClick={onIgnore}>
                    <Slash size={16} /> Ignore
                  </PopperMenuItem>
                  <PopperMenuItem className={styles.menuItem} onClick={onEditText}>
                    <Edit2 size={16} /> Edit Text
                  </PopperMenuItem>
                </PopperMenu>
              </Popper>
            )}
          </div>
          <EditModal term={props.term} />
          <ReplaceAlert
            className={styles.alert}
            message={<AlertConfirmationContent replacementText={replacementState} term={props.term} />}
            confirmText='Replace All'
            onConfirm={() => onReplace(replacementState)} />
        </div>
      </ClickAwayListener>
    </>
  );
};

const EditTermModal = ({ onClose, open, ...props }: Props & UseModalProps) => {
  const [state, setState] = useState(props.term.termValue);
  const [addToDict, toggleAddToDict] = useState(false);
  const { addGlobalReplacements, addDictionaryWord } = useContext(TranscriptReplacementsContext);

  const onChange = useCallback(() => {
    addGlobalReplacements([{ originalText: props.term.termValue, replacementText: state, transcriptIds: props.term.transcriptIds, sourceEntities: props.term.entities }]);

    if (addToDict) {
      addDictionaryWord(state);
    }
    onClose();
  }, [addDictionaryWord, addGlobalReplacements, addToDict, onClose, props.term.entities, props.term.termValue, props.term.transcriptIds, state]);

  useEffect(() => {
    if (!isValidDictionaryTerm(state)) {
      toggleAddToDict(false);
    }
  }, [state]);

  const updateText = props.term.transcriptIds?.length ?
    `This will update ${props.term.occurrences} references across ${props.term.transcriptIds.length} transcripts.` :
    `This will update all references across all the project transcripts.`;

  return (
    <Modal open={open} onClose={onClose}>
      <Header>Update all instances of this term</Header>
      <div className={styles.row}>
        <div className={styles.label}>Selection:</div>
        <FancyInput value={props.term.termValue} disabled />
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Replace with:</div>
        <FancyInput value={state} onChange={e => setState(e.target.value)} />
      </div>
      {isValidDictionaryTerm(props.term.termValue) &&
        <label className={styles.dictRow}>
          <Checkbox
            checked={addToDict}
            onClick={() => toggleAddToDict(!addToDict)}
            disabled={!isValidDictionaryTerm(state)} />
          <div>
            Add to medical dictionary
          </div>
        </label>
      }
      <div className={styles.updateText}>{updateText}</div>
      <div className={styles.buttons}>
        <Button
          variant='brick'
          color='destructive'
          onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant='brick'
          disabled={state === props.term.termValue}
          onClick={onChange}>
          Change
        </Button>
      </div>
    </Modal>
  );
};

type AlertConfirmationProps = {
  term: ProjectTerm;
  replacementText: string;
};
const AlertConfirmationContent = ({ term, replacementText }: AlertConfirmationProps) => {
  return (
    <div>
      <Header>Replace all Terms?</Header>
      <div className={styles.confirmationLine}>Would you like to change <span className={styles.bold}>{term.termValue}</span> to <span className={styles.bold}>{replacementText}</span> across all transcripts?</div>
      {term.transcriptIds?.length && <div>This will update {term.occurrences} references across {term.transcriptIds.length} transcripts.</div>}
    </div>
  );
};

function isValidDictionaryTerm(text: string) {
  return !text.includes(' ');
}