import { cx } from '@utils';
import styles from './style/Terms.css';

type Props = {
  as?:      'div' | 'p' | 'span' | 'h1';
  bold?:    boolean;
  children: React.ReactNode;
  upcase?:  boolean;
};

export const Span = ({
  as: AsProp = 'span',
  bold = false,
  upcase = false,
  ...props
}: Props) => {
  const classes = cx({
    [styles.bold]: bold,
    [styles.upcase]: upcase,
  });

  return (
    <AsProp className={classes}>
      {props.children}
    </AsProp>
  );
};

export const Bold = (props: Omit<Props, 'bold'>) => {
  return (
    <Span
      as={props.as}
      bold
      upcase={props.upcase}>
      {props.children}
    </Span>
  );
};