import { Fragment, memo } from 'react';
import { format } from 'date-fns';
import { Field, FieldProps, useFormikContext } from 'formik';
import { DatePicker } from '@/components/DatePicker';
import styles from './style.css';

type Props = {
  editing: boolean;
  label: string;
  name: string;
  displayValue: Date;
};

export function FieldDatePicker({ editing, label, name, displayValue }: Props) {
  const { setFieldValue } = useFormikContext();

  if (!editing) {
    return (
      <Display
        label={label}
        displayValue={displayValue} />
    );
  }

  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => (
        <div>
          <div className={styles.label}>
            {label} {meta.touched && meta.error && <span className={styles.formError}>{meta.error}</span>}
          </div>
          <div className={styles.value}>
            <DatePicker
              name={field.name}
              inline={false}
              isClearable
              onChange={value => setFieldValue(name, value)}
              onBlur={field.onBlur}
              placeholderText="Select a date"
              selected={field.value as Date} />
          </div>
        </div>
      )}
    </Field>
  );
}

const Display = memo(({ label, displayValue }: Pick<Props, 'label' | 'displayValue'>) => {
  return (
    <Fragment>
      <div className={styles.label}>{label}</div>
      <span className={styles.dateValue}>
        {displayValue ? format(displayValue, 'M/d/yyyy') : '-'}
      </span>
    </Fragment>
  );
});