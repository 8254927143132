import { useCallback } from 'react';
import type { AxiosError } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import * as api from '@api';
import type { GetProjectFiles } from '@api/interfaces/projects.files';
import { querykey } from '@consts';

type Props = GetProjectFiles.Request;
type Data = GetProjectFiles.Response;
type QueryKey = readonly [string, Props];
type Options = UseQueryOptions<Data, AxiosError, Data, QueryKey>;

export const useFetchProjectFiles = (props: Props, options?: Options) => {

  const qk = querykey.Projects.Files.Get(props);

  return useQuery({
    queryKey: qk,
    queryFn: ({ queryKey }) => {
      const [, params] = queryKey;
      return api.projects.files.getProjectFiles(params);
    },
    ...options,
  });
};

export const useInvalidateProjectFilesQuery = () => {

  const queryClient = useQueryClient();

  return useCallback(() => {
    const baseQueryKey = querykey.Projects.Files.Base;
    return queryClient.invalidateQueries([baseQueryKey]);
  }, [queryClient]);
};