export enum ChatConnectionState {
  Connected = 'connected',
  Connecting = 'connecting',
  Denied = 'denied',
  Disconnected = 'disconnected',
  Disconnecting = 'disconnecting',
}

export enum ChatMessageStatus {
  Pending  = 'pending',
  Failed   = 'failed',
  Retrying = 'retrying'
}