import { Commands } from './Toolbar.Commands';
import styles from './style/Toolbar.css';

type Props = {
  children: React.ReactNode;
};

export const Toolbar = (props: Props) => {

  return (
    <div className={styles.menu}>
      <Commands />
      {props.children}
    </div>
  );
};

Toolbar.displayName = 'Post.New.Toolbar';