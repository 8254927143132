import { cx } from '@utils';
import { SectionProps } from './interfaces';
import styles from './style.css';

const Section = (props: SectionProps) => (
  <section
    className={cx(styles.sectionRoot, props.className)}>
    <div className={cx(styles.sectionWrap, props.wrapClassName)}>
      {props.children}
    </div>
  </section>
);

export default Section;