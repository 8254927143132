import { useClassificationConditionContext, useClassificationConditionValidations } from '@containers/SurveyBuilder.Logic';
import { useSurveyClassifications } from '@/containers/SurveyBuilder';
import { LogicValidation } from './Builder.Validation';
import { ClassificationDropdown } from './Builder.Classification.Dropdown';
import styles from './style/Builder.Condition.Classification.css';

export const LogicBuilderClassificationCondition = () => {

  const {
    item,
  } = useClassificationConditionContext();
  const classifications = useSurveyClassifications();

  const validations = useClassificationConditionValidations(item.identifier);

  const hasClassifications = !!classifications.length;

  return (
    <div>
      <div className={styles.row}>
        <div className={styles.label}>Classified as:</div>
        {hasClassifications &&
          <ClassificationDropdown />
        }
        {!hasClassifications &&
          <div className={styles.none}>No available classifications.</div>
        }
      </div>
      <LogicValidation value={validations.value} />
    </div>
  );
};