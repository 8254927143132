import * as core from './core';

const landing = {
  button: {
    cta: core.main.landing.button.demo,
    enterprise: core.main.landing.button.enterprise,
    login: core.main.landing.button.login,
    individuals: core.main.landing.button.individuals,
  },
  text: {
    copy: core.main.landing.text.copy,
    title: core.main.landing.text.title,
  },
};

const banner = {
  button: {
    cta: {
      text: `Schedule a demo to see how the platform works`,
      ...core.ui.link.demo,
    },
  },
};

const benefits = {
  collection: {
    benefits: [{
      image: {
        icon: core.main.benefits.image.value,
      },
      text: {
        title: 'Value',
        copy: `Our technology-enabled automation allows enterprise clients to perform more research with the same budget.`,
      },
    }, {
      image: {
        icon: core.main.benefits.image.quality,
      },
      text: {
        title: `Quality`,
        copy: `We pay top experts the highest fees and provide the best technology to consult with our clients.`,
      },
    }, {
      image: {
        icon: core.main.benefits.image.efficiency,
      },
      text: {
        title: `Service`,
        copy: `Our clients are matched with top-tier service professionals to supplement our software tools as needed.`,
      },
    }],
  },
};

const funnel = {
  button: {
    cta: core.main.funnel.button.demo,
  },
  text: {
    cta: core.main.funnel.text.demo,
  },
};

export const content = {
  main: {
    banner,
    benefits,
    landing,
    funnel,
    press: core.main.press,
  },
  nav: {
    button: {
      cta: core.nav.button.demo,
      login: core.ui.button.login,
      signup: core.ui.button.signup,
    },
  },
  signup: null,
};