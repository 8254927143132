import { useCallback, useMemo } from 'react';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { useDeleteSurveySection, useSurveySectionBuilderContext } from '@/containers/SurveyBuilder.Section/context';
import { SurveyBuilderContextMenu } from '@/components/SurveyBuilder/ContextMenu';
import type { ContextMenuItemProps } from '@/components/SurveyBuilder/ContextMenu.Item';
import { useSectionNameModal } from './Modal.Name';
import { useVisibilityLogicModal } from './Modal.VisibilityLogic';
import { useSectionRandomizationModal } from './Modal.Randomization';

export const SectionContextMenu = () => {

  const ctx = useSurveySectionBuilderContext();
  const [state, dispatch] = useSurveyBuilderState();
  const isDerivedSection = useIsDerivedSection();

  const [toggleNameModal, SectionNameModal] = useSectionNameModal();
  const [toggleVisibilityModal, VisibilityModal] = useVisibilityLogicModal();
  const [toggleRandomizationModal, RandomizationModal] = useSectionRandomizationModal();

  const handleDelete = useDeleteSurveySection();

  const canDelete = useMemo(() => {
    return !state.editing.itemIdentifier && ctx.section.metadata.canDelete;
  }, [state.editing.itemIdentifier, ctx.section.metadata.canDelete]);

  const deleteItem = useMemo(() => {
    if (!canDelete) return null;

    return {
      children: 'Delete',
      onClick: handleDelete,
    };
  }, [canDelete, handleDelete]);

  const nameItem = useMemo(() => {
    return {
      children: 'Edit name',
      onClick: toggleNameModal,
    };
  }, [toggleNameModal]);

  const toggleHiddenSection = useCallback(() => {
    dispatch({
      type: 'toggle-section-hidden',
      identifier: ctx.section.identifier,
    });
  }, [dispatch, ctx.section.identifier]);

  const toggleScreener = useCallback(() => {
    dispatch({
      type: 'section-screener-toggled',
      payload: {
        identifier: ctx.section.identifier,
      },
    });
  }, [dispatch, ctx.section.identifier]);

  const screenerItem = useMemo(() => {
    return {
      checked: ctx.section.isScreener,
      disabled: false,
      children: 'Screener',
      onClick: toggleScreener,
    };
  }, [
    ctx.section.isScreener,
    toggleScreener,
  ]);

  const hiddenItem = useMemo(() => {

    const canToggle = ctx.section.metadata.canModifyVisibility;

    return {
      checked: ctx.section.hidden,
      disabled: !canToggle,
      children: 'Hidden by default',
      onClick: toggleHiddenSection,
    };
  }, [
    ctx.section.metadata.canModifyVisibility,
    ctx.section.hidden,
    toggleHiddenSection,
  ]);

  const visibilityLogicItem = useMemo(() => {

    return {
      disabled: !ctx.section.hidden,
      children: 'Manage Visibility Logic',
      onClick: toggleVisibilityModal,
    };
  }, [
    ctx.section.hidden,
    toggleVisibilityModal,
  ]);

  const randomizeItem = useMemo(() => {
    return {
      checked: ctx.section.metadata.randomize,
      disabled: false,
      children: 'Randomize Items',
      onClick: () => {
        dispatch({
          type: 'section-metadata-updated',
          payload: {
            identifier: ctx.section.identifier,
            metadata: { randomize: !ctx.section.metadata.randomize },
          },
        });
      },
    };
  }, [
    ctx.section.metadata.randomize,
    ctx.section.identifier,
    dispatch,
  ]);

  const randomizeSelf = useMemo(() => {
    return {
      checked: ctx.section.metadata.randomizeSelf,
      disabled: state.survey.sections.length < 2,
      children: 'Randomize Sections',
      onClick: () => {
        toggleRandomizationModal();
      },
    };
  }, [ctx.section.metadata.randomizeSelf, state.survey.sections.length, toggleRandomizationModal]);

  const items: ContextMenuItemProps[] = [
    nameItem,
    hiddenItem,
    visibilityLogicItem,
    screenerItem,
    deleteItem,
    randomizeItem,
    randomizeSelf,
  ].filter(Boolean);

  if (isDerivedSection) {
    return null;
  }

  return (
    <>
      <SurveyBuilderContextMenu items={items} />
      <SectionNameModal />
      <VisibilityModal />
      <RandomizationModal />
    </>
  );
};

const useIsDerivedSection = () => {
  const ctx = useSurveySectionBuilderContext();
  const [state, dispatch] = useSurveyBuilderState();

  return state.survey.questions.some(q => q.section.identifier === ctx.section.identifier && q.metadata.derivedType);
};