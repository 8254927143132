import { useLocation } from 'react-router-dom';
import { NumberParam, useQueryParam } from 'use-query-params';
import { useMemo } from 'react';
import { path } from '@consts';
import { useAppReadyState } from '@/containers/AppReadyState';
import * as Menu from './Menu';
import * as Nav from './Nav';
import { useHideSignupActions } from './hooks';
import styles from './style/Navbar.css';

type Props = unknown;

export const Navbar = (props: Props) => {
  const appState = useAppReadyState();
  const hideActions = useHideSignupActions();
  const [platform] = useQueryParam('platform', NumberParam);
  const location = useLocation();

  const logoUrl = useMemo(() => {
    if (hideActions && !appState.authenticated) {
      return null;
    } else {
      return platform
        ? `${path.Website.PlatformLogin}${location.search}`
        : path.Website.Root;
    }
  }, [appState.authenticated, hideActions, platform, location]);

  const hideNavActions = hideActions || platform;

  return (
    <Menu.Container>
      <Navbar.LayoutRoot>
        <div className={styles.navbar}>
          <Nav.Logo
            platform={!!platform}
            to={logoUrl} />
          {/* <Nav.Links /> */}
          <div className={styles.nav}>
            {!platform && <Nav.SentimentLinks />}
            {!hideNavActions && <Nav.Actions />}
            {!platform && <Menu.Hamburger />}
          </div>
        </div>
      </Navbar.LayoutRoot>
      <Menu.Drawer />
    </Menu.Container>
  );
};

type LayoutRootProps = {
  children?: React.ReactNode;
  className?: string;
};

const LayoutRoot = (props: LayoutRootProps) => {
  return (
    <>
      <header className={styles.root}>
        <div className={styles.wrap}>
          {props.children}
        </div>
      </header>
      <div className={styles.pad} />
    </>
  );
};

LayoutRoot.displayName = 'Navbar.LayoutRoot';

Navbar.LayoutRoot = LayoutRoot;