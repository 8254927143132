import { useCallback, useEffect, useState } from 'react';
import { PreCallRecording } from '@/types';
import * as api from '$admin/api';
import { PreCallRecordingSaveContext, PreCallRecordingValueContext, PreCallRecordingUrlContext, PreCallRecordingPreviewContext, PreCallRecordingLoadingContext, PreCallRecordingFetchContext } from './Context';

type Props = {
  children: React.ReactNode;
} & IGroupId;

export const PreCallRecordingContainer = (props: Props) => {

  const [value, setValue] = useState<PreCallRecording>(null);
  const [previewValue, setPreviewValue] = useState<PreCallRecording>(null);
  const [loading, setLoading] = useState(false);

  const [previewUrl, setPreviewUrl] = useState<string>(null);

  const fetchPreCallRecording = useCallback(() => {
    setLoading(true);
    return api.groups.getPreCallRecording({
      groupId: props.groupId,
    }).then(data => {
      setValue(data);
      setPreviewValue(null);
      setPreviewUrl(null);
      setLoading(false);
    });
  }, [
    props.groupId,
  ]);

  useEffect(() => {
    fetchPreCallRecording();
  }, [
    fetchPreCallRecording,
  ]);

  const getPreviewUrl = useCallback(() => {
    return api.groups.getPreCallRecordingPreviewUrl({
      groupId: props.groupId,
    }).then(({ url }) => {
      setPreviewUrl(url);
      setPreviewValue(value);
    });
  }, [
    props.groupId,
    value,
  ]);

  const generateRecording = useCallback((text: string) => {
    return api.groups.generatePreCallRecordingPreview({
      groupId: props.groupId,
      text,
    }).then(({ tmpS3Key, url }) => {
      setPreviewUrl(url);
      setPreviewValue({
        s3Key: tmpS3Key,
        text,
      });
    });
  }, [
    props.groupId,
  ]);

  const handlePreview = useCallback((text: string) => {

    if (previewValue?.text === text) {
      return Promise.resolve();
    }

    if (text === value?.text) {
      return getPreviewUrl();
    } else {
      return generateRecording(text);
    }
  }, [
    getPreviewUrl,
    generateRecording,
    previewValue,
    value?.text,
  ]);

  const handleSave = useCallback(() => {
    return api.groups.savePreCallRecording({
      groupId: props.groupId,
      s3Key: previewValue.s3Key,
      text: previewValue.text,
    }).then(data => {
      setValue(data);
    });
  }, [
    previewValue,
    props.groupId,
  ]);

  return (
    <PreCallRecordingLoadingContext.Provider value={loading}>
      <PreCallRecordingFetchContext.Provider value={fetchPreCallRecording}>
        <PreCallRecordingValueContext.Provider value={value}>
          <PreCallRecordingSaveContext.Provider value={handleSave}>
            <PreCallRecordingUrlContext.Provider value={previewUrl}>
              <PreCallRecordingPreviewContext.Provider value={handlePreview}>
                {props.children}
              </PreCallRecordingPreviewContext.Provider>
            </PreCallRecordingUrlContext.Provider>
          </PreCallRecordingSaveContext.Provider>
        </PreCallRecordingValueContext.Provider>
      </PreCallRecordingFetchContext.Provider>
    </PreCallRecordingLoadingContext.Provider>
  );
};

export default PreCallRecordingContainer;