import styled from '@emotion/styled';

type MenuProps = {
  open: boolean;
};

const BorderRadius = 2;

export const DropdownMenuAnchor = styled.button<MenuProps>`
  cursor: pointer;
  color: ${props => props.theme.palette.text.primary};
  background-color: ${props => !props.open ? props.theme.palette.gray.light2 : props.theme.palette.gray.light1};
  display: inline-flex;
  align-items: center;
  font-family: ${props => props.theme.fonts.regular};
  box-sizing: border-box;
  padding: 5px 10px;
  border-radius: 4px;
  flex: 0 0 auto;

  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.palette.gray.light1};
  }
`;

export const DropdownMenu = styled.div`
  background-color: ${props => props.theme.palette.secondary.main};
  background-clip: padding-box;
  border-radius: ${BorderRadius}px;
  border: 1px solid ${props => props.theme.palette.gray.light2};
  box-shadow: 0 2px 6px 2px rgba(60, 64, 67, .15);
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
`;

export const DropdownMenuItem = styled.button`
  display: inline-flex;
  color: ${props => props.theme.palette.text.primary};
  cursor: pointer;
  box-sizing: border-box;
  font-size: 13px;
  font-family: ${props => props.theme.fonts.regular};
  padding: 8px 10px;
  text-align: left;

  transition: color 0.2s, background-color 0.2s;

  &:first-of-type {
    border-top-left-radius: ${BorderRadius}px;
    border-top-right-radius: ${BorderRadius}px;
  };

  &:last-of-type {
    border-bottom-left-radius: ${BorderRadius}px;
    border-bottom-right-radius: ${BorderRadius}px;
  };

  &:hover {
    color: ${props => props.theme.palette.text.primary};
    background-color: ${props => props.theme.palette.gray.light4};
  };
`;