import { path } from '@consts';
import { Button } from '@/components/Button';
import { useHideSignupActions } from './hooks';
import styles from './style/Nav.Menu.css';

export const SentimentLinks = () => {
  const hideSignupActions = useHideSignupActions();

  if (hideSignupActions) return null;

  return (
    <div className={styles.sentimentLinks}>
      <Button
        className={styles.item}
        to={path.Website.ContactUs}
        variant='link'>
        Contact Us
      </Button>
      <Button
        className={styles.item}
        to={path.Website.FAQ}
        variant='link'>
        {`FAQ's`}
      </Button>
    </div>
  );
};