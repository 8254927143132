import { useContext } from 'react';
import { FileUploadReviewContainer } from '@/containers/WorkspaceFileUpload/Upload.Review';
import { FilesTable, FilesTableLoadingContext } from '@/components/Workspace.FilesTable';
import { BrandFilesContainer } from '@/containers/Workspace.Brand/Brand.Files.Container';
import { TabViewTableSkeleton } from '@/components/TabView';
import styles from './style/Tab.Files.css';

const ViewComponent = () => {
  const loading = useContext(FilesTableLoadingContext);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.table}>
            {loading
              ? <TabViewTableSkeleton />
              : <FilesTable classes={{ root: styles.body }} />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export const BrandTabFiles = () => (
  <FileUploadReviewContainer>
    <BrandFilesContainer>
      <ViewComponent />
    </BrandFilesContainer>
  </FileUploadReviewContainer>
);

BrandTabFiles.displayName = 'Workspace.Brand.Tab.Files';