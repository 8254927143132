import { useMemo } from 'react';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { IUniversalSearch } from '@/types';
import { cx } from '@utils';
import ClickContainer from './Results.ClickContainer';
import styles from './style/Results.SeeAll.css';

type Props = {
  className?: string;
  index: number;
  item: IUniversalSearch.SearchBarResultSeeAll;
  highlighted?: boolean;
}

export default function ResultsSeeAll({ className, index, item, highlighted }: Props) {
  const keywordItem: IUniversalSearch.SearchBarResultKeyword = useMemo(() => ({
    ...item,
    type: 'keyword',
  }), [item]);

  return (
    <ClickContainer index={index} item={keywordItem}>
      <div className={cx(styles.root, { [styles.highlighted]: highlighted }, className)}>
        <div className={styles.wrap}>
          <div className={styles.text}>See All Results</div>
          {highlighted && (
            <div className={styles.return}>
              <KeyboardReturnIcon />
            </div>
          )}
        </div>
      </div>
    </ClickContainer>
  );
}

export { ResultsSeeAll };