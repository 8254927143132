import { useCallback } from 'react';
import FormGroup from '@mui/material/FormGroup';
import { cx } from '@utils';
import { Input } from '@/components/Input';
import { SurveyRichTextEditor, SurveyRichTextEditorContainer, useSurveyRichTextEditor } from '@/components/Survey.RichText';
import { CheckboxLabel } from './CheckboxLabel';
import type { RichTextCheckboxGroupProps, RichTextCheckboxGroupItem } from './interfaces';
import styles from './style/CheckboxGroup.css';

type Props =
  RichTextCheckboxGroupProps;

type RichTextLabelProps = {
  item: RichTextCheckboxGroupItem;
};

const RichTextLabel = ({ item }: RichTextLabelProps) => {
  const { manager, state, onChange } = useSurveyRichTextEditor({
    initialState: item.label,
  });

  return (
    <SurveyRichTextEditorContainer
      editable={false}
      manager={manager}
      state={state}
      onChange={onChange}>
      <SurveyRichTextEditor className={styles.editor} />
    </SurveyRichTextEditorContainer>
  );
};

export default function RichTextCheckboxGroup({ className, checkedItems, items, onToggle, onInputChange }: Props) {
  const renderInputLabel = useCallback((item: RichTextCheckboxGroupItem, checked: boolean) => {
    if (!item.allowText || !checked) return <RichTextLabel item={item} />;

    return (
      <div className={styles.openEnded}>
        <div className={styles.label}>
          <RichTextLabel item={item} />
        </div>
        <Input
          name={`${item.id}-text`}
          onChange={e => onInputChange({ id: item.id, text: e.target.value })}
          placeholder="Enter additional text"
          value={item.text || ''} />
      </div>
    );
  }, [
    onInputChange,
  ]);

  const renderItem = useCallback((item: RichTextCheckboxGroupItem, index: number) => {
    const onChange = () => onToggle({ id: item.id });
    const checked = checkedItems.includes(item.id);

    const style: React.CSSProperties = {
      marginBottom: index === items.length - 1
        ? 0
        : 15,
      alignItems: 'flex-start',
    };

    return (
      <CheckboxLabel
        key={item.id}
        checked={checked}
        label={renderInputLabel(item, checked)}
        onChange={onChange}
        disabled={item.disabled}
        style={style} />
    );
  }, [
    checkedItems,
    items.length,
    onToggle,
    renderInputLabel,
  ]);

  return (
    <div className={cx(styles.group, className)}>
      {items.map(renderItem)}
    </div>
  );
}

export { RichTextCheckboxGroup };