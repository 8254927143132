import type { ProjectType, SurveyType } from '@enums';
import type { SurveyBuilderVersion } from '@/types';
import {
  SurveyBuilderState,
} from './SurveyBuilderState';
import { SubmitSurveyDraftContainer } from './SubmitSurveyDraftContainer';
import { ProjectSurveyOptionsContainer } from './ProjectSurveyOptionsContainer';
import { RichTextEditorRegistryContainer } from './RichTextEditorRegistryContainer';
import { ScreenStateContainer } from './ScreenStateContainer';

type Props = {
  initialValue?: SurveyBuilderVersion;
  excludeSurveyTypes?: SurveyType[];
  projectType: ProjectType;
} & ChildrenProps;

export const ProjectSurveyBuilderState = (props: Props) => {
  return (
    <ProjectSurveyOptionsContainer {...props}>
      <RichTextEditorRegistryContainer>
        <SurveyBuilderState initialValue={props.initialValue}>
          <SubmitSurveyDraftContainer>
            <ScreenStateContainer>
              {props.children}
            </ScreenStateContainer>
          </SubmitSurveyDraftContainer>
        </SurveyBuilderState>
      </RichTextEditorRegistryContainer>
    </ProjectSurveyOptionsContainer>
  );
};

export default ProjectSurveyBuilderState;
