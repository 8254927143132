import { cx } from '@utils';
import { useLoginSubmit } from '$website/containers/Auth/hooks/useLoginSubmit';
import styles from './style/SignUpForm.css';

type Props = {
  className?: string;
};

const LoginError = (props: Props) => {
  const [state] = useLoginSubmit();

  const content = state.error
      ? <div className={styles.error}>{state.error.message}</div>
      : null;

  return (
    <div className={cx(styles.notify, props.className)}>{content}</div>
  );
};

export { LoginError };
export default LoginError;