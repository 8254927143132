import { useEffect } from 'react';
import { getFullCalSubmitButtonElement } from '@/components/Calendar/utils';

type Params = {
  disabled:      boolean;
  fcContainerId: string;
};

const useReapplySubmitButtonDisabledState = (params: Params) => {

  useEffect(() => {
    const cal = document.getElementById(params.fcContainerId);

    const overrideDOMAttribute = (_: MouseEvent) => {
      const btn = getFullCalSubmitButtonElement();

      if (btn) btn.disabled = params.disabled;
    };

    cal.addEventListener('click', overrideDOMAttribute);

    return () => {
      cal?.removeEventListener?.('click', overrideDOMAttribute);
    };
  }, [
    params.fcContainerId,
    params.disabled,
  ]);

};

export { useReapplySubmitButtonDisabledState };
export default useReapplySubmitButtonDisabledState;