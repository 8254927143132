import { useCallback, useContext, useMemo } from 'react';
import { useMutation, useQueryClient, UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import * as QK from '@consts/querykey.admin';
import * as api from '$admin/api';
import { Calls } from '$admin/api/interfaces';
import { CallDetailsContext } from '$admin/containers/Call/Context';
import { Call as CD } from '$admin/interfaces';

type Data = Calls.UpdateCallPricing.Response;
type Vars = {
  chargeOverride?: number;
  chargeOverrideCurrency?: string;
  payoutOverride?: number;
  payoutOverrideCurrency?: string;
};

export const useUpdateCallPricing = (options?: UseMutationOptions<Data, AxiosResponse, Vars>) => {
  const queryClient = useQueryClient();

  const ctx = useContext(CallDetailsContext);
  const { call } = ctx.data;

  const queryKey = useMemo(() => {
    return QK.Calls.Call.Get({ callId: call.id });
  }, [call.id]);

  const onSuccess = useCallback((res: Data, v: Vars, ctx) => {
    queryClient.setQueryData(queryKey, (old: CD.ProjectCallDetails) => ({
      ...old,
      pricing: res.pricing,
    }));

    options?.onSuccess(res, v, ctx);
  }, [
    options,
    queryKey,
    queryClient,
  ]);

  return useMutation(pricing => {
    return api.calls.updateCallPricing({
      callId: call.id,
      pricing,
    });
  }, {
    ...options,
    onSuccess,
  });
};