import image from 'static/images/logo-trinity-white.svg?url';
import * as Layout from './Layout';
import { SignUpButton } from './SignUpButton';
import styles from './style/Banner.css';

type Props = unknown;

export const Trinity = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Layout.Screen>
          <div className={styles.main}>
            <div className={styles.header}>
              <img
                className={styles.img}
                src={image} />
            </div>
            <div className={styles.message}>
              {copy.subtitle}
            </div>
            <div className={styles.ctx}>
              <SignUpButton />
            </div>
          </div>
        </Layout.Screen>
      </div>
    </div>
  );
};

Trinity.displayName = 'ResearchCommunity.Banner.Trinity';

const copy = {
  subtitle: `Backed by over 25 years of experience in strategic life sciences research, Trinity will conduct over 400 research engagements using Sentiment this year.`,
};