import http, { xhr } from '@services/http';
import { Users } from './interfaces';

export const fetchEmailVerificationState = (data: Users.FetchEmailVerificationState.Request) => {
  return xhr.get<Users.FetchEmailVerificationState.Response>(`/users/verify/emails/state`, {
    params: {
      userId: data.userId,
    },
  });
};

export const resendInviteEmailVerification = (data: Users.ResendInviteEmailVerification.Request) => {
  return xhr.post<Users.ResendInviteEmailVerification.Response>(`/users/verify/emails/password/resend`, data);
};

export const verifyEmailFromPasswordChangeInvite = (data: Users.VerifyEmailFromPasswordChangeInvite.Request) => {
  return xhr.post<Users.VerifyEmailFromPasswordChangeInvite.Response>(`/users/verify/emails/password/verify`, data);
};

export const refreshEmailVerification = (data: Users.RefreshEmailVerification.Request): Promise<Users.RefreshEmailVerification.Respose> => {
  return http.post(`/users/verify/emails/refresh`, data);
};

export const sendEmailVerification = (data: Users.SendEmailVerification.Request): Promise<Users.SendEmailVerification.Respose> => {
  return http.post(`/users/verify/emails/send`, data);
};

export const verifyEmailToken = (data: Users.VerifyEmailToken.Request): Promise<Users.VerifyEmailToken.Respose> => {
  return http.post(`/users/verify/emails/verify`, data);
};

export const reverifyEmailToken = (data: Users.ReverifyEmailToken.Request) => {
  return http.post<Users.ReverifyEmailToken.Response>(`/users/verify/emails/reverify`, data);
};

export const resendEmailReverification = (data: Users.ResendEmailReverification.Request) => {
  return http.post<Users.ResendEmailReverification.Response>(`/users/verify/emails/reverify/resend`, data);
};