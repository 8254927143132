import { useCallback, useState } from 'react';
import { useTargetProductProfileData, useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';

export const useCanSaveLaunchYear = (value: number) => {

  return !!value;
};

export const useTemplateLaunchYear = () => {
  const [templateData] = useTargetProductProfileData();
  const [launchYear, setLaunchYear] = useState(templateData.launchYear);
  const [_, dispatch] = useSurveyBuilderState();

  const onSubmit = useCallback(() => {
    dispatch({
      type: 'template-launch-year-updated',
      value: launchYear,
    });
  }, [
    launchYear,
    dispatch,
  ]);

  return {
    onChange: setLaunchYear,
    onSubmit,
    value: launchYear,
  };
};