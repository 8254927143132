import { ThreadMessageActions } from './ThreadMessageActions';
import { ThreadMessageStatus } from './ThreadMessageStatus';
import { ThreadMessage } from './ThreadMessage';
import { PlatformMessage } from './interfaces';
import styles from './style/ThreadMessage.css';

type Props = {
  item: PlatformMessage;
};

type ActiveProps = {
  onApprove:  (id: number) => unknown;
  onReject:   (id: number) => unknown;
  onFlag?:    (id: number) => unknown;
  isFlagged?: boolean;
} & Props;

export const MessageActive = (props: ActiveProps) => {
  return (
    <ThreadMessage item={props.item}>
      <ThreadMessageActions
        isFlagged={props.isFlagged}
        item={props.item}
        onApprove={props.onApprove}
        onFlag={props.onFlag}
        onReject={props.onReject} />
    </ThreadMessage>
  );
};

type StaticProps = {
  isApproved: boolean;
  isBypassed: boolean;
  isFlagged:  boolean;
  isRejected: boolean;
} & Props;

export const MessageStatic = (props: StaticProps) => {
  return (
    <ThreadMessage
      className={styles.screened}
      item={props.item}>
      <ThreadMessageStatus
        isApproved={props.isApproved}
        isBypassed={props.isBypassed}
        isFlagged={props.isFlagged}
        isRejected={props.isRejected}
        item={props.item} />
    </ThreadMessage>
  );
};

export const Message = {
  Active: MessageActive,
  Static: MessageStatic,
};

export default Message;