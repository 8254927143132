import { memo, useContext } from 'react';
import styled from '@emotion/styled';
import { BrandInsightsModalContext } from '@/brand-insights/components/BrandInsights/context';
import { useHover } from '@/brand-insights/hooks';
import { InsightsChatIcon } from '@/components/icons/InsightsChat';

type Props = {
  className?: string;
};

export const DiscoverInsights = memo(({ className }: Props) => {

  const [ref, hovering] = useHover();

  const [_, onClick] = useContext(BrandInsightsModalContext);

  return (
    <Root
      className={className}
      onClick={onClick}
      ref={ref}>
      <Wrap>
        <StyledIcon color={hovering ? hoverColor : baseColor} />
        <Title hovering={hovering}>Discover Insights</Title>
      </Wrap>
    </Root>
  );
});

const baseColor = '#415993';
const hoverColor = 'rgb(49 68 117)';

const Wrap = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '8px 12px',
});

type HoverProps = {
  hovering: boolean;
};

const Title = styled('div')<HoverProps>(({ hovering }) => ({
  fontSize: 15,
  fontFamily: 'var(--font-semibold)',
  color: hovering ? hoverColor : baseColor,
  transition: 'color 0.3s linear',
}));

const StyledIcon = styled(InsightsChatIcon)({
  marginRight: 12,
  transition: 'color 0.3s linear',
});

const Root = styled('div')({
  cursor: 'pointer',
  borderRadius: 50,
  border: `2px solid #BCC9E1`,
  backgroundImage: `linear-gradient(to right, #fbfbfb , #f0f2f8)`,
  position: 'relative',
  zIndex: 1,
  transition: 'all 0.3s linear',

  ':before': {
    position: 'absolute',
    backgroundImage: `linear-gradient(to right, #f0f2f8 , #E3F4FB)`,
    content: '""',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: -1,
    transition: 'opacity 0.3s linear',
    opacity: 0,
    borderRadius: 50,
  },

  ':hover::before': {
    opacity: 1,
  },

  ':hover': {
    border: `2px solid #99a7cb`,
  },

});