import type { Action, DownloaderItem, QueryDownloaderState } from './interfaces';

export function asyncItemsReducer(state: QueryDownloaderState, action: Action) {
  const { type, ...item } = action;

  switch (type) {

    case `download/init`:
      return {
        items: [
          ...state.items,
          { ...item, status: `init` } as DownloaderItem,
        ],
      };

    case `download/set`:
      return {
        items: state.items.map(x => {
          if (x.id !== item.id) return x;

          return { ...x, ...item };
        }),
      };

    case `download/remove`:
      return {
        items: state.items.filter(x => x.id !== item.id),
      };

    default:
      return state;
  }
}

export const initialDownloadsState: QueryDownloaderState = {
  items: [],
};