import { memo } from 'react';
import { cx } from '@utils';
import styles from './style/Base.css';

type Props = {
  children:   React.ReactNode;
  className?: string;
};

const ButtonSet = memo((props: Props) => {
  const className = cx(styles.btns, props.className);

  return (
    <div className={className}>
      {props.children}
    </div>
  );
});

export { ButtonSet };
export default ButtonSet;