import extend from '@enums/extend';

export enum VerificationStatus {
  New         = 1,
  Verified    = 2,
  Unverified  = 3,
  Preverified = 4,
}

const map = new Map([
  [VerificationStatus.New,          { name: 'New'   }],
  [VerificationStatus.Verified,     { name: 'Verified'  }],
  [VerificationStatus.Unverified,   { name: 'Unverified' }],
  [VerificationStatus.Preverified,  { name: 'Pre-verified' }],
]);

export default {
  VerificationStatus: extend(map),
};
