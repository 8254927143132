import type { DetailedHTMLProps, HTMLAttributes } from 'react';
import { forwardRef, useMemo } from 'react';
import type { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { Draggable } from 'react-beautiful-dnd';
import { cx } from '@utils';
import type { AltRankingForm } from './interfaces';
import { getDraggableId } from './utils.ranking.alt';
import { useDraggableInPortal } from './hooks/useDraggableInPortal';
import styles from './style/Ranking.Alt.Item.css';

type Props = {
  className?: string;
  isDragging: boolean;
  item: AltRankingForm.DraggableItem;
} & ChildrenProps
  & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const Item = forwardRef<HTMLDivElement, Props>(({ children, className, isDragging, item, ...otherProps }, ref) => {

  const computedClassName = useMemo(() => {
    return cx(styles.root, className, {
      [styles.dragging]: isDragging,
    });
  }, [className, isDragging]);

  return (
    <div
      ref={ref}
      {...otherProps}
      className={computedClassName}>
      {children}
    </div>
  );
});

type DraggableProps = {
  className?: string;
  isDragDisabled?: boolean;
  item: AltRankingForm.DraggableItem;
} & ChildrenProps;

export const DraggableItem = ({ children, className, isDragDisabled, item }: DraggableProps) => {
  const renderDraggable = useDraggableInPortal();

  return (
    <Draggable
      isDragDisabled={isDragDisabled}
      draggableId={getDraggableId(item)}
      index={item.index}>
      {renderDraggable((provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
        return (
          <Item
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            item={item}
            isDragging={snapshot.isDragging || !!snapshot.combineTargetFor}
            className={className}>
            {children}
          </Item>
        );
      })}
    </Draggable>
  );
};