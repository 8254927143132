import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as api from '@api';
import { RefetchTranscriptContext } from '@containers/Transcript/context';
import { FindReplaceContext } from '@/components/Transcript/context';
import { useFindReplaceWordSnapping } from '@/components/Transcript/hooks/useFindReplaceWordSnapping';
import { useUpdateEditorView } from './useUpdateEditorView';

export const useFindReplaceAllMutation = ({ transcriptId }: ITranscriptId) => {

  const { caseSensitive, query, replacement, stopFind } = useContext(FindReplaceContext);
  const refetch = useContext(RefetchTranscriptContext);

  const findReplaceWordSnapping = useFindReplaceWordSnapping();
  const updateEditorView = useUpdateEditorView();

  const mutation = useMutation({
    mutationKey: [`put:transcripts/text/global`,
      transcriptId,
      caseSensitive,
      query,
      replacement,
    ],
    mutationFn: () => {
      const replacements = findReplaceWordSnapping({
        caseSensitive,
        query,
        replacement,
      });

      return api.transcripts.text.saveTextReplacements({
        transcriptId,
        replacements,
      })
      .then(() => {
        return refetch()
          .then(res => updateEditorView(res.data.transcript.document))
          .then(stopFind);
      });
    },
  });

  return mutation;
};