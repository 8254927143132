import { format } from 'date-fns';
import CheckIcon from '@mui/icons-material/Check';
import { AvailableTimeslot } from '@containers/Calendar/interfaces';
import { cx } from '@utils';
import styles from './style/Timeslot.css';

type Props = {
  item: AvailableTimeslot;
  onSelect: (item: AvailableTimeslot) => unknown;
  selected: boolean;
};

const Timeslot = (props: Props) => {
  const time = format(props.item.start, 'h:mm a');

  function handleClick() {
    props.onSelect(props.item);
  }

  return (
    <button
      className={cx(styles.item, { [styles.selected]: props.selected })}
      onClick={handleClick}>
      <span className={styles.check}>
        <CheckIcon />
      </span>
      {time}
    </button>
  );
};

export { Timeslot };
export default Timeslot;