import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import type { QueryParamConfig } from 'use-query-params';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import type { Projects } from '@api/interfaces/projects';
import { useSuspendInitialLoading } from '@containers/GroupProject/hooks';
import { useIsInternalUser } from '@/containers/Store';
import { ProjectStatus } from '@enums';
import { ButtonOutlined } from '@presentation/ButtonOutlined';
import { getLocationFor } from '@utils';
import { WorkspacesNavMenu } from '@/components/Workspace/WorkspaceNav';
import AgendaCard from '@screens/GroupMain/AgendaCard';
import { useRegisterGlobalChatContext } from '@/screens/GroupMain/hooks';
import { ProjectStatusFilter } from '@/components/GroupProject';
import * as Table from '@/components/Table';
import * as CallScheduling from '$admin/components/Scheduling';
import { ProjectsTable } from './Projects.Table';
import styles from './style/Group.Projects.css';

type Props = unknown;

export const GroupProjects = (props: Props) => {

  const isInternalUser = useIsInternalUser();

  useRegisterGlobalChatContext();

  return (
    <CallScheduling.Container>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.main}>
            <Helmet title="Projects" />

            <div className={styles.table}>
              {isInternalUser && <WorkspacesNavMenu className={styles.workspaces} />}

              <ProjectTableWidget />
            </div>

            <div className={styles.sidebar}>
              <AgendaCard />
            </div>

          </div>
        </div>
      </div>
    </CallScheduling.Container>
  );
};

const ProjectTableWidget = () => {

  const isInternalUser = useIsInternalUser();

  const [qp, setQuery] = useQueryParams({
    index: withDefault(NumberParam, 0),
    size: withDefault(NumberParam, 25),
    status: withDefault(NumberParam, ProjectStatus.Active),
    sortBy: withDefault(StringParam, 'created') as QueryParamConfig<'created' | 'name' | 'owner' | 'progress'>,
    sortDir: withDefault(StringParam, 'desc') as QueryParamConfig<'asc' | 'desc'>,
  });

  const queryKey = useMemo(() => {
    return [
      `get:projects`, {
        index: qp.index,
        size: qp.size,
        status: qp.status,
        sortBy: qp.sortBy,
        sortDir: qp.sortDir,
      },
    ];
  }, [qp]);

  const query = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey,
    queryFn: () => {
      return api.projects.fetchProjects({
        index: qp.index,
        status: qp.status,
        size: qp.size,
        sortBy: qp.sortBy,
        sortDir: qp.sortDir,
      });
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const pagination = useMemo(() => ({
    pageCount: query.data?.pagination?.pageCount ?? 0,
    totalCount: query.data?.pagination?.totalCount ?? 0,
  }), [
    query.data?.pagination,
  ]);

  const loading = useMemo(() => query.isInitialLoading && !query.data, [query.data, query.isInitialLoading]);

  const data = useMemo(() => {
    return loading
      ? getLazyTableData(25)
      : query.data?.items ?? [];
  }, [loading, query.data?.items]);

  const empty = useMemo(() => {
    return query.isFetchedAfterMount && !loading && !data.length;
  }, [data.length, loading, query.isFetchedAfterMount]);

  const suspended = useSuspendInitialLoading({
    data,
    empty,
    isInitialLoading: query.isInitialLoading,
    loading,
    pagination,
  }, 1000);

  const handleStatusChange = useCallback((status: ProjectStatus) => {
    setQuery({ index: 0, status, size: qp.size }, 'pushIn');
  }, [qp.size, setQuery]);

  return (
    <div className={styles.table}>
      <Table.Layout.Box className={styles.box}>
        <Table.Layout.Header
          classes={{ title: styles.title }}
          title="Projects">
          <div className={styles.actions}>
            <ProjectStatusFilter
              className={styles.filter}
              value={qp.status}
              onChange={handleStatusChange} />
            {isInternalUser && (
              <ButtonOutlined
                borderWidth="thin"
                className={styles.btn}
                color="blue"
                fontWeight="bold">
                <Link to={getLocationFor.project.create()}>
                  Create New
                </Link>
              </ButtonOutlined>
            )}
          </div>
        </Table.Layout.Header>

        <ProjectsTable
          data={suspended.data}
          empty={suspended.empty}
          loading={suspended.loading}
          pagination={suspended.pagination}
          query={qp}
          setQuery={setQuery} />
      </Table.Layout.Box>
    </div>
  );
};

GroupProjects.displayName = 'Group.Projects';

const getLazyTableData = <T extends Projects.FetchProjects.ProjectItem>(pageSize = 25) => {
  return Array.from({ length: pageSize }, _ => ({} as T));
};