import type { DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { Droppable } from 'react-beautiful-dnd';

type Props = ChildrenProps;

export const RankingListDropArea = (props: Props) => {
  return (
    <Droppable droppableId="droppable">
      {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}>
          {props.children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default RankingListDropArea;