import { useEffect } from 'react';
import type { LeftBarWindowMessages } from '../interfaces';
import { useLeftBarWindowContext, useLeftBarContext, useLeftBarActionsContext } from '../context';

export const useParentWindowEvents = () => {
  const { window: childWindow, setWindow } = useLeftBarWindowContext();
  const { state } = useLeftBarContext();
  const { onItemClick } = useLeftBarActionsContext();

  useEffect(() => {
    function messageHandler(msg: MessageEvent<LeftBarWindowMessages.Message>) {
      if (msg.data?.type) {
        if (msg.data.type === 'request-state') {
          const dispatchMessage: LeftBarWindowMessages.DispatchMessage = {
            type: 'dispatch',
            action: {
              type: 'state-replaced',
              state,
            },
          };
          msg.source.postMessage(dispatchMessage);
        } else if (msg.data.type === 'click-item') {
          onItemClick(msg.data.itemId);
        }
      }
    }

    window.addEventListener('message', messageHandler);

    return () => window.removeEventListener('message', messageHandler);
  }, [state, onItemClick]);

  useEffect(() => {
    function closeHandler() {
      setWindow(null);
    }
    if (childWindow) {
      childWindow.addEventListener('beforeunload', closeHandler);
      const interval = setInterval(() => {
        if (!childWindow || childWindow.closed) {
          closeHandler();
          clearInterval(interval);
        }
      }, 500);

      return () => {
        childWindow.removeEventListener('beforeunload', closeHandler);
        clearInterval(interval);
      };
    }
  }, [childWindow, setWindow]);

  useEffect(() => {
    function closeHandler() {
      childWindow?.close();
    }

    window.addEventListener('beforeunload', closeHandler);

    return () => window.removeEventListener('beforeunload', closeHandler);
  }, [childWindow]);
};