import { useContext } from 'react';
import { Button } from '@/components/Button';
import { Schedulers } from './Booking.ViewAvailability.Schedulers';
import { FrameContext } from './Context';
import { Matches } from './Booking.ViewAvailability.Matches';
import { FrameKey } from './interfaces';
import styles from './style/Booking.ViewAvailability.css';

type Props = unknown;

export const ViewAvailability = (props: Props) => {
  const ctx = {
    frame: useContext(FrameContext),
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <Matches />
          <Schedulers />
        </div>
        <div className={styles.footer}>
          <Button
            className={styles.btn}
            color="primary"
            onClick={() => ctx.frame.goToFrame({ frame: FrameKey.RequestAvailability })}
            variant="brick">
            Request Availability
          </Button>
        </div>
      </div>
    </div>
  );
};

ViewAvailability.displayName = 'Booking.ViewAvailability';