import { Flag as FlagIcon } from 'react-feather';
import { cx } from '@utils';
import styles from './style/Flag.css';

type Props = {
  active?:          boolean;
  activeClassName?: string;
  className?:       string;
  color?:           string;
  onClick?:         () => unknown;
  size?:            string | number;
};

const Flag = (props: Props) => {

  const iconStyles = cx({
    [cx(styles.active, props.className, props.activeClassName)]: props.active,
    [cx(styles.inactive, props.className)]: !props.active,
  });

  return (
    <FlagIcon
      className={iconStyles}
      color={props.color}
      onClick={props.onClick}
      size={props.size} />
  );
};

const defaultProps = {
  active: false,
  onClick: () => {},
};

Flag.defaultProps = defaultProps;

export { Flag };
export default Flag;