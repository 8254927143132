import { LinkProps, generatePath } from 'react-router-dom';
import type { LocationDescriptor } from 'history';
import * as path from '@consts/path';
import * as pathname from '@consts/pathname';
import type { Navigation } from '@utils/interfaces';
import { encode, stringify } from '@utils/querystring';
import type {
  Project,
  UserProfileProps,
  WorkspacePathValue,
  WorkspaceSearchFull,
  IUniversalSearch,
  GroupTag,
} from '@/types';
import { currentLocation } from './navigation.shared';

const getComplianceCallReviewLocation = (data: Navigation.Compliance.CallReview) => {
  return {
    pathname: `${pathname.Compliance}/${data.groupId}/review/calls/${data.callId}`,
    state: { from: currentLocation() },
  };
};

const getComplianceMessageReviewLocation = (data: Navigation.Compliance.MessageReview) => {
  return {
    pathname: `${pathname.Compliance}/${data.groupId}/review/messages/${data.threadId}`,
    state: { from: currentLocation() },
  };
};

const getComplianceSurveyReviewLocation = (data: Navigation.Compliance.SurveyReview) => {
  return {
    pathname: `${pathname.Compliance}/${data.groupId}/review/surveys/${data.responseId}`,
    state: { from: currentLocation() },
  };
};

const getProjectOnboardingLocation = (data: Navigation.ProjectOnboardingParams) => {
  return {
    pathname: `${path.Onboarding.Projects.Root}/${data.projectId}`,
  };
};

const getProjectSearchLocation = (data: Pick<Project, 'slug'>) => {
  return {
    pathname: `${pathname.PROJECTS}/${data.slug}/search`,
  };
};

const getUserProfileLocation = (data: Pick<UserProfileProps, 'slug'>) => {
  return {
    pathname: `${pathname.USER_PROFILE}/${data.slug}`,
    state: {
      from: currentLocation(),
    },
  };
};

const getOwnUserProfileLocation = (data: Pick<Store.User.Profile, 'slug'> & IUserId & { from?: string }) => {
  return {
    pathname: `${pathname.USER_PROFILE}/${data.slug}`,
    state: {
      from: data.from,
      userId: data.userId,
    },
  };
};

const getProjectSurveyResponseLocation = (data: Pick<Project, 'slug'> & { projectSurveyResponseId: number }) => {
  return {
    pathname: `${pathname.PROJECTS}/${data.slug}/survey-responses/${data.projectSurveyResponseId}`,
    state: {
      from: currentLocation(),
    },
  };
};

const getProjectRootLocation = (data: Pick<Project, 'slug'>) => {
  return {
    pathname: `${pathname.PROJECTS}/${data.slug}`,
    state: {
      from: currentLocation(),
    },
  };
};

const getProjectRootLocationWithId = (data: Pick<Project, 'id'>) => {
  return {
    pathname: `${pathname.PROJECTS}/${data.id}`,
    state: {
      from: currentLocation(),
    },
  };
};

const getProjectSummaryLocation = (data: Pick<Project, 'slug'>) => {
  return {
    pathname: `${pathname.PROJECTS}/${data.slug}${pathname.SUMMARY}`,
    state: {
      from: currentLocation(),
    },
  };
};

const getProjectSurveyResponsesLocation = (data: Pick<Project, 'slug'>) => {
  return {
    pathname: `${pathname.PROJECTS}/${data.slug}/survey-responses`,
    state: {
      from: currentLocation(),
    },
  };
};

const getProjectEntitiesLocation = ({ slug }: Pick<Project, 'slug'>) => {
  return {
    pathname: generatePath(path.ProjectTabs.Entities, { slug }),
    state: {
      from: currentLocation(),
    },
  };
};

const getProjectEntitiesOnboardingLocation = ({ slug }: Pick<Project, 'slug'>) => {
  return {
    pathname: generatePath(path.Projects.EntitiesOnboarding, { slug }),
    state: {
      from: currentLocation(),
    },
  };
};

const getProjectCreationLocation = (parentObjectId?: number, step = 'overview'): LocationDescriptor => {
  const search = parentObjectId ? `?parentObjectId=${parentObjectId}` : undefined;

  return {
    pathname: `${pathname.PROJECTS}/new/${step}`,
    search,
    state: {
      from: currentLocation(),
    },
  };
};

const getCallTranscriptLocation = (data: Navigation.CallTranscriptParams) => {
  const search = data.position ? `?s=${data.position}` : undefined;
  return {
    pathname: `${pathname.PROJECTS}/${data.slug}/calls/${data.callId}/transcript`,
    search,
    state: {
      from: currentLocation(),
    },
  };
};

const getCallTranscriptCleanupLocation = (data: ICallId & IProjectId) => {
  return {
    pathname: `${path.Projects.Root}/${data.projectId}/calls/${data.callId}/cleanup`,
    state: {
      from: currentLocation(),
    },
  };
};

const getMessageChannelsLocation = (data: Navigation.MessagesChannelsParams) => {
  return {
    pathname: `${path.Messages.Root}/${data.userId}`,
    state: {
      from: currentLocation(),
    },
  };
};

const getProjectSurveysLocation = (data: Pick<Project, 'slug'>) => {
  return {
    pathname: `${pathname.PROJECTS}/${data.slug}/surveys`,
    state: {
      from: currentLocation(),
    },
  };
};

const getProjectSurveyEditorLocation = (data: Pick<Project, 'slug'> & ISurveyId) => {
  return {
    pathname: `${pathname.PROJECTS}/${data.slug}/survey/${data.surveyId}`,
    state: {
      from: currentLocation(),
    },
  };
};

const getNewProjectSurveyLocation = (data: Pick<Project, 'slug'>) => {
  return {
    pathname: generatePath(path.Projects.SurveyNew, { slug: data.slug }),
    state: {
      from: currentLocation(),
    },
  };
};

const getProjectSurveyMonitoringLocation = (data: Pick<Project, 'slug'> & Partial<ISurveyId>) => {
  return {
    pathname: `${pathname.PROJECTS}/${data.slug}/survey-monitoring${data.surveyId ? `/${data.surveyId}` : ''}`,
    state: {
      from: currentLocation(),
    },
  };
};

const getProjectSurveySettingsLocation = (data: IProjectId) => {
  return {
    pathname: `${pathname.PROJECTS}/${data.projectId}/survey-settings`,
    state: {
      from: currentLocation(),
    },
  };
};

const getSurveyPreviewLocation = (data: ISurveyVersionId) => {
  return {
    pathname: path.Projects.SurveyPreview.replace(':surveyVersionId', data.surveyVersionId.toString()),
    state: {
      from: currentLocation(),
    },
  };
};

const getProjectSurveyCloningLocation = (data: Pick<Project, 'id'>) => {
  return {
    pathname: `${pathname.PROJECTS}/${data.id}/clone`,
    state: {
      from: currentLocation(),
    },
  };
};

const getProjectSurveyPreviewLocation = (data: Pick<Project, 'id'>) => {
  return {
    pathname: `${pathname.PROJECTS}/${data.id}/preview`,
    state: {
      from: currentLocation(),
    },
  };
};

const getSurveyPreviewCompleteLocation = (data: Pick<Project, 'id'> & { isDisqualified: boolean }) => {
  return {
    pathname: `${pathname.PROJECTS}/${data.id}/preview/complete?isDisqualified=${data.isDisqualified ? 1 : 0}`,
    state: {
      from: currentLocation(),
    },
  };
};

const getSearchLocation = (data: GetSearchLocation) => {
  const encoded = encode(data);

  return `${pathname.SEARCH}?${stringify({ q: encoded })}`;
};

type GetSearchLocation = {
  [key: string]: {
    id: string | number;
    name: string;
  }[];
};

const getWorkspaceLocation = (data: GetWorkspaceLocation) => {
  return {
    pathname: [pathname.WORKSPACES, data.workspace, 'files'].filter(Boolean).join('/'),
    state: {
      from: currentLocation(),
    },
  };
};

const getFolderLocation = (data: IWorkspaceFolderId) => {
  return {
    pathname: `${pathname.WORKSPACES}/folders/${data.folderId}`,
    state: {
      from: currentLocation(),
    },
  };
};

export const getWorkspaceTranscriptLocation = ({ position, transcriptId }: Navigation.WorkspaceTranscriptParams) => {
  const search = position ? `?s=${position}` : undefined;
  return {
    pathname: `${pathname.WORKSPACES}/transcripts/${transcriptId}`,
    search,
    state: {
      from: currentLocation(),
    },
  };
};

type GetWorkspaceLocation = {
  workspace: WorkspacePathValue;
};

const getFileLocation = (data: IWorkspaceFileId) => {
  return {
    pathname: `${pathname.WORKSPACES}/files/${data.fileId}`,
    state: {
      from: currentLocation(),
    },
  };
};

const getWorkspaceBrandLocation = (data: IWorkspaceBrandId) => {
  return {
    pathname: `${pathname.WORKSPACES}/brands/${data.brandId}`,
    state: {
      from: currentLocation(),
    },
  };
};

type GetWorkspaceSearchLocation = {
  tab?: WorkspaceSearchFull.SearchTab;
  keywords?: string[];
  tags?: GroupTag[];
};

const getWorkspaceSearchLocation = (data: GetWorkspaceSearchLocation) => {
  return {
    pathname: path.Workspaces.Search,
    search: `?${stringify({
      keywords: data.keywords,
      tab: data.tab,
      tags: data.tags ? JSON.stringify(data.tags) : undefined,
    })}`,
    state: {
      from: currentLocation(),
    },
  };
};

export const getPostLocation = ({ postId }: { postId: number }) => {
  return {
    pathname: `${pathname.POSTS}/${postId}`,
    state: {
      from: currentLocation(),
    },
  };
};

export const getDiscoveryVideoLocation = ({ postId }: { postId: number }) => {
  return {
    pathname: `${pathname.Discovery}/${postId}`,
    state: {
      from: currentLocation(),
    },
  };
};

type GetUnviersalSearchLocation = {
  tab?: IUniversalSearch.SearchPageQueryTab;
  query: string;
};

const getUniversalSearchLocation = (data: GetUnviersalSearchLocation) => {
  const search: Partial<IUniversalSearch.SearchPageQuery> = {
    q: data.query,
    tab: data.tab,
  };

  return {
    pathname: path.UniversalSearch.Root,
    search: `?${stringify(search)}`,
    state: {
      from: currentLocation(),
    },
  };
};

const getCallRequestLocation = ({ callId }: ICallId) => {
  return {
    pathname: `${path.Onboarding.Root}/calls/${callId}/details`,
    state: {
      from: currentLocation(),
    },
  };
};

const getCallRequestAvailabilityLocation = ({ callId }: ICallId) => {
  return {
    pathname: `${path.Onboarding.Root}/calls/${callId}/availability`,
    state: {
      from: currentLocation(),
    },
  };
};

const getCallRequestPaymentLocation = ({ callId }: ICallId) => {
  return {
    pathname: `${path.Onboarding.Root}/calls/${callId}/payout-setup`,
    state: {
      from: currentLocation(),
    },
  };
};

const getCallRequestSchedulingTutorialLocation = ({ callId }: ICallId) => {
  return {
    pathname: `${path.Onboarding.Root}/calls/${callId}/scheduling-tutorial`,
    state: {
      from: currentLocation(),
    },
  };
};

const getConferenceLocation = (conferenceIdentifier: number) => {
  return {
    pathname: `${pathname.CONFERENCE}/${conferenceIdentifier}`,
    state: {
      from: currentLocation(),
    },
  };
};

const getCompanyLocation = (slug: string) => {
  if (!slug) return null;

  return {
    pathname: `${pathname.Companies}/${slug}`,
    state: {
      from: currentLocation(),
    },
  };
};

const getCallLocation = (data: ICallId) => {
  return {
    pathname: `${pathname.Calls}/${data.callId}`,
    state: {
      from: currentLocation(),
    },
  };
};

const getProjectFilePreviewLocation = (data: IProjectId & IWorkspaceFileId) => {
  return {
    pathname: `${pathname.PROJECTS}/${data.projectId}/files/${data.fileId}`,
    state: {
      from: currentLocation(),
    },
  };
};

const getProjectFilesLocation = (data: Pick<Project, 'id'>) => {
  return {
    pathname: `${pathname.PROJECTS}/P${data.id}/files`,
    state: {
      from: currentLocation(),
    },
  };
};

const getProjectTagsLocation = (data: IProjectId) => {
  return {
    pathname: generatePath(path.ProjectTabs.Tags.replace('/:slug', '/:projectId'), { projectId: data.projectId }),
    state: {
      from: currentLocation(),
    },
  };
};

const getProjectTagsOnboardingLocation = (data: IProjectId) => {
  return {
    pathname: generatePath(path.Projects.TagsOnboarding, { projectId: data.projectId }),
    state: {
      from: currentLocation(),
    },
  };
};

const getConferenceCaptureSheetLocation = (data: ICallId & ISurveyId) => {
  return {
    pathname: generatePath(path.Conference.CaptureSheet, { callId: data.callId, surveyId: data.surveyId }),
    state: {
      from: currentLocation(),
    },
  };
};

const getBrandLocation = (data: { brandId: number }) => {
  return {
    pathname: `${pathname.WORKSPACES}/brands/${data.brandId}`,
    state: {
      from: currentLocation(),
    },
  };
};

const getProjectQuantAnalysisLocation = (data: {
  quantAnalysisId: number;
  itemId?: number;
  slug: string;
}) => {
  return {
    pathname: `${pathname.PROJECTS}/${data.slug}/quant-analysis/${data.quantAnalysisId}${data.itemId ? `?analysisId=${data.itemId}` : ''}`,
    state: {
      from: currentLocation(),
    },
  };
};

export const brand = {
  root: getBrandLocation,
};

export const call = {
  cleanup: getCallTranscriptCleanupLocation,
  conference: getConferenceLocation,
  root: getCallLocation,
  transcript: getCallTranscriptLocation,
};

export const company = {
  root: getCompanyLocation,
};

export const compliance = {
  review: {
    call: getComplianceCallReviewLocation,
    message: getComplianceMessageReviewLocation,
    survey: getComplianceSurveyReviewLocation,
  },
};

export const conference = {
  captureSheet: getConferenceCaptureSheetLocation,
};

export const discovery = {
  video: getDiscoveryVideoLocation,
};

export const me = {
  profile: getOwnUserProfileLocation,
};

export const messages = {
  root: getMessageChannelsLocation,
};

export const onboarding = {
  call: {
    availability: getCallRequestAvailabilityLocation,
    details: getCallRequestLocation,
    payment: getCallRequestPaymentLocation,
    schedulingTutorial: getCallRequestSchedulingTutorialLocation,
  },
  project: getProjectOnboardingLocation,
};

export const post = {
  root: getPostLocation,
};

export const project = {
  clone: getProjectSurveyCloningLocation,
  create: getProjectCreationLocation,
  entities: getProjectEntitiesLocation,
  entitiesOnboarding: getProjectEntitiesOnboardingLocation,
  file: getProjectFilePreviewLocation,
  files: getProjectFilesLocation,
  preview: getProjectSurveyPreviewLocation,
  previewComplete: getSurveyPreviewCompleteLocation,
  quantAnalysis: getProjectQuantAnalysisLocation,
  root: getProjectRootLocation,
  rootWithId: getProjectRootLocationWithId,
  search: getProjectSearchLocation,
  summary: getProjectSummaryLocation,
  surveys: getProjectSurveysLocation,
  surveyMonitoring: getProjectSurveyMonitoringLocation,
  surveyEditor: getProjectSurveyEditorLocation,
  surveyNew: getNewProjectSurveyLocation,
  surveyPreview: getSurveyPreviewLocation,
  surveyResponse: getProjectSurveyResponseLocation,
  surveyResponses: getProjectSurveyResponsesLocation,
  surveySettings: getProjectSurveySettingsLocation,
  tags: getProjectTagsLocation,
  tagsOnboarding: getProjectTagsOnboardingLocation,
};

export const search = getSearchLocation;

export const universalsearch = {
  search: getUniversalSearchLocation,
};

export const user = {
  profile: getUserProfileLocation,
};

export const workspaces = {
  brand: getWorkspaceBrandLocation,
  search: getWorkspaceSearchLocation,
  file: getFileLocation,
  folder: getFolderLocation,
  root: getWorkspaceLocation,
  transcript: getWorkspaceTranscriptLocation,
};

export * from './navigation.admin';
export * from './navigation.scheduling';