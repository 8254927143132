import type { CSSProperties } from 'react';
import { memo, useCallback, useMemo } from 'react';
import type { UsePositionerReturn } from '@remirror/react';
import { PositionerPortal } from '@remirror/react';
import { Portal } from '@/components/Portal';

type Props = {
  containerRef?: React.LegacyRef<HTMLDivElement>;
  position: UsePositionerReturn;
  renderOutsideEditor?: boolean;
  positionOverrides?: {
    top?: number;
    left?: number;
  };
} & ChildrenProps;

export const FloatingWrapper = memo(({
  children,
  containerRef,
  position,
  renderOutsideEditor = false,
  positionOverrides = {},
}: Props) => {

  const positionerStyles = useMemo(() => {
    return {
      position: 'absolute',
      minWidth: '1px',
      minHeight: '1px',
      pointerEvents: 'none',
      userSelect: 'none',
      cursor: 'none',
      zIndex: -1,
      top: position.y,
      left: position.x,
      width: position.width,
      height: position.height,
      ...positionOverrides,
    } as CSSProperties;
  }, [
    position.x,
    position.y,
    position.width,
    position.height,
    positionOverrides,
  ]);

  const renderContainer = useCallback(() => {
    if (renderOutsideEditor) {
      return (
        <Portal>
          <div ref={containerRef} />
        </Portal>
      );
    } else {
      return (
        <PositionerPortal>
          <div ref={containerRef} />
        </PositionerPortal>
      );
    }
  }, [
    containerRef,
    renderOutsideEditor,
  ]);

  return (
    <>
      <PositionerPortal>
        <span
          id={'positioner-el'}
          style={positionerStyles}
          ref={position.ref} />
      </PositionerPortal>
      {renderContainer()}
      {children}
    </>
  );
});

export default FloatingWrapper;