import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useRemirrorContext, useDocChanged, useEditorState } from '@remirror/react';
import cuid from 'cuid';
import type { Point } from '@/types';
import { TaggedMomentFormDispatchContext } from '@/containers/Transcript/context.tagged-moment';
import { UnsavedHighlightColor } from '../utils';
import { useChainedCommands } from './useCommands';
import { useHelpers } from './useHelpers';
import { useMapDocPosToTimePos } from './useMapPositions';

type BaseOptions = {
  container: HTMLElement;
  enabled: boolean;
  onHighlightStart?: (e: Point) => void;
};

type EventOptions = {
  isHighlighting: boolean;
  onHighlightEnd: (e: Point) => void;
  onHighlightUpdated: (e: Point) => void;
  onHighlightStart: (e: Point) => void;
};

type Options = BaseOptions & EventOptions;

type OngoingHighlight = {
  start: number;
  id: string;
};

const useGetCoordsFromEvent = () => {

  const { view } = useRemirrorContext({ autoUpdate: true });

  const getCoordsFromEvent = useCallback((e: Point) => {
    const editorRect = view.dom.getBoundingClientRect();

    return view.posAtCoords({
      left: Math.min(Math.max(e.x, editorRect.x), editorRect.x + editorRect.width),
      top: Math.min(Math.max(e.y, editorRect.y), editorRect.y + editorRect.height),
    }).pos;
  }, [view]);

  return getCoordsFromEvent;
};

export const useConfigureTranscriptHighlighting = (opts: BaseOptions) => {

  const container = opts.container ?? document.body;

  const [highlight, setHighlight] = useState<OngoingHighlight>(null);

  const commands = useChainedCommands();
  const helpers = useHelpers();

  const isHighlightFocused = useMemo(() => {
    return helpers.isHighlightFocused();
  }, [helpers]);

  const enabled = useMemo(() => {
    return opts.enabled && !isHighlightFocused;
  }, [isHighlightFocused, opts.enabled]);

  useDocChanged(handler => {
    if (handler.tr) {
      const mapper = handler.tr.mapping;
      const highlights = helpers.getHighlights();

      const mappedHighlights = highlights.map(h => ({
        ...h,
        from: mapper.map(h.from),
        to: mapper.map(h.to),
      }));

      commands.setHighlights(mappedHighlights).run();
    }
  });

  const getCoordsFromEvent = useGetCoordsFromEvent();

  const handleHighlightStart = useCallback((e: Point) => {

    if (!isHighlightFocused) {
      const position = getCoordsFromEvent(e);

      setHighlight({
        id: cuid(),
        start: position,
      });
    }
  }, [
    getCoordsFromEvent,
    isHighlightFocused,
  ]);

  const handleHighlightEnd = useCallback((e: Point) => {

    const position = getCoordsFromEvent(e);

    if (position !== highlight.start) {
      commands.focusHighlight(highlight.id, true).run();
    }

    setHighlight(null);
  }, [
    commands,
    getCoordsFromEvent,
    highlight,
  ]);

  const handleHighlightUpdated = useCallback((e: Point) => {

    const position = getCoordsFromEvent(e);

    if (position !== highlight.start) {
      commands.addHighlight({
        id: highlight.id,
        color: UnsavedHighlightColor,
        dbId: null,
        from: Math.min(highlight.start, position),
        to: Math.max(highlight.start, position),
      }).run();
    } else {
      commands.removeHighlights([highlight.id]).run();
    }
  }, [
    commands,
    getCoordsFromEvent,
    highlight,
  ]);

  useConfigureHighlightEvents({
    container,
    enabled,
    isHighlighting: !!highlight,
    onHighlightStart: handleHighlightStart,
    onHighlightEnd: handleHighlightEnd,
    onHighlightUpdated: handleHighlightUpdated,
  });
};

export const useConfigureTaggedMomentHighlighting = (opts: BaseOptions) => {
  const container = opts.container ?? document.body;

  const [highlight, setHighlight] = useState<OngoingHighlight>(null);
  const dispatch = useContext(TaggedMomentFormDispatchContext);

  const commands = useChainedCommands();
  const helpers = useHelpers();
  const state = useEditorState();

  const getCoordsFromEvent = useGetCoordsFromEvent();
  const mapDocPosToTimePos = useMapDocPosToTimePos();

  const removeOtherHighlights = useCallback(() => {
    const existingHighlights = helpers.getHighlights();
    const highlightsToDelete = existingHighlights.map(m => m.id);

    commands.removeHighlights(highlightsToDelete).run();
  }, [
    commands,
    helpers,
  ]);

  const handleHighlightStart = useCallback((e: Point) => {

    const position = getCoordsFromEvent(e);

    removeOtherHighlights();

    setHighlight({
      id: cuid(),
      start: position,
    });
  }, [
    getCoordsFromEvent,
    removeOtherHighlights,
  ]);

  const handleHighlightEnd = useCallback((e: Point) => {

    const stateHighlight = helpers.getBasicHighlight(highlight.id);

    if (!stateHighlight) {
      dispatch({
        type: 'range-updated',
        payload: {
          value: {
            start: null,
            end: null,
          },
        },
      });
      setHighlight(null);
      return;
    }

    const range = mapDocPosToTimePos({
      from: stateHighlight.from,
      to: stateHighlight.to,
      state,
    });

    dispatch({
      type: 'range-updated',
      payload: {
        value: range,
      },
    });

    setHighlight(null);
  }, [
    dispatch,
    helpers,
    highlight,
    mapDocPosToTimePos,
    state,
  ]);

  const handleHighlightUpdated = useCallback((e: Point) => {

    const position = getCoordsFromEvent(e);

    if (position !== highlight.start) {
      commands.addHighlight({
        id: highlight.id,
        color: UnsavedHighlightColor,
        dbId: null,
        from: Math.min(highlight.start, position),
        to: Math.max(highlight.start, position),
      }).run();
    } else {
      commands.removeHighlights([highlight.id]).run();
    }
  }, [
    commands,
    getCoordsFromEvent,
    highlight,
  ]);

  useConfigureHighlightEvents({
    container,
    enabled: opts.enabled,
    isHighlighting: !!highlight,
    onHighlightStart: handleHighlightStart,
    onHighlightEnd: handleHighlightEnd,
    onHighlightUpdated: handleHighlightUpdated,
  });
};

const useConfigureHighlightEvents = ({
  container,
  enabled,
  isHighlighting,
  onHighlightEnd,
  onHighlightStart,
  onHighlightUpdated,
}: Options) => {

  const handleTouchStart = useCallback((e: TouchEvent) => {

    const point = getTouchPoint(e);

    if (point) {
      onHighlightStart(point);
    }

  }, [onHighlightStart]);

  const handleTouchMove = useCallback((e: TouchEvent) => {

    const point = getTouchPoint(e);

    if (point) {
      onHighlightUpdated(point);
    }

  }, [onHighlightUpdated]);

  const handleTouchEnd = useCallback((e: TouchEvent) => {

    const point = getTouchPoint(e);

    if (point) {
      onHighlightEnd(point);
    }

  }, [onHighlightEnd]);

  const handleMouseDown = useCallback((e: MouseEvent) => {

    if (e.button !== 0) {
      return;
    }

    onHighlightStart(getMousePoint(e));

  }, [onHighlightStart]);

  const handleMouseUp = useCallback((e: MouseEvent) => {

    onHighlightEnd(getMousePoint(e));

  }, [onHighlightEnd]);

  const handleMouseMove = useCallback((e: MouseEvent) => {
    onHighlightUpdated(getMousePoint(e));

  }, [onHighlightUpdated]);

  useEffect(() => {
    if (enabled) {
      container.addEventListener('mousedown', handleMouseDown, { passive: false });
      container.addEventListener('touchstart', handleTouchStart, { passive: false });

      return () => {
        container.removeEventListener('mousedown', handleMouseDown, false);
        container.removeEventListener('touchstart', handleTouchStart, false);
      };
    }
  }, [
    container,
    handleMouseDown,
    handleTouchStart,
    enabled,
  ]);

  useEffect(() => {

    if (isHighlighting) {
      container.addEventListener('mousemove', handleMouseMove, { passive: false });
      document.body.addEventListener('mouseup', handleMouseUp, { passive: false });
      container.addEventListener('touchmove', handleTouchMove, { passive: false });
      document.body.addEventListener('touchend', handleTouchEnd, { passive: false });

      return () => {
        container.removeEventListener('mousemove', handleMouseMove, false);
        document.body.removeEventListener('mouseup', handleMouseUp, false);
        container.removeEventListener('touchmove', handleTouchMove, false);
        document.body.removeEventListener('touchend', handleTouchEnd, false);
      };
    }

  }, [
    container,
    handleMouseUp,
    handleMouseMove,
    handleTouchEnd,
    handleTouchMove,
    isHighlighting,
  ]);

};

function getTouchPoint(e: TouchEvent): Point {
  if (e.changedTouches.length !== 1) {
    return null;
  }

  const touch = e.changedTouches[0];
  return {
    x: touch.clientX,
    y: touch.clientY,
  };
}

function getMousePoint(e: MouseEvent): Point {
  return {
    x: e.x,
    y: e.y,
  };
}