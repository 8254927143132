import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { pathname } from '@consts';
import { roles } from '@utils';
import { useRoleValidator } from '@utils/store';
import { useResolveRouteForNewUser } from './useResolveRouteForNewUser';

const selectIsNew = (state: Store.State) => state.user.meta.isNew;

const useResolveRouteForNoMatch = () => {
  const resolveNewUserRoute = useResolveRouteForNewUser();
  const validateRole = useRoleValidator();
  const isNew = useSelector(selectIsNew);

  return useCallback(() => {

    if (validateRole([ ...roles.client, ...roles.transient ])) {
      return pathname.HOME;
    }

    if (validateRole(roles.consultant)) {
      return isNew
          ? resolveNewUserRoute()
          : pathname.HOME;
    }

    return null;
  }, [
    isNew,
    resolveNewUserRoute,
    validateRole,
  ]);
};

export { useResolveRouteForNoMatch };
export default useResolveRouteForNoMatch;