import { memo } from 'react';

type Props = {
  className?: string;
  size?: number;
};

export const Lightbulb = memo(({ className, size = 24 }: Props) => {
  return (
    <svg
      className={className}
      width={size}
      height={size * 1.453125}
      viewBox="0 0 64 93">
      <g
        id="May-30th"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g
          id="Group-2"
          transform="translate(0.000000, 0.532895)"
          fill="#5C73AD">
          <path
            d="M31.7889491,0 C49.582517,0 64.0070539,14.4245369 64.0070539,32.2181048 C64.0070539,43.229438 55.3430402,53.8556946 53.0045763,56.7359851 C48.713087,62.021821 46.9510888,69 46.9510888,69 L46.9510888,69 L17.0423385,69 C17.0423385,69 14.7442364,62.2580428 12.6958226,59.2636249 C7.98169936,52.3724124 1.79056769e-12,43.9589005 1.79056769e-12,32.2181048 C1.79056769e-12,14.4245369 13.9953811,0 31.7889491,0 Z M27.6677686,10.5387571 C20.765059,11.0267154 10,18.2511509 10,30.8811507 C10,31.9877446 10.4360932,33.6152192 12.991116,33.6152192 C14.8554286,33.6152192 16.0071662,32.0507432 15.9768013,30.1890769 C15.9402464,27.9479051 16.4348334,24.2233839 20.2165138,20.3223271 C23.6541843,16.7761397 27.3252788,16.6266879 29.5726115,16.4247808 C31.7862728,16.2258989 32.9883926,15.8858499 32.9883926,13.335709 C32.9883926,10.5387571 31.0947998,10.2964974 27.6677686,10.5387571 Z"
            id="Combined-Shape" />
          <path
            d="M32.5,91.8671049 C40.5081289,91.8671049 47,84.0768595 47,74.4671049 C47,74.4671049 18,74.4671049 18,74.4671049 C18,84.0768595 24.4918711,91.8671049 32.5,91.8671049 Z"
            id="Oval" />
        </g>
      </g>
    </svg>
  );
});