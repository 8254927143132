import { useCallback, useContext, useMemo, useState } from 'react';
import { format } from 'date-fns/fp';
import { addMinutes, formatDuration, subMinutes } from 'date-fns';
import { ButtonOutlined } from '@presentation';
import { getCurrentTimezone } from '@/utils';
import { Button } from '@/components/Button';
import { Focusable } from '@/components/Focusable';
import { TimePicker } from '@/components/DatePicker';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { useTabViewContext } from '@/components/TabView/hooks';
import { ConflictWarning } from './Booking.ConflictWarning';
import { BookingSegmentContext, FrameContext, SettingsContext } from './Context';
import { FrameKey, TabKey } from './interfaces';
import styles from './style/Booking.Schedule.css';

type Props = unknown;

export const FromAvailability = (props: Props) => {
  const ctx = {
    frame: useContext(FrameContext),
    settings: useContext(SettingsContext),
  };

  const tabview = useTabViewContext();
  const [segment] = useContext(BookingSegmentContext);

  const { last, options } = useMemo(() => {
    const last = getLastRange(segment.end, ctx.settings.conference.defaultDuration);

    return {
      last: last.start,
      options: {
        interval: ctx.settings.conference.defaultDuration,
        maxEndTime: segment.end,
      },
    };
  }, [
    ctx.settings.conference.defaultDuration,
    segment.end,
  ]);

  const [range, setRange] = useState(createRange(segment.start, options));

  const [conflict, setConflict] = useState(false);

  const setTime = useCallback((date: Date) => {
    setRange(createRange(date, options));
  }, [options]);

  const handleSubmit = useCallback(() => {
    ctx.settings.setValue(x => ({ ...x, time: range }));
    ctx.frame.goToFrame({ frame: FrameKey.AttendeeList });
  }, [
    ctx.frame,
    ctx.settings,
    range,
  ]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>

          <div className={styles.row}>
            <div className={styles.field}>
              <div className={styles.label}>Availablity</div>
              <div>{`${formatTime(segment.start)} - ${formatTime(segment.end)}`}</div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.fields}>
              <div className={styles.field}>
                <div className={styles.label}>Date</div>
                <div>{formatDate(segment.start)}</div>
              </div>
              <div className={styles.field}>
                <div className={styles.label}>Start Time</div>
                <Focusable>
                  <div className={styles.datepicker}>
                    <TimePicker
                      dateFormat="hh:mm aa"
                      maxDate={last}
                      minDate={segment.start}
                      maxTime={last}
                      minTime={segment.start}
                      onChange={setTime}
                      renderCustomHeader={null}
                      selected={range.start}
                      showPopperArrow={false}
                      showTimeSelect
                      showTimeSelectOnly
                      timeFormat="hh:mm aa"
                      timeIntervals={5} />
                  </div>
                </Focusable>
              </div>
              <div className={styles.field}>
                <div className={styles.label}>End Time</div>
                <div>{formatTime(range.end)}</div>
              </div>
              <div className={styles.field}>
                <div className={styles.label}>Duration</div>
                <div>{formatSlotDuration(ctx.settings.conference.defaultDuration)}</div>
              </div>
            </div>
          </div>

          <div className={styles.timezone}>
            {`All times displayed in ${getCurrentTimezone()}.`}
          </div>

          <ConflictWarning
            end={range.end}
            onChange={setConflict}
            start={range.start} />

        </div>

        <ButtonSet className={styles.footer}>
          <ButtonOutlined
            className={styles.btn}
            color="silver"
            onClick={() => tabview.jumpTo(TabKey.ViewAvailability)}>
            Cancel
          </ButtonOutlined>
          <Button.Primary
            className={styles.btn}
            onClick={handleSubmit}
            variant="brick">
            Confirm
          </Button.Primary>
        </ButtonSet>
      </div>
    </div>
  );
};

FromAvailability.displayName = 'Schedule.FromAvailability';

function formatSlotDuration(minutes: number) {
  return formatDuration({ minutes }, { format: ['minutes'] });
}

const formatTime = format('h:mm a');
const formatDate = format('MM/d/yyyy');

type Options = {
  interval:   number;
  maxEndTime: Date;
};

function createRange(start: Date, options: Options) {
  const incremented = addMinutes(start, options.interval);

  if (incremented <= options.maxEndTime) {
    return {
      start,
      end: incremented,
    };
  }

  return getLastRange(options.maxEndTime, options.interval);
}

function getLastRange(end: Date, interval: number) {
  return {
    start: subMinutes(end, interval),
    end,
  };
}