import { cx } from '@utils';
import styles from './style/Layout.css';

type Props = {
  children?:  React.ReactNode;
  className?: string;
};

export const Screen = (props: Props) => {
  return (
    <div className={cx(styles.root, props.className)}>
      <div className={styles.wrap}>
        {props.children}
      </div>
    </div>
  );
};

export const Row = (props: Props) => {
  return (
    <div className={cx(styles.row, props.className)}>
      {props.children}
    </div>
  );
};

type HeaderProps = {
  classes?: {
    root?:     string;
    title?:    string;
    subtitle?: string;
  };
  title:     string;
  subtitle?: string;
};

export const Header = ({ classes = {}, ...props }: HeaderProps) => {
  const classnames = {
    root: cx(styles.header, styles.row, classes.root),
    title: cx(styles.title, styles. row, classes.title),
    subtitle: cx(styles.subtitle, styles.row, classes.subtitle),
  };

  return (
    <div className={classnames.root}>
      <div className={classnames.title}>{props.title}</div>
      {props.subtitle && <div className={classnames.subtitle}>{props.subtitle}</div>}
    </div>
  );
};