import { AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as $api from '@api/transcripts';
import { GetTranscriptTagsOverview } from '@api/interfaces/transcripts';

type Data = GetTranscriptTagsOverview.Response;
type Params = ITranscriptId;
type QueryKey = readonly [string, Params];

type Options = UseQueryOptions<Data, AxiosResponse, Data, QueryKey>;

export function useTranscriptTagsOverview(
  data: Params,
  options?: Options
) {

  return useQuery(['', data], ({ queryKey }) => {
    const [_, { transcriptId }] = queryKey;

    return $api.getTranscriptTagsOverview({ transcriptId });

  }, options);
}