import { useCallback, useState } from 'react';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder/hooks';
import { SurveyMatchValidation } from '@/containers/SurveyBuilder.Logic';
import { useSurveyTaggingValidations, useSurveyTaggingItemState } from '../context';
import { TaggingItemValidations } from '../interfaces';
import { validateTaggingItem } from '../validation';

export const useSurveyTaggingActionValidations = () => {
  const ctx = useSurveyTaggingValidations();
  return ctx.action;
};

export const useRunSurveyTaggingValidations = () => {
  const [validations, setValidations] = useState<TaggingItemValidations>({
    action: { success: true },
  });
  const [matchValidation, setMatchValidation] = useState<SurveyMatchValidation>({
    conditions: {},
    rule: { success: true },
  });

  const [item] = useSurveyTaggingItemState();
  const [state] = useSurveyBuilderState();

  const runValidations = useCallback(() => {
    const { success, conditions, rule,  ...rest } = validateTaggingItem(item, state.survey);

    setValidations(rest);
    setMatchValidation({ conditions, rule });
    return success;
  }, [item, state.survey]);

  return {
    validations,
    matchValidation,
    runValidations,
  };
};