import { useCallback, useState } from 'react';
import * as PlatformMessage from '@containers/PlatformMessage/interfaces';
import { useSelectUser } from '@containers/Store';
import { useSendMessage } from '@utils/api';
import { Spinner } from '@/components/ActivityIndicator';
import { Button } from '@/components/Button';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { Header } from '@/components/Modal/Header';
import Modal from '@/components/Modal/Modal.Legacy';
import styles from '@/components/Modal/style/SendMessage.css';
import Toast from '@/components/Toast';
import AsPaidMessage from './AsPaidMessage';

type Props = {
  onClose: () => unknown;
  user:    PlatformMessage.RecipientUser;
  visible: boolean;
};

const SendMessage = (props: Props) => {
  const [body, setBody] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [paid, setPaid] = useState(false);
  const [paymentSetup, setPaymentSetup] = useState<boolean>(false);
  const user = useSelectUser();
  const sendMessage = useSendMessage();

  const name = props.user.profile?.firstName;

  const handlePaidChange = useCallback(() => {
    setPaid(!paid);
  }, [paid, setPaid]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBody(e.target.value);
  }, [setBody]);

  const handleSetupPayment = useCallback(() => {
    setPaymentSetup(true);
  }, [setPaymentSetup]);

  const handleSubmit = useCallback(() => {
    if (!body.trim()) return;

    setSubmitting(true);

    sendMessage({
      body,
      paid,
      userId: props.user.id,
    })
    .then(_ => {

      Toast.alert({
        title: 'Message Sent',
        body: `${name} has received your message. You will be alerted as soon as ${name} responds.`,
      });

      setSubmitting(false);

      props.onClose();
    });
  }, [body, name, paid, props, sendMessage]);

  const visible = !paymentSetup && props.visible;

  return (
    <>
      <Modal
        onClose={props.onClose}
        visible={visible}>
        <div className={styles.wrap}>
          <Header className={styles.header}>
            {`Ask ${name} a Question`}
          </Header>
          <textarea
            className={styles.textarea}
            onChange={handleChange}
            placeholder="Customize the invitation message."
            rows={7}
            name="message"
            value={body} />
          <AsPaidMessage
            paid={paid}
            onChange={handlePaidChange}
            onSetupPayment={handleSetupPayment} />
          <div className={styles.msg}>
            <div className={styles.text}>
              {`We'll let you know when ${name} responds!`}
            </div>
          </div>
          <ButtonSet>
            <Button
              className={styles.btn}
              disabled={submitting}
              color="affirmative"
              onClick={handleSubmit}
              variant="brick">
              {submitting
                ? <Spinner color="var(--pri-01)" />
                : 'Send'}
            </Button>
          </ButtonSet>
        </div>
      </Modal>
    </>
  );
};

export { SendMessage };
export default SendMessage;
