import { useContext } from 'react';
import { ProjectResponseFilterContext, SurveyResponsesFilterDataContext } from '@containers/GroupProject/Context';
import { ProjectSurveyResponsesContext } from '@containers/SurveyResponses/Context';
import { getFilteredUserIds } from '@containers/GroupProject/utils/survey-filtering';
import type { HybridSurveyResponseUserId } from '@/types';

export const useFilteredResponseUsers = () => {
  const filterContext = useContext(ProjectResponseFilterContext);
  const responses = useContext(ProjectSurveyResponsesContext);
  const filterDataContext = useContext(SurveyResponsesFilterDataContext);

  let userIds: HybridSurveyResponseUserId[];
  if (filterContext?.filter && responses) {
    userIds = getFilteredUserIds(filterContext.filter, filterDataContext.allQuestions, filterDataContext.allResponses);
  }

  return userIds;
};