import type { CellProps } from 'react-table';
import { Brand as BrandIcon } from '@/components/icons';
import ClickContainer from './ResultClickContainer';
import type { BrandsTableItem } from './interfaces';
import styles from './style/Cell.Name.css';

export default function Name({ row: { original: data } }: CellProps<BrandsTableItem>) {
  return (
    <ClickContainer className={styles.overflowWrapper} item={data}>
      <div className={styles.root}>
        <BrandIcon
          className={styles.icon} />
        <div className={styles.details}>
          <div className={styles.name}>{data.name}</div>
        </div>
      </div>
    </ClickContainer>
  );
}

export { Name };