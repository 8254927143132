import { AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { CallTranscriptDetails } from '$admin/api/interfaces/calls';
import * as api from '$admin/api/calls';

type Data = CallTranscriptDetails.Response;
type QueryKey = readonly [string, ICallId];

export function useCallTranscriptDetails(
  data: ICallId,
  options?: UseQueryOptions<Data, AxiosResponse, Data, QueryKey>
) {

  return useQuery([AdminTranscriptDetailsKey, data], ({ queryKey }) => {
    const callId = queryKey[1].callId;

    return api.getTranscriptDetails({
      callId,
    });
  }, options);
}

export const AdminTranscriptDetailsKey = 'admin.transcript-details';
