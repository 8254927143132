import { Fragment, useMemo } from 'react';
import type { MatrixSliderQuestion } from '@/types';
import { formatSurveyNumber } from '@utils/survey';
import type { MatrixSliderAnswerProps, MatrixSliderAnswerItemProps } from './interfaces';
import styles from './style/MatrixSlider.css';
import { Rationale } from './Rationale';

const sortItems = (reversed: boolean) => (a: MatrixSliderQuestion.Answer.Item, b: MatrixSliderQuestion.Answer.Item) => {
  return reversed
    ? a.value > b.value ? 1 : -1
    : a.value > b.value ? -1 : 1;
};

const MatrixSliderAnswerItem = ({ item, row, settings }: MatrixSliderAnswerItemProps) => {

  const openEndedRowText = row.metadata.isOpenEnded
    ? ` [${item.row.openEndedValue}]`
    : '';
  const label = `${item.row.value}${openEndedRowText}`;

  return (
    <div className={styles.item}>
      {`${label}: ${formatSurveyNumber(item.value, settings.numberSettings)}`}
    </div>
  );
};

export const MatrixSliderAnswerValue = ({ answer, question, rationale }: MatrixSliderAnswerProps) => {
  const sortedItems = useMemo(() => {
    const isReverse = question.settings.slider.minValue > question.settings.slider.maxValue;
    return answer.items
      .sort(sortItems(isReverse));
  }, [answer.items, question.settings]);

  return (
    <>
      {sortedItems.map(item => {
        const row = question.matrixRows.find(f => f.id === item.row.id);
        return (
          <Fragment key={item.row.id}>
            <MatrixSliderAnswerItem
              item={item}
              row={row}
              settings={question.settings} />
          </Fragment>
        );
      })}
      <Rationale value={rationale} />
    </>
  );
};

export default MatrixSliderAnswerValue;