import { useCallback, useContext } from 'react';
import { MedicalBackgroundContext, MedicalBackgroundContactContext } from '@containers/MedicalOnboarding/Context';
import { cx } from '@utils';
import { Select } from '$admin/components/Select';
import styles from './style/Member.MedicalProfile.css';

type Props = {
  classes?: {
    root?: string;
    placeholder?: string;
  };
  items: Descriptor[];
};

export const Market = ({ classes = {}, ...props }: Props) => {
  const ctx = useContext(MedicalBackgroundContext);
  const contact = useContext(MedicalBackgroundContactContext);

  const handleChange = useCallback((value: number) => {
    ctx.mutation.mutate({
      contact: {
        ...contact.form,
        market: value,
        timezone: contact.form.timezone || null,
      },
    }, {
      onSuccess: contact.rehydrate,
    });

    contact.setValue({
      market: value,
    });
  }, [
    ctx.mutation,
    contact,
  ]);

  const classnames = {
    root: cx(styles.input, classes.root, {
      [classes.placeholder || styles.placeholder]: !contact.form.market,
    }),
  };

  const value = !props.items?.length
      ? ''
      : contact.form.market ?? '';

  return (
    <Select
      classes={classnames}
      options={props.items || []}
      onChange={e => handleChange(+e.target.value)}
      placeholder="None"
      value={value as number} />
  );
};

Market.displayName = 'Member.Experience.Input.Market';