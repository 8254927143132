import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getLocationFor } from '@utils';
import CloneProjectPrompt from '../CloneProjectPrompt';
import { CloneProjectPromptProps } from '../interfaces';

type Props =
  Pick<CloneProjectPromptProps, 'project'>;

export const useCloneProjectPrompt = ({ project }: Props) => {
  const [visible, setVisibility] = useState(false);
  const toggleVisibility = useCallback(() => setVisibility(!visible), [visible]);
  const history = useHistory();

  const handleConfirm = useCallback(() => {
    toggleVisibility();
    history.push(getLocationFor.project.clone(project));
  }, [
    history,
    project,
    toggleVisibility,
  ]);

  const Modal = useCallback(() => (
    <CloneProjectPrompt
      onConfirm={handleConfirm}
      onClose={toggleVisibility}
      project={project}
      visible={visible} />
  ), [
    handleConfirm,
    project,
    visible,
    toggleVisibility,
  ]);

  return [toggleVisibility, Modal] as const;
};

export default useCloneProjectPrompt;