import { useCallback } from 'react';
import { NumberInput, OnNumberInputValueChange } from '@/components/Input';
import styles from './style/MatrixSlider.Settings.Slider.css';

type Props = {
  onChange: (value: number) => void;
  value: number;
};

export const TotalsNumberInput = ({ onChange, value }: Props) => {

  const handleChange: OnNumberInputValueChange = useCallback(values => {
    onChange(values.floatValue);
  }, [onChange]);

  return (
    <div className={styles.input}>
      <NumberInput
        allowNegative={false}
        onValueChange={handleChange}
        placeholder="-"
        value={value} />
    </div>
  );
};