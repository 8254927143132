import { memo } from 'react';
import { PlusCircle } from 'react-feather';
import styles from './style/Profile.Form.AddEntryButton.css';

type Props = {
  onClick: () => unknown;
};

export const AddEntryButton = memo(({
  onClick,
}: Props) => {
  return (
    <div className={styles.btn}>
      <PlusCircle
        className={styles.plus}
        onClick={onClick} />
    </div>
  );
});

export default AddEntryButton;