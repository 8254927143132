import { useContext } from 'react';
import { CallReviewDispatchContext } from '@containers/ComplianceReview/Context';

const useCallReviewDispatch = () => {
  const state = useContext(CallReviewDispatchContext);

  return state;
};

export { useCallReviewDispatch };
export default useCallReviewDispatch;