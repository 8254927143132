import { useCallback, useMemo } from 'react';
import { useSurveyBuilderState, useHasUncommittedAIEChanges, useSubmitSurveyDraft } from '@containers/SurveyBuilder/hooks';
import { SurveyType } from '@/enums';
import { SurveyAlternateImagesBuilderSaveContext, useSurveyAlternateImagesBuilderItemContext } from './context';
import { useUploadImages } from './hooks/useUploadImages';

type Props =
  ChildrenProps;

export const AlternateImagesBuilderSaveContainer = (props: Props) => {

  const [state, dispatch] = useSurveyBuilderState();
  const uploadImages = useUploadImages();
  const item = useSurveyAlternateImagesBuilderItemContext();

  const submitDraft = useSubmitSurveyDraft();

  const hasUncommittedChanges = useHasUncommittedAIEChanges();

  const canSave = useMemo<ValidationResult>(() => {
    function validationFailure(message: string) {
      return {
        val: false,
        message,
      };
    }

    function validationSuccess() { return { val: true }; }

    const hasChanges = hasUncommittedChanges();
    if (!hasChanges) return validationFailure(null);

    if (state.survey.surveyDetails?.typeId === SurveyType.Module) return validationSuccess();

    if (item.groups.some(g => !g.name)) return validationFailure(`Some concepts don't have a name`);

    if (item.groups.length === 0) {
      return validationFailure(`There must be at least one concept`);
    }

    if (!item.groups.every(g => g.entries.length > 0)) {
      return validationFailure(`All concepts must have at least one image`);
    }

    if (item.groups.some(g => g.entries.some(e => !e.name))) {
      return validationFailure(`All images must have names`);
    }

    return validationSuccess();
  }, [hasUncommittedChanges, item.groups, state.survey.surveyDetails?.typeId]);

  const handleSave = useCallback(async () => {

    await uploadImages();

    dispatch({
      type: 'toggle-editing',
      itemIdentifier: null,
    });

    submitDraft();
  }, [
    dispatch,
    submitDraft,
    uploadImages,
  ]);

  const value = {
    canSave,
    onSave: handleSave,
  };

  return (
    <>
      <SurveyAlternateImagesBuilderSaveContext.Provider value={value}>
        {props.children}
      </SurveyAlternateImagesBuilderSaveContext.Provider>
    </>
  );
};

type ValidationResult = {
  val: boolean;
  message?: string;
};