import { useContext } from 'react';
import { AccountProfileFieldsPopulationContext } from '@containers/AccountProfile/Context';

type Params = unknown;

const useAccountProfilePopulationState = () => {
  const [populated] = useContext(AccountProfileFieldsPopulationContext) ?? [];

  return populated;
};

export { useAccountProfilePopulationState };
export default useAccountProfilePopulationState;