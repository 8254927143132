import { memo } from 'react';
import { Briefcase } from 'react-feather';
import { getLocationFor } from '@utils';
import { Notifications } from '@/types';
import { NotificationAction, NotificationActions } from './Notification.Actions';
import Notification from './Notification.Base';

type Props = {
  item: Notifications.ProjectRequested.Item;
};

export const ProjectRequestNotification = memo(({ item }: Props) => {

  const location = getLocationFor.onboarding.project({ projectId: item.payload.projectId });

  return (
    <Notification
      Icon={Briefcase}
      text={item.text}
      title={item.title}>
      <NotificationActions>
        <NotificationAction
          to={location}
          title="Review" />
      </NotificationActions>
    </Notification>
  );
});

export default ProjectRequestNotification;