import cuid from 'cuid';
import type { OptionQuestionType, RowsQuestionType } from '@enums/Survey';
import {
  SurveyOptionType,
} from '@enums/Survey';
import type { OptionMetadata, RowMetadata, SurveyQuestion, SurveyQuestionMatrixRow, SurveyQuestionMetadata, SurveyQuestionOption, SurveyRichText, SurveyAIEEntry } from '@/types/survey';
import type { OptionsQuestion, RowsQuestion } from '@containers/Survey/utils/questions';
import { hasOptions, hasRows } from '@containers/Survey/utils/questions';
import { NodeType } from '@/types/rich-text';
import type { SurveyCondition } from '@/types/survey.logic.condition';
import type { SurveyBuilder, SurveyQuestionsBuilder } from '../interfaces';
import {
  generateDefaultOptionMetadata,
  generateDefaultRowMetadata,
  generateNewQuestion,
  getInitialMatrixRows,
  getInitialQuestionOptions,
} from './defaults';

type State = SurveyBuilder.State['survey']['questions'];

export function questionCopied(state: SurveyBuilder.State, action: SurveyQuestionsBuilder.QuestionCopied.Action): State {
  const sourceQuestion = state.survey.questions.find(q => q.base.identifier === action.payload.sourceQuestionIdentifier);

  const newQuestion = {
    ...sourceQuestion,
    base: {
      id: null,
      identifier: action.payload.targetQuestionIdentifier,
    },
    ordinal: state.survey.questions.length + 1,
  };

  if (sourceQuestion.options?.length) {
    newQuestion.options = sourceQuestion.options.map<SurveyQuestionOption>((o: SurveyQuestionOption) => ({
      ...o,
      base: {
        id: null,
        identifier: cuid(),
      },
      metadata: {
        ...o.metadata,
        linked: {
          ...o.metadata.linked,
          question: { identifier: action.payload.targetQuestionIdentifier },
        },
      },
    }));

  }

  if (sourceQuestion.matrixRows?.length) {
    newQuestion.matrixRows = (sourceQuestion.matrixRows.map<SurveyQuestionMatrixRow>((r: SurveyQuestionMatrixRow) => ({
      ...r,
      base: {
        id: null,
        identifier: cuid(),
      },
      metadata: {
        ...r.metadata,
        linked: {
          ...r.metadata.linked,
          question: { identifier: action.payload.targetQuestionIdentifier },
        },
      },
    })) as SurveyQuestion['matrixRows']);
  }

  return state.survey.questions.concat(newQuestion);
}

export function questionOptionsCopied(state: SurveyBuilder.State, action: SurveyQuestionsBuilder.QuestionOptionsCopied.Action): State {

  const sourceQuestion = state.survey.questions.find(f => f.base.identifier === action.payload.sourceQuestionIdentifier);

  return state.survey.questions.reduce<State>((acc, x) => {
    if (x.base.identifier === action.payload.targetQuestionIdentifier && hasOptions(x)) {
      if (action.payload.source === 'options') {
        return acc.concat({
          ...x,
          options: sourceQuestion.options.map((option: SurveyQuestionOption) => {
            return {
              id: null,
              base: {
                id: null,
                identifier: cuid(),
              },
              conditions: [],
              ordinal: option.ordinal,
              value: option.value,
              metadata: resolveOptionMetadata(option.base.identifier, x.typeId),
              type: SurveyOptionType.Default,
            };
          }),
          metadata: resolveQuestionMetadata(x.metadata),
        } as OptionsQuestion);
      } else if (action.payload.source === 'rows') {
        return acc.concat({
          ...x,
          options: sourceQuestion.matrixRows.map((row: SurveyQuestionMatrixRow) => {
            return {
              id: null,
              base: {
                id: null,
                identifier: cuid(),
              },
              conditions: [],
              ordinal: row.ordinal,
              value: row.value,
              metadata: resolveOptionMetadata(row.base.identifier, x.typeId),
              type: SurveyOptionType.Default,
            };
          }),
          metadata: resolveQuestionMetadata(x.metadata),
        } as OptionsQuestion);
      }
    }

    return acc.concat(x);
  }, []);

  function resolveQuestionMetadata(metadata: SurveyQuestionMetadata): SurveyQuestionMetadata {
    return {
      ...metadata,
      linked: {
        ...(metadata.linked || {}),
        options: {
          question: { identifier: action.payload.sourceQuestionIdentifier },
          source: action.payload.source,
        },
      },
    };
  }

  function resolveOptionMetadata<T extends OptionQuestionType = OptionQuestionType>(linkedIdentifier: string, typeId: T): OptionMetadata<T> {
    const base = generateDefaultOptionMetadata(typeId);
    const field = {
      options: 'option',
      rows: 'row',
    }[action.payload.source];

    return {
      ...base,
      canDelete: false,
      canModifyValue: false,
      linked: {
        question: { identifier: action.payload.sourceQuestionIdentifier },
        [field]: { identifier: linkedIdentifier },
      },
    };
  }
}

export function questionOptionsCopiedFromAIE(state: SurveyBuilder.State, action: SurveyQuestionsBuilder.QuestionOptionsCopiedFromAIE.Action): State {
  const sourceExercise = state.survey.alternateImageExercises.find(f => f.identifier === action.payload.sourceExerciseIdentifier);

  let entries: SurveyAIEEntry[] = [];
  let conditionBuilder = (entry: SurveyAIEEntry) => [] as SurveyCondition[];
  if (action.payload.sourceExerciseGroupIdentifier === null) {
    entries = sourceExercise.groups.flatMap(g => g.entries);
    conditionBuilder = (entry: SurveyAIEEntry) => {
      //TODO: attach a condition to only show if the image was shown. Need to build out that condition logic
      return [];
    };
  } else {
    const group = sourceExercise.groups.find(g => g.identifier === action.payload.sourceExerciseGroupIdentifier);

    entries = group.entries;
  }

  return state.survey.questions.reduce<State>((acc, x) => {
    if (x.base.identifier === action.payload.targetQuestionIdentifier && hasOptions(x)) {
      return acc.concat({
        ...x,
        options: entries.map((e, i) => {
          return {
            id: null,
            base: {
              id: null,
              identifier: cuid(),
            },
            conditions: conditionBuilder(e),
            ordinal: i + 1,
            value: buildRichTextImage(e.imageUrl),
            metadata: resolveOptionMetadata(e.identifier, x.typeId),
            type: SurveyOptionType.Default,
          };
        }),
        metadata: resolveQuestionMetadata(x.metadata),
      } as OptionsQuestion);
    }

    return acc.concat(x);
  }, []);

  function buildRichTextImage(imageUrl: string): SurveyRichText.RichTextValue {
    return {
      type: NodeType.Doc,
      content: [
        {
          type: NodeType.Paragraph,
          content: [
            {
              type: NodeType.Image,
              attrs: {
                src: imageUrl,
              },
            },
          ],
        },
      ],
    };
  }

  function resolveQuestionMetadata(metadata: SurveyQuestionMetadata): SurveyQuestionMetadata {
    return {
      ...metadata,
      linked: {
        ...(metadata.linked || {}),
        options: {
          alternateImageExercise: {
            identifier: action.payload.sourceExerciseIdentifier,
          },
        },
      },
    };
  }

  function resolveOptionMetadata<T extends OptionQuestionType = OptionQuestionType>(linkedIdentifier: string, typeId: T): OptionMetadata<T> {
    const base = generateDefaultOptionMetadata(typeId);

    return {
      ...base,
      canDelete: false,
      canModifyValue: false,
      linked: {
        alternateImageExercise: { identifer: action.payload.sourceExerciseIdentifier },
        group: { identifier: action.payload.sourceExerciseGroupIdentifier },
        entry: { identifier: linkedIdentifier },
      },
    };
  }
}

export function questionRowsCopied(state: State, action: SurveyQuestionsBuilder.QuestionRowsCopied.Action): State {

  const sourceQuestion = state.find(f => f.base.identifier === action.payload.sourceQuestionIdentifier);

  return state.reduce<State>((acc, x) => {
    if (x.base.identifier === action.payload.targetQuestionIdentifier && hasRows(x)) {
      if (action.payload.source === 'options') {
        return acc.concat({
          ...x,
          matrixRows: sourceQuestion.options.map((option: SurveyQuestionOption) => {
            const metadata = resolveRowMetadata(option.base.identifier, x.typeId);
            return {
              id: null,
              base: {
                id: null,
                identifier: cuid(),
              },
              conditions: [],
              ordinal: option.ordinal,
              value: option.value,
              metadata,
            };
          }),
          metadata: resolveQuestionMetadata(x.metadata),
        } as RowsQuestion);
      } else if (action.payload.source === 'rows') {
        return acc.concat({
          ...x,
          matrixRows: sourceQuestion.matrixRows.map((row: SurveyQuestionMatrixRow) => {
            return {
              id: null,
              base: {
                id: null,
                identifier: cuid(),
              },
              conditions: [],
              metadata: resolveRowMetadata(row.base.identifier, x.typeId),
              ordinal: row.ordinal,
              value: row.value,
            };
          }),
          metadata: resolveQuestionMetadata(x.metadata),
        } as RowsQuestion);
      }

    }

    return acc.concat(x);
  }, []);

  function resolveRowMetadata<T extends RowsQuestionType = RowsQuestionType>(linkedIdentifier: string, typeId: T): RowMetadata<T> {
    const base = generateDefaultRowMetadata(typeId);
    const field = {
      options: 'option',
      rows: 'row',
    }[action.payload.source];

    return {
      ...base,
      canDelete: false,
      canModifyValue: false,
      linked: {
        question: { identifier: action.payload.sourceQuestionIdentifier },
        [field]: { identifier: linkedIdentifier },
      },
    };
  }

  function resolveQuestionMetadata(metadata: SurveyQuestionMetadata): SurveyQuestionMetadata {
    return {
      ...metadata,
      linked: {
        ...(metadata.linked || {}),
        rows: {
          question: { identifier: action.payload.sourceQuestionIdentifier },
          source: action.payload.source,
        },
      },
    };
  }
}

export function copiedOptionsRemoved(state: State, action: SurveyQuestionsBuilder.CopiedOptionsRemoved.Action): State {

  return state.reduce<State>((acc, x) => {

    if (x.base.identifier === action.payload.questionIdentifier && hasOptions(x)) {
      return acc.concat({
        ...x,
        metadata: {
          ...x.metadata,
          linked: {
            ...(x.metadata.linked || {}),
            options: null,
          },
        },
        options: getInitialQuestionOptions(x.typeId),
      } as OptionsQuestion);
    }

    return acc.concat(x);
  }, []);
}

export function copiedRowsRemoved(state: State, action: SurveyQuestionsBuilder.CopiedRowsRemoved.Action): State {

  return state.reduce<State>((acc, x) => {

    if (x.base.identifier === action.payload.questionIdentifier && hasRows(x)) {
      return acc.concat({
        ...x,
        metadata: {
          ...x.metadata,
          linked: {
            ...(x.metadata.linked || {}),
            rows: null,
          },
        },
        matrixRows: getInitialMatrixRows(x.typeId),
      } as RowsQuestion);
    }

    return acc.concat(x);
  }, []);
}