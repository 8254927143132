import { useEnumerateTopicNews } from '@utils/api';
import { TopicNewsContext } from './Context';

type Props = {
  enabled: boolean;
  slug: string;
} & ChildrenProps;

export function TopicNewsContainer({ enabled, children, slug }: Props) {
  const query = useEnumerateTopicNews(slug, {
    getNextPageParam: lastPage => lastPage.pagination.next,
    refetchOnWindowFocus: false,
    enabled,
  });

  const value = {
    query,
  };

  return (
    <TopicNewsContext.Provider value={value}>
      {children}
    </TopicNewsContext.Provider>
  );
}