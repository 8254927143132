import axios from 'axios';
import { pathname } from '@consts';
import * as $session from '@services/auth/session';
import { history } from '@services/history';
import { logConnectionRefused, logError, logRequest } from './console';
import type { XHR } from './interfaces';

export const xhr = axios.create({
  baseURL: process.env.BACKEND_BASE_URL,
});

const RequiresCSRFToken = new Set(['patch', 'post', 'put', 'delete']);

xhr.interceptors.request.use(logRequest);

xhr.interceptors.request.use(request => {
  const base = new URL(process.env.BACKEND_BASE_URL);
  const conf = new URL(request.baseURL);

  // not sure if a necessary check, since cookies are linked to the domain
  if (conf.hostname === base.hostname) {
    request.withCredentials = true;
  } else {
    console.log(`Hostname mismatch, will not attach cookies to request.`);
  }

  if (RequiresCSRFToken.has(request.method)) {
    request.headers.set('X-CSRF-Token', $session.getCSRF());
  }

  return request;
});

xhr.interceptors.response.use(
  (res: XHR.Response) => res,
  (e: XHR.Error) => {

    if (!e.response) {
      logConnectionRefused();
      e.offline = true;

      return Promise.reject(e);
    }

    logError(e);

    if ([401, 403].includes(e.response.status)) {
      // NOTE: need a better solution as doing this loses returnUrl for post login redirect
      return history.replace(pathname.HOME);
    } else {
      return Promise.reject(e.response);
    }

  }
);