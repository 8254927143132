import { memo } from 'react';
import { FileIconProps } from './interfaces';

export const Doc = memo(({
  className,
  size = 24,
}: FileIconProps) => {

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 25">
      <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
        <g id="Group-14" transform="translate(4.000000, 2.000000)" stroke="#3387FF" strokeWidth="2">
          <g id="Group-3" strokeLinejoin="round">
            <g id="Group-8">
              <g id="Group-6">
                <path d="M10,0 L2,0 C0.8954305,0 0,0.8954305 0,2 L0,18 C0,19.1045695 0.8954305,20 2,20 L14,20 C15.1045695,20 16,19.1045695 16,18 L16,6 L10,0 Z" id="Path" />
              </g>
            </g>
          </g>
          <line x1="4" y1="7.6370985" x2="9" y2="7.6370985" id="Path-4" />
          <line x1="4" y1="11.5" x2="12" y2="11.5" id="Path-4" />
          <line x1="4" y1="15.5" x2="12" y2="15.5" id="Path-4" />
        </g>
      </g>
    </svg>
  );
});

export default Doc;