import { Link } from 'react-router-dom';
import { CellItem } from '@containers/Group.Contacts/interfaces';
import { path } from '@consts';
import { slugify } from '@utils';
import styles from './style/Table.css';

type Props = CellItem;

export const Cell = (props: Props) => {
  const name = `${props.row.original.firstName} ${props.row.original.lastName}`;

  const slug = slugify({
    id: props.row.original.id,
    name,
  });

  const location = `${path.Contacts.Root}/${slug}`;

  return (
    <div
      className={styles.trunc}
      title={name}>
      <Link to={location}>
        {name}
      </Link>
    </div>
  );
};

Cell.displayName = 'Column.Name.Cell';