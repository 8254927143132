import { useCallback, memo, useMemo } from 'react';
import CameraOnIcon from '@mui/icons-material/Videocam';
import CameraOffIcon from '@mui/icons-material/VideocamOff';
import { cx } from '@utils';
import { useProvidedDevices } from '@containers/DeviceProvider';
import { Select, SelectOptionProps } from '@/components/Select';
import styles from './style/Camera.css';

type Props = {
  className?: string;
  value: boolean;
  source: string;
  disabled?: boolean;
  visible?: boolean;
  onToggleCamera: (enabled: boolean) => void;
  onChangeCameraSource: (device: MediaDeviceInfo) => void;
};

export const Camera = memo(({ className, value: hasCamera, source, onToggleCamera, onChangeCameraSource, visible = false, disabled = false }: Props) => {
  const devices = useProvidedDevices();

  const options = useMemo(() => {
    const deviceOptions = devices.videoInputDevices.map(d => ({
      id: d.deviceId,
      name: `${d.label} - ${hasCamera ? 'Camera on' : 'Camera off'} on Join`,
    }));

    return deviceOptions;
    //return [{ id: '', name: 'Default' }, ...deviceOptions];
  }, [devices.videoInputDevices, hasCamera]);

  const selectedOption = useMemo(() => options.find(o => o.id === (source ?? 'default')) ?? options[0], [options, source]);

  const DeviceSelector = useCallback(() => {
    if (disabled) {
      return (
        <div className={styles.optionDisabled}>
          {selectedOption?.name}
        </div>
      );
    } else if (devices.videoErrorState) {
      return (
        <div className={styles.errorMessage}>
          {devices.videoErrorState.prettyMsg}
        </div>
      );
    } else if (devices.videoLoading) {
      return (
        <div className={styles.optionDisabled}>
          Waiting for camera permissions from browser...
        </div>
      );
    } else if (devices.videoInputDevices.length) {
      return (
        <Select
          className={styles.select}
          value={selectedOption?.name}
          getItemValue={x => x.name}
          onSelect={v => { onChangeCameraSource(devices.videoInputDevices.find(d => d.deviceId === v.id)); }}
          options={options} />
      );
    } else {
      return (
        <div>No devices available. Check brower permissions.</div>
      );
    }
  }, [disabled, devices.videoInputDevices, devices.videoErrorState, onChangeCameraSource, devices.videoLoading, options, selectedOption]);

  if (!visible) return null;

  return (
    <div className={cx(className)}>
      <div className={styles.header}>
        <div className={styles.optionLabel}>Camera</div>
        <div className={cx(styles.camera, { [styles.off]: !hasCamera })} onClick={() => onToggleCamera(!hasCamera)}>
          {hasCamera ? <CameraOnIcon /> : <CameraOffIcon />}
        </div>
      </div>
      <div>
        <DeviceSelector />
      </div>
    </div>
  );
});