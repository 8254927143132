import { useMemo, useCallback } from 'react';
import { ButtonOutlined, ButtonPulsate } from '@presentation';
import { cx } from '@utils';
import { useRefreshSummariesContext } from '@containers/ConferenceTag.Summary';
import { useProjectResponseGridModal } from '@/components/Project.ConferenceTags.Overview';
import { RefreshSummaries } from '@/components/ConferenceTag.Summary';
import styles from './style/Insights.css';

type Props = {
  canGenerateSummary: boolean;
  hasData: boolean;
} & IProjectId;

export const Actions = (props: Props) => {

  const { refresh, ...refreshCtx } = useRefreshSummariesContext();

  const isRefreshing = useMemo(() => {
    return refreshCtx.isRefreshing && !refreshCtx.tagRefreshing;
  }, [
    refreshCtx.isRefreshing,
    refreshCtx.tagRefreshing,
  ]);

  const handleRefresh = useCallback(() => {
    return refresh();
  }, [refresh]);

  const generateDisabled = !props.canGenerateSummary || isRefreshing;

  return (
    <div className={styles.actions}>
      {!props.hasData && (
        <ButtonPulsate
          animate={false}
          className={styles.action}
          color="purple"
          disabled={generateDisabled}
          onClick={handleRefresh}
          rounded={false}>
          Auto-Generate
        </ButtonPulsate>
      )}
      {props.hasData && (
        <RefreshSummaries className={styles.refresh} />
      )}
      {props.hasData && (
        <Export projectId={props.projectId} />
      )}
    </div>
  );
};

Actions.displayName = 'Insights.Actions';

const Export = (props: IProjectId) => {
  const [toggleResponseGridModal, ResponseGridModal] = useProjectResponseGridModal();
  return (
    <>
      <ButtonOutlined
        className={cx(styles.action, styles.export)}
        color="silver"
        fontWeight="bold"
        onClick={toggleResponseGridModal}>
        Export
      </ButtonOutlined>
      <ResponseGridModal projectId={props.projectId} />
    </>
  );
};

Export.displayName = 'Insights.Actions.Export';