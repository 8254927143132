import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import { useSelectUser } from '@containers/Store/hooks/useSelectUser';

const useUpdateUserProfileReattestation = () => {

  const me = useSelectUser();
  const dispatch = useDispatch();

  const logProfileUpdate = useCallback(() => {

    return api.profiles.logProfileUpdate({
      userId: me.id,
    })
    .then(_ => {

      const action = actions.platformAlertChanged({
        displayProfileReattestation: false,
      });

      dispatch(action);

    });

  }, [
    dispatch,
    me.id,
  ]);

  return logProfileUpdate;

};

export { useUpdateUserProfileReattestation };
export default useUpdateUserProfileReattestation;