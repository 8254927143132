import { useContext } from 'react';
import { useFetchUniversalSearchBar } from '@utils/api';
import { useDebounceValue } from '@utils';
import { QueryContext, ResultsContext } from './Context';

export function Results({ children }: ChildrenProps) {
  const { query } = useContext(QueryContext);
  const debouncedValue = useDebounceValue<string>(query, 50);

  const fetch = useFetchUniversalSearchBar(debouncedValue, {
    enabled: !!debouncedValue?.length,
    refetchOnWindowFocus: false,
  });

  return (
    <ResultsContext.Provider value={fetch}>
      {children}
    </ResultsContext.Provider>
  );
}