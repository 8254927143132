import { useCallback } from 'react';
import type { SurveyAIEGroup } from '@/types';
import { useSurveyAlternateImagesBuilderItemContext } from '@containers/SurveyBuilder.AIE';
import { ImageInput, DragInputComponent } from '@/components/ImageInput';
import Toast from '@/components/Toast';
import { Spinner } from '@/components/ActivityIndicator';
import { useAddImages, useUploadAIEAttachmentMutation } from './hooks';

type Props = {
  group: SurveyAIEGroup;
};

export const AddEntry = (props: Props) => {
  const item = useSurveyAlternateImagesBuilderItemContext();

  const addImages = useAddImages({ exerciseIdentifier: item.identifier, groupIdentifier: props.group.identifier });
  const uploadAttachmentMutation = useUploadAIEAttachmentMutation({ exerciseIdentifier: item.identifier, groupIdentifier: props.group.identifier });

  const onInputChange = useCallback((fileList: FileList) => {
    const files = new Array(...fileList);

    const pdfFiles = files.filter(f => f.name.endsWith('.pdf'));

    if (pdfFiles.length > 0) {
      if (pdfFiles.length !== 1) {
        Toast.error({
          title: 'Uploading multiple PDFs at a time is not supported right now',
        });
      } else {
        uploadAttachmentMutation.mutate({
          blob: pdfFiles[0],
        });
      }
    } else {
      addImages(fileList);
    }
  }, [addImages, uploadAttachmentMutation]);

  if (uploadAttachmentMutation.isLoading) {
    return <Spinner size={40} />;
  }

  return (
    <div>
      <ImageInput
        inputProps={{
          multiple: true,
          accept: 'image/*,.pdf',
        }}
        InputComponent={ImageInputComponent}
        onChange={e => onInputChange(e.target.files)} />
    </div>
  );
};

const ImageInputComponent = () => {
  return <DragInputComponent text='Upload Stimuli' />;
};