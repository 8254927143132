import qs from 'query-string';
import http, { transformers } from '@services/http';
import { nullableDate } from '@/utils';
import * as xformAdmin from './transformers';
import type { Calls } from './interfaces';

export * as bookings from './calls.bookings';

export const fetchCall = (data: Calls.FetchCall.Request) => {
  return http.get<Calls.FetchCall.Response>(`/admin/calls/${data.callId}`);
};

export const fetchCalls = (data: Calls.FetchCalls.Request): Promise<Calls.FetchCalls.Response> => {
  return http.get(`/admin/calls?${qs.stringify(data, { arrayFormat: 'bracket' })}`)
    .then((data: Calls.FetchCalls.Response<string>) => ({
      items: data.items.map(xformAdmin.calls.transformCallItem),
      metadata: data.metadata,
      filters: data.filters,
      pagination: data.pagination,
    }));
};

export const markCallComplete = (data: Calls.MarkCallComplete.Request): Promise<Calls.MarkCallComplete.Response> => {
  return http.post(`/admin/calls/${data.callId}/mark-complete`, {
    callId: data.callId,
  });
};

export const updateAccess = (data: Calls.UpdateAccess.Request) => {
  return http.put<Calls.UpdateAccess.Response>(`/admin/calls/${data.callId}/access`, data);
};
/*
export const rejectCall = (data: Calls.RejectCall.Request) => {
  const { callId, notifyExpert } = data;
  return http.post(`/admin/calls/${callId}/reject`, { notifyExpert });
};
*/
export const updateScreeningStatus = (data: Calls.UpdateScreeningStatus.Request): Promise<Calls.UpdateScreeningStatus.Response> => {
  return http.patch(`/admin/calls/${data.callId}/screening-status`, data);
};

export const updateCallPricing = ({ callId, pricing }: Calls.UpdateCallPricing.Request): Promise<Calls.UpdateCallPricing.Response> => {
  return http.patch(`/admin/calls/${callId}/pricing`, pricing);
};

export const getRecordingUrl = ({ callId, ...data }: Calls.GetRecordingUrl.Request): Promise<Calls.GetRecordingUrl.Response> => {
  return http.get(`/admin/calls/${callId}/audio?${qs.stringify(data)}`);
};

export const getVideoUrl = ({ callId, ...data }: Calls.GetVideoUrl.Request): Promise<Calls.GetVideoUrl.Response> => {
  return http.get(`/admin/calls/${callId}/video?${qs.stringify(data)}`);
};
/*
export const updateCall = ({ callId, ...data }: Calls.UpdateCall.Request): Promise<Calls.UpdateCall.Response> => {
  return http.patch(`/admin/calls/${callId}`, data);
};
*/

export const getTranscriptDetails = ({ callId }: Calls.CallTranscriptDetails.Request): Promise<Calls.CallTranscriptDetails.Response> => {
  return http.get<Calls.CallTranscriptDetails.Response<string>>(`/admin/calls/${callId}/transcript-details`)
    .then(data => ({
      ...data,
      call: {
        ...data.call,
        approvedOn: nullableDate(data.call.approvedOn),
        createdAt: nullableDate(data.call.createdAt),
        screenedOn: nullableDate(data.call.screenedOn),
        timeEnd: nullableDate(data.call.timeEnd),
        timeStart: nullableDate(data.call.timeStart),
      },
    }));
};

export const getCallEmails = ({ callId }: Calls.CallEmails.Request) => {
  return http.get<Calls.CallEmails.Response>(`/admin/calls/${callId}/emails`, { transformResponse: transformers.transformDates });
};