import * as enums from '@enums';
import type { Project, ProjectParent } from '@/types';
import { ProjectType } from '@enums';

export function canArchive(item: Pick<Project, 'projectStatusId'>) {
  const statuses = [
    enums.ProjectStatus.Active,
  ];

  return statuses.includes(item.projectStatusId);
}

export function canActivate(item: Pick<Project, 'projectStatusId'>) {
  const statuses = [
    enums.ProjectStatus.Archived,
  ];

  return statuses.includes(item.projectStatusId);
}

export function canManage(item: Pick<Project, 'projectStatusId'>) {
  const statuses = [
    enums.ProjectStatus.Active,
    enums.ProjectStatus.Draft,
  ];

  return statuses.includes(item.projectStatusId);
}

export function canEditSurvey(item: Pick<Project, 'isExternal' | 'projectStatusId' | 'projectType'>) {
  const statuses = [
    enums.ProjectStatus.Active,
  ];
  const types = [
    enums.ProjectType.Survey,
    enums.ProjectType.SurveyCall,
  ];

  if (item.isExternal && item.projectType === enums.ProjectType.Survey) return false;

  return true;
}

export function canClone(item: Pick<ProjectParent, 'frequency'>) {
  return item.frequency !== enums.ProjectFrequency.None;
}

export function isSurveyLike(item: Pick<Project, 'projectType'>) {
  return item.projectType != ProjectType.Call;
}

export function isCallLike(item: Pick<Project, 'projectType'>) {
  return item.projectType != ProjectType.Survey;
}

export function canDownload(item: Pick<Project, 'isExternal'>) {
  return !item.isExternal;
}

export function canShare(item: Pick<Project, 'projectStatusId'>) {
  const statuses = [
    enums.ProjectStatus.Active,
  ];

  return statuses.includes(item.projectStatusId);
}

export function canPreview(item: Pick<Project, 'isExternal' | 'projectType'>) {
  return !item.isExternal && isSurveyLike(item);
}

export function canMonitorSurvey(item: Pick<Project, 'projectType'>) {
  return [enums.ProjectType.Survey, enums.ProjectType.SurveyCall].includes(item.projectType);
}