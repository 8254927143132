import { memo } from 'react';
import { Redirect, generatePath } from 'react-router-dom';
import { path, pathname } from '@consts';
import { ConferenceMediaType } from '@enums';
import type { IConference } from '@containers/Conference';
import { LiveRoomContainer, MultiChatContainer, useConferenceParam, useConferenceInstance, useTwilioVideo, useTwilioVoice } from '@containers/Conference';
import { ConferenceRoomVideo } from '@screens/Conference.Room.Video';
import { ConferenceRoomVoice } from '@screens/Conference.Room.Voice';

export function ConferenceRoom() {
  const conferenceIdentifier = useConferenceParam();
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom | IConference.Coordinator.Conference.Left>();

  if (instance?.status === 'left') {
    return <Redirect to={{
      pathname: generatePath(`${path.Conference.Completed}`, { conferenceIdentifier: instance.conferenceIdentifier }),
      search: `?title=${instance.title}&reason=${instance.redirect}`,
      state: {
        redirect: instance.redirect === 'location' ? instance.location : null,
        callRole: instance.participant?.role,
      },
    }} />;
  }

  if (instance?.status !== 'meeting-room') {
    const to = conferenceIdentifier
      ? generatePath(path.Conference.Entry, { conferenceIdentifier })
      : pathname.Home;
    return <Redirect to={to} />;
  }

  switch (instance.conference.type) {
    case ConferenceMediaType.Video: return <RoomVideoGuard />;
    case ConferenceMediaType.Voice: return <RoomVoiceGuard />;
    default: return <Redirect to={pathname.Home} />;
  }
}

function RoomVideoGuard() {
  const conferenceIdentifier = useConferenceParam();
  const twilio = useTwilioVideo();

  if (!twilio?.room) {
    const to = conferenceIdentifier
      ? generatePath(path.Conference.Entry, { conferenceIdentifier })
      : pathname.Home;
    return <Redirect to={to} />;
  }

  return <RoomVideo />;
}

const RoomVideo = memo(() => {
  return (
    <MultiChatContainer>
      <LiveRoomContainer>
        <ConferenceRoomVideo />
      </LiveRoomContainer>
    </MultiChatContainer>
  );
});

function RoomVoiceGuard() {
  const conferenceIdentifier = useConferenceParam();
  const twilio = useTwilioVoice();

  if (!twilio?.call) {
    const to = conferenceIdentifier
      ? generatePath(path.Conference.Entry, { conferenceIdentifier })
      : pathname.Home;
    return <Redirect to={to} />;
  }

  return <RoomVoice />;
}

const RoomVoice = memo(() => {
  return (
    <MultiChatContainer>
      <LiveRoomContainer>
        <ConferenceRoomVoice />
      </LiveRoomContainer>
    </MultiChatContainer>
  );
});