import http from '@services/http';
import type { Users } from './interfaces';

export const completePrivacyConsentRequest = (data: Users.CompletePrivacyConsentRequest.Request): Promise<Users.CompletePrivacyConsentRequest.Response> => {
  return http.patch(`/users/consent/privacy/gdpr/${data.consentId}`, data);
};

export const findPrivacyConsentRequest = (): Promise<Users.FindPrivacyConsentRequest.Response> => {
  return http.get(`/users/consent/privacy/gdpr`);
};

export const fetchResearchConsent = () => {
  return http.get<Users.FetchResearchConsent.Response>(`/users/consent/privacy/research`);
};

export const updateInactiveContractConsent = (data: Users.UpdateInactiveContractConsent.Request) => {
  return http.post<Users.UpdateInactiveContractConsent.Response>(`/users/consent/usage/inactive-contract`, data);
};

export const updateResearchConsent = (data: Users.UpdateResearchConsent.Request) => {
  return http.put<Users.UpdateResearchConsent.Response>(`/users/consent/privacy/research`, data);
};