import { useContext, useMemo } from 'react';
import { useIsInternalUser, useSelectUser } from '@containers/Store';
import { TranscriptEnhancementStateContext } from '@containers/Transcript/context';
import { TranscriptionRevisionStatus } from '@enums';
import { hasCallTranscriptWritePermission, hasDataStewardRole } from '@utils';

export const useTranscriptEnhancementState = () => {
  const enhancementState = useContext(TranscriptEnhancementStateContext);
  const user = useSelectUser();
  const isInternalGroupUser = useIsInternalUser();

  const state = useMemo(() => {

    const isAssignee = user?.id === enhancementState?.assigneeId;
    const hasNoAssignee = !enhancementState?.assigneeId;

    const has = {
      role: hasDataStewardRole({ roles: user.roles }),
      permission: hasCallTranscriptWritePermission({ permissions: user.permissions }),
    };

    const status = {
      isCompleted: enhancementState?.statusId === TranscriptionRevisionStatus.Completed,
      isInProgress: enhancementState?.statusId === TranscriptionRevisionStatus.InProgress,
      isInQueue: enhancementState?.statusId === TranscriptionRevisionStatus.InQueue,
    };

    const cleanup = {
      claimed: !hasNoAssignee && !isAssignee,
      unclaimed: hasNoAssignee && status.isInQueue,
    };

    const isViewer = !has.role
      || has.role && status.isCompleted
      || has.role && cleanup.claimed;

    return {
      cleanup,
      enabled: enhancementState?.enabled,
      status,
      user: {
        canPublish: isAssignee && status.isInProgress,
        canSelfAssign: has.role && cleanup.unclaimed,
        hasTranscriptWritePermission: has.permission,
        hasDataStewardRole: has.role,
        isAssignee,
        isViewer,
        isInternalUser: isInternalGroupUser,
      },
    };
  }, [
    enhancementState,
    isInternalGroupUser,
    user,
  ]);

  return state;
};