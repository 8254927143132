import { createRef, useCallback, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { SurveyPipedQuestionValueType, SurveyPipedValueType } from '@enums/Survey';
import type { PipedValue, SurveyQuestion, SurveyQuestionMatrixRow, SurveyQuestionOption } from '@/types/survey';
import { cx, trunc } from '@utils';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import { Portal } from '@/components/Portal';
import { useZIndexModifier } from '@/components/Popper';
import { usePipingMenuState } from './context';
import type { RowTypes } from './consts';
import { Menu, MenuItem } from './Editor.Piping.Menu';
import { PipingOptionsMenu } from './Editor.Piping.OptionsMenu';
import styles from './style/Editor.Piping.Menu.css';

type Props = {
  type: RowTypes;
  item: SurveyQuestion;
};

export const PipingRowsMenu = ({ item, type }: Props) => {

  const [openRow, setOpenRow] = useState<string>();

  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);

  const refMap = useMemo(() => {
    return item.matrixRows.map(m => m as SurveyQuestionMatrixRow).reduce<Map>((acc, x) => {
      acc[x.base.identifier] = createRef<HTMLDivElement>();
      return acc;
    }, {});

    type Map = {
      [rowIdentifier: string]: React.RefObject<HTMLDivElement>;
    };
  }, [item.matrixRows]);

  const referenceElement = useMemo(() => {
    return refMap[openRow];
  }, [refMap, openRow]);

  const zIndexModifier = useZIndexModifier({ zIndex: 8 });

  const { styles: popperStyles, attributes } = usePopper(referenceElement?.current, popperElement, {
    modifiers: [
      zIndexModifier,
    ],
    placement: 'right-start',
  });

  const { onPipingValueSelect } = usePipingMenuState();

  const handleBlur = useCallback(() => {
    setOpenRow(null);
  }, []);

  const handleOptionClick = useCallback((option: SurveyQuestionOption) => {
    onPipingValueSelect({
      value: {
        option: { identifier: option.base.identifier },
        row: { identifier: openRow },
        type: SurveyPipedValueType.NumberTableCellValue,
      },
      question: {
        identifier: item.base.identifier,
      },
    });
  }, [item.base.identifier, onPipingValueSelect, openRow]);

  const handleItemClick = useCallback((row: SurveyQuestionMatrixRow) => () => {

    const handler = clickMap[type];

    if (handler) {
      const value = handler(row.base.identifier);

      onPipingValueSelect({
        value,
        question: {
          identifier: item.base.identifier,
        },
      });
    } else {
      setOpenRow(row.base.identifier);
    }
  }, [item.base.identifier, onPipingValueSelect, type]);

  const renderItem = useCallback((row: SurveyQuestionMatrixRow) => {
    const value = parseSurveyRichText(row.value);
    const text = `R${row.ordinal}: ${trunc(value, 50)}`;

    const onClick = handleItemClick(row);

    const active = row.base.identifier === openRow;
    const hasChildren = type === SurveyPipedValueType.NumberTableCellValue;

    return (
      <MenuItem
        key={row.ordinal}
        ref={refMap[row.base.identifier]}
        active={active}
        hasChildren={hasChildren}
        onClick={onClick}>
        {text}
      </MenuItem>
    );
  }, [handleItemClick, openRow, refMap, type]);

  return (
    <>
      <Menu
        className={cx({
          [styles.overflow]: !openRow,
        })}>
        {item.matrixRows.map(renderItem)}
      </Menu>
      {openRow &&
        <Portal>
          <ClickAwayListener
            mouseEvent='onMouseDown'
            onClickAway={handleBlur}
            touchEvent='onTouchStart'>
            <div
              ref={setPopperElement}
              style={popperStyles.popper}
              {...attributes.popper}>
              <PipingOptionsMenu
                type={type}
                onClick={handleOptionClick}
                item={item} />
            </div>
          </ClickAwayListener>
        </Portal>
      }
    </>
  );
};

const sliderRowValue = (rowIdentifier: string) => ({
  row: { identifier: rowIdentifier },
  type: SurveyPipedValueType.SliderRowValue,
});

const matrixOptionsSelected = (rowIdentifier: string) => ({
  row: { identifier: rowIdentifier },
  type: SurveyPipedValueType.MatrixOptionsSelected,
});

const matrixOptionsNotSelected = (rowIdentifier: string) => ({
  row: { identifier: rowIdentifier },
  type: SurveyPipedValueType.MatrixOptionsNotSelected,
});

type ClickType = RowTypes;

const clickMap = {
  [SurveyPipedValueType.SliderRowValue]: sliderRowValue,
  [SurveyPipedValueType.MatrixOptionsSelected]: matrixOptionsSelected,
  [SurveyPipedValueType.MatrixOptionsNotSelected]: matrixOptionsNotSelected,
} as Record<ClickType, (rowIdentifier: string) => PipedValue.Value>;