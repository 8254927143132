import { createContext, useCallback, useContext, useState, useMemo } from 'react';
import { usePopper } from 'react-popper';
import { MoreVertical } from 'react-feather';
import { Formik, type FormikHelpers } from 'formik';
import type * as Yup from 'yup';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import {
  ExternalSourcingConfigsContext,
  ExternalSourcingMutationsContext,
  ExternalSourcingRefetchContext,
  type ExternalSourcingConfigurationFormValues as ConfigurationFormValues,
  type ExternalSourcingConfigurationDisplay as ConfigurationDisplayProps,
} from '@containers/Project.ExternalSourcing';
import { ZIndex } from '@consts';
import { ExternalSourcingVendor } from '@/enums';
import { Anchor } from '@presentation/Anchor';
import { cx } from '@/utils';
import { useDownloader } from '@utils/hooks';
import { useZIndexModifier, PopperMenu, PopperMenuItem } from '@/components/Popper';
import { Portal } from '@/components/Portal';
import Check from '@/components/icons/Check';
import XCircle from '@/components/icons/XCircle';
import { FieldText, FieldSelect, FieldMoney, FieldSourcingVendors } from './Field';
import { SectionHeader } from './SectionHeader';
import * as AllGlobal from './Section.Settings.Survey.OffPlatformSourcing.AllGlobal';
import * as AtlasPrimary from './Section.Settings.Survey.OffPlatformSourcing.AtlasPrimary';
import * as Dynata from './Section.Settings.Survey.OffPlatformSourcing.Dynata';
import * as GenericSurvey from './Section.Settings.Survey.OffPlatformSourcing.Generic';
import * as M3 from './Section.Settings.Survey.OffPlatformSourcing.M3';
import * as Medefield from './Section.Settings.Survey.OffPlatformSourcing.Medefield';
import * as MedSurvey from './Section.Settings.Survey.OffPlatformSourcing.MedSurvey';
import * as Reckner from './Section.Settings.Survey.OffPlatformSourcing.Reckner';
import * as Sago from './Section.Settings.Survey.OffPlatformSourcing.Sago';
import * as SHG from './Section.Settings.Survey.OffPlatformSourcing.SHG';
import * as Toluna from './Section.Settings.Survey.OffPlatformSourcing.Toluna';
import rootStyles from './style.css';
import styles from './style/Section.Settings.Survey.ExternalSourcing.css';

type EditingContextValue = {
  editing: number;
  setEditing: React.Dispatch<React.SetStateAction<number>>;
  save: (values: ConfigurationFormValues, formikHelpers: FormikHelpers<ConfigurationFormValues>) => Promise<void>;
  createConfig: ConfigurationFormValues;
  setCreateConfig: React.Dispatch<React.SetStateAction<ConfigurationFormValues>>;

  deleteConfig: (configId: number) => Promise<void>;
  generateLinks: (configId: number) => Promise<void>;
  downloadLinks: (configId: number) => Promise<void>;
  deleteUnusedLinks: (configId: number) => Promise<void>;
};

const EditingContext = createContext<EditingContextValue>(null);

export function OffPlatformSourcing() {
  const [createConfig, setCreateConfig] = useState<ConfigurationFormValues>(null);
  const [editing, setEditing] = useState<number>();

  const refetchConfigs = useContext(ExternalSourcingRefetchContext);
  const configurations = useContext(ExternalSourcingConfigsContext);

  const {
    deleteMutation,
    generateLinksMutation,
    downloadLinksMutation,
    deleteUnusedLinksMutation,
    saveMutation,
  } = useContext(ExternalSourcingMutationsContext);

  const handleSave = useCallback((values: ConfigurationFormValues, formikHelpers: FormikHelpers<ConfigurationFormValues>) => {
    return saveMutation.mutateAsync(values, {
      onSuccess: () => {
        setEditing(null);
        setCreateConfig(null);
        refetchConfigs();
      },
    });
  }, [
    refetchConfigs,
    saveMutation,
  ]);

  const handleDelete = useCallback((configId: number) => {
    return deleteMutation.mutateAsync(configId)
    .then(() => {
      setEditing(null);
      setCreateConfig(null);
      refetchConfigs();
    });
  }, [refetchConfigs, deleteMutation]);

  const handleGenerateLinks = useCallback((configId: number) => {
    return generateLinksMutation.mutateAsync(configId)
    .then(() => {
      setEditing(null);
      setCreateConfig(null);
      refetchConfigs();
    });
  }, [refetchConfigs, generateLinksMutation]);

  const download = useDownloader();

  const handleDownloadLinks = useCallback((configId: number) => {
    return downloadLinksMutation.mutateAsync(configId)
    .then(result => download(result));
  }, [download, downloadLinksMutation]);

  const handleDeleteUnusedLinks = useCallback((configId: number) => {
    return deleteUnusedLinksMutation.mutateAsync(configId)
    .then(() => {
      setEditing(null);
      setCreateConfig(null);
      refetchConfigs();
    });
  }, [refetchConfigs, deleteUnusedLinksMutation]);

  const handleCreate = useCallback(() => {
    setEditing(0);
    setCreateConfig({
      id: 0,
      label: '',
      rate: '',
      branding: 'none',
      vendor: null,
      completionUrl: '',
      disqualifyUrl: '',
      overquotaUrl: '',
      appendVidToLinks: false,
      lazyLinkAssignment: false,
      metadata: {},
    });
  }, []);

  const ctx = {
    editing,
    setEditing,
    save: handleSave,
    createConfig,
    setCreateConfig,
    deleteConfig: handleDelete,
    generateLinks: handleGenerateLinks,
    downloadLinks: handleDownloadLinks,
    deleteUnusedLinks: handleDeleteUnusedLinks,
  };

  return (
    <EditingContext.Provider value={ctx}>
      <SectionHeader
        title="Off Platform Sourcing"
        canEdit={true}
        canSave={false}
        canCreate={true}
        editing={true}
        onEdit={null}
        onReset={null}
        onSave={null}
        onCreate={handleCreate} />
      {configurations.map(item => (
        editing === item.config.id
          ? <ConfigurationForm
              key={item.config.id}
              edit={{
                id: item.config.id,
                label: item.config.label,
                rate: `${item.config.linkRate}`,
                branding: item.config.branding,
                vendor: item.config.vendor,
                completionUrl: item.config.completionUrl,
                disqualifyUrl: item.config.disqualifyUrl,
                overquotaUrl: item.config.overquotaUrl,
                appendVidToLinks: item.config.appendVidToLinks,
                lazyLinkAssignment: item.config.lazyLinkAssignment,
                metadata: item.config.metadata,
              }} />
          : <ConfigurationDisplay
              key={item.config.id}
              id={item.config.id}
              projectId={item.config.projectId}
              identifier={item.config.identifier}
              label={item.config.label}
              rate={item.config.linkRate}
              branding={item.config.branding}
              vendor={item.config.vendor}
              completionUrl={item.config.completionUrl}
              disqualifyUrl={item.config.disqualifyUrl}
              overquotaUrl={item.config.overquotaUrl}
              appendVidToLinks={item.config.appendVidToLinks}
              lazyLinkAssignment={item.config.lazyLinkAssignment}
              lazyLinkSecret={item.config.lazyLinkSecret}
              used={item.links.used}
              total={item.links.total}
              metadata={item.config.metadata} />
      ))}
      {createConfig && <ConfigurationForm edit={createConfig} />}
      {configurations?.length === 0 && !createConfig && (
        <div className={cx(rootStyles.grid)}>
          <div className={rootStyles.section100}>No sourcing configurations.</div>
        </div>
      )}
    </EditingContext.Provider>
  );
}

function ConfigurationDisplay(props: ConfigurationDisplayProps) {
  const { editing, setEditing, deleteConfig, generateLinks, downloadLinks, deleteUnusedLinks } = useContext(EditingContext);

  const handleEdit = useCallback(() => {
    setEditing(props.id);
  }, [
    setEditing,
    props.id,
  ]);

  const handleDelete = useCallback((configId: number) => () => {
    deleteConfig(configId);
  }, [deleteConfig]);

  const handleGenerateLinks = useCallback((configId: number) => () => {
    generateLinks(configId);
  }, [generateLinks]);

  const handleDownloadLinks = useCallback((configId: number) => () => {
    downloadLinks(configId);
  }, [downloadLinks]);

  const handleDeleteUnusedLinks = useCallback((configId: number) => () => {
    deleteUnusedLinks(configId);
  }, [deleteUnusedLinks]);

  const getRenderer = useCallback(() => {
    switch (props.vendor.id) {
      case ExternalSourcingVendor.AllGlobal: return <AllGlobal.OffPlatformSourcingAllGlobalDisplay {...props} />;
      case ExternalSourcingVendor.AtlasPrimary: return <AtlasPrimary.OffPlatformSourcingAtlasPrimaryDisplay {...props} />;
      case ExternalSourcingVendor.Dynata: return <Dynata.OffPlatformSourcingDynataDisplay {...props} />;
      case ExternalSourcingVendor.M3: return <M3.OffPlatformSourcingM3Display {...props} />;
      case ExternalSourcingVendor.Medefield: return <Medefield.OffPlatformSourcingMedefieldDisplay {...props} />;
      case ExternalSourcingVendor.MedSurvey: return <MedSurvey.OffPlatformSourcingMedSurveyDisplay {...props} />;
      case ExternalSourcingVendor.Reckner: return <Reckner.OffPlatformSourcingRecknerDisplay {...props} />;
      case ExternalSourcingVendor.Sago: return <Sago.OffPlatformSourcingSagoDisplay {...props} />;
      case ExternalSourcingVendor.SHG: return <SHG.OffPlatformSourcingSHGDisplay {...props} />;
      case ExternalSourcingVendor.Toluna: return <Toluna.OffPlatformSourcingTolunaDisplay {...props} />;
      default: return <GenericSurvey.OffPlatformSourcingGenericDisplay {...props} />;
    }
  }, [props]);

  return (
    <div className={cx(rootStyles.grid, styles.config)}>
      <div className={rootStyles.section33}>
        <div className={rootStyles.label}>
          Source Label
        </div>
        <div>{props.label}</div>
      </div>
      <div className={rootStyles.section33}>
        <div className={rootStyles.label}>
          Vendor
        </div>
        <div>{props.vendor?.name ?? '-'}</div>
      </div>
      <div className={rootStyles.section33} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <div className={styles.available}>
          {`(${props.total - props.used} of ${props.total} links available)`}
        </div>
        {!editing &&
          <DisplayContextMenu
            onEdit={handleEdit}
            onDelete={props.used === 0 ? handleDelete(props.id) : null}
            onGenerateLinks={handleGenerateLinks(props.id)}
            onDownloadLinks={handleDownloadLinks(props.id)}
            onDeleteUnusedLinks={handleDeleteUnusedLinks(props.id)} />}
      </div>
      <div className={rootStyles.section33}>
        <div className={rootStyles.label}>
          Link Rate
        </div>
        <div>{`$${props.rate}`}</div>
      </div>
      <div className={rootStyles.section33}>
        <div className={rootStyles.label}>
          Branding
        </div>
        <div>{props.branding}</div>
      </div>
      <div className={rootStyles.section33} />
      {getRenderer()}
    </div>
  );
}

type ConfigurationFormProps = {
  edit?: ConfigurationFormValues;
};

function ConfigurationForm(props: ConfigurationFormProps) {
  const { save, setEditing, createConfig, setCreateConfig } = useContext(EditingContext);
  const [ConfigurationSchema, setConfigurationSchema] = useState<Yup.AnyObjectSchema>();

  const handleReset = useCallback(() => {
    setEditing(null);
    if (createConfig) {
      setCreateConfig(null);
    }
  }, [
    createConfig,
    setEditing,
    setCreateConfig,
  ]);

  const getRenderer = useCallback((vendor: ExternalSourcingVendor) => {
    switch (vendor) {
      case ExternalSourcingVendor.AllGlobal: return <AllGlobal.OffPlatformSourcingAllGlobalForm setConfigurationSchema={setConfigurationSchema} />;
      case ExternalSourcingVendor.AtlasPrimary: return <AtlasPrimary.OffPlatformSourcingAtlasPrimaryForm setConfigurationSchema={setConfigurationSchema} />;
      case ExternalSourcingVendor.Dynata: return <Dynata.OffPlatformSourcingDynataForm setConfigurationSchema={setConfigurationSchema} />;
      case ExternalSourcingVendor.M3: return <M3.OffPlatformSourcingM3Form setConfigurationSchema={setConfigurationSchema} />;
      case ExternalSourcingVendor.Medefield: return <Medefield.OffPlatformSourcingMedefieldForm setConfigurationSchema={setConfigurationSchema} />;
      case ExternalSourcingVendor.MedSurvey: return <MedSurvey.OffPlatformSourcingMedSurveyForm setConfigurationSchema={setConfigurationSchema} />;
      case ExternalSourcingVendor.Reckner: return <Reckner.OffPlatformSourcingRecknerForm setConfigurationSchema={setConfigurationSchema} />;
      case ExternalSourcingVendor.Sago: return <Sago.OffPlatformSourcingSagoForm setConfigurationSchema={setConfigurationSchema} />;
      case ExternalSourcingVendor.SHG: return <SHG.OffPlatformSourcingSHGForm setConfigurationSchema={setConfigurationSchema} />;
      case ExternalSourcingVendor.Toluna: return <Toluna.OffPlatformSourcingTolunaForm setConfigurationSchema={setConfigurationSchema} />;
      default: return <GenericSurvey.OffPlatformSourcingGenericForm setConfigurationSchema={setConfigurationSchema} />;
    }
  }, []);

  return (
    <Formik<ConfigurationFormValues>
      initialValues={{
        id: props.edit?.id || 0,
        label: props.edit?.label || '',
        rate: props.edit?.rate || '50',
        branding: props.edit?.branding || 'none',
        vendor: props.edit?.vendor,
        completionUrl: props.edit?.completionUrl || '',
        disqualifyUrl: props.edit?.disqualifyUrl || '',
        overquotaUrl: props.edit?.overquotaUrl || '',
        appendVidToLinks: props.edit?.appendVidToLinks || false,
        lazyLinkAssignment: props.edit?.lazyLinkAssignment || false,
        metadata: props.edit?.metadata || {},
      }}
      validationSchema={ConfigurationSchema}
      enableReinitialize={true}
      onSubmit={save}
      onReset={handleReset}>
      {props => (
        <>
          <form
            id="sourcing-configuration"
            onSubmit={props.handleSubmit}
            onReset={props.handleReset}>
            <div className={cx(rootStyles.grid, styles.config)}>
              <div className={rootStyles.section50}>
                <FieldText
                  editing={true}
                  label="Source Label"
                  name="label"
                  displayValue={props.values.label} />
              </div>
              <div className={rootStyles.section50}>
                <FieldSourcingVendors
                  editing={true}
                  label="Vendor"
                  name="vendor"
                  displayValue={props.values.vendor} />
              </div>
              <div className={rootStyles.section50}>
                <FieldMoney
                  editing={true}
                  label="Link Rate"
                  name="rate"
                  displayValue={`$${props.values.rate}`} />
              </div>
              <div className={rootStyles.section50}>
                <FieldSelect
                  editing={true}
                  label="Branding"
                  name="branding"
                  displayValue={props.values.branding}
                  options={{
                    'none': 'None',
                  }} />
              </div>
              {getRenderer(props.values?.vendor?.id)}
              <div className={styles.actions}>
                {props.isValid && (
                  <Check
                  className={styles.save}
                  onClick={props.handleSubmit} />)
                }
                <XCircle onClick={props.handleReset} />
              </div>
            </div>
          </form>
        </>
      )}
    </Formik>
  );
}

type DisplayContextMenuProps = {
  onEdit: () => void;
  onDelete: () => void;
  onGenerateLinks: () => void;
  onDownloadLinks: () => void;
  onDeleteUnusedLinks: () => void;
};

const DisplayContextMenu = ({ onEdit, onDelete, onGenerateLinks, onDownloadLinks, onDeleteUnusedLinks }: DisplayContextMenuProps) => {
  const [popperOpen, setPopperOpen] = useState(false);

  const [referenceElement, setReferenceElement] = useState<HTMLElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);

  const zIndexModifier = useZIndexModifier({ zIndex: ZIndex.Popper });

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      zIndexModifier,
    ],
    placement: 'bottom-end',
  });

  const handleAnchorClick = useCallback(() => {
    setPopperOpen(true);
  }, []);

  const handleClickAway = useCallback((e: MouseEvent) => {
    if (!referenceElement.contains(e.target as Node)) {
      setPopperOpen(false);
    }
  }, [referenceElement]);

  const handleClick = useCallback((fn: () => void) => () => {
    setPopperOpen(false);
    return fn();
  }, [setPopperOpen]);

  return (
    <>
      <div
        className={styles.anchor}
        ref={setReferenceElement}
        onClick={handleAnchorClick}>
        <Anchor
          className={styles.anchor}
          open={popperOpen}
          Icon={MoreVertical} />
      </div>
      {popperOpen &&
        <Portal>
          <div
            ref={setPopperElement}
            style={popperStyles.popper}
            {...attributes.popper}>
            <ClickAwayListener onClickAway={handleClickAway}>
              <div>
                <PopperMenu>
                  <PopperMenuItem key="edit" onClick={handleClick(onEdit)}>Edit</PopperMenuItem>
                  {onDelete && <PopperMenuItem key="delete" onClick={handleClick(onDelete)}>Delete</PopperMenuItem>}
                  <PopperMenuItem key="generate" onClick={handleClick(onGenerateLinks)}>Generate Links</PopperMenuItem>
                  <PopperMenuItem key="delete-links" onClick={handleClick(onDeleteUnusedLinks)}>Delete Unused Links</PopperMenuItem>
                  <PopperMenuItem key="download" onClick={handleClick(onDownloadLinks)}>Download Links</PopperMenuItem>
                </PopperMenu>
              </div>
            </ClickAwayListener>
          </div>
        </Portal>
      }
    </>
  );
};