import bg1 from 'static/images/website/collaboration-tools/doc-preview-s.png';
import bg2 from 'static/images/website/collaboration-tools/doc-preview-m.png';
import bg3 from 'static/images/website/collaboration-tools/doc-preview-l.png';
import styles from './style/ContentPanel.css';

type Props = unknown;

export const ContentPanel = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.graphic}>
            <picture>
              <source
                media="(min-width: 1100px)"
                srcSet={bg3} />
              <source
                media="(min-width: 800px)"
                srcSet={bg2} />
              <img
                alt="View documents"
                className={styles.img}
                src={bg1} />
            </picture>
          </div>
          <div className={styles.info}>
            <h2 className={styles.title}>View documents and collaborate with a custom panel of respondents and ideal buyers</h2>
            <div className={styles.subtitle}>Tag content with keywords and identify professionals that are best fit to contribute to those documents. Click through to start a messaging exchange or schedule a call with the best and brightest professionals on the topics of your research.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

ContentPanel.displayName = 'CollaborationTools.ContentPanel';