import { useMemo } from 'react';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { parse, stringify } from 'query-string';
import type { EncodedQuery } from 'use-query-params';
import { QueryParamProvider, transformSearchStringJsonSafe } from 'use-query-params';

const searchStringToObject = (searchString: string): EncodedQuery => {
  const transformed = transformSearchStringJsonSafe(searchString);
  return parse(transformed);
};

type Props = ChildrenProps;

export const QueryParamsClient = (props: Props) => {
  const options = useMemo(() => ({
    searchStringToObject,
    objectToSearchString: stringify,
  }), []);

  return (
    <QueryParamProvider
      adapter={ReactRouter5Adapter}
      options={options}>
      {props.children}
    </QueryParamProvider>
  );
};

export default QueryParamsClient;