import { X } from 'react-feather';
import { cx } from '@utils';
import styles from './style/LanguageSection.css';

type SectionProps = LanguageProps & ChildrenProps & {
  classes?: {
    root?: string;
    header?: string;
    body?: string;
  };
  onClose?: () => void;
};

export const LanguageCodeSection = ({ classes = {}, ...props }: SectionProps) => {
  //const [state] = useSurveyBuilderState();
  //const language = state.survey.language.languages.find(l => l.code === props.languageCode);

  return (
    <div className={cx(styles.languageSection, classes.root)}>
      <div className={cx(styles.sectionHeader, classes.header)}>
        {props.languageCode}
        {props.onClose && <X className={styles.closeIcon} onClick={props.onClose} />}
      </div>
      <div className={cx(styles.sectionBody, classes.body)}>{props.children}</div>
    </div>
  );
};

type LanguageProps = {
  languageCode: string;
};
