import { utils as $enums } from '@/enums';
import { Entry } from './interfaces';
import styles from './style/ProjectAccess.css';

type Props = {
  items: Entry[];
};

export const ReadOnlyProjectAccess = ({ items }: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>

          <div className={styles.table}>
            <div className={styles.header}>
              <div className={styles.name}>
                <div className={styles.label}>Name</div>
              </div>
              <div className={styles.fields}>
                <div className={styles.role}>
                  <div className={styles.label}>Role</div>
                </div>
              </div>
            </div>

            <div className={styles.items}>
              {items.map(x =>
                <div
                  className={styles.item}
                  key={x.id || x.email}>
                  <div className={styles.name}>{x.name}</div>

                  <div className={styles.fields}>
                    <div className={styles.role}>{$enums.WorkspaceObjectRole.getName(x.roleId)}</div>
                  </div>
                </div>
              )}
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};