import { Reducer, useCallback, useReducer } from 'react';
import * as api from '@api';
import { useSelectGroup, useSelectPipeline } from '@containers/Store';
import { DownloaderGroupParams, DownloaderProjectParams, DownloaderState, UseSignedDocumentDownloader } from '@containers/Downloader/interfaces';
import { DocumentSigning } from '@enums';
import { PipelineRecord } from '@/types';
import { useDownloader } from '@utils/hooks';

type Params =
  & DownloaderGroupParams
  & DownloaderProjectParams
  & DownloaderGroupParams & DownloaderProjectParams;

const useCreateSignedDocumentDownloader = () => {
  const download = useDownloader();
  const group = useSelectGroup();
  const pipeline = useSelectPipeline<Store.Pipeline.Project>();

  const [state, dispatch] = useReducer<Reducer<DownloaderState, Partial<DownloaderState>>>(
    (acc, x) => ({ ...acc, ...x }), {
      downloading: false,
      enabled: false,
    }
  );

  /**
   * @function getUserPipelineRecord
   * */
  const getUserPipelineRecord = useCallback((params: IProjectId & IUserId) => {

    const attributes = pipeline[params.projectId];

    return attributes.users?.[params.userId];

  }, [pipeline]);

  /**
   * @function setDownloadingState
   * */
  const setDownloadingState = useCallback((downloading: boolean) => {

    dispatch({ downloading });

  }, [dispatch]);

  /**
   * @function handleDownload
   * */
  const handleDownload = useCallback(async (params: Params) => {

    function save(data: Blob) {

      download({
        blob: data,
        filename: params.filename,
      });

      setDownloadingState(false);
    }

    const record = getUserPipelineRecord({
      projectId: params.projectId,
      userId: params.userId,
    });

    if (isRequired(record?.documentSigning)) {
      setDownloadingState(true);

      return api.groups.downloadConsultantGroupDocument({
        groupId: group.id,
        userId: params.userId,
      })
      .then(save);
    }

    if (isManuallyUploaded(record?.documentSigning)) {
      setDownloadingState(true);

      return api.groups.downloadConsultantProjectDocument({
        projectId: params.projectId,
        userId: params.userId,
      })
      .then(save);
    }

  }, [
    download,
    getUserPipelineRecord,
    group.id,
    setDownloadingState,
  ]);

  /**
   * @function setEnabledState
   */
  const setEnabledState = useCallback(<T extends Omit<Params, 'filename'>>(params: T) => {

    const record = getUserPipelineRecord({
      projectId: params.projectId,
      userId: params.userId,
    });

    const disable = shouldDisableDownload({
      documentSigning: record?.documentSigning,
      project: params.signature.project,
      group: params.signature.group,
    });

    if (state.enabled === !disable) return;

    dispatch({ enabled: !disable });

  }, [
    dispatch,
    getUserPipelineRecord,
    state.enabled,
  ]);

  /**
   * @alias useSignedDocumentDownloader
   */
  return useCallback(<T extends Params>(params: T) => {

    setEnabledState(params);

    function dispatchDownload() {

      if (!state.enabled) return;

      handleDownload(params);

    }

    return [state, dispatchDownload] as UseSignedDocumentDownloader;

  }, [
    handleDownload,
    setEnabledState,
    state,
  ]);

};

type ValidateDownloadParams =
  & DownloaderGroupParams['signature']
  & DownloaderProjectParams['signature']
  & Pick<PipelineRecord, 'documentSigning'>;

function shouldDisableDownload<T extends ValidateDownloadParams>(params: T) {
  return isNotRequired(params.documentSigning)
      || isRequired(params.documentSigning) && !params.group
      || isManuallyUploaded(params.documentSigning) && !params.project;
}

function isManuallyUploaded(type: DocumentSigning) {
  return type === DocumentSigning.ManuallyUploaded;
}

function isNotRequired(type: DocumentSigning) {
  return type === DocumentSigning.NotRequired;
}

function isRequired(type: DocumentSigning) {
  return type === DocumentSigning.Required;
}

export { useCreateSignedDocumentDownloader };
export default useCreateSignedDocumentDownloader;