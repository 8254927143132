import { useCallback } from 'react';
import AppsIcon from '@mui/icons-material/Apps';
import ListIcon from '@mui/icons-material/List';
import styles from './style/FileLayoutSelector.css';

type Layout = 'grid' | 'list';

type FileLayoutSelectorProps = {
  classname?: string;
  selected: Layout;
  onSelect: (layout: Layout) => unknown;
};

export const FileLayoutSelector = ({ classname, onSelect, selected }: FileLayoutSelectorProps) => {

  const handleClick = useCallback((layout: Layout) => () => {
    onSelect(layout);
  }, [onSelect]);

  return (
    <div className={classname}>
      <AppsIcon
        className={selected === 'grid' ? styles.fileLayoutIconActive : styles.fileLayoutIcon}
        onClick={handleClick('grid')} />
      <ListIcon
        className={selected === 'list' ? styles.fileLayoutIconActive : styles.fileLayoutIcon}
        onClick={handleClick('list')} />
    </div>
  );
};

export default FileLayoutSelector;