import { useState } from 'react';
import * as consts from '@consts';
import { useSelectUser } from '@containers/Store/hooks';
import { useSearchQuery } from '@/containers';
import { ReferralClientModal as ReferralModal } from '@/components/Modal/Referral';
import graphic from 'static/icons/empty-search.svg?url';
import styles from './style/ResultsFooter.css';

type Props = unknown;

const ResultsFooter = (props: Props) => {
  const [query] = useSearchQuery();

  const count = query.value?.items?.length;
  const newQuery = query.value?.new;
  const total = query.value?.total;

  const empty = newQuery && total === 0;
  const done = newQuery && total === count;

  if (empty) return (
    <>
      <ListEmpty />
      <ListEnd />
    </>
  );

  if (done) return <ListEnd />;

  return null;
};

function ListEmpty() {
  return (
    <div className={styles.empty}>
      <div className={styles.graphic}>
        <img
          className={styles.image}
          src={graphic} />
      </div>

      <div className={styles.message}>Try expanding your search criteria</div>
    </div>
  );
}

function ListEnd() {
  const [visible, toggleModal] = useState(false);
  const user = useSelectUser();

  const handleToggle = () => {
    toggleModal(!visible);
  };

  const url = `${process.env.FRONT_BASE_URL}${consts.path.Website.ReferralSignup}/${user.referral}`;

  return (
    <>
      <div className={styles.root}>
        <div className={styles.subtitle}>Still looking for an expert?</div>
        <div className={styles.row}>
          <div
            className={styles.link}
            onClick={handleToggle}>
            Share a sign up link
          </div>
          <div className={styles.text}> to sign up a new expert</div>
        </div>
      </div>

      <ReferralModal
        onClose={handleToggle}
        url={url}
        visible={visible} />
    </>
  );
}

export { ResultsFooter };
export default ResultsFooter;