import { useMemo, useCallback } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { TabView } from '@/components/TabView';
import { useTabViewState } from '@/components/TabView/hooks/useTabViewState';
import { SharedSceneMap, SharedRouteKey, TabScene } from './interfaces';

export const SharedTabView = ({ sceneMap: map }: { sceneMap: SharedSceneMap }) => {

  const keys = useMemo(() => Object.keys(map) as SharedRouteKey[], [map]);
  const routes = useMemo(() => keys.map(k => ({ key: k, ...map[k] })), [keys, map]);

  const formatPath = useCallback((tab: SharedRouteKey) => {
    return `/workspaces/shared/${tab}`;
  }, []);

  const [{ index }, updateLocation] = useTabViewState<SharedRouteKey>({
    formatPath,
    routes: keys,
  });

  const renderTabView = useCallback(({ route }: { route: TabScene }) => {
    const defaultRoute = formatPath(SharedRouteKey.Files);

    return (
      <Switch>
        {keys.map((key: SharedRouteKey) =>
          <Route
            key={key}
            path={map[key].path}
            component={map[key].component} />)}
        <Redirect to={defaultRoute} />
      </Switch>
    );
  }, [
    formatPath,
    keys,
    map,
  ]);

  return (
    <TabView
      index={index}
      onIndexChange={updateLocation}
      routes={routes}
      renderTabView={renderTabView} />
  );
};

export default SharedTabView;