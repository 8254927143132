import { useCallback, useContext, useMemo, useState } from 'react';
import { MedicalBackgroundContext, MedicalBackgroundIdentificationContext, MedicalExpertiseContext } from '@containers/MedicalOnboarding/Context';
import { useBackgroundIdentificationValidation } from '@containers/MedicalOnboarding/hooks';
import { MedicalExperienceNavigationContext } from '@containers/ProjectOnboarding.MedicalExperience/Context';
import { validators, Length } from '@containers/MedicalOnboarding/utils';
import { type ProfessionalRole } from '@/enums';
import { DatePicker } from '@/components/MedicalProfile/DatePicker';
import * as Field from '@/components/MedicalProfile/Field';
import * as Input from '@/components/MedicalProfile/Input';
import * as Select from '@/components/MedicalProfile/Select';
import { Label } from '@/components/MedicalProfile/Label';
import * as Layout from '@/components/MedicalProfile/Layout';
import styles from './style/Experience.css';

type Props = unknown;

export const Identification = (props: Props) => {
  const ctx = {
    background: useContext(MedicalBackgroundContext),
    expertise: useContext(MedicalExpertiseContext),
    identification: useContext(MedicalBackgroundIdentificationContext),
    navigation: useContext(MedicalExperienceNavigationContext),
  };

  const [showErrors, setShowErrors] = useState(false);

  const { errors, meta } = useBackgroundIdentificationValidation(ctx.expertise?.professionalRole);

  const handleSubmit = useCallback(() => {
    const hasErrors = Object.values(errors).some(x => x === true);

    if (hasErrors) {
      setShowErrors(true);
      return;
    }

    setShowErrors(false);

    return ctx.navigation.next();
  }, [errors, ctx.navigation]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Layout.Screen>
          <Layout.Header title={copy.title(ctx.expertise.professionalRole)} />

          <div className={styles.main}>
            <div className={styles.item}>
              <Label required>
                {copy.label.country}
              </Label>
              <div className={styles.field}>
                <Field.Country
                  className={styles.select}
                  isInvalid={showErrors && errors.country}
                  onChange={item => ctx.identification.setValue({ country: item.value })}
                  value={ctx.identification.form.country} />
              </div>
            </div>

            {meta.stateOfPractice.visible &&
              <div className={styles.item}>
                <Label required>
                  {copy.label.stateOfPractice}
                </Label>
                <div className={styles.field}>
                  <Field.StateOfPractice
                    className={styles.select}
                    isInvalid={showErrors && errors.stateOfPractice}
                    onChange={item => ctx.identification.setValue({ stateOfPractice: item.value })}
                    value={ctx.identification.form.stateOfPractice} />
                </div>
              </div>}

            {meta.meNumber.visible &&
              <div className={styles.item}>
                <Label tooltip={copy.tooltip.me}>
                  {copy.label.meNumber}
                </Label>
                <div className={styles.field}>
                  <Input.Text
                    isInvalid={showErrors && errors.meNumber}
                    maxLength={meta.meNumber.length}
                    minLength={meta.meNumber.length}
                    placeholder="Enter ME Number"
                    onChange={e => ctx.identification.setValue({ meNumber: e.target.value })}
                    value={ctx.identification.form.meNumber} />
                </div>
              </div>}

            {meta.npiNumber.visible &&
              <div className={styles.item}>
                <Label
                  required={meta.npiNumber.required}
                  tooltip={copy.tooltip.npi}>
                  {copy.label.npiNumber}
                </Label>
                <div className={styles.field}>
                  <Input.Text
                    isInvalid={showErrors && errors.npiNumber}
                    maxLength={meta.npiNumber.length}
                    minLength={meta.npiNumber.length}
                    placeholder="Enter NPI Number"
                    onChange={e => ctx.identification.setValue({ npiNumber: e.target.value })}
                    value={ctx.identification.form.npiNumber} />
                </div>
              </div>}

            {meta.stateLicenseNumber.visible &&
              <div className={styles.item}>
                <Label required={meta.stateLicenseNumber.required}>
                  {copy.label.stateLicenseNumber}
                </Label>
                <div className={styles.field}>
                  <Input.Text
                    isInvalid={showErrors && errors.stateLicenseNumber}
                    placeholder="Enter State License Number"
                    onChange={e => ctx.identification.setValue({ stateLicenseNumber: e.target.value })}
                    value={ctx.identification.form.stateLicenseNumber} />
                </div>
              </div>}

            {meta.clinicalPracticeStartedOn.visible &&
              <div className={styles.item}>
                <Label required={meta.clinicalPracticeStartedOn.required}>
                  {copy.label.clinicalPracticeStartedOn}
                </Label>
                <div className={styles.field}>
                  <DatePicker
                    isInvalid={showErrors && errors.clinicalPracticeStartedOn}
                    onChange={value => ctx.identification.setValue({ clinicalPracticeStartedOn: value })}
                    value={ctx.identification.form.clinicalPracticeStartedOn} />
                </div>
              </div>}

            {meta.usaPayer.visible &&
              <div className={styles.item}>
                <Label required={meta.usaPayer.required}>
                  {copy.label.usaPayer}
                </Label>
                <div className={styles.field}>
                  <Select.Input
                    isInvalid={showErrors && errors.usaPayer}
                    value={ctx.identification.form.isPayer == undefined ? '' : ctx.identification.form.isPayer ? 'y' : 'n'}
                    options={[{ id: 'y', name: 'Yes' }, { id: 'n', name: 'No' }]}
                    onChange={e => ctx.identification.setValue({ isPayer: e.target.value === 'y' })}
                    placeholder="Yes or No" />
                </div>
              </div>}

            {(meta.usaPayer.visible && ctx.identification.form.isPayer) &&
              <div className={styles.item}>
                <Label required>
                  {copy.label.usaPayerRole}
                </Label>
                <div className={styles.field} style={{ width: '350px' }}>
                  <Field.USAPayerAdmin
                    isInvalid={showErrors && errors.payerAdminType}
                    onChange={item => ctx.identification.setValue({ payerAdminType: item.value })}
                    value={ctx.identification.form.payerAdminType}  />
                </div>
              </div>}
          </div>

          <Layout.Footer className={styles.footer}>
            <Layout.BackButton
              onClick={ctx.navigation.back} />
            <Layout.NextButton
              className={styles.btn}
              implicitDisable={false}
              loading={ctx.background.mutation.isLoading}
              onClick={handleSubmit}
              variant="brick">
              Next
            </Layout.NextButton>
          </Layout.Footer>
        </Layout.Screen>
      </div>
    </div>
  );
};

Identification.displayName = 'Experience.Identification';

const copy = {
  label: {
    clinicalPracticeStartedOn: `Please select the approximate month and year that you started practicing after completing your medical training, residency or fellowship.`,
    country: `What is your country?`,
    deaNumber: `What is your ${Length.dea} digit DEA Number?`,
    meNumber: `What is your ${Length.me} digit ME Number?`,
    npiNumber: `What is your ${Length.npi} digit NPI Number?`,
    stateLicenseNumber: `What is your State License Number?`,
    stateOfPractice: `What is your state of primary practice?`,
    usaPayer: `Are you currently or were you in past a decision-maker or advisor in the determination of pricing, reimbursement, or funding of drug products in your country, region, or institution?`,
    usaPayerRole: `How would you classify this role?`,
  },
  title: (role: ProfessionalRole) => validators.isNonMedical(role) ? `Help us verify your background with the following information.` : `Help us verify your medical background with the following information.`,
  tooltip: {
    dea: `We request this information to validate that you are a practicing physician. It is vital to our research to ensure that our respondents have the expertise necessary to participate in studies. Your privacy and security are important to us - we will never provide this information to third parties.`,
    me: `Your ME (Medical Education) number is issued by the AMA and can be located on your AMA journal. We request this information to validate that you are a practicing physician. It is vital to our research to ensure that our respondents have the expertise necessary to participate in studies. Your privacy and security are important to us - we will never provide this information to third parties.`,
    npi: `We request this information to validate that you are a practicing health care provider. It is vital to our research to ensure that our respondents have the expertise necessary to participate in studies. Your privacy and security are important to us - we will never provide this information to third parties`,
  },
};