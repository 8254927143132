import { type Dispatch, type SetStateAction, useState, useCallback, useMemo } from 'react';
import MultiSelect from 'react-select/creatable';
import { isValidEmail, arr } from '@utils';
import type { SearchResultItem } from '@/services/api/interfaces/projects.access';
import { Button } from '@/components/Button';
import { Select } from '@/components/Select';
import styles from './style/Project.Access.Picker.css';

type RoleOption<Role> = {
  id: Role;
  name: string;
};

type ExistingItem = Omit<SearchResultItem, 'id'> & Partial<Pick<SearchResultItem, 'id'>>;

type Props<Role> = {
  onAdd: (items: SearchResultItem[], role: Role) => void;
  onChange: Dispatch<SetStateAction<SearchResultItem[]>>;
  onPaste?: (emails: string[], role: Role) => void;
  isRoleValid: (item: SearchResultItem, role: Role) => boolean;
  roleOptions: RoleOption<Role>[];
  search: {
    loading?: boolean;
    results: SearchResultItem[];
    keyword: string;
    setKeyword: (val: string) => void;
  };
  selected: SearchResultItem[];
  existingItems: ExistingItem[];
};

export const Picker = <Role, >({ isRoleValid, ...props }: Props<Role>) => {
  const [role, setRole] = useState<Role>(props.roleOptions[0].id);

  const existingEmails = useMemo(() => {
    return props.existingItems.map(i => i.email)
      .concat(props.selected.map(o => o.email))
      .map(x => x.toLocaleLowerCase());
  }, [
    props.existingItems,
    props.selected,
  ]);

  const onRoleChange = useCallback((role: RoleOption<Role>) => {
    const invalidSelections = props.selected.filter(o => !isRoleValid(o, role.id));
    if (!invalidSelections.length) {
      setRole(role.id);
    } else {
      alert(`Role ${role.name} is invalid for selected users: ${invalidSelections.map(o => o.name).join(', ')} `);
    }
  }, [
    isRoleValid,
    props,
  ]);

  const onAdd = useCallback(() => {
    props.onAdd(props.selected, role);
    props.onChange([]);
  }, [
    props,
    role,
  ]);

  const noOptionsMessage = useCallback((val: { inputValue: string }) => {
    const value = val.inputValue?.toLowerCase?.()?.trim();

    const exists = existingEmails.includes(value);

    const invalid = [
      `@trinitylifesciences.com`,
      `@trinitypartners.com`,
    ].some(email => value?.endsWith?.(email));

    return val.inputValue?.length
      ? !exists && invalid
        ? `Please add this user to the Trinity Account before adding them to this call.`
        : `No matches found.`
      : null;
  }, [existingEmails]);

  const isValidNewOption = useCallback((str: string) => {
    if (!isValidEmail(str)) return false;

    if (!isRoleValid({
      id: null,
      name: str,
      email: str,
      offPlatform: true,
    }, role)) {
      return false;
    }

    const value = str?.toLowerCase?.()?.trim();

    const exists = existingEmails.includes(value);

    const invalid = [
      `@trinitylifesciences.com`,
      `@trinitypartners.com`,
    ].some(email => value?.endsWith?.(email));

    return !exists && !invalid;
  }, [existingEmails, isRoleValid, role]);

  const handleCreate = useCallback((value: string) => {
    const custom = {
      email: value,
      name: value,
      offPlatform: true,
      id: null,
    };
    props.onChange(o => [...o, custom]);
  }, [props]);

  const getOptionLabel = useCallback((item: SearchResultItem & CreateOption) => {
    if (item.value) {
      return `Create ${item.value}`;
    } else {
      return `${item.name} (${item.email})`;
    }
  }, []);

  const onPaste = useCallback((e: React.ClipboardEvent) => {
    const clipboardValue = e.clipboardData.getData('text');

    const emails = arr.distinct(clipboardValue.split(/[\n,•]/).map(x => x.trim().toLocaleLowerCase()).filter(Boolean));

    //Don't try and add emails that already exist in either list
    const emailAdditions = emails.filter(e => !existingEmails.includes(e));

    const invalidEmails = emailAdditions.filter(e => !isValidEmail(e));

    if (invalidEmails.length) {
      alert('You pasted some invalid emails: ' + JSON.stringify(invalidEmails));
      return;
    }

    if (emailAdditions.length) {
      props.onPaste?.(emailAdditions, role);
    } else if (emails.length && !emailAdditions.length) {
      alert('After removing duplicates, there were no new emails to add.');
      return;
    }

    e.preventDefault();
  }, [
    existingEmails,
    props,
    role,
  ]);

  return (
    <div className={styles.root}>
      <div
        className={styles.userSelect}
        onPaste={onPaste}>
        <MultiSelect
          classNames={{
            menu: () => styles.userSelectMenu,
            menuList: () => styles.userSelectMenu,
            menuPortal: () => styles.userSelectMenu,
          }}
          createOptionPosition='first'
          filterOption={o => true}
          getOptionLabel={getOptionLabel}
          getOptionValue={val => `${val.id}-${val.offPlatform?.toString()}`}
          isDisabled={props.search.loading}
          isLoading={props.search.loading}
          isMulti={true}
          isOptionDisabled={o => !isRoleValid(o, role)}
          isValidNewOption={isValidNewOption}
          menuPortalTarget={document.body}
          noOptionsMessage={noOptionsMessage}
          onChange={val => props.onChange([...val])}
          onCreateOption={handleCreate}
          onInputChange={val => props.search.setKeyword(val)}
          options={props.search.results}
          value={props.selected} />
      </div>
      <div className={styles.roleSelect}>
        <Select
          value={props.roleOptions.find(o => o.id === role)?.name}
          options={props.roleOptions}
          getItemValue={i => i.name}
          onSelect={onRoleChange} />
      </div>
      <Button
        disabled={props.search.loading}
        variant='brick'
        size='small'
        onClick={onAdd}>
        Add
      </Button>
    </div>
  );
};

type CreateOption = {
  label: string;
  value: string;
};