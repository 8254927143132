import { useCallback } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder';
import { useRichTextEditorRegister } from '@/containers/SurveyBuilder/hooks/useRichTextEditorRegister';
import { useCanCreateFindAndReplace } from '@/containers/SurveyBuilder/hooks/useCanCreateFindAndReplace';
import type { MultiTextboxQuestion, SurveyRichText } from '@/types/survey';
import { Input, NumberInput } from '@/components/Input';
import type { SurveyRichTextEditorOnChange } from '@/components/Survey.RichText';
import { SurveyRichTextEditorContainer, useSurveyRichTextEditor, SurveyRichTextEditor } from '@/components/Survey.RichText';
import { FindAndReplaceButton } from '@/components/Survey.RichText/Editor.FindAndReplace.Button';
import { RationaleCheckbox, ShowDontKnowCheckbox } from './Settings';
import styles from './style/MultiTextbox.Question.css';
import { LanguageEditingBarrier } from './LanguageEditingBarrier';

type Props = {
  item: MultiTextboxQuestion.Question;
};

export const MultiTextboxQuestionBuilder = ({ item }: Props) => {
  const [_, dispatch] = useSurveyBuilderState();

  const handleMinimumEntriesChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value;

    dispatch({
      questionIdentifier: item.base.identifier,
      settings: {
        ...item.settings,
        minimumEntries: value,
      },
      type: 'update-question-settings',
    });
  }, [
    dispatch,
    item.base.identifier,
    item.settings,
  ]);

  const handleMaximumEntriesChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value;
    const entryLabels = item.settings.entryLabels.slice(0, value);
    while (entryLabels.length < value) {
      entryLabels.push('');
    }

    dispatch({
      questionIdentifier: item.base.identifier,
      settings: {
        ...item.settings,
        entryLabels,
        maximumEntries: value,
      },
      type: 'update-question-settings',
    });
  }, [
    dispatch,
    item.base.identifier,
    item.settings,
  ]);

  const handleLabelChange = useCallback((value: SurveyRichText.RichTextValue) => {
    dispatch({
      questionIdentifier: item.base.identifier,
      settings: {
        ...item.settings,
        label: value,
      },
      type: 'update-question-settings',
    });
  }, [dispatch, item.base.identifier, item.settings]);

  const handleEntryLabelChange = useCallback((index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const entryLabels = item.settings.entryLabels.map((m, i) => {
      return i === index ? value : m;
    });

    dispatch({
      questionIdentifier: item.base.identifier,
      settings: {
        ...item.settings,
        entryLabels,
      },
      type: 'update-question-settings',
    });
  }, [dispatch, item.base.identifier, item.settings]);

  const renderEntryLabels = useCallback(() => {
    if (!item.settings.entryLabels.length) return null;

    return (
      <div className={styles.entryLabels}>
        <div className={styles.label}>Entry Labels</div>
        {item.settings.entryLabels.map((label, index) => (
          <div key={index} className={styles.entryLabelInput}>
            <Input
              onChange={handleEntryLabelChange(index)}
              value={label} />
          </div>
        ))}
      </div>
    );
  }, [
    handleEntryLabelChange,
    item.settings.entryLabels,
  ]);

  return (
    <div>
      <div className={styles.settings}>

        <div className={styles.item}>
          <div className={styles.label}>Label</div>
          <EditorContainer
            onChange={handleLabelChange}
            value={item.settings.label}
            identifier={`${item.base.identifier}:settings:label`} />
        </div>

        <div className={styles.entrySettings}>
          <div className={styles.item}>
            <div className={styles.label}>Minimum Textboxes</div>
            <NumberInput
              onChange={handleMinimumEntriesChange}
              value={item.settings.minimumEntries} />
          </div>
          <div className={styles.item}>
            <div className={styles.label}>Maximum Textboxes</div>
            <NumberInput
              onChange={handleMaximumEntriesChange}
              value={item.settings.maximumEntries} />
          </div>
        </div>

        {renderEntryLabels()}

        <LanguageEditingBarrier>
          <ShowDontKnowCheckbox />
          <RationaleCheckbox className={styles.checkbox} />
        </LanguageEditingBarrier>

      </div>

    </div>
  );
};

type EditorProps = {
  onChange: (value: SurveyRichText.RichTextValue) => void;
  value: SurveyRichText.RichTextValue;
  identifier: string;
};

const EditorContainer = ({ onChange, value, identifier }: EditorProps) => {
  const canFar = useCanCreateFindAndReplace();

  const { manager, state, setState } = useSurveyRichTextEditor({
    initialState: value,
  });

  const handleChange: SurveyRichTextEditorOnChange = useCallback(params => {
    if (!params.tr?.docChanged) return;

    setState(params.state);

    const value = params.state.doc.toJSON() as SurveyRichText.RichTextValue;

    onChange(value);
  }, [
    onChange,
    setState,
  ]);

  const editorRef = useRichTextEditorRegister({
    identifier,
  });

  return (
    <SurveyRichTextEditorContainer
      manager={manager}
      onChange={handleChange}
      state={state}
      editorRef={editorRef}>
      <SurveyRichTextEditor className={styles.editor} />
      {canFar ? <FindAndReplaceButton className={styles.editorBtn} /> : null}
    </SurveyRichTextEditorContainer>
  );
};

export default MultiTextboxQuestionBuilder;