import { useCallback, Fragment } from 'react';
import { SurveySumConditionItemType, SurveyQuestionType } from '@enums';
import { getOperatorText } from '@utils/operator';
import { useSurveyBuilderState, useParseSurveyRichText } from '@/containers/SurveyBuilder';
import { SurveyLogic, SurveyQuestion } from '@/types';
import { ConditionTooltip } from './Logic.ConditionTooltip';
import styles from './style/Logic.Item.css';

type Props = {
  condition: SurveyLogic.SumCondition;
};

type SumItemProps = {
  label: string;
  text: string;
};

export const SumCondition = ({ condition }: Props) => {

  const [state] = useSurveyBuilderState();

  const parseSurveyRichText = useParseSurveyRichText();

  const getQuestionSumItems = useCallback((item: SurveyLogic.SumConditionItem) => {
    const question = state.survey.questions.find(f => f.base.identifier === item.question.identifier);

    switch (item.type) {
      case SurveySumConditionItemType.NumberTableCellValue: {
        const questionText = parseSurveyRichText(question.value);
        const q = question as SurveyQuestion<SurveyQuestionType.NumberInputTable>;

        return item.values.reduce<SumItemProps[]>((acc, m) => {
          const row = q.matrixRows.find(f => f.base.identifier === m.row.identifier);
          const option = q.options.find(f => f.base.identifier === m.option.identifier);

          const tooltipLabel = [
            `Q: ${questionText}`,
            `R: ${parseSurveyRichText(row.value)}`,
            `C: ${parseSurveyRichText(option.value)}`,
          ].join(`\n`);
          const tooltipText = `Q${question.ordinal}:R${row.ordinal}/C${option.ordinal}`;

          return acc.concat({
            label: tooltipLabel,
            text: tooltipText,
          });
        }, []);
      }

      case SurveySumConditionItemType.SliderRowValue: {
        const questionText = parseSurveyRichText(question.value);
        const q = question as SurveyQuestion<SurveyQuestionType.Sliders>;

        return item.values.reduce<SumItemProps[]>((acc, m) => {
          const row = q.matrixRows.find(f => f.base.identifier === m.row.identifier);
          const tooltipLabel = [
            `Q: ${questionText}`,
            `R: ${parseSurveyRichText(row.value)}`,
          ].join(`\n`);
          const tooltipText = `Q${question.ordinal}:R${row.ordinal}`;

          return acc.concat({
            label: tooltipLabel,
            text: tooltipText,
          });
        }, []);
      }

      default:
        throw new UnreachableCaseError(item);
    }
  }, [
    parseSurveyRichText,
    state.survey.questions,
  ]);

  const items = condition.data.items.reduce<SumItemProps[]>((acc, x) => {
    const questionItems = getQuestionSumItems(x);

    return acc.concat(questionItems);
  }, []);

  const rendered = items.filter(Boolean).map((m, i) => (
    <Fragment
      key={i}>
      <ConditionTooltip
        label={m.label}
        text={m.text} />
      {i !== items.length - 1 && <span className={styles.plus}> + </span>}
    </Fragment>
  ));

  return (
    <>
      sum of ({rendered}) is {getOperatorText(condition.data.operator)} {condition.data.comparate}
    </>
  );
};