import { useIsInternalUser } from '@/containers/Store';
import PastSearches from './PastSearches';
import RecentProfiles from './RecentProfiles';

export default function ResultsEmptyQuery() {
  const isInternalUser = useIsInternalUser();

  return (
    <div>
      {isInternalUser && <RecentProfiles />}
      <PastSearches />
    </div>
  );
}

export { ResultsEmptyQuery };