import type { AxiosResponse } from 'axios';

export function parseErrorMessage(res: AxiosResponse) {

  if (typeof res.data === 'object') {
    return (res.data as APIError).errors.map(m => m.message).join('\n');
  }

  if (typeof res.data === 'string') {
    return res.data.split('\n')[0];
  }

  return 'Unknown error';
}

export type APIError = {
  errors: {
    message: string;
  }[];
  message: string;
};