import { useState, useCallback, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import * as consts from '@consts';
import * as $email from '@services/auth/email';
import { Input } from '@/components/Input';
import logosentiment from 'static/images/logo-sentiment-square.png';
import styles from './style.css';

type Form = {
  email: string;
};

const initialForm: Form = {
  email: '',
};

const ForgotPassword = () => {
  const [form, setForm] = useState<Form>(initialForm);
  const [sentMessage, setSentMessage] = useState('');

  const handleSubmit = useCallback((event: React.SyntheticEvent) => {
    event.preventDefault();

    const { email } = form;
    $email.changePassword({
      email,
    })
      .then((result: string) => {
        setForm(initialForm);
        setSentMessage(result);
      });

    return false;
  }, [form]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      email: e.target.value,
    });
  }, []);

  const isSent = sentMessage && sentMessage.length > 0;

  return (
    <>
      <Helmet title="Forgot Password" />
      <div className={styles.nav}>
        <div className={styles.logo}>
          <Link to={consts.path.Website.Login}>
            <img
              className={styles.img}
              src={logosentiment} />
          </Link>
        </div>
      </div>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.header}>
              Forgot Password?
            </div>
            <p className={styles.descriptor}>A password reset link will be sent to your email address.</p>
            {isSent && (
              <Fragment>
                <br /><div className={styles.success}>{sentMessage}</div>
              </Fragment>
            )}
            {!isSent && (
              <Fragment>
                <Input
                  autoFocus
                  className={styles.input}
                  name="email"
                  onChange={handleChange}
                  placeholder="Email"
                  type="text"
                  value={form.email} />
                <div className={styles.submit}>
                  <button
                    className={styles.btn}
                    type="submit">
                    Send
                  </button>
                </div>
              </Fragment>)}
          </form>
        </div>
      </div>
    </>
  );

};

export { ForgotPassword };
export default ForgotPassword;