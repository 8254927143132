import { useEffect, useState } from 'react';
import { ProjectSurveyResponsesLoadingContext, SurveyStarredAnswersContainer, SurveyRespondentContainer } from '@containers/SurveyResponses';
import { SurveyContext } from '@containers/Survey/Context';
import { useProjectState } from '@containers/GroupProject/hooks/useProjectState';
import { useProjectSurveyResponsesState } from '../SurveyResponses/hooks';
import { RepeatSurveyResponseDataContext } from './Context';
import { useFetchRepeatSurveyResponses } from './hooks/useFetchRepeatSurveyResponses';

export const RepeatResponsesDataContainer = (props: ChildrenProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const { projectParent } = useProjectState();
  const { query } = useProjectSurveyResponsesState();

  const responses = useFetchRepeatSurveyResponses({
    projectParentId: projectParent.id,
    surveyId: query.surveyId,
  }, {
    keepPreviousData: true,
  });

  useEffect(() => {
    setLoading(responses.isLoading || !responses.data);
  }, [
    responses.isLoading,
    responses.data,
  ]);

  return (
    <ProjectSurveyResponsesLoadingContext.Provider value={loading}>
      <RepeatSurveyResponseDataContext.Provider value={responses.data?.data}>
        <SurveyContext.Provider value={responses.data?.survey || null}>
          <SurveyStarredAnswersContainer initial={responses.data?.starred || {}}>
            <SurveyRespondentContainer data={responses.data?.respondents || { values: {} }}>
              {props.children}
            </SurveyRespondentContainer>
          </SurveyStarredAnswersContainer>
        </SurveyContext.Provider>
      </RepeatSurveyResponseDataContext.Provider>
    </ProjectSurveyResponsesLoadingContext.Provider>
  );
};