import { RouteProps } from 'react-router-dom';

export enum ResultTab {
  Members   = 'Experts',
  Leads     = 'Leads'
}

export type Scene = {
  key:       ResultTab;
  location:  Partial<RouteProps['location']>;
  title:     React.ReactNode;
};

export type SceneMap = {
  [key in ResultTab]: Scene;
};

export declare namespace BuildSceneMap {
  export type Return = SceneMap;
}