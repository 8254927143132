import { useCallback, useEffect, useState } from 'react';
import * as api from '@api';
import { useProjectOrLatestChild } from '@containers/GroupProject/hooks/useProjectState';
import { ProjectSurveyStarredResponses } from '@/types';
import { ProjectSurveyStarredAnswersState, ProjectSurveyStarredAnswersStateContext } from './Context';
import { useProjectSurveyResponsesState } from './hooks/useProjectSurveyResponsesState';
import { StarringParams } from './interfaces';

type Props = {
  initial: ProjectSurveyStarredResponses;
} & ChildrenProps;

export const SurveyStarredAnswersContainer = (props: Props) => {
  const project = useProjectOrLatestChild();
  const { query } = useProjectSurveyResponsesState();
  const [starred, setStarred] = useState<ProjectSurveyStarredResponses>(props.initial);

  useEffect(() => {
    setStarred(props.initial);
  }, [props.initial]);

  const onToggle = useCallback((data: StarringParams) => () => {
    const value = !starred[data.questionId].includes(data.projectSurveyResponseId);
    return api.projects.surveys.starProjectSurveyResponseAnswer({
      projectId: project.id,
      value,
      projectSurveyResponseId: data.projectSurveyResponseId,
      surveyQuestionId: query.questionId,
    }).then(_ => {
      if (value) {
        setStarred({
          ...starred,
          [data.questionId]: [...starred[data.questionId], data.projectSurveyResponseId],
        });
      } else {
        setStarred({
          ...starred,
          [data.questionId]: starred[data.questionId]
            .filter(f => f !== data.projectSurveyResponseId),
        });
      }
    });
  }, [
    project.id,
    query.questionId,
    setStarred,
    starred,
  ]);

  const value: ProjectSurveyStarredAnswersState = {
    initial: props.initial,
    onToggle,
    starred,
  };

  return (
    <ProjectSurveyStarredAnswersStateContext.Provider value={value}>
      {props.children}
    </ProjectSurveyStarredAnswersStateContext.Provider>
  );
};

export default SurveyStarredAnswersContainer;