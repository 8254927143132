import { SurveyTemplateType } from '@enums/survey.template';
import { SurveyTemplateBuilder } from '../interfaces';

type State = SurveyTemplateBuilder.State;

function templateDataUpdated(state: SurveyTemplateBuilder.State, updater: Updater) {
  return {
    ...state,
    data: updater(state.data),
  };
}

type Updater = (state: SurveyTemplateBuilder.State['data']) => SurveyTemplateBuilder.State['data'];

export function templateMedicalConditionUpdated(state: State, action: SurveyTemplateBuilder.MedicalConditionUpdated.Action): State {
  return templateDataUpdated(state, s => ({
    ...s,
    medicalCondition: action.value,
  }));
}

export function templateDrugUpdated(state: State, action: SurveyTemplateBuilder.DrugUpdated.Action): State {
  return templateDataUpdated(state, s => ({
    ...s,
    drug: action.value,
  }));
}

export function templateLaunchYearUpdated(state: State, action: SurveyTemplateBuilder.LaunchYearUpdated.Action): State {
  return templateDataUpdated(state, s => ({
    ...s,
    launchYear: action.value,
  }));
}

export function templateTrialDesignUpdated(state: State, action: SurveyTemplateBuilder.TrialDesignUpdated.Action): State {
  return templateDataUpdated(state, s => ({
    ...s,
    trialDesign: action.value,
  }));
}

export function templateQualityOfLifeUpdated(state: State, action: SurveyTemplateBuilder.QualityOfLifeUpdated.Action): State {
  return templateDataUpdated(state, s => ({
    ...s,
    qualityOfLife: action.value,
  }));
}

export function safetyEndpointsUpdated(state: State, action: SurveyTemplateBuilder.SafetyEndpointsUpdated.Action): State {
  return templateDataUpdated(state, s => ({
    ...s,
    safetyEndpoints: action.value,
  }));
}

export function efficacyEndpointsUpdated(state: State, action: SurveyTemplateBuilder.EfficacyEndpointsUpdated.Action): State {
  return templateDataUpdated(state, s => ({
    ...s,
    efficacyEndpoints: action.value,
  }));
}

export function otherAttributesUpdated(state: State, action: SurveyTemplateBuilder.OtherAttributesUpdated.Action): State {
  return templateDataUpdated(state, s => ({
    ...s,
    otherAttributes: action.value,
  }));
}

export function drugIndicationUpdated(state: State, action: SurveyTemplateBuilder.DrugIndicationUpdated.Action): State {
  return templateDataUpdated(state, s => ({
    ...s,
    drugIndication: action.value,
  }));
}

export function pricingComparatorsUpdated(state: State, action: SurveyTemplateBuilder.PricingComparatorsUpdated.Action): State {
  return templateDataUpdated(state, s => ({
    ...s,
    pricingComparators: action.value,
  }));
}

export function treatmentComparatorsUpdated(state: State, action: SurveyTemplateBuilder.TreatmentComparatorsUpdated.Action): State {
  return templateDataUpdated(state, s => ({
    ...s,
    treatmentComparators: action.value,
  }));
}

export function drugPriceUpdated(state: State, action: SurveyTemplateBuilder.DrugPriceUpdated.Action): State {
  return templateDataUpdated(state, s => ({
    ...s,
    drugPrice: action.value,
  }));
}

export function treatmentCategoryUpdated(state: State, action: SurveyTemplateBuilder.TreatmentCategoryUpdated.Action): State {
  return templateDataUpdated(state, s => ({
    ...s,
    treatmentCategory: action.value,
  }));
}

export function stimulusUpdated(state: State, action: SurveyTemplateBuilder.StimulusUpdated.Action): State {
  return templateDataUpdated(state, s => ({
    ...s,
    stimulus: action.value,
  }));
}

export function clientUpdated(state: State, action: SurveyTemplateBuilder.ClientUpdated.Action): State {
  return templateDataUpdated(state, s => ({
    ...s,
    client: action.value,
  }));
}

export function tppUrlUpdated(state: State, action: SurveyTemplateBuilder.TPPUrlUpdated.Action): State {
  return templateDataUpdated(state, s => ({
    ...s,
    tppUrl: action.value,
  }));
}

export function tppUpdated(state: State, action: SurveyTemplateBuilder.TPPUpdated.Action): State {
  return templateDataUpdated(state, s => ({
    ...s,
    tpp: action.value,
  }));
}

export function templateCategoryUpdated<T extends SurveyTemplateType = SurveyTemplateType>(state: SurveyTemplateBuilder.State<T>, action: SurveyTemplateBuilder.TemplateCategoryUpdated.Action): SurveyTemplateBuilder.State<T> {

  return {
    ...state,
    data: {
      ...state.data,
      category: action.value,
    },
  };
}

export function templateTargetUpdated<T extends SurveyTemplateType = SurveyTemplateType>(state: SurveyTemplateBuilder.State<T>, action: SurveyTemplateBuilder.TemplateTargetUpdated.Action): SurveyTemplateBuilder.State<T> {
  return {
    ...state,
    data: {
      ...state.data,
      target: action.value,
    },
  };
}

export function templateCategoriesUpdated<T extends SurveyTemplateType = SurveyTemplateType>(state: SurveyTemplateBuilder.State<T>, action: SurveyTemplateBuilder.TemplateCompetitorsUpdated.Action): SurveyTemplateBuilder.State<T> {
  return {
    ...state,
    data: {
      ...state.data,
      competitors: action.value,
    },
  };
}

export function templateEvaluationCriteriaUpdated<T extends SurveyTemplateType = SurveyTemplateType.PersonaResearch>(state: SurveyTemplateBuilder.State<T>, action: SurveyTemplateBuilder.EvaluationCriteriaUpdated.Action): SurveyTemplateBuilder.State<T> {
  return {
    ...state,
    data: {
      ...state.data,
      evaluationCriteria: action.value,
    },
  };
}

export function templatePartnershipCriteriaUpdated<T extends SurveyTemplateType = SurveyTemplateType.PersonaResearch>(state: SurveyTemplateBuilder.State<T>, action: SurveyTemplateBuilder.PartnershipCriteriaUpdated.Action): SurveyTemplateBuilder.State<T> {
  return {
    ...state,
    data: {
      ...state.data,
      partnershipCriteria: action.value,
    },
  };
}

export function templateRolesUpdated<T extends SurveyTemplateType = SurveyTemplateType.PersonaResearch>(state: SurveyTemplateBuilder.State<T>, action: SurveyTemplateBuilder.TargetRolesUpdated.Action): SurveyTemplateBuilder.State<T> {
  return {
    ...state,
    data: {
      ...state.data,
      roles: action.value,
    },
  };
}

export function doseAdministrationUpdated(state: State, action: SurveyTemplateBuilder.DoseAdministrationUpdated.Action): State {
  return templateDataUpdated(state, s => ({
    ...s,
    doseAdministration: action.payload.value,
  }));
}

export function templateAdditionalPricesUpdated(state: State, action: SurveyTemplateBuilder.AdditionalPricesUpdated.Action): State {
  return templateDataUpdated(state, s => ({
    ...s,
    additionalPriceLinks: action.payload.value,
  }));
}

export function medicalSpecialtiesUpdated(state: State, action: SurveyTemplateBuilder.MedicalSpecialtiesUpdated.Action): State {
  return templateDataUpdated(state, s => ({
    ...s,
    specialties: action.payload.value,
  }));
}

export function mtMedicalConditionsUpdated(state: State, action: SurveyTemplateBuilder.MTMedicalConditionsUpdated.Action): State {
  return templateDataUpdated(state, s => ({
    ...s,
    medicalConditions: action.payload.value,
  }));
}

export function messageCategoriesUpdated(state: State, action: SurveyTemplateBuilder.MessageCategoriesUpdated.Action): State {
  return templateDataUpdated(state, s => ({
    ...s,
    categories: action.payload.value,
  }));
}

export function productUpdated(state: State, action: SurveyTemplateBuilder.TemplateProductUpdated.Action): State {
  return templateDataUpdated(state, s => ({
    ...s,
    product: action.payload.value,
  }));
}

export function attitudinalsUpdated(state: State, action: SurveyTemplateBuilder.TemplateAttitudinalsUpdated.Action): State {
  return templateDataUpdated(state, s => ({
    ...s,
    attitudinals: action.payload.value,
  }));
}

export function qualificationVariableUpdated(state: State, action: SurveyTemplateBuilder.TemplateQualificationMTUpdated.Action): State {
  return templateDataUpdated(state, s => ({
    ...s,
    qualification: action.payload.value,
  }));
}