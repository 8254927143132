import type {
  CreateExtensionPlugin,
} from '@remirror/core';
import {
  command,
  extension,
  getTextSelection,
  helper,
  within,
  CommandFunction,
  ExtensionPriority,
  Helper,
  PlainExtension,
  PrimitiveSelection,
} from '@remirror/core';
import type { EditorState } from '@remirror/pm/state';
import { HighlightState } from './Extension.Highlight.State';
import type * as HE from './interfaces.extension.highlight';
import { getRemirrorCoords } from './utils';

@extension({
  defaultOptions: {},
  defaultPriority: ExtensionPriority.Low,
})
export class HighlightExtension<T extends HE.BaseHighlight = HE.BaseHighlight> extends PlainExtension {
  get name() {
    return 'highlight' as const;
  }

  getHighlightState(): HighlightState<T> {
    return this.getPluginState();
  }

  dispatchAction(action: HE.Action<T>): CommandFunction {

    return ({ tr, dispatch }) => {
      const items = tr.getMeta(HighlightExtension.name) as HE.Action<T>[] || [];

      dispatch?.(
        tr.setMeta(HighlightExtension.name, [
          ...items,
          action,
        ].filter(Boolean)),
      );

      return true;
    };
  }

  createPlugin(): CreateExtensionPlugin<HighlightState<T>> {
    const pluginState = new HighlightState<T>();

    return {
      state: {
        init() {
          return pluginState;
        },
        apply(tr) {
          const actions = tr.getMeta(HighlightExtension.name) as HE.Action<T>[];
          return pluginState.apply({ tr, actions });
        },
      },
      props: {
        decorations(state: EditorState) {
          return this.getState(state).decorationSet;
        },
      },
    };
  }

  @command()
  addHighlight(highlight: T): CommandFunction {
    return this.dispatchAction({
      highlight,
      type: 'add-highlight',
    } as HE.AddHighlight.Action<T>);
  }

  @command()
  blurHighlight(): CommandFunction {
    return this.dispatchAction({
      type: 'blur-highlight',
    } as HE.BlurHighlight.Action);
  }

  @command()
  focusHighlight(highlightId: string, showTooltip: boolean): CommandFunction {
    return this.dispatchAction({
      highlight: {
        id: highlightId,
        showTooltip,
      },
      type: 'focus-highlight',
    } as HE.FocusHighlight.Action);
  }

  @command()
  removeHighlights(highlightIds: string[]): CommandFunction {
    return this.dispatchAction({
      type: 'remove-highlights',
      highlightIds,
    } as HE.RemoveHighlights.Action);
  }

  @command()
  setHighlights(highlights: Array<T>): CommandFunction {
    return this.dispatchAction({
      type: 'set-highlights',
      highlights,
    } as HE.SetHighlights.Action<T>);
  }

  @command()
  updateHighlight(highlight: HE.UpdateHighlight.Action<T>['highlight']): CommandFunction {
    return this.dispatchAction({
      type: 'update-highlight',
      highlight,
    } as HE.UpdateHighlight.Action<T>);
  }

  @helper()
  getHighlightsInitialized(): Helper<boolean> {
    const state = this.getHighlightState();

    return state.initialized;
  }

  @helper()
  getFocusedHighlight(): Helper<HE.HighlightWithText & { showTooltip: boolean }> {
    const state = this.getHighlightState();

    const highlight = state.getHighlight(state.focusedHighlight?.id);

    if (!highlight) return null;

    return {
      ...highlight,
      text: this.getHighlightText(highlight.id),
      showTooltip: state.focusedHighlight.showTooltip,
    } as Helper<HE.HighlightWithText & { showTooltip: boolean }>;
  }

  @helper()
  getBasicHighlight(id: string): Helper<HE.BaseHighlight> {
    const state = this.getHighlightState();
    const highlight = state.getHighlight(id);

    return highlight;
  }

  @helper()
  getHighlightPosition(id: string): Helper<number> {
    const state = this.getHighlightState();
    const highlight = state.getHighlight(id);

    if (!highlight) return null;

    return getRemirrorCoords(this.store.view, highlight.from);
  }

  // @ts-ignore
  @helper()
  getHighlight(id: string): Helper<HE.HighlightWithText> {
    const state = this.getHighlightState();
    const highlight = state.getHighlight(id);

    return {
      ...highlight,
      text: this.getHighlightText(id),
    } as Helper<HE.HighlightWithText>;
  }

  @helper()
  getHighlightsAt(pos?: PrimitiveSelection): Helper<HE.HighlightWithText[]> {
    const highlights: HE.HighlightWithText[] = [];
    const { doc } = this.store.getState();
    const state = this.getHighlightState();
    const { from, to } = getTextSelection(pos, doc);

    for (const highlight of state.highlights) {
      if (
        within(from, highlight.from, highlight.to) ||
        within(to, highlight.from, highlight.to) ||
        within(highlight.from, from, to) ||
        within(highlight.to, from, to)
      ) {
        highlights.push({
          ...highlight,
          text: this.getHighlightText(highlight.id),
        });
      }
    }

    return highlights;
  }

  @helper()
  getHighlights(): Helper<HE.BaseHighlight[]> {
    const state = this.getHighlightState();
    return state.highlights;
  }

  @helper()
  getHighlightsWithText(): Helper<HE.HighlightWithText[]> {
    const state = this.getHighlightState();
    return state.highlights.map(h => ({
      ...h,
      text: this.getHighlightText(h.id),
    }));
  }

  @helper()
  isHighlightFocused(): Helper<boolean> {
    const state = this.getHighlightState();

    if (!state.focusedHighlight?.id) return false;

    const highlight = state.getHighlight(state.focusedHighlight.id);

    return !!highlight;
  }

  private getHighlightText(id: string) {
    const { doc } = this.store.getState();
    const state = this.getHighlightState();
    const highlight = state.getHighlight(id);

    return doc.textBetween(highlight.from, highlight.to);
  }
}