import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useDownloader } from '@/utils';
import { Button } from '@/components/Button';
import ActivityIndicator from '@/components/ActivityIndicator';
import { getDownloadUrls } from '@api/calls';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { useModal } from '@/components/Modal/hooks';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import { Header } from '@/components/Modal/Header';

type Props = Pick<ModalProps, 'onClose' | 'open'> & ICallId;

export const MediaDownloadModal = (props: Props) => {
  const download = useDownloader();

  const { isLoading, data: downloadUrls } = useQuery(['get-call-download-urls'], () => {
    return getDownloadUrls({ callId: props.callId });
  }, {
    enabled: props.open,
  });

  const onClickDownload = useCallback((url: string) => {
    return () => {
      download({ url });
      props.onClose();
    };
  }, [download, props]);

  const DownloadContent = useCallback(() => {
    return (
      <ButtonSet>
        <DownloadButton
          label='Download Video + Audio'
          onClick={onClickDownload(downloadUrls.video)}
          disabled={!downloadUrls?.video} />
        <DownloadButton
          label='Download Audio'
          onClick={onClickDownload(downloadUrls.audio)}
          disabled={!downloadUrls?.audio} />
      </ButtonSet>
    );
  }, [downloadUrls, onClickDownload]);

  return (
    <Modal
      disableOverlayClick={isLoading}
      onClose={props.onClose}
      open={props.open}>
      <Header>Download Transcript Media</Header>

      {isLoading ? <ActivityIndicator /> : <DownloadContent />}
    </Modal>
  );
};

export const useMediaDownloadModal = () => useModal(MediaDownloadModal);

type ButtonProps = {
  disabled: boolean;
  label: string;
  onClick: () => void;
};
const DownloadButton = ({ disabled, label, onClick }: ButtonProps) => {
  return (
    <Button
      variant='brick'
      color='primary'
      size='small'
      disabled={disabled}
      onClick={onClick}>
      {label}
    </Button>
  );
};