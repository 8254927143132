import { useRemirrorContext } from '@remirror/react-core';
import { useEffect } from 'react';
import { SurveyRichText } from '@/types';

type Props = {
  value: SurveyRichText.RichTextValue;
} & ChildrenProps;

export const SurveyRichTextUpdater = (props: Props) => {
  const { setContent } = useRemirrorContext();

  useEffect(() => {
    setContent(props.value);
  }, [props.value, setContent]);

  return (
    <>
      {props.children}
    </>
  );
};