import { useContext, useEffect } from 'react';
import * as Conference from '$admin/components/Conference.Settings';
import * as $conference from '$admin/containers/Call/utils';
import { defaultConferenceDetailsContent, useConferenceDetailsEditor } from '@/components/Conference';
import { CallDetailsContext } from './Context';
import * as Layout from './Layout';
import styles from './style/Field.css';

type Props = unknown;

export const Details = (props: Props) => {
  const ctx = useContext(CallDetailsContext);

  const details = useConferenceDetailsEditor({
    initialState: ctx.query.data.settings.conferenceDetails,
    placeholder: `Enter conference details`,
  });

  useEffect(() => {

    if (details.manager.view) {
      details.manager.view.updateState(details.manager.createState({
        content: ctx.query.data.settings.conferenceDetails || defaultConferenceDetailsContent,
      }));
    }

  }, [
    details.manager,
    ctx.query.data.settings.conferenceDetails,
  ]);

  if (!$conference.showConferenceDetails(ctx.query.data.settings)) return null;

  return (
    <div className={styles.row}>
      <Layout.Label>
        Client Invitation Details
      </Layout.Label>
      <Conference.Field.Details
        editing={false}
        manager={details.manager}
        state={details.state}
        values={{ conferenceDetails: ctx.query.data.settings.conferenceDetails }} />
    </div>
  );
};

Details.displayName = 'Call.Main.Field.Details';