import { useCallback } from 'react';
import * as Sentry from '@sentry/react';
import { useSelectUser } from '@containers/Store/hooks';

export const useInitSentry = () => {
  const user = useSelectUser();

  const initSentry = () => {
    Sentry.configureScope(scope => {
      scope.setUser({
        email: user.contact.email,
        id: `${user.id}`,
      });
    });
  };

  return useCallback(initSentry, [
    user,
  ]);
};