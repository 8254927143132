import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ChatContextType } from '@/brand-insights/enums';
import type { BrandInsightsCapabilities, Chat } from '@/brand-insights/types';
import { ChatSettingsContainer } from '@/brand-insights/components/Chat';
import { ChatStateContainer } from '@/brand-insights/components/Chat.State';
import { LinkStateContainer } from '@/brand-insights/components/BrandInsights/Link.Container';
import { ChatPageContextDataContext, DispatchPageContextDataContext } from '@/brand-insights/components/Chat/context';
import type { BrandInsightsPageContext } from '@/brand-insights/types';
import { ChatContainer } from '@/brand-insights/components/BrandInsights/Chat.Container';
import { StandaloneThemeContainer } from './ThemeProvider';

type Props = {
  author: Chat.Creator;
  capabilities: BrandInsightsCapabilities;
} & ChildrenProps;

type RouteParams = {
  chatIdentifier: string;
};

export const BrandInsightsStandaloneContainer = (props: Props) => {

  const [contextData, setContextData] = useState<BrandInsightsPageContext.Item>({
    type: ChatContextType.Global,
  });

  return (
    <StandaloneThemeContainer>
      <DispatchPageContextDataContext.Provider value={setContextData}>
        <ChatPageContextDataContext.Provider value={contextData}>
          <ChatSettingsContainer>
            <ChatStateContainer>
              <ChatIdentifierContainer>
                <ChatContainer author={props.author} capabilities={props.capabilities}>
                  {props.children}
                </ChatContainer>
              </ChatIdentifierContainer>
            </ChatStateContainer>
          </ChatSettingsContainer>
        </ChatPageContextDataContext.Provider>
      </DispatchPageContextDataContext.Provider>
    </StandaloneThemeContainer>
  );
};

function ChatIdentifierContainer(props: ChildrenProps) {
  const { chatIdentifier } = useParams<RouteParams>();

  return (
    <LinkStateContainer initialChatIdentifier={chatIdentifier}>
      {props.children}
    </LinkStateContainer>
  );
}