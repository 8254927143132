import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useEditorState } from '@remirror/react';
import { saveTranscript } from '@api/transcripts.text';
import { useTranscriptRedactingContext } from '@containers/Transcript.Redaction/hooks';
import { RefetchTranscriptContext, TranscriptUpdatesContext } from '@containers/Transcript/context';
import type { DocumentNode } from '@/types/transcribe.rich-text';
import Toast from '@/components/Toast';
import { FindReplaceContext } from '@/components/Transcript/context';
import { useHelpers } from '@/components/Transcript/hooks';

export const useSaveTranscript = ({ transcriptId }: ITranscriptId) => {
  const editorState = useEditorState();
  const redactingContext = useTranscriptRedactingContext();
  const { corrections, confirmedAutoCorrections, overwrittenAutoCorrections } = useContext(TranscriptUpdatesContext);

  const refetch = useContext(RefetchTranscriptContext);

  const { getHighlightsWithText } = useHelpers();

  const mutation = useMutation({
    mutationKey: ['save-transcript-changes', transcriptId],
    mutationFn: () => {
      return saveTranscript({
        transcriptId,
        transcript: editorState.doc.toJSON() as DocumentNode,
        highlights: getHighlightsWithText(),
        redactions: redactingContext?.redactedRanges,
        corrections,
        confirmedAutoCorrectIdentifiers: confirmedAutoCorrections,
        overwrittenAutoCorrectIdentifiers: overwrittenAutoCorrections,
      });
    }, onSuccess: () => {
      refetch();
    }, onError: () => {
      Toast.error({
        title: 'There was an error saving the transcript',
      });
    },
  });

  return mutation;
};