import { useEffect, useCallback } from 'react';
import { useHistory, useRouteMatch, matchPath } from 'react-router-dom';
import { path } from '@consts';

type Props = {
  onLeave: () => void;
};

export const useLeaveOnNavigate = ({ onLeave }: Props) => {
  const history = useHistory();
  const isConferenceRoute = useRouteMatch(path.Conference.Root);
  //Don't wire some beforeunload events on the conference page, we already have some separate events that fire for that
  const isConferenceRoom = useRouteMatch(path.Conference.Room);

  useEffect(() => {
    function onLeaveWrapper() {
      console.log('beforeunload event triggered. Disconnecting the participant');
      onLeave();
    }
    if (isConferenceRoute) {
      const unlisten = history.listen((location, action) => {
        const navigatingToConference = matchPath(location.pathname, {
          path: path.Conference.Root,
        });

        if (!navigatingToConference) {
          console.log('Navigating away from the conference. Disconnecting the participant');
          onLeave();
        }
      });

      if (!isConferenceRoom) {
        window.addEventListener('beforeunload', onLeaveWrapper);
      }

      return () => {
        unlisten();
        if (!isConferenceRoom) {
          window.removeEventListener('beforeunload', onLeaveWrapper);
        }
      };
    }
  }, [isConferenceRoute, isConferenceRoom, history, onLeave]);
};