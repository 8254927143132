import { WorkspaceExpertFilesItem } from '@/types';
import Header from './Header';
import Files from './Files';

type Props = {
  items: WorkspaceExpertFilesItem[];
}

export default function WorkspaceSidebarExpertFiles({ items }: Props) {
  return (
    <>
      <Header />
      <Files items={items} />
    </>
  );
}

export { WorkspaceSidebarExpertFiles };