import { Component } from 'react';
import * as api from '@services/api';
import * as enums from '@enums';
import AutoComplete from '@/components/AutoComplete';

const minimumCustomLength = 3;
class VICSAutocomplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recommendations: [],
      searchResults: [],
      inputText: '',
    };
  }

  static defaultProps = {
    showCustomItem: true,
  };

  componentDidMount() {
    this.getRecommendedValues();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.parentIds.length !== this.props.parentIds.length) {
      if (this.props.parentIds.length === 0) {
        this.setState({
          recommendations: [],
        });
      } else {
        this.getRecommendedValues();
      }
    }
  }

  getRecommendedValues = () => {
    const parentIds = this.props.parentIds.filter(f => Number.isInteger(f));
    if (parentIds.length > 0) {
      api.profile.getVICSChildren({
        ids: parentIds,
      }).then(data => {
        this.setState({
          recommendations: data.map(m => ({
            ...m,
            typeId: this.props.vicsType,
          })),
        });
      });
    }
  }

  handleSearchChange = e => {
    const text = e.target.value;
    this.setState({
      inputText: text,
    });

    if (!text) {
      return;
    }

    this.fetchSearchResults(text);
  }

  getAutocompleteIgnoreList = () => {
    return [
      ...this.props.selectedItems.map(m => `${m.id}_${enums.VICSType.getSearchType(this.props.vicsType)}`),
    ];
  }

  fetchSearchResults = val => {
    api.search.getAutocompleteResults({
      type: enums.VICSType.getSearchType(this.props.vicsType),
      text: val,
      ignoreList: this.getAutocompleteIgnoreList(),
    }).then(data => {
      this.setState({
        searchResults: data.map(m => ({
          ...m,
          /*
            Avoid assigning properties (typeId) which
            all observable state changes depend on.

            This could be implemented in a transformer or
            passed from a parent component as a callback
            to this component to use.
          */
          typeId: this.props.vicsType,
        })),
      });
    });
  }

  handleSearchSelect = item => {
    this.setState({
      inputText: '',
      searchResults: [],
    });

    if (item.isCustom) {
      if (this.state.inputText.length >= minimumCustomLength) {
        this.props.onSelection({
          id: `${this.props.vicsType}~${this.state.inputText}`,
          name: item.name.split(' ').map(m => m.charAt(0).toUpperCase() + m.slice(1)).join(' '),
          typeId: this.props.vicsType,
          isUserCreated: true,
        });
      }
    } else {
      this.props.onSelection({
        ...item,
        name: item.originalName,
      });
    }
  }

  getPlaceholder = () => {
    switch (this.props.vicsType) {
      case enums.VICSType.Sector:
        return 'Enter Sectors';
      case enums.VICSType.Industry:
        return `Enter ${enums.VICSType.getPluralName(enums.VICSType.Industry)}`;
      case enums.VICSType.SubIndustry:
        return `Enter ${enums.VICSType.getPluralName(enums.VICSType.SubIndustry)}`;
    }
  }

  showCustomItem = () => {
    return this.props.showCustomItem
           && this.state.inputText.length >= minimumCustomLength
           && ![...this.state.searchResults, ...this.props.selectedItems].some(s => s.name === this.state.inputText.trim())
    ;
  }

  getAutocompleteItems = () => {
    if (this.state.inputText !== '') {
      return [
        ...(this.showCustomItem() ? [{
          id: `${this.props.vicsType}~${this.state.inputText}`,
          name: this.state.inputText,
          type: enums.VICSType.getSearchType(this.props.vicsType),
          isCustom: true,
          typeId: this.props.vicsType,
        }] : []),
        ...this.state.searchResults
          .filter(f => this.state.recommendations.some(s => s.id === f.id))
          .map(m => ({ ...m, highlight: true })),
        ...this.state.searchResults
          .filter(f => !this.state.recommendations.some(s => s.id === f.id)),
      ];
    }
    else {
      return this.state.recommendations
        .filter(f => !this.props.selectedItems.some(s => s.id === f.id))
        .map(m => ({ ...m, highlight: true }));
    }
  }

  render() {
    return (
      <>
        <div className={this.props.autocompleteClass}>
          <AutoComplete.Tags
            autoFocus={this.props.autoFocus}
            refProp={this.props.refProp}
            getItemValue={item => item.name}
            items={this.getAutocompleteItems()}
            name={enums.VICSType.getName(this.props.vicsType)}
            onChange={this.handleSearchChange}
            onSelect={this.handleSearchSelect}
            placeholder={this.getPlaceholder()} />
        </div>
      </>
    );
  }
}

export default VICSAutocomplete;