import { Publisher } from '@/components/PostCreation';
import { PublishContainer } from './Post.Video.Uploader.PublishContainer';
import { StateContainer } from './Post.Video.Uploader.StateContainer';

type Props = {
  children: React.ReactNode;
};

export const UploaderContainer = (props: Props) => {
  return (
    <StateContainer>
      <PublishContainer>
        {props.children}
        <Publisher.UploadProgressPortal />
      </PublishContainer>
    </StateContainer>
  );
};

UploaderContainer.displayName = 'PostCreation.Post.Video.Uploader.Container';