import { useSelector } from 'react-redux';
import { useProfileState } from '@containers/AccountProfile';
import { ExpertRateProfile } from '@/components/ExpertRate';
import styles from './style/Profile.Header.css';

const mapState = (state: Store.State) => ({
  group: state.group,
  me: state.user,
});

const HeaderInfo = () => {
  const state = useSelector(mapState);
  const [user] = useProfileState();

  function LinkedinButton() {
    if (!user.profile.publicUrl) return null;

    return (
      <div className={styles.social}>
        <a
          href={user.profile.publicUrl}
          rel="noopener noreferrer"
          target="_blank">
          <div className={styles.external}>LinkedIn</div>
        </a>
      </div>
    );
  }

  const showRate = state.group?.features?.showConsultantRates;
  console.log(user);
  return (
    <div className={styles.info}>
      <div className={styles.name}>{user.profile.fullname}</div>
      <div className={styles.rate}>
        <ExpertRateProfile
          currency={user.rateCurrency}
          user={{
            id: user.id,
            profile: user.profile,
          }}
          observer={state.me}
          visible={showRate} />
      </div>
      <LinkedinButton />
    </div>
  );
};

export { HeaderInfo };
export default HeaderInfo;