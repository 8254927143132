import { createNamedContext } from '@utils';
import type { MatchList, Members } from './interfaces';

export const FilterOptionsContext = createNamedContext<Members.Filters.Options>(undefined, 'Search.Members.FilterOptions.Context');
export const MembersFilterContext = createNamedContext<Members.Filters.Context>(undefined, 'Search.Members.Filters.Context');
export const MembersQueryContext = createNamedContext<Members.Query.Context>(undefined, 'Search.Members.Query.Context');
export const MembersSelectedContext = createNamedContext<Members.Selected.Context>(undefined, 'Search.Members.Selected.Context');
export const MembersIndeterminateSelectedContext = createNamedContext<Members.IndeterminiteSelectedContextValue>(undefined, 'Search.Members.IndeterminateSelected.Context');
export const MatchListQueryParamsContext = createNamedContext<MatchList.QueryParamsContextValue>(undefined, 'Search.MatchList.QueryParams.Context');
export const MatchListMutationContext = createNamedContext<MatchList.MutationContextValue>(undefined, 'Search.MatchList.Mutation.Context');
export const MatchListSelectedContext = createNamedContext<MatchList.SelectedContextValue>(undefined, 'Search.MatchList.Selected.Context');