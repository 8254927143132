import { useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import * as scheduler from '@containers/Scheduler/utils';
import { CallRole, CallStatus } from '@enums';
import { Back } from '@presentation/Back';
import { CallCancellationContext } from '$admin/components/Call.Cancellation/Context';
import { CallEditSettingsContext } from '$admin/components/Call.EditSettings/Context';
import { CallReschedulingContext } from '$admin/components/Call.Rescheduling/Context';
import { ProjectActionsMenuContext } from '$admin/components/ProjectActionsMenu/Context';
import { Menu } from '$admin/components/ProjectActionsMenu/Menu';
import { CallDetailsContext } from './Context';
import * as Layout from './Layout';
import styles from './style/Header.css';

type Props = unknown;

export const Header = (props: Props) => {
  const history = useHistory();
  const ctx = useContext(CallDetailsContext);

  const cancellation = useContext(CallCancellationContext);
  const editing = useContext(CallEditSettingsContext);
  const rescheduling = useContext(CallReschedulingContext);

  const offPlatform = useMemo(() => {
    const participants = ctx.query.data?.conference?.participants || [];

    return participants.some(x => x.roleId === CallRole.PrimaryRespondent && !!x.offPlatform);
  }, [ctx.query.data?.conference?.participants]);

  const handleClickCancel = useCallback(() => {
    cancellation.setContext({
      callId: ctx.query.data.call.id,
      externalRespondent: offPlatform,
    });
  }, [
    cancellation,
    ctx.query.data?.call?.id,
    offPlatform,
  ]);

  const handleClickEdit = useCallback(() => {
    editing.setContext({
      callId: ctx.query.data.call.id,
      projectId: ctx.query.data.project.id,
    });
  }, [
    editing,
    ctx.query.data?.call?.id,
    ctx.query.data?.project?.id,
  ]);

  const handleClickReschedule = useCallback(() => {
    rescheduling.setContext({
      callId: ctx.query.data.call.id,
      externalRespondent: offPlatform,
    });
  }, [
    rescheduling,
    ctx.query.data?.call?.id,
    offPlatform,
  ]);

  return (
    <Layout.Row>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.main}>
            <Back onClick={() => history.goBack()} />
            {ctx.editable &&
              <Menu>
                <Action
                  disabled={!canEdit(ctx.query.data.call.statusId)}
                  onClick={handleClickEdit}>
                  Edit
                </Action>
                <Action
                  disabled={!scheduler.calls.isConfirmed(ctx.query.data.call)}
                  onClick={handleClickReschedule}>
                  Reschedule
                </Action>
                <Action
                  disabled={!scheduler.calls.canCancelCall(ctx.query.data.call)}
                  onClick={handleClickCancel}>
                  Cancel
                </Action>
              </Menu>}
          </div>
        </div>
      </div>
    </Layout.Row>
  );
};

Header.displayName = 'Call.Main.Header';

type ActionProps = {
  children:  React.ReactNode;
  disabled?: boolean;
  onClick:   () => void;
};

const Action = ({ onClick, ...props }: ActionProps) => {
  const { close } = useContext(ProjectActionsMenuContext);
  const handleClick = useCallback(() => {
    onClick();
    close();
  }, [
    close,
    onClick,
  ]);

  return (
    <MenuItem
      className={styles.item}
      disabled={props.disabled}
      onClick={handleClick}>
      {props.children}
    </MenuItem>
  );
};

function canEdit(statusId: CallStatus) {
  return ![
    CallStatus.Completed,
    CallStatus.MissedByClient,
    CallStatus.Rejected,
    CallStatus.Cancelled,
  ].includes(statusId);
}