import { useCallback } from 'react';
import { useSubmitSurveyDraft, useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import { useToggle } from '@utils/hooks';
import { useSurveyBuilderItem } from '@containers/SurveyBuilder/Context';
import { DeleteAlternateImagesAlert } from '@/components/SurveyBuilder.AIE/Alert.Deletion';
import { SurveyAlternateImagesBuilderDeleteContext, useSurveyAlternateImagesBuilderItemContext } from './context';

type Props =
  ChildrenProps;

export const AlternateImagesBuilderDeleteContainer = (props: Props) => {

  const [_, dispatch] = useSurveyBuilderState();
  const exercise = useSurveyAlternateImagesBuilderItemContext();
  const item = useSurveyBuilderItem();

  const submitDraft = useSubmitSurveyDraft();

  const [showAlert, toggleAlert] = useToggle(false);

  const confirmDelete = useCallback(() => {
    dispatch({
      type: 'aie-removed',
      itemIdentifier: item.identifier,
      exerciseIdentifier: exercise.identifier,
    });
    toggleAlert();
    submitDraft();
  }, [
    dispatch,
    toggleAlert,
    item.identifier,
    submitDraft,
    exercise.identifier,
  ]);

  return (
    <>
      <SurveyAlternateImagesBuilderDeleteContext.Provider value={toggleAlert}>
        {props.children}
      </SurveyAlternateImagesBuilderDeleteContext.Provider>
      {showAlert &&
        <DeleteAlternateImagesAlert
          open={true}
          onConfirm={confirmDelete}
          onClose={toggleAlert} />
      }
    </>
  );
};