import { useContext } from 'react';
import { SearchQueryLegacyContext } from '../Context';

type Params = unknown;

const useCompatSearchQuery = () => {
  return useContext(SearchQueryLegacyContext);
};

export { useCompatSearchQuery };
export default useCompatSearchQuery;