import { useMemo } from 'react';
import { useSelectedSurvey } from '@/components/SurveySelector/context';
import { QuestionResponses } from '@/components/SurveyResponses/QuestionResponses';
import { SurveyQuestionResponsesContext } from './Context';
import { useActiveQuestion } from './hooks/useActiveQuestion';
import { useProjectSurveyResponses } from './hooks/useProjectSurveyResponses';

export const QuestionResponsesContainer = () => {
  const { selectedSurvey } = useSelectedSurvey();
  const question = useActiveQuestion();
  const allResponses = useProjectSurveyResponses();

  const value = useMemo(() => {
    if (!question) {
      return {
        total: 0,
        question: null,
        rationale: null,
        responses: null,
      };
    }

    const response = allResponses?.[question.base.id];

    if (!response) {
      return {
        total: 0,
        question,
        rationale: null,
        responses: null,
      };
    }

    return {
      total: response.total,
      question,
      rationale: response.rationale,
      responses: response.data,
    };
  }, [
    allResponses,
    question,
  ]);

  return (
    <SurveyQuestionResponsesContext.Provider value={value}>
      <QuestionResponses />
    </SurveyQuestionResponsesContext.Provider>
  );
};

export default QuestionResponsesContainer;