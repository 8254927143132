import { useContext } from 'react';
import { differenceInSeconds } from 'date-fns';
import { OngoingDurationContext } from './Context';
import styles from './style/Tagging.Tag.css';

type TimeRangeProps = {
  end:   Date;
  start: Date;
};

export const TimeRange = (props: TimeRangeProps) => {
  const duration = useContext(OngoingDurationContext);
  const now = `${duration.minutes}:${duration.seconds}`;

  const display = !props.start && !props.end
    ? `${now} - 00:00`
    : props.start && !props.end
      ? `${formatDateDiff(duration.start, props.start)} - ${now}`
      : `${formatDateDiff(duration.start, props.start)} - ${formatDateDiff(duration.start, props.end)}`;

  return (
    <div className={styles.duration}>
      {display}
    </div>
  );
};

TimeRange.displayName = 'Tagging.Tag.TimeRange';

function formatDateDiff(startDate: Date, endDate: Date) {
  const totalSeconds = differenceInSeconds(endDate, startDate);
  const minutes = Math.floor(totalSeconds / 60).toFixed().padStart(2, '0');
  const seconds = (totalSeconds % 60).toFixed().padStart(2, '0');

  return `${minutes}:${seconds}`;
}