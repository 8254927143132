import { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as consts from '@consts';
import * as actions from '@store/actions';
import { ConnectedAccountProvider, ConnectedAccountType } from '@enums';
import img from 'static/images/logo-sentiment-square.png';
import { AccountConnectCallback } from './AccountConnectCallback';
import styles from './style.css';

const mapDispatch = {
  userAuthenticationChange: actions.userAuthenticationChange,
};

class HandleCallback extends Component {
  state = {
    error: null,
  };

  componentDidMount() {
    const type = this.props.match.params.type;
    const provider = this.props.match.params.provider;

    if (type === 'connect') {
      if (HandleCallback.validProviders.includes(provider)) {
        return;
      }
    }

    this.setState({
      error: `Oops, it looks like something went wrong. Type '${type}' and Provider '${provider}' is not known.`,
    });
  }

  static validProviders = ['microsoft', 'calendly'];

  renderBody = () => {
    if (this.props.match.params.provider == 'calendly') {
      return <AccountConnectCallback type={ConnectedAccountType.Calendar} provider={ConnectedAccountProvider.Calendly} />;
    } else if (this.props.match.params.provider == 'zoom') {
      return <AccountConnectCallback type={ConnectedAccountType.Files} provider={ConnectedAccountProvider.Zoom} />;
    }
    return (
      <>
        <div className={styles.text}>
          {this.state.error ? this.state.error : 'Redirecting...'}
        </div>
        <div className={styles.loginLink}>
          <Link to={consts.path.Website.Login}>
            Return to Log In Page
          </Link>
        </div>
      </>
    );
  }

  render() {
    return (
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <img
            className={styles.img}
            src={img} />
          {this.renderBody()}
        </div>
      </div>
    );
  }
}

export default connect(null, mapDispatch)(HandleCallback);
