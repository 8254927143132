import * as Radio from '@/components/Radio/RadioGroup';

type Props = {
  onChange: (optionId: string) => unknown;
  options: {
    id:    string | number;
    value: string;
  }[];
  selected: string | number;
};

export const RadioGroup  = ({ selected = '', ...props }: Props) => {
  return (
    <>
      <style>{`
        .MuiButtonBase-root {
          margin-right: 10px;
        }
      `}
      </style>
      <Radio.RadioGroup
        selectedOption={selected}
        onOptionChange={item => props.onChange(item.optionId)}
        options={props.options.map(x => ({
          allowText: false,
          label: x.value,
          value: x.id,
        }))} />
    </>
  );
};

RadioGroup.displayName = 'MedicalProfile.RadioGroup';