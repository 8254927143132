import { useCallback, useState } from 'react';
import type { SaveTaggedMoment } from '@/services/api/interfaces/transcripts';
import { TaggedMomentBuilderModal } from '@/components/Transcript/TaggedMomentBuilder.Modal';
import { SetTaggedMomentEditParamsContext } from './context';
import type { TaggedMomentFormParams } from './interfaces';
import { TaggedMomentFormState } from './TaggedMoment.Form.State';
import { TaggedMomentFormContainer } from './TaggedMoment.Form';
import { SaveTaggedMomentFormContainer } from './TaggedMoment.Form.Save';

type Props = {
  onSuccess?: (data: SaveTaggedMoment.Response) => Promise<void>;
} & ChildrenProps;

export const TaggedMomentBuilderContainer = ({ children, onSuccess }: Props) => {

  const [params, setParams] = useState<TaggedMomentFormParams>(null);

  const onReset = useCallback(() => {
    setParams(null);
  }, []);

  const modalOpen = !!params;

  return (
    <SetTaggedMomentEditParamsContext.Provider value={setParams}>
      {children}
      {modalOpen && (
        <TaggedMomentFormState>
          <TaggedMomentFormContainer params={params}>
            <SaveTaggedMomentFormContainer onSuccess={onSuccess}>
              <TaggedMomentBuilderModal
                open={modalOpen}
                onClose={onReset} />
            </SaveTaggedMomentFormContainer>
          </TaggedMomentFormContainer>
        </TaggedMomentFormState>
      )}
    </SetTaggedMomentEditParamsContext.Provider>
  );
};