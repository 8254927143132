import { useCallback, useState } from 'react';
import type { WorkspaceSearchFull } from '@/types';
import {
  SearchResultsAllContext, SearchResultsBrandContext, SearchResultsDispatchContext, SearchResultsFileContext,
  SearchResultsFolderContext, SearchResultsMemberContext, SearchResultsProjectContext,
  SearchResultsResetContext,
  SearchResultsTranscriptContext,
} from './Context';
import type { SearchResultsContextState } from './interfaces';

export default function Results({ children }: ChildrenProps) {
  const [state, dispatch] = useState<SearchResultsContextState>(getInitialState());

  const reset = useCallback((tab: WorkspaceSearchFull.SearchTab) => {
    // dispatch(prev => ({
    //   ...prev,
    //   [tab]: tab === 'all'
    //     ? {
    //       brands: null,
    //       projects: null,
    //       members: null,
    //       files: null,
    //       folders: null,
    //       transcripts: null,
    //     }
    //     : null,
    // }));
    dispatch(getInitialState());
  }, []);

  return (
    <SearchResultsDispatchContext.Provider value={dispatch}>
      <SearchResultsResetContext.Provider value={reset}>
        <SearchResultsAllContext.Provider value={state.all}>
          <SearchResultsProjectContext.Provider value={state.projects}>
            <SearchResultsMemberContext.Provider value={state.members}>
              <SearchResultsFileContext.Provider value={state.files}>
                <SearchResultsFolderContext.Provider value={state.folders}>
                  <SearchResultsTranscriptContext.Provider value={state.transcripts}>
                    <SearchResultsBrandContext.Provider value={state.brands}>
                      {children}
                    </SearchResultsBrandContext.Provider>
                  </SearchResultsTranscriptContext.Provider>
                </SearchResultsFolderContext.Provider>
              </SearchResultsFileContext.Provider>
            </SearchResultsMemberContext.Provider>
          </SearchResultsProjectContext.Provider>
        </SearchResultsAllContext.Provider>
      </SearchResultsResetContext.Provider>
    </SearchResultsDispatchContext.Provider>
  );
}

function getInitialState(): SearchResultsContextState {
  return {
    all: {
      brands: null,
      projects: null,
      members: null,
      files: null,
      folders: null,
      transcripts: null,
    },
    brands: null,
    projects: null,
    members: null,
    files: null,
    folders: null,
    transcripts: null,
  };
}

export { Results };