import { useTemplateTrialDesignModal } from '@/components/Survey.Templates/Modal.TrialDesign';
import { TemplateItemLabel } from './Label.Base';
import { TemplateLabelColor } from './Label.colors';

type Props = {
  className?: string;
  disabled: boolean;
  value: string;
};

export const TemplateTrialDesign = ({ className, disabled, value }: Props) => {

  const [toggleModal, Modal] = useTemplateTrialDesignModal();

  return (
    <>
      <TemplateItemLabel
        className={className}
        color={TemplateLabelColor.Three}
        disabled={disabled}
        label={value}
        tooltip="Trial design"
        onClick={toggleModal} />
      {!disabled && <Modal />}
    </>
  );
};