import { useMemo } from 'react';
import { ProjectType, SurveyTemplateType } from '@/enums';

type UseSurveyTemplates = {
  includeExternalSurvey: boolean;
  projectType: ProjectType;
};

export const useSurveyTemplates = ({ includeExternalSurvey, projectType }: UseSurveyTemplates) => {

  const templates = useMemo(() => {
    if (projectType === ProjectType.SurveyCall) {
      return includeExternalSurvey
        ? [SurveyTemplateType.None, SurveyTemplateType.ExternalSurvey]
        : [SurveyTemplateType.None];
    }

    return [
      SurveyTemplateType.None,
      includeExternalSurvey ? SurveyTemplateType.ExternalSurvey : null,
      SurveyTemplateType.TargetProductProfile,
      SurveyTemplateType.MessageTesting,
      SurveyTemplateType.PersonaResearch,
      SurveyTemplateType.NetPromoterScore,
      SurveyTemplateType.EmployeeSatisfaction,
      SurveyTemplateType.MarketingCampaignEffectiveness,
      SurveyTemplateType.PricingStrategy,
      // SurveyTemplateType.MarketAnalysis,
    ].filter(t => t != null);
  }, [includeExternalSurvey, projectType]);

  return templates;
};