import { useCallback, useEffect, useMemo, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as consts from '@consts';
import { useHasChatOnlyRole, useHasClientRole } from '@containers/User/hooks';
import { useZendeskScript } from '@containers/Zendesk';
import { usePrevious } from '@utils';
import { useIdentifyUser } from './hooks';
import * as Shared from './shared';

const clientExactPaths = [
  // '/',
  // consts.pathname.HOME,
  consts.path.Conference.Entry,
];

const clientWildPaths = [
  // consts.pathname.PROJECTS,
  // consts.path.Calendar.Root,
  consts.path.Conference.Join,
  consts.path.Conference.Completed,
  consts.path.Conference.NotFound,
];

const restExactPaths = [
  consts.path.Conference.Entry,
];

const restWildPaths = [
  consts.path.Calendar.Root,
  consts.path.Conference.Join,
  consts.path.Conference.Completed,
  consts.path.Conference.NotFound,
  consts.path.Onboarding.Root,
];

const excludeExactPaths = [
  consts.path.Onboarding.Projects.Project,
  consts.path.SelfServeSurvey.Root,
  consts.path.Conference.Room,
  consts.path.Conference.MiniNotes,
  consts.path.Conference.MiniTagging,
  consts.path.Conference.CaptureSheetPopout,
];

const excludeWildPaths = [
  consts.path.Inbound.Root,
  consts.path.ExternalSourcingProject.Root,
];

const selectUser = (state: Store.State) => state.user;

export function ChatWidget() {
  const [initialized, setInitialized] = useState(false);
  const [visible, setVisibility] = useState(false);

  const user = useSelector(selectUser);
  const prevUser = usePrevious(user);
  const identifyUser = useIdentifyUser();
  const hasClientRole = useHasClientRole();
  const hasChatOnlyRole = useHasChatOnlyRole();

  const location = useLocation();

  const script = useZendeskScript();

  const initialize = useCallback(() => {
    if (!initialized && window.zE) {
      setInitialized(true);
      window.zE('webWidget', 'updateSettings', Shared.settings);
    }
  }, [initialized]);

  const updateVisibility = useCallback(() => {
    // Clients use inclusive paths, while the rest use exclusive paths
    const shouldShow = hasChatOnlyRole
      ? false
      : hasClientRole
        ? !!(matchPath(location.pathname, clientWildPaths) || matchPath(location.pathname, { path: clientExactPaths, exact: true }))
        : !(matchPath(location.pathname, excludeWildPaths) || matchPath(location.pathname, { path: excludeExactPaths, exact: true }));

    if (!shouldShow && visible) {
      setVisibility(false);
      window.zE('webWidget', 'close');
      window.zE('webWidget', 'hide');
    } else if (shouldShow && !visible) {
      setVisibility(true);
      window.zE('webWidget', 'show');
    }

  }, [hasClientRole, hasChatOnlyRole, location.pathname, visible, setVisibility]);

  useEffect(() => {
    if (script.isLoaded) {
      initialize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [script.isLoaded]);

  useEffect(() => {
    if (initialized) {
      identifyUser();
      updateVisibility();
    }

    if (!user.id && initialized) {
      window.zE('webWidget', 'logout');
    }
  }, [
    identifyUser,
    initialized,
    prevUser,
    updateVisibility,
    user.id,
  ]);

  useEffect(() => {
    return () => {
      window.zE?.('webWidget', 'close');
      window.zE?.('webWidget', 'hide');
      window.zE?.('webWidget', 'logout');
    };
  }, []);

  return null;
}
