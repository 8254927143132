import { useSelector } from 'react-redux';
import { ProjectType } from '@enums';

const selectIsProjectSurveyLike = (projectId: number) => (state: Store.State) => {
  const projectType = state.projects[projectId]?.projectType;

  return [
    ProjectType.Survey,
    ProjectType.SurveyCall,
  ].includes(projectType);
};

const useIsProjectTypeSurveyLike = (projectId: number) => {
  return useSelector(selectIsProjectSurveyLike(projectId));
};

export { useIsProjectTypeSurveyLike };
export default useIsProjectTypeSurveyLike;
