import type { ComponentType } from 'react';
import { useMemo } from 'react';
import type { NodeViewComponentProps } from '@remirror/react';
import type { Mark } from 'remirror';
import { useIsEditingSurvey, useSurveyBuilderState, useSurveyTemplateData } from '@containers/SurveyBuilder/hooks';
import type { SurveyRichText, SurveyTemplate } from '@/types/survey';
import { cx, formatCurrencyLegacy, CURRENCY_FORMATTERS_LEGACY } from '@utils';
import {
  TemplateDrug,
  TemplateLaunchYear,
  TemplateMedicalCondition,
  TemplateQualityOfLife,
  TemplateTrialDesign,
  TemplateDrugIndication,
  TemplateOtherAttribute,
  TemplateSafetyEndpoint,
  TemplateDrugPrice,
  TemplateClient,
  TemplatePricingComparator,
  TemplateMedicalConditionMT,
  TemplateProduct,
} from '@/components/Survey.Templates/Label';
import styles from './style/Node.Template.css';

export const TemplateValue: ComponentType<NodeViewComponentProps> = props => {

  const ctx = useSurveyBuilderState();

  return ctx
    ? <BuilderNode {...props} />
    : <Node {...props} />;
};

function BuilderNode({ node }: NodeViewComponentProps) {
  const editing = useIsEditingSurvey();
  const [templateData] = useSurveyTemplateData();

  const attrs = (node.attrs as SurveyRichText.TemplateValueNode['attrs']);

  const LabelComponent = useMemo(() => {
    return LabelMap[attrs.nodeType];
  }, [attrs.nodeType]);

  const value = useMemo(() => {
    const func = valueSelectorMap[attrs.nodeType];
    return func?.(templateData, attrs.id);
  }, [attrs, templateData]);

  return (
    <LabelComponent
      disabled={editing}
      value={value} />
  );
}

function Node({ node }: NodeViewComponentProps) {
  const attrs = (node.attrs as SurveyRichText.TemplateValueNode['attrs']);

  const className = computeMarkClassName(node.marks);

  return (
    <span className={className}>{attrs.value}</span>
  );
}

function computeMarkClassName(marks: readonly Mark[]) {
  return cx({
    // @ts-ignore
    [styles.bold]: marks.some(s => s.type === 'bold'),
  });
}

type NodeComponentMap = Record<SurveyRichText.TemplateValueNodeType, LabelComponent>;
type LabelComponent = React.ComponentType<{
  className?: string;
  disabled: boolean;
  value: string;
}>;
type ValueSelectorMap = Record<SurveyRichText.TemplateValueNodeType, (data: SurveyTemplate.Data, id?: string) => string>;

const LabelMap: NodeComponentMap = {
  ['launch-year']: TemplateLaunchYear,
  ['medical-condition']: TemplateMedicalCondition,
  ['drug']: TemplateDrug,
  ['trial-design']: TemplateTrialDesign,
  ['quality-of-life']: TemplateQualityOfLife,
  ['drug-indication']: TemplateDrugIndication,
  ['other-attribute']: TemplateOtherAttribute,
  ['safety-endpoint']: TemplateSafetyEndpoint,
  ['high-drug-price']: TemplateDrugPrice,
  ['medium-drug-price']: TemplateDrugPrice,
  ['low-drug-price']: TemplateDrugPrice,
  ['client']: TemplateClient,
  ['pricing-comparator']: TemplatePricingComparator,
  ['mt-medical-condition']: TemplateMedicalConditionMT,
  ['efficacy-endpoint']: null,
  ['product']: TemplateProduct,
};

const valueSelectorMap: ValueSelectorMap = {
  ['efficacy-endpoint']: data => '',
  ['launch-year']: (data: SurveyTemplate.TargetProductProfile) => data.launchYear.toString(),
  ['medical-condition']: (data: SurveyTemplate.TargetProductProfile) => data.medicalCondition.value,
  ['drug']: (data: SurveyTemplate.TargetProductProfile) => data.drug.value,
  ['trial-design']: (data: SurveyTemplate.TargetProductProfile) => data.trialDesign,
  ['quality-of-life']: (data: SurveyTemplate.TargetProductProfile) => data.qualityOfLife,
  ['drug-indication']: (data: SurveyTemplate.TargetProductProfile) => data.drugIndication,
  ['other-attribute']: (data: SurveyTemplate.TargetProductProfile, id: string) => data.otherAttributes.find(f => f.id === id).value,
  ['safety-endpoint']: (data: SurveyTemplate.TargetProductProfile, id: string) => data.safetyEndpoints.find(f => f.id === id).value,
  ['high-drug-price']: (data: SurveyTemplate.TargetProductProfile) => formatCurrencyLegacy(data.drugPrice.high, CURRENCY_FORMATTERS_LEGACY[data.drugPrice.currency]),
  ['medium-drug-price']: (data: SurveyTemplate.TargetProductProfile) => formatCurrencyLegacy(data.drugPrice.medium, CURRENCY_FORMATTERS_LEGACY[data.drugPrice.currency]),
  ['low-drug-price']: (data: SurveyTemplate.TargetProductProfile) => formatCurrencyLegacy(data.drugPrice.low, CURRENCY_FORMATTERS_LEGACY[data.drugPrice.currency]),
  ['client']: (data: SurveyTemplate.TargetProductProfile) => data.client,
  ['pricing-comparator']: (data: SurveyTemplate.TargetProductProfile, id: string) => data.pricingComparators.find(f => f.id === id)?.value || 'Other',
  ['mt-medical-condition']: (data: SurveyTemplate.MessageTesting.Data) => data.medicalConditions.primary.value,
  ['product']: (data: SurveyTemplate.MessageTesting.Data) => data.product.value,
};

export default TemplateValue;