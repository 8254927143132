import { lazy } from 'react';
import { RouteAuth } from '@enums';
import { routes as mainRoutes } from '$main';
import { enumerateRoutePaths } from './utils';

const Main = lazy(() => import(
  /* webpackChunkName: "main_" */
  '$main/Main')
);

const path = enumerateRoutePaths(mainRoutes);

export const router = {
  auth: RouteAuth.Ignore,
  component: Main,
  path: [...path, '/'],
};