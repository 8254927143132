import Dropdown from './Dropdown';
import ReadOnly from './ReadOnly';
import Switch from './Switch';

export {
  Dropdown,
  ReadOnly,
  Switch,
};

export default {
  Dropdown,
  ReadOnly,
  Switch,
};