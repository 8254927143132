import MinimizeIcon from '@mui/icons-material/Minimize';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import styles from './style/Panel.Tools.css';

type Props = {
  onClickMinimize?: () => void;
  onClickPopout?:   () => void;
  title:            string;
};

export const PanelToolsHeader = (props: Props) => {
  return (
    <div className={styles.header}>
      <div className={styles.win}>
        <div className={styles.title}>{props.title}</div>
        <div className={styles.btns}>
          {props.onClickMinimize &&
            <button
              className={styles.btn}
              onClick={props.onClickMinimize}>
              <MinimizeIcon />
            </button>}
          {props.onClickPopout &&
            <button
              className={styles.btn}
              onClick={props.onClickPopout}>
              <OpenInNewIcon />
            </button>}
        </div>
      </div>
    </div>
  );
};

PanelToolsHeader.displayName = 'PanelToolsHeader';