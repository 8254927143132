import { parseSurveyRichText, richTextHasImages } from '@/containers/Survey/utils';
import { SurveyOptionType } from '@enums';
import type { SurveyQuestionOption } from '@/types';
import type { Validation } from '../interfaces';

function isExtendedOption(option: SurveyQuestionOption) {
  return option.type !== SurveyOptionType.Default;
}

export function assertValidNumberOfOptions(options: SurveyQuestionOption[], min: number, max: number): Validation.AssertionResult {
  const userEnteredLength = (options ?? []).filter(f => !isExtendedOption(f) && !Object.keys(f.metadata?.linked ?? {}).length).length;
  const allOptionsLength = (options ?? []).filter(f => !isExtendedOption(f)).length;

  const isInvalid = allOptionsLength < min || userEnteredLength > max;

  if (isInvalid) {
    return {
      success: false,
      message: 'Invalid number of options.',
    };
  }

  return { success: true };
}

export function assertNoDuplicateOptions(options: SurveyQuestionOption[]): Validation.AssertionResult {
  const hasDuplicates
    = options
      .some((s, i) =>
        options.some((ss, ii) => {
          const ssValue = parseSurveyRichText(ss.value);
          const sValue = parseSurveyRichText(s.value);
          return ssValue !== '' && ii !== i && sValue === ssValue;
        })
      );

  if (hasDuplicates) {
    return {
      success: false,
      message: 'Duplicate options present.',
    };
  }

  return { success: true };
}

export function assertOptionValues(options: SurveyQuestionOption[]): Validation.AssertionResult {
  const hasEmptyValues = options.some(s => !parseSurveyRichText(s.value) && !richTextHasImages(s.value));

  if (hasEmptyValues) {
    return {
      success: false,
      message: 'Option value is empty.',
    };
  }

  return { success: true };
}

export function assertValidOptions(options: SurveyQuestionOption[], min: number, max: number): Validation.AssertionResult {
  const hasNumberOfOptions
    = assertValidNumberOfOptions(options, min, max);

  if (hasNumberOfOptions.success === false) {
    return {
      success: false,
      message: hasNumberOfOptions.message,
    };
  }

  const hasUniqueOptions
    = assertNoDuplicateOptions(options);

  if (hasUniqueOptions.success === false) {
    return {
      success: false,
      message: hasUniqueOptions.message,
    };
  }

  const hasOptionValues
    = assertOptionValues(options);

  if (hasOptionValues.success === false) {
    return {
      success: false,
      message: hasOptionValues.message,
    };
  }

  return { success: true };
}