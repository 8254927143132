export enum RollupRange {
  Daily = 1,
  Weekly = 2,
  Monthly = 3
}

export type Filter = {
  start: Date;
  end: Date;
};

export type GraphDatum = {
  date: Date;
  value: number;
};

export type TeamInfo = {
  id: number;
  name: string;
};

type UserStats = {
  outreach: number;
  signups: number;
  completes: number;
};

export type UserRowDatum = {
  userId: number;
  name: string;
  team: TeamInfo;
  fourWeekCompletionAvg: number;
  sixMonthCompletionAvg: number;
} & UserStats;

export type UserRowBreakdown = {
  date: Date;
} & UserStats;

export type BreakdownRow = {
  name: string;
} & UserStats;