import { useMemo } from 'react';
import { path } from '@/consts';
import { FileTabView } from './File.TabView';
import { FileTabPreview } from './File.Tab.Preview';
import { FileTabSummary } from './File.Tab.Summary';
import { FileTabDetails } from './File.Tab.Details';
import { FileTabReferences } from './File.Tab.References';
import type { SceneMap } from './interfaces';
import { RouteKey } from './interfaces';

type Props = {
  paths?: {
    preview: string;
    summary: string;
    details: string;
    references: string;
  };
};

export const FileTabViewContainer = ({
  paths = {
    preview: path.WorkspaceFileTabs.Preview,
    summary: path.WorkspaceFileTabs.Summary,
    details: path.WorkspaceFileTabs.Details,
    references: path.WorkspaceFileTabs.References,
  },
}: Props) => {
  const map: SceneMap = useMemo(() => ({
    [RouteKey.Preview]: {
      component: FileTabPreview,
      path: paths.preview,
      title: 'Preview',
    },
    [RouteKey.Summary]: {
      component: FileTabSummary,
      path: paths.summary,
      title: 'Summary',
    },
    [RouteKey.Details]: {
      component: FileTabDetails,
      path: paths.details,
      title: 'Details',
    },
    [RouteKey.References]: {
      component: FileTabReferences,
      path: paths.references,
      title: 'References',
    },
  }), [paths]);

  return <FileTabView map={map} />;
};