import { Route, Switch } from 'react-router-dom';
import * as api from '@api';
import * as consts from '@consts';
import { DiscoveryMain } from '@screens/Discovery.Main';
import { DiscoveryTopic } from '@screens/Discovery.Topic';
import { useAsyncState } from '@utils';
import { AuthModalContainer } from '$website/components';
import { TopicsNavigationContext } from './Context';

type Props = unknown;

export const DiscoveryContainer = (props: Props) => {
  const [state] = useAsyncState(() => {
    return api.discovery.fetchVideoTopics();
  });

  return (
    <AuthModalContainer>
      <TopicsNavigationContext.Provider value={state}>
        <Switch>
          <Route
            component={DiscoveryMain}
            exact
            path={consts.path.Discovery.Root} />
          <Route
            component={DiscoveryTopic}
            exact
            path={consts.path.Discovery.Topics.Topic} />
        </Switch>
      </TopicsNavigationContext.Provider>
    </AuthModalContainer>
  );
};