import { createContext, useMemo } from 'react';
import {
  useAppBanner,
  useProfileCompletedBanner,
  useVerifiedBanner,
} from './hooks';
import type { BannerStateContextValue, BannerStateProps } from './interfaces';

const BannerStateContext = createContext<BannerStateContextValue>(null!);

const BannerState = ({ children }: BannerStateProps) => {
  const [profileCompletedVisible, dismissProfileCompleted] = useProfileCompletedBanner();
  const [verifiedVisible, dismissVerified] = useVerifiedBanner();

  // TODO: create better way of determing to show a banner
  const context: BannerStateContextValue = useMemo(() => ({
    dismissProfileCompleted,
    dismissVerified,
    profileCompletedVisible,
    verifiedVisible: verifiedVisible && !profileCompletedVisible,
  }), [profileCompletedVisible, verifiedVisible]);

  return (
    <BannerStateContext.Provider value={context}>
      {children}
    </BannerStateContext.Provider>
  );
};

export { BannerStateContext };
export { BannerState };
export default BannerState;