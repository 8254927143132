import { useMemo } from 'react';
import { useSurveyBuilderState, useParseSurveyRichText } from '@containers/SurveyBuilder';
import { useQuestionBuilderItemContext } from '@containers/SurveyBuilder.Question/Context';
import { SurveyQuestionType } from '@enums/Survey';
import { ResponsePipingIcon } from '@presentation/SurveyBuilder';
import { Tooltip } from '@presentation/Tooltip';
import { cx } from '@utils';
import { useResponsePipingModal } from './ResponsePiping.Modal';
import styles from './style/ResponsePiping.css';

type Props = {
  disabled?: boolean;
  type?: 'options' | 'rows';
};

export const ResponsePiping = ({
  disabled = false,
  type = 'options',
}: Props) => {

  const [toggleModal, ResponsePipingModal] = useResponsePipingModal();
  const pipeableQuestions = usePipeableQuestions();

  const isDisabled = useMemo(() => {
    return disabled || !pipeableQuestions.length;
  }, [disabled, pipeableQuestions]);

  const rootClassName = cx(styles.root, {
    [styles.disabled]: isDisabled,
  });

  return (
    <>
      <Tooltip title="Pipe previous responses">
        <div className={rootClassName}>
          <ResponsePipingIcon
            className={styles.icon}
            onClick={isDisabled ? undefined : toggleModal} />
        </div>
      </Tooltip>
      <ResponsePipingModal
        pipeableQuestions={pipeableQuestions}
        type={type} />
    </>
  );
};

const usePipeableQuestions = () => {

  const item = useQuestionBuilderItemContext();
  const [state] = useSurveyBuilderState();
  const parseSurveyRichText = useParseSurveyRichText();

  const pipeableQuestions = useMemo(() => {
    const types = [
      SurveyQuestionType.MultipleChoice,
      SurveyQuestionType.Multiselect,
      SurveyQuestionType.Dropdown,
      SurveyQuestionType.MatrixGrid,
      SurveyQuestionType.MatrixMultiselect,
      SurveyQuestionType.Ranking,
    ];

    return state.survey.questions
      .filter(f => types.includes(f.typeId) && f.ordinal < item.ordinal)
      .map(m => ({
        base: m.base,
        ordinal: m.ordinal,
        value: parseSurveyRichText(m.value),
      }));
  }, [
    item.ordinal,
    parseSurveyRichText,
    state.survey.questions,
  ]);

  return pipeableQuestions;
};

export default ResponsePiping;