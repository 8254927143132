import { useContext, useEffect, useMemo } from 'react';
// import { useIsInternalUser } from '@/containers/Store/hooks';
import { pathname } from '@/consts';
import type { WorkspaceGlobalSearchBarResultKeyword } from '@/types';
import { qs, hash64 } from '@/utils';
import {
  WorkspaceGlobalSearchBarResultsContext, WorkspaceGlobalSearchBarQueryContext,
  WorkspaceGlobalSearchKeyboardContext, WorkspaceGlobalSearchBarResetContext,
} from './Context';
import EmptyQuery from './Results.EmptyQuery';
import ResultsItems from './Results.Items';
import styles from './style/Results.css';

export default function Results() {
  const { query } = useContext(WorkspaceGlobalSearchBarQueryContext);

  return query.length
    ? <HasQuery />
    : <EmptyQuery />;
}

// function findMembersItem(query: string): WorkspaceGlobalSearchBarResultFindMembers {
//   const encoded = qs.encode({ keyword: [{ id: query }] });
//   return {
//     id: hash64(`find-members:${query}`),
//     name: query,
//     path: `${pathname.SEARCH.substring(1)}?${qs.stringify({ q: encoded })}`,
//     type: 'find-members',
//   };
// }

function keywordItem(query: string): WorkspaceGlobalSearchBarResultKeyword {
  return {
    id: hash64(`keyword:${query}`),
    name: query,
    path: `${pathname.WORKSPACES.substring(1)}/search?${qs.stringify({ keywords: [query] })}`,
    type: 'keyword',
  };
}

function HasQuery() {
  const results = useContext(WorkspaceGlobalSearchBarResultsContext);
  const { index } = useContext(WorkspaceGlobalSearchKeyboardContext);
  const { updatedResultsReset } = useContext(WorkspaceGlobalSearchBarResetContext);
  const { query } = useContext(WorkspaceGlobalSearchBarQueryContext);
  // const isInternalUser = useIsInternalUser();

  useEffect(() => {
    updatedResultsReset(results.items.length + 1);
  }, [updatedResultsReset, results.items.length]);

  // const findMembers = useMemo(() => {
  //   if (!isInternalUser) return null;
  //   return findMembersItem(query);
  // }, [isInternalUser, query]);

  const keyword = useMemo(() => keywordItem(query), [query]);
  const items = useMemo(() => [keyword, ...results.items].filter(Boolean), [keyword, results.items]);

  return (
    <div className={styles.root}>
      <ResultsItems
        activeIndex={index}
        items={items} />
    </div>
  );
}

export { Results };

