import { forwardRef } from 'react';
import { cx } from '@utils';
import { SliderThumbProps } from './interfaces';
import styles from './style/Thumb.css';

export const SliderThumb = forwardRef<HTMLDivElement, SliderThumbProps>(({ className, ...otherProps }, ref) => {

  return (
    <div
      ref={ref}
      className={cx(styles.root, className)}
      {...otherProps} />
  );
});

export default SliderThumb;