import { useCallback } from 'react';
import { SurveyQuestionsBuilder } from './QuestionsBuilder';
import { SurveyLogicBuilder } from './LogicBuilder';
import SurveyBuilder from './SurveyBuilder';
import SurveyTab from './SurveyTab';

type Props = {
  tabs?: SurveyTab[];
};

export const ComplianceSurveyBuilder = ({
  tabs = [SurveyTab.Questions, SurveyTab.Logic],
}: Props) => {

  const renderTab = useCallback((tab: SurveyTab) => {
    switch (tab) {
      case SurveyTab.Logic:
        return (
          <SurveyLogicBuilder />
        );

      case SurveyTab.Questions:
        return (
          <SurveyQuestionsBuilder />
        );
    }

  }, []);

  return (
    <SurveyBuilder
      tabs={tabs}
      renderTab={renderTab} />
  );
};

export default ComplianceSurveyBuilder;