import { Modal as ModalRoot, ModalProps } from '@/components/Modal/Modal';
import { Tabs } from './Following.Tabs';
import styles from './style/Following.Modal.css';

type Props = Pick<ModalProps, 'onClose' | 'open'>;

export const Modal = (props: Props) => {
  return (
    <ModalRoot
      classes={{
        root: styles.modal,
        wrap: styles.root,
        close: styles.close,
      }}
      onClose={props.onClose}
      open={props.open}>
      <div className={styles.wrap}>

        <div className={styles.header}>{text.header}</div>

        <div className={styles.main}>
          <Tabs />
        </div>

        <div className={styles.footer}>
          <button
            className={styles.btn}
            onClick={props.onClose}>
            Back to Feed
          </button>
        </div>
      </div>
    </ModalRoot>
  );
};

Modal.displayName = 'Following.Modal';

const text = {
  header: `Follow topics to customize your newsfeed and receive relevant project opportunities.`,
};