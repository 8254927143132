import { useCallback, useState } from 'react';

export const useToggle = (initialValue = false) => {
  const [value, setValue] = useState<boolean>(initialValue);

  const toggler = useCallback(() => setValue(value => !value), []);

  return [value, toggler] as const;
};

export default useToggle;