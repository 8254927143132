import { useContext, useMemo, useEffect } from 'react';
import { FileSocketEvent } from '@/enums';
import * as $ws from '@/services/websocket';
import { useFilesSocketEvent } from '@/services/websocket/hooks/files';
import { useFetchFilePreview } from './hooks';
import { WorkspaceFilePreviewContext } from './Context';

type Props =
  IWorkspaceFileId &
  ChildrenProps;

export const FilePreviewSocketContainer = ({ children, fileId }: Props) => {

  const fetchPreview = useFetchFilePreview();
  const previewData = useContext(WorkspaceFilePreviewContext);

  const enabled = useMemo(() => {
    return previewData?.inProgress;
  }, [previewData?.inProgress]);

  useFilesSocketEvent(FileSocketEvent.PreviewAvailable, fetchPreview, enabled);

  useEffect(() => {
    if (enabled) {
      $ws.files.subscribe({ fileId });
      $ws.files.open();

      return () => {
        $ws.files.unsubscribe({ fileId });
        $ws.files.close();
      };
    }
  }, [enabled, fileId]);

  return (
    <>
      {children}
    </>
  );
};

export default FilePreviewSocketContainer;