import { useCallback, useState } from 'react';
import { usePopper } from 'react-popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { useZIndexModifier } from '@/components/Popper';
import { Portal } from '@/components/Portal';
import type { SurveyItem, SurveyQuestion } from '@/types';
import { usePipingMenuState } from './context';
import { useEligiblePipingQuestions } from './hooks/useEligiblePipingQuestions';
import { PipingQuestionsMenu } from './Editor.Piping.QuestionsMenu';
import { useEligiblePipingItems } from './hooks/useEligiblePipingItems';

type Props = {
  children: RenderAnchor;
  activeQuestionOrdinal?: number;
  eligible?: {
    questions: SurveyQuestion[];
    items: SurveyItem[];
  };
};

type RenderAnchor = (props: RenderAnchorProps, ref: React.Ref<HTMLElement>) => React.ReactNode;

export type RenderAnchorProps = {
  // onClick: () => void;
  popperOpen: boolean;
};

export const PipingAnchor = (props: Props) => {

  const { open, setOpen } = usePipingMenuState();

  const [referenceElement, setReferenceElement] = useState<HTMLElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);

  const zIndexModifier = useZIndexModifier({ zIndex: 8 });

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      zIndexModifier,
      {
        name: 'offset',
        options: {
          offset: [0, 5],
        },
      },
    ],
    placement: 'bottom-start',
  });

  const handleBlur = useCallback((e: MouseEvent) => {
    if (!referenceElement.contains(e.target as Node)) {
      setOpen(false);
    }
  }, [referenceElement, setOpen]);

  const defaultQuestions = useEligiblePipingQuestions({ activeQuestionOrdinal: props.activeQuestionOrdinal });
  const defaultItems = useEligiblePipingItems({ activeQuestionOrdinal: props.activeQuestionOrdinal });

  const questions = props.eligible?.questions ?? defaultQuestions;
  const items = props.eligible?.items ?? defaultItems;

  if (!questions.length) return null;

  return (
    <>
      {props.children({
        popperOpen: open,
      }, setReferenceElement)}
      {open &&
        <Portal>
          <ClickAwayListener
            mouseEvent='onMouseDown'
            onClickAway={handleBlur}
            touchEvent='onTouchStart'>
            <div
              ref={setPopperElement}
              style={popperStyles.popper}
              {...attributes.popper}>
              <PipingQuestionsMenu questions={questions} items={items} />
            </div>
          </ClickAwayListener>
        </Portal>
      }
    </>
  );
};