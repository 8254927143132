import type { UseMutationResult } from '@tanstack/react-query';
import type { UsePositionerReturn } from '@remirror/react';
import type { FromToProps } from 'remirror';
import type * as API from '@api/interfaces';
import type { BaseHighlight, HighlightWithText } from './interfaces.extension.highlight';

export declare namespace TranscriptHelpers {
  type GetHighlightPosition = (id: string) => number;
  type GetBasicHighlight = (id: string) => BaseHighlight;
  type GetFocusedHighlight = () => HighlightWithText & { showTooltip: boolean };
  type GetHighlight = (id: string) => HighlightWithText;
  type GetHighlights = () => BaseHighlight[];
  type GetHighlightsAt = (pos: number) => HighlightWithText[];
  type IsHighlightFocused = () => boolean;
  type IsInitialized = () => boolean;

  export type Helpers = {
    getBasicHighlight: GetBasicHighlight;
    getFocusedHighlight: GetFocusedHighlight;
    getHighlight: GetHighlight;
    getHighlights: GetHighlights;
    getHighlightsAt: GetHighlightsAt;
    getHighlightPosition: GetHighlightPosition;
    isHighlightFocused: IsHighlightFocused;
    getHighlightsInitialized: IsInitialized;
  };
}

export namespace Tags {
  export const tagColors = [
    {
      highlight: '#CCECF9',
      menu: '#B4DFF1',
      hover: '#9CD3EA',
    },
    {
      highlight: '#DCDCF8',
      menu: '#C8C9F4',
      hover: '#ABACEB',
    },
    {
      highlight: '#F7C0CD',
      menu: '#EEAABA',
      hover: '#EF819C',
    },
    {
      highlight: '#E4F1CF',
      menu: '#D6E8B8',
      hover: '#C1DD93',
    },
    {
      highlight: '#C6F5F5',
      menu: '#AEF3F3',
      hover: '#8DECEC',
    },
    {
      highlight: '#F7DAD4',
      menu: '#EFB5AA',
      hover: '#E7917F',
    },
  ];

  export const defaultColor = tagColors[0].menu;

  export const PopperZIndex = 8;
}

export type TextReplaceSelection = HighlightWithText;

export type TextReplacePopperContextValue = {
  close: () => void;
  selection: TextReplaceSelection;
  setSelection: (params: TextReplaceSelection) => void;
};

export type ReplacementMade = {
  text: string;
  originalText: string;
  ts: {
    start: number;
    end: number;
  };
};

export type FindReplaceContextValue = {
  activeIndex: number | null;
  caseSensitive: boolean;
  findIndex: (index: number) => void;
  findNext: () => void;
  findPrev: () => void;
  query: string;
  ranges: FromToProps[];
  replace: () => void;
  replaceAll: () => void;
  replacement: string;
  setQuery: (query: string) => void;
  setReplacement: (replacement: string) => void;
  stopFind: () => void;
  toggleCaseSensitive: () => void;
  total: number;
};

export type ReassignSpeakerSelectionContextValue = {
  mutation: UseMutationResult<
    API.Transcripts.UpdateTranscriptSpeakerSelection.Response,
    unknown,
    Pick<API.Transcripts.UpdateTranscriptSpeakerSelection.Request, 'speakerIndex'> & API.Transcripts.UpdateTranscriptSpeakerSelection.Request['ts']>;
  setValue: (data: Value) => void;
  value: Value;
};

type Value = {
  end: number;
  speakerIndex: number;
  start: number;
};

export type TextReplacePopperPositionerContextValue = UsePositionerReturn<TextReplacePositionerData>;

export type TextReplacePositionerData = {
  from: PositionerCoords;
  to: PositionerCoords;
};

type PositionerCoords = {
  left: number;
  right: number;
  top: number;
  bottom: number;
};