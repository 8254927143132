import { useCallback, useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as $api from '@api';
import { useUpdateStoredProjectTagSummaries } from '@utils/api';
import { ConferenceTagSummaryItem, ConferenceTagSummaryRating } from '@/types';
import { SetSummaryRatingContext, SummaryFeedbackModalToggleContext } from './context';

type Props = ChildrenProps & IProjectId;

type Vars = {
  rating: ConferenceTagSummaryRating;
  tagSummaryId: number;
};

export const ProjectSummaryRatingsContainer = ({ children, projectId }: Props) => {

  const updateStoredProjectTagSummaries = useUpdateStoredProjectTagSummaries({ projectId });

  const toggleFeedbackModal = useContext(SummaryFeedbackModalToggleContext);

  const updateRatingInStore = useCallback((tagSummaryId: number, rating: ConferenceTagSummaryRating) => {
    updateStoredProjectTagSummaries(oldData => {
      return {
        ...oldData,
        items: oldData.items.map(item => {
          return {
            ...updateItem(item, rating),
            children: item.children.map(child => updateItem(child, rating)),
          };
        }),
      };
    });

    function updateItem(item: ConferenceTagSummaryItem, rating: ConferenceTagSummaryRating) {
      if (item.summary?.id !== tagSummaryId) return item;

      return {
        ...item,
        summary: {
          ...item.summary,
          feedback: {
            ...item.summary.feedback,
            rating,
          },
        },
      };
    }
  }, [updateStoredProjectTagSummaries]);

  const { mutateAsync } = useMutation(['project:update-tag-summary-rating', projectId], ({ rating, tagSummaryId }: Vars) => {
    return $api.projects.conference.updateTagSummaryRating({
      projectId,
      tagSummaryId,
      rating,
    });
  }, {
    onMutate: ({ rating, tagSummaryId }) => {
      updateRatingInStore(tagSummaryId, rating);
    },
    onSuccess: (data, vars) => {
      updateRatingInStore(vars.tagSummaryId, data.rating);
    },
  });

  const handleSetRating = useCallback((item: ConferenceTagSummaryItem) => (rating: ConferenceTagSummaryRating) => {
    const tagSummaryId = item.summary.id;

    if (rating <= 3) {
      toggleFeedbackModal(item, rating);
      return;
    }

    return mutateAsync({
      rating,
      tagSummaryId,
    });
  }, [mutateAsync, toggleFeedbackModal]);

  return (
    <SetSummaryRatingContext.Provider value={handleSetRating}>
      {children}
    </SetSummaryRatingContext.Provider>
  );
};