import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInWeeks,
  differenceInYears,
} from 'date-fns';

export function formatDisplayAge(age: Date) {
  const now = new Date();

  const yearsOld = differenceInYears(now, age);
  if (yearsOld) {
    return `${yearsOld}y`;
  }

  const monthsOld = differenceInMonths(now, age);
  if (monthsOld) {
    return `${monthsOld}mo`;
  }

  const weeksOld = differenceInWeeks(now, age);
  if (weeksOld) {
    return `${weeksOld}w`;
  }

  const daysOld = differenceInDays(now, age);
  if (daysOld) {
    return `${daysOld}d`;
  }

  const hoursOld = differenceInHours(now, age);
  if (hoursOld) {
    return `${hoursOld}h`;
  }

  const minutesOld = differenceInMinutes(now, age);
  if (minutesOld) {
    return `${minutesOld}m`;
  }

  return 'now';
}