import { useState, useRef, useContext } from 'react';
import { styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { TranscriptAutoCorrectContext } from '@containers/Transcript/context';
import { useTranscriptToolsEnabled } from '@containers/Transcript/hooks';
import { FloatingWrapper } from '@/components/Remirror';
import { AutoCorrectTooltip } from './Tooltip.AutoCorrect';
import {
  useAutoCorrectTooltipPositioner,
} from './hooks';

const PREFIX = 'htc';

const classes = {
  popper: `${PREFIX}-popper`,
  arrow: `${PREFIX}-arrow`,
};

const StyledPopper = styled(Popper)(({ theme }) => {
  const bgColor = `var(--pri-01)`;
  const borderColor = `var(--gray-l)`;

  const borderWidth = 1;
  const size = 10;

  const borderSize = size + Math.round(borderWidth * Math.sqrt(2));

  return {
    [`& .${classes.popper}`]: {
      [`&[data-popper-placement="top"] ${classes.arrow}`]: {
        bottom: 0,
        '&::before': {
          top: '100%',
          left: '50%',
          borderTopColor: borderColor,
          marginLeft: `-${borderSize}px`,
        },
        '&::after': {
          top: '100%',
          left: '50%',
          borderTopColor: bgColor,
          marginLeft: `-${size}px`,
        },
      },
    },
    [`& ${classes.arrow}`]: {
      position: 'absolute',
      background: bgColor,
      border: `${borderWidth}px solid transparent`,
      '&::before': {
        border: 'solid transparent',
        content: '""',
        height: 0,
        width: 0,
        position: 'absolute',
        pointerEvents: 'none',
        borderWidth: `${borderSize}px`,
      },
      '&::after': {
        border: 'solid transparent',
        content: '""',
        height: 0,
        width: 0,
        position: 'absolute',
        pointerEvents: 'none',
        borderWidth: `${size}px`,
      },
    },
  };
});

export const AutoCorrectTooltipContainer = () => {

  const position = useAutoCorrectTooltipPositioner();
  const ctx = useContext(TranscriptAutoCorrectContext);

  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);

  const containerRef = useRef<HTMLDivElement>();

  const hidden = !useTranscriptToolsEnabled();

  if (hidden) return null;

  return (
    <>
      <FloatingWrapper
        containerRef={containerRef}
        position={position}>
        <StyledPopper
          id="highlight-popper"
          className={classes.popper}
          container={containerRef.current}
          placement="top"
          open={position.visible}
          anchorEl={position.element}
          style={{
            zIndex: 2,
          }}
          modifiers={[
            {
              name: 'arrow',
              options: {
                element: arrowRef,
              },
            },
            {
              name: 'offset',
              options: {
                offset: [0, 15],
              },
            },
          ]}>
          <ClickAwayListener
            mouseEvent={'onMouseDown'}
            touchEvent={'onTouchStart'}
            onClickAway={() => ctx.setMark(null)}>
            <AutoCorrectTooltip
              arrow={{
                className: classes.arrow,
                ref: setArrowRef,
              }} />
          </ClickAwayListener>
        </StyledPopper>
      </FloatingWrapper>
    </>
  );
};