import { useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { usePastSearches } from '@containers/WorkspaceGlobalSearch/hooks/usePastSearches';
import type { WorkspaceGlobalSearchBarResultItem } from '@/types';
import { WorkspaceGlobalSearchBarQueryContext, WorkspaceGlobalSearchKeyboardContext, WorkspaceGlobalSearchBarResetContext } from './Context';
import type { KeyboardActiveHandler } from './interfaces';

export default function ResultsClickContainer({ index, item, children, handler }: ResultsClickContainerProps) {
  const history = useHistory();
  const { query } = useContext(WorkspaceGlobalSearchBarQueryContext);
  const { addPast } = usePastSearches();
  const { index: activeIndex, setActiveHandler } = useContext(WorkspaceGlobalSearchKeyboardContext);
  const { clickedResultReset } = useContext(WorkspaceGlobalSearchBarResetContext);

  const handleClick: KeyboardActiveHandler = useCallback(e => {
    e?.preventDefault();
    handler?.();
    addPast({
      query,
      entityId: item.id,
      item,
    });
    clickedResultReset();
    history.push(`/${item.path}`);
  }, [addPast, clickedResultReset, handler, query, item, history]);

  useEffect(() => {
    if (activeIndex !== -1 && index === activeIndex) {
      setActiveHandler(handleClick);
    }
  }, [index, activeIndex, setActiveHandler, handleClick]);

  return (
    <a href={`/${item.path}`} onClick={handleClick}>
      {children}
    </a>
  );
}

type ResultsClickContainerProps = {
  children: React.ReactNode;
  item: WorkspaceGlobalSearchBarResultItem;
  index: number;
  handler?: () => unknown;
};

export { ResultsClickContainer };