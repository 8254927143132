import { useHasConsultantRole } from '@containers/User';
import { Tableau } from '@/components/icons';
import { Integration } from '@/components/Settings';
import styles from './style/Settings.Integrations.css';
import { useTableauModal } from './TableaIntegrationModal';
import { useTableauAPIKey } from './hooks';

export const TableauIntegration = () => {
  const tableauAPIKey = useTableauAPIKey();

  const [toggleTableau, TableauModal] = useTableauModal();

  const hasConsultantRole = useHasConsultantRole();

  if (hasConsultantRole) return null;

  return (
    <>
      <Integration
        className={styles.item}
        icon={Tableau}
        title={title}
        description={description}
        connected={!!tableauAPIKey.key}
        disabled={false}
        loading={tableauAPIKey.loading}
        onConnect={toggleTableau}
        onDisconnect={toggleTableau}
        disconnectText={'Modify'} />
      <TableauModal existingKey={tableauAPIKey.key} />
    </>
  );
};

const title = `Tableau Data Sync`;
const description = `Load survey response data into your Tableau Server or Desktop.`;

export default TableauIntegration;