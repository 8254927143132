import { useCallback } from 'react';
import { useChainedCommands } from '@remirror/react';
import type { SurveyRichText } from '@/types/survey';
import type { PipedResponseExtension } from '../Extension.PipedResponse';

export const useAddPipedResponseNode = () => {

  const chain = useChainedCommands<PipedResponseExtension>();

  const add = useCallback((attrs: SurveyRichText.PipedResponseNode['attrs']) => {
    chain
      .addPipedResponseNode(attrs)
      .focus()
      .run();
  }, [chain]);

  return add;
};