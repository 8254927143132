import { useMemo } from 'react';
import { useParseSurveyRichText, useSurveyBuilderState } from '@containers/SurveyBuilder';
import { OptionQuestionTypes, RowQuestionTypes } from '@containers/Survey/utils/questions';
import { ActionExcludeQuestionChoiceQuestionTypes } from '../utils.action';

export const useSkipToQuestionItems = () => {
  const [state] = useSurveyBuilderState();
  const getQuestionText = useParseSurveyRichText();

  return useMemo(() => {
    return state.survey.questions.map(m => ({
      base: m.base,
      ordinal: m.ordinal,
      value: getQuestionText(m.value),
    }));
  }, [getQuestionText, state.survey.questions]);
};

export const useSkipToSectionItems = () => {
  const [state] = useSurveyBuilderState();

  return useMemo(() => {
    return state.survey.sections.filter(f => !f.hidden);
  }, [state.survey.sections]);
};

export const useIncludeSectionItems = () => {
  const [state] = useSurveyBuilderState();

  return useMemo(() => {
    return state.survey.sections.filter(f => f.hidden);
  }, [state.survey.sections]);
};

export const useExcludeQuestionChoiceItems = () => {
  const [state] = useSurveyBuilderState();
  const getQuestionText = useParseSurveyRichText();

  return useMemo(() => {
    return state.survey.questions
    .filter(q => ActionExcludeQuestionChoiceQuestionTypes.includes(q.typeId))
    .map(q => ({
      base: q.base,
      ordinal: q.ordinal,
      value: getQuestionText(q.value),
      hasOptions: OptionQuestionTypes.includes(q.typeId),
      hasRows: RowQuestionTypes.includes(q.typeId),
    }));
  }, [
    getQuestionText,
    state.survey.questions,
  ]);
};