import { memo, MouseEvent, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSurveyBuilderState } from '@containers/SurveyBuilder';
import { Back } from '@presentation/Back';
import { ButtonActivityIndicator, type ButtonActivityIndicatorProps } from '@presentation/Button.ActivityIndicator';
import type { BackProps } from '@presentation/interfaces';
import { cx } from '@utils';
import { useDiscardSurveyDraft } from '@utils/api/survey';
import { Button } from '@/components/Button';
import type { ButtonProps } from '@/components/Button/interfaces';
import styles from './style/Footer.css';

export const BackButton = ({ className, onClick, disabled, ...props }: BackProps) => {
  const history = useHistory();

  const handleClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    return onClick
      ? onClick(e)
      : history.goBack();
  }, [history, onClick]);

  if (disabled || onClick === null) return null;

  return (
    <Back
      className={cx(styles.back, className)}
      onClick={handleClick}
      {...props} />
  );
};

type FooterProps = {
  children?: React.ReactNode;
  className?: string;
};

export const Footer = (props: FooterProps) => {
  return (
    <div className={cx(styles.btns, props.className)}>
      {props.children}
    </div>
  );
};

export const NextButton = ({
  className,
  color = 'primary',
  children = 'Next',
  variant = 'brick',
  ...props
}: ButtonProps) => {
  return (
    <Button
      className={cx(styles.btn, className)}
      color={color}
      variant={variant}
      {...props}>
      {children}
    </Button>
  );
};

export const LoadingNextButton = ({ className, ...props }: ButtonActivityIndicatorProps) => {
  return (
    <ButtonActivityIndicator
      className={cx(styles.btn, className)}
      {...props}>
      Next
    </ButtonActivityIndicator>
  );
};

type SkipProps = {
  className?: string;
  onClick: () => void;
  text?: string;
};

export const SkipStep = memo(({ className, onClick, text = 'Skip this step' }: SkipProps) => {

  return (
    <div className={cx(styles.skip, className)} onClick={onClick}>
      {`${text} >`}
    </div>
  );
});

export const DiscardDraftButton = ({ className, ...props }: ButtonProps) => {
  const [state, dispatch] = useSurveyBuilderState();

  const { mutate: discardDraft, isLoading: isDiscarding } = useDiscardSurveyDraft({ surveyId: state.savedSurvey?.surveyId }, {
    onSuccess: () => dispatch({ type: 'draft-discarded' }),
  });

  if (!state.draft.surveyVersionId) {
    return null;
  }

  return (
    <ButtonActivityIndicator
      {...props}
      className={cx(styles.discardButton, className)}
      variant='brick'
      color='destructive'
      loading={isDiscarding}
      onClick={() => discardDraft({ surveyVersionId: state.draft.surveyVersionId })}>
      Discard Draft
    </ButtonActivityIndicator>);
};