import { useCallback } from 'react';
import { useSurveyQuestionConditionValue } from '@/containers/SurveyBuilder.Logic';
import { SurveyQuestionConditionType } from '@/enums';
import { cx } from '@utils';
import { OperatorDropdown } from '@presentation/SurveyBuilder';
import { NumberInput, OnNumberInputValueChange } from '@/components/Input';
import styles from './style/Builder.Condition.Question.css';

export const SlidersTallyConditionBuilder = () => {

  const [condition, updateConditionValue] = useSurveyQuestionConditionValue<SurveyQuestionConditionType.SliderValuesTally>();

  const handleRowOperatorUpdate = useCallback((operator: Operator) => {
    updateConditionValue({
      ...condition.value,
      rows: {
        comparate: condition.value.rows.comparate,
        operator,
      },
    });
  }, [updateConditionValue, condition]);

  const handleAnswerOperatorUpdate = useCallback((operator: Operator) => {
    updateConditionValue({
      ...condition.value,
      answer: {
        comparate: condition.value.answer.comparate,
        operator,
      },
    });
  }, [updateConditionValue, condition]);

  const handleAnswerComparateUpdate: OnNumberInputValueChange = useCallback(value => {
    updateConditionValue({
      ...condition.value,
      answer: {
        comparate: value.floatValue,
        operator: condition.value.answer.operator,
      },
    });
  }, [updateConditionValue, condition]);

  const handleRowComparateUpdate: OnNumberInputValueChange = useCallback(value => {
    updateConditionValue({
      ...condition.value,
      rows: {
        comparate: value.floatValue,
        operator: condition.value.rows.operator,
      },
    });
  }, [updateConditionValue, condition]);

  return (
    <div>
      <div className={cx(styles.row, styles.marginBottom)}>
        <div className={styles.operatorDropdown}>
          <OperatorDropdown
            onSelect={handleRowOperatorUpdate}
            value={condition.value.rows.operator} />
        </div>
        <div className={styles.comparateInput}>
          <NumberInput
            placeholder="-"
            allowLeadingZeros={false}
            onValueChange={handleRowComparateUpdate}
            value={condition.value.rows.comparate} />
        </div>
        <div className={styles.tallyText}>Row(s) where answer is</div>
      </div>
      <div className={styles.row}>
        <div className={styles.operatorDropdown}>
          <OperatorDropdown
            onSelect={handleAnswerOperatorUpdate}
            value={condition.value.answer.operator} />
        </div>
        <div className={styles.comparateInput}>
          <NumberInput
            placeholder="-"
            allowLeadingZeros={false}
            onValueChange={handleAnswerComparateUpdate}
            value={condition.value.answer.comparate} />
        </div>
      </div>
    </div>
  );
};