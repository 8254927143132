import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { OnboardingChecklistItem } from '@enums';
import * as api from '@api/users.onboarding';
import { useSelectUser } from '@/containers/Store';

export const useFetchOnboardingChecklist = (options?: Options) => {

  const me = useSelectUser();

  return useQuery(['user.onboarding.checklist'], () => {
    return api.fetchChecklist({ userId: me.id }).then(data => data.items);
  }, options);
};

type Data = OnboardingChecklistItem[];
type Options = UseQueryOptions<Data, AxiosResponse, Data>;