import { useCallback, useMemo, useState } from 'react';
import { useMessageTestingData } from '@containers/SurveyBuilder/hooks';
import { SurveyTemplate } from '@/types/survey';
import { useSubmitMedicalConditionsMT } from './mt-medical-conditions.submit';

export const useCanSaveMedicalConditionsMT = (data: SurveyTemplate.MessageTesting.Conditions) => {

  return useMemo(() => {
    return !!data.primary.value.length &&
      !!data.other.length;
  }, [data]);
};

export const useTemplateMedicalMedicalConditionsMT = () => {
  const [templateData] = useMessageTestingData();
  const [conditions, setConditions] = useState(templateData.medicalConditions);

  const onSelectOther = useCallback((value: SurveyTemplate.LinkedEntity<'medical-condition'>) => {
    setConditions(s => ({
      ...s,
      other: s.other.concat(value),
    }));
  }, []);

  const onRemoveOther = useCallback((id: string) => {
    setConditions(s => ({
      ...s,
      other: s.other.filter(f => f.id !== id),
    }));
  }, []);

  const onChangePrimary = useCallback((value: SurveyTemplate.LinkedEntity<'medical-condition'>) => {
    setConditions(s => ({
      ...s,
      primary: value,
    }));
  }, []);

  const submit = useSubmitMedicalConditionsMT();

  const onSubmit = useCallback(() => {
    submit(conditions);
  }, [
    conditions,
    submit,
  ]);

  return {
    onChangePrimary,
    onSelectOther,
    onRemoveOther,
    onSubmit,
    value: conditions,
  };
};