import { useCallback, useState } from 'react';
import { compareValuesUsingOperator } from '@utils/operator';
import { NumberInputTableQuestion } from '@/types/survey';
import { NumberTable } from '../interfaces';

type Item = {
  matrixRows: NumberInputTableQuestion.FormRow[];
  options: NumberInputTableQuestion.FormOption[];
  settings: NumberInputTableQuestion.Settings<number>;
};

type Props = {
  item: Item;
};

export const useNumberTableValidation = ({ item }: Props) => {
  const [cellValidations, setCellValidations] = useState<NumberTable.WarningsMap>(generateInitialCellMap(item));
  const [totalsValidations, setTotalValidations] = useState<NumberTable.TotalsWarningsMap>(generateInitialTotalsMap(item));

  const runTotalsValidations = useCallback((answer: NumberInputTableQuestion.RespondentAnswer.Value) => {
    let canSubmit = true;

    if (item.settings.total.comparate === null) return [canSubmit, {} as NumberTable.TotalsWarningsMap] as const;

    const map = item.options.reduce<NumberTable.TotalsWarningsMap>((acc, o) => {
      const optionTotal = item.matrixRows.reduce((acc2, r) => {
        const cellAnswer = answer.items.find(f => f.optionId === o.id && f.rowId === r.id);
        return acc2 + (cellAnswer?.value || 0);
      }, 0);

      const valid = compareValuesUsingOperator({
        comparate: item.settings.total.comparate,
        operator: item.settings.total.operator,
        value: optionTotal,
      });

      canSubmit = canSubmit && valid;

      acc[o.id] = !valid;

      return acc;
    }, {});

    return [canSubmit, map] as const;
  }, [
    item.matrixRows,
    item.options,
    item.settings.total,
  ]);

  const runCellValidations = useCallback((answer: NumberInputTableQuestion.RespondentAnswer.Value) => {
    let canSubmit = true;
    const { allowEmptyValues, validations: { minValue, maxValue } } = item.settings;

    const map = item.matrixRows.reduce<NumberTable.WarningsMap>((acc, row) => {
      const optionMap = item.options.reduce<NumberTable.WarningsOptionMap>((acc2, option) => {
        const cellAnswerItem = answer.items.find(f => f.optionId === option.id && f.rowId === row.id);
        const isEmpty = cellAnswerItem?.value === null || cellAnswerItem?.value === undefined;

        const columnValidations = (option.metadata.validations || []);

        let isValid = true;

        if (isEmpty) {
          isValid = allowEmptyValues;
        } else if (maxValue !== null && cellAnswerItem.value > maxValue) {
          isValid = false;
        } else if (minValue !== null && cellAnswerItem.value < minValue) {
          isValid = false;
        } else if (columnValidations.length) {
          isValid = columnValidations.every(e => {
            if (e.comparate.type === 'column-value') {
              const castedValidation = e as NumberInputTableQuestion.OtherColumnValidation;
              const otherOption = item.options.find(f => f.base.identifier === castedValidation.comparate.value.option.identifier);
              const comparateCellAnswer = answer.items.find(f => f.optionId === otherOption.id && f.rowId === row.id);
              if (!comparateCellAnswer) return false;
              return compareValuesUsingOperator({
                comparate: comparateCellAnswer.value,
                operator: e.operator,
                value: cellAnswerItem.value,
              });
            } else if (e.comparate.type === 'number') {
              return compareValuesUsingOperator({
                comparate: e.comparate.value,
                operator: e.operator,
                value: cellAnswerItem.value,
              });
            }
          });
        }

        canSubmit = isValid && canSubmit;

        acc2[option.id] = !isValid;

        return acc2;
      }, {});

      acc[row.id] = optionMap;
      return acc;

    }, {});

    return [canSubmit, map] as const;
  }, [
    item.matrixRows,
    item.options,
    item.settings,
  ]);

  const runValidations = useCallback((answer: NumberInputTableQuestion.RespondentAnswer.Value) => {

    const [cellsValid, cellsMap] = runCellValidations(answer);
    const [totalsValid, totalsMap] = runTotalsValidations(answer);

    setCellValidations(cellsMap);
    setTotalValidations(totalsMap);

    return cellsValid && totalsValid;
  }, [runCellValidations, runTotalsValidations]);

  return [cellValidations, totalsValidations, runValidations] as const;
};

function generateInitialTotalsMap(item: Item) {
  return item.options.reduce<NumberTable.TotalsWarningsMap>((acc, option) => {
    acc[option.id] = false;
    return acc;
  }, {});
}

function generateInitialCellMap(item: Item) {
  return item.matrixRows.reduce<NumberTable.WarningsMap>((acc, row) => {
    const optionMap = item.options.reduce<NumberTable.WarningsOptionMap>((acc2, option) => {
      acc2[option.id] = false;
      return acc2;
    }, {});
    acc[row.id] = optionMap;
    return acc;
  }, {});
}