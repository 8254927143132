import extend from '@enums/extend';

export enum LicenseType {
  Enterprise = 1,
  Viewer = 2,
}

const map = new Map([
  [LicenseType.Enterprise, { name: 'Enterprice Seat License' }],
  [LicenseType.Viewer, { name: 'Viewer Seat License' }],
]);

export default {
  LicenseType: extend(map),
};