import { useMemo } from 'react';
import { useRepeatSurveyData } from '@containers/RepeatSurveyResponses/hooks/useRepeatSurveyData';

export const useProjectVersion = (projectId: number) => {
  const { projectIds } = useRepeatSurveyData();

  const version = useMemo(() => {
    return projectIds.findIndex(id => id === projectId) + 1;
  }, [
    projectIds,
    projectId,
  ]);

  return version;
};

export default useProjectVersion;