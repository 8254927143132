import { useContext } from 'react';
import { useLeadFormContext } from '$website/containers/LeadForm/Context';
import { PricingModalContext } from '$website/containers/Pricing/Context';
import { Button } from '@/components/Button';
import { Body } from '@/components/Modal/Body';
import { Header } from '@/components/Modal/Header';
import styles from './style/Plan.Modal.css';

type Props = unknown;

export const PlanModalSubmitted = (props: Props) => {
  const [open, toggle] = useContext(PricingModalContext);
  const { values } = useLeadFormContext();

  const title = `Thanks ${values.firstName}!`;
  return (
    <div className={styles.submitted}>
      <Header>{title}</Header>
      <Body className={styles.body}>
        {`You’ll be contacted by a customer service representative to set up your account.`}
      </Body>
      <Button
        className={styles.btn}
        color="affirmative"
        onClick={toggle}
        variant="brick">
        Done
      </Button>
    </div>
  );
};

PlanModalSubmitted.displayName = 'Plan.Modal.Submitted';