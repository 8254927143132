import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { querykey } from '@consts';
import * as api from '@api';
import { GetRecentFiles } from '@api/interfaces/users';

type Data = GetRecentFiles.Response;

export default function useRecentFiles<TData = Data>(
  options?: UseQueryOptions<Data, AxiosError, TData>
) {

  return useQuery([querykey.Users.RecentFiles], async () => {
    return api.users.getRecentFiles();
  }, options);
}

export { useRecentFiles };