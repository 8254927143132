export enum SearchType {
  Degree = 'degree',
  Company = 'company',
  FormerCompany = 'formerCompany',
  Industry = 'industry',
  JobFunction = 'jobfunction',
  Keyword = 'keyword',
  Location = 'location',
  Product = 'product',
  Sector = 'sector',
  School = 'school',
  Seniority = 'seniority',
  SubIndustry = 'subindustry',
  Title = 'title',
  GroupTag = 'group-tag',
  Topic = 'topic',
}