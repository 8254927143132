import { useEffect } from 'react';
import { useSurveyFormScreenContext } from '@/containers/SurveyForm';
import { useLeftBarContext, useFormPreviewContext } from '../context';

export const useSyncSurveyState = () => {
  const { dispatch } = useLeftBarContext();
  const { preview } = useFormPreviewContext();
  const [formState] = useSurveyFormScreenContext();

  useEffect(() => {
    dispatch({
      type: 'survey-items-updated',
      items: preview.items,
      sections: preview.sections,
    });
  }, [preview.items, preview.sections, dispatch]);

  useEffect(() => {
    dispatch({
      type: 'active-item-updated',
      activeItemId: formState?.item?.id,
    });
  }, [dispatch, formState?.item?.id]);

  useEffect(() => {
    dispatch({
      type: 'completed-items-updated',
      completedItemIds: formState?.metadata?.completedItemIds ?? [],
    });
  }, [formState?.metadata?.completedItemIds, dispatch]);
};