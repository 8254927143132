import { SectionList, SectionListRenderItemInfo } from '@/components/SectionList';
import { Timeslot } from './Timeslot';
import { AvailableTimeslot, EventsMap, EventsSectionData, TimeslotItem, TimeslotProps } from './interfaces';
import styles from './style.css';
import { keyToReadable } from './utils';

type Props = {
  events:   EventsMap;
  onChange: (value: TimeslotProps<AvailableTimeslot>) => unknown;
};

const CalendarEvents = (props: Props) => {

  const sections = Object.keys(props.events).reduce<EventsSectionData[]>((acc, x) => {
    return acc.concat({
      data: props.events[x],
      key: x,
      title: keyToReadable(x),
    });
  }, []);

  function SectionHeader({ section }: SectionListRenderItemInfo<TimeslotItem, EventsSectionData>) {
    return (
      <div className={styles.sticky}>
        <div className={styles.title}>
          {section.title}
        </div>
      </div>
    );
  }

  function SectionItem(info: SectionListRenderItemInfo<TimeslotItem, EventsSectionData>) {
    return (
      <Timeslot
        item={info.item}
        onDelete={props.onChange}
        section={info.section} />
    );
  }

  return (
    <SectionList
      keyExtractor={item => String(item.start)}
      sections={sections}
      renderItem={SectionItem}
      renderSectionHeader={SectionHeader} />
  );
};

export { CalendarEvents };
export default CalendarEvents;