import { useMemo, useCallback } from 'react';
import { SurveyActionType, utils as enumUtils } from '@enums';
import DropDown from '@/components/DropDown';

export const ActionTypeDropdown = ({
  items,
  onSelect,
  value,
}: Props) => {

  const getItemValue = useCallback((item: ActionTypeDropdownItem) => {
    return enumUtils.SurveyActionType.getName(item.id);
  }, []);

  const handleSelect = useCallback((item: ActionTypeDropdownItem) => {
    onSelect(item.id);
  }, [onSelect]);

  const mappedItems = useMemo(() => {
    return items.map(m => ({ id: m }));
  }, [items]);

  const text = value !== null
    ? enumUtils.SurveyActionType.getName(value)
    : null;

  return (
    <DropDown
      placeholder="Select action"
      items={mappedItems}
      getItemValue={getItemValue}
      onSelect={handleSelect}
      text={text} />
  );
};

type ActionTypeDropdownItem = {
  id: SurveyActionType;
};

type Props = {
  items: SurveyActionType[];
  onSelect: (item: SurveyActionType) => void;
  value: SurveyActionType;
};

export default ActionTypeDropdown;