import axios from 'axios';
import qs from 'query-string';
import { SurveyTypeOld } from '@/enums';
import type { Projects } from '@services/api/interfaces';

export * from './projects.external-sourcing.config';

const xhr = axios.create({
  baseURL: process.env.BACKEND_BASE_URL,
  withCredentials: true,
});

export const getProjectEntry = (data: Projects.ExternalSourcing.GetProjectEntry.Request) => {
  const query = qs.stringify({
    page: JSON.stringify(data.pageQueries),
    cid: data.configIdentifier,
  }, {
    skipEmptyString: true,
    skipNull: true,
  });

  return xhr.get<Projects.ExternalSourcing.GetProjectEntry.Response>(
    `/projects/${data.projectId}/external-sourcing${data.userId ? `/${data.userId}` : ''}?${query}`
  );
};

export const getProjectSurveyForm = (data: Projects.ExternalSourcing.GetProjectSurveyForm.Request) => {
  return xhr.get<Projects.ExternalSourcing.GetProjectSurveyForm.Response>(
    `/projects/${data.projectId}/external-sourcing/${data.userId}/survey-form`
  );
};

export const goBack = ({ projectId, userId, data }: Projects.ExternalSourcing.GoBack.Request) => {
  return xhr.patch<Projects.ExternalSourcing.GoBack.Response>(`/projects/${projectId}/external-sourcing/${userId}/survey-versions/${data.surveyVersionId}/responses`, {
    type: 'back',
    body: data,
  });
};

export const submitMessageScreen = ({ projectId, userId, data }: Projects.ExternalSourcing.SubmitMessageScreen.Request) => {
  return xhr.patch<Projects.ExternalSourcing.SubmitMessageScreen.Response>(`/projects/${projectId}/external-sourcing/${userId}/survey-versions/${data.surveyVersionId}/responses`, {
    type: 'submit-message-screen',
    body: data,
  });
};

export const submitAIEScreen = ({ projectId, userId, data }: Projects.ExternalSourcing.SubmitAIEScreen.Request) => {
  return xhr.patch<Projects.ExternalSourcing.SubmitMessageScreen.Response>(`/projects/${projectId}/external-sourcing/${userId}/survey-versions/${data.surveyVersionId}/responses`, {
    type: 'submit-aie-screen',
    body: data,
  });
};

export const submitAnswer = ({ projectId, userId, data }: Projects.ExternalSourcing.SubmitAnswer.Request) => {
  return xhr.patch<Projects.ExternalSourcing.SubmitAnswer.Response>(`/projects/${projectId}/external-sourcing/${userId}/survey-versions/${data.surveyVersionId}/responses`, {
    type: 'submit-answer',
    body: data,
  });
};

export const saveProjectSurveyResponse = ({ projectId, userId, data }: Projects.ExternalSourcing.SaveProjectSurveyResponse.Request) => {
  return xhr.patch<Projects.ExternalSourcing.SaveProjectSurveyResponse.Response>(
    `/projects/${projectId}/external-sourcing/${userId}/surveys/${data.surveyVersionId}/response?type=${SurveyTypeOld.Project}`,
    data
  );
};