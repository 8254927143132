import { useCallback, useMemo } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder';
import { useQuestionBuilderItem } from '@containers/SurveyBuilder.Question';
import type { SurveySettings } from '@/types/survey';
import { NumberInput } from '@/components/Input';
import { CheckboxLabel } from '@/components/Checkbox';
import styles from './style/Settings.Rationale.css';

type Props = {
  className?: string;
};

export const RationaleCheckbox = ({ className }: Props) => {

  const item = useQuestionBuilderItem();

  const [_, dispatch] = useSurveyBuilderState();

  const rationale = useMemo(() => {
    return (item.settings as SurveySettings.AskForRationale).rationale ||
    {
      enabled: false,
      minimum: 75,
    };
  }, [item.settings]);

  const handleUpdate = useCallback((data: Partial<SurveySettings.Rationale>) => {
    dispatch({
      type: 'rationale-updated',
      questionIdentifier: item.base.identifier,
      value: {
        ...rationale,
        ...data,
      },
    });
  }, [
    dispatch,
    item.base.identifier,
    rationale,
  ]);

  const handleToggle = useCallback((e: React.ChangeEvent) => {
    handleUpdate({
      enabled: !rationale.enabled,
    });
  }, [
    handleUpdate,
    rationale,
  ]);

  const handleMinimumChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const minimum = value === '' ? null : +value;

    handleUpdate({
      minimum,
    });
  }, [
    handleUpdate,
  ]);

  const renderMinimum = useCallback(() => {
    if (!rationale.enabled) return null;

    return (
      <div className={styles.item}>
        <div className={styles.title}>Min. characters</div>
        <div className={styles.input}>
          <NumberInput
            onChange={handleMinimumChange}
            value={rationale.minimum?.toString() || ''} />
        </div>
      </div>
    );
  }, [handleMinimumChange, rationale]);

  return (
    <div className={className}>
      <CheckboxLabel
        className={styles.checkbox}
        checked={rationale.enabled}
        label="Ask for rationale"
        onChange={handleToggle} />
      {renderMinimum()}
    </div>
  );
};