import { useCallback } from 'react';
import type { SurveyQuestionType } from '@enums/Survey';
import * as surveyBuilder from '@containers/SurveyBuilder/utils';
import { QuestionOptionsBuilderContainer } from '@containers/SurveyBuilder.Question/OptionsBuilderContainer';
import { useQuestionBuilderItem } from '@containers/SurveyBuilder.Question/Context';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { QuestionSettingTitle } from '@presentation/SurveyBuilder';
import type { DropdownQuestion } from '@/types/survey';
import { CheckboxLabel } from '@/components/Checkbox';
import { ResponsePiping } from './ResponsePiping';
import {
  RationaleCheckbox,
  RandomizeOptionsCheckbox,
} from './Settings';
import { QuestionOptionsBuilder } from './OptionsBuilder';
import { DropdownOptionContextMenu } from './Dropdown.Option.ContextMenu';
import styles from './style/Dropdown.Question.css';
import { LanguageEditingBarrier } from './LanguageEditingBarrier';

type Props = {
  item: DropdownQuestion.Question;
};

export const DropdownQuestionBuilder = (props: Props) => {
  const item = useQuestionBuilderItem<SurveyQuestionType.Dropdown>();
  const [_, dispatch] = useSurveyBuilderState();

  const handlePresetToggled = useCallback(() => {
    dispatch({
      questionIdentifier: item.base.identifier,
      type: 'preset-options-updated',
      value: item.settings.presetOptions ? null : 'us-states',
    });
  }, [dispatch, item.base.identifier, item.settings.presetOptions]);

  return (
    <>
      <LanguageEditingBarrier>
        <CheckboxLabel
          className={styles.presets}
          checked={!!item.settings.presetOptions}
          label="Fill Options with US States"
          onChange={handlePresetToggled} />
      </LanguageEditingBarrier>

      <QuestionSettingTitle title="Options">
        <ResponsePiping disabled={!!item.settings.presetOptions} />
      </QuestionSettingTitle>
      <QuestionOptionsBuilderContainer
        maxOptions={surveyBuilder.dropdown.MaxOptions}
        minOptions={surveyBuilder.dropdown.MinOptions}>
        <QuestionOptionsBuilder
          renderAction={option => (
            <DropdownOptionContextMenu
              question={item}
              option={option as DropdownQuestion.Option} />
          )} />
      </QuestionOptionsBuilderContainer>

      <LanguageEditingBarrier>
        <RandomizeOptionsCheckbox className={styles.rationale} />

        <RationaleCheckbox className={styles.rationale} />
      </LanguageEditingBarrier>
    </>
  );
};

export default DropdownQuestionBuilder;