import { useContext, lazy, Suspense, useCallback } from 'react';
import { cx } from '@utils';
import { Button, Buttons } from '@/components/Button';
import type { UseModalProps } from '@/components/Modal';
import { Modal, Header, useModal } from '@/components/Modal';
import { ItemLanguageEditingContainer, SurveyLanguageEditorContext, useIsEditingSurvey, useSurveyBuilderItem, useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { LanguageCodeSection } from './LanguageSection';
import styles from './style/ItemLanguageModal.css';

// eslint-disable-next-line import/no-cycle, no-constant-condition
const LazyItemBuilder = lazy(() => import(true ? './SurveyItemBuilder' : null));

export const ItemLanguageModal = (props: Props) => {
  const item = useSurveyBuilderItem();
  return (
    <Modal
      className={styles.modal}
      disableOverlayClick={true}
      {...props}>
      <ItemLanguageEditingContainer languageCode={props.languageCode} itemIdentifier={item.identifier}>
        <ModalContent {...props} />
      </ItemLanguageEditingContainer>
    </Modal>
  );
};

const ModalContent = ({ languageCode, ...props }: Props) => {
  const item = useSurveyBuilderItem();
  const { saveLanguageChanges } = useContext(SurveyLanguageEditorContext);

  const onSave = useCallback(() => {
    saveLanguageChanges();
    props.onClose();
  }, [props, saveLanguageChanges]);

  const isEditing = useIsEditingSurvey();

  return (
    <>
      <Header>Manage Language Versions</Header>
      <LanguageCodeSection classes={{ body: styles.sectionBody }} languageCode={languageCode}>
        <Suspense fallback={<div>Loading</div>}>
          <LazyItemBuilder item={item} />
        </Suspense>
      </LanguageCodeSection>
      <Buttons className={styles.buttons}>
        <Button
          color='destructive'
          variant='brick'
          onClick={props.onClose}>
          Cancel
        </Button>
        <Button
          color='affirmative'
          variant='brick'
          disabled={isEditing}
          onClick={onSave}>
          Save
        </Button>
      </Buttons>
    </>
  );
};

type LanguageProps = {
  languageCode: string;
};

type Props = LanguageProps & UseModalProps;