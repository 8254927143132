export class CompanyType {
  static get Public() {
    return 1;
  }

  static get Private() {
    return 2;
  }

  static get NonProfit() {
    return 3;
  }

  static get LawFirm() {
    return 4;
  }

  static get Hospital() {
    return 5;
  }

  static get Unknown() {
    return 6;
  }

  static CompanyTypeMap = new Map([
    [CompanyType.Public, { name: 'Public' }],
    [CompanyType.Private, { name: 'Private' }],
    [CompanyType.NonProfit, { name: 'Non-Profit' }],
    [CompanyType.LawFirm, { name: 'Law Firm' }],
    [CompanyType.Hospital, { name: 'Hospital' }],
    [CompanyType.Unknown, { name: 'Unknown' }],
  ]);

  static getName(id) {
    return (CompanyType.CompanyTypeMap.get(Number(id)) || {}).name;
  }

  static keys() {
    return Array.from(CompanyType.CompanyTypeMap.values()).map(({ name }) => name);
  }

  static values() {
    return Array.from(CompanyType.CompanyTypeMap.keys());
  }
}
