import { useEffect } from 'react';
import { SurveyLogicExpression } from '@/types/survey.logic.expression';
import { ExpressionEditorContainer } from '@/containers/SurveyBuilder.Logic/Expression.EditorContainer';
import { ExpressionEditor } from './Expression.Editor';
import { useSurveyExpressionEditor } from './hooks';

type Props = {
  value: SurveyLogicExpression.RichTextValue;
};

export const SurveyRuleExpression = ({ value }: Props) => {

  const { manager, state, setState } = useSurveyExpressionEditor({
    initialState: value,
  });

  return (
    <ExpressionEditorContainer
      editable={false}
      manager={manager}
      setState={setState}
      state={state}>
      <ExpressionEditor />
    </ExpressionEditorContainer>
  );
};