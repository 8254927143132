import * as Pricing from '$website/containers/Pricing/interfaces';

export const customer: Pricing.Plan<Pricing.ColumnIdentifer.Customer> = {
  id: Pricing.ColumnIdentifer.Customer,
  name: `Customer Research`,
  description: `Great for small projects.`,
  features: [
    `incentive-management`,
    `participant-management`,
    `email-support`,
    `video-and-audio-downloads`,
    `csv-spss-import-export`,
    `highlights-tags-charts`,
    `notes`,
    `encrypted-data-and-backups`,
  ],
  payment: {
    monthly: 25,
    yearly: 25,
  },
  tiered: [{
    id: 'viewer-seats',
    value: `Unlimited`,
  }, {
    id: `video-image-files`,
    value: `Unlimited`,
  }, {
    id: `projects`,
    value: `Unlimited`,
  }, {
    id: `tracked-research-participants`,
    value: 10,
  }],
  usage: [{
    id: `included-seats`,
    value: 1,
  }, {
    id: `additional-seats`,
    value: `-`,
  }],
};

export const market: Pricing.Plan<Pricing.ColumnIdentifer.Market> = {
  id: Pricing.ColumnIdentifer.Market,
  name: `Market Research & Repository`,
  description: `Access and organize customer knowledge.`,
  features: [
    `incentive-management`,
    `participant-management`,
    `email-support`,
    `video-and-audio-downloads`,
    `csv-spss-import-export`,
    `highlights-tags-charts`,
    `notes`,
    `encrypted-data-and-backups`,
    `data-processing-privacy`,
    `custom-compliance-settings`,
  ],
  payment: {
    monthly: 125,
    yearly: 115,
  },
  tiered: [{
    id: 'viewer-seats',
    value: `Unlimited`,
  }, {
    id: `video-image-files`,
    value: `Unlimited`,
  }, {
    id: `projects`,
    value: `Unlimited`,
  }, {
    id: `tracked-research-participants`,
    value: 50,
  }],
  usage: [{
    id: `included-seats`,
    value: 5,
  }, {
    id: `additional-seats`,
    value: `-`,
  }],
};

export const enterprise: Pricing.Plan<Pricing.ColumnIdentifer.Enterprise> = {
  id: Pricing.ColumnIdentifer.Enterprise,
  name: `Enterprise`,
  description: `Integrate the market into the lifecycle of your product.`,
  features: [
    `incentive-management`,
    `participant-management`,
    `email-support`,
    `recruiting-support`,
    `privacy-support-response`,
    `service-level-agreement`,
    `video-and-audio-downloads`,
    `csv-spss-import-export`,
    `highlights-tags-charts`,
    `notes`,
    `full-text-search-w-filters`,
    `comments-and-sharing`,
    `cross-project-tags`,
    `project-templates`,
    `user-settings`,
    `encrypted-data-and-backups`,
    `data-processing-privacy`,
    `custom-compliance-settings`,
    `flexible-payment-options`,
  ],
  payment: {
    monthly: 300,
    yearly: 250,
  },
  tiered: [{
    id: 'viewer-seats',
    value: `Unlimited`,
  }, {
    id: `video-image-files`,
    value: `Unlimited`,
  }, {
    id: `projects`,
    value: `Unlimited`,
  }, {
    id: `tracked-research-participants`,
    value: 100,
  }],
  usage: [{
    id: `included-seats`,
    value: 10,
  }, {
    id: `additional-seats`,
    value: 10,
  }],
};