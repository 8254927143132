import { ConferenceTagSummaryItem, TranscribeRT } from '@/types';
import { Header } from '@/components/Modal/Header';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import { TagSummaryCitationsTable } from './CitationsTable';
import styles from './style/Modal.Citations.css';

type Props = {
  hideSourceColumn?: boolean;
  items: (TranscribeRT.TaggedMoment & Partial<ICallId>)[];
  summaryItem: ConferenceTagSummaryItem;
} & IProjectId & Pick<ModalProps, 'open' | 'onClose'>;

export const TagSummaryCitationsModal = ({
  hideSourceColumn = false,
  items,
  open,
  onClose,
  summaryItem,
  projectId,
}: Props) => {

  const title = `${summaryItem.tag.name} Citations`;

  return (
    <Modal
      className={styles.root}
      open={open}
      onClose={onClose}>

      <Header text={title} />

      <div className={styles.body}>
        <TagSummaryCitationsTable
          projectId={projectId}
          hideSourceColumn={hideSourceColumn}
          items={items} />
      </div>

    </Modal>
  );
};