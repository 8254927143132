import { ProfileEntry, SearchType } from '@enums';
import Tags from './Profile.Tags';
import { Section } from './interfaces';
import styles from './style/Profile.Products.css';

type Props =
  Section.SingleEntry.ReadOnlyProps<ProfileEntry.Product>;

export const ProfileProducts = ({ value }: Props) => {

  return (
    <Tags
      className={styles.tags}
      items={value}
      type={SearchType.Product} />
  );
};

export default ProfileProducts;