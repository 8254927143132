import { ChatInstanceItemType } from '@/brand-insights/enums';
import type { Chat } from '@/brand-insights/types';
import { FileUploadItem } from './Item.FileUpload';
import { QueryItem } from './Item.Query';
import { SegmentationItem } from './Item.Segmentation';

type Props = {
  canRegenerate: boolean;
  item: Chat.InstanceItem;
};

export const ChatItem = ({ canRegenerate, item }: Props) => {
  switch (item.type) {
    case ChatInstanceItemType.Query:
      return (
        <QueryItem
          canRegenerate={canRegenerate}
          item={item} />
      );

    case ChatInstanceItemType.FileUpload:
      return <FileUploadItem item={item} />;

    case ChatInstanceItemType.Segmentation:
      return <SegmentationItem item={item} />;

    default:
      throw new UnreachableCaseError(item);
  }
};