import { useMutation } from '@tanstack/react-query';
import { saveProjectSurveyResponse } from '@api/surveys.capture-sheet';
import { Surveys } from '@api/interfaces/surveys';

type Props = ICallId & ISurveyId;

export const useSaveSurveyMutation = ({ surveyId, callId }: Props) => {
  return useMutation(['capture-sheet-save-survey-response', surveyId, callId], (data: Surveys.CaptureSheets.SaveProjectSurveyResponse.Request['data']) => {
    return saveProjectSurveyResponse({
      data,
      callId,
    });
  });
};