import { useCallback, useContext } from 'react';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { Button } from '@/components/Button';
import { PublishModalContext } from './context';
import styles from './style/PublishModal.css';

type Props = {
  onNext: () => Promise<unknown>;
  onCancel: () => void;
  cancelText?: string;
  nextText: string;
  disabled?: boolean;
};

export const Navigation = ({ onNext, nextText, onCancel, cancelText = 'Cancel', disabled = false }: Props) => {
  const handleNext = usePublishModalNavigateNext(onCancel);

  return (
    <div className={styles.buttons}>
      <Button.Destructive variant='brick' onClick={onCancel}>{cancelText}</Button.Destructive>
      {nextText &&
        <ButtonActivityIndicator
          variant='brick'
          color='affirmative'
          disabled={disabled}
          onClick={handleNext}>
          {nextText}
        </ButtonActivityIndicator>
      }
    </div>
  );
};

export const usePublishModalNavigateNext = (cancel: () => void) => {
  const { actions, index, numScreens } = useContext(PublishModalContext);

  return useCallback(() => {
    if (index + 1 >= numScreens) {
      cancel();
    } else {
      actions.next();
    }
  }, [
    actions,
    cancel,
    index,
    numScreens,
  ]);
};