import { useContext, useCallback } from 'react';
import { useEditorState } from '@remirror/react';
import type { Remirror } from '@/types';
import type { MonologueNode } from '@/types/transcribe.rich-text';
import { NodeType } from '@/types/transcribe.rich-text';
import type { Highlight } from '@/types/transcribe.rich-text';
import { ReassignSpeakerSelectionContext } from '../context';
import { useMapDocPosToTimePos } from './useMapPositions';

export const useHandleReassignSpeaker = () => {

  const state = useEditorState();
  const mapPosToTime = useMapDocPosToTimePos();
  const { setValue } = useContext(ReassignSpeakerSelectionContext);

  const handleReassignSpeaker = useCallback((highlight: Highlight) => {
    const range = mapPosToTime({
      from: highlight.from,
      to: highlight.to,
      state,
    });

    const doc = state.doc.toJSON() as Remirror.RootNode<MonologueNode>;

    let speakerIndex: number = undefined;

    for (const x of doc.content) {
      if (x.type === NodeType.MonologueDetails) {
        if (range.start >= x.attrs.s && range.end <= x.attrs.e) {
          speakerIndex = x.attrs.spk;
          break;
        }
      }
    }

    setValue({
      end: range.end,
      start: range.start,
      speakerIndex,
    });

  }, [
    mapPosToTime,
    setValue,
    state,
  ]);

  return handleReassignSpeaker;
};