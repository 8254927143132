import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { cx } from '@utils';
import { OngoingDurationContainer, Tag } from '@/components/Conference.Tagging';
import { TaggingContext } from '@/components/Conference.Tagging/Context';
import { PanelToolsContext } from './Context';
import { PanelToolsHeader } from './Pane.Header';
import styles from './style/Pane.Tagging.css';

type Props = {
  started: Date;
} & ICallId;

export const Tagging = (props: Props) => {
  const ctx = useContext(PanelToolsContext);
  const tagging = useContext(TaggingContext);

  const count = tagging.items.length;
  const insert = tagging.insert;

  useEffect(() => {

    tagging.setFocus(null);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {

    if (count <= 0) {
      insert();
    }

  }, [
    count,
    insert,
  ]);

  const handleDiscard = useCallback((identifier: string) => {
    const single = tagging.items.length === 1;

    tagging.discard(identifier);

    if (single) {
      ctx.tagging.toggle();
    }
  }, [
    ctx.tagging,
    tagging,
  ]);

  const canInsert = useMemo(() => {
    return true;
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <OngoingDurationContainer started={props.started}>
          <PanelToolsHeader
            onClickPopout={ctx.tagging.popout}
            onClickMinimize={ctx.tagging.toggle}
            title="Insert Tag" />
          <div className={cx(styles.main, { [styles.active]: canInsert })}>
            {tagging.items.map(x =>
              <div
                className={cx(styles.item, { [styles.ongoing]: tagging.focus !== x.identifier })}
                key={x.identifier}>
                <Tag.Item
                  className={cx({
                    [styles.separator]: !!x.start,
                    [styles.focus]: tagging.focus === x.identifier,
                  })}
                  discard={handleDiscard}
                  focused={tagging.focus === x.identifier}
                  item={x}
                  remove={tagging.remove}
                  setFocus={tagging.setFocus}
                  setValue={tagging.setValue} />
              </div>)}
            {canInsert &&
              <div className={styles.new}>
                <button
                  className={styles.add}
                  onClick={tagging.insert}>
                  New Tag
                </button>
              </div>}
          </div>
        </OngoingDurationContainer>
      </div>
    </div>
  );
};

Tagging.displayName = 'Pane.Tagging';

type PopupProps = {
  started: Date;
};

export const Popup = (props: PopupProps) => {
  const tagging = useContext(TaggingContext);

  const handleDiscard = useCallback((identifier: string) => {
    const single = tagging.items.length === 1;

    tagging.discard(identifier);

    if (single) {
      tagging.insert();
    }
  }, [
    tagging,
  ]);

  const canInsert = useMemo(() => {
    return true;
  }, []);

  const onUnload = useCallback(() => {
    /* eslint-disable-next-line */
    window.opener.postMessage({
      action: `unload`,
      type: `tagging`,
      tagging: {
        items: tagging.items.filter(x => !x.end),
      },
    }, '*');
  }, [tagging.items]);

  const count = tagging.items.length;
  const ref = useRef(tagging.items.length);

  useEffect(() => {
    const previous = ref.current;

    if (count > previous) {
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: 'smooth',
      });
    }

    ref.current = count;

  }, [count]);

  useEffect(() => {
    if (onUnload) {
      window.addEventListener('unload', onUnload);
    }

    return () => {
      if (onUnload) {
        window.removeEventListener('unload', onUnload);
      }
    };
  }, [onUnload]);

  return (
    <div className={styles.popup}>
      <OngoingDurationContainer started={props.started}>
        <div className={cx(styles.mini)}>
          {tagging.items.map(x =>
            <div
              className={cx(styles.item, { [styles.ongoing]: tagging.focus !== x.identifier })}
              key={x.identifier}>
              <Tag.Item
                className={cx({
                  [styles.separator]: !!x.start,
                  [styles.focus]: tagging.focus === x.identifier,
                })}
                discard={handleDiscard}
                focused={tagging.focus === x.identifier}
                item={x}
                remove={tagging.remove}
                setFocus={tagging.setFocus}
                setValue={tagging.setValue} />
            </div>)}
          {canInsert &&
            <div className={styles.new}>
              <button
                className={styles.add}
                onClick={tagging.insert}>
                New Tag
              </button>
            </div>}
        </div>
      </OngoingDurationContainer>
    </div>
  );
};