import { useMemo } from 'react';
import { Error } from '@mui/icons-material';
import { useProjectConferenceTagSummaries } from '@utils/api';
import { useRefreshSummariesContext } from '@containers/ConferenceTag.Summary';
import { ConferenceTagSummaries } from '@/components/ConferenceTag.Summary';
import { Actions } from './Insights.Actions';
import { LoadingProgress } from './Insights.LoadingProgress';
import styles from './style/Insights.css';

type Props = {
  actionsEnabled: boolean;
} & IProjectId;

export const Panel = (props: Props) => {

  const { data, isLoading, isError } = useProjectConferenceTagSummaries({
    projectId: props.projectId,
  }, {
    keepPreviousData: true,
    placeholderData: {
      meta: {
        canGenerateSummary: false,
      },
      items: [],
    },
    refetchOnWindowFocus: false,
  });

  const refreshCtx = useRefreshSummariesContext();

  const isRefreshing = useMemo(() => {
    return refreshCtx.isRefreshing && !refreshCtx.tagRefreshing;
  }, [
    refreshCtx.isRefreshing,
    refreshCtx.tagRefreshing,
  ]);

  if (isError) return <Error />;

  const hasData = data.items.length > 0;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.header}>
            <div className={styles.trunc}>
              <h2 className={styles.h2}>Summary</h2>
            </div>
            <div className={styles.actions}>
              {!isLoading &&
                <Actions
                  canGenerateSummary={data.meta.canGenerateSummary}
                  hasData={hasData}
                  projectId={props.projectId} />
              }
            </div>
          </div>
          <div className={styles.notepad}>
            {isRefreshing &&
              <div className={styles.loading}>
                <LoadingProgress />
              </div>
            }
            {!isLoading && !isRefreshing &&
              <ConferenceTagSummaries items={data.items} />
            }
            {!isLoading && !hasData && !isRefreshing &&
              <div className={styles.empty}>
                <div className={styles.placeholder}>Tag content in transcripts to generate</div>
                <div className={styles.placeholder}>an automated summary.</div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

Panel.displayName = 'Insights.Panel';