import extend from '@enums/extend';

export enum UserProjectStatus {
  Invited      = 1,
  Active       = 2,
  Recommended  = 3,
  Rejected     = 4,
  Added        = 5,
  InReview     = 6,
  Inactive     = 7,
  Disqualified = 8,
  Staging      = 9,
}

const map = new Map([
  [UserProjectStatus.Invited,       { name: 'Invited'  }],
  [UserProjectStatus.Active,        { name: 'Active'   }],
  [UserProjectStatus.Recommended,   { name: 'Recommended' }],
  [UserProjectStatus.Rejected,      { name: 'Rejected' }],
  [UserProjectStatus.Added,         { name: 'Added' }],
  [UserProjectStatus.InReview,      { name: 'In-Review' }],
  [UserProjectStatus.Inactive,      { name: 'Inactive'  }],
  [UserProjectStatus.Disqualified,  { name: 'Disqualified'  }],
  [UserProjectStatus.Staging,       { name: 'Staging' }],
]);

export default {
  UserProjectStatus: extend(map),
};