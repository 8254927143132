import { useContext, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { TranscriptMediaPlayerContext } from '@/containers/Transcript/context';

type SeekingElementsMap = { element: Element; s: number };

export function useFirstLoadSeek() {
  const location = useLocation();
  const initialized = useRef(false);

  const player = useContext(TranscriptMediaPlayerContext);

  const search = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const query = {
    s: +search.get('s') || null,
  };

  useEffect(() => {
    initialized.current = false;
  }, [query.s]);

  useEffect(() => {
    if (!initialized.current && query.s && player) {
      const nodes: SeekingElementsMap[] = [];
      document.querySelectorAll(`div[data-ts-s]`).forEach(element => {
        nodes.push(({
          element: element,
          s: +element.getAttribute('data-ts-s') || null,
        }));
      });

      const closest = nodes.reduce((prev, curr) => {
        return (Math.abs(curr.s - query.s) < Math.abs(prev.s - query.s) ? curr : prev);
      });

      if (closest) {
        setTimeout(() => {
          player.currentTime = query.s;
          closest.element.parentElement.scrollIntoView({ behavior: 'smooth' });
        }, 0);
        initialized.current = true;
      }
    }
  }, [
    query.s,
    player,
  ]);
}

