import type { RankingQuestion } from '@/types';
import { RankingStory } from './Ranking.Story';

type Props = {
  question: RankingQuestion.FormQuestion;
};

export const RankingStoryContainer = ({ question }: Props) => {
  return (
    <RankingStory items={question.options} />
  );
};