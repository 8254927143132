import { useContext, useMemo } from 'react';
import { Redirect, generatePath, useHistory } from 'react-router-dom';
import type { AxiosResponse } from 'axios';
import { TranscriptLockContext } from '@containers/Transcript.Redaction/Context';
import { WorkspaceBreadcrumbsContext } from '@containers/WorkspaceObject';
import * as $api from '@api';
import { path } from '@consts';
import { useDownloadFile } from '@containers/Workspace.File/hooks';
import { TranscriptType } from '@enums';
import { useQueryWrapper } from '@/utils';
import type { SuggestedMentionsContextValue } from '@/components/Remirror/Context';
import { SuggestedMentionsContext } from '@/components/Remirror/Context';
import {
  TranscriptHasMediaContext,
  TranscriptTypeContext,
  RefetchTranscriptContext,
  TranscriptDocumentContext,
  TranscriptEnhancementStateContext,
  TranscriptSpeakersContext,
  TranscriptIdContext,
  DownloadTranscriptMediaContext,
  WorkspaceTranscriptDetailsContext,
} from './context';
import { TranscriptDataContainer } from './Transcript.Data';
import { TranscriptStateContainer } from './Transcript.State';
import { TranscriptMetadataContainer } from './Metadata.Container';

/* Note: This container is for any contexts/containers that are specific to the workspace transcript page */

type Props =
  ITranscriptId &
  ChildrenProps;

const WorkspaceTranscriptContainer = (props: Props) => {
  const history = useHistory();
  const [_, setTranscriptLocked] = useContext(TranscriptLockContext);

  const query = useQueryWrapper({
    queryKey: ['workspace.transcript', { transcriptId: props.transcriptId }],
    queryFn: () => {
      return $api.workspaces.transcript.getWorkspaceTranscript({
        transcriptId: props.transcriptId,
      });
    },
    onError: (error: AxiosResponse) => {
      if (error.status === 404) {
        history.replace(path.Workspaces.FileNotFound);
      }
    },
    onSuccess: res => {
      setTranscriptLocked(res.isLocked);
    }, refetchOnWindowFocus: false, refetchOnReconnect: false,
  });

  const detailsCtx = {
    features: query.data?.features,
    object: query.data?.object,
    metadata: query.data?.source?.metadata,
    settings: query.data?.settings,
  };

  const mentionsCtx: SuggestedMentionsContextValue = useMemo(() => {
    return ['workspace.transcript.mentions', (query: string) => Promise.resolve([])];
  }, []);

  const handleDownload = useDownloadFile({
    workspaceId: query.data?.source?.object?.workspaceId,
    fileId: query.data?.source?.object?.entityId,
  });

  if (query.isFetched && !query.data?.transcript) {
    const e = query.error;
    const data = e?.data as IWorkspaceObjectId;
    const forbidden = query.isError && e.status === 403;
    const hasObjectId = data !== null && typeof data === 'object' && !!data?.objectId;

    return forbidden && hasObjectId
      ? <Redirect to={generatePath(path.Access.Request, data)} />
      : <Redirect to={path.Workspaces.FileNotFound} />;
  }

  return (
    <TranscriptHasMediaContext.Provider value={query.data?.source?.hasMedia}>
      <DownloadTranscriptMediaContext.Provider value={handleDownload}>
        <SuggestedMentionsContext.Provider value={mentionsCtx}>
          <WorkspaceTranscriptDetailsContext.Provider value={detailsCtx}>
            <WorkspaceBreadcrumbsContext.Provider value={query.data?.breadcrumbs}>
              <TranscriptEnhancementStateContext.Provider value={query.data?.enhancement}>
                <RefetchTranscriptContext.Provider value={query.refetch}>
                  <TranscriptIdContext.Provider value={props.transcriptId}>
                    <TranscriptDocumentContext.Provider value={query.data?.transcript}>
                      <TranscriptSpeakersContext.Provider value={query.data?.speakers}>
                        <TranscriptDataContainer>
                          <TranscriptMetadataContainer>
                            <TranscriptTypeContext.Provider value={TranscriptType.WorkspaceFile}>
                              {props.children}
                            </TranscriptTypeContext.Provider>
                          </TranscriptMetadataContainer>
                        </TranscriptDataContainer>
                      </TranscriptSpeakersContext.Provider>
                    </TranscriptDocumentContext.Provider>
                  </TranscriptIdContext.Provider>
                </RefetchTranscriptContext.Provider>
              </TranscriptEnhancementStateContext.Provider>
            </WorkspaceBreadcrumbsContext.Provider>
          </WorkspaceTranscriptDetailsContext.Provider>
        </SuggestedMentionsContext.Provider>
      </DownloadTranscriptMediaContext.Provider>
    </TranscriptHasMediaContext.Provider>
  );
};

const WithState = ({ children, ...props }: Props) => {
  return (
    <TranscriptStateContainer>
      <WorkspaceTranscriptContainer {...props}>
        {children}
      </WorkspaceTranscriptContainer>
    </TranscriptStateContainer>
  );
};

export { WithState as WorkspaceTranscriptContainer };