import { useCallback, useState } from 'react';
import { Slack } from '@/types';
import { useSlackTeams } from '@utils/api';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import Tags from '@/components/UserProfile/Profile.Tags.Form';
import { useSlackChannelsContext } from './Context';
import { SlackChannelsAutocomplete } from './Channels.Autocomplete';
import { SlackTeamsSelect } from './TeamsSelect';
import styles from './style/Channels.Editor.css';

type Props = {
  className?: string;
};

export const SlackChannelsEditor = ({ className }: Props) => {

  const [team, setTeam] = useState<Slack.Team>(null);
  const [channels, setChannels] = useSlackChannelsContext();

  const {
    data: teams,
    isLoading: teamsLoading,
  } = useSlackTeams({
    // enabled: integrations?.slack?.connected,
    onSuccess: teams => {
      if (teams.length >= 1) {
        setTeam(teams[0]);
      }
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const renderTeam = useCallback(() => {
    if (teams.length === 1) {
      const team = teams[0];
      return (
        <div className={styles.team}>
          <div className={styles.name}>Team:</div>
          {team.name}
        </div>
      );
    } else {
      return (
        <div className={styles.team}>
          <div className={styles.name}>Team:</div>
          <SlackTeamsSelect
            items={teams}
            onSelect={t => setTeam(t)}
            selected={team} />
        </div>
      );
    }
  }, [
    team,
    teams,
  ]);

  const addChannel = useCallback((value: Slack.Channel) => {
    setChannels(s => [...s, value]);
  }, [setChannels]);

  const removeChannel = useCallback((channel: Slack.Channel) => () => {
    setChannels(s => s.filter(f => f.id !== channel.id));
  }, [setChannels]);

  if (teamsLoading || !channels) {
    return (
      <ActivityIndicator show />
    );
  }

  return (
    <div className={className}>
      {renderTeam()}
      <div className={styles.autocomplete}>
        <SlackChannelsAutocomplete
          onSelect={addChannel}
          teamId={team?.id} />
      </div>
      <Tags
        items={channels}
        onClick={removeChannel} />
    </div>
  );
};

export default SlackChannelsEditor;