import { useCallback, useContext } from 'react';
import * as api from '@api';
import { AccountProfile } from './interfaces';
import { useAccountProfileEditState } from './hooks';
import { AccountProfileRefetchContext, DeleteProfileEntryContext, SaveUserProfileContext } from './Context';

type Props =
  ChildrenProps;

export const AccountProfileSaveContainer = (props: Props) => {

  const [_, toggleEditing] = useAccountProfileEditState();
  const refetch = useContext(AccountProfileRefetchContext);

  const updateProfile: AccountProfile.SaveProfile = useCallback(data => {
    return api.profiles.updateProfile(data)
    .then(refetch)
    .then(_ => toggleEditing('')());
  }, [
    refetch,
    toggleEditing,
  ]);

  const deleteEntry: AccountProfile.DeleteProfileEntry = useCallback(data => {
    return api.profiles.deleteEntry(data)
    .then(refetch)
    .then(_ => toggleEditing('')());
  }, [
    refetch,
    toggleEditing,
  ]);

  return (
    <SaveUserProfileContext.Provider value={updateProfile}>
      <DeleteProfileEntryContext.Provider value={deleteEntry}>
        {props.children}
      </DeleteProfileEntryContext.Provider>
    </SaveUserProfileContext.Provider>
  );
};

export default AccountProfileSaveContainer;