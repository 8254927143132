import FormControlLabel from '@mui/material/FormControlLabel';
import { forwardRef, useMemo } from 'react';
import { cx } from '@utils';
import { Input } from '@/components/Input';
import { SurveyRichTextEditor, SurveyRichTextEditorContainer, useSurveyRichTextEditor } from '@/components/Survey.RichText';
import RadioButton from './RadioButton';
import { RichTextRadioGroupOptionProps, RichTextRadioGroupOptionItem } from './interfaces';
import styles from './style.css';

const RichTextLabel = ({ label }: Pick<RichTextRadioGroupOptionItem, 'label'>) => {
  const { manager, state, onChange } = useSurveyRichTextEditor({
    initialState: label,
  });

  return (
    <SurveyRichTextEditorContainer
      editable={false}
      manager={manager}
      state={state}
      onChange={onChange}>
      <SurveyRichTextEditor className={styles.editor} />
    </SurveyRichTextEditorContainer>
  );
};

export default function RichTextRadioGroupOption(props: RichTextRadioGroupOptionProps) {
  const showInput = useMemo(() => props.allowText && props.isSelected, [props.allowText, props.isSelected]);

  return (
    <>
      <FormControlLabel
        className={cx(styles.option, props.className)}
        sx={{
          marginLeft: 0,
          alignItems: 'flex-start',
          [`& .MuiFormControlLabel-label`]: {
            fontSize: '16px',
            fontFamily: 'var(--font-reg)',
            width: '100%',
          },
        }}
        disabled={props.disabled}
        control={<RadioButton sx={{ marginRight: '10px' }} />}
        label={showInput ? (
          <InputLabel
            label={props.label}
            onInputChange={props.onInputChange}
            text={props.text}
            value={props.value} />
          )
          : <RichTextLabel label={props.label} />}
        key={props.value}
        value={props.value} />
    </>
  );
}

const InputLabel = forwardRef<HTMLDivElement, LabelProps>(({ label, onInputChange, text, value }, ref) => {
  return (
    <div className={styles.openEnded}>
      <div className={styles.label}>
        <RichTextLabel label={label} />
      </div>
      <Input
        name={`${value}-text`}
        onChange={onInputChange}
        placeholder="Enter additional text"
        value={text || ''} />
    </div>
  );
});

type LabelProps =
  Pick<RichTextRadioGroupOptionItem, 'label' | 'text' | 'value'>
& Pick<RichTextRadioGroupOptionProps, 'onInputChange'>;

export { RichTextRadioGroupOption };