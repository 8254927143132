import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import { saveTaggedMoment } from '@api/transcripts';
import { TranscriptFocusedTagContext } from '@containers/Transcript.Tagging/Context';
import { useTranscriptTagsContext } from '@containers/Transcript.Tagging/hooks';
import Toast from '@/components/Toast';
import { TranscriptChainedCommandsContext } from '@/components/Transcript/context';
import { useMapTimePosToDocPos } from './useMapPositions';
import { useUpdateTagsQueryData } from './useSearchCallTags';

type Params = Parameters<typeof saveTaggedMoment>[0] & {
  highlightIdentifier: string;
};

type Props = ITranscriptId;

export const useSaveTaggedMoment = (props: Props) => {
  const commands = useContext(TranscriptChainedCommandsContext);
  const mapTimePosToDocPos = useMapTimePosToDocPos();
  const [tags, dispatch] = useTranscriptTagsContext();
  const [focusedTag, focusTag] = useContext(TranscriptFocusedTagContext);
  const updateTagQueryData = useUpdateTagsQueryData(props);

  const mutation = useMutation({
    mutationFn: (data: Params) => {
      return saveTaggedMoment(data);
    },
    onSuccess: (data, variables) => {
      //Update highlight, update tag state
      const { from, to } = mapTimePosToDocPos(data.tag);
      if (variables.momentId) {
        commands.updateHighlight({
          id: data.tag.identifier,
          color: data.tag.color,
          from,
          to,
        }).run();
      } else {
        commands.addHighlight({
          id: data.tag.identifier,
          color: data.tag.color,
          from,
          to,
          dbId: null,
          derived: true,
        }).removeHighlights([variables.highlightIdentifier]).run();
        //Clear out the old placeholder tag
        dispatch({
          type: 'tag-removed',
          highlightIdentifier: variables.highlightIdentifier,
        });

        //If the old identifier was focused, update the focused identifier
        if (focusedTag === variables.highlightIdentifier) {
          focusTag(data.tag.identifier);
        }
      }

      if (!variables.tag.id) {
        updateTagQueryData(data.tag);
      }

      dispatch({
        type: 'tag-updated',
        tag: data.tag,
      });
    },
    onError: () => {
      Toast.error({ title: 'Failed to save tagged moment' });
    },
  });

  return mutation;
};