import { useMemo } from 'react';
import { ConjointAnalysisQuestion } from '@/types';
import { conjointAnalysis } from '../utils';

export const useConjointAnalysisColors = (attributes: ConjointAnalysisQuestion.Attribute[]) => {

  return useMemo(() => {

    return attributes.reduce((acc, attr, attributeIndex) => {

      return attr.levels.reduce((acc2, level, levelIndex) => ({
        ...acc2,
        [level.base.id]: conjointAnalysis.getLevelColor(attributeIndex, levelIndex, attr.levels.length),
      }), acc);
    }, {});

  }, [
    attributes,
  ]);
};

export default useConjointAnalysisColors;