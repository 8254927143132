import type { SurveyBuilderEditing, SurveyBuilder } from '../interfaces';

export function editingReducer(state: SurveyBuilder.State, action: SurveyBuilder.Actions): SurveyBuilderEditing.State {

  switch (action.type) {

    case 'toggle-editing':
      return {
        itemIdentifier: action.itemIdentifier,
        logic: {
          identifier: null,
          isNew: false,
        },
        quota: {
          identifier: null,
          isNew: false,
        },
        tagging: {
          identifier: null,
          isNew: false,
        },
      };

    case 'revert-uncommitted-changes':
    case 'toggle-quota-editing-off':
    case 'logic-editing-toggled-off':
    case 'tagging-editing-toggled-off':
    case 'save-quota-item':
    case 'logic-item-saved':
    case 'tagging-item-saved':
      return {
        itemIdentifier: null,
        logic: {
          identifier: null,
          isNew: false,
        },
        quota: {
          identifier: null,
          isNew: false,
        },
        tagging: {
          identifier: null,
          isNew: false,
        },
      };

    case 'question-added':
    case 'message-added':
    case 'aie-added':
      return {
        itemIdentifier: action.item.identifier,
        logic: {
          identifier: null,
          isNew: false,
        },
        quota: {
          identifier: null,
          isNew: false,
        },
        tagging: {
          identifier: null,
          isNew: false,
        },
      };

    case 'add-quota-item':
      return {
        itemIdentifier: null,
        logic: {
          identifier: null,
          isNew: false,
        },
        quota: {
          identifier: action.identifier,
          isNew: true,
        },
        tagging: {
          identifier: null,
          isNew: false,
        },
      };

    case 'toggle-quota-editing-on':
      return {
        itemIdentifier: null,
        logic: {
          identifier: null,
          isNew: false,
        },
        quota: {
          identifier: action.identifier,
          isNew: false,
        },
        tagging: {
          identifier: null,
          isNew: false,
        },
      };

    case 'logic-unsaved-item-added':
      return {
        itemIdentifier: null,
        logic: {
          identifier: action.payload.identifier,
          isNew: true,
        },
        quota: {
          identifier: null,
          isNew: false,
        },
        tagging: {
          identifier: null,
          isNew: false,
        },
      };

    case 'logic-editing-toggled-on':
      return {
        itemIdentifier: null,
        logic: {
          identifier: action.payload.identifier,
          isNew: false,
        },
        quota: {
          identifier: null,
          isNew: false,
        },
        tagging: {
          identifier: null,
          isNew: false,
        },
      };

    case 'tagging-item-added':
      return {
        itemIdentifier: null,
        logic: {
          identifier: null,
          isNew: false,
        },
        quota: {
          identifier: null,
          isNew: false,
        },
        tagging: {
          identifier: action.payload.identifier,
          isNew: true,
        },
      };

    case 'tagging-editing-toggled-on':
      return {
        itemIdentifier: null,
        logic: {
          identifier: null,
          isNew: false,
        },
        quota: {
          identifier: null,
          isNew: false,
        },
        tagging: {
          identifier: action.payload.identifier,
          isNew: false,
        },
      };

    default:
      return state.editing;

  }
}