import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { GroupSidebarTags } from '@/components/GroupSidebarTags';
import { useProjectOrLatestChild, useProjectState, useProjectQueryContext } from '@/containers/GroupProject/hooks';
import { useGroupEntityTags, useGroupEntityTagsUpdate } from '@/containers/GroupTags/hooks';
import { useSelectUser } from '@/containers/Store';
import * as enums from '@/enums';
import * as Tab from '@/presentation/Workspace.Tab.Pane';
import { useHasGroupFeature } from '@/store/hooks';
import { cx, useConditionalRender, hasInternalAdminRole } from '@/utils';
import ProjectFilters from './ProjectFilters';
import { ProjectOverview } from './ProjectOverview';
import { TargetCompany } from './TargetCompany';
import styles from './style.css';

const mapState = (state: Store.State) => state.group.features;

type Props = unknown;

const ProjectTabDetails = (_: Props) => {
  const user = useSelectUser();
  const project = useProjectOrLatestChild();
  const { capabilities } = useProjectState();
  const groupFeatures = useSelector(mapState);
  const query = useProjectQueryContext();

  const $TargetCompany = useConditionalRender(groupFeatures.projectTargetCompany);
  const isAdmin = useMemo(() => hasInternalAdminRole(user), [user]);
  const $ProjectFilters = useConditionalRender(isAdmin);

  const canEdit = useMemo(() => {
    return capabilities.canManage &&
      project.projectStatusId === enums.ProjectStatus.Active;
  }, [capabilities, project.projectStatusId]);

  return (
    <Tab.Panes.Container>
      <Tab.Pane.Pane>
        <Tab.Pane.Title>Details</Tab.Pane.Title>
        <Tab.Pane.Grid gridTemplateColumns='repeat(2, 0.5fr)'>
          <ProjectOverview editable={canEdit} item={project} />
          <Tab.Items.Container>
            <Tab.Item.Container>
              <Tab.Item.TextLabel>Methodology</Tab.Item.TextLabel>
              <Tab.Item.Value>
                {enums.utils.ProjectType.getName(query.data.project.projectType)}
              </Tab.Item.Value>
            </Tab.Item.Container>
            <Tab.Item.Container>
              <Tab.Item.TextLabel>Start Date</Tab.Item.TextLabel>
              <Tab.Item.Value>
                {query.data.project.startDate && <div className={styles.field}>{format(query.data.project.startDate, 'MM/dd/yy')}</div>}
                {!query.data.project.startDate && <div className={cx(styles.field, styles.na)}>N/A</div>}
              </Tab.Item.Value>
            </Tab.Item.Container>
            <Tab.Item.Container>
              <Tab.Item.TextLabel>Target Complete Date</Tab.Item.TextLabel>
              <Tab.Item.Value>
                {query.data.project.targetCompleteDate && <div className={styles.field}>{format(query.data.project.targetCompleteDate, 'MM/dd/yy')}</div>}
                {!query.data.project.targetCompleteDate && <div className={cx(styles.field, styles.na)}>N/A</div>}
              </Tab.Item.Value>
            </Tab.Item.Container>
            <$TargetCompany>
              <Tab.Item.Container>
                <TargetCompany editable={canEdit} item={project} />
              </Tab.Item.Container>
            </$TargetCompany>
          </Tab.Items.Container>
        </Tab.Pane.Grid>
      </Tab.Pane.Pane>
      <TagPane />
      <$ProjectFilters>
        <Tab.Pane.Pane>
          <ProjectFilters editable={false} item={project} />
        </Tab.Pane.Pane>
      </$ProjectFilters>
    </Tab.Panes.Container>
  );
};

function TagPane() {
  const $Tags = useConditionalRender(useHasGroupFeature('userTagging'));

  const tags = useGroupEntityTags();
  const updateTags = useGroupEntityTagsUpdate();

  return (
    <$Tags>
      <Tab.Pane.Pane>
        <GroupSidebarTags
          tags={tags}
          onSave={updateTags} />
      </Tab.Pane.Pane>
    </$Tags>
  );
}

export default ProjectTabDetails;
