import { History } from 'history';

export const logLocationChange: History.LocationListener = (location, action) => {
  if (!process.env.__DEV__) return;

  const pic = { POP: '○', PUSH: '●', REPLACE: '◎', INIT: 'INIT'}[action];
  const event = action.concat(' '.repeat(7 - action.length));
  console.info(
    `%c${' '.repeat(8)}${event} %c${pic} %c${location.pathname}`,
    'color: #979797; font-weight: bold;',
    'color: #5fafff;',
    'color: #979797;'
  );
};

export type LocationListener = History.LocationListener;