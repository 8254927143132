import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { Node } from 'slate';
import * as QK from '@consts/querykey';
import { ArticleFeedPublisherContext } from '@containers/Post.Article.Form/Context';
import * as Search from '@containers/PostCreation/Search';
import { StateContainer } from '@containers/PostCreation/Post.Text.StateContainer';
import { TopicTagsContainer } from '@containers/PostCreation/Topic.Tags.Container';
import { useTextPostForm, useTopicTagsContext } from '@containers/PostCreation/hooks';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { Button } from '@/components/Button';
import * as Editor from '@/components/PostCreation/Editor';
import * as Publisher from '@/components/PostCreation/Publisher';
import { TopicTags } from '@/components/PostCreation/Topics.Tags';
import { URLPreview, Placeholder } from '@/components/URLPreview';
import { ImageResource, crop } from '@/components/URLPreview/crop';
import styles from './style/FeedPublisher.css';

type Props =
  Pick<Publisher.ModalProps,
  | 'onClose'
  | 'open'>;

export const FeedPublisher = (props: Props) => {
  return (
    <StateContainer>
      <Search.Container>
        <TopicTagsContainer>
          <FormContainer {...props} />
        </TopicTagsContainer>
      </Search.Container>
    </StateContainer>
  );
};

FeedPublisher.displayName = 'FeedPublisher';

const FormContainer = (props: Props) => {
  const history = useHistory();
  const feed = useContext(ArticleFeedPublisherContext);
  const [form, dispatch] = useTextPostForm();
  const [topic, dispatchTopicsAction] = useTopicTagsContext();
  const [preview, setPreview] = useState<URLPreviewObject>(null);

  const initPreview = useCallback(async () => {
    const image = feed.previewImage
        ? await crop(feed.previewImage)
        : null;

    setPreview({
      description: feed.state.description,
      domain: getDomain(),
      image,
      title: feed.state.title,
      sitename: `Sentiment`,
      url: `/posts/articles/${feed.state.postId}`,
    });
  }, [
    feed.previewImage,
    feed.state,
  ]);

  const initialize = useCallback(() => {
    initPreview();
    dispatchTopicsAction({
      items: feed.state.topics,
      type: 'add-topics',
    });
  }, [
    dispatchTopicsAction,
    feed.state,
    initPreview,
  ]);

  useEffect(() => {

    if (props.open) {
      initialize();
    }

  }, [
    initialize,
    props.open,
  ]);

  const handleChange = useCallback((value: Node[]) => {
    dispatch({
      type: 'update-body',
      value: { value },
    });
  }, [dispatch]);

  const handleSubmit = useCallback(() => {
    return feed.publishArticleToFeed({
      body: form.body,
      preview,
      topics: topic.items,
    });
  }, [
    feed,
    form,
    preview,
    topic.items,
  ]);

  const navigateToPublishedArticle = useCallback(() => {
    history.push(`/posts/articles/${feed.state.postId}`);
  }, [
    feed.state,
    history,
  ]);

  const mutation = useMutation([
    QK.Posts.Post,
  ],
  handleSubmit, {
    onSuccess: () => {
      navigateToPublishedArticle();
    },
  });

  if (!props.open) return null;

  return (
    <Publisher.Modal
      disableEscapeClose={mutation.isLoading}
      disableOverlayClick={mutation.isLoading}
      hideCloseIcon={mutation.isLoading}
      onClose={navigateToPublishedArticle}
      open={props.open}>
      <Publisher.Header>
        Share your article with the community!
      </Publisher.Header>

      <div className={styles.root}>
        <div className={styles.scroll}>
          <Publisher.Author className={styles.author} />
          <Editor.TextField
            onChange={handleChange}
            placeholder="Share a thought"
            value={form.body.value} />

          {preview?.url &&
            <div className={styles.preview}>
              <div className={styles.content}>
                <URLPreview
                  item={preview}
                  url={preview.url} />
              </div>
            </div>}

        </div>
      </div>

      <TopicTags className={styles.topics} />

      <Publisher.Footer>
        <div className={styles.btns}>
          <Button
            className={styles.dismiss}
            disabled={mutation.isLoading}
            color="transparent"
            onClick={navigateToPublishedArticle}
            variant="brick">
            {copy.dismiss}
          </Button>
          <ButtonActivityIndicator
            className={styles.btn}
            loading={mutation.isLoading}
            onClick={() => mutation.mutate()}
            variant="brick">
            {copy.submit}
          </ButtonActivityIndicator>
        </div>
      </Publisher.Footer>
    </Publisher.Modal>
  );
};

FormContainer.displayName = 'Post.Article.FeedPublisher.FormContainer';

const getDomain = () => {
  switch (process.env.VANCERY_ENV) {

    case 'dev':
    case 'test':
      return 'test.vancery.com';

    case 'stage':
      return 'beta.sentimentglobal.com';

    default:
      return 'app.sentimentglobal.com';
  }
};

type URLPreviewObject = {
  description: string;
  domain:      string;
  image:       ImageResource;
  sitename:    string;
  title:       string;
  url:         string;
};

const copy = {
  dismiss: `I don't want to share`,
  submit: `Share`,
};