import { useCallback } from 'react';
import * as api from '@api';
import * as API from '@api/interfaces';
import { useContentFeedState } from '@containers/ContentFeed/hooks/useContentFeedState';
import Toast from '@/components/Toast';

export const useDeletePost = () => {
  const [_, dispatch] = useContentFeedState();

  const deletePost = useCallback(({ postId }: API.Posts.DeletePost.Request) => {
    return api.posts.deletePost({
      postId,
    }).then(_ => {
      dispatch({
        postId,
        type: 'post-deleted',
      });
      Toast.alert({
        title: 'Post Deleted',
      });
    }).catch(e => {
      Toast.error({
        title: 'Error',
        body: `We're sorry, there was an issue with your request`,
      });
      throw e;
    });
  }, [dispatch]);

  return deletePost;
};

export default useDeletePost;