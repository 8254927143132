import { path } from '@consts';
import { LinkLabeledIcon } from '$website/components';
import img from 'static/images/website/about/office.png';
import styles from './style/BannerCareers.css';

type Props = unknown;

export const BannerCareers = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.graphic}>
            <img
              alt="Accelerate your career"
              className={styles.img}
              src={img} />
          </div>
          <div className={styles.info}>
            <h2 className={styles.title}>Accelerate your career at Sentiment</h2>

            <LinkLabeledIcon
              classes={{ link: styles.link }}
              to={path.Website.Careers}>
              View Opportunities
            </LinkLabeledIcon>
          </div>
        </div>
      </div>
    </div>
  );
};

BannerCareers.displayName = 'About.BannerCareers';