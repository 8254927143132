import { Fragment, memo } from 'react';
import { Results } from '../interfaces/members';

export const Cell = memo(({ row }: Results.TableCellProps) => {
  return (
    <Fragment>
      {row.original.specialty.length ? row.original.specialty.join(', ') : '-'}
    </Fragment>
  );
});

export const Filter = memo(() => null);

export const Header = memo(() => {
  return (
    <Fragment>
      Specialty
    </Fragment>
  );
});

Cell.displayName = 'Column.Specialty.Cell';
Filter.displayName = 'Column.Specialty.Filter';
Header.displayName = 'Column.Specialty.Header';