import { useMemo } from 'react';
import type { LocalVideoTrack } from 'twilio-video';
import type { IConference } from '@containers/Conference';
import { useConferenceInstance, useTwilioVideo, useLiveRoomBar } from '@containers/Conference';
import { Views, Right } from '@screens/ConferenceBar';
import { cx } from '@utils';
import { BarRightChat } from '@screens/Conference.Common';
import { isCameraTrack } from '@/components/Conference.Video';
import { usePublications, useTrack } from '@/components/Conference.Video/hooks';
import { Logs } from './Bar.Right.Logs';
import { BarRightParticipants } from './Bar.Right.Participants';
import { BarRightSupport } from './Bar.Right.Support';
import { Surveys } from './Bar.Right.Surveys';
import styles from './style/Bar.Right.css';

export function BarRight() {
  const [bar] = useLiveRoomBar();

  const rootClassname = useMemo(() => cx(
    styles.right,
    bar.active ? styles.open : null,
  ), [bar.active]);

  const rootStyle = useMemo(() => (bar.active ? { width: '350px' } : {}), [bar.active]);

  return (
    <Right
      className={rootClassname}
      style={rootStyle}>
      {bar.active === 'information' && <Information />}
      {bar.active === 'participants' && <Participants />}
      {bar.active === 'chat' && <Chat />}
      {bar.active === 'support' && <Support />}
      {bar.active === 'surveys' && <Surveys />}
      {bar.active === 'logs' && <Logs />}
    </Right>
  );
}

function Information() {
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom>();
  const twilio = useTwilioVideo();

  const localParticipant = twilio.room.localParticipant;
  const publications = usePublications(localParticipant);
  const cameraPublication = publications.find(p => isCameraTrack(p.trackName));
  const cameraTrack = useTrack(cameraPublication) as LocalVideoTrack;

  const camera = useMemo(() => cameraTrack
    ? ({
      resolution: cameraTrack.dimensions?.height,
      visible: true,
    })
    : null, [cameraTrack]);

  const network = useMemo(() => localParticipant?.networkQualityLevel != null
    ? ({
      qualityLevel: localParticipant.networkQualityLevel,
      visible: true,
    })
    : null, [localParticipant?.networkQualityLevel]);

  return (
    <div className={styles.info}>
      <Views.Information
        conferenceIdentifier={instance.conferenceIdentifier}
        camera={camera}
        dial={instance.dial}
        network={network} />
    </div>
  );
}

function Participants() {
  return (
    <div className={styles.participants}>
      <BarRightParticipants />
    </div>
  );
}

function Chat() {
  return (
    <div className={styles.chat}>
      <BarRightChat />
    </div>
  );
}

function Support() {
  return <BarRightSupport />;
}