import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { NotificationsSocketEvent, NotificationType } from '@enums';
import * as ws from '@services/websocket';

const selectNotifications = (state: Store.State) => state.notifications;

type Params = number;

export const useDismissProjectRequestNotification = (projectId: Params) => {
  const notifications = useSelector(selectNotifications);

  const dismiss = useCallback(() => {

    const notification = notifications.items.find(x => {
      return x.typeId === NotificationType.ProjectRequest
          && x.payload?.projectId === projectId;
    });

    ws.notifications.emit(NotificationsSocketEvent.Dismiss, {
      notificationIds: [notification?.id],
    });

  }, [
    projectId,
    notifications.items,
  ]);

  return dismiss;
};