import { useCallback, useContext, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as QK from '@consts/querykey';
import { ButtonOutlined } from '@presentation';
import { isValidEmail } from '@utils';
import * as api from '$admin/api';
import { Button } from '@/components/Button';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import { Header } from '@/components/Modal/Header';
import { ParamsContext, RespondentSetupContext } from './Context';
import { Autosuggest, Checkbox, PayoutHonorarium } from './PickRespondent';
import { useExitCallScheduling } from './hooks';
import { RespondentItem } from './interfaces';
import styles from './style/Modal.PickRespondent.css';

type Props = Pick<ModalProps, 'open'>;

export const PickRespondent = (props: Props) => {
  const ctx = {
    respondent: useContext(RespondentSetupContext),
    scheduling: useContext(ParamsContext),
  };

  const [keyword, setKeyword] = useState('');
  const [selected, setSelected] = useState<RespondentItem>(null);

  const query = useQuery([
    QK.Admin.Search.Users.XPlat.Get,
    keyword,
    true,
  ], () => {
    return api.search.users.xplat({
      excludeInternal: true,
      keyword,
    });
  }, {
    enabled: !!keyword.trim(),
    refetchOnWindowFocus: false,
  });

  const handleClose = useExitCallScheduling();

  const handleCreate = useCallback((value: string) => {
    ctx.respondent.setValue({ email: value });
    const custom = {
      email: value,
      name: value,
      offPlatform: true,
    };
    setSelected(custom);
  }, [ctx.respondent]);

  const handleSelect = useCallback((item: RespondentItem) => {
    if (!item) {
      ctx.respondent.setValue({
        email: null,
        userId: null,
      });
      setSelected(null);
    } else {
      const params = item.offPlatform
          ? { email: item.email }
          : { userId: item.id };

      ctx.respondent.setValue(params);
      setSelected(item);
    }
  }, [ctx.respondent]);

  const handleNext = useCallback(() => {
    ctx.scheduling.setContext({
      projectId: ctx.respondent.form.projectId,
      userId: ctx.respondent.form.userId || null,
    });
  }, [
    ctx.respondent,
    ctx.scheduling,
  ]);

  const disabled = useMemo(() => {
    if (ctx.respondent.form.external) return false;

    const hasRespondent = ctx.respondent.form.email || ctx.respondent.form.userId;

    return !hasRespondent;

  }, [ctx.respondent.form]);

  if (ctx.scheduling.params?.projectId) return null;

  return (
    <Modal
      classes={{
        root: styles.wrap,
        close: styles.close,
      }}
      onClose={handleClose}
      open={props.open}>
      <Header className={styles.header}>{copy.title}</Header>
      <div className={styles.main}>
        <div className={styles.subtitle}>{copy.subtitle}</div>

        <div className={styles.row}>
          <Checkbox
            checked={ctx.respondent.form.external}
            disabled={!!ctx.respondent.form.userId || !!ctx.respondent.form.email}
            className={styles.input}
            label={copy.checkbox.external}
            onChange={checked => ctx.respondent.setValue({ external: checked })} />
        </div>

        <div className={styles.row}>
          <div className={styles.label}>Add Respondent</div>
          <div className={styles.input}>
            <Autosuggest
              inputValue={keyword}
              isValidNewOption={isValidEmail}
              onChange={setKeyword}
              onCreateOption={handleCreate}
              onSelect={(value, action) => handleSelect(value)}
              options={query.data?.items || []}
              placeholder="Name or email"
              value={selected} />
          </div>
        </div>

        <div className={styles.row}>
          <Checkbox
            checked={ctx.respondent.form.payout}
            disabled={!ctx.respondent.form.userId}
            label={copy.checkbox.payout}
            onChange={checked => ctx.respondent.setValue({ payout: checked })} />
        </div>
        <div className={styles.row}>
          <PayoutHonorarium
            disabled={!ctx.respondent.form.payout || !ctx.respondent.form.userId}
            currency={ctx.respondent.form.honorarium.currency}
            onChangeCurrency={currency => ctx.respondent.setValue({ honorarium: { ...ctx.respondent.form.honorarium, currency } })}
            onChangeValue={value => ctx.respondent.setValue({ honorarium: { ...ctx.respondent.form.honorarium, value } })}
            value={ctx.respondent.form.honorarium.value} />
        </div>
      </div>
      <ButtonSet className={styles.footer}>
        <ButtonOutlined
          className={styles.btn}
          color="silver"
          onClick={handleClose}>
          Back
        </ButtonOutlined>
        <Button
          color="primary"
          className={styles.btn}
          disabled={disabled}
          onClick={handleNext}
          variant="brick">
          Next
        </Button>
      </ButtonSet>
    </Modal>
  );
};

PickRespondent.displayName = 'Modal.PickRespondent';

const copy = {
  checkbox: {
    external: `Externally Sourced Respondent`,
    payout: `Create Honoraria Payout Records`,
  },
  subtitle: `Select which Respondent you’d like to schedule the call with.`,
  title: `Pick Respondent`,
};