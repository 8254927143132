import { useContext } from 'react';
import { createNamedContext } from '@/utils';
import type { PipedSurveyValue } from '@/types';

export type PipingMenuContextValue = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onPipingValueSelect: (value: PipedSurveyValue) => void;
};

export const PipingMenuContext = createNamedContext<PipingMenuContextValue>(undefined, 'PipingMenuContext');

export const usePipingMenuState = () => useContext(PipingMenuContext);