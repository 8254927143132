import type { ErrorInfo } from 'react';
import { memo, Component } from 'react';
import { Tooltip } from '@/presentation/Tooltip';
import type { SurveyLogic } from '@/types/survey';
import { utils as $enums } from '@enums';

type Props = {
  action: SurveyLogic.Action;
} & ChildrenProps;
type State = {
  error: Error;
};

export class ActionErrorBoundary extends Component<Props, State>{
  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.log('Caught action Error');
    console.error(error, info);
  }

  render() {
    if (this.state.error) {
      return (
        <ActionFallback
          action={this.props.action}
          error={this.state.error} />
      );
    }

    return this.props.children;
  }
}

type FProps = {
  error: Error;
} & Omit<Props, 'children'>;

const ActionFallback = memo((props: FProps) => {
  const conditionText = `[Invalid ${$enums.SurveyActionType.getName(props.action.type)} action]`;

  const title = [props.error.message, props.error.stack].join('\n\n');

  return (
    <Tooltip title={title}>
      <span style={{ color: 'red', fontFamily: 'var(--font-bold)' }}>{conditionText}</span>
    </Tooltip>
  );
});