import { useCallback, useState } from 'react';
import { AccountProfileEditStateContext } from './Context';

type Props = {
  children: React.ReactNode;
};

const AccountProfileEditStateContainer = (props: Props) => {

  const [id, dispatch] = useState('');

  const onEditStateChange = (entryId: string) => {
    return () => {
      const value = entryId === id
          ? ''
          : entryId;

      dispatch(value);
    };

  };

  const handleEditStateChange = useCallback(onEditStateChange, [
    dispatch,
    id,
  ]);

  return (
    <AccountProfileEditStateContext.Provider value={[id, handleEditStateChange]}>
      {props.children}
    </AccountProfileEditStateContext.Provider>
  );
};

export { AccountProfileEditStateContainer };
export default AccountProfileEditStateContainer;