import { useCallback } from 'react';
import cuid from 'cuid';
import { useSurveyTaggingState } from '@containers/SurveyBuilder/Context';
import { AddTagButton as Button } from '@/presentation/SurveyBuilder';

type Props = {
  className?: string;
};

export const AddTagButton = ({ className }: Props) => {

  const [_, dispatch] = useSurveyTaggingState();

  const handleClick = useCallback(() => {
    dispatch({
      type: 'tagging-item-added',
      payload: {
        identifier: cuid(),
      },
    });
  }, [dispatch]);

  return (
    <Button
      className={className}
      onClick={handleClick} />
  );
};