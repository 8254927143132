import { memo } from 'react';
import { Mail, MapPin, Phone } from 'react-feather';
import * as consts from '@consts';
import { cx } from '@utils';
import styles from './style.css';

export const ContactInfo = memo(() => {
  return (
    <div className={cx(styles.col, styles.information)}>
      <div className={styles.card}>
        <h2 className={styles.headline}>Contact Information</h2>
        <div className={styles.info}>
          <MapPin className={styles.icon} />
          <div>
            <div>{consts.vancery.addressLine1},</div>
            <div>{consts.vancery.addressLine2}</div>
          </div>
        </div>
        <div className={styles.info}>
          <Mail className={styles.icon} />
          <div>{consts.vancery.email}</div>
        </div>
        <div className={styles.info}>
          <Phone className={styles.icon} />
          <div>{consts.vancery.phoneNumberDisplay}</div>
        </div>
      </div>
    </div>
  );
});

export default ContactInfo;