import { useCallback } from 'react';
import { cx } from '@utils';
import { Button } from '@/components/Button';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { Header } from '@/components/Modal/Header';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import styles from './style/Conference.Leave.css';

type Props = {
  onConfirm?: (end?: boolean) => unknown;
  isHost: boolean;
  canEnd: boolean;
} & Pick<ModalProps, 'onClose' | 'open'>;

export function ConferenceLeave({ onClose, onConfirm, open, isHost, canEnd }: Props) {
  const handleLeave = useCallback((end: boolean) => () => {
    onConfirm(end);
  }, [onConfirm]);

  return (
    <Modal
      hideCloseIcon
      className={cx(styles.root)}
      onClose={onClose}
      open={open}>
      <Header>
        {`Are you sure you want to leave conference?`}
      </Header>
      <ButtonSet className={styles.buttonset}>
        <Button
          className={styles.cancel}
          color="secondary"
          onClick={onClose}
          variant="brick">
          Cancel
        </Button>
        <Button
          className={styles.btn}
          color="secondary"
          onClick={handleLeave(false)}
          variant="brick">
          Leave Conference
        </Button>
        {isHost && (
          <Button
            className={styles.end}
            onClick={handleLeave(true)}
            disabled={!canEnd}
            variant="brick">
            End Conference
          </Button>
        )}
      </ButtonSet>
    </Modal>
  );
}

ConferenceLeave.displayName = 'Modal.Conference.Leave';