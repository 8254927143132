import { useContext, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as QK from '@consts/querykey.admin';
import * as api from '$admin/api';
import * as $conference from '$admin/containers/Call/utils';
import { ParamsContext, ParticipantsContext, SettingsContext } from './Context';
import type { Settings as CallSettings } from './interfaces';

type Props = {
  children: React.ReactNode;
};

export const Settings = (props: Props) => {
  const ctx = {
    participants: useContext(ParticipantsContext),
    scheduling: useContext(ParamsContext),
  };

  const [settings, dispatch] = useState<CallSettings.Form>({
    call: null,
    conference: null,
    time: null,
    tags: null,
  });

  const enabled = useMemo(() => {
    const required = !!ctx.scheduling.params?.projectId;

    const fromCall = required && !!ctx.scheduling.params?.callId;
    const fromPrerequisites = required && ctx.participants.items.some(x => x.scheduler);

    return fromCall
      || fromPrerequisites;
  }, [
    ctx.participants.items,
    ctx.scheduling.params?.callId,
    ctx.scheduling.params?.projectId,
  ]);

  const query = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: QK.Projects.Scheduling.Settings.Get({
      callId: ctx.scheduling.params?.callId,
      projectId: ctx.scheduling.params?.projectId,
    }), queryFn: () => {
      return api.projects.scheduling.getCallSettings({
        callId: ctx.scheduling.params.callId,
        projectId: ctx.scheduling.params.projectId,
      });
    },
    enabled,
    onSuccess: res => {
      dispatch(data => ({
        call: res.call,
        conference: data.conference ?? $conference.resolveDefaultSettings(res.conference, (res.call?.title ?? res.project.clientName?.trim()) || res.project.name),
        time: data.time ?? null,
        tags: data.tags,
      }));
    }, refetchOnWindowFocus: false,
  });

  const value = {
    conference: settings.conference,
    isLoading: query.isInitialLoading || !settings.conference,
    setValue: dispatch,
    time: settings.time,
    tags: settings.tags,
  };

  return (
    <SettingsContext.Provider value={value}>
      {props.children}
    </SettingsContext.Provider>
  );
};

Settings.displayName = 'Call.Scheduling.Settings';