import type { ChatState } from './interfaces.chat';
import { chatHistoryReducer } from './state.chat-history';
import { chatSessionReducer } from './state.chat-session';
import { chatSessionManagerReducer } from './state.chat-session-manager';
import { generateEmptyChatState } from './state.initial-state';

function baseChatReducer(state: ChatState.State, action: ChatState.Action): ChatState.State {

  const newState = computeNewState(state, action);

  logAction(state, newState, action);

  return newState;
}

function batchActions(state: ChatState.State, { payload }: ChatState.BatchAction): ChatState.State {

  let updatedState = state;
  for (const action of payload.actions) {
    updatedState = baseChatReducer(updatedState, action);
  }

  return updatedState;
}

export function chatReducer(state: ChatState.State, action: ChatState.Action): ChatState.State {
  switch (action.type) {

    case 'batch-actions':
      return batchActions(state, action);

    default:
      return baseChatReducer(state, action);
  }
}

function computeNewState(state: ChatState.State, action: ChatState.Action): ChatState.State {
  switch (action.type) {

    case 'reset-chat-state':
      return generateEmptyChatState({
        context: action.payload.context,
      });

    default:
      return {
        history: chatHistoryReducer(state.history, action),
        session: chatSessionReducer(state.session, action),
        sessionManager: chatSessionManagerReducer(state.sessionManager, action),
      };
  }
}

function logAction(oldState: ChatState.State, newState: ChatState.State, action: ChatState.Action) {
  if (process.env.__DEV__) {
    console.groupCollapsed('BrandInsights Chat Action', action);
    console.log('Old State', oldState);
    console.log('New State', newState);
    console.groupEnd();
  }
}