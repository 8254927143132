import { useMemo } from 'react';
import { useSurveyBuilderItem, useSurveyBuilderState } from '@containers/SurveyBuilder';
import {
  SurveyMessageBuilderItemContext,
} from './context';
import { MessageBuilderDeleteContainer } from './DeleteContainer';

type Props =
  ChildrenProps;

export const SurveyBuilderMessageContainer = (props: Props) => {

  const [state] = useSurveyBuilderState();
  const item = useSurveyBuilderItem();

  const message = useMemo(() => {
    return state.survey.messages.find(f => f.identifier === item.source.identifier);
  }, [
    item.source.identifier,
    state.survey.messages,
  ]);

  return (
    <SurveyMessageBuilderItemContext.Provider value={message}>
      <MessageBuilderDeleteContainer>
        {props.children}
      </MessageBuilderDeleteContainer>
    </SurveyMessageBuilderItemContext.Provider>
  );
};