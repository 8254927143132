import { useMemo } from 'react';
import { useFileMenuItems } from './useFileMenuItems';

export const useShowFileMenu = () => {
  const items = useFileMenuItems();

  const showMenu = useMemo(() => {
    return !!Object.values(items).filter(Boolean).length;
  }, [items]);

  return showMenu;
};

export default useShowFileMenu;