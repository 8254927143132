import { useCallback, useRef, useState } from 'react';
import cuid from 'cuid';
import * as Search from '@containers/PostCreation/Search.Topics.Container';
import { TopicTagsContainer } from '@containers/PostCreation/Topic.Tags.Container';
import { ArticleCoverImageContext, ArticleFormContext, ArticleIdentifierContext } from './Context';
import { PostArticleFeedPublisherContainer } from './Post.Article.FeedPublisher.Container';
import { CoverImageObject, OnSubmitImageContent } from './interfaces';

type Props = {
  children: React.ReactNode;
};

export const PostArticleFormContainer = (props: Props) => {
  const [coverimage, setCoverImage] = useState<CoverImageObject>(null);
  const [title, setTitle] = useState(initial.title);
  const identifier = useRef(cuid()).current;

  const handleImageObjects: OnSubmitImageContent = useCallback(async ({ helpers }, handler) => {
    const images = helpers.findImageObjects();

    const sources = await handler(images);

    const lookup = helpers.createImageURLLookup(images, sources);

    return helpers.replaceImageSrc(lookup);
  }, []);

  const value = {
    onSubmitImageContent: handleImageObjects,
    setTitle,
    title,
  };

  return (
    <ArticleIdentifierContext.Provider value={identifier}>
      <ArticleCoverImageContext.Provider value={[coverimage, setCoverImage]}>
        <ArticleFormContext.Provider value={value}>
          <Search.TopicsContainer>
            <TopicTagsContainer>
              <PostArticleFeedPublisherContainer>
                {props.children}
              </PostArticleFeedPublisherContainer>
            </TopicTagsContainer>
          </Search.TopicsContainer>
        </ArticleFormContext.Provider>
      </ArticleCoverImageContext.Provider>
    </ArticleIdentifierContext.Provider>
  );
};

PostArticleFormContainer.displayName = 'Post.Article.FormContainer';

const initial = {
  title: ``,
};