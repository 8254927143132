import { Card, CardContent, GroupCardHeader, Divider } from '@presentation/Main';
import { Agenda } from '@screens/Main.Member/Agenda';
import { cx } from '@utils';
import styles from './style/AgendaCard.css';

export const AgendaCard = () => {
  return (
    <Card className={cx(styles.desktop, styles.root)}>
      <GroupCardHeader>Upcoming Calls</GroupCardHeader>
      <Divider />
      <CardContent fluid className={styles.content}>
        <Agenda />
      </CardContent>
    </Card>
  );
};

export default AgendaCard;