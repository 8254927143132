import { forwardRef, useCallback, useContext } from 'react';
import { Edit2 } from 'react-feather';
import BlockIcon from '@mui/icons-material/Block';
import { useCommands as useCommandsRM, useChainedCommands as useChainedRmCommands, useRemirrorContext } from '@remirror/react';
import cuid from 'cuid';
import { MenuItem } from '@mui/material';
import type { FromToProps } from 'remirror';
import { TranscriptConfidenceContext, TranscriptIdContext } from '@containers/Transcript/context';
import { MarkType, type Confidence } from '@/types/transcribe.rich-text';
import { PopperMenu as Menu } from '@/components/Popper';
import {
  useTextReplaceHighlight,
  useChainedCommands,
} from './hooks';
import { UnsavedHighlightColor } from './utils';
import styles from './style/Tooltip.Confidence.css';

type Props = {
  arrow?: {
    className: string;
    ref: React.Ref<HTMLDivElement>;
  };
};

export const ConfidenceTooltip = forwardRef<HTMLDivElement, Props>(({ arrow, ...props }, ref) => {
  const { selectedMark, setMark } = useContext(TranscriptConfidenceContext);
  const rmContext = useRemirrorContext();
  const commands = useChainedCommands();
  const rmCommands = useCommandsRM();
  const chainedRmCommands = useChainedRmCommands();
  const textReplaceHighlight = useTextReplaceHighlight();
  const onTextReplace = useCallback(() => {
    const highlight = {
      id: cuid(),
      color: UnsavedHighlightColor,
      from: selectedMark.from,
      to: selectedMark.to,
      dbId: null,
    };
    commands
      .blurHighlight()
      .addHighlight(highlight).run();

    textReplaceHighlight({ ...highlight, text: selectedMark.text });
    setMark(null);
    //Blur any existing higlight
    //Set a highlight covering the mark, trigger text replace highlight
  }, [textReplaceHighlight, commands, selectedMark, setMark]);

  const onIgnore = useCallback(() => {
    rmCommands.applyMark(MarkType.Confidence, CONFIRMED_MARK_ATTRS, selectedMark);

    setMark(null);
  }, [rmCommands, selectedMark, setMark]);

  const onIgnoreAll = useCallback(() => {
    const doc = rmContext.getState().doc;

    const ignoreAllPos: FromToProps[] = [];
    doc.descendants((n, pos) => {
      if (n.isText && n.text === selectedMark.text) {
        ignoreAllPos.push({ from: pos, to: pos + n.nodeSize });
      }
    });

    for (const pos of ignoreAllPos) {
      chainedRmCommands.applyMark(MarkType.Confidence, CONFIRMED_MARK_ATTRS, pos);
    }
    chainedRmCommands.run();
    setMark(null);
  }, [chainedRmCommands, rmContext, selectedMark?.text, setMark]);

  const onUseSuggestion = useCallback((suggestion: string) => {
    const confMarkAttributes: Confidence.Attributes = {
      c: 1,
      s: [],
    };
    rmCommands.insertText(suggestion, {
      from: selectedMark.from,
      to: selectedMark.to,
      marks: {
        [MarkType.Confidence]: confMarkAttributes,
      },
    });

    setMark(null);
  }, [rmCommands, selectedMark?.from, selectedMark?.to, setMark]);

  if (!selectedMark) return null;

  const suggestions = ((selectedMark.mark.attrs as Confidence.Attributes)?.s ?? []).slice(0, 5);

  return (
    <div
      ref={ref}>
      <Menu>
        {suggestions.length ?
          suggestions.map(s => (
            <MenuItem
              className={styles.menuItem}
              key={s}
              onClick={() => onUseSuggestion(s)}>
              {`"${s}"`}
            </MenuItem>
          ))
          :
          <MenuItem className={styles.noSuggestions} disabled={true}>
            No Suggestions
          </MenuItem>}
        <MenuItem className={styles.menuItem} onClick={onIgnore}>
          <BlockIcon sx={{ height: 14, width: 14 }} /> Ignore
        </MenuItem>
        <MenuItem className={styles.menuItem} onClick={onIgnoreAll}>
          <BlockIcon sx={{ height: 14, width: 14 }} /> Ignore All
        </MenuItem>
        <MenuItem className={styles.menuItem} onClick={onTextReplace}>
          <Edit2 size={14} /> Edit Text
        </MenuItem>
      </Menu>
    </div>
  );
});

const CONFIRMED_MARK_ATTRS: Confidence.Attributes = {
  c: 2,
  s: [],
};

export default ConfidenceTooltip;