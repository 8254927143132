import { useCallback, useContext, useMemo } from 'react';
import { ProjectTagsQueryContext } from '@/containers/Group.Project.Tags/Context';

type Tags = {
  tagId: number;
  tagStart?: number;
  tagEnd?: number;
};

export const useProjectClipsTags = (selectedTags: Tags[], defaultRange?: {
  start: number;
  end: number;
}) => {
  const tagsQuery = useContext(ProjectTagsQueryContext);

  const selected = useMemo(() => {
    return selectedTags.map(sTag => {
      const tag = tagsQuery.data.tags.find(tag => tag.base.id === sTag.tagId);
      if (!tag) return null;
      return {
        id: tag.base.id,
        color: tag?.base?.color,
        name: tag?.base?.name,
        typeId: tag?.base?.typeId,
        range: {
          start: sTag.tagStart,
          end: sTag.tagEnd,
        },
      };
    }).filter(Boolean);
  }, [selectedTags, tagsQuery.data]);

  const noOptionsMessage = useCallback((obj: { inputValue: string }) => {
    if (tagsQuery.isFetching) return `Loading...`;
    return obj.inputValue?.length ? `No tags found` : null;
  }, [tagsQuery.isFetching]);

  const options = useMemo(() => {
    const tags = tagsQuery.data.tags.map(tag => ({
      color: tag.base.color,
      id: tag.base.id,
      name: tag.base.name,
      typeId: tag.base.typeId,
      range: defaultRange,
    }));

    const goodQuoteTag = tags.find(tag => tag.name === 'Good Quote');

    return [
      goodQuoteTag,
      ...tags.filter(tag => tag.name !== 'Good Quote'),
    ].filter(Boolean);
  }, [defaultRange, tagsQuery.data]);

  return {
    options,
    noOptionsMessage,
    selected,
  };
};