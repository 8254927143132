import { Helmet } from 'react-helmet';
import { ContentFeedContainer } from '@containers/ContentFeed/ContentFeedContainer';
import { FollowedTopicsContainer } from '@containers/Topic';
import { CardContent, CardHeader, Divider } from '@presentation/Main';
import { StartPost } from '@/components/ContentFeed/StartPost';
import { useTransitionClasses } from '@/components/ContentFeed/hooks/useTransitionClasses';
import { OnboardingChecklist } from '@/components/OnboardingChecklist';
import { AccountProfile } from './AccountProfile';
import { Agenda } from './Agenda';
import { InvitationsPanel } from './InvitationsPanel';
import { Following } from './Following';
import { Onboarding } from './Onboarding';
import Card from './Card';
import { Column, FixedColumn, Scaffold } from './Layout';
import ContentFeed from './ContentFeed';
import styles from './style/Main.css';

type Props = unknown;

export const Main = (props: Props) => {
  return (
    <FollowedTopicsContainer>
      <ContentFeedContainer>
        <Helmet title="Home" />
        <MainScreen />
      </ContentFeedContainer>
    </FollowedTopicsContainer>
  );
};

Main.displayName = 'Main.Member';

const MainScreen = (props: Props) => {
  const classes = useTransitionClasses();

  return (
    <Scaffold>

      <FixedColumn>
        <Card desktop>
          <CardContent>
            <AccountProfile />
          </CardContent>
        </Card>

        <Onboarding>
          <Card className={classes}>
            <CardContent fluid>
              <OnboardingChecklist />
            </CardContent>
          </Card>
        </Onboarding>

        <Card
          className={classes}
          desktop>
          <CardContent fluid>
            <Following />
          </CardContent>
        </Card>
      </FixedColumn>

      <Column>
        <Card>
          <StartPost />
        </Card>
        <Divider className={styles.divider} />
        <ContentFeed />
      </Column>

      <FixedColumn>
        <Card>
          <CardHeader>Invitations</CardHeader>
          <Divider middle />
          <CardContent>
            <InvitationsPanel />
          </CardContent>
        </Card>

        <Card desktop>
          <CardHeader>Upcoming Calls</CardHeader>
          <CardContent fluid>
            <Agenda />
          </CardContent>
        </Card>

      </FixedColumn>

    </Scaffold>
  );
};

MainScreen.displayName = 'Main.Member.Screen';