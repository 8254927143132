import { Component } from 'react';
import { Calendar as FullCalendar } from '@fullcalendar/core';
import * as api from '@api';
import CalendarBase from '@/components/Calendar/CalendarBase';
import EventPopover from './EventPopover';
import { SelectingMain } from './SelectingMain';
import styles from './style/Calendar.css';
import {
  getCalendarView,
  getFullCalSubmitButtonElement,
  getProvidingDefaults,
  transformEvent,
  transformExternalEvents,
} from './utils';

class Selecting extends Component {
  state = {
    events: [],
    externalEvents: [],
    selectedExternalCalendars: [ this.props.user.id ],
  }

  componentDidMount() {
    this.fetchCalendar();
    this.renderCalendar();
  }

  componentDidUpdate() {
    this.calendar.refetchEvents();
  }

  componentWillUnmount() {
    if (this.calendar)  this.calendar.destroy();
    if (this.pastTimer) clearInterval(this.pastTimer);
  }

  buildEvents = data => {
    const events = data.events.map(evt => evt.segments.hour.map(seg => ({ ...evt, ...seg })))
                              .reduce((acc, curr) => acc.concat(curr), [])
                              .map(e => transformEvent({ event: e, user: this.props.user }));
    const externalEvents = transformExternalEvents({
      events: data.external.events,
      userId: this.props.user.id,
    });

    this.setState({
      events,
      externalEvents,
    });
  }

  dismissPopover = () => {
    this.setState({ popover: null });
  }

  fetchCalendar = () => {
    this.setState({ selectedEvent: null });

    return api.calendars.getUserCalendarAvailability({
      callId: this.props.calendar.call.id,
      userId: this.props.calendar.scheduler.id,
    })
    .then(this.buildEvents);
  }

  generateExternalEventSource = () => {
    return Object.keys(this.state.externalEvents)
    .filter(u => this.state.selectedExternalCalendars.includes(+u))
    .reduce((acc, u) => acc.concat(...this.state.externalEvents[u]), []);
  }

  handleCalendarClick = e => {
    if (this.state.popover) {
      this.dismissPopover();
    }
  }

  handleClick = (el, event) => {
    if (['external', 'past', 'moratorium'].includes(event.extendedProps.type)) {
      return;
    }

    if (event.extendedProps.type === 'busy') {
      return this.setState({
        popover: {
          event,
          el: el.id,
        },
      });
    }

    if (this.state.selectedEvent && this.state.selectedEvent.id === event.id) {
      const events = this.state.events.map(e => ({
        ...e,
        backgroundColor: '',
        borderColor: '',
      }));

      this.setState({
        events,
        selectedEvent: null,
      });
    } else {
      const getActiveColor = e => (e.id == event.id) ? 'var(--pri-03)' : '';

      const events = this.state.events.map(e => ({
        ...e,
        backgroundColor: getActiveColor(e),
        borderColor: getActiveColor(e),
      }));

      this.setState({
        events,
        selectedEvent: event,
      });
    }
  }

  handleExternalSelection = data => {
    this.setState({
      selectedExternalCalendars: data.selected
      ? [...this.state.selectedExternalCalendars, data.id]
      : [...this.state.selectedExternalCalendars.filter(u => u !== data.id)],
    });
  }

  renderCalendar = () => {
    this.calendar = new FullCalendar(document.getElementById(styles.calendar), {
      ...getProvidingDefaults(),
      customButtons: {
        save: {
          text: 'Confirm',
          click: this.handleConfirm,
        },
      },
      eventClick: info => this.handleClick(info.el, info.event),
      eventSources:[
        { id: 'events', events: (_, cb) => cb(this.state.events) },
        { id: 'external', events: (_, cb) => cb(this.generateExternalEventSource()) },
        { id: 'past', events: (_, cb) => cb(CalendarBase.generateBackgroundEventSource()) },
      ],
      selectAllow: _ => false,
      windowResize: _ => this.calendar.changeView(getCalendarView()),
    });

    this.calendar.render();
    CalendarBase.scrollToNow();
    this.scrollable = CalendarBase.getScrollableElement();

    if (this.pastTimer) {
      clearInterval(this.pastTimer);
    }

    this.pastTimer = setInterval(() => {
      const pastEventSource = this.calendar.getEventSourceById('past');
      if (pastEventSource) {
        pastEventSource.refetch();
      }
    }, 30*1000);
  }

  handleConfirm = () => {
    return this.props.onSubmit({
      slot: {
        start: this.state.selectedEvent.start,
        end: this.state.selectedEvent.end,
      },
    });
  }

  render() {
    const props = {
      calendar: this.props.calendar,
      canSubmit: !!this.state.selectedEvent,
      className: this.props.className,
      events: this.state.events,
      gotoDate: date => {
        this.calendar?.gotoDate?.(date);
        const btn = getFullCalSubmitButtonElement();

        if (btn) {
          btn.disabled = !this.state.selectedEvent;
        }
      },
      id: styles.calendar,
      onChangeExternalCalendar: this.handleExternalSelection,
      onClickContainer: this.handleCalendarClick,
      onSubmit: this.handleConfirm,
      removedEvents: this.state.removedEvents,
    };

    return (
      <>
        <SelectingMain {...props} />
        {!!this.state.popover &&
          <EventPopover
            dismiss={this.dismissPopover}
            options={{ showActions: false }}
            popover={this.state.popover}
            scrollable={this.scrollable} />
        }
        {this.state.popover && <div
          className={styles.eventOverlay}
          onClick={this.dismissPopover} />}
      </>
    );
  }
}

export { Selecting };
export default Selecting;