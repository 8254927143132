import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { querykey } from '@consts';
import * as api from '@api';
import { GetUpcomingCalls } from '@api/interfaces/users';

type Data = GetUpcomingCalls.Item[];

export default function useUpcomingCalls<TData = Data>(
  options?: UseQueryOptions<Data, AxiosError, TData>
) {

  return useQuery([querykey.Users.UpcomingCalls], async () => {
    return api.users.getUpcomingCalls();
  }, options);
}

export { useUpcomingCalls };