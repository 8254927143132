export const Countries = {
  Get: `get:search/countries`,
};

export const Markets = {
  Get: `get:search/markets`,
};

export const Medical = {
  Conditions: {
    Get: `get:search/medical/conditions`,
  },
  Hospitals: {
    Get: `get:search/medical/hospitals`,
  },
  Options: {
    ResearchParticipation: {
      Get: `get:search/medical/options/research-participation`,
    },
  },
};

export const Timezones = {
  Get: `get:search/timezones`,
};