import { useContext, useMemo } from 'react';
import { Carousel, Width } from '@/components/Discovery';

type Params = number;

export const useCarouselRowStyle = (width: Params) => {
  const carousel = useContext(Carousel.Context);

  return useMemo(() => {
    return {
      translateX: -width * carousel.position,
      width: carousel.items.length * (width + Width.VideoRowItemGap),
    };
  }, [
    carousel.items.length,
    carousel.position,
    width,
  ]);
};