import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import * as $api from '@api';
import type { Entities } from '@api/interfaces/projects';
import { getLocationFor } from '@utils';
import { useMatchProjectIdFromUrl } from '@containers/GroupProject/hooks';
import { EntityListOnboardingContext } from './Context';
import { AddEntityForm } from './AddEntity.Form';
import { EntityTypesContainer } from './EntityTypes.Container';
import styles from './style/Onboarding.css';

export const OnboardingManual = () => {
  const { projectId, slug } = useMatchProjectIdFromUrl();
  const history = useHistory();
  const { selectedLists, ignoredEntities, goBack } = useContext(EntityListOnboardingContext);

  const onFinished = useCallback(() => {
    history.push(getLocationFor.project.entities({ slug }));
  }, [slug, history]);

  const finish = useMutation([
    `post:projects/finish-onboarding`,
    projectId,
  ], (items: Entities.AddItems.Request['items']) => {
    return $api.projects.entities.finishEntityOnboarding({
      projectId,
      items,
      lists: {
        listIds: selectedLists,
        entitiesToIgnore: ignoredEntities,
      },
    });
  }, {
    onSuccess: onFinished,
  });

  return (
    <EntityTypesContainer>
      <div className={styles.root}>
        <div className={styles.title}>Input additional Entities to capture the medical terms that may come up during for your interviews</div>
        <div className={styles.subTitle}>Improve the accuracy of the automated transcripts on this project by providing the medical terms or drug names you expect to come up in interviews. You must provide at least 10 entries.</div>
        <div className={styles.formWrapper}>
          <AddEntityForm
            initialEntries={10}
            loading={finish.isLoading}
            onCancel={goBack}
            cancelText='Back'
            minEntries={10}
            onSubmit={finish.mutate} />
        </div>
      </div>
    </EntityTypesContainer>
  );
};