import { useQuery } from '@tanstack/react-query';
import * as $api from '@api';
import { useWorkspaceFileState } from '@/containers/Workspace.File.Preview/hooks';
import styles from './style/References.css';

export const FileTabReferences = () => {

  const { file, object } = useWorkspaceFileState();

  const { data: references, isInitialLoading } = useQuery({
    queryKey: ['workspace-file-citations', {
      fileId: file.id,
      workspaceId: object.workspaceId,
    }],
    queryFn: () => {
      return $api.workspaces.file.getFileCitations({
        fileId: file.id,
        workspaceId: object.workspaceId,
      }).then(res => res.citations);
    },
  });

  if (isInitialLoading) {
    return null;
  }

  return (
    <div className={styles.root}>
      {references.length === 0 ? (
        <div className={styles.empty}>
          No references found
        </div>
      ) : (
        <div className={styles.list}>
          {references.map((reference, i) => (
            <div key={i} className={styles.item}>
              <div className={styles.ordinal}>
                {reference.ordinal}.
              </div>
              <div>
                {reference.rawValue}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};