import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { getLocationFor } from '@utils';
import { SchedulingTutorial } from '@screens/SchedulingTutorial';
import { useRequestCall } from './useCall';

export const AdHocCallRequestSchedulingTutorial = () => {
  const call = useRequestCall();
  const history = useHistory();

  const handleClick = useCallback(() => {
    const location = getLocationFor.onboarding.call.availability({
      callId: call.id,
    });

    history.push(location);
  }, [
    call.id,
    history,
  ]);

  return (
    <SchedulingTutorial
      nextScreen={handleClick} />
  );
};

export default AdHocCallRequestSchedulingTutorial;