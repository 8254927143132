import { useCallback } from 'react';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import * as api from '@api';
import * as Branding from '@api/interfaces/groups.branding';
import * as QKey from '@consts/querykey';
import { useSelectGroup } from '@containers/Store';
import { useQueryDataAccessor } from './useQueryDataAccessor';

export const useRemoveSurveyCompletionImageMutation = <Context extends Mutation.Context>(options: Mutation.Options<Context> = {}) => {
  const group = useSelectGroup();
  const helpers = useMutationHelpers({
    onError: options.onError,
    onMutate: options.onMutate,
  });

  const mutation = useMutation([QKey.Groups.Branding.Survey.Pages.Completion.Image.Delete, group.id], () => {
    return api.groups.branding.surveys.removeImage({
      groupId: group.id,
    });
  }, {
    ...options,
    ...helpers,
  });

  return mutation;
};

const useMutationHelpers = <Context extends Mutation.Context>(options: Mutation.Options<Context> = {}) => {
  const accessor = useQueryDataAccessor();

  const onError = useCallback((e, variables, context: Context) => {
    accessor.setQueryData(data => {
      return {
        ...data,
        survey: {
          ...data.survey,
          page: {
            ...data.survey.page,
            completion: {
              ...data.survey.page.completion,
              headerImage: context.headerImage,
            },
          },
        },
      };
    });

    options.onError?.(e, variables, context);

  }, [
    accessor,
    options,
  ]);

  const onMutate = useCallback((variables: RemoveSurveyCompletionImage.Variables) => {
    const previous = accessor.getQueryData();

    accessor.setQueryData(data => {
      return {
        ...data,
        survey: {
          ...data.survey,
          page: {
            ...data.survey.page,
            completion: {
              ...data.survey.page.completion,
              headerImage: null,
            },
          },
        },
      };
    });

    options.onMutate?.(variables);

    return {
      headerImage: previous.survey.page.completion.headerImage,
    };

  }, [
    accessor,
    options,
  ]);

  return {
    onError,
    onMutate,
  };
};

declare namespace RemoveSurveyCompletionImage {
  export type Variables = Branding.Surveys.RemoveImage.Request;

  export type Options<Context = unknown> =
    UseMutationOptions<
      Branding.Surveys.RemoveImage.Response,
      unknown,
      Variables,
      Context>;
}

declare namespace Mutation {
  export type Options<C = unknown> = RemoveSurveyCompletionImage.Options<C>;

  export type Context = Pick<Branding.FetchBranding.Response['survey']['page']['completion'], 'headerImage'>;
}