import styles from './style/ImagePreview.css';

type Props = {
  url: string;
};

export const ImagePreview = ({ url }: Props) => {

  return (
    <div className={styles.root}>
      <img
        className={styles.img}
        crossOrigin="anonymous"
        src={url} />
    </div>
  );
};

export default ImagePreview;