import { useCallback, useContext, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import * as API from '@api/interfaces';
import * as api from '@api';
import { ProjectStateContext } from '@containers/GroupProject/Context';
import { ConferenceTagType } from '@enums';
import { ButtonActivityIndicator } from '@presentation';
import { ProjectConferenceTagWithMetadata } from '@/types';
import { cx } from '@utils';
import { Input } from '@/components/Input';
import { PlusCircle } from '@/components/icons';
import * as Layout from '@/components/MedicalProfile/Layout';
import { TagItem } from '@/components/Project.Tags/interfaces';
import { OnboardingFormContext, OnboardingStepperContext } from './Context';
import styles from './style/Onboarding.Categories.css';

type Props = unknown;

export const Subcategories = (props: Props) => {
  const stepper = useContext(OnboardingStepperContext);
  const form = useContext(OnboardingFormContext);
  const state = useContext(ProjectStateContext);
  const [map, setMap] = useState<CategoryGroupMap>(createInitialState(form.categories));

  const mutation = useMutation([
    `put:projects/conferences/tags/bulk`,
    state?.project?.id,
  ], (items: API.ConferenceTags.CreateTags.Request['items']) => {
    return api.projects.conference.createTags({
      items,
      projectId: state.project.id,
    });
  }, {
    onSuccess: res => {
      const tags = res.tags.filter(x => x.base.typeId !== ConferenceTagType.Global);

      const categories = sortTags(tags.filter(t => !t.parent?.id).map(t => ({
        ...t,
        children: sortTags(tags.filter(c => c.parent?.id === t.id)),
      })));

      form.replaceCategoriesState(categories);
    },
  });

  const handleNavigateNext = useCallback(() => {
    const lastOrd = Math.max(0, ...form.categories.map(x => x.ordinal));

    const subcategories = Object.entries(map).reduce((acc, [key, values]) => {
      const items = values.filter(x => !!x?.trim?.()?.length);

      if (!items.length) return acc;

      const parent = form.categories.find(cat => cat.id === +key);

      const tags = items.map((val, i) => ({
        baseTag: {
          name: val,
          color: parent?.base?.color,
          id: null,
        },
        parentTagId: +key,
        ordinal: lastOrd + (i + 1) + acc.length,
      }));

      return [...acc, ...tags];
    }, [] as API.ConferenceTags.CreateTags.Request['items']);

    if (!subcategories.length) {
      stepper.next();
    } else {
      mutation.mutateAsync(subcategories).then(stepper.next);
    }
  }, [
    form,
    map,
    mutation,
    stepper,
  ]);

  const addSubcategory = useCallback((key: number) => {
    setMap(map => ({
      ...map,
      [key]: map[key].concat(''),
    }));
  }, [setMap]);

  const setValue = useCallback((key: number) => {
    return (value: string, index: number) => {
      setMap(map => {
        const items = map[key];

        return {
          ...map,
          [key]: items.map((current, ii) => ii === index ? value : current),
        };
      });
    };
  }, [setMap]);

  const handleRemoveSubcategory = useCallback((key: number) => {
    return (index: number) => {
      setMap(map => {
        const items = map[key];

        return {
          ...map,
          [key]: items.filter((_, ii) => ii !== index),
        };
      });
    };
  }, [setMap]);

  const handlePaste = useCallback((key: number) => {
    return (e: React.ClipboardEvent, index: number) => {
      e.preventDefault();
      const paste = e.clipboardData.getData('text');

      const [first, ...rest] = paste.split('\n');

      if (rest.length > 0) {
        setMap(map => {
          const items = map[key];

          return {
            ...map,
            [key]: items
              .map((current, ii) => ii === index ? first : current)
              .filter(Boolean)
              .concat(rest),
          };
        });
      } else {
        setMap(map => {
          const items = map[key];

          return {
            ...map,
            [key]: items.map((current, ii) => ii === index ? first : current),
          };
        });
      }
    };
  }, [setMap]);

  return (
    <Layout.Screen>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <Layout.Header
            subtitle={copy.subtitle}
            title={copy.title} />
          <div className={styles.main}>
            {form.categories.map(x =>
              <CategoryGroup
                key={x.id}
                item={x}
                onChange={setValue(x.id)}
                onClickAdd={() => addSubcategory(x.id)}
                onClickRemove={handleRemoveSubcategory(x.id)}
                onPaste={handlePaste(x.id)}
                values={map[x.id]} />)}
          </div>
          <Layout.Footer>
            <div
              className={styles.cancel}
              onClick={stepper.back}>
              {`< Back`}
            </div>
            <ButtonActivityIndicator
              className={styles.btn}
              color="primary"
              loading={mutation.isLoading}
              onClick={handleNavigateNext}>
              Next
            </ButtonActivityIndicator>
          </Layout.Footer>
        </div>
      </div>
    </Layout.Screen>
  );
};

Subcategories.displayName = 'Onboarding.Subcategories';

const copy = {
  title: `Input the topic sub-categories of the interview beneath each category`,
  subtitle: `Subcategories should align to the question areas or specific topics you plan to cover in the interview. We recommend up to 70 subcategories.`,
};

type CategoryGroupProps = {
  item: TagItem;
  values: string[];
  onChange: (value: string, index: number) => void;
  onClickAdd: () => void;
  onClickRemove: (index: number) => void;
  onPaste: (e: React.ClipboardEvent, index: number) => void;
};

const CategoryGroup = (props: CategoryGroupProps) => {
  return (
    <div className={cx(styles.items, styles.group)}>
      <Category name={props.item.base.name} />
      {props.values.map((val, i) =>
        <EditableSubcategory
          key={i}
          onChange={str => props.onChange(str, i)}
          onClickRemove={() => props.onClickRemove(i)}
          onPaste={e => props.onPaste(e, i)}
          value={val} />)}
      <div
        className={styles.add}
        onClick={props.onClickAdd}>
        <PlusCircle size={30} />
        <span>Add Subcategory</span>
      </div>
    </div>
  );
};

type CategoryProps = {
  name: string;
};

const Category = (props: CategoryProps) => {
  return (
    <div className={styles.item}>
      <div className={cx(styles.text, styles.uneditable)}>{props.name}</div>
      <div className={styles.remove} />
    </div>
  );
};

type EditableSubcategoryProps = {
  onChange: (value: string) => void;
  onClickRemove: () => void;
  onPaste: (e: React.ClipboardEvent) => void;
  value: string;
};

const EditableSubcategory = (props: EditableSubcategoryProps) => {
  return (
    <div className={styles.item}>
      <Input
        classes={{ input: styles.text }}
        onChange={e => props.onChange(e.target.value)}
        onPaste={props.onPaste}
        placeholder="Enter a subcategory title"
        value={props.value ?? ''} />
      <div
        className={styles.remove}
        onClick={props.onClickRemove}>
        <RemoveCircleOutlineIcon />
      </div>
    </div>
  );
};

EditableSubcategory.displayName = 'Onboarding.Categories.EditableSubcategory';

type CategoryGroupMap = Record<number, string[]>;

function createInitialState(categories: TagItem[]) {
  return categories.reduce((acc, cat) => ({
    ...acc,
    [cat.id]: Array.from({ length: 3 }, _ => ''),
  }), {} as CategoryGroupMap);
}

function sortTags<T extends ProjectConferenceTagWithMetadata>(tags: T[]) {
  return tags.sort((a, b) => (a.ordinal ?? Number.MAX_VALUE) - (b.ordinal ?? Number.MAX_VALUE));
}