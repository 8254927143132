import styles from './styles/Panel.css';

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
  onClick: () => void;
};

export const Export = (props: Props) => {
  return (
    <div>
      <button
        className={styles.btn}
        disabled={props.disabled}
        onClick={props.onClick}>
        {props.children}
      </button>
    </div>
  );
};

Export.displayName = 'Leads.Toolbar.Action.Export';