import { useCallback } from 'react';
import type { FindExtension } from '@remirror/extension-find';
import { useEditorState, useHelpers } from '@remirror/react';
import { resolveWordSnappingFromSelection } from '@containers/Transcript/utils';

export const useFindReplaceWordSnapping = () => {
  const state = useEditorState();

  const { findRanges } = useHelpers<FindExtension>();

  const findReplaceWordSnapping = ({ caseSensitive, query, replacement }: FindReplaceState) => {
    const result = findRanges({
      caseSensitive,
      query,
    });

    const re = new RegExp(query, caseSensitive ? 'g' : 'gi');

    const mappings = result.ranges.map(range => {
      const cursor = resolveWordSnappingFromSelection(range, state);

      const replaced = cursor.text.replaceAll(re, replacement);

      return Object.assign(cursor, {
        text: replaced,
        originalText: query,
        diff: replaced.length - cursor.text.length,
      });
    });

    const diffs = mappings.map(x => x.diff);

    return mappings.map((x, i) => {
      if (i === 0) return x;

      return {
        ...x,
        pos: {
          from: x.pos.from + diffs.slice(0, i).reduce((a, b) => a + b, 0),
          to: x.pos.to + diffs.slice(0, i).reduce((a, b) => a + b, 0),
        },
      };
    });
  };

  return useCallback(findReplaceWordSnapping, [
    findRanges,
    state,
  ]);
};

type FindReplaceState = {
  caseSensitive: boolean;
  query: string;
  replacement: string;
};