import { useCallback, useContext, useMemo } from 'react';
import {
  MedicalBackgroundContactContext,
  MedicalBackgroundAdditionalExpertiseContext,
  MedicalBackgroundIdentificationContext,
  MedicalBackgroundLeadershipContext,
  MedicalBackgroundPracticeContext,
} from '@containers/MedicalOnboarding/Context';
import { type OnSubmitHandler } from '@containers/MedicalOnboarding/interfaces';
import { validators } from '../utils';

export const useOnSubmitAdditionalExpertise = <T extends AE>(handler: T, deps: Deps = []) => {
  const ctx = {
    additionalexpertise: useContext(MedicalBackgroundAdditionalExpertiseContext),
  };

  const variables = useMemo(() => {
    return {
      boardEligibility: {
        questionId: ctx.additionalexpertise.form.boardEligibility.question.id,
        value: ctx.additionalexpertise.form.boardEligibility.answer,
      },
      description: ctx.additionalexpertise.form.description,
      therapeuticAreas: ctx.additionalexpertise.form.therapeuticAreas.map(x => x.custom
        ? { custom: x.custom, name: x.name }
        : x),
    };
  }, [ctx.additionalexpertise.form]);

  const submit = useCallback(() => {
    return handler(variables) as ReturnType<T>;
  }, [
    handler,
    variables,
  ]);

  return useCallback(submit, [
    deps,
    submit,
  ]);
};

export const useOnSubmitContact = <T extends CO>(handler: T, deps: Deps = []) => {
  const ctx = {
    contact: useContext(MedicalBackgroundContactContext),
  };

  const variables = useMemo(() => {
    return {
      contactMethod: ctx.contact.form.contactMethod,
      inPersonResearch: ctx.contact.form.inPersonResearch,
      market: ctx.contact.form.market,
      phone: ctx.contact.form.phone,
      phoneAlternate: ctx.contact.form.phoneAlternate,
      phoneResearch: ctx.contact.form.phoneResearch,
      reminderMethod: ctx.contact.form.reminderMethod,
      smsConsent: ctx.contact.form.smsConsent,
      surveyResearch: ctx.contact.form.surveyResearch,
      timezone: ctx.contact.form.timezone || null,
      virtualResearch: ctx.contact.form.virtualResearch,
    };
  }, [ctx.contact.form]);

  const submit = useCallback(() => {
    return handler(variables) as ReturnType<T>;
  }, [
    handler,
    variables,
  ]);

  return useCallback(submit, [
    deps,
    submit,
  ]);
};

export const useOnSubmitIdentification = <T extends ID>(handler: T, deps: Deps = []) => {
  const ctx = {
    identification: useContext(MedicalBackgroundIdentificationContext),
  };

  const variables = useMemo(() => {
    const country = ctx.identification.form.country || null;
    const isUSA = validators.isCountryUSA(country);

    return {
      clinicalPracticeStartedOn: ctx.identification.form.clinicalPracticeStartedOn,
      country,
      deaNumber: ctx.identification.form.deaNumber || null,
      meNumber: ctx.identification.form.meNumber || null,
      npiNumber: ctx.identification.form.npiNumber || null,
      stateLicenseNumber: ctx.identification.form.stateLicenseNumber || null,
      stateOfPractice: isUSA ? ctx.identification.form.stateOfPractice : null,
      isPayer: isUSA ? ctx.identification.form.isPayer : null,
      payerAdminType: ctx.identification.form.isPayer ? (ctx.identification.form.payerAdminType || null) : null,
    };
  }, [ctx.identification.form]);

  const submit = useCallback(() => {
    return handler(variables) as ReturnType<T>;
  }, [
    handler,
    variables,
  ]);

  return useCallback(submit, [
    deps,
    submit,
  ]);
};

export const useOnSubmitLeadership = <T extends LE>(handler: T, deps: Deps = []) => {
  const ctx = {
    leadership: useContext(MedicalBackgroundLeadershipContext),
  };

  const variables = useMemo(() => {
    return {
      items: ctx.leadership.form.items.map(x => ({
        questionId: x.question.id,
        value: x.answer,
      })),
    };
  }, [ctx.leadership.form]);

  const submit = useCallback(() => {
    return handler(variables) as ReturnType<T>;
  }, [
    handler,
    variables,
  ]);

  return useCallback(submit, [
    deps,
    submit,
  ]);
};

export const useOnSubmitPractice = <T extends PR>(handler: T, deps: Deps = []) => {
  const ctx = {
    practice: useContext(MedicalBackgroundPracticeContext),
  };

  const variables = useMemo(() => {
    return {
      employer: ctx.practice.form.employer,
      medicalFacilityType: {
        questionId: ctx.practice.form.medicalFacilityType.question.id,
        value: ctx.practice.form.medicalFacilityType.answer,
      },
      practiceTime: {
        questionId: ctx.practice.form.practiceTime.question.id,
        value: ctx.practice.form.practiceTime.answer,
      },
    };
  }, [ctx.practice.form]);

  const submit = useCallback(() => {
    return handler(variables) as ReturnType<T>;
  }, [
    handler,
    variables,
  ]);

  return useCallback(submit, [
    deps,
    submit,
  ]);
};

type Deps = ReadonlyArray<unknown>;

type AE = OnSubmitHandler<'additionalExpertise'>;
type CO = OnSubmitHandler<'contact'>;
type ID = OnSubmitHandler<'identification'>;
type LE = OnSubmitHandler<'leadership'>;
type PR = OnSubmitHandler<'practice'>;