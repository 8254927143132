import { useCallback, useEffect, useState } from 'react';
import { MediaOrientation } from '@enums';
import { useDimensions } from '@utils';

type LogoData = {
  orientation: Omit<MediaOrientation, 'portrait'>;
  url:         string;
};

type Params = {
  logos: Store.Group.Branding['logos'];
  width: number;
};

export const useNavbarLogo = (logos: Store.Group.Branding['logos']) => {
  const [logo, setLogo] = useState<LogoData>(null);
  const { width } = useDimensions();

  const selectLogo = useCallback((params: Params) => {
    const landscape = {
      orientation: MediaOrientation.Landscape,
      url: params.logos?.landscape?.url,
    };

    const square = {
      orientation: MediaOrientation.Square,
      url: params.logos?.square?.url,
    };

    if (params.width >= 800) {
      const large = landscape.url
          ? landscape
          : square.url
            ? square
            : null;

      setLogo(large);
    } else {
      const small = square.url
          ? square
          : null;

      setLogo(small);
    }
  }, []);

  useEffect(() => {

    selectLogo({
      logos,
      width,
    });

  }, [
    logos,
    selectLogo,
    width,
  ]);

  return logo;
};