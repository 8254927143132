import { memo } from 'react';
import type { QuantFileTableData } from '@/types/project.quant-analysis';
import { cx } from '@utils';
import styles from './style/Analysis.Table.css';

type Props = {
  className?: string;
  data: QuantFileTableData;
};

export const AnalysisTable = memo((props: Props) => {

  return (
    <table className={cx(styles.table, props.className)}>
      <thead>
        {props.data.columnNames.map((column, index) => (
          <th key={index}>{column}</th>
        ))}
      </thead>
      <tbody>
        {props.data.records.map((row, rowIndex) => (
          <tr key={rowIndex}>
            <td>{props.data.rowNames[rowIndex]}</td>
            {row.map((cell, cellIndex) => (
              <td key={cellIndex}>{cell}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
});