import { useState } from 'react';
import { FormButtons } from '@presentation';
import { cx } from '@utils';
import { TargetCompany as TargetCompanyType } from '@/types';
import { TargetCompanyInput } from '@/components/GroupProject/TargetCompanyInput';
import { TargetCompanySubmitData } from './interfaces';
import styles from './style.css';

type Props = {
  className?:     string;
  onCancel:       () => unknown;
  onSubmit:       (form: TargetCompanyType, data?: TargetCompanySubmitData) => unknown;
  targetCompany?: TargetCompanyType;
};

const TargetCompanyForm = (props: Props) => {
  const [form, setFormValue] = useState<TargetCompanyType>(props.targetCompany ?? {});

  function handleAttemptSubmit() {
    form.id === props.targetCompany?.id ||
    form.name?.trim?.() === props.targetCompany?.name
      ? props.onCancel()
      : handleSubmit();
  }

  function handleSubmit() {
    const { id, ...tc } = form;

    const value = !id
        ? null
        : tc.isUserCreated
          ? { name: form.name }
          : { id: form.id };

    props.onSubmit(form, value as TargetCompanySubmitData);
  }

  return (
    <div className={cx(styles.target, props.className)}>
      <TargetCompanyInput
        autoFocus
        className={styles.form}
        defaultItem={form}
        onChange={setFormValue} />

      <FormButtons
        onCancel={props.onCancel}
        onSubmit={handleAttemptSubmit} />
    </div>
  );
};

export { TargetCompanyForm };
export default TargetCompanyForm;