// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tui-image-editor-header-logo {
  display: none;
}

.tui-image-editor-header-buttons {
  display: none;
}

.tie-btn-reset {
  display: none !important;
}

.tui-image-editor-help-menu.top {
  display: none;
}

.tui-image-editor-submenu {
  display: none;
}

.tui-image-editor-controls {
  display: none !important;
}

.tui-image-editor-container .tui-image-editor-main-container {
  bottom: 0px;
  border-width: 2px !important;
}

.tui-image-editor-container .tui-image-editor-main {
  top: 0px;
}

.tui-image-editor-submenu {
  display: none !important;
}

.tui-image-editor-wrap {
  height: calc(100% + 6px);
}

.tui-image-editor-align-wrap {
  vertical-align: top !important;
}`, "",{"version":3,"sources":["webpack://./src/static/css/useable/toast-image-editor-overrides.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,WAAW;EACX,4BAA4B;AAC9B;;AAEA;EACE,QAAQ;AACV;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".tui-image-editor-header-logo {\n  display: none;\n}\n\n.tui-image-editor-header-buttons {\n  display: none;\n}\n\n.tie-btn-reset {\n  display: none !important;\n}\n\n.tui-image-editor-help-menu.top {\n  display: none;\n}\n\n.tui-image-editor-submenu {\n  display: none;\n}\n\n.tui-image-editor-controls {\n  display: none !important;\n}\n\n.tui-image-editor-container .tui-image-editor-main-container {\n  bottom: 0px;\n  border-width: 2px !important;\n}\n\n.tui-image-editor-container .tui-image-editor-main {\n  top: 0px;\n}\n\n.tui-image-editor-submenu {\n  display: none !important;\n}\n\n.tui-image-editor-wrap {\n  height: calc(100% + 6px);\n}\n\n.tui-image-editor-align-wrap {\n  vertical-align: top !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
