import { useConjointAnalysisSurveyResponses } from '@containers/SurveyResponses/hooks/useSurveyResponsesContext';
import { ConjointAnalysisQuestion } from '@/types';
import LevelData from './ConjointAnalysis.Data.Level';
import styles from './style/ConjointAnalysis.Data.css';

export const ConjointAnalysisData = () => {

  const {
    levelColors,
    onReset,
    query,
    responses,
    question,
  } = useConjointAnalysisSurveyResponses();

  function renderContent() {

    if (query.levelId) {
      const level = question.attributes.reduce((acc, attr) => {
        return acc || attr.levels.find(l => l.base.id === query.levelId);
      }, null as ConjointAnalysisQuestion.Level);

      return (
        <LevelData
          color={levelColors[query.levelId]}
          data={responses.levels[query.levelId]}
          level={level}
          onClose={onReset} />
      );
    } else {
      return (
        <div className={styles.placeholder}>
          Click a level to view respondents
        </div>
      );
    }
  }

  return (
    <div className={styles.root}>
      {renderContent()}
    </div>
  );

};

export default ConjointAnalysisData;