import { CellProps } from 'react-table';
import { ProjectIcon } from '@/components/icons';
import ClickContainer from './ResultClickContainer';
import { ProjectsTableItem } from './interfaces';
import styles from './style/Cell.Name.css';

export default function Name({ row: { original: data } }: CellProps<ProjectsTableItem>) {
  return (
    <ClickContainer className={styles.overflowWrapper} item={data}>
      <div className={styles.root}>
        <ProjectIcon
          className={styles.icon}
          projectType={data.metadata.type} />
        <div className={styles.details}>
          <div className={styles.name}>{data.name}</div>
        </div>
      </div>
    </ClickContainer>
  );
}

export { Name };