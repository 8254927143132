import { AxiosResponse } from 'axios';
import { useCallback, useContext, useRef, forwardRef } from 'react';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import * as API from '@api/interfaces';
import * as $api from '$admin/api/leads';
import Toast from '@/components/Toast';
import { LeadsResultsContext } from './containers/Context';
import styles from './styles/Panel.css';

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
  onClose: () => void;
};

export const Import = ({ onClose, ...props }: Props) => {
  const query = useContext(LeadsResultsContext);
  const ref = useRef<HTMLInputElement>();
  const mutation = useImportContactsMutation({
    onSuccess: () => {
      query.refetch();
    },
    onMutate: () => {
      onClose();
    },
  });

  const handleClick = useCallback(() => {
    ref.current.value = '';
    ref.current.click();
  }, []);

  return (
    <div>
      <button
        className={styles.btn}
        disabled={props.disabled}
        onClick={handleClick}>
        {props.children}
      </button>
      <FileInput
        onInput={mutation.mutate}
        ref={ref} />
    </div>
  );
};

Import.displayName = 'Leads.Toolbar.Action.Import';

type FileInputProps = {
  onInput: (file: File) => unknown;
};

const FileInput = forwardRef<HTMLInputElement, FileInputProps>((props, ref) => {

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const file = e.target.files[0];

    props.onInput(file);

  }, [props]);

  return (
    <input
      autoComplete="off"
      accept=".csv"
      ref={ref}
      type="file"
      onChange={handleChange}
      style={{ display: 'none' }} />
  );
});

type ErrorData = {
  message: string;
};

const useImportContactsMutation = (options: ImportContacts.Options = {}) => {
  const mutation = useMutation(['post:upload-leads'], (file: File) => {
    return $api.uploadLeads({
      file,
    });
  }, {
    ...options,
    onError: (e: AxiosResponse<ErrorData>, v, c) => {
      Toast.error({
        title: 'There was an error uploading your leads',
        body: e?.data?.message,
      });
      console.log('Error', e);
      options?.onError(e, v, c);
    },
    onSuccess: (d, v, c) => {
      Toast.success({
        title: `Your leads have been uploaded!`,
      });
      console.log('success');

      options?.onSuccess(d, v, c);
    },
  });

  return mutation;
};

namespace ImportContacts {
  export type Variables = File;

  export type Options =
    UseMutationOptions<
      API.Groups.Contacts.Upload.Response,
      unknown,
      Variables>;
}