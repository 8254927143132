// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vis-timeline {
  border: none;
}

.vis-panel.vis-center,
.vis-panel.vis-left,
.vis-panel.vis-right,
.vis-panel.vis-top,
.vis-panel.vis-bottom {
  border-color: var(--gray);
}

.vis-current-time {
  background-color: var(--pri-02);
}

.vis-item {
  border-color: var(--azure);
  background-color: var(--azure);
}`, "",{"version":3,"sources":["webpack://./src/static/css/useable/vis-timeline.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;;;;;EAKE,yBAAyB;AAC3B;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,0BAA0B;EAC1B,8BAA8B;AAChC","sourcesContent":[".vis-timeline {\n  border: none;\n}\n\n.vis-panel.vis-center,\n.vis-panel.vis-left,\n.vis-panel.vis-right,\n.vis-panel.vis-top,\n.vis-panel.vis-bottom {\n  border-color: var(--gray);\n}\n\n.vis-current-time {\n  background-color: var(--pri-02);\n}\n\n.vis-item {\n  border-color: var(--azure);\n  background-color: var(--azure);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
