import { useCallback } from 'react';
import Toast from '@/components/Toast';
import * as $api from '@/services/api';
import type { DeleteFileParams } from '../interfaces';

export const useDeleteFile = () => {

  const deleteFile = useCallback((data: DeleteFileParams) => {
    return $api.workspaces.file.deleteFile({
      fileId: data.fileId,
      workspaceId: data.workspaceId,
    }).then(_ => {
      Toast.alert({
        title: 'File Deleted',
      });
      return data;
    }).catch(e => {
      Toast.error({
        title: 'Error',
        body: `We're sorry, there was an issue with your request`,
      });
      throw e;
    });

  }, []);

  return deleteFile;
};

export default useDeleteFile;