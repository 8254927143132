import * as consulting from './consulting';
import * as corporate from './corporate';
import * as enterprise from './enterprise';
import * as individuals from './individuals';

export const strategy = {
  consulting,
  corporate,
  enterprise,
  individuals,
  investment: enterprise,
};