import { useCallback, useContext } from 'react';
import * as api from '@api';
import { SearchLoadingDispatchContext, SearchResultsDispatchContext } from '@/containers/WorkspaceGlobalSearch/Context';
import type { WorkspaceSearchFull } from '@/types';

export default function useSearchFetchTranscripts() {
  const setResults = useContext(SearchResultsDispatchContext);
  const setLoading = useContext(SearchLoadingDispatchContext);

  const transcripts = useCallback(async ({ filters, size }: FetchData) => {
    const result = await api.workspaces.globalSearch.transcripts({
      filters,
      size,
    });

    if (result) {
      setResults(prev => ({
        ...prev,
        transcripts: result,
      }));
    }
  }, [setResults]);

  const fetch = useCallback(({ filters, size }: FetchData) => {
    setLoading(prev => ({ ...prev, transcripts: { ...prev.transcripts, loading: true } }));
    Promise.all([
      new Promise<void>(resolve => setTimeout(() => resolve(), 250)),
      transcripts({ filters, size }),
    ])
    .then(() => {
      setLoading(prev => ({ ...prev, transcripts: { loading: false } }));
    });
  }, [transcripts, setLoading]);

  return fetch;
}

export { useSearchFetchTranscripts };

type FetchData = {
  filters: WorkspaceSearchFull.Transcript.Filters;
  size: number;
};