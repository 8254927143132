import { useCallback, useMemo } from 'react';
import type { SurveyAIEGroup, SurveyItem } from '@/types/survey';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { SurveyPipedValueType } from '@/enums';
import { usePipingMenuState } from './context';
import { Menu, MenuItem } from './Editor.Piping.Menu';

type Props = {
  item: SurveyItem;
};

export const PipingAIEMenu = ({ item }: Props) => {
  const [state] = useSurveyBuilderState();

  const exercise = useMemo(() => state.survey.alternateImageExercises.find(e => e.identifier === item.source.identifier), [item.source.identifier, state.survey.alternateImageExercises]);

  const { onPipingValueSelect } = usePipingMenuState();

  const handleItemClick = useCallback((group: SurveyAIEGroup) => {
    onPipingValueSelect({
      value: {
        type: SurveyPipedValueType.AlternateImageShown,
        group: { identifier: group.identifier },
      },
      item: { identifier: item.identifier },
    });
  }, [item.identifier, onPipingValueSelect]);

  const renderItem = useCallback((group: SurveyAIEGroup) => {
    const text = `C${group.name}`;

    return (
      <MenuItem
        key={group.identifier}
        onClick={() => handleItemClick(group)}>
        {text}
      </MenuItem>
    );
  }, [handleItemClick]);

  return (
    <Menu>
      {exercise.groups.map(renderItem)}
    </Menu>
  );
};