import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';
import { cx } from '@/utils';
import type {
  ExternalSourcingConfigurationFormValues,
  ExternalSourcingConfigurationDisplay,
  ExternalSourcingConfigurationForm,
} from '@containers/Project.ExternalSourcing';
import { FieldSwitch, FieldText } from './Field';
import styles from './style/Section.Settings.Survey.ExternalSourcing.css';
import rootStyles from './style.css';

export const OffPlatformSourcingGenericDisplay = (props: ExternalSourcingConfigurationDisplay) => {
  return (
    <>
      <div className={rootStyles.section50}>
        <div className={rootStyles.label}>Append Vendor User ID to User Links</div>
        <div>{props.appendVidToLinks ? 'Yes' : 'No'}</div>
      </div>
      <div className={rootStyles.section50}>
        <div className={rootStyles.label}>Lazy User Link Assignment</div>
        <div>{props.lazyLinkAssignment ? 'Yes' : 'No'}</div>
      </div>
      {props.lazyLinkAssignment
        ? (
          <div className={rootStyles.section100}>
            <div className={rootStyles.label}>Lazy User Link</div>
            <div>{`${process.env.FRONT_BASE_URL}/ext-src/${props.projectId}/${props.identifier}?_ss=${props.lazyLinkSecret}`}</div>
          </div>
        ) : null}
      <div className={rootStyles.section100}>
        <div className={cx(styles.label, styles.link)}>Completion Template Link</div>
        <div className={styles.value}>{props.completionUrl}</div>
      </div>
      <div className={rootStyles.section100}>
        <div className={cx(styles.label, styles.link)}>Disqualify Template Link</div>
        <div className={styles.value}>{props.disqualifyUrl}</div>
      </div>
      <div className={rootStyles.section100}>
        <div className={cx(styles.label, styles.link)}>Overquota Template Link</div>
        <div className={styles.value}>{props.overquotaUrl}</div>
      </div>
    </>
  );
};

export const OffPlatformSourcingGenericForm = (props: ExternalSourcingConfigurationForm) => {
  const form = useFormikContext<ExternalSourcingConfigurationFormValues>();

  useEffect(() => {
    props.setConfigurationSchema(getValidationSchema());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={rootStyles.section100}>
        <FieldText
          editing={true}
          label="Completion Link"
          name="completionUrl"
          displayValue={form.values.completionUrl} />
      </div>
      <div className={rootStyles.section100}>
        <FieldText
          editing={true}
          label="Disqualify Link"
          name="disqualifyUrl"
          displayValue={form.values.disqualifyUrl} />
      </div>
      <div className={rootStyles.section100}>
        <FieldText
          editing={true}
          label="Overquota Link"
          name="overquotaUrl"
          displayValue={form.values.overquotaUrl} />
      </div>
      <div className={rootStyles.section50}>
        <FieldSwitch
          editing={true}
          label="Append Vendor User ID to User Links"
          name="appendVidToLinks"
          displayValue={form.values.appendVidToLinks ? 'Yes' : 'No'} />
      </div>
      <div className={rootStyles.section50}>
        <FieldSwitch
          editing={true}
          label="Lazy User Link Assignment"
          name="lazyLinkAssignment"
          displayValue={form.values.lazyLinkAssignment ? 'Yes' : 'No'} />
      </div>
    </>
  );
};

function getValidationSchema() {
  const generalUrlValidator = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gm;

  return Yup.object().shape({
    label: Yup.string().required('Label is required'),
    rate: Yup.number().min(0, 'Rate must be a positive number').required('Rate is required'),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    vendor: Yup.object().shape({ id: Yup.number().required(), name: Yup.string().required() }).nullable().default(null).required('Vendor is required'),
    completionUrl: Yup.string().matches(generalUrlValidator, 'Must be a valid URL').required('Completion link is required'),
    disqualifyUrl: Yup.string().matches(generalUrlValidator, 'Must be a valid URL').required('Disqualify link is required'),
    overquotaUrl: Yup.string().matches(generalUrlValidator, 'Must be a valid URL').required('Overquota link is required'),
  });
}