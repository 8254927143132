import { usePersistentProjectInvite, useProjectInviteWhitelabelState } from '@containers/ProjectLinkInvitation/hooks';
import { Nav, Navbar as NavbarCore, useLoginModal, useSignupModal } from '$website/components';
import { Button } from '@/components/Button';
import { ButtonColor } from '@/components/Button/interfaces';
import styles from './style/ProjectInvite.css';

type Props = unknown;

export const Navbar = (props: Props) => {
  return (
    <NavbarCore.LayoutRoot>
      <div className={styles.navbar}>
        <Nav.Logo />
        <div className={styles.nav}>
          <ButtonSet />
        </div>
      </div>
    </NavbarCore.LayoutRoot>
  );
};

Navbar.displayName = 'ProjectInvite.Navbar';

const ButtonSet = (props: Props) => {
  const [l, setLoginModalOpen] = useLoginModal();
  const [s, setSignupModalOpen] = useSignupModal();
  const whitelabel = useProjectInviteWhitelabelState();
  const urlstate = usePersistentProjectInvite();
  const state = urlstate.get();

  const btn = {
    color: whitelabel.enabled ? `transparent` : `primary` as ButtonColor,
    style: whitelabel.enabled ? {
      backgroundColor: state.data.theming.palette.primary.main,
      borderColor: state.data.theming.palette.primary.main,
      color: state.data.theming.palette.primary.text,
    } : {},
  };

  return (
    <div className={styles.btns}>
      <Button
        className={styles.login}
        onClick={() => setLoginModalOpen(true)}
        variant="link">
        Log In
      </Button>
      <Button
        className={styles.signup}
        color={btn.color}
        onClick={() => setSignupModalOpen(true)}
        variant="brick"
        style={btn.style}>
        Sign Up
      </Button>
    </div>
  );
};

ButtonSet.displayName = 'ProjectInvite.Navbar.ButtonSet';