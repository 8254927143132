import { Node } from 'slate';
import { FeedItemComment } from '@/types/comments';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import * as Comment from '@/components/ContentFeed/Comment';
import { useCommentReplyState } from '@/components/ContentFeed/hooks/useCommentReplyState';
import styles from './style/CommentsBlock.css';

type Props = {
  items:           FeedItemComment[];
  loading:         boolean;
  onSubmitComment: (val: Node[]) => Promise<FeedItemComment>;
};

export const CommentsBlock = (props: Props) => {
  const [replying, replymode] = useCommentReplyState(true);

  const empty = !!(!props.loading && !props.items?.length);
  const items = props.items || [];

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          {props.loading &&
            <ActivityIndicator show />}
          {replying &&
            <Comment.Input onSubmit={props.onSubmitComment} />}
          <div className={styles.thread}>
            {items.map(x =>
              <Comment.Thread
                className={styles.item}
                item={x}
                depth={0}
                key={x.id} />)}
          </div>
          {empty &&
            <div className={styles.empty}>{copy.empty}</div>}
        </div>
      </div>
    </div>
  );
};

CommentsBlock.displayName = 'Posts.CommentsBlock';

const copy = {
  empty: `Post a comment to start the conversation!`,
};