import { useCallback, useContext, useEffect, useMemo } from 'react';
import { nullableDate } from '@/utils/date';
import { EditingToolPaneContainer } from '@containers/Transcript/EditingToolPane.Container';
import { CallTranscriptDetailsContext, TranscriptIdContext, WorkspaceTranscriptDetailsContext } from '@containers/Transcript/context';
import { TranscriptCommentsRefPointContext } from '@containers/Transcript.Commenting/Context';
import {
  useRegisterChatPageContext,
  ChatContextType,
  type BrandInsightsPageContext,
} from '@/brand-insights';
import { MonologueDetailsEditSpeakerContainer } from '@/components/Transcript/MonologueDetails.EditSpeaker.Container';
import { useCommentsBounds, useEditingToolsDisplayable } from './hooks';

export const TranscriptScreenContainer = (props: ChildrenProps) => {
  const [commentsRef, sidebarBounds] = useCommentsBounds();
  const displayEditingTools = useEditingToolsDisplayable();

  useBrandInsights();

  return (
    <TranscriptCommentsRefPointContext.Provider value={{ ref: commentsRef, bounds: sidebarBounds }}>
      <EditingToolPaneContainer enabled={displayEditingTools}>
        <MonologueDetailsEditSpeakerContainer>
          {props.children}
        </MonologueDetailsEditSpeakerContainer>
      </EditingToolPaneContainer>
    </TranscriptCommentsRefPointContext.Provider>
  );
};

const useBrandInsights = () => {
  const transcriptId = useContext(TranscriptIdContext);
  const callDetails = useContext(CallTranscriptDetailsContext);
  const workspaceDetails = useContext(WorkspaceTranscriptDetailsContext);
  const getMetadata = useCallback(() => {
    if (callDetails) {
      return {
        name: callDetails.project?.name,
        date: nullableDate(callDetails.call?.timeEnd),
      };
    } else if (workspaceDetails) {
      return {
        name: workspaceDetails.object?.name,
        date: nullableDate(workspaceDetails.object?.createdOn),
      };
    }
    return {
      name: null,
      date: null,
    };
  }, [callDetails, workspaceDetails]);

  const chatContext: BrandInsightsPageContext.Transcript = useMemo(() => {
    const insightsChat = callDetails?.features?.insightsChat || workspaceDetails?.features?.insightsChat;

    if (!insightsChat) return null;

    return {
      type: ChatContextType.Transcript,
      data: {
        callId: callDetails?.call?.id,
        projectId: callDetails?.project?.id,
        transcriptId,
      },
      metadata: getMetadata(),
    };
  }, [callDetails?.call?.id, callDetails?.project?.id, getMetadata, transcriptId, callDetails?.features?.insightsChat, workspaceDetails?.features?.insightsChat]);

  const register = useRegisterChatPageContext();
  useEffect(() => {
    if (!chatContext) return;

    if (chatContext.data.transcriptId) {
      register(chatContext);
    }
  }, [register, chatContext]);
};