import { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import logo from 'static/images/logo-sentiment-square.png';
import styles from './style.css';

type State = {
  confirmPassword: string;
  error: string;
  password: string;
};

const ResetPassword = () => {
  const [state, setState] = useState<State>({
    confirmPassword: '',
    error: null,
    password: '',
  });

  const handleChange = useCallback((field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setState(s => ({
      ...s,
      [field]: e.target.value,
    }));
  }, []);

  const handleSubmit = useCallback((e: React.SyntheticEvent) => {
    e.preventDefault();
    const { confirmPassword, password } = state;
    if (!confirmPassword || !password || confirmPassword !== password) {
      setState(s => ({
        ...s,
        error: 'Passwords must be the same',
      }));
    }
  }, [state]);

  const renderError = useCallback(() => {
    return state.error
      ? <div className={styles.error}>{state.error}</div>
      : null;
  }, [state.error]);

  return (
    <div className={styles.root}>
      <Helmet title="Reset Password" />
      <div className={styles.logo}>
        <img
          className={styles.img}
          src={logo} />
      </div>
      <div className={styles.wrap}>
        <form className={styles.form}>
          <div className={styles.header}>Reset Password</div>
          <input
            className={styles.input}
            name="password"
            onChange={handleChange('password')}
            placeholder="New Password"
            type="password" />
          <input
            className={styles.input}
            name="confirmPassword"
            onChange={handleChange('confirmPassword')}
            placeholder="Confirm Password"
            type="password" />
          <div className={styles.footer}>
            <div className={styles.notify}>
              {renderError()}
            </div>
            <div className={styles.submit}>
              <button
                className={styles.btn}
                onClick={handleSubmit}
                type="submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export { ResetPassword };
export default ResetPassword;