import { CardHeader } from './Layout';
import styles from './style/Sidebar.css';

type HeaderProps = {
  children?: React.ReactNode;
  title: string;
};

export const SidebarHeader = ({ children, title }: HeaderProps) => {
  return (
    <div className={styles.header}>
      <div>{title}</div>
      {children}
    </div>
  );
};

