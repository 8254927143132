import { useMemo } from 'react';
import { SurveyQuestionType } from '@enums';
import { useValidateRationale } from './hooks';
import { SurveyFormCanSubmitContext, useSurveyFormQuestionAnswer } from './Context';
import { SurveyFormBackContainer, SurveyFormSubmitContainer } from './BaseNavigation';

type Props =
  ChildrenProps;

export const ShortTextResponseFormContainer = (props: Props) => {

  const [answer] = useSurveyFormQuestionAnswer<SurveyQuestionType.ShortTextResponse>();
  const validateRationale = useValidateRationale();

  const canSubmit = useMemo(() => {

    const isRationaleValid = validateRationale();

    if (!isRationaleValid) return false;

    return isTextValid();

    function isTextValid() {
      return !!answer?.value;
    }
  }, [
    answer,
    validateRationale,
  ]);

  return (
    <SurveyFormCanSubmitContext.Provider value={canSubmit}>
      <SurveyFormSubmitContainer>
        <SurveyFormBackContainer>
          {props.children}
        </SurveyFormBackContainer>
      </SurveyFormSubmitContainer>
    </SurveyFormCanSubmitContext.Provider>
  );
};

export default ShortTextResponseFormContainer;