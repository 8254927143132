export const filterTypes = {
  AUTOCOMPLETE: 'AUTOCOMPLETE',
  SLIDER: 'SLIDER',
  SELECT: 'SELECT',
  INPUT: 'INPUT',
};

// export const seniorityList = [
//   { id: 5, type: 'seniority', name: 'C-Level' },
//   { id: 4, type: 'seniority', name: 'Executives' },
//   { id: 3, type: 'seniority', name: 'Director' },
//   { id: 2, type: 'seniority', name: 'Manager' },
//   { id: 1, type: 'seniority', name: 'Non-Manager' },
//   { id: 0, type: 'seniority', name: 'Other' },
// ];

const maxTagTextLength = 21;

export const cutoffTagText = text => {
  return text.length > maxTagTextLength ? text.substring(0, maxTagTextLength) + '...' : text;
};
