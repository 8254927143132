import { useCallback, useContext } from 'react';
import { ButtonOutlined } from '@presentation';
import { FileSummaryContext } from '@/containers/Workspace.File/Context';

export function SummaryActionDownload() {
  const ctx = useContext(FileSummaryContext);

  const handleDownload = useCallback(() => {
    ctx.download();
  }, [ctx]);

  return (
    <ButtonOutlined
      color="silver"
      fontWeight="bold"
      onClick={handleDownload}>
      Export
    </ButtonOutlined>
  );
}