import { useContext } from 'react';
import { createNamedContext } from '@utils';
import type { SurveyFormPreview } from '@/types/survey';
import type { LeftBarActions, LeftBarState } from './interfaces';

type FormPreviewContextValue = {
  preview: SurveyFormPreview;
};

type LeftBarContextValue = {
  open: boolean;
  setOpen: (open: boolean) => void;
  zoomValue: number;
  setZoomValue: (zoomValue: number) => void;
  state: LeftBarState;
  dispatch: React.Dispatch<LeftBarActions.Actions>;
} & ICallId;

type LeftBarActionContextValue = {
  onItemClick: (itemId: number) => void;
};

type LeftBarWindowContextValue = {
  window: Window;
  thisRole: 'parent' | 'child';
  setWindow: (window: Window) => void;
};

export const FormPreviewContext = createNamedContext<FormPreviewContextValue>(undefined, 'FormPreviewContext');
export const LeftBarContext = createNamedContext<LeftBarContextValue>(undefined, 'LeftBarContext');
export const LeftBarActionContext = createNamedContext<LeftBarActionContextValue>(undefined, 'LeftBarActionContext');
export const LeftBarWindowContext = createNamedContext<LeftBarWindowContextValue>(undefined, 'LeftBarWindowContext');

export const useFormPreviewContext = () => useContext(FormPreviewContext);
export const useLeftBarContext = () => useContext(LeftBarContext);
export const useLeftBarActionsContext = () => useContext(LeftBarActionContext);
export const useLeftBarWindowContext = () => useContext(LeftBarWindowContext);