import { path } from '@consts';
import { RouteAuth } from '@enums';
import { BasicRouter } from '@routes/Routers';
import { ProjectLinkInvitationContainer } from '@containers/ProjectLinkInvitation';
import type { Routing } from '@containers/Routing';

export const routes: Routing.Route[] = [
  {
    component: ProjectLinkInvitationContainer,
    exact: true,
    path: path.Invite.Token,
  },
];

export const router = BasicRouter(routes, {
  auth: RouteAuth.Ignore,
  path: path.Invite.Token,
});