import { ProjectType } from '@enums';
import { Select } from '@/components/Select';
import { Field } from './Overview.Field';
import styles from './style/Overview.css';

type Props<T> = {
  defaultValue: string;
  label:        string;
  onChange:     (val: T) => unknown;
  options:      T[];
  placeholder?: string;
  tooltip?:     string;
};

export const Methodology = <T extends Option>(props: Props<T>) => {

  return (
    <Field
      label={props.label}
      tooltip={props.tooltip}>
      <div className={styles.type}>
        <Select
          className={styles.select}
          defaultValue={props.defaultValue}
          getItemValue={x => x.name}
          onSelect={props.onChange}
          options={props.options} />
      </div>
    </Field>
  );
};

Methodology.displayName = 'Project.Creation.Details.Field.Methodology';

type Option = {
  id:   ProjectType;
  name: string;
};