import {
  BoldButton,
  ImageButton,
  ItalicButton,
  LinkButton,
  ListBulletedButton,
  ListOrderedButton,
  UnderlineButton,
  Toolbar as TB,
} from '@/components/Remirror';

type Props = {
  className?: string;
};

export const Toolbar = ({ className }: Props) => {

  return (
    <TB className={className}>
      <BoldButton />
      <ItalicButton />
      <UnderlineButton />
      <LinkButton />
    </TB>
  );
};