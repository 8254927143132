import { useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import * as api from '@api';
import * as Discovery from '@/components/Discovery';
import { TopicsNavigationContext } from '@/containers/Discovery/Context';
import { useAsyncStateLazy } from '@/utils';
import { TopicContent } from './TopicContent';
import styles from './style/DiscoveryTopic.css';

type Props = unknown;

type Params = {
  slug: string;
};

export const DiscoveryTopic = (props: Props) => {
  const params = useParams<Params>();
  const topic = useContext(TopicsNavigationContext);

  const [state, refetch] = useAsyncStateLazy((params: { slug: string }) => {
    return Promise.all([
      api.discovery.fetchVideoTopicContent({
        slug: params.slug,
      }),
      api.discovery.fetchVideoTopicCreators({
        slug: params.slug,
      }),
    ])
    .then(([ video, creators ]) => ({ creators, video }));
  }, []);

  useEffect(() => {

    refetch({ slug: params.slug });

  }, [
    params?.slug,
    refetch,
  ]);

  const loading = useMemo(() => {
    return state.loading
        || !state.value
        || !topic.value;
  }, [
    state.value,
    state.loading,
    topic.value,
  ]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>

          {topic.value
            && <Discovery.TopicNavigation items={topic.value?.items} />}

          {loading
            ? <SkeletonMain />
            : <TopicContent
                creators={state.value.creators}
                video={state.value.video} />}
        </div>
      </div>

      {state.value &&
        <Discovery.Footer />}
    </div>
  );
};

function SkeletonMain() {
  return (
    <div className={styles.skeleton}>
      <Discovery.Skeleton.Row />
      <Discovery.Skeleton.Row />
      <Discovery.Skeleton.Row />
    </div>
  );
}

SkeletonMain.displayName = 'Skeleton.Main';
DiscoveryTopic.displayName = 'Discovery.Topic';