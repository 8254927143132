import { useCanCreateFindAndReplace } from '@/containers/SurveyBuilder/hooks/useCanCreateFindAndReplace';
import { PipingAnchor, PipingButton, PipingMenuContainer } from '@/components/Survey.RichText/Editor.Piping';
import { FindAndReplaceButton } from '@/components/Survey.RichText/Editor.FindAndReplace';
import {
  BoldButton,
  ImageButton,
  ItalicButton,
  LinkButton,
  ListBulletedButton,
  ListOrderedButton,
  UnderlineButton,
  Toolbar as TB,
  ColorButton,
} from '@/components/Remirror';

type Props = {
  className?: string;
  disablePiping?: boolean;
};

export const Toolbar = ({ className, disablePiping }: Props) => {
  const canFar = useCanCreateFindAndReplace();

  return (
    <TB className={className}>
      {!disablePiping &&
        <PipingMenuContainer>
          <PipingAnchor>
            {(_, ref) => (
              <PipingButton ref={ref as React.Ref<HTMLButtonElement>} />
            )}
          </PipingAnchor>
        </PipingMenuContainer>
      }
      {canFar ? <FindAndReplaceButton /> : null}
      <BoldButton />
      <ItalicButton />
      <UnderlineButton />
      <LinkButton />
      <ImageButton />
      <ListBulletedButton />
      <ListOrderedButton />
      <ColorButton />
    </TB>
  );
};