import { useCallback, useMemo, useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ButtonActivityIndicator, ButtonOutlined } from '@presentation';
import * as api from '$admin/api';
import Autocomplete from '@/components/AutoComplete';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { Header } from '@/components/Modal/Header';
import { NumberInput } from '@/components/Input';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import styles from './style/Project.CreateCharge.css';

type Props = {
  onSuccess?: () => unknown;
  project: {
    code?: string;
  } & Descriptor;
} & Pick<ModalProps, 'onClose' | 'open'>;

export const CreateCharge = (props: Props) => {
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('');

  const value = useMemo(() => {
    return amount.startsWith('$')
      ? amount.slice(1)
      : amount;
  }, [amount]);

  const mutation = useMutation([
    `post:admin/projects/billing/charges`,
    props.project.id,
  ], () => {
    return api.projects.billing.createCharge({
      projectId: props.project.id,
      description,
      amount: +value,
    });
  }, {
    onSuccess: () => props.onSuccess?.(),
  });

  const disabled = useMemo(() => {
    return !description.trim().length
        || !amount.trim().length;
  }, [
    amount,
    description,
  ]);

  const query = useQuery([
    `get:admin/projects/billing/charges/presets`,
    description,
    props.project.id,
  ], () => {
    return api.projects.billing.searchChargePresets({
      projectId: props.project.id,
      keyword: description,
    });
  }, {
    enabled: !!description.trim(),
    refetchOnWindowFocus: false,
  });

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setDescription(value);
  }, [setDescription]);

  const handleSelect = useCallback((item: Item & Descriptor) => {
    const description = item?.description || item?.name || '';

    setDescription(description);

    if (item?.amount) {
      setAmount(`$${item.amount}`);
    }
  }, [
    setAmount,
    setDescription,
  ]);

  const selected = useMemo(() => {
    return {
      amount,
      description,
    };
  }, [
    amount,
    description,
  ]);

  const options = useMemo(() => {
    if (!query.data?.items) return [];

    return query.data?.items.map(x => ({
      id: x.description,
      amount: x.amount,
      description: x.description,
    }));
  }, [query.data?.items]);

  return (
    <Modal
      onClose={props.onClose}
      open={props.open}>
      <div className={styles.root}>
        <Header>Create Charge</Header>
        <div className={styles.wrap}>
          <div className={styles.main}>

            <div className={styles.row}>
              <div className={styles.description}>
                <div className={styles.label}>Description</div>
                <Autocomplete
                  focusableClasses={{ root: styles.focusable }}
                  getItemValue={(item: Item) => `${item.description} - $${item.amount}`}
                  item={selected}
                  items={options}
                  name="charge-presets"
                  onChange={handleChange}
                  onSelect={handleSelect}
                  placeholder="Input description"
                  value={description || ''} />
              </div>
              <div className={styles.amount}>
                <div className={styles.label}>Amount</div>
                <NumberInput
                  classes={{ input: styles.input }}
                  decimalScale={2}
                  onChange={e => setAmount(e.target.value)}
                  placeholder="$100.00"
                  prefix={`$`}
                  value={amount} />
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.display}>
                <div className={styles.label}>Project Code</div>
                {props.project.code
                  ? <div className={styles.readonly}>{props.project.code}</div>
                  : <div className={styles.na}>Not Set</div>}
              </div>
              <div className={styles.display}>
                <div className={styles.label}>Project</div>
                <div className={styles.readonly}>{props.project.name}</div>
              </div>
            </div>
          </div>

          <ButtonSet className={styles.footer}>
            <ButtonOutlined
              className={styles.btn}
              color="silver"
              onClick={props.onClose}>
              Back
            </ButtonOutlined>
            <ButtonActivityIndicator
              color="primary"
              className={styles.btn}
              disabled={disabled}
              implicitDisable={false}
              loading={mutation.isLoading}
              onClick={() => mutation.mutate()}
              variant="brick">
              Save
            </ButtonActivityIndicator>
          </ButtonSet>
        </div>
      </div>
    </Modal>
  );
};

CreateCharge.displayName = 'Modal.Project.CreateCharge';

type Item = {
  amount:      number;
  description: string;
};