import extend from '@enums/extend';

export enum CoveredLivesPlans {
  Commercial = 1,
  CommercialExchange = 2,
  MedicareAdvantage = 3,
  MedicareFFSMAC = 4,
  MedicaidFFS = 5,
  ManagedMedicaid = 6,
  MedicareMAPD = 7,
  MedicarePDP = 8,
}

export enum CoveredLivesTypes {
  EntireOrganization = 1,
  DirectlyResponsible = 2,
}

const plans = new Map([
  [CoveredLivesPlans.Commercial, { name: 'Commercial Lives' }],
  [CoveredLivesPlans.CommercialExchange, { name: 'Commercial Exchange Lives' }],
  [CoveredLivesPlans.MedicareAdvantage, { name: 'Medicare Advantage Lives' }],
  [CoveredLivesPlans.MedicareFFSMAC, { name: 'Medicare FFS - MAC Lives' }],
  [CoveredLivesPlans.MedicaidFFS, { name: 'Medicaid FFS Lives' }],
  [CoveredLivesPlans.ManagedMedicaid, { name: 'Managed Medicaid Lives' }],
  [CoveredLivesPlans.MedicareMAPD, { name: 'Medicare MAPD Lives' }],
  [CoveredLivesPlans.MedicarePDP, { name: 'Medicare PDP Lives' }],
]);

const types = new Map([
  [CoveredLivesTypes.EntireOrganization, { name: 'Entire Organization' }],
  [CoveredLivesTypes.DirectlyResponsible, { name: 'Direct Responsibility' }],
]);

export default {
  CoveredLivesPlans: extend(plans),
  CoveredLivesTypes: extend(types),
};