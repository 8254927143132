import type { Dispatch, SetStateAction } from 'react';
import type { WorkspaceObjectType } from '@enums';
import type { StepperActions } from '@utils';
import { createNamedContext } from '@utils';
import type { WorkspaceObjectItem } from '@/types/workspace.table';

export type FilesTableContextValue = {
  parentObject: {
    id: number;
    workspaceId: number;
  };
  projectId?: number;
};

export type FilesTableFilters = {
  parentObjectId?: number;
  typeIds?: WorkspaceObjectType[];
};

export type FilesTableMetaContextValue = {
  count: number;
  size: number;
};

export type SetFilesTableFiltersContextValue = Dispatch<SetStateAction<FilesTableFilters>>;
export type FilesTableLoadingContextValue = boolean;
export type FilesTableDataContextValue = WorkspaceObjectItem[];

export type PublishModalContextValue = {
  actions: StepperActions;
  index: number;
  numScreens: number;
  numTranscriptsPublished: number;
  setNumTranscriptsPublished: (val: number) => void;
};

export type SelectedObjectsContextValue = {
  selectedObjectIds: number[];
  toggleSelectedObject: (objectId: number) => void;
  clearSelectedObjects: () => void;
};

export const FilesTableFiltersContext = createNamedContext<FilesTableFilters>(undefined, 'files-table-filters-context');
export const SetFilesTableFiltersContext = createNamedContext<SetFilesTableFiltersContextValue>(undefined, 'set-files-table-filters-context');
export const FilesTableContext = createNamedContext<FilesTableContextValue>(undefined, 'files-table-context');
export const FilesTableLoadingContext = createNamedContext<FilesTableLoadingContextValue>(undefined, 'files-table-loading-context');
export const FilesTableDataContext = createNamedContext<FilesTableDataContextValue>(undefined, 'files-table-data-context');
export const FilesTableMetaContext = createNamedContext<FilesTableMetaContextValue>(undefined, 'Files.Table.Meta.Context');
export const PublishModalContext = createNamedContext<PublishModalContextValue>(undefined, 'publish-modal-context');
export const SelectedObjectsContext = createNamedContext<SelectedObjectsContextValue>(undefined, 'selected-objects-context');