import { useCallback, useState, useMemo } from 'react';
import type { DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { DragDropContext } from 'react-beautiful-dnd';
import type { SurveyBuilder } from '@containers/SurveyBuilder';
import { useSurveyBuilderState } from '@containers/SurveyBuilder';
import { useSurveyAlternateImagesBuilderItemContext } from '@containers/SurveyBuilder.AIE';
import { AddItemButton } from '@/presentation/AddItemButton';
import type { SurveyAIEGroup, SurveyAlternateImageExercise } from '@/types';
import { Group } from './Editor.Group';
import { Settings } from './Editor.Settings';
import styles from './style/Editor.css';
import { buildGroupPlaceholderIdentifier, parseDndIdentifier } from './utils';
import { GroupPlaceholder } from './Editor.Group.Placeholder';

export const Editor = () => {
  const [, dispatch] = useSurveyBuilderState();
  const item = useSurveyAlternateImagesBuilderItemContext();

  const onAddGroup = useCallback(() => {
    dispatch({
      type: 'aie-group-added',
      exerciseIdentifier: item.identifier,
    });
  }, [dispatch, item.identifier]);

  function onDragEnd(result: DropResult, provided: ResponderProvided) {
    if (!result.destination) return;
    const isEnd = true;
    const activeEntryIdentifier = parseDndIdentifier(result.draggableId).identifier;
    const parsedIdentifier = parseDndIdentifier(result.destination.droppableId);

    switch (parsedIdentifier.type) {
      case 'group': {
        dispatch({
          type: 'aie-entry-moved',
          targetGroupIdentifier: parsedIdentifier.identifier,
          entryIdentifier: activeEntryIdentifier,
          exerciseIdentifier: item.identifier,
          targetOrdinal: result.destination.index,
        });
        break;
      }

      case 'entry': {
        const overEntry = findGroupEntry(item.groups, parsedIdentifier.identifier);

        if (overEntry) {
          dispatch({
            type: 'aie-entry-moved',
            targetGroupIdentifier: overEntry.group.identifier,
            entryIdentifier: activeEntryIdentifier,
            exerciseIdentifier: item.identifier,
            targetOrdinal: overEntry.entry.ordinal,
          });
        }
        break;
      } case 'group-placeholder': {
        if (isEnd) {
          const targetGroup = item.groups.find(g => g.identifier === parsedIdentifier.identifier);
          const { entry: activeEntry, group: activeEntryGroup } = findGroupEntry(item.groups, activeEntryIdentifier);

          const { group: originalEntryGroup } = findGroupEntry(item.groups, activeEntryIdentifier);
          if (targetGroup && activeEntry) {
            const removeSourceGroup = !originalEntryGroup.entries.some(e => e.identifier !== activeEntry.identifier);
            //If we're removing a group beforehand we'll need to adjust the target accordingly
            const targetOrdinal = targetGroup.ordinal + 1 + (removeSourceGroup && targetGroup.ordinal > originalEntryGroup.ordinal ? -1 : 0);
            dispatch({
              type: 'batch-actions',
              actions: [
                {
                  type: 'aie-entry-removed',
                  exerciseIdentifier: item.identifier,
                  entryIdentifier: activeEntry.identifier,
                  groupIdentifier: activeEntryGroup.identifier,
                },
                removeSourceGroup ?
                  {
                    type: 'aie-group-removed',
                    exerciseIdentifier: item.identifier,
                    groupIdentifier: originalEntryGroup.identifier,
                  } : null,
                {
                  type: 'aie-group-added',
                  exerciseIdentifier: item.identifier,
                  entries: [{ ...activeEntry, ordinal: 1 }],
                  targetOrdinal,
                  name: '',
                },
              ].filter(Boolean) as SurveyBuilder.NonBatchActions[],
            });
          }
        }
        break;
      }
      default: {
        throw new UnreachableCaseError(parsedIdentifier.type);
      }
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={styles.root}>
        <div className={styles.groups}>
          {item.groups.map(g => (
            <div key={g.identifier}>
              <Group key={g.identifier} group={g} />
              <GroupPlaceholder key={buildGroupPlaceholderIdentifier(g.identifier)} prevGroupIdentifier={g.identifier} />
            </div>
          ))}
        </div>
        <AddItemButton label={'Add Concept'} onClick={onAddGroup} />
        <Settings />
      </div>
    </DragDropContext>
  );
};

function findGroupEntry(groups: SurveyAIEGroup[], entryIdentifier: string) {
  for (const group of groups) {
    for (const entry of group.entries) {
      if (entry.identifier === entryIdentifier) {
        return {
          group,
          entry,
        };
      }
    }
  }
}