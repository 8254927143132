import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

export const useEdgeLoginPath = () => {
  const location = useLocation();

  return useMemo(() => {
    const returnPath = `${location.pathname}${location.search}${location.hash}`;
    const redirectUrl = `${process.env.FRONT_BASE_URL}${returnPath}`;

    const qs = queryString.stringify({
      platformId: process.env.EDGE_PLATFORM_ID,
      redirectUrl,
    });

    return `${process.env.EDGE_BASE_URL}?${qs}`;
  }, [location]);
};