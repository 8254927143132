import { useCallback, useMemo, useState } from 'react';
import { useTargetProductProfileData, useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import { SurveyTemplate } from '@/types';
import { CurrencyLegacy } from '@/types/survey.currency';

export const useCanSaveDrugPrice = (value: SurveyTemplate.DrugPrice) => {

  return useMemo(() => {

    return !!value.currency &&
      validate(value.low) &&
      validate(value.medium) &&
      validate(value.high) &&
      value.high > value.medium &&
      value.medium > value.low;

    function validate(v: number) {
      return !!v;
    }
  }, [value]);

};

export const useTemplateDrugPrice = () => {
  const [templateData] = useTargetProductProfileData();
  const [drugPrice, setDrugPrice] = useState(templateData.drugPrice);
  const [_, dispatch] = useSurveyBuilderState();

  const onSubmit = useCallback(() => {
    dispatch({
      type: 'template-drug-price-updated',
      value: drugPrice,
    });
  }, [
    drugPrice,
    dispatch,
  ]);

  const onCurrencyChange = useCallback((currency: CurrencyLegacy) => {
    setDrugPrice(s => ({
      ...s,
      // low: null,
      // medium: null,
      // high: null,
      currency,
    }));
  }, []);

  const onChange = useCallback((key: keyof SurveyTemplate.DrugPrice) => (value: number) => {
    setDrugPrice(s => ({
      ...s,
      [key]: value,
    }));
  }, []);

  return {
    onChange: onChange,
    onCurrencyChange,
    onSubmit,
    value: drugPrice,
  };
};