import { Button } from '@/components/Button';
import Modal from '@/components/Modal/Modal.Legacy';
import styles from './style/NewVersionModal.css';

type Props = {
  onClose: () => void;
  saveAsExisting: () => void;
  saveAsNew: () => void;
};

const title = `Modify Question Versioning`;

export const NewVersionModal = ({
  onClose,
  saveAsExisting,
  saveAsNew,
}: Props) => {
  return (
    <Modal
      visible
      onClose={onClose}>
      <div className={styles.wrap}>

        <Modal.Header>{title}</Modal.Header>

        <Modal.Body className={styles.body}>
          <div className={styles.line}>
            If your edits do not materially change the meaning of the question,
            please select <b>Update Existing Question</b>, and all historical responses
            will be transferred to the question.
          </div>
          <div className={styles.line}>
            If these edits do materially change the meaning of the question,
            please select <b>Save As New Question</b>, and all respondents will see
            the new question going forward. Existing responses will still be
            accessible in a previous version of the survey.
          </div>
        </Modal.Body>

        <Modal.ButtonSet className={styles.btns}>
          <Button
            className={styles.btn}
            color="secondary"
            onClick={saveAsNew}
            variant="brick">
            Save as New Question
          </Button>
          <Button
            className={styles.btn}
            color="primary"
            onClick={saveAsExisting}
            variant="brick">
            Update Existing Question
          </Button>
        </Modal.ButtonSet>

      </div>
    </Modal>
  );
};

export default NewVersionModal;