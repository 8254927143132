import { memo, useCallback } from 'react';
import type { MatrixMultiselectQuestion } from '@/types/survey';
import MatrixRows from './Rows';
import Options from './Options';
import { SurveyQuestionLogic } from './Logic';
import { SurveyQuestionClassifications } from './Tagging';
import {
  useGetRowDisplayLogic,
  useGetOptionDisplayLogic,
  useQuestionClassifications,
  useQuestionLogic,
} from './hooks';
import styles from './style/MatrixQuestion.css';
import { SurveySettings } from './Settings';
import { SurveyMetadata } from './Metadata';

type Props = {
  item: MatrixMultiselectQuestion.Question;
};

export const MatrixMultiselectPreview = ({ item }: Props) => {
  const questionLogic = useQuestionLogic(item.base.identifier);
  const questionClassifications = useQuestionClassifications(item.base.identifier);

  const getRowDisplayLogic = useGetRowDisplayLogic(item.base.identifier);
  const getOptionDisplayLogic = useGetOptionDisplayLogic(item.base.identifier);

  const renderRowExtras = useCallback((row: MatrixMultiselectQuestion.Row) => {
    const items = [
      row.metadata.isOpenEnded && 'Open-ended',
      row.metadata.isAnchored && 'Anchored',
    ].filter(Boolean);
    const displayLogic = getRowDisplayLogic(row);

    return (
      <>
        <SurveyMetadata items={items} />
        {displayLogic}
      </>
    );
  }, [getRowDisplayLogic]);

  const renderOptionExtras = useCallback((option: MatrixMultiselectQuestion.Option) => {
    const displayLogic = getOptionDisplayLogic(option);

    return (
      <>
        {displayLogic}
      </>
    );
  }, [getOptionDisplayLogic]);

  return (
    <>
      <div className={styles.header}>Rows</div>
      <div className={styles.rows}>
        <MatrixRows
          item={item}
          renderRowExtras={renderRowExtras} />
      </div>
      <div className={styles.header}>Choices</div>
      <Options
        item={item}
        renderOptionExtras={renderOptionExtras} />
      <Settings settings={item.settings} />
      <SurveyQuestionLogic items={questionLogic} />
      <SurveyQuestionClassifications items={questionClassifications} />
    </>
  );
};

type SettingsProps = {
  settings: MatrixMultiselectQuestion.Settings;
};

const Settings = memo(({ settings }: SettingsProps) => {

  return (
    <SurveySettings>
      {settings.scaleDisplayFrequency && <>Show scale every {settings.scaleDisplayFrequency} rows</>}
      {settings.orderRowsBasedOnSource && <>Order rows based on source question</>}
      {settings.randomizeRows && <>Randomize rows</>}
      {settings.randomizeOptions && <>Randomize choices</>}
      {(settings.minimumSelections !== null || settings.maximumSelections !== null) &&
        <>
          {[
            settings.minimumSelections !== null && `Min. ${settings.minimumSelections} selection(s)`,
            settings.maximumSelections !== null && `Max. ${settings.maximumSelections} selection(s)`,
          ].filter(Boolean).join(', ')}
        </>
      }
      {settings.display &&
        <>
          {{
            grid: 'Always show respondents grid form',
            list: 'Always show respondents list form',
          }[settings.display]}
        </>}
      {{
        column: 'Group answers by column',
        row: 'Group answers by row',
      }[settings.groupBy]}
    </SurveySettings>
  );
});