import { useEffect, useState } from 'react';
import { SurveyFormScreenState } from '@/types/survey';
import { SurveyFormScreenContext, useSurveyFormMetadata } from '@containers/SurveyForm/Context';

type Props = {
  state: SurveyFormScreenState;
} & ChildrenProps;

export const FormContainer = ({
  ...props
}: Props) => {
  const [state, setState] = useState<SurveyFormScreenState>(props.state);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [state.ordinal]);

  return (
    <>
      <SurveyFormScreenContext.Provider value={[state, setState]}>
        {props.children}
      </SurveyFormScreenContext.Provider>
    </>
  );
};

export default FormContainer;