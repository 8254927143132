import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import Color from 'color';
import type * as API from '@api/interfaces';
import type { ConferenceTagType } from '@enums';

export namespace OngoingDuration {
  export type Context = {
    seconds: string;
    start: Date;
    minutes: string;
  };
}

export namespace Tagging {
  export type Context = {
    discard: (identifier: string) => void;
    focus: string;
    insert: () => void;
    items: Tag[];
    mutation: UseMutationResult<API.Conferences.Tags.SaveTag.Response, unknown, MutationParams, MutationContext>;
    queries: {
      available: UseQueryResult<API.Conferences.Tags.GetAvailableTags.Response['tags']>;
    };
    popup: boolean;
    replace: (items: Tag[]) => void;
    remove: (identifier: string) => void;
    setFocus: (identifier: string) => void;
    setValue: Set;
  };

  export type MutationParams = {
    identifier: string;
    item: Omit<Tagging.Tag, 'identifier'>;
  };

  export type MutationContext = {
    tagId?: number;
  } & IConferenceIdentifier;

  export type Tag = {
    color: string;
    end: Date;
    id: number;
    identifier: string;
    name: string;
    start: Date;
    typeId: ConferenceTagType;
  };

  type Params = Omit<Tag, 'createdBy' | 'end' | 'typeId'>;

  export type Set<T extends Params = Params, K extends keyof T = keyof T> = (field: K, value: T[K]) => void;

  export type SetValue = Set<Omit<Tag, 'createdBy' | 'typeId'>>;
}

export namespace Tags {
  export const baseColors = [
    {
      highlight: '#CCECF9',
      menu: '#B4DFF1',
      hover: '#9CD3EA',
    },
    {
      highlight: '#DCDCF8',
      menu: '#C8C9F4',
      hover: '#ABACEB',
    },
    {
      highlight: '#F7C0CD',
      menu: '#EEAABA',
      hover: '#EF819C',
    },
    {
      highlight: '#E4F1CF',
      menu: '#D6E8B8',
      hover: '#C1DD93',
    },
    {
      highlight: '#C6F5F5',
      menu: '#AEF3F3',
      hover: '#8DECEC',
    },
    {
      highlight: '#F7DAD4',
      menu: '#EFB5AA',
      hover: '#E7917F',
    },
  ];

  export const LIGHTEN_FACTOR = .07;
  const lightenedColors = baseColors.map(c => ({
    highlight: new Color(c.highlight).lighten(LIGHTEN_FACTOR).hex(),
    menu: new Color(c.menu).lighten(LIGHTEN_FACTOR).hex(),
    hover: new Color(c.hover).lighten(LIGHTEN_FACTOR).hex(),
  }));

  const darkenedColors = baseColors.map(c => ({
    highlight: new Color(c.highlight).darken(LIGHTEN_FACTOR).hex(),
    menu: new Color(c.menu).darken(LIGHTEN_FACTOR).hex(),
    hover: new Color(c.hover).darken(LIGHTEN_FACTOR).hex(),
  }));

  export const tagColors = lightenedColors.concat(baseColors).concat(darkenedColors);

  export const defaultColor = tagColors[0].menu;

  export const PopperZIndex = 8;
}