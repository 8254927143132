import img1 from 'static/images/website/about/e-g.png';
import img2 from 'static/images/website/about/e-p.png';
import img3 from 'static/images/website/about/e-b.png';
import styles from './style/Leadership.css';

type Props = unknown;

export const Leadership = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h2 className={styles.header}>Leadership</h2>
        <div className={styles.content}>
          <div className={styles.main}>
            <div className={styles.items}>
              {items.map(x =>
                <div
                  className={styles.item}
                  key={x.title}>
                  <div className={styles.graphic}>
                    <img
                      alt={x.title}
                      className={styles.img}
                      src={x.src} />
                  </div>
                  <div className={styles.info}>
                    <div className={styles.title}>{x.title}</div>
                    <div className={styles.text}>{x.text}</div>
                  </div>
                </div>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Leadership.displayName = 'About.Leadership';

const items = [{
  title: `Gunnar Gregory`,
  text: `CEO`,
  src: img1,
}, {
  title: `Patrick Waring`,
  text: `COO`,
  src: img2,
}, {
  title: `Brian Walendzinski`,
  text: `VP of Engineering`,
  src: img3,
}];