import { useMemo } from 'react';
import { Column } from 'react-table';
import { Search } from '$admin/interfaces';
import * as Columns from '../Results.Column';

export function useResultsColumns(): Column<Search.Members.Results.Item>[] {
  return useMemo(() => [
    {
      Header: Columns.Checkbox.Header,
      columns: [
        {
          Cell: Columns.Checkbox.Cell,
          Header: Columns.Checkbox.Filter,
          id: 'indeterminate',
          minWidth: 60,
          maxWidth: 18,
        },
      ],
      id: 'checkbox',
      minWidth: 60,
      maxWidth: 18,
    },
    {
      Header: Columns.Name.Header,
      accessor: 'name',
      columns: [
        {
          Cell: Columns.Name.Cell,
          Header: Columns.Name.Filter,
          id: 'name-filter',
          minWidth: 150,
          maxWidth: 55,
        },
      ],
      id: 'name',
      minWidth: 150,
      maxWidth: 55,
    },
    {
      Header: Columns.Role.Header,
      accessor: 'professionalRole',
      columns: [
        {
          Cell: Columns.Role.Cell,
          Header: Columns.Role.Filter,
          id: 'role-filter',
          minWidth: 150,
          maxWidth: 50,
        },
      ],
      id: 'professionalRole',
      minWidth: 150,
      maxWidth: 50,
    },
    {
      Header: Columns.ProjectRating.Header,
      accessor: 'projectRating',
      columns: [
        {
          Cell: Columns.ProjectRating.Cell,
          Header: Columns.ProjectRating.Filter,
          id: 'projectRating-filter',
          minWidth: 150,
          maxWidth: 45,
        },
      ],
      id: 'projectRating',
      minWidth: 150,
      maxWidth: 45,
    },
    {
      Header: Columns.Specialty.Header,
      accessor: 'specialty',
      columns: [
        {
          Cell: Columns.Specialty.Cell,
          Header: Columns.Specialty.Filter,
          id: 'specialty-filter',
          minWidth: 200,
          maxWidth: 60,
        },
      ],
      id: 'specialty',
      minWidth: 200,
      maxWidth: 60,
    },
    {
      Header: Columns.Subspecialty.Header,
      accessor: 'subspecialty',
      columns: [
        {
          Cell: Columns.Subspecialty.Cell,
          Header: Columns.Subspecialty.Filter,
          id: 'subspecialty-filter',
          minWidth: 200,
          maxWidth: 60,
        },
      ],
      id: 'subspecialty',
      minWidth: 200,
      maxWidth: 60,
    },
    {
      Header: Columns.Rate.Header,
      accessor: 'rate',
      columns: [
        {
          Cell: Columns.Rate.Cell,
          Header: Columns.Rate.Filter,
          id: 'rate-filter',
          minWidth: 80,
          maxWidth: 25,
        },
      ],
      id: 'rate',
      minWidth: 80,
      maxWidth: 25,
    },
    {
      Header: Columns.Country.Header,
      accessor: 'country',
      columns: [
        {
          Cell: Columns.Country.Cell,
          Header: Columns.Country.Filter,
          id: 'country-filter',
          minWidth: 80,
        },
      ],
      id: 'country',
      minWidth: 80,
    },
    {
      Header: Columns.History.Header,
      accessor: 'history',
      columns: [
        {
          Cell: Columns.History.Cell,
          Header: Columns.History.Filter,
          id: 'history-filter',
          minWidth: 80,
        },
      ],
      id: 'history',
      minWidth: 80,
    },
  ], []);
}