import { useState, useCallback } from 'react';
import { RouteGuardContext } from './Context';

export const RouteGuardContainer = (props: ChildrenProps) => {
  const [shouldSkipGuard, setShouldSkipGuard] = useState(false);

  const setSkipGuard = useCallback(() => {
    setShouldSkipGuard(true);
  }, []);
  return (
    <RouteGuardContext.Provider value={{ shouldSkipGuard, setSkipGuard }}>
      {props.children}
    </RouteGuardContext.Provider>
  );
};