import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Home } from '@consts/pathname';

type LocationState = {
  from: string;
};

export const usePreviousPage = (fallback = Home) => {
  const location = useLocation<LocationState>();

  const previous = useMemo(() => location.state?.from || fallback, [location.state?.from]);

  return previous;
};