import { AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as api from '@api';
import { Integrations } from '@api/interfaces';

type Data = Integrations.Slack.GetChannels.Response['channels'];

export function useSlackChannels(
  teamId: string,
  options?: UseQueryOptions<Data, AxiosResponse>
) {

  return useQuery(['slack-channels', teamId], () => {
    return api.integrations.getSlackChannels({ teamId })
      .then(res => res.channels);
  }, options);
}