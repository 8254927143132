import { SurveyTagging } from '@/types';
import { SurveyBuilder, SurveyClassificationsBuilder } from '../interfaces';

export function classificationsReducer(state: SurveyBuilder.State, action: SurveyBuilder.Actions): SurveyClassificationsBuilder.State {

  const classifications = state.survey.classifications;

  switch (action.type) {

    case 'classification-added':
      return classifications.concat(action.payload.value);

    case 'revert-uncommitted-changes':
      return state.committed.classifications;

    default:
      return classifications;
  }

}