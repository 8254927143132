import { SurveyItemType } from '@/enums';
import type { SurveyItem } from '@/types';
import { SurveyQuestionContainer } from '@containers/SurveyBuilder.Question/QuestionContainer';
import { SurveyBuilderItemContainer } from '@/containers/SurveyBuilder/ItemContainer';
import { SurveyBuilderMessageContainer } from '@/containers/SurveyBuilder.Message';
import { SurveyBuilderAlternateImagesContainer } from '@/containers/SurveyBuilder.AIE';
import { QuestionBuilderItem } from '@/components/SurveyBuilder.Question/QuestionBuilderItem';
import { MessageBuilder } from '@/components/SurveyBuilder.Message';
import { AIEBuilder } from '@/components/SurveyBuilder.AIE';

type Props = {
  item: SurveyItem;
};

export const SurveyItemBuilder = ({ item }: Props) => {
  if (item.type === SurveyItemType.Question) {
    return (
      <SurveyBuilderItemContainer itemIdentifier={item.identifier}>
        <SurveyQuestionContainer>
          <QuestionBuilderItem />
        </SurveyQuestionContainer>
      </SurveyBuilderItemContainer>
    );
  } else if (item.type === SurveyItemType.Message) {
    return (
      <SurveyBuilderItemContainer itemIdentifier={item.identifier}>
        <SurveyBuilderMessageContainer>
          <MessageBuilder />
        </SurveyBuilderMessageContainer>
      </SurveyBuilderItemContainer>
    );
  } else if (item.type === SurveyItemType.AlternateImageExercise) {
    return (
      <SurveyBuilderItemContainer itemIdentifier={item.identifier}>
        <SurveyBuilderAlternateImagesContainer>
          <AIEBuilder />
        </SurveyBuilderAlternateImagesContainer>
      </SurveyBuilderItemContainer>
    );
  }
};

export default SurveyItemBuilder;