import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { menu, path } from '@consts';
import { useLogout } from '@containers/AppStateEffect/hooks';
import { isPlatformUser } from '@utils';
import type { MenuItem } from './interfaces';
import { memoizedSelectValidator } from './utils';
import { MenuContext, defaultValue } from './Context';

type Props = ChildrenProps;

const selectPlatformUser = (state: Store.State) => isPlatformUser(state.user);

export const AccessMenuProvider = (props: Props) => {
  const validator = useSelector(memoizedSelectValidator);
  const platform = useSelector(selectPlatformUser);

  const logout = useLogout();

  const handleLogout = useCallback(() => {
    return logout({
      sideEffects: true,
    });
  }, [logout]);

  const ctx = useMemo(() => ({
    ...defaultValue,
    account: [
      validator.admin && {
        count: 0,
        name: menu.Admin,
        icon: null,
        key: menu.Admin,
        to: path.Admin.Root,
      },
      validator.research && {
        count: 0,
        name: menu.Research,
        icon: null,
        key: menu.Research,
        to: path.Research.Members,
      },
      {
        count: 0,
        name: menu.Logout,
        icon: null,
        key: menu.Logout,
        onClick: handleLogout,
        to: platform ? path.Website.PlatformLogin : path.Website.Login,
      },
    ].filter(Boolean) as MenuItem[],
  }), [
    handleLogout,
    validator,
    platform,
  ]);

  return (
    <MenuContext.Provider value={ctx}>
      {props.children}
    </MenuContext.Provider>
  );
};

export default AccessMenuProvider;