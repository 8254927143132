import { useMemo } from 'react';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { isQuestionInCondition } from '@/containers/SurveyBuilder.Logic/utils.lookup';

export const useQuestionLogic = (questionIdentifier: string) => {
  const [state] = useSurveyBuilderState();

  const logic = useMemo(() => {
    return state.survey.logic
      .filter(item => item.conditions.some(condition => isQuestionInCondition({
        condition,
        question: { identifier: questionIdentifier },
      })));
  }, [questionIdentifier, state.survey.logic]);

  return logic;
};