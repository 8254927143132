import { useContext } from 'react';
import { useToggle } from '@utils/hooks';
import { ProjectAccessModal } from '@/components/Project.Access';
import { ProjectAccessModalContext, ProjectAccessQueryContext } from '@/components/Project.Access/Context';
import { type ModalProps } from '@/components/Modal/Modal';
import { ProjectAccessContainer } from '$admin/components/Project.Access';

type Props = {
  children: React.ReactNode;
} & IProjectId;

export const ManageAccessContainer = (props: Props) => {
  const [open, toggle] = useToggle(false);

  return (
    <ProjectAccessContainer projectId={props.projectId}>
      <ProjectAccessModalContext.Provider value={toggle}>
        {props.children}
        <ManageAccessModal
          projectId={props.projectId}
          onClose={toggle}
          open={open} />
      </ProjectAccessModalContext.Provider>
    </ProjectAccessContainer>
  );
};

ManageAccessContainer.displayName = 'Modal.Project.ManageAccess.Container';

type ManageAccessModalProps =
  Pick<ModalProps, 'onClose' | 'open'> & IProjectId;

const ManageAccessModal = (props: ManageAccessModalProps) => {
  const ctx = {
    query: useContext(ProjectAccessQueryContext),
  };

  if (!props.open) return null;

  return (
    <ProjectAccessModal
      projectId={props.projectId}
      items={ctx.query.data?.items || []}
      onClose={props.onClose}
      onSuccess={props.onClose}
      open />
  );
};

ManageAccessModal.displayName = 'Modal.Project.ManageAccess';