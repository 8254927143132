import { Briefcase } from 'react-feather';
import CountBadge from './CountBadge';

type Props = {
  count: number;
  to: string;
};

const ProjectsCount = (props: Props) => (
  <CountBadge
    count={props.count}
    icon={<Briefcase size={20} />}
    to={props.to} />
);

export { ProjectsCount };
export default ProjectsCount;