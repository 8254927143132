import { useCallback, useMemo } from 'react';
import { FormButtons } from '@presentation/FormButtons';
import { useRunQuotaValidations } from '@/containers/SurveyBuilder.Quota';
import { useSubmitSurveyDraft, useSurveyQuotasState } from '@/containers/SurveyBuilder';
import { useSurveyBasicQuotaState } from '@/containers/SurveyBuilder.Quota';

type Props = {
  className?: string;
};

export const BasicQuotaFormButtons = ({ className }: Props) => {

  const [{ editing }, dispatchQuotas] = useSurveyQuotasState();
  const [state] = useSurveyBasicQuotaState();
  const submitDraft = useSubmitSurveyDraft();
  const runValidations = useRunQuotaValidations();

  const canSave = useMemo(() => {
    return !!state.data.items.length;
  }, [state.data.items]);

  const handleSave = useCallback(() => {
    dispatchQuotas({
      type: 'save-quota-item',
      value: state,
    });
    submitDraft();
  }, [dispatchQuotas, state, submitDraft]);

  const handleSubmit = useCallback(() => {
    const valid = runValidations();

    if (valid) {
      handleSave();
    }
  }, [handleSave, runValidations]);

  const handleCancel = useCallback(() => {
    dispatchQuotas({
      identifier: editing.identifier,
      type: 'toggle-quota-editing-off',
    });
  }, [dispatchQuotas, editing]);

  return (
    <FormButtons
      className={className}
      disabled={!canSave}
      onCancel={handleCancel}
      onSubmit={handleSubmit} />
  );
};