import { memo } from 'react';
import { useSelectUser } from '@containers/Store';
import { useIsLocationAdminRoute } from '@routes/hooks/useIsLocationAdminRoute';
import { cx, hasInternalAdminRole, isAdmin, isExpert, isNonAdminClient, isPlatformUser } from '@utils';
import landscape from 'static/images/logo-sentiment-landscape.svg?url';
import horizonaltrinity from 'static/images/invite/logo-trinity.svg?url';
import icontrinity from 'static/images/logo-trinity-icon.svg?url';
import square from 'static/images/logo-sentiment-square.png';
import white from 'static/images/logo-sentiment-white.png';
import styles from './style/Logo.css';

type SquareProps = {
  size: number;
} & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

export const Square = memo(({ size = 30, ...props }: SquareProps) => {
  return (
    <img
      {...props}
      src={square}
      style={{ width: size, height: size }} />
  );
});

Square.displayName = 'Branding.Logo.Square';

type LandscapeProps = {
  className?: string;
};

export const Landscape = memo((props: LandscapeProps) => {
  const classes = cx(styles.root, styles.landscape, props.className);

  return (
    <div className={classes}>
      <img
        className={classes}
        draggable={false}
        src={landscape} />
    </div>
  );
});

Landscape.displayName = 'Branding.Logo.Landscape';

export const Responsive = (props: unknown) => {
  const user = useSelectUser();
  const adminRoute = useIsLocationAdminRoute();
  const clientUser = isNonAdminClient(user);
  const adminUser = isAdmin(user);

  const classes = {
    lg: styles.lg,
    sm: styles.sm,
  };

  const platform = clientUser || (adminUser && !adminRoute);

  return (
    <div className={styles.responsive}>
      {platform
        ? <IconTrinity
          className={classes.sm}
          size={32} />
        : <Square
          className={classes.sm}
          size={32} />}
      {platform
        ? <HorizonalTrinity className={classes.lg} />
        : <Landscape className={classes.lg} />}
    </div>
  );
};

Responsive.displayName = 'Branding.Logo.Responsive';

type WhiteProps = {
  className?: string;
};

export const White = memo((props: WhiteProps) => {
  const classes = cx(styles.root, styles.landscape, props.className);

  return (
    <div className={classes}>
      <img
        className={classes}
        draggable={false}
        src={white}
        alt="logo" />
    </div>
  );
});

White.displayName = 'Branding.Logo.White';

export const HorizonalTrinity = memo((props: LandscapeProps) => {
  const classes = cx(styles.root, styles.landscape, styles.trinity, props.className);

  return (
    <div className={classes}>
      <img
        className={classes}
        draggable={false}
        src={horizonaltrinity} />
    </div>
  );
});

HorizonalTrinity.displayName = 'Branding.Logo.Horizonal.Trinity';

const IconTrinity = memo(({ size, ...props }: SquareProps) => {
  return (
    <img
      {...props}
      src={icontrinity}
      style={{ width: size, height: size }} />
  );
});

IconTrinity.displayName = 'Branding.Logo.Icon.Trinity';