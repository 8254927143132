import { useCallback, useMemo } from 'react';
import { parseSurveyRichText } from '@/containers/Survey/utils/rich-text';
import { RangeIndicator } from '@presentation/RangeIndicator';
import { cx } from '@utils';
import { MatrixSliderQuestion } from '@/types';
import { formatSurveyNumber } from '@utils/survey';
import { SurveyMatrixSliderHeaderProps } from './interfaces';
import styles from './style/Header.css';

function generateRangeValues({ numberSettings, slider: opts }: MatrixSliderQuestion.Settings<number>) {
  const steps = Math.min(50, (opts.maxValue - opts.minValue) / opts.increment);
  const arr = [];
  for (let i = 0; i <= steps; i++) {
    if (i == 0) {
      arr.push(formatSurveyNumber(opts.minValue, numberSettings));
    } else if (i == steps) {
      arr.push(formatSurveyNumber(opts.maxValue, numberSettings));
    } else {
      arr.push('');
    }
  }
  return arr;
}

export const SurveyMatrixSliderHeader = (props: SurveyMatrixSliderHeaderProps) => {
  const rangeValues = useMemo(() => generateRangeValues(props.settings), [props.settings]);

  const renderRange = useCallback(() => {
    const className = cx(styles.indicator, {
      [styles.labelIndicator]: !!props.settings.slider.label,
    });
    return (
      <div className={styles.range}>
        <RangeIndicator
          className={className}
          values={rangeValues} />
      </div>
    );
  }, [rangeValues, props.settings.slider.label]);

  return (
    <div className={cx(styles.root, props.className)}>
      {props.settings.slider.label &&
        <div className={styles.title}>
          {parseSurveyRichText(props.settings.slider.label)}
        </div>
      }
      {renderRange()}
    </div>
  );
};

export default SurveyMatrixSliderHeader;