import type { LocalTrackPublication, Participant, RemoteTrackPublication, Track } from 'twilio-video';
import { isCameraTrack } from '@/components/Conference.Video/constants';
import { AudioTrack } from './AudioTrack';
import { VideoTrack } from './VideoTrack';
import { useTrack } from './hooks/useTrack';

type PublicationProps = {
  publication: LocalTrackPublication | RemoteTrackPublication;
  participant: Participant;
  isLocal: boolean;
  disableAudio?: boolean;
  disableVideo?: boolean;
  videoPriority?: Track.Priority;
  isMain: boolean;
};

export function Publication(props: PublicationProps) {
  const { publication, isLocal, disableAudio, disableVideo, videoPriority, isMain } = props;
  const track = useTrack(publication);

  if (!track) return null;

  switch (track.kind) {
    case 'video':
      return disableVideo ? null : (
        <VideoTrack
          track={track}
          priority={videoPriority}
          isLocal={isCameraTrack(track.name) && isLocal}
          isMain={isMain} />
      );
    case 'audio':
      return disableAudio ? null : <AudioTrack track={track} />;
    default:
      return null;
  }
}

export default Publication;