import Button from '@/components/Button';
import { OverviewProps } from './interfaces';
import styles from './style/Overview.css';

export default function GettingStarted({ navigation }: OverviewProps) {
  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h1 className={styles.title}>{`Set up a payout account to receive compensation for projects you participate in.`}</h1>
        <div className={styles.copy}>{`In a few short steps you can connect a payout account on Sentiment to be paid for projects you complete.`}</div>
        <div className={styles.navigation}>
          <Button
            variant="brick"
            title="Add Payout Account"
            onClick={navigation.next} />
        </div>
      </div>
    </div>
  );
}

export { GettingStarted };