import { Children, forwardRef, useCallback, useImperativeHandle, useMemo, useEffect, useState } from 'react';
import Label from './Label';
import SimpleAccordion from './SimpleAccordion';
import type { AccordionProps, AccordionRefObject } from './interfaces';

type Props =
  AccordionProps;

const Accordion = forwardRef<AccordionRefObject, Props>(({
  classes = {},
  collapsed = false,
  grows = true,
  height = 60,
  label = '',
  showCount = true,
  tooltipText = '',
  ...props
}, ref) => {
  const [count, setCount] = useState(Children.count(props.children));
  const [open, setOpen] = useState(!collapsed);
  const setVisibility = useCallback((visible: boolean) => setOpen(visible), [setOpen]);
  const toggleVisibility = useCallback(() => {
    if (count > 0) setOpen(!open);
  }, [open, setOpen, count]);

  useImperativeHandle(ref, () => ({ toggleVisibility }), [toggleVisibility]);

  const nextCount = useMemo(() => {
    return Children.count(props.children);
  }, [props.children]);

  useEffect(() => {
    if (!collapsed && nextCount !== count && nextCount > 0) {
      setVisibility(true);
    }
  }, [
    collapsed,
    nextCount,
    count,
    setVisibility,
  ]);

  useEffect(() => {
    if (nextCount !== count) {
      setCount(nextCount);
    }
  }, [nextCount, count]);

  const renderLabel = useCallback(() => {
    if (typeof label !== 'function') {
      return (
        <Label
          className={classes.label}
          count={count}
          showCount={showCount}
          tooltipText={tooltipText}>
          {label}
        </Label>
      );
    } else {
      return label(count);
    }
  }, [
    count,
    label,
    showCount,
    tooltipText,
    classes.label,
  ]);

  return (
    <SimpleAccordion
      className={props.className}
      classes={classes}
      grows={grows}
      height={height}
      label={renderLabel()}
      open={open}
      toggleOpen={toggleVisibility}>
      {props.children}
    </SimpleAccordion>
  );
});

export { Accordion };
export default Accordion;
