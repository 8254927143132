import { Fragment, useCallback, useMemo } from 'react';
import { useTopicTagsContext } from '@containers/PostCreation/hooks/usePostCreationContext';
import { Chip } from '@presentation/Chip';
import { cx } from '@utils';
import { TopicInput } from './Topics.Input';
import styles from './style/Topics.Tags.css';

type Props = {
  buttonPosition?: 'before-tags' | 'after-tags';
  className?: string;
  classes?: {
    root?:    string;
    tags?:    string;
    input?:   string;
    editing?: string;
    edit?:    string;
    cancel?:  string;
  };
  maxItems?:  number;
};

export const TopicTags = ({ buttonPosition, classes = {}, ...props }: Props) => {
  const [topic] = useTopicTagsContext();

  const classnames = {
    root: cx(props.className, classes.root),
  };

  return (
    <div className={classnames.root}>
      {topic.editing &&
        <TopicInput classes={{
          root: classes.editing,
          input: classes.input,
          cancel: classes.cancel,
        }} />}
      {!topic.editing &&
        <TopicTagItems
          buttonPosition={buttonPosition}
          classes={{
            root: classes.tags,
            edit: classes.edit,
          }}
          maxItems={props.maxItems} />}
    </div>
  );
};

TopicTags.displayName = 'PostCreation.Topic.Tags';

type TopicTagItemsProps = {
  classes?: {
    root?: string;
    edit?:  string;
  };
} & Pick<Props, 'buttonPosition' | 'maxItems'>;

const TopicTagItems = ({ buttonPosition = 'after-tags', classes, ...props }: TopicTagItemsProps) => {
  const [topic] = useTopicTagsContext();

  const canAddTopic = useMemo(() => {
    return topic.items.length < props.maxItems;
  }, [
    props.maxItems,
    topic.items,
  ]);

  const classnames = {
    wrap: cx(styles.wrap, classes.root),
  };

  return (
    <div className={styles.root}>
      <div className={classnames.wrap}>
        {buttonPosition === 'after-tags' && <TopicTagsList />}
        <AddTopicButton
          className={classes.edit}
          disabled={!canAddTopic} />
        {buttonPosition === 'before-tags' && <TopicTagsList />}
      </div>
    </div>
  );
};

const defaultProps = {
  classes: {},
  maxItems: 5,
};

TopicTagItems.defaultProps = defaultProps;
TopicTagItems.displayName = 'PostCreation.Topic.TagItems';

const TopicTagsList = (props: unknown) => {
  const [topic, dispatch] = useTopicTagsContext();

  const removeTopic = useCallback((topicId: number) => () => {
    dispatch({
      topicId,
      type: 'remove-topic',
    });
  }, [dispatch]);

  return (
    <Fragment>
      {topic.items.map(x =>
        <Chip
          className={styles.tag}
          color="selected"
          key={x.id}>
          <Chip.Body.Action
            onClick={removeTopic(x.id)}
            variant="remove">
            {x.name}
          </Chip.Body.Action>
        </Chip>)}
    </Fragment>
  );
};

TopicTagsList.displayName = 'PostCreation.Topic.TagsList';

type AddTopicButtonProps = {
  className?: string;
  disabled?: boolean;
};

const AddTopicButton = (props: AddTopicButtonProps) => {
  const [topic, dispatch] = useTopicTagsContext();

  const addTopic = useCallback(() => {
    if (props.disabled) return;

    dispatch({
      type: 'toggle-topic-editing',
    });
  }, [
    dispatch,
    props.disabled,
  ]);

  const classnames = {
    root: cx(styles.addTopic, {
      [styles.disabled]: props.disabled,
    }, props.className),
  };

  return (
    <div
      className={classnames.root}
      onClick={addTopic}>
      + Add a topic
    </div>
  );
};

TopicTagsList.displayName = 'PostCreation.Topic.AddTopicButton';