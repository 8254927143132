import { useCallback, useEffect, useState, useRef } from 'react';
import type { CreateLocalAudioTrackOptions, LocalVideoTrack, LocalAudioTrack } from 'twilio-video';
import Video from 'twilio-video';
import type { GaussianBlurBackgroundProcessor } from '@twilio/video-processors';
import { useConferenceAudioCuePreferences, useConferenceCameraPreferences } from '@/components/Conference/hooks';
import { generateCameraTrackName, generateMicrophoneTrackName } from '@/components/Conference.Video/constants';
import { isBlurBackgroundSupported, createGaussianBlurBackgroundProcessor } from '../video-processors';

export function useLocalTracks(videoDevice: React.MutableRefObject<MediaDeviceInfo>) {
  const [audioTrack, setAudioTrack] = useState<LocalAudioTrack>();
  const [videoTrack, setVideoTrack] = useState<LocalVideoTrack>();
  const blurBackgroundProcessor = useRef<GaussianBlurBackgroundProcessor>();

  const [audioCuePreferences] = useConferenceAudioCuePreferences();
  const [cameraPreferences] = useConferenceCameraPreferences();

  const getLocalAudioTrack = useCallback((microphoneDevice: MediaDeviceInfo): Promise<LocalAudioTrack> => {
    const options: CreateLocalAudioTrackOptions = {
      name: generateMicrophoneTrackName(),
      deviceId: microphoneDevice?.deviceId,
      noiseCancellationOptions: !audioCuePreferences.disableAudioFilter ? {
        sdkAssetsPath: `https://cdn.app.sentimentglobal.com/js/@twilio/krisp-audio-plugin@1.0.0`,
        vendor: 'krisp',
      } : null,
    };
    return Video.createLocalAudioTrack(options)
      .then(newTrack => {
        setAudioTrack(newTrack);
        return newTrack;
      });
  }, [audioCuePreferences?.disableAudioFilter]);

  useEffect(() => {
    if (audioTrack?.noiseCancellation) {
      if (audioCuePreferences.disableAudioFilter) {
        audioTrack.noiseCancellation.disable();
      } else {
        audioTrack.noiseCancellation.enable();
      }
    }
  }, [audioCuePreferences?.disableAudioFilter, audioTrack]);

  const getLocalVideoTrack = useCallback(async (): Promise<LocalVideoTrack> => {
    const options: Video.CreateLocalTrackOptions = {
      frameRate: 24,
      height: 720,
      width: 1280,
      name: generateCameraTrackName(),
      facingMode: 'user',
      deviceId: videoDevice.current?.deviceId,
      groupId: videoDevice.current?.groupId,
    };
    return Video.createLocalVideoTrack(options)
      .then(newTrack => {
        setVideoTrack(newTrack);
        return newTrack;
      });
  }, [videoDevice]);

  useEffect(() => {
    if (!videoTrack || !isBlurBackgroundSupported()) return;
    try {
      if (cameraPreferences.blurBackground) {
        if (!blurBackgroundProcessor.current) {
          blurBackgroundProcessor.current = createGaussianBlurBackgroundProcessor();
          blurBackgroundProcessor.current.loadModel()
          .then(() => {
            videoTrack.addProcessor(blurBackgroundProcessor.current, {
              inputFrameBufferType: 'video',
              outputFrameBufferContextType: 'webgl2',
            });
          });
        } else {
          videoTrack.addProcessor(blurBackgroundProcessor.current, {
            inputFrameBufferType: 'video',
            outputFrameBufferContextType: 'webgl2',
          });
        }
      } else {
        if (blurBackgroundProcessor.current && videoTrack.processor) {
          videoTrack.removeProcessor(blurBackgroundProcessor.current);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, [cameraPreferences.blurBackground, videoTrack]);

  const removeLocalAudioTrack = useCallback(() => {
    if (audioTrack) {
      audioTrack.stop();
      setAudioTrack(undefined);
    }
  }, [audioTrack]);

  const removeLocalVideoTrack = useCallback(() => {
    if (videoTrack) {
      videoTrack.stop();
      setVideoTrack(undefined);
    }
  }, [videoTrack]);

  const refreshLocalVideoTrack = useCallback(() => {
    if (videoTrack) {
      videoTrack.stop();
      getLocalVideoTrack();
    }
  }, [videoTrack, getLocalVideoTrack]);

  const localTracks = [audioTrack, videoTrack].filter(t => t !== undefined);

  return {
    localTracks,
    getLocalAudioTrack,
    getLocalVideoTrack,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    refreshLocalVideoTrack,
  };
}