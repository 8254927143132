import { memo, useCallback, Fragment } from 'react';
import { Field, FieldProps, useFormikContext } from 'formik';
import { cx } from '@utils';
import { Select, OnSelectChange, DescriptorLike } from '$admin/components/Select';
import styles from './style.css';

type BaseProps = {
  classes?: {
    label?: string;
    value?: string;
  };
  editing: boolean;
  label: string;
  name: string;
  displayValue: React.ReactChild;
  options: Record<string, string> | DescriptorLike[];
  onChange: OnSelectChange;
};

type Props<T extends string | number = string> = {
  onChange?: (value: T) => void;
} & Omit<BaseProps, 'onChange'>;

export const FieldSelect = (props: Props<string>) => {
  return !props.editing
    ? <Display {...props} />
    : <FieldSelectEditable {...props} />;
};

export const FieldSelectNumber = (props: Props<number>) => {
  return !props.editing
    ? <Display {...props} />
    : <FieldSelectNumberEditable {...props} />;
};

const FieldSelectEditable = ({ onChange, ...props }: Props<string>) => {
  const { setFieldValue } = useFormikContext();

  const handleChange: OnSelectChange<string> = useCallback(e => {
    const value = e.target.value;
    setFieldValue(props.name, value);
    onChange?.(value);
  }, [
    onChange,
    props.name,
    setFieldValue,
  ]);

  return (
    <Editable
      {...props}
      onChange={handleChange} />
  );
};

const FieldSelectNumberEditable = ({ onChange, ...props }: Props<number>) => {
  const { setFieldValue } = useFormikContext();

  const handleChange: OnSelectChange<string> = useCallback(e => {
    const value = +e.target.value;
    setFieldValue(props.name, value);
    onChange?.(value);
  }, [
    onChange,
    props.name,
    setFieldValue,
  ]);

  return (
    <Editable
      {...props}
      onChange={handleChange} />
  );
};

const Editable = ({ classes = {}, label, name, onChange, options }: BaseProps) => {
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <div>
          <div className={cx(styles.label, classes.label)}>
            {label} {meta.touched && meta.error && <span className={styles.formError}>{meta.error}</span>}
          </div>
          <div className={styles.value}>
            <Select
              className={styles.select}
              name={field.name}
              onChange={onChange}
              onBlur={field.onBlur}
              value={(field.value ?? '') as string}
              options={options} />
          </div>
        </div>
      )}
    </Field>
  );
};

export const Display = memo(({ classes = {}, label, displayValue }: Pick<Props, 'classes' | 'label' | 'displayValue'>) => (
  <Fragment>
    <div className={cx(styles.label, classes.label)}>{label}</div>
    <span className={cx(styles.selectValue, classes.value)}>
      {displayValue ?? '-'}
    </span>
  </Fragment>
));