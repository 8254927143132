import { useMemo } from 'react';
import { MultipleChoiceQuestion } from '@/types/survey';
import { OptionContextMenu } from './Option.ContextMenu';
import { useAnchoredOptionMenuItem, useDisqualifyOptionItem, useOpenEndedOptionMenuItem } from './hooks/option-menu';

type Props = {
  option: MultipleChoiceQuestion.Option;
  question: MultipleChoiceQuestion.Question;
};

export const MultipleChoiceOptionContextMenu = (props: Props) => {
  const disqualifyingItems = useDisqualifyOptionItem(props);

  const openEndedMenuItem = useOpenEndedOptionMenuItem({
    option: props.option,
    questionIdentifier: props.question.base.identifier,
  });

  const anchoredMenuItem = useAnchoredOptionMenuItem({
    option: props.option,
    question: props.question,
  });

  const items = useMemo(() => {
    return [
      openEndedMenuItem,
      ...disqualifyingItems,
      anchoredMenuItem,
    ].filter(Boolean);
  }, [
    anchoredMenuItem,
    disqualifyingItems,
    openEndedMenuItem,
  ]);

  return (
    <OptionContextMenu
      option={props.option}
      items={items}
      excludeFindAndReplace={false} />
  );
};