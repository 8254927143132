import { useCallback, useMemo } from 'react';
import type { SurveyActionType } from '@enums/Survey';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder/hooks';
import { cx } from '@utils';
import { useExcludeQuestionChoiceItems, useSurveyLogicItemState } from '@containers/SurveyBuilder.Logic';
import { QuestionDropdown, OptionsDropdown, RowDropdown } from '@presentation/SurveyBuilder';
import { OptionsQuestion, RowsQuestion } from '@containers/Survey/utils/questions';
import type { SurveyLogic, SurveyQuestionMatrixRow } from '@/types/survey';
import styles from './style/Builder.Action.css';

type FilterOptionActionType =
  | SurveyActionType.DisplayQuestionChoice
  | SurveyActionType.ExcludeQuestionChoice;

export function ExcludeQuestionChoiceDropdown() {
  const items = useExcludeQuestionChoiceItems();

  const [item, dispatch] = useSurveyLogicItemState();
  const action = item.action as SurveyLogic.Action<FilterOptionActionType>;

  const selectedItem = useMemo(() => {
    return items.find(f => f.base.identifier === action.question.identifier);
  }, [items, action.question.identifier]);

  const handleSelect = useCallback((identifier: string) => {
    dispatch({
      type: 'action-updated',
      payload: {
        question: { identifier },
        option: null,
        row: null,
        type: action.type,
      },
    });
  }, [dispatch, action.type]);

  return (
    <>
      <div className={styles.dropdown}>
        <QuestionDropdown
          items={items}
          onSelect={handleSelect}
          value={selectedItem} />
      </div>
      {action.question.identifier && selectedItem?.hasRows
        ? (
          <div className={cx(styles.dropdown, styles.choice)}>
            <QuestionRowDropdown />
          </div>
          )
        : null}
      {selectedItem?.hasOptions && selectedItem?.hasRows ? <>or</> : null}
      {action.question.identifier && selectedItem?.hasOptions
        ? (
          <div className={cx(styles.dropdown, styles.choice)}>
            <QuestionOptionDropdown />
          </div>
          )
        : null}
    </>
  );
}

function QuestionOptionDropdown() {
  const [item, dispatch] = useSurveyLogicItemState();
  const action = item.action as SurveyLogic.Action<FilterOptionActionType>;

  const question = useActionQuestion() as OptionsQuestion;

  const selectedOption = question.options?.find(f => f.base.identifier === action.option?.identifier);

  const handleUpdate = useCallback((identifier: string) => {
    dispatch({
      type: 'action-updated',
      payload: {
        question: { identifier: question.base.identifier },
        option: { identifier },
        row: null,
        type: action.type,
      },
    });
  }, [
    question.base.identifier,
    action.type,
    dispatch,
  ]);

  return (
    <div>
      <OptionsDropdown
        items={question.options}
        onSelect={handleUpdate}
        value={selectedOption} />
    </div>
  );
}

function QuestionRowDropdown() {
  const [item, dispatch] = useSurveyLogicItemState();
  const action = item.action as SurveyLogic.Action<FilterOptionActionType>;

  const question = useActionQuestion() as RowsQuestion;

  const matrixRows = question.matrixRows as SurveyQuestionMatrixRow[];
  const selectedRow = matrixRows.find(r => r.base.identifier === action.row?.identifier);

  const handleUpdate = useCallback((identifier: string) => {
    dispatch({
      type: 'action-updated',
      payload: {
        question: { identifier: question.base.identifier },
        option: null,
        row: { identifier },
        type: action.type,
      },
    });
  }, [
    question.base.identifier,
    action.type,
    dispatch,
  ]);

  return (
    <div>
      <RowDropdown
        items={question.matrixRows}
        onSelect={handleUpdate}
        value={selectedRow} />
    </div>
  );
}

function useActionQuestion() {
  const [state] = useSurveyBuilderState();
  const [item] = useSurveyLogicItemState();
  const action = item.action as SurveyLogic.Action<FilterOptionActionType>;

  return useMemo(() => state.survey.questions.find(f => f.base.identifier === action.question.identifier), [
    state.survey.questions,
    action.question.identifier,
  ]);
}