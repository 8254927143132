import { useState, useCallback } from 'react';
import type { ReplacementMade } from '@/containers/Transcript/interfaces';
import { TranscriptUpdatesContext } from '@containers/Transcript/context';

export const UpdatesContainer = (props: ChildrenProps) => {
  const [corrections, setCorrections] = useState<ReplacementMade[]>([]);
  const [confirmedAutoCorrections, setConfirmedAutoCorrections] = useState<string[]>([]);
  const [overwrittenAutoCorrections, setOverwrittenAutoCorrections] = useState<string[]>([]);

  const addCorrections = useCallback((corrections: ReplacementMade[]) => {
    setCorrections(old => [...old, ...corrections.filter(c => c.originalText !== c.text)]);
  }, []);

  const addConfirmedAutoCorrections = useCallback((identifiers: string[]) => {
    setConfirmedAutoCorrections(old => [...old, ...identifiers]);
  }, []);

  const addOverwrittenAutoCorrections = useCallback((identifiers: string[]) => {
    setOverwrittenAutoCorrections(old => [...old, ...identifiers]);
  }, []);

  return (
    <TranscriptUpdatesContext.Provider value={{ corrections, addCorrections, confirmedAutoCorrections, addConfirmedAutoCorrections, overwrittenAutoCorrections, addOverwrittenAutoCorrections }}>
      {props.children}
    </TranscriptUpdatesContext.Provider>
  );
};