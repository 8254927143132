import { forwardRef } from 'react';
import { X, Menu as Burger } from 'react-feather';
import { cx } from '@utils';
import styles from '@/components/AppBar/style/Drawer.css';

type Props = {
  className?: string;
  onClick?: () => unknown;
  open: boolean;
};

const Hamburger = (props: Props, ref: React.Ref<HTMLButtonElement>) => {
  const className = cx({
    [styles.open]: props.open,
    [styles.closed]: !props.open,
  });

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <div className={cx(styles.drawer, props.className)}>
      <button
        className={styles.toggle}
        onClick={handleClick}
        ref={ref}>
        <div className={styles.btn}>
          <X className={cx(className, styles.x)} />
          <Burger className={cx(className, styles.burger)} />
        </div>
      </button>
    </div>
  );
};

const HamburgerRef = forwardRef<HTMLButtonElement, Props>(Hamburger);

export { HamburgerRef as Hamburger };
export default HamburgerRef;