import {
  CommonTemplateQuestion,
} from '@enums/survey.template';
import type {
  MultipleChoiceQuestion,
  SurveyQuestion,
} from '@/types/survey';
import * as $template from './utils';

export function OrgSizeQuestion({ section }: SharedQuestionProps): MultipleChoiceQuestion.Question {

  return $template.generateMultipleChoiceQuestion({
    options: $template.RevenueOptions.map((value, i) => $template.generateMultipleChoiceOption({
      ordinal: i + 1,
      value,
    })),
    metadata: {
      anchor: false,
      canModifyValue: true,
      canDelete: true,
      template: { key: CommonTemplateQuestion.OrganizationRevenue },
    },
    section: {
      identifier: section.identifier,
    },
    value: $template.generateRichTextValue([
      $template.generateTextNode(`To provide an idea your organization's size, how much revenue did your organization generate last year?`),
    ]),
  });
}

export function OrgIndustryQuestion({ section }: SharedQuestionProps): MultipleChoiceQuestion.Question {

  return $template.generateMultipleChoiceQuestion({
    options: $template.IndustryOptions.map((value, i) => $template.generateMultipleChoiceOption({
      ordinal: i + 1,
      value,
    })),
    metadata: {
      anchor: false,
      canModifyValue: true,
      canDelete: true,
      template: { key: CommonTemplateQuestion.OrganizationPrimaryIndustry },
    },
    section: {
      identifier: section.identifier,
    },
    value: $template.generateRichTextValue([
      $template.generateTextNode('Which of the following best describes the primary industry in which your organization operates?'),
    ]),
  });
}

export function OrgRoleQuestion({ section }: SharedQuestionProps) {
  const optionTexts = $template.RoleOptions;

  const options = optionTexts.map((value, i) => $template.generateMultipleChoiceOption({
    ordinal: i + 1,
    value,
  }));

  return $template.generateMultipleChoiceQuestion({
    options,
    metadata: {
      anchor: false,
      canModifyValue: true,
      canDelete: true,
      template: { key: CommonTemplateQuestion.RespondentRole },
    },
    section: {
      identifier: section.identifier,
    },
    value: $template.generateRichTextValue([
      $template.generateTextNode(`Which of the following best describes your role at your organization?`),
    ]),
  });
}

export function OrgRoleDQQuestion({ section }: SharedQuestionProps) {
  const optionTexts = $template.RoleOptions;

  const options = optionTexts.map((value, i) => $template.generateMultipleChoiceOption({
    ordinal: i + 1,
    value,
  }));

  const studentOption = options[0];
  const contractorOption = options[1];

  return $template.generateMultipleChoiceQuestion({
    // logic: {
    //   base: {
    //     action: {
    //       type: SurveyActionType.None,
    //     },
    //   },
    //   response: [{
    //     id: cuid(),
    //     action: {
    //       type: SurveyActionType.Disqualification,
    //     },
    //     condition: {
    //       value: {
    //         option: { identifier: studentOption.base.identifier },
    //       },
    //       type: SurveyQuestionConditionType.Chosen,
    //     },
    //   }, {
    //     id: cuid(),
    //     action: {
    //       type: SurveyActionType.Disqualification,
    //     },
    //     condition: {
    //       value: {
    //         option: { identifier: contractorOption.base.identifier },
    //       },
    //       type: SurveyQuestionConditionType.Chosen,
    //     },
    //   }],
    // },
    options,
    metadata: {
      anchor: false,
      canModifyValue: true,
      canDelete: true,
      template: { key: CommonTemplateQuestion.RespondentRole },
    },
    section: {
      identifier: section.identifier,
    },
    value: $template.generateRichTextValue([
      $template.generateTextNode(`Which of the following best describes your role at your organization?`),
    ]),
  });
}

export function OrgDepartmentQuestion({ section }: SharedQuestionProps) {

  const options = $template.DepartmentOptions;

  return $template.generateMultipleChoiceQuestion({
    options: [
      ...options.map((value, i) => $template.generateMultipleChoiceOption({
        ordinal: i + 1,
        value,
      })),
      $template.generateMultipleChoiceOption({
        metadata: {
          isAnchored: true,
          isOpenEnded: true,
        },
        ordinal: options.length + 1,
        value: 'Other',
      }),
    ],
    metadata: {
      anchor: false,
      canModifyValue: true,
      canDelete: true,
      template: { key: CommonTemplateQuestion.RespondentDepartment },
    },
    section: {
      identifier: section.identifier,
    },
    value: $template.generateRichTextValue([
      $template.generateTextNode(`Which of the following best describes the department you work in?`),
    ]),
  });
}

type SharedQuestionProps = {
  section: SurveyQuestion['section'];
};