import { useCallback, useContext } from 'react';
import { SurveyBuilderItemContext, useSurveyBuilderCapabilities } from '@containers/SurveyBuilder/Context';
import { useQuestionBuilderItemContext } from '@containers/SurveyBuilder.Question/Context';
import { useCanPreviewSurvey, useIsEditingSurvey } from '@/containers/SurveyBuilder/hooks';
import { BuilderQuestionText } from '@presentation/Survey';
import { getLocationFor } from '@utils';
import { QuestionPreviewItem } from '@/components/SurveyBuilder.Question.Preview';
import { ItemLanguageToggle } from '@/components/SurveyBuilder/ItemLanguageToggle';
import { QuestionContextMenu } from './QuestionContextMenu';
import styles from './style/EditableQuestion.css';

const EditableQuestion = () => {
  const item = useQuestionBuilderItemContext();
  const editing = useIsEditingSurvey();
  const state = useContext(SurveyBuilderItemContext);

  const { canPreview, surveyVersionId } = useCanPreviewSurvey();

  const { canEditItems } = useSurveyBuilderCapabilities();

  const renderQuestion = useCallback(() => {
    return (
      <div className={styles.question}>
        <BuilderQuestionText
          className={styles.questionText}
          item={{
            ordinal: item.ordinal,
            value: item.value,
            identifier: item.base.identifier,
            captureSheetColor: item.captureSheetColor,
            captureSheetLabel: item.captureSheetLabel,
          }} />
        {!editing && canEditItems &&
          <div className={styles.menus}>
            <ItemLanguageToggle />
            <QuestionContextMenu
              canPreview={canPreview}
              onClickPreview={() => {
                window.open(`${getLocationFor.project.surveyPreview({ surveyVersionId }).pathname}?ordinal=${state.ordinal}`);
              }} />
          </div>}
      </div>
    );
  }, [item.ordinal, item.value, item.base.identifier, item.captureSheetColor, item.captureSheetLabel, editing, canEditItems, canPreview, surveyVersionId, state.ordinal]);

  return (
    <div className={styles.root}>
      {renderQuestion()}
      <QuestionPreviewItem
        item={item} />
    </div>
  );
};

export default EditableQuestion;