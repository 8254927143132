import { ButtonActivityIndicator, ButtonOutlined } from '@presentation';
import { ButtonSet as ModalButtonSet } from '@/components/Modal/ButtonSet';
import styles from './style/Editor.css';

type Props = {
  className?: string;
  disabled:   boolean;
  loading:    boolean;
  onCancel:   () => unknown;
  onSubmit:   () => unknown;
};

export const ButtonSet = (props: Props) => {

  return (
    <ModalButtonSet className={props.className}>
      <ButtonOutlined
        className={styles.btn}
        color="gray"
        disabled={props.loading}
        fontWeight="bold"
        onClick={props.onCancel}>
        Cancel
      </ButtonOutlined>
      <ButtonActivityIndicator
        className={styles.btn}
        disabled={props.disabled}
        implicitDisable={false}
        loading={props.loading}
        onClick={props.onSubmit}
        color="primary">
        Save
      </ButtonActivityIndicator>
    </ModalButtonSet>
  );
};

ButtonSet.displayName = 'ColorPalette.Editor.ButtonSet';