import { useCallback } from 'react';
import { useRichTextEditorRegistryEditor, useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import { useUploadSurveyImages } from '@/components/Survey.RichText/hooks';
import { useSurveyMessageBuilderItemContext } from './context';

export const useUploadMessageImages = () => {

  const [_, dispatch] = useSurveyBuilderState();
  const item = useSurveyMessageBuilderItemContext();
  const { getEditorByIdentifier } = useRichTextEditorRegistryEditor();

  const getEditor = useCallback(() => getEditorByIdentifier(item.identifier), [getEditorByIdentifier, item.identifier]);

  const uploadImages = useUploadSurveyImages();

  const handleSubmit = useCallback(async () => {

    const { updated, value } = await uploadImages({
      getContext: getEditor().getContext,
    });

    if (updated) {
      dispatch({
        type: 'message-value-updated',
        messageIdentifier: item.identifier,
        value,
      });
    }
  }, [
    dispatch,
    item.identifier,
    uploadImages,
    getEditor,
  ]);

  return handleSubmit;
};