import { useCallback, useEffect, useMemo, useRef, useContext } from 'react';
import { AppBarContext } from '@/components/AppBar/Context';

const useScrollLock = (locked = false) => {
  const enabled = useRef(locked);
  const scrollbarWidth = useMemo(() => `${window.innerWidth - document.body.clientWidth}px`, []);
  //const appBar = useContext(AppBarContext);

  const lockElement = useCallback((el: HTMLElement) => {
    el.style.overflowY = 'hidden';
    el.style.paddingRight = scrollbarWidth;
    el.style.width = 'auto';
  }, [scrollbarWidth]);

  const unlockElement = useCallback((el: HTMLElement) => {
    el.style.overflowY = '';
    el.style.paddingRight = '';
    el.style.width = '';
  }, []);

  const scroll = useMemo(() => ({
    get locked() {
      return window
        .getComputedStyle(document.body)
        .getPropertyValue('overflow-y') === 'hidden';
    },
    lock: () => {
      lockElement(document.body);
      /*
      if (appBar?.root?.ref?.current) {
        lockElement(appBar.root.ref.current);
      }
      */
    },
    unlock: () => {
      unlockElement(document.body);
      /*
      if (appBar?.root?.ref?.current) {
        unlockElement(appBar.root.ref.current);
      }
      */
    },
  }), [lockElement, unlockElement/*, appBar?.root?.ref*/]);

  const enableLock = useCallback(() => {
    if (scroll.locked) {
      enabled.current = true;
    } else {
      scroll.lock();
    }

    if (!enabled.current) {
      enabled.current = true;
    }
  }, [
    scroll,
  ]);

  const disableLock = useCallback(() => {
    if (enabled.current) {
      scroll.unlock();
    }

    enabled.current = false;
  }, [scroll]);

  useEffect(() => {
    return () => enabled.current && disableLock();
  }, [disableLock]);

  return {
    lock: enableLock,
    locked: scroll.locked,
    unlock: disableLock,
  };
};

export { useScrollLock };
export default useScrollLock;