import { SurveyTemplate } from '@/types';
import { TemplateInput } from './Input.Base';

type Props = {
  onChange: (value: SurveyTemplate.LinkedEntity) => void;
  value: SurveyTemplate.LinkedEntity;
};

export const TemplateMedicalConditionInput = ({ onChange, value }: Props) => {

  const placeholder = `Enter a medical condition`;

  return (
    <TemplateInput
      // className={styles.input}
      placeholder={placeholder}
      onChange={onChange}
      value={value} />
  );
};