import { createNamedContext } from '@utils';
import {
  KeyboardContextValue,PopupContextValue, QueryContextValue,
  ResetContextValue, ResultsContextValue,
} from './interfaces';

export const ResultsContext =
  createNamedContext<ResultsContextValue>(undefined, 'UniversalSearchBar.ResultsContext');

export const KeyboardContext =
  createNamedContext<KeyboardContextValue>(undefined, 'UniversalSearchBar.KeyboardContext');

export const PopupContext =
  createNamedContext<PopupContextValue>(undefined, 'UniversalSearchBar.PopupContext');

export const QueryContext =
  createNamedContext<QueryContextValue>(undefined, 'UniversalSearchBar.QueryContext');

export const ResetContext =
  createNamedContext<ResetContextValue>(undefined, 'UniversalSearchBar.ResetContext');

