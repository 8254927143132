import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as api from '@api';
import { TranscribeRT } from '@/types';

type Props = {
  tagSummaryId: number;
} & ITranscriptId;
type Data = {
  citations: TranscribeRT.TaggedMoment[];
};
type QueryKey = readonly [string, Props];

type Options = UseQueryOptions<Data, AxiosError, Data, QueryKey>;

export const useFetchTranscriptTagSummaryCitations = (props: Props, options?: Options) => {

  return useQuery(['get:transcripts/conference-tags/summaries/citations', props], ({ queryKey }) => {
    const [, { transcriptId, tagSummaryId }] = queryKey;
    return api.transcripts.getTagSummaryCitations({
      transcriptId,
      tagSummaryId,
    });
  }, options);
};