import { useCallback, useMemo, useEffect } from 'react';
import { FileSocketEvent } from '@/enums';
import * as $ws from '@/services/websocket';
import { useFilesSocketEvent } from '@/services/websocket/hooks/files';
import { useInvalidateFilePreviewQueries, useInvalidateFilesQueries } from '@utils/api';
import type { WorkspaceFileTranscript } from '@/types/workspace';
import { FileTranscriptStatus } from '@/types/workspace';

type Props =
  IWorkspaceFileId &
  {
    transcript: WorkspaceFileTranscript;
  } & ChildrenProps;

export const FileTranscriptStatusContainer = ({ children, fileId, transcript }: Props) => {

  const invalidateFilesQueries = useInvalidateFilesQueries();
  const invalidateFilePreviewQueries = useInvalidateFilePreviewQueries();

  const enabled = useMemo(() => {
    return transcript?.status === FileTranscriptStatus.InProgress;
  }, [transcript]);

  const handleTranscriptAvailable = useCallback((data: ITranscriptId) => {
    invalidateFilesQueries();
    invalidateFilePreviewQueries({ fileId });
  }, [
    fileId,
    invalidateFilesQueries,
    invalidateFilePreviewQueries,
  ]);

  useFilesSocketEvent(FileSocketEvent.TranscriptAvailable, handleTranscriptAvailable, enabled);

  useEffect(() => {
    if (enabled) {
      $ws.files.subscribe({ fileId });
      $ws.files.open();

      return () => {
        $ws.files.unsubscribe({ fileId });
        $ws.files.close();
      };
    }
  }, [enabled, fileId]);

  return (
    <>
      {children}
    </>
  );
};

export default FileTranscriptStatusContainer;