import type { ConferenceTag } from './conferences';
import type { SpeakerMap } from './transcribe.processed';

export type TranscriptMetadata = {
  hasSpeakerNames: boolean;
  hasTimestamps: boolean;
};

export type Transcript = {
  id: number;
  document: DocumentNode;
  metadata?: TranscriptMetadata;
  source: 'assemblyai' | 'aws-transcribe';
  version: string;
};

export enum MarkType {
  Timestamp   = 'ts',
  Redaction   = 'redact',
  Confidence  = 'conf',
  AutoCorrect = 'ac',
}

export enum NodeType {
  Document         = 'doc',
  MonologueText    = 'mt',
  MonologueDetails = 'md',
  Text             = 'text',
}

export type DocumentNode = {
  content: MonologueNode[];
  type: NodeType.Document;
};

export type MonologueNode = MonologueDetails.Node | MonologueText.Node;

export declare namespace MonologueDetails {

  export type Attributes = {
    s: number;
    e: number;
    spk: number;
  };

  export type Node = {
    attrs: Attributes;
    type: NodeType.MonologueDetails;
  };
}

export declare namespace MonologueText {

  export type Node = {
    content: TextNode[];
    type: NodeType.MonologueText;
  };
}

export declare namespace Timestamp {
  export type Attributes = {
    s: number;
    e: number;
  };

  export type Mark = {
    attrs: Attributes;
    type: MarkType.Timestamp;
  };
}

export declare namespace Confidence {
  export type Attributes = {
    c: number;
    s?: string[];
  };

  export type Mark = {
    attrs: Attributes;
    type: MarkType.Confidence;
  };
}

export declare namespace AutoCorrect {
  export type Attributes = {
    id: string;
  };

  export type Mark = {
    attrs: Attributes;
    type: MarkType.AutoCorrect;
  };
}

export type TextNode = {
  marks?: Timestamp.Mark[];
  type: NodeType.Text;
  text: string;
};

export type Highlight = {
  color: string;
  dbId: number | null;
  id: string;
  from: number;
  to: number;
  derived?: boolean;
};

export type TaggedMoment = Omit<ConferenceTag, 'id'> & {
  content?: DocumentNode;
  speakers: SpeakerMap;
  id: number;
  fromTs: number;
  toTs: number;
  identifier: string;
  tagId: number;
  meta: {
    type: TaggedMomentEntity;
    callId?: number;
    conferenceId?: number;
    transcriptId?: number;
  };
};

export enum TaggedMomentEntity {
  Conference = 'conference',
  Transcript = 'transcript',
}

export type ProjectTaggedMoment =
  TaggedMoment &
  Partial<ICallId>;

export * from './transcript.rich-text.comments';
export * from './transcript.rich-text.text';