import type { SurveyQuestionType } from '@enums';
import type { ExclusiveOptionsQuestion } from '@/types';
import type { Validation } from '../interfaces';
import { assertValidOptions } from './validation.options';
import { assertHasValue, returnAssertFailure } from './validation.base';
import { assertValidSelectionSettings as assertSelectionSettings, getSelectionSettingsMinMax } from './validation.settings.selections';

export const DefaultOptions = 4;
export const MinOptions = 2;
export const MaxOptions = 50;

export const SectionTypeDisplayName: Record<ExclusiveOptionsQuestion.SectionType, string> = {
  'multi-select': 'Multi-Select',
  'single-select': 'Single-Select',
};

export const DefaultSettings: ExclusiveOptionsQuestion.Settings = {
  rationale: {
    enabled: false,
    minimum: 75,
  },
  displayGroupTitles: false,
  optionSections: [],
};

export const DefaultOptionMetadata: ExclusiveOptionsQuestion.OptionMetadata = {
  canDelete: true,
  canModifyValue: true,
  isOpenEnded: false,
  isExclusive: false,
  sectionId: null,
  template: {},
};

export function getSelectionSettingOptions(item: ExclusiveOptionsQuestion.Question) {
  return getSelectionSettingsMinMax({
    hasNoneOfTheAbove: false,
    options: item.options.length,
  });
}

export function validateQuestion(question: ExclusiveOptionsQuestion.Question): Validation.ValidateQuestionResult<SurveyQuestionType.ExclusiveOptions> {
  const hasValue = assertHasValue(question);
  if (hasValue.success === false) {
    return returnAssertFailure(hasValue, question);
  }

  const hasValidOptions = assertValidOptions(question.options, MinOptions, MaxOptions);
  if (hasValidOptions.success === false) {
    return returnAssertFailure(hasValidOptions, question);
  }

  return {
    success: true,
    result: {
      question,
    },
  };
}