import type { ChangeEvent } from 'react';
import { useCallback, useMemo } from 'react';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import * as consts from '@consts';
import type { RankingQuestion } from '@/types/survey';
import { cx } from '@utils';
import DropDown from '@/components/DropDown';
import { TextareaAutosize } from '@/components/Textarea';
import { SurveyQuestionOption } from '@/components/Survey.RichText';
import type { RankingOrdinalDropdownItem } from './interfaces';
import styles from './style/Ranking.ListItem.css';

export type Props = {
  className?: string;
  dragDisabled?: boolean;
  rankingDisabled?: boolean;
  item: RankingQuestion.FormOption;
  openEndedValue: string;
  ordinalItems: number[];
  onOrdinalSelect: (item: RankingOrdinalDropdownItem) => void;
  onUpdateOpenEndedValue: (value: string) => void;
};

const getDropdownItemText = (ordinal: number) => {
  if (ordinal === consts.surveys.NotApplicableRankingOrdinal) return 'N/A';

  if (!ordinal) return '-';

  return ordinal.toString();
};

export const RankingListItem = ({ onUpdateOpenEndedValue, ...props }: Props) => {
  const dropdownItems = useMemo(() => {
    return props.ordinalItems.map(ordinal => ({
      id: ordinal,
      name: getDropdownItemText(ordinal),
    }));
  }, [props.ordinalItems]);

  const handleOpenEndedValueChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    onUpdateOpenEndedValue(e.target.value);
  }, [onUpdateOpenEndedValue]);

  return (
    <div className={cx(styles.root, props.className)}>
      <div className={styles.left}>
        <div className={styles.ordinal}>
          <DropDown
            disabled={props.rankingDisabled}
            getItemValue={(item: RankingOrdinalDropdownItem) => item.name}
            items={dropdownItems}
            onSelect={props.onOrdinalSelect}
            text={getDropdownItemText(props.item.ordinal)} />
        </div>
        <div>
          <div className={styles.message}>
            <SurveyQuestionOption value={props.item.value} editorClassName={styles.editor} />
          </div>
          {props.item.metadata.isOpenEnded &&
            <div className={styles.openEndedInput}>
              <TextareaAutosize
                onChange={handleOpenEndedValueChange}
                value={props.openEndedValue} />
            </div>
          }
        </div>
      </div>
      {!props.dragDisabled &&
        <div className={styles.right}>
          <DragHandleIcon
            className={styles.handle}
            sx={{
              height: '28px',
              width: '28px',
            }} />
        </div>
      }
    </div>
  );
};

export default RankingListItem;