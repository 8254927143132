import { useCallback } from 'react';
import { useLinkModalContext, useLinkPopperContext } from './Context';
import { LinkExtensionModal } from './Link.Modal';
import { useSubmitLink } from './hooks';

export const LinkModalContainer = (props: ChildrenProps) => {

  const { close: closePopper } = useLinkPopperContext();
  const {
    close: closeModal,
    selection: modalSelection,
  } = useLinkModalContext();

  const handleCancel = useCallback(() => {
    closePopper();
    closeModal();
  }, [closeModal, closePopper]);

  const handleSubmit = useSubmitLink();

  return (
    <>
      {!!modalSelection &&
        <LinkExtensionModal
          canEditText={!modalSelection?.text}
          selection={modalSelection}
          onCancel={handleCancel}
          onSubmit={handleSubmit} />
      }
      {props.children}
    </>
  );
};