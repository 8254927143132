import { useMemo } from 'react';
import { useEditorState } from '@remirror/react';
import { useTranscriptToolsPermission } from '@containers/Transcript/hooks';
import type { Highlight } from '@/types/transcribe.rich-text';
import { MarkType } from '@/types/transcribe.rich-text';
import { useHelpers } from './useHelpers';

export const useTooltipHighlightConfig = () => {
  const { getFocusedHighlight, getHighlights } = useHelpers();
  const highlight = getFocusedHighlight();
  const allHighlights = getHighlights();

  const canRedact = useMemo(() => {
    if (!highlight) return false;

    if (highlight?.dbId) return false;

    return !allHighlights.some(h => h.id != highlight.id && doHighlightsOverlap(h, highlight));

    function doHighlightsOverlap(a: Highlight, b: Highlight) {
      return a.from <= b.to && b.from <= a.to;
    }
  }, [highlight, allHighlights]);

  const canTag = useMemo(() => {
    if (!highlight) return false;

    if (highlight?.dbId) return false;

    return true;
  }, [highlight]);

  const state = useEditorState();

  const includesRedaction = useMemo(() => {
    if (!highlight) return false;

    let valid = true;

    state.doc.nodesBetween(highlight.from, highlight.to, (node, pos) => {
      const marks = node.marks || [];

      if (valid) {
        for (const m of marks) {
          if (m.type.name === MarkType.Redaction) {
            valid = false;
            break;
          }
        }
      }
    });

    return !valid;
  }, [
    highlight,
    state,
  ]);

  const permission = useTranscriptToolsPermission();

  const display = useMemo(() => {
    return {
      clip: true,
      colors: permission.highlight,
      commenting: permission.commenting,
      copy: true,
      redaction: permission.redaction && canRedact,
      replace: permission.replace && canTag && !includesRedaction,
      speaker: permission.speaker && canTag && !includesRedaction,
      tagging: permission.tagging && canTag,
    };
  }, [
    canTag,
    canRedact,
    permission,
    includesRedaction,
  ]);

  return display;
};