import HelloSign from 'hellosign-embedded';

class ESign {
  constructor() {
    this.addListeners();
  }

  getInstance = () => {
    return this.client;
  }

  client = new HelloSign({
    clientId: process.env.HELLOSIGN_CLIENT_ID,
    debug: false,
  });

  addListeners = () => {
    /*
      The signature request or template was canceled by the
      user by either pressing the close button or selecting
      "close" from the "⋮" dropdown list.
    */
    this.client.on('cancel', () => logger('cancel'));

    /*
      The user creates the signature request template.
    */
    this.client.on('createTemplate', data => logger('createTemplate', data));

    /*
      HelloSign Embedded window has closed.
    */
    this.client.on('close', () => logger('close'));

    /*
      HelloSign app encounters an error.
    */
    this.client.on('error', e => logger('error', e));

    /*
      The user has finished the embedded template or signature
      request flow without cancelling.
    */
    this.client.on('finish', () => logger('finish'));

    /*
      HelloSign Embedded has received a cross-origin window
      message from the HelloSign app.
    */
    this.client.on('message', data => logger(`message (${data.type})`, data));

    /*
      HelloSign Embedded window has opened.
    */
    this.client.on('open', data => logger('open', data));

    /*
      HelloSign app embedded in the iFrame has initialized
      and the signature request is ready.
    */
    this.client.on('ready', () => logger('ready'));
    /*
    this.client.on('ready', () => {
      const selector = '.x-hellosign-embedded__modal-close-button';
      const closeBtn = document.querySelector(selector);
      closeBtn.addEventListener('click', this.handleClickClose(closeBtn))
    });
    */
  }

  handleClickClose = target => {
    /*
    const clickListener = () => {
      this.client.close();
      target.removeEventListener('click', clickListener);
    };
    return clickListener;
    */
  }
}

function logger(label, data = null) {
  const event = `%cHelloSign: ${label}`;
  const style = 'color: #5fafff;';

  if (!data) {
    console.info(event, style);
  } else {
    console.groupCollapsed(event, style);
    console.info(data);
    console.groupEnd();
  }
}

export default new ESign();
