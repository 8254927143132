import { useCallback, useState } from 'react';
import { SurveyTemplateType } from '@enums/survey.template';
import { useSurveyTemplateChangeAlert } from '@/components/Survey.Templates/TemplateChangeAlert';
import { useSetSurveyTemplate } from './useSetSurveyTemplate';
import { useSurveyTemplate } from './useSurveyTemplate';
import { useSurveyBuilderState } from './useSurveyBuilderContext';

type Props = {
  onUpdate: () => void;
};

type Return = readonly [
  (template: SurveyTemplateType) => () => void,
  () => JSX.Element,
];

export const useUpdateTemplate = ({ onUpdate }: Props): Return => {
  const [template] = useSurveyTemplate();
  const [pendingTemplate, setPendingTemplate] = useState<SurveyTemplateType>();
  const [toggleChangeAlert, TemplateChangeAlert] = useSurveyTemplateChangeAlert();
  const [state] = useSurveyBuilderState();
  const setTemplate = useSetSurveyTemplate();

  const updateTemplate = useCallback((t: SurveyTemplateType) => {

    setTemplate(t);

    onUpdate?.();
  }, [
    onUpdate,
    setTemplate,
  ]);

  const handleTemplateClick = useCallback((t: SurveyTemplateType) => () => {

    const isDifferentTemplate = template !== null && t !== template;
    const hasExistingSurvey = state.survey.questions.length;

    if (hasExistingSurvey && isDifferentTemplate) {
      toggleChangeAlert();
      setPendingTemplate(t);
    } else if (hasExistingSurvey) {
      onUpdate?.();
    } else {
      updateTemplate(t);
    }

  }, [
    onUpdate,
    state.survey.questions,
    template,
    toggleChangeAlert,
    updateTemplate,
  ]);

  const Alert = useCallback(() => {
    return (
      <TemplateChangeAlert onConfirm={() => updateTemplate(pendingTemplate)} />
    );
  }, [
    TemplateChangeAlert,
    pendingTemplate,
    updateTemplate,
  ]);

  return [handleTemplateClick, Alert];
};