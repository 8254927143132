import { useParams } from 'react-router-dom';

type RouteParams = {
  projectId: string;
};

export const useMatchProjectIdFromRoute = () => {
  const params = useParams<RouteParams>();
  return {
    projectId: +params.projectId,
  };
};

export default useMatchProjectIdFromRoute;