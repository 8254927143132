import { useCallback, useEffect, useRef, useState } from 'react';
import { Play } from 'react-feather';
import type { AudioTrack as TwAudioTrack } from 'twilio-video';
import * as Sentry from '@sentry/react';
import { usePushTwilioLog, useLogConferenceEvent } from '@containers/Conference/hooks';
import { ConferenceLogType } from '@/types';
import { Modal } from '@/components/Modal/Modal';
import { Button } from '@/components/Button';
import { TWILIO_TRACK_ATTRIBUTE } from './constants';
import styles from './style/AudioTrackModal.css';

type AudioTrackProps = {
  track: TwAudioTrack;
};

export function AudioTrack({ track }: AudioTrackProps) {
  const audioEl = useRef<HTMLAudioElement>();
  const [autoplayError, setAutoplayError] = useState(false);
  const pushTwilioLog = usePushTwilioLog();
  const logConferenceEvent = useLogConferenceEvent();

  useEffect(() => {
    audioEl.current = track.attach();
    audioEl.current.setAttribute(TWILIO_TRACK_ATTRIBUTE, track.name);
    document.body.appendChild(audioEl.current);

    if (audioEl.current.paused) {
      audioEl.current.play()?.catch((err: Error) => {
        setAutoplayError(true);
        pushTwilioLog({
          type: 'custom',
          message: 'Audio auto-play error',
        });
        logConferenceEvent(ConferenceLogType.Error, { message: `Browser won't allow audio to play` });
      });
    }

    audioEl.current.onplaying = () => {
      //For some reason this event can get fired while it's actually paused
      if (!audioEl.current.paused) {
        setAutoplayError(false);
      }
    };

    return () =>
      track.detach().forEach(el => {
        el.remove();

        // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
        // See: https://github.com/twilio/twilio-video.js/issues/1528
        el.srcObject = null;
      });
  }, [logConferenceEvent, pushTwilioLog, track]);

  // useEffect(() => {
  //   audioEl.current?.setSinkId?.(activeSinkId);
  // }, [activeSinkId]);

  if (autoplayError) {
    return (
      <AudioManualStartModal elRef={audioEl} />
    );
  }

  return null;
}

type ManualStartModalProps = {
  elRef: React.MutableRefObject<HTMLAudioElement>;
};

const AudioManualStartModal = (props: ManualStartModalProps) => {
  const [isHidden, setHidden] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const pushTwilioLog = usePushTwilioLog();
  const logConferenceEvent = useLogConferenceEvent();

  const onClose = useCallback(() => {
    setHidden(true);
    pushTwilioLog({
      type: 'custom',
      message: 'Audio auto-play modal manually dismissed',
    });
    logConferenceEvent(ConferenceLogType.Info, { message: `Browser audio prompt was dismissed.` });
  }, [logConferenceEvent, pushTwilioLog]);

  const playAudio = useCallback(() => {
    props.elRef.current.play()
      .then(() => {
        //Find all the other twilio audio element and try and get them to play
        document.querySelectorAll(`audio[${TWILIO_TRACK_ATTRIBUTE}]`).forEach((el: HTMLAudioElement) => {
          if (el != props.elRef.current) {
            el.play();
          }
        });
        logConferenceEvent(ConferenceLogType.Info, { message: `Browser audio prompt was manually allowed.` });
      })
      .catch((err: Error) => {
        Sentry.captureException(err, {
          extra: {
            playErrorType: 'forced-play',
          },
        });
        setErrorMsg(err.message);
      });
  }, [logConferenceEvent, props.elRef]);
  return (
    <Modal
      open={!isHidden}
      disableOverlayClick
      disableEventPropagation
      onClose={onClose}>
      <div className={styles.root}>
        <div className={styles.informational}>
          <div className={styles.header}>
            Your browser is not letting us automatically play audio. Please click the button to allow audio to play.
          </div>
          <div className={styles.subheader}>
            You can avoid this issue by allowing Autoplay for this website in your browser settings.
          </div>
          {errorMsg &&
            <div>
              The audio cannot be started: {errorMsg}
            </div>
          }
        </div>
        <Button variant='brick' onClick={playAudio}>
          <div className={styles.btnContent}>
            <Play /> Play
          </div>
        </Button>
      </div>
    </Modal>
  );
};

export default AudioTrack;