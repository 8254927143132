import { useCallback } from 'react';
import type { InvalidContentHandler } from 'remirror';
import { EditorComponent, Remirror, useRemirror } from '@remirror/react';
import type { DocumentNode } from '@/types/transcribe.rich-text';
import { SeeMore, Collapser } from '@presentation/SeeMore';
import { TranscriptSpeakersContext } from '@/containers/Transcript/context';
import type { SpeakerMap } from '@/types/transcribe.processed';
import { MonologueDetailsExtension, MonologueTextExtension, TimestampExtension } from '@/components/Transcript/Extension';
import { SnippetMonologueDetails } from '@/components/Transcript/Snippet.MonologueDetails';

type Props = {
  content: DocumentNode;
  speakers: SpeakerMap;
};
export const StandaloneTranscriptSnippet = ({ content, speakers }: Props) => {
  const onError: InvalidContentHandler = useCallback(({ json, invalidContent, transformers }) => {
    console.error('Invalid Content', invalidContent);
    return transformers.remove(json, invalidContent);
  }, []);

  const { manager, state } = useRemirror({
    extensions: () => [
      new MonologueDetailsExtension(SnippetMonologueDetails),
      new MonologueTextExtension(),
      new TimestampExtension(),
    ],
    content,
    onError,
  });

  return (
    <TranscriptSpeakersContext.Provider value={speakers}>
      <SeeMore
        lineHeight={24}
        maxLines={5}
        CollapserComp={Collapser}>
        <Remirror
          manager={manager}
          editable={false}
          initialContent={state}>
          <EditorComponent />
        </Remirror>
      </SeeMore>
    </TranscriptSpeakersContext.Provider>
  );
};