import { useState } from 'react';
import { format } from 'date-fns';
import { DatePicker } from '@/components/DatePicker';
import Pencil from '@/components/icons/Pencil';
import { FormButtons } from '@/presentation/FormButtons';
import styles from '@screens/Transcript/style/Header.PublishedOn.css';

type PublishedDateProps = {
  editing: boolean;
  setEditing: (value: boolean) => void;
  onCancel: () => void;
  onSubmit: (value: Date) => void;
  value: Date;
};

export const PublishedDate = ({ setEditing, onCancel, onSubmit, editing, value }: PublishedDateProps) => {
  return (
    <>
      {!editing && (
        <div className={styles.dateFieldValue} onClick={() => setEditing(true)}>
          {value ? format(value, 'MMM do yyyy') : `-`}
          <div className={styles.pencil}>
            <Pencil size={16} />
          </div>
        </div>
      )}
      {editing && (
        <EditingPublishedDate
          editing={editing}
          value={value}
          onSubmit={onSubmit}
          onCancel={onCancel}
          setEditing={setEditing} />
      )}
    </>
  );
};

type DatePickerState = {
  value: Date;
};

const EditingPublishedDate = ({ onCancel, onSubmit, value }: PublishedDateProps) => {
  const [state, setState] = useState<DatePickerState>({
    value,
  });

  return (
    <>
      <div className={styles.dateFieldValue}>
        <div className={styles.datePicker}>
          <DatePicker
            inline={false}
            open={true}
            onChange={value => setState({ value })}
            placeholderText="Select a date"
            selected={state.value} />
        </div>
        <FormButtons
          className={styles.btns}
          size={16}
          disabled={false}
          onCancel={onCancel}
          onSubmit={() => onSubmit(state.value)} />
      </div>
    </>
  );
};