import { InfoTooltip } from '@presentation/InfoTooltip';
import { HourlyRateSlider } from '@/components/Slider';
import { CallRateProps } from './interfaces';
import styles from './style.css';

const MinimumRate = 100;
const MaximumRate = 2000;
const IncrementBy = 10;

const CallRate = (props: CallRateProps) => {

  const renderCopy = () => {
    const copy = `When an expert signs up with your unique referral link, we will wave our fees
    on the first call booked with that expert.`;

    return <div className={styles.copy}>{copy}</div>;
  };

  const renderRate = () => {
    const helpText = `The specified call rate will be applied to calls when an expert joins the project with this link.`;

    return (
      <div className={styles.rateSection}>
        <div className={styles.rateHeader}>
          <div className={styles.rateLabel}>Set the call rate:</div>
          <div className={styles.rateValue}>
            ${props.hourlyRate}
          </div>
          <InfoTooltip
            className={styles.rateInfo}
            text={helpText} />
        </div>
        <div className={styles.rateBar}>
          <HourlyRateSlider
            onDragEnd={props.onDragEnd}
            onPositionChange={props.setDisplayRate}
            incrementBy={IncrementBy}
            maximumRate={MaximumRate}
            minimumRate={props.allowFree ? 0 : MinimumRate}
            value={props.hourlyRate} />
        </div>
      </div>
    );
  };

  return (
    <>
      {renderCopy()}
      {renderRate()}
    </>
  );
};

export default CallRate;