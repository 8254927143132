import { useFiltersState } from '@containers/UniversalSearch/hooks';
import SearchTabViewContainer from './Search.Tab.ViewContainer';
import styles from './style/Main.css';

export default function SearchMain() {
  const [query] = useFiltersState();

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.query}>{`Search: "${query.q}"`}</div>
        <SearchTabViewContainer />
      </div>
    </div>
  );
}