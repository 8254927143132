import * as types from '@store/action-types';
import { onboarding as initialState } from '@store/initial-state';
import {
  AppDataFetchedAction,
  OnboardingCompletedAction,
} from '@store/interfaces';

type Actions =
  AppDataFetchedAction |
  OnboardingCompletedAction;

function onboardingReducer(state: Store.Onboarding = initialState, action: Actions) {
  switch (action.type) {
    case types.APP_DATA_FETCHED:
      return {
        ...state,
        completed: !!action.onboarding?.completedOn,
        completedOn: action.onboarding?.completedOn,
      };

    case types.ONBOARDING_COMPLETED:
      return {
        ...state,
        completed: true,
        completedOn: action.completedOn,
      };

    default:
      return state;
  }
}

export default onboardingReducer;
