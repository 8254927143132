import { createNamedContext } from '@utils';

export type GroupUserParams =
  IGroupId &
  IUserId;

export type ProjectUserParams =
  IProjectId &
  IUserId;

export type DocumentDownloadContextValue = {
  downloadGroupUserDocument: (data: GroupUserParams) => Promise<Blob>;
  downloadProjectUserDocument: (data: ProjectUserParams) => Promise<Blob>;
};

export const DocumentDownloadContext = createNamedContext<DocumentDownloadContextValue>(undefined, 'document-download');