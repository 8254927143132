import { Dispatch } from 'react';
import { Posts } from '@/types';
import { PostBody } from '@containers/PostCreation/interfaces/post.text';

export enum PostingState {
  Initializing,
  Uploading,
  Saving,
  Created,
}

type MediaFileUpload = {
  bucket: string;
  fid:    string;
  file:   File | string;
  format: string;
  pid:    string;
  s3Key:  string;
}

declare namespace FileUploadProgressChange {
  export type State = {
    fid:   string;
    value: number;
  };
  export type Action = {
    type: 'file-upload/progress-change';
  } & State;
}

declare namespace FileUploadStarted {
  export type State = {
    upload: MediaFileUpload;
  };
  export type Action = {
    type: 'file-upload/started';
  } & State;
}

declare namespace PostingInit {
  export type State = {
    post: VideoState;
  };
  export type Action = {
    type: 'posting/init';
  } & State;
}

declare namespace PostingCreated {
  export type State = {
    post: Posts.Post;
  };
  export type Action = {
    type: 'posting/created';
  } & State;
}

declare namespace PostingRendered {
  export type State = {
    postIds: number[];
  };
  export type Action = {
    type: 'posting/rendered';
  } & State;
}

declare namespace PostingStateChange {
  export type State = {
    pid:    string;
    status: PostingState;
  };
  export type Action = {
    type: 'posting/state-change';
  } & State;
}

export type State = {
  media: {
    [pid: string]: {
      post:      VideoState;
      status:    PostingState;
      uploadIds: MediaFileUpload['fid'][];
    };
  };
  posts: Posts.Post[];
  uploads:    {
    [fid: string]: MediaFileUpload;
  };
  progress: {
    [fid: string]: number;
  };
};

export declare namespace HandleSubmit {
  export type Params = {
    post: {
      identifier: string;
    } & VideoState;
    topics: number[];
  };

  export type Return = void;
}

type VideoState = {
  body?:       PostBody;
  description: PostBody;
  identifier:  string;
  snapshot:    string | null;
  thumbnail?:  File | null;
  title:       string;
  video:       File | null;
};

export type Action =
  | FileUploadProgressChange.Action
  | FileUploadStarted.Action
  | PostingCreated.Action
  | PostingInit.Action
  | PostingRendered.Action
  | PostingStateChange.Action;

export type ContextValue = [
  State,
  Dispatch<Action>,
];

export type PublishContextValue = (params: HandleSubmit.Params) => HandleSubmit.Return;