import { useCallback, useContext, useMemo } from 'react';
import type { ColumnDef } from '@tanstack/react-table';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';
import type { ProjectClips } from '@/types/project.clips';
import {
  ProjectClipsPaginationContext,
} from '@/containers/Project.Clips/context';
import { ControlledTablePagination } from '@/presentation/TablePagination';
import * as Table from '@/components/Table';
import { ActionsCell } from './Cell.Actions';
import { ClipsTableEmpty } from './Clips.Table.Empty';
import { ContentCell } from './Cell.Content';
import { GoodQuoteCell } from './Cell.GoodQuote';
import { SourceCell } from './Cell.Source';
import { TagsCell } from './Cell.Tags';
import { SourceHeader } from './Header.Source';
import { TagsHeader } from './Header.Tags';
import styles from './style/Project.Clips.css';
import { GoodQuoteHeader } from './Header.GoodQuote';

type Props = {
  data:    ProjectClips.Item[];
  empty:   boolean;
  loading: boolean;
};

export const ClipsTable = (props: Props) => {

  const pagination = useContext(ProjectClipsPaginationContext);

  const columns = useTableColumns();

  const table = useReactTable({
    data: props.data,
    getCoreRowModel: getCoreRowModel(),
    columns,
  });

  const paginationProps = {
    goToPage: pagination.setPageIndex,
    pageCount: pagination.totalRows % pagination.pageSize === 0
      ? pagination.totalRows / pagination.pageSize
      : Math.floor(pagination.totalRows / pagination.pageSize) + 1,
    pageIndex: pagination.pageIndex,
    pageSize: pagination.pageSize,
    totalCount: pagination.totalRows,
  };

  const getCellStyles = useCallback((def: ColumnDef<TableItem>) => {
    return def.meta?.['className'] as string;
  }, []);

  return (
    <Table.Root
      EmptyComponent={ClipsTableEmpty}
      empty={props.empty}
      loading={props.loading}>
      <Table.Header
        getHeaderGroups={table.getHeaderGroups}
        getHeaderCellStyles={getCellStyles} />
      <Table.Body
        classes={{
          td: styles.td,
          tr: styles.tr,
        }}
        getRowModel={table.getRowModel}
        // @ts-ignore
        getBodyCellStyles={getCellStyles} />
      <Table.Layout.Footer className={styles.footer}>
        <ControlledTablePagination {...paginationProps} />
      </Table.Layout.Footer>
    </Table.Root>
  );
};

ClipsTable.displayName = 'Project.Clips.Table';

type TableItem = ProjectClips.Item;

const useTableColumns = () => {

  return useMemo<ColumnDef<TableItem>[]>(() => [
    {
      id: 'source',
      header: SourceHeader,
      cell: SourceCell,
      meta: {
        className: styles.call,
      },
    }, {
      id: 'tags',
      header: TagsHeader,
      cell: TagsCell,
      meta: {
        className: styles.tag,
      },
    }, {
      id: 'good-quote',
      header: GoodQuoteHeader,
      cell: GoodQuoteCell,
      meta: {
        className: styles.goodQuoteCell,
      },
    }, {
      id: 'snippet',
      header: 'Content',
      cell: ContentCell,
      meta: {
        className: styles.text,
      },
    }, {
      id: 'actions',
      header: '',
      cell: ActionsCell,
      meta: {
        className: styles.actions,
      },
    },
  ].filter(Boolean), []);
};