import { useCallback, useContext, useRef, useEffect } from 'react';
import type { EditorState, ProsemirrorNode } from '@remirror/pm';
import { TranscriptTimestampPositionsContext, TranscriptTimestampEndPositionsContext } from '@containers/Transcript/context';
import type { TimestampMap } from '@/containers/Transcript/interfaces';

type TimePos = {
  fromTs: number;
  toTs: number;
};
export const useMapTimePosToDocPos = () => {
  const timestampPositions = useContext(TranscriptTimestampPositionsContext);
  const endTimestampPositions = useContext(TranscriptTimestampEndPositionsContext);

  const startCache = useRef(new Map<number, number>());
  const endCache = useRef(new Map<number, number>());

  useEffect(() => {
    startCache.current = new Map<number, number>();
    endCache.current = new Map<number, number>();
    //console.log('Cleared cache');
  }, [timestampPositions, endTimestampPositions]);

  return useCallback(({ fromTs, toTs }: TimePos) => {
    const range = {
      from: resolveStartValue(),
      to: resolveEndValue(),
    };

    if (range.from === undefined || range.to === undefined) {
      return null;
    } else {
      return range;
    }

    function getClosestTimestamp(val: number, map: TimestampMap) {
      let closest: number = null;
      for (const ts of map.keys()) {
        if (closest === null) {
          closest = ts;
        }

        if (Math.abs(val - ts) <= Math.abs(val - closest)) {
          closest = ts;
        }
      }

      return closest;
    }

    function resolveStartValue() {
      if (startCache.current.has(fromTs)) {
        //console.log(`Cache hit for ${fromTs}`);
        return startCache.current.get(fromTs);
      } else {
        const closestStartTs = getClosestTimestamp(fromTs, timestampPositions);
        const from = timestampPositions.get(closestStartTs)?.from;
        startCache.current.set(fromTs, from);
        return from;
      }
    }

    function resolveEndValue() {
      if (endCache.current.has(toTs)) {
        //console.log(`End Cache hit for ${toTs}`);
        return endCache.current.get(toTs);
      } else {
        const closestEndTs = getClosestTimestamp(toTs, endTimestampPositions);
        const to = endTimestampPositions.get(closestEndTs)?.to;
        endCache.current.set(toTs, to);
        return to;
      }
    }
  }, [timestampPositions, endTimestampPositions]);
};

type Params = {
  from: number;
  to: number;
  state: EditorState;
};

export const useMapDocPosToTimePos = () => {
  return useCallback(({ from, to, state }: Params) => {

    const nodes: ProsemirrorNode[] = [];

    state.doc.nodesBetween(from, to, n => { nodes.push(n); });

    const times: number[] = [];
    for (const n of nodes) {
      const timeMark = getTimeMarkFromNode(n);
      if (timeMark) {
        times.push(timeMark.attrs.s as number, timeMark.attrs.e as number);
      }
    }

    if (!times.length) return null;

    const start = Math.min(...times);
    const end = Math.max(...times);

    return { start, end };
  }, []);
};

function getTimeMarkFromNode(node: ProsemirrorNode) {
  return node.marks.find(m => m.type.name === 'ts');
}