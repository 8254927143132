import { useCallback, useMemo, Fragment } from 'react';
import { SurveyQuestionType } from '@enums';
import { useSelectRespondentProps, useSelectStarredProjectSurveyAnswer, useInitialStarredResponses, useProjectSurveyResponsesState } from '@containers/SurveyResponses/hooks';
import { ResponseItem, ResponseItemProps } from '@presentation/ProjectSurveyResponses';
import type { SurveyQuestion, MultiTextboxQuestion, HybridSurveyResponseUserId } from '@/types';
import { ControlledTablePagination } from '@presentation/TablePagination';
import styles from './style/ResponseText.css';

type Props = {
  question: MultiTextboxQuestion.Question;
  responses: MultiTextboxQuestion.Aggregate.Data;
};

type ResponseItem = {
  values: string[];
  userId: HybridSurveyResponseUserId;
};

type ResponseTextListProps = {
  items: ResponseItem[];
  renderItem: (props: RenderProps) => React.ReactNode;
  question: SurveyQuestion;
};

type RenderProps = Omit<ResponseItemProps, 'value'> & { values: string[] };

export const useSortStarrableResponses = (questionId: number) => {
  const starred = useInitialStarredResponses(questionId);

  const selectRespondentProps = useSelectRespondentProps();

  const sort = useCallback((items: ResponseItem[]) => {
    return items
      .sort((a, b) => {
        const responseIdA = selectRespondentProps(a.userId).responseId;
        const respondeIdB = selectRespondentProps(b.userId).responseId;
        const aStarred = starred.includes(responseIdA);
        const bStarred = starred.includes(respondeIdB);
        return (aStarred === bStarred) ? 0 : aStarred ? -1 : 1;
      });
  }, [
    selectRespondentProps,
    starred,
  ]);

  return sort;
};

export const ResponseTextList = ({ question, renderItem, items }: ResponseTextListProps) => {

  const selectRespondentProps = useSelectRespondentProps();
  const selectStarred = useSelectStarredProjectSurveyAnswer(question.base.id);

  const values = useMemo(() => items
    .map(item => ({
      respondent: selectRespondentProps(item.userId),
      values: item.values,
    })), [selectRespondentProps, items]);

  return (
    <div>
      {
        values.map(item => {
          const starred = selectStarred(item.respondent.responseId);
          return (
            <Fragment key={item.respondent.user.id}>
              {renderItem({
                onToggleStar: starred.onToggle,
                respondent: item.respondent,
                starred: starred.value,
                values: item.values,
              })}
            </Fragment>
          );
        })
      }
    </div>
  );
};

const pageSize = 50;

const ResponseText = (props: Props) => {

  const { query, setQueryState } = useProjectSurveyResponsesState<SurveyQuestionType.ShortTextResponse>();
  const page = useMemo(() => query.page || 1, [query.page]);

  const sortValues = useSortStarrableResponses(props.question.base.id);

  const items: ResponseItem[] = useMemo(() => {
    return Object.keys(props.responses).map(userId => {
      return {
        userId,
        values: props.responses[userId],
      };
    });
  }, [
    props.responses,
  ]);

  const sortedItems = useMemo(() => {
    return sortValues(items);
  }, [
    items,
    sortValues,
  ]);

  const paginatedItems = useMemo(() => {
    return sortedItems
      .slice((page - 1) * pageSize, page * pageSize);
  }, [
    page,
    sortedItems,
  ]);

  const renderItem = useCallback((data: RenderProps) => {
    return (
      <ResponseItem
        {...data}
        value={<Values values={data.values} labels={props.question.settings.entryLabels} />} />
    );
  }, [props.question.settings.entryLabels]);

  const handlePageChange = useCallback((pageIndex: number) => {
    setQueryState({ page: pageIndex + 1 });
  }, [
    setQueryState,
  ]);

  const pageCount = useMemo(() => Math.ceil(items.length / pageSize), [items.length]);

  return (
    <div className={styles.root}>
      <ResponseTextList
        items={paginatedItems}
        question={props.question}
        renderItem={renderItem} />
      {items.length > pageSize &&
        <div className={styles.footer}>
          <ControlledTablePagination
            goToPage={handlePageChange}
            pageIndex={page - 1}
            pageCount={pageCount}
            pageSize={pageSize}
            totalCount={items.length} />
        </div>
      }
    </div>
  );
};

type ValuesProps = {
  labels: string[];
} & Pick<RenderProps, 'values'>;

const Values = ({ labels, values }: ValuesProps) => {
  if (!values.length) return <>N/A</>;

  return (
    <>
      {values.map((v, i) => {
        const label = labels[i] || `${i + 1}`;
        return (
          <div key={i}>
            {label}) {v}
          </div>);
      })}
    </>);
};

export { ResponseText };
export default ResponseText;