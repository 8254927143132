import { path } from '@consts';
import { RouteAuth } from '@enums';
import { BasicRouter } from '@routes/Routers';
import * as Routing from '@routes/interfaces';
import { ProjectEntry } from '@screens/ExternalSourcingProject';

export const routes: Routing.Route[] = [
  {
    component: ProjectEntry,
    exact: true,
    path: path.ExternalSourcingProject.Project,
  },
];

export const router = BasicRouter(routes, {
  auth: RouteAuth.Ignore,
  path: path.ExternalSourcingProject.Root,
});