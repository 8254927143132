import { Middleware } from '@reduxjs/toolkit';

export const thunk: Middleware = ({ dispatch, getState }) => {
  return next => action =>
    typeof action === 'function'
      ? action(dispatch, getState)
      : next(action);
};

export const apply = (middleware: Middleware[]) => {
  return middleware.concat(thunk);
};

export default {
  apply,
  middleware: thunk,
};