import { useEditorState } from '@remirror/react';
import { useCallback } from 'react';
import { buildDocumentSnippet } from '../utils';
import { useMapTimePosToDocPos } from './useMapPositions';

export const useBuildTagContent = () => {
  const editor = useEditorState();
  const mapTimePosToDocPos = useMapTimePosToDocPos();
  return useCallback((fromTs: number, toTs: number) => {
    const { from, to } = mapTimePosToDocPos({ fromTs, toTs });
    return buildDocumentSnippet(editor.doc, from, to);
  }, [editor.doc, mapTimePosToDocPos]);
};