import { useContext } from 'react';
import { SignupFormContext } from '$website/containers/Auth/Context';

const useSignupForm = () => {
  const context = useContext(SignupFormContext);

  if (!context && process.env.__DEV__) {
    throw new Error('`useSignupForm` must be wrapped inside an `AuthContainer`');
  }

  return context;
};

export { useSignupForm };
export default useSignupForm;