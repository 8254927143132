import { useSignupModal } from '$website/components/AuthModal/useSignupModal';
import { Button } from '@/components/Button';
import styles from './style/Banner.Join.css';

type Props = unknown;

export const Join = (props: Props) => {
  const [open, setSignupModalOpen] = useSignupModal();

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.title}>Join the Club</div>
        <div className={styles.subtitle}>Exchange Ideas and Perspectives with the World’s Brightest Professionals</div>
        <Button
          className={styles.btn}
          onClick={() => setSignupModalOpen(true)}>
          Get Started
        </Button>
      </div>
    </div>
  );
};

Join.displayName = 'Banner.Join';