import { useMemo } from 'react';
import type { Modifier } from 'react-popper';
import { useTheme } from '@/brand-insights/components/Theme';

export const useZIndexModifier = () => {
  const theme = useTheme();
  const zIndex = theme.zIndex.baseModal + 1;

  return useMemo(() => {
    const modifier: Modifier<'zIndexer'> = {
      name: 'zIndexer',
      enabled: true,
      phase: 'beforeWrite',
      requires: ['computeStyles'],
      fn: ({ state }) => {
        state.styles.popper.zIndex = `${zIndex}`;
      },
      effect: ({ state }) => {
        state.elements.popper.style.zIndex = `${zIndex}`;
      },
    };
    return modifier;
  }, [zIndex]);
};

export const useSameWidthModifier = () => {

  return useMemo(() => {
    const modifier: Modifier<'sameWidth'> = {
      name: 'sameWidth',
      enabled: true,
      phase: 'beforeWrite',
      requires: ['computeStyles'],
      fn: ({ state }) => {
        state.styles.popper.width = `${state.rects.reference.width}px`;
      },
      effect: ({ state }) => {
        const refWidth = (state.elements.reference as HTMLDivElement).offsetWidth;
        state.elements.popper.style.width = `${refWidth}px`;
      },
    };
    return modifier;
  }, []);
};