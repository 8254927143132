import { useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import Toast from '@/components/Toast';

const DEFAULT_TIMEOUT_MS = 30 * 1000;
const WARN_TIMEOUT_MS = 3 * 1000;

type Props = {
  onReconnectTimeout: () => void;
  reconnectTimeoutMs?: number;
};

type ToastId = string | number;

export const useReconnectHandler = ({ onReconnectTimeout, reconnectTimeoutMs = DEFAULT_TIMEOUT_MS }: Props) => {
  const disconnectTimeoutRef = useRef<NodeJS.Timeout>();
  const warnTimeoutRef = useRef<NodeJS.Timeout>();
  const toastRef = useRef<ToastId>();

  const clearRefs = useCallback(() => {
    if (toastRef.current) {
      toast.dismiss(toastRef.current);
      toastRef.current = null;
    }
    if (disconnectTimeoutRef.current) {
      clearTimeout(disconnectTimeoutRef.current);
      disconnectTimeoutRef.current = null;
    }

    if (warnTimeoutRef.current) {
      clearTimeout(warnTimeoutRef.current);
      warnTimeoutRef.current = null;
    }
  }, []);

  const onReconnecting = useCallback(() => {
    clearRefs();

    warnTimeoutRef.current = setTimeout(() => {
      toastRef.current = Toast.warn({
        title: 'Bad network connection detected. Trying to reconnect...',
        autoClose: reconnectTimeoutMs,
      });
      warnTimeoutRef.current = null;
    }, WARN_TIMEOUT_MS);

    disconnectTimeoutRef.current = setTimeout(onReconnectTimeout, reconnectTimeoutMs);
  }, [onReconnectTimeout, reconnectTimeoutMs, clearRefs]);

  const onReconnected = useCallback(() => {
    clearRefs();

  }, [clearRefs]);

  return [onReconnecting, onReconnected] as const;
};