import { AudioPlayer } from '@/components/AudioPlayer';
import { VideoPlayer } from '@/components/VideoPlayer';
import { AudioPlayerContainer } from '@containers/AudioPlayer';
import { useWorkspaceFileState } from '@/containers/Workspace.File.Preview/hooks';
import { useIsVideo } from '@utils/hooks';
import styles from './style/File.Preview.css';

type Props = {
  url: string;
};

export const VideoPreview = ({ url }: Props) => {

  const isVideo = useIsVideo(url);

  if (!isVideo) {
    return <AudioPreview url={url} />;
  }

  return (
    <div className={styles.mediaWrap}>
      <VideoPlayer
        controls
        className={styles.videoPlayer}
        src={url} />
    </div>
  );
};

export const AudioPreview = ({ url }: Props) => {
  const { file } = useWorkspaceFileState();

  const audio =  {
    preload: 'metadata',
    src: url,
    title: file.name,
  };
  return (
    <div className={styles.mediaWrap}>
      <div className={styles.audio}>
        <AudioPlayerContainer>
          <AudioPlayer audio={audio} />
        </AudioPlayerContainer>
      </div>
    </div>
  );
};