import { Brand } from '@/components/icons';
import CountBadge from './CountBadge';

type Props = {
  count: number;
  to: string;
};

const BrandsCount = (props: Props) => (
  <CountBadge
    count={props.count}
    icon={<Brand height={20} />}
    to={props.to} />
);

export { BrandsCount };
export default BrandsCount;