import { useContext, useMemo, useState } from 'react';
import { useEditorState } from '@remirror/react';
import type { ColumnDef, SortingState } from '@tanstack/react-table';
import { useReactTable, getCoreRowModel, getSortedRowModel } from '@tanstack/react-table';
import { TranscriptHydrationContext, TranscriptIdContext, TranscriptTimestampPositionsContext } from '@containers/Transcript/context';
import { useTranscriptTagsContext } from '@containers/Transcript.Tagging/hooks';
import { Collapser, SeeMore } from '@presentation/SeeMore';
import { SortableTable } from '@presentation/Tables';
import type { TaggedMoment } from '@/types/transcribe.rich-text';
import { TaggedMomentMenu } from '@/components/Call.TaggedMoment.Menu';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import { RichText, Skeleton, TagBadge, TimeRange, TranscriptSnippet } from '@/components/Transcript';
import { useMapTimePosToDocPos, useInitializeTimestampPositions } from '@/components/Transcript/hooks';
import styles from './style/Tab.Highlights.css';

type Props = unknown;

export const Highlights = (props: Props) => {
  const hydration = useContext(TranscriptHydrationContext);

  if (!(hydration.state.comments && hydration.state.highlights && hydration.state.tags && hydration.state.transcript)) {
    return (
      <Skeleton.Highlights rows={4} />
    );
  }

  return (
    <RichText.Container>
      <HighlightsTab />
    </RichText.Container>
  );
};

Highlights.displayName = 'Transcript.Tab.Highlights';

const HighlightsTab = () => {
  const [tags] = useTranscriptTagsContext();
  const timestamps = useContext(TranscriptTimestampPositionsContext);
  const transcriptId = useContext(TranscriptIdContext);

  const [sorting, setSorting] = useState<SortingState>([]);

  const columns = useMemo<ColumnDef<TaggedMoment>[]>(() => [
    {
      id: 'timestamp',
      header: 'Timestamp',
      accessorFn: tag => tag.fromTs,
      cell: ({ row }) => (
        <TimeRange startSeconds={row.original.fromTs} endSeconds={row.original.toTs} />
      ),
      meta: {
        className: styles.timeCell,
      },
    }, {
      id: 'tag',
      header: 'Tag',
      accessorFn: tag => tag.name,
      cell: ({ row }) => (
        <TagBadge tag={row.original} />
      ),
      meta: {
        className: styles.tagCell,
      },
    }, {
      id: 'snippet',
      header: 'Text',
      cell: ({ row }) => (
        <TagSnippet tag={row.original} />
      ),
      meta: {
        className: styles.textCell,
      },
    }, {
      id: 'actions',
      header: '',
      cell: ({ row }) => (
        <TaggedMomentMenu
          transcriptId={transcriptId}
          momentId={row.original.id}
          tag={row.original} />
      ),
      meta: {
        className: styles.actionsCell,
      },
    },
  ], [transcriptId]);

  const table = useReactTable<TaggedMoment>({
    data: tags.items.sort((a, b) => a.fromTs - b.fromTs),
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  //Needed for text selection mapping
  useInitializeTimestampPositions();

  if (!timestamps.size) return <ActivityIndicator />;

  if (!tags.items.length) {
    return (
      <div className={styles.root}>
        <div className={styles.emptyMsg}>
          No highlights available.
        </div>
        <div className={styles.emptyMsg}>
          Tag and highlight the transcript to view highlights from the interview.
        </div>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <SortableTable table={table} />
    </div>
  );
};

type TagDetailProps = {
  tag: TaggedMoment;
};

const TagSnippet = ({ tag }: TagDetailProps) => {
  const mapTimePosToDocPos = useMapTimePosToDocPos();
  const editorState = useEditorState();

  const { from, to } = useMemo(() => mapTimePosToDocPos(tag), [mapTimePosToDocPos, tag]);

  return (
    <SeeMore
      lineHeight={24}
      maxLines={5}
      CollapserComp={Collapser}>
      <TranscriptSnippet
        from={from}
        to={to}
        state={editorState} />
    </SeeMore>
  );
};