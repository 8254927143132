import { useCallback } from 'react';
import { useSubmitSurveyDraft, useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import { useToggle } from '@utils/hooks';
import { useSurveyBuilderItem } from '@containers/SurveyBuilder/Context';
import { DeleteMessageAlert } from '@/components/SurveyBuilder.Message/Alert.Deletion';
import { SurveyMessageBuilderDeleteContext, useSurveyMessageBuilderItemContext } from './context';

type Props =
  ChildrenProps;

export const MessageBuilderDeleteContainer = (props: Props) => {

  const [_, dispatch] = useSurveyBuilderState();
  const message = useSurveyMessageBuilderItemContext();
  const item = useSurveyBuilderItem();

  const submitDraft = useSubmitSurveyDraft();

  const [showAlert, toggleAlert] = useToggle(false);

  const confirmDelete = useCallback(() => {
    dispatch({
      type: 'message-removed',
      itemIdentifier: item.identifier,
      messageIdentifier: message.identifier,
    });
    toggleAlert();
    submitDraft();
  }, [
    dispatch,
    toggleAlert,
    item.identifier,
    submitDraft,
    message.identifier,
  ]);

  return (
    <>
      <SurveyMessageBuilderDeleteContext.Provider value={toggleAlert}>
        {props.children}
      </SurveyMessageBuilderDeleteContext.Provider>
      {showAlert &&
        <DeleteMessageAlert
          open={true}
          onConfirm={confirmDelete}
          onClose={toggleAlert} />
      }
    </>
  );
};