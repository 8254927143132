import { useCallback, useState } from 'react';
import { useTargetProductProfileData, useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';

export const useCanSaveDrugIndication = (value: string) => {

  return value.length >= 2;
};

export const useTemplateDrugIndication = () => {
  const [templateData] = useTargetProductProfileData();
  const [drugIndication, setDrugIndication] = useState(templateData.drugIndication);
  const [_, dispatch] = useSurveyBuilderState();

  const onSubmit = useCallback(() => {
    dispatch({
      type: 'template-drug-indication-updated',
      value: drugIndication,
    });
  }, [
    drugIndication,
    dispatch,
  ]);

  return {
    onChange: setDrugIndication,
    onSubmit,
    value: drugIndication,
  };
};