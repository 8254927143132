import { useCallback, useState } from 'react';
import { useCoordinator, useConferenceInstance } from '@containers/Conference';
import styles from '@screens/Conference.Room.Video/style/Bar.Right.Participants.css';
import { EnumDropDown } from '@/components/DropDown';
import { CallRole, utils as enumUtils } from '@/enums';
import { PhoneInputControlled, TelInputState } from '@/components/TelInput';
import { Button } from '@/components/Button';
import Toast from '@/components/Toast';

export function BarRightParticipantsAdd() {
  const { dialOutParticipant } = useCoordinator();
  const [phoneState, setPhoneState] = useState<TelInputState>();
  const [dialOutRole, setDialOutRole] = useState(CallRole.Respondent);
  const instance = useConferenceInstance();
  const addParticipant = useCallback(() => {
    dialOutParticipant({
      conferenceIdentifier: instance.conferenceIdentifier,
      dialOutValue: phoneState.value,
      role: dialOutRole,
    });
    setPhoneState({ value: '', valid: false, empty: true });
    Toast.success({
      title: `${phoneState.value} has been invited to the conference`,
    });
  }, [dialOutParticipant, instance, phoneState, setPhoneState, dialOutRole]);
  return (
    <>
      <div className={styles.subHeader}>
        Add Attendees
      </div>
      <div className={styles.roleSelector}>
        <EnumDropDown
          enum={enumUtils.CallRole}
          items={validDialoutRoles}
          onSelect={r => setDialOutRole(r)}
          value={dialOutRole} />
      </div>
      <div className={styles.row}>
        <PhoneInputControlled value={phoneState?.value ?? ''} onChange={setPhoneState} />
        <Button
          className={styles.addParticipantBtn}
          disabled={!phoneState?.value || !phoneState?.valid}
          onClick={addParticipant}
          variant='brick'>Add
        </Button>
      </div>
    </>
  );
}

const validDialoutRoles = [CallRole.Attendee, CallRole.Respondent];