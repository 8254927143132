import { useSearchQuery } from '@containers/Search/hooks/useSearchQuery';
import { Button } from '@/components/Button';
import QueryStats from '@/scenes/search/SearchListHeader/QueryStats';
import styles from './style/ResultsHeader.css';

type Props = {
  onShow: () => unknown;
};

const ResultsHeader = (props: Props) => {
  const [query] = useSearchQuery();

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        {!query.loading && <QueryStats count={query.value.total} />}
        <Button
          className={styles.btn}
          onClick={props.onShow}
          variant="pill">
          Filters
        </Button>
      </div>
    </div>
  );
};

export { ResultsHeader };
export default ResultsHeader;