/* import { ComplianceOnboarding } from '@containers/ComplianceOnboarding'; */
import { useSelectProject } from '@containers/Store';
import { useProjectOnboardingContext, useSubmitSignature } from '@containers/ProjectOnboarding/hooks';
/* import { ComplianceIntroScreen, QuestionsScreen, RulesScreen, TestScreen } from '@screens/ComplianceOnboarding'; */
import { default as SignatureRequest } from '@screens/EmbeddedSign';
import { useStepper } from '@utils';
import { SurveyTerms as ProjectSurveyTerms } from './Compliance.SurveyTerms';

type Props = unknown;

export const EmbeddedSign = (props: Props) => {
  const ctx = useProjectOnboardingContext();
  const project = useSelectProject(ctx.projectId);
  const submitSignature = useSubmitSignature();

  const handleCompletion = () => {
    return submitSignature()
      .finally(ctx.next);
  };

  return (
    <SignatureRequest
      groupId={project.groupId}
      onAbort={ctx.next}
      onSignatureSubmit={handleCompletion} />
  );
};
/*
export const OnboardingStepper = (props: Props) => {
  const ctx = useProjectOnboardingContext();
  const [Screen, actions, step] = useStepper([
    ComplianceIntroScreen,
    Terms,
    TestScreen,
    Questionnaire,
  ]);

  return (
    <ComplianceOnboarding
      back={actions.back}
      next={actions.next}
      onCompletion={ctx.next}
      projectId={ctx.projectId}>
      <Screen />
    </ComplianceOnboarding>
  );
};

export const Questionnaire = (props: Props) => {
  const title = `Now provide us just a couple more details`;

  return (
    <QuestionsScreen title={title} />
  );
}; */

export const SurveyTerms = (props: Props) => {
  const ctx = useProjectOnboardingContext();

  return (
    <ProjectSurveyTerms onSubmit={ctx.next} />
  );
};

/*
export const Terms = (props: Props) => {
  const title = `Here are the compliance terms for projects you participate in on Sentiment.`;

  return (
    <RulesScreen title={title} />
  );
};
*/