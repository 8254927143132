import { useCallback, useState } from 'react';

export const useToggle = (initialValue?: boolean): [boolean, () => void] => {
  const [value, setValue] = useState(!!initialValue);

  const toggle = useCallback(() => {
    setValue(t => !t);
  }, []);

  return [value, toggle];
};

export default useToggle;