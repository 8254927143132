import { useCallback, useMemo, useState } from 'react';
import { useSelectGroup } from '@containers/Store/hooks';
import { useHasClientRole } from '@containers/User/hooks';
import type { BrandInsightsPageContext } from '@/brand-insights';
import { BrandInsights, ChatPageContextDataContext, DispatchPageContextDataContext } from '@/brand-insights';
import { BrandInsightsModalContext } from '@/brand-insights/components/BrandInsights/context';
import { useBrandInsightsAuthor, useBrandInsightsCapabilities } from './hooks';

type Props = ChildrenProps;

export const BrandInsightsContainer = (props: Props) => {

  const [contextData, setContextData] = useState<BrandInsightsPageContext.Item>(null);

  const author = useBrandInsightsAuthor();
  const capabilities = useBrandInsightsCapabilities();
  const hasClientRole = useHasClientRole();
  const group = useSelectGroup();

  const [open, setOpen] = useState(false);
  const handleToggle = useCallback(() => setOpen(prev => !prev), []);

  const enabled = useMemo(() => {
    return !!contextData &&
      hasClientRole &&
      group?.features?.insightsChat;
  }, [
    contextData,
    hasClientRole,
    group?.features,
  ]);

  const modalCtx = useMemo(() => [open, handleToggle] as [boolean, () => void], [open, handleToggle]);

  return (
    <BrandInsightsModalContext.Provider value={modalCtx}>
      <DispatchPageContextDataContext.Provider value={setContextData}>
        <ChatPageContextDataContext.Provider value={contextData}>
          {enabled && <BrandInsights author={author} capabilities={capabilities} />}
          {props.children}
        </ChatPageContextDataContext.Provider>
      </DispatchPageContextDataContext.Provider>
    </BrandInsightsModalContext.Provider>
  );
};