import { useMemo } from 'react';
import { useSurveyBuilderState } from './useSurveyBuilderContext';
import { useIsEditingSurvey } from './useIsEditingSurvey';

export const useCanPreviewSurvey = () => {
  const [state] = useSurveyBuilderState();
  const isEditing = useIsEditingSurvey();

  const surveyVersionId = state.draft.surveyVersionId || state.savedSurvey?.id;

  const canPreview = useMemo(() => {
    return !isEditing &&
      state.draft.status === 'idle' &&
      !!surveyVersionId &&
      !!state.survey.items.length;
  }, [isEditing, state.draft.status, surveyVersionId, state.survey.items.length]);

  return {
    canPreview,
    surveyVersionId,
  };
};