import { useCallback } from 'react';
import { useEditorState } from '@remirror/react';
import { MessageTesting } from '@enums/survey.template';
import { SurveyBuilder } from '@containers/SurveyBuilder/interfaces';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder/hooks/useSurveyBuilderContext';
import { isSurveyRichTextEmpty } from '@/containers/SurveyBuilder/utils/rich-text.validate';
import { useUploadSurveyImages } from '@/components/Survey.RichText/hooks/useUploadImages';
import * as $template from '@containers/SurveyBuilder/template/utils';
import { SurveyBuilderVersion, SurveyRichText } from '@/types';
import { useRichTextEditorRegistryEditor } from '@/containers/SurveyBuilder/hooks';

export const useCanSaveRichTextTpp = () => {

  const editor = useEditorState();

  return useCallback(() => {
    const value = editor.doc.toJSON() as SurveyRichText.RichTextValue;
    const isEmpty = isSurveyRichTextEmpty(value);
    return !isEmpty;
  }, [editor.doc]);
};

export const useSubmitRichTextTPP = () => {
  const [state, dispatch] = useSurveyBuilderState();
  const { getEditorByIdentifier } = useRichTextEditorRegistryEditor();

  const getEditor = useCallback(() => getEditorByIdentifier(`tpp-text-input`), [getEditorByIdentifier]);

  const uploadImages = useUploadSurveyImages();

  const handleSubmit = useCallback(async () => {

    const { value } = await uploadImages({
      getContext: getEditor().getContext,
    });

    const actions = computeActions({
      saved: state.survey,
      value,
    });
    // console.log('actions', actions);

    dispatch({
      actions,
      type:'batch-actions',
    });

  }, [
    dispatch,
    state.survey,
    uploadImages,
    getEditor,
  ]);

  return handleSubmit;
};

type ComputeActions = {
  saved: SurveyBuilderVersion;
  value: SurveyRichText.RichTextValue;
};

function computeActions({ saved, value }: ComputeActions): SurveyBuilder.NonBatchActions[] {

  const actions: SurveyBuilder.NonBatchActions[] = [];

  actions.push({
    type: 'template-tpp-updated',
    value,
  });

  const message = saved.messages.find(f => f.metadata.template?.key === MessageTesting.Message.TPPIntro);

  if (!message) return actions;

  const messageValue = $template.generateRichTextNode([
    $template.generateParagraphNode([
      $template.generateTextNode(`Please review the following TPP, and then answer the following questions. If at any time you would like to review the TPP again, click on the "View TPP” on the screen.`),
    ]),
    $template.generateParagraphNode([]),
    ...value.content,
  ]);

  actions.push({
    type: 'message-value-updated',
    messageIdentifier: message.identifier,
    value: messageValue,
  });

  return actions;
}