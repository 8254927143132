import { CellItem } from '@containers/Group.Contacts/interfaces';
import styles from './style/Table.css';

type Props = CellItem;

export const Cell = (props: Props) => {
  const { tags } = props.row.original;

  if (!tags.length) return null;

  const [visible, ...rest] = tags;

  return (
    <div className={styles.tags}>
      <div className={styles.tag}>{visible.name}</div>
      {rest.length > 0 && <div className={styles.tag}>{`+${rest.length}`}</div>}
    </div>
  );
};

Cell.displayName = 'Column.Tags.Cell';