import { useCallback } from 'react';
import { ConferenceSocketEvent } from '@/enums';
import type { ConferenceLogContext, ConferenceLogType } from '@/types';
import { useSocket } from './useSocket';

export const useLogConferenceEvent = () => {
  const ws = useSocket();

  return useCallback(<T extends ConferenceLogType>(type: T, context: ConferenceLogContext<T>) => {
    ws.raw.emit(ConferenceSocketEvent.LogEvent, {
      event: {
        typeId: type,
        context,
      },
    });
  }, [ws.raw]);

};