import { useCallback } from 'react';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import type { ReactFrameworkOutput } from '@remirror/react';
import type { RemirrorJSON } from 'remirror';
import { uploadSurveyImageAttachment } from '@api/surveys';
import type { ImageObject } from '@/components/Remirror/Extension.Image.ImageState';
import type { SurveyRichText } from '@/types';
import type { SurveyRichTextEditorExtension } from '../interfaces';

type MutationParams = {
  images:     ImageObject[];
};

type Options =
  UseMutationOptions<
    ImageObject[],
    unknown,
    MutationParams>;

export const useUploadImageObjectsMutation = (options?: Options) => {
  const mutation = useMutation(['upload-survey-images'], (data: MutationParams) => {
    return Promise.all(data.images.map(image => uploadImage({
      image,
    })));
  }, options);

  return mutation;
};

const uploadImage = async (data: UploadImage) => {
  const blob = await fetch(data.image.src)
    .then(res => res.blob());

  return uploadSurveyImageAttachment({
    identifier: data.image.cid,
    blob,
  })
  .then(res => {
    return {
      cid: data.image.cid,
      src: res.attachment.url,
    };
  });
};

export const useUploadSurveyImages = () => {
  const { mutateAsync: uploadImages } = useUploadImageObjectsMutation();

  const getImageHelpers = useCallback(({ getContext }: GetImageHelpersProps) => {
    const helpers = getContext().helpers;

    return {
      createImageURLLookup: helpers?.createImageURLLookup as ((targets: ImageObject<string>[], sources: ImageObject<string>[]) => Record<string, string>),
      findImageObjects: helpers?.findImageObjects as (() => ImageObject<string>[]),
      replaceImageSrc: helpers?.replaceImageSrc as ((lookup: Record<string, string>) => RemirrorJSON),
    };
  }, []);

  const submitImages = useCallback(async ({ getContext }: UseUploadSurveyImagesProps): Promise<SubmitImagesResult> => {
    const { createImageURLLookup, findImageObjects, replaceImageSrc } = getImageHelpers({ getContext });
    const editor = getContext().manager;
    const images = findImageObjects();
    const imagesToUpload = images.length && images.filter(f => f.src.startsWith('data:'));

    if (!imagesToUpload.length) {
      return {
        updated: false,
        value: editor.view.state.doc.toJSON() as SurveyRichText.RootNode,
      };
    }

    const sources = await uploadImages({
      images: imagesToUpload,
    });

    const lookup = createImageURLLookup(imagesToUpload, sources);

    const content = replaceImageSrc(lookup);

    editor.view.updateState(editor.createState({ content }));

    return {
      updated: true,
      value: content as SurveyRichText.RootNode,
    };

  }, [
    getImageHelpers,
    uploadImages,
  ]);

  return submitImages;
};

type UploadImage = {
  image: ImageObject;
};

type UseUploadSurveyImagesProps = {
  getContext: () => ReactFrameworkOutput<SurveyRichTextEditorExtension>;
};

type GetImageHelpersProps = UseUploadSurveyImagesProps;

type SubmitImagesResult = {
  updated: boolean;
  value: SurveyRichText.RootNode;
};