import { useCallback, useMemo, memo, useRef } from 'react';
import { Redirect, Route, Switch, useHistory, generatePath, matchPath, useLocation } from 'react-router-dom';
import * as consts from '@consts';
import { SearchMembers } from '@screens/Search.Members';
import { SearchLeads } from '@screens/Search.Leads';
import { BuildSceneMap, ResultTab, Scene, SceneMap } from '$admin/Search/interfaces/search';
import { TabView, TabViewRenderParams } from '@/components/TabView';

type Props = unknown;

export const SearchTabView = (props: Props) => {
  const history = useHistory();
  const location = useLocation();
  const queryParamCache = useRef<Partial<Record<ResultTab, string>>>({ });

  const scenes = useMemo(() => buildSceneMap(), []);

  const matchTabIndexFromLocation = useCallback((scenes: SceneMap) => {
    const matchedScene = Object.values(scenes).find(s => matchPath(location.pathname, {
      path: s.location.pathname,
    }));

    if (!matchedScene) return 0;

    return Object.keys(scenes).indexOf(matchedScene.key);
  }, [location.pathname]);

  const index = useMemo(() => matchTabIndexFromLocation(scenes), [matchTabIndexFromLocation, scenes]);

  const changeLocation = useCallback((idx: number) => {
    const tab = Object.keys(scenes)[idx] as ResultTab;
    const location = scenes[tab].location;
    const oldTab = Object.keys(scenes)[index] as ResultTab;

    queryParamCache.current[oldTab] = history.location.search;

    history.push({
      ...location,
      search: queryParamCache.current[tab],
    });
  }, [history, scenes, index]);

  const routes = useMemo(() => Object.values(scenes), [scenes]);

  const renderer = useCallback(() => <RenderTabView />, []);

  return (
    <TabView<Scene>
      index={index}
      onIndexChange={changeLocation}
      renderTabView={renderer}
      routes={routes} />
  );
};

const RenderTabView = memo(() => {
  return (
    <Switch>
      <Route
        component={SearchMembers}
        exact
        path={consts.path.Search.Experts} />
      <Route
        component={SearchLeads}
        exact
        path={consts.path.Search.Leads} />
      <Redirect
        exact
        from={consts.path.Search.Root}
        to={consts.path.Search.Experts} />
    </Switch>
  );
});

function buildSceneMap(): BuildSceneMap.Return {
  const scenes: SceneMap = {
    [ResultTab.Members]: {
      key: ResultTab.Members,
      title: `Experts`,
      location: {
        pathname: generatePath(consts.path.Search.Experts),
      },
    },
    [ResultTab.Leads]: {
      key: ResultTab.Leads,
      title: `Leads`,
      location: {
        pathname: generatePath(consts.path.Search.Leads),
      },
    },
  };

  return Object.entries(scenes)
    .reduce<SceneMap>((acc, [key, val]) => {
    return val
          ? { ...acc, [key]: val }
          : acc;
  }, {} as SceneMap);
}