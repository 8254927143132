import { Fragment } from 'react';
import { Row } from 'react-table';
import { cx } from '@utils';
import { TableBody } from '@presentation/TableBody';
import { TableRow } from '@presentation/TableRow';
import ActivityIndicator from '@/components/ActivityIndicator';
import styles from './style/Table.Body.css';

type Props<T extends object> = {
  className?: string;
  loading?: boolean;
  keyExtractor: (item: T) => string;
  renderLoadingIndicator?: () => React.ReactElement;
  renderNoItems?: () => React.ReactElement;
  prepareRow: (row: Row<T>) => void;
  rows: Row<T>[];
};

const Body = <T extends object>(props: Props<T>) => {
  if (props.loading) {
    if (props.renderLoadingIndicator) {
      return props.renderLoadingIndicator();
    } else {
      return (
        <div className={cx(styles.loadingRow, props.className)}>
          <ActivityIndicator show={true} />
        </div>
      );
    }
  }

  if (!props.rows.length && props.renderNoItems) {
    return props.renderNoItems();
  }

  return (
    <TableBody className={props.className}>
      {props.rows.map((row, i) => {
        props.prepareRow(row);
        return (
          <TableRow
            key={props.keyExtractor(row.original)}
            {...row.getRowProps()}>
            {row.cells.map(cell => (
              <Fragment
                key={cell.column.id}>
                {cell.render('Cell')}
              </Fragment>
            ))}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default Body;