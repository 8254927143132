import { AxiosResponse } from 'axios';
import { useQueryDownloader, UseDownloaderOptions } from '@containers/QueryDownloader';
import * as $api from '@api/transcripts';
import { Transcripts } from '@api/interfaces';

type Data = Transcripts.DownloadSummary.Response;
type Params = ITranscriptId & {
  format: 'pptx' | 'docx';
  tagIds: number[];
};
type QueryKey = readonly [string, Params];

type Options = UseDownloaderOptions<Data>;

export const useDownloadTranscriptSummary = ({ format, tagIds, transcriptId }: Params, options: Options = {}) => {
  return useQueryDownloader({
    queryKey: [
      `get:transcripts/summary/export`,
      transcriptId,
      format,
      tagIds,
    ],
    queryFn: () => {
      return $api.downloadSummary({
        format,
        tagIds,
        transcriptId,
      });
    },
    meta: {
      downloader: true,
    },
    downloaderOptions: {
      onResponse: res => ({
        filename: res.name,
        type: 'blob',
        value: res.file,
      }),
    },
    ...options,
  });
};