import { ActiveFilters } from './ActiveFilters';
import { MatchListFilter } from './MatchListFilter';
import { PendingFilter } from './PendingFilter';
import styles from './style/Filters.css';

export function Filters() {
  return (
    <div className={styles.root}>
      <div className={styles.new}>
        <PendingFilter />
      </div>
      <div className={styles.active}>
        <ActiveFilters />
        <MatchListFilter />
      </div>
    </div>
  );
}