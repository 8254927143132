import { ImageCrop } from '@/components/ImageCrop';
import { useModal } from '../hooks';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import styles from './style.css';

type Props = {
  onSave:     (data: Blob) => unknown;
  pictureUrl: string;
} & Pick<ModalProps, 'onClose' | 'open'>;

export const ProfileAvatar = ({ onClose, onSave, open, pictureUrl }: Props) => {
  return (
    <Modal
      onClose={onClose}
      open={open}>
      <div className={styles.title}>Edit Profile Photo</div>
      <div className={styles.root}>
        <ImageCrop
          circularCrop={true}
          imageUrl={pictureUrl}
          onSave={data => onSave(data.blob)}
          previewClassName={styles.preview}
          ruleOfThirds={true} />
      </div>
    </Modal>
  );
};

export const useProfileAvatarModal = () => useModal(ProfileAvatar);