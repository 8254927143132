import type { CurrencyLegacy } from './survey.currency';

export enum SurveyQuestionNumberType {
  Number = 0,
  Currency = 1,
  Unit = 2
}

export type SurveyQuestionNumberSettings = {
  type: SurveyQuestionNumberType;
  unitType?: string;
  currency?: CurrencyLegacy;
};
export declare namespace SurveySettings {

  export type Randomization = {
    randomization: boolean;
  };

  export type RandomizeRows = {
    randomizeRows: boolean;
  };

  export type RandomizeOptions = {
    randomizeOptions: boolean;
  };

  export type Rationale = {
    enabled: boolean;
    minimum: number;
  };

  export type AskForRationale = {
    rationale: Rationale;
  };

  export type NumberValidations = {
    minValue: number;
    maxValue: number;
  };

  export type NumberSetting = {
    numberSettings: SurveyQuestionNumberSettings;
  };

  export type PipedTotalValue = {
    question: {
      identifier: string;
    };
    option?: {
      identifier: string;
    };
    row: {
      identifier: string;
    };
  };

  export type OrderOptionsBasedOnSource = {
    orderOptionsBasedOnSource: boolean;
  };

  export type OrderRowsBasedOnSource = {
    orderRowsBasedOnSource: boolean;
  };

  export type TotalSetting<T extends Total = Total> = {
    comparate: T;
    operator: Operator;
  };

  export type Total = number | PipedTotalValue;

  export type MatrixDisplayValue = 'grid' | 'list';

  export type MatrixDisplay = {
    display: MatrixDisplayValue;
  };

  export type MatrixGroupByValue = 'row' | 'column';

  export type MatrixGroupBy = {
    groupBy: MatrixGroupByValue;
  };

  export type DontKnow = {
    enabled: boolean;
    label: string;
  };

  export type IDontKnow = {
    dontKnow: DontKnow;
  };
}