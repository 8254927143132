import MenuItem from '@mui/material/MenuItem';
import { forwardRef, useCallback, useContext } from 'react';
import { ActionsMenuContext } from './Context';
import styles from './style/Menu.css';

type Props = {
  onClick: () => void;
  disabled?: boolean;
} & ChildrenProps;

export const ActionButton = forwardRef<HTMLLIElement, Props>(({ onClick, children, disabled }, ref) => {
  const { close } = useContext(ActionsMenuContext);
  const handleClick = useCallback(() => {
    onClick();
    close();
  }, [
    close,
    onClick,
  ]);

  return (
    <MenuItem
      ref={ref}
      className={styles.menuItem}
      onClick={handleClick}
      disabled={disabled}>
      {children}
    </MenuItem>
  );
});