import { SurveyFormItemBackContext, SurveyFormItemSubmitContext, SurveyFormShowCompleteContext } from './Context';
import { useSubmitMessageScreen, useSurveyFormIsFirstItem, useSurveyMessageGoBack } from './hooks';

type Props =
  ChildrenProps;

export const SurveyFormMessageContainer = (props: Props) => {

  const goBack = useSurveyMessageGoBack();
  const submit = useSubmitMessageScreen();

  const isFirstItem = useSurveyFormIsFirstItem();

  const backCtx = {
    disabled: isFirstItem,
    isError: goBack.isError,
    isLoading: goBack.isLoading,
    handler: goBack.mutateAsync,
  };

  const submitCtx = {
    isError: submit.isError,
    isLoading: submit.isLoading,
    handler: submit.mutateAsync,
  };

  return (
    <SurveyFormItemSubmitContext.Provider value={submitCtx}>
      <SurveyFormItemBackContext.Provider value={backCtx}>
        {props.children}
      </SurveyFormItemBackContext.Provider>
    </SurveyFormItemSubmitContext.Provider>
  );
};