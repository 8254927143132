import { SurveyActionType, SurveyConditionType, SurveyQuestionConditionType, SurveySumConditionItemType } from '@/enums';
import type { SurveyLogic } from '@/types';

export function isOptionInCondition({ condition, option }: IsOptionInCondition) {
  if (condition.conditionType === SurveyConditionType.Question) {
    return isOptionInQuestionCondition({ condition, option });
  } else if (condition.conditionType === SurveyConditionType.AggregatedSum) {
    return isOptionInSumCondition({ condition, option });
  }

  return false;
}

export function isOptionInQuestionCondition({ condition, option }: IsOptionInCondition<SurveyConditionType.Question>) {
  return isOptionConditionType(condition.data) &&
    condition.data.value.option.identifier === option.identifier;
}

export function isOptionInSumCondition({ condition, option }: IsOptionInCondition<SurveyConditionType.AggregatedSum>) {
  return condition.data.items.some(s => {
    return s.type === SurveySumConditionItemType.NumberTableCellValue &&
      s.values.some(ss => ss.option.identifier === option.identifier);
  });
}

export function isRowInCondition({ condition, row }: IsRowInCondition) {
  if (condition.conditionType === SurveyConditionType.Question) {
    return isRowInQuestionCondition({ condition, row });
  } else if (condition.conditionType === SurveyConditionType.AggregatedSum) {
    return isRowInSumCondition({ condition, row });
  }

  return false;
}

export function isRowInQuestionCondition({ condition, row }: IsRowInCondition<SurveyConditionType.Question>) {
  return isRowConditionType(condition.data) &&
    condition.data.value.row.identifier === row.identifier;
}

export function isRowInSumCondition({ condition, row }: IsRowInCondition<SurveyConditionType.AggregatedSum>) {
  return condition.data.items.some(s => {
    return s.values.some(ss => ss.row.identifier === row.identifier);
  });
}

export function isQuestionInCondition({ condition, question }: IsQuestionInCondition) {
  if (condition.conditionType === SurveyConditionType.Question) {
    return isQuestionInQuestionCondition({ condition, question });
  } else if (condition.conditionType === SurveyConditionType.AggregatedSum) {
    return isQuestionInSumCondition({ condition, question });
  }

  return false;
}

export function isQuestionInQuestionCondition({ condition, question }: IsQuestionInCondition<SurveyConditionType.Question>) {
  return condition.data.question.identifier === question.identifier;
}

export function isQuestionInSumCondition({ condition, question }: IsQuestionInCondition<SurveyConditionType.AggregatedSum>) {
  return condition.data.items.some(s => s.question.identifier === question.identifier);
}

export function isQuestionInAction({ action, question }: IsQuestionInAction) {

  if (action.type === SurveyActionType.SkipToQuestion) {
    return action.question.identifier === question.identifier;
  } else if (action.type === SurveyActionType.ExcludeQuestionChoice) {
    return action.question.identifier === question.identifier;
  } else if (action.type === SurveyActionType.DisplayQuestionChoice) {
    return action.question.identifier === question.identifier;
  }

  return false;
}

export function isOptionInAction({ action, option }: IsOptionInAction) {

  if (action.type === SurveyActionType.ExcludeQuestionChoice) {
    return action.option?.identifier === option.identifier;
  } else if (action.type === SurveyActionType.DisplayQuestionChoice) {
    return action.option?.identifier === option.identifier;
  }

  return false;
}

export function isRowInAction({ action, row }: IsRowInAction) {

  if (action.type === SurveyActionType.ExcludeQuestionChoice) {
    return action.row?.identifier === row.identifier;
  } else if (action.type === SurveyActionType.DisplayQuestionChoice) {
    return action.row?.identifier === row.identifier;
  }

  return false;
}

export function isSectionInAction({ action, section }: IsSectionInAction) {
  if (action.type === SurveyActionType.SkipToSection ||
    action.type === SurveyActionType.IncludeSection) {
    return action.section.identifier === section.identifier;
  }
}

type IsOptionInCondition<T extends SurveyConditionType = SurveyConditionType> = {
  condition: SurveyLogic.SurveyCondition<T>;
  option: { identifier: string };
};

type IsRowInCondition<T extends SurveyConditionType = SurveyConditionType> = {
  condition: SurveyLogic.SurveyCondition<T>;
  row: { identifier: string };
};

type IsQuestionInCondition<T extends SurveyConditionType = SurveyConditionType> = {
  condition: SurveyLogic.SurveyCondition<T>;
  question: { identifier: string };
};

type IsSectionInAction = {
  action: SurveyLogic.Action;
  section: { identifier: string };
};

type IsQuestionInAction = {
  action: SurveyLogic.Action;
  question: { identifier: string };
};

type IsOptionInAction = {
  action: SurveyLogic.Action;
  option: { identifier: string };
};

type IsRowInAction = {
  action: SurveyLogic.Action;
  row: { identifier: string };
};

function isOptionConditionType(data: SurveyLogic.QuestionConditionData): data is OptionCondition {
  if ([
    SurveyQuestionConditionType.OptionSelected,
    SurveyQuestionConditionType.OptionNotSelected,
    SurveyQuestionConditionType.OptionRankedFirst,
    SurveyQuestionConditionType.OptionRankedLast,
    SurveyQuestionConditionType.MatrixValueChosen,
    SurveyQuestionConditionType.MatrixValueNotChosen,
    SurveyQuestionConditionType.OptionRankedTopX,
  ].includes(data.type)) {
    return true;
  }
}

function isRowConditionType(data: SurveyLogic.QuestionConditionData): data is RowCondition {
  if ([
    SurveyQuestionConditionType.MatrixValueChosen,
    SurveyQuestionConditionType.MatrixValueNotChosen,
    SurveyQuestionConditionType.NumberCellValue,
    SurveyQuestionConditionType.SliderValue,
  ].includes(data.type)) {
    return true;
  }
}

type OptionCondition = SurveyLogic.QuestionConditionData<SurveyLogic.ConditionWithOption>;
type RowCondition = SurveyLogic.QuestionConditionData<SurveyLogic.ConditionWithRow>;