import { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  ProjectQuantAnalysisContainer,
  QuantAnalysisLoadingContext,
  useSelectedQuantAnalysisItem,
} from '@containers/Project.QuantAnalysis';
import { TabViewTableSkeleton } from '@/components/TabView';
import * as Table from '@/components/Table';
import { AnalysesPanel } from './AnalysesList';
import styles from './style/QuantAnalysis.css';
import { AnalysisContent } from './Analysis.Content';
import { QuantAnalysisActions } from './Actions';

const ProjectQuantAnalysis = () => {

  const isLoading = useContext(QuantAnalysisLoadingContext);
  const selectedAnalysisItem = useSelectedQuantAnalysisItem();

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        {!isLoading && (
          <Table.Layout.Box className={styles.table}>
            <Table.Layout.Header title="Analyses">
              <QuantAnalysisActions />
            </Table.Layout.Header>
            <div className={styles.body}>
              <AnalysesPanel className={styles.list} />
              {selectedAnalysisItem && (
                <AnalysisContent
                  className={styles.content}
                  value={selectedAnalysisItem} />
              )}
            </div>
          </Table.Layout.Box>
        )}
        {isLoading && <TabViewTableSkeleton />}
      </div>
    </div>
  );
};

type RouterParams = {
  slug: string;
  quantAnalysisId: string;
};

const WithState = () => {

  const params = useParams<RouterParams>();

  const quantAnalysisId = useMemo(() => {
    return +params.quantAnalysisId;
  }, [params.quantAnalysisId]);

  const projectId = useMemo(() => {
    const pString = params.slug.split('-').pop();
    return +pString;
  }, [params.slug]);

  return (
    <ProjectQuantAnalysisContainer projectId={projectId} quantAnalysisId={quantAnalysisId}>
      <ProjectQuantAnalysis />
    </ProjectQuantAnalysisContainer>
  );
};

export { WithState as ProjectQuantAnalysis };