import { useCallback, useMemo } from 'react';
import Autorenew from '@mui/icons-material/Autorenew';
import { cx } from '@utils';
import { Tooltip } from '@presentation/Tooltip';
import { useParseSurveyRichText, useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import { useQuestionBuilderItemContext } from '@/containers/SurveyBuilder.Question';
import type { SurveyQuestionMatrixRow } from '@/types';
import { canReuseOptions, canReuseRows } from './ReuseQuestionValues.utils';
import { useReuseQuestionModal } from './ReuseQuestionValues.Modal';
import styles from './style/ResponsePiping.css';
import { useReuseAIEImagesModal } from './ReuseAlEImages.Modal';

export const ReuseQuestionOptions = () => {

  const item = useQuestionBuilderItemContext();
  const [toggleModal, Modal] = useReuseQuestionModal();
  const [toggleAIEModal, AIEModal] = useReuseAIEImagesModal();
  const questions = useReusableQuestions();
  const exercises = useReusableAIE();
  const [_, dispatch] = useSurveyBuilderState();

  const hasOptionsLinked = useMemo(() => {
    return Object.keys(item.metadata.linked?.options ?? {}).length > 0;
  }, [item.metadata.linked]);

  const disabled = useMemo(() => {
    return !questions.length ||
      hasOptionsLinked ||
      !!item.options.filter(f => !!f.value).length;
  }, [
    questions.length,
    hasOptionsLinked,
    item.options,
  ]);

  const aieDisabled = useMemo(() => {
    return !exercises.length;
  }, [exercises]);

  const handleSubmit = useCallback((sourceQuestionIdentifier: string, source: 'options' | 'rows') => {
    dispatch({
      type: 'question-options-copied',
      payload: {
        source,
        sourceQuestionIdentifier,
        targetQuestionIdentifier: item.base.identifier,
      },
    });
  }, [dispatch, item.base.identifier]);

  const handleAIESubmit = useCallback((sourceExerciseIdentifier: string, sourceExerciseGroupIdentifier: string) => {
    dispatch({
      type: 'question-options-copied-from-aie',
      payload: {
        sourceExerciseIdentifier,
        sourceExerciseGroupIdentifier,
        targetQuestionIdentifier: item.base.identifier,
      },
    });
  }, [dispatch, item.base.identifier]);

  const handleUnlink = useCallback(() => {
    dispatch({
      type: 'copied-options-removed',
      payload: {
        questionIdentifier: item.base.identifier,
      },
    });
  }, [dispatch, item.base.identifier]);

  if (hasOptionsLinked) {
    return (
      <Tooltip title="Remove linked options">
        <div className={styles.root}>
          <Autorenew
            onClick={handleUnlink}
            style={{ color: 'var(--pri-03)' }}
            className={cx(styles.icon, styles.linked)} />
        </div>
      </Tooltip>
    );
  }

  return (
    <>
      <Tooltip title="Reuse values from previous question">
        <div className={cx(styles.root, {
          [styles.disabled]: disabled,
        })}>
          <Autorenew
            style={{ color: '#9B9B9B' }}
            onClick={disabled ? undefined : toggleModal}
            className={styles.icon} />
        </div>
      </Tooltip>
      {!aieDisabled &&
        <Tooltip title="Reuse values stimuli display">
          <div className={styles.root}>
            <Autorenew
              style={{ color: '#9B9B9B' }}
              onClick={toggleAIEModal}
              className={styles.icon} />
          </div>
        </Tooltip>
      }
      <Modal
        questions={questions}
        onSubmit={handleSubmit} />
      <AIEModal
        exercises={exercises}
        onSubmit={handleAIESubmit} />
    </>
  );
};

export const ReuseQuestionRows = () => {

  const item = useQuestionBuilderItemContext();
  const [toggleModal, Modal] = useReuseQuestionModal();
  const questions = useReusableQuestions();
  const [_, dispatch] = useSurveyBuilderState();
  const getQuestionText = useParseSurveyRichText();

  const hasRowsLinked = useMemo(() => {
    return !!item.metadata.linked?.rows?.question;
  }, [item.metadata.linked]);

  const disabled = useMemo(() => {
    return !questions.length ||
      hasRowsLinked ||
      !!(item.matrixRows as SurveyQuestionMatrixRow[]).filter(f => !!getQuestionText(f.value)).length;
  }, [
    getQuestionText,
    questions.length,
    hasRowsLinked,
    item.matrixRows,
  ]);

  const handleSubmit = useCallback((sourceQuestionIdentifier: string, source: 'options' | 'rows') => {
    dispatch({
      type: 'question-rows-copied',
      payload: {
        source,
        sourceQuestionIdentifier,
        targetQuestionIdentifier: item.base.identifier,
      },
    });
  }, [dispatch, item.base.identifier]);

  const handleUnlink = useCallback(() => {
    dispatch({
      type: 'copied-rows-removed',
      payload: {
        questionIdentifier: item.base.identifier,
      },
    });
  }, [dispatch, item.base.identifier]);

  if (hasRowsLinked) {
    return (
      <Tooltip title="Remove linked rows">
        <div className={styles.root}>
          <Autorenew
            onClick={handleUnlink}
            className={cx(styles.icon, styles.linked)} />
        </div>
      </Tooltip>
    );
  }

  return (
    <>
      <Tooltip title="Reuse values from previous question">
        <div className={cx(styles.root, {
          [styles.disabled]: disabled,
        })}>
          <Autorenew
            onClick={disabled ? undefined : toggleModal}
            className={styles.icon} />
        </div>
      </Tooltip>
      <Modal
        questions={questions}
        onSubmit={handleSubmit} />
    </>
  );
};

const useReusableQuestions = () => {
  const item = useQuestionBuilderItemContext();
  const [state] = useSurveyBuilderState();
  const getQuestionText = useParseSurveyRichText();

  return useMemo(() => {

    return state.survey.questions.filter(f => {
      return f.ordinal < item.ordinal &&
        (canReuseRows(f) || canReuseOptions(f));
    })
      .map(m => ({
        base: m.base,
        ordinal: m.ordinal,
        value: getQuestionText(m.value),
      }));

  }, [
    getQuestionText,
    item.ordinal,
    state.survey.questions,
  ]);
};

const useReusableAIE = () => {
  const question = useQuestionBuilderItemContext();
  const [state] = useSurveyBuilderState();

  return useMemo(() => {
    const itemOrdinal = state.survey.items.find(f => f.source.identifier === question.base.identifier).ordinal;
    return state.survey.alternateImageExercises.filter(f => {
      return state.survey.items.find(i => i.source.identifier === f.identifier)?.ordinal < itemOrdinal;
    }).map(m => ({
      ...m,
      ordinal: state.survey.items.find(i => i.source.identifier === m.identifier).ordinal,
    }));
  }, [state.survey.alternateImageExercises, state.survey.items, question.base.identifier]);
};