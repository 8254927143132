import { useMemo } from 'react';
import { useQuestionBuilderItem, RichTextListBuilderContext } from './Context';
import { useAddOption, useRemoveOption, useUpdateOptionRichValue, useUpdateOptionValue } from './hooks';

type Props = {
  className?: string;
  maxOptions: number;
  minOptions: number;
} & ChildrenProps;

export const QuestionOptionsBuilderContainer = (props: Props) => {
  const question = useQuestionBuilderItem();

  const addOption = useAddOption(question.base.identifier);
  const removeOption = useRemoveOption(question.base.identifier);
  const updateOptionValue = useUpdateOptionValue(question.base.identifier);
  const updateOptionRichValue = useUpdateOptionRichValue(question.base.identifier);

  const canAddOption = useMemo(() => {
    return question.options.length < props.maxOptions;
  }, [question.options, props.maxOptions]);

  const canRemoveOption = useMemo(() => {
    return question.options.length > props.minOptions;
  }, [question.options, props.minOptions]);

  const ctx = {
    permissions: {
      canAddItem: canAddOption,
      canRemoveItem: canRemoveOption,
    },
    items: question.options,
    addItem: addOption,
    removeItem: removeOption,
    updateItemValue: updateOptionRichValue,
    updateItemPlainValue: updateOptionValue,
  };

  return (
    <RichTextListBuilderContext.Provider value={ctx}>
      {props.children}
    </RichTextListBuilderContext.Provider>
  );
};

export default QuestionOptionsBuilderContainer;