import { useCallback } from 'react';
import { useCanCreateFindAndReplace, useRichTextEditorRegister } from '@/containers/SurveyBuilder/hooks';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { useRichTextListBuilderContext } from '@/containers/SurveyBuilder.Question';
import type { SurveyRichText } from '@/types/survey.rich-text';
import { AddQuestionRowButton } from '@presentation/SurveyBuilder';
import type { MatrixRangeQuestion } from '@/types';
import type { SurveyRichTextEditorOnChange } from '@/components/Survey.RichText';
import { SurveyRichTextEditorContainer, useSurveyRichTextEditor, SurveyRichTextEditor } from '@/components/Survey.RichText';
import { FindAndReplaceButton } from '@/components/Survey.RichText/Editor.FindAndReplace.Button';
import { BoldButton } from '@/components/Remirror';
import { MatrixRangeRowContextMenu } from './MatrixRange.Row.ContextMenu';
import styles from './style/MatrixRange.Question.css';
import { LanguageEditingBarrier } from './LanguageEditingBarrier';

type Props = {
  item: MatrixRangeQuestion.Question;
};

export const MatrixRangeRowsBuilder = ({ item }: Props) => {

  const context = useRichTextListBuilderContext<MatrixRangeQuestion.Row>();

  const [_, dispatch] = useSurveyBuilderState();

  const handleRowMetadataChange = useCallback((row: MatrixRangeQuestion.Row, field: 'rightStatement' | 'leftStatement') => (value: SurveyRichText.RichTextValue) => {
    dispatch({
      type: 'matrix-range-row-metadata-updated',
      payload: {
        questionIdentifier: item.base.identifier,
        row: { identifier: row.base.identifier },
        field,
        value,
      },
    });
  }, [dispatch, item.base.identifier]);

  return (
    <div>
      {context.items.map(row => (
        <div className={styles.row} key={row.base.identifier}>
          <div className={styles.rowContent}>
            <div className={styles.rowInput}>
              <RowEditorContainer
                onChange={value => context.updateItemValue(row.base.identifier, value)}
                value={row.value}
                identifier={row.base.identifier} />
            </div>
            <div className={styles.rowMetadata}>
              <div className={styles.rowLabel}>Left Label:</div>
              <LabelEditorContainer
                onChange={handleRowMetadataChange(row, 'leftStatement')}
                value={row.metadata.leftStatement}
                identifier={`${row.base.identifier}:metadata:leftStatement`} />
            </div>
            <div className={styles.rowMetadata}>
              <div className={styles.rowLabel}>Right Label:</div>
              <LabelEditorContainer
                onChange={handleRowMetadataChange(row, 'rightStatement')}
                value={row.metadata.rightStatement}
                identifier={`${row.base.identifier}:metadata:rightStatement`} />
            </div>
          </div>
          <LanguageEditingBarrier>
            <div className={styles.menu}>
              <MatrixRangeRowContextMenu row={row} />
            </div>
          </LanguageEditingBarrier>
        </div>
      ))}
      {context.permissions.canAddItem &&
        <LanguageEditingBarrier>
          <AddQuestionRowButton
            onClick={context.addItem} />
        </LanguageEditingBarrier>}
    </div>
  );
};

type EditorProps = {
  onChange: (value: SurveyRichText.RichTextValue) => void;
  value: SurveyRichText.RichTextValue;
  identifier: string;
};

const RowEditorContainer = ({ onChange, value, identifier }: EditorProps) => {
  const { manager, state, setState } = useSurveyRichTextEditor({
    initialState: value,
    placeholder: 'Enter row name',
  });

  const handleChange: SurveyRichTextEditorOnChange = useCallback(params => {
    if (!params.tr?.docChanged) return;

    setState(params.state);

    const value = params.state.doc.toJSON() as SurveyRichText.RichTextValue;

    onChange(value);
  }, [
    setState,
    onChange,
  ]);

  const editorRef = useRichTextEditorRegister({
    identifier,
  });

  return (
    <SurveyRichTextEditorContainer
      manager={manager}
      onChange={handleChange}
      state={state}
      editorRef={editorRef}>
      <SurveyRichTextEditor className={styles.editor} />
    </SurveyRichTextEditorContainer>
  );
};

const LabelEditorContainer = ({ onChange, value, identifier }: EditorProps) => {
  const canFar = useCanCreateFindAndReplace();

  const { manager, state, setState } = useSurveyRichTextEditor({
    initialState: value,
    placeholder: 'Enter Label',
  });

  const handleChange: SurveyRichTextEditorOnChange = useCallback(params => {
    if (!params.tr?.docChanged) return;

    setState(params.state);

    const value = params.state.doc.toJSON() as SurveyRichText.RichTextValue;

    onChange(value);
  }, [
    setState,
    onChange,
  ]);

  const editorRef = useRichTextEditorRegister({
    identifier,
  });

  return (
    <SurveyRichTextEditorContainer
      manager={manager}
      onChange={handleChange}
      state={state}
      editorRef={editorRef}>
      <SurveyRichTextEditor className={styles.editor} />
      <BoldButton className={styles.boldBtn} />
      {canFar ? <FindAndReplaceButton className={styles.boldBtn} /> : null}
    </SurveyRichTextEditorContainer>
  );
};
