import { useConjointAnalysisSurveyResponses } from '@containers/SurveyResponses/hooks/useSurveyResponsesContext';
import { SurveyResponsesHeader } from '@presentation/ProjectSurveyResponses';
import Chart from './ConjointAnalysis.Chart';
import Data from './ConjointAnalysis.Data';
import styles from './style/ConjointAnalysis.css';

export const ConjointAnalysisSurveyReponses = () => {
  const {
    levelColors,
    onLevelClick,
    query,
    question,
    responses,
    sortedValues,
  } = useConjointAnalysisSurveyResponses();

  return (
    <>
      <SurveyResponsesHeader
        question={question} />
      {!query.levelId &&
        <Chart
          className={styles.chart}
          attributes={sortedValues}
          levelColors={levelColors}
          onLevelClick={onLevelClick}
          responses={responses} />
      }
      <Data />
    </>
  );
};

export default ConjointAnalysisSurveyReponses;