import { useCallback } from 'react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { querykey } from '@consts';
import * as $api from '@api/projects.quant-analysis';
import type { GetProjectQuantAnalysis } from '@api/interfaces/projects.quant-analysis';

type Props = IProjectId & {
  quantAnalysisId: number;
};
type Data = GetProjectQuantAnalysis.Response;
type QueryKey = readonly [string, Props];

type Options = UseQueryOptions<Data, AxiosError, Data, QueryKey>;

export const useProjectQuantAnalysisQuery = (props: Props, options?: Options) => {

  const query = useQuery({
    queryKey: querykey.Projects.QuantAnalysis.Get({
      projectId: props.projectId,
      quantAnalysisId: props.quantAnalysisId,
    }),
    queryFn: () => {
      return $api.getProjectQuantAnalysis({
        projectId: props.projectId,
        quantAnalysisId: props.quantAnalysisId,
      });
    },
    ...options,
  });

  return query;
};

export const useInvalidateProjectQuantAnalysisQuery = () => {

  const queryClient = useQueryClient();

  return useCallback((data: {
    projectId: number;
    quantAnalysisId: number;
  }) => {
    return queryClient.invalidateQueries({
      queryKey: querykey.Projects.QuantAnalysis.Get({
        projectId: data.projectId,
        quantAnalysisId: data.quantAnalysisId,
      }),
    });
  }, [queryClient]);
};