import { useCallback, useState } from 'react';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { useDownloadTranscriptHighlights } from '@utils/api';
import { CallTranscriptTagsReportFormat } from '@/types';
import { SelectUnderlined } from '$admin/components/Select';
import { useModal } from '@/components/Modal/hooks';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { Header } from '@/components/Modal/Header';
import styles from './style/TranscriptDownloadModal.css';

type Props =
  & ITranscriptId
  & Pick<ModalProps, 'open' | 'onClose'>;

export const TranscriptHighlightsDownloadModal = (props: Props) => {
  const [format, setFormat] = useState<CallTranscriptTagsReportFormat>('xlsx');

  const { download, isFetching } = useDownloadTranscriptHighlights({
    format,
    transcriptId: props.transcriptId,
  }, {
    onSuccess: () => {
      props.onClose();
    },
  });

  const handleSubmit = useCallback(() => {
    download({
      extension: format,
      name: `Project Highlights`,
      title: `Generating Report`,
    });
  }, [
    download,
    format,
  ]);

  return (
    <Modal
      classes={{ root: styles.root }}
      onClose={props.onClose}
      open={props.open}>
      <Header>Select Highlights File Format</Header>

      <div className={styles.field}>
        <SelectUnderlined<Record<CallTranscriptTagsReportFormat, string>>
          onChange={x => setFormat(x.target.value)}
          options={[
            { id: 'xlsx', name: 'XLSX' },
          ]}
          value={format} />
      </div>

      <ButtonSet className={styles.footer}>
        <ButtonActivityIndicator
          className={styles.btn}
          disabled={!format}
          onClick={handleSubmit}
          loading={isFetching}>
          Download
        </ButtonActivityIndicator>
      </ButtonSet>
    </Modal>
  );
};

export const useTranscriptHighlightsDownloadModal = () => useModal(TranscriptHighlightsDownloadModal);