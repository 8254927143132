import { useCallback } from 'react';
import { SurveyQuestionConditionType } from '@enums';
import { useConditionQuestion, useQuestionConditionContext, useSurveyQuestionConditionValidations } from '@containers/SurveyBuilder.Logic';
import { ConditionTypeDropdown } from './Builder.ConditionTypeDropdown';
import { ConditionQuestionDropdown } from './Builder.QuestionDropdown';
import { OptionsDropdown } from './Builder.OptionDropdown';
import { RowsDropdown } from './Builder.RowsDropdown';
import { ValueInput } from './Builder.Question.ComparateInput';
import { LogicValidation } from './Builder.Validation';
import styles from './style/Builder.Condition.Question.css';
import { OperatorDropdown } from './Builder.OperatorDropdown';
import { SlidersTallyConditionBuilder } from './Builder.Condition.SliderTally';

export const LogicBuilderQuestionCondition = () => {

  const { item } = useQuestionConditionContext();
  const question = useConditionQuestion();

  const validations = useSurveyQuestionConditionValidations(item.identifier);

  const renderConditionValues = useCallback(() => {
    if (!question) return null;

    if (!item.data.type) return null;

    switch (item.data.type) {

      case SurveyQuestionConditionType.OptionSelected:
      case SurveyQuestionConditionType.OptionNotSelected:
      case SurveyQuestionConditionType.OptionRankedFirst:
      case SurveyQuestionConditionType.OptionRankedLast:
        return <OptionsDropdown />;

      case SurveyQuestionConditionType.OptionRankedTopX:
        return (
          <>
            <OptionsDropdown />
            <ValueInput field="ordinal" />
          </>
        );

      case SurveyQuestionConditionType.NumberOfOptionsSelected:
        return (
          <>
            <OperatorDropdown />
            <ValueInput />
          </>
        );

      case SurveyQuestionConditionType.MatrixValueChosen:
      case SurveyQuestionConditionType.MatrixValueNotChosen:
        return (
          <>
            <RowsDropdown />
            <OptionsDropdown />
          </>
        );

      case SurveyQuestionConditionType.SliderValue:
        return (
          <>
            <RowsDropdown />
            <OperatorDropdown />
            <ValueInput />
          </>
        );

      case SurveyQuestionConditionType.NumberCellValue:
        return (
          <>
            <RowsDropdown />
            <OptionsDropdown />
            <OperatorDropdown />
            <ValueInput />
          </>
        );

      case SurveyQuestionConditionType.SliderValuesTally:
        return (
          <SlidersTallyConditionBuilder />
        );

      case SurveyQuestionConditionType.Straightline:
        return (
          <>
            <ValueInput
              allowNegative={false} />
          </>
        );

      default:
        throw new UnreachableCaseError(item.data);
    }
  }, [item.data, question]);

  return (
    <div>
      <div className={styles.row}>
        <div className={styles.label}>Question:</div>
        <ConditionQuestionDropdown />
      </div>
      <LogicValidation value={validations.question} />

      <div className={styles.conditionRow}>
        <div className={styles.label}>Type:</div>
        <ConditionTypeDropdown disabled={!item.data.question.identifier} />
      </div>
      <LogicValidation value={validations.type} />

      <div className={styles.valueRow}>
        <div className={styles.label}>Value:</div>
        {renderConditionValues()}
      </div>
      <LogicValidation value={validations.value} />

    </div>
  );
};