import { useMemo } from 'react';
import { FormProgressBar } from '@/components/SurveyForm/FormProgressBar';

type Props = {
  eql?:      number;
  ordinal:   number;
  total: number;
};

export const DynamicProgressContainer = (props: Props) => {

  const value = useMemo(() => {
    const hasEstimate = Number.isInteger(props.eql);

    const actual = (Math.max(props.ordinal - 1, 0) / props.total) * 100;
    const estimate = ((props.ordinal - 1) / props.eql) * 100;

    const metric = !hasEstimate || estimate > actual
        ? 'Actual'
        : 'Estimated';

    const pct = !hasEstimate || estimate > actual
        ? actual
        : estimate;

    return {
      metric,
      percent: pct,
    };
  }, [
    props.eql,
    props.ordinal,
    props.total,
  ]);

  return (
    <FormProgressBar value={value.percent} />
  );
};

DynamicProgressContainer.displayName = 'SurveyForm.DynamicProgress.Container';