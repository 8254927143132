export enum NotificationType {
  CompleteOnboarding = 1,
  ProjectRequest,
  ProjectCallRequest,
  MessageRejected,

  AdHocCallRequested,
  AdHocCallRejected,
  AdHocCallCancelled,
  AdHocCallAccepted,
  AdHocCallScheduled,
  AdHocCallRescheduleRequested,
  AdHocCallTimesProposed,
}