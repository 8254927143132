import { useContext } from 'react';
import { useTranscriptTagsContext } from '@containers/Transcript.Tagging/hooks';
import { useDeleteTranscriptMomentMutation } from '@/utils/api';
import { TranscriptChainedCommandsContext } from '@/components/Transcript/context';

type Props = ITranscriptId;

export const useDeleteTaggedMoment = (props: Props) => {
  const [_, dispatch] = useTranscriptTagsContext();
  const commands = useContext(TranscriptChainedCommandsContext);

  return useDeleteTranscriptMomentMutation({
    transcriptId: props.transcriptId,
  }, {
    onSuccess: (_, variables) => {
      dispatch({
        type: 'tag-removed',
        momentId: variables.momentId,
      });
      if (variables.highlightIdentifier) {
        commands.removeHighlights([variables.highlightIdentifier]).run();
      }
    },
  });
};