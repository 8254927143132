import { forwardRef, useCallback, Ref } from 'react';
import PopperMUI, { PopperProps } from '@mui/material/Popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { bindToggle, bindPopper } from 'material-ui-popup-state';
import { usePopupState, PopupState as PopupStateProps } from 'material-ui-popup-state/hooks';
import { MoreHorizontalAnchorSmall } from '@presentation/Anchor';
import { useToggle } from '@utils/hooks';
import { FeedItemComment } from '@/types/comments';
import { Alert } from '@/components/Modal/Alert';
import { PopperMenu, PopperMenuItem } from '@/components/Popper';
import { useCommentContextMenu } from './hooks/useCommentContextMenu';

type Props = {
  depth:         number;
  item:          FeedItemComment;
  onDelete:      () => void;
  onReply:       () => void;
  renderAnchor?: (state: PopupStateProps) => React.ReactNode;
} & Pick<PopperProps, 'placement'>;

export const ContextMenu = ({ placement = 'bottom-end', ...props }: Props) => {
  const [open, toggle] = useToggle();

  const items = useCommentContextMenu({
    authorId: props.item.author.id,
    depth: props.depth,
    onDelete: toggle,
    onReply: props.onReply,
  });

  const defaultAnchor = useCallback((state: PopupStateProps) => {
    return (
      <MoreHorizontalAnchorSmall open={state.isOpen} />
    );
  }, []);

  const popupState = usePopupState({
    popupId: 'comment-popper',
    variant: 'popper',
  });

  if (!items.length) return null;

  return (
    <>
      <div>
        <div
          {...bindToggle(popupState)}>
          {(props.renderAnchor || defaultAnchor)(popupState)}
        </div>
        <PopperMUI
          {...bindPopper(popupState)}
          placement={placement}>
          <ClickAwayListener onClickAway={popupState.close}>
            <MenuItems
              items={items}
              onClose={popupState.close} />
          </ClickAwayListener>
        </PopperMUI>
      </div>
      <Alert
        onConfirm={props.onDelete}
        onClose={toggle}
        message="Are you sure you want to delete your comment?"
        open={open} />
    </>
  );
};

ContextMenu.displayName = 'Comment.ContextMenu';

type MenuProps = {
  onClose: () => unknown;
  items: {
    children: string;
    onClick:  () => void;
  }[];
};

const ContextMenuItems = ({ onClose, items }: MenuProps, ref: Ref<HTMLDivElement>) => {
  const handleClick = useCallback((fn: () => unknown) => () => {
    onClose();
    return fn();
  }, [onClose]);

  return (
    <PopperMenu ref={ref}>
      {items.map((item, i) => (
        <PopperMenuItem
          key={i}
          onClick={handleClick(item.onClick)}>
          {item.children}
        </PopperMenuItem>))}
    </PopperMenu>
  );
};

ContextMenuItems.displayName = 'Comment.ContextMenu.Items';

const MenuItems = forwardRef<HTMLDivElement, MenuProps>(ContextMenuItems);