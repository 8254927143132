import ReactQueryClient from './ReactQueryClient';
import { QueryParamsClient } from './QueryParamsClient';

type Props = ChildrenProps;

export default function RootVendors({ children }: Props) {
  return (
    <ReactQueryClient>
      <QueryParamsClient>
        {children}
      </QueryParamsClient>
    </ReactQueryClient>
  );
}

export { RootVendors };