import { memo } from 'react';
import { X } from 'react-feather';
import { Tooltip } from '@presentation/Tooltip';
import styles from './style/Close.css';

type Props = {
  onClick: () => void;
};

export const CloseRow = memo(({ onClick }: Props) => {
  return (
    <Tooltip title="Close">
      <div
        className={styles.close}
        onClick={onClick}>
        <X className={styles.x} />
      </div>
    </Tooltip>
  );
});

export default CloseRow;