import { format } from 'date-fns';
import * as consts from '@consts';
import { CallReviewContainer } from '@containers/ComplianceReview/CallReviewContainer';
import { useCallReviewState } from '@containers/ComplianceReview/hooks/useCallReviewState';
import { useCreateSignedDocumentDownloader } from '@containers/Downloader/hooks/useCreateSignedDocumentDownloader';
import { BackLink } from '@presentation/Back';
import { ConsultantAvatar, ConsultantCareer } from '@presentation';
import styles from '@screens/ComplianceCallReview/style/ComplianceCallReview.css';
import { cx } from '@utils';
import { ActivityIndicator, Spinner } from '@/components/ActivityIndicator';
import { ComplianceScreener } from '@/scenes/surveys/CompletedSurvey';
import { ApprovalButtons } from './ApprovalButtons';
import { CallChaperone } from './CallChaperone';

type Props = unknown;

const CallReview = (props: Props) => {
  const state = useCallReviewState();
  const createDownloader = useCreateSignedDocumentDownloader();

  if (!state) return <ActivityIndicator show />;

  const [downloadState, downloadDispatch] = createDownloader({
    filename: `${state.consultant.profile.fullname} ${format(new Date(), 'M/d/yyyy')}.pdf`,
    projectId: state.project.id,
    signature: state.documents,
    userId: state.consultant.id,
  });

  return (
    <div className={styles.root}>
      <BackLink
        className={styles.back}
        to={consts.pathname.HOME} />

      <div className={styles.wrap}>

        <ConsultantAvatar
          name={state.consultant.profile.fullname}
          slug={state.consultant.profile.slug}
          src={state.consultant.profile.pictureUrl} />

        <ConsultantCareer career={state.consultant.career} />

        <div className={styles.item}>
          <span className={styles.label}>Project:</span>
          <span className={styles.value}>{state.project.name}</span>
        </div>

        {downloadState.enabled &&
          <div className={styles.item}>
            <span className={styles.label}>Document:</span>
            <span className={cx(styles.value, styles.download)}>
              {downloadState.downloading
                ? <Spinner size={20} />
                : <button onClick={downloadDispatch}>Download</button>}
            </span>
          </div>}

        <div className={styles.item}>
          <span className={styles.label}>Chaperone Call:</span>
          <span className={styles.value}>
            <CallChaperone />
          </span>
        </div>

        {state.screener &&
          <ComplianceScreener survey={state.screener} />}

        <ApprovalButtons />
      </div>
    </div>
  );
};

const ComplianceCallReview = (props: Props) => {
  return (
    <CallReviewContainer>
      <CallReview />
    </CallReviewContainer>
  );
};

export { ComplianceCallReview };
export default ComplianceCallReview;