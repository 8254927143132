import { SurveyLogic } from '@/types/survey';
import { ClassificationConditionValidation } from './interfaces';

export function validateClassificationCondition({ data: condition }: SurveyLogic.ClassificationCondition): ClassificationConditionValidation {

  const validation: ClassificationConditionValidation = {
    value: {
      success: true,
    },
  };

  if (!condition.identifier || condition.identifier === '') {
    validation.value = {
      message: 'Classification must be set',
      success: false,
    };
  }

  return validation;
}