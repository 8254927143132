import { io } from 'socket.io-client';
import { LifeCycleSocketEvent, SocketNamespace, ConferenceSocketEvent } from '@enums';
import { createLogger, resolveSocketOptions } from './utils';
import { Conference } from './interfaces';

const log = createLogger(SocketNamespace.Conference);

export const socket: Conference.Socket = io(`${process.env.BACKEND_WS_URL}/${SocketNamespace.Conference}`, resolveSocketOptions({
  closeOnBeforeunload: false,
}));

export function open() {
  socket.on(LifeCycleSocketEvent.Connect, () => log.event(LifeCycleSocketEvent.Connect));
  socket.on(LifeCycleSocketEvent.ConnectError, (...args) => log.event(LifeCycleSocketEvent.ConnectError, args));
  socket.on(LifeCycleSocketEvent.Disconnect, () => log.event(LifeCycleSocketEvent.Disconnect));

  socket.prependAny((event: ConferenceSocketEvent, payload: unknown) => log.event(event, payload));
  socket.open();
}

export function close() {
  socket.close();

  socket.offAny();
  socket.removeAllListeners();
}