import { useCallback, useMemo, useState } from 'react';
import cuid from 'cuid';
import {
  SurveyQuestionConditionType,
  SurveyOptionType,
  SurveyConditionType,
  SurveyQuestionType,
  SurveyActionType,
  SurveyLogicRule,
} from '@enums/Survey';
import type { SurveyOptionsBuilder, SurveyLogicBuilder, SurveyRowsBuilder } from '@containers/SurveyBuilder';
import { useSurveyBuilderState, useParseSurveyRichText } from '@containers/SurveyBuilder';
import { useQuestionBuilderItemContext } from '@containers/SurveyBuilder.Question/Context';
import { GrayOutlineButton } from '@presentation/Buttons';
import type { QuestionDropdownItem } from '@presentation/SurveyBuilder';
import { ConditionTypeDropdown, MultiOptionsDropdown, QuestionDropdown } from '@presentation/SurveyBuilder';
import type { SurveyQuestionOption, SurveyQuestionMatrixRow } from '@/types/survey.question';
import type { SurveyLogic, SurveyRichText } from '@/types';
import { Button } from '@/components/Button';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import ModalHeader from '@/components/Modal/Header';
import { useModal } from '@/components/Modal/hooks';
import styles from './style/ResponsePiping.Modal.css';

type Props = {
  pipeableQuestions: QuestionDropdownItem[];
  type?: 'options' | 'rows';
} & Pick<ModalProps,
  'open' |
  'onClose'>;

type ConditionType =
  SurveyQuestionConditionType.OptionSelected |
  SurveyQuestionConditionType.OptionNotSelected;

type SourceOption = {
  identifier: string;
  value: SurveyRichText.RootNode;
  type: 'option' | 'row';
  linked: SurveyQuestionOption['metadata']['linked'];
};

const RowSourceQuestionTypes = [SurveyQuestionType.MatrixGrid, SurveyQuestionType.MatrixMultiselect];

export const ResponsePipingModal = ({
  onClose,
  open,
  pipeableQuestions,
  type,
}: Props) => {

  const item = useQuestionBuilderItemContext();
  const [state, dispatch] = useSurveyBuilderState();
  const parseSurveyRichText = useParseSurveyRichText();

  const [conditionType, setConditionType] = useState<ConditionType>(SurveyQuestionConditionType.OptionSelected);
  const [questionIdentifier, setQuestionIdentifier] = useState<string>(null);
  const [optionsFilter, setOptionsFilter] = useState<SurveyQuestionOption[]>([]);

  const selectedQuestion = useMemo(() => {
    return state.survey.questions.find(f => f.base.identifier === questionIdentifier);
  }, [
    questionIdentifier,
    state.survey.questions,
  ]);

  const options = useMemo(() => {
    if (!selectedQuestion) return [];
    return selectedQuestion.options
      .filter(f => f.type === SurveyOptionType.Default);
  }, [
    selectedQuestion,
  ]);

  const sourceOptions = useMemo<SourceOption[]>(() => {
    if (!selectedQuestion) return [];

    if (RowSourceQuestionTypes.includes(selectedQuestion.typeId)) {
      return selectedQuestion.matrixRows.map((m: SurveyQuestionMatrixRow) => ({
        identifier: m.base.identifier,
        value: m.value,
        linked: {
          question: { identifier: selectedQuestion.base.identifier },
          row: { identifier: m.base.identifier },
        },
        type: 'row' as const,
      }));
    } else {
      return options.map(o => ({
        identifier: o.base.identifier,
        value: o.value,
        linked: {
          question: { identifier: selectedQuestion.base.identifier },
          option: { identifier: o.base.identifier },
        },
        type: 'option',
      }));
    }
  }, [selectedQuestion, options]);

  const buildConditions = useCallback((option: SourceOption): SurveyLogic.QuestionCondition[] => {
    if (RowSourceQuestionTypes.includes(selectedQuestion.typeId)) {
      return optionsFilter.map(o => ({
        conditionType: SurveyConditionType.Question,
        identifier: cuid(),
        data: {
          question: {
            identifier: questionIdentifier,
          },
          type: getMatrixConditionType(conditionType),
          value: {
            option: {
              identifier: o.base.identifier,
            },
            row: {
              identifier: option.identifier,
            },
          },
        },
      }));
    } else {
      return [{
        conditionType: SurveyConditionType.Question,
        identifier: cuid(),
        data: {
          question: {
            identifier: questionIdentifier,
          },
          type: conditionType,
          value: {
            option: {
              identifier: option.identifier,
            },
          },
        },
      }];
    }
  }, [selectedQuestion, optionsFilter, questionIdentifier, conditionType]);

  const buildAddLogicAction = useCallback((options: SourceOption[], optionsMapping: ChoiceMap) => {

    const logicItems = options.map<SurveyLogic.Item>(o => {
      return {
        id: null,
        identifier: cuid(),
        action: {
          type: SurveyActionType.DisplayQuestionChoice,
          question: {
            identifier: item.base.identifier,
          },
          row: {
            identifier: type === 'rows' ? optionsMapping[o.identifier] : null,
          },
          option: {
            identifier: type === 'options' ? optionsMapping[o.identifier] : null,
          },
        },
        metadata: {
          canDelete: true,
          canEdit: true,
        },
        rule: {
          type: SurveyLogicRule.AnyOfTheAbove,
          expression: undefined,
        },
        conditions: buildConditions(o),
      };
    });

    const addLogicAction: SurveyLogicBuilder.ItemsAdded.Action = {
      type: 'logic-items-added',
      payload: {
        items: logicItems,
      },
    };

    return addLogicAction;

  }, [
    buildConditions,
    item.base.identifier,
    type,
  ]);

  const buildAddOptionsAction = useCallback((options: SourceOption[], optionsMapping: ChoiceMap) => {
    const lastOrdinal = Math.max(...item.options
      .filter(f => f.type === SurveyOptionType.Default)
      .map(m => m.ordinal)
    );

    const toAdd: SurveyQuestionOption[] = options.map((m, i) => ({
      id: null,
      base: {
        id: null,
        identifier: optionsMapping[m.identifier],
      },
      metadata: {
        canDelete: true,
        canModifyValue: true,
        linked: m.linked,
        template: {},
      },
      ordinal: lastOrdinal + i + 1,
      conditions: [],
      type: SurveyOptionType.Default,
      value: m.value,
    }));

    const addOptionsAction: SurveyOptionsBuilder.AddOptions.Action = {
      questionIdentifier: item.base.identifier,
      options: toAdd,
      reorder: true,
      type: 'add-question-options',
    };

    return addOptionsAction;
  }, [
    item.base.identifier,
    item.options,
  ]);

  const buildAddRowsAction = useCallback((options: SourceOption[], optionsMapping: ChoiceMap) => {
    const lastOrdinal = Math.max(...item.matrixRows.map((m: SurveyQuestionMatrixRow) => m.ordinal));

    const toAdd: SurveyQuestionMatrixRow[] = options.map((m, i) => ({
      id: null,
      base: {
        id: null,
        identifier: optionsMapping[m.identifier],
      },
      metadata: {
        canDelete: true,
        canModifyValue: true,
        linked: m.linked,
        template: {},
      },
      ordinal: lastOrdinal + i + 1,
      conditions: [],
      value: m.value,
    }));

    const addRowsAction: SurveyRowsBuilder.AddRows.Action = {
      questionIdentifier: item.base.identifier,
      reorder: true,
      rows: toAdd,
      type: 'add-question-rows',
    };

    return addRowsAction;
  }, [
    item.base.identifier,
    item.matrixRows,
  ]);

  const handleSubmitOptions = useCallback(() => {

    const filteredOptions = sourceOptions.filter(f => !item.options.some(s => s.value === f.value));

    const optionMappings = filteredOptions.reduce<ChoiceMap>((acc, curr) => {
      acc[curr.identifier] = cuid();
      return acc;
    }, {});

    const addLogicAction = buildAddLogicAction(filteredOptions, optionMappings);
    const addOptionsAction = buildAddOptionsAction(filteredOptions, optionMappings);

    dispatch({
      type: 'batch-actions',
      actions: [
        addOptionsAction,
        addLogicAction,
      ],
    });

  }, [
    buildAddLogicAction,
    buildAddOptionsAction,
    item.options,
    dispatch,
    sourceOptions,
  ]);

  const handleSubmitRows = useCallback(() => {

    const filteredOptions = sourceOptions
      .filter(o => !item.matrixRows.some((r: SurveyQuestionMatrixRow) => parseSurveyRichText(r.value) === parseSurveyRichText(o.value)));

    const rowMappings = filteredOptions.reduce<ChoiceMap>((acc, curr) => {
      acc[curr.identifier] = cuid();
      return acc;
    }, {});

    const addLogicAction = buildAddLogicAction(filteredOptions, rowMappings);
    const addRowsAction = buildAddRowsAction(filteredOptions, rowMappings);

    dispatch({
      type: 'batch-actions',
      actions: [
        addRowsAction,
        addLogicAction,
      ],
    });

  }, [
    buildAddLogicAction,
    buildAddRowsAction,
    item.matrixRows,
    dispatch,
    parseSurveyRichText,
    sourceOptions,
  ]);

  const handleSubmit = useCallback(() => {
    if (type === 'rows') {
      handleSubmitRows();
    } else {
      handleSubmitOptions();
    }

    onClose();
  }, [
    handleSubmitOptions,
    handleSubmitRows,
    onClose,
    type,
  ]);

  const canSubmit = useMemo(() => {
    return !!options.length;
  }, [options]);

  const hasOptionFilter = selectedQuestion && RowSourceQuestionTypes.includes(selectedQuestion.typeId);

  return (
    <Modal
      open={open}
      onClose={onClose}>

      <ModalHeader text="Pipe Previous Responses" />

      <div className={styles.body}>

        <div className={styles.dropdowns}>
          <div className={styles.conditionDropdown}>
            <ConditionTypeDropdown
              items={[SurveyQuestionConditionType.OptionSelected, SurveyQuestionConditionType.OptionNotSelected]}
              getTextValue={getConditionDisplayText}
              onSelect={v => setConditionType(v as ConditionType)}
              value={conditionType} />
          </div>
          <div className={styles.questionDropdown}>
            <QuestionDropdown
              items={pipeableQuestions}
              onSelect={setQuestionIdentifier}
              value={selectedQuestion ? {
                base: selectedQuestion.base,
                ordinal: selectedQuestion.ordinal,
                value: parseSurveyRichText(selectedQuestion.value),
              } : null} />
          </div>
        </div>
        {hasOptionFilter &&
          <div className={styles.dropdowns}>
            <div className={styles.optionDropdown}>
              <MultiOptionsDropdown
                values={optionsFilter}
                items={options}
                placeholder='Filter Rows'
                onChange={setOptionsFilter} />
            </div>
          </div>
        }

        {!!sourceOptions.length &&
          <div className={styles.options}>
            <div className={styles.optionsTitle}>Options</div>
            {sourceOptions.map(option => (
              <div
                key={option.identifier}
                className={styles.option}>
                {parseSurveyRichText(option.value)}
              </div>
            ))}
          </div>
        }
      </div>

      <div className={styles.footer}>
        <GrayOutlineButton
          onClick={onClose}
          title="Cancel" />
        <Button.Primary
          className={styles.submit}
          disabled={!canSubmit}
          onClick={handleSubmit}
          title="Insert"
          variant="brick" />
      </div>
    </Modal>
  );
};

function getConditionDisplayText(v: SurveyQuestionConditionType) {
  if (v === SurveyQuestionConditionType.OptionSelected) {
    return 'Pipe selected from';
  } else if (v === SurveyQuestionConditionType.OptionNotSelected) {
    return 'Pipe not selected from';
  }
}

function getMatrixConditionType(v: ConditionType) {
  switch (v) {
    case SurveyQuestionConditionType.OptionSelected:
      return SurveyQuestionConditionType.MatrixValueChosen;
    case SurveyQuestionConditionType.OptionNotSelected:
      return SurveyQuestionConditionType.MatrixValueNotChosen;
    default:
      throw new UnreachableCaseError(v);
  }
}

type ChoiceMap = {
  [identifier: string]: string;
};

export const useResponsePipingModal = () => useModal(ResponsePipingModal);

export default ResponsePipingModal;