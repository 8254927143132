import { useCallback, useEffect, useReducer, useRef } from 'react';
import { FormDerivedEditStateContext, FormStateContext } from './Context';
import type { FormAction, FormState } from './interfaces';

type Props<T> = {
  editing: boolean;
  children: React.ReactNode | React.FC;
  onChange?: (state: FormState<T>) => unknown;
  values: T;
};

// eslint-disable-next-line comma-spacing
const FormStateContainer = <T,>(props: Props<T>) => {
  const [state, dispatch] = useFormFields<T>(props.values);
  const initialized = useRef(false);

  const init = useCallback(() => {

    dispatch({ type: 'reset' });

  }, [
    dispatch,
  ]);

  useEffect(() => {

    if (initialized.current && !props.editing) init();

    initialized.current = true;

  }, [
    props.editing,
    init,
  ]);

  useEffect(() => {

    props.onChange?.(state);

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [state]);

  return (
    <FormDerivedEditStateContext.Provider value={props.editing}>
      <FormStateContext.Provider value={[state, dispatch]}>
        {typeof props.children === 'function'
          ? props.children(state)
          : props.children}
      </FormStateContext.Provider>
    </FormDerivedEditStateContext.Provider>
  );
};

export function useFormFields<T>(values: T) {
  const errors = Object.keys(values).reduce<FormState<T>['errors']>((acc, key) => ({
    ...acc,
    [key]: undefined,
  }), {} as FormState<T>['errors']);

  const [state, dispatch] = useReducer((acc: FormState<T>, x: FormAction<T>) => {

    switch (x.type) {
      case 'error':
        return {
          ...acc,
          errors: { ...acc.errors, [x.key]: x.error },
        };

      case 'value':
        return {
          ...acc,
          values: { ...acc.values, [x.key]: x.value },
        };

      case 'reset':
        return { errors, values };

      default:
        return acc;
    }

  }, {
    errors,
    values,
  } as FormState<T>);

  return [state, dispatch] as const;
}

export { FormStateContainer };
export default FormStateContainer;