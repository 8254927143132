import { useCallback, useContext } from 'react';
import type * as API from '$admin/api/interfaces';
import * as CallScheduling from '$admin/components/Scheduling';
import { CallDetailsContext } from './Context';

type Props = {
  children: React.ReactNode;
} & ICallId;

export const CallSchedulingContainer = (props: Props) => {
  const { query: { refetch } } = useContext(CallDetailsContext);

  const handleSuccess = useCallback((res: API.Projects.Scheduling.ScheduleCall.Response) => {
    if (props.callId === res.call.id) {
      refetch();
    }
  }, [
    props.callId,
    refetch,
  ]);

  return (
    <CallScheduling.Container onSuccess={handleSuccess}>
      {props.children}
    </CallScheduling.Container>
  );
};

CallSchedulingContainer.displayName = 'Call.Scheduling.Container';