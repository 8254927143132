import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { usePersistentProjectInvite } from '@containers/ProjectLinkInvitation/hooks/usePersistentProjectInvite';
import * as actions from '@actions';
import * as $apple from '@services/auth/apple';
import * as $facebook from '@services/auth/facebook';
import * as $google from '@services/auth/google';
import { SocialAuthContext } from './Context';

type Props = {
  children: React.ReactNode;
};

const SocialAuthContainer = (props: Props) => {
  const dispatch = useDispatch();
  const urlstate = usePersistentProjectInvite();

  const authorizeFacebook = useCallback(() => {
    const state = urlstate.get();

    return $facebook.authorize({ token: state?.token })
    .then(data => {
      dispatch(actions.userAuthenticationChange());

      return data;
    });

  }, [
    dispatch,
    urlstate,
  ]);

  const authorizeGoogle = useCallback(() => {
    const state = urlstate.get();

    return $google.authorize({ token: state?.token })
    .then(data => {
      dispatch(actions.userAuthenticationChange());

      return data;
    });

  }, [
    dispatch,
    urlstate,
  ]);

  const authorizeApple = useCallback(() => {
    const state = urlstate.get();

    return $apple.authorize({ token: state?.token })
    .then(data => {
      dispatch(actions.userAuthenticationChange());

      return data;
    });

  }, [
    dispatch,
    urlstate,
  ]);

  const context = {
    authorizeApple,
    authorizeFacebook,
    authorizeGoogle,
  };

  return (
    <SocialAuthContext.Provider value={context}>
      {props.children}
    </SocialAuthContext.Provider>
  );
};

export { SocialAuthContainer };
export default SocialAuthContainer;