import { useCallback, useEffect, useMemo, useState, useRef, memo, Fragment } from 'react';
import { format, compareAsc } from 'date-fns';
import * as Twilio from '@twilio/conversations';
import { bindHover, bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import Popover from 'material-ui-popup-state/HoverPopover';
import { useMultiChat, useConferenceInstance, IConference } from '@containers/Conference';
import { ParticipantWebMember } from '@/types/conferences.live';
import { CallRole, ConferenceChatType } from '@enums';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import { SelectBadge } from '@/components/Select';
import styles from './styles/Bar.Right.Chat.css';

export function BarRightChat() {
  const chat = useMultiChat();
  const top = useRef<HTMLDivElement>();
  const [input, setInput] = useState<string>('');
  const { activeChannel, setActiveChannel } = chat;

  useEffect(() => {
    if (chat.ready) {
      if (activeChannel && !chat.channels.find(c => c.identifier === activeChannel)?.initialized) {
        chat.initializeChannel(activeChannel);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat.ready, activeChannel]);

  useEffect(() => {
    chat.consumeAll();
  }, [chat, chat.messages]);

  useEffect(() => {
    if (top.current) {
      setTimeout(() => {
        if (top.current) {
          top.current.scrollTo({
            top: top.current.scrollHeight,
          });
        }
      }, 0);
    }
  }, [chat.messages, chat.ready]);

  const handleInputChange = useCallback((value: string) => {
    setInput(value);
  }, []);

  const handleSendMessage = useCallback(() => {
    chat.send(input, activeChannel);
    setInput('');
  }, [input, chat, activeChannel]);

  const ChannelSelector = useCallback(() => {
    return (
      <SelectBadge
        className={styles.channelSelect}
        optionsClassName={styles.channelOptions}
        options={chat.channels.filter(c => c.visible)}
        value={`To: ${chat.channels.find(c => c.identifier === activeChannel)?.name}`}
        getItemValue={i => i.name}
        onSelect={x => setActiveChannel(x.identifier)} />
    );
  }, [chat.channels, activeChannel, setActiveChannel]);

  if (!chat.ready) {
    return (
      <div className={styles.root}>
        <div className={styles.header}>Chat</div>
        <ActivityIndicator.Spinner />
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>Chat</div>
      <div className={styles.container}>
        <div
          ref={top}
          className={styles.top}>
          <div className={styles.messages}>
            <Messages />
          </div>
        </div>
        <div className={styles.bottom}>
          <MessageHelpText />
          <div className={styles.bottomPadding}>
            <div className={styles.channelSelector}>
              <ChannelSelector />
            </div>
            <div>
              <Messenger
                disabled={!chat.channels.find(c => c.identifier === activeChannel)?.initialized}
                input={input}
                onChange={handleInputChange}
                send={handleSendMessage} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

type MessageProps = {
  author: string;
  text: string;
  time: Date;
  to: string;
};

const Message = memo(({ author, time, text, to }: MessageProps) => {
  const authorClassname =
    author === 'Vancery'
      ? styles.authorVancery
      : author === 'You'
        ? styles.authorYou
        : styles.author;

  return (
    <div className={styles.message}>
      <div className={styles.messageHeader}>
        <div className={authorClassname}>{author} to {to}:</div>
        <div className={styles.time}>{format(time, 'h:mmaaaa')}</div>
      </div>
      <div className={styles.text}>{text}</div>
    </div>
  );
});

function MessageHelpText() {
  const { channels } = useMultiChat();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'conference-chat-help',
  });

  const hoverProps = bindHover(popupState);

  const hoverMessages = useMemo(() => {
    return [
      'Only the person or group specified in the To address will be able to see your chat message.',
      channels.some((c: IConference.MultiChat.Channel) => c.type === ConferenceChatType.Group && c.role === CallRole.Attendee)
        ? 'The Attendees group thread is not visible to the respondent.'
        : null,
    ].filter(Boolean);
  }, [channels]);

  return (
    <>
      <div className={styles.helpText} {...hoverProps}>
        Who can see your messages?
      </div>
      <Popover
        {...bindPopover(popupState)}
        sx={{
          pointerEvents: 'none',
          cursor: 'default',
        }}
        slotProps={{
          paper: {
            sx: {
              backgroundColor: 'var(--pri-01)',
              border: '1px solid transparent',
              borderRadius: '4px',
              boxShadow: `
                0px 5px 5px -3px rgba(0,0,0,0.2),
                0px 8px 10px 1px rgba(0,0,0,0.14),
                0px 3px 14px 2px rgba(0,0,0,0.12)`,
            },
          },
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}>
        <div className={styles.helpPopover}>
          {hoverMessages.map((e, i) => (
            <div
              key={i}
              className={styles.helpPopoverRow}>
              {e}
            </div>
          ))}
        </div>
      </Popover>
    </>
  );
}

function Messages() {
  const { messages, participants } = useMultiChat();
  const mappedMessages = useChatMessages(messages, participants);

  if (!messages.length) {
    return (
      <div className={styles.none}>
        No messages.
      </div>
    );
  }

  return (
    <Fragment>
      {mappedMessages.map(message => (
        <Message
          key={message.id}
          author={message.author}
          text={message.text}
          time={message.time}
          to={message.to} />
      ))}
    </Fragment>
  );
}

type MessengerProps = {
  input: string;
  onChange: (value: string) => void;
  send: () => void;
  disabled: boolean;
};

function Messenger({ input, onChange, send, disabled }: MessengerProps) {
  const handleChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
  }, [onChange]);

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13) {
      e.preventDefault();

      const value = input.trim();
      if (!value.length) return;

      send();
    }
  }, [input, send]);

  return (
    <textarea
      className={styles.entry}
      placeholder="Type here..."
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      value={input}
      disabled={disabled} />
  );
}

function useChatMessages(messages: Twilio.Message[], participants: Twilio.Participant[]) {
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom>();
  const chat = useMultiChat();
  const webParticipants = useMemo(() => instance.participants.filter(p => p.type === 'web') as ParticipantWebMember[], [instance.participants]);

  const getAuthor = useCallback((author: string, participant: Twilio.Participant) => {
    if (author === 'system') return {
      isYou: false,
      name: 'Sentiment',
    };

    const attributes = participant?.attributes as TwilioParticipantAttributes;
    const selectedParticipant = webParticipants.find(p => p.chatIdentity === author || p.chatIdentity === participant?.identity);

    return {
      isYou: selectedParticipant?.id === instance.pid,
      name: selectedParticipant?.name ?? attributes?.name ?? `Participant ${participant?.identity}`,
    };
  }, [instance.pid, webParticipants]);

  const getTo = useCallback((isAuthorYou: boolean, conversationIdentifier: string) => {
    const channel = chat.channels.find(c => c.identifier === conversationIdentifier);
    if (!channel) return null;

    if (channel.type === ConferenceChatType.Direct && !isAuthorYou) {
      return 'You';
    } else if (channel.type === ConferenceChatType.Direct && !channel.name) {
      const matchingParticipant = participants.find(p => p.identity === channel.them.chatIdentity);
      return (matchingParticipant?.attributes as TwilioParticipantAttributes)?.name;
    } else {
      return channel.name;
    }
  }, [chat.channels, participants]);

  return useMemo(() => messages.sort((a, b) => compareAsc(a.dateCreated, b.dateCreated)).map(message => {
    const authorDetails = getAuthor(message.author, participants.find(m => m.sid === message.participantSid));
    return ({
      id: message.sid,
      author: authorDetails.isYou ? 'You' : authorDetails.name,
      text: message.body,
      time: message.dateCreated,
      to: getTo(authorDetails.isYou, message.conversation.uniqueName),
    });
  }), [getAuthor, getTo, messages, participants]);
}

type TwilioParticipantAttributes = {
  name: string;
};