import { cx } from '@utils';
import styles from './style/TableCell.css';

type TableCellProps = {
  children: React.ReactNode;
  className?: string;
};

export const TableCell = (props: TableCellProps) => (
  <div className={cx(styles.root, props.className)}>
    {props.children}
  </div>
);
