import { useCallback, useContext, useMemo } from 'react';
import { Node } from 'slate';
import CloseRounded from '@mui/icons-material/CloseRounded';
import { TextURLPreviewContext } from '@containers/PostCreation/Context';
import { useTextPostForm } from '@containers/PostCreation/hooks/useTextPostForm';
import * as utils from '@containers/PostCreation/utils';
import { useDebounceCallback } from '@utils';
import { URLPreview, Placeholder } from '@/components/URLPreview';
import * as Editor from './Editor';
import * as Publisher from './Publisher';
import styles from './style/Text.Compose.Body.css';

export const Body = () => {
  const [form, dispatch] = useTextPostForm();
  const { setUrl, url, ...ctx } = useContext(TextURLPreviewContext);

  const handleURLPreview = useCallback((value: Node[]) => {

    if (!url) {
      const text = value.map(x => Node.string(x)).join('');
      /* eslint-disable-next-line */
      const match = text.match(/(https?:\/\/|www\.)?[\w-\.]+\.[\w-\.]+(\/([\S]+)?)?/gi);
      if (match) {
        const url = match[0];
        const normalized = normalizeURL(url).replace(/^[^\w]+|[^\w]+$/, '');
        setUrl(normalized);
      }
    }

  }, [
    setUrl,
    url,
  ]);

  const checkURL = useDebounceCallback(handleURLPreview, 1500);

  const handleBodyChange = useCallback((value: Node[]) => {
    dispatch({
      type: 'update-body',
      value: { value },
    });

    checkURL(value);

  }, [
    checkURL,
    dispatch,
  ]);

  const charactersRemaining = useMemo(() => {
    return utils.body.charactersRemaining(form.body);
  }, [form.body]);

  return (
    <div className={styles.root}>
      <div className={styles.scroll}>
        <Publisher.Author className={styles.author} />
        <Editor.TextField
          onChange={handleBodyChange}
          placeholder="Share a thought"
          value={form.body.value} />

        {url &&
          <div className={styles.preview}>
            <div className={styles.content}>
              <div
                className={styles.remove}
                onClick={ctx.clear}>
                <CloseRounded
                  className={styles.cancel} />
              </div>
              {ctx.query.data
                ? <URLPreview
                    item={ctx.query.data}
                    url={url} />
                : <Placeholder url={url} />}
            </div>
          </div>}

      </div>
      <div className={styles.message}>
        {(ctx.query.isInitialLoading || ctx.query.isFetching) &&
          <div className={styles.loading}>Loading preview</div>}
        {charactersRemaining < 0 &&
          <div className={styles.charLimit}>
            You have exceeded the maximum character limit ({charactersRemaining})
          </div>}
      </div>
    </div>
  );
};

Body.displayName = 'PostCreation.Text.Compose.Body';

const normalizeURL = (url: string) => {
  if (url.startsWith('https://')) return url;

  if (url.startsWith('http://')) return url.replace('http', 'https');

  return `https://${url}`;
};