import { useContext } from 'react';
import { createNamedContext } from '@utils';
import { ContextValue } from './interfaces';

export const defaultValue: ContextValue = {
  account: [],
  drawer: [],
  menu: {
    drawer: [],
    dropdown: [],
    navbar: [],
  },
  nav: [],
  notifications: null,
};

export const MenuContext = createNamedContext<ContextValue>(defaultValue, 'AppNavigation.Context');

export const useMenuContext = () => useContext(MenuContext);
