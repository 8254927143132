import { useContext } from 'react';
import styled from '@emotion/styled';
import { ChatInstance, ChatSettings, ChatSettingsToggleContext } from '@/brand-insights/components/Chat';
import { GetStartedContext, ChatGetStarted } from '@/brand-insights/components/Chat.GetStarted';
import { ChatHeader } from '@/brand-insights/components/Chat.Header';
import { ChatFooter, FooterHeight } from '@/brand-insights/components/Chat.Modal/Footer';
import { ChatSidebar } from '@/brand-insights/components/Chat.Modal/Sidebar';
import chatBackgroundImg from 'static/images/chat-background.png';
import { useMonitorChatIdentifier } from '../Chat.Modal/hooks';

export const BrandInsightsChatScreen = () => {

  const [showGetStarted, dismissGetStarted] = useContext(GetStartedContext);

  useMonitorChatIdentifier();

  const [settings] = useContext(ChatSettingsToggleContext);

  return (
    <Root>

      <MainChat>
        <ChatSidebar />

        <Wrap>
          {!settings && <ChatHeader />}

          {settings
            ? <ChatSettings />
            : showGetStarted
              ? <StyledGetStarted onClick={dismissGetStarted} />
              : <ChatInstance />}

        </Wrap>
      </MainChat>

      <ChatFooter />
    </Root>
  );
};

const Root = styled.div({
  height: `calc(100vh - var(--navbar-height))`,
  width: '100%',
});

const Wrap = styled.div({
  boxSizing: 'border-box',
  width: '100%',
  background: `url(${chatBackgroundImg}) no-repeat`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
});

const StyledGetStarted = styled(ChatGetStarted)(({
  marginTop: 30,
}));

const MainChat = styled.div({
  display: 'flex',
  boxSizing: 'border-box',
  width: '100%',
  height: `calc(100% - ${FooterHeight}px)`,
});