import { memo } from 'react';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import { ProjectStatus } from '@/enums';
import styles from './style/EmptyState.css';

export const EmptyState = memo(() => {
  const [qp] = useQueryParams({
    status: withDefault(NumberParam, ProjectStatus.Active),
  });

  const statusText = {
    [ProjectStatus.Active]: 'active',
    [ProjectStatus.Archived]: 'archived',
  }[qp.status];

  const title = [
    'No',
    statusText,
    'projects',
  ].filter(Boolean).join(' ');

  return (
    <div className={styles.empty}>
      <div className={styles.title}>{title}</div>
      <div className={styles.text}>Create a project to get started</div>
    </div>
  );
});
