import qs from 'query-string';
import http from '@/services/http';
import type { Users } from './interfaces';

export const fetchMedicalProfile = (data: Users.Profiles.FetchMedicalProfile.Request) => {
  return http.get<Users.Profiles.FetchMedicalProfile.Response>(`/users/${data.userId}/profiles/medical`);
};

export const fetchOverview = (data: Users.Profiles.FetchOverview.Request) => {
  return http.get<Users.Profiles.FetchOverview.Response>(`/users/${data.userId}/profiles/overview`);
};

export const fetchProfessionalProfile = (data: Users.Profiles.FetchProfessionalProfile.Request) => {
  return http.get<Users.Profiles.FetchProfessionalProfile.Response>(`/users/${data.userId}/profiles/professional`);
};

export const fetchProjectHistory = (data: Users.Profiles.FetchProjectHistory.Request) => {
  const query = qs.stringify({
    index: data.index,
    size: data.size,
  }, {
    skipNull: true,
    skipEmptyString: true,
  });
  return http.get<Users.Profiles.FetchProjectHistory.Response>(`/users/${data.userId}/profiles/projects?${query}`);
};

export const fetchScreenerResponse = (data: Users.Profiles.FetchScreenerResponse.Request) => {
  return http.get<Users.Profiles.FetchScreenerResponse.Response>(`/users/${data.userId}/profiles/screeners/${data.responseId}`);
};

export const updateUserInfo = (data: Users.Profiles.UpdateUserInfo.Request) => {
  const { userId, ...rest } = data;
  return http.put<Users.Profiles.UpdateUserInfo.Response>(`/users/${userId}/profiles`, rest);
};