import { useMemo } from 'react';
import { useSurveyBuilderState } from './useSurveyBuilderContext';

export const useSurveySection = (identifier: string) => {
  const [state] = useSurveyBuilderState();

  const section = useMemo(() => {
    return state.survey.sections
      .find(f => f.identifier === identifier);
  }, [identifier, state.survey.sections]);

  return section;
};

export default useSurveySection;