import cuid from 'cuid';
import { SurveyItemType } from '@enums/Survey';
import { SurveyTemplateEntityType } from '@enums/survey.template';
import type {
  SurveyQuestionOption,
  SurveyQuestion,
  SurveyTemplate,
  SurveyItem,
  SurveyRichText,
  SurveyQuestionOptionMetadata,
  SurveyQuestionRowMetadata,
  SurveyLogic,
  MatrixGridQuestion,
} from '@/types/survey';
import { generateMatrixGridRow, generateMultipleChoiceOption, generateMultiselectOption, generateOption, generateRankingOption, generateRow, generateRowRichText, generateSlidersRow } from './utils.question';
import { generateParagraphNode, generateRichTextNode, generateRichTextValue, generateTextNode } from './utils.rich-text';

function targetOptionMetadata(id: string): SurveyQuestionOptionMetadata {
  return {
    canModifyValue: false,
    canDelete: false,
    template: {
      linkedEntity: {
        id,
        type: SurveyTemplateEntityType.Competitor,
      },
      key: null,
    },
  };
}

export function generateTargetOption(data: GenerateTargetOption) {
  return generateOption({
    conditions: data.conditions,
    metadata: targetOptionMetadata(null),
    ordinal: data.ordinal,
    value: data.target,
  });
}

export function generateTargetMultipleChoiceOption(data: GenerateTargetOption) {
  return generateMultipleChoiceOption({
    conditions: data.conditions,
    metadata: targetOptionMetadata(null),
    ordinal: data.ordinal,
    value: data.target,
  });
}

export function generateTargetMultiselectOption(data: GenerateTargetOption) {
  return generateMultiselectOption({
    conditions: data.conditions,
    metadata: targetOptionMetadata(null),
    ordinal: data.ordinal,
    value: data.target,
  });
}

export function generateTargetRankingOption(data: GenerateTargetOption) {
  return generateRankingOption({
    conditions: data.conditions,
    metadata: targetOptionMetadata(null),
    ordinal: data.ordinal,
    value: data.target,
  });
}

function targetRowMetadata(id: string): SurveyQuestionRowMetadata {
  return {
    canModifyValue: false,
    canDelete: false,
    template: {
      linkedEntity: {
        id,
        type: SurveyTemplateEntityType.Target,
      },
    },
  };
}

export function generateTargetRow(data: GenerateTargetOption) {
  return generateRow({
    conditions: data.conditions,
    metadata: targetRowMetadata(null),
    ordinal: data.ordinal,
    value: data.target,
  });
}

export function generateMatrixGridTargetRow(data: GenerateTargetOption) {
  return generateMatrixGridRow({
    conditions: data.conditions,
    metadata: targetRowMetadata(null),
    ordinal: data.ordinal,
    value: data.target,
  });
}

export function generateSlidersTargetRow(data: GenerateTargetOption) {
  return generateSlidersRow({
    conditions: data.conditions,
    metadata: targetRowMetadata(null),
    ordinal: data.ordinal,
    value: data.target,
  });
}

function competitorOptionMetadata(id: string): SurveyQuestionOptionMetadata {
  return {
    canModifyValue: false,
    canDelete: false,
    template: {
      linkedEntity: {
        id,
        type: SurveyTemplateEntityType.Competitor,
      },
      key: null,
    },
  };
}

export function generateAttitudinalRow(data: GenerateTextItemOption) {
  return generateMatrixGridRow({
    conditions: data.conditions,
    metadata: {
      canModifyValue: false,
      canDelete: false,
      template: {
        linkedEntity: {
          id: data.item.id,
          type: SurveyTemplateEntityType.Attitudinal,
        },
        key: null,
      },
    },
    ordinal: data.ordinal,
    value: data.item.value,
  });
}

export function generateMTMedicalConditionRow(data: GenerateTextItemOption) {
  return generateSlidersRow({
    conditions: data.conditions,
    metadata: {
      canModifyValue: false,
      canDelete: false,
      template: {
        linkedEntity: {
          id: data.item.id,
          type: SurveyTemplateEntityType.MedicalCondition,
        },
        key: null,
      },
    },
    ordinal: data.ordinal,
    value: data.item.value,
  });
}

export function generateCategoryMessageRow(data: GenerateTextItemOption) {
  return generateRow({
    conditions: data.conditions,
    metadata: {
      canModifyValue: false,
      canDelete: false,
      template: {
        linkedEntity: {
          id: data.item.id,
          type: SurveyTemplateEntityType.CategoryMessage,
        },
        key: null,
      },
    },
    ordinal: data.ordinal,
    value: data.item.value,
  });
}

export function generateMedicalSpecialtyOption(data: GenerateTextItemOption) {
  return generateMultipleChoiceOption({
    conditions: data.conditions,
    metadata: {
      canModifyValue: false,
      canDelete: false,
      template: {
        linkedEntity: {
          id: data.item.id,
          type: SurveyTemplateEntityType.MedicalSpecialty,
        },
        key: null,
      },
    },
    ordinal: data.ordinal,
    value: data.item.value,
  });
}

export function generateCompetitorOption(data: GenerateTextItemOption) {
  return generateOption({
    conditions: data.conditions,
    metadata: competitorOptionMetadata(data.item.id),
    ordinal: data.ordinal,
    value: data.item.value,
  });
}

export function generateCompetitorMultiselectOption(data: GenerateTextItemOption) {
  return generateMultiselectOption({
    conditions: data.conditions,
    metadata: competitorOptionMetadata(data.item.id),
    ordinal: data.ordinal,
    value: data.item.value,
  });
}

export function generateCompetitorRankingOption(data: GenerateTextItemOption) {
  return generateRankingOption({
    conditions: data.conditions,
    metadata: competitorOptionMetadata(data.item.id),
    ordinal: data.ordinal,
    value: data.item.value,
  });
}

export function generateCompetitorMultipleChoiceOption(data: GenerateTextItemOption) {
  return generateMultipleChoiceOption({
    conditions: data.conditions,
    metadata: competitorOptionMetadata(data.item.id),
    ordinal: data.ordinal,
    value: data.item.value,
  });
}

function competitorRowMetadata(id: string): SurveyQuestionRowMetadata {
  return {
    canModifyValue: false,
    canDelete: false,
    template: {
      linkedEntity: {
        id,
        type: SurveyTemplateEntityType.Competitor,
      },
      key: null,
    },
  };
}

export function generateCompetitorRow(data: GenerateTextItemOption) {
  return generateRow({
    conditions: data.conditions,
    metadata: competitorRowMetadata(data.item.id),
    ordinal: data.ordinal,
    value: data.item.value,
  });
}

export function generateMatrixGridCompetitorRow(data: GenerateTextItemOption) {
  return generateMatrixGridRow({
    conditions: data.conditions,
    metadata: competitorRowMetadata(data.item.id),
    ordinal: data.ordinal,
    value: data.item.value,
  });
}

export function generateCompetitorSlidersRow(data: GenerateTextItemOption) {
  return generateSlidersRow({
    conditions: data.conditions,
    metadata: competitorRowMetadata(data.item.id),
    ordinal: data.ordinal,
    value: data.item.value,
  });
}

function generateLinkedEntityRow(data: GenerateLinkedEntityRow) {
  return generateRowRichText({
    id: data.id,
    base: data.base,
    conditions: data.conditions,
    metadata: {
      canModifyValue: false,
      canDelete: false,
      template: {
        linkedEntity: {
          id: data.item.id,
          type: data.type,
        },
      },
    },
    ordinal: data.ordinal,
    value: data.value || generateRichTextValue([generateTextNode(data.item.value)]),
  });
}

export function generateEvaluationCriteriaOption({ item, ...data }: GenerateTextItemOption) {
  return generateMultiselectOption({
    ...data,
    metadata: linkedEntityOptionMetadata(item.id, SurveyTemplateEntityType.EvaluationCriteria),
    ordinal: data.ordinal,
    value: item.value,
  });
}

function linkedEntityOptionMetadata(id: string, type: SurveyTemplateEntityType): SurveyQuestionOptionMetadata {
  return {
    canModifyValue: false,
    canDelete: false,
    template: {
      linkedEntity: {
        id,
        type,
      },
      key: null,
    },
  };
}

function generateLinkedEntityOption({
  conditions = [],
  type,
  ...data
}: GenerateLinkedEntityOption) {
  return generateOption({
    base: data.base,
    id: data.id,
    conditions,
    metadata: linkedEntityOptionMetadata(data.item.id, type),
    ordinal: data.ordinal,
    value: data.value || data.item.value,
  });
}

function generateLinkedEntityMultiselectOption({
  conditions = [],
  type,
  ...data
}: GenerateLinkedEntityOption) {
  return generateMultiselectOption({
    base: data.base,
    id: data.id,
    conditions,
    metadata: linkedEntityOptionMetadata(data.item.id, type),
    ordinal: data.ordinal,
    value: data.value || data.item.value,
  });
}

export function generateQualityOfLifeRow(data: Omit<GenerateLinkedEntityOption, 'type'>): MatrixGridQuestion.Row {
  return generateRowRichText({
    id: data.id,
    base: data.base,
    conditions: data.conditions,
    metadata: {
      canModifyValue: false,
      canDelete: false,
      isAnchored: false,
      isOpenEnded: false,
      isLastRowInCurrentGrid: false,
      template: {
        linkedEntity: {
          id: data.item.id,
          type: SurveyTemplateEntityType.QualityOfLife,
        },
      },
    },
    ordinal: data.ordinal,
    value: generateRichTextNode([
      generateParagraphNode([
        generateTextNode(`Quality of Life:`, [{ type: 'bold' }, { type: 'underline' }]),
      ]),
      generateParagraphNode([
        generateTextNode(data.item.value),
      ]),
    ]),
  });
}

export function generateTrialDesignRow(data: Omit<GenerateLinkedEntityOption, 'type'>): MatrixGridQuestion.Row {
  return generateRowRichText({
    id: data.id,
    base: data.base,
    conditions: data.conditions,
    metadata: {
      canModifyValue: false,
      canDelete: false,
      isAnchored: false,
      isOpenEnded: false,
      isLastRowInCurrentGrid: false,
      template: {
        linkedEntity: {
          id: data.item.id,
          type: SurveyTemplateEntityType.TrialDesign,
        },
      },
    },
    ordinal: data.ordinal,
    value: generateRichTextNode([
      generateParagraphNode([
        generateTextNode(`Trial design:`, [{ type: 'bold' }, { type: 'underline' }]),
      ]),
      generateParagraphNode([
        generateTextNode(data.item.value),
      ]),
    ]),
  });
}

export function generateDrugIndicationRow(data: Omit<GenerateLinkedEntityOption, 'type'>): MatrixGridQuestion.Row {
  return generateRowRichText({
    id: data.id,
    base: data.base,
    conditions: data.conditions,
    metadata: {
      canModifyValue: false,
      canDelete: false,
      isAnchored: false,
      isOpenEnded: false,
      isLastRowInCurrentGrid: false,
      template: {
        linkedEntity: {
          id: data.item.id,
          type: SurveyTemplateEntityType.DrugIndication,
        },
      },
    },
    ordinal: data.ordinal,
    value: generateRichTextNode([
      generateParagraphNode([
        generateTextNode(`Indication:`, [{ type: 'bold' }, { type: 'underline' }]),
      ]),
      generateParagraphNode([
        generateTextNode(data.item.value),
      ]),
    ]),
  });
}

export function generateSafetyEndpointRow(data: Omit<GenerateLinkedEntityOption, 'type'>): MatrixGridQuestion.Row {
  return generateRowRichText({
    id: data.id,
    base: data.base,
    conditions: data.conditions,
    metadata: {
      canModifyValue: false,
      canDelete: false,
      isAnchored: false,
      isOpenEnded: false,
      isLastRowInCurrentGrid: false,
      template: {
        linkedEntity: {
          id: data.item.id,
          type: SurveyTemplateEntityType.SafetyEndpoint,
        },
      },
    },
    ordinal: data.ordinal,
    value: generateRichTextNode([
      generateParagraphNode([
        generateTextNode(`Safety/tolerability:`, [{ type: 'bold' }, { type: 'underline' }]),
      ]),
      generateParagraphNode([
        generateTextNode(data.item.value),
      ]),
    ]),
  });
}

export function generateOtherAttributeRow(data: Omit<GenerateLinkedEntityOption, 'type'>): MatrixGridQuestion.Row {
  return generateRowRichText({
    id: data.id,
    base: data.base,
    conditions: data.conditions,
    metadata: {
      canModifyValue: false,
      canDelete: false,
      isAnchored: false,
      isOpenEnded: false,
      isLastRowInCurrentGrid: false,
      template: {
        linkedEntity: {
          id: data.item.id,
          type: SurveyTemplateEntityType.OtherAttribute,
        },
      },
    },
    ordinal: data.ordinal,
    value: generateRichTextNode([
      generateParagraphNode([
        generateTextNode(`Other:`, [{ type: 'bold' }, { type: 'underline' }]),
      ]),
      generateParagraphNode([
        generateTextNode(data.item.value),
      ]),
    ]),
  });
}

export function generateDoseAdministrationRow(data: Omit<GenerateLinkedEntityOption, 'type'>): MatrixGridQuestion.Row {
  return generateRowRichText({
    id: data.id,
    base: data.base,
    conditions: data.conditions,
    metadata: {
      canModifyValue: false,
      canDelete: false,
      isAnchored: false,
      isOpenEnded: false,
      isLastRowInCurrentGrid: false,
      template: {
        linkedEntity: {
          id: data.item.id,
          type: SurveyTemplateEntityType.DoseAdministration,
        },
      },
    },
    ordinal: data.ordinal,
    value: generateRichTextNode([
      generateParagraphNode([
        generateTextNode(`Dosing & Administration:`, [{ type: 'bold' }, { type: 'underline' }]),
      ]),
      generateParagraphNode([
        generateTextNode(data.item.value),
      ]),
    ]),
  });
}

export function generatePrimaryEfficacyEndpointRow(data: Omit<GenerateLinkedEntityOption, 'type'>): MatrixGridQuestion.Row {
  return generateRowRichText({
    id: data.id,
    base: data.base,
    conditions: data.conditions,
    metadata: {
      canModifyValue: false,
      canDelete: false,
      isAnchored: false,
      isOpenEnded: false,
      isLastRowInCurrentGrid: false,
      template: {
        linkedEntity: {
          id: data.item.id,
          type: SurveyTemplateEntityType.PrimaryEfficacyEndpoint,
        },
      },
    },
    ordinal: data.ordinal,
    value: generateRichTextNode([
      generateParagraphNode([
        generateTextNode(`Efficacy - 1ary EP:`, [{ type: 'bold' }, { type: 'underline' }]),
      ]),
      generateParagraphNode([
        generateTextNode(data.item.value),
      ]),
    ]),
  });
}

export function generateCoPrimaryEfficacyEndpointRow(data: Omit<GenerateLinkedEntityOption, 'type'>): MatrixGridQuestion.Row {
  return generateRowRichText({
    id: data.id,
    base: data.base,
    conditions: data.conditions,
    metadata: {
      canModifyValue: false,
      canDelete: false,
      isAnchored: false,
      isOpenEnded: false,
      isLastRowInCurrentGrid: false,
      template: {
        linkedEntity: {
          id: data.item.id,
          type: SurveyTemplateEntityType.CoPrimaryEfficacyEndpoint,
        },
      },
    },
    ordinal: data.ordinal,
    value: generateRichTextNode([
      generateParagraphNode([
        generateTextNode(`Efficacy - Co-1ary EP:`, [{ type: 'bold' }, { type: 'underline' }]),
      ]),
      generateParagraphNode([
        generateTextNode(data.item.value),
      ]),
    ]),
  });
}

export function generateSecondaryEfficacyEndpointRow(data: Omit<GenerateLinkedEntityOption, 'type'>): MatrixGridQuestion.Row {
  return generateRowRichText({
    id: data.id,
    base: data.base,
    conditions: data.conditions,
    metadata: {
      canModifyValue: false,
      canDelete: false,
      isAnchored: false,
      isOpenEnded: false,
      isLastRowInCurrentGrid: false,
      template: {
        linkedEntity: {
          id: data.item.id,
          type: SurveyTemplateEntityType.SecondaryEfficacyEndpoint,
        },
      },
    },
    ordinal: data.ordinal,
    value: generateRichTextNode([
      generateParagraphNode([
        generateTextNode(`Efficacy - 2ary EP(s):`, [{ type: 'bold' }, { type: 'underline' }]),
      ]),
      generateParagraphNode([
        generateTextNode(data.item.value),
      ]),
    ]),
  });
}

export function generatePricingComparatorOption(data: Omit<GenerateLinkedEntityOption, 'type'>) {
  return generateLinkedEntityMultiselectOption({
    ...data,
    type: SurveyTemplateEntityType.PricingComparator,
  });
}

export function generateTreatmentComparatorOption(data: Omit<GenerateLinkedEntityOption, 'type'>) {
  return generateLinkedEntityMultiselectOption({
    ...data,
    type: SurveyTemplateEntityType.TreatmentComparator,
  });
}

type GenerateLinkedEntityRow = {
  type: SurveyTemplateEntityType;
  value?: SurveyRichText.RichTextValue;
} & GenerateTextItemOption;

type GenerateLinkedEntityOption = {
  type: SurveyTemplateEntityType;
  value?: string;
} & GenerateTextItemOption;

function generatePartnershipCriteriaOptionMetadata(id: string): SurveyQuestionOptionMetadata {
  return {
    canModifyValue: false,
    canDelete: false,
    template: {
      linkedEntity: {
        id,
        type: SurveyTemplateEntityType.PartnershipCriteria,
      },
      key: null,
    },
  };
}

export function generatePartnershipCriteriaOption(data: GenerateTextItemOption) {
  return generateOption({
    conditions: data.conditions,
    metadata: generatePartnershipCriteriaOptionMetadata(data.item.id),
    ordinal: data.ordinal,
    value: data.item.value,
  });
}

export function generatePartnershipCriteriaRankingOption(data: GenerateTextItemOption) {
  return generateRankingOption({
    conditions: data.conditions,
    metadata: generatePartnershipCriteriaOptionMetadata(data.item.id),
    ordinal: data.ordinal,
    value: data.item.value,
  });
}

function targetRoleOptionMetadata(id: string): SurveyQuestionOptionMetadata {
  return {
    canModifyValue: false,
    canDelete: false,
    template: {
      linkedEntity: {
        id,
        type: SurveyTemplateEntityType.TargetRole,
      },
      key: null,
    },
  };
}

export function generateTargetRoleOption(data: GenerateTextItemOption) {
  return generateOption({
    conditions: data.conditions,
    metadata: targetRoleOptionMetadata(data.item.id),
    ordinal: data.ordinal,
    value: data.item.value,
  });
}
export function generateTargetRoleMulipleChoiceOption(data: GenerateTextItemOption) {
  return generateMultipleChoiceOption({
    conditions: data.conditions,
    metadata: targetRoleOptionMetadata(data.item.id),
    ordinal: data.ordinal,
    value: data.item.value,
  });
}
export function generateTargetRoleMultiselectOption(data: GenerateTextItemOption) {
  return generateMultiselectOption({
    conditions: data.conditions,
    metadata: targetRoleOptionMetadata(data.item.id),
    ordinal: data.ordinal,
    value: data.item.value,
  });
}

export function generateInitialTextItems(count: number, fill = ''): SurveyTemplate.LinkedEntity[] {
  return Array<string>(count)
    .fill('')
    .map((x, i) => ({
      id: cuid(),
      value: fill ? `${fill} ${i + 1}` : x,
    }));
}

export function generateItemsForQuestions(questions: SurveyQuestion[]): SurveyItem[] {
  return questions.map((m, i) => ({
    id: null,
    identifier: cuid(),
    ordinal: i + 1,
    type: SurveyItemType.Question,
    section: {
      identifier: m.section.identifier,
    },
    source: {
      identifier: m.base.identifier,
    },
  }));
}

export function generateLinkedEntity(value = ''): SurveyTemplate.LinkedEntity {
  return {
    id: cuid(),
    value,
  };
}

type GenerateTargetOption = {
  conditions?: SurveyQuestionOption['conditions'];
  ordinal: number;
  target: string;
};

type GenerateTextItemOption = {
  base?: {
    id: number;
    identifier: string;
  };
  id?: number;
  conditions?: SurveyLogic.SurveyCondition[];
  ordinal: number;
  item: SurveyTemplate.LinkedEntity;
};