import { cx } from '@utils';
import Button from '@/components/Button';
import type { ModalProps } from '@/components/Modal/Modal';
import Modal from '@/components/Modal/Modal';
import ButtonSet from '@/components/Modal/ButtonSet';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { useModal } from './hooks';
import styles from './style/Base.css';

type Props = {
  cancelText?: string;
  confirmText?: string;
  onConfirm: () => unknown;
  loading?: boolean;
  message: React.ReactNode;
} & Pick<ModalProps,
  'blocking' |
  'className' |
  'onClose' |
  'open'
>;

export const Alert = ({
  blocking = true,
  className,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  message,
  onConfirm,
  onClose,
  open,
  ...props
}: Props) => {
  return (
    <Modal
      blocking={blocking}
      className={cx(styles.alert, className)}
      onClose={onClose}
      open={open}>
      <div className={styles.alertMessage}>{message}</div>
      <ButtonSet className={styles.alertBtns}>
        <Button
          className={styles.alertBtn}
          color="destructive"
          onClick={onClose}
          variant="brick">
          {cancelText}
        </Button>
        <ButtonActivityIndicator
          className={styles.alertBtn}
          loading={props.loading}
          onClick={onConfirm}
          variant="brick">
          {confirmText}
        </ButtonActivityIndicator>
      </ButtonSet>
    </Modal>
  );
};

export const useAlert = () => {
  const [toggle, MC, open] = useModal(Alert);

  return [
    toggle,
    MC as (props: Omit<Props, 'open' | 'onClose'>) => JSX.Element,
    open,
  ] as const;
};

export type AlertModalProps = Props;

export default Alert;