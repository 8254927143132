import { useCallback } from 'react';
import { useActive, useCurrentSelection, useSelectedText } from '@remirror/react';
import { useLinkModalContext } from '@/components/Remirror/Context';

export const useLinkCommand = () => {
  const active = useActive();
  const selection = useCurrentSelection();
  const selectedText = useSelectedText();
  const {
    open: openModal,
  } = useLinkModalContext();

  const handleEdit = useCallback(() => {
    openModal({
      href: '',
      text: selectedText,
      range: {
        from: selection.from,
        to: selection.to,
      },
    });
  }, [
    openModal,
    selection,
    selectedText,
  ]);

  return {
    active: active.link(),
    onClick: handleEdit,
  };
};