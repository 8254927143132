import { useCallback, useMemo, useState } from 'react';
import { utils } from '@enums';
import AutoComplete from '@/components/AutoComplete';

type Props = {
  className?: string;
  onSelect: (item: Descriptor) => unknown;
  selected: Descriptor[];
};

export const Roles = (props: Props) => {
  const [value, setValue] = useState('');

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);

  const items = useMemo(() => {
    const remaining = options.filter(x => !props.selected.some(y => y.id === x.id));

    return !value.trim().length
      ? remaining
      : remaining.filter(x => x.name.toLowerCase().includes(value.toLowerCase()));
  }, [
    props.selected,
    value,
  ]);

  return (
    <AutoComplete.Tags
      className={props.className}
      getItemValue={(item: Descriptor) => item.name}
      items={items}
      name="roles"
      onChange={handleChange}
      onSelect={props.onSelect}
      placeholder="Enter Roles" />
  );
};

Roles.displayName = 'Filters.Roles';

const options = utils.ProfessionalRole.values().map(x => ({
  id: x,
  name: utils.ProfessionalRole.getName(x),
}));