import { forwardRef, memo, ComponentType, Ref } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { MoreHorizontal, MoreVertical, IconProps } from 'react-feather';
import { cx } from '@utils';
import styles from './style/Anchor.css';

export type AnchorProps = {
  className?: string;
  iconClassName?: string;
  Icon?: ComponentType<IconProps>;
  open: boolean;
};

export const Anchor = memo(forwardRef((props: AnchorProps, ref: Ref<HTMLDivElement>) => {
  const className = cx(styles.root, props.className, {
    [styles.open]: props.open,
  });
  const iconClassName = cx(styles.icon, props.iconClassName);
  return (
    <div className={className} ref={ref}>
      <props.Icon className={iconClassName} />
    </div>
  );
}));

export const MoreHorizontalAnchor = memo(forwardRef((props: Omit<AnchorProps, 'Icon'>, ref: Ref<HTMLDivElement>) => {
  return (
    <Anchor
      {...props}
      ref={ref}
      Icon={MoreHorizontal} />
  );
}));

export const MoreVerticalAnchor = memo(forwardRef((props: Omit<AnchorProps, 'Icon'>, ref: Ref<HTMLDivElement>) => {
  return (
    <Anchor
      {...props}
      ref={ref}
      Icon={MoreVertical} />
  );
}));

export const MoreHorizontalAnchorSmall = memo(forwardRef((props: Omit<AnchorProps, 'Icon'>, ref: Ref<HTMLDivElement>) => {
  return (
    <MoreHorizontalAnchor
      {...props}
      iconClassName={styles.small}
      ref={ref} />
  );
}));

export const MoreVerticalAnchorSmall = memo(forwardRef((props: Omit<AnchorProps, 'Icon'>, ref: Ref<HTMLDivElement>) => {
  return (
    <MoreVerticalAnchor
      {...props}
      iconClassName={styles.small}
      ref={ref} />
  );
}));

type SkeletonProps = {
  className?: string;
};

export const AnchorSkeleton = memo(({ className }: SkeletonProps) => {

  return (
    <Skeleton
      height={30}
      width={30}
      variant="rounded" />
  );
});