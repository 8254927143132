import { useCallback, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { getLocationFor } from '@/utils';
import { TaggedMomentFormDispatchContext, TaggedMomentFormStateContext, SaveTaggedMomentContext, IsSavingTaggedMomentContext } from '@/containers/Transcript/context.tagged-moment';
import { GrayOutlineButton } from '@/presentation/Buttons';
import type { ConferenceTag } from '@/types';
import { TranscriptType } from '@/enums';
import { ButtonActivityIndicator } from '@/presentation/Button.ActivityIndicator';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import { Header } from '@/components/Modal/Header';
import styles from './style/Modal.BuildTaggedMoment.css';
import { TagColorSelector } from './Tag.ColorSelector';
import { CreateableTagSelect } from './Tag.Creatable';
import { TaggedMomentEditor } from './TaggedMomentBuilder.Content';
import { useBuildTagContent } from './hooks';

type Props = Pick<ModalProps, 'onClose' | 'open'>;

export const TaggedMomentBuilderModal = ({ onClose, open }: Props) => {

  const state = useContext(TaggedMomentFormStateContext);
  const dispatch = useContext(TaggedMomentFormDispatchContext);

  const isSubmitting = useContext(IsSavingTaggedMomentContext);

  const submitDisabled = useMemo(() => {
    return !state.fromTs || !state.toTs || !state.tag?.id || !state.tag?.color;
  }, [state.fromTs, state.toTs, state.tag]);

  const handleColorChange = useCallback((value: string) => {
    dispatch({
      type: 'color-updated',
      payload: { value },
    });
  }, [dispatch]);

  const handleTagChange = useCallback((value: ConferenceTag) => {
    dispatch({
      type: 'tag-updated',
      payload: { tag: value },
    });
  }, [dispatch]);

  const displayStart = useMemo(() => {
    return secondsToTime(state.fromTs);
  }, [state.fromTs]);

  const displayEnd = useMemo(() => {
    return secondsToTime(state.toTs);
  }, [state.toTs]);

  const transcriptLocation = useMemo(() => {
    return state.transcript.context.type === TranscriptType.VideoCall
      ? getLocationFor.call.transcript({
        callId: state.transcript.context.callId,
        slug: state.transcript.context.projectId.toString(),
      }) : state.transcript.context.type === TranscriptType.WorkspaceFile
        ? getLocationFor.workspaces.transcript({
          transcriptId: state.transcript.id,
        })
        : null;
  }, [state.transcript]);

  const handleSubmit = useSubmitTaggedMoment();

  return (
    <Modal
      classes={{
        root: styles.modal,
      }}
      onClose={onClose}
      open={open}>
      <Header>Highlight Text to Save Tag</Header>

      <div className={styles.row}>
        <div className={styles.label}>Tag</div>

        <CreateableTagSelect
          onChange={handleTagChange}
          value={state.tag}
          options={state.tags} />

        <TagColorSelector
          color={state.tag?.color}
          onChange={handleColorChange} />
      </div>

      <div className={styles.row}>
        <div className={styles.range}>
          <div className={styles.label}>Start</div>
          <div className={styles.time}>{displayStart}</div>
        </div>
        <div className={styles.range}>
          <div className={styles.label}>End</div>
          <div className={styles.time}>{displayEnd}</div>
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.label}>Interview Title</div>
        <Link className={styles.link} to={transcriptLocation}>{state.transcript.name}</Link>
      </div>

      <div className={styles.content}>
        <TaggedMomentEditor />
      </div>

      <ButtonSet>
        <GrayOutlineButton
          onClick={onClose}>
          Discard
        </GrayOutlineButton>
        <ButtonActivityIndicator
          type="submit"
          loading={isSubmitting}
          implicitDisable={isSubmitting}
          disabled={submitDisabled}
          onClick={handleSubmit}>
          Save Tag
        </ButtonActivityIndicator>
      </ButtonSet>
    </Modal>
  );
};

const useSubmitTaggedMoment = () => {
  const form = useContext(TaggedMomentFormStateContext);
  const saveTaggedMoment = useContext(SaveTaggedMomentContext);

  const buildTagContent = useBuildTagContent();

  const handleSubmit = useCallback(() => {
    const content = buildTagContent(form.fromTs, form.toTs);

    return saveTaggedMoment({
      content,
      momentId: form.momentId,
      fromTs: form.fromTs,
      toTs: form.toTs,
      tag: form.tag,
      transcriptId: form.transcript.id,
    });
  }, [buildTagContent, saveTaggedMoment, form]);

  return handleSubmit;
};

function secondsToTime(value: number) {
  const time = new Date(value * 1000).toISOString().substring(11, 19);
  return time.startsWith('00') ? time.substring(3) : time;
}