import { useContext, useMemo } from 'react';
import { NumberParam, type QueryParamConfig, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { useQuery } from '@tanstack/react-query';
import * as $api from '@api';
import { BrandStateContext } from '@/containers/Workspace.Brand/Context';
import type { WorkspaceBrand } from '@api/interfaces/workspaces';
import { ProjectStatus } from '@/enums';
import * as Table from '@/components/Table';
import { ProjectsTable } from '@/screens/Group.Projects/Projects.Table';
import styles from './style/Tab.Projects.css';

const ViewComponent = () => {
  const { brand } = useContext(BrandStateContext);

  const [qp, setQuery] = useQueryParams({
    index: withDefault(NumberParam, 0),
    size: withDefault(NumberParam, 25),
    status: withDefault(NumberParam, ProjectStatus.Active),
    sortBy: withDefault(StringParam, 'created') as QueryParamConfig<'created' | 'name' | 'owner' | 'progress'>,
    sortDir: withDefault(StringParam, 'desc') as QueryParamConfig<'asc' | 'desc'>,
  });

  const queryKey = useMemo(() => {
    return [
      `get:brand:projects`, {
        brandId: brand.id,
        index: qp.index,
        size: qp.size,
        sortBy: qp.sortBy,
        sortDir: qp.sortDir,
      },
    ];
  }, [brand.id, qp]);

  const query = useQuery(queryKey, () => {
    return $api.workspaces.brand.getProjects({
      brandId: brand.id,
      index: qp.index,
      size: qp.size,
      sortBy: qp.sortBy,
      sortDir: qp.sortDir,
    });
  }, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const pagination = useMemo(() => ({
    pageCount: query.data?.pagination?.pageCount ?? 0,
    totalCount: query.data?.pagination?.totalCount ?? 0,
  }), [
    query.data?.pagination,
  ]);

  const loading = useMemo(() => query.isInitialLoading && !query.data, [query.data, query.isInitialLoading]);

  const data = useMemo(() => {
    return loading
      ? getLazyTableData(25)
      : query.data?.items ?? [];
  }, [loading, query.data?.items]);

  const empty = useMemo(() => {
    return query.isFetchedAfterMount && !loading && !data.length;
  }, [data.length, loading, query.isFetchedAfterMount]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.table}>
            <Table.Layout.Box className={styles.box}>
              <Table.Layout.Header
                classes={{ title: styles.title }}
                title="Projects" />
              <ProjectsTable
                classes={{
                  body: styles.body,
                }}
                data={data}
                empty={empty}
                loading={loading}
                pagination={pagination}
                query={qp}
                setQuery={setQuery} />
            </Table.Layout.Box>
          </div>
        </div>
      </div>
    </div>
  );
};

const getLazyTableData = <T extends WorkspaceBrand.GetProjects.ProjectItem>(pageSize = 25) => {
  return Array.from({ length: pageSize }, _ => ({} as T));
};

export const BrandTabProjects = () => (
  <>
    <ViewComponent />
  </>
);

BrandTabProjects.displayName = 'Workspace.Brand.Tab.Projects';