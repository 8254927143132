import { useMemo } from 'react';
import { AddItemButton } from '@presentation/AddItemButton';
import { SurveyTemplate } from '@/types/survey.templates';
import { TextEntryList } from '@/components/TextEntryList';
import styles from './style/Presets.css';

type Props = {
  canAddCompetitor: boolean;
  canRemoveCompetitor: boolean;
  competitors: SurveyTemplate.Competitor[];
  onAddCompetitor: () => void;
  onRemoveCompetitor: (id: string) => () => void;
  onUpdateCompetitor: (id: string) => (v: string) => void;
};

export const TemplateCompetitorsInput = ({
  canAddCompetitor,
  canRemoveCompetitor,
  onAddCompetitor,
  onRemoveCompetitor,
  onUpdateCompetitor,
  competitors,
}: Props) => {

  const items = useMemo(() => {
    return competitors.map(m => ({
      key: m.id,
      value: m.value,
    }));
  }, [competitors]);

  return (
    <>
      <TextEntryList
        items={items}
        placeholder="Enter a company or product name"
        removeItem={onRemoveCompetitor}
        removeItemEnabled={canRemoveCompetitor}
        updateItemValue={onUpdateCompetitor} />
      {canAddCompetitor &&
        <AddItemButton
          className={styles.add}
          label="Add"
          onClick={onAddCompetitor} />}
    </>
  );
};