import { useEffect, useState } from 'react';
import type { Range } from 'slate';
import { RichTextMentionsContext, RichTextAutoCompleteContext } from './Context';
import type { TagNode } from './interfaces';

type Props<T> = {
  children: React.ReactNode;
  getOptionTagProps: (option: T) => TagNode;
  onInput: (data: string) => unknown;
  options: T[];
};

const RichTextMentionsContainer = <T, >({ options = [], ...props }: Props<T>) => {
  const [target, setTarget] = useState<Range | undefined>();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState('');

  const context = {
    index,
    search,
    setIndex,
    setSearch,
    setTarget,
    target,
  };

  useEffect(() => {

    props.onInput(search);

    /* eslint-disable-next-line */
  }, [search]);

  const autocomplete = {
    getOptionLabel: (option: T) => props.getOptionTagProps(option)?.label,
    getOptionTagProps: props.getOptionTagProps,
    options: options,
  };

  return (
    <RichTextMentionsContext.Provider value={context}>
      <RichTextAutoCompleteContext.Provider value={autocomplete}>
        {props.children}
      </RichTextAutoCompleteContext.Provider>
    </RichTextMentionsContext.Provider>
  );
};

export { RichTextMentionsContainer };
export default RichTextMentionsContainer;

/*
  extract ids from nodes

  const extractIds = (acc, childs) => {
    const ids = childs.filter(x => x.id).map(x => x.id);

    return acc.concat(ids);
  };

  const ids = form.body.value.reduce((acc, x) => {
    if (x.children) {
      acc = extractIds(acc, x.children);
    }

    if (x.id) {
      acc = acc.concat(x.id);
    }

    return acc;
  }, []);
*/