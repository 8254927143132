import { SegmentQueryValue } from '$website/containers/MarketSegmentation/interfaces';
import * as core from './core';

const appendQuery = core.setSegmentQueryParams(SegmentQueryValue.Corporate);

const landing = {
  button: {
    cta: appendQuery(core.main.landing.button.signup),
    login: core.main.landing.button.login,
  },
  text: {
    copy: core.main.landing.text.copy,
    title: core.main.landing.text.title,
  },
};

const banner = {
  button: {
    cta: {
      text: `Get answers to your most pressing business problems`,
      ...appendQuery(core.ui.link.signup),
    },
  },
};

const benefits = {
  collection: {
    benefits: [{
      image: {
        icon: core.main.benefits.image.discovery,
      },
      text: {
        copy: `Build better products with feedback from potential users`,
      },
    }, {
      image: {
        icon: core.main.benefits.image.efficiency,
      },
      text: {
        copy: `Uncover operational efficiencies for your business from former executives`,
      },
    }, {
      image: {
        icon: core.main.benefits.image.chart,
      },
      text: {
        copy: `Scale your business with advice from the brightest industry professionals`,
      },
    }],
  },
  text: {
    title: `Stop guessing and start gathering answers for your company's product and competitive intelligence questions`,
  },
};

const funnel = {
  button: {
    cta: appendQuery(core.main.funnel.button.signup),
  },
  text: {
    cta: core.main.funnel.text.signup,
  },
};

const operations = {
  text: {
    header: `Sentiment is where professionals come to gather market insights.`,
    subheader: `Join our platform to ask top industry leaders questions on problems relevant to your business.`,
    planning: `Organizational Planning`,
    product: `Product Market Fit`,
  },
  collection: {
    planning: [{
      button: {
        link: {
          text: `How do you set up a digital marketing funnel (i.e. outbound email, digital ads, etc)?`,
          ...appendQuery(core.ui.link.signup),
        },
      },
    }, {
      button: {
        link: {
          text: `How should you structure your sales team?`,
          ...appendQuery(core.ui.link.signup),
        },
      },
    }, {
      button: {
        link: {
          text: `How should you set up your sales and operational teams?....and when!`,
          ...appendQuery(core.ui.link.signup),
        },
      },
    }],
    product: [{
      button: {
        link: {
          text: `What functions do your clients really want?`,
          ...appendQuery(core.ui.link.signup),
        },
      },
    }, {
      button: {
        link: {
          text: `What pricing schemes will work?`,
          ...appendQuery(core.ui.link.signup),
        },
      },
    }, {
      button: {
        link: {
          text: `What do your future clients think of market incumbents?`,
          ...appendQuery(core.ui.link.signup),
        },
      },
    }],
  },
};

export const content = {
  main: {
    banner,
    benefits,
    landing,
    funnel,
    operations,
    press: core.main.press,
  },
  nav: {
    button: {
      cta: appendQuery(core.nav.button.signup),
      login: core.ui.button.login,
      signup: appendQuery(core.ui.button.signup),
    },
  },
  signup: {
    feature: core.signup.feature,
  },
};