import { useCallback, useEffect, useRef } from 'react';

type UseMounted = () => () => boolean;

const useMounted: UseMounted = () => {
  const ref = useRef(false);

  useEffect(() => {
    ref.current = true;

    return () => {
      ref.current = false;
    };
  });

  const isMounted = useCallback(() => ref.current, []);

  return isMounted;
};

export { useMounted };
export default useMounted;