import qs from 'query-string';
import http from '@services/http';
import type { Groups } from '@services/api/interfaces';
import type { DownloadConsultantGroupDocumentRequest, DownloadConsultantProjectDocumentRequest } from './interfaces';

export * as apps from './groups.apps';
export * as branding from './groups.branding';
export * as compliance from './groups.compliance';
export * as contacts from './groups.contacts';
export * as projects from './groups.projects';

export const getGroupUsers = (data: Groups.GetGroupUsers.Request): Promise<Groups.GetGroupUsers.Response> => {
  return http.get(`/groups/users?${qs.stringify(data, { arrayFormat: 'bracket' })}`);
};

export const getGroupComplianceSurvey = (): Promise<Groups.GetComplianceSurveyResponse> => {
  return http.get(`/groups/compliance-survey`);
};

export const getGroupPostCallAnalystSurvey  = (): Promise<Groups.GetPostCallAnalystQuestionsResponse> => {
  return http.get(`/groups/post-call-analyst-questions`);
};

export const downloadConsultantGroupDocument = (data: DownloadConsultantGroupDocumentRequest): Promise<Blob> => {
  return http.get(`/documents/consultants/${data.userId}/groups/${data.groupId}`, { responseType: 'blob' });
};

export const downloadConsultantProjectDocument = (data: DownloadConsultantProjectDocumentRequest): Promise<Blob> => {
  return http.get(`/documents/consultants/${data.userId}/projects/${data.projectId}`, { responseType: 'blob' });
};

export const saveGroupComplianceSurvey = (data: Groups.SaveComplianceSurveyRequest): Promise<Groups.SaveComplianceSurveyResponse> => {
  return http.post(`/groups/compliance-survey`, data);
};

export const saveGroupPostCallAnalystSurvey = (data: Groups.SavePostCallAnalystQuestionsRequest): Promise<Groups.SavePostCallAnalystQuestionsResponse> => {
  return http.post(`/groups/post-call-analyst-questions`, data);
};

export const updateGroupSettings = (data: Groups.UpdateGroupSettingsRequest): Promise<Groups.UpdateGroupSettingsResponse> => {
  return http.patch(`/groups/${data.groupId}/settings`, data);
};

export const exportGroupData = (): Promise<Groups.ExportGroupData.Response> => {
  return http.get('/groups/export');
};

export const fetchAccounts = () => {
  return http.get<Groups.FetchAccounts.Response>('/groups/search');
};

export const sendGroupUserInviteEmail = (data: IUserId) => {
  return http.post<void>(`/groups/emails/invite`, data);
};