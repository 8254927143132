import { Share2 } from 'react-feather';
import { cx } from '@utils';
import styles from './style.legacy.css';

type Props = {
  className?: string;
  disabled?: boolean;
  onClick: () => void;
};

export const Icon = (props: Props) => {
  const classes = cx(styles.shareBtn, {
    [styles.disabled]: props.disabled,
  }, props.className);

  const handleClick = props.disabled
      ? () => {}
      : props.onClick;

  return (
    <div
      className={classes}
      onClick={handleClick}>
      <Share2
        height={20}
        width={20} />
      <span className={styles.hideMobile}>Share</span>
    </div>
  );
};

export default Icon;