import { useContext } from 'react';
import { SearchParamsContext } from '../Context';

type Params = unknown;

const useSearchParams = () => {
  const [params, actions] = useContext(SearchParamsContext);

  return [params, actions] as const;
};

export { useSearchParams };
export default useSearchParams;