import { useMemo } from 'react';
import * as utils from '@utils';
import { useSelectUser } from '@/containers/Store/hooks';

export const useHasInternalAdminBillingAccess = () => {
  const me = useSelectUser();

  const hasInternalAdminBillingAccess = useMemo(() => {
    return utils.hasInternalAdminBillingAccess({
      permissions: me.permissions,
    });
  }, [me.permissions]);

  return hasInternalAdminBillingAccess;
};

export default useHasInternalAdminBillingAccess;