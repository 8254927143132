import { memo, useContext } from 'react';
import Select, { type ActionMeta } from 'react-select';
import type { Members } from '$admin/interfaces/search';
import { FilterOptionsContext } from '$admin/Search/Context';
import type { Filters } from '$admin/Search/interfaces/members';
import selectStyles from './SelectStyles';
import styles from './style/Filters.css';

type Props = {
  value: Members.Filters.Field;
  onChange: (value: Filters.FieldNameOption, meta: ActionMeta<Filters.FieldNameOption>) => unknown;
};

export const FieldName = memo(({ value, onChange }: Props) => {
  const options = useContext(FilterOptionsContext);

  return (
    <div className={styles.fieldName}>
      <Select<Filters.FieldNameOption>
        className={styles.select}
        options={options}
        value={options.find(o => o.id === value)}
        getOptionLabel={o => o.name}
        getOptionValue={o => o.id}
        onChange={onChange}
        isSearchable={false}
        styles={selectStyles} />
    </div>
  );
});