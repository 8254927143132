import { useCallback, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHasConsultantRole } from '@containers/User';
import { UserSocketEvent } from '@enums';
import * as actions from '@store/actions';
import { useAppReadyState } from '@containers/AppReadyState';
import * as ws from '@services/websocket';
import type { WS } from '@/types';
import { useUserSocketEvent } from '@services/websocket';

type Props =
  ChildrenProps;

export const UserSocketContainer = (props: Props) => {
  const dispatch = useDispatch();
  const isConsultant = useHasConsultantRole();

  const { hydrated } = useAppReadyState();

  const handleInfoUpdated = useCallback((data: WS.User.InfoUpdated.Payload) => {
    dispatch(actions.userInfoChange({ ...data }));
  }, [dispatch]);

  const enabled = useMemo(() => {
    return hydrated && isConsultant;
  }, [hydrated, isConsultant]);

  useUserSocketEvent(UserSocketEvent.InfoUpdated, handleInfoUpdated, enabled);

  useEffect(() => {
    if (enabled) {
      ws.user.open();
      return () => ws.user.close();
    }
  }, [enabled]);

  return (
    <>
      {props.children}
    </>
  );
};
