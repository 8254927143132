import { useCallback } from 'react';
import * as api from '@api';

export const useConnectSlack = () => {

  return useCallback(async () => {
    const { url } = await api.integrations.getSlackInstallationUrl();

    if (!url) return;

    window.location.href = url;

  }, []);

};

export default useConnectSlack;