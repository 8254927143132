import { matchPath, useLocation } from 'react-router-dom';
import { NumberParam, useQueryParam } from 'use-query-params';
import { path } from '@consts';
import { Button } from '@/components/Button';
import styles from './style/Footer.css';

export const Sentiment = () => {
  const [platform] = useQueryParam('platform', NumberParam);
  const [ba] = useQueryParam('ba', NumberParam);
  const location = useLocation();
  const match = matchPath(location.pathname, {
    path: [
      path.Website.Platform,
      path.Inbound.AuthError,
      path.Inbound.AuthPW,
    ],
  });
  const isTermsBA = matchPath(location.pathname, {
    path: [
      path.Website.Terms.BrandInsights,
    ],
  });

  const isPlatform = !!platform || !!match;
  const isBA = !!ba || !!isTermsBA;

  const q = isPlatform && isBA
    ? '?platform=1&ba=1'
    : isPlatform
      ? '?platform=1'
      : isBA
        ? '?ba=1'
        : '';

  const privacyUrl = `${path.Website.PrivacyPolicy.Root2}${q}`;
  const termsUrl = `${path.Website.Terms.Root2}${q}`;

  return (
    <div className={styles.sentimentRoot}>
      <div className={styles.links}>
        <Button
          className={styles.link}
          variant='link'
          to={privacyUrl}>
          Privacy Policy
        </Button>
        <Button
          className={styles.link}
          variant='link'
          to={termsUrl}>
          Terms of Use
        </Button>
      </div>
      <div className={styles.copyright}>
        &copy; {new Date().getFullYear()} Trinity. All rights Reserved.
      </div>
    </div>
  );
};