import { useContext } from 'react';
import { useSuggestedTopicsState, useCreateTopicsState } from '@containers/Topic/hooks';
import { FollowedTopicsContext } from '@containers/Topic/Context';
import { Item } from './Following.Item';
import { useSearchBar } from './hooks/useSearchBar';

type Props = unknown;

export const Suggested = (props: Props) => {
  const items = useSuggestedTopicsState();
  const [searchState, SearchBar] = useSearchBar();
  const followed = useContext(FollowedTopicsContext);

  const searchedItems = useCreateTopicsState({ data: searchState.returnedItems ?? [], followed: followed.topics });
  const displayItems = searchState.returnedItems ? searchedItems : items;

  return (
    <>
      <SearchBar term={searchState.searchTerm} isLoading={searchState.isLoading} />
      {displayItems.map(x =>
        <Item
          following={x.following}
          item={x.item}
          onToggleFollowing={x.onToggleFollowing}
          key={x.item.slug} />)}
    </>
  );
};

Suggested.displayName = 'Following.Suggested';