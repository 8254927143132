import * as Layout from './Layout';
import styles from './style/BubbleNote.css';

type Props = unknown;

export const BubbleNote = (props: Props) => {

  return (
    <Layout.Screen>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.main}>
            {copy.items.map(x =>
              <span
                className={x.className}
                key={x.text}>
                {x.text}
              </span>)}
          </div>
        </div>
      </div>
    </Layout.Screen>
  );
};

BubbleNote.displayName = 'ResearchCommunity.BubbleNote';

const copy = {
  items: [
    {
      text: `In just the last three years, Trinity's research has produced nearly `,
    },
    {
      className: styles.highlight,
      text: `90 `,
    },
    {
      className: styles.em,
      text: `peer-reviewed scientific papers `,
    },
    {
      text: `and national & international conference presentations.`,
    },
  ],
};