import { useMutation } from '@tanstack/react-query';
import { useCallback, useContext } from 'react';
import { saveTaggedMoment } from '@api/transcripts';
import type { SaveTaggedMoment } from '@api/interfaces/transcripts';
import { IsSavingTaggedMomentContext, SaveTaggedMomentContext, SetTaggedMomentEditParamsContext } from './context';

type Props = {
  onSuccess?: (data: SaveTaggedMoment.Response) => Promise<void>;
} & ChildrenProps;

export const SaveTaggedMomentFormContainer = ({
  onSuccess,
  ...props
}: Props) => {

  const setModalParams = useContext(SetTaggedMomentEditParamsContext);

  const mutationFn = useCallback((params: SaveTaggedMoment.Request) => {
    return saveTaggedMoment(params);
  }, []);

  const handleSuccess = useCallback(async (data: SaveTaggedMoment.Response) => {
    await onSuccess?.(data);
    setModalParams(null);
  }, [onSuccess, setModalParams]);

  const mutation = useMutation({
    mutationFn,
    onSuccess: handleSuccess,
  });

  return (
    <IsSavingTaggedMomentContext.Provider value={mutation.isLoading}>
      <SaveTaggedMomentContext.Provider value={mutation.mutateAsync}>
        {props.children}
      </SaveTaggedMomentContext.Provider>
    </IsSavingTaggedMomentContext.Provider>
  );
};