import { useState } from 'react';
import { ButtonActivityIndicator } from '@presentation';
import { Input } from '@/components/Input';
import { Header } from './Header';
import { Modal } from './Modal';
import styles from './style/NameRequiredForCurrentUser.css';

type Props = {
  onSubmit: (form: typeof initial) => void;
  submitting: boolean;
};

export const NameRequiredForCurrentUser = (props: Props) => {
  const [form, setForm] = useState(initial);

  return (
    <Modal
      classes={{
        root: styles.root,
        wrap: styles.wrap,
      }}
      disableEscapeClose
      disableOverlayClick
      hideCloseIcon
      open>
      <Header className={styles.title}>{copy.title}</Header>
      <div className={styles.row}>
        <div className={styles.label}>First Name</div>
        <Input
          autoFocus
          name="firstName"
          onChange={e => setForm({ ...form, firstName: e.target.value })}
          value={form.firstName} />
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Last Name</div>
        <Input
          autoFocus
          name="lastName"
          onChange={e => setForm({ ...form, lastName: e.target.value })}
          value={form.lastName} />
      </div>
      <div className={styles.footer}>
        <ButtonActivityIndicator
          className={styles.btn}
          disabled={!form.firstName.trim() || !form.lastName.trim()}
          implicitDisable={false}
          loading={props.submitting}
          onClick={() => props.onSubmit(form)}>
          Submit
        </ButtonActivityIndicator>
      </div>
    </Modal>
  );
};

NameRequiredForCurrentUser.displayName = 'NameRequiredForCurrentUser';

const copy = {
  title: `Welcome! Let's get your account set up.`,
};

const initial = {
  firstName: '',
  lastName: '',
};