import { useEffect, useState } from 'react';
import { NetworkQualityLevel, NetworkQualityStats, Participant } from 'twilio-video';

export function useParticipantNetworkQualityLevel(participant: Participant) {
  const [level, setLevel] = useState(participant.networkQualityLevel);

  useEffect(() => {
    const handleChange = (level: NetworkQualityLevel, stats: NetworkQualityStats) => {
      setLevel(level);
    };

    setLevel(participant.networkQualityLevel);

    participant.on('networkQualityLevelChanged', handleChange);
    return () => {
      participant.off('networkQualityLevelChanged', handleChange);
    };
  }, [participant]);

  return level;
}

export default useParticipantNetworkQualityLevel;