import type { Ref } from 'react';
import { forwardRef, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { ChevronRight } from 'react-feather';
import { Link } from 'react-router-dom';
import Popper from '@mui/material/Popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import { ProjectDisplayrDashboardIdContext } from '@containers/GroupProject/Context';
import { useProjectActions, useProjectActionsValidity } from '@containers/GroupProject/hooks';
import { ProjectAction, utils as $enums } from '@enums';
import { MoreHorizontalAnchor } from '@presentation/Anchor';
import { getLocationFor } from '@utils';
import { PopperMenu, PopperMenuItem } from '@/components/Popper';
import { Portal } from '@/components/Portal';
import type { ProjectActionsContextMenuItem, ProjectActionsPopperProps, ProjectActionsMenuProps } from './interfaces';
import styles from './style/ProjectActionsContextMenu.css';

type ClickEvent = () => unknown;

const ProjectActionsContextMenu = forwardRef(({ excluded = [], onClose }: ProjectActionsMenuProps, ref: Ref<HTMLDivElement>) => {
  const actions = useProjectActions();
  const valid = useProjectActionsValidity();

  const displayrDashboardId = useContext(ProjectDisplayrDashboardIdContext);

  const [exportOpen, setExportOpen] = useState(false);
  const [exportPopperElement, setExportPopperElement] = useState<HTMLDivElement>(null);
  const exportItemRef = useRef<HTMLDivElement>();

  const { styles: popperStyles, attributes } = usePopper(exportItemRef?.current, exportPopperElement, {
    modifiers: [],
    placement: 'right-start',
  });

  const handleClick = useCallback((fn: ClickEvent) => () => {
    onClose();
    return fn();
  }, [onClose]);

  const activate = useMemo(() => {

    return {
      children: 'Activate',
      onClick: actions[ProjectAction.Activate],
    };
  }, [actions]);

  const archive = useMemo(() => {
    return {
      children: 'Archive',
      onClick: actions[ProjectAction.Archive],
    };
  }, [actions]);

  const cloneSurvey = useMemo(() => {
    return {
      children: 'Clone Survey',
      onClick: actions[ProjectAction.Clone],
    };

  }, [actions]);

  const createCall = useMemo(() => {
    return {
      children: `Create Call`,
      onClick: actions[ProjectAction.CreateCall],
    };
  }, [actions]);

  const editSurvey = useMemo(() => {
    return {
      children: 'Manage Surveys',
      onClick: actions[ProjectAction.EditSurvey],
    };
  }, [actions]);

  const share = useMemo(() => {
    return {
      children: 'Share',
      onClick: actions.share,
    };
  }, [actions]);

  const ownership = useMemo(() => {
    return {
      children: 'Change Owner',
      onClick: actions[ProjectAction.ChangeOwner],
    };
  }, [actions]);

  const move = useMemo(() => {
    return {
      children: 'Move',
      onClick: actions[ProjectAction.Move],
    };
  }, [actions]);

  const manageDisplayrDashboard = useMemo(() => {
    return {
      children: displayrDashboardId ? 'Update Dashboard' : 'Create Dashboard',
      onClick: actions[ProjectAction.ManageDisplayrDashboard],
    };
  }, [actions, displayrDashboardId]);

  const preview = useMemo(() => ({
    children: (
      <Link
        className={styles.linkButton}
        target="_blank"
        to={getLocationFor.project.preview({ id: actions.projectId })}>Preview Survey
      </Link>),
    onClick: () => { },
  }), [actions.projectId]);

  const surveyMonitoring = useMemo(() => {
    return {
      children: 'Survey Monitoring',
      onClick: actions[ProjectAction.MonitorSurvey],
    };
  }, [actions]);

  const surveySettings = useMemo(() => {
    return {
      children: 'Survey Settings',
      onClick: actions[ProjectAction.ManageSurveySettings],
    };
  }, [actions]);

  const uploadQuantFile = useMemo(() => {
    return {
      children: 'Upload Quant Analysis File',
      onClick: actions[ProjectAction.UploadQuantAnalysisFile],
    };
  }, [actions]);

  const handleExportBlur = useCallback(() => {
    setExportOpen(false);
  }, []);

  const showExportItem = useMemo(() => ([
    ProjectAction.DownloadCallReport,
    ProjectAction.DownloadSurveyReport,
    ProjectAction.DownloadHighlightsReport,
    ProjectAction.DownloadTagSummariesReport,
    ProjectAction.DownloadInsightsReport,
  ].filter(action => valid[action]).length > 0), [valid]);

  const items: ProjectActionsContextMenuItem[] = useMemo(() => {

    const allMenuItemsMap: Partial<Record<ProjectAction, ProjectActionsContextMenuItem>> = {
      [ProjectAction.Share]: share,
      [ProjectAction.ChangeOwner]: ownership,
      [ProjectAction.CreateCall]: createCall,
      [ProjectAction.Clone]: cloneSurvey,
      [ProjectAction.Archive]: archive,
      [ProjectAction.Activate]: activate,
      [ProjectAction.Move]: move,
      [ProjectAction.EditSurvey]: editSurvey,
      [ProjectAction.ManageSurveySettings]: surveySettings,
      [ProjectAction.Preview]: preview,
      [ProjectAction.MonitorSurvey]: surveyMonitoring,
      [ProjectAction.ManageDisplayrDashboard]: manageDisplayrDashboard,
      [ProjectAction.UploadQuantAnalysisFile]: uploadQuantFile,
    };

    return Object.keys(allMenuItemsMap).map(key => key as ProjectAction)
      .filter(f => valid[f] && !excluded.includes(f))
      .map(m => allMenuItemsMap[m]);

  }, [
    archive,
    activate,
    cloneSurvey,
    createCall,
    editSurvey,
    excluded,
    move,
    ownership,
    share,
    valid,
    preview,
    surveyMonitoring,
    surveySettings,
    manageDisplayrDashboard,
    uploadQuantFile,
  ]);

  return (
    <>
      <PopperMenu ref={ref}>
        {items.map((item, i) => (
          <PopperMenuItem
            key={i}
            onClick={handleClick(item.onClick)}>
            {item.children}
          </PopperMenuItem>
        ))}
        {showExportItem &&
          <PopperMenuItem
            ref={exportItemRef}
            className={styles.exportItem}
            onClick={() => setExportOpen(true)}>
            Export
            <ChevronRight className={styles.icon} />
          </PopperMenuItem>
        }
      </PopperMenu>
      {exportOpen &&
        <Portal>
          <ClickAwayListener
            mouseEvent='onMouseDown'
            onClickAway={handleExportBlur}
            touchEvent='onTouchStart'>
            <div
              className={styles.menu}
              ref={setExportPopperElement}
              style={popperStyles.popper}
              {...attributes.popper}>
              <ExportActionsMenu excluded={excluded} onClose={handleExportBlur} />
            </div>
          </ClickAwayListener>
        </Portal>
      }
    </>
  );
});

const ExportActionsMenu = forwardRef(({ excluded = [], onClose }: ProjectActionsMenuProps, ref: Ref<HTMLDivElement>) => {

  const actions = useProjectActions();
  const valid = useProjectActionsValidity();

  const handleClick = useCallback((fn: ClickEvent) => () => {
    onClose();
    return fn();
  }, [onClose]);

  const highlightsReport = useMemo(() => ({
    children: 'Highlights',
    onClick: actions[ProjectAction.DownloadHighlightsReport],
  }), [actions]);

  const tagSummariesExport = useMemo(() => ({
    children: 'Tag Summaries',
    onClick: actions[ProjectAction.DownloadTagSummariesReport],
  }), [actions]);

  const responseGridExport = useMemo(() => ({
    children: 'Response Grid',
    onClick: actions[ProjectAction.DownloadResponseGrid],
  }), [actions]);

  const insightsReport = useMemo(() => ({
    children: 'Insights Report',
    onClick: actions[ProjectAction.DownloadInsightsReport],
  }), [actions]);

  const callReport = useMemo(() => {
    return {
      children: 'Call Data',
      onClick: actions[ProjectAction.DownloadCallReport],
    };
  }, [actions]);

  const surveyReport = useMemo(() => {
    return {
      children: 'Survey Data',
      onClick: actions[ProjectAction.DownloadSurveyReport],
    };
  }, [actions]);

  const items: ProjectActionsContextMenuItem[] = useMemo(() => [
    valid[ProjectAction.DownloadCallReport] && !excluded.includes(ProjectAction.DownloadCallReport) && callReport,
    valid[ProjectAction.DownloadSurveyReport] && !excluded.includes(ProjectAction.DownloadSurveyReport) && surveyReport,
    valid[ProjectAction.DownloadHighlightsReport] && !excluded.includes(ProjectAction.DownloadHighlightsReport) && highlightsReport,
    valid[ProjectAction.DownloadTagSummariesReport] && !excluded.includes(ProjectAction.DownloadTagSummariesReport) && tagSummariesExport,
    valid[ProjectAction.DownloadResponseGrid] && !excluded.includes(ProjectAction.DownloadResponseGrid) && responseGridExport,
    valid[ProjectAction.DownloadInsightsReport] && insightsReport,
  ].filter(Boolean), [
    callReport,
    excluded,
    highlightsReport,
    insightsReport,
    valid,
    responseGridExport,
    surveyReport,
    tagSummariesExport,
  ]);

  return (
    <PopperMenu ref={ref}>
      {items.map((item, i) => (
        <PopperMenuItem
          key={i}
          onClick={handleClick(item.onClick)}>
          {item.children}
        </PopperMenuItem>
      ))}
    </PopperMenu>
  );
});

export const ProjectActionsContextMenuPopper = ({
  Anchor = MoreHorizontalAnchor,
  className,
  excluded,
  placement = 'bottom-end',
}: ProjectActionsPopperProps) => {

  return (
    <PopupState variant="popper" popupId="project-actions-popper">
      {popupState => (
        <div className={className}>
          <div {...bindToggle(popupState)}>
            <Anchor open={popupState.isOpen} />
          </div>
          <Popper
            {...bindPopper(popupState)}
            placement={placement}
            className={styles.popper}>
            <ClickAwayListener
              onClickAway={popupState.close}>
              <ProjectActionsContextMenu
                onClose={popupState.close}
                excluded={excluded} />
            </ClickAwayListener>
          </Popper>
        </div>
      )}
    </PopupState>
  );
};