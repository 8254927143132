import { useCallback } from 'react';
import { useRemirrorContext } from '@remirror/react';
import type { DocumentNode } from '@/types/transcribe.rich-text';

export const useUpdateEditorView = () => {
  const re = useRemirrorContext();

  const updateEditorView = (content: DocumentNode) => {
    if (re.manager.view) {
      const newState = re.manager.createState({
        content,
      });

      re.manager.view.updateState(newState);
    }
  };

  return useCallback(updateEditorView, [re]);
};