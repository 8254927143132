export const Blog = 'https://www.vancery.com/insights';
export const FAQ = 'https://sentimentglobal.com/faqs';

export const LinkedIn = 'https://www.linkedin.com/company/trinitylifesciences';
export const Twitter = 'https://twitter.com/TrinityLifeSci';
export const YouTube = 'https://www.youtube.com/channel/UCojllqGVy1TFYTecooNvFmA';
export const Facebook = 'https://www.facebook.com/TrinityLifeSciences';

export const StripeConnectedAccountAgreementUrl = `https://stripe.com/connect-account/legal`;
export const StripeServicesAgreementUrl = `https://stripe.com/legal`;

export const SnowflakeReport = 'https://go.vancery.com/snowflake';
export const SurveyBestPractices = 'https://go.vancery.com/survey-best-practices';
export const UltimateProductResearchGuide = 'https://go.vancery.com/product-research-guide';