import { memo } from 'react';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { LocationDescriptor } from 'history';
import { cx } from '@utils';
import { Tooltip } from '@presentation/Tooltip';
import { MaybeLink } from '@/components/MaybeLink';
import styles from './style/ViewAll.css';

type Props = {
  className?: string;
  onClick?: () => void;
  to?: string | LocationDescriptor;
  title?: string;
};

export const ResponsesViewAll = memo(({ className, onClick, to, title }: Props) => {
  return (
    <div className={cx(styles.chevron, className)}>
      <MaybeLink
        to={to}
        className={styles.viewAll}>
        <Tooltip title={title || 'View responses'}>
          <ChevronRightRoundedIcon
            className={styles.chevronIcon}
            onClick={onClick}
            fontSize="large" />
        </Tooltip>
      </MaybeLink>
    </div>
  );
});

export default ResponsesViewAll;