import { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { path } from '@consts';
import {
  useRegisterChatPageContext,
  ChatContextType,
  type BrandInsightsPageContext,
} from '@/brand-insights';
import { useSelectGroup } from '@containers/Store/hooks';
import { WorkspaceBreadcrumbsContext } from '@/containers/WorkspaceObject/Context';
import { useFetchBrand } from '@utils/api';
import { BrandLoadingContext, BrandStateContext } from './Context';

type Props = ChildrenProps;

export const WorkspaceBrandContainer = (props: Props) => {
  const params = useBrandParams();
  const history = useHistory();
  const group = useSelectGroup();

  const query = useFetchBrand({
    brandId: params.brandId,
  }, {
    onError: error => {
      if (error.status === 404) {
        history.replace(path.Workspaces.BrandNotFound);
      }
    },
  });

  const state = {
    capabilities: query.data?.capabilities,
    brand: query.data?.brand,
    features: query.data?.features,
    object: query.data?.object,
    meta: query.data?.meta,
  };

  const brandContext: BrandInsightsPageContext.Brand = useMemo(() => {
    if (!query.data?.features?.insightsChat) return null;

    return {
      type: ChatContextType.Brand,
      data: {
        brandId: query.data?.brand?.id,
        objectId: query.data?.object?.id,
      },
      metadata: {
        id: query.data?.brand?.id,
        name: query.data?.brand?.name,
      },
    };
  }, [
    query.data?.features?.insightsChat,
    query.data?.object?.id,
    query.data?.brand?.id,
    query.data?.brand?.name,
  ]);

  const register = useRegisterChatPageContext();
  useEffect(() => {
    if (!brandContext) return;

    if (brandContext.data.objectId) {
      register(brandContext);
    }
  }, [register, brandContext]);

  return (
    <WorkspaceBreadcrumbsContext.Provider value={query.data?.breadcrumbs}>
      <BrandLoadingContext.Provider value={query.isLoading}>
        <BrandStateContext.Provider value={state}>
          {props.children}
        </BrandStateContext.Provider>
      </BrandLoadingContext.Provider>
    </WorkspaceBreadcrumbsContext.Provider>
  );
};

type RouteParams = {
  brandId: string;
};

const useBrandParams = () => {
  const params = useParams<RouteParams>();
  return {
    brandId: +params.brandId,
  };
};
