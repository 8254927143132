import { useCallback } from 'react';
import { getIntialSurvey } from '../template';
import { useSurveyTemplate } from './useSurveyTemplate';
import { useSurveyTemplateData } from './useSurveyTemplateData';
import { useSurveyBuilderOptions, useSurveyBuilderState } from './useSurveyBuilderContext';
import { useSubmitSurveyDraft } from './useSubmitSurveyDraft';

export const useInitializeTemplateSurvey = () => {

  const [state, dispatch] = useSurveyBuilderState();
  const options = useSurveyBuilderOptions();
  const [template] = useSurveyTemplate();
  const [templateData] = useSurveyTemplateData();
  const submitDraft = useSubmitSurveyDraft();

  return useCallback(() => {
    const surveyData = getIntialSurvey(template, templateData);

    dispatch({
      value: {
        ...surveyData,
        surveyDetails: options.defaultSurveyDetails,
        surveyId: state.survey.surveyId,
      },
      type: 'reset-survey',
    });

    submitDraft();
  }, [
    dispatch,
    state.survey.surveyId,
    submitDraft,
    template,
    templateData,
    options.defaultSurveyDetails,
  ]);
};