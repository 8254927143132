import { createNamedContext } from '@utils';
import {
  PayoutSetupStepperContextValue,
  PayoutSetupProjectContextValue,
} from './interfaces';

export const PayoutSetupStepperContext =
    createNamedContext<PayoutSetupStepperContextValue>(undefined, 'PayoutSetupStepperContext');

export const PayoutSetupProjectContext =
    createNamedContext<PayoutSetupProjectContextValue>(undefined, 'PayoutSetupProjectContext');