import { useCallback, useMemo, useReducer } from 'react';
import { useQueryParams, NumberParam } from 'use-query-params';

type Options = {
  index?: number;
  max?: number;
  min?: number | null;
};

export const useBasicStepper = (items: number, options: Options = { index: 0, min: 0 }): [StepperActions, number] => {

  const lastIndex = items - 1;
  const max = options.max ?? lastIndex;
  const [step, dispatch] = useReducer(createReducer(options.min, max), options.index);

  const goToStep = useCallback((target: number) => {
    dispatch({ type: 'GO_TO_STEP', target });
  }, [dispatch]);

  const nextStep = useCallback(() => {
    dispatch({ type: 'NEXT_STEP' });
  }, [dispatch]);

  const previousStep = useCallback(() => {
    dispatch({ type: 'PREVIOUS_STEP' });
  }, [dispatch]);

  const actions = {
    back: previousStep,
    goTo: goToStep,
    next: nextStep,
  };

  return [actions, step];
};

export const useStepper = <T>(screens: T[], options: Options = { index: 0, min: 0 }): [T, StepperActions, number] => {

  const lastIndex = useMemo(() => screens.length - 1, [screens]);
  const [actions, step] = useBasicStepper(screens.length, options);

  const screen = useMemo(() => {
    return step > lastIndex
      ? null
      : screens[step];
  }, [
    lastIndex,
    screens,
    step,
  ]);

  return [screen, actions, step];
};

export const useUrlStepper = <T>(screens: T[], options: Options = { index: null, min: 0 }): [T, StepperActions, number] => {
  const [param, setParam] = useQueryParams({
    'step': NumberParam,
  });

  const [screen, oldActions, step] = useStepper(screens, {
    ...options,
    index: options.index !== null
      ? options.index
      : param?.step ?? 0,
  });

  const actions: StepperActions = {
    back: () => {
      oldActions.back();
      setParam({ step: step - 1 }, 'replace');
    },
    goTo: (index: number) => {
      oldActions.goTo(index);
      setParam({ step: index }, 'replace');
    },
    next: () => {
      oldActions.next();
      setParam({ step: step + 1 }, 'replace');
    },
  };

  return [screen, actions, step];
};

export type StepperActions = {
  back: () => void;
  goTo: (target: number) => void;
  next: () => void;
};

type StepperMessage =
  | { type: 'PREVIOUS_STEP' }
  | { type: 'NEXT_STEP' }
  | { type: 'GO_TO_STEP'; target: number };

function createReducer(min: number, max: number | null) {
  return (index: number, x: StepperMessage) => {
    switch (x.type) {
      case 'PREVIOUS_STEP': {
        const prevIndex = index - 1;

        return prevIndex < min ? min : prevIndex;
      }

      case 'NEXT_STEP': {
        const nextIndex = index + 1;

        return nextIndex > max ? max : nextIndex;
      }

      case 'GO_TO_STEP': {
        if (x.target >= min && x.target <= max) {
          return x.target;
        } else {
          return index;
        }
      }

      default:
        return index;
    }
  };
}