import { memo, useCallback, useContext, useMemo } from 'react';
import { Folder as FolderIcon } from 'react-feather';
import { Link } from 'react-router-dom';
import { cx, getLocationFor } from '@/utils';
import { IWorkspaceFolderItem } from '@/types/workspace.table';
import { SetFilesTableFiltersContext } from './context';
import { formatCreatedOnDate } from './Files.Table.Grid.utils';
import styles from './style/Files.Table.Grid.css';

type Props = {
  item: IWorkspaceFolderItem;
};

export const Folder = ({ item }: Props) => {

  const setFilters = useContext(SetFilesTableFiltersContext);

  const handleClick = useCallback(() => {
    setFilters({ parentObjectId: item.object.id });
  }, [item.object.id, setFilters]);

  const createdOn = useMemo(() => {
    return formatCreatedOnDate(item.object.createdOn);
  }, [item.object.createdOn]);

  const to = getLocationFor.workspaces.folder({
    folderId: item.folder.id,
  });

  return (
    <Link
      className={cx(styles.link, styles.file)}
      onClick={handleClick}
      to={to}
      draggable={false}>
      <div className={styles.thumb}>
        <GenericFolderThumb />
      </div>
      <div className={styles.info}>
        <div className={styles.icon}>
          <FolderIcon
            className={styles.icon} />
        </div>
        <div className={styles.details}>
          <div className={styles.name}>{item.object.name}</div>
          <div className={styles.lastModified}>{createdOn}</div>
        </div>
      </div>
    </Link>
  );
};

const GenericFolderThumb = memo(() => {
  return (
    <div className={styles.genericThumb}>
      <FolderIcon className={styles.icon} />
    </div>
  );
});