export enum Section {
  Screener            = 'mt-screener',
  MessageAnalysis     = 'mt-message-analysis',
  MaxDiffExercise     = 'mt-max-diff',
  OrderingExercise    = 'mt-ordering',
  Demographics        = 'mt-demographics',
}

export enum Question {
  PrimarySpecialty                   = 'mt-primary-specialty',
  Qualification                      = 'mt-qualification',
  Certified                          = 'mt-certified',
  PracticeYears                      = 'mt-practice-years',
  ProfessionalActivities             = 'mt-professional-activities',
  PatientConditions                  = 'mt-patient-conditions',
  TherapyWillingness                 = 'mt-therapy-willingness',
  EmployerCheck                      = 'mt-employer-check',
  MarketResearchCheck                = 'mt-market-research',

  MessageDimensionOne               = 'mt-dimension-one',
  MessageDimensionTwo               = 'mt-dimension-two',

  MaxDiff                            = 'mt-max-diff',
  MaxDiffMost                        = 'mt-max-diff-most',
  MaxDiffSecondMost                  = 'mt-max-diff-2nd-most',
  MaxDiffLeast                       = 'mt-max-diff-least',
  MaxDiffSecondLeast                 = 'mt-max-diff-2nd-least',

  OrderingExercise                   = 'mt-ordering-exercise',

  Attitudinals                       = 'mt-attitudinals',
  PracticePhysicians                 = 'mt-practice-physicians',
  PracticeSetting                    = 'mt-practice-setting',
  PracticeEmployees                  = 'mt-practice-employees',
  AdministrativeStaff                = 'mt-admin-staff',
  Gender                             = 'mt-gender',
  Age                                = 'mt-age',
}

export enum Message {
  TPPIntro        = 'mt-tpp',
  DemoIntro       = 'mt-demo-intro',
}

export enum Logic {
  PrimarySpecialty                    = 'mt.logic.primary-specialty',
}