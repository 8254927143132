import { useContext } from 'react';
import { WorkspaceBrandContainer } from '@containers/Workspace.Brand/Brand.Container';
import { BrandLoadingContext } from '@/containers/Workspace.Brand/Context';
import { TabViewSkeleton } from '@/components/TabView';
import { BrandTabView } from './Brand.TabView';
import { Header } from './Header';
import { useBrandTabsMap } from './useBrandTabsMap';
import styles from './style/Workspace.Brand.css';

const ViewComponent = () => {
  const isLoading = useContext(BrandLoadingContext);
  const tabsMap = useBrandTabsMap();

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Header />
        {isLoading
            ? <TabViewSkeleton />
            : <BrandTabView map={tabsMap} />}
      </div>
    </div>
  );
};

export const WorkspaceBrand = () => (
  <>
    <WorkspaceBrandContainer>
      <ViewComponent />
    </WorkspaceBrandContainer>
  </>
);

WorkspaceBrand.displayName = 'Workspace.Brand';