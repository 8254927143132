import { cx } from '@utils';
import styles from './style/Layout.css';

type Props = {
  className?: string;
  children?: React.ReactNode;
};

export const Root = (props: Props) => {
  return (
    <div className={cx(styles.root, props.className)}>
      <div className={styles.wrap}>
        {props.children}
      </div>
    </div>
  );
};

Root.displayName = 'Layout.Tile.Root';

export const Header = (props: Props) => {
  return (
    <div className={cx(styles.header, props.className)}>
      <div className={styles.title}>
        {props.children}
      </div>
    </div>
  );
};

Header.displayName = 'Layout.Tile.Header';

export const Body = (props: Props) => {
  return (
    <div className={styles.main}>
      <div className={styles.content}>
        {props.children}
      </div>
    </div>
  );
};

Body.displayName = 'Layout.Tile.Body';