import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import { ButtonActivityIndicator } from '@presentation';
import * as api from '$admin/api';
import { Project } from '$admin/interfaces';
import { Button } from '@/components/Button';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { Header } from '@/components/Modal/Header';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import styles from './style/Project.EngageMembers.css';

type ActivateItem = {
  user: Pick<Project.Members.Item['user'], 'id'>;
};

type Props = {
  className?: string;
  onConfirm?: (project: Descriptor, items: ActivateItem[]) => unknown;
  items: ActivateItem[];
} & IProjectId
  & Pick<ModalProps, 'open' | 'onClose'>;

export const ActivateMembers = ({ projectId, items, open, onClose, onConfirm }: Props) => {

  const { mutateAsync, isLoading } = useMutation(['admin.activate-members'], () => {
    return api.projects.members.activateMembers({
      projectId,
      userIds: items.map(m => m.user.id),
    });
  }, {
    onSuccess: () => {
      onConfirm(null, items);
    },
  });

  const handleConfirm = useCallback(() => {
    mutateAsync();
  }, [mutateAsync]);

  return (
    <Modal
      onClose={onClose}
      open={open}>
      <Header>{`Mark ${items.length} expert${items.length > 1 ? 's' : ''} active?`}</Header>
      <ButtonSet className={styles.buttonset}>
        <Button
          className={styles.cancel}
          color="destructive"
          onClick={onClose}
          variant="brick">
          Cancel
        </Button>
        <ButtonActivityIndicator
          className={styles.btn}
          color="primary"
          loading={isLoading}
          onClick={handleConfirm}
          variant="brick">
          Activate
        </ButtonActivityIndicator>
      </ButtonSet>
    </Modal>
  );
};

ActivateMembers.displayName = 'Modal.Project.ActivateMembers';