import { useCallback } from 'react';
import { NumberInputTableQuestion, MatrixSliderQuestion, SurveyQuestionNumberSettings, SurveyQuestionNumberType } from '@/types';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks/useSurveyBuilderContext';
import { CurrencyLegacy } from '@/types/survey.currency';
import { DropDown } from '@/components/DropDown/DropDown';
import { CurrenciesDropdown } from '@/components/Currency';
import { Input } from '@/components/Input';
import styles from './style/NumberSettingsBuilder.css';

type NumberQuestions = NumberInputTableQuestion.Question | MatrixSliderQuestion.Question;

type Props = {
  item: NumberQuestions;
};

type NumberSettingsUpdater = (value: SurveyQuestionNumberSettings) => SurveyQuestionNumberSettings;

export const NumberSettingsBuilder = ({ item }: Props) => {
  const [_, dispatch] = useSurveyBuilderState();

  const handleNumberSettingsChange = useCallback((updater: NumberSettingsUpdater) => {

    const initialNumberSettings = item.settings?.numberSettings ?? { type: SurveyQuestionNumberType.Number };
    const newNumberSettings = updater(initialNumberSettings);

    dispatch({
      questionIdentifier: item.base.identifier,
      settings: {
        ...item.settings,
        numberSettings: newNumberSettings,
      },
      type: 'update-question-settings',
    });
  }, [
    dispatch,
    item.base.identifier,
    item.settings,
  ]);

  const handleFormatChange = useCallback((type: SurveyQuestionNumberType) => {
    handleNumberSettingsChange(numberSettings => ({
      ...numberSettings,
      type,
    }));
  }, [handleNumberSettingsChange]);

  const handleCurrencyChange = useCallback((currency: CurrencyLegacy) => {
    handleNumberSettingsChange(numberSettings => ({
      ...numberSettings,
      currency,
    }));
  }, [handleNumberSettingsChange]);

  const handleUnitType = useCallback((unitType: string) => {
    handleNumberSettingsChange(numberSettings => ({
      ...numberSettings,
      unitType,
    }));
  }, [handleNumberSettingsChange]);

  return (
    <>
      <div className={styles.item}>
        <div className={styles.title}>Number Format</div>
        <div className={styles.input}>
          <DropDown
            items={FormatOptions}
            getItemValue={i => i.value}
            onSelect={val => handleFormatChange(val.id)}
            text={(FormatOptions.find(o => o.id === item.settings.numberSettings?.type) ?? FormatOptions[0]).value} />
        </div>
      </div>
      {item.settings.numberSettings?.type === SurveyQuestionNumberType.Unit &&
        <div className={styles.item}>
          <div className={styles.title}>Unit Suffix</div>
          <div className={styles.input}>
            <Input
              value={item.settings.numberSettings?.unitType || ''}
              onChange={e => handleUnitType(e.target.value)} />
          </div>
        </div>
      }
      {
        item.settings.numberSettings?.type === SurveyQuestionNumberType.Currency &&
        <div className={styles.item}>
          <div className={styles.title}>Currency Type</div>
          <div className={styles.input}>
            <CurrenciesDropdown
              onChange={handleCurrencyChange}
              value={item.settings.numberSettings?.currency} />
          </div>
        </div>
      }
    </>
  );
};

const FormatOptions = [SurveyQuestionNumberType.Number, SurveyQuestionNumberType.Currency, SurveyQuestionNumberType.Unit].map(t => ({
  id: t,
  value: SurveyQuestionNumberType[t],
}));

export default NumberSettingsBuilder;