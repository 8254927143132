import { extend } from './extend';

export enum CallType {
  Project = 1,
  AdHoc = 2,
}

const type = new Map([
  [CallType.Project, { name: 'Project' }],
  [CallType.AdHoc, { name: 'Ad Hoc' }],
]);

export default {
  CallType: extend(type),
};