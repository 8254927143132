import { useCallback } from 'react';
import { useSurveyBuilderState, useRichTextEditorRegister } from '@containers/SurveyBuilder/hooks';
import { useDebounceCallback } from '@utils';
import type { SurveyRichText } from '@/types/survey.rich-text';
import type { SurveyRichTextEditorOnChange } from '@/components/Survey.RichText';
import {
  SurveyRichTextEditorContainer,
  useSurveyRichTextEditor,
} from '@/components/Survey.RichText';
import { LinkContainer } from '@/components/Remirror';
import { useQuestionBuilderItemContext } from './Context';

type Props =
  ChildrenProps;

export const QuestionBuilderEditorContainer = (props: Props) => {
  const [_, dispatch] = useSurveyBuilderState();
  const item = useQuestionBuilderItemContext();

  const { manager, state, setState } = useSurveyRichTextEditor({
    initialState: item.value,
    placeholder: 'Enter your question',
  });

  const debouncedDispatch = useDebounceCallback(dispatch, 500);

  const handleChange: SurveyRichTextEditorOnChange = useCallback(params => {
    if (!params.tr?.docChanged) return;

    setState(params.state);

    const value = params.state.doc.toJSON() as SurveyRichText.RichTextValue;

    debouncedDispatch({
      type: 'update-question-value',
      identifier: item.base.identifier,
      value,
    });
  }, [
    item.base.identifier,
    debouncedDispatch,
    setState,
  ]);

  const editorRef = useRichTextEditorRegister({
    identifier: item.base.identifier,
  });

  return (
    <SurveyRichTextEditorContainer
      manager={manager}
      onChange={handleChange}
      state={state}
      editorRef={editorRef}>
      <LinkContainer>
        {props.children}
      </LinkContainer>
    </SurveyRichTextEditorContainer>
  );
};