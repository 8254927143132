import { memo } from 'react';
import styles from './style/Header.css';

type Props =
  ChildrenProps;

export const WorkspaceHeader = memo((props: Props) => {

  return (
    <div className={styles.root}>
      {props.children}
    </div>
  );
});

export default WorkspaceHeader;