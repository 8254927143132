import { useCallback, useContext } from 'react';
import { FileUploadsStateContext } from '../Context';
import { useFileUploadEffects } from './useFileUploadEffects';

export const useAbortUpload = () => {
  const state = useContext(FileUploadsStateContext);

  const effects = useFileUploadEffects();

  const abortUpload = useCallback((fileIdentifier: string) => {
    const upload = state.uploads[fileIdentifier];
    const fileUpload = state.valid.find(f => f.identifier === fileIdentifier);

    if (upload) {
      upload.abort();
    }

    return effects.abort({
      fileUploadId: fileUpload.id,
      workspaceId: fileUpload.data.workspaceId,
    });
  }, [
    effects,
    state.uploads,
    state.valid,
  ]);

  return abortUpload;
};

export default useAbortUpload;