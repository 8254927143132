import { useEffect, useState, useMemo } from 'react';
import { parse } from 'query-string';
import ActivityIndicator from '@/components/ActivityIndicator';
import { ConnectedAccountProvider, ConnectedAccountType } from '@/enums';
import { useConnectUserAccount } from '@/utils/api/user.connected-accounts.connect';
import type { ConnectedAccount } from '@/types';

enum Status {
  Loading,
  Error,
  NoOnConnect,
}

type Props = {
  provider: ConnectedAccountProvider;
  type: ConnectedAccountType;
}

export const AccountConnectCallback = (props: Props) => {
  const parsed = useMemo(() => parse(window.location.search), []);
  const [status, setStatus] = useState(Status.Loading);
  const onConnectMethod = props.provider == ConnectedAccountProvider.Calendly ? 'onCalendlyConnect' : 'onZoomConnect';

  const { mutateAsync } = useConnectUserAccount(props, {
    onSuccess: acc => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const onConnect: (conn: ConnectedAccount) => void = window?.opener?.[onConnectMethod] as (conn: ConnectedAccount) => void;
      if (onConnect) {
        onConnect(acc.connection);
        window.close();
      } else {
        setStatus(Status.NoOnConnect);
      }
    },
    onError: e => setStatus(Status.Error),
  });

  useEffect(() => {
    mutateAsync({ code: parsed['code'] as string });
  }, [mutateAsync, parsed]);

  if (status == Status.Error) {
    return (
      <div>
        There was an error connecting your account.
      </div>
    );
  } else if (status == Status.NoOnConnect) {
    return (
      <div>
        Your connect has been connected.
      </div>
    );
  }

  return (
    <div>
      <ActivityIndicator />
    </div>);
};