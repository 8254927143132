import { GroupType } from '@enums';
import { useAsyncState } from '@utils';
import * as api from '$admin/api';

export default function useFetchAccounts() {
  const [response, fetch] = useAsyncState(() => {
    return api.groups.getAccounts();
  }, []);

  return [response, fetch] as const;
}

export { useFetchAccounts };