import { useCallback } from 'react';
import { useInvalidateFilesQueries } from '@utils/api';
import { useDeleteFolder } from './hooks';
import { DeleteFolderContext } from './Context';

type Props =
  IWorkspaceFolderId &
  IWorkspaceObjectId &
  IWorkspaceId &
  ChildrenProps;

export const DeleteFolderContainer = (props: Props) => {

  const deleteFolder = useDeleteFolder();
  const invalidateQueries = useInvalidateFilesQueries();

  const handleDelete = useCallback(() => {
    return deleteFolder({
      folderId: props.folderId,
      objectId: props.objectId,
      workspaceId: props.workspaceId,
    }).then(() => {
      invalidateQueries();
    });
  }, [
    deleteFolder,
    invalidateQueries,
    props.folderId,
    props.objectId,
    props.workspaceId,
  ]);

  return (
    <DeleteFolderContext.Provider value={handleDelete}>
      {props.children}
    </DeleteFolderContext.Provider>
  );
};

export default DeleteFolderContainer;