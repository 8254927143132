import styles from './style/Layout.css';

type Props =
  ChildrenProps;

const ScreenLayout = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export { ScreenLayout };
export default ScreenLayout;