import { useCallback, useState } from 'react';
import type { EditorView } from '@remirror/pm/view';
import { NodeType } from '@/types/transcribe.rich-text';
import { UpdateTranscriptTimestampMapsContext, TranscriptTimestampEndPositionsContext, TranscriptTimestampPositionsContext } from './context';
import type { TimestampMap } from './interfaces';

type Props = ChildrenProps;

export const TranscriptTimestampPositionsContainer = (props: Props) => {
  const [timestamps, setTimestamps] = useState<TimestampMap>(new Map());
  const [endTimestamps, setEndTimestamps] = useState<TimestampMap>(new Map());

  const updateTimestamps = useCallback((view: EditorView) => {
    const { timestamps, endTimestamps } = calculateTimestampPositions(view);

    setTimestamps(timestamps);
    setEndTimestamps(endTimestamps);
  }, []);

  return (
    <TranscriptTimestampPositionsContext.Provider value={timestamps}>
      <TranscriptTimestampEndPositionsContext.Provider value={endTimestamps}>
        <UpdateTranscriptTimestampMapsContext.Provider value={updateTimestamps}>
          {props.children}
        </UpdateTranscriptTimestampMapsContext.Provider>
      </TranscriptTimestampEndPositionsContext.Provider>
    </TranscriptTimestampPositionsContext.Provider>
  );
};

function calculateTimestampPositions(view: EditorView) {
  const timestamps: TimestampMap = new Map();
  const endTimestamps: TimestampMap = new Map();

  view.state.doc.descendants((node, pos) => {

    if (node.type.name === NodeType.Text) {
      const tsMark = node.marks.find(f => f.type.name === 'ts');
      if (tsMark) {
        timestamps.set(tsMark.attrs.s as number, {
          from: pos,
          to: pos + node.text.length,
        });
        endTimestamps.set(tsMark.attrs.e as number, {
          from: pos,
          to: pos + node.text.length,
        });
      }
    }
  });

  return { timestamps, endTimestamps };
}
