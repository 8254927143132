export function formatTimestamp(ts: number, includeHours = true) {
  const d = Math.max(0, ts);
  const hours = Math.floor(Math.round(d) / 3600);
  const minutes = Math.floor((Math.round(d) / 60) - (hours * 60));
  const seconds = Math.round(d) % 60;

  return [
    includeHours ? hours : null,
    minutes.toString().padStart(2, '0'),
    seconds.toString().padStart(2, '0'),
  ].filter(Boolean)
   .join(':');
}