import { useContext } from 'react';
import { CallDetailsContext } from './Context';
import * as Layout from './Layout';
import styles from './style/Field.css';

type Props = unknown;

export const Link = (props: Props) => {
  const ctx = useContext(CallDetailsContext);

  const { url } = ctx.query.data.conference;

  if (!url) return null;

  return (
    <div className={styles.row}>
      <Layout.Label>Conference Link</Layout.Label>
      <a
        className={styles.link}
        href={url}
        rel="noreferrer noopener"
        target="_blank">
        {ctx.query.data.conference.url}
      </a>
    </div>
  );
};

Link.displayName = 'Call.Main.Field.Link';