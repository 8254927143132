import { cx } from '@utils';
import { CheckboxLabel } from '@/components/Checkbox';
import styles from './style/DontKnow.css';

type Props = {
  className?: string;
  label?: string;
  message?: string;
  onChange?: () => void;
  value: boolean;
};

export const DontKnow = ({
  className,
  label = 'I do not know',
  message,
  onChange,
  value,
}: Props) => {

  return (
    <div className={cx(styles.root, className)}>
      {message && <div className={styles.message}>{message}</div>}
      <CheckboxLabel
        onChange={onChange}
        label={label}
        checked={value} />
    </div>
  );

};