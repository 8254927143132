import { useCallback, useState } from 'react';
import { SelectedContext } from './Context';
import { SelectedState } from './interfaces';

type Props = {
  children: React.ReactNode;
};

export const SelectedStateContainer = (props: Props) => {
  const [selected, setSelected] = useState<SelectedState>({
    ids: new Set(),
    items: [],
  });

  const clear = useCallback(() => {
    setSelected({
      ids: new Set(),
      items: [],
    });
  }, []);

  return (
    <SelectedContext.Provider value={[selected, setSelected, clear]}>
      {props.children}
    </SelectedContext.Provider>
  );
};

SelectedStateContainer.displayName = 'Group.Contacts.SelectedState.Container';