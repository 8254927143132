import type { DecodedValueMap, QueryParamConfig, SetQuery } from 'use-query-params';
import type { UseQueryResult } from '@tanstack/react-query';
import type * as API from '@api/interfaces';
import { createNamedContext } from '@utils';

type QPConfig = {
  index: QueryParamConfig<number, number>;
  size: QueryParamConfig<number, number>;
  sortBy: QueryParamConfig<'boosted' | 'name' | 'total' | 'type'>;
  sortDir: QueryParamConfig<'asc' | 'desc'>;
  nameFilter: QueryParamConfig<string>;
};

type ProjectEntitiesQueryParamsContextValue = [
  DecodedValueMap<QPConfig>,
  SetQuery<QPConfig>,
];

type ProjectEntitiesQueryContextValue = UseQueryResult<API.Entities.FetchItems.Response>;

export const ProjectEntitiesQueryContext = createNamedContext<ProjectEntitiesQueryContextValue>(undefined, 'Project.Entities.Query.Context');
export const ProjectEntitiesQueryParamsContext = createNamedContext<ProjectEntitiesQueryParamsContextValue>(undefined, 'Project.Entities.QueryParams.Context');