/** @jsxImportSource @emotion/react */
import { useCallback, useContext, useMemo } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import  { ChatActionType, ChatFileUploadStatus, ChatHintType } from '@/brand-insights/enums';
import type { BrandInsightsContext, Chat } from '@/brand-insights/types';
import { MessageQueryHint } from '@/brand-insights/components/presentation';
import { FileUpload } from './FileUpload';
import { UserMessage } from './Message.User';
import { ActiveChatSessionContext, SubmitActionContext } from './context';
import { BaseSystemUserMessage } from './Message.System';

type Props = {
  className?: string;
  item: Chat.InstanceFileUploadsItem;
};

export const FileUploadItem = ({ className, item }: Props) => {

  const uploadsComplete = useMemo(() => {
    return item.items.every(upload => upload.status === ChatFileUploadStatus.Ready);
  }, [item.items]);

  return (
    <Root className={className}>
      <UserMessage
        classes={{
          body: css({
            minWidth: 400,
          }),
        }}
        value={<FileUpload item={item} />} />
      {uploadsComplete && (
        <StyledSystemMessage>
          <UploadComplete />
        </StyledSystemMessage>
      )}
    </Root>
  );
};

const UploadComplete = () => {
  const ctx = useContext(SubmitActionContext);
  const chat = useContext(ActiveChatSessionContext);

  const hint: Chat.QueryHint.Item = useMemo(() => ({
    displayValue: 'Summarize the uploaded documents',
    type: ChatHintType.Text,
  }), []);

  const submitAction = useCallback(() => {
    return ctx({
      folderId: (chat.context.data as BrandInsightsContext.Folder.Data).folderId,
      type: ChatActionType.SummarizeFolder,
    }, hint.displayValue);
  }, [
    hint,
    chat.context.data,
    ctx,
  ]);

  const message = 'OK great, your documents have been successfully processed. You can now ask a question or choose one of the following:';

  return (
    <>
      <div>{message}</div>
      <StyledHint
        hint={hint}
        onClick={submitAction} />
    </>
  );
};

const Root = styled('div')({
  padding: '15px 20px',
});

const StyledHint = styled(MessageQueryHint)(() => ({
  marginTop: 15,
}));

const StyledSystemMessage = styled(BaseSystemUserMessage)({
  marginTop: 30,
});