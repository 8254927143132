import extend from '@enums/extend';

export enum PayerAdminType {
  None                          = 0,
  ClinicalPathwayRepresentative = 1,
  EmployeeBenefitConsultant     = 2,
  GPORepresentative             = 3,
  HealthPlanClinicalPharmacist  = 4,
  HealthPlanFinance             = 5,
  HealthPlanMedicalDirector     = 6,
  HealthPlanPharmacyDirector    = 7,
  HospitalClinicalPharmacist    = 8,
  HospitalFinance               = 9,
  HospitalMedicalDirector       = 10,
  HospitalPharmacyDirector      = 11,
  IDNPayerMedicalDirector       = 12,
  IDNPayerPharmacyDirector      = 13,
  IDNProviderMedicalDirector    = 14,
  MedicaidState                 = 15,
  MedicareMACCAC                = 16,
  PBMRepresentative             = 17,
  PatientAdvocacy               = 18,
  PracticeManager               = 19,
  SPPRepresentative             = 20,
  Other                         = 21,
  Regional                      = 22,
  National                      = 23,
}

const map = new Map([
  [PayerAdminType.None, { name: `None` }],
  [PayerAdminType.ClinicalPathwayRepresentative, { name: `Clinical Pathway Representative` }],
  [PayerAdminType.EmployeeBenefitConsultant, { name: `Employee Benefit Consultant` }],
  [PayerAdminType.GPORepresentative, { name: `GPO Representative` }],
  [PayerAdminType.HealthPlanClinicalPharmacist, { name: `Health Plan Clinical Pharmacist` }],
  [PayerAdminType.HealthPlanFinance, { name: `Health Plan Finance` }],
  [PayerAdminType.HealthPlanMedicalDirector, { name: `Health Plan Medical Director` }],
  [PayerAdminType.HealthPlanPharmacyDirector, { name: `Health Plan Pharmacy Director` }],
  [PayerAdminType.HospitalClinicalPharmacist, { name: `Hospital Clinical Pharmacist` }],
  [PayerAdminType.HospitalFinance, { name: `Hospital Finance` }],
  [PayerAdminType.HospitalMedicalDirector, { name: `Hospital Medical Director / CMO` }],
  [PayerAdminType.HospitalPharmacyDirector, { name: `Hospital Pharmacy Director` }],
  [PayerAdminType.IDNPayerMedicalDirector, { name: `IDN Payer - Medical Director` }],
  [PayerAdminType.IDNPayerPharmacyDirector, { name: `IDN Payer - Pharmacy Director` }],
  [PayerAdminType.IDNProviderMedicalDirector, { name: `IDN Provider - Medical Director` }],
  [PayerAdminType.MedicaidState, { name: `Medicaid (State)` }],
  [PayerAdminType.MedicareMACCAC, { name: `Medicare MAC / CAC` }],
  [PayerAdminType.PBMRepresentative, { name: `PBM Representative` }],
  [PayerAdminType.PatientAdvocacy, { name: `Patient Advocacy` }],
  [PayerAdminType.PracticeManager, { name: `Practice Manager` }],
  [PayerAdminType.SPPRepresentative, { name: `SPP Representative` }],
  [PayerAdminType.Other, { name: `Other` }],
  [PayerAdminType.Regional, { name: `Regional` }],
  [PayerAdminType.National, { name: `National` }],
]);

export default {
  PayerAdminType: extend(map),
};