import { useSurveyClassification } from '@/containers/SurveyBuilder';
import { useSurveyTaggingItemState } from '@/containers/SurveyBuilder.Tagging';
import { useClassificationModal } from './Builder.Action.ClassificationModal';
import styles from './style/Builder.Classification.css';

export const ClassificationTagBuilder = () => {

  const [item] = useSurveyTaggingItemState();

  const classification = useSurveyClassification(item.action.value.identifier);

  const [toggleModal, ClassificationModal] = useClassificationModal();

  return (
    <>
      {classification &&
        <div className={styles.row}>
          {classification.name}
          <div
            className={styles.edit}
            onClick={toggleModal}>
            Edit
          </div>
        </div>
      }
      {!classification &&
        <div className={styles.row}>
          <div
            className={styles.link}
            onClick={toggleModal}>
            Select a classification
          </div>
        </div>
      }
      <ClassificationModal />
    </>
  );
};