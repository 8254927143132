import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as consts from '@consts';
import { Proposal, ProposalResponsive } from '@screens/Scheduling';
import { device, qs } from '@utils';
import { useProposeProjectCallTimes } from '@utils/api';
import { useNotifier } from './Notifier';

type RouteParams = {
  projectId: string;
  callId:    string;
};

type RouteState = {
  schedulerId: number;
  scheduleeId: number;
};

const ProposalCalendar = () => {
  const notifier = useNotifier();
  const params = useParams<RouteParams>();
  const { state } = useLocation<RouteState>();
  const history = useHistory();
  const callId = +params.callId;

  const { mutateAsync: proposeTimes } = useProposeProjectCallTimes({
    callId,
  }, {
    onSuccess: res => {
      const from = qs.getParam('from');

      notifier.callReschedule(res.call);

      history.replace(from || consts.pathname.HOME);
    },
    onError: notifier.error,
  });

  const Screen = device.phone
      ? ProposalResponsive
      : Proposal;

  return (
    <Screen
      callId={callId}
      onSubmit={proposeTimes}
      scheduleeId={state.scheduleeId} />
  );
};

export { ProposalCalendar };
export default ProposalCalendar;