import * as types from '@store/action-types';
import { projects as initialState } from '@store/initial-state';
import { distinct } from '@utils/array';
import {
  AppDataFetchedAction,
  AppStateRehydratedAction,
  ProjectsActions,
  Calls,
  ProjectPipelineUpdatedAction,
} from '../interfaces';

type Actions =
    AppDataFetchedAction
  | AppStateRehydratedAction
  | ProjectsActions
  | Calls.ProjectCallCreated.Action
  | ProjectPipelineUpdatedAction
;

function projectReducer(state: Store.Projects = initialState, action: Actions) {

  switch (action.type) {
    case types.APP_DATA_FETCHED:
      return action.projects;

    case types.PROJECT_PIPELINE_UPDATED: {
      const pipeline = action.pipeline.project[action.projectId];

      if (!pipeline) return state;

      return {
        ...state,
        [action.projectId]: {
          ...state[action.projectId],
          userIds: Object.values(pipeline.users).map(({ userId }) => userId),
        },
      };
    }

    case types.PROJECT_CREATED: {
      return {
        ...state,
        [action.projectId]: action.project,
        ids: [action.projectId].concat(state.ids),
        parents: {
          ...state.parents,
          [action.parent.id]: action.parent,
          ids: [action.parent.id].concat(state.parents.ids),
        },
      };
    }

    case types.PROJECT_REMOVED: {
      const { [action.projectId]: _, ids, ...other } = state;

      return {
        ...other,
        ids: ids.filter(id => id !== action.projectId),
      };
    }

    case types.PROJECT_OVERVIEW_UPDATED:
    case types.PROJECT_UPDATED: {
      const { [action.projectId]: project, ids, ...other } = state;

      return {
        ...other,
        ids: distinct([...ids, action.projectId]),
        [action.projectId]: { ...project, ...action.project },
      };
    }

    case types.PROJECT_PARENT_UPDATED: {
      const { [action.parent.id]: parent, ...other } = state.parents;

      return {
        ...state,
        parents: {
          ...other,
          [action.parent.id]: { ...parent, ...action.parent },
        },
      };
    }

    case types.PROJECT_CLONED: {
      return {
        ...state,
        [action.project.id]: action.project,
        [action.previous.project.id]: {
          ...state[action.previous.project.id],
          ...action.previous.project,
        },
        ids: state.ids.concat(action.project.id),
        parents: {
          ...state.parents,
          [action.parent.id]: {
            ...state.parents[action.parent.id],
            ...action.parent,
          },
        },
      };
    }

    case types.APP_STATE_REHYDRATED:
      return { ...state, ...action.projects };

    case types.PROJECT_CALL_CREATED: {
      const project = state[action.call.projectId];

      return {
        ...state,
        [action.call.projectId]: {
          ...project,
          callIds: project.callIds.concat(action.call.id),
        },
      };
    }

    default:
      return state;
  }
}

export default projectReducer;