import { useCopy } from '@utils';
import { Button } from '@/components/Button';
import styles from './style/Link.css';

type Props = {
  url: string;
};

export const Clipboard = (props: Props) => {
  const clipboard = useCopy();

  return (
    <div className={styles.copy}>
      <div className={styles.link}>
        <input
          ref={clipboard.target}
          className={styles.input}
          type="text"
          readOnly
          value={props.url ?? ''}
          placeholder="Loading..." />
      </div>
      <Button
        className={styles.btn}
        color="transparent"
        disabled={!props.url}
        onClick={clipboard.copy}
        variant="link">
        {clipboard.copied
          ? `Copied!`
          : `Copy Link`}
      </Button>
    </div>
  );
};

Clipboard.displayName = 'ProjectInviteReferral.Link.Clipboard';