import { memo } from 'react';
import styled from '@emotion/styled';

type Props = {
  className?: string;
} & ChildrenProps;

export const MessageQueryHintContainer = memo(({ className, children }: Props) => {
  return (
    <Root className={className}>
      {children}
    </Root>
  );
});

const Root = styled.div`
  box-sizing: border-box;

  & :not(:last-child) {
    margin-bottom: 8px;
  }
`;
