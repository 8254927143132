import type { BrandInsightsContext, BrandInsightsInstanceContext } from '@/brand-insights/types';
import { ChatContextType } from '@/brand-insights/enums';
import type { ChatSessionState, ChatState } from './interfaces';

type State = ChatSessionState.State['chatInstance'];

export function chatInstanceReducer(state: State, action: ChatState.Action): State {

  switch (action.type) {
    case 'query-submitted': {
      return {
        ...state,
        currentQueryIdentifier: action.payload.identifier,
      };
    }

    case 'query-response-available': {
      return {
        ...state,
        id: action.payload.chatInstance.id,
        currentQueryIdentifier: action.payload.query.identifier,
        name: action.payload.chatInstance.name,
      };
    }
    case 'folder-name-updated': {
      return {
        ...state,
        context: {
          ...state.context,
          type: ChatContextType.Folder,
          metadata: {
            ...state.context.metadata,
            name: action.payload.value,
          } as BrandInsightsContext.Folder.Metadata,
        } as BrandInsightsInstanceContext.FolderWithMetadata,
      };
    }

    default:
      return state;
  }
}