import { memo, useContext } from 'react';
import Skeleton from '@mui/material/Skeleton';
import styles from '@screens/Workspace.File/style/Header.Actions.css';
import { BrandLoadingContext, BrandStateContext } from '@/containers/Workspace.Brand/Context';
import { useIsInternalUser } from '@/containers/Store/hooks';
import { DiscoverInsights } from '@/components/GroupProject/DiscoverInsights';
import { Access } from './Header.Access';

type Props = unknown;

export const Actions = (props: Props) => {
  const isLoading = useContext(BrandLoadingContext);

  return isLoading
    ? <SkeletonActions />
    : <InteractiveActions />;
};

Actions.displayName = 'Brand.Header.Actions';

const InteractiveActions = (props: Props) => {
  const state = useContext(BrandStateContext);
  const isInternalUser = useIsInternalUser();

  return (
    <div className={styles.root}>
      {(state.features.insightsChat && !isInternalUser) &&
        <DiscoverInsights className={styles.discover} />}
      <Access />
    </div>
  );
};

InteractiveActions.displayName = 'Brand.Header.Actions.Interactive';

const SkeletonActions = memo((props: Props) => {
  return (
    <div className={styles.actions}>
      <Skeleton
        height={30}
        variant="rounded"
        width={70} />
      <Skeleton
        height={30}
        variant="rounded"
        width={70} />
      <Skeleton
        height={30}
        variant="rounded"
        width={70} />
    </div>
  );
});

SkeletonActions.displayName = 'Brand.Header.Actions.Skeleton';