import styled from '@emotion/styled';

type Props = {
  className?: string;
};

export function SummaryNotAvailable({ className }: Props) {
  return (
    <Root className={className}>
      <Text>There is no summary available for this file type.</Text>
    </Root>
  );
}

const Root = styled.div``;
const Text = styled.div`
  color: var(--gray);
  line-height: 30px;
`;