import type { Ref } from 'react';
import { forwardRef, useCallback, useImperativeHandle } from 'react';
import { useRemirrorContext } from '@remirror/react';
import type { EditorImperativeRef, SurveyRichTextEditorExtension } from '@/components/Survey.RichText/interfaces';

export const EditorImperativeHandle = forwardRef((_, ref: Ref<EditorImperativeRef>) => {
  const ctx = useRemirrorContext<SurveyRichTextEditorExtension>({
    autoUpdate: true,
  });

  const getContext = useCallback(() => ctx, [ctx]);

  useImperativeHandle(ref, () => ({ getContext }));

  return <></>;
});