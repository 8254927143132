import http from '@services/http';
import type * as Projects from '@api/interfaces/projects.external-survey';
import { qs } from '@utils';

export const fetchUnassignedLinks = (data: Projects.FetchUnassignedLinks.Request) => {
  return http.get<Projects.FetchUnassignedLinks.Response>(`/admin/projects/${data.projectId}/unassigned`, {
    params: {
      keyword: data.keyword,
    },
  });
};

export const fetchUserFixedLinksHistory = (data: Projects.FetchUserFixedLinksHistory.Request) => {
  return http.get<Projects.FetchUserFixedLinksHistory.Response>(`/admin/projects/${data.projectId}/assignments/${data.userId}`);
};

export const importExternalProjectLinks = async (projectId: number, linksFile: File, fixed?: boolean): Promise<{ numInserted: number }> => {
  const formData = new FormData();
  formData.append('linksFile', linksFile, linksFile.name);

  const q = fixed ? `?${qs.stringify({ fixed })}` : '';

  return http.patch(`/admin/projects/${projectId}/import-external-links${q}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const importSingleExternalProjectLink = async ({ projectId, ...data }: Projects.ImportSingleExternalProjectLink.Request) => {
  return http.put<Projects.ImportSingleExternalProjectLink.Response>(`/admin/projects/${projectId}/import-single-external-link`, data);
};

export const deleteUnassignedExternalProjectLinks = async ({ projectId }: Projects.DeleteUnassignedExternalProjectLinks.Request) => {
  return http.delete(`/admin/projects/${projectId}/unassigned-external-links`);
};

export const bootstrapExternalProject = async ({ projectId }: Projects.BootstrapExternalProject.Request) => {
  return http.post<Projects.BootstrapExternalProject.Response>(`/admin/projects/${projectId}/bootstrap-external-project`, {});
};

export const deleteExternalProjectConfiguration = async ({ projectId }: Projects.DeleteExternalSurveyConfiguration.Request) => {
  return http.delete(`/admin/projects/${projectId}/external-configuration`);
};

export const updateExternalProjectConfiguration = async (data: Projects.UpdateExternalSurveyConfiguration.Request) => {
  return http.patch<Projects.UpdateExternalSurveyConfiguration.Response>(`/admin/projects/${data.projectId}/external-configuration`, data);
};

export const saveUserAssignedLink = (data: Projects.SaveUserAssignedLink.Request) => {
  return http.put<Projects.SaveUserAssignedLink.Response>(`/admin/projects/${data.projectId}/assignments`, data);
};