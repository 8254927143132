import { useCallback, useMemo } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getLocationFor } from '@utils';
import Toast from '@/components/Toast';
import styles from './style/Conference.Participants.Table.css';

type Props = {
  identifier: number;
  pin:        string;
};

export const CopyLinkToClipboard = (props: Props) => {
  const url = useMemo(() => {
    const loc = getLocationFor.call.conference(props.identifier);

    return loc?.pathname
      ? `${process.env.FRONT_BASE_URL}${loc.pathname}?pin=${props.pin}`
      : null;
  }, [
    props.identifier,
    props.pin,
  ]);

  const copy = useCallback(() => {
    navigator.clipboard.writeText(url).then(() => {
      Toast.info({
        title: `Copied!`,
      });
    });
  }, [url]);

  if (!url) return null;

  return (
    <button
      className={styles.copy}
      onClick={copy}>
      <ContentCopyIcon className={styles.icon} />
    </button>
  );
};

CopyLinkToClipboard.displayName = 'Conference.Participants.CopyLinkToClipboard';