import { useContext } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { ProjectAction } from '@enums';
import { useProjectActions, useProjectActionsValidity, useProjectState } from '@containers/GroupProject/hooks';
import { ProjectStateLoadingContext } from '@/containers/GroupProject/Context';
import { DiscoverInsights } from '@/components/GroupProject/DiscoverInsights';
import { ProjectActionsContextMenuPopper } from '@/components/GroupProject/ProjectActionsContextMenu';
import { Share } from '@/components/ShareLink';
import { Access } from './Header.Access';
import styles from './style/ProjectActions.css';

type Props = unknown;

export const Actions = (props: Props) => {
  const isLoading = useContext(ProjectStateLoadingContext);

  return isLoading
    ? <SkeletonActions />
    : <InteractiveActions />;
};

Actions.displayName = 'Project.Actions';

const InteractiveActions = (props: Props) => {
  const { share } = useProjectActions();
  const validity = useProjectActionsValidity();
  const state = useProjectState();

  return (
    <div className={styles.root}>
      {state.features.insightsChat &&
        <DiscoverInsights className={styles.discover} />}
      {validity[ProjectAction.Share] &&
        <div className={styles.share}>
          <Share
            disabled
            onClick={share} />
        </div>}
      <Access className={styles.access} />
      <ProjectActionsContextMenuPopper className={styles.contextMenu} excluded={[ProjectAction.Share]} />
    </div>
  );
};

InteractiveActions.displayName = 'Project.Actions.Interactive';

const SkeletonActions = (props: Props) => {
  return (
    <div className={styles.actions}>
      <Skeleton
        height={30}
        variant="rounded"
        width={70} />
      <Skeleton
        height={30}
        variant="rounded"
        width={70} />
      <Skeleton
        height={30}
        variant="rounded"
        width={70} />
    </div>
  );
};

SkeletonActions.displayName = 'Project.Actions.Skeleton';