import { useMemo } from 'react';
import { NumberInputTableQuestion } from '@/types/survey';
import { OptionContextMenu } from './Option.ContextMenu';
import { useReadOnlyOptionMenuItem } from './hooks/option-menu';
import { useHasLinkedRows } from './hooks';
import { useNumberInputTableColumnValidationsModal } from './NumberInputTable.Modal.ColumnValidations';

type Props = {
  option: NumberInputTableQuestion.Option;
  question: NumberInputTableQuestion.Question;
};

export const NumberInputTableOptionContextMenu = (props: Props) => {

  const hasLinkedRows = useHasLinkedRows(props.question);

  const [toggleValidationModal, ValidationsModal] = useNumberInputTableColumnValidationsModal();

  const readOnlyItem = useReadOnlyOptionMenuItem({
    option: props.option,
    questionIdentifier: props.question.base.identifier,
  });

  const validationItem = useMemo(() => {
    return {
      children: 'Manage Validations',
      onClick: toggleValidationModal,
    };
  }, [toggleValidationModal]);

  const items = useMemo(() => {

    return [
      hasLinkedRows && readOnlyItem,
      validationItem,
    ].filter(Boolean);
  }, [
    hasLinkedRows,
    readOnlyItem,
    validationItem,
  ]);

  return (
    <>
      <OptionContextMenu
        option={props.option}
        items={items}
        excludeFindAndReplace={false} />
      <ValidationsModal
        question={props.question}
        option={props.option} />
    </>
  );
};