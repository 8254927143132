import { useCallback, useEffect, memo } from 'react';
import http from '@services/http';

export const DebugThrowErrorBackend = memo(() => {
  const fetch = useCallback(() => {
    return http.get(`/health/sentry-test-db`);
  }, []);

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div>Debug Throw Error Backend</div>
  );
});