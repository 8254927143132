import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@actions';
import * as selectors from '@store/selectors';
import { usePrevious } from '@utils';
import { ProjectInviteValidationContext } from './Context';
import { useAbortOnboardingRedirect, useInviteValidationQuery } from './hooks';
import { useDismissProjectRequestNotification } from './hooks/useDismissProjectRequestNotification';

type Props = {
  children: React.ReactNode;
} & IProjectId;

export const ProjectOnboardingInviteValidationContainer = (props: Props) => {
  const dispatch = useDispatch();
  const record = useSelector(selectors.pipeline.expert.record(props.projectId));
  const previousRecord = usePrevious(record);
  const dismissProjectRequestNotification = useDismissProjectRequestNotification(props.projectId);
  const abortRedirect = useAbortOnboardingRedirect(props.projectId);

  const invite = useInviteValidationQuery(props.projectId);

  const abortOnboarding = useCallback(() => {
    dismissProjectRequestNotification();

    if (record) {
      dispatch(actions.projectPipelineUpdated({
        pipeline: { me: { [record.projectId]: record }, project: {} },
        projectId: record.projectId,
      }));
    }

    abortRedirect(invite.data?.user?.disqualified);

  }, [
    abortRedirect,
    dismissProjectRequestNotification,
    dispatch,
    invite.data,
    record,
  ]);

  const validation = useMemo(() => {
    return {
      initialLoad: !invite.isLoading && !invite.data,
      inviteInvalid: !!invite.data && !invite.data.valid,
      statusChanged: record?.statusId !== previousRecord?.statusId,
    };
  }, [
    invite.isLoading,
    invite.data,
    previousRecord?.statusId,
    record?.statusId,
  ]);

  const hasRecord = useMemo(() => !!record, [record]);

  useEffect(() => {

    if (!hasRecord || validation.inviteInvalid) {
      return abortOnboarding();
    }

    if (validation.initialLoad || validation.statusChanged) {
      invite.refetch();
    }

  }, [
    abortOnboarding,
    invite,
    props.projectId,
    hasRecord,
    validation,
  ]);

  return (
    <ProjectInviteValidationContext.Provider value={invite}>
      {props.children}
    </ProjectInviteValidationContext.Provider>
  );
};

ProjectOnboardingInviteValidationContainer.displayName = 'ProjectOnboarding.InviteValidation.Container';