import { useContext, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import { getLocationFor } from '@utils';
import * as $api from '@api';
import { Button } from '@/components/Button';
import { CallFeedbackStepperContext } from './Context';
import styles from './style.css';

export const SecondaryScreener = () => {
  const ctx = useContext(CallFeedbackStepperContext);
  const history = useHistory();

  const { mutate } = useMutation($api.users.snoozeSecondaryScreenPrompt);

  const snoozePrompt = useCallback(() => {
    mutate();
    ctx.next();
  }, [mutate, ctx]);

  const goToProject = useCallback(() => {
    history.push(getLocationFor.onboarding.project({ projectId: ctx.secondaryScreenerProjectId }));
  }, [history, ctx]);

  return (
    <div className={styles.root}>
      <div className={styles.screenerTitle}>Want to improve your chances of being selected for research projects that are interesting and relevant to you?</div>
      <div>
        <p>
          Complete a couple of questions around your professional area of expertise to boost your odds of being selected for future projects.
        </p>
        <p>
          Click below to get started!
        </p>
      </div>
      <div className={styles.btns}>
        <Button
          className={styles.screenerSkipBtn}
          variant='brick'
          color='destructive'
          onClick={snoozePrompt}>
          {`I'll do this later`}
        </Button>
        <Button
          className={styles.screenerAcceptBtn}
          variant='brick'
          color='primary'
          onClick={goToProject}>
          Get Started
        </Button>
      </div>
    </div>
  );
};