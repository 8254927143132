import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as actions from '@store/actions';
import { useSelectGroup } from '@containers/Store';
import { useProjectFormContext, useProjectParentId } from '@screens/ProjectCreation/context';
import type { BaseCompany } from '@/types/companies';
import { getLocationFor } from '@utils';
import { useCreateProject } from '@utils/api';

export const useSubmitNewProject = () => {
  const parentObjectId = useProjectParentId();

  const history = useHistory();
  const dispatch = useDispatch();
  const group = useSelectGroup();
  const [form] = useProjectFormContext();

  const {
    mutateAsync: createProject,
    isLoading,
  } = useCreateProject({
    onSuccess: res => {
      dispatch(actions.projectCreated({
        object: res.object,
        parent: res.parent,
        project: res.project,
        projectId: res.project.id,
      }));

      const location = getLocationFor.project.root({
        slug: String(res.project.id),
      });
      history.push(location);
    },
  });

  const submit = useCallback(() => {
    const { id, ...tc } = form.targetCompany;

    // @ts-ignore
    const targetCompany: BaseCompany = !id
      ? null
      : tc.isUserCreated
        ? { name: form.targetCompany.name }
        : { id: form.targetCompany.id };

    const params = {
      accountId: form.accountId,
      clientName: form.clientName,
      companies: [],
      description: form.description,
      frequency: form.frequency,
      goal: form.goal,
      groupId: group.id,
      conferenceSettings: form.conferenceSettings,
      name: form.name,
      netsuiteProjectId: form.netsuite?.id,
      parentObjectId: parentObjectId,
      // projectCode: form.projectCode,
      projectSubtype: form.projectSubtype,
      projectType: form.projectType,
      purchaseOrder: form.purchaseOrder,
      roles: form.roles,
      screeningQuestions: [],
      // startDate: form.startDate,
      //survey: surveyState.survey,
      //surveyVersionId: surveyState.draft.surveyVersionId,
      surveyIds: form.surveys?.map(s => s.id) ?? [],
      targetCompany,
      targetCompleteDate: form.targetCompleteDate,
      titles: form.titles,
      vics: form.vics,
    };

    createProject(params);
  }, [
    createProject,
    form,
    group.id,
    parentObjectId,
  ]);

  return [submit, isLoading] as const;
};