import http from '@services/http';
import * as xform from './transformers/search';
import type { Search } from './interfaces';

export * as entities from './search.entities';
export * as projects from './search.projects';
export * as users from './search.users';

export const searchMembers = (data: Search.SearchMembers.Request): Promise<Search.SearchMembers.Response> => {
  return http.post(`/admin/search/members`, {
    active: data.active,
    listId: data.listId,
    pageIndex: data.pageIndex,
    pageSize: data.pageSize,
  })
  .then((result: Search.SearchMembers.Response<string>) => ({
    ...result,
    items: xform.transformMembers(result.items),
  }));
};

export const searchMembersExport = (data: Search.SearchMembersExport.Request) => {
  return http.downloadPost(`/admin/search/members`, {
    active: data.active,
    listId: data.listId,
    export: data.export,
  });
};