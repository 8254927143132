import http from '@services/http';
import { qs } from '@utils';
import { transformCallItem } from './transformers/projects';
import type { Projects } from './interfaces';

export const fetchCalls = (data: Projects.FetchCalls.Request): Promise<Projects.FetchCalls.Response> => {
  return http.get(`/admin/projects/${data.projectId}/calls?${qs.stringify(data, { arrayFormat: 'bracket' })}`)
  .then((data: Projects.FetchCalls.Response<string>) => ({
    items: data.items.map(transformCallItem),
    filters: data.filters,
    pagination: data.pagination,
  }));
};

export const downloadCallFiles = (data: Projects.DownloadCallFiles.Request): Promise<Projects.DownloadCallFiles.Response> => {
  return http.download(`/admin/projects/${data.projectId}/calls/export`);
};

export const updateCallSettings = (data: Projects.UpdateCallSettings.Request) => {
  const { callId, projectId, ...rest } = data;

  return http.put<Projects.UpdateCallSettings.Response>(`/admin/projects/${projectId}/calls/${callId}`, rest);
};