import { usePersistentProjectInvite } from '@containers/ProjectLinkInvitation/hooks/usePersistentProjectInvite';
import { cx } from '@utils';
import * as Landing from './Landing';
import { useProjectAudienceType } from './hooks/audience-type';
import styles from './style/Landing.css';

type Props = unknown;

export const CallLanding = (props: Props) => {
  const urlstate = usePersistentProjectInvite();
  const state = urlstate.get();
  const audienceType = useProjectAudienceType();

  const payment = state.data.payment
      ? `${state.data?.metadata?.currency?.sign || '$'}${state.data.payment}`
      : null;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={cx(styles.details, styles.info)}>
            <div className={styles.group}>
              <div className={styles.row}>
                <Landing.Title.Interview
                  audienceType={audienceType}
                  payment={!!state.data.payment} />
              </div>
              <div className={styles.row}>
                <h2 className={styles.text}>{state.data.project.name}</h2>
              </div>
            </div>
            <div className={styles.group}>
              <div className={styles.row}>
                <div className={styles.text}>
                  <span className={styles.label}>Project type:</span> Interview
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.text}>
                  <span className={styles.label}>Estimated Length:</span> {state.data.metadata.estimatedLength}
                </div>
              </div>
              {!!state.data.payment &&
                <div className={styles.row}>
                  <div className={styles.text}>
                    <span className={styles.label}>Payment:</span> {payment}
                  </div>
                </div>
              }
            </div>
            <div className={styles.group}>
              <div
                className={styles.row}
                style={{ whiteSpace: 'pre-line' }}>
                <div className={styles.text}>{state.data.project.description}</div>
              </div>
            </div>
            <div className={styles.group}>
              <Landing.ButtonSet />
            </div>
          </div>
          <Landing.Graphic audienceType={audienceType} />
        </div>
      </div>
    </div>
  );
};

CallLanding.displayName = 'ProjectInvite.Call.Landing';