import { memo } from 'react';
import { Hash } from 'react-feather';
import { cx } from '@utils';
import styles from './style/Topic.Logo.css';

type ClassNameProps = {
  className?: string;
  logoClassName?: string;
};

type Props = {
  classes?: {
    img?:  string;
    root?: string;
  };
  fallback?: {
    color?: string;
    icon?:  string;
    size?:  number;
  };
  url: string;
} & ClassNameProps;

export const TopicLogo = memo(({ classes = {}, fallback = {}, ...props }: Props) => {
  const root = cx(props.className, classes.root);
  const img = cx(props.logoClassName, classes.img);

  return props.url
    ? <TopicLogoImage
        classes={{
          img,
          root,
        }}
        url={props.url} />
    : <TopicLogoPlaceholder
        classes={{ icon: fallback.icon }}
        className={root}
        color={fallback.color}
        logoClassName={img}
        size={fallback.size} />;
});

export const TopicLogoImage = memo(({ classes = {}, ...props }: Props) => {
  const root = cx(styles.root, props.className, classes.root);
  const img = cx(styles.image, props.logoClassName, classes.img);

  return (
    <div className={root}>
      <ImageOverlay className={img}>
        <img
          className={img}
          src={props.url} />
      </ImageOverlay>
    </div>
  );
});

type PlaceholderProps = {
  classes?: {
    icon?: string;
  };
  color?: string;
  size?:  number;
} & ClassNameProps;

export const TopicLogoPlaceholder = memo(({
  classes = {},
  color = `var(--gray-d)`,
  ...props
}: PlaceholderProps) => {
  const root = cx(styles.root, props.className);
  const icon = cx(
    styles.placeholder,
    {
      [styles.svg]: !classes.icon,
    },
    props.logoClassName,
    classes.icon
  );

  return (
    <div className={root}>
      <div className={icon}>
        <Hash
          color={color}
          size={props.size} />
      </div>
    </div>
  );
});

type ImageOverlayProps = {
  className: string;
  children:  React.ReactNode;
};

const ImageOverlay = (props: ImageOverlayProps) => {
  return (
    <div className={cx(styles.wrap, props.className)}>
      {props.children}
      <div className={styles.overlay} />
    </div>
  );
};