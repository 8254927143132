import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { useBuildReturnRoute } from '@routes/hooks/useBuildReturnRoute';
import { SecretParams } from './interfaces';

const selectState = (state: Store.State) => state.user.state;

const LoginRedirect = () => {
  const redirect = useBuildReturnRoute();

  return <Redirect to={redirect} />;
};

export const RedirectWrapper = (props: SecretParams & ChildrenProps) => {
  const state = useSelector(selectState);
  if (!state.authenticated) {
    return <LoginRedirect />;
  } else {
    return (
      <>
        {props.children}
      </>);
  }
};