import { useCallback } from 'react';
import { useRepeatSurveyResponsesState } from '@containers/RepeatSurveyResponses/hooks/useRepeatSurveyResponsesState';
import { RepeatSurvey } from '@containers/RepeatSurveyResponses/interfaces';
import AggregateData from './ConjointAnalysis.Data.Aggregate';
import LevelData from './ConjointAnalysis.Data.Level';
import VersionData from './ConjointAnalysis.Data.Version';
import styles from './style/Data.css';

export const ConjointAnalysisRepeatData = () => {
  const { query } = useRepeatSurveyResponsesState<RepeatSurvey.QueryState.ConjointAnalysis>();

  const renderContent = useCallback(() => {
    if (query.levelId !== undefined) {
      return <LevelData />;
    } else if (query.projectId) {
      return <VersionData />;
    } else {
      return <AggregateData />;
    }
  }, [
    query.levelId,
    query.projectId,
  ]);

  return (
    <div className={styles.root}>
      {renderContent()}
    </div>
  );
};

export default ConjointAnalysisRepeatData;