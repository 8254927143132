import { useCallback, useContext, useEffect, useState } from 'react';
import type { EditorImperativeRef } from '@/components/Survey.RichText/interfaces';
import { RichTextEditorRegistryContext } from '../Context';

type Props = {
  identifier: string;
};

export const useRichTextEditorRegister = ({ identifier }: Props) => {
  const { register, unregister } = useContext(RichTextEditorRegistryContext);
  const [registered, setRegistered] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setRegistered(false);
      unregister(identifier);
    };
  }, [unregister, identifier]);

  return useCallback((editor: EditorImperativeRef) => {
    if (!registered && editor) {
      setRegistered(true);
      setTimeout(() => {
        register(identifier, editor);
      }, 0);
    }
  }, [register, identifier, registered]);
};