import * as consts from '@consts';
import {
  MessageReviewContainer,
  useMessageReviewState,
} from '@containers';
import { BackLink } from '@presentation/Back';
import { ConsultantAvatar, ConsultantCareer } from '@presentation';
import { cx } from '@utils';
import { Thread } from './Thread';
import styles from './style/ComplianceMessageReview.css';

type Props = unknown;

const MessageReview = (props: Props) => {
  const state = useMessageReviewState();

  if (!state?.thread) return null;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>

        <BackLink
          className={styles.back}
          to={consts.pathname.HOME} />

        <ConsultantAvatar
          name={state.consultant.profile.fullname}
          slug={state.consultant.profile.slug}
          src={state.consultant.profile.pictureUrl} />

        <ConsultantCareer career={state.consultant.career} />

        <div className={cx(styles.header, styles.divider)}>Message Details</div>

        <Thread data={state.thread} />

      </div>
    </div>
  );
};

const ComplianceMessageReview = () => {
  return (
    <MessageReviewContainer>
      <MessageReview />
    </MessageReviewContainer>
  );
};

export { ComplianceMessageReview };
export default ComplianceMessageReview;