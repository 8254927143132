import { useCallback } from 'react';
import { useTextContentPublisher, useTextPostCanPublish, usePublishPostContext } from '@containers/PostCreation/hooks';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { useLock } from '@utils';

export const PublishButton = () => {
  const [_, toggle] = useTextContentPublisher();
  const publish = usePublishPostContext();
  const canPublish = useTextPostCanPublish();

  const [busy, lock] = useLock();

  const onSubmit = useCallback(() => {
    return publish()
      .then(toggle);
  }, [
    toggle,
    publish,
  ]);

  return (
    <ButtonActivityIndicator
      loading={busy}
      implicitDisable={false}
      disabled={!canPublish}
      onClick={lock(onSubmit)}
      variant="brick">
      Post
    </ButtonActivityIndicator>
  );
};

PublishButton.displayName = 'PostCreation.Text.Compose.PublishButton';