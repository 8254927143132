import { memo } from 'react';
import { cx } from '@utils';
import { ConditionalTooltip } from '@presentation/Tooltip';
import Archive from '@/components/icons/Archive.Legacy';
import Check from '@/components/icons/Check';
import Trash from '@/components/icons/Trash';
import XCircle from '@/components/icons/XCircle';
import PlusCircle from './PlusCircle';
import styles from './style/FormButtons.css';

export type FormButtonsProps = {
  archivable?: boolean;
  className?: string;
  creatable?: boolean;
  deletable?: boolean;
  disabled?: boolean;
  disabledTooltip?: string;
  onArchive?: () => unknown;
  onCancel: () => unknown;
  onDelete?: () => unknown;
  onSubmit?: () => unknown;
  onCreate?: () => unknown;
  size?: number;
};

type Props = FormButtonsProps;

const FormButtons = memo((props: Props) => (
  <div className={cx(styles.root, props.className)}>
    {props.creatable &&
      <PlusCircle
        size={28}
        onClick={props.onCreate} />}
    {!(props.disabled && !props.disabledTooltip) &&
      <ConditionalTooltip
        enabled={!!props.disabledTooltip}
        title={props.disabledTooltip}
        placement='top'>
        <span>
          <Check
            disabled={props.disabled}
            size={props.size}
            onClick={props.onSubmit} />
        </span>
      </ConditionalTooltip>}
    {props.onCancel && (
      <XCircle
        size={props.size}
        onClick={props.onCancel} />
    )}
    {props.archivable &&
      <Archive
        size={props.size}
        onClick={props.onArchive} />}
    {props.deletable &&
      <Trash
        size={props.size}
        onClick={props.onDelete} />}
  </div>
));

export { FormButtons };
export default FormButtons;