import { NetworkQualityLevel } from '@/components/Conference.Video';
import styles from './style.css';

type Props = {
  qualityLevel: number;
  visible: boolean;
};

function getText(level: number) {
  if (level >= 5) return 'Excellent';
  if (level >= 4) return 'Great';
  if (level >= 3) return 'Good';
  if (level >= 2) return 'Average';
  return 'Poor';
}

export function NetworkQuality(props: Props) {
  if (!props.visible) return null;
  if (props.qualityLevel === undefined || props.qualityLevel === null) return null;

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        Network Quality
      </div>
      <div className={styles.content}>
        <div className={styles.icon}>
          <NetworkQualityLevel
            borderColor='var(--pri-01)'
            qualityLevel={props.qualityLevel} />
        </div>
        <div className={styles.text}>
          {getText(props.qualityLevel)}
        </div>
      </div>
    </div>
  );
}

export default NetworkQuality;