import { memo } from 'react';

type Props = {
  className?: string;
  onClick?: () => void;
};

export const ResponsePipingIcon = memo(({ className, onClick }: Props) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      enableBackground="new 0 0 24 24"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000">
      <g>
        <rect fill="none" height="24" width="24"/>
      </g>
      <g>
        <g>
          <path d="M3,11c0,2.45,1.76,4.47,4.08,4.91l-1.49-1.49L7,13l4,4.01L7,21l-1.41-1.41l1.58-1.58l0-0.06C3.7,17.54,1,14.58,1,11 c0-3.87,3.13-7,7-7h3v2H8C5.24,6,3,8.24,3,11z"/>
          <path d="M22,11V4h-9v7H22z M20,9h-5V6h5V9z"/>
          <rect height="7" width="9" x="13" y="13"/>
        </g>
      </g>
    </svg>
  );
});