import { useTemplateTargetModal } from '@/components/Survey.Templates/Modal.Target';
import { TemplateItemLabel } from './Label.Base';

type Props = {
  className?: string;
  disabled: boolean;
  value: string;
};

export const TemplateTarget = ({ className, disabled, value }: Props) => {

  const [toggleModal, Modal] = useTemplateTargetModal();

  return (
    <>
      <TemplateItemLabel
        className={className}
        disabled={disabled}
        label={value}
        onClick={toggleModal} />
      {!disabled && <Modal />}
    </>
  );
};