import { FilterOptionsContext } from '$admin/Search/Context';
import type { Filters } from '$admin/Search/interfaces/members';

type Props = {
  children: React.ReactNode;
  options?: Filters.Options;
};

export const StateContainerFiltersOptions = ({ options = Options, ...props }: Props) => {
  return (
    <FilterOptionsContext.Provider value={options}>
      {props.children}
    </FilterOptionsContext.Provider>
  );
};

StateContainerFiltersOptions.displayName = 'StateContainer.Filters.Options';

export const Options: Filters.FieldNameOption[] = [
  { id: 'keyword', name: 'Keyword' },
  { id: 'company', name: 'Current Institution' },
  { id: 'company-former', name: 'Former Institution' },
  { id: 'covered-lives', name: 'Covered Lives' },
  { id: 'lead-status', name: 'Lead Status' },
  { id: 'name', name: 'Name' },
  { id: 'job-title', name: 'Title' },
  /* { id: 'sector', name: 'Sector' }, */
  { id: 'industry', name: 'Specialty' },
  { id: 'sub-industry', name: 'Subspecialty' },
  { id: 'product', name: 'Product' },
  { id: 'role', name: 'Role' },
  { id: 'market-research', name: 'Market Research Preferences' },
  { id: 'country', name: 'Country' },
  { id: 'payer-admin-type', name: 'Payer / Admin Type' },
  { id: 'is-kol', name: 'Is KOL' },
  { id: 'is-payer', name: 'Is Payer' },
  { id: 'hcp-type', name: 'HCP Type' },
  { id: 'project-code', name: 'Project Code' },
];