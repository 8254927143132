import { useCallback } from 'react';
import { useSurveyBuilderState, useIsEditingSurvey } from '@containers/SurveyBuilder';
import { useRichTextListBuilderContext } from '@containers/SurveyBuilder.Question/Context';
import { AddQuestionRowButton, ConditionalResponseIcon } from '@presentation/SurveyBuilder';
import { Tooltip } from '@presentation/Tooltip';
import type { SurveyQuestionMatrixRow, SurveyQuestionRowMetadata } from '@/types/survey';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import { SurveyConditionType } from '@/enums';
import { getTemplateEntityLabelComponent } from '@/components/Survey.Templates/Label';
import type { RenderRichTextEntryListEditorProps, RichTextEntryItem } from '@/components/TextEntryList';
import { RichTextEntryListEditor } from '@/components/TextEntryList/RichTextEntryListItem';
import { RichListBuilderItem } from './RichListBuilder';
import { RowContextMenu } from './Row.ContextMenu';
import { LanguageEditingBarrier } from './LanguageEditingBarrier';

type Props = {
  className?: string;
  renderAction?: (option: SurveyQuestionMatrixRow) => React.ReactNode;
};

type RichListItem = {
  metadata: SurveyQuestionRowMetadata;
} & RichTextEntryItem;

export const QuestionRowsBuilder = ({ renderAction, ...props }: Props) => {

  const context = useRichTextListBuilderContext<SurveyQuestionMatrixRow>();

  const [state] = useSurveyBuilderState();
  const editing = useIsEditingSurvey();

  const renderConditionalIcon = useCallback((option: SurveyQuestionMatrixRow) => {
    if (!option.conditions.length) return null;

    const condition = option.conditions[0];
    if (condition.conditionType !== SurveyConditionType.Question) return null;

    const dependentOrdinal = state.survey.questions
      .find(f => f.base.identifier === condition.data.question.identifier)?.ordinal;

    if (!dependentOrdinal) return null;

    const tooltipText = `Conditional upon response to Q${dependentOrdinal}`;

    return (
      <Tooltip title={tooltipText}>
        <div>
          <ConditionalResponseIcon />
        </div>
      </Tooltip>
    );
  }, [state.survey.questions]);

  const renderItem = useCallback((p: RenderRichTextEntryListEditorProps<RichListItem>) => {

    const entityType = p.item.metadata.template.linkedEntity?.type;

    if (!entityType) return <RichTextEntryListEditor<RichListItem> {...p} />;

    const TemplateComponent = getTemplateEntityLabelComponent(entityType);

    return (
      <TemplateComponent
        disabled={editing}
        value={parseSurveyRichText(p.item.value, false)} />
    );

  }, [editing]);

  const renderRowMenu = useCallback((row: SurveyQuestionMatrixRow) => {
    if (renderAction) return renderAction(row);

    return (
      <LanguageEditingBarrier>
        <RowContextMenu row={row} />
      </LanguageEditingBarrier>
    );
  }, [renderAction]);

  return (
    <div className={props.className}>
      {context.items.map(row => (
        <RichListBuilderItem<RichListItem>
          key={row.base.identifier}
          disabled={!row.metadata.canModifyValue}
          item={{
            key: row.base.identifier,
            value: row.value,
            metadata: row.metadata,
          }}
          renderEditor={renderItem}
          placeholder="Enter a row"
          renderAction={() => renderRowMenu(row)}
          onUpdate={value => context.updateItemValue(row.base.identifier, value)}>
          {renderConditionalIcon(row)}
        </RichListBuilderItem>
      ))}
      {context.permissions.canAddItem &&
        <LanguageEditingBarrier>
          <AddQuestionRowButton
            onClick={context.addItem} />
        </LanguageEditingBarrier>}
    </div>
  );
};

export default QuestionRowsBuilder;