import { createElement, isValidElement, useCallback, useMemo } from 'react';
import { format } from 'date-fns';
import type { GetUpcomingCalls } from '@api/interfaces/users';
import type { SectionListProps, SectionListRenderHeader, SectionProps } from '@/components/SectionList';
import { SectionList } from '@/components/SectionList';
import styles from './style/CallsAgenda.css';
import { buildCallsMap } from './utils';

type Props = {
  ListEmptyComponent?: React.ComponentType;
  items: UpcomingCallsItem[];
  sectionKeyExtractor: (item: UpcomingCallsItem) => string;
} & Pick<SectionListProps<UpcomingCallsItem>, 'renderItem'>;

type UpcomingCallsItem = GetUpcomingCalls.Item;

const CallsAgenda = ({ ListEmptyComponent, items, sectionKeyExtractor, ...props }: Props) => {

  const renderSectionHeader: SectionListRenderHeader<UpcomingCallsItem, SectionProps> = useCallback(({ section }) => {
    return (
      <div className={styles.day}>{section.title as React.ReactNode}</div>
    );
  }, []);

  const sections = useMemo(() => {
    const callsMap = buildCallsMap(items, sectionKeyExtractor);

    return Object.keys(callsMap).map(key => {
      const title = format(new Date(), 'MMM d') === key ? 'Today' : key;
      const data = callsMap[key];
      return {
        data,
        title,
      };
    });
  }, [
    items,
    sectionKeyExtractor,
  ]);

  if (!items.length) {
    return isValidElement(ListEmptyComponent)
      ? null
      : createElement(ListEmptyComponent);
  }

  return (
    <SectionList
      className={styles.root}
      sections={sections}
      keyExtractor={x => String(x.call.id)}
      renderItem={props.renderItem}
      renderSectionHeader={renderSectionHeader} />
  );
};

export { CallsAgenda };
export default CallsAgenda;