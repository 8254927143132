import { Link } from 'react-router-dom';
import { X } from 'react-feather';
import * as consts from '@consts';
import { useBannerContext } from '@containers/Banner';
import styles from './style/ProfileComplete.css';

export const ProfileComplete = () => {
  const alert = useBannerContext();

  if (!alert.profileCompletedVisible) return null;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.group}>
            <div className={styles.text}>Your profile is now live for our clients. </div>
            <div className={styles.text}>You will be notified when new project opportunities are available! </div>
          </div>

          <div className={styles.group}>
            <div className={styles.text}>
              <a
                className={styles.hyperlink}
                target="_blank"
                rel="noopener noreferrer"
                href={consts.urls.FAQ}>
                <span className={styles.span}>Learn more here </span>
              </a>
              <span className={styles.span}>or </span>
              <Link
                className={styles.hyperlink}
                to={consts.pathname.Home}>
                <span className={styles.span}>go to Home </span>
              </Link>
            </div>
            <div className={styles.text}>
              to continue onboarding
            </div>
          </div>
        </div>

        <div
          className={styles.close}
          onClick={alert.dismissProfileCompleted}>
          <X size={30} />
        </div>
      </div>
    </div>
  );
};

ProfileComplete.displayName = 'Banner.ProfileComplete';