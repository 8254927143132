import { useContext, useMemo, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { Admin } from '@consts/path.admin';
import * as QK from '@consts/querykey.admin';
import { ButtonActivityIndicator, ButtonOutlined } from '@presentation';
import { getLocationFor } from '@utils';
import * as $conference from '$admin/containers/Call/utils';
import * as api from '$admin/api';
import { Button } from '@/components/Button';
import { Checkbox } from '@/components/Checkbox';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import Toast from '@/components/Toast';
import * as CallConfirmation from './Confirmation';
import { ConfirmContext, ParamsContext, RespondentSetupContext } from './Context';
import { useCallRecordLocation, useExitCallScheduling } from './hooks';
import styles from './style/Confirmation.css';

type Props = unknown;

export const Confirmation = (props: Props) => {
  const ctx = {
    confirm: useContext(ConfirmContext),
    respondent: useContext(RespondentSetupContext),
    scheduling: useContext(ParamsContext),
  };

  const [notifyParticipants, setNotifyParticipants] = useState(true);
  const [notifyRespondent, setNotifyRespondent] = useState(!!ctx.respondent.form.email);

  const mutation = useMutation(QK.Projects.Scheduling.Notifications.Post({
    callId: ctx.confirm.confirmation.call.id,
    projectId: ctx.confirm.confirmation.call.projectId,
  }), () => {
    return api.projects.scheduling.notifyCallParticipants({
      callId: ctx.confirm.confirmation.call.id,
      projectId: ctx.confirm.confirmation.call.projectId,
      notify: {
        participants: notifyParticipants,
        respondent: notifyRespondent,
      },
    });
  }, {
    onSuccess: () => {
      Toast.success({
        title: copy.toast,
      });
    },
    onError: () => {
      Toast.error({
        title: 'There was an error sending the calendar invitations',
      });
    },
  });

  const showNotifyRespondent = useMemo(() => {
    return !!ctx.respondent.form.email
      && mutation.status !== 'success';
  }, [
    ctx.respondent.form.email,
    mutation.status,
  ]);

  const showSubmit = useMemo(() => {
    return (notifyParticipants || notifyRespondent)
      && mutation.status !== 'success';
  }, [
    mutation.status,
    notifyParticipants,
    notifyRespondent,
  ]);

  const close = useExitCallScheduling();
  const location = useCallRecordLocation(ctx.confirm.confirmation.call.id);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>

        <div className={styles.main}>
          {$conference.isOffPlatform(ctx.confirm.confirmation.conference.settings)
            ? <CallConfirmation.OffPlatform />
            : <CallConfirmation.OnPlatform />}
        </div>

        <div className={styles.row}>
          <div className={styles.option}>
            {mutation.status !== 'success' &&
              <>
                <Checkbox
                  checked={notifyParticipants}
                  onChange={(e, checked) => setNotifyParticipants(checked)} />
                <div className={styles.message}>{copy.notify}</div>
              </>}
          </div>
          {showNotifyRespondent &&
            <div className={styles.option}>
              <Checkbox
                checked={notifyRespondent}
                onChange={(e, checked) => setNotifyRespondent(checked)} />
              <div className={styles.message}>{`Send calendar invitation to ${ctx.respondent.form.email}`}</div>
            </div>}
        </div>

        <ButtonSet className={styles.footer}>
          <ButtonOutlined
            className={styles.btn}
            color="silver"
            fontWeight="bold"
            onClick={close}>
            Close
          </ButtonOutlined>
          {showSubmit &&
            <ButtonActivityIndicator
              className={styles.btn}
              loading={mutation.isLoading}
              implicitDisable={false}
              onClick={() => mutation.mutate()}>
              Send
            </ButtonActivityIndicator>}
          {(!showSubmit && location) &&
            <Button
              className={styles.btn}
              color="secondary"
              to={location}
              variant="brick">
              Go to Call
            </Button>}
        </ButtonSet>
      </div>
    </div>
  );
};

Confirmation.displayName = 'Frame.Confirmation';

const copy = {
  notify: `Send calendar invitations`,
  toast: `Calendar invitations sent`,
};