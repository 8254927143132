import { useEffect } from 'react';
import * as $api from '@api';

export const useLockProjectEntityBoost = ({ projectId }: IProjectId) => {
  useEffect(() => {
    $api.projects.entities.toggleProjectEntityBoostLock({ projectId, lock: true });

    return () => {
      $api.projects.entities.toggleProjectEntityBoostLock({ projectId, lock: false });
    };
  }, [projectId]);
};