import { Helmet } from 'react-helmet';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import { pathname } from '@consts';
import { BackLink } from '@presentation/Back';
import styles from './style/Profile.NotFound.css';

export function ProfileNotFound() {
  return (
    <div className={styles.root}>
      <Helmet title="Profile not found" />
      <div className={styles.wrap}>
        <div className={styles.icon}><ErrorIcon /></div>
        <div className={styles.copy}>Profile does not exist</div>
        <div className={styles.back}>
          <BackLink to={`${pathname.Home}`} />
        </div>
      </div>
    </div>
  );
}

ProfileNotFound.displayName = 'Profile.NotFound';