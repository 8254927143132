import { PlatformMessage, PlatformMessageThread } from '@/types';

export const transformMessageItem = (item: PlatformMessage<string>['message']) => {
  return {
    ...item,
    createdOn: new Date(item.createdOn),
  };
};

export const transformMessageThread = (item: PlatformMessageThread<string>) => {
  return {
    ...item,
    lastMessage: {
      ...item.lastMessage,
      timestamp: new Date(item.lastMessage.timestamp),
    },
    messages: transformPlatformMessages(item.messages),
  };
};

export const transformPlatformMessages = (items: PlatformMessage<string>[]) => {
  return items.map<PlatformMessage>(x => ({
    ...x,
    message: transformMessageItem(x.message),
  }));
};