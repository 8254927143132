import { useState, useCallback, useContext, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { getEntityLists, getEntityListRecords } from '@api/projects.entities';
import { useMatchProjectIdFromUrl } from '@containers/GroupProject/hooks';
import ActivityIndicator from '@/components/ActivityIndicator';
import { Checkbox } from '@/components/Checkbox';
import { useStepper } from '@/utils';
import { Back } from '@/presentation/Back';
import { ButtonActivityIndicator } from '@/presentation';
import { OnboardingManual } from './Onboarding.Manual';
import { EntityListOnboardingContext } from './Context';
import type { EntityListItem } from './interfaces';
import { EntitiesTable } from './Onboarding.Entities.Table';
import { useBoostDismiss, useLockProjectEntityBoost } from './hooks';
import styles from './style/Onboarding.Lists.css';

export const EntitiesOnboarding = () => {
  const { projectId } = useMatchProjectIdFromUrl();
  const [selectedLists, setSelectedLists] = useState<number[]>([]);
  const [ignoredEntities, setIgnoredEntities] = useState<number[]>([]);
  const screens = useMemo(() => [ListsSelector, selectedLists.length > 0 ? EntityConfirmation : null, OnboardingManual].filter(Boolean), [selectedLists.length]);
  const [Screen, actions, index] = useStepper(screens);
  const { dismiss } = useBoostDismiss();
  const history = useHistory();

  const leaveOnboarding = useCallback(() => {
    dismiss();
    history.goBack();
  }, [dismiss, history]);

  useLockProjectEntityBoost({ projectId });

  return (
    <EntityListOnboardingContext.Provider
      value={{
        selectedLists,
        setSelectedLists,
        ignoredEntities,
        setIgnoredEntities,
        goBack: index > 0 ? actions.back : leaveOnboarding,
        goNext: actions.next,
      }}>
      <div className={styles.root}>
        <Screen />
      </div>
    </EntityListOnboardingContext.Provider>
  );
};

const ListsSelector = () => {
  const { projectId } = useMatchProjectIdFromUrl();

  const listsQuery = useQuery({
    queryKey: ['get-project-entity-lists', projectId],
    queryFn: () => getEntityLists({ projectId }),
  });

  const { selectedLists, setSelectedLists } = useContext(EntityListOnboardingContext);

  const handleListToggle = useCallback((listId: number, checked: boolean) => {
    if (checked) {
      setSelectedLists(old => old.indexOf(listId) === -1 ? [...old, listId] : old);
    } else {
      setSelectedLists(old => old.filter(i => i !== listId));
    }
  }, [setSelectedLists]);

  if (listsQuery.isLoading) {
    return <ActivityIndicator />;
  }

  return (
    <div className={styles.content}>
      <div>
        <div className={styles.title}>Select the Entity list that wish to import for your project</div>
        <div className={styles.list}>
          {listsQuery.data.lists.map(l => (
            <label className={styles.row} key={l.id}>
              <Checkbox checked={selectedLists.includes(l.id)} onChange={e => handleListToggle(l.id, e.target.checked)} />
              <div className={styles.label}>{l.name}</div>
            </label>
          ))}
        </div>
      </div>
      <Navigation />
    </div>
  );
};

const EntityConfirmation = () => {
  const { projectId } = useMatchProjectIdFromUrl();
  const { selectedLists } = useContext(EntityListOnboardingContext);

  const entitiesQuery = useQuery({
    queryKey: ['get-entity-list-entities', ...selectedLists],
    queryFn: () => getEntityListRecords({ projectId, listIds: selectedLists }).then(transformListEntityResponse),
    enabled: selectedLists.length > 0,
    placeholderData: [],
  });

  if (entitiesQuery.isLoading || selectedLists.length === 0) {
    return <ActivityIndicator />;
  }

  return (
    <div className={styles.content}>
      <div>
        <div className={styles.title}>{`Here's all the entities on the lists you chose`}</div>
        <EntitiesTable items={entitiesQuery.data} />
      </div>
      <Navigation />
    </div>
  );
};

const Navigation = () => {
  const { goBack, goNext } = useContext(EntityListOnboardingContext);

  return (
    <div className={styles.navRoot}>
      {goBack && <Back onClick={goBack} />}
      {goNext && <ButtonActivityIndicator onClick={goNext}>Next</ButtonActivityIndicator>}
    </div>
  );
};

function transformListEntityResponse(data: Awaited<ReturnType<typeof getEntityListRecords>>): EntityListItem[] {
  return data.entities.map(e => {
    return {
      ...e,
      lists: e.listIds.map(i => data.lists.find(l => l.id == i)).filter(Boolean),
    };
  });
}