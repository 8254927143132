import { useCallback } from 'react';
import type { GroupTagUpdateItem } from '@/types';
import GroupTagAutocomplete from '@/components/AutoComplete/GroupTag';
import List from './Tags.List';
import { useGroupTags, useGroupTagsDispatch } from './hooks';
import styles from './style.css';

export default function Editing() {
  const { tags } = useGroupTags();
  const dispatch = useGroupTagsDispatch();

  const handleSelect = useCallback((item: GroupTagUpdateItem) => {
    dispatch(state => ({
      ...state,
      tags: [...state.tags, item],
    }));
  }, [dispatch]);

  const handleRemove = useCallback((item: GroupTagUpdateItem) => {
    dispatch(state => ({
      ...state,
      tags: state.tags.filter(t => t.id !== item.id),
    }));
  }, [dispatch]);

  return (
    <>
      <div className={styles.ac}>
        <GroupTagAutocomplete
          onSelect={handleSelect}
          ignoreList={tags} />
      </div>
      <List selected onRemove={handleRemove} />
    </>
  );
}

export { Editing };