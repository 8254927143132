import type { AsyncMutationDownloadsVars } from '@containers/QueryDownloader';
import { useAsyncMutationDownloader } from '@containers/QueryDownloader';
import * as $api from '@api/projects.reports';
import type { ReportFormat } from '@/enums';

type Props = IProjectId;

type Data = { websocketKey: string };
type Context = { id: string };
type Vars = { format: ReportFormat } & AsyncMutationDownloadsVars;

export const useDownloadGoodQuotes = (props: Props) => {
  return useAsyncMutationDownloader<Data, Context, Vars>({
    mutationFn: vars => {
      return $api.downloadProjectQuotesReport({
        projectId: props.projectId,
        format: vars.format,
      });
    },
  });
};