import { useMemo } from 'react';
import { type Cell } from 'react-table';
import { TranscriptPublishType, TranscriptionRevisionStatus, WorkspaceObjectType } from '@/enums';
import { DownloadFileContainer } from '@/containers/Workspace.File/DownloadFileContainer';
import { FileActionsContainer } from '@/containers/Workspace.File/FileMenuContainer';
import { FileActionsContextMenuPopper } from '@/components/Workspace.File/File.Actions.ContextMenu';
import { FolderActionsContextMenuPopper } from '@/components/Workspace.Folder/Folder.Actions.ContextMenu';
import { TranscriptContextMenuPopper } from '@/components/Workspace.Transcript';
import { WorkspaceTranscriptAccessContext, type WorkspaceTranscriptAccess } from '@/containers/Transcript/context';
import { CallTranscriptWorkspaceActionsContainer } from '@/containers/Transcript/Call.WorkspaceActions';
import type {
  IWorkspaceFileItem,
  IWorkspaceFolderItem,
  IWorkspaceProjectItem,
  IWorkspaceTranscriptItem,
} from '@/types/workspace.table';
import FileTranscriptStatusContainer from '@/containers/Workspace.File/TranscriptAvailableContainer';
import { FolderActionsContainer } from '@/containers/Workspace.Folder/FolderMenuContainer';
import { type FileMenuItemsContextValue, FileMenuItemsContext } from '@/containers/Workspace.File/Context';
import { FolderMenuItemsContext } from '@/containers/Workspace.Folder/Context';
import { useIsInternalUser } from '@/containers/Store/hooks';
import { assertCanImportTranscript, assertCanTranscribeFile } from '@/containers/Workspace.File/utils';
import type { IFilesTable } from './interfaces';

export function ActionsCell(props: Cell<IFilesTable.Item>) {
  switch (props.row.original.object.typeId) {
    case WorkspaceObjectType.File: {
      const item = (props as Cell<IWorkspaceFileItem>).row.original;

      return (
        <FileTranscriptStatusContainer fileId={item.file.id} transcript={item.transcript}>
          <FileActionsCell {...(props as Cell<IWorkspaceFileItem>)} />
        </FileTranscriptStatusContainer>
      );
    }

    case WorkspaceObjectType.Folder:
      return <FolderActionsCell {...(props as Cell<IWorkspaceFolderItem>)} />;

    case WorkspaceObjectType.ProjectParent:
      return <ProjectActionsCell {...(props as Cell<IWorkspaceProjectItem>)} />;

    case WorkspaceObjectType.CallTranscript:
      return <TranscriptActionsCell {...(props as Cell<IWorkspaceTranscriptItem>)} />;

    case WorkspaceObjectType.Brand:
    case WorkspaceObjectType.Workspace:
      return null;

    default:
      throw new UnreachableCaseError(props.row.original.object.typeId);
  }
}

function ProjectActionsCell({ row: { original: data } }: Cell<IWorkspaceProjectItem>) {

  return (
    <>
    </>
  );
}

function TranscriptActionsCell({ row: { original: data } }: Cell<IWorkspaceTranscriptItem>) {
  const isInternalUser = useIsInternalUser();

  const access: WorkspaceTranscriptAccess = useMemo(() => ({
    canAccessRecording: isInternalUser || canAccessRecording(data.transcript?.settings?.publishType),
    canAccessTranscription: isInternalUser || canAccessTranscription(data.transcript?.settings?.publishType),
    canManageAccess: data.capabilities.canManageAccess,
    canMove: data.capabilities.canMove,
  }), [
    data.capabilities,
    data.transcript?.settings?.publishType,
    isInternalUser,
  ]);

  const isDownloadable = isInternalUser || data.transcript.enhancement?.statusId === TranscriptionRevisionStatus.Completed;

  if (!isDownloadable) return null;

  return (
    <WorkspaceTranscriptAccessContext.Provider value={access}>
      <CallTranscriptWorkspaceActionsContainer object={data.object} transcript={data.transcript}>
        <TranscriptContextMenuPopper transcriptId={data.transcript.id} />
      </CallTranscriptWorkspaceActionsContainer>
    </WorkspaceTranscriptAccessContext.Provider>
  );
}

function FileActionsCell({ row: { original: data } }: Cell<IWorkspaceFileItem>) {
  const isInternalUser = useIsInternalUser();

  const isViewable = isInternalUser || data.transcript.enhancement?.statusId === TranscriptionRevisionStatus.Completed;

  const menu: FileMenuItemsContextValue = useMemo(() => ({
    canChangeOwner: data.capabilities.canChangeOwner,
    canDelete: data.capabilities.canDelete,
    canDownload: isViewable && data.capabilities.canDownload,
    canManageAccess: data.capabilities.canManageAccess,
    canMove: data.capabilities.canMove,
    canRename: data.capabilities.canRename,
    canTranscribe: assertCanTranscribeFile(data.transcript),
    canViewTranscript: isViewable && !!data.transcript.id,
    canImportTranscript: assertCanImportTranscript(data.transcript),
  }), [
    data.capabilities,
    data.transcript,
    isViewable,
  ]);

  return (
    <DownloadFileContainer
      fileId={data.file.id}
      workspaceId={data.object.workspaceId}>
      <FileMenuItemsContext.Provider value={menu}>
        <FileActionsContainer
          file={data.file}
          object={data.object}
          transcript={data.transcript}>
          <FileActionsContextMenuPopper />
        </FileActionsContainer>
      </FileMenuItemsContext.Provider>
    </DownloadFileContainer>
  );
}

function FolderActionsCell({ row: { original: data } }: Cell<IWorkspaceFolderItem>) {

  const menu = useMemo(() => {
    return {
      canAddChildren: data.capabilities.canAddChildren,
      canDelete: data.capabilities.canDelete,
      canRename: data.capabilities.canRename,
      canManageAccess: data.capabilities.canManageAccess,
      canMove: data.capabilities.canMove,
    };
  }, [data.capabilities]);

  return (
    <FolderMenuItemsContext.Provider value={menu}>
      <FolderActionsContainer
        folder={data.folder}
        object={data.object}>
        <FolderActionsContextMenuPopper />
      </FolderActionsContainer>
    </FolderMenuItemsContext.Provider>
  );
}

function canAccessRecording(typeId: TranscriptPublishType) {
  return typeId === TranscriptPublishType.RecordingAndTranscript
    || typeId === TranscriptPublishType.RecordingOnly;
}

function canAccessTranscription(typeId: TranscriptPublishType) {
  return typeId === TranscriptPublishType.RecordingAndTranscript
    || typeId === TranscriptPublishType.TranscriptOnly;
}