import { ConferenceCoordinatorContainer } from './CoordinatorContainer';
import { ConferenceSocketContainer } from './SocketContainer';
import { TwilioVoiceContainer } from './TwilioVoiceContainer';
import { TwilioVideoContainer } from './TwilioVideoContainer';
import { TwilioLogContainer } from './TwilioLogContainer';

export function ConferenceContainer({ children }: ChildrenProps) {
  return (
    <ConferenceSocketContainer>
      <TwilioLogContainer>
        <TwilioVoiceContainer>
          <TwilioVideoContainer>
            <ConferenceCoordinatorContainer>
              {children}
            </ConferenceCoordinatorContainer>
          </TwilioVideoContainer>
        </TwilioVoiceContainer>
      </TwilioLogContainer>
    </ConferenceSocketContainer>
  );
}