type Props = unknown;

export const OutlookCalendar = (props: Props) => {

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: `100vh`,
      width: `100vw`,
      backgroundColor: `#002d32`,
      color: `#aaffaa`,
      fontSize: 32,
    }}>
      Loading...
    </div>
  );
};

OutlookCalendar.displayName = 'Inbound.OutlookCalendar';