import { useMemo, useCallback } from 'react';
import type { BarTooltipDatum, BarMouseEventHandler, BarDatum } from '@nivo/bar';
import { MatrixChartTooltip } from '@presentation/ProjectSurveyResponses';
import type { StackedBarChartData, Colors, MatrixGroupItem } from './interfaces';
import { StackedBarChart, percentProps } from './StackedBarChart';

type Props = {
  keys: {
    id: number;
    isNotApplicable: boolean;
  }[];
  responses: MatrixGroupItem[];
  onSegmentClick: (rowId: number, key: string) => void;
};

const MissingKey = 'missing';

export const MatrixGridChart = ({
  onSegmentClick,
  ...props
}: Props) => {

  const items: StackedBarChartData[] = useMemo(() => {

    return props.responses.map(m => {

      const values: BarDatum = {};
      m.options.forEach(o => {
        values[o.id] = o.pct;
      });

      const colors: Colors = {};
      m.options.forEach(o => {
        colors[o.id] = o.color;
      });

      return Object.assign(values, {
        colors,
        indexId: m.id.toString(),
        name: m.name,
      }) as StackedBarChartData;
    });

  }, [props.responses]);

  const notApplicableKeys = useMemo(() => {
    const naOption = props.keys.find(f => f.isNotApplicable);

    const k: string[] = [MissingKey];

    if (naOption) k.push(naOption.id.toString());

    return k;
  }, [
    props.keys,
  ]);

  const handleSegmentClick: BarMouseEventHandler<SVGRectElement, StackedBarChartData> = useCallback((item, e) => {
    onSegmentClick(+item.data.indexId, item.id.toString());
  }, [onSegmentClick]);

  const renderTooltip = useCallback((item: BarTooltipDatum) => {

    const data = props.responses
      .find(f => f.id === +item.data.indexId).options.find(f => f.id.toString() === item.id);

    return (
      <MatrixChartTooltip
        rowName={item.data.name}
        count={data.userIds.length}
        optionName={data.name}
        percent={data.pct} />
    );
  }, [props.responses]);

  const keys = props.keys.map(m => m.id.toString());

  return (
    <StackedBarChart
      {...percentProps}
      handleSegmentClick={handleSegmentClick}
      items={items}
      keys={keys}
      notApplicableKeys={notApplicableKeys}
      renderTooltip={renderTooltip} />
  );
};

export default MatrixGridChart;