import { useCallback, Fragment } from 'react';
import { ChatAttachmentType } from '@/brand-insights/enums';
import type { Chat } from '@/brand-insights/types';
import { SuggestedActionsAttachment } from './Message.SuggestedActions';

type Props = {
  attachment: Chat.Attachments.Value[];
  className?: string;
};

export const ChatMessageAttachments = (props: Props) => {

  const renderAttachment = useCallback((attachment: Chat.Attachments.Value) => {
    switch (attachment.type) {
      case ChatAttachmentType.SuggestedActions:
        return <SuggestedActionsAttachment attachment={attachment} />;

      default:
        throw new UnreachableCaseError(attachment.type);
    }
  }, []);

  return (
    <div className={props.className}>
      {props.attachment.map((attachment, i) => (
        <Fragment key={i}>
          {renderAttachment(attachment)}
        </Fragment>
      ))}
    </div>
  );
};