import { useCallback, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as api from '$admin/api';
import { MatchListQueryParamsContext } from '$admin/Search/Context';
import { useDebouncedFilters } from './useDebouncedFilters';

export function useSearchMembers() {
  const [list] = useContext(MatchListQueryParamsContext);
  const filters = useDebouncedFilters();

  const fetch = useCallback(() => {
    // if (!filters.active.length && !list?.id) {
    //   return {
    //     items: [],
    //     pagination: {
    //       pageCount: 0,
    //       totalCount: 0,
    //     },
    //   };
    // }
    return api.search.searchMembers({
      active: filters.active,
      listId: list?.id,
      pageIndex: filters.pageIndex,
      pageSize: filters.pageSize,
    });
  }, [
    filters.active,
    filters.pageIndex,
    filters.pageSize,
    list?.id,
  ]);

  return useQuery([
    'admin-search-members',
    filters.active,
    filters.pageIndex,
    filters.pageSize,
    list?.id,
  ], fetch, {
    // enabled: !!filters.active.length || !!list?.id,
    enabled: true,
    placeholderData: {
      items: [],
      pagination: {
        pageCount: 0,
        totalCount: 0,
      },
    },
    refetchOnWindowFocus: false,
  });
}