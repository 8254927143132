import { useContext } from 'react';
import { X } from 'react-feather';
import { Input } from '@/components/Input';
import { NetSuiteProjects as NetSuiteAutocomplete } from '$admin/components/Autocomplete';
import { ProjectCreationSetupContext } from './Context';
import styles from './style/Frame.Setup.css';

export const NetSuiteProject = () => {
  const { form, setValue } = useContext(ProjectCreationSetupContext);

  return <NetSuiteAutocomplete
    onSelect={val => setValue({ netsuiteProject: val })}
    selected={null} />;
};