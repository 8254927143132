import { useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { path, pathname } from '@consts';
import { MedicalBackgroundEnabledStateContext, MedicalOnboardingCompletionContext, MedicalExpertiseContext, MedicalOnboardingScreensContext } from '@containers/MedicalOnboarding/Context';
import { Step, Steps } from '@containers/MedicalOnboarding/Steps';
import { Optional } from '@containers/MedicalOnboarding/utils';
import { Role } from '@screens/MedicalOnboarding.Expertise/Role';
import { Specialty } from '@screens/MedicalOnboarding.Expertise/Specialty';
/*
import { Specialties } from '@screens/MedicalOnboarding.Expertise/Specialties';
import { Subspecialties } from '@screens/MedicalOnboarding.Expertise/Subspecialties';
*/
import { Stepper } from '@/components/Stepper';

type Props = unknown;

export const Expertise = (props: Props) => {
  const history = useHistory();

  const ctx = {
    completion: useContext(MedicalOnboardingCompletionContext),
    expertise: useContext(MedicalExpertiseContext),
  };
  // const routing = {
  //   background: useContext(MedicalBackgroundEnabledStateContext),
  // };

  const { lookup, stepper } = useContext(MedicalOnboardingScreensContext);

  const next = useCallback(async () => {
    const more = stepper.remaining.some(x => Steps.expertise.includes(x));

    if (!more) {
      /*
      ctx.expertise.mutation.mutateAsync()
      .then(async () => {
      */

      if (!stepper.remaining.length) {
        await ctx.completion.mutation.mutateAsync();
      }

      history.push(stepper.route.next);
      /*
      });
      */
    } else {
      history.push(stepper.route.next);
    }
  }, [
    ctx.completion.mutation,
    /* ctx.expertise.mutation, */
    history,
    stepper.remaining,
    stepper.route.next,
  ]);

  const handleSubmitRole = useCallback(() => {
    // routing.background.setValue(ctx.expertise.professionalRole);

    ctx.expertise.mutation.role.mutateAsync().then(next);

  }, [
    ctx.expertise.mutation.role,
    //ctx.expertise.professionalRole,
    next,
    // routing.background,
  ]);

  const handleSubmitSpecialty = useCallback(() => {
    ctx.expertise.mutation.specialty.mutateAsync().then(next);
  }, [
    ctx.expertise.mutation.specialty,
    next,
  ]);

  const handleSubmitSpecialties = useCallback(() => {
    ctx.expertise.mutation.specialties.mutateAsync().then(next);
  }, [
    ctx.expertise.mutation.specialties,
    next,
  ]);

  const handleBack = useCallback(() => {
    if (stepper.context.index === 0) {
      history.push(pathname.Home);
    } else {
      history.goBack();
    }
  }, [
    history,
    stepper,
  ]);

  const specialtyIsRequired = useMemo(() => {
    return !Optional.specialtyGroup.includes(ctx.expertise.professionalRole);
  }, [
    ctx.expertise,
  ]);

  return (
    <>
      {lookup[Step.ExpertiseRole] &&
        <Stepper.Route
          exact
          path={path.Onboarding.Expertise.ProfessionalRole}>
          <Role
            button={{
              disabled: !ctx.expertise.professionalRole,
              loading: ctx.expertise.mutation.role.isLoading,
            }}
            navigation={{
              back: handleBack,
              onClick: handleSubmitRole,
            }} />
        </Stepper.Route>}

      {lookup[Step.ExpertiseSpecialty] &&
        <Stepper.Route
          exact
          path={path.Onboarding.Expertise.Specialties}>
          <Specialty
            button={{
              disabled: specialtyIsRequired ? (!ctx.expertise.specialty.primary.group?.id || !ctx.expertise.specialty.primary.subspecialty?.id) : false,
              loading: ctx.expertise.mutation.specialty.isLoading,
            }}
            navigation={{
              back: handleBack,
              onClick: handleSubmitSpecialty,
            }} />
        </Stepper.Route>}
      {/*
      {lookup[Step.ExpertiseSpecialties] &&
        <Stepper.Route
          exact
          path={path.Onboarding.Expertise.Specialties}>
          <Specialties
            button={{
              disabled: ctx.expertise.industries.length < 1,
              loading: ctx.expertise.mutation.specialties.isLoading,
            }}
            navigation={{
              back: handleBack,
              onClick: handleSubmitSpecialties,
            }} />
        </Stepper.Route>}

      {lookup[Step.ExpertiseSubspecialties] &&
        <Stepper.Route
          exact
          path={path.Onboarding.Expertise.Subspecialties}>
          <Subspecialties
            button={{
              disabled: ctx.expertise.subIndustries.length < 1,
              loading: ctx.expertise.mutation.specialties.isLoading,
            }}
            navigation={{
              back: handleBack,
              onClick: handleSubmitSpecialties,
            }} />
        </Stepper.Route>}
      */}
    </>
  );
};

Expertise.displayName = 'MedicalOnboarding.Stepper.Routes.Expertise';