import { useReducer } from 'react';
import { AudioPlayerStateContext } from './Context';
import { AudioPlayer } from './interfaces';

const initialState: AudioPlayer.State = {
  currentTime: 0,
  duration: 0,
  ended: false,
  error: null,
  loading: true,
  muted: false,
  playing: false,
  ready: false,
  stopped: true,
};

type Props =
  ChildrenProps;

export const AudioPlayerState = (props: Props) => {
  const reducer = useReducer(audioPlayerReducer, initialState);

  return (
    <AudioPlayerStateContext.Provider value={reducer}>
      {props.children}
    </AudioPlayerStateContext.Provider>
  );
};

function audioPlayerReducer(state: AudioPlayer.State, action: AudioPlayer.Actions) {

  switch (action.type) {
    case 'load-start':
      return {
        ...state,
        loading: true,
        stopped: true,
        ready: false,
        error: null,
        duration: 0,
      };

    case 'load-end':
      return {
        ...state,
        loading: false,
        ended: false,
        ready: true,
      };

    case 'duration-change':
      return {
        ...state,
        duration: action.duration,
      };

    case 'play':
      return {
        ...state,
        playing: true,
        ended: false,
        stopped: false,
      };

    case 'stop':
      return {
        ...state,
        stopped: true,
        playing: false,
      };

    case 'end':
      return {
        ...state,
        stopped: true,
        playing: false,
        ended: true,
      };

    case 'pause':
      return {
        ...state,
        playing: false,
      };

    case 'time-change':
      return {
        ...state,
        currentTime: action.time,
      };

    case 'load-error':
      return {
        ...state,
        playing: false,
        stopped: true,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }

}

export default AudioPlayerState;