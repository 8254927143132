import { useCallback } from 'react';
import * as api from '@api';
import {
  useInvalidateFilePreviewQueries,
  useInvalidateFilesQueries,
  useInvalidateFolderQueries,
} from '@utils/api';
import { WorkspaceObjectType } from '@/enums';
import { useIsInternalUser } from '@containers/Store';
import Toast from '@/components/Toast';
import { BaseObjectAccessContainer } from './BaseObjectAccessContainer';
import { ObjectAccessState } from './ObjectAccessState';
import type { ObjectAccess as OA } from './interfaces';
import { useObjectAccessState } from './hooks';

type Props =
  IWorkspaceObjectId &
  IWorkspaceId &
  ChildrenProps;

const ObjectAccessContainer = (props: Props) => {

  const invalidateFileQueries = useInvalidateFilesQueries();
  const invalidateFilePreviewQueries = useInvalidateFilePreviewQueries();
  const invalidateFolderQueries = useInvalidateFolderQueries();

  const isInternalUser = useIsInternalUser();

  const [state] = useObjectAccessState();

  const fetchAccess = useCallback(() => {
    return api.workspaces.object.getObjectAccess({
      objectId: props.objectId,
      workspaceId: props.workspaceId,
    });
  }, [
    props.objectId,
    props.workspaceId,
  ]);

  const saveAccess = useCallback((data: OA.SaveAccessParams) => {
    return api.workspaces.object.saveObjectAccess({
      items: data.items,
      objectId: props.objectId,
      workspaceId: props.workspaceId,
    }).then(res => {

      invalidateFileQueries();

      if (state.object.typeId === WorkspaceObjectType.File) {
        invalidateFilePreviewQueries({
          fileId: state.object.entityId,
        });
      } else if (state.object.typeId === WorkspaceObjectType.Folder) {
        invalidateFolderQueries({
          folderId: state.object.entityId,
        });
      }

      Toast.alert({
        title: `Access Updated`,
      });
    }).catch(e => {
      Toast.error({
        title: 'Error',
        body: `We're sorry, there was an issue updating access`,
      });
    });
  }, [
    invalidateFileQueries,
    invalidateFilePreviewQueries,
    invalidateFolderQueries,
    props.objectId,
    props.workspaceId,
    state.object,
  ]);

  const fetchGroupUsers: OA.FetchGroupUsers = useCallback(data => {
    return api.groups.getGroupUsers({
      allGroups: isInternalUser,
      ...data,
    });
  }, [isInternalUser]);

  const containerProps = {
    fetchAccess,
    fetchGroupUsers,
    saveAccess,
  };

  return (
    <BaseObjectAccessContainer {...containerProps}>
      {props.children}
    </BaseObjectAccessContainer>
  );
};

const WithState = (props: Props) => {

  return (
    <ObjectAccessState>
      <ObjectAccessContainer {...props} />
    </ObjectAccessState>
  );
};

export { WithState as ObjectAccessContainer };