import { useParams, useHistory } from 'react-router-dom';
import { path } from '@consts';
import { useSubmitUpdatedArticlePostMutation } from '@containers/Post.Article.Form/hooks';
import { ButtonActivityIndicator } from '@presentation';
import { useCanPublishArticle } from '@/components/Remirror/hooks';
import styles from './style/Toolbar.css';

export const UpdateButton = (props: unknown) => {
  const params = useParams<{ postId: string; }>();
  const history = useHistory();
  const enabled = useCanPublishArticle();

  const mutation = useSubmitUpdatedArticlePostMutation({
    onError: e => {
      console.log('Error');
      console.log(e);
    },
    onSuccess: res => {
      history.push(`${path.Posts.Articles.Root}/${params.postId}`);
    },
  });

  return (
    <div className={styles.publish}>
      <ButtonActivityIndicator
        classes={{ root: styles.submit }}
        disabled={mutation.isSuccess || !enabled}
        implicitDisable={mutation.isLoading}
        indicatorSize={8}
        loading={mutation.isLoading}
        onClick={() => mutation.mutate()}>
        Save
      </ButtonActivityIndicator>
    </div>
  );
};

UpdateButton.displayName = 'Post.Edit.Toolbar.UpdateButton';