import type { ChangeEvent, InputHTMLAttributes } from 'react';
import { HTMLInputTypeAttribute, useCallback } from 'react';
import { Search, X } from 'react-feather';
import { cx } from '@utils';
import styles from './styles/SearchInput.css';

type Props = {
  value: string;
  onChange: (val: string) => void;
  onClear?: () => void;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

export const SearchInput = ({ value, onChange, onClear, className, ...rest }: Props) => {
  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  }, [onChange]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.left}>
          <Search
            className={styles.icon}
            size={22} />
          <input
            className={cx(styles.input, className)}
            autoComplete="off"
            {...rest}
            type="text"
            onChange={handleChange}
            value={value} />
        </div>
        <X
          className={styles.x}
          onClick={onClear ?? (() => onChange(''))}
          size={22} />
      </div>
    </div>
  );
};