import { useContext } from 'react';
import { useQueryParams, NumberParam } from 'use-query-params';
import { CanUploadFilesContext } from '@/containers/WorkspaceFileUpload/Context';
import { FilesTable } from '@/components/Workspace.FilesTable';
import {
  FilesTableContext,
  FilesTableDataContext,
  SetFilesTableFiltersContext,
  FilesTableFiltersContext,
  FilesTableLoadingContext,
  SelectedObjectsContainer,
} from '@/components/Workspace.FilesTable';
import { useFetchSharedObjects } from '@utils/api';
import { TabViewTableSkeleton } from '@/components/TabView';
import styles from './style/Workspaces.css';

const useParams = () => {
  return useQueryParams({

  });
};

const SharedTabFiles = () => {

  const loading = useContext(FilesTableLoadingContext);

  return (
    <div className={styles.tableWrap}>
      {loading
        ? <TabViewTableSkeleton />
        : <FilesTable />
      }
    </div>
  );
};

const SharedWorkspaceFilesContainer = (props: ChildrenProps) => {

  const [params, setQueryParams] = useParams();

  const query = useFetchSharedObjects({
    parentObjectId: null,
  }, {
    refetchOnWindowFocus: false,
  });

  return (
    <SelectedObjectsContainer>
      <FilesTableDataContext.Provider value={query.data?.items || []}>
        <FilesTableLoadingContext.Provider value={query.isLoading}>
          <FilesTableFiltersContext.Provider value={params}>
            <SetFilesTableFiltersContext.Provider value={setQueryParams}>
              <FilesTableContext.Provider value={{ parentObject: null }}>
                <CanUploadFilesContext.Provider value={false}>
                  {props.children}
                </CanUploadFilesContext.Provider>
              </FilesTableContext.Provider>
            </SetFilesTableFiltersContext.Provider>
          </FilesTableFiltersContext.Provider>
        </FilesTableLoadingContext.Provider>
      </FilesTableDataContext.Provider>
    </SelectedObjectsContainer>
  );
};

const WithState = () => {

  return (
    <SharedWorkspaceFilesContainer>
      <SharedTabFiles />
    </SharedWorkspaceFilesContainer>
  );
};

export { WithState as SharedTabFiles };
export default WithState;