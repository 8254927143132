import { useCallback } from 'react';
import { createId } from '@paralleldrive/cuid2';
import type { Chat } from '@/brand-insights/types';
import {
  SubmitActionContext,
} from './context';
import { useSelectedQueryParentIdentifier, useSubmitQueryMutation } from './hooks';

type Props = {
  children: React.ReactNode;
};

export const SubmitChatActionContainer = (props: Props) => {

  const mutation = useSubmitQueryMutation();
  const getSelectedQueryParentIdentifier = useSelectedQueryParentIdentifier();

  const handleSubmit = useCallback((action: Chat.Actions.Value, query: string) => {
    const identifier = createId();
    const parentQueryIdentifier = getSelectedQueryParentIdentifier();

    return mutation.mutateAsync({
      action,
      identifier,
      parentQueryIdentifier,
      query,
    });
  }, [getSelectedQueryParentIdentifier, mutation]);

  return (
    <SubmitActionContext.Provider value={handleSubmit}>
      {props.children}
    </SubmitActionContext.Provider>
  );
};