import { memo } from 'react';
import { Clock, XCircle } from 'react-feather';
import { CallRating } from '@/components/CallRating';

type Props = {
  className?: string;
  skipped: boolean;
  notes: string;
  rating: number | null;
};

const Rating = memo(({
  className,
  rating,
  skipped,
  notes,
}: Props) => {
  if (skipped === true) {
    return <XCircle className={className} />;
  } else if (rating === null) {
    return <Clock className={className} />;
  } else {
    return (
      <CallRating rating={rating} notes={notes} />
    );
  }
});

export { Rating };
export default Rating;