import type { SurveyQuestion, SurveyQuestionRowMetadata, SurveyQuestionMatrixRow } from '@/types';
import { hasRows } from '@/containers/Survey/utils/questions';
import type { SurveyQuestionsBuilder, SurveyRowsBuilder } from '../interfaces';

export function isAnchorUpdated(state: SurveyQuestionsBuilder.State, payload: SurveyRowsBuilder.RowIsAnchorUpdated.Payload): SurveyQuestionsBuilder.State {

  return updateMetadata<{ isAnchored: boolean }>({
    rowIdentifier: payload.row.identifier,
    questionIdentifier: payload.questionIdentifier,
    state,
    metadata: {
      isAnchored: payload.value,
    },
  });
}

export function isLastRowInGridUpdated(state: SurveyQuestionsBuilder.State, payload: SurveyRowsBuilder.RowIsLastRowInGridUpdated.Payload): SurveyQuestionsBuilder.State {
  return updateMetadata<{ isLastRowInCurrentGrid: boolean }>({
    rowIdentifier: payload.row.identifier,
    questionIdentifier: payload.questionIdentifier,
    state,
    metadata: {
      isLastRowInCurrentGrid: payload.value,
    },
  });
}

export function isOpenEndedUpdated(state: SurveyQuestionsBuilder.State, payload: SurveyRowsBuilder.RowIsOpenEndedUpdated.Payload): SurveyQuestionsBuilder.State {
  return updateMetadata<{ isOpenEnded: boolean }>({
    rowIdentifier: payload.row.identifier,
    questionIdentifier: payload.questionIdentifier,
    state,
    metadata: {
      isOpenEnded: payload.value,
    },
  });
}

type UpdateMetadata<T extends Record<string, unknown>> = {
  questionIdentifier: string;
  rowIdentifier: string;
  metadata: Partial<SurveyQuestionRowMetadata<T>>;
  state: SurveyQuestionsBuilder.State;
};

function updateMetadata<T extends Record<string, unknown>>({
  rowIdentifier,
  questionIdentifier,
  metadata,
  state,
}: UpdateMetadata<T>) {

  return state.reduce((acc, q: SurveyQuestion) => {
    if (q.base.identifier === questionIdentifier && hasRows(q)) {
      const matrixRows = (q.matrixRows as SurveyQuestionMatrixRow[]).reduce((acc2, o) => {
        if (o.base.identifier === rowIdentifier) {
          return acc2.concat({
            ...o,
            metadata: {
              ...o.metadata,
              ...metadata,
            },
          });
        }
        return acc2.concat(o);
      }, []);
      return acc.concat({ ...q, matrixRows });
    }
    return acc.concat(q);
  }, []);
}