import { useCallback, useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { AppReadyStateContext, ReadyStateSubscriberContext, ReadyStateSubscriptionContext } from './Context';
import type { ReadyStateChangeHandler, ReadyStateRun, ReadyStateSubscriber } from './interfaces';
import { ReadyState } from './interfaces';

const mapState = (state: Store.State) => ({
  authenticated: state.user.state.authenticated,
  error: state.user.state.error,
  hydrated: !!state.user.id,
  hydrating: state.user.state.authenticated && !state.user.id,
  initialized: state.user.state.initialized,
});

type Props = {
  children: React.ReactElement;
};

const SubscriberContainer = (props: Props) => {
  const state = useSelector(mapState);
  const authenticated = useRef(state.authenticated);
  const error = useRef(state.error);
  const initialized = useRef(state.initialized);
  const hydrated = useRef(state.hydrated);
  const run = useContext(ReadyStateSubscriberContext);

  useEffect(() => {

    if (state.error) {
      run(ReadyState.Error);
    } else {
      if (!authenticated.current && state.authenticated) {
        run(ReadyState.UserSignedIn);
      }

      if (!initialized.current && state.initialized && !state.hydrated) {
        run(ReadyState.NoCredentialsFound);
      }

      if (initialized.current && authenticated.current && !state.authenticated) {
        run(ReadyState.UserSignedOut);
      }

      if (!hydrated.current && state.hydrated) {
        run(ReadyState.AppDataLoaded);
      }

      if (hydrated.current && !state.hydrated) {
        run(ReadyState.AppDataReset);
      }
    }

    authenticated.current = state.authenticated;
    error.current = state.error;
    hydrated.current = state.hydrated;
    initialized.current = state.initialized;

  }, [
    run,
    state,
  ]);

  return (
    <AppReadyStateContext.Provider value={state}>
      {props.children}
    </AppReadyStateContext.Provider>
  );
};

SubscriberContainer.displayName = 'AppReadyState.Subscriber';

const SubscriptionContainer = (props: Props) => {
  const handlers = useRef({
    [ReadyState.AppDataLoaded]: new Set<ReadyStateChangeHandler>(),
    [ReadyState.AppDataReset]: new Set<ReadyStateChangeHandler>(),
    [ReadyState.Error]: new Set<ReadyStateChangeHandler>(),
    [ReadyState.NoCredentialsFound]: new Set<ReadyStateChangeHandler>(),
    [ReadyState.UserSignedIn]: new Set<ReadyStateChangeHandler>(),
    [ReadyState.UserSignedOut]: new Set<ReadyStateChangeHandler>(),
  }).current;

  const run = useCallback<ReadyStateRun>(state => {
    handlers[state].forEach(fn => fn());
  }, [handlers]);

  const on = useCallback<ReadyStateSubscriber>((state, fn) => {
    handlers[state].add(fn);
  }, [handlers]);

  const off = useCallback<ReadyStateSubscriber>((state, fn) => {
    handlers[state].delete(fn);
  }, [handlers]);

  return (
    <ReadyStateSubscriptionContext.Provider value={{ off, on }}>
      <ReadyStateSubscriberContext.Provider value={run}>
        {props.children}
      </ReadyStateSubscriberContext.Provider>
    </ReadyStateSubscriptionContext.Provider>
  );
};

SubscriptionContainer.displayName = 'AppReadyState.Subscription';

export const AppReadyStateContainer = (props: Props) => {
  return (
    <SubscriptionContainer>
      <SubscriberContainer>
        {props.children}
      </SubscriberContainer>
    </SubscriptionContainer>
  );
};