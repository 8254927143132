import { memo } from 'react';
import { cx } from '@utils';
import styles from './style/Profile.Placeholder.css';

type Props = {
  disabled?: boolean;
  onClick: () => unknown;
  text: string;
};

export const ProfileSectionPlaceholder = memo(({ disabled, onClick, text }: Props) => {
  const className = cx(styles.root, {
    [styles.disabled]: disabled,
  });

  return (
    <div className={className} onClick={disabled ? undefined : onClick}>
      {text}
    </div>
  );
});

export default ProfileSectionPlaceholder;