import { memo } from 'react';

type Props = {
  className?: string;
  size?: number;
};

export const ChatIcon = memo(({ className, size = 40 }: Props) => {

  return (
    <svg
      className={className}
      width={size}
      height={size}
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 32 32"
      enableBackground="new 0 0 32 32"
      xmlSpace="preserve">
      <path
id="text--to--speech_1_"
d="M12,17.5c0,0.552-0.448,1-1,1s-1-0.448-1-1s0.448-1,1-1S12,16.948,12,17.5z M15,16.5
      c-0.552,0-1,0.448-1,1s0.448,1,1,1s1-0.448,1-1S15.552,16.5,15,16.5z M7,16.5c-0.552,0-1,0.448-1,1s0.448,1,1,1s1-0.448,1-1
      S7.552,16.5,7,16.5z M12.701,31.199L8.807,25.36H3c-1.301,0-2.36-1.059-2.36-2.36V12c0-1.301,1.059-2.36,2.36-2.36h15.64V1
      c0-0.199,0.161-0.36,0.36-0.36h12c0.199,0,0.36,0.161,0.36,0.36v14c0,0.199-0.161,0.36-0.36,0.36h-9.64V23
      c0,1.302-1.059,2.36-2.36,2.36h-6v-0.72h6c0.904,0,1.64-0.735,1.64-1.64V12c0-0.904-0.735-1.64-1.64-1.64H3
      c-0.904,0-1.64,0.736-1.64,1.64v11c0,0.904,0.736,1.64,1.64,1.64h6c0.121,0,0.233,0.061,0.299,0.161l4,6L12.701,31.199z
       M21.36,14.64h9.279V1.36H19.36v8.308c0.689,0.106,1.282,0.512,1.64,1.081V10.64l8,0.002v0.72l-7.729-0.002
      c0.058,0.204,0.089,0.418,0.089,0.64V14.64z M25.573,28.083l-2.828-2.828c-0.141-0.141-0.141-0.369,0-0.51l2.828-2.829l0.51,0.51
      l-2.214,2.214h3.132c0.903,0,1.639-0.735,1.639-1.64v-4h0.721v4c0,1.302-1.059,2.36-2.359,2.36H23.87l2.214,2.213L25.573,28.083z
       M29,8.36h-8V7.64h8V8.36z M29,5.36h-8V4.64h8V5.36z" />
      <rect
        fill="none"
        id="_Transparent_Rectangle"
        width="32"
        height="32" />
    </svg>
  );
});