import { useMemo } from 'react';
import type { DraggableStateSnapshot, DraggableProvided } from 'react-beautiful-dnd';
import { Draggable } from 'react-beautiful-dnd';
import { cx } from '@utils';
import type { RankingQuestion } from '@/types';
import { RankingListItem } from './Ranking.ListItem';
import styles from './style/Ranking.ListItem.css';
import { useDraggableInPortal } from './hooks/useDraggableInPortal';
import { useRankingOptionOpenEndedValue } from './hooks/useRankingOpenEndedValue';
import type { RankingOrdinalDropdownItem } from './interfaces';

type Props = {
  className?: string;
  dragDisabled?: boolean;
  index: number;
  item: RankingQuestion.FormOption;
  ordinalItems: number[];
  onOrdinalSelect: (item: RankingOrdinalDropdownItem) => void;
  rankingDisabled: boolean;
};

export const DraggableRankingListItem = ({ index, rankingDisabled: rankingDisabledProp, ...props }: Props) => {
  const renderDraggable = useDraggableInPortal();
  const [openEndedValue, setOpenEndedValue] = useRankingOptionOpenEndedValue(props.item.id);

  const rankingDisabled = useMemo(() => {
    return rankingDisabledProp;
    // return (props.item.metadata.isOpenEnded && !openEndedValue) || rankingDisabledProp;
  }, [
    //openEndedValue,
    //props.item.metadata.isOpenEnded,
    rankingDisabledProp,
  ]);

  const dragDisabled = useMemo(() => {
    return props.dragDisabled || rankingDisabled;
  }, [props.dragDisabled, rankingDisabled]);

  return (
    <Draggable
      draggableId={props.item.id.toString()}
      isDragDisabled={dragDisabled}
      index={index}>
      {renderDraggable((provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}>
          <RankingListItem
            {...props}
            className={cx({
              [styles.dragging]: snapshot.isDragging,
            })}
            openEndedValue={openEndedValue}
            onUpdateOpenEndedValue={setOpenEndedValue}
            dragDisabled={dragDisabled}
            rankingDisabled={rankingDisabled} />
        </div>
      ))}
    </Draggable>
  );
};