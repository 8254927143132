import { useCallback, useContext } from 'react';
import { MedicalBackgroundContext, MedicalBackgroundIdentificationContext } from '@containers/MedicalOnboarding/Context';
import * as enums from '@enums';
import { cx } from '@utils';
import { Select } from '$admin/components/Select';
import styles from './style/Member.MedicalProfile.css';

type Props = {
  classes?: {
    root?: string;
    placeholder?: string;
  };
};

export const HCPType = ({ classes = {}, ...props }: Props) => {
  const ctx = useContext(MedicalBackgroundContext);
  const identification = useContext(MedicalBackgroundIdentificationContext);

  const handleChange = useCallback((value: enums.HCPType) => {
    ctx.mutation.mutate({
      identification: {
        ...identification.form,
        hcpType: value,
      },
    }, {
      onSuccess: identification.rehydrate,
    });

    identification.setValue({
      hcpType: value,
    });
  }, [
    ctx.mutation,
    identification,
  ]);

  const classnames = {
    root: cx(styles.input, classes.root, {
      [classes.placeholder || styles.placeholder]: !identification.form.hcpType,
    }),
  };

  const value = identification.form.hcpType ?? '';

  return (
    <Select
      classes={classnames}
      options={OptionsMap}
      onChange={e => handleChange(+e.target.value)}
      placeholder="N/A"
      value={value as number} />
  );
};

HCPType.displayName = 'Member.Experience.Input.HCPType';

type Lookup<T extends enums.HCPType = enums.HCPType> = {
  [Key in T]: keyof typeof enums.HCPType;
};

const OptionsMap = {
  ...enums.utils.HCPType.values().reduce<Lookup>((acc, x) => ({
    ...acc,
    [x]: enums.utils.HCPType.getName(x),
  }), {} as Lookup),
};