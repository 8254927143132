import { useCallback, useState } from 'react';
import { usePopper } from 'react-popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { useZIndexModifier } from '@/components/Popper';
import { useLinkPositioner, useRemoveLink } from './hooks';
import { useLinkModalContext, useLinkPopperContext } from './Context';
import { LinkExtensionPopper } from './Link.Popper';
import { FloatingWrapper } from './FloatingWrapper';

export const LinkPopperContainer = (props: ChildrenProps) => {

  const {
    open: openModal,
  } = useLinkModalContext();
  const {
    selection: popperSelection,
    close: closePopper,
  } = useLinkPopperContext();

  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);

  const handleRemove = useRemoveLink();

  const position = useLinkPositioner();
  const zIndexModifier = useZIndexModifier({ zIndex: 10 });
  const { styles: popperStyles, attributes: popperAttributes } = usePopper(position.element, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 5],
        },
      },
      zIndexModifier,
    ],
    placement: 'bottom-start',
  });

  const handleCancel = useCallback(() => {
    closePopper();
  }, [closePopper]);

  const handleEdit = useCallback(() => {
    openModal(popperSelection);
    closePopper();
  }, [closePopper, openModal, popperSelection]);

  return (
    <>
      <FloatingWrapper
        renderOutsideEditor={true}
        position={position}>
        <div
          ref={setPopperElement}
          style={popperStyles.popper}
          {...popperAttributes.popper}>
          {position.active &&
            <ClickAwayListener onClickAway={closePopper}>
              <LinkExtensionPopper
                key={popperSelection?.text}
                href={popperSelection?.href}
                text={popperSelection?.text}
                onCancel={handleCancel}
                onClickEdit={handleEdit}
                onRemove={handleRemove} />
            </ClickAwayListener>
          }
        </div>
      </FloatingWrapper>
      {props.children}
    </>
  );
};