import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import {
  ExternalSurveyConfigurationMutationsContext,
  DownloadExternalSurveyLinksContext,
} from '@containers/Project.ExternalSurvey';
import { downloadExternalProjectLinks } from '$admin/api/reports';
import { external as $external } from '$admin/api/projects';

type Props =
  ChildrenProps &
  IProjectId;

export const AdminOffPlatformSurveyMutationContainer = ({ projectId, ...props }: Props) => {

  const deleteUnassignedLinksMutation = useMutation(['admin.delete-unassigned-external-links'], () => {
    return $external.deleteUnassignedExternalProjectLinks({ projectId });
  });

  const handleDownload = useCallback(() => downloadExternalProjectLinks({ projectId }), [projectId]);

  const importLinksMutation = useMutation(['admin.import-external-project-links'], (params: { file: File; fixed?: boolean }) => {
    return $external.importExternalProjectLinks(projectId, params.file, params.fixed);
  });

  const importSingleLinkMutation = useMutation(['admin.import-single-external-project-link'], (link: string) => {
    return $external.importSingleExternalProjectLink({
      link,
      projectId,
    });
  });

  const deleteProjectConfigMutation = useMutation(() => {
    return $external.deleteExternalProjectConfiguration({ projectId });
  });

  const updateProjectConfigMutation = useMutation([
    'patch:admin/projects/external-configuration',
    projectId,
  ], (params: { fixedLinks: boolean }) => {
    return $external.updateExternalProjectConfiguration({
      projectId,
      ...params,
    });
  });

  const mutations = {
    deleteProjectConfigMutation,
    deleteUnassignedLinksMutation,
    importSingleLinkMutation,
    importLinksMutation,
    updateProjectConfigMutation,
  };

  return (
    <DownloadExternalSurveyLinksContext.Provider value={handleDownload}>
      <ExternalSurveyConfigurationMutationsContext.Provider value={mutations}>
        {props.children}
      </ExternalSurveyConfigurationMutationsContext.Provider>
    </DownloadExternalSurveyLinksContext.Provider>
  );
};