import { formatWithOrdinalSuffix, formatNumber, formatPlurality, roundToPlace } from '@utils';
import type {
  MatrixChartTooltipProps,
  OptionsBarChartTooltipProps,
  RankingBarChartTooltipProps,
  MatrixSliderChartTooltipProps,
  MaxDiffChartTooltipProps,
} from './interfaces';
import { TooltipWrapper } from './Tooltip.Wrapper';
import styles from './style/Tooltip.css';

function formatRankedText(value: number | string) {
  return value === 0
    ? 'N/A'
    : `Ranked ${formatWithOrdinalSuffix(+value)}`;
}

function formatCountText(count: number) {
  return `${count} ${formatPlurality('response', count)}`;
}

function formatPctText(count: number, pct: number) {
  return count > 0
    ? `${pct}% of total`
    : `0% of total`;
}

export const OptionsBarChartTooltip = (props: OptionsBarChartTooltipProps) => {
  return (
    <TooltipWrapper>
      <div className={styles.header}>{props.name}</div>
      <div className={styles.stat}>{formatCountText(props.count)}</div>
      <div className={styles.stat}>{formatPctText(props.count, props.percent)}</div>
    </TooltipWrapper>
  );
};

export const RankingBarChartTooltip = (props: RankingBarChartTooltipProps) => {
  return (
    <TooltipWrapper>
      <div className={styles.header}>{props.name}</div>
      <div className={styles.subtitle}>{formatRankedText(props.ordinal)}</div>
      <div className={styles.stat}>{formatCountText(props.count)}</div>
      <div className={styles.stat}>{formatPctText(props.count, props.percent)}</div>
    </TooltipWrapper>
  );
};

export const MatrixChartTooltip = (props: MatrixChartTooltipProps) => {
  return (
    <TooltipWrapper>
      <div className={styles.header}>{props.rowName}</div>
      <div className={styles.subtitle}>{props.optionName}</div>
      <div className={styles.stat}>{formatCountText(props.count)}</div>
      {props.percent !== undefined &&
        <div className={styles.stat}>{formatPctText(props.count, props.percent)}</div>}
    </TooltipWrapper>
  );
};

export const MatrixSliderChartTooltip = (props: MatrixSliderChartTooltipProps) => {
  return (
    <TooltipWrapper>
      <div className={styles.header}>{props.row}</div>
      <div style={{ marginTop: 5 }}>
        Avg: {props.avg}
      </div>
    </TooltipWrapper>
  );
};

type NumberInputTableProps = {
  avg: string;
  option: string;
  row: string;
};

export function NumberInputTableChartTooltip(props: NumberInputTableProps) {
  return (
    <TooltipWrapper>
      <div className={styles.header}>{props.row}</div>
      <div className={styles.subtitle}>{props.option}</div>
      <div style={{ marginTop: 5 }}>
        Avg: {props.avg}
      </div>
    </TooltipWrapper>
  );
}

export function MaxDiffChartTooltip({ appeared, choice, name, value }: MaxDiffChartTooltipProps) {
  const choiceLabel = MaxDiffTooltipChoiceLabel({ choice, value });

  return (
    <TooltipWrapper>
      <div className={styles.header}>{name}</div>
      <div className={styles.stat}>{choiceLabel}</div>
      <div className={styles.stat}>Appeared: {appeared} {formatPlurality('time', appeared)}</div>
    </TooltipWrapper>
  );
}

function MaxDiffTooltipChoiceLabel({ choice, value }: Pick<MaxDiffChartTooltipProps, 'choice' | 'value'>) {
  switch (choice) {
    case 'least': return `Least: ${value} ${formatPlurality('time', value)}`;
    case 'most': return `Most: ${value} ${formatPlurality('time', value)}`;
    case 'nc': return `Not Selected: ${value} ${formatPlurality('time', value)}`;
  }
}