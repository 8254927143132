import { useCallback, useMemo } from 'react';
import * as api from '@api';
import { TranscriptCommentActionsContext } from '@containers/Transcript.Commenting/Context';
import type { RemoveComment, SaveComment, UpdateComment } from '@containers/Transcript.Commenting/interfaces';
import { TranscriptHighlightActionsContext } from './context';
import type { Transcript } from './interfaces';

type Props = {
  children: React.ReactNode;
} & ITranscriptId;

export const TranscriptHighlightActionsContainer = ({ transcriptId, ...props }: Props) => {

  const saveHighlight: Transcript.SaveHighlight = useCallback(({ highlight }) => {
    return api.transcripts.saveTranscriptHighlight({
      highlight,
      transcriptId,
    }).then(res => res.highlight);
  }, [
    transcriptId,
  ]);

  const removeHighlight: Transcript.RemoveHighlight = useCallback(({ highlight }) => {
    return api.transcripts.removeTranscriptHighlight({
      highlightId: highlight.dbId,
      transcriptId,
    });
  }, [
    transcriptId,
  ]);

  const removeComment: RemoveComment = useCallback(({ commentId }) => {
    return api.transcripts.removeTranscriptComment({
      commentId,
      transcriptId,
    });
  }, [
    transcriptId,
  ]);

  const updateComment: UpdateComment = useCallback(({ commentId, value }) => {
    return api.transcripts.updateTranscriptComment({
      commentId,
      transcriptId,
      value,
    });
  }, [
    transcriptId,
  ]);

  const saveComment: SaveComment = useCallback(({ comment }) => {
    return api.transcripts.saveTranscriptComment({
      comment,
      transcriptId,
    });
  }, [
    transcriptId,
  ]);

  const highlightActions = useMemo(() => {
    return {
      removeHighlight,
      saveHighlight,
    };
  }, [removeHighlight, saveHighlight]);

  const commentActions = useMemo(() => {
    return {
      removeComment,
      saveComment,
      updateComment,
    };
  }, [removeComment, saveComment, updateComment]);

  return (
    <TranscriptHighlightActionsContext.Provider value={highlightActions}>
      <TranscriptCommentActionsContext.Provider value={commentActions}>
        {props.children}
      </TranscriptCommentActionsContext.Provider>
    </TranscriptHighlightActionsContext.Provider>
  );
};

TranscriptHighlightActionsContainer.displayName = 'Transcript.Highlight.Actions.Container';