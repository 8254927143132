import { useState } from 'react';
import { CSVFileContext } from './Context';
import { CSVQueriesContainer } from './CSV.Queries.Container';
import { CSVFieldMappingContainer } from './CSV.FieldMapping.Container';

type Props = {
  children: React.ReactNode;
};

export const CSVContainer = (props: Props) => {
  const [file, setFile] = useState<File>(null);

  return (
    <CSVFileContext.Provider value={[file, setFile]}>
      <CSVFieldMappingContainer>
        <CSVQueriesContainer file={file}>
          {props.children}
        </CSVQueriesContainer>
      </CSVFieldMappingContainer>
    </CSVFileContext.Provider>
  );
};

CSVContainer.displayName = 'Group.Contacts.Import.CSV.Container';