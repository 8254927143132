import { useCallback, useContext } from 'react';
import { RemoveLeadsContext } from './containers';
import styles from './styles/Panel.css';

type Props = {
  children:  React.ReactNode;
  disabled?: boolean;
  onClose:   () => void;
};

export const Remove = ({ onClose, ...props }: Props) => {

  const { toggle } = useContext(RemoveLeadsContext);

  const handleClick = useCallback(() => {
    onClose();
    toggle();
  }, [
    onClose,
    toggle,
  ]);

  return (
    <div>
      <button
        className={styles.btn}
        disabled={props.disabled}
        onClick={handleClick}>
        {props.children}
      </button>
    </div>
  );
};

Remove.displayName = 'Leads.Toolbar.Action.Remove';