import { AxiosResponse } from 'axios';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import * as api from '$admin/api/transcripts';
import { GetTranscriptMedia } from '$admin/api/interfaces/transcripts';

type Props = {
  transcriptId: number;
};

export const useTranscriptMedia = (props: Props, options: Options = {}) => {

  const query = useQuery(['admin.transcript-media', props], ({ queryKey }) => {
    const params = queryKey[1];
    return api.getTranscriptMedia(params);
  }, options);

  return query;
};

type Data = GetTranscriptMedia.Response;
type QK = readonly [string, Props];

type Options = UseQueryOptions<Data, AxiosResponse, Data, QK>;