import { CallRole } from '@enums';
import * as API from '$admin/api/interfaces';
import styles from './style/Confirmation.css';

type Props = {
  items: API.Projects.Scheduling.ScheduleCall.Response['participants'];
};

export const Participants = (props: Props) => {
  const participants = (props.items || [])
    .filter(x => !(x.offPlatform && x.roleId === CallRole.PrimaryRespondent))
    .map(x => {
      return x.offPlatform
        ? `(${x.email})`
        : `${x.name} (${x.email})`;
    })
    .join(', ') || '';

  return (
    <div className={styles.participants}>
      {participants}
    </div>
  );
};

Participants.displayName = 'Confirmation.Details.Participants';