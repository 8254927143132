import { SurveyTaggingBuilderState } from './State';
import { SurveyTaggingItemValidationContainer } from './ValidationContainer';
import { TaggingItemBuilder } from './interfaces';

type Props = {
  initial: TaggingItemBuilder.State;
} & ChildrenProps;

export const SurveyTaggingItemContainer = ({ children, initial }: Props) => {

  return (
    <SurveyTaggingBuilderState initial={initial}>
      <SurveyTaggingItemValidationContainer>
        {children}
      </SurveyTaggingItemValidationContainer>
    </SurveyTaggingBuilderState>
  );
};