import extend from '@enums/extend';

export enum StripePayoutConnectTransferStatus {
  Pending = 1,
  InProgress = 2,
  Completed = 3,
  Failed = 4,
}

const map = new Map([
  [StripePayoutConnectTransferStatus.Pending,    { name: 'Pending' }],
  [StripePayoutConnectTransferStatus.InProgress, { name: 'In Progress' }],
  [StripePayoutConnectTransferStatus.Completed,  { name: 'Completed' }],
  [StripePayoutConnectTransferStatus.Failed,     { name: 'Failed' }],
]);

export default {
  StripePayoutConnectTransferStatus: extend(map),
};