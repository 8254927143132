import cuid from 'cuid';
import {
  SurveyActionType,
  SurveyQuestionConditionType,
  SurveyOptionType,
} from '@enums/Survey';
import {
  TargetProductProfileQuestion,
  TargetProductProfileSection,
  SurveyTemplateType,
  TargetProductProfileMessage,
  SurveyTemplateEntityType,
} from '@enums/survey.template';
import type { SurveyTemplate } from '@/types/survey.templates';
import type {
  SurveyQuestion,
  SurveyBuilderVersion,
  SurveySection,
  SurveyItem,
  SurveyQuestionOption,
  SurveyRichText,
  ExclusiveOptionsQuestion,
  SurveyLogic,
  MatrixGridQuestion,
} from '@/types/survey';
import {
  SurveyQuestionNumberType,
} from '@/types/survey';
import { Remirror } from '@/types';
import * as $question from '../utils/question';
import * as $template from './utils';

const defaultTemplateData: SurveyTemplate.TargetProductProfile = {
  additionalPriceLinks: {
    canada: null,
    france: null,
    germany: null,
    italy: null,
    spain: null,
    uk: null,
  },
  client: '',
  doseAdministration: $template.generateInitialTextItems(5),
  drug: $template.generateLinkedEntity(),
  drugIndication: '',
  efficacyEndpoints: {
    primary: $template.generateInitialTextItems(1),
    secondary: $template.generateInitialTextItems(3),
  },
  drugPrice: {
    currency: 'USD',
    low: null,
    medium: null,
    high: null,
  },
  launchYear: null,
  medicalCondition: $template.generateLinkedEntity(),
  otherAttributes: $template.generateInitialTextItems(1),
  qualityOfLife: '',
  safetyEndpoints: $template.generateInitialTextItems(5),
  stimulus: $template.generateRichTextNode([$template.generateParagraphNode()]),
  treatmentCategory: '',
  trialDesign: '',
  treatmentComparators: $template.generateInitialTextItems(5),
  pricingComparators: $template.generateInitialTextItems(5),
  tppUrl: null,
};

const defaultTemplateDataWithValues: SurveyTemplate.TargetProductProfile = {
  additionalPriceLinks: {
    canada: null,
    france: null,
    germany: null,
    italy: null,
    spain: null,
    uk: null,
  },
  client: 'Client',
  doseAdministration: $template.generateInitialTextItems(5, 'Dose Administration'),
  treatmentComparators: $template.generateInitialTextItems(5, 'Treatment Comparator'),
  pricingComparators: $template.generateInitialTextItems(5, 'Pricing Comparator'),
  drug: $template.generateLinkedEntity('Drug'),
  drugIndication: 'Drug Indication',
  drugPrice: {
    currency: 'USD',
    low: 1000,
    medium: 5000,
    high: 10000,
  },
  efficacyEndpoints: {
    primary: $template.generateInitialTextItems(1, 'Primary Efficacy Endpoint'),
    secondary: $template.generateInitialTextItems(3, 'Secondary Efficacy Endpoint'),
  },
  launchYear: 2024,
  medicalCondition: $template.generateLinkedEntity('Medical Condition'),
  otherAttributes: $template.generateInitialTextItems(1, 'Other Attribute'),
  safetyEndpoints: $template.generateInitialTextItems(5, 'Safety Endpoint'),
  qualityOfLife: 'Quality of Life',
  stimulus: $template.generateRichTextNode([$template.generateParagraphNode([
    $template.generateTextNode('Here is a stimulus'),
  ])]),
  tppUrl: null,
  treatmentCategory: 'Drug Treatment Category',
  trialDesign: 'Trial Design',
};

export function generateDefaultTemplateData(withValues: boolean) {
  return withValues ? defaultTemplateDataWithValues : defaultTemplateData;
}

function generateSections(data: SurveyTemplate.TargetProductProfile) {
  const introSection = $template.generateSection({
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: TargetProductProfileSection.Intro },
    },
    name: 'Intro',
  });

  const euScreenerSection = $template.generateSection({
    hidden: true,
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: TargetProductProfileSection.EUScreener },
    },
    name: 'EU Screener',
  });

  const nonEUScreenerSection = $template.generateSection({
    hidden: true,
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: TargetProductProfileSection.NonEUScreener },
    },
    name: 'Non-EU Screener',
  });

  const unmetNeedsSection = $template.generateSection({
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: TargetProductProfileSection.UnmetNeeds },
    },
    name: 'Unmet Needs',
  });

  const tppAssessmentSection = $template.generateSection({
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: TargetProductProfileSection.TPPAssessment },
    },
    name: 'TPP Assessment',
  });

  const clinicalComparatorsSection = $template.generateSection({
    hidden: true,
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: TargetProductProfileSection.ClinicalComparators },
    },
    name: 'Clinical Comparators',
  });

  const usSection = $template.generateSection({
    hidden: true,
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: TargetProductProfileSection.USPricing },
    },
    name: 'US Access & Pricing',
  });

  const franceSection = $template.generateSection({
    hidden: true,
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: TargetProductProfileSection.FrancePricing },
    },
    name: 'France',
  });

  const germanySection = $template.generateSection({
    hidden: true,
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: TargetProductProfileSection.GermanyPricing },
    },
    name: 'Germany',
  });

  const spainSection = $template.generateSection({
    hidden: true,
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: TargetProductProfileSection.SpainPricing },
    },
    name: 'Spain',
  });

  const italySection = $template.generateSection({
    hidden: true,
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: TargetProductProfileSection.ItalyPricing },
    },
    name: 'Italy',
  });

  const ukSection = $template.generateSection({
    hidden: true,
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: TargetProductProfileSection.UKPricing },
    },
    name: 'UK',
  });

  const euSection = $template.generateSection({
    hidden: true,
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: TargetProductProfileSection.EUPricing },
    },
    name: 'EU Access & Pricing',
  });

  const euPricingComparatorSections = data.pricingComparators.map(generateEuPricingComparatorSection);
  const euPricingComparatorOtherSection = generateEuPricingComparatorSection({
    id: null,
    value: 'Other',
  });

  const gdprControllerSection = $template.generateSection({
    hidden: true,
    metadata: {
      canAddQuestion: false,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: TargetProductProfileSection.GDPRDataController },
    },
    name: 'GDPR Data Controller',
  });

  const canadaSection = $template.generateSection({
    hidden: true,
    metadata: {
      canAddQuestion: false,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: TargetProductProfileSection.Canada },
    },
    name: 'Canada',
  });

  const euAcceptablePriceSection = $template.generateSection({
    hidden: true,
    metadata: {
      canAddQuestion: false,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: TargetProductProfileSection.EUAcceptablePrice },
    },
    name: 'EU Acceptable Price',
  });

  return [
    introSection,
    euScreenerSection,
    nonEUScreenerSection,
    unmetNeedsSection,
    tppAssessmentSection,
    clinicalComparatorsSection,
    usSection,
    franceSection,
    germanySection,
    spainSection,
    italySection,
    ukSection,
    canadaSection,
    euAcceptablePriceSection,
    euSection,
    ...euPricingComparatorSections,
    euPricingComparatorOtherSection,
    gdprControllerSection,
  ].map((m, i) => ({
    ...m,
    ordinal: i + 1,
  }));
}

export function generateSurveyData(data: SurveyTemplate.TargetProductProfile): SurveyBuilderVersion {

  const sections = generateSections(data);
  const sectionsMap = sections.reduce<SectionsMap>((acc, x) => ({
    ...acc,
    [x.metadata.template.key]: x,
  }), {});

  function medicalConditionNode(marks?: SurveyRichText.TemplateValueNodeMark[]) {
    return $template.generateMedicalConditionNode(data.medicalCondition, marks);
  }

  function drugNode(marks?: SurveyRichText.TemplateValueNodeMark[]) {
    return $template.generateDrugNode(data.drug, marks);
  }

  function launchYearNode(marks?: SurveyRichText.TemplateValueNodeMark[]) {
    return $template.generateLaunchYearNode(data.launchYear, marks);
  }

  function drugPriceNode(key: keyof Omit<SurveyTemplate.DrugPrice, 'currency'>, marks?: SurveyRichText.TemplateValueNodeMark[]) {
    return $template.generateDrugPriceNode(data.drugPrice, key, marks);
  }

  function additionalPriceLinkNode(key: keyof SurveyTemplate.AdditionalTPPPriceLinks, textParam?: string): SurveyRichText.AdditionalPriceLinkNode {

    const href = data.additionalPriceLinks[key];

    const text = textParam || {
      canada: 'View Canada Pricing',
      france: 'View France Pricing',
      germany: 'View Germany Pricing',
      italy: 'View Italy Pricing',
      spain: 'View Spain Pricing',
      uk: 'View UK Pricing',
    }[key];

    return {
      attrs: {
        key,
        href,
        text,
      },
      type: Remirror.NodeType.AdditionalPriceLink,
    };
  }

  const viewTppNode = $template.generateViewTPPNode();
  const viewStimulusNode = $template.generateViewStimulusNode();

  const [screenerIntroMessage, screenerIntroItem] = (function() {
    const message = $template.generateMessage({
      metadata: {
        anchor: false,
        canDelete: false,
        canEdit: true,
        template: {},
      },
      value: {
        type: Remirror.NodeType.Doc,
        content: [
          $template.generateParagraphNode([
            $template.generateBoldTextNode(`The purpose of the research, which is being conducted on behalf of a pharmaceutical company, is to understand `),
            drugNode([{ type: 'bold' }]),
            $template.generateBoldTextNode(`.`),
          ]),
        ],
      },
    });

    const section = sectionsMap[TargetProductProfileSection.Intro];

    const item = $template.generateMessageItem({
      ...message,
      section: {
        identifier: section.identifier,
      },
    });

    return [message, item];
  }());

  const {
    item: locationQuestionItem,
    logic: locationQuestionLogic,
    question: locationQuestion,
  } = (function() {
    const questionIdentifier = cuid();
    const section = sectionsMap[TargetProductProfileSection.Intro];

    const usOption = $template.generateMultipleChoiceOption({
      metadata: {
        canModifyValue: false,
        canDelete: false,
      },
      value: 'United States',
    });

    const ukOption = $template.generateMultipleChoiceOption({
      metadata: {
        canModifyValue: false,
        canDelete: false,
      },
      value: 'United Kingdom',
    });

    const franceOption = $template.generateMultipleChoiceOption({
      metadata: {
        canModifyValue: false,
        canDelete: false,
      },
      value: 'France',
    });

    const spainOption = $template.generateMultipleChoiceOption({
      metadata: {
        canModifyValue: false,
        canDelete: false,
      },
      value: 'Spain',
    });

    const germanyOption = $template.generateMultipleChoiceOption({
      metadata: {
        canModifyValue: false,
        canDelete: false,
      },
      value: 'Germany',
    });

    const italyOption = $template.generateMultipleChoiceOption({
      metadata: {
        canModifyValue: false,
        canDelete: false,
      },
      value: 'Italy',
    });

    const canadaOption = $template.generateMultipleChoiceOption({
      metadata: {
        canModifyValue: false,
        canDelete: false,
      },
      value: 'Canada',
    });

    const euOption = $template.generateMultipleChoiceOption({
      metadata: {
        canModifyValue: false,
        canDelete: false,
      },
      value: 'Other EU Country',
    });

    function generateIncludeSectionLogic(section: TargetProductProfileSection, option: SurveyQuestionOption) {
      return $template.generateLogicItem({
        action: {
          type: SurveyActionType.IncludeSection,
          section: {
            identifier: sectionsMap[section].identifier,
          },
        },
        conditions: [$template.generateLogicItemQuestionCondition({
          data: {
            question: {
              identifier: questionIdentifier,
            },
            type: SurveyQuestionConditionType.OptionSelected,
            value: {
              option: {
                identifier: option.base.identifier,
              },
            },
          },
        })],
      });
    }

    const logic = [
      generateIncludeSectionLogic(TargetProductProfileSection.USPricing, usOption),
      generateIncludeSectionLogic(TargetProductProfileSection.NonEUScreener, usOption),

      generateIncludeSectionLogic(TargetProductProfileSection.FrancePricing, franceOption),
      generateIncludeSectionLogic(TargetProductProfileSection.EUAcceptablePrice, franceOption),
      generateIncludeSectionLogic(TargetProductProfileSection.EUPricing, franceOption),
      generateIncludeSectionLogic(TargetProductProfileSection.EUScreener, franceOption),
      generateIncludeSectionLogic(TargetProductProfileSection.GDPRDataController, franceOption),

      generateIncludeSectionLogic(TargetProductProfileSection.UKPricing, ukOption),
      generateIncludeSectionLogic(TargetProductProfileSection.EUAcceptablePrice, ukOption),
      generateIncludeSectionLogic(TargetProductProfileSection.EUPricing, ukOption),
      generateIncludeSectionLogic(TargetProductProfileSection.EUScreener, ukOption),
      generateIncludeSectionLogic(TargetProductProfileSection.GDPRDataController, ukOption),

      generateIncludeSectionLogic(TargetProductProfileSection.SpainPricing, spainOption),
      generateIncludeSectionLogic(TargetProductProfileSection.EUAcceptablePrice, spainOption),
      generateIncludeSectionLogic(TargetProductProfileSection.EUPricing, spainOption),
      generateIncludeSectionLogic(TargetProductProfileSection.EUScreener, spainOption),
      generateIncludeSectionLogic(TargetProductProfileSection.GDPRDataController, spainOption),

      generateIncludeSectionLogic(TargetProductProfileSection.GermanyPricing, germanyOption),
      generateIncludeSectionLogic(TargetProductProfileSection.EUAcceptablePrice, germanyOption),
      generateIncludeSectionLogic(TargetProductProfileSection.EUPricing, germanyOption),
      generateIncludeSectionLogic(TargetProductProfileSection.EUScreener, germanyOption),
      generateIncludeSectionLogic(TargetProductProfileSection.GDPRDataController, germanyOption),

      generateIncludeSectionLogic(TargetProductProfileSection.ItalyPricing, italyOption),
      generateIncludeSectionLogic(TargetProductProfileSection.EUAcceptablePrice, italyOption),
      generateIncludeSectionLogic(TargetProductProfileSection.EUPricing, italyOption),
      generateIncludeSectionLogic(TargetProductProfileSection.EUScreener, italyOption),
      generateIncludeSectionLogic(TargetProductProfileSection.GDPRDataController, italyOption),

      generateIncludeSectionLogic(TargetProductProfileSection.Canada, canadaOption),
      generateIncludeSectionLogic(TargetProductProfileSection.EUPricing, canadaOption),
    ];

    const question = $template.generateMultipleChoiceQuestion({
      identifier: questionIdentifier,
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: TargetProductProfileQuestion.CurrentLocation,
        },
      },
      options: [
        usOption,
        canadaOption,
        ukOption,
        franceOption,
        spainOption,
        germanyOption,
        italyOption,
      ].map((m, i) => ({ ...m, ordinal: i + 1 })),
      section: {
        identifier: section.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`In what country do you conduct business?`),
      ]),
    });

    const item = $template.generateQuestionItem(question);

    return {
      item,
      logic,
      question,
    };
  }());

  const [euScreenerMessage, euScreenerMessageItem] = (function() {

    const message = $template.generateMessage({
      metadata: {
        anchor: false,
        canDelete: false,
        canEdit: true,
        template: {},
      },
      value: {
        type: Remirror.NodeType.Doc,
        content: [
          $template.generateParagraphNode([
            $template.generateTextNode(`In accordance with GDPR, the data controllers for this market research project will be named at the end of this survey.`),
          ]),
          $template.generateParagraphNode(),
          $template.generateParagraphNode([
            $template.generateTextNode(`Please note, your information will only be used for market research purposes and will not be passed to any other organisation without your permission.`),
          ]),
          $template.generateParagraphNode(),
          $template.generateParagraphNode([
            $template.generateTextNode(`You have the right to refuse to answer a question or withdraw from the study at any time.  For more information about your rights please see our privacy notice, it is available here: `),
            $template.generateLinkNode({
              href: `https://trinitylifesciences.com/market-research-participant-privacy-policy/`,
            }),
            $template.generateTextNode(`.`),
          ]),
          $template.generateParagraphNode(),
          $template.generateParagraphNode([
            $template.generateTextNode(`We need your consent in order for us to collect and use any information about you.  We will store any personal data securely.`),
          ]),
          $template.generateParagraphNode(),
          $template.generateParagraphNode([
            $template.generateTextNode(`We may need to send some of your personal data outside of the European Economic Area to the United States, where TRINITY is headquartered. We will make sure that it is kept secure at all times.`),
          ]),
        ],
      },
    });

    const section = sectionsMap[TargetProductProfileSection.EUScreener];

    const item = $template.generateMessageItem({
      ...message,
      section: {
        identifier: section.identifier,
      },
    });

    return [message, item];
  }());

  const {
    question: nonEuScreenerQuestion,
    item: nonEuScreenerItem,
    logic: nonEuScreenerLogic,
  } = (function() {
    const questionIdentifier = cuid();
    const section = sectionsMap[TargetProductProfileSection.NonEUScreener];

    const yesOption = $template.generateMultipleChoiceOption({
      metadata: {
        canModifyValue: false,
        canDelete: false,
      },
      ordinal: 1,
      value: 'Yes',
    });
    const noOption = $template.generateMultipleChoiceOption({
      metadata: {
        canModifyValue: false,
        canDelete: false,
      },
      ordinal: 2,
      value: 'No',
    });

    const dqIfNoLogicItem = $template.generateOptionSelectedLogic({
      action: { type: SurveyActionType.Disqualification },
      question: { identifier: questionIdentifier },
      option: { identifier: noOption.base.identifier },
    });

    const logic = [
      dqIfNoLogicItem,
    ];

    const question = $template.generateMultipleChoiceQuestion({
      identifier: questionIdentifier,
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: TargetProductProfileQuestion.NonEUScreener,
        },
      },
      options: [yesOption, noOption],
      section: {
        identifier: section.identifier,
      },
      value: {
        type: Remirror.NodeType.Doc,
        content: [
          $template.generateParagraphNode([
            $template.generateTextNode(`Market research companies are legally required to pass to our client details of adverse events that are mentioned during the course of market research interviews and which may relate to one or more of their products. Although what you communicate will, of course, be treated in confidence, should you mention an adverse event that happened with one or a group of your patients, we will need to report this to the manufacturer - even if you have already reported it directly to the company or the relevant regulatory authority. In such a situation, are you willing to waive the confidentiality given to you under Market Research Codes of Conduct, specifically in relation to that adverse event? Everything else you communicate during the course of the survey will continue to remain confidential.`),
          ]),
          $template.generateParagraphNode(),
          $template.generateParagraphNode([
            $template.generateTextNode(`Please indicate below if you are willing to participate in this research, given these legal requirements.`),
          ]),
        ],
      },
    });

    const item = $template.generateQuestionItem(question);

    return { item, logic, question };
  }());

  const {
    question: euScreener2Question,
    item: euScreener2Item,
    logic: euScreener2Logic,
  } = (function() {
    const questionIdentifier = cuid();
    const section = sectionsMap[TargetProductProfileSection.EUScreener];

    const yesOption = $template.generateMultipleChoiceOption({
      metadata: {
        canModifyValue: false,
        canDelete: false,
      },
      ordinal: 1,
      value: 'I have read the statements above, acknowledge them, and wish to continue with the study',
    });
    const noOption = $template.generateMultipleChoiceOption({
      metadata: {
        canModifyValue: false,
        canDelete: false,
      },
      ordinal: 2,
      value: 'I do not wish to continue with the study',
    });

    const dqIfNoLogic = $template.generateOptionSelectedLogic({
      action: { type: SurveyActionType.Disqualification },
      question: { identifier: questionIdentifier },
      option: { identifier: noOption.base.identifier },
    });

    const logic = [dqIfNoLogic];

    const question = $template.generateMultipleChoiceQuestion({
      identifier: questionIdentifier,
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: TargetProductProfileQuestion.EUScreener,
        },
      },
      options: [yesOption, noOption],
      section: {
        identifier: section.identifier,
      },
      value: {
        type: Remirror.NodeType.Doc,
        content: [
          $template.generateParagraphNode([
            $template.generateTextNode(`The aim of this market research is to gain your views and is not intended to be promotional. All information provided will remain `),
            $template.generateBoldTextNode(`confidential`),
            $template.generateTextNode(` and will only be reported in aggregate, with no identifying information. You may withdraw at any stage of the research or withhold information if you so wish.`),
          ]),
          $template.generateParagraphNode(),
          $template.generateParagraphNode([
            $template.generateBoldTextNode(`European Federation of Pharmaceutical Associations (EFPIA) disclosure requirements`),
          ]),
          $template.generateParagraphNode([
            $template.generateTextNode(`I understand that the European Federation of Pharmaceutical Associations (EFPIA) Disclosure Code requires increased transparency with regard to interactions between the pharmaceutical industry and the healthcare profession. The pharmaceutical sponsor is required to document and publicly disclose any payment received by participants in aggregate form.`),
          ]),
          $template.generateParagraphNode(),
          $template.generateParagraphNode([
            $template.generateBoldTextNode(`Adverse Event Reporting`),
          ]),
          $template.generateBulletListNode([
            $template.generateParagraphNode([
              $template.generateTextNode(`Any details of adverse events/product complaints for any of the pharmaceutical sponsor’s products mentioned during the course of market research are required to be reported to the pharmaceutical sponsor`),
            ]),
            $template.generateParagraphNode([
              $template.generateTextNode(`In the event that an adverse event is mentioned in the survey responses, this will need to be reported to the pharmaceutical sponsor, even if it has already been reported by you to the pharmaceutical sponsor or the regulatory authorities.`),
            ]),
            $template.generateParagraphNode([
              $template.generateTextNode(`In such a situation you will be requested to waive the confidentiality given to you under the Market Research Codes of Conduct, `),
              $template.generateItalicTextNode(`specifically in relation to that adverse event/product complaint`),
              $template.generateTextNode(`. All other responses will continue to remain confidential.`),
            ]),
          ]),
        ],
      },
    });

    const item = $template.generateQuestionItem(question);

    return { item, logic, question };
  }());

  const [stimulusMessage, stimulusItem] = (function() {
    const message = $template.generateMessage({
      metadata: {
        anchor: false,
        canDelete: false,
        canEdit: false,
        template: {
          key: TargetProductProfileMessage.Stimulus,
        },
      },
      value: data.stimulus,
    });

    const section = sectionsMap[TargetProductProfileSection.UnmetNeeds];

    const item = $template.generateMessageItem({
      ...message,
      section: {
        identifier: section.identifier,
      },
    });

    return [message, item];
  }());

  const [unmetNeedsQuestion, unmetNeedsItem] = (function() {
    const section = sectionsMap[TargetProductProfileSection.UnmetNeeds];

    const question = $template.generateSlidersQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: TargetProductProfileQuestion.ConditionUnmetNeeds,
        },
      },
      matrixRows: [$template.generateSlidersRow({
        metadata: {
          canDelete: false,
          canModifyValue: false,
        },
        ordinal: 1,
        value: 'Rating',
      })],
      section: {
        identifier: section.identifier,
      },
      settings: {
        ...$question.matrixSlider.DefaultSettings,
        slider: {
          displayPctOfTotal: false,
          ensureAnswerTotalEqualsMax: false,
          hideSlider: false,
          minValue: 1,
          maxValue: 9,
          increment: 1,
          label: null,
          total: $question.matrixSlider.DefaultSettings.slider.total,
        },
      },
      value: {
        type: Remirror.NodeType.Doc,
        content: [
          $template.generateParagraphNode([
            $template.generateTextNode('Based on the current treatment landscape, please rate your perceived level of unmet need for the treatment of '),
            medicalConditionNode(),
            $template.generateTextNode('.'),
          ]),
          $template.generateParagraphNode(),
          $template.generateParagraphNode([
            $template.generateTextNode(`Please use a 1-9 scale, where 1 is “very low unmet need”, and 9 is “very high unmet need.”`),
          ]),
          $template.generateParagraphNode(),
          $template.generateParagraphNode([viewStimulusNode]),
        ],
      },
    });

    const item = $template.generateQuestionItem(question);

    return [question, item];
  }());

  const [economicBurdenQuestion, economicBurdenItem] = (function() {

    const section = sectionsMap[TargetProductProfileSection.UnmetNeeds];

    const question = $template.generateSlidersQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: TargetProductProfileQuestion.ConditionEconomicBurden,
        },
      },
      matrixRows: [$template.generateSlidersRow({
        metadata: {
          canDelete: false,
          canModifyValue: false,
        },
        ordinal: 1,
        value: 'Rating',
      })],
      section: {
        identifier: section.identifier,
      },
      settings: {
        ...$question.matrixSlider.DefaultSettings,
        slider: {
          displayPctOfTotal: false,
          ensureAnswerTotalEqualsMax: false,
          hideSlider: false,
          minValue: 1,
          maxValue: 9,
          increment: 1,
          label: null,
          total: $question.matrixSlider.DefaultSettings.slider.total,
        },
      },
      value: {
        type: Remirror.NodeType.Doc,
        content: [
          $template.generateParagraphNode([
            $template.generateTextNode('What is your perception of the economic burden associated with '),
            medicalConditionNode(),
            $template.generateTextNode(' in your market?'),
          ]),
          $template.generateParagraphNode(),
          $template.generateParagraphNode([
            $template.generateTextNode(`Please use a 1-9 scale, where 1 is “very low budget impact”, and 9 is “very high budget impact.”`),
          ]),
          $template.generateParagraphNode(),
          $template.generateParagraphNode([viewStimulusNode]),
        ],
      },
    });

    const item = $template.generateQuestionItem(question);

    return [question, item];
  }());

  const [tppIntroMessage, tppIntroItem] = (function() {
    const message = $template.generateMessage({
      metadata: {
        anchor: false,
        canDelete: false,
        canEdit: false,
        template: {
          key: TargetProductProfileMessage.TPP,
        },
      },
      value: {
        type: Remirror.NodeType.Doc,
        content: [
          $template.generateParagraphNode([
            $template.generateTextNode(`Please carefully review the `),
            drugNode(),
            $template.generateTextNode(` TPP. `),
            drugNode(),
            $template.generateTextNode(` is expected to launch by `),
            launchYearNode(),
            $template.generateTextNode(` for the treatment of `),
            medicalConditionNode(),
            $template.generateTextNode(`.`),
          ]),
          $template.generateParagraphNode(),
          $template.generateParagraphNode([$template.generateTPPNode()]),
        ],
      },
    });

    const section = sectionsMap[TargetProductProfileSection.TPPAssessment];

    const item = $template.generateMessageItem({
      ...message,
      section: {
        identifier: section.identifier,
      },
    });

    return [message, item];
  }());

  const [drugStrengthsQuestion, drugStrengthsItem] = (function() {
    const section = sectionsMap[TargetProductProfileSection.TPPAssessment];

    const question = $template.generateMultiTextBoxesQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: TargetProductProfileQuestion.DrugStrengths,
        },
      },
      section: {
        identifier: section.identifier,
      },
      settings: {
        dontKnow: {
          enabled: false,
          label: '',
        },
        entryLabels: ['', '', ''],
        label: $template.generateRichTextValue([
          $template.generateTextNode('Strengths'),
        ]),
        minimumEntries: 1,
        maximumEntries: 3,
        rationale: {
          enabled: false,
          minimum: 75,
        },
      },
      value: {
        type: Remirror.NodeType.Doc,
        content: [
          $template.generateParagraphNode([
            $template.generateTextNode('What do you believe are the primary strengths of '),
            drugNode(),
            $template.generateTextNode(' in this indication?'),
          ]),
          $template.generateParagraphNode(),
          $template.generateParagraphNode([viewStimulusNode]),
          $template.generateParagraphNode([viewTppNode]),
        ],
      },
    });

    const item = $template.generateQuestionItem(question);

    return [question, item];
  }());

  const [drugWeaknessesQuestion, drugWeaknessesItem] = (function() {
    const section = sectionsMap[TargetProductProfileSection.TPPAssessment];

    const question = $template.generateMultiTextBoxesQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: TargetProductProfileQuestion.DrugWeaknesses,
        },
      },
      section: {
        identifier: section.identifier,
      },
      settings: {
        dontKnow: {
          enabled: false,
          label: '',
        },
        entryLabels: ['', '', ''],
        label: $template.generateRichTextValue([
          $template.generateTextNode('Weaknesses'),
        ]),
        minimumEntries: 1,
        maximumEntries: 3,
        rationale: {
          enabled: false,
          minimum: 75,
        },
      },
      value: {
        type: Remirror.NodeType.Doc,
        content: [
          $template.generateParagraphNode([
            $template.generateTextNode('What do you believe are the primary weaknesses of '),
            drugNode(),
            $template.generateTextNode(' in this indication?'),
          ]),
          $template.generateParagraphNode(),
          $template.generateParagraphNode([viewStimulusNode]),
          $template.generateParagraphNode([viewTppNode]),
        ],
      },
    });

    const item = $template.generateQuestionItem(question);

    return [question, item];
  }());

  const [ratingIntroMessage, ratingIntroItem] = (function() {
    const message = $template.generateMessage({
      metadata: {
        anchor: false,
        canDelete: false,
        canEdit: false,
        template: {},
      },
      value: {
        type: Remirror.NodeType.Doc,
        content: [
          $template.generateParagraphNode([
            $template.generateBoldTextNode(`Now, we would like you to consider the value of each of the following attributes, and overall, for `),
            drugNode([{ type: 'bold' }]),
            $template.generateBoldTextNode(` i.e., the level of benefit offered and its impact in the P&R (pricing and reimbursement) decision.`),
          ]),
        ],
      },
    });

    const section = sectionsMap[TargetProductProfileSection.TPPAssessment];

    const item = $template.generateMessageItem({
      ...message,
      section: {
        identifier: section.identifier,
      },
    });

    return [message, item];
  }());

  const [ratingRationaleQuestion, ratingRationaleItem] = (function() {

    const section = sectionsMap[TargetProductProfileSection.TPPAssessment];

    const question = $template.generateLongTextQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: TargetProductProfileQuestion.RatingRationale,
        },
      },
      section: {
        identifier: section.identifier,
      },
      settings: {
        ...$question.longText.DefaultSettings,
        characterMinimum: 150,
      },
      value: {
        type: Remirror.NodeType.Doc,
        content: [
          $template.generateParagraphNode([
            $template.generateTextNode(`Please explain your rationale for the overall rating, while discussing specific features of `),
            drugNode(),
            $template.generateTextNode(` that you consider the most relevant to shape your overall value perception for this product.`),
          ]),
          $template.generateParagraphNode(),
          $template.generateParagraphNode([viewStimulusNode]),
          $template.generateParagraphNode([viewTppNode]),
        ],
      },
    });

    const item = $template.generateQuestionItem(question);

    return [question, item];
  }());

  const [tppRatingQuestion, tppRatingItem] = (function() {

    const section = sectionsMap[TargetProductProfileSection.TPPAssessment];

    const matrixRows: MatrixGridQuestion.Row[] = [];

    matrixRows.push($template.generateDrugIndicationRow({
      item: {
        id: '',
        value: data.drugIndication,
      },
      ordinal: matrixRows.length + 1,
    }));

    matrixRows.push($template.generateTrialDesignRow({
      item: {
        id: '',
        value: data.trialDesign,
      },
      ordinal: matrixRows.length + 1,
    }));

    const primaryEndpoint = data.efficacyEndpoints.primary[0];
    const coPrimaryEndpoint = data.efficacyEndpoints.primary[1];

    if (primaryEndpoint) {
      matrixRows.push($template.generatePrimaryEfficacyEndpointRow({
        item: {
          id: '',
          value: primaryEndpoint.value,
        },
        ordinal: matrixRows.length + 1,
      }));
    }

    if (coPrimaryEndpoint) {
      matrixRows.push($template.generateCoPrimaryEfficacyEndpointRow({
        item: {
          id: '',
          value: coPrimaryEndpoint.value,
        },
        ordinal: matrixRows.length + 1,
      }));
    }

    data.efficacyEndpoints.secondary.forEach(e => {
      matrixRows.push($template.generateSecondaryEfficacyEndpointRow({
        item: {
          id: e.id,
          value: e.value,
        },
        ordinal: matrixRows.length + 1,
      }));
    });

    data.safetyEndpoints.forEach(e => {
      matrixRows.push($template.generateSafetyEndpointRow({
        item: {
          id: e.id,
          value: e.value,
        },
        ordinal: matrixRows.length + 1,
      }));
    });

    if (data.qualityOfLife) {
      matrixRows.push($template.generateQualityOfLifeRow({
        item: {
          id: '',
          value: data.qualityOfLife,
        },
        ordinal: matrixRows.length + 1,
      }));
    }

    data.otherAttributes.forEach(e => {
      matrixRows.push($template.generateOtherAttributeRow({
        item: {
          id: e.id,
          value: e.value,
        },
        ordinal: matrixRows.length + 1,
      }));
    });

    data.doseAdministration.forEach(e => {
      matrixRows.push($template.generateDoseAdministrationRow({
        item: {
          id: e.id,
          value: e.value,
        },
        ordinal: matrixRows.length + 1,
      }));
    });

    matrixRows.push($template.generateRowRichText<MatrixGridQuestion.RowMetadata>({
      metadata: {
        canDelete: false,
        canModifyValue: false,
        isAnchored: false,
        isOpenEnded: false,
        isLastRowInCurrentGrid: false,
        template: {},
      },
      ordinal: matrixRows.length + 1,
      value: $template.generateRichTextValue([$template.generateBoldTextNode(`Overall added value`)]),
    }));

    const question = $template.generateMatrixGridQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: TargetProductProfileQuestion.RateAttributes,
        },
      },
      matrixRows,
      options: [
        `1 - Neutral/No added P&R value`,
        `2 - Limited addition of P&R value`,
        `3 - Moderately adds P&R value`,
        `4 - Highly adds P&R value`,
        `5 - Very highly adds P&R value`,
      ].map((value, i) => $template.generateOption({
        metadata: {
          canDelete: false,
          canModifyValue: false,
          template: {},
        },
        ordinal: i + 1,
        value,
      })),
      section: {
        identifier: section.identifier,
      },
      value: {
        type: Remirror.NodeType.Doc,
        content: [
          $template.generateParagraphNode([
            $template.generateTextNode(`Please rate `),
            drugNode(),
            $template.generateTextNode(`, both overall and by each attribute, on a scale of 1-5 where 1 is “neutral/no added P&R value”, 2 is “limited addition of P&R value”, 3 is “moderately adds P&R value”, 4 is “highly adds P&R value” and 5 is "very highly adds P&R value".`),
          ]),
          $template.generateBulletListNode([
            $template.generateParagraphNode([
              $template.generateItalicTextNode(`Consider the treatment landscape at the point of `),
              drugNode(),
              $template.generateTextNode(` launch`),
            ]),
            $template.generateParagraphNode([
              $template.generateItalicTextNode(`Consider each attribute independent of other attributes`),
            ]),
            $template.generateParagraphNode([
              $template.generateItalicTextNode(`If an attribute is NOT relevant for the P&R decision in your market please select 1, regardless of the magnitude of benefit offered`),
            ]),
          ]),
          $template.generateParagraphNode(),
          $template.generateParagraphNode([
            $template.generateItalicTextNode(`Note: P&R is an abbreviation for "Pricing and reimbursement"`),
          ]),
          $template.generateParagraphNode(),
          $template.generateParagraphNode([viewStimulusNode]),
          $template.generateParagraphNode([viewTppNode]),
        ],
      },
    });

    const item = $template.generateQuestionItem(question);

    return [question, item];
  }());

  const {
    question: clinicalComparatorsAppropriateQuestion,
    item: clinicalComparatorsAppropriateItem,
    logic: clinicalComparatorsAppropriateLogic,
  } = (function() {
    const questionIdentifier = cuid();
    const section = sectionsMap[TargetProductProfileSection.TPPAssessment];

    const yesOption = $template.generateMultipleChoiceOption({
      metadata: {
        canModifyValue: false,
        canDelete: false,
      },
      ordinal: 1,
      value: 'Yes',
    });
    const noOption = $template.generateMultipleChoiceOption({
      metadata: {
        canModifyValue: false,
        canDelete: false,
      },
      ordinal: 2,
      value: 'No',
    });

    const includeSectionLogic = $template.generateOptionSelectedLogic({
      action: {
        type: SurveyActionType.IncludeSection,
        section: {
          identifier: sectionsMap[TargetProductProfileSection.ClinicalComparators].identifier,
        },
      },
      question: { identifier: questionIdentifier },
      option: {
        identifier: noOption.base.identifier,
      },
    });

    const logic = [includeSectionLogic];

    const question = $template.generateMultipleChoiceQuestion({
      identifier: questionIdentifier,
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: TargetProductProfileQuestion.ClinincalComparatorsAppropriate,
        },
      },
      options: [yesOption, noOption],
      section: {
        identifier: section.identifier,
      },
      value: {
        type: Remirror.NodeType.Doc,
        content: [
          $template.generateParagraphNode([
            $template.generateTextNode(`Do you consider the clinical comparator(s) to be appropriate in this treatment landscape?`),
          ]),
          $template.generateParagraphNode(),
          $template.generateParagraphNode([viewStimulusNode]),
          $template.generateParagraphNode([viewTppNode]),
        ],
      },
    });

    const item = $template.generateQuestionItem(question);

    return { item, logic, question };
  }());

  const [clinicalComparatorsQuestion, clinicalComparatorsItem] = (function() {

    const section = sectionsMap[TargetProductProfileSection.ClinicalComparators];

    let question: SurveyQuestion;

    const hasTreatmentComparators = !!data.treatmentComparators.length;

    if (hasTreatmentComparators) {
      const comparatorOptions = data.treatmentComparators.map((m, i) => {
        return $template.generateTreatmentComparatorOption({
          item: {
            id: m.id,
            value: m.value,
          },
          ordinal: i + 1,
        });
      });

      const naOption = $template.generateMultiselectOption({
        metadata: {
          canDelete: false,
          canModifyValue: false,
          isAnchored: true,
          isExclusive: true,
        },
        ordinal: comparatorOptions.length + 1,
        type: SurveyOptionType.NoneOfTheAbove,
        value: 'No appropriate comparator',
      });
      const otherOption = $template.generateMultiselectOption({
        metadata: {
          canDelete: false,
          canModifyValue: false,
          isAnchored: true,
          isOpenEnded: true,
        },
        ordinal: comparatorOptions.length + 2,
        value: 'Other',
      });

      question = $template.generateMultiselectQuestion({
        metadata: {
          anchor: false,
          canModifyValue: false,
          canDelete: false,
          template: {
            key: TargetProductProfileQuestion.ClinicalComparators,
          },
        },
        options: [...comparatorOptions, naOption, otherOption],
        settings: {
          ...$question.multiselect.DefaultSettings,
          rationale: {
            enabled: true,
            minimum: 75,
          },
          includeNoneOfTheAbove: true,
        },
        section: {
          identifier: section.identifier,
        },
        value: $template.generateRichTextNode([
          $template.generateParagraphNode([
            $template.generateTextNode(`What treatments would you consider to be appropriate comparators?`),
          ]),
          $template.generateParagraphNode(),
          $template.generateParagraphNode([viewStimulusNode]),
          $template.generateParagraphNode([viewTppNode]),
        ]),
      });
    } else {
      question = $template.generateShortTextQuestion({
        metadata: {
          anchor: false,
          canModifyValue: false,
          canDelete: false,
          template: {
            key: TargetProductProfileQuestion.ClinicalComparatorsOE,
          },
        },
        section: {
          identifier: section.identifier,
        },
        settings: {
          ...$question.shortText.DefaultSettings,
          rationale: {
            enabled: true,
            minimum: 75,
          },
        },
        value: $template.generateRichTextNode([
          $template.generateParagraphNode([
            $template.generateTextNode(`What treatments would you consider to be appropriate comparators?`),
          ]),
          $template.generateParagraphNode(),
          $template.generateParagraphNode([viewStimulusNode]),
          $template.generateParagraphNode([viewTppNode]),
        ]),
      });
    }

    const item = $template.generateQuestionItem(question);

    return [question, item];
  }());

  function generateMedicalBenefitGroup() {
    const identifier = cuid();

    const generateOption = (value: string) => $template.generateOptionGroupOption({
      metadata: {
        canDelete: false,
        canModifyValue: false,
        sectionId: identifier,
        template: {},
      },
      value,
    });

    const mbOption = generateOption('Medical Benefit');
    const tier2Option = generateOption('Tier 2');
    const tier3Option = generateOption('Tier 3');
    const tier4Option = generateOption('Tier 4 (if applicable)');
    const tier5Option = generateOption('Tier 5 (if applicable)');

    const group = $template.generateOptionGroup({
      identifier,
      settings: {
        requireSelection: true,
        exclusiveGroups: [
          {
            primaryOption: mbOption.base.identifier,
            exclusiveOptions: [
              tier2Option.base.identifier,
              tier3Option.base.identifier,
              tier4Option.base.identifier,
              tier5Option.base.identifier,
            ],
          },
          {
            primaryOption: tier2Option.base.identifier,
            exclusiveOptions: [
              tier3Option.base.identifier,
              tier4Option.base.identifier,
              tier5Option.base.identifier,
            ],
          },
          {
            primaryOption: tier3Option.base.identifier,
            exclusiveOptions: [
              tier2Option.base.identifier,
              tier4Option.base.identifier,
              tier5Option.base.identifier,
            ],
          },
          {
            primaryOption: tier4Option.base.identifier,
            exclusiveOptions: [
              tier2Option.base.identifier,
              tier3Option.base.identifier,
              tier5Option.base.identifier,
            ],
          },
          {
            primaryOption: tier5Option.base.identifier,
            exclusiveOptions: [
              tier2Option.base.identifier,
              tier3Option.base.identifier,
              tier4Option.base.identifier,
            ],
          },
        ],
      },
      type: 'multi-select',
      value: 'Medical Benefit',
    });

    return [group, [
      mbOption,
      tier2Option,
      tier3Option,
      tier4Option,
      tier5Option,
    ]] as const;
  }

  function generatePriorAuthorizationGroup() {
    const identifier = cuid();

    const generateOption = (value: string) => $template.generateOptionGroupOption({
      metadata: {
        canDelete: false,
        canModifyValue: false,
        sectionId: identifier,
        template: {},
      },
      value,
    });

    const noPAOption = generateOption('No prior authorization');
    const paToLabelOption = generateOption('Prior authorization to label');
    const paBeyondLabelOption = generateOption('Prior authorization beyond label');
    const noRecertOption = generateOption('Prior authorization without recertification');
    const recertLessThanYearOption = generateOption('Prior authorization with recertification < 1 year');
    const recertAtYearOption = generateOption('Prior authorization with recertification at 1 year');

    const group = $template.generateOptionGroup({
      identifier,
      settings: {
        requireSelection: true,
        exclusiveGroups: [
          {
            primaryOption: noPAOption.base.identifier,
            exclusiveOptions: [
              paToLabelOption.base.identifier,
              paBeyondLabelOption.base.identifier,
              noRecertOption.base.identifier,
              recertLessThanYearOption.base.identifier,
              recertAtYearOption.base.identifier,
            ],
          },
          {
            primaryOption: paToLabelOption.base.identifier,
            exclusiveOptions: [
              noPAOption.base.identifier,
              paBeyondLabelOption.base.identifier,
            ],
          },
          {
            primaryOption: paBeyondLabelOption.base.identifier,
            exclusiveOptions: [
              noPAOption.base.identifier,
              paToLabelOption.base.identifier,
            ],
          },
          {
            primaryOption: noRecertOption.base.identifier,
            exclusiveOptions: [
              noPAOption.base.identifier,
              recertLessThanYearOption.base.identifier,
              recertAtYearOption.base.identifier,
            ],
          },
          {
            primaryOption: recertLessThanYearOption.base.identifier,
            exclusiveOptions: [
              noPAOption.base.identifier,
              noRecertOption.base.identifier,
              recertAtYearOption.base.identifier,
            ],
          },
          {
            primaryOption: recertAtYearOption.base.identifier,
            exclusiveOptions: [
              noPAOption.base.identifier,
              noRecertOption.base.identifier,
              recertLessThanYearOption.base.identifier,
            ],
          },
        ],
      },
      type: 'multi-select',
      value: 'Prior Authorization',
    });

    return [group, [
      noPAOption,
      paToLabelOption,
      paBeyondLabelOption,
      noRecertOption,
      recertLessThanYearOption,
      recertAtYearOption,
    ]] as const;
  }

  function generateTherapyOptionsGroup() {
    const identifier = cuid();

    const generateOption = (value: string, metadata?: Partial<ExclusiveOptionsQuestion.OptionMetadata>) => $template.generateOptionGroupOption({
      metadata: {
        canDelete: false,
        canModifyValue: false,
        sectionId: identifier,
        template: {},
        ...(metadata || {}),
      },
      value,
    });

    const noStepOption = generateOption('No step therapy');
    const singleStepOption = generateOption('Single step therapy (which product(s))', { isOpenEnded: true });
    const doubleStepOption = generateOption('Double step therapy (which product(s))', { isOpenEnded: true });
    const tripleStepOption = generateOption('Triple step therapy (which product(s))', { isOpenEnded: true });

    const group = $template.generateOptionGroup({
      identifier,
      settings: {
        requireSelection: true,
        exclusiveGroups: [
          {
            primaryOption: noStepOption.base.identifier,
            exclusiveOptions: [
              singleStepOption.base.identifier,
              doubleStepOption.base.identifier,
              tripleStepOption.base.identifier,
            ],
          },
          {
            primaryOption: singleStepOption.base.identifier,
            exclusiveOptions: [
              noStepOption.base.identifier,
              doubleStepOption.base.identifier,
              tripleStepOption.base.identifier,
            ],
          },
          {
            primaryOption: doubleStepOption.base.identifier,
            exclusiveOptions: [
              noStepOption.base.identifier,
              singleStepOption.base.identifier,
              tripleStepOption.base.identifier,
            ],
          },
          {
            primaryOption: tripleStepOption.base.identifier,
            exclusiveOptions: [
              noStepOption.base.identifier,
              singleStepOption.base.identifier,
              doubleStepOption.base.identifier,
            ],
          },
        ],
      },
      type: 'single-select',
      value: 'Therapy Options',
    });

    return [group, [
      noStepOption,
      singleStepOption,
      doubleStepOption,
      tripleStepOption,
    ]] as const;
  }

  function generatePrescriberOptionsGroup() {
    const identifier = cuid();

    const generateOption = (value: string, metadata?: Partial<ExclusiveOptionsQuestion.OptionMetadata>) => $template.generateOptionGroupOption({
      metadata: {
        canDelete: false,
        canModifyValue: false,
        sectionId: identifier,
        template: {},
        ...(metadata || {}),
      },
      value,
    });

    const coPayOption = generateOption('Co-pay differential');
    const coInsuranceOption = generateOption('Co-insurance differential');
    const specialistOption = generateOption('Specialist prescribing');
    const mandatoryOption = generateOption('Mandatory specialty pharmacy');
    const otherOption = generateOption('Other', { isOpenEnded: true });

    const group = $template.generateOptionGroup({
      identifier,
      settings: {
        requireSelection: true,
        exclusiveGroups: [],
      },
      type: 'multi-select',
      value: 'Prescriber Options',
    });

    return [group, [
      coPayOption,
      coInsuranceOption,
      specialistOption,
      otherOption,
      mandatoryOption,
    ]] as const;
  }

  function generateNotCoveredGroup() {
    const identifier = cuid();

    const noneOption = $template.generateOptionGroupOption({
      metadata: {
        canDelete: false,
        canModifyValue: false,
        sectionId: identifier,
        template: {},
      },
      type: SurveyOptionType.NoneOfTheAbove,
      value: 'Not covered',
    });

    const group = $template.generateOptionGroup({
      identifier,
      settings: {
        requireSelection: false,
        exclusiveGroups: [],
      },
      type: 'multi-select',
      value: 'Other',
    });

    return [group, [
      noneOption,
    ]] as const;
  }

  function generateNoChangeGroup() {
    const identifier = cuid();

    const noChangeOption = $template.generateOptionGroupOption({
      metadata: {
        canDelete: false,
        canModifyValue: false,
        sectionId: identifier,
        template: {},
      },
      type: SurveyOptionType.NoneOfTheAbove,
      value: 'No change from previous management exercise',
    });

    const group = $template.generateOptionGroup({
      identifier,
      settings: {
        requireSelection: false,
        exclusiveGroups: [],
      },
      type: 'multi-select',
      value: 'No Change',
    });

    return [group, [noChangeOption]] as const;
  }

  const [usPricingQuestion, usPricingItem] = (function() {
    const section = sectionsMap[TargetProductProfileSection.USPricing];

    const [medicalBenefitGroup, medicalBenefitOptions] = generateMedicalBenefitGroup();
    const [priorAuthorizationGroup, priorAuthorizationOptions] = generatePriorAuthorizationGroup();
    const [therapyOptionsGroup, therapyOptionsOptions] = generateTherapyOptionsGroup();
    const [prescriberOptionsGroup, prescriberOptionsOptions] = generatePrescriberOptionsGroup();
    const [notCoveredGroup, notCoveredOptions] = generateNotCoveredGroup();

    const optionGroups = [
      notCoveredGroup,
      medicalBenefitGroup,
      priorAuthorizationGroup,
      therapyOptionsGroup,
      prescriberOptionsGroup,
    ].map((m, i) => ({ ...m, ordinal: i + 1 }));

    const options = [
      ...notCoveredOptions,
      ...medicalBenefitOptions,
      ...priorAuthorizationOptions,
      ...therapyOptionsOptions,
      ...prescriberOptionsOptions,
    ].map((m, i) => ({ ...m, ordinal: i + 1 }));

    const question = $template.generateExclusiveOptionsQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: TargetProductProfileQuestion.ProductManage,
        },
      },
      options,
      section: {
        identifier: section.identifier,
      },
      settings: {
        ...$question.exclusiveOptions.DefaultSettings,
        optionSections: optionGroups,
        rationale: {
          enabled: true,
          minimum: 75,
        },
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`Irrespective of price and based on the profile of `),
          drugNode(),
          $template.generateTextNode(`, how would you expect to manage `),
          drugNode(),
          $template.generateTextNode(` for `),
          medicalConditionNode(),
          $template.generateTextNode(`?`),
        ]),
        $template.generateParagraphNode(),
        $template.generateParagraphNode([viewStimulusNode]),
        $template.generateParagraphNode([viewTppNode]),
      ]),
    });

    const item = $template.generateQuestionItem(question);

    return [question, item];
  }());

  const [wacQuestion, wacItem] = (function() {

    const section = sectionsMap[TargetProductProfileSection.USPricing];

    const question = $template.generateNumberInputTableQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: TargetProductProfileQuestion.AcceptableWACPrice,
        },
      },
      matrixRows: [$template.generateNumberInputTableRow({
        metadata: {
          canDelete: false,
          canModifyValue: false,
        },
        ordinal: 1,
        value: 'WAC price per month',
      })],
      options: [
        $template.generateOption({
          metadata: {
            canDelete: false,
            canModifyValue: false,
            isReadOnly: false,
            template: {},
            validations: [],
          },
          ordinal: 1,
          value: 'Acceptable Price',
        }),
      ],
      section: {
        identifier: section.identifier,
      },
      settings: {
        ...$question.numberInputTable.DefaultSettings,
        numberSettings: {
          currency: 'USD',
          type: SurveyQuestionNumberType.Currency,
        },
        validations: {
          minValue: 0,
          maxValue: 1000000,
        },
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`Given the product profile and the context of the `),
          medicalConditionNode(),
          $template.generateTextNode(` environment, what would you consider to be an acceptable Wholesale Acquisition Cost (WAC) price per month (i.e., does not lead to restrictions and results in broad access based on the label) for `),
          drugNode(),
          $template.generateTextNode(`?`),
        ]),
        $template.generateParagraphNode(),
        $template.generateParagraphNode([viewStimulusNode]),
        $template.generateParagraphNode([viewTppNode]),
      ]),
    });

    const item = $template.generateQuestionItem(question);

    return [question, item];
  }());

  function generatePricingComparatorQuestion(sectionEnum: TargetProductProfileSection) {
    const section = sectionsMap[sectionEnum];

    let question: SurveyQuestion;

    const hasComparators = !!data.pricingComparators.length;

    if (hasComparators) {
      const comparatorOptions = data.pricingComparators.map((m, i) => {
        return $template.generatePricingComparatorOption({
          item: {
            id: m.id,
            value: m.value,
          },
          ordinal: i + 1,
        });
      });

      const otherOption = $template.generateMultiselectOption({
        metadata: {
          canDelete: false,
          canModifyValue: false,
          isOpenEnded: true,
          isAnchored: true,
        },
        ordinal: comparatorOptions.length + 2,
        value: 'Other',
      });

      question = $template.generateMultiselectQuestion({
        metadata: {
          anchor: false,
          canModifyValue: false,
          canDelete: false,
          template: {
            key: TargetProductProfileQuestion.PricingComparators,
          },
        },
        options: [...comparatorOptions, otherOption],
        settings: {
          ...$question.multiselect.DefaultSettings,
          rationale: {
            enabled: true,
            minimum: 75,
          },
        },
        section: {
          identifier: section.identifier,
        },
        value: $template.generateRichTextNode([
          $template.generateParagraphNode([
            $template.generateTextNode(`What do you believe would be an appropriate pricing comparator(s) for `),
            drugNode(),
            $template.generateTextNode(`?`),
          ]),
          $template.generateParagraphNode(),
          $template.generateParagraphNode([viewStimulusNode]),
          $template.generateParagraphNode([viewTppNode]),
        ]),
      });
    } else {
      question = $template.generateShortTextQuestion({
        metadata: {
          anchor: false,
          canModifyValue: false,
          canDelete: false,
          template: {
            key: TargetProductProfileQuestion.PricingComparatorsOE,
          },
        },
        section: {
          identifier: section.identifier,
        },
        settings: {
          ...$question.shortText.DefaultSettings,
          rationale: {
            enabled: true,
            minimum: 75,
          },
        },
        value: $template.generateRichTextNode([
          $template.generateParagraphNode([
            $template.generateTextNode(`What do you believe would be an appropriate pricing comparator(s) for `),
            drugNode(),
            $template.generateTextNode(`? Please specify.`),
          ]),
          $template.generateParagraphNode(),
          $template.generateParagraphNode([viewStimulusNode]),
          $template.generateParagraphNode([viewTppNode]),
        ]),
      });
    }

    const item = $template.generateQuestionItem(question);

    return [question, item] as const;
  }

  const [pricingComparatorsQuestion, pricingComparatorsItem] = generatePricingComparatorQuestion(TargetProductProfileSection.USPricing);

  const [usPriceIntroMessage, usPriceIntroItem] = (function() {

    const section = sectionsMap[TargetProductProfileSection.USPricing];

    const message = $template.generateMessage({
      metadata: {
        anchor: false,
        canDelete: false,
        canEdit: true,
        template: {},
      },
      value: $template.generateRichTextValue([
        $template.generateBoldTextNode(`You will now see a number of pricing scenarios presented in sequence. For each, please select the most likely management decisions to be applied by your organization.`),
      ]),
    });

    const item = $template.generateMessageItem({
      ...message,
      section: {
        identifier: section.identifier,
      },
    });

    return [message, item];
  }());

  const [lowPriceQuestion, lowPriceItem] = (function() {

    const section = sectionsMap[TargetProductProfileSection.USPricing];

    const [medicalBenefitGroup, medicalBenefitOptions] = generateMedicalBenefitGroup();
    const [priorAuthorizationGroup, priorAuthorizationOptions] = generatePriorAuthorizationGroup();
    const [therapyOptionsGroup, therapyOptionsOptions] = generateTherapyOptionsGroup();
    const [prescriberOptionsGroup, prescriberOptionsOptions] = generatePrescriberOptionsGroup();
    const [notCoveredGroup, notCoveredOptions] = generateNotCoveredGroup();

    const optionGroups = [
      notCoveredGroup,
      medicalBenefitGroup,
      priorAuthorizationGroup,
      prescriberOptionsGroup,
      therapyOptionsGroup,
    ].map((m, i) => ({ ...m, ordinal: i + 1 }));

    const options = [
      ...notCoveredOptions,
      ...medicalBenefitOptions,
      ...priorAuthorizationOptions,
      ...prescriberOptionsOptions,
      ...therapyOptionsOptions,
    ].map((m, i) => ({ ...m, ordinal: i + 1 }));

    const question = $template.generateExclusiveOptionsQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: TargetProductProfileQuestion.ProductLowPrice,
        },
      },
      options,
      section: {
        identifier: section.identifier,
      },
      settings: {
        ...$question.exclusiveOptions.DefaultSettings,
        optionSections: optionGroups,
      },
      value: {
        type: Remirror.NodeType.Doc,
        content: [
          $template.generateParagraphNode([
            $template.generateTextNode(`At the following price level, how would you expect to manage `),
            drugNode(),
            $template.generateTextNode(` in a future disease landscape? Please select the most likely utilization management that will apply to `),
            drugNode(),
            $template.generateTextNode(` in this pricing scenario by your organization.`),
          ]),
          $template.generateParagraphNode(),
          $template.generateParagraphNode([
            $template.generateBoldTextNode(`Price = `),
            drugPriceNode('low', [{ type: 'bold' }]),
          ]),
          $template.generateParagraphNode(),
          $template.generateParagraphNode([viewStimulusNode]),
          $template.generateParagraphNode([viewTppNode]),
        ],
      },
    });

    const item = $template.generateQuestionItem(question);

    return [question, item];
  }());

  const [mediumPriceQuestion, mediumPriceItem] = (function() {

    const section = sectionsMap[TargetProductProfileSection.USPricing];

    const [medicalBenefitGroup, medicalBenefitOptions] = generateMedicalBenefitGroup();
    const [priorAuthorizationGroup, priorAuthorizationOptions] = generatePriorAuthorizationGroup();
    const [therapyOptionsGroup, therapyOptionsOptions] = generateTherapyOptionsGroup();
    const [prescriberOptionsGroup, prescriberOptionsOptions] = generatePrescriberOptionsGroup();
    const [notCoveredGroup, notCoveredOptions] = generateNotCoveredGroup();
    const [noChangeGroup, noChangeOptions] = generateNoChangeGroup();

    const optionGroups = [
      noChangeGroup,
      notCoveredGroup,
      medicalBenefitGroup,
      priorAuthorizationGroup,
      prescriberOptionsGroup,
      therapyOptionsGroup,
    ].map((m, i) => ({ ...m, ordinal: i + 1 }));

    const options = [
      ...noChangeOptions,
      ...notCoveredOptions,
      ...medicalBenefitOptions,
      ...priorAuthorizationOptions,
      ...prescriberOptionsOptions,
      ...therapyOptionsOptions,
    ].map((m, i) => ({ ...m, ordinal: i + 1 }));

    const question = $template.generateExclusiveOptionsQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: TargetProductProfileQuestion.ProductMediumPrice,
        },
      },
      options,
      section: {
        identifier: section.identifier,
      },
      settings: {
        ...$question.exclusiveOptions.DefaultSettings,
        optionSections: optionGroups,
      },
      value: {
        type: Remirror.NodeType.Doc,
        content: [
          $template.generateParagraphNode([
            $template.generateTextNode(`At the following price level, how would you expect to manage `),
            drugNode(),
            $template.generateTextNode(` in a future disease landscape? Please select the most likely utilization management that will apply to `),
            drugNode(),
            $template.generateTextNode(` in this pricing scenario by your organization.`),
          ]),
          $template.generateParagraphNode(),
          $template.generateParagraphNode([
            $template.generateBoldTextNode(`Price = `),
            drugPriceNode('medium', [{ type: 'bold' }]),
          ]),
          $template.generateParagraphNode(),
          $template.generateParagraphNode([viewStimulusNode]),
          $template.generateParagraphNode([viewTppNode]),
        ],
      },
    });

    const item = $template.generateQuestionItem(question);

    return [question, item];
  }());

  const [highPriceQuestion, highPriceItem] = (function() {

    const section = sectionsMap[TargetProductProfileSection.USPricing];

    const [medicalBenefitGroup, medicalBenefitOptions] = generateMedicalBenefitGroup();
    const [priorAuthorizationGroup, priorAuthorizationOptions] = generatePriorAuthorizationGroup();
    const [therapyOptionsGroup, therapyOptionsOptions] = generateTherapyOptionsGroup();
    const [prescriberOptionsGroup, prescriberOptionsOptions] = generatePrescriberOptionsGroup();
    const [notCoveredGroup, notCoveredOptions] = generateNotCoveredGroup();
    const [noChangeGroup, noChangeOptions] = generateNoChangeGroup();

    const optionGroups = [
      noChangeGroup,
      notCoveredGroup,
      medicalBenefitGroup,
      priorAuthorizationGroup,
      prescriberOptionsGroup,
      therapyOptionsGroup,
    ].map((m, i) => ({ ...m, ordinal: i + 1 }));

    const options = [
      ...noChangeOptions,
      ...notCoveredOptions,
      ...medicalBenefitOptions,
      ...priorAuthorizationOptions,
      ...prescriberOptionsOptions,
      ...therapyOptionsOptions,
    ].map((m, i) => ({ ...m, ordinal: i + 1 }));

    const question = $template.generateExclusiveOptionsQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: TargetProductProfileQuestion.ProductHighPrice,
        },
      },
      options,
      section: {
        identifier: section.identifier,
      },
      settings: {
        ...$question.exclusiveOptions.DefaultSettings,
        optionSections: optionGroups,
      },
      value: {
        type: Remirror.NodeType.Doc,
        content: [
          $template.generateParagraphNode([
            $template.generateTextNode(`At the following price level, how would you expect to manage `),
            drugNode(),
            $template.generateTextNode(` in a future disease landscape? Please select the most likely utilization management that will apply to `),
            drugNode(),
            $template.generateTextNode(` in this pricing scenario by your organization.`),
          ]),
          $template.generateParagraphNode(),
          $template.generateParagraphNode([
            $template.generateBoldTextNode(`Price = `),
            drugPriceNode('high', [{ type: 'bold' }]),
          ]),
          $template.generateParagraphNode(),
          $template.generateParagraphNode([viewStimulusNode]),
          $template.generateParagraphNode([viewTppNode]),
        ],
      },
    });

    const item = $template.generateQuestionItem(question);

    return [question, item];
  }());

  function generateForeignPriceIntroScreen({ display, key, section }: GenerateIntroScreen) {
    if (!data.additionalPriceLinks[key]) return [null, null] as const;

    const sectionObj = sectionsMap[section];

    const message = $template.generateMessage({
      metadata: {
        anchor: false,
        canDelete: false,
        canEdit: true,
        template: {},
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`Thanks, please review the pricing comparators for ${display} via the link below:`),
        ]),
        $template.generateParagraphNode(),
        $template.generateParagraphNode([
          additionalPriceLinkNode(key, 'Please click the link to view the drug landscape & stimulus.'),
        ]),
      ]),
    });

    const item = $template.generateMessageItem({
      ...message,
      section: {
        identifier: sectionObj.identifier,
      },
    });

    return [message, item] as const;
  }

  type GenerateIntroScreen = {
    display: string;
    key: keyof SurveyTemplate.AdditionalTPPPriceLinks;
    section: TargetProductProfileSection;
  };

  const [franceIntroMessage, franceIntroItem] = generateForeignPriceIntroScreen({
    display: 'France',
    key: 'france',
    section: TargetProductProfileSection.FrancePricing,
  });

  const [franceQuestion, franceItem] = (function() {

    const section = sectionsMap[TargetProductProfileSection.FrancePricing];

    const [smrGroup, smrOptions] = (function() {

      const identifier = cuid();

      const group = $template.generateOptionGroup({
        identifier,
        settings: {
          exclusiveGroups: [],
          requireSelection: true,
        },
        type: 'single-select',
        value: 'SMR',
      });

      const generateOption = (value: string) => $template.generateOptionGroupOption({
        metadata: {
          canDelete: false,
          canModifyValue: false,
          sectionId: identifier,
          template: {},
        },
        value,
      });

      const options = [
        'Important',
        'Moderate',
        'Weak',
        'Insufficient',
      ].map(generateOption);

      return [group, options] as const;
    }());

    const [asmrGroup, asmrOptions] = (function() {

      const identifier = cuid();

      const group = $template.generateOptionGroup({
        identifier,
        settings: {
          exclusiveGroups: [],
          requireSelection: true,
        },
        type: 'single-select',
        value: 'ASMR',
      });

      const generateOption = (value: string) => $template.generateOptionGroupOption({
        metadata: {
          canDelete: false,
          canModifyValue: false,
          sectionId: identifier,
          template: {},
        },
        value,
      });

      const options = [
        'Major',
        'Important',
        'Moderate',
        'Minor',
      ].map(generateOption);

      return [group, options] as const;
    }());

    const question = $template.generateExclusiveOptionsQuestion({
      metadata: {
        anchor: false,
        canDelete: false,
        canModifyValue: true,
        template: {
          key: TargetProductProfileQuestion.HTAFrance,
        },
      },
      options: [
        ...smrOptions,
        ...asmrOptions,
      ].map((m, i) => ({ ...m, ordinal: i + 1 })),
      section: {
        identifier: section.identifier,
      },
      settings: {
        rationale: {
          enabled: true,
          minimum: 75,
        },
        displayGroupTitles: true,
        optionSections: [
          smrGroup,
          asmrGroup,
        ].map((m, i) => ({ ...m, ordinal: i + 1 })),
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`Based on this product's profile and the anticipated `),
          medicalConditionNode(),
          $template.generateTextNode(` landscape, what HTA outcome would you expect for this product in your country?`),
        ]),
        $template.generateParagraphNode(),
        $template.generateParagraphNode([viewStimulusNode]),
        $template.generateParagraphNode([viewTppNode]),
        data.additionalPriceLinks.france && $template.generateParagraphNode([additionalPriceLinkNode('france')]),
      ].filter(Boolean)),
    });

    const item = $template.generateQuestionItem(question);

    return [question, item];
  }());

  const [germanyIntroMessage, germanyIntroItem] = generateForeignPriceIntroScreen({
    display: 'Germany',
    key: 'germany',
    section: TargetProductProfileSection.GermanyPricing,
  });

  const [germanyQuestion, germanyItem] = (function() {
    const section = sectionsMap[TargetProductProfileSection.GermanyPricing];

    const question = $template.generateMultipleChoiceQuestion({
      metadata: {
        anchor: false,
        canDelete: false,
        canModifyValue: true,
        template: {
          key: TargetProductProfileQuestion.HTAGermany,
        },
      },
      options: [
        'Major',
        'Considerable',
        'Minor',
        'Non-quantifiable',
        'None',
        'Negative',
      ].map((value, i) => $template.generateMultipleChoiceOption({
        metadata: {
          canDelete: false,
          canModifyValue: false,
        },
        ordinal: i + 1,
        value,
      })),
      section: {
        identifier: section.identifier,
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`Based on this product's profile and the anticipated `),
          medicalConditionNode(),
          $template.generateTextNode(` landscape, what HTA outcome would you expect for this product in your country? Select a G-BA Benefit level.`),
        ]),
        $template.generateParagraphNode(),
        $template.generateParagraphNode([viewStimulusNode]),
        $template.generateParagraphNode([viewTppNode]),
        data.additionalPriceLinks.germany && $template.generateParagraphNode([additionalPriceLinkNode('germany')]),
      ].filter(Boolean)),
    });

    const item = $template.generateQuestionItem(question);

    return [question, item];
  }());

  const [spainIntroMessage, spainIntroItem] = generateForeignPriceIntroScreen({
    display: 'Spain',
    key: 'spain',
    section: TargetProductProfileSection.SpainPricing,
  });

  const [spainQuestion, spainItem] = (function() {

    const section = sectionsMap[TargetProductProfileSection.SpainPricing];

    const question = $template.generateMultipleChoiceQuestion({
      metadata: {
        anchor: false,
        canDelete: false,
        canModifyValue: false,
        template: {
          key: TargetProductProfileQuestion.MoHReimbursement,
        },
      },
      options: ['Yes', 'No'].map((value, i) => $template.generateMultipleChoiceOption({
        metadata: {
          canDelete: false,
          canModifyValue: false,
        },
        ordinal: i + 1,
        value,
      })),
      section: { identifier: section.identifier },
      settings: {
        ...$question.multipleChoice.DefaultSettings,
        rationale: {
          enabled: true,
          minimum: 75,
        },
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`Prior to discussing price, and based on this product's profile and the anticipated `),
          medicalConditionNode(),
          $template.generateTextNode(` landscape, would you expect the MoH to reimburse `),
          drugNode(),
          $template.generateTextNode('?'),
        ]),
        $template.generateParagraphNode(),
        $template.generateParagraphNode([viewStimulusNode]),
        $template.generateParagraphNode([viewTppNode]),
        data.additionalPriceLinks.spain && $template.generateParagraphNode([additionalPriceLinkNode('spain')]),
      ].filter(Boolean)),
    });

    const item = $template.generateQuestionItem(question);

    return [question, item];
  }());

  const [italyIntroMessage, italyIntroItem] = generateForeignPriceIntroScreen({
    display: 'Italy',
    key: 'italy',
    section: TargetProductProfileSection.ItalyPricing,
  });

  const [italyQuestion, italyItem] = (function() {

    const section = sectionsMap[TargetProductProfileSection.ItalyPricing];

    const question = $template.generateMultipleChoiceQuestion({
      metadata: {
        anchor: false,
        canDelete: false,
        canModifyValue: false,
        template: {
          key: TargetProductProfileQuestion.AIFAReimbursement,
        },
      },
      options: ['Yes', 'No'].map((value, i) => $template.generateMultipleChoiceOption({
        metadata: {
          canDelete: false,
          canModifyValue: false,
        },
        ordinal: i + 1,
        value,
      })),
      section: { identifier: section.identifier },
      settings: {
        ...$question.multipleChoice.DefaultSettings,
        rationale: {
          enabled: true,
          minimum: 75,
        },
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`Based on this product's profile and the anticipated `),
          medicalConditionNode(),
          $template.generateTextNode(` landscape, would you expect AIFA to reimburse `),
          drugNode(),
          $template.generateTextNode(`?`),
        ]),
        $template.generateParagraphNode(),
        $template.generateParagraphNode([viewStimulusNode]),
        $template.generateParagraphNode([viewTppNode]),
        data.additionalPriceLinks.italy && $template.generateParagraphNode([additionalPriceLinkNode('italy')]),
      ].filter(Boolean)),
    });

    const item = $template.generateQuestionItem(question);

    return [question, item];
  }());

  const [ukIntroMessage, ukIntroItem] = generateForeignPriceIntroScreen({
    display: 'United Kingdom',
    key: 'uk',
    section: TargetProductProfileSection.UKPricing,
  });

  const [ukQuestion, ukItem] = (function() {
    const section = sectionsMap[TargetProductProfileSection.UKPricing];

    const generateOption = (value: string, groupIdentifier: string) => $template.generateOptionGroupOption({
      metadata: {
        canDelete: false,
        canModifyValue: false,
        sectionId: groupIdentifier,
        template: {},
      },
      value,
    });

    const group1 = $template.generateOptionGroup({
      identifier: cuid(),
      settings: {
        exclusiveGroups: [],
        requireSelection: false,
      },
      type: 'single-select',
      value: 'Group 1',
    });

    const group2 = $template.generateOptionGroup({
      identifier: cuid(),
      settings: {
        exclusiveGroups: [],
        requireSelection: false,
      },
      type: 'multi-select',
      value: 'Group 2',
    });

    const group3 = $template.generateOptionGroup({
      identifier: cuid(),
      settings: {
        exclusiveGroups: [],
        requireSelection: false,
      },
      type: 'multi-select',
      value: 'None of the above',
    });

    const option1 = generateOption('I expect this drug to be recommended by NICE for routine NHS reimbursement', group1.identifier);
    const option2 = generateOption('I expect this drug to be recommended for managed access via the Innovative Medicines Fund ahead of routine NHS reimbursement ', group1.identifier);
    const option3 = generateOption('I expect my local health authority to fund this product even if it does not receive a favorable NICE appraisal', group2.identifier);
    const option4 = $template.generateOptionGroupOption({
      metadata: {
        canDelete: false,
        canModifyValue: false,
        sectionId: group3.identifier,
        template: {},
        isExclusive: true,
      },
      value: 'None of the above',
    });

    const optionGroups = [
      group1,
      group2,
      group3,
    ].map((m, i) => ({ ...m, ordinal: i + 1 }));

    const options = [
      option1,
      option2,
      option3,
      option4,
    ].map((m, i) => ({ ...m, ordinal: i + 1 }));

    const question = $template.generateExclusiveOptionsQuestion({
      metadata: {
        anchor: false,
        canDelete: false,
        canModifyValue: false,
        template: {
          key: TargetProductProfileQuestion.UKOutcomes,
        },
      },
      options,
      section: { identifier: section.identifier },
      settings: {
        ...$question.exclusiveOptions.DefaultSettings,
        optionSections: optionGroups,
        rationale: {
          enabled: true,
          minimum: 75,
        },
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`Based on this product's profile and the anticipated `),
          medicalConditionNode(),
          $template.generateTextNode(` landscape, what outcomes would you believe to be applicable for `),
          drugNode(),
          $template.generateTextNode(` in the UK?`),
        ]),
        $template.generateParagraphNode(),
        $template.generateParagraphNode([viewStimulusNode]),
        $template.generateParagraphNode([viewTppNode]),
        data.additionalPriceLinks.uk && $template.generateParagraphNode([additionalPriceLinkNode('uk')]),
      ].filter(Boolean)),
    });

    const item = $template.generateQuestionItem(question);

    return [question, item];
  }());

  const [acceptablePriceQuestion, acceptablePriceItem] = (function() {
    const section = sectionsMap[TargetProductProfileSection.EUAcceptablePrice];

    const question = $template.generateNumberInputTableQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: TargetProductProfileQuestion.ProductAcceptableListPrice,
        },
      },
      matrixRows: [$template.generateNumberInputTableRow({
        metadata: {
          canDelete: false,
          canModifyValue: false,
        },
        ordinal: 1,
        value: 'Price per month',
      })],
      options: [
        $template.generateOption({
          metadata: {
            canDelete: false,
            canModifyValue: false,
            isReadOnly: false,
            template: {},
            validations: [],
          },
          ordinal: 1,
          value: 'Acceptable Price',
        }),
      ],
      section: {
        identifier: section.identifier,
      },
      settings: {
        ...$question.numberInputTable.DefaultSettings,
        numberSettings: {
          currency: 'EUR',
          type: SurveyQuestionNumberType.Currency,
        },
        validations: {
          minValue: 0,
          maxValue: 1000000,
        },
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`Given the product profile and the context of the `),
          medicalConditionNode(),
          $template.generateTextNode(` environment, what would you consider to be an acceptable list price per month (i.e., resulting in minimal to no access restrictions) for `),
          drugNode(),
          $template.generateTextNode(`?`),
        ]),
        $template.generateParagraphNode(),
        $template.generateParagraphNode([viewStimulusNode]),
        $template.generateParagraphNode([viewTppNode]),
      ]),
    });

    const item = $template.generateQuestionItem(question);

    return [question, item];
  }());

  const [pricingComparatorsEUQuestion, pricingComparatorsEUItem] = generatePricingComparatorQuestion(TargetProductProfileSection.EUPricing);

  const [regionalAccessQuestion, regionalAccessItem] = (function() {
    const section = sectionsMap[TargetProductProfileSection.EUPricing];

    const question = $template.generateMultipleChoiceQuestion({
      metadata: {
        anchor: false,
        canDelete: false,
        canModifyValue: false,
        template: {
          key: TargetProductProfileQuestion.RegionalAccessVariation,
        },
      },
      options: ['Yes', 'No'].map((value, i) => $template.generateMultipleChoiceOption({
        metadata: {
          canDelete: false,
          canModifyValue: false,
        },
        ordinal: i + 1,
        value,
      })),
      section: { identifier: section.identifier },
      settings: {
        ...$question.multipleChoice.DefaultSettings,
        rationale: {
          enabled: true,
          minimum: 75,
        },
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`Do you expect regional variations in access at the regional/local level?`),
        ]),
        $template.generateParagraphNode(),
        $template.generateParagraphNode([viewStimulusNode]),
        $template.generateParagraphNode([viewTppNode]),
      ]),
    });

    const item = $template.generateQuestionItem(question);

    return [question, item];
  }());

  const [formularyQuestion, formularyItem] = (function() {
    const section = sectionsMap[TargetProductProfileSection.EUPricing];

    const question = $template.generateMultipleChoiceQuestion({
      metadata: {
        anchor: false,
        canDelete: false,
        canModifyValue: false,
        template: {
          key: TargetProductProfileQuestion.InsititutionalFormulary,
        },
      },
      options: ['High', 'Medium', 'Low'].map((value, i) => $template.generateMultipleChoiceOption({
        metadata: {
          canDelete: false,
          canModifyValue: false,
        },
        ordinal: i + 1,
        value,
      })),
      section: { identifier: section.identifier },
      settings: {
        ...$question.multipleChoice.DefaultSettings,
        rationale: {
          enabled: true,
          minimum: 75,
        },
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`What's the probability of `),
          drugNode(),
          $template.generateTextNode(` being included in your institutional formulary?`),
        ]),
        $template.generateParagraphNode(),
        $template.generateParagraphNode([viewStimulusNode]),
        $template.generateParagraphNode([viewTppNode]),
      ]),
    });

    const item = $template.generateQuestionItem(question);

    return [question, item];
  }());

  const [finalGdprMessage, finalGdprItem] = (function() {

    const isTrinityDataController = data.client.toLocaleLowerCase() === 'trinity';

    const paragraphContent = isTrinityDataController
      ? [$template.generateTextNode(`In accordance with GDPR, the data controller for this market research project is TRINITY`)]
      : [
        $template.generateTextNode(`In accordance with GDPR, the data controllers for this market research project include TRINITY and `),
        $template.generateDataControllerNode(data.client),
        $template.generateTextNode(`.`),
      ];

    const message = $template.generateMessage({
      metadata: {
        anchor: false,
        canDelete: false,
        canEdit: true,
        template: {},
      },
      value: {
        type: Remirror.NodeType.Doc,
        content: [
          $template.generateParagraphNode(paragraphContent),
        ],
      },
    });

    const section = sectionsMap[TargetProductProfileSection.GDPRDataController];

    const item = $template.generateMessageItem({
      ...message,
      section: {
        identifier: section.identifier,
      },
    });

    return [message, item];
  }());

  function generateEUPricingComparatorValues(comparator: SurveyTemplate.LinkedEntity) {
    const section = sections.find(f => f.metadata.template.key === TargetProductProfileSection.EUPricingComparator &&
      f.metadata.template.linkedEntity.id === comparator.id);
    return generateEUPricingComparatorQuestion({
      comparator,
      pricingComparatorsEUQuestion,
      section,
      template: data,
    });
  }

  const euPricingComparators = (function generateEUPricingComparatorSections() {
    const base = data.pricingComparators.reduce((acc, comparator) => {
      const { item, logicItem, question } = generateEUPricingComparatorValues(comparator);
      return {
        items: acc.items.concat(item),
        logicItems: acc.logicItems.concat(logicItem),
        questions: acc.questions.concat(question),
      };
    }, {
      items: [],
      logicItems: [],
      questions: [],
    } as {
      items: Omit<SurveyItem, 'ordinal'>[];
      logicItems: SurveyLogic.Item[];
      questions: SurveyQuestion[];
    });

    if (data.pricingComparators.length) {
      const other = generateEUPricingComparatorValues({
        id: null,
        value: 'Other',
      });
      base.items = base.items.concat(other.item);
      base.logicItems = base.logicItems.concat(other.logicItem);
      base.questions = base.questions.concat(other.question);
    }

    return {
      items: base.items,
      logicItems: base.logicItems,
      questions: base.questions,
    };
  }());

  const [canadaQuestion, canadaItem] = (function() {
    const section = sectionsMap[TargetProductProfileSection.Canada];

    const options = [
      'I expect this drug to be reimbursed by CADTH',
      'I expect this drug to be reimbursed by CADTH with conditions',
      'I expect this drug to not be reimbursed by CADTH',
    ];

    const question = $template.generateMultipleChoiceQuestion({
      metadata: {
        anchor: false,
        canDelete: false,
        canModifyValue: false,
        template: {
          key: TargetProductProfileQuestion.CanadaReimbursement,
        },
      },
      options: options.map((o, i) => $template.generateMultipleChoiceOption({
        ordinal: i + 1,
        value: o,
      })),
      section: { identifier: section.identifier },
      settings: {
        ...$question.multipleChoice.DefaultSettings,
        rationale: {
          enabled: true,
          minimum: 75,
        },
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`Based on this product's profile and the anticipated `),
          medicalConditionNode(),
          $template.generateTextNode(` landscape, what outcomes would you believe to be applicable for `),
          drugNode(),
          $template.generateTextNode(` in CAN?`),
        ]),
        $template.generateParagraphNode(),
        $template.generateParagraphNode([
          $template.generateTextNode(`Please provide rationale (`),
          $template.generateTextNode(`if reimbursement with conditions selected, please specify clinical and/or financial conditions expected [e.g. start, stop, continue criteria]`, [{ type: 'italic' }]),
          $template.generateTextNode(`).`),
        ]),
        $template.generateParagraphNode(),
        $template.generateParagraphNode([viewStimulusNode]),
        $template.generateParagraphNode([viewTppNode]),
        data.additionalPriceLinks.canada && $template.generateParagraphNode([additionalPriceLinkNode('canada')]),
      ]),
    });

    const item = $template.generateQuestionItem(question);

    return [question, item];
  }());

  const [acceptableCANPriceQuestion, acceptableCANPriceItem] = (function() {
    const section = sectionsMap[TargetProductProfileSection.Canada];

    const question = $template.generateNumberInputTableQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: TargetProductProfileQuestion.ProductAcceptableListPrice,
        },
      },
      matrixRows: [$template.generateNumberInputTableRow({
        metadata: {
          canDelete: false,
          canModifyValue: false,
        },
        ordinal: 1,
        value: 'Price per month',
      })],
      options: [
        $template.generateOption({
          metadata: {
            canDelete: false,
            canModifyValue: false,
            isReadOnly: false,
            template: {},
            validations: [],
          },
          ordinal: 1,
          value: 'Acceptable Price',
        }),
      ],
      section: {
        identifier: section.identifier,
      },
      settings: {
        ...$question.numberInputTable.DefaultSettings,
        numberSettings: {
          currency: 'CAD',
          type: SurveyQuestionNumberType.Currency,
        },
        validations: {
          minValue: 0,
          maxValue: 1000000,
        },
      },
      value: $template.generateRichTextNode([
        $template.generateParagraphNode([
          $template.generateTextNode(`Given the product profile and the context of the `),
          medicalConditionNode(),
          $template.generateTextNode(` environment, what would you consider to be an acceptable list price per month (i.e., resulting in minimal to no access restrictions) for `),
          drugNode(),
          $template.generateTextNode(`?`),
        ]),
        $template.generateParagraphNode(),
        $template.generateParagraphNode([viewStimulusNode]),
        $template.generateParagraphNode([viewTppNode]),
      ]),
    });

    const item = $template.generateQuestionItem(question);

    return [question, item];
  }());

  const items: SurveyItem[] = [
    screenerIntroItem,
    locationQuestionItem,
    euScreenerMessageItem,
    euScreener2Item,
    nonEuScreenerItem,
    stimulusItem,
    unmetNeedsItem,
    economicBurdenItem,
    tppIntroItem,
    drugStrengthsItem,
    drugWeaknessesItem,
    ratingIntroItem,
    tppRatingItem,
    ratingRationaleItem,
    clinicalComparatorsAppropriateItem,
    clinicalComparatorsItem,
    usPricingItem,
    wacItem,
    pricingComparatorsItem,
    usPriceIntroItem,
    lowPriceItem,
    mediumPriceItem,
    highPriceItem,
    franceIntroItem,
    franceItem,
    germanyIntroItem,
    germanyItem,
    spainIntroItem,
    spainItem,
    italyIntroItem,
    italyItem,
    ukIntroItem,
    ukItem,
    canadaItem,
    acceptableCANPriceItem,
    acceptablePriceItem,
    pricingComparatorsEUItem,
    ...euPricingComparators.items,
    // regionalAccessItem,
    // formularyItem,
    finalGdprItem,
  ].filter(Boolean)
    .map((m, i) => ({
      ...m,
      ordinal: i + 1,
    }));

  return {
    alternateImageExercises: [],
    classifications: [],
    createdOn: null,
    id: null,
    items,
    language: $template.generateLanguage(),
    logic: [
      ...locationQuestionLogic,
      ...nonEuScreenerLogic,
      ...euScreener2Logic,
      ...clinicalComparatorsAppropriateLogic,
      ...euPricingComparators.logicItems,
    ].filter(Boolean),
    messages: [
      screenerIntroMessage,
      euScreenerMessage,
      stimulusMessage,
      tppIntroMessage,
      ratingIntroMessage,
      usPriceIntroMessage,
      franceIntroMessage,
      germanyIntroMessage,
      italyIntroMessage,
      spainIntroMessage,
      ukIntroMessage,
      finalGdprMessage,
    ].filter(Boolean),
    questions: [
      locationQuestion,
      euScreener2Question,
      nonEuScreenerQuestion,
      unmetNeedsQuestion,
      economicBurdenQuestion,
      drugStrengthsQuestion,
      drugWeaknessesQuestion,
      tppRatingQuestion,
      ratingRationaleQuestion,
      clinicalComparatorsAppropriateQuestion,
      clinicalComparatorsQuestion,
      usPricingQuestion,
      wacQuestion,
      pricingComparatorsQuestion,
      lowPriceQuestion,
      mediumPriceQuestion,
      highPriceQuestion,
      franceQuestion,
      germanyQuestion,
      spainQuestion,
      italyQuestion,
      ukQuestion,
      canadaQuestion,
      acceptableCANPriceQuestion,
      acceptablePriceQuestion,
      pricingComparatorsEUQuestion,
      ...euPricingComparators.questions,
      // regionalAccessQuestion,
      // formularyQuestion,
    ].filter(Boolean),
    quotas: [],
    sections: sections.filter(e => items.some(s => s.section.identifier === e.identifier)),
    surveyId: null,
    surveyDetails: null,
    tagging: [],
    template: {
      data,
      type: SurveyTemplateType.TargetProductProfile,
    },
  };
}

type SectionsMap = {
  [key: string]: SurveySection;
};

type GenerateEUPricingComparatorQuestion = {
  comparator: SurveyTemplate.LinkedEntity;
  pricingComparatorsEUQuestion: SurveyQuestion;
  section: { identifier: string };
  template: SurveyTemplate.TargetProductProfile;
};

export function generateEUPricingComparatorQuestion({ comparator, pricingComparatorsEUQuestion, section, template }: GenerateEUPricingComparatorQuestion) {
  const linkedEntity = {
    id: comparator.id,
    type: SurveyTemplateEntityType.PricingComparator,
  };

  const question = $template.generateMultipleChoiceQuestion({
    metadata: {
      anchor: false,
      canDelete: false,
      canModifyValue: false,
      template: {
        linkedEntity,
        key: TargetProductProfileQuestion.ProductPremiumDiscount,
      },
    },
    options: [
      'Premiums of 60-80%',
      'Premiums of 40-60%',
      'Premiums of 10-20%',
      'Premiums of up to 10%',
      `Parity Pricing to ${comparator.value}`,
      'Discount of up to 10%',
      'Discount of 10-20%',
      'Discount of 20-40%',
      'Discount of 40-60%',
    ].map((value, i) => $template.generateMultipleChoiceOption({
      metadata: {
        canDelete: false,
        canModifyValue: false,
      },
      ordinal: i + 1,
      value,
    })),
    section,
    value: $template.generateRichTextNode([
      $template.generateParagraphNode([
        $template.generateTextNode(`Based on expected HTA/reimbursement outcomes and the context of the `),
        $template.generateMedicalConditionNode(template.medicalCondition),
        $template.generateTextNode(` environment, what level of premium or discount to `),
        $template.generatePricingComparatorNode(comparator),
        $template.generateTextNode(` do you think `),
        $template.generateDrugNode(template.drug),
        $template.generateTextNode(` can achieve?`),
      ]),
      $template.generateParagraphNode(),
      $template.generateParagraphNode([$template.generateViewStimulusNode()]),
      $template.generateParagraphNode([$template.generateViewTPPNode()]),
    ]),
  });

  const correspondingOption = comparator.id
    ? pricingComparatorsEUQuestion.options.find(q => q.metadata.template.linkedEntity?.id === comparator.id)
    : pricingComparatorsEUQuestion.options.find(q => !q.metadata.template?.linkedEntity?.id);

  const logicItem = $template.generateOptionSelectedLogic({
    action: {
      type: SurveyActionType.IncludeSection,
      section: { identifier: section.identifier },
    },
    question: {
      identifier: pricingComparatorsEUQuestion.base.identifier,
    },
    option: { identifier: correspondingOption.base.identifier },
  });

  const item = $template.generateQuestionItem(question);

  return {
    item,
    logicItem,
    question,
  };
}

export function generateEuPricingComparatorSection(comparator: SurveyTemplate.LinkedEntity) {
  return $template.generateSection({
    hidden: true,
    metadata: {
      canAddQuestion: false,
      canDelete: false,
      canModifyVisibility: false,
      template: {
        linkedEntity: {
          id: comparator.id,
          type: SurveyTemplateEntityType.PricingComparator,
        },
        key: TargetProductProfileSection.EUPricingComparator,
      },
    },
    name: `${comparator.value} Section`,
  });
}