import { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import pickerCSS from 'react-datepicker/dist/react-datepicker.css';
import { CalendarPickerHeader } from '@/components/CalendarPicker/CalendarPickerHeader';
import type { CalendarPickerBaseProps, CalendarPickerHeaderParams } from './interfaces';

type Props =
  CalendarPickerBaseProps;

const CalendarPickerBase = ({
  renderCustomHeader = defaultRenderCustomHeader,
  ...props
}: Props ) => {
  useEffect(() => {
    pickerCSS.use();

    return () => pickerCSS.unuse();
  }, []);

  return (
    <DatePicker
      renderCustomHeader={renderCustomHeader}
      {...props} />
  );
};

function defaultRenderCustomHeader(params: CalendarPickerHeaderParams) {
  return <CalendarPickerHeader {...params} />;
}

export { CalendarPickerBase };
export default CalendarPickerBase;