import http from '@services/http';
import { Transcripts } from './interfaces';

export const getTranscript = ({ transcriptId }: Transcripts.GetTranscript.Request): Promise<Transcripts.GetTranscript.Response> => {
  return http.get(`/admin/transcripts/${transcriptId}`);
};

export const downloadTranscriptReport = (data: Transcripts.DownloadTranscriptReport.Request): Promise<Transcripts.DownloadTranscriptReport.Response> => {
  return http.get(`/admin/transcripts/${data.transcriptId}/report?format=${data.format}`);
};

export const getTranscriptHighlights = ({ transcriptId }: Transcripts.GetTranscriptHighlights.Request): Promise<Transcripts.GetTranscriptHighlights.Response> => {
  return http.get(`/admin/transcripts/${transcriptId}/highlights`);
};

export const getTranscriptUploadCredentials = (data: Transcripts.GetTranscriptUploadCredentials.Request): Promise<Transcripts.GetTranscriptUploadCredentials.Response> => {
  return http.get(`/admin/transcripts/${data.transcriptId}/upload-credentials?newKey=${(data.newKey ?? false).toString()}`);
};

export const completeTranscriptUpload = (data: Transcripts.CompleteTranscriptUpload.Request) => {
  return http.post(`/admin/transcripts/${data.transcriptId}/upload`, {
    highlights: data.highlights,
  });
};

export const completeTranscriptRedaction = (data: Transcripts.CompleteTranscriptRedaction.Request) => {
  return http.post(`/admin/transcripts/${data.transcriptId}/complete-redaction`, {
    highlights: data.highlights,
    tempTranscriptKey: data.tempTranscriptKey,
    redactRanges: data.redactRanges,
  });
};

export const getTranscriptPromptResults = (data: Transcripts.GetTranscriptPromptResults.Request): Promise<Transcripts.GetTranscriptPromptResults.Response> => {
  return http.get(`/admin/transcripts/${data.transcriptId}/prompt-results`);
};

export const getTranscriptMedia = (data: Transcripts.GetTranscriptMedia.Request): Promise<Transcripts.GetTranscriptMedia.Response> => {
  return http.get(`/admin/transcripts/${data.transcriptId}/media`);
};