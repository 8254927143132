import { calls as initialState } from '@store/initial-state';
import * as types from '@store/action-types';
import {
  AppDataFetchedAction,
  AppStateRehydratedAction,
  Calls,
  ProjectRemovedAction,
} from '@store/interfaces';

type Actions =
    AppDataFetchedAction
  | AppStateRehydratedAction
  | Calls.CallActions
  | ProjectRemovedAction;

function callsReducer(state = initialState, action: Actions) {

  switch (action.type) {
    case types.APP_DATA_FETCHED:
      return (action.calls || {}) || state;

    case types.APP_STATE_REHYDRATED:
      return { ...state, ...action.calls };

    case types.PROJECT_REMOVED: {
      const { ids, ...calls } = state;

      const items = action.callIds.reduce((acc, callId) => {
        const { [callId]: _, ...other } = acc;
        return other;
      }, calls);

      return {
        ...items,
        ids: ids.filter(id => !action.callIds.includes(id)),
      };
    }

    case types.PROJECT_CALL_CREATED:
    case types.ADHOC_CALL_CREATED:
    {
      const { call } = action;
      return {
        ...state,
        [call.id]: call,
        ids: state.ids.concat(call.id),
      };
    }

    case types.ADHOC_CALL_UPDATED:
    case types.CALL_UPDATED: {
      const { call } = action;
      return { ...state, [call.id]: { ...state[call.id], ...call } };
    }

    default:
      return state;
  }
}

export default callsReducer;
