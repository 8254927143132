import { useMemo } from 'react';
import { Topics } from '@/types';
import { arr } from '@utils';
import { ProfilePreview } from '@/components/UserProfile';
import { Row } from './Layout';
import styles from './style/Panel.css';

type Props = {
  items: Topics.TopicExpertPreview[];
}

export function Experts({ items }: Props) {
  const rows = useMemo(() => arr.chunk(items, 3), [items]);

  return (
    <>
      {rows.map((row, rowIdx) => {
        return (
          <Row key={rowIdx}>
            {row.map((profile, profileIdx) => {
              return (
                <div key={`${profileIdx}-${profile.id}`} className={styles.profile}>
                  <ProfilePreview user={profile} />
                </div>
              );
            })}
          </Row>
        );
      })}
    </>
  );
}