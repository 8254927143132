import { useCallback, useMemo, useState } from 'react';
import { ReportFormat } from '@/enums';
import type { SelectChangeEvent } from '$admin/components/Select';
import { Select } from '$admin/components/Select';
import type { ModalProps } from '@/components/Modal/Modal';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { Modal } from '@/components/Modal/Modal';
import { Header } from '@/components/Modal/Header';
import { useModal } from '@/components/Modal/hooks';
import Button from '@/components/Button';
import styles from './style/FormatSelectionModal.css';

type BaseProps = {
  formats: ReportFormat[];
  onDownload: (format: ReportFormat) => void;
  title: string;
}
  & Pick<ModalProps,
    'open' |
    'onClose'>;

export const ReportFormatSelectionModal = ({ formats, onDownload, onClose, open, title }: BaseProps) => {

  const [format, setFormat] = useState<ReportFormat>(formats[0]);

  const handleSelect = useCallback((x: SelectChangeEvent<ReportFormat>) => {
    setFormat(x.target.value);
  }, []);

  const options = useMemo(() => {
    const nameMap: Record<ReportFormat, string> = {
      [ReportFormat.Csv]: 'csv',
      [ReportFormat.Excel]: 'xlsx',
      [ReportFormat.PowerPoint]: 'pptx',
      [ReportFormat.Pdf]: 'pdf',
      [ReportFormat.Word]: 'docx',
    };
    return formats.map(x => ({
      id: x,
      name: nameMap[x],
    }));
  }, [formats]);

  const handleDownload = useCallback(() => {
    onDownload(format);
    onClose();
  }, [onClose, onDownload, format]);

  return (
    <Modal
      classes={{ root: styles.modal }}
      open={open}
      onClose={onClose}>
      <Header>{title}</Header>

      <div className={styles.format}>

        <div className={styles.field}>
          <div className={styles.label}>File format:</div>

          <Select<Record<ReportFormat, string>>
            onChange={handleSelect}
            options={options}
            value={format} />
        </div>
      </div>

      <ButtonSet className={styles.footer}>
        <Button
          className={styles.action}
          color="primary"
          variant="brick"
          onClick={handleDownload}>
          Download
        </Button>
      </ButtonSet>
    </Modal>
  );
};

export const useReportFormatSelectionModal = () => useModal(ReportFormatSelectionModal);