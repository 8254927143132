import { useMutation } from '@tanstack/react-query';
import { submitAnswer, submitAIEScreen, submitMessageScreen } from '@api/surveys.capture-sheet';
import { Surveys } from '@api/interfaces/surveys';

type Props = ICallId & ISurveyId;

export const useSubmitAnswerMutation = (props: Props) => {
  return useMutation(['capture-sheet-submit-answer'], (data: Surveys.CaptureSheets.SubmitAnswer.Request['data']) => {
    return submitAnswer({
      data,
      ...props,
    });
  });
};

export const useSubmitAIEScreenMutation = (props: Props) => {
  return useMutation(['capture-sheet-submit-aie'], (data: Surveys.CaptureSheets.SubmitAlternateImageExercise.Request['data']) => {
    return submitAIEScreen({
      data,
      ...props,
    });
  });
};

export const useSubmitMessageScreenMutation = (props: Props) => {
  return useMutation(['capture-sheet-submit-message'], (data: Surveys.CaptureSheets.SubmitMessageScreen.Request['data']) => {
    return submitMessageScreen({
      data,
      ...props,
    });
  });
};