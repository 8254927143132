import { useMemo } from 'react';

export const useValidateTextInput = (value: string, opts: Options) => {
  return useMemo(() => {
    return value.length >= opts.minimum;
  }, [opts.minimum, value]);
};

type Options = {
  minimum: number;
};