import { useCallback } from 'react';
import { ColorPaletteType } from '@enums';
import * as Branding from '@api/interfaces/groups.branding';
import { RemovePalette, useRemoveChartPaletteMutation as useRemoveChartPaletteMutationAPI } from '@utils/api/branding';
import { ColorPalette } from '@/types/branding';
import { useQueryDataAccessor } from './useQueryDataAccessor';

export const useRemoveChartPaletteMutation = (options: RemovePalette.Options = {}) => {
  const helpers = useMutationHelpers({
    onError: options.onError,
    onMutate: options.onMutate,
  });

  const mutation = useRemoveChartPaletteMutationAPI({
    ...options,
    ...helpers,
  });

  return mutation;
};

const useMutationHelpers = (options: Mutation.Options = {}) => {
  const accessor = useQueryDataAccessor();

  const onError: Mutation.Options<Mutation.Context>['onError'] = useCallback((e, variables, context) => {
    accessor.setQueryData(data => {
      return {
        ...data,
        theming: context.theming,
      };
    });

    options.onError?.(e, variables, context);

  }, [
    accessor,
    options,
  ]);

  const onMutate: Mutation.Options<Mutation.Context>['onMutate']  = useCallback(variables => {
    const previous = accessor.getQueryData();

    accessor.setQueryData(data => {
      const active = data.theming.themes.find(x => x.paletteId === variables.paletteId);

      const remaining = active
          ? data.theming.themes.filter(x => x.paletteId !== variables.paletteId)
          : data.theming.themes;

      return {
        ...data,
        theming: {
          ...data.theming,
          palettes: data.theming.palettes.filter(x => x.id !== variables.paletteId),
          themes: active
            ? [...remaining, findCategoryPreset(active.categoryId, data.theming.palettes)].sort((a, b) => a.categoryId - b.categoryId)
            : remaining,
        },
      };
    });

    options.onMutate?.(variables);

    return {
      theming: previous.theming,
    };
  }, [
    accessor,
    options,
  ]);

  return {
    onError,
    onMutate,
  };
};

function findCategoryPreset(categoryId: number, palettes: ColorPalette[]) {
  const palette = palettes.find(x => {
    return x.categoryId === categoryId
        && x.typeId === ColorPaletteType.Preset;
  });

  if (!palette) return;

  return {
    categoryId: palette.categoryId,
    paletteId: palette.id,
  };
}

declare namespace Mutation {
  export type Options<C = unknown> =
    Pick<RemovePalette.Options<C>,
    | 'onError'
    | 'onMutate'>;

  export type Context = Pick<Branding.FetchBranding.Response, 'theming'>;
}