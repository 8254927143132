import { Fragment, useCallback, useMemo } from 'react';
import { useConferenceInstance, useCoordinator, useTwilioVoice, IConference, useCanEnd } from '@containers/Conference';
import { useDebounceCallback } from '@utils';
import * as Buttons from '@/components/Conference.Buttons';
import { ConferenceLeave } from '@/components/Modal/Conference.Leave';
import { useModal } from '@/components/Modal/hooks/useModal';
import { useYourParticipant } from './hooks';
import styles from './style/Controls.css';

export function Controls() {
  const {
    isMuted,
    toggleMicrophone,
    microphoneSource,
    changeMicrophoneSource,
  } = useTwilioVoice();
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom>();
  const { leave } = useCoordinator();
  const you = useYourParticipant();

  const canEnd = useCanEnd();

  const handleMicrophone = useCallback(() => toggleMicrophone(), [toggleMicrophone]);
  const handleMicrophoneDebounced = useDebounceCallback(handleMicrophone, 50);

  const [toggleLeaveModal, ConferenceLeaveModal] = useModal(ConferenceLeave);

  const handleLeave = useCallback((end: boolean) => {
    leave({
      conferenceIdentifier: instance.conferenceIdentifier,
      end,
      title: instance.conference.title,
      redirect: end ? 'ended' : 'left',
      participant: instance.participant,
    });
  }, [instance.conference.title, instance.conferenceIdentifier, instance.participant, leave]);

  return (
    <Fragment>
      <div className={styles.root}>
        <Buttons.Microphone
          value={!isMuted}
          onClick={handleMicrophoneDebounced}
          onSelectDevice={changeMicrophoneSource}
          selectedDevice={microphoneSource}
          visible={true}
          disabledByInvisible={!you.isVisible && isMuted} />
        <Buttons.FSToggle />
        <Buttons.End
          onClick={() => toggleLeaveModal()}
          visible={true} />
      </div>
      <ConferenceLeaveModal
        isHost={you.isHost}
        canEnd={canEnd}
        onConfirm={handleLeave} />
    </Fragment>
  );
}