import { ComponentType } from 'react';
import { NodeViewComponentProps } from '@remirror/react';
import { useTranscriptSpeaker } from '@containers/Transcript/hooks/useTranscriptSpeaker';
import { MonologueDetails as MD } from '@/types/transcribe.rich-text';
import styles from './style/Snippet.Monologue.css';

export const SnippetMonologueDetails: ComponentType<NodeViewComponentProps> = ({ node }) => {
  const {
    spk: speakerIndex,
  } = node.attrs as MD.Attributes;

  const speaker = useTranscriptSpeaker(speakerIndex);

  return (
    <div
      className={styles.details}>
      <div className={styles.speaker}>({speaker.displayName})</div>
    </div>
  );
};

export default SnippetMonologueDetails;