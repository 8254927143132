import { useCallback, useContext } from 'react';
import * as $api from '@api';
import { SearchLoadingDispatchContext, SearchResultsDispatchContext } from '@/containers/WorkspaceGlobalSearch/Context';
import type { WorkspaceSearchFull } from '@/types';

export default function useSearchFetchBrands() {
  const setResults = useContext(SearchResultsDispatchContext);
  const setLoading = useContext(SearchLoadingDispatchContext);

  const brands = useCallback(async ({ filters, size }: FetchData) => {
    const result = await $api.workspaces.globalSearch.brands({
      filters,
      size,
    });

    if (result) {
      setResults(prev => ({
        ...prev,
        brands: result,
      }));
    }
  }, [setResults]);

  const fetch = useCallback(({ filters, size }: FetchData) => {
    setLoading(prev => ({ ...prev, brands: { ...prev.brands, loading: true } }));
    Promise.all([
      new Promise<void>(resolve => setTimeout(() => resolve(), 250)),
      brands({ filters, size }),
    ])
    .then(() => {
      setLoading(prev => ({ ...prev, brands: { loading: false } }));
    });
  }, [brands, setLoading]);

  return fetch;
}

export { useSearchFetchBrands };

type FetchData = {
  filters: WorkspaceSearchFull.Brand.Filters;
  size: number;
};