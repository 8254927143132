import type { SurveyAggregate } from '@/types';
import { formatWithOrdinalSuffix } from '@utils';

export function formatRankedText(value: number | string) {
  return value === 0
    ? 'N/A'
    : `Ranked ${formatWithOrdinalSuffix(+value)}`;
}

export function getSegmentColor(data: {
  key: string;
  keys: SurveyAggregate.StackedBarChart.Keys;
}, {
  extendedStrong = opts.extendedStrong,
  extendedWeak = opts.extendedWeak,
  strong = opts.strong,
  weak = opts.weak,
}: Options = opts) {
  if (data.key === data.keys.notApplicableKey) {
    return 'var(--gray-l)';
  }

  const total = data.keys.ids.length - 1;
  const index = data.keys.ids.findIndex(f => f === data.key);

  const NeutralColor = total <= 12
    ? NeutraRankingColor
    : ExtendedNeutralRankingColor;
  const StrongColors = total <= 12
    ? strong
    : extendedStrong;
  const WeakColors = total <= 12
    ? weak
    : extendedWeak;

  const middle = Math.ceil(total / 2);

  return index + 1 === middle && total % 2
    ? NeutralColor
    : index + 1 <= middle
      ? StrongColors[index]
      : WeakColors[total - index - 1]
  ;
}

type Options = {
  extendedStrong: string[];
  extendedWeak:   string[];
  strong:         string[];
  weak:           string[];
};

export const NeutraRankingColor = '#D1D1D1';
export const ExtendedNeutralRankingColor = '#EAEAEA';

export const WeakRankingColors = [
  '#E12E40',
  '#B7D97C',
  '#EE6166',
  '#EF7075',
  '#F07F85',
  '#F3979E',
];

export const ExtendedWeakRankingColors = [
  '#DD2734',
  '#E22E3C',
  '#E83D42',
  '#EF5151',
  '#F75F60',
  '#F86D6E',
  '#F87E83',
  '#F7878D',
  '#F89EA4',
  '#F8ACB2',
  '#FBC6CA',
  '#FBDCDE',
];

export const StrongRankingColors = [
  '#0B4DBC',
  '#2564B9',
  '#4074C2',
  '#5986C7',
  '#90AFDF',
  '#A5BEE2',
];

export const ExtendedStrongRankingColors = [
  '#0A4EBF',
  '#1456C3',
  '#2564B9',
  '#346BC2',
  '#4074C2',
  '#5D8BE1',
  '#759EE6',
  '#8FAFE2',
  '#A6C1EA',
  '#BCD2F2',
  '#DCE7F7',
  '#F0F2FC',
];

const opts = {
  extendedStrong: ExtendedStrongRankingColors,
  extendedWeak: ExtendedWeakRankingColors,
  strong: StrongRankingColors,
  weak: WeakRankingColors,
};