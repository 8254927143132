import { SurveyLogicRule, SurveyQuotaType } from '@enums/Survey';
import { SurveyQuota } from '@/types';
import {
  getDefaultAdvancedSet,
} from '@containers/SurveyBuilder.Quota/utils';
import { isQuestionInCondition, isOptionInCondition, isRowInCondition } from '@/containers/SurveyBuilder.Logic/utils.lookup';
import {
  SurveyBuilder,
  SurveyQuotasBuilder,
  SurveyQuestionsBuilder,
  SurveySectionsBuilder,
  SurveyOptionsBuilder,
  SurveyRowsBuilder,
} from '../interfaces';
import { updateQuestionInCondition } from './state.conditions';

export function getDefaultQuotaItem(action: SurveyQuotasBuilder.AddItem.Action): SurveyQuota.Record {
  const common = {
    actionType: null,
    id: null,
    identifier: action.identifier,
    limit: 0,
    name: '',
  };

  if (action.quotaType === SurveyQuotaType.Advanced) {
    return {
      ...common,
      data: {
        sets: [getDefaultAdvancedSet()],
      },
      type: SurveyQuotaType.Advanced,
    };
  } else if (action.quotaType === SurveyQuotaType.Basic) {
    return {
      ...common,
      data: {
        rule: {
          expression: null,
          type: SurveyLogicRule.AllOfTheAbove,
        },
        items: [],
      },
      type: SurveyQuotaType.Basic,
    };
  }
}

export function handleRemovedQuestions(state: SurveyQuotasBuilder.State, identifiers: string[]): SurveyQuotasBuilder.State {
  return state.reduce<SurveyQuotasBuilder.State>((acc, quota) => {
    if (quota.type === SurveyQuotaType.Advanced) {
      const updatedSets = quota.data.sets
        .filter(f => !identifiers.includes(f.question.identifier));

      acc.push({
        ...quota,
        data: {
          sets: updatedSets,
        },
      });
    } else if (quota.type === SurveyQuotaType.Basic) {
      const updatedItems = quota.data.items.filter(condition => {

        const questionsInCondition = identifiers.some(questionIdentifier => {
          return isQuestionInCondition({
            condition,
            question: { identifier: questionIdentifier },
          });
        });

        if (questionsInCondition) return false;

        return true;
      });

      acc.push({
        ...quota,
        data: {
          rule: quota.data.rule,
          items: updatedItems,
        },
      });
    }

    return acc;
  }, []);
}

export function questionRemoved(state: SurveyQuotasBuilder.State, action: SurveyQuestionsBuilder.QuestionRemoved.Action): SurveyQuotasBuilder.State {
  return handleRemovedQuestions(state, [action.questionIdentifier]);
}

export function questionTypeUpdated(state: SurveyQuotasBuilder.State, action: SurveyQuestionsBuilder.UpdateQuestionType.Action): SurveyQuotasBuilder.State {
  return handleRemovedQuestions(state, [action.questionIdentifier]);
}

export function optionRemoved(state: SurveyQuotasBuilder.State, action: SurveyOptionsBuilder.RemoveOption.Action): SurveyQuotasBuilder.State {
  return state.reduce<SurveyQuotasBuilder.State>((acc, quota) => {
    if (quota.type === SurveyQuotaType.Advanced) {
      const updatedSets = quota.data.sets.reduce<SurveyQuota.Advanced.Set[]>((acc2, x) => {
        const items = x.items
          .filter(f => f.condition.value.option.identifier !== action.option.identifier);

        acc2.push({
          ...x,
          items,
        });

        return acc2;
      }, []);

      acc.push({
        ...quota,
        data: {
          sets: updatedSets,
        },
      });
    } else if (quota.type === SurveyQuotaType.Basic) {
      const updatedItems = quota.data.items
        .filter(condition => !isOptionInCondition({
          condition,
          option: { identifier: action.option.identifier },
        }));

      acc.push({
        ...quota,
        data: {
          rule: quota.data.rule,
          items: updatedItems,
        },
      });
    }

    return acc;
  }, []);
}

export function rowRemoved(state: SurveyQuotasBuilder.State, action: SurveyRowsBuilder.RemoveRow.Action): SurveyQuotasBuilder.State {
  return state.reduce<SurveyQuotasBuilder.State>((acc, quota) => {
    if (quota.type === SurveyQuotaType.Advanced) {
      return acc.concat(quota);
    } else if (quota.type === SurveyQuotaType.Basic) {
      const updatedItems = quota.data.items
        .filter(condition => !isRowInCondition({
          condition,
          row: { identifier: action.row.identifier },
        }));

      return acc.concat({
        ...quota,
        data: {
          rule: quota.data.rule,
          items: updatedItems,
        },
      });
    }
  }, []);
}

export function sectionRemoved(state: SurveyBuilder.State, action: SurveySectionsBuilder.RemoveSection.Action): SurveyQuotasBuilder.State {
  const badIdentifiers = state.survey.questions
    .filter(f => f.section.identifier === action.identifier)
    .map(m => m.base.identifier);

  return handleRemovedQuestions(state.survey.quotas, badIdentifiers);
}

export function questionIdentifierUpdated(state: SurveyQuotasBuilder.State, action: SurveyQuestionsBuilder.UpdateQuestionIdentifier.Action): SurveyQuotasBuilder.State {
  return state.reduce((acc, x) => {
    if (x.type === SurveyQuotaType.Advanced) {
      // TODO: implement
    } else if (x.type === SurveyQuotaType.Basic) {
      const items = x.data.items.map(m => updateQuestionInCondition(m, action.oldIdentifier, action.newIdentifier));

      return acc.concat({
        ...x,
        data: {
          ...x.data,
          items,
        },
      });
    }

    return acc;
  }, []);
}
