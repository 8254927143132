import { useContext } from 'react';
import { path } from '@consts';
import { ProjectFilePreviewContainer } from '@/containers/Project.FilePreview';
import { WorkspaceFilePreviewLoadingContext } from '@/containers/Workspace.File.Preview/Context';
import styles from '@screens/Workspace.File/style/Workspace.File.css';
import { Header } from '@screens/Workspace.File/Header';
import { FileTabView } from '@screens/Workspace.File/File.TabView';
import { TabViewSkeleton } from '@/components/TabView';
import { useProjectFileTabMap } from './useProjectFileTabMap';

const StateDependent = () => {

  const isLoading = useContext(WorkspaceFilePreviewLoadingContext);

  const tabMap = useProjectFileTabMap();

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Header />
        {isLoading
            ? <TabViewSkeleton />
            : <FileTabView path={path.Projects.FileTabs} map={tabMap} />}
      </div>
    </div>
  );
};

export const ProjectFilePreview = () => (
  <ProjectFilePreviewContainer>
    <StateDependent />
  </ProjectFilePreviewContainer>
);

export default ProjectFilePreview;
