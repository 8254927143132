import type { ComponentType } from 'react';
import { useMemo } from 'react';
import type { NodeViewComponentProps } from '@remirror/react';
import { useSurveyBuilderState, useIsEditingSurvey } from '@containers/SurveyBuilder/hooks';
import type { SurveyRichText, SurveyTemplate } from '@/types/survey';
import { TemplateCompetitor as Label } from '@/components/Survey.Templates/Label.Competitor';

export const TemplateCompetitor: ComponentType<NodeViewComponentProps> = props => {

  const ctx = useSurveyBuilderState();

  return ctx
    ? <BuilderNode {...props} />
    : <Node {...props} />;
};

function BuilderNode({ node }: NodeViewComponentProps) {
  const [state] = useSurveyBuilderState();
  const editing = useIsEditingSurvey();

  const value = useMemo(() => {
    const id = (node.attrs as SurveyRichText.CompetitorNode['attrs']).id;
    const competitors = (state.survey.template.data as { competitors: SurveyTemplate.Competitor[] }).competitors;

    return competitors.find(f => f.id === id)?.value;
  }, [node.attrs, state.survey.template.data]);

  return (
    <Label
      disabled={editing}
      value={value} />
  );
}

function Node({ node }: NodeViewComponentProps) {
  const value = (node.attrs as SurveyRichText.CompetitorNode['attrs']).competitor;

  return (
    <Label
      disabled={true}
      value={value} />
  );
}

export default TemplateCompetitor;