import { useCallback, useContext, useEffect } from 'react';
import type { RemirrorEventListener } from 'remirror';
import { Remirror } from '@remirror/react';
import type { NodeExtension } from '@remirror/core';
import {
  InitialTranscriptPositionContext,
  TranscriptEditingContext,
  UpdateTranscriptTimestampMapsContext,
} from '@containers/Transcript/context';
import { useTranscriptDocumentContext } from '@containers/Transcript/hooks';
import { cx } from '@utils';
import { useRichTextTranscript, useScrollToSpeakerBlock } from '@/components/Transcript/hooks';
import type { TranscriptExtensions } from '@/components/Transcript/interfaces.extension.remirror';
import styles from './style/RichText.css';

type Props = {
  className?: string;
  editable?: boolean;
  MonologueDetailsComponent?: NodeExtension['ReactComponent'];
} & ChildrenProps;

export const Container = ({
  children,
  className,
  MonologueDetailsComponent,
}: Props) => {
  const [richTextEditing] = useContext(TranscriptEditingContext);
  const updateTimestampPositions = useContext(UpdateTranscriptTimestampMapsContext);

  const transcript = useTranscriptDocumentContext();
  const initialPosition = useContext(InitialTranscriptPositionContext);

  const { manager, onChange, state } = useRichTextTranscript({
    MonologueDetailsComponent,
    transcript: transcript.document,
  });

  // @ts-ignore
  const scrollToPosition = useScrollToSpeakerBlock(manager);

  useEffect(() => {
    //Make sure we update the state if the server query changes. Mostly used to clear the undo history after we save
    if (transcript) {
      manager.view.updateState(manager.createState({ content: transcript.document }));
    }
  }, [transcript, manager]);

  const resolvedClassName = cx(styles.root, className, {
    [styles.noselect]: !richTextEditing,
  });

  const handleChange: RemirrorEventListener<TranscriptExtensions> = useCallback(params => {
    if (params.tr?.docChanged) {
      updateTimestampPositions(params.view);
    }

    onChange(params);
  }, [onChange, updateTimestampPositions]);

  useEffect(() => {
    if (initialPosition) {
      scrollToPosition(initialPosition);
    }
  }, [initialPosition, scrollToPosition]);

  return (
    <Remirror
      classNames={[resolvedClassName]}
      editable={richTextEditing}
      manager={manager}
      onChange={handleChange}
      initialContent={state}>
      {children}
    </Remirror>
  );
};

Container.displayName = 'Transcript.RichText.Container';