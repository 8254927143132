import { Topics } from '@api/interfaces';
import http from '@services/http';
import { qs, safeJsonDate } from '@utils';
import { ContentFeed } from '@/types';
import * as xform from '@transformers';

export const fetchMembers = (data: Topics.FetchMembers.Request) => {
  return http.get<Topics.FetchMembers.Response>(`/topics/${data.slug}/members?c=${data.cursor}`);
};

export const fetchNews = (data: Topics.FetchNews.Request) => {
  const query = data.cursor
      ? `?from=${data.cursor}`
      : '';

  return http.get<Topics.FetchNews.Response<string>>(`/topics/${data.slug}/news${query}`)
  .then(data => ({
    items: data.items.map(x => ({
      ...x,
      publishDate: safeJsonDate(x.publishDate),
    })),
    pagination: data.pagination,
    next: data.pagination.next,
  }));
};

export const fetchOverview = (data: Topics.FetchOverview.Request) => {
  return http.get<Topics.FetchOverview.Response>(`/topics/${data.slug}`);
};

export const fetchPosts = (data: Topics.FetchPosts.Request) => {
  const url = `/topics/${data.slug}/posts${data.cursor ? `?c=${data.cursor}`: ''}`;
  return http.get<Topics.FetchPosts.Response<string>>(url)
  .then(result => ({
    items: result.items.map(m => xform.normalizePost(m)) as ContentFeed.Post[],
    next: result.next,
  }));
};

export const fetchSuggestedItems = (data: Topics.FetchSuggestedItems.Request = {}) => {
  const q = Object.keys(data).length
      ? `?${qs.stringify(data, { arrayFormat: 'bracket' })}`
      : '';
  return http.get<Topics.FetchSuggestedItems.Response>(`/topics/suggested${q}`);
};

export const followTopic = (data: Topics.FollowTopic.Request): Promise<void> => {
  return http.post(`/topics/${data.slug}/follow`, undefined);
};

export const unfollowTopic = (data: Topics.UnfollowTopic.Request): Promise<void> => {
  return http.post(`/topics/${data.slug}/unfollow`, undefined);
};

export const searchTopics = (data: Topics.SearchTopics.Request) => {
  return http.get<Topics.SearchTopics.Response>(`/topics/search?${qs.stringify(data)}`);
};