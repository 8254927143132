import { useCallback, useContext, useState } from 'react';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { usePopper } from 'react-popper';
import { MoreVerticalAnchorSmall } from '@presentation/Anchor';
import { useProjectState } from '@/containers/GroupProject/hooks';
import { QuantAnalysisContext } from '@/containers/Project.QuantAnalysis';
import { useDeleteQuantAnalysisItemModal, useRenameQuantAnalysisItemModal } from '@/components/Project.QuantAnalysis';
import type { QuantAnalysisItem } from '@/types/project.quant-analysis';
import { useZIndexModifier, PopperMenu, PopperMenuItem } from '@/components/Popper';
import { Portal } from '@/components/Portal';

type Props = {
  className?: string;
  analysisItem: QuantAnalysisItem;
};

export const AnalysisContextMenu = (props: Props) => {

  const [open, setOpen] = useState(false);

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);

  const zIndexModifier = useZIndexModifier({ zIndex: 8 });

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      zIndexModifier,
    ],
    placement: 'bottom-start',
  });

  const handleBlur = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const quantAnalysis = useContext(QuantAnalysisContext);
  const state = useProjectState();

  const [toggleDeleteModal, DeleteModal] = useDeleteQuantAnalysisItemModal();
  const [toggleRenameQuantAnalysisItemModal, RenameModal] = useRenameQuantAnalysisItemModal();

  return (
    <>
      <div
        className={props.className}
        ref={setReferenceElement}
        onClick={handleClick}>
        <MoreVerticalAnchorSmall
          open={open} />
      </div>
      {open &&
        <Portal>
          <ClickAwayListener
            mouseEvent='onMouseDown'
            onClickAway={handleBlur}
            touchEvent='onTouchStart'>
            <div
              ref={setPopperElement}
              style={popperStyles.popper}
              {...attributes.popper}>
              <PopperMenu>
                <PopperMenuItem onClick={toggleRenameQuantAnalysisItemModal}>
                  Rename
                </PopperMenuItem>
                <PopperMenuItem onClick={toggleDeleteModal}>
                  Delete
                </PopperMenuItem>
              </PopperMenu>
            </div>
          </ClickAwayListener>
        </Portal>
      }
      <DeleteModal
        projectId={state.project.id}
        quantAnalysisId={quantAnalysis.id}
        analysisItemId={props.analysisItem.id} />
      <RenameModal
        projectId={state.project.id}
        quantAnalysisId={quantAnalysis.id}
        analysisItem={props.analysisItem} />
    </>
  );
};