import { Permission, Role } from '@enums';
import { hasAccessFor } from './permissions.admin';

const permissions = {
  admin: {
    dashboard: Permission.InternalAdminDashboard,
    research: Permission.InternalResearchDashboard,
  },
  billing: {
    dashboard: Permission.InternalAdminBillingDashboard,
  },
  developer: {
    buildInfo: Permission.DeveloperBuildInfo,
  },
};

type HasPermission = (user: Pick<Store.User, 'permissions'>) => boolean;

export const hasInternalAdminAccess: HasPermission = user => {
  return user.permissions.some(p => p === permissions.admin.dashboard);
};

export const hasInternalResearchAccess: HasPermission = user => {
  return user.permissions.some(p => p === permissions.admin.research);
};

export const hasInternalAdminBillingAccess = hasAccessFor.billing.dashboard;

export const hasGroupBillingAdminPermission: HasPermission = user => {
  return user.permissions.includes(Permission.GroupBillingAdmin);
};

export const hasBuildInfo: HasPermission = user => {
  return user.permissions.some(p => p === permissions.developer.buildInfo);
};

export const hasSchedulingPermission: HasPermission = user => {
  return user.permissions.some(p => p === Permission.InternalResearchScheduling);
};

export const hasBetaFeatureAccess: HasPermission = user => {
  return user.permissions.some(p => p === Permission.BetaMain);
};

export const hasCallTranscriptWritePermission: HasPermission = user => {
  return user.permissions.some(p => p === Permission.CallTranscriptWrite);
};

export const hasUniversalSearchAccess = (user: Pick<Store.User, 'roles'>) => {
  return access.has.role.none(
    Role.FirmTransient,
    Role.DataSteward,
    Role.InsightsChatOnly,
  )(user.roles);
};

export const access = {
  has: {
    permission: {
      none: (...params: Permission[]) => (permissions: Permission[]) => params.every(x => !permissions.includes(x)),
      some: (...params: Permission[]) => (permissions: Permission[]) => permissions.some(x => params.includes(x)),
    },
    role: {
      none: (...params: Role[]) => (roles: Role[]) => params.every(x => !roles.includes(x)),
      some: (...params: Role[]) => (roles: Role[]) => roles.some(x => params.includes(x)),
    },
  },
};