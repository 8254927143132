import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Editable } from '@/components/Editable';
import { createConditional } from '@/containers/Conditional';
import { ProjectDetailsContext } from '@/containers/GroupProject/Context';
import { useProjectGoal, useProjectState, useSaveProjectOverview } from '@/containers/GroupProject/hooks';
import type { ProjectOverviewFormState } from '@/containers/GroupProject/interfaces';
import * as enums from '@/enums';
import * as Tab from '@/presentation/Workspace.Tab.Pane';
import type { Project } from '@/types';
import { useConditionalRender } from '@/utils';
import { ProjectOverviewForm } from './ProjectOverviewForm';
import styles from './style.css';
import * as utils from './utils';

const mapState = (state: Store.State) => state.group.features;

type Props = {
  editable: boolean;
  item: Project;
};

const ProjectOverview = (props: Props) => {
  const projectGoal = useProjectGoal();

  const [goal, setGoalValue] = useState<number>(null);
  const [editing, setEditing] = useState(false);
  const groupFeatures = useSelector(mapState);
  const saveOverview = useSaveProjectOverview();
  const { projectParent } = useProjectState();

  const ctx = useContext(ProjectDetailsContext);

  useEffect(() => {

    if (projectGoal?.goal?.value) {
      setGoalValue(projectGoal.goal.value);
    }

  }, [projectGoal?.goal?.value]);

  const toggleEditing = useCallback(() => {
    setEditing(!editing);
  }, [
    editing,
    setEditing,
  ]);

  const handleSubmit = useCallback((form: ProjectOverviewFormState) => {
    saveOverview(form)
      .then(data => {
        setGoalValue(data.goal.value);

        toggleEditing();
      });

  }, [
    saveOverview,
    toggleEditing,
  ]);

  const includeFrequency = useMemo(() => utils.includeFrequency(props.item, groupFeatures), [groupFeatures, props.item]);
  const $Frequency = useConditionalRender(includeFrequency);

  const content = (
    <Tab.Items.Container>
      <Tab.Item.Container>
        <Tab.Item.TextLabel>Project Code</Tab.Item.TextLabel>
        <Tab.Item.Value>
          {ctx.query.data?.project?.netsuite?.name
            ? <div className={styles.field}>{ctx.query.data?.project?.netsuite?.name}</div>
            : <div className={styles.noneText}>None</div>}
        </Tab.Item.Value>
      </Tab.Item.Container>
      <Tab.Item.Container>
        <Tab.Item.TextLabel>PMR Platform Fee Purchase Order</Tab.Item.TextLabel>
        <Tab.Item.Value>
          {ctx.query.data?.project?.purchaseOrder
            ? <div className={styles.field}>{ctx.query.data?.project?.purchaseOrder}</div>
            : <div className={styles.noneText}>None</div>}
        </Tab.Item.Value>
      </Tab.Item.Container>
      <Tab.Item.Container>
        <Tab.Item.TextLabel>Respondent Title</Tab.Item.TextLabel>
        <Tab.Item.Value>
          {props.item.rawName}
        </Tab.Item.Value>
      </Tab.Item.Container>
      <Tab.Item.Container>
        <Tab.Item.TextLabel>Client Title</Tab.Item.TextLabel>
        <Tab.Item.Value>
          {props.item.clientName || <span className={styles.none}>None</span>}
        </Tab.Item.Value>
      </Tab.Item.Container>
      <Tab.Item.Container>
        <Tab.Item.TextLabel>Client Account</Tab.Item.TextLabel>
        <Tab.Item.Value>{ctx.query.data?.project?.account?.name ?? '-'}</Tab.Item.Value>
      </Tab.Item.Container>
      <$Frequency>
        <Tab.Item.Container>
          <Tab.Item.TextLabel>Frequency</Tab.Item.TextLabel>
          <Tab.Item.Value>
            {enums.utils.ProjectFrequency.getName(projectParent.frequency)}
          </Tab.Item.Value>
        </Tab.Item.Container>
      </$Frequency>
      <Tab.Item.Container>
        <Tab.Item.TextLabel>Description</Tab.Item.TextLabel>
        <Tab.Item.Value>
          {props.item.description}
        </Tab.Item.Value>
      </Tab.Item.Container>
      <Tab.Item.Container>
        <Tab.Item.TextLabel>Goal</Tab.Item.TextLabel>
        <Tab.Item.Value>
          {goal
            ? goal
            : <div className={styles.noneText}>None</div>}
        </Tab.Item.Value>
      </Tab.Item.Container>
    </Tab.Items.Container>
  );

  const ProjectOverview = createConditional(props.editable, content);

  return (
    <div className={styles.card}>
      {editing &&
        <ProjectOverviewForm
          account={ctx.query.data?.project?.account}
          frequency={projectParent.frequency}
          goal={goal}
          item={props.item}
          netsuite={ctx.query.data?.project?.netsuite}
          purchaseOrder={ctx.query.data?.project?.purchaseOrder}
          onCancel={toggleEditing}
          onSubmit={handleSubmit} />}
      {!editing &&
        <ProjectOverview>
          <Editable onClick={toggleEditing}>
            {content}
          </Editable>
        </ProjectOverview>}
    </div>
  );
};

export { ProjectOverview };
export default ProjectOverview;