import type { ProjectType } from '@/enums';
import ListEmpty from './ListEmpty';
import ProjectShareLink from './ProjectShareLink';
import styles from './style.css';

type Props = {
  resultsCount: number;
  totalCount:   number;
  visible:      boolean;
  project: {
    id: number;
    name: string;
    projectType: ProjectType;
  };
};

const ProjectSearchListFooter = (props: Props) => {
  if (!props.visible || props.resultsCount !== props.totalCount) return null;

  return (
    <>
      <ListEmpty
        resultsCount={props.resultsCount}
        totalCount={props.totalCount} />

      <div className={styles.root}>
        <div className={styles.wrap}>
          {props.resultsCount > 0 &&
            <div className={styles.zero}>Try expanding your search criteria for more results.</div>}

          <div>
            <div className={styles.vancery}>Our team has been notified and is working on sourcing candidates.</div>
            <ProjectShareLink project={props.project} />
          </div>
        </div>
      </div>
    </>
  );
};

export { ProjectSearchListFooter };
export default ProjectSearchListFooter;