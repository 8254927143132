import AccountInvoicingMethod from '@enums/AccountInvoicingMethod';
import AccountPaymentMethod from '@enums/AccountPaymentMethod';
import ApprovalStatus from '@enums/ApprovalStatus';
import AudienceType from '@enums/AudienceType';
import BillingPeriod from '@enums/BillingPeriod';
import BillingPayoutApprovalStep from '@enums/BillingPayoutApprovalStep';
import CallRole from '@enums/CallRole';
import CallStatus from '@enums/CallStatus';
import CallType from '@enums/CallType';
import ChargebleType from '@enums/ChargeableType';
import ChargeStatus from '@enums/ChargeStatus';
import Conference from '@/enums/conference';
import CoveredLives from '@enums/CoveredLives';
import DocumentSigning from '@enums/DocumentSigning';
import HCPType from '@enums/HCPType';
import IdentityProvider from '@enums/IdentityProvider';
import LeadStatus from '@enums/LeadStatus';
import LicenseType from '@enums/LicenseType';
import Payment from '@enums/Payment';
import PayerAdminType from '@/enums/PayerAdminType';
import PayoutableType from '@enums/PayoutableType';
import Tipalti from '@/enums/tipalti';
import PayoutStatus from '@enums/PayoutStatus';
import Permission from '@enums/Permission';
import ProfileSecondaryScreener from '@enums/profile-screener';
import ProfessionalRole from '@enums/ProfessionalRole';
import Project from '@enums/Project';
import InvoiceStatus from '@enums/InvoiceStatus';
import ReviewOption from '@enums/ReviewOption';
import Role from '@enums/Role';
import Seniority from '@enums/Seniority';
import Survey from '@enums/Survey';
import SurveyTemplate from '@enums/survey.template';
import StripePayoutConnectTransferStatus from '@enums/StripePayoutConnectTransferStatus';
import TokenType from '@enums/TokenType';
import TranscriptEntityType from '@/enums/transcript';
import TranscriptionRevisionStatus from '@/enums/TranscriptionRevisionStatus';
import UserProjectStatus from '@enums/UserProjectStatus';
import VerificationStatus from '@enums/VerificationStatus';
import VICS from '@enums/VICS';
import Workspace from '@enums/Workspace';

const enums = {
  ...AccountInvoicingMethod,
  ...AccountPaymentMethod,
  ...ApprovalStatus,
  ...AudienceType,
  ...BillingPeriod,
  ...BillingPayoutApprovalStep,
  ...CallRole,
  ...CallStatus,
  ...CallType,
  ...ChargebleType,
  ...ChargeStatus,
  ...CoveredLives,
  ...DocumentSigning,
  ...HCPType,
  ...IdentityProvider,
  ...Conference,
  ...LeadStatus,
  ...LicenseType,
  ...PayerAdminType,
  ...Payment,
  ...PayoutableType,
  ...PayoutStatus,
  ...Permission,
  ...ProfessionalRole,
  ...ProfileSecondaryScreener,
  ...Project,
  ...InvoiceStatus,
  ...ReviewOption,
  ...Role,
  ...Seniority,
  ...Survey,
  ...SurveyTemplate,
  ...StripePayoutConnectTransferStatus,
  ...Tipalti,
  ...TokenType,
  ...TranscriptEntityType,
  ...TranscriptionRevisionStatus,
  ...UserProjectStatus,
  ...VerificationStatus,
  ...VICS,
  ...Workspace,
};

type Helpers = Helper<typeof enums>;

type Helper<T> = {
  [P in keyof T]: T[P];
};

export const utils = Object.keys(enums).reduce((acc, key: keyof typeof enums) => {
  // @ts-ignore
  acc[key] = enums[key];
  return acc;
}, {} as Helpers);