import { Input, InputProps } from '@/components/Input';

type Props = InputProps;

export const Title = (props: Props) => {

  return (
    <Input {...props} />
  );
};

Title.displayName = 'Project.Honoraria.Field.Title';