import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectMessageItems } from '@store/selectors';

const mapState = (state: Store.State) => ({
  participants: state.chat.participants,
  messages: selectMessageItems(state),
});

const useAllowMessageModal = () => {
  const { participants, messages } = useSelector(mapState);

  const hasMessages = useCallback((userId: number) => {
    const participant = participants[userId];
    if (!participant) return false;

    const conversationMessages = messages[participant.conversationSid];
    return !!conversationMessages.length;
  }, [
    participants,
    messages,
  ]);

  return useCallback((userId: number) => !hasMessages(userId), [hasMessages]);
};

export { useAllowMessageModal };
export default useAllowMessageModal;