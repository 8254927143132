import { useCallback, useContext, useMemo } from 'react';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { ProjectConferenceTagWithMetadata } from '@/types';
import { Button } from '@/components/Button';
import { Modal, Header } from '@/components/Modal';
import { ModalProps } from '@/components/Modal/Modal';
import { TagFormContext, TagStateContext } from '@/components/Project.Tags/Context';
import { useUpdateTagMutation } from '@/components/Project.Tags/hooks';
import { TagForm } from './TagForm';
import { TagFormContainer } from './TagFormContainer';
import styles from './style/CreateTagModal.css';
import { tagFormIsValid } from './utils';

type Props = Pick<ModalProps, 'open' | 'onClose'> & {
  tag: ProjectConferenceTagWithMetadata;
  hasChildren: boolean;
};
export const EditTag = ({ open, onClose, tag, hasChildren }: Props) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Header>Edit Tag</Header>
      <TagFormContainer defaultState={{ parentId: tag.parent?.id, title: tag.base.name, color: tag.base.color, includeSummary: tag.includeSummary }}>
        <ModalContent
          onClose={onClose}
          projectTagId={tag.id}
          hasChildren={hasChildren} />
      </TagFormContainer>
    </Modal>
  );
};

type ContentProps = Pick<ModalProps, 'onClose'> &
{
  projectTagId: number;
  hasChildren: boolean;
};

const ModalContent = ({ onClose, projectTagId, hasChildren }: ContentProps) => {
  const { projectId } = useContext(TagStateContext);
  const { data } = useContext(TagFormContext);

  const canSave = useMemo(() => tagFormIsValid(data), [data]);

  const { mutateAsync, isLoading } = useUpdateTagMutation({ projectId, projectTagId });
  const updateTag = useCallback(() => {
    mutateAsync({
      title: data.title,
      color: data.color,
      includeSummary: data.includeSummary,
      parentId: data.parentId,
    }).then(onClose);
  }, [mutateAsync, onClose, data]);
  return (
    <>
      <TagForm disableParent={hasChildren} excludeParentIds={[projectTagId]} />
      <div className={styles.actions}>
        <Button
          variant='brick'
          color='destructive'
          onClick={onClose}>
          Cancel
        </Button>
        <ButtonActivityIndicator
          disabled={!canSave}
          implicitDisable={false}
          loading={isLoading}
          variant='brick'
          onClick={updateTag}>
          Save
        </ButtonActivityIndicator>
      </div>
    </>
  );
};