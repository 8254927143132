import Skeleton, { SkeletonProps } from '@mui/material/Skeleton';
import { cx } from '@utils';
import * as Article from '@/components/Post.Article';
import styles from './style/Skeleton.css';

type Props = unknown;

export const ArticleBody = (props: Props) => {
  return (
    <>
      <SkeletonParagraphText />
      <SkeletonParagraphText />
      <SkeletonParagraphText />
    </>
  );
};

ArticleBody.displayName = 'Post.Article.Skeleton.ArticleBody';

export const ArticleTitle = (props: Props) => {
  return (
    <div className={styles.header}>
      <Skeleton
        height={50}
        width="85%"
        variant="rectangular" />
    </div>
  );
};

ArticleTitle.displayName = 'Post.Article.Skeleton.ArticleTitle';

export const Author = (props: Props) => {
  return (
    <Article.Author item={null} />
  );
};

Author.displayName = 'Post.Article.Skeleton.Author';

export const CoverImage = (props: Props) => {
  return (
    <div className={styles.cover}>
      <Article.CoverImage />
      <div className={styles.box}>
        <Skeleton
          height="100%"
          variant="rectangular" />
      </div>
    </div>
  );
};

CoverImage.displayName = 'Post.Article.Skeleton.CoverImage';

type SkeletonTextProps = {
  className?: string;
} & Omit<SkeletonProps, 'variant'>;

const SkeletonText = ({ className, height = 30, ...props }: SkeletonTextProps) => {
  const classnames = {
    root: cx(className, styles.text),
  };

  return (
    <div className={classnames.root}>
      <Skeleton
        {...props}
        height={height}
        variant="text" />
    </div>
  );
};

type SkeletonParagraphTextProps = {
  className?: string;
};

const SkeletonParagraphText = ({ className, ...props }: SkeletonParagraphTextProps) => {
  const classnames = {
    root: cx(className, styles.paragraph),
  };

  return (
    <div className={classnames.root}>
      <SkeletonText
        width="90%" />
      <SkeletonText
        width="95%" />
      <SkeletonText
        width="70%" />
    </div>
  );
};