import { useContext, useMemo } from 'react';
import { MedicalBackgroundContext, MedicalBackgroundPracticeContext } from '@containers/MedicalOnboarding/Context';
import { useOnSubmitPractice } from '@containers/MedicalOnboarding/hooks';
import * as Field from '@/components/MedicalProfile/Field';
import * as Layout from '@/components/MedicalProfile/Layout';
import { Label } from '@/components/MedicalProfile/Label';
import { RadioGroup } from '@/components/MedicalProfile/RadioGroup';
import { BackNavigationProps, NextNavigationProps, OnSuccessHandler } from './interfaces';
import styles from './style/Practice.css';

type Props = {
  back?:      BackNavigationProps;
  next?:      NextNavigationProps;
  onSuccess?: OnSuccessHandler<'practice'>;
};

export const Practice = ({ back = {}, next = {}, onSuccess, ...props }: Props) => {
  const ctx = useContext(MedicalBackgroundContext);
  const practice = useContext(MedicalBackgroundPracticeContext);

  const handleSubmit = useOnSubmitPractice(data => {
    ctx.mutation.mutate({
      practice: {
        employer: data.employer,
        medicalFacilityType: data.medicalFacilityType,
        practiceTime: data.practiceTime,
      },
    }, {
      onSuccess: (data, variables) => {
        onSuccess?.(variables);
      },
    });
  }, [
    ctx.mutation,
    onSuccess,
  ]);

  const validation = useMemo(() => {
    const errors = {
      practiceTime: !(practice.form.practiceTime.answer.items.reduce((acc, x) => x.value + acc, 0) === 100),
      medicalFacilityType: !practice.form.medicalFacilityType.answer,
      employer: false, // !practice.form.employer?.name,
    };

    const disabled = Object.values(errors).some(x => x === true);

    return {
      disabled,
      errors,
    };
  }, [
    practice.form,
  ]);

  return (
    <Layout.Screen>
      <Layout.Header title={copy.title} />
      <div className={styles.item}>
        <Label required>
          {practice.form.practiceTime.question.value}
        </Label>
        <div className={styles.field}>
          <Field.PracticeTime />
        </div>
      </div>
      <div className={styles.item}>
        <Label required>
          {practice.form.medicalFacilityType.question.value}
        </Label>
        <div className={styles.field}>
          <RadioGroup
            onChange={optionId => practice.setValue({ medicalFacilityType: optionId })}
            options={practice.form.medicalFacilityType.question.options}
            selected={practice.form.medicalFacilityType.answer} />
        </div>
      </div>

      <div className={styles.item}>
        <Label>
          {copy.label.employer}
        </Label>
        <div className={styles.field}>
          <Field.Hospitals
            className={styles.input}
            placeholder={copy.placeholder.employer} />
        </div>
      </div>
      <Layout.Footer className={styles.footer}>
        {back && <Layout.BackButton />}
        <Layout.NextButton
          disabled={validation.disabled}
          implicitDisable={false}
          loading={ctx.mutation.isLoading}
          onClick={handleSubmit}>
          {next.text}
        </Layout.NextButton>
      </Layout.Footer>
    </Layout.Screen>
  );
};

Practice.displayName = 'MedicalOnboarding.Practice';

const copy = {
  label: {
    employer: `If you are comfortable, please tell us the name of the hospital or practice you are currently employed by.`,
  },
  placeholder: {
    employer: `Hospital or Practice`,
  },
  title: `Next we'd like to know a bit more about your practice and medical activities for your role.`,
};