import { forwardRef, useCallback, useMemo } from 'react';
import * as consts from '@consts';
import { ProjectType } from '@enums/Project';
import { ProjectDescriptionInput } from '@/components/GroupProject/ProjectDescriptionInput';
import { useProjectFormContext, useProjectCreationNavigation } from './context';
import { BackButton, Footer, NextButton } from './Footer';
import { Header, Subtitle, Title } from './Header';
import styles from './style/style.old.css';

export const ProjectDescription = forwardRef<HTMLTextAreaElement>((_, ref) => {

  const [form, setFormState] = useProjectFormContext();

  const subtitle = useMemo(() => {
    return {
      [ProjectType.Survey]: `Please describe what you are trying to learn and any background information that would be helpful for the research project.`,
      [ProjectType.Call]: `Please describe what you are trying to learn and any background information that would be helpful for the research project.`,
      [ProjectType.SurveyCall]: `Please describe what you are trying to learn and any background information that would be helpful for the consultation.`,
    }[form.projectType];
  }, [form.projectType]);

  const actions = useProjectCreationNavigation();

  const enable = form.description.length
    && form.description.length <= consts.maxLengths.PROJECT_DESCRIPTION;

  const onChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormState({ description: e.target.value });
  }, [setFormState]);

  return (
    <>
      <div className={styles.section}>
        <Header className={styles.row}>
          <Title className={styles.row}>Description</Title>
          <Subtitle className={styles.row}>{subtitle}</Subtitle>
        </Header>
        <div className={styles.ta}>
          <ProjectDescriptionInput
            className={styles.description}
            ref={ref}
            onChange={onChange}
            value={form.description} />
        </div>
      </div>
      <Footer>
        <BackButton />
        <NextButton
          disabled={!enable}
          onClick={actions.next} />
      </Footer>
    </>
  );
});