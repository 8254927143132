import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { arr } from '@utils';

const selectRoles = (state: Store.State) => state.user.roles;

const useRoleValidator = () => {
  const roles = useSelector(selectRoles);

  const validator = useCallback((data?: Store.User.Roles) => {

    return !arr.nonEmpty(data) || roles.some(x => data.includes(x));

  }, [roles]);

  return validator;
};

export { useRoleValidator };
export default useRoleValidator;