import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useMatchProjectIdFromUrl } from '@containers/GroupProject/hooks';
import * as Nav from '$website/components/core/Navbar/Nav.Logo';
import { Navbar } from '$website/components/core/Navbar/Navbar';
import { SurveyMonitoringPasswordRequiredContext } from './Context';
import { SurveyMonitoringAuth } from './AuthScreen';
import { SurveyMonitoring } from './SurveyMonitoring';
import styles from './style/Container.css';

const selectAuthenticated = (state: Store.State) => state.user.state.authenticated;

export const SurveyMonitoringContainer = () => {
  const authenticated = useSelector(selectAuthenticated);

  return (
    <>
      {!authenticated &&
        <Navbar.LayoutRoot>
          <div className={styles.nav}>
            <Nav.Logo />
          </div>
        </Navbar.LayoutRoot>}

      <SurveyMonitoringAuthContainer>
        <div className={styles.root}>
          <div className={styles.wrap}>
            <SurveyMonitoringDashboard />
          </div>
        </div>
      </SurveyMonitoringAuthContainer>
    </>
  );
};

type SurveyMonitoringAuthContainerProps = {
  children: React.ReactNode;
};

export const SurveyMonitoringAuthContainer = (props: SurveyMonitoringAuthContainerProps) => {
  const [authRequired, setAuthRequired] = useState<boolean>(null);

  return (
    <SurveyMonitoringPasswordRequiredContext.Provider value={[authRequired, setAuthRequired]}>
      {authRequired && <SurveyMonitoringAuth />}
      {!authRequired && props.children}
    </SurveyMonitoringPasswordRequiredContext.Provider>
  );
};

const SurveyMonitoringDashboard = () => {
  const { projectId } = useMatchProjectIdFromUrl();

  const { surveyId } = useParams<Stringify<ISurveyId>>();

  return (
    <SurveyMonitoring projectId={projectId} surveyId={surveyId ? +surveyId : null} />
  );
};