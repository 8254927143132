import { useCallback, useContext, useMemo } from 'react';
import {
  useQueryParams,
  NumericArrayParam,
  BooleanParam,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params';
import { useProjectOrLatestChild, useSuspendInitialLoading } from '@containers/GroupProject/hooks';
import {
  ProjectTagsQueryContainer,
  ProjectTagsQueryContext,
} from '@containers/Group.Project.Tags';
import type { ProjectClips } from '@/types';
import {
  useUpdateTranscriptRangeTagsMutation,
  useSyncProjectClipsRangeQueryData,
  useProjectClipsQuery,
  useProjectTranscriptsQuery,
  useInvalidateProjectClipsQuery,
  useAddGoodQuoteInQueryData,
  useStarTranscriptQuoteMutation,
} from '@utils/api';
import { TaggedMomentBuilderContainer } from '@containers/Transcript/TaggedMoment.Modal';
import Toast from '@/components/Toast';
import type { Filters, StarQuote, UpdateTranscriptRangeTags } from './interfaces';
import {
  ProjectClipsStarQuoteContext,
  ProjectClipsDataContext,
  ProjectClipsSortContext,
  ProjectClipsPaginationContext,
  ProjectClipsFiltersContext,
  SetProjectClipsFiltersContext,
  ProjectClipsTranscriptSearchContext,
  ProjectClipsUpdateTranscriptRangeTagsContext,
} from './context';

type Props = ChildrenProps;

const ProjectClipsContainer = (props: Props) => {

  const project = useProjectOrLatestChild();

  const tagsQuery = useContext(ProjectTagsQueryContext);

  const [q, setQuery] = useQueryParams({
    keyword: withDefault(StringParam, ''),
    groupTagIds: NumericArrayParam,
    tagIds: NumericArrayParam,
    speakerRole: withDefault(StringParam, 'all'),
    transcriptIds: NumericArrayParam,
    pageSize: withDefault(NumberParam, 25),
    pageIndex: withDefault(NumberParam, 0),
    sort: withDefault(StringParam, 'tags'),
    sortDesc: withDefault(BooleanParam, false, false),
  });

  const onSortClick = useCallback((sort: string) => {
    setQuery({
      pageIndex: 0,
      sort,
      sortDesc: sort === q.sort ? !q.sortDesc : true,
    }, 'replaceIn');
  }, [setQuery, q.sortDesc, q.sort]);

  const setFilters = useCallback((filters: Partial<Filters>) => {
    setQuery({
      pageIndex: 0,
      ...filters,
    }, 'replaceIn');
  }, [setQuery]);

  // Using query param withDefault doesn't seem to memoize array properly
  const filters = useMemo(() => ({
    keyword: q.keyword,
    groupTagIds: q.groupTagIds || [],
    speakerRole: (q.speakerRole || 'all') as ProjectClips.SpeakerFilter,
    tagIds: q.tagIds || [],
    transcriptIds: q.transcriptIds || [],
  }), [q]);

  const clipsQueryProps = useMemo(() => ({
    groupTagIds: filters.groupTagIds,
    projectId: project.id,
    keywords: filters.keyword ? [filters.keyword] : [],
    pageIndex: q.pageIndex,
    pageSize: q.pageSize,
    sort: q.sort,
    sortDesc: !!q.sortDesc,
    speakerRole: filters.speakerRole,
    tagIds: filters.tagIds,
    transcriptIds: filters.transcriptIds,
  }), [filters, project.id, q.pageIndex, q.pageSize, q.sort, q.sortDesc]);

  const clipsQuery = useProjectClipsQuery(clipsQueryProps, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const invalidateClipsQuery = useInvalidateProjectClipsQuery();
  const addGoodQuoteInQueryData = useAddGoodQuoteInQueryData(clipsQueryProps);
  const syncTranscriptRangeQueryData = useSyncProjectClipsRangeQueryData(clipsQueryProps);

  const {
    mutateAsync: updateTranscriptRangeTagsMutation,
  } = useUpdateTranscriptRangeTagsMutation({
    onMutate: data => {
      const inserts = data.inserts.map(i => {
        const queryTag = tagsQuery.data.tags.find(t => t.base.id === i.tagId);
        return {
          id: i.tagId,
          name: queryTag.base.name,
          color: queryTag.base.color,
          typeId: queryTag.base.typeId,
          moment: { start: i.tagStart, end: i.tagEnd },
        };
      });
      syncTranscriptRangeQueryData({
        transcriptId: data.transcriptId,
        range: data.range,
        deletes: data.deletes.map(m => m.tagId),
        inserts,
      });
    },
    onSuccess: res => {
      // invalidateClipsQuery();
    },
    onError: () => {
      Toast.error({
        title: `Error`,
        body: `Couldn't update tags.`,
      });
    },
  });

  const {
    mutateAsync: starQuoteMutation,
  } = useStarTranscriptQuoteMutation({
    onMutate: data => {
      addGoodQuoteInQueryData({
        transcriptId: data.transcriptId,
        range: { start: data.fromTs, end: data.toTs },
      });
    },
    onSuccess: res => {
      // invalidateClipsQuery();
    },
    onError: () => {
      Toast.error({
        title: `Error`,
        body: `Couldn't add good quote.`,
      });
    },
  });

  const starQuote: StarQuote = useCallback(params => {
    return starQuoteMutation({
      fromTs: params.range.start,
      toTs: params.range.end,
      transcriptId: params.transcriptId,
    });
  }, [starQuoteMutation]);

  const updateTranscriptRangeTags: UpdateTranscriptRangeTags = useCallback(params => {
    return updateTranscriptRangeTagsMutation({
      inserts: params.inserts,
      deletes: params.deletes,
      transcriptId: params.transcriptId,
      range: params.range,
    });
  }, [updateTranscriptRangeTagsMutation]);

  const transcriptsQuery = useProjectTranscriptsQuery({
    projectId: project.id,
  }, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const loading = useMemo(() => {
    return clipsQuery.isInitialLoading && !clipsQuery.data;
  }, [clipsQuery.isInitialLoading, clipsQuery.data]);

  const data = useMemo(() => {
    return loading
      ? getLazyTableData(25)
      : clipsQuery.data?.items ?? [];
  }, [loading, clipsQuery.data]);

  const empty = useMemo(() => {
    return clipsQuery.isFetchedAfterMount && !loading && !data.length;
  }, [clipsQuery.isFetchedAfterMount, loading, data]);

  const suspended = useSuspendInitialLoading({
    data,
    empty,
    isInitialLoading: clipsQuery.isInitialLoading,
    loading,
    pagination: {
      pageCount: clipsQuery.data ? clipsQuery.data.items.length : 0,
      totalCount: clipsQuery.data ? clipsQuery.data.total : 0,
    },
  }, 500);

  const paginationContext = {
    pageSize: q.pageSize,
    pageIndex: q.pageIndex,
    setPageSize: useCallback((pageSize: number) => setQuery({ pageSize }, 'replaceIn'), [setQuery]),
    setPageIndex: useCallback((pageIndex: number) => setQuery({ pageIndex }, 'replaceIn'), [setQuery]),
    totalRows: suspended.pagination.totalCount,
  };

  const sortContext = {
    sort: q.sort,
    sortDesc: q.sortDesc,
    onSortClick,
  };

  return (
    <ProjectClipsTranscriptSearchContext.Provider value={transcriptsQuery.data || []}>
      <SetProjectClipsFiltersContext.Provider value={setFilters}>
        <ProjectClipsFiltersContext.Provider value={filters}>
          <ProjectClipsPaginationContext.Provider value={paginationContext}>
            <ProjectClipsSortContext.Provider value={sortContext}>
              <ProjectClipsDataContext.Provider value={suspended}>
                <ProjectClipsStarQuoteContext.Provider value={starQuote}>
                  <ProjectClipsUpdateTranscriptRangeTagsContext.Provider value={updateTranscriptRangeTags}>
                    <TaggedMomentBuilderContainer onSuccess={invalidateClipsQuery}>
                      {props.children}
                    </TaggedMomentBuilderContainer>
                  </ProjectClipsUpdateTranscriptRangeTagsContext.Provider>
                </ProjectClipsStarQuoteContext.Provider>
              </ProjectClipsDataContext.Provider>
            </ProjectClipsSortContext.Provider>
          </ProjectClipsPaginationContext.Provider>
        </ProjectClipsFiltersContext.Provider>
      </SetProjectClipsFiltersContext.Provider>
    </ProjectClipsTranscriptSearchContext.Provider>
  );
};

const getLazyTableData = <T extends ProjectClips.Item>(pageSize = 25) => {
  return Array.from({ length: pageSize }, _ => ({} as T));
};

const WithState = (props: Props) => {
  const project = useProjectOrLatestChild();

  return (
    <ProjectTagsQueryContainer projectId={project.id} enabled={true}>
      <ProjectClipsContainer>
        {props.children}
      </ProjectClipsContainer>
    </ProjectTagsQueryContainer>
  );
};

export { WithState as ProjectClipsContainer };