import { cx } from '@utils';
import styles from './style/OnboardingChecklist.css';

type Props = {
  className?: string;
  loading?:   boolean;
  total:      number;
  value:      number;
};

export const Progress = (props: Props) => {
  const value = props.loading
      ? props.total
      : props.value;

  const style = {
    width: `${((props.total - value) * 100) / props.total}%`,
  };

  return (
    <div className={cx(styles.progress, props.className)}>
      <div
        className={styles.bar}
        style={style} />
    </div>
  );
};

Progress.displayName = 'OnboardingChecklist.Progress';