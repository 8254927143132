import { useSurveyFormMessageItem } from '@/containers/SurveyForm/hooks/useSurveyFormMessageItem';
import { cx } from '@utils';
import { SurveyQuestionText } from '@/components/Survey.RichText';
import Navigation from './Navigation';
import styles from './style.css';

export const SurveyMessageScreen = () => {

  const item = useSurveyFormMessageItem();

  return (
    <div className={cx(styles.root, styles.messageRoot)}>
      <div className={styles.content}>
        <SurveyQuestionText value={item.content.message.value} />
      </div>
      <div className={styles.footer}>
        <Navigation />
      </div>
    </div>
  );
};

export default SurveyMessageScreen;