import { useCallback } from 'react';
import { StructuredSummaryFeedback, utils as $enumUtils } from '@enums';
import { ConferenceTagSummaryItem, TagSummaryFeedback, ConferenceTagSummaryRating } from '@/types/conferences.tags';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { Collapser, SeeMore } from '@presentation/SeeMore';
import { Chip } from '@presentation/Chip';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import { Header } from '@/components/Modal/Header';
import { Textarea } from '@/components/Textarea';
import { StarRating } from '@/components/StarRating';
import styles from './style/Modal.FeedbackForm.css';

type Value = Pick<TagSummaryFeedback, 'notes' | 'structuredFeedback' | 'rating'>;

type Props = {
  canEdit: boolean;
  isSubmitting: boolean;
  onChange: (value: Value) => void;
  onSubmit: () => void;
  summaryItem: ConferenceTagSummaryItem;
  value: Value;
} & Pick<ModalProps, 'open' | 'onClose'>;

export const TagSummaryFeedbackModal = ({
  onClose, open,
  canEdit, isSubmitting, onSubmit,
  summaryItem, value, onChange,
}: Props) => {

  const handleNotesChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange({
      ...value,
      notes: e.target.value,
    });
  }, [onChange, value]);

  const handleRatingChange = useCallback((rating: ConferenceTagSummaryRating) => {
    onChange({
      ...value,
      rating,
    });
  }, [onChange, value]);

  const handleItemToggled = useCallback((v: StructuredSummaryFeedback) => () => {
    const selected = value.structuredFeedback.includes(v);
    const structuredFeedback = selected
      ? value.structuredFeedback.filter(f => f !== v)
      : [...value.structuredFeedback, v];

    onChange({
      ...value,
      structuredFeedback,
    });
  }, [onChange, value]);

  const renderStructuredFeedback = useCallback(() => {
    return (
      <div className={styles.tagsForm}>
        {$enumUtils.StructuredSummaryFeedback.values().map(item => {
          const selected = value.structuredFeedback.includes(item);
          return (
            <Chip
              key={item}
              className={styles.tag}
              onClick={handleItemToggled(item)}
              color={selected ? 'selected' : 'basic'}>
              {$enumUtils.StructuredSummaryFeedback.getName(item)}
            </Chip>
          );
        })}
      </div>
    );
  }, [
    handleItemToggled,
    value.structuredFeedback,
  ]);

  const canSubmit = !!value.rating;

  const title = `${summaryItem.tag.name} Feedback`;

  return (
    <Modal
      open={open}
      onClose={onClose}>

      <Header text={title} />

      <div className={styles.summary}>
        <SeeMore
          CollapserComp={Collapser}
          lineHeight={20}
          maxLines={4}>
          {summaryItem.summary.value}
        </SeeMore>
      </div>

      <div className={styles.section}>
        <div className={styles.sectionHeader}>Overall Quality</div>
        <StarRating
          rootClassName={styles.rating}
          onClick={handleRatingChange}
          rating={value.rating} />
      </div>

      <div className={styles.section}>
        <div className={styles.sectionHeader}>Please tell us why you didn&apos;t like the summary</div>
        {renderStructuredFeedback()}
      </div>

      <div className={styles.section}>
        <div className={styles.sectionHeader}>Additional Feedback</div>
        <Textarea
          className={styles.ta}
          disabled={!canEdit}
          value={value.notes}
          onChange={handleNotesChange} />
      </div>

      <div className={styles.footer}>
        <ButtonActivityIndicator
          className={styles.btn}
          disabled={!canSubmit}
          implicitDisable={isSubmitting}
          loading={isSubmitting}
          onClick={onSubmit}>
          Save
        </ButtonActivityIndicator>
      </div>

    </Modal>
  );
};