export function formatPossessive(name: string) {
  if (!name) return name;

  return name.endsWith('s') ? `${name}'` : `${name}'s`;
}

export function formatPlurality(word: string, count: number) {
  return count == 1
    ? word
    : `${word}s`;
}

export function formatWithOrdinalSuffix(n: number) {

  const suffixes = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  const suffix = suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0];

  return `${n}${suffix}`;
}

export function capitalize(word: string) {
  if (!word?.length) return word;
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
}

export function oxfordCommaJoin(arr: string[], separator: 'and' | 'or' = 'and') {
  return arr.length === 2
    ? arr.join(` ${separator} `)
    : arr.length > 2
      ? arr
        .slice(0, arr.length - 1)
        .concat(`${separator} ${arr.slice(-1)[0]}`)
        .join(', ')
      : arr.join(', ');
}