import { Filters } from './Containers.Filters';
import { Loading } from './Containers.Loading';
import { Results } from './Containers.Results';

export default function SearchContainer({ children }: ChildrenProps) {
  return (
    <Filters>
      <Results>
        <Loading>
          {children}
        </Loading>
      </Results>
    </Filters>
  );
}

export { SearchContainer };
