import { useMemo } from 'react';
import { Globe } from 'react-feather';
import { cx } from '@utils';
import styles from './style.css';

type PlaceholderProps = {
  className?: string;
  size?: number;
};

export const PlaceHolderCompanyLogo = (props: PlaceholderProps) => {
  const className = useMemo(() => cx(styles.logo, props.className), [props.className]);
  const style = useMemo(() => {
    return props.size ? {
      height: props.size,
      width: props.size,
    } : null;
  }, [props.size]);

  return (
    <Globe
      className={className}
      style={style} />
  );
};

export default PlaceHolderCompanyLogo;
