import { useMemo } from 'react';
import { useCommentEditorContent } from './useCommentEditorContent';

export const MinimumCommentLength = 5;

export const useCanSaveComment = () => {

  const { mentions, text } = useCommentEditorContent();

  const canSave = useMemo(() => {
    return text.length > MinimumCommentLength || mentions.length > 0;
  }, [mentions, text]);

  return canSave;
};