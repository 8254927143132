import { Skeleton } from '@mui/material';
import { useHasDataStewardRole } from '@containers/Store/hooks';
import { WorkspaceObjectBreadcrumbs } from '@/components/Workspace/ObjectBreadcrumbs';
import { useObjectBreadcrumbsContext } from '@/containers/WorkspaceObject/Context';
import { Breadcrumbs, SkeletonBreadcrumbs } from '@/presentation/Breadcrumbs';
import styles from '@screens/Transcript/style/Transcript.css';
import { HomeBreadcrumb } from '@/components/Workspace/WorkspaceBreadcrumb';

type Props = unknown;

export const WorkspaceTranscriptBreadcrumbs = (props: Props) => {

  const breadcrumbs = useObjectBreadcrumbsContext();
  const hasDataStewardRole = useHasDataStewardRole();

  if (!breadcrumbs) {
    return (
      <SkeletonBreadcrumbs />
    );
  }

  return hasDataStewardRole
    ? <DataStewardBreadcrumbs />
    : <ObjectBreadcrumbs />;
};

const DataStewardBreadcrumbs = () => (
  <Breadcrumbs>
    <HomeBreadcrumb />
  </Breadcrumbs>
);

const ObjectBreadcrumbs = () => {

  const breadcrumbs = useObjectBreadcrumbsContext();

  return (
    <div className={styles.breadcrumbs}>
      <WorkspaceObjectBreadcrumbs items={breadcrumbs} />
    </div>
  );
};

WorkspaceTranscriptBreadcrumbs.displayName = 'Workspace.Transcript.Breadcrumbs';