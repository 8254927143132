import { useCallback, useMemo } from 'react';
import { Point, ComputedSerie } from '@nivo/line';
import { useRepeatQuestionResponsesData, useRepeatSurveyData, useRepeatSurveyResponsesState } from '@containers/RepeatSurveyResponses/hooks';
import { RepeatSurvey as RS } from '@containers/RepeatSurveyResponses/interfaces';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import { SeriesTooltip as BaseSeriesTooltip, PointsTooltip as BasePointsTooltip } from '@presentation/ProjectSurveyResponses';
import { RepeatSurvey } from '@/types';
import { useRepeatOptionsChartData } from './hooks';
import { PointsTooltipProps, SeriesTooltipProps } from './interfaces';
import BaseLineChart from './BaseLineChart';

const SeriesTooltip = ({ series: rawSeries }: SeriesTooltipProps) => {
  const { question, aggregate } = useRepeatQuestionResponsesData();

  const getSerieLabel = useCallback((serie: ComputedSerie) => {
    const option = question.options.find(f => f.base.id === +serie.id);
    return parseSurveyRichText(option.value);
  }, [
    question.options,
  ]);

  const getSerieStat = useCallback((serie: ComputedSerie) => {
    const data = (aggregate as RepeatSurvey.Options.Map)[+serie.id];
    return `Avg: ${Math.round(data.pct)}%`;
  }, [
    aggregate,
  ]);

  const series = useMemo(() => {
    return rawSeries
      .sort((a, b) => {
        const { ordinal: ordinalA } = question.options.find(f => f.base.id === +a.id);
        const { ordinal: ordinalB } = question.options.find(f => f.base.id === +b.id);
        return ordinalA - ordinalB;
      });
  }, [
    question.options,
    rawSeries,
  ]);

  return (
    <BaseSeriesTooltip
      getSerieLabel={getSerieLabel}
      getSerieStat={getSerieStat}
      series={series} />
  );
};

const PointsTooltip = ({ points: rawPoints }: PointsTooltipProps) => {
  const { question } = useRepeatQuestionResponsesData();

  const getPointLabel = useCallback((point: Point) => {
    const option = question.options.find(f => f.base.id === +point.serieId);
    return parseSurveyRichText(option.value);
  }, [
    question.options,
  ]);

  const pointsValueText = useMemo(() => {
    const pct = +rawPoints[0].data.y;
    return `${pct}% of Total`;
  }, [
    rawPoints,
  ]);

  const points = useMemo(() => {
    return rawPoints
      .sort((a, b) => {
        const { ordinal: ordinalA } = question.options.find(f => f.base.id === +a.serieId);
        const { ordinal: ordinalB } = question.options.find(f => f.base.id === +b.serieId);
        return ordinalA - ordinalB;
      });
  }, [
    rawPoints,
    question.options,
  ]);

  return (
    <BasePointsTooltip
      points={points}
      getPointLabel={getPointLabel}
      pointsValueText={pointsValueText} />
  );
};

export const RepeatOptionsLineChart = () => {
  const data = useRepeatOptionsChartData();
  const { projectIds } = useRepeatSurveyData();
  const { setQueryState } = useRepeatSurveyResponsesState<RS.QueryState.Options>();

  const handleSerieClick = useCallback((series: ComputedSerie[]) => {
    setQueryState({
      optionIds: series.map(m => +m.id),
    });
  }, [
    setQueryState,
  ]);

  const handlePointClick = useCallback((points: Point[]) => {
    const x = +points[0].data.x;
    setQueryState({
      optionIds: points.map(m => +m.serieId),
      projectId: projectIds[x - 1],
    });
  }, [
    projectIds,
    setQueryState,
  ]);

  const maxYValue = useMemo(() => {
    const max = Math.max(...data.map(m => Math.max(...m.data.map(d => +d.y))));
    return Math.round(max + (max * 0.1 < 1 ? 1 : max * 0.1));
  }, [data]);

  const yValues = useMemo(() => [0, 25, 50, 75, 100].filter(f => f <= maxYValue), [maxYValue]);

  return (
    <BaseLineChart
      data={data}
      axisLeft={{
        format: (v: string) => `${v}%`,
        tickValues: yValues,
      }}
      yScale={{ type: 'linear', max: maxYValue, stacked: false, reverse: false }}
      onPointClick={handlePointClick}
      onSerieClick={handleSerieClick}
      PointsTooltip={PointsTooltip}
      SeriesTooltip={SeriesTooltip} />
  );

};

export default RepeatOptionsLineChart;