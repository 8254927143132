import { ActiveSliderTrack, InactiveSliderTrack, SliderThumb } from '@presentation/Slider';
import { useSlider } from './hooks/useSlider';
import { SliderProps } from './interfaces';
import styles from './style/Slider.css';

export const Slider = (props: SliderProps) => {
  const {
    activeTrackStyles,
    handleMouseDown,
    handleTouchStart,
    thumbStyles,
    isDragging,
    sliderRef,
    thumbRef,
  } = useSlider(props);

  return (
    <div
      className={styles.root}
      ref={sliderRef}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}>
      <ActiveSliderTrack
        className={styles.activeTrack}
        style={activeTrackStyles} />
      <InactiveSliderTrack className={styles.inactiveTrack} />
      <SliderThumb
        className={styles.thumb}
        ref={thumbRef}
        style={thumbStyles} />
    </div>
  );
};

export default Slider;