import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHasConsultantRole } from '@containers/User';
import { useDisconnectUserAccount } from '@utils/api';
import * as selectors from '@store/selectors';
import {
  useGoogleDriveSync
} from '@/components/ConnectAccount/hooks';
import { useAlert } from '@/components/Modal/Alert';
import {
  GoogleDrive,
} from '@/components/icons';
import { Integration } from '@/components/Settings';
import styles from './style/Settings.Integrations.css';

export const GoogleDriveIntegration = () => {

  const [toggleAlert, Alert] = useAlert();

  const connected = useSelector(selectors.selectGoogleDrive);

  const { mutateAsync: disconnect } = useDisconnectUserAccount({
    onSuccess: _ => {
      toggleAlert();
    },
  });

  const googleDrive = useGoogleDriveSync();

  const handleDisconnect = useCallback(() => {
    if (!connected) return;
    return disconnect({
      id: connected.id,
    });
  }, [connected, disconnect]);

  //TODO: Check workspace access
  const description = 'Connect your google drive for easier uploading to your Sentiment workspace.';

  return (
    <>
      <Integration
        className={styles.item}
        icon={GoogleDrive}
        title={title}
        description={description}
        connected={!!connected}
        disabled={false}
        loading={googleDrive.isLoading}
        onConnect={googleDrive.connect}
        onDisconnect={toggleAlert} />
      <Alert
        message="Are you sure you want to remove your connected Google drive?"
        onConfirm={handleDisconnect} />
    </>
  );
};

const title = `Google Drive Sync`;

export default GoogleDriveIntegration;
