import { useCallback, useEffect, useState, useRef } from 'react';
import type { IFilesTable } from '../interfaces';

const LOCAL_STORAGE_KEY = `sentiment:file-layout`;

export function useFileLayoutSelector() {
  const initialized = useRef<boolean>(false);
  const getStoredValue = useCallback((defaultValue: IFilesTable.Layout = 'list') => {
    try {
      const layout = window.localStorage.getItem(LOCAL_STORAGE_KEY) as IFilesTable.Layout | null;
      if (!layout) return defaultValue;
      return layout;
    } catch {
      return defaultValue;
    }
  }, []);

  const setStoredValue = useCallback((value: IFilesTable.Layout) => {
    try {
      window.localStorage.setItem(LOCAL_STORAGE_KEY, value);
    } catch {
      //
    }
  }, []);

  const [layout, setLayout] = useState<IFilesTable.Layout>(getStoredValue());

  const handleSetLayout = useCallback((layout: IFilesTable.Layout) => {
    setLayout(layout);
  }, []);

  useEffect(() => {
    if (initialized.current) {
      setStoredValue(layout);
    } else {
      initialized.current = true;
    }
  }, [
    setStoredValue,
    layout,
  ]);

  return [layout, handleSetLayout] as const;
}