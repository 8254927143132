import { useCallback, useMemo } from 'react';
import { format } from 'date-fns';
import { cx } from '@utils';
import { DateRange } from './interfaces';
import Field from './Profile.Form.Field';
import styles from './style/Profile.DateRange.css';

type Props =
  DateRange.FormProps;

export const ProfileDateRangeForm = ({
  currentText,
  onChange,
  endMonth,
  endYear,
  startMonth,
  startYear,
}: Props) => {

  const present = useMemo(() => !endYear, [endYear]);

  const handleCheckbox = useCallback(() => {
    onChange({
      endMonth: present ? nowMonth() : null,
      endYear: present ? nowYear() : null,
    });
  }, [
    onChange,
    present,
  ]);

  const handleChange = useCallback((field: keyof DateRange.Values) => (value: number) => {
    onChange({
      [field]: value,
    });
  }, [
    onChange,
  ]);

  const renderCheckbox = useCallback(() => {
    const className = cx({
      [styles.current]: present,
      [styles.previous]: !present,
    });

    return (
      <div className={styles.checkbox}>
        <div
          className={className}
          onClick={handleCheckbox} />
        <div className={styles.text}>{currentText}</div>
      </div>
    );
  }, [
    currentText,
    handleCheckbox,
    present,
  ]);

  return (
    <div>
      <div className={styles.ranges}>
        <Field
          required
          title="Start Date">
          <div className={styles.dates}>
            <MonthSelect
              className={styles.select}
              name="startMonth"
              onChange={handleChange('startMonth')}
              value={startMonth} />
            <YearSelect
              className={styles.select}
              name="startYear"
              onChange={handleChange('startYear')}
              value={startYear} />
          </div>
        </Field>
        <Field
          required
          className={styles.end}
          title="End Date">
          <div className={styles.dates}>
            {!present
              ? (
                <>
                  <MonthSelect
                    disabled={present}
                    className={styles.select}
                    name="endMonth"
                    onChange={handleChange('endMonth')}
                    value={endMonth} />
                  <YearSelect
                    disabled={present}
                    className={styles.select}
                    name="endYear"
                    onChange={handleChange('endYear')}
                    value={endYear} />
                  </>
                )
              : <div className={styles.present}>Present</div>
            }
          </div>
        </Field>
      </div>
      {renderCheckbox()}
    </div>
  );
};

function nowMonth() {
  return Number(format(Date.now(), 'MM'));
}

function nowYear() {
  return new Date().getFullYear();
}

type SelectProps = {
  className?: string;
  disabled?: boolean;
  name: string;
  onChange: (value: number) => unknown;
  value: number;
};

const monthOptions =
  Array.from(
    { length: 12 },
    (_, i) => ({ value: `${i + 1}`, name: format(new Date(null, i), 'MMM') }),
  );

const MonthSelect = ({
  className,
  disabled,
  name,
  onChange,
  value,
}: SelectProps) => {

  const handleChange = (e: React.ChangeEvent<{
    name: string;
    value: string;
  }>) => {
    onChange(+e.target.value);
  };

  return (
    <select
      disabled={disabled}
      className={cx(styles.select, className)}
      value={value}
      name={name}
      onChange={handleChange}>
      {monthOptions.map(x =>
        <option
          key={x.name}
          value={x.value}>
          {x.name}
        </option>
      )}
    </select>
  );
};

const YearSelect = ({
  className,
  disabled,
  name,
  onChange,
  value,
}: SelectProps) => {

  const options = useMemo(() => {
    const currentYear = new Date().getFullYear();
    return Array.from(
      { length: 100 },
      (_, i) => ({ value: `${currentYear - i}`, name: `${currentYear - i}` }),
    );
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(+e.target.value);
  };

  return (
    <select
      disabled={disabled}
      className={cx(styles.select, className)}
      value={value}
      name={name}
      onChange={handleChange}>
      {options.map(x =>
        <option
          key={x.name}
          value={x.value}>
          {x.name}
        </option>
      )}
    </select>
  );
};

export function generateDuring(data: DateRange.Values) {
  const start = Number(`${data.startYear}${data.startMonth.toString().padStart(2, '0')}`);
  const end = data.endYear ? Number(`${data.endYear}${data.endMonth.toString().padStart(2, '0')}`) : null;

  return [start, end] as [number, number];
}

export default ProfileDateRangeForm;