import { useState } from 'react';
import { usePopper } from 'react-popper';
import { useQuery } from '@tanstack/react-query';
import { Grow, Portal } from '@mui/material';
import * as api from '@api';
import { useProjectState } from '@containers/GroupProject/hooks';
import { ButtonOutlined } from '@presentation';
import styles from '@screens/Project.Tags/style/TabTitle.css';
import { getLocationFor } from '@utils';
import { useIsInternalUser } from '@/containers/Store/hooks';
import { useZIndexModifier } from '@/components/Popper';
import boostGraphic from 'static/images/website/project-entities/boosting-graphic-1.svg?url';

type Props = unknown;

export const TabTitle = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [anchor, setAnchor] = useState<HTMLDivElement>();
  const [popover, setPopover] = useState<HTMLDivElement>();
  const zIndexModifier = useZIndexModifier({ zIndex: 8 });
  const popper = usePopper(anchor, popover, {
    modifiers: [zIndexModifier],
    placement: 'bottom',
  });

  const isInternalUser = useIsInternalUser();

  if (!isInternalUser) {
    return (
      <div className={styles.root}>
        <div className={styles.title}>Entities</div>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.title}>Entities</div>
      <div
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}>
        <div
          className={styles.badge}
          ref={setAnchor}>
          Beta
        </div>
        {open &&
          <Portal>
            <div onClick={e => e.stopPropagation()}>
              <div
                ref={setPopover}
                style={popper.styles.popper}
                {...popper.attributes.popper}>
                <Popover open={open} />
              </div>
            </div>
          </Portal>}
      </div>
    </div>
  );
};

TabTitle.displayName = 'Project.Tags.TabTitle';

type PopoverProps = {
  open: boolean;
};

const Popover = (props: PopoverProps) => {
  const state = useProjectState();
  const projectId = state.project?.id;

  const { data } = useQuery({
    queryKey: ['is-project-entity-boosting-locked', projectId],
    queryFn: () => {
      return api.projects.entities.isProjectEntityBoostLocked({ projectId });
    },
    enabled: !!projectId,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  const isLocked = data ? data.locked : true;

  return (
    <Grow in={props.open}>
      <div className={styles.popover}>
        <div className={styles.arrow}>
          <div className={styles.triangle} />
        </div>
        <div className={styles.menu}>
          <div className={styles.header}>
            {copy.title}
          </div>
          <div className={styles.body}>
            <div className={styles.subtitle}>
              {copy.subtitle}
            </div>
            <img
              className={styles.boostImage}
              src={boostGraphic}
              style={{ width: 175, margin: `0 auto` }} />
            {!isLocked &&
              <ButtonOutlined
                className={styles.btn}
                color="blue"
                fontWeight="bold"
                to={getLocationFor.project.entitiesOnboarding({ slug: projectId.toString() })}>
                Get Started
              </ButtonOutlined>}
          </div>
        </div>
      </div>
    </Grow>
  );
};

Popover.displayName = 'Project.Tags.Popover';

const copy = {
  title: `Improve Transcript Quality With Entities`,
  subtitle: `Set up entities by providing medical terms and drug names to improve interview transcript accuracy.`,
};