import { useCallback } from 'react';
import { ButtonActivityIndicator } from '@presentation';
import { formatPlurality, useAsyncStateLazy } from '@utils';
import * as api from '$admin/api';
import Button from '@/components/Button';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { Header } from '@/components/Modal/Header';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import { Project } from '$admin/interfaces';
import styles from './style/Project.RejectMember.css';

type ResetItem = {
  user: Pick<Project.Members.Item['user'], 'id'>;
};

type Props = {
  project: Descriptor;
  items: ResetItem[];
  onConfirm?: (project: Descriptor, items: ResetItem[]) => unknown;
} & Pick<ModalProps, 'open' | 'onClose'>;

export function ResetMembers({ project, items, open, onClose, onConfirm }: Props) {
  const handleConfirm = useCallback(() => {
    return api.projects.members.resetMembers({
      projectId: project.id,
      userIds: items.map(i => i.user.id),
    })
    .finally(() => onConfirm(project, items));
  }, [project, items, onConfirm]);

  const [response, confirm] = useAsyncStateLazy(handleConfirm, [handleConfirm]);

  return (
    <Modal
      onClose={onClose}
      open={open}>
      <Header>{`Reset ${items.length} ${formatPlurality('expert', items.length)}?`}</Header>
      <ButtonSet className={styles.buttonset}>
        <Button
          className={styles.cancel}
          color="destructive"
          onClick={onClose}
          variant="brick">
          Cancel
        </Button>
        <ButtonActivityIndicator
          className={styles.btn}
          color="primary"
          loading={response.loading}
          onClick={confirm}
          variant="brick">
          Reset
        </ButtonActivityIndicator>
      </ButtonSet>
    </Modal>
  );
}

ResetMembers.displayName = 'Modal.Project.ResetMembers';