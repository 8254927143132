import { useContext } from 'react';
import { AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { Transcripts } from '@api/interfaces';
import { Highlight } from '@/types/transcribe.rich-text';
import { TranscriptHighlightActionsContext } from '@containers/Transcript/context.highlights';
import { TranscriptCommentsStateContext } from '@containers/Transcript.Commenting/Context';
import { useChainedCommands } from './useCommands';

type Data = Transcripts.RemoveTranscriptHighlight.Response;
type Variables = {
  highlight: Highlight;
};
type Options = UseMutationOptions<Data, AxiosResponse, Variables>;

export function useRemoveHighlight(options?: Options) {

  const { removeHighlight } = useContext(TranscriptHighlightActionsContext);
  const commands = useChainedCommands();
  const [_, dispatch] = useContext(TranscriptCommentsStateContext);

  const remove = useMutation(['remove-transcript-highlight'], removeHighlight, {
    ...options,
    onMutate: v => {
      commands
        .blurHighlight()
        .removeHighlights([v.highlight.id])
        .run();
    },
    onSuccess: (r, v, ctx) => {
      dispatch({
        type: 'highlight-removed',
        highlightIdentifier: v.highlight.id,
      });

      options?.onSuccess?.(r, v, ctx);
    },
    onError: (e, v) => {
      commands
        .addHighlight(v.highlight)
        .run();
    },
  });

  return remove;
}