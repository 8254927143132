import { useEffect, useRef } from 'react';
import * as api from '@api';
import { useSelectUser } from '@containers/Store/hooks';
import { useEmbeddedInstance } from '@services/esign';
import {
  EmbeddedSignProps,
} from './interfaces';
import styles from './style.css';

type Props =
  EmbeddedSignProps;

const EmbeddedSign = (props: Props) => {
  const user = useSelectUser();
  const client = useEmbeddedInstance();
  const containerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    fetchEmeddedSignUrl()
      .then(data => {
        if (data.alreadySigned) {
          return props.onAbort();
        }

        openDocument(data.url);
      });
  }, []);

  const openDocument = url => {
    client.open(url, {
      allowCancel: false,
      container: containerRef.current,
      skipDomainVerification: process.env.HELLOSIGN_SKIP_DOMAIN_VERIF === 'true',
    });

    client.on('finish', () => {
      props.onSignatureSubmit();
    });
  };

  const fetchEmeddedSignUrl = () => {
    return api.documents.getEmbeddedSignUrl({
      groupId: props.groupId,
      userId: user.id,
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>Project Terms</div>
      <div
        className={styles.embed}
        ref={containerRef} />
    </div>
  );
};

export default EmbeddedSign;