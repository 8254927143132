import type { AxiosResponse } from 'axios';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import * as api from '@api/projects';
import type { Projects } from '@api/interfaces/projects';

type Data = Projects.SaveProjectSurvey.Response;
type Vars = Omit<Projects.SaveProjectSurvey.Request, 'projectId'>;

export function useSaveProjectSurvey(
  { projectId }: IProjectId,
  options?: UseMutationOptions<Data, AxiosResponse, Vars>
) {

  return useMutation({
    mutationKey: ['save-project-survey', projectId],
    mutationFn: ({ survey, draftVersionId }) => {
      return api.surveys.save({
        projectId,
        survey,
        draftVersionId,
      });

    },
    ...options,
  });
}