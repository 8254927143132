import extend from '@enums/extend';

export enum AudienceType {
  Payer                  = 1,
  Patient                = 2,
  HealthcareProfessional = 3,
  HealthcarePractitioner = 4,
  Other                  = 5,
}
const map = new Map([
  [AudienceType.Payer, { name: `Payer` }],
  [AudienceType.Patient, { name: `Patient` }],
  [AudienceType.HealthcareProfessional, { name: `Healthcare Professional` }],
  [AudienceType.HealthcarePractitioner, { name: `Healthcare Practitioner` }],
  [AudienceType.Other, { name: `Other` }],
]);

export default {
  AudienceType: extend(map),
};