import http, { xhr } from '@services/http';
import { Posts } from '@api/interfaces';

export const create = (): Promise<Posts.Articles.Create.Response> => {
  return http.post(`/posts/articles/new`, {});
};

export const deleteArticle = (data: Posts.Articles.DeleteArticle.Request) => {
  return http.delete<Posts.Articles.DeleteArticle.Response>(`/posts/articles/${data.postId}`);
};

export const deleteCoverImage = (data: Posts.Articles.DeleteCoverImage.Request) => {
  return http.delete<Posts.Articles.DeleteCoverImage.Response>(`/posts/articles/attachments/cover-image/${data.postId}`);
};

export const fetchComments = (data: Posts.Articles.FetchComments.Request) => {
  return http.get<Posts.Articles.FetchComments.Response>(`posts/articles/${data.postId}/comments`);
};

export const fetchPost = (data: Posts.Articles.FetchPost.Request) => {
  return xhr.get<Posts.Articles.FetchPost.Response>(`/posts/articles/${data.postId}`)
  .then(({ data }) => data);
};

export const fetchPostEditContext = (data: Posts.Articles.FetchPostEditContext.Request) => {
  return http.get<Posts.Articles.FetchPostEditContext.Response>(`/posts/articles/${data.postId}/edit`);
};

export const publish = (data: Posts.Articles.Publish.Request): Promise<Posts.Articles.Publish.Response> => {
  return http.post(`/posts/articles`, data);
};

export const update = (data: Posts.Articles.Update.Request) => {
  return http.put<Posts.Articles.Update.Response>(`/posts/articles/${data.id}`, data);
};

export const uploadCoverImage = (data: Posts.Articles.UploadCoverImage.Request): Promise<Posts.Articles.UploadCoverImage.Response> => {
  const form = new FormData();

  form.append('blob', data.blob);
  form.append('identifier', data.identifier);

  return http.post(`/posts/articles/attachments/cover-image`, form, {
    headers: {
      'Content-Type': `multipart/form-data`,
    },
  });
};

export const uploadImageAttachment = (data: Posts.Articles.UploadImageAttachment.Request): Promise<Posts.Articles.UploadImageAttachment.Response> => {
  const form = new FormData();

  form.append('blob', data.blob);
  form.append('identifier', data.identifier);

  return http.post(`/posts/articles/attachments`, form, {
    headers: {
      'Content-Type': `multipart/form-data`,
    },
  });
};