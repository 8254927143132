import { Permission } from '@enums';

type HasPermission = (user: Pick<Store.User, 'permissions'>) => boolean;

const hasAdminDashboardAccess: HasPermission = user => {
  return user.permissions.some(x => x === Permission.InternalAdminDashboard);
};

const hasAdminBillingDashboardAccess: HasPermission = user => {
  return hasAdminDashboardAccess(user)
      && user.permissions.some(x => x === Permission.InternalAdminBillingDashboard);
};

const hasAdminProjectExpertsAccess: HasPermission = user => {
  return user.permissions.some(x => x === Permission.InternalAdminProjectsExperts);
};

const hasAdminProjectLeadsAccess: HasPermission = user => {
  return user.permissions.some(x => x === Permission.InternalAdminProjectsLeads);
};

const hasAdminProjectBillingAccess: HasPermission = user => {
  return hasAdminBillingDashboardAccess(user);
};

const hasAdminAccountsDashboardAccess: HasPermission = user => {
  return user.permissions.some(x => x === Permission.InternalAdminAccountsDashboard);
};

const hasAdminDirectoryDashboardAccess: HasPermission = user => {
  return user.permissions.some(x => x === Permission.InternalAdminDirectoryDashboard);
};

const hasAdminExpertsDashboardAccess: HasPermission = user => {
  return user.permissions.some(x => x === Permission.InternalAdminExpertsDashboard);
};

const hasAdminLeadsDashboardAccess: HasPermission = user => {
  return user.permissions.some(x => x === Permission.InternalAdminLeadsDashboard);
};

const hasAdminRecruiterDashboardAccess: HasPermission = user => {
  return user.permissions.some(x => x === Permission.InternalAdminRecruiterDashboard);
};

const hasAdminSearchDashboardAccess: HasPermission = user => {
  return user.permissions.some(x => x === Permission.InternalAdminSearchDashboard);
};

export const hasAccessFor = {
  accounts: {
    dashboard: hasAdminAccountsDashboardAccess,
  },
  billing: {
    dashboard: hasAdminBillingDashboardAccess,
  },
  directory: {
    dashboard: hasAdminDirectoryDashboardAccess,
  },
  experts: {
    dashboard: hasAdminExpertsDashboardAccess,
  },
  leads: {
    dashboard: hasAdminLeadsDashboardAccess,
  },
  projects: {
    billing: hasAdminProjectBillingAccess,
    experts: hasAdminProjectExpertsAccess,
    leads: hasAdminProjectLeadsAccess,
  },
  recruiter: {
    dashboard: hasAdminRecruiterDashboardAccess,
  },
  search: {
    dashboard: hasAdminSearchDashboardAccess,
  },
};