import { cx } from '@utils';
import { TabBarTitle } from './TabBarTitle';
import TabIndicator from './TabIndicator';
import styles from './style.css';

type KeyType = string | number;

export type ControlledTabProps<K extends KeyType> = {
  title: React.ReactNode;
  onClick: () => void;
  key: string;
};

type Props<K extends KeyType> = {
  className?: string;
  classes?: {
    root?: string;
    tab?: string;
    tabs?: string;
  };
  tabs: ControlledTabProps<K>[];
  activeKey: K;
};

// eslint-disable-next-line comma-spacing
export const ControlledTabBar = <K extends KeyType,>({ classes = {}, ...props }: Props<K>) => {
  function getClassName(i: ControlledTabProps<K>) {
    return cx(i.key === props.activeKey ? styles.active : styles.tab);
  }

  const activeIndex = props.tabs.findIndex(x => x.key === props.activeKey);

  return (
    <div className={cx(styles.root, classes.root)}>
      <div className={styles.wrap}>
        <div className={styles.scrollable}>
          <div className={cx(styles.tabs, classes.tabs, props.className)}>
            {props.tabs.map((item, i) =>
              <TabBarTitle
                className={cx(getClassName(item), classes.tab)}
                key={i}
                index={i}
                onClick={item.onClick}>
                {item.title}
              </TabBarTitle>)}
            <TabIndicator
              count={props.tabs.length}
              index={activeIndex} />
          </div>
        </div>
        <div className={styles.bar} />
      </div>
    </div>
  );
};