import { TipaltiPayeeStatus } from '@/enums';

export function selectIsPendingPayoutAccount(state: Store.State) {
  return state.user.payout?.payeeStatus === TipaltiPayeeStatus.Pending;
}

export function selectHasPayablePayoutAccount(state: Store.State) {
  return !!state.user.payout?.paymentMethod && state.user.payout?.isPayable;
}

export function selectHasTipaltiPayoutAccount(state: Store.State) {
  return !!state.user.payout?.tipaltiPayeeId;
}