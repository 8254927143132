import { useCallback } from 'react';
import { ComputedSerie, Point } from '@nivo/line';
import { useRepeatSurveyData, useRepeatSurveyResponsesState } from '@containers/RepeatSurveyResponses/hooks';
import { RepeatSurvey as RS } from '@containers/RepeatSurveyResponses/interfaces';
import BaseLineChart from './BaseLineChart';
import PointsTooltip from './MaxDiff.Chart.PointsTooltip';
import SeriesTooltip from './MaxDiff.Chart.SeriesTooltip';
import { useRepeatMaxDiffChartData } from './hooks';

export default function MaxDiffLineChart() {
  const data = useRepeatMaxDiffChartData();
  const { projectIds } = useRepeatSurveyData();
  const { setQueryState } = useRepeatSurveyResponsesState<RS.QueryState.MaxDiff>();

  const formatLeftAxisLabel = useCallback((value: string) => {
    return Number.isInteger(+value)
      ? value
      : '';
  }, []);

  const handleSerieClick = useCallback((series: ComputedSerie[]) => {
    setQueryState({
      optionIds: series.map(s => +s.id),
    });
  }, [
    setQueryState,
  ]);

  const handlePointClick = useCallback((points: Point[]) => {
    const x = +points[0].data.x;
    setQueryState({
      optionIds: points.map(p => +p.serieId),
      projectId: projectIds[x - 1],
    });
  }, [
    projectIds,
    setQueryState,
  ]);

  return (
    <BaseLineChart
      data={data}
      axisLeft={{
        format: formatLeftAxisLabel,
      }}
      yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
      onPointClick={handlePointClick}
      onSerieClick={handleSerieClick}
      PointsTooltip={PointsTooltip}
      SeriesTooltip={SeriesTooltip} />
  );
}

export { MaxDiffLineChart };