import { Fragment, useCallback } from 'react';
import type { SurveyQuestionType } from '@enums';
import { useProjectSurveyResponsesState } from '@containers/SurveyResponses/hooks/useProjectSurveyResponsesState';
import { SurveyResponsesHeader } from '@presentation/ProjectSurveyResponses';
import type { DropdownQuestion, ExclusiveOptionsQuestion, MultipleChoiceQuestion, MultiselectQuestion, SurveyAggregate } from '@/types';
import { useOptionsColors } from './hooks/usePaletteColors';
import styles from './style/Options.css';
import ResponseOptionsChart from './ResponseOptionsChart';
import OptionsOptionData from './OptionsOptionData';
import OptionsAggregateData from './OptionsAggregateData';
import { SegmentedDataTooltip } from './SegmentedDataTooltip';

type Props = {
  question: DropdownQuestion.Question | MultipleChoiceQuestion.Question | MultiselectQuestion.Question | ExclusiveOptionsQuestion.Question;
  responses: SurveyAggregate.Options.Map;
};

export default function ResponseOptions({ question, responses }: Props) {
  const colors = useOptionsColors();

  const { query, setQueryState } = useProjectSurveyResponsesState<SurveyQuestionType.MultipleChoice>();

  const handleBarClick = useCallback((optionId: number) => {
    setQueryState({ optionId });
  }, [setQueryState]);

  return (
    <Fragment>
      <SurveyResponsesHeader
        question={question}>
        <SegmentedDataTooltip questionIdentifier={question.base.identifier} />
      </SurveyResponsesHeader>
      <ResponseOptionsChart
        colors={colors}
        question={question}
        responses={responses}
        onClick={handleBarClick} />
      <div className={styles.options}>
        {query.optionId
          ? <OptionsOptionData />
          : <OptionsAggregateData />}
      </div>
    </Fragment>
  );
}

export { ResponseOptions };