import { SurveyQuestionType } from '@enums';
import type { SurveyResponseItem } from '@/types';

export function isAnswerHidden(item: SurveyResponseItem) {
  if (!isTextResponse(item)) {
    return false;
  }

  return item.hidden;
}

export const isMultipleChoice = (data: SurveyResponseItem): data is SurveyResponseItem<SurveyQuestionType.MultipleChoice> => {
  return data.question.typeId === SurveyQuestionType.MultipleChoice;
};

export const isLongTextResponse = (data: SurveyResponseItem): data is SurveyResponseItem<SurveyQuestionType.LongTextResponse> => {
  return data.question.typeId === SurveyQuestionType.LongTextResponse;
};

export const isShortTextResponse = (data: SurveyResponseItem): data is SurveyResponseItem<SurveyQuestionType.ShortTextResponse> => {
  return data.question.typeId === SurveyQuestionType.ShortTextResponse;
};

export const isTextResponse = (data: SurveyResponseItem): data is SurveyResponseItem<SurveyQuestionType.ShortTextResponse | SurveyQuestionType.LongTextResponse> => {
  return isShortTextResponse(data) || isLongTextResponse(data);
};

export const isMultiSelect = (data: SurveyResponseItem): data is SurveyResponseItem<SurveyQuestionType.Multiselect> => {
  return data.question.typeId === SurveyQuestionType.Multiselect;
};

export const isRanking = (data: SurveyResponseItem): data is SurveyResponseItem<SurveyQuestionType.Ranking> => {
  return data.question.typeId === SurveyQuestionType.Ranking;
};

export const isMatrixGrid = (data: SurveyResponseItem): data is SurveyResponseItem<SurveyQuestionType.MatrixGrid> => {
  return data.question.typeId === SurveyQuestionType.MatrixGrid;
};

export const isMatrixSlider = (data: SurveyResponseItem): data is SurveyResponseItem<SurveyQuestionType.Sliders> => {
  return data.question.typeId === SurveyQuestionType.Sliders;
};

export const isMaxDiff = (data: SurveyResponseItem): data is SurveyResponseItem<SurveyQuestionType.MaxDifference> => {
  return data.question.typeId === SurveyQuestionType.MaxDifference;
};

export const isConjointAnalysis = (data: SurveyResponseItem): data is SurveyResponseItem<SurveyQuestionType.ConjointAnalysis> => {
  return data.question.typeId === SurveyQuestionType.ConjointAnalysis;
};

export const isMatrixMultiselect = (data: SurveyResponseItem): data is SurveyResponseItem<SurveyQuestionType.MatrixMultiselect> => {
  return data.question.typeId === SurveyQuestionType.MatrixMultiselect;
};

export const isMultiTextbox = (data: SurveyResponseItem): data is SurveyResponseItem<SurveyQuestionType.MultiTextbox> => {
  return data.question.typeId === SurveyQuestionType.MultiTextbox;
};

export const isNumberInputTable = (data: SurveyResponseItem): data is SurveyResponseItem<SurveyQuestionType.NumberInputTable> => {
  return data.question.typeId === SurveyQuestionType.NumberInputTable;
};

export const isExclusiveOptions = (data: SurveyResponseItem): data is SurveyResponseItem<SurveyQuestionType.ExclusiveOptions> => {
  return data.question.typeId === SurveyQuestionType.ExclusiveOptions;
};

export const isDropdownQuestion = (data: SurveyResponseItem): data is SurveyResponseItem<SurveyQuestionType.Dropdown> => {
  return data.question.typeId === SurveyQuestionType.Dropdown;
};