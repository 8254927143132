import { useMemo, useCallback } from 'react';
import { SurveyLogic } from '@/types/survey';
import { SurveyConditionType } from '@/enums';
import {
  SurveyConditionsBuilderContextValue,
  SurveyConditionsBuilderContext,
  useSurveyConditionsState,
} from './context';
import { SyncSurveyExpressionContainer } from './Expression.Container';

type Props = ChildrenProps;

export const SurveyLogicBuilderConditionsContainer = (props: Props) => {

  const [items, dispatch] = useSurveyConditionsState();

  const canAddItem = useMemo(() => {
    return true;
  }, []);

  const canRemoveItem = useMemo(() => {
    return true;
  }, []);

  const addCondition = useCallback((conditionType: SurveyConditionType) => {
    dispatch({
      payload: {
        conditionType,
      },
      type: 'condition-added',
    });
  }, [dispatch]);

  const removeItem = useCallback((identifier: string) => () => {
    dispatch({
      type: 'condition-removed',
      payload: {
        identifier,
      },
    });
  }, [dispatch]);

  const ctx: SurveyConditionsBuilderContextValue = {
    canAddItem,
    canRemoveItem,
    addItem: addCondition,
    items,
    removeItem,
  };

  return (
    <SurveyConditionsBuilderContext.Provider value={ctx}>
      <SyncSurveyExpressionContainer>
        {props.children}
      </SyncSurveyExpressionContainer>
    </SurveyConditionsBuilderContext.Provider>
  );
};