import { useCallback, useMemo } from 'react';
import { useCookie } from '@/brand-insights/hooks';
import { GetStartedContext } from './context';

type Props = {
  children: React.ReactNode;
};

const cookieName = 'brand-insights:get-started-dismissed';
const dismissedCookieValue = 'dismissed';

export const GetStartedContainer = (props: Props) => {

  const [cookieValue, setCookie] = useCookie(cookieName);

  const visible = useMemo(() => {
    return cookieValue !== dismissedCookieValue;
  }, [cookieValue]);

  const toggleVisibility = useCallback(() => {
    setCookie({
      expires: new Date('2100-01-01'),
      value: dismissedCookieValue,
    });
  }, [setCookie]);

  return (
    <GetStartedContext.Provider value={[visible, toggleVisibility]}>
      {props.children}
    </GetStartedContext.Provider>
  );
};