import { useMemo } from 'react';
import { SurveyConditionType, SurveyQuestionConditionType } from '@/enums';
import { SurveyLogic } from '@/types';

export const useGetAdditionalSlidersLogic = (questionLogic: SurveyLogic.Item[]) => {

  return useMemo(() => {
    return questionLogic.filter(f => {
      if (f.conditions.length !== 1) return true;

      const condition = f.conditions[0];
      if (condition.conditionType !== SurveyConditionType.Question) return true;

      return ![
        SurveyQuestionConditionType.SliderValue,
      ].includes(condition.data.type);
    });
  }, [questionLogic]);

};