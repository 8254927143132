import { useState } from 'react';
import type { GetMarkRange } from 'remirror';
import { TranscriptConfidenceContext } from './context';

export const TranscriptConfidenceContainer = (props: ChildrenProps) => {
  const [selectedMark, setMark] = useState<GetMarkRange>(null);

  return (
    <TranscriptConfidenceContext.Provider value={{ selectedMark, setMark }}>
      {props.children}
    </TranscriptConfidenceContext.Provider>
  );
};