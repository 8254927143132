import { ComponentType } from 'react';
import { NodeViewComponentProps } from '@remirror/react';
import { useSurveyFormTemplateData } from '@containers/SurveyForm/hooks';
import { useIsEditingSurvey, useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import { SurveyTemplateType } from '@/enums';
import {
  useEditableTPPModal,
  useTPPModal,
} from '@/components/Survey.Templates/Modal.TPP';
import styles from './style/Node.ViewStimulus.css';

export const ViewTPPNode: ComponentType<NodeViewComponentProps> = props => {

  const ctx = useSurveyBuilderState();

  return ctx
    ? <BuilderNode {...props} />
    : <Node {...props} />
  ;
};

function BuilderNode({ node }: NodeViewComponentProps) {

  const editing = useIsEditingSurvey();

  const [toggleModal, Modal] = useEditableTPPModal();

  return (
    <>
      <span className={styles.link} onClick={!editing ? toggleModal : null}>
        View TPP
      </span>
      <Modal />
    </>
  );
}

function Node({ node }: NodeViewComponentProps) {

  const [toggleModal, Modal] = useTPPModal();
  const data = useSurveyFormTemplateData<SurveyTemplateType.TargetProductProfile>();

  if (!data) return null;

  return (
    <>
      <span className={styles.link} onClick={toggleModal}>
        View TPP
      </span>
      {data && <Modal templateData={data} />}
    </>
  );
}