import { useContext, useMemo } from 'react';
import { CallTranscriptDetailsContext } from '@containers/Transcript/context';
import { useIsInternalUser, useHasDataStewardRole } from '@containers/Store/hooks';
import { TranscriptPublishType } from '@enums';
import { TabKey } from '@screens/Transcript/interfaces';
import { Details, Entities, Highlights, Summary, Transcript } from '../Tab';

export const useCallTranscriptTabs = () => {
  const ctx = useContext(CallTranscriptDetailsContext);
  const hasDataStewardRole = useHasDataStewardRole();
  const isInternalUser = useIsInternalUser();

  const access = useMemo(() => {
    return {
      transcription: isInternalUser || canAccessTranscription(ctx?.settings?.publishType),
    };
  }, [
    ctx?.settings?.publishType,
    isInternalUser,
  ]);

  return useMemo(() => {

    if (hasDataStewardRole) {
      return [
        {
          key: TabKey.Transcript,
          title: 'Transcript',
          component: Transcript,
        },
        {
          key: TabKey.Entities,
          title: 'Entities',
          component: Entities,
        },
      ];
    }

    return [
      access.transcription && {
        key: TabKey.Transcript,
        title: 'Transcript',
        component: Transcript,
      },
      isInternalUser && {
        key: TabKey.Highlights,
        title: 'Highlights',
        component: Highlights,
      },
      {
        key: TabKey.Entities,
        title: 'Entities',
        component: Entities,
      },
      isInternalUser && {
        key: TabKey.Summary,
        title: 'Summary',
        component: Summary,
      },
      {
        key: TabKey.Details,
        title: 'Details',
        component: Details,
      },
    ].filter(Boolean);
  }, [
    access.transcription,
    hasDataStewardRole,
    isInternalUser,
  ]);
};

function canAccessTranscription(typeId: TranscriptPublishType) {
  return typeId === TranscriptPublishType.RecordingAndTranscript
    || typeId === TranscriptPublishType.TranscriptOnly;
}