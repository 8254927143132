import { useCallback, useState } from 'react';
import { useTranscriptEnhancementStateMutation } from '@containers/Transcript/hooks';
import { TranscriptPublishType, TranscriptionRevisionStatus } from '@enums';
import { ButtonActivityIndicator, ButtonOutlined } from '@presentation';
import { useToggle } from '@utils';
import { Header } from '@/components/Modal/Header';
import { Modal, type ModalProps } from '@/components/Modal/Modal';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { PublishingCompliance } from './Publishing.Compliance';
import { PublishSettingsSelect } from './Publishing.Select';
import styles from './style/Publishing.css';

type Props = {
  onSuccess?: () => void;
} & Pick<ModalProps, 'onClose' | 'open'>
  & ITranscriptId;

export const PublishingTranscriptModal = ({ onSuccess, transcriptId, ...props }: Props) => {
  const [publishType, setPublishType] = useState(TranscriptPublishType.RecordingOnly);
  const mutation = useTranscriptEnhancementStateMutation({ transcriptId });
  const [displayCompliance, toggleDisplayCompliance] = useToggle(false);

  const handleSubmit = useCallback(() => {
    mutation.mutateAsync({
      publishType,
      statusId: TranscriptionRevisionStatus.Completed,
    })
      .then(() => onSuccess?.());
  }, [
    mutation,
    onSuccess,
    publishType,
  ]);

  return (
    <Modal
      onClose={props.onClose}
      open={props.open}>
      {!displayCompliance &&
        <PublishingTranscriptSetting
          onCancel={props.onClose}
          onChange={setPublishType}
          onSubmit={toggleDisplayCompliance}
          value={publishType} />}
      {displayCompliance &&
        <PublishingCompliance
          onCancel={toggleDisplayCompliance}
          onSubmit={handleSubmit}
          submitting={mutation.isLoading} />}
    </Modal>
  );
};

PublishingTranscriptModal.displayName = 'Publishing.Transcript.Modal';

type PublishingTranscriptSettingProps = {
  onChange: (value: TranscriptPublishType) => void;
  onCancel: () => void;
  onSubmit: () => void;
  value: TranscriptPublishType;
};

const PublishingTranscriptSetting = (props: PublishingTranscriptSettingProps) => {
  return (
    <div className={styles.root}>
      <Header>
        Publish Transcript?
      </Header>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.label}>Publish Setting</div>
          <PublishSettingsSelect
            onChange={e => props.onChange(e.target.value)}
            value={props.value} />
        </div>
        <ButtonSet className={styles.footer}>
          <ButtonOutlined
            className={styles.btn}
            onClick={props.onCancel}>
            Cancel
          </ButtonOutlined>
          <ButtonActivityIndicator
            className={styles.btn}
            color="primary"
            onClick={props.onSubmit}>
            Next
          </ButtonActivityIndicator>
        </ButtonSet>
      </div>
    </div>
  );
};

PublishingTranscriptSetting.displayName = 'Publishing.Transcript.Setting';