import { Actions } from './Panel.Toolbar.Actions';
import { SearchFilter } from './Panel.Toolbar.Search';
import styles from './style/Toolbar.css';

type Props = unknown;

export const Toolbar = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <SearchFilter />
          <Actions />
        </div>
      </div>
    </div>
  );
};

Toolbar.displayName = 'Group.Contacts.Panel.Toolbar';