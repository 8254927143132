import { useCallback } from 'react';
import { useIsInternalUser } from '@containers/Store';

export const useShouldDisplayExternalBadge = () => {
  const internalUser = useIsInternalUser();

  const shouldDisplayExternalBadge = (internal?: boolean) => {
    if (!internalUser) return false;

    return !internal;
  };

  return useCallback(shouldDisplayExternalBadge, [
    internalUser,
  ]);
};