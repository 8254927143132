import { useCallback, useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as $api from '@/brand-insights/api';
import { ChatStateDispatchContext } from '@/brand-insights/components/Chat.State';
import type { Chat } from '@/brand-insights/types';
import type * as IApi from '@/brand-insights/api/interfaces';

type Vars = IApi.Chat.SegmentationConfirmation.Request;

export const useSegmentationConfirmationMutation = () => {
  const dispatch = useContext(ChatStateDispatchContext);

  const handleUpdate = useCallback((segmentation: Chat.Segmentation.Segmentation) => {
    dispatch({
      type: 'segmentation/screen/confirmation',
      payload: {
        segmentation,
      },
    });
  }, [dispatch]);

  const mutation = useMutation(['segmentation:screen:confirmatino'], (vars: Vars) => {
    return $api.segmentationConfirmation(vars);
  }, {
    onSuccess: ({ segmentation }) => {
      handleUpdate(segmentation);
    },
  });

  return mutation;
};