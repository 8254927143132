import { useQuery } from '@tanstack/react-query';
import * as api from '@api';

type Params = number;

export const useFetchProjectInviteLink = (projectId: Params) => {

  return useQuery(['fetch-project-link'], async () => {
    const response = await api.projects.links.get({
      projectId,
    });

    return { link: response };

  }, { enabled: !!projectId });
};