import { useCallback, useMemo, useState } from 'react';
import { ProjectType } from '@enums';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { ProjectReportType, Project } from '@/types';
import { ReportSocketContainer, useReportKey } from '@containers/Reports';
import { useFilteredResponseUsers } from '@containers/GroupProject/hooks';
import { DropDown } from '@/components/DropDown';
import { useModal } from '@/components/Modal/hooks';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import { Header } from '@/components/Modal/Header';
import Toast from '@/components/Toast';
import { useDownloadProjectReportAsync } from './hooks/useDownloadProjectReport';
import styles from './style/ProjectReportModal.css';
import { useTrackProjectReportDownload } from './hooks/useTrackProjectReportDownload';

type Props = {
  isParentReport?: boolean;
  project: Pick<Project, 'id' | 'parentId' | 'projectType'>;
} & Pick<ModalProps, 'open' | 'onClose'>;

type DropdownItem = {
  id: ProjectReportType;
  value: string;
};

export const ProjectReportModal = (props: Props) => {
  return (
    <ReportSocketContainer onDownload={props.onClose}>
      <ModalContents {...props} />
    </ReportSocketContainer>
  );
};

export const ModalContents = ({ onClose, open, project, isParentReport }: Props) => {

  const [format, setFormat] = useState<ProjectReportType>();
  const [reportKey, setReportKey] = useReportKey();
  const filteredUsers = useFilteredResponseUsers();

  const {
    isFetching,
    refetch: download,
  } = useDownloadProjectReportAsync({
    format,
    isParentReport,
    projectId: project.id,
    projectParentId: project.parentId,
    filteredUsers,
  }, {
    onSuccess: data => {
      setReportKey(data.key);
    },
    onError: () => {
      Toast.error({
        body: 'Project Report',
        title: 'There was an error trying to download the report.',
      });
    },
  });

  const track = useTrackProjectReportDownload({ projectId: project.id });

  const handleSubmit = useCallback(() => {
    track(format);
    download();
  }, [
    download,
    format,
    track,
  ]);

  const dropdownItems: DropdownItem[] = useMemo(() => {
    return projectTypeFormats[project.projectType]
      .map(m => ({
        id: m,
        value: m.toLocaleUpperCase(),
      }));
  }, [
    project.projectType,
  ]);

  const handleSelect = useCallback((item: DropdownItem) => {
    setFormat(item.id);
  }, []);

  return (
    <Modal
      className={styles.root}
      open={open}
      onClose={onClose}>
      <Header text="Project Report" />
      <div className={styles.dropdown}>
        <DropDown<DropdownItem>
          items={dropdownItems}
          onSelect={handleSelect}
          text={format?.toLocaleUpperCase() || 'Choose Format'}
          getItemValue={(item: DropdownItem) => item.value} />
      </div>
      <div className={styles.btns}>
        <ButtonActivityIndicator
          className={styles.btn}
          disabled={!format}
          onClick={handleSubmit}
          loading={isFetching || !!reportKey}>
          Download
        </ButtonActivityIndicator>
      </div>
    </Modal>
  );
};

const projectTypeFormats = {
  [ProjectType.Call]: ['csv', 'ppt', 'sav', 'xlsx'],
  [ProjectType.Survey]: ['csv', 'ppt', 'sav', 'xlsx'],
  [ProjectType.SurveyCall]: ['csv', 'sav', 'xlsx'],
} as {
  [type: number]: ProjectReportType[];
};

export const useProjectReportModal = () => useModal(ProjectReportModal);

export default ProjectReportModal;