import qs from 'query-string';
import http, { transformers } from '@services/http';
import { Recruitments } from '$admin/api/interfaces';

export const getData = (data: Recruitments.DashboardGetData.Request) => {
  return http.get<Recruitments.DashboardGetData.Response>(`/admin/recruitments/dashboard?${qs.stringify(data)}`, { transformResponse: transformers.transformDates }).then(r => {
    return r;
  });
};

export const getBreakdownData = (data: Recruitments.DashboardGetBreakdown.Request) => {
  return http.get<Recruitments.DashboardGetBreakdown.Response>(`/admin/recruitments/dashboard/user-breakdown?${qs.stringify(data)}`, { transformResponse: transformers.transformDates }).then(r => {
    return r;
  });
};

export const getRecruiterCSV = (data: Recruitments.DashboardGetCSV.Request) => {
  return http.get<Recruitments.DashboardGetCSV.Response>(`/admin/recruitments/dashboard/recruiter-csv?${qs.stringify(data)}`, {
    responseType: 'blob',
  });
};

export const getRecruiterBreakdownCSV = (data: Recruitments.DashboardGetBreakdownCSV.Request) => {
  return http.get<Recruitments.DashboardGetBreakdownCSV.Response>(`/admin/recruitments/dashboard/recruiter-breakdown-csv?${qs.stringify(data)}`, {
    responseType: 'blob',
  });
};