import { useContext, useEffect, useState } from 'react';
import { ChatGuard } from '@/brand-insights/components/Chat/Chat.Guard';
import type { BrandInsightsCapabilities, Chat } from '@/brand-insights/types';
import {
  SubmitMessageContainer,
  SubmitChatActionContainer,
  RegenerateMessageContainer,
  AuthorContext,
  ChatHintsContainer,
  VariantMessageContainer,
  ChatPermissionsContainer,
  UploadFilesContainer,
  MessagesListRefContext,
} from '@/brand-insights/components/Chat';
import { QueryFeedbackModalContainer } from '@/brand-insights/components/Chat.Feedback';
import { ChatHistoryContainer } from '@/brand-insights/components/Chat.History';
import { GetStartedContainer } from '@/brand-insights/components/Chat.GetStarted';
import { SidebarContainer } from '@/brand-insights/components/Chat.Modal';
import { RateLimitingContext } from '@containers/RateLimiting/Context';
import { CapabilitiesContext } from './context';
import { WebSocketContainer } from './Socket.Container';

type Props = {
  author: Chat.Creator;
  capabilities: BrandInsightsCapabilities;
  children: React.ReactNode;
};

export const ChatContainer = (props: Props) => {
  const [messagesRef, setMessagesRef] = useState<HTMLDivElement>(null);
  const { query } = useContext(RateLimitingContext);

  useEffect(() => {

    query?.refetch();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <CapabilitiesContext.Provider value={props.capabilities}>
      <AuthorContext.Provider value={props.author}>
        <MessagesListRefContext.Provider value={[messagesRef, setMessagesRef]}>
          <GetStartedContainer>
            <SidebarContainer>
              <QueryFeedbackModalContainer>
                <ChatHistoryContainer>
                  <ChatPermissionsContainer>
                    <ChatGuard>
                      <SubmitMessageContainer>
                        <RegenerateMessageContainer>
                          <SubmitChatActionContainer>
                            <VariantMessageContainer>
                              <ChatHintsContainer>
                                <UploadFilesContainer>
                                  <WebSocketContainer>
                                    {props.children}
                                  </WebSocketContainer>
                                </UploadFilesContainer>
                              </ChatHintsContainer>
                            </VariantMessageContainer>
                          </SubmitChatActionContainer>
                        </RegenerateMessageContainer>
                      </SubmitMessageContainer>
                    </ChatGuard>
                  </ChatPermissionsContainer>
                </ChatHistoryContainer>
              </QueryFeedbackModalContainer>
            </SidebarContainer>
          </GetStartedContainer>
        </MessagesListRefContext.Provider>
      </AuthorContext.Provider>
    </CapabilitiesContext.Provider>
  );
};