import { Calendars } from '@api/interfaces';
import http from '@services/http';

export const getPersonalCalendar = (data: Calendars.GetPersonalCalendar.Request): Promise<Calendars.GetPersonalCalendar.Response> => {
  return http.get(`/calendars${data ? `?scheduleeId=${data.scheduleeId}` : ''}`);
};

export const getUserCalendarAvailability = (data: Calendars.GetUserCalendarAvailability.Request): Promise<Calendars.GetUserCalendarAvailability.Response> => {
  return http.get(`/calendars/availability?userId=${data.userId}&callId=${data.callId}`);
};

export const updatePersonalCalendar = (data: Calendars.UpdatePersonalCalendar.Request): Promise<Calendars.UpdatePersonalCalendar.Response> => {
  return http.patch(`/calendars/availability`, data);
};