import { useCallback } from 'react';
import { generatePath } from 'react-router';
import { Check, Lock } from 'react-feather';
import { path } from '@consts';
import { utils as enumUtils } from '@enums';
import { formatPlurality, getLocationFor } from '@utils';
import type { IConference } from '@/containers/Conference';
import { useConferenceInstance } from '@/containers/Conference';
import Button from '@/components/Button';
import styles from './style/Bar.Right.Surveys.css';

type SurveyItem = IConference.Coordinator.Conference.MeetingRoom['surveys'][number];

export const Surveys = () => {
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom>();

  return (
    <div className={styles.root}>
      <div>
        <div className={styles.header}>Survey and Polls</div>
      </div>
      <div className={styles.surveys}>
        {instance.surveys.map(s => <Survey key={s.id} survey={s} />)}
      </div>
    </div>
  );
};

type SurveyProps = { survey: SurveyItem };

const Survey = ({ survey }: SurveyProps) => {
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom>();
  const startSurvey = useCallback(() => {
    window.open(getLocationFor.conference.captureSheet({
      callId: instance.call.id,
      surveyId: survey.id,
    }).pathname, '_blank', `height=${screen.height},width=${screen.width}`);
  }, [instance.call.id, survey.id]);

  const Actions = useCallback(() => {
    if (survey.status === 'completed') {
      return <Check className={styles.completed} />;
    } else if (survey.status === 'locked') {
      return <Lock />;
    } else {
      return (
        <Button
          variant='brick'
          size='small'
          color='secondary'
          onClick={startSurvey}>
          {survey.status === 'in-progress' ? 'Resume' : 'Start'}
        </Button>
      );
    }
  }, [survey.status, startSurvey]);

  return (
    <div className={styles.surveyRoot}>
      <div className={styles.nameRow}>
        <div className={styles.surveyName}>{survey.name}</div>
        <div className={styles.actions}>
          <Actions />
        </div>
      </div>
      <div><span className={styles.surveyType}>{enumUtils.SurveyType.getName(survey.typeId)}</span> | <span className={styles.questionCount}>{survey.questionCount} {formatPlurality('Question', survey.questionCount)}</span></div>
    </div>
  );
};