import { useEffect, useState } from 'react';
import type { SurveyFormScreenState } from '@/types/survey';
import { SurveyFormScreen } from '@/containers/SurveyForm/Screen';
import { DynamicProgressContainer } from './DynamicProgressContainer';
import { SurveyFormScreenContext, useSurveyFormMetadata } from './Context';

type Props = {
  QuestionScreenComponent: React.ComponentType<{ hideNavigation?: boolean }>;
  state: SurveyFormScreenState;
};

export const SurveyFormStepperState = ({
  QuestionScreenComponent,
  ...props
}: Props) => {
  const [state, setState] = useState<SurveyFormScreenState>(props.state);
  const metadata = useSurveyFormMetadata();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [state.ordinal]);

  return (
    <>
      <DynamicProgressContainer
        eql={metadata.eql}
        ordinal={state.ordinal}
        total={metadata.questionsCount} />
      <SurveyFormScreenContext.Provider value={[state, setState]}>
        <SurveyFormScreen
          key={state.item.id}
          QuestionScreenComponent={QuestionScreenComponent} />
      </SurveyFormScreenContext.Provider>
    </>
  );
};

export default SurveyFormStepperState;