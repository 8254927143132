import { useContext } from 'react';
import { GroupConnectedAppsContext } from '@containers/Group.Contacts/Context';
import { CSVContainer, TaggingSearchContainer } from '@containers/Group.Contacts.Import';
import { Sidebar } from '@presentation/Sidebar';
import { LinearProgress } from '@/components/Progress/LinearProgress';
import { Stepper } from '@/components/Stepper';
import { useStepperContext } from '@/components/Stepper/hooks/useStepperContext';
import { ImportOptions } from './ImportOptions';
import { CommitSummary } from './CommitSummary';
import { CommitSummaryNavigation } from './CommitSummary.Navigation';
import { CSVFieldMapping } from './CSVFieldMapping';
import { CSVFieldMappingNavigation } from './CSVFieldMapping.Navigation';
import { Tagging } from './Tagging';
import { TaggingNavigation } from './Tagging.Navigation';
import styles from './style/ImportStepper.css';

type Props = unknown;

export const ImportStepper = (props: Props) => {
  const { query } = useContext(GroupConnectedAppsContext);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Stepper>
          <CSVContainer>
            <TaggingSearchContainer>
              <div className={styles.main}>
                <div className={styles.steps}>
                  <StepsSidebar />
                </div>
                <div className={styles.view}>
                  <Stepper.Route
                    exact
                    path="/contacts/import">
                    <ImportOptions />
                  </Stepper.Route>
                  <Stepper.Route
                    exact
                    path="/contacts/import/fields">
                    <CSVFieldMapping />
                    <CSVFieldMappingNavigation />
                  </Stepper.Route>
                  <Stepper.Route
                    exact
                    path="/contacts/import/tags">
                    <Tagging />
                    <TaggingNavigation />
                  </Stepper.Route>
                  <Stepper.Route
                    exact
                    path="/contacts/import/summary">
                    <CommitSummary />
                    <CommitSummaryNavigation />
                  </Stepper.Route>
                </div>
              </div>
            </TaggingSearchContainer>
          </CSVContainer>
        </Stepper>
      </div>
    </div>
  );
};

ImportStepper.displayName = 'ImportStepper';

const steps = [{
  title: `Import Contacts`,
}, {
  title: `Map Fields`,
}, {
  title: `Apply Tags`,
}, {
  title: `Start Import`,
}].map(x => ({
  title: x.title,
  key: x.title,
}));

const StepsSidebar = (props: Props) => {
  const ctx = useStepperContext();

  return (
    <Sidebar
      className={styles.sidebar}
      index={ctx.index}
      steps={steps} />
  );
};