import { Fragment, useMemo } from 'react';
import type { Participant, Room, Track, LocalTrackPublication, RemoteTrackPublication, VideoTrackPublication } from 'twilio-video';
import { VideoTrack } from 'twilio-video';
import { isCameraTrack, isScreenTrack } from '@/components/Conference.Video/constants';
import { Publication } from './Publication';
import { usePublications } from './hooks/usePublications';

type ParticipantTracksProps = {
  participant: Participant;
  disableAudio?: boolean;
  disableVideo?: boolean;
  videoPriority?: Track.Priority;
  room: Room;
  preferredVideoTrack?: VideoTrackPublication;
  isMainParticipant?: boolean;
};

function filterPublications(items: (LocalTrackPublication | RemoteTrackPublication)[], preferredVideoTrack: VideoTrackPublication) {
  if (preferredVideoTrack) {
    return items.filter(i => i.kind !== 'video' || i.trackSid === preferredVideoTrack.trackSid);
  } else {
    return items.some(p => isScreenTrack(p.trackName))
      ? items.filter(p => !isCameraTrack(p.trackName))
      : items;
  }
}

export function ParticipantTracks({ disableAudio, disableVideo, participant, videoPriority, room, preferredVideoTrack, isMainParticipant }: ParticipantTracksProps) {
  const isLocal = participant === room.localParticipant;

  const publications = usePublications(participant);

  const filteredPublications = useMemo(() => filterPublications(publications, preferredVideoTrack), [publications, preferredVideoTrack]);

  return (
    <Fragment>
      {filteredPublications.map(publication => (
        <Publication
          key={publication.trackName}
          publication={publication}
          participant={participant}
          isLocal={isLocal}
          disableAudio={disableAudio}
          disableVideo={disableVideo}
          videoPriority={videoPriority}
          isMain={isMainParticipant} />
      ))}
    </Fragment>
  );
}

export default ParticipantTracks;
