import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as api from '@api';
import { TranscribeRT } from '@/types';

type Props = {
  tagIds?: number[];
} & IProjectId;
type Data = {
  items: TranscribeRT.ProjectTaggedMoment[];
};
type QueryKey = readonly [string, Props];

type Options = UseQueryOptions<Data, AxiosError, Data, QueryKey>;

export const useFetchProjectTaggedConferenceMoments = (props: Props, options?: Options) => {

  return useQuery(['get-project-tagged-moments', props], ({ queryKey }) => {
    const [, { projectId, tagIds }] = queryKey;
    return api.projects.conference.getProjectTaggedMoments({
      projectId,
      tagIds,
    });
  }, options);
};