import { useCallback } from 'react';
import * as api from '@api';
import { useInvalidateFilesQueries } from '@utils/api';
import { WorkspaceObject } from '@/types';
import { BaseObjectOwnershipContainer } from './BaseOwnershipContainer';
import { useSaveObjectOwnership } from './hooks';

type Props =
  { object: WorkspaceObject } &
  IWorkspaceId &
  ChildrenProps;

export const ObjectOwnershipContainer = (props: Props) => {
  const saveOwnership = useSaveObjectOwnership();
  const invalidateQueries = useInvalidateFilesQueries();

  const handleSave = useCallback((ownerId: number) => {
    return saveOwnership({
      objectId: props.object.id,
      ownerId,
      workspaceId: props.workspaceId,
    })
    .then(() => {
      invalidateQueries();
    });
  }, [
    invalidateQueries,
    props.object.id,
    props.workspaceId,
    saveOwnership,
  ]);

  const fetchUsers = useCallback(() => {
    return api.groups.getGroupUsers({
      groupId: props.object.groupId,
    }).then(data => data.items);
  }, [
    props.object.groupId,
  ]);

  const containerProps = {
    fetchUsers,
    object: props.object,
    onSave: handleSave,
  };

  return (
    <BaseObjectOwnershipContainer {...containerProps}>
      {props.children}
    </BaseObjectOwnershipContainer>
  );
};

export default ObjectOwnershipContainer;