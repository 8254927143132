import extend from '@enums/extend';

export enum ProfessionalRole {
  None                = 0,
  Physician           = 1,
  NursePractitioner   = 2,
  Nurse               = 3,
  PhysiciansAssistant = 4,
  Pharmacist          = 5,
  Payer               = 6,
  PatientCaregiver    = 7,
  Other               = 8,
  ClinicianKOL        = 9,
  FormerExec          = 10,
  KOLInPractice       = 12,
  KOLResearchOnly     = 13,
  Patient             = 14,
  AdministrativeStaff = 15,
  Professor           = 16,
  HealthEconomist     = 17,
}

const map = new Map([
  [ProfessionalRole.Physician, { name: `Physician` }],
  [ProfessionalRole.NursePractitioner, { name: `Nurse Practitioner` }],
  [ProfessionalRole.Nurse, { name: `Nurse` }],
  [ProfessionalRole.PhysiciansAssistant, { name: `Physician's Assistant` }],
  [ProfessionalRole.Pharmacist, { name: `Pharmacist` }],
  [ProfessionalRole.Payer, { name: `Payer` }],
  [ProfessionalRole.PatientCaregiver, { name: `Patient Caregiver` }],
  [ProfessionalRole.Other, { name: `Other` }],
  [ProfessionalRole.ClinicianKOL, { name: `Clinician / KOL` }],
  [ProfessionalRole.FormerExec, { name: `Former Executive` }],
  [ProfessionalRole.KOLInPractice, { name: `KOL - In Practice` }],
  [ProfessionalRole.KOLResearchOnly, { name: `KOL - Research Only` }],
  [ProfessionalRole.None, { name: `None` }],
  [ProfessionalRole.Patient, { name: `Patient` }],
  [ProfessionalRole.AdministrativeStaff, { name: `Administrative Staff` }],
  [ProfessionalRole.Professor, { name: `Professor` }],
  [ProfessionalRole.HealthEconomist, { name: `Health Economist` }],
]);

export default {
  ProfessionalRole: extend(map),
};