import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import { cx } from '@utils';
import { Currencies } from '@/types';
import { SelectSearchUnderlined } from '$admin/components/Select';
import { NumberInput } from '@/components/Input';
import styles from './style/Modal.PickRespondent.css';

type Props = {
  currency:         Currencies.Item['code'];
  disabled?:        boolean;
  onChangeCurrency: (val: Currencies.Item['code']) => void;
  onChangeValue:    (val: string) => void;
  value:            string;
};

export const PayoutHonorarium = (props: Props) => {
  const query = useQuery([`get:search/currencies`], api.search.fetchCurrencies);

  const classnames = cx(styles.honorarium, {
    [styles.disabled]: props.disabled,
  });

  const options = query.data?.items ?? [];

  return (
    <div className={classnames}>
      <SelectSearchUnderlined
        className={styles.currency}
        getOptionLabel={option => option.sign ? `${option.code} (${option.sign})` : option.code}
        getOptionValue={option => option.code}
        isDisabled={props.disabled}
        tabIndex={props.disabled ? -1 : undefined}
        onChange={(val: Currencies.Item) => props.onChangeCurrency(val.code)}
        options={options}
        placeholder="Select Currency"
        value={options.find(x => x.code === props.currency)} />

      <NumberInput
        classes={{ input: styles.amount }}
        decimalScale={2}
        disabled={props.disabled}
        name="amount"
        onChange={e => props.onChangeValue(e.target.value)}
        placeholder="100.00"
        value={props.value} />
    </div>
  );
};

PayoutHonorarium.displayName = 'PickRespondent.PayoutHonorarium';