import { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight, Edit2 } from 'react-feather';
import Skeleton from '@mui/material/Skeleton';
import type { FromToProps } from 'remirror';
import { cx, getLocationFor, useInfiniteElementScroll } from '@utils';
import { HighlightQueryContext, TranscriptReplacementsContext, TermFilterContext, ProjectTermsContext } from '@containers/Group.Project.Terms/Context';
import type { TranscriptHighlight } from '@containers/Group.Project.Terms/interfaces';
import Button from '@/components/Button';
import { useProjectOrLatestChild } from '@/containers/GroupProject/hooks';
import Input from '@/components/Input';
import { Alert, useAlert } from '@/components/Modal/Alert';
import { TransformedProjectTermsContext } from './Context';
import { AudioPreview } from './AudioPreview';
import { useEditModal } from './Highlights.EditModal';
import styles from './styles/Highlights.css';

export const Highlights = () => {
  const { query: highlightsQuery } = useContext(HighlightQueryContext);
  const { replacements, globalReplacements, addReplacements, addGlobalReplacements } = useContext(TranscriptReplacementsContext);
  const { replacement: replacementTerm, highlightTerm: activeTerm, setReplacement } = useContext(TermFilterContext);
  const { terms } = useContext(TransformedProjectTermsContext);

  const correspondingTerm = useMemo(() => terms.find(t => t.termValue === activeTerm), [terms, activeTerm]);

  const numReplacedAlready = useMemo(() => {
    return replacements.filter(r => r.expectedText === activeTerm).length;
  }, [replacements, activeTerm]);

  const visibleHighlights = useMemo(() => {
    return highlightsQuery.data.pages.flatMap(p => p.highlights).filter(h => !replacements.some(r => r.transcriptId === h.transcript.id && doRangesOverlap(r.docPos, h.termDocPos)));
  }, [highlightsQuery.data.pages, replacements]);

  const scrollRef = useRef<HTMLDivElement>();

  const [activeIndex, setActiveIndex] = useState(0);
  const activeHighlight = useMemo(() => visibleHighlights[activeIndex], [activeIndex, visibleHighlights]);

  const onReplaceActive = useCallback(() => {
    if (activeHighlight) {
      addReplacements([{ replacementText: replacementTerm, expectedText: activeHighlight.foundTerm, tsStart: activeHighlight.highlightTimePos.fromTs, transcriptId: activeHighlight.transcript.id, docPos: activeHighlight.termDocPos }]);
    }
  }, [activeHighlight, addReplacements, replacementTerm]);

  const [toggleConfirmReplaceAll, ConfirmReplaceAllAlert] = useAlert();
  const onReplaceAll = useCallback(() => {
    addGlobalReplacements([{
      replacementText: replacementTerm,
      originalText: activeTerm,
      transcriptIds: correspondingTerm?.transcriptIds,
      sourceEntities: correspondingTerm?.entities,
    }]);
  }, [addGlobalReplacements, replacementTerm, activeTerm, correspondingTerm?.transcriptIds, correspondingTerm?.entities]);
  const ReplaceAllConfirmationText = useCallback(() => {
    if (!correspondingTerm) return `Unknown Term`;

    return (
      <div className={styles.replaceAllWarning}>
        <div>
          Would you like to change <strong>{correspondingTerm.termValue}</strong> to <strong>{replacementTerm}</strong> across all transcripts?
        </div>
        {correspondingTerm.transcriptIds?.length &&
          <div>This will update {correspondingTerm.occurrences} references across {correspondingTerm.transcriptIds.length} transcripts.</div>
        }
      </div>
    );
  }, [correspondingTerm, replacementTerm]);

  useEffect(() => {
    if (!highlightsQuery.isFetching && activeIndex > visibleHighlights.length) {
      setActiveIndex(visibleHighlights.length - 1);
    }
  }, [highlightsQuery.isFetching, activeIndex, visibleHighlights.length]);

  useInfiniteElementScroll({
    canFetch: highlightsQuery.hasNextPage && !highlightsQuery.isFetching,
    fetchNext: highlightsQuery.fetchNextPage,
    container: scrollRef,
  });

  if (!highlightsQuery.isFetching) {
    if (!highlightsQuery.data.pages[0]?.highlights?.length) return <div className={styles.highlightsMsg}>There are no occurrences for {activeTerm} in project transcripts.</div>;

    if (!visibleHighlights.length || globalReplacements.some(r => r.originalText === activeTerm)) return <div className={styles.highlightsMsg}>All occurrences for {activeTerm} are currently being replaced.</div>;
  }

  let currTranscript: number = null;

  return (
    <>
      <div className={styles.root}>
        <div className={styles.header}>
          <IndexNavigation
            index={activeIndex}
            total={correspondingTerm?.occurrences ?? (highlightsQuery.data.pages[0]?.total - numReplacedAlready)}
            setIndex={setActiveIndex} />
          <Input
            classes={{
              root: styles.replaceInputRoot,
            }}
            value={replacementTerm}
            onChange={e => setReplacement(e.target.value)}
            placeholder='Replace Term' />
          <div className={styles.spacer} />
          <div className={styles.btns}>
            <Button
              variant='brick'
              onClick={toggleConfirmReplaceAll}
              disabled={!replacementTerm}>
              Replace All
            </Button>
            <Button
              variant='brick'
              onClick={onReplaceActive}
              disabled={!replacementTerm || !activeHighlight}>
              Replace Next
            </Button>
          </div>
        </div>
        <div ref={scrollRef} className={styles.highlights}>
          {visibleHighlights.map((h, i) => (
            <div key={buildHighlightKey(h)} data-index={i}>
              {h.transcript.id !== currTranscript && (currTranscript = h.transcript.id) &&
                <TranscriptTitle transcript={h.transcript} />}
              <Highlight
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                highlight={h}
                index={i} />
            </div>
          ))}
          {(highlightsQuery.isFetching) && <LoadingSkeleton />}
        </div>
      </div>
      <ConfirmReplaceAllAlert
        className={styles.replaceAllConfirmation}
        message={<ReplaceAllConfirmationText />}
        onConfirm={onReplaceAll}
        confirmText='Replace All' />
    </>
  );
};

const TranscriptTitle = (props: Pick<TranscriptHighlight, 'transcript'>) => {
  const project = useProjectOrLatestChild();

  const url = getTranscriptUrl(props.transcript, project.slug);

  if (url && props.transcript.source?.type != 'unknown') {
    return (
      <div className={styles.transcriptName}>
        <TranscriptLink url={url}>
          {props.transcript.source.name}
        </TranscriptLink>
      </div>
    );
  } else {
    return <div className={styles.transcriptName}>Transcript: {props.transcript.id}</div>;
  }
};

const TranscriptLink = (props: { url: string } & ChildrenProps) => {
  return (
    <a
      href={props.url}
      target='_blank'
      rel='noreferrer'>
      {props.children}
    </a>
  );
};

const LoadingSkeleton = () => {
  const numRows = 5;

  const iterator = Array.from(Array(numRows).keys());
  return (
    <div className={styles.skeletonContainer}>
      {iterator.map((_, i) => (
        <div className={styles.skeletonRow} key={i}>
          <Skeleton
            variant='circular'
            height={40}
            width={40} />
          <Skeleton
            variant='rectangular'
            height={40}
            className={styles.skeletonSnippet} />
        </div>
      ))}
    </div>
  );
};

function buildHighlightKey(highlight: TranscriptHighlight) {
  return `${highlight.transcript.id}-${highlight.termDocPos.from}`;
}

function getTranscriptUrl(transcript: TranscriptHighlight['transcript'], projectSlug: string, s: number = null) {
  const query = s ? `?s=${s}` : '';
  if (transcript.source.type === 'call') {
    return getLocationFor.call.transcript({ callId: transcript.source.id, slug: projectSlug }).pathname + query;
  } else if (transcript.source.type === 'file') {
    return getLocationFor.workspaces.transcript({ transcriptId: transcript.id }).pathname + query;
  }
}

type IndexNavigationProps = {
  total: number;
  index: number;
  setIndex: (index: number) => void;
};

const IndexNavigation = (props: IndexNavigationProps) => {
  const canGoBack = props.index > 0;
  const canGoNext = props.index < props.total;
  return (
    <div className={styles.pagination}>
      <div>
        Result {props.index + 1} of {props.total}
      </div>
      <ChevronLeft className={cx(styles.pageBtn, canGoBack ? null : styles.disabled)} onClick={canGoBack ? () => props.setIndex(props.index - 1) : null} />
      <ChevronRight className={cx(styles.pageBtn, canGoNext ? null : styles.disabled)} onClick={canGoNext ? () => props.setIndex(props.index + 1) : null} />
    </div>
  );
};

type HighlightProps = {
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  index: number;
  highlight: TranscriptHighlight;
};

const Highlight = ({ highlight, index, setActiveIndex, activeIndex }: HighlightProps) => {
  const project = useProjectOrLatestChild();
  const [toggleEditModal, EditModal] = useEditModal();

  return (
    <>
      <div className={styles.highlightRow}>
        <AudioPreview
          audioUrl={highlight.transcript.sourceUrl}
          from={highlight.highlightTimePos?.fromTs}
          to={highlight.highlightTimePos?.toTs} />
        <div
          className={cx(styles.snippet, index === activeIndex ? styles.active : null)}
          onClick={() => setActiveIndex(index)}>
          <div dangerouslySetInnerHTML={{ __html: highlight.highlight }} />
          <Edit2
            className={styles.edit}
            onClick={toggleEditModal}
            size={16} />
        </div>
        <a
          className={styles.highlightLink}
          href={getTranscriptUrl(highlight.transcript, project.slug, highlight.highlightTimePos?.fromTs)}
          target='_blank'
          rel='noreferrer'>
          <ChevronsRight size={24} />
        </a>
      </div>
      <EditModal highlight={highlight} />
    </>
  );
};

function doRangesOverlap(a: FromToProps, b: FromToProps) {
  if (b.from < a.from) {
    return b.to >= a.from;
  }
  else {
    return b.from <= a.to;
  }
}