import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import * as api from '@api';
import { ProjectResearchConsentContext } from './Context';

type Props = {
  children: React.ReactNode;
};

export const ProjectOnboardingResearchConsentContainer = (props: Props) => {
  const [state, dispatch] = useState({
    ready: false,
    enabled: false,
  });
  const mutation = useMutation([`post:users/consent/privacy/research`], () => {
    return api.users.consent.updateResearchConsent({
      value: true,
    });
  });

  const query = useQuery([`get:users/consent/privacy/research`], () => {
    return api.users.consent.fetchResearchConsent();
  }, {
    onSuccess: res => {
      dispatch({
        enabled: !res?.notice?.value,
        ready: true,
      });
    },
    refetchOnWindowFocus: false,
  });

  const value = {
    enabled: state.enabled,
    mutation,
    query,
    ready: state.ready,
  };

  return (
    <ProjectResearchConsentContext.Provider value={value}>
      {props.children}
    </ProjectResearchConsentContext.Provider>
  );
};

ProjectOnboardingResearchConsentContainer.displayName = 'ProjectOnboarding.ResearchConsent.Container';