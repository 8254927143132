import { useSurveyBuilderItemEditing } from '@/containers/SurveyBuilder';
import { QuestionBuilderContainer } from '@containers/SurveyBuilder.Question/BuilderContainer';
import EditableQuestion from './EditableQuestion';
import QuestionBuilder from './QuestionBuilder';

export const QuestionBuilderItem = () => {

  const { editing } = useSurveyBuilderItemEditing();

  if (editing) {
    return (
      <QuestionBuilderContainer>
        <QuestionBuilder />
      </QuestionBuilderContainer>
    );
  } else {
    return (
      <EditableQuestion />
    );
  }
};

export default QuestionBuilderItem;