import http from '@services/http';
import * as xform from '@transformers';
import { transformDates } from '@services/http/transformers';
import type { WorkspaceFolders as WS } from './interfaces';

export const createFolder = (data: WS.CreateFolder.Request): Promise<WS.CreateFolder.Response<Date>> => {
  return http.post(`/workspaces/${data.workspaceId}/folders`, {
    name: data.name,
    parentObjectId: data.parentObjectId,
  }).then((data: WS.CreateFolder.Response) => ({
    folder: xform.normalizeFolder(data.folder),
    object: xform.normalizeWorkspaceObject(data.object),
  }));
};

export const deleteFolder = (data: WS.DeleteFolder.Request) => {
  return http.delete(`/workspaces/${data.workspaceId}/folders/${data.folderId}`);
};

export const renameFolder = (data: WS.RenameFolder.Request): Promise<WS.RenameFolder.Response<Date>> => {
  return http.patch(`/workspaces/folders/${data.folderId}/name`, {
    name: data.name,
  }).then((data: WS.RenameFolder.Response) => ({
    folder: xform.normalizeFolder(data.folder),
    object: xform.normalizeWorkspaceObject(data.object),
  }));
};

export const getFolder = (data: WS.GetFolder.Request): Promise<WS.GetFolder.Response> => {
  return http.get(`/workspaces/folders/${data.folderId}`, {
    transformResponse: transformDates,
  });
};