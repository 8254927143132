import { useRef, useCallback } from 'react';
import { AudioTrack } from 'twilio-video';
import { roundToPlace } from '@utils';
import { useAudioLevel } from './hooks/useAudioLevel';
import styles from './style/AudioLevelBorder.css';

type Props = {
  audioTrack: AudioTrack;
} & ChildrenProps;

const MAX_AUDIO_LEVEL = 21;
const MAX_BORDER_SIZE = 6;
export const AudioLevelBorder = ({ audioTrack, children }: Props) => {
  const borderElement = useRef<HTMLDivElement>();
  const onLevelChange = useCallback((val: number) => {
    if (borderElement.current) {
      const inverted = val;
      const scaled = inverted / MAX_AUDIO_LEVEL * MAX_BORDER_SIZE;

      const rounded = roundToPlace(scaled, 4);

      borderElement.current.style.outlineWidth = `${rounded}px`;
    }
  }, []);

  useAudioLevel({ audioTrack, onLevelChange });

  return (
    <div ref={borderElement} className={styles.root}>
      {children}
    </div>
  );
};