import { memo } from 'react';
import { BulletPointItem, ConferenceTagSummaryItem } from '@/types';
import { cx } from '@utils';
import styles from './style/Insights.css';

type Props = {
  className?: string;
  item: ConferenceTagSummaryItem;
};

export const Insights = memo((props: Props) => {
  if (!props.item.insights?.length) return null;

  const className = cx(styles.root, props.className);

  return (
    <div className={className}>
      {props.item.insights.map(x => {
        const hideTitle = x.text.toLowerCase() === props.item.tag.name.toLowerCase();
        return (
          <div key={x.text} className={styles.item}>
            {!hideTitle && <span className={styles.title}>{x.text}</span>}
            <UnorderedList items={x.items} />
          </div>
        );
      })}
    </div>
  );
});

type UnorderedListProps = {
  items: BulletPointItem['items'];
};

export const UnorderedList = memo((props: UnorderedListProps) => {
  if (!props.items?.length) return null;

  return (
    <ul className={styles.ul}>
      {props.items.map((bullet, i) =>
        <li key={i} className={styles.li}>
          {bullet.text}
          <UnorderedList items={bullet.items} />
        </li>)}
    </ul>
  );
});