import { useCallback, useMemo } from 'react';
import { SurveyQuestionType } from '@enums';
import { cx } from '@utils';
import { MatrixGridPreview } from './MatrixGrid';
import { MatrixMultiselectPreview } from './MatrixMultiselect';
import MaxDiff from './MaxDiff.Question';
import { MultipleChoicePreview } from './MultipleChoice';
import { MultiselectPreview } from './Multiselect';
import type { QuestionItemProps } from './interfaces';
import { MultiTextboxesQuestion } from './MultiTextboxes.Question';
import { ExclusiveOptions } from './ExclusiveOptions';
import { NumberInputTableQuestionPreview } from './NumberInputTable';
import { RankingPreview } from './Ranking';
import { SlidersPreview } from './Sliders';
import { MatrixRangePreview } from './MatrixRange';
import { DropdownPreview } from './Dropdown';
import { LongTextResponsePreview } from './LongTextResponse';
import { ShortTextResponsePreview } from './ShortTextResponse';
import { ImageMarkupPreview } from './ImageMarkup';
import styles from './style/QuestionItem.css';

export const QuestionPreviewItem = (props: QuestionItemProps) => {

  const renderContent = useCallback(() => {

    switch (props.item.typeId) {

      case SurveyQuestionType.MultipleChoice:
        return (
          <MultipleChoicePreview
            item={props.item} />
        );

      case SurveyQuestionType.Multiselect:
        return (
          <MultiselectPreview
            item={props.item} />
        );

      case SurveyQuestionType.Ranking:
        return (
          <RankingPreview
            item={props.item} />
        );

      case SurveyQuestionType.Sliders: {
        return (
          <SlidersPreview
            item={props.item} />
        );
      }

      case SurveyQuestionType.MatrixGrid: {
        return (
          <MatrixGridPreview
            item={props.item} />
        );
      }

      case SurveyQuestionType.MatrixMultiselect: {
        return (
          <MatrixMultiselectPreview
            item={props.item} />
        );
      }

      case SurveyQuestionType.MaxDifference:
        return <MaxDiff item={props.item} />;

      case SurveyQuestionType.MultiTextbox:
        return <MultiTextboxesQuestion item={props.item} />;

      case SurveyQuestionType.ExclusiveOptions:
        return (
          <ExclusiveOptions
            item={props.item} />
        );

      case SurveyQuestionType.NumberInputTable:
        return (
          <NumberInputTableQuestionPreview
            item={props.item} />
        );

      case SurveyQuestionType.MatrixRange:
        return (
          <MatrixRangePreview
            item={props.item} />
        );

      case SurveyQuestionType.Dropdown:
        return <DropdownPreview item={props.item} />;

      case SurveyQuestionType.LongTextResponse:
        return <LongTextResponsePreview item={props.item} />;

      case SurveyQuestionType.ShortTextResponse:
        return <ShortTextResponsePreview item={props.item} />;

      case SurveyQuestionType.ConjointAnalysis:
        return null;

      case SurveyQuestionType.ImageMarkup:
        return <ImageMarkupPreview item={props.item} />;

      default: {
        const error = new UnreachableCaseError(props.item);
        return null;
      }
    }
  }, [props.item]);

  return (
    <div className={cx(styles.root, props.className)}>
      <div className={styles.content}>
        {renderContent()}
      </div>
    </div>
  );
};

export default QuestionPreviewItem;