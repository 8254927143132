import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  SurveyFormVersionIdContext,
  SurveyFormMetadataContext,
  SurveyFormStepperState,
  SurveyResponseIdentifierContext,
  SaveCompletedSurveyResponseContext,
  SaveDisqualifiedSurveyResponseContext,
  SurveyFormTemplateContext,
} from '@containers/SurveyForm';
import { ProjectSurveyQuestion } from '@screens/Survey';
import { getLocationFor } from '@utils';
import { useFetchProjectSurveyForm } from '@utils/api';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import { ProjectOnboardingContext, SurveyProjectContext } from './Context';
import { useMatchProjectIdFromRoute } from './hooks';

export const ProjectTestDriveContainer = () => {
  const { projectId } = useMatchProjectIdFromRoute();

  const {
    data: res,
    isLoading,
    refetch,
  } = useFetchProjectSurveyForm({
    preview: true,
    projectId,
  }, {
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
  });

  useEffect(() => { refetch(); }, [refetch]);

  const history = useHistory();

  const handleDisqualification = useCallback(() => {
    const loc = getLocationFor.project.previewComplete({
      id: projectId,
      isDisqualified: true,
    });

    history.push(loc.pathname);
  }, [
    history,
    projectId,
  ]);

  const handleCompletion = useCallback(() => {
    const loc = getLocationFor.project.previewComplete({
      id: projectId,
      isDisqualified: false,
    });

    history.push(loc.pathname);

  }, [
    history,
    projectId,
  ]);

  if (isLoading || !res) {
    return <ActivityIndicator show />;
  }

  console.log(res);

  return (
    <SurveyProjectContext.Provider value={res.project}>
      <SurveyFormVersionIdContext.Provider value={res.survey.id}>
        <SurveyFormMetadataContext.Provider value={res.metadata}>
          <SaveCompletedSurveyResponseContext.Provider value={handleCompletion}>
            <SaveDisqualifiedSurveyResponseContext.Provider value={handleDisqualification}>
              <SurveyResponseIdentifierContext.Provider value={res.response.identifier}>
                <SurveyFormTemplateContext.Provider value={res.survey.template}>
                  <SurveyFormStepperState
                    state={res.response.state}
                    QuestionScreenComponent={ProjectSurveyQuestion} />
                </SurveyFormTemplateContext.Provider>
              </SurveyResponseIdentifierContext.Provider>
            </SaveDisqualifiedSurveyResponseContext.Provider>
          </SaveCompletedSurveyResponseContext.Provider>
        </SurveyFormMetadataContext.Provider>
      </SurveyFormVersionIdContext.Provider>
    </SurveyProjectContext.Provider>
  );
};

export { ProjectOnboardingContext };
export default ProjectTestDriveContainer;