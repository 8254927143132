import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { path } from '@consts';
import { usePersistentProjectInvite, useProjectInviteWhitelabelState } from '@containers/ProjectLinkInvitation/hooks';
import { cx } from '@utils';
import { HorizonalTrinity } from '@/components/Branding/Logo';
import * as Whitelabel from '@/components/Whitelabel';
import { useNavbarLogo } from '@/components/Whitelabel/hooks';
import { MaybeLink } from '@/components/MaybeLink';
import logo from 'static/images/logo-sentiment-landscape.svg?url';
import styles from './style/Navbar.css';

type Props = {
  platform?: boolean;
} & Partial<Pick<LinkProps, 'to'>>;

export const Logo = ({
  to = path.Website.Root,
  ...props
}: Props) => {
  const whitelabel = useProjectInviteWhitelabelState();
  const urlstate = usePersistentProjectInvite();
  const state = urlstate.get();
  const logodata = useNavbarLogo(state?.data?.theming?.logos as Store.Group.Branding['logos']);

  if (whitelabel.enabled && logodata?.url) {
    return (
      <Link to={to}>
        <Whitelabel.NavbarLogo
          classes={{
            root: cx(styles.logo, styles.theme),
            landscape: styles.landscape,
          }}
          orientation={logodata.orientation}
          url={logodata.url} />
      </Link>
    );
  }

  if (props.platform) {
    return (
      <Link
        className={styles.logo}
        to={to}>
        <HorizonalTrinity />
      </Link>
    );
  }

  return (
    <MaybeLink
      className={styles.logo}
      to={to}>
      <img
        className={styles.img}
        src={logo} />
    </MaybeLink>
  );
};

Logo.displayName = 'Nav.Logo';