import { ChatFileUploadStatus } from '@/brand-insights/enums';
import type { ChatState, FileUploadsState } from './interfaces';

export function fileUploadsReducer(state: FileUploadsState.State, action: ChatState.Action): FileUploadsState.State {

  switch (action.type) {

    case 'file-upload-initiated':
      return state.concat(action.payload.fileUpload);

    case 'file-upload-started':
      return state.map(m => {
        if (m.identifier !== action.payload.identifier) {
          return m;
        }

        return {
          ...m,
          items: m.items.reduce((acc, x) => {
            if (x.identifier === action.payload.fileIdentifier) {
              return acc.concat({
                ...x,
                status: ChatFileUploadStatus.Started,
                fileId: action.payload.fileId,
                fileUploadId: action.payload.fileUploadId,
              });
            }

            return acc.concat(x);
          }, []),
        };
      });

    case 'file-upload-errored':
      return state.map(m => {
        if (m.identifier !== action.payload.identifier) {
          return m;
        }

        return {
          ...m,
          items: m.items.reduce((acc, x) => {
            if (x.identifier === action.payload.fileIdentifier) {
              return acc.concat({
                ...x,
                status: ChatFileUploadStatus.Errored,
              });
            }
            return acc.concat(x);
          }, []),
        };
      });

    case 'file-upload-completed':
      return state.map(m => {
        if (m.identifier !== action.payload.identifier) {
          return m;
        }

        return {
          ...m,
          items: m.items.reduce((acc, x) => {
            if (x.identifier === action.payload.fileIdentifier) {
              return acc.concat({
                ...x,
                status: ChatFileUploadStatus.Uploaded,
              });
            }
            return acc.concat(x);
          }, []),
        };
      });

    case 'file-upload-chat-ready':
      return state.map(m => {
        if (m.identifier !== action.payload.identifier) {
          return m;
        }

        return {
          ...m,
          items: m.items.reduce((acc, x) => {
            if (x.identifier === action.payload.fileIdentifier) {
              return acc.concat({
                ...x,
                status: ChatFileUploadStatus.Ready,
              });
            }
            return acc.concat(x);
          }, []),
        };
      });

    default:
      return state;
  }
}