import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { path, pathname } from '@consts';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { cx } from '@utils';
import * as AuthForm from '$website/components/AuthForm';
import * as AuthButton from '$website/components/AuthButton';
import { useLoginSubmit } from '$website/containers/Auth/hooks/useLoginSubmit';
import { useSocialAuth } from '$website/containers/Auth/hooks/useSocialAuth';
import { LoginError } from '$website/screens/Auth/LoginError';
import { Logo } from '@/components/Branding';
import { Modal } from '@/components/Modal/Modal';
import { useLoginModal } from './useLoginModal';
import styles from './style/AuthModal.css';

const { Layout } = AuthForm;

type Props = {
  blocking?: boolean;
};

export const LoginModal = ({ blocking }: Props) => {
  const [open, setOpen] = useLoginModal();
  const [form, submit] = useLoginSubmit();
  const social = useSocialAuth();

  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  function handleEmail(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    submit();
  }

  return (
    <Modal
      className={styles.root}
      blocking={blocking}
      onClose={handleClose}
      open={open}>
      <div className={styles.wrap}>

        <Layout.Header>
          <Layout.Row>
            <Logo.Square size={55} />
          </Layout.Row>

          <Layout.Row>
            <Layout.Title>Log In to Sentiment</Layout.Title>
          </Layout.Row>

        </Layout.Header>

        <div className={styles.body}>
          {/* <Layout.Row>
            <AuthButton.Google onClick={social.authorizeGoogle}>
              Log in with Google
            </AuthButton.Google>
          </Layout.Row> */}

          {/* <Layout.Row>
            <AuthButton.Facebook onClick={social.authorizeFacebook}>
              Log in with Facebook
            </AuthButton.Facebook>
          </Layout.Row> */}

          {/* <Layout.Row>
            <AuthButton.Apple onClick={social.authorizeApple}>
              Log in with Apple
            </AuthButton.Apple>
          </Layout.Row> */}

          {/* <Layout.Row>
            <Layout.ThematicBreak>
              or
            </Layout.ThematicBreak>
          </Layout.Row> */}

          <form onSubmit={handleEmail}>
            <AuthForm.Email.Login className={styles.input} />

            <div className={styles.forgot}>
              <Link to={pathname.FORGOT_PASSWORD}>
                Forgot your password?
              </Link>
            </div>

            <div className={cx(styles.login, styles.row)}>
              <LoginError className={cx(styles.error)} />
            </div>

            <Layout.Row className={styles.wrap}>
              <ButtonActivityIndicator
                className={styles.submit}
                loading={form.loading}>
                Log In
              </ButtonActivityIndicator>
            </Layout.Row>
            <Layout.Row>
              <div className={styles.otherStrategy}>
                <Link to={path.Website.EnterpriseLogin}>
                  <span>Looking for enterprise login?</span>
                </Link>
              </div>
            </Layout.Row>
          </form>
        </div>

      </div>
    </Modal>
  );
};