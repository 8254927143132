import { cx } from '@utils';
import styles from './style/ConversionOptimization.css';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const ConversionOptimization = (props: Props) => {
  return (
    <div className={cx(styles.root, props.className)}>
      {props.children}
    </div>
  );
};

export { ConversionOptimization };
export default ConversionOptimization;