import { GroupSidebarTaggedProjects } from '@/components/GroupSidebarProjects';
import { useTaggedProjectsLoading, useTaggedProjects, useTaggedProjectsUpdate } from '@/containers/TaggedProjects';
import { Card } from '@/presentation/Main';
import styles from './style/Sidebar.css';

type Props = {
  forehead?: boolean;
};

export const Sidebar = ({ forehead = true }: Props) => {
  const projectsLoading = useTaggedProjectsLoading();
  const projects = useTaggedProjects();
  const updateProjects = useTaggedProjectsUpdate();

  if (projectsLoading) {
    return null;
  }

  return (
    <>
      {forehead && <div className={styles.forehead} />}
      <div className={styles.sidebar}>
        <Card className={styles.card}>
          <GroupSidebarTaggedProjects
            items={projects}
            onSave={updateProjects} />
        </Card>
      </div>
    </>
  );
};

export default Sidebar;