import { useCallback, useContext } from 'react';
import { utils } from '@enums';
import { TranscriptSpeakersContext } from '@containers/Transcript/context';

export const useGetSpeakerLabel = () => {
  const map = useContext(TranscriptSpeakersContext);

  const getSpeakerLabel = (speakerIndex: number) => {

    const speaker = map?.[speakerIndex];
    const fallback = `Speaker ${speakerIndex + 1}`;

    const name = speaker?.name
      ? speaker.name
      : typeof speaker?.role === 'number'
        ? utils.CallRole.getName(speaker.role)
        : fallback;

    return name || fallback;
  };

  return useCallback(getSpeakerLabel, [map]);
};