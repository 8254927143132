import { createRef } from 'react';
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';
import { path } from '@consts';
import { useVideoPlayback } from '@screens/Discovery.Post/useVideoPlayback';
import { LinkLabeledIcon } from '$website/components';
import tl from 'static/images/website/q2q/thumb-dot-globe-l.png';
import styles from './style/Platform.css';

type Props = unknown;

export const Platform = (props: Props) => {
  const ref = createRef<HTMLVideoElement>();

  const playing = useVideoPlayback(ref);

  return (
    <div className={styles.root}>
      <div className={styles.bg}>
        <div className={styles.wrap}>
          <div className={styles.content}>
            <div className={styles.main}>
              <div className={styles.player}>
                <div className={styles.box}>
                  <video
                    className={styles.video}
                    controls
                    poster={tl}
                    ref={ref}
                    preload="metadata"
                    src={url.video} />
                  {!playing && <PlayArrowRounded
                    className={styles.play}
                    onClick={() => ref.current?.play?.()} />}
                </div>
              </div>
              <div className={styles.info}>
                <h2 className={styles.header}>
                  One platform for all your research
                </h2>
                <div className={styles.subtitle}>
                  Sentiment is a customer-centric research platform for product teams to access custom panels of their ideal buyers for feedback and market inputs.
                </div>

                <LinkLabeledIcon
                  classes={{ link: styles.link }}
                  to={path.Website.Demo}>
                  Request a demo today
                </LinkLabeledIcon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Platform.displayName = 'Quant2Qual.Platform';

const url = {
  video: `https://cdn.app.sentimentglobal.com/videos/q2q.mp4`,
};