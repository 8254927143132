import { useCallback, useMemo, useState } from 'react';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { usePopper } from 'react-popper';
import { useHasInternalAdminRole } from '@containers/User/hooks';
import { useToggleTagSumaryVersionsContext, useRefreshSummariesContext, useSummaryFeedbackClickedContext } from '@containers/ConferenceTag.Summary/context';
import { MoreVerticalAnchorSmall } from '@presentation/Anchor';
import { ConferenceTagSummaryItem } from '@/types';
import { useZIndexModifier, PopperMenu, PopperMenuItem } from '@/components/Popper';
import { Portal } from '@/components/Portal';

type Props = {
  className?: string;
  item: ConferenceTagSummaryItem;
};

export const TagSummaryContextMenu = (props: Props) => {

  const [open, setOpen] = useState(false);
  const toggleVersionsModal = useToggleTagSumaryVersionsContext(props.item);
  const { refresh, ...refreshCtx } = useRefreshSummariesContext();
  const openFeedbackModal = useSummaryFeedbackClickedContext(props.item);

  const hasAdminRole = useHasInternalAdminRole();

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);

  const zIndexModifier = useZIndexModifier({ zIndex: 8 });

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      zIndexModifier,
    ],
    placement: 'bottom-start',
  });

  const handleBlur = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleRefresh = useCallback(() => {
    if (refreshCtx.isRefreshing) return;
    return refresh(props.item.tag.id);
  }, [refresh, refreshCtx.isRefreshing, props.item.tag.id]);

  const refreshItem = useMemo(() => {
    return {
      children: 'Refresh',
      disabled: refreshCtx.isRefreshing,
      onClick: handleRefresh,
    };
  }, [handleRefresh, refreshCtx.isRefreshing]);

  const versionsItem = useMemo(() => {
    const totalVersions = props.item.summary?.meta?.totalVersions;
    const hasOtherVersions = totalVersions > 1;
    const children = [
      'View other versions',
      hasOtherVersions && `(${totalVersions})`,
    ].filter(Boolean).join(' ');

    return {
      children,
      disabled: !hasOtherVersions,
      onClick: toggleVersionsModal,
    };
  }, [props.item.summary?.meta?.totalVersions, toggleVersionsModal]);

  const feedbackItem = useMemo(() => {
    const hasFeedback = props.item.summary?.meta?.hasFeedback;
    return {
      children: 'View feedback',
      disabled: !hasAdminRole || !hasFeedback,
      onClick: openFeedbackModal,
    };
  }, [hasAdminRole, props.item.summary?.meta?.hasFeedback, openFeedbackModal]);

  const items = useMemo(() => {
    return [feedbackItem, refreshItem, versionsItem];
  }, [feedbackItem, refreshItem, versionsItem]);

  return (
    <>
      <div
        className={props.className}
        ref={setReferenceElement}
        onClick={handleClick}>
        <MoreVerticalAnchorSmall
          open={open} />
      </div>
      {open &&
        <Portal>
          <ClickAwayListener
            mouseEvent='onMouseDown'
            onClickAway={handleBlur}
            touchEvent='onTouchStart'>
            <div
              ref={setPopperElement}
              style={popperStyles.popper}
              {...attributes.popper}>
              <PopperMenu>
                {items.map((m, i) => {
                  return (
                    <PopperMenuItem
                      key={i}
                      disabled={m.disabled}
                      onClick={() => {
                        m.onClick();
                        setOpen(false);
                      }}>
                      {m.children}
                    </PopperMenuItem>
                  );
                })}
              </PopperMenu>
            </div>
          </ClickAwayListener>
        </Portal>
      }
    </>
  );
};