import { Fragment, memo, useCallback } from 'react';
import { Calendar } from 'react-feather';
import { useSelector } from 'react-redux';
import { useSelectProject, useSelectUser } from '@containers/Store';
import * as consts from '@consts';
import { InvitationType } from '@enums';
import * as selectors from '@store/selectors';
import { cx, getLocationFor } from '@utils';
import { AdHocCall, Project, ProjectCall, Invitations } from '@/types';
import { Button } from '@/components/Button';
import { ProjectIcon } from '@/components/icons';
import styles from './style/SidePanel.css';

type Props = unknown;

const AdHocCallTimeProposal = memo(({ item }: { item: AdHocCall }) => {

  const me = useSelectUser();

  const to = getLocationFor.scheduling.adHoc.selecting({
    callId: item.id,
    scheduleeId: me.id,
    schedulerId: item.lastModifiedBy,
  });

  return (
    <div className={styles.item}>
      <Calendar
        size={22}
        className={styles.icon} />
      <Button
        className={cx(styles.link, styles.text)}
        to={to}
        variant="link">
        {item.request.subject}
      </Button>
    </div>
  );
});

const AdHocCallRequest = memo(({ item }: { item: AdHocCall }) => {

  const to = getLocationFor.onboarding.call.details({ callId: item.id });

  return (
    <div className={styles.item}>
      <Calendar
        size={22}
        className={styles.icon} />
      <Button
        className={cx(styles.link, styles.text)}
        to={to}
        variant="link">
        {item.request.subject}
      </Button>
    </div>
  );
});

const ProjectCallRequest = memo(({ item }: { item: ProjectCall }) => {

  const me = useSelectUser();
  const project = useSelectProject(item.projectId);

  const to = getLocationFor.scheduling.selecting({
    callId: item.id,
    projectId: item.projectId,
    scheduleeId: me.id,
    schedulerId: item.creatorUserId,
  });

  return (
    <div className={styles.item}>
      <Calendar
        size={22}
        className={styles.icon} />
      <Button
        className={cx(styles.link, styles.text)}
        to={to}
        variant="link">
        {project.name}
      </Button>
    </div>
  );
});

const ProjectInvite = (({ item }: { item: Project }) => {
  const to = getLocationFor.onboarding.project({
    projectId: item.id,
  });

  return (
    <div className={styles.item}>
      <ProjectIcon
        className={styles.icon}
        size={22}
        projectType={item.projectType} />
      <Button
        className={cx(styles.link, styles.text)}
        to={to}
        variant="link">
        {item.name}
      </Button>
    </div>
  );
});

export const InvitationsPanel = (props: Props) => {
  const invitations = useSelector(selectors.selectConsultantInvitations);

  const renderInvitation = useCallback((item: Invitations.Item) => {
    switch (item.type) {
      case InvitationType.AdHocCallRequest:
        return (
          <AdHocCallRequest item={item.item} />
        );

      case InvitationType.ProjectCall:
        return (
          <ProjectCallRequest item={item.item} />
        );

      case InvitationType.ProjectRequest:
        return (
          <ProjectInvite item={item.item} />
        );

      case InvitationType.AdHocCallTimeProposal:
        return (
          <AdHocCallTimeProposal item={item.item} />
        );

      default: return null;
    }

  }, []);

  return (
    <div className={styles.root}>
      <div>
        {invitations.map(invite => (
          <Fragment key={`${invite.type}-${invite.item.id}`}>
            {renderInvitation(invite)}
          </Fragment>))}
      </div>
      {!!invitations.length && <ViewAll />}
      {!invitations.length && <ListEmpty />}
    </div>
  );
};

const ListEmpty = memo(() => {
  return (
    <div className={styles.empty}>
      <div className={styles.subtitle}>You will be notified as soon as you are selected for a project</div>
    </div>
  );
});

const ViewAll = memo(() => {
  return (
    <div className={styles.footer}>
      <Button
        className={styles.link}
        to={consts.path.Projects.Root}
        variant="link">
        View All
      </Button>
    </div>
  );
});