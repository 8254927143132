import { useEffect, useRef } from 'react';
import { cx } from '@utils';
import { useProjectCreationNavigation } from './context';
import { AutoFocusRefObject, ProjectCreationScreen } from './interfaces';
import styles from './style/style.old.css';

type Props = {
  screens: ProjectCreationScreen<AutoFocusRefObject>[];
};

const ScreenRefInjector = (props: Props) => {
  const { index } = useProjectCreationNavigation();
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {

    ref?.current?.focus?.();

  }, [index]);

  return (
    <>
      {props.screens.map((x, i) => index === i &&
        <div
          className={cx({ [styles.hidden]: index !== i })}
          key={x.key}>
          <x.component ref={(r: HTMLInputElement) => x.ignoreRef ? undefined : ref.current = r} />
        </div>)}
    </>
  );
};

export { ScreenRefInjector };
export default ScreenRefInjector;