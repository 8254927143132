import type { SurveyLogic } from '@/types';
import { cx } from '@utils';
import { SurveyActionType } from '@enums';
import { SurveyAction } from '@/components/SurveyBuilder.Logic/Preview.Action';
import styles from './style/Logic.Item.css';
import { SurveyConditions } from './Logic.Conditions';

type Props = {
  className?: string;
  item: SurveyLogic.Item;
};

export const SurveyLogicItem = ({ className, item }: Props) => {

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.row}>
        <SurveyAction
          className={cx({ [styles.dq]: item.action.type === SurveyActionType.Disqualification })}
          action={item.action} />
        <span className={styles.if}>if:</span>
      </div>
      <div className={styles.conditions}>
        <SurveyConditions
          conditions={item.conditions}
          rule={item.rule} />
      </div>
    </div>
  );
};