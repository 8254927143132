import { Fragment, useCallback } from 'react';
import type { MatrixMultiselectQuestion } from '@/types';
import styles from './style/MatrixMultiselect.css';
import { Rationale } from './Rationale';

type Props = {
  answer: MatrixMultiselectQuestion.Answer.Value;
  rationale: string;
};

export const MatrixMultiselectAnswerValue = ({ answer, rationale }: Props) => {

  const renderItem = useCallback((item: MatrixMultiselectQuestion.Answer.Item) => {
    const openEndedRowValue = item.group.openEndedValue
      ? `[${item.group.openEndedValue}]`
      : '';

    const rowValue = item.group.value;

    const joined = [rowValue, openEndedRowValue].join(' ');

    return (
      <Fragment key={item.group.id}>
        <div className={styles.row}>
          <div className={styles.rowText}>{joined}</div>
          {item.selected.map(option => (
            <div key={option.id} className={styles.option}>{option.value}</div>
          ))}
        </div>
      </Fragment>
    );
  }, []);

  return (
    <>
      {answer.items.map(renderItem)}
      <Rationale value={rationale} />
    </>
  );
};

export default MatrixMultiselectAnswerValue;