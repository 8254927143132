import { useEffect, useMemo } from 'react';
import {
  useRegisterChatPageContext,
  ChatContextType,
  type BrandInsightsPageContext,
} from '@/brand-insights';
import { useSelectGroup } from '@containers/Store/hooks';
import { useMatchWorkspaceStateFromUrl } from './hooks';
import { WorkspaceStateContext } from './Context';

type Props =
  ChildrenProps;

export const WorkspaceContainer = (props: Props) => {
  const workspace = useMatchWorkspaceStateFromUrl();
  const group = useSelectGroup();

  const folderContext: BrandInsightsPageContext.Folder = useMemo(() => {
    if (!group?.features?.insightsChat) return null;

    return {
      type: ChatContextType.Folder,
      data: {
        objectId: workspace?.objectId,
        folderId: null,
        workspaceId: workspace?.id,
      },
      metadata: {
        supportsUploads: false,
        name: workspace?.name,
      },
    }
  }, [workspace?.objectId, workspace?.id, workspace?.name, group?.features?.insightsChat]);

  const register = useRegisterChatPageContext();

  useEffect(() => {
    if (!folderContext) return;

    if (folderContext.data.objectId && folderContext.metadata.name) {
      register(folderContext);
    }
  }, [register, folderContext]);

  return (
    <WorkspaceStateContext.Provider value={{ workspace }}>
      {props.children}
    </WorkspaceStateContext.Provider>
  );
};

export default WorkspaceContainer;