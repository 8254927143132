import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import { Input } from '@/components/Input';
import { Field } from './Overview.Field';

type Props = {
  label:        string;
  alert?:       string;
  onChange:     (e: React.ChangeEvent<HTMLInputElement>) => unknown;
  inputName?:   string;
  placeholder?: string;
  tooltip?:     string;
  value:        string;
};

const NameWithRef = (props: Props, ref: ForwardedRef<HTMLInputElement>) => {
  return (
    <Field
      label={props.label}
      tooltip={props.tooltip}
      alert={props.alert}>
      <Input
        name={props.inputName ?? 'name'}
        onChange={props.onChange}
        placeholder={props.placeholder}
        ref={ref}
        value={props.value} />
    </Field>
  );
};

NameWithRef.displayName = 'Project.Creation.Details.Field.Name';

export const Name = forwardRef(NameWithRef);