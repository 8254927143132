import * as enums from '@enums';
import { PipelineRecord, Project } from '@/types';

type RecordStatus = Pick<PipelineRecord, 'statusId'>;

export function isActive(record: RecordStatus) {
  const statuses = [
    enums.UserProjectStatus.Active,
  ];

  return statuses.includes(record.statusId);
}

export function isAwaitingJoin(record: RecordStatus) {
  const statuses = [
    enums.UserProjectStatus.InReview,
    enums.UserProjectStatus.Invited,
  ];

  return statuses.includes(record.statusId);
}

export function isBookmarked(record: RecordStatus) {
  const statuses = [
    enums.UserProjectStatus.Added,
  ];

  return statuses.includes(record.statusId);
}

export function isDisqualified(record: RecordStatus) {
  const statuses = [
    enums.UserProjectStatus.Disqualified,
  ];

  return statuses.includes(record.statusId);
}

export function isInactive(record: RecordStatus) {
  const statuses = [
    enums.UserProjectStatus.Inactive,
  ];

  return statuses.includes(record.statusId);
}

export function isMemberOfProject(record: RecordStatus) {
  const statuses = [
    enums.UserProjectStatus.Active,
    enums.UserProjectStatus.Inactive,
  ];

  return statuses.includes(record.statusId);
}

export function isNotMemberOfProject(record?: RecordStatus) {
  const statuses = [
    enums.UserProjectStatus.Added,
    enums.UserProjectStatus.Disqualified,
    enums.UserProjectStatus.Recommended,
    enums.UserProjectStatus.Rejected,
  ];

  return !record || statuses.includes(record.statusId);
}

export function IsInReview(record: RecordStatus) {
  const statuses = [
    enums.UserProjectStatus.InReview,
  ];

  return statuses.includes(record.statusId);
}

export function isInvited(record: RecordStatus) {
  const statuses = [
    enums.UserProjectStatus.Invited,
  ];

  return statuses.includes(record.statusId);
}

export const isAwaitingComplianceAction = IsInReview;
export const isAwaitingConsultantAction = isInvited;

export const isProjectSurveyOnly = (data: Pick<Project, 'projectType'>) => {
  const types = [
    enums.ProjectType.Survey,
  ];

  return types.includes(data.projectType);
};

export function isRecommended(record: RecordStatus) {
  const statuses = [
    enums.UserProjectStatus.Recommended,
  ];

  return statuses.includes(record.statusId);
}

export function isRemoved(record: RecordStatus) {
  const statuses = [
    enums.UserProjectStatus.Disqualified,
    enums.UserProjectStatus.Rejected,
  ];

  return statuses.includes(record.statusId);
}