import { useContext, useMemo } from 'react';
import { MedicalExpertiseContext } from '@containers/MedicalOnboarding/Context';
import { MedicalExperienceNavigationContext } from '@containers/ProjectOnboarding.MedicalExperience/Context';
import { Optional } from '@containers/MedicalOnboarding/utils';
import { Specialty as ExpertiseSpecialty } from '@screens/MedicalOnboarding.Expertise/Specialty';
import styles from './style/Experience.css';

type Props = unknown;

export const Specialty = (props: Props) => {
  const ctx = {
    expertise: useContext(MedicalExpertiseContext),
    navigation: useContext(MedicalExperienceNavigationContext),
  };

  const specialtyIsRequired = useMemo(() => {
    return !Optional.specialtyGroup.includes(ctx.expertise.professionalRole);
  }, [
    ctx.expertise,
  ]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <ExpertiseSpecialty
            button={{
              disabled: specialtyIsRequired ? (!ctx.expertise.specialty.primary.group?.id || !ctx.expertise.specialty.primary.subspecialty?.id) : false,
              loading: ctx.expertise.mutation.specialty.isLoading,
            }}
            navigation={{
              back: ctx.navigation.back,
              onClick: ctx.navigation.next,
            }} />
      </div>
    </div>
  );
};

Specialty.displayName = 'Experience.Specialty';