import { useMemo, useCallback } from 'react';
import { Switch, Route, Redirect, useParams, useLocation, generatePath } from 'react-router-dom';
import * as consts from '@/consts';
import { TabView } from '@/components/TabView';
import { useTabViewState } from '@/components/TabView/hooks/useTabViewState';
import { WorkspaceSceneMap, WorkspaceRouteKey, WorkspaceRouteParams } from './interfaces';

export const WorkspaceTabView = ({ sceneMap: map }: { sceneMap: WorkspaceSceneMap }) => {
  const { workspace } = useParams<WorkspaceRouteParams>();
  const location = useLocation();
  const keys = useMemo(() => Object.keys(map) as WorkspaceRouteKey[], [map]);
  const routes = useMemo(() => keys.map(k => ({ key: k, ...map[k] })), [keys, map]);

  const formatPath = useCallback((tab: WorkspaceRouteKey) => {
    return generatePath(consts.path.Workspaces.WorkspaceTabs, { workspace, tab });
  }, [workspace]);

  const [{ index }, updateLocation] = useTabViewState<WorkspaceRouteKey>({
    formatPath,
    routes: keys,
  });

  const renderTabView = useCallback(() => {
    const defaultRoute = formatPath(WorkspaceRouteKey.Files);

    return (
      <Switch>
        {keys.map((key: WorkspaceRouteKey) => {
          const route = map[key];
          return (
            <Route
              key={key}
              exact={route.exact}
              path={route.path}
              component={route.component} />
          );
        })}
        <Redirect to={`${defaultRoute}${location.search}${location.hash}`} />
      </Switch>
    );
  }, [
    location.search,
    location.hash,
    formatPath,
    keys,
    map,
  ]);

  return (
    <TabView
      index={index}
      onIndexChange={updateLocation}
      routes={routes}
      renderTabView={renderTabView} />
  );
};

export default WorkspaceTabView;