import { useMediaDownloadModal } from '@screens/Call.Transcript/MediaDownloadModal';
import { DownloadTranscriptMediaContext } from './context';

type Props = ICallId & ChildrenProps;

export const DownloadCallMediaContainer = (props: Props) => {

  const [toggleMediaDownloadModal, MediaDownloadModal] = useMediaDownloadModal();

  return (
    <>
      <DownloadTranscriptMediaContext.Provider value={toggleMediaDownloadModal}>
        {props.children}
      </DownloadTranscriptMediaContext.Provider>
      <MediaDownloadModal callId={props.callId} />
    </>
  );
};