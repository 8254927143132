/** @jsxImportSource @emotion/react */
import { memo } from 'react';
import { css } from '@emotion/react';
import { useThemedStyles } from '../Theme';
import { GetStartedItem } from './interfaces';

type Props = {
  className?: string;
  item: GetStartedItem;
};

export const GetStartedCard = memo(({ className, item }: Props) => {

  const styles = useStyles();

  return (
    <div css={styles.root} className={className}>
      <div css={styles.iconWrap}>
        <item.icon
          size={item.size ?? 65}
          css={styles.icon} />
      </div>
      <div css={styles.title}>
        {item.title}
      </div>
    </div>
  );
});

const rootCss = (theme: Theme) => css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  // border: `2px solid ${theme.palette.gray.light1}`,
  // borderRadius: 5,

  padding: 15,
  width: 215,
  height: 180,
  boxSizing: 'border-box',
});

const titleCss = (theme: Theme) => css({
  textAlign: 'center',
  fontFamily: theme.fonts.semiBold,
});

const iconCss = css({

});

const iconWrapCss = css({
  height: 65,
  marginBottom: 15,
  display: 'flex',
  alignItems: 'center',
});

const useStyles = () => useThemedStyles({
  icon: iconCss,
  iconWrap: iconWrapCss,
  root: rootCss,
  title: titleCss,
});