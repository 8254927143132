import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { ErrorMessage } from '@screens/ErrorMessage';

type Props = {
  children: React.ReactNode;
};

export const ErrorBoundary = (props: Props) => {
  return (
    <SentryErrorBoundary fallback={props => <ErrorMessage {...props} />}>
      {props.children}
    </SentryErrorBoundary>
  );
};