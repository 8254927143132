import { useCallback } from 'react';
import { cx } from '@utils';
import { MaxDifferenceQuestion } from '@/types';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import { useSurveyRichTextEditor, SurveyRichTextEditorContainer, SurveyRichTextEditor } from '@/components/Survey.RichText';
import { RadioButton } from '@/components/Radio';
import styles from './style/MaxDiff.Set.css';

export default function MaxDiffSet({ value, set, settings, onChange }: Props) {
  const handleChoice = useCallback((choice: 'left' | 'right', value: number) => () => {
    onChange(choice, value);
  }, [onChange]);

  const leftRichText = useSurveyRichTextEditor({
    initialState: settings.label.left,
  });

  const rightRichText = useSurveyRichTextEditor({
    initialState: settings.label.right,
  });

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={cx(styles.left, styles.least)}>
          <SurveyRichTextEditorContainer
            editable={false}
            manager={leftRichText.manager}
            state={leftRichText.state}
            onChange={leftRichText.onChange}>
            <SurveyRichTextEditor />
          </SurveyRichTextEditorContainer>
        </div>
        <div className={styles.middle} />
        <div className={cx(styles.right, styles.most)}>
          <SurveyRichTextEditorContainer
            editable={false}
            manager={rightRichText.manager}
            state={rightRichText.state}
            onChange={rightRichText.onChange}>
            <SurveyRichTextEditor />
          </SurveyRichTextEditorContainer>
        </div>
      </div>
      <div className={styles.attributes}>
        {set.options.map(option => {
          return (
            <div className={styles.attribute} key={option.ordinal}>
              <div className={cx(styles.left, styles.radio)} onClick={handleChoice('left', option.ordinal)}>
                <RadioButton checked={option.ordinal === value?.left} />
              </div>
              <div className={cx(styles.middle, styles.name)}>
                {parseSurveyRichText(option.value)}
              </div>
              <div className={cx(styles.right, styles.radio)} onClick={handleChoice('right', option.ordinal)}>
                <RadioButton checked={option.ordinal === value?.right} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export { MaxDiffSet };

type Props = {
  set: MaxDifferenceQuestion.Form.Set;
  onChange: (choice: 'left' | 'right', value: number) => unknown;
  value: MaxDifferenceQuestion.RespondentAnswer.SetAnswer;
  settings: MaxDifferenceQuestion.Settings;
};
