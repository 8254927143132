import { AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as api from '@api';
import { Payout } from '@api/interfaces';

type Data = Payout.GetPayoutHistory.Response;

export default function usePaginatedUserPayoutHistory<TData = Data>(
  userId: number,
  page?: number,
  options?: UseQueryOptions<Data, AxiosResponse, TData>
) {

  return useQuery(['user-payout-history', page], () => {
    return api.payout.getPayoutHistory({
      userId,
      cursor: page ? +page : null,
    });
  }, {
    keepPreviousData: true,
    staleTime: 60*1000,
    ...options,
  });
}

export { usePaginatedUserPayoutHistory };