import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import { pathname } from '@consts';
import { EmailVerificationContainer } from '@containers/EmailVerification';
import { useSelectUser } from '@containers/Store';
import ActivityIndicator from '@/components/ActivityIndicator';
import { Logo } from '@/components/Branding';
import { ResendVerificationEmail } from './ResendVerificationEmail';
import { ReverifyEmail } from './ReverifyEmail';
import styles from './style/VerifyEmail.css';

const VerifyEmail = () => {
  const user = useSelectUser();

  if (user.meta.emailReverificationRequired) {
    return (
      <ReverifyEmail />
    );
  }

  return (
    <EmailVerificationContainer>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.main}>
            <Logo.Landscape className={styles.logo} />
            <div className={styles.header}>Confirm your email address</div>
            <div className={styles.message}>
              <div className={styles.subtext}>
                We have sent an email to <span className={styles.email}>{user.contact.email}</span>
              </div>
            </div>
            <div className={styles.message}>
              <div className={styles.subtext}>Click the verify button in the email to confirm your email address and gain access to your account.</div>
            </div>
            <ResendVerificationEmail />
          </div>
        </div>
      </div>
    </EmailVerificationContainer>
  );
};

export { VerifyEmail };
export default VerifyEmail;

const EmailReverification = (props: unknown) => {
  const params = useParams<{ token: string }>();
  const history = useHistory();
  const user = useSelectUser();
  const dispatch = useDispatch();

  const refresh = useMutation({
    mutationKey: [
      `post:users/verify/emails/refresh`,
      user.id,
    ],
    mutationFn: () => {
      return api.users.verify.refreshEmailVerification({
        userId: user.id,
      })
      .then(data => {
        const action = actions.userInfoChange({
          meta: {
            emailReverificationRequired: data.emailReverificationRequired,
            isEmailVerified: data.emailVerified,
          },
        });

        dispatch(action);
      });
    },
  });

  const reverify = useMutation({
    mutationKey: [
      `post:users/verify/emails/reverify`,
      params?.token,
      user.id,
    ],
    mutationFn: ({ token }: { token: string }) => {
      return api.users.verify.reverifyEmailToken({
        token,
        userId: user.id,
      })
      .then(data => {
        const action = actions.userInfoChange({
          meta: {
            emailReverificationRequired: data.emailReverificationRequired,
            isEmailVerified: data.isEmailVerified,
          },
        });
        dispatch(action);
      });
    },
  });

  useEffect(() => {
    if (params?.token) {
      reverify.mutate({
        token: params.token,
      });
    }
  }, [params?.token]);

  useEffect(() => {
    if (!user.meta.emailReverificationRequired) {
      history.replace({
        pathname: pathname.Home,
      });
    } else {
      refresh.mutate();
    }
  }, [
    history,
    user.meta.emailReverificationRequired,
  ]);

  if (reverify.isLoading || refresh.isLoading) {
    return (
      <ActivityIndicator show />
    );
  }

  return (
    <div>
      <div>
        Reverification required
      </div>
      <div>Check your inbox or resend email</div>
    </div>
  );
};