import { createContext } from 'react';
import type {
  Data,
  Filters,
  SetFilters,
  Sort,
  Pagination,
  TranscriptSearchItem,
  StarQuote,
  UpdateTranscriptRangeTags,
} from './interfaces';

export const ProjectClipsDataContext = createContext<Data>(null);
export const ProjectClipsFiltersContext = createContext<Filters>(null);
export const SetProjectClipsFiltersContext = createContext<SetFilters>(null);
export const ProjectClipsSortContext = createContext<Sort>(null);
export const ProjectClipsPaginationContext = createContext<Pagination>(null);
export const ProjectClipsTranscriptSearchContext = createContext<TranscriptSearchItem[]>(null);
export const ProjectClipsStarQuoteContext = createContext<StarQuote>(null);
export const ProjectClipsUpdateTranscriptRangeTagsContext = createContext<UpdateTranscriptRangeTags>(null);