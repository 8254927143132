import { useCallback, useContext } from 'react';
import { MedicalBackgroundContext, MedicalBackgroundIdentificationContext } from '@containers/MedicalOnboarding/Context';
import { cx } from '@utils';
import { Select } from '$admin/components/Select';
import styles from './style/Member.MedicalProfile.css';

type Props = {
  classes?: {
    root?: string;
    placeholder?: string;
  };
};

export const IsPayer = ({ classes = {}, ...props }: Props) => {
  const ctx = useContext(MedicalBackgroundContext);
  const identification = useContext(MedicalBackgroundIdentificationContext);

  const handleChange = useCallback((value: boolean) => {
    ctx.mutation.mutate({
      identification: {
        ...identification.form,
        isPayer: value,
      },
    }, {
      onSuccess: identification.rehydrate,
    });

    identification.setValue({
      isPayer: value,
    });
  }, [
    ctx.mutation,
    identification,
  ]);

  const classnames = {
    root: cx(styles.input, classes.root, {
      [classes.placeholder || styles.placeholder]: typeof identification.form.isPayer !== 'boolean',
    }),
  };

  const value = identification.form.isPayer === true
    ? 'yes'
    : identification.form.isPayer === false
      ? 'no'
      : '';

  return (
    <Select
      classes={classnames}
      options={OptionsMap}
      onChange={e => {
        const value = e.target.value === 'no'
          ? false
          : e.target.value === 'yes'
            ? true
            : null;

        handleChange(value);
      }}
      placeholder="N/A"
      value={value} />
  );
};

IsPayer.displayName = 'Member.Experience.Input.IsPayer';

const OptionsMap = {
  no: 'No',
  yes: 'Yes',
};