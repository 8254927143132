import { useCallback } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { pathname } from '@consts';
import { useSelectUser } from '@containers/Store';
import { Invitation, InvitationResponsive } from '@screens/Scheduling';
import { ProjectOnboardingSubmitCalendarParams } from '@screens/Scheduling/interfaces';
import { useAcceptAdHocCallRequest } from '@utils/api';
import { device, getLocationFor } from '@utils';
import Toast from '@/components/Toast';
import { useRequestCall } from './useCall';

export const AdHocCallRequestAvailability = () => {
  const call = useRequestCall();
  const me = useSelectUser();
  const history = useHistory();

  const { mutateAsync, ...accept } = useAcceptAdHocCallRequest({ callId: call.id }, {
    onSuccess: data => {

      Toast.success({
        body: 'The client has received your availability and will respond shortly.',
        title: 'Availability Sent!',
      });

      const payment = getLocationFor.onboarding.call.payment({ callId: call.id });

      history.replace(payment);
    },
  });

  const handleSubmit = useCallback((data: ProjectOnboardingSubmitCalendarParams) => {
    return mutateAsync({
      new: data.new,
      removed: data.removed,
    });
  }, [mutateAsync]);

  if (call.request.accepted) {
    return <Redirect to={pathname.Home} />;
  }

  const Screen = device.phone
    ? InvitationResponsive
    : Invitation;

  return (
    <>
      <Screen
        creatorId={call.creatorUserId}
        onSubmit={handleSubmit}
        scheduleeId={call.userId}
        schedulerId={call.creatorUserId}
        userId={me.id} />
    </>
  );
};

export default AdHocCallRequestAvailability;