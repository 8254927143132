import { useCallback } from 'react';
import { useScript } from './useScript';

type Props = {
  oauthToken: string;
  onSelect: (selected: google.picker.DocumentObject[]) => void;
};

export const useGoogleDrivePicker = (props: Props) => {
  const status = useScript({
    src: 'https://apis.google.com/js/api.js',
    onLoad: () => {
      gapi.load('auth', {
        'callback': () => {
          window.gapi.auth.authorize({
            'client_id': process.env.GOOGLE_SYNC_CLIENT_ID,
            'scope': 'https://www.googleapis.com/auth/drive',
            'immediate': true,
          }, () => { });
        },
      });
      gapi.load('picker', { callback: () => { } });
      gapi.load('client', {
        callback: () => {
          gapi.client.load('drive', 'v3');
        },
      });
    },
  });

  const pickerCallback = useCallback((data: google.picker.ResponseObject) => {
    if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
      const docs = data[google.picker.Response.DOCUMENTS];
      props.onSelect(docs);
    }
  }, [props]);

  const showPicker = useCallback(() => {
    const picker = new google.picker.PickerBuilder()
      //.addView(new google.picker.DocsUploadView())
      .addView(new google.picker.DocsView())
      .setOAuthToken(props.oauthToken)
      //.setDeveloperKey('AIzaSyDPs9U-dgOC9h1jRFNwOwhRtARCph8_3HM')
      .setCallback(pickerCallback)
      .build();
    picker.setVisible(true);
  }, [pickerCallback, props.oauthToken]);

  return {
    showPicker,
    isLoading: status === 'loading',
    isReady: status === 'ready',
  };
};