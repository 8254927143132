import http from '@services/http';
import * as Projects from './interfaces/projects.external-sourcing';

export const saveExternalSourcingConfiguration = (data: Projects.SaveExternalSourcingConfiguration.Request): Promise<Projects.SaveExternalSourcingConfiguration.Response> => {
  return http.post(`/projects/${data.projectId}/external-sourcing/${data.configId}`, data);
};

export const deleteExternalSourcingConfiguration = (data: Projects.DeleteExternalSourcingConfiguration.Request): Promise<Projects.DeleteExternalSourcingConfiguration.Response> => {
  return http.delete(`/projects/${data.projectId}/external-sourcing/${data.configId}`);
};

export const generateExternalSourcingLinks = (data: Projects.GenerateExternalSourcingLinks.Request): Promise<Projects.GenerateExternalSourcingLinks.Response> => {
  return http.post(`/projects/${data.projectId}/external-sourcing/${data.configId}/generate-links`, data);
};

export const downloadExternalSourcingLinks = (data: Projects.DownloadExternalSourcingLinks.Request) => {
  return http.download(`/projects/${data.projectId}/external-sourcing/${data.configId}/download-links`);
};

export const deleteExternalSourcingLinks = (data: Projects.DeleteUnsedExternalSourcingLinks.Request) => {
  return http.delete(`/projects/${data.projectId}/external-sourcing/${data.configId}/links`);
};

export const searchSourcingVendors = (data: Projects.SearchSourcingVendors.Request): Promise<Projects.SearchSourcingVendors.Response> => {
  return http.get(`/projects/external-sourcing/vendors?name=${data.name}`);
};