import { useContext } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { TranscriptHydrationContext } from '@containers/Transcript/context';
import { EditingTools } from './Transcript.Toolbar.EditingTools';
import { EnhancementActions } from './Transcript.Toolbar.Enhancement';
import styles from './style/Tool.css';

type Props = unknown;

export const TranscriptToolbar = (props: Props) => {
  const hydration = useContext(TranscriptHydrationContext);

  if (!hydration.state.transcript) {
    return (
      <div className={styles.actions}>
        <Skeleton variant="rectangular" sx={{ height: '30px', width: '80px', borderRadius: '15px' }} />
        <Skeleton variant="rectangular" sx={{ height: '30px', width: '80px', borderRadius: '15px' }} />
      </div>
    );
  }

  return (
    <div className={styles.actions}>
      <EditingTools />
      <EnhancementActions />
    </div>
  );
};

TranscriptToolbar.displayName = 'Transcript.Toolbar';