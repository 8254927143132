import { useState } from 'react';
import { Globe } from 'react-feather';
import type { ContextMenuItem } from '@/components/ContextMenu';
import { ContextMenu } from '@/components/ContextMenu';
import { useSurveyBuilderState, useIsLanguageVersion } from '@/containers/SurveyBuilder';
import { useQuestionBuilderItemContext } from '@/containers/SurveyBuilder.Question';
import { ItemLanguageModal } from './ItemLanguageModal';
import styles from './style/ItemLanguageToggle.css';

export const ItemLanguageToggle = () => {
  const [state] = useSurveyBuilderState();
  const [languageCode, setLanguageCode] = useState('');

  const inLanguageVersion = useIsLanguageVersion();

  const question = useQuestionBuilderItemContext();

  if (question?.metadata?.derivedType === 'language') return null;

  if (state.survey.language.languages?.length < 2 || inLanguageVersion) return null;

  return (
    <>
      <ContextMenu
        className={styles.menu}
        AnchorElement={Globe}
        items={state.survey.language.languages.map<ContextMenuItem>(l => ({
          label: `${l.name}${l.isDefault ? ` (Default)` : ''}`,
          disabled: l.isDefault,
          onClick: () => setLanguageCode(l.code),
        }))} />
      {languageCode && <ItemLanguageModal
        open={true}
        onClose={() => setLanguageCode(null)}
        languageCode={languageCode} />}
    </>
  );
};