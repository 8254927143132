import type { ChangeEvent } from 'react';
import { useCallback, useMemo } from 'react';
import { cx } from '@utils';
import { TextareaAutosize } from '@/components/Textarea';
import { SurveyQuestionOption } from '@/components/Survey.RichText';
import type { AltRankingForm } from './interfaces';
import { DraggableItem } from './Ranking.Alt.Item';
import styles from './style/Ranking.Alt.StartArea.css';
import { useRankingOptionOpenEndedValue } from './hooks/useRankingOpenEndedValue';

type Props = {
  className?: string;
  isDisabled: boolean;
  item: AltRankingForm.DraggableItem;
};

export const DraggableStartAreaItem = ({ isDisabled, item, ...props }: Props) => {

  const [openEndedValue, setOpenEndedValue] = useRankingOptionOpenEndedValue(item.id);

  const handleValueUpdated = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setOpenEndedValue(e.target.value);
  }, [setOpenEndedValue]);

  const isDragDisabled = useMemo(() => {
    return isDisabled;
    // return (item.isOpenEnded && !openEndedValue) || isDisabled;
  }, [/*item.isOpenEnded, openEndedValue,*/ isDisabled]);

  const className = cx(props.className, {
    [styles.disabled]: isDragDisabled,
  });

  return (
    <DraggableItem
      className={className}
      isDragDisabled={isDragDisabled}
      item={item}>
      <div className={styles.itemContent}>
        <SurveyQuestionOption value={item.value} editorClassName={styles.editor} />
        {item.isOpenEnded &&
          <div className={styles.openEndedInput}>
            <TextareaAutosize
              onChange={handleValueUpdated}
              value={openEndedValue} />
          </div>
        }
      </div>
    </DraggableItem>
  );
};