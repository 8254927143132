import { io } from 'socket.io-client';
import type { ProjectSocketEvent } from '@enums';
import { LifeCycleSocketEvent, SocketNamespace } from '@enums';
import { createLogger, resolveSocketOptions } from './utils';
import type { Projects } from './interfaces';

const logger = createLogger(SocketNamespace.Projects);

const socket = io(`${process.env.BACKEND_WS_URL}/${SocketNamespace.Projects}`, resolveSocketOptions());

export function open() {
  socket.on(LifeCycleSocketEvent.Connect, () => logger.event(LifeCycleSocketEvent.Connect));
  socket.on(LifeCycleSocketEvent.ConnectError, (...args) => logger.event(LifeCycleSocketEvent.ConnectError, args));
  socket.on(LifeCycleSocketEvent.Disconnect, () => logger.event(LifeCycleSocketEvent.Disconnect));

  socket.prependAny((event: ProjectSocketEvent, payload: unknown) => logger.event(event, payload));
  socket.open();
}

export function close() {
  socket.close();

  socket.offAny();
  socket.removeAllListeners();
}

export const on = <T extends ProjectSocketEvent>(event: T, handler: Projects.RegisterHandler<T>) => {
  return socket.on<ProjectSocketEvent | LifeCycleSocketEvent>(event, handler);
};

export const off = <T extends ProjectSocketEvent>(event: T, handler: Projects.RegisterHandler<T>) => {
  return socket.off<ProjectSocketEvent | LifeCycleSocketEvent>(event, handler);
};

export const subscribe = (projectId: number) => {
  logger.log(`subscribe:${projectId}`);
  socket.emit('subscribe', projectId);
};

export const unsubscribe = (projectId: number) => {
  logger.log(`unsubscribe:${projectId}`);
  socket.emit('unsubscribe', projectId);
};