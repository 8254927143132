import { memo } from 'react';
import { cx, roundToPlace, trunc, formatPlurality, formatWithOrdinalSuffix } from '@utils';
import CloseRow from './Close';
import ViewAll from './ViewAll';
import { LabelWithSubtitle } from './ColorIndicator';
import {
  AggregateDataHeaderRowProps,
  MatrixSliderValueRowProps,
  ProjectVersionDataRowProps,
  ProjectVersionHeaderRowProps,
  RankingRankRowRowProps,
  ResponsesDataRowProps,
  ResponseRankingAvgRowProps,
  ConjointAnalysisLevelRowProps,
} from './interfaces';
import styles from './style/ResponseRows.css';

export const ResponsesDataRow = memo(({
  children,
  className,
  label: title,
  onClick,
  subtitle,
}: ResponsesDataRowProps) => {
  const label = (
    <LabelWithSubtitle
      label={title}
      subtitle={subtitle} />
  );

  return (
    <div
      className={cx(styles.root, className)}
      onClick={onClick}>
      {label}
      {children}
    </div>
  );
});

export const ResponseRankingAvgRow = (props: ResponseRankingAvgRowProps) => {

  const avg = roundToPlace(props.avg, 1).toFixed(1);

  return (
    <ResponsesDataRow
      className={props.className}
      label={trunc(props.value, 40)}
      subtitle={`(Avg Rank: ${avg})`} />
  );
};

export const ResponseMatrixSliderAvgRow = (props: ResponseRankingAvgRowProps) => {

  const avg = roundToPlace(props.avg, 1).toFixed(1);

  return (
    <ResponsesDataRow
      className={props.className}
      label={trunc(props.value, 40)}
      subtitle={`(Avg: ${avg})`} />
  );
};

export const AggregateDataHeaderRow = memo(({
  className,
  label,
  onClose,
}: AggregateDataHeaderRowProps) => {
  return (
    <ResponsesDataRow
      className={className}
      label={label}>
      {onClose &&
        <CloseRow onClick={onClose} />
      }
    </ResponsesDataRow>
  );
});

export const ProjectVersionDataRow = memo(({
  className,
  label,
  onClick,
  version,
}: ProjectVersionDataRowProps) => {

  return (
    <ResponsesDataRow
      className={cx(styles.versionData, className)}
      onClick={onClick}
      label={`Version ${version}:`}
      subtitle={label}>
      <ViewAll
        onClick={onClick}
        title="View Results" />
    </ResponsesDataRow>
  );
});

export const ProjectVersionHeaderRow = memo(({
  className,
  onClick,
  version,
}: ProjectVersionHeaderRowProps) => {

  return (
    <ResponsesDataRow
      className={className}
      label={`Version ${version}`}>
      <CloseRow onClick={onClick} />
    </ResponsesDataRow>
  );
});

export const MatrixSliderValueRow = memo(({
  value,
  count,
  className,
  onClick,
}: MatrixSliderValueRowProps) => {

  const subtitle = `(${count} ${formatPlurality('Respondent', count)})`;

  return (
    <ResponsesDataRow
      className={cx(styles.versionData, className)}
      onClick={onClick}
      label={value}
      subtitle={subtitle}>
      <ViewAll
        onClick={onClick}
        title="View All Respondents" />
    </ResponsesDataRow>
  );
});

export const RankingRankRow = memo(({
  count,
  className,
  onClick,
  rank,
}: RankingRankRowRowProps) => {
  const label = rank === 0
    ? 'N/A'
    : `Ranked ${formatWithOrdinalSuffix(rank)}`;

  const subtitle = `(${count} ${formatPlurality('Respondent', count)})`;

  return (
    <ResponsesDataRow
      className={cx(styles.versionData, className)}
      onClick={onClick}
      label={label}
      subtitle={subtitle}>
      <ViewAll
        onClick={onClick}
        title="View All Respondents" />
    </ResponsesDataRow>
  );
});

export const ConjointAnalysisLevelRow = memo(({
  className,
  level,
  onClick,
  utility,
}: ConjointAnalysisLevelRowProps) => {
  const subtitle = `(${utility})`;

  return (
    <ResponsesDataRow
      className={cx(styles.versionData, className)}
      onClick={onClick}
      label={level.value}
      subtitle={subtitle}>
      <ViewAll
        onClick={onClick}
        title="View All Respondents" />
    </ResponsesDataRow>
  );
});