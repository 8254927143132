import { Reducer, useCallback, useReducer } from 'react';
import * as consts from '@consts';
import { Search } from '@/types';

const useSearchParamsState = () => {
  const [state, dispatch] = useReducer<Reducer<Search.Params, Action>>(paramsReducer, initial);

  function push<T extends FilterParamKey>(key: T, item: FilterParamValueItem<T>) {
    dispatch({
      type: 'ADD_TO_ADVANCED_FILTER_LIST',
      list: key,
      item,
    });
  }

  function pop<T extends FilterParamKey>(key: T, id: FilterParamValueItem<T>['id']) {
    dispatch({
      type: 'REMOVE_FROM_ADVANCED_FILTER_LIST',
      list:  key,
      id,
    });

  }

  function init(params = initial) {
    dispatch({
      type: 'SET_SEARCH_FILTERS',
      ...params,
    });
  }

  function batch<T extends FilterParamKey>(actions: Action<T>[]) {
    dispatch({
      type: 'BATCH_ACTIONS',
      actions,
    });
  }

  function clear() {
    dispatch({
      type: 'RESET_SEARCH_FILTERS',
      ...initial,
    });
  }

  function orderBy(order: SortParams['sortDir']) {
    dispatch({
      type: 'UPDATE_SEARCH_SORT',
      sort: state.sort,
      sortDir: order,
    });
  }

  function sortBy(sort: SortParams['sort']) {
    dispatch({
      type: 'UPDATE_SEARCH_SORT',
      sort,
      sortDir: state.sortDir,
    });

  }

  function between<T extends RangeFilter>(key: T, [low, high]: [number, number]) {
    dispatch({
      type: 'SET_PRICE_RANGE',
      high,
      low,
    });
  }

  const actions: SearchParamsActions = {
    batch: useCallback(batch, [dispatch]),
    between: useCallback(between, [dispatch]),
    clear: useCallback(clear, [dispatch]),
    init: useCallback(init, [dispatch]),
    orderBy: useCallback(orderBy, [dispatch, state.sort]),
    pop: useCallback(pop, [dispatch]),
    push: useCallback(push, [dispatch]),
    sortBy: useCallback(sortBy, [dispatch, state.sortDir]),
  };

  return [state, actions] as const;
};

export declare namespace Compat {

  export type DispatchMap = {
    ADD_TO_ADVANCED_FILTER_LIST:      <T extends FilterParamKey>(data: Compat.ParamValueItem<T>) => void;
    REMOVE_FROM_ADVANCED_FILTER_LIST: <T extends FilterParamKey>(data: Compat.ParamValueItemId<T>) => void;
    SET_SEARCH_FILTERS:               (params?: Partial<Search.Params>) => void;
    BATCH_ACTIONS:                    <T extends FilterParamKey>(data: { actions: Action<T>[]; }) => void;
    RESET_SEARCH_FILTERS:             () => void;
    SET_PRICE_RANGE:                  (data: { low: number; high: number; }) => void;
    UPDATE_SEARCH_SORT:               (data: SortParams) => void;
    KEYWORD_SEARCH_UPDATE:            (data: { keyword: string; }) => void;
  };

  export type ParamValueItem<T extends FilterParamKey> = {
    list: T;
    item: FilterParamValueItem<T>;
  };

  export type ParamValueItemId<T extends FilterParamKey> = {
    list: T;
    id: FilterParamValueItem<T>['id'];
  };

}

export type SearchParamsActions = {
  batch:   <T extends FilterParamKey>(actions: Action<T>[]) => void;
  between: <T extends RangeFilter>(key: T, range: [number, number]) => void;
  clear:   () => void;
  init:    (params?: Partial<Search.Params>) => void;
  orderBy: (params: SortParams['sortDir']) => void;
  pop:     <T extends FilterParamKey>(key: T, id: FilterParamValueItem<T>['id']) => void;
  push:    <T extends FilterParamKey>(key: T, item: FilterParamValueItem<T>) => void;
  sortBy:  (params: SortParams['sort']) => void;
};

type RangeFilter = 'price';

export type FilterParams =
  Omit<Search.Params,
  | 'priceHigh'
  | 'priceLow'
  | 'sort'
  | 'sortDir'>;

export type SortParams =
  Pick<Search.Params,
  | 'sort'
  | 'sortDir'>;

type CustomItem = {
  isCustom: true;
  metadata: { key: string; };
  name:     string;
};

export type FilterParamKey<
  T extends FilterParams = FilterParams,
  K extends keyof T = keyof T> = K;

type FilterParamValue<
  T extends FilterParams = FilterParams,
  K extends keyof T = keyof T> = T[K];

type FilterParamValueItem<T extends FilterParamKey = FilterParamKey> =
  & Search.Filter.Keyword
  & Omit<FilterParamValue<FilterParams, T>, 'keyword'>[number]
  & CustomItem
  | Search.Filter.JobFunction

export type Action<T = FilterParamKey> =
  | { type: 'BATCH_ACTIONS'; actions: Action<T>[]; }
  | { type: 'RESET_SEARCH_FILTERS'; } & Pick<typeof initial, keyof SortParams>
  | { type: 'UPDATE_SEARCH_SORT'; } & SortParams
  | { type: 'SET_SEARCH_FILTERS'; } & Search.Params
  | { type: 'APPLY_ADVANCED_FILTERS'; }
  | { type: 'ADD_TO_ADVANCED_FILTER_LIST'; item: FilterParamValueItem; list: T; }
  | { type: 'REMOVE_FROM_ADVANCED_FILTER_LIST'; list: T; } & Pick<FilterParamValueItem, 'id'>
  | { type: 'SET_PRICE_RANGE'; low: number; high: number; };

export const paramsReducer = (state: Search.Params, action: Action) => {
  switch (action.type) {
    case 'BATCH_ACTIONS':
      return action.actions.reduce<Search.Params>(paramsReducer, state);

    case 'UPDATE_SEARCH_SORT': {
      return {
        ...state,
        sort: action.sort,
        sortDir: action.sortDir,
      };
    }

    case 'APPLY_ADVANCED_FILTERS':
    case 'RESET_SEARCH_FILTERS':
    case 'SET_SEARCH_FILTERS': {
      const { type, ...other } = action;

      return {
        ...state,
        ...other,
      };
    }

    case 'ADD_TO_ADVANCED_FILTER_LIST': {
      const item = action.item.isCustom
          ? { custom: true, id: action.item.name }
          : action.item;

      if (state[action.list].some(x => x.id === item.id)) return state;

      return {
        ...state,
        [action.list]:  [ ...state[action.list], item],
      };
    }

    case 'REMOVE_FROM_ADVANCED_FILTER_LIST': {
      const values = state[action.list] as FilterParamValueItem[];

      return {
        ...state,
        [action.list]: values.filter(x => x.id !== action.id),
      };
    }

    case 'SET_PRICE_RANGE': {
      return {
        ...state,
        priceLow: action.low,
        priceHigh: action.high,
      };
    }

    default:
      return state;
  }
};

export const initial: Search.Params = {
  company: [],
  formerCompany: [],
  industry: [],
  jobfunction: [],
  keyword: [],
  location: [],
  priceHigh: consts.rate.MAX_CREDIT,
  priceLow: 0,
  product: [],
  sector: [],
  seniority: [],
  sort: 'most_relevant',
  sortDir: 'desc',
  subindustry: [],
  title: [],
};

export { useSearchParamsState };
export default useSearchParamsState;