import { useCallback, useContext, useMemo } from 'react';
import * as Frame from '@/components/PostCreation/Frame';
import * as Publisher from '@/components/PostCreation/Publisher';
import { PostCreationSubscriptionContext, TextPublishContext, TextURLPreviewContext } from './Context';
import { StateContainer } from './Post.Text.StateContainer';
import * as Search from './Search';
import { TopicTagsContainer } from './Topic.Tags.Container';
import { URLPreviewContainer } from './Post.Text.URLPreview.Container';
import { useTextContentPublisher, useTextPostForm, useTopicTagsContext, useTextPostHasChanged } from './hooks';

const FormContainer = () => {
  const [active, toggle] = useTextContentPublisher();
  const [topic] = useTopicTagsContext();
  const [form] = useTextPostForm();
  const ctx = useContext(PostCreationSubscriptionContext);
  const { query, uploadPreviewImage, url } = useContext(TextURLPreviewContext);

  const createPreviewIfExists = useCallback(async () => {
    if (!url || !query.data) return null;

    const image = await uploadPreviewImage();

    return {
      description: query.data.description,
      domain: query.data.domain,
      image,
      title: query.data.title,
      sitename: query.data.sitename,
      url,
    };
  }, [
    query,
    url,
    uploadPreviewImage,
  ]);

  const save = useCallback(async () => {
    const preview = await createPreviewIfExists();

    return ctx.text.submit({
      ...form,
      body: { value: form.body.value },
      preview,
      topics: topic.items.map(m => m.id),
    });
  }, [
    createPreviewIfExists,
    ctx.text,
    form,
    topic.items,
  ]);

  const hasChanged = useTextPostHasChanged();

  return (
    <>
      <TextPublishContext.Provider value={save}>
        <Search.Container>
          <Publisher.Modal
            blocking={hasChanged}
            onClose={toggle}
            open={active}>
            <Frame.Text.Compose />
          </Publisher.Modal>
        </Search.Container>
      </TextPublishContext.Provider>
    </>
  );
};

export const Container = () => {
  const [active] = useTextContentPublisher();

  if (!active) return null;

  return (
    <TopicTagsContainer>
      <StateContainer>
        <URLPreviewContainer>
          <FormContainer />
        </URLPreviewContainer>
      </StateContainer>
    </TopicTagsContainer>
  );
};

Container.displayName = 'PostCreation.Post.Text.Container';
FormContainer.displayName = 'PostCreation.Post.Text.FormContainer';