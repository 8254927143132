import { useMemo } from 'react';
import { usePopupState } from 'material-ui-popup-state/hooks';
import Containers from './Containers';
import { PopupContext } from './Context';
import Popper from './Popper';
import styles from './style/Bar.css';

export function UniversalSearchBar() {
  const popupState = usePopupState({
    variant: 'popper',
    popupId: styles.portal,
    disableAutoFocus: true,
  });

  const value = useMemo(() => ({
    isOpen: popupState.isOpen,
    setOpen: popupState.setOpen,
  }), [popupState.isOpen, popupState.setOpen]);

  return (
    <PopupContext.Provider value={value}>
      <Containers>
        <Popper popupState={popupState} />
      </Containers>
    </PopupContext.Provider>
  );
}