import { useCallback, useEffect, useState } from 'react';
import { AsyncActivity, ReportSocketEvent } from '@enums';
import * as ws from '@services/websocket';
import { useDownloader } from '@utils/hooks';
import { useReportsSocketEvent } from '@services/websocket';
import type { Reports } from '@services/websocket/interfaces';
import Toast from '@/components/Toast';
import { ReportKeyContext } from './Context';

type Props =
  {
    onDownload?: () => void;
    onError?: () => void;
  } & ChildrenProps;

export const ReportSocketContainer = ({ children, onDownload, onError }: Props) => {
  const [reportKey, setReportKey] = useState<string>(null);
  const download = useDownloader();

  const handleAvailable = useCallback((event: Reports.ReportAvailable.Payload) => {
    if (event.type !== AsyncActivity.FileDownload) return;
    download({
      url: event.data.url,
    });
    setReportKey(null);
    onDownload?.();
  }, [download, onDownload]);

  const handleError = useCallback(() => {
    Toast.error({
      title: 'Error',
      body: 'There was an error downloading the file.',
    });
    setReportKey(null);
    onError?.();
  }, [onError]);

  useReportsSocketEvent(ReportSocketEvent.ReportAvailable, handleAvailable, !!reportKey);
  useReportsSocketEvent(ReportSocketEvent.ReportError, handleError, !!reportKey);

  useEffect(() => {
    if (reportKey) {
      ws.reports.open();
      ws.reports.subscribe(reportKey);

      return () => {
        ws.reports.unsubscribe(reportKey);
        ws.reports.close();
      };
    }
  }, [reportKey]);

  return (
    <ReportKeyContext.Provider value={[reportKey, setReportKey]}>
      {children}
    </ReportKeyContext.Provider>
  );
};

export default ReportSocketContainer;