import { useReducer } from 'react';
import { VideoUploaderContext } from './Context';
import { Uploader } from './interfaces/post.video';

type Props = {
  children: React.ReactNode;
};

export const StateContainer = (props: Props) => {
  const [state, dispatch] = useReducer(reducer, initial);

  return (
    <VideoUploaderContext.Provider value={[state, dispatch]}>
      {props.children}
    </VideoUploaderContext.Provider>
  );
};

StateContainer.displayName = 'PostCreation.Post.Video.Uploader.StateContainer';

function reducer(state: Uploader.State, action: Uploader.Action) {
  switch (action.type) {

    case 'posting/created': {
      const { [action.post.identifier]: item  } = state.media;

      return {
        ...state,
        posts: [action.post, ...state.posts],
        media: {
          ...state.media,
          [action.post.identifier]: {
            ...item,
            status: Uploader.PostingState.Created,
          },
        },
      };
    }

    case 'posting/init':
      return {
        ...state,
        media: {
          ...state.media,
          [action.post.identifier]: {
            post: action.post,
            status: Uploader.PostingState.Initializing,
            uploadIds: [],
          },
        },
      };

    case 'posting/rendered': {
      return {
        ...state,
        posts: state.posts.filter(x => !action.postIds.includes(x.id)),
      };
    }

    case 'posting/state-change': {
      const { [action.pid]: item  } = state.media;

      return {
        ...state,
        media: {
          ...state.media,
          [action.pid]: {
            ...item,
            status: action.status,
          },
        },
      };
    }

    case 'file-upload/progress-change': {
      return {
        ...state,
        progress: {
          ...state.progress,
          [action.fid]: action.value,
        },
      };
    }

    case 'file-upload/started': {
      const { [action.upload.pid]: item  } = state.media;

      return {
        ...state,
        media: {
          ...state.media,
          [action.upload.pid]: {
            ...item,
            status: Uploader.PostingState.Uploading,
            uploadIds: item.uploadIds.concat(action.upload.fid),
          },
        },
        progress: {
          ...state.progress,
          [action.upload.fid]: 0,
        },
        uploads: {
          ...state.uploads,
          [action.upload.fid]: action.upload,
        },
      };
    }

    default:
      return state;
  }
}

const initial = {
  media: {},
  posts: [],
  progress: {},
  uploads: {},
};