import { useCallback, useContext, useMemo } from 'react';
import type { UseQueryResult } from '@tanstack/react-query';
import { QueryDownloaderContext } from '@containers/QueryDownloader/Context';
import type { DownloadFnVariables } from '@containers/QueryDownloader/interfaces';
import type { UseQueryOptions } from '@utils/hooks';
import { useDownloader, useQueryWrapper } from '@utils/hooks';

type QueryKey = readonly [string, ...Array<unknown>];

export const useQueryDownloader = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(params: UseQueryDownloaderOptions<TQueryFnData, TError, TData, TQueryKey>): UseDownloaderResult<TData, TError> => {
  const { hash, init, set } = useContext(QueryDownloaderContext);
  const downloader = useDownloader();

  const {
    downloaderOptions = {},
    queryKey,
    meta = {},
    onError,
    onSuccess,
    ...options
  } = params;

  const queryKeyHash = useMemo(() => hash(queryKey), [hash, queryKey]);

  const handleTransferring = useCallback(() => {
    set(queryKeyHash, {
      status: 'transferring',
    });
  }, [
    queryKeyHash,
    set,
  ]);

  const handleResponse = useCallback((res: TData) => {
    if (meta.downloader) return;

    const resource = downloaderOptions.onResponse?.(res);

    if (!resource?.value) {
      return handleTransferring();
    }

    switch (resource?.type) {
      case 'blob':
        downloader({
          blob: resource.value,
          filename: resource.filename,
        });

        handleTransferring();
        break;

      case 'websocket':
        set(queryKeyHash, {
          websocketKey: resource.value,
        });

        break;

      case 'url':
        downloader({
          filename: resource.filename,
          url: resource.value,
        });

        handleTransferring();
        break;
    }

  }, [
    downloader,
    downloaderOptions,
    handleTransferring,
    queryKeyHash,
    meta,
    set,
  ]);

  const other = useMemo(() => {

    if (!meta.downloader) return { meta };

    return {
      meta: {
        ...meta,
        onResponse: (res: TData) => {
          const resource = downloaderOptions.onResponse?.(res);

          if (resource?.type === 'blob') {
            downloader({
              blob: resource.value,
              filename: resource.filename,
            });

            handleTransferring();
          } else if (resource?.type === 'url') {
            downloader({
              filename: resource.filename,
              url: resource.value,
            });

            handleTransferring();
          }
        },
      },
    };
  }, [
    downloader,
    downloaderOptions,
    handleTransferring,
    meta,
  ]);

  const { refetch, ...query } = useQueryWrapper({
    queryKey,
    retry: 1,
    ...options,
    ...other,
    enabled: false,
    onSuccess: res => {
      handleResponse(res);
      onSuccess?.(res);
    },
    onError: e => {
      set(queryKeyHash, {
        status: 'error',
      });
      onError?.(e);
    },
  });

  const download = useCallback((params: DownloadFnVariables) => {
    init({
      id: queryKeyHash,
      queryKey,
      ...(params || {}),
    });
    return refetch();
  }, [
    queryKeyHash,
    init,
    queryKey,
    refetch,
  ]);

  return {
    ...query,
    download,
  };
};

export type UseQueryDownloaderOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey> =
  & Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'enabled'>
  & DownloaderOptions<TData>;

export type UseDownloaderOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey> =
  & Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'enabled'>
  & Omit<DownloaderOptions<TData>, 'downloaderOptions'>;

export type UseDownloaderResult<TData = unknown, TError = unknown> =
  & Omit<UseQueryResult<TData, TError>, 'refetch'>
  & DownloaderResult<TData, TError>;

type DownloaderResult<TData = unknown, TError = unknown> = {
  download: (params?: DownloadFnVariables) => ReturnType<UseQueryResult<TData, TError>['refetch']>;
};

type DownloaderOptions<Res = unknown> = {
  downloaderOptions?: {
    onResponse?: (res: Res) => FileResource;
  };
};

type FileResource =
  | { filename?: string; type: `blob`;      value: Blob }
  | { filename?: string; type: `url`;       value: string }
  | { filename?: string; type: `websocket`; value: string };