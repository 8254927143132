import { useContext, useMemo } from 'react';
import { ExternalLink } from 'react-feather';
import { ProjectDisplayrDashboardIdContext } from '@containers/GroupProject/Context';
import styles from './style/Dashboard.css';

export const ProjectDisplayrDashboard = () => {

  const displayrDashboardId = useContext(ProjectDisplayrDashboardIdContext);

  const displayrUrl = useMemo(() => {
    if (!displayrDashboardId) {
      return null;
    }

    const url = new URL('https://app.displayr.com/Dashboard');
    url.searchParams.append('id', displayrDashboardId);

    return url;
  }, [displayrDashboardId]);

  if (!displayrUrl) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>

        <div className={styles.section}>
          <div className={styles.header}>
            <div className={styles.title}>
              <div className={styles.h2}>Dashboard</div>
              <a
                href={displayrUrl.href}
                target="_blank"
                rel="noreferrer">
                <ExternalLink
                  size={22}
                  className={styles.link} />
              </a>
            </div>
          </div>

          <iframe
            className={styles.iframe}
            src={displayrUrl.href} />
        </div>

      </div>
    </div>
  );
};