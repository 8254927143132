import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import { PersonalCalendar } from '@screens/PersonalCalendar';
import { PersonalCalendarResponsive } from '@screens/PersonalCalendarResponsive';
import { device } from '@utils';

type Props =
  RouteComponentProps;

const Personal = (props: Props) => {

  const Screen = device.phone
      ? PersonalCalendarResponsive
      : PersonalCalendar;

  return (
    <>
      <Helmet title="Calendar" />
      <Screen />
    </>
  );
};

export { Personal as PersonalCalendar };
export default Personal;