import { Sliders } from 'react-feather';
import { memo, useCallback } from 'react';
import { ExclusiveOptionsQuestion, SurveyLogic } from '@/types';
import { SectionTypeDisplayName } from '@containers/SurveyBuilder/utils/question.exclusive-options';
import { QuestionOption } from './Option';
import { useSettingsModal } from './ExclusiveOptions.SettingsModal';
import styles from './style/ExclusiveOptions.Question.css';
import { useGetOptionsSelectedLogic, useQuestionClassifications, useQuestionLogic } from './hooks';
import { OptionMetadata } from './Metadata';
import { SurveyQuestionLogic } from './Logic';
import { SurveyQuestionClassifications } from './Tagging';
import { SurveySettings } from './Settings';

type Props = {
  item: ExclusiveOptionsQuestion.Question;
};

export const ExclusiveOptions = memo(({ item }: Props) => {
  const questionLogic = useQuestionLogic(item.base.identifier);
  const questionClassifications = useQuestionClassifications(item.base.identifier);

  return (
    <>
      {item.settings.optionSections.sort((a, b) => a.ordinal - b.ordinal).map(s => (
        <Section
          question={item}
          questionLogic={questionLogic}
          key={s.identifier}
          section={s}
          options={item.options} />
      ))}
      <SurveySettings>
        {item.settings.rationale.enabled && <>Ask for rationale</>}
      </SurveySettings>
      <SurveyQuestionLogic items={questionLogic} />
      <SurveyQuestionClassifications items={questionClassifications} />
    </>
  );
});

type SectionProps = {
  question: ExclusiveOptionsQuestion.Question;
  section: ExclusiveOptionsQuestion.OptionSection;
  options: ExclusiveOptionsQuestion.Question['options'];
  questionLogic: SurveyLogic.Item[];
};

const Section = ({ section: s, options, question, questionLogic }: SectionProps) => {
  const [toggleModal, Modal] = useSettingsModal();

  const renderOptionSettings = useCallback((optionId: string) => {
    const exclusiveOptions = s?.settings?.exclusiveGroups.find(g => g.primaryOption === optionId)?.exclusiveOptions?.map(o => options.find(x => x.base.identifier === o));
    if (!exclusiveOptions?.length) return null;

    return (
      <span className={styles.exclusiveOptionSubtext}>
        (Exclusive from {exclusiveOptions.sort((a, b) => a.ordinal - b.ordinal).map(o => `A${o.ordinal}`).join(', ')})
      </span>
    );
  }, [s, options]);

  const getOptionsLogic = useGetOptionsSelectedLogic(questionLogic);

  const renderOptionDetails = useCallback((option: ExclusiveOptionsQuestion.Option) => {
    const logicItems = getOptionsLogic(option);

    return (
      <>
        {renderOptionSettings(option.base.identifier)}
        <OptionMetadata
          isAnchored={false}
          isExclusive={option.metadata.isExclusive}
          isOpenEnded={option.metadata.isOpenEnded} />
        {logicItems}
      </>
    );
  }, [getOptionsLogic, renderOptionSettings]);

  return (
    <div>
      <div className={styles.divider} />
      <div className={styles.sectionHeader}>
        <div>
          <span className={styles.bullet}>{`G${s.ordinal}`}</span> <span className={styles.sectionValue}>{s.value}</span>
        </div>
        <div>
          <Sliders
            className={styles.settingsIcon}
            onClick={toggleModal} />
        </div>
      </div>
      <div className={styles.sectionType}>
        {SectionTypeDisplayName[s.type]} {s.settings.requireSelection ? '(Selection required)' : ''}
      </div>
      <div className={styles.optionsSection}>
        {options.filter(o => o.metadata.sectionId === s.identifier).sort((a, b) => a.ordinal - b.ordinal).map(o => {
          return (
            <div key={o.base.identifier} className={styles.optionContainer}>
              <QuestionOption
                className={styles.option}
                item={o}>
                {renderOptionDetails(o)}
              </QuestionOption>
            </div>
          );
        })}
      </div>
      <Modal
        section={s}
        options={options}
        question={question} />
    </div>
  );
};