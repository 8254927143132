import { useMemo } from 'react';
import { path } from '@consts';
import type { SceneMap } from './interfaces';
import { RouteKey } from './interfaces';
import { BrandTabProjectTracker } from './Tab.ProjectTracker';
import { BrandTabProjects } from './Tab.Projects';
import { BrandTabFiles } from './Tab.Files';

export const useBrandTabsMap = () => {
  const map: SceneMap = useMemo(() => ({
    [RouteKey.ProjectTracker]: {
      component: BrandTabProjectTracker,
      path: path.WorkspaceBrandTabs.ProjectTracker,
      title: 'Project Tracker',
    },
    [RouteKey.Projects]: {
      component: BrandTabProjects,
      path: path.WorkspaceBrandTabs.Projects,
      title: 'Projects',
    },
    [RouteKey.Files]: {
      component: BrandTabFiles,
      path: path.WorkspaceBrandTabs.Files,
      title: 'Files',
    },
  }), []);
  return map;
};