import { useMemo } from 'react';
import type { Modifier } from 'react-popper';

type Props = {
  zIndex: number;
};

export const useZIndexModifier = ({ zIndex }: Props) => {
  return useMemo(() => {
    const modifier: Modifier<'zIndexer'> = {
      name: 'zIndexer',
      enabled: true,
      phase: 'beforeWrite',
      requires: ['computeStyles'],
      fn: ({ state }) => {
        state.styles.popper.zIndex = `${zIndex}`;
      },
      effect: ({ state }) => {
        state.elements.popper.style.zIndex = `${zIndex}`;
      },
    };
    return modifier;
  }, [zIndex]);
};