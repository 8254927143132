import { useCallback, useMemo } from 'react';
import type { PopupState as PopupStateProps } from 'material-ui-popup-state/hooks';
import { FileActionsContextMenuPopper } from '@/components/Workspace.File/File.Actions.ContextMenu';
import type { FileMenuItemsContextValue } from '@/containers/Workspace.File/Context';
import { FileMenuItemsContext } from '@/containers/Workspace.File/Context';
import useWorkspaceFileState from '@/containers/Workspace.File.Preview/hooks/useWorkspaceFileState';
import { MoreHorizontalAnchor } from '@/presentation/Anchor';
import { FileActionsContainer } from '@/containers/Workspace.File/FileMenuContainer';
import { assertCanImportTranscript, assertCanTranscribeFile } from '@/containers/Workspace.File/utils';
import styles from './style/Header.Actions.css';

export const FilePreviewContextMenu = () => {

  const { capabilities, file, object, transcript } = useWorkspaceFileState();

  const menu: FileMenuItemsContextValue = useMemo(() => ({
    canChangeOwner: false, // capabilities.canChangeOwner,
    canDelete: false, // capabilities.canDelete,
    canDownload: capabilities.canDownload,
    canManageAccess: false, // capabilities.canManageAccess,
    canMove: false, // capabilities.canMove,
    canRename: capabilities.canRename,
    canTranscribe: assertCanTranscribeFile(transcript),
    canViewTranscript: !!transcript.id,
    canImportTranscript: assertCanImportTranscript(transcript),
  }), [
    capabilities,
    transcript,
  ]);

  const renderAnchor = useCallback((state: PopupStateProps) => {
    return (
      <MoreHorizontalAnchor className={styles.menu} open={state.isOpen} />
    );
  }, []);

  return (
    <FileMenuItemsContext.Provider value={menu}>
      <FileActionsContainer
        file={file}
        object={object}
        transcript={transcript}>
        <FileActionsContextMenuPopper renderAnchor={renderAnchor} />
      </FileActionsContainer>
    </FileMenuItemsContext.Provider>
  );
};