import { ButtonOutlined } from '@presentation/ButtonOutlined';
import { cx } from '@utils';
import { ButtonOutlinedProps } from './interfaces/Button';
import styles from './style/ButtonOutlined.LabeledIcon.css';

type Props = {
  children?:         React.ReactNode;
  divider?:          boolean;
  dividerClassName?: string;
  icon:              React.ReactElement;
} & ButtonOutlinedProps;

export const ButtonOutlinedLabeledIcon = (props: Props) => {
  const {
    borderWidth = 'thin',
    children,
    className,
    color = 'silver',
    divider = false,
    dividerClassName,
    icon,
    variant = 'brick',
    ...other
  } = props;

  const classes = cx(
    styles.root,
    className,
  );

  return (
    <ButtonOutlined
      className={classes}
      color={color}
      borderWidth={borderWidth}
      variant={variant}
      {...other}>
      <div className={styles.wrap}>
        <div className={styles.icon}>
          {icon}
        </div>
        {divider && <div className={cx(styles.divider, dividerClassName)} />}
        <div className={styles.main}>{props.children}</div>
      </div>
    </ButtonOutlined>
  );
};

ButtonOutlinedLabeledIcon.displayName = 'ButtonOutlined.LabeledIcon';