import { useCallback, useContext, useMemo, useState } from 'react';
import type { HeaderContext } from '@tanstack/react-table';
import type { MouseEventHandler } from 'react';
import { usePopper } from 'react-popper';
import { ClickAwayListener } from '@mui/material';
import { ProjectClipsFiltersContext, ProjectClipsSortContext } from '@/containers/Project.Clips';
import type { ProjectClips } from '@/types';
import { useZIndexModifier } from '@/components/Popper';
import { Portal } from '@/components/Portal';
import { SortableHeader } from './Header.Sortable';
import { useIsColumnSorted } from './hooks/useIsColumnSorted';
import { SourcesFilterMenu } from './Filter.Source';
import { Filter } from './Filter.Icon';

type Props = HeaderContext<ProjectClips.Item, unknown>;

export const SourceHeader = ({ column }: Props) => {

  const { onSortClick } = useContext(ProjectClipsSortContext);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const hasSortApplied = useIsColumnSorted(column.id);

  const [filterElement, setFilterElement] = useState<HTMLDivElement>(null);

  const handleClick: MouseEventHandler = useCallback(e => {

    if (filterElement?.contains(e.target as Node)) {
      return;
    }

    onSortClick(column.id);
  }, [column.id, filterElement, onSortClick]);

  const filters = useContext(ProjectClipsFiltersContext);

  const [popperElement, setPopperElement] = useState<HTMLElement>(null);

  const zIndexModifier = useZIndexModifier({ zIndex: 8 });
  const { styles: popperStyles, attributes } = usePopper(filterElement, popperElement, {
    modifiers: [
      zIndexModifier,
    ],
    placement: 'bottom-start',
  });

  const handleBlur = useCallback(() => {
    setFilterMenuOpen(false);
  }, []);

  const handleFilterClick = useCallback(() => {
    setFilterMenuOpen(true);
  }, []);

  const isFilterActive = useMemo(() => {
    return !!filters.transcriptIds.length;
  }, [filters.transcriptIds]);

  return (
    <>
      <SortableHeader
        onClick={handleClick}
        sortable={true}
        sorted={hasSortApplied}>
        <div
          ref={setFilterElement}
          onClick={handleFilterClick}>
          <Filter isActive={isFilterActive} />
        </div>
        Interview
      </SortableHeader>
      {filterMenuOpen &&
        <Portal>
          <ClickAwayListener
            mouseEvent='onMouseDown'
            onClickAway={handleBlur}
            touchEvent='onTouchStart'>
            <div
              ref={setPopperElement}
              style={popperStyles.popper}
              {...attributes.popper}>
              <SourcesFilterMenu onSubmit={handleBlur} />
            </div>
          </ClickAwayListener>
        </Portal>
      }
    </>
  );
};